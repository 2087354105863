const defaultTheme = {
  color: {
    action: {
      primary: '#2E93EE',
      hover: '#1D5297',
      disabled: '#C9E3FA',
    },
    text: {
      primary: '#4A4A4A',
      featured: '#1D5297',
      label: '#A6B6CC',
      disabled: '#CDD8DF',
    },
    background: {
      tooltip: '#707070',
      wizard: '#F1F3F6',
      boxes: '#F2F8FC',
      marketingBanner: '#1F2044',
    },
    border: {
      primary: '#CDD8DF',
    },
    line: {
      wizard: '#D2DAE5',
      fields: '#A6B6CC',
      divisor: '#CDD8DF',
    },
    warning: {
      pending: '#FF8D4E',
      success: '#35CF8B',
      attention: '#FBCE3B',
      error: '#D94445',
      canceled: '#CDD8DF',
      mustard: '#FFD54C',
    },
    white: {
      primary: '#fff',
    },
    shadow: {
      primary: '#00000029',
    },
  },
  font: {
    family: {
      primary: "'Galano', 'Roboto',sans-serif,'Helvetica Neue',sans-serif",
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },
  breakpoints: {
    xs: '360px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
  layers: {
    hidden: -1,
    base: 0,
    header: 20,
    overlay: 30,
    modal: 40,
    awaysOnTop: 50,
    important: 99999,
  },
  shadows: {
    grey: {
      light: 'rgba(0, 0, 0, 0.16)',
      dark: 'rgba(0, 0, 0, 0.05)',
      darkest: 'rgba(0, 0, 0, 0.5)',
    },
  },
};

export default defaultTheme;
