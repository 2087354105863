const styles = theme => ({
  text: {
    color: theme.color.indigo,
  },
  wrapper: {
    padding: '0',
    position: 'relative',
  },
  cardGrid: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    justifyContent: 'center',
    paddingBottom: '48px',
    [theme.breakpoints.up(600)]: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },

    '& div + :nth-child(4n)': {
      marginRight: '22px',

      [theme.breakpoints.up(1200)]: {
        marginRight: 0,
      },
    },
  },
});

export default styles;
