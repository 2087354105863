import { CALL_API } from '@/middleware/api';
import { DNS_DOMAIN_LIST, DNS_DOMAIN_LIST_EXTERNAL, DNS_DETAILS } from '@/config/api';
import {
  DNS_DOMAIN_LIST_REQUEST, DNS_DOMAIN_LIST_RECEIVE, DNS_DOMAIN_LIST_ERROR,
  DNS_DETAILS_REQUEST, DNS_DETAILS_RECEIVE, DNS_DETAILS_ERROR,
  SAVE_CHANGE_TO_DNS_NATIVE_REQUEST, SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS, SAVE_CHANGE_TO_DNS_NATIVE_ERROR,
  CHANGE_TO_OTHER_DNS, CHANGE_TO_NATIVE_DNS, CHANGE_TO_HOSTING_DNS,
  SAVE_CHANGE_TO_DNS_OTHER_REQUEST, SAVE_CHANGE_TO_DNS_OTHER_SUCCESS, SAVE_CHANGE_TO_DNS_OTHER_ERROR,
  DNS_DOMAIN_LIST_EXTERNAL_REQUEST, DNS_DOMAIN_LIST_EXTERNAL_RECEIVE, DNS_DOMAIN_LIST_EXTERNAL_ERROR,
  SAVE_CHANGE_TO_DNS_HOSTING_REQUEST, SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS, SAVE_CHANGE_TO_DNS_HOSTING_ERROR,
  CHANGE_DNS_DOMAIN_STATE, SET_CHOSEN_DOMAIN,
} from './actionsTypes';


function receiveDns(dnsList) {
  const newList = dnsList;

  Object.keys(newList).forEach((key) => {
    if (key !== 'loadingExternalRequest' && key !== 'loadingInternalRequest' && newList[key].length > 0) {
      newList[key].forEach((obj) => {
        const newObject = obj;
        newObject.domain = newObject.domain.replace(/ /g, '');
        return newObject;
      });
    }
  });

  return {
    type: DNS_DOMAIN_LIST_RECEIVE,
    dnsList: newList,
  };
}

function receiveDnsExternal(dnsList) {
  return {
    type: DNS_DOMAIN_LIST_EXTERNAL_RECEIVE,
    dnsList,
  };
}

function receiveDnsDetails(dnsDetails) {
  const newDnsDetails = dnsDetails;
  if (newDnsDetails.data.hosting || newDnsDetails.data.native || newDnsDetails.data.other) {
    newDnsDetails.data.dbData = {
      hosting: newDnsDetails.data.hosting,
      native: newDnsDetails.data.native,
      other: newDnsDetails.data.other,
    };
  }
  return {
    type: DNS_DETAILS_RECEIVE,
    dnsDetails: newDnsDetails,
  };
}

export function loadDnsDomainList() {
  return {
    [CALL_API]: {
      endpoint: DNS_DOMAIN_LIST,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: DNS_DOMAIN_LIST_REQUEST }),
        success: response => receiveDns(response.data),
        error: () => ({ type: DNS_DOMAIN_LIST_ERROR }),
      },
      authenticated: true,
    },
  };
}

export function loadDnsDomainListExternal() {
  return {
    [CALL_API]: {
      endpoint: DNS_DOMAIN_LIST_EXTERNAL,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: DNS_DOMAIN_LIST_EXTERNAL_REQUEST }),
        success: response => receiveDnsExternal(response.data),
        error: () => ({ type: DNS_DOMAIN_LIST_EXTERNAL_ERROR }),
      },
      authenticated: true,
    },
  };
}

export function loadDnsDetails(domainId) {
  return {
    [CALL_API]: {
      endpoint: DNS_DETAILS(domainId),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: DNS_DETAILS_REQUEST }),
        success: response => receiveDnsDetails(response),
        error: () => ({ type: DNS_DETAILS_ERROR }),
      },
      authenticated: true,
    },
  };
}

export function changeDnsToNative() {
  return {
    type: CHANGE_TO_NATIVE_DNS,
  };
}

export function saveDnsNative(domainId) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: DNS_DETAILS(domainId),
      method: 'PUT',
      body: { native: true },
      actionTypes: {
        request: () => ({ type: SAVE_CHANGE_TO_DNS_NATIVE_REQUEST }),
        success: response => (response.http_status_code === 200
          ? ({ type: SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS })
          : ({ type: SAVE_CHANGE_TO_DNS_NATIVE_ERROR })),
        error: () => ({ type: SAVE_CHANGE_TO_DNS_NATIVE_ERROR }),
      },
    },
  };
}

export function changeDnsToOther() {
  return {
    type: CHANGE_TO_OTHER_DNS,
  };
}

const receiveDnsOther = (data) => {
  const other = Object.keys(data).reduce((prev, next) => [...prev, {
    name: next,
    host: data[next].host,
    ip: data[next].ip,
  }], []);
  return {
    type: SAVE_CHANGE_TO_DNS_OTHER_SUCCESS,
    other,
  };
};

export function saveDnsOther(domainId, other) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: DNS_DETAILS(domainId),
      method: 'PUT',
      body: other,
      actionTypes: {
        request: () => ({ type: SAVE_CHANGE_TO_DNS_OTHER_REQUEST }),
        success: response => (response.data.result === 'success'
          ? receiveDnsOther(other)
          : ({ type: SAVE_CHANGE_TO_DNS_OTHER_ERROR })),
        error: () => ({ type: SAVE_CHANGE_TO_DNS_OTHER_ERROR }),
      },
    },
  };
}

export const changeDnsToHosting = () => ({
  type: CHANGE_TO_HOSTING_DNS,
});

const receiveDnsHosting = data => ({
  type: SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS,
  hosting: data,
});

export function saveDnsHosting(domainId, hosting) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: DNS_DETAILS(domainId),
      method: 'PUT',
      body: { hostingId: hosting.id },
      actionTypes: {
        request: () => ({ type: SAVE_CHANGE_TO_DNS_HOSTING_REQUEST }),
        success: ({ data }) => (data.result === 'success'
          ? receiveDnsHosting(hosting)
          : ({ type: SAVE_CHANGE_TO_DNS_HOSTING_ERROR })),
        error: () => ({ type: SAVE_CHANGE_TO_DNS_HOSTING_ERROR }),
      },
    },
  };
}

export const updateDomainDnsState = (dnsState, domainId) => (dispatch, getState) => {
  const { dnsList: stateDomains } = getState();
  const stateToFindDnsDomain = dnsState === 'dnsOk' ? 'dnsMissing' : 'dnsOk';
  const indexItem = stateDomains.domains[stateToFindDnsDomain].findIndex(item => item.id === domainId);

  if (indexItem !== -1) {
    stateDomains.domains[dnsState].push(stateDomains.domains[stateToFindDnsDomain][indexItem]);
    stateDomains.domains[stateToFindDnsDomain].splice(indexItem, 1);
  }

  return dispatch(({
    type: CHANGE_DNS_DOMAIN_STATE,
    stateDomains,
  }));
};

export const setChosenDomain = domain => ({
  type: SET_CHOSEN_DOMAIN,
  payload: { domain },
});
