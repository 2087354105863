import { CommonActionTypes } from './common.types';

const setLoading = loading => ({
  type: CommonActionTypes.SET_LOADING,
  payload: {
    loading,
  },
});

const setNotifications = notifications => ({
  type: CommonActionTypes.SET_NOTIFICATIONS,
  payload: notifications,
});

const clearNotification = index => ({
  type: CommonActionTypes.CLEAR_NOTIFICATIONS,
  payload: { index },
});

const setRedirect = redirect => ({
  type: CommonActionTypes.SET_REDIRECT,
  payload: {
    redirect,
  },
});

const clearRedirect = () => ({
  type: CommonActionTypes.CLEAR_REDIRECT,
});

const setServerTime = serverTime => ({
  type: CommonActionTypes.SET_SERVER_TIME,
  payload: {
    serverTime,
  },
});

const requestServerTime = () => ({
  type: CommonActionTypes.REQUEST_SERVER_TIME,
});

const actions = {
  loading: {
    set: setLoading,
  },
  notifications: {
    set: setNotifications,
    remove: clearNotification,
  },
  redirect: {
    set: setRedirect,
    clear: clearRedirect,
  },
  serverTime: {
    set: setServerTime,
    request: requestServerTime,
  },
};

export default actions;
