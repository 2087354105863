/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  loadBasicPrice, loadDomains, setDomain, requestCompleteScan, requestShield, clearSitelock, requestCompleteSMARTScan, getPaymentMethods, getPrices,
} from '@/redux/actions/sitelock';
import { loadDomains as loadAllDomains } from '@/redux/actions/domains';
import { loadFaqSecurity } from '@/redux/actions/faq';
import { loadCGDomains } from '@/redux/actions/codeguard';

export const SecurityContext = createContext();

const SecurityContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const sitelockProducts = useSelector(state => state.sitelock);
  const sitelockCta = useSelector(state => state.sitelock.cta);
  const codeguardProducts = useSelector(state => state.codeguard);

  useEffect(() => {
    dispatch(loadAllDomains({}));
  }, [dispatch]);

  useEffect(() => {
    const noProductType = params.productType === undefined;
    const noProductId = params.productId === undefined;
    const noCompleteScan = sitelockProducts.completeScan === null;
    const noSmartScanHistory = sitelockProducts.smartscan.history === null;
    const noSitelockProducts = !sitelockProducts.sitelockProduct;

    if (!noProductType && !noProductId) {
      noCompleteScan && dispatch(requestCompleteScan(params.productType, params.productId));

      noSmartScanHistory && dispatch(requestCompleteSMARTScan(params.productType, params.productId));

      noSitelockProducts && dispatch(setDomain(params.productType, params.productId));

      dispatch(requestShield(params.productType, params.productId));

      dispatch(loadFaqSecurity());
    }

    if (sitelockCta !== undefined) {
      if (!sitelockCta.paymentMethods.length && sitelockCta.isLoadingPayment === false) {
        dispatch(getPaymentMethods());
      }
      if (!sitelockCta.prices.length && sitelockCta.isLoadingPrices === false) {
        dispatch(getPrices());
      }
    }

    if (sitelockProducts.basicPrice === null) {
      dispatch(loadBasicPrice());
    }

    if (!sitelockProducts.loadingDomains) {
      dispatch(loadDomains({}));
    }

    if (!codeguardProducts.loadingDomains) {
      dispatch(loadCGDomains());
    }
  }, []);

  useEffect(() => {
    dispatch(clearSitelock());
  }, [params]);

  useEffect(() => {
    if (sitelockCta !== undefined) {
      if (!sitelockCta.prices.length && sitelockCta.isLoadingPrices === false) {
        dispatch(getPrices());
      }
    }
  }, [sitelockCta]);


  const values = {
    sitelockProducts,
  };


  return (
    <SecurityContext.Provider value={values} t={key => key}>
      {children}
    </SecurityContext.Provider>
  );
};

SecurityContextProvider.propTypes = {
  children: PropTypes.node,
};

SecurityContextProvider.defaultProps = {
  children: null,
};

export default SecurityContextProvider;
