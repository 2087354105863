import React from 'react';
import * as Style from './IconDoubt.style';

const IconDoubt = ({
  color = '#fff',
  borderColor = '#4480c5',
  backgroundColor = '#4480c5',
  testId = 'icon-doubt',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="icn_suporte" transform="translate(-1065 -29)">
        <g id="Elipse_305" data-name="Elipse 305" transform="translate(1065 29)" fill={backgroundColor} stroke={borderColor} strokeWidth="2">
          <circle cx="12" cy="12" r="12" stroke="none" />
          <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <text id="_" data-name="?" transform="translate(1073 46)" fill={color} fontSize="16" fontFamily="Roboto-Bold, Roboto" fontWeight="700" letterSpacing="0.001em"><tspan x="0" y="0">?</tspan></text>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDoubt;
