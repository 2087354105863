import React from 'react';
import { OPTION_NAME } from './useMountEmptyList.enum';
import { useMountEmptyListLocale } from './useMountEmptyList.locale';
import {
  IconDetailedServer, IconDetailedDomain, IconDetailedEmail, IconDetailedSecurity, IconDetailedAcademy,
} from '@/icons';

export const rules = {
  [OPTION_NAME.DOMAINS]: (options, routes) => {
    options.splice(0, 0, {
      name: OPTION_NAME.DOMAINS,
      label: useMountEmptyListLocale.emptyOptions.manageDomains,
      icon: <IconDetailedDomain />,
      route: routes.domains,
      target: '_self',
    });
  },
  [OPTION_NAME.TITAN]: (options, routes) => {
    options.splice(0, 0, {
      name: OPTION_NAME.TITAN,
      label: useMountEmptyListLocale.emptyOptions.manageEmails,
      icon: <IconDetailedEmail />,
      route: routes.emailsList,
      target: '_self',
    });
  },
  [OPTION_NAME.DEDICATED]: (options, routes) => {
    options.splice(0, 0, {
      name: OPTION_NAME.DEDICATED,
      label: useMountEmptyListLocale.emptyOptions.manageServer,
      icon: <IconDetailedServer />,
      route: routes.products,
      target: '_self',
    });
  },
  [OPTION_NAME.SECURITY]: (options, routes) => {
    options.splice(0, 0, {
      name: OPTION_NAME.SECURITY,
      label: useMountEmptyListLocale.emptyOptions.manageSecurity,
      icon: <IconDetailedSecurity />,
      route: routes.security,
      target: '_self',
    });
  },
  [OPTION_NAME.ACADEMY]: (options, routes) => {
    options.splice(0, 0, {
      name: OPTION_NAME.ACADEMY,
      label: useMountEmptyListLocale.emptyOptions.manageAcademy,
      icon: <IconDetailedAcademy />,
      route: routes.academy,
      target: '_self',
    });
  },
  [null]: () => null,
};
