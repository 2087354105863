import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 16px 8px 24px 16px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 0px;
  `}
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin: 8px 0px 40px 0px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0px 0px 0px;
`;
