import { CALL_API } from '@/middleware/api';
import { api } from '@/utils/api';
import {
  GATORPAY_PAAS_SAVE_CREDITCARD,
  GATORPAY_PAAS_SAVE_ATTEMPT_CREDITCARD,
  GATORPAY_PAAS_GET_ATTEMPT_CREDITCARD,
  BLACK_LIST_GET_IP_USER,
} from '@/config/api';
import {
  REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD,
  RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD,
  ERROR_GATORPAY_PAAS_SAVE_CREDITCARD,
  REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT,
  RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT,
  ERROR_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT,
  REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT,
  ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT,
  RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT,
  ERROR_GATORPAY_PAAS_GET_USER_IP,
} from './actionsTypes';

export const saveCreditCard = body => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: GATORPAY_PAAS_SAVE_CREDITCARD,
    method: 'POST',
    body,
    actionTypes: {
      request: () => ({ type: REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD }),
      success: ({ success }) => ({ type: RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD, success }),
      error: () => ({ type: ERROR_GATORPAY_PAAS_SAVE_CREDITCARD }),
    },
  },
});

export const saveCreditCardAttempt = token => async (dispatch) => {
  const getIp = async () => {
    const requestIpUser = {
      url: BLACK_LIST_GET_IP_USER,
    };

    try {
      const result = await Promise.all([
        api.whmcs.get(requestIpUser),
      ]);

      const [dados] = result;
      const ip = dados.data.data;
      return ip && ip.ip ? ip.ip : '';
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error to get ip', e);
      dispatch({ type: ERROR_GATORPAY_PAAS_GET_USER_IP });
    }
  };

  const resultIp = await getIp();
  const bodyObject = {
    token,
    ip: resultIp,
  };

  return dispatch({
    [CALL_API]: {
      endpoint: GATORPAY_PAAS_SAVE_ATTEMPT_CREDITCARD,
      method: 'POST',
      body: bodyObject,
      actionTypes: {
        request: () => ({ type: REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT }),
        success: response => RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT(response),
        error: () => ({ type: ERROR_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT }),
      },
    },
  });
};

const receiveCreditCardAttempt = success => ({
  type: RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT,
  success,
});

export const getCreditCardAttempt = () => ({
  [CALL_API]: {
    endpoint: GATORPAY_PAAS_GET_ATTEMPT_CREDITCARD,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT }),
      success: data => receiveCreditCardAttempt(data || []),
      error: () => ({ type: ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT }),
    },
  },
});
