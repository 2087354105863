import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v2.font.family.primary};
    padding: 32px;
  `}
`;

export const ContentTitle = styled.div`
  display: flex;
  gap: 16px;
`;

export const TitleModal = styled.text`
${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v2.font.weights.regular};
    `}
`;

export const ControlButton = styled.div`
    ${Button}:not(:first-child) {
      margin-left: 12px;
    }
    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.feature};
    display: inline-flex;
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
    margin: 26px 0px 32px 0px;
  `}
`;

export const ContentData = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-family: ${theme.v2.font.family.primary};
    line-height: normal;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
`;
