import React from 'react';
import {
  withStyles,
  Dialog,
  Grid,
  Paper,
  IconButton,
  DialogContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { withI18n, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { formatDate } from '@/utils/Formatters/formatDate';
import styles from './styles';

const ModalSelectionHostingPlans = ({
  onClose, open, classes, t, domain, hostings,
}) => {
  const [value, setValue] = React.useState(1);
  const [disabledButtonAction, setDisabledButtonAction] = React.useState(true);
  const [urlTitanHire, setUrlTitanHire] = React.useState('');


  const handleChange = (event) => {
    const url = `${t('routes.emailsList')}${t('routes.titanHireFreePlan')}/${hostings[event.target.value].primary_domain}?id=${hostings[event.target.value].hostingId}&selected=true`;
    setUrlTitanHire(url);
    setValue(event.target.value);
    setDisabledButtonAction(false);
  };
  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      open={open}
      className={classes.checkAvailabilityPlanLinkDialog}
    >
      <div id="dialog-title" className={classes.dialogTitle}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ textAlign: 'center' }}>
        <Trans i18nKey="proEmail.hostingPlansTitleLabel" values={{ domain }}>
          <font className={classes.title}>0</font>
          <font className={classes.titleDomain} data-testid="textDomain">1</font>
          <font className={classes.title}>2</font>
        </Trans>
        <Typography className={classes.description}>{t('proEmail.hostingPlansDescriptionLabel')}</Typography>

        <Paper className={classes.scroolPaper}>
          <RadioGroup
            data-testid="radioGroup"
            className={classes.radioItem}
            value={value}
            onChange={handleChange}
          >
            {Object.keys(hostings).map((index) => {
              const title = `${t('proEmail.hostingLabel')}  ${hostings[index].hostname} - ${hostings[index].primary_domain}`;
              const regDate = hostings[index].regdate;
              const nextDueDate = hostings[index].nexduedate;

              return (

                <FormControlLabel
                  value={index}
                  control={<Radio data-testid={t(`radio_${index}`)} />}
                  alignItems="flex-start"
                  className={classes.gridList}
                  label={(
                    <Grid container spacing={4}>
                      <Grid item>
                        <Typography title={hostings[index].primary_domain} className={classes.titleItem} data-testid={t(`radioTitle_${index}`)}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item container spacing={4} className={classes.gridDates}>
                        <Grid item className={classes.gridSubDates.firstColumn}>
                          <Typography className={classes.titleDate}>
                            {t('proEmail.hostingPlansCreateDateLabel')}
                          </Typography>
                          <Typography className={classes.titleItemDate} data-testid={t(`regDate_${index}`)}>
                            {formatDate(regDate)}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.gridSubDates}>
                          <Typography className={classes.titleDate}>
                            {t('proEmail.hostingPlansDueDateLabel')}
                          </Typography>
                          <Typography
                            className={classes.titleItemDate}
                            data-testid={t(`nextDueDate_${index}`)}
                          >
                            {formatDate(nextDueDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                          )}
                />
              );
            })}
          </RadioGroup>
        </Paper>
        <NavLink target="_blank" disabled={disabledButtonAction} to={urlTitanHire} onClick={onClose} className={classes.checkAvailabilityPlanLinkButton} data-testid="linkButton">
          {t('proEmail.hostingPlansViewPlansLabel')}
        </NavLink>
      </DialogContent>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalSelectionHostingPlans));
