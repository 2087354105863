import styled, { css } from 'styled-components';

export const Chip = styled.div`
  ${({ theme, isChecked }) => css`
    display: flex;
    gap: 4px;
    background-color: ${isChecked ? theme.v2.colors.feedback.activeUltraLight : theme.v2.colors.neutral.highLight};
    border-radius: 4px;
    color: ${isChecked ? theme.v2.colors.feedback.activeMedium : theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
    padding: ${isChecked ? '4px 8px' : '4px 8px 4px 4px'};
  `};
`;
