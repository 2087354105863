import React, { useContext, useState } from 'react';
import { withI18n } from 'react-i18next';
import {
  Button,
  withStyles,
  Chip,
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import { DNSContext } from '@/contexts/DNS/context';
import PortalAddDNS from '@/components/Dns/ZoneManager/AddRecordForm/PortalAddDNS';
import AddRecordForm from '@/components/Dns/ZoneManager/AddRecordForm';
import styles from './styles';

const RecordedItemBlock = ({
  classes, t, recordedItem, idx,
}) => {
  const {
    domainName,
    mobileItemRef,
    mobileListAmount,
    recordTypes,
    setExcludeRecordedDnsItem,
    setShowExcludeModalConfirmation,
    showExcludeModalConfirmation,
  } = useContext(DNSContext);

  const [showEditForm, setShowEditForm] = useState(false);
  return (
    <>
      <PortalAddDNS toggleAddRecordForm={showEditForm}>
        <AddRecordForm editing recordedItem={recordedItem} closeForm={() => setShowEditForm(false)} domainName={domainName} />
      </PortalAddDNS>

      <ul
        key={recordedItem.name}
        className={`${classes.table} ${idx % 2 === 0 ? classes.tableBackground : null}`}
        ref={idx === (mobileListAmount - 1) ? mobileItemRef : null}
      >

        <li className={classes.tableRow}>
          <div className={`${classes.tableHeader} ${classes.tableData}`}>
            {t('dns.zoneManager.recordList.listLabel.type')}
          </div>

          <div className={`${classes.tabeItem} ${classes.tableData}`}>
            <Chip
              className={classes.chipElement}
              color="primary"
              label={recordedItem.type}
            />
          </div>
        </li>

        <li className={classes.tableRow}>
          <div className={`${classes.tableHeader} ${classes.tableData}`}>
            {t('dns.zoneManager.recordList.listLabel.name')}
          </div>

          <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
            {recordedItem.name}
          </div>
        </li>

        <li className={classes.tableRow}>
          <div className={`${classes.tableHeader} ${classes.tableData}`}>
            {t('dns.zoneManager.recordList.listLabel.class')}
          </div>

          <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
            {recordedItem.class}
          </div>
        </li>

        <li className={classes.tableRow}>
          <div className={`${classes.tableHeader} ${classes.tableData}`}>
            {t('dns.zoneManager.recordList.listLabel.ttl')}
          </div>

          <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
            {recordedItem.ttl}
          </div>
        </li>

        {(recordedItem.type === 'A' || recordedItem.type === 'AAAA')
          && (
            <li className={classes.tableRow}>
              <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                {t('dns.zoneManager.recordList.listLabel.address')}
              </div>

              <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                {recordedItem.address}
              </div>
            </li>
          )
        }

        {(recordedItem.type === 'CAA')
          && (
            <>
              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.recordList.listLabel.flag')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.flag}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.recordList.listLabel.tag')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.tag}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.recordList.listLabel.value')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.value}
                </div>
              </li>
            </>
          )
        }

        {(recordedItem.type === 'CNAME')
          && (
            <>
              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.recordList.listLabel.cname')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.cname}
                </div>
              </li>
            </>
          )
        }

        {(recordedItem.type === 'MX')
          && (
            <>
              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeMxPriorityField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.preference}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeMxDestinationField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.exchange}
                </div>
              </li>
            </>
          )
        }

        {(recordedItem.type === 'TXT')
          && (
            <>
              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.recordList.listLabel.address')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.txt_data}
                </div>
              </li>
            </>
          )
        }

        {(recordedItem.type === 'SRV')
          && (
            <>
              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeSrvPriorityField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.priority}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeSrvPortField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.port}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeSrvWeightField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.weight}
                </div>
              </li>

              <li className={classes.tableRow}>
                <div className={`${classes.tableHeader} ${classes.tableData} ${classes.trunkateData}`}>
                  {t('dns.zoneManager.addRecord.form.label.typeSrvDestinationField')}
                </div>

                <div className={`${classes.tabeItem} ${classes.tableData} ${classes.trunkateData}`}>
                  {recordedItem.target}
                </div>
              </li>
            </>
          )
        }

        <li className={classes.buttonWrapper}>
          <Button
            className={classes.tableButton}
            disabled={!recordTypes.includes(recordedItem.type)}
            onClick={() => setShowEditForm(!showEditForm)}
          >
            <EditIcon />
            {t('dns.zoneManager.recordList.listLabel.editButton')}
          </Button>

          <Button
            className={classes.tableButton}
            disabled={!recordTypes.includes(recordedItem.type)}
            onClick={() => {
              setExcludeRecordedDnsItem(recordedItem);
              setShowExcludeModalConfirmation(!showExcludeModalConfirmation);
            }}
          >
            <DeleteIcon />
            {t('dns.zoneManager.recordList.listLabel.removeButton')}
          </Button>
        </li>
      </ul>
    </>
  );
};

RecordedItemBlock.propTypes = {
  recordedItem: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
};

export default (withI18n()((withStyles(styles)(RecordedItemBlock))));
