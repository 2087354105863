import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { videoClassesActionTypes } from './videoClasses.types';
import videoClassesActions from './videoClasses.actions';
import videoClassesAssembly from './videoClasses.assembly';
import { authActions } from '@/redux/modules/auth';
import logger from '@/utils/logger';
import { COUNTRY } from '@/config';

function* getLiveDataRequest() {
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const summary = yield select(state => state.summary);
  yield put(authActions.tokens.jwt.update());

  const request = {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
      'X-Client-ID': 2,
      'X-Brand': COUNTRY,
    },
    url: 'api/v3/lives',
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const liveData = videoClassesAssembly.receiveLive(response, summary.datecreated);

    yield put(videoClassesActions.live.set(liveData));
    yield put(videoClassesActions.live.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getLiveDataRequest', error);
    yield put(videoClassesActions.live.failure());
  }
}

function* sagas() {
  yield takeLatest(videoClassesActionTypes.REQUEST_LIVE_DATA, getLiveDataRequest);
}

export default sagas;
