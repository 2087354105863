import { collect } from '@/analytics';

import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: 'Wizard de DNS',
  ACTIONS: {
    PLAN_SELECTION_MODAL: 'Modal de seleção de plano',
    EXTERNAL_PLATFORM_MODAL: platformName => `Modal de configuração para ${platformName} (automático)`,
    OTHER_PLATFORM_MODAL: 'Modal de configuração para outro provedor',
    ALTER_HOSTING_PLATFORM_MODAL: 'Modal de edição de plataforma',
    DOMAIN_CONFIGURED: 'Tela final com domínio configurado',
    DOMAIN_LIST: 'Listagem de domínios',
    PLATFORM_DEFINITION: 'Definição de plataforma',
    ALTER_EMAIL_PLATFORM_MODAL: 'Modal alteração plataforma de e-mail',
    EMAIL_PLATFORM_MODAL: platformName => `Modal alterar e-mail para ${platformName}`,
    CUSTOM_EMAIL_PLATFORM: 'Modal configurar para outra plataforma de e-mail',
    CUSTOM_DNS_ZONE: (type) => {
      switch (type) {
        case HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA:
          return 'Modal de configuração para Loja Integrada';
        case HOSTING_PLATFORM_TYPES.NUVEMSHOP:
          return 'Modal de configuração para Nuvemshop';
        default:
          return `Modal de configuração para ${type}`;
      }
    },
  },
};

const dnsWizardButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_LIST,
  gaEventLabel: 'Botão Configurar Domínio na listagem',
});

const dnsWizardManageScreenButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_LIST,
  gaEventLabel: 'Botão Configurar Domínio em gerenciar',
});

const dnsWizardPlatformDefinitionHelpMaterialClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Material de ajuda',
});

const dnsWizardHostingPlatformsView = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Exibição tela Definição de plataforma',
});

const dnsWizardHostGatorCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card HG',
});

const dnsWizardLojaIntegradaCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card Loja Integrada',
});

const dnsWizardNuvemshopCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card Nuvemshop',
});

const dnsWizardShopifyCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card Shopify',
});

const dnsWizardDLojaCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card DLoja',
});

const dnsWizardExternalCardClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: `Card ${platformName}`,
});

const dnsWizardSkipWizardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Ir para configuração avançada (Zona de DNS)',
});

const dnsWizardPlanSelectClick = planName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLAN_SELECTION_MODAL,
  gaEventLabel: planName,
});

const dnsWizardPlanSelectionCheckboxClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLAN_SELECTION_MODAL,
  gaEventLabel: 'Checkbox "ir para zona de DNS"',
});

const dnsWizardClosePlanSelectionModal = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLAN_SELECTION_MODAL,
  gaEventLabel: 'Fechar modal de plano',
});

const dnsWizardConfirmPlanSelection = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLAN_SELECTION_MODAL,
  gaEventLabel: 'Confirmar configuração',
});

const dnsWizardHelpMaterialPlanSelection = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLAN_SELECTION_MODAL,
  gaEventLabel: 'Material de ajuda o que é propagação',
});

const dnsWizardAlterPlatformClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Alterar plataforma de hospedagem',
});

const dnsWizardAlterHostingPlanClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Edição de plano HG',
});

const dnsWizardShowDnsStatsClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Botão mostrar zona de DNS',
});

const dnsWizardCopyDnsZoneStatsClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Copiar zona de DNS',
});

const dnsWizardHelpMaterialDnsZoneStatsClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Material de ajuda propagação concluída',
});

const dnsWizardCustomConfigurationClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Card outro provedor',
});

const dnsWizardCloseCustomConfigurationClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.OTHER_PLATFORM_MODAL,
  gaEventLabel: 'Fechar modal de plano',
});

const dnsWizardConfirmCustomConfigurationClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.OTHER_PLATFORM_MODAL,
  gaEventLabel: 'Confirmar configuração',
});

const dnsWizardHelpMaterialCustomConfigurationClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.OTHER_PLATFORM_MODAL,
  gaEventLabel: 'Clique material de ajuda propagação',
});

const dnsWizardDeleteAdditionalNameServerClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.OTHER_PLATFORM_MODAL,
  gaEventLabel: 'Botão excluir servidor',
});

const dnsWizardHostGatorEditPlatformCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_HOSTING_PLATFORM_MODAL,
  gaEventLabel: 'Card HG',
});

const dnsWizardEditPlatformContinueClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_HOSTING_PLATFORM_MODAL,
  gaEventLabel: 'Continuar',
});

const dnsWizardCloseEditPlatformModal = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_HOSTING_PLATFORM_MODAL,
  gaEventLabel: 'Fechar modal',
});

const dnsWizardCustomEditPlatformCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_HOSTING_PLATFORM_MODAL,
  gaEventLabel: 'Card outro provedor',
});

const dnsWizardExternalEditPlatformCardClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_HOSTING_PLATFORM_MODAL,
  gaEventLabel: `Card ${platformName}`,
});

const dnsWizardCustomDnsZoneCloseClick = type => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_DNS_ZONE(type),
  gaEventLabel: 'Fechar modal de plano',
});

// CUSTOM DNS ZONE
const dnsWizardCustomDnsZoneConfigureButtonClick = type => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_DNS_ZONE(type),
  gaEventLabel: 'Confirmar configuração',
});

const dnsWizardCustomDnsZonePropagationHelpClick = type => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_DNS_ZONE(type),
  gaEventLabel: 'Clique material de ajuda propagação',
});

const dnsWizardCustomDnsZoneShowRecordsClick = type => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_DNS_ZONE(type),
  gaEventLabel: 'Clique botão mostrar registros padrões',
});

const dnsWizardCustomDnsZoneHideRecordsClick = type => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_DNS_ZONE(type),
  gaEventLabel: 'Clique botão fechar registros padrões',
});

const dnsWizardExternalPlatformModalCloseButtonClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EXTERNAL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Fechar modal de plano',
});

const dnsWizardExternalPlatformModalConfirmButtonClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EXTERNAL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Confirmar configuração',
});

const dnsWizardExternalPlatformModalEditNameServerButtonClick = (platformName, serverNumber) => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EXTERNAL_PLATFORM_MODAL(platformName),
  gaEventLabel: `Edição servidor ${serverNumber}`,
});

const dnsWizardExternalPlatformModalHelpLinkClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EXTERNAL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Clique material de ajuda propagação',
});

const dnsWizardBannerNewPlanClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Botão comprar plano HG',
});

const dnsWizardBannerUpgradePlanClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Botão upgrade de plano',
});

const dnsWizardEmailPlatformCpanelTitanCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'plataforma de e-mail HG - cPanel e Titan',
});

const dnsWizardEmailPlatformGmailCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Card Gmail',
});

const dnsWizardEmailPlatformCustomCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Card Outra plataforma de e-mail',
});

const dnsWizardBannerSeePlansClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.PLATFORM_DEFINITION,
  gaEventLabel: 'Botão ver planos HG',
});

const dnsWizardEmailPlatformTitanCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Card convite definição Titan',
});

const dnsWizardEmailPlatformCpanelCardClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Plataforma de e-mail cpanel',
});

const dnsWizardEmailModalHideRecords = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Botão fechar registros',
});

const dnsWizardEmailModalShowRecords = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Botão mostrar registros',
});

const dnsWizardEmailModalClose = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Fechar modal',
});

const dnsWizardEmailModalCheckbox = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Checkbox',
});

const dnsWizardEmailModalHelp = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Cliques material de ajuda',
});

const dnsWizardEmailModalConfigure = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.EMAIL_PLATFORM_MODAL(platformName),
  gaEventLabel: 'Botão alterar e-mail',
});

const dnsWizardAlterEmailPlatformCardClick = platformName => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_EMAIL_PLATFORM_MODAL,
  gaEventLabel: `Card ${platformName}`,
});

const dnsWizardAlterEmailClose = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_EMAIL_PLATFORM_MODAL,
  gaEventLabel: 'Fechar modal',
});

const dnsWizardAlterEmailContinueButton = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.ALTER_EMAIL_PLATFORM_MODAL,
  gaEventLabel: 'Botão continuar',
});

const dnsWizardAlterEmailCustomCloseClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_EMAIL_PLATFORM,
  gaEventLabel: 'Fechar modal',
});

const dnsWizardAlterEmailCustomCheckbox = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_EMAIL_PLATFORM,
  gaEventLabel: 'Checkbox',
});

const dnsWizardAlterEmailCustomHelpLink = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_EMAIL_PLATFORM,
  gaEventLabel: 'Material de ajuda',
});

const dnsWizardAlterEmailCustomConfigureButton = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.CUSTOM_EMAIL_PLATFORM,
  gaEventLabel: 'Botão ir para zona de DNS',
});

const dnsWizardAlterPlatformEmailButton = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
  gaEventLabel: 'Alterar plataforma de e-mail',
});

const dnsWizardEmailPlatformCpanelAndTitanCardClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTIONS.DOMAIN_CONFIGURED,
    gaEventLabel: 'Card convite definição cPanel e Titan',
  });
};

const dnsWizardEditDnsZone = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: 'Solução - Portal do Cliente Zona de DNS - Clique no cta',
    gaEventAction: 'Solução - Portal do Cliente Zona de DNS - Cta Editar zona avançada de DNS',
    gaEventLabel: 'Solução - Portal do Cliente Zona de DNS - Clique no cta Editar zona avançada de DNS',
  });
};

const dnsWizardContinueToEditDnsZone = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: 'Solução - Portal do Cliente Zona de DNS - Clique no cta',
    gaEventAction: 'Solução - Portal do Cliente Zona de DNS - Cta Ok, continuar para a zona de dns',
    gaEventLabel: 'Solução - Portal do Cliente Zona de DNS - Cta Ok, continuar para a zona de dns',
  });
};

const dnsWizardShowDnsZoneSettings = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: 'Solução - Portal do Cliente Zona de DNS - Clique no cta',
    gaEventAction: 'Solução - Portal do Cliente Zona de DNS - Cta Mostrar configuração manual da zona de dns',
    gaEventLabel: 'Solução - Portal do Cliente Zona de DNS - Cta Mostrar configuração manual da zona de dns',
  });
};

const dnsPropagationUnderstandTerm = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'propagation_understandtheterm',
});

const dnsUnpointedDomain = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'unpointeddomain_configurenow',
});

const dnsUnpointedDomainConfigureDomain = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'unpointeddomain_configurenow_configuredomain',
});

const domainNotContracted = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'tooltip_domainnotcontracted',
});

const bannerDomainNotContracted = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'banneralert_domainnotcontracted',
});

const buttonRegisterDomainNotContracted = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_register_domainnotcontracted',
});

const buttonCloseDomainNotContracted = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'close_button_domainnotcontracted',
});

const buttonHowToConfigure = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_how_to_configure',
});

const buttonGoToAnotherProvider = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_gotoanotherprovider',
});

const buttonAlreadyMadeChanges = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_already_made_all_the_changes',
});

const buttonCheckDomain = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_check_domain',
});

const buttonBackInstructions = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - banner de propagação de dns',
  gaEventAction: 'Solução - banner de propagação de dns',
  gaEventLabel: 'button_backto_instructions',
});

const eppKeyButtonDisplayed = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Botão apresentado com sucesso',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  apresentado com sucesso',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP- Botão de Chave EPP apresentado com sucesso',
});

const eppKeyButtonDisabled = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Botão apresentado desabilitado',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Botão desabilitado',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Botão apresentado desabilitado',
});

const eppKeyButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Clique no botão',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Clique no botão Gerar Chave EPP',
});

const eppKeyFirstModalButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 1',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP - Clique no botão',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 1 Clique no botão Gerar Chave EPP',
});

const eppKeySecondtModalButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 2',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP - Clique no botão',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 2 Clique no botão Gerar Chave EPP',
});

const eppKeySecondtModalCancelClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 2',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão Cancelar',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 2 Clique no cancelar',
});

const eppKeyThirdModalCopyCode = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 3',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão copiar código',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 3 clique no botão copiar código',
});

const eppKeyThirdModalOk = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 3',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão Ok, entendi',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 3 clique no botão Ok, entendi',
});

const eppKeyFourthModalOpenTicket = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 4',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão Abrir um chamado',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 4 clique no botão Abrir um chamado',
});

const eppKeyFourthModalReturn = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Portal do Cliente Chave EPP - Modal 4',
  gaEventAction: 'Solução - Portal do Cliente Chave EPP -  Clique no botão Voltar para gerenciar domínios',
  gaEventLabel: 'Solução - Portal do Cliente Chave EPP-  Modal 4 clique no botão Voltar para gerenciar domínios',
});

const changePlatformCtaClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Wizard de dns - Clique no cta',
  gaEventAction: 'Solução - Wizard de dns - Cta alterar plataforma',
  gaEventLabel: 'Solução - Wizard de dns - Clique no cta alterar plataforma',
});

const onlyDnsZoneCtaClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Wizard de dns - Clique no cta',
  gaEventAction: 'Solução - Wizard de dns - Cta sem hospedagem apenas zona de dns',
  gaEventLabel: 'Solução - Wizard de dns - Clique no cta sem hospedagem apenas zona de dns',
});

const continueCtaClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Wizard de dns - Clique no cta',
  gaEventAction: 'Solução - Wizard de dns - Cta continuar',
  gaEventLabel: 'Solução - Wizard de dns - Clique no cta continuar',
});

const configureCtaClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Wizard de dns - Clique no cta',
  gaEventAction: 'Solução - Wizard de dns - Cta configurar',
  gaEventLabel: 'Solução - Wizard de dns - Clique no cta configurar',
});

const keepConfigurationsCtaClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Wizard de dns - Clique no cta',
  gaEventAction: 'Solução - Wizard de dns - Cta mater configuração',
  gaEventLabel: 'Solução - Wizard de dns - Clique no cta manter configuração',
});

const analytics = {
  dnsWizard: {
    dnsWizardButtonClick,
    dnsWizardHostingPlatformsView,
    dnsWizardHostGatorCardClick,
    dnsWizardSkipWizardClick,
    dnsWizardPlanSelectClick,
    dnsWizardPlanSelectionCheckboxClick,
    dnsWizardClosePlanSelectionModal,
    dnsWizardConfirmPlanSelection,
    dnsWizardAlterPlatformClick,
    dnsWizardAlterHostingPlanClick,
    dnsWizardShowDnsStatsClick,
    dnsWizardCopyDnsZoneStatsClick,
    dnsWizardCustomConfigurationClick,
    dnsWizardCloseCustomConfigurationClick,
    dnsWizardConfirmCustomConfigurationClick,
    dnsWizardHelpMaterialCustomConfigurationClick,
    dnsWizardManageScreenButtonClick,
    dnsWizardPlatformDefinitionHelpMaterialClick,
    dnsWizardDeleteAdditionalNameServerClick,
    dnsWizardHostGatorEditPlatformCardClick,
    dnsWizardCloseEditPlatformModal,
    dnsWizardEditPlatformContinueClick,
    dnsWizardCustomEditPlatformCardClick,
    dnsWizardExternalEditPlatformCardClick,
    dnsWizardHelpMaterialPlanSelection,
    dnsWizardHelpMaterialDnsZoneStatsClick,
    dnsWizardCustomDnsZoneCloseClick,
    dnsWizardLojaIntegradaCardClick,
    dnsWizardNuvemshopCardClick,
    dnsWizardShopifyCardClick,
    dnsWizardDLojaCardClick,
    dnsWizardExternalCardClick,
    dnsWizardCustomDnsZoneConfigureButtonClick,
    dnsWizardCustomDnsZonePropagationHelpClick,
    dnsWizardCustomDnsZoneShowRecordsClick,
    dnsWizardCustomDnsZoneHideRecordsClick,
    dnsWizardExternalPlatformModalCloseButtonClick,
    dnsWizardExternalPlatformModalConfirmButtonClick,
    dnsWizardExternalPlatformModalEditNameServerButtonClick,
    dnsWizardExternalPlatformModalHelpLinkClick,
    dnsWizardBannerNewPlanClick,
    dnsWizardBannerUpgradePlanClick,
    dnsWizardBannerSeePlansClick,
    dnsWizardEmailPlatformCpanelTitanCardClick,
    dnsWizardEmailPlatformGmailCardClick,
    dnsWizardEmailPlatformCustomCardClick,
    dnsWizardEmailPlatformTitanCardClick,
    dnsWizardEmailPlatformCpanelCardClick,
    dnsWizardEmailModalHideRecords,
    dnsWizardEmailModalShowRecords,
    dnsWizardEmailModalClose,
    dnsWizardEmailModalCheckbox,
    dnsWizardEmailModalHelp,
    dnsWizardEmailModalConfigure,
    dnsWizardAlterEmailPlatformCardClick,
    dnsWizardAlterEmailClose,
    dnsWizardAlterEmailContinueButton,
    dnsWizardAlterEmailCustomCloseClick,
    dnsWizardAlterEmailCustomCheckbox,
    dnsWizardAlterEmailCustomHelpLink,
    dnsWizardAlterEmailCustomConfigureButton,
    dnsWizardAlterPlatformEmailButton,
    dnsWizardEmailPlatformCpanelAndTitanCardClick,
    dnsWizardEditDnsZone,
    dnsWizardContinueToEditDnsZone,
    dnsWizardShowDnsZoneSettings,
    dnsPropagationUnderstandTerm,
    dnsUnpointedDomain,
    dnsUnpointedDomainConfigureDomain,
    domainNotContracted,
    bannerDomainNotContracted,
    buttonRegisterDomainNotContracted,
    buttonCloseDomainNotContracted,
    buttonHowToConfigure,
    buttonGoToAnotherProvider,
    buttonAlreadyMadeChanges,
    buttonCheckDomain,
    buttonBackInstructions,
    changePlatformCtaClick,
    onlyDnsZoneCtaClick,
    continueCtaClick,
    configureCtaClick,
    keepConfigurationsCtaClick,
  },
  eppKey: {
    eppKeyButtonDisplayed,
    eppKeyButtonDisabled,
    eppKeyButtonClick,
    eppKeyFirstModalButtonClick,
    eppKeySecondtModalButtonClick,
    eppKeySecondtModalCancelClick,
    eppKeyThirdModalCopyCode,
    eppKeyThirdModalOk,
    eppKeyFourthModalOpenTicket,
    eppKeyFourthModalReturn,
  },
};

export default analytics;
