const styles = () => ({
  actionsWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '19px',
  },

  action: {
    marginBottom: '10px',
    marginTop: '5px',
    minWidth: '105px',

    '&:last-child': {
      marginLeft: '10px',
    },
  },
});

export default styles;
