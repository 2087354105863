import { EmailsActionTypes } from './email.types';

const setLoading = isLoading => ({ type: EmailsActionTypes.SET_LOADING, payload: { isLoading } });

const getEmailsRequest = () => ({ type: EmailsActionTypes.GET_EMAILS_REQUEST });
const getEmailsSuccess = () => ({ type: EmailsActionTypes.GET_EMAILS_SUCCESS });
const getEmailsFailure = () => ({ type: EmailsActionTypes.GET_EMAILS_FAILURE });

const emailsActivateRenewalRequest = (email, callBackFunction) => ({
  type: EmailsActionTypes.EMAIL_ACTIVATE_RENEWAL_REQUEST,
  payload: { email, callBackFunction },

});

const emailsActivateRenewalSuccess = () => ({
  type: EmailsActionTypes.EMAIL_ACTIVATE_RENEWAL_SUCCESS,
});

const emailsActivateRenewalFailure = () => ({
  type: EmailsActionTypes.EMAIL_ACTIVATE_RENEWAL_FAILURE,
});

const createEmailTrialRequest = (dataEmail, history) => ({
  type: EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_REQUEST,
  payload: {
    data: dataEmail,
    history,
  },
});

const createEmailTrialSuccess = () => ({
  type: EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_SUCCESS,
});

const createEmailTrialFailure = () => ({
  type: EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_FAILURE,
});

const updateDomainSetupRequest = (dataDomainSetup, callBackFunction) => ({
  type: EmailsActionTypes.UPDATE_DOMAIN_SETUP_REQUEST,
  payload: { dataDomainSetup, callBackFunction },

});

const updateDomainSetupSuccess = () => ({
  type: EmailsActionTypes.UPDATE_DOMAIN_SETUP_SUCCESS,
});

const updateDomainSetupFailure = () => ({
  type: EmailsActionTypes.UPDATE_DOMAIN_SETUP_FAILURE,
});

const getPaymentMethodsRequest = () => ({
  type: EmailsActionTypes.GET_PRO_EMAIL_PAYMENT_METHODS,
});

const getPaymentMethodsRequestSuccess = response => ({
  type: EmailsActionTypes.GET_PRO_EMAIL_PAYMENT_METHODS_SUCCESS,
  payload: { paymentMethods: response },
});

const getPaymentMethodsRequestFailure = () => ({
  type: EmailsActionTypes.GET_PRO_EMAIL_PAYMENT_METHODS_ERROR,
});

const requestTitanPremiumBuy = data => ({
  type: EmailsActionTypes.REQUEST_TITAN_PREMIUM_BUY_FAST_CHECKOUT,
  payload: data,
});

const errorTitanPremiumBuy = () => ({
  type: EmailsActionTypes.ERROR_TITAN_PREMIUM_BUY_FAST_CHECKOUT,
});

const receiveTitanPremiumBuy = response => ({
  type: EmailsActionTypes.RECEIVE_TITAN_PREMIUM_BUY_FAST_CHECKOUT,
  data: response,
});

const abortTitanPremiumBuy = () => ({
  type: EmailsActionTypes.ABORT_TITAN_PREMIUM_BUY_FAST_CHECKOUT,
});

const actions = {
  emails: {
    failure: getEmailsFailure,
    request: getEmailsRequest,
    success: getEmailsSuccess,

    createEmailTrial: {
      request: createEmailTrialRequest,
      success: createEmailTrialSuccess,
      failure: createEmailTrialFailure,
    },

    activateRenewal: {
      request: emailsActivateRenewalRequest,
      success: emailsActivateRenewalSuccess,
      failure: emailsActivateRenewalFailure,
    },
    updateDomainSetup: {
      request: updateDomainSetupRequest,
      success: updateDomainSetupSuccess,
      failure: updateDomainSetupFailure,
    },
    loading: {
      set: setLoading,
    },
    paymentMethods: {
      request: getPaymentMethodsRequest,
      success: getPaymentMethodsRequestSuccess,
      failure: getPaymentMethodsRequestFailure,
    },
    buyTitanPremium: {
      request: requestTitanPremiumBuy,
      success: receiveTitanPremiumBuy,
      failure: errorTitanPremiumBuy,
      abort: abortTitanPremiumBuy,
    },
  },
};

export default actions;
