import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 32px 0px 0px 0px;
    width: 100%;
    @media (max-width: ${theme.v2.breakpoints.md}){
      padding: 24px 0px 0px 0px;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0 0 24px 0px;
    @media (max-width: ${theme.v2.breakpoints.md}){
      font-size: ${theme.v2.font.sizes.sm};
    }
  `}
`;

export const CardsWrapper = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    justify-content: center;

    @media (max-width: ${theme.v2.breakpoints.md}){
      align-items: center;
      flex-direction: column;
      gap: 24px;
    }
  `}
`;
