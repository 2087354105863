import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, Typography, Collapse, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import { filterDomainNames, statusDomains } from '@/redux/actions/domains';
import InputField from '@/components/Fields/InputField';
import SelectCheckboxField from '@/components/Fields/SelectCheckboxField';
import { requestUpgradePlans } from '@/redux/actions/emails';
import { executeWithParams, executeGADisplayBannerFromDomains } from '@/utils/ThirdParties/tagManager';
import FilterButton from '@/components/Buttons/FilterButton';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import { REGISTER_DOMAIN_URL } from '@/config/urls/domainsUrls';
import BannerToEnableTitanTrial from '@/components/Email/BannerToEnableTitanTrial';
import ModalTitanTrialColorful from '@/components/Email/ModalTitanTrialColorful';
import { isBRServer } from '@/utils/Validators/validation';
import { ESSENTIALS_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import styles from './styles';
import { useHistory } from 'react-router';
import { queryParams } from '@/utils/Application/queryParams';
import { ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const FilterDomains = ({
  t, total, classes,
}) => {
  const selectedStatus = useSelector(state => state.domains.selectFilters.selectedStatus);
  const filterDomainStatus = useSelector(state => state.domains.selectFilters.domainStatus);
  const domainsElegible = useSelector(state => state.domains.domainsElegible);
  const history = useHistory();

  const enableRenderProEmail = false;
  const shouldRenderSmallBannerOffer = true;
  const enableTrialTitanByUrlParam = useFeatureIsOn(ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM);

  const [essentialData, setEssentialData] = useState({ name: '', price: '' });

  const [expand, setExpand] = useState(false);
  const [site, setSite] = useState('site');

  const isMobile = useMediaQuery('(max-width:1260px)');

  const allEmails = useSelector(state => state.emails.proEmailList);
  let domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');
  if (!enableRenderProEmail && domainsElegible.length) {
    domainWithoutEmail = domainsElegible.filter(item => item.status === 'Active');
  }
  const domainWithoutEmailFormattedToOptions = domainWithoutEmail.map(item => ({ value: item.id, label: `@${item.domain}` }));

  const [enableTrialState, setEnableTrialState] = useState({
    modalIsOpen: false,
    isEnabledByURLParam: false,
    hideBanner: true,
    currentDomain: {
      id: null,
      domain: null,
    },
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const enableTrialByBannerGAEventsByAction = useCallback((action) => {
    executeWithParams(
      'gaEvent',
      'e-mail',
      'Banner trial de Titan - aba de Domínios',
      action,
    );
  }, []);

  const onBannerLoaded = useCallback(() => {
    if (!isLoaded) {
      executeGADisplayBannerFromDomains();
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = useCallback(
    () => {
      enableTrialByBannerGAEventsByAction('Fechar modal');

      setEnableTrialState(previousEnableTrialState => ({
        ...previousEnableTrialState,
        modalIsOpen: false,
        currentDomain: { id: null, domain: null },
      }));
      if (enableTrialTitanByUrlParam && queryParams(history.location.search, 'dspActtrial')) {
        history.push(t('routes.domains'));
      }
    }, [setEnableTrialState, enableTrialByBannerGAEventsByAction, enableTrialTitanByUrlParam, history, t],
  );
  const handlerChangeBannerSelect = useCallback(({ value, label }) => {
    enableTrialByBannerGAEventsByAction('Selecionar domínio');

    setEnableTrialState(previousEnableTrialState => ({
      ...previousEnableTrialState,
      modalIsOpen: true,
      currentDomain: { id: value, domain: label },
    }));
  }, [setEnableTrialState, enableTrialByBannerGAEventsByAction]);

  const dispatch = useDispatch();

  const handleCollapse = () => {
    isMobile ? setExpand(false) : setExpand(true);
  };

  const getDataOfEssential = useCallback(() => dispatch(
    requestUpgradePlans(),
  ).then(({ data }) => {
    const [planData] = data.filter(plan => plan.id === ESSENTIALS_PLAN);
    if (planData) {
      const { name, configoptions } = planData;
      const [{ monthly: price }] = configoptions;

      const dataEssential = { name, price };

      if (isBRServer) {
        dataEssential.price = price.replace('.', ',');
      }

      setEssentialData(dataEssential);
    }
  }), [dispatch]);

  const displayTrialTitanModalByUrl = useCallback(() => {
    const domainByUrl = queryParams(history.location.search, 'dspActtrial');

    if (domainByUrl !== null && enableTrialTitanByUrlParam && !enableTrialState.modalIsOpen) {
      const domainObject = domainWithoutEmail.filter(item => item.domain === domainByUrl);
      const idDomain = domainObject !== undefined && domainObject.length > 0 ? domainObject[0].id : null;


      if (idDomain !== null && essentialData.price !== '') {
        setEnableTrialState(previousEnableTrialState => ({
          ...previousEnableTrialState,
          modalIsOpen: true,
          isEnabledByURLParam: true,
          currentDomain: { id: idDomain, domain: domainByUrl },
        }));
      }
    }
  }, [essentialData, history.location.search, domainWithoutEmail, enableTrialState.modalIsOpen, enableTrialTitanByUrlParam, setEnableTrialState]);

  useEffect(() => {
    displayTrialTitanModalByUrl();
  }, [displayTrialTitanModalByUrl]);

  useEffect(() => {
    setSite('');
    handleCollapse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (domainWithoutEmail.length > 0 && essentialData.price === '') {
      getDataOfEssential();
    }
  }, [getDataOfEssential, domainWithoutEmail.length, essentialData.price]);

  useEffect(() => {
    dispatch(filterDomainNames(site, selectedStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, selectedStatus]);

  const handleExpandCollapse = () => {
    setExpand(!expand);
  };

  const handleChange = (event) => {
    setSite(event.target.value);
  };

  const handleSelect = (event) => {
    dispatch(statusDomains(event.target.value));
    dispatch(filterDomainNames(site, selectedStatus));
  };

  if (isMobile
    && total <= 5
    && shouldRenderSmallBannerOffer
    && essentialData.name
    && domainWithoutEmail.length > 0
  ) {
    return (
      <div className={classes.wrapper}>
        <ModalTitanTrialColorful
          isOpen={enableTrialState.modalIsOpen}
          isEnabledByURLParam={enableTrialState.isEnabledByURLParam}
          onClose={handleCloseModal}
          selectedDomain={enableTrialState.currentDomain}
          essentialData={essentialData}
          offerContext={['domain_page_banner_trial_activation']}
          location="modalDomainList"
        />
        <BannerToEnableTitanTrial
          domainOptions={domainWithoutEmailFormattedToOptions}
          onDomainSelected={handlerChangeBannerSelect}
          productName={essentialData.name}
          onBannerLoaded={onBannerLoaded}
        />
      </div>
    );
  }

  return (
    <>

      <Card className={classes.card} data-id="page-filter-card">

        {!isMobile && (
          <div className={classes.startContentWrapper}>
            <Typography variant="h6" className={classes.listTitle} data-id="domain-page-title">{t('lists.domains')}</Typography>
            <FilterButton target={REGISTER_DOMAIN_URL} dataId="hire_domain_button">
              {t('hireDomain')}
            </FilterButton>
          </div>
        )}

        {total > 5 && (
          <div className={classes.filterContainer}>
            <Typography variant="h6" className={classes.filterTitle}>{t('filter.title')}</Typography>
            <Collapse in={expand || !isMobile} className={classes.collapseWrapper}>
              <div className={classes.root}>
                <InputField
                  className={classes.domainInput}
                  value={site}
                  onChange={event => handleChange(event)}
                  label={t('filter.domainName')}
                />
                <SelectCheckboxField
                  totalProducts={total}
                  className={classes.select}
                  filterStatus={filterDomainStatus}
                  type="domain"
                  filterTrans="domain"
                  selectType="status"
                  menuItemDefaultText={t('filter.allStatus')}
                  inputLabel={t('filter.status')}
                  selectValue={selectedStatus}
                  handleChangeSelectCheckbox={event => handleSelect(event)}
                  labelWidth={calcLabelWidth(t('filter.status'))}
                />
              </div>
            </Collapse>

            {isMobile && (
              <div className={classes.expandArrow}>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expand,
                  })}
                  onClick={() => handleExpandCollapse()}
                >
                  <ExpandMore className={classes.arrow} />
                </IconButton>
              </div>
            )}

          </div>
        )}
      </Card>

      {shouldRenderSmallBannerOffer
        && (essentialData.name && domainWithoutEmail.length > 0)
        && (
          <div className={classes.wrapper}>
            <ModalTitanTrialColorful
              isOpen={enableTrialState.modalIsOpen}
              isEnabledByURLParam={enableTrialState.isEnabledByURLParam}
              onClose={handleCloseModal}
              selectedDomain={enableTrialState.currentDomain}
              essentialData={essentialData}
              offerContext={['domain_page_banner_trial_activation']}
              location="modalDomainList"
            />
            <BannerToEnableTitanTrial
              domainOptions={domainWithoutEmailFormattedToOptions}
              onDomainSelected={handlerChangeBannerSelect}
              productName={essentialData.name}
              onBannerLoaded={onBannerLoaded}
            />
          </div>
        )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(withI18n()(FilterDomains));
