import React from 'react';
import * as Styles from './ModalContent.styles';
import { Button, IconLoading } from 'gatorcomponents';

const ModalContent = ({
  loading,
  title,
  icon,
  description,
  actions,
}) => {
  if (loading) {
    return (
      <Styles.ContentWrapper data-testid="ModalContent" isLoading={loading}>
        <Styles.LoadingContainer>
          <IconLoading />
          <Styles.LoadingText>
            {description}
          </Styles.LoadingText>
        </Styles.LoadingContainer>
      </Styles.ContentWrapper>
    );
  }

  return (
    <Styles.ContentWrapper data-testid="ModalContent">
      <Styles.MessageContainer>
        {title && (
          <Styles.Title data-testid="title">
            {title}
          </Styles.Title>
        )}

        <Styles.Description>
          {icon && (
            <Styles.IconWrapper>
              {icon}
            </Styles.IconWrapper>
          )}

          {description && (
            <Styles.DescriptionMessage data-testid="description">
              {description}
            </Styles.DescriptionMessage>
          )}
        </Styles.Description>

        {actions && actions.length && (
          <Styles.ActionContainer>
            {actions.map(action => (
              <Button
                key={action.label}
                {...action}
              />
            ))}
          </Styles.ActionContainer>
        )}
      </Styles.MessageContainer>
    </Styles.ContentWrapper>
  );
};

export default ModalContent;
