import { IconFeatured } from '@/icons';
import React, { useEffect, useState } from 'react';
import { Radio } from '../../inputs';
import * as Styles from './RadioOption.styles';

const RadioOption = ({
  testId = 'radio-option',
  title = 'Radio option title',
  basis = 'unset',
  maxHeight = '1000px',
  checked = false,
  onToggle,
  controlledByFather = false,
  featured = false,
  name = 'radio',
}) => {
  const [check, toggleCheck] = useState(checked);

  const handleClickToggle = () => {
    (!controlledByFather && !check) && toggleCheck(!check);
    (onToggle && !check) && onToggle({ checked: !check, name });
  };

  useEffect(() => {
    toggleCheck(checked);
  }, [checked]);

  return (
    <Styles.SlideToggleWrapper data-testid={testId} basis={basis} maxHeight={maxHeight} active={check}>
      <Styles.ToggleAction clickable onClick={handleClickToggle} data-testid={`${testId}-action`}>
        <Radio size="small" variant="v1" checked={check} onChange={handleClickToggle} testId={`${testId}-radio`} />

        <Styles.ToggleHeader>
          <Styles.Title data-testid={`${testId}-title`} active={check}>{title}</Styles.Title>
        </Styles.ToggleHeader>
      </Styles.ToggleAction>

      {featured && (
        <Styles.IconWrapper>
          <IconFeatured />
        </Styles.IconWrapper>
      )}
    </Styles.SlideToggleWrapper>
  );
};

export default RadioOption;
