export const OFFER_RETENTION_CANCEL_TYPES = {
  disable_auto_renewal: 'disable_auto_renewal',
  change_payment_cycle: 'change_payment_cycle',
};


export const RETENTION_TYPES = {
  disable_auto_renewal: 0,
  change_payment_cycle: 1,
};
