import {
  REQUEST_CREDIT_CARD, RECEIVE_CREDIT_CARD, DELETE_CREDIT_CARD_START,
  DELETE_CREDIT_CARD_SUCCESS, SAVE_CREDIT_CARD_START, SAVE_CREDIT_CARD_SUCCESS,
  SAVE_CREDIT_CARD_ERROR,
} from '@/redux/actions/actionsTypes';


const initialState = {
  cardType: '',
  cardLastFour: '',
  expDate: '',
  ccvnumber: '',
  loading: false,
  error: false,
  ccname: '',
};

export default function creditCard(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_CREDIT_CARD:
      return {
        ...state,
        loading: false,
        id: action.creditCard.id,
        cardType: action.creditCard.cardtype,
        cardLastFour: action.creditCard.cardlastfour,
        expDate: action.creditCard.expdate,
        ccname: action.creditCard.card_holder,
      };
    case DELETE_CREDIT_CARD_START:
    case SAVE_CREDIT_CARD_START:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...initialState,
      };
    case SAVE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        cardType: action.data.cctype,
        cardLastFour: action.data.cardlastfour,
        expDate: action.data.expdate,
        ccvnumber: action.data.ccvnumber,
        ccname: action.data.ccname,
      };
    case SAVE_CREDIT_CARD_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
