export const SecurityActionTypes = {
  SITELOCK_SCAN_DETAILS_LOADING: 'SITELOCK_SCAN_DETAILS_LOADING',
  SITELOCK_SCAN_DETAILS_REQUEST: 'SITELOCK_SCAN_DETAILS_REQUEST',
  SITELOCK_SCAN_DETAILS_SUCCESS: 'SITELOCK_SCAN_DETAILS_SUCCESS',
  SITELOCK_SCAN_DETAILS_FAILURE: 'SITELOCK_SCAN_DETAILS_FAILURE',
  SET_SITELOCK_SCAN_DETAILS: 'SET_SITELOCK_SCAN_DETAILS',
  UPDATE_SEAL: 'UPDATE_SEAL',
  REQUEST_SEAL: 'REQUEST_SEAL',
  SUCCESS_REQUEST_SEAL: 'SUCCESS_REQUEST_SEAL',
  FAILURE_REQUEST_SEAL: 'FAILURE_REQUEST_SEAL',
  SUCCESS_UPDATE_SEAL: 'SUCCESS_UPDATE_SEAL',
  FAILURE_UPDATE_SEAL: 'FAILURE_UPDATE_SEAL',
  SET_SEAL: 'SET_SEAL',
  SET_SEAL_LOADING_STATUS: 'SET_SEAL_LOADING_STATUS',
  SET_SEAL_SAVING_STATUS: 'SET_SEAL_SAVING_STATUS',
  SITELOCK_SCAN_LIST_LOADING: 'SITELOCK_SCAN_LIST_LOADING',
  SITELOCK_SCAN_LIST_REQUEST: 'SITELOCK_SCAN_LIST_REQUEST',
  SITELOCK_SCAN_LIST_SUCCESS: 'SITELOCK_SCAN_LIST_SUCCESS',
  SITELOCK_SCAN_LIST_FAILURE: 'SITELOCK_SCAN_LIST_FAILURE',
  SET_SITELOCK_SCAN_LIST: 'SET_SITELOCK_SCAN_LIST',
  SET_UPGRADE_SITELOCK_LOADING: 'SET_UPGRADE_SITELOCK_LOADING',
  REQUEST_UPGRADE_SITELOCK: 'REQUEST_UPGRADE_SITELOCK',
  SUCCESS_UPGRADE_SITELOCK: 'SUCCESS_UPGRADE_SITELOCK',
  FAILURE_UPGRADE_SITELOCK: 'FAILURE_UPGRADE_SITELOCK',
  SET_UPGRADE_SITELOCK: 'SET_UPGRADE_SITELOCK',
  UPDATE_CONFIGURATIONS_LOADING: 'UPDATE_CONFIGURATIONS_LOADING',
  REQUEST_UPDATE_CONFIGURATIONS: 'REQUEST_UPDATE_CONFIGURATIONS',
  SUCCESS_UPDATE_CONFIGURATIONS: 'SUCCESS_UPDATE_CONFIGURATIONS',
  FAILURE_UPDATE_CONFIGURATIONS: 'FAILURE_UPDATE_CONFIGURATIONS',
  GET_CONFIGURATIONS_LOADING: 'GET_CONFIGURATIONS_LOADING',
  REQUEST_GET_CONFIGURATIONS: 'REQUEST_GET_CONFIGURATIONS',
  SET_SITELOCK_CONFIGURATIONS: 'SET_SITELOCK_CONFIGURATIONS',
  SUCCESS_GET_CONFIGURATIONS: 'SUCCESS_GET_CONFIGURATIONS',
  FAILURE_GET_CONFIGURATIONS: 'FAILURE_GET_CONFIGURATIONS',
  REQUEST_SITELOCK_SSO: 'REQUEST_SITELOCK_SSO',
  SITELOCK_SSO_SUCCESS: 'SITELOCK_SSO_SUCCESS',
  SITELOCK_SSO_FAILURE: 'SITELOCK_SSO_FAILURE',
  SET_SITELOCK_SSO_LOADING: 'SET_SITELOCK_SSO_LOADING',
  REQUEST_CHECK_NEW_SITELOCK: 'REQUEST_CHECK_NEW_SITELOCK',
  SUCCESS_CHECK_NEW_SITELOCK: 'SUCCESS_CHECK_NEW_SITELOCK',
  FAILURE_CHECK_NEW_SITELOCK: 'FAILURE_CHECK_NEW_SITELOCK',
  SET_CHECK_NEW_SITELOCK: 'SET_CHECK_NEW_SITELOCK',
  REQUEST_SAW_NEW_SITELOCK: 'REQUEST_SAW_NEW_SITELOCK',
  SUCCESS_SAW_NEW_SITELOCK: 'SUCCESS_SAW_NEW_SITELOCK',
  FAILURE_SAW_NEW_SITELOCK: 'FAILURE_SAW_NEW_SITELOCK',
};
