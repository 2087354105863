import React from 'react';
import {
  CardContent, Card, withStyles, Typography,
} from '@material-ui/core';

import Logo from '@/media/logo/LogoMarca';
import styles from './styles';


const PhoneCard = ({
  classes,
  title,
  subtitle,
  children,
}) => (
  <>
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Logo />
        <div className={classes.titleWrapper}>
          <h1 className={classes.title}>{title}</h1>
          <Typography>{subtitle}</Typography>
        </div>
        <div className={classes.contentWrapper}>
          {children}
        </div>
      </CardContent>
    </Card>
  </>
);

export default withStyles(styles)(PhoneCard);
