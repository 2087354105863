import React, { useState, useEffect } from 'react';
import {
  Typography,
  withStyles,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { buyAcademyPass } from '@/redux/actions/academyPass';
import { subscribeAcademyFooterClick, paymentMethodChangeEvent } from '@/utils/ThirdParties/tagManager';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { BRASPAG_PARC_1X, GATORPAY_BOLETO, GATORPAY_PAYPAL } from '@/config/billing/paymentMethods';
import { ACADEMY_PASS_PRODUCT_ID } from '@/config/products/academypass';
import styles from './styles';

const CtaFooter = ({ classes, t }) => {
  const HG_ACADEMY_PAYMENT_LOCATION_FOOTER = 'footer';

  const dispatch = useDispatch();
  const { price, paymentMethods } = useSelector(state => state.academyPass.cta);

  const [fastcheckout, setFastCheckout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0 && paymentMethods[0].length > 0) {
      const firstPayment = paymentMethods[0];
      const newCheckout = firstPayment[0];
      setFastCheckout(newCheckout);
    }
  }, [paymentMethods]);

  const sortedPayments = [
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === GATORPAY_BOLETO)),
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === BRASPAG_PARC_1X)),
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === GATORPAY_PAYPAL)),
  ];

  const handleBuy = () => {
    setIsLoading(true);
    dispatch(buyAcademyPass({ planId: ACADEMY_PASS_PRODUCT_ID.br, paymentMethod: fastcheckout, paymentLocation: HG_ACADEMY_PAYMENT_LOCATION_FOOTER }));
  };

  const handleChangeMethod = (payment) => {
    setFastCheckout(payment.value);
  };

  const handleClick = () => {
    handleBuy();
    subscribeAcademyFooterClick();
    paymentMethodChangeEvent(fastcheckout);
  };

  return (
    <div className={classes.wrapper} data-testid="cta-footer">
      <div className={classes.typography}>
        <Typography className={classes.title}>{t('academypass.cta.footer.productOffer')}</Typography>
        {parseFloat(price) > 0 && (
        <Typography className={classes.priceDiscount} data-testid="footer-price-tag">
          {formatCurrency(price)}
          <span>{t('academypass.cta.footer.month')}</span>
        </Typography>
        )}
      </div>
      <div className={classes.rcol}>
        <Select
          value={fastcheckout}
          onChange={e => handleChangeMethod(e.target)}
          className={classes.select}
          input={<Input value={fastcheckout} name="paymentMethod" />}
          data-testid="footer-payment-select"
          disabled={isLoading}
        >
          {sortedPayments && sortedPayments[0] !== null && sortedPayments.map(paymentMethod => (
            paymentMethod && paymentMethod.length
            && <MenuItem key={sortedPayments[0]} value={paymentMethod[0]}>{t(paymentMethod[0])}</MenuItem>
          ))}
        </Select>
        <PrimaryButton onClick={handleClick} onLoading={isLoading} data-testid="footer-buy-btn">
          {t('academypass.cta.footer.buyBtn')}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(CtaFooter));
