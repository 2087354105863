import { Modal } from '@/pages/common';
import React, { useEffect } from 'react';
import sitelock from '@/media/security/sitelock.svg';
import * as Styles from './SitelockProvisionedModal.styles';
import useLocale from '@/hooks/useLocale';
import { Button, IconCheck } from 'gatorcomponents';
import { useHistory } from 'react-router';
import { securityActions } from '@/redux/modules';
import { useDispatch } from 'react-redux';
import withErrorBoundary from '@/utils/errorBoundary/errorBoundary.jsx';
import logger from '@/utils/logger';
import { sitelockAnalytics } from '@/analytics/security/sitelock';

const SitelockProvisionedModal = ({ setSiteLockModalIsClosed }) => {
  const history = useHistory();
  const { security: securityLocale, routes: routesLocale } = useLocale();
  const { provisionatedModal: provisionatedModalLocale } = securityLocale;
  const dispatch = useDispatch();

  useEffect(() => {
    sitelockAnalytics.sitelockFreeModal.show();
  }, []);

  const handleView = (redirect) => {
    try {
      if (!redirect) {
        sitelockAnalytics.sitelockFreeModal.close();
      }
      dispatch(securityActions.newSiteLock.saw.request());
      setSiteLockModalIsClosed(true);
    } catch (e) {
      logger.error('Error on SitelockProvisionedModal - method handleView', e);
    }
  };

  const handleClick = () => {
    try {
      sitelockAnalytics.sitelockFreeModal.redirect();
      history.push(`${routesLocale.security}`);
      handleView(true);
      setSiteLockModalIsClosed(true);
    } catch (e) {
      logger.error('Error on SitelockProvisionedModal - method handleClick', e);
    }
  };

  return (
    <Modal maxWidth="800" onClose={handleView} variant="regular">
      <Styles.LogoWrapper>
        <Styles.CustomTag>
          {provisionatedModalLocale.tag}
        </Styles.CustomTag>

        <Styles.Logo src={sitelock} />
      </Styles.LogoWrapper>

      <Styles.TitleWrapper>
        {provisionatedModalLocale.title}
      </Styles.TitleWrapper>

      <Styles.DescriptionWrapper>
        {provisionatedModalLocale.description}
      </Styles.DescriptionWrapper>

      <Styles.List>
        {provisionatedModalLocale.items.map(item => (
          <Styles.ListItem key={item}>
            <IconCheck color="primaryDark" />
            {item}
          </Styles.ListItem>
        ))}
      </Styles.List>

      <Styles.ButtonWrapper>
        <Button
          onClick={handleClick}
          label={provisionatedModalLocale.cta}
          size="large"
        />
      </Styles.ButtonWrapper>
    </Modal>
  );
};

export default withErrorBoundary(SitelockProvisionedModal, 'SitelockProvisionedModal');
