import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
  margin: 0 40px;
  padding: 0px 0px 40px;


    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color:${theme.v2.colors.white.primary};
    padding: 24px;
  `}
`;

export const TabsWrapper = styled.div`
    > div button {
      div {
        padding: 0;
        margin: 0;
        width: fit-content;
      }
    }
`;
