import {
  REQUEST_INVOICES, RECEIVE_INVOICES, REQUEST_INVOICE_DETAILS, RECEIVE_INVOICE_DETAILS,
  REQUEST_SECOND_INVOICE, UPDATE_UNPAID_INVOICES, UPDATE_PAID_INVOICES, RECEIVE_UNPAID_INVOICES,
  REQUEST_BOLETO_URL, RECEIVE_BOLETO_URL, REQUEST_BOLETO_VALIDATE_ADDRESS,
  RECEIVE_BOLETO_VALIDATE_ADDRESS, ERROR_BOLETO_VALIDATE_ADDRESS,
  ERROR_BOLETO_URL,
  ERROR_INVOICES,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  loading: false,
  loadingDetails: false,
  boletoDataCheck: {
    loading: false,
    invoiceId: '',
    errors: [],
    urlBoleto: '',
  },
  loadingByStatus: {
    Unpaid: false,
    Paid: false,
    Cancelled: false,
  },
  byStatus: {
    Unpaid: [],
    Paid: [],
    Cancelled: [],
  },
  paginationData: {},
  loadedDate: {},
  total_invoice: 0,
  sum: 0,
  selectFilters: {
    unpaidStatus: [
      'unpaid',
      'paymentPending',
      'unpaidExpired',
    ],
    cancelledStatus: [
      'cancelled',
      'refunded',
    ],
  },
};


export default function invoices(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SECOND_INVOICE:
    case REQUEST_INVOICES:
      return {
        ...state,
        loadingByStatus: {
          ...state.loadingByStatus,
          [action.data]: true,
        },
        loading: true,

      };
    case REQUEST_INVOICE_DETAILS:
      return {
        ...state,
        loadingDetails: true,
      };
    case RECEIVE_INVOICES:
      return {
        ...state,
        loading: false,
        loadingByStatus: {
          ...state.loadingByStatus,
          [action.loadingByStatus]: false,
        },
        byStatus: {
          ...state.byStatus,
          ...action.byStatus,
        },
        paginationData: {
          ...state.paginationData,
          ...action.paginationData,
        },
        loadedDate: {
          ...state.loadedDate,
          ...action.loadedDate,
        },
      };
    case RECEIVE_INVOICE_DETAILS: {
      const byStatusReceived = state.byStatus;

      let invoiceStatus = '';
      switch (action.invoice.status) {
        case 'Payment Pending':
          invoiceStatus = 'Unpaid';
          break;
        case 'Refunded':
          invoiceStatus = 'Cancelled';
          break;
        default:
          invoiceStatus = action.invoice.status;
          break;
      }

      // find index position of element to update in Array state.invoices.byStatus
      const index = byStatusReceived[invoiceStatus].findIndex(item => item.id === action.invoice.id);
      const itemToAddDetails = byStatusReceived[invoiceStatus][index];

      if (itemToAddDetails.details) {
        itemToAddDetails.details = {
          ...itemToAddDetails.details,
          ...action.invoice,
        };
      } else {
        itemToAddDetails.details = action.invoice;
      }

      // update Array state.invoices.byStatus with details
      byStatusReceived[invoiceStatus][index] = itemToAddDetails;

      return {
        ...state,
        loadingDetails: false,
        byStatus: {
          ...state.byStatus,
          byStatusReceived,
        },
        paginationData: {
          ...state.paginationData,
        },
      };
    }
    case RECEIVE_UNPAID_INVOICES:
      return {
        ...state,
        ...action.data,
        loadedDate: {
          ...state.loadedDate,
          invoiceMessage: action.invoiceMessage,
        },
      };
    case UPDATE_UNPAID_INVOICES:
      return {
        ...state,
        byStatus: {
          ...state.byStatus,
          Unpaid: action.data,
        },
      };
    case UPDATE_PAID_INVOICES:
      return {
        ...state,
        byStatus: {
          ...state.byStatus,
          Paid: action.data,
        },
      };
    case REQUEST_BOLETO_URL:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          invoiceId: action.invoiceId,
          loading: true,
        },
      };
    case RECEIVE_BOLETO_URL:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          urlBoleto: `${action.dataCheck.data.url}`,
          loading: false,
          errors: action.dataCheck.data.errors || [],
        },
      };
    case ERROR_BOLETO_URL:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          loading: false,
          errors: action.dataCheck.data.errors || [],
        },
      };
    case REQUEST_BOLETO_VALIDATE_ADDRESS:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          loading: true,
        },
      };
    case RECEIVE_BOLETO_VALIDATE_ADDRESS:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          loading: false,
        },
      };
    case ERROR_BOLETO_VALIDATE_ADDRESS:
      return {
        ...state,
        boletoDataCheck: {
          ...state.boletoDataCheck,
          loading: false,
        },
      };
    case ERROR_INVOICES:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
