export const SITELOCK_PANEL = 'https://secure.sitelock.com/login';

export const SITELOCK_BUY_URL = 'https://www.hostgator.com.br/sitelock';

export const SITELOCK_SHIELD_IMAGE = 'http://shield.sitelock.com/shield/';

export const SITELOCK_HELP_URL = 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000393034-Como-inserir-no-site-o-selo-de-seguran%C3%A7a-do-SiteLock-';

export const SITELOCK_SMARTSCAN_HELP_URL = 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000393034-Como-inserir-no-site-o-selo-de-seguran%C3%A7a-do-SiteLock-';

export const REMOVE_MALICIOUS_CODE = 'https://suporte.hostgator.com.br/hc/pt-br/articles/115002001314';

export const FTP_ACCESS = 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000742474-Acesso-FTP-Informa%C3%A7%C3%B5es-gerais';
