import styled, { css } from 'styled-components';

export const RefreshPageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: 74px 85px 66px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    text-align: center;
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
      align-items: center;
      background-color: ${theme.v2.colors.brand.primaryUltralight};
      border-radius: 100%;
      display: flex;
      height: 56px;
      justify-content: center;
      width: 56px;
  `}
`;
