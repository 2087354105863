export const defaultValues = {
  typeWebsite: '',
  othertypeWebsite: '',
  typePlatform: '',
  othertypePlatform: '',
};

export const translateOptions = t => [
  {
    name: 'typeWebsite',
    label: t('customerProfileSurvey.question-1.label'),
    otherLabel: t('customerProfileSurvey.question-1.otherLabel'),
    options: [
      t('customerProfileSurvey.question-1.options.1'),
      t('customerProfileSurvey.question-1.options.2'),
      t('customerProfileSurvey.question-1.options.3'),
      t('customerProfileSurvey.question-1.options.4'),
      t('customerProfileSurvey.question-1.options.5'),
      t('customerProfileSurvey.question-1.options.6'),
      t('customerProfileSurvey.question-1.options.7'),
    ],
  },
  {
    name: 'typePlatform',
    label: t('customerProfileSurvey.question-2.label'),
    otherLabel: t('customerProfileSurvey.question-2.otherLabel'),
    options: [
      t('customerProfileSurvey.question-2.options.1'),
      t('customerProfileSurvey.question-2.options.2'),
      t('customerProfileSurvey.question-2.options.3'),
      t('customerProfileSurvey.question-2.options.4'),
      t('customerProfileSurvey.question-2.options.5'),
    ],
  },
];

export const makeAnswers = (data, options) => {
  const answers = {};
  options.forEach((option) => {
    answers[option.label] = data[`other${option.name}`] || data[option.name];
  });
  return answers;
};

export const getLastSharedBoarding = (boarding) => {
  const sharedBoarding = boarding.filter(boarding => boarding.isShared);
  const hasSharedBoarding = sharedBoarding.length > 0;
  const getLastSharedBoarding = sharedBoarding[sharedBoarding.length - 1] || {};

  return {
    hasSharedBoarding,
    onboardingFilter: getLastSharedBoarding,
  };
};

const skipLabel = 'skipSurveyForAdm';

export const skipSurveyLocalStorage = {
  get: () => localStorage.getItem(skipLabel),
  set: () => localStorage.setItem(skipLabel, true),
  remove: () => localStorage.removeItem(skipLabel),
};
