import React, { Component } from 'react';


class TicketDragAndDropUpload extends Component {
  dropRef = React.createRef()

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const element = document.getElementById('dragarea');
    element.classList.add('over');
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const element = document.getElementById('dragarea');
    element.classList.remove('over');
  }

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleDrop } = this.props;
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
    const element = document.getElementById('dragarea');
    element.classList.remove('over');
  }


  render() {
    const { children } = this.props;
    return (
      <div
        style={{ display: 'inline-block', position: 'relative' }}
        ref={this.dropRef}
      >
        {children}
      </div>
    );
  }
}
export default TicketDragAndDropUpload;
