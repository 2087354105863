import React from 'react';
import * as Style from './IconDomain.styles';

const IconDomain = ({ testId = 'IconHosting' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="icon-domínio" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path id="Caminho_238842" data-name="Caminho 238842" d="M332.8,2961.2v30.232h32Z" transform="translate(-332.801 -2959.431)" fill="#ffd54c" />
      <g id="Grupo_34255" data-name="Grupo 34255" transform="translate(2.399)">
        <g id="Elipse_1051" data-name="Elipse 1051" transform="translate(0.001)" fill="none" stroke="#4a4a4a" strokeWidth="2">
          <ellipse cx="14.8" cy="14.8" rx="14.8" ry="14.8" stroke="none" />
          <ellipse cx="14.8" cy="14.8" rx="13.8" ry="13.8" fill="none" />
        </g>
        <g id="Elipse_1052" data-name="Elipse 1052" transform="translate(8.001)" fill="none" stroke="#4a4a4a" strokeWidth="2">
          <ellipse cx="6.8" cy="14.8" rx="6.8" ry="14.8" stroke="none" />
          <ellipse cx="6.8" cy="14.8" rx="5.8" ry="13.8" fill="none" />
        </g>
        <path id="Caminho_218165" data-name="Caminho 218165" d="M-11801.4,3883.5h28.674" transform="translate(11801.396 -3869.125)" fill="none" stroke="#4a4a4a" strokeWidth="2" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDomain;
