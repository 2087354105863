const styles = theme => ({
  zoneManager: {
    width: '100%',
  },
  zoneTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '29px',
    fontWeight: 300,
    marginBottom: 10,
  },
  explanationWrapper: {
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    textTransform: 'unset',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  explanation: {
    color: theme.color.dustyGgray,
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 14,
  },
  explanationCTA: {
    color: theme.color.indigo,
  },
  explanationReadLess: {
    background: theme.color.white,
    color: theme.color.indigo,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '22px',
    padding: 0,
    textAlign: 'center',
    textDecoration: 'underline',
    textTransform: 'unset',
    width: 'unset',
  },
  dnsWizardLegacyDnsTableButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
});

export default styles;
