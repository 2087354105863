import {
  REQUEST_DOMAINS, RECEIVE_DOMAINS, FILTER_DOMAINS,
  SELECT_STATUS_DOMAINS, REQUEST_DOMAIN_ELEGIBLE, REQUEST_DOMAIN_DETAIL, RECEIVE_DOMAIN_ELEGIBLE,
  RECEIVE_DOMAIN_DETAIL, RECEIVE_DOMAIN_DETAIL_RENEWAL, REQUEST_DOMAIN_RENEWAL, RECEIVE_DOMAIN_ITEM_RENEWAL,
  REQUEST_DOMAINS_VERIFICATION_PENDING, RECEIVE_DOMAINS_VERIFICATION_PENDING,
  ERROR_DOMAINS_VERIFICATION_PENDING, REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL,
  RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL, ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL, ABORT_REQUEST_DOMAINS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  list: [],
  bkpList: [],
  selectFilters: {
    domainStatus: [],
    selectedStatus: [],
  },
  total: 0,
  loadedDate: null,
  detail: {
    id: '',
    loading: false,
    loadingRenewal: false,
    domain: '',
    registrationDate: '',
    firstPaymentAmount: '',
    paymentMethod: '',
    recurringAmount: '',
    registrar: '',
    registrationPeriod: 1,
    billingCycle: '',
    expiryDate: '',
    nextInvoiceDate: '',
    status: '',
    doNotRenew: 0,
    nextDueDate: '',
    actions: [],
  },
  domainsElegible: [],
  verificationPending: {
    loadingVerification: false,
    list: [],
  },
  emailVerification: {
    emailVerified: false,
    success: null,
    loadingEmailVerification: false,
  },
};

export default function domains(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DOMAINS:
      return {
        ...state,
        loading: true,
      };
    case ABORT_REQUEST_DOMAINS:
      if (action.payload.forceLoadedDomains) {
        return {
          ...state,
          loadedDomains: true,
        };
      }
      return {
        ...state,
      };
    case RECEIVE_DOMAINS:
      return {
        ...state,
        loading: false,
        list: action.sortedDomains,
        loadedDomains: true,
        bkpList: action.bkpList,
        pagination: action.pagination,
        selectFilters: {
          ...state.selectFilters,
          domainStatus: action.filterDomainStatus,
          selectedStatus: action.filterSelectedStatus,
        },
        total: action.total,
        loadedDate: action.loadedDate,
      };
    case FILTER_DOMAINS:
      return {
        ...state,
        list: action.filterDomain,
      };
    case SELECT_STATUS_DOMAINS:
      return {
        ...state,
        selectFilters: {
          ...state.selectFilters,
          selectedStatus: action.domainStatus,
        },
      };
    case REQUEST_DOMAIN_ELEGIBLE:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_DOMAIN_ELEGIBLE:
      return {
        ...state,
        domainsElegible: action.domainsElegible,
        loading: false,
      };
    case REQUEST_DOMAIN_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case RECEIVE_DOMAIN_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.domainDetail,
          loading: false,
          actions: action.domainActions,
        },
      };

    case REQUEST_DOMAIN_RENEWAL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loadingRenewal: true,
        },
      };
    case RECEIVE_DOMAIN_DETAIL_RENEWAL:
      return {
        ...state,
        list: action.domains,
        detail: {
          ...state.detail,
          loadingRenewal: false,
          doNotRenew: action.domainRenewal.doNotRenew,
        },
      };
    case RECEIVE_DOMAIN_ITEM_RENEWAL:
      return {
        ...state,
        list: action.domains,
        detail: {
          ...state.detail,
          loadingRenewal: false,
        },
      };
    case REQUEST_DOMAINS_VERIFICATION_PENDING:
      return {
        ...state,
        verificationPending: {
          ...state.verificationPending,
          loadingVerification: true,
        },
      };
    case RECEIVE_DOMAINS_VERIFICATION_PENDING:
      return {
        ...state,
        verificationPending: {
          ...state.verificationPending,
          loadingVerification: false,
          list: action.domainsVerificationPending,
        },
      };
    case ERROR_DOMAINS_VERIFICATION_PENDING:
      return {
        ...state,
        verificationPending: {
          loadingVerification: false,
        },
      };

    case REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL:
      return {
        ...state,
        emailVerification: {
          // ...state.emailVerification,
          loadingEmailVerification: true,
        },
      };

    case RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL:
      return {
        ...state,
        emailVerification: {
          ...state.emailVerification,
          loadingEmailVerification: false,
          success: true,
          emailVerified: action.domainEmailVerified,
        },
      };
    case ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL:
      return {
        ...state,
        emailVerification: {
          ...state.emailVerification,
          loadingEmailVerification: false,
          success: false,
        },
      };
    default:
      return state;
  }
}
