import styled, { css } from 'styled-components';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    flexGrow: 1,
    padding: 40,
  },
});

export const Wrapper = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    padding: 0px 40px 40px;

    @media (max-width: ${theme.breakpoints.sm}) {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`;

export default styles;
