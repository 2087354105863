const styles = theme => ({
  MailBoxesWrapper: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: 12,
    maxWidth: '829px',
    padding: 24,
  },

  WrapperTitle: {
    color: theme.color.tundora,
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '22px',
    marginBottom: '12px',
  },
});
export default styles;
