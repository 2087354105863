import { Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Info as InfoIcon,
} from '@material-ui/icons';
import { differenceInCalendarDays } from 'date-fns';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDate } from '@/utils/Formatters/formatDate';
import ModalChangeInboxAmount from '@/components/Modal/ModalChangeInboxAmount';
import { Button } from '@/pages/common';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import MemorialMessage from '../../MemorialMessage/MemorialMessage';
import styles from './styles';

const DescriptionItems = ({
  t,
  classes,
  multiple,
  paymentCycle,
  plan,
  newNextDueDate,
  summaryCalcs,
  showMonthlyCycle,
  domainObject,
  billingcycle,
  calledFrom,
  newAmount,
  setNewAmount,
}) => {
  const today = new Date();
  const history = useHistory();
  const nextDueDate = new Date(`${domainObject.nextduedate} 00:00:00`);
  const dateDiff = differenceInCalendarDays(nextDueDate, today);

  const memorialEnabled = true;
  const [showModal, toggleShowModal] = useState(false);

  const handleRedirect = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.descriptionRow}>
      <div className={classes.planDescription}>
        {showModal
        && (
        <ModalChangeInboxAmount
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          newAmount={newAmount}
          setNewAmount={setNewAmount}
          plan={plan}
          multiple={multiple}
          paymentCycle={paymentCycle}
          domainObject={domainObject}
        />
        )
      }

        {calledFrom === 'cycleUpgrade' && (
        <div className={classNames(classes.descriptionItem, classes.firstDescriptionItem)} data-testid="mailBoxes">
          <div className={classes.centeredItem}>
            <Typography className={classes.descriptionItemTitle}>{t('proEmail.upgradePlan.boughtMailBoxes')}</Typography>

            <div className={classes.row}>
              <Typography className={classes.descriptionItemValue}>{multiple}</Typography>
              <Button
                variant="link"
                label={t('proEmail.upgradePlan.changeInboxLabel')}
                alt={t('proEmail.upgradePlan.changeInboxLabelAlt')}
                onClick={() => toggleShowModal(!showModal)}
              />
            </div>
          </div>


          <p className={classes.downgradeMessage}>
            <Trans
              i18nKey="proEmail.upgradePlan.downgradeMaessage"
              values={{
                planName: plan.name,
                cycle: t(`proEmail.upgradePlan.${paymentCycle.charAt(0).toUpperCase() + paymentCycle.slice(1)}`),
                accounts: newAmount || domainObject.total_accounts,
                from: formatDate(new Date()),
                to: formatDate(newNextDueDate),
              }}
            >
              <>0</>
              <a href={`${t('routes.emailsList')}${t('routes.emailsTitan')}/${domainObject.domain}/email-accounts`} onClick={() => handleRedirect(`${t('routes.emailsList')}${t('routes.emailsTitan')}/${domainObject.domain}/email-accounts`)}>1</a>
              <>2</>
            </Trans>
          </p>
        </div>
        )}

        <div className={classes.descriptionItem} data-testid="valuePerMailBox">
          <Typography className={classes.descriptionItemTitle}>{t('proEmail.upgradePlan.valuePerMailBox')}</Typography>
          <Typography className={classes.descriptionItemValue}>
            {formatCurrency(plan.configoptions[0][paymentCycle].toLowerCase())}
            {' '}
            <small>{t(`proEmail.upgradePlan.${paymentCycle.toLowerCase()}`)}</small>
          </Typography>
        </div>

        <div className={classes.descriptionItem} data-testid="recurrentValue">
          <Typography className={classes.descriptionItemTitle}>{t('proEmail.upgradePlan.recurrentValue')}</Typography>
          <Typography className={classes.descriptionItemValue}>
            {formatCurrency(plan.configoptions[0][paymentCycle].toLowerCase() * multiple)}
            {' '}
            <small>{t(`proEmail.upgradePlan.${paymentCycle.toLowerCase()}`)}</small>
            <Tooltip
              placement="top"
              title={(
                <Trans i18nKey={`proEmail.upgradePlan.${paymentCycle.toLowerCase()}RecurrentValueTooltip`}>
                  <>0</>
                  {{ planName: plan.name }}
                  <>1</>
                </Trans>
            )}
            >
              <InfoIcon className={classes.info} />
            </Tooltip>

          </Typography>
        </div>

        {(showMonthlyCycle && billingcycle) && (
        <div className={classes.descriptionItem} data-testid="billingCycle">
          <Typography className={classes.descriptionItemTitle}>{t('proEmail.upgradePlan.upgradeMailBoxPaymentCycle')}</Typography>
          <Typography className={classes.descriptionItemValue}>{t(`proEmail.upgradePlan.${billingcycle}`)}</Typography>
        </div>
        )}

        <div className={classes.descriptionItem} data-testid="nextDueDate">
          <Typography className={classes.descriptionItemTitle}>{t('proEmail.upgradePlan.nextDueDate')}</Typography>
          <Typography className={classes.descriptionItemValue}>
            {formatDate(newNextDueDate)}
            <Tooltip
              placement="top"
              title={(
                <Trans i18nKey="proEmail.upgradePlan.newNextduedateTooltip">
                  <>0</>
                  {{ planName: plan.name }}
                  <>1</>
                </Trans>
            )}
            >
              <InfoIcon className={classes.info} />
            </Tooltip>
          </Typography>
        </div>

        {(memorialEnabled && summaryCalcs && domainObject.packageid !== TRIAL_PLAN) && (
        <MemorialMessage
          domainObject={domainObject}
          summaryCalcs={summaryCalcs}
          dateDiff={dateDiff}
          multiple={multiple}
          calledFrom={calledFrom}
          plan={plan}
        />
        )}
      </div>

    </div>
  );
};

DescriptionItems.propTypes = {
  multiple: PropTypes.number.isRequired,
  paymentCycle: PropTypes.string,
  plan: PropTypes.object,
  newNextDueDate: PropTypes.instanceOf(Date),
};

DescriptionItems.defaultProps = {
  paymentCycle: null,
  plan: null,
  newNextDueDate: null,
};

export default withI18n()(withStyles(styles)(DescriptionItems));
