import { COUNTRY } from '@/config';

const SERVICE_URL_BY_BRAND = {
  br: 'https://status.hostgator.com.br/',
  mx: 'https://status.hostgator.mx/',
  co: 'https://status.hostgator.cl/',
  cl: 'https://status.hostgator.co/',
};

export const SERVICE_STATUS_URL = SERVICE_URL_BY_BRAND[COUNTRY];
