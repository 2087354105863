
const styles = theme => ({
  cardContentProduct: {
    minHeight: 100,
    paddingTop: 27,
    paddingLeft: 28,
    paddingRight: 30,
    '&:last-child': {
      paddingRight: 15,
      '@media(max-width: 1280px)': {
        paddingRight: 30,
      },
    },
    '@media(max-width: 620px)': {
      '&:last-child': {
        paddingBottom: 19,
      },
    },
  },
  titlesWrapper: {
    width: 253,
    display: 'inline-block',
    paddingLeft: '15px',
  },
  container: {
    position: 'relative',
    padding: '2px 0px 5px 0px',
  },
  subdomain: {
    '&:after': {
      [theme.breakpoints.down('xs')]: {
        content: '" "',
      },
      content: '" / "',
      paddingRight: '10px',
      paddingLeft: '10px',
      color: theme.color.geyser,
    },
    '&:last-child:after': {
      content: '""',
    },
  },
  switch: {
    marginLeft: '-13px',
  },
  arrow: {
    fill: theme.color.tundora,
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  statusDueButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '85%',

    '@media(max-width: 1280px)': {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },

    '@media(max-width: 768px)': {
      width: '100%',
    },
  },
  statusWithDue: {
    '@media(max-width: 1280px)': {
      alignItems: 'flex-start',
      width: '70%',
    },
    '@media(max-width: 600px)': {
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  statusWithoutDue: {
    '@media(max-width: 1280px)': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  statusDueContainer: {
    marginTop: -6,
    display: 'flex',
    alignItems: 'center',
    width: 445,
    '@media(min-width: 960px) and (max-width: 1280px)': {
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '65%',
      paddingLeft: 0,
    },
    '@media(max-width: 620px)': {
      flexDirection: 'column',
      width: '100%',
    },
    '@media(min-width: 620px) and (max-width: 1280px)': {
      marginBottom: '10px',
    },
  },
  cardDateBox: {
    marginRight: '25px',
    '@media(min-width: 670px) and (max-width: 1280px)': {
      marginRight: '55px',
    },
    '@media(max-width: 550px)': {
      marginRight: '55px',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 300,
    '@media(max-width: 1280px)': {
      width: '100%',
      minWidth: '300px',
    },
    '@media(max-width: 620px)': {
      minWidth: '100%',
    },
  },
  dueContainer: {
    width: 150,
    display: 'flex',
    '@media(min-width: 600px) and (max-width: 1280px)': {
      width: '100%',
    },
    '@media(max-width: 620px)': {
      width: '100%',
      marginBottom: '13px',
      marginTop: '18px',
    },
  },
  renewTitle: {
    width: '120px',
    '@media(min-width: 552px) and (max-width: 610px)': {
      width: '119px',
    },
  },
  buttonContainer: {
    flex: 1,

    '@media(max-width: 1280px)': {
      width: '100%',
    },
    '@media(min-width: 1281px)': {
      marginTop: '-7px',
    },
  },
  buttonContainerWithoutDue: {
    marginTop: '19px',
    '@media(min-width: 550px)': {
      marginTop: '-7px',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '165px',
    '@media(max-width: 1280px)': {
      width: '30%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '@media(max-width: 600px)': {
      width: '100%',
    },
  },
  cardActions: {
    minWidth: '139.31px',
    display: 'initial',
    padding: '0',
    '& > a': {
      '&:hover': {
        color: theme.color.chambray,
        textDecoration: 'underline',
      },
      transition: 'color 0.2s',
      textTransform: 'uppercase',
      textDecoration: 'none',
      textAlign: 'right',
      color: theme.color.indigo,
      fontWeight: '400',
      margin: 0,
    },
    '@media(max-width: 1280px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  iconButton: {
    '@media(max-width: 1280px)': {
      position: 'absolute',
      top: 15,
      right: 8,
    },
  },
  addonList: {
    '@media(min-width: 1280px)': {
      paddingRight: '25px',
    },
  },
  label: {
    display: 'inline-block',
    height: 'auto',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.color.white,
    fontWeight: 'normal',
    fontSize: '12px',
    userSelect: 'none',
    padding: '0 7px',
    borderRadius: 3,
    whiteSpace: 'normal',
    '@media(min-width: 1100px) and (max-width: 1280px)': {
      maxWidth: '82px',
    },
    '@media(min-width: 793px) and (max-width: 960px)': {
      maxWidth: '82px',
    },
    '@media(min-width: 420px) and (max-width: 510px)': {
      maxWidth: '82px',
    },
  },
  resellerMessageWrapper: {
    marginTop: 20,
  },
});

export default styles;
