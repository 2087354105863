import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 15px 0px 24px;
    padding: 0px 8px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    padding: 0px 8px;

    strong {
      align-items: center;
      color: ${theme.v2.colors.neutral.lowDark};
      display: flex;
      margin: 24px 0px 0px;
  
      &::before {
        background-color: ${theme.v2.colors.neutral.lowDark};
        border-radius: 100%;
        content: "";
        display: flex;
        flex-shrink: 0;
        flex: 0 0 2px;
        margin: 0px 8px;
        min-height: 5px;
        min-width: 5px;
      }
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      font-size:${theme.v2.font.sizes.xxs}
    }
  `}
`;

export const AlertWrapper = styled.div`
  align-items: flex-start;
  display:  flex;
  flex-direction: row;
  flex-warp: wrap;
  gap:10px;
  margin: 24px 0px 0px;
  padding: 0px 8px;

  svg {
    flex-shrink: 0;
  }
`;

export const AlertDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;

    a {
      display: inline;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      font-size:${theme.v2.font.sizes.xxs}
    }
  `}
`;

export const ButtonWrapper = styled.div`
    ${({ theme }) => css`
      display: flex;
      gap: 8px;
      justify-content: center;
      margin: 24px 0px;

      @media (max-width: ${theme.v2.breakpoints.sm}){
        flex-direction: column;
        gap: 16px;
        margin: 24px auto;
        max-width: fit-content;

        button {
          max-height: 52px;
          padding: 13px 24px;
        }
      }


      button {
        max-height: 52px;
      }
    `}
`;
