import styled, { css } from 'styled-components';


export const SocialMediaList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 18px;
`;

export const SocialMediaDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 18.9px;
    margin: 3px 0;
  `}
`;

export const SocialLink = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background: none;
    border-radius: 25px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 8px 16px 8px 8px;
    width: 164px;
  `}
`;

export const ModalTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: 0;
    margin-bottom: 3px;
  `}
`;

export const ModalDescription = styled.h4`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 10px;
    margin-top: 0;
    max-width: 500px;
  `}
`;

export const ModalButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 24px;
    margin-top: 35px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 80px;

      div {
        margin-bottom: 0px;
      }
    }
  `}
`;

export const ModalContent = styled.div`
  ${({ isWhatsapp }) => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: ${isWhatsapp && '350px'};
    padding: 8px 16px 16px 16px;
  `}
`;

export const ModalDeleteButtonsWrapper = styled(ModalButtonsWrapper)`
  ${({ theme }) => css`
    margin-top: 0;
    
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 80px;

      div {
        margin-bottom: 0px;
      }
    }
  `}
`;

export const ModalInputWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  width: 331px;
`;

export const SocialIconWrapper = styled.div`
  width: 44px;
`;
