export const PRODUCTS_TYPE = {
  DOMAIN: 'domain',
  PROEMAIL: 'proemail',
  DEDICATED: 'dedicated',
  SITELOCK: 'sitelock',
  CODEGUARD: 'codeguard',
  ACADEMY: 'academy',
};

export const OPTION_NAME = {
  HIREHOST: 'hireHost',
  HIREPLAN: 'hirePlan',
  DOMAINS: 'domains',
  TITAN: 'titan',
  DEDICATED: 'dedicated',
  SECURITY: 'security',
  ACADEMY: 'academy',
};
