import styled, { css } from 'styled-components';

export const TabButton = styled.button`
  ${({ theme, activated }) => css`
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom: 5px solid ${activated ? theme.palette.primary.main : theme.palette.white.light};
    display: flex;
    justify-content: center;
    opacity: ${activated ? '1' : '0.5'};
    transition: opacity 200ms ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 1;
    }
  `}
`;

export const TabText = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-weight: 500;
    margin-left: 8px;
    text-transform: uppercase;
    color: ${theme.palette.primary.main}
  `}
`;

export const TabIcon = styled.div`
  display: flex;
`;

export const TabHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    display: flex;
    height: 60px;

    ${TabButton} {
      padding: 0 16px;
    }

    ${TabText} {
      font-size: 14px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      background: ${theme.palette.primary.dark};

      ${TabButton} {
        padding: 0;
      }

      ${TabIcon} svg {
        fill: ${theme.palette.white.light};
      }

      ${TabText} {
        color: ${theme.palette.white.light};
      }
    }
  `}
`;

export const TabContainer = styled.div`
  display: flex;
`;
