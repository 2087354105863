import billingBR from './billing.br';
import billingCL from './billing.cl';
import billingCO from './billing.co';
import billingMX from './billing.mx';

const locales = {
  BR: billingBR,
  CL: billingCL,
  CO: billingCO,
  MX: billingMX,
};

export default locales;
