const styles = theme => ({
  cardWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: '0px 2px 4px 0px #DBDBDB',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    padding: '24px 38px 24px 36px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '24px',
    color: theme.color.tundora,
    userSelect: 'none',
    [theme.breakpoints.up(379)]: {
      whiteSpace: 'nowrap',
    },
  },
  dBlock: {
    display: 'block',
  },
  description: {
    color: theme.color.regentGray,
    display: 'flex',
    fontSize: '16px',
    lineHeight: '19px',
    margin: '18px 0 0px 0',
    alignItems: 'center',

    [theme.breakpoints.down(500)]: {
      display: 'block',
    },

  },
  link: {
    alignItems: 'center',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: 16,
    marginLeft: 5,
  },
});
export default styles;
