import styled, { css } from 'styled-components';
import IconCheck from '@/media/icons/check.svg';

export const Card = styled.div`
margin: 40px auto 24px;

  @media (max-width:1580px){
    max-width: 1062px;
  }

  .slick-list {
    order: 99 !important;
    @media (min-width: 769px){
      display: flex;
    }

    .slick-track { 
      display: flex;

      > div {
        height: calc(100% - 30px);
        > div {
          height: 100%;
        }

        @media (max-width:1343px){
          display: flex;
          justify-content: center;
        }
      }

      @media (min-width: 1343px){
        width: auto;
        margin: 0px auto;

        > div { 
          display: flex;
          margin: 0px 12px;
          @media (min-width:1343px){
            width: unset;
          }
        }
      }
    }
  }
`;

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xl};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 29px;
    margin: 0px 0px 7px 0px;
  
  `}
`;

export const CardDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    letter-spacing: 0.01px;
    line-height: 21px;
    margin: 0px 0px 32px 0px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const PlanOption = styled.button`
${({ theme }) => css`
    background-color:  ${theme.color.white};;
    border-radius: 5px;
    border: 1px solid ${theme.color.valencia};
    boxShadow: '0px 0px 6px #00000014',
    cursor: pointer;
    display: flex !important;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin-right: 24px;
    max-width: 330px;
    min-height: 624px;
    position: relative;
    transition: 300ms border-color ease-in-out;
    width: 330px !important;

    &:last-child {
      margin-right: 0px;
    }

    @media (max-width: 758px) {
      margin-bottom: 35px;
      margin-right: unset;
      max-width: unset;
      width: 100% !important;
    }
  `}
`;

export const TagWrapper = styled.div`
  display: flex; 
  min-height: 23px;
  position: relative;
  width: 100%;
`;

export const RadioWrapper = styled.div`
  position: absolute;
`;

export const Tag = styled.span`
  ${({
    theme, type, centered,
  }) => {
    const tagStyle = {
      selected: {
        backgroundColor: theme.palette.primary.main,
        margin: '0px 0px 0px 38px',
      },
      trial: {
        backgroundColor: theme.palette.success.primary,
        margin: '0px 0px 0px 18px',
      },
    };

    return css`
      align-items: center;
      background-color: ${tagStyle[type].backgroundColor};
      border-radius: 5px;
      color: ${theme.palette.white.light};
      display: flex;
      font-size: ${theme.font.sizes.sm};
      font-weight: ${theme.font.weights.medium};
      justify-content: center;
      letter-spacing: 0.01px;
      line-height: 19px;
      margin: ${centered ? '0px auto' : tagStyle[type].margin};
      padding: 2px 8px;
    `;
  }}
`;

export const TrialTag = styled.div`
  display: flex; 
`;

export const PlanName = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 16px auto 8px auto; 
    width: 100%;
  `}
`;

export const StorageValue = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 32px;
    margin: 0px auto;
    text-align: center;
  `}
`;

export const StorageLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    letter-spacing: 0.01px;
    line-height: 19px;
    margin: 0px auto 8px;
    text-align: center;
  `}
`;

export const PaymentPrice = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    letter-spacing: 0.01px;
    line-height: 19px;
    margin: 0px 0px 24px 0px;
    text-align: center;
    width: 100%;

    & small {
      fontSize: 10px;
    }
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.grey.primary};
    height: 1px;
    margin: 0 auto 24px;
    max-width: 282px;
    padding: 0 6px;
    width: 100%;
  `}
`;

export const BennefitsWrapper = styled.div`
  ${({ theme }) => css`
  & ul {
    list-style-type: none;
    margin: unset;
    padding: 0px 6px;
    text-align: left;

    & li {
      color: ${theme.palette.grey.dark};
      display: flex;
      font-size: ${theme.font.sizes.sm};
      letter-spacing: 0.01px;
      line-height: 21px;
      margin-bottom: 16px;
      position: relative;
    
      & > strong, & > b {
        display: contents;
      }

      & span {
        align-items: center;
        background: ${theme.palette.primary.main};
        border-radius: 50%;
        color: ${theme.palette.white.light};
        display: flex;
        font-size: ${theme.font.sizes.xxxxs};
        height: 12px;
        justify-content: center;
        margin-left: 8px;
        max-width: 12px;
        position: relative;
        top: 4px;
        width: 100%;
      }

      &:before {
        background-image: url('${IconCheck}');
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 16px;
        margin-right: 8px;
        min-width: 16px;
        position: relative;
        top: 2px;
      }
    }

    & li:last-child {
      margin-bottom: 0px,
    }
  }
  `}
`;

export const SlickAdapter = styled.div`
  flex: 1;
  max-width: 90%;
  overflow: hidden;
  padding: 0;
  position: relative;
  &:before {
    border-radius: 2px;
    bottom: 14px;
    content: '';
    display: block;
    height: 4px;
    left: 38px;
    position: absolute;
    right: 38px;
  }
  .slick-list {
    max-width: 31%;
    overflow: initial;
  }
  .slick-slide {
    padding: 0 15px;
  }
  .slick-prev, .slick-next {
    bottom: 0;
    height: 32px;
    top: auto;
    transform: none;
    width: 32px;
    z-index: 2;
    &.slick-disabled {
      cursor: default;
      &::before {
        opacity: 0.4;
      }
    }
    &::before {
      background-position: center;
      background-repeat: no-repeat;
      color: transparent;
      font-size: 32px;
      opacity: 1;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    .slick-list {
      max-width: 49%;
    }
  }
`;

export const IncludedLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    line-height: 19px;
    margin: 0px 0px 16px 0px;
    text-align: left;
  `}
`;
