import React from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LinkButton from '@/components/Buttons/LinkButton';
import DomainSetup from '@/components/Dns/DomainSetup';
import Loader from '@/components/Layout/Loader';
import styles from './styles';

const ChoosenDomainWithoutProvider = ({
  classes, t, loading,
}) => {
  const notifications = useSelector(state => state.dnsDetails.notifications);


  if (loading) {
    return (
      <div className={classes.cardWrapper}>
        <Loader data-testid="loaderwrapper" />
      </div>
    );
  }

  return (
    <div className={classes.cardWrapper}>
      { notifications.length > 0
        ? (
          <div data-testid="domainWithoutProviderInfo">
            <Typography className={`${classes.title} ${classes.dBlock}`} variant="h1">
              {t('whichDnsToChange.title2')}
            </Typography>
            <p className={classes.description}>
              {t('whichDnsToChange.text')}
              <LinkButton to={t('routes.help')} className={classes.link} target="blank">{t('whichDnsToChange.text2')}</LinkButton>
            </p>
          </div>
        )
        : <DomainSetup />
      }
    </div>
  );
};

ChoosenDomainWithoutProvider.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default (withI18n()(withStyles(styles)(ChoosenDomainWithoutProvider)));
