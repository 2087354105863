export const ALERT_TYPES = {
  INVOICES: 'invoices',
  DOMAIN_NOT_CONTRACTED: 'domain_not_contracted',
  DOMAIN_NOT_PROPAGATED: 'domain_not_propagated',
  DOMAIN_NOT_CONFIGURED: 'domain_not_configured',
  DOMAIN_FOR_POINTING: 'domains_for_pointing',
  DOMAIN_IN_PROGRESS: 'domain_in_progress',
  SUSBCRIPTION_OVERDUE: 'subscriptions_overdue',
  REFUND: 'refund',
  TOS: 'tos',
  TICKET_OPEN: 'ticket_open',
  TICKET_NEW: 'ticket_new',
  TICKET_PENDING: 'ticket_pending',
  TICKET_SOLVED: 'ticket_solved',
  BACKUP: 'backup',
  DUPLICATE_ORDERS: 'duplicate_orders',
  PENDING_ORDER: 'pending_order',
  SSL: 'ssl_status',
};

export const BACKUP_STATUS = {
  RESTORED: 'restoration',
  REQUESTED: 'request',
};

export const BACKUP_GA = {
  SUCCESS: 'backup-success',
  WITHOUT: 'backup-without',
  SEVEN_DAYS: 'backup-7daysOrMore',
  FIFTEEN_DAYS: 'backup-15daysOrMore',
  THIRTY_DAYS: 'backup-30daysOrMore',
};

export const SSL_STATUS = {
  ACTIVE: 'active',
  ACTIVATION_FAILED: 'activation_failed',
  IN_PROGRESS: 'in_progress',
  VIEW_PENDING: 'view_pending',
  AUTO_SSL: 'auto_ssl',
};

export const SSL_GA = {
  ACTIVE: 'ssl_active',
  ACTIVATION_FAILED: 'ssl_activation_failed',
  IN_PROGRESS: 'ssl_in_progress',
  AUTO_SSL: 'auto_ssl',
  VIEW_PENDING: 'view_pending',
  REGISTER_DOMAIN: 'register_domain',
  ACTIVATION_ERROR: 'activation_error',
  MODAL_TO_OTHER: 'modal_to_other',
};

export const PENDING_ORDER_GA = {
  CANCELLED: 'cancelled',
  PENDING_ANALYSIS: 'pending_analysis',
};

export const ANALYSIS_STATUS = {
  PENDING_PAYMENT: 'pending_payment',
  PENDING_ANALYSIS: 'pending_analysis',
  CANCELLED: 'cancelled',
};

export default ALERT_TYPES;
