import { collect } from '@/analytics';
import { collectTrackData } from '@/utils/Tracker/tracker';

const EVENT = {
  NAME: 'gaEvent',
  CATEGORY: {
    LINK_NA_BIO: 'Link Na Bio',
  },
  ACTION: {
    MENU_ACCESS: 'Menu Acesso',
    EDIT: 'Editar',
    REMOVE: 'Remover',
    FORWARD: 'Avançar',
    ADD: 'Adicionar',
    COPY: 'Copiar',
    VIEW: 'Visualizar',
    UNAVAILABLE: 'Link indisponível',
    LINK: 'Link',
    LINK_NA_BIO: 'Link Na Bio',
    PUBLISH: 'Publicar',
  },
  LABEL: {
    STEP_1: 'Step 1',
    STEP_2: 'Step 2',
    STEP_3: 'Step 3',
    STEP_4: 'Step 4',
    LIST: 'Listagem Link Na Bio',
  },
};

const listViewEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.MENU_ACCESS,
    gaEventLabel: EVENT.LABEL.LIST,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.MENU_ACCESS,
    label: EVENT.LABEL.LIST,
  },
};

const accessToStep1EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.MENU_ACCESS,
    gaEventLabel: EVENT.LABEL.STEP_1,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.MENU_ACCESS,
    label: EVENT.LABEL.STEP_1,
  },
};

const linkUnavailableEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.UNAVAILABLE,
    gaEventLabel: EVENT.LABEL.STEP_1,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.UNAVAILABLE,
    label: EVENT.LABEL.STEP_1,
  },
};

const accessToStep2EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.VIEW,
    gaEventLabel: EVENT.LABEL.STEP_2,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.VIEW,
    label: EVENT.LABEL.STEP_2,
  },
};

const addLinkStep2EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.ADD} ${EVENT.ACTION.LINK}`,
    gaEventLabel: EVENT.LABEL.STEP_2,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.ADD} ${EVENT.ACTION.LINK}`,
    label: EVENT.LABEL.STEP_2,
  },
};

const removeLinkStep2EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.REMOVE} ${EVENT.ACTION.LINK}`,
    gaEventLabel: EVENT.LABEL.STEP_2,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.REMOVE} ${EVENT.ACTION.LINK}`,
    label: EVENT.LABEL.STEP_2,
  },
};

const editLinkStep2EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.EDIT} ${EVENT.ACTION.LINK}`,
    gaEventLabel: EVENT.LABEL.STEP_2,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.EDIT} ${EVENT.ACTION.LINK}`,
    label: EVENT.LABEL.STEP_2,
  },
};

const accessToStep3EventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.VIEW,
    gaEventLabel: EVENT.LABEL.STEP_3,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.VIEW,
    label: EVENT.LABEL.STEP_3,
  },
};

const publishLinkNaBioEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: EVENT.ACTION.PUBLISH,
    gaEventLabel: EVENT.LABEL.STEP_3,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: EVENT.ACTION.PUBLISH,
    label: EVENT.LABEL.STEP_3,
  },
};

const copyLinkNaBioEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.COPY} ${EVENT.ACTION.LINK}`,
    gaEventLabel: EVENT.LABEL.STEP_4,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.COPY} ${EVENT.ACTION.LINK}`,
    label: EVENT.LABEL.STEP_4,
  },
};

const viewLinkNaBioEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.VIEW} ${EVENT.ACTION.LINK_NA_BIO}`,
    gaEventLabel: EVENT.LABEL.STEP_4,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.VIEW} ${EVENT.ACTION.LINK_NA_BIO}`,
    label: EVENT.LABEL.STEP_4,
  },
};

const editLinkNaBioEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.EDIT} ${EVENT.ACTION.LINK_NA_BIO}`,
    gaEventLabel: EVENT.LABEL.LIST,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.EDIT} ${EVENT.ACTION.LINK_NA_BIO}`,
    label: EVENT.LABEL.LIST,
  },
};

const removeLinkNaBioEventData = {
  ga: {
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.LINK_NA_BIO,
    gaEventAction: `${EVENT.ACTION.REMOVE} ${EVENT.ACTION.LINK_NA_BIO}`,
    gaEventLabel: EVENT.LABEL.LIST,
  },
  tracker: {
    campaign: EVENT.CATEGORY.LINK_NA_BIO,
    action: `${EVENT.ACTION.REMOVE} ${EVENT.ACTION.LINK_NA_BIO}`,
    label: EVENT.LABEL.LIST,
  },
};

const listView = () => {
  collect(listViewEventData.ga);
  collectTrackData(listViewEventData.tracker);
};

const accessToStep1 = () => {
  collect(accessToStep1EventData.ga);
  collectTrackData(accessToStep1EventData.tracker);
};
const linkUnavailable = () => {
  collect(linkUnavailableEventData.ga);
  collectTrackData(linkUnavailableEventData.tracker);
};

const accessToStep2 = () => {
  collect(accessToStep2EventData.ga);
  collectTrackData(accessToStep2EventData.tracker);
};

const addLinkStep2 = () => {
  collect(addLinkStep2EventData.ga);
  collectTrackData(addLinkStep2EventData.tracker);
};

const removeLinkStep2 = () => {
  collect(removeLinkStep2EventData.ga);
  collectTrackData(removeLinkStep2EventData.tracker);
};

const editLinkStep2 = () => {
  collect(editLinkStep2EventData.ga);
  collectTrackData(editLinkStep2EventData.tracker);
};

const accessToStep3 = () => {
  collect(accessToStep3EventData.ga);
  collectTrackData(accessToStep3EventData.tracker);
};

const publishLinkNaBio = () => {
  collect(publishLinkNaBioEventData.ga);
  collectTrackData(publishLinkNaBioEventData.tracker);
};

const copyLinkNaBio = () => {
  collect(copyLinkNaBioEventData.ga);
  collectTrackData(copyLinkNaBioEventData.tracker);
};

const viewLinkNaBio = () => {
  collect(viewLinkNaBioEventData.ga);
  collectTrackData(viewLinkNaBioEventData.tracker);
};

const editLinkNaBio = () => {
  collect(editLinkNaBioEventData.ga);
  collectTrackData(editLinkNaBioEventData.tracker);
};

const removeLinkNaBio = () => {
  collect(removeLinkNaBioEventData.ga);
  collectTrackData(removeLinkNaBioEventData.tracker);
};

const analytics = {
  listView,
  accessToStep1,
  linkUnavailable,
  accessToStep2,
  addLinkStep2,
  removeLinkStep2,
  editLinkStep2,
  accessToStep3,
  publishLinkNaBio,
  copyLinkNaBio,
  viewLinkNaBio,
  editLinkNaBio,
  removeLinkNaBio,
};

export default analytics;
