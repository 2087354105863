import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { surveyActions } from '@/redux/actions/survey';
import isValidJsonString from '@/utils/Validators/isValidJsonString';
import { queryParams } from '@/utils/Application/queryParams';
import { config } from '@/config';
import { surveyDB } from '@/pages/SurveyMonkey/indexDB';

const useSurvey = (t) => {
  const dispatch = useDispatch();

  const { id: userId } = useSelector(state => state.summary);
  const history = useHistory();
  const { SURVEY_CONFIG } = config;
  const surveyURL = `${SURVEY_CONFIG.url}?userID=${userId}&brand=${SURVEY_CONFIG.country}`;
  const location = useLocation();
  const success = queryParams(location.search, 'survey');

  const successSurvey = () => {
    if (success === 'success') {
      const dataSurvey = { surveyMessage: true };
      window.parent.postMessage(JSON.stringify(dataSurvey), '*');
    }
  };

  const saveSurvey = async () => {
    const surveyIndexDB = await surveyDB.get(userId);

    return (surveyIndexDB)
      ? dispatch(surveyActions.saveSurvey(surveyIndexDB))
      : dispatch(surveyActions.loadSurvey(SURVEY_CONFIG.idAPI, userId));
  };

  const onMessage = async (event) => {
    const surveyData = isValidJsonString(event.data) ? JSON.parse(event.data) : false;

    if (!surveyData.surveyMessage) return false;

    const data = {
      userId,
      id: SURVEY_CONFIG.idLink,
      country: SURVEY_CONFIG.country,
      save: true,
    };

    await surveyDB.save(data);

    dispatch(surveyActions.saveSurvey(data));

    history.push(t('routes.sitesPage'));
  };

  return {
    surveyURL,
    onMessage,
    saveSurvey,
    successSurvey,
  };
};

export default useSurvey;
