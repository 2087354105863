const styles = theme => ({
  '@keyframes create': {
    from: {
      opacity: 0,
      transform: 'scaleY(0,0)',
    },
    to: {
      opacity: 1,
      transform: 'scaleY(1,1)',
    },
  },
  group: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5px',
    animationName: 'create',
    animationDuration: '0.30s',
  },
  subLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '19px',
  },
  svg: {
    marginTop: '-6px',
    marginRight: '10px',
    fill: theme.color.tundora,
  },
  input: {
    width: '100%',
    height: '32px',
    margin: '3px 0 11px 0',
    color: theme.color.tundora,
    '& fieldset': {
      height: '39px',
      borderRadius: '2px',
    },
  },
  cssLabel: {
    transform: 'translate(14px, 10px) scale(1)',
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  cssOutlinedInput: {
    '& input': {
      padding: '10px 14px 6px 14px',
      fontSize: '14px',
    },
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  masterWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
