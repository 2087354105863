import React, { useContext } from 'react';
import { Trans, withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { addDays, differenceInDays } from 'date-fns';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import { ProductsContext } from '@/contexts/Products/context';
import { RESELLER_PRICE_UPDATE_EXPLANATION } from '@/config/urls/supportUrls';
import formatDateFns from '@/utils/Formatters/formatDateFns';
import styles from './styles';

const OldResellerWarning = ({ classes, product, productDetail }) => {
  const { toggleShowModal } = useContext(ProductsContext);

  const changePlanAction = product.actions.find(action => action.type === 'plan-switch');
  const dateDiff = product.nextinvoicedate
    ? differenceInDays(addDays(new Date(product.nextinvoicedate), 1), new Date())
    : differenceInDays(addDays(new Date(product.nextduedate), 1), new Date());
  const criticalMessage = dateDiff < 10;

  const isValidDate = (product.nextinvoicedate !== undefined && product.nextinvoicedate !== '0000-00-00')
  || (product.nextduedate !== undefined && product.nextduedate !== '0000-00-00');

  if (!isValidDate) {
    return null;
  }

  return (
    <div
      className={classnames(classes.resellerMessageWrapper, {
        [classes.productDetail]: productDetail === true,
      })}
    >
      <WarningNotice
        border={criticalMessage ? 'Suspended' : product.status}
        message={(
          <div className={classes.messageStyle}>

            {(product.status === 'Suspended' || product.domainstatus === 'Suspended') ? (
              <Trans
                i18nKey="oldReseller.cardSuspendedMessage"
              >
                <b>
                  {{ productname: product.productname }}
                  {{
                    invoicedate: formatDateFns({
                      productDate: product.nextinvoicedate ? product.nextinvoicedate : product.nextduedate,
                      additionalDays: 31,
                    }),
                  }}
                  <>0</>
                </b>

                {product.nextinvoicedate ? (
                  <button
                    className={classes.listCursos}
                    onClick={() => toggleShowModal(true)}
                    type="button"
                    data-testid="nextinvoicedateU"
                  >
                    1
                  </button>
                )
                  : (<a href={changePlanAction.linkUrl} target="_blank" rel="noopener noreferrer" className={classes.listCursos}> 1 </a>)
                    }

                <>2</>
              </Trans>
            )
              : (
                <Trans
                  i18nKey={criticalMessage ? 'oldReseller.cardAffectedMessageTilTenDays' : 'oldReseller.cardAffectedMessage'}
                >
                  <b>
                    {{ productname: product.productname }}
                    {{
                      invoicedate: formatDateFns({
                        productDate: product.nextinvoicedate ? product.nextinvoicedate : product.nextduedate,
                        additionalDays: 1,
                      }),
                    }}
                    <>0</>
                  </b>

                  <>1</>

                  <a href={RESELLER_PRICE_UPDATE_EXPLANATION} target="_blank" rel="noopener noreferrer" className={classes.listCursos}> 2 </a>

                  <>3</>

                  {product.nextinvoicedate ? (
                    <button
                      type="button"
                      className={classes.listCursos}
                      onClick={() => toggleShowModal(true)}
                      data-testid="nextinvoicedateU"
                    >
                      4
                    </button>
                  )
                    : (<a href={changePlanAction.linkUrl} target="_blank" rel="noopener noreferrer" className={classes.listCursos}> 4 </a>)
                      }

                  <>5</>
                </Trans>
              )
                }
          </div>
            )}
        fill="100%"
      />
    </div>
  );
};

OldResellerWarning.propTypes = {
  product: PropTypes.object.isRequired,
  productDetail: PropTypes.bool.isRequired,
};

export default withI18n()(withStyles(styles)(OldResellerWarning));
