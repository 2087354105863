import { isBRServer } from '@/utils/Validators/validation';
import {
  SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING,
  SUPPORT_ARTICLE_CHANGE_DNS_GODADDY,
  SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST,
  SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB,
  SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX,
  SUPPORT_ARTICLE_CHANGE_DNS_OTHERS,
  SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR,
  SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST,
  SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA,
  GODADDY_HOMEPAGE,
  KINGHOST_HOMEPAGE,
  LOCAWEB_HOMEPAGE,
  REGISTROBR_HOMEPAGE,
  UOLHOST_HOMEPAGE,
  WEBEMPRESA_HOMEPAGE,
  COLOMBIAHOSTING_HOMEPAGE,
  NEUBOX_HOMEPAGE,
} from '@/config/urls/supportUrls';

const optionsBr = ['Registro.br', 'Godaddy', 'Locaweb', 'Uol Host', 'Kinghost', 'Outros'];
const optionsEs = ['Godaddy', 'Web Empresa', 'Colombia Hosting', 'Neubox', 'Otros'];

export const optionsList = isBRServer
  ? optionsBr.map((item, index) => ({ id: index, label: item, value: item }))
  : optionsEs.map((item, index) => ({ id: index, label: item, value: item }));

export const SELECT_MODAL_STEPS = {
  CHECKING_STATUS: 0,
  GET_DOMAIN_ORIGINAL_REGISTRATION: 1,
  HOW_TO_SETUP: 2,
  FINISH_SETUP_STEP: 3,
  DOMAIN_NOT_REGISTERED: 99,
};

export const PROVIDERS_LINKS = [
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR, page: REGISTROBR_HOMEPAGE, name: 'Registro.br' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_GODADDY, page: GODADDY_HOMEPAGE, name: 'Godaddy' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB, page: LOCAWEB_HOMEPAGE, name: 'Locaweb' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST, page: UOLHOST_HOMEPAGE, name: 'Uol Host' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST, page: KINGHOST_HOMEPAGE, name: 'Kinghost' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA, page: WEBEMPRESA_HOMEPAGE, name: 'Web Empresa' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING, page: COLOMBIAHOSTING_HOMEPAGE, name: 'Colombia Hosting' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX, page: NEUBOX_HOMEPAGE, name: 'Neubox' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_OTHERS, name: 'Others' },
];
