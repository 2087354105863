import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './RestorateBackupHgRoutine.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  Select, IconData, Input, IconLoading, Checkbox, Button, Alert,
} from 'gatorcomponents';
import { useDispatch, useSelector } from 'react-redux';
import { formsActions } from '@/redux/modules';
import { format } from 'date-fns';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import { CHARLIE_SKIP_HG_ROUTINE_BACKUP } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const RestorateBackupHgRoutine = ({
  submitForm,
  selectedProduct,
  opening,
}) => {
  const {
    hgRoutineBackups, loadingHgBackupHistory, forceState, rules,
  } = useSelector(state => state.formsReducer);
  const shouldSkipHpRoutine = useFeatureIsOn(CHARLIE_SKIP_HG_ROUTINE_BACKUP);
  const dispatch = useDispatch();
  const { ticket: ticketsLocale } = useLocale();
  const { restorateBackupHgRoutine: restorateBackupHgRoutineLocale } = ticketsLocale.forms;
  const [selectedRestorationType, setSelectedRestoratoinType] = useState(restorateBackupHgRoutineLocale.selectRestorationType.full);
  const [userName, setUserName] = useState('');
  const [server, setServer] = useState('');
  const [domain, setDomain] = useState('');
  const [backupOptions, setBackupOptions] = useState([]);
  const [selectedBackupOption, setSelectedBackupOption] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [specificDomains, setSpecificDomains] = useState('');
  const [specificDatabase, setSpecificDatabase] = useState('');
  const [specificFiles, setSpecificFiles] = useState('');

  useEffect(() => {
    if (selectedProduct && selectedProduct.domain) {
      setUserName(selectedProduct.username);
      setServer((selectedProduct.serverhostname || selectedProduct.dedicatedip) || '');
      setDomain(selectedProduct.domain);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (forceState && rules.active === 'restorateBackupHgRoutine') {
      setSelectedRestoratoinType(rules.restorateBackupHgRoutine.selectedRestorationType);
      setSelectedBackupOption(rules.restorateBackupHgRoutine.selectedBackupOption);
    }
  }, [forceState, rules.active, rules.restorateBackupHgRoutine.selectedRestorationType, rules.restorateBackupHgRoutine.selectedBackupOption]);

  const requestHgRoutineBackups = useCallback(() => {
    if (selectedProduct && selectedProduct.id) {
      dispatch(formsActions.backupHgRoutine.request({ id: selectedProduct.id }));
    }
  }, [dispatch, selectedProduct]);

  useEffect(() => {
    requestHgRoutineBackups();
  }, [requestHgRoutineBackups]);

  const mountBackupOptions = useCallback(() => {
    if (hgRoutineBackups) {
      const options = [];
      if (hgRoutineBackups.weekly) {
        options.push({
          label: `${restorateBackupHgRoutineLocale.options.weekly} - ${format(new Date(hgRoutineBackups.weekly), 'dd/MM/yyyy')} às ${format(new Date(hgRoutineBackups.weekly), 'HH:mm')}h`,
          value: hgRoutineBackups.weekly,
        });
      }

      if (hgRoutineBackups.monthly) {
        options.push({
          label: `${restorateBackupHgRoutineLocale.options.monthly} - ${format(new Date(hgRoutineBackups.monthly), 'dd/MM/yyyy')} às ${format(new Date(hgRoutineBackups.monthly), 'HH:mm')}h`,
          value: hgRoutineBackups.monthly,
        });
      }
      setBackupOptions(options);
    }
  }, [hgRoutineBackups, restorateBackupHgRoutineLocale]);

  useEffect(() => {
    mountBackupOptions();
  }, [mountBackupOptions]);

  const restorationTypeOptions = [
    { value: 'full', label: restorateBackupHgRoutineLocale.selectRestorationType.full },
    { value: 'database', label: restorateBackupHgRoutineLocale.selectRestorationType.database },
    { value: 'file', label: restorateBackupHgRoutineLocale.selectRestorationType.file },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const bodyDatabase = `
    nome do banco de dados: ${specificDatabase}
    `;
    const bodyFile = `
    arquivos: ${specificDatabase}
    domínios: ${specificDomains}
    `;

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.restorateBackupHgRoutine,
      key: V2Forms.RESTORATE_HG_ROUTINE,
      tags: [
        selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.database
          ? 'aut-bkp-db_site'
          : 'aut-bkp-tipodebackup',
        'fluxo_backup',
      ],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.restorateBackupHgRoutine,
      },
      body: `
      ${selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.database ? bodyDatabase : ''}
      ${selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.file ? bodyFile : ''}
      ${restorateBackupHgRoutineLocale.body.productId}: ${selectedProduct.id}.
      ${restorateBackupHgRoutineLocale.body.server}: ${server}
      ${restorateBackupHgRoutineLocale.body.user}: ${userName}
      ${restorateBackupHgRoutineLocale.body.domain}: ${domain}
      ${restorateBackupHgRoutineLocale.body.backupName}: ${selectedBackupOption || restorateBackupHgRoutineLocale.body.uninformed}
      ${restorateBackupHgRoutineLocale.body.agree}: ${agreed ? restorateBackupHgRoutineLocale.body.yes : restorateBackupHgRoutineLocale.body.no}
      `,
    };

    if (shouldSkipHpRoutine) {
      data.skipHgRoutineBackup = selectedProduct.id;
    }

    submitForm && submitForm(data);
  };

  const checkForm = useCallback(() => {
    let isOk = false;

    const checkDatabase = () => {
      if (selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.database) {
        return specificDatabase.length > 0;
      }

      return true;
    };

    const checkSites = () => {
      if (selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.file) {
        return specificDomains.length > 0 || specificFiles.length > 0;
      }

      return true;
    };

    isOk = agreed
    && userName.length
    && server.length
    && domain.length
    && checkDatabase()
    && checkSites();

    return isOk;
  }, [
    domain,
    userName,
    server,
    restorateBackupHgRoutineLocale,
    specificDatabase,
    selectedRestorationType,
    specificFiles,
    specificDomains,
    agreed,
  ]);

  if (loadingHgBackupHistory) {
    return (
      <Styles.Form>
        <Styles.InputWrapper>
          <IconLoading />
        </Styles.InputWrapper>
      </Styles.Form>
    );
  }

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {restorateBackupHgRoutineLocale.terms}
      </Styles.Info>

      <Styles.Info>
        {restorateBackupHgRoutineLocale.termsItems}
      </Styles.Info>

      <Styles.Alert>
        <Alert
          variant="error"
          withoutBackground
          title={restorateBackupHgRoutineLocale.alert.title}
          description={restorateBackupHgRoutineLocale.alert.description}
        />
      </Styles.Alert>

      <Styles.Title>
        {restorateBackupHgRoutineLocale.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Select
          name="restoration_type"
          testId="restoration_type"
          placeholder={restorateBackupHgRoutineLocale.fields.restorationType}
          label={selectedRestorationType ? restorateBackupHgRoutineLocale.fields.restorationType : ''}
          options={restorationTypeOptions}
          value={selectedRestorationType}
          setValue={label => setSelectedRestoratoinType(label)}
          withScroll
        />

        <Input
          name="user"
          testId="user"
          placeholder={restorateBackupHgRoutineLocale.fields.userLabel}
          label={userName ? restorateBackupHgRoutineLocale.fields.user : ''}
          value={userName}
          onChange={e => setUserName(e.target.value)}
        />

        <Input
          name="server"
          testId="server"
          placeholder={restorateBackupHgRoutineLocale.fields.serverLabel}
          label={server ? restorateBackupHgRoutineLocale.fields.server : ''}
          value={server}
          onChange={e => setServer(e.target.value)}
        />

        <Input
          name="domain"
          testId="domain"
          placeholder={restorateBackupHgRoutineLocale.fields.domainLabel}
          label={domain ? restorateBackupHgRoutineLocale.fields.domain : ''}
          value={domain}
          onChange={e => setDomain(e.target.value)}
        />

        {selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.database && (
          <Input
            testId="backupPicker"
            name="specificDatabase"
            label={specificDomains ? restorateBackupHgRoutineLocale.fields.specificDatabase : ''}
            placeholder={restorateBackupHgRoutineLocale.fields.specificDatabase}
            value={specificDatabase}
            onChange={e => setSpecificDatabase(e.target.value)}
          />
        )}

        {(selectedRestorationType === restorateBackupHgRoutineLocale.selectRestorationType.file) && (
          <>
            <Input
              name="specificDomains"
              testId="specificDomains"
              label={specificDomains ? restorateBackupHgRoutineLocale.fields.specificDomains : ''}
              placeholder={restorateBackupHgRoutineLocale.fields.specificDomains}
              value={specificDomains}
              onChange={e => setSpecificDomains(e.target.value)}
            />

            <Input
              name="specificFiles"
              testId="specificFiles"
              label={specificDomains ? restorateBackupHgRoutineLocale.fields.specificFiles : ''}
              placeholder={restorateBackupHgRoutineLocale.fields.specificFiles}
              value={specificFiles}
              onChange={e => setSpecificFiles(e.target.value)}
            />
          </>
        )}
      </Styles.InputWrapper>

      {(backupOptions.length > 0) && (
        <>
          <Styles.Info>
            {restorateBackupHgRoutineLocale.selectBackupTitle}
          </Styles.Info>
          <Styles.InputWrapper>
            <Select
              name="restoration_type"
              placeholder={restorateBackupHgRoutineLocale.fields.backup}
              label={backupOptions ? restorateBackupHgRoutineLocale.fields.backup : ''}
              options={backupOptions}
              value={selectedBackupOption}
              setValue={label => setSelectedBackupOption(label)}
              withScroll
            />
          </Styles.InputWrapper>
        </>
      )}

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => setAgreed(!agreed)}
      >
        <Checkbox checked={agreed} id="readTerms" />
        <Styles.Text>
          {restorateBackupHgRoutineLocale.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.InputWrapper>
        <Button
          size="large"
          testId="submit"
          label={opening ? <IconLoading /> : restorateBackupHgRoutineLocale.open}
          disabled={!checkForm() || opening}
          type="submit"
        />
      </Styles.InputWrapper>
    </Styles.Form>
  );
};

export default RestorateBackupHgRoutine;
