import React from 'react';
import * as Style from './IconJoomla.style';

const IconJoomla = ({
  color = '#4a4a4a',
  size = '27',
  testId = 'icon-joomla',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26.972 26.991" fill={color}>
      <g id="Grupo_39829" data-name="Grupo 39829" transform="translate(-966.514 -516.613)">
        <g id="_1632519_circle_joomla_round_icon_icon" data-name="1632519_circle_joomla_round icon_icon" transform="translate(966.514 516.613)">
          <path id="Caminho_247724" data-name="Caminho 247724" d="M279.83,222.5a3.607,3.607,0,0,1-7.143.721,5.952,5.952,0,0,1-5.987-1.492l2.658-2.668a2.215,2.215,0,0,0,3.132-3.132l-5.661-5.661L269.5,207.6l5.651,5.671a5.962,5.962,0,0,1,1.581,5.651A3.611,3.611,0,0,1,279.83,222.5Z" transform="translate(-252.858 -199.123)" />
          <path id="Caminho_247725" data-name="Caminho 247725" d="M129.716,128.953a5.986,5.986,0,0,0,1.5,5.957l5.661,5.661,2.658-2.668-5.661-5.671a2.236,2.236,0,0,1,0-3.142,2.206,2.206,0,0,1,3.132,0l2.658-2.668a5.95,5.95,0,0,0-5.6-1.591,3.607,3.607,0,1,0-4.347,4.12Z" transform="translate(-126.87 -121.8)" />
          <path id="Caminho_247726" data-name="Caminho 247726" d="M214.971,126.624a5.971,5.971,0,0,1,5.918-1.512,3.61,3.61,0,1,1,4.051,4.09,5.985,5.985,0,0,1-1.521,5.888l-2.658-2.668a2.215,2.215,0,0,0-3.132-3.132l-5.661,5.661-2.668-2.668Z" transform="translate(-201.129 -121.98)" />
          <path id="Caminho_247727" data-name="Caminho 247727" d="M139.394,273.377a5.951,5.951,0,0,1-5.671,1.571,3.608,3.608,0,1,1-4.347-4.327,5.993,5.993,0,0,1,1.571-5.72l2.658,2.668a2.215,2.215,0,0,0,3.132,3.132l5.661-5.671,2.658,2.668Z" transform="translate(-126.6 -250.762)" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconJoomla;
