const styles = theme => ({
  input: {
    width: '100%',
    fontSize: '14px',
    margin: '6px 14px 0 0',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 0,
    },
    '& input': {
      padding: '7px',
      paddingBottom: '8px',
    },
    [theme.breakpoints.down(1166)]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '14px',
      marginLeft: 0,
      '& fieldset': {
        width: '100%',
      },
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
});

export default styles;
