import { useMemo } from 'react';
import { FieldValidators } from '@/utils/FieldValidators';

const useWizardWordpressSetupForm = (userEmail, isWondersuiteEnabled) => {
  const userMinimumSize = isWondersuiteEnabled ? 4 : 8;

  const defaultFormState = useMemo(() => ({
    name: {
      value: '',
      empty: true,
      error: false,
    },
    description: {
      value: '',
      empty: true,
      error: false,
    },
    email: {
      value: userEmail,
      empty: false,
      error: !FieldValidators.email(userEmail),
    },
    user: {
      value: '',
      empty: true,
      error: false,
    },
    password: {
      value: '',
      empty: true,
      error: false,
    },
    confirmPassword: {
      value: '',
      empty: true,
      error: false,
    },
  }), [userEmail]);

  const validatePasswordOnChange = ({ event, wodpressSetupValues }) => {
    const { value, name: fieldName } = event.target;
    let newFormValues = null;

    if (fieldName === 'password') {
      const hasLowerAndUpperCase = FieldValidators.hasLowerAndUper(value);
      const hasNumber = FieldValidators.hasNumber(value);
      const hasSymbol = FieldValidators.hasSpecial(value);
      const hasAtLeastEight = value.length >= 8;
      const matchConfirmation = wodpressSetupValues.confirmPassword.value === value;

      newFormValues = {
        ...wodpressSetupValues,
        confirmPassword: {
          ...wodpressSetupValues.confirmPassword,
          error: !matchConfirmation,
          matchConfirmation,
        },
        [fieldName]: {
          ...wodpressSetupValues[fieldName],
          empty: value.length === 0,
          value,
          error: !hasLowerAndUpperCase || !hasNumber || !hasSymbol || !hasAtLeastEight,
          hasLowerAndUpperCase,
          hasNumber,
          hasSymbol,
          hasAtLeastEight,
          matchConfirmation,
        },
      };

      return newFormValues;
    }

    if (fieldName === 'confirmPassword') {
      const matchConfirmation = wodpressSetupValues.password.value === value;

      newFormValues = {
        ...wodpressSetupValues,
        password: {
          ...wodpressSetupValues.password,
          matchConfirmation,
        },
        [fieldName]: {
          ...wodpressSetupValues[fieldName],
          empty: value.length === 0,
          value,
          error: !matchConfirmation,
        },
      };

      return newFormValues;
    }
  };

  const validateUserOnChange = ({ event, wodpressSetupValues }) => {
    const { value, name: fieldName } = event.target;
    let newFormValues = null;

    const notHaveSymbol = FieldValidators.userWordpressHasNotSpecial(value);
    const hasRequiredSize = value.length >= userMinimumSize;

    newFormValues = {
      ...wodpressSetupValues,
      user: {
        ...wodpressSetupValues[fieldName],
        empty: value.length === 0,
        value,
        error: !notHaveSymbol || !hasRequiredSize,
        hasRequiredSize,
        notHaveSymbol,
      },
    };

    return newFormValues;
  };

  const handleUpdateWordpressSetupValues = ({ event, wodpressSetupValues }) => {
    const { name: fieldName, value } = event.target;
    const isPassword = fieldName === 'password' || fieldName === 'confirmPassword';
    const isUser = fieldName === 'user';
    let newFormValues = null;

    if (!isPassword && !isUser) {
      newFormValues = {
        ...wodpressSetupValues,
        [fieldName]: {
          ...wodpressSetupValues[fieldName],
          empty: value.length === 0,
          value,
        },
      };

      return newFormValues;
    }

    if (isPassword) {
      return validatePasswordOnChange({ event, wodpressSetupValues });
    }
    if (isUser) {
      return validateUserOnChange({ event, wodpressSetupValues });
    }
  };

  const handleBlurWordpressSetupValues = ({ event, wodpressSetupValues }) => {
    const { name: fieldName, value } = event.target;
    const isNotEmail = fieldName !== 'password' && fieldName !== 'confirmPassword' && fieldName !== 'user';
    let newFormValues = null;

    const rules = {
      name: value.length < 3,
      description: false,
      email: !FieldValidators.email(value),
    };

    if (isNotEmail) {
      newFormValues = {
        ...wodpressSetupValues,
        [fieldName]: {
          ...wodpressSetupValues[fieldName],
          error: rules[fieldName],
        },
      };

      return newFormValues;
    }
  };

  const validateWordPressInfoInputs = ({ wodpressSetupValues }) => {
    let setupData = { ...wodpressSetupValues };
    const fields = ['name', 'email'];

    fields.forEach((fieldName) => {
      setupData[fieldName].error = false;

      const rules = {
        name: wodpressSetupValues.name.value.length < 3,
        email: !FieldValidators.email(wodpressSetupValues.email.value),
      };

      setupData = {
        ...setupData,
        [fieldName]: {
          ...setupData[fieldName],
          error: rules[fieldName],
        },
      };
    });

    return setupData;
  };

  const wordpressSetupData = {
    defaultFormState,
    validatePasswordOnChange,
    validateUserOnChange,
    handleUpdateWordpressSetupValues,
    handleBlurWordpressSetupValues,
    validateWordPressInfoInputs,
  };

  return wordpressSetupData;
};

export default useWizardWordpressSetupForm;
