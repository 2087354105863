import styled, { css } from 'styled-components';

export const ClosedWrapper = styled.button`
${({ theme }) => css`
align-items: center;
  background-color: ${theme.color.oceanGreen};
  border: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  outline: none;
  padding: 16px 0 8px 0;
  `
}
`;

export const VerticalText = styled.p`
${({ theme }) => css`
  color: ${theme.color.white};
  font-family: ${theme.font.family.secondary};
  font-size: 16px;
  margin:0;
  padding: 0px 8px 0px 8px;
  text-orientation: mixed;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  `
}
`;
