import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
  ${({
    theme, variant, color, bgColor,
  }) => {
    const variants = {
      small: {
        fontSize: theme.font.sizes.xxxxs,
        lineHeight: '11px',
        padding: '3px 6px 2px 6px',
      },
      default: {
        fontSize: theme.font.sizes.xxs,
        lineHeight: '16px',
        padding: '4px 8px',
      },
    };

    return css`
      align-items: center;
      background-color: ${bgColor};
      color: ${color};
      display: inline-flex;
      padding: ${variants[variant].padding};
      font-size: ${variants[variant].fontSize};
      font-weight: ${theme.font.weights.regular};
      letter-spacing: 0.01px;
      line-height: ${variants[variant].lineHeight};
      margin: 0px;
      text-transform: capitalize;
      border-radius: 2px;
      white-space: nowrap;
    `;
  }
}
`;
