import React from 'react';
import * as Style from './IconArrowWardsLeft.style';

const IconArrowWardsLeft = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-arrow-wards-left">
    <svg id="keyboard_backspace_black_24dp" fill={color} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Caminho_244582" data-name="Caminho 244582" d="M0,0H16V16H0Z" fill="none" />
      <path id="Caminho_244583" data-name="Caminho 244583" d="M15,9.333H5.553L7.94,6.94,7,6,3,10l4,4,.94-.94L5.553,10.666H15Z" transform="translate(-1 -1.999)" />
    </svg>
  </Style.Wrapper>
);


export default IconArrowWardsLeft;
