import { InvoicesActionTypes } from './invoices.types';

export const initialState = {
  orderCancelStatus: undefined,
  orderCancelLoading: false,
  invoices: {
    listOfNfse: null,
    currentInvoiceId: [],
  },
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case InvoicesActionTypes.CANCEL_ORDER_SUCCESS:
    case InvoicesActionTypes.CANCEL_ORDER_FAILURE: {
      return {
        ...state,
        orderCancelStatus: action.payload,
      };
    }
    case InvoicesActionTypes.CANCEL_ORDER_LOADING: {
      return {
        ...state,
        orderCancelLoading: action.payload,
      };
    }
    case InvoicesActionTypes.SET_NFSE: {
      return {
        ...state,
        invoices: action.payload,
      };
    }
    default:
      return state;
  }
};

export default alertsReducer;
