import React, {
  useState, useCallback, useEffect, useRef, useMemo,
} from 'react';
import { IconArrowDownOutlined } from '@/icons';
import * as Styles from './Select.styles';

const Select = ({
  defaultOption = null,
  defaultOptionLabel = 'Select',
  disabled = false,
  onChange,
  onClick,
  options = [],
  testId = 'select',
  placeholder = 'selecionar objetivo',
  variant = 'default',
  minWidth = null,
  maxWidth = null,
  optionsPosition = 'bottom',
  optionsHeight = null,
  selectedResetOnChangeOf = null,
  breakLine = false,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const defaultSelectOption = useMemo(
    () => ({
      id: 'default',
      label: defaultOptionLabel,
      value: '',
    }),
    [defaultOptionLabel],
  );

  useEffect(() => {
    setSelected(null);
  }, [selectedResetOnChangeOf]);

  const [selectOptions, setSelectOptions] = useState(placeholder ? [...options] : [defaultSelectOption, ...options]);

  useEffect(() => {
    setSelectOptions(placeholder ? [...options] : [defaultSelectOption, ...options]);
  }, [options, setSelectOptions, placeholder, defaultSelectOption]);

  useEffect(() => {
    if (defaultOption !== null && !selected) {
      setSelected({ value: selectOptions[defaultOption], callOnChange: false });
    }

    if (!placeholder && !defaultOption) {
      if (JSON.stringify(selectOptions) !== JSON.stringify([defaultSelectOption, ...options])) {
        setSelectOptions([defaultSelectOption, ...options]);
        setSelected(null);
      }

      if (!selected && selectOptions.length > 0) {
        const clamped = Math.min(Math.max(defaultOption, 0), selectOptions.length - 1);
        setSelected({ value: selectOptions[clamped], callOnChange: false });
      }
    }

    if (placeholder && !selected && selectOptions.length === 1 && !defaultOption) {
      const clamped = Math.min(Math.max(defaultOption, 0), selectOptions.length - 1);
      setSelected({ value: selectOptions[clamped], callOnChange: false });
    }
  }, [defaultOption, selectOptions, selected, options, defaultSelectOption, placeholder]);

  const onSelectClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onOptionClick = useCallback(
    (option) => {
      onClick && onClick(option);
      if (selected && selected.value.id === option.id) {
        setIsOpen(false);
        return;
      }
      setSelected({ value: option, callOnChange: true });
      setIsOpen(false);
    },
    [selected, onClick],
  );

  const callOnChange = useCallback(() => {
    if (selected && onChange && selected.callOnChange) {
      onChange && onChange(selected.value);
      setSelected({ value: selected.value, callOnChange: false });
    }
  }, [onChange, selected]);

  useEffect(() => {
    callOnChange();
  }, [callOnChange]);

  const onMouseDown = useCallback(
    (event) => {
      (
        isOpen
        && wrapperRef.current
        && !wrapperRef.current.contains(event.target)
      ) && setIsOpen(false);
    },
    [isOpen],
  );

  const optionsMap = selectOptions.map((option, index) => (
    <Styles.OptionItem
      data-testid={`${testId}-option-${option.id}`}
      key={`id-${option.id}-order-${index.toString()}`}
      onClick={() => onOptionClick(option)}
      variant={variant}
    >
      <Styles.OptionLabel>{option.label}</Styles.OptionLabel>
    </Styles.OptionItem>
  ));

  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseDown]);

  const validateEmptySelect = useCallback(() => {
    if ((placeholder && !selectOptions.length) || !placeholder) {
      setIsEmpty(selectOptions.length === 1);
    }
  }, [placeholder, selectOptions.length]);

  useEffect(() => {
    validateEmptySelect();
  }, [validateEmptySelect]);

  return (
    <Styles.Wrapper
      data-testid={testId}
      ref={wrapperRef}
      disabled={isEmpty || disabled}
      variant={variant}
      maxWidth={maxWidth}
      minWidth={minWidth}
    >
      <Styles.Field
        data-testid={`${testId}-field`}
        onClick={onSelectClick}
        disabled={isEmpty || disabled}
        variant={variant}
        isOpen={isOpen}
      >
        <Styles.Label
          variant={variant}
          selected={selected}
          data-testid={`${testId}-label`}
        >
          {selected ? selected.value.label : placeholder}
        </Styles.Label>

        <Styles.IconWrapper variant={variant}>
          <IconArrowDownOutlined />
        </Styles.IconWrapper>

        <Styles.OptionsWrapper show={isOpen} optionsPosition={optionsPosition}>
          {options.length > 0 && (
            <Styles.OptionsList breakLine={breakLine} data-testid={`${testId}-options`} show={isOpen} variant={variant} optionsHeight={optionsHeight}>
              {optionsMap}
            </Styles.OptionsList>
          )}
        </Styles.OptionsWrapper>
      </Styles.Field>

    </Styles.Wrapper>
  );
};

export default Select;
