import { CALL_API } from '@/middleware/api';
import {
  CODEGUARD_DOMAINS, CODEGUARD_BUY_FAST_CHECKOUT, CODEGUARD_HML_PAYMENT_METHOD, CODEGUARD_PRICE, CODEGUARD_HML_CHECKOUT_PRODUCT,
} from '@/config/api';
import { COUNTRY, config } from '@/config';
import {
  RECEIVE_CODEGUARD_PRICE,
  REQUEST_CODEGUARD_DOMAINS,
  RECEIVE_CODEGUARD_DOMAINS,
  ERROR_CODEGUARD_DOMAINS,
  ABORT_CODEGUARD_DOMAINS,
  RECEIVE_CODEGUARD_BASIC_PRICE,
  REQUEST_CODEGUARD_BASIC_PRICE,
  ABORT_CODEGUARD_BASIC_PRICE,
  ERROR_CODEGUARD_BASIC_PRICE,
  ABORT_CODEGUARD_BUY_FAST_CHECKOUT,
  REQUEST_CODEGUARD_BUY_FAST_CHECKOUT,
  RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT,
  ERROR_CODEGUARD_BUY_FAST_CHECKOUT,
  ABORT_CODEGUARD_PAYMENT_METHOD,
  REQUEST_CODEGUARD_PAYMENT_METHOD,
  RECEIVE_CODEGUARD_PAYMENT_METHOD,
  ERROR_CODEGUARD_PAYMENT_METHOD,
} from './actionsTypes';
import { checkoutProductAdapter } from '@/utils/Formatters/checkoutProductAdapter';

export const receiveDomains = (codeguards) => {
  const { codeguard } = codeguards;
  return {
    type: RECEIVE_CODEGUARD_DOMAINS,
    codeguard,
    total: codeguard.length,
  };
};

export const loadCGDomains = () => (dispatch, getState) => {
  const { loadedDate } = getState().codeguard;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_CODEGUARD_DOMAINS }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: CODEGUARD_DOMAINS,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_DOMAINS }),
        success: response => receiveDomains(response.data),
        error: () => ({ type: ERROR_CODEGUARD_DOMAINS }),
      },
    },
  });
};

export const receivePrice = (codeguards) => {
  const { prices: basicPrice } = codeguards;

  return {
    type: RECEIVE_CODEGUARD_BASIC_PRICE,
    basicPrice,
  };
};

export const getPrices = () => (dispatch, getState) => {
  const { loadedDate } = getState().codeguard;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_CODEGUARD_BASIC_PRICE }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: CODEGUARD_HML_CHECKOUT_PRODUCT,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
        Accept: 'application/json, text/plain, */*',
      },
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_BASIC_PRICE }),
        success: response => ({ type: RECEIVE_CODEGUARD_BASIC_PRICE, data: checkoutProductAdapter(response) }),
        error: () => ({ type: ERROR_CODEGUARD_BASIC_PRICE }),
      },
    },
  });
};


export const receiveCodeGuardPrice = (codeGuard) => {
  const { basic_price: basicPrice } = codeGuard;

  return {
    type: RECEIVE_CODEGUARD_PRICE,
    basicPrice,
  };
};

export const loadBasicPrice = () => (dispatch, getState) => {
  const { loadedDate } = getState().codeguard;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_CODEGUARD_BASIC_PRICE }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: CODEGUARD_PRICE,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
        Accept: 'application/json, text/plain, */*',
      },
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_BASIC_PRICE }),
        success: response => receiveCodeGuardPrice(response.data),
        error: () => ({ type: ERROR_CODEGUARD_BASIC_PRICE }),
      },
    },
  });
};

export const getPaymentMethodsCG = () => (dispatch, getState) => {
  const { paymentLoadedDate } = getState().codeguard.cta;
  if ((Date.now() - paymentLoadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_CODEGUARD_PAYMENT_METHOD }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: CODEGUARD_HML_PAYMENT_METHOD,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_PAYMENT_METHOD }),
        success: response => ({ type: RECEIVE_CODEGUARD_PAYMENT_METHOD, data: response }),
        error: () => ({ type: ERROR_CODEGUARD_PAYMENT_METHOD }),
      },
    },
  });
};

export const buyCodeGuard = ({
  productId, paymentMethod, productCycle,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().codeguard.buyFastcheckout;

  if (isLoading) {
    return Promise.resolve(dispatch({ type: ABORT_CODEGUARD_BUY_FAST_CHECKOUT }));
  }

  const bodyObject = {
    products: [
      {
        id: productId,
        domain: 'fims.com',
        cycle: productCycle,
        type: 'product',
        quantity: 1,
      },
    ],
    payment_method: paymentMethod,
    offer_contexts: [
      'codeguard_landing_page_cta_top',
    ],
  };

  return dispatch({
    [CALL_API]: {
      endpoint: CODEGUARD_BUY_FAST_CHECKOUT,
      method: 'POST',
      body: bodyObject,
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_BUY_FAST_CHECKOUT }),
        success: response => ({ type: RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT, data: { ...response, paymentMethod, invoice_id: response.data.invoice_id } }),
        error: () => ({ type: ERROR_CODEGUARD_BUY_FAST_CHECKOUT }),
      },
    },
  });
};
