import React from 'react';
import { withStyles } from '@material-ui/core';
import { Forum } from '@material-ui/icons';

import styles from './styles';


const TicketHeaderIcon = ({ classes }) => (
  <Forum className={`${'status-icon'} ${classes.icon}`} />
);

export default withStyles(styles)(TicketHeaderIcon);
