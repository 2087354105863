import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';

import styles from './styles';


const ActionButton = ({
  classes,
  t,
  action,
  children,
  color,
  variant,
  className,
  ...rest
}) => (
  <Button
    data-testid="action-button-component"
    className={`${classes.button} ${className}`}
    color={color}
    variant={variant}
    onClick={action}
    {...rest}
  >
    {children}
  </Button>
);

export default withStyles(styles)(ActionButton);
