import React from 'react';
import * as Styles from './ValidateEmail.styles';
import { SendAgain } from '../SendAgain';
import { Button, IconLoading } from 'gatorcomponents';

const ValidateEmail = ({
  validateEmailLocale,
  emailVerifyLocale,
  clientInfo,
  countDownResend,
  setCountDownResend,
  handleResendEmail,
  loadingResend,
  handleChangeEmail,
}) => (
  <Styles.Wrapper data-testid="ValidateEmail">
    <Styles.Title>
      {validateEmailLocale.title}
    </Styles.Title>

    <Styles.Description>
      {validateEmailLocale.description({ email: clientInfo.email })}
    </Styles.Description>

    {countDownResend > 0
      ? (
        <Styles.SendAgainWrapper>
          <SendAgain
            emailVerifyLocale={emailVerifyLocale}
            countDownResend={countDownResend}
            setCountDownResend={setCountDownResend}
          />
        </Styles.SendAgainWrapper>
      )
      : (
        <>

          <Styles.TitleNotReceived>{emailVerifyLocale.emailNotRecived}</Styles.TitleNotReceived>
          <Styles.DescriptionNotReceived>
            {emailVerifyLocale.tryResendDescription}
          </Styles.DescriptionNotReceived>

          <Styles.ButtonWrapper>
            <Button
              type="button"
              width="fit"
              label={validateEmailLocale.changeEmail}
              onClick={handleChangeEmail}
              variant="tertiary"
              testId="redirectChangeEmail"
            />

            <Button
              type="button"
              testId="submit"
              width="fit"
              label={loadingResend ? <IconLoading /> : validateEmailLocale.resendButtonLabel}
              onClick={handleResendEmail}
              variant="secondary"
              disabled={loadingResend}
            />
          </Styles.ButtonWrapper>
        </>
      )}
  </Styles.Wrapper>
);

export default ValidateEmail;
