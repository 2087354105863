import PropTypes from 'prop-types';

export const PaymentModalProps = {
  cancelRenewalDescriptionLabel: PropTypes.string,
  cancelRenewalLabel: PropTypes.string,
  cancelImmediateDescriptionLabel: PropTypes.string,
  cancelImmediateLabel: PropTypes.string,
  continueButtonLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  onCloseClick: PropTypes.func,
  onContinueButtonClick: PropTypes.func,
  onQuitButtonClick: PropTypes.func,
  quitButtonLabel: PropTypes.string,
  titleLabel: PropTypes.string,
};

export const PaymentMethods = {
  CREDIT_CARD: 'CREDIT_CARD',
  TICKET: 'BOLETO',
  PAY_PAL: 'PAY_PAL',
};

export const OriginCalling = {
  INVOICES_MANAGER: 'INVOICES_MANAGER',
  SUBSCRIPTION_LIST: 'SUBSCRIPTION_LIST',
  SUBSCRIPTION_MANAGER: 'SUBSCRIPTION_MANAGER',
  SITELOCK_UPGRADE: 'SITELOCK_UPGRADE',
};

export const DiscountType = {
  RENEWAL: 'RENEWAL',
  SAVERS35OFF: 'SAVERS35OFF',
  RETENTION: 'HOSTINGRETENTION',
};
