import { locale } from '@/utils/locale';

export const ACADEMY_PASS_PRODUCT_ID = {
  br: 427,
};

export const ACADEMY_PASS_TRIAL_PRODUCT_ID = {
  br: 431,
};

export const ACADEMY_PASS_SUBJECTS = [
  {
    id: 'option-design', label: locale('academypass.filter.subjectList.design'), value: 'design', checked: false,
  },
  {
    id: 'option-domain', label: locale('academypass.filter.subjectList.domain'), value: 'domain', checked: false,
  },
  {
    id: 'option-email', label: locale('academypass.filter.subjectList.email'), value: 'email', checked: false,
  },
  {
    id: 'option-ecommerce', label: locale('academypass.filter.subjectList.ecommerce'), value: 'ecommerce', checked: false,
  },
  {
    id: 'option-site_hosting', label: locale('academypass.filter.subjectList.site_hosting'), value: 'site_hosting', checked: false,
  },
  {
    id: 'option-digital_mkt', label: locale('academypass.filter.subjectList.digital_mkt'), value: 'digital_mkt', checked: false,
  },
  {
    id: 'option-digital_business', label: locale('academypass.filter.subjectList.digital_business'), value: 'digital_business', checked: false,
  },
  {
    id: 'option-programming', label: locale('academypass.filter.subjectList.programming'), value: 'programming', checked: false,
  },
  {
    id: 'option-social_media', label: locale('academypass.filter.subjectList.social_media'), value: 'social_media', checked: false,
  },
  {
    id: 'option-videos', label: locale('academypass.filter.subjectList.videos'), value: 'videos', checked: false,
  },
  {
    id: 'option-wp', label: locale('academypass.filter.subjectList.wp'), value: 'wp', checked: false,
  },
];
