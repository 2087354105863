import { PreChatActionTypes } from './preChat.types';

export const initialState = {
  loadingOfferContext: false,
  application: 'clientarea',
  loading: false,
  diagnosticLoaded: false,
  sessionOpen: false,
  invoices: {},
  refunds: [],
  backups: [],
  backup_available: [],
  duplicateOrders: [],
  pendingOrders: [],
  customer: {},
  open: false,
  chatDisplay: false,
  invoiceId: null,
  screenToShowInPreChat: 'diagnostic',
  trustReactivation: [],
  billingGenerated: false,
  reactivationRedirect: false,
  trustReactivationInvoiceId: null,
  invoiceTicketWillOpen: false,
  productsList: {},
  subscriptions: {},
  productsLoading: true,
  triage: {
    subscriptionToCancel: null,
    subscriptionToChangePaymentMethod: null,
    holdDomainRedirect: false,
    onEppKeyFlow: false,
    domainReservedFlow: false,
    redirectManageSubscription: false,
  },
  domainsPropagation: [],
  tos: {
    redirect: false,
    tickets: [],
  },
  vpsUpgrade: {
    redirect: false,
    termsAccepted: false,
    productId: null,
  },
  productDetails: {},
  productDetailsLoading: false,
  weekDay: null,
  ssl: [],
  sitelock: [],
  showSidePanel: false,
};

const preChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case PreChatActionTypes.SET_SUCCESS_OFFER_CONTEXT:
    case PreChatActionTypes.SET_FAILURE_OFFER_CONTEXT: {
      return {
        ...state,
        loadingOfferContext: false,
      };
    }
    case PreChatActionTypes.SET_DIAGNOSTIC_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case PreChatActionTypes.SET_DIAGNOSTIC: {
      const { diagnostic } = action.payload;

      return {
        ...state,
        invoices: diagnostic.invoices,
        subscriptions: diagnostic.subscriptions,
        refunds: diagnostic.refunds,
        backups: diagnostic.backups,
        backup_available: diagnostic.backup_available,
        customer: diagnostic.customer,
        trustReactivation: diagnostic.trustReactivation,
        domainsPropagation: diagnostic.domains_propagation,
        duplicateOrders: diagnostic.duplicate_orders,
        pendingOrders: diagnostic.pending_orders,
        tos: {
          ...state.tos,
          tickets: diagnostic.tickets,
        },
        ssl: diagnostic.ssl,
        sitelock: diagnostic.sitelock,
      };
    }
    case PreChatActionTypes.SET_OPEN: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case PreChatActionTypes.SET_INVOICE_ID: {
      return {
        ...state,
        invoiceId: action.payload,
      };
    }
    case PreChatActionTypes.SET_SCREEN_TO_SHOW_IN_PRECHAT: {
      return {
        ...state,
        screenToShowInPreChat: action.payload,
      };
    }
    case PreChatActionTypes.SET_SESSION: {
      return {
        ...state,
        sessionOpen: action.payload,
      };
    }
    case PreChatActionTypes.RELOAD_DIAGNOSTIC: {
      return {
        ...initialState,
        open: state.open,
      };
    }
    case PreChatActionTypes.GET_DIAGNOSTIC_SUCCESS: {
      return {
        ...state,
        diagnosticLoaded: true,
      };
    }
    case PreChatActionTypes.GET_DIAGNOSTIC_FAILURE: {
      return {
        ...state,
        diagnosticLoaded: false,
      };
    }
    case PreChatActionTypes.BILLING_GENERATED: {
      return {
        ...state,
        billingGenerated: action.payload,
      };
    }
    case PreChatActionTypes.REACTIVATION_REDIRECT: {
      return {
        ...state,
        reactivationRedirect: action.payload,
      };
    }
    case PreChatActionTypes.SET_TRUST_REACTIVATION_INVOICE_ID: {
      return {
        ...state,
        trustReactivationInvoiceId: action.payload,
      };
    }
    case PreChatActionTypes.SET_INVOICE_TICKET_WILL_OPEN: {
      return {
        ...state,
        invoiceTicketWillOpen: action.payload,
      };
    }
    case PreChatActionTypes.SET_PRODUCT_BY_STATUS: {
      return {
        ...state,
        productsList: action.payload.productsList,
      };
    }
    case PreChatActionTypes.SET_PRODUCTS_BY_STATUS_LOADING: {
      return {
        ...state,
        productsLoading: action.payload,
      };
    }
    case PreChatActionTypes.SET_SUBSCRIPTION_TO_CANCEL: {
      return {
        ...state,
        triage: {
          ...state.triage,
          subscriptionToCancel: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_SUBSCRIPTION_TO_CHANGE_PAYMENT_METHOD: {
      return {
        ...state,
        triage: {
          ...state.triage,
          subscriptionToChangePaymentMethod: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_TOS_REDIRECT: {
      return {
        ...state,
        tos: {
          ...state.tos,
          redirect: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_VPS_REDIRECT: {
      return {
        ...state,
        vpsUpgrade: {
          ...state.vpsUpgrade,
          redirect: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_VPS_PRODUCT_ID: {
      return {
        ...state,
        vpsUpgrade: {
          ...state.vpsUpgrade,
          productId: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_VPS_TERMS_ACCEPTED: {
      return {
        ...state,
        vpsUpgrade: {
          ...state.vpsUpgrade,
          termsAccepted: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_PRODUCT_DETAILS_LOADING: {
      return {
        ...state,
        productDetailsLoading: action.payload,
      };
    }
    case PreChatActionTypes.SET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case PreChatActionTypes.SET_HOLD_REDIRECT: {
      return {
        ...state,
        triage: {
          ...state.triage,
          holdDomainRedirect: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_EPP_KEY_FLOW: {
      return {
        ...state,
        triage: {
          ...state.triage,
          onEppKeyFlow: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_RESERVED_DOMAIN_FLOW: {
      return {
        ...state,
        triage: {
          ...state.triage,
          domainReservedFlow: action.payload,
        },
      };
    }
    case PreChatActionTypes.SET_WEEK_DAY: {
      return {
        ...state,
        weekDay: action.payload,
      };
    }
    case PreChatActionTypes.SET_SUBSCRIPTION_REDIRECT_FROM_PRECHAT: {
      return {
        ...state,
        triage: {
          ...state.triage,
          redirectManageSubscription: action.payload,
        },
      };
    }
    case PreChatActionTypes.HANDLE_CHAT_DISPLAY: {
      return {
        ...state,
        chatDisplay: !state.chatDisplay,
      };
    }
    case PreChatActionTypes.SET_SHOW_SIDE_PANEL: {
      return {
        ...state,
        showSidePanel: !state.showSidePanel,
      };
    }
    default:
      return state;
  }
};

export default preChatReducer;
