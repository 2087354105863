import React from 'react';
import * as Styles from './CancelSubscriptionPage.styles';
import { CancelSubscriptionHandler } from '@/components/billing';

const CancelSubscriptionPage = () => (
  <Styles.Wrapper data-testid="cancel-subscription-page">
    <CancelSubscriptionHandler />
  </Styles.Wrapper>
);

export default CancelSubscriptionPage;
