const styles = theme => ({
  cardDetail: {
    alignItems: 'flex-start',
    display: 'flex',
    paddingTop: 20,
    '@media(max-width: 710px)': {
      display: 'block',
    },
  },
  cardDetailItemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 30px',
    '@media(max-width: 710px)': {
      flexDirection: 'column',
      padding: 0,
      width: 'auto',
    },
  },
  cardDetailItemWrapper1: {
    borderRight: `1px dashed ${theme.color.mystic}`,
    flexDirection: 'column',
    width: '190px',
    '@media(max-width: 710px)': {
      borderRight: 0,
    },
  },
  cardDetailItemWrapper2: {
    borderRight: `1px dashed ${theme.color.mystic}`,
    flex: 1,
    flexDirection: 'column',
    '@media(max-width: 710px)': {
      borderBottom: `1px dashed ${theme.color.mystic}`,
      borderRight: 0,
      borderTop: `1px dashed ${theme.color.mystic}`,
      marginBottom: 15,
      paddingTop: 15,
      '&:first-child': {
        borderBottom: 'none',
        borderTop: 'none',
        marginBottom: 0,
        paddingTop: 0,
      },
    },
  },
  cardDetailItemWrapper3: {
    justifyContent: 'space-between',
    width: '310px',
  },
  cardDetailItem: {
    marginBottom: 15,
  },
  cardDetailItemColumn3: {
    width: '120px',
  },
  cardDetailTitle: {
    color: theme.color.regentGray,
    display: 'block',
    fontSize: 12,
    marginBottom: 5,
  },
  cardDetailValue: {
    color: theme.color.tundora,
    display: 'block',
    fontSize: 14,
    lineHeight: '18px',
  },
  cardDetailNoBorder: {
    borderRight: 'none',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 6,
    width: '100%',
  },
  backupInfo: {
    borderBottom: `1px dashed ${theme.color.mystic}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 40,
    paddingBottom: 25,

    '@media(max-width: 710px)': {
      flexDirection: 'column',
      padding: 0,
      width: 'auto',
    },
  },
  productInfo: {
    display: 'flex',
  },
  backupDescription: {
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: '400',
    margin: 0,
    paddingBottom: 17,
  },
});

export default styles;
