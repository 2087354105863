import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import * as Styles from './ManageCloudFlareHandler.styles';
import {
  Button, Skeleton, IconTrashBg, IconLive, IconSecurityCheck, IconDoneChecklist,
} from 'gatorcomponents';
import ManageCard from './components/ManageCard';
import useLocale from '@/hooks/useLocale';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { useSelector } from 'react-redux';
import { SUPPORT_DOMAINS_URL } from '@/config/urls/domainsUrls';
import { useResize } from '@/hooks/useResize';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { CHARLIE_CLOUDFLARE_FEATURES, CHARLIE_CLOUDFLARE_MANAGE_CDN } from '@/config/GrowthBook/constants';
import { useHistory } from 'react-router';
import CleanCacheModal from './components/CleanCacheModal';
import { FEATURES } from './ManageCloudFlareHandler.types';

export const ManageCloudFlareHandler = () => {
  // remover esse trecho de código após a integração
  const domainDataRequestDefault = useMemo(() => ({
    domain: 'domain.com.br',
    planName: 'Plano M',
    selectedDomainLabel: 'Domínio selecionado',
    changeDomainLabel: 'Alterar',
    loading: false,
    developerMode: false,
    underAttackMode: false,
    alwaysOnlineMode: false,
    cleanCacheLoading: false,
  }), []);

  const [domainDataRequest, setDomainDataRequest] = useState(domainDataRequestDefault);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDomainDataRequest(prevData => ({
        ...prevData,
        loading: false,
      }));
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (domainDataRequest.cleanCacheLoading) {
        setDomainDataRequest({
          ...domainDataRequest,
          cleanCacheLoading: false,
        });
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [domainDataRequest]);
  // até aqui

  const [developerMode, setDeveloperMode] = useState(domainDataRequest.developerMode);
  const [underAttackMode, setUnderAttackMode] = useState(domainDataRequest.underAttackMode);
  const [alwaysOnlineMode, setAlwaysOnlineMode] = useState(domainDataRequest.alwaysOnlineMode);
  const [displayCleanCacheModal, setDisplayCleanCacheModal] = useState(false);

  const cloudflareManagementIsEnabled = useFeatureIsOn(CHARLIE_CLOUDFLARE_MANAGE_CDN);
  const cloudflareFeatures = useFeatureValue(CHARLIE_CLOUDFLARE_FEATURES);

  const history = useHistory();

  const { cloudflare: cloudflareLocale, routes: routesLocale } = useLocale();
  const manageCloudflareLocale = cloudflareLocale.manageCdnHandler;

  const faq = useSelector(state => state.newFaq);

  const windowRect = useResize();
  const { width } = windowRect;
  const isMobile = width < 600;

  const handleToggleDeveloperMode = () => {
    console.log('Toggle developer mode'); // inserir request
    setDeveloperMode(!developerMode);
  };

  const handleToggleUnderAttackMode = () => {
    console.log('Toggle under attack mode'); // inserir request
    setUnderAttackMode(!underAttackMode);
  };

  const handleToggleAlwaysOnlineMode = () => {
    console.log('Toggle always online mode'); // inserir request
    setAlwaysOnlineMode(!alwaysOnlineMode);
  };

  const handleClickChangeDomain = () => {
    alert('Change domain'); // inserir action (já dá pra ir fazendo)
  };

  const handleCleanCache = () => {
    setDisplayCleanCacheModal(true);
    setDomainDataRequest({
      ...domainDataRequest,
      cleanCacheLoading: true,
    });
  };

  const cardsProps = {
    [FEATURES.CLEAN_CACHE]: {
      ...manageCloudflareLocale.cdnCards[FEATURES.CLEAN_CACHE],
      button: {
        ...manageCloudflareLocale.cdnCards[FEATURES.CLEAN_CACHE].button,
        onClick: handleCleanCache,
      },
      icon: <IconTrashBg />,
      active: cloudflareFeatures && cloudflareFeatures[FEATURES.CLEAN_CACHE],
    },
    [FEATURES.DEVELOPER_MODE]: {
      ...manageCloudflareLocale.cdnCards[FEATURES.DEVELOPER_MODE],
      toggle: {
        toggleId: 'developer-mode',
        onMark: handleToggleDeveloperMode,
        checked: developerMode,
      },
      icon: <IconLive secondaryColor="pendingPure" />,
      active: cloudflareFeatures && cloudflareFeatures[FEATURES.DEVELOPER_MODE],
    },
    [FEATURES.UNDER_ATTACK_MODE]: {
      ...manageCloudflareLocale.cdnCards[FEATURES.UNDER_ATTACK_MODE],
      toggle: {
        toggleId: 'under-attack-mode',
        onMark: handleToggleUnderAttackMode,
        checked: underAttackMode,
      },
      icon: <IconSecurityCheck secondaryColor="activeLight" />,
      active: cloudflareFeatures && cloudflareFeatures[FEATURES.UNDER_ATTACK_MODE],
    },
    [FEATURES.ALWAYS_ONLINE]: {
      ...manageCloudflareLocale.cdnCards[FEATURES.ALWAYS_ONLINE],
      toggle: {
        toggleId: 'always-online-mode',
        onMark: handleToggleAlwaysOnlineMode,
        checked: alwaysOnlineMode,
      },
      icon: <IconDoneChecklist secondaryColor="primaryBlue" />,
      active: cloudflareFeatures && cloudflareFeatures[FEATURES.ALWAYS_ONLINE],
    },
  };

  const handleRedirectUserOut = useCallback(() => {
    !cloudflareManagementIsEnabled && history.push(routesLocale.sites);
  }, [cloudflareManagementIsEnabled, history, routesLocale]);

  useEffect(() => {
    handleRedirectUserOut();
  }, [handleRedirectUserOut]);

  if (domainDataRequest.loading) {
    return (
      <Styles.Wrapper>
        <Styles.SkeletonItem>
          <Styles.SkeletonHeaderColumn>
            <Skeleton variant="textLine" width="232px" />
            <Skeleton variant="textLine" width="512px" />
            {isMobile && <Skeleton variant="textLine" width="200px" />}
          </Styles.SkeletonHeaderColumn>
        </Styles.SkeletonItem>

        {Array.from(Array(4).keys()).map(item => (
          <Styles.SkeletonItem key={`skeleton_${item}`}>
            <Skeleton variant="rectangle" width="216px" height="24px" />
            <Styles.SkeletonText>
              <Skeleton variant="textLine" width="660px" />
              <Skeleton variant="textLine" width="540px" />
            </Styles.SkeletonText>

            <Styles.SkeletonButton>
              <Skeleton variant="button" />
            </Styles.SkeletonButton>
          </Styles.SkeletonItem>
        ))}
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper>
      {displayCleanCacheModal && (
        <CleanCacheModal
          onClose={() => setDisplayCleanCacheModal(false)}
          cleanCacheLoading={domainDataRequest.cleanCacheLoading}
          loadingTitle={manageCloudflareLocale.cleanCacheModal.loadingTitle}
          successTitle={manageCloudflareLocale.cleanCacheModal.successTitle}
          successDescription={manageCloudflareLocale.cleanCacheModal.successDescription}
          okButtonLabel={manageCloudflareLocale.cleanCacheModal.okButtonLabel}
        />
      )}
      <Styles.HeadContainer>
        <Styles.Text>
          {manageCloudflareLocale.hostingLabel(domainDataRequest.planName)}
        </Styles.Text>

        <Styles.ChangeDomainWrapper>
          <Styles.PlanName>
            {manageCloudflareLocale.selectedDomainLabel}
          </Styles.PlanName>

          <Styles.DomainContainer>
            <Styles.DomainLabel>
              {domainDataRequest.domain}
            </Styles.DomainLabel>
            <Button
              variant="text"
              testId="change-domain-button"
              label={manageCloudflareLocale.changeButtonLabel}
              onClick={handleClickChangeDomain}
            />
          </Styles.DomainContainer>
        </Styles.ChangeDomainWrapper>
      </Styles.HeadContainer>

      {Object.keys(cardsProps).map(key => cardsProps[key].active && (
        <ManageCard
          key={key}
          testId={key}
          cardTitle={cardsProps[key].cardTitle}
          cardDescription={cardsProps[key].cardDescription}
          tooltipLabel={cardsProps[key].tooltipLabel}
          toggle={cardsProps[key].toggle}
          button={cardsProps[key].button}
          icon={cardsProps[key].icon}
          readMoreButtonLabel={manageCloudflareLocale.readMoreButton}
          isMobile={isMobile}
        />
      ))}

      <Styles.FooterText>{manageCloudflareLocale.footerLabel}</Styles.FooterText>

      <Styles.FaqRow>
        <FaqDefault
          questions={faq.cloudflare.questions}
          link={SUPPORT_DOMAINS_URL}
          loading={faq.loading}
        />
      </Styles.FaqRow>
    </Styles.Wrapper>
  );
};

export default ManageCloudFlareHandler;
