const faqParams = [
  'pro-account',
  'import-account',
  'pro-getall',
  'pro-internal-mail',
  'pro-check-dns',
  'pro-desktop',
  'pro-app',
  'pro-upgrade',
  'pro-buy-account',
  'pro-home',
];

export default faqParams;
