const styles = theme => ({
  wrapper: {
    [theme.breakpoints.up(960)]: {
    },
  },
  paymentOptions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '24px',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      margin: '24px 0px',
    },
  },
  productName: {
    color: theme.color.tundora,
    font: 'normal normal bold 24px/32px Roboto',
  },
  productPrice: {
    alignItems: 'baseline',
    color: theme.color.tundora,
    display: 'flex',
    font: 'normal normal normal 30px/39px Roboto',
    letterSpacing: '0.01px',
    textAlign: 'center',
  },
  month: {
    color: theme.color.doveGray,
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0.01px',
    textAlign: 'center',
  },
  select: {
    marginTop: '20px',
    minWidth: '100%',
  },
  button: {
    marginTop: '16px',
    minWidth: '100%',
  },
});

export default styles;
