const styles = theme => ({
  contentWrapper: {
    backgroundColor: theme.color.white,
    left: 0,
    position: 'fixed',
    top: 0,
    zIndex: 1,
  },
});

export default styles;
