import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ disabled }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    pointer-events: ${disabled ? 'none' : 'initial'};
    position: relative;
  `}
`;

export const FixedLabel = styled.label`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    color: ${theme.palette.dark};
    font-size: ${theme.font.sizes.sm};
    left: 0px;
    padding: 0px 5px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    transform: translate(6px, -9px) scale(0.8);
    z-index: 1;

    &.selected {
      color: ${theme.palette.secondary.primary};
    }

    &.error {
      color: ${theme.palette.error.primary};
    }

    &.disabled {
      background: linear-gradient(0deg, transparent 40%, ${theme.palette.white.light} 40%);
    }
  `}
`;

export const Label = styled.p`
  ${({ theme, size }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.sm};
    margin: ${size === 'small' ? '0' : ''}

  `
}
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox', name: 'multi-checkbox' })`
  ${({ theme }) => css`
    color: ${theme.palette.primary.darkest};
    cursor: pointer;
    font-size: ${theme.font.sizes.sm};
    width: ${theme.font.sizes.lg};
    min-width: 32px;
    height: ${theme.font.sizes.lg};
  `}
`;

export const Field = styled.div`
  ${({ disabled, theme }) => css`
    align-items: center;
    background-color: ${disabled ? theme.palette.white.dark : theme.palette.white.light};
    border: 1px solid ${theme.palette.grey.primary};
    cursor: pointer;
    display: flex;
    flex: 1;
    max-height: 35px;
    padding: 8px 42px 8px 9px;
    position: relative;

    &.error {
      border-color: ${theme.palette.error.primary};

      ${Label} {
        color: ${theme.palette.error.primary};
      }

      svg {
        fill: ${theme.palette.error.primary};
        stroke: ${theme.palette.error.primary};
      }
    }
  `}
`;

export const Header = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
`;

export const IconAdapter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 18px;
`;

export const Options = styled.ul`
  ${({ theme, show }) => css`
    margin: 0;
    padding: 0;
    background-color: ${theme.palette.white.light};
    border-bottom: 1px solid ${theme.palette.grey.variant};
    border-left: 1px solid ${theme.palette.grey.variant};
    border-right: 1px solid ${theme.palette.grey.variant};
    left: -1px;
    list-style: none;
    position: absolute;
    right: -1px;
    top: calc(100% - 1px);
    transition: opacity 200ms ease-in-out;
    z-index: ${theme.layers.awaysOnTop};
    opacity:  ${show ? 1 : 0};
    display: ${show ? '' : 'none'};

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.palette.white.light};
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.palette.grey.primary};
      border-left: 2px solid ${theme.palette.white.light};
      border-radius: 3px;
      border-right: 2px solid ${theme.palette.white.light};
      height: 30px;
      width: 100%;
    }
  `}
`;

export const Option = styled.li`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0;
    gap: 0px;

    &:hover {
      background-color: ${theme.palette.grey.ultralight};
    }
  `}
`;

export const OptionLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    cursor: pointer;
    font-size: ${theme.font.sizes.md};
    line-height: ${theme.font.sizes.lg};
    margin: 0;
    padding: 12px 0px;
    white-space: nowrap;
  `}
`;

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.error.primary};
    font-size: ${theme.font.sizes.xxs};
    margin: 8px 0px 0px 0px;
  `}
`;
