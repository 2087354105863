import React from 'react';

const IconArrowDown = ({ color = '#1F2044' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={color} data-testid="icon-arrow-down">
    <g id="down">
      <path id="arrow" d="M1 4h10L6 9 1 4" stroke="none" />
    </g>
  </svg>
);

export default IconArrowDown;
