const styles = theme => ({
  icon: {
    fontSize: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
    },
  },
  st0: { fill: '#AEC911' },
  st1: { fill: '#FEDFC5' },
  st2: { fill: '#FFE49B' },
  st3: { fill: '#DF5D00' },
});

export default styles;
