const styles = theme => ({
  incrementWrapper: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '2px',
    display: 'flex',
    maxWidth: 226,
  },

  newIncrementWrapper: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: '4px',
    display: 'flex',
    height: '42px',
    width: 221,
  },

  amountBlock: {
    width: 120,

    '& fieldset': {
      border: 0,
      height: '32px',
      padding: 0,
      top: 0,
    },

    '& input': {
      height: '32px',
      padding: 0,
      textAlign: 'center',
    },
  },

  newAmountBlock: {
    width: '100%',

    '& fieldset': {
      border: 0,
      height: '42px',
      padding: 0,
      top: 0,
    },

    '& input': {
      height: '42px',
      padding: 0,
      textAlign: 'center',
    },
  },

  fieldset: {
    padding: 0,
  },

  cssOutlinedInput: {
    alignItems: 'center',
    color: theme.color.tundora,
    display: 'flex',
    fontSize: '22px',
    fontWeight: '500',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '27px',
  },

  newInput: {
    alignItems: 'center',
    color: theme.palette.dark,
    display: 'flex',
    fontFamily: theme.font.family.secondary,
    fontSize: '16px',
    fontWeight: '700',
    justifyContent: 'center',
    height: '42px',
    letterSpacing: '0.01px',
    lineHeight: '24px',
  },

  incrementLabel: {
    color: theme.color.regentGray,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginBottom: '6px',
  },

  newIncrementLabel: {
    color: theme.color.regentGray,
    fontSize: '14px',
    fontFamily: theme.font.family.secondary,
    lineHeight: '18.2px',
    marginBottom: '8px',
    textAlign: 'left',
  },

  button: {
    backgroundColor: 'unset',
    borderRadius: '0px',

    '&:hover': {
      backgroundColor: 'unset',
    },

    '&:disabled': {
      backgroundColor: theme.color.porcelain,
    },
  },

  newButton: {
    minWidth: '40px',
    padding: '0px',
  },

  decrement: {
    borderRight: `1px solid ${theme.color.geyser}`,
  },

  increment: {
    borderLeft: `1px solid ${theme.color.geyser}`,
  },

  icon: {
    fill: theme.color.indigo,
    fontSize: '20px',
  },

  newIcon: {
    fill: theme.palette.dark,
    fontSize: '20px',
  },

  disabledIcon: {
    fill: theme.color.regentGray,
    fontSize: '20px',
  },

  separator: {
    borderBottom: `1px solid ${theme.color.geyser}`,
    height: '1px',
    marginBottom: '12px',
    width: '100%',
  },
});
export default styles;
