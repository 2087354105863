const styles = theme => ({
  header: {
    alignItems: 'flex-end',
    display: 'flex',
  },

  radioWrapper: {
    padding: '7px 7px 0 7px',
    marginBottom: 0,
  },

  radio: {
    padding: '5px',
  },

  title: {
    color: theme.color.tundora,
    fontSize: '14px',
    fontWeight: 500,
    margin: 0,
    marginLeft: '-21px',
    padding: '0 0 8px 0',
    userSelect: 'none',
  },

  description: {
    color: theme.color.regentGray,
    fontSize: '14px',
    margin: 0,
    padding: '0 20px 0 29px',
    textAlign: 'left',
    userSelect: 'none',
  },
});

export default styles;
