import { phoneNumberPattern } from '@/utils/Masks/inputMasks';
import { phoneNumberToDigits } from '@/utils/Formatters/phoneNumberToDigits';

export const formatPhoneNumber = ({ phoneNumber = '', phoneNumberCountry = 'other' }) => {
  if (phoneNumber) {
    const isValidCountry = Object.keys(phoneNumberPattern).includes(phoneNumberCountry.toLowerCase());
    const country = isValidCountry ? phoneNumberCountry.toLowerCase() : 'other';
    const phoneNumberDigits = phoneNumberToDigits({ phoneNumber });
    const phone = phoneNumberDigits
      ? phoneNumberDigits.replace(
        phoneNumberPattern[country].regex,
        phoneNumberPattern[country].replace,
      ) : '';
    return phone;
  }
  return phoneNumber;
};
