import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  withStyles,
} from '@material-ui/core';
import { Button, IconButton } from 'gatorcomponents';
import { useResize } from '@/hooks/useResize';
import styles from './Modal.styles';

const Modal = ({
  onClose,
  title,
  primaryButtonLabel = 'Confirmar',
  primaryButtonAction,
  isDisabled = false,
  isLoading = false,
  secondaryButtonLabel = 'Cancelar',
  secondaryButtonAction,
  children,
  content,
  open = false,
  classes,
  testId = 'modal-component',
}) => {
  const { width } = useResize();
  const mobile = width <= 425;

  return (
    <Dialog open={open} fullScreen={mobile} maxWidth="md" data-testid={testId}>
      <div className={classes.root}>
        <DialogTitle className={classes.titleContainer} data-testid={`${testId}__title`}>
          <div className={classes.title}>
            {title}
            <IconButton onClick={onClose} icon="IconClose" testId={`${testId}__close-button`} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.contentWrapper} data-testid={`${testId}__content`}>
          {children || content}
        </DialogContent>
        <DialogActions className={classes.actions} data-testid={`${testId}__actions`}>
          {secondaryButtonAction && (
            <Button
              variant="secondary"
              label={secondaryButtonLabel}
              onClick={secondaryButtonAction || onClose}
              testId={`${testId}__secondary-button`}
              size="medium2"
            />
          )}
          <Button
            label={primaryButtonLabel}
            onClick={primaryButtonAction}
            disabled={isDisabled}
            loading={isLoading}
            testId={`${testId}__primary-button`}
            size="medium2"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);
