import { DNS_PROPAGATION_ARTICLE } from '@/config/urls/supportUrls';
import { Link } from 'gatorcomponents';
import React from 'react';

export default {
  stepper: {
    linkChoose: 'Escolha um link único',
    content: 'Insira o conteúdo',
    template: 'Selecione um template',
    share: 'Compartilhe',
  },
  step1: {
    title: (
      <>
        <strong>Crie seu Link na Bio</strong>
        <br />
        e expanda sua audiência.
      </>
    ),
    description: 'Compartilhe em todas as redes sociais e permita que seus clientes se conectem com você de forma fácil.',
    free: 'Gratuito',
    customizable: 'Personalizável',
    easy: 'Fácil de usar',
    chooseALink: 'Escolha o seu link único',
    continue: 'Continuar',
    linkPlaceholder: 'seulink',
    success: 'Esse link está disponível',
    error: 'Esse link não está disponível.',
    knowMore: 'Saiba mais',
    olink: 'olink.ai/',
  },
  step2: {
    title: 'Conteúdo',
    description: 'Descreva seu negócio, adicione suas redes sociais e outros links úteis.',
    about: 'Sobre o seu link',
    linkTitle: 'Titulo',
    linksDescription: 'Descrição',
    links: 'Links',
    addLink: 'Adicionar link',
    continue: 'Continuar',
    required: 'Adicione seus links antes de continuar',
    preview: 'É assim que seu Link na Bio vai aparecer para os seus visitantes.',
    back: 'Voltar',
    linksYouAdd: 'Os links que você adicionar irão aparecer aqui.',
    insertLink: 'Inserir link',
    url: 'URL',
    linkTitlePlaceHolder: 'Ex: Visite nossa loja',
    urlPlaceHolder: 'Ex.: lojahostgator.com.br',
    add: 'Adicionar',
    edit: 'Salvar',
    cancel: 'Cancelar',
    tooltip: 'Você pode adicionar até 15 links dentro do seu Link na Bio. Adicione informações como seu cardápio, endereço, redes sociais e muito mais.',
    tooltipSocialMedia: 'Você pode adicionar até 6 ícones no seu Link na Bio. Adicione ícones para suas redes sociais e aplicativos de mensagem.',
    previewButton: 'Preview',
    closePreview: 'Fechar Preview',
    delete: 'O seu link será removido. Esta ação não pode ser desfeita.',
    deleteModalTitle: 'Excluir Link',
    confirmDelete: 'Estou ciente, quero excluir',
    invalidLink: 'Link inválido',
    titlePlaceHolder: 'Ex: Padaria da Catarina',
    descriptionPlaceholder: 'Ex: Descubra os sabores únicos da nossa padaria artesa...',
    socialLinks: 'Ícones de redes sociais',
    addSocialLink: 'Adicionar rede social',
    deleteSocialLinkTitle: 'Excluir Ícone',
    deleteSocialLink: 'O seu ícone será removido. Esta ação não pode ser desfeita.',
    socialLinkModal: {
      title: 'Inserir rede social',
      description: 'Selecione o ícone da rede social que deseja adicionar',
      socialMedia: 'Redes sociais',
      messageApps: 'Aplicativos de mensagem e e-mail',
      insertLink: 'Insira abaixo o link do seu perfil na rede social.',
      whatsappPlaceholder: '123456789',
    },
    fieldRequired: 'Campo obrigatório',
  },
  step3: {
    customize: 'Personalize seu link',
    colorScheme: 'Selecione o esquema de cores:',
    template: 'Selecione um template para o seu Link na Bio:',
    MODERN: 'Moderno',
    FRIENDLY: 'Amigável',
    MINIMALIST: 'Minimalista',
    FUNNY: 'Divertido',
    publish: 'Publicar Link na Bio',
    back: 'Voltar',
    previewButton: 'Preview',
    closePreview: 'Fechar Preview',
    preview: 'É assim que seu Link na Bio vai aparecer para os seus visitantes.',
  },
  step4: {
    title: 'Seu link está pronto para ser compartilhado!',
    description: 'Mostre aos seus clientes o quão fácil é se conectar com o seu negócio.',
    copyButton: 'Copiar link',
    viewButton: 'Visualizar',
    doMore: 'Faça mais com seu Link na Bio',
    linkPlaceholder: 'olink.ai/',
    copied: 'Copiado',
  },
  list: {
    myLinks: 'Meus links',
    create: 'Criar novo link na bio',
    limit: (
      <span>
        Você pode criar até
        {' '}
        <strong>5 Links na Bio</strong>
      </span>
    ),
    copy: 'Copiar link',
    dataAnalysis: 'Análise de dados',
    customDomain: 'Domínio personalizado',
    edit: 'Editar Link na Bio',
    delete: 'Excluir Link na Bio',
    deleteModalDescription: profileName => (
      <p>
        O seu link
        {' '}
        <strong>{profileName}</strong>
        {' '}
        será removido, junto com todo o seu conteúdo. Esta ação não pode ser desfeita.
      </p>
    ),
    cancel: 'Cancelar',
    confirmDelete: 'Estou ciente, quero excluir',
    linkPlaceholder: 'olink.ai/',
    copied: 'Copiado',
    tooltip: 'Você atingiu o limite de 5 Links na Bio, para criar um novo, você pode excluir um Link na Bio existente.',
    domainConnectedTo: 'Conectado ao domínio ',
    bannerTitle: (
      <>
        <strong>Novidade!</strong>
        {' '}
        Conecte um domínio personalizado ao seu Link na Bio
      </>
    ),
    bannerDescription: 'Personalize ainda mais o seu Link na Bio conectando a um domínio que você já possui.',
    seeHowToConfigure: 'Veja como configurar',
    linkExample: 'bio.minhaloja.com',
  },
  toasts: {
    linkDeleteError: 'Não foi possível excluir o link selecionado. Tente novamente.',
    linkDeleteSuccess: 'O link selecionado foi excluído.',
    linkNaBioCreationError: 'Ocorreu um erro ao tentar criar o seu Link na Bio. Tente novamente.',
    linkNaBioDeleteError: 'Não foi possível excluir o seu Link na Bio. Tente novamente.',
    linkNaBioDeleteSuccess: 'Seu Link na Bio foi excluído.',
    linkEdition: 'Não foi possível realizar essa ação. Tente novamente.',
    profileLoadingError: 'Ocorreu um erro ao carregar os seus Links na Bio. Tente novamente.',
    linkUpdated: 'Link atualizado',
    domainLinked: 'O domínio personalizado foi removido do seu Link na Bio.',
    domainRemoved: 'Ocorreu um erro ao remover o domínio personalizado do seu Link na Bio. Tente novamente.',
  },
  statistics: {
    linkPlaceholder: 'olink.ai/',
    consolidatedData: 'Dados consolidados',
    totalData: 'Dados de acessos totais desde a criação do seu Link na Bio.',
    access: totalAccess => (
      <>
        <strong>{totalAccess}</strong>
        {' '}
        <p>Acessos</p>
      </>
    ),
    clicks: totalClicks => (
      <>
        <strong>{totalClicks}</strong>
        {' '}
        <p>Cliques</p>
      </>
    ),
    conversionRate: rate => (
      <>
        <strong>
          {rate}
          %
        </strong>
        {' '}
        <p>Taxa de conversão</p>
      </>
    ),
    dailyAccess: 'Dados de acessos diários',
    lastWeek: 'Últimos 7 dias',
    lastMonth: 'Últimos 30 dias',
    totalAccess: 'Total de acessos',
    totalClicks: 'Total de cliques',
    totalConversion: 'Taxa de conversão',
    accessTooltip: 'Total de vezes que o seu Link na Bio foi acessado.',
    clicksTooltip: 'Total de cliques nos links internos e ícones de redes sociais do seu Link na Bio.',
    conversionRateTooltip: 'É o percentual de visitantes que clicaram em algum link interno ou ícone do seu Link na Bio.',
    emptyStateTitle: 'Ainda não há dados para exibir',
    emptyStateDescription: 'Estamos analisando seu Link na Bio. Em breve, você terá acesso a dados de acesso que ajudarão a entender melhor o comportamento do seu Link.',
  },
  customDomainModal: {
    title: 'Conecte o seu Link na Bio a um domínio próprio',
    description: 'Personalize ainda mais o seu Link na Bio conectando a um domínio que você já possui, você pode conectar como domínio principal ou criar um subdomínio apenas para o seu Link na Bio.',
    domainPlaceHolder: 'Selecionar domínio',
    cancel: 'Cancelar',
    verify: 'Verificar domínio',
    otherDomain: 'Quero usar outro domínio',
    otherDomainInputLabel: 'Qual domínio deseja conectar?',
    invalidDomain: 'Domínio inválido! Não pode conter caracteres especiais (exceto hífen), iniciar e finalizar com hífen, conter apenas números ou ter mais de 63 caracteres.',
    howToConnectTitle: domain => (
      <>
        Como você deseja conectar o domínio
        {' '}
        <span>
          {domain}
        </span>
        {' '}
        ao seu Link na Bio?
      </>
    ),
    subdomain: 'Definir um subdomínio para o meu Link na Bio',
    subdomainDescription: (
      <>
        Crie um subdomínio apenas para o seu Link na Bio, como
        {' '}
        <strong>bio.meusite.com</strong>
        . Isso não altera o direcionamento do domínio original.
      </>
    ),
    recommended: 'Recomendado',
    mainDomain: domain => (
      <>
        Usar
        {' '}
        <span>
          {domain}
        </span>
        {' '}
        como domínio principal
      </>
    ),
    mainDomainDescription: (
      <>
        Ideal se você ainda não possui um site vinculado ao domínio.
        {' '}
        <strong>
          O seu domínio irá direcionar diretamente e apenas
        </strong>
        {' '}
        para o Link na Bio.
      </>
    ),
    back: 'Voltar',
    continue: 'Continuar',
    insertSubdomain: 'Insira o subdomínio',
    verifyChanges: 'Verifique as alterações',
    verifyChangesDescription: 'As mudanças abaixo serão realizadas após você confirmar a alteração.',
    afterConfirmation: 'Após a confirmação, o seu domínio personalizado irá direcionar para a página do seu Link na Bio:',
    newDomainConnected: 'Novo domínio conectado',
    afterConfirmationInfo: (
      <>
        O seu domínio original
        {' '}
        <strong>olink.ai</strong>
        {' '}
        também continuará funcionando e redirecionando para o seu Link na Bio.
      </>
    ),
    confirmChange: 'Confirmar alteração',
    domainConnectionSuccess: 'Domínio personalizado conectado!',
    successDescription: (
      <>
        O domínio foi conectado ao seu Link na Bio.
        {' '}
        <strong>Pode levar algumas horas para que os visitantes vejam o seu Link na Bio a partir do novo domínio conectado</strong>
        {' '}
        , enquanto isso, você ainda pode usar o seu domínio padrão
        {' '}
        <strong>olink.ai</strong>
        {' '}
        na sua Bio.
      </>
    ),
    backToList: 'Voltar para lista de Links',
    configureYourDomain: 'Configure seu domínio',
    configureYourDomainDescription: domain => (
      <>
        Como o domínio
        {' '}
        <strong>
          &quot;
          {domain}
          &quot;
        </strong>
        {' '}
        não foi registrado aqui na HostGator, você precisa ir no outro provedor e apontar o registro CNAME para a página do seu Link na Bio, siga as instruções abaixo:
      </>
    ),
    configureStep1: 'Acesse o painel do provedor onde o seu domínio está registrado.',
    configureStep2: 'No domínio que deseja configurar (alterar DNS) para o seu Link na Bio da HostGator, busque por “Alterar DNS ou Alterar Servidores” - se não achar, contate o suporte desse provedor',
    configureStep3: 'Busque pela opção de adicionar um novo registro, e selecione o tipo CNAME.',
    configureStep4: 'Adicione um novo registro CNAME contendo os valores abaixo:',
    configureStep4Attention: (
      <>
        <strong>Atenção:</strong>
        {' '}
        Ao realizar a configuração abaixo, os direcionamentos atuais do domínio serão perdidos e ele passará a direcionar apenas para o Link na Bio.
      </>
    ),
    configureStep4Name: domain => (
      <>
        Nome:
        {' '}
        <strong>{domain}</strong>
      </>
    ),
    configureStep4TTL: (
      <>
        TTL:
        {' '}
        <strong>3600</strong>
      </>
    ),
    configureStep4Value: value => (
      <>
        Valor:
        {' '}
        <strong>{value}</strong>
      </>
    ),
    configurationTip: 'Algumas plataformas podem utilizar nomes diferentes para os campos do registro, se tiver dúvidas, pesquise como adicionar registros DNS no provedor do seu domínio.',
    configurationDone: 'Já fiz as alterações',
    verifyingDomain: 'Verificando domínio',
    verifyingDomainDescription: 'Verificando o status de configuração do seu domínio.',
    verificationNotPossible: 'Não foi possível conectar o seu Link na Bio ao domínio informado.',
    verifyOtherDomain: 'Verifique se o domínio está correto e registrado, ou escolha um outro domínio.',
    chooseOther: 'Escolher outro domínio',
    domainConnected: 'Domínio personalizado conectado!',
    domainConnectedDescription: domain => (
      <>
        O domínio
        {' '}
        <strong>{domain}</strong>
        {' '}
        foi conectado ao seu Link na Bio, você já pode compartilhar esse novo domínio com os seus visitantes.
      </>
    ),
    removeDomainLink: 'Remover domínio personalizado',
    removeDomainTitle: 'Remover domínio',
    removeDomainDescription: (
      <>
        Remover o domínio personalizado faz com que ele não direcione mais o tráfego para o seu Link na Bio. Você ainda poderá usar o domínio original
        {' '}
        <strong>olink.ai</strong>
        {' '}
        para compartilhar o seu Link na Bio ou adicionar um novo domínio personalizado.
      </>
    ),
    removeDomainConfirm: 'Estou ciente, quero remover',
    removeDomainSuccessAlert: 'O domínio personalizado foi removido do seu Link na Bio.',
    removeDomainErrorAlert: 'Ocorreu um erro ao remover o domínio personalizado do seu Link na Bio. Tente novamente.',
    externalDomainConnected: (
      <>
        Seu domínio foi conectado e em breve seus visitantes poderão ver o seu Link na Bio a partir do novo domínio. Enquanto isso, utilize o seu domínio padrão
        {' '}
        <strong>olink.ai</strong>
        {' '}
        na sua bio.
      </>
    ),
    timeToPropagate: (
      <>
        As alterações no registro DNS podem levar até 24 horas para serem concluídas (período de propagação do DNS). Após esse tempo, o novo domínio será vinculado ao seu Link na Bio.
        <Link text="Entenda esse prazo" href={DNS_PROPAGATION_ARTICLE} />
      </>
    ),
  },
};
