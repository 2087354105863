import { withStyles } from '@material-ui/core';
import React from 'react';
import { withI18n } from 'react-i18next';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import SelectField from '@/components/Fields/SelectField';
import { locale } from '@/utils/locale';
import styles from './styles';
import * as Styles from './PaymentMethod.styles';


const PaymentMethod = ({
  paymentMethod, setPaymentMethod, methodOptions, disabled,
}) => (
  <Styles.PaymentCycleWrapper data-testid="paymentMethodBlock">
    <SelectField
      disabled={disabled}
      inputLabel={locale('proEmail.upgradePlan.paymentMethod')}
      labelWidth={calcLabelWidth(locale('proEmail.upgradePlan.paymentMethod'))}
      filterOptions={methodOptions}
      selectValue={paymentMethod}
      selectType="paymentMethod"
      menuItemDefaultText="hidden"
      handleChangeSelectValue={e => setPaymentMethod(e.target.value)}
      selectTestId="selectButton"
      selectInputTestId="selectField"
    />
  </Styles.PaymentCycleWrapper>
);

export default withI18n()(withStyles(styles)(PaymentMethod));
