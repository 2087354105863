import { collect } from '@/analytics';
import { TITAN_PRODUCTS_NAMES } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: 'e-mail',
  ACTION: {
    ENTER_PAGE: 'Exibição_Tela',
    SELECT_ESSENTIALS: 'Titan_Essentials',
    SELECT_PREMIUM: 'Titan_Premium',
    SELECT_ULTRA: 'Titan_Ultra',
  },
  LABEL: 'Titan_Upgrade_Page',
};

const enterTitanUpgradePage = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: EVENTS.ACTION.ENTER_PAGE,
  gaEventLabel: EVENTS.LABEL,
});

const confirmUpgrade = (upgradeTo) => {
  let action = '';
  switch (upgradeTo) {
    case TITAN_PRODUCTS_NAMES.PREMIUM:
      action = EVENTS.ACTION.SELECT_PREMIUM;
      break;
    case TITAN_PRODUCTS_NAMES.ESSENTIALS:
      action = EVENTS.ACTION.SELECT_ESSENTIALS;
      break;
    case TITAN_PRODUCTS_NAMES.ULTRA:
      action = EVENTS.ACTION.SELECT_ULTRA;
      break;
    default:
  }
  return collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: action,
    gaEventLabel: EVENTS.LABEL,
  });
};

const analytics = {
  enterTitanUpgradePage,
  confirmUpgrade,
};

export default analytics;
