import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './BackupGeneralVision.styles';
import {
  IconDanger, IconDomain, IconCheck, Tag, Accordion, IconLoading, IconClock, Button,
} from 'gatorcomponents';
import SnappyFaceBackup from '@/media/backup/SnappyFaceBackup.svg';
import { differenceInCalendarDays, format } from 'date-fns';
import { GeneralVisionTags } from '../../BackupHandler.types';
import { backupAnalytics } from '@/analytics';

const BackupGeneralVision = ({
  generalVisionLocale,
  loadingLog,
  lastBackup,
  currentProduct,
  goToCPanel,
  setShowWizard,
  firstStep,
}) => {
  const [product, setProduct] = useState({});

  const mountProductData = useCallback(() => {
    if (!loadingLog && lastBackup) {
      const createdAt = new Date(lastBackup.created_at);
      const today = new Date();
      const dif = differenceInCalendarDays(today, createdAt);
      setProduct({
        plan_type: currentProduct ? currentProduct.productname : '',
        plan_domain: currentProduct ? currentProduct.domain : '',
        daysSinceLastBackup: dif,
        date: format(new Date(lastBackup.created_at), 'dd/MM/yyyy'),
        hour: format(new Date(lastBackup.created_at), 'HH:mm'),
        status: lastBackup.status,
      });
    }
  }, [loadingLog, lastBackup, currentProduct]);

  useEffect(() => {
    mountProductData();
  }, [mountProductData]);


  const daysToShow = useCallback(() => {
    if (product.daysSinceLastBackup > 7 && product.daysSinceLastBackup <= 15) {
      return 7;
    }
    if (product.daysSinceLastBackup > 15 && product.daysSinceLastBackup <= 30) {
      return 15;
    }
    if (product.daysSinceLastBackup > 30) {
      return 30;
    }
  }, [product]);

  const tagType = useCallback(() => {
    if (product.status === 'completed' && product.daysSinceLastBackup <= 7) {
      return GeneralVisionTags.COMPLETED;
    }
    if ((product.status === 'completed' && product.daysSinceLastBackup > 7) || !lastBackup) {
      return GeneralVisionTags.ATTENTION;
    }
    if (product.status === 'in_progress') {
      return GeneralVisionTags.IN_PROGRESS;
    }
    if (product.status === 'error') {
      return GeneralVisionTags.ERROR;
    }
  }, [lastBackup, product]);

  const faqItems = [
    {
      title: generalVisionLocale.faq.backupTypesAccordion.title,
      content: () => (
        <Styles.FaqTextContainer>
          {generalVisionLocale.faq.backupTypesAccordion.content.description}
          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.backupTypesAccordion.content.fullBackup}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>

          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.backupTypesAccordion.content.parcialBackup}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>

          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.backupTypesAccordion.content.emailsBackup}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>

          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.backupTypesAccordion.content.databaseBackup}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>
        </Styles.FaqTextContainer>
      ),
      analytics: backupAnalytics.accordionBackupTypes,
    },
    {
      title: generalVisionLocale.faq.createBackupAccordion.title,
      content: () => (
        <Styles.FaqTextContainer>
          {generalVisionLocale.faq.createBackupAccordion.content.description}
          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.createBackupAccordion.content.byCpanel}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>

          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.createBackupAccordion.content.byPortal}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>
        </Styles.FaqTextContainer>
      ),
      analytics: backupAnalytics.accordionHowToDo,
    },
    {
      title: generalVisionLocale.faq.maxSizeBackupAccordion.title,
      content: () => (
        <Styles.FaqTextContainer>
          {generalVisionLocale.faq.maxSizeBackupAccordion.content.paragraphOne}
          <Styles.FaqTextParagraph>
            {generalVisionLocale.faq.maxSizeBackupAccordion.content.paragraphTwo}
          </Styles.FaqTextParagraph>
          <Styles.FaqTextParagraph>
            {generalVisionLocale.faq.maxSizeBackupAccordion.content.paragraphThree}
          </Styles.FaqTextParagraph>
        </Styles.FaqTextContainer>
      ),
      analytics: backupAnalytics.accordionMaxSize,
    },
    {
      title: generalVisionLocale.faq.restoreBackupAccordion.title,
      content: () => (
        <Styles.FaqTextContainer>
          {generalVisionLocale.faq.restoreBackupAccordion.content.description}
          <Styles.FaqTextParagraph>
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.restoreBackupAccordion.content.subdescription}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>
          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.restoreBackupAccordion.content.byPortal}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>

          <Styles.FaqTextParagraph>
            <li />
            <Styles.FaqTextContainer>
              {generalVisionLocale.faq.restoreBackupAccordion.content.bySupport}
            </Styles.FaqTextContainer>
          </Styles.FaqTextParagraph>
          <Styles.FaqTextParagraph>
            {generalVisionLocale.faq.restoreBackupAccordion.content.footer}
          </Styles.FaqTextParagraph>
        </Styles.FaqTextContainer>
      ),
      analytics: backupAnalytics.accordionHowToRestore,
    },
    {
      title: generalVisionLocale.faq.whyDoBackupAccordion.title,
      content: () => (
        <Styles.FaqTextContainer>
          {generalVisionLocale.faq.whyDoBackupAccordion.content.paragraphOne}
          <Styles.FaqTextParagraph>
            {generalVisionLocale.faq.whyDoBackupAccordion.content.paragraphTwo}
          </Styles.FaqTextParagraph>
          <Styles.FaqTextParagraph>
            {generalVisionLocale.faq.whyDoBackupAccordion.content.paragraphThree}
          </Styles.FaqTextParagraph>
        </Styles.FaqTextContainer>
      ),
      analytics: backupAnalytics.accordionUpdatedBackup,
    },
  ];

  const backupTag = useCallback(() => {
    switch (tagType()) {
      case GeneralVisionTags.COMPLETED:
        return (<Tag variant="active" label={generalVisionLocale.status.completed} />);
      case GeneralVisionTags.ATTENTION:
        return (<Tag variant="problem" label={generalVisionLocale.status.attention} />);
      case GeneralVisionTags.ERROR:
        return (<Tag variant="problem" label={generalVisionLocale.status.error} />);
      case GeneralVisionTags.IN_PROGRESS:
        return (<Tag variant="pending" label={generalVisionLocale.status.inProgress} />);
      default:
        return (<></>);
    }
  }, [generalVisionLocale, tagType]);

  const infoItem = (
    <>
      <Styles.PlanInfoWrapper>
        <Styles.IconWrapper>
          <IconDomain />
        </Styles.IconWrapper>
        <Styles.NameWrapper>
          <Styles.Text><strong>{currentProduct && currentProduct.productname}</strong></Styles.Text>
          <Styles.SecondaryText>{currentProduct && currentProduct.domain}</Styles.SecondaryText>
        </Styles.NameWrapper>
      </Styles.PlanInfoWrapper>
      <Styles.StatusWrapper>
        {backupTag()}
      </Styles.StatusWrapper>
    </>
  );

  if (loadingLog || !currentProduct) {
    return (
      <Styles.LoadingWrapper>
        <IconLoading />
      </Styles.LoadingWrapper>
    );
  }

  return (
    <Styles.Wrapper data-testid="backup-general-vision">
      <Styles.Title>{generalVisionLocale.title}</Styles.Title>
      {lastBackup ? (
        <Styles.Text>
          {generalVisionLocale.description(format(new Date(lastBackup.created_at), 'dd/MM/yyyy'), format(new Date(lastBackup.created_at), 'HH:mm'))}
        </Styles.Text>
      ) : (
        <Styles.Text>
          {generalVisionLocale.noBackupDescription}
        </Styles.Text>
      )}

      <Styles.InfoBox>
        {infoItem}

        <Styles.InfoWrapper>
          {product.status === 'in_progress' && ((
            <>
              <IconClock color="pendingPure" />
            </>
          ))}
          {product.status === 'completed' && product.daysSinceLastBackup <= 7 && (<IconCheck color="activePure" />)}
          {(product.status === 'error' || !lastBackup || (product.status === 'completed' && product.daysSinceLastBackup > 7)) && ((<IconDanger color="problemPure" />))}

          {product.status === 'completed' && (
            <>
              {product.daysSinceLastBackup <= 7 && (
              <Styles.DescriptionText>{generalVisionLocale.status.completedAlert(product.date)}</Styles.DescriptionText>
              )}
              {product.daysSinceLastBackup > 7 && (
              <Styles.DescriptionText>{generalVisionLocale.status.oldBackupAlert(goToCPanel, daysToShow())}</Styles.DescriptionText>
              )}
            </>
          )}
          {product.status === 'in_progress' && (
            <Styles.DescriptionText>{generalVisionLocale.status.inProgressBackupAlert}</Styles.DescriptionText>
          )}
          {!lastBackup && (
            <Styles.DescriptionText>{generalVisionLocale.status.noBackupAlert(goToCPanel)}</Styles.DescriptionText>
          )}
          {product.status === 'error' && (
            <Styles.DescriptionText>{generalVisionLocale.status.errorBackupAlert(goToCPanel)}</Styles.DescriptionText>
          )}
        </Styles.InfoWrapper>
      </Styles.InfoBox>

      <Styles.WizardWrapper>
        <Styles.Title>{firstStep.title}</Styles.Title>

        <Styles.Text>
          {firstStep.description}
        </Styles.Text>

        <Button
          label={firstStep.labelWithGeneration}
          onClick={() => setShowWizard(true)}
          width="fit"
        />
      </Styles.WizardWrapper>

      <Styles.FaqContainer>
        <Styles.Text>{generalVisionLocale.faq.faqTitle}</Styles.Text>

        <Styles.SnappyBox>
          <Styles.SnappyImageContainer>
            <Styles.SnappyFace src={SnappyFaceBackup} />
          </Styles.SnappyImageContainer>
        </Styles.SnappyBox>

        {faqItems.map(question => (
          <Accordion
            key={question.title}
            title={question.title}
            items={question.content}
            width="full"
            iconPositionStatic
            onToggleAccordion={(event, isOpen) => isOpen && question.analytics()}
          />
        ))}
      </Styles.FaqContainer>
    </Styles.Wrapper>
  );
};

export default BackupGeneralVision;
