import {
  Button, IconDanger, IconWarning,
} from 'gatorcomponents';
import * as Styles from './Restore.styles';
import React from 'react';
import { backupAnalytics } from '@/analytics';

const Restore = ({
  title,
  description,
  alertTitle,
  alertDescription,
  agreement,
  uploadLabel,
  goToCPanel,
  cPanelLoading,
}) => {
  const handleRestore = () => {
    backupAnalytics.clickRestore();
    goToCPanel && goToCPanel(true);
  };

  return (
    <>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Description>{description}</Styles.Description>
      <Styles.AlertWrapper>
        <Styles.AlertTitle>
          <IconWarning color="attentionMedium" />
          {alertTitle}
        </Styles.AlertTitle>

        <Styles.AlertDescription>
          {alertDescription}
        </Styles.AlertDescription>
      </Styles.AlertWrapper>

      <Styles.AgreementWrapper>
        <IconDanger color="primaryBlue" />
        <Styles.Agreement>
          {agreement}
        </Styles.Agreement>
      </Styles.AgreementWrapper>

      <Styles.ButtonWrapper>
        <Button label={uploadLabel} onClick={handleRestore} loading={cPanelLoading} size="large" />
      </Styles.ButtonWrapper>
    </>
  );
};

export default Restore;
