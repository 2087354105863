const styles = theme => ({
  domainInput: {
    width: '200px',
    '@media(max-width: 1260px)': {
      width: '100%',
    },
  },
  startContentWrapper: {
    display: 'flex',
  },
  listTitle: {
    fontWeight: 300,
    fontSize: '24px',
    margin: '0 20px 0 32px',
  },
  filterTitle: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    paddingRight: '15px',
    fontWeight: 400,
    '@media(max-width: 1260px)': {
      fontSize: '24px',
      fontWeight: 300,
      paddingTop: '10px',
      marginLeft: '32px',
      paddingBottom: '10px',
    },
  },
  card: {
    minHeight: '60px',
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    '@media(max-width: 1260px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 2,
    },
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 1260px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
  },
  select: {
    margin: '6px 8px 5px 0',
    width: '199px',
    '@media(max-width: 1260px)': {
      width: '100%',
      margin: '5px 0px 18px 0px',
    },
  },

  collapseWrapper: {
    width: '100%',
    background: 'transparent',
    display: 'flex',
    paddingRight: '17px',
    justifyContent: 'flex-end',
    '@media(max-width: 1260px)': {
      flexDirection: 'column',
      paddingLeft: '23px',
      paddingRight: '23px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandArrow: {
    position: 'absolute',
    right: 12,
    top: 7,
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '@media(max-width: 1260px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  wrapper: {
    marginBottom: theme.font.sizes.xxxxxxl,
    '@media(min-width: 960px)': {
      marginTop: theme.font.sizes.xxxxxxl,
    },
  },
});

export default styles;
