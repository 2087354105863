import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px 24px 24px 0px;
  width: 100%;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.sizes.xxl};
    margin: 0 0 16px 0;
  `}
`;

export const MethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 777px;
`;

export const MethodButton = styled.button`
  ${({ theme, active }) => css`
    align-items: center;
    background-color: transparent;
    border-color: ${active ? theme.palette.primary.main : theme.palette.grey.primary};
    border-radius: 5px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    padding: 22px 16px 24px;
  `}
`;

export const RadioWrapper = styled.div`
  margin-right: 16px;

  @media (max-width: 705px){
    align-self: flex-start;
    margin-top: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.primary.contrastText};
    display: flex;
    height: 72px;
    justify-content: center;
    width: 72px;
  `}
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1;

  @media (max-width: 705px){
    margin-left: 0px;
    margin-top: 16px;
    width: 100%;
    flex: none;
  }
`;

export const MethodName = styled.h4`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.xxl};
    margin: 0 0 8px 0;
    text-align: left;
  `}
`;

export const MethodDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0 0 8px 0;
    text-align: left;
  `}
`;

export const LoaderAdapter = styled.div`
  display: flex;
  justify-content: center;
`;
