import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class NewSiteWebsiteBuilder extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 434 283.5">
        <path
          className={classes.st0}
          d="M54.4,244.2l39.2,32.1L383.1,103c7.9-4.7,10.4-15.1,5.6-22.9l-28.3-46c-4.8-7.7-14.8-10.2-22.6-5.5L49,201.5
          L54.4,244.2z"
        />
        <path className={classes.st1} d="M391.6,98l-44.7-74.8L382.4,2c7-4.2,16.2-1.7,20.6,5.6L431.8,56c4.4,7.3,2.2,16.6-4.8,20.8L391.6,98z" />
        <path className={classes.st1} d="M0.7,276.5l48.2-74.9l44.7,74.7l-88.8,7.1C1.2,283.7-1.2,279.5,0.7,276.5z" />
        <path className={classes.st0} d="M0.7,276.5l21.6-33.7l22.4,37.4l-39.9,3.2C1.2,283.7-1.2,279.5,0.7,276.5z" />
        <path
          className={classes.st2}
          d="M74.4,244.2l229-137.1c1.3-0.8,2.7-1.1,4.1-1.1c2.7,0,5.4,1.4,6.9,3.9c2.3,3.8,1,8.7-2.8,11L82.6,258
          L74.4,244.2z"
        />
        <path
          className={classes.st2}
          d="M335.2,56c2.7,0,5.4,1.4,6.9,3.9c2.3,3.8,1,8.7-2.8,11L162.8,176.5c-3.8,2.3-8.7,1-11-2.8s-1-8.7,2.8-11
          L331,57.2C332.3,56.4,333.7,56,335.2,56z"
        />
        <path className={classes.st3} d="M391.6,98L365,113.9l-44.7-74.7l26.6-15.9L391.6,98z" />
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(NewSiteWebsiteBuilder);
