import React from 'react';
import { withStyles } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import TicketMessage from '@/components/Tickets/TicketMessage';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import styles from './styles';


class TicketContent extends React.Component {
  constructor(props) {
    super(props);
    this.scrollbars = React.createRef();
  }

  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    const { mounted } = this.state;
    mounted && this.scrollbars.current.scrollToBottom();
  }

  render() {
    const {
      classes,
      comments,
      status,
      t,
    } = this.props;

    return (
      <Scrollbars ref={this.scrollbars}>
        <div className={`${classes.content} `}>
          {comments.map((comment) => {
            const thumbnails = comment.attachments
            && comment.attachments.map(image => (
              image.thumbnails.length && image.thumbnails[0].content_url
            ));

            const attachments = comment.attachments.length
            && comment.attachments.map(attachment => (
              attachment.content_url
            ));

            const fileName = comment.attachments.length
            && comment.attachments.map(attachment => (
              attachment.file_name
            ));

            return (
              <TicketMessage
                key={comment.id}
                person={comment.person}
                time={comment.elapsedTime}
                date={comment.created_at}
                message={comment.html_body}
                thumbnails={thumbnails}
                url={attachments}
                filename={fileName}
              />
            );
          })}
          {status === 'solved' && (
            <div className={classes.endMessage}>
              <WarningNotice message={t('tickets.endMessage')} fill="80%" />
            </div>
          )}
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = state => ({
  comments: state.ticketDetail.comments,
});

export default withI18n()(withStyles(styles)(connect(mapStateToProps)(TicketContent)));
