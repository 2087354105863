export const FormsActionTypes = {
  POST_FORM: 'POST_FORM',
  REQUEST_HG_ROUTINE_BACKUPS: 'REQUEST_HG_ROUTINE_BACKUPS',
  SUCCESS_HG_ROUTINE_BACKUPS: 'SUCCESS_HG_ROUTINE_BACKUPS',
  FAILURE_HG_ROUTINE_BACKUPS: 'FAILURE_HG_ROUTINE_BACKUPS',
  SET_HG_BACKUP_HISTORY_LOAD_STATE: 'SET_HG_BACKUP_HISTORY_LOAD_STATE',
  SET_HG_ROUTINE_BACKUPS: 'SET_HG_ROUTINE_BACKUPS',
  SET_DEFAULT_FORCE_STATE: 'SET_DEFAULT_FORCE_STATE',
  SET_FORCE_STATE: 'SET_FORCE_STATE',
  SKIP_HG_ROUTINE_BACKUPS: 'SKIP_HG_ROUTINE_BACKUPS',
};
