import React from 'react';
import * as Style from './IconLight.style';

const IconLight = ({
  color = '#FBCE3B',
  testId = 'icon-light',
  size = 17,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="tips_and_updates_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 24 24">
      <rect id="Rectangle_15643" data-name="Rectangle 15643" width="24" height="24" fill="none" />
      <path id="Path_247532" data-name="Path 247532" d="M7,20h4a2,2,0,0,1-4,0ZM5,19h8V17H5ZM16.5,9.5A7.536,7.536,0,0,1,12.73,16H5.27A7.5,7.5,0,1,1,16.5,9.5Zm4.87-2.13L20,8l1.37.63L22,10l.63-1.37L24,8l-1.37-.63L22,6ZM19,6l.94-2.06L22,3l-2.06-.94L19,0l-.94,2.06L16,3l2.06.94Z" />
    </svg>

  </Style.Wrapper>
);


export default IconLight;
