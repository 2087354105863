import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './SiteLockUpgradeHandler.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import {
  Button, IconCheck, IconLoading, IconStar, Radio,
} from 'gatorcomponents';
import sitelock from '@/media/security/sitelock.svg';
import useLocale from '@/hooks/useLocale';
import { CYCLE_TYPES, PRICES, SITELOCK_ID } from './SiteLockUpgradeHandler.types';
import { SITELOCK_PLANS } from '@/enums/products/products.enum';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { DetailsSection } from '../../SecurityCta/SiteLockLP/components/DetailsSection';
import { DiscoverBenefits } from '../../SecurityCta/SiteLockLP/components/DiscoverBenefits';
import { SnappyBanner } from '../../SecurityCta/SiteLockLP/components/SnappyBanner';
import SnappyFace from '@/media/sitelock/snappy-face.png';
import { sitelockAnalytics } from '@/analytics/security/sitelock';
import { UpgradeModal } from './components/UpgradeModal';
import { GroupPaymentModal } from '@/components/billing/subscriptions/GroupPaymentModal';
import { preChatActions, securityActions } from '@/redux/modules';
import { loadInvoices } from '@/redux/actions/invoices';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_TOKEN_REFRESH } from '@/config/GrowthBook/constants';
import { OriginCalling } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';

const SiteLockUpgradeHandler = () => {
  const [selectedCycle, setSelectedCycle] = useState(CYCLE_TYPES.ANNUALLY);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [requestedUpgrade, setRequestedUpgrade] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [successUpgrade, setSuccessUpgrade] = useState(false);
  const [newPlanData, setNewPlanData] = useState(null);
  const waitTokenToggle = useFeatureIsOn(CHARLIE_TOKEN_REFRESH);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { requestDiagnostic } = useDiagnostic();
  const { security: securityLocale, routes: routesLocale } = useLocale();
  const { sitelock: sitelockState, loading: loadingDiagnostic, diagnosticLoaded: successDiagnostic } = useSelector(state => state.preChat);
  const { upgrade: upgradeState } = useSelector(state => state.security);
  const { loading: invoicesLoading, byStatus: invoicesListState } = useSelector(state => state.invoices);


  const { upgrade: upgradeSitelockLocale } = securityLocale;
  const { successModal: successModalLocale } = upgradeSitelockLocale;
  const landingPageLocale = upgradeSitelockLocale.landingPageContent;
  const sitelockUpgradeLoading = (upgradeState && upgradeState.loading) || invoicesLoading;

  const newUpgradeProcessed = requestedUpgrade && upgradeState && !upgradeState.loading && !!upgradeState.invoiceId;
  const newInvoiceGenerated = !invoicesLoading && !!invoicesListState.Unpaid.length;
  const displayInvoiceModal = newUpgradeProcessed && newInvoiceGenerated;

  const product = sitelockState.find && sitelockState.find(product => product.id.toString() === params.productId);

  const redirectToPurchase = useCallback(() => {
    if (product) {
      const productNotElegible = (product.status && product.status !== 'active') || (product.plan && product.plan !== SITELOCK_PLANS.FREE);

      if ((!loadingDiagnostic && successDiagnostic && productNotElegible && !successUpgrade)) {
        history.push('/sitelock');
      }
    }
  }, [product, history, loadingDiagnostic, successDiagnostic, successUpgrade]);

  const handleSignNow = () => {
    history.push('/sitelock');
  };

  const handleBuyNow = (selectedPlan) => {
    setNewPlanData(selectedPlan);
    setRequestedUpgrade(true);

    if (!sitelockUpgradeLoading) {
      sitelockAnalytics.newSitelockPage.gaHandleContractNow();
    }

    const requestPayload = {
      hosting_id: product.id,
      new_product_id: selectedPlan.pid,
      new_billing_cycle: selectedCycle,
      offer_contexts: ['sitelockfree_scan_upsell'],
    };

    if (!sitelockUpgradeLoading) {
      dispatch(securityActions.upgrade.request(requestPayload));
      setSelectedProduct(selectedPlan.plan);
    }
  };

  useEffect(() => {
    if (upgradeState && !upgradeState.loading && upgradeState.invoiceId) {
      dispatch(loadInvoices('Unpaid', false));
    }
  }, [dispatch, upgradeState]);

  const getDetailsSectionProps = () => {
    const defaultProps = {
      title: landingPageLocale.detailsSectionTitle,
      firstSectionTitle: landingPageLocale.detailsSectionFirstSectionTitle,
      firstSectionDescription: landingPageLocale.detailsSectionFirstSectionDescription,
      firstSectionButtonLabel: landingPageLocale.detailsSectionFirstSectionButtonLabel,
      secondSectionTitle: landingPageLocale.detailsSectionSecondSectionTitle,
      secondSectionDescription: landingPageLocale.detailsSectionSecondSectionDescription,
      secondSectionButtonLabel: landingPageLocale.detailsSectionSecondSectionButtonLabel,
      gaProtectMySite: sitelockAnalytics.gaProtectMySite,
    };

    return defaultProps;
  };

  const getDiscoverBenefitsProps = () => {
    const defaultProps = {
      title: landingPageLocale.discoveryTitle,
      benefits: landingPageLocale.benefits,
      ctaTitle: landingPageLocale.ctaTitle,
      ctaDescription: landingPageLocale.ctaDescription,
      buttonLabel: landingPageLocale.buttonLabel,
      gaWantMoreProtection: sitelockAnalytics.gaWantMoreProtection,
    };

    return defaultProps;
  };

  const getBannerProps = () => {
    const defaultProps = {
      title: landingPageLocale.bannerTitle,
      buttonLabel: landingPageLocale.bannerLabel,
      gaGetNowAnchor: sitelockAnalytics.gaGetNowAnchor,
    };

    return defaultProps;
  };

  const handleUpgradeSitelock = useCallback(() => {
    setRequestedUpgrade(false);
    setSuccessUpgrade(true);
  }, [setRequestedUpgrade, setSuccessUpgrade]);

  const groupPaymentModalProps = useCallback(() => ({
    invoice: invoiceData,
    displayModalPayment: displayInvoiceModal,
    setDisplayModalPayment: () => setRequestedUpgrade(false),
    originCalling: OriginCalling.SITELOCK_UPGRADE,
    handleUpgradeSitelock,
  }), [displayInvoiceModal, invoiceData, handleUpgradeSitelock]);

  const setInvoiceById = useCallback(() => {
    if (displayInvoiceModal) {
      const invoiceById = invoicesListState.Unpaid.find(invoice => invoice.id === upgradeState.invoiceId);
      setInvoiceData(invoiceById);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayInvoiceModal]);

  const mountButtonLabel = planName => (
    <>
      {((sitelockUpgradeLoading) && selectedProduct === planName)
        ? <Styles.LoadingWrapper><IconLoading testId="loading-upgrade" /></Styles.LoadingWrapper>
        : upgradeSitelockLocale.cards.buyNowButtonLabel
      }
    </>
  );

  useEffect(() => {
    setInvoiceById();
  }, [setInvoiceById]);

  useEffect(() => {
    if (!product && !successDiagnostic) {
      requestDiagnostic('sitelockUpgrade');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    redirectToPurchase();
  }, [redirectToPurchase]);

  const mountCycleOptionLabel = cycle => (
    <Styles.RadioLabel htmlFor={`#${cycle}`} onClick={() => setSelectedCycle(cycle)} data-testid={`${cycle}Radio`}>
      <Radio checked={selectedCycle === cycle} id={cycle} />
      {upgradeSitelockLocale.cycleOptions[cycle]}
    </Styles.RadioLabel>
  );

  const getUpgradeModalProps = useCallback(() => {
    const defaultProps = {
      title: successModalLocale.title(newPlanData && newPlanData.plan),
      description: successModalLocale.description,
      ListItems: newPlanData && newPlanData.pid
        ? successModalLocale.listItems[SITELOCK_ID[(newPlanData.pid)]]
        : [],
      buttonLabel: successModalLocale.buttonLabel,
    };

    const handleRedirect = () => {
      dispatch(preChatActions.productByStatus.request({ withCancelled: true, withPending: true, waitToken: waitTokenToggle }));
      history.push(
        `${routesLocale.security}${routesLocale.productdetail}/${params.productType}/${params.productId}`,
      );
    };

    return {
      ...defaultProps,
      handleRedirect,
    };
  }, [newPlanData, params, history, routesLocale, successModalLocale, dispatch, waitTokenToggle]);

  if (loadingDiagnostic || !successDiagnostic || !product) {
    return (
      <Styles.Wrapper>
        <Styles.LoadingContainer>
          <IconLoading size="xl" />
        </Styles.LoadingContainer>
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper>

      <Styles.Section>
        <Styles.LogoWrapper>
          <Styles.SitelockLogo src={sitelock} alt="sitelock-logo" />
          <Styles.SubscriptTag>{upgradeSitelockLocale.tag}</Styles.SubscriptTag>
        </Styles.LogoWrapper>
        <Styles.Title>{upgradeSitelockLocale.title}</Styles.Title>
        <Styles.PurchaseInfoWrapper>
          <Styles.DomainLabel>
            {upgradeSitelockLocale.selectedDomain(product.domain)}
          </Styles.DomainLabel>
          <Styles.PaymentCycleWrapper>
            {upgradeSitelockLocale.paymentCicleLabel}
            <Styles.PaymentCycleOptionsWrapper>
              <Styles.PaymentCycleOptions>
                <Styles.PaymentCycleFrameWrapper>
                  {mountCycleOptionLabel(CYCLE_TYPES.ANNUALLY)}
                  {mountCycleOptionLabel(CYCLE_TYPES.SEMIANNUALLY)}
                </Styles.PaymentCycleFrameWrapper>
                <Styles.PaymentCycleFrameWrapper>
                  {mountCycleOptionLabel(CYCLE_TYPES.QUARTERLY)}
                  {mountCycleOptionLabel(CYCLE_TYPES.MONTHLY)}
                </Styles.PaymentCycleFrameWrapper>
              </Styles.PaymentCycleOptions>
            </Styles.PaymentCycleOptionsWrapper>
          </Styles.PaymentCycleWrapper>
        </Styles.PurchaseInfoWrapper>
        <Styles.CardsWrapper>
          {upgradeSitelockLocale.cards.items.map(card => (
            <Styles.Card isFree={card.plan === SITELOCK_PLANS.FREE} key={card.plan}>
              {card.plan === SITELOCK_PLANS.PROFESSIONAL && (
              <Styles.FeatureWrapper>
                <IconStar size="lg" />
              </Styles.FeatureWrapper>
              )}
              <Styles.CardTitle>{card.title}</Styles.CardTitle>
              <Styles.PlanInfoWrapper isFree={card.plan === SITELOCK_PLANS.FREE}>
                {card.plan === SITELOCK_PLANS.FREE ? (
                  <>
                    <Styles.CardSubtitle>{upgradeSitelockLocale.cards.currentPlanLabel}</Styles.CardSubtitle>
                    <Styles.PlanValueTitle>{upgradeSitelockLocale.cards.defaultOnHostPlanLabel}</Styles.PlanValueTitle>
                  </>
                ) : (
                  <>
                    <Styles.PlanValueTitle>{upgradeSitelockLocale.cards.valueToPaidLabel}</Styles.PlanValueTitle>
                    <Styles.PlanValueLabel>{formatCurrency(PRICES[card.plan][selectedCycle])}</Styles.PlanValueLabel>
                    <Styles.ButtonWrapper>
                      <Button
                        testId={`upgrade-to-${card.pid}`}
                        label={mountButtonLabel(card.plan)}
                        onClick={() => handleBuyNow(card)}
                        disabled={(sitelockUpgradeLoading) && selectedProduct !== card.plan}
                        size="large"
                      />
                    </Styles.ButtonWrapper>
                  </>
                )}
              </Styles.PlanInfoWrapper>
              <Styles.List>
                {card.list.map(item => (
                  <Styles.ListItem>
                    <Styles.IconWrapper>
                      <IconCheck color="primaryMedium" />
                    </Styles.IconWrapper>
                    <Styles.ListText>{item}</Styles.ListText>
                  </Styles.ListItem>
                ))}
              </Styles.List>
            </Styles.Card>
          ))}
        </Styles.CardsWrapper>
        <Styles.SectionFootWrapper>
          <Styles.SignNowSpan>
            {upgradeSitelockLocale.wishOtherSitelock(handleSignNow)}
          </Styles.SignNowSpan>
        </Styles.SectionFootWrapper>
        <Styles.BottomDetail>
          <Styles.SnappyDetail src={SnappyFace} />
        </Styles.BottomDetail>
      </Styles.Section>

      <DetailsSection {...getDetailsSectionProps()} />
      <DiscoverBenefits {...getDiscoverBenefitsProps()} />
      <SnappyBanner {...getBannerProps()} />

      {successUpgrade && (
        <UpgradeModal {...getUpgradeModalProps()} />
      )}

      {displayInvoiceModal && invoiceData && (<GroupPaymentModal {...groupPaymentModalProps()} />)}
    </Styles.Wrapper>


  );
};

export default SiteLockUpgradeHandler;
