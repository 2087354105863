import styled, { css } from 'styled-components';


export const Container = styled.div`
  ${({ theme }) => css`
    background-color:${theme.v2.colors.white.primary};
    font-family: ${theme.v2.font.family.primary};
    padding: 24px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px 0px;
    text-align: left;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px 0px;
    text-align: left;
  `}
`;

export const StepTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px 0px;
    text-align: left;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 24px 0px 0px 0px;

  @media(max-width: 960px){
    flex-direction: column;
  }
`;

export const RedirectMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px 0px;
    text-align: left;
  `}
`;

export const AlertWrapper = styled.div`
  margin: 24px 0px 0px;
  max-width: 1040px;
`;

export const InfoWrapper = styled.div`
  ${({ theme }) => css`
    margin: 24px 0px 0px;
    max-width: 1040px;
    color: ${theme.v2.colors.neutral.lowDark};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const BreadCrumb = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.primaryDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight:  ${theme.v2.font.weights.regular};
    justify-content: flex-start;

    button {
      margin-right: 16px;

      svg {
        fill: ${theme.v2.colors.neutral.lowDark}
      }
    }

    strong {
      border-bottom: 2px solid ${theme.v2.colors.brand.primaryBlue};
      font-weight:  ${theme.v2.font.weights.medium};
      margin-left: 8px;
    }
  `}
`;
