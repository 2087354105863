import React, {
  useCallback, useContext, useEffect,
  useState,
} from 'react';
import * as Styles from './LinkNaBioStatistic.styles';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, IconFilledInfo, Skeleton, Tooltip as GatorTooltip,
} from 'gatorcomponents';
import withErrorBoundary from '@/utils/errorBoundary';
import { useHistory, useParams } from 'react-router';
import { linkNaBioActions } from '@/redux/modules';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { FAQ_LINK_NA_BIO } from '@/config/urls/linkNaBioUrls';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { format } from 'date-fns';
import { ThemeContext } from 'styled-components';
import { IconGraph } from './components/IconGraph';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const LinkNaBioStatistic = () => {
  const [periodIntervalInDays, setPeriodIntervalInDays] = useState(7);
  const [profileSelected, setProfileSelected] = useState({});

  const { linkNaBio: linkNaBioLocale, routes: routesLocale } = useLocale();
  const { statistics: statisticsLocale } = linkNaBioLocale;
  const { profileId } = useParams();
  const history = useHistory();
  const { statistics, loading, profiles } = useSelector(state => state.linkNaBio);
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const setNewStatisticPeriod = (intervalInDays) => {
    setPeriodIntervalInDays(intervalInDays);
    dispatch(linkNaBioActions.statistic.request({ profileId, periodIntervalInDays: intervalInDays }));
  };

  const verifyProfileAndGetStatistics = useCallback(() => {
    const selectedProfile = profiles.find(profile => profile.id === Number(profileId));

    if (selectedProfile && selectedProfile.id) {
      setProfileSelected(selectedProfile);
    } else {
      history.push(routesLocale.linkNaBio);
    }

    dispatch(linkNaBioActions.statistic.request({ profileId, periodIntervalInDays }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, dispatch, history, profiles, routesLocale]);

  useEffect(() => {
    verifyProfileAndGetStatistics();
  }, [verifyProfileAndGetStatistics]);

  const getFaqProps = () => ({
    questions: FAQ_LINK_NA_BIO,
  });

  const labels = statistics.data ? statistics.data.map(dailyRegister => format(new Date(`${dailyRegister.date} 00:00:00`), 'dd/MM')) : [];
  const clicksData = statistics.data ? statistics.data.map(dailyRegister => dailyRegister.clicks) : [];
  const conversionData = statistics.data ? statistics.data.map(dailyRegister => dailyRegister.conversion) : [];
  const accessData = statistics.data ? statistics.data.map(dailyRegister => dailyRegister.access) : [];

  const chartData = {
    labels,
    datasets: [
      {
        type: 'line',
        label: statisticsLocale.totalConversion,
        backgroundColor: theme.v2.colors.feedback.activePure,
        borderWidth: 3,
        borderColor: theme.v2.colors.feedback.activePure,
        fill: true,
        data: conversionData,
        pointBorderColor: theme.v2.colors.neutral.highPure,
        pointBorderWidth: 2,
        pointRadius: 6,
      },
      {
        type: 'bar',
        label: statisticsLocale.totalAccess,
        backgroundColor: theme.v2.colors.brand.primaryMedium,
        data: accessData,
        borderRadius: 4,
        categoryPercentage: 0.4,
        barPercentage: 0.8,
      },
      {
        type: 'bar',
        label: statisticsLocale.totalClicks,
        backgroundColor: theme.v2.colors.brand.primaryExtraDark,
        data: clicksData,
        borderRadius: 4,
        categoryPercentage: 0.4,
        barPercentage: 0.8,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 25,
          color: theme.v2.colors.neutral.lowPure,
          font: {
            family: theme.v2.font.family.primary,
            size: 14,
            weight: theme.v2.font.weights.medium,
          },
          usePointStyle: true,
          boxHeight: 9,
        },
        tooltip: {
          bodyFont: {
            family: theme.v2.font.family.primary,
          },
          titleFont: {
            family: theme.v2.font.family.primary,
          },
        },
        onHover: (event) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = 'pointer';
        },
        onLeave: (event) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = 'default';
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.v2.colors.neutral.lowDark,
          font: {
            family: theme.v2.font.family.primary,
            size: 14,
            weight: theme.v2.font.weights.medium,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.v2.colors.neutral.lowDark,
          font: {
            family: theme.v2.font.family.primary,
            size: 14,
            weight: theme.v2.font.weights.medium,
          },
        },
      },
    },
  };

  return (
    <>
      <Styles.LinkNaBioUser>
        {statisticsLocale.linkPlaceholder + profileSelected.username}
      </Styles.LinkNaBioUser>

      {!statistics.data && loading && (
        <Styles.Container>
          <Styles.Skeleton gap={16}>
            <Skeleton variant="headline" width="200px" />
          </Styles.Skeleton>

          <Styles.Skeleton gap={30}>
            <Skeleton variant="textLine" width="400px" />
          </Styles.Skeleton>

          <Styles.Skeleton gap={50}>
            <Skeleton variant="rectangle" height="80px" width="716px" />
          </Styles.Skeleton>

          <Styles.Skeleton gap={45}>
            <Styles.SkeletonRow gap={175}>
              <Skeleton variant="textLine" width="195px" />

              <Styles.SkeletonRow gap={16}>
                <Skeleton variant="button" width="145px" />
                <Skeleton variant="button" width="145px" />
              </Styles.SkeletonRow>
            </Styles.SkeletonRow>
          </Styles.Skeleton>

          <Styles.Skeleton gap={10}>
            <Skeleton variant="rectangle" height="250px" width="715px" />
          </Styles.Skeleton>
        </Styles.Container>
      )}

      {statistics.data && (
        <Styles.Container>
          <Styles.Title>
            {statisticsLocale.consolidatedData}
          </Styles.Title>
          <Styles.Description>
            {statisticsLocale.totalData}
          </Styles.Description>

          <Styles.TotalData>
            <Styles.Data>
              <Styles.Circle variant="access" />
              {statisticsLocale.access(statistics.total && statistics.total.access)}
              <GatorTooltip content={statisticsLocale.accessTooltip} width={315} disableMobile>
                <IconFilledInfo color="primaryBlue" size="sm" />
              </GatorTooltip>
            </Styles.Data>
            <Styles.VerticalDivider />
            <Styles.Data>
              <Styles.Circle variant="clicks" />
              {statisticsLocale.clicks(statistics.total && statistics.total.clicks)}
              <GatorTooltip content={statisticsLocale.clicksTooltip} width={315} disableMobile>
                <IconFilledInfo color="primaryBlue" size="sm" />
              </GatorTooltip>
            </Styles.Data>
            <Styles.VerticalDivider />
            <Styles.Data>
              <Styles.Circle variant="conversion" />
              {statisticsLocale.conversionRate(statistics.total && statistics.total.conversion)}
              <GatorTooltip content={statisticsLocale.conversionRateTooltip} width={315} disableMobile>
                <IconFilledInfo color="primaryBlue" size="sm" />
              </GatorTooltip>
            </Styles.Data>
          </Styles.TotalData>

          <Styles.PeriodFilter>
            {statisticsLocale.dailyAccess}
            <Styles.FilterButtons>
              <Button
                label={statisticsLocale.lastWeek}
                onClick={() => setNewStatisticPeriod(7)}
                variant={periodIntervalInDays === 7 ? 'tabActive' : 'tab'}
              />
              <Button
                label={statisticsLocale.lastMonth}
                onClick={() => setNewStatisticPeriod(30)}
                variant={periodIntervalInDays === 30 ? 'tabActive' : 'tab'}
              />
            </Styles.FilterButtons>
          </Styles.PeriodFilter>

          <Styles.ChartWrapper>
            {statistics.data && loading && (<Skeleton variant="rectangle" height="360px" width="715px" />)}
            {statistics.data && !loading && (<Chart type="bar" data={chartData} options={chartOptions} />)}
          </Styles.ChartWrapper>
        </Styles.Container>
      )}

      {!loading && (!statistics.data || !statistics.data.length) && (
        <Styles.EmptyState>
          <IconGraph />
          <Styles.EmptyStateTitle>
            {statisticsLocale.emptyStateTitle}
          </Styles.EmptyStateTitle>
          <Styles.EmptyStateDescription>
            {statisticsLocale.emptyStateDescription}
          </Styles.EmptyStateDescription>
        </Styles.EmptyState>
      )}

      <Styles.FaqSection>
        <FaqDefault {...getFaqProps()} width="full" withoutButton />
      </Styles.FaqSection>
    </>
  );
};

export default withErrorBoundary(LinkNaBioStatistic, 'LinkNaBioStatistic');
