import styled, { css } from 'styled-components';

export const ContainerRight = styled.div`
  ${({ imageRight }) => css`
    display: flex;
    flex-direction: ${imageRight ? 'row' : 'row-reverse'};
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 40px;

    @media (max-width: 1194px) {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
    }
  `}
`;

export const TextArea = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    max-width: 395px;

    @media (max-width: 1194px) {
      margin-bottom: 50px;
      max-width: 100%;
    }
  `}
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 17px;
    font-weight: ${theme.v1.font.weights.regular};
  `}
`;

export const Image = styled.img`
  ${({ isMarginRight }) => css`
    margin-right: ${isMarginRight ? '27px' : '77px'};

    @media (max-width: 1194px) {
      margin-right: 0;
      max-width: 380px;
    }
 `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    text-align: left;

    @media (max-width: 1194px) {
    display: none;
    }
  `}
`;

export const IsMobileTitle = styled.h1`
  ${({ theme }) => css`
    @media (max-width: 1194px) {
        color: ${theme.v1.color.text.primary};
        text-align: left;
    }

    @media (min-width: 1195px) {
        display: none;
    }
  `}
`;

export const Container = styled.div`
  ${() => css`
    margin: 0 62px 62px 62px;
    max-width: 100%;
  `}
`;

export const Btn = styled.div``;
