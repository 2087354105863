import React from 'react';
import simpleBr from '@/media/security/simple_br.svg';
import codeguard from '@/media/security/codeguard.svg';
import sitelock from '@/media/security/sitelock.svg';
import { Button, Link } from 'gatorcomponents';
import { IconDetailedAlert } from '@/icons/IconDetailedAlert';
import { IconDetailedBrush } from '@/icons/IconDetailedBrush';
import { IconDetailedProtection } from '@/icons/IconDetailedProtection';
import { HgProducts } from '@/components/SupportForms/SupportForms.types';

export default {
  cards: {
    title: 'Backup e Segurança',
    description: (
      <>
        {'Precisa restaurar seus dados ou de mais segurança no site site? '}
        <strong>Veja abaixo as opções disponíveis:</strong>
      </>
    ),
    items: [
      {
        id: 'simple',
        title: 'BACKUP SIMPLES E MANUAL',
        imgSrc: simpleBr,
        list: [
          <>
            <strong>Segurança ao seu alcance:</strong>
            {' Faça cópias da sua hospedagem cPanel sem custos adicionais;'}
          </>,
          <>
            <strong>Restaure quando precisar:</strong>
            {' Restaure versões anteriores do seu site facilmente.'}
          </>,
          <>
            <strong>Não possui um backup para restauração?</strong>
            {' Solicite para o suporte por uma pequena taxa.'}
          </>,
        ],
        action: 'Acessar',
      },
      {
        id: 'codeguard',
        title: 'BACKUP AUTOMÁTICO',
        imgSrc: codeguard,
        list: [
          <>
            <strong>Proteção simplificada:</strong>
            {' Com o CodeGuard, os backups são automáticos;'}
          </>,
          <>
            <strong>Sempre seguro:</strong>
            {' Garanta a integridade dos seus dados com backups regulares;'}
          </>,
          <>
            <strong>Restauração rápida:</strong>
            {' Recupere seu site sem complicações e evite processos manuais.'}
          </>,
        ],
        action: 'Saiba mais',
      },
      {
        id: 'sitelock',
        title: 'SEGURANÇA EXTRA',
        imgSrc: sitelock,
        list: [
          <>
            <strong>Proteção diária:</strong>
            {' Realize scans diários em busca de Malwares, garantindo a segurança contínua do seu site;'}
          </>,
          <>
            <strong>Mostre sua credibilidade:</strong>
            {' Exiba o selo de segurança e inspire segurança nos seus visitantes;'}
          </>,
          <>
            <strong>Reputação impecável:</strong>
            {' Monitore e gerencie a sua reputação online.'}
          </>,
        ],
        action: 'Saiba mais',
      },
    ],
  },
  modal: {
    title: 'Lista de Planos',
    description: 'Selecione o plano desejado para realizar o seu backup.',
    select: {
      placeholder: 'Selecione um plano para continuar',
      label: 'Planos',
    },
    goToLabel: 'Ir para o Backup',
    status: {
      Active: 'Ativo',
      Suspended: 'Suspenso',
      Cancelled: 'Cancelado',
      Terminated: 'Cancelado',
    },
  },
  productsList: {
    title: 'Produtos contratados',
    filterLabel: 'Filtrar por',
    filterByPlaceholder: 'Busque por domínio',
    clearFilterLabel: 'Limpar filtros',
    description: (
      <>
        Aqui você vê os produtos contratados de:
        {' '}
        <strong>CodeGuard</strong>
        {' '}
        e
        {' '}
        <strong>SiteLock</strong>
        .
      </>
    ),
    due: 'Vencimento ',
    manage: 'Gerenciar',
    status: {
      Active: 'ATIVO',
      Pending: 'EM ANÁLISE',
      Suspended: 'SUSPENSO',
      Terminated: 'CANCELADO',
      Cancelled: 'CANCELADO',
    },
  },
  detailsPage: {
    tabs: {
      threats_detector: {
        title: 'Detecção de Ameaças',
        paidTitle: 'Detecção e remoção de Ameaças',
        noThreatsFounded: 'Não foram encontradas ameaças.',
        neverScanned: 'Estamos verificando seu site...',
        willShowScan: 'Exibiremos os resultados quando a verificação for finalizada.',
        threatsFounded: threats => (
          <>
            {'Encontramos '}
            {threats}
            {threats > 1 ? ' ameaças ' : ' ameaça '}
            {'no seu site!'}
          </>
        ),
        lastCheck: 'Última verificação',
        nextCheck: 'Próxima verificação',
        verifiedPages: 'Páginas verificadas',
        verifiedFiles: 'Arquivos verificados',
        noMalwaresFounded: 'Nenhum malware identificado',
        malwaresFounded: malwares => (
          <>
            {malwares}
            {malwares > 1 ? ' malwares idenfiticados' : ' malware idenfiticado'}
          </>
        ),
        vulnerabilities: {
          not_found: 'Nenhuma vulnerabilidade identificada',
          found: 'Vulnerabilidades idenfiticadas',
        },
        blocklists: {
          not_found: 'Nenhuma blocklist detectada',
          found: 'Blocklists idenfiticadas',
        },
        paidPlanNote: plan => `Seu site possui detecção e remoção automática de ameaças ativos com o ${plan}.`,
        freePlanNote: handleClick => (
          <>
            {'A verificação gratuita apenas detecta ameaças que estejam nos arquivos externos do seu site. Para uma proteção mais completa e verificação profunda do site, '}
            <Button variant="text" label="assine um Plano do SiteLock." onClick={handleClick} />
          </>
        ),
        signSitelockCard: {
          howToFixThreats: 'Como corrigir ameaças encontradas',
          howToFixThreatsDescription: 'Para corrigir automaticamente as ameaças, assine um Plano do SiteLock ou veja os links de ajuda mais abaixo, para fazer de forma manual.',
          tag: 'PROTEÇÃO COMPLETA',
          title: 'Remova malwares automaticamente!',
          description: 'Tenha remoção automática de Malwares, múltiplos tipos de verificações e centenas de páginas monitoradas com um pacote de proteção completo para o seu site.',
          subscribeButton: 'Assinar SiteLock',
        },
      },
      last_scans: {
        title: 'Últimas verificações',
        scanDate: 'Data da verificação',
        pagesVerified: 'Páginas verificadas',
        filesVerified: 'Arquivos verificados',
        threatsFound: 'Ameaças encontradas',
        filesFixed: 'Arquivos corrigidos',
        filesNeedFix: 'Arquivos com Pendência de correção',
        status: 'Status',
        safe: 'Seguro',
        unsafe: 'Inseguro',
        description: ({ handleSignSitelock, seeHowToFix, isFree }) => (
          <>
            {isFree && (
            <>
              {'Veja as últimas verificações realizadas no seu site. Para correção e remoção automática, '}
              <Button label="assine um Plano do SiteLock" variant="text" onClick={handleSignSitelock} />
              . Para os arquivos com &quot;pendência de correção&quot; será necessária uma tratativa manual.
              {' '}
              <Button label="Veja como corrigir" variant="text" onClick={seeHowToFix} />
            </>
            )}
            {!isFree && (
              <>
                Veja as últimas verificações realizadas no seu site. O seu plano de SiteLock remove automaticamente as principais ameaças encontradas. Para os arquivos com &quot;pendência de correção&quot; será necessária uma tratativa manual.
                {' '}
                <Button label="Veja como corrigir" variant="text" onClick={seeHowToFix} />
                .
              </>
            )}
          </>
        ),
        emptyStateLabel: 'Ainda não foi realizada nenhuma verificação no seu site, exibiremos os resultados quando a primeira verificação for finalizada.',
        loadMore: 'Carregar mais',
        signSitelockCard: {
          howToFixThreats: 'Como corrigir ameaças encontradas',
          howToFixThreatsDescription: 'Para corrigir automaticamente as ameaças, assine um Plano do SiteLock ou veja os links de ajuda mais abaixo, para fazer de forma manual.',
          tag: 'PROTEÇÃO COMPLETA',
          title: 'Remova malwares automaticamente!',
          description: 'Tenha remoção automática de Malwares, múltiplos tipos de verificações e centenas de páginas monitoradas com um pacote de proteção completo para o seu site.',
          subscribeButton: 'Assinar SiteLock',
        },
        emptyScans: 'A ferramenta de remoção de ameaças do SiteLock ainda não foi configurada, e por isso, não é possível exibir os resultados das verificações. Para ativar a verificação e remoção automática de ameaças, configure sua conta FTP no SiteLock.',
        goToFTP: 'Ir para configuração de FTP',
      },
      security_seal: {
        title: 'Selo de Segurança',
        description: {
          0: 'Adicione um Selo de Segurança no seu site e mostre para os seus visitantes que ele é seguro para navegação.',
          1: (
            <>
              <strong>Atenção:</strong>
              {' Se houver problemas identificados na verificação do SiteLock, é importante corrigi-los em alguns dias para manter o selo. Caso contrário, o selo será removido. Não alertamos os visitantes sobre falhas na verificação.'}
            </>
          ),
        },
        preview: 'Pré-visualização:',
        saveToView: 'Salve as alterações para visualizar as mudanças',
        howToInsert: (
          <>
            <Link
              text="Como inserir o selo de segurança do SiteLock no site"
              href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000393034-Como-inserir-no-site-o-selo-de-seguran%C3%A7a-do-SiteLock-?_ga=2.38925859.1211142327.1705338544-165595683.1692209116"
            />
          </>
        ),
        labelCopyInput: 'Insira o código abaixo no seu site para instalar o selo:',
        edit: 'Editar',
        copy: 'Copiar Selo',
        cancel: 'Cancelar',
        save: 'Salvar',
        colors: {
          white: 'Branco',
          red: 'Vermelho',
        },
        sizes: {
          small: 'Pequeno',
          medium: 'Médio',
          big: 'Grande',
        },
        type: {
          mal: 'Sem Malware',
          secure: 'Seguro',
        },
        language: {
          br: 'Português',
          es: 'Espanhol',
          en: 'Inglês',
        },
        labelColor: 'Cor',
        labelSize: 'Tamanho',
        labelLanguage: 'Idioma',
        labelStyle: 'Estilo',
        successfullUpdate: 'Alterações no selo salvas!',
        failedfullUpdate: 'Erro ao salvar as alterações no selo.',
      },
      configurations: {
        title: 'Configurações',
        ftpLabel: 'Configuração de FTP para remoção automática de ameaças',
        ftpDescription: 'A varredura automática do SiteLock verifica diariamente o seu site em busca de código malicioso e o remove dos arquivos. Para isso, é necessário configurar suas credenciais FTP. Insira os dados de acesso do FTP da sua hospedagem nos campos abaixo.',
        serverUrl: 'URL do servidor',
        serverUrlPlaceholder: 'Insira a URL do servidor',
        username: 'Nome de usuário',
        usernamePlaceholder: 'Insira seu Nome de usuário',
        password: 'Senha',
        passwordPlaceholder: 'Insira sua senha',
        save: 'Salvar configurações',
        tooltip: (
          <>
            <strong>Importante:</strong>
            {' '}
            Para que a varredura seja feita corretamente, certifique-se de que o seu domínio já está vinculado ao plano de hospedagem selecionado através dos dados de FTP inseridos.
          </>
        ),
        doubts: ({ handleClick }) => (
          <>
            {'Se tiver dúvidas sobre seu acesso FTP, '}
            <Button variant="text" label="consulte as instruções de acesso." onClick={handleClick} size="large" />
          </>
        ),
        successfullUpdate: 'Suas configurações foram atualizados!',
        failedfullUpdate: 'Não foi possível realizar as alterações.',
        required: 'Campo obrigatório',
      },
    },
    sitelockPanelButton: 'Painel do SiteLock',
    faqButton: 'Ver mais conteúdos',
  },
  upgrade: {
    tag: 'PROTEÇÃO COMPLETA',
    title: 'Proteja mais páginas e remova ameaças automaticamente com um plano pago SiteLock',
    selectedDomain: domain => (
      <>
        {'Domínio selecionado: '}
        <em>{domain}</em>
      </>
    ),
    paymentCicleLabel: 'Ciclo de pagamento:',
    cycleOptions: {
      annually: 'Anual',
      semiannually: 'Semestral',
      quarterly: 'Trimestral',
      monthly: 'Mensal',
    },
    cards: {
      currentPlanLabel: 'Seu plano atual',
      buyNowButtonLabel: 'Contrate agora',
      valueToPaidLabel: 'Valor a pagar',
      defaultOnHostPlanLabel: 'Incluso no seu plano de hospedagem.',
      items: [
        {
          plan: 'Sitelock Free',
          pid: 440,
          title: 'Grátis',
          list: [
            <>
              {'Proteção para '}
              <strong>5 páginas</strong>
            </>,
            'Detecção de ameaças',
            'Selo de segurança',
          ],
        },
        {
          plan: 'Sitelock Professional',
          pid: HgProducts.SITELOCK_PROFESSIONAL,
          title: 'Professional',
          list: [
            <>
              {'Proteção para até '}
              <strong>100 páginas</strong>
            </>,
            'Remoção automática de Malwares',
            'Verificação diária de FTP',
            'Verificação de edição de arquivos',
            'Monitoramento de blocklist',
            <strong>Todos os recursos do Grátis</strong>,
          ],
        },
        {
          plan: 'Sitelock Premium',
          pid: HgProducts.SITELOCK_PREMIUM,
          title: 'Premium',
          list: [
            <>
              {'Proteção para até '}
              <strong>500 páginas</strong>
            </>,
            'Verificação de segurança de rede',
            <strong>Todos os recursos do Professional</strong>,
          ],
        },
        {
          plan: 'Sitelock Enterprise',
          pid: HgProducts.SITELOCK_ENTERPRISE,
          title: 'Enterprise',
          list: [
            <>
              {'Proteção para até '}
              <strong>2500 páginas</strong>
            </>,
            'Verificação avançada de segurança',
            <strong>Todos os recursos do Premium</strong>,
          ],
        },
      ],
    },
    wishOtherSitelock: handleClick => (
      <>
        {'Deseja proteger outro domínio com o '}
        <strong>{'SiteLock? '}</strong>
        <Button label="Assine agora!" variant="text" onClick={handleClick} />
      </>
    ),
    landingPageContent: {
      detailsSectionTitle: (
        <>
          Mais de
          <span> 12 milhões de sites </span>
          contam com a proteção do SiteLock
        </>
      ),
      detailsSectionFirstSectionTitle: 'Proteja seu site, sua reputação e seus visitantes',
      detailsSectionFirstSectionDescription: (
        <>
          <p>Não jogue com segurança do site. Existem muitas ameaças na internet, e prevenir ataques é a melhor opção para evitar prejuízos.</p>
          <p>
            O SiteLock tem
            <b> um ótimo custo-benefício </b>
            , e protege seu site de ameaças cibernéticas maliciosas.
          </p>
        </>
      ),
      detailsSectionFirstSectionButtonLabel: 'Quero proteger meu site',
      detailsSectionSecondSectionTitle: 'Não importa o tamanho do seu site, segurança nunca é demais',
      detailsSectionSecondSectionDescription: (
        <>
          Com o SiteLock você não tem trabalho. Ele monitora e
          <b> remove ameaças automaticamente </b>
          e com extrema facilidade, evitando dores de cabeça.
        </>
      ),
      detailsSectionSecondSectionButtonLabel: 'Quero proteger meu site',
      discoveryTitle: 'Descubra como o SiteLock protege o seu site',
      benefits: [
        {
          icon: <IconDetailedAlert />,
          id: 0,
          title: 'Alertas e atualizações imediatas',
          description: 'Mantenha-se informado sobre a segurança do seu site com alertas por e-mail ou através do painel do SiteLock.',
        },
        {
          icon: <IconDetailedBrush />,
          id: 1,
          title: 'Remoção automática de malwares',
          description: 'Não se preocupe, você não precisa ser um astro da tecnologia para livrar seu site de malwares.',
        },
        {
          icon: <IconDetailedProtection />,
          id: 2,
          title: 'Proteção dos arquivos do seu site',
          description: 'Com SiteLock todos os arquivos do seu site estão em segurança e não correm risco de serem infectados.',
        },
      ],
      ctaTitle: 'Mostre que o seu site é 100% seguro',
      ctaDescription: 'Crie um ambiente seguro para os visitantes do seu site: com o Selo de Segurança SiteLock você dá mais tranquilidade para que os visitantes do seu site naveguem e comprem mais de você',
      buttonLabel: 'Quero mais proteção',
      bannerTitle: 'Proteja seu site contra ameaças cibernéticas',
      bannerLabel: 'Contrate agora',
    },
    successModal: {
      title: (name = 'Sitelock') => `Já estamos configurando o ${name} em sua conta!`,
      description: 'A remoção automática de ameaças e outros recursos do seu novo plano estarão disponíveis em breve. Veja abaixo os principais:',
      listItems: {
        professional: [
          'Proteção para até 100 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Todos os recursos do Grátis',
        ],
        premium: [
          'Proteção para até 500 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Verificação de segurança de rede',
          'Todos os recursos do Grátis',
        ],
        enterprise: [
          'Proteção para até 2500 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Verificação de segurança de rede',
          'Verificação avançada de segurança',
          'Todos os recursos do Grátis',
        ],
      },
      buttonLabel: 'Ir para página do SiteLock',
    },
  },
  provisionatedModal: {
    tag: 'Novidade!',
    title: 'Agora você tem acesso ao SiteLock Grátis',
    description: 'Incluímos o SiteLock Grátis em todos os domínios dos Planos M e Turbo, com isso sua hospedagem ficou ainda melhor! Veja abaixo os recursos:',
    items: [
      'Detecção de ameaças automática e diária',
      'Selo de segurança para dar mais credibilidade ao seu site',
      'Proteção para até 5 páginas por site',
    ],
    cta: 'Ir para página do SiteLock',
  },
};
