import {
  REQUEST_HOSTING_TYPES, RECEIVE_HOSTING_TYPES, ERROR_HOSTING_TYPES, REQUEST_EMAILS,
  RECEIVE_EMAILS, ERROR_EMAILS, FILTERED_EMAILS, REQUEST_DELETE_EMAILS,
  SUCCESS_DELETE_EMAILS, ERROR_DELETE_EMAILS, REQUEST_HOST_DOMAIN, RECEIVE_HOST_DOMAIN,
  ERROR_HOST_DOMAIN, REQUEST_CREATE_EMAIL, SUCCESS_CREATE_EMAIL, ERROR_CREATE_EMAIL,
  REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS, SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS,
  ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS, REQUEST_ALTER_PASSWORD_EMAILS,
  SUCCESS_ALTER_PASSWORD_EMAILS, ERROR_ALTER_PASSWORD_EMAILS,

  RECEIVE_PRO_EMAILS_LIST, REQUEST_GET_PRO_EMAILS_LIST, ERROR_GET_PRO_EMAILS_LIST, REQUEST_GET_UPGRADE_SUMMARY, ERROR_GET_UPGRADE_SUMMARY, RECEIVE_GET_UPGRADE_SUMMARY, REQUEST_UPGRADE_MAIL_INVOICE, ERROR_UPGRADE_MAIL_INVOICE, RECEIVE_UPGRADE_MAIL_INVOICE, ERROR_EXISTENT_INVOICE_VALIDATION, RECEIVE_EXISTENT_INVOICE_VALIDATION, REQUEST_EXISTENT_INVOICE_VALIDATION, RECEIVE_UPGRADE_TITAN,
} from '@/redux/actions/actionsTypes';
import { EmailsActionTypes } from '../modules/emails/email.types';


const initialState = {
  loading: false,
  loadingProEmails: false,
  loadingDeleteAccount: false,
  loadingCreateAccount: false,
  loadingAlterPassword: false,
  loadingChangeStorageAccount: false,
  total: 0,
  list: [],
  bkp: [],
  loadedDate: null,
  hostings: {
    loading: false,
    list: [],
    loadedDate: null,
  },
  hostDomain: {
    loadedDate: null,
    loading: false,
    list: [],
    primaryDomain: '',
  },
  proEmailList: [],
  loadingUpgradeSummary: false,
  upgradeSummary: {},
  loadingUpgradeMailInvoice: false,
  createMailLoader: false,
  eligibleForTitanUpgrade: false,
  eligibleDomainOptions: [],
  paymentMethods: [],
  buyFastcheckout: {
    isLoading: false,
    aborted: false,
    error: false,
    invoiceId: null,
    redirectToInvoices: false,
    paymentMethod: null,
    domainsWithInvoices: [],
  },
};

export default function emails(state = initialState, action) {
  switch (action.type) {
    case EmailsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.isLoading,
      };
    case REQUEST_EMAILS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_EMAILS:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_EMAILS:
      return {
        ...state,
        loading: false,
        list: action.emails,
        bkp: action.emails,
        total: action.total,
        loadedDate: action.loadedDate,
      };
    case REQUEST_HOSTING_TYPES:
      return {
        ...state,
        hostings: {
          ...state.hostings,
          loading: true,
        },
      };
    case RECEIVE_HOSTING_TYPES:
      return {
        ...state,
        hostings: {
          ...state.hostings,
          loading: false,
          list: action.hostings,
          loadedDate: action.loadedDate,
        },
      };
    case FILTERED_EMAILS:
      return {
        ...state,
        list: action.data,
      };
    case ERROR_HOSTING_TYPES:
      return {
        ...state,
        hostings: {
          ...state.hostings,
          loading: false,
        },
      };
    case REQUEST_HOST_DOMAIN:
      return {
        ...state,
        hostDomain: {
          ...state.hostDomain,
          loading: true,
        },
      };
    case RECEIVE_HOST_DOMAIN:
      return {
        ...state,
        hostDomain: {
          ...state.hostDomain,
          list: action.hostDomain,
          loading: false,
          loadedDate: action.loadedDate,
          primaryDomain: action.primaryDomain,
        },
      };
    case ERROR_HOST_DOMAIN:
      return {
        ...state,
        hostDomain: {
          ...state.hostDomain,
          loading: false,
        },
      };
    case REQUEST_CREATE_EMAIL:
      return {
        ...state,
        loadingCreateAccount: true,
      };
    case SUCCESS_CREATE_EMAIL:
      return {
        ...state,
        loadingCreateAccount: false,
        list: action.emailsList,
        total: action.total,
        loadedDate: action.loadedDate,
      };
    case ERROR_CREATE_EMAIL:
      return {
        ...state,
        loadingCreateAccount: false,
      };
    case REQUEST_DELETE_EMAILS:
      return {
        ...state,
        loadingDeleteAccount: true,
      };
    case SUCCESS_DELETE_EMAILS:
      return {
        ...state,
        list: action.data,
        total: action.total,
        loadingDeleteAccount: false,
      };
    case ERROR_DELETE_EMAILS:
      return {
        ...state,
        loadingDeleteAccount: false,
      };
    case REQUEST_ALTER_PASSWORD_EMAILS:
      return {
        ...state,
        loadingAlterPassword: true,
      };
    case SUCCESS_ALTER_PASSWORD_EMAILS:
      return {
        ...state,
        loadingAlterPassword: false,
      };
    case ERROR_ALTER_PASSWORD_EMAILS:
      return {
        ...state,
        loadingAlterPassword: false,
      };
    case REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS:
      return {
        ...state,
        loadingChangeStorageAccount: true,
      };
    case SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS:
      return {
        ...state,
        loadingChangeStorageAccount: false,
        bkp: action.list,
        list: action.list,
      };
    case ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS:
      return {
        ...state,
        loadingChangeStorageAccount: false,
      };
    case REQUEST_GET_PRO_EMAILS_LIST:
    case EmailsActionTypes.GET_EMAILS_REQUEST:
      return {
        ...state,
        loadingProEmails: true,
      };
    case ERROR_GET_PRO_EMAILS_LIST:
    case EmailsActionTypes.GET_EMAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_PRO_EMAILS_LIST:
      return {
        ...state,
        loadingProEmails: false,
        proEmailList: action.data,
        createMailLoader: false,
        eligibleDomainOptions: action.eligibleDomainOptions,
        eligibleForTitanUpgrade: action.eligibleForTitanUpgrade,
      };
    case REQUEST_GET_UPGRADE_SUMMARY:
      return {
        ...state,
        loadingUpgradeSummary: true,
      };
    case ERROR_GET_UPGRADE_SUMMARY:
      return {
        ...state,
        loadingUpgradeSummary: false,
      };
    case RECEIVE_GET_UPGRADE_SUMMARY:
      return {
        ...state,
        loadingUpgradeSummary: false,
        upgradeSummary: action.data,
      };
    case REQUEST_UPGRADE_MAIL_INVOICE:
      return {
        ...state,
        loadingUpgradeMailInvoice: true,
      };
    case RECEIVE_UPGRADE_MAIL_INVOICE:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          aborted: false,
          error: false,
          invoiceId: action.data.response.data.invoice_id,
          paymentMethod: action.data.paymentMethod,
          domainsWithInvoices: [...state.buyFastcheckout.domainsWithInvoices, action.data.domain],
          redirectToInvoices: true,
        },
      };
    case RECEIVE_UPGRADE_TITAN:
      return {
        ...state,
      };
    case ERROR_UPGRADE_MAIL_INVOICE:
      return {
        ...state,
        loadingUpgradeMailInvoice: false,
      };

    case REQUEST_EXISTENT_INVOICE_VALIDATION:
      return {
        ...state,
        loadingValidateUpgradeInvoice: true,
      };
    case RECEIVE_EXISTENT_INVOICE_VALIDATION:
      return {
        ...state,
        loadingValidateUpgradeInvoice: false,
      };
    case ERROR_EXISTENT_INVOICE_VALIDATION:
      return {
        ...state,
        loadingValidateUpgradeInvoice: false,
      };
    case EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_REQUEST:
      return {
        ...state,
        createMailLoader: true,
      };
    case EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_SUCCESS:
    case EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_FAILURE:
      return {
        ...state,
        createMailLoader: false,
      };
    case EmailsActionTypes.GET_PRO_EMAIL_PAYMENT_METHODS_SUCCESS: {
      const { paymentMethods } = action.payload;
      return {
        ...state,
        paymentMethods,
      }; }
    case EmailsActionTypes.REQUEST_TITAN_PREMIUM_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: true,
          aborted: false,
          error: false,
          redirectToInvoices: false,
        },
      };
    case EmailsActionTypes.RECEIVE_TITAN_PREMIUM_BUY_FAST_CHECKOUT: {
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          aborted: false,
          error: false,
          invoiceId: action.data.invoice_id,
          paymentMethod: action.data.paymentMethod,
          domainsWithInvoices: [...state.buyFastcheckout.domainsWithInvoices, action.data.domain],
          redirectToInvoices: true,
        },
      };
    }
    case EmailsActionTypes.ABORT_TITAN_PREMIUM_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          aborted: true,
          error: false,
          redirectToInvoices: false,
        },
      };
    case EmailsActionTypes.ERROR_TITAN_PREMIUM_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          aborted: false,
          error: true,
          redirectToInvoices: false,
        },
      };
    default:
      return state;
  }
}
