import React from 'react';
import * as Styles from './TicketUpgradeVpsModal.styles';
import { Modal } from '@/pages/common';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preChatActions } from '@/redux/modules';
import useLocale from '@/hooks/useLocale/useLocale';
import { preChatAnalytics } from '@/analytics/preChat';
import { Button } from 'gatorcomponents';

const TicketUpgradeVpsModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preChatVpsUpgrade = useSelector(state => state.preChat.vpsUpgrade);
  const { ticket: ticketLocale, routes } = useLocale();

  const handleModalClose = () => {
    dispatch(preChatActions.vps.redirect(false));
    dispatch(preChatActions.vps.productId(null));
    dispatch(preChatActions.preChat.setOpen(true));
  };

  const handleAcceptTermClick = () => {
    dispatch(preChatActions.vps.termsAccepted(true));
    dispatch(preChatActions.vps.redirect(false));
    history.push(`${routes.help}${routes.createTicket}`);
    preChatAnalytics.vpsUpgradeModal();
  };

  return preChatVpsUpgrade.redirect && (
    <Modal onClose={handleModalClose} testId="vps-upgrade-modal" maxWidth={633}>
      <Styles.ModalWrapper>
        <Styles.ModalTitle>
          {ticketLocale.ticketUpgradeVpsModal.title}
        </Styles.ModalTitle>
        <Styles.TextContainer>
          {ticketLocale.ticketUpgradeVpsModal.firstLabel()}
        </Styles.TextContainer>
        <Styles.TextContainer>
          {ticketLocale.ticketUpgradeVpsModal.secondLabel()}
        </Styles.TextContainer>
        <Styles.ButtonContainer>
          <Button
            size="large"
            onClick={handleAcceptTermClick}
            type="button"
            label={ticketLocale.ticketUpgradeVpsModal.buttonLabel}
            testId="ok-button-upgrade-vps"
          />
        </Styles.ButtonContainer>

      </Styles.ModalWrapper>
    </Modal>
  );
};

export default TicketUpgradeVpsModal;
