export const MODAL_STEP = {
  DUPLICATED_ORDER_CHOICE: 'DUPLICATED_ORDER_CHOICE',
  DEFAULT: 'DEFAULT',
  LOADING_SECOND_SCREEN: 'LOADING_SECOND_SCREEN',
  CANCELLATION_SUCCESS: 'CANCELLATION_SUCCESS',
  CANCELLATION_FAILURE: 'CANCELLATION_FAILURE',
};

export const ORDER_STATUS = {
  PAID: 'Paid',
};

export const ANALYTICS = {
  CLOSE_UNDERSTAND: 'CLOSE_UNDERSTAND',
};
