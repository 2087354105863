import React, { useState } from 'react';
import { withI18n, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import {
  CardContent, Grid, IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import DomainDetailCard from '@/components/Cards/DomainDetailCard';
import WarningNotice from '@/components/Notification/WarningNotice';
import LinkButton from '@/components/Buttons/LinkButton';
import { formatDate } from '@/utils/Formatters/formatDate';
import { resendVerificationEmail } from '@/redux/actions/domains';
import { registrobrActions } from '@/config/components/ctas';
import styles from './styles';

const ModalDomainWrapped = ({
  classes,
  open,
  onClose,
  t,
  domainsVerificationPending,
  loadingEmailVerification,
  domainEmailVerification,
  domainEmailVerificationSuccess,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [, { link: changeEmailLink }] = registrobrActions;

  const handleEmailConfirm = (domainId) => {
    setSelected(domainId);
    dispatch(resendVerificationEmail(domainId));
  };

  const domainStatus = (status) => {
    if (status === 'pending') {
      return 'cardAlerts.domain.emailConfirmSuccess';
    }
    return 'cardAlerts.domain.emailSuspended';
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className={classes.paper}>
        <IconButton
          className={classes.closeIcon}
          aria-label="Delete"
          color="default"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title} variant="h4" id="modal-title">
          {t('domains.emailConfirmation')}
        </Typography>
        <Typography className={classes.modalSubTitle}>
          <Trans i18nKey="domains.domainModalSubtitle">
            <strong>0</strong>
            1
          </Trans>
        </Typography>

        <Grid container className={classes.listContainer} spacing={16}>
          {domainsVerificationPending.map(domain => (
            <Grid item key={domain.domain_id} data-id={domain.domain_id} xs={12}>
              <DomainDetailCard domainStatus={domain.verification_status}>
                <CardContent className={`default ${classes.cardContent}`}>
                  <Grid container spacing={8} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography className="cardSubTitle" variant="caption">{t('domain')}</Typography>
                      <Typography className={classes.subTitle} variant="h6">{domain.domain_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography className="cardSubTitle" variant="caption">{t('domains.domainStatusTitle')}</Typography>
                      <Typography className={classes.subTitle} variant="h6">{domain.verification_status === 'suspended' ? t('domains.verificationStatusSuspended') : t('domains.verificationStatusPending')}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography className="cardSubTitle" variant="caption">{t('domains.domainExpirationDate')}</Typography>
                      <Typography className={classes.subTitle} variant="h6">{formatDate(domain.suspension_date)}</Typography>
                    </Grid>
                    <Grid className={`cardActions ${classes.gridLink}`} item xs={12} sm={3}>
                      <PrimaryButton
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.emailConfimationButton}
                        onClick={() => handleEmailConfirm(domain.domain_id)}
                        onLoading={loadingEmailVerification && selected === domain.domain_id}
                      >
                        {t('domains.emailConfirmation')}
                      </PrimaryButton>
                    </Grid>
                    {!loadingEmailVerification && selected === domain.domain_id && (
                    <Grid item xs={12}>
                      <div className={classes.warningNoticeContainer}>
                        <WarningNotice
                          fill="100%"
                          className={classes.wrapperWarningNotice}
                          message={(
                          domainEmailVerification && domainEmailVerificationSuccess
                            ? (
                              <Trans i18nKey={domainStatus(domain.verification_status)} values={{ email: domain.registrant_email }}>
                                <strong>0</strong>
                                <p>
                                  1
                                </p>
                                <span>
                                  2
                                </span>
                                <LinkButton target="blank" to={`${changeEmailLink}${domain.domain_id}`} className={classes.linkMessage}>3</LinkButton>
                                {domain.verification_status === 'suspended' && (
                                <div className={classes.wrapperWarningNoticeSuspended}>
                                  <p>
                                    4
                                  </p>
                                  <p>
                                    5
                                  </p>
                                </div>
                                )}
                              </Trans>
                            ) : (
                              <Trans i18nKey="cardAlerts.domain.emailConfirmError">
                                <strong>0</strong>
                                1
                              </Trans>
                            )
                        )}
                        />
                      </div>
                    </Grid>
                    )}
                  </Grid>
                </CardContent>
              </DomainDetailCard>
            </Grid>
          ))}

        </Grid>
      </div>
    </Modal>
  );
};

export default withI18n()(withStyles(styles)(ModalDomainWrapped));
