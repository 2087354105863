import React from 'react';
import PropTypes from 'prop-types';

export const IconArrowDownOutlined = ({ color }) => (
  <svg data-testid="icon-arrow-down" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Caminho_37341" data-name="Caminho 37341" d="M24,24H0V0H24Z" fill="none" opacity="0.87" />
    <path id="Caminho_37342" data-name="Caminho 37342" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" fill={color} />
  </svg>
);

IconArrowDownOutlined.propTypes = {
  color: PropTypes.string,
};

IconArrowDownOutlined.defaultProps = {
  color: '#2e93ee',
};
