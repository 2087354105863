const styles = theme => ({
  main: {
    backgroundColor: theme.color.mystic,
    position: 'relative',
    padding: '46px 176px',
    [theme.breakpoints.up('lg')]: {
      padding: '46px 176px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '46px 80px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '46px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  card: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      boxShadow: 'none',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexgrow: 1,
    paddingTop: '20px',
    minHeight: 'calc(92vh - 92px)',
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    '& .status-icon': {
      color: theme.color.indigo,
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      minHeight: '92vh',
      paddingBottom: 0,
      paddingLeft: 16,
    },
  },
  title: {
    zIndex: 2,
    userSelect: 'none',
    fontSize: '24px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.color.emperor,
    margin: '45px 0 15px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0 40px',
      marginBottom: '30px',
    },
  },
  description: {
    userSelect: 'none',
    fontSize: '14px',
    margin: 0,
    textAlign: 'center',
    color: theme.color.emperor,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 291,
      marginBottom: '10px',
      whiteSpace: 'inherit',
    },
  },
  descriptionMT20: {
    marginTop: 20,
  },
  choice: {
    userSelect: 'none',
    fontSize: '14px',
    margin: '10px 0 0 0',
    textAlign: 'center',
    color: theme.color.emperor,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '25px 0',
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 30,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 29,
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    flexgrow: 1,
    padding: '120px 0 34px 0',
    '& > a': {
      color: theme.color.indigo,
      '&:focus': {
        color: theme.color.indigo,
      },
      '&:active': {
        color: theme.color.indigo,
      },
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '343px',
      marginLeft: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '291px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  selectWrapperThemes: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      maxWidth: 291,
    },
  },
  select: {
    minWidth: '343px',
  },
  buttonCreateSite: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  loader: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonAfterThemes: {
    marginTop: 20,
  },
});

export default styles;
