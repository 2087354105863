import styled, { css } from 'styled-components';

export const Card = styled.div`
  ${({
    theme, selected, asButton, cursorPointer, iconAction,
  }) => css`
    align-items: ${iconAction ? 'center' : 'flex-start'};
    background-color: ${selected || asButton ? theme.color.lightBlue : ''};
    border: 1px solid ${theme.palette.grey.main};
    border-radius: 4px;
    cursor: ${cursorPointer ? 'pointer' : 'auto'};
    display: flex;
    font-family: ${theme.font.family.secondary};
    justify-content: space-between;
    overflow-x: hidden;
    padding: 15px;
    width: ${asButton ? '230px' : '348px'};

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    },
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.palette.grey.dark};
    display: flex;
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    margin-left: 35px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.regular};
    margin-left: 35px;
    margin-top: 10px;
  `}
`;

export const Icon = styled.img`
  ${() => css`
    height: 20px;
    padding: -3px;
    margin-left: -35px;
    margin-right: 15px;
  `}
`;

export const IconAction = styled.button`
 ${({ theme }) => css`
  background-color: transparent;
  border: none;

  &:hover{
    cursor: pointer;

    svg {
      fill: ${theme.color.cyanCobaltBlue};
      stroke: ${theme.color.cyanCobaltBlue};
    }
  }

  `}
`;

export const CardContainer = styled.div`
  max-width: 100%;
`;
