import styled, { css } from 'styled-components';


export const ModalWrapper = styled.div`
    padding: 32px;
`;

export const Image = styled.img`
  max-width: 224px
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 48px 0px 0px 0px;
  `}
`;


export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 8px 0px 0px 0px;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
    
    br {
      content: "";
      margin: ${theme.v2.font.sizes.xxs};
      display: block;
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    margin: 32px 0px 0px 0px;
    > div {
      padding: 0px;
      a {
        display: inline-block !important;
        font-weight: ${theme.v2.font.weights.medium};
      }
    }
  `}
`;

export const CheckBoxWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-top: 1px solid ${theme.v2.colors.neutral.highLight};
    display: flex;
    justify-content: center;
    margin: 24px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
  `}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px auto 0px;
`;


export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 2px;
  margin: 0px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;
