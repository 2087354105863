import {
  ACCESS_CPANEL_ARTICLE, ADD_DOMAIN_TO_HOST_ARTICLE, CLIENT_AREA_FEATURES_ARTICLE, CONFIGURE_DOMAIN_DNS_ARTICLE, CREATE_WORDPRESS_SITE_ARTICLE,
} from '@/config/urls/supportUrls';

export default {
  diagnostic: {
    helpLinks: [
      {
        title: 'Guia de início da sua hospedagem',
        link: CLIENT_AREA_FEATURES_ARTICLE,
      },
      {
        title: 'Como configurar o domínio (Alterar DNS)',
        link: CONFIGURE_DOMAIN_DNS_ARTICLE,
      },
      {
        title: 'Como adicionar um domínio na hospedagem',
        link: ADD_DOMAIN_TO_HOST_ARTICLE,
      },
      {
        title: 'Como criar um site em WordPress pelo Portal',
        link: CREATE_WORDPRESS_SITE_ARTICLE,
      },
      {
        title: 'Como acessar o cPanel',
        link: ACCESS_CPANEL_ARTICLE,
      },
    ],
  },
  zopimDepartments: {
    builder: 'hgbr_chat_construtor_de_sites',
    domain: 'hgbr_chat_dominios',
    financial: 'hgbr_chat_financeiro',
    mail: 'hgbr_chat_email',
    priority: 'priority',
    retention: 'hgbr_chat_retencao',
    shared: 'hgbr_chat_compartilhado',
    skilled: 'specialized',
    tos: 'hgbr_chat_abuse_tos',
  },
  resetMessages: ['Vou encerrar essa conversa por agora, mas a gente está sempre por aqui se precisar de mais alguma coisa. Em breve, você vai receber um e-mail com o histórico da nossa conversa'],
  chatFinished: 'Essa conversa foi finalizada. Se ainda precisar de ajuda, basta iniciar uma nova conversa.',
  startNewChat: 'Iniciar novo atendimento',
};
