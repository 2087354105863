import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/Button';
import { IconCheck } from '@/icons';
import * as Style from './PaymentSuccessModal.styles';

const PaymentSuccessModal = ({
  title = 'Compra realizada com sucesso',
  buttonLabel = 'FECHAR',
  onClose,
  onButtonClick,
}) => {
  const theme = useContext(ThemeContext);

  const handleOnButtonClick = () => {
    onButtonClick && onButtonClick();
  };

  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    <Modal onClose={handleOnClose} data-testid="payment-success-modal">
      <Style.Wrapper>
        <Style.Icon>
          <Style.IconCheck>
            <IconCheck size={24} color={theme.color.white} />
          </Style.IconCheck>
        </Style.Icon>
        <Style.Title>
          {title}
        </Style.Title>
        <Style.Button>
          <Button data-testid="modal-button-close" label={buttonLabel} onClick={handleOnButtonClick} />
        </Style.Button>
      </Style.Wrapper>
    </Modal>
  );
};

export default PaymentSuccessModal;
