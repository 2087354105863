import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    margin: -1px 24px -1px 24px;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: auto;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.v2.colors.brand.primaryUltraLight};
  height: auto;
  margin: 0px 24px 0px 24px;
  padding: 10px 44px;
  text-align: center;

  @media (max-width: ${theme.v2.breakpoints.sm}){
    margin: 0px;
    padding: 8px 20px;
    text-align: left;
    width: 100%;
  }

`}
`;

export const Description = styled.span`
  ${
  ({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    font-size: 14px;
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 229px;
      text-align:right;
      width: 100%;
    }
    span {
      font-weight: ${theme.v2.font.weights.bold};
    }
    a {
      font-size: 14px;
    }
  `}
`;
