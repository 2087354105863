import styled, { css } from 'styled-components';

export const OpenFloatingSurveyContainer = styled.div`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right:40px;
  position:relative;
  width: 285px;
`;

export const Header = styled.div`
${({ theme }) => css`
  align-items: center;
  background-color: ${theme.palette.grey.ultralight};
  display: flex;
  justify-content: space-between;
  padding: 0px 22px 0px 18px;
  button {
    padding: 0;
  }
`
}`;

export const Title = styled.p`
${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.md};
  font-weight: ${theme.font.weights.medium};
  margin-left: 10px;
`
}`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const CloseContainer = styled.div`
  display: flex;
`;

export const WelcomeContainer = styled.div`
${({ theme }) => css`
  background-color:${theme.color.white};
  display:flex;
  flex-direction: column;
`
}`;

export const Welcome = styled.p`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.xxs};
  line-height: ${theme.font.lineHeights.md};
  padding: 16px;
  margin: 0px;
  span{
    font-weight: ${theme.font.weights.medium};
  }
`
}`;

export const Explanation = styled.p`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.xxs};
  line-height: ${theme.font.lineHeights.md};
  padding: 0px 16px 16px 16px;
  margin: 0px;
  span{
    font-weight: ${theme.font.weights.medium};
  }
`
}`;

export const ButtonContainer = styled.div`
  align-self: end;
  margin: 8px 16px 24px 0px;
`;

export const SnappyImage = styled.img`
  bottom: 0px;
  display: flex;
  height: 38px;
  left: 17px;
  position:absolute;
  width:80px;
`;

export const FinishContainer = styled.div`
${({ theme }) => css`
  background-color:${theme.color.white};
  display:flex;
  flex-direction: column;
`
}`;

export const Congrats = styled.p`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.xxs};
  font-weight: ${theme.font.weights.medium};
  line-height: ${theme.font.lineHeights.md};
  padding: 16px;
  margin: 0px;
`
}`;

export const FinalText = styled.p`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.xxs};
  line-height: ${theme.font.lineHeights.md};
  padding: 0px 16px 16px 16px;
  margin: 0px;
`
}`;

export const QuestionsContainer = styled.div`
${({ theme }) => css`
  background-color:${theme.color.white};
  display:flex;
  flex-direction: column;
`
}`;

export const QuestionCounter = styled.p`
${({ theme }) => css`
  color:${theme.palette.grey.main};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.xxs};
  line-height: ${theme.font.lineHeights.md};
  padding: 16px 16px 4px 16px;
  margin: 0px;
`
}`;
