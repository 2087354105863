import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { IconDropdownArrow } from '@/icons';
import * as Styles from './Accordion.styles';

const Accordion = ({
  children,
  onToggleClick,
  title = 'title',
  testId = 'accordion',
  expanded = false,
  titleHighlightPattern = null,
  variant = 'default',
  icon,
  enabledAutoHeight = false,
  invoiceResume = null,
}) => {
  const [open, setOpen] = useState(expanded);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef();

  const getContentHeight = () => contentRef.current.scrollHeight;

  const defineMaxHeight = useCallback(() => {
    setMaxHeight(enabledAutoHeight ? 'auto' : `${getContentHeight()}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleClick = () => {
    onToggleClick && onToggleClick(!open);
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  useEffect(() => {
    open && defineMaxHeight();
  }, [defineMaxHeight, open]);

  return (
    <Styles.Wrapper data-testid={testId} variant={variant}>
      <Styles.Header onClick={handleOnToggleClick} variant={variant} data-testid={`${testId}-header`}>
        {!invoiceResume && (
          <Styles.ContentTitle>
            {icon && (
              <Styles.InsertedIcon>
                {icon}
              </Styles.InsertedIcon>
            )}
            {
              titleHighlightPattern ? (
                <Styles.Title
                  data-testid={`${testId}-title`}
                  dangerouslySetInnerHTML={{
                    __html: title.replace(new RegExp(`(${titleHighlightPattern})`), '<span class="highlight">$1</span>'),
                  }}
                />
              ) : (
                <Styles.Title
                  data-testid={`${testId}-title`}
                >
                  {title}
                </Styles.Title>
              )
            }
          </Styles.ContentTitle>
        )}
        {invoiceResume && (
          <>
            {invoiceResume}
          </>
        )}
        <Styles.Icon data-testid={`${testId}-icon`} open={open}>
          <IconDropdownArrow />
        </Styles.Icon>
      </Styles.Header>
      <Styles.Content ref={contentRef} maxHeight={maxHeight} open={open}>
        {variant !== 'clean' && (
          <Styles.InnerContent data-testid={`${testId}-inner-content`}>
            {children}
          </Styles.InnerContent>
        )}
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default Accordion;
