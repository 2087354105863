import React from 'react';
import * as Styles from './PersonFields.styles';
import { isBRServer } from '@/utils/Validators/validation';
import { COUNTRY } from '@/config';
import {
  IconCheck, Input, PhoneInput, Select,
} from 'gatorcomponents';

export const PersonFields = ({
  myDataLocale,
  isForeigner,
  handleFormChange,
  validateField,
  formData,
  countriesSelectOptions,
  setIsCompany,
  isCompany,
  newPhoneFlowEnabled,
  handlePhoneChange,
  newEmailFlowEnabled,
  handleEmailChange,
  knowledgeLevels,
  renderFlags,
  countryCode,
}) => (
  <>
    <Styles.Title>{myDataLocale.title}</Styles.Title>
    {isBRServer && (
      <Styles.Label htmlFor="#foreigner" onClick={() => handleFormChange('foreigner', !isForeigner)}>
        <Styles.CheckboxWrapper type="button">
          <Styles.CheckboxInput
            id="foreigner"
            type="checkbox"
            checked={isForeigner}
            data-testid="foreigner"
            readOnly
          />
          <Styles.Checkbox checked={isForeigner}>
            {isForeigner && <IconCheck />}
          </Styles.Checkbox>
        </Styles.CheckboxWrapper>
        {myDataLocale.form.foreignerLabel}
      </Styles.Label>
    )}

    {isBRServer && isForeigner && (
      <Styles.ForeignerWrapper id="ForeignerWrapper">
        <Styles.SelectWrapper half>
          <Select
            id="birthCountry"
            name="birthCountry"
            onClickSelect={fieldData => handleFormChange('birthCountry', fieldData.value)}
            label={myDataLocale.form.birthCountryLabel}
            value={formData.birthCountry.value.name}
            placeholder={myDataLocale.form.birthCountryPlaceholder}
            testId="birthCountry"
            options={countriesSelectOptions}
            withScroll
            onBlur={({ name, value }) => validateField(name, value)}
            errorMessage={formData.birthDate.error}
          />
        </Styles.SelectWrapper>
        <Input
          id="document"
          name="document"
          onChange={fieldData => handleFormChange('document', fieldData.currentTarget.value)}
          label={isForeigner ? myDataLocale.form.documentLabel : myDataLocale.form.cpfCnpjLabel}
          value={formData.document.value}
          placeholder={myDataLocale.form.documentPlaceholder}
          testId="document"
          onBlur={({ name, value }) => validateField(name, value)}
          mask={isForeigner ? undefined : 'document'}
          errorMessage={formData.document.error}
        />
      </Styles.ForeignerWrapper>
    )}
    {isBRServer && !isForeigner && (
      <Input
        id="document"
        name="document"
        onChange={fieldData => handleFormChange('document', fieldData.currentTarget.value)}
        label={isForeigner ? myDataLocale.form.documentLabel : myDataLocale.form.cpfCnpjLabel}
        value={formData.document.value}
        placeholder={myDataLocale.form.documentPlaceholder}
        testId="document"
        onBlur={({ name, value }) => validateField(name, value)}
        mask={isForeigner ? undefined : 'document'}
        errorMessage={formData.document.error}
      />
    )}
    {!isBRServer && (
      <Styles.Label htmlFor="#isCompany" onClick={() => setIsCompany(!isCompany)}>
        <Styles.CheckboxWrapper type="button">
          <Styles.CheckboxInput
            id="isCompany"
            type="checkbox"
            checked={isCompany}
            data-testid="isCompany"
            readOnly
          />
          <Styles.Checkbox checked={isCompany}>
            {isCompany && <IconCheck />}
          </Styles.Checkbox>
        </Styles.CheckboxWrapper>
        {myDataLocale.form.isCompanyLabel}
      </Styles.Label>
    )}
    {!isCompany && (
      <Input
        id="name"
        name="name"
        onChange={fieldData => handleFormChange('name', fieldData.currentTarget.value)}
        label={myDataLocale.form.nameLabel}
        value={formData.name.value}
        placeholder={myDataLocale.form.namePlaceholder}
        testId="name"
        onBlur={({ name, value }) => validateField(name, value)}
        errorMessage={formData.name.error}
        maxLength={isBRServer ? 40 : undefined}
      />
    )}
    {isCompany && (
      <Input
        id="companyName"
        name="companyName"
        onChange={fieldData => handleFormChange('companyName', fieldData.currentTarget.value)}
        label={myDataLocale.form.companyNameLabel}
        value={formData.companyName.value}
        placeholder={myDataLocale.form.companyNamePlaceholder}
        testId="companyName"
        onBlur={({ name, value }) => validateField(name, value)}
        errorMessage={formData.companyName.error}
        maxLength={isBRServer ? 50 : undefined}
      />
    )}
    <Styles.Row>
      {!isCompany && (
        <Styles.InputWrapper>
          <Input
            id="birthDate"
            name="birthDate"
            onChange={fieldData => handleFormChange('birthDate', fieldData.currentTarget.value)}
            label={myDataLocale.form.birthDateLabel}
            value={formData.birthDate.value}
            placeholder={myDataLocale.form.birthDatePlaceholder}
            testId="birthDate"
            onBlur={({ name, value }) => validateField(name, value)}
            mask="date"
            maxLength={10}
            errorMessage={formData.birthDate.error}
          />
        </Styles.InputWrapper>
      )}
      {isCompany && (
        <Styles.InputWrapper>
          <Input
            id="name"
            name="name"
            onChange={fieldData => handleFormChange('name', fieldData.currentTarget.value)}
            label={myDataLocale.form.companyResponsible}
            value={formData.name.value}
            placeholder={myDataLocale.form.companyResponsiblePlaceholder}
            testId="companyResponsible"
            onBlur={({ name, value }) => validateField(name, value)}
            errorMessage={formData.name.error}
            maxLength={isBRServer ? 40 : undefined}
          />
        </Styles.InputWrapper>
      )}
      {!newPhoneFlowEnabled && (
        <Styles.InputWrapper>
          <Input
            id="phone"
            name="phone"
            label={myDataLocale.form.phoneLabel}
            value={formData.phone.value}
            placeholder={myDataLocale.form.phonePlaceholder}
            testId="phone"
            icon={newPhoneFlowEnabled ? 'IconPencil' : undefined}
            onClickIcon={handlePhoneChange}
            readOnly={newPhoneFlowEnabled}
            allowIconClick
            mask="phone"
            country={COUNTRY}
            onChange={fieldData => !newPhoneFlowEnabled && handleFormChange('phone', fieldData.currentTarget.value)}
            onBlur={({ name, value }) => !newPhoneFlowEnabled && validateField(name, value)}
            errorMessage={formData.phone.error}
          />
        </Styles.InputWrapper>
      )}

      {newPhoneFlowEnabled && (
        <Styles.InputWrapper phoneInput>
          <PhoneInput
            name="phone"
            key="phone"
            id="phone"
            testId="phone"
            label={myDataLocale.form.phoneLabel}
            inputValue={formData.phone.value || ''}
            onChangeInput={fieldData => !newPhoneFlowEnabled && handleFormChange('phone', fieldData.currentTarget.value)}
            onBlur={({ name, value }) => !newPhoneFlowEnabled && validateField(name, value)}
            selected={countryCode}
            error={formData.phone.error}
            brand={COUNTRY}
            renderFlags={renderFlags}
            icon={newPhoneFlowEnabled ? 'IconPencil' : undefined}
            onClickIcon={handlePhoneChange}
            readOnly={newPhoneFlowEnabled}
            allowIconClick
          />
        </Styles.InputWrapper>
      )}
    </Styles.Row>
    <Input
      id="email"
      name="email"
      onChange={fieldData => !newEmailFlowEnabled && handleFormChange('email', fieldData.currentTarget.value)}
      label={myDataLocale.form.emailLabel}
      value={formData.email.value}
      placeholder={myDataLocale.form.emailPlaceholder}
      testId="email"
      icon={newEmailFlowEnabled ? 'IconPencil' : undefined}
      onClickIcon={handleEmailChange}
      readOnly={newEmailFlowEnabled}
      allowIconClick
      onBlur={({ name, value }) => !newPhoneFlowEnabled && validateField(name, value)}
      errorMessage={formData.email.error}
    />
    <Styles.SelectWrapper id="selectWrapper">
      <Select
        id="knowledge"
        name="knowledge"
        onClickSelect={fieldData => handleFormChange('knowledge', fieldData.value)}
        label={myDataLocale.form.knowledgeLabel}
        value={knowledgeLevels[formData.knowledge - 1] && knowledgeLevels[formData.knowledge - 1].label}
        placeholder={myDataLocale.form.knowledgePlaceholder}
        testId="knowledge"
        options={knowledgeLevels}
        withScroll
        clearValue={!knowledgeLevels[formData.knowledge - 1]}
      />
    </Styles.SelectWrapper>
  </>
);

export default PersonFields;
