import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: 'Meus Dados',
  ACTION: {
    EDIT_EMAIL: 'Alteração: E-mail',
    EDIT_PHONE: 'Alteração: Celular',
    EDIT_FOREIGN: 'Ação: Sou estrangeiro',
    EDIT_BIRTHCOUNTRY: 'Alteração: País de nascimento',
    EDIT_DOCUMENT: 'Alteração: CPF/CNPJ',
    EDIT_NAME: 'Alteração: Nome Completo',
    EDIT_BIRTHDATE: 'Alteração: Data Nascimento',
    EDIT_COUNTRY: 'Alteração: País de residência',
    EDIT_CEP: 'Alteração: CEP',
    EDIT_ADDRESSNUMBER: 'Alteração: Número ou Complemento',
    EDIT_MARKETING_AGREEMENT: 'Ação: Receber novidades por e-mail',
    FIND_MY_CEP: 'Ação: Não sei meu CEP',
    CANCEL_SUBMIT: 'Ação: Cancelar',
    SAVE_SUBMIT: 'Ação: Salvar',
    EDIT_KNOWLEDGE: 'Alteração: Nível de conhecimento',
    ACCESS_MYDATA_PAGE: 'Acessos a página Meus Dados',
  },
};

const accessMyDataPage = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.ACCESS_MYDATA_PAGE,
    gaEventLabel: '',
  });
};

const editPhone = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_PHONE,
    gaEventLabel: '',
  });
};

const editEmail = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_EMAIL,
    gaEventLabel: '',
  });
};

const editForeign = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_FOREIGN,
    gaEventLabel: '',
  });
};

const editBirthCountry = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_BIRTHCOUNTRY,
    gaEventLabel: '',
  });
};

const editDocument = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_DOCUMENT,
    gaEventLabel: '',
  });
};

const editName = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_NAME,
    gaEventLabel: '',
  });
};

const editBirthDate = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_BIRTHDATE,
    gaEventLabel: '',
  });
};

const editCountry = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_COUNTRY,
    gaEventLabel: '',
  });
};

const editCEP = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_CEP,
    gaEventLabel: '',
  });
};

const editAddressNumber = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_ADDRESSNUMBER,
    gaEventLabel: '',
  });
};

const editMarketingAgreement = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_MARKETING_AGREEMENT,
    gaEventLabel: '',
  });
};

const findMyCep = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.FIND_MY_CEP,
    gaEventLabel: '',
  });
};

const cancelSubmit = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.CANCEL_SUBMIT,
    gaEventLabel: '',
  });
};

const submitChange = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.SAVE_SUBMIT,
    gaEventLabel: '',
  });
};

const changeKnowledgeLevel = (level) => {
  let label;
  switch (level) {
    case 1:
      label = 'Iniciante';
      break;
    case 2:
      label = 'Intermediário';
      break;
    case 3:
      label = 'Técnico';
      break;
    default: break;
  }
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: EVENTS.ACTION.EDIT_KNOWLEDGE,
    gaEventLabel: label,
  });
};

const analytics = {
  editPhone,
  editEmail,
  editForeign,
  editBirthCountry,
  editDocument,
  editName,
  editBirthDate,
  editCountry,
  editCEP,
  editAddressNumber,
  editMarketingAgreement,
  findMyCep,
  cancelSubmit,
  submitChange,
  changeKnowledgeLevel,
  accessMyDataPage,
};

export default analytics;
