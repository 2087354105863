import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, TextField,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';

const TypeCAAValueField = ({
  t,
  classes, formData, onHandleChangeInput, isInvalidDomain,
}) => {
  const { loading } = useContext(DNSContext);

  return (
    <div className={classes.CnameBlockWrapper}>
      <TextField
        className={classes.textField}
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeCnameValueField')}
        name="cname"
        onChange={e => onHandleChangeInput(e.target)}
        required
        value={formData.cname}
        variant="outlined"
        InputProps={{
          inputProps: {
            maxLength: 255,
            'data-testid': 'zoneManagerCnameValueField',
          },
        }}
        error={isInvalidDomain}
        helperText={isInvalidDomain ? t('dns.zoneManager.addRecord.form.helperText.cnameValueField') : null}
      />
    </div>
  );
};

export default (withI18n()(withStyles(styles)(TypeCAAValueField)));
