import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
${({ theme, variant }) => {
    const variants = {
      default: {
        color: theme.palette.primary.main,
        padding: '0 24px',
      },
      yellow: {
        color: theme.palette.yellow.main,
        padding: '0 24px',
      },
      clean: {
        color: '',
        padding: '16px 16px 16px 16px',
      },
    };

    return css`
    border-left: ${variant === 'clean' ? '' : `5px solid ${variants[variant].color}`};
    box-shadow: ${variant === 'clean' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.25)' : `2px 2px 5px ${theme.shadows.grey.light}`};
    border-radius: ${variant === 'clean' ? '4px' : ''};

    box-sizing: border-box;
    padding: ${variants[variant].padding};
    width: 100 %; `;
  }}
`;

export const ContentTitle = styled.div`
    ${() => css`
    display:flex;
    `}
`;


export const Header = styled.header`
${({ variant }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${variant === 'clean' ? '40px' : '80px'};
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    height: ${variant === 'clean' ? 'auto' : ''};
    display: ${variant === 'clean' ? 'block' : 'flex'};
  }
`}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin: 0;
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.md};

    & .highlight {
      color: ${theme.palette.primary.main};
    }
  `}
`;

export const Icon = styled.div`
  ${({ open }) => css`
    transform: rotate(${open ? '0deg' : '180deg'});
    margin-left: 48px;
    transition: transform 200ms ease-out;
    @media (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
      text-align: center;
    }
  `}
`;

export const InsertedIcon = styled.div`
${() => css`
  margin-right: 18px;
`}
`;


export const Content = styled.div`
  ${({ maxHeight, open }) => css`
    overflow: hidden;
    height: auto;
    max-height: ${open ? `${maxHeight}` : 0};
    transition: max-height 200ms ease-out;
  `}
`;

export const InnerContent = styled.div`
  padding-bottom: 24px;
`;
