const styles = theme => ({
  wrapper: {
    margin: 24,
    marginTop: 0,
    [theme.breakpoints.up(960)]: {
    },
  },
  header: {
    textAlign: 'center',
    opacity: 1,
    color: theme.color.tundora,
  },
  headerTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    letterSpacing: '0.02px',
    marginTop: '28px',
  },
  headerLogo: {
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  headerSubtitle: {
    fontSize: '17px',
    letterSpacing: '0.02px',
    marginTop: '14px',
    marginRight: '24px',
    marginLeft: '24px',
  },
  paymentOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    marginLeft: '98px',
    marginRight: '98px',
    padding: '24px',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      margin: '16px 0px',
      padding: '24px 16px',
    },
  },
  listIcon: {
    width: '56px',
    marginRight: '14px',
    [theme.breakpoints.down(600)]: {
      marginRight: '6px',
    },
  },
  title: {
    font: 'normal normal bold 14px/19px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
  },
  subtitle: {
    font: 'normal normal regular 12px/16px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
  },
  itemList: {
    display: 'flex',
    flexDirection: 'column',
  },
  listCard: {
    display: 'flex',
    alignItems: 'center',
  },
  perks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    borderLeft: `1px solid ${theme.color.geyser}`,
    paddingLeft: '24px',
    minWidth: '50%',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      paddingLeft: '0px',
      borderLeft: '0px',
    },
  },
});

export default styles;
