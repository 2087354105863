import { locale } from '@/utils/locale';

export const titanAdvertisement = {
  title: locale('modalTitanAdvertisement.top.title'),
  description: locale('titanAdvertisement.top.description_0'),
  descriptionDomain: locale('titanAdvertisement.top.description_1'),
  labelLink: locale('titanAdvertisement.top.labelLink'),
  titleModal: locale('modalTitanAdvertisement.title'),
  selectDomainModal: locale('modalTitanAdvertisement.selectDomain'),
  buttonLabelModal: locale('modalTitanAdvertisement.button'),
  urlEmailList: locale('routes.emailsList'),
  pageHirePlanActiveCpanel: locale('routes.titanHirePlanActiveCPanel'),
};
