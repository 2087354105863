import React from 'react';
import * as Styles from './WizardWondersuiteTools.styles';
import { WizardWondersuiteToolCard } from '../WizardWondersuiteToolCard';

const WizardWondersuiteTools = ({
  title = 'title sample',
  tools = [
    {
      title: 'SiteBuilder',
      subtitle: 'SiteBuilder subtitle sample',
      items: [
        <>Item sample</>,
      ],
    },
  ],
  showMoreText,
  showLessText,
  onToolCardClick,
  sitesLoading,
  testId = 'wizard-wondersuite-tools',
}) => (
  <>
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title data-testid="title">
        {title}
      </Styles.Title>
      <Styles.CardsWrapper>
        {tools.map(tool => (
          <WizardWondersuiteToolCard
            tool={tool}
            showMoreText={showMoreText}
            showLessText={showLessText}
            key={tool.title}
            testId={tool.testId}
            onToolCardClick={onToolCardClick}
            sitesLoading={sitesLoading}
          />
        ))}
      </Styles.CardsWrapper>
    </Styles.Wrapper>
  </>
);

export default WizardWondersuiteTools;
