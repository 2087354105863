import React from 'react';
import { locale } from '@/utils/locale';

export const subscriptionsLocale = {
  searchPlaceholder: locale('subscriptions.searchPlaceholder'),
  clearLabel: locale('subscriptions.clearLabel'),
  activeStatusLabel: locale('subscriptions.activeStatusLabel'),
  cancelledStatusLabel: locale('subscriptions.cancelledStatusLabel'),
  pendingStatusLabel: locale('subscriptions.pendingStatusLabel'),
  pendingPaymentStatusLabel: locale('subscriptions.pendingPaymentStatusLabel'),
  pendingAnalysisStatusLabel: locale('subscriptions.pendingAnalysisStatusLabel'),
  suspendedStatusLabel: locale('subscriptions.suspendedStatusLabel'),
  toDueStatusLabel: locale('subscriptions.toDueStatusLabel'),
  overdueStatusLabel: locale('subscriptions.overdueStatusLabel'),
  expiredStatusLabel: locale('subscriptions.expiredStatusLabel'),
  registeredStatusLabel: locale('subscriptions.registeredStatusLabel'),
  transferredAwayStatusLabel: locale('subscriptions.transferredAwayStatusLabel'),
  listTitle: locale('subscriptions.listTitle'),
  tabInvoicesLabel: locale('billing.tabInvoicesLabel'),
  tabSubscriptionsLabel: locale('billing.tabSubscriptionsLabel'),
  buttonManageSubscriptionLabel: locale('subscriptions.buttonManageSubscriptionLabel'),
  manageSubscriptionLabel: locale('subscriptions.manageSubscriptionLabel'),
  searchPlaceholderNewLayout: locale('subscriptions.searchPlaceholderNewLayout'),
  filterLabel: locale('billing.filterLabel'),
  filterSubscriptionsLabel: locale('billing.filterSubscriptionsLabel'),
  filterStatusLabel: locale('billing.filterStatusLabel'),
  domainLabel: locale('billing.domainLabel'),
  routeInvoices: locale('routes.invoices'),
  routeSubscriptions: locale('routes.subscriptions'),
  routeBillingHandler: locale('routes.newBilling'),
  routeUnpaid: locale('routes.unpaid'),
  routePaid: locale('routes.paid'),
  routeCancelled: locale('routes.cancelled'),
  modal: {
    title: locale('billing.modal.title'),
    firstLinecontent: locale('billing.modal.firstLinecontent'),
    secondLinecontent: locale('billing.modal.secondLinecontent'),
    activateRenew: locale('billing.modal.activateRenew'),
    close: locale('billing.modal.close'),
  },
};

export const subscriptionLocale = {
  modalActiveRenewalTitle: locale('subscriptions.modal.activateRenew.title'),
  modalActiveRenewalContent: locale('subscriptions.modal.activateRenew.content'),
  modalActiveRenewalButtonLabel: locale('subscriptions.modal.activateRenew.buttonRenewalLabel'),
};

export const routesLocale = {
  academy: locale('routes.coursesCta'),
};

export const urlsLocale = {
  domain: locale('subscriptions.urls.domain'),
};

export const messagesLocale = {
  pendingPayment: () => locale('subscriptions.message.pendingPayment'),
  pendingAnalysis: () => locale('subscriptions.message.pendingAnalysis'),
  toDue: ({
    invoicePaymentMethod,
    dueDate,
    invoiceUrl,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.toDue.0')}
      <strong>
        {`${locale('subscriptions.message.toDue.1')} ${dueDate}`}
      </strong>
      {invoicePaymentMethod === 'ticket'
        ? (
          <>
            {locale('subscriptions.message.toDue.ticket.0')}
            <a
              href={invoiceUrl}
              onClick={onGoToInvoicesClick}
            >
              {locale('subscriptions.message.toDue.ticket.1')}
            </a>
            {locale('subscriptions.message.toDue.ticket.2')}
          </>
        )
        : locale(`subscriptions.message.toDue.${invoicePaymentMethod}.0`)}
    </p>
  ),
  toDueWithTypeCancel: ({
    dueDate,
    invoiceUrl,
    onActiveRenewalClick,
  }) => (
    <p>
      {locale('subscriptions.message.active.withCancelDate.0')}
      <strong>
        {`${locale('subscriptions.message.active.withCancelDate.1')} ${dueDate}.`}
      </strong>
      <a
        href={invoiceUrl}
        onClick={onActiveRenewalClick}
      >
        {locale('subscriptions.message.active.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.active.withCancelDate.3')}
    </p>
  ),
  overdue: ({
    dueDate,
    daysToExpiration,
    singularText,
    invoiceUrl,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.overdue.0')}
      <strong>
        {`${locale('subscriptions.message.overdue.1')} ${dueDate}`}
      </strong>
      {`${locale('subscriptions.message.overdue.2')}`}
      <strong>
        {`${locale('subscriptions.message.overdue.3')} ${daysToExpiration}  ${locale(`subscriptions.message.overdue.4${singularText}`)}`}
      </strong>
      <a
        href={invoiceUrl}
        onClick={onGoToInvoicesClick}
      >
        {locale('subscriptions.message.overdue.5')}
      </a>
    </p>
  ),
  overdueAndIsMoreThan10DaysLate: ({
    dueDate,
  }) => (
    <p>
      {locale('subscriptions.message.offAfterExpiration.0')}
      <strong>
        {`${locale('subscriptions.message.offAfterExpiration.1')} ${dueDate}`}
      </strong>
      {`${locale('subscriptions.message.offAfterExpiration.2')}`}
    </p>
  ),
  suspended: ({
    chatSupportUrl,
  }) => (
    <p>
      {locale('subscriptions.message.suspendedWithoutDate.0')}
      <strong>{locale('subscriptions.message.suspendedWithoutDate.1')}</strong>
      {locale('subscriptions.message.suspendedWithoutDate.2')}
      <a href={chatSupportUrl}>
        {locale('subscriptions.message.suspendedWithoutDate.3')}
      </a>
      {locale('subscriptions.message.suspendedWithoutDate.4')}
    </p>
  ),
  suspendedWithDateSuspended: ({
    dateSuspended,
    invoiceUrl,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.suspended.0')}
      <strong>
        {`${locale('subscriptions.message.suspended.1')} ${dateSuspended}`}
      </strong>
      {`${locale('subscriptions.message.suspended.2')}`}
      <strong>
        {locale('subscriptions.message.suspended.3')}
      </strong>
      <a
        href={invoiceUrl}
        onClick={onGoToInvoicesClick}
      >
        {locale('subscriptions.message.suspended.4')}
      </a>
      {locale('subscriptions.message.suspended.5')}
    </p>
  ),
  cancelled: ({
    cancelDate,
    country,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.default.0')}
      <strong>
        {locale('subscriptions.message.cancelled.default.1', {
          cancelDate,
        })}
      </strong>
      {locale('subscriptions.message.cancelled.default.2')}
      <strong>
        {locale('subscriptions.supportEmail', { country })}
      </strong>
      {locale('subscriptions.message.cancelled.default.3')}
    </p>
  ),
  cancelledWithStatusFraud: ({
    country,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.fraud.0')}
      <strong>{locale('subscriptions.message.cancelled.fraud.1')}</strong>
      {locale('subscriptions.message.cancelled.fraud.2')}
      <strong>{locale('subscriptions.supportEmail', { country })}</strong>
      {locale('subscriptions.message.cancelled.fraud.3')}
    </p>
  ),
  cancelledWithoutDateCancel: ({
    onGoToClick,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.withoutDate.0')}
      <strong>{locale('subscriptions.message.cancelled.withoutDate.1')}</strong>
      {locale('subscriptions.message.cancelled.withoutDate.2')}
      <a
        href={siteUrl}
        onClick={onGoToClick}
        title={locale('subscriptions.message.cancelled.withoutDate.3')}
      >
        {locale('subscriptions.message.cancelled.withoutDate.3')}
      </a>
    </p>
  ),
  cancelledWithCancelTypeUserRequest: ({
    cancelDate,
    onGoToClick,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.user_request.0')}
      <strong>
        {locale('subscriptions.message.cancelled.user_request.1', {
          cancelDate,
        })}
      </strong>
      {'. '}
      <a
        href={siteUrl}
        onClick={onGoToClick}
        title={locale('subscriptions.message.cancelled.user_request.2')}
      >
        {locale('subscriptions.message.cancelled.user_request.2')}
      </a>
    </p>
  ),
  cancelledWithCancelTypeWhmcsSuspension: ({
    cancelDate,
    onGoToClick,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.whmcs_suspension.0')}
      <strong>
        {locale('subscriptions.message.cancelled.whmcs_suspension.1', { cancelDate })}
      </strong>
      {` ${locale('subscriptions.message.cancelled.whmcs_suspension.2')}`}
      <a
        href={siteUrl}
        onClick={onGoToClick}
        title={locale('subscriptions.message.cancelled.whmcs_suspension.3')}
      >
        {locale('subscriptions.message.cancelled.whmcs_suspension.3')}
      </a>
    </p>
  ),
  activeWithCancelType: ({
    cancelDate,
    onActiveRenewalClick,
  }) => (
    <p>
      {locale('subscriptions.message.active.withCancelDate.0')}
      <strong>
        {`${locale('subscriptions.message.active.withCancelDate.1')} ${cancelDate}.`}
      </strong>
      <a
        href={locale('subscriptions.message.active.withCancelDate.2')}
        onClick={onActiveRenewalClick}
      >
        {locale('subscriptions.message.active.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.active.withCancelDate.3')}
    </p>
  ),
  registeredWithCancelTypeUserRequest: ({
    cancelDate,
    onActiveRenewalClick,
  }) => (
    <p>
      {locale('subscriptions.message.domain.registered.withCancelDate.0')}
      <strong>{`${locale('subscriptions.message.domain.registered.withCancelDate.1')} ${cancelDate}.`}</strong>
      <a
        href={locale('subscriptions.message.domain.registered.withCancelDate.2')}
        onClick={onActiveRenewalClick}
      >
        {locale('subscriptions.message.domain.registered.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.domain.registered.withCancelDate.3')}
    </p>
  ),
  domainOverdue: ({
    daysToExpiration,
    dueDate,
    invoiceUrl,
    singularText,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.domain.overdue.0')}
      <strong>{`${locale('subscriptions.message.domain.overdue.1')} ${dueDate}`}</strong>
      {`${locale('subscriptions.message.domain.overdue.2')} ${daysToExpiration} ${locale(`subscriptions.message.domain.overdue.3${singularText}`)}`}
      <a
        href={invoiceUrl}
        onClick={onGoToInvoicesClick}
      >
        {locale('subscriptions.message.domain.overdue.4')}
      </a>
    </p>
  ),
  domainExpired: ({
    dueDate,
    invoiceUrl,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.domain.expired.0')}
      <strong>{`${locale('subscriptions.message.domain.expired.1')} ${dueDate}`}</strong>
      {locale('subscriptions.message.domain.expired.2')}
      <a
        href={invoiceUrl}
        onClick={onGoToInvoicesClick}
      >
        {locale('subscriptions.message.domain.expired.3')}
      </a>
    </p>
  ),
  domainCancelledForNonPayment: ({
    cancelDate,
    onGoToClick,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.domain.cancelled.forNonPayment.0')}
      <strong>{`${locale('subscriptions.message.domain.cancelled.forNonPayment.1')} ${cancelDate}`}</strong>
      {locale('subscriptions.message.domain.cancelled.forNonPayment.2')}
      <a
        href={siteUrl}
        onClick={onGoToClick}
        title={locale('subscriptions.message.domain.cancelled.forNonPayment.3')}
      >
        {locale('subscriptions.message.domain.cancelled.forNonPayment.3')}
      </a>
    </p>
  ),
  domainCancelledByClient: ({
    cancelDate,
    onGoToClick,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.domain.cancelled.byClient.0')}
      <strong>{`${locale('subscriptions.message.domain.cancelled.byClient.1')} ${cancelDate}`}</strong>
      <a
        href={siteUrl}
        onClick={onGoToClick}
        title={locale('subscriptions.message.domain.cancelled.byClient.2')}
      >
        {locale('subscriptions.message.domain.cancelled.byClient.2')}
      </a>
    </p>
  ),
  transferredAway: () => (
    <p>
      {locale('subscriptions.message.domain.transferredAway')}
    </p>
  ),
  domainCancelledByFraud: ({
    country,
  }) => (
    <p>
      <strong>{`${locale('subscriptions.message.domain.cancelled.byFraud.0')}`}</strong>
      {locale('subscriptions.message.domain.cancelled.byFraud.1')}
      <a
        href={country === 'br' ? 'mailto:analise@hostgator.com.br' : 'https://soporte-latam.hostgator.com/hc/es-419/requests/new'}
        title={locale('subscriptions.message.domain.cancelled.byFraud.2')}
      >
        {locale('subscriptions.message.domain.cancelled.byFraud.2')}
      </a>
      {locale('subscriptions.message.domain.cancelled.byFraud.3')}
    </p>
  ),
  addonWithDateSuspended: ({
    dateSuspended,
    invoiceUrl,
    onGoToInvoicesClick,
  }) => (
    <p>
      {locale('subscriptions.message.addon.withDateSuspended.0')}
      <strong>
        {`${locale('subscriptions.message.addon.withDateSuspended.1')} ${dateSuspended}. `}
      </strong>
      <a
        href={invoiceUrl}
        onClick={onGoToInvoicesClick}
      >
        {locale('subscriptions.message.addon.withDateSuspended.2')}
      </a>
      {locale('subscriptions.message.addon.withDateSuspended.3')}
    </p>
  ),
  addonWithoutDateSuspended: ({
    chatSupportUrl,
  }) => (
    <p>
      {locale('subscriptions.message.addon.withoutDateSuspended.0')}
      <strong>{locale('subscriptions.message.addon.withoutDateSuspended.1')}</strong>
      {locale('subscriptions.message.addon.withoutDateSuspended.2')}
      <a href={chatSupportUrl}>
        {locale('subscriptions.message.addon.withoutDateSuspended.3')}
      </a>
      {locale('subscriptions.message.addon.withoutDateSuspended.4')}
    </p>
  ),
};
