const styles = theme => ({
  dragArea: {
    paddingTop: '10px',
  },
  dialog: {
    width: 'auto',
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.color.tundora,
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    objectFit: 'contain',
  },
});

export default styles;
