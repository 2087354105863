import React, { useState } from 'react';
import useInterval from '@/hooks/useInterval';

const OldCountdown = ({ countdownTime, handleShowCountdown }) => {
  const [count, setCount] = useState(countdownTime);

  useInterval(() => {
    if (count > 0) {
      setCount(count - 1);
    } else {
      handleShowCountdown(false);
    }
  }, 1000);

  function format(count) {
    let seconds = count % 60;
    let minutes = Math.floor(count / 60);
    minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}s`;
  }

  return (
    <>
      {format(count)}
    </>
  );
};

export default OldCountdown;
