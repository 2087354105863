import rules from './useSubscriptionsMessage.rules';

const useSubscriptionsMessage = (subscriptionList = [], deps = {}) => subscriptionList.map((subscription) => {
  const { type, status, substatus } = subscription;

  const property = `${status.toLowerCase()}/${substatus.toLowerCase()}`;

  const message = rules[type][property](subscription, deps);
  const subscriptionDecoratedWithMessage = {
    ...subscription,
    message,
  };
  return subscriptionDecoratedWithMessage;
});

export default useSubscriptionsMessage;
