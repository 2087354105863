import styled, { css } from 'styled-components';

export const DnsWizardPlanCardWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.boxes};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.line.wizard};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 24px;
    gap: 16px;
    position: relative;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      padding: 24px 24px 60px 24px;
    }
  `}
`;

export const Image = styled.img`
  width: fit-content;
`;

export const ChangePlatform = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.action.primary};
    cursor: pointer;
    display: initial;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.medium};
    text-align: right;

    &:hover {
      color: ${theme.v1.color.action.hover};
    }

    @media (max-width: ${theme.v1.breakpoints.sm}){
      bottom: 24px;
      position: absolute;
      right: 24px;
    }

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      margin-left: auto;
    }
  `}
`;

export const ChangePlanWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    max-width: 100%;
    
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      align-items:center;
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
    div {
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
    }

    > div {
      max-width: 880px;
      padding: 0px;

      svg {
        height: 31px;
        margin-top: -4px !important;
        width: 31px;
      }

      a {
        color: ${theme.v1.color.action.primary};
      }

      div:last-child{
        margin-left: 5px;
      }
    }
  `}
`;

export const OtherCard = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.medium};

    img {
      margin: 0px 8px 0px 0px;
    }
  `}
`;

export const SelectedPlatformName = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.medium};
    margin: 0 0 0 12px;;
  `}
`;
