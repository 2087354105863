export const AsaasActionTypes = {
  // LOADING
  SET_LOADING: 'ASAAS_SET_LOADING',

  // SUBACCOUNT
  ASAAS_CREATE_SUBACCOUNT_REQUEST: 'ASAAS_CREATE_SUBACCOUNT_REQUEST',
  ASAAS_CREATE_SUBACCOUNT_FAILURE: 'ASAAS_CREATE_SUBACCOUNT_FAILURE',
  ASAAS_CREATE_SUBACCOUNT_SUCCESS: 'ASAAS_CREATE_SUBACCOUNT_SUCCESS',
  ASAAS_SET_ACCOUNT_STATUS: 'ASAAS_SET_ACCOUNT_STATUS',
};
