import asaasBR from './asaas.br';
import asaasMX from './asaas.mx';
import asaasCL from './asaas.cl';
import asaasCO from './asaas.co';

const asaas = {
  BR: asaasBR,
  MX: asaasMX,
  CL: asaasCL,
  CO: asaasCO,
};

export default asaas;
