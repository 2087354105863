import React, { useState } from 'react';
import { ClosedFloatingSurvey } from './ClosedFloatingSurvey';
import { OpenFloatingSurvey } from './OpenFloatingSurvey';

const FloatingSurvey = ({
  survey = {},
  questionsAnswered = { ids: [], answers: {} },
  shouldRenderOpened,
  setQuestionsAnswered,
  onFinishSurvey,
  dispatch,
  handleStartSurvey,
  handleClose,
  handleJumpQuestion,
}) => {
  const [isOpen, setIsOpen] = useState(shouldRenderOpened);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (isOpen
    ? (
      <OpenFloatingSurvey
        dispatch={dispatch}
        handleStartSurvey={handleStartSurvey}
        onFinishSurvey={onFinishSurvey}
        setQuestionsAnswered={setQuestionsAnswered}
        questionsAnswered={questionsAnswered}
        toggleOpen={toggleOpen}
        survey={survey}
        handleClose={handleClose}
        handleJump={handleJumpQuestion}
      />
    )
    : (<ClosedFloatingSurvey toggleOpen={toggleOpen} survey={survey} />)
  );
};

export default FloatingSurvey;
