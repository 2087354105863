import React from 'react';
import { locale } from '@/utils/locale';

export const AcademyPassTrialHandlerLocale = {
  titleCard: locale('academyPassTrial.titleCard'),
  title: locale('academyPassTrial.title'),
  descriptionText: locale('academyPassTrial.descriptionText'),
  descriptionTextBold: locale('academyPassTrial.descriptionTextBold'),
  button: locale('academyPassTrial.button'),
  footer: locale('academyPassTrial.footer'),
  routes: {
    billing: locale('routes.billing'),
    unpaid: locale('routes.unpaid'),
  },
  middleSectionDust: {
    title: locale('academyPassTrial.middleSectionDust.0'),
    button: locale('academyPassTrial.middleSectionDust.button'),
    paragraph: (
      <>
        {locale('academyPassTrial.middleSectionDust.1')}
        <strong>{locale('academyPassTrial.middleSectionDust.2')}</strong>
        {locale('academyPassTrial.middleSectionDust.3')}
      </>
    ),
  },
  middleSectionKnowledge: {
    title: locale('academyPassTrial.middleSectionKnowledge.0'),
    button: locale('academyPassTrial.middleSectionKnowledge.button'),
    paragraph: (
      <>
        {locale('academyPassTrial.middleSectionKnowledge.1')}
        <strong>{locale('academyPassTrial.middleSectionKnowledge.2')}</strong>
        {locale('academyPassTrial.middleSectionKnowledge.3')}
      </>
    ),
  },
  middleSectionSaveMoney: {
    title: locale('academyPassTrial.middleSectionSaveMoney.0'),
    button: locale('academyPassTrial.middleSectionSaveMoney.button'),
    paragraph: (
      <>
        {locale('academyPassTrial.middleSectionSaveMoney.1')}
        <strong>{locale('academyPassTrial.middleSectionSaveMoney.2')}</strong>
        {locale('academyPassTrial.middleSectionSaveMoney.3')}
      </>
    ),
  },
  middleSectionVideo: {
    title: locale('academyPassTrial.middleSectionVideo.title'),
    descInfOne: locale('academyPassTrial.middleSectionVideo.descInfOne'),
    descInfTwo: locale('academyPassTrial.middleSectionVideo.descInfTwo'),
    descInfThree: locale('academyPassTrial.middleSectionVideo.descInfThree'),
    linkVideo: locale('academyPassTrial.middleSectionVideo.linkVideo'),
    button: locale('academyPassTrial.middleSectionVideo.button'),
  },
  middleSectionCards: {
    title: locale('academyPassTrial.middleSectionCards.title'),
    textCardOne: locale('academyPassTrial.middleSectionCards.textCardOne'),
    autorCardOne: (
      <strong>
        {locale('academyPassTrial.middleSectionCards.autorCardOne')}
      </strong>
    ),
    footerCardOne: locale('academyPassTrial.middleSectionCards.footerCardOne'),

    textCardTwo: locale('academyPassTrial.middleSectionCards.textCardTwo'),
    autorCardTwo: (
      <strong>
        {locale('academyPassTrial.middleSectionCards.autorCardTwo')}
      </strong>
    ),
    footerCardTwo: locale('academyPassTrial.middleSectionCards.footerCardTwo'),

    textCardThree: locale('academyPassTrial.middleSectionCards.textCardThree'),
    autorCardThree: (
      <strong>
        {locale('academyPassTrial.middleSectionCards.autorCardThree')}
      </strong>
    ),
    footerCardThree: locale(
      'academyPassTrial.middleSectionCards.footerCardThree',
    ),
  },
  middleSectionFooter: {
    title: locale('academyPassTrial.middleSectionFooter.title'),
    textFooter: (
      <>
        {locale('academyPassTrial.middleSectionFooter.textFooter.0')}
        <strong>
          {locale('academyPassTrial.middleSectionFooter.textFooter.1')}
        </strong>
        {locale('academyPassTrial.middleSectionFooter.textFooter.2')}
      </>
    ),
    btnFooter: locale('academyPassTrial.middleSectionFooter.btnFooter'),
  },
};
