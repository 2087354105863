import { locale } from '@/utils/locale';
import React from 'react';

export const useCostumerPortalSurveyLocale = {

  title: locale('costumerPortalSurvey.title'),
  welcome: (
    <>
      {locale('costumerPortalSurvey.welcome.0')}
      {<span>{locale('costumerPortalSurvey.welcome.1')}</span>}
    </>
  ),
  explanation: (
    <>
      {locale('costumerPortalSurvey.explanation.0')}
      {<span>{locale('costumerPortalSurvey.explanation.1')}</span>}
      {locale('costumerPortalSurvey.explanation.2')}
    </>
  ),
  start: locale('costumerPortalSurvey.start'),
  jump: locale('costumerPortalSurvey.jump'),
  send: locale('costumerPortalSurvey.send'),
  selectPlaceholder: locale('costumerPortalSurvey.selectPlaceholder'),
  otherInputPlaceholder: locale('costumerPortalSurvey.otherInputPlaceholder'),
  congrats: locale('costumerPortalSurvey.congrats'),
  finalText: locale('costumerPortalSurvey.finalText'),
  finishLabel: locale('costumerPortalSurvey.finishLabel'),
  counterLabel: locale('costumerPortalSurvey.counterLabel'),
  questions: {
    question1: {
      label: locale('costumerPortalSurvey.questions.question-1.label'),
      options: {
        op1: locale('costumerPortalSurvey.questions.question-1.options.1'),
        op2: locale('costumerPortalSurvey.questions.question-1.options.2'),
        op3: locale('costumerPortalSurvey.questions.question-1.options.3'),
      },
    },
    question2: {
      label: locale('costumerPortalSurvey.questions.question-2.label'),
      options: {
        op1: locale('costumerPortalSurvey.questions.question-2.options.1'),
        op2: locale('costumerPortalSurvey.questions.question-2.options.2'),
        op3: locale('costumerPortalSurvey.questions.question-2.options.3'),
        op4: locale('costumerPortalSurvey.questions.question-2.options.4'),
        op5: locale('costumerPortalSurvey.questions.question-2.options.5'),
      },
    },
    question3: {
      label: locale('costumerPortalSurvey.questions.question-3.label'),
      options: {
        op1: locale('costumerPortalSurvey.questions.question-3.options.1'),
        op2: locale('costumerPortalSurvey.questions.question-3.options.2'),
        op3: locale('costumerPortalSurvey.questions.question-3.options.3'),
        op4: locale('costumerPortalSurvey.questions.question-3.options.4'),
        op5: locale('costumerPortalSurvey.questions.question-3.options.5'),
        op6: locale('costumerPortalSurvey.questions.question-3.options.6'),
        op7: locale('costumerPortalSurvey.questions.question-3.options.7'),
        op8: locale('costumerPortalSurvey.questions.question-3.options.8'),
        op9: locale('costumerPortalSurvey.questions.question-3.options.9'),
        op10: locale('costumerPortalSurvey.questions.question-3.options.10'),
        op11: locale('costumerPortalSurvey.questions.question-3.options.11'),
        op12: locale('costumerPortalSurvey.questions.question-3.options.12'),
        op13: locale('costumerPortalSurvey.questions.question-3.options.13'),
        op14: locale('costumerPortalSurvey.questions.question-3.options.14'),
        op15: locale('costumerPortalSurvey.questions.question-3.options.15'),
        op16: locale('costumerPortalSurvey.questions.question-3.options.16'),
        op17: locale('costumerPortalSurvey.questions.question-3.options.17'),
        op18: locale('costumerPortalSurvey.questions.question-3.options.18'),
        op19: locale('costumerPortalSurvey.questions.question-3.options.19'),
        op20: locale('costumerPortalSurvey.questions.question-3.options.20'),
        op21: locale('costumerPortalSurvey.questions.question-3.options.21'),
      },
    },
    question4: {
      label: locale('costumerPortalSurvey.questions.question-4.label'),
      options: {
        op1: locale('costumerPortalSurvey.questions.question-4.options.1'),
        op2: locale('costumerPortalSurvey.questions.question-4.options.2'),
        op3: locale('costumerPortalSurvey.questions.question-4.options.3'),
        op4: locale('costumerPortalSurvey.questions.question-4.options.4'),
      },
    },
  },
};
