import React from 'react';
import ProductsContextProvider from '@/contexts/Products/context';
import ProductPageContent from './ProductPageContent';

const ProductsPage = () => (
  <div data-testid="contentWrapper">
    <ProductsContextProvider>
      <ProductPageContent />
    </ProductsContextProvider>
  </div>
);

export default ProductsPage;
