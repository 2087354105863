import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import Loader from '@/components/Layout/Loader';
import DomainChoice from '@/components/Dns/DomainChoice';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import { loadDnsDomainList, loadDnsDomainListExternal } from '@/redux/actions/dnsForward';
import DNSContextProvider from '@/contexts/DNS/context';
import styles from './styles';

const Dns = ({ classes, t }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.dnsList.loading);
  const [domainId, setDomainId] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadDnsDomainList()).then(() => {
      setDomainId(id);
    });
    dispatch(loadDnsDomainListExternal());
  }, [dispatch, id]);

  return (
    <ContentContainer className={classes.container} dataId="change-dns-page">
      {(!loading && domainId)
        ? (
          <DNSContextProvider t={t}>
            <DomainChoice domainId={domainId} />
          </DNSContextProvider>
        )
        : (
          <Loader data-testid="pageLoader" />
        )
      }
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles, { withTheme: true })((Dns)));
