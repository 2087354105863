import React from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './ReinforcedModal.styles';
import { DnsWizardBannerHosting } from '@/components/domain/components/DnsWizardBannerHosting';
import PropTypes from 'prop-types';

const ReinforcedModal = ({
  handleClose,
  title,
  dnsWizardBannerHostingProps,
}) => {
  const handleOnClose = () => {
    handleClose && handleClose(false);
  };

  return (
    <Modal
      maxWidth={880}
      disablePadding
      onClose={handleOnClose}
    >
      <Styles.ModalWrapper>
        <Styles.ModalTitle>
          {title}
        </Styles.ModalTitle>

        <DnsWizardBannerHosting {...dnsWizardBannerHostingProps()} />
      </Styles.ModalWrapper>
    </Modal>
  );
};

ReinforcedModal.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.string,
  dnsWizardBannerHostingProps: PropTypes.func,
};

export default ReinforcedModal;
