import styled, { css } from 'styled-components';


export const LabelError = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
    font-family: ${theme.font.family.secondary};
    font-size: 0.75rem;
    margin: 8px 12px 0;
    text-align: left;
    verflow-wrap: break-word;
  `}
`;

export const Wrapper = styled.span`
${({ maxWidth }) => css`
    max-width:${maxWidth};
 `}
`;

export const ButtonPassword = styled.button`
${() => css`
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    cursor: pointer;
    padding-right: 0px;

    span{
      padding-right:0px;
    }

 `}
`;

export const WrapperInput = styled.div`
${({ theme, typeValue, variant }) => {
    const variants = {
      default: {
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '0px',
      },
      textWithBorder: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '4px',
      },
      marketing: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '4px',
      },
    };

    const types = {
      textarea: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '4px',
      },
      text: {
        borderStyle: variants[variant].borderStyle,
        borderWidth: variants[variant].borderWidth,
        borderColor: variants[variant].borderColor,
        borderRadius: variants[variant].borderRadius,
      },
      password: {
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '0px',
      },
      email: {
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: theme.v1.color.line.fields,
        borderRadius: '0px',
      },
    };

    return css`
      background: ${theme.palette.white.light} 0% 0% no-repeat padding-box;
      border-radius: ${types[typeValue].borderRadius};
      border-style: ${types[typeValue].borderStyle};
      border-width: ${types[typeValue].borderWidth};
      border-color: ${types[typeValue].borderColor};
      display: flex;
`;
  }}
`;

export const Input = styled.input`
  ${({ theme, textAlign, variant }) => {
    const variants = {
      default: {
        padding: '11px',
        fontSize: '16px',
        placeholderColor: theme.v1.color.text.label,
      },
      textWithBorder: {
        fontSize: '16px',
        padding: '11px',
        placeholderColor: theme.v1.color.text.primary,
      },
      marketing: {
        padding: '16px',
        fontSize: '14px',
        placeholderColor: theme.v1.color.text.primary,
      },
    };

    return css`
    background-color: transparent;
    border:none;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${variants[variant].fontSize};
    font-weight: ${theme.v1.font.weights.regular};
    outline: 0 none;
    padding: ${variants[variant].padding};
    resize: none;
    text-align: ${textAlign};
    width: 100%;

    ::placeholder {
      color: ${variants[variant].placeholderColor};
      font-family: ${theme.v1.font.family.primary};
      letter-spacing: 0px;
      opacity: 1;
      text-align: left;
    }
  `;
  }}
`;

export const TextArea = styled.textarea`
  ${({ theme, textAlign }) => css`
    border:none;
    background-color: transparent;
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    outline: 0 none;
    padding: 11px;
    resize: none;
    text-align:${textAlign};
    width: 100%;

    ::placeholder {
      color: ${theme.palette.grey.main};
      font-family: ${theme.font.family.secondary};
      letter-spacing: 0px;
      opacity: 1;
      text-align: left;
    }
  `}
`;

export const Counter = styled.span`
${({ theme, typeValue }) => {
    const variants = {
      textarea: {
        position: 'absolute',
        padding: '0px',
        right: '15px',
        bottom: '10px',
      },
      text: {
        position: 'inherit',
        padding: '15px 5px 0px 0px',
        right: '15px',
        bottom: '10px',
      },
      password: {
        position: 'inherit',
        padding: '15px 5px 0px 0px',
        right: '15px',
        bottom: '10px',
      },
      email: {
        position: 'inherit',
        padding: '15px 5px 0px 0px',
        right: '15px',
        bottom: '10px',
      },
    };

    return css`
      color: ${theme.v1.color.text.label};
      font-size: ${theme.font.sizes.xxs};
      font-family: ${theme.font.family.secondary};
      padding: ${variants[typeValue].padding};
      position: ${variants[typeValue].position};
      right: ${variants[typeValue].right};
      bottom: ${variants[typeValue].bottom};
    `;
  }}
`;
