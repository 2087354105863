import styled, { css } from 'styled-components';
import Bg from '@/media/siteBuilder/woondersuite/features-background.svg';
import BgMobile from '@/media/siteBuilder/woondersuite/features-background-mobile.svg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px 20px 88px 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0 45px 80px;

      .slick-prev {
        position: absolute;
        top: 37px;
      }

      .slick-next {
        position: absolute;
        top: 37px;
      }
    }
  `}

  &::before {
    content: '';
    background-image: url(${Bg});
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    width: 252px;
    height: 272px;
    bottom: 0;
    left: -34px;
    z-index: -1;
    @media (max-width: 600px) {
      background-image: url(${BgMobile});
    }
  }
`;

export const FullWidthBorder = styled.div`
  border-bottom: 1px solid #EBEFF3;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 32px;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 30%;
    padding: 16px 14px;
    border-radius: 10px;
    border: 1px solid ${theme.v2.colors.feedback.canceledMedium};
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 14px;
    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  `}
`;

export const Snappy = styled.img`
  ${({ theme }) => css`
    position: absolute;
    right: 60px;
    bottom: 0;

    @media (max-width: ${theme.breakpoints.md}) {
      width: 128px;
      height: 57px;
      right: 22px;
    }
  `}
`;
