import React from 'react';
import * as Style from './IconDetailedPay.style';

const IconDetailedPay = ({
  color = '#1F2044',
  backgroundColor = '#0198FF',
  width = '30',
  height = '40',
  testId = 'icon-detailed-pay',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="6.99998" r="6.99998" fill={backgroundColor} />
      <path d="M8.07463 14.2889C4.72985 13.3711 3.65421 12.4222 3.65421 10.9444C3.65421 9.24887 5.14242 8.06665 7.63259 8.06665C10.2554 8.06665 11.2279 9.38887 11.3163 11.3333H14.5726C14.4695 8.65776 12.9224 6.19999 9.8428 5.40666V2H5.42238V5.35999C2.56384 6.01332 0.265225 7.97332 0.265225 10.9755C0.265225 14.5689 3.07956 16.3577 7.19055 17.4C10.8742 18.3333 11.611 19.7022 11.611 21.1488C11.611 22.2222 10.889 23.9333 7.63259 23.9333C4.59724 23.9333 3.40372 22.5022 3.24164 20.6666H0C0.176817 24.0733 2.59331 25.9866 5.42238 26.6244V29.9999H9.8428V26.6555C12.7161 26.0799 15 24.3222 15 21.1333C15 16.7155 11.4194 15.2066 8.07463 14.2889Z" fill={color} />
    </svg>

  </Style.Wrapper>
);


export default IconDetailedPay;
