import React from 'react';
import { faqTagManager } from '@/utils/ThirdParties/tagManager';
import * as Styles from './FaqContent.styles';
import { Link } from '@/pages/common';
import { IconArrowForward } from '@/icons/IconArrowForward';
import { Button } from '@/pages/common/v1/Button';
import { IconPaper } from '@/icons/IconPaper';

const FaqContent = ({
  faqLocaleTitle,
  faqLocaleButton,
  questionsFaq,
  handleMoreContent,
  onClickMaterial,
}) => {
  const handleClick = question => faqTagManager(question);
  const questions = questionsFaq;

  return (
    <Styles.Container data-testid="faq-content-component">
      <Styles.FaqWrapper>

        <Styles.FaqTitle>
          <IconPaper />
          <Styles.FaqTextTitle>
            {faqLocaleTitle}
          </Styles.FaqTextTitle>
        </Styles.FaqTitle>

        <Styles.ListWrapper>
          <Styles.List>
            {questions && questions.length && questions.map((questions, index) => (
              <Styles.ListItem key={questions.id}>
                <Link
                  href={questions.link}
                  onClick={() => {
                    handleClick(questions.question);
                    onClickMaterial(index + 1);
                  }}
                  target="_blank"
                  text={questions.question}
                />
                <IconArrowForward size={18} />
              </Styles.ListItem>
            ))}
          </Styles.List>
        </Styles.ListWrapper>

        <Styles.FaqFooter>
          <Styles.ButtonWrapper>
            <Button
              testId="button-redirect"
              onClick={handleMoreContent}
              label={faqLocaleButton}
              variant="secondary"
            />
          </Styles.ButtonWrapper>
        </Styles.FaqFooter>
      </Styles.FaqWrapper>
    </Styles.Container>
  );
};


export default FaqContent;
