import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

const useBillingStatus = () => {
  const today = useMemo(() => (new Date()), []);
  const limitNextDueDate = 30;
  const getIsDueDate = domain => (domain.isGracePeriod ? parseISO(domain.gracePeriodDueDate) : parseISO(domain.nextDueDate));
  const getIsOverDue = useCallback(domain => differenceInCalendarDays(parseISO(domain.nextDueDate), today) < 0 && !domain.isGracePeriod && domain.status === 'active', [today]);
  const getDateDiff = useCallback(dueDate => differenceInCalendarDays(dueDate, today), [today]);
  const getIsNextDueDate = useCallback(domain => getDateDiff(getIsDueDate(domain)) <= limitNextDueDate && domain.status === 'active' && !domain.isGracePeriod, [getDateDiff]);

  const getTagStatus = useCallback((domain) => {
    if (getIsNextDueDate(domain) && !getIsOverDue(domain)) {
      return 'nextDueDate';
    }
    if (getIsOverDue(domain)) {
      return 'overDueDate';
    }
    return domain.status;
  }, [getIsNextDueDate, getIsOverDue]);

  return {
    getTagStatus,
    getIsDueDate,
    getIsOverDue,
    getDateDiff,
    getIsNextDueDate,
  };
};

export default useBillingStatus;
