const styles = theme => ({
  id: {
    display: 'block',
    fontWeight: 500,
    marginRight: '15px',
    color: theme.color.tundora,
  },
});

export default styles;
