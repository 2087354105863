import styled, { css } from 'styled-components';

const clientareaHeader = '80px';
const chatSize = '101px';
const chatSizeMobile = '40px';
const inputWrapperHeight = '85px';
const contentHeader = '83px';
const skelletonSiize = '150px';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${clientareaHeader} - ${chatSizeMobile});
    margin: 0;
    padding: 16px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      margin: 0 16px;
      padding: 16px;
      height: calc(100vh - ${clientareaHeader} - ${chatSize});
    }
  `}
`;

export const EmptyContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${clientareaHeader} - ${chatSizeMobile});
    justify-content: center;
    margin: 0;
    padding: 16px;

    > button { 
      max-width: 337px;
      width: 100%;
    }

    @media(min-width:${theme.v2.breakpoints.sm}){
      margin: 0 16px;
      padding: 16px;
      height: calc(100vh - ${clientareaHeader} - ${chatSize});
    }
  `}
`;

export const TicketId = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 16px;
    margin: 0px;
  `}
`;

export const TicketSubject = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 16px 0px;
    padding: 0px 0px 8px 0px;
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
  `}
`;


export const MessagesWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: calc(100% - ${inputWrapperHeight} - ${contentHeader} - ${skelletonSiize});
    overflow-y: scroll;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 25px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${theme.v2.colors.neutral.highMedium}; 
      border-radius: 25px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
        overflow: auto;
    }
  `}
`;

export const MessagesWrapperSkeleton = styled(MessagesWrapper)`
    display: flex;
    gap: 25px;
`;

export const MessageBlock = styled.div`
  ${() => css` 
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  `}
`;

export const Person = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const PersonIconWrapper = styled.span`
  ${({ theme }) => css`
    width: 24px;
    align-items: center;
    background-color: ${theme.v2.colors.neutral.highLight};
    border-radius: 100%;
    display: flex;
    height: 24px;
    justify-content: center;
    margin: 0px 16px 0px 0px;
    padding: 4px;
  `}
`;

export const Time = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 0px 16px;

    &:first-letter {
      text-transform: uppercase;
    }
  `}
`;

export const Message = styled.div`
  ${({ theme, variant }) => {
    const variants = {
      analyst: {
        bgColor: theme.v2.colors.neutral.highLight,
      },
      me: {
        bgColor: theme.v2.colors.brand.primaryUltralight,
      },
    };

    return css`
    align-self: flex-start;
    background-color: ${variants[variant].bgColor};
    border-radius: 8px;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    padding: 8px 16px;

    p {
      margin: 0px;
    }
  `;
  }}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    align-self: flex-start;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.feedback.canceledPure};
    cursor: pointer;
    flex: 0 0 150px;
    width: 150%;
  `}
`;

export const AttachmentsWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
  `}
`;

export const ImageWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 8px;
  `}
`;

export const DocWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 8px;
  `}
`;

export const ImageModal = styled.img`
`;

export const DocumentButton = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    box-shadow: unset;
    color: ${theme.v2.colors.neutral.lowPure};
    cursor: pointer;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    padding: 12px 10px;
  `}
`;

export const AttatchedFile = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    align-self: flex-start;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    box-shadow: unset;
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    padding: 12px 10px;
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 24px 12px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-wrap: wrap;
      > button:last-child {
        width: 100%;
      }
    }
  `}
`;

export const InputDelimiter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    width: 100%;

    & div:first-child {
      border: 0px;
    }
`;

export const EmptyLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 38px
  `}
`;

export const File = styled.div`
  ${({ theme }) => css`
    align-items: center;
    box-shadow: ${theme.v2.shadows.dark};
    display: flex;
    fit-content;
    flex-direction: row;
    gap: 24px;
    margin: 24px 0px 0px;
    padding: 16px;
    width: fit-content;
  `}
`;

export const ModalWithFileContent = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px 32px 0px;
  `}
`;

export const ButtonRow = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    margin: 0px 0px 32px;
  `}
`;
