const styles = theme => ({
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outlinedButton: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    marginTop: 24,
  },
});

export default styles;
