import React, { useEffect, useState } from 'react';
import * as Styles from './SelectChip.styles';

const SelectChip = ({
  variant = 'default',
  title,
  onChange,
  type = 'radio',
  checked = false,
  id = 'SelectChip',
  testId = 'SelectChip',
  value = null,
  justify = 'flex-start',
  align = 'center',
  name = 'selectName',
  disabled = false,
  truncate = false,
  truncateMaxWidth,
  truncateTitle,
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  const handleChange = (event) => {
    onChange && onChange(event, value);
    type === 'radio'
      ? setCurrentChecked(!checked)
      : setCurrentChecked(!currentChecked);
  };

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);

  return (
    <Styles.SelectChipWrapper data-testid={testId}>
      <Styles.SelectInput
        type={type}
        value={value}
        id={`${id}-input`}
        data-testid={`${testId}-input`}
        checked={currentChecked}
        onChange={event => handleChange(event)}
        variant={variant}
        name={name}
        disabled={disabled}
      />

      <Styles.SelectChipLabel
        data-testid={`${testId}-label`}
        id={`${id}Label`}
        variant={variant}
        justify={justify}
        align={align}
        htmlFor={`${id}-input`}
        disabled={disabled}
      >

        <Styles.LabelContent>
          {title && <Styles.Title title={truncateTitle} truncateMaxWidth={truncateMaxWidth} truncate={truncate} variant={variant}>{title}</Styles.Title>}
        </Styles.LabelContent>
      </Styles.SelectChipLabel>
    </Styles.SelectChipWrapper>
  );
};

export default SelectChip;
