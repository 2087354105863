const hasLowerCase = (value) => {
  const hasLowerCaseRule = /(.*[a-z].*)/;
  return hasLowerCaseRule.test(value);
};

const hasUpperCase = (value) => {
  const hasUppercaseRule = /(.*[A-Z].*)/;
  return hasUppercaseRule.test(value);
};

const hasLowerAndUper = value => hasLowerCase(value) && hasUpperCase(value);

const hasNumber = (value) => {
  const hasNumberRule = /(.*[0-9].*)/;
  return hasNumberRule.test(value);
};

const hasSpecial = (value) => {
  // eslint-disable-next-line no-useless-escape
  const hasSpecialRule = /.*[$-/:-?{-~!"^_`\[\]@¢¬#£].*/;
  return hasSpecialRule.test(value);
};

const changePwValidations = {
  hasLowerCase,
  hasUpperCase,
  hasLowerAndUper,
  hasNumber,
  hasSpecial,
};

export default changePwValidations;
