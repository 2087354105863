/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  path, findIndex, propEq, isNil, find,
} from 'ramda';
import { withI18n } from 'react-i18next';
import {
  requestGetRecord, requestAddRecord, requestDeleteRecord, requestEditRecord,
} from '@/redux/actions/dnsZoneManager';
import {
  ERROR_DNS_ZONE_MANAGER_ADD_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD,
  ERROR_DNS_ZONE_MANAGER_DELETE_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD,
  ERROR_DNS_ZONE_MANAGER_EDIT_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_GET_RECORD,
  ERROR_DNS_ZONE_MANAGER_GET_RECORD,
} from '@/redux/actions/actionsTypes';
import { COUNTRY } from '@/config';
import {
  SUPPORT_ARTICLE_DNS_REGISTROBR,
  SUPPORT_ARTICLE_DNS_GODADDY,
  SUPPORT_ARTICLE_DNS_KINGHOST,
  SUPPORT_ARTICLE_DNS_WEBEMPRESA,
  SUPPORT_ARTICLE_DNS_COLOMBIAHOSTING,
} from '@/config/urls/supportUrls';
import { commonActions } from '@/redux/modules';

export const DNSContext = createContext();

let typedFilterTimeout = null;

const DNSContextProvider = (props) => {
  const { t, children, planStatus } = props;
  const dispatch = useDispatch();
  const domainName = useSelector((state) => {
    const domainPath = path(['dnsDetails', 'details', 'domainName'], state);
    return domainPath ? domainPath.replace(/ /g, '') : '';
  });
  const recordTypes = useSelector(state => state.dnsZoneManager.recordTypes);
  const loading = useSelector(state => state.dnsZoneManager.loading);
  const dnsListLoading = useSelector(state => state.dnsList.loading);
  const details = useSelector(state => state.dnsDetails.details);
  const recordedDnsItems = useSelector(state => state.dnsZoneManager.recordedDnsItems);
  const tableColumns = ['type', 'name', 'class', 'ttl', 'address'];
  const [clearedRecordedItems, setClearedRecordedItems] = useState([]);
  const [renderedRecordedItems, setRenderedRecordedItems] = useState(recordedDnsItems);
  const [showExcludeModalConfirmation, setShowExcludeModalConfirmation] = useState(false);
  const [excludeRecordedDnsItem, setExcludeRecordedDnsItem] = useState(null);
  const [toggleAddRecordForm, setToggleAddRecordForm] = useState(false);
  const [dnsFilterOptions, setDnsFilterOptions] = useState([]);
  const [interactedWithFilterOptions, setInteractedWithFilterOptions] = useState(false);
  const [mobileListAmount, setMobileListAmount] = useState(5);
  const mobileItemRef = useRef();
  const [typedFilterData, setTypedFilterData] = useState('');
  const [showPointToModal, setShowPointToModal] = useState(false);
  const [hostingSelected, setHostingSelected] = useState(null);
  const [couldConnectWithServer, setCouldConnectWithServer] = useState(true);
  const [otherProviderLinks, setOtherProviderLinks] = useState(null);
  const [featureTogglesZoneManager, setFeatureTogglesZoneManager] = useState({
    hostingCpanel: false,
    hostingWhm: false,
    other: false,
    native: false,
  });
  const [lastInteracted, setLastInteracted] = useState(null);

  const handleLoadMoreMobile = () => {
    const top = mobileItemRef.current.offsetTop;
    window.scrollTo({
      top: top + 233,
      left: 0,
      behavior: 'smooth',
    });
    setMobileListAmount(mobileListAmount + 5);
  };

  const handleSetSelectedFilter = (idx, recordType) => {
    let newFilters = [];
    newFilters = [...dnsFilterOptions];

    if (newFilters[idx].name === t('dns.zoneManager.recordList.allFiltersLabel')) {
      newFilters.map((filter) => {
        const newFilter = filter;
        newFilter.active = false;
        newFilters[idx].active = true;
        return newFilter;
      });
    } else {
      const allItemsPosition = findIndex(propEq('name', t('dns.zoneManager.recordList.allFiltersLabel')), newFilters);
      newFilters[allItemsPosition].active = false;
      newFilters[idx].active = !recordType.active;
    }

    let allAreDisbled = true;
    newFilters.forEach((item) => {
      if (item.active === true) {
        allAreDisbled = false;
      }
    });

    if (allAreDisbled) {
      newFilters[0].active = true;
    }

    setInteractedWithFilterOptions(true);
    setDnsFilterOptions(newFilters);
  };

  const handleErrors = (response, errrorMethod, lastSubmited) => {
    const errors = response.data;
    errors.forEach((error) => {
      if (error.code) {
        let splittedError = error.code.split('.');
        splittedError = splittedError.splice(1, splittedError.length - 1);
        let errorPath = '';
        splittedError.forEach((errorBlock) => {
          errorPath = errorPath === '' ? `${errorBlock}` : `${errorPath}.${errorBlock}`;
        });
        const hasMessage = t(`dns.zoneManager.errorCode.${errorPath}`) !== `dns.zoneManager.errorCode.${errorPath}`;
        if (hasMessage) {
          dispatch(commonActions.notifications.set({ label: t(`dns.zoneManager.errorCode.${errorPath}`), type: 'error' }));
        } else {
          if (errorPath === 'name.unique') {
            let errorMessage = '';
            if (lastSubmited.type === 'CNAME') {
              errorMessage = `${t('dns.zoneManager.errorCode.name.unique_3')}`;
            } else {
              errorMessage = `${t('dns.zoneManager.errorCode.name.unique_1')} ${lastSubmited.type} ${t('dns.zoneManager.errorCode.name.unique_2')}`;
            }
            dispatch(commonActions.notifications.set({ label: errorMessage, type: 'error' }));
            return;
          }
          if (errorPath === 'unique.domain') {
            dispatch(commonActions.notifications.set({ label: t(`dns.zoneManager.${errrorMethod}.errorMessageDomain`), type: 'error' }));
            return;
          }
          if (planStatus === 'Terminated') {
            dispatch(commonActions.notifications.set({ label: t('dns.zoneManager.planStatusCancelledZoneDNS'), type: 'error' }));
          } else {
            dispatch(commonActions.notifications.set({ label: t(`dns.zoneManager.${errrorMethod}.errorMessage`), type: 'error' }));
          }
        }
      } else {
        dispatch(commonActions.notifications.set({ label: t(`dns.zoneManager.${errrorMethod}.errorMessage`), type: 'error' }));
      }
    });
  };

  const getListRecords = () => {
    dispatch(requestGetRecord()).then((response) => {
      if (response.type === RECEIVE_DNS_ZONE_MANAGER_GET_RECORD) {
        setCouldConnectWithServer(true);
      } else if (response.type === ERROR_DNS_ZONE_MANAGER_GET_RECORD) {
        setCouldConnectWithServer(false);
      }
    });
  };

  const handleSubmit = (e, formData, editing, keepEditing) => {
    e.preventDefault();
    const newFormData = { ...formData };
    newFormData.ttl = parseInt(newFormData.ttl, 10);

    if (newFormData.name === '@') {
      newFormData.name = `${domainName}`;
    }

    const hasDomain = newFormData.name.includes(domainName);

    const lastCharIsDot = newFormData.name[newFormData.name.length - 1] === '.';
    if (hasDomain) {
      newFormData.name = lastCharIsDot ? newFormData.name.substring(0, newFormData.name.length - 1) : newFormData.name;
    } else {
      newFormData.name = lastCharIsDot ? `${newFormData.name}${domainName}` : `${newFormData.name}.${domainName}`;
    }

    if (formData.type === 'CAA') {
      newFormData.flag = parseInt(newFormData.flag, 10);
      delete newFormData.address;
    }

    if (formData.type === 'A' || formData.type === 'AAAA') {
      delete newFormData.flag;
      delete newFormData.tag;
      delete newFormData.value;
    }

    if (formData.type === 'MX') {
      newFormData.preference = parseInt(newFormData.preference, 10);
    }

    if (formData.type !== 'CAA') {
      delete newFormData.flag;
      delete newFormData.tag;
      delete newFormData.value;
    } else {
      newFormData.flag = parseInt(newFormData.flag, 10);
      delete newFormData.address;
    }

    if (formData.type === 'SRV') {
      newFormData.priority = parseInt(newFormData.priority, 10);
      newFormData.port = parseInt(newFormData.port, 10);
      newFormData.weight = parseInt(newFormData.weight, 10);
    }

    if (loading) {
      return;
    }

    setLastInteracted(newFormData);

    if (editing) {
      dispatch(requestEditRecord(newFormData)).then((response) => {
        keepEditing(false);
        if (response.type === RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD) {
          dispatch(commonActions.notifications.set({ label: t('dns.zoneManager.editRecord.successMessage'), type: 'success' }));
          getListRecords();
        } else if (response.type === ERROR_DNS_ZONE_MANAGER_EDIT_RECORD) {
          handleErrors(response, 'editRecord', newFormData);
        }
      });
    } else {
      dispatch(requestAddRecord(newFormData)).then((response) => {
        if (response.type === RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD) {
          dispatch(commonActions.notifications.set({ label: t('dns.zoneManager.addRecord.successMessage'), type: 'success' }));
          getListRecords();
        } else if (response.type === ERROR_DNS_ZONE_MANAGER_ADD_RECORD) {
          handleErrors(response, 'addRecord', newFormData);
        }
      });
    }
  };

  const confirmDeletion = () => {
    dispatch(requestDeleteRecord(excludeRecordedDnsItem)).then((response) => {
      if (response.type === RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD) {
        dispatch(commonActions.notifications.set({ label: t('dns.zoneManager.excludeRecord.successMessage'), type: 'success' }));
        getListRecords();
        setLastInteracted(excludeRecordedDnsItem);
        setShowExcludeModalConfirmation(false);
      } else if (response.type === ERROR_DNS_ZONE_MANAGER_DELETE_RECORD) {
        dispatch(commonActions.notifications.set({ label: t('dns.zoneManager.excludeRecord.errorMessage'), type: 'error' }));
        setShowExcludeModalConfirmation(false);
      }
    });
  };

  const removeSpecificTypes = (list) => {
    const types = ['SOA', 'NS'];
    const newList = [...list].filter(item => !types.includes(item.type));
    newList.sort((a, b) => (b.line - a.line));
    return newList;
  };

  const filterByOptions = () => {
    let newRecordedDnsItems = [...recordedDnsItems].filter((dnsItem) => {
      let isFiltered = false;
      dnsFilterOptions.forEach((filterOption) => {
        if (filterOption.active) {
          if (filterOption.name === t('dns.zoneManager.recordList.allFiltersLabel')) {
            isFiltered = true;
          } else if (dnsItem.type === filterOption.name) {
            isFiltered = true;
          }
        }
      });
      return isFiltered;
    });

    newRecordedDnsItems = removeSpecificTypes(newRecordedDnsItems);
    return newRecordedDnsItems;
  };

  const filterByTypedData = (obj) => {
    const { list, value } = obj;
    const newList = [...list].filter((item) => {
      const string = JSON.stringify(item);
      const itemHasTerm = string.indexOf(value) > -1;
      return itemHasTerm;
    });

    return newList;
  };

  const handleChangeTypedFilterData = (value, list) => {
    if (list) {
      let newRecordedDnsItems = filterByOptions();
      newRecordedDnsItems = filterByTypedData({ list: newRecordedDnsItems, value });
      return newRecordedDnsItems;
    }


    setTypedFilterData(value);

    /**
     * clearTimeout prevents filter functions to be called.
     * Those functions will be called only after the user finish typing the searchTearm.
     *
     * O clearTimeout impede que as funções de filtros sejam invocadas.
     * As funções de filtro são executadas somente após o usuário terminar de digitiar o termo de busca.
     */
    clearTimeout(typedFilterTimeout);
    typedFilterTimeout = setTimeout(() => {
      let newRecordedDnsItems = filterByOptions();
      newRecordedDnsItems = filterByTypedData({ list: newRecordedDnsItems, value });
      setRenderedRecordedItems(newRecordedDnsItems);
    }, 500);
  };

  const gettingFeatureToggles = () => {
    const isAlphaZoneManagerHostingCpanel = true;
    const isAdminAlphaZoneManagerHostingCpanel = false;

    const isAlphaZoneManagerHostingWhm = false;
    const isAdminAlphaZoneManagerHostingWh = false;

    const isAlphaZoneManagerNative = true;
    const isAdminAlphaZoneManagerNative = false;

    const toggles = {
      hostingCpanel: isAlphaZoneManagerHostingCpanel || isAdminAlphaZoneManagerHostingCpanel, // Hosting cpanel
      hostingWhm: isAlphaZoneManagerHostingWhm || isAdminAlphaZoneManagerHostingWh, // Hosting whm
      other: false, // Other - Must always be false
      native: isAlphaZoneManagerNative || isAdminAlphaZoneManagerNative, // Reservado
    };

    setFeatureTogglesZoneManager(toggles);
  };

  const validatingFeatureToggles = (hasDbData, hostingSelected) => {
    let featureToggleIsOn = false;
    if (hasDbData) {
      Object.keys(hasDbData).forEach((dnsOption) => {
        if (hasDbData[dnsOption] === true) {
          if (dnsOption === 'hosting') {
            if (hostingSelected) {
              const isCpanel = hostingSelected.type !== 'reselleraccount';
              const isWhm = hostingSelected.type === 'reselleraccount';

              if (isCpanel) {
                featureToggleIsOn = featureTogglesZoneManager.hostingCpanel;
              }

              if (isWhm) {
                featureToggleIsOn = featureTogglesZoneManager.hostingWhm;
              }
            }
          } else {
            featureToggleIsOn = featureTogglesZoneManager[dnsOption];
          }
        }
      });
    }

    return featureToggleIsOn;
  };

  useEffect(() => {
    const typesArray = [];
    typesArray.push({ name: t('dns.zoneManager.recordList.allFiltersLabel'), active: true });

    recordTypes.forEach((recordType) => {
      typesArray.push({ name: recordType, active: false });
    });
    setDnsFilterOptions(typesArray);

    let otherProvidersHelpLink = [
      { url: SUPPORT_ARTICLE_DNS_REGISTROBR, name: 'RegistroBR', country: { br: true } },
      {
        url: SUPPORT_ARTICLE_DNS_GODADDY,
        name: 'GoDaddy',
        country: {
          br: true, mx: true, es: true, co: true,
        },
      },
      { url: SUPPORT_ARTICLE_DNS_KINGHOST, name: 'KingHost', country: { br: true } },
      { url: SUPPORT_ARTICLE_DNS_WEBEMPRESA, name: 'Web Empresa', country: { mx: true, es: true, co: true } },
      { url: SUPPORT_ARTICLE_DNS_COLOMBIAHOSTING, name: 'Colombia Hosting', country: { mx: true, es: true, co: true } },
    ];

    otherProvidersHelpLink = otherProvidersHelpLink.filter((link) => {
      const shouldDisplay = path([`${COUNTRY}`], link.country);
      return !isNil(shouldDisplay);
    });

    setOtherProviderLinks(otherProvidersHelpLink);
    gettingFeatureToggles();
  }, []);

  useEffect(() => {
    const hasDbData = details && details.dbData;
    let featureToggleIsOn = false;

    if (details.domainName) {
      let hostingSelected = null;
      if (details.dbData && details.dbData.hosting) {
        hostingSelected = details.availableHostings.find(item => item.id === details.nameserver.hosting.id);
      } else {
        hostingSelected = details.availableHostings.find(item => item.domain === details.domainName);
      }

      setHostingSelected(hostingSelected);

      featureToggleIsOn = validatingFeatureToggles(hasDbData, hostingSelected);
    }

    if ((featureToggleIsOn)) {
      getListRecords();
    }
  }, [details && details.dbData]);

  const filteringItems = (list) => {
    let typeIsActive = false;
    let filteredList = [...list];

    if (lastInteracted) {
      typeIsActive = find(propEq('name', lastInteracted.type), dnsFilterOptions).active;
    }

    if (typeIsActive) {
      filteredList = handleChangeTypedFilterData(typedFilterData, list);
    }

    if (!typeIsActive) {
      const newTypesArray = [...dnsFilterOptions].map((filterOption, idx) => {
        const newRecordType = { ...filterOption };
        newRecordType.active = idx === 0;
        return newRecordType;
      });

      setDnsFilterOptions(newTypesArray);
      setLastInteracted(null);
    }

    return filteredList;
  };

  useEffect(() => {
    const shouldFilter = typedFilterData !== '' || interactedWithFilterOptions;

    if (recordedDnsItems && recordedDnsItems.length > 0) {
      let newRecordedDnsItems = [...recordedDnsItems];
      newRecordedDnsItems = removeSpecificTypes(newRecordedDnsItems);
      setRenderedRecordedItems(shouldFilter ? filteringItems(newRecordedDnsItems) : newRecordedDnsItems);
      setClearedRecordedItems(newRecordedDnsItems);
    }
  }, [recordedDnsItems]);

  useEffect(() => {
    let newRecordedDnsItems = [...recordedDnsItems].filter((dnsItem) => {
      let isFiltered = false;
      dnsFilterOptions.forEach((filterOption) => {
        if (filterOption.active) {
          if (filterOption.name === t('dns.zoneManager.recordList.allFiltersLabel')) {
            isFiltered = true;
          } else if (dnsItem.type === filterOption.name) {
            isFiltered = true;
          }
        }
      });
      return isFiltered;
    });

    newRecordedDnsItems = removeSpecificTypes(newRecordedDnsItems);
    newRecordedDnsItems = filterByTypedData({ list: newRecordedDnsItems, value: typedFilterData });
    setRenderedRecordedItems(newRecordedDnsItems);
  }, [dnsFilterOptions]);

  const values = {
    details,
    dnsFilterOptions,
    handleSetSelectedFilter,
    dnsListLoading,
    loading,
    recordTypes,
    setDnsFilterOptions,
    setToggleAddRecordForm,
    toggleAddRecordForm,
    handleSubmit,
    tableColumns,
    renderedRecordedItems,
    showExcludeModalConfirmation,
    setShowExcludeModalConfirmation,
    excludeRecordedDnsItem,
    setExcludeRecordedDnsItem,
    confirmDeletion,
    mobileListAmount,
    setMobileListAmount,
    mobileItemRef,
    handleLoadMoreMobile,
    domainName,
    typedFilterData,
    setTypedFilterData,
    handleChangeTypedFilterData,
    showPointToModal,
    setShowPointToModal,
    hostingSelected,
    couldConnectWithServer,
    otherProviderLinks,
    clearedRecordedItems,
    featureTogglesZoneManager,
  };

  return (
    <DNSContext.Provider value={values}>{children}</DNSContext.Provider>
  );
};

DNSContextProvider.propTypes = {
  children: PropTypes.node,
};

DNSContextProvider.defaultProps = {
  children: null,
};

export default withI18n()(DNSContextProvider);
