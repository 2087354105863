const styles = theme => ({


  createFirstAccount: {
    alignItems: 'center',
    backgroundColor: theme.color.white,
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '16px',
    color: theme.color.indigo,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.medium,
    height: '28px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginLeft: '8px',
    maxWidth: '100%',
    opacity: 1,
    padding: '5px 5px 5px 5px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: '300ms',
    width: '149px',
    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.color.indigo}`,
      color: theme.color.white,
    },
  },

  textCell: {
    color: theme.color.tundora,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      maxWidth: '148px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },

  textCellOverDueDate: {
    color: theme.color.regentGray,
  },

  textCellPending: {
    color: theme.color.regentGray,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      whiteSpace: 'nowrap',
    },
  },

  table: {
    marginBottom: '28px',
    marginRight: '24px',
    marginTop: '8px',
    width: '99%',
    [theme.breakpoints.down(700)]: {
      marginRight: '16px',
      '&:last-child': {
        padding: '0px',
      },
    },
  },

  divLogoTitle: {
    display: 'inline-block',
  },

  divMain: {
    marginLeft: '16px',
    marginRight: '16px',
  },

  imgLogo: {
    display: 'inline-block',
    top: '5px',
    position: 'relative',
  },

  divContentLogo: {
    width: '50%',
    textAlign: 'right',
    [theme.breakpoints.down(700)]: {
      width: '70px',
    },
  },

  divLogos: {
    display: 'inline-block',
    marginRight: '40px',
    marginTop: '21px',
    position: 'relative',
    width: '96px',
  },

  logo: {
    display: 'inline-block',
    height: '24px',
    marginLeft: '16px',
    marginTop: '11px',
    width: '24px',
  },

  logoTitle: {
    color: theme.palette.grey.light,
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '0.01px',
    marginLeft: '4px',
    opacity: 1,
    textAlign: 'left',
  },

  tableRow: {
    backgroundColor: theme.color.lightBlue,
    borderRadius: '5px',
    height: '62px',
    opacity: 1,
  },

  tableHead: {
    height: '32px',
  },

  tableHeadCellDomain: {
    color: theme.color.regentGray,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.xxs,
    letterSpacing: '0.01px',
    opacity: 1,
    paddingBottom: '4px',
    paddingLeft: '14px',
    paddingTop: '25px',
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '4px',
      width: '152px',
    },

  },

  tableCellDomain: {
    borderBottom: `2px solid ${theme.color.white}`,
    borderTop: `2px solid ${theme.color.white}`,
    color: theme.color.tundora,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    letterSpacing: '0.01px',
    opacity: 1,
    paddingLeft: '14px',
    textAlign: 'left',
    width: '300px',
    paddingRight: '0px',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '4px',
      width: '152px',
    },
  },

  tableHeadCell: {
    color: theme.color.regentGray,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.xxs,
    letterSpacing: '0.01px',
    opacity: 1,
    paddingBottom: '4px',
    paddingTop: '25px',
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '12px',
      paddingRight: '8px',
    },
  },

  tableHeadCellAction: {
    borderBottom: `2px solid ${theme.color.white}`,
    borderTop: `2px solid ${theme.color.white}`,
    color: theme.color.regentGray,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.xxs,
    letterSpacing: '0.01px',
    opacity: 1,
    paddingBottom: '4px',
    paddingTop: '25px',
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down(700)]: {
      paddingRight: '0px',
      width: '10%',
      padding: '0px',
      '&:last-child': {
        padding: '0px',
      },
    },
  },

  tableCell: {
    borderBottom: `2px solid ${theme.color.white}`,
    borderTop: `2px solid ${theme.color.white}`,
    color: theme.color.tundora,
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      paddingRight: '8px',
      paddingLeft: '12px',
    },
  },


  tableCellAction: {
    borderBottom: `2px solid ${theme.color.white}`,
    borderTop: `2px solid ${theme.color.white}`,
    color: theme.color.tundora,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'right',
    [theme.breakpoints.down(700)]: {
      width: '10%',
      padding: '0px',
      '&:last-child': {
        padding: '0px',
      },
    },
  },

  card: {
    borderRadius: 0,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    marginBottom: 6,
    '& .detailParam': {
      fontSize: theme.font.sizes.sm,
      fontFamily: 'Roboto, sans-serif',
    },
    '& .cardSubTitle': {
      color: theme.color.regentGray,
    },
  },

  cardContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '48px',
    justifyContent: 'flex-start',
    '&:last-child': {
      padding: '19px 24px 18px 24px',
    },
    [theme.breakpoints.down(700)]: {
      display: 'inline-table',
      height: '92px',
      marginBottom: '-28px',
    },

  },

  cardContainerFreePending: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '48px',
    justifyContent: 'flex-start',
    '&:last-child': {
      padding: '19px 24px 18px 24px',
    },
    [theme.breakpoints.down(700)]: {
    },
  },

  messageBlock: {
    alignItems: 'center',
    border: `1px solid ${theme.color.white}`,
    borderRadius: '6px',
    display: 'flex',
    marginRight: 'auto',
    maxWidth: '473px',
    padding: '12px',
    width: '100%',
    [theme.breakpoints.down(1595)]: {
      marginBottom: '18px',
    },
    [theme.breakpoints.down(1285)]: {
      marginBottom: '21px',
      maxWidth: '100%',
    },
  },

  activeMessage: { borderColor: theme.color.oceanGreen },

  warningIcon: {
    fill: theme.color.valencia,
    height: '14px',
    marginRight: '4px',
    opacity: 1,
    width: '14px',
  },

  messageLink: {
    color: theme.color.indigo,
    fontWeight: theme.font.weights.medium,
  },

  message: {
    color: theme.color.tundora,
    fontSize: theme.font.sizes.xxs,
  },

  activeCardStatus: {
    borderLeft: `5px solid ${theme.color.oceanGreen}`,
    '& .status-icon': {
      color: theme.color.oceanGreen,
    },
    '& .barChecked': {
      '& ~ .roundBar': {
        backgroundColor: theme.color.oceanGreen,
      },
    },
    '& .roundIcon': {
      backgroundColor: theme.color.mystic,
    },
    '& .iconChecked': {
      backgroundColor: theme.color.oceanGreen,
    },
    '& .switchRoot': {
      '& .roundBar': {
        backgroundColor: theme.color.tundora,
      },
    },
  },

  nullCardStatus: {
    borderLeft: `5px solid ${theme.color.coral}`,
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .warning-notice': {
      borderColor: theme.color.coral,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.coral,
    },
  },

  pendingCardStatus: {
    borderLeft: `5px solid ${theme.color.coral}`,
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .warning-notice': {
      borderColor: theme.color.coral,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.coral,
    },
  },

  provisioningCardStatus: {
    borderLeft: `5px solid ${theme.color.coral}`,
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .warning-notice': {
      borderColor: theme.color.coral,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.coral,
    },
  },

  suspendedCardStatus: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },

  terminatedCardStatus: {
    borderLeft: `5px solid ${theme.color.geyser}`,
    '& .status-icon': {
      color: theme.color.geyser,
    },
    '& .warning-notice': {
      borderColor: theme.color.geyser,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.geyser,
    },
  },

  expiredCardStatus: {
    borderLeft: `5px solid ${theme.color.geyser}`,
    '& .status-icon': {
      color: theme.color.geyser,
    },
    '& .warning-notice': {
      borderColor: theme.color.geyser,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.geyser,
    },
  },

  uponDueDateProEmailStatus: {
    borderLeft: `5px solid ${theme.color.brightSun}`,
    '& .status-icon': {
      color: theme.color.brightSun,
    },
    '& .warning-notice': {
      borderColor: theme.color.brightSun,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.brightSun,
    },
  },

  almostCanceladCardStatus: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },

  details: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '342px',

    [theme.breakpoints.down(1285)]: {
      marginBottom: '21px',
    },

    [theme.breakpoints.down(745)]: {
      marginBottom: '2px',
      width: '100%',
    },
  },

  emailIcon: {
    fontSize: theme.font.sizes.xxl,
    marginRight: '20px',
  },

  activeIcon: { fill: theme.color.oceanGreen },

  nullIcon: { fill: theme.color.indigo },

  pendingIcon: { fill: theme.color.brightSun },

  provisioningIcon: { fill: theme.color.brightSun },

  suspendedIcon: { fill: theme.color.valencia },

  terminatedIcon: { fill: theme.color.geyser },

  expiredIcon: { fill: theme.color.geyser },


  proEmailDataWrapper: {
    alignSelf: 'flex-start',
  },

  forceCenter: {
    alignSelf: 'center',
  },

  proEmailName: {
    color: theme.color.tundora,
    fontSize: theme.font.sizes.md,
    fontWeight: theme.font.weights.medium,
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  proEmailDomain: {
    color: theme.color.regentGray,
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    letterSpacing: '0.01px',
    lineHeight: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '275px',
  },

  proEmailStatus: {
    borderRadius: '2px',
    color: theme.color.white,
    display: 'inline-block',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    lineHeight: '14px',
    marginTop: '10px',
    padding: '3px 6px',
  },

  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: '3px',
    fontSize: theme.font.sizes.xxs,
    lineHeight: '14px',
    opacity: '0.9',
    padding: '8px 8px 8px 8px',
    textAlign: 'left',
  },

  activeProEmailStatus: { backgroundColor: theme.color.oceanGreen },

  pendingProEmailStatus: { backgroundColor: theme.color.brightSun },

  provisioningProEmailStatus: { backgroundColor: theme.color.brightSun },

  suspendedProEmailStatus: { backgroundColor: theme.color.valencia },

  terminatedProEmailStatus: { backgroundColor: theme.color.geyser },

  expiredProEmailStatus: { backgroundColor: theme.color.geyser },

});

export default styles;
