import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    @media (max-width: 1296px){
      flex-direction: column;
    }
  `}
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  padding: 56px 0px 0px 83px;

  @media(max-width: 1919px){
    padding: 32px 0px 0px 61px;
  }

  @media (max-width: 1296px){
    align-items: center;
    padding: 32px 61px 0px 61px;
  }

  @media (max-width: 428px){
    padding: 32px 15px 0px 15px;
  }
`;

export const Right = styled.div`
  flex: 0 1;
  margin-left: 20px;
  padding: 56px 83px 0px 0px;

  @media(max-width: 1919px){
    padding: 32px 61px 0px 0px;
  }

  @media (max-width: 1296px){
    display: none;
    visibility: hidden;
  }
`;

export const MobileForm = styled.div`
  display: none;
  visibility: hidden;
  width: 100%;

  @media (max-width: 1296px){
    display: flex;
    visibility: visible;
  }
`;

export const Logo = styled.img`
  ${() => css`
    max-width: 170px;
  `}
`;

export const TitleWrapper = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 30px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 41px;
    margin: 18px 0px 16px;
    max-width: 650px;

    & span {
      color: ${theme.v1.color.action.hover} !important;
    }

    @media (max-width: 1296px){
      text-align: center;
    }
  `}
`;

export const DescriptionWrapper = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0px 0px 16px;
    max-width: 440px;

    @media (max-width: 1296px){
      text-align: center;
    }
  `}
`;

export const ListTitleWrapper = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0px 0px 50px;
    max-width: 440px;


    @media (max-width: 1296px){
      margin-top: 16px;
      text-align: center;
    }

    @media (max-width: 724px){
      margin: 16px 0px 24px;
      align-self: start;
    }
  `}
`;

export const ListWrapper = styled.div`
  ${() => css`
    display: flex;

    ul:first-child {
      margin-right: 50px
    }
    
    @media(max-width: 1919px){
      flex-direction: column;
    }

    @media (max-width: 1296px){
      flex-direction: row;
    }

    @media (max-width: 724px){
      align-self: start;
      flex-direction: column;
    }
  `}
`;

export const ListItems = styled.ul`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    list-style: none;
    margin: 0px;
    padding: 0px 0px 0px 20px;
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0px 0px 12px 0px;
    max-width: 440px;
    position: relative;

    &:before {
      border-color: ${theme.v1.color.text.featured};
      border-style: solid;
      border-width: 0 3px 3px 0;
      content: "";
      display: inline-block;
      height: 11px;
      left: -20px;
      position: absolute;
      top: 2px;
      transform-origin: bottom left;
      transform: rotate(45deg);
      width: 6px;
    }
  `}
`;

export const BottomDetail = styled.div`
    ${({ theme }) => css`
      height: 90px;
      margin-top: 32px;
      position: relative;
      width: 100%;

      overflow: hidden;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &:after {
        background-color: ${theme.v1.color.background.wizard};
        bottom: -45px;
        content: '';
        display: block;
        height: 70px;
        left: 0px;
        position: absolute;
        transform: rotate(-2deg);
        width: calc(100% + 10px);
      }
    `}
`;

export const SnappyDetail = styled.img`
  ${() => css`
    margin-left: 150px;
    margin-top: auto;
    max-width: 186px;
    position: absolute;
    bottom: 0px;
  `}
`;
