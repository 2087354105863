import * as Styles from './BannerInvoiceSplit.styles';
import React, { } from 'react';
import useLocale from '@/hooks/useLocale';
import { Link } from 'gatorcomponents';

const BannerInvoiceSplit = () => {
  const { billing: billingLocale } = useLocale();
  const { invoices: invoicesLocale } = billingLocale;
  return (
    <Styles.Wrapper>
      <Styles.Content>
        <Styles.Description data-testid="invoice-banner-alert-description">
          {invoicesLocale.headerInvoicesPage.text.splitAlert}
          {' '}
          <Link
            data-testid="know-more-link"
            href={invoicesLocale.knowMoreURL}
            target="_blank"
            text={invoicesLocale.knowMore}
          />
        </Styles.Description>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default BannerInvoiceSplit;
