import {
  takeLatest, select, call, put, takeLeading,
} from 'redux-saga/effects';
import { verify, TokenExpiredError } from 'jsonwebtoken';
import Cookies, { get as getCookie, set as setCookie } from 'js-cookie';
import { api } from '@/utils/api';
import { setJWT, setRefreshToken } from '@/redux/actions/auth';
import { COOKIES, SMS_ERROR } from './auth.enums';
import { AuthActionsTypes } from './auth.types';
import {
  LOGOUT_URL,
  WHMCS_JWT_PUBLIC_KEY_URL,
} from '@/config/whmcsUrls';
import { SEND_PHONE_CODE } from '@/config/api';
import { commonActions } from '@/redux/modules/common';
import React from 'react';
import { locale } from '@/utils/locale';
import { authActions } from '.';
import { format } from 'date-fns';
import { COUNTRY } from '@/config';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import logger from '@/utils/logger';

function* requestPublicKey() {
  let key = getCookie(WHMCS_JWT_PUBLIC_KEY_URL);

  const request = {
    url: 'api/v2/jwt/key',
  };

  if (key) {
    const response = yield call(api.whmcs.get, request);
    // eslint-disable-next-line prefer-destructuring
    key = response.data.key;
  }

  setCookie(WHMCS_JWT_PUBLIC_KEY_URL, key, { expires: 1 }); // expires in one day

  return key;
}

function* requestRefreshToken() {
  const { token, jwt: jwtToken, refreshToken } = yield select(state => state.auth);
  const jwtTokenFromCookie = getCookie(COOKIES.HG_SERVICE_TOKEN);
  const refreshTokenFromCookie = getCookie(COOKIES.HG_REFRESH_TOKEN);

  const request = {
    url: `api/v2/jwt/refresh/${refreshTokenFromCookie || refreshToken}`,
    headers: {
      Authorization: token,
      'X-Service-Token': `Bearer ${jwtTokenFromCookie || jwtToken}`,
    },
    data: {},
  };

  try {
    const response = yield call(api.whmcs.post, request);
    const { token: newJwtToken, refresh_token: newRefreshToken } = response.data;

    return {
      newJwtToken,
      newRefreshToken,
    };
  } catch (err) {
    const error = { ...err, userRequestData: request };
    logger.error('Error at requestRefreshToken (sagas)', error);
    const enableLogoutModal = Cookies.get(TEMPLATE_COOKIES.ENABLE_LOGOUT_MODAL);
    if (enableLogoutModal === 'true') {
      yield put(authActions.tokens.jwt.errorDuringRefresh(true));
    } else {
      window.location.href = LOGOUT_URL;
    }
  }
}

function* updateJwtToken() {
  const { token, refreshToken } = yield select(state => state.auth);
  let { jwt: jwtToken } = yield select(state => state.auth);
  const IS_STORYBOOK = process.env.STORYBOOK_IS_SB === '1';

  const cookieJWT = Cookies.get('hg-service-token');
  if (cookieJWT) {
    jwtToken = cookieJWT;
  }

  try {
    yield put(setJWT(jwtToken));
    yield put(setRefreshToken(refreshToken));

    const key = yield requestPublicKey();
    if (IS_STORYBOOK && key === 'storybookMock') {
      return;
    }

    verify(jwtToken, key, { algorithms: ['RS256'] });
  } catch (err) {
    const hasAllData = refreshToken && token && jwtToken;

    if ((err instanceof TokenExpiredError) || hasAllData) {
      const { newJwtToken, newRefreshToken } = yield requestRefreshToken();

      if (!newJwtToken && !newRefreshToken) return;

      let domain = window.location.hostname;

      domain = domain.includes('cliente')
        ? domain.split('cliente').pop()
        : domain;

      setCookie(COOKIES.HG_SERVICE_TOKEN, newJwtToken, { domain, expires: 1 }); // expires in one day
      setCookie(COOKIES.HG_REFRESH_TOKEN, newRefreshToken, { domain, expires: 1 }); // expires in one day

      yield put(setJWT(newJwtToken));
      yield put(setRefreshToken(newRefreshToken));
    } else {
      logger.error('Error at updateJwtToken (sagas)', err);
      const enableLogoutModal = Cookies.get(TEMPLATE_COOKIES.ENABLE_LOGOUT_MODAL);
      if (enableLogoutModal === 'true') {
        yield put(authActions.tokens.jwt.errorDuringRefresh(true));
      } else {
        window.location.href = LOGOUT_URL;
      }
    }
  }

  yield put(authActions.tokens.jwt.updateFinished());
}

function* sendPhoneToken(action) {
  const {
    clientId,
    password,
    countryCode,
    phoneNumber,
    clientPhoneAgreement,
    setVerificationStep,
    recaptchaToken,
    phoneTimer,
    checkPhoneTimerOnSend,
    setPendingCounter,
  } = action.payload;

  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `${SEND_PHONE_CODE}?recaptcha-value=${recaptchaToken}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: {
      clientId,
      password,
      countryCode,
      phoneNumber,
      clientPhoneAgreement,
    },
  };
  let response = null;

  const brPhone = COUNTRY === 'br' && countryCode === '55';

  try {
    response = yield call(api.aws.post, request);

    if (response.status === 200) {
      setVerificationStep && setVerificationStep();
    }


    yield put(authActions.user.phoneToken.success());

    if (response && response.data && response.data.data && response.data.data.pending_attempts >= 0 && phoneTimer) {
      const attempts = response.data.data.pending_attempts;
      setPendingCounter();
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {locale('phoneVerify.feedback.smsSendAttempts')({ attempts, used: brPhone ? 10 - attempts : 5 - attempts })}
          </p>
        ),
        type: 'success',
      }));
    }
  } catch (e) {
    let internalCode = null;
    let unblock = null;
    let disabledTil = null;

    if (e && e.response && e.response.data && e.response.data.internal_code) {
      internalCode = e.response.data.internal_code;
    }

    if (e && e.response && e.response.data && e.response.data.data && e.response.data.data.unblock_time) {
      unblock = new Date(e.response.data.data.unblock_time);
    }

    if (e && e.response && e.response.data && e.response.data.data && e.response.data.data.disabled_til) {
      disabledTil = e.response.data.data.disabled_til;
    }

    if (internalCode === SMS_ERROR.REQUEST_DISABLED && checkPhoneTimerOnSend) {
      setPendingCounter(disabledTil);
    } else {
      if (internalCode !== SMS_ERROR.INVALID_PASSWORD) {
        setPendingCounter(disabledTil);
      }

      yield put(commonActions.notifications.set({
        label: (
          <p>
            {e.response.data.data === 'Bad Gateway' && (
              `${locale('phoneVerify.feedback.genericError')} `
            )}

            {internalCode === SMS_ERROR.INVALID_PASSWORD && (
              `${locale('phoneVerify.feedback.password')} `
            )}

            {internalCode === SMS_ERROR.GENERIC_ERROR && (
              `${locale('phoneVerify.feedback.sendCodeError')} `
            )}

            {internalCode === SMS_ERROR.NO_MORE_ATTEMPTS && (
              <>
                {locale('phoneVerify.feedback.noMoreAttempts')({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
              </>
            )}

            {internalCode === SMS_ERROR.DATA_IN_USE && (
              <>
                {locale('phoneVerify.feedback.dataInUse')}
              </>
            )}

            {internalCode === SMS_ERROR.ALREADY_VALIDATED && (
              <>
                {locale('phoneVerify.feedback.alreadyValidated')({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
              </>
            )}
          </p>
        ),
        type: 'error',
      }));
    }


    yield put(authActions.user.phoneToken.failure());
  }
}

function* sagas() {
  yield takeLeading(AuthActionsTypes.UPDATE_JWT_TOKEN, updateJwtToken);
  yield takeLatest(AuthActionsTypes.SEND_PHONE_TOKEN, sendPhoneToken);
}

export default sagas;
