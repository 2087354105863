import {
  config,
  IS_PROD,
} from '@/config/index';

import { pushGaDataForMsw } from '@/utils/storybook/pushGaDataForMsw';

const { GTM } = config || { GTM: '' };

const IS_STORYBOOK = process.env.STORYBOOK_IS_SB === '1';

export function push(data, debug = false) {
  if (IS_PROD) {
    window.dataLayer.push(data);
  }

  if (IS_STORYBOOK) {
    pushGaDataForMsw(data);
  }
  // eslint-disable-next-line no-console
  debug && console.table(data);
}

export function onLoadSurveyHotjar(label) {
  push({
    event: 'survey',
    product: label,
  });
}

export function onLoadHotjar(summary) {
  push({
    event: 'user-id',
    id: summary.id,
    'sign-up': summary.signUp,
    'last-login': summary.lastLogin,
  });
}

export function loadGoogleTagManagerScripts() {
  if (IS_PROD) {
    const gtmTag = document.createElement('script');
    gtmTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM}')`;
    document.head.appendChild(gtmTag);
  }
}

export function onLoadEventTagManager() {
  push({
    event: 'onLoad',
  });
}

export function onChangeRouteEventTagManager() {
  push({
    event: 'onRoute',
  });
}

export function chipLinkOfProductTagManager(label) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'atalho',
    gaEventAction: 'click',
    gaEventLabel: label,
  });
}

export function feedbackScoreTagManager(score) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'feedback',
    gaEventAction: 'conclusao',
    gaEventLabel: score,
  });
}

export function actionsAppBarTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'feedback',
    gaEventAction: 'inicio',
    gaEventLabel: '',
  });
}

export function faqTagManager(question) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'faq',
    gaEventAction: 'click',
    gaEventLabel: question,
  });
}

export function invoiceCardTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'faturas',
    gaEventAction: 'mostrar detalhes',
    gaEventLabel: '',
  });
}

export function invoiceUnpaidCardTagManager(paymentMethod) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'faturas',
    gaEventAction: 'pagar fatura',
    gaEventLabel: paymentMethod,
  });
}

export function outGoingTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Outgoing link',
    gaEventAction: 'click',
    gaEventLabel: '',
  });
}

export function mySitesTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'meus sites',
    gaEventAction: 'click',
    gaEventLabel: 'gerenciar site',
  });
}

export function domainsTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'dominios',
    gaEventAction: 'click',
    gaEventLabel: 'gerenciar dominio',
  });
}

export function emailsTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mails',
    gaEventAction: 'click',
    gaEventLabel: 'gerenciar e-mail',
  });
}

export function emailVerifySkipFlowTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'click',
    gaEventLabel: 'fechar_validacao_email',
  });
}

export function emailVerifyResendLinkTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'click',
    gaEventLabel: 'reenviar_confirmacao_email',
  });
}

export function changeEmailTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'click',
    gaEventLabel: 'alterar_email',
  });
}

export function freePlanNoRegistredTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'click',
    gaEventLabel: 'Redirecionado Site HG Pg de Compra de Dominio',
  });
}

function getNamePlan(planId) {
  if (planId === 421) {
    return 'Pedido Gerado Titan Essential';
  } if (planId === 424) {
    return 'Pedido Gerado Titan Premium';
  } if (planId === 198810) {
    return 'Cpanel Webmail';
  }
  return 'Free reativado';
}

export function reativateWithHostingPlanTagManager(planId, eventAction) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: `Com_host_${eventAction}`,
    gaEventLabel: `${getNamePlan(planId)}`,
  });
}

export function reativateWithoutHostingPlanTagManager(planId, eventAction) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: `Sem_host_Oferta${eventAction}`,
    gaEventLabel: `${getNamePlan(planId)}`,
  });
}

export function offerPlanWithDomainNoRegisteredTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'Com_host_Oferta de Planos Titan Pagos/Reativação de Plano Free Domínio Não registrado',
    gaEventLabel: 'Redirecionado Site HG Pg de Compra de Dominio',
  });
}

export function cPanelWebmailTagManager(planId) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'Setup de plataforma de e-mail grátis da hospedagem',
    gaEventLabel: `${getNamePlan(planId)}`,
  });
}

export function executeWithParams(event, gaEventCategory, gaEventAction, gaEventLabel) {
  push({
    event: `${event}`,
    gaEventCategory: `${gaEventCategory}`,
    gaEventAction: `${gaEventAction}`,
    gaEventLabel: `${gaEventLabel}`,
  });
}

export function executeUniqueEvent(event) {
  push({
    event: `${event}`,
  });
}

export function domainWithoutHostingPlanTagManager(planId) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'e-mail',
    gaEventAction: 'click',
    gaEventLabel: `Domain_Without_Plan_${getNamePlan(planId)}`,
  });
}

export function phoneNumberVerifySkipFlowTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'telefone',
    gaEventAction: 'click',
    gaEventLabel: 'fechar_validacao_telefone',
  });
}

export function changePhoneNumberTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'telefone',
    gaEventAction: 'click',
    gaEventLabel: 'alterar_telefone',
  });
}

export function phoneNumberSendVerificationCodeTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'telefone',
    gaEventAction: 'click',
    gaEventLabel: 'enviar_codigo_telefone',
  });
}

export function phoneNumberCodeValidTagManager() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'telefone',
    gaEventAction: 'click',
    gaEventLabel: 'validar_codigo_telefone',
  });
}

export function zoneManagerDomainChoice(value) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'dropdown_choose_your_domain',
    gaEventAction: 'change_domain',
    gaEventLabel: value,
  });
}

export function zoneManagerChangeDns(value) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'name_server',
    gaEventAction: 'save_name_server',
    gaEventLabel: value,
  });
}

export function mktPlaceAcquireButton({ name, menu }) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Marketplace',
    gaEventAction: menu ? 'Menu' : 'CTA cards',
    gaEventLabel: menu ? `Page ${name}` : `Button ${name}`,
  });
}

export function zendeskChatTag() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'zendesk',
    gaEventAction: 'click_zendesk_chat',
    gaEventLabel: 'Open zendesk chat',
  });
}

export function visualizeNFSeTagManager(isJuridicPerson) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'faturas',
    gaEventAction: 'visualizar_nfse',
    gaEventLabel: isJuridicPerson ? 'Juridica' : 'Fisica',
  });
}

export function sitelockMenuButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'palavra_seguranca_menu',
  });
}

export function sitelockProductButtonGerenciar(value) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'clique_botao_gerenciar',
    gaEventLabel: value,
  });
}

export function sitelockButtonBuy() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'botao_compras_sitelock_LP_portal',
  });
}

export function codeguardButtonBuy() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'botao_compra_codeguard',
  });
}

export function doSweepSitelock(event) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click_varredura',
    gaEventLabel: event,
  });
}

export function detailsSweepSitelock(event) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click_detalhes_varredura',
    gaEventLabel: event,
  });
}

export function downloadSweepSitelock(event) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click_sitelock_download_seguranca',
    gaEventLabel: event,
  });
}

export function menuHgAcademyClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click',
    gaEventLabel: 'click_academy_menu',
  });
}

export function menuAsaasClick(label) {
  const cleanLabel = label.toLowerCase().replace(/ /g, '_');
  push({
    event: 'gaEvent',
    gaEventCategory: cleanLabel,
    gaEventAction: 'click',
    gaEventLabel: `click_${cleanLabel}_menu`,
  });
}

export function bannerHgAcademyClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click',
    gaEventLabel: 'click_academy_banner',
  });
}

export function hgAcademyListLoad() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'pageview',
    gaEventLabel: 'lista_academy_cursos',
  });
}

export function watchHgAcademyClick(courseName) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'lista_de_curso',
    gaEventLabel: courseName,
  });
}

export function getHgAcademyClick(courseName) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'obter_academy_pass',
    gaEventLabel: courseName,
  });
}

export function cancelBuyPayPalByPortalClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click_modal_assinatura',
    gaEventLabel: 'click_cancelar_compra',
  });
}

export function cancelBuyCCByPortalClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click_modal_assinatura',
    gaEventLabel: 'click_limpar_dados_cc',
  });
}

export function keepWatchingCourseClick(courseName) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'continuar_assistindo',
    gaEventLabel: courseName,
  });
}


export function watchAgainCourseClick(courseName) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'assistir_novamente',
    gaEventLabel: courseName,
  });
}

export function paymentMethodChangeEvent(paymentMethod) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'assinar',
    gaEventLabel: paymentMethod,
  });
}

export function subscribeAcademyClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click',
    gaEventLabel: 'click_adquirir_topo',
  });
}

export function subscribeAcademyFooterClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click',
    gaEventLabel: 'click_adquirir_rodape',
  });
}

export function academyPassVideoPlayClick() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'click',
    gaEventLabel: 'click_video_academy_oferta',
  });
}

export function subscribeAcademyPassLpLoad() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Academy_Portal',
    gaEventAction: 'pageview',
    gaEventLabel: 'lp_oferta_academy',
  });
}

export function cardCobreFacilClickButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Marketplace',
    gaEventAction: 'click',
    gaEventLabel: 'Button Cobrefacil',
  });
}

export function cardTraktoClickButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Marketplace',
    gaEventAction: 'click',
    gaEventLabel: 'Button Trakto',
  });
}

export function cardCreditasClickButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Marketplace',
    gaEventAction: 'CTA cards',
    gaEventLabel: 'Button Creditas',
  });
}

export function buttonFooterLPSitelock() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'sitelock_compre_agora_LP_bottom',
  });
}

export function cobreFacilTopBanner() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Topbanner',
    gaEventAction: 'click',
    gaEventLabel: 'Cobrefácil',
  });
}

export function traktoTopBanner() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Topbanner',
    gaEventAction: 'click',
    gaEventLabel: 'Trakto',
  });
}


export function siteLockCycleType(cycleType) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: `sitelock_${cycleType}`,
  });
}

export function siteLockPaymentMethod(paymentMethod) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: `sitelock_${paymentMethod}`,
  });
}

export function siteLockBuyButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'sitelock_compre_agora_LP_topo',
  });
}

export function siteLockGetButton() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'sitelock_compre_agora_LP_bottom',
  });
}

export function sitelockSelectHostedDomain() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'sitelock_seletor_dominios_LP',
  });
}

export function sitelockSelectExternalDomain() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'sitelock_digitar_dominios_LP',
  });
}

export function bottomBannerPartnersClick(eventLabel) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Bottombanner',
    gaEventAction: 'click',
    gaEventLabel: eventLabel,
  });
}

export function menuMktPlaceVersion(version) {
  let gaVersion = '';
  switch (version) {
    case 'TS_v1':
      gaVersion = 'menuv1';
      break;
    case 'TS_v2':
      gaVersion = 'menuv2';
      break;
    case 'TS_v3':
      gaVersion = 'menuv3';
      break;
    case 'TS_v4':
      gaVersion = 'menuv4';
      break;
    case 'off':
      gaVersion = 'menuv4';
      break;
    default:
      gaVersion = 'menuUnknowVersion';
  }

  push({
    event: 'gaEvent',
    gaEventCategory: 'Marketplace',
    gaEventAction: 'click',
    gaEventLabel: gaVersion,
  });
}

export function codeguardPaymentMethodLP(paymentMethod) {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: `codeguard_${paymentMethod}`,
  });
}

export function codeguardButtonBuyLP() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'codeguard_compre_agora_LP_topo',
  });
}

export function codeGuardButtonFooter() {
  push({
    event: 'gaEvent',
    gaEventCategory: 'Seguranca_portalcliente',
    gaEventAction: 'click',
    gaEventLabel: 'codeguard_compre_agora_LP_bottom',
  });
}

export const executeGADomainSelectionActiveTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail sem domínios',
    'Seleção de domínio',
  );
};

export const executeGAInitTrial = (quantity = 1) => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail sem domínios',
    `Ativar trial - ${quantity} ${quantity > 1 ? 'caixas' : 'caixa'}`,
  );
};

export const executeGATermsActiveTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail sem domínios',
    'Termos e Condições',
  );
};

export const executeGADisplayKeyPasswordActiveTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail sem domínios',
    'Visualização de senha',
  );
};

export const executeGADomainSelectionActiveTrialWithDomain = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail com domínios',
    'Seleção de domínio',
  );
};

export const executeGAInitTrialWithDomain = (quantity = 1) => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail com domínios',
    `Ativar trial - ${quantity} ${quantity > 1 ? 'caixas' : 'caixa'}`,
  );
};

export const executeGATermsActiveTrialWithDomain = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail com domínios',
    'Termos e Condições',
  );
};

export const executeGADisplayBannerWithDomains = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail com domínios',
    'Exibição banner menor',
  );
};

export const executeGADisplayKeyPasswordActiveTrialWithDomain = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail com domínios',
    'Visualização de senha',
  );
};

export const executeGADisplayBannerWithoutDomains = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - lista de e-mail sem domínios',
    'Exibição banner maior',
  );
};

export const executeGAToastTitanToastDisplay = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Toast - ativação Titan em Meus Sites',
    'Exibição toast',
  );
};

export const executeGAToastCloseToastClick = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Toast - ativação Titan em Meus Sites',
    'Fechar toast',
  );
};

export const executeGAToastTitanDisplayPlans = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Toast - ativação Titan em Meus Sites',
    'Ver planos disponíveis',
  );
};

export const executeGABottomBannerDarkTitanBannerDisplay = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner Dark - ativação Titan em Meus Sites',
    'Exibição banner dark',
  );
};

export const executeGABottomBannerDarkTitanBannerDisplayPlans = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner Light - ativação Titan em Meus Sites',
    'Ver planos disponíveis',
  );
};

export const executeGABottomBannerLightTitanBannerDisplay = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner Light - ativação Titan em Meus Sites',
    'Exibição banner light',
  );
};

export const executeGABottomBannerLightTitanBannerDisplayPlans = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner Light - ativação Titan em Meus Sites',
    'Ver planos disponíveis',
  );
};


export const executeGADisplayBannerTrialInSites = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - Sites',
    'Exibição banner',
  );
};


export const sitesHandlerGa = ({
  gaEventAction,
  gaEventLabel,
}) => {
  push({
    event: 'gaEvent',
    gaEventCategory: 'sites',
    gaEventAction,
    gaEventLabel,
  });
};

export const executeGADomainSelectionActiveTrialInSites = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - Sites',
    'Selecionar domínio',
  );
};

export const executeGATermsActiveTrialInSites = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - Sites',
    'Termos e Condições',
  );
};

export const executeGADisplayKeyPasswordActiveTrialInSites = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - Sites',
    'Visualização da senha',
  );
};

export const executeGADisplayKeyPasswordActiveTrialInDomains = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - aba de Domínios',
    'Visualização da senha - banner Domínios',
  );
};

export const executeGADisplayModalTrialInHirePlan = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Exibição modal de trial - Setup de plataforma com Trial',
  );
};

export const executeGACloseInModalHirePlan = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Fechar modal - Setup de plataforma com Trial',
  );
};

export const executeGATermsActiveTrialInModalHirePlan = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Termos e Condições - Setup de plataforma com Trial',
  );
};

export const executeGADisplayKeyPasswordActiveTrialInModalHirePlan = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Visualização da senha modal - Setup de plataforma com Trial',
  );
};

export const executeGACheckBoxInModalHirePlan = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Check Modal - Setup de plataforma com Trial',
  );
};

export const renderEmailPlatformSelectionPageWithTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Exibição tela Setup de plataforma com Trial',
  );
};

export const hireTitanFreeWithTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Titan Free',
  );
};

export const hireTitanEssentialsTrialWithTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Titan Essentials Trial',
  );
};

export const hireTitanPremiumWithTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Titan Essentials Trial',
  );
};

export const hireTitanCPanelWebmailWithTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Cpanel Webmail',
  );
};

export const executeGADisplayBannerFromDomains = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Banner trial de Titan - aba de Domínios',
    'Exibição banner',
  );
};

export const executeGAContractNewPlanInListEmails = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Listagem de e-mail',
    'Contratar novo plano',
  );
};

export const executeGAInitTrialInLocation = (location, quantity = 1) => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    `Modal de ativação de múltiplas caixas de trial - banner em ${location}`,
    `Ativar trial - ${quantity} ${quantity > 1 ? 'caixas' : 'caixa'}`,
  );
};

export const executeGAAddMoreTitanBoxes = (location, quantity) => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    `Modal de ativação de múltiplas caixas de trial - ${location}`,
    `Adicionar caixa ${quantity}`,
  );
};

export const executeGAExcludeTitanBoxes = (location, quantity) => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    `Modal de ativação de múltiplas caixas de trial - ${location}`,
    `Excluir caixa ${quantity}`,
  );
};

export const generateInvoiceTitanPremium = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Titan Premium',
  );
};

export const executeGAHireTitanTrial = () => {
  executeWithParams(
    'gaEvent',
    'e-mail',
    'Setup de plataforma de e-mail com Trial de Titan',
    'Iniciar trial modal - Setup de plataforma com Trial',
  );
};
