import styled, { css } from 'styled-components';

export const BannerWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.white};
    border-radius: 4px;
    box-shadow: 0px 2px 5px #0000001a;
    display: flex;
    padding: 8px;
    position: relative;
    width: 100%;
    height: 128px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 30px;
      height: 100%;
    }

    .assetTriangle {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      .assetTriangle {
        left: auto;
        right: 8px;
        transform: rotate(90deg);
      }
    }
  `}
`;

export const Figure = styled.figure`
  ${({ theme }) => css`
    position: relative;
    .snappy {
      position: relative;
      bottom: 12px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const ContentSection = styled.section`
  padding:0;
`;

export const BannerTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 28px 0 20px;

    p {
      margin: 0px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 0 20px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.astronaut};
    font-size: ${theme.font.sizes.lg};
    font-weight: 400;
    letter-spacing: 0.01px;
    margin: 0px;
  `}
`;

export const TagBanner = styled.span`
  ${({ theme }) => css`
    background-color: ${`${theme.palette.grey.primary}66`};
    color: ${theme.palette.success.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    margin-left: 12px;
    padding: 6px;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-self: flex-start;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 0;
    }
  `}
`;

export const SelectContainer = styled.div`
  ${({ theme }) => css`
    width: 280px;

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;
