import React, { useContext, useRef } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles,
} from '@material-ui/core';
import HeaderItems from '@/components/Dns/ZoneManager/RecordList/DesktopRecordList/HeaderItems';
import List from '@/components/Dns/ZoneManager/RecordList/DesktopRecordList/List';
import { DNSContext } from '@/contexts/DNS/context';
import EmptyList from '@/components/Dns/ZoneManager/RecordList/EmptyList';
import styles from './styles';

const MobileRecordList = ({ classes }) => {
  const { renderedRecordedItems } = useContext(DNSContext);
  const tableRef = useRef();
  const hasRenderedItems = renderedRecordedItems && renderedRecordedItems.length > 0;

  return (
    <div className={classes.tableWrapper} ref={tableRef}>
      <HeaderItems />
      {hasRenderedItems
        ? <List />
        : <EmptyList />
      }
    </div>
  );
};


export default (withI18n()((withStyles(styles)(MobileRecordList))));
