import styled, { css } from 'styled-components';

export const Triangle = styled.div`
  ${({ theme }) => css`
    width: 0; 
    height: 0; 
    border-top: 10px solid ${theme.v1.color.border.primary};
    border-bottom: 10px solid transparent; 
    border-left: 10px solid ${theme.v1.color.border.primary};
    border-right: 10px solid transparent;
  `}
`;

export const Header = styled.h1`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.action.hover};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.medium};
    gap: 16px;
    grid-area: header;
    margin: 0;
    white-space: nowrap;
  `}
`;

export const Domain = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v1.color.line.fields};
    min-width: 300px;
    max-width: 100%;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.regular};
    grid-area: domain;
    padding: 0px 9px 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const DomainHeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
    max-width: 100%;
    grid-template-areas: 
    "header"
    "domain";
    overflow: hidden;

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: flex
    }
  `}
`;
