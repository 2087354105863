export const calcLabelWidth = (text) => {
  const lenghtText = text.length;
  switch (lenghtText) {
    case 16:
      return parseInt(text.length * 8, 10);
    case 30:
      return parseInt(text.length * 7.6, 10);
    default:
      return parseInt(text.length * 8.7, 10);
  }
};
