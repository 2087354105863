const styles = theme => ({
  tabWrapper: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '5px',
    height: '369px',
    padding: '12px 0px',

    [theme.breakpoints.down(960)]: {
      border: 0,
      height: 'unset',
      padding: 0,
    },
  },

  tabContainer: {
    boxShadow: '0px 1px 3px #00000029',
    margin: '0px 12px 12px 12px',

    '& > div > div > span': {
      height: '5px',
    },

    [theme.breakpoints.down(960)]: {
      margin: '0px 0px 12px 0px',
    },
  },

  tabTitle: {
    color: theme.color.gray,
    fontSize: '14px',
    fontWeight: 500,
    height: '60px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    opacity: 1,
    transition: '300ms',
  },

  activeTab: {
    color: theme.color.tundora,
  },

  listItemsWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginRight: '12px',
    maxHeight: '285px',
    overflow: 'hidden scroll',
    padding: '0px 12px',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.color.silver,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: theme.color.indigo,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.indigo,
    },

    [theme.breakpoints.down(960)]: {
      overflow: 'unset',
      maxHeight: 'unset',
      flex: 1,
      margin: 0,
    },
  },
});

export default styles;
