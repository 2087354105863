import useLocale from '@/hooks/useLocale/useLocale';
import * as Styles from './ProductsList.styles';
import React from 'react';
import {
  Button, IconCodeGuard, IconSecurity, PageTitle, Skeleton, Tag,
} from 'gatorcomponents';
import { format } from 'date-fns';

const ProductsList = ({
  handleManageProductClick,
  productsListFiltered,
  getPageTitleProps,
  productsLoading,
  getStatus,
}) => {
  const { security: securityLocale } = useLocale();

  const handleManageProduct = (product) => {
    handleManageProductClick && handleManageProductClick(product);
  };

  return (
    <>
      <Styles.FilterWrapper>
        <PageTitle {...getPageTitleProps()} />
      </Styles.FilterWrapper>

      <Styles.ProductsListWrapper>
        <Styles.ProductListDescription>
          {securityLocale.productsList.description}
        </Styles.ProductListDescription>
        <Styles.Divider />

        {productsLoading && (
          <Styles.ProductsListLoading>
            <Skeleton variant="tableSimple" />
          </Styles.ProductsListLoading>
        )}

        {!productsLoading && (
          <Styles.ProductListWrapper>
            { productsListFiltered && !!productsListFiltered.length && productsListFiltered.map(product => (
              <Styles.Product key={product.id}>
                <Styles.ProductData data-testid={`product-${product.id}`}>
                  <Styles.ProductIdentify>
                    {product.name.toLowerCase().includes('sitelock') ? <IconSecurity /> : <IconCodeGuard />}
                    <Styles.ProductTitle>
                      {product.name}
                      <Styles.ProductDomain>{product.domain}</Styles.ProductDomain>
                    </Styles.ProductTitle>
                  </Styles.ProductIdentify>
                  <Styles.TagWrapper>
                    <Tag label={securityLocale.productsList.status[product.status]} variant={getStatus(product.status)} />
                  </Styles.TagWrapper>
                  {product.due_date && (
                  <Styles.DueDate>
                    {format(new Date(`${product.due_date} 00:00:00`), 'dd/MM/yyyy')}
                    <Styles.DueText>
                      {securityLocale.productsList.due}
                    </Styles.DueText>
                  </Styles.DueDate>
                  )}
                  <Styles.ButtonWrapper>
                    {product.status === 'Active' && (
                    <Button
                      label={securityLocale.productsList.manage}
                      onClick={() => handleManageProduct(product)}
                      variant="secondary"
                    />
                    )}
                  </Styles.ButtonWrapper>
                </Styles.ProductData>

                <Styles.Divider />
              </Styles.Product>
            ))}
          </Styles.ProductListWrapper>
        )}
      </Styles.ProductsListWrapper>
    </>
  );
};

export default ProductsList;
