import { useCostumerPortalSurveyLocale } from './useCostumerPortalSurvey.locale';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sitesActions } from '@/redux/modules';
import { SURVEY_TYPE } from './useCostumerPortalSurvey.enum';
import { registerCustomerProfileSurvey } from '@/redux/actions/customerProfileSurvey';
import { COUNTRY, costumerPortalSurvey } from '@/config';
import { costumerPortalSurveyAnalytics } from '@/analytics';

const useCostumerPortalSurvey = () => {
  const dispatch = useDispatch();
  const initialQuestionsAnswered = useSelector(state => state.sites.questionsAnswered);
  const { id: userId } = useSelector(state => state.summary);

  const [surveyQuestionsAnswered, setSurveyQuestionsAnswered] = useState({ ids: [], answers: {} });
  const [shouldRenderSurvey, setShouldRenderSurvey] = useState(false);

  const optionsFirstQuestion = [
    { id: 'option-1', label: useCostumerPortalSurveyLocale.questions.question1.options.op1, value: useCostumerPortalSurveyLocale.questions.question1.options.op1 },
    { id: 'option-2', label: useCostumerPortalSurveyLocale.questions.question1.options.op2, value: useCostumerPortalSurveyLocale.questions.question1.options.op2 },
    { id: 'option-3', label: useCostumerPortalSurveyLocale.questions.question1.options.op3, value: useCostumerPortalSurveyLocale.questions.question1.options.op3 },
  ];

  const optionsSecondQuestion = [
    { id: 'option-1', label: useCostumerPortalSurveyLocale.questions.question2.options.op1, value: useCostumerPortalSurveyLocale.questions.question2.options.op1 },
    { id: 'option-2', label: useCostumerPortalSurveyLocale.questions.question2.options.op2, value: useCostumerPortalSurveyLocale.questions.question2.options.op2 },
    { id: 'option-3', label: useCostumerPortalSurveyLocale.questions.question2.options.op3, value: useCostumerPortalSurveyLocale.questions.question2.options.op3 },
    { id: 'option-4', label: useCostumerPortalSurveyLocale.questions.question2.options.op4, value: useCostumerPortalSurveyLocale.questions.question2.options.op4 },
  ].sort(() => Math.random() - 0.5);

  const optionsThirdQuestion = [
    { id: 'option-1', label: useCostumerPortalSurveyLocale.questions.question3.options.op1, value: useCostumerPortalSurveyLocale.questions.question3.options.op1 },
    { id: 'option-2', label: useCostumerPortalSurveyLocale.questions.question3.options.op2, value: useCostumerPortalSurveyLocale.questions.question3.options.op2 },
    { id: 'option-3', label: useCostumerPortalSurveyLocale.questions.question3.options.op3, value: useCostumerPortalSurveyLocale.questions.question3.options.op3 },
    { id: 'option-4', label: useCostumerPortalSurveyLocale.questions.question3.options.op4, value: useCostumerPortalSurveyLocale.questions.question3.options.op4 },
    { id: 'option-5', label: useCostumerPortalSurveyLocale.questions.question3.options.op5, value: useCostumerPortalSurveyLocale.questions.question3.options.op5 },
    { id: 'option-6', label: useCostumerPortalSurveyLocale.questions.question3.options.op6, value: useCostumerPortalSurveyLocale.questions.question3.options.op6 },
    { id: 'option-7', label: useCostumerPortalSurveyLocale.questions.question3.options.op7, value: useCostumerPortalSurveyLocale.questions.question3.options.op7 },
    { id: 'option-8', label: useCostumerPortalSurveyLocale.questions.question3.options.op8, value: useCostumerPortalSurveyLocale.questions.question3.options.op8 },
    { id: 'option-9', label: useCostumerPortalSurveyLocale.questions.question3.options.op9, value: useCostumerPortalSurveyLocale.questions.question3.options.op9 },
    { id: 'option-10', label: useCostumerPortalSurveyLocale.questions.question3.options.op10, value: useCostumerPortalSurveyLocale.questions.question3.options.op10 },
    { id: 'option-11', label: useCostumerPortalSurveyLocale.questions.question3.options.op11, value: useCostumerPortalSurveyLocale.questions.question3.options.op11 },
    { id: 'option-12', label: useCostumerPortalSurveyLocale.questions.question3.options.op12, value: useCostumerPortalSurveyLocale.questions.question3.options.op12 },
    { id: 'option-13', label: useCostumerPortalSurveyLocale.questions.question3.options.op13, value: useCostumerPortalSurveyLocale.questions.question3.options.op13 },
    { id: 'option-14', label: useCostumerPortalSurveyLocale.questions.question3.options.op14, value: useCostumerPortalSurveyLocale.questions.question3.options.op14 },
    { id: 'option-15', label: useCostumerPortalSurveyLocale.questions.question3.options.op15, value: useCostumerPortalSurveyLocale.questions.question3.options.op15 },
    { id: 'option-16', label: useCostumerPortalSurveyLocale.questions.question3.options.op16, value: useCostumerPortalSurveyLocale.questions.question3.options.op16 },
    { id: 'option-17', label: useCostumerPortalSurveyLocale.questions.question3.options.op17, value: useCostumerPortalSurveyLocale.questions.question3.options.op17 },
    { id: 'option-18', label: useCostumerPortalSurveyLocale.questions.question3.options.op18, value: useCostumerPortalSurveyLocale.questions.question3.options.op18 },
    { id: 'option-19', label: useCostumerPortalSurveyLocale.questions.question3.options.op19, value: useCostumerPortalSurveyLocale.questions.question3.options.op19 },
    { id: 'option-20', label: useCostumerPortalSurveyLocale.questions.question3.options.op20, value: useCostumerPortalSurveyLocale.questions.question3.options.op20 },
  ].sort(() => Math.random() - 0.5);

  const optionsFourthQuestion = [
    { id: 'option-1', label: useCostumerPortalSurveyLocale.questions.question4.options.op1, value: useCostumerPortalSurveyLocale.questions.question4.options.op1 },
    { id: 'option-2', label: useCostumerPortalSurveyLocale.questions.question4.options.op2, value: useCostumerPortalSurveyLocale.questions.question4.options.op2 },
    { id: 'option-3', label: useCostumerPortalSurveyLocale.questions.question4.options.op3, value: useCostumerPortalSurveyLocale.questions.question4.options.op3 },
    { id: 'option-4', label: useCostumerPortalSurveyLocale.questions.question4.options.op4, value: useCostumerPortalSurveyLocale.questions.question4.options.op4 },
  ].sort(() => Math.random() - 0.5);

  const survey = useMemo(() => ({
    title: useCostumerPortalSurveyLocale.title,
    explanation: useCostumerPortalSurveyLocale.explanation,
    welcome: useCostumerPortalSurveyLocale.welcome,
    startLabel: useCostumerPortalSurveyLocale.start,
    questions: [
      {
        id: 1,
        name: 'typePerson',
        label: useCostumerPortalSurveyLocale.questions.question1.label,
        skip: {
          skippable: true,
          skipLabel: useCostumerPortalSurveyLocale.jump,
        },
        sendLabel: useCostumerPortalSurveyLocale.send,
        selectPlaceholder: useCostumerPortalSurveyLocale.selectPlaceholder,
        options: [...optionsFirstQuestion],
        otherOption: {
          displayInput: false,
          inputPlaceholder: '',
        },
      },
      {
        id: 2,
        name: 'typeProfile',
        label: useCostumerPortalSurveyLocale.questions.question2.label,
        skip: {
          skippable: true,
          skipLabel: useCostumerPortalSurveyLocale.jump,
        },
        sendLabel: useCostumerPortalSurveyLocale.send,
        selectPlaceholder: useCostumerPortalSurveyLocale.selectPlaceholder,
        options: [
          ...optionsSecondQuestion,
          { id: 'option-5', label: useCostumerPortalSurveyLocale.questions.question2.options.op5, value: 'other' },
        ],
        otherOption: {
          displayInput: true,
          inputPlaceholder: useCostumerPortalSurveyLocale.otherInputPlaceholder,
        },
      },
      {
        id: 3,
        name: 'typeBusiness',
        label: useCostumerPortalSurveyLocale.questions.question3.label,
        skip: {
          skippable: true,
          skipLabel: useCostumerPortalSurveyLocale.jump,
        },
        sendLabel: useCostumerPortalSurveyLocale.send,
        selectPlaceholder: useCostumerPortalSurveyLocale.selectPlaceholder,
        options: [
          ...optionsThirdQuestion,
          { id: 'option-21', label: useCostumerPortalSurveyLocale.questions.question3.options.op21, value: 'other' },
        ],
        otherOption: {
          displayInput: true,
          inputPlaceholder: useCostumerPortalSurveyLocale.otherInputPlaceholder,
        },
      },
      {
        id: 4,
        name: 'forWho',
        label: useCostumerPortalSurveyLocale.questions.question4.label,
        skip: {
          skippable: true,
          skipLabel: useCostumerPortalSurveyLocale.jump,
        },
        sendLabel: useCostumerPortalSurveyLocale.send,
        selectPlaceholder: useCostumerPortalSurveyLocale.selectPlaceholder,
        options: [...optionsFourthQuestion],
        otherOption: {
          displayInput: false,
          inputPlaceholder: '',
        },
      },
    ],
    congrats: useCostumerPortalSurveyLocale.congrats,
    finalText: useCostumerPortalSurveyLocale.finalText,
    finishLabel: useCostumerPortalSurveyLocale.finishLabel,
    counterLabel: useCostumerPortalSurveyLocale.counterLabel,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  useEffect(() => {
    dispatch(sitesActions.surveyQuestionsAnswered.request(costumerPortalSurvey[COUNTRY]));
  }, [dispatch]);

  useEffect(() => {
    const canRenderSurvey = initialQuestionsAnswered.ids.length !== survey.questions.length;
    setShouldRenderSurvey(canRenderSurvey);
  }, [survey.questions, initialQuestionsAnswered]);

  useEffect(() => {
    setSurveyQuestionsAnswered(initialQuestionsAnswered);
  }, [initialQuestionsAnswered]);

  const handleFinishSurvey = () => {
    setShouldRenderSurvey(false);
    costumerPortalSurveyAnalytics.surveyFinish();
  };

  const handleSendQuestion = (answers, questionId) => {
    const payload = {
      answers,
      country: COUNTRY,
      user_id: userId.toString(),
      hosting_id: `user-${userId}`,
      domain: `user-${userId}`,
    };
    dispatch(registerCustomerProfileSurvey({ data: payload, type: SURVEY_TYPE }));
    costumerPortalSurveyAnalytics.surveyQuestionSend(questionId);
  };

  const handleClose = (step) => {
    costumerPortalSurveyAnalytics.surveyClose(step);
  };

  const handleJumpQuestion = (questionId) => {
    costumerPortalSurveyAnalytics.surveyQuestionJump(questionId);
  };

  const handleStartSurvey = () => {
    costumerPortalSurveyAnalytics.surveyStart();
  };

  const shouldRenderOpened = surveyQuestionsAnswered.ids && !surveyQuestionsAnswered.ids.length > 0;

  const useCostumerPortalSurveyData = {
    survey,
    handleFinishSurvey,
    surveyQuestionsAnswered,
    setSurveyQuestionsAnswered,
    shouldRenderSurvey,
    shouldRenderOpened,
    handleSendQuestion,
    handleStartSurvey,
    handleClose,
    handleJumpQuestion,
  };
  return useCostumerPortalSurveyData;
};

export default useCostumerPortalSurvey;
