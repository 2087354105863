export const BillingActionTypes = {
  DEFAULT: 'BILLING_DEFAULT',
  GET_SUBCRIPTIONS_QUESTIONS_FAILURE: 'GET_SUBCRIPTIONS_QUESTIONS_FAILURE',
  GET_SUBCRIPTIONS_QUESTIONS_REQUEST: 'GET_SUBCRIPTIONS_QUESTIONS_REQUEST',
  GET_SUBCRIPTIONS_QUESTIONS_SUCCESS: 'GET_SUBCRIPTIONS_QUESTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_FAILURE: 'GET_SUBSCRIPTIONS_FAILURE',
  GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
  SET_SUBSCRIPTION_REFUND_METHOD_SELECTED: 'SET_SUBSCRIPTION_REFUND_METHOD_SELECTED',
  SET_SUBSCRIPTIONS_CANCEL: 'SET_SUBSCRIPTIONS_CANCEL',
  SET_SUBSCRIPTIONS_QUESTION: 'SET_SUBSCRIPTIONS_QUESTION',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
  SET_LOADING_SUBSCRIPTIONS_STATUS: 'SET_LOADING_SUBSCRIPTIONS_STATUS',

  GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: 'GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD',
  SUCCESS_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: 'SUCCESS_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD',
  FAILURE_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: 'FAILURE_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD',
  SET_LOADING_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: 'SET_LOADING_SUBSCRIPTIONS_NO_IN_CREDIT_CARD',
  SET_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: 'SET_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD',
  UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: 'UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD',
  SET_LOADING_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: 'SET_LOADING_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD',
  SUCCESS_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: 'SUCCESS_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD',
  FAILURE_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: 'FAILURE_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD',

  SUBSCRIPTION_CANCEL_RENEWAL_FAILURE: 'SUBSCRIPTION_CANCEL_RENEWAL_FAILURE',
  SUBSCRIPTION_CANCEL_RENEWAL_REQUEST: 'SUBSCRIPTION_CANCEL_RENEWAL_REQUEST',
  SUBSCRIPTION_CANCEL_RENEWAL_SUCCESS: 'SUBSCRIPTION_CANCEL_RENEWAL_SUCCESS',

  SUBSCRIPTION_CANCEL_SIGNATURE_SET: 'SUBSCRIPTION_CANCEL_SIGNATURE_SET',
  SUBSCRIPTION_CANCEL_SIGNATURE_FAILURE: 'SUBSCRIPTION_CANCEL_SIGNATURE_FAILURE',
  SUBSCRIPTION_CANCEL_SIGNATURE_REQUEST: 'SUBSCRIPTION_CANCEL_SIGNATURE_REQUEST',
  SUBSCRIPTION_CANCEL_SIGNATURE_SUCCESS: 'SUBSCRIPTION_CANCEL_SIGNATURE_SUCCESS',

  SUBSCRIPTION_ACTIVATE_RENEWAL_FAILURE: 'SUBSCRIPTION_ACTIVATE_RENEWAL_FAILURE',
  SUBSCRIPTION_ACTIVATE_RENEWAL_REQUEST: 'SUBSCRIPTION_ACTIVATE_RENEWAL_REQUEST',
  SUBSCRIPTION_ACTIVATE_RENEWAL_SUCCESS: 'SUBSCRIPTION_ACTIVATE_RENEWAL_SUCCESS',

  SUBSCRIPTION_REFUND_CHECK_FAILURE: 'SUBSCRIPTION_REFUND_CHECK_FAILURE',
  SUBSCRIPTION_REFUND_CHECK_REQUEST: 'SUBSCRIPTION_REFUND_CHECK_REQUEST',
  SUBSCRIPTION_REFUND_CHECK_SUCCESS: 'SUBSCRIPTION_REFUND_CHECK_SUCCESS',
  SET_SUBSCRIPTION_REFUND: 'SET_SUBSCRIPTION_REFUND',

  SUBSCRIPTION_SWITCH_PAYMENT_METHOD_REQUEST: 'SUBSCRIPTION_SWITCH_PAYMENT_METHOD_REQUEST',
  SUBSCRIPTION_SWITCH_PAYMENT_METHOD_SUCCESS: 'SUBSCRIPTION_SWITCH_PAYMENT_METHOD_SUCCESS',
  SUBSCRIPTION_SWITCH_PAYMENT_METHOD_FAILURE: 'SUBSCRIPTION_SWITCH_PAYMENT_METHOD_FAILURE',
  SUBSCRIPTION_SWITCH_PAYMENT_METHOD_CLEAR_STATUS: 'SUBSCRIPTION_SWITCH_PAYMENT_METHOD_CLEAR_STATUS',
  SUBSCRIPTION_SWITCH_PAYMENT_METHOD_LOADING: 'SUBSCRIPTION_SWITCH_PAYMENT_METHOD_LOADING',

  GET_INVOICE_PAYMENT_METHODS_REQUEST: 'GET_INVOICE_PAYMENT_METHODS_REQUEST',
  GET_INVOICE_PAYMENT_METHODS_FAILURE: 'GET_INVOICE_PAYMENT_METHODS_FAILURE',
  GET_INVOICE_PAYMENT_METHODS_SUCCESS: 'GET_INVOICE_PAYMENT_METHODS_SUCCESS',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',

  GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_REQUEST: 'GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_REQUEST',
  GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_FAILURE: 'GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_FAILURE',
  GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_SUCCESS: 'GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_SUCCESS',
  SET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT: 'SET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT',

  GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_REQUEST: 'GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_REQUEST',
  GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_FAILURE: 'GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_FAILURE',
  GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_SUCCESS: 'GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_SUCCESS',
  SET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER: 'SET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER',

  SET_LOADING_STATUS_ACCEPT_RETENTION_OFFER: 'SET_LOADING_STATUS_ACCEPT_RETENTION_OFFER',
  ACCEPT_RETENTION_OFFER_REQUEST: 'ACCEPT_RETENTION_OFFER_REQUEST',
  ACCEPT_RETENTION_OFFER_FAILURE: 'ACCEPT_RETENTION_OFFER_FAILURE',
  ACCEPT_RETENTION_OFFER_SUCCESS: 'ACCEPT_RETENTION_OFFER_SUCCESS',
  SET_ACCEPT_RETENTION_OFFER: 'SET_ACCEPT_RETENTION_OFFER',

  GET_DISCOUNT_VALUES_REQUEST: 'GET_DISCOUNT_VALUES_REQUEST',
  GET_DISCOUNT_VALUES_FAILURE: 'GET_DISCOUNT_VALUES_FAILURE',
  GET_DISCOUNT_VALUES_SUCCESS: 'GET_DISCOUNT_VALUES_SUCCESS',
  SET_DISCOUNT_VALUES: 'SET_DISCOUNT_VALUES',

  GET_HOST_BENEFIT_BILLING_REQUEST: 'GET_HOST_BENEFIT_BILLING_REQUEST',
  GET_HOST_BENEFIT_BILLING_FAILURE: 'GET_HOST_BENEFIT_BILLING_FAILURE',
  GET_HOST_BENEFIT_BILLING_SUCCESS: 'GET_HOST_BENEFIT_BILLING_SUCCESS',
  SET_HOST_BENEFIT_BILLING: 'SET_HOST_BENEFIT_BILLING',
  RESET_HOST_BENEFIT_BILLING: 'RESET_HOST_BENEFIT_BILLING',

  SET_SUBSCRIPTION_REACTIVATION_LOADING: 'SET_SUBSCRIPTION_REACTIVATION_LOADING',
  SUBSCRIPTION_REACTIVATION_REQUEST: 'SUBSCRIPTION_REACTIVATION_REQUEST',
  SUBSCRIPTION_REACTIVATION_SUCCESS: 'SUBSCRIPTION_REACTIVATION_SUCCESS',
  SUBSCRIPTION_REACTIVATION_FAILURE: 'SUBSCRIPTION_REACTIVATION_FAILURE',
  SET_SUBSCRIPTION_REACTIVATION: 'SET_SUBSCRIPTION_REACTIVATION',

  SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_REQUEST: 'SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_REQUEST',
  SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_FAILURE: 'SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_FAILURE',
  SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_SUCCESS: 'SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_SUCCESS',

  CHECK_HOSTING_ABLE_DISCOUNT_REQUEST: 'CHECK_HOSTING_ABLE_DISCOUNT_REQUEST',
  CHECK_HOSTING_ABLE_DISCOUNT_FAILURE: 'CHECK_HOSTING_ABLE_DISCOUNT_FAILURE',
  CHECK_HOSTING_ABLE_DISCOUNT_SUCCESS: 'CHECK_HOSTING_ABLE_DISCOUNT_SUCCESS',
  SET_CHECK_HOSTING_ABLE_DISCOUNT_DATA: 'SET_CHECK_HOSTING_ABLE_DISCOUNT_DATA',

};

export const SubscriptionsQuestionTypes = {
  CANCEL_RENEWAL: 'cancel-renewal',
  CANCEL_SIGNATURE: 'cancel-signature',
};
