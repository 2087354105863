import React from 'react';
import { Alert, Button } from 'gatorcomponents';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './ModalFrozenDomain.styles';
import useLocale from '@/hooks/useLocale';
import { isMobile } from '@/utils/Validators/validation';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preChatActions } from '@/redux/modules';
import { preChatAnalytics } from '@/analytics/preChat';

const ModalFrozenDomain = ({
  onCloseClick = null,
}) => {
  const history = useHistory();
  const { billing, routes } = useLocale();
  const dispatch = useDispatch();
  const preChatState = useSelector(state => state.preChat);
  const { holdDomainModal: holdDomainModalLocale } = billing.invoices;

  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
    dispatch(preChatActions.invoice.set.holdDomainRedirect(false));
    dispatch(preChatActions.preChat.setOpen(true));
    dispatch(preChatActions.invoice.set.invoiceId(null));
  };

  const goToInvoicePaymentModal = () => {
    dispatch(preChatActions.invoice.set.holdDomainRedirect(false));
    const unpaidInvoicePath = `${routes.invoices}${routes.unpaid}/${preChatState.invoiceId}`;
    preChatAnalytics.holdDomainModalPayInvoice();
    history.push(unpaidInvoicePath);
  };

  return (
    <Modal
      maxWidth={624}
      onClose={handleOnCloseClick}
      testId="hold-domain-modal"
      disablePadding
      fullScreen={isMobile}
    >
      <Styles.Content>
        <Styles.Header>
          <Styles.TitleModal data-testid="title-modal-hold-domain">{holdDomainModalLocale.title}</Styles.TitleModal>
        </Styles.Header>
        <Styles.ContentData>
          <Styles.Label data-testid="description-line1-modal-hold-domain">
            {holdDomainModalLocale.description1}
          </Styles.Label>
          <Styles.Label data-testid="description-line2-modal-hold-domain">
            {holdDomainModalLocale.description2(preChatState.productDetails.domain || '')}
          </Styles.Label>
          <Styles.AlertWrapper>
            <Alert variant="info" description={holdDomainModalLocale.alert} />
          </Styles.AlertWrapper>
        </Styles.ContentData>
        <Styles.Controls>
          <Button
            testId="cta-hold-domain-button"
            onClick={goToInvoicePaymentModal}
            label={holdDomainModalLocale.cta}
            size="large"
          />
        </Styles.Controls>
      </Styles.Content>
    </Modal>
  );
};


export default ModalFrozenDomain;
