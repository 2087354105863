import {
  REQUEST_ONBOARDING_DOMAINS, REQUEST_LOAD_ONBOARDING_SSO, REQUEST_SITECREATE_DISMISS,
  REQUEST_ONBOARDING_THEMES, RECEIVE_ONBOARDING_DOMAINS, RECEIVE_ONBOARDING_THEMES,
  RECEIVE_FILTERED_THEMES_BY_CATEGORIE, CLEAN_FILTERED_THEMES_BY_CATEGORIE, SELECTED_TEMPLATE_ID,
  CLEAN_SELECTED_TEMPLATE_ID, RECEIVE_LOAD_ONBOARDING_SSO, RECEIVE_SITECREATE_DISMISS,
  SET_ONBOARDING_TOOL, SET_ONBOARDING_PRODUCT, ERROR_ONBOARDING_DOMAINS,
  ERROR_ONBOARDING_THEMES, ERROR_LOAD_ONBOARDING_SSO, ERROR_SITECREATE_DISMISS, CLEAN_ONBOARDING_TOOL,
  DISABLE_ONBOARDING_SELECT_DOMAIN, RECEIVE_FILTERED_THEMES_BY_ID, RECEIVE_ONBOARDING_EMAIL_DATA,
  REQUEST_ONBOARDING_EMAIL_DATA, REQUEST_ONBOARDING_DNS, RECEIVE_ONBOARDING_DNS,
} from '@/redux/actions/actionsTypes';

const initialState = {
  loading: false,
  loadingThemes: false,
  loadingSso: false,
  loadingDns: false,
  products: [],
  productsLength: 0,
  themes: [],
  webSitebuilderTraditional: -1,
  filteredThemes: [],
  selectedTemplateId: -1,
  selectedTool: '',
  activateWebsitebuilderPerProduct: false,
  selectDomainDisabled: false,
  cpanel: {
    loading: false,
    limitEmailAccount: -1,
  },
  sso: {
    cpanel: '',
    express: '',
    tradicional: '',
    wordpress: '',
  },
  hostingDns: {
    ns1: '',
    ns2: '',
  },
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ONBOARDING_DOMAINS:
    case REQUEST_SITECREATE_DISMISS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ONBOARDING_THEMES:
      return {
        ...state,
        loadingThemes: true,
      };
    case RECEIVE_ONBOARDING_DOMAINS:
      return {
        ...state,
        loading: false,
        products: action.data,
        productsLength: action.data.length,
      };
    case RECEIVE_ONBOARDING_THEMES:
      return {
        ...state,
        loadingThemes: false,
        themes: action.data,
      };
    case RECEIVE_FILTERED_THEMES_BY_CATEGORIE:
      return {
        ...state,
        filteredThemes: action.data,
        selectedTemplateId: -1,
      };
    case RECEIVE_FILTERED_THEMES_BY_ID:
      return {
        ...state,
        filteredThemes: action.data,
      };
    case CLEAN_FILTERED_THEMES_BY_CATEGORIE:
      return {
        ...state,
        filteredThemes: [],
        selectedTemplateId: -1,
      };
    case SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.data,
      };
    case CLEAN_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: -1,
      };
    case REQUEST_LOAD_ONBOARDING_SSO:
      return {
        ...state,
        loadingSso: true,
      };
    case RECEIVE_LOAD_ONBOARDING_SSO:
      return {
        ...state,
        loadingSso: false,
        webSitebuilderTraditional: action.data.productId,
        sso: action.data.sso,
      };
    case RECEIVE_SITECREATE_DISMISS:
      return {
        ...state,
        loading: false,
        products: [],
      };
    case SET_ONBOARDING_TOOL:
      return {
        ...state,
        selectedTool: action.data,
      };
    case SET_ONBOARDING_PRODUCT:
      return {
        ...state,
        activateWebsitebuilderPerProduct: true,
        products: [
          ...state.products,
          action.data,
        ],
      };
    case CLEAN_ONBOARDING_TOOL:
      return {
        ...state,
        products: [],
      };
    case REQUEST_ONBOARDING_EMAIL_DATA:
      return {
        ...state,
        cpanel: {
          ...state.cpanel,
          loading: true,
        },
      };
    case RECEIVE_ONBOARDING_EMAIL_DATA:
      return {
        ...state,
        cpanel: {
          ...state.cpanel,
          loading: false,
          limitEmailAccount: action.limit,
        },
      };
    case DISABLE_ONBOARDING_SELECT_DOMAIN:
      return {
        ...state,
        selectDomainDisabled: true,
      };
    case REQUEST_ONBOARDING_DNS:
      return {
        ...state,
        loadingDns: true,
      };
    case RECEIVE_ONBOARDING_DNS:
      return {
        ...state,
        loadingDns: false,
        hostingDns: {
          ns1: action.ns1,
          ns2: action.ns2,
        },
      };
    case ERROR_ONBOARDING_DOMAINS:
    case ERROR_ONBOARDING_THEMES:
    case ERROR_LOAD_ONBOARDING_SSO:
    case ERROR_SITECREATE_DISMISS:
      return {
        ...state,
        loading: false,
        loadingThemes: false,
      };
    default:
      return state;
  }
}
