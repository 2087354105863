import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';


const NoResultMessageTrans = ({ classes, languageKey, urlTarget }) => (
  <Typography variant="caption" className={classes.message}>
    <Trans i18nKey={languageKey}>
      0
      <LinkButton target="blank" to={urlTarget} className={classes.linkMessage}>1</LinkButton>
      2
    </Trans>
  </Typography>
);

export default withI18n()(withStyles(styles)(NoResultMessageTrans));
