import styled, { css } from 'styled-components';

export const DnsWizardPlanCardWrapper = styled.div`
  ${({ selected, theme }) => css`
    padding: 16px;
    border: 1px solid ${theme.v1.color.border.primary};
    border-radius: 4px;
    display: flex;
    min-height: 122px;
    box-sizing: border-box;
    cursor: pointer;
    max-width: 100%;
    background-color: ${selected ? theme.v1.color.background.boxes : ''};
    &:hover {
      background-color: ${theme.v1.color.background.boxes};
    }
  `}
`;

export const DnsWizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: flex-start;
  gap: 8px;
`;

export const Plan = styled.p`
  ${({ theme }) => css`
    line-height: ${theme.font.lineHeights.lg};
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;
  `}
`;

export const MainDomain = styled.div`
  ${({ theme }) => css`
    margin: 0;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 12px;
    line-height: ${theme.font.lineHeights.lg};
    @media (min-width: ${theme.v1.breakpoints.md}) {
      & > br {
        display: none;
      }
    }
  `}
`;

export const OtherDomains = styled.div`
  ${({ theme }) => css`
    margin: 0;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 12px;
    line-height: ${theme.font.lineHeights.lg};
    @media (min-width: ${theme.v1.breakpoints.md}) {
      & > br {
        display: none;
      }
    }
  `}
`;

export const Icon = styled.div`
  margin-right: 8px;
  span, svg{
    max-width: 22px;
    max-height: 22px;
  }
`;
