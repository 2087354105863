const styles = theme => ({
  card: {
    width: '100%',
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    border: 'none',
    background: theme.color.white,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
});

export default styles;
