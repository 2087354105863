import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme, variant }) => {
    const variants = {
      withPix: {
        margin: '40px 0px',
      },
      default: {
        margin: '40px 0px',
      },
    };
    return css`
    background: ${theme.v1.color.background.wizard};
    margin: ${variants[variant].margin};
    padding: 24px 36px;
  `;
  }}
`;

export const ContentPixCode = styled.div`
${({ theme }) => css`
  margin: 24px 69px 0px 69px;
  padding: 18px 46px;
  text-align: center;

  @media (max-width: ${theme.v1.breakpoints.sm}) {
    margin: 0px;
    padding: 18px 0px;
  }
  `}
`;

export const ContentInputPixCode = styled.div`
  ${({ theme }) => css`
    padding: 0px 0px 10px 0px;

    div {
      border-radius: 5px;
    }

    input {
      border: solid 1px ${theme.v1.shadows.grey.light};
      border-radius: 5px;
    }
  `}
`;

export const ContentPixUpdater = styled.div`
  display: inline-grid;
  height: 100px;

`;

export const Icon = styled.span`
  display: inline-grid;
  margin-right:10.6px;
  position: relative;
  top: 3px;
`;

export const ContentDoubts = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    font-size: 12px;
    text-align: center;
    z-index: 99;
  `}
`;
export const ContentDataPix = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    padding: 0px 98px 0px 98px;
    text-align: center;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 0;
      text-align: center;
    }
  `}
`;

export const ButtonReload = styled.button`
  background: transparent;
  border: none;
  left: 36px;
  position: relative;
  text-align: center;
  top: -138px;
  width: min-content;
`;


export const PixUpdateDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0;
    padding: 14px 0px 0px 0px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;


export const PixDescription = styled.p`

  ${({ theme, variant }) => {
    const variants = {
      withPix: {
        padding: '24.4px 24px 0px 24px',
      },
      default: {
        padding: '24.4px 0px 0px 0px',
      },
    };
    return css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    margin: 0;
    padding: ${variants[variant].padding};

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 24.4px 0px 0px 0px;
      text-align: center;
    }
  `;
  }
}
`;

export const PixCodeDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 18px;
    margin: 0px 0px 9px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;


export const Number = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.background.marketingBanner};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0px 8px 0px 0px;
`}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    margin: 0;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: ;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    margin: 0;
    padding: 0px;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;
export const Item = styled.li`
  list-style-type:none;
  padding: 0 0 16px 0;

  &:last-child{
    padding: 0;
  }
`;

export const Link = styled.a`
${({ theme }) => css`
  color: ${theme.v1.color.action.primary};
  margin-right: 5px;
  text-decoration: underline;
`}
`;

export const QRCode = styled.img`
${({ variant }) => {
    const variants = {
      withPix: {
        margin: '40px 0px',
      },
      default: {
        margin: '24px 0px',
      },
    };
    return css`
  height: 153px;
  margin: ${variants[variant].margin};
  width: 153px:
  `;
  }}
`;
