import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    padding: 0 40px 40px 40px;
    overflow-x: hidden;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0 0 40px 0;
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
  @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 8px;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    box-shadow: 0px 1px 3px ${theme.shadows.grey.light};
    margin-top: 8px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0 32px;
    }
  `}
`;

export const StepContent = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey.lightbluish};
  `}
`;

export const StepSurveyContainer = styled.div``;

export const StepConfirmationContainer = styled.div``;

export const StepDoneContainer = styled.div``;

export const Controls = styled.div`
  ${({ theme, disabled }) => css`
    background: ${theme.palette.white.light};
    border-top: 1px solid ${disabled ? 'transparent' : theme.palette.grey.lightbluish};
    display: flex;
    justify-content: space-between;
    margin-top: 1px;
    padding: 24px;

    @media (max-width: ${theme.breakpoints.md}) {
      align-items: center;
      flex-direction: column-reverse;
    }
  `}
`;

export const ControlLeft = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 16px;
    }
  `}
`;

export const ControlRight = styled.div``;
