export const MODAL_STEPS = {
  SUCCESS_CC_REGISTER: 'SUCCESS_CC_REGISTER',
  SELECT_PRODUCTS: 'SELECT_PRODUCTS',
  SUCCESS_CHANGE_PRODUCTS_METHOD: 'SUCCESS_CHANGE_PRODUCTS_METHOD',
};

export const GA_RULES = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  CTA_CANCEL: 'CTA_CANCEL',
  CTA_CHANGE_PAYMENT_METHOD: 'CTA_CHANGE_PAYMENT_METHOD',
};

export const FLOW_ORIGIN = {
  CREDIT_CARD: 'CREDIT_CARD',
  MANAGE_SUBSCRIPTION: 'MANAGE_SUBSCRIPTION',
};
