import React from 'react';
import * as Style from './IconDetailedBrush.style';

const IconDetailedBrush = ({
  line = '#4a4a4a',
  color = '#FFCF00',
  size = '40',
  testId = 'icon-brush',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_542_3582)">
        <path d="M28.1469 39.9998C34.9692 39.9998 40.4998 34.4692 40.4998 27.6469C40.4998 20.8245 34.9692 15.2939 28.1469 15.2939C21.3245 15.2939 15.7939 20.8245 15.7939 27.6469C15.7939 34.4692 21.3245 39.9998 28.1469 39.9998Z" fill={color} />
        <path d="M35.6211 33.9687L33.5508 22.0312H34.25C34.8125 22.0312 35.2656 21.5781 35.2656 21.0156V13.5156C35.2656 12.9531 34.8125 12.5 34.25 12.5H24.6406V5.39062C24.6406 4.82812 24.1875 4.375 23.625 4.375H17.375C16.8125 4.375 16.3594 4.82812 16.3594 5.39062V12.5H6.75C6.1875 12.5 5.73437 12.9531 5.73437 13.5156V21.0156C5.73437 21.5781 6.1875 22.0312 6.75 22.0312H7.44922L5.37891 33.9687C5.36719 34.0273 5.36328 34.0859 5.36328 34.1406C5.36328 34.7031 5.81641 35.1562 6.37891 35.1562H34.6211C34.6797 35.1562 34.7383 35.1523 34.793 35.1406C35.3477 35.0469 35.7187 34.5195 35.6211 33.9687ZM8.46875 15.2344H19.0937V7.10937H21.9062V15.2344H32.5312V19.2969H8.46875V15.2344ZM26.75 32.4219V26.3281C26.75 26.1562 26.6094 26.0156 26.4375 26.0156H24.5625C24.3906 26.0156 24.25 26.1562 24.25 26.3281V32.4219H16.75V26.3281C16.75 26.1562 16.6094 26.0156 16.4375 26.0156H14.5625C14.3906 26.0156 14.25 26.1562 14.25 26.3281V32.4219H8.42187L10.1836 22.2656H30.8125L32.5742 32.4219H26.75Z" fill={line} />
      </g>
      <defs>
        <clipPath id="clip0_542_3582">
          <rect width="40" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedBrush;
