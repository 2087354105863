import {
  RECEIVE_VIMEO_SHOWCASES,
  ERROR_VIMEO_SHOWCASES,
  REQUEST_VIMEO_SHOWCASES,
  RECEIVE_SHOWCASE_VIDEOS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  courses: [],
  requestError: false,
};

export default function videos(state = initialState, action) {
  switch (action.type) {
    case REQUEST_VIMEO_SHOWCASES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_VIMEO_SHOWCASES:
      return {
        ...state,
        loading: false,
        courses: action.data,
      };
    case RECEIVE_SHOWCASE_VIDEOS:
      return {
        ...state,
        loading: false,
        courses: action.data,
      };
    case ERROR_VIMEO_SHOWCASES:
      return {
        ...state,
        loading: false,
        requestError: true,
      };
    default:
      return state;
  }
}
