import React, { useCallback, useMemo } from 'react';
import { useMountEmptyListLocale } from './useMountEmptyList.locale';
import { GET_A_NEW_DOMAIN } from '@/config/urls/supportUrls';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import { PRODUCTS_TYPE, OPTION_NAME } from './useMountEmptyList.enum';
import {
  IconDetailedDomain, IconDetailedHost,
} from '@/icons';
import { rules } from './useMountEmptyList.rules';

const useMountEmptyList = (ownedProducts) => {
  const routes = useMemo(() => ({
    domains: useMountEmptyListLocale.routes.domains,
    emailsList: useMountEmptyListLocale.routes.emailsList,
    products: useMountEmptyListLocale.routes.products,
    security: useMountEmptyListLocale.routes.security,
    academy: useMountEmptyListLocale.routes.academy,
  }), []);

  const getOptionName = useCallback(() => {
    const hasProduct = productType => ownedProducts.find(product => product.category === productType && product.has_product);

    if (hasProduct(PRODUCTS_TYPE.DOMAIN)) return OPTION_NAME.DOMAINS;
    if (hasProduct(PRODUCTS_TYPE.PROEMAIL)) return OPTION_NAME.TITAN;
    if (hasProduct(PRODUCTS_TYPE.DEDICATED)) return OPTION_NAME.DEDICATED;
    if (hasProduct(PRODUCTS_TYPE.SITELOCK) || hasProduct(PRODUCTS_TYPE.CODEGUARD)) return OPTION_NAME.SECURITY;
    if (hasProduct(PRODUCTS_TYPE.ACADEMY)) return OPTION_NAME.ACADEMY;
    return null;
  }, [ownedProducts]);

  const mountEmptyOptions = useCallback(() => {
    const options = [];

    options.push({
      name: OPTION_NAME.HIREHOST,
      label: useMountEmptyListLocale.emptyOptions.hireHost,
      icon: <IconDetailedHost />,
      route: HOSTING_URL,
      target: '_blank',
    });

    options.push({
      name: OPTION_NAME.HIREPLAN,
      label: useMountEmptyListLocale.emptyOptions.hirePlan,
      icon: <IconDetailedDomain />,
      route: GET_A_NEW_DOMAIN,
      target: '_blank',
    });

    const optionName = getOptionName();
    rules[optionName](options, routes);


    return options;
  }, [getOptionName, routes]);

  const mountEmptyListData = {
    routes,
    mountEmptyOptions,
  };
  return mountEmptyListData;
};

export default useMountEmptyList;
