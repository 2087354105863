import React from 'react';
import * as Style from './IconFinancy.style';

const IconFinancy = ({ color = '#4a4a4a', className = '' }) => (
  <Style.Wrapper className={className} data-testid="icon-financy">
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" data-testid="icon-financy-svg" width="24" height="24.016" viewBox="0 0 24 24.016">
      <g transform="translate(0 0)">
        <path d="M12.156,8.5a1.124,1.124,0,0,1,.907.406A1.9,1.9,0,0,1,13.4,10.13h1.976a3.323,3.323,0,0,0-.684-2.171A2.857,2.857,0,0,0,12.8,6.951V5.468H11.7V6.931a3.115,3.115,0,0,0-1.962.861,2.564,2.564,0,0,0-.738,1.894,2.841,2.841,0,0,0,.215,1.155,2.581,2.581,0,0,0,.6.845,4.068,4.068,0,0,0,.926.634,12.24,12.24,0,0,0,1.285.558,2.816,2.816,0,0,1,1.057.612,1.251,1.251,0,0,1,.31.892,1.107,1.107,0,0,1-.348.858,1.363,1.363,0,0,1-.95.318,1.407,1.407,0,0,1-1.111-.435,1.814,1.814,0,0,1-.387-1.247H8.629A3.2,3.2,0,0,0,9.4,16.121a3.282,3.282,0,0,0,2.17.995V18.47h1.087V17.109a3.179,3.179,0,0,0,2-.854,2.55,2.55,0,0,0,.725-1.887,2.865,2.865,0,0,0-.191-1.091,2.566,2.566,0,0,0-.554-.834,3.9,3.9,0,0,0-.889-.656,12.665,12.665,0,0,0-1.343-.618,3.315,3.315,0,0,1-1.118-.643,1.158,1.158,0,0,1-.3-.834,1.208,1.208,0,0,1,.308-.878A1.152,1.152,0,0,1,12.156,8.5Z" transform="translate(0 0.016)" />
        <path d="M12,22A9.98,9.98,0,0,1,5.555,4.383l2.1,2.1V.828H2L4.134,2.962A11.983,11.983,0,0,0,14.305,23.773l-.3-1.976A10,10,0,0,1,12,22Z" transform="translate(0 0.016)" />
        <path d="M24,11.984a12.013,12.013,0,0,0-12-12h0a11.974,11.974,0,0,0-2.3.227l.3,1.976a9.984,9.984,0,0,1,8.445,17.422l-2.094-2.094v5.657H22L19.857,21.03A11.963,11.963,0,0,0,24,11.984Z" transform="translate(0 0.016)" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconFinancy;
