/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { TOS_STATUS } from '@/enums/preChat/preChat.enum';
import { IconDanger, Link } from 'gatorcomponents';
import { BLOG_URL, HELP_CENTER_URL } from '@/config/urls/supportUrls';
import { format, isSameDay } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { COUNTRY } from '@/config';

export default {
  listPageFaq: {
    title: 'Links de ajuda',
    filterLabel: 'Filtrar por',
    filterByPlaceholder: 'Busque por palavra-chave',
    clearFilterLabel: 'Limpar filtros',
    helpLinks: {
      filter: {
        chips: {
          main: 'Principais dúvidas',
          financial: 'Financeiro',
          domain: 'Domínio e DNS',
          mail: 'E-mail',
          site: 'Site',
        },
      },
    },
    listDescription: (
      <>
        {'Vea a continuación los principales contenidos, '}
        <br />
        {'pero no deje de visitar nuestro '}
        <Link href={BLOG_URL} text="Blog" />
        {' y '}
        <Link href={HELP_CENTER_URL} text="Central de Ayuda" />
        {' de HostGator :)'}
      </>
    ),
  },
  listPage: {
    title: 'Lista de Tickets',
    filterLabel: 'Filtrar por',
    filterByPlaceholder: 'Buscar por asunto',
    clearFilterLabel: 'Limpiar filtros',
    openNewTicketLabel: 'Nuevo ticket',
    startChat: 'Contáctenos',
    listDescription: open => (
      <>
        {open > 0 ? (
          <>
            {'Tiene '}
            <strong>{`${open} ticket${open === 1 ? '' : 's'} abierto${open === 1 ? '' : 's'}.`}</strong>
            {' El plazo para la primera respuesta es de '}
            <strong>hasta 48 horas hábiles.</strong>
            <br />
            {` ¡Esté siempre atento antes de que sea${open === 1 ? '' : 'n'} finalizado${open === 1 ? '' : 's'}!`}
          </>
        ) : 'No tiente tickets abiertos.'}
      </>
    ),
    tickets: {
      status: {
        closed: 'FINALIZADO',
        solved: 'COMPLETADO',
        new: 'ABIERTO',
        open: 'PENDIENTE',
        opened: 'PENDIENTE',
        pending: 'ESPERANDO RESPUESTA',
      },
      numberLabel: 'Número del ticket',
      openingLabel: 'Abierto',
      viewButtonLabel: 'Ver ticket',
      filter: {
        chips: {
          all: 'Todos',
          closed: 'Finalizados',
          solved: 'Completados',
          new: 'Abiertos',
          open: 'Pendientes',
          opened: 'Pendientes',
          pending: 'Esperando respuesta',
        },
      },
    },
    loadMoreLabel: 'Ver mas',
    empty: 'No tienes tickets',
  },
  ticketOpenModal: {
    title: 'Tickets en abierto',
    cautionText: (
      <>
        <IconDanger color="problemPure" size="lg" />
        <p>
          Tiene más de un ticket urgente que resolver. Ingrese al ticket para ser orientado por nuestros especialistas.
        </p>
      </>
    ),
    selectLabel: 'Tickets',
    selectPlaceholder: 'Seleccione el ticket',
    ticketStatusLabel: status => ({
      [TOS_STATUS.DISK]: 'Alerta de espacio en disco',
      [TOS_STATUS.INODES]: 'Alerta de límite de archivos',
      [TOS_STATUS.BACKUP]: 'Alerta de copia de seguridad',
      [TOS_STATUS.MYSQL]: 'Alerta de uso de recursos de base de datos',
      [TOS_STATUS.CPU]: 'Alerta de uso de recursos del procesador',
      [TOS_STATUS.DMCA]: 'Alerta de derechos de autor',
      [TOS_STATUS.PISHING]: 'Alerta de archivo infectado',
    })[status] || '',
    ticketWarningText: status => ({
      [TOS_STATUS.DISK]: (
        <p>
          <strong>{'Alerta de espacio en disco: '}</strong>
          ¡Su espacio en disco está lleno! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.INODES]: (
        <p>
          <strong>{'Alerta de límite de archivos: '}</strong>
          ¡La cuenta ha alcanzado el límite de archivos y directorios! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.BACKUP]: (
        <p>
          <strong>{'Alerta de copia de seguridad: '}</strong>
          ¡Solo puede almacenar una copia de seguridad en su cuenta! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.MYSQL]: (
        <p>
          <strong>{'Alerta de uso de recursos de base de datos: '}</strong>
          ¡Su base de datos MySQL ha alcanzado el límite de uso de recursos! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.CPU]: (
        <p>
          <strong>{'Alerta de uso de recursos del procesador: '}</strong>
          ¡Su uso de los recursos del procesador ha llegado al límite! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.DMCA]: (
        <p>
          <strong>{'Alerta de derechos de autor: '}</strong>
          ¡Hay un problema relacionado con los derechos de autor en su sitio! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
      [TOS_STATUS.PISHING]: (
        <p>
          <strong>{'Alerta de archivo infectado: '}</strong>
          ¡Detectamos contenido potencialmente peligroso en su plan de hosting! Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
        </p>
      ),
    })[status] || '',
    goToTicketBtnText: 'Ir al ticket',
  },
  ticketUpgradeVpsModal: {
    title: 'Upgrade de VPS',
    firstLabel: () => (
      <p>
        Este tipo de upgrade solo se realiza a través de la
        <strong>{' apertura de un ticket '}</strong>
        con el atendimiento de un especialista.
      </p>
    ),
    secondLabel: () => (
      <p>
        Por lo tanto,
        <strong>{' complete las informaciones que falta '}</strong>
        (IP del Servidor, Nuevo producto y Horario para la ejecución) y envíe el formulario de apertura del ticket. Tan pronto como un especialista responda,
        <strong>{' la respuesta se enviará por correo electrónico.'}</strong>
      </p>
    ),
    buttonLabel: 'Ok, entendí',
  },
  openNewTicketPage: {
    title: 'Complete las siguientes informaciones:',
    selecteProductOptionPlaceholder: ' ¿Para cuál producto/ servicio desea soporte? *',
    selecteSectorOptionPlaceholder: '¿Cuál sector? *',
    sectorBilling: 'Financiero',
    sectorTechnical: 'Técnico',
    selecteNeedOptionPlaceholder: '¿Cuál es su necesidad? *',
    textManipulation: {
      startsWith: {
        billing: 'Soporte Financiero',
        technical: 'Soporte Técnico',
        myAccount: 'Mi cuenta',
      },
    },
    selectProductGroups: {
      myAccount: 'Mi cuenta',
      activeProducts: 'Productos/Servicios Activos',
      suspendedProducts: 'Productos/ Servicios Suspendidos ',
    },
  },
  forms: {
    error: 'No fue posible generar el ticket en este momento. Espere un instante y vuelva a intentarlo.',
    terms: (
      <>
        {'Lea con atención los siguientes '}
        <strong>términos:</strong>
      </>
    ),
    submitLabel: 'Abrir ticket',
    internalMigration: {
      title: 'Complete el siguiente formulario',
      originTitle: 'Datos de origen:',
      destinationTitle: 'Datos de destino:',
      links: {
        migration: () => (
          <>
            {'Lea nuestros '}
            <Link
              text="Términos de Migración"
              href="https://www.hostgator.mx/terminos-de-migracion"
              target="_blank"
            />
            .
          </>
        ),
        compatibility: () => (
          <>
            {'Vea también '}
            <Link
              text="cuales son nuestras compatibilidades"
              href="https://soporte-latam.hostgator.com/hc/es-419/articles/115002096351"
              terget="_blank"
            />
            {' para ver si es adecuado a su necesidad.'}
          </>
        ),
      },
      warning: {
        title: 'ATENCIÓN:',
        item: {
          0: () => (
            <li>NO CANCELE su plan antiguo hasta que los archivos se hayan migrado, probado y las DNS cambiadas correctamente.</li>
          ),
          1: () => (
            <li>Recuerde esperar el plazo de propagación de 24h a 72h dependiendo de la extensión de su dominio.</li>
          ),
          2: () => (
            <li>No somos responsables del contenido migrado, ya que no tenemos control de la versión.</li>
          ),
        },
      },
      checkbox: {
        terms: () => (
          <>
            {'Confirmo que '}
            <strong>he leído y estoy de acuerdo </strong>
            {' con los '}
            <Link
              text="Términos de migración."
              target="_blank"
              href="https://www.hostgator.mx/terminos-de-migracion"
            />
          </>
        ),
        agreed: () => (
          <>
            {'Confirmo que '}
            <strong>estoy de acuerdo </strong>
            {' con un eventual cobro si no cumplo con los límites de la migración descritos en los términos.'}
          </>
        ),
      },
      migrationTypeText: 'Tipo de migración',
      partialMigration: 'Migración Parcial',
      fullMigration: 'Migración completa',
      whatToBeMigrated: '¿Qué debe ser migrado?',
      name: 'Migración Interna',
      alert: 'Este formulario es para la migración de toda la cuenta. Al solicitar la migración, todo el contenido de su sitio y/o banco de datos, serán reemplazados por el contenido migrado, no pudiendo volver al contenido anterior, si tiene más de un plan de hosting asegúrese de que está haciendo la solicitud para el producto correcto. ',
      originDomainPlaceholder: 'Seleccione entre sus planes activos',
      originDomainLabel: 'Dominio principal *',
      originServerPlaceholder: 'Digite el servidor aquí',
      originServerLabel: 'Servidor',
      originUserPlaceholder: 'Digite el usuario aquí',
      originUserLabel: 'Usuario',
      myServerLabelRadio: 'Para mi panel  HostGator',
      otherServerLabelRadio: 'Para otro panel HostGator',
      myDomainPlaceholder: 'Seleccione el dominio principal aquí',
      myDomainLabel: 'Dominio principal *',
      myServerPlaceholder: 'Digite el servidor aquí',
      myServerLabel: 'Servidor',
      myUserPlaceholder: 'Digite el usuario aquí',
      myUserLabel: 'Usuario',
      otherDomainPlaceholder: 'Digite el dominio principal aquí',
      otherDomainLabel: 'Dominio principal*',
      otherServerPlaceholder: 'Digite el servidor aquí',
      otherServerLabel: 'Servidor',
      otherUserPlaceholder: 'Digite el usuario aquí',
      otherUserLabel: 'Usuario',
      body: {
        originServer: 'Servidor de origen',
        originUser: 'Usuario de origen',
        destinyServer: 'Servidor de destino',
        destinyUser: 'Usuario de destino',
        agree: 'De acuerdo con los términos',
        agreePayment: 'De acuerdo con un eventual cobro',
        yes: 'Si',
        no: 'No',
      },
    },
    restorateBackupHgRoutine: {
      name: 'Restauração de Backup Completo - Rotina HostGator',
      terms: (
        <>
          {'Lea atentamente los siguientes '}
          <strong>términos:</strong>
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li>
              <strong>Hay un cobro</strong>
              {' por este procedimiento. '}
              <strong>Al final de la restauración se generará el cobro en su cuenta.</strong>
            </li>
            <li>No tenemos control de versiones, por lo que no somos responsables del contenido restaurado.</li>
            <li>
              {'Si sus correos electrónicos son Titan, no restauramos desde la copia de seguridad. Usted es responsable de '}
              <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360059617832" text="hacer su propia copia de seguridad." />
            </li>
            <li>Si su sitio fue desarrollado con el Creador de Sitios web de HostGator, debe solicitar la restauración del editor a través del formulario "Restauración de acceso al editor del Creador de Sitios”</li>
          </ul>
        </>
      ),
      alert: {
        title: 'ATENCIÓN!',
        description: (
          <>
            {'Este formulario es para solicitar la restauración de toda la cuenta. Todo el contenido del sitio y/o la base de datos será reemplazado por contenido restaurado y '}
            <strong>no podrá ser devuelto al contenido anterior.</strong>
          </>
        ),
      },
      title: 'Complete los siguientes datos ',
      selectRestorationType: {
        full: 'Completa',
        database: 'Base de datos',
        file: 'Archivos',
        domain: 'Dominio',
      },
      fields: {
        restorationType: 'Tipo de restauración ',
        user: 'Escribe el nombre de usuario aquí',
        userLabel: 'Usuario',
        server: 'Escribe el servidor aquí',
        serverLabel: 'Servidor',
        domain: 'Escribe el dominio aquí',
        domainLabel: 'Dominio',
        backup: 'Seleccione una de las fechas para restaurar',
        specificDomains: 'Informe abajo los dominios que desea restaurar separados por coma',
        specificDatabase: 'Informe abajo las Bases de Datos que desea restaurar separados por coma',
        specificFiles: 'Informe abajo los archivos que desea restaurar separados por coma',
      },
      options: {
        daily: 'Diario',
        monthly: 'Mensual',
        weekly: 'Semanal',
      },
      selectBackupTitle: 'Copias de seguridad disponibles para restaurar:',
      months: {
        0: 'Enero',
        1: 'Febrero',
        2: 'Marzo',
        3: 'Abril',
        4: 'Mayo',
        5: 'Junio',
        6: 'Julio',
        7: 'Agosto',
        8: 'Septiembre',
        9: 'Octubre',
        10: 'Noviembre',
        11: 'Diciembre',
      },
      agreed: (
        <>

          {'Confirmo que '}
          <strong>leí y estoy de acuerdo</strong>
          {' con los términos de restauración del backup de arriba.  '}
        </>
      ),
      open: 'Abrir ticket',
      body: {
        productId: 'ID del producto',
        server: 'Servidor',
        user: 'Usuario',
        domain: 'Dominio',
        backupName: 'Nombre del backup utilizado para la restauración',
        uninformed: 'no informado',
        agree: 'De acuerdo con los términos',
        yes: 'Si',
        no: 'No',
      },
    },
    generateBackup: {
      name: 'Restauração de Backup Completo - Rotina HostGator',
      terms: (
        <>
          {'Lea atentamente los siguientes '}
          <strong>términos:</strong>
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li>
              El archivo de backup será enviado por correo electrónico, y podrás seguir su progreso en la pantalla de Soporte, en la sección de Tickets.
            </li>
          </ul>
        </>
      ),
      title: 'Complete los siguientes datos ',
      fields: {
        user: 'Escribe el nombre de usuario aquí',
        userLabel: 'Usuario',
        server: 'Escribe el servidor aquí',
        serverLabel: 'Servidor',
        domain: 'Escribe el dominio aquí',
        domainLabel: 'Dominio',
      },
      agreed: (
        <>

          {'Confirmo que '}
          <strong>leí y estoy de acuerdo</strong>
          {' con los términos de restauración del backup de arriba.  '}
        </>
      ),
      open: 'Abrir ticket',
      body: {
        productId: 'ID del producto',
        server: 'Servidor',
        user: 'Usuario',
        agree: 'De acuerdo con los términos',
        yes: 'Si',
        no: 'No',
      },
    },
    userBackupRestore: {
      name: 'Restaurar backup - Copia del cliente',
      restorationType: 'Tipo de Restauración ',
      completeRestoration: 'Completa',
      databaseRestoration: 'Base de datos',
      filesRestoration: 'Archivos',
      domainRestorarion: 'Dominio',
      title: 'Complete los siguientes datos',
      alert: 'Este formulario es para solicitar la restauración de toda la cuenta. Todo el contenido del sitio y/o la base de datos será reemplazado por contenido restaurado y no podrá ser devuelto al contenido anterior. Si tiene más de un plan de hosting, asegúrese de solicitar para el producto correcto.',
      fileNameInputLabel: 'Ingrese el nombre del archivo de backup a ser restaurado',
      fileNameInputPlaceholder: 'Digite el nombre del archivo de backup',
      databaseNameInputLabel: 'Nombre de la base de datos',
      databaseNameInputPlaceholder: 'Digite el nombre de la base de datos',
      checkboxAgreed: (
        <>
          Confirmo que
          <strong>{' leí y estoy de acuerdo '}</strong>
          con los términos de restauración del backup de arriba.
        </>
      ),
      warning: {
        title: (
          <strong>ATENCIÓN!</strong>
        ),
        item: {
          0: (
            <li>
              {'Asegúrese de cargar el archivo de copia de seguridad en cPanel antes de solicitar la restauración. Consulte '}
              <Link text="cómo cargar archivos." href="https://soporte-latam.hostgator.com/hc/es-419/articles/15116025680027" />
            </li>
          ),
          1: (
            <li>No tenemos control de versiones, por lo que no somos responsables del contenido restaurado.</li>
          ),
          2: (
            <li>
              {'Si sus correos electrónicos son Titan, no restauramos desde la copia de seguridad. Usted es responsable de '}
              <Link text="hacer su propia copia de seguridad." href="https://soporte-latam.hostgator.com/hc/es-419/articles/360059617832" />
            </li>
          ),
          3: (
            <li>Si su sitio fue desarrollado con el Creador de Sitios web de HostGator, debe solicitar la restauración del editor a través del formulario "Restauración de acceso al editor del Creador de Sitios” </li>
          ),
        },
      },
      selectedProduct: 'Producto selecionado',
      backupFileName: 'Archivo de backup',
      databaseFileName: 'Base de datos',
      user: 'Escribe el nombre de usuario aquí',
      userLabel: 'Usuario',
      server: 'Escribe el servidor aquí',
      serverLabel: 'Servidor',
      domain: 'Escribe el dominio aquí',
      domainLabel: 'Dominio',
      agree: 'De acuerdo con los términos',
      yes: 'Si',
      no: 'No',
    },
    withoutEmailAccess: {
      name: 'No tengo acceso al correo electrónico registrado',
      title: 'Complete el siguiente formulario',
      info: 'Para garantizar la seguridad de su cuenta y proceder al cambio de su correo electrónico registrado, solicitamos que complete las informaciones de abajo. Después del envío, nuestro equipo de soporte se comunicará con usted en hasta 48 horas.',
      emailLabel: 'Correo electrónico registrado',
      documentLabel: 'Número de Documento del titular de la cuenta (Personal o Empresa)',
      contactEmailPlaceholder: 'Digite aquí una dirección de correo electrónico válida para que podamos ponernos en contacto con usted.',
      contactEmailLabel: 'Correo electrónico de contacto',
      addDocumentLabel: (
        <>
          {'Para completar el proceso de verificación, solicitamos que '}
          <strong>envíe una foto teniendo el documento de identificación (Personal o de la Empresa) utilizado para registrar su cuenta.</strong>
          {' Por favor, haga clic en el botón "Adjuntar documento" de abajo para cargar el archivo. Tenga en cuenta que el límite de tamaño es de 5 MB y aceptamos formatos JPEG, PNG y PDF.'}
        </>
      ),
      addDocumentButton: 'Adjuntar documento',
      descriptionLabel: 'Describa su solicitud en detalle en el campo de abajo:',
      emailError: 'Introduzca un correo electrónico válido',
      body: {
        registeredEmail: 'Correo electrónico registrado',
        document: 'Documento',
        contactEmail: 'Correo electrónico de contacto',
        description: 'Descripción del problema',
      },
    },
    changePrimaryDomain: {
      title: 'Complete el siguiente formulario',
      info: isHosting => (
        <>
          <strong>Para el cambio de dominio principal, compruebe los dos pasos siguientes:</strong>
          <ul>
            <li>
              {'El nuevo dominio debe estar registrado. Si es necesario, consulte '}
              <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360038452032" text="Cómo registrar un dominio" />
              .
            </li>
            <li>
              {isHosting ? (
                <>
                  {'El dominio no puede estar añadido en cPanel. Por lo tanto, si el nuevo dominio ya está configurado como dominio adicional o alternativo (Aliases) en su hosting '}
                  <Link
                    href="https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691"
                    text="realice una copia de seguridad"
                    target="_blank"
                  />
                  {' y '}
                  <Link
                    href="https://soporte-latam.hostgator.com/hc/es-419/articles/216721038"
                    text="elimine el dominio de cPanel"
                    target="_blank"
                  />
                  {' antes de proceder con su solicitud.'}
                </>
              )
                : (
                  <>
                    {'El dominio debe estar añadido en una instancia de cPanel. Por lo tanto, '}
                    <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/233377267" text="cree la cuenta en el servidor" />
                    {' antes de continuar con su solicitud.'}
                  </>
                )}
            </li>
          </ul>
          <strong>Lea con atención los siguientes términos:</strong>
          <ul>
            <li>
              Si el sitio web ha sido desarrollado con el Creador de Sitios Web de HostGator, al cambiar el dominio principal, usted perderá acceso a la herramienta de edición. Para recuperar el acceso será necesario crear un nuevo sitio web.
            </li>
          </ul>
        </>
      ),
      newDomainLabel: 'Nuevo dominio que será utilizado',
      whyLabel: 'Motivo del cambio',
      agreedLabel: 'Confirmo que he leído y estoy de acuerdo con los términos anteriores y autorizo el cambio de dominio principal.',
      productId: 'ID del producto',
      solicitation: 'Solicitación',
      product: 'Producto',
      yes: 'Si',
      no: 'No',
      userLabel: 'Usuario',
      subject: 'Soporte Técnico - Alteración de Dominio Primario',
    },
    installExternalSsl: {
      info: (
        <>
          <strong>Lea con atención los siguientes términos:</strong>
          <ul>
            <li>
              <strong>{'En el campo URL para la instalación del SSL, asegúrate de informar el dominio correcto, '}</strong>
              ya que no es posible emitir el certificado nuevamente para otro dominio;
            </li>
            <li>
              El SSL funcionará unicamente para el dominio informado a continuación (por ejemplo: nombrededominio.com);
            </li>
            <li>
              <strong>El certificado SSL es valido únicamente para ser instalado en los servidores de HostGator</strong>
              ;
            </li>
          </ul>
        </>
      ),
      urlInputPlaceholder: 'URL para la instalación del SSL *',
      urlInputLabel: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo tienda.nombredeldominio.com.',
      sslCertificate: 'Certificado SSL *',
      addDocumentButton: 'Anexar archivo',
      sslCertififateFileType: 'Archivo con extensión .crt',
      sslCertificateCA: 'Insira el contenido del archivo del Certificado SSL CA (Trusted Authority)',
      privateKey: 'Insira el contenido del archivo del Private Key *',
      orPasteContentPlaceholder: 'Agrega el contenido del archivo en este campo',
      checkboxLabel: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $516.00 MXN',
      send: 'Enviar',

      bodyFields: {
        tag: 'whmcs_form_tecnico_ssl_otra_empresa',

        product: 'Producto',
        user: 'Usuario',
        server: 'Servidor',
        solicitation: 'Solicitación',
        sslUrl: 'URL para la instalación del SSL',
        sslCa: 'Contenido del archivo del Certificado SSL CA (Trusted Authority)',
        privateKey: 'Contenido del archivo del Private Key',
        agree: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $516.00 MXN',
        yes: 'Si',
        no: 'No',
        productId: 'ID del producto',
      },
    },
    googleAdwordsCoupon: {
      info: (
        <>
          <strong>Lea con atención los siguientes términos:</strong>
          <ul>
            <li>Como cliente de HostGator tienes derecho a 1 cupón por cada plan de hospedaje contratado;</li>
            <li>
              Apenas 1 crédito promocional puede ser obtenido por el cliente;
            </li>
            <li>
              Usted tiene derecho al cupón si eres nuevo en Google AdWords y si tienes una cuenta con por lo menos 14 dias de existencia, o si ya eres un cliente de Google AdWords y activas un nuevo recurso o producto, o reactivar todavia una cuenta inactiva;
            </li>
            <li>
              Nosotros te enviaremos un código promocional que deberá ser registrado en su cuenta de Google AdWords - este código no tiene valor inmediato, este servira apenas para iniciar el proceso de calificación para el crédito promocional asociado;
            </li>
            <li>
              El crédito no se aplica a los costos acumulados antes de la introducción del código promocional. O sea, el crédito puede ser usado unicamente para los gastos de publicidades futuras.
              Para informaciones detalladas, verifique los
              <Link text="términos y condiciones de uso de Google AdWords." href="https://support.google.com/adspolicy/answer/1396257?hl=es-419" />
            </li>
          </ul>

        </>
      ),
      checkbox: 'Confirmo que leí y concuerdo con los términos',
      sendButtonLabel: 'Enviar',
      bodyFields: {
        product: 'Producto',
        user: 'Usuario',
        server: 'Servidor',
        solicitation: 'Solicitación',
        agree: 'Confirmo que leí y concuerdo con los términos',
        productId: 'ID del producto',
      },
    },
    externalMigration: {
      title: 'Complete el siguiente formulario',
      info: (
        <>
          {'Antes de completar este formulario, por favor: '}
          <br />
          <ul>
            <li>
              {'Lee los '}
              <Link
                href="https://www.hostgator.co/terminos-de-migracion"
                text="términos de migración"
              />
            </li>
            <li>
              {'Consulte también '}
              <Link
                href="https://soporte-latam.hostgator.com/hc/es-419/articles/115002096351"
                text="cuáles son nuestras compatibilidades"
              />
              {' para ver si se adapta a sus necesidades.'}
            </li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>
              <strong>NO CANCELES</strong>
              {' tu antiguo plan hasta que los archivos se hayan migrado, los hayas probado y los DNS se hayan cambiado correctamente.'}
            </li>
            <li>
              Recuerda esperar el periodo de propagación, de 24h a 72h dependiendo de la extensión de tu dominio
            </li>
          </ul>
          <strong>ATENCION</strong>
          <ul>
            <li>Este formulario es para la restauración de toda la cuenta. Al solicitar la restauración, todo el contenido del site y/o, base de datos serán substituídos por el contenido restaurado, no pudiendo volver al contenido anterior. Si posee más de un plan de hospedaje certifiquese que está solicitando para el producto correcto.</li>
          </ul>
        </>
      ),
      oldHostDomain: 'Dominio registrado en el antiguo antiguo hosting *',
      oldHostLogin: 'Introduce el nombre de usuario para acceder al panel administrativo del antiguo hosting *',
      oldHostPassword: 'Introduce la contraseña para acceder al panel administrativo del antiguo hosting *',
      oldHostLinkAdmin: 'Link para acceder al panel administrativo del antiguo hosting *',
      oldHostAdminPanel: '¿Cuál es el panel administrativo del antiguo servidor? *',
      oldHostProEmail: '¿Cuál es el tipo de correo electrónico utilizado en el antiguo hosting? *',
      actualProEmail: '¿Seleccione el tipo de correo electrónico utilizado en su alojamiento actual con HostGator? *',
      aditionalInfo: 'Utiliza el siguiente campo para introducir cualquier información que consideres importante para la migración, como una observación específica',
      terms: 'Entiendo y estoy de acuerdo con los términos de la migración',
      payment: 'Confirmo que estoy de acuerdo con cualquier pago o tasa adicional, de no cumplir con las condiciones estipuladas en el término de migración',
      dontKnow: 'No sé',
      bodyProduct: selectedProduct => `Producto: ${selectedProduct.name} - Usuario: ${selectedProduct.username}`,
      solicitation: 'Migración Externa',
      bodyDomain: oldHostDomain => `Dominio registrado en el antiguo proveedor de hosting: ${oldHostDomain}`,
      bodyLogin: oldHostLogin => `Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor: ${oldHostLogin}`,
      bodyPassword: oldHostPassword => `Introduce la contraseña para acceder al panel administrativo del antiguo proveedor: ${oldHostPassword}`,
      bodyLink: oldHostLinkAdmin => `Link para acceder al panel administrativo del antiguo proveedor: ${oldHostLinkAdmin}`,
      bodyPanel: oldHostAdminPanel => `¿Cuál es el panel administrativo del antiguo servidor?: ${oldHostAdminPanel}`,
      bodyOldEmail: oldHostProEmail => `¿Cuál es el tipo de correo electrónico utilizado en el antiguo hosting? ${oldHostProEmail}`,
      bodyNewEmail: actualProEmai => `¿Seleccione el tipo de correo electrónico utilizado en su alojamiento actual con HostGator? ${actualProEmai}`,
      bodyAditionalInfo: aditionalInfo => `Utiliza el siguiente campo para introducir cualquier información que consideres importante para la migración, como una observación específica: ${aditionalInfo}`,
      bodyTerms: terms => `Entiendo y estoy de acuerdo con los términos de la migración: ${terms ? 'Si' : 'No'}`,
      bodyPayment: payment => `Confirmo que estoy de acuerdo con cualquier pago o tasa adicional, de no cumplir con las condiciones estipuladas en el término de migración: ${payment ? 'Si' : 'No'}`,
      bodyProductId: selectedProduct => ` ID del producto: ${selectedProduct.id}`,
      subject: 'Soporte Técnico - Migración Externa',
    },
    financialDoubts: {
      title: 'Complete el siguiente formulario',
      info: (
        <>
          Si no encuentra la opción que desea en la lista de abajo, inicie un
          {' '}
          <strong>servicio de chat</strong>
          .
        </>
      ),
      descriptionLabel: '¿Cuál es tu duda para el área financiera?',
      addDocumentLabel: '¿Quieres adjuntar un archivo? ¡Envíanoslo!',
      addDocumentButton: 'Adjuntar documento',
      solicitation: 'Solicitación',
      product: 'Producto',
      productId: 'ID del producto',
      user: 'Usuario',
      subject: 'Soporte Financiero - Dudas Generales',
      tag: 'whmcs_form_dudas_financieras',
    },
    restorateCancelledPlan: {
      title: 'Complete el siguiente formulario!',
      subject: 'Soporte Técnico - Restauración de plan cancelado',
      terms: (
        <>
          {'Lea con atención los '}
          <strong>términos</strong>
          {' a continuación:'}
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li><strong>ES OBLIGATORIO que ya haya contratado nuevamente el mismo plan con el mismo dominio para que la restauración sea realizada.</strong></li>
            <li>
              <strong>{'Existe una cobranza por hora técnica para este procedimiento. '}</strong>
              Al término de la restauración será generada una cobranza en su cuenta.
            </li>
            <li>No nos responsabilizamos por el contenido restaurado, pues no tenemos control de versiones. </li>
            <li>Nuestra rutina de backups es semana e incluye cuentas de hasta 20GB de datos, que posean hasta 100 mil inodes (archivos, carpetas, e-mails, etc.).</li>
            <li>Todos los dominio bajo su usuario que tengamos backups serán restaurados intependientemente si ellos fueron removidos anteriormente.</li>
            <li>Si su sitio fue desarrollado con el Creador de sitios de HostGator, también debe solicitar que se restaure el editor utilizando el formulario Restauración de acceso al editor del Creador de Sitios.</li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>Este formulario es para restauración de cuenta cancelada.</li>
            <li>Al solicitar la restauración recuperaremos todo el contenido del sitio, bases de datos e e-mails del último estado de su cuenta antes del cancelamiento, no siendo posible restaurar solamente el dominio principal o solamente dominios adicionales.</li>
          </ul>
        </>
      ),
      mainDomainPlaceholder: 'Informe abajo el dominio principal del plan cancelado que desea restaurar',
      chargeAgreed: 'Confirmo que leí y concuerdo con los términos y con la cobranza',
      termsAgreed: 'Confirmo que leí y concuerdo con los términos de restauración de backup',
      bodyProduct: selectedProduct => `Producto: ${selectedProduct.name} - Usuário: ${selectedProduct.username}`,
      bodyServer: selectedProduct => `Servidor: ${selectedProduct.serverhostname || selectedProduct.dedicatedip}`,
      bodyRequest: subject => subject,
      bodyProductId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      bodyMainDomain: domain => `Informe abajo el dominio principal del plan cancelado que desea restaurar: ${domain}`,
    },
    affiliateProgram: {
      title: 'Complete el siguiente formulario!',
      subject: 'Preguntas exclusivamente sobre el Programa de Afiliados',
      info: (
        <>
          <strong>
            {'Lea con atención los '}
            <strong>términos</strong>
            {' a continuación:'}
          </strong>
          <ul>
            <li>
              {'Este formulario es '}
              <strong>exclusivo</strong>
              {' para nuestro Programa de Afiliados. Atención de lunes a viernes con tiempo de respuesta entre 24/72 horas laborables.'}
            </li>
            <li>
              <strong>Si necesitas soporte técnico o financiero este formulario no es el apropiado.</strong>
            </li>
          </ul>
        </>
      ),
      labelAffiliate: '¿Formas parte del Programa de Afiliados?',
      affiliateOptions: {
        yes: 'Sí',
        no: 'No, pero tengo dudas sobre el Programa',
      },
      labelDoubts: 'Ingrese su pregunta sobre el Programa de Afiliados',
      bodyProduct: 'Producto: Preguntas exclusivamente sobre el Programa de Afiliados',
      bodyRequest: 'Preguntas exclusivamente sobre el Programa de Afiliados',
      bodyIsAffiliate: answer => `¿Formas parte del Programa de Afiliados?: ${answer}`,
      bodyDoubts: content => `Ingrese su pregunta sobre el Programa de Afiliados: ${content}`,
      sendButtonLabel: 'Enviar',
    },
    upgradeVps: {
      title: 'Complete el siguiente formulario!',
      subject: 'Preguntas exclusivamente sobre el Programa de Afiliados',
      fields: {
        productLabel: 'Nuevo plan',
        agreedTermsLabel: 'Permitir el upgrade  del plan seleccionado',
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username} - IP: ${selectedProduct.dedicatedip}`,
        request: 'Upgrade de un plan',
        newProduct: newProduct => `Nuevo plan: ${newProduct}`,
        agreedTerms: agreedTerms => `Permitir el upgrade  del plan seleccionado: ${agreedTerms ? 'Sí' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      sendButtonLabel: 'Enviar',
    },
    changeDueDate: {
      info: () => (
        <>
          <p><strong>Lea con atención las siguientes informaciones:</strong></p>
          <ul>
            <li>
              {'La nueva fecha debe ser '}
              <strong>de hasta 30 días a partir de la fecha del vencimiento actual</strong>
              {' del servicio;'}
            </li>
            <li>
              {'Cambiar la fecha de vencimiento '}
              <strong>puede generar un incremento en el valor de su próxima factura</strong>
              {' debido al reajuste de la fecha.'}
            </li>
          </ul>
        </>
      ),
      desiredDueDate: '¿Cuál es la fecha de vencimiento deseada?',
      desiredDueDatePlaceHolder: 'mm/dd/aaaa',
      agreement: 'Confirmo que he leído y estoy de acuerdo con los términos anteriores.',
      send: 'Enviar',
    },
    upgradeCodeGuard: {
      info: (
        <>
          {'Si no encuentra la opción deseada en la lista de abajo, inicie un '}
          <strong>atendimiento por chat.</strong>
        </>
      ),
      placeholders: {
        newProduct: 'Nuevo Producto',
        agreed: ' Confirmo que he leído y estoy de acuerdo con la diferencia de valor entre los productos',
        agreedSelectedPlan: 'Confirmo que seleccione el plan correcto y soy consciente de que no habrá doble confirmación para realizar el upgrade/actualización.',
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Upgrade/actualización del Plan',
        newProduct: newProduct => `Nuevo Produto: ${newProduct}`,
        agreed: agreed => ` Confirmo que he leído y estoy de acuerdo con la diferencia de valor entre los productos: ${agreed ? 'Si' : 'No'}`,
        agreedSelectedPlan: agreedSelectedPlan => ` Confirmo que seleccione el plan correcto y soy consciente de que no habrá doble confirmación para realizar el upgrade/actualización: ${agreedSelectedPlan ? 'Si' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      title: '¡Complete los datos de abajo!',
      send: 'Enviar',
    },
    privateSslCertificate: {
      title: '¡Complete los datos de abajo!',
      send: 'Enviar',
      info: (
        <>
          <strong>Importante:</strong>
          <ul>
            <li>
              Para que el SSL solicitado sea generado, será adicionada una cuenta de correo electrónico &quot;hostmaster@dominio&quot;. En algunos casos, solicitaremos la creación de esta cuenta. Si tienes alguna duda, entra en contacto con nuestro equipo a través de nuestros Canales de Atención.
            </li>
            <li>
              Este certificado no da derecho a la barra de direcciones verde de Comodo. En caso de que necesites un certificado SSL con esta caracterísitica, por favor llena la solicitud del Certificado SSL EV.
            </li>
            <li>
              En el campo URL para la instalación del SSL, asegúrate de informar el dominio correcto, ya que no es posible emitir el certificado nuevamente para otro dominio.
            </li>
            <li>
              El SSL funcionará unicamente para el dominio informado a continuación (por ejemplo: nombrededominio.com).
            </li>
            <li>
              El certificado SSL es válido únicamente para ser instalado en los servidores de HostGator.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año`,
      },
      subject: 'Soporte Técnico - Certificado SSL Privado',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Privado',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
    widlcardSslCertificate: {
      title: '¡Complete los datos de abajo!',
      send: 'Enviar',
      info: (
        <>
          <strong>Importante:</strong>
          <ul>
            <li>
              Para que el SSL solicitado sea generado, será adicionada una cuenta de correo electrónico &quot;hostmaster@dominio&quot;. En algunos casos, solicitaremos la creación de esta cuenta. Si tienes alguna duda, entra en contacto con nuestro equipo a través de nuestros Canales de Atención.
            </li>
            <li>
              Este certificado no da derecho a la barra de direcciones verde de Comodo. En caso de que necesites un certificado SSL con esta caracterísitica, por favor llena la solicitud del Certificado SSL EV.
            </li>
            <li>
              En el campo URL para la instalación del SSL, asegúrate de informar el dominio correcto, ya que no es posible emitir el certificado nuevamente para otro dominio.
            </li>
            <li>
              El SSL funcionará unicamente para el dominio informado a continuación (por ejemplo: nombrededominio.com).
            </li>
            <li>
              El certificado SSL es válido únicamente para ser instalado en los servidores de HostGator.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año`,
      },
      subject: 'Soporte Técnico - Certificado SSL Wildcard',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Wildcard',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
    changePaymentCycle: {
      info: (
        <>
          {'Si no encuentra la opción deseada en la lista de abajo, inicie un '}
          <strong>atendimiento por chat.</strong>
        </>
      ),
      placeholders: {
        newCycle: 'Seleccione el nuevo ciclo que desea',
        agreed: 'Confirmo el cambio de ciclo de pago para el servicio seleccionado',
        generalComment: 'Comentario adicional',
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Cambiar Ciclo de Pago',
        newCycle: newCycle => `Seleccione el nuevo ciclo que desea: ${newCycle}`,
        agreed: agreed => `Confirmo el cambio de ciclo de pago para el servicio seleccionado: ${agreed ? 'Sí' : 'No'}`,
        generalComment: comment => `Comentario general: ${comment}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      title: '¡Complete los datos de abajo!',
      send: 'Enviar',
      subject: 'Soporte Financiero - Cambiar Ciclo de Pago',
    },
    reloadRebuild: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea con atención los términos a continuación:</strong>
          <ul>
            <li>
              <strong>No realizaremos backup de su servidor.</strong>
            </li>
            <li>
              <strong>Caso usted desee que restauremos su backup, luego del procedimiento, deberá subir todos los archivos para un mismo directorio en hasta 48 horas.</strong>
            </li>
            <li>
              <strong>No nos responsabilizamos por el contenido restaurado, pues no tenemos control sobre el backup disponibilizado.</strong>
            </li>
          </ul>
          <br />
          <strong>{'Importante: '}</strong>
          <ul>
            <li>
              <strong>Este formulario es para solicitudes de Reload/Rebuild. Al solicitar el procedimiento todo el contenido del servidor será removido sin posibilidad de restauración.</strong>
            </li>
          </ul>
          <br />
          <strong>{'Atención: '}</strong>
          <ul>
            <li>
              <strong>El procedimiento será realizado en el servidor con este IP. No habrá doble confirmación</strong>
            </li>
            <li>
              <strong>Al seleccionar una imagen linux sin cPanel, el servidor solo se entrega con SSH configurado. Cualquier configuración adicional es responsabilidad del cliente.</strong>
            </li>
            <li>
              <strong>Si tu servidor contratado es Windows, solo puedes elegir una imagen Windows, si tu servidor contratado es Linux, solo puedes elegir una imagen  Linux. Para cambiar el Sistema Operativo en estos casos, deberá solicitar la baja y realizar una nueva contratación.</strong>
            </li>
          </ul>
        </>
      ),
      placeholders: {
        image: 'Cual es la imagen deseada?',
        canExecuteInComercialHours: 'Podemos hacerlo en horario comercial',
        agreedBrasiliaHours: '08:00 as 18:00',
        agreedRebuildReloadTerms: 'Confirmo que he leído y estoy de acuerdo con los Términos de Reload/Rebuild',
        agreedValue: 'Confirmo que he leído y estoy de acuerdo con los términos antes descritos y con la cobranza del valor de Hora Técnica en caso se encaje en los escenarios descritos en los términos',
        agreedResponsability: 'Confirmo que he leído y estoy de acuerdo en que es mi responsabilidad hacer backups para posterior restauración',
        linuxWithCpanel: 'Imágenes Linux con cPanel',
        linuxWithoutCpanel: 'Imágenes Linux sin cPanel',
        windowsWithPleskWithoutLicense: 'Imágenes Windows con Plesk (no incluida la licencia, debe ser contratada directamente con ellos)',
        windowsWithPleskWithLicense: 'Imágenes Windows con Plesk (incluida la licencia)',
        yes: 'Si',
        no: 'No',
      },
      subject: 'Soporte Técnico - Solicitud de Reload/Rebuild',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitación: Reload/Rebuild',
        image: image => `Cual es la imagen deseada? ${image}`,
        canExecuteInComercialHours: bool => `Podemos hacerlo en horario comercial? ${bool ? 'Si' : 'No'}`,
        agreedBrasiliaHours: bool => `08:00 as 18:00? ${bool ? 'Si' : 'No'}`,
        acuerdoConTerminosRebuildReload: bool => `Confirmo que he leído y estoy de acuerdo con los Términos de Rebuild/Reload? ${bool ? 'Sí' : 'No'}`,
        acuerdoConCobro: bool => `Confirmo que he leído y estoy de acuerdo con los términos anteriores y con el cargo de $250,00 si se ajusta a los escenarios descritos en los términos? ${bool ? 'Sí' : 'No'}`,
        acuerdoResponsabilidad: bool => `Confirmo que he leído y soy consciente de que es mi responsabilidad realizar copias de seguridad para su posterior restauración? ${bool ? 'Sí' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    moreEmails: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea cuidadosamente los siguientes términos:</strong>
          <ul>
            <li>
              {'La limitación es para la seguridad de su servidor y '}
              <strong>
                aumentarla puede aumentar el riesgo de que sus Ips sean bloqueados por los principales proveedores de correo electrónico
              </strong>
            </li>
            <li>
              {'Como se puede ver en los puntos '}
              <Link
                href="https://www.hostgator.mx/terminos#servidores-vps"
                text="20"
                target="_blank"
              />
              {' y '}
              <Link
                href="https://www.hostgator.mx/terminos#servidores-dedicados"
                text="21"
                target="_blank"
              />
              <strong>
                {' es su responsabilidad eliminar las direcciones IP de las listas de bloqueo, monitorear los envíos de correo electrónico y las colas'}
              </strong>
            </li>
            <li>
              No somos responsables por la IP del servidor o por si su dominio está bloqueado por proveedores de correo electrónico debido a su uso, como por ejemplo el envío de correo electrónico de marketing.
            </li>
          </ul>
          <br />
          <span>
            <strong>{'IMPORTANTE: '}</strong>
            El valor que usted ha informado puede ser aplicado o no. El límite se definirá en función del historial de uso del servidor.
          </span>
        </>
      ),
      placeholders: {
        serverIp: '¿Cuál es la IP del servidor?',
        motivation: '¿Por qué desea aumentar el límite de 500 correos electrónicos por hora?',
        newLimit: '¿Cuál es el límite de correos electrónicos por hora que cree que es apropiado para su uso?',
        agreedService: 'Confirmo que he leído y acepto los Términos de servicio',
        agreedPolicy: 'Confirmo que he leído y acepto la Política de correo electrónico',
        agreedResponsability: 'Confirmo que soy consciente de que es mi responsabilidad eliminar las direcciones IP de las listas negras, monitorear los correos y las colas de correo electrónico',
      },
      subject: 'Soporte Técnico - Aumento del envío de correos',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Aumento del envío de correos',
        serverIp: serverIp => `¿Cuál es la IP del servidor?: ${serverIp}`,
        motivation: motivation => `¿Por qué desea aumentar el límite de 500 correos electrónicos por hora?: ${motivation}`,
        newLimit: newLimit => `¿Cuál es el límite de correos electrónicos por hora que cree que es apropiado para su uso?: ${newLimit}`,
        agreedService: agreed => `Confirmo que he leído y acepto los Términos de servicio: ${agreed ? 'Si' : 'No'}`,
        agreedPolicy: agreed => `Confirmo que he leído y acepto la Política de correo electrónico: ${agreed ? 'Si' : 'No'}`,
        agreedResponsability: agreed => `Confirmo que soy consciente de que es mi responsabilidad eliminar las direcciones IP de las listas negras, monitorear los correos y las colas de correo electrónico: ${agreed ? 'Si' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    migrateAccountVpsDedicated: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea cuidadosamente los siguientes términos:</strong>
          <ul>
            <li>
              Las versiones PHP 5.2 y 5.3 fueron descontinuadas en nuestra estructura para evitar vulnerabilidades. Pedimos que tus sitio web estén debidamente actualizados de acuerdo a la versión PHP 5.6 (o superior) para que no ocurran problemas durante la migración.
            </li>
          </ul>
          <br />
          <span>
            <strong>{'Importante: '}</strong>
          </span>
          <ul>
            <li>
              {'Antes de completar este formulario, por favor lee los '}
              <Link
                text="términos de migración"
                href={`https://www.hostgator.${COUNTRY.toLowerCase()}/terminos-de-migracion`}
                target="_blank"
              />
            </li>
            <li>NO CANCELES tu antiguo plsm hasta que los archivos se hayan migrado, los hayas probado y los DNS se hayan cambiado correctamente.</li>
          </ul>
        </>
      ),
      placeholders: {
        ipOrDomain: 'Dirección IP o nombre de dominio de la cuenta de hosting anterior',
        user: 'Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor',
        password: 'Introduce la contraseña para acceder al panel administrativo del antiguo proveedor',
        detailedList: 'Por favor propociónanos una lista completa de las cuentas que deseas migrar, con todos los detalles necesarios, tales como nombre de usuario, contraseña y panel de control que estaba utilizando',
        agreed: 'Entiendo y estoy de acuerdo con los términos de la migración',
      },
      subject: 'Soporte Técnico - Migración de Cuenta de VPS/Dedicado',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitaión: Migración de Cuenta de VPS/Dedicado',
        ipOrDomain: ipOrDomain => `Dirección IP o nombre de dominio de la cuenta de hosting anterior: ${ipOrDomain}`,
        user: user => `Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor: ${user}`,
        password: password => `Introduce la contraseña para acceder al panel administrativo del antiguo proveedor: ${password}`,
        detailedList: detailedList => `Por favor propociónanos una lista completa de las cuentas que deseas migrar, con todos los detalles necesarios, tales como nombre de usuario, contraseña y panel de control que estaba utilizando: ${detailedList}`,
        agreed: agreed => `Entiendo y estoy de acuerdo con los términos de la migración: ${agreed ? 'Si' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    updateWhmcsLicense: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea cuidadosamente los siguientes términos:</strong>
          <ul>
            <li>
              <strong>
                Este es un formulario de actualización de licencia, no un formulario de adquisición.
              </strong>
              {' Para la contratación, consulte el material de '}
              <Link
                text="¿Cómo obtener la licencia de WHMCS?"
                href="https://soporte-latam.hostgator.com/hc/es-419/articles/115003219111"
                target="_blank"
              />
            </li>
            <li>
              La licencia gratuita esta disponible solo para los planes de hospedajes Reseller y Dedicados.
            </li>
            <li>
              La licencia gratuita esta limitada a 249 cuentas registradas en WHMCS.
            </li>
            <li>
              Los planos de hospedaje Reseller y Dedicados incluyen solo una licencia gratuita de WHMCS.
            </li>
            <li>
              La licencia WHMCS ofrecida por HostGator solo es valida si WHMCS esta instalado en la cuetna de HostGator.
            </li>
          </ul>
          <br />
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>
              HostGator no se responsabiliza por la instalación y configuración de WHMCS. En el caso de que se requiera, existe un pago adicional por el servicio.
            </li>
            <li>
              No damos soporte sobre la plataforma de WHMCS, solo instalamos y/o actualizamos las lincencias.
            </li>
            <li>
              Deberá acceder al panel de control de administración al menos 1 (una vez) al mes para mantener su licencia activa.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'Dominio que activó WHMCS',
        domainInfo: 'Informar el nuevo dominio. Si no debe cambiarse, solo informe al dominio actual.',
        license: 'Licencia WHMCS',
        directory: 'Directorio donde está instalado WHMCS',
        directoryInfo: 'Informar al nuevo directorio. Si no debe cambiarse, solo informe al directorio actual.',
        accessLink: 'Enlace de acceso al panel de administración de WHMCSLink de acesso ao painel admin do WHMCS',
        serverIp: 'Dirección IP del servidor',
        serverIpInfo: (
          <>
            {'Déjelo en blanco si no conoce la IP o consígalo a través del panel de WHMCS '}
            <Link
              href="https://www.whmcs.com/members/"
              target="_blank"
              text="https://www.whmcs.com/members/"
            />
          </>
        ),
        agreed: 'Confirmo que lei y estoy de acuerdo con los teminos y condiciones informado',
      },
      subject: 'Soporte Técnico - Actualización de licencia WHMCS (ES)',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitación: Actualización de licencia WHMCS (ES)',
        agreed: agreed => `Confirmo que lei y estoy de acuerdo con los teminos y condiciones informado: ${agreed ? 'Si' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    whmcsAcquisition: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>
            {'Antes de continuar, verifique si hay algun material de apoyo en nuestra Central de Ayuda que pueda ayudarte, de esta forma ahorras tiempo y no necesitarias esperar en fila para ser atendido :) '}
          </strong>
          <ul>
            <li>
              <Link
                text="¿Qué es WHMCS?"
                href="https://soporte-latam.hostgator.com/hc/es-419/articles/216761998"
                target="_blank"
              />
            </li>
            <li>
              <Link
                text="¿Cómo obtener la licencia de WHMCS?"
                href="https://soporte-latam.hostgator.com/hc/es-419/articles/115003219111"
                target="_blank"
              />
            </li>
            <li>
              <Link
                text="¿Cómo instalar el WHMCS?"
                href="https://soporte-latam.hostgator.com/hc/es-419/articles/360038065951"
                target="_blank"
              />
            </li>
          </ul>
          <br />
          <strong>Lea con atencion los terminos a seguir:</strong>
          <ul>
            <li>
              La licencia gratuita esta disponible solo para los planes de hospedajes Reseller y Dedicados
            </li>
            <li>
              Los planos de hospedaje Reseller y Dedicados incluyen solo una licencia gratuita de WHMCS.
            </li>
            <li>
              Os planos de Revenda de hospedagem e Dedicado incluem uma única licença gratuita do WHMCS.
            </li>
            <li>
              La licencia WHMCS ofrecida por HostGator solo es valida si WHMCS esta instalado en la cuetna de HostGator.
            </li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>
              HostGator no se responsabiliza por la instalación y configuración de WHMCS. En el caso de que se requiera, existe un pago adicional por el servicio.
            </li>
            <li>
              No damos soporte sobre la plataforma de WHMCS, solo instalamos y/o actualizamos las lincencias.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'Informe el dominio a ser utilizado en la solicitud',
        email: 'Informe el email a ser utilizado en la solicitud',
        problem: 'Describa su problema y mencione cual es el mensaje de error (caso exista)',
        directory: 'Informe el email a ser utilizado en la solicitud',
        print: 'Tienes algun print o foto del error o del problema? Envianosla!',
        addDocumentButton: 'Adjuntar documento',
        agreed: 'Confirmo que lei y estoy de acuerdo con los teminos y condiciones informados.',
      },
      subject: 'Soporte Técnico - Adquisición de la Licencia de WHMCS (ES)',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitaión: Adquisición de la Licencia de WHMCS (ES)',
        agreed: ({ text, bool }) => `${text}: ${bool ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    migrateReseller: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea atentamente los siguientes términos:</strong>
          <ul>
            <li>
              Las versiones PHP 5.2 y 5.3 fueron descontinuadas en nuestra estructura para evitar vulnerabilidades. Pedimos que tus sitio web estén debidamente actualizados de acuerdo a la versión PHP 5.6 (o superior) para que no ocurran problemas durante la migración.
            </li>
          </ul>
          <br />
          <span>
            <strong>IMPORTANTE:</strong>
            <ul>
              <li>
                {'Antes de completar este formulario, por favor lee los '}
                <Link
                  text="términos de migración"
                  href={`https://www.hostgator.${COUNTRY.toLowerCase()}/terminos-de-migracion`}
                  target="_blank"
                />
              </li>
              <li>
                NO CANCELES tu antiguo plan hasta que los archivos se hayan migrado, los hayas probado y los DNS se hayan cambiado correctamente.
              </li>
            </ul>
          </span>
        </>
      ),
      subject: 'Soporte Técnico - Migración de Cuentas de Revendedor',
      placeholders: {
        domain: 'Dominio registrado en el antiguo proveedor de hosting',
        panel: '¿Cuál es el panel administrativo del servidor antiguo?',
        user: 'Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor',
        password: 'Introduce la contraseña para acceder al panel administrativo del antiguo proveedor',
        other: 'Otro',
        dontKnow: 'No sé',
        link: 'Link para acceder al panel administrativo del antiguo proveedor',
        migrateAll: 'Selecciona esta opción únicamente si deseas migrar TODOS los campos existentes en el WHM/Plesk del antiguo servidor para el nuevo plan en HostGator',
        multipleDomainsLabel: 'Si no seleccionas la casilla, introduce en el cuadro de texto a continuación un máximo de 30 dominios para que sean migrados de forma gratuita, manteniendo un dominio por línea, como se muestra',
        multipleDomainsPlaceHolder: 'Ejemplo:\ndominio1.com\ndominio2.com\ndominio3.com',
        agreed: 'Entiendo y estoy de acuerdo con los términos de la migración',
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitaión: Migración de Cuentas de Revendedor',
        agreed: ({ text, bool }) => `${text}: ${bool ? 'Sí' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    successModal: {
      title: '¡Recibimos su ticket!',
      description: (
        <>
          <strong>Este atento al plazo de respuesta.</strong>
          <br />
          <span>
            {'En hasta '}
            <strong>48h horas hábiles</strong>
            {' (De lunes a viernes) recibirá una respuesta de nuestro equipo de soporte.'}
          </span>
        </>
      ),
      closeLabel: 'Volver para el soporte',
    },
    names: {
      internalMigration: 'Migración Interna (Entre cuentas HostGator)',
      restorateBackupHgRoutine: 'Restauración de Backup (Rutina HostGator)',
      userBackupRestore: 'Restauración de Backup (archivo fornecido por el cliente)',
      generateBackup: 'Generar Backup',
      withoutEmailAccess: 'No tengo acceso al correo electrónico registrado',
      changePrimaryDomain: 'Alteración de Dominio Primario',
      googleAdwordsCoupon: 'Cupón de Google AdWords (ES)',
      externalMigration: 'Migração externa (Outros provedores)',
      installExternalSsl: 'Instalación del SSL de otra empresa',
      financialDoubts: 'Dudas Financieras Generales',
      restorateCancelledPlan: 'Restauración de plan cancelado',
      affiliateProgram: 'Preguntas exclusivamente sobre el Programa de Afiliados',
      upgradeVps: 'Upgrade de un plan',
      ChangePaymentcycle: 'Cambiar Ciclo de Pago',
      changeDueDate: 'Cambiar Fecha de Vencimiento',
      privateSslCertificate: 'Certificado SSL Privado',
      widlcardSslCertificate: 'Certificado SSL Wildcard',
      reloadRebuild: 'Solicitud de rebuild/reload',
      moreEmails: 'Aumento del envío de correos',
      migrateAccountVpsDedicated: 'Migración de Cuenta de VPS/Dedicado',
      updateWhmcsLicense: 'Actualización de licencia WHMCS',
      whmcsAcquisition: 'Aquisição Licença WHMCS',
      migrateReseller: 'Migración de Cuentas de Revendedor',
    },
  },
  history: {
    attachFile: 'Adjuntar archivo',
    draggableTitle: 'Seleccione un archivo de su dispositivo o arrastre y suelte el archivo en esta área.',
    findFile: 'Buscar archivo',
    placeHolder: 'Envía un mensaje',
    send: 'Enviar',
    status: {
      closed: 'Finalizado',
      solved: 'Completado',
      new: 'Abierto',
      open: 'Pendiente',
      opened: 'Pendiente',
      pending: 'Esperando respuesta',
      emptyLabel: 'No se pudo cargar la información del ticket',
      emptyCta: 'Ir a la lista de tickets',
    },
    ticketDate: ({ comment }) => {
      const isToday = isSameDay(new Date(comment.created_at), new Date());
      if (isToday) {
        return format(new Date(comment.created_at), 'H:mm aa');
      }

      return `${format(new Date(comment.created_at), 'EEEE, dd/MM/yyyy', {
        locale: es,
      })} a las ${format(new Date(comment.created_at), 'H:mm aa', {
        locale: es,
      })}`;
    },
  },
  underMaintenance: (
    <>
      Estamos en mantenimiento
      <br />
      Siga sus tickets en su correo electrónico.
    </>
  ),
};
