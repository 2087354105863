import React from 'react';
import { billingActions } from '@/redux/modules';
import { debounce } from '@/utils/debounce';
import { locale } from '@/utils/locale';
import { CANCEL_SUBSCRIPTION_STEPS, REFUND_PAYMENT_METHODS } from '@/enums/billing/billing.enum';
import { SubscriptionsQuestionTypes } from '@/redux/modules/billing/billing.types';
import HostGator from '@/media/subscriptions/refund/HostGator.svg';
import { IconCreditCard, IconPix, IconPayPal } from '@/icons';
import { refundModalLocale, subscriptionLocale } from './CancelSubscriptionHandler.locale';
import { billingAnalytics } from '@/analytics';
import { isBRServer, isMXServer } from '@/utils/Validators/validation';

const createFunctions = ({
  dispatch,
  history,
  questions,
  setExpandRefundModalAccordion,
  setOpenRefundModal,
  setStep,
  step,
  subscriptionId,
  subscriptionType,
  openPreChatCancelFlow,
}) => {
  const isXrayRefundPaymentCreditCardEnabled = true;
  const isXrayRefundPaymentPaypalEnabled = isBRServer || isMXServer;
  const isXrayRefundPaymentPixEnabled = false;
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const steps = [
    {
      id: CANCEL_SUBSCRIPTION_STEPS.SURVEY,
      label: subscriptionLocale.stepSurveyLabel,
    },
    {
      id: CANCEL_SUBSCRIPTION_STEPS.REFUND,
      label: subscriptionLocale.stepRefundLabel,
    },
    {
      id: CANCEL_SUBSCRIPTION_STEPS.REVIEW,
      label: subscriptionLocale.stepReviewLabel,
    },
    {
      id: CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION,
      label: subscriptionLocale.stepConfirmationLabel,
    },
  ];

  const getPaymentOptions = () => ({
    [REFUND_PAYMENT_METHODS.HG_CREDITS]: {
      show: true,
      image: <img src={HostGator} alt="hostgator" />,
      name: subscriptionLocale.refundMethodOptions.hg_credits.name,
      description: subscriptionLocale.refundMethodOptions.hg_credits.description,
      info: subscriptionLocale.refundMethodOptions.hg_credits.info,
    },
    [REFUND_PAYMENT_METHODS.BRASPAG_CREDIT_1X]: {
      show: isXrayRefundPaymentCreditCardEnabled,
      image: <IconCreditCard />,
      name: subscriptionLocale.refundMethodOptions.braspag_credit_1x.name,
      description: subscriptionLocale.refundMethodOptions.braspag_credit_1x.description,
      info: subscriptionLocale.refundMethodOptions.braspag_credit_1x.info,
    },
    [REFUND_PAYMENT_METHODS.DLOCAL]: {
      show: isXrayRefundPaymentCreditCardEnabled,
      image: <IconCreditCard />,
      name: subscriptionLocale.refundMethodOptions.dlocal.name,
      description: subscriptionLocale.refundMethodOptions.dlocal.description,
      info: subscriptionLocale.refundMethodOptions.dlocal.info,
    },
    [REFUND_PAYMENT_METHODS.PIX]: {
      show: isXrayRefundPaymentPixEnabled,
      image: <IconPix />,
      name: subscriptionLocale.refundMethodOptions.gp_boleto.name,
      description: subscriptionLocale.refundMethodOptions.gp_boleto.description,
      info: subscriptionLocale.refundMethodOptions.gp_boleto.info,
    },
    [REFUND_PAYMENT_METHODS.GP_PAYPAL]: {
      show: isXrayRefundPaymentPaypalEnabled,
      image: <IconPayPal />,
      name: subscriptionLocale.refundMethodOptions.gp_paypal.name,
      description: subscriptionLocale.refundMethodOptions.gp_paypal.description,
      info: subscriptionLocale.refundMethodOptions.gp_paypal.info,
    },
    [REFUND_PAYMENT_METHODS.PAYPAL]: {
      show: isXrayRefundPaymentPaypalEnabled,
      image: <IconPayPal />,
      name: subscriptionLocale.refundMethodOptions.gp_paypal.name,
      description: subscriptionLocale.refundMethodOptions.gp_paypal.description,
      info: subscriptionLocale.refundMethodOptions.gp_paypal.info,
    },
  });

  const resetOptionsByQuestionName = (type) => {
    const question = questions[type][0];

    question.options = question.options.map(option => ({
      ...option,
      ...(!option.selected && { selected: true }),
    }));

    dispatch(billingActions.subscriptions.questions.set(question, type));
  };

  const resetCancelSignatureSurvey = () => {
    dispatch(billingActions.subscriptions.cancel.set({
      questionId: null,
      optionId: null,
      questionValue: '',
      reason: '',
    }));
  };

  const handleOnOptionSelected = (payload) => {
    dispatch(billingActions.subscriptions.cancel.set(payload));
  };

  const handleOnReasonChange = debounce((payload) => {
    dispatch(billingActions.subscriptions.cancel.set(payload));
  });

  const handleOnStopCancel = () => {
    resetCancelSignatureSurvey();
    subscriptionAnalytics.cancelSubscriptionClick('6_desisir_cancelar_imediato');
    openPreChatCancelFlow();
    history.push(locale('routes.subscriptions'));
  };

  const handleOnRefundModalClose = () => {
    setOpenRefundModal(false);
    setExpandRefundModalAccordion(false);
  };

  const handleOnRefundPolicyClick = () => {
    setExpandRefundModalAccordion(true);
  };

  const handleOnServiceTermsClick = () => {
    window.open(refundModalLocale.serviceTermsUrl, '_blank');
  };

  const handleOnRefundPoliticsClick = () => {
    subscriptionAnalytics.cancelSubscriptionClick('4_ver_politica_reembolso_cancelar_imediato');
    setOpenRefundModal(true);
  };

  const onPreviousStepClick = () => {
    const steps = {
      [CANCEL_SUBSCRIPTION_STEPS.SURVEY]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('3_voltar_pesquisa_respondida_cancelar_imediato');
        resetCancelSignatureSurvey();
        openPreChatCancelFlow();
        history.push(locale('routes.subscriptions'));
      },
      [CANCEL_SUBSCRIPTION_STEPS.REFUND]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('4_voltar_reembolso_cancelar_imediato');
        setStep(CANCEL_SUBSCRIPTION_STEPS.SURVEY);
      },
      [CANCEL_SUBSCRIPTION_STEPS.REVIEW]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('5_voltar_conferir_informacao_cancelar_imediato');
        setStep(CANCEL_SUBSCRIPTION_STEPS.REFUND);
      },
      [CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('6_voltar_para_pesquisa_cancelar_imedito');
        setStep(CANCEL_SUBSCRIPTION_STEPS.REVIEW);
      },
    };

    steps[step]();
  };

  const onFowardStepClick = () => {
    const steps = {
      [CANCEL_SUBSCRIPTION_STEPS.SURVEY]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('3_continuar_pesquisa_respondida_cancelar_imediato');
        setStep(CANCEL_SUBSCRIPTION_STEPS.REFUND);
      },
      [CANCEL_SUBSCRIPTION_STEPS.REFUND]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('4_continuar_reembolso_cancelar_imediato');
        setStep(CANCEL_SUBSCRIPTION_STEPS.REVIEW);
      },
      [CANCEL_SUBSCRIPTION_STEPS.REVIEW]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('5_continuar_conferir_informacao_cancelar_imediato');
        setStep(CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION);
      },
      [CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION]: () => {
        setStep(CANCEL_SUBSCRIPTION_STEPS.DONE);
        subscriptionAnalytics.cancelSubscriptionClick('6_cta_cancelar_imediato');
        dispatch(billingActions.subscriptions.cancelSignature.request({
          productId: subscriptionId,
          productType: subscriptionType,
          history,
          openPreChatCancelFlow,
        }));

        resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_SIGNATURE);
      },
    };

    steps[step]();
  };

  const resetSurveyEffect = () => {
    resetCancelSignatureSurvey();

    if (questions[SubscriptionsQuestionTypes.CANCEL_SIGNATURE]) {
      resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_SIGNATURE);
    }
  };

  const requestQuestionsEffect = () => {
    if (!questions[SubscriptionsQuestionTypes.CANCEL_SIGNATURE]) {
      dispatch(billingActions.subscriptions.questions.request(
        SubscriptionsQuestionTypes.CANCEL_SIGNATURE,
      ));
    }
  };

  return {
    values: {
      steps,
    },
    methods: {
      getPaymentOptions,
      handleOnOptionSelected,
      handleOnReasonChange,
      handleOnRefundModalClose,
      handleOnRefundPolicyClick,
      handleOnRefundPoliticsClick,
      handleOnServiceTermsClick,
      handleOnStopCancel,
      onFowardStepClick,
      onPreviousStepClick,
      requestQuestionsEffect,
      resetCancelSignatureSurvey,
      resetOptionsByQuestionName,
      resetSurveyEffect,
    },
  };
};

export default createFunctions;
