import React from 'react';
import { Card, withStyles } from '@material-ui/core';
import styles from './styles';


function InvoiceSummaryCardTicket({ children, classes, status }) {
  return (
    <Card elevation={1} className={`${classes.container} ${classes[status]}`}>
      {children}
    </Card>
  );
}

export default withStyles(styles, { withTheme: true })(InvoiceSummaryCardTicket);
