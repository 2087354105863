import React from 'react';
import { Button } from '@/pages/common/v1/Button';
import * as Styles from './SnappyBanner.styles';
import bannerSnappy from '@/media/codeguard/banner-snappy.png';

const SnappyBanner = ({
  title = 'tilte',
  buttonLabel = 'button label',
  gaGetNowAnchor,
}) => {
  const moveScreenToTop = () => {
    gaGetNowAnchor();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Styles.Container>
      <Styles.ContentWrapper>
        <Styles.Title>{title}</Styles.Title>
        <Styles.ButtonWrapper>
          <Button
            testId="snappy-banner-button"
            label={buttonLabel}
            onClick={() => moveScreenToTop()}
            variant="tertiary"
          />
        </Styles.ButtonWrapper>
      </Styles.ContentWrapper>
      <Styles.BannerSnappy src={bannerSnappy} />
    </Styles.Container>
  );
};

export default SnappyBanner;
