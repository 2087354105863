/* eslint-disable react/no-unescaped-entities */
import { Button, Link } from 'gatorcomponents';
import React from 'react';

export default {
  tabs: {
    labels: {
      general: 'Visión General',
      create: 'Realizar copia de seguridad',
      restore: 'Restaurar Backup',
      log: 'Últimos Backups',
      cpanel: 'Copia de seguridad cPanel',
    },
    create: {
      title: 'Realizar copia de seguridad',
      description: 'Descargue la copia del directorio inicial de archivos de su alojamiento.',
      alertTitle: 'Vea los detalles de esta copia de seguridad',
      alertDescription: (
        <>
          Esta copia de seguridad descargará en su computador
          {' '}
          <strong>solo los archivos del directorio principal de su sitio</strong>
          {' '}
          (no incluye base de datos ni correos electrónicos).
          <br />
          <strong>Recuerde</strong>
          : cada copia de seguridad completa tiene un límite de 10 GB.
          <br />
          Para guardar, recomendamos que la descargue en su computador y la guarde en algún lugar seguro.
        </>
      ),
      alertDescription2: onClick => (
        <>
          <p>
            {'Este backup descargará en su computadora solo '}
            <strong>los archivos del directorio principal de su alojamiento</strong>
            {' (no incluye la base de datos ni correos electrónicos).'}
          </p>

          <p>No cierre su navegador hasta que se complete la descarga del archivo.</p>

          <p>
            {'Si necesita una copia de seguridad completa, que incluya todos los archivos, bases de datos y correos electrónicos, '}
            <Button variant="text" onClick={onClick} label="utilice este formulario." />
          </p>
        </>
      ),
      proceedAlertDescription: (
        <>
          Al continuar, la copia de seguridad de sus archivos
          {' '}
          <strong>se descargará en su computador</strong>
          . Espere a que se complete y, si desea restaurar, suba los archivos en la pestaña “Restaurar copia de seguridad”.
        </>
      ),
      backupLabel: 'Descargar backup',
      backupError: {
        title: 'Error al realizar la copia de seguridad',
        description: 'Tuvimos un problema al generar su copia de seguridad. Espere un momento e inténtelo de nuevo.',
      },
      backupSuccess: {
        title: 'Copia de seguridad generada',
        description: 'Su copia de seguridad ha sido generada y el archivo está siendo descargado por su navegador. Vea el archivo en las descargas de su computador y no vuelva a generar una copia de seguridad hasta su finalización.',
      },
    },
    restore: {
      title: 'Restaurar copia de seguridad - Directorio Inicial o Base de Datos',
      description: 'Vea como restaurar una copia de seguridad.',
      alertTitle: 'Sepa como restaurar',
      alertDescription: (
        <>
          <p>Para restaurar una copia de seguridad desde el archivo que descargaste previamente (Directorio Inicial o Base de Datos), sigue estos pasos:</p>

          <p>
            <ol>
              <li>Localiza el archivo de copia de seguridad en tu ordenador.</li>
              <li>
                Sube el archivo utilizando la opción
                <strong>{' “Restaurar” '}</strong>
                en cPanel.
              </li>
              <li>Espera a que todos los archivos se transfieran.</li>
              <li>La restauración estará completa.</li>
            </ol>
          </p>

          <p>
            {'Atención: el archivo debe tener la extensión '}
            <strong>“.tar”</strong>
            {' o '}
            <strong>“.tar.gz”</strong>
            {'. El tamaño máximo del archivo a cargar debe ser de '}
            <strong>500MB para el Directorio Inicial y 50MB para la Base de Datos.</strong>
          </p>

          <p>
            {'Para obtener más instrucciones, consulta el material de apoyo '}
            <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/18012814637083#restauracion-directorio-principal" />
          </p>

          <p>
            {'Si el archivo es más grande de lo indicado, te recomendamos '}
            <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360022155171" text="restaurar mediante FTP." />
          </p>
        </>
      ),
      agreement: 'Al continuar, se le dirigirá a la pantalla de restauración de backup (copia de seguridad) de cPanel donde puede subir y restaurar el directorio inicial de su sitio web.',
      uploadLabel: 'Restaurar via cPanel',
    },
    generalVision: {
      title: 'Visión General',
      description: (date, hour) => (
        <>
          {'Tu última copia de seguridad se realizó el '}
          <strong>{`${date} `}</strong>
          <strong>
            {`a las ${hour}h`}
          </strong>
          . Revisa los detalles a continuación:
        </>
      ),
      noBackupDescription: 'No tienes una copia de seguridad reciente. Revisa los detalles a continuación:',
      centralQuestions: () => (
        <>
          {'Consulta las '}
          <strong>preguntas y respuestas principales</strong>
          {' aquí:'}
        </>
      ),
      status: {
        completed: 'COMPLETADO',
        attention: 'ATENCIÓN',
        error: 'ERROR',
        inProgress: 'EN CURSO',
        completedAlert: date => (
          <>
            {'Tu última copia de seguridad '}
            <strong>
              {`se realizó con éxito el ${date}`}
            </strong>
            {'. ¡Esto significa que tu copia de seguridad está actualizada! :) '}
          </>
        ),
        noBackupAlert: handleClick => (
          <>
            No tienes una copia de seguridad de este plan.
            <br />
            <Button type="button" variant="text" onClick={() => handleClick()} label="Realiza una copia de seguridad" />
            <br />
            <strong>ahora para no perder tus datos</strong>
            .
          </>
        ),
        oldBackupAlert: (handleClick, days) => (
          <>
            {`Han pasado más de ${days} días desde tu última copia de seguridad. `}
            <Button type="button" variant="text" onClick={() => handleClick()} label="Actualiza tu copia de seguridad" />
            <br />
            <strong>ahora para no perder tus datos</strong>
            .
          </>
        ),
        errorBackupAlert: handleClick => (
          <>
            {'Hemos tenido un problema al realizar tu copia de seguridad. Espera unos momentos y '}
            <Button type="button" variant="text" onClick={() => handleClick()} label="haz clic aquí para intentar nuevamente" />
            .
          </>
        ),
        inProgressBackupAlert: 'Tu copia de seguridad está en proceso. Espera unos momentos para que esté completa.',
      },
      firstStep: {
        title: 'Primeros Pasos - Copia de Seguridad',
        description: 'Realiza copias de seguridad y restauraciones de tu sitio web con facilidad siguiendo nuestros simples pasos.',
        label: 'Iniciar Restauración',
        labelWithGeneration: 'Iniciar Copia de Seguridad/Restauración',
      },
      faq: {
        faqTitle: (
          <>
            {'Consulta las '}
            <strong>preguntas y respuestas principales</strong>
            {' aquí:'}
          </>
        ),
        backupTypesAccordion: {
          title: '¿Cuáles son los tipos de copia de seguridad?',
          content: {
            description: 'Hay varias opciones para tener una copia de seguridad de los datos que desee. Dependiendo del tipo de copia de seguridad realizada obtendrá diferentes resultados. Vea a continuación cuáles son:',
            fullBackup: (
              <>
                <strong>Copia de seguridad completa:</strong>
                {' hace una copia de todo lo que está vinculado a su cuenta de hosting. Esto incluye archivos y carpetas de los directorios, como correos electrónicos de webmail (cPanel) y base de datos de todos los sitios web existentes en su cuenta de hosting (directorio principal, dominios y subdominios adicionales). Las cuentas de correo electrónico de Titan no ingresan en la copia de seguridad completa, deberá '}
                <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360059617832" text="realizar el procedimiento" />
                {' por separado.'}
              </>
            ),
            parcialBackup: (
              <>
                <strong>{'Copia de seguridad del directorio principal (copia de seguridad parcial): '}</strong>
                realiza la copia solamente de los archivos del dominio principal o raíz.
                El directorio raíz es donde se almacenan los archivos del dominio principal de su sitio web, generalmente es la carpeta "public_html" - Excepto cuando el sitio web se ha instalado en otra carpeta, es un subdominio o un dominio adicional, y no incluye base de datos ni correos electrónicos.
              </>
            ),
            emailsBackup: (
              <>
                <strong>{'Copia de seguridad de correos electrónicos: '}</strong>
                {`realiza una copia de seguridad de la información almacenada en las cuentas de correo electrónico webmail (cPanel) de cada dominio.
                Si tiene una cuenta de correo electrónico de Titan no realizamos la copia de seguridad, para ello, vea cómo `}
                <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360059617832" text="realizar el procedimiento" />
                .
              </>
            ),
            databaseBackup: (
              <>
                <strong>{'Copia de seguridad de la base de datos: '}</strong>
                Realiza la copia de todas las tablas que almacena los datos de un sitio web (normalmente en formato SQL).
              </>
            ),
          },
        },
        createBackupAccordion: {
          title: '¿Cómo hacer una copia de seguridad? ',
          content: {
            description: 'Hay dos formas de hacer una copia de seguridad:',
            byCpanel: (
              <>
                <strong>{'Copia de seguridad avanzada a través del cPanel: '}</strong>
                Esta copia de seguridad le permite descargar por separado archivos y carpetas, base de datos y correos electrónicos, siempre y cuando sea webmail (cPanel).
              </>
            ),
            byPortal: (
              <>
                <strong>Copia de seguridad a través del Portal del Cliente:</strong>
                {' realiza una copia de seguridad solo de los archivos que se encuentran en el directorio principal (dominio principal) de su sitio. A continuación, le indicamos cómo:'}
                <section>
                  <span>1.</span>
                  En la pantalla "Administrar plan", haga clic en
                  <>&nbsp;</>
                  <strong>Realizar copia de seguridad</strong>
                  ;
                </section>
                <section>
                  <span>2.</span>
                  En "Copia de seguridad", haga clic en
                  <>&nbsp;</>
                  <strong>Copia de seguridad, Portal del Cliente</strong>
                  ;
                </section>
                <section>
                  <span>3.</span>
                  Luego, haga clic en
                  <>&nbsp;</>
                  <strong>Copia de seguridad</strong>
                  ;
                </section>
                <section>
                  <span>4.</span>
                  Después de eso, el archivo estará disponible para
                  <>&nbsp;</>
                  <strong>descargar en su computador</strong>
                  .
                </section>
              </>
            ),
          },
        },
        maxSizeBackupAccordion: {
          title: '¿Cuál es el tamaño máximo de mi almacenamiento?',
          content: {
            paragraphOne: (
              <>
                {'Para realizar la copia de seguridad, debe tener '}
                <strong>menos de 10GB </strong>
                {' de contenido en uso en el plan de hosting/alojamiento.'}
              </>
            ),
            paragraphTwo: 'Si excede los límites mencionados anteriormente, saldrá automáticamente de nuestra rutina de almacenamiento.',
            paragraphThree: 'La copia de seguridad estará disponible para descargar en su computador.',
          },
        },
        restoreBackupAccordion: {
          title: '¿Cómo restaurar una copia de seguridad? ',
          content: {
            description: 'Antes de realizar la restauración, es importante asegurarse de que sus archivos no estén dañados y que su copia de seguridad contenga todo lo que necesita para realizar una restauración completa.',
            subdescription: (
              <>
                {'Existen '}
                <strong>dos opciones</strong>
                {' para restaurar la copia de seguridad:'}
              </>
            ),
            byPortal: (
              <>
                <strong>A través del Portal del cliente - </strong>
                {' usted cargará el archivo de copia de seguridad, vea como:'}
                <section>
                  <span>1.</span>
                  Localice el archivo de copia de seguridad que descargó en su computadora - el archivo debe ser con
                  <>&nbsp;</>
                  <strong>extensión "tar.gz" </strong>
                  ;
                </section>
                <section>
                  <span>2.</span>
                  En la pantalla "Restaurar copia de seguridad", haga clic en
                  <>&nbsp;</>
                  <strong>Cargar</strong>
                  ;
                </section>
                <section>
                  <span>3.</span>
                  A continuación, haga clic en
                  <>&nbsp;</>
                  <strong>Restaurar via cPanel</strong>
                  ;
                </section>
                <section>
                  <span>3.</span>
                  Para finalizar el procedimiento, aparecerá un mensaje de finalización de carga, haga clic en
                  <>&nbsp;</>
                  <strong>Ok, entiendo</strong>
                  .
                </section>
              </>
            ),
            bySupport: (
              <>
                <strong>A través de Soporte</strong>
                {' - es necesario abrir un ticket - '}
                <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/360052526712#a-traves-del-suporte" text="Aquí se explica cómo hacerlo" />
              </>
            ),
            footer: 'Recuerde que todos los archivos actuales en su sitio se sobrescribirán con los archivos presentes en la copia de seguridad que se restaurarán.',
          },
        },
        whyDoBackupAccordion: {
          title: '¿Por qué es importante mantener mi copia de seguridad actualizada?',
          content: {
            paragraphOne: 'Hacer una copia de seguridad de la cuenta es un acto de prevención de pérdida de datos. Esta acción le permite obtener una copia de seguridad de su cuenta en caso de que haya un problema con ella.',
            paragraphTwo: 'HostGator no es responsable de los archivos y / o datos almacenados en la cuenta. Es muy importante que mantenga sus propias copias de seguridad en un lugar seguro.',
            paragraphThree: 'Se recomienda que realice y actualice copias de seguridad regularmente, asegurándose de que la versión más actualizada esté siempre disponible para su restauración. La frecuencia de las actualizaciones y ediciones dentro de su sitio generalmente determinará con qué recurrencia debe realizar copias de seguridad.',
          },
        },
      },
    },
    log: {
      title: 'Últimos backups',
      description: 'Aqui você pode ver o histórico dos seus últimos backups.',
      tags: {
        success: 'COMPLETADO',
        error: 'ERROR',
        ongoing: 'EN CURSO',
        warning: 'ATENCIÓN',
        failed: 'FALHA',
      },
      info: {
        success: date => (
          <>
            {'Tu última copia de seguridad '}
            <strong>
              {`se realizó con éxito el ${date}`}
            </strong>
            {'. ¡Esto significa que tu copia de seguridad está actualizada! :) '}
          </>
        ),
        warning: (days, goToCPanel) => (
          <>
            {`Han pasado más de ${days} días desde tu última copia de seguridad. `}
            <Button variant="text" onClick={goToCPanel} label="Actualiza tu copia de seguridad " />
            <br />
            <strong>ahora para no perder tus datos.</strong>
          </>
        ),
        empty: goToCPanel => (
          <>
            No tienes una copia de seguridad de este plan.
            <br />
            <Button variant="text" onClick={goToCPanel} label="Realiza una copia de seguridad " />
            {' '}
            <strong>ahora para no perder tus datos.</strong>
          </>
        ),
        error: handleClick => (
          <>
            {'Hemos tenido un problema al realizar tu copia de seguridad. Espera unos momentos y '}
            <Button type="button" variant="text" onClick={() => handleClick()} label="haz clic aquí para intentar nuevamente" />
            {'. '}
          </>
        ),
        ongoing: () => (
          <>
            Tu copia de seguridad está en proceso.
            <br />
            Espera unos momentos para que esté completa.
          </>
        ),
      },
      loadMoreLabel: 'Ver mais',
    },
  },
  modal: {
    title: 'Realizar backup (copia de seguridad)',
    description: () => (
      <>
        Haz una copia de seguridad completa de tu sitio web para guardar tus datos y restaurarlos fácilmente en caso de problemas. Elige la mejor forma de copia de seguridad para ti:
        <br />
        <strong>Backup a través del Portal del Cliente:</strong>
        Realiza una copia de seguridad completa de tu plan, almacenando todos los datos, archivos y correos electrónicos en el Directorio Inicial. Puedes guardar hasta 10 GB en esta copia de seguridad.
        <br />
        <strong>Backup avanzado a través de cPanel:</strong>
        Una copia de seguridad más avanzada con opciones adicionales de descarga. Personaliza y obtén un mayor control sobre tus copias de seguridad.
      </>
    ),
    alertDescription: () => (
      <>
        {'¿No sabes cuál elegir? Obtén más información sobre '}
        <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/18012814637083" text="las diferencias entre los dos tipos de copia de seguridad aquí." />
      </>
    ),
    buttonLabelCpanel: 'Backup avanzado cPanel',
    buttonLabelPortal: 'Backup Portal del Cliente',
  },
  wizard: {
    title: 'Primeros Pasos en Backup',
    description: 'Vamos a ayudarte a generar o restaurar una copia de seguridad para tu plan con nuestra guía paso a paso.',
    fristStepTitle: (
      <strong>Comienza seleccionando lo que deseas hacer ahora:</strong>
    ),
    generateLocale: {
      optionTitle: 'Generar una copia de seguridad',
      optionDescription: (
        <>
          <strong>Haz una copia de seguridad</strong>
          {' de tu sitio para descargarla y mantenerla en un lugar seguro.'}
        </>
      ),
      secondStep: {
        description: (
          <strong>¿Y qué copia de seguridad deseas generar?</strong>
        ),
        generateSuspendedTitle: (
          <>
            {'Ok! '}
            <strong>¡Vamos a ayudarte!</strong>
            {' :)'}
          </>
        ),
      },
      thirdStep: {
        options: {
          active: {
            full: {
              title: 'Backup completo',
              description: (
                <>
                  Descargue una copia completa de su alojamiento, incluyendo archivos, bases de datos y configuraciones de cPanel:

                  <ul>
                    <li>Tamaño máximo de 10 GB o 10,000 archivos (inodes).</li>
                    <li>Ideal para migraciones de sitios, desarrollo local o recuperación en caso de infección por virus/malwares.</li>
                    <li>Incluye los archivos de todos los sitios instalados en el alojamiento.</li>
                    <li>Evita inconsistencias entre la base de datos y los archivos de su sitio.</li>
                    <li>Cuenta con el soporte de HostGator para restaurar su backup siempre que lo necesite.</li>
                  </ul>
                </>
              ),
            },
            root: {
              title: 'Backup del directorio inicial',
              description: (
                <>
                  Descargue una copia del directorio de archivos de su alojamiento:

                  <ul>
                    <li>Tamaño máximo de 10 GB o 10,000 archivos (inodes).</li>
                    <li>Ideal para recuperación en casos de eliminación accidental de archivos o infección por virus/malwares.</li>
                    <li>Incluye los archivos de todos los sitios instalados en el alojamiento.</li>
                    <li>Restauración fácil mediante cPanel cuando lo necesite.</li>
                  </ul>
                </>
              ),
            },
            database: {
              title: 'Backup del banco de datos',
              description: (
                <>
                  Descargue una copia segura de la base de datos MySQL de su sitio (avanzado):

                  <ul>
                    <li>Ideal para realizar análisis de datos avanzados o recuperación de datos corruptos.</li>
                    <li>Restauración fácil mediante cPanel cuando lo necesite.</li>
                  </ul>
                </>
              ),
            },
          },
        },
      },
      generateTexts: {
        description: (
          <>
            {'¡De acuerdo! '}
            <strong>Vamos a ayudarte a generar tu copia de seguridad, espera unos segundos</strong>
            {'. :) '}
          </>
        ),
        info: 'Haz clic en el botón de abajo para obtener más información sobre la copia de seguridad y descargar el archivo.',
      },
      generateByCpanel: {
        description: (
          <>
            {'Puede realizar una copia de seguridad completa de su plan de forma rápida '}
            <strong> a través del cPanel.</strong>
          </>
        ),
        info: (
          <>
            {'Haga clic en el botón a continuación para ir allí. En caso de duda, '}
            <Link href="https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691" text="vea cómo hacerlo." />
          </>
        ),
      },
      buttonLable: 'Hacer copia de seguridad',
      cpanelButtonLable: 'Hacer copia de seguridad a través de cPanel',
    },
    restorateLocale: {
      optionTitle: 'Restaurar una copia de seguridad',
      optionDescription: (
        <>
          <strong>Restaura una copia de seguridad</strong>
          {' de tu alojamiento si estás teniendo problemas.'}
        </>
      ),
      mine: {
        title: 'Desde un archivo que ya tengo',
        description: 'Texto de apoyo.',
      },
      hostgator: {
        title: 'Desde un archivo que Hostgator tenga',
        description: 'Texto de apoyo.',
      },
      restoreLabel: 'Restaurar copia de seguridad',
      description: (
        <>
          {'Como no tienes un archivo de copia de seguridad disponible, para continuar con la restauración, es necesario '}
          <strong>abrir un ticket de soporte con nuestro equipo</strong>
          . Haz clic en el botón de abajo y completa el formulario.
        </>
      ),
      fullBkpDescription: (
        <>
          {'Para realizar la restauración del backup completo, '}
          <strong>es necesario abrir un ticket para nuestro equipo de Soporte.</strong>
          {' Haga clic en el botón a continuación para acceder al formulario e iniciar el proceso.'}
        </>
      ),
      noFullBkpDescription: (
        <>
          Haga clic en el botón a continuación para obtener más información y
          <strong>{' ver cómo realizar la restauración '}</strong>
          a través del cPanel.
        </>
      ),
      firstStep: {
        title: (
          <>
            {'¿Tienes el '}
            <strong>archivo para restaurar?</strong>
          </>
        ),
      },
      secondStep: {
        title: (
          <>
            ¡Ok! Vamos a ayudarte a restaurar tu copia de seguridad. :)
            <br />
            <br />
            <strong>
              ¿Qué tipo de copia de seguridad deseas restaurar?
            </strong>
          </>
        ),
        options: {
          positive: (
            <>
              <strong>Sí</strong>
              , ¡tengo el archivo!
            </>
          ),
          negative: (
            <>
              <strong>No</strong>
              , quiero usar un archivo de HostGator.
            </>
          ),
        },
      },
      thirdStep: {
        options: {
          active: {
            userFile: {
              full: {
                title: 'Backup completo',
                description: (
                  <>
                    Seleccione esta opción si tiene un archivo de backup completo del cPanel.
                    <ul>
                      <li>Archivo en formato .tar.gz</li>
                      <li>Tamaño máximo de 500 MB.</li>
                      <li>No se conservarán cambios realizados después de la fecha de generación del archivo de backup en cPanel después de la restauración completa.</li>
                    </ul>
                  </>
                ),
              },
              root: {
                title: 'Backup del directorio inicial',
                description: (
                  <>
                    Seleccione esta opción si tiene un archivo de backup del directorio inicial del cPanel.
                    <ul>
                      <li>Archivo en formato .tar.gz</li>
                      <li>Tamaño máximo de 500 MB.</li>
                      <li>No se conservarán cambios realizados en los archivos de cPanel después de la fecha de generación del archivo de backup después de la restauración.</li>
                    </ul>
                  </>
                ),
              },
              database: {
                title: 'Backup del banco de datos',
                description: (
                  <>
                    Seleccione esta opción si tiene un archivo de backup de la base de datos MySQL del cPanel.
                    <ul>
                      <li>Archivo en formato .sql.gz</li>
                      <li>Tamaño máximo de 50 MB.</li>
                      <li>No se conservarán cambios realizados en los datos de su sitio después de la fecha de generación del archivo de backup después de la restauración.</li>
                    </ul>
                  </>
                ),
              },
            },
            routineFile: {
              full: {
                title: 'Backup completo',
                description: (
                  <>
                    Realice la restauración completa de su backup a partir de un archivo proporcionado por HostGator.
                    <ul>
                      <li>Ideal para migraciones de sitios, desarrollo local o recuperación después de una infección por virus/malwares.</li>
                      <li>Incluye todos los archivos de los sitios instalados en el alojamiento.</li>
                      <li>Confíe en el soporte de HostGator para ejecutar el proceso de restauración.</li>
                    </ul>
                  </>
                ),
              },
              root: {
                title: 'Backup del directorio inicial',
                description: (
                  <>
                    Restauración del directorio de archivos proporcionada por HostGator:
                    <ul>
                      <li>Recupere su directorio de archivos hospedado con facilidad.</li>
                      <li>Ideal para casos de eliminación accidental o infección por virus/malwares. Incluye todos los archivos de los sitios instalados en el alojamiento.</li>
                      <li>Confíe en el soporte de HostGator para ejecutar el proceso de restauración.</li>
                    </ul>
                  </>
                ),
              },
              database: {
                title: 'Backup del banco de datos',
                description: (
                  <>
                    Realice la restauración del banco de datos MySQL de su sitio proporcionada por HostGator (avanzado):
                    <ul>
                      <li>Ideal para análisis avanzados de datos o recuperación de datos corruptos.</li>
                      <li>Confíe en el soporte de HostGator para ejecutar el proceso de restauración.</li>
                    </ul>
                  </>
                ),
              },
            },
          },
          suspended: {
            full: {
              title: 'Backup completo',
              description: 'Restaure todos los archivos del backup completo.',
            },
            root: {
              title: 'Backup del directorio inicial',
              description: 'Puede elegir un archivo específico para restaurar.',
            },
            database: {
              title: 'Backup de la base de datos',
              description: 'Recupere su backup del banco de datos.',
            },
          },
        },
      },
      inodeMessage: (
        <>
          Hemos detectado que su copia de seguridad excede el límite de tamaño o de archivos permitido.
          <br />
          {'En este caso, '}
          <strong>es necesario abrir un ticket.</strong>
          {' Haga clic en el botón de abajo para acceder al formulario.'}
        </>
      ),
      exceededInodeButton: 'Solicitar copia de seguridad',
      buttonLable: 'Solicitar restauración',
      continue: 'Continuar',
    },
    cancelledRestoreAlert: (
      <>
        <strong>Su plan ha sido cancelado y no se puede recuperar.</strong>
        <br />
        Si tiene un backup, contrate un nuevo alojamiento y restaure los archivos en el nuevo plan.
      </>
    ),
    cancelledGenerateAlert: (
      <>
        {'Como tu plan ha sido cancelado, '}
        <strong>no es posible generar una copia de seguridad.</strong>
        <br />
        Por favor, adquiere un nuevo plan para acceder a este servicio.
      </>
    ),
    suspendedRestoreAlert: (
      <>
        <strong>Como su plan está suspendido, necesita solicitar la restauración a nuestro equipo de Soporte.</strong>
        <br />
        Haga clic en el botón de abajo para continuar con la solicitud y obtener información sobre cómo regularizar su plan.
      </>
    ),
    suspendedGenerateAlert: (
      <>
        <strong>Como tu plan está suspendido, necesitas solicitar la copia de seguridad a nuestro equipo de soporte.</strong>
        <br />
        Haz clic en el botón de abajo para continuar con la solicitud y obtener información sobre cómo regularizar tu plan.
      </>
    ),
    hireNewLabel: 'Contratar nuevo alojamiento',
    askRestorationLabel: 'Solicitar restauración',
    askBackupLabel: 'Solicitar copia de seguridad',
    continue: 'Continuar',
    breadCrumb: (
      <>
        {'Vista general / '}
        <strong>Primeros Pasos</strong>
      </>
    ),
  },
};
