import React from 'react';
import * as Styles from './LinkNaBioStep4.styles';
import { Input, Button } from 'gatorcomponents';
import { Preview } from '../Preview';
import partyImg from '../../media/party.svg';
import confetti from '../../media/lottie-confetti.gif';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import { useDispatch } from 'react-redux';
import { commonActions } from '@/redux/modules';
import { FAQ_LINK_NA_BIO } from '@/config/urls/linkNaBioUrls';
import { linkNaBioAnalytics } from '@/analytics/linkNaBio';
import { LINK_NA_BIO_URL } from '@/config';
import withErrorBoundary from '@/utils/errorBoundary';

const LinkNaBioStep4 = ({
  previewData = {},
  stepLocale = {},
  testId = 'link-na-bio-step-4',
  link = '',
  onMobile,
}) => {
  const dispatch = useDispatch();

  const showCopyNotification = () => {
    dispatch(commonActions.notifications.set({
      label: stepLocale.copied,
      type: 'success',
    }));
  };

  const handleCopy = () => {
    const linkToCopy = `${LINK_NA_BIO_URL}/${link}`;

    copyTextToClipboard(linkToCopy);
    showCopyNotification();
    linkNaBioAnalytics.copyLinkNaBio();
  };

  const handleOpenLink = () => {
    const linkToOpen = `${LINK_NA_BIO_URL}/${link}`;

    window.open(linkToOpen, '_blank');
    linkNaBioAnalytics.viewLinkNaBio();
  };

  const handleLinkToShow = () => {
    if (link.length > 20) {
      return `${stepLocale.linkPlaceholder + link.slice(0, 20)}...`;
    }

    return stepLocale.linkPlaceholder + link;
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Content>
        <Styles.Img src={partyImg} alt="Party!" />
        <Styles.Title>{stepLocale.title}</Styles.Title>
        <Styles.Description>{stepLocale.description}</Styles.Description>

        <Styles.InputWrapper>
          <Input
            icon="IconCopy"
            value={handleLinkToShow}
            allowIconClick
            readOnly
            onClickIcon={handleCopy}
            size="md"
          />
        </Styles.InputWrapper>

        <Styles.ButtonsWrapper>
          <Button
            label={stepLocale.copyButton}
            onClick={handleCopy}
            variant="secondary"
            size="large"
          />
          <Button
            label={stepLocale.viewButton}
            onClick={handleOpenLink}
            size="large"
          />
        </Styles.ButtonsWrapper>

        <Styles.Faq>
          <Styles.FaqTitle>
            {stepLocale.doMore}
          </Styles.FaqTitle>
          <Styles.FaqLink target="_blank" href={FAQ_LINK_NA_BIO[2].link}>{FAQ_LINK_NA_BIO[2].question}</Styles.FaqLink>
        </Styles.Faq>

      </Styles.Content>

      <Styles.PreviewWrapper>
        <Preview
          description={previewData.description}
          links={previewData.links}
          templateType={previewData.template}
          title={previewData.emoji ? `${previewData.emoji} ${previewData.title}` : previewData.title}
          variant={previewData.colorScheme}
          onMobile={onMobile}
          socialMedia={previewData.socialMediaLinks}
        />
        <Styles.Confetti src={confetti} alt="Confetti" />
      </Styles.PreviewWrapper>


    </Styles.Wrapper>
  );
};


export default withErrorBoundary(LinkNaBioStep4, 'LinkNaBioStep4');
