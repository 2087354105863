import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './VerifyingSiteExistModal.styles';
import { IconLoading } from 'gatorcomponents';
import { verifyingSiteExistModalLocale } from './VerifyingSiteExistModal.locale';


const VerifyingSiteExistModal = () => (
  <Modal centerContentInMobile maxWidth={654} testId="verifying-site-exist-modal">
    <Styles.ModalContainer>
      <IconLoading />
      <Styles.LoadingMessage>
        {verifyingSiteExistModalLocale.message}
      </Styles.LoadingMessage>
    </Styles.ModalContainer>
  </Modal>
);

export default VerifyingSiteExistModal;
