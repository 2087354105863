import React from 'react';
import { Trans, withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import WarningNotice from '@/components/Notification/WarningNotice';
import styles from './styles';

const WarningBlock = ({ classes }) => (
  <div className={classes.warningWrapper}>
    <WarningNotice
      info
      message={(
        <Trans i18nKey="dnsSetup.warning">
          0
          <b>1</b>
        </Trans>
      )}
    />
  </div>
);
export default withI18n()(withStyles(styles)((WarningBlock)));
