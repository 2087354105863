import productsBR from './products.br';
import productsCL from './products.cl';
import productsCO from './products.co';
import productsMX from './products.mx';

const locales = {
  BR: productsBR,
  CL: productsCL,
  CO: productsCO,
  MX: productsMX,
};

export default locales;
