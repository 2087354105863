import { COUNTRY } from '@/config';
import { MANIFEST_TITLE } from '.';


const getManifestConfig = () => {
  const urlDomain = window.location.origin;
  const dynamicManifest = {
    name: MANIFEST_TITLE[COUNTRY],
    short_name: 'HostGator',
    display: 'standalone',
    start_url: urlDomain,
    background_color: '#fff',
    theme_color: '#2E578B',
    icons: [{
      src: `${urlDomain}/assets/images/icons/icon-128x128.png`,
      sizes: '128x128',
      type: 'image/png',
    }, {
      src: `${urlDomain}/assets/images/icons/icon-144x144.png`,
      sizes: '144x144',
      type: 'image/png',
    }, {
      src: `${urlDomain}/assets/images/icons/icon-152x152.png`,
      sizes: '152x152',
      type: 'image/png',
    }, {
      src: `${urlDomain}/assets/images/icons/icon-192x192.png`,
      sizes: '192x192',
      type: 'image/png',
    }, {
      src: `${urlDomain}/assets/images/icons/icon-256x256.png`,
      sizes: '256x256',
      type: 'image/png',
    }, {
      src: `${urlDomain}/assets/images/icons/icon-512x512.png`,
      sizes: '512x512',
      type: 'image/png',
    },
    ],
  };
  const stringManifest = JSON.stringify(dynamicManifest);
  const blobManifest = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blobManifest);
  return manifestURL;
};

export default getManifestConfig;
