const styles = theme => ({
  save: {
    minWidth: 95,
  },
  dialogContentRoot: {
    paddingBottom: 0,
  },
  modalContainer: {
    [theme.breakpoints.up(491)]: {
      height: 'inherit',
      marginTop: 70,
    },
  },
  containerInput: {
    marginTop: 26,
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperContentModal: {
    color: theme.color.tundora,
  },
  modalTitle: {
    fontSize: 24,
    margin: 0,
    padding: 0,
  },
  modalText: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
  modalPaper: {
    borderRadius: 0,
    padding: '10px 16px',
    width: 474,
    [theme.breakpoints.down(491)]: {
      minWidth: '100%',
      padding: 0,
      paddingTop: 10,
    },
  },
  dialogTitle: {
    paddingBottom: 8,
  },
  dividerWarning: {
    marginBottom: 20,
  },
  closeIconButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  warningNotice: {
    marginTop: 10,
    marginBottom: 10,
  },
  dialogActionsContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 6,
  },
  textFieldInput: {
    width: '100%',
    fontSize: '14px',
    margin: 0,
    maxWidth: 139,
    marginRight: 9,
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 0,
    },
    '& input': {
      padding: 7,
      paddingTop: 10,
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  textTie: {
    fontSize: 12,
    color: theme.color.regentGray,
    marginLeft: 30,
    marginRight: 30,
    [theme.breakpoints.down(360)]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  capacityUnity: {
    fontSize: 12,
  },
  checkboxRootError: {
    color: '#f44336',
    '& span': {
      color: '#f44336',
    },
  },
  iconInfo: {
    cursor: 'pointer',
    fontSize: 17,
    color: theme.color.indigo,
  },
  tootlTipCustom: {
    background: '#000000e3',
    fontSize: 12,
  },
});

export default styles;
