import React from 'react';
import { withStyles, withWidth } from '@material-ui/core';
import { withI18n } from 'react-i18next';

import styles from './styles';


const ExternalServiceErrorMessage = ({ t, classes, children }) => (
  <div className={classes.root}>
    <h1 className={classes.title}>{t('notFound.title')}</h1>
    <p className={classes.textDescription}>{children}</p>
  </div>
);

export default withWidth()(withStyles(styles)(withI18n()(ExternalServiceErrorMessage)));
