import { locale } from '@/utils/locale';
import React from 'react';
import { Link } from '@/pages/common';

export const useWizardTipsLocale = {
  sitebuilder: {
    title: locale('sitesWizard.tips.createSites.title'),
    subtitle: () => (
      <>
        {locale('sitesWizard.tips.createSites.altSubtitle.0')}
        {<Link inlineLink historyRedirect href={locale('routes.sitesPage')} text={locale('sitesWizard.tips.createSites.altSubtitle.1')} />}
        {locale('sitesWizard.tips.createSites.altSubtitle.2')}
      </>
    ),
    buttonLabel: locale('sitesWizard.tips.createSites.buttonLabel'),
    seeBellow: {
      0: locale('sitesWizard.tips.wordpress.subtitleOne'),
      1: locale('sitesWizard.tips.wordpress.subtitleTwo'),
    },
    hiddenInfo: locale('sitesWizard.tips.createSites.hiddenInfo'),
    ssoUrl: locale('routes.siteBuilderRedirectAccess'),
    faqContent: {
      title: locale('help.contentHelpCenter'),
      button: locale('help.seeMoreResults'),
      buttonRedirectWordPress: locale('help.buttonRedirectWordPress'),
      buttonRedirectSitesBuilder: locale('help.buttonRedirectSitesBuilder'),
      buttonRedirectSoftaculos: locale('help.buttonRedirectSoftaculos'),
    },
  },
  wordpress: {
    title: locale('sitesWizard.tips.wordpress.title'),
    buttonLabel: locale('sitesWizard.tips.wordpress.buttonLabel'),
    subtitle: locale('sitesWizard.tips.wordpress.subtitle'),
    seeBellow: {
      0: locale('sitesWizard.tips.wordpress.subtitleOne'),
      1: locale('sitesWizard.tips.wordpress.subtitleTwo'),
    },
    hiddenInfo: locale('sitesWizard.tips.wordpress.hiddenInfo'),
    routeSitesPages: locale('routes.sitesPage'),
    faqContent: {
      title: locale('help.contentHelpCenter'),
      button: locale('help.seeMoreResults'),
      buttonRedirectWordPress: locale('help.buttonRedirectWordPress'),
      buttonRedirectSitesBuilder: locale('help.buttonRedirectSitesBuilder'),
      buttonRedirectSoftaculos: locale('help.buttonRedirectSoftaculos'),
    },
  },
  softaculous: {
    title: locale('sitesWizard.tips.softaculous.title'),
    seeBellow: {
      0: locale('sitesWizard.tips.wordpress.subtitleOne'),
      1: locale('sitesWizard.tips.wordpress.subtitleTwo'),
    },
    hiddenInfo: locale('sitesWizard.tips.softaculous.hiddenInfo'),
    faqContent: {
      title: locale('help.contentHelpCenter'),
      button: locale('help.seeMoreResults'),
      buttonRedirectWordPress: locale('help.buttonRedirectWordPress'),
      buttonRedirectSitesBuilder: locale('help.buttonRedirectSitesBuilder'),
      buttonRedirectSoftaculos: locale('help.buttonRedirectSoftaculos'),
    },
  },
};
