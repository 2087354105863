import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: block;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 24px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    margin-top: 8px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 0;
    }
  `}
`;

export const HeaderRow = styled.div`
  ${({ theme }) => css`
  box-shadow: 0px 1px 3px ${theme.shadows.grey.light};

    @media (max-width: ${theme.breakpoints.md}) {
      // box-shadow: 0px 1px 3px ${theme.shadows.grey.light};
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 22px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 6px;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.light};
    margin: 0px;
  `}
`;

export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 22px 12px 22px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 8px;
    }
  `}
`;

export const NewFilterWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary}
  `}
`;
