export const contractWithSpecialistURL = 'https://conteudos.hostgator.com.br/contratar-especialista-para-criar-meu-site';

export const modalCloseAction = {
  createSite: 'createSite',
  createWithSpecialist: 'createWithSpecialist',
  close: 'close',
  understand: 'understand',
};

export const steps = {
  finishStep: '99',
};

export const checklistProgressStatus = {
  noStarted: 0,
  completed: 4,
};
