import useDnsModalBR from './useDnsModal.br';
import useDnsModalCL from './useDnsModal.cl';
import useDnsModalCO from './useDnsModal.co';
import useDnsModalMX from './useDnsModal.mx';

const locales = {
  BR: useDnsModalBR,
  CL: useDnsModalCL,
  CO: useDnsModalCO,
  MX: useDnsModalMX,
};

export default locales;
