import React from 'react';
import * as Styles from './GetNowForm.styles';
import {
  Button, RadioOption, Select,
} from '@/pages/common/v1';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { IconArrowForward } from '@/icons';
import Loader from '@/components/Layout/Loader';

const GetNowForm = ({
  paymentCycles = [
    { title: 'Anual', name: 'annually', featured: true },
    { title: 'Semestral', name: 'semiannually', featured: false },
    { title: 'Trimestral', name: 'quarterly', featured: false },
    { title: 'Mensal', name: 'monthly', featured: false },
  ],
  paymentMethods = [
    { label: '1x Cartão Crédito', value: 'braspag_credit_1x', id: 'braspag_credit_1x' },
    { label: 'Boleto', value: 'gp_boleto', id: 'gp_boleto' },
    { label: 'Boleto Bank of America', value: 'boleto', id: 'boleto' },
    { label: 'PayPal Antigo', value: 'paypal', id: 'paypal' },
    { label: 'Paypal', value: 'gp_paypal', id: 'gp_paypal' },
  ],
  formTitle = 'professional plan',
  cycleLabel = 'Select cycle:',
  setSelectedCycle,
  selectedCycle = 'annually',
  selectePaymentLabel = 'Select the payment method:',
  activeCycle = { price: '279.99', cycle: 'annually', per: '/ano' },
  toBePaid = 'To be paid',
  hgCodeguard = 'https://www.hostgator.com.br/backup-online',
  getCodeGuard,
  isLoadingPayment = false,
  setSelectedPaymentMethod,
  needMoreLabel,
  needMoreButtonText,
  codeGuardAnalytics,
}) => {
  const handleSelectMethod = ({ value }) => {
    setSelectedPaymentMethod && setSelectedPaymentMethod(value);
    codeGuardAnalytics.selectPaymentMethod(value);
  };

  const onChangeCycle = ({ name }) => {
    if (selectedCycle !== name) {
      setSelectedCycle && setSelectedCycle(name);
      codeGuardAnalytics.cyclePaymentClick(name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getCodeGuard && getCodeGuard();
    codeGuardAnalytics.codeGuardBuy();
  };

  return (
    <Styles.Form onSubmit={handleSubmit} data-testid="GetNow-form">
      <Styles.FormTitle>{formTitle}</Styles.FormTitle>

      <Styles.CyclesBlock>
        <Styles.Label>
          {cycleLabel}
        </Styles.Label>
        <Styles.CyclesWrapper>
          {paymentCycles.map(cycle => (
            <RadioOption
              key={cycle.title}
              testId={cycle.name}
              title={cycle.title}
              basis="1 0 123px"
              featured={cycle.featured}
              onToggle={onChangeCycle}
              checked={selectedCycle === cycle.name}
              name={cycle.name}
            />
          ))}
        </Styles.CyclesWrapper>
      </Styles.CyclesBlock>

      <Styles.MethodsBlock>
        <Styles.Label>
          {selectePaymentLabel}
        </Styles.Label>
        <Select options={paymentMethods} defaultOption={0} onChange={handleSelectMethod} />
      </Styles.MethodsBlock>
      <Styles.ValueBlock>
        <Styles.Label>{toBePaid}</Styles.Label>
        <Styles.Price>
          {formatCurrency(activeCycle.price)}
          <small>{activeCycle.per}</small>
        </Styles.Price>

        <Button
          label={isLoadingPayment ? <Loader size={16} /> : 'Contrate agora'}
          disabled={isLoadingPayment}
          type="submit"
        />
      </Styles.ValueBlock>

      <Styles.NeedMoreWrapper>
        <Styles.Label>
          {needMoreLabel}
        </Styles.Label>
        <Styles.NeedMoreLink
          href={hgCodeguard}
          onClick={() => codeGuardAnalytics.seeAllCodeGuardPlansButtonClick()}
          target="_blank"
          rel="noreferrer"
        >
          {needMoreButtonText}
          <IconArrowForward size={16} />
        </Styles.NeedMoreLink>
      </Styles.NeedMoreWrapper>

    </Styles.Form>
  );
};

export default GetNowForm;
