import PropTypes from 'prop-types';

export const SubscriptionControlsProps = {
  activeSubscriptionRenewalButtonLabel: PropTypes.string,
  cancelSubscriptionButtonLabel: PropTypes.string,
  cancelSubscriptionImmediatelyButtonLabel: PropTypes.string,
  loading: PropTypes.bool,
  onActiveSubscriptionRenewalClick: PropTypes.func,
  onCancelSubscriptionClick: PropTypes.func,
  onCancelSubscriptionImmediatelyClick: PropTypes.func,
  onSwitchPaymentMethodClick: PropTypes.func,
  showActiveSubscriptionRenewalButton: PropTypes.bool,
  showCancelSubscriptionButton: PropTypes.bool,
  showCancelSubscriptionImmediatelyButton: PropTypes.bool,
  showSwitchPaymentMethodButton: PropTypes.bool,
  switchPaymentMethodButtonLabel: PropTypes.string,
};
