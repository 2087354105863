const styles = () => ({
  snackbarCustom: {
    '&> div:nth-child(1)': {
      paddingRight: 75,
    },
  },
  iconNotification: {
    fontSize: 24,
    marginRight: 10,
  },
});

export default styles;
