import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button, FeedbackStepper, Header, Tooltip, Survey,
} from '@/pages/common';
import {
  NoRefund, WithRefund, Review, OrderProcessing, RefundModal, SignatureConfirmation,
} from '@/components/billing';
import Loader from '@/components/Layout/Loader';
import { IconArrowWardsLeft } from '@/icons';
import { SubscriptionsQuestionTypes } from '@/redux/modules/billing/billing.types';
import { locale } from '@/utils/locale';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDate } from '@/utils/Formatters/formatDate';
import { COUNTRY } from '@/config';
import { CANCEL_SUBSCRIPTION_STEPS } from '@/enums/billing/billing.enum';
import {
  subscriptionLocale,
  refundModalLocale,
  signatureConfirmationLocale,
  refundModalLocaleV2,
} from './CancelSubscriptionHandler.locale';
import { billingActions, preChatActions } from '@/redux/modules';
import createFunctions from './CancelSubscriptionHandler.functions';
import * as Styles from './CancelSubscriptionHandler.styles';
import { billingAnalytics } from '@/analytics';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_RETENTION_OFFER, FOXTROT_REFUND_TAG } from '@/config/GrowthBook/constants';
import useLocale from '@/hooks/useLocale';
import { RetentionOfferPage } from '@/pages/billing/subscriptions/RetentionOfferPage';

const CancelSubscriptionHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();
  const subscriptionId = Number(params.subscriptionId);
  const { subscriptionType } = params;

  const loading = useSelector(state => state.common.loading);
  const subscriptions = useSelector(state => state.billing.subscriptions.items);
  const questions = useSelector(state => state.billing.subscriptions.questions);
  const cancel = useSelector(state => state.billing.subscriptions.cancel);
  const { triage } = useSelector(state => state.preChat);
  const [subscription, setSubscription] = useState(undefined);
  const { billing: billingLocale } = useLocale();
  const enabledRetentionOffer = useFeatureIsOn(XRAY_ENABLED_RETENTION_OFFER);

  const [step, setStep] = useState(CANCEL_SUBSCRIPTION_STEPS.SURVEY);

  const [expandRefundModalAccordion, setExpandRefundModalAccordion] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const enableRefundToggle = useFeatureIsOn(FOXTROT_REFUND_TAG);

  const canShowBackupLink = () => {
    if (subscription && subscription.showFilesExclusionText !== undefined) {
      return subscription.showFilesExclusionText;
    }
    return subscriptionType !== 'addon' && subscriptionType !== 'domain';
  };

  const onClickBackupLink = () => {
    subscriptionAnalytics.cancelSubscriptionClick('6_link_veja_como_fazer_backup_cancelar_imediato');
  };

  const {
    questionId, optionId, reason, modal,
  } = cancel;

  const openPreChatCancelFlow = useCallback(() => {
    if (triage.subscriptionToCancel) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
    }
  }, [triage, dispatch]);

  const { values, methods } = createFunctions({
    dispatch,
    history,
    questions,
    setExpandRefundModalAccordion,
    setOpenRefundModal,
    setStep,
    setSubscription,
    step,
    subscriptionId: Number(subscriptionId),
    subscriptions,
    subscriptionType,
    openPreChatCancelFlow,
    enabledRetentionOffer,
    optionId,
  });

  const {
    steps,
  } = values;

  const {
    getPaymentOptions,
    handleOnOptionSelected,
    handleOnReasonChange,
    handleOnRefundModalClose,
    handleOnRefundPolicyClick,
    handleOnRefundPoliticsClick,
    handleOnServiceTermsClick,
    handleOnStopCancel,
    onFowardStepClick,
    onPreviousStepClick,
    requestQuestionsEffect,
    resetSurveyEffect,
  } = methods;

  const handleDeclineRenewDiscount = useCallback(() => {
    setStep(CANCEL_SUBSCRIPTION_STEPS.REFUND);
  }, []);

  const handleAnswer = useCallback(() => {
    const actionSuccess = () => {
      setStep(CANCEL_SUBSCRIPTION_STEPS.NEXT_RENEW_OFFER);
    };

    const actionFail = () => {
      onFowardStepClick();
    };

    if (enabledRetentionOffer && step < CANCEL_SUBSCRIPTION_STEPS.REFUND) {
      dispatch(billingActions.subscriptions.ableToCancelOffer.request(
        subscriptionId, subscriptionType, optionId, actionSuccess, actionFail,
      ));
    } else {
      onFowardStepClick();
    }
  }, [
    dispatch,
    enabledRetentionOffer,
    optionId,
    onFowardStepClick,
    step,
    subscriptionId,
    subscriptionType,
  ]);

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('3_pesquisa_respondida_cancelar_imediato');
  }, [subscriptionAnalytics]);

  const getCancelledAddonsTopics = () => {
    if (subscription && subscription.addons) {
      return subscription.addons.map(addon => signatureConfirmationLocale.addonCancellationAlert(addon));
    }

    return [];
  };

  const {
    exampleCalculation,
    amountOwed,
    amountAvailableRefund,
  } = refundModalLocale.accordionCancelledAfterContent;

  const countriesCurrencyValues = {
    mx: {
      CONTRACT_VALUE: '442,31',
      REFUND_VALUE: '51,60',
      REMAINING_VALUE: '73,72',
      USED_VALUE: '368,59',
    },
    cl: {
      CONTRACT_VALUE: '17637,84',
      REFUND_VALUE: '2057,04',
      REMAINING_VALUE: '2938,62',
      USED_VALUE: '14693,11',
    },
    co: {
      CONTRACT_VALUE: '84105,60',
      REFUND_VALUE: '9819,60',
      REMAINING_VALUE: '14028,00',
      USED_VALUE: '70140,00',
    },
  };

  if (COUNTRY !== 'br') {
    exampleCalculation.text = exampleCalculation.text
      .replace(/(\[CONTRACT_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].CONTRACT_VALUE))
      .replace(/(\[REMAINING_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].REMAINING_VALUE))
      .replace(/(\[REFUND_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].REFUND_VALUE));

    amountOwed.annualContractAmount = amountOwed.annualContractAmount
      .replace(/(\[CONTRACT_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].CONTRACT_VALUE));

    amountOwed.usedAmount = amountOwed.usedAmount
      .replace(/(\[USED_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].USED_VALUE));

    amountAvailableRefund.totalAmount = amountAvailableRefund.totalAmount
      .replace(/(\[REMAINING_VALUE\])/, formatCurrency(countriesCurrencyValues[COUNTRY].REMAINING_VALUE));

    refundModalLocale.accordionCancelledAfterContent = {
      ...refundModalLocale.accordionCancelledAfterContent,
      exampleCalculation,
      amountOwed,
      amountAvailableRefund,
    };
  }

  const isSurveyStepComplete = useCallback(() => !questionId || !optionId, [questionId, optionId]);

  const onRefundMethodSelected = (refundMethod) => {
    dispatch(billingActions.subscription.refund.method.set(
      refundMethod,
      subscriptionId,
      subscriptionType,
    ));
  };

  const getSubscriptionName = () => {
    if (subscription) {
      const { type } = subscription;
      return type === 'domain'
        ? `${billingLocale.domainLabel}: ${subscription.domain}`
        : subscription.productName;
    }

    return '';
  };

  const stepsStatus = {
    SURVEY: 1,
    CONFIRMATION: 2,
    DONE: 3,
  };

  const verifyParamsInUrl = useCallback(() => {
    if (enabledRetentionOffer) {
      const containsStep = window.location.href.includes('step=');
      const step = window.location.href.includes('step=2') ? 2 : 1;
      const shouldOpenInvoice = containsStep && step === 2;

      if (shouldOpenInvoice) {
        setStep(stepsStatus.CONFIRMATION);
      }
    }
  }, [setStep, enabledRetentionOffer, stepsStatus.CONFIRMATION]);

  useEffect(() => {
    verifyParamsInUrl();
  }, [verifyParamsInUrl]);

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(billingActions.subscriptions.request());
    }
  }, [subscriptions, dispatch]);

  useEffect(() => {
    if (subscriptions.length) {
      subscriptions
        .filter(({ id, type }) => id === subscriptionId && type === subscriptionType)
        .forEach(item => setSubscription(item));
    }
  }, [subscriptionId, subscriptionType, subscriptions]);

  useEffect(() => {
    requestQuestionsEffect();
    resetSurveyEffect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscription && !subscription.refund) {
      dispatch(billingActions.subscription.refund.check.request({
        subscriptionId,
        subscriptionType,
      }));
    }
  }, [subscription, subscriptionId, subscriptionType, dispatch]);

  useEffect(() => {
    modal && history.push(`${locale('routes.subscriptions')}`);
  }, [history, modal]);

  if (loading
    || !subscription
    || (subscription && !subscription.refund)
    || !questions[SubscriptionsQuestionTypes.CANCEL_SIGNATURE]
  ) {
    return <Loader />;
  }

  return (
    <Styles.Wrapper data-testid="subscription-cancel-handler">
      <Styles.Header>
        <Header
          title={
            `${subscription.type === 'domain'
              ? billingLocale.domainLabel
              : subscription.productName} - `
          }
          description={subscription.domain}
        />
      </Styles.Header>
      <Styles.Content>
        <FeedbackStepper
          activeStep={step}
          steps={steps}
        />
        <Styles.StepContent>
          { step === CANCEL_SUBSCRIPTION_STEPS.NEXT_RENEW_OFFER && (
            <RetentionOfferPage handleDeclineRenewDiscount={handleDeclineRenewDiscount} />
          )}
          {
            step === CANCEL_SUBSCRIPTION_STEPS.SURVEY && (
              <Styles.StepSurveyContainer data-testid="subscription-cancel-signature-step-survey">
                <Survey
                  questions={questions[SubscriptionsQuestionTypes.CANCEL_SIGNATURE]}
                  questionId={questionId}
                  optionId={optionId}
                  reason={reason}
                  testId="subscription-cancel-signature-survey"
                  onOptionSelected={handleOnOptionSelected}
                  onReasonChange={handleOnReasonChange}
                  questionDescriptionLabel={subscriptionLocale.surveyQuestionDescriptionLabel}
                  reasonQuestionLabel={subscriptionLocale.surveyReasonQuestionLabel}
                />
              </Styles.StepSurveyContainer>
            )
          }
          {
            step === CANCEL_SUBSCRIPTION_STEPS.REFUND && (
              <Styles.StepConfirmationContainer data-testid="subscription-cancel-signature-step-refund">
                {(subscription.refund && subscription.refund.total > 0)
                  ? (
                    <WithRefund
                      title={subscriptionLocale.withRefund.title}
                      totalLabel={subscriptionLocale.withRefund.totalLabel}
                      refundPolitics={subscriptionLocale.withRefund.refundPolitics}
                      onRefundPoliticsClick={handleOnRefundPoliticsClick}
                      tipTitle={subscriptionLocale.withRefund.tipTitle}
                      tip={subscriptionLocale.withRefund.tipText(subscription ? formatDate(subscription.dueDate) : null)}
                      stopCancelLabel={subscriptionLocale.withRefund.giveUp}
                      handleStopCancel={handleOnStopCancel}
                      selectMethodTitle={subscriptionLocale.withRefund.selectMethodTitle}
                      onChangeSelected={onRefundMethodSelected}
                      methodOptions={getPaymentOptions()}
                      refund={subscription.refund}
                    />
                  )
                  : (
                    <NoRefund
                      amountTitle={subscriptionLocale.noRefund.amountTitle}
                      withoutRefund={subscriptionLocale.noRefund.withoutRefund}
                      refundPolitics={subscriptionLocale.noRefund.refundPolitics}
                      tipTitle={subscriptionLocale.noRefund.tipTitle}
                      tipText={subscriptionLocale.noRefund.tipText}
                      tipDate={formatDate(subscription.dueDate)}
                      stopCancelLabel={subscriptionLocale.noRefund.stopCancelLabel}
                      handleStopCancel={handleOnStopCancel}
                      onRefundPoliticsClick={handleOnRefundPoliticsClick}
                    />
                  )
                }
                <RefundModal
                  accordionCancelledAfterContent={refundModalLocale.accordionCancelledAfterContent}
                  accordionCancelledAfterLabel={refundModalLocale.accordionCancelledAfterContent.title}
                  accordionCancelledAfterTitleHighlightPattern={refundModalLocale.accordionCancelledAfterContent.titleHighlight}
                  accordionCancelledBeforeContent={refundModalLocale.accordionCancelledBeforeContent}
                  accordionCancelledBeforeExpanded={expandRefundModalAccordion}
                  accordionCancelledBeforeLabel={refundModalLocale.accordionCancelledBeforeContent.title}
                  accordionCancelledBeforeLabelV2={refundModalLocale.accordionCancelledBeforeContent.titleV2}
                  accordionCancelledBeforeTitleHighlightPattern={refundModalLocale.accordionCancelledBeforeContent.titleHighlight}
                  accordionCancelledBeforeTitleHighlightPatternV2={refundModalLocale.accordionCancelledBeforeContent.titleHighlightV2}
                  onRefundModalClose={handleOnRefundModalClose}
                  onRefundPolicyClick={handleOnRefundPolicyClick}
                  onServiceTermsClick={handleOnServiceTermsClick}
                  open={openRefundModal}
                  refundPolicyButtonLabel={refundModalLocale.refundPolicyButtonLabel.toUpperCase()}
                  serviceTermsButtonLabel={refundModalLocale.serviceTermsButtonLabel.toUpperCase()}
                  title={refundModalLocale.title}
                  enableRefundToggle={enableRefundToggle}
                  refundModalLocaleV2={refundModalLocaleV2}
                />
              </Styles.StepConfirmationContainer>
            )
          }
          {
            step === CANCEL_SUBSCRIPTION_STEPS.REVIEW && (
              <Styles.StepConfirmationContainer data-testid="subscription-cancel-signature-step-review">
                <Review
                  title={subscriptionLocale.review.title}
                  signature={getSubscriptionName()}
                  signatureTitle={subscriptionLocale.review.signatureTitle}
                  reasonTitle={subscriptionLocale.review.reasonTitle}
                  reasonOption={cancel.questionValue}
                  reasonDescription={cancel.reason}
                  refundTitle={subscriptionLocale.review.refundTitle}
                  totalLabel={subscriptionLocale.review.totalLabel}
                  methodOptions={getPaymentOptions()}
                  refund={subscription.refund}
                />
              </Styles.StepConfirmationContainer>
            )
          }

          {
            step === CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION && (
              <Styles.StepConfirmationContainer data-testid="subscription-cancel-signature-step-confirmation">
                <SignatureConfirmation
                  canShowBackupLink={canShowBackupLink()}
                  attentionLabel={subscriptionLocale.attentionLabel}
                  backupFilesLinkLabel={subscriptionLocale.backupFilesLinkLabel}
                  backupFilesLinkUrl={subscriptionLocale.backupFilesLinkUrl}
                  onStopCancel={handleOnStopCancel}
                  onClickBackupLink={() => onClickBackupLink}
                  stopCancelLabel={subscriptionLocale.stopCancelSignatureLabel}
                  title={subscriptionLocale.confirmationTitleCancelSignatureLabel}
                  topics={[
                    canShowBackupLink()
                      ? signatureConfirmationLocale.attentionCancelSignature : signatureConfirmationLocale.attentionCancelSignatureAddon,
                    ...getCancelledAddonsTopics(),
                  ]}
                />
              </Styles.StepConfirmationContainer>
            )
          }
          {
            step === CANCEL_SUBSCRIPTION_STEPS.DONE && (
              <Styles.StepDoneContainer data-testid="subscription-cancel-signature-step-done">
                <OrderProcessing
                  text={subscriptionLocale.processing}
                />
              </Styles.StepDoneContainer>
            )
          }
        </Styles.StepContent>

        {step !== CANCEL_SUBSCRIPTION_STEPS.NEXT_RENEW_OFFER && (
          <Styles.Controls disabled={step === CANCEL_SUBSCRIPTION_STEPS.DONE}>
            {
              step !== CANCEL_SUBSCRIPTION_STEPS.DONE && (
                <>
                  <Styles.ControlLeft>
                    <Button
                      label={subscriptionLocale.controlBackButtonLabel.toUpperCase()}
                      iconLeft={<IconArrowWardsLeft />}
                      onClick={onPreviousStepClick}
                      testId="subscription-cancel-signature-back-button"
                    />
                  </Styles.ControlLeft>
                  <Styles.ControlRight>
                    <Tooltip
                      width="270"
                      position="left"
                      text={subscriptionLocale.tooltipCancelSignatureText}
                      disabled={!isSurveyStepComplete()}
                    >
                      <Button
                        disabled={isSurveyStepComplete() || !subscription.refund}
                        iconRight={step === CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION ? null : <IconArrowWardsLeft />}
                        iconRightDirection="right"
                        label={
                          (step === CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION
                            ? subscriptionLocale.controlFowardButtonStepConfirmationCancelSignatureLabel
                            : subscriptionLocale.controlFowardButtonLabel).toUpperCase()
                        }
                        onClick={handleAnswer}
                        testId="subscription-cancel-signature-foward-button"
                        variant="primary"
                      />
                    </Tooltip>
                  </Styles.ControlRight>
                </>
              )
            }
          </Styles.Controls>
        )}
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default CancelSubscriptionHandler;
