import React from 'react';
import { Card, withStyles } from '@material-ui/core';

import styles from './styles';


function TicketCard({ classes, children, className }) {
  return (
    <Card elevation={1} className={`${classes.container} ${className}`}>
      {children}
    </Card>
  );
}

export default withStyles(styles, { withTheme: true })(TicketCard);
