import React, { useEffect } from 'react';
import {
  Route, Redirect, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { withI18n } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import shortid from 'shortid';
import Template from '@/components/Layout/Template/index';
import { onChangeRouteEventTagManager } from '@/utils/ThirdParties/tagManager';
import { routesV2 } from '@/routes/routes';
import { setBreadCrumb } from '@/redux/actions/breadCrumbs';
import redirects from '@/routes/redirects';
import generateBreadCrumbs from '@/routes/generateBreadCrumbs';
import NewSitePage from '@/pages/NewSite/NewSitePage';
import FeedbackSite from '@/pages/NewSite/FeedbackSite/index';
import EmailVerify from '@/pages/EmailVerify';
import PhoneVerify from '@/pages/PhoneVerify';
import Error404Page from '@/pages/Error404Page/index';
import RedirectToWHMPage from '@/pages/SSO/RedirectToWHMPage';
import RedirectToSiteBuilderPage from '@/pages/SSO/RedirectToSiteBuilderPage/index';
import { handleLastPath } from '@/utils/Formatters/lastPath';
import { productsActions } from '@/redux/modules/';
import Loader from '@/components/Layout/Loader';
import { queryParams } from '@/utils/Application/queryParams';
import {
  ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM, CHARLIE_NEW_CHANGE_PASSWORD_PAGE, CHARLIE_SITELOCK,
  BRAVO_CHANGE_PHONE_FLOW, BRAVO_EMAIL_FLOW, USER_DATA_CHANGE_EMAIL_FLOW, USER_DATA_CHANGE_PHONE_FLOW, CHARLIE_NEW_DOMAINS_LIST, CHARLIE_LINK_NA_BIO,
  CHARLIE_LINK_NA_BIO_DATA_ANALYSIS,
  EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY, EXPERIENCE_ACCOUNT_SECURITY,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

let historyListenKey = '';
let onChangeRouteTimeout = null;

const Main = ({ t }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const newSiteFlux = useSelector(state => !state.summary.websiteBuilderDismiss && state.summary.newSiteFlux);
  const emailFlowSkip = useSelector(state => state.emailVerify.emailFlowSkip);
  const emailChangeFlow = useSelector(state => state.emailVerify.emailChangeFlow);
  const emailVerified = useSelector(state => state.summary.emailVerified);
  const phoneNumberVerified = useSelector(state => state.summary.phoneNumberVerified);
  const phoneNumberFlowSkip = useSelector(state => state.phoneNumberVerify.phoneNumberFlowSkip);
  const forceUrl = useSelector(state => state.phoneNumberVerify.forceUrl);
  const phoneNumberChangeFlow = useSelector(state => state.phoneNumberVerify.phoneNumberChangeFlow);
  const dynamicBreadCrumbs = useSelector(state => state.breadCrumbs.dynamicBreadCrumbTitles);
  const newEmailFlowEnabled = useFeatureIsOn(USER_DATA_CHANGE_EMAIL_FLOW);
  const newPhoneFlowEnabled = useFeatureIsOn(USER_DATA_CHANGE_PHONE_FLOW);
  const bravoEnableEmailFlow = useFeatureIsOn(BRAVO_EMAIL_FLOW);
  const bravoEnablePhoneFlow = useFeatureIsOn(BRAVO_CHANGE_PHONE_FLOW);
  const enableTrialTitanByUrlParam = useFeatureIsOn(ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM);
  const newChangePwPage = useFeatureIsOn(CHARLIE_NEW_CHANGE_PASSWORD_PAGE);
  const newSitelockPage = useFeatureIsOn(CHARLIE_SITELOCK);
  const newDomainsPage = useFeatureIsOn(CHARLIE_NEW_DOMAINS_LIST);
  const renderLinkNaBio = useFeatureIsOn(CHARLIE_LINK_NA_BIO);
  const renderLinkNaBioDataAnalysis = useFeatureIsOn(CHARLIE_LINK_NA_BIO_DATA_ANALYSIS);
  const renderAccountSecurity = useFeatureIsOn(EXPERIENCE_ACCOUNT_SECURITY);
  const withGatorSolutions = useFeatureIsOn(EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY);

  const isRedirecToTrialModal = queryParams(history.location.search, 'dspActtrial') !== null && enableTrialTitanByUrlParam;
  const featureTogglePhoneNumberFlow = useSelector(state => state.featureToggles.toggles && state.featureToggles.toggles.portal.phoneNumberFlow && !isRedirecToTrialModal);
  const featureToggleEmailNumberFlow = useSelector(state => state.featureToggles.toggles && state.featureToggles.toggles.portal.emailFlow && !isRedirecToTrialModal);
  const userId = useSelector(state => state.summary.id);
  const products = useSelector(state => state.products);
  const isRootRoute = history.location.pathname === '/';

  const ssoRedirectWHMP = history.location.pathname.includes(t('routes.whmRedirectAccess'));
  const ssoRedirectSiteBuilder = history.location.pathname.includes(t('routes.siteBuilderRedirectAccess'));

  const pathEmailsList = `${t('routes.emailsList')}`;
  const pathTitanUpgrade = `${t('routes.titanUpgrade')}/`;
  const pathTitanInboxUpgrade = `${t('routes.titanInboxUpgrade')}/`;

  const isTitanUrl = location.pathname.includes(pathEmailsList) || location.pathname.includes(pathEmailsList.concat(pathTitanUpgrade)) || location.pathname.includes(pathEmailsList.concat(pathTitanInboxUpgrade));

  const getLocationValue = () => {
    const locationSearch = queryParams(location.search, 'location');

    if (locationSearch !== null) {
      return locationSearch;
    }

    return location.pathname;
  };

  useEffect(() => {
    if (location.pathname !== '/') {
      handleLastPath(location, userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  history.listen((location, action) => {
    if (action === 'PUSH') {
      handleLastPath(location, userId);
    }
  });

  history.listen((historyListen) => {
    if (historyListenKey !== historyListen.key) {
      clearTimeout(onChangeRouteTimeout);
      historyListenKey = historyListen.key;
      onChangeRouteTimeout = setTimeout(() => {
        onChangeRouteEventTagManager();
      }, 100);
    }
  });

  const getBreadCrumbs = (props, Component) => {
    const isMobile = window.innerWidth < 700;
    const { match } = props;

    if (match.path === '*' && !isMobile) {
      return <Component {...props} />;
    }

    const breadCrumbs = generateBreadCrumbs(props, t, dynamicBreadCrumbs);
    dispatch(setBreadCrumb(breadCrumbs));

    return <Component {...props} />;
  };

  useEffect(() => {
    if (isRootRoute) {
      dispatch(productsActions.products.request({ history, withGatorSolutions }));
    }
  }, [dispatch, history, withGatorSolutions, isRootRoute]);

  const getRender = () => {
    if (ssoRedirectWHMP) {
      return (
        <Switch>
          <Route exact path={`${t('routes.whmRedirectAccess')}/:id`} component={RedirectToWHMPage} />
          <Route path="*" component={Error404Page} />
        </Switch>
      );
    }

    if (ssoRedirectSiteBuilder) {
      return (
        <Switch>
          <Route exact path={`${t('routes.siteBuilderRedirectAccess')}/:domain/:hostingId?`} component={RedirectToSiteBuilderPage} />
          <Route path="*" component={Error404Page} />
        </Switch>
      );
    }

    if ((bravoEnableEmailFlow && featureToggleEmailNumberFlow && !isTitanUrl && (!emailFlowSkip && !emailVerified)) || (emailChangeFlow && newEmailFlowEnabled)) {
      return (
        <Switch>
          <Route exact path={`${t('routes.emailVerify')}/:changeFlow?`} component={EmailVerify} />
          <Redirect to={`${t('routes.emailVerify')}${emailChangeFlow && newEmailFlowEnabled ? '/alterar' : ''}?location=${getLocationValue()}`} />
        </Switch>
      );
    }

    if ((bravoEnablePhoneFlow && featureTogglePhoneNumberFlow && !isTitanUrl && (!phoneNumberVerified && !phoneNumberFlowSkip)) || (phoneNumberChangeFlow && newPhoneFlowEnabled)) {
      return (
        <Switch>
          <Route exact path={`${t('routes.phoneVerify')}/:changeFlow?`} component={PhoneVerify} />
          <Redirect to={`${t('routes.phoneVerify')}${phoneNumberChangeFlow && newPhoneFlowEnabled ? '/alterar' : ''}?location=${getLocationValue()}`} />
        </Switch>
      );
    }

    if (newSiteFlux) {
      return (
        <Switch>
          <Route exact path={t('routes.newSite')} component={NewSitePage} />
          <Route exact path={`${t('routes.newSite')}${t('routes.feedbackSite')}`} component={FeedbackSite} />
          <Redirect to={t('routes.newSite')} />
        </Switch>
      );
    }

    if (forceUrl) {
      return (
        <Switch>
          {routesV2(t, undefined, {
            newChangePwPage, newSitelockPage, newDomainsPage, renderLinkNaBio, renderLinkNaBioDataAnalysis, renderAccountSecurity,
          }).map(({ path, Component }) => (
            <Route
              exact
              path={path}
              key={shortid.generate}
              render={props => <Template>{getBreadCrumbs(props, Component)}</Template>}
            />
          ))}
          <Redirect to={`${t('routes.myData')}`} />
        </Switch>
      );
    }

    return (
      <Switch>
        {redirects(t).map(({ path, redirectPath }) => (
          <Redirect
            exact
            from={path}
            to={redirectPath}
            key={shortid.generate}
          />
        ))}

        {routesV2(t, undefined, {
          newChangePwPage, newSitelockPage, newDomainsPage, renderLinkNaBio, renderLinkNaBioDataAnalysis, renderAccountSecurity,
        }).map(({ path, Component }) => (
          <Route
            exact
            path={path}
            key={shortid.generate}
            render={props => <Template>{getBreadCrumbs(props, Component)}</Template>}
          />
        ))}

        {forceUrl && (
          <Redirect to={forceUrl} />
        )}
      </Switch>
    );
  };

  if (isRootRoute && (products.loaded === false || products.loading)) {
    return <Loader />;
  }

  return getRender();
};

export default withI18n()(Main);
