import React from 'react';

import * as Styles from './SubscriptionRenewalData.styles';
import { SubscriptionRenewalDataProps } from './SubscriptionRenewalData.types';
import { Tooltip } from '@/pages/common/v1/Tooltip';
import { IconDoubt } from '@/icons/IconDoubt';
import { Button, IconInfo } from 'gatorcomponents';
import { DiscountType } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';
import { DiscountBlock } from '../../DiscountBlock';
import { isMobile } from '@/utils/Validators/validation';

const SubscriptionRenewalData = ({
  installmentsAmountLabel = 'Installments amount',
  installmentsAmountText = '',
  installmentsPaymentFormLabel = 'Payment form',
  installmentsPaymentFormText = '',
  installmentsPaymentFormTooltipText = 'Credit Card and PayPal methods process payment immediately. Boleto can take up to 72 hours to clear.',
  loading = false,
  nextRenovationDateLabel = 'Next renovation',
  nextRenovationDateText = '',
  paymentMethodLabel = 'Payment method',
  paymentMethodText = '',
  paymentMethodTooltipText = 'The installment options are defined according to the value of the product and its payment cycle.',
  renewalDataTitleLabel = 'Renewal data',
  renovationAmountLabel = 'Renovation amount',
  renovationAmountText = '',
  renovationInfoText = '',
  showInstallments = false,
  enabledDiscountRetention = false,
  discount = null,
  paymantLink = 'Pagar fatura.',
  onDisplayModalPayment = null,
}) => (
  <Styles.Wrapper data-testid="subscription-renewaldata">

    <Styles.Title data-testid="subscription-renewaldata-title">
      {renewalDataTitleLabel}
    </Styles.Title>

    <Styles.Content>

      <Styles.Item>
        <Styles.Label data-testid="subscription-renewaldata-nextrenovationdate-label">
          {nextRenovationDateLabel}
        </Styles.Label>
        <Styles.Text data-testid="subscription-renewaldata-nextrenovationdate-text">
          {(loading || !nextRenovationDateText) ? '...' : nextRenovationDateText}
        </Styles.Text>
      </Styles.Item>


      {(!discount || (discount && discount.type !== DiscountType.RETENTION)) && (
        <Styles.Item>
          <Styles.Label data-testid="subscription-renewaldata-renovationamount-label">
            {renovationAmountLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-renewaldata-renovationamount-text">
            {(loading || !renovationAmountText) ? '...' : renovationAmountText}
          </Styles.Text>
        </Styles.Item>
      )}

      {enabledDiscountRetention && discount && discount.type === DiscountType.RETENTION && (
        <Styles.ItemDiscount>
          <DiscountBlock
            discount={discount}
            loading={loading}
            renovationAmountText={renovationAmountText}
            testId="subscription-renewaldata"
            enabledStrongText={false}
            installmentsAmountText={renovationAmountLabel}
          />
        </Styles.ItemDiscount>
      )}

      <Styles.Item>
        <Styles.Label data-testid="subscription-renewaldata-paymentmethod-label">
          {paymentMethodLabel}
          <Styles.TooltipWrapper>
            <Tooltip
              content={installmentsPaymentFormTooltipText}
              preferredPosition="top"
              testId="subscription-renewaldata-paymentmethod-tooltip"
              width="290px"
            >
              <IconDoubt testId="subscription-renewaldata-paymentmethod-tooltip-icon" />
            </Tooltip>
          </Styles.TooltipWrapper>
        </Styles.Label>
        <Styles.Text data-testid="subscription-renewaldata-paymentmethod-text">
          {(loading || !paymentMethodText) ? '...' : paymentMethodText}
        </Styles.Text>
      </Styles.Item>

      {showInstallments && (
        <Styles.Item>
          <Styles.Label data-testid="subscription-renewaldata-installmentspaymentform-label">
            {installmentsPaymentFormLabel}
            <Styles.TooltipWrapper>
              <Tooltip
                content={paymentMethodTooltipText}
                preferredPosition="top"
                testId="subscription-renewaldata-installmentspaymentform-tooltip"
                width="290px"
              >
                <IconDoubt />
              </Tooltip>
            </Styles.TooltipWrapper>
          </Styles.Label>
          <Styles.Text data-testid="subscription-renewaldata-installmentspaymentform-text">
            {(loading || !installmentsPaymentFormText) ? '...' : installmentsPaymentFormText}
          </Styles.Text>
        </Styles.Item>
      )}

      {showInstallments && (
        <Styles.Item>
          <Styles.Label data-testid="subscription-renewaldata-installmentsamount-label">
            {installmentsAmountLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-renewaldata-installmentsamount-text">
            {(loading || !installmentsAmountText) ? '...' : installmentsAmountText}
          </Styles.Text>
        </Styles.Item>
      )}
    </Styles.Content>
    {
      enabledDiscountRetention && discount && discount.type === DiscountType.RETENTION && (
        <Styles.Info>
          <IconInfo color="attentionPure" size="md" />
          <span>
            {renovationInfoText}
            {isMobile && (
              <Button
                variant="text"
                onClick={() => { onDisplayModalPayment && onDisplayModalPayment(); }}
                label={paymantLink}
                testId="link-invoice-modal-payment"
              />
            )}
          </span>
        </Styles.Info>
      )
    }
  </Styles.Wrapper>
);

SubscriptionRenewalData.propTypes = SubscriptionRenewalDataProps;

export default SubscriptionRenewalData;
