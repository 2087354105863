const styles = theme => ({
  icon: {
    fontSize: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
    },
  },
  st0: { fill: '#727272' },
  st1: { fill: '#A7C7D3' },
  st2: { fill: '#D1D1D1' },
});

export default styles;
