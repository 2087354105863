import React, { useContext } from 'react';
import * as Styles from './WizardWordpressConfigPlugin.styles';
import { SlideToggle } from '@/pages/common/v1/SlideToggle';
import { Tooltip } from '@/pages/common/v1/';
import { IconInfo, IconDetailedPlugin } from '@/icons';
import { WizardPluginCard } from '../WizardPluginCard';
import { Button } from '@/pages/common/v1/Button';
import { ThemeContext } from 'styled-components';

const WizardWordpressConfigPlugin = ({
  pluginTitle = 'Plugin Title',
  pluginDescription = 'Plugin Description',
  pluginTooltip = 'Plugin Tooltip',
  plugins = [{
    id: 1,
    name: 'Plugin Sample',
    description: 'Plugin Description',
    image: <IconInfo />,
  }],
  pluginsSelected,
  handleChangePlugin,
  nextStepButtonLabel,
  open,
  wordPressPluginsBlock,
  handleLoadingWordpress,
  wodpressSetupValues,
  wordPressSetupReadyToContinue,
  themeSelected,
  wordPressInfoBlock,
  onContinueSetup,
  wordPressThemesBlock,
  validateWordpressInfo,
  asaasShouldAppear,
  setupSteps,
  activeSetupStep,
}) => {
  const theme = useContext(ThemeContext);

  const handleOnClick = () => {
    const buttonClick = !asaasShouldAppear ? handleLoadingWordpress : onContinueSetup;
    buttonClick && buttonClick(3);
  };

  const handleOnToggle = () => {
    validateWordpressInfo();

    const hasError = wodpressSetupValues.name.error || wodpressSetupValues.email.error || wodpressSetupValues.user.error;

    if (!wordPressSetupReadyToContinue) {
      if (!themeSelected) {
        onContinueSetup(1);
        window.scrollTo({
          top: wordPressThemesBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (hasError) {
        onContinueSetup(0);
        window.scrollTo({
          top: wordPressInfoBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }

      onContinueSetup(2);
    }

    if (wordPressSetupReadyToContinue) {
      onContinueSetup(0);
      window.scrollTo({
        top: wordPressInfoBlock.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Styles.Wrapper ref={wordPressPluginsBlock}>
      <SlideToggle
        open={open}
        onToggle={handleOnToggle}
        maxHeight="2000px"
        controlledByFather
        toggleOnIcon={activeSetupStep >= setupSteps.ASAAS}
        testId="slide-toggle-plugin"
        icon={<IconDetailedPlugin />}
        title={<Styles.Title>{pluginTitle}</Styles.Title>}
        description={(
          <Styles.DescriptionWrapper>
            {pluginDescription}
            <Tooltip width="376px" content={pluginTooltip}>
              <IconInfo size={18} color={theme.v1.color.action.primary} />
            </Tooltip>
          </Styles.DescriptionWrapper>
          )}
      >
        <Styles.PluginContainer>
          <Styles.PluginWrapper>
            {plugins && plugins.map((plugin) => {
              const selected = pluginsSelected.find(pluginValue => pluginValue === plugin.value);
              return (
                <WizardPluginCard
                  selected={selected}
                  handleChangePlugin={handleChangePlugin}
                  title={plugin.title}
                  image={plugin.image}
                  description={plugin.description}
                  cardValue={plugin.value}
                  id={plugin.id}
                  key={plugin.id}
                />
              );
            })}
          </Styles.PluginWrapper>
        </Styles.PluginContainer>

        <Styles.ButtonWrapper>
          <Button
            onClick={() => handleOnClick()}
            testId="plugin-next-step-button"
            label={nextStepButtonLabel}
          />
        </Styles.ButtonWrapper>
      </SlideToggle>
    </Styles.Wrapper>
  );
};

export default WizardWordpressConfigPlugin;
