import React, { useState, useLayoutEffect } from 'react';
import TitanUpgradeToast from '../TitanUpgradeToast/TitanUpgradeToast';
import { TitanUpgradeToastHandlerLocale } from './TitanUpgradeToastHandler.locale';
import { executeGAToastTitanToastDisplay, executeGAToastCloseToastClick, executeGAToastTitanDisplayPlans } from '@/utils/ThirdParties/tagManager';
import { isBRServer } from '@/utils/Validators/validation';

function TitanUpgradeToastHandler({
  setDisplayModal = () => {},
  shouldRender = true,
}) {
  const {
    knowMore,
    description,
    newTag,
    title,
    yourDomain,
  } = TitanUpgradeToastHandlerLocale;

  const [isOpen, setIsOpen] = useState(true);
  const [alreadyViewed, setAlreadyViewed] = useState(false);

  useLayoutEffect(() => {
    if (!alreadyViewed) {
      setAlreadyViewed(true);
      executeGAToastTitanToastDisplay();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    executeGAToastCloseToastClick();
  };

  const handleKnowMoreClick = () => {
    setDisplayModal(true);
    executeGAToastTitanDisplayPlans();
  };

  return (
    shouldRender && (
    <TitanUpgradeToast
      knowMore={knowMore}
      description={description}
      yourDomain={yourDomain}
      title={title}
      newTag={newTag}
      isOpen={isOpen}
      handleToggleOpen={handleToggleOpen}
      handleKnowMoreClick={handleKnowMoreClick}
      isBr={isBRServer}
    />
    )
  );
}

export default TitanUpgradeToastHandler;
