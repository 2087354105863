import React, { useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Separator } from '@/pages/common/v1/Separator';
import {
  CircularProgress,
  Dialog,
  IconButton,
} from '@material-ui/core'; import * as Styles from './SwitchPaymentMethodModal.styles';
import { PaymentMethods, SwitchPaymentMethodModalProps } from './SwitchPaymentMethodModal.types';
import useLocale from '@/hooks/useLocale';
import { Radio } from '@/pages/common';
import {
  BOLETO, BOLETO_CL_CO, BOLETO_MX, BRASPAG_CREDIT, CREDIT_CARD_ES, GATORPAY_BOLETO, GATORPAY_PAYPAL, PIX,
} from '@/config/billing/paymentMethods';
import {
  isBRServer, isCLServer, isCOServer, isMobile, isMXServer,
} from '@/utils/Validators/validation';
import { IconError } from '@/icons';
import PaypalDialog from '@/components/Paypal/PaypalDialog';
import CreditCardDialog from '@/components/CreditCard/CreditCardDialog';
import { Close } from '@material-ui/icons';
import { PAYMENT_STATUS } from '@/pages/Invoices/types';
import { useHistory } from 'react-router';
import { billingAnalytics } from '@/analytics';
import { PaypalActionTypes } from '@/components/Paypal/PaypalDialog/Paypal.types';
import { Chip } from '@/pages/common/v1/Chip';
import PixCard from '@/components/Pix/PixCard';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_OPTION_TO_SELECT_PAYPAL_ACCOUNT, XRAY_INSTALLMENTS_MODAL, XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL } from '@/config/GrowthBook/constants';
import { differenceInCalendarDays } from 'date-fns';

const SwitchPaymentMethodModal = ({
  onCloseClick,
  requestsLoading = false,
  titleLabel = 'Switch the payment method',
  selectPaymentMethodLabel = 'Select payment method',
  infoChangePaymentByTicketLabel = 'Payment by ticket info',
  infoChangePaymentByPayPalLabel = 'Payment by paypal info',
  infoChangePaymentByCreditCardLabel = 'Payment by credit card info',
  confirmSwitchLabel = 'Switch payment method',
  selectCardLabel = 'Select credit card to pay',
  infoAddNewCreditCardLabel = 'Add new credit card',
  invoiceId = '',
  actualPaymentMethod = '',
  enabledPaymentByCreditCard = true,
  enabledPaymentByPayPal = true,
  enabledPaymentByTicket = true,
  enabledPaymentByPix = false,
  onSwitchPaymentMethod,
  subscriptionId = '',
  subscriptionType = '',
  infoPaymentByPicPayLabel = '',
  infoSendEmailWithPix = '',
}) => {
  const history = useHistory();
  const { billing: billingLocale } = useLocale();
  const [option, setOption] = useState('');

  const [paymentMethod, setPaymentMethod] = useState('');
  const [creditCardInstallment, setCreditCardInstallment] = useState('');
  const [hasValidCreditCardSelected, setHasValidCreditCardSelected] = useState(false);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const isCreditCardInstallmentsEnabled = useFeatureIsOn(XRAY_INSTALLMENTS_MODAL);
  const enabledChangePayPalAccount = useFeatureIsOn(XRAY_ENABLED_OPTION_TO_SELECT_PAYPAL_ACCOUNT);
  const enabledLimitDateNewPixLabel = useFeatureIsOn(XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL);
  const limitDateNewPixLabel = useFeatureValue(XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL);
  const limitDateToDisplayNewPixLabel = enabledLimitDateNewPixLabel && limitDateNewPixLabel.limitDate != null ? limitDateNewPixLabel.limitDate : null;

  const enabledNewPixChipLabel = limitDateToDisplayNewPixLabel != null && differenceInCalendarDays(new Date(`${limitDateToDisplayNewPixLabel} 00:00:00`), new Date()) > 0;

  const {
    paymentMethods: paymentMethodsLocale,
    manageSubscriptionPaymentInvoiceModal: labelsLocale,
  } = billingLocale;

  const isCreditCardSelected = () => option === PaymentMethods.CREDIT_CARD;

  const isTicketSelected = () => option === PaymentMethods.TICKET;

  const isPayPalSelected = () => option === PaymentMethods.PAY_PAL;

  const isPixSelected = () => option === PaymentMethods.PIX;

  const isPaymentByTicket = () => [BOLETO, BOLETO_CL_CO, BOLETO_MX, GATORPAY_BOLETO].includes(actualPaymentMethod);

  const isPaymentByPix = () => [PIX].includes(actualPaymentMethod);

  const handleOnCloseClick = (event) => {
    subscriptionAnalytics.changePaymentMethodClick('2_opção_fechar');
    onCloseClick && onCloseClick(event);
  };

  const onSetCreditCardSelected = (isValidCreditCardSelected) => {
    setHasValidCreditCardSelected(isValidCreditCardSelected);

    subscriptionAnalytics.changePaymentMethodClick(isValidCreditCardSelected ? '3_usar_cartão_existente' : '3_usar_cartão_novo');
  };

  const getTypeCreditCard = () => {
    if (!isBRServer) {
      return CREDIT_CARD_ES;
    }
    if (creditCardInstallment !== '') {
      return creditCardInstallment;
    }

    return BRASPAG_CREDIT;
  };

  const onSelectCreditCardInstallment = (newCreditCardInstallment) => {
    setCreditCardInstallment(newCreditCardInstallment);
    setPaymentMethod(newCreditCardInstallment);
  };

  const getTypeTicket = () => {
    if (isCLServer || isCOServer) {
      return BOLETO_CL_CO;
    }

    if (isMXServer) {
      return BOLETO_MX;
    }

    return GATORPAY_BOLETO;
  };
  const onPaymentPayPalExecuted = (event, route) => {
    if (event && event.status === PAYMENT_STATUS.SUCCESS) {
      history.push(route);
    }
  };

  const onPaymentCreditCardExecuted = (event, route) => {
    if (event && (event.status === PAYMENT_STATUS.SUCCESS || event.status === PAYMENT_STATUS.PENDING_ANALYSIS)) {
      history.push(route);
    }
  };

  const clickTiket = () => {
    const typeTicket = getTypeTicket();
    setOption(PaymentMethods.TICKET);
    setPaymentMethod(typeTicket);
    subscriptionAnalytics.changePaymentMethodClick('2_selecionar_boleto');
  };

  const clickPayPal = () => {
    setOption(PaymentMethods.PAY_PAL);
    setPaymentMethod(GATORPAY_PAYPAL);
    subscriptionAnalytics.changePaymentMethodClick('2_selecionar_paypal');
  };

  const clickCreditCard = () => {
    setOption(PaymentMethods.CREDIT_CARD);
    subscriptionAnalytics.changePaymentMethodClick('2_selecionar_cartão');
    if (!isBRServer) {
      setPaymentMethod(CREDIT_CARD_ES);
    }
  };

  const clickPix = () => {
    setOption(PaymentMethods.PIX);
    setPaymentMethod(PIX);

    subscriptionAnalytics.changePaymentMethodClick('2_selecionar_pix');
  };

  const getInfoChangeMessage = () => {
    if (isTicketSelected()) {
      return infoChangePaymentByTicketLabel;
    }

    if (isPayPalSelected()) {
      return infoChangePaymentByPayPalLabel;
    }

    if (isCreditCardSelected()) {
      return infoChangePaymentByCreditCardLabel;
    }

    return '';
  };

  const isDisabledSwitchPaymentButton = () => {
    if (isCreditCardSelected()) {
      return isBRServer ? creditCardInstallment === '' || !hasValidCreditCardSelected : !hasValidCreditCardSelected;
    }
    return false;
  };

  const switchPaymentMethod = () => {
    if (onSwitchPaymentMethod && paymentMethod !== '') {
      if (isCreditCardSelected()) {
        onSwitchPaymentMethod(getTypeCreditCard);
      }

      onSwitchPaymentMethod(paymentMethod);
      subscriptionAnalytics.changePaymentMethodClick('3_confirmar_alteração');
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      open
      onClose={handleOnCloseClick}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          maxWidth: '742px',
        },
      }}
    >
      <Styles.Content>
        <Styles.ContentCloseButton>
          <IconButton data-testid="btn-close-switch-payment-modal" aria-label="Fechar" onClick={handleOnCloseClick}>
            <Close />
          </IconButton>
        </Styles.ContentCloseButton>
        <Styles.Header>
          <Styles.Title data-testid="subscription-switch-payment-method-title">{titleLabel}</Styles.Title>
        </Styles.Header>
        <Styles.Data>

          <Styles.ContentPaymentSelector>
            {selectPaymentMethodLabel}
            <Styles.ContentOptionsInSelectord>
              {enabledPaymentByCreditCard && (
              <Styles.OptionItem
                data-testid="subscription-manage-switch-payment-method-credit-card-option"
                checked={isCreditCardSelected()}
                onClick={clickCreditCard}
              >
                <Styles.OptionItemWrapper>
                  <Styles.OptionRadio checked={isCreditCardSelected()}>
                    <Radio
                      size="medium"
                      checked={isCreditCardSelected()}
                      inputId="CancelOptions."
                      variant="primary"
                      testId="credit-card-option-selection"
                    />
                  </Styles.OptionRadio>
                  <Styles.OptionContent>
                    <Styles.OptionLabel
                      checked={false}
                      data-testid="subscription-manage-switch-payment-method-credit-card-option-label"
                    >
                      {paymentMethodsLocale.creditCard}
                    </Styles.OptionLabel>

                  </Styles.OptionContent>
                </Styles.OptionItemWrapper>
              </Styles.OptionItem>
              )}
              {!isPaymentByPix() && enabledPaymentByPix && (
                <Styles.OptionItem
                  data-testid="subscription-manage-switch-payment-method-pix-option"
                  checked={isPixSelected()}
                  onClick={clickPix}
                >
                  <Styles.OptionItemWrapper>
                    <Styles.OptionRadio checked={isPixSelected()}>
                      <Radio
                        size="medium"
                        checked={isPixSelected()}
                        inputId="CancelOptions."
                        testId="pix-option-selection"
                        variant="primary"
                      />
                    </Styles.OptionRadio>
                    <Styles.OptionContent>
                      <Styles.OptionLabel
                        checked={false}
                        data-testid="subscription-manage-switch-payment-method-pix-option-label"
                      >
                        {paymentMethodsLocale.pix}
                        {enabledNewPixChipLabel && (
                        <Styles.Chip>
                          <Chip
                            data-testid="new-pix-label"
                            label={paymentMethodsLocale.new}
                            primaryColor={isPixSelected() ? '#1D5297' : '#2E93EE'}
                            variant="straightEdges"
                            weight="bold"
                            uppercase
                          />
                        </Styles.Chip>
                        )}
                      </Styles.OptionLabel>

                    </Styles.OptionContent>
                  </Styles.OptionItemWrapper>
                </Styles.OptionItem>
              )}
              {!isPaymentByTicket() && enabledPaymentByTicket && (
              <Styles.OptionItem
                data-testid="subscription-manage-switch-payment-method-ticket-option"
                onClick={clickTiket}
                checked={isTicketSelected()}
              >
                <Styles.OptionItemWrapper>
                  <Styles.OptionRadio checked={isTicketSelected()}>
                    <Radio
                      variant="primary"
                      size="medium"
                      checked={isTicketSelected()}
                      onChange
                      testId="ticket-option-selection"
                      inputId="CancelOptions."
                    />
                  </Styles.OptionRadio>
                  <Styles.OptionContent>
                    <Styles.OptionLabel
                      checked={false}
                      data-testid="subscription-manage-switch-payment-method-ticket-option-label"
                    >
                      {paymentMethodsLocale.ticket}
                    </Styles.OptionLabel>

                  </Styles.OptionContent>
                </Styles.OptionItemWrapper>
              </Styles.OptionItem>
              )}
              {enabledPaymentByPayPal && (
              <Styles.OptionItem
                data-testid="subscription-manage-switch-payment-method-pay-pal-option"
                checked={isPayPalSelected()}
                onClick={clickPayPal}
              >
                <Styles.OptionItemWrapper>
                  <Styles.OptionRadio checked={isPayPalSelected()}>
                    <Radio
                      size="medium"
                      checked={isPayPalSelected()}
                      inputId="CancelOptions."
                      testId="paypal-option-selection"
                      variant="primary"
                    />
                  </Styles.OptionRadio>
                  <Styles.OptionContent>
                    <Styles.OptionLabel
                      checked={false}
                      data-testid="subscription-manage-switch-payment-method-pay-pal-option-label"
                    >
                      {paymentMethodsLocale.paypal}
                    </Styles.OptionLabel>

                  </Styles.OptionContent>
                </Styles.OptionItemWrapper>
              </Styles.OptionItem>
              )}
            </Styles.ContentOptionsInSelectord>

          </Styles.ContentPaymentSelector>
          {option !== '' && (
          <Separator
            data-testid="subscription-switch-payment-separator"
          />
          )}
        </Styles.Data>

        {isPixSelected() && (
          <>
            <Styles.WrapperInfo data-testid="text-info-pix">
              {infoPaymentByPicPayLabel}
            </Styles.WrapperInfo>
            <PixCard enabledContentDoubts={false} />
            <Styles.InfoPix>
              <Styles.InfoIcon>
                <IconError
                  size="26"
                  testId="info-pix-send-email-icon-attention"
                />
              </Styles.InfoIcon>
              <Styles.InfoText
                data-testid="info-pix-send-email-label"
              >
                {infoSendEmailWithPix}
                <br />
              </Styles.InfoText>
            </Styles.InfoPix>
          </>
        )}
        {isCreditCardSelected() && (
        <>
          {enabledPaymentByCreditCard && (
          <CreditCardDialog
            subscriptionId={subscriptionId}
            subscriptionType={subscriptionType}
            invoiceId={invoiceId}
            newPaymentMethod={paymentMethod}
            showResumeData={false}
            displayActionButton={false}
            onSelectCreditCardInstallment={onSelectCreditCardInstallment}
            selectCardLabel={selectCardLabel}
            infoAddNewCreditCardLabel={infoAddNewCreditCardLabel}
            onClose={handleOnCloseClick}
            onSetCreditCardSelected={onSetCreditCardSelected}
            onPaymentExecuted={onPaymentCreditCardExecuted}
            isCreditCardInstallmentsEnabled={isCreditCardInstallmentsEnabled}
          />
          )}
        </>
        )}
        {option !== '' && (
        <>
          { !enabledChangePayPalAccount && (
          <Styles.Info>
            <Styles.InfoIcon>
              <IconError
                size="26"
                testId="icon-info-text-switch-payment"
              />
            </Styles.InfoIcon>
            <Styles.InfoText
              data-testid="info-text-switch-payment"
            >
              {getInfoChangeMessage()}

            </Styles.InfoText>
          </Styles.Info>
          )}
          {!isPayPalSelected() && (
          <Styles.Controls>
            <Styles.ControlButton>
              <Button
                testId="subscription-switch-payment-button"
                onClick={switchPaymentMethod}
                disabled={isDisabledSwitchPaymentButton() || requestsLoading}
                label={requestsLoading
                  ? (
                    <>
                      <CircularProgress color="#00000" size={16} data-id="to-ticket-loader" />
                      {'   '}
                      {confirmSwitchLabel}
                    </>
                  )
                  : confirmSwitchLabel}
              />
            </Styles.ControlButton>
          </Styles.Controls>
          )}

          {isPayPalSelected() && (
          <>
            {enabledPaymentByPayPal && (
            <PaypalDialog
              close={handleOnCloseClick}
              displayActionButton
              enabledChangePayPalAccount={enabledChangePayPalAccount}
              invoiceId={invoiceId}
              onPaymentExecuted={onPaymentPayPalExecuted}
              open
              showResumeData={false}
              switchPaymentMethod={switchPaymentMethod}
              isSubscriptionsPage={switchPaymentMethod}
              labelsLocale={labelsLocale}
              callingOrigin={PaypalActionTypes.CALLING_BY_SWITCH_PAYMENT_METHOD_MODAL}
            />
            )}
          </>
          )}
        </>
        )}
      </Styles.Content>
    </Dialog>
  );
};

SwitchPaymentMethodModal.propTypes = SwitchPaymentMethodModalProps;

export default SwitchPaymentMethodModal;
