import React from 'react';
import * as Style from './IconDetailedDomain.style';

const IconDetailedDomain = ({
  color = '#4a4a4a',
  background = '#3c97ff',
  width = '32',
  height = '30',
  testId = 'icon-detailed-domain',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32.001 30">
      <g data-name="Grupo 40282" transform="translate(-490 -735)" fill={color}>
        <rect data-name="Retângulo 14835" width="22" height="24" transform="translate(490 735)" fill={background} />
        <g data-name="Grupo 36657" transform="translate(493.121 739.16)">
          <g data-name="Grupo 34248" transform="translate(0 0)">
            <g data-name="Grupo 34249">
              <g data-name="Grupo 34258" transform="translate(0 0)">
                <g data-name="Retângulo 13303">
                  <rect data-name="Retângulo 14356" width="29" height="15" rx="3" transform="translate(-0.12 -0.16)" fill="none" />
                  <g data-name="Grupo 36513" transform="translate(0.13 1.136)">
                    <path data-name="Caminho 238974" d="M31.141,21.135H8.586A3.079,3.079,0,0,1,5.51,18.059V11.908A3.078,3.078,0,0,1,8.586,8.832H31.141a3.079,3.079,0,0,1,3.076,3.076v6.151A3.079,3.079,0,0,1,31.141,21.135ZM8.586,10.37a1.539,1.539,0,0,0-1.538,1.538v6.151A1.54,1.54,0,0,0,8.586,19.6H31.141a1.54,1.54,0,0,0,1.538-1.538V11.908a1.539,1.539,0,0,0-1.538-1.538Z" transform="translate(-5.51 -8.832)" />
                  </g>
                </g>
                <g data-name="Retângulo 13306" transform="translate(0 12.413)">
                  <rect data-name="Retângulo 14357" width="29" height="13" rx="3" transform="translate(-0.12 0.427)" fill="none" />
                  <g data-name="Grupo 36514" transform="translate(0.13 0)">
                    <path data-name="Caminho 238975" d="M31.141,32.135H8.586A3.079,3.079,0,0,1,5.51,29.059V22.908a3.078,3.078,0,0,1,3.076-3.076H31.141a3.079,3.079,0,0,1,3.076,3.076v6.151A3.079,3.079,0,0,1,31.141,32.135ZM8.586,21.37a1.539,1.539,0,0,0-1.538,1.538v6.151A1.54,1.54,0,0,0,8.586,30.6H31.141a1.54,1.54,0,0,0,1.538-1.538V22.908a1.539,1.539,0,0,0-1.538-1.538Z" transform="translate(-5.51 -19.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1043" transform="translate(5.256 6.422)">
                  <g data-name="Grupo 36515" transform="translate(0 0)">
                    <path data-name="Caminho 238976" d="M11.535,16.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,11.535,16.882Z" transform="translate(-10.51 -14.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1049" transform="translate(5.256 18.485)">
                  <g data-name="Grupo 36516" transform="translate(0 0)">
                    <path data-name="Caminho 238977" d="M11.535,27.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,11.535,27.882Z" transform="translate(-10.51 -25.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1044" transform="translate(8.332 6.422)">
                  <g data-name="Grupo 36517" transform="translate(0 0)">
                    <path data-name="Caminho 238978" d="M14.535,16.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,14.535,16.882Z" transform="translate(-13.51 -14.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1048" transform="translate(8.332 18.485)">
                  <g data-name="Grupo 36518" transform="translate(0 0)">
                    <path data-name="Caminho 238979" d="M14.535,27.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,14.535,27.882Z" transform="translate(-13.51 -25.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1045" transform="translate(11.408 6.422)">
                  <g data-name="Grupo 36519" transform="translate(0 0)">
                    <path data-name="Caminho 238980" d="M17.535,16.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,17.535,16.882Z" transform="translate(-16.51 -14.832)" />
                  </g>
                </g>
                <g data-name="Elipse 1047" transform="translate(11.408 18.485)">
                  <g data-name="Grupo 36520" transform="translate(0 0)">
                    <path data-name="Caminho 238981" d="M17.535,27.882a1.025,1.025,0,1,1,1.025-1.025A1.026,1.026,0,0,1,17.535,27.882Z" transform="translate(-16.51 -25.832)" />
                  </g>
                </g>
                <g data-name="Caminho 218161" transform="translate(17.528 6.578)">
                  <path data-name="Caminho 238982" d="M28.615,16.522H23.249a.769.769,0,1,1,0-1.538h5.366a.769.769,0,1,1,0,1.538Z" transform="translate(-22.48 -14.984)" />
                </g>
                <g data-name="Caminho 218162" transform="translate(17.528 18.485)">
                  <path data-name="Caminho 238983" d="M28.615,27.088H23.249a.769.769,0,1,1,0-1.538h5.366a.769.769,0,1,1,0,1.538Z" transform="translate(-22.48 -25.55)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedDomain;
