import React, { useEffect } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './SubscriptionActiveRenewalModal.styles';
import { IconError } from '@/icons';
import { SubscriptionRenewalModalData } from '../SubscriptionRenewalModalData';
import { SubscriptionActiveRenewalModalProps } from './SubscriptionActiveRenewalModal.types';
import { locale } from '@/utils/locale';
import useLocale from '@/hooks/useLocale';
import { billingAnalytics } from '@/analytics';
import { DiscountType } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';

const SubscriptionActiveRenewalModal = ({
  confirmButtonLabel = 'Confirm activation',
  descriptionAlertInfoLabel = 'Select the form you want below:',
  onCloseClick,
  onConfirmButtonClick,
  titleLabel = 'Activate renewal subscription',
  installmentsAmountText = '',
  productNameText = '',
  paymentMethodText = '',
  renovationAmountText = '',
  installmentsPaymentFormText = '',
  nextRenovationDateText = '',
  showInstallments = true,
  enabledPaymentInfo = true,
  discount,
  from,
  to,
  discountText,
  note,
  paymentCycleText,
  paymentCycleLabel,
  origin,
  enabledDiscountOfferBanner,
}) => {
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    origin === 'url'
      ? subscriptionAnalytics.activeRenewalModalDisplayByURL()
      : subscriptionAnalytics.activeRenewalModalDisplay();

    if (discount) {
      subscriptionAnalytics.activeRenewalModalDisplayDiscountBlock();
    }
  }, []);

  const handleOnCloseClick = (event) => {
    subscriptionAnalytics.activeRenewalModalClose();
    onCloseClick && onCloseClick(event);
  };
  const { billing: billingLocale } = useLocale();

  const {
    manageSubscription: manageSubscriptionLocale,
  } = billingLocale;


  const handleOnConfirmButtonClick = () => {
    subscriptionAnalytics.activeRenewalModalClick();
    onConfirmButtonClick && onConfirmButtonClick();
  };

  const getSubscriptionRenewalModalDataProps = () => ({
    installmentsAmountLabel: manageSubscriptionLocale.installmentsAmountLabel,
    installmentsAmountText,
    installmentsPaymentFormLabel: manageSubscriptionLocale.installmentsPaymentFormLabel,
    installmentsPaymentFormText,
    loading: false,
    nextRenovationDateLabel: manageSubscriptionLocale.nextRenovationDateLabel,
    nextRenovationDateText,
    paymentMethodLabel: manageSubscriptionLocale.paymentMethodLabel,
    productLabel: locale(manageSubscriptionLocale.productNameLabel),
    paymentMethodText,
    paymentCycleText,
    paymentCycleLabel,
    renovationAmountLabel: manageSubscriptionLocale.renovationAmountLabel,
    renovationAmountText,
    productNameText,
    showInstallments,
    enabledPaymentInfo,
    enabledDiscountOfferBanner,
    discount,
    from,
    to,
    discountText,
  });

  return (
    <Styles.Wrapper>
      <Modal
        maxWidth={550}
        disablePadding
        onClose={handleOnCloseClick}
        testId="subscription-manage-active-renewal-modal"
        variant="regular"
      >
        <Styles.Content>
          <Styles.Header>
            <Styles.Title data-testid="subscription-manage-active-renewal-title">{titleLabel}</Styles.Title>
          </Styles.Header>
          <Styles.Data>
            <SubscriptionRenewalModalData {...getSubscriptionRenewalModalDataProps()} />
            {discount && discount.type === DiscountType.RENEWAL && enabledDiscountOfferBanner && (
              <Styles.Note>{note}</Styles.Note>
            )}
          </Styles.Data>
          <Styles.Info>
            <Styles.InfoIcon>
              <IconError
                size="26"
                testId="activate-automatic-billing-modal-icon-attention"
              />
              <Styles.InfoText data-testid="subscription-manage-active-renewal-description">{descriptionAlertInfoLabel}</Styles.InfoText>
            </Styles.InfoIcon>
          </Styles.Info>
          <Styles.Controls>
            <Styles.ControlButton>
              <Button
                testId="subscription-manage-active-renewal-confirm-button"
                onClick={handleOnConfirmButtonClick}
                label={confirmButtonLabel}
              />
            </Styles.ControlButton>
          </Styles.Controls>
        </Styles.Content>
      </Modal>
    </Styles.Wrapper>
  );
};

SubscriptionActiveRenewalModal.propTypes = SubscriptionActiveRenewalModalProps;

export default SubscriptionActiveRenewalModal;
