import React, { useCallback, useState } from 'react';
import * as Styles from './WhmcsAcquisition.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const WhmcsAcquisition = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { whmcsAcquisition: whmcsAcquisitionLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    email: '',
    problem: '',
    directory: '',
    print: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.domain
    && !!formData.email
    && !!formData.problem
    && !!formData.agreed
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.updateWhmcsLicense,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.WHMCS_ACQUISITION,
      tags: [
        'whmcs_form_tecnico_licenca_whmcs',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
         • ${whmcsAcquisitionLocale.body.product(selectedProduct)}
         • ${whmcsAcquisitionLocale.body.request}
         • ${whmcsAcquisitionLocale.placeholders.domain}: ${formData.domain}
         • ${whmcsAcquisitionLocale.placeholders.email}: ${formData.email}
         • ${whmcsAcquisitionLocale.placeholders.problem}: ${formData.problem}
         • ${whmcsAcquisitionLocale.placeholders.directory}: ${formData.directory}
         • ${whmcsAcquisitionLocale.body.agreed({ text: whmcsAcquisitionLocale.placeholders.agreed, bool: formData.agreed })}
         • ${whmcsAcquisitionLocale.body.productId(selectedProduct)}
      `,
    };

    if (formData.print && formData.print.binary) {
      data.files = [formData.print.binary];
    }

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    whmcsAcquisitionLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);


  const handleFile = (e) => {
    setFormData({
      ...formData,
      print: {
        path: e.currentTarget.value,
        binary: e.target.files[0],
      },
    });
  };


  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {whmcsAcquisitionLocale.info}
      </Styles.Info>

      <Styles.Title>
        {whmcsAcquisitionLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="domain"
          placeholder={whmcsAcquisitionLocale.placeholders.domain}
          label={formData.domain ? whmcsAcquisitionLocale.placeholders.domain : ''}
          value={formData.domain}
          onChange={e => handleChange({ key: 'domain', value: e.target.value })}
          type="text"
          testId="domain"
        />

        <Input
          name="email"
          placeholder={whmcsAcquisitionLocale.placeholders.email}
          label={formData.email ? whmcsAcquisitionLocale.placeholders.email : ''}
          value={formData.email}
          onChange={e => handleChange({ key: 'email', value: e.target.value })}
          type="text"
          testId="email"
        />

        <Input
          name="problem"
          placeholder={whmcsAcquisitionLocale.placeholders.problem}
          label={formData.problem ? whmcsAcquisitionLocale.placeholders.problem : ''}
          value={formData.problem}
          onChange={e => handleChange({ key: 'problem', value: e.target.value })}
          type="textarea"
          rows={5}
          testId="problem"
        />

        <Input
          name="directory"
          placeholder={whmcsAcquisitionLocale.placeholders.directory}
          label={formData.directory ? whmcsAcquisitionLocale.placeholders.directory : ''}
          value={formData.directory}
          onChange={e => handleChange({ key: 'directory', value: e.target.value })}
          type="text"
          testId="directory"
        />

        <Styles.FileWrapper>
          <Styles.FileButton htmlFor="picture" file={!!formData.print}>
            {(formData.print && formData.print.binary.name) || whmcsAcquisitionLocale.placeholders.addDocumentButton}
          </Styles.FileButton>
          <Styles.FileInput
            type="file"
            id="picture"
            data-testid="picture"
            accept=".jpg, .png, .pdf, .doc"
            value={(formData.print && formData.print.path)}
            onChange={e => handleFile(e)}
          />
        </Styles.FileWrapper>

      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {whmcsAcquisitionLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : whmcsAcquisitionLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default WhmcsAcquisition;
