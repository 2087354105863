import { Button, Link } from 'gatorcomponents';
import React from 'react';

export default {
  tabs: {
    labels: {
      general: 'Visão Geral',
      create: 'Fazer Backup',
      restore: 'Restaurar Backup',
      log: 'Últimos Backups',
      cpanel: 'Backup pelo cPanel',
    },
    create: {
      title: 'Fazer backup',
      description: 'Baixe a cópia do diretório inicial de arquivos da sua hospedagem.',
      alertTitle: 'Informações sobre o backup',
      alertDescription: (
        <>
          Esse backup irá baixar no seu computador
          {' '}
          <strong>somente os arquivos do diretório principal do seu site</strong>
          {' '}
          (não inclui banco de dados e e-mails).
          <br />
          <strong>Lembre-se</strong>
          : cada backup completo possui limite de 10GB.
          <br />
          Para guardar, recomendamos que faça o download no seu computador e salve em algum local seguro.
        </>
      ),
      alertDescription2: onClick => (
        <>
          <p>
            {'Esse backup irá baixar no seu computador somente '}
            <strong>os arquivos do diretório principal da sua hospedagem</strong>
            {' (não inclui banco de dados e e-mails).'}
          </p>

          <p>Não feche seu navegador até a conclusão do download do arquivo.</p>

          <p>
            {'Se você precisa de um backup completo, incluindo todos os arquivos, bancos de dados e e-mails, '}
            <Button variant="text" onClick={onClick} label="utilize esse formulário." />
          </p>
        </>
      ),
      proceedAlertDescription: (
        <>
          Ao prosseguir, o backup dos seus arquivos
          {' '}
          <strong>será baixado no seu computador</strong>
          . Aguarde a conclusão e, se quiser restaurar, faça upload dos arquivos na aba “Restaurar Backup”.
        </>
      ),
      backupLabel: 'Baixar backup',
      backupError: {
        title: 'Erro ao fazer backup',
        description: 'Tivemos um problema ao gerar seu backup. Aguarde alguns instantes tente novamente.',
      },
      backupSuccess: {
        title: 'Backup gerado',
        description: 'Seu backup foi gerado e o arquivo está sendo baixado pelo seu navegador. Confira o arquivo nos downloads do seu computador e não gere um backup novamente até a conclusão.',
      },
    },
    restore: {
      title: 'Restaurar backup - Diretório Inicial ou Banco de Dados',
      description: 'Confira como restaurar um backup.',
      alertTitle: 'Saiba como restaurar',
      alertDescription: (
        <>
          <p>Para restaurar um backup a partir do arquivo que você baixou anteriormente (Diretório Inicial ou Banco de Dados), siga estes passos:</p>

          <p>
            <ol>
              <li>Localize o arquivo de backup no seu computador.</li>
              <li>
                Faça o upload do arquivo utilizando a opção
                <strong>{' “Restaurar” '}</strong>
                ,no cPanel.
              </li>
              <li>Aguarde até que todos os arquivos sejam transferidos.</li>
              <li>A restauração estará completa.</li>
            </ol>
          </p>

          <p>
            {'Atenção: o arquivo precisa ter extensão '}
            <strong>“.tar”</strong>
            {' ou '}
            <strong>“.tar.gz”</strong>
            {'. O tamanho máximo do arquivo a ser enviado deve ser de '}
            <strong>500MB para Diretório Inicial e 50MB para Banco de Dados.</strong>
          </p>

          <p>
            {'Para mais instruções, confira o material de apoio '}
            <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/18012735627035-Como-fazer-uma-c%C3%B3pia-de-seguran%C3%A7a-backup-e-restaura%C3%A7%C3%A3o-na-HostGator-#restaura%C3%A7%C3%A3o-diretorio-principal" text="como fazer um backup." />
          </p>

          <p>
            {'Caso o arquivo seja maior que o informado, recomendamos '}
            <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/360052708173-Quais-s%C3%A3o-os-dados-de-acesso-do-FTP-" text="restaurar via FTP." />
          </p>
        </>
      ),
      agreement: (
        <>
          <strong>Atenção:</strong>
          <br />
          Ao restaurar, você perderá todas as alterações feitas nos arquivos ou dados do seu site após a data de geração do arquivo de backup.
        </>
      ),
      uploadLabel: 'Restaurar via cPanel',
    },
    generalVision: {
      title: 'Visão geral',
      description: (date, hour) => (
        <>
          {'O seu último backup realizado foi em '}
          <strong>{date}</strong>
          {' '}
          <strong>
            {`às ${hour}h`}
          </strong>
          . Confira os detalhes abaixo:
        </>
      ),
      noBackupDescription: 'Você não tem um backup recente. Confira os detalhes abaixo:',
      centralQuestions: () => (
        <>
          {'Veja as '}
          <strong>principais dúvidas e respostas</strong>
          {' aqui:'}
        </>
      ),
      status: {
        completed: 'REALIZADO',
        attention: 'ATENÇÃO',
        error: 'ERRO',
        inProgress: 'EM ANDAMENTO',
        completedAlert: date => (
          <>
            {'Seu último backup '}
            <strong>
              foi feito com sucesso em
              {' '}
              {date}
            </strong>
            . Isso significa que seu backup está atualizado! :)
          </>
        ),
        noBackupAlert: handleClick => (
          <>
            Você não tem backup desse plano.
            <br />
            <Button type="button" variant="text" onClick={() => handleClick()} label="Faça um backup agora" />
            {' '}
            <strong>para não perder os seus dados</strong>
            .
          </>
        ),
        oldBackupAlert: (handleClick, days) => (
          <>
            {`Seu último backup foi realizado há mais de ${days} dias. `}
            <Button type="button" variant="text" onClick={() => handleClick()} label="Atualize seu backup agora" />
            <br />
            <strong>para não perder os seus dados</strong>
            .
          </>
        ),
        errorBackupAlert: handleClick => (
          <>
            {'Tivemos um problema ao realizar seu backup. Espere alguns instantes e '}
            <Button type="button" variant="text" onClick={() => handleClick()} label="clique aqui para tentar novamente" />
            {'. '}
          </>
        ),
        inProgressBackupAlert: 'Seu backup está em andamento. Aguarde alguns instantes para ele estar completo.',
      },
      firstStep: {
        title: 'Primeiros passos - Backup',
        description: 'Realize backups e restaurações do seu site com facilidade, seguindo nosso passo a passo simplificado.',
        label: 'Iniciar Restauração',
        labelWithGeneration: 'Iniciar Backup/Restauração',
      },
      faq: {
        faqTitle: (
          <>
            {'Veja as '}
            <strong>principais dúvidas e respostas</strong>
            {' aqui:'}
          </>
        ),
        backupTypesAccordion: {
          title: 'Quais são os tipos de backup?',
          content: {
            description: 'Tem várias opções de backup que você pode fazer para ter uma cópia de segurança dos dados que você deseja. Dependendo do tipo do backup executado vai obter resultados distintos. Veja abaixo quais são:',
            fullBackup: (
              <>
                <strong>Backup Completo:</strong>
                {` Faz uma cópia de tudo o que estiver atrelado a sua conta de hospedagem. Isso inclui arquivos e pastas dos diretórios, como e-mails do webmail (cPanel) e banco de dados de todos os sites existentes em sua conta de hospedagem (diretório principal, domínios adicionais e subdomínios).
                Contas de e-mail Titan não entram no backup completo, você terá que `}
                <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/1260804497929" text="realizar o procedimento" />
                {' separadamente.'}
              </>
            ),
            parcialBackup: (
              <>
                <strong>Backup do Diretório principal (backup parcial):</strong>
                {` Faz a cópia somente dos arquivos do domínio principal ou raiz.
                O diretório raiz é onde os arquivos de seu domínio principal do site estão armazenados, geralmente é a pasta “public_html” - Exceto quando o site foi instalado em outra pasta, é um subdomínio ou um domínio adicional, não inclui banco de dados e nem e-mails.`}
              </>
            ),
            emailsBackup: (
              <>
                <strong>Backup dos e-mails:</strong>
                {` Faz uma cópia das informações armazenadas nas contas de e-mail webmail (cPanel) de cada domínio.
                Caso tenha uma conta de e-mail Titan não realizamos o backup, para isso, veja como `}
                <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/1260804497929" text="realizar o procedimento" />
                .
              </>
            ),
            databaseBackup: (
              <>
                <strong>Backup do banco de dados:</strong>
                {' Faz a cópia de todas as tabelas que armazena os dados de um site, envolve a exportação de todas essas tabelas (geralmente em formato SQL).'}
              </>
            ),
          },
        },
        createBackupAccordion: {
          title: 'Como fazer um backup?',
          content: {
            description: 'Existem duas formas de fazer um backup:',
            byCpanel: (
              <>
                <strong>Backup avançado pelo cPanel:</strong>
                {' Este backup permite o download separadamente dos arquivos e pastas, banco de dados e dos e-mails desde que seja webmail (cPanel).'}
              </>
            ),
            byPortal: (
              <>
                <strong>Backup pelo Portal do Cliente:</strong>
                {' backup somente dos arquivos do diretório principal (domínio principal) do seu site. Veja como:'}
                <section>
                  <span>1.</span>
                  Na tela “Gerenciar Plano” clique em
                  <>&nbsp;</>
                  <strong>Fazer backup</strong>
                  ;
                </section>
                <section>
                  <span>2.</span>
                  Na modal “Fazer backup” clique em
                  <>&nbsp;</>
                  <strong>Backup Portal do Cliente</strong>
                  ;
                </section>
                <section>
                  <span>3.</span>
                  Em seguida, clique em
                  <>&nbsp;</>
                  <strong>Fazer backup</strong>
                  ;
                </section>
                <section>
                  <span>4.</span>
                  Após, aparecerá disponível o arquivo para fazer
                  <>&nbsp;</>
                  <strong>download em seu computador</strong>
                  .
                </section>
              </>
            ),
          },
        },
        maxSizeBackupAccordion: {
          title: 'Qual tamanho do máximo do meu armazenamento?',
          content: {
            paragraphOne: (
              <>
                {'Para realizar o backup você precisa possuir '}
                <strong>menos de 10GB </strong>
                {' de conteúdo em uso no plano de hospedagem.'}
              </>
            ),
            paragraphTwo: 'Caso exceda os limites mencionados abaixo, sairá automaticamente da nossa rotina de armazenamento.',
            paragraphThree: 'O backup ficará disponibilizado para fazer download em seu computador.',
          },
        },
        restoreBackupAccordion: {
          title: 'Como restaurar um backup?',
          content: {
            description: 'Antes de fazer a restauração é importante certificar-se de que os arquivos não estão corrompidos e que seu backup contenha tudo o que você precisa para fazer uma restauração completa.',
            subdescription: (
              <>
                {'Existem '}
                <strong>duas opções</strong>
                {' para restaurar o backup:'}
              </>
            ),
            byPortal: (
              <>
                <strong>Via Portal do Cliente - </strong>
                {' você realizara o upload do arquivo de backup, veja como:'}
                <section>
                  <span>1.</span>
                  Localize o arquivo de backup que você baixou em seu computador - O arquivo precisa ser
                  <>&nbsp;</>
                  <strong>extensão “tar.gz”</strong>
                  ;
                </section>
                <section>
                  <span>2.</span>
                  Na tela “Restaurar backup” clique em
                  <>&nbsp;</>
                  <strong>Faça upload</strong>
                  ;
                </section>
                <section>
                  <span>3.</span>
                  Em seguida, clique em
                  <>&nbsp;</>
                  <strong>Restaurar via cPanel</strong>
                  ;
                </section>
                <section>
                  <span>4.</span>
                  Para finalizar o procedimento, aparecerá uma mensagem de conclusão do upload, clique em
                  <>&nbsp;</>
                  <strong>Ok, entendi</strong>
                  .
                </section>
              </>
            ),
            bySupport: (
              <>
                <strong>Via Suporte</strong>
                {' - é necessário abrir um chamado - '}
                <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/360039146374#AtravesdoSuporte" text="Veja como fazer" />
              </>
            ),
            footer: 'Lembre-se que todos os arquivos atuais de seu site serão substituídos pelos arquivos presentes no backup que será restaurado.',
          },
        },
        whyDoBackupAccordion: {
          title: 'Porque é importante manter meu backup atualizado?',
          content: {
            paragraphOne: 'Fazer o backup da conta, é um ato de prevenção contra a perda de dados. Essa ação permite obter uma cópia de segurança de sua conta, caso ocorra algum problema com ele.',
            paragraphTwo: 'A HostGator não se responsabiliza pelos arquivos e/ou dados armazenados na conta. É muito importante que você mantenha seus próprios backups em um lugar seguro.',
            paragraphThree: 'É recomendado fazer e atualizar os backups regularmente, garantindo que a versão mais atualizada esteja sempre disponível para restauração. A frequência nas atualizações e edições dentro de seu site geralmente determinará com que recorrência você deve realizar backups.',
          },
        },
      },
    },
    log: {
      title: 'Últimos backups',
      description: 'Aqui você pode ver o histórico dos seus últimos backups.',
      tags: {
        success: 'REALIZADO',
        error: 'ERRO',
        ongoing: 'EM ANDAMENTO',
        warning: 'ATENÇÃO',
      },
      info: {
        success: date => (
          <>
            {'Seu último backup '}
            <strong>
              {`foi feito com sucesso em ${date}`}
            </strong>
            {'. Isso significa que seu backup está atualizado! :) '}
          </>
        ),
        warning: (days, goToCPanel) => (
          <>
            {`Seu último backup foi realizado há mais de ${days} dias. `}
            <Button type="button" variant="text" onClick={goToCPanel} label="Atualize seu backup agora" />
            <br />
            <strong>para não perder os seus dados.</strong>
          </>
        ),
        empty: goToCPanel => (
          <>
            Você não tem backup desse plano.
            <br />
            <Button variant="text" onClick={goToCPanel} label="Faça um backup agora " />
            <strong>para não perder os seus dados.</strong>
          </>
        ),
        error: handleClick => (
          <>
            {'Tivemos um problema ao realizar seu backup. Espere alguns instantes e '}
            <Button type="button" variant="text" onClick={() => handleClick()} label="clique aqui para tentar novamente" />
            {'. '}
          </>
        ),
        ongoing: () => (
          <>
            Seu backup está em andamento.
            <br />
            Aguarde alguns instantes para ele estar completo.
          </>
        ),
      },
      loadMoreLabel: 'Ver mais',
    },
  },
  modal: {
    title: 'Fazer backup',
    description: () => (
      <>
        Faça um backup completo do seu site para guardar seus dados e restaurar facilmente em caso de problemas. Escolha a melhor forma de backup para você:
        <br />
        <strong>Backup pelo Portal do Cliente:</strong>
        Faça um backup completo do seu plano, armazenando todos os dados, arquivos e e-mails no Diretório Inicial. Você pode guardar até 10GB nesse backup.
        <br />
        <strong>Backup avançado pelo cPanel:</strong>
        Um backup mais avançado com opções adicionais de download. Personalize e tenha maior controle sobre seus backups.
      </>
    ),
    alertDescription: () => (
      <>
        {'Não sabe qual escolher? Saiba mais sobre '}
        <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/18012735627035" text="as diferenças entre os dois tipos de backup aqui." />
      </>
    ),
    buttonLabelCpanel: 'Backup avançado cPanel',
    buttonLabelPortal: 'Backup Portal do Cliente',
  },
  wizard: {
    title: 'Primeiros passos em Backup',
    description: 'Vamos ajudar a gerar ou restaurar um backup para o seu plano com o nosso passo a passo.',
    fristStepTitle: (
      <strong>Comece selecionando o que deseja fazer agora:</strong>
    ),
    generateLocale: {
      optionTitle: 'Gerar um backup',
      optionDescription: (
        <>
          <strong>Faça uma cópia de segurança</strong>
          {' do seu site para baixar e manter em um local seguro.'}
        </>
      ),
      secondStep: {
        description: (
          <strong>E qual backup você quer gerar?</strong>
        ),
        generateSuspendedTitle: (
          <>
            {'Ok! '}
            <strong>Vamos ajudá-lo.</strong>
            {' :)'}
          </>
        ),
      },
      thirdStep: {
        options: {
          active: {
            full: {
              title: 'Backup completo',
              description: (
                <>
                  Baixe uma cópia completa da sua hospedagem incluindo arquivos, bancos de dados, configurações do cPanel:

                  <ul>
                    <li>Tamanho máximo 10Gb ou 10mil arquivos (inodes).</li>
                    <li>Ideal para migrações de site, desenvolvimento local ou recuperação em caso de infecção por vírus/malwares.</li>
                    <li>Inclui os arquivos de todos os sites instalados na hospedagem.</li>
                    <li>Evita inconsistências entre o banco de dados e os arquivos do seu site.</li>
                    <li>Conta com o suporte HostGator para restaurar o seu backup sempre que precisar.</li>
                  </ul>
                </>
              ),
            },
            root: {
              title: 'Backup do diretório inicial',
              description: (
                <>
                  Baixe a cópia do diretório de arquivos da sua hospedagem:

                  <ul>
                    <li>Tamanho máximo 10Gb ou 10mil arquivos (inodes).</li>
                    <li>Ideal para recuperação em casos de exclusão acidental de arquivos ou infecção por vírus/malwares.</li>
                    <li>Inclui os arquivos de todos os sites instalados na hospedagem.</li>
                    <li>Restauração fácil pelo cPanel quando precisar.</li>
                  </ul>
                </>
              ),
            },
            database: {
              title: 'Backup do banco de dados',
              description: (
                <>
                  Baixe uma cópia segura do banco de dados MySQL do seu site (avançado):

                  <ul>
                    <li>Ideal para realizar análises de dados avançadas ou recuperação de dados corrompidos.</li>
                    <li>Restauração fácil pelo cPanel quando precisar.</li>
                  </ul>
                </>
              ),
            },
          },
        },
      },
      generateTexts: {
        description: (
          <>
            {'Ok! '}
            <strong>Vamos ajudar a gerar seu Backup, aguarde uns segundos</strong>
            {'. :) '}
          </>
        ),
        info: 'Clique no botão abaixo para ler mais sobre o backup e fazer o download do arquivo.',
      },
      generateByCpanel: {
        description: (
          <>
            {'Você pode realizar o backup completo do seu plano de forma rápida '}
            <strong>através do cPanel.</strong>

          </>
        ),
        info: (
          <>
            {'Clique no botão abaixo para ir até lá. Em caso de dúvida, '}
            <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173-Como-fazer-um-backup-completo" text="veja como fazer." />
          </>
        ),
      },
      buttonLable: 'Fazer backup',
      cpanelButtonLable: 'Fazer backup via cPanel',
    },
    restorateLocale: {
      optionTitle: 'Restaurar um backup',
      optionDescription: (
        <>
          <strong>Restaure uma cópia de segurança</strong>
          {' da sua hospedagem caso esteja enfrentando problemas.'}
        </>
      ),
      mine: {
        title: 'De um arquivo que eu já tenho',
        description: 'texto de apoio.',
      },
      hostgator: {
        title: 'De um arquivo que a Hostgator tenha',
        description: 'texto de apoio.',
      },
      restoreLabel: 'Restaurar backup',
      description: (
        <>
          {'Como você não possui um arquivo de backup disponível, para prosseguir com a restauração, é necessário '}
          <strong>abrir um chamado  para a nossa equipe de Suporte</strong>
          . Clique no botão abaixo e preencha o formulário.
        </>
      ),
      fullBkpDescription: (
        <>
          {'Para realizar a restauração do backup completo, '}
          <strong>é necessário abrir um chamado para nossa equipe de Suporte.</strong>
          {' Clique no botão abaixo para acessar o formulário e iniciar o processo.'}
        </>
      ),
      noFullBkpDescription: (
        <>
          Clique no botão abaixo para ler mais informações e
          <strong>{' ver como fazer a restauração '}</strong>
          pelo cPanel.
        </>
      ),
      firstStep: {
        title: (
          <>
            {'Você tem o '}
            <strong>arquivo para restaurar?</strong>
          </>
        ),
      },
      secondStep: {
        title: (
          <>
            Ok! Vamos ajudar a restaurar seu Backup. :)
            <br />
            <br />
            <strong>
              Qual tipo de backup você quer restaurar
            </strong>
            ?
          </>
        ),
        options: {
          positive: (
            <>
              <strong>Sim</strong>
              , tenho o arquivo!
            </>
          ),
          negative: (
            <>
              <strong>Não</strong>
              , quero usar um arquivo da HostGator.
            </>
          ),
        },
      },
      thirdStep: {
        options: {
          active: {
            userFile: {
              full: {
                title: 'Backup completo',
                description: (
                  <>
                    Selecione esta opção se você possui um arquivo de backup completo do cPanel.
                    <ul>
                      <li>Arquivo no formato .tar.gz</li>
                      <li>Tamanho máximo 500MB.</li>
                      <li>Nenhuma alteração realizada após a data de geração do arquivo de backup será preservada no cPanel após a restauração completa.</li>
                    </ul>
                  </>
                ),
              },
              root: {
                title: 'Backup do diretório inicial',
                description: (
                  <>
                    Selecione esta opção se você possui um arquivo de backup do diretório inicial do cPanel.

                    <ul>
                      <li>Arquivo no formato .tar.gz</li>
                      <li>Tamanho máximo 500MB.</li>
                      <li>Nenhuma alteração realizada nos arquivos do cPanel após a data de geração do arquivo de backup será preservada após a restauração.</li>
                    </ul>
                  </>
                ),
              },
              database: {
                title: 'Backup do banco de dados',
                description: (
                  <>
                    Selecione esta opção se você possui um arquivo de backup de banco de dados MySQL do cPanel.

                    <ul>
                      <li>Arquivo no formato .sql.gz</li>
                      <li>Tamanho máximo 50MB.</li>
                      <li>Nenhuma alteração realizada nos dados do seu site após a data de geração do arquivo de backup será preservada após a restauração.</li>
                    </ul>
                  </>
                ),
              },
            },
            routineFile: {
              full: {
                title: 'Backup completo',
                description: (
                  <>
                    Realize a restauração completa do seu backup a partir de um arquivo fornecido pela HostGator.
                    <ul>
                      <li>Ideal para migrações de site, desenvolvimento local ou recuperação pós infecção por vírus/malwares.</li>
                      <li>Inclui todos os arquivos dos sites instalados na hospedagem.</li>
                      <li>Conte com o suporte HostGator para executar o processo de restauração.</li>
                    </ul>
                  </>
                ),
              },
              root: {
                title: 'Backup do diretório inicial',
                description: (
                  <>
                    Restauração de diretório de arquivos pela HostGator:
                    <ul>
                      <li>Recupere seu diretório de arquivos hospedado com facilidade.</li>
                      <li>Ideal para casos de exclusão acidental ou infecção por vírus/malwares. Inclui todos os arquivos dos sites instalados na hospedagem.</li>
                      <li>Conte com o suporte HostGator para executar o processo de restauração.</li>
                    </ul>
                  </>
                ),
              },
              database: {
                title: 'Backup do banco de dados',
                description: (
                  <>
                    Realize a restauração do banco de dados MySQL do seu site pela HostGator (avançado):
                    <ul>
                      <li>Ideal para análises avançadas de dados ou recuperação de dados corrompidos.</li>
                      <li>Conte com o suporte HostGator para executar o processo de restauração.</li>
                    </ul>
                  </>
                ),
              },
            },
          },
          suspended: {
            full: {
              title: 'Backup completo',
              description: 'Restaure todos os arquivos do backup completo.',
            },
            root: {
              title: 'Backup do diretório inicial',
              description: 'Você pode escolher um arquivo específico para restaurar.',
            },
            database: {
              title: 'Backup do banco de dados',
              description: 'Recupere seu backup do banco de dados.',
            },
          },
        },
      },
      inodeMessage: (
        <>
          Verificamos que o seu backup excede o limite de tamanho ou de arquivos permitido.
          <br />
          {'Nesse caso, '}
          <strong>é necessário abrir um chamado.</strong>
          {' Clique no botão abaixo para acessar o formulário.'}
        </>
      ),
      exceededInodeButton: 'Solicitar Backup',
      buttonLable: 'Solicitar restauração',
      continue: 'Continuar',
    },
    cancelledRestoreAlert: (
      <>
        <strong>Seu plano foi encerrado e não pode ser recuperado.</strong>
        <br />
        Se tiver um backup, contrate uma nova hospedagem e restaure os arquivos no novo plano.
      </>
    ),
    cancelledGenerateAlert: (
      <>
        {'Como o seu plano foi cancelado, '}
        <strong>não é possível gerar um backup.</strong>
        <br />
        Por favor, adquira um novo plano para ter acesso a esse serviço.
      </>
    ),
    suspendedRestoreAlert: (
      <>
        <strong>Como seu plano está suspenso, você precisa solicitar a restauração para a nossa equipe de Suporte.</strong>
        <br />
        Clique no botão abaixo para prosseguir com a solicitação e obter informações sobre como regularizar seu plano.
      </>
    ),
    suspendedGenerateAlert: (
      <>
        <strong>Como seu plano está suspenso, você precisa solicitar o backup para a nossa equipe de Suporte.</strong>
        <br />
        Clique no botão abaixo para prosseguir com a solicitação e obter informações sobre como regularizar seu plano.
      </>
    ),
    hireNewLabel: 'Contratar nova hospedagem',
    askRestorationLabel: 'Solicitar restauração',
    askBackupLabel: 'Solicitar backup',
    continue: 'Continuar',
    breadCrumb: (
      <>
        {'Visão geral / '}
        <strong>Primeiros Passos</strong>
      </>
    ),
  },
};
