import React from 'react';
import * as Styles from './CreateSiteBanner.styles';
import { Button } from '@/pages/common/v1/Button';
import {
  IconDetailedWordpress,
  IconDetailedOtherSquare,
  IconDetailedSiteBuilder,
} from '@/icons';
import { useResize } from '@/hooks/useResize';
import modalDecoLine from '@/media/createSiteBanner/decoline.png';

const CreateSiteBanner = ({
  title, subtitle, testId, buttonLabel, tools, buttonAction,
}) => {
  const screenSize = useResize();
  const isMobile = screenSize.width <= 600;
  const iconsSize = isMobile ? 20 : 26;
  const toolsList = [
    {
      title: tools.wordpress,
      name: 'wordpress',
      icon: <IconDetailedWordpress size={iconsSize} />,
      id: 0,
    },
    {
      title: tools.sitebuilder,
      name: 'sitebuilder',
      icon: <IconDetailedSiteBuilder size={iconsSize} />,
      id: 1,
    },
    {
      title: tools.other,
      name: 'others',
      icon: <IconDetailedOtherSquare width={iconsSize} height={iconsSize} />,
      id: 2,
    },
  ];

  const handleClick = () => {
    buttonAction && buttonAction();
  };

  return (
    <Styles.Container>
      <Styles.BannerContent>
        <Styles.Title data-testid={`${testId}-title`}>
          {title}
        </Styles.Title>

        <Styles.Subtitle data-testid={`${testId}-subtitle`}>
          {subtitle}
        </Styles.Subtitle>

        <Styles.ButtonWrapper>
          <Button
            size={isMobile ? 'small' : 'regular'}
            label={buttonLabel}
            onClick={() => handleClick()}
            testId={`${testId}-button`}
          />
        </Styles.ButtonWrapper>
      </Styles.BannerContent>

      <Styles.Tools>
        <Styles.ToolsList>
          {toolsList.map(tool => (
            <Styles.Tool key={tool.id}>
              {tool.icon}
              <Styles.ToolTitle data-testid={`tool-${tool.name}-title`}>
                {tool.title}
              </Styles.ToolTitle>
            </Styles.Tool>
          ))}
        </Styles.ToolsList>
      </Styles.Tools>
      <Styles.DecoTriangle />

      <Styles.DecoLine src={modalDecoLine} />
    </Styles.Container>
  );
};

export default CreateSiteBanner;
