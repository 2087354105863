import { SET_CRUMBS, SET_DYNAMIC_BREADCRUMBS_TITLE } from '@/redux/actions/actionsTypes';


const initialState = {
  crumbs: [],
  dynamicBreadCrumbTitles: [],
};

export default function breadCrumbs(state = initialState, action) {
  switch (action.type) {
    case SET_CRUMBS:
      return {
        ...state,
        crumbs: action.crumbs,
      };
    case SET_DYNAMIC_BREADCRUMBS_TITLE:
      return {
        ...state,
        dynamicBreadCrumbTitles: [
          ...state.dynamicBreadCrumbTitles,
          ...action.data,
        ],
      };
    default:
      return state;
  }
}
