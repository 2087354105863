import React from 'react';
import * as Styles from './Tag.styles';

const Tag = ({
  label = 'text',
  testId = 'tag',
  variant = 'default',
  color = '#ffffff',
  bgColor = '#a4a4a4',
}) => (
  <Styles.Wrapper
    variant={variant}
    data-testid={testId}
    color={color}
    bgColor={bgColor}
  >
    {label}
  </Styles.Wrapper>
);

export default Tag;
