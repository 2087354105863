import { CALL_API } from '@/middleware/api';
import { FAQ_URL } from '@/config/api';
import { config } from '@/config';
import {
  REQUEST_FAQ_HELP, RECEIVE_FAQ_HELP, ERROR_FAQ_HELP, ABORT_FAQ_HELP,
  RECEIVE_FAQ_MYSITES, REQUEST_FAQ_MYSITES, ERROR_FAQ_MYSITES, ABORT_FAQ_MYSITES,
  RECEIVE_FAQ_DOMAIN, REQUEST_FAQ_DOMAIN, ERROR_FAQ_DOMAIN, ABORT_FAQ_DOMAIN,
  RECEIVE_FAQ_INVOICES, REQUEST_FAQ_INVOICES, ERROR_FAQ_INVOICES, ABORT_FAQ_INVOICES,
  REQUEST_FAQ_DNS, ERROR_FAQ_DNS, RECEIVE_FAQ_DNS, ABORT_FAQ_DNS,
  RECEIVE_FAQ_DOMAIN_LIST, REQUEST_FAQ_DOMAIN_LIST, ERROR_FAQ_DOMAIN_LIST, ABORT_FAQ_DOMAIN_LIST,
  RECEIVE_FAQ_EMAILS, REQUEST_FAQ_EMAILS, ERROR_FAQ_EMAILS, ABORT_FAQ_EMAILS,
  RECEIVE_FAQ_PRODUCTS, REQUEST_FAQ_PRODUCTS, ERROR_FAQ_PRODUCTS, REQUEST_FAQ_PRODUCT_DETAIL,
  RECEIVE_FAQ_PRODUCT_DETAIL, ERROR_FAQ_PRODUCT_DETAIL, ABORT_FAQ_PRODUCT_DETAIL, ABORT_FAQ_PRODUCTS,
  RECEIVE_FAQ_PARAM, REQUEST_FAQ_PARAM, ERROR_FAQ_PARAM, ABORT_FAQ_SECURITY, REQUEST_FAQ_SECURITY, ERROR_FAQ_SECURITY, RECEIVE_FAQ_SECURITY,
} from './actionsTypes';


const receiveFaqHelp = faqHelp => ({
  type: RECEIVE_FAQ_HELP,
  faqHelp,
  loadedDate: Date.now(),
});

export const loadFaqHelp = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.help;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_HELP }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/help`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_HELP }),
        success: ({ data }) => receiveFaqHelp(data || []),
        error: () => ({ type: ERROR_FAQ_HELP }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqProducts = faqProducts => ({
  type: RECEIVE_FAQ_PRODUCTS,
  faqProducts,
  loadedDate: Date.now(),
});

const receiveFaqSecurity = faqSecurity => ({
  type: RECEIVE_FAQ_SECURITY,
  faqSecurity,
  loadedDate: Date.now(),
});

export const loadFaqProducts = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.products;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_PRODUCTS }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/products-page`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_PRODUCTS }),
        success: ({ data }) => receiveFaqProducts(data || []),
        error: () => ({ type: ERROR_FAQ_PRODUCTS }),
      },
      authenticated: true,
    },
  });
};

export const loadFaqSecurity = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.security;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_SECURITY }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/security`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_SECURITY }),
        success: ({ data }) => receiveFaqSecurity(data || []),
        error: () => ({ type: ERROR_FAQ_SECURITY }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqDomainList = faqDomainList => ({
  type: RECEIVE_FAQ_DOMAIN_LIST,
  faqDomainList,
  loadedDate: Date.now(),
});

export const loadFaqDomainList = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.domainList;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_DOMAIN_LIST }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/domain-list`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_DOMAIN_LIST }),
        success: ({ data }) => receiveFaqDomainList(data || []),
        error: () => ({ type: ERROR_FAQ_DOMAIN_LIST }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqEmails = faqEmails => ({
  type: RECEIVE_FAQ_EMAILS,
  faqEmails,
  loadedDate: Date.now(),
});

export const loadFaqEmails = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.emails;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_EMAILS }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/email-tab`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_EMAILS }),
        success: ({ data }) => receiveFaqEmails(data || []),
        error: () => ({ type: ERROR_FAQ_EMAILS }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqMySites = faqMySites => ({
  type: RECEIVE_FAQ_MYSITES,
  faqMySites,
  loadedDate: Date.now(),
});

export const loadFaqMySites = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.mySites;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_MYSITES }));
    });
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/mysites`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_MYSITES }),
        success: ({ data }) => receiveFaqMySites(data || []),
        error: () => ({ type: ERROR_FAQ_MYSITES }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqInvoices = faqInvoices => ({
  type: RECEIVE_FAQ_INVOICES,
  faqInvoices,
  loadedDate: Date.now(),
});

export const loadFaqInvoices = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.invoices;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_INVOICES }));
    });
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/invoice`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_INVOICES }),
        success: ({ data }) => receiveFaqInvoices(data || []),
        error: () => ({ type: ERROR_FAQ_INVOICES }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqDomain = faqDomainDetail => ({
  type: RECEIVE_FAQ_DOMAIN,
  faqDomainDetail,
  loadedDate: Date.now(),
});

export const loadFaqDomain = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.domainDetail;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_DOMAIN }));
    });
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/domain`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_DOMAIN }),
        success: ({ data }) => receiveFaqDomain(data || []),
        error: () => ({ type: ERROR_FAQ_DOMAIN }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqDns = faqDns => ({
  type: RECEIVE_FAQ_DNS,
  faqDns,
  loadedDate: Date.now(),
});

export const loadFaqDns = () => (dispatch, getState) => {
  const { loadedDate } = getState().faq.dns;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_DNS }));
    });
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/dns`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_DNS }),
        success: ({ data }) => receiveFaqDns(data || []),
        error: () => ({ type: ERROR_FAQ_DNS }),
      },
      authenticated: true,
    },
  });
};

const reciveFaqProductDetail = faqProductDetail => ({
  type: RECEIVE_FAQ_PRODUCT_DETAIL,
  faqProductDetail,
  loadedDate: Date.now(),
});

export const loadFaqProductDetail = id => (dispatch, getState) => {
  const { loadedDate } = getState().faq.productDetail;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_PRODUCT_DETAIL }));
    });
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/product-detail/${id}`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_PRODUCT_DETAIL }),
        success: ({ data }) => reciveFaqProductDetail(data || []),
        error: () => ({ type: ERROR_FAQ_PRODUCT_DETAIL }),
      },
      authenticated: true,
    },
  });
};

const receiveFaqParam = faqParam => ({
  type: RECEIVE_FAQ_PARAM,
  faqParam,
  loadedDate: Date.now(),
});


export const loadFaqParam = param => (dispatch, getState) => {
  const { loadedDate } = getState().faq.emails;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_FAQ_EMAILS }));
    });
  }
  return dispatch({
    [CALL_API]: {
      endpoint: `${FAQ_URL}/${param}`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FAQ_PARAM }),
        success: ({ data }) => receiveFaqParam(data || []),
        error: () => ({ type: ERROR_FAQ_PARAM }),
      },
      authenticated: true,
    },
  });
};
