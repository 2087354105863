import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    @media(max-width: ${theme.v1.breakpoints.md}){
      align-items: center;
    }
  `}
`;

export const TitleContainer = styled.div`
${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 8px;
    margin: 32px 0px 8px 0px;
    @media(max-width: ${theme.v1.breakpoints.md}){
      flex-direction: column;
      margin: 16px 0px 8px 0px;
    }
  `}
`;

export const CongratsIcon = styled.img`
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 0px;
    max-width: 440px;
    text-align: left;
    width: 100%;
    @media(max-width: ${theme.v1.breakpoints.md}){
      text-align: center;
      width: 256px;
    }
  `}
`;

export const Description = styled.p`
${({ theme, success }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin: ${success ? '0px 0px 24px 0px' : '0px 0px 16px 0px'};
    max-width: 448px;
    text-align: left;
    width: 100%;
    span {
      font-weight: bold;
    }
    @media(max-width: ${theme.v1.breakpoints.md}){
      margin: ${success ? '0px 0px 16px 0px' : '0px 0px 26px 0px'};
      width: 256px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
${({ theme, success, isInsideWizard }) => css`
    align-items: center;
    display: flex;
    gap: 32px;
    margin: ${isInsideWizard ? '0px auto 39px auto' : '0px 0px 39px 0px'};

    @media(max-width: ${theme.v1.breakpoints.lg}){
      align-items: initial;
      flex-direction: column;
      gap: 14px;
      {width: ${success ? '288px' : '200px'};
      span {
        font-size: ${success ? '16px' : '14px'}
      }
    }
    @media(max-width: ${theme.v1.breakpoints.md}){
      align-items: center;
      margin-bottom: 0px;
      width: 250px;
      span {
        font-size: 14px;
      }
    }
  `}
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    margin: 0px 0px 0px 12px;
      
    & svg {
      fill: ${theme.v1.color.action.primary};
      margin-right: 10px;
      stroke: ${theme.v1.color.action.primary};
      transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
      transform: rotate(180deg);
    }
    @media(max-width: ${theme.v1.breakpoints.lg}){
      margin: 0px;
    }
  `}
`;
