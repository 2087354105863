import React, { useState } from 'react';
import * as Styles from './DnsWizardZoneTable.styles';
import { Button } from '@/pages/common/v1/Button';
import { DnsWizardLegacyDnsTable } from '../DnsWizardLegacyDnsTable';
import { IconArrowDownOutlined } from '@/icons';
import { domainsAnalytics } from '@/analytics';
import { v1 } from '@/styles/HgThemeProvider';

const DnsWizardZoneTable = ({
  testId = 'dnswizardzonetable',
  openText = 'Open Text',
  closeText = 'close Text',
  openDnsTable,
  setOpenDnsTable,
  setShowAdvanceDNSZoneModal,
}) => {
  const [hoverColor, setHoverColor] = useState(v1.color.action.primary);

  const [alreadyOpened, setAlreadyOpened] = useState(false);

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;

  const {
    dnsWizardShowDnsStatsClick,
  } = dnsWizardAnalytics;

  const handleOpenDnsZoneTable = () => {
    if (!openDnsTable) {
      dnsWizardAnalytics.dnsWizardShowDnsZoneSettings();
      if (!alreadyOpened) {
        dnsWizardShowDnsStatsClick();
        setAlreadyOpened(true);
      }
    }

    const dontShowAlert = localStorage.getItem('dnsAdvancedZoneDontShowModal');
    if (dontShowAlert || openDnsTable) {
      setOpenDnsTable(!openDnsTable);
    } else {
      setShowAdvanceDNSZoneModal(true);
    }
  };

  const label = openDnsTable ? (
    <Styles.TextWrapper data-testid="dns-table-close-button">
      {closeText}
      <Styles.IconWrapper orientation="down">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  ) : (
    <Styles.TextWrapper data-testid="dns-table-open-button">
      {openText}
      <Styles.IconWrapper orientation="up">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  );

  return (
    <Styles.DnsWizardZoneTableWrapper data-testid={testId}>
      <Styles.ButtonWrapper
        onMouseEnter={() => setHoverColor(v1.color.white.primary)}
        onMouseLeave={() => setHoverColor(v1.color.action.primary)}
      >
        <Button
          label={label}
          variant="secondary"
          onClick={handleOpenDnsZoneTable}
          customStyles={{
            borderRadius: '32px',
          }}
        />
      </Styles.ButtonWrapper>
      {openDnsTable && <DnsWizardLegacyDnsTable testId="dns-table" />}
    </Styles.DnsWizardZoneTableWrapper>
  );
};

export default DnsWizardZoneTable;
