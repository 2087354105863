import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    &:not(:first-child) {
      margin-top: 24px;
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;

export const Label = styled.span`
  ${({ theme, enabledStrongText }) => css`
    align-items: center;
    color: ${!enabledStrongText ? theme.v2.colors.neutral.lowPure : theme.v2.colors.neutral.lowLight};
    display: inline-flex;
    font-size: 12px;
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 16px;
  `}
`;

export const TooltipWrapper = styled.span`
  margin-left: 8px;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    display: flex;
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;

    > div {
      position: relative;
    }

    > strong {
      margin: 0px 10px 0px 5px;
      color: ${theme.v2.colors.neutral.lowPure};
    }

    > p {
      margin: 0px 8px 0px 5px;
      color: ${theme.v2.colors.neutral.lowPure};
    }

  `}
`;

export const LineThrough = styled.span`
    text-decoration: line-through;
`;

export const TagWrapper = styled.span`
${({ theme }) => css`

> div {
  background-color: ${theme.v2.colors.feedback.activePure};
  border-radius: 4px;
  color: ${theme.v2.colors.white.primary};
  font-weight: ${theme.v2.font.weights.bold};
  padding: 4px 8px;
  position: unset

}
`}
`;
