import styled, { css } from 'styled-components';
import bannerDesktop from './desktop.png';
import bannerMobile from './mobile.png';

export const Content = styled.div`
  ${({ theme, bannerImage }) => css`
    background-image: url(${bannerImage || bannerDesktop});
    background-size: cover;
    color: ${theme.v2.colors.neutral.lowPure};
    height: 444px;
    width: 800px;
    cursor: pointer;

    #tag {
      margin-left: 47px;
      margin-top: 23px;

      #dot {
        top: 35px;
        left: 67px;
      }
    }

    #close-button {
      position: relative;

      button {
        position: absolute;
        top: 30px;
        left: 962px;
      }
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      background-image: url(${bannerImage || bannerMobile});
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: auto;

      #tag {
        #dot {
          top: 29px;
          left: 61px;
        }
      }
    }
  `}
`;

export const NotShowAgain = styled.div`
 ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 24px;
    display: flex;
    position: absolute;
    right: 45px;
    top: 15px;
  `}
`;
