import styled, { css } from 'styled-components';

export const Container = styled.section`
${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.marketingBanner};
    display: flex;
    justify-content: space-between;
    gap: 72px;
    padding: 42px 40px;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      gap: 20px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column;
      gap: 26px;
      padding: 32px 0px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 30px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 40px;
    margin: 0px 0px 8px 0px;
    max-width: 443px;
    text-align: left;
    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 20px;
      max-width: 324px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      line-height: 27px;
      margin: 0px 0px 16px 0px;
      text-align: center;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display: flex;
      justify-content: center;
      margin-bottom: 0px;
      order: 3;
      width: 100%;
    }
    > button {
      width: 170px;
    }
  `}
`;
export const Description = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0px 0px 40px 0px;
    max-width: 552px;
    text-align: left;
    span {
      font-weight: bold;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 14px;
      max-width: 307px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      line-height: 19px;
      text-align: center;
    }
  `}
`;

export const RaImage = styled.img`
  ${({ theme }) => css`
    height: 48px;
    width: 94px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;


export const BannerSnappy = styled.img`
${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.md}) {
      width: 300px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 220px;
    }
  `}
`;
