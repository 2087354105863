export const steps = {
  LINK_CHOOSE: 1,
  CONTENT: 2,
  TEMPLATE: 3,
  SHARE: 4,
};

export const linkNaBioEnum = {
  steps,
};

export const LINK_NOT_AVAILABLE = 'LINK_NOT_AVAILABLE';
export const LINK_AVAILABLE = 'Username available';


export const colorSchemes = {
  HOSTGATOR_DARK_BLUE: 'HOSTGATOR_DARK_BLUE',
  NEON: 'NEON',
  PASTEL: 'PASTEL',
  WARM: 'WARM',
  HOSTGATOR_BLUE: 'HOSTGATOR_BLUE',
  SIMPLE: 'SIMPLE',
  GRADIENT_ROSE: 'GRADIENT_ROSE',
  GRADIENT_WARM: 'GRADIENT_WARM',
  GRADIENT_DARK: 'GRADIENT_DARK',
};

export const templates = {
  MODERN: 'MODERN',
  FRIENDLY: 'FRIENDLY',
  MINIMALIST: 'MINIMALIST',
  FUNNY: 'FUNNY',
};

export const wizardStep2Actions = {
  NONE: 'NONE',
  ADD_LINK: 'ADD_LINK',
  EDIT_LINK: 'EDIT_LINK',
  DELETE_LINK: 'DELETE_LINK',
  ADD_SOCIAL_MEDIA_STEP1: 'ADD_SOCIAL_MEDIA_STEP1',
  ADD_SOCIAL_MEDIA_STEP2: 'ADD_SOCIAL_MEDIA_STEP2',
  DELETE_SOCIAL_MEDIA: 'DELETE_SOCIAL_MEDIA',
  EDIT_SOCIAL_MEDIA: 'EDIT_SOCIAL_MEDIA',
};

export const linkType = {
  LINK: 'LINK',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
};
