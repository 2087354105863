import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, step }) => css`
    color: ${theme.color.tundora};
    font-family: ${theme.font.family.secondary};
    height: ${step === 1 && '300px'};
    margin: 15px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 7px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    margin: 0;
    margin-bottom: 24px;
    text-align: left;
  `}
`;

export const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      margin-top: 42px;
    }
  `}
`;

export const TextDescription = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-left: 17px;
    span {
      color: ${theme.color.cyanCobaltBlue};
      font-weight: ${theme.font.weights.bold};
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0px;
      margin-top: 38px;
    }
  `}
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: ${theme.font.sizes.sm};
    justify-content: space-evenly;
    margin-top: 18px;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      margin-top: -5px;
    }
  `}
`;

export const TextSelect = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.blue.dark};
    font-size: ${theme.font.sizes.md};
    text-align: left;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: -65px;
    }
  `}
`;

export const Select = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: nowrap;
    max-height: 50px;s
    margin-left: 2px;
    width: 250px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
      width: 100%;
      margin-top: 2px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 32px;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
      margin-top: 40px;
    }
  `}
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-left: -42px;
        margin-top: 22px;
    }
  `}
`;

export const InstructionSquare = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.boxes};
    border: 1px solid ${theme.v1.color.line.fields};
    width: 100%;
    padding: 13px 26px 22px 26px;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-bottom: 31px;

    a{
      color: ${theme.v1.color.action.primary};
    }
  `}
`;

export const SquareContentWrapper = styled.div`
    display: flex;
    margin-top: -23px;
    margin-bottom: 18px;
    align-items: center;

    @media (max-width: 650px) {
        flex-direction: column;
    }
`;

export const SquareDnsColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 230px;
`;

export const IconWrapper = styled.div`
  padding: 35px;
  margin-top: 44px;

  @media (max-width: 650px) {
    transform: rotate(90deg);
    padding: 5px;
    margin-top: 0;
  }
`;

export const DnsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.font.sizes.sm};
    color: ${theme.v1.color.text.primary};
    height: 34px;

    p{
      font-weight: ${theme.v1.font.weights.medium};
      color: ${theme.v1.color.text.featured};
      margin: 0;
      padding: 0;
    }

    button {
      margin-left: 13px;
    }
  `}
`;

export const TextBoldSquare = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-weight: ${theme.v1.font.weights.medium};
  `}
`;

export const SquareButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DnsWarning = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 28px;

    svg{
      margin-right: -1px;
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      div {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  `}
`;

export const TextWarningDns = styled.div`
 ${({ theme }) => css`
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 20px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      div {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  `}
`;

export const UnderlinedText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    text-decoration: underline;
    margin: 0;

  `}
`;

export const ButtonsBottonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column-reverse;
    }
  `}
`;
