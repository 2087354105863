import React from 'react';
import { IconDetailedAlert } from '@/icons/IconDetailedAlert';
import { IconDetailedBrush } from '@/icons/IconDetailedBrush';
import { IconDetailedProtection } from '@/icons/IconDetailedProtection';

export default {
  pageTitle: {
    0: 'Proteja seu site ',
    1: 'de ameaças cibernéticas com SiteLock',
  },
  description: 'A solução ideal para encontrar e corrigir ameaças ao seu site automaticamente.',
  listTitle: {
    0: 'Contrate o ',
    1: 'SiteLock Professional ',
    2: 'e conte com:',
  },
  featureList: {
    1: 'Proteção de até 100 páginas',
    2: 'Selo de segurança do site',
    3: 'Varreduras frequentes',
    4: 'Remoção automática de malwares',
    5: 'Verificação diária de FTP',
    6: 'Verificação de edição de arquivos',
    7: 'Monitoramento de blocklist',
    8: 'E muito mais',
  },
  formTitle: 'Plano profissional',
  formDescription: 'Escolha seu domínio:',
  formFirstToggleLabel: 'Selecionar um domínio contratado com a HostGator',
  formSecondToggleLabel: 'Digitar outro domínio',
  formSecondTogglePlaceholder: 'Digitar seu domínio',
  cycleLabel: 'Selecione o ciclo:',
  selectePaymentLabel: 'Selecione a forma de pagamento:',
  cycleOptions: {
    annually: 'Anual',
    semiannually: 'Semestral',
    quarterly: 'Trimestral',
    monthly: 'Mensal',
  },
  cyclePer: {
    annually: '/ano',
    semiannually: '/semestre',
    quarterly: '/trimestre',
    monthly: '/mês',
  },
  toBePaid: 'Valor a pagar:',
  hgSiteLock: 'https://www.hostgator.com.br/sitelock',
  needMoreLabel: 'Precisa de um plano mais robusto? Confira opções no nosso site:',
  needMoreButtonText: 'Ver todos os planos SiteLock',
  detailsSectionTitle: () => (
    <>
      Mais de
      <span> 12 milhões de sites </span>
      contam com a proteção do SiteLock
    </>
  ),
  detailsSectionFirstSectionTitle: 'Proteja seu site, sua reputação e seus visitantes',
  detailsSectionFirstSectionDescription: () => (
    <>
      <p>Não jogue com segurança do site. Existem muitas ameaças na internet, e prevenir ataques é a melhor opção para evitar prejuízos.</p>

      <p>
        O SiteLock tem
        <b> um ótimo custo-benefício </b>
        , e protege seu site de ameaças cibernéticas maliciosas.
      </p>
    </>
  ),
  detailsSectionFirstSectionButtonLabel: 'Quero proteger meu site',
  detailsSectionSecondSectionTitle: 'Não importa o tamanho do seu site, segurança nunca é demais',
  detailsSectionSecondSectionDescription: () => (
    <>
      Com o SiteLock você não tem trabalho. Ele monitora e
      <b> remove ameaças automaticamente </b>
      e com extrema facilidade, evitando dores de cabeça.
    </>
  ),
  detailsSectionSecondSectionButtonLabel: 'Quero proteger meu site',
  discoveryTitle: 'Descubra como o SiteLock protege o seu site',
  benefits: () => [
    {
      icon: <IconDetailedAlert />,
      id: 0,
      title: 'Alertas e atualizações imediatas',
      description: 'Mantenha-se informado sobre a segurança do seu site com alertas por e-mail ou através do painel do SiteLock.',
    },
    {
      icon: <IconDetailedBrush />,
      id: 1,
      title: 'Remoção automática de malwares',
      description: 'Não se preocupe, você não precisa ser um astro da tecnologia para livrar seu site de malwares.',
    },
    {
      icon: <IconDetailedProtection />,
      id: 2,
      title: 'Proteção dos arquivos do seu site',
      description: 'Com SiteLock todos os arquivos do seu site estão em segurança e não correm risco de serem infectados.',
    },
  ],
  ctaTitle: 'Mostre que o seu site é 100% seguro',
  ctaDescription: 'Crie um ambiente seguro para os visitantes do seu site: com o Selo de Segurança SiteLock você dá mais tranquilidade para que os visitantes do seu site naveguem e comprem mais de você',
  buttonLabel: 'Quero mais proteção',
  bannerTitle: 'Proteja seu site contra ameaças cibernéticas',
  bannerLabel: 'Contrate agora',
};
