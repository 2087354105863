import React from 'react';
import * as Styles from './WizardWonderSuiteHeroBanner.styles';
import heroBannerImage from '@/media/siteBuilder/wondersuite/hero-banner.png';
import wordpress from '@/media/siteBuilder/wondersuite/wordpress.svg';
import polygon from '@/media/siteBuilder/wondersuite/polygon.png';
import polygonMobile from '@/media/siteBuilder/wondersuite/polygon-mobile.png';
import { Button, Tag } from 'gatorcomponents';
import { useResize } from '@/hooks/useResize';

const WizardWonderSuiteHeroBanner = ({
  title = 'title sample',
  description = 'description default',
  tagLabel = '',
  btnLabel,
  assignValue,
  wordpressExperience,
  onWondersuiteTrialStart,
  sitesLoading,
  testId = 'wizard-wondersuite-hero-banner',
}) => {
  const screenSize = useResize();
  const isMobile = screenSize.width < 700;
  const polygonSrc = isMobile ? polygonMobile : polygon;

  const onButtonClick = () => {
    onWondersuiteTrialStart && onWondersuiteTrialStart();
  };

  return (
    <>
      <Styles.Wrapper data-testid={testId}>
        <Styles.Container>
          <Styles.Polygon src={polygonSrc} />
          <Styles.HeroContent>
            <Styles.TagAdapter>
              <Tag label={tagLabel} />
            </Styles.TagAdapter>
            <Styles.Title>
              {title}
            </Styles.Title>
            <Styles.Description>
              {description}
            </Styles.Description>
            <Styles.AssignBox>
              <Styles.ButtonAdapter>
                <Button loading={sitesLoading} onClick={onButtonClick} label={btnLabel} size="large" />
              </Styles.ButtonAdapter>
              <Styles.Text>{assignValue}</Styles.Text>
            </Styles.AssignBox>
            <Styles.Wordpress>
              <Styles.WordpressImg src={wordpress} alt="wordpress" />
              <Styles.Strong>{wordpressExperience}</Styles.Strong>
            </Styles.Wordpress>
          </Styles.HeroContent>
          <Styles.ImageAdapter>
            <Styles.Image src={heroBannerImage} alt="hero-banner" />
          </Styles.ImageAdapter>
        </Styles.Container>
      </Styles.Wrapper>
    </>
  );
};


export default WizardWonderSuiteHeroBanner;
