import React from 'react';
import MaskedInput from 'react-text-mask';


function MaskIpv4(props) {
  const { inputRef, ...other } = props;

  const optionsConfig = {
    guide: false,
    placeholderChar: '\u2000',
    mask: (value) => {
      let result = [];
      const chunks = value.split('.');

      for (let i = 0; i < 4; i += 1) {
        const chunk = (chunks[i] || '').replace(/_/gi, '');
        if (chunks.length > i + 1) {
          result.push(...new Array(chunk.length).fill(/\d/), '.');
        } else if (chunk === '') {
          result.push(/\d/, /\d/, /\d/, '.');
        } else if (+chunk === 0) {
          result.push(/\d/, '.');
        } else if (
          chunks.length < 4
          || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)
        ) {
          if (
            (chunk.length < 2 && +`${chunk}00` > 255)
            || (chunk.length < 3 && +`${chunk}0` > 255)
          ) {
            result.push(/\d/, /\d/, '.');
          } else {
            result.push(/\d/, /\d/, /\d/, '.');
          }
        } else {
          result.push(...new Array(chunk.length).fill(/\d/), '.');
        }
      }

      result = result.slice(0, -1);
      return result;
    },
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) return false;
      const parts = value.split('.');
      if (
        parts.length > 4
        || parts.some(part => part === '00' || part < 0 || part > 255)
      ) {
        return false;
      }
      return value;
    },
  };

  return (
    <MaskedInput
      {...optionsConfig}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

export default MaskIpv4;
