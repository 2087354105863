import React from 'react';
import { Select } from '@/pages/common/v1/inputs/Select';
import { TextButton } from '@/pages/common/v1/TextButton';
import { Button as NewButton } from '@/pages/common/v1/Button';
import { Input } from '@/pages/common/v1/inputs/Input';
import * as Styles from './SelectQuestion.styles';

const SelectQuestion = ({
  currentQuestion, handleAnswerChange, handleJumpQuestion, handleSendAnswer, currentQuestionAnswer, displayOtherOptionField, otherOptionFieldValue, setOtherOptionFieldValue,
}) => {
  const handleOtherOptionChange = (value) => {
    setOtherOptionFieldValue(value);
  };

  return (
    <>
      <Styles.QuestionLabel>
        {currentQuestion.label}
      </Styles.QuestionLabel>
      <Styles.SelectContainer>
        <Select
          options={currentQuestion.options && [...currentQuestion.options]}
          placeholder={currentQuestion.selectPlaceholder}
          onChange={handleAnswerChange}
          optionsHeight="300px"
          test-id="questions-select"
          selectedResetOnChangeOf={currentQuestion}
          breakLine
        />
        {currentQuestion.otherOption && currentQuestion.otherOption.displayInput && displayOtherOptionField && (
        <Styles.OtherInputContainer>
          <Input testId="question-other-input" onChange={event => handleOtherOptionChange(event.target.value)} value={otherOptionFieldValue} placeholder={currentQuestion.otherOption && currentQuestion.otherOption.inputPlaceholder} type="text" />
        </Styles.OtherInputContainer>
        )}
      </Styles.SelectContainer>
      <Styles.QuestionButtonsContainer>
        {currentQuestion.skip && currentQuestion.skip.skippable && (
        <TextButton onClick={() => handleJumpQuestion(currentQuestion.id)} text={currentQuestion.skip.skipLabel} />
        )}
        <NewButton
          onClick={handleSendAnswer}
          label={currentQuestion.sendLabel}
          disabled={displayOtherOptionField
            ? otherOptionFieldValue === ''
            : currentQuestionAnswer.label === ''}
        />
      </Styles.QuestionButtonsContainer>
    </>
  );
};

export default SelectQuestion;
