import { DNS_PROPAGATION_ARTICLE } from '@/config/urls/supportUrls';
import { Link } from 'gatorcomponents';
import React from 'react';

export default {
  stepper: {
    linkChoose: 'Elija un enlace único',
    content: 'Introduzca el contenido',
    template: 'Seleccione una plantilla',
    share: 'Comparta',
  },
  step1: {
    title: (
      <>
        <strong>Cree su enlace en la Bio</strong>
        <br />
        y amplie su audiencia.
      </>
    ),
    description: 'Compártalo en todas las redes sociales y permita que sus clientes se conecten con usted fácilmente.',
    free: 'Gratis',
    customizable: 'Personalizable',
    easy: 'Fácil de usar',
    chooseALink: 'Elija su enlace único',
    continue: 'Continuar',
    linkPlaceholder: 'enlace',
    success: 'Este enlace está disponible',
    error: 'Este enlace no está disponible',
    knowMore: 'Consulte más',
    olink: 'olink.ai/',
  },
  step2: {
    title: 'Contenido',
    description: 'Describa su negocio, agregue sus redes sociales y otros enlaces útiles.',
    about: 'Acerca de su enlace',
    linkTitle: 'Titulo',
    linksDescription: 'Descripción',
    links: 'Enlaces',
    addLink: 'Añadir enlace',
    continue: 'Continuar',
    required: 'Agregue sus enlaces antes de continuar',
    preview: 'Así es como su enlace en la Bio (Biografía) aparecerá para sus visitantes.',
    back: 'Volver',
    linksYouAdd: 'Los enlaces que agregue aparecerán aquí.',
    insertLink: 'Insertar enlace',
    url: 'URL',
    linkTitlePlaceHolder: 'Ej: Visite nuestra tienda',
    urlPlaceHolder: 'Ej: tiendahostgator.mx',
    add: 'Añadir',
    edit: 'Guardar',
    cancel: 'Cancelar',
    tooltip: 'Puede agregar hasta 15 enlaces dentro de su enlace en la bio. Añada informaciones como menú, dirección del lugar, redes sociales y mucho más.',
    tooltipSocialMedia: 'Puedes agregar hasta 6 iconos en tu Link en Bio. Agrega iconos para tus redes sociales y aplicaciones de mensajería.',
    previewButton: 'Preview',
    closePreview: 'Cerrar Preview',
    delete: 'Su enlace será eliminado. Esta acción no se puede deshacer',
    deleteModalTitle: 'Eliminar Enlace en la Bio',
    confirmDelete: 'Soy consciente, quiero eliminar',
    invalidLink: 'Enlace no es válido',
    titlePlaceHolder: 'Ej: Panadería Catarina',
    descriptionPlaceholder: 'Ej: Descubra los sabores únicos de nuestra panadería artesanal...',
    socialLinks: 'Iconos de redes sociales',
    addSocialLink: 'Agregar red social',
    deleteSocialLinkTitle: 'Eliminar Icono',
    deleteSocialLink: 'Su icono será eliminado. Esta acción no se puede deshacer',
    socialLinkModal: {
      title: 'Insertar red social',
      description: 'Seleccione el ícono de la red social que desee agregar',
      socialMedia: 'Redes sociales',
      messageApps: 'Aplicaciones de mensajería y correo electrónico',
      insertLink: 'Ingrese abajo el enlace de su perfil de la red social',
      whatsappPlaceholder: '123456789',
    },
    fieldRequired: 'Campo obligatorio',
  },
  step3: {
    customize: 'Personalice su enlace',
    colorScheme: 'Seleccione la combinación de colores:',
    template: 'Selecciona una plantilla para su Enlace en la Bio:',
    MODERN: 'Moderno',
    FRIENDLY: 'Amigable',
    MINIMALIST: 'Minimalista',
    FUNNY: 'Divertido',
    publish: 'Publicar el Enlace en la Bio',
    back: 'Volver',
    previewButton: 'Preview',
    closePreview: 'Cerrar Preview',
    preview: 'Así es como su enlace en la Bio (Biografía) aparecerá para sus visitantes.',
  },
  step4: {
    title: '¡Su enlace está listo para ser compartido!',
    description: 'Muestre a sus clientes lo fácil que es conectarse con su negocio.',
    copyButton: 'Copiar enlace',
    viewButton: 'Visualizar',
    doMore: '',
    linkPlaceholder: 'olink.ai/',
    copied: 'Copiado',
  },
  list: {
    myLinks: 'Mis Enlaces',
    create: 'Crear nuevo enlace en la bio',
    limit: (
      <span>
        Puede crear hasta
        {' '}
        <strong>5 enlaces en la Bio</strong>
      </span>
    ),
    copy: 'Copiar enlace',
    dataAnalysis: 'Análisis de datos',
    customDomain: 'Dominio personalizado',
    edit: 'Editar enlace en la Bio',
    delete: 'Eliminar Enlace en la Bio',
    deleteModalDescription: profileName => (
      <p>
        Su enlace
        {' '}
        <strong>{profileName}</strong>
        {' '}
        será eliminado, junto con todo su contenido. Esta acción no se puede deshacer.
      </p>
    ),
    cancel: 'Cancelar',
    confirmDelete: 'Soy consciente, quiero eliminar',
    linkPlaceholder: 'olink.ai/',
    copied: 'Copiado',
    tooltip: 'Has alcanzado el límite de 5 enlaces en la Bio. Para crear uno nuevo, puedes eliminar un enlace existente en la Bio',
    domainConnectedTo: 'Conectado al dominio ',
    bannerTitle: (
      <>
        <strong>¡Novedad!</strong>
        {' '}
        Conecte un dominio personalizado a su Enlace en la Bio
      </>
    ),
    bannerDescription: 'Personalice aún más su Enlace en la Bio conectando un dominio que ya tiene registrado.',
    seeHowToConfigure: 'Consulte como configurar',
    linkExample: 'bio.mitienda.com',
  },
  toasts: {
    linkDeleteError: 'No se ha podido eliminar el enlace seleccionado. Inténtelo de nuevo.',
    linkDeleteSuccess: 'El enlace seleccionado fue eliminado',
    linkNaBioCreationError: 'Ocurrió un error al intentar crear su Enlace en la Bio. Inténtelo de nuevo.',
    linkNaBioDeleteError: 'No fue posible eliminar el Enlace en la Bio. Inténtelo de nuevo.',
    linkNaBioDeleteSuccess: 'Su Enlace en la Bio fue eliminado.',
    linkEdition: 'No fue posible realizar esta acción. Inténtelo de nuevo.',
    profileLoadingError: 'Se ha producido un error al cargar los Enlaces en la Bio. Inténtelo de nuevo',
    linkUpdated: 'Enlace actualizado',
    domainLinked: 'El dominio personalizado fue eliminado de su Enlace en la Bio.',
    domainRemoved: 'Ocurrió un error al eliminar el dominio personalizado de su Enlace en la Bio. Inténtelo nuevamente.',
  },
  statistics: {
    linkPlaceholder: 'olink.ai/',
    consolidatedData: 'Datos consolidados',
    totalData: 'Datos de accesos totales desde la creación de su Enlace en la Bio.',
    access: totalAccess => (
      <>
        <strong>{totalAccess}</strong>
        {' '}
        <p>Accesos</p>
      </>
    ),
    clicks: totalClicks => (
      <>
        <strong>{totalClicks}</strong>
        {' '}
        <p>Clics</p>
      </>
    ),
    conversionRate: rate => (
      <>
        <strong>
          {rate}
          %
        </strong>
        {' '}
        <p>Tasa de conversión</p>
      </>
    ),
    dailyAccess: 'Datos de accesos diarios',
    lastWeek: 'Últimos 7 días',
    lastMounth: 'Últimos 30 días',
    totalAccess: 'Total de accesos',
    totalClicks: 'Total de clics',
    totalConversion: 'Tasa de conversión',
    accessTooltip: 'Total de veces que su Enlace en la Bio fue accedido.',
    clicksTooltip: 'Total de clics en los enlaces internos y iconos de redes sociales de su Enlace en la Bio.',
    conversionRateTooltip: 'Es el porcentaje de visitantes que hicieron clic en algún enlace interno o icono de su Enlace en la Bio.',
    emptyStateTitle: 'Todavía no hay datos para mostrar',
    emptyStateDescription: 'Estamos analizando su Enlace en la Bio. En breve, tendrá acesso a los dados de acesso que ayudarán a comprender mejor el comportamiento de su Enlace.',
  },
  customDomainModal: {
    title: 'Conecte su Enlace en la Bio a un dominio propio',
    description: 'Personalice aún más su Enlace en la Bio vinculándolo a un dominio que ya tiene registrado, puede conectarlo como su dominio principal o crear un subdominio solo para su Enlace en la Bio.',
    domainPlaceHolder: 'Seleccionar dominio',
    cancel: 'Cancelar',
    verify: 'Verificar dominio',
    otherDomain: 'Quiero usar otro dominio',
    otherDomainInputLabel: '¿Cuál dominio desea conectar?',
    invalidDomain: '¡Dominio inválido! No puede contener caracteres especiales (excepto guion), iniciar y finalizar con guion, contener solo números o tener más de 63 caracteres.',
    howToConnectTitle: domain => (
      <>
        ¿Cómo quiere conectar el dominio
        {' '}
        <span>
          {domain}
        </span>
        {' '}
        su Enlace en la Bio?
      </>
    ),
    subdomain: 'Definir un subdominio para mi Enlace en la Bio',
    subdomainDescription: (
      <>
        Cree un subdominio solo para su Enlace en la Bio, como
        {' '}
        <strong>bio.meusite.com</strong>
        .  Esto no cambia el apuntamiento del dominio original.
      </>
    ),
    recommended: 'Recomendado',
    mainDomain: domain => (
      <>
        Usar
        {' '}
        <span>
          {domain}
        </span>
        {' '}
        como dominio principal
      </>
    ),
    mainDomainDescription: (
      <>
        Ideal si aún no tiene un sitio web vinculado al dominio.
        {' '}
        <strong>Su dominio apuntará directamente</strong>
        {' '}
        al Enlace en la Bio.
      </>
    ),
    back: 'Volver',
    continue: 'Continuar',
    insertSubdomain: 'Ingrese el subdominio',
    verifyChanges: 'Verifique los cambios',
    verifyChangesDescription: 'Los cambios a continuación se realizarán después de que confirme el cambio.',
    afterConfirmation: 'Después de la confirmación, su dominio personalizado redirigirá a la página de su Enlace en la Bio:',
    newDomainConnected: 'Nuevo dominio conectado',
    afterConfirmationInfo: (
      <>
        Su dominio original
        {' '}
        <strong>olink.ai</strong>
        {' '}
        también continuará funcionando y  se redirigirá para su Enlace en la Bio.
      </>
    ),
    confirmChange: 'Confirmar cambio',
    domainConnectionSuccess: '¡Dominio personalizado conectado!',
    successDescription: (
      <>
        El dominio se conectó a su Enlace en la Bio.
        {' '}
        <strong>Es posible que los visitantes tarden algunas horas en ver su Enlace en la Bio del dominio recién conectado</strong>
        {' '}
        , mientras tanto, aún puede usar su dominio predeterminado
        {' '}
        <strong>olink.ai</strong>
        {' '}
        en su Enlace en la Bio.
      </>
    ),
    backToList: 'Volver a la lista de Enlaces',
    configureYourDomain: 'Configure su dominio',
    configureYourDomainDescription: domain => (
      <>
        Como el dominio
        {' '}
        <strong>
          &quot;
          {domain}
          &quot;
        </strong>
        {' '}
        no se registró aquí en HostGator, debe ir al otro proveedor y apuntar el registro CNAME para la página de su Enlace en la Bio, siga las instrucciones a continuación:
      </>
    ),
    configureStep1: 'Ingrese al panel del proveedor donde está registrado su dominio.     ',
    configureStep2: 'En el dominio que desea configurar (cambiar DNS) para su Enlace en la Bio de HostGator, busque por "Cambiar DNS o Cambiar Servidores" - se no encuentra esa opción, contacte el soporte de ese proveedor.',
    configureStep3: 'Busque la opción para agregar un nuevo registro y seleccione el tipo CNAME.',
    configureStep4: 'Agregue un nuevo registro CNAME que contenga los siguientes valores:',
    configureStep4Attention: (
      <>
        <strong>Atención:</strong>
        {' '}
        Al realizar la configuración de abajo, las direcciones actuales del dominio se perderán y pasara solo a dirigirse al Enlace en la Bio.
      </>
    ),
    configureStep4Name: domain => (
      <>
        Nombre:
        {' '}
        <strong>{domain}</strong>
      </>
    ),
    configureStep4TTL: (
      <>
        TTL:
        {' '}
        <strong>3600</strong>
      </>
    ),
    configureStep4Value: value => (
      <>
        Valor:
        {' '}
        <strong>{value}</strong>
      </>
    ),
    configurationTip: 'Algunas plataformas pueden utilizar nombres diferentes para los campos del registro, si tiene dudas, investigue cómo agregar registros DNS en el proveedor de su dominio.',
    configurationDone: 'Ya hice los cambios',
    verifyingDomain: 'Verificando dominio',
    verifyingDomainDescription: 'Verificar el status de configuración de su dominio.',
    verificationNotPossible: 'No fue posible conectar su Enlace en la Bio al dominio informado.',
    verifyOtherDomain: 'Compruebe si el dominio está correcto y registrado, o elija otro dominio.',
    chooseOther: 'Elegir otro dominio',
    domainConnected: '¡Dominio personalizado conectado!',
    domainConnectedDescription: domain => (
      <>
        El dominio
        {' '}
        <strong>{domain}</strong>
        {' '}
        ha sido conectado a su Enlace en la Bio, ahora puede compartir este nuevo dominio con sus visitantes.
      </>
    ),
    removeDomainLink: 'Eliminar dominio personalizado',
    removeDomainTitle: 'Eliminar dominio',
    removeDomainDescription: (
      <>
        Eliminar el dominio personalizado significa que ya no dirigirá tráfico a su Enlace en la Bio. Aún puede usar el dominio
        {' '}
        <strong>olink.ai</strong>
        {' '}
        original para compartir su Enlace en la Bio o agregar un nuevo dominio personalizado.
      </>
    ),
    removeDomainConfirm: 'Estoy de acuerdo, quiero eliminarlo',
    removeDomainSuccessAlert: 'El dominio personalizado fue eliminado de su Enlace en la Bio.',
    removeDomainErrorAlert: 'Ocurrió un error al eliminar el dominio personalizado de su Enlace en la Bio. Inténtelo nuevamente.',
    externalDomainConnected: (
      <>
        Su dominio se ha conectado y sus visitantes pronto podrán ver su Enlace en la Bio desde el nuevo dominio. Mientras tanto, use su dominio predeterminado
        {' '}
        <strong>olink.ai</strong>
        {' '}
        en su bio.
      </>
    ),
    timeToPropagate: (
      <>
        Los cambios en el registro DNS pueden tardar hasta 72 horas en completarse (período de propagación de DNS). Pasado este tiempo, el nuevo dominio quedará vinculado a su Enlace en la Bio.
        <Link text="Consulte más sobre este plazo" href={DNS_PROPAGATION_ARTICLE} />
      </>
    ),
  },

};
