import React from 'react';
import * as Styles from './MyDataSkeleton.styles';
import {
  Skeleton,
} from 'gatorcomponents';

const MyDataSkeleton = ({ personalDataLabel, addressLabel, isBRServer }) => {
  const InputsRow = (
    <Styles.Row>
      <Skeleton variant="input" width="100%" />
      <Skeleton variant="input" width="100%" />
    </Styles.Row>
  );

  return (
    <Styles.Container data-testid="loaderContainer">
      <Styles.Form data-testid="loader">
        <Styles.Column>
          <Styles.Title>{personalDataLabel}</Styles.Title>
          <Skeleton variant="rectangle" height="21px" width="140px" />
          <Skeleton variant="input" />
          {isBRServer && <Skeleton variant="input" />}
          {InputsRow}
          <Skeleton variant="input" />
          <Skeleton variant="input" />
        </Styles.Column>

        <Styles.Column>
          <Styles.Title>{addressLabel}</Styles.Title>
          {InputsRow}
          <Skeleton variant="input" />
          {InputsRow}
          {InputsRow}
          <Styles.PrivacyTitle>
            <Skeleton variant="headline" width="250px" />
          </Styles.PrivacyTitle>
          <Skeleton variant="rectangle" height="24px" width="264px" />
        </Styles.Column>
      </Styles.Form>
    </Styles.Container>
  );
};

export default MyDataSkeleton;
