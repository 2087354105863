import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 350px;
  max-width: 550px;
  padding-left: 17px;
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    padding-left: 0;
    width: 100%;
  },
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
  align-self: center;
  background-color: ${theme.palette.success.primary};
  border-radius: 50%;
  height: 45px;
  margin-top: 35px;
  width: 45px;
  `}
`;

export const IconCheck = styled.div`
  ${() => css`
  padding: 10px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
  align-items: center;
  font-size: 32px;
  font-weight: ${theme.font.weights.medium};
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 45px;
  text-align: center;
  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.font.sizes.xxl};
  },
  `}
`;

export const Button = styled.div`
  ${() => css`
  display: flex;
  margin-bottom: 35px;
  margin-top: 15px;
  `}
`;
