import React from 'react';
import { locale } from '@/utils/locale';
import { Trans } from 'react-i18next';

export const sitesPagesLocale = {
  routeSites: locale('routes.sitesPage'),
  routeSitesPages: locale('routes.sitesPage'),
  routesNewSite: locale('routes.newSite'),
  sitesPageTitle: locale('lists.sitesPage'),
  tooltip: locale('sites.tooltip'),
  newTooltip: (
    <>
      {locale('sites.newTooltip.0')}
      <span>{locale('sites.newTooltip.1')}</span>
    </>
  ),
  filter: {
    options: {
      filterBy: locale('sites.filter.options.filterBy'),
    },
    clear: locale('sites.filter.clear'),
    searchBarPlaceHolder: locale('sites.filter.placeholder'),
  },
  createSiteBanner: {
    title: (
      <>
        {<span>{locale('sites.createSiteBanner.title.0')}</span>}
        {locale('sites.createSiteBanner.title.1')}

      </>
    ),
    subtitle: locale('sites.createSiteBanner.subtitle'),
    button: locale('sites.createSiteBanner.button'),
    tools: {
      wordpress: locale('sites.createSiteBanner.tools.wordpress'),
      sitebuilder: locale('sites.createSiteBanner.tools.sitebuilder'),
      other: locale('sites.createSiteBanner.tools.other'),
    },
  },
  modalExclusion: {
    title: locale('sites.modalExclusion.title'),
    descriptor: [
      locale('sites.modalExclusion.descriptor.0'),
      locale('sites.modalExclusion.descriptor.1'),
      locale('sites.modalExclusion.descriptor.2'),
    ],
    radioText: locale('sites.modalExclusion.radioText'),
    cancelLabel: locale('sites.modalExclusion.cancelLabel'),
    continueLabel: locale('sites.modalExclusion.continueLabel'),
  },
  warningModals: {
    domainCanceled: {
      title: locale('sites.siteModals.domainCanceled.title'),
      button: locale('sites.siteModals.domainCanceled.button'),
      contentDomainName: domain => locale('sites.siteModals.domainCanceled.contentDomainName', { domain }),
      contentsDomainName: domain => locale('sites.siteModals.domainCanceled.contentsDomainName', { domain }),
      contentInfo: locale('sites.siteModals.domainCanceled.contentInfo'),
      contentsInfo: locale('sites.siteModals.domainCanceled.contentsInfo'),
      contentRedirect: locale('sites.siteModals.domainCanceled.contentRedirect'),
    },
    planCanceled: {
      title: locale('sites.siteModals.planCanceled.title'),
      button: locale('sites.siteModals.planCanceled.button'),
      contentRedirect: locale('sites.siteModals.planCanceled.contentRedirect'),
    },
    newSite: {
      contentInfo: locale('sites.siteModals.newSite.contentInfo'),
      button: locale('sites.siteModals.newSite.button'),
    },
    createSite: {
      title: (
        <>
          {locale('sites.siteModals.createSite.title.0')}
          <span>{locale('sites.siteModals.createSite.title.1')}</span>
        </>
      ),
      description: domain => (
        <>
          {locale('sites.siteModals.createSite.description.0')}
          <br />
          {locale('sites.siteModals.createSite.description.1')}
          <span>{locale('sites.siteModals.createSite.description.2', { domain })}</span>
        </>
      ),
      toolsText: (
        <>
          {locale('sites.siteModals.createSite.toolsText.0')}
          <span>{locale('sites.siteModals.createSite.toolsText.1')}</span>
          {locale('sites.siteModals.createSite.toolsText.2')}
        </>
      ),
      buttonCreate: locale('sites.siteModals.createSite.buttonCreate'),
      buttonClose: locale('sites.siteModals.createSite.buttonClose'),
    },
    createSiteWithPartners: {
      title: (
        <>
          <span>{locale('sites.siteModals.createSiteWithPartners.title.0')}</span>
          {locale('sites.siteModals.createSiteWithPartners.title.1')}
        </>
      ),
      description: () => (
        <>
          {locale('sites.siteModals.createSiteWithPartners.description.0')}
          <span>{locale('sites.siteModals.createSiteWithPartners.description.1')}</span>
          {locale('sites.siteModals.createSiteWithPartners.description.2')}
        </>
      ),
      buttonCreate: locale('sites.siteModals.createSiteWithPartners.buttonCreate'),
      buttonClose: locale('sites.siteModals.createSiteWithPartners.buttonClose'),
    },
    ecommercePlusOffer: {
      title: locale('sites.siteModals.ecommercePlusOffer.title'),
      descriptionFirst: domain => (
        <>
          {locale('sites.siteModals.ecommercePlusOffer.descriptionFirst.0')}
          <span>{locale('sites.siteModals.ecommercePlusOffer.descriptionFirst.1', { domain })}</span>
          {locale('sites.siteModals.ecommercePlusOffer.descriptionFirst.2')}
        </>
      ),
      descriptionSecond: locale('sites.siteModals.ecommercePlusOffer.descriptionSecond'),
      continue: locale('sites.siteModals.ecommercePlusOffer.continue'),
      skip: locale('sites.siteModals.ecommercePlusOffer.skip'),
    },
  },
  paymentUrl: locale('routes.billing'),
  asaas: {
    banner: {
      title: locale('asaas.banner.title'),
      newChip: locale('asaas.banner.newChip'),
      text: (
        <>
          {locale('asaas.banner.text.0')}
          <strong>{locale('asaas.banner.text.1')}</strong>
          {locale('asaas.banner.text.2')}
        </>
      ),
      buttonLabel: locale('asaas.banner.buttonLabel'),
    },
    asaasRoute: locale('routes.asaas'),
  },
  createSiteWithPartners: {
    title: (
      <>
        <span>{locale('menu.v2.createSiteWithPartners.title.0')}</span>
        {locale('menu.v2.createSiteWithPartners.title.1')}
      </>
    ),
    description: (
      <>
        {locale('menu.v2.createSiteWithPartners.description.0')}
        <span>{locale('menu.v2.createSiteWithPartners.description.1')}</span>
        {locale('menu.v2.createSiteWithPartners.description.2')}
      </>
    ),
    buttonCreate: locale('menu.v2.createSiteWithPartners.buttonCreate'),
  },
};

export const emptyMessage = label => (
  <Trans i18nKey="sites.filter.noResults.option" values={{ status: label }}>
    <>0</>
    <b>1</b>
    <>2</>
  </Trans>
);
