import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import { Grid, withWidth } from '@material-ui/core';
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import { SUPPORT_ARTICLES } from '@/config/urls/supportUrls';
import styles from './styles';

const verifyToSetClasses = (screenIsDowSM, screenIsDowXS, screenIsUpLG, classes) => {
  if (screenIsDowSM && !screenIsDowXS) {
    return classes.contentBorder;
  }
  if (!screenIsUpLG) {
    return classes.wrapperWithoutLeftTitle;
  }
  return '';
};

const Hits = ({ hit, classes, width }) => {
  const linkTo = `${SUPPORT_ARTICLES}${hit.id}`;
  const screenIsUpLG = isWidthUp('lg', width);
  const screenIsDowSM = isWidthDown('sm', width);
  const screenIsDowXS = isWidthDown('xs', width);

  return (
    <Grid container className={classes.container}>
      <a href={linkTo} target="blank" className={classes.link}>
        <Grid container className={classes.category}>
          <Grid item className={classes.categoryContent}>
            {hit.category.title}
          </Grid>
        </Grid>
        <Grid container>
          {screenIsUpLG && (
          <Grid item className={classes.section}>
            {hit.section.title}
          </Grid>
          )}

          {screenIsDowSM && !screenIsDowXS && (
          <Grid item className={classes.section}>
            {hit.section.title}
          </Grid>
          )}

          <Grid
            container
            className={`${classes.content} ${screenIsUpLG && classes.contentBorder} ${verifyToSetClasses(screenIsDowSM, screenIsDowXS, screenIsUpLG, classes)}`}
          >
            <Grid item className={classes.title}>
              <Highlight className={classes.highlight} attribute="title" hit={hit} />
            </Grid>
            <Grid item className={classes.body}>
              <span>... </span>
              <Snippet className={classes.highlight} attribute="body_safe" hit={hit} />
              <span> ...</span>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default withWidth()(withStyles(styles)(Hits));
