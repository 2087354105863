import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0px 16px 18px 16px;

    strong {
      font-weight: ${theme.v2.font.weights.bold};
    };

    a {
      text-decoration: 'none';
      color: ${theme.v2.colors.brand.primaryBlue};
    };
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    margin-bottom: 16px;
  `}
`;

export const Subtitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-bottom: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 19px;
  justify-content: center;
  margin-top: 25px;
`;
