import { cellphoneCover } from '@/utils/Masks/inputMasks';
import { phoneNumberToDigits } from '../Formatters/phoneNumberToDigits';

export const handleCoverPhoneNumber = ({ phoneNumber, phoneNumberCountry = 'other' }) => {
  const phoneNumberDigits = phoneNumberToDigits({ phoneNumber });
  const isValidCountry = Object.keys(cellphoneCover).includes(phoneNumberCountry.toLowerCase());
  const country = isValidCountry ? phoneNumberCountry.toLowerCase() : 'other';
  const phone = phoneNumberDigits
    ? phoneNumberDigits.replace(
      cellphoneCover[country].regex,
      cellphoneCover[country].replace,
    ) : '** ****-****';
  return phone;
};
