import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class NewSiteWordpress extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 310.9 263.6">
        <path
          className={classes.st0}
          d="M307.4,177.4l-19.8-1.5c-1-3.5-2.4-6.8-4-10l13.2-14.9c1.4-1.6,1.3-4-0.2-5.6l-13.2-13.2
          c-1.5-1.5-3.9-1.6-5.5-0.2l-15,12.9c-3.1-1.7-6.4-3.1-9.9-4.2l-1.2-19.9c-0.1-2.1-1.9-3.8-4.1-3.8H229c-2.1,0-3.9,1.6-4.1,3.8
          l-1.5,19.7c-3.8,1.1-7.3,2.6-10.7,4.4l-15-12.9c-1.6-1.4-4-1.3-5.5,0.2L179,145.4c-1.5,1.5-1.6,4-0.2,5.6l-10.6,26.4
          c-2.1,0.2-3.8,1.9-3.8,4.1v18.7c0,2.1,1.7,3.9,3.8,4.1l19.9,1.2c1.1,3.5,2.5,6.8,4.2,9.9l-12.9,15c-1.4,1.6-1.3,4,0.2,5.5l13.2,13.2
          c1.5,1.5,4,1.6,5.6,0.2l14.9-13.2c3.1,1.7,6.5,3,10,4l1.5,19.7c0.2,2.1,1.9,3.8,4,3.8h18.7c2.1,0,3.9-1.7,4.1-3.8l1.2-19.9
          c3.2-1,6.3-2.3,9.2-3.8l14.9,13.2c1.6,1.4,4,1.3,5.6-0.2l13.2-13.2c1.5-1.5,1.6-3.9,0.2-5.5l-12.9-15c1.7-3.1,3.1-6.4,4.2-9.9
          l19.9-1.2c2.1-0.1,3.8-1.9,3.8-4.1v-18.7C311.1,179.3,309.5,177.6,307.4,177.4z M237.8,211.8c-11.9,0-21.5-9.6-21.5-21.5
          s9.6-21.5,21.5-21.5s21.5,9.6,21.5,21.5S249.7,211.8,237.8,211.8z"
        />
        <path
          className={classes.st1}
          d="M206,87.2l-20-1.5c-1.9-7.6-4.8-14.8-8.7-21.4l13.4-15.2c2.1-2.3,1.9-5.8-0.2-8l-19-19.1
          c-2.2-2.2-5.6-2.3-8-0.3l-15.2,13c-6.6-3.9-13.7-7-21.3-9l-1.2-20.2C125.6,2.4,123,0,120,0H93c-3.1,0-5.6,2.4-5.8,5.4l-1.5,20
          c-8,2-15.6,5.2-22.5,9.3L48,21.7c-2.3-2-5.8-1.9-8,0.3L20.9,41.1c-2.2,2.2-2.3,5.7-0.2,8l13.4,15.2c-3.8,6.6-6.8,13.8-8.7,21.4
          l-20,1.5C2.3,87.4,0,90,0,93v27c0,3.1,2.4,5.7,5.5,5.8l20.2,1.2c2,7.6,5.1,14.7,9,21.3l-13,15.2c-2,2.3-1.9,5.8,0.3,8l19.1,19.1
          c2.2,2.2,5.7,2.3,8,0.2l15.2-13.4c6.6,3.8,13.8,6.8,21.4,8.7l1.5,20c0.2,3.1,2.8,5.4,5.8,5.4h27c3.1,0,5.7-2.4,5.8-5.5l1.2-20.2
          c7.1-1.9,13.9-4.7,20.1-8.4l15.2,13.4c2.3,2.1,5.8,1.9,8-0.2l19.1-19.1c2.2-2.2,2.3-5.6,0.3-8l-13-15.2c3.9-6.6,7-13.7,9-21.3
          l20.2-1.2c3.1-0.2,5.5-2.8,5.5-5.8V93C211.5,89.9,209.1,87.4,206,87.2z M105.8,153c-26.1,0-47.3-21.2-47.3-47.3s21.2-47.3,47.3-47.3
          s47.3,21.2,47.3,47.3S131.9,153,105.8,153z"
        />
        <path
          className={classes.st2}
          d="M206,87.2l-20-1.5c-1.9-7.6-4.8-14.8-8.7-21.4l13.4-15.2c2.1-2.3,1.9-5.8-0.2-8l-19-19.1
          c-2.2-2.2-5.6-2.3-8-0.3l-15.2,13c-6.6-3.9-13.7-7-21.3-9l-1.2-20.2C125.6,2.4,123,0,120,0H93c-3.1,0-5.6,2.4-5.8,5.4l-1.5,20
          c-8,2-15.6,5.2-22.5,9.3L48,21.7c-2.3-2-5.8-1.9-8,0.3L20.9,41.1c-2.2,2.2-2.3,5.7-0.2,8l13.4,15.2c-3.8,6.6-6.8,13.8-8.7,21.4
          l-20,1.5C2.3,87.4,0,90,0,93v27c0,3.1,2.4,5.7,5.5,5.8l20.2,1.2c2,7.6,5.1,14.7,9,21.3l-13,15.2c-2,2.3-1.9,5.8,0.3,8l19.1,19.1
          c2.2,2.2,5.7,2.3,8,0.2l15.2-13.4c6.6,3.8,13.8,6.8,21.4,8.7l1.5,20c0.2,3.1,2.8,5.4,5.8,5.4h27c3.1,0,5.7-2.4,5.8-5.5l1.2-20.2
          c7.1-1.9,13.9-4.7,20.1-8.4l15.2,13.4c2.3,2.1,5.8,1.9,8-0.2l19.1-19.1c2.2-2.2,2.3-5.6,0.3-8l-13-15.2c3.9-6.6,7-13.7,9-21.3
          l20.2-1.2c3.1-0.2,5.5-2.8,5.5-5.8V93C211.5,89.9,209.1,87.4,206,87.2z M105.8,153c-26.1,0-47.3-21.2-47.3-47.3s21.2-47.3,47.3-47.3
          s47.3,21.2,47.3,47.3S131.9,153,105.8,153z"
        />
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(NewSiteWordpress);
