import { COUNTRY } from '@/config';
import { routesLocale } from './useSubscriptionsMessage.locale';

export const getInvoiceUrl = (id) => {
  const { invoices: invoicesRoute, unpaid: invoicesUnpaidRoute } = routesLocale;
  const paymentMethod = COUNTRY === 'br' ? 'boleto' : 'payu_boleto';
  return `${invoicesRoute()}${invoicesUnpaidRoute()}/${id}/${paymentMethod}`;
};

export const adaptPaymentMethodFromInvoice = (invoicePaymentMethod) => {
  const method = invoicePaymentMethod.toLowerCase();

  const hasMethod = (nameList = []) => nameList.some(name => method.includes(name));

  if (hasMethod(['credit', 'dlocal'])) return 'creditCard';
  if (hasMethod(['boleto', 'payu_boleto'])) return 'ticket';
  if (hasMethod(['paypal'])) return 'paypal';

  return method;
};
