const styles = theme => ({
  titleElement: {
    paddingBottom: 7,
    paddingTop: 0,

    '& h6': {
      color: theme.color.thundora,
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: '0.01px',
      lineHeight: '29px',
    },
  },
  closeButton: {
    margin: '15px 17px 0px auto',
    maxHeight: 24,
    maxWidth: 24,
  },
  containerWrapper: {
    padding: '0px 26px 24px',
    flex: 'unset',
  },
  warning: {
    color: theme.color.tundora,
    fontSize: 14,
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginBottom: 24,
    marginTop: 15,
  },
  explanation: {
    alignItems: 'center',
    border: `1px solid ${theme.color.havelockBlue}`,
    color: theme.color.tundora,
    display: 'flex',
    fontSize: 12,
    lineHeight: '18px',
    padding: '13px 15px',

    '& svg': {
      height: 18,
      marginRight: 6,
      width: 18,
    },
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  actionsWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  outlinedCancel: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
  },
});

export default styles;
