import React, { useEffect, useCallback } from 'react';
import {
  Modal,
} from '@/pages/common';
import * as Styles from './ModalCreateSiteWithSpecialist.styles';
import modalDeco from '@/media/newSiteModal/modal-deco.svg';
import { Button } from '@/pages/common/v1';
import SnappyFullBody from '@/media/siteBuilder/snappy/Snappy-Default.svg';

const ModalCreateSiteWithSpecialist = ({
  handleOnCloseWarningModal,
  buttonCreate = 'buttonCreate',
  description = 'description',
  title = 'title',
  createSitesWithSpecialistAnalytics,
  enabledCreateSiteWithSpecialistModal,
}) => {
  const handleOnClose = (action) => {
    handleOnCloseWarningModal && handleOnCloseWarningModal(action);
  };

  const setLocalStorage = useCallback(() => {
    localStorage.setItem('modalCreateSiteWithSpecialist', true);
  }, []);

  useEffect(() => {
    setLocalStorage();
  }, [setLocalStorage]);

  useEffect(() => {
    if (enabledCreateSiteWithSpecialistModal) {
      createSitesWithSpecialistAnalytics.createSiteModalView('uniqueMvp');
    }
  }, [createSitesWithSpecialistAnalytics, enabledCreateSiteWithSpecialistModal]);

  return (
    <Modal testId="modal-create-site" maxWidth={630} disablePadding onClose={() => handleOnClose('close')}>
      <Styles.ContentWrapper>
        <Styles.Deco src={modalDeco} alt="Deco" />

        <Styles.Title data-testid="modal-title">
          {title}
        </Styles.Title>

        <Styles.Content data-testid="modal-description">
          {description}
        </Styles.Content>

        <Styles.Action>
          <Button testId="create-site-button" onClick={() => handleOnClose('createWithSpecialist')} label={buttonCreate} />
        </Styles.Action>

        <Styles.SnappyFullBody src={SnappyFullBody} />

        <Styles.DecoTriangle />

        <Styles.DecoLine />
      </Styles.ContentWrapper>
    </Modal>
  );
};

export default ModalCreateSiteWithSpecialist;
