import React, { useEffect, useState } from 'react';
import * as Styles from './Timer.styles';

const Timer = ({
  startHour = null,
  startMinute = null,
  startSecound = null,
  onFinish = null,
  displayHour = true,
  testeId = 'timer-content',
}) => {
  const [hours, setHours] = useState(startHour);
  const [minutes, setMinutes] = useState(startMinute);
  const [seconds, setSeconds] = useState(startSecound);
  const [deadline, setDeadline] = useState((((hours || 0) * 60 * 60) + ((minutes || 0) * 60) + (seconds || 0)) * 1000);

  const getTime = (deadline) => {
    setHours(Math.floor((deadline / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((deadline / 1000 / 60) % 60));
    setSeconds(Math.floor((deadline / 1000) % 60));
    setDeadline(deadline);
  };

  const handleFinish = () => {
    onFinish && onFinish();
  };

  useEffect(() => {
    const timer = deadline > -1 && setInterval(() => getTime(deadline - 1000), 1000);

    if (deadline === 0) {
      handleFinish();
    }

    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadline]);

  const convertToString = value => (value < 10 ? `0${value}` : value);

  return (
    <Styles.Content data-testid={testeId}>
      {displayHour && (
        <Styles.Label data-testid="hours-time">
          {hours == null ? '00' : convertToString(hours)}
          :
        </Styles.Label>
      )}
      <Styles.Label data-testid="minutes-time">
        {minutes == null ? '00' : convertToString(minutes)}
      </Styles.Label>
      :
      <Styles.Label data-testid="secounds-time">
        {seconds == null ? '00' : convertToString(seconds)}
      </Styles.Label>
    </Styles.Content>
  );
};

export default Timer;
