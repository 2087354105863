import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, TextField,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';

const TypeCAAValueField = ({
  t, classes, formData, onHandleChangeInput,
}) => {
  const { loading } = useContext(DNSContext);
  return (
    <div className={classes.TxtBlockWrapper}>
      <TextField
        className={classes.textField}
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeTxtTextField')}
        multiline
        name="txt_data"
        onChange={e => onHandleChangeInput(e.target)}
        required
        value={formData.txt_data}
        variant="outlined"
        InputProps={{
          inputProps: {
            'data-testid': 'zoneManagerTxtTextField',
          },
        }}
      />
    </div>
  );
};

export default (withI18n()(withStyles(styles)(TypeCAAValueField)));
