import { routesV2 } from './routes';

const getPathId = () => {
  const handlePatch = window.location.pathname.split('/');
  return handlePatch[handlePatch.length - 1];
};

const getTitleFromDynamicBreadCrumbs = (stateDynamicBreadCrumbs) => {
  const keyName = getPathId();
  const [breadCrumb] = stateDynamicBreadCrumbs.filter(item => item.urlParam === keyName);
  return (breadCrumb && breadCrumb.title) ? breadCrumb.title : '';
};

const generateBreadCrumbs = (props, t, stateDynamicBreadCrumbs) => {
  const crumbs = routesV2(t).filter(({ path }) => props.match.path.includes(path)).map(({ path, ...rest }) => ({
    path: Object.keys(props.match.params).length ? Object.keys(props.match.params).reduce((path, param) => path.replace(
      `:${param}`, props.match.params[param],
    ), path) : path,
    ...rest,
  }));

  const generatedCrumbs = crumbs.map(({ name, path, icon }) => ({
    name: name || getTitleFromDynamicBreadCrumbs(stateDynamicBreadCrumbs),
    path,
    icon,
  }));
  return generatedCrumbs;
};

export default generateBreadCrumbs;
