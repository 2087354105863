import { IconDetailedHost, IconEdit } from '@/icons';
import React, { useEffect, useState } from 'react';
import { IconButton } from '../IconButton';
import * as Styles from './SlideToggle.styles';

const SlideToggle = ({
  testId = 'slide-toggle',
  icon = <IconDetailedHost size={29} />,
  title = 'Slide toggle title',
  description = 'Slide toggle description',
  toggleOnIcon = false,
  maxWidht = 'unset',
  maxHeight = '1000px',
  open = false,
  children = (
    <>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
    </>
  ),
  onToggle,
  controlledByFather = false,
}) => {
  const [display, toggleDisplay] = useState(open);

  const handleClickToggle = () => {
    (!controlledByFather && !toggleOnIcon) && toggleDisplay(!display);
    onToggle && onToggle({ toggleOpen: !display });
  };

  const handleClickButton = () => {
    !controlledByFather && toggleDisplay(!display);
    onToggle && onToggle({ toggleOpen: !display });
  };

  useEffect(() => {
    toggleDisplay(open);
  }, [open]);

  return (
    <Styles.SlideToggleWrapper data-testid={testId} maxWidht={maxWidht} maxHeight={maxHeight}>
      <Styles.ToggleAction clickable={!toggleOnIcon} onClick={handleClickToggle} data-testid={`${testId}-action`}>
        <Styles.IconWrapper>
          {icon}
        </Styles.IconWrapper>

        <Styles.ToggleHeader>
          <Styles.TitleWrapper>
            <Styles.Title data-testid={`${testId}-title`}>{title}</Styles.Title>
          </Styles.TitleWrapper>

          <Styles.Description data-testid={`${testId}-description`}>{description}</Styles.Description>
        </Styles.ToggleHeader>

        {toggleOnIcon && (
          <IconButton
            onClick={handleClickButton}
            icon={<IconEdit />}
          />
        )}
      </Styles.ToggleAction>

      <Styles.ToggleContent open={display} maxHeight={maxHeight} data-testid={`${testId}-content`}>
        {children}
      </Styles.ToggleContent>
    </Styles.SlideToggleWrapper>
  );
};

export default SlideToggle;
