import React from 'react';
import SnappyLogo from '@/media/logo/SnappyLogo/SnappyLogo.svg';
import { Button } from '@/pages/common/Button';
import { Chip } from '@/pages/common/Chip';
import { useHistory } from 'react-router-dom';
import * as Style from './Card.styles';

const Card = ({
  testId = 'cardItem',
  card = {
    logo: SnappyLogo,
    name: 'snappy',
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    featured: true,
    type: [0, 2],
  },
  modal = false,
  btnLabel = 'Label',
  tagList = [{
    id: 0,
    label: 'Default',
    value: 'default',
    checked: true,
  }, {
    id: 1,
    label: 'Chip',
    value: 'chip',
    checked: true,
  }, {
    id: 2,
    label: 'Recommended',
    value: 'recommended',
    checked: true,
  }],
  altLayout = false,
  handleClick = () => {},
  ga = () => {},
  featured = false,
  internLink = false,
}) => {
  const history = useHistory();

  const clickEvent = () => {
    ga();
    handleClick({ name: card.name, modal });
    internLink && history.push(card.link);
  };

  return (
    <Style.Card featured={featured}>
      <Style.Wrapper data-testid={testId} altLayout={altLayout}>
        <Style.Logo src={card.logo} alt={card.name} />
        <Style.Tags>
          {card.type.map((cardType) => {
            const label = tagList.find(element => element.id === cardType);
            return <Chip label={label.label} recommended={cardType === 1} altLayout={altLayout} key={`chip_${label.label}`} />;
          })}
        </Style.Tags>
        <Style.Description altLayout={altLayout}>{card.description}</Style.Description>
        <Style.Link href={!internLink && card.link} target={modal ? '_self' : '_blank'}>
          <Button onClick={clickEvent} label={btnLabel} testId={`acquire_${card.name}`} />
        </Style.Link>
      </Style.Wrapper>
    </Style.Card>
  );
};

export default (Card);
