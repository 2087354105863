const styles = theme => ({

  iconWrapper: {
    height: '20px',
    marginRight: '-1px',
    paddingLeft: '3px',
    width: '20px',
  },

  closeIcon: {
    animationDuration: '0.50s',
    animationName: 'show',
    fill: theme.color.regentGray,
    fontSize: '19px',
    marginRight: '-4px',
    opacity: '0',
    transform: 'scale(0)',
    transition: 'fill 0.2s, transform 0.2s, opacity 0.2s',

    '&.show': {
      opacity: '1',
      transform: 'scale(1)',
    },

    '&:hover': {
      cursor: 'pointer',
      fill: theme.color.tundora,
    },
  },

  showIcon: {
    opacity: '1',
    transform: 'scale(1)',
  },
});

export default styles;
