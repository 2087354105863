import styled, { css } from 'styled-components';
import colorfulLine from '@/media/asaas/colorful-border.svg';

export const Card = styled.div`
${({ featured }) => css`
    background-image: ${featured ? `url(${colorfulLine})` : undefined};
    background-size: cover;
    height: auto;
    padding: ${featured ? '4px' : '0px'};
    width: 100%;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme, altLayout }) => css`
    align-items: stretch;
    background-color: ${altLayout ? theme.color.indigo : theme.color.white};
    box-shadow: 0 3px 6px ${theme.color.tundoraLight};
    color: ${altLayout ? theme.color.white : theme.color.tundora};
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 24px;
    height: 100%;
    justify-content: space-between;
    left: 0;
    padding: 16px;
  `}
`;

export const Logo = styled.img`
  height: 46px;
  object-fit: contain;
  width: 100%;
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
`;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

export const Description = styled.div`
  ${({ theme, altLayout }) => css`
    color: ${altLayout ? theme.color.white : theme.color.tundora};
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  `}
`;
