import styled, { css } from 'styled-components';

export const SendWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const DescriptionWrapper = styled.div`
  display: flex;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 0px 0px;
  `}
`;
