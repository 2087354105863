const styles = theme => ({
  message: {
    fontSize: '14px',
    color: theme.color.regentGray,
    textAlign: 'center',
    marginTop: '18px',
    fontWeight: '400',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
  },
});

export default styles;
