import { TLD_LIST } from '@/enums/domains/tld.enum';

export const splitDomainAndTld = (domainName) => {
  if (!domainName || domainName === null) {
    return '';
  }

  const reversedDomainPieces = domainName && (domainName.split('.'));

  let tld = '';
  while (reversedDomainPieces.length > 0) {
    tld = `.${reversedDomainPieces.pop()}${tld}`;
    if (TLD_LIST.includes(tld)) {
      break;
    }
  }


  const domain = domainName.slice(0, -tld.length);

  return [domain, tld];
};
