import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: relative;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      border-radius: 0;
    };
  `}
`;

export const CardHeadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  min-width: fit-content;
`;

export const CardTitle = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.primaryExtraDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm2};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    padding: 0 8px 0 16px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      font-size: ${theme.v2.font.sizes.xs};
    };
  `}
`;

export const TooltipIconContainer = styled.div`
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 2px;
  width: fit-content;
`;

export const CardDescription = styled.p`
 ${({ theme, extraPadding }) => css`
    align-items: center;
    color: ${({ theme }) => theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 157px 0 40px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      margin-top: ${extraPadding && '28px'};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-right: 0;
    };
 `}
`;

export const ToggleContainer = styled.div`
  margin-left: auto;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: 24px;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    position: relative;
    margin: 16px 0 0 40px;
  };
`;
