const styles = theme => ({
  sealPreview: {
    width: '113px',
    height: '65px',
    marginRight: '2px',
  },
  previewBox: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 0,
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
  },
});

export default styles;
