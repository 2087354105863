import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { find, propEq } from 'ramda';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation, useHistory, useParams } from 'react-router';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { changePlan, getUnpaidUpgradeInvoice } from '@/redux/actions/emails';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import { Button as ButtonV1 } from '@/pages/common/v1/Button';
import ModalValidateExistentInvoice from '@/components/Modal/ModalValidateExistentInvoice/ModalValidateExistentInvoice';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import {
  ERROR_UPGRADE_MAIL_INVOICE,
  RECEIVE_EXISTENT_INVOICE_VALIDATION,
} from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import {
  reativateWithoutHostingPlanTagManager,
  domainWithoutHostingPlanTagManager,
  cPanelWebmailTagManager,
} from '@/utils/ThirdParties/tagManager';
import { COUNTRY } from '@/config';
import styles from './styles';
import PaymentMethod from '../../PaymentMethod';
import { getTotalPrice } from './getTotalPrice/getTotalPrice';
import { ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const HireBlock = ({
  classes,
  amount,
  cycle,
  annuallyPrice,
  monthlyPrice,
  featured,
  pid,
  domain,
  t,
  hostingId,
  handleOpenModalTitanTrialColorful,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { domain: currentDomain } = useParams();
  const [displayInvoiceModal, toggleDisplayInvoiceModal] = useState(false);
  const hasCPanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );

  const finalPrice = useMemo(
    () => getTotalPrice(monthlyPrice, annuallyPrice, cycle, amount),
    [cycle, monthlyPrice, annuallyPrice, amount],
  );
  const [paymentMethod, setPaymentMethod] = useState(
    COUNTRY === 'br' ? 'braspag_credit_1x' : 'dlocal',
  );
  const [ongoingInvoice, setOngoingInvoice] = useState(null);
  const [loading, setLoadingRedirect] = useState(false);
  const [lastResponse, setLastResponse] = useState(null);
  const invoices = useSelector(state => state.invoices);
  const location = useLocation();
  const proEmailList = useSelector(state => state.emails.proEmailList);

  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );
  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;


  const methodOptions = [
    {
      name: t('proEmail.upgradePlan.creditCard'),
      value: COUNTRY === 'br' ? 'braspag_credit_1x' : 'dlocal',
    },
    {
      name: t('proEmail.upgradePlan.gp_boleto'),
      value: COUNTRY === 'br' ? 'boleto' : 'payu_boleto',
    },
    { name: t('proEmail.upgradePlan.gp_paypal'), value: 'paypal' },
  ];

  function executeGA() {
    if (!hasCPanel) {
      hostingId === null
        ? domainWithoutHostingPlanTagManager(pid)
        : reativateWithoutHostingPlanTagManager(pid);
    } else {
      cPanelWebmailTagManager(pid);
    }
  }

  useEffect(() => {
    if (lastResponse && invoices && invoices.byStatus.Paid.length > 0) {
      const isPaid = find(
        propEq('id', lastResponse.data.invoice_id),
        invoices.byStatus.Paid,
      );


      let alertShowMessage = '';
      if (isPaid) {
        alertShowMessage = t('proEmail.upgradePlan.successWithCredits');
      } else if (lastResponse.data.upgraded) {
        alertShowMessage = t('proEmail.upgradePlan.success');
      }


      if (isPaid || lastResponse.data.upgraded) {
        dispatch(
          enqueueSnackbar(
            alertShowMessage,
            notifierSuccess,
          ),
        );

        const managerEmailDomainRoute = `${t('routes.emailsList')}${t('routes.emailsTitan')}/${domain}`;

        history.push(managerEmailDomainRoute);
        return;
      }

      const paymentPart = paymentMethod ? `/${paymentMethod}` : '';
      const unpaidRoute = `${t('routes.billing')}${t('routes.unpaid')}/${lastResponse.data.invoice_id}${paymentPart}`;

      history.push(unpaidRoute);
    }
  }, [invoices, lastResponse, t, domain, paymentMethod, dispatch, history]);

  const getTitanHirePlanActiveCpanelOfferContext = useMemo(() => {
    if (!proEmailList.length) {
      return;
    }

    const { plan } = proEmailList.find(item => item.domain === currentDomain);
    const dontHasPlan = plan === null;

    if (dontHasPlan) {
      return ['email_list_no_email_plan_offer'];
    }

    return ['email_list_free_email_platform_setup'];
  }, [currentDomain, proEmailList]);

  const getOfferContext = useCallback(() => {
    const currentPathName = location.pathname;

    const mapOfferContextByPath = {
      titanHirePlan: {
        route: '/titan-hire-plan/',
        offerContext: ['email_list_cancelled_reactivation'],
      },
      titanHirePlanActiveCpanel: {
        route: '/titan-hire-plan-active-cpanel/',
        offerContext: getTitanHirePlanActiveCpanelOfferContext,
      },
      titanHireFreePlan: {
        route: '/titan-hire-free-plan/',
        offerContext: ['email_list_cancelled_reactivation'],
      },
    };

    const keyForThatRoute = Object.keys(mapOfferContextByPath)
      .find(key => currentPathName.includes(mapOfferContextByPath[key].route));

    return keyForThatRoute ? mapOfferContextByPath[keyForThatRoute].offerContext : [];
  }, [location.pathname, getTitanHirePlanActiveCpanelOfferContext]);

  const handlePay = () => {
    executeGA();
    setLoadingRedirect(true);
    const offerContext = getOfferContext();

    const dispatchedAction = changePlan({
      planId: pid,
      domain,
      cycle,
      paymentMethod,
      inboxAmount: amount,
      hostingId,
      offerContext,
      isFree: true,
    });

    dispatch(dispatchedAction).then((response) => {
      if (response.type === ERROR_UPGRADE_MAIL_INVOICE) {
        dispatch(
          enqueueSnackbar(t('proEmail.upgradePlan.error'), notifierError),
        );
        setLoadingRedirect(false);
      } else {
        setLastResponse({ data: response.data.data });
        setLoadingRedirect(true);
      }
    });
  };

  useEffect(() => {
    if (ongoingInvoice) {
      toggleDisplayInvoiceModal(true);
    }
  }, [ongoingInvoice]);

  const ValidateExistentInvoice = (hostingId) => {
    setLoadingRedirect(true);
    dispatch(
      getUnpaidUpgradeInvoice({ domain, hostingId, isFree: false }),
    ).then((response) => {
      if (response.type === RECEIVE_EXISTENT_INVOICE_VALIDATION) {
        if (response.data.data.already_exists) {
          setOngoingInvoice(response.data.data.invoice);
        } else {
          handlePay();
        }
      } else {
        dispatch(
          enqueueSnackbar(t('proEmail.upgradePlan.error'), notifierError),
        );
      }
    });
  };

  return (
    <>
      {ongoingInvoice && (
        <ModalValidateExistentInvoice
          apllyableCredit={
            ongoingInvoice.recurring_amount - ongoingInvoice.total
          }
          displayInvoiceModal={displayInvoiceModal}
          handlePay={handlePay}
          loading={loading}
          ongoingInboxAmount={ongoingInvoice.number_of_mailboxes}
          ongoingInvoiceId={ongoingInvoice.id}
          ongoingMailBoxPrice={formatCurrency(
            ongoingInvoice.recurring_amount / ongoingInvoice.number_of_mailboxes,
          )}
          ongoingPaymentCycle={ongoingInvoice.billing_cycle}
          ongoingPlan={ongoingInvoice.product_name}
          ongoingTotalPrice={formatCurrency(ongoingInvoice.total)}
          toggleDisplayInvoiceModal={toggleDisplayInvoiceModal}
        />
      )}
      {!shouldRenderNewTitanCards && (
        <Typography
          data-testid="productTotalPrice"
          className={classes.priceDiv}
        >
          <font className={classes.price}>
            {t('proEmail.hirePlan.total')}
            {`${finalPrice}`}
          </font>
          <font className={classes.priceLabel}>
            {` ${
              cycle === 'annually'
                ? t('proEmail.hirePlan.yearPayment')
                : t('proEmail.hirePlan.monthPayment')
            }`}
          </font>
        </Typography>
      )}
      { !shouldRenderNewTitanCards && (
        <PaymentMethod
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          methodOptions={methodOptions}
        />
      )}
      {!shouldRenderNewTitanCards ? (
        <PrimaryButton
          className={classnames(classes.button, {
            [classes.featuredButton]: featured,
          })}
          type="submit"
          variant="contained"
          onLoading={loading}
          onClick={() => (ValidateExistentInvoice(hostingId))
          }
          data-testid="payInvoice"
        >
          {t('proEmail.hirePlan.hireCTA')}
        </PrimaryButton>
      ) : (
        <div className={classes.hireButtonWrapper}>
          <ButtonV1
            data-id="acquireButton"
            data-testid="acquireButton"
            variant="secondary"
            onClick={handleOpenModalTitanTrialColorful}
            target="_blank"
            rel="noopener noreferrer"
            label={t('proEmail.hirePlan.titanEssentials.ctaButton')}
          />
        </div>
      )}
    </>
  );
};

HireBlock.propTypes = {
  amount: PropTypes.number.isRequired,
  cycle: PropTypes.string.isRequired,
  annuallyPrice: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  pid: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(HireBlock));
