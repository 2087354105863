import { sitesHandlerGa } from '@/utils/ThirdParties/tagManager';
import { getURLCpanel } from '@/config/urls/cpanelUrls';
import { useCardActionsLocale } from './useCardActions.locale';
import { WHMCS_URL } from '@/config';
import { useCardActionsEnum } from './useCardActions.enum';
import { locale } from '@/utils/locale';
import React, {
  useCallback, useState,
} from 'react';
import { sitesAnalytics } from '@/analytics';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { IconLoading } from 'gatorcomponents';
import { useDispatch } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN, CHARLIE_CPANEL_SSO_PORTAL, CHARLIE_GROUP_SITES_CARDS, CHARLIE_SITELOCK,
} from '@/config/GrowthBook/constants';
import { wizardEnum } from '@/pages/sites/WizardHandler/WizardHandler.enum';
import { SITELOCK_STATUS } from '@/enums/sites/status.enum';
import { isBRServer } from '@/utils/Validators/validation';
import useLocale from '@/hooks/useLocale';
import logger from '@/utils/logger';
import { HgProducts } from '@/components/SupportForms/SupportForms.types';

const useCardActions = ({
  history,
  loadingCPanelSSO,
  routesLocale,
  allDomainsDnsStatus,
  setShowDnsInProgressModal,
  openWordpressLink,
  setDnsInProgressDomain,
  withGatorSolutions,
}) => {
  const defaultFilteredOptions = [
    {
      id: '0',
      label: useCardActionsLocale.filter.options.all,
      value: 'all',
      checked: true,
    },
    {
      id: '1',
      label: useCardActionsLocale.filter.options.pending,
      value: 'pending',
      checked: false,
    },
    {
      id: '2',
      label: useCardActionsLocale.filter.options.out,
      value: 'out',
      checked: false,
    },
  ];

  const { bannerNewSite: bannerNewSiteAnalytics } = sitesAnalytics;
  const [siteInExclusion, setSiteInExclusion] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(defaultFilteredOptions);
  const [filterTyped, setFilterTyped] = useState('');
  const [pageDescriptionTitle, setPageDescriptionTitle] = useState(useCardActionsLocale.pageDescription.empty.title);
  const [pageDescriptionSubtitle, setPageDescriptionSubtitle] = useState(useCardActionsLocale.pageDescription.empty.subtitle);
  const [pageMaxWidth, setPageMaxWidth] = useState('unset');
  const [button, setButton] = useState(null);
  const [displayArrow, setDisplayArrow] = useState(null);
  const [displayCreateSiteBanner, setDisplayCreateSiteBanner] = useState(false);
  const isSiteBuilderPreferenceEnabled = useFeatureIsOn(BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN);
  const newSitelock = useFeatureIsOn(CHARLIE_SITELOCK);
  const groupSitesCards = useFeatureIsOn(CHARLIE_GROUP_SITES_CARDS);
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);
  const [loadingCpanelSSOProductId, setLoadingCpanelSSOProductId] = useState(null);

  const putMainDomainToFirstPosition = useCallback((hostingArray) => {
    if (withGatorSolutions) {
      const hostindIdArray = [...hostingArray].map((sitesList) => {
        const newSitesList = [...sitesList];
        newSitesList.map((siteItem) => {
          if (!siteItem.cpanel_domains) {
            return [];
          }

          const newSiteItem = { ...siteItem };
          const sortedList = [...siteItem.cpanel_domains];

          if (!sortedList.length) {
            return newSiteItem;
          }

          const mainItemIndex = sortedList.findIndex(item => item.type === 'main');
          const mainItem = sortedList.splice(mainItemIndex, 1);
          sortedList.unshift(mainItem[0]);
          newSiteItem.cpanel_domains = sortedList;
          return newSiteItem;
        });

        return newSitesList;
      });

      return hostindIdArray;
    }

    if (!withGatorSolutions) {
      try {
        const newArray = [...hostingArray].map((hostItem) => {
          const sortedList = [...hostItem];
          const mainItemIndex = sortedList.findIndex(item => item.is_main_domain);
          const mainItem = sortedList.splice(mainItemIndex, 1);
          sortedList.unshift(mainItem[0]);
          return sortedList;
        });

        return newArray;
      } catch (e) {
        logger.error('Error on useCardActions - method putMainDomainToFirstPosition', e);
      }
    }
  }, [withGatorSolutions]);

  const dispatch = useDispatch();
  const { sites: sitesLocale } = useLocale();

  const openInNewTabClick = url => window.open(url, '_blank').focus();

  const filterCanceledSites = (sitesList) => {
    if (sitesList && sitesList.length > 0) {
      if (withGatorSolutions) {
        try {
          const notCanceledSitesList = sitesList.filter(site => !['cancelled', 'terminated'].includes(site.sub_status.toLowerCase()));
          return notCanceledSitesList;
        } catch (e) {
          logger.error('Error on useCardActions - method filterCanceledSites', e);
        }
      }

      if (!withGatorSolutions) {
        try {
          let notCanceledSitesList = sitesList.filter(site => site.status !== 'cancelled' && site.status_plan !== 'cancelled' && site.status_domain !== 'cancelled');
          notCanceledSitesList = sitesList.filter(site => site.status !== 'terminated' && site.status_plan !== 'terminated' && site.status_domain !== 'terminated');
          return notCanceledSitesList;
        } catch (e) {
          logger.error('Error on useCardActions - method filterCanceledSites', e);
        }
      }
    }

    return [];
  };

  const getMainActions = useCallback(({ siteItem, accordionItem }) => {
    if (withGatorSolutions) {
      try {
        const links = [];
        const isReseller = HgProducts.RESELLER_ALL.includes(siteItem.package_id);
        const isShared = HgProducts.SHARED_ALL.includes(siteItem.package_id);
        const isSitebuilder = HgProducts.PRODUCT_GROUPS.websitebuilder.includes(siteItem.package_id);
        const isWordpress = HgProducts.WPHOSTING_ALL.includes(siteItem.package_id);

        const hasSiteBuilder = false; // siteItem.builder_tools.sitebuilder.status.toLowerCase() === 'active'; sendo implementado pelo back
        const hasWordpressTool = false; // siteItem.builder_tools.wordpress.status.toLowerCase() === 'active'; sendo implementado pelo back

        const showSiteBuilderButton = (isSitebuilder && hasSiteBuilder) || (isShared && hasSiteBuilder) || (isReseller && hasSiteBuilder);

        const isActive = siteItem.status.toLowerCase() === 'active';

        if (!isActive) {
          return links;
        }

        const getCpanel = () => {
          if ((isShared && !groupSitesCards) || (isShared && groupSitesCards && !accordionItem)) {
            links.push({
              id: `item-${siteItem.hosting_id}-cPanel`,
              testId: 'button_cPanel',
              label: loadingCPanelSSO && loadingCpanelSSOProductId === siteItem.id ? <IconLoading /> : 'cPanel',
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: 'cPanel',
                  gaEventLabel: '',
                });
                getURLCpanel({
                  externalId: siteItem.hosting_id,
                  params: null,
                  openInNewTab: true,
                  dispatch,
                  enableCpanelSSO,
                });
                setLoadingCpanelSSOProductId(siteItem.id);
              },
            });
          }
        };

        const getSitebuilder = () => {
          if (showSiteBuilderButton) {
            links.push({
              id: `item-${siteItem.hosting_id}-sitebuilder`,
              testId: 'button_site_builder',
              label: useCardActionsLocale.websitebuilderAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.websitebuilderAction,
                  gaEventLabel: '',
                });
                window.open(`${useCardActionsLocale.routeWebsitebuilderRedirectAccess}/${siteItem.site}`);
              },
            });
          }
        };

        const getWHM = () => {
          if (isReseller) {
            links.push({
              id: `item-${siteItem.hosting_id}-reseller`,
              testId: 'button_reseller',
              label: useCardActionsLocale.resellerAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.resellerAction,
                  gaEventLabel: '',
                });
                openInNewTabClick(`${WHMCS_URL}/clientarea.php?action=productdetails&id=${siteItem.external_id}&dosinglesignon=1`);
              },
            });
          }
        };

        const getWordpress = () => {
          if (isWordpress) {
            links.push({
              id: `item-${siteItem.external_id}-wordpress`,
              testId: 'button_wordpress',
              label: useCardActionsLocale.wordpressAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.wordpressAction,
                  gaEventLabel: '',
                });
                openInNewTabClick(`${WHMCS_URL}/custom_includes/wordpress/endpoint.php?action=sso&id=${siteItem.external_id}&domain=${siteItem.site}`);
              },
            });
          }
        };

        const getWordpressOnBuilderTools = () => {
          if ((isShared || isWordpress) && hasWordpressTool) {
            links.push({
              id: `item-${siteItem.hosting_id}-wordpress-admin`,
              testId: 'button_wordpress_admin',
              label: useCardActionsLocale.wordpressAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.wordpressAction,
                  gaEventLabel: '',
                });
                const domainDnsStatus = allDomainsDnsStatus.find(domain => domain.domain === siteItem.site);
                if (domainDnsStatus && domainDnsStatus.propagation_status === wizardEnum.dnsStatusInProgress) {
                  setDnsInProgressDomain(siteItem.site);
                  return setShowDnsInProgressModal(true);
                }
                openWordpressLink(siteItem.site);
              },
            });
          }
        };

        getWordpressOnBuilderTools();
        getSitebuilder();
        getWordpress();
        getWHM();
        getCpanel();

        const showWordpressButton = isWordpress || ((isShared || isWordpress) && hasWordpressTool);
        if (showWordpressButton && showSiteBuilderButton && isSiteBuilderPreferenceEnabled) {
          delete links[links.findIndex(link => link.label === useCardActionsLocale.wordpressAction)];
        }

        return links;
      } catch (e) {
        logger.error('Error on useCardActions - method filterCards', e);
      }
    }

    if (!withGatorSolutions) {
      try {
        const links = [];
        const isReseller = siteItem.product_type === 'reseller';
        const isShared = siteItem.product_type === 'shared';
        const isSitebuilder = siteItem.product_type === 'sitebuilder';
        const isWordpress = siteItem.product_type === 'wordpress';
        const hasSiteBuilder = siteItem.builder_tools.sitebuilder.status.toLowerCase() === 'active';
        const hasWordpressTool = siteItem.builder_tools.wordpress.status.toLowerCase() === 'active';

        const showSiteBuilderButton = (isSitebuilder && hasSiteBuilder) || (isShared && hasSiteBuilder) || (isReseller && hasSiteBuilder);

        const isActive = siteItem.status_plan.toLowerCase() === 'active' || siteItem.status_plan.toLowerCase() === 'to_due';

        if (!isActive) {
          return links;
        }

        const getCpanel = () => {
          if ((isShared && !groupSitesCards) || (isShared && groupSitesCards && !accordionItem)) {
            links.push({
              id: `item-${siteItem.external_id}-cPanel`,
              testId: 'button_cPanel',
              label: loadingCPanelSSO && loadingCpanelSSOProductId === siteItem.id ? <IconLoading /> : 'cPanel',
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: 'cPanel',
                  gaEventLabel: '',
                });
                getURLCpanel({
                  externalId: siteItem.external_id,
                  params: null,
                  openInNewTab: true,
                  dispatch,
                  enableCpanelSSO,
                });
                setLoadingCpanelSSOProductId(siteItem.id);
              },
            });
          }
        };

        const getSitebuilder = () => {
          if (showSiteBuilderButton) {
            links.push({
              id: `item-${siteItem.external_id}-sitebuilder`,
              testId: 'button_site_builder',
              label: useCardActionsLocale.websitebuilderAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.websitebuilderAction,
                  gaEventLabel: '',
                });
                window.open(`${useCardActionsLocale.routeWebsitebuilderRedirectAccess}/${siteItem.site}`);
              },
            });
          }
        };

        const getWHM = () => {
          if (isReseller) {
            links.push({
              id: `item-${siteItem.external_id}-reseller`,
              testId: 'button_reseller',
              label: useCardActionsLocale.resellerAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.resellerAction,
                  gaEventLabel: '',
                });
                openInNewTabClick(`${WHMCS_URL}/clientarea.php?action=productdetails&id=${siteItem.external_id}&dosinglesignon=1`);
              },
            });
          }
        };

        const getWordpress = () => {
          if (isWordpress) {
            links.push({
              id: `item-${siteItem.external_id}-wordpress`,
              testId: 'button_wordpress',
              label: useCardActionsLocale.wordpressAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.wordpressAction,
                  gaEventLabel: '',
                });
                openInNewTabClick(`${WHMCS_URL}/custom_includes/wordpress/endpoint.php?action=sso&id=${siteItem.external_id}&domain=${siteItem.site}`);
              },
            });
          }
        };

        const getWordpressOnBuilderTools = () => {
          if ((isShared || isWordpress) && hasWordpressTool) {
            links.push({
              id: `item-${siteItem.external_id}-wordpress-admin`,
              testId: 'button_wordpress_admin',
              label: useCardActionsLocale.wordpressAction,
              icon: null,
              onClick: () => {
                sitesHandlerGa({
                  gaEventAction: useCardActionsLocale.wordpressAction,
                  gaEventLabel: '',
                });
                const domainDnsStatus = allDomainsDnsStatus.find(domain => domain.domain === siteItem.site);
                if (domainDnsStatus && domainDnsStatus.propagation_status === wizardEnum.dnsStatusInProgress) {
                  setDnsInProgressDomain(siteItem.site);
                  return setShowDnsInProgressModal(true);
                }
                openWordpressLink(siteItem.site);
              },
            });
          }
        };

        getWordpressOnBuilderTools();
        getSitebuilder();
        getWordpress();
        getWHM();
        getCpanel();

        const showWordpressButton = isWordpress || ((isShared || isWordpress) && hasWordpressTool);
        if (showWordpressButton && showSiteBuilderButton && isSiteBuilderPreferenceEnabled) {
          delete links[links.findIndex(link => link.label === useCardActionsLocale.wordpressAction)];
        }

        return links;
      } catch (e) {
        logger.error('Error on useCardActions - method filterCards', e);
      }
    }
  }, [
    isSiteBuilderPreferenceEnabled,
    loadingCpanelSSOProductId,
    loadingCPanelSSO,
    dispatch,
    allDomainsDnsStatus,
    openWordpressLink,
    setShowDnsInProgressModal,
    setDnsInProgressDomain,
    groupSitesCards,
    enableCpanelSSO,
    withGatorSolutions,
  ]);

  const validateRules = useCallback(({ action, siteItem }) => {
    try {
      if (withGatorSolutions) {
        const isActiveProduct = siteItem.status.toLowerCase() === 'active';
        const isNotPendingProduct = siteItem.status.toLowerCase() !== 'pending';

        const rules = {
          [useCardActionsEnum.backup]: {
            wordpress: false,
            sitebuilder: false,
            reseller: isNotPendingProduct,
            shared: isNotPendingProduct,
          },
          [useCardActionsEnum.ssl]: {
            wordpress: isActiveProduct,
            sitebuilder: isActiveProduct,
            reseller: isActiveProduct,
            shared: isActiveProduct,
          },
        };

        const productType = () => {
          if (HgProducts.SHARED_ALL.includes(siteItem.package_id)) {
            return 'shared';
          }

          if (HgProducts.RESELLER_ALL.includes(siteItem.package_id)) {
            return 'reseller';
          }

          if (HgProducts.WPHOSTING_ALL.includes(siteItem.package_id)) {
            return 'wordpress';
          }

          if (HgProducts.WPHOSTING_ALL.includes(siteItem.package_id)) {
            return 'wordpress';
          }
        };

        return rules[action][productType()];
      }

      if (!withGatorSolutions) {
        const isActiveProduct = siteItem.status_plan && siteItem.status_plan.toLowerCase() === 'active';
        const isNotPendingProduct = siteItem.status_plan && siteItem.status_plan.toLowerCase() !== 'pending';
        const hasEasyPage = siteItem.builder_tools.webpresence.status.toLowerCase() === 'active';
        const hasSiteBuilder = siteItem.builder_tools.sitebuilder.status.toLowerCase() === 'active';
        const hasSitelock = siteItem.sitelock && siteItem.sitelock.status === SITELOCK_STATUS.ACTIVE;

        const rules = {
          [useCardActionsEnum.backup]: {
            wordpress: false,
            sitebuilder: false,
            reseller: isNotPendingProduct,
            shared: isNotPendingProduct,
          },
          [useCardActionsEnum.easyPage]: {
            wordpress: (isActiveProduct && hasEasyPage),
            sitebuilder: (isActiveProduct && hasEasyPage),
            reseller: (isActiveProduct && hasEasyPage),
            shared: (isActiveProduct && hasEasyPage),
          },
          [useCardActionsEnum.exclude]: {
            wordpress: false,
            sitebuilder: (isActiveProduct && hasSiteBuilder),
            reseller: (isActiveProduct && hasSiteBuilder),
            shared: (isActiveProduct && hasSiteBuilder),
          },
          [useCardActionsEnum.ssl]: {
            wordpress: isActiveProduct,
            sitebuilder: isActiveProduct,
            reseller: isActiveProduct,
            shared: isActiveProduct,
          },
          [useCardActionsEnum.sitelock]: {
            wordpress: isActiveProduct && hasSitelock && isBRServer,
            sitebuilder: isActiveProduct && hasSitelock && isBRServer,
            reseller: isActiveProduct && hasSitelock && isBRServer,
            shared: isActiveProduct && hasSitelock && isBRServer,
          },
        };

        return rules[action][siteItem.product_type];
      }
    } catch (e) {
      logger.error('Error on useCardActions - method validateRules', e);
    }
  }, [withGatorSolutions]);

  const setupLink = useCallback(({ siteItem, action }) => {
    try {
      const link = {
        id: `item-${withGatorSolutions ? siteItem.hosting_id : siteItem.external_id}-${action}`,
        label: '',
        icon: null,
        onClick: () => { },
        variant: 'secondary',
      };

      let shouldDisplayButton = false;
      switch (action) {
        case useCardActionsEnum.backup:
          shouldDisplayButton = validateRules({ action, siteItem });

          if (!shouldDisplayButton) {
            return null;
          }
          link.onClick = () => {
            sitesHandlerGa({
              gaEventAction: 'Backup',
              gaEventLabel: '',
            });
            history.push(`${routesLocale.products}${routesLocale.productdetail}/${siteItem.external_id || siteItem.hosting_id}${routesLocale.backup}/wizard`);
          };
          link.label = useCardActionsLocale.backupSite;
          break;
        case useCardActionsEnum.easyPage:
          shouldDisplayButton = validateRules({ action, siteItem });

          if (!shouldDisplayButton) {
            return null;
          }

          link.target = '_blank';
          link.onClick = () => {
            sitesHandlerGa({
              gaEventAction: 'Página Fácil',
              gaEventLabel: '',
            });
            openInNewTabClick(`${WHMCS_URL}/index.php?m=webpresence&action=edit&hosting_id=${siteItem.external_id}`);
          };
          link.label = useCardActionsLocale.easyPageSite;
          break;
        case useCardActionsEnum.exclude:
          shouldDisplayButton = validateRules({ action, siteItem });

          if (!shouldDisplayButton) {
            return null;
          }

          link.target = '_self';
          link.onClick = () => {
            setSiteInExclusion(siteItem);
            sitesHandlerGa({
              gaEventAction: 'Excluir conteúdo site',
              gaEventLabel: '',
            });
          };
          link.label = useCardActionsLocale.excludeSite;
          break;
        case useCardActionsEnum.manage:
          link.target = '_self';
          link.onClick = () => {
            sitesHandlerGa({
              gaEventAction: 'Gerenciar hospedagem',
              gaEventLabel: '',
            });
            history.push(`${locale('routes.products')}${locale('routes.productdetail')}/${siteItem.external_id}`);
          };
          link.label = useCardActionsLocale.manageSite;
          break;
        case useCardActionsEnum.signatures:
          link.target = '_self';
          link.onClick = () => {
            sitesHandlerGa({
              gaEventAction: 'Ver assinaturas',
              gaEventLabel: '',
            });
            history.push(`${locale('routes.subscriptions')}`);
          };
          link.label = useCardActionsLocale.signaturesSite;
          break;
        case useCardActionsEnum.ssl:
          shouldDisplayButton = validateRules({ action, siteItem });

          if (!shouldDisplayButton) {
            return null;
          }

          link.target = '_blank';
          link.onClick = () => {
            sitesHandlerGa({
              gaEventAction: 'Certificado SSL',
              gaEventLabel: '',
            });
            openInNewTabClick(`${WHMCS_URL}/index.php?m=ssl_addon&action=request1&hosting_id=${siteItem.external_id || siteItem.hosting_id}`);
          };

          link.label = useCardActionsLocale.sslSite;
          break;
        case useCardActionsEnum.sitelock:
          shouldDisplayButton = validateRules({ action, siteItem });
          if (!shouldDisplayButton) {
            return null;
          }

          if (siteItem.status_plan && (siteItem.status_plan === 'in_analysis' || siteItem.status_plan === 'pending' || siteItem.status_plan === 'suspended')) {
            link.variant = 'disabled';
          }

          link.target = '_self';
          link.onClick = () => {
            const route = newSitelock ? `${locale('routes.security')}/gerenciar-produto/standalone/${siteItem.sitelock.id}` : `${locale('routes.security')}/standalone/${siteItem.sitelock.id}`;
            history.push(route);
          };
          link.label = useCardActionsLocale.sitelock;
          break;
        default:
          return null;
      }

      return link;
    } catch (e) {
      logger.error('Error on useCardActions - method setupLink', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, validateRules]);

  const getDropdownActions = useCallback(({ siteItem, grouped }) => {
    try {
      const actionOptions = grouped
        ? ['ssl', 'sitelock', 'backup', 'exclude', 'easyPage', 'signatures']
        : ['ssl', 'sitelock', 'backup', 'exclude', 'easyPage', 'manage', 'signatures'];

      const newGroup = {
        alignItems: 'center',
        list: [],
      };

      actionOptions.forEach((action) => {
        const link = setupLink({ siteItem, action });

        if (link) {
          newGroup.list.push(link);
        }
      });

      return newGroup;
    } catch (e) {
      logger.error('Error on useCardActions - method getDropdownActions', e);
    }
  }, [setupLink]);

  const sortSitesAlphabetically = useCallback((filteredSites) => {
    if (withGatorSolutions) {
      try {
        return filteredSites.sort((a, b) => {
          if (a.domain < b.domain) { return -1; }
          if (a.domain > b.domain) { return 1; }
          return 0;
        });
      } catch (e) {
        logger.error('Error on useCardActions - method getDropdownActions', e);
      }
    }

    if (!withGatorSolutions) {
      try {
        return filteredSites.sort((a, b) => {
          if (a.site < b.site) { return -1; }
          if (a.site > b.site) { return 1; }
          return 0;
        });
      } catch (e) {
        logger.error('Error on useCardActions - method getDropdownActions', e);
      }
    }
  }, []);

  const filterCards = useCallback((filteredOptions, filterTyped, sites, propagationStatus) => {
    if (withGatorSolutions) {
      try {
        if (filterTyped !== '') {
          sitesHandlerGa({
            gaEventAction: 'Campo busca',
            gaEventLabel: '',
          });
        }
        if (filteredOptions && filteredOptions[0] && !filteredOptions[0].checked) {
          const filter = filteredOptions.find(filter => filter.checked);
          sitesHandlerGa({
            gaEventAction: 'Click filtro',
            gaEventLabel: filter.label,
          });
        }

        const shouldRenderAll = filteredOptions.find(filter => filter.value === 'all').checked;
        const activeOption = filteredOptions.find(filterOption => filterOption.checked);

        const optionFilteredSites = [...sites].filter((site) => {
          const sitePropagation = propagationStatus && propagationStatus.find(propagation => propagation.domain === site.domain);
          const sitePropagationNotOk = sitePropagation && ([PROPAGATION_STATUS.NOT_CONTRACTED, PROPAGATION_STATUS.NOT_PROPAGATED].includes(sitePropagation.status));
          let shoudlRender = false;

          if (activeOption.value === 'out') {
            shoudlRender = site.sub_status.toLowerCase() === 'suspended' || sitePropagationNotOk;
          }

          if (activeOption.value === 'pending') {
            shoudlRender = site.sub_status.toLowerCase() === 'pending';
          }

          return shouldRenderAll || shoudlRender;
        });

        const getOnlyMatchedOptions = (sitesList, filter) => {
          if (!filter) {
            return sitesList;
          }

          const cpanelDomainsRemovals = sitesList.map((item) => {
            const hasMatch = item.cpanel_domains.find(domain => domain.domain.toLowerCase().includes(filter.toLowerCase()));
            const newItem = { ...item };
            newItem.cpanel_domains = hasMatch ? [hasMatch] : [];
            return newItem;
          });

          const filteredList = cpanelDomainsRemovals.filter(item => item.cpanel_domains.length);

          return filteredList;
        };

        const filteredSites = withGatorSolutions
          ? getOnlyMatchedOptions(optionFilteredSites, filterTyped)
          : optionFilteredSites.filter(item => item.domain.toLowerCase().includes(filterTyped.toLowerCase()));

        const sorted = sortSitesAlphabetically(filteredSites);

        return sorted;
      } catch (e) {
        logger.error('Error on useCardActions - method filterCards', e);
      }
    }

    if (!withGatorSolutions) {
      try {
        if (filterTyped !== '') {
          sitesHandlerGa({
            gaEventAction: 'Campo busca',
            gaEventLabel: '',
          });
        }
        if (filteredOptions && filteredOptions[0] && !filteredOptions[0].checked) {
          const filter = filteredOptions.find(filter => filter.checked);
          sitesHandlerGa({
            gaEventAction: 'Click filtro',
            gaEventLabel: filter.label,
          });
        }

        const shouldRenderAll = filteredOptions.find(filter => filter.value === 'all').checked;
        const activeOption = filteredOptions.find(filterOption => filterOption.checked);

        const optionFilteredSites = [...sites].filter((site) => {
          const sitePropagation = propagationStatus && propagationStatus.find(propagation => propagation.domain === site.site);
          const sitePropagationNotOk = sitePropagation && (sitePropagation.status === PROPAGATION_STATUS.NOT_CONTRACTED || sitePropagation.status === PROPAGATION_STATUS.NOT_PROPAGATED);
          let shoudlRender = false;

          if (activeOption.value === 'out') {
            shoudlRender = site.status_plan === 'suspended' || site.status_domain === 'suspended' || sitePropagationNotOk;
          }

          if (activeOption.value === 'pending') {
            shoudlRender = site.status_plan === 'pending' || site.status_domain === 'pending';
          }

          return shouldRenderAll || shoudlRender;
        });

        const filteredSites = optionFilteredSites.filter(item => item.site.toLowerCase().includes(filterTyped.toLowerCase()));

        const sorted = sortSitesAlphabetically(filteredSites);

        return sorted;
      } catch (e) {
        logger.error('Error on useCardActions - method filterCards', e);
      }
    }
  }, [sortSitesAlphabetically, withGatorSolutions]);

  const mountDescription = useCallback(({
    filteredCanceledSites,
  }) => {
    try {
      const isEmptyList = filteredCanceledSites.length === 0;
      const hasOtherThanPendingStites = filteredCanceledSites.find((site) => {
        const isPending = site.status_plan === 'pending' || site.status_domain === 'pending';
        const isInAnalysis = site.status_plan === 'in_analysis' || site.status_domain === 'in_analysis';
        return !isPending && !isInAnalysis;
      });
      const hasOnlyPendingSites = hasOtherThanPendingStites === undefined;

      if (isEmptyList) {
        setPageDescriptionTitle(useCardActionsLocale.pageDescription.empty.title);
        setPageDescriptionSubtitle(useCardActionsLocale.pageDescription.empty.subtitle);
      }

      if (!isEmptyList && hasOtherThanPendingStites) {
        setPageDescriptionTitle(null);
        const buttonOnClick = () => {
          bannerNewSiteAnalytics.createNewSiteClick();
          history.push(`${locale('routes.sitesPage')}${locale('routes.sitesCreate')}`);
        };


        setDisplayCreateSiteBanner(true);
        setButton({
          onClick: () => buttonOnClick(),
        });

        setPageDescriptionSubtitle(
          <span>
            <strong>{useCardActionsLocale.pageDescription.hasOtherThanPendingStites.composedSubtitle[0]}</strong>
            {useCardActionsLocale.pageDescription.hasOtherThanPendingStites.composedSubtitle[1]}
            <strong>{useCardActionsLocale.pageDescription.hasOtherThanPendingStites.composedSubtitle[2]}</strong>
            {useCardActionsLocale.pageDescription.hasOtherThanPendingStites.composedSubtitle[3]}
          </span>,
        );
        setPageMaxWidth('485px');
        setButton({
          label: useCardActionsLocale.pageDescription.hasOtherThanPendingStites.buttonLabel,
          title: null,
          onClick: () => buttonOnClick(),
        });
        setDisplayArrow(true);
      }

      if (!isEmptyList && hasOnlyPendingSites) {
        setPageDescriptionTitle(null);
        setPageDescriptionSubtitle(useCardActionsLocale.pageDescription.onlyPendingSites.subtitle);
        setPageMaxWidth('275px');
      }
    } catch (e) {
      logger.error('Error on useCardActions - method mountDescription', e);
    }
  }, [history, bannerNewSiteAnalytics]);

  const sortSitesPerProductSubStatus = useCallback((list) => {
    if (withGatorSolutions) {
      try {
        const finalList = [];
        let newList = [...list];

        newList = newList.filter(site => !['cancelled', 'terminated'].includes(site.sub_status.toLowerCase()));

        const active = newList.filter(site => site.sub_status.toLowerCase() === 'active');
        finalList.push(...active);

        const toDue = newList.filter(site => site.sub_status.toLowerCase() === 'to_due');
        finalList.push(...toDue);

        const overdue = newList.filter(site => site.sub_status.toLowerCase() === 'overdue');
        finalList.push(...overdue);

        const pending = newList.filter(site => site.sub_status.toLowerCase() === 'pending');
        finalList.push(...pending);

        const inAnalysis = newList.filter(site => site.sub_status.toLowerCase() === 'in_analysis');
        finalList.push(...inAnalysis);

        const suspended = newList.filter(site => site.sub_status.toLowerCase() === 'suspended');
        finalList.push(...suspended);

        return finalList;
      } catch (e) {
        logger.error('Error on useCardActions - method sortSitesPerProductSubStatus', e);
        return [];
      }
    }

    if (!withGatorSolutions) {
      try {
        const finalList = [];
        let newList = [...list];

        newList = newList.filter(site => !['cancelled', 'terminated'].includes(site.status_plan.toLowerCase())
          && !['cancelled', 'terminated'].includes(site.substatus_plan.toLowerCase()));

        const active = newList.filter(site => site.status_plan.toLowerCase() === 'active' || site.substatus_plan.toLowerCase() === 'active');
        finalList.push(...active);

        const toDue = newList.filter(site => site.status_plan.toLowerCase() === 'to_due' || site.substatus_plan.toLowerCase() === 'to_due');
        finalList.push(...toDue);

        const pending = newList.filter(site => site.status_plan.toLowerCase() === 'pending' || site.substatus_plan.toLowerCase() === 'pending');
        finalList.push(...pending);

        const inAnalysis = newList.filter(site => site.status_plan.toLowerCase() === 'in_analysis' || site.substatus_plan.toLowerCase() === 'in_analysis');
        finalList.push(...inAnalysis);

        const suspended = newList.filter(site => site.status_plan.toLowerCase() === 'suspended' || site.substatus_plan.toLowerCase() === 'suspended');
        finalList.push(...suspended);

        return finalList;
      } catch (e) {
        logger.error('Error on useCardActions - method sortSitesPerProductSubStatus', e);
        return [];
      }
    }
  }, [withGatorSolutions]);

  const mountNoResultMessage = useCallback((filteredOptions, filterTyped, emptyMessage) => {
    try {
      const filteredOption = filteredOptions.find(filter => filter.checked);
      if (filterTyped !== '') {
        return groupSitesCards ? sitesLocale.page.emptyState : useCardActionsLocale.filter.noResults.field;
      }

      return emptyMessage(filteredOption.label.toLowerCase());
    } catch (e) {
      logger.error('Error on useCardActions - method mountNoResultMessage', e);
    }
  }, [groupSitesCards, sitesLocale.page.emptyState]);

  const groupByExternalId = useCallback((cardList) => {
    if (withGatorSolutions) {
      try {
        const setOfExternalIds = new Set();
        let sitesGroupedByExternalId = [];

        const sortedCardList = sortSitesPerProductSubStatus(cardList);

        sortedCardList.forEach((card) => {
          setOfExternalIds.add(`${card.hosting_id}`);
        });

        const arrayOfExternalIds = Array.from(setOfExternalIds);

        arrayOfExternalIds.forEach((groupHostingId) => {
          const sites = sortedCardList.filter(card => card.hosting_id === Number(groupHostingId));
          sitesGroupedByExternalId.push(sites);
        });

        sitesGroupedByExternalId = putMainDomainToFirstPosition(sitesGroupedByExternalId);

        return sitesGroupedByExternalId;
      } catch (e) {
        logger.error('Error on useCardActions - method groupByExternalId', e);
      }
    }

    if (!withGatorSolutions) {
      try {
        const setOfExternalIds = new Set();
        let sitesGroupedByExternalId = [];

        const sortedCardList = sortSitesPerProductSubStatus(cardList);

        sortedCardList.forEach((card) => {
          setOfExternalIds.add(`${card.external_id}`);
        });

        const arrayOfExternalIds = Array.from(setOfExternalIds);

        arrayOfExternalIds.forEach((groupExternalId) => {
          const sites = sortedCardList.filter(card => card.external_id === Number(groupExternalId));
          sitesGroupedByExternalId.push(sites);
        });

        sitesGroupedByExternalId = putMainDomainToFirstPosition(sitesGroupedByExternalId);

        return sitesGroupedByExternalId;
      } catch (e) {
        logger.error('Error on useCardActions - method groupByExternalId', e);
      }
    }
  }, [putMainDomainToFirstPosition, sortSitesPerProductSubStatus, withGatorSolutions]);

  const clearFilter = () => {
    try {
      setFilterTyped('');
      const optionsWithoutCancelled = filterCanceledSites(defaultFilteredOptions);
      groupByExternalId(optionsWithoutCancelled);
      setFilteredOptions(optionsWithoutCancelled);
      sitesHandlerGa({
        gaEventAction: 'Limpar filtros',
        gaEventLabel: '',
      });
    } catch (e) {
      logger.error('Error on useCardActions - method clearFilter', e);
    }
  };

  return {
    getMainActions,
    getDropdownActions,
    filterCards,
    mountDescription,
    mountNoResultMessage,
    siteInExclusion,
    setSiteInExclusion,
    filteredOptions,
    setFilteredOptions,
    filterTyped,
    setFilterTyped,
    clearFilter,
    pageDescriptionTitle,
    pageDescriptionSubtitle,
    pageMaxWidth,
    button,
    displayArrow,
    displayCreateSiteBanner,
    filterCanceledSites,
    groupByExternalId,
    loadingCpanelSSOProductId,
    setLoadingCpanelSSOProductId,
  };
};

export default useCardActions;
