import React from 'react';
import esContent from './ticket.es';
import { Link } from 'gatorcomponents';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';

export default {
  ...esContent,
  forms: {
    ...esContent.forms,
    internalMigration: {
      ...esContent.forms.internalMigration,
      links: {
        ...esContent.forms.internalMigration.links,
        migration: () => (
          <>
            {'Lea nuestros '}
            <Link
              text="Términos de Migración"
              href="https://www.hostgator.cl/terminos-de-migracion"
              target="_blank"
            />
            .
          </>
        ),
      },
      checkbox: {
        ...esContent.forms.internalMigration.checkbox,
        terms: () => (
          <>
            {'Confirmo que '}
            <strong>he leído y acepto </strong>
            {' los '}
            <Link
              text="Términos de migración."
              target="_blank"
              href="https://www.hostgator.cl/terminos-de-migracion"
            />
          </>
        ),
      },
    },
    installExternalSsl: {
      ...esContent.forms.installExternalSsl,
      checkboxLabel: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $10.800,00 CLP',
      bodyFields: {
        ...esContent.forms.installExternalSsl.bodyFields,
        agree: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $10.800,00 CLP',
      },
    },
    privateSslCertificate: {
      ...esContent.forms.privateSslCertificate,
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(51600.00)} CLP/año`,
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Privado',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(51600.00)} CLP/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
    widlcardSslCertificate: {
      ...esContent.forms.widlcardSslCertificate,
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(51600.00)} CLP/año`,
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Wildcard',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(51600.00)} CLP/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
  },
};
