import {
  REQUEST_ALERTS,
  RECEIVE_ALERTS,
  ERROR_ALERTS,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  loading: false,
  data: [],
};

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALERTS:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case RECEIVE_ALERTS:
      return {
        ...state,
        loading: false,
        data: [...action.data],
      };
    case ERROR_ALERTS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default invoices;
