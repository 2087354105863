import { Modal, Button, Select } from '@/pages/common';
import React, { useContext, useEffect, useState } from 'react';
import { COUNTRY } from '@/config/index';
import * as Styles from './SelectManualDnsModal.styles';
import {
  IconError, IconArrowLeft, IconDropdownArrow, IconChevronRight, IconCopyContent,
} from '@/icons';
import { ThemeContext } from 'styled-components';
import { useResize } from '@/hooks/useResize';
import { IconButton } from '@/pages/common/v1/IconButton';
import { Button as NewButton } from '@/pages/common/v1';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import {
  SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING,
  SUPPORT_ARTICLE_CHANGE_DNS_GODADDY,
  SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST,
  SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB,
  SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX,
  SUPPORT_ARTICLE_CHANGE_DNS_OTHERS,
  SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR,
  SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST,
  SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA,
  GODADDY_HOMEPAGE,
  KINGHOST_HOMEPAGE,
  LOCAWEB_HOMEPAGE,
  REGISTROBR_HOMEPAGE,
  UOLHOST_HOMEPAGE,
  WEBEMPRESA_HOMEPAGE,
  COLOMBIAHOSTING_HOMEPAGE,
  NEUBOX_HOMEPAGE,
} from '@/config/urls/supportUrls';
import { useDispatch } from 'react-redux';
import { commonActions } from '@/redux/modules/common';

const optionsBr = ['Registro.br', 'Godaddy', 'Locaweb', 'Uol Host', 'Kinghost', 'Outros'];
const optionsBrList = optionsBr.map((item, index) => ({ id: index, label: item, value: item }));
const optionsEs = ['Godaddy', 'Web Empresa', 'Colombia Hosting', 'Neubox', 'Otros'];
const optionsEsList = optionsEs.map((item, index) => ({ id: index, label: item, value: item }));

const providersHelpLinks = [
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR, page: REGISTROBR_HOMEPAGE, name: 'Registro.br' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_GODADDY, page: GODADDY_HOMEPAGE, name: 'Godaddy' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB, page: LOCAWEB_HOMEPAGE, name: 'Locaweb' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST, page: UOLHOST_HOMEPAGE, name: 'Uol Host' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST, page: KINGHOST_HOMEPAGE, name: 'Kinghost' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA, page: WEBEMPRESA_HOMEPAGE, name: 'Web Empresa' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING, page: COLOMBIAHOSTING_HOMEPAGE, name: 'Colombia Hosting' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX, page: NEUBOX_HOMEPAGE, name: 'Neubox' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_OTHERS, name: 'Others' },
];

export default function SelectManualDnsModal({
  domain,
  handleCloseModal,
  onContinue,
  domainDns,
  planDns,
  title,
  descriptionOne,
  descriptionTwo,
  question,
  placeholder,
  labelButtonBack,
  labelButtonKeep,
  step2Title,
  step2Description,
  step2TutorialLink,
  step2Replace,
  step2ActualDns,
  step2HgDns,
  step2Server1,
  step2Server2,
  step2GoToHost,
  step2WaitTime,
  step2WaitTimeStrong,
  step2Warning,
  step2LabelButtonBack,
  step2LabelButtonContinue,
  step2Copied,
  onChangeProvider,
  onContinueNextModal,
  onBackStep,
  onClickHelpLink,
  onClickRedirect,
  onCopyServer,
  onContinueNextStep,
}) {
  const theme = useContext(ThemeContext);
  const { palette, color } = theme;
  const [provider, setProvider] = useState({ id: 0, label: '' });
  const [step, setStep] = useState(1);
  const [helpLink, setHelpLink] = useState({});
  const screenSize = useResize();
  const isMobile = screenSize <= 600;

  useEffect(() => {
    providersHelpLinks.forEach((providerLinks) => {
      if (providerLinks.name === provider.label) setHelpLink(providerLinks);
      if (provider.label === 'Outros' || provider.label === 'Otros') setHelpLink(providersHelpLinks[providersHelpLinks.length - 1]);
    });
  }, [provider]);

  const handleContinue = () => {
    onContinue && onContinue();
    onContinueNextStep();
  };

  const goNextStep = () => {
    setStep(2);
    onContinueNextModal();
  };
  const goPrevStep = (action) => {
    setStep(1);
    onBackStep(action);
  };

  const openLink = () => {
    onClickRedirect(provider.label);
    window.open(helpLink.page, '_blank').focus();
  };
  const dispatch = useDispatch();

  const showCopyNotification = () => {
    dispatch(commonActions.notifications.set({
      label: (
        <p>
          {step2Copied}
        </p>
      ),
      type: 'success',
    }));
  };

  const handleBackButton = (action) => {
    goPrevStep(action);
    setProvider({ id: 0, label: '' });
  };

  useEffect(() => {
    if (provider.label !== '') {
      onChangeProvider(provider.label);
    }
  }, [provider, onChangeProvider]);

  if (step === 1) {
    return (
      <Modal maxHeight={330} onClose={() => handleCloseModal(true, 'close')} maxWidth={625} testId="select-dns-modal">
        <Styles.Wrapper step={step}>
          <Styles.Title>
            {' '}
            {title}
            {' '}
          </Styles.Title>
          <Styles.DescriptionWrapper>

            <IconError color={palette.error.main} size={isMobile ? 45 : 26} />
            <Styles.TextDescription>
              {descriptionOne}
              <span>
                &quot;
                {domain}
                &quot;
              </span>
              {descriptionTwo}
            </Styles.TextDescription>

          </Styles.DescriptionWrapper>
          <Styles.SelectWrapper>

            <Styles.TextSelect>
              {question}
            </Styles.TextSelect>
            <Styles.Select>
              <Select
                options={COUNTRY === 'br' ? optionsBrList : optionsEsList}
                customDropdownIcon={<IconDropdownArrow testId="icon-dropdown-arrow-provider" />}
                animatedDropdownIcon
                placeholder={placeholder}
                variant="newStandard"
                testId="provider-select"
                onChange={setProvider}
                maxHeight="120px"
              />
            </Styles.Select>
          </Styles.SelectWrapper>

          <Styles.ButtonWrapper>
            <Styles.BackButtonWrapper>
              <Button
                testId="back-button"
                onClick={() => handleCloseModal(true, 'back')}
                variant="newTertiary"
                iconLeft={<IconArrowLeft color={theme.palette.secondary.primary} />}
                font="secondary"
                size="large"
                label={labelButtonBack}
              />
            </Styles.BackButtonWrapper>
            <Button
              disabled={provider.label === ''}
              testId="continue-button"
              onClick={goNextStep}
              variant="newPrimary"
              font="secondary"
              size="large"
              label={labelButtonKeep}
            />
          </Styles.ButtonWrapper>
        </Styles.Wrapper>
      </Modal>
    );
  }

  if (step === 2) {
    return (
      <Modal onClose={() => goPrevStep('close')} maxWidth={679} maxHeight={675} testId="select-dns-modal-step-2">
        <Styles.Wrapper>
          <Styles.Title>
            {step2Title}
          </Styles.Title>

          <Styles.Text>
            {step2Description}
            {' '}
            <a
              onClick={() => {
                onClickHelpLink(provider.label);
              }}
              href={helpLink.tutorial}
              target="_blank"
              rel="noreferrer"
            >
              {step2TutorialLink}
            </a>
          </Styles.Text>

          <Styles.InstructionSquare>
            <Styles.Text>
              {step2Replace}
            </Styles.Text>

            <Styles.SquareContentWrapper>
              <Styles.SquareDnsColumn>
                <Styles.TextBoldSquare>
                  {step2ActualDns}
                  {provider.label}
                </Styles.TextBoldSquare>
                <Styles.UnderlinedText>
                  {step2Server1}
                </Styles.UnderlinedText>
                <Styles.DnsContainer>
                  <p>
                    {domainDns && domainDns[0]}
                  </p>
                </Styles.DnsContainer>
                <Styles.UnderlinedText>
                  {step2Server2}
                </Styles.UnderlinedText>
                <Styles.DnsContainer>
                  <p>
                    {domainDns && domainDns[1]}
                  </p>
                </Styles.DnsContainer>
              </Styles.SquareDnsColumn>

              <Styles.IconWrapper>
                <IconChevronRight />
              </Styles.IconWrapper>

              <Styles.SquareDnsColumn>
                <Styles.TextBoldSquare>
                  {step2HgDns}
                </Styles.TextBoldSquare>
                <Styles.UnderlinedText>
                  {step2Server1}
                </Styles.UnderlinedText>
                <Styles.DnsContainer>
                  {planDns && planDns[0] && (
                  <>
                    <p>
                      {planDns[0]}
                    </p>
                    {' '}
                    <IconButton
                      icon={<IconCopyContent />}
                      onClick={() => {
                        onCopyServer(1);
                        copyTextToClipboard(planDns[0]);
                        showCopyNotification();
                      }}
                    />
                  </>
                  )}
                </Styles.DnsContainer>
                <Styles.UnderlinedText>
                  {step2Server2}
                </Styles.UnderlinedText>
                <Styles.DnsContainer>
                  {planDns && planDns[1] && (
                    <>
                      <p>
                        {planDns[1]}
                      </p>
                      {' '}
                      <IconButton
                        icon={<IconCopyContent />}
                        onClick={() => {
                          onCopyServer(2);
                          copyTextToClipboard(planDns[1]);
                          showCopyNotification();
                        }}
                      />
                    </>
                  )}
                </Styles.DnsContainer>
              </Styles.SquareDnsColumn>
            </Styles.SquareContentWrapper>

            {helpLink && helpLink.page && (
              <Styles.SquareButtonWrapper>
                <NewButton
                  testId="continue-button"
                  onClick={openLink}
                  label={step2GoToHost}
                  customStyles={{
                    borderRadius: '32px',
                  }}
                />
              </Styles.SquareButtonWrapper>
            )}
          </Styles.InstructionSquare>

          <Styles.DnsWarning>
            <IconError color={color.mustard} size={isMobile ? 45 : 26} />
            <Styles.TextDescription>
              {step2WaitTime}
              <strong>
                {step2WaitTimeStrong}
              </strong>
            </Styles.TextDescription>
          </Styles.DnsWarning>

          <Styles.TextWarningDns>
            <Styles.TextDescription>
              {step2Warning}
            </Styles.TextDescription>
          </Styles.TextWarningDns>

          <Styles.ButtonWrapper>
            <Styles.BackButtonWrapper>
              <Button
                testId="back-button"
                onClick={() => handleBackButton('back')}
                variant="newTertiary"
                iconLeft={<IconArrowLeft color={theme.palette.secondary.primary} />}
                font="secondary"
                size="large"
                label={step2LabelButtonBack}
              />
            </Styles.BackButtonWrapper>
            <NewButton
              disabled={!provider}
              testId="continue-creating-site-button"
              onClick={handleContinue}
              variant="secondary"
              label={step2LabelButtonContinue}
            />
          </Styles.ButtonWrapper>

        </Styles.Wrapper>
      </Modal>
    );
  }
}
