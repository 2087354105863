import getManifestConfig from '@/config/application/pwa/getManifestConfig';
import { onLoadEventTagManager } from '@/utils/ThirdParties/tagManager';


export const initConfigs = (theme) => {
  // Manifest Dynamic
  const dynamicManifest = document.querySelector('#dynamic-manifest');
  if (dynamicManifest) {
    dynamicManifest.setAttribute('href', getManifestConfig());
  }

  // Background Color
  const root = document.getElementById('root');
  if (root) {
    root.style = `background-color: ${
      theme.color.mystic
    }`;
  }

  // Initializating dataLayer
  window.dataLayer = window.dataLayer || [];
  onLoadEventTagManager();
};
