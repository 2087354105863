import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { withI18n } from 'react-i18next';

import './styles.css';


const CreditCardWrapper = ({ t, classes, ...rest }) => (
  <Cards
    locale={{ valid: t('creditcard.label.date') }}
    {...rest}
  />
);

export default withI18n()(CreditCardWrapper);
