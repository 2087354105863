import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';


const NumberMaskedInput = ({ inputRef, maxLength, ...rest }) => (
  <MaskedInput
    {...rest}
    placeholderChar=" "
    pipe={rawValue => rawValue.trim()}
    mask={new Array(maxLength).fill(/\d/)}
  />
);

NumberMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default NumberMaskedInput;
