import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.v2.colors.feedback.activePure};
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    margin-top: 48px;
    width: 56px;
  `}
`;

export const ContentWrapper = styled.div`
  align-self: center;
  margin-top: 24px;
`;

export const ModalTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 32px;
    margin: 0px 0px 24px 0px;
    text-align: center;
  `}
`;

export const ModalText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 16px;
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 0;
    text-align: center;
  `}
`;

export const FooterWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 24px 0px 0px;

  & button {
    height: 48px;
    border-radius: 24px;
    width: 100%;
  }

  & button:first-child {
    margin-bottom: 6px;
  }
`;
