import React from 'react';
import { withI18n } from 'react-i18next';
import { useParams } from 'react-router';
import EmailsContextProvider from '@/contexts/Emails/context';
import TitanHirePlanWrapper from './TitanHirePlanWrapper';


const TitanHirePlan = () => {
  const { domain } = useParams();

  return (
    <EmailsContextProvider>
      <TitanHirePlanWrapper domain={domain} />
    </EmailsContextProvider>
  );
};

export default withI18n()(TitanHirePlan);
