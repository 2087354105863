import { Modal } from '@/pages/common';
import { Button, IconCheck } from 'gatorcomponents';
import React from 'react';
import * as Styles from './UpgradeModal.styles';

export const UpgradeModal = ({
  title = 'Obrigado por assinar o SiteLock Professional!',
  description = 'A remoção automática de ameaças e os outros recursos do seu novo plano já estão sendo configuradas na sua conta. Veja abaixo os principais:',
  ListItems = [
    'Proteção para até 100 páginas',
    'Remoção automática de Malwares',
    'Verificação diária de FTP',
    'Verificação de edição de arquivos',
    'Monitoramento de blocklist',
    'Todos os recursos do Grátis',
  ],
  buttonLabel = 'Ir para página do SiteLock',
  handleRedirect,
}) => {
  const handleGoToSitelock = () => {
    handleRedirect && handleRedirect();
  };

  return (
    <Modal maxWidth={800}>
      <Styles.IconWrapper>
        <IconCheck color="activeMedium" size="xl" />
      </Styles.IconWrapper>

      <Styles.ModalTitle>
        {title}
      </Styles.ModalTitle>

      <Styles.ModalDescription>
        {description}
      </Styles.ModalDescription>

      <Styles.List>
        {ListItems.map(item => (
          <Styles.ListItem key={item}>
            <IconCheck color="primaryMedium" size="md" />
            {item}
          </Styles.ListItem>
        ))}
      </Styles.List>

      <Styles.ButtonWrapper>
        <Button label={buttonLabel} width="fit-content" size="large" onClick={handleGoToSitelock} />
      </Styles.ButtonWrapper>
    </Modal>
  );
};

export default UpgradeModal;
