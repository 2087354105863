import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
${() => css`
    margin-top: 24px;
`}
`;

export const BannerWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.blue.light};
    box-shadow: 0px 2px 5px #0000001a;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding:  8px 0px 24px 0px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 30px;
    }

    .assetTriangle {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 24px;
      .assetTriangle {
        left: auto;
        right: 8px;
        transform: rotate(90deg);
      }
    }
  `}
`;

export const Figure = styled.figure`
  ${({ theme }) => css`
  margin-left:24px;
  margin-right:24px;
    position: relative;
    .envelope {
      position: relative;
      width: 40px;
      height: 40px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0px;
      margin-top: 0px;
    }
  `}
`;

export const ContentSection = styled.section`
  padding:0;
`;

export const BannerTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top:24px;

    p {
      margin: 0px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 0 20px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.ultradark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    font-family: 'Galano';
    letter-spacing: 0.01px;
    margin: 0px;
    opacity: 1;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.ultradark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    font-family: 'Galano';
    letter-spacing: 0.01px;
    margin: 0px;
    opacity: 1;
  `}
`;

export const TagBanner = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.palette.green.dark};
    color: ${theme.palette.white.light};
    font-size: ${theme.font.sizes.xxs};
    font-family: 'Galano';
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    margin-left: 12px;
    padding: 4px 8px 4px 8px;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-self: flex-start;
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 0;
    }
  `}
`;


export const BannerDescription = styled.div`
  ${({ theme }) => css`
    margin-bottom:0px;
    margin-left:24px;
    margin-right:24px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
      margin-right:0px;
    }
  `}
`;

export const SelectContainer = styled.div`
  ${({ theme }) => css`
    width: 280px;
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;
