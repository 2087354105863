import { push } from '@/utils/ThirdParties/tagManager';

export { billingAnalytics } from './billing';
export { domainsAnalytics } from './domains';
export { sitesBuilderAnalytics } from './sitesBuilder';
export { sitesAnalytics } from './sites';
export { costumerPortalSurveyAnalytics } from './costumerPortalSurvey';
export { createSitesWithSpecialistAnalytics } from './createSitesWithSpecialist';
export { backupAnalytics } from './backup';
export { invoicesAnalytics } from './invoices';
export { supportAnalytics } from './support';
export { upgradeTitanAnalytics } from './upgradeTitan';

export const collect = data => push(data, false);
