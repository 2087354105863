import styled, { css } from 'styled-components';

export const ProductListWrapper = styled.ul`
  padding: 0;
`;

export const Product = styled.li`
  list-style: none;
`;

export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    margin: -9px 24px 0px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const ProductsListWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    font-family: ${theme.v2.font.family.primary};
    margin: 16px 24px;
    padding: 26px 24px 60px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 16px 0px;
      padding: 10px 10px 60px 24px;
    }
  `}
`;

export const ProductsListLoading = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductData = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 85px;
    padding-left: 7px;
    padding-right: 15px;

    svg {
      margin-right: 15px;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      gap: 0
    }
  `}
`;
export const ProductIdentify = styled.div`
  display: flex;
  flex: 1;
`;

export const ProductTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const ProductDomain = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-size: ${theme.v2.font.sizes.xxxs};
    margin-top: 7px;
    margin-bottom: 0px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: 360px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      width: 148px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

export const TagWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 110px;

  @media (max-width: 350px) {
    margin-left: 40px;
    margin-top: 20px;
  }
`;

export const ProductListDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};

    strong {
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;

export const DueDate = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 20px 0 20px 43px;
      width: 100%;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 118px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 43px;
      width: 100%;
    }
  `}
`;

export const DueText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-size: ${theme.v2.font.sizes.xxxs};
    margin-top: 7px;
    margin-bottom: 0px;
  `}
`;

export const Divider = styled.hr`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin: 24px 0px 16px;
  `}
`;
