import {
  Card, Typography, withStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { withI18n } from 'react-i18next';
import { isBRServer, isMobile } from '@/utils/Validators/validation';
import IconTitan from '@/media/icons/logoTitan.svg';
import IconCpanel from '@/media/icons/logoCpanel.svg';
import InactiveBlock from './InactiveBlock';
import CardStatus from './CardStatus';
import ProEmailMenuMobilePlans from './CardStatus/ProEmailPlan/ProEmailMenuMobilePlans';
import ActiveBlock from './ActiveBlock';
import styles from './styles';
import { HOSPEDAGEM_COLABORADORES } from '@/components/Products/HgProducts';
import { ALPHA_PLAN_TYPE_CHOICE_IN_EMAIL_LIST } from '@/config/GrowthBook/constants';
import LinkButton from '@/components/Buttons/LinkButton';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ProEmailCard = ({
  classes,
  proEmail,
  listEmailDomains,
  t,
  reactivateAutoRenovationCycle,
}) => {
  const isStartPlan = proEmail.packageid === 437;
  const daysTrial = differenceInCalendarDays(parseISO(proEmail.regdate), new Date()) + 30;
  const isTrialPlan = proEmail.billingcycle === 'Free Account';
  const isProEmailInactive = proEmail.status.toLowerCase() !== 'active' && proEmail.status.toLowerCase() !== null;
  const plans = ['null', 'pending', 'provisioning', 'suspended', 'terminated', 'expired'];
  const tolerenceDaysInEs = -7;
  const tolerenceDaysInBr = -10;
  const toleranceDays = isBRServer ? tolerenceDaysInBr : tolerenceDaysInEs;
  const isToDueDateNotTrial = proEmail.nextduedate != null ? differenceInCalendarDays(parseISO(proEmail.nextduedate), new Date()) <= 9 && ['active', 'suspended'].includes(proEmail.status) : false; const isCPanelFreePending = proEmail.type === 'cpanel' && proEmail.status === 'pending';
  const maxDaysTrialToDueDate = 10;
  const minDaysTrialToDueDate = 0;
  const isToDueDate = isTrialPlan ? daysTrial <= maxDaysTrialToDueDate && daysTrial > minDaysTrialToDueDate && proEmail.status !== 'terminated' : isToDueDateNotTrial;
  const dateDiff = proEmail.nextduedate ? differenceInCalendarDays(parseISO(proEmail.nextduedate), new Date()) : 0;
  const almostOverdue = proEmail.packageid !== HOSPEDAGEM_COLABORADORES && isTrialPlan ? daysTrial <= 0 && proEmail.status !== 'terminated' : (dateDiff < 0 && ['active', 'suspended'].includes(proEmail.status));
  const almostExcluded = proEmail.packageid !== HOSPEDAGEM_COLABORADORES && (dateDiff < toleranceDays && ['active', 'suspended'].includes(proEmail.status));
  const enableEmailServiceChoice = useFeatureIsOn(ALPHA_PLAN_TYPE_CHOICE_IN_EMAIL_LIST);

  const shouldRenderCreateFirstAccountButton = false;
  const notDisplayNumberAccountsUsed = proEmailTmp => ((proEmailTmp.status === 'terminated' || proEmailTmp.status === 'pending') || proEmail.status === 'suspended' || almostExcluded);
  const displayNumberAccountsUsed = proEmailTmp => ((proEmailTmp) && proEmailTmp.status !== 'terminated' && proEmailTmp.status !== 'pending' && proEmailTmp.status !== 'suspended' && !almostExcluded);
  const displayButtonCreateFirstAccount = proEmailTmp => ((proEmailTmp)
  && !isMobile
  && proEmailTmp.used_accounts === 0
  && proEmailTmp.status !== 'terminated'
  && proEmailTmp.status !== 'pending');

  const verifyAutomaticRenovationDisabled = () => {
    if (proEmail.type_cancel === undefined) {
      return true;
    }

    return !reactivateAutoRenovationCycle ? true : (proEmail.type_cancel === null || isTrialPlan);
  };

  const isAutomaticRenovationDisabled = verifyAutomaticRenovationDisabled;

  const handlerIsConfigured = proEmail => (
    proEmail.configured ? proEmail.configured : plans.includes(proEmail.status.toLowerCase())
  );

  const shouldSetService = (proEmail) => {
    const numberOfInboxes = parseInt(proEmail.used_accounts, 10);
    return (
      enableEmailServiceChoice
      && (!almostExcluded)
      && (!proEmail.domain_configured)
      && (
        !proEmail.configured
        || (numberOfInboxes < 1)
      )
    );
  };

  return (
    <Card
      elevation={1}
      className={
        classnames(classes.container, classes.card, classes[`${proEmail.plan !== null ? proEmail.status.toLowerCase() : 'null'}CardStatus`],
          {
            [classes.almostCanceladCardStatus]: almostOverdue,
            [classes.uponDueDateProEmailStatus]: isToDueDate,
          })}
      data-testid="proEmailCard"
    >
      <div className={classes.divMain}>
        <div
          className={!isCPanelFreePending ? classes.cardContainer : classes.cardContainerFreePending}
        >
          <CardStatus
            className={classes.divContentLogo}
            proEmail={proEmail}
            dateDiff={dateDiff}
            almostOverdue={almostOverdue}
            almostExcluded={almostExcluded}
            isAutomaticRenovationDisabled={isAutomaticRenovationDisabled}
            daysTrial={daysTrial}
          />

          {!isMobile && proEmail.configured && (

          <div className={classes.divContentLogo}>
              {(!isCPanelFreePending) && (

              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  popper: classes.customPopper,
                }}
                placement="top"
                title={proEmail.type === 'cpanel' ? t('proEmail.cPanelIconInfo') : t('proEmail.titanIconInfo')}
              >
                <div className={classes.divLogos}>
                  <div className={classes.imgLogo}>
                    <img src={proEmail.type === 'cpanel' ? IconCpanel : IconTitan} alt={proEmail.type === 'cpanel' ? t('proEmail.cPanelLabel') : t('proEmail.titanLabel')} className={classes.logo} />
                  </div>
                  <div className={classes.divLogoTitle}>
                    <Typography className={classes.logoTitle}>
                      {proEmail.type === 'cpanel' ? t('proEmail.cPanelLabel') : t('proEmail.titanLabel')}
                    </Typography>
                  </div>
                </div>
              </Tooltip>
              )}
          </div>
          )}
        </div>
        <div>
          <Table
            className={classnames(classes.table)}
          >
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell classes={{ root: classes.tableHeadCellDomain }}>
                  {t('proEmail.domainLabel')}
                </TableCell>
                <TableCell className={classes.tableHeadCell}>{t('proEmail.mailBox')}</TableCell>
                <TableCell className={classes.tableHeadCellAction}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {listEmailDomains && listEmailDomains.map(proEmailTmp => (
                <TableRow key={proEmail.id} className={classes.tableRow}>
                  <TableCell className={classes.tableCellDomain} component="th" scope="row">
                    <Typography
                      className={classnames(
                        classes.textCell,
                        {
                          [classes.textCellPending]: (proEmailTmp.status === 'pending' || almostExcluded),
                          [classes.textCellOverDueDate]: isTrialPlan && almostOverdue,
                        },
                      )}
                      data-testid="proEmailAccountUsed"
                    >
                      {`@  ${proEmailTmp.domain}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">

                    {handlerIsConfigured(proEmailTmp) && (
                      <div
                        className={classnames(classes.proEmailItem, classes.proEmailItemAccountUsed)}
                        data-testid="proEmailAccountAmountBlock"
                      >
                        {displayNumberAccountsUsed(proEmailTmp)
                      && (shouldRenderCreateFirstAccountButton || !shouldSetService(proEmailTmp))
                      && (
                      <Typography
                        className={classnames(
                          classes.textCell,
                          {
                            [classes.textCellPending]: (proEmailTmp.status === 'pending' || almostExcluded),
                            [classes.textCellOverDueDate]: isTrialPlan && almostOverdue,
                          },
                        )}
                        data-testid="proEmailAccountUsed"
                      >
                        {proEmailTmp.plan === 'free' || proEmailTmp.type === 'cpanel' || proEmail.packageid === TRIAL_PLAN
                          ? proEmailTmp.used_accounts
                          : `${proEmailTmp.used_accounts}/${proEmailTmp.total_accounts} `}

                        {(shouldRenderCreateFirstAccountButton || !shouldSetService(proEmailTmp))
                        && displayButtonCreateFirstAccount(proEmailTmp)
                        && (
                        <LinkButton
                          data-testid="buttonCreateFirstAccount"
                          className={classes.createFirstAccount}
                          to={proEmailTmp.type !== 'cpanel'
                            ? `${t('routes.emailsList')}${t('routes.createFirstEmailsTitan')}/${proEmailTmp.domain}`
                            : `${t('routes.emails')}${t('routes.createEmail')}/${proEmailTmp.domain}`}
                        >
                          {t('proEmail.createFirstAccount')}
                        </LinkButton>
                        )}
                      </Typography>
                      )}

                        { (notDisplayNumberAccountsUsed(proEmailTmp)
                      || !(shouldRenderCreateFirstAccountButton || !shouldSetService(proEmailTmp)))
                      && (
                      <Typography
                        className={classes.proEmailItemPlan}
                        data-testid="proEmailAccountUsed"
                      >
                        {isStartPlan ? '0 / 3' : '-'}
                      </Typography>
                      )}
                      </div>

                    )}
                    { !handlerIsConfigured(proEmailTmp) && (
                      <Typography className={classes.textCell}>
                        {isStartPlan ? '0 / 3' : '-'}
                      </Typography>
                    )}

                  </TableCell>
                  {!isMobile && (
                    <TableCell className={classes.tableCellAction} align="right">

                      {isProEmailInactive
                    && (
                    <InactiveBlock
                      domain={proEmailTmp.domain}
                      hostingId={proEmail.hosting_id}
                      idProEmail={proEmailTmp.id}
                      plan={proEmailTmp.plan}
                      status={proEmailTmp.status}
                      type={proEmailTmp.type}
                    />
                    )}

                      {!isProEmailInactive
                    && (
                    <ActiveBlock
                      almostExcluded={almostExcluded}
                      almostOverdue={almostOverdue}
                      domain={proEmailTmp.domain}
                      idProEmail={proEmailTmp.id}
                      isConfiguredAccounts={handlerIsConfigured(proEmailTmp)}
                      plan={proEmailTmp.plan}
                      proEmail={proEmailTmp}
                      status={proEmailTmp.status}
                      type={proEmailTmp.type}
                      isTrialPlan={isTrialPlan}
                      shouldSetService={shouldSetService(proEmailTmp)}
                      customerEmail={proEmailTmp.customer_email}
                    />
                    )}

                    </TableCell>
                  )}

                  {isMobile && (
                    <TableCell className={classes.tableCellAction} align="right">
                      {proEmailTmp.status !== 'pending'
                    && proEmailTmp.status !== 'suspended'
                    && ((isTrialPlan && !almostOverdue) || (!isTrialPlan && almostOverdue) || (proEmail.status === 'active' && !almostOverdue)) && (
                      <ProEmailMenuMobilePlans
                        proEmail={proEmailTmp}
                        domain={proEmailTmp.domain}
                        isProEmailInactive={isProEmailInactive}
                        isConfiguredAccounts={handlerIsConfigured(proEmailTmp)}
                        almostExcluded={almostExcluded}
                        almostOverdue={almostOverdue}
                        type={proEmailTmp.type}
                        shouldSetService={shouldSetService(proEmailTmp)}
                        isTrialPlan={isTrialPlan}
                        customerEmail={proEmailTmp.customer_email}
                      />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

ProEmailCard.propTypes = {
  proEmail: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(ProEmailCard));
