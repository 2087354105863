import styled, { css } from 'styled-components';

export const FaqV2Wrapper = styled.div`
  ${({ theme, width }) => css`
    width: ${width === 'half' ? '50%' : 'full'};

    @media (max-width: ${theme.breakpoints.sm}){
      width: 100%;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    box-shadow: 0px 1px 3px 0px ${theme.color.black}29;
    margin-bottom: 40px;
    margin: 0px;
    position: relative;
    width: 50%;

    @media (max-width: ${theme.breakpoints.sm}){
      width: 100%;
    }

    * {
      font-family: ${theme.font.family.secondary};
    }
  `}
`;

export const FaqWrapper = styled.div`
  padding: 0px;
`;

export const FaqTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 32px;
    margin: 0;
    padding: 17px 24px 0px;
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.grey.primary};
    margin: 8px auto 18px;
    max-width: calc(100% - 48px);
    padding: 0px 24px 0px;
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  padding: 0 24px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.sizes.sm};
    list-style-type: none;
    margin-bottom: 12px;

    & > a {
      color: ${theme.palette.secondary.primary};
      text-decoration-color: transparent;
      transition: text-decoration-color 150ms ease-in-out;
    }

    & > a:hover {
      color: ${theme.palette.secondary.primary};
      text-decoration-color: ${theme.palette.secondary.primary};
    }

    & svg {
      fill: transparent;
      margin-left: 6px;
      stroke: transparent;
      transition: fill 150ms ease-in-out, stroke 150ms ease-in-out;
    }

    &:hover {
      svg {
        fill: ${theme.palette.secondary.primary};
        stroke: ${theme.palette.secondary.primary};
      }
    }
  `}
`;

export const FaqFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.sizes.sm};
    justify-content: flex-start;
    padding: 0px 24px 30px;

    & > a {
      color: ${theme.palette.primary.main};
      text-decoration-color: transparent;
      transition: text-decoration-color 150ms ease-in-out;
    }

    & > a:hover {
      color: ${theme.palette.primary.main};
      text-decoration-color: ${theme.palette.primary.main};
    }

    & svg {
      fill: transparent;
      margin-left: 6px;
      stroke: transparent;
      transition: fill 150ms ease-in-out, stroke 150ms ease-in-out;
    }

    &:hover {
      svg {
        fill: ${theme.palette.primary.main};
        stroke: ${theme.palette.primary.main};
      }
    }
  `}
`;
