import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './UserBackupRestore.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconInfo,
  Input, Select,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';
import Loader from '@/components/Layout/Loader';
import { useSelector } from 'react-redux';

export const UserBackupRestore = ({
  selectedProduct,
  submitForm,
  opening,
}) => {
  const {
    forceState, rules,
  } = useSelector(state => state.formsReducer);
  const { ticket: ticketsLocale } = useLocale();
  const { userBackupRestore } = ticketsLocale.forms;
  const restorationOptions = [
    { value: 'database', label: userBackupRestore.databaseRestoration },
    { value: 'files', label: userBackupRestore.filesRestoration },
    { value: 'complete', label: userBackupRestore.completeRestoration },
  ];
  const [restorationType, setRestorationType] = useState(null);
  const [backupFileName, setBackupFileName] = useState('');
  const [databaseFileName, setDatabaseFileName] = useState('');
  const [userName, setUserName] = useState('');
  const [server, setServer] = useState('');
  const [domain, setDomain] = useState('');
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (selectedProduct && selectedProduct.domain) {
      setUserName(selectedProduct.username);
      setServer((selectedProduct.serverhostname || selectedProduct.dedicatedip) || '');
      setDomain(selectedProduct.domain);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (forceState && rules.active === 'userBackupRestore') {
      setRestorationType(rules.userBackupRestore.selectedRestorationType);
    }
  }, [forceState, rules.active, rules.userBackupRestore.selectedRestorationType]);


  const checkForm = useCallback(() => {
    let isOk = false;

    const checkDatabase = () => {
      if (restorationType === userBackupRestore.databaseRestoration) {
        return databaseFileName.length;
      }

      return true;
    };

    isOk = !!agreed && restorationType && backupFileName.length && checkDatabase();
    return isOk;
  }, [
    agreed,
    restorationType,
    backupFileName,
    databaseFileName,
    userBackupRestore,
  ]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const bodyDatabase = `${userBackupRestore.databaseFileName}: ${databaseFileName}`;

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: userBackupRestore.name,
      key: V2Forms.USER_BACKUP_RESTORE,
      tags: ['aut-bkp-customer-file', 'fluxo_backup'],
      root: {
        name: 'techsupport',
        as: userBackupRestore.name,
      },
      body: `
      ${userBackupRestore.selectedProduct}: ${selectedProduct.name} - ID: ${selectedProduct.id}
      ${userBackupRestore.serverLabel}: ${server}
      ${userBackupRestore.domainRestorarion}: ${domain}
      ${userBackupRestore.userLabel}: ${userName}
      ${userBackupRestore.restorationType}: ${restorationType}
      ${restorationType === userBackupRestore.databaseRestoration ? bodyDatabase : ''}
      ${userBackupRestore.backupFileName}: ${backupFileName}
      ${userBackupRestore.agree}: ${agreed ? userBackupRestore.yes : userBackupRestore.no}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    userBackupRestore,
    agreed,
    selectedProduct,
    submitForm,
    backupFileName,
    databaseFileName,
    restorationType,
    domain,
    server,
    userName,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {ticketsLocale.forms.terms}
      </Styles.Info>

      <Styles.Warning>
        <Styles.WarningList>
          {userBackupRestore.warning.item[0]}
          {userBackupRestore.warning.item[1]}
          {userBackupRestore.warning.item[2]}
          {userBackupRestore.warning.item[3]}
        </Styles.WarningList>
      </Styles.Warning>

      <Styles.Alert>
        <IconInfo color="problemPure" />
        <Styles.AlertText>
          {userBackupRestore.warning.title}
          <Styles.AlertDescription>
            {userBackupRestore.alert}
          </Styles.AlertDescription>
        </Styles.AlertText>
      </Styles.Alert>

      <Styles.Title>
        {userBackupRestore.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Select
          name="restoration-type"
          testId="restoration-type"
          placeholder={userBackupRestore.restorationType}
          label={userBackupRestore.restorationType}
          setValue={label => setRestorationType(label)}
          value={restorationType}
          options={restorationOptions}
          withScroll
        />

        <Input
          name="user"
          testId="user"
          placeholder={userBackupRestore.userLabel}
          label={userBackupRestore.user}
          value={userName}
          onChange={e => setUserName(e.target.value)}
        />

        <Input
          name="server"
          testId="server"
          placeholder={userBackupRestore.serverLabel}
          label={userBackupRestore.server}
          value={server}
          onChange={e => setServer(e.target.value)}
        />

        <Input
          name="domain"
          testId="domain"
          placeholder={userBackupRestore.domainLabel}
          label={userBackupRestore.domain}
          value={domain}
          onChange={e => setDomain(e.target.value)}
        />

        <Input
          name="backup-file-name"
          testId="backup-file-name"
          label={userBackupRestore.fileNameInputLabel}
          placeholder={userBackupRestore.fileNameInputPlaceholder}
          onChange={e => setBackupFileName(e.target.value)}
          value={backupFileName}
        />

        {restorationType === userBackupRestore.databaseRestoration && (
          <Input
            name="database-file-name"
            testId="database-file-name"
            label={userBackupRestore.databaseNameInputLabel}
            placeholder={userBackupRestore.databaseNameInputPlaceholder}
            onChange={e => setDatabaseFileName(e.target.value)}
            value={databaseFileName}
          />
        )}
      </Styles.InputWrapper>

      <Styles.CheckboxWrapper>
        <Styles.CheckboxLabel
          htmlFor="#agreed"
          onClick={() => setAgreed(!agreed)}
          data-testid="check-agreed"
        >
          <Checkbox checked={agreed} id="agreed" />
          <Styles.Text>
            {userBackupRestore.checkboxAgreed}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.CheckboxWrapper>

      <Styles.ButtonWrapper>
        <Button
          label={opening ? <Loader size="12" /> : ticketsLocale.forms.submitLabel}
          disabled={!checkForm() || opening}
          size="large"
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default UserBackupRestore;
