const styles = theme => ({

  manageLinkButtonDisabled: {
    color: theme.palette.grey.light,
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s',

  },

  btActive: {
    alignItems: 'center',
    background: theme.color.indigo,
    borderRadius: '2px',
    color: theme.color.white,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    height: '40px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    paddingLeft: '16px',
    paddingRight: '16px',
    textDecoration: 'none',
    textTransform: 'uppercase',

    '&:hover': {

      backgroundColor: theme.color.chambray,
      border: `1px solid ${theme.color.chambray}`,
      borderRadius: '2px',
      color: theme.color.white,
    },
  },

  proEmailItem: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: '195px',

    [theme.breakpoints.down(1285)]: {
      marginBottom: '24px',
      minWidth: 'unset',
      width: '100',
    },
  },

  proEmailItemAccountUsed: {
    marginLeft: 'auto',
    paddingRight: 48,

    [theme.breakpoints.down(1285)]: {
      minWidth: '195px',
    },

    [theme.breakpoints.down(745)]: {
      marginLeft: 'unset',
    },

    [theme.breakpoints.down(443)]: {
      maxWidth: '64%',
      minWidth: 'unset',
    },
  },

  proEmailExpiringDate: {
    minWidth: '183px',

    [theme.breakpoints.down(1285)]: {
      minWidth: '195px',
    },

    [theme.breakpoints.down(443)]: {
      maxWidth: '64%',
      minWidth: 'unset',
      paddingRight: 0,
    },
  },

  proEmailItemTitle: {
    color: theme.color.regentGray,
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginBottom: '5px',
  },

  proEmailItemPlan: {
    color: theme.color.tundora,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '17px',
  },

  proEmailActions: {
    alignSelf: 'center',

    [theme.breakpoints.down(1285)]: {
      width: '100%',
    },
  },

  manageLinkButton: {
    color: theme.color.indigo,
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s',

    '&:hover': {
      color: theme.color.chambray,
      textDecoration: 'underline',
    },
  },

  activeBlock: {
    display: 'inline-block',
  },

  activeBlockLink: {
    display: 'inline-block',
    minWidth: '241px',
  },
});

export default styles;
