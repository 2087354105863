import styled, { css } from 'styled-components';

export const AcademyPassFilterHandlerWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white.light} 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px ${theme.shadows.grey.light};
    margin-top: 0px;
    padding: 12px 24px;
  `}
`;
