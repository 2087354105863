import React from 'react';
import {
  Typography,
  withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { videoEmbedUrl } from '@/config/urls/academypass';
import styles from './styles';

const CtaVideo = ({ classes, t }) => (
  <div data-testid="cta-video" className={classes.wrapper}>
    <Typography className={classes.headerTitle}>{t('academypass.cta.video.title')}</Typography>
    <Typography className={classes.headerSubtitle}>{t('academypass.cta.video.subtitle')}</Typography>
    <iframe className={classes.video} src={videoEmbedUrl} title={t('academypass.cta.video.iframeTitle')} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
  </div>
);
export default withI18n()(withStyles(styles, { withTheme: true })(CtaVideo));
