import React from 'react';
import * as Styles from './AsaasFirstRow.styles';
import AsaasLogo from '@/media/asaas/asaas-logo.svg';
import AsaasDetails from '@/media/asaas/Assas-lp-details.svg';
import AsaasWizardDetails from '@/media/asaas/Asaas-wizard-details.svg';
import {
  FormStepper,
} from '@/pages/common/v1';
import { FormOpenAccount } from '../FormOpenAccount';
import { asaasEnum } from '../../AsaasHandler.enum';
import { FormMainData } from '../FormMainData';
import { FormConclusion } from '../FormConclusion';
import { HelpLinks } from '../HelpLinks';

const AsaasFirstRow = ({
  testId = 'asaas-first-row',
  title = 'First row title',
  description = 'description',
  imageItems = [
    'item 0',
    'item 1',
    'item 2',
  ],
  descriptionImageAlt = 'descriptionImageAlt',
  formData,
  onChange,
  onContinue,
  loadingCep,
  enabledFormOpenAccountContinue,
  enabledMainDataContinue,
  onGoback,
  onSubmit,
  resetForm,
  cnpjTypes,
  callformStepTwoMyCEP,
  accountCreated,
  isInsideWizard = false,
}) => {
  const shouldDisplayHelpLinks = !isInsideWizard && formData.activeStep === asaasEnum.formStepper.FORM_CONCLUSION;
  const descriptionImage = isInsideWizard ? AsaasWizardDetails : AsaasDetails;

  const formOpenAccountProps = () => {
    const defaultProps = {
      formData,
      onChange,
      onContinue,
      enabledFormOpenAccountContinue,
      cnpjTypes,
      isInsideWizard,
    };

    return defaultProps;
  };

  const formMainDataProps = () => {
    const defaultProps = {
      formData,
      onChange,
      onContinue,
      loadingCep,
      enabledMainDataContinue,
      onGoback,
      callformStepTwoMyCEP,
    };

    return defaultProps;
  };

  const formConclusionProps = () => {
    const defaultProps = {
      formData,
      resetForm,
      accountCreated,
      isInsideWizard,
    };

    return defaultProps;
  };

  const helpLinksProps = () => {
    const defaultProps = {
      formData,
      accountCreated,
    };

    return defaultProps;
  };

  const handleSubmit = (e) => {
    onSubmit && onSubmit(e);
  };

  return (
    <Styles.FirstRowWrapper isInsideWizard={isInsideWizard}>
      <Styles.LeftColumn>
        <Styles.Logo isInsideWizard={isInsideWizard} src={AsaasLogo} alt="Asaas Logo" />
        <Styles.Title isInsideWizard={isInsideWizard} data-testid={`${testId}-title`}>{title}</Styles.Title>
        <Styles.Description isInsideWizard={isInsideWizard} data-testid={`${testId}-description`}>{description}</Styles.Description>
        <Styles.AsaasDescriptionImage isInsideWizard={isInsideWizard} src={descriptionImage} alt={descriptionImageAlt} data-testid={`${testId}-descriptionImage`} />
      </Styles.LeftColumn>

      <Styles.RightColumn isInsideWizard={isInsideWizard}>
        <Styles.Form onSubmit={handleSubmit} isInsideWizard={isInsideWizard}>
          <Styles.StepperWrapper>
            <FormStepper items={formData.stepItems} />
          </Styles.StepperWrapper>

          {formData.activeStep === asaasEnum.formStepper.OPEN_ACCOUNT && (
            <FormOpenAccount {...formOpenAccountProps()} />
          )}

          {formData.activeStep === asaasEnum.formStepper.MAIN_DATA && (
            <FormMainData {...formMainDataProps()} />
          )}

          {formData.activeStep === asaasEnum.formStepper.FORM_CONCLUSION && (
            <FormConclusion {...formConclusionProps()} />
          )}
        </Styles.Form>

        {shouldDisplayHelpLinks && (
          <HelpLinks {...helpLinksProps()} />
        )}
      </Styles.RightColumn>

      <Styles.DescriptionList data-testid={`${testId}-imageDescription`}>
        {imageItems.map(item => <Styles.DescriptionItem key={item} isInsideWizard={isInsideWizard}>{item}</Styles.DescriptionItem>)}
      </Styles.DescriptionList>
    </Styles.FirstRowWrapper>
  );
};

export default AsaasFirstRow;
