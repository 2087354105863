import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const HowToBackground = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.brand.primaryLowLight};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding:  32px;
  `}
`;

export const HowToTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.primaryExtraDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const Steps = styled.ol`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 16px 0px;
    padding: 0px;
    counter-reset: item;
    list-style: none;
  `}
`;

export const StepItem = styled.li`
  ${({ theme }) => css`
      align-items: flex-start;
      counter-increment: item;
      display: flex;
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.xxs};
      font-weight: ${theme.v2.font.weights.regular};
      gap: 16px;
      justify-content: flex-start;
      max-width: calc(484px + 26px + 24px);
      
      &:before {
        align-items: center;
        background: ${theme.v2.colors.brand.primaryExtraDark};
        blex-basis: 24px;
        border-radius: 100%;
        color: ${theme.v2.colors.neutral.highPure};
        content: counter(item);
        display: flex;
        flex-shrink: 0;
        font-weight: ${theme.v2.font.weights.bold};
        height: 24px;
        justify-content: center;
        width: 24px;
    }
  `}
`;

export const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StepDescription = styled.span`
    ${({ theme }) => css`
        color: ${theme.v2.colors.neutral.lowPure};
        font-family: ${theme.v2.font.family.primary};
        font-size: ${theme.v2.font.sizes.xxs};
        font-weight: ${theme.v2.font.weights.regular};
        line-height: ${theme.v2.font.lineHeight.list};
        margin: 0px;
    `}
`;

export const StepAlertWrapper = styled.div`
    display: flex;
    
    > div {
     padding: 0px;
    }
`;

export const StepDnsBox = styled.div`
    ${({ theme }) => css`
      background-color: ${theme.v2.colors.brand.primaryUltraLight};
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 16px;
    `}
`;

export const StepDnsBoxRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const StepDnsRow = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 16px;
    width: fit-content;
  `}
`;

export const StepLabel = styled.span`
    ${({ theme }) => css`
        color: ${theme.v2.colors.neutral.lowMedium};
        font-family: ${theme.v2.font.family.primary};
        font-size: ${theme.v2.font.sizes.xxs};
        font-weight: ${theme.v2.font.weights.regular};
        line-height: ${theme.v2.font.lineHeight.list};
    `}
`;

export const StepValue = styled.span`
    ${({ theme }) => css`
        color: ${theme.v2.colors.neutral.lowDark};
        font-family: ${theme.v2.font.family.primary};
        font-size: ${theme.v2.font.sizes.xxs};
        font-weight: ${theme.v2.font.weights.medium};
        line-height: ${theme.v2.font.lineHeight.list};
    `}
`;

export const ButtonWrapper = styled.div`
    display: block;
    padding: 0px 0px 0px 40px;
`;


export const ActionsRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: flex-end;
    margin: 32px 0px 0px;
`;
