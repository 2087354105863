import { IconDetailedSiteBuilder } from '@/icons/';
import React from 'react';
import * as Styles from './WizardTools.styles';
import { WizardWondersuiteToolCard } from '../WizardWondersuiteToolCard';

const WizardTools = ({
  title = [
    'my ',
    '"title" ',
    'sample',
  ],
  subtitle = 'subtitle sample',
  tools = [
    {
      featured: true,
      icon: <IconDetailedSiteBuilder />,
      title: 'SiteBuilder',
      items: [
        <>Item sample</>,
      ],
    },
  ],
  showMoreText = 'show more',
  showLessText = 'show less',
  siteType,
  onToolCardClick,
  onWoocommerceHelpMaterialClick,
  shouldRenderEcommercePlusCard,
  requestAutoSsl,
}) => {
  const getWizardWondersuiteToolCardProps = ({ tool }) => {
    const defaultProps = {
      tool,
      showMoreText,
      showLessText,
      key: tool.title,
      siteType,
      testId: tool.testId,
      onToolCardClick,
      onWoocommerceHelpMaterialClick,
      requestAutoSsl,
    };

    return {
      ...defaultProps,
    };
  };

  return (
    <>
      <Styles.Wrapper>
        <Styles.Title data-testid="title">
          {title[0]}
          <span>{title[1]}</span>
          {title[2]}
        </Styles.Title>

        <Styles.Subtitle data-testid="subtitle">
          {subtitle}
        </Styles.Subtitle>

        <Styles.CardsWrapper shouldRenderEcommercePlusCard={shouldRenderEcommercePlusCard}>
          {tools.map(tool => (
            <WizardWondersuiteToolCard {...getWizardWondersuiteToolCardProps({ tool })} />
          ))}
        </Styles.CardsWrapper>
      </Styles.Wrapper>
    </>
  );
};

export default WizardTools;
