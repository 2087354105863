export default {
  sites: 'Meus sites',
  sitesPage: 'Sites',
  sitesCreate: 'Criar site',
  sitesPlans: 'Sites e Planos',
  cancelRenewal: 'Cancelar Renovação',
  cancelSignature: 'Cancelar Assinatura',
  manageSubscription: 'Gerenciar Assinatura',
  domains: 'Domínios',
  emails: 'E-mails',
  products: 'Produtos',
  billing: 'Faturas',
  invoices: 'Faturas',
  subscriptions: 'Assinaturas',
  unpaid: 'Faturas Pendentes',
  paid: 'Faturas',
  cancelled: 'Faturas',
  domain: 'Gerenciar Domínio',
  product: 'Gerenciar Produto',
  creditCard: 'Gerenciar Cartão de Crédito',
  help: 'Suporte',
  support: 'Suporte',
  supportforms: 'Abrir chamado',
  ticket: 'Chamado',
  dns: 'Alterar DNS',
  changePassword: 'Alterar Senha',
  createEmail: 'Criar Conta de E-mail',
  '404Page': 'Erro 404',
  courses: 'Cursos',
  mktPlace: 'Parceiros HostGator',
  security: 'Segurança',
  sitelock: 'SiteLock',
  emailsTitan: 'Gerenciar',
  titanUpgrade: 'Upgrade de Plano',
  titanInboxUpgrade: 'Gerenciar Plano',
  titanHire: 'Contratar Plano de E-mail',
  titanHireFreePlan: 'Contratar Novo Plano de E-mail',
  titanHirePlanActiveCPanel: 'Plataforma de E-mail Grátis',
  academyPass: 'Hostgator Academy',
  codeguard: 'CodeGuard',
  asaas: 'Asaas',
  v2: {
    sitesPage: 'Sites',
    sitesCreate: 'Criar site',
    emails: 'E-mails',
    setEmailPlatform: 'Definir Plataforma de E-mail',
    manageEmailsTitan: 'Gerenciar E-mail Titan',
    manageEmailsCpanel: 'Gerenciar E-mail cPanel',
    domains: 'Domínios',
    manageDomain: 'Gerenciar Domínio',
    setupDomain: 'Configurar Domínio',
    hostsAndserver: 'Hospedagens e Servidores',
    manageHostOrServer: 'Gerenciar Hospedagem ou Servidor',
    securityAndBackup: 'Segurança e Backup',
    manageSiteLock: 'Gerenciar SiteLock',
    upgradeSiteLock: 'Alterar Plano do SiteLock',
    manageCodeGuard: 'Gerenciar CodeGuard',
    subscribeSitelock: 'Assinar SiteLock',
    subscribeCodeGuard: 'Assinar CodeGuard',
    toolsAndServices: 'Ferramentas e Serviços',
    courses: 'Cursos',
    subscribeAcademyPass: 'Assinar Academy Pass',
    invoice: 'Faturas',
    subscription: 'Assinaturas',
    manageSubscriptions: 'Gerenciar Assinatura',
    cancelSubscription: 'Cancelar Assinatura',
    manageCreditCard: 'Gerenciar Cartão de Crédito',
    support: 'Suporte',
    openTicket: 'Abrir Novo Chamado',
    seeTicket: 'Ver Chamado',
    myData: 'Meus Dados',
    changePassword: 'Alterar Senha',
    accountSecurity: 'Segurança da Conta',
    asaas: 'Asaas',
    linkNaBio: 'Link na bio',
    dataAnalysis: 'Análise de dados do Link na Bio',
    cloudflare: 'Desempenho de sites',
  },
};
