import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { creditCardType } from '@/types';
import CreditCardWrapper from '../CreditCardWrapper';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_PAYMENT_METHOD_MODAL, CHARLIE_CREDIT_CARDV2 } from '@/config/GrowthBook/constants';
import * as Styles from './CreditCardPreview.styles';
import { Button, IconLoading, Skeleton } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import snappyWithShadow from '@/media/billing/snappy-with-shadow.svg';
import { billingActions } from '@/redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_STEPS } from '@/pages/CreditCard/components/ChangePaymentModal/ChangePaymentModal.types';
import { useResize } from '@/hooks/useResize';

const CreditCardPreview = ({
  creditCard,
  t,
  onRemoveCreditCard,
  setDisplayChangePaymentModal,
  hideButtons = false,
  dialogTemplate,
  setModalStep,
}) => {
  const designV2 = useFeatureIsOn(CHARLIE_CREDIT_CARDV2);
  const dispatch = useDispatch();
  const shouldHandleNewCreditCardDispatch = useFeatureIsOn(CHARLIE_CHANGE_PAYMENT_METHOD_MODAL);
  const summary = useSelector(state => state.summary);
  const { billing: billingLocale } = useLocale();
  const { creditCard: creditCardLocale } = billingLocale;
  const billingState = useSelector(state => state.billing);
  const itemsToChangePaymentMethod = billingState.subscriptions.itemsNotInCreditcard;
  const windowRect = useResize();
  const { width } = windowRect;
  const isMobile = width < 600;

  const handleShowModal = useCallback(() => {
    setModalStep(MODAL_STEPS.SELECT_PRODUCTS);
    setDisplayChangePaymentModal(true);
  }, [setDisplayChangePaymentModal, setModalStep]);

  const handleRemove = () => {
    onRemoveCreditCard && onRemoveCreditCard();
  };

  const checkProducts = useCallback(() => {
    shouldHandleNewCreditCardDispatch && dispatch(billingActions.subscriptions.requestSubscriptionsNotInCreditCard({ userId: summary.id }));
  }, [dispatch, shouldHandleNewCreditCardDispatch, summary.id]);

  useEffect(() => {
    checkProducts();
  }, [checkProducts]);

  if (designV2) {
    return (
      <>
        <Styles.PreviewWrapper id="PreviewWrapper" withItems={itemsToChangePaymentMethod.length || !billingState.subscriptions.loadedItemsNotInCreditcard}>
          <Styles.CreditCardRow>
            <Styles.CreditCardColumnWrapper>
              <CreditCardWrapper
                number={creditCard.cardLastFour}
                name={creditCard.ccname}
                preview
                expiry={creditCard.expDate}
                cvc={creditCard.ccvnumber}
                issuer={creditCard.cardType}
                focused="name"
                placeholders={{ name: t('creditcard.placeholder.name') }}
              />

              <Button
                variant="secondary"
                label={creditCardLocale.changeCreditCard}
                onClick={handleRemove}
                testId="changeCard"
                size="large"
                width="fit"
              />
            </Styles.CreditCardColumnWrapper>

            {!!(billingState.subscriptions.loadedItemsNotInCreditcard
              && !billingState.subscriptions.loadingItemsNotInCreditCard
              && itemsToChangePaymentMethod.length > 0
            ) && (
              <Styles.ChangePaymentInfo>
                <Styles.Image src={snappyWithShadow} />

                <Styles.Content>
                  <Styles.ChangePaymentInfoTitle>
                    {creditCardLocale.changeMethodTitle}
                  </Styles.ChangePaymentInfoTitle>
                  <Styles.ChangePaymentInfoDescription>
                    {creditCardLocale.changeMethodDescription}
                  </Styles.ChangePaymentInfoDescription>

                  <Styles.ButtonWrapper>
                    <Button
                      label={billingState.subscriptions.loadingItemsNotInCreditCard ? (
                        <>
                          <IconLoading />
                          {creditCardLocale.useThis}
                        </>
                      ) : creditCardLocale.useThis}
                      testId="showModalCta"
                      onClick={handleShowModal}
                    />
                  </Styles.ButtonWrapper>
                </Styles.Content>

              </Styles.ChangePaymentInfo>
            )}

            {!!billingState.subscriptions.loadingItemsNotInCreditCard && (
              <Skeleton variant="rectangle" height="200px" width={isMobile ? undefined : '736px'} />
            )}
          </Styles.CreditCardRow>
        </Styles.PreviewWrapper>
      </>
    );
  }

  return (
    <Grid container justify="center" spacing={24}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={24}>
          <Grid
            item
            xs={dialogTemplate ? 5 : 12}
            style={{
              padding: '5px 8px 0px 10px',
            }}
          >
            <CreditCardWrapper
              number={creditCard.cardLastFour}
              name={creditCard.ccname}
              preview
              expiry={creditCard.expDate}
              cvc={creditCard.ccvnumber}
              issuer={creditCard.cardType}
              focused="name"
              placeholders={{ name: t('creditcard.placeholder.name') }}
            />
          </Grid>
          {dialogTemplate && (
          <Grid item xs={7}>
            <Typography variant="subtitle1">{creditCard.cardLastFour}</Typography>
            <Typography variant="caption">
              {`${t('creditCardExpDate')} ${creditCard.expDate}`}
            </Typography>
          </Grid>
          )}
        </Grid>
      </Grid>

      {!hideButtons && (
      <Grid item xs={11} container justify="center">

        <OutlineButton onClick={onRemoveCreditCard}>{t('removeCreditCard')}</OutlineButton>

      </Grid>
      )}
    </Grid>
  );
};

CreditCardPreview.propTypes = {
  creditCard: creditCardType.isRequired,
  t: PropTypes.func.isRequired,
  onRemoveCreditCard: PropTypes.func.isRequired,
};

export default withI18n()(CreditCardPreview);
