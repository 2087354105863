
import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';


const EmptyList = ({ classes, t }) => {
  const { clearedRecordedItems } = useContext(DNSContext);

  const hasItems = clearedRecordedItems.length > 0;
  return (
    hasItems
      ? (
        <>
          <Typography className={classes.featuredMessage} data-testid="emptyMessage1">{t('dns.zoneManager.recordList.emptySearch')}</Typography>
          <Typography className={classes.message}>{t('dns.zoneManager.recordList.emptySearch_2')}</Typography>
        </>
      )
      : (
        <>
          <Typography className={classes.featuredMessage} data-testid="emptyMessage1">{t('dns.zoneManager.recordList.emptyMessage')}</Typography>
          <Typography className={classes.message}>{t('dns.zoneManager.recordList.emptyMessage_2')}</Typography>
        </>
      )
  );
};


export default (withI18n()((withStyles(styles)(EmptyList))));
