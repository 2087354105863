import React, { useState } from 'react';
import { Alert } from 'gatorcomponents';
import * as Styles from './DomainPropagationAlert.styles';
import { useSelector } from 'react-redux';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import ModalDomainDnsPropagationAlert from '../ModalDomainDnsPropagationAlert';
import analytics from '@/analytics/domains/domains.analytics';
import ModalDomainNotContracted from '../ModalDomainNotContracted';
import ModalDomainNotConfigured from '../ModalDomainNotConfigured';

const DomainPropagationAlert = () => {
  const { alerts: alertsLocale } = useLocale();
  const domainsNotPropagated = useSelector(state => state.newDomains && state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_PROPAGATED));
  const domainInPropagation = useSelector(state => state.newDomains && state.newDomains.propagationStatus && state.newDomains.propagationStatus.find(propagation => propagation.status === PROPAGATION_STATUS.IN_PROGRESS));
  const domainsNotContracted = useSelector(state => state.newDomains && state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONTRACTED));
  const domainsNotConfigured = useSelector(state => state.newDomains && state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONFIGURED));

  const [showModalDnsPropagationAlert, setShowModalDnsPropagationAlert] = useState(false);
  const [showModalDomainNotContracted, setShowModalDomainNotContracted] = useState(false);
  const [showModalDomainNotConfigured, setShowModalDomainNotConfigured] = useState(false);

  if (!domainsNotPropagated.length && !domainInPropagation && !domainsNotContracted.length && !domainsNotConfigured.length) {
    return null;
  }

  const handleShowModalDnsPropagationAlert = () => {
    setShowModalDnsPropagationAlert(!showModalDnsPropagationAlert);
  };

  const handleShowModalDomainNotContracted = () => {
    setShowModalDomainNotContracted(!showModalDomainNotContracted);
  };

  const handleShowModalDomainNotConfigured = () => {
    setShowModalDomainNotConfigured(!showModalDomainNotConfigured);
  };

  const alertNotPropagated = {
    description: alertsLocale.domainNotPropagated({
      handleClick: () => {
        analytics.dnsWizard.dnsUnpointedDomain();
        handleShowModalDnsPropagationAlert();
      },
    }),
    variant: 'error',
  };

  const alertInPropagation = {
    description: alertsLocale.domainInPropagation({
      handleClick: () => {
        analytics.dnsWizard.dnsPropagationUnderstandTerm();
      },
    }),
    variant: 'timed',
  };

  const alertNotContracted = {
    description: alertsLocale.domainNotContracted({
      handleClick: () => {
        analytics.dnsWizard.bannerDomainNotContracted();
        handleShowModalDomainNotContracted();
      },
    }),
    variant: 'error',
  };

  const alertNotConfigured = {
    description: alertsLocale.domainNotConfigured({
      handleClick: () => {
        analytics.dnsWizard.buttonHowToConfigure();
        handleShowModalDomainNotConfigured();
      },
    }),
    variant: 'error',
  };

  return (
    <Styles.Container>
      {domainsNotPropagated && !!domainsNotPropagated.length && (
        <Alert
          width="full"
          variant={alertNotPropagated.variant}
          description={alertNotPropagated.description}
        />
      )}
      {domainInPropagation && (
        <Alert
          testId="domain-in-progress-alert"
          width="full"
          variant={alertInPropagation.variant}
          description={alertInPropagation.description}
        />
      )}
      {domainsNotContracted && !!domainsNotContracted.length && (
        <Alert
          width="full"
          variant={alertNotContracted.variant}
          description={alertNotContracted.description}
        />
      )}
      {domainsNotConfigured && !!domainsNotConfigured.length && (
        <Alert
          width="full"
          variant={alertNotConfigured.variant}
          description={alertNotConfigured.description}
        />
      )}
      {showModalDnsPropagationAlert && <ModalDomainDnsPropagationAlert hideModal={handleShowModalDnsPropagationAlert} />}
      {showModalDomainNotContracted && <ModalDomainNotContracted hideModal={handleShowModalDomainNotContracted} />}
      {showModalDomainNotConfigured && <ModalDomainNotConfigured hideModal={handleShowModalDomainNotConfigured} />}
    </Styles.Container>
  );
};

export default withI18n()((DomainPropagationAlert));
