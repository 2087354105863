const CLIENTID_BY_COUNTRY = {
  br: '250001',
  mx: '251001',
  cl: '252001',
  co: '253001',
};

export const getClientIdByCountry = country => CLIENTID_BY_COUNTRY[country];

/**
 * Iframe securepay prod
 * @type {string}
 */
export const IFRAME_PROD = 'https://securepay.svcs.endurance.com';

/**
 * Iframe securepay sandbox
 * @type {string}
 */
export const IFRAME_SANDBOX = 'https://qa.securepay.svcs.endurance.com';

/**
 * Iframe securepay pagename
 * @type {string}
 */
export const IFRAME_PAGENAME = 'hglatam.html';
