import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Tabs, Tab, Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import SwipeableViews from 'react-swipeable-views';
import { loadInvoices, loadInvoicesWithParams } from '@/redux/actions/invoices';
import { loadUnpaidInvoices } from '@/redux/actions/unpaidInvoices';
import PageTitle from '@/components/PageTitle';
import FaqInvoicesPage from '@/components/FrequentlyQuestions/FaqInvoicesPage';
import FilterInvoices from '@/components/Filters/FilterInvoices';
import UnpaidInvoiceItem from '@/components/Invoices/UnpaidInvoiceItem';
import InvoiceItem from '@/components/Invoices/InvoiceItem';
import FilterCard from '@/components/Cards/FilterCard';
import LinkButton from '@/components/Buttons/LinkButton';
import ActionButton from '@/components/Buttons/ActionButton';
import { INVOICES_STATUS, INVOICES_STATUS_WITHOUT_CAPITALIZE } from '@/config/invoices/invoiceStatus';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import { PaymentSuccessModal } from '@/components/billing';
import { PAYMENT_STATUS } from './types';
import styles from './styles';
import { SplitPaymentOfferModal } from '@/components/Invoices/SplitPaymentOfferModal';
import { setCookie, getCookie } from '@/utils/Application/cookies';
import { isBRServer } from '@/utils/Validators/validation';
import { PixOfferPopup } from '@/components/Pix/PixOfferPopup';
import { differenceInCalendarDays } from 'date-fns';
import { InvoiceHeader } from '@/pages/billing/invoices/InvoiceHeader';
import PageMessages from '../common/PageMessages';
import { ModalFrozenDomain } from '@/components/billing/subscriptions/ModalFrozenDomain';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import {
  XRAY_ENABLED_HEADER_INVOICE_PAGE,
  XRAY_ENABLED_NEW_LAYOUT_INVOICE_PAGE,
  XRAY_LIMIT_DATE_TO_DISPLAY_INVOICE_SPLIT_MESSAGE,
  XRAY_LIMIT_DATE_TO_DISPLAY_SPLIT_PAYMENT_BANNER,
} from '@/config/GrowthBook/constants';
import InvoiceCard from '@/components/Invoices/InvoiceCards';
import InvoiceFilter from '@/components/Invoices/InvoiceFilterPage/InvoiceFilterPage';
import { Button, Skeleton } from 'gatorcomponents';
import { BannerInvoiceSplit } from '../billing/invoices/BannerInvoiceSplit';

class InvoicesPage extends Component {
  tabTitles = {
    [INVOICES_STATUS.UNPAID]: 'unpaidInvoicesPageSubtitle',
    [INVOICES_STATUS.PAID]: 'paidInvoicesPageSubtitle',
    [INVOICES_STATUS.CANCELLED]: 'cancelledInvoicesPageSubtitle',
  }

  emptyTabTitles = {
    [INVOICES_STATUS.UNPAID]: 'unpaidInvoicesEmptyPageSubtitle',
    [INVOICES_STATUS.PAID]: 'paidInvoicesEmptyPageSubtitle',
    [INVOICES_STATUS.CANCELLED]: 'cancelledInvoicesEmptyPageSubtitle',
  }

  constructor(props) {
    super(props);

    const {
      t,
      match,
      setCurrentInvoiceStatus,
    } = this.props;
    let invoiceStatus;
    loadReCaptcha();

    switch (match.params.tab) {
      case t('routes.unpaid'):
        invoiceStatus = INVOICES_STATUS_WITHOUT_CAPITALIZE.UNPAID;
        break;
      case t('routes.paid'):
        invoiceStatus = INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID;
        break;
      case t('routes.cancelled'):
        invoiceStatus = INVOICES_STATUS_WITHOUT_CAPITALIZE.CANCELLED;
        break;
      default:
        break;
    }

    setCurrentInvoiceStatus(invoiceStatus);

    this.state = {
      currentTab: INVOICES_STATUS.UNPAID,
      filter: {
        statusValue: '',
        invoiceId: '',
      },
      visible: 50,
      indexTab: 0,
      emptySwipeTabTitles: [
        INVOICES_STATUS.UNPAID,
        INVOICES_STATUS.PAID,
        INVOICES_STATUS.CANCELLED,
      ],
      payment: {
        status: PAYMENT_STATUS.HIDDEN,
        productType: null,
      },
      showSplitPaymentOfferModal: true,
      showPixOffer: true,
      loading: false,
      statusSelected: 'all',
    };
  }

  componentDidMount() {
    let currentTabState;
    let indexTab;
    const {
      t,
      match,
      loadInvoices,
      loadUnpaidInvoices,
    } = this.props;

    switch (match.params.tab) {
      case t('routes.unpaid'):
        currentTabState = INVOICES_STATUS.UNPAID;
        indexTab = 0;
        break;
      case t('routes.paid'):
        currentTabState = INVOICES_STATUS.PAID;
        indexTab = 1;
        break;
      case t('routes.cancelled'):
        currentTabState = INVOICES_STATUS.CANCELLED;
        indexTab = 2;
        break;
      default:
        break;
    }

    this.setState({
      currentTab: currentTabState,
      indexTab,
      filter: {
        statusValue: '',
        invoiceId: match.params.invoiceId ? match.params.invoiceId : '',
      },
    });

    loadInvoices(currentTabState);
    loadUnpaidInvoices();
  }

  componentDidUpdate(_prevProps) {
    const {
      match,
      t,
    } = this.props;

    if ((_prevProps
      && _prevProps.match
      && _prevProps.match.params
      && _prevProps.match.params.invoiceId !== match.params.invoiceId)
      || (_prevProps
        && _prevProps.match
        && _prevProps.match.params
        && _prevProps.match.params.tab !== match.params.tab)
    ) {
      let currentTabState;
      let indexTab;

      switch (match.params.tab) {
        case t('routes.unpaid'):
          currentTabState = INVOICES_STATUS.UNPAID;
          indexTab = 0;
          break;
        case t('routes.paid'):
          currentTabState = INVOICES_STATUS.PAID;
          indexTab = 1;
          break;
        case t('routes.cancelled'):
          currentTabState = INVOICES_STATUS.CANCELLED;
          indexTab = 2;
          break;
        default:
          break;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentTab: currentTabState,
        indexTab,
        filter: {
          statusValue: '',
          invoiceId: match.params.invoiceId ? match.params.invoiceId : '',
        },
      });
    }
  }

  handleTabChange = (_, newTab) => {
    const { emptySwipeTabTitles } = this.state;
    this.setState(
      {
        currentTab: emptySwipeTabTitles[newTab],
        filter: {
          statusValue: '',
          invoiceId: '',
        },
        visible: 5,
        indexTab: newTab,
      },
      () => this.onLoadInvoices(),
    );
  }

  handleChangeIndex = (index) => {
    const { emptySwipeTabTitles } = this.state;
    this.setState(
      {
        indexTab: index,
        visible: 5,
        currentTab: emptySwipeTabTitles[index],
      },
      () => {
        this.onLoadInvoices();
        this.handleChangeUrlWithTraduction();
      },
    );
  }

  handleChangeUrlWithTraduction = () => {
    let url = '';
    const { indexTab } = this.state;
    const { t } = this.props;

    switch (parseInt(indexTab, 10)) {
      case 0:
        url = t('routes.unpaid');
        break;
      case 1:
        url = t('routes.paid');
        break;
      case 2:
        url = t('routes.cancelled');
        break;
      default:
        break;
    }

    window.history.replaceState({}, null, url);
  }

  onLoadInvoices = () => {
    const { loadInvoices } = this.props;
    const { currentTab } = this.state;

    loadInvoices(currentTab);
  }

  getTotalUnpaidInvoices = (invoices) => {
    let total = 0;

    const {
      setTotalInvoicesUnpaid,
    } = this.props;

    const financialPendingStatus = [
      'unpaid',
      'unpaidExpired',
      'pendingPayment',
      'pending',
      'overdue',
      'toDue'];

    invoices && invoices.forEach((invoice) => {
      if (financialPendingStatus.includes(invoice.statusMessage)) {
        total += 1;
      }
    });
    setTotalInvoicesUnpaid(total);
  }

  onLoadInvoiceWithParams = (search, status, page = null) => {
    const { loadInvoicesWithParams, invoicesByStatus } = this.props;
    const invoices = invoicesByStatus.all ? invoicesByStatus.all : null;

    const hideLoading = () => {
      this.setState({
        loading: false,
      });
    };

    loadInvoicesWithParams(status, search, page, invoices, hideLoading);
  }

  onChangeChip = (chip) => {
    this.setState({
      statusSelected: (chip),
    });

    this.onLoadInvoiceWithParams('', chip);
  }

  onExecuteAction = () => {
    this.onLoadInvoiceWithParams('', 'all');
  }

  inputChange = itemKey => (e) => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        [itemKey]: e.target.value,
      },
      visible: 10,
    });
  }

  filterInvoices = (invoicesByStatus) => {
    const { filter } = this.state;
    let filterInvoices;

    if (filter.statusValue !== '') {
      filterInvoices = invoicesByStatus.filter(invoice => invoice.statusMessage === filter.statusValue);
    } else {
      filterInvoices = invoicesByStatus;
    }
    if (filter.invoiceId !== null) {
      filterInvoices = filterInvoices.filter(invoice => invoice.id.toString().includes(filter.invoiceId));
    }
    return filterInvoices;
  }

  loadMore = (length) => {
    this.setState(prev => ({
      visible: prev.visible + (length - prev.visible),
    }));
  }

  loadMoreInBackend = () => {
    const {
      invoicesByStatus,
      paginationData,
    } = this.props;

    const { statusSelected } = this.state;

    this.setState({
      loading: true,
    });

    if (invoicesByStatus[statusSelected].length <= paginationData[statusSelected].total) {
      const pageNumber = paginationData[statusSelected].next_page_url ? paginationData[statusSelected].next_page_url.split('=')[1] : null;
      this.onLoadInvoiceWithParams('', statusSelected, pageNumber);
    } else {
      this.setState({
        loading: false,
      });
    }
    this.getTotalUnpaidInvoices(invoicesByStatus.all);
  }

  renderLoadMoreButton = () => {
    const {
      invoicesByStatus,
      paginationData,
    } = this.props;
    const { statusSelected } = this.state;

    this.getTotalUnpaidInvoices(invoicesByStatus[statusSelected]);
    return paginationData[statusSelected] && paginationData[statusSelected].to !== paginationData[statusSelected].total;
  }

  handleTotal = (tab) => {
    const { invoicesByStatus } = this.props;
    let total;

    switch (tab) {
      case 'Unpaid':
        total = invoicesByStatus.Unpaid.length;
        break;
      case 'Paid':
        total = invoicesByStatus.Paid.length;
        break;
      case 'Cancelled':
        total = invoicesByStatus.Cancelled.length;
        break;
      default:
        break;
    }

    return total;
  }

  setPaymentStatus = async ({ status }) => {
    this.setState({
      payment: {
        status,
      },
    });
  }

  handleOnPaymentModalClose = () => {
    this.setState({
      payment: {
        status: PAYMENT_STATUS.HIDDEN,
      },
    });
  };

  handleOnSplitPaymentsModalClose = () => {
    this.setState({
      showSplitPaymentOfferModal: false,
    });
    setCookie('hg-modal-split-payment', false);
  };

  handleOnPixOfferPopupClose = () => {
    this.setState({
      showPixOffer: false,
    });
    setCookie('hg-popup-pix', false);
  };

  handleOnPixOfferPopupLinkClick = () => {
    const {
      t,
    } = this.props;

    window.open(t('pixOfferPopupKnowMoreURL'), '_blank');
  }

  render() {
    const {
      currentTab,
      visible,
      indexTab,
      emptySwipeTabTitles,
      filter,
      payment,
      showSplitPaymentOfferModal,
      loading,
      showPixOffer,
    } = this.state;

    const {
      theme,
      t,
      classes,
      invoicesByStatus,
      paginationData,
      selectFilters,
      invoicesLoadingUnpaid,
      invoicesLoadingPaid,
      invoicesLoadingCancelled,
      width,
      match,
      enabledInvoiceSplitPaymentBanner,
      limitDateToDisplayPopupPixLabel,
      enabledHeaderInvoicePage,
      enabledInvoiceSplitMessage,
      holdDomainRedirect,
      enabledNewLayoutInvoicePage,
      setInvoices,
      invoices,
      totalInvoicesUnpaid,
      invoicesLoading,
      currentInvoiceStatus,
    } = this.props;

    const isLoading = invoicesLoading
    || invoicesLoadingUnpaid
    || invoicesLoadingPaid
    || invoicesLoadingCancelled;

    const cookiePopupSplitPayment = getCookie('hg-modal-split-payment') !== 'false';
    const cookiePopupPix = getCookie('hg-popup-pix') !== 'false';
    const enablePixPopup = limitDateToDisplayPopupPixLabel != null && differenceInCalendarDays(new Date(`${limitDateToDisplayPopupPixLabel} 00:00:00`), new Date()) > 0;

    const titlePage = invoicesByStatus[emptySwipeTabTitles[indexTab]].length < 1 && !enabledNewLayoutInvoicePage && (
      <PageTitle style={{ marginTop: 4, marginBottom: 19 }}>{t(this.emptyTabTitles[currentTab])}</PageTitle>
    );

    const contentFaqInvoicesPage = (
      <div className={classes.bottomInfoWrapper}>
        <FaqInvoicesPage />
        <BottomAdvertisementHandler />
      </div>
    );

    const isWidthDownSm = isWidthDown('sm', width);

    const tabRoutes = {
      unpaid: `${t('routes.invoices')}${t('routes.unpaid')}`,
      paid: `${t('routes.invoices')}${t('routes.paid')}`,
      cancelled: `${t('routes.invoices')}${t('routes.cancelled')}`,
    };

    const searchParams = new URLSearchParams(window.location.search);

    const shouldOpenInvoice = window.location.href.includes('open_invoice=')
      ? searchParams.get('open_invoice')
      : false;

    return (
      <div data-testid="invoices">
        {enabledInvoiceSplitPaymentBanner && showSplitPaymentOfferModal && cookiePopupSplitPayment && (
          <SplitPaymentOfferModal
            onClose={this.handleOnSplitPaymentsModalClose}
          />
        )}
        {isBRServer && enablePixPopup && cookiePopupPix && showPixOffer && (
          <PixOfferPopup
            closeAriaLabel={t('pixOfferPopupCloseLabel')}
            cornerTagText={t('pixOfferPopupNew')}
            description={t('pixOfferPopupDescription')}
            knowMore={t('pixOfferPopupKnowMoreLink')}
            onClose={this.handleOnPixOfferPopupClose}
            onLinkClick={this.handleOnPixOfferPopupLinkClick}
            title={t('pixOfferPopupTitle')}
          />
        )}

        <PageMessages
          displayInvoiceMessage={false}
          displayDomainAlertManager={false}
        />

        {enabledNewLayoutInvoicePage && (
          <div className={classes.invoiceFilter}>
            <InvoiceFilter
              invoices={invoicesByStatus.all}
              setInvoices={setInvoices}
              currentInvoiceStatus={currentInvoiceStatus}
              paginationData={paginationData}
              onLoadInvoices={this.onLoadInvoiceWithParams}
              onChangeChip={this.onChangeChip}
            />
          </div>
        )}

        {(enabledNewLayoutInvoicePage && isLoading) && (
          <div className={classes.loading}>
            <Skeleton variant="tableSimple" />
          </div>
        )}

        {enabledHeaderInvoicePage && totalInvoicesUnpaid > 0 && (
          <InvoiceHeader pendingInvoices={totalInvoicesUnpaid} />
        )}

        {enabledInvoiceSplitMessage && (
          <BannerInvoiceSplit />
        )}

        <div className={classes.tabContainer}>

          {!isWidthDownSm && !enabledNewLayoutInvoicePage && (
            <FilterCard title={t('lists.invoices')} useMediaWidth={1060}>
              <FilterInvoices
                type={currentTab}
                filterValues={filter}
                onInputChange={this.inputChange}
                selectFilters={selectFilters}
                total={this.handleTotal(currentTab)}
              />
            </FilterCard>
          )}

          {!enabledNewLayoutInvoicePage && (
            <Tabs
              classes={{ indicator: classes.indicator }}
              className={classes.tabs}
              value={indexTab}
              scrollButtons="auto"
              variant={isWidthDownSm ? 'fullWidth' : 'standard'}
              onChange={this.handleTabChange}
            >
              <Tab
                component={LinkButton}
                to={tabRoutes.unpaid}
                className={classes.tab}
                label={t('invoices.unpaidInvoicesTitle')}
                data-id="tab-button-unpaid"
                data-testid="tab-button-unpaid"
                classes={{ textColorPrimary: classes.labelDisabled, selected: classes.tabSelected }}
              />
              <Tab
                component={LinkButton}
                to={tabRoutes.paid}
                className={classes.tab}
                label={t('invoices.paidInvoicesTitle')}
                data-id="tab-button-paid"
                data-testid="tab-button-paid"
                classes={{ textColorPrimary: classes.labelDisabled, selected: classes.tabSelected }}
              />
              <Tab
                component={LinkButton}
                to={tabRoutes.cancelled}
                className={classes.tab}
                label={t('invoices.cancelledInvoicesTitle')}
                data-id="tab-button-cancelled"
                classes={{ textColorPrimary: classes.labelDisabled, selected: classes.tabSelected }}
              />
            </Tabs>
          )}
        </div>

        {enabledNewLayoutInvoicePage && (
          <div dir={theme.direction} className={classes.swipeContainerTab}>
            {!isLoading && (
              <div data-testid="invoices-paid-list">
                {titlePage}
                {invoices.length > 0 && (
                  <Fragment>
                    {this.filterInvoices(invoices).length > 0 ? (
                      this.filterInvoices(invoices)
                        .map(invoice => (
                          <>
                            <InvoiceCard
                              key={invoice.id}
                              index={invoice.id}
                              invoice={invoice}
                              openInvoiceOnMount={match.params.invoiceId && (!!((match.params.invoiceId.toString() === invoice.id.toString() && shouldOpenInvoice)))}
                              onExecuteAction={() => this.onExecuteAction()}
                            />
                          </>
                        ))
                    ) : (
                      <Typography className={classes.tabTitle}>
                        {t('noResults.filter')}
                      </Typography>
                    )}
                    {this.renderLoadMoreButton() && (
                      <div className={classes.loadMoreDiv}>
                        <Button
                          testId="load-more-button"
                          variant="secondary"
                          onClick={loading ? () => { } : () => this.loadMoreInBackend()}
                          label={loading ? '' : `${t('btn.loadMore')}`}
                          loading={loading}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                {contentFaqInvoicesPage}
              </div>
            )}
          </div>

        )
        }


        {
          !enabledNewLayoutInvoicePage && (
            <div className={classes.wrapperContentTab}>
              {isWidthDownSm && (
                <PageMessages
                  displayInvoiceMessage={false}
                  displayDomainAlertManager={false}
                />
              )}

              {isWidthDownSm && (
                <FilterCard
                  title={t('lists.invoices')}
                  useMediaWidth={1000}
                  className={this.handleTotal(currentTab) <= 5 && classes.bottomCard}
                >
                  {this.handleTotal(currentTab) > 5 && (
                    <FilterInvoices
                      type={currentTab}
                      filterValues={filter}
                      onInputChange={this.inputChange}
                      selectFilters={selectFilters}
                      total={this.handleTotal(currentTab)}
                    />
                  )}
                </FilterCard>
              )}
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={indexTab}
                onChangeIndex={this.handleChangeIndex}
                className={classes.swipeableViewsContainer}
              >
                <div dir={theme.direction} className={classes.swipeContainerTab}>
                  {!isLoading && (
                    <div data-testid="invoices-unpaid-list">
                      {titlePage}
                      {invoicesByStatus.Unpaid.length > 0 && (
                        <Fragment>
                          {this.filterInvoices(invoicesByStatus.Unpaid).length > 0 ? (
                            this.filterInvoices(invoicesByStatus.Unpaid)
                              .slice(0, visible)
                              .map((invoice, index) => {
                                const hasOpenInvoiceOnMount = match.params.invoiceId
                                  && (!!((match.params.invoiceId.toString() === invoice.id.toString() && shouldOpenInvoice)));

                                return (
                                  <UnpaidInvoiceItem
                                    key={invoice.id}
                                    index={index}
                                    invoice={invoice}
                                    invoiceValueLabel="invoiceTotalUnpaidValue"
                                    paymentMethod={match.params.paymentMethod}
                                    onPaymentExecuted={(status) => {
                                      this.setPaymentStatus(status);
                                    }}
                                    openInvoiceOnMount={hasOpenInvoiceOnMount}
                                  />
                                );
                              })
                          ) : (
                            <Typography className={classes.tabTitle}>
                              {t('noResults.filter')}
                            </Typography>
                          )}
                          {visible < this.filterInvoices(invoicesByStatus.Unpaid).length && (
                            <ActionButton action={() => this.loadMore(invoicesByStatus.Unpaid.length)} data-id="load-more-button">
                              {t('btn.loadMore')}
                            </ActionButton>
                          )}
                          {payment.status === PAYMENT_STATUS.SUCCESS && (
                            <PaymentSuccessModal
                              onClose={() => this.handleOnPaymentModalClose()}
                              onButtonClick={() => this.handleOnPaymentModalClose()}
                            />
                          )}
                        </Fragment>
                      )}

                      {contentFaqInvoicesPage}

                    </div>
                  )}
                </div>
                <div dir={theme.direction} className={classes.swipeContainerTab}>
                  {!isLoading && (
                    <div data-testid="invoices-paid-list">
                      {titlePage}
                      {invoicesByStatus.Paid.length > 0 && (
                        <Fragment>
                          {this.filterInvoices(invoicesByStatus.Paid).length > 0 ? (
                            this.filterInvoices(invoicesByStatus.Paid)
                              .slice(0, visible)
                              .map(invoice => (
                                <InvoiceItem
                                  key={invoice.id}
                                  invoice={invoice}
                                  invoiceValueLabel="invoiceTotalLabel"
                                  type={INVOICES_STATUS.PAID}
                                />
                              ))
                          ) : (
                            <Typography className={classes.tabTitle}>
                              {t('noResults.filter')}
                            </Typography>
                          )}
                          {visible < this.filterInvoices(invoicesByStatus.Paid).length && (
                            <ActionButton action={() => this.loadMore(invoicesByStatus.Paid.length)} data-id="load-more-button">
                              {t('btn.loadMore')}
                            </ActionButton>
                          )}
                        </Fragment>
                      )}

                      {contentFaqInvoicesPage}

                    </div>
                  )}
                </div>
                <div dir={theme.direction} className={classes.swipeContainerTab}>

                  {!isLoading && (
                    <Fragment>
                      {titlePage}
                      {invoicesByStatus.Cancelled.length > 0 && (
                        <Fragment>
                          {this.filterInvoices(invoicesByStatus.Cancelled).length > 0 ? (
                            this.filterInvoices(invoicesByStatus.Cancelled)
                              .slice(0, visible)
                              .map(invoice => (
                                <InvoiceItem
                                  key={invoice.id}
                                  invoice={invoice}
                                  invoiceValueLabel="invoiceTotalLabel"
                                  type={INVOICES_STATUS.CANCELLED}
                                />
                              ))
                          ) : (
                            <Typography className={classes.tabTitle}>
                              {t('noResults.filter')}
                            </Typography>
                          )}
                          {visible < this.filterInvoices(invoicesByStatus.Cancelled).length && (
                            <ActionButton action={() => this.loadMore(invoicesByStatus.Cancelled.length)} data-id="load-more-button">
                              {t('btn.loadMore')}
                            </ActionButton>
                          )}
                        </Fragment>
                      )}

                      {contentFaqInvoicesPage}

                    </Fragment>
                  )}
                </div>
              </SwipeableViews>
            </div>
          )
        }
        {holdDomainRedirect && <ModalFrozenDomain />}
      </div>
    );
  }
}

InvoicesPage.propTypes = {
  classes: PropTypes.shape({
    indicator: PropTypes.string.isRequired,
    swipeableViewsContainer: PropTypes.string.isRequired,
    swipeContainerTab: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
    tabContainer: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    tabTitle: PropTypes.string.isRequired,
  }).isRequired,
  invoicesByStatus: PropTypes.object.isRequired,
  paginationData: PropTypes.object.isRequired,
  invoicesLoadingCancelled: PropTypes.bool.isRequired,
  invoicesLoadingPaid: PropTypes.bool.isRequired,
  invoicesLoadingUnpaid: PropTypes.bool.isRequired,
  loadInvoices: PropTypes.func.isRequired,
  loadInvoicesWithParams: PropTypes.func.isRequired,
  selectFilters: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  enabledNewLayoutInvoicePage: PropTypes.bool,
  enabledHeaderInvoicePage: PropTypes.bool,
  enabledInvoiceSplitMessage: PropTypes.bool,
  enabledInvoiceSplitPaymentBanner: PropTypes.bool,
  setInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  setCurrentInvoiceStatus: PropTypes.func.isRequired,
  currentInvoiceStatus: PropTypes.string.isRequired,
  totalInvoicesUnpaid: PropTypes.number.isRequired,
  setTotalInvoicesUnpaid: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  invoicesByStatus: state.invoices.byStatus,
  paginationData: state.invoices.paginationData,
  invoicesLoading: state.invoices.loading,
  invoicesLoadingCancelled: state.invoices.loadingByStatus.Cancelled,
  invoicesLoadingPaid: state.invoices.loadingByStatus.Paid,
  invoicesLoadingUnpaid: state.invoices.loadingByStatus.Unpaid,
  selectFilters: state.invoices.selectFilters,
  limitDateToDisplayPopupPixLabel: false,
  holdDomainRedirect: state.preChat.triage.holdDomainRedirect,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadInvoices,
    loadInvoicesWithParams,
    loadUnpaidInvoices,
  },
  dispatch,
);

function withHooks(InvoicePage) {
  return (props) => {
    const validDate = (toogleWithDate) => {
      const limitDateValue = toogleWithDate && toogleWithDate.limitDate != null ? toogleWithDate.limitDate : null;
      return limitDateValue != null && differenceInCalendarDays(new Date(`${limitDateValue} 00:00:00`), new Date()) > 0;
    };

    const enabledNewLayoutInvoicePage = useFeatureIsOn(XRAY_ENABLED_NEW_LAYOUT_INVOICE_PAGE);
    const enabledHeaderInvoicePage = useFeatureIsOn(XRAY_ENABLED_HEADER_INVOICE_PAGE);
    const enabledInvoiceSplitMessage = validDate(useFeatureValue(XRAY_LIMIT_DATE_TO_DISPLAY_INVOICE_SPLIT_MESSAGE));
    const enabledInvoiceSplitPaymentBanner = validDate(useFeatureValue(XRAY_LIMIT_DATE_TO_DISPLAY_SPLIT_PAYMENT_BANNER));

    const [invoices, setInvoices] = useState([]);
    const [totalInvoicesUnpaid, setTotalInvoicesUnpaid] = useState(0);
    const [currentInvoiceStatus, setCurrentInvoiceStatus] = useState('');

    return (
      <InvoicePage
        enabledNewLayoutInvoicePage={enabledNewLayoutInvoicePage}
        enabledHeaderInvoicePage={enabledHeaderInvoicePage}
        enabledInvoiceSplitMessage={enabledInvoiceSplitMessage}
        invoices={invoices}
        setInvoices={setInvoices}
        totalInvoicesUnpaid={totalInvoicesUnpaid}
        setTotalInvoicesUnpaid={setTotalInvoicesUnpaid}
        currentInvoiceStatus={currentInvoiceStatus}
        setCurrentInvoiceStatus={setCurrentInvoiceStatus}
        enabledInvoiceSplitPaymentBanner={enabledInvoiceSplitPaymentBanner}
        {...props}
      />
    );
  };
}

export default withRouter(withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles, { withTheme: true })(withHooks(InvoicesPage)))),
));
