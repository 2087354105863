import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import * as Styles from './Link.styles';

const Link = ({
  align = 'left',
  href = '#',
  onClick,
  target = '_self',
  testId = 'link',
  text = 'Link description',
  variant = 'default',
  historyRedirect = false,
  iconRight,
  inlineLink = false,
}) => {
  const history = useHistory();
  const handleOnClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ href, event }) => {
      event.preventDefault();
      event.stopPropagation();

      onClick && onClick();

      if (href && href !== '#') {
        if (target === '_blank') {
          return window.open(href, target);
        }

        if (historyRedirect) {
          history.push(href);
        } else {
          window.location.href = href;
        }
      }
    },
    [onClick, target, historyRedirect, history],
  );

  return (
    <Styles.Anchor
      align={align}
      data-testid={testId}
      href={href}
      onClick={event => handleOnClick({ href, event })}
      target={target}
      title={text}
      variant={variant}
      inlineLink={inlineLink}
    >
      {text}
      {iconRight && (
        <Styles.IconWrapper>
          {iconRight}
        </Styles.IconWrapper>
      )}
    </Styles.Anchor>
  );
};

export default Link;
