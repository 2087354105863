import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import MaskIpv4 from '@/components/Fields/InputMasks/MaskIpv4';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';

const TypeAValueField = ({
  classes, t, formData, onHandleChangeInput, isInvalidBlock,
}) => {
  const { loading } = useContext(DNSContext);

  return (
    <TextField
      disabled={loading}
      label={t('dns.zoneManager.addRecord.form.label.typeAValueField')}
      value={formData.address}
      onChange={e => onHandleChangeInput(e.target)}
      name="address"
      placeholder="___.___.___.___"
      variant="outlined"
      required
      className={classes.textField}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
        inputComponent: MaskIpv4,
        inputProps: {
          'data-testid': 'zoneManagerIpv4Field',
        },
      }}
      error={formData.address.length > 0 && isInvalidBlock}
      helperText={(formData.address.length > 0 && isInvalidBlock) ? t('dns.zoneManager.addRecord.form.helperText.typeA') : null}
    />
  );
};

export default (withI18n()(withStyles(styles)(TypeAValueField)));
