import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../../Modal';
import { Input, Button, Alert } from 'gatorcomponents';
import { Timer } from '@/pages/common/v1';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierSuccess } from '@/utils/Application/notifier';
import { loadSummary } from '@/redux/actions/summary';
import * as Styles from './ValidateCodeModal.styles';
import {
  sendEnableTwoFactorAuthCode,
  sendDisableTwoFactorAuthCode,
  resendTwoFactorAuthCode,
  setValidationFeedback,
  validateTwoFactorAuthCode,
} from '@/redux/actions/twoFactorAuth';

const CODE_LENGTH = 6;
const TIMEOUT_IN_MINUTES = 2;

const ValidateCodeModal = ({
  modal,
  setModal,
  userEmail,
  twoFactorIsEnabled,
  twoFactorAuthLocale,
}) => {
  const [resendEnabled, setResendEnabled] = useState(true);
  const [formData, setFormData] = useState({
    code: '',
  });
  const testId = `validate-code-modal--${modal.type}`;
  const isDisableFormModal = modal.type === 'disable';

  const dispatch = useDispatch();
  const { loadingResend, loadingValidate, validationError } = useSelector(state => state.twoFactorAuth);

  const showSnackbar = () => {
    setModal(prev => ({ ...prev, open: false }));
    dispatch(setValidationFeedback({ hasError: false }));

    const snackbarType = twoFactorIsEnabled ? {} : notifierSuccess;
    dispatch(enqueueSnackbar(
      twoFactorAuthLocale.snackbarFeedback(modal.type),
      snackbarType,
    ));
  };

  const onSubmit = () => {
    dispatch(validateTwoFactorAuthCode({
      code: formData.code,
      action: twoFactorIsEnabled ? 'disable' : 'enable',
    })).then(({ payload }) => {
      const codeValidated = payload.success;

      codeValidated && dispatch(loadSummary()).then(showSnackbar);
      !codeValidated && dispatch(setValidationFeedback({ hasError: true }));

      setResendEnabled(true);
      setFormData(prev => ({ ...prev, code: '' }));
    });
  };

  const handleChange = useCallback(({ key, value }) => {
    dispatch(setValidationFeedback({ hasError: false }));
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  const resendCode = () => {
    dispatch(resendTwoFactorAuthCode());
    setResendEnabled(false);
  };

  const sendCode = () => {
    modal.type === 'enable' ? dispatch(sendEnableTwoFactorAuthCode()) : dispatch(sendDisableTwoFactorAuthCode());
    setResendEnabled(false);
  };

  const onFinishTimeout = () => {
    setResendEnabled(true);
  };

  const onOpenModal = useCallback(() => {
    dispatch(setValidationFeedback({ hasError: false }));
    const canResendCode = modal.open && resendEnabled && !loadingResend;
    canResendCode && sendCode();
  }, [modal.open]);

  useEffect(() => {
    onOpenModal();
  }, [onOpenModal]);

  return (
    <Modal
      open={modal.open}
      title={modal.title}
      onClose={() => setModal({ ...modal, open: false })}
      secondaryButtonAction={() => setModal({ ...modal, open: false })}
      primaryButtonLabel={modal.primaryButtonLabel}
      primaryButtonAction={onSubmit}
      isLoading={loadingValidate}
      isDisabled={loadingValidate}
      testId={testId}
    >
      <Styles.Container>
        <Styles.Description data-testid={`${testId}__description`}>
          {twoFactorAuthLocale.validateCodeModal.description}
          <div>
            <b>{userEmail}</b>
          </div>
        </Styles.Description>
        <Styles.Form>
          <Input
            name="code"
            size="md"
            maxLength={CODE_LENGTH}
            errorMessage={validationError ? twoFactorAuthLocale.validateCodeModal.codeInputError : undefined}
            value={formData.code}
            placeholder={twoFactorAuthLocale.validateCodeModal.codeInputPlaceholder}
            onChange={e => handleChange({ key: 'code', value: e.target.value })}
            testId={`${testId}__input-code`}
          />
        </Styles.Form>

        {resendEnabled || loadingResend ? (
          <Button
            type="text"
            variant="tertiary"
            label={twoFactorAuthLocale.validateCodeModal.resendCodeButtonLabel}
            onClick={resendCode}
            loading={loadingResend}
            disabled={loadingResend}
            testId={`${testId}__resend-button`}
          />
        ) : (
          <Styles.ResendCodeMessage>
            {twoFactorAuthLocale.validateCodeModal.resendCodeMessage}
            {' '}
            <Timer
              startSecound={0}
              startMinute={TIMEOUT_IN_MINUTES}
              onFinish={onFinishTimeout}
              displayHour={false}
            />
          </Styles.ResendCodeMessage>
        )}

        <Styles.ActionInfo>
          <Styles.Divider />

          {isDisableFormModal && (
            <Styles.AlertWrapper>
              <Alert
                variant="error"
                title={twoFactorAuthLocale.validateCodeModal.disable2FA.warning.title}
                description={twoFactorAuthLocale.validateCodeModal.disable2FA.warning.description}
                testId={`${testId}__alert`}
              />
            </Styles.AlertWrapper>
          )}

          <Styles.ConfirmActionText data-testid={`${testId}__confirm-action-text`}>
            {twoFactorAuthLocale.validateCodeModal.confirmText(modal.type)}
          </Styles.ConfirmActionText>
        </Styles.ActionInfo>
      </Styles.Container>
    </Modal>
  );
};

export default ValidateCodeModal;
