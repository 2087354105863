import React from 'react';
import * as Styles from './DnsWizardConfigCardHostGator.styles';
import hostGatorLogo from '@/media/logo/HalfSnappyLogo/HalfSnappyLogo.svg';
import IconAttentionYellow from '@/icons/IconAttentionYellow/IconAttentionYellow';
import { IconEdit } from '@/icons';
import { Button } from 'gatorcomponents';
import { isMobile } from '@/utils/Validators/validation';

const DnsWizardConfigCardHostGator = ({
  testId = 'dns-wizard-config-card',
  changePlatformText = '',
  editAdvancedDNSZone = '',
  nameServers = [],
  serverText,
  domain,
  planDetails,
  attentionMessage,
  resellerMessage,
  changePlatformClick,
  handleAdvanceDNSZoneModalShow,
  editPlanClick,
  shouldRenderPropagationMessage = false,
  shouldRenderChangePlanButton = false,
  isPointedToResale = false,
  shouldRenderAdvancedDNSZoneButton,
}) => {
  const { name: plan } = planDetails;
  return (
    <Styles.DnsWizardPlanCardWrapper data-testid={testId} isToggleOn>
      <Styles.Header isToggleOn>
        <Styles.LogoWrapper>
          <Styles.Logo
            src={hostGatorLogo}
            loading="lazy"
          />
        </Styles.LogoWrapper>

        {!isMobile && (
          <Styles.Actions>
            <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
            {shouldRenderAdvancedDNSZoneButton && <Button label={editAdvancedDNSZone} variant="tertiary" size="large" onClick={handleAdvanceDNSZoneModalShow} testId="advanced-dns-button" />}
          </Styles.Actions>
        )}

      </Styles.Header>
      <Styles.Body>
        <Styles.Product data-testid="selected-plan">
          <p>
            {`${plan} -`}
          </p>
          <span>
            {domain}
          </span>
          {shouldRenderChangePlanButton
              && (
                <Styles.Edit onClick={editPlanClick} data-testid="change-plan-pencil">
                  <IconEdit />
                </Styles.Edit>
              )}
        </Styles.Product>

        {nameServers && nameServers.map((nameServer, index) => (
          <Styles.Nameservers key={nameServer}>
            {`${serverText} ${index + 1}: `}
            <span>{nameServer}</span>
          </Styles.Nameservers>
        ))}
      </Styles.Body>
      {shouldRenderPropagationMessage && (
      <Styles.Footer data-testid="attention-message">
        <IconAttentionYellow />
        <Styles.FooterMessage>
          {shouldRenderPropagationMessage && attentionMessage}
          {isPointedToResale && resellerMessage}
        </Styles.FooterMessage>
      </Styles.Footer>
      )}

      {isMobile && (
        <Styles.Actions>
          <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
          {shouldRenderAdvancedDNSZoneButton && <Button label={editAdvancedDNSZone} variant="tertiary" size="large" onClick={handleAdvanceDNSZoneModalShow} testId="advanced-dns-button" />}
        </Styles.Actions>
      )}

    </Styles.DnsWizardPlanCardWrapper>
  );
};

export default DnsWizardConfigCardHostGator;
