const styles = theme => ({
  card: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    borderRadius: '0',
    backgroundColor: theme.color.brightSun,
    padding: 10,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px',
    },
  },
  cardContent: {
    flex: 1,
    padding: 7,
    '&:last-child': {
      paddingBottom: '7px',
    },
  },
  messageContent: {
    color: theme.color.tundora,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& > a': {
        backgroundColor: 'transparent',
        '& > span': {
          textDecoration: 'underline',
        },
      },
    },
    verticalAlign: 'middle',
    '& .static-message-button': {
      justifyContent: 'flex-start',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        padding: 0,
        marginTop: 5,
      },
      '& span': {
        transition: '0.2s color',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          color: theme.color.havelockBlue,
        },
        color: theme.color.indigo,
        fontWeight: '600',
      },

    },
    '& .static-message-modal-button': {
      textDecoration: 'underline',
      fontSize: 13,
      color: theme.color.indigo,
      textTransform: 'none',
      textAlign: 'left',
      fontWeight: 400,
      padding: 0,
      verticalAlign: 'bottom',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  messageIcon: {
    marginRight: '20px',
    verticalAlign: 'inherit',
  },
  messageBox: {
    display: 'flex',
  },
  alertCritical: {
    backgroundColor: theme.color.valencia,
    '& p': {
      color: theme.color.white,
    },
    '& svg': {
      color: theme.color.white,
    },
    '& a': {
      color: theme.color.white,
    },
    '& .static-message-modal-button': {
      color: theme.color.white,
    },
  },

  oldResellerPaddingCard: {
    [theme.breakpoints.down(960)]: {
      padding: '15px 14px',

      '& svg': {
        marginRight: 16,
      },
    },
  },

  oldResellerPaddingCardContent: {
    '&:last-child': {
      [theme.breakpoints.down(960)]: {
        padding: 0,
      },
    },
  },

});

export default styles;
