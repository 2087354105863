import React, { useEffect, useState } from 'react';
import * as Styles from './Guides.styles';
import { Guide } from 'gatorcomponents';
import { isBRServer } from '@/utils/Validators/validation';
import Snappy from '@/media/billing/snappy-with-shadow.svg';
import { useSelector } from 'react-redux';
import { CHARLIE_LINK_NA_BIO_GENERAL_GUIDE } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useLocale from '@/hooks/useLocale';
import { useResize } from '@/hooks/useResize';

const Guides = () => {
  const [alreadySawLinkNaBioGuide, setAlreadySawLinkNaBioGuide] = useState(true);
  const { modalDisplayed } = useSelector(state => state.videoClassesReducer);
  const canShowLinkNaBioGuide = useFeatureIsOn(CHARLIE_LINK_NA_BIO_GENERAL_GUIDE);
  const { template: templateLocale } = useLocale();
  const view = useResize();
  const isMobile = view.width < 960;

  useEffect(() => {
    const alreadySawGuide = localStorage.getItem('linkNaBioGuideIsDone');
    if (canShowLinkNaBioGuide && !alreadySawGuide) {
      setAlreadySawLinkNaBioGuide(false);
    }
  }, [canShowLinkNaBioGuide, alreadySawLinkNaBioGuide]);

  const markLinkNaBioAsRead = () => {
    if (!modalDisplayed) {
      localStorage.setItem('linkNaBioGuideIsDone', 'true');
      setAlreadySawLinkNaBioGuide(true);
      window.dispatchEvent(new Event('storage'));
    }
  };

  const linkNaBioGuideContent = () => (
    <Styles.LinkNaBioGuide>
      <Styles.GuideTexts>
        <Styles.GuideTitle data-testid="title">
          {templateLocale.linkNaBioGuide.title}
        </Styles.GuideTitle>
        <Styles.GuideDescription data-testid="description">
          {templateLocale.linkNaBioGuide.description}
        </Styles.GuideDescription>
      </Styles.GuideTexts>
      <Styles.Image src={Snappy} />
    </Styles.LinkNaBioGuide>
  );

  const linkNaBioGuideSteps = [{
    popover: {
      left: 160, top: isBRServer ? 370 : 300, content: linkNaBioGuideContent(), pointer: 'leftBottom', maxwidth: 252,
    },
  }];

  if (!alreadySawLinkNaBioGuide && !modalDisplayed && canShowLinkNaBioGuide && !isMobile) {
    return (
      <>
        <Styles.GuideShadow />
        <Guide
          steps={linkNaBioGuideSteps}
          onClick={markLinkNaBioAsRead}
        />
      </>
    );
  }

  return <></>;
};

export default Guides;
