import {
  REQUEST_INVOICE_PAYMENT_METHODS, RECEIVE_INVOICE_PAYMENT_METHODS, ERROR_INVOICE_PAYMENT_METHOD,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  methods: {},
};

export default function invoicePaymentMethods(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INVOICE_PAYMENT_METHODS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_INVOICE_PAYMENT_METHODS:
      return {
        ...state,
        methods: action.paymentMethods,
        loading: false,
      };
    case ERROR_INVOICE_PAYMENT_METHOD:
      return {
        ...state,
        errors: true,
        loading: false,
      };

    default:
      return state;
  }
}
