const styles = theme => ({
  wrapper: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    color: theme.color.white,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 60,
    maxheight: '210px',
    width: '100%',
    [theme.breakpoints.down(1150)]: {
      padding: '0px',
    },
    padding: '24px',
  },
  testimonialTitle: {
    color: theme.color.white,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '32px',
    fontWeight: theme.font.weights.bold,
    margin: '24px 16px',
    textAlign: 'center',
  },
  testimonialList: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    justifyContent: 'center',
    '& blockquote': {
      fontWeight: 'normal',
      [theme.breakpoints.down(1150)]: {
        width: '100%',
      },
      width: '40%',
      textAlign: 'center',
      '& cite': {
        display: 'block',
        fontStyle: 'normal',
        fontWeight: theme.font.weights.bold,
        marginTop: 24,
      },
    },
  },
});

export default styles;
