import React, { } from 'react';

import * as Styles from './CreditCardPreview.styles';
import CreditCardWrapper from '../CreditCardWrapper';

const CreditCardPreview = ({
  creditCard,
  newFormatCreditCardNumber,
  creditCardExpDateLabel = 'Due Date',
}) => (
  <>
    <Styles.Wrapper>
      <Styles.Content>
        <CreditCardWrapper
          number={creditCard.cardLastFour}
          name={creditCard.ccname}
          preview
          expiry={creditCard.expDate}
          cvc={creditCard.ccvnumber}
          issuer={creditCard.cardType}
          focused="name"
          placeholders={{ name: 'creditcard.placeholder.name' }}
        />
      </Styles.Content>
      <Styles.Content>
        <Styles.LabelCardNumber data-testid="card-number">
          {newFormatCreditCardNumber}
        </Styles.LabelCardNumber>
        <Styles.LabelCardExpDate data-testid="due-date">
          {`${creditCardExpDateLabel} ${creditCard.expDate}`}
        </Styles.LabelCardExpDate>
      </Styles.Content>
    </Styles.Wrapper>
  </>
);


export default CreditCardPreview;
