const formStepper = {
  OPEN_ACCOUNT: 0,
  MAIN_DATA: 1,
  FORM_CONCLUSION: 2,
};

const documentType = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
};

const documentLength = {
  CPF: 14,
  CNPJ: 18,
};

export const asaasEnum = {
  formStepper,
  documentType,
  documentLength,
};
