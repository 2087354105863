import styled, { css } from 'styled-components';

export const SlideToggleWrapper = styled.div`
  ${({
    theme, basis, active,
  }) => css`
    align-items: flex-start;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 4px;
    border: ${active ? `1px solid ${theme.v1.color.action.primary}` : `1px solid ${theme.v1.color.line.wizard}`};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: ${basis};
    padding: 18px 10px 18px 18px;
    position: relative;
    transition: border 200ms ease-in-out;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      padding: 22px 15px; 
    }
  `}
`;

export const ToggleAction = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;

  span {
    flex: 0 0 16px;
  }
`;

export const ToggleHeader = styled.div`
  align-items: flex-start;
  cursor: 'pointer';
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 3px;
`;

export const Title = styled.p`
  ${({ theme, active }) => {
    const variant = {
      color: active ? theme.v1.color.action.primary : theme.v1.color.text.primary,
      weight: active ? theme.v1.font.weights.bold : theme.v1.font.weights.regular,
    };

    return css`
    color: ${variant.color};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${variant.weight};
    line-height: 19px;
    margin: 0px;
  `;
  }}
`;

export const ToggleContent = styled.div`
  ${({ open, maxHeight }) => css`
    margin: ${open ? '12px 0px 0px 0px' : '0px'};
    max-height: ${open ? maxHeight : '0px'};
    overflow:  ${open ? 'unset' : 'hidden'};
    transition: max-height 200ms ease-in-out, margin 200ms ease-in-out;
    width: 100%;
  `}
`;


export const Label = styled.span``;
