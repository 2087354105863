import React from 'react';
import { withStyles, Button, CircularProgress } from '@material-ui/core';

import styles from './styles';


const PrimaryButton = ({
  classes,
  children,
  className,
  onLoading,
  ...rest
}) => (
  <Button
    className={`${classes.btn} ${className}`}
    {...rest}
    variant="contained"
    color="primary"
    type="submit"
  >
    {!onLoading ? children : (
      <div className={classes.wrapper}>
        <CircularProgress size={20} className={classes.loader} data-testid="primaryButtonCircularProgress" />
      </div>
    )}
  </Button>
);

export default withStyles(styles)(PrimaryButton);
