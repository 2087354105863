import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { authActions } from '@/redux/modules/auth';
import linkNaBioActions from './linkNaBio.actions';
import { LinkNaBioActionTypes } from './linkNaBio.types';
import { config } from '@/config';
import { isBRServer } from '@/utils/Validators/validation';
import logger from '@/utils/logger';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import { LINK_NOT_AVAILABLE } from '@/pages/linkNaBio/LinkNaBioWizardHandler/LinkNaBioWizardHandler.enum';
import { locale } from '@/utils/locale';
import { commonActions } from '@/redux/modules/common';

const { WHMCS_URL } = config;

function* checkLinkAvailability(action) {
  const { link } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${link}/available`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.profile.loading(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const available = response && response.data && response.data.message;
    yield put(linkNaBioActions.profile.availability.set(available));
    yield put(linkNaBioActions.profile.availability.success());
  } catch (requestError) {
    yield put(linkNaBioActions.profile.availability.set(LINK_NOT_AVAILABLE));
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method checkLinkAvailability', error);
    yield put(linkNaBioActions.profile.availability.failure());
  }

  yield put(linkNaBioActions.profile.loading(false));
}

function* createLinkNaBio(action) {
  const {
    linkName, title, description, template, colorScheme, links, callback, icons,
  } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      username: linkName,
      title,
      description,
      template,
      color_scheme: colorScheme,
      links,
      icons,
    },
  };

  yield put(linkNaBioActions.profile.loading(true));

  try {
    yield call(api.whmcs.post, request);
    yield put(linkNaBioActions.profile.create.success());
    callback && callback();
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method createLinkNaBio', error);
    yield put(linkNaBioActions.profile.create.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkNaBioCreationError'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.profile.loading(false));
}

function* getLinkNaBio() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profiles`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.profile.loading(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const profiles = (response && response.data) || [];
    yield put(linkNaBioActions.profile.set(profiles));
    yield put(linkNaBioActions.profile.get.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method getLinkNaBio', error);
    yield put(linkNaBioActions.profile.get.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.profileLoadingError'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.profile.loading(false));
}

function* updateLinkNaBio(action) {
  const {
    linkName, title, description, template, colorScheme, profileId, callback, domainToLink, isRemovingDomain, isExternalDomain, nameServer, hostingId, domainId,
  } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
      'x-nameserver-host': nameServer,
      'x-hosting-id': hostingId,
    },
    data: {
      username: linkName,
      title,
      description,
      template,
      color_scheme: colorScheme,
      domain: domainToLink,
      domain_external: isExternalDomain,
      domain_id: domainId,
    },
  };

  try {
    yield call(api.whmcs.put, request);
    yield put(linkNaBioActions.profile.update.success());

    if (isRemovingDomain) {
      yield put(commonActions.notifications.set({
        label: locale('linkNaBio.toasts.domainLinked'),
        type: 'success',
      }));
    }
    callback && callback();
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method updateLinkNaBio', error);
    yield put(linkNaBioActions.profile.update.failure());
    yield put(commonActions.notifications.set({
      label: isRemovingDomain ? locale('linkNaBio.toasts.domainRemoved') : locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }
}

function* deleteLinkNaBio(action) {
  const { profileId } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.profile.loading(true));

  try {
    yield call(api.whmcs.useDelete, request);
    yield put(linkNaBioActions.profile.delete.success());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkNaBioDeleteSuccess'),
      type: 'success',
    }));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method deleteLinkNaBio', error);
    yield put(linkNaBioActions.profile.delete.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkNaBioDeleteError'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.profile.loading(false));
}

function* addNewLinks(action) {
  const { profileId, links } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/link`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      links,
      profile_id: profileId,
    },
  };


  yield put(linkNaBioActions.link.loading(true));

  try {
    yield call(api.whmcs.post, request);
    yield put(linkNaBioActions.profile.get.request());
    yield put(linkNaBioActions.link.add.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method addNewLink', error);
    yield put(linkNaBioActions.link.add.failure());
    yield put(linkNaBioActions.profile.get.request());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.link.loading(false));
}

function* updateLink(action) {
  const { profileId, link } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/link/${link.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      description: link.description,
      link: link.link,
    },
  };

  yield put(linkNaBioActions.link.loading(true));
  try {
    yield call(api.whmcs.put, request);
    yield put(linkNaBioActions.link.update.success());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkUpdated'),
      type: 'success',
    }));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method updateLink', error);
    yield put(linkNaBioActions.link.update.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.link.loading(false));
}

function* reorderLinks(action) {
  const { profileId, links } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/link/sort`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      links,
    },
  };

  yield put(linkNaBioActions.link.loading(true));
  try {
    yield call(api.whmcs.put, request);
    yield put(linkNaBioActions.link.add.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method reorderLinks', error);
    yield put(linkNaBioActions.link.add.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.link.loading(false));
}

function* deleteLink(action) {
  const { profileId, linkId } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/link/${linkId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.link.loading(true));
  try {
    yield call(api.whmcs.useDelete, request);
    yield put(linkNaBioActions.link.add.success());

    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkDeleteSuccess'),
      type: 'success',
    }));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method deleteLink', error);
    yield put(linkNaBioActions.link.add.failure());

    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkDeleteError'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.link.loading(false));
}

function* addNewSocialMedia(action) {
  const { profileId, links } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/social-icon`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      icons: links,
      profile_id: profileId,
    },
  };

  yield put(linkNaBioActions.socialMedia.loading(true));

  try {
    yield call(api.whmcs.post, request);
    yield put(linkNaBioActions.profile.get.request());
    yield put(linkNaBioActions.socialMedia.add.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method addNewSocialMedia', error);
    yield put(linkNaBioActions.socialMedia.add.failure());
    yield put(linkNaBioActions.profile.get.request());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.socialMedia.loading(false));
}

function* updateSocialMedia(action) {
  const { profileId, link } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/social-icon/${link.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      value: link.link,
    },
  };

  yield put(linkNaBioActions.socialMedia.loading(true));
  try {
    yield call(api.whmcs.put, request);
    yield put(linkNaBioActions.socialMedia.update.success());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkUpdated'),
      type: 'success',
    }));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method updateSocialMedia', error);
    yield put(linkNaBioActions.socialMedia.update.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.socialMedia.loading(false));
}

function* reorderSocialMedia(action) {
  const { profileId, links } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/social-icon/sort`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
    data: {
      icons: links,
    },
  };

  yield put(linkNaBioActions.socialMedia.loading(true));
  try {
    yield call(api.whmcs.put, request);
    yield put(linkNaBioActions.socialMedia.sort.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method reorderSocialMedia', error);
    yield put(linkNaBioActions.socialMedia.sort.failure());
    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.socialMedia.loading(false));
}

function* deleteSocialMedia(action) {
  const { profileId, linkId } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/social-icon/${linkId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.socialMedia.loading(true));
  try {
    yield call(api.whmcs.useDelete, request);
    yield put(linkNaBioActions.socialMedia.delete.success());

    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkDeleteSuccess'),
      type: 'success',
    }));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method deleteSocialMedia', error);
    yield put(linkNaBioActions.socialMedia.delete.failure());

    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkDeleteError'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.socialMedia.loading(false));
}

function* getSocialIcons() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/social-icons`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };
  yield put(linkNaBioActions.socialMedia.loading(true));
  try {
    const response = yield call(api.whmcs.get, request);
    yield put(linkNaBioActions.socialMedia.icons.set(response.data));
    yield put(linkNaBioActions.socialMedia.icons.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method getSocialIcons', error);
    yield put(linkNaBioActions.socialMedia.icons.failure());
  }

  yield put(linkNaBioActions.socialMedia.loading(false));
}

function* getProfileStatistics(action) {
  const { profileId, periodIntervalInDays } = action.payload;

  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/link-na-bio/profile/${profileId}/statistics?period=${periodIntervalInDays}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(linkNaBioActions.profile.loading(true));
  try {
    const response = yield call(api.whmcs.get, request);
    yield put(linkNaBioActions.statistic.set(response.data));
    yield put(linkNaBioActions.statistic.success());
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas linkNaBio - method getProfileStatistics', error);
    yield put(linkNaBioActions.statistic.failure());

    yield put(commonActions.notifications.set({
      label: locale('linkNaBio.toasts.linkEdition'),
      type: 'error',
    }));
  }

  yield put(linkNaBioActions.profile.loading(false));
}

function* sagas() {
  yield takeLatest(LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_REQUEST, checkLinkAvailability);
  yield takeLatest(LinkNaBioActionTypes.CREATE_LINK_NA_BIO_REQUEST, createLinkNaBio);
  yield takeLatest(LinkNaBioActionTypes.GET_LINK_NA_BIO_REQUEST, getLinkNaBio);
  yield takeLatest(LinkNaBioActionTypes.UPDATE_LINK_NA_BIO_REQUEST, updateLinkNaBio);
  yield takeLatest(LinkNaBioActionTypes.DELETE_LINK_NA_BIO_REQUEST, deleteLinkNaBio);
  yield takeLatest(LinkNaBioActionTypes.ADD_LINKS_REQUEST, addNewLinks);
  yield takeLatest(LinkNaBioActionTypes.UPDATE_LINK_REQUEST, updateLink);
  yield takeLatest(LinkNaBioActionTypes.SORT_LINKS_REQUEST, reorderLinks);
  yield takeLatest(LinkNaBioActionTypes.DELETE_LINK_REQUEST, deleteLink);
  yield takeLatest(LinkNaBioActionTypes.ADD_SOCIAL_MEDIA_REQUEST, addNewSocialMedia);
  yield takeLatest(LinkNaBioActionTypes.UPDATE_SOCIAL_MEDIA_REQUEST, updateSocialMedia);
  yield takeLatest(LinkNaBioActionTypes.SORT_SOCIAL_MEDIA_REQUEST, reorderSocialMedia);
  yield takeLatest(LinkNaBioActionTypes.DELETE_SOCIAL_MEDIA_REQUEST, deleteSocialMedia);
  yield takeLatest(LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_REQUEST, getSocialIcons);
  yield takeLatest(LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_REQUEST, getProfileStatistics);
}

export default sagas;
