import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './SocialMediaModal.styles';
import {
  Button, IconDanger, Input, PhoneInput, SocialMediaIcons,
} from 'gatorcomponents';
import { linkType, wizardStep2Actions } from '../../LinkNaBioWizardHandler.enum';
import withErrorBoundary from '@/utils/errorBoundary';
import { COUNTRY } from '@/config';

const SocialMediaModal = ({
  stepLocale = {},
  handleModalDisplay = () => null,
  availableSocialMedias = [],
  modalData = {},
  submitSocialMedia = () => null,
  updateModalData = () => null,
  handleKeyDown = () => null,
  linkAction = '',
  deleteSocialMedia = () => null,
  hasLinkError = false,
  validateLink = () => null,
  countryCode,
  showNewWhatsappInput,
}) => (
  <>
    {linkAction.action === wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP1 && (
      <Modal
        maxWidth={640}
        testId="social-link-modal"
        onClose={handleModalDisplay}
      >
        <Styles.ModalContent>
          <Styles.ModalTitle>
            {stepLocale.socialLinkModal.title}
          </Styles.ModalTitle>
          <Styles.ModalDescription>
            {stepLocale.socialLinkModal.description}
          </Styles.ModalDescription>

          <Styles.SocialMediaDescription>
            {stepLocale.socialLinkModal.messageApps}
          </Styles.SocialMediaDescription>

          <Styles.SocialMediaList>
            {availableSocialMedias.map(socialMedia => socialMedia.category && socialMedia.category === 'MESSAGE' && (
              <Styles.SocialLink
                key={socialMedia.name}
                onClick={() => handleModalDisplay({ action: wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP2, selectedSocialMedia: socialMedia })}
              >
                <SocialMediaIcons icon={socialMedia.icon.replace('-icon', '')} brandColors size="lg" />
                {socialMedia.name}
              </Styles.SocialLink>
            ))}
          </Styles.SocialMediaList>

          <Styles.SocialMediaDescription>
            {stepLocale.socialLinkModal.socialMedia}
          </Styles.SocialMediaDescription>

          <Styles.SocialMediaList>
            {availableSocialMedias.map(socialMedia => socialMedia.category && socialMedia.category === 'SOCIAL' && (
              <Styles.SocialLink
                key={socialMedia.name}
                onClick={() => handleModalDisplay({ action: wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP2, selectedSocialMedia: socialMedia })}
              >
                <SocialMediaIcons icon={socialMedia.icon.replace('-icon', '')} brandColors size="lg" />
                {socialMedia.name}
              </Styles.SocialLink>
            ))}
          </Styles.SocialMediaList>

        </Styles.ModalContent>
      </Modal>
    )}

    {((linkAction.action === wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP2) || linkAction.action === wizardStep2Actions.EDIT_SOCIAL_MEDIA) && (
      <Modal
        maxWidth={480}
        testId="social-link-modal"
        onClose={handleModalDisplay}
      >
        <Styles.ModalContent isWhatsapp={modalData.name.toLowerCase() === 'whatsapp'}>
          <Styles.ModalTitle>
            {stepLocale.socialLinkModal.title}
          </Styles.ModalTitle>
          <Styles.ModalDescription>
            {stepLocale.socialLinkModal.insertLink}
          </Styles.ModalDescription>

          <Styles.ModalInputWrapper>
            <Styles.SocialIconWrapper>
              <SocialMediaIcons icon={modalData.icon.replace('-icon', '')} brandColors size="xl" />
            </Styles.SocialIconWrapper>
            {modalData.name.toLowerCase() === 'whatsapp' && showNewWhatsappInput ? (
              <PhoneInput
                name="phone"
                key="phone"
                label={modalData.name}
                inputValue={modalData.phoneNumber || ''}
                onChangeInput={value => updateModalData(value, 'phoneNumber')}
                onChangeSelect={value => updateModalData(value, 'ddi')}
                selected={countryCode && countryCode.includes('+') ? countryCode : `+${countryCode}`}
                error={hasLinkError ? stepLocale.invalidLink : ''}
                brand={COUNTRY}
                renderFlags
              />
            ) : (
              <Input
                label={modalData.name}
                value={modalData.link || ''}
                placeholder={modalData.name.toLowerCase() === 'whatsapp' ? stepLocale.socialLinkModal.whatsappPlaceholder : modalData.placeholder}
                onChange={event => updateModalData(event.currentTarget.value, 'link')}
                maxLength={modalData.link && modalData.link.startsWith('http') ? 255 : 245}
                onKeyDown={({ key }) => handleKeyDown({ key, type: linkType.SOCIAL_MEDIA })}
                errorMessage={hasLinkError ? stepLocale.invalidLink : ''}
                onBlur={validateLink}
              />
            )}
          </Styles.ModalInputWrapper>
          <Styles.ModalButtonsWrapper>
            <Button
              label={stepLocale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleModalDisplay}
            />
            <Button
              label={linkAction.action === wizardStep2Actions.EDIT_SOCIAL_MEDIA ? stepLocale.edit : stepLocale.add}
              size="large"
              onClick={submitSocialMedia}
              disabled={(!modalData.link && !modalData.phoneNumber) || hasLinkError}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    )}

    {linkAction.action === wizardStep2Actions.DELETE_SOCIAL_MEDIA && (
      <Modal
        maxWidth={620}
        testId="link-na-bio-modal"
        onClose={handleModalDisplay}
      >
        <Styles.ModalContent>
          <Styles.ModalTitle>
            {stepLocale.deleteSocialLinkTitle}
          </Styles.ModalTitle>
          <Styles.ModalDescription>
            <IconDanger size="lg" color="attentionPure" />
            {stepLocale.deleteSocialLink}
          </Styles.ModalDescription>

          <Styles.ModalDeleteButtonsWrapper>
            <Button
              label={stepLocale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleModalDisplay}
            />
            <Button
              label={stepLocale.confirmDelete}
              size="large"
              onClick={deleteSocialMedia}
            />
          </Styles.ModalDeleteButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    )}
  </>
);
export default withErrorBoundary(SocialMediaModal, 'SocialMediaModal');
