import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './Input.styles';
import { IconEyePasswordDisabled, IconEyePasswordEnabled } from '@/icons';
import {
  applyDocumentMask, applyDateMask, applyCpfMask, applyCnpjMask, applyCepMask, applyBRMoneyMask,
} from '@/utils/Masks/inputMasks';
import { removeAccents } from '@/utils/Validators/validation';

const Input = ({
  onChange,
  onClickIconShowPassword,
  testId = 'test-id-text',
  value = 'teste',
  maxLength = null,
  title = '',
  placeholder = 'ex: place holder',
  disabled,
  errorMessage = '',
  type = 'password',
  textAlign = 'left',
  counter = false,
  name = 'input-name',
  iconColor = '#959ea3',
  onBlur,
  rows = 1,
  maxWidth = 'unset',
  variant = 'default',
  readOnly = false,
  mask = null,
}) => {
  const [textValue, setTextValue] = useState(value);
  const [typeValue, setTypeValue] = useState(type);
  const isPasswordType = type === 'password';

  const handleMask = useCallback((value) => {
    let sanitizedValue = value;
    if (mask === 'document') {
      sanitizedValue = applyDocumentMask(value);
    }

    if (mask === 'cpf') {
      sanitizedValue = applyCpfMask(value);
    }

    if (mask === 'cnpj') {
      sanitizedValue = applyCnpjMask(value);
    }

    if (mask === 'date') {
      sanitizedValue = applyDateMask(value);
    }

    if (mask === 'cep') {
      sanitizedValue = applyCepMask(value);
    }

    if (mask === 'domain') {
      sanitizedValue = removeAccents(value).replace(/[^a-zA-Z0-9\-.]/g, '');
    }

    if (mask === 'money') {
      sanitizedValue = applyBRMoneyMask(value);
    }

    return sanitizedValue;
  }, [mask]);

  const handleOnChange = (event) => {
    const sanitizedObject = {
      ...event,
    };

    sanitizedObject.target.value = handleMask(event.target.value);


    if (maxLength && event.target.value.length > maxLength) {
      return;
    }

    onChange && onChange(sanitizedObject);
    setTextValue(event.target.value);
  };

  const handleBlur = (event) => {
    onBlur && onBlur(event);
  };

  const showPassword = () => {
    if (onClickIconShowPassword) {
      onClickIconShowPassword();
    }
    setTypeValue('text');
  };

  const unShowPassword = () => {
    setTypeValue('password');
  };

  const applyMask = useCallback(() => {
    const sanitizedValue = handleMask(value);
    setTextValue(sanitizedValue);
  }, [value, handleMask]);

  useEffect(() => {
    applyMask();
  }, [applyMask]);


  return (
    <Styles.Wrapper maxWidth={maxWidth}>
      <Styles.WrapperInput typeValue={typeValue} variant={variant}>

        {typeValue === 'textarea'
          ? (
            <Styles.TextArea
              disabled={disabled}
              value={textValue}
              onChange={handleOnChange}
              onBlur={handleBlur}
              data-testid={testId}
              title={title}
              placeholder={placeholder}
              textAlign={textAlign}
              name={name}
              rows={rows}
              readOnly={readOnly}
            />
          )
          : (
            <Styles.Input
              disabled={disabled}
              value={textValue}
              onChange={handleOnChange}
              onBlur={handleBlur}
              data-testid={testId}
              title={title}
              type={typeValue}
              placeholder={placeholder}
              textAlign={textAlign}
              name={name}
              variant={variant}
              readOnly={readOnly}
            />
          )
        }

        {counter && (
          <Styles.Counter typeValue={typeValue}>
            {`(${value.length >= maxLength ? maxLength : value.length}/${maxLength})`}
          </Styles.Counter>
        )}

        {isPasswordType && typeValue === 'password' && (
          <Styles.ButtonPassword onClick={disabled ? null : showPassword}>
            <IconEyePasswordDisabled color={iconColor} />
          </Styles.ButtonPassword>
        )}

        {isPasswordType && typeValue === 'text' && (
          <Styles.ButtonPassword onClick={disabled ? null : unShowPassword}>
            <IconEyePasswordEnabled color={iconColor} />
          </Styles.ButtonPassword>
        )}
      </Styles.WrapperInput>

      {errorMessage && (
        <Styles.LabelError title={errorMessage}>
          {errorMessage}
        </Styles.LabelError>
      )}
    </Styles.Wrapper>
  );
};

export default Input;
