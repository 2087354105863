import React from 'react';
import * as Style from './IconDetailedServer.style';

const IconDetailedServer = ({
  color = '#4a4a4a',
  background = '#ff9144',
  size = '24',
  testId = 'icon-detailed-server',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24.549">
      <g data-name="Grupo 40125" transform="translate(-250.393 -268.396)">
        <g data-name="Grupo 36347" transform="translate(250.393 268.396)">
          <rect width="11.273" height="24.549" transform="translate(12.727)" fill={background} />
          <g data-name="Grupo 36346" transform="translate(0 1.757)" fill={color}>
            <rect data-name="Retângulo 14248" width="1.669" height="1.669" transform="translate(2.856 16.369)" />
            <rect data-name="Retângulo 14248" width="1.669" height="1.669" transform="translate(2.856 9.821)" />
            <rect data-name="Retângulo 14248" width="1.669" height="1.669" transform="translate(2.856 3.274)" />
            <path data-name="Caminho 238741" d="M1087.189,140.176v-1.835h-22.243v21.312h22.243v-1.835h-20.175v-4.657h20.175v-1.835h-20.175v-4.657h20.175v-1.835h-20.175v-4.657Z" transform="translate(-1064.947 -138.341)" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedServer;
