import { billingActions, commonActions } from '@/redux/modules';
import { SubscriptionsQuestionTypes } from '@/redux/modules/billing/billing.types';
import { locale } from '@/utils/locale';
import { debounce } from '@/utils/debounce';

const createSubscriptionCancelRenewalHandler = ({
  history,
  questions,
  step,
  setStep,
  subscriptionAnalytics,
  subscriptions,
  subscriptionId,
  subscriptionType,
  stepsStatus,
  questionId,
  optionId,
  setSubscription,
  dispatch,
  openPreChatCancelFlow,
}) => {
  const handleOnOptionSelected = (payload) => {
    dispatch(billingActions.subscriptions.cancel.set(payload));
  };

  const resetCancelRenewalSurvey = () => {
    dispatch(billingActions.subscriptions.cancel.set({
      questionId: null,
      optionId: null,
      questionValue: '',
      reason: '',
    }));
  };

  const resetOptionsByQuestionName = (type) => {
    const question = questions[type][0];
    question.options = question.options.map(option => ({
      ...option,
      ...(!option.selected && { selected: true }),
    }));

    dispatch(billingActions.subscriptions.questions.set(question, type));
  };

  const onFowardStepClick = () => {
    const redirect = () => {
      history.push(`${locale('routes.subscriptions')}${locale('routes.manageSubscription')}/${subscriptionId}/${subscriptionType}`);
    };

    const steps = {
      [stepsStatus.SURVEY]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('3_continuar_pesquisa_respondida_cancelar_renovacao');
        setStep(stepsStatus.CONFIRMATION);
      },
      [stepsStatus.CONFIRMATION]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('4_cta_cancelar_renovacao');

        subscriptionAnalytics.cancelRenewalConfirmationClick();
        setStep(stepsStatus.DONE);
        const subscription = subscriptions.find(item => item.id === Number(subscriptionId));
        dispatch(billingActions.subscriptions.cancelRenewal.request(
          subscription,
          subscriptionId,
          subscriptionType,
          redirect,
        ));

        resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_RENEWAL);
      },
    };

    steps[step]();
  };

  const onPreviousStepClick = () => {
    const steps = {
      [stepsStatus.CONFIRMATION]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('4_voltar_para_pesquisa_cancelar_renovacao');
        setStep(stepsStatus.SURVEY);
      },
      [stepsStatus.SURVEY]: () => {
        subscriptionAnalytics.cancelSubscriptionClick('3_voltar_pesquisa_respondida_cancelar_renovacao');
        resetCancelRenewalSurvey();
        resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_RENEWAL);
        openPreChatCancelFlow();
        history.push(locale('routes.subscriptions'));
      },
    };

    steps[step]();
  };

  const handleOnStopCancel = () => {
    subscriptionAnalytics.cancelSubscriptionClick('4_desisir_cancelar_renovacao');
    resetCancelRenewalSurvey();
    resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_RENEWAL);
    openPreChatCancelFlow();
    history.push(locale('routes.subscriptions'));
  };

  const isSurveyStepComplete = () => (!questionId || !optionId);

  const settingSubscriptions = () => {
    setSubscription(subscriptions.find(subscription => subscription.id === Number(subscriptionId)
        && subscription.type === subscriptionType));
  };

  const requestQuestions = () => {
    dispatch(billingActions.subscriptions.questions.request(
      SubscriptionsQuestionTypes.CANCEL_RENEWAL,
    ));
  };

  const findSubscription = () => {
    const subItem = subscriptions.find(({ id }) => id === subscriptionId);
    subItem && setSubscription(subItem);
  };

  const requestSubscription = () => {
    dispatch(billingActions.subscription.request({ subscriptionId, subscriptionType }));
  };

  const reset = () => {
    resetCancelRenewalSurvey();

    if (questions[SubscriptionsQuestionTypes.CANCEL_RENEWAL]) {
      resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_RENEWAL);
    }
  };

  const redirect = () => {
    setTimeout(() => {
      history.push(redirect.url);
      dispatch(commonActions.redirect.clear());
    }, 3000);
  };

  const handleOnReasonChange = debounce((payload) => {
    dispatch(billingActions.subscriptions.cancel.set(payload));
  });

  return {
    onPreviousStepClick,
    onFowardStepClick,
    handleOnStopCancel,
    handleOnOptionSelected,
    resetCancelRenewalSurvey,
    resetOptionsByQuestionName,
    isSurveyStepComplete,
    settingSubscriptions,
    requestQuestions,
    findSubscription,
    requestSubscription,
    reset,
    redirect,
    handleOnReasonChange,
  };
};

export default createSubscriptionCancelRenewalHandler;
