import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: {
    CREATE_SITE: 'Criar Site',
  },
  ACTION: {
    MODAL_DISPLAYED: 'Modal exibido',
    MODAL_CLOSED: 'Modal fechado',
    CLICK_CREATE_SPECIALIST: 'Clique em Criar com um Especialista',
    CLICK_CREATE_MYSELF: 'Clique em Criar eu Mesmo',
  },
  LABEL: {
    UNIQUE_VIEW_LABEL: 'Modal de exibição única (MVP)',
    SITES_LIST_LABEL: 'Modal de Criar Site na seção /Site (MVP)',
  },
};

const createSiteModalView = (origin) => {
  let label = EVENTS.LABEL.UNIQUE_VIEW_LABEL;
  if (origin !== 'uniqueMvp') {
    label = EVENTS.LABEL.SITES_LIST_LABEL;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.CREATE_SITE,
    gaEventAction: EVENTS.ACTION.MODAL_DISPLAYED,
    gaEventLabel: label,
  });
};

const createSiteModalClose = (origin) => {
  let label = EVENTS.LABEL.UNIQUE_VIEW_LABEL;
  if (origin !== 'uniqueMvp') {
    label = EVENTS.LABEL.SITES_LIST_LABEL;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.CREATE_SITE,
    gaEventAction: EVENTS.ACTION.MODAL_CLOSED,
    gaEventLabel: label,
  });
};

const createSiteModalCreate = (origin) => {
  let label = EVENTS.LABEL.UNIQUE_VIEW_LABEL;
  if (origin !== 'uniqueMvp') {
    label = EVENTS.LABEL.SITES_LIST_LABEL;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.CREATE_SITE,
    gaEventAction: EVENTS.ACTION.CLICK_CREATE_SPECIALIST,
    gaEventLabel: label,
  });
};

const createSiteModalCreateMySelf = (origin) => {
  let label = EVENTS.LABEL.UNIQUE_VIEW_LABEL;
  if (origin !== 'uniqueMvp') {
    label = EVENTS.LABEL.SITES_LIST_LABEL;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.CREATE_SITE,
    gaEventAction: EVENTS.ACTION.CLICK_CREATE_MYSELF,
    gaEventLabel: label,
  });
};

const analytics = {
  createSiteModalView,
  createSiteModalClose,
  createSiteModalCreate,
  createSiteModalCreateMySelf,
};

export default analytics;
