import React from 'react';
import {
  call, takeLatest, put, select, take,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { COUNTRY, config } from '@/config';
import { authActions } from '@/redux/modules/auth';
import { commonActions } from '@/redux/modules/common';
import { locale } from '@/utils/locale';
import { textRegular, textBold } from '@/pages/common/TextDescriptor';
import { TextDescriptor } from '@/pages/common/';
import { billingAnalytics } from '@/analytics';
import billingAssembly from './billing.assembly';
import billingActions from './billing.actions';
import { BillingActionTypes } from './billing.types';
import { INVOICE_PAYMENT_METHODS } from '@/config/api';
import { EnumActionOpeningModal, Modais } from '@/components/billing/subscriptions/ManageSubscriptionNewHandler/ManageSubscriptionNewHandler.types';
import logger from '@/utils/logger';

const HTTP_STATUS = {
  TOO_MANY_REQUESTS: 429,
};

const { subscriptions: subscriptionAnalytics } = billingAnalytics;

function* getInvoicePaymentMethods() {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: INVOICE_PAYMENT_METHODS,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const paymentMethodData = response.data;
    yield put(billingActions.paymentMethod.set(paymentMethodData.data));
    yield put(billingActions.paymentMethod.success());
  } catch (e) {
    yield put(billingActions.paymentMethod.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* getSubscriptions(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(billingActions.subscriptions.setSubscriptionsLoadingStatus(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const subscriptionsData = billingAssembly.receiveSubscriptions(response.data);
    yield put(billingActions.subscriptions.set(subscriptionsData));
    yield put(billingActions.subscriptions.success());

    if (action.payload && action.payload.preChatRedirect) {
      yield getInvoicePaymentMethods();
      action.payload.preChatRedirect();
    }
  } catch (e) {
    yield put(billingActions.subscriptions.failure());
  }

  yield put(billingActions.subscriptions.setSubscriptionsLoadingStatus(false));
}

function* getSubscriptionsQuestions(action) {
  const { type } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/questions?name=${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const questionData = billingAssembly.receiveSubscriptionsQuestion(response.data);

    yield put(billingActions.subscriptions.questions.set(questionData, type));

    yield put(billingActions.subscriptions.questions.success());
  } catch (e) {
    yield put(billingActions.subscriptions.questions.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* requestCancellingRenewal(action) {
  yield put(authActions.tokens.jwt.update());

  const { productId, productType, redirect } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { cancel, items } = yield select(state => state.billing.subscriptions);
  const data = billingAssembly.parseCancellingRenewal(productId, productType, cancel);

  if (cancel.reason !== '') {
    subscriptionAnalytics.cancelRenewalReasonFeedback();
  }

  const subscription = items.find(({ id, type }) => id === Number(productId) && type === productType);

  const request = {
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/cancel_renewal`,
  };

  try {
    const response = yield call(api.whmcs.post, request);
    const responseData = billingAssembly.receiveCancelRenewal(response.data, subscription);

    yield put(billingActions.subscriptions.cancel.set(responseData));

    yield put(billingActions.subscriptions.cancelRenewal.success());
  } catch (e) {
    if (e.response.status === HTTP_STATUS.TOO_MANY_REQUESTS) {
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {`${locale('subscriptions.notifications.cancelRenewal.retry.0')}`}
            <strong>{locale('subscriptions.notifications.cancelRenewal.retry.1')}</strong>
            {`${locale('subscriptions.notifications.cancelRenewal.retry.2')}`}
          </p>
        ),
        type: 'error',
      }));

      if (redirect) {
        redirect();
      }
    } else {
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {`${locale('subscriptions.notifications.cancelRenewalPartOne')} `}
            <strong>{subscription.productname}</strong>
            {` ${locale('subscriptions.notifications.cancelRenewalPartTwo')}`}
          </p>
        ),
        type: 'error',
      }));
      yield put(billingActions.subscriptions.cancelRenewal.failure());
    }
  }

  yield put(billingActions.subscriptions.cancel.set({
    questionId: null,
    optionId: null,
    questionValue: '',
    reason: '',
  }));
}

function* requestCancellingSignature(action) {
  yield put(authActions.tokens.jwt.update());

  const {
    productId, productType, history, openPreChatCancelFlow,
  } = action.payload;

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { cancel, items } = yield select(state => state.billing.subscriptions);

  const subscription = items.find(item => (Number(productId) === item.id && productType === item.type));

  const withRefund = !!subscription.refund.methodSelected;

  const data = {
    serviceId: productId,
    serviceType: productType,
    optionId: cancel.optionId,
    reason: cancel.reason,
    ...(withRefund && {
      refund: {
        withRefund,
        refundMethod: subscription.refund.methodSelected,
      },
    }),
  };

  const request = {
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/cancel`,
  };

  try {
    const response = yield call(api.whmcs.post, request);
    if (response.status === 200) {
      yield put(billingActions.subscriptions.cancelSignature.success());
      history.push(`${locale('routes.subscriptions')}`);
      yield put(commonActions.notifications.set({
        label: (
          <TextDescriptor descriptors={[
            textRegular(locale('subscriptions.notifications.cancelSignature.success.0')),
            textBold(subscription.productName),
            textRegular(locale('subscriptions.notifications.cancelSignature.success.1')),
          ]}
          />
        ),
        type: 'error',
      }));
      openPreChatCancelFlow();
    }
  } catch (e) {
    yield put(billingActions.subscriptions.cancelSignature.failure());
    yield put(commonActions.notifications.set({
      label: (
        <TextDescriptor descriptors={[
          textRegular(locale('subscriptions.notifications.cancelSignature.failure.0')),
          textBold(subscription.productName),
          textRegular(locale('subscriptions.notifications.cancelSignature.failure.1')),
        ]}
        />
      ),
      type: 'error',
    }));
  }

  yield put(billingActions.subscriptions.cancel.set({
    questionId: null,
    optionId: null,
    questionValue: '',
    reason: '',
  }));
}

function* requestActivatingRenewal(action) {
  yield put(authActions.tokens.jwt.update());
  const { subscription, setModalById } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const request = {
    data: {
      serviceType: subscription.type,
      serviceId: subscription.id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/active_renewal`,
  };
  try {
    yield call(api.whmcs.post, request);
    yield put(billingActions.subscriptions.request());
    yield take([BillingActionTypes.GET_SUBSCRIPTIONS_SUCCESS, BillingActionTypes.GET_SUBSCRIPTIONS_FAILURE]);


    if (setModalById != null) {
      setModalById(subscription.modal !== EnumActionOpeningModal.MODAL_INVOICE_GENERATION ? Modais.ACTIVE_RENOVATION_SUBSCRIPTION_SUCCESS_MODAL : Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    } else {
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {`${locale('subscriptions.notifications.activateRenewal.success.0')} `}
            <strong>{subscription.productname}</strong>
            {`${locale('subscriptions.notifications.activateRenewal.success.1')} `}
          </p>
        ),
        type: 'success',
      }));
    }
  } catch (e) {
    if (setModalById != null) {
      setModalById(Modais.ACTIVE_RENOVATION_SUBSCRIPTION_SUCCESS_MODAL);
    }

    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('subscriptions.notifications.activateRenewal.failure.0')} `}
          <strong>{subscription.productname}</strong>
          {`${locale('subscriptions.notifications.activateRenewal.failure.1')} `}
        </p>
      ),
      type: 'error',
    }));
  }
}

function* requestRefundCheck(action) {
  const { subscriptionId, subscriptionType } = action.payload;

  const { token } = yield select(state => state.auth);

  const request = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/refund?serviceId=${subscriptionId}&serviceType=${subscriptionType}`,
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const refundData = billingAssembly.receiveRefund(response.data);
    yield put(billingActions.subscription.refund.check.set(refundData, subscriptionId, subscriptionType));
    yield put(billingActions.subscription.refund.check.success());
  } catch (e) {
    yield put(billingActions.subscription.refund.check.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* requestSwitchPaymentMethod(action) {
  yield put(authActions.tokens.jwt.update());
  const { subscription, paymentMethod, onSuccess } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    data: {
      newPaymentMethod: paymentMethod,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/${subscription.type}/${subscription.id}`,
  };

  yield put(billingActions.subscriptions.switchPaymentMethod.setLoading(true));
  try {
    yield call(api.whmcs.put, request);

    yield put(billingActions.subscriptions.switchPaymentMethod.success());
    onSuccess(true);

    yield put(billingActions.subscriptions.request());
    yield take([BillingActionTypes.GET_SUBSCRIPTIONS_SUCCESS, BillingActionTypes.GET_SUBSCRIPTIONS_FAILURE]);
  } catch (e) {
    yield put(billingActions.subscriptions.switchPaymentMethod.failure());
    onSuccess(false);
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('subscriptions.notifications.switchPaymentMethod.failure')} `}
        </p>
      ),
      type: 'error',
    }));
  }
  yield put(billingActions.subscriptions.switchPaymentMethod.setLoading(false));
}


function* getSubscriptionAbleToDiscount(action) {
  const { hostingId } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/check-hosting-able-discount/${hostingId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const discountData = billingAssembly.receiveAbleToDiscount(response.data);
    yield put(billingActions.subscriptions.ableToDiscount.set(discountData, hostingId));
    yield put(billingActions.subscriptions.ableToDiscount.success());
  } catch (e) {
    yield put(billingActions.subscriptions.ableToDiscount.failure());
  }

  yield put(commonActions.loading.set(false));
}


function* getSubscriptionAbleToCancelOffer(action) {
  const {
    subscriptionId, subscriptionType, optionId, actionSuccess, actionFail,
  } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/verify-cancel-offer/${optionId}/${subscriptionType}/${subscriptionId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const offerRetentionCancel = response && response.data.data ? response.data.data.offer : '';
    yield put(billingActions.subscriptions.ableToCancelOffer.set(offerRetentionCancel));
    actionSuccess && actionSuccess();
    yield put(billingActions.subscriptions.ableToCancelOffer.success());
  } catch (e) {
    actionFail && actionFail();
    yield put(billingActions.subscriptions.ableToDiscount.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* getDiscountValues(action) {
  const { hostingId } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/values/${hostingId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const discountData = billingAssembly.receiveDiscountValues(response.data);
    yield put(billingActions.subscriptions.discountValues.set(discountData, hostingId));
    yield put(billingActions.subscriptions.discountValues.success());
  } catch (e) {
    yield put(billingActions.subscriptions.discountValues.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* checkHostingAbleDiscount(action) {
  const { subscriptionId } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/check-hosting-able-discount/${subscriptionId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);

    const subscription = billingAssembly.receiveRetentioData(response.data);
    yield put(billingActions.hostingAbleDiscountRequest.set(subscription));

    yield put(billingActions.hostingAbleDiscountRequest.success());
  } catch (e) {
    const subscription = {};
    yield put(billingActions.hostingAbleDiscountRequest.set(subscription));
    yield put(billingActions.hostingAbleDiscountRequest.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* getHostBenefitBilling(action) {
  const { hostingId } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    data: {
      hosting_id: hostingId,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/retention`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.post, request);
    const invoice = billingAssembly.receiveHostBenefitBilling(response.data);
    yield put(billingActions.invoice.set(invoice));
    yield put(billingActions.invoice.success());
  } catch (e) {
    yield put(billingActions.invoice.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* requestDraftInvoicePublish(action) {
  const { invoiceId, executePayment } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/retention/${invoiceId}/publish`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.post, request);
    if (response.data && response.data.data && response.data.data.success) {
      executePayment(response.data.success);
    } else {
      executePayment(false);
    }
    yield put(billingActions.invoice.success());
  } catch (e) {
    yield put(billingActions.invoice.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* requestReactivateSubscription(action) {
  const { invoiceId, redirect } = action.payload;

  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/hostings/trust-reactivation`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
      brand: COUNTRY.toLowerCase(),
    },
    data: { invoice_id: invoiceId },
  };

  yield put(billingActions.subscriptions.reactivation.setLoading(true));

  try {
    const response = yield call(api.whmcs.post, request);
    yield call(getSubscriptions);

    if (response && response.data && response.data.data) {
      yield put(billingActions.subscriptions.reactivation.set(response.data.data));
      yield put(billingActions.subscriptions.reactivation.success());
      redirect && redirect();
    }
  } catch (e) {
    yield put(billingActions.subscriptions.reactivation.failure());
  } finally {
    yield put(billingActions.subscriptions.reactivation.setLoading(false));
  }
}

function* requestAcceptRetentionOffer(action) {
  const {
    subscriptionId, subscriptionType, offer, actionSuccess, showMessage,
  } = action.payload;

  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const request = {
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/accept-offer`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
      brand: COUNTRY.toLowerCase(),
    },
    data: {
      product_type: subscriptionType,
      product_id: subscriptionId,
      offer,
    },
  };

  yield put(billingActions.subscriptions.setLoadingStatusAcceptRetentionOffer(true));

  try {
    const response = yield call(api.whmcs.post, request);
    if (response && response.data && response.data.data === 'Offer accepted! Payment method updated successfully') {
      yield put(billingActions.subscriptions.acceptRetentionOffer.set(true));
      yield call(getSubscriptions);

      if (showMessage) {
        yield put(commonActions.notifications.set({
          label: (
            <p>
              {`${locale('billing.retentionOffer.toastSucces')}`}
            </p>
          ),
          type: 'success',
        }));
      }

      yield put(billingActions.subscriptions.acceptRetentionOffer.success());
      actionSuccess && actionSuccess();
    }
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('billing.defaultErrorMessage')}`}
        </p>
      ),
      type: 'error',
    }));
    yield put(billingActions.subscriptions.reactivation.failure());
  }

  yield put(billingActions.subscriptions.setLoadingStatusAcceptRetentionOffer(false));
}

function* requestSubscriptionsNotInCreditCard() {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const request = {
    url: `api/v2/clientarea/${COUNTRY}/signatures/boleto-and-pix`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(billingActions.subscriptions.setLoadingSubscriptionsNotInCreditCard(true));
  try {
    const response = yield call(api.whmcs.get, request);

    if (response && response.data && response.data.data) {
      yield put(billingActions.subscriptions.successRequestSubscriptionsNotInCreditCard());
      yield put(billingActions.subscriptions.setSubscriptionsNotInCreditCard(response.data.data));
    }
  } catch (e) {
    const error = { sagasError: e, userRequestData: request, errorMessage: e.message };
    logger.error('Error sagas requestSubscriptionsNotInCreditCard', error);
    yield put(billingActions.subscriptions.failureRequestSubscriptionsNotInCreditCard());
  }
}

function* migrateSubscriptionsToCreditCard(action) {
  const { list } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const request = {
    url: `api/v2/clientarea/${COUNTRY}/signatures/change-payment-method`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: list,
  };

  yield put(billingActions.subscriptions.setLoadingUpdateSubscriptionsNotInCreditCard(true));
  try {
    const response = yield call(api.whmcs.put, request);

    if (response && response.data && response.data.data) {
      yield put(billingActions.subscriptions.successUpdateSubscriptionsNotInCreditCard());
      yield put(billingActions.subscriptions.requestSubscriptionsNotInCreditCard());
    }
  } catch (e) {
    const error = { sagasError: e, userRequestData: request, errorMessage: e.message };
    logger.error('Error sagas migrateSubscriptionsToCreditCard', error);
    yield put(billingActions.subscriptions.failureUpdateSubscriptionsNotInCreditCard());
  }
  yield put(billingActions.subscriptions.setLoadingUpdateSubscriptionsNotInCreditCard(false));
}

function* sagas() {
  yield takeLatest(BillingActionTypes.GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
  yield takeLatest(BillingActionTypes.GET_SUBCRIPTIONS_QUESTIONS_REQUEST, getSubscriptionsQuestions);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_CANCEL_RENEWAL_REQUEST, requestCancellingRenewal);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_ACTIVATE_RENEWAL_REQUEST, requestActivatingRenewal);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_CANCEL_SIGNATURE_REQUEST, requestCancellingSignature);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_REFUND_CHECK_REQUEST, requestRefundCheck);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_REQUEST, requestSwitchPaymentMethod);
  yield takeLatest(BillingActionTypes.GET_INVOICE_PAYMENT_METHODS_REQUEST, getInvoicePaymentMethods);
  yield takeLatest(BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_REQUEST, getSubscriptionAbleToDiscount);
  yield takeLatest(BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_REQUEST, getSubscriptionAbleToCancelOffer);
  yield takeLatest(BillingActionTypes.GET_DISCOUNT_VALUES_REQUEST, getDiscountValues);
  yield takeLatest(BillingActionTypes.CHECK_HOSTING_ABLE_DISCOUNT_REQUEST, checkHostingAbleDiscount);
  yield takeLatest(BillingActionTypes.GET_HOST_BENEFIT_BILLING_REQUEST, getHostBenefitBilling);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_REACTIVATION_REQUEST, requestReactivateSubscription);
  yield takeLatest(BillingActionTypes.SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_REQUEST, requestDraftInvoicePublish);
  yield takeLatest(BillingActionTypes.GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD, requestSubscriptionsNotInCreditCard);
  yield takeLatest(BillingActionTypes.UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD, migrateSubscriptionsToCreditCard);
  yield takeLatest(BillingActionTypes.ACCEPT_RETENTION_OFFER_REQUEST, requestAcceptRetentionOffer);
}

export default sagas;
