import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, noGap }) => css`
    display: flex;
    flex-direction: column;
    gap: ${noGap ? '0px' : '24px'};
    margin: 32px 0px 0px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const CentralizedContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  min-height: 240px;
  width: 100%;
`;

export const TitleContainer = styled(Container)`
  align-items: center;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `}
`;

export const Card = styled.div`
  ${({ theme, variant = 'info', withMarginTop }) => css`
    background: ${variant === 'info' && theme.v2.colors.feedback.canceledLight};
    background: ${variant === 'problem' && theme.v2.colors.feedback.problemUltralight};
    border-radius: 8px;
    padding: 20px;
    margin-top: ${withMarginTop ? '24px' : '0'};
  `};
`;

export const CheckResultsContainer = styled(Container)`
  gap: 24px;
  flex-wrap: wrap;
`;

export const VerticalContainer = styled(Container)`
  flex-direction: column;
  gap: 4px;
`;

export const CheckResultTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `}
`;

export const CheckResultContent = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
  `}
`;

export const ScanDetailsContainer = styled(VerticalContainer)`
  gap: 20px;
`;

export const ScanDetailItem = styled.div`
  ${({ theme, smText }) => css`
    align-items: center;
    display: flex;
    gap: 12px;
    max-width: 648px;

    p, a, button {
      font-size: ${smText && theme.v2.font.sizes.xxxs};
    }
  `};
`;

export const SekeletonRow = styled.div`
  ${({ margin, column }) => css`
    display: flex;
    flex-direction: ${column ? 'column' : 'row'};
    flex-wrap: wrap;
    gap: ${column ? '8px' : '16px'};
    margin: ${margin ? '24px 0px 0px' : '0px'};
  `}
`;
