import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${() => css`
    flex: 1;
    padding-bottom: 80px;
  `}
`;

export const SubscriptionAdapter = styled.li`
  ${({ variant, theme }) => {
    const variants = {
      oldLayout: {
        marginTop: '8px',
        marginTopMD: '0px',

      },
      newLayout: {
        marginTop: '1px',
        marginTopMD: '8px',
      },
    };

    return css`
    background: ${theme.v2.colors.white.primary};
    list-style: none;
  &:not(:first-child) {
    margin-top: ${variants[variant].marginTop};
  }

  @media (max-width: ${theme.v2.breakpoints.md}){
    margin-top: ${variants[variant].marginTopMD};
  }

  `;
  }}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 8px 0px
    }
  `}
`;
