import styled, { css } from 'styled-components';

const defaultWrapperWidth = '540px';

export const Form = styled.form`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `};
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const BlockTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const DestinationBlockTitle = styled(BlockTitle)`
  margin: 24px 0px
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0px 0px;
  max-width: ${defaultWrapperWidth};

  div {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
`;

export const FileInputContainer = styled.div`
  textarea {
    padding: 4px;
  }
`;

export const InputTitle = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.medium};
  `};
`;

export const InputDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-size: 12px;
    margin: 8px 0 0;
  `};
`;

export const Info = styled.span`
  ${({ theme }) => css`
    display: block;
    flex-direction: row;
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;
    color: ${theme.v2.colors.neutral.lowPure};

    li {
      margin-bottom: 8px;
    }

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const InfoText = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activeMedium};
    display: inline-block;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    gap: 8px;
    lin-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const ButtonWrapper = styled.div`
  button {
    max-height: 35px;
  }
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 24px 0px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const FileWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const FileButton = styled.label`
  ${({ theme, file }) => css`
    background-color:${file ? theme.v2.colors.feedback.activeLight : theme.v2.colors.white.primary};
    border-color: ${file ? theme.v2.colors.feedback.activeLight : theme.v2.colors.brand.primaryBlue};
    border-radius: 20px;
    border-style: dashed;
    border-width: 2px;
    color: ${file ? theme.v2.colors.white.primary : theme.v2.colors.brand.primaryBlue};
    cursor: pointer;
    display: inline-flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    lin-height: ${theme.v2.font.lineHeight.text};
    padding: 6px 24px;
    transition:background-color 150ms ease-in-out, border 150ms ease-in-out, color 150ms ease-in-out;

    &:hover {
      border-color: ${theme.v2.colors.brand.primaryBlue};
      background-color: ${theme.v2.colors.brand.primaryBlue};
      color: ${theme.v2.colors.white.primary};
    }
  `}
`;

export const FileDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const FileInput = styled.input`
  display:none;
`;
