const styles = theme => ({
  messageBlock: {
    alignItems: 'flex-start',
    border: `1px solid ${theme.color.havelockBlue}`,
    color: theme.color.tundora,
    display: 'inline-flex',
    fontSize: 12,
    lineHeight: '18px',
    maxWidth: 706,
    padding: '14px 18px',

    '& svg': {
      height: 18,
      marginRight: 6,
      width: 18,
    },
  },
  messageError: {
    border: `1px solid ${theme.color.valencia}`,
  },
  messageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& > div, & > p': {
      display: 'block',
      width: '100%',
    },
  },
  messageText: {
    color: theme.color.tundora,
    fontSize: 12,
    lineHeight: '14px',
    marginBottom: 14,
  },
  linkList: {
    color: theme.color.indigo,
    fontSize: 14,
    lineHeight: '17px',
    margin: 0,
    marginBottom: 14,
    paddingLeft: 15,
    textDecoration: 'underline',

    '& a': {
      color: theme.color.indigo,
    },
  },
  messageLink: {
    color: theme.color.indigo,
  },
  loaderWrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    padding: '14px 0',
  },
});

export default styles;
