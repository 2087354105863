/* eslint-disable no-empty */
import useLocale from '@/hooks/useLocale';
import { useCallback, useState } from 'react';
import yaml from 'js-yaml';
import { COUNTRY, config } from '@/config';
import { useHistory } from 'react-router';
import { HgProducts } from '@/components/SupportForms/SupportForms.types';
import { brForms, esForms } from './localForms';
import { isBRServer } from '@/utils/Validators/validation';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  CHARLIE_CHANGE_PAYMENT_CYCLE_FORM,
  CHARLIE_INSTALL_WHMCS,
  CHARLIE_FORM_MORE_EMAILS,
  CHARLIE_MIGRADE_SUPPORT_FORMS_CHANGE_PRIMARY_DOMAIN, CHARLIE_MIGRADE_SUPPORT_FORMS_GENMERAL_DOUBTS, CHARLIE_MIGRATE_SUPPORT_FORMS, CHARLIE_MIGRATE_SUPPORT_FORMS_SECCOND_WAVE, CHARLIE_MIGRATE_SUPPORT_NEW_VPS_UPGRADE,
  CHARLIE_PRIVATE_SSL_CERTIFICATE,
  CHARLIE_REBUILD_RELOAD,
  CHARLIE_UPGRADE_PLAN_FORMS,
  CHARLIE_SERVER_MIGRATION_VPS_DEDICATED,
  CHAR_UPDATE_WHMCS_LICENSE,
  CHARLIE_WHMCS_ACQUISITION_FORM,
  CHARLIE_MIGRATE_RESELLER,
} from '@/config/GrowthBook/constants';

const useSupportForms = ({
  defaultLoading = true,
  defaultProductsOptions = [],
  defaultFormsValue = [],
  defaultSelectedProductOption = '',
  defaultProductsArray = [],
  defaultSelectedNeed = null,
  defaultTechnicalOptions = [],
  defaultBillingOptions = [],
  defaultSelectedSector = '',
  defaultselectedProductData = null,
}) => {
  const history = useHistory();
  const newForms = useFeatureIsOn(CHARLIE_MIGRATE_SUPPORT_FORMS);
  const newFormsWave2 = useFeatureIsOn(CHARLIE_MIGRATE_SUPPORT_FORMS_SECCOND_WAVE);
  const upgradeVpsV2 = useFeatureIsOn(CHARLIE_MIGRATE_SUPPORT_NEW_VPS_UPGRADE);
  const changePrimaryDomainV2 = useFeatureIsOn(CHARLIE_MIGRADE_SUPPORT_FORMS_CHANGE_PRIMARY_DOMAIN);
  const generalDoubtsV2 = useFeatureIsOn(CHARLIE_MIGRADE_SUPPORT_FORMS_GENMERAL_DOUBTS);
  const upgradePlanForms = useFeatureIsOn(CHARLIE_UPGRADE_PLAN_FORMS);
  const changePaymentCycleForm = useFeatureIsOn(CHARLIE_CHANGE_PAYMENT_CYCLE_FORM);
  const privateSslCertificate = useFeatureIsOn(CHARLIE_PRIVATE_SSL_CERTIFICATE);
  const installWhmcsForm = useFeatureIsOn(CHARLIE_INSTALL_WHMCS);
  const reloadrebuild = useFeatureIsOn(CHARLIE_REBUILD_RELOAD);
  const morEmails = useFeatureIsOn(CHARLIE_FORM_MORE_EMAILS);
  const migrateVpsDedicated = useFeatureIsOn(CHARLIE_SERVER_MIGRATION_VPS_DEDICATED);
  const updateWhmcsLicense = useFeatureIsOn(CHAR_UPDATE_WHMCS_LICENSE);
  const whmcsAcquisition = useFeatureIsOn(CHARLIE_WHMCS_ACQUISITION_FORM);
  const migrateReseller = useFeatureIsOn(CHARLIE_MIGRATE_RESELLER);
  const { ticket: ticketsLocale, routes: routesLocale } = useLocale();
  const [billingOptions, setBillingOptions] = useState(defaultBillingOptions);
  const [technicalOptions, setTechnicalOptions] = useState(defaultTechnicalOptions);
  const [products, setProducts] = useState(defaultProductsArray);
  const [defaultForms, setDefaultForms] = useState(defaultFormsValue);
  const [productsOptions, setProductsOptions] = useState(defaultProductsOptions);
  const [loading, setLoading] = useState(defaultLoading);
  const [selectedProductOption, setSelectedProductOption] = useState(defaultSelectedProductOption);
  const [selectedProductFormmd, setSelectedProductFormmd] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductData, setSelectedProductData] = useState(defaultselectedProductData);
  const [selectedSector, setSelectedSector] = useState(defaultSelectedSector);
  const [selectedNeed, setSelectedNeed] = useState(defaultSelectedNeed);
  const [needsOptions, setNeedsOptions] = useState(null);
  const [activeProductsOptions, setActiveProductsOptions] = useState([]);

  const accountFormsV2 = useCallback(() => {
    const formsArray = [{
      label: ticketsLocale.forms.names.withoutEmailAccess,
      id: ticketsLocale.forms.names.withoutEmailAccess,
      key: ticketsLocale.forms.names.withoutEmailAccess,
      v2: true,
    }];

    if (newFormsWave2) {
      formsArray.push({
        label: ticketsLocale.forms.names.affiliateProgram,
        id: ticketsLocale.forms.names.affiliateProgram,
        key: ticketsLocale.forms.names.affiliateProgram,
        v2: true,
      });

      isBRServer && formsArray.push({
        label: ticketsLocale.forms.names.nationalDomainTransfer,
        id: ticketsLocale.forms.names.nationalDomainTransfer,
        key: ticketsLocale.forms.names.nationalDomainTransfer,
        v2: true,
      });
    }

    return formsArray;
  }, [ticketsLocale, newFormsWave2]);

  const technicalFormsV2 = useCallback(() => {
    const formsArray = [{
      label: ticketsLocale.forms.names.internalMigration,
      id: ticketsLocale.forms.names.internalMigration,
      key: ticketsLocale.forms.names.internalMigration,
      v2: true,
    }];

    formsArray.push({
      label: ticketsLocale.forms.names.restorateBackupHgRoutine,
      id: ticketsLocale.forms.names.restorateBackupHgRoutine,
      key: ticketsLocale.forms.names.restorateBackupHgRoutine,
      v2: true,
    });

    formsArray.push({
      label: ticketsLocale.forms.names.userBackupRestore,
      id: ticketsLocale.forms.names.userBackupRestore,
      key: ticketsLocale.forms.names.userBackupRestore,
      v2: true,
    });

    formsArray.push({
      label: ticketsLocale.forms.names.generateBackup,
      id: ticketsLocale.forms.names.generateBackup,
      key: ticketsLocale.forms.names.generateBackup,
      v2: true,
    });

    if (changePrimaryDomainV2) {
      formsArray.push({
        label: ticketsLocale.forms.names.changePrimaryDomain,
        id: ticketsLocale.forms.names.changePrimaryDomain,
        key: ticketsLocale.forms.names.changePrimaryDomain,
        v2: true,
      });
    }

    if (newForms) {
      formsArray.push({
        label: ticketsLocale.forms.names.externalMigration,
        id: ticketsLocale.forms.names.externalMigration,
        key: ticketsLocale.forms.names.externalMigration,
        v2: true,
      });
    }

    formsArray.push({
      label: ticketsLocale.forms.names.googleAdwordsCoupon,
      id: ticketsLocale.forms.names.googleAdwordsCoupon,
      key: ticketsLocale.forms.names.googleAdwordsCoupon,
      v2: true,
    });

    formsArray.push({
      label: ticketsLocale.forms.names.installExternalSsl,
      id: ticketsLocale.forms.names.installExternalSsl,
      key: ticketsLocale.forms.names.installExternalSsl,
      v2: true,
    });

    formsArray.push({
      label: ticketsLocale.forms.names.restorateCancelledPlan,
      id: ticketsLocale.forms.names.restorateCancelledPlan,
      key: ticketsLocale.forms.names.restorateCancelledPlan,
      v2: true,
    });

    isBRServer && formsArray.push({
      label: ticketsLocale.forms.names.generateCSR,
      id: ticketsLocale.forms.names.generateCSR,
      key: ticketsLocale.forms.names.generateCSR,
      v2: true,
    });

    isBRServer && formsArray.push({
      label: ticketsLocale.forms.names.restorateSiteBuilderAccess,
      id: ticketsLocale.forms.names.restorateSiteBuilderAccess,
      key: ticketsLocale.forms.names.restorateSiteBuilderAccess,
      v2: true,
    });

    if (privateSslCertificate) {
      formsArray.push({
        label: ticketsLocale.forms.names.privateSslCertificate,
        id: ticketsLocale.forms.names.privateSslCertificate,
        key: ticketsLocale.forms.names.privateSslCertificate,
        v2: true,
      });

      formsArray.push({
        label: ticketsLocale.forms.names.widlcardSslCertificate,
        id: ticketsLocale.forms.names.widlcardSslCertificate,
        key: ticketsLocale.forms.names.widlcardSslCertificate,
        v2: true,
      });

      isBRServer && formsArray.push({
        label: ticketsLocale.forms.names.enterPrisessslCertificate,
        id: ticketsLocale.forms.names.enterPrisessslCertificate,
        key: ticketsLocale.forms.names.enterPrisessslCertificate,
        v2: true,
      });
    }

    if (isBRServer && installWhmcsForm) {
      formsArray.push({
        label: ticketsLocale.forms.names.installWhmcs,
        id: ticketsLocale.forms.names.installWhmcs,
        key: ticketsLocale.forms.names.installWhmcs,
        v2: true,
      });
    }

    if (reloadrebuild) {
      formsArray.push({
        label: ticketsLocale.forms.names.reloadRebuild,
        id: ticketsLocale.forms.names.reloadRebuild,
        key: ticketsLocale.forms.names.reloadRebuild,
        v2: true,
      });
    }

    if (morEmails) {
      formsArray.push({
        label: ticketsLocale.forms.names.moreEmails,
        id: ticketsLocale.forms.names.moreEmails,
        key: ticketsLocale.forms.names.moreEmails,
        v2: true,
      });
    }

    if (migrateVpsDedicated) {
      isBRServer && formsArray.push({
        label: ticketsLocale.forms.names.migrateVpsDedicated,
        id: ticketsLocale.forms.names.migrateVpsDedicated,
        key: ticketsLocale.forms.names.migrateVpsDedicated,
        v2: true,
      });

      !isBRServer && formsArray.push({
        label: ticketsLocale.forms.names.migrateAccountVpsDedicated,
        id: ticketsLocale.forms.names.migrateAccountVpsDedicated,
        key: ticketsLocale.forms.names.migrateAccountVpsDedicated,
        v2: true,
      });
    }

    if (updateWhmcsLicense) {
      formsArray.push({
        label: ticketsLocale.forms.names.updateWhmcsLicense,
        id: ticketsLocale.forms.names.updateWhmcsLicense,
        key: ticketsLocale.forms.names.updateWhmcsLicense,
        v2: true,
      });
    }

    if (whmcsAcquisition) {
      formsArray.push({
        label: ticketsLocale.forms.names.whmcsAcquisition,
        id: ticketsLocale.forms.names.whmcsAcquisition,
        key: ticketsLocale.forms.names.whmcsAcquisition,
        v2: true,
      });
    }

    if (migrateReseller) {
      formsArray.push({
        label: ticketsLocale.forms.names.migrateReseller,
        id: ticketsLocale.forms.names.migrateReseller,
        key: ticketsLocale.forms.names.migrateReseller,
        v2: true,
      });
    }

    return formsArray;
  }, [
    ticketsLocale,
    newForms,
    changePrimaryDomainV2,
    privateSslCertificate,
    installWhmcsForm,
    reloadrebuild,
    morEmails,
    migrateVpsDedicated,
    updateWhmcsLicense,
    whmcsAcquisition,
    migrateReseller,
  ]);

  const billingFormsV2 = useCallback(() => {
    const formsArray = [];

    if (generalDoubtsV2) {
      formsArray.push({
        label: ticketsLocale.forms.names.financialDoubts,
        id: ticketsLocale.forms.names.financialDoubts,
        key: ticketsLocale.forms.names.financialDoubts,
        v2: true,
      });
    }

    if (newForms) {
      formsArray.push({
        label: ticketsLocale.forms.names.changeDueDate,
        id: ticketsLocale.forms.names.changeDueDate,
        key: ticketsLocale.forms.names.changeDueDate,
        v2: true,
      });
    }

    if (upgradeVpsV2) {
      formsArray.push({
        label: ticketsLocale.forms.names.upgradeVps,
        id: ticketsLocale.forms.names.upgradeVps,
        key: ticketsLocale.forms.names.upgradeVps,
        v2: true,
      });
    }

    if (upgradePlanForms) {
      if (isBRServer) {
        formsArray.push({
          label: ticketsLocale.forms.names.upgradeSiteLock,
          id: ticketsLocale.forms.names.upgradeSiteLock,
          key: ticketsLocale.forms.names.upgradeSiteLock,
          v2: true,
        });
      }


      formsArray.push({
        label: ticketsLocale.forms.names.upgradeCodeGuard,
        id: ticketsLocale.forms.names.upgradeCodeGuard,
        key: ticketsLocale.forms.names.upgradeCodeGuard,
        v2: true,
      });
    }

    if (changePaymentCycleForm) {
      formsArray.push({
        label: ticketsLocale.forms.names.ChangePaymentcycle,
        id: ticketsLocale.forms.names.ChangePaymentcycle,
        key: ticketsLocale.forms.names.ChangePaymentcycle,
        v2: true,
      });
    }

    return formsArray;
  }, [ticketsLocale, newForms, upgradeVpsV2, generalDoubtsV2, upgradePlanForms, changePaymentCycleForm]);

  const removeWhmcsForms = useCallback((whmcsList) => {
    let newList = [...whmcsList];
    if (newForms) {
      newList = newList.filter(form => form.key !== 'reloadrebuild');
      newList = newList.filter(form => form.key !== 'changeduedate');

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('restauração de plano cancelado') || item.label.toLowerCase().includes('restauración de plan cancelado'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('migração externa') || item.label.toLowerCase().includes('migración externa'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('alteração de domínio primário') || item.label.toLowerCase().includes('alteración de dominio primario'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('cupom google adwords') || item.label.toLowerCase().includes('cupón de google adwords (es)'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('instalação de ssl de outra empresa') || item.label.toLowerCase().includes('instalación del ssl de otra empresa'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('gerar csr'))) {
          return item;
        }

        return false;
      });

      newList = newList.filter(form => !form.label.toLowerCase().includes(ticketsLocale.forms.names.financialDoubts));

      newList = newList.filter(form => !form.label.toLowerCase().includes(ticketsLocale.forms.names.restorateSiteBuilderAccess));

      newList = newList.filter((item) => {
        if (item && item.label && !(item.label.toLowerCase().includes('acesso ao editor do criador de sites') || item.label.toLowerCase().includes('acceso al editor del creador de sitios'))) {
          return item;
        }

        return false;
      });
    }

    if (newFormsWave2) {
      newList = newList.filter(form => !form.label.toLowerCase().includes('afiliados'));
      newList = newList.filter(form => !form.label.toLowerCase().includes(ticketsLocale.openNewTicketPage.textManipulation.startsWith.nationalDomainTransfer));
    }

    if (upgradePlanForms) {
      if (isBRServer) {
        newList = newList.filter((item) => {
          const isFromWhmcs = item.label.toLowerCase().includes('sitelock')
            && item.key !== ticketsLocale.forms.names.upgradeSiteLock;
          return !isFromWhmcs;
        });
      }

      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('codeguard')
          && item.key !== ticketsLocale.forms.names.upgradeSiteLock;
        return !isFromWhmcs;
      });
    }

    if (changePaymentCycleForm) {
      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('alterar ciclo')
          && item.key !== ticketsLocale.forms.names.ChangePaymentcycle;
        return !isFromWhmcs;
      });
    }

    if (privateSslCertificate) {
      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('certificado ssl privado')
          && item.key !== ticketsLocale.forms.names.privateSslCertificate;
        return !isFromWhmcs;
      });

      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('certificado ssl wildcard')
          && item.key !== ticketsLocale.forms.names.widlcardSslCertificate;
        return !isFromWhmcs;
      });

      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('certificado ssl empresarial')
          && item.key !== ticketsLocale.forms.names.widlcardSslCertificate;
        return !isFromWhmcs;
      });
    }

    if (generalDoubtsV2) {
      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('dudas financieras')
          && item.key !== ticketsLocale.forms.names.financialDoubts;
        return !isFromWhmcs;
      });
    }

    if (installWhmcsForm) {
      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('instalação do whmcs')
          && item.key !== ticketsLocale.forms.names.installWhmcs;
        return !isFromWhmcs;
      });
    }

    if (reloadrebuild) {
      newList = newList.filter((item) => {
        const isFromWhmcs = item.label.toLowerCase().includes('Solicitação de Reload/Rebuild')
          && item.key !== ticketsLocale.forms.names.reloadRebuild;
        return !isFromWhmcs;
      });
    }

    if (morEmails) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('aumento de envio de emails')
          || item.label.toLowerCase().includes('aumento del envío de correos')
        )
          && item.key !== ticketsLocale.forms.names.moreEmails;
        return !isFromWhmcs;
      });
    }

    if (migrateVpsDedicated) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('migração de servidor dedicado e vps')
              || item.label.toLowerCase().includes('migración de cuenta de vps/dedicado')
        )
            && item.key !== (isBRServer ? ticketsLocale.forms.names.migrateVpsDedicated : ticketsLocale.forms.names.migrateAccountVpsDedicated);
        return !isFromWhmcs;
      });
    }

    if (upgradeVpsV2) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('Upgrade de un plan')
          || item.label.toLowerCase().includes('Upgrade de Plano')
        )
            && item.key !== ticketsLocale.forms.names.upgradeVps;
        return !isFromWhmcs;
      });
    }

    if (updateWhmcsLicense) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('atualização de licença whmcs')
            || item.label.toLowerCase().includes('actualización de licencia whmcs')
        )
              && item.key !== ticketsLocale.forms.names.updateWhmcsLicense;
        return !isFromWhmcs;
      });
    }

    if (whmcsAcquisition) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('aquisição licença whmcs')
            || item.label.toLowerCase().includes('adquisición de la licencia de whmcs')
        )
              && item.key !== ticketsLocale.forms.names.whmcsAcquisition;
        return !isFromWhmcs;
      });
    }

    if (migrateReseller) {
      newList = newList.filter((item) => {
        const isFromWhmcs = (
          item.label.toLowerCase().includes('migração de revenda')
            || item.label.toLowerCase().includes('migración de cuentas de revendedor')
        )
              && item.key !== ticketsLocale.forms.names.migrateReseller;
        return !isFromWhmcs;
      });
    }

    return newList;
  }, [
    newForms,
    ticketsLocale,
    newFormsWave2,
    upgradePlanForms,
    changePaymentCycleForm,
    privateSslCertificate,
    generalDoubtsV2,
    installWhmcsForm,
    reloadrebuild,
    morEmails,
    migrateVpsDedicated,
    upgradeVpsV2,
    updateWhmcsLicense,
    whmcsAcquisition,
    migrateReseller,
  ]);

  const validate = useCallback(({ selectedProductData, rule }) => {
    const { method } = rule;
    let groups = null;
    let ruleProducts = null;
    let isOk = false;

    if (rule.or) {
      const ruleResult = rule.or.some(rule => validate({ selectedProductData, rule })); // recursive call
      return ruleResult;
    }

    if (rule.and) {
      const ruleResult = rule.and.every(rule => validate({ selectedProductData, rule })); // recursive call
      return ruleResult;
    }

    switch (method) {
      case 'isInGroup':
        groups = rule.vars.map(r => HgProducts[r]);
        if (groups) {
          groups.forEach((group) => {
            if (group && group.includes(selectedProductData.pid)) {
              isOk = true;
            }
          });
        }
        return isOk;
      case 'isNotInGroup':
        groups = rule.vars.map(r => HgProducts[r]);
        if (groups) {
          groups.forEach((group) => {
            if (!group.includes(selectedProductData.pid)) {
              isOk = true;
            }
          });
        }
        return isOk;
      case 'isProduct':
        if (Array.isArray(rule.vars)) {
          ruleProducts = rule.vars.map(r => HgProducts[r]);
          return ruleProducts.includes(selectedProductData.pid);
        }
        return rule.vars === selectedProductData.pid;
      case 'notIsProduct':
        if (Array.isArray(rule.vars)) {
          ruleProducts = rule.vars.map(r => HgProducts[r]);
          return !ruleProducts.includes(selectedProductData.pid);
        }
        return rule.vars !== selectedProductData.pid;
      case 'eq':
        if (rule.vars[0].pop() === 'brand') {
          switch (rule.vars[1]) {
            case 1:
              return COUNTRY === 'mx';
            case 2:
              return COUNTRY === 'cl';
            case 3:
              return COUNTRY === 'co';
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  }, []);

  const parseForm = (data) => {
    const splitted = data.split('---');
    const form = yaml.load(splitted[1]);
    return form;
  };

  const requestForms = useCallback(() => {
    try {
      fetch(`${config.API_URL}/formconfig_new`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
        .then((response) => {
          try {
            const jsonResp = response.json();
            return jsonResp;
          } catch (e) {
            return response;
          }
        })
        .then((formData) => {
          const { products } = formData.data;
          let defaultForms = formData.data.forms;

          const frontForms = isBRServer ? brForms({ products }) : esForms({ brand: COUNTRY });
          frontForms.forEach((frontForm) => {
            const newForm = {
              name: frontForm.subject,
              id: frontForm.subject,
              key: frontForm.key,
              form: { ...frontForm },
            };
            defaultForms.push(newForm);
          });

          defaultForms = defaultForms.filter(item => !!item.form && !!item.name).map((item, index) => {
            const newItem = { ...item };
            if (!newItem.key) {
              newItem.key = `formmd${index}`;
            }
            newItem.label = newItem.name;
            delete newItem.name;
            try {
              newItem.form = parseForm(newItem.form);
              if (!newItem.form.showWhen) {
                newItem.form.showWhen = true;
              }
            } catch (e) { }
            return newItem;
          });

          let billingForms = [...defaultForms].filter(item => item.label.startsWith(ticketsLocale.openNewTicketPage.textManipulation.startsWith.billing));
          if (newForms) {
            billingForms = billingForms.filter(form => !form.label.includes(ticketsLocale.forms.names.financialDoubts));
          }
          billingForms = [...billingForms].map(item => ({
            ...item,
            label: item.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.billing} -`, ''),
          }));
          billingForms = removeWhmcsForms(billingForms);
          setBillingOptions([...billingForms]);

          let technicalForms = [...defaultForms].filter(item => item.label.startsWith(ticketsLocale.openNewTicketPage.textManipulation.startsWith.technical));
          technicalForms = removeWhmcsForms(technicalForms);
          technicalForms = [...technicalForms].map(item => ({
            ...item,
            label: item.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.technical} - `, ''),
          }));

          technicalFormsV2().forEach(form => technicalForms.push(form));
          billingFormsV2().forEach(form => billingForms.push(form));

          setTechnicalOptions([...technicalForms.sort((a, b) => {
            if (b.label > a.label) {
              return -1;
            }
            return 1;
          })]);

          setBillingOptions([...billingForms.sort((a, b) => {
            if (b.label > a.label) {
              return -1;
            }
            return 1;
          })]);

          let productsOptions = [...defaultForms.filter(item => item.label.startsWith(ticketsLocale.openNewTicketPage.textManipulation.startsWith.myAccount)
            || item.label.startsWith(ticketsLocale.openNewTicketPage.textManipulation.startsWith.afiliated))];

          productsOptions = [...productsOptions].map(item => ({
            ...item,
            label: item.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.myAccount} - `, ''),
          }));
          productsOptions.unshift({ value: 'my_account', label: ticketsLocale.openNewTicketPage.selectProductGroups.myAccount, title: true });

          productsOptions = removeWhmcsForms(productsOptions);
          accountFormsV2().forEach(form => productsOptions.push(form));

          const activeProducts = [...products].filter(product => product.active);
          if (activeProducts.length) {
            setActiveProductsOptions(activeProducts);
            productsOptions.push({ value: 'active_products', label: ticketsLocale.openNewTicketPage.selectProductGroups.activeProducts, title: true });
          }
          activeProducts.forEach((product) => {
            productsOptions.push({
              label: `${product.name} - ${product.domain}`,
              value: product.id,
            });
          });

          const suspendedProducts = [...products].filter(product => !product.active);
          if (suspendedProducts.length) {
            productsOptions.push({ value: 'suspended_products', label: ticketsLocale.openNewTicketPage.selectProductGroups.suspendedProducts, title: true });
          }
          suspendedProducts.forEach((product) => {
            productsOptions.push({
              label: `${product.name} - ${product.domain}`,
              value: product.id,
            });
          });

          defaultForms = [...defaultForms].map((item) => {
            const newItem = item;
            newItem.label = newItem.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.myAccount} - `, '');
            newItem.label = newItem.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.technical} -`, '');
            newItem.label = newItem.label.replace(`${ticketsLocale.openNewTicketPage.textManipulation.startsWith.billing} -`, '');

            return newItem;
          });

          defaultForms = removeWhmcsForms(defaultForms);

          setProducts(products);
          setDefaultForms(defaultForms);
          setProductsOptions(productsOptions);
          setLoading(false);
        });
    } catch (e) { }
  }, [
    ticketsLocale,
    technicalFormsV2,
    billingFormsV2,
    removeWhmcsForms,
    newForms,
    accountFormsV2,
  ]);

  const checkProduct = useCallback(() => {
    if (productsOptions && selectedProductOption) {
      const option = defaultForms.find(item => item.label === selectedProductOption);

      if (option && option.id) {
        history.push(`${routesLocale.help}${routesLocale.createTicket}/${`${option.key}`}`);
        setSelectedProductFormmd(`${option.key}`);
      }

      if (!option) {
        const productSelectedOption = productsOptions.find(item => item.label === selectedProductOption);
        const product = products.find(item => item.id === productSelectedOption.value);
        if (product) {
          setSelectedProduct(product);
          setSelectedProductFormmd(product.id);
          setSelectedProductData(product);
        }
      }
    }
  }, [selectedProductOption, history, routesLocale, productsOptions, defaultForms, products]);

  const checkNeed = useCallback(() => {
    if (billingOptions && technicalOptions && selectedNeed) {
      const formsArray = selectedSector === 'technical' ? [...technicalOptions] : [...billingOptions];
      const option = formsArray.find(item => item.label === selectedNeed);

      if (option && option.id && !option.v2) {
        history.push(`${routesLocale.help}${routesLocale.createTicket}/${selectedProductFormmd}/${selectedSector}/${`${option.key}`}`);
      }
    }
  }, [
    history,
    billingOptions,
    technicalOptions,
    selectedNeed,
    routesLocale,
    selectedProductFormmd,
    selectedSector,
  ]);

  const removeFormsPerPlanAttributes = useCallback(({
    selectedProduct,
    HgProducts,
    formsOptions,
  }) => {
    let newFormsOptions = [...formsOptions];
    const isShared = HgProducts.SHARED_ALL.includes(selectedProduct.pid);
    const isWpHosting = HgProducts.WPHOSTING_ALL.includes(selectedProduct.pid);
    const isReseller = HgProducts.RESELLER_ALL.includes(selectedProduct.pid);
    const isVps = HgProducts.VPS_ALL.includes(selectedProduct.pid);
    const isDedicated = HgProducts.DEDICATED_ALL.includes(selectedProduct.pid);
    const isPlus = HgProducts.PLUS_ALL.includes(selectedProduct.pid);
    const isSitelock = HgProducts.SITELOCK_ALL.includes(selectedProduct.pid);
    const isCodeGuard = HgProducts.CODEGUARD_ALL.includes(selectedProduct.pid);
    const isStreaming = HgProducts.STREAMING_ALL.includes(selectedProduct.pid);
    const isWeebly = HgProducts.WEEBLY_ALL.includes(selectedProduct.pid);

    const isNewVps = [
      HgProducts.VPS_ENTRY,
      HgProducts.VPS_OPTIMIZED,
      HgProducts.VPS_PLATINUM,
      HgProducts.VPS_STANDARD,
    ].includes(selectedProduct.pid);

    const shouldRemoveChangePrimaryDomain = !isShared && !isWpHosting && !isReseller && !isVps && !isDedicated && !isPlus;
    const shouldRemoveInternalMigration = !isShared && !isPlus;
    const shouldRemoveExternalMigration = !isShared && !isPlus;
    const shouldRemoveGoogleAdwordsCoupon = !isShared && !isReseller && !isPlus;
    const shouldRemoveRestorateCancelledPlan = !isShared && !isReseller && !isPlus;
    const shouldRemoveRestorateSiteBuilderAccess = !isShared && !isReseller && !isPlus;
    const shouldRemoveSitelockUpgrade = isBRServer && !isSitelock;
    const shouldRemoveCodeGuardUpgrade = !isCodeGuard;
    const shouldRemoveChangeCycle = !isVps && !isWpHosting && !isStreaming && !isDedicated;
    const shouldRemoveEnterpriseSslCertificate = isBRServer && !isVps && !isDedicated && !isReseller && !isWeebly;
    const shouldRemovePrivateSslCertificate = isBRServer
      ? !isVps && !isDedicated && !isReseller
      : !isVps && !isDedicated && !isReseller && !isWeebly;
    const shouldRemoveReloadRebuild = !isVps && !isDedicated;
    const shouldRemoveMoreEmailsForms = !isVps && !isDedicated;
    const shouldRemoveExternalSsl = !isShared
    && !isReseller
    && !isVps
    && !isDedicated
    && !isPlus
      && HgProducts.WORDPRESS_PRO !== selectedProduct.pid
      && HgProducts.WORDPRESS_VIP !== selectedProduct.pid;
    const shouldRemoveInstallWhmcs = !isReseller && !isDedicated;
    const shouldRemoveMigrateVpsDedicated = !isVps && !isDedicated;
    const shouldRemoveVpsUpgrade = !isNewVps;
    const shouldRemoveUpdateWhmcs = !isReseller && !isDedicated;
    const shouldRemoveWhmcsAcquisition = !isReseller && !isDedicated;
    const shouldRemoveResellerMigration = !isReseller;


    if (shouldRemoveChangePrimaryDomain) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.changePrimaryDomain);
    }

    if (shouldRemoveInternalMigration) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.internalMigration);
    }

    if (shouldRemoveExternalMigration) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.externalMigration);
    }

    if (shouldRemoveGoogleAdwordsCoupon) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.googleAdwordsCoupon);
    }

    if (shouldRemoveExternalSsl) {
      newFormsOptions = newFormsOptions.filter(item => !(item && item.id && item.id.toString().includes(ticketsLocale.forms.names.installExternalSsl)));
    }

    if (shouldRemoveRestorateCancelledPlan) {
      newFormsOptions = newFormsOptions.filter(item => !(item && item.id && item.id.toString().includes(ticketsLocale.forms.names.restorateCancelledPlan)));
    }

    if (shouldRemoveRestorateSiteBuilderAccess) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.restorateSiteBuilderAccess);
    }

    if (shouldRemoveVpsUpgrade) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.upgradeVps);
    }

    if (shouldRemoveSitelockUpgrade) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.upgradeSiteLock);
    }

    if (shouldRemoveCodeGuardUpgrade) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.upgradeCodeGuard);
    }

    if (shouldRemoveChangeCycle) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.ChangePaymentcycle);
    }

    if (shouldRemovePrivateSslCertificate) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.privateSslCertificate);
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.widlcardSslCertificate);
    }

    if (shouldRemoveEnterpriseSslCertificate) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.enterPrisessslCertificate);
    }

    if (shouldRemoveInstallWhmcs) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.installWhmcs);
    }

    if (shouldRemoveReloadRebuild) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.reloadRebuild);
    }

    if (shouldRemoveMoreEmailsForms) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.moreEmails);
    }

    if (shouldRemoveMigrateVpsDedicated) {
      if (isBRServer) {
        newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.migrateVpsDedicated);
      }

      if (!isBRServer) {
        newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.migrateAccountVpsDedicated);
      }
    }

    if (shouldRemoveUpdateWhmcs) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.updateWhmcsLicense);
    }

    if (shouldRemoveWhmcsAcquisition) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.whmcsAcquisition);
    }

    if (shouldRemoveResellerMigration) {
      newFormsOptions = newFormsOptions.filter(item => item.id !== ticketsLocale.forms.names.migrateReseller);
    }

    return newFormsOptions;
  }, [
    ticketsLocale,
  ]);

  const checkSector = useCallback(() => {
    if (selectedSector) {
      let isOk = false;
      let formsOptions = selectedSector === 'technical' ? [...technicalOptions] : [...billingOptions];
      formsOptions = formsOptions.filter((item) => {
        if (item && item.form && item.form.showWhen === true) {
          isOk = item.form.showWhen;
        }

        if (item && item.form && item.form.showWhen instanceof Array) {
          isOk = item.form.showWhen.some(rule => validate({ selectedProductData, rule }));
        }

        if (item.v2) {
          isOk = true;
        }

        const isResellerOrShared = selectedProduct && (
          HgProducts.SHARED_ALL.includes(selectedProduct.pid)
          || HgProducts.RESELLER_ALL.includes(selectedProduct.pid)
          || HgProducts.VPS_ALL.includes(selectedProduct.pid)
          || HgProducts.DEDICATED_ALL.includes(selectedProduct.pid)
        );

        const isVps = selectedProduct && HgProducts.VPS_ALL.includes(selectedProduct.pid);
        const isDedicated = selectedProduct && HgProducts.DEDICATED_ALL.includes(selectedProduct.pid);

        const backupOptions = [
          ticketsLocale.forms.names.generateBackup,
          ticketsLocale.forms.names.restorateBackupHgRoutine,
          ticketsLocale.forms.names.userBackupRestore,
          54,
        ];

        if (!isResellerOrShared && item && backupOptions.includes(item.id)) {
          isOk = false;
        }

        if (selectedProduct && HgProducts.PLUS === selectedProduct.pid && item && item.id === ticketsLocale.forms.names.restorateBackupHgRoutine) {
          isOk = true;
        }

        if ((isVps || isDedicated) && item && item.id === ticketsLocale.forms.names.restorateBackupHgRoutine) {
          isOk = false;
        }

        return isOk;
      });

      if (selectedProduct && HgProducts) {
        formsOptions = removeFormsPerPlanAttributes({ selectedProduct, HgProducts, formsOptions });
      }

      setNeedsOptions(formsOptions);
    }
    setSelectedNeed(null);
  }, [
    billingOptions,
    technicalOptions,
    validate,
    selectedSector,
    selectedProductData,
    ticketsLocale,
    selectedProduct,
    removeFormsPerPlanAttributes,
  ]);

  const handleSelectedProductOption = useCallback((label) => {
    if (productsOptions) {
      setSelectedProductOption(label);
    }
    setSelectedProduct(null);
    setSelectedNeed(null);
    setSelectedSector(null);
  }, [productsOptions]);

  const useSupportFormsData = {
    requestForms,
    productsOptions,
    loading,
    checkProduct,
    checkNeed,
    checkSector,
    handleSelectedProductOption,
    selectedProductOption,
    selectedProduct,
    setSelectedSector,
    selectedSector,
    selectedNeed,
    needsOptions,
    setSelectedNeed,
    parseForm,
    requestedProducts: products,
    activeProductsOptions,
    setSelectedProductData,
    selectedProductData,
  };

  return useSupportFormsData;
};

export default useSupportForms;
