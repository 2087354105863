export const BackupActionTypes = {
  SET_BACKUP_REDIRECT_MODAL_SHOW: 'SET_BACKUP_REDIRECT_MODAL_SHOW',
  SET_REDIRECT_FROM_PRE_CHAT: 'SET_REDIRECT_FROM_PRE_CHAT',
  SET_BACKUP_GENERATION_LOADING: 'SET_BACKUP_GENERATION_LOADING',
  REQUEST_BACKUP_GENERATION: 'REQUEST_BACKUP_GENERATION',
  FAILURE_BACKUP_GENERATION: 'FAILURE_BACKUP_GENERATION',
  SUCCESS_BACKUP_GENERATION: 'SUCCESS_BACKUP_GENERATION',
  CLEAR_BACKUP_STATUS: 'CLEAR_BACKUP_STATUS',
  REQUEST_BACKUP_LOG: 'REQUEST_BACKUP_LOG',
  SET_LOADING_STATUS_BACKUP_LOG: 'SET_LOADING_STATUS_BACKUP_LOG',
  SET_SUCCESS_BACKUP_LOG: 'SET_SUCCESS_BACKUP_LOG',
  SET_FAILURE_BACKUP_LOG: 'SET_FAILURE_BACKUP_LOG',
  SET_BACKUP_LOG: 'SET_BACKUP_LOG',
  REQUEST_INODES_STATUS: 'REQUEST_INODES_STATUS',
  LOADING_INODES_STATUS: 'LOADING_INODES_STATUS',
  SUCCESS_INODES_STATUS: 'SUCCESS_INODES_STATUS',
  FAILURE_INODES_STATUS: 'FAILURE_INODES_STATUS',
  SET_INODES: 'SET_INODES',
};


export const BackupGenerationStatus = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};
