import styled, { css } from 'styled-components';

export const GtmModalWrapper = styled.div`
  align-items: center;
  background-color: rgba(31, 32, 68, 0.4);
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: 'none';
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      z-index: 99;
    }
    to {
      opacity: 0;
      z-index: -1;
    }
  }
  &.in {
    display: 'flex';
    opacity: 1;
    animation: fadeIn 0.2s 0.02s forwards;
    z-index: 1500;
  }
  &.out {
    animation: fadeOut 0.2s forwards;
    pointer-events: none;
    display: none;
  }
  &:not(.in) {
    display: none;
    pointer-events: none;
  }
`;

export const GtmModalContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
  }
`;

export const GtmModalCloseButton = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 24px;
    height: 48px;
    justify-content: center;
    margin: 0 0 16px auto;
    padding: 0;
    width: 48px;
    svg {
      pointer-events: none;
    }
    @media (min-width: ${theme.v1.breakpoints.lg}) {
      height: 38px;
      width: 38px;
    }
  `}
`;

export const DefaultContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0px 10px;
`;

export const DefaultPanel = styled.div`
  ${({ theme }) => css`
    background-color: 'transparent';
    flex: 1;
    max-height: 100vh;
    max-width: fit-content;
    overflow: auto;
    @media (min-width: ${theme.v1.breakpoints.lg}) {
      max-width: fit-content;
    }
  `}
`;
