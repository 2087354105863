import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import DOMPurify from 'dompurify';
import { handleTicketTime } from '@/utils/Formatters/handleTicketTime';
import TicketAttachmentsModal from '@/components/Tickets/TicketAttachmentsModal';
import FileIcon from '@/media/icons/FileIcon';
import styles from './styles';


class TicketMessage extends React.Component {
  state = {
    imgModalOpen: false,
    imageIndex: 0,
  }

  openImageModal = (index) => {
    this.setState({
      imgModalOpen: true,
      imageIndex: index,
    });
  }

  closeImageModal = () => {
    this.setState({
      imgModalOpen: false,
    });
  }

  handleFileExtension = (file) => {
    if (file) {
      const acceptedExtensionsList = ['pdf', 'txt', 'doc', 'docx', 'crt', 'key', 'zip', 'csv'];
      const extension = file.split('.').pop();
      if (acceptedExtensionsList.includes(extension)) {
        return true;
      }
    }
    return false;
  }

  getFileExtension = (file) => {
    const canHandleExtension = this.handleFileExtension(file);
    if (canHandleExtension) {
      const fileExtension = file.split('.').pop();
      return fileExtension;
    }
    return null;
  }

  render() {
    const { imgModalOpen, imageIndex } = this.state;
    const {
      person, date, message, classes, t, url, thumbnails, filename,
    } = this.props;

    const sanitizedMessage = DOMPurify.sanitize(message);

    const who = (person === 'me') ? t('help.me') : t('help.analyst');

    return (
      <div className={classes.cardMessageWrapper}>
        <div className={`wrapper-${person}`}>
          <div className={`${classes.cardMessage} cardMessage-${person}`}>
            <Typography className={classes.cardMessageTitle}>
              {who}
              <span>{handleTicketTime(date, t)}</span>
            </Typography>

            <Typography className={classes.cardMessageText} dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />

            {(thumbnails && !!thumbnails.length) && (
              <div
                className={classes.imgWrapper}
              >
                {thumbnails.map((thumb, index) => (
                  thumb !== 0 ? (
                    <img
                      key={`${filename[index]}`}
                      src={thumb}
                      alt={filename[index]}
                      role="presentation"
                      onClick={() => this.openImageModal(index)}
                    />
                  )
                    : (
                      this.handleFileExtension(filename[index]) && <FileIcon download={url[index]} extension={this.getFileExtension(filename[index])} />
                    )
                ))}
              </div>
            )}
          </div>
        </div>
        <TicketAttachmentsModal open={imgModalOpen} closeImgModal={this.closeImageModal} urlImage={url[imageIndex]} altImage={message} />
      </div>
    );
  }
}

TicketMessage.propTypes = {
  person: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(TicketMessage));
