/* eslint-disable no-console */
import db from '@/config/indexedDB/surveyDB';


const get = async (id) => {
  try {
    const query = db.table('saves').where({ userId: id }).first();
    return query || {};
  } catch (error) {
    console.error(error);
    return false;
  }
};

const save = async (data) => {
  try {
    await db.table('saves').put(data);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const surveyDB = {
  get,
  save,
};
