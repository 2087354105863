const styles = theme => ({
  loader: {
    marginRight: -20,
    marginLeft: -10,
    '& > div': {
      margin: 0,
    },
  },
  cardLink: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color.oceanGreen}`,
    borderRadius: '16px',
    color: theme.color.tundora,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: theme.font.sizes.xxs,
    fontWeight: 'normal',
    height: '32px',
    margin: '5px 5px 0px 5px',
    outline: 'none',
    padding: '8px 12px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:focus': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
    '&:hover': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
  },
});

export default styles;
