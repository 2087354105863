import React from 'react';

const HgOrangeLogo = () => (
  <svg width="123" height="19" viewBox="0 0 123 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.0349 13.6133C36.2119 14.6913 37.2169 15.0983 38.3099 15.0983C39.4029 15.0983 40.2299 14.6323 40.2599 14.0793C40.1983 13.6912 40.0123 13.3336 39.7299 13.0603C39.4474 12.7871 39.0838 12.613 38.6939 12.5643L36.0939 12.0983C33.7009 11.6613 32.3419 10.9913 32.3419 8.98232C32.3419 6.39032 34.6169 4.70032 37.8659 4.70032C41.1149 4.70032 43.1839 6.12732 43.7149 8.34132L39.9629 9.06932C39.8472 8.60083 39.5638 8.19101 39.1663 7.91736C38.7688 7.64372 38.2848 7.52523 37.8059 7.58432C36.5359 7.58432 36.1519 8.05032 36.0629 8.57432C35.9739 9.09832 36.1519 9.21532 37.3329 9.44832L40.3459 10.0603C42.7679 10.5603 43.8909 12.0113 43.8909 13.8763C43.8909 16.6763 41.4389 18.1283 38.0419 18.1283C34.6449 18.1283 32.4289 17.0503 32.0149 14.4283L36.0349 13.6133Z" fill="#FF9442" />
    <path d="M73.375 7.87529C73.4374 8.38755 73.4671 8.90326 73.464 9.41929C73.5047 10.5988 73.3013 11.774 72.8664 12.8711C72.4316 13.9683 71.7747 14.9638 70.9369 15.7951C70.0992 16.6264 69.0988 17.2757 67.9983 17.7021C66.8978 18.1286 65.7211 18.323 64.542 18.2733C63.3449 18.3263 62.1495 18.1368 61.0275 17.7162C59.9056 17.2956 58.8802 16.6525 58.013 15.8257C57.1458 14.9988 56.4547 14.0052 55.9812 12.9045C55.5077 11.8038 55.2616 10.6187 55.2576 9.42052C55.2536 8.22231 55.4919 7.03566 55.9581 5.93184C56.4243 4.82803 57.1087 3.82983 57.9704 2.99722C58.8321 2.16461 59.8532 1.51475 60.9723 1.0867C62.0915 0.658646 63.2856 0.461223 64.483 0.506292C68.323 0.506292 71.691 2.28329 72.902 5.60629L68.825 6.77129C68.4274 5.98367 67.8058 5.33115 67.0384 4.89577C66.271 4.46038 65.392 4.26156 64.512 4.32429C63.8535 4.31834 63.2005 4.44541 62.5923 4.69787C61.9841 4.95033 61.4331 5.323 60.9724 5.79353C60.5116 6.26407 60.1507 6.82278 59.9111 7.43618C59.6715 8.04959 59.5582 8.70505 59.578 9.36329C59.5658 10.0308 59.6881 10.694 59.9375 11.3133C60.1869 11.9326 60.5583 12.4954 61.0296 12.9683C61.501 13.4411 62.0626 13.8143 62.6811 14.0656C63.2997 14.317 63.9624 14.4413 64.63 14.4313C65.6151 14.4932 66.5934 14.2305 67.415 13.6835C68.2366 13.1364 68.8562 12.3351 69.179 11.4023H63.891V7.87529H73.375Z" fill="#FF9442" />
    <path d="M16.507 17.6033H12.282V10.8753H5.04402V17.6033H0.791016V0.593262H5.04501V7.23426H12.283V0.593262H16.508L16.507 17.6033Z" fill="#FF9442" />
    <path d="M31.7808 11.2253C31.777 12.1361 31.5892 13.0368 31.2287 13.8732C30.8683 14.7097 30.3426 15.4647 29.6832 16.0931C29.0238 16.7214 28.2442 17.21 27.3913 17.5297C26.5384 17.8493 25.6297 17.9934 24.7198 17.9533C20.9088 17.9533 17.5708 15.2443 17.5708 11.2253C17.5708 7.20627 20.8208 4.52527 24.7198 4.52527C28.6188 4.52527 31.7808 7.23427 31.7808 11.2253ZM27.8218 11.2253C27.8377 10.8048 27.7698 10.3854 27.622 9.99142C27.4742 9.59746 27.2495 9.23684 26.961 8.93056C26.6725 8.62428 26.3259 8.37846 25.9415 8.20742C25.5571 8.03637 25.1425 7.94352 24.7218 7.93427C24.2926 7.93739 23.8682 8.02571 23.4734 8.19411C23.0786 8.36251 22.7211 8.60763 22.4218 8.91527C21.9893 9.38213 21.6993 9.96299 21.5861 10.5893C21.4729 11.2156 21.5413 11.8612 21.7831 12.4499C22.0249 13.0386 22.43 13.5459 22.9507 13.9119C23.4714 14.2778 24.086 14.4872 24.7218 14.5153C25.1454 14.5161 25.5647 14.43 25.9537 14.2625C26.3428 14.095 26.6934 13.8495 26.9838 13.5412C27.2743 13.2329 27.4985 12.8683 27.6427 12.47C27.7868 12.0717 27.8478 11.6481 27.8218 11.2253Z" fill="#FF9442" />
    <path d="M55.4141 17.0203C54.3436 17.6317 53.1319 17.953 51.899 17.9523C48.679 17.9523 46.7591 16.2343 46.7591 12.8843V8.07826H44.4241V4.87826H46.7241V1.46726L50.83 0.593262V4.87526H54.6411V8.07526H50.83V12.5023C50.83 13.8133 51.4801 14.3663 52.5431 14.3663C53.2037 14.3492 53.8513 14.178 54.4341 13.8663L55.4141 17.0203Z" fill="#FF9442" />
    <path d="M86.4919 9.53529V17.6033H82.5329V16.6423C81.4468 17.5381 80.0699 18.0045 78.6629 17.9533C75.7969 17.9533 73.8479 16.2933 73.8479 13.7883C73.8479 11.2833 75.7979 9.71029 79.2249 9.71029H82.5039V9.44828C82.5163 9.21105 82.4782 8.97389 82.392 8.75253C82.3058 8.53116 82.1735 8.33065 82.0039 8.16428C81.8331 7.99669 81.6294 7.86642 81.4056 7.78172C81.1818 7.69703 80.9429 7.65976 80.7039 7.67229C80.4569 7.63281 80.2044 7.64309 79.9614 7.70253C79.7184 7.76197 79.4897 7.86938 79.2888 8.01842C79.0878 8.16747 78.9187 8.35517 78.7913 8.57049C78.6639 8.78581 78.5808 9.02441 78.5469 9.27229L74.6769 8.48629C75.2379 6.03929 77.5129 4.46729 80.5849 4.46729C84.1879 4.49629 86.4919 6.27329 86.4919 9.53529ZM82.5329 12.5673V12.2763H79.6969C78.6629 12.2763 78.0129 12.8003 78.0129 13.5873C78.0129 14.3743 78.7219 14.8983 79.8129 14.8983C80.9039 14.8983 82.5329 13.9913 82.5329 12.5673Z" fill="#FF9442" />
    <path d="M98.1021 17.0203C97.0344 17.6419 95.8225 17.9732 94.587 17.9813C91.367 17.9813 89.4471 16.2633 89.4471 12.9133V8.07826H87.1121V4.87826H89.446V1.46726L93.5521 0.593262V4.87526H97.363V8.07526H93.5521V12.5023C93.5521 13.8133 94.2021 14.3663 95.2661 14.3663C95.9267 14.3492 96.5743 14.178 97.157 13.8663L98.1021 17.0203Z" fill="#FF9442" />
    <path d="M111.691 11.2253C111.687 12.136 111.5 13.0366 111.139 13.873C110.779 14.7094 110.253 15.4644 109.594 16.0927C108.935 16.721 108.155 17.2097 107.302 17.5294C106.45 17.8491 105.541 17.9933 104.631 17.9533C100.82 17.9533 97.4521 15.2443 97.4521 11.2253C97.4521 7.20627 100.702 4.52527 104.601 4.52527C108.5 4.52527 111.691 7.23427 111.691 11.2253ZM107.732 11.2253C107.748 10.8048 107.68 10.3854 107.532 9.99142C107.385 9.59746 107.16 9.23684 106.871 8.93056C106.583 8.62428 106.236 8.37846 105.852 8.20742C105.467 8.03637 105.053 7.94352 104.632 7.93427C104.203 7.93739 103.779 8.02571 103.384 8.19411C102.989 8.36251 102.631 8.60763 102.332 8.91527C101.9 9.38213 101.61 9.96299 101.496 10.5893C101.383 11.2156 101.452 11.8612 101.693 12.4499C101.935 13.0386 102.34 13.5459 102.861 13.9119C103.382 14.2778 103.996 14.4872 104.632 14.5153C105.056 14.5162 105.475 14.4303 105.864 14.2628C106.254 14.0953 106.604 13.8499 106.895 13.5416C107.185 13.2332 107.41 12.8686 107.554 12.4702C107.698 12.0719 107.759 11.6481 107.733 11.2253H107.732Z" fill="#FF9442" />
    <path d="M122.209 4.78728L122.002 8.83628H121.263C118.398 8.83628 116.802 10.2923 116.802 13.6133V17.6073H112.696V5.74828L116.802 4.87428V7.32128C117.236 6.51912 117.881 5.85088 118.667 5.3889C119.453 4.92692 120.351 4.68879 121.263 4.70028C121.581 4.68694 121.899 4.7162 122.209 4.78728Z" fill="#FF9442" />
  </svg>
);

export default HgOrangeLogo;
