
export const brForms = () => {
  const forms = [];

  forms.push({
    subject: 'Suporte Financeiro - Alterar Data de Vencimento',
    key: 'changeduedate',
    tags: ['whmcs_form_financeiro_alterar_data_de_vencimento'],
    showWhen: [
      {
        method: 'isNotInGroup',
        vars: [
          'SERVICES_ALL',
          'DOMAINS_ALL',
        ],
      },
    ],
    root: { name: 'salessupport', as: 'Alterar Data de Vencimento' },
    text: [
      '<b>Leia com atenção os termos a seguir:</b>',
      '- A nova data precisa ser <b>de até 30 dias a partir da data de vencimento atual</b> do serviço;',
      '- Alterar a data de vencimento <b>poderá gerar um acréscimo de valor na sua próxima fatura</b> por conta da readequação da data.',
      '<br><br>',
    ].join('\n'),
    fields: [
      { label: 'Qual a data de vencimento desejada?', type: 'date' },
      { label: '✔️ Confirmo que li e concordo com os termos acima.', type: 'checkbox' },
    ],
  });

  forms.push({
    subject: 'Suporte Técnico - Solicitação de Reload/Rebuild',
    key: 'reloadrebuild',
    tags: ['whmcs_form_tecnico_reload-rebuild'],
    showWhen: [
      {
        method: 'isInGroup',
        vars: [
          'VPS_ALL',
          'DEDICATED_ALL',
        ],
      },
    ],
    root: { name: 'techsupport', as: 'Solicitação de Reload/Rebuild' },
    text: [
      '<b>Leia com atenção os termos a seguir:</b>',
      '<p>',
      '<b>- Nós não faremos backup de seu servidor.</b>',
      '<b>- Caso você deseje que restauremos seu backup, após o procedimento, deverá subir todos os arquivos para um mesmo diretório em até 48 horas.</b>',
      '<b>- Não nos responsabilizamos pelo conteúdo restaurado, pois não temos controle sobre o backup disponibilizado.</b>',
      '</p>',
      '<b>IMPORTANTE:</b>',
      '<p>',
      '<b>Este formulário é para solicitação de Reload/Rebuild. Ao solicitar o procedimento todo o conteúdo do servidor será removido sem possibilidade de restauração.</b>',
      '</p>',
    ].join('<br/>'),
    fields: [
      {
        text: [
          '<p>',
          '<b>ATENÇÃO:</b><br/>',
          '<b>- O procedimento será realizado no servidor selecionado. Não haverá dupla confirmação</b>',
          '<b>- Ao escolher uma imagem linux sem cPanel, o servidor é entregue somente com o SSH configurado. Todas configurações adicionais é responsábilidade do cliente.</b>',
          '<b>- Se o seu servidor contratado é Windows, você somente pode escolher uma imagem Windows, assim como se seu servidor contratado for Linux, somente pode escolher uma imagem Linux. Para trocar o Sistema Operacional nesses casos, deve realizar o cancelamento e uma nova contratação.</b>',
          '</p><br/>',
        ].join('<br/>'),
        optional: true,
      },
      {
        label: 'Qual a imagem desejada?',
        type: 'select',
        name: 'selectImage',
        options: [
          {
            group: true,
            groupLabel: 'Imagens Linux com cPanel',
            options: [
              { name: 'CentOS 7 cPanel', value: 'CentOS 7 cPanel', groupLabel: 'TESTE' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imagens Linux sem cPanel',
            options: [
              { name: 'CentOS 8', value: 'CentOS 8' },
              { name: 'CentOS 7', value: 'CentOS 7' },
              { name: 'Debian 10', value: 'Debian 10' },
              { name: 'Debian 9', value: 'Debian 9' },
              { name: 'Ubuntu 20.04', value: 'Ubuntu 20.04' },
              { name: 'Ubuntu 18.04', value: 'Ubuntu 18.04' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imagens Windows com Plesk (não incluso a licença que deve ser contratada diretamente com eles)',
            options: [
              { name: 'CentOS 8 com Plesk', value: 'CentOS 8 com Plesk' },
              { name: 'CentOS 7 com Plesk', value: 'CentOS 7 com Plesk' },
              { name: 'Debian 10 com Plesk', value: 'Debian 10 com Plesk' },
              { name: 'Debian 9 com Plesk', value: 'Debian 9 com Plesk' },
              { name: 'Ubuntu 20.04 com Plesk', value: 'Ubuntu 20.04 com Plesk' },
              { name: 'Ubuntu 18.04 com Plesk', value: 'Ubuntu 18.04 com Plesk' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imagens Windows com Plesk (incluso a licença)',
            options: [
              { name: 'Windows Server 2016', value: 'Windows Server 2016' },
              { name: 'Windows Server 2019', value: 'Windows Server 2019' },
            ],
          },
        ],
      },
      {
        label: 'Podemos executar em horário comercial',
        type: 'select',
        name: 'selectTime',
        options: [
          { name: 'Sim', value: 'Sim' },
          { name: 'Não', value: 'Não' },
        ],
      },
      { label: '✔️ 08:00 as 18:00 *Conforme horário de Brasília', type: 'checkbox' },
      { label: '✔️ Confirmo que li e concordo com os Termos de Rebuild/Reload', type: 'checkbox' },
      { label: '✔️ Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$250,00 caso se encaixe nos cenários descritos nos termos', type: 'checkbox' },
      { label: '✔️ Confirmo que li e estou ciente que é minha responsabilidade fazer backups para posterior restauração', type: 'checkbox' },
    ],
  });

  return forms;
};

export const esForms = ({ brand }) => {
  const forms = [];

  forms.push(() => {
    function getPreco(clientBrand) {
      switch (clientBrand) {
        case 1:
          return 'El precio del certificado SSL Privado es de 516,00/anõ, certificado SSL WildCard es de 2.892,00/año, y el certificado SSL EV es de 8.988,00/año;\n';
        case 2:
          return 'El precio del certificado SSL Privado es de 48.000,00/anõ, certificado SSL WildCard es de 230.400,00/año, y el certificado SSL EV es de 727.200,00/año;\n';
        case 3:
          return 'El precio del certificado SSL Privado es de 10.800,00/anõ, certificado SSL WildCard es de 51.600,00/año, y el certificado SSL EV es de 168.000,00/año;\n';
        default:
          return '';
      }
    }

    return {
      subject: 'Soporte Técnico - Solicitar SSL',
      key: 'sslwildcardlatam',
      tags: [
        'whmcs_ssl',
      ],
      showWhen: [
        {
          method: 'isProduct',
          vars: [
            'DEDICATED_ALL',
            'VPS_ALL',
          ],
        },
      ],
      root: {
        name: 'techsupport',
        as: 'Solicitar SSL',
      },
      text: [
        `${'¡Atención!\n'
            + 'Este formulario se dirige sólo a los dueños de planes VPS y Dedicado. Si tiene un plan Web Hosting, Creador de Sitios o Reseller, siga los pasos de nuestra FAQ haciendo clic [AQUÍ](https://soporte-latam.hostgator.com/hc/es-419/articles/360007602492-Como-contratar-un-certificado-SSL);\n'}${
          getPreco(brand)
        }Los campos marcados con * son obligatorios;`,
      ].join(''),
      fields: [
        {
          label: 'Tipo',
          type: 'select',
          options: [
            { name: 'Certificado SSL Privado', value: 1 },
            { name: 'Certificado SSL EV', value: 2 },
            { name: 'Certificado SSL WildCard', value: 3 },
          ],
        },
        {
          label: 'Su Nombre',
          type: 'text',
        },
        {
          label: 'Regístre su e-mail',
          type: 'text',
        },
        {
          label: 'Nombre de la Empresa',
          type: 'text',
        },
        {
          label: 'Dirección',
          type: 'text',
        },
        {
          label: 'Ciudad',
          type: 'text',
        },
        {
          label: 'Provincia/Región',
          type: 'text',
        },
        {
          label: 'País',
          type: 'text',
        },
        {
          label: 'CEP',
          type: 'text',
        },
        {
          label: 'Documento',
          type: 'text',
        },
        {
          label: 'Nombre',
          type: 'text',
        },
        {
          label: 'Cargo',
          type: 'text',
        },
        {
          label: 'E-mail',
          type: 'text',
        },
        {
          label: 'Teléfono',
          type: 'text',
        },
        {
          label: '¿Está usted de acuerdo con el cobro relacionado con el Certificado SSL citado arriba?',
          type: 'checkbox',
        },
      ],
    };
  });

  forms.push({
    subject: 'Suporte Técnico - Solicitud de rebuild/reload',
    key: 'reloadrebuild',
    tags: ['whmcs_form_tecnico_reload-rebuild'],
    showWhen: [
      {
        method: 'isInGroup',
        vars: [
          'VPS_ALL',
          'DEDICATED_ALL',
        ],
      },
    ],
    root: { name: 'techsupport', as: 'Solicitud de rebuild/reload' },
    text: [
      '<b>Lea con atención los términos a continuación:</b>',
      '<p>',
      '<b>- No realizaremos backup de su servidor.</b>',
      '<b>- Caso usted desee que restauremos su backup, luego del procedimiento, deberá subir todos los archivos para un mismo directorio en hasta 48 horas.</b>',
      '<b>- No nos responsabilizamos por el contenido restaurado, pues no tenemos control sobre el backup disponibilizado.</b>',
      '</p>',
      '<b>IMPORTANTE:</b>',
      '<p>',
      '<b>Este formulario es para solicitudes de Reload/Rebuild. Al solicitar el procedimiento todo el contenido del servidor será removido sin posibilidad de restauración.</b>',
      '</p>',
    ].join('<br/>'),
    fields: [
      {
        text: [
          '<p>',
          '<b>ATENCIÓN:</b><br/>',
          '<b>- El procedimiento será realizado en el servidor con este IP. No habrá doble confirmación</b>',
          '<b>- Al seleccionar una imagen linux sin cPanel, el servidor solo se entrega con SSH configurado. Cualquier configuración adicional es responsabilidad del cliente.</b>',
          '<b>- Si tu servidor contratado es Windows, solo puedes elegir una imagen Windows, si tu servidor contratado es Linux, solo puedes elegir una imagen  Linux. Para cambiar el Sistema Operativo en estos casos, deberá solicitar la baja y realizar una nueva contratación.</b>',
          '</p><br/>',
        ].join('<br/>'),
        optional: true,
      },
      {
        label: 'Cual es la imagen deseada?*',
        type: 'select',
        name: 'selectImage',
        options: [
          {
            group: true,
            groupLabel: 'Imágenes Linux con cPanel',
            options: [
              { name: 'CentOS 7 cPanel', value: 'CentOS 7 cPanel', groupLabel: 'TESTE' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imágenes Linux sin cPanel    ',
            options: [
              { name: 'CentOS 8', value: 'CentOS 8' },
              { name: 'CentOS 7', value: 'CentOS 7' },
              { name: 'Debian 10', value: 'Debian 10' },
              { name: 'Debian 9', value: 'Debian 9' },
              { name: 'Ubuntu 20.04', value: 'Ubuntu 20.04' },
              { name: 'Ubuntu 18.04', value: 'Ubuntu 18.04' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imágenes Windows con Plesk (no incluida la licencia, debe ser contratada directamente con ellos)',
            options: [
              { name: 'CentOS 8 con Plesk', value: 'CentOS 8 con Plesk' },
              { name: 'CentOS 7 con Plesk', value: 'CentOS 7 con Plesk' },
              { name: 'Debian 10 con Plesk', value: 'Debian 10 con Plesk' },
              { name: 'Debian 9 con Plesk', value: 'Debian 9 con Plesk' },
              { name: 'Ubuntu 20.04 con Plesk', value: 'Ubuntu 20.04 con Plesk' },
              { name: 'Ubuntu 18.04 con Plesk', value: 'Ubuntu 18.04 con Plesk' },
            ],
          },
          {
            group: true,
            groupLabel: 'Imágenes Windows con Plesk (incluida la licencia)',
            options: [
              { name: 'Windows Server 2016', value: 'Windows Server 2016' },
              { name: 'Windows Server 2019', value: 'Windows Server 2019' },
            ],
          },
        ],
      },
      {
        label: 'Podemos hacerlo en horario comercial.',
        type: 'select',
        name: 'selectTime',
        options: [
          { name: 'Si', value: 'Si' },
          { name: 'No', value: 'No' },
        ],
      },
      { label: '✔️ 08:00 as 18:00', type: 'checkbox' },
      { label: '✔️ Confirmo que he leído y estoy de acuerdo con los Términos de Reload/Rebuild', type: 'checkbox' },
      { label: '✔️ Confirmo que he leído y estoy de acuerdo con los términos antes descritos y con la cobranza del valor de Hora Técnica en caso se encaje en los escenarios descritos en los términos', type: 'checkbox' },
      { label: '✔️ Confirmo que he leído y estoy de acuerdo en que es mi responsabilidad hacer backups para posterior restauración', type: 'checkbox' },
    ],
  });

  return forms;
};
