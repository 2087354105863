import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useStatusColors = () => {
  const theme = useContext(ThemeContext);
  return {
    active: {
      border: theme.palette.success.primary,
      icon: theme.palette.success.primary,
    },
    cancelled: {
      border: theme.palette.grey.primary,
      icon: theme.palette.grey.dark,
    },
    overdue: {
      border: theme.palette.error.primary,
      icon: theme.palette.error.primary,
    },
    error: {
      border: theme.palette.error.primary,
      icon: theme.palette.error.primary,
    },
    pending: {
      border: theme.palette.danger.dark,
      icon: theme.palette.danger.dark,
    },
    pending_analysis: {
      border: theme.palette.danger.dark,
      icon: theme.palette.danger.dark,
    },
    pending_payment: {
      border: theme.palette.danger.dark,
      icon: theme.palette.danger.dark,
    },
    suspended: {
      border: theme.palette.error.primary,
      icon: theme.palette.error.primary,
    },
    to_due: {
      border: theme.palette.warning.primary,
      icon: theme.palette.warning.primary,
    },
    next_due_date: {
      border: theme.color.brightSun,
      icon: theme.palette.grey.dark,
    },
    due_date: {
      border: theme.color.valencia,
      icon: theme.palette.grey.dark,
    },
    expired: {
      border: theme.color.valencia,
      icon: theme.palette.grey.dark,
    },
    info: {
      border: theme.palette.grey.primary,
      icon: theme.palette.blue.dark,
    },
  };
};

export default useStatusColors;
