import styled, { css } from 'styled-components';

export const ContainerCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    box-shadow: 0px 1px 3px #00000029;
    display: flex;
    flex-direction: column;
    padding: 36px 48px;

    @media (max-width:758px) {
      padding: 24px;
    }
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width:758px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
