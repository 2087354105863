import React from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './Loader.styles';

function Loader({
  testId = 'loader',
  message,
  modal = false,
  noMinHeight = false,
}) {
  const loader = (
    <Styles.LoaderWrapper data-testid={testId} noMinHeight={noMinHeight}>
      <Styles.Loader>
        <span />
      </Styles.Loader>
      {message && (
      <Styles.Message>
        {message}
      </Styles.Message>
      )}
    </Styles.LoaderWrapper>
  );

  return (modal
    ? (
      <Modal testId="loader-modal">
        {loader}
      </Modal>
    ) : (loader)
  );
}

export default Loader;
