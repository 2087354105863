import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, isChecked }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 4px;
    border: 2px solid ${isChecked ? theme.v2.colors.feedback.activeLight : theme.v2.colors.neutral.highMedium};
    height: 471px;
    margin: 0px 24px 0px 0px;
    width: 365px;
    min-width: 296px;
`}
`;
export const Content = styled.div`
    padding: 16px 24px 24px 24px;
`;

export const Step = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-style: normal;
    font-weight: ${theme.v2.font.weights.bold};
    line-height: normal;
    text-transform: uppercase;
  `}
`;

export const IconCheck = styled.img`
  ${({ theme, disabled }) => css`
    color: ${theme.v2.colors.white.primary};

     &:hover {
      cursor: ${disabled ? 'not-allowed;' : 'pointer'};
    }
  `}
`;

export const ContentIcon = styled.div`
`;

export const ContentTitle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: stretch;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-bottom: 8px;
  `}
`;

export const Description = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    text-align: left;
    margin-top: 8px;
    width: 102%;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    text-align: left;
    span {
      font-weight: bold;
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => css`
    color: ${theme.v2.colors.white.primary};
    height: 48px;
    width: 48px;
  `}
`;

export const Body = styled.div`
${({ isOpen }) => css`
      height: ${isOpen ? 'fit-content' : '0px'};
      margin-top: ${isOpen ? '2px' : '0px'};
      opacity: ${isOpen ? '2px' : '0px'};
      overflow: hidden;
      padding: 0 24px 0 24px;
      transition: 0.2s;
      margin: ${isOpen ? '2px' : '0px'};
  `}
`;

export const Header = styled.div`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
    margin-right: -8px;
`;

export const Container = styled.div`
    align-items: center;
    display: grid;
    gap: 4px;
    width: 100 %;
    height: 40px;
`;

export const ContentDescriptions = styled.div`
${({ wasViewed }) => css`
    align-items: center;
    display: grid;
    gap: 4px;
    width: 100 %;
    height:${wasViewed ? '134px' : 'unset'};
    `}
  `;


export const ContentDisabledBlock = styled.div`
${({ theme }) => css`
    align-items: center;
    background: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    position: relative;
    bottom: 34px;
    padding: 0px 22px 0px 22px;
    `}
    `;

export const IconLock = styled.img`
  ${({ theme }) => css`
    color: ${theme.v2.colors.white.primary};
    margin-top: 16px;
  `}
`;

export const BlockedText = styled.p`
  ${({ theme }) => css`
    color:  ${theme.v2.colors.feedback.canceledMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin-top: 8px;
    text-align: center;
  `}
`;


export const ContentButtons = styled.div`
    align-items: center;
    text-align: center;
    gap: 4px;
    margin: 16px 0px 16px 0px;

    & button {
      width: fit-content;
    }
`;

export const ContentLinks = styled.div`
${({ theme }) => css`
    display: grid;
    gap: 4px;
    margin-top: 16px;
    & a {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.xxs};
      font-style: normal;
      font-weight: ${theme.v2.font.weights.medium};
      line-height: normal;
      text-decoration-line: underline;
    }
`}
`;
