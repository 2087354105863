const styles = theme => ({
  container: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '& .solved': {
      borderLeft: `5px solid ${theme.color.oceanGreen}`,
      '& .status-icon': {
        color: theme.color.oceanGreen,
      },
      '& .label': {
        backgroundColor: theme.color.oceanGreen,
      },
    },
    '& .hold': {
      borderLeft: `5px solid ${theme.color.brightSun}`,
      '& .status-icon': {
        color: theme.color.brightSun,
      },
      '& .label': {
        backgroundColor: theme.color.brightSun,
      },
    },
    '& .open, .new': {
      borderLeft: `5px solid ${theme.color.brightSun}`,
      '& .status-icon': {
        color: theme.color.brightSun,
      },
      '& .label': {
        backgroundColor: theme.color.brightSun,
      },
    },
    '& .pending': {
      borderLeft: `5px solid ${theme.color.pendingPure}`,
      '& .status-icon': {
        color: theme.color.pendingPure,
      },
      '& .label': {
        backgroundColor: theme.color.pendingPure,
      },
    },
    '& .closed': {
      borderLeft: `5px solid ${theme.color.geyser}`,
      '& .status-icon': {
        color: theme.color.geyser,
      },
      '& .label': {
        backgroundColor: theme.color.geyser,
      },
    },
  },
});

export default styles;
