import React from 'react';

export default {
  productsDetails: {
    modals: {
      createSite: {
        title: () => (
          <>
            {'Vamos expandir sua marca '}
            <span>criando um novo site</span>
            ?
          </>
        ),
        description: {
          0: domain => (
            <>
              {'5 bilhões de pessoas no mundo estão usando a internet. Não perca tempo e crie um site personalizado para '}
              <span>{`${domain}.`}</span>
            </>
          ),
          1: () => (
            <>
              {'Você pode usar o '}
              <span>Criador de Sites, Wordpress</span>
              {'  ou outras ferramentas para instalar e personalizar. :)'}
            </>
          ),
        },
        buttonLabel: 'Criar site',
        backButtonLabel: 'Não quero expandir minha marca',
      },
    },
  },
};
