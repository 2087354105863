const styles = theme => ({
  wrapper: {
    position: 'relative',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  courses: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto auto',
    '& > div:nth-child(1)': {
      gridColumn: '1 / 3',
    },
    gridGap: '14px',
    height: 'fit-content',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  '@keyframes cardTransition': {
    from: {
      opacity: 0,
      transform: 'translateY(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  card: {
    animationName: 'cardTransition',
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-out',
  },
  info: {
    display: 'block',
    color: theme.color.tundora,
    fontSize: 16,
    paddingBottom: 25,
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      color: theme.color.regentGray,
      fontSize: 14,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
  },
  '@keyframes blueDoodleTransition': {
    from: {
      opacity: 0,
      transform: 'scale(0.8) translateX(-30px) translateY(-30px) rotate(-20deg)',
    },
    to: {
      opacity: 0.7,
      transform: 'scale(1) translateX(0) translateY(0) rotate(0)',
    },
  },
  blueDoodle: {
    position: 'absolute',
    right: 70,
    bottom: 0,
    opacity: 0.7,
    zIndex: 2,
    animationName: 'blueDoodleTransition',
    animationDuration: '0.6s',
  },
  '@keyframes snappyDefaultTransition': {
    from: {
      opacity: 0,
      transform: 'scale(0.9) translateX(20px) translateY(20px)',
    },
    to: {
      opacity: 0.7,
      transform: 'scale(1) translateX(0) translateY(0)',
    },
  },
  snappyDefault: {
    position: 'absolute',
    right: 131,
    bottom: 71,
    opacity: 0.7,
    zIndex: 3,
    animationName: 'snappyDefaultTransition',
    animationDuration: '0.5s',
  },
});

export default styles;
