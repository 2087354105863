import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import { config } from '@/config';

import ptTranslation from './pt_BR';
import esTranslation from './es_ES';

export const selectedLanguage = config.LANG.code;

export const createLocale = (lang = 'br') => i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pt: { translation: ptTranslation },
      es: { translation: esTranslation },
    },
    lng: lang,
    fallbackLng: {
      mx: ['es'],
      cl: ['es'],
      co: ['es'],
      default: ['pt'],
    },
  });

export default createLocale(selectedLanguage);
