import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ open }) => css`
    visibility: ${open ? 'visible' : 'hidden'};
    opacity: ${open ? 1 : 0};
    transition:
      opacity 200ms ease-in-out,
      visibility 200ms ease-in-out;
  `}
`;

export const TitleAccordion = styled.span`
 ${({ theme }) => css`
   font-size: ${theme.font.sizes.sm};
   font-weight: ${theme.font.weights.bold};
 `}
`;

export const Header = styled.header``;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.medium};
    margin: 24px 0 0 0;
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey.primary};
    margin-top: 40px;
  `}
`;

export const Content = styled.div`
  margin-right: -16px;
  max-height: 385px;
  overflow-y: auto;
  padding: 0 16px 12px 0;
`;

export const ContentItem = styled.div`
  padding-top: 24px;
`;

export const Label = styled.strong`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.bold};
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    display: block;
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    line-height: 20px;
    margin-top: 24px;
  `}

`;

export const Block = styled.div`
  ${({ theme }) => css`
    border-radius: 5px;
    border: 1px solid ${theme.palette.grey.primary};
    margin-top: 16px;
    padding: 24px 16px;

    & ${Label} {
      display: block;
    }

    & ${Text} {
      margin-top: 16px;
      display: block;
    }
  `}
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const ListItem = styled.li`
  padding-left: 16px;
  position: relative;

  &:not(:first-child) {
    margin-top: 24px;
  }

  &:before {
    background-color: #959EA3;
    border-radius: 50%;
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: translateY(50%);
    width: 8px;
  }

  & ${Text} {
    display: inline;
    margin: 0;
  }
`;

export const BlockSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;

    &:last-child {
      margin-bottom: -8px;
    }

    & ${Label} {
      font-weight: ${theme.font.weights.medium};
    }

    & ${Block} {
      padding: 16px;
      flex: 1;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;

      & ${Block} {
        margin: 0;
        width: 100%;
      }

      & ${Block}:first-child {
        margin-top: 16px;
      }
    }
  `}
`;

export const Expression = styled.div`
  align-items: center;
  display: flex;
  margin-top: 16px;
`;

export const ExpressionBase = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.grey.variantlight};
    padding: 8px 16px;

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 1;
    }
  `}
`;

export const ExpressionPrice = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weights.regular};
    font-size: ${theme.font.sizes.sm};
    color: ${theme.palette.grey.dark};
  `}
`;

export const ExpressionText = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.medium};
    margin-top: 6px;
  `}
`;

export const SymbolMinus = styled.div`
  ${({ theme }) => css`
    flex: 0 0 14px;
    height: 4px;
    background-color: ${theme.palette.primary.main};
    border-radius: 2px;
    margin: 0 20px;
  `}
`;

export const SymbolEqual = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    width: 14px;
    flex: 0 0 14px;
    margin: 0 20px 45px 20px;

    &:before {
      content: '';
      display: block;
      height: 4px;
      background-color: ${theme.palette.primary.main};
      border-radius: 2px;
    }

    &:after {
      margin-top: 2px;
      content: '';
      display: block;
      height: 4px;
      background-color: ${theme.palette.primary.main};
      border-radius: 2px;
    };

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 16px 0;
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 12px;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const ButtonAdapter = styled.div`
  ${({ theme }) => css`
    &:not(:first-child) {
      margin-left: 16px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 16px;

      &:not(:first-child) {
        margin: 8px 0 0 0;
      }
    }
  `}
`;
