import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    background-color: ${theme.v1.color.white.primary};
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin: 0;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 24px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 8px;
    margin-top: 24px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-right: 12px;
      > svg {
        height: 24px;
        width: 58px;
      }
    }
  `}
`;
export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;

export const ItemDiscount = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    &:nth-child(2) {
      width:calc( + 160px);
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: inline-flex;
    font-size: 12px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
  `}
`;

export const TooltipWrapper = styled.span`
  margin-left: 8px;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
  `}
`;

export const TagWrapper = styled.span`
  margin-top: 4px;
`;
