import { CALL_API } from '@/middleware/api';
import {
  USER_DOMAINS_URL, USER_DOMAINS_ELEGIBLE, DOMAIN_DETAIL_URL, DOMAIN_RENEWAL_URL, DOMAINS_VERIFICATION_PENDING_URL,
  DOMAINS_RESEND_VERIFICATION_EMAIL_URL,
} from '@/config/api';
import { config } from '@/config';
import { registrobrActions, intResellerActions } from '@/config/components/ctas';
import {
  REQUEST_DOMAINS, RECEIVE_DOMAINS, ERROR_DOMAINS, ABORT_REQUEST_DOMAINS,
  FILTER_DOMAINS, SELECT_STATUS_DOMAINS, REQUEST_DOMAIN_DETAIL, ERROR_DOMAIN_DETAIL,
  RECEIVE_DOMAIN_DETAIL, REQUEST_DOMAIN_ELEGIBLE, RECEIVE_DOMAIN_ELEGIBLE, ERROR_DOMAIN_ELEGIBLE,
  REQUEST_DOMAIN_RENEWAL, ERROR_DOMAIN_RENEWAL, RECEIVE_DOMAIN_DETAIL_RENEWAL,
  RECEIVE_DOMAIN_ITEM_RENEWAL, REQUEST_DOMAINS_VERIFICATION_PENDING,
  RECEIVE_DOMAINS_VERIFICATION_PENDING, ERROR_DOMAINS_VERIFICATION_PENDING, REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL,
  RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL, ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL,
} from './actionsTypes';


const getAllStatusFromDomainsList = (domains) => {
  const allStatus = [];
  domains.forEach((item) => {
    const { status } = item;
    if (status && allStatus.indexOf(status) === -1) {
      allStatus.push(status);
    }
  });

  const activeStatus = allStatus.filter(item => item !== 'cancelled');

  return {
    allStatus,
    activeStatus,
  };
};

const sortDomainByStatus = (domains) => {
  const registered = [];
  const pendingTransfer = [];
  const pending = [];
  const expired = [];
  const cancelled = [];
  const registerError = [];

  domains.forEach((domain) => {
    switch (domain.status) {
      case 'active':
        registered.push(domain);
        break;
      case 'expired':
        expired.push(domain);
        break;
      case 'pendingTransfer':
        pendingTransfer.push(domain);
        break;
      case 'pending':
        pending.push(domain);
        break;
      case 'cancelled':
        cancelled.push(domain);
        break;
      case 'registerError':
        registerError.push(domain);
        break;
      default:
        break;
    }
  });

  const activeStatus = [
    ...registered,
    ...pendingTransfer,
    ...pending,
    ...expired,
    ...registerError,
  ];

  return {
    all: [
      ...activeStatus,
      ...cancelled,
    ],
    activeStatus,
  };
};

const receiveDomains = (rawDomains, loadedDomains = []) => {
  const domainsAsObject = rawDomains && rawDomains.paginate;
  const filteredLoadedDomains = rawDomains.paginate && rawDomains.paginate.currentPage === 1 ? [] : loadedDomains;
  let domains = [];
  let pagination;

  if (rawDomains.length) {
    domains = rawDomains;
  }

  if (domainsAsObject) {
    domains = [...filteredLoadedDomains, ...rawDomains.domains];
    pagination = rawDomains.paginate;
  }

  const total = domains.length;
  const sortDomainsByStatus = sortDomainByStatus(domains);
  const getStatus = getAllStatusFromDomainsList(domains);

  return {
    type: RECEIVE_DOMAINS,
    pagination,
    filterSelectedStatus: getStatus.allStatus,
    filterDomainStatus: getStatus.allStatus,
    loadedDate: Date.now(),
    sortedDomains: sortDomainsByStatus.all,
    bkpList: sortDomainsByStatus.all,
    total,
  };
};

export const loadDomains = ({ page = 1, forceLoadedDomains = true }) => (dispatch, getState) => {
  const { loadedDate, pagination, list: loadedDomains } = getState().domains;

  const paginationParams = `?page=${page}`;
  if (Date.now() - loadedDate < config.CACHE_TIME && !pagination) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_REQUEST_DOMAINS, payload: { forceLoadedDomains } }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: `${USER_DOMAINS_URL}${paginationParams}`,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_DOMAINS }),
        success: response => receiveDomains(response.data, loadedDomains),
        error: () => ({ type: ERROR_DOMAINS }),
      },
    },
  });
};

const receiveDomainsElegible = domainsElegible => ({
  type: RECEIVE_DOMAIN_ELEGIBLE,
  domainsElegible,
});

export const loadDomainsEligible = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: USER_DOMAINS_ELEGIBLE,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_DOMAIN_ELEGIBLE }),
      success: response => receiveDomainsElegible(response.data),
      error: () => ({ type: ERROR_DOMAIN_ELEGIBLE }),
    },
  },
});

export const statusDomains = domainStatus => ({
  type: SELECT_STATUS_DOMAINS,
  domainStatus,
});

export const filterDomainNames = domainName => (dispatch, getState) => {
  const allDomains = getState().domains.bkpList;
  const allStatus = getState().domains.selectFilters.selectedStatus;
  let filteredDomains;

  if (domainName !== '') {
    filteredDomains = allDomains.filter(domain => domain.domain.toString().includes(domainName));
  } else {
    filteredDomains = allDomains;
  }

  if (allStatus.length !== 0) {
    filteredDomains = filteredDomains.filter((domain) => {
      if (
        domain.status === allStatus[0]
        || (allStatus[1] && domain.status === allStatus[1])
        || (allStatus[2] && domain.status === allStatus[2])
        || (allStatus[3] && domain.status === allStatus[3])
        || (allStatus[4] && domain.status === allStatus[4])
        || (allStatus[5] && domain.status === allStatus[5])
      ) {
        return domain;
      }
      return null;
    });
  }

  return dispatch({
    type: FILTER_DOMAINS,
    filterDomain: filteredDomains || [],
  });
};

const receiveDomainDetail = domainDetail => ({
  type: RECEIVE_DOMAIN_DETAIL,
  domainDetail,
  domainActions: domainDetail.registrar === 'registrobr' ? registrobrActions : intResellerActions,
});

export const loadDomainDetail = domainId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: DOMAIN_DETAIL_URL(domainId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_DOMAIN_DETAIL }),
      success: response => receiveDomainDetail({ ...response.data }),
      error: () => ({ type: ERROR_DOMAIN_DETAIL }),
    },
  },
});

const receiveDomainRenewal = (domainRenewal, domains) => ({
  type: RECEIVE_DOMAIN_DETAIL_RENEWAL,
  domainRenewal,
  domains,
});

const receiveRenewalDomainList = domains => ({
  type: RECEIVE_DOMAIN_ITEM_RENEWAL,
  domains,
});

export const handleDomainRenewal = domainRenewal => (dispatch, getState) => {
  const state = getState();
  const domains = state.domains.bkpList;
  const indexDomain = domains.findIndex(item => item.id === domainRenewal.id);
  domains[indexDomain].doNotRenew = domainRenewal.donotrenew;

  // Update domain detail
  if (state.domains.list.id !== undefined) {
    dispatch(receiveDomainRenewal(domainRenewal, domains));
  }

  // Update domain list
  if (domains.length > 0) {
    dispatch(receiveRenewalDomainList(domains));
  }
};

export const loadDomainRenewal = (domainId, donotrenew) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: DOMAIN_RENEWAL_URL(domainId),
    method: 'PUT',
    body: { donotrenew },
    actionTypes: {
      request: () => ({ type: REQUEST_DOMAIN_RENEWAL }),
      success: data => handleDomainRenewal(data.data),
      error: () => ({ type: ERROR_DOMAIN_RENEWAL }),
    },
  },
});

const receiveDomainsVerificationPending = domainsVerificationPending => ({
  type: RECEIVE_DOMAINS_VERIFICATION_PENDING,
  domainsVerificationPending,
});

export const loadDomainsVerificationPending = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: DOMAINS_VERIFICATION_PENDING_URL,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_DOMAINS_VERIFICATION_PENDING }),
      success: response => (receiveDomainsVerificationPending(response.data)),
      error: () => ({ type: ERROR_DOMAINS_VERIFICATION_PENDING }),
    },
  },
});

export const resendVerificationEmail = domainId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: DOMAINS_RESEND_VERIFICATION_EMAIL_URL,
    method: 'POST',
    body: { domain_id: domainId },
    actionTypes: {
      request: () => ({ type: REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL }),
      success: response => ({ type: RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL, domainEmailVerified: response.data.result }),
      error: response => ({ type: ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL, success: response.success }),
    },
  },
});
