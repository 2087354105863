import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import {
  Dialog, IconButton, DialogTitle, DialogContent, DialogActions, withStyles,
  TextField, FormControlLabel, Checkbox, Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { changeStorageEmailAccount } from '@/redux/actions/emails';
import { SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS, ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS } from '@/redux/actions/actionsTypes';
import { validateOnlyNumbers, validateQuota } from '@/utils/Validators/validation';
import { notifierSuccess, notifierError } from '@/utils/Application/notifier';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import styles from './styles';


const ChangeStorageEmailAccountModal = ({
  classes,
  openModal,
  handleCloseModal,
  email,
  t,
}) => {
  const { hostingId, email: emailAddress } = email;
  const [quota, setQuota] = useState(!email.diskUnlimited ? email.diskQuota : '');
  const [errorInput, setErrorInput] = useState(false);
  const [unlimited, setUnlimited] = useState(email.diskUnlimited);
  const dispatch = useDispatch();
  const loadingChangeStorage = useSelector(state => state.emails.loadingChangeStorageAccount);
  const isMobile = window.innerWidth <= 490;

  const onHandleCloseModal = () => {
    if (!loadingChangeStorage) {
      handleCloseModal('changeStorage');
    }
  };

  const handleChangeStorageEmailAccount = () => {
    if (loadingChangeStorage || (quota === '' && !unlimited)) {
      setErrorInput(true);
      return;
    }
    const storageOptions = {
      quota: Number(quota),
      unlimited,
    };

    dispatch(changeStorageEmailAccount(hostingId, emailAddress, storageOptions)).then((res) => {
      if (res.type === SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS) {
        onHandleCloseModal();
        dispatch(enqueueSnackbar('snackbar.emails.changeStorage', notifierSuccess));
      }

      if (res.type === ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS) {
        dispatch(enqueueSnackbar('snackbar.error.changeStorageEmail', notifierError));
      }
    });
  };

  const changeInput = (event) => {
    setErrorInput(false);
    if (validateOnlyNumbers(event.target.value) || event.target.value === '') {
      setQuota((validateQuota(event.target.value)));
      if (event.target.value.length > 7) {
        setQuota(validateQuota(event.target.value.slice(0, event.target.value.length - 1)));
      }
    }
  };

  const onChangeCheckBox = () => {
    setErrorInput(false);
    setUnlimited(!unlimited);
    setQuota('');
  };

  return (
    <Dialog
      open={openModal}
      onClose={onHandleCloseModal}
      aria-labelledby="alert-dialog-delete-account"
      aria-describedby="alert-dialog-confirmation-to-delete-email-account"
      classes={{
        container: classes.modalContainer,
        paper: classes.modalPaper,
      }}
      fullScreen={isMobile}
    >
      <IconButton onClick={onHandleCloseModal} className={classes.closeIconButton}>
        <Close />
      </IconButton>
      <DialogTitle
        id="alert-dialog-delete-email-account"
        classes={{
          root: classes.dialogTitle,
        }}
        data-id="email-change-storage-form"
      >
        {t('emails.changeStorage')}
      </DialogTitle>
      <DialogContent classes={{
        root: classes.dialogContentRoot,
      }}
      >
        <div className={classes.wrapperContentModal}>
          <p className={classes.modalText}>
            <Trans i18nKey="emails.typeQuotaStorage" emailAddress={emailAddress}>
              0
              <strong>
                {{ emailAddress }}
                1
              </strong>
            </Trans>
          </p>
          <div className={classes.containerInput}>
            <TextField
              disabled={unlimited}
              label={t('emails.quota')}
              id="outlined-bare"
              className={classes.textFieldInput}
              margin="normal"
              variant="outlined"
              onChange={event => changeInput(event)}
              value={quota}
              error={errorInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              data-id="email-quota"
            />
            <p className={classes.capacityUnity}>MB</p>
            <span className={classes.textTie}>{(t('emails.or'))}</span>
            <FormControlLabel
              classes={{
                root: errorInput && classes.checkboxRootError,
              }}
              control={(
                <Checkbox
                  checked={unlimited}
                  onChange={() => onChangeCheckBox()}
                  value="ilimited"
                  color="primary"
                  classes={{
                    root: errorInput && classes.checkboxRootError,
                  }}
                  data-id="email-unlimited-quota"
                />
              )}
              label={t('emails.unlimited')}
            />
            {!isMobile && (
              <Tooltip
                title={t('emails.toolTipQuota')}
                classes={{
                  tooltip: classes.tootlTipCustom,
                }}
              >
                <InfoIcon classes={{
                  root: classes.iconInfo,
                }}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions classes={{
        root: classes.dialogActionsContent,
      }}
      >
        <OutlineButton
          onClick={onHandleCloseModal}
          color="primary"
          disabled={loadingChangeStorage}
        >
          {t('cancel')}
        </OutlineButton>
        <PrimaryButton
          onClick={() => handleChangeStorageEmailAccount()}
          color="primary"
          autoFocus
          onLoading={loadingChangeStorage}
          className={classes.save}
          data-id="button-submit-email-change-storage"
        >
          {t('emails.change')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

ChangeStorageEmailAccountModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  email: PropTypes.shape({
    hostingId: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    diskQuota: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    diskUnlimited: PropTypes.bool.isRequired,
  }),
};

export default withStyles(styles, { withTheme: true })(withI18n()(ChangeStorageEmailAccountModal));
