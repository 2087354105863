import { ptBR, es } from 'date-fns/locale';

const CODE_LANG = {
  br: 'pt',
  mx: 'mx',
  cl: 'cl',
  co: 'co',
};

const PAYPAL_LANG = {
  br: 'pt_BR',
  mx: 'es_ES',
  cl: 'es_ES',
  co: 'es_ES',
};

const LOCALE_STRING = {
  br: 'pt-BR',
  mx: 'es-ES',
  cl: 'es-ES',
  co: 'es-ES',
};

const DATEFNS_LANG = {
  br: ptBR,
  mx: es,
  cl: es,
  co: es,
};

export const getLangByCountry = country => ({
  code: CODE_LANG[country] || CODE_LANG.br,
  paypal: PAYPAL_LANG[country] || PAYPAL_LANG.br,
  dateFns: DATEFNS_LANG[country] || DATEFNS_LANG.br,
  locale: LOCALE_STRING[country] || LOCALE_STRING.br,
});
