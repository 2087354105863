import React from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { GET_A_NEW_DOMAIN } from '@/config/urls/supportUrls';
import LinkButton from '@/components/Buttons/LinkButton';
import { offerPlanWithDomainNoRegisteredTagManager } from '@/utils/ThirdParties/tagManager';
import styles from './styles';

const ModalCheckDomainAvailable = ({
  onClose, open, classes, t,
}) => {
  const executeGA = () => {
    offerPlanWithDomainNoRegisteredTagManager();
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      open={open}
      className={classes.checkAvailabilityPlanLinkDialog}
    >
      <div id="dialog-title" style={{ height: '45px', textAlign: 'right' }}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography className={classes.titleDialog}>{t('proEmail.domainNoAvailabilityTitleLabel')}</Typography>
        <Typography className={classes.descriptionDialog}>{t('proEmail.domainNoAvailabilityDescriptionLabel')}</Typography>
        <LinkButton target="_blank" to={GET_A_NEW_DOMAIN} onClick={executeGA} className={classes.checkAvailabilityPlanLinkButton} data-testid="linkButton">
          {t('proEmail.checkAvailabilityLabel')}
        </LinkButton>
      </DialogContent>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalCheckDomainAvailable));
