import styled, { css } from 'styled-components';
import IconCheck from '@/media/icons/check.svg';

const Container = styled.div`
  ${({ theme }) => css`
      border-radius: 0px;
      box-shadow: 0px 2px 4px 0px ${theme.color.alto};
      position: relative;
      text-align: left;
      width: 100%;
    `}
`;

export const ContainerRow = styled(Container)`
  ${({ theme, location, background }) => {
    const layout = {
      list: {
        background: `${theme.color.weakBlue} linear-gradient(180deg, ${theme.palette.primary.main} 30%, ${theme.palette.success.primary} 100%)`,
        padding: '0px 0px 0px 5px',
      },
      modal: {
        background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 30%, ${theme.palette.success.primary} 100%)`,
        padding: '0px',
      },
      sites: {
        background: theme.palette.white.light,
        padding: '0px',
      },
      modalHirePlan: {
        background: theme.palette.white.light,
        padding: '0px',
      },
      modalEmailList: {
        background: theme.palette.white.light,
        padding: '0px',
      },
      modalDomainList: {
        background: theme.palette.white.light,
        padding: '0px',
      },
    };

    return css`
      background: ${layout[location].background};
      background-image: url('${background}');
      padding: ${layout[location].padding};
    `;
  }}
`;

export const Figure = styled.figure`
  ${({ theme }) => css`
  margin:0px 15px 0px 0px;
    position: relative;
    .envelope {
      height: 28px;
      position: relative;
      width: 34px;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0px;
    }
    @media (max-width: 413px) {
      margin-top: -40px;
    }
  `}
`;

export const TitleForm = styled.p`
${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-size: ${theme.font.sizes.lg};
  font-weight: ${theme.font.weights.medium};
  letter-spacing: 0.01px;
  font-family:${theme.font.family.secondary};
  margin-top:11px;
`}
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

export const TitleHirePlanWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0px 0px 0px 0px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.dark};
    font-size: 24px;
    font-weight: ${theme.font.weights.regular};
    line-height: 29px;
    margin: 0px 16px 0px 0px;

    @media (max-width: 1230px) {
      order: 2;
    }
  `}
`;

export const TrialTag = styled.span`
  ${({ theme }) => css`
    background-color: ${`${theme.palette.grey.primary}66`};
    color: ${theme.palette.success.primary};
    font-size: 15px;
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    padding: 6px;
    text-transform: uppercase;

    @media (max-width: 1230px) {
      margin: 0px 0px 16px;
      order: 1;
    }
  `}
`;


export const DescriptionContainer = styled(Container)`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    margin: 24px 0px 0px 0px;
    padding: 34px 32px 0px;

    @media (max-width: 1356px){
      padding: 24px 24px 0px;
    }
  `
}`;

export const DescriptionWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 16px;
    letter-spacing: 0.01px;
    line-height: 26px;
    margin: 0px;
    max-width: 720px;
  `}
`;

export const ListWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0px 8px 0px;

    @media (max-width: 700px) {
      flex-direction: column;
      margin: 24px 0px 8px;
    }
  `}
`;

export const RemoveButton = styled.button`
    align-self: center;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    width: fit-content;
    @media (max-width: 579px){
      margin-left: -16px;
      margin-top: 12px;
    }
`;

export const LinkButtonAdapter = styled.div``;

export const DescriptionList = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    margin: 0px 68px 0px 0px;
    padding: 0;
    text-align: left;

    & li {
      color: ${theme.palette.grey.dark};
      display: flex;
      font-size: 14px;
      letter-spacing: 0.01px;
      line-height: 21px;
      margin-bottom: 16px;
      position: relative;

      & > strong, & > b {
        display: contents;
      }

      & span {
        align-items: center;
        background: ${theme.palette.primary.main};
        border-radius: 50%;
        color: ${theme.palette.white.light};
        display: flex;
        font-size: 10px;
        height: 12px;
        justify-content: center;
        margin-left: 8px;
        max-width: 12px;
        position: relative;
        top: 4px;
        width: 100%;
      }

      &:before {
        background-image: url('${IconCheck}');
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 16px;
        margin-right: 8px;
        min-width: 16px;
        position: relative;
        top: 2px;
      }
    }

    & li:last-child {
      margin-bottom: 0px,
    }

    @media (max-width: 700px){
      margin: 0px;
    }
  `}
`;

export const SelectInsideHirePlan = styled.span`
input{
  text-overflow: ellipsis;
  white-space: nowrap;
}
`;

export const Snappy = styled.img`
  display: inline-block;
  height: 246px;
  margin: 0px 0px 0px 72px;
  position: relative;
  top: 40px;
  width: 227px;
  z-index: 1;

  @media (max-width: 1254px){
    display: none;
  }
`;

export const FormWrapper = styled.div`
  ${({ location }) => {
    const layout = {
      list: {
        padding: '0px',
        width: '900px',
        width1356: '100%',
      },
      modal: {
        padding: '0px',
        width: '100%',
      },
      sites: {
        padding: '0px',
        width: '900px',
        width1356: '100%',
      },
      modalHirePlan: {
        padding: '4px',
        width: 'auto',
        width1356: 'auto',
      },
      modalEmailList: {
        padding: '4px',
        width: 'auto',
        width1356: 'auto',
      },
      modalDomainList: {
        padding: '4px',
        width: 'auto',
        width1356: 'auto',
      },
    };
    return css`
    border-radius: 0px;
    margin: ${layout[location].margin};
    padding: ${layout[location].padding};
    position: relative;
    text-align: left;
    width: ${layout[location].width};

    @media (max-width: 1356px){
      top: 0px;
      width: ${layout[location].width1356};
    }

    @media (max-width: 1300px) {
    }
  `;
  }
}}
`;

export const Form = styled.form`
  ${({ theme, location }) => {
    const layout = {
      list: {
        backgroundColor: theme.color.weakBlue,
        padding: '40px 40px 24px',
        padding360: '24px',
        padding1356: '24px',
      },
      modal: {
        backgroundColor: theme.color.weakBlue,
        padding: '40px 120px 24px',
        padding360: '24px',
        padding1356: '24px',
      },
      sites: {
        backgroundColor: '',
        padding: '34px 24px 0px 24px',
        padding360: '24px 4px 24px 24px',
        padding1356: '16px',
      },
      modalHirePlan: {
        backgroundColor: theme.palette.white.light,
        padding: '13px 30px 16px 24px',
        padding360: '24px 24px 24px 24px',
        padding1356: '13px 24px 16px 24px',
      },
      modalEmailList: {
        backgroundColor: theme.palette.white.light,
        padding: '13px 30px 16px 24px',
        padding360: '24px 24px 24px 24px',
        padding1356: '13px 24px 16px 24px',
      },
      modalDomainList: {
        backgroundColor: theme.palette.white.light,
        padding: '13px 30px 16px 24px',
        padding360: '24px 24px 24px 24px',
        padding1356: '13px 24px 16px 24px',
      },
    };

    return css`
      background-color:${layout[location].backgroundColor};
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: ${layout[location].padding};

      @media (max-width: 1356px){
        padding: ${layout[location].padding1356};
      }

      @media (max-width: 360px){
        padding: ${layout[location].padding360};
      }
    `;
  }}
`;

export const SetEmailRow = styled.div`
  ${({ theme, location }) => {
    const layout = {
      list: {
        marginBottom: '',
        marginTop: '',
        width: '534px',
      },
      modal: {
        marginBottom: '',
        marginTop: '',
        width: '534px',
      },
      sites: {
        marginBottom: '',
        marginTop: '',
        width: '534px',
      },
      modalHirePlan: {
        marginBottom: '5px',
        marginTop: '16px',
        width: 'auto',
      },
      modalEmailList: {
        marginBottom: '5px',
        marginTop: '16px',
        width: 'auto',
      },
      modalDomainList: {
        marginBottom: '5px',
        marginTop: '16px',
        width: 'auto',
      },
    };

    return css`
    align-items: flex-start;
    display: inline-flex;
    justify-content: flex-start;
    margin-bottom:${layout[location].marginBottom};
    margin-top:${layout[location].marginTop};
    width: ${layout[location].width};

     div{
      input {
        background-color: ${theme.palette.white.light};
        }
      }
    @media (max-width: 1356px){
      width: 100%;
    }
  `;
  }
}}
`;

export const Label = styled.p`
  ${({ theme, location }) => {
    const layout = {
      list: {
        color: theme.palette.grey.dark,
        fontFamily: '',
        fontWeight: '',
      },
      modal: {
        color: theme.palette.grey.dark,
        fontFamily: '',
        fontWeight: '',
      },
      sites: {
        color: theme.palette.secondary.primary,
        fontFamily: 'Galano',
        fontWeight: theme.font.weights.medium,
      },
      modalHirePlan: {
        color: theme.palette.secondary.primary,
        fontFamily: 'Galano',
        fontWeight: theme.font.weights.medium,
      },
      modalEmailList: {
        color: theme.palette.secondary.primary,
        fontFamily: 'Galano',
        fontWeight: theme.font.weights.medium,
      },
      modalDomainList: {
        color: theme.palette.secondary.primary,
        fontFamily: 'Galano',
        fontWeight: theme.font.weights.medium,
      },
    };
    return css`
      color: ${layout[location].color};
      font-family:  ${layout[location].fontFamily};
      font-size: ${theme.font.sizes.sm};
      font-weight:  ${layout[location].fontWeight};
      margin: 0px 0px 8px 0px;
      text-align: left;
    `;
  }}
`;
export const OutputWrapper = styled.div`
  ${() => css`

  display: flex;
      flex-direction: column;
      margin-left: -20px!important;
      margin: 0px 0px 16px 0px;
      max-width: 100%;
      width: 50%;
      div {
        max-width: unset;

        input{
            text-align: right;
          }
      }

      @media (max-width: 390px){
        width: 45%;
        div {
          max-width: unset;
        }
      }
    `}
`;


export const InputWrapper = styled.div`
  ${({ theme, location, disabled }) => {
    const layout = {
      list: {
        inputFontSize: theme.font.sizes.sm,
        inputHeight: 'calc( 40px - 14px )',
        marginRight: '20px',
        marginRight400px: '20px',
        maxWidth: '534px',
        width1356: '50%',
      },
      modal: {
        inputFontSize: theme.font.sizes.sm,
        inputHeight: 'calc( 40px - 14px )',
        marginRight: '0px',
        marginRight400px: '0px',
        maxWidth: '100%',
        width1356: '50%',
      },
      sites: {
        inputFontSize: theme.font.sizes.md,
        inputHeight: '42px',
        marginRight: '20px',
        marginRight400px: '20px',
        maxWidth: '100%',
        maxWidth400px: '156px',
        width1356: '50%',
      },
      modalHirePlan: {
        inputFontSize: theme.font.sizes.md,
        inputHeight: '42px',
        marginRight: '20px',
        marginRight400px: '0px',
        maxWidth: '100%',
        maxWidth400px: '',
        width1356: '',
      },
      modalEmailList: {
        inputFontSize: theme.font.sizes.md,
        inputHeight: '42px',
        marginRight: '20px',
        marginRight400px: '0px',
        maxWidth: '100%',
        maxWidth400px: '',
        width1356: '',
      },
      modalDomainList: {
        inputFontSize: theme.font.sizes.md,
        inputHeight: '42px',
        marginRight: '20px',
        marginRight400px: '0px',
        maxWidth: '100%',
        maxWidth400px: '',
        width1356: '',
      },
    };

    return css`

      display: flex;
      flex-direction: column;
      margin: 0px 0px 16px 0px;
      margin-right:${layout[location].marginRight};
      max-width: ${layout[location].maxWidth};
      width: 100%;

      > div {
        margin: 0;
        width: auto;

        > div {
          position: relative;
          width: 100%;

          > fieldset {
            border-radius: ${disabled ? '0px 2px 2px 0px' : '2px 0px 0px 2px'};
            border: 1px solid ${theme.palette.grey.primary};
            padding: 0px 0px 0px 8px;
          }

          > input {
            background-color: ${disabled ? theme.palette.primary.contrastText : theme.palette.white.light};
            color: ${disabled ? theme.palette.grey.light : theme.palette.grey.dark};
            font-size: ${layout[location].inputFontSize};
            height: ${layout[location].inputHeight};
            letter-spacing: 0px;
            line-height: 21px;
            padding: 7px;
            width: calc(100% - 14px);

            &::placeholder {
              color: ${theme.palette.grey.light};
              opacity: 1;
            }
          }
        }
      }

      @media (max-width: 1356px){
        width:  ${layout[location].width1356};
        div {
          max-width: 100%;
          > span {
            max-width: 100%;
            width: 100%;
          }
        }
      }

      @media (max-width: 400px){
        margin-right:${layout[location].marginRight400px};
        max-width: ${layout[location].maxWidth400px};
        width: 100%;
        div {
          max-width: unset;
        }
      }
    `;
  }}
`;

export const SelectWrapper = styled.div`
${({ theme, location }) => {
    const layout = {
      list: {
        height: '',
        maxWidth: '120px',
      },
      modal: {
        height: '',
        maxWidth: '',
      },
      sites: {
        height: '44px',
        maxWidth: '156px',
      },
      modalHirePlan: {
        height: '44px',
        maxWidth: '',
      },
      modalEmailList: {
        height: '44px',
        maxWidth: '',
      },
      modalDomainList: {
        height: '44px',
        maxWidth: '',
      },
    };

    return css`
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      height: ${layout[location].height};

      > fieldset {
        border-radius: 0px 2px 2px 0px !important;
        background-color: ${theme.palette.secondary.contrastText};
      }

      > div {
        > div {
          height: calc(40px - 17px);
          padding-bottom: 5px;
          padding-top: 11px;

          @media (max-width: 360px){
            max-width:  ${layout[location].maxWidth};
          }
        }
      }
    }
    `;
  }}
`;

export const FixedEmailAddress = styled.p`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.contrastText};
    border: 1px solid ${theme.palette.grey.primary};
  `}
`;

export const PasswordWrapper = styled.div`
  ${({ theme, location, hasTrashIcon }) => {
    const layout = {
      list: {
        margin: '0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 48px',
        maxWidth: '232px',
        width: '100%',
      },
      modal: {
        margin: '0px 0px 0px 0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 48px',
        maxWidth: 'calc(50% - 8px)',
        textAlign: 'left',
        width: '100%',
      },
      sites: {
        margin: '0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 16px',
        maxWidth: '232px',
        textAlign: 'left',
        width: '100%',
      },
      modalHirePlan: {
        margin: '0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 48px',
        maxWidth: '232px',
        textAlign: 'right',
        width: '100%',
      },
      modalEmailList: {
        margin: '0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 48px',
        maxWidth: '232px',
        textAlign: 'right',
        width: '100%',
      },
      modalDomainList: {
        margin: '0px',
        margin1356: '0px 0px 24px 0px',
        marginHasTrashIcon: '0px 0px 0px 48px',
        maxWidth: '232px',
        textAlign: 'right',
        width: '100%',
      },
    };

    return css`
      display: flex;
      flex-direction: column;
      margin: ${layout[location].margin};
      max-width: ${layout[location].maxWidth};
      width: ${layout[location].width};

      ${!hasTrashIcon
      && css`
        margin-right: ${layout[location].marginHasTrashIcon};
      `}

      > div {
        margin: 0;
        width: auto;
        > div {
          background-color: ${theme.palette.white.light};
          display: inline-flex;
          position: relative;

          > fieldset {
            border-radius: 2px 0px 0px 2px;
            border: 1px solid ${theme.palette.grey.primary};
            padding: 0px 0px 0px 8px;
          }

          > input {
            font-size: ${theme.font.sizes.sm};
            height: calc( 40px - 14px );
            letter-spacing: 0px;
            line-height: 21px;
            padding: 7px;
            &:-ms-reveal,
            &:-ms-clear {
              display: none;
            }

            &::placeholder {
              color: ${theme.palette.grey.light};
              opacity: 1;
            }
          }
        }
      }

      @media (max-width: 1356px){
        margin: ${layout[location].margin1356};
        max-width: 100%;
        width: 100%;
      }
    `;
  }}
`;

export const OptionInput = styled.input`
  ${() => css`
    margin: 8px 8px 0px -2px;

    @media (max-width:1356px){
      margin: 15px 8px 0px -2px;
    }
  `}
`;

export const ActionWrapper = styled.div`
  ${({ theme, location }) => {
    const layout = {
      list: {
        borderDisabledButton: `5px solid ${theme.palette.grey.primary}`,
        buttonWidth: 'unset',
        display: 'inline-block',
        fontFamily: '',
        fontSize: theme.font.sizes.sm,
        margin: '0px 0px 0px 20px',
        marginButtom1356: '8px 0px 0px 0px',
        paddingButtom: '10px 16px',
        width: 'unset',
        width1381: '100%',
      },
      modal: {
        borderDisabledButton: `5px solid ${theme.palette.grey.primary}`,
        buttonWidth: '100%',
        display: 'inline-block',
        fontFamily: '',
        fontSize: theme.font.sizes.sm,
        margin: '24px 0px 0px auto',
        marginButtom1356: '8px 0px 0px 0px',
        paddingButtom: '10px 16px',
        width: 'calc(50% - 8px)',
        textAlign: '',
        width1381: '100%',
      },
      sites: {
        borderDisabledButton: '',
        bordernWidth: '5px',
        buttonWidth: 'unset',
        display: 'inline-block',
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.md,
        margin: '0px 0px 0px 24px',
        marginButtom1356: '32px 32px 0px 0px',
        paddingButtom: '10px 23px 9px 23px',
        width: 'unset',
        textAlign: '',
        width1381: '100%',
      },
      modalHirePlan: {
        borderDisabledButton: theme.palette.grey.ultralight,
        bordernWidth: '5px',
        buttonWidth: 'unset',
        display: '',
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.md,
        margin: '0px 0px 0px 0px',
        marginButtom1356: '0px 0px 0px 0px',
        marginButtomButton: '32px 0px 8px',
        paddingButtom: '10px 23px 9px 23px',
        width: '100%',
        textAlign: 'center',
        width1381: 'auto',
      },
      modalEmailList: {
        borderDisabledButton: theme.palette.grey.ultralight,
        bordernWidth: '5px',
        buttonWidth: 'unset',
        display: '',
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.md,
        margin: '0px 0px 0px 0px',
        marginButtom1356: '0px 0px 0px 0px',
        marginButtomButton: '32px 0px 8px',
        paddingButtom: '10px 23px 9px 23px',
        width: '100%',
        textAlign: 'center',
        width1381: 'auto',
      },
      modalDomainList: {
        borderDisabledButton: theme.palette.grey.ultralight,
        bordernWidth: '5px',
        buttonWidth: 'unset',
        display: '',
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.md,
        margin: '0px 0px 0px 0px',
        marginButtom1356: '0px 0px 0px 0px',
        marginButtomButton: '32px 0px 8px',
        paddingButtom: '10px 23px 9px 23px',
        width: '100%',
        textAlign: 'center',
        width1381: 'auto',
      },
    };

    return css`
      display: ${layout[location].display};
      margin: ${layout[location].margin};
      width: ${layout[location].width};
      text-align: ${layout[location].textAlign};

      button {
        font-family: ${layout[location].fontFamily};
        font-size: ${layout[location].fontSize};
        font-weight: ${theme.font.weights.medium};
        height: 40px;
        letter-spacing: 0.01px;
        line-height: 19px;
        min-width: 208px;
        margin:${layout[location].marginButtomButton};
        padding: ${layout[location].paddingButtom};
        width ${layout[location].buttonWidth};

        &.disabled {
          border:  ${layout[location].borderDisabledButton};
          background-color: ${theme.palette.grey.ultralight};
          color:${theme.palette.grey.main};
        }
      }

      @media (min-width:1381px){
        > div {
          width: ${layout[location].width1381};
        }
      }

      @media (max-width:1356px){
        margin: 29px 0px 0px auto;
      }

      @media (max-width:1356px){
        margin: ${layout[location].marginButtom1356};
        text-align: center;
        width: 100%;
      }
    `;
  }}
`;


export const CheckBoxDescription = styled.p`
  ${({ theme }) => css`
      background-color: ${theme.palette.white.light};
      color: ${theme.palette.grey.dark};
      display:flex;
      font-family:${theme.font.family.secondary};
      font-size: ${theme.font.sizes.sm};
      letter-spacing: 0.01px;
      line-height: 18px;
      margin-top: 8px;
      margin: -1px 0px 0px 0px;
      padding: 2px 0px 0px;
      text-align: left;
    `}
`;


export const PosDescription = styled.p`
  ${({ theme, location }) => {
    const layout = {
      list: {
        backgroundColor: theme.color.weakBlue,
        display: '',
        fontSize: theme.font.sizes.sm,
        linkColor: theme.palette.primary.main,
        padding: '0px 40px 40px',
        paddingTerms: '0px 24px 24px',
        textAlign: 'left',
        marginTopA: '',
        textAlign1356px: 'center',
        marginTopA1356px: '16px',
      },
      modal: {
        backgroundColor: theme.color.weakBlue,
        display: '',
        fontSize: theme.font.sizes.sm,
        linkColor: theme.palette.primary.main,
        padding: '0px 76px 63px',
        paddingTerms: '0px 24px 24px',
        textAlign: 'center',
        textAlign1356px: 'center',
        marginTopA: '',
        marginTopA1356px: '16px',
      },
      sites: {
        backgroundColor: theme.palette.white.light,
        display: '',
        fontSize: theme.font.sizes.xs,
        linkColor: theme.palette.secondary.primary,
        padding: '17px 0px 29px 24px',
        paddingTerms: '17px 24px 24px',
        textAlign: 'left',
        textAlign1356px: 'center',
        marginTopA: '',
        marginTopA1356px: '16px',
      },
      modalHirePlan: {
        backgroundColor: theme.palette.white.light,
        display: 'block',
        fontSize: theme.font.sizes.xs,
        linkColor: theme.palette.secondary.primary,
        padding: '7px 0px 9px',
        paddingTerms: '13px 0px 9px',
        textAlign: 'left',
        textAlign1356px: 'left',
        marginTopA: '8px',
        marginTopA1356px: '8px',
      },
      modalEmailList: {
        backgroundColor: theme.palette.white.light,
        display: 'block',
        fontSize: theme.font.sizes.xs,
        linkColor: theme.palette.secondary.primary,
        padding: '7px 0px 9px',
        paddingTerms: '13px 0px 9px',
        textAlign: 'left',
        textAlign1356px: 'left',
        marginTopA: '8px',
        marginTopA1356px: '8px',
      },
      modalDomainList: {
        backgroundColor: theme.palette.white.light,
        display: 'block',
        fontSize: theme.font.sizes.xs,
        linkColor: theme.palette.secondary.primary,
        padding: '7px 0px 9px',
        paddingTerms: '13px 0px 9px',
        textAlign: 'left',
        textAlign1356px: 'left',
        marginTopA: '8px',
        marginTopA1356px: '8px',
      },
    };

    return css`
      background-color: ${layout[location].backgroundColor};
      color: ${theme.palette.grey.dark};
      font-size: ${theme.font.sizes.sm};
      letter-spacing: 0.01px;
      line-height: 18px;
      margin-top: 8px;
      margin: -1px 0px 0px 0px;
      padding: ${layout[location].padding};
      text-align: ${layout[location].textAlign};

      a {
        color: ${layout[location].linkColor};
        font-size: 14px;
        font-weight: ${theme.font.weights.bold};
        letter-spacing: 0.01px;
        text-align: ${layout[location].textAlign};
        text-decoration: underline;
        display: ${layout[location].display};
        margin-top: ${layout[location].marginTopA};;

        @media (max-width: 1356px){
          display: block;
          margin-top: ${layout[location].marginTopA1356px};
          text-align: ${layout[location].textAlign1356px};
        }

        @media (max-width: 700px){
          margin-top: 16px;
        }
      }

      @media (max-width: 1356px){
        padding: ${layout[location].paddingTerms};
        text-align: ${layout[location].textAlign1356px};
        with:100%!important;
      }
    `;
  }}
`;
