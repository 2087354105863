import React from 'react';
import * as Styles from './FormOpenAccount.styles';
import {
  Button,
  RadioOption,
  Select,
} from '@/pages/common/v1';
import { Input } from '@/pages/common/v1/inputs';
import { asaasEnum } from '../../AsaasHandler.enum';

const AsaasFirstRow = ({
  testId = 'asaas-form-open-account',
  formData,
  onChange,
  onContinue,
  enabledFormOpenAccountContinue,
  cnpjTypes,
  isInsideWizard = false,
}) => {
  const handleChange = (e) => {
    onChange && onChange({ value: e.target.value, name: e.target.name });
  };

  const handleToggle = (e, document) => {
    onChange && onChange({ value: document, name: e.name });
  };

  const handleChangeSelect = (e) => {
    onChange && onChange({ value: e.value, name: 'companyType' });
  };

  const handleContinue = () => {
    onContinue && onContinue(asaasEnum.formStepper.OPEN_ACCOUNT);
  };


  return (
    <Styles.FormContent data-testid={testId}>
      <Styles.FormTtitle>{formData.texts[formData.activeStep].title}</Styles.FormTtitle>
      <Styles.FormDescription>{formData.texts[formData.activeStep].description}</Styles.FormDescription>

      <Styles.InputWrapper>
        <Input
          placeholder={formData.fields.name.placeholder}
          value={formData.fields.name.value}
          name="name"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          errorMessage={formData.fields.name.error}
          maxLength={100}
          testId={`${testId}-input-name`}
        />
        <Input
          placeholder={formData.fields.email.placeholder}
          value={formData.fields.email.value}
          name="email"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          errorMessage={formData.fields.email.error}
          maxLength={100}
          testId={`${testId}-input-email`}
        />
      </Styles.InputWrapper>

      <Styles.Question>{formData.texts[formData.activeStep].questionDocument}</Styles.Question>

      <Styles.RadioWrapper>
        <RadioOption
          testId={formData.texts[formData.activeStep].document.cpf}
          title={formData.texts[formData.activeStep].document.cpf}
          basis="1 0 123px"
          onToggle={e => handleToggle(e, 'CPF')}
          checked={formData.fields.documentType.value === 'CPF'}
          name="documentType"
        />
        <RadioOption
          testId={formData.texts[formData.activeStep].document.cnpj}
          title={formData.texts[formData.activeStep].document.cnpj}
          basis="1 0 123px"
          onToggle={e => handleToggle(e, 'CNPJ')}
          checked={formData.fields.documentType.value === 'CNPJ'}
          name="documentType"
        />
      </Styles.RadioWrapper>

      <Styles.InputWrapper>
        <Input
          placeholder={formData.fields.documentType.value || 'CPF'}
          value={formData.fields.documentValue.value}
          name="documentValue"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          mask={formData.fields.documentType.value && formData.fields.documentType.value.toLowerCase()}
          errorMessage={formData.fields.documentValue.error}
          testId={`${testId}-input-documentValue`}
        />

        <Input
          placeholder={formData.fields.monthlyIncome && formData.fields.monthlyIncome.placeholder}
          value={formData.fields.monthlyIncome && formData.fields.monthlyIncome.value}
          name="monthlyIncome"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          mask="money"
          errorMessage={formData.fields.monthlyIncome && formData.fields.monthlyIncome.error}
          testId={`${testId}-input-monthlyIncome`}
        />

        {formData.fields.documentType.value === 'CPF' && (
          <Input
            placeholder={formData.fields.birthday.placeholder}
            value={formData.fields.birthday.value}
            name="birthday"
            onChange={handleChange}
            variant="textWithBorder"
            type="text"
            mask="date"
            maxLength={10}
            errorMessage={formData.fields.birthday.error}
            testId={`${testId}-input-birthday`}
          />
        )}

        {formData.fields.documentType.value === 'CNPJ' && (
          <Select
            placeholder={formData.fields.companyType.placeholder}
            testId={`${testId}-select-companyType`}
            options={cnpjTypes}
            onChange={handleChangeSelect}
          />
        )}

        {formData.fields.companyType.error && (
          <Styles.LabelError>
            {formData.fields.companyType.error}
          </Styles.LabelError>
        )}
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          label={formData.texts[formData.activeStep].continueButtonLabel}
          onClick={handleContinue}
          testId={`${testId}-continue`}
          disabled={!enabledFormOpenAccountContinue()}
        />
      </Styles.ButtonWrapper>

      {!isInsideWizard && (
        <Styles.AccessWrapper>
          {formData.texts[formData.activeStep].accessAsaas}
        </Styles.AccessWrapper>
      )}
    </Styles.FormContent>
  );
};

export default AsaasFirstRow;
