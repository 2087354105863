import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';

import { IconArrowDownOutlined } from '@/icons';

import * as Styles from './SelectBottomDashed.style';

const SelectBottomDashed = ({
  options, onChange, label, value, width, position,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const domainSelectRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!domainSelectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [domainSelectRef]);

  const currentLabel = useMemo(() => {
    const foundedValue = options.find(option => option.value === value);

    if (foundedValue) {
      return foundedValue.label;
    }

    return label;
  }, [value, label, options]);

  const optionsToShow = useMemo(() => options.map(option => (
    <Styles.SelectOptions
      key={option.value}
      role="option"
      aria-label="option"
      aria-selected={option.value === value}
      data-testid={`select-list-option-${option.value}`}
      value={option.value}
      title={option.label}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsOpen(false);
        onChange(option);
      }}
    >
      {option.label}
    </Styles.SelectOptions>
  )), [options, setIsOpen, onChange, value]);

  return (
    <Styles.WrapperSelectDashed
      width={width}
      position={position}
      ref={domainSelectRef}
      data-testid="select-dashed"
    >
      <Styles.LabelSelectDashed
        data-testid="label-select-dashed"
        id="listboxLabel"
        aria-label="label of select"
        aria-labelledby="listboxLabel"
        hasValue={value}
        isVoid={options.length === 0}
        isOpen={isOpen}
        onClick={() => setIsOpen(previousIsOpen => !previousIsOpen)}
      >
        <span className="text">
          {currentLabel}
        </span>
        <IconArrowDownOutlined />
      </Styles.LabelSelectDashed>

      <Styles.SelectListBox
        data-testid="select-listbox"
        role="listbox"
        aria-label="Select domain"
        aria-activedescendant="domain-select"
        isOpen={isOpen}
      >
        <Styles.WrapperSelectOptions>
          {isOpen && optionsToShow}
        </Styles.WrapperSelectOptions>
      </Styles.SelectListBox>
    </Styles.WrapperSelectDashed>
  );
};


export default SelectBottomDashed;
