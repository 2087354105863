import { CommonActionTypes } from './common.types';

export const initialState = {
  loading: false,
  notifications: [],
  redirect: null,
  serverTime: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommonActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }
    case CommonActionTypes.SET_NOTIFICATIONS: {
      const newNotifications = [...state.notifications];
      newNotifications.push(action.payload);
      return {
        ...state,
        notifications: newNotifications,
      };
    }
    case CommonActionTypes.CLEAR_NOTIFICATIONS: {
      const newNotifications = [...state.notifications];
      newNotifications.splice(action.payload.index, 1);
      return {
        ...state,
        notifications: newNotifications,
      };
    }
    case CommonActionTypes.SET_REDIRECT: {
      return {
        ...state,
        redirect: { ...action.payload.redirect },
      };
    }
    case CommonActionTypes.CLEAR_REDIRECT: {
      return {
        ...state,
        redirect: null,
      };
    }
    case CommonActionTypes.SET_SERVER_TIME: {
      return {
        ...state,
        serverTime: action.payload.serverTime,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default commonReducer;
