import React, { useCallback, useState } from 'react';
import * as Styles from './MigrateResellerBr.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const MigrateResellerBr = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { migrateReseller: migrateResellerLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    panel: '',
    user: '',
    password: '',
    link: '',
    multipleDomains: '',
    multipleDomainsCpanel: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.domain
    && !!formData.panel
    && !!formData.user
    && !!formData.password
    && !!formData.link
    && !!formData.agreed
  ), [formData]);

  const panelOptions = [
    {
      label: 'WHM',
      value: 'WHM',
    },
    {
      label: 'cPanel',
      value: 'cPanel',
    },
    {
      label: 'Plesk',
      value: 'Plesk',
    },
    {
      label: migrateResellerLocale.placeholders.other,
      value: migrateResellerLocale.placeholders.other,
    },
  ];

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.migrateReseller,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.MIGRATE_RESELLER,
      tags: [
        'whmcs_form_tecnico_migracao_revenda',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
         • ${migrateResellerLocale.body.product(selectedProduct)}
         • ${migrateResellerLocale.body.request}
         • ${migrateResellerLocale.placeholders.domain}: ${formData.domain}
         • ${migrateResellerLocale.placeholders.panel}: ${formData.panel}
         • ${migrateResellerLocale.placeholders.user}: ${formData.user}
         • ${migrateResellerLocale.placeholders.password}: ${formData.password}
         • ${migrateResellerLocale.placeholders.link}: ${formData.link}
         • ${migrateResellerLocale.placeholders.multipleDomainsLabel}: ${formData.multipleDomains}
         • ${migrateResellerLocale.placeholders.mutipleDomainsCpanelOtherLabel}: ${formData.multipleDomainsCpanel}
         • ${migrateResellerLocale.body.agreed({ text: migrateResellerLocale.placeholders.agreed, bool: formData.agreed })}
         • ${migrateResellerLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    migrateResellerLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {migrateResellerLocale.info}
      </Styles.Info>

      <Styles.Title>
        {migrateResellerLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="domain"
          placeholder={migrateResellerLocale.placeholders.domain}
          label={formData.domain ? migrateResellerLocale.placeholders.domain : ''}
          value={formData.domain}
          onChange={e => handleChange({ key: 'domain', value: e.target.value })}
          type="text"
          testId="domain"
        />

        <Select
          name="panel"
          testId="panel"
          placeholder={migrateResellerLocale.placeholders.panel}
          label={formData.panel ? migrateResellerLocale.placeholders.panel : ''}
          options={panelOptions}
          value={formData.panel}
          setValue={label => handleChange({ key: 'panel', value: label })}
          withScroll
        />

        <Input
          name="user"
          placeholder={migrateResellerLocale.placeholders.user}
          label={formData.user ? migrateResellerLocale.placeholders.user : ''}
          value={formData.user}
          onChange={e => handleChange({ key: 'user', value: e.target.value })}
          type="text"
          testId="user"
        />

        <Input
          name="password"
          placeholder={migrateResellerLocale.placeholders.password}
          label={formData.password ? migrateResellerLocale.placeholders.password : ''}
          value={formData.password}
          onChange={e => handleChange({ key: 'password', value: e.target.value })}
          type="text"
          testId="password"
        />

        <Input
          name="link"
          placeholder={migrateResellerLocale.placeholders.link}
          label={formData.link ? migrateResellerLocale.placeholders.link : ''}
          value={formData.link}
          onChange={e => handleChange({ key: 'link', value: e.target.value })}
          type="text"
          testId="linkField"
        />

        <Input
          name="multipleDomains"
          placeholder={migrateResellerLocale.placeholders.multipleDomainsPlaceHolder}
          label={migrateResellerLocale.placeholders.multipleDomainsLabel}
          value={formData.multipleDomains}
          onChange={e => handleChange({ key: 'multipleDomains', value: e.target.value })}
          type="textarea"
          rows={5}
          testId="multipleDomains"
        />

        <Input
          name="multipleDomainsCpanel"
          placeholder={migrateResellerLocale.placeholders.mutipleDomainsCpanelOtherPlaceholder}
          label={migrateResellerLocale.placeholders.mutipleDomainsCpanelOtherLabel}
          value={formData.multipleDomainsCpanel}
          onChange={e => handleChange({ key: 'multipleDomainsCpanel', value: e.target.value })}
          type="textarea"
          rows={5}
          testId="multipleDomainsCpanel"
        />
      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {migrateResellerLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : migrateResellerLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default MigrateResellerBr;
