import styled, { css } from 'styled-components';

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    position: relative;
  `}
`;

export const TagWrapperDesktop = styled.div`
  ${({ theme }) => css`
    display: none;
    
    @media(min-width:${theme.v2.breakpoints.sm}){
      display: block;
    }
  `}
`;

export const TagWrapperMobile = styled.div`
  ${({ theme }) => css`
    display: block;

    @media(min-width:${theme.v2.breakpoints.sm}){
      display: none;
    }
  `}
`;

export const DnsContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;
    margin: 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      flex-direction: row;
    }
  `}
`;


export const DnsSpan = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    gap: 8px;
  `}
`;

export const SlashSpan = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.feedback.canceledMedium};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    gap: 8px;
  `}
`;


export const NoPaddingAlertWrapper = styled.div`
  > div {
    padding: 0px;
  }
`;
