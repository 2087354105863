import React from 'react';
import * as Styles from './Button.styles';

const Button = ({
  disabled = false,
  label = 'Button text',
  onClick,
  iconLeft,
  iconLeftDirection = 'left',
  iconRightDirection = 'left',
  testId = 'button',
  type = 'button',
  variant = 'default',
  size = 'medium',
  display = 'inline',
  titleEnabled = true,
  align = 'center',
  iconRight,
  icon,
  font = 'primary',
}) => {
  const handleOnClick = (event) => {
    onClick && onClick(event);
  };

  return (
    <Styles.Wrapper disabled={disabled}>
      <Styles.Button
        align={align}
        className={disabled ? 'disabled' : ''}
        data-testid={testId}
        disabled={disabled}
        display={display}
        onClick={handleOnClick}
        size={size}
        title={titleEnabled ? label : ''}
        type={type}
        variant={variant}
        font={font}
      >
        {iconLeft && (
          <Styles.IconLeft
            iconDirection={iconLeftDirection}
            data-testid="icon-left"
          >
            {iconLeft}
          </Styles.IconLeft>
        )}

        {icon ? (
          <Styles.Icon
            data-testid="icon"
          >
            {icon}
          </Styles.Icon>
        ) : <Styles.Label>{label}</Styles.Label>}

        {iconRight && (
          <Styles.IconRight
            iconDirection={iconRightDirection}
            data-testid="icon-right"
          >
            {iconRight}
          </Styles.IconRight>
        )}
      </Styles.Button>
    </Styles.Wrapper>
  );
};

export default Button;
