import styled, { css } from 'styled-components';

export const GroupedCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EmptyState = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.neutral.highPure};
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    justify-content: center;
    padding: 89px;
  `}
`;

export const NewActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
