import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 28px 0px 10px 32px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 32px 0px 20px 24px;
    }
  `}
`;

export const Icon = styled.span`
    margin: 18px 0px 0px 0px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font: ${theme.v1.font.family.primary}
    font-weight: ${theme.font.weights.medium};
  `}
`;

export const Action = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `}
`;

export const ContentWrapper = styled.div`
${({ theme }) => css`
  display:flex;
  margin: 0px 31px;
  padding: 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    align-items:center;
    flex-direction:column;
    margin: 0px 24px;
    svg{
      height:60px;
      margin-bottom:20px;
      width: 60px;
    }
  }
  `}
`;

export const Content = styled.div`
${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.sm};
  letter-spacing: 0px;
  line-height: ${theme.font.lineHeights.lg};
  margin: 0px 0px 40px 12px;
  a{
    color: ${theme.palette.secondary.primary};
    display:inline;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 0px 0px 40px 0px;
  }
`}
`;
