import styled, { css } from 'styled-components';

export const Description = styled.p`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    line-height: ${theme.v2.font.lineHeight.text};
    text-align: center;
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 32px;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const Password = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
`;

export const RequirementsLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const Requirements = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 430px;
`;
