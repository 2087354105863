import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const TicketFooter = ({ classes, children, className }) => (
  <div className={`${classes.footer} ${className}`}>
    {children}
  </div>
);

TicketFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(TicketFooter);
