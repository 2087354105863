const styles = theme => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: '0px',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    padding: '30px',
    borderLeft: `5px solid ${theme.color.oceanGreen}`,
  },
  statusActive: {
    borderLeftColor: theme.color.oceanGreen,
  },
  statusPending: {
    borderLeftColor: theme.color.brightSun,
  },
  statusPendingTransfer: {
    borderLeftColor: theme.color.brightSun,
  },
  statusExpired: {
    borderLeftColor: theme.color.valencia,
  },
  statusCancelled: {
    borderLeftColor: theme.color.geyser,
  },
  statusFraud: {
    borderLeftColor: theme.color.brightSun,
  },
  statusSuspended: {
    borderLeftColor: theme.color.valencia,
  },
  statusBuilding: {
    borderLeftColor: theme.color.coral,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderBottom: `1px dashed ${theme.color.mystic}`,
  },
  cardHeaderInner: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    marginRight: 40,
  },
  cardIcon: {
    marginRight: '15px',
  },
  cardName: {
    marginRight: '15px',
  },
  cardTitle: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.color.tundora,
  },
  cardSubtitle: {
    fontSize: '12px',
    color: theme.color.regentGray,
  },
  cardActions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  cardWarning: {
    marginLeft: '40px',
    marginBottom: '20px',
  },
  cardWarningLink: {
    color: theme.color.indigo,
  },
  cardContent: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'stretch',
    '@media(max-width: 710px)': {
      display: 'block',
    },
  },
  chiplinkWrapper: {
    alignSelf: 'flex-start',
  },
});

export default styles;
