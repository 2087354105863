import { withStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { find, propEq } from 'ramda';
import { useDispatch } from 'react-redux';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import { EmailsContext } from '@/contexts/Emails/context';
import Loader from '@/components/Layout/Loader';
import { requestUpgradePlans } from '@/redux/actions/emails';
import { COUNTRY } from '@/config';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import UpgradePlanCycleEnabled from './UpgradePlanCycleEnabled';
import UpgradeNotEnabled from './UpgradeNotEnabled';
import styles from './styles';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ALPHA_TITAN_UPGRADE_PLAN_CYLE } from '@/config/GrowthBook/constants';

const TitanUpgrade = ({ classes, domain }) => {
  const dispatch = useDispatch();

  const shouldRenderUpgrade = useFeatureIsOn(ALPHA_TITAN_UPGRADE_PLAN_CYLE);

  const [plans, setPlans] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const { renderedEmailList, loadingProEmails } = useContext(EmailsContext);
  const domainObject = find(propEq('domain', domain), renderedEmailList);

  useEffect(() => {
    dispatch(requestUpgradePlans()).then((response) => {
      const plans = [];

      plans.push({
        configoptions: [
          {
            monthly: '0.00',
            quarterly: '0.00',
            semiannually: '0.00',
            annually: '0.00',
            biennially: '0.00',
          },
        ],
        id: COUNTRY === 'br' ? 418 : 241,
      });

      response.data.forEach((item) => {
        plans.push(item);
      });

      if (plans.length > 2 && plans.find(plan => plan.id === TRIAL_PLAN)) {
        const trialPlan = plans.find(plan => plan.id === TRIAL_PLAN);
        plans.splice(plans.indexOf(trialPlan));
        plans.splice(1, 0, trialPlan);
      }

      setPlans(plans);
      setFirstLoad(false);
    });
  }, [dispatch]);

  if ((renderedEmailList.length === 0) || firstLoad || domainObject === undefined || loadingProEmails) {
    return <Loader />;
  }

  if (!shouldRenderUpgrade) {
    return (
      <ContentContainer className={classes.root}>
        <UpgradeNotEnabled domain={domain} />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer className={classes.root}>
      <UpgradePlanCycleEnabled domain={domain} domainObject={domainObject} plans={plans} />
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles)(TitanUpgrade));
