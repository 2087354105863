import React from 'react';
import * as Style from './IconAttentionYellow.styles';

const IconAttentionYellow = ({ testId = 'icon-attention-yellow' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path id="error_black_24dp" d="M14,2A12,12,0,1,0,26,14,12,12,0,0,0,14,2Zm1.2,18H12.8V17.6h2.4Zm0-4.8H12.8V8h2.4Z" transform="translate(-2 -2)" fill="#ffd54c" />
    </svg>

  </Style.Wrapper>
);


export default IconAttentionYellow;
