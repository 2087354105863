import React, { useState } from 'react';
import * as Styles from './AccountSecurity.styles';
import { useSelector } from 'react-redux';
import { TwoFactorAuth } from './TwoFactorAuth';
import useLocale from '@/hooks/useLocale';
import { ValidateCodeModal } from './TwoFactorAuth/ValidateCodeModal';
import ChangePassword from './ChangePassword';
import ChangePasswordModal from './ChangePassword/ChangePasswordModal';

const AccountSecurity = () => {
  const userEmail = useSelector(state => state.summary.email);
  const { myData: myDataLocale } = useLocale();
  const twoFactorIsEnabled = useSelector(state => state.summary.twoFactorIsEnabled);
  const twoFactorAuthLocale = myDataLocale.twoFactorAuth;

  const [modal, setModal] = useState({
    open: false,
    title: '',
    content: '',
    type: '',
  });

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const openDisableTwoFactorModal = () => {
    setModal({
      open: true,
      title: twoFactorAuthLocale.validateCodeModal.disable2FA.title,
      primaryButtonLabel: twoFactorAuthLocale.validateCodeModal.disable2FA.buttonLabel,
      type: 'disable',
    });
  };

  const openEnableTwoFactorModal = () => {
    setModal({
      open: true,
      title: twoFactorAuthLocale.validateCodeModal.enable2FA.title,
      primaryButtonLabel: twoFactorAuthLocale.validateCodeModal.enable2FA.buttonLabel,
      type: 'enable',
    });
  };

  const togglePasswordModal = () => {
    setShowChangePasswordModal(prev => !prev);
  };

  const twoAuthButtonClick = twoFactorIsEnabled ? openDisableTwoFactorModal : openEnableTwoFactorModal;

  return (
    <>
      <Styles.Wrapper>
        <Styles.Container>
          <Styles.Content>
            <ChangePassword
              togglePasswordModal={togglePasswordModal}
            />
            <Styles.Divider />
            <TwoFactorAuth
              userEmail={userEmail}
              twoFactorIsEnabled={twoFactorIsEnabled}
              twoFactorAuthLocale={twoFactorAuthLocale}
              twoAuthButtonClick={twoAuthButtonClick}
            />
          </Styles.Content>
        </Styles.Container>
      </Styles.Wrapper>

      <ValidateCodeModal
        modal={modal}
        setModal={setModal}
        twoFactorIsEnabled={twoFactorIsEnabled}
        twoFactorAuthLocale={twoFactorAuthLocale}
        userEmail={userEmail}
      />

      <ChangePasswordModal
        isOpen={showChangePasswordModal}
        togglePasswordModal={togglePasswordModal}
      />
    </>
  );
};

export default AccountSecurity;
