import styled, { css } from 'styled-components';

const defaultWrapperWidth = '540px';

export const Form = styled.form`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0px 24px;
  max-width: ${defaultWrapperWidth};

  div {
    width: 100%;
  }
`;

export const RadioOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RadioQuestionTitle = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.medium};
  `};
`;

export const Info = styled.span`
  ${({ theme }) => css`
    display: block;
    flex-direction: row;
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;
    max-width: ${defaultWrapperWidth};
    color: ${theme.v2.colors.neutral.lowPure};

    li {
      margin-bottom: 8px;
    }

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  button {
    max-height: 35px;
  }
`;

export const RadioLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;
