const styles = theme => ({
  icon: {
    fontSize: '160px',
    position: 'absolute',
    top: '472px',
    left: '5px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  st0: { fill: '#C5E0EF' },
  st1: { fill: '#D1D1D1' },
  st2: { fill: '#FFE49B' },
  st3: { fill: '#69D998' },
  st4: { fill: '#FFB8D5' },
});

export default styles;
