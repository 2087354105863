import React, { useContext } from 'react';
import * as Styles from './SubscriptionControls.styles';
import { SubscriptionControlsProps } from './SubscriptionControls.types';
import { ThemeContext } from 'styled-components';
import { Button } from 'gatorcomponents';

const SubscriptionControls = ({
  activeSubscriptionRenewalButtonLabel = 'Activate subscription renewal',
  cancelSubscriptionButtonLabel = 'Cancel subscription',
  cancelSubscriptionImmediatelyButtonLabel = 'Cancel immediately',
  loading = true,
  onActiveSubscriptionRenewalClick,
  onCancelSubscriptionClick,
  onCancelSubscriptionImmediatelyClick,
  onSwitchPaymentMethodClick,
  showActiveSubscriptionRenewalButton = false,
  showCancelSubscriptionButton = false,
  showCancelSubscriptionImmediatelyButton = false,
  showSwitchPaymentMethodButton = false,
  showPaymentInvoceButton = false,
  switchPaymentMethodButtonLabel = 'Change payment method',
  payInvoiceButtonLabel = 'Pay invoice',
  onDisplayModalPayment,
  invoiceId = '',
  showPlanButton = false,
  planButtonLabel = 'Change plan and/or payment cycle',
  onPlanButtonClick,
}) => {
  const theme = useContext(ThemeContext);

  const handleOnActiveSubscriptionRenewalClick = (event) => {
    onActiveSubscriptionRenewalClick && onActiveSubscriptionRenewalClick(event);
  };

  const handleOnSwitchPaymentMethodClick = (event) => {
    onSwitchPaymentMethodClick && onSwitchPaymentMethodClick(event);
  };

  const handleOnshowCancelSubscriptionButtonClick = (event) => {
    onCancelSubscriptionClick && onCancelSubscriptionClick(event);
  };

  const handleOnCancelSubscriptionImmediatelyClick = (event) => {
    onCancelSubscriptionImmediatelyClick && onCancelSubscriptionImmediatelyClick(event);
  };

  const handleOnPlanButtonClick = (event) => {
    onPlanButtonClick && onPlanButtonClick(event);
  };

  return (
    <Styles.Wrapper data-testid="subscription-controls">
      <Styles.Content>

        {showActiveSubscriptionRenewalButton && (
          <Styles.Item>
            <Button
              onClick={handleOnActiveSubscriptionRenewalClick}
              label={activeSubscriptionRenewalButtonLabel}
              type="button"
              title={activeSubscriptionRenewalButtonLabel}
              size="small"
              variant="secondary"
              disabled={loading}
              testId="subscription-controls-active-subscription-renewal-button"
            />
          </Styles.Item>
        )}

        {showPaymentInvoceButton && invoiceId && (
          <Styles.Item>
            <Button
              onClick={onDisplayModalPayment}
              label={payInvoiceButtonLabel}
              type="button"
              title={payInvoiceButtonLabel}
              size="small"
              variant="primary"
              disabled={loading}
              testId="subscription-payment-invoice-button"
              customStyles={{
                backColor: theme.v1.color.action.primary,
              }}
            />
          </Styles.Item>
        )}

        {showPlanButton && (
          <Styles.Item>
            <Button
              onClick={handleOnPlanButtonClick}
              label={planButtonLabel}
              type="button"
              title={planButtonLabel}
              size="small"
              variant="secondary"
              disabled={loading}
              testId="subscription-change-plan-cycle-button"
            />
          </Styles.Item>
        )}

        {showSwitchPaymentMethodButton && !showActiveSubscriptionRenewalButton && (
          <Styles.Item>
            <Button
              onClick={handleOnSwitchPaymentMethodClick}
              label={switchPaymentMethodButtonLabel}
              type="button"
              title={switchPaymentMethodButtonLabel}
              size="small"
              variant="secondary"
              disabled={loading}
              testId="subscription-controls-change-payment-method-button"
            />
          </Styles.Item>
        )}

        {showCancelSubscriptionButton && (
          <Styles.Item>
            <Button
              onClick={handleOnshowCancelSubscriptionButtonClick}
              label={cancelSubscriptionButtonLabel}
              type="button"
              title={cancelSubscriptionButtonLabel}
              size="small"
              variant="secondary"
              disabled={loading}
              testId="subscription-controls-cancel-subscription-button"
              customStyles={{
                backColor: 'transparent',
                borderColor: 'transparent',
                hoverBackColor: 'transparent',
                hoverBorderColor: 'transparent',
                hoverColor: theme.v1.color.action.hover,
                disabledBackColor: 'transparent',
                disabledBorderColor: 'transparent',
                padding: showSwitchPaymentMethodButton ? null : '6px 0px',
              }}
            />
          </Styles.Item>
        )}

        {showCancelSubscriptionImmediatelyButton && (
          <Styles.Item>
            <Button
              onClick={handleOnCancelSubscriptionImmediatelyClick}
              label={cancelSubscriptionImmediatelyButtonLabel}
              type="button"
              title={cancelSubscriptionImmediatelyButtonLabel}
              size="small"
              variant="secondary"
              disabled={loading}
              testId="subscription-controls-cancel-subscription-immediately-button"
              customStyles={{
                backColor: 'transparent',
                borderColor: 'transparent',
                hoverBackColor: 'transparent',
                hoverBorderColor: 'transparent',
                hoverColor: theme.v1.color.action.hover,
                disabledBackColor: 'transparent',
                disabledBorderColor: 'transparent',
              }}
            />
          </Styles.Item>
        )}

      </Styles.Content>
    </Styles.Wrapper>
  );
};

SubscriptionControls.propTypes = SubscriptionControlsProps;

export default SubscriptionControls;
