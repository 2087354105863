import { api } from '@/utils/api';
import logger from '@/utils/logger';
import { useSelector } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_LIVE_TRACK } from '@/config/GrowthBook/constants';
import store from '@/redux/store';


const useTrack = () => {
  const { token } = useSelector(state => state.auth);
  const enableTrack = useFeatureIsOn(CHARLIE_LIVE_TRACK);

  const sendAnalytics = (payload) => {
    if (!enableTrack) return;
    const request = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: '/api/v2/portal/track',
      data: {
        ...payload,
      },
    };

    try {
      api.whmcs.post(request);
    } catch (e) {
      logger.error('Error sendAnalytics', e);
    }
  };

  return { sendAnalytics };
};

const collectTrackData = (payload) => {
  const { auth } = store.getState();
  const { token } = auth;

  const request = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: '/api/v2/portal/track',
    data: {
      ...payload,
    },
  };

  try {
    api.whmcs.post(request);
  } catch (e) {
    logger.error('Error collectTrackData', e);
  }
};

export { useTrack, collectTrackData };
