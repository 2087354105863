import React from 'react';

const IconCardVisa = ({ className }) => (
  <svg className={className} id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 22.93">
    <g id="Grupo_1610" data-name="Grupo 1610">
      <rect id="Retângulo_1748" data-name="Retângulo 1748" width="36" height="22.93" rx="2" fill="#0e4595" />
      <path id="Caminho_2521" data-name="Caminho 2521" d="M13.39,16.27,15,6.74h2.56L16,16.27ZM25.17,7a6.42,6.42,0,0,0-2.29-.4c-2.52,0-4.3,1.29-4.32,3.14,0,1.37,1.27,2.13,2.24,2.59s1.33.76,1.32,1.18c0,.64-.79.93-1.52.93a5.11,5.11,0,0,1-2.4-.5l-.33-.15-.36,2.13a7.67,7.67,0,0,0,2.84.51c2.69,0,4.43-1.28,4.45-3.25,0-1.09-.67-1.91-2.15-2.59-.89-.44-1.44-.74-1.43-1.18s.46-.82,1.46-.82a4.61,4.61,0,0,1,1.91.36l.23.11L25.17,7m6.56-.21h-2a1.26,1.26,0,0,0-1.33.79l-3.79,8.73h2.68l.53-1.43h3.27c.08.33.31,1.43.31,1.43H33.8ZM28.6,12.89c.22-.55,1-2.66,1-2.66L30,9.32l.17.82s.49,2.27.59,2.75ZM11.23,6.74l-2.5,6.5-.27-1.32a7.38,7.38,0,0,0-3.53-4l2.28,8.33h2.7l4-9.51h-2.7" fill="#fff" />
      <path id="Caminho_2522" data-name="Caminho 2522" d="M6.42,6.74H2.23l0,.2a8.6,8.6,0,0,1,6.31,5L7.6,7.55a1.07,1.07,0,0,0-1.18-.8" fill="#f2ae14" />
    </g>
  </svg>
);

export default IconCardVisa;
