import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { MktPlaceContext } from '@/contexts/MktPlace/context';
import { Filter } from '@/pages/common/Filter';
import * as Style from './FilterSection.styles';

const FilterSection = ({ t }) => {
  const {
    filteredOptions, setFilteredOptions, clearFilter, filterTyped, setFilterTyped,
  } = useContext(MktPlaceContext);
  return (
    <Style.Wrapper data-testid="filterSectionWrapper">
      <Style.FirstRow data-testid="filterSectionFirstRow">
        <Style.FilterLabel data-testid="filterSectionLabel">{t('mktPlace.filterLabel')}</Style.FilterLabel>
      </Style.FirstRow>
      <Style.SecondRow data-testid="filterSectionSecondRow">
        <Filter
          filterLabel={t('mktPlace.filterBy')}
          searchPlaceholder={t('dns.zoneManager.recordList.searchField')}
          filteredOptions={filteredOptions}
          setFilteredOptions={setFilteredOptions}
          filterTyped={filterTyped}
          setFilterTyped={setFilterTyped}
          clearFilter={clearFilter}
          clearLabel={t('mktPlace.cleanFilters')}
        />
      </Style.SecondRow>
    </Style.Wrapper>
  );
};
export default withI18n()((FilterSection));
