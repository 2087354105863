import isValidJsonString from '@/utils/Validators/isValidJsonString';

export const handleLastPath = (location, userId) => {
  const content = {
    id: userId,
    location: location.pathname,
  };
  const lastPath = localStorage.getItem('lastPath');
  const lastPaths = isValidJsonString(lastPath) ? JSON.parse(lastPath) : [];
  const pathFilter = lastPaths ? lastPaths.filter(item => item.id !== userId) : [];
  pathFilter.push(content);
  localStorage.setItem('lastPath', JSON.stringify(pathFilter));
};
