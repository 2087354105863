import { REQUEST_FEATURETOGGLES, RECEIVE_FEATURETOGGLES, ERROR_FEATURETOGGLES } from '@/redux/actions/actionsTypes';


const initialState = {
  toggles: null,
  loading: false,
};

export default function featureToggles(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FEATURETOGGLES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FEATURETOGGLES:
      return {
        ...state,
        toggles: action.featureToggles,
        loading: false,
      };
    case ERROR_FEATURETOGGLES:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
