const styles = theme => ({
  button: {
    textDecoration: 'none',
  },
  outline: {
    color: theme.color.indigo,
  },
});

export default styles;
