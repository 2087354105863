
const receivePlatforms = (rawData) => {
  const {
    domain, registrar, planDetails, configurationStatus, hostingPlanStatus, availablePlatforms, nameServers, platformName,
  } = rawData.data;

  return {
    domain,
    registrar,
    planDetails,
    nameServers,
    hostingPlanStatus,
    configurationStatus,
    availablePlatforms,
    platformName,
  };
};

const receiveEmailPlatforms = (rawData) => {
  const { availableEmailPlatforms, configurationStatus, proEmail } = rawData.data;

  return { availableEmailPlatforms, configurationStatus, proEmail };
};
const receiveConfiguration = rawData => ({
  ...rawData.data,
});

const receiveDnsZones = (rawData) => {
  const {
    records,
  } = rawData.data;

  return {
    records,
  };
};

const trimNameServers = (rawData) => {
  const nameServers = [];
  Object.keys(rawData).forEach((key) => {
    if (key !== 'platformName' && key !== 'platformId') {
      nameServers.push(rawData[key].host);
    }
  });
  return { nameServers: nameServers.filter(elem => elem !== '') };
};

const receiveEmailPlataform = rawData => rawData.data.data.data;

const assembly = {
  receiveConfiguration,
  receiveDnsZones,
  receiveEmailPlatforms,
  receivePlatforms,
  trimNameServers,
  receiveEmailPlataform,
};

export default assembly;
