import { collect } from '@/analytics';

const NEWEVENTS = {
  NAME: 'gaEvent',
  CATEGORY: {
    STEP_1: 'Etapa 1 - Configurações iniciais',
    STEP_2: 'Etapa 2 - Sobre o site',
    STEP_3: 'Etapa 3 - Definir ferramenta de criação',
    STEP_4: 'Etapa 4 - Dicas para começar',
  },
  ACTION: {
    CONFIGURATIONS: {
      SELECTDOMAIN: 'Seleção de domínio para criação do site',
      MODALOTHERDOMAIN: 'Modal: Definição de novo domínio',
      MODALDOMAINREGISTERED: 'Modal: Domínio já registrado',
      MODALNOTDOMAINREGISTERED: 'Modal: Domínio não registrado',
      MODALSITEALREADYREGISTERED: 'Alerta site existente para o domínio - document root desabilitada',
      MODALSITEALREADYREGISTEREDENABLE: 'Alerta site existente para o domínio - document root habilitada',
      MODALUPGRADEPLAN: 'Modal: Escolha de plano para upgrade',
      MODALSELECTPLAN: 'Modal: Escolha do plano para criação do site',
      MODALDNSINPROGRESS: 'Modal: Alteração de DNS automática',
      MODALDNSMANUALFIRSTSTEP: 'Modal: Seleção de provedor para configuração de DNS',
      MODALDNSMANUALSECONDSTEP: 'Modal: Configuração de DNS para provedores externos',
      UPGRADEPLAN: 'Tela: Necessidade de upgrade/compra de plano',
      SELECTPLAN: 'Seleção de plano para criação do site',
      MOVETOSECONDSTEP: 'Avançar para etapa 2 - Sobre o site',
      CONTINUE_TO_OVERWRITE: 'Modal: Site já criado',
    },
    ABOUT: {
      SITETYPE: 'Tipo do site',
      SITEOBJECTIVE: 'Finalidade do site',
      OTHERSITETYPEMODAL: 'Modal: Outro tipo do site',
      OTHERSITEOBJECTIVEMODAL: 'Modal: Outro objetivo do site',
      MOVETOTHIRDSTEP: 'Avançar para etapa 3 - Definir ferramenta de criação',
    },
    TOOLS: {
      CHOOSETOOL: 'Escolha da ferramenta para criação do site',
      WORDPRESSINSTALL: 'Instalação do',
      WORDPRESSINSTALLMODAL: 'Modal de visualização do tema -',
    },
    TIPS: {
      TIPSTOSTART: 'Dicas para começar -',
    },
  },
};

// NEW GA - STEP CONFIGURATIONS

// ACCESS SITEBUILDER

const getToolGaName = (tool) => {
  switch (tool) {
    case 'sitebuilder':
      return 'Criador de Sites';
    case 'wordpress':
      return 'WordPress';
    case 'wordpress-woocommerce':
      return 'WordPress + Woocommerce';
    case 'softaculous':
      return 'Outras ferramentas';
    case 'wordpress-woocommerce-trial':
      return 'Oferta E-commerce Plus';
    case 'wordpress-woocommerce-plus':
      return 'E-commerce Plus';
    default: break;
  }
};

const getOfferLabel = userSawEcommercePlusOffer => (userSawEcommercePlusOffer ? ' - oferta' : '');

export const accessFirstStepSiteBuilder = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: 'Acessos a etapa 1 - Configurações iniciais',
  gaEventLabel: 'Total de acessos a etapa 1',
});

// SELECT DOMAIN

export const selectListedDomain = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.SELECTDOMAIN,
  gaEventLabel: 'Ação: Seleção de domínio já listado',
});

export const selectUnlistedDomain = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.SELECTDOMAIN,
  gaEventLabel: 'Ação: Utilizar domínio não listado',
});

// OTHER DOMAIN MODAL

export const otherDomainModalValidateDomain = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALOTHERDOMAIN,
  gaEventLabel: 'Ação: Validação de domínio',
});

export const otherDomainModalCloseModal = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALOTHERDOMAIN,
  gaEventLabel: 'Ação: Fechar modal',
});

export const otherDomainModalSelectTLD = tld => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALOTHERDOMAIN,
  gaEventLabel: `Ação: ${tld}`,
});

// REGISTERED DOMAIN MODAL

export const domainRegisteredModalContinue = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDOMAINREGISTERED,
  gaEventLabel: 'Ação: Continuar criando site',
});

export const domainRegisteredModalCloseModal = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDOMAINREGISTERED,
  gaEventLabel: 'Ação: Fechar modal',
});

// NOT REGISTERED DOMAIN MODAL

export const domainNotRegisteredModalContinue = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALNOTDOMAINREGISTERED,
  gaEventLabel: 'Ação: Continuar criando site',
});

export const domainNotRegisteredModalCloseModal = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALNOTDOMAINREGISTERED,
  gaEventLabel: 'Ação: Fechar modal',
});

export const domainNotRegisteredModalRegisterDomain = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALNOTDOMAINREGISTERED,
  gaEventLabel: 'Ação: Registrar domínio',
});

// UPGRADE PLAN MODAL

export const upgradePlanModalButton = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALUPGRADEPLAN,
  gaEventLabel: 'Ação: Upgrade do plano',
});

export const upgradePlanModalCloseModal = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALUPGRADEPLAN,
  gaEventLabel: 'Ação: Fechar modal',
});

export const upgradePlanStepBack = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.UPGRADEPLAN,
  gaEventLabel: 'Ação: Voltar',
});

// SELECT PLAN MODAL

export const selectOtherPlanModalSelectPlan = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALSELECTPLAN,
  gaEventLabel: 'Ação: Escolher plano',
});

export const selectOtherPlanModalCloseModal = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALSELECTPLAN,
  gaEventLabel: 'Ação: Fechar modal',
});

// DNS AUTOMATIC MODAL

export const DNSAutomaticModalAccessSupportMaterial = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSINPROGRESS,
  gaEventLabel: 'Ação: Material sobre processo de propagação',
});

export const DNSAutomaticModalContinue = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSINPROGRESS,
  gaEventLabel: 'Ação: Continuar criando site',
});

// MANUAL DNS MODAL STEP 1

export const DNSManualModalCloseModal = action => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALFIRSTSTEP,
  gaEventLabel: action === 'back' ? 'Ação: Voltar' : 'Ação: Fechar modal',
});

export const DNSManualModalSelectProvider = provider => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALFIRSTSTEP,
  gaEventLabel: `Ação: Provedor ${provider}`,
});

export const DNSManualModalContinue = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALFIRSTSTEP,
  gaEventLabel: 'Ação: Ver configurações para provedor escolhido',
});

// MANUAL DNS MODAL STEP 2

export const DNSManualModalSecondStepBack = action => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALSECONDSTEP,
  gaEventLabel: action === 'back' ? 'Ação: Voltar' : 'Ação: Fechar modal',
});

export const DNSManualModalSecondStepHelp = material => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALSECONDSTEP,
  gaEventLabel: `Ação: Material central de ajuda - ${material}`,
});

export const DNSManualModalSecondStepRedirect = material => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALSECONDSTEP,
  gaEventLabel: `Ação: Redirecionamento para - ${material}`,
});

export const DNSManualModalSecondStepCopy = server => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALSECONDSTEP,
  gaEventLabel: `Ação: Copiar Servidor ${server} (DNS)`,
});

export const DNSManualModalSecondStepContinue = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MODALDNSMANUALSECONDSTEP,
  gaEventLabel: 'Ação: Continuar criando site',
});

// BACK TO ANOTHER DOMAIN MODAL

export const backToAnotherDomainModal = (modal) => {
  let action = '';
  switch (modal) {
    case 'domain-registered':
      action = NEWEVENTS.ACTION.CONFIGURATIONS.MODALDOMAINREGISTERED;
      break;
    case 'domain-not-registered':
      action = NEWEVENTS.ACTION.CONFIGURATIONS.MODALNOTDOMAINREGISTERED;
      break;
    case 'upgrade-plan':
      action = NEWEVENTS.ACTION.CONFIGURATIONS.MODALUPGRADEPLAN;
      break;
    default: break;
  }
  return collect({
    event: NEWEVENTS.NAME,
    gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
    gaEventAction: action,
    gaEventLabel: 'Ação: Voltar',
  });
};

// UPGRADE PLAN

export const accessUpgradePlanPage = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.UPGRADEPLAN,
  gaEventLabel: 'Total de acessos a tela de upgrade/compra de plano',
});

export const upgradePlanButtonClick = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.UPGRADEPLAN,
  gaEventLabel: 'Ação: Upgrade de plano',
});

export const upgradePlanBuyNewPlan = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.UPGRADEPLAN,
  gaEventLabel: 'Ação: Comprar novo plano',
});

// SELECT PLAN

export const selectOtherPlan = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.SELECTPLAN,
  gaEventLabel: 'Ação: Alterar plano',
});

// WIZARD GO TO SECOND STEP

export const continueToSecondStep = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.ACTION.CONFIGURATIONS.MOVETOSECONDSTEP,
  gaEventLabel: 'Ação: Continuar',
});

// NEW GA - STEP ABOUT

// ACCESS SECOND STEP

export const accessSecondStepSiteBuilder = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: 'Acessos a etapa 2 - Sobre o site',
  gaEventLabel: 'Total de acessos a etapa 2',
});

export const moveBackFirstStepSiteBuilder = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: 'Voltar para etapa 1 - Configurações iniciais',
  gaEventLabel: '',
});

// SITE TYPE

export const chooseSiteType = type => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.SITETYPE,
  gaEventLabel: type,
});

// SITE OBJECTIVE

export const chooseSiteObjective = objective => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.SITEOBJECTIVE,
  gaEventLabel: objective,
});

// OTHER SITE TYPE MODAL

export const otherSiteTypeModalClose = action => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.OTHERSITETYPEMODAL,
  gaEventLabel: action === 'back' ? 'Ação: Voltar' : 'Ação: Fechar modal',
});

export const otherSiteTypeModalSave = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.OTHERSITETYPEMODAL,
  gaEventLabel: 'Ação: Salvar',
});

// OTHER SITE OBJECTIVE MODAL

export const otherSiteObjectiveModalClose = action => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.OTHERSITEOBJECTIVEMODAL,
  gaEventLabel: action === 'back' ? 'Ação: Voltar' : 'Ação: Fechar modal',
});

export const otherSiteObjectiveModalSave = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.OTHERSITEOBJECTIVEMODAL,
  gaEventLabel: 'Ação: Salvar',
});

// WIZARD GO TO THIRD STEP

export const continueToThirdStep = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_2,
  gaEventAction: NEWEVENTS.ACTION.ABOUT.MOVETOTHIRDSTEP,
  gaEventLabel: 'Ação: Continuar',
});

// NEW GA - STEP TOOLS

// ACCESS THIRD STEP

export const accessThirdStepSiteBuilder = (type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: 'Acessos a etapa 3 - Definir ferramenta de criação',
  gaEventLabel: 'Total de acessos a etapa 3',
});

export const moveBackSecondStepSiteBuilder = (type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: 'Voltar para etapa 2 - Sobre o site',
  gaEventLabel: '',
});

// CHOOSE TOOL

export const chooseTool = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: NEWEVENTS.ACTION.TOOLS.CHOOSETOOL,
  gaEventLabel: tool,
});

export const accessWoocommerceHelpMaterial = (type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: NEWEVENTS.ACTION.TOOLS.CHOOSETOOL,
  gaEventLabel: 'Acesso ao material sobre Woocommerce',
});

// WORDPRESS OR WORDPRESS + WOOCOMMERCE INSTALL

// ACCESS WORDPRESS INSTALL

export const accessWordPressInstall = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: `Total de acessos a instalação do ${getToolGaName(tool)}`,
});

export const backWordPressInstall = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Voltar para etapa 3 - Definir ferramenta de criação',
});

// FILL FORM INPUTS

export const fillFormInputs = (inputName, tool, type, userSawEcommercePlusOffer) => {
  let label = '';
  switch (inputName) {
    case 'name':
      label = 'Informações úteis: Preenchimento do nome do site';
      break;
    case 'description':
      label = 'Informações úteis: Preenchimento da descrição do site';
      break;
    case 'email':
      label = 'Informações úteis: Preenchimento do e-mail do WordPress';
      break;
    case 'user':
      label = 'Informações úteis: Preenchimento do usuário do WordPress';
      break;
    case 'password':
      label = 'Informações úteis: Preenchimento da senha do WordPress';
      break;
    case 'confirmPassword':
      label = 'Informações úteis: Preenchimento da confirmação da senha do WordPress';
      break;
    default: break;
  }
  return collect({
    event: NEWEVENTS.NAME,
    gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
    gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
    gaEventLabel: label,
  });
};

// ACCESS WORDPRESS INSTALL THEME

export const continueToChooseTheme = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Informações úteis: Avanço para escolha do tema',
});

export const backToWordPressConfig = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Voltar para etapa anterior - Informações úteis',
});

// WORDPRESS INSTALL THEME

export const chooseTheme = (tool, theme, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: `Escolha do tema: Usar tema ${theme}`,
});

export const visualizeTheme = (tool, theme, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: `Escolha do tema: Visualizar tema ${theme}`,
});

// WORDPRESS THEME MODAL

export const closeWordPressThemeModal = (tool, theme, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALLMODAL} ${getToolGaName(tool)}`,
  gaEventLabel: `Ação: Fechar modal do tema ${theme}`,
});

export const chooseThemeWordPressThemeModal = (tool, theme, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALLMODAL} ${getToolGaName(tool)}`,
  gaEventLabel: `Ação: Usar tema ${theme}`,
});

// ACCESS WORDPRESS INSTALL PLUGIN

export const continueToChoosePlugin = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Escolha do tema: Avanço para escolha do plugin',
});

export const backToWordPressTheme = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Voltar para etapa anterior - Escolha do tema',
});

// CHOOSE PLUGIN

export const choosePlugin = (tool, plugin, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: `Escolha do plugin: ${plugin}`,
});

// CHOOSE FINISH INSTALL

export const finishWordPressInstall = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Finalizar e instalar o WordPress',
});

// MODAL VERIFIED OVERWRITE SITE

export const showVerifiedOverwriteSiteModal = sharedDocumentRoot => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: sharedDocumentRoot ? NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTEREDENABLE : NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTERED,
  gaEventLabel: 'Ação: exibição modal',
});

export const securityCopyVerifiedOverwriteModal = sharedDocumentRoot => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: sharedDocumentRoot ? NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTEREDENABLE : NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTERED,
  gaEventLabel: 'Ação: Click conteúdo sobre backup',
});

export const continueToVerifiedOverwrite = sharedDocumentRoot => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: sharedDocumentRoot ? NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTEREDENABLE : NEWEVENTS.ACTION.CONFIGURATIONS.MODALSITEALREADYREGISTERED,
  gaEventLabel: 'Ação: Continuar criando site',
});

// MODAL OVERWRITE SITE

export const continueToOverwrite = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.CONTINUE_TO_OVERWRITE,
  gaEventLabel: 'Ação: Continuar criando site',
});

export const closeOverwrite = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.CONTINUE_TO_OVERWRITE,
  gaEventLabel: 'Ação: Fechar modal',
});

export const goBackOverwrite = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.CONTINUE_TO_OVERWRITE,
  gaEventLabel: 'Ação: Voltar',
});

export const checkboxOverwrite = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.CONTINUE_TO_OVERWRITE,
  gaEventLabel: 'Ação: Checkbox',
});

export const materialOverwrite = () => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: NEWEVENTS.CATEGORY.STEP_1,
  gaEventAction: NEWEVENTS.CONTINUE_TO_OVERWRITE,
  gaEventLabel: 'Ação: Material sobre backup',
});

// NEW GA - STEP TIPS

// ACCESS FOURTH STEP

export const accessFourthStepSiteBuilder = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: `Total de acessos a etapa 4 - ${getToolGaName(tool)}`,
});

// ACCESS TOOL BUTTON

export const accessBuilderTool = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Acessar Criador de Sites',
});

export const accessToolWordPress = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Acessar WordPress',
});

// BACK TO SITES BUTTON

export const goToSites = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Ir para tela "Sites"',
});

// ASAAS BANNER

export const asaasBannerClick = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Asaas',
});

// SHOW MORE MATERIALS

export const showMoreMaterials = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Ver mais materiais da central de ajuda',
});

// ACCESS MATERIAL

export const accessMaterial = (tool, number, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_4} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TIPS.TIPSTOSTART} ${getToolGaName(tool)}`,
  gaEventLabel: `Ação: Acessou material ${number}`,
});


export const nextToAsaasStep = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Escolha do plugin: Avanço para instalação Asaas',
});

export const nextToAsaasStepWithWondersuite = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Informações úteis: Avanço para instalação Asaas',
});

export const backFromAsaasToPluginStep = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Voltar para etapa anterior - Escolha do plugin',
});

export const accessAsaasHelpCenter = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Central de ajuda',
});

export const asaasDontWantAsaas = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Não quero criar conta agora (instalação WP sem Asaas)',
});

export const asaasFormNextToStep2 = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Asaas: avanço para etapa 2 do formulário',
});

export const asaasFormNextToStep3 = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Asaas: avanço para etapa 3 do formulário',
});

export const asaasAccountCreated = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Asaas: Conta criada',
});

export const asaasAlreadyExists = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Asaas: Conta existente',
});

export const finishAndInstallWithAsaas = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Finalizar e instalar WordPress (instalação WP com Asaas)',
});

export const remakeAsaasForm = (tool, type, userSawEcommercePlusOffer) => collect({
  event: NEWEVENTS.NAME,
  gaEventCategory: `${NEWEVENTS.CATEGORY.STEP_3} - ${type}${getOfferLabel(userSawEcommercePlusOffer)}`,
  gaEventAction: `${NEWEVENTS.ACTION.TOOLS.WORDPRESSINSTALL} ${getToolGaName(tool)}`,
  gaEventLabel: 'Ação: Refazer cadastro',
});


const newGa = {
  sitesBuilderConfigurations: {
    accessFirstStepSiteBuilder,
    selectListedDomain,
    selectUnlistedDomain,
    otherDomainModalValidateDomain,
    otherDomainModalCloseModal,
    otherDomainModalSelectTLD,
    domainRegisteredModalContinue,
    domainRegisteredModalCloseModal,
    backToAnotherDomainModal,
    domainNotRegisteredModalContinue,
    domainNotRegisteredModalCloseModal,
    domainNotRegisteredModalRegisterDomain,
    accessUpgradePlanPage,
    upgradePlanButtonClick,
    upgradePlanBuyNewPlan,
    upgradePlanStepBack,
    upgradePlanModalButton,
    upgradePlanModalCloseModal,
    selectOtherPlan,
    selectOtherPlanModalSelectPlan,
    selectOtherPlanModalCloseModal,
    continueToSecondStep,
    DNSAutomaticModalAccessSupportMaterial,
    DNSAutomaticModalContinue,
    DNSManualModalCloseModal,
    DNSManualModalSelectProvider,
    DNSManualModalContinue,
    DNSManualModalSecondStepBack,
    DNSManualModalSecondStepHelp,
    DNSManualModalSecondStepRedirect,
    DNSManualModalSecondStepCopy,
    DNSManualModalSecondStepContinue,
    continueToOverwrite,
    closeOverwrite,
    goBackOverwrite,
    checkboxOverwrite,
    materialOverwrite,
    showVerifiedOverwriteSiteModal,
    securityCopyVerifiedOverwriteModal,
    continueToVerifiedOverwrite,
  },
  sitesBuilderAbout: {
    accessSecondStepSiteBuilder,
    moveBackFirstStepSiteBuilder,
    chooseSiteType,
    otherSiteTypeModalClose,
    otherSiteTypeModalSave,
    otherSiteObjectiveModalClose,
    otherSiteObjectiveModalSave,
    chooseSiteObjective,
    continueToThirdStep,
  },
  sitesBuilderTools: {
    accessThirdStepSiteBuilder,
    moveBackSecondStepSiteBuilder,
    chooseTool,
    accessWoocommerceHelpMaterial,
    accessWordPressInstall,
    backWordPressInstall,
    fillFormInputs,
    continueToChooseTheme,
    continueToChoosePlugin,
    backToWordPressConfig,
    chooseTheme,
    visualizeTheme,
    closeWordPressThemeModal,
    chooseThemeWordPressThemeModal,
    backToWordPressTheme,
    choosePlugin,
    finishWordPressInstall,
    nextToAsaasStep,
    nextToAsaasStepWithWondersuite,
    backFromAsaasToPluginStep,
    accessAsaasHelpCenter,
    asaasDontWantAsaas,
    asaasFormNextToStep2,
    asaasFormNextToStep3,
    asaasAccountCreated,
    asaasAlreadyExists,
    finishAndInstallWithAsaas,
    remakeAsaasForm,
  },
  sitesBuilderTips: {
    accessFourthStepSiteBuilder,
    accessBuilderTool,
    accessToolWordPress,
    goToSites,
    showMoreMaterials,
    accessMaterial,
    asaasBannerClick,
  },
};

const analytics = {
  newGa,
};

export default analytics;
