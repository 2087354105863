import { config } from '@/config';
import { RECAPTCHA_V3_URL } from '@/config/externalScriptsUrl';


export const generateRecaptchaToken = async (sitekey, action) => {
  let tokenReceived = '';
  await window.grecaptcha.execute(sitekey, { action })
    .then((token) => {
      tokenReceived = token;
    });
  return tokenReceived;
};

export const loadReCaptcha = () => {
  const hasRecaptchaScript = document.getElementById('recaptcha-script');
  if (!hasRecaptchaScript) {
    const script = document.createElement('script');
    script.id = 'recaptcha-script';
    script.src = RECAPTCHA_V3_URL(config.RECAPTCHA_SITE_KEY);
    document.body.appendChild(script);
  }
};

export const loadReCaptchaPaaS = (enabledNewRecaptchaPass = false) => {
  const hasRecaptchaScript = document.getElementById('recaptcha-script-paas');
  const hasNewRecaptchaScript = document.getElementById('new-recaptcha-script-paas');

  if (!hasRecaptchaScript && !hasNewRecaptchaScript) {
    const script = document.createElement('script');
    script.id = 'recaptcha-script-paas';
    script.src = RECAPTCHA_V3_URL(config.RECAPTCHA_PAAS);
    document.body.appendChild(script);
  }
  if (!hasNewRecaptchaScript && enabledNewRecaptchaPass) {
    const script = document.createElement('script');
    script.id = 'new-recaptcha-script-paas';
    script.src = RECAPTCHA_V3_URL(config.NEW_RECAPTCHA_PAAS);
    document.body.appendChild(script);

    if (hasRecaptchaScript) {
      const element = document.getElementById('recaptcha-script-paas');
      element.remove();
    }
  }
};
