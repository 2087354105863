import React, { useContext } from 'react';
import { withStyles, Typography, Tooltip } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import { SUPPORT_ARTICLE_KNOW_MORE_DNS } from '@/config/urls/supportUrls';
import { DNSContext } from '@/contexts/DNS/context';
import DomainSetupOptions from '@/components/Dns/DomainSetupOptions';
import styles from './styles';


const DomainSetup = ({ classes, t }) => {
  const { details } = useContext(DNSContext);
  return (
    <section className={classes.section} data-testid="domainSetupSection">
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} variant="h1">
          {t('whichDnsToChange.title')}
        </Typography>

        <Tooltip
          classes={{
            tooltip: classes.customTooltip,
            popper: classes.customPopper,
          }}
          placement="top"
          title={t('whichDnsToChange.infoIconTooltip')}
        >
          <InfoIcon className={classes.info} />
        </Tooltip>
      </div>

      <Typography className={classes.explanation}>
        {t('whichDnsToChange.paragraphOne')}
      </Typography>

      <Typography className={`${classes.explanation} ${classes.knowMore}`}>
        {t('whichDnsToChange.wichDnsToChangeKnowMore')}
          &nbsp;
        <a href={SUPPORT_ARTICLE_KNOW_MORE_DNS} target="_blank" rel="noopener noreferrer" className={classes.explanationLink}>
          {t('whichDnsToChange.wichDnsToChangeKnowMore_2')}
        </a>
      </Typography>

      <DomainSetupOptions
        dnsDetails={details}
      />

    </section>
  );
};

export default withI18n()(withStyles(styles)(DomainSetup));
