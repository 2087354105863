import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { IconLight } from '@/icons';
import { Button } from '@/pages/common';
import * as Styles from './NoRefund.styles';
import { billingAnalytics } from '@/analytics';

const NoRefund = ({
  amountTitle = 'Total to be refounded',
  withoutRefund = 'This subscription is not eligible to receive a refund',
  refundPolitics = 'Read our refund politics',
  tipTitle = 'Tip:',
  tipText = 'É mais vantajoso utilizar a assinatura até o final do ciclo, em',
  tipDate = '01/10/2021',
  stopCancelLabel = 'Desistir do Cancelamento',
  testId = 'refund',
  handleStopCancel,
  onRefundPoliticsClick,
}) => {
  const theme = useContext(ThemeContext);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('4_visualizar_reembolso_cancelar_imediato');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnRefundPoliticsClick = () => {
    onRefundPoliticsClick && onRefundPoliticsClick();
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Amount>
        <Styles.AmountTitle>{amountTitle}</Styles.AmountTitle>

        <Styles.NoRefundWrapper>
          <Styles.NoRefundExplanation>{withoutRefund}</Styles.NoRefundExplanation>
          <Button variant="link" label={refundPolitics} onClick={handleOnRefundPoliticsClick} />
        </Styles.NoRefundWrapper>
      </Styles.Amount>

      <Styles.TipWrapper>
        <Styles.Tip>
          <IconLight size={22} color={theme.palette.yellow.main} />
          <Styles.TipLabel>
            {tipTitle}
          </Styles.TipLabel>
        </Styles.Tip>

        <Styles.Content>
          <Styles.TipContent>
            {`${tipText} ${tipDate}.`}
          </Styles.TipContent>

          <Button variant="yellow" label={stopCancelLabel} onClick={handleStopCancel} testId="refund-stop-cancelation" />
        </Styles.Content>
      </Styles.TipWrapper>
    </Styles.Wrapper>
  );
};

export default NoRefund;
