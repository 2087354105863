import { SecurityActionTypes } from './security.types';

const setScanLoading = isLoading => ({ type: SecurityActionTypes.SITELOCK_SCAN_DETAILS_LOADING, payload: { isLoading } });

const getScanDetails = payload => ({ type: SecurityActionTypes.SITELOCK_SCAN_DETAILS_REQUEST, payload });
const scanDetailsSuccess = () => ({ type: SecurityActionTypes.SITELOCK_SCAN_DETAILS_SUCCESS });
const scanDetailsFailure = () => ({ type: SecurityActionTypes.SITELOCK_SCAN_DETAILS_FAILURE });
const setScanDetails = payload => ({ type: SecurityActionTypes.SET_SITELOCK_SCAN_DETAILS, payload });

const updateSeal = ({
  productType,
  productId,
  formData,
  setDate,
  successMessage,
  failureMessage,
}) => ({
  type: SecurityActionTypes.UPDATE_SEAL,
  payload: {
    formData,
    productType,
    productId,
    setDate,
    successMessage,
    failureMessage,
  },
});

const setLoadingStatus = bool => ({ type: SecurityActionTypes.SET_SEAL_LOADING_STATUS, payload: { bool } });
const setSavingStatus = bool => ({ type: SecurityActionTypes.SET_SEAL_SAVING_STATUS, payload: { bool } });
const setSuccess = () => ({ type: SecurityActionTypes.SUCCESS_UPDATE_SEAL });
const setFailure = () => ({ type: SecurityActionTypes.FAILURE_UPDATE_SEAL });
const requestSeal = ({ productType, productId }) => ({ type: SecurityActionTypes.REQUEST_SEAL, payload: { productType, productId } });
const setSealSuccessRequest = () => ({ type: SecurityActionTypes.SUCCESS_REQUEST_SEAL });
const setSealFailureRequest = () => ({ type: SecurityActionTypes.FAILURE_REQUEST_SEAL });
const setSeal = seal => ({ type: SecurityActionTypes.SET_SEAL, payload: seal });

const setScanListLoading = isLoading => ({ type: SecurityActionTypes.SITELOCK_SCAN_LIST_LOADING, payload: { isLoading } });
const getScanList = payload => ({ type: SecurityActionTypes.SITELOCK_SCAN_LIST_REQUEST, payload });
const scanListSuccess = () => ({ type: SecurityActionTypes.SITELOCK_SCAN_LIST_SUCCESS });
const scanListsFailure = () => ({ type: SecurityActionTypes.SITELOCK_SCAN_LIST_FAILURE });
const setScanList = payload => ({ type: SecurityActionTypes.SET_SITELOCK_SCAN_LIST, payload });

const setUpgradeSitelockLoading = isLoading => ({ type: SecurityActionTypes.SET_UPGRADE_SITELOCK_LOADING, payload: { isLoading } });
const requestUpgradeSitelock = payload => ({ type: SecurityActionTypes.REQUEST_UPGRADE_SITELOCK, payload });
const successUpgradeSitelock = () => ({ type: SecurityActionTypes.SUCCESS_UPGRADE_SITELOCK });
const failureUpgradeSitelock = () => ({ type: SecurityActionTypes.FAILURE_UPGRADE_SITELOCK });
const setUpgradeSitelock = payload => ({ type: SecurityActionTypes.SET_UPGRADE_SITELOCK, payload });

const requestUpdateConfigurations = ({
  productType, productId, data, successMessage, failureMessage,
}) => ({
  type: SecurityActionTypes.REQUEST_UPDATE_CONFIGURATIONS,
  payload: {
    productType, productId, data, successMessage, failureMessage,
  },
});

const loadingUpdateConfigurations = isLoading => ({ type: SecurityActionTypes.UPDATE_CONFIGURATIONS_LOADING, payload: { isLoading } });
const successUpdateConfigurations = () => ({ type: SecurityActionTypes.SUCCESS_UPDATE_CONFIGURATIONS });
const failureUpdateConfigurations = () => ({ type: SecurityActionTypes.FAILURE_UPDATE_CONFIGURATIONS });

const requestGetConfigurations = ({ productType, productId }) => ({ type: SecurityActionTypes.REQUEST_GET_CONFIGURATIONS, payload: { productType, productId } });
const successGetConfigurations = () => ({ type: SecurityActionTypes.SUCCESS_GET_CONFIGURATIONS });
const failureGetConfigurations = () => ({ type: SecurityActionTypes.FAILURE_GET_CONFIGURATIONS });
const setGetConfigurations = payload => ({ type: SecurityActionTypes.SET_SITELOCK_CONFIGURATIONS, payload: { data: payload } });
const loadingGetConfigurations = isLoading => ({ type: SecurityActionTypes.GET_CONFIGURATIONS_LOADING, payload: { isLoading } });
const requestSitelockSso = ({ hostingId }) => ({ type: SecurityActionTypes.REQUEST_SITELOCK_SSO, payload: { hostingId } });
const successSitelockSso = () => ({ type: SecurityActionTypes.SITELOCK_SSO_SUCCESS });
const failureSitelockSso = () => ({ type: SecurityActionTypes.SITELOCK_SSO_FAILURE });
const setSitelockSsoLoading = isLoading => ({ type: SecurityActionTypes.SET_SITELOCK_SSO_LOADING, payload: { isLoading } });

const requestCheckNewSitelock = () => ({ type: SecurityActionTypes.REQUEST_CHECK_NEW_SITELOCK });
const successCheckNewSitelock = () => ({ type: SecurityActionTypes.SUCCESS_CHECK_NEW_SITELOCK });
const failureCheckNewSitelock = () => ({ type: SecurityActionTypes.FAILURE_CHECK_NEW_SITELOCK });
const setCheckNewSitelock = payload => ({ type: SecurityActionTypes.SET_CHECK_NEW_SITELOCK, payload });

const requestSawNewSitelock = () => ({ type: SecurityActionTypes.REQUEST_SAW_NEW_SITELOCK });
const successSawNewSitelock = () => ({ type: SecurityActionTypes.SUCCESS_SAW_NEW_SITELOCK });
const failureSawNewSitelock = () => ({ type: SecurityActionTypes.FAILURE_SAW_NEW_SITELOCK });

const actions = {
  scanDetails: {
    setLoading: setScanLoading,
    request: getScanDetails,
    success: scanDetailsSuccess,
    failure: scanDetailsFailure,
    set: setScanDetails,
  },
  seal: {
    setLoadingStatus,
    setSavingStatus,
    requestSeal,
    setSealSuccessRequest,
    setSealFailureRequest,
    updateSeal,
    setSuccess,
    setFailure,
    setSeal,
  },
  scanList: {
    setLoading: setScanListLoading,
    request: getScanList,
    success: scanListSuccess,
    failure: scanListsFailure,
    set: setScanList,
  },
  upgrade: {
    setLoading: setUpgradeSitelockLoading,
    request: requestUpgradeSitelock,
    success: successUpgradeSitelock,
    failure: failureUpgradeSitelock,
    set: setUpgradeSitelock,
  },
  configurations: {
    update: {
      setLoading: loadingUpdateConfigurations,
      request: requestUpdateConfigurations,
      success: successUpdateConfigurations,
      failure: failureUpdateConfigurations,
    },
    get: {
      setLoading: loadingGetConfigurations,
      request: requestGetConfigurations,
      success: successGetConfigurations,
      failure: failureGetConfigurations,
      set: setGetConfigurations,
    },
  },
  sso: {
    request: requestSitelockSso,
    success: successSitelockSso,
    failure: failureSitelockSso,
    setLoading: setSitelockSsoLoading,
  },
  newSiteLock: {
    check: {
      request: requestCheckNewSitelock,
      success: successCheckNewSitelock,
      failure: failureCheckNewSitelock,
      set: setCheckNewSitelock,
    },
    saw: {
      request: requestSawNewSitelock,
      success: successSawNewSitelock,
      failure: failureSawNewSitelock,
    },
  },
};

export default actions;
