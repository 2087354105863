import styled, { css } from 'styled-components';

export const SelectChipWrapper = styled.div`
  cursor: pointer;
`;

export const SelectChipLabel = styled.label`
  ${({
    align,
    justify,
    theme,
    variant,
  }) => {
    const variants = {
      default: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.v1.color.action.primary}`,
        hoverBackgroundColor: theme.v1.color.action.hover,
        hoverFontColor: theme.v1.color.white.primary,
        hoverBorder: `1px solid ${theme.v1.color.action.hover}`,
      },
    };

    return css`
      align-items: ${align};
      background-color: ${variants[variant].backgroundColor};
      border-radius: 16px;
      border: ${variants[variant].border};
      cursor: pointer;
      display: flex;
      justify-content: ${justify};
      padding: 6px 24px;
      transition: background-color 150ms ease-in-out, border 150ms ease-out;

      &:hover{
        background-color: ${variants[variant].hoverBackgroundColor};
        border: ${variants[variant].hoverBorder};
        span{
          color: ${variants[variant].hoverFontColor};
        }
      }
    `;
  }}
`;

export const SelectInput = styled.input`
  ${({
    theme,
    variant,
  }) => {
    const variants = {
      default: {
        checkedBackgroundColor: theme.v1.color.warning.success,
        checkedBorder: `1px solid ${theme.v1.color.warning.success}`,
        fontColor: theme.v1.color.white.primary,
        hoverFontColor: theme.v1.color.white.primary,
        hoverBackgroundColor: theme.v1.color.action.hover,
        disabledBorder: `1px solid ${theme.v1.color.action.disabled}`,
        disabledColor: theme.v1.color.action.disabled,
        disabledBackgroundColor: theme.v1.color.action.disabled,
      },
    };
    return css`
    display: none;

    &:checked + label {
      background-color: ${variants[variant].checkedBackgroundColor};
      border: ${variants[variant].checkedBorder};
      span {
        color: ${variants[variant].fontColor};
      }
    }
    &:disabled + label {
      background-color: transparent;
      border: ${variants[variant].disabledBorder};
      span {
        color: ${variants[variant].disabledColor};
      }
    }
    `;
  }}
`;

export const Title = styled.span`
  ${({
    theme,
    variant,
    truncate,
    truncateMaxWidth,
  }) => {
    const variants = {
      default: {
        color: theme.v1.color.action.primary,
        weight: theme.v1.font.weights.medium,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0px',
      },
    };
    return css`
      color: ${variants[variant].color};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${variants[variant].fontSize};
      font-weight: ${variants[variant].weight};
      letter-spacing: ${variants[variant].letterSpacing};
      line-height: ${variants[variant].lineHeight};
      max-width:${truncate && truncateMaxWidth};
      overflow: ${truncate && 'hidden'};
      text-overflow: ${truncate && 'ellipsis'};
      white-space: ${truncate && 'nowrap'};
    `;
  }}
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span``;
