import React from 'react';
import * as Style from './IconDetailedSiteBuilder.style';

const IconDetailedSiteBuilder = ({
  color = '#4a4a4a',
  backgroundColor = '#f47924',
  size = '50',
  testId = 'icon-detailed-sitebuilder',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg id="icon-criador" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 50 50.016" fill={color}>
      <g id="Group_6" data-name="Group 6" transform="translate(0)">
        <rect id="Rectangle-3" width="35.185" height="35.185" transform="translate(14.815 14.82)" fill={backgroundColor} />
        <rect id="Retângulo_13327" data-name="Retângulo 13327" width="19.672" height="19.672" fill="none" />
      </g>
      <path id="Caminho_238742" data-name="Caminho 238742" d="M472.845,149.822l-10.38-14.894-.073.141v-.141h-32.52v29.787h0l13.466,20.229,5.179-.016-12.907-20.213h26.782V142.861l4.864,6.961Zm-15.1,10.638h-23.23V139.183h23.23Z" transform="translate(-429.871 -134.928)" />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedSiteBuilder;
