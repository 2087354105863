import React, { useMemo } from 'react';
import * as Styles from './AlertsBell.styles';
import { IconBell } from 'gatorcomponents';
import { useSelector } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_TICKETS_STATUS_V2 } from '@/config/GrowthBook/constants';

const AlertsBell = () => {
  const { diagnosticAlerts, ticketsAlerts } = useSelector(state => state.alertsV2);
  const diagnostic = useSelector(state => state.preChat);
  const renderAllTickets = useFeatureIsOn(CHARLIE_TICKETS_STATUS_V2);

  const tos = useMemo(() => (diagnostic.tos && diagnostic.tos.tickets) || [], [diagnostic.tos]);
  const filteredTicketsAlerts = useMemo(() => (renderAllTickets ? ticketsAlerts.filter(ticket => !tos.some(singleTos => singleTos.id === ticket.id)) : []), [ticketsAlerts, tos, renderAllTickets]);
  const alerts = [...diagnosticAlerts, ...filteredTicketsAlerts];
  const unreadedAlertsAmount = alerts.filter(alert => alert.isNew).length;

  return (
    <Styles.Wrapper data-testid="menu">
      {!!unreadedAlertsAmount && (
        <Styles.NotificationsCircle data-testid="notifications-circle">
          {unreadedAlertsAmount}
        </Styles.NotificationsCircle>
      )}
      <IconBell />
    </Styles.Wrapper>
  );
};

export default AlertsBell;
