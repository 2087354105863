import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFaqDomainList } from '@/redux/actions/faq';
import { SUPPORT_DOMAINS_URL } from '@/config/urls/domainsUrls';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';


const FaqDomains = ({ className }) => {
  const faq = useSelector(state => state.faq);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFaqDomainList());
  }, [dispatch]);

  return (
    <FaqDefault
      questions={faq.domainList.questions}
      link={SUPPORT_DOMAINS_URL}
      className={className}
      loading={faq.loading}
    />
  );
};

export default FaqDomains;
