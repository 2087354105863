export const SitesActionTypes = {
  // LOADING
  SET_LOADING: 'SET_LOADING',
  SET_LOADING_EXCLUSION: 'SET_LOADING_EXCLUSION',
  SET_LOADING_TLDS: 'SET_LOADING_TLDS',
  SET_LOADING_DOMAINS: 'SET_LOADING_DOMAINS',
  SET_LOADING_PLANS: 'SET_LOADING_PLANS',
  SET_LOADING_SITES: 'SET_LOADING_SITES',
  SET_LOADING_SSL_VERIFICATION: 'SET_LOADING_SSL_VERIFICATION',
  SET_LOADING_DOMAIN_VALIDATION: 'SET_LOADING_DOMAIN_VALIDATION',
  SET_LOADING_REDIRECT: 'SET_LOADING_REDIRECT',

  // SITES
  REQUEST_GET_SITES: 'REQUEST_GET_SITES',
  GET_SITES_SUCCESS: 'GET_SITES_SUCCESS',
  GET_SITES_FAILURE: 'GET_SITES_FAILURE',
  SET_SITES: 'SET_SITES',
  DELETE_SITE: 'DELETE_SITE',
  REDIRECT_TO_EDIT: 'REDIRECT_TO_EDIT',

  // TLDS
  REQUEST_GET_TLDS: 'REQUEST_GET_TLDS',
  GET_TLDS_FAILURE: 'GET_TLDS_FAILURE',
  GET_TLDS_SUCCESS: 'GET_TLDS_SUCCESS',
  SET_TLDS: 'SET_TLDS',

  // DOMAIN AVAILABILITY
  REQUEST_GET_DOMAIN_AVAILABILITY: 'REQUEST_GET_DOMAIN_AVAILABILITY',
  GET_DOMAIN_AVAILABILITY_FAILURE: 'GET_DOMAIN_AVAILABILITY_FAILURE',
  GET_DOMAIN_AVAILABILITY_SUCCESS: 'GET_DOMAIN_AVAILABILITY_SUCCESS',
  SET_DOMAIN_AVAILABILITY: 'SET_DOMAIN_AVAILABILITY',

  // DOMAINS
  REQUEST_GET_DOMAINS: 'REQUEST_GET_DOMAINS',
  SET_SITES_DOMAINS: 'SET_SITES_DOMAINS',
  GET_DOMAINS_SUCCESS: 'GET_DOMAINS_SUCCESS',
  GET_DOMAINS_FAILURE: 'GET_DOMAINS_FAILURE',

  // PLANS
  REQUEST_GET_PLANS: 'REQUEST_GET_PLANS',
  SET_PLANS: 'SET_PLANS',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_FAILURE: 'GET_PLANS_FAILURE',

  // WARNINGS
  SET_WARNINGS_SUCCESS: 'SET_WARNINGS_SUCCESS',
  SET_WARNINGS_FAILURE: 'SET_WARNINGS_FAILURE',
  SET_WARNING_SEEN: 'SET_WARNING_SEEN',

  // SUBDOMAIN
  REQUEST_ADITIONAL_DOMAIN_TO_PLAN: 'REQUEST_ADITIONAL_DOMAIN_TO_PLAN',
  SUCCEED_ADITIONAL_DOMAIN_TO_PLAN: 'SUCCEED_ADITIONAL_DOMAIN_TO_PLAN',
  FAIL_ADITIONAL_DOMAIN_TO_PLAN: 'FAIL_ADITIONAL_DOMAIN_TO_PLAN',

  // CHANGE DOMAIN DNS
  REQUEST_CHANGE_DOMAIN_DNS: 'REQUEST_CHANGE_DOMAIN_DNS',
  CHANGE_DOMAIN_DNS_SUCCESS: 'CHANGE_DOMAIN_DNS_SUCCESS',
  CHANGE_DOMAIN_DNS_FAILURE: 'CHANGE_DOMAIN_DNS_FAILURE',

  // GET DOMAIN DNS
  REQUEST_DOMAIN_DNS: 'REQUEST_DOMAIN_DNS',
  GET_DOMAIN_DNS_SUCCESS: 'GET_DOMAIN_DNS_SUCCESS',
  GET_DOMAIN_DNS_FAILURE: 'GET_DOMAIN_DNS_FAILURE',
  SET_DOMAIN_DNS: 'SET_DOMAIN_DNS',

  // GET PLAN DNS
  REQUEST_PLAN_DNS: 'REQUEST_PLAN_DNS',
  GET_PLAN_DNS_SUCCESS: 'GET_PLAN_DNS_SUCCESS',
  GET_PLAN_DNS_FAILURE: 'GET_PLAN_DNS_FAILURE',
  SET_PLAN_DNS: 'SET_PLAN_DNS',

  // SITEBUIDER ACTIVE
  SET_SITE_BUILDER_ACTIVE: 'SET_SITE_BUILDER_ACTIVE',

  // WORDPRESS INSTALLATION LOADING
  REQUEST_WORDPRESS_INSTALLATION: 'REQUEST_WORDPRESS_INSTALLATION',
  SET_WORDPRESS_INSTALLATION_SUCCESS: 'SET_WORDPRESS_INSTALLATION_SUCCESS',
  SET_WORDPRESS_INSTALLATION_FAILURE: 'SET_WORDPRESS_INSTALLATION_FAILURE',
  SET_WORDPRESS_INSTALLATION_LOADING: 'SET_WORDPRESS_INSTALLATION_LOADING',
  SET_WORDPRESS_INSTALLATION_STATUS: 'SET_WORDPRESS_INSTALLATION_STATUS',
  SET_WORDPRESS_INSTALLATION_DEFAULT: 'SET_WORDPRESS_INSTALLATION_DEFAULT',

  // SURVEY ANSWERS
  REQUEST_SURVEY_QUESTIONS_ANSWERED: 'REQUEST_SURVEY_QUESTIONS_ANSWERED',
  GET_SURVEY_QUESTIONS_ANSWERED_SUCCESS: 'GET_SURVEY_QUESTIONS_ANSWERED_SUCCESS',
  GET_SURVEY_QUESTIONS_ANSWERED_FAILURE: 'GET_SURVEY_QUESTIONS_ANSWERED_FAILURE',
  SET_SURVEY_QUESTIONS_ANSWERED: 'SET_SURVEY_QUESTIONS_ANSWERED',

  // FORCE
  FORCE_RELOAD: 'FORCE_RELOAD',

  // ADDON CHECK
  REQUEST_ADDON_CHECK: 'REQUEST_ADDON_CHECK',
  GET_ADDON_CHECK_SUCCESS: 'GET_ADDON_CHECK_SUCCESS',
  GET_ADDON_CHECK_FAILURE: 'GET_ADDON_CHECK_FAILURE',
  SET_ADDON_CHECK_DEFAULT: 'SET_ADDON_CHECK_DEFAULT',

  // VERIFY IF SITE EXIST
  REQUEST_VERIFY_SITE_EXIST: 'REQUEST_VERIFY_SITE_EXIST',
  GET_VERIFY_SITE_EXIST_SUCCESS: 'GET_VERIFY_SITE_EXIST_SUCCESS',
  GET_VERIFY_SITE_EXIST_RESET: 'GET_VERIFY_SITE_EXIST_RESET',
  GET_VERIFY_SITE_EXIST_LOADING: 'GET_VERIFY_SITE_EXIST_LOADING',
  GET_VERIFY_SITE_EXIST_FAILURE: 'GET_VERIFY_SITE_EXIST_FAILURE',
  GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE: 'GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE',
  GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_SUCCESS: 'GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_SUCCESS',
  GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_FAILURE: 'GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_FAILURE',

  // UNINSTALL SITE
  REQUEST_UNINSTALL_SITE: 'REQUEST_UNINSTALL_SITE',
  UNINSTALL_SITE_SUCCESS: 'UNINSTALL_SITE_SUCCESS',
  UNINSTALL_SITE_LOADING: 'UNINSTALL_SITE_LOADING',
  UNINSTALL_SITE_FAILURE: 'UNINSTALL_SITE_FAILURE',

  // CHECK DOMAIN DNS STATUS
  REQUEST_DOMAIN_DNS_STATUS_CHECK: 'REQUEST_DOMAIN_DNS_STATUS_CHECK',
  GET_DOMAIN_DNS_STATUS_CHECK_SUCCESS: 'GET_DOMAIN_DNS_STATUS_CHECK_SUCCESS',
  GET_DOMAIN_DNS_STATUS_CHECK_FAILURE: 'GET_DOMAIN_DNS_STATUS_CHECK_FAILURE',

  // CHECK ALL DOMAINS DNS STATUS
  REQUEST_ALL_DOMAINS_DNS_STATUS_CHECK: 'REQUEST_ALL_DOMAINS_DNS_STATUS_CHECK',
  GET_ALL_DOMAINS_DNS_STATUS_CHECK_SUCCESS: 'GET_ALL_DOMAINS_DNS_STATUS_CHECK_SUCCESS',
  GET_ALL_DOMAINS_DNS_STATUS_CHECK_FAILURE: 'GET_ALL_DOMAINS_DNS_STATUS_CHECK_FAILURE',
  GET_ALL_DOMAINS_DNS_STATUS_CHECK_LOADING: 'GET_ALL_DOMAINS_DNS_STATUS_CHECK_LOADING',

  SITES_FROM_PRECHAT: 'SITES_FROM_PRECHAT',

  // SSL
  CLEAR_SSL_STATUS: 'CLEAR_SSL_STATUS',

  AUTO_SSL_START_REQUEST: 'AUTO_SSL_START_REQUEST',
  AUTO_SSL_START_SUCCESS: 'AUTO_SSL_START_SUCCESS',
  AUTO_SSL_START_FAILURE: 'AUTO_SSL_START_FAILURE',

  AUTO_SSL_PROGRESS_REQUEST: 'AUTO_SSL_PROGRESS_REQUEST',
  AUTO_SSL_PROGRESS_LOOP: 'AUTO_SSL_PROGRESS_LOOP',
  AUTO_SSL_PROGRESS_SUCCESS: 'AUTO_SSL_PROGRESS_SUCCESS',
  AUTO_SSL_PROGRESS_FAILURE: 'AUTO_SSL_PROGRESS_FAILURE',

  AUTO_SSL_CHECK_REQUEST: 'AUTO_SSL_CHECK_REQUEST',
  AUTO_SSL_CHECK_SUCCESS: 'AUTO_SSL_CHECK_SUCCESS',
  AUTO_SSL_CHECK_FAILURE: 'DOMAIN_AUTO_SSL_CHECK_FAILURE',

  SSL_VERIFICATION_REQUEST: 'SSL_VERIFICATION_REQUEST',
  SET_SSL_VERIFICATION: 'SET_SSL_VERIFICATION',

  REQUEST_POST_DOMAIN_TO_HG: 'REQUEST_POST_DOMAIN_TO_HG',
  POST_DOMAIN_TO_HG_SUCCESS: 'POST_DOMAIN_TO_HG_SUCCESS',
  POST_DOMAIN_TO_HG_FAILURE: 'POST_DOMAIN_TO_HG_FAILURE',
  POST_DOMAIN_TO_HG_LOADING: 'POST_DOMAIN_TO_HG_LOADING',

  POST_STEP_WELCOME_CHECKLIST_REQUEST: 'POST_STEP_WELCOME_CHECKLIST_REQUEST',
  POST_STEP_WELCOME_CHECKLIST_SUCCESS: 'POST_STEP_WELCOME_CHECKLIST_SUCCESS',
  POST_STEP_WELCOME_CHECKLIST_FAILURE: 'POST_STEP_WELCOME_CHECKLIST_FAILURE',

  GET_STEP_WELCOME_CHECKLIST_REQUEST: 'GET_STEP_WELCOME_CHECKLIST_REQUEST',
  GET_STEP_WELCOME_CHECKLIST_SUCCESS: 'GET_STEP_WELCOME_CHECKLIST_SUCCESS',
  GET_STEP_WELCOME_CHECKLIST_FAILURE: 'GET_STEP_WELCOME_CHECKLIST_FAILURE',
  SET_STEP_WELCOME_CHECKLIST: 'SET_STEP_WELCOME_CHECKLIST',

  RD_CREATE_LEAD_REQUEST: 'RD_CREATE_LEAD_REQUEST',
};
