const styles = () => ({
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
  },
  faq: {
    padding: '0px 0 17px 0',
  },
});
export default styles;
