export const LinkNaBioActionTypes = {
  LINK_NA_BIO_AVAILABILITY_REQUEST: 'LINK_NA_BIO_AVAILABILITY_REQUEST',
  LINK_NA_BIO_AVAILABILITY_SUCCESS: 'LINK_NA_BIO_AVAILABILITY_SUCCESS',
  LINK_NA_BIO_AVAILABILITY_FAILURE: 'LINK_NA_BIO_AVAILABILITY_FAILURE',
  LINK_NA_BIO_AVAILABILITY_SET: 'LINK_NA_BIO_AVAILABILITY_SET',

  CREATE_LINK_NA_BIO_REQUEST: 'CREATE_LINK_NA_BIO_REQUEST',
  CREATE_LINK_NA_BIO_SUCCESS: 'CREATE_LINK_NA_BIO_SUCCESS',
  CREATE_LINK_NA_BIO_FAILURE: 'CREATE_LINK_NA_BIO_FAILURE',

  GET_LINK_NA_BIO_REQUEST: 'GET_LINK_NA_BIO_REQUEST',
  GET_LINK_NA_BIO_SUCCESS: 'GET_LINK_NA_BIO_SUCCESS',
  GET_LINK_NA_BIO_FAILURE: 'GET_LINK_NA_BIO_FAILURE',

  UPDATE_LINK_NA_BIO_REQUEST: 'UPDATE_LINK_NA_BIO_REQUEST',
  UPDATE_LINK_NA_BIO_SUCCESS: 'UPDATE_LINK_NA_BIO_SUCCESS',
  UPDATE_LINK_NA_BIO_FAILURE: 'UPDATE_LINK_NA_BIO_FAILURE',

  DELETE_LINK_NA_BIO_REQUEST: 'DELETE_LINK_NA_BIO_REQUEST',
  DELETE_LINK_NA_BIO_SUCCESS: 'DELETE_LINK_NA_BIO_SUCCESS',
  DELETE_LINK_NA_BIO_FAILURE: 'DELETE_LINK_NA_BIO_FAILURE',

  ADD_LINKS_REQUEST: 'ADD_LINKS_TO_LINK_NA_BIO_REQUEST',
  ADD_LINKS_SUCCESS: 'ADD_LINKS_TO_LINK_NA_BIO_SUCCESS',
  ADD_LINKS_FAILURE: 'ADD_LINKS_TO_LINK_NA_BIO_FAILURE',

  SORT_LINKS_REQUEST: 'SORT_LINKS_TO_LINK_NA_BIO_REQUEST',
  SORT_LINKS_SUCCESS: 'SORT_LINKS_TO_LINK_NA_BIO_SUCCESS',
  SORT_LINKS_FAILURE: 'SORT_LINKS_TO_LINK_NA_BIO_FAILURE',

  UPDATE_LINK_REQUEST: 'UPDATE_LINK_TO_LINK_NA_BIO_REQUEST',
  UPDATE_LINK_SUCCESS: 'UPDATE_LINK_TO_LINK_NA_BIO_SUCCESS',
  UPDATE_LINK_FAILURE: 'UPDATE_LINK_TO_LINK_NA_BIO_FAILURE',

  DELETE_LINK_REQUEST: 'DELETE_LINK_TO_LINK_NA_BIO_REQUEST',
  DELETE_LINK_SUCCESS: 'DELETE_LINK_TO_LINK_NA_BIO_SUCCESS',
  DELETE_LINK_FAILURE: 'DELETE_LINK_TO_LINK_NA_BIO_FAILURE',

  ADD_SOCIAL_MEDIA_REQUEST: 'ADD_SOCIAL_MEDIA_TO_LINK_NA_BIO_REQUEST',
  ADD_SOCIAL_MEDIA_SUCCESS: 'ADD_SOCIAL_MEDIA_TO_LINK_NA_BIO_SUCCESS',
  ADD_SOCIAL_MEDIA_FAILURE: 'ADD_SOCIAL_MEDIA_TO_LINK_NA_BIO_FAILURE',

  SORT_SOCIAL_MEDIA_REQUEST: 'SORT_SOCIAL_MEDIA_TO_LINK_NA_BIO_REQUEST',
  SORT_SOCIAL_MEDIA_SUCCESS: 'SORT_SOCIAL_MEDIA_TO_LINK_NA_BIO_SUCCESS',
  SORT_SOCIAL_MEDIA_FAILURE: 'SORT_SOCIAL_MEDIA_TO_LINK_NA_BIO_FAILURE',

  UPDATE_SOCIAL_MEDIA_REQUEST: 'UPDATE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_REQUEST',
  UPDATE_SOCIAL_MEDIA_SUCCESS: 'UPDATE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_SUCCESS',
  UPDATE_SOCIAL_MEDIA_FAILURE: 'UPDATE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_FAILURE',

  DELETE_SOCIAL_MEDIA_REQUEST: 'DELETE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_REQUEST',
  DELETE_SOCIAL_MEDIA_SUCCESS: 'DELETE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_SUCCESS',
  DELETE_SOCIAL_MEDIA_FAILURE: 'DELETE_SOCIAL_MEDIA_TO_SOCIAL_MEDIA_NA_BIO_FAILURE',

  SOCIAL_MEDIA_ICONS_REQUEST: 'SOCIAL_MEDIA_ICONS_REQUEST',
  SOCIAL_MEDIA_ICONS_SUCCESS: 'SOCIAL_MEDIA_ICONS_SUCCESS',
  SOCIAL_MEDIA_ICONS_FAILURE: 'SOCIAL_MEDIA_ICONS_FAILURE',
  SOCIAL_MEDIA_ICONS_SET: 'SOCIAL_MEDIA_ICONS_SET',

  LINK_NA_BIO_STATISTIC_REQUEST: 'LINK_NA_BIO_STATISTIC_REQUEST',
  LINK_NA_BIO_STATISTIC_SUCCESS: 'LINK_NA_BIO_STATISTIC_SUCCESS',
  LINK_NA_BIO_STATISTIC_FAILURE: 'LINK_NA_BIO_STATISTIC_FAILURE',
  LINK_NA_BIO_STATISTIC_SET: 'LINK_NA_BIO_STATISTIC_SET',

  VERIFY_DOMAIN_TO_LINK_EXISTS_RESET: 'VERIFY_DOMAIN_TO_LINK_EXISTS_RESET',
  VERIFY_DOMAIN_TO_LINK_EXISTS_SUCCESS: 'VERIFY_DOMAIN_TO_LINK_EXISTS_SUCCESS',
  VERIFY_DOMAIN_TO_LINK_EXISTS_FAILURE: 'VERIFY_DOMAIN_TO_LINK_EXISTS_FAILURE',

  LINK_NA_BIO_LOADING: 'LINK_NA_BIO_LOADING',
  LINKS_FROM_LINK_NA_BIO_LOADING: 'LINKS_FROM_LINK_NA_BIO_LOADING',
  SOCIAL_MEDIA_FROM_LINK_NA_BIO_LOADING: 'SOCIAL_MEDIA_FROM_LINK_NA_BIO_LOADING',
  LINK_NA_BIO_PROFILES_SET: 'LINK_NA_BIO_PROFILES_SET',

};
