import React from 'react';
import * as Style from './IconDetailedHost.style';

const IconDetailedHost = ({
  color = '#4a4a4a',
  background = '#ffd54c',
  size = '24',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24.967 24.549" fill={color}>
      <path d="M228.459,158.734l-24.588-24.5v24.5Z" transform="translate(-203.871 -134.237)" fill={background} />
      <g transform="translate(14.514 11.66)">
        <rect data-name="Retângulo 13328" width="8.654" height="10.108" fill="none" />
        <g data-name="Grupo 36335" transform="translate(0 0)">
          <path data-name="Caminho 238743" d="M236.527,167.345h-8.654V157.237h8.654Zm-6.832-2.022h5.01v-6.065h-5.01Z" transform="translate(-227.873 -157.237)" />
        </g>
      </g>
      <rect width="2" height="2" transform="translate(4.059 4.055)" />
      <rect width="2" height="2" transform="translate(7.609 4.055)" />
      <rect width="2" height="2" transform="translate(11.36 4.055)" />
      <rect data-name="Rectangle Copy 18" width="4.637" height="2" transform="translate(3.739 19.118)" />
      <rect data-name="Rectangle Copy 21" width="8.611" height="2" transform="translate(3.739 15.209)" />
      <rect data-name="Rectangle Copy 23" width="8.611" height="2" transform="translate(3.739 11.66)" />
      <rect data-name="Rectangle Copy 25" width="20.909" height="2" transform="translate(4.058 7.604)" />
      <path data-name="Caminho 238744" d="M228.839,134.8H203.873v24.176h2.08v-22.1h22.885Z" transform="translate(-203.871 -134.427)" />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedHost;
