/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { locale } from '@/utils/locale';
import { ESSENTIALS_PLAN, PREMIUM_PLAN, ULTRA_PLAN } from '../TitanPlans';
import * as Styles from './PlanFeatures.styles';
import { Mark } from '@/pages/common/v1/Mark';
import { ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';


const PlanFeatures = ({ plan, description }) => {
  const sanitizedDescription = DOMPurify.sanitize(description);

  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );
  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;

  const planFeaturesLocale = {
    whatIsincluded: locale('proEmail.upgradePlan.whatIsincluded'),
    whatIsincludedInPaid: locale('proEmail.upgradePlan.whatIsincludedInPaid'),
  };


  const whatIsincluded = useMemo(() => (
    ([PREMIUM_PLAN, ESSENTIALS_PLAN, ULTRA_PLAN].includes(plan.id) && shouldRenderNewTitanCards)
      ? planFeaturesLocale.whatIsincludedInPaid
      : ''), [plan.id, planFeaturesLocale.whatIsincludedInPaid, shouldRenderNewTitanCards]);

  return (
    <>
      {!shouldRenderNewTitanCards && (
        <Styles.IncludedLabel>
          {whatIsincluded}
        </Styles.IncludedLabel>
      )}
      {shouldRenderNewTitanCards && (
        <>
          <Styles.Separator />
          {whatIsincluded.length > 0 && (
          <Styles.TitleWrapper>
            <Mark variant="sunLight" text={`${whatIsincluded}:`} weight="bold" />
          </Styles.TitleWrapper>
          )}
        </>
      )}
      <Styles.DescriptionWrapper needsMargin={(whatIsincluded.length === 0)} location={`${shouldRenderNewTitanCards ? 'hirePlan' : 'default'}`} dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
    </>
  );
};

PlanFeatures.propTypes = {
  description: PropTypes.string.isRequired,
};

export default PlanFeatures;
