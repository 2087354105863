import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import RecordedItemRow from '@/components/Dns/ZoneManager/RecordList/DesktopRecordList/RecordedItemRow';
import styles from './styles';

const MobileRecordList = ({ classes }) => {
  const { renderedRecordedItems } = useContext(DNSContext);

  return (
    <div className={classes.listWrapper} id="list_wrapper" data-testid="zoneManagerDesktopListWrapper">
      {renderedRecordedItems.map((recordedItem, idx) => {
        const row = idx;
        return (
          <RecordedItemRow recordedItem={recordedItem} key={`row_${row}_${recordedItem.type}_${recordedItem.name}`} idx={idx} />
        );
      })}
    </div>
  );
};


export default (withI18n()((withStyles(styles)(MobileRecordList))));
