import {
  REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS, RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS,
  ERROR_SITE_BUILDER_AVAILABLE_DOMAINS, REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN,
  RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN, ERROR_STATUS_SITE_BUILDER_BY_DOMAIN,
  REQUEST_SITEBUILDER_SSO, ERROR_SITEBUILDER_SSO, SUCCESS_SITEBUILDER_SSO,
  REQUEST_DELETE_SITE_FROM_SITEBUILDER, SUCCESS_DELETE_SITE_FROM_SITEBUILDER,
  ERROR_DELETE_SITE_FROM_SITEBUILDER, REQUEST_ACTIVATE_SITEBUILDER_ADDON,
  RECEIVE_ACTIVATE_SITEBUILDER_ADDON, ERROR_ACTIVATE_SITEBUILDER_ADDON, REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS, RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS, ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  loadingRemoveSite: false,
  loadingSiteBuilderSso: false,
  loadingStatusSiteBuilderByDomain: false,
  loadingStatusSiteBuilderForActions: false,
  loadingActivateSiteBuilderAddon: false,
  domainsList: [],
};

export default function siteBuilder(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS:
      return {
        ...state,
        loading: false,
        domainsList: action.data,
      };
    case ERROR_SITE_BUILDER_AVAILABLE_DOMAINS:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN:
      return {
        ...state,
        loadingStatusSiteBuilderByDomain: true,
      };
    case RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN:
    case ERROR_STATUS_SITE_BUILDER_BY_DOMAIN:
      return {
        ...state,
        loadingStatusSiteBuilderByDomain: false,
      };
    case REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS:
      return {
        ...state,
        loadingStatusSiteBuilderForActions: true,
      };
    case RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS:
    case ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS:
      return {
        ...state,
        loadingStatusSiteBuilderForActions: false,
      };
    case REQUEST_DELETE_SITE_FROM_SITEBUILDER:
      return {
        ...state,
        loadingRemoveSite: true,
      };
    case SUCCESS_DELETE_SITE_FROM_SITEBUILDER:
    case ERROR_DELETE_SITE_FROM_SITEBUILDER:
      return {
        ...state,
        loadingRemoveSite: false,
      };
    case REQUEST_SITEBUILDER_SSO:
      return {
        ...state,
        loadingSiteBuilderSso: true,
      };
    case SUCCESS_SITEBUILDER_SSO:
    case ERROR_SITEBUILDER_SSO:
      return {
        ...state,
        loadingSiteBuilderSso: false,
      };
    case REQUEST_ACTIVATE_SITEBUILDER_ADDON:
      return {
        ...state,
        loadingActivateSiteBuilderAddon: true,
      };
    case RECEIVE_ACTIVATE_SITEBUILDER_ADDON:
    case ERROR_ACTIVATE_SITEBUILDER_ADDON:
      return {
        ...state,
        loadingActivateSiteBuilderAddon: false,
      };
    default:
      return state;
  }
}
