export const styles = theme => ({
  wrapper: {
    position: 'relative',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  container: {
    display: 'flex',
    minHeight: 'calc(100vh - 125px)',
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
    },
  },
  main: {
    width: '100%',
    height: 'fit-content',
    marginRight: 24,
    position: 'relative',
  },
  title: {
    display: 'block',
    fontSize: 28,
    color: theme.color.tundora,
    fontWeight: 500,
    [theme.breakpoints.up(960)]: {
      marginBottom: 12,
    },
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
      fontSize: 20,
      color: theme.color.white,
      padding: '0px 20px 12px 20px',
      backgroundColor: theme.color.chambray,
    },
  },
  aside: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: theme.color.white,
    boxShadow: 'none',
    flexShrink: 0,
    animationName: 'opac',
    animationDuration: '0.8s',
    [theme.breakpoints.up(960)]: {
      width: 310,
    },
    [theme.breakpoints.down(960)]: {
      marginTop: -4,
      paddingTop: 20,
    },
  },
  '@keyframes opac': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  '@keyframes animeAsideBackground': {
    from: {
      backgroundColor: 'transparent',
    },
    to: {
      backgroundColor: theme.color.white,
    },
  },
  asideBackground: {
    backgroundColor: theme.color.white,
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,

    animationName: 'animeAsideBackground',
    animationDuration: '0.3s',
  },
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: 500,
    height: 57,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingRight: 16,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '23px 11px 22px 16px',
    border: 'none',
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  '@keyframes showMenuHover': {
    from: {
      transform: 'translateX(-90%)',
    },
    to: {
      transform: 'translateX(-95%)',
    },
  },
  menuHover: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: theme.color.white,
    height: 57,
    paddingLeft: 22,
    paddingRight: 10,
    animationName: 'showMenuHover',
    animationDuration: '0.30s',
    animationTimingFunction: 'ease-out',
    transform: 'translateX(-95%)',
  },
  menuTitle: {
    animationName: 'animeAsideProgress',
    animationDuration: '0.8s',
  },
  '@keyframes showMenuTitleHover': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  menuTitleHover: {
    animationName: 'showMenuTitleHover',
    animationDuration: '0.40s',
  },
  video: {
    width: '100%',
    [theme.breakpoints.up(960)]: {
      position: 'absolute',
    },
  },
  videoContent: {
    position: 'relative',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    paddingBottom: '57.25%',
    height: 0,
    overflow: 'hidden',
  },
  loader: {
    marginTop: '50%',
  },
  certificateProgress: {
    fontSize: 12,
    color: theme.color.white,
    height: 20,
    lineHeight: '20px',
    borderRadius: 2,
    padding: '0 6px',
  },
  certificateWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '25px 27px 0px 27px',
    '& > a': {
      textDecoration: 'none',
    },
  },
  certificateInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  wrapperPDFLink: {
    '& > a': {
      textDecoration: 'none',
    },
  },
  certificateButton: {
    marginBottom: 30,
  },
  certificateText: {
    fontSize: 12,
    color: theme.color.regentGray,
  },
  greyCertificateProgress: {
    backgroundColor: theme.color.regentGray,
  },
  greenCertificateProgress: {
    backgroundColor: theme.color.oceanGreen,
  },
  lockIcon: {
    marginRight: 15,
    color: theme.color.regentGray,
  },
});

export const headerKeyframes = {
  '0%': {
    opacity: 0,
    transform: 'translateY(35px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

export const widthKeyFrames = (from, to) => ({
  '0%': {
    width: from,
  },
  '100%': {
    width: to,
  },
});

export const heightKeyFrames = (from, to) => ({
  '0%': {
    height: from,
  },
  '100%': {
    height: to,
  },
});
