import { domainsActions } from '@/redux/modules/domains';
import { useDispatch } from 'react-redux';


const useInvoice = () => {
  const dispatch = useDispatch();

  const generate = (domain, callBackFunction) => {
    const dataDomain = {
      id: domain.id,
      donotrenew: false,
      name: domain.domain,
    };
    dispatch(domainsActions.domains.generateNewInvoice.request(dataDomain, callBackFunction));
  };

  return { generate };
};

export default useInvoice;
