import React, { useContext, useState } from 'react';
import { withI18n } from 'react-i18next';
import {
  Button,
  withStyles,
  Chip,
  Collapse,
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons/';
import { DNSContext } from '@/contexts/DNS/context';
import CollapsedRow from '@/components/Dns/ZoneManager/RecordList/DesktopRecordList/CollapsedRow';
import styles from './styles';

const RecordedItemRow = ({
  classes, t, recordedItem, idx,
}) => {
  const {
    recordTypes,
    setExcludeRecordedDnsItem,
    setShowExcludeModalConfirmation,
    showExcludeModalConfirmation,
  } = useContext(DNSContext);

  // Note: if change the value of any DNS type, it will be necessary to change the value in the copyTableToClipboard.js function
  const [collapse, toggleCollapse] = useState(false);
  return (
    <>
      <div className={classes.row} data-id={`Regiser-row_${idx}`} id={`Regiser-row_${idx}`} data-testid={`Regiser-row_${idx}`}>
        <div data-id={`Regiser-type_col_${idx}`} id={`Regiser-type_col_${idx}`} className={`${classes.item} ${classes.type}`} align="left" padding="none" title={recordedItem.type}>
          <div className={classes.wrapperData}>
            <div className={classes.trunkateData}>
              <Chip
                className={classes.chipElement}
                color="primary"
                label={recordedItem.type}
                id={`Regiser-type_col_${idx}`}
              />
            </div>
          </div>
        </div>

        <div data-id={`Regiser-name_col_${idx}`} className={`${classes.item} ${classes.name}`} align="left" padding="none" title={recordedItem.name}>
          <div className={classes.wrapperData}>
            <div className={classes.trunkateData} id={`Regiser-name_col_${idx}`}>
              {recordedItem.name}
            </div>
          </div>
        </div>

        <div data-id={`Regiser-class_col_${idx}`} className={`${classes.item} ${classes.class}`} align="left" padding="none" title={recordedItem.class}>
          <div className={classes.wrapperData}>
            <div className={classes.trunkateData} id={`Regiser-class_col_${idx}`}>
              {recordedItem.class}
            </div>
          </div>
        </div>

        <div data-id={`Regiser-ttl_col_${idx}`} className={`${classes.item} ${classes.ttl}`} align="left" padding="none" title={recordedItem.ttl}>
          <div className={classes.wrapperData}>
            <div className={classes.trunkateData} id={`Regiser-ttl_col_${idx}`}>
              {recordedItem.ttl}
            </div>
          </div>
        </div>

        <div data-id={`Regiser-value_col_${idx}`} className={`${classes.item} ${classes.value}`} align="left" padding="none" title={recordedItem.address}>
          {(recordedItem.type === 'A' || recordedItem.type === 'AAAA')
                && (
                <div className={classes.wrapperData}>
                  <div className={classes.trunkateData} id={`Regiser-value_col_${idx}`}>
                    {recordedItem.address}
                  </div>
                </div>
                )
              }

          {(recordedItem.type === 'CAA') && (
          <div className={classes.wrapperData} data-id={`Regiser-caa_wrapper_${idx}`}>
            <div className={classes.trunkateData} title={recordedItem.flag}>
              {`${t('dns.zoneManager.recordList.listLabel.flag')}: `}
              <span data-id={`Regiser-caa_flag_${idx}`} id={`Regiser-caa_flag_${idx}`}>{recordedItem.flag}</span>
            </div>

            <div className={classes.trunkateData} title={recordedItem.tag}>
              {`${t('dns.zoneManager.recordList.listLabel.tag')}: `}
              <span data-id={`Regiser-caa_tag_${idx}`} id={`Regiser-caa_tag_${idx}`}>{recordedItem.tag}</span>
            </div>

            <div className={classes.trunkateData} title={recordedItem.value}>
              {`${t('dns.zoneManager.recordList.listLabel.value')}: `}
              <span data-id={`Regiser-caa_value_${idx}`} id={`Regiser-caa_value_${idx}`}>{recordedItem.value}</span>
            </div>
          </div>
          )}

          {(recordedItem.type === 'CNAME')
                && (
                <div className={classes.wrapperData} data-id={`Regiser-CNAME_wrapper_${idx}`} id={`Regiser-CNAME_wrapper_${idx}`}>
                  <div className={classes.trunkateData} title={recordedItem.cname} id={`Regiser-CNAME_wrapper_${idx}`}>
                    {recordedItem.cname}
                  </div>
                </div>
                )
              }

          {(recordedItem.type === 'MX')
                && (
                  <div className={classes.wrapperData} data-id={`Regiser-mx_wrapper_${idx}`} id={`Regiser-mx_wrapper_${idx}`}>
                    <div className={classes.trunkateData} title={recordedItem.flag}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeMxPriorityField')}: `}
                      <span data-id={`Regiser-mx_preference_${idx}`} id={`Regiser-mx_preference_${idx}`}>{recordedItem.preference}</span>
                    </div>

                    <div className={classes.trunkateData} title={recordedItem.tag}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeMxDestinationField')}: `}
                      <span data-id={`Regiser-mx_exchange_${idx}`} id={`Regiser-mx_exchange_${idx}`}>{recordedItem.exchange}</span>
                    </div>
                  </div>
                )
              }

          {(recordedItem.type === 'TXT')
                && (
                  <div className={classes.wrapperData} data-id={`Regiser-txt_wrapper_${idx}`} id={`Regiser-txt_wrapper_${idx}`}>
                    <div className={classes.trunkateData} title={recordedItem.txt_data}>
                      <span data-id={`Regiser-txt_data_${idx}`} id={`Regiser-txt_data_${idx}`}>{recordedItem.txt_data}</span>
                    </div>
                  </div>
                )
              }

          {(recordedItem.type === 'SRV')
                && (
                  <div className={classes.wrapperData} data-id={`Regiser-mx_wrapper_${idx}`} id={`Regiser-mx_wrapper_${idx}`}>
                    <div className={classes.trunkateData} title={`${t('dns.zoneManager.addRecord.form.label.typeSrvPriorityField')}: ${recordedItem.priority}`}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeSrvPriorityField')}: `}
                      <span data-id={`Regiser-mx_preference_${idx}`} id={`Regiser-mx_preference_${idx}`}>{recordedItem.priority}</span>
                    </div>

                    <div className={classes.trunkateData} title={`${t('dns.zoneManager.addRecord.form.label.typeSrvPortField')}: ${recordedItem.port}`}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeSrvPortField')}: `}
                      <span data-id={`Regiser-mx_preference_${idx}`} id={`Regiser-mx_preference_${idx}`}>{recordedItem.port}</span>
                    </div>

                    <div className={classes.trunkateData} title={`${t('dns.zoneManager.addRecord.form.label.typeSrvWeightField')}: ${recordedItem.weight}`}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeSrvWeightField')}: `}
                      <span data-id={`Regiser-mx_preference_${idx}`} id={`Regiser-mx_preference_${idx}`}>{recordedItem.weight}</span>
                    </div>

                    <div className={classes.trunkateData} title={`${t('dns.zoneManager.addRecord.form.label.typeSrvDestinationField')}: ${recordedItem.target}`}>
                      {`${t('dns.zoneManager.addRecord.form.label.typeSrvDestinationField')}: `}
                      <span data-id={`Regiser-mx_preference_${idx}`} id={`Regiser-mx_preference_${idx}`}>{recordedItem.target}</span>
                    </div>
                  </div>
                )
              }
        </div>

        <div data-id={`Regiser-edit_col_${idx}`} id={`Regiser-edit_col_${idx}`} className={`${classes.item} ${classes.actions} ${classes.firstAction}`} align="center" padding="none">
          <Button
            className={classes.tableButton}
            disabled={!recordTypes.includes(recordedItem.type)}
            onClick={() => toggleCollapse(!collapse)}
            data-testid="editRecordedItem"
          >
            <EditIcon />
            {t('dns.zoneManager.recordList.listLabel.editButton')}
          </Button>
        </div>
        <div data-id={`Regiser-delete_col_${idx}`} id={`Regiser-delete_col_${idx}`} className={`${classes.item} ${classes.actions}`} align="center" padding="none">
          <Button
            className={classes.tableButton}
            disabled={!recordTypes.includes(recordedItem.type)}
            onClick={() => {
              setExcludeRecordedDnsItem(recordedItem);
              setShowExcludeModalConfirmation(!showExcludeModalConfirmation);
            }}
            data-testid="deleteRecordedItem"
          >
            <DeleteIcon />
            {t('dns.zoneManager.recordList.listLabel.removeButton')}
          </Button>
        </div>
      </div>

      {collapse && (
        <Collapse in={collapse} timeout={300}>
          <CollapsedRow recordedItem={recordedItem} toggleCollapse={toggleCollapse} />
        </Collapse>
      )}
    </>
  );
};
export default (withI18n()((withStyles(styles)(RecordedItemRow))));
