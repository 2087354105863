import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 13px 0px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      padding: 20px 16px;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    > div {
      display: flex;
    }

    svg {
      fill: ${theme.v2.colors.neutral.lowMedium};

      @media (max-width: ${theme.v2.breakpoints.md}){
        fill: ${theme.v2.colors.brand.primaryLight};
      }
    }
  `}
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  display: flex;
`;

export const PageName = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height:${theme.v2.font.lineHeight.text};
    margin: 0px 0px 0px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${theme.v2.breakpoints.md}){
      color: ${theme.v2.colors.brand.primaryLight};
      font-size: ${theme.v2.font.sizes.xs};
    }
  `}
`;

export const ActionsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
    min-width: 591px;

    div {
      right: 0px;
    }

    button {
      white-space: nowrap;

      svg {
        flex: 0 0 24px;
      }
    }

    @media (max-width: ${theme.v2.breakpoints.md}){
      display: none;
    }
  `}
`;

export const FirstFloatingWrapper = styled.div`
  align-items: center;
  display: flex;

  > div > div > div:first-child {
    button{
      padding: 6px 16px;
    }
  }
`;


export const LastFloatingWrapper = styled.div`
  align-items: center;
  display: flex;

  > div > div > div:first-child {
    button{
      padding: 6px 0px 6px 8px;
    }
  }
`;
