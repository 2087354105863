const styles = theme => ({
  modal: {
    borderRadius: 0,
    maxWidth: '475px',
  },
  content: {
    position: 'relative',
    padding: '35px 40px',
  },
  btnClose: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: theme.color.tundora,
    padding: '6px',
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 10px',
  },
  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },
  generatorText: {
    margin: '26px 0',
    color: theme.color.regentGray,
    fontSize: '12px',
  },
  generatorLink: {
    display: 'contents',
    color: theme.color.indigo,
    fontSize: '12px',
  },
  actions: {
    textAlign: 'center',
    marginTop: '25px',
  },
  btnCancel: {
    marginRight: '10px',
    color: theme.color.indigo,
  },
});

export default styles;
