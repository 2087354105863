import { TLD_LIST } from '@/enums/domains/tld.enum';

export const formatDomainEllipsis = (domainName, maxDomainLength = 20) => {
  if (!domainName || domainName === null) {
    return '';
  }
  if (domainName.length <= maxDomainLength) {
    return domainName;
  }

  const reversedDomainPieces = domainName && (domainName.split('.'));

  let tld = '';
  while (reversedDomainPieces.length > 0) {
    tld = `.${reversedDomainPieces.pop()}${tld}`;
    if (TLD_LIST.includes(tld)) {
      break;
    }
  }

  const slicedStringLength = maxDomainLength - tld.length - 5;

  const slicedString = domainName.slice(0, slicedStringLength);

  if (tld === domainName) {
    return domainName;
  }

  return `${slicedString}...[${tld}]`;
};
