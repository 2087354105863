import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import CardStatus from '@/components/Cards/CardStatus';
import ShadowButton from '@/components/Buttons/ShadowButton';
import styles from './styles';


const SiteItem = ({
  classes,
  site,
  t,
}) => {
  const {
    id,
    domain,
    status,
    type,
  } = site;

  const isActive = status === 'active';
  const isWebsitebuilder = type === 'websitebuilder';

  let buttonTarget = '';
  let buttonText = '';

  switch (status) {
    case 'active':
      buttonTarget = `${t('routes.products')}${t('routes.productdetail')}/${id}`;
      buttonText = t('site.actions.active');
      break;
    case 'not_configured':
      buttonTarget = `${t('routes.domains')}${t('routes.domainConfig')}/${id}`;
      buttonText = t('site.actions.notConfigured');
      break;
    default:
      break;
  }

  return (
    <div className={classes.item}>
      <div className={classes.content}>
        <div>
          <span className={classes.title}>{domain}</span>
          {isWebsitebuilder && (
          <span className={classes.subtitle}>{t('site.websitebuilder')}</span>
          )}
        </div>
        {!isActive && (
          <CardStatus status={status}>{t(`site.status.${status}`)}</CardStatus>
        )}
      </div>

      <ShadowButton to={buttonTarget}>{buttonText}</ShadowButton>
    </div>
  );
};

SiteItem.propTypes = {
  classes: PropTypes.object.isRequired,
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    domain: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withI18n()(withStyles(styles)(SiteItem));
