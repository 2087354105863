import React from 'react';
import * as Style from './IconInfo.style';

const IconInfo = ({
  color = '#4480c5',
  testId = 'icon-info',
  size = '18',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill={color}>
      <path data-name="Caminho 247683" d="M0,0H18V18H0Z" fill="none" />
      <path data-name="Caminho 247684" d="M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Zm.7,10.5H8.3V8.3H9.7Zm0-5.6H8.3V5.5H9.7Z" />
    </svg>
  </Style.Wrapper>
);


export default IconInfo;
