const styles = theme => ({

  manageLinkButton: {
    color: theme.palette.grey.light,
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s',

  },

  messageBlock: {
    alignItems: 'center',
    border: `1px solid ${theme.color.white}`,
    borderRadius: '6px',
    display: 'flex',
    marginLeft: 'auto',
    maxWidth: '473px',
    padding: '12px',
    width: '100%',
    [theme.breakpoints.down(1285)]: {
      maxWidth: '100%',
    },
  },

  pendingMessage: { borderColor: theme.color.brightSun },
  provisioningMessage: { borderColor: theme.color.brightSun },
  suspendedMessage: { borderColor: theme.color.valencia },
  terminatedMessage: { borderColor: theme.color.geyser },
  expiredMessage: { borderColor: theme.color.geyser },

  billButton: {
    alignItems: 'center',
    alignSelf: 'center',
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '200px',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '12px',
    height: 32,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    textDecoration: 'none',
    transition: '300ms',
    width: 116,

    '&:hover': {
      backgroundColor: theme.color.indigo,
      color: theme.color.white,
    },

    [theme.breakpoints.down(1285)]: {
      alignSelf: 'flex-start',
      marginBottom: '24px',
    },

    [theme.breakpoints.down(745)]: {
      alignSelf: 'center',
    },
  },

  proEmailInactiveActions: {
    color: theme.color.indigo,
    display: 'block',
    fontWeight: '400',
    margin: 0,
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s',
    [theme.breakpoints.down(1285)]: {
      width: '100%',
    },

    '&:hover': {
      background: theme.color.lightBlue,
      color: theme.color.chambray,
      textDecoration: 'underline',
    },
  },

  contractNewPlanLinkButton: {
    color: theme.color.indigo,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    margin: 0,
    paddingRight: '0px',
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s',
    '&:hover': {
      color: theme.color.chambray,
      backgroundColor: '#F2F8FC',
      textDecoration: 'underline',
    },
  },

  warningIcon: {
    fill: theme.color.valencia,
    height: '14px',
    marginRight: '4px',
    opacity: 1,
    width: '14px',
  },

  messageLink: {
    color: theme.color.indigo,
    fontWeight: 500,
  },

  message: {
    color: theme.color.tundora,
    fontSize: 12,
  },
});

export default styles;
