const styles = theme => ({
  labelDisabled: {
    color: theme.color.halfBaked,
    [theme.breakpoints.down(960)]: {
      color: theme.color.white,
    },
  },
  faq: {
    padding: '29px 0 17px 0',
  },
  bottomCard: {
    marginBottom: 0,
  },
  tabs: {
    backgroundColor: theme.color.chambray,
    [theme.breakpoints.up(960)]: {
      backgroundColor: theme.color.white,
    },
  },
  tabContainerNewBilling: {
    top: '116px',
  },
  invoiceFilter: {
    margin: '0px 24px 0px 24px',
    [theme.breakpoints.down(960)]: {
      margin: 'auto',
    },
  },
  tabContainer: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.up(960)]: {
      boxShadow: '0px 2px 4px 0px #DBDBDB',
      marginLeft: 40,
      marginRight: 40,
      position: 'initial',
      width: 'initial',
    },
  },
  indicator: {
    height: '6px',
    backgroundColor: theme.color.indigo,
  },
  tab: {
    color: theme.color.white,
    opacity: 1,
    transition: '0.2s opacity',
    willChange: 'opacity',
    [theme.breakpoints.up(960)]: {
      color: theme.color.halfBaked,
    },
  },
  tabSelected: {
    color: theme.color.indigo,
    [theme.breakpoints.down(960)]: {
      color: theme.color.white,
    },
  },
  tabTitle: {
    color: theme.color.regentGray,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
    marginBottom: '26px',
    marginTop: '20px',
    padding: '0 20px',
    textAlign: 'center',
    width: '100%',
  },
  swipeableViewsContainer: {
    width: '100%',
  },
  loadMoreDiv: {
    boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.20)',
    background: theme.color.white,
    padding: '40px',
    textAlign: 'center',
  },
  loading: {
    backgroundColor: theme.color.white,
    margin: '8px 24px',
    padding: '24px',
    '@media(max-width: 600px)': {
      margin: '8px 0px',
    },
  },
  swipeContainerTab: {
    padding: '0px 24px 7px 24px',
    [theme.breakpoints.up(960)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  filterPaid: {
    minHeight: '60px',
  },
  wrapperContentTab: {
    [theme.breakpoints.down(960)]: {
      paddingTop: 48,
    },
  },
  wrapperContentTabNewBilling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 118,
    },
  },
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
  },
});

export default styles;
