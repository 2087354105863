import React from 'react';
import * as Style from './IconDetailedSchedules.style';

const IconDetailedSchedules = ({
  size = '30',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1986_1719)">
        <path d="M12.4607 14.6572H27.1125V25.6447H12.4607V14.6572Z" fill="#FF9442" />
        <path d="M12.5518 14.6572H19.9553V21.7394H12.5518V14.6572Z" fill="#FF9442" />
        <path d="M4.90174 25.6446H2.88745V4.93384H23.5714V6.9642H4.90174V25.6446Z" fill="#1F2044" />
        <path d="M6.62133 13.3125H8.60883V15.3H6.62133V13.3125ZM6.62133 17.3625H8.60883V19.35H6.62133V17.3625ZM6.62133 21.4125H8.60883V23.4H6.62133V21.4125ZM15.4928 13.3125H17.4803V15.3H15.4928V13.3125ZM15.4928 17.3625H17.4803V19.35H15.4928V17.3625ZM15.4928 21.4125H17.4803V23.4H15.4928V21.4125ZM20.1642 13.3125H22.1517V15.3H20.1642V13.3125ZM20.1642 17.3625H22.1517V19.35H20.1642V17.3625ZM20.1642 21.4125H22.1517V23.4H20.1642V21.4125ZM11.2928 13.3125H13.2803V15.3H11.2928V13.3125ZM11.2928 17.3625H13.2803V19.35H11.2928V17.3625ZM11.2928 21.4125H13.2803V23.4H11.2928V21.4125ZM7.39276 3.66431H9.11776V6.68573H7.39276V3.66431ZM18.332 3.66431H20.057V6.68573H18.332V3.66431ZM6.3374 9.32145H23.5928V11.3089H6.3374V9.32145Z" fill="#1F2044" />
      </g>
      <defs>
        <clipPath id="clip0_1986_1719">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedSchedules;
