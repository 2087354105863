import React from 'react';
import * as Style from './IconEdit.style';

const IconEdit = ({
  color = '#2e93ee',
  testId = 'icon-edit',
  size = 24,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="mode_edit_black_24dp" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill={color}>
      <path id="Caminho_247899" data-name="Caminho 247899" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" />
    </svg>

  </Style.Wrapper>
);


export default IconEdit;
