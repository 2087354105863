export const tagList = [
  {
    id: 0, label: 'Todos', value: 'all', checked: true,
  },
  {
    id: 1, label: 'Recomendados', value: 'recomended', checked: false,
  },
  // {id: 2, label: 'Email', value: 'all', checked: false}, Removido temporariamente -- Retornando em 2021
  {
    id: 3, label: 'Marketing', value: 'marketing', checked: false,
  },
  {
    id: 4, label: 'Criação de Sites', value: 'siteBuilder', checked: false,
  },
  {
    id: 5, label: 'Chat', value: 'chat', checked: false,
  },
  // {id: 6, label: 'Automação', value: 'automation', checked: false}, Removido temporariamente -- Retornando em 2021
  {
    id: 7, label: 'Segurança', value: 'security', checked: false,
  },
  {
    id: 8, label: 'Design', value: 'design', checked: false,
  },
  {
    id: 10, label: 'Cursos', value: 'courses', checked: false,
  },
  {
    id: 9, label: 'Financeiro', value: 'finantial', checked: false,
  },
];
