import React from 'react';
import * as Styles from './CancelSubscriptionRenewalPage.styles';
import { CancelSubscriptionRenewalHandler } from '@/components/billing';

const CancelSubscriptionRenewalPage = () => (
  <Styles.Wrapper data-testid="cancel-subscription-renewal-page">
    <CancelSubscriptionRenewalHandler />
  </Styles.Wrapper>
);

export default CancelSubscriptionRenewalPage;
