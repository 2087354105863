import styled, { css } from 'styled-components';

export const UploadModalTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 32px 0px;
  `}
`;

export const AllowDrag = styled.label`
  ${({ theme }) => css`
    align-items: center;
    border-radius: 8px;
    border: 1px dashed ${theme.v2.colors.neutral.highMedium};
    color: ${theme.v2.colors.neutral.lowDark};
    cursor: pointer;
    display: flex;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin: 16px 32px 48px;
    padding: 36px 18px 76px 28px;
    z-index: ${theme.v2.layers.important};

    button: {
    z-index: -1;
    }
  `}
`;


export const DraggableTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px 0px;
    max-width: 304px;
    text-align: center;
  `}
`;


export const UploadInput = styled.input`
  display: none;
`;
