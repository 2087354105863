import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';
import { Wrapper as Radio, Dot as RadioDot } from '@/pages/common/inputs/Radio/Radio.styles';
import { Link } from 'react-router-dom';


export const Content = styled.div`
  ${({
    variant,
  }) => {
    const variants = {
      subscriptions: {
        margin: '0px -22px 0px -22px',
      },
      invoices: {
        margin: '0px',
      },
    };
    return css`
    margin: ${variants[variant].margin};
    `;
  }}
`;

export const Data = styled.div`
  ${() => css`
    padding-top: 16px;
  `}
`;

export const Header = styled.header``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 0;
`;

export const UserName = styled.p`
${({ theme }) => css`
  font-family: ${theme.v1.font.family.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin:8px 0px 0px 0px;
`}
`;

export const UserEmail = styled.p`
${({ theme }) => css`
  color: ${theme.color.regentGray};
  font-family: ${theme.v1.font.family.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin: 0px;
`}
`;

export const OptionList = styled.ul`
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
`;

export const OptionItem = styled.div`
  ${({ checked }) => css`
    background-size: contain;
    border-radius: ${checked ? 0 : '2px'};
    border: 1px solid #CDD8DF;
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
    &:not(:first-child) {
      margin-top:17px;
    }
  `}
`;

export const OptionItemWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    padding: 11px;
    padding-botton:0px;
  `}
`;

export const OptionRadio = styled.div`
  ${({ theme, checked }) => css`
    ${Radio} {
      border-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }

    ${RadioDot} {
      background-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }
  `}
`;

export const OptionContent = styled.div`
  padding-left: 9px;
`;

export const OptionLabel = styled.label`
  ${({ theme, checked }) => css`
    color: ${checked ? theme.palette.blue.dark : theme.palette.secondary.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: -0.19px;
    transition: color 200ms ease-in-out;
  `}
`;

export const OptionDescription = styled.p`
  font-size: 14px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const ControlButton = styled.div`
  ${() => css`
 
    &:not(:first-child) {
      margin-left: 12px;
    }

    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  `}
`;


export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-bottom: 21px;
    margin-top: 33px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;

export const InfoIcon = styled.span`
  ${({ theme }) => css`
    display: contents;
    & svg {
      fill: ${theme.v1.color.warning.attention};
      height:26px;
      width:26px;
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;
export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.label};
    display: inline-flex;
    font-size: 12px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
  `}
`;
export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
  `}
`;

export const WrapperData = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v1.font.family.primary};
    padding: 16px 24px 16px 24px;
  `}
`;
export const WrapperInfo = styled.section`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v1.font.family.primary};
    justify-content: center;
    padding: 16px 24px 16px 24px;
    text-align:center;
  `}
`;
export const SelectTitle = styled.section`
  ${({ theme }) => css`
    font-style: normal;
    font-weight: ${theme.v1.font.weights.regular};
    font-size: ${theme.font.sizes.md};
    line-height: 22px;
    color: ${theme.color.greyDark};
    margin: 24px 0px -2px 0px;
`}
`;

export const DescriptionInfo = styled.p`
  ${({ theme }) => css`
      font-size:${theme.font.sizes.xxs};
      margin: 8px 0px 0px 0px;
 `}
`;
export const ContentCloseButton = styled.div`
${() => css`
    position: absolute;
    top: 0px;
    right: 0px;
`}
`;
export const ContentData = styled.div`
  ${({ theme }) => css`
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;
export const ContentPaymentSelector = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding-top: 0px;
  `}
`;

export const ContentOptionsInSelectord = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding-top: 23px;
  `}
`;
export const CTA = styled(Link)`
  ${({ theme, arrow }) => css`
  color: ${theme.palette.secondary.primary};
  cursor: pointer;
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.md};
  font-weight: ${theme.font.weights.medium};
  line-height: ${theme.font.sizes.xl};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: url(${arrow});
    font-size: ${theme.font.sizes.xxxxxxl};
    font-weight: 1000;
    opacity: 0;
    position: absolute;
    transition: transform 0.1s, opacity 0.1s;
    transform: translate(-18px, 3px) rotate(180deg);
    @media (max-width: ${theme.breakpoints.md}) {
      transform:none;
      content:none;
    }
  }

  &:hover:before {
    opacity: 1;
    transform: translate(-18px, 3px) rotate(180deg);
    @media (max-width: ${theme.breakpoints.md}) {
      transform:none;
      content:none;
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    display: block;
    text-decoration: underline;
  }

  `}
`;

export const ArrowIcon = styled.img`
${({ theme, arrow }) => css`
@media (max-width: ${theme.breakpoints.md}) {
    bottom: -2px;
    content: url(${arrow});
    position: relative;
    right:-2px;
    width:16px;
   }
  `}
`;
