import React, { useCallback } from 'react';
import * as Styles from './Header.styles';
import { Tooltip } from '@/pages/common';

const Header = ({
  children,
  childrenPosition = 'flex-start',
  description = 'Description',
  testId = 'header',
  title = 'Title',
  tooltip = null,
  paddingBottom = true,
}) => {
  const headerContent = useCallback(() => (
    <>
      <Styles.Title data-testid={`${testId}-title`}>{title}</Styles.Title>

      {description
       && <Styles.Description data-testid={`${testId}-description`}>{description}</Styles.Description>
      }

      <Styles.ChildrenContainer position={childrenPosition}>{children}</Styles.ChildrenContainer>
    </>
  ), [children, childrenPosition, description, title, testId]);

  return (
    <Styles.Wrapper data-testid={testId} paddingBottom={paddingBottom}>
      {tooltip
        ? (
          <Styles.TooltipWrapper id="TooltipWrapper">
            <Tooltip {...tooltip}>
              {headerContent()}
            </Tooltip>
          </Styles.TooltipWrapper>
        )
        : headerContent()
      }
    </Styles.Wrapper>
  );
};

export default Header;
