import { BackupActionTypes } from './backup.types';

const showModalBackupRedirect = ({ showModal, productId }) => ({
  type: BackupActionTypes.SET_BACKUP_REDIRECT_MODAL_SHOW,
  payload: { showModal, productId },
});

const redirectFromPreChat = payload => ({
  type: BackupActionTypes.SET_REDIRECT_FROM_PRE_CHAT,
  payload,
});

const setBackupGenerationLoading = payload => ({
  type: BackupActionTypes.SET_BACKUP_GENERATION_LOADING,
  payload,
});

const requestBackupGeneration = ({ id }) => ({
  type: BackupActionTypes.REQUEST_BACKUP_GENERATION,
  payload: { id },
});

const failureBackupGeneration = payload => ({
  type: BackupActionTypes.FAILURE_BACKUP_GENERATION,
  payload,
});

const successBackupGeneration = payload => ({
  type: BackupActionTypes.SUCCESS_BACKUP_GENERATION,
  payload,
});

const clearBackupStatus = () => ({
  type: BackupActionTypes.CLEAR_BACKUP_STATUS,
});

const requestBackupLog = ({ id }) => ({
  type: BackupActionTypes.REQUEST_BACKUP_LOG,
  payload: { id },
});

const setBackupLoadingStatus = payload => ({
  type: BackupActionTypes.SET_LOADING_STATUS_BACKUP_LOG,
  payload,
});

const setBackupLogSuccess = payload => ({
  type: BackupActionTypes.SET_SUCCESS_BACKUP_LOG,
  payload,
});

const setBackupLogFailure = payload => ({
  type: BackupActionTypes.SET_FAILURE_BACKUP_LOG,
  payload,
});

const setBackupLog = payload => ({
  type: BackupActionTypes.SET_BACKUP_LOG,
  payload,
});

const requestInodes = ({ id }) => ({ type: BackupActionTypes.REQUEST_INODES_STATUS, payload: { id } });
const setRequestInodesLoading = bool => ({ type: BackupActionTypes.LOADING_INODES_STATUS, payload: bool });
const successRequestInodes = () => ({ type: BackupActionTypes.SUCCESS_INODES_STATUS });
const failureRequestInode = () => ({ type: BackupActionTypes.FAILURE_INODES_STATUS });
const setInodes = bool => ({ type: BackupActionTypes.SET_INODES, payload: bool });

const actions = {
  set: {
    showModalBackupRedirect,
    redirectFromPreChat,
  },
  inodes: {
    request: requestInodes,
    loading: setRequestInodesLoading,
    success: successRequestInodes,
    failure: failureRequestInode,
    set: setInodes,
  },
  backupGeneration: {
    request: requestBackupGeneration,
    failure: failureBackupGeneration,
    success: successBackupGeneration,
    loading: setBackupGenerationLoading,
    clearStatus: clearBackupStatus,
  },
  backupLog: {
    request: requestBackupLog,
    loading: setBackupLoadingStatus,
    success: setBackupLogSuccess,
    failure: setBackupLogFailure,
    set: setBackupLog,
  },
};

export default actions;
