import React from 'react';
import * as Style from './IconDedicatedWindows.styles';

const IconDedicatedWindows = ({ testId = 'icon-dedicated-windows' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="Grupo_40863" data-name="Grupo 40863" transform="translate(-478.498 -577)">
        <rect id="Retângulo_16278" data-name="Retângulo 16278" width="22" height="22" transform="translate(478.498 577)" fill="none" />
        <g id="Grupo_40812" data-name="Grupo 40812" transform="translate(478.498 577.929)">
          <ellipse id="Elipse_1094" data-name="Elipse 1094" cx="8.433" cy="8.232" rx="8.433" ry="8.232" transform="translate(0 3.68)" fill="#ff9144" />
          <path id="Caminho_32981" data-name="Caminho 32981" d="M-9766.209-13875.773l6.853-.911,0,6.45-6.851.039Zm6.85,6.283,0,6.456-6.849-.918v-5.58Zm.83-7.314,9.087-1.291v7.78l-9.087.069Zm9.088,7.374v7.749l-9.087-1.251-.012-6.512Z" transform="translate(9771.439 13878.096)" fill="#4a4a4a" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDedicatedWindows;
