import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 24px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    margin: 0;
  `}
`;

export const ItemTitle = styled.p`
  ${({ theme }) => css`
      color: ${theme.palette.grey.dark};
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.font.weights.medium};
      letter-spacing: 0.01px;
      line-height: 24px;
      margin: 34px 0px 18px 0px;
    `}
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 24px;
    padding-left: 16px;
    position: relative;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:before {
      background-color: ${theme.palette.grey.dark};
      border-radius: 50%;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(70%);
      width: 8px;
    }
  `}
`;

export const ReasonDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 0;
    padding-left: 16px;
    position: relative;
  `}
`;
