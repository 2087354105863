import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family.secondary};
    gap: 24px;
    margin: 8px;
  `}
`;

export const Title = styled.p`
  ${({ theme, isMobile }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${!isMobile && theme.font.weights.bold};
    margin: 0;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
  `}
`;

export const Description = styled(Text)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0;
    a {
      color: ${theme.v2.colors.brand.primaryBlue};
    }
  `}
`;
