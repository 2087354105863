const styles = theme => ({
  tRowBorder: {
    borderLeft: `1px solid ${theme.color.mystic}`,
    width: '35%',
  },
  tRowAlign: {
    textAlign: 'right',
  },
  tableMain: {
    border: `1px solid ${theme.color.mystic}`,
  },
  tableHeader: {
    backgroundColor: theme.color.mystic,
    '& tr': {
      height: 'auto',
    },
    '& tr th': {
      padding: '10px 15px',
      color: theme.color.tundora,
      fontWeight: '400',
    },
    '& tr > th': {
      '&:last-child': {
        padding: '10px 15px',
      },

    },
  },
  tableBody: {
    '& > tr': {
      height: 'auto',
    },
    '& > tr > td': {
      [theme.breakpoints.down('xs')]: {
        '&:last-child': {
          padding: '7px 15px',
        },
        padding: '7px 15px',
      },
      '&:last-child': {
        padding: '10px 15px',
      },
      padding: '10px 15px',
      fontSize: '12px',
    },
  },
  tableTextBold: {
    fontWeight: '500',
  },
  tableValueTd: {
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
    },
  },
});

export default styles;
