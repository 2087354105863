import React from 'react';
import * as Style from './IconMagento.style';

const IconMagento = ({
  color = '#4a4a4a',
  width = '25',
  height = '29',
  testId = 'icon-magento',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25.019 28.895" fill={color}>
      <g id="Grupo_39830" data-name="Grupo 39830" transform="translate(-1050.49 -547.104)">
        <g id="_x32_04-magento" transform="translate(1050.49 547.104)">
          <g id="Grupo_39661" data-name="Grupo 39661" transform="translate(0 0)">
            <path id="Caminho_247732" data-name="Caminho 247732" d="M81.876,33.218v14.45L78.3,49.728V35.294l-8.943-5.169-8.949,5.169.023,14.439-3.572-2.065V33.229L69.378,26ZM71.149,49.728l-1.783,1.038-1.794-1.027V35.294L64,37.359,64.006,51.8l5.355,3.1,5.366-3.1V37.354l-3.577-2.065V49.728Z" transform="translate(-56.857 -26.001)" />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconMagento;
