import {
  REQUEST_SITES_AND_PLANS, RECEIVE_SITES_AND_PLANS, ERROR_SITES_AND_PLANS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  loadedDate: null,
  products: [],
  filterStatus: [],
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SITES_AND_PLANS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SITES_AND_PLANS:
      return {
        ...state,
        loading: false,
        products: action.sitesPlans,
        loadedDate: action.loadedDate,
        filterStatus: action.filterStatus,
      };
    case ERROR_SITES_AND_PLANS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default changePassword;
