import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const useConfigurationStatus = (configurationStatus, platformName) => {
  const {
    isConfiguredThroughWizard,
    isConfiguredInHostGator,
    isConfiguredInHostGatorWithHostingPlan,
    isConfiguredInHostGatorWithCustomDnsZone,
  } = configurationStatus;

  const isPointedToHostGatorPlan = isConfiguredThroughWizard && isConfiguredInHostGator && isConfiguredInHostGatorWithHostingPlan && !isConfiguredInHostGatorWithCustomDnsZone;
  const isPointedToReservedServer = isConfiguredThroughWizard && isConfiguredInHostGator && !isConfiguredInHostGatorWithHostingPlan && !isConfiguredInHostGatorWithCustomDnsZone;
  const isPointedToCustomDnsZone = isConfiguredThroughWizard && isConfiguredInHostGator && isConfiguredInHostGatorWithCustomDnsZone;
  const isPointedToCustomServer = isConfiguredThroughWizard && !isConfiguredInHostGator && !isConfiguredInHostGatorWithHostingPlan && !isConfiguredInHostGatorWithCustomDnsZone && !platformName;
  const isPointedToExternalServer = isConfiguredThroughWizard && !isConfiguredInHostGator && !isConfiguredInHostGatorWithHostingPlan && !isConfiguredInHostGatorWithCustomDnsZone && platformName;

  if (isPointedToCustomDnsZone || platformName === HOSTING_PLATFORM_TYPES.DLOJA) {
    return HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE;
  }

  if (isPointedToReservedServer) {
    return HOSTING_PLATFORM_TYPES.RESERVED;
  }

  if (isPointedToExternalServer) {
    return HOSTING_PLATFORM_TYPES.EXTERNAL;
  }

  if (isPointedToCustomServer) {
    return HOSTING_PLATFORM_TYPES.CUSTOM;
  }

  if (isPointedToHostGatorPlan) {
    return HOSTING_PLATFORM_TYPES.HOSTGATOR;
  }

  return HOSTING_PLATFORM_TYPES.UNDEFINED;
};

export default useConfigurationStatus;
