import React, { useEffect, useState } from 'react';
import {
  withStyles, Paper, Table, TableRow, TableCell, TableHead, Typography, Button, CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Save from '@material-ui/icons/Save';
import { withI18n } from 'react-i18next';
import { downloadSMARTFile } from '@/redux/actions/sitelock';
import { downloadSweepSitelock } from '@/utils/ThirdParties/tagManager';
import styles from './styles';

const SmartScanResult = ({
  classes, t, history, domainName,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const {
    lastRequestedFilename,
    lastReceivedFilename,
    lastFailedFilename,
  } = useSelector(state => state.sitelock.smartscan.file);

  const { product } = useSelector(state => state.sitelock);

  const handleClick = (downloadFilename) => {
    dispatch(downloadSMARTFile(downloadFilename));
    downloadSweepSitelock(product);
  };

  useEffect(() => {
    if (lastRequestedFilename === history.download) {
      setLoading(true);
      setError(false);
    }
  }, [lastRequestedFilename, history.download]);

  useEffect(() => {
    if (lastReceivedFilename === history.download) {
      setLoading(false);
      setError(false);
    }
  }, [lastReceivedFilename, history.download]);

  useEffect(() => {
    if (lastFailedFilename === history.download) {
      setLoading(false);
      setError(true);
    }
  }, [lastFailedFilename, history.download]);

  return (
    <Paper className={classes.resultContainer} data-testid="smartscan-result">
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>
              <Typography className={classes.smartscanHeader}>{t('sitelock.smartscan.history.verified')}</Typography>
              <Typography className={classes.smartscanBody}>{domainName}</Typography>
            </TableCell>
            <TableCell className={classes.tableHeader}>
              <Typography className={classes.smartscanHeader}>{t('sitelock.smartscan.history.scanned')}</Typography>
              <Typography className={classes.smartscanBody}>{history.scanned}</Typography>
            </TableCell>
            <TableCell className={classes.tableHeader}>
              <Typography className={classes.smartscanHeader}>{t('sitelock.smartscan.history.fixed')}</Typography>
              <Typography className={classes.smartscanBody}>{history.fixed}</Typography>
            </TableCell>
            <TableCell className={classes.tableHeader} colSpan={2}>
              <Typography className={classes.smartscanHeader}>{t('sitelock.smartscan.history.manualFix')}</Typography>
              <Typography className={classes.smartscanBody}>{history.needFix}</Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableHeader, classes.downloadCell)}>
              {(history.files > 0)
              && (
              <Button
                onClick={() => handleClick(history.download)}
                className={classes.btn}
                size="small"
                data-testid="btn-download"
                disabled={loading}
              >
                {loading ? (<CircularProgress size={18} className={classes.btnIcon} />) : (<Save className={classes.btnIcon} />)}
                {t('sitelock.smartscan.history.actionBtn')}
              </Button>
              )}
              {
                (error === true) && (
                <Typography className={classes.downloadError}>
                  {t('sitelock.smartscan.history.downloadError')}
                  <br />
                  {t('sitelock.smartscan.history.downloadTryAgainLater')}
                </Typography>
                )
              }
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Paper>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(SmartScanResult));
