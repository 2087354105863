import React, { Component, Fragment } from 'react';
import { Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadDomainRenewal } from '@/redux/actions/domains';


class DomainRenewal extends Component {
  constructor(props) {
    super(props);
    const { donotrenew } = this.props;
    this.state = {
      checked: !donotrenew,
    };
  }

  handleRenewal = () => {
    const { domainId, loadDomainRenewal } = this.props;
    const { checked } = this.state;
    this.setState({ checked: !checked });
    loadDomainRenewal(domainId, checked);
  };

  render() {
    const { disabled } = this.props;
    const { checked } = this.state;
    return (
      <Fragment>
        <Switch
          classes={{
            bar: 'roundBar',
            icon: 'roundIcon',
            checked: 'barChecked',
            iconChecked: 'iconChecked',
            root: 'switchRoot',
          }}
          onChange={this.handleRenewal}
          checked={checked}
          disabled={disabled}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  loadDomainRenewal,
}, dispatch);

export default connect(
  undefined,
  mapDispatchToProps,
)(DomainRenewal);
