export default {
  sites: 'Meus sites',
  sitesPage: 'Sites',
  sitesPlans: 'Sites e Planos',
  domains: 'Domínios',
  emails: 'E-mails',
  products: 'Produtos',
  billing: 'Faturas',
  newBilling: 'Faturas e Assinaturas',
  subscriptions: 'Assinaturas',
  hireProduct: 'Contratar Produto / Serviço',
  creditCard: 'Gerenciar Cartão',
  help: 'Suporte',
  support: 'Suporte',
  servicesStatus: 'Status dos Serviços',
  stopBeta: 'Parar de usar a Versão Beta',
  quit: 'Sair',
  myData: 'Meus Dados',
  changePassword: 'Alterar Senha',
  accountSecurity: 'Segurança da Conta',
  manageUsers: 'Gerenciar Usuários',
  courses: 'Cursos',
  mktPlace: 'Ferramentas e Serviços',
  mktPlaceAlt: 'Potencialize seu negócio',
  mktPlaceDescription: 'Encontre os melhores aplicativos para o seu site',
  security: 'Segurança e Backup',
  sitelock: 'SiteLock',
  academyPass: 'Cursos',
  asaas_a: 'Venda com Pix, cartões e boleto',
  asaas_b: 'Pagamentos na sua loja',
  asaas_c: 'Gere cobranças em seu site',
  profile: 'Perfil',
  linkNaBio: 'Link na bio',
  v2: {
    products: 'Hospedagens e Servidores',
    createSiteWithPartners: {
      title: {
        0: 'Faça seu site ',
        1: 'do jeito que você imaginou!',
      },
      description: {
        0: 'Conte com um',
        1: ' especialista em criação de sites ',
        2: 'para o seu projeto. :)',
      },
      buttonCreate: 'Criar com um especialista',
    },
  },
  myAlerts: 'Meus avisos',
  allNotifications: 'Todos',
  ticketNotifications: 'Chamados',
  seeMore: 'Ver mais',
  alertsTooltip: 'Quando a situação de um aviso for concluída ele desaparece dessa área.',
  noNotifications: 'Você não tem novas notificações 😊',
};
