import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 32px 32px 0px 32px;
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 30px 32px 0px 32px;

    

    @media (max-width: ${theme.breakpoints.sm}){
      align-items: center;
      flex-direction: column;

      svg {
        height: 55px;
        margin-bottom: 40px;
        width: 55px;
      }
    }
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    letter-spacing: 0px;
    line-height: 19px;
    margin: 0;
    padding-left: 16px;

    strong {
      color: ${theme.palette.blue.dark};
      font-weight: ${theme.font.weights.medium};
    }
  `}
`;

export const RadioWrapper = styled.div`
  display: flex;
  margin: 40px auto 0px;
`;

export const RadioLabel = styled.label`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.palette.grey.dark};
    cursor: pointer;
    display: flex;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxs};
    letter-spacing: 0px;
    line-height: 16px;

    > span {
      margin-right: 8px;
    }
  `}
`;

export const ActionsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 32px;
    margin: 40px auto;

    button {
      align-items: center;
      display: flex;
      justify-content: center;

      > div {
        margin-right: 10px !important;

        svg {
          opacity:0;
          transform: rotate( 180deg );
          transition: opacity 150ms ease-in-out !important;
        }
      }


      &:hover {
        > div {
          svg {
            opacity: 1;
          }
        }
      }

      &:last-child {
        div {
          padding: 0px;
          div {
            width: 16px;
            height: 16px;
            margin: 0 67px;
          }
        }
      }
    }

    @media (max-width: ${theme.breakpoints.sm}){
      flex-direction: column-reverse;
      > div:first-child {
        button {
          div {
            margin-left: -26px;
          }
        }
      }

      button {
        > div{
          margin: unset;
        }
      }
    }
  `}
`;
