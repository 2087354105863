export const STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  NONEXISTENT: 'nonexistent',
  PENDING_DEACTIVATION: 'pendingDeactivation',
  ACTIVATION_POINTING_ERROR: 'activation_pointing_error',
  INACTIVATION_POINTING_ERROR: 'inactivation_pointing_error',
  DELAY: 'delay',
};

export const ORIGIN = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};
