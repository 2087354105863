const styles = theme => ({
  card: {
    alignItems: 'center',
    backgroundColor: theme.color.white,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    display: 'flex',
    marginBottom: 6,
    minHeight: '60px',
    padding: '15px 24px',
    flexWrap: 'wrap',
  },

  plan: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 300,
    letterSpacing: '0.01px',
    lineHeight: '29px',
    marginRight: '6px',
  },

  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 300,
    letterSpacing: '0.01px',
    lineHeight: '29px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '270px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },

  redirectButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.color.indigo,
    cursor: 'pointer',
    marginRight: '16px',
    padding: 0,
  },

  titleHasPlan: {
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.01px',
    color: theme.color.regentGray,
    fontWeight: 300,
  },
});
export default styles;
