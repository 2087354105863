import { BackupActionTypes } from './backup.types';

export const initialState = {
  showBackupRedirectModal: false,
  productId: null,
  redirectFromPreChat: false,
  loadingLog: true,
  loadingInodes: false,
  inodesAndSizeOk: true,
  backupGeneration: {
    loading: false,
    status: null,
  },
  backupLog: [],
};

const backupReducer = (state = initialState, action) => {
  switch (action.type) {
    case BackupActionTypes.SET_BACKUP_REDIRECT_MODAL_SHOW: {
      const { showModal, productId } = action.payload;
      return {
        ...state,
        showBackupRedirectModal: showModal,
        productId,
      };
    }
    case BackupActionTypes.SET_REDIRECT_FROM_PRE_CHAT: {
      return {
        ...state,
        redirectFromPreChat: action.payload,
      };
    }
    case BackupActionTypes.SET_BACKUP_GENERATION_LOADING: {
      const { payload } = action;
      return {
        ...state,
        backupGeneration: {
          ...state.backupGeneration,
          loading: payload,
        },
      };
    }
    case BackupActionTypes.SUCCESS_BACKUP_GENERATION:
    case BackupActionTypes.FAILURE_BACKUP_GENERATION: {
      const { payload } = action;
      return {
        ...state,
        backupGeneration: {
          ...state.backupGeneration,
          status: payload,
        },
      };
    }
    case BackupActionTypes.CLEAR_BACKUP_STATUS: {
      return {
        ...state,
        backupGeneration: {
          ...state.backupGeneration,
          status: null,
        },
      };
    }
    case BackupActionTypes.SET_LOADING_STATUS_BACKUP_LOG: {
      return {
        ...state,
        loadingLog: action.payload,
      };
    }
    case BackupActionTypes.SET_BACKUP_LOG: {
      return {
        ...state,
        backupLog: action.payload,
      };
    }
    case BackupActionTypes.LOADING_INODES_STATUS: {
      return {
        ...state,
        loadingInodes: action.payload,
      };
    }
    case BackupActionTypes.SET_INODES: {
      return {
        ...state,
        inodesAndSizeOk: action.payload,
      };
    }
    default:
      return state;
  }
};

export default backupReducer;
