const styles = theme => ({
  card: {
    alignItems: 'center',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    display: 'flex',
    minHeight: '60px',
    padding: '21px 24px',
    position: 'relative',

    [theme.breakpoints.up(768)]: {
      boxShadow: 'none',
    },
  },
  verticalFlexCard: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '470px',
  },
  subtitle: {
    textAlign: 'left',
    font: 'normal normal normal 24px/29px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
  },
  description: {
    font: 'normal normal normal 14px/17px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    marginTop: '5px',
  },
  button: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    fontSize: '14px',
    fontWeight: '500',
    height: '37px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    textDecoration: 'none',
    transition: '300ms',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.color.chambray,
    },
  },
  outlinedButton: {
    backgroundColor: theme.color.white,
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    '&:hover': {
      backgroundColor: theme.color.chambray,
      color: theme.color.white,
    },
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    marginTop: '68px',
  },
  flexTopCard: {
    flex: 1,
    alignItems: 'flex-start',
  },
  previewTitle: {
    marginTop: 19,
    marginBottom: 6,
    fontSize: '14px',
    fontWeight: 500,
    color: theme.color.tundora,
  },
  hide: {
    maxHeight: '0px !important',
    transition: 'height 0.5s, ease-out',
  },
  smooth: {
    maxHeight: '1000px',
    transition: 'height 0.5s, ease-out',
  },
  toast: {
    height: '26px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    font: 'normal normal normal 12px/14px Roboto',
    maxHeight: '0px',
    overflow: 'hidden',
    transition: 'all 0.1s ease',
  },
  toastOpen: {
    maxHeight: '25px',
    transition: 'all 0.1s ease',
  },
  toastSuccess: {
    backgroundColor: theme.color.oceanGreen,
    color: theme.color.white,
  },
  wrapper: {
    minWidth: '49.5%',
  },
});

export default styles;
