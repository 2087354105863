import styled, { css } from 'styled-components';

export const MailCardWrapper = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  min-height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  > span {
    position: absolute;
    top: 0;
    right: 0;
   }
  > img{
    max-width: 120px;
  }
`;

export const PlatformName = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.primary};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.medium};
    gap: 8px;
    line-height: 19px;
    text-align: center;
  `}
`;
