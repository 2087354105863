import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import StaticMessage from '@/components/Notification/StaticMessage/index';
import ModalOldResellerManagement from '@/components/Modal/ModalOldResellerManagement';
import { ProductsContext } from '@/contexts/Products/context';
import { RESELLER_PRICE_UPDATE_EXPLANATION } from '@/config/urls/supportUrls';
import styles from './styles';


const InvoiceMessage = ({ t, classes }) => {
  const {
    suspendedProducts,
    hasAboutToSuspend,
    toggleShowModal,
  } = useContext(ProductsContext);

  const hasSuspended = suspendedProducts.length > 0 || hasAboutToSuspend;
  return (
    <div className={classes.container}>
      <StaticMessage
        className={(hasSuspended) ? 'critical' : null}
        oldReseller
      >
        <div
          className={classnames(classes.messageButton, {
            [classes.affectedMessageButton]: !hasSuspended,
          })}
          alt={hasSuspended ? t('oldReseller.ResellerSuspendedMessage') : t('oldReseller.ResellerAffectedMessage')}
        >
          <Trans i18nKey={hasSuspended ? 'oldReseller.ResellerSuspendedMessage' : 'oldReseller.ResellerAffectedMessage'}>
            <>0</>
            <a href={RESELLER_PRICE_UPDATE_EXPLANATION} target="_blank" rel="noopener noreferrer" className={classes.listCursos}> 1 </a>
            <>2</>
            <u
              tabIndex="0"
              role="button"
              className={classnames({
                [classes.affectedULink]: !hasSuspended,
                [classes.suspendedULink]: hasSuspended,
              })}
              onClick={() => (toggleShowModal(true))}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  toggleShowModal(true);
                }
              }}
            >
              3
            </u>
            <>4</>
          </Trans>
        </div>

        <ModalOldResellerManagement />
      </StaticMessage>
    </div>
  );
};

export default withI18n()(withStyles(styles)(InvoiceMessage));
