import React, { useState } from 'react';
import Modal from '../../Modal';
import { Input } from 'gatorcomponents';
import changePwValidations from '@/utils/Validators/changePassword';
import Chip from '../Chip';
import * as Styles from './ChangePasswordModal.styles';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_CHANGE_PASSWORD } from '@/redux/actions/actionsTypes';
import { saveChangePassword } from '@/redux/actions/changePassword';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';

const ChangePasswordModal = ({ isOpen, togglePasswordModal }) => {
  const defaultFormData = {
    currentPassword: { value: '', error: '' },
    newPassword: { value: '', error: '' },
    confirmPassword: { value: '', error: '' },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [passwordRules, setPasswordRules] = useState({});
  const [hasRequirements, setHasRequirements] = useState(false);

  const { myData: myDataLocale } = useLocale();
  const changePasswordState = useSelector(state => state.changePassword);
  const changePasswordLocale = myDataLocale.changePassword;
  const dispatch = useDispatch();

  const chips = [
    {
      id: 'hasAtLeastEight',
      label: changePasswordLocale.chips.hasAtLeastEight,
    },
    {
      id: 'hasUpperCase',
      label: changePasswordLocale.chips.hasUpperCase,
    },
    {
      id: 'hasLowerCase',
      label: changePasswordLocale.chips.hasLowerCase,
    },
    {
      id: 'hasSymbol',
      label: changePasswordLocale.chips.hasSymbol,
    },
    {
      id: 'hasNumber',
      label: changePasswordLocale.chips.hasNumber,
    },
  ];

  const validateFields = (field, formData, validations) => {
    const { hasAtLeastEight, matchConfirmation, differentPasswords } = validations;
    const passwordsFilled = hasAtLeastEight && formData.confirmPassword.value;

    const newFormData = { ...formData };

    if (field === 'newPassword' || field === 'currentPassword') {
      newFormData.newPassword.error = '';
    }
    if (field === 'newPassword') {
      newFormData.confirmPassword.error = '';
    }

    if (formData.currentPassword.value && hasAtLeastEight && !differentPasswords) {
      newFormData.newPassword.error = changePasswordLocale.requirementsList.differentPasswords;
    }
    if (passwordsFilled && !matchConfirmation) {
      newFormData.confirmPassword.error = changePasswordLocale.requirementsList.matchConfirmation;
    }

    setFormData(newFormData);
  };

  const validateFormRules = (formData) => {
    const hasLowerCase = changePwValidations.hasLowerCase(formData.newPassword.value);
    const hasUpperCase = changePwValidations.hasUpperCase(formData.newPassword.value);
    const hasNumber = changePwValidations.hasNumber(formData.newPassword.value);
    const hasSymbol = changePwValidations.hasSpecial(formData.newPassword.value);
    const hasAtLeastEight = formData.newPassword.value.length >= 8;
    const matchConfirmation = formData.newPassword.value.length && formData.confirmPassword.value === formData.newPassword.value;
    const differentPasswords = formData.newPassword.value && formData.currentPassword.value && formData.currentPassword.value !== formData.newPassword.value;

    const noValues = !formData.currentPassword.value
    && !formData.newPassword.value
    && !formData.confirmPassword.value;

    const hasAllRequirements = !noValues && (
      hasLowerCase
      && hasUpperCase
      && hasNumber
      && hasSymbol
      && hasAtLeastEight
      && matchConfirmation
      && differentPasswords
    );

    const validations = {
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSymbol,
      hasAtLeastEight,
      matchConfirmation,
      differentPasswords,
      noValues,
    };

    setHasRequirements(hasAllRequirements);

    return validations;
  };

  const handleChange = ({ field, value }) => {
    const newFormData = { ...formData };
    newFormData[field].value = value;
    newFormData[field].error = '';

    const validations = validateFormRules(newFormData);
    validateFields(field, newFormData, validations);

    setPasswordRules(validations);
    setFormData(newFormData);
  };

  const resetFormData = () => {
    setFormData(defaultFormData);
    setPasswordRules({});
    setHasRequirements(false);
  };

  const handleSave = () => {
    const passwords = {
      existingpw: formData.currentPassword.value,
      newpw: formData.newPassword.value,
      confirmpw: formData.confirmPassword.value,
    };

    if (!changePasswordState.loading) {
      dispatch(saveChangePassword(passwords)).then((response) => {
        if (response.type === SUCCESS_CHANGE_PASSWORD) {
          resetFormData();
          togglePasswordModal();
          dispatch(enqueueSnackbar(changePasswordLocale.notifierSuccess, notifierSuccess));
        } else if (!response.error_message && response.notifications.length === 0) {
          dispatch(enqueueSnackbar(changePasswordLocale.notifierError, notifierError));
        }
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      title={changePasswordLocale.modalTitle}
      primaryButtonLabel={changePasswordLocale.buttonLabel}
      secondaryButtonLabel={changePasswordLocale.secondaryButtonLabel}
      primaryButtonAction={handleSave}
      secondaryButtonAction={togglePasswordModal}
      onClose={togglePasswordModal}
      isDisabled={!hasRequirements || changePasswordState.loading}
      isLoading={changePasswordState.loading}
      testId="change-password-modal"
    >
      <Styles.Description>
        {changePasswordLocale.modalDescription()}
      </Styles.Description>

      <Styles.Form>
        <Styles.Password>
          <Styles.Label>{changePasswordLocale.currentPwLabel}</Styles.Label>
          <Input
            type="password"
            size="lg"
            value={formData.currentPassword.value}
            onChange={({ target }) => handleChange({ field: 'currentPassword', value: target.value })}
            placeholder={changePasswordLocale.currentPwPlaceholder}
            errorMessage={formData.currentPassword.error || undefined}
            name="currentPassword"
            testId="currentPassword"
          />
        </Styles.Password>
        <Styles.Password>
          <Styles.Label>{changePasswordLocale.newPwLabel}</Styles.Label>
          <Input
            type="password"
            value={formData.newPassword.value}
            onChange={({ target }) => handleChange({ field: 'newPassword', value: target.value })}
            placeholder={changePasswordLocale.newPwPlaceholder}
            errorMessage={formData.newPassword.error || undefined}
            size="lg"
            name="newPassword"
            testId="newPassword"
          />

          <Input
            type="password"
            value={formData.confirmPassword.value}
            onChange={({ target }) => handleChange({ field: 'confirmPassword', value: target.value })}
            placeholder={changePasswordLocale.repeatNewPwPlaceholder}
            errorMessage={formData.confirmPassword.error || undefined}
            size="lg"
            name="confirmPassword"
            testId="confirmPassword"
          />
        </Styles.Password>
      </Styles.Form>
      <Styles.RequirementsContainer>
        <Styles.RequirementsLabel>{changePasswordLocale.requirementsTitle}</Styles.RequirementsLabel>
        <Styles.Requirements>
          {chips.map(({ id, label }) => (
            <Chip
              key={id}
              id={id}
              label={label}
              isChecked={passwordRules[id]}
            />
          ))}
        </Styles.Requirements>
      </Styles.RequirementsContainer>
    </Modal>
  );
};

export default ChangePasswordModal;
