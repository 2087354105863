import React, { useCallback, useEffect, useState } from 'react';
import NewChat from 'gatorhelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  billingActions, preChatActions, domainsActions, backupActions, sitesActions,
} from '@/redux/modules';
import { COUNTRY, IS_PROD, WHMCS_URL } from '@/config';
import useLocale from '@/hooks/useLocale';
import { useHistory, useLocation } from 'react-router-dom';
import { preChatAnalytics } from '@/analytics/preChat';
import ModalDomainNotContracted from '@/components/Domain/ModalDomainNotContracted';
import ModalDomainNotConfigured from '@/components/Domain/ModalDomainNotConfigured';
import { DuplicateOrdersModal } from '@/components/billing/components/DuplicateOrdersModal';
import { TicketUpgradeVpsModal } from '@/components/Tickets/TicketUpgradeVpsModal';
import {
  CHARLIE_PRECHAT_TRIAGE_SCREEN,
  BRAVO_EMAIL_FLOW,
  BRAVO_CHANGE_PHONE_FLOW,
  XRAY_ENABLED_SUBSCRIPTIONS_IN_DIAGNOSTIC,
  CHARLIE_TICKETS_STATUS_V2,
  CHARLIE_GATORHELPER_FORCE_WEEKEND,
  CHARLIE_TOKEN_REFRESH,
  CHARLIE_PRECHAT_REARANGE_CATEGORIES,
  CHARLIE_PRIORITY_CHAT,
  CHARLIE_NEW_ZENDESK_GATORHELPER,
  CHARLIE_NEW_ZENDESK_CATEGORIES_REARRANGE,
  CHARLIE_ZENDESK_SEND_MESSAGE_BUTTON_TITLE,
  CHARLIE_START_CHAT_MESSAGE,
  PRE_CHAT_ESTIMATED_TIME_TO_CHAT,
  HIDE_CHAT_BUTTON,
  NEW_SUPPORT,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { useTickets } from '@/hooks/tickets';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import logger from '@/utils/logger';
import withErrorBoundary from '@/utils/errorBoundary';
import mountZendeskTags from './utils/mountZendeskTags';

const MODALS = {
  DOMAIN_NOT_CONTRACTED: 'DOMAIN_NOT_CONTRACTED',
  DOMAIN_NOT_CONFIGURED: 'DOMAIN_NOT_CONFIGURED',
  DUPLICATE_ORDERS: 'DUPLICATE_ORDERS',
};

let getDiagnosticInterval = null;
const NewChatButton = ({
  hasActiveChat,
  setHasActiveChat,
  chatTitle,
  widgetIsOpen,
  setWidgetIsOpen,
  setIsTicket,
}) => {
  const [modalToShow, setModalToShow] = useState(null);
  const [chatMessageDispatched, setChatMessageDispatched] = useState(false);
  const [isClickBlocked, setIsClickBlocked] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { preChat: preChatLocale, routes } = useLocale();
  const { requestDiagnostic } = useDiagnostic();

  const preChatState = useSelector(state => state.preChat);
  const summaryState = useSelector(state => state.summary);
  const billingState = useSelector(state => state.billing);
  const { serverTime } = useSelector(state => state.common);

  const newTriageScreen = useFeatureIsOn(CHARLIE_PRECHAT_TRIAGE_SCREEN);
  const enableEmailVerify = useFeatureIsOn(BRAVO_EMAIL_FLOW);
  const enablePhoneVerify = useFeatureIsOn(BRAVO_CHANGE_PHONE_FLOW);
  const enabledSubscriptionInDiagnostic = useFeatureIsOn(XRAY_ENABLED_SUBSCRIPTIONS_IN_DIAGNOSTIC);
  const renderAllTickets = useFeatureIsOn(CHARLIE_TICKETS_STATUS_V2);
  const waitTokenToggle = useFeatureIsOn(CHARLIE_TOKEN_REFRESH);
  const reArrangedCategories = useFeatureIsOn(CHARLIE_PRECHAT_REARANGE_CATEGORIES);
  const useNewZendesk = useFeatureIsOn(CHARLIE_NEW_ZENDESK_GATORHELPER);
  const forceWeekend = useFeatureIsOn(CHARLIE_GATORHELPER_FORCE_WEEKEND);
  const charliePriorityChat = useFeatureIsOn(CHARLIE_PRIORITY_CHAT);
  const startChatMessage = useFeatureValue(CHARLIE_START_CHAT_MESSAGE);
  const sendMessageButtonTitle = useFeatureValue(CHARLIE_ZENDESK_SEND_MESSAGE_BUTTON_TITLE);
  const useNewZendeskCategoriesRearranged = useFeatureIsOn(CHARLIE_NEW_ZENDESK_CATEGORIES_REARRANGE);
  const canShowEstimativeToChat = useFeatureIsOn(PRE_CHAT_ESTIMATED_TIME_TO_CHAT);
  const hideChatButton = useFeatureIsOn(HIDE_CHAT_BUTTON);
  const useNewSupport = useFeatureIsOn(NEW_SUPPORT);
  const { routes: routesLocale } = useLocale();

  const { requestTickets } = useTickets({});

  const featureToggles = {
    newTriageScreen: !newTriageScreen,
    forceWeekend,
    newSelectProducts: true,
    reArrangedCategories,
    charliePriorityChat,
    useNewZendesk,
    useNewZendeskCategoriesRearranged,
    canShowEstimativeToChat,
  };

  const clickOnSendMessageButton = useCallback((chatComponentDocument, inputElement) => {
    const sendBtnElement = chatComponentDocument.querySelector(`[title="${sendMessageButtonTitle}"]`);

    if (!sendBtnElement) {
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
      window.setTimeout(clickOnSendMessageButton, 50);
      return;
    }

    sendBtnElement.click();
    setChatMessageDispatched(true);
  }, [sendMessageButtonTitle]);


  const sendStartChatMessage = useCallback(() => {
    if (widgetIsOpen && !chatMessageDispatched && startChatMessage) {
      if (hasActiveChat) {
        return;
      }

      const chatComponent = document.querySelector(`[title="${chatTitle}"]`);
      const chatComponentDocument = chatComponent && chatComponent.contentWindow && chatComponent.contentWindow.document;
      const inputElement = chatComponentDocument && chatComponentDocument.getElementsByTagName('textarea')[0];

      if (!inputElement) {
        window.setTimeout(sendStartChatMessage, 50);
        return;
      }

      inputElement.innerText = startChatMessage;
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
      inputElement.dispatchEvent(new Event('input', { bubbles: true }));

      if (inputElement.value === startChatMessage) {
        clickOnSendMessageButton(chatComponentDocument, inputElement);
      }

      setHasActiveChat(true);

      const spans = chatComponentDocument && chatComponentDocument.querySelectorAll('span');
      spans && spans.forEach((spanElement) => {
        if (spanElement.textContent === startChatMessage && spanElement.parentElement && spanElement.parentElement.parentElement) {
          // eslint-disable-next-line no-param-reassign
          spanElement.parentElement.parentElement.style.display = 'none';
        }
      });
    }
  }, [widgetIsOpen, chatMessageDispatched, chatTitle, startChatMessage, clickOnSendMessageButton, hasActiveChat, setHasActiveChat]);


  useEffect(() => {
    sendStartChatMessage();
  }, [sendStartChatMessage]);

  // eslint-disable-next-line no-undef
  zE('messenger:on', 'unreadMessages', useCallback((count) => {
    if (count >= 1) {
      if (hasActiveChat && !widgetIsOpen) {
        // eslint-disable-next-line no-undef
        zE('messenger', 'open');
        setWidgetIsOpen(true);
      }

      if (!hasActiveChat) {
        setHasActiveChat(true);
        setWidgetIsOpen(true);
        // eslint-disable-next-line no-undef
        zE('messenger', 'open');
      }
    }
  }, [hasActiveChat, setHasActiveChat, widgetIsOpen, setWidgetIsOpen]));

  // eslint-disable-next-line no-undef
  zE('messenger:on', 'close', () => {
    setWidgetIsOpen(false);
  });

  const emailVerify = enableEmailVerify ? summaryState.emailVerified : true;
  const phoneVerify = enablePhoneVerify ? summaryState.phoneNumberVerified : true;

  const contactsVerified = emailVerify && phoneVerify;

  const newPendingOrdersList = preChatState.pendingOrders && preChatState.pendingOrders.filter(order => order.status === 'pending_analysis');

  const pendingOrdersList = newPendingOrdersList;

  const verifyDaysDifference = (date, days) => {
    try {
      const formatDate = new Date(date);
      const today = new Date();

      const daysDifference = (today - formatDate) / (1000 * 60 * 60 * 24);

      return daysDifference < days;
    } catch (e) {
      logger.error('Error on NewChatButton - method verifyDaysDifference', e);
    }
  };

  const hostingsList = preChatState.productsList.hostings && preChatState.productsList.hostings.filter(hosting => hosting.status !== 'Cancelled' || verifyDaysDifference(hosting.due_date, 30));
  const domainsList = preChatState.productsList.domains && preChatState.productsList.domains.filter(domain => domain.status !== 'Cancelled' || verifyDaysDifference(domain.due_date, 30));

  const productsList = { hostings: hostingsList, domains: domainsList };

  const getDiagnostic = useCallback((isOpening) => {
    try {
      renderAllTickets && requestTickets(true);
      isOpening && dispatch(preChatActions.productByStatus.request({ withCancelled: true, withPending: false, waitToken: waitTokenToggle }));
    } catch (e) {
      logger.error('Error on NewChatButton - method getDiagnostic', e);
    }
  }, [dispatch, renderAllTickets, requestTickets, waitTokenToggle]);

  const handleSetScreenToShow = useCallback((screen) => {
    dispatch(preChatActions.preChat.setScreen(screen));
  }, [dispatch]);

  const handleOpenChat = useCallback((isOpening, isToOpenSidePanel) => {
    if (!hasActiveChat || (isToOpenSidePanel && preChatState.showSidePanel)) {
      try {
        if (!contactsVerified) {
          handleSetScreenToShow('triage');
          dispatch(preChatActions.preChat.setSessionOpen(true));
          getDiagnostic(isOpening);
        }
        if (contactsVerified && preChatState && !preChatState.open && !preChatState.sessionOpen) {
          handleSetScreenToShow('diagnostic');
          dispatch(preChatActions.preChat.setSessionOpen(true));
          getDiagnostic(isOpening);
        }

        dispatch(preChatActions.preChat.setOpen(!preChatState.open));
      } catch (e) {
        logger.error('Error on NewChatButton - method handleOpenChat', e);
      }
    }

    if (hasActiveChat && !isToOpenSidePanel) {
      if (!widgetIsOpen) {
        // eslint-disable-next-line no-undef
        zE('messenger', 'open');
        setWidgetIsOpen(true);
      }
      if (widgetIsOpen) {
        // eslint-disable-next-line no-undef
        zE('messenger', 'close');
        setWidgetIsOpen(false);
      }
    }
  }, [contactsVerified, dispatch, getDiagnostic, handleSetScreenToShow, preChatState, hasActiveChat, widgetIsOpen, setWidgetIsOpen]);

  const controlHandleOpenChat = (isOpening, isToOpenSidePanel) => {
    if (!isClickBlocked) {
      handleOpenChat(isOpening, isToOpenSidePanel);
      setIsClickBlocked(true);
      setTimeout(() => {
        setIsClickBlocked(false);
      }, 1550);
    }
  };

  useEffect(() => {
    if (preChatState.chatDisplay) {
      controlHandleOpenChat(true);
      dispatch(preChatActions.preChat.handleChatDisplay());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preChatState.chatDisplay]);

  useEffect(() => {
    if (preChatState.showSidePanel) {
      controlHandleOpenChat(true, true);
      dispatch(preChatActions.preChat.handleChatDisplay());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preChatState.showSidePanel]);

  const handleClosePreChat = () => {
    dispatch(preChatActions.preChat.setOpen(false));
    dispatch(preChatActions.preChat.setSessionOpen(false));
    dispatch(preChatActions.preChat.setShowSidePanel());
  };

  const handleStartZenDeskChat = ({
    department,
    primaryReason,
    secondaryReason,
    product,
    hostingId,
    domain,
    isTicket,
    priority,
  }) => {
    try {
      const { id } = summaryState;

      if (isTicket) {
        setIsTicket(true);
        localStorage.setItem('hasTicketOpen', true);
      }

      const tags = mountZendeskTags({
        department,
        primaryReason,
        secondaryReason,
        product,
        whmcsId: id,
        departmentLocales: preChatLocale.zopimDepartments,
        priority,
        isTicket,
      });
      // eslint-disable-next-line no-undef
      zE('messenger:set', 'conversationFields', [
        { id: IS_PROD ? '27835972869267' : '26102773393171', value: domain },
        { id: IS_PROD ? '28285611660947' : '26102798680595', value: String(hostingId) },
      ]);
      // eslint-disable-next-line no-undef
      zE('messenger:set', 'conversationTags', tags);
      setWidgetIsOpen(true);

      // eslint-disable-next-line no-undef
      zE('messenger', 'open');

      dispatch(preChatActions.preChat.setOpen(false));
    } catch (e) {
      logger.error('Error on NewChatButton - method handleStartZenDeskChat', e);
    }
  };

  const OpenNewTriageScreen = () => {
    dispatch(preChatActions.weekDay.get());
    handleSetScreenToShow('triage');
  };

  const goToChatTriage = () => {
    preChatAnalytics.goToChatTriage();
    !newTriageScreen ? OpenNewTriageScreen() : handleStartZenDeskChat({});
  };

  const redirectToSubscriptionManagement = ({ productId, productType }) => {
    try {
      const route = `${routes.subscriptions}${routes.manageSubscription}/${productId}/${productType}`;
      history.push(route);

      handleOpenChat();

      setTimeout(() => {
        dispatch(preChatActions.preChat.setScreen('problemSolved'));
      }, 2000);
    } catch (e) {
      logger.error('Error on NewChatButton - method redirectToSubscriptionManagement', e);
    }
  };

  const handleCloseModal = () => {
    setModalToShow(null);
    handleSetScreenToShow('problemSolved');
    dispatch(preChatActions.preChat.setOpen(true));
  };

  const goToTrustReactivationModal = (invoiceId) => {
    try {
      if (preChatState && !preChatState.trustReactivationInvoiceId) {
        dispatch(preChatActions.trustReactivation.setInvoiceId(invoiceId));
      }
      let trustReactivationPath = '';
      const subscriptionReactivationList = preChatState.trustReactivation.filter(subscription => subscription.invoice_id === preChatState.trustReactivationInvoiceId);
      if (subscriptionReactivationList.length === 1) {
        trustReactivationPath = `${routes.subscriptions}${routes.manageSubscription}/${subscriptionReactivationList[0].hosting_id}/hosting`;
      } else {
        trustReactivationPath = `${routes.subscriptions}`;
      }
      dispatch(preChatActions.trustReactivation.redirect(true));
      dispatch(preChatActions.preChat.setOpen(false));
      history.push('/');
      setTimeout(() => {
        history.push(trustReactivationPath);
      }, 10);
    } catch (e) {
      logger.error('Error on NewChatButton - method goToTrustReactivationModal', e);
    }
  };

  const goToInvoicePaymentModal = (invoiceId) => {
    try {
      const unpaidInvoicePath = `${routes.invoices}${routes.unpaid}/${invoiceId}`;
      dispatch(preChatActions.preChat.setOpen(false));
      dispatch(preChatActions.invoice.set.invoiceId(invoiceId));
      history.push(unpaidInvoicePath);
    } catch (e) {
      logger.error('Error on NewChatButton - method goToInvoicePaymentModal', e);
    }
  };

  const getDiagnosticProps = () => {
    const reloadDiagnostic = () => {
      dispatch(preChatActions.diagnostic.reload());
      getDiagnostic();
    };

    const goToTos = () => {
      try {
        dispatch(preChatActions.preChat.setOpen(false));
        dispatch(preChatActions.tos.redirect(true));

        if (preChatState.tos.tickets.length > 1) {
          if (location.pathname !== routes.help) {
            history.push(routes.help);
          }
          preChatAnalytics.tosModalDisplayed();
        } else if (preChatState.tos.tickets.length === 1) {
          const ticketPath = `${routes.ticket}/${preChatState.tos.tickets[0].id}`;
          if (location.pathname !== ticketPath) {
            history.push(ticketPath);
          }
        }
      } catch (e) {
        logger.error('Error on NewChatButton - method goToTos', e);
      }
    };

    const goToSubscriptionAnalysis = (productId, productType) => {
      try {
        redirectToSubscriptionManagement({ productId, productType });

        setTimeout(() => {
          dispatch(preChatActions.preChat.setOpen(true));
        }, 2000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToSubscriptionAnalysis', e);
      }
    };

    const handleModalToShow = (modal) => {
      dispatch(preChatActions.preChat.setOpen(false));
      setModalToShow(modal);
    };

    return ({
      loadingDiagnostic: preChatState.loading,
      requestSuccess: preChatState.diagnosticLoaded,
      helpLinks: preChatLocale.diagnostic.helpLinks,
      invoices: preChatState.invoices,
      refunds: preChatState.refunds,
      customer: preChatState.customer,
      domainsPropagation: preChatState.domainsPropagation,
      tosTickets: preChatState.tos.tickets,
      goToSubscriptionAnalysis,
      pendingOrders: pendingOrdersList,
      reloadDiagnostic,
      goToInvoicePaymentModal,
      setModalToShow: handleModalToShow,
      goToTos,
      goToDuplicateOrdersModal: () => handleModalToShow(MODALS.DUPLICATE_ORDERS),
      hasDuplicateOrders: preChatState.duplicateOrders && preChatState.duplicateOrders.length,
    });
  };

  const getTriageProps = () => {
    const goToSubscriptionCancelModal = (productId, productType) => {
      try {
        redirectToSubscriptionManagement({ productId, productType });
        dispatch(preChatActions.subscription.setSubscriptionToCancel(productId));
        setTimeout(() => {
          dispatch(preChatActions.preChat.setScreen('problemSolved'));
        }, 1000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToSubscriptionCancelModal', e);
      }
    };

    const goToSubscriptionManagement = ({ productId, productType }) => {
      try {
        redirectToSubscriptionManagement({ productId, productType });
        dispatch(preChatActions.subscription.redirectManageSubscription(true));
        setTimeout(() => {
          dispatch(preChatActions.preChat.setScreen('problemSolved'));
        }, 1000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToSubscriptionManagement', e);
      }
    };

    const goToPlanOrCycleChange = ({ planId }) => {
      window.open(`${WHMCS_URL}/index.php?m=custom_upgrade&action=selectupgrade&id=${planId}`, '_blank');
      handleSetScreenToShow('problemSolved');
    };

    const goToUpgradeVPSModal = (productId) => {
      try {
        dispatch(preChatActions.preChat.setOpen(false));
        dispatch(preChatActions.vps.redirect(true));
        dispatch(preChatActions.vps.productId(productId));

        setTimeout(() => {
          dispatch(preChatActions.preChat.setScreen('problemSolved'));
        }, 2000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToUpgradeVPSModal', e);
      }
    };

    const goDirectToChangePaymentMethod = (productId, productType) => {
      dispatch(preChatActions.subscription.setSubscriptionToChangePaymentMethod(productId));

      redirectToSubscriptionManagement({ productId, productType });
    };

    const shouldFetchSubscriptions = billingState && billingState.subscriptions && billingState.subscriptions.items && !billingState.subscriptions.items.length;

    const goToChangePaymentMethod = (productId, productType) => {
      try {
        if (shouldFetchSubscriptions) {
          dispatch(billingActions.subscriptions.request({ preChatRedirect: () => goDirectToChangePaymentMethod(productId, productType) }));
        } else {
          goDirectToChangePaymentMethod(productId, productType);
        }
      } catch (e) {
        logger.error('Error on NewChatButton - method goToChangePaymentMethod', e);
      }
    };

    const goToHoldDomain = (invoiceId) => {
      try {
        dispatch(preChatActions.invoice.set.holdDomainRedirect(true));
        goToInvoicePaymentModal(invoiceId);

        setTimeout(() => {
          dispatch(preChatActions.preChat.setScreen('problemSolved'));
        }, 2000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToHoldDomain', e);
      }
    };

    const requestSelectedProductDetails = (data) => {
      dispatch(preChatActions.productDetails.get(data));
    };

    const goToGenerateEppKey = (domainToGenKey) => {
      try {
        dispatch(preChatActions.eppKeyGen.onFlow(true));
        const domainDetailPath = `${routes.domains}${routes.domaindetail}/${domainToGenKey.domainId}`;
        history.push(domainDetailPath);
        handleOpenChat();
        dispatch(domainsActions.modalEppKey.open(domainToGenKey.domain, domainToGenKey.domainId));

        setTimeout(() => {
          handleSetScreenToShow('problemSolved');
        }, 2000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToGenerateEppKey', e);
      }
    };

    const goToBackupModal = ({ id, category }) => {
      try {
        history.push(`${routesLocale.products}${routesLocale.productdetail}/${id}${routesLocale.backup}/wizard/${category}`);

        dispatch(backupActions.set.redirectFromPreChat(true));
        handleOpenChat();
        setTimeout(() => {
          handleSetScreenToShow('problemSolved');
        }, 1000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToBackupModal', e);
      }
    };


    const goToSitesWizard = ({ domain }) => {
      try {
        history.push(`${routes.sitesPage}${routes.sitesCreate}/${domain}`);

        dispatch(sitesActions.chat.setRedirectFromPreChat(true));
        handleOpenChat();
        setTimeout(() => {
          handleSetScreenToShow('problemSolved');
        }, 1000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToSitesWizard', e);
      }
    };

    const goToSslPage = ({ hostingId }) => {
      try {
        window.open(`${WHMCS_URL}/index.php?m=ssl_addon&action=request1&hosting_id=${hostingId}`, '_blank');
        setTimeout(() => {
          handleSetScreenToShow('problemSolved');
        }, 1000);
      } catch (e) {
        logger.error('Error on NewChatButton - method goToSslPage', e);
      }
    };

    const datadog = {
      priority: () => logger.info('chat - departamento prioritário - clientarea'),
    };

    return ({
      goToSubscriptionCancelModal,
      startZendeskChat: handleStartZenDeskChat,
      productsList,
      trustReactivation: preChatState.trustReactivation,
      goToPlanOrCycleChange,
      goToUpgradeVPSModal,
      goToChangePaymentMethod,
      goDirectToChangePaymentMethod,
      goToGenerateEppKey,
      shouldFetchSubscriptions,
      goToHoldDomain,
      requestSelectedProductDetails,
      loadingSelectedProductDetails: preChatState.productDetailsLoading,
      selectedProductDetail: preChatState.productDetails,
      weekDay: preChatState.weekDay,
      goToBackupModal,
      goToSitesWizard,
      goToSslPage,
      goToSubscriptionManagement,
      datadog,
    });
  };

  const setGetDiagnosticInterval = useCallback(() => {
    const fifteenMinutes = 1000 * 60 * 30;
    clearInterval(getDiagnosticInterval);

    getDiagnosticInterval = setInterval(() => {
      requestDiagnostic('newChatButton2');
      renderAllTickets && requestTickets(true);
    }, fifteenMinutes);
  }, [requestTickets, renderAllTickets, requestDiagnostic]);

  useEffect(() => {
    requestDiagnostic('newChatButton1');
    renderAllTickets && requestTickets(true);
    setGetDiagnosticInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enabledSubscriptionInDiagnostic, requestTickets, renderAllTickets, waitTokenToggle]);

  const resetDiagnosticInterval = useCallback(() => {
    if (preChatState.open) {
      setGetDiagnosticInterval();
    }
  }, [preChatState.open, setGetDiagnosticInterval]);

  useEffect(() => {
    resetDiagnosticInterval();
  }, [resetDiagnosticInterval]);


  const goToWizardDns = useCallback((id) => {
    try {
      history.push(`${routes.domains}${routes.dnsWizard}/${id}`);
      dispatch(preChatActions.reservedDomain.onFlow(true));

      handleOpenChat();
      setTimeout(() => {
        handleSetScreenToShow('problemSolved');
      }, 1000);
    } catch (e) {
      logger.error('Error on NewChatButton - method goToWizardDns', e);
    }
  }, [dispatch, handleOpenChat, handleSetScreenToShow, history, routes]);

  return (
    <>
      <NewChat
        brand={COUNTRY}
        handleOpenChat={isOpening => controlHandleOpenChat(isOpening)}
        chatIsOpen={preChatState.open}
        clientAreaProps={{ diagnosticProps: getDiagnosticProps(), triageProps: getTriageProps() }}
        application={useNewSupport ? 'clientareav2' : preChatState.application}
        screenToShow={preChatState.screenToShowInPreChat}
        contactsVerified={contactsVerified}
        setScreenToShow={handleSetScreenToShow}
        closePreChat={handleClosePreChat}
        featureToggles={featureToggles}
        analyticEvents={preChatAnalytics}
        goToChatTriage={goToChatTriage}
        goToTrustReactivationModal={goToTrustReactivationModal}
        goToWizardDns={goToWizardDns}
        sessionOpen={preChatState.sessionOpen}
        loadingOfferContext={preChatState.loadingOfferContext}
        hideButton={!hasActiveChat && hideChatButton}
        serverTime={serverTime}
      />

      {modalToShow === MODALS.DOMAIN_NOT_CONTRACTED && <ModalDomainNotContracted hideModal={handleCloseModal} isPreChat />}
      {modalToShow === MODALS.DOMAIN_NOT_CONFIGURED && <ModalDomainNotConfigured hideModal={handleCloseModal} isPreChat />}
      {modalToShow === MODALS.DUPLICATE_ORDERS && <DuplicateOrdersModal hideModal={handleCloseModal} />}
      <TicketUpgradeVpsModal />
    </>
  );
};

export default withErrorBoundary(NewChatButton, 'NewChatButton');
