import React from 'react';
import { ModalDomainCanceled } from '../ModalDomainCanceled';
import { ModalPlanCanceled } from '../ModalPlanCanceled';
import { ModalCreateSite } from '../ModalCreateSite';
import { ModalExcludeContent } from '../ModalExcludeContent';
import { TitanAdvertisementModal } from '@/pages/sites/components/TitanAdvertisementModal';
import { MODAL_TYPE } from '@/hooks/sites/sitesList/useMountNotificationModal/useMountNotificationModal.enum';
import { createSitesWithSpecialistAnalytics } from '@/analytics/';
import { contractWithSpecialistURL } from '../../SitesHandler/SitesHandler.enum';
import { ModalCreateSiteWithSpecialist } from '../ModalCreateSiteWithSpecialist';
import ModalDomainNotConfigured from '@/components/Domain/ModalDomainNotConfigured';
import { DnsInProgressModal } from '../DnsInProgressModal';
import { ModalEcommercePlusOffer } from '../ModalEcommercePlusOffer';

const SitesHandlerModals = ({
  sitesPagesLocale,
  modalDomains,
  handleOnCloseWarningModal,
  handleCloseConfigureDomainModal,
  shouldRenderConfigureDomainModal,
  shouldRenderNotificationModal,
  modalWarningType,
  enabledNewSiteModal,
  siteInExclusion,
  loadingExclusion,
  excludeSiteBuilder,
  setSiteInExclusion,
  enabledNewStatusDomain,
  eligibleForTitanUpgrade,
  eligibleDomainOptions,
  ecommercePlusModalAnalytics,
  displayModalTitanAdvertisementHandler,
  setDisplayModalTitanAdvertisementHandler,
  advertisementType,
  enableNewCreateSiteModalBehavior,
  enabledCreateSiteWithSpecialistModal,
  specialistMvpButtonCreate,
  specialistMvpDescription,
  specialistMvpTitle,
  showDnsInProgressModal,
  openWordpressLink,
  dnsInProgressDomain,
  setShowDnsInProgressModal,
}) => {
  const modalPlanCanceled = () => {
    const defaultProps = {
      button: sitesPagesLocale.warningModals.planCanceled.button,
      title: sitesPagesLocale.warningModals.planCanceled.title,
      contentDomainName: modalDomains,
      contentRedirect: sitesPagesLocale.warningModals.planCanceled.contentRedirect,
      handleOnCloseWarningModal,
    };
    return { ...defaultProps };
  };

  const modalDomainCanceledProps = () => {
    const defaultProps = {
      button: sitesPagesLocale.warningModals.domainCanceled.button,
      title: sitesPagesLocale.warningModals.domainCanceled.title,
      contentDomainName: modalDomains.length === 1 ? sitesPagesLocale.warningModals.domainCanceled.contentDomainName(modalDomains) : sitesPagesLocale.warningModals.domainCanceled.contentsDomainName(modalDomains),
      contentInfo: modalDomains.length === 1 ? sitesPagesLocale.warningModals.domainCanceled.contentInfo : sitesPagesLocale.warningModals.domainCanceled.contentsInfo,
      contentRedirect: sitesPagesLocale.warningModals.domainCanceled.contentRedirect,
      handleOnCloseWarningModal,
    };
    return { ...defaultProps };
  };

  const modalCreateSiteProps = () => {
    const defaultProps = {
      title: enableNewCreateSiteModalBehavior ? sitesPagesLocale.warningModals.createSiteWithPartners.title : sitesPagesLocale.warningModals.createSite.title,
      description: enableNewCreateSiteModalBehavior ? sitesPagesLocale.warningModals.createSiteWithPartners.description : sitesPagesLocale.warningModals.createSite.description,
      toolsText: sitesPagesLocale.warningModals.createSite.toolsText,
      contentDomainName: modalDomains,
      buttonCreate: enableNewCreateSiteModalBehavior ? sitesPagesLocale.warningModals.createSiteWithPartners.buttonCreate : sitesPagesLocale.warningModals.createSite.buttonCreate,
      buttonClose: enableNewCreateSiteModalBehavior ? sitesPagesLocale.warningModals.createSiteWithPartners.buttonClose : sitesPagesLocale.warningModals.createSite.buttonClose,
      handleOnCloseWarningModal,
      enableNewCreateSiteModalBehavior,
      createSitesWithSpecialistAnalytics,
    };
    return { ...defaultProps };
  };

  const modalExcludeContentProps = () => {
    const defaultProps = {
      title: sitesPagesLocale.modalExclusion.title,
      descriptor: [
        sitesPagesLocale.modalExclusion.descriptor[0],
        sitesPagesLocale.modalExclusion.descriptor[1],
        sitesPagesLocale.modalExclusion.descriptor[2],
      ],
      radioText: sitesPagesLocale.modalExclusion.radioText,
      cancelLabel: sitesPagesLocale.modalExclusion.cancelLabel,
      continueLabel: sitesPagesLocale.modalExclusion.continueLabel,
      site: siteInExclusion,
      continueExclusion: e => excludeSiteBuilder(e),
      setSiteInExclusion: e => setSiteInExclusion(e),
      loadingExclusion,
    };
    return { ...defaultProps };
  };

  const modalDnsInProgressProps = () => {
    const defaultProps = {
      onClose: () => setShowDnsInProgressModal(false),
      buttonClick: () => openWordpressLink(dnsInProgressDomain),
    };
    return { ...defaultProps };
  };

  const titanAdvertisementModalProps = () => {
    const defaultProps = {
      domainOptions: eligibleDomainOptions,
      displayModal: displayModalTitanAdvertisementHandler,
      setDisplayModal: setDisplayModalTitanAdvertisementHandler,
      typeAdvertisement: advertisementType,
    };
    return { ...defaultProps };
  };

  const getModalCreateSiteWithSpecialistProps = () => {
    const defaultProps = {
      buttonCreate: specialistMvpButtonCreate,
      description: specialistMvpDescription,
      title: specialistMvpTitle,
      createSitesWithSpecialistAnalytics,
      enabledCreateSiteWithSpecialistModal,
    };

    const handleOnClose = (action) => {
      const rules = {
        createWithSpecialist: () => {
          window.open(contractWithSpecialistURL);
          createSitesWithSpecialistAnalytics.createSiteModalCreate('uniqueMvp');
        },
        close: () => {
          createSitesWithSpecialistAnalytics.createSiteModalClose('uniqueMvp');
        },
      };

      rules[action]();

      handleOnCloseWarningModal && handleOnCloseWarningModal();
    };

    return {
      ...defaultProps,
      handleOnCloseWarningModal: handleOnClose,
    };
  };

  const modalEcommercePlusOfferProps = () => {
    const defaultProps = {
      title: sitesPagesLocale.warningModals.ecommercePlusOffer.title,
      buttonLabel: sitesPagesLocale.warningModals.ecommercePlusOffer.continue,
      backButtonLabel: sitesPagesLocale.warningModals.ecommercePlusOffer.skip,
      descriptionFirst: sitesPagesLocale.warningModals.ecommercePlusOffer.descriptionFirst,
      descriptionSecond: sitesPagesLocale.warningModals.ecommercePlusOffer.descriptionSecond,
      onClose: handleOnCloseWarningModal,
      contentDomainName: modalDomains,
      onModalOpen: ecommercePlusModalAnalytics.ecommercePlusModalOpen,
    };
    return { ...defaultProps };
  };

  if (shouldRenderConfigureDomainModal) {
    return <ModalDomainNotConfigured hideModal={handleCloseConfigureDomainModal} />;
  }

  if (shouldRenderNotificationModal) {
    switch (modalWarningType) {
      case MODAL_TYPE.PLANCANCELED:
        return <ModalPlanCanceled {...modalPlanCanceled()} />;
      case MODAL_TYPE.DOMAINCANCELED:
        return <ModalDomainCanceled {...modalDomainCanceledProps()} />;
      case MODAL_TYPE.ECOMMERCEPLUS:
        return <ModalEcommercePlusOffer {...modalEcommercePlusOfferProps()} />;
      case MODAL_TYPE.NEWSITE:
        if (enabledNewSiteModal) {
          return <ModalCreateSite {...modalCreateSiteProps()} />;
        }
        break;
      case MODAL_TYPE.SPECIALIST:
        if (enabledCreateSiteWithSpecialistModal) {
          return <ModalCreateSiteWithSpecialist {...getModalCreateSiteWithSpecialistProps()} />;
        }
        break;
      default: break;
    }
  }

  return (
    <>
      {enabledNewStatusDomain && eligibleForTitanUpgrade && (
      <TitanAdvertisementModal {...titanAdvertisementModalProps()} />
      )}

      {siteInExclusion && (
      <ModalExcludeContent {...modalExcludeContentProps()} />
      )}

      {showDnsInProgressModal && (
      <DnsInProgressModal {...modalDnsInProgressProps()} />
      )}
    </>
  );
};

export default SitesHandlerModals;
