import { useSelector, useDispatch } from 'react-redux';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import { dnsWizardActions } from '@/redux/modules/dnsWizard';

const formatConfigurationFromSelectedPlan = (selectedPlan, platformId) => ({
  hostingId: selectedPlan.id,
  platformId,
  selectedPlan,
});

const configureHostgatorPlan = (dispatch, platformId, currentConfiguration, selectedPlanId = null, hostingPlans = []) => {
  const selectedConfiguration = hostingPlans.find(e => (e.id === selectedPlanId));
  if (currentConfiguration.hostingId !== selectedConfiguration.id) {
    dispatch(dnsWizardActions.defineConfiguration.set(formatConfigurationFromSelectedPlan(selectedConfiguration, platformId)));
  }
};

const useSetConfiguration = (hostingType, selectedPlanId, platformId) => {
  const dispatch = useDispatch();
  const { hostGatorAvailablePlans, selectedConfiguration: currentConfiguration } = useSelector(state => state.dnsWizard);
  const { hostingPlans } = hostGatorAvailablePlans;

  if (HOSTING_PLATFORM_TYPES.HOSTGATOR === hostingType && selectedPlanId !== null) {
    configureHostgatorPlan(dispatch, platformId, currentConfiguration, selectedPlanId, hostingPlans);
  }
};

export default useSetConfiguration;
