import React from 'react';
import * as Styles from './OrderProcessing.styles';

const OrderProcessing = ({
  testId = 'order-processing',
  text = '',
}) => (
  <Styles.Wrapper data-testid={testId}>
    <Styles.Loader />
    <Styles.Description>
      {text}
    </Styles.Description>
  </Styles.Wrapper>
);

export default OrderProcessing;
