
import React, { useState } from 'react';
import * as Styles from './LastScans.styles';
import useLocale from '@/hooks/useLocale';
import {
  Button, Skeleton, Tag,
} from 'gatorcomponents';
import { UpgradeBanner } from '../UpgradeBanner';

const LastScans = ({
  scanHistory, handleSignSitelock, seeHowToFix, isLoading, isFree, handleGoToFTP, hasFTPConfigurations,
}) => {
  const [historiesToShowPage, setHistoriesToShowPage] = useState(1);
  const { security: securityLocale } = useLocale();
  const lastScansLocale = securityLocale.detailsPage.tabs.last_scans;
  const canShowLoadMoreButton = scanHistory && scanHistory.length > historiesToShowPage * 5;

  const isSafe = scan => scan.filesFixed === scan.filesNeedFix;

  const loadMoreScanHistory = () => {
    setHistoriesToShowPage(historiesToShowPage + 1);
  };

  const upgradeBannerProps = () => ({
    bannerLocale: lastScansLocale.signSitelockCard,
    handleSignSitelock,
  });

  if (isLoading) {
    return (
      <Styles.SkeletonContainer>
        <Skeleton variant="paragraph" />

        <Skeleton variant="tableSimple" />
      </Styles.SkeletonContainer>
    );
  }

  return (
    <Styles.Wrapper>
      <Styles.Description>
        {lastScansLocale.description({ handleSignSitelock, seeHowToFix, isFree })}
      </Styles.Description>

      {(isFree || hasFTPConfigurations) && !scanHistory.length && (
        <Styles.EmptyStateWrapper>
          {lastScansLocale.emptyStateLabel}
        </Styles.EmptyStateWrapper>
      )}

      {!isFree && !hasFTPConfigurations && scanHistory && !scanHistory.length && (
        <Styles.ConfigureFTPWrapper>
          <Styles.ConfigureFTPAlert>
            {lastScansLocale.emptyScans}
          </Styles.ConfigureFTPAlert>
          <Button
            label={lastScansLocale.goToFTP}
            onClick={handleGoToFTP}
          />
        </Styles.ConfigureFTPWrapper>
      )}

      {scanHistory && !!scanHistory.length && scanHistory.slice(0, historiesToShowPage * 5).map(scan => (
        <Styles.Line key={`scan-${scan.scanDate}`}>
          {Object.keys(scan).map(key => (
            <Styles.Data key={key + scan.scanDate}>
              <Styles.DataTitle>
                {lastScansLocale[key]}
              </Styles.DataTitle>
              <Styles.DataValue>
                {scan[key]}
              </Styles.DataValue>
            </Styles.Data>
          ))}
          <Styles.Data isStatus key={`scan-status-${scan.scanDate}`}>
            <Styles.DataTitle>
              {lastScansLocale.status}
            </Styles.DataTitle>
            <Styles.DataValue>
              <Tag label={isSafe(scan) ? lastScansLocale.safe : lastScansLocale.unsafe} variant={isSafe(scan) ? 'active' : 'problem'} rounded={false} positionStatic />
            </Styles.DataValue>
          </Styles.Data>
        </Styles.Line>
      ))}

      {canShowLoadMoreButton && (
        <Styles.ButtonWrapper>
          <Button
            label={lastScansLocale.loadMore}
            onClick={loadMoreScanHistory}
            variant="secondary"
            size="medium"
          />
        </Styles.ButtonWrapper>
      )}

      {isFree && <UpgradeBanner {...upgradeBannerProps()} />}
    </Styles.Wrapper>
  );
};

export default LastScans;
