/* eslint-disable react/no-did-update-set-state */
import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button, MenuItem, Select, OutlinedInput, FormControl, InputLabel, Dialog, withMobileDialog,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import invoiceType from '@/types/invoiceType';
import CreditCardDialog from '@/components/CreditCard/CreditCardDialog';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import Loader from '@/components/Layout/Loader';
import InvoiceItem from '@/components/Invoices/InvoiceItem';
import ModalCheckData from '@/components/Invoices/ModalCheckData';
import {
  BOLETO, CREDIT_CARD, PAYPAL, PARC_2X, PARC_3X, PARC_4X, PARC_5X, PARC_6X,
  BRASPAG_PARC_1X, BRASPAG_PARC_2X, BRASPAG_PARC_3X, BRASPAG_PARC_4X, BRASPAG_PARC_5X,
  BRASPAG_PARC_6X, BRASPAG_PARC_7X, BRASPAG_PARC_8X, BRASPAG_PARC_9X, BRASPAG_PARC_10X,
  BRASPAG_PARC_11X, BRASPAG_PARC_12X, CREDIT_CARD_ES, BOLETO_CL_CO, BOLETO_MX, GATORPAY_BOLETO, GATORPAY_PAYPAL,
} from '@/config/billing/paymentMethods';
import { getPaymentMethod } from '@/redux/actions/paymentMethod';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { BOLETO_URL } from '@/config/whmcsUrls';
import { INVOICES_STATUS, INVOICES_STATUS_WITHOUT_CAPITALIZE } from '@/config/invoices/invoiceStatus';
import { invoiceUnpaidCardTagManager } from '@/utils/ThirdParties/tagManager';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import { notifierError } from '@/utils/Application/notifier';
import { payTicketInvoiceForES, getBoletoUrl, checkBoletoUserData } from '@/redux/actions/invoices';
import { RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES, ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES } from '@/redux/actions/actionsTypes';
import { IS_PROD, COUNTRY } from '@/config/index';
import PaypalDialog from '@/components/Paypal/PaypalDialog';
import TicketDialog from '@/components/Tickets/TicketDialog';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { billingAnalytics } from '@/analytics';
import styles from './styles';
import { PaymentModal } from '@/components/billing/subscriptions/PaymentModal';
import { OriginCalling } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';
import { format } from 'date-fns';
import { ExpiredTimePixModal } from '@/components/billing/subscriptions/ExpiredTimePixModal';
import { PaymentSuccessPixModal } from '@/components/billing/subscriptions/PaymentSuccessPixModal';
import { GenerateErrorPixModal } from '@/components/billing/subscriptions/GenerateErrorPixModal';
import { preChatActions } from '@/redux/modules';
import { TicketRedirectModal } from '@/components/billing/subscriptions/TicketRedirectModal';
import logger from '@/utils/logger/logger';

const preChatSetOpen = preChatActions.preChat.setOpen;
const preChatSetScreen = preChatActions.preChat.setScreen;
const setPreChatInvoiceId = preChatActions.invoice.set.invoiceId;
const setPreChatBillingGenerated = preChatActions.trustReactivation.billingGenerated;
const setPreChatTrustReactivationInvoiceId = preChatActions.trustReactivation.setInvoiceId;
const getDiagnostic = preChatActions.diagnostic.get;
class UnpaidInvoiceItem extends Component {
  state = {
    openDialog: false,
    openPaymentModal: false,
    openDialogPaypal: false,
    openDialogTicket: false,
    openDialogDataCheck: false,
    openExpiredTimePixModal: false,
    openGenerateErrorPixModal: false,
    openPaymentSuccessPixModal: false,
    openTicketModal: false,
    isOriginSavePDF: false,
    urlTicket: '',
    hasQRCodeGenerated: false,
    boletoDataCheckInvoiceId: '',
    pixCodeGenerated: '',
    qRCodeGenerated: '',
    urlBoletoBraspag: '',
    paymentMethod: '',
    warningSize: '',
    paymentTicketOnEs: {
      loadGetUrlPaymentTicketOnEs: false,
      urlPaymentTicketOnEs: '',
      errorOnGetUrl: false,
    },
    redirecting: false,
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleMatchMedia);
  }

  handleOpen = (dialog) => {
    this.setState(prevState => ({
      ...prevState,
      [dialog]: true,
    }));
  }

  handleClose = () => {
    this.setState({ openDialog: false });
    this.openPreChat();
  }


  handleChangePaymentMethod = (event) => {
    const { invoice } = this.props;

    this.setState({ paymentMethod: event.target.value });
    if (event.target.value === BOLETO_CL_CO || event.target.value === BOLETO_MX) {
      this.setState({
        paymentTicketOnEs: {
          loadGetUrlPaymentTicketOnEs: true,
          urlPaymentTicketOnEs: '',
          errorOnGetUrl: false,
        },
      });

      const { payTicketInvoiceForES } = this.props;
      payTicketInvoiceForES(invoice.id).then((response) => {
        if (response.type === RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES) {
          this.setState({
            paymentTicketOnEs: {
              loadGetUrlPaymentTicketOnEs: false,
              urlPaymentTicketOnEs: response.url,
              errorOnGetUrl: false,
            },
          });
        }

        if (response.type === ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES) {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar('notifyFailMessage', notifierError);
          this.setState({
            paymentTicketOnEs: {
              loadGetUrlPaymentTicketOnEs: false,
              urlPaymentTicketOnEs: '',
              errorOnGetUrl: true,
            },
          });
        }
      });
    }
  }

  handleRedirectToBoleto = async ({ urlBoleto, invoiceId }) => {
    const { checkBoletoUserData, enqueueSnackbar } = this.props;
    const { data: response } = await checkBoletoUserData(invoiceId);

    if (!response) {
      enqueueSnackbar('snackbar.error.process_boleto', notifierError);
      return;
    }

    const isCepError = response.errorCode === 'braspag.boleto_creation.errorCep';

    if (!response.success) {
      if (isCepError) {
        this.setState(prevState => ({
          ...prevState,
          openDialogDataCheck: true,
          boletoDataCheckInvoiceId: invoiceId,
        }));
      } else {
        enqueueSnackbar('snackbar.error.process_boleto', notifierError);
      }
      return;
    }
    window.open(urlBoleto, '_blank');
  }

  handleGetBoletoURL = async (invoice) => {
    if (COUNTRY === 'br') {
      const { getBoletoUrl } = this.props;
      const { dataCheck } = await getBoletoUrl(invoice.id);

      if (dataCheck.data.result) {
        this.setState({
          redirecting: true,
        }, () => {
          this.handleRedirectToBoleto({
            urlBoleto: dataCheck.data.url,
            invoiceId: invoice.id,
          });
        });
      }
    } else {
      window.open(BOLETO_URL(invoice.id));
    }
  }

  handleOpenDialogNewApiPaypal = () => (this.setState({ openDialogPaypal: true }));

  handlePaypalPayment = (invoice) => {
    const { getPaymentMethod, gatorpayPaypalFeatureToggle } = this.props;

    if (gatorpayPaypalFeatureToggle) {
      this.handleOpenDialogNewApiPaypal();
      getPaymentMethod(GATORPAY_PAYPAL, invoice.id);
      return;
    }

    getPaymentMethod(PAYPAL, invoice.id);
  }

  handleTicketPayment = () => {
    const { ticketModalFeatureToggle } = this.props;
    if (ticketModalFeatureToggle) {
      this.setState({ openDialogTicket: true });
    }
  }

  openPreChat = () => {
    const {
      invoiceIdFromPreChat, preChatBillingGenerated, preChatSetOpen, setPreChatInvoiceId, preChatSetScreen, setPreChatBillingGenerated, preChatTrustReactivationStates, invoice, setPreChatTrustReactivationInvoiceId,
    } = this.props;
    if (invoiceIdFromPreChat && invoice && invoiceIdFromPreChat.toString() === invoice.id.toString()) {
      const findTrustReactivationState = preChatTrustReactivationStates.find(state => (state.invoice_id === invoice.id && state.trust_reactivation_available));

      const trustReactivationAvailable = findTrustReactivationState && findTrustReactivationState.trust_reactivation_available;

      const trustReactivationInvoiceId = findTrustReactivationState && findTrustReactivationState.invoice_id;

      const trustReactivationFlow = preChatBillingGenerated && trustReactivationAvailable;

      if (trustReactivationFlow) {
        setPreChatTrustReactivationInvoiceId(trustReactivationInvoiceId);
        preChatSetScreen('trustReactivation');
      } else {
        preChatSetScreen('problemSolved');
      }
      setPreChatBillingGenerated(false);
      setPreChatInvoiceId(null);
      preChatSetOpen(true);
    }
  }

  onDisplaPaymentModal = (invoiceIdFromPreChat) => {
    this.setState({ openPaymentModal: true });
    this.setState({ hasQRCodeGenerated: false });

    if (invoiceIdFromPreChat) {
      return;
    }

    const { subscriptions: subscriptionAnalytics } = billingAnalytics;
    subscriptionAnalytics.executePaymentInvoiceManagerClick('1_link_pagar_fatura');
  }

  payInvoice = () => {
    const {
      invoice, paymentMethod: paymentMethodReceiveByProps, ticketModalFeatureToggle,
    } = this.props;
    const { paymentMethod, paymentTicketOnEs } = this.state;
    invoiceUnpaidCardTagManager(paymentMethodReceiveByProps);

    switch (paymentMethod) {
      case BOLETO:
      case GATORPAY_BOLETO:
        ticketModalFeatureToggle ? this.handleTicketPayment(invoice) : this.handleGetBoletoURL(invoice);
        break;
      case BOLETO_CL_CO:
      case BOLETO_MX:
        !paymentTicketOnEs.errorOnGetUrl && window.open(paymentTicketOnEs.urlPaymentTicketOnEs);
        break;
      case PAYPAL:
      case GATORPAY_PAYPAL:
        this.handlePaypalPayment(invoice);
        break;
      case CREDIT_CARD:
      case CREDIT_CARD_ES:
      case PARC_2X:
      case PARC_3X:
      case PARC_4X:
      case PARC_5X:
      case PARC_6X:
      case BRASPAG_PARC_1X:
      case BRASPAG_PARC_2X:
      case BRASPAG_PARC_3X:
      case BRASPAG_PARC_4X:
      case BRASPAG_PARC_5X:
      case BRASPAG_PARC_6X:
      case BRASPAG_PARC_7X:
      case BRASPAG_PARC_8X:
      case BRASPAG_PARC_9X:
      case BRASPAG_PARC_10X:
      case BRASPAG_PARC_11X:
      case BRASPAG_PARC_12X:
        this.handleOpen('openDialog');
        break;
      default:
        // eslint-disable-next-line no-console
        !IS_PROD && console.error(`Payment method not implemented ${paymentMethod}`);
        break;
    }
  }

  managePaymentMethodsAdapter = (invoicePaymentMethods) => {
    const { gpPaypalESFeatureToggle } = this.props;

    if ((gpPaypalESFeatureToggle || COUNTRY === 'br') && invoicePaymentMethods.paypal) {
      const paymentMethods = { ...invoicePaymentMethods };
      delete paymentMethods.paypal;
      return paymentMethods;
    }

    return invoicePaymentMethods;
  }

  manageInvoicePaymentMethodAdapter = (paymentMethod) => {
    const isBrAndPaypal = COUNTRY === 'br' && paymentMethod === PAYPAL;
    const isEsAndPaypal = COUNTRY !== 'br' && paymentMethod === PAYPAL;

    if (isBrAndPaypal) return GATORPAY_PAYPAL;
    if (isEsAndPaypal) return GATORPAY_PAYPAL;

    return paymentMethod;
  }


  mountSelectItems = (invoicePaymentMethods, invoice) => {
    const paymentMethodsAvailable = this.managePaymentMethodsAdapter(invoicePaymentMethods);
    const paymentMethodKey = this.manageInvoicePaymentMethodAdapter(invoice.paymentmethod);

    const method = paymentMethodsAvailable[paymentMethodKey];
    const hasMethod = !!method;
    const isMethodSelectable = hasMethod && method.selectable;
    const isMethodPrimary = hasMethod && method.primary;

    const canShow = hasMethod && !isMethodSelectable && !isMethodPrimary;

    if (canShow) {
      return (
        <MenuItem key={paymentMethodKey} value={paymentMethodKey}>
          {method.description}
        </MenuItem>
      );
    }
    return Object.keys(paymentMethodsAvailable).map(method => (
      paymentMethodsAvailable[method].selectable && (
      <MenuItem key={method} value={method}>
        {paymentMethodsAvailable[method].description}
      </MenuItem>
      )
    ));
  }

  handleCloseDataCheck = () => {
    this.setState({ openDialogDataCheck: false });
    this.openPreChat();
  }

  handleClosePaypalDialog = () => {
    this.setState({ openDialogPaypal: false });
    this.openPreChat();
  }

  handleCloseTicketDialog = () => {
    this.setState({ openDialogTicket: false });
    this.openPreChat();
  }

  getGenerateErrorPixModalProps = () => {
    const {
      invoice,
    } = this.props;

    const onCloseClick = () => {
      this.setState({ openGenerateErrorPixModal: false });
      this.openPreChat();
    };

    const onSwitchPaymentMethod = () => {
      this.setState({ hasQRCodeGenerated: false });
      this.setState({ openPaymentModal: true });
      this.setState({ openGenerateErrorPixModal: false });
      this.setState({ openTicketModal: false });
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code && code !== '') && (qrCode && qrCode !== '')) {
        this.setState({ pixCodeGenerated: code });
        this.setState({ qrCodeGenerated: qrCode });
        this.setState({ hasQRCodeGenerated: true });
        this.setState({ openPaymentModal: true });
        this.setState({ openTicketModal: false });
        this.setState({ openGenerateErrorPixModal: false });
      }
    };

    const onPaymentSuccessPix = () => {
      this.setState({ openPaymentModal: false });
      this.setState({ openGenerateErrorPixModal: false });
      this.setState({ openExpiredTimePixModal: false });
      this.setState({ openTicketModal: false });
      this.setState({ openPaymentSuccessPixModal: true });
      getDiagnostic();
      logger.info('tentativa de requisição de diagnostic: UnpaidInvoiceItem');
    };

    return {
      onPaymentSuccessPix,
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onCloseClick,
      invoiceId: invoice.id,
      originCalling: OriginCalling.INVOICES_MANAGER,
    };
  }

  getTicketModalProps = () => {
    const onCloseClick = () => {
      this.setState({ openTicketModal: false });
      this.openPreChat();
    };

    const {
      urlTicket,
      isOriginSavePDF,
    } = this.state;

    return {
      onCloseClick,
      originCalling: OriginCalling.INVOICES_MANAGER,
      urlTicket,
      hasOriginSavePDF: isOriginSavePDF,
      titleLabel: 'Redirect to ticket page',
      infoTextLabel: 'To open ticket in another tab was need disable popup blocker.',
      redirectTicketUrlButtonLabel: 'Go to Ticket',
    };
  }
;

  getPaymentSuccessPixModalProps = () => {
    const onCloseClick = () => {
      this.setState({ openPaymentSuccessPixModal: false });
      this.openPreChat();
    };

    const onPaymentSuccessPix = () => {
      this.setState({ hasQRCodeGenerated: false });
      this.setState({ openPaymentModal: false });
      this.setState({ openExpiredTimePixModal: false });
      this.setState({ openTicketModal: false });
      this.setState({ openPaymentSuccessPixModal: true });
    };

    return {
      onCloseClick,
      onPaymentSuccessPix,
      originCalling: OriginCalling.INVOICES_MANAGER,
    };
  }

  getExpiredPixModalProps = () => {
    const {
      invoice,
    } = this.props;

    const onCloseClick = () => {
      this.setState({ openExpiredTimePixModal: false });
      this.openPreChat();
    };

    const onSwitchPaymentMethod = () => {
      this.setState({ hasQRCodeGenerated: false });
      this.setState({ openPaymentModal: true });
      this.setState({ openExpiredTimePixModal: false });
      this.setState({ pixCodeGenerated: '' });
      this.setState({ qrCodeGenerated: '' });
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code || code !== '') && (qrCode || qrCode !== '')) {
        this.setState({ pixCodeGenerated: code });
        this.setState({ qrCodeGenerated: qrCode });
        this.setState({ hasQRCodeGenerated: true });
        this.setState({ openPaymentModal: true });
        this.setState({ openExpiredTimePixModal: false });
        this.setState({ openTicketModal: false });
      }
    };

    const onPaymentSuccessPix = () => {
      this.setState({ hasQRCodeGenerated: false });
      this.setState({ openPaymentModal: false });
      this.setState({ openExpiredTimePixModal: false });
      this.setState({ openPaymentSuccessPixModal: true });
      this.setState({ openTicketModal: false });
    };


    return {
      onPaymentSuccessPix,
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onCloseClick,
      invoiceId: invoice.id,
      originCalling: OriginCalling.INVOICES_MANAGER,

    };
  };


   getPaymentModalProps = () => {
     const {
       invoice,
       enabledChangePayPalAccount,
       enabledTimeToDisplayQRCode,
     } = this.props;

     const onCloseClick = () => {
       this.setState({ openPaymentModal: false });
       this.openPreChat();
     };

     const onConfirmButtonClick = () => {
       this.setState({ openPaymentModal: false });
       this.openPreChat();
     };

     const isNotDomainType = 'x' !== 'domain';

     const onFinishPixTimer = () => {
       this.setState({ openPaymentModal: false });
       this.setState({ openExpiredTimePixModal: true });
     };

     const onGenerateErrorPix = () => {
       this.setState({ openPaymentModal: false });
       this.setState({ openGenerateErrorPixModal: true });
     };

     const onPaymentSuccessPix = () => {
       this.setState({ openPaymentModal: false });
       this.setState({ openGenerateErrorPixModal: false });
       this.setState({ openExpiredTimePixModal: false });
       this.setState({ openPaymentSuccessPixModal: true });
     };

     const onRedirectTicket = (urlTicket, isOriginSavePDF = false) => {
       this.setState({ openTicketModal: true });
       this.setState({ isOriginSavePDF });
       this.setState({ urlTicket });
     };

     return {
       onCloseClick,
       onConfirmButtonClick,
       onFinishPixTimer,
       onPaymentSuccessPix,
       onGenerateErrorPix,
       onRedirectTicket,
       enabledTimeToDisplayQRCode,
       enabledChangePayPalAccount: { enabledChangePayPalAccount },
       invoiceId: invoice.id,
       discount: invoice.discount,
       isExceedsRetriesCC: invoice.isExceedsRetriesCC,
       dueDate: format(new Date(`${invoice.duedate} 00:00:00`), 'dd/MM/yyyy'),
       originCalling: OriginCalling.INVOICES_MANAGER,
       canShowCancelImmediateButton: isNotDomainType,
       renovationAmountText: formatCurrency(invoice.total),
     };
   };

  handleMatchMedia = () => {
    const screenMinimalDesktop = window.matchMedia('(max-width: 1100px)');

    if (screenMinimalDesktop.matches) {
      this.setState({
        warningSize: '100%',
      });
    } else {
      this.setState({
        warningSize: '75%',
      });
    }
  }

  componentDidMount = () => {
    this.paymentDialog = React.createRef();
    window.addEventListener('resize', this.handleMatchMedia);
    this.handleMatchMedia();

    const { openInvoiceOnMount } = this.props;
    this.setState({ openPaymentModal: openInvoiceOnMount });
  }

  openPaymentDialog = () => {
    const { paymentMethod, invoicePaymentMethods } = this.props;

    if (!paymentMethod) return;

    const method = invoicePaymentMethods[paymentMethod];
    const isMethodSelectable = !!method && method.selectable;

    const statePayload = isMethodSelectable
      ? [{ paymentMethod }, () => this.payInvoice()]
      : [{ paymentMethod: null }];

    this.setState(...statePayload);
  }

  componentDidUpdate = (prevProps) => {
    const {
      boletoDataCheck, invoiceIdFromPreChat, invoice, holdDomainRedirect,
    } = this.props;
    const { redirecting, openPaymentModal } = this.state;

    const canOpenPaymentDialog = this.paymentDialog
      && this.props !== prevProps
      && !boletoDataCheck.loading
      && !redirecting;

    canOpenPaymentDialog && this.openPaymentDialog();

    if (!openPaymentModal && !holdDomainRedirect && invoiceIdFromPreChat && invoice && invoiceIdFromPreChat.toString() === invoice.id.toString()) {
      this.onDisplaPaymentModal(true);
    }
  }

  render() {
    const {
      classes,
      t,
      invoicePaymentMethods,
      invoice,
      loading,
      fullScreen,
      index,
      loadingDataCheck,
      loadingDataCheckInvoiceId,
      onPaymentExecuted,
      enabledChangePayPalAccount,
    } = this.props;

    const {
      paymentMethod,
      openDialog,
      paymentTicketOnEs,
      openDialogDataCheck,
      openDialogPaypal,
      openDialogTicket,
      warningSize,
      openPaymentModal,
      openExpiredTimePixModal,
      openGenerateErrorPixModal,
      openTicketModal,
      openPaymentSuccessPixModal,
      hasQRCodeGenerated,
      pixCodeGenerated,
      qrCodeGenerated,
    } = this.state;

    const isBoletoES = paymentMethod === BOLETO_CL_CO || paymentMethod === BOLETO_MX;
    const isPaymentPendingPhishingCheck = invoice.statusMessage === INVOICES_STATUS_WITHOUT_CAPITALIZE.PAYMENT_PENDING;
    const enabledNewModalPayment = true;

    const getInvoiceAction = () => {
      if (!enabledNewModalPayment) {
        return (paymentMethod && !invoice.needContactUs && !isPaymentPendingPhishingCheck) && (
          !paymentTicketOnEs.loadGetUrlPaymentTicketOnEs ? (
            <Fragment>
              {loadingDataCheck && loadingDataCheckInvoiceId === invoice.id ? (
                <Loader size={20} classesToProps={classes.loaderDataCheck} />
              ) : (
                <Button
                  data-id={`button-pay-invoice-${index}`}
                  className="cardPayButton"
                  onClick={!enabledNewModalPayment ? this.payInvoice : this.onDisplaPaymentModal}
                  disabled={loading || (isBoletoES && paymentTicketOnEs.errorOnGetUrl)}
                >
                  {t(`invoiceAction.${paymentMethod}`)}
                </Button>
              )}
            </Fragment>
          ) : (
            <Loader size={20} classesToProps={classes.loaderDataCheck} />
          )
        );
      }
      return (!invoice.needContactUs && !isPaymentPendingPhishingCheck) && (
        !paymentTicketOnEs.loadGetUrlPaymentTicketOnEs ? (
          <Fragment>
            {loadingDataCheck && loadingDataCheckInvoiceId === invoice.id ? (
              <Loader size={20} classesToProps={classes.loaderDataCheck} />
            ) : (
              <Button
                data-id={`button-pay-invoice-${index}`}
                className="cardPayButton"
                onClick={this.onDisplaPaymentModal}
                disabled={loading || (isBoletoES && paymentTicketOnEs.errorOnGetUrl)}
              >
                {t('invoiceAction.pay_invoice')}
              </Button>
            )}
          </Fragment>
        ) : (
          <Loader size={20} classesToProps={classes.loaderDataCheck} />
        )
      );
    };
    return (
      <Fragment>
        <InvoiceItem
          invoiceValueLabel="invoiceTotalLabel"
          invoice={invoice}
          content={
            (invoice.needContactUs || isPaymentPendingPhishingCheck) ? (
              <WarningNotice
                contentClassName="warning-payment"
                phishingCheck={isPaymentPendingPhishingCheck}
                message={isPaymentPendingPhishingCheck ? t('cardAlerts.invoicePhishingCheck') : t('cardAlerts.unpaidInvoice')}
                fill={warningSize}
                icon={isPaymentPendingPhishingCheck && <Info />}
              />
            ) : (
              !enabledNewModalPayment && (
              <FormControl variant="outlined" className={`${classes.formControl} select-payment`}>
                <InputLabel className={classes.inputLabelCustom} htmlFor="outlined-age-simple">
                  {t('invoicePaymentMethod')}
                </InputLabel>
                <Select
                  value={paymentMethod}
                  className={classes.selectCustom}
                  onChange={this.handleChangePaymentMethod}
                  input={(
                    <OutlinedInput
                      classes={{
                        root: classes.selectCustom,
                        input: classes.selectCustomInput,
                        notchedOutline: classes.selectCustomOutline,
                        focused: classes.selectCustomFocused,
                      }}
                      labelWidth={calcLabelWidth(t('invoicePaymentMethod'))}
                      name="site"
                      id="outlined-age-simple"
                    />
                    )}
                  disabled={paymentTicketOnEs.loadGetUrlPaymentTicketOnEs || loadingDataCheck}
                  data-id={`invoice-${index}`}
                >
                  <MenuItem value="">
                    <em>{t('invoicePaymentMethod')}</em>
                  </MenuItem>
                  {Object.keys(invoicePaymentMethods).length && this.mountSelectItems(invoicePaymentMethods, invoice)}
                </Select>
              </FormControl>
              )
            )
          }
          action={getInvoiceAction()}
          enabledNewModalPayment={enabledNewModalPayment}
          type={INVOICES_STATUS.UNPAID}
        />
        <Dialog
          fullScreen={fullScreen}
          fullWidth
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          className={classes.dialogContainer}
          classes={{ root: classes.dialogContainer }}
          ref={this.paymentDialog}
        >
          <CreditCardDialog
            onClose={this.handleClose}
            invoiceId={invoice.id}
            newPaymentMethod={paymentMethod}
            invoiceStatusMessage={invoice.statusMessage}
            onPaymentExecuted={onPaymentExecuted}
            showResumeData
          />
        </Dialog>

        <ModalCheckData open={openDialogDataCheck} close={this.handleCloseDataCheck} />

        <Dialog
          fullScreen={fullScreen}
          fullWidth
          open={openDialogPaypal}
          onClose={this.handleClosePaypalDialog}
          className={classes.dialogContainer}
          classes={{ root: classes.dialogContainer }}
          ref={this.paymentDialog}
        >
          <PaypalDialog
            enabledChangePayPalAccount={enabledChangePayPalAccount}
            open={openDialogPaypal}
            invoiceId={invoice.id}
            invoiceStatusMessage={invoice.statusMessage}
            close={this.handleClosePaypalDialog}
            onPaymentExecuted={onPaymentExecuted}
          />
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          fullWidth
          open={openDialogTicket}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          className={classes.dialogContainer}
          classes={{ root: classes.dialogContainer }}
          ref={this.paymentDialog}
        >
          <TicketDialog
            invoiceId={invoice.id}
            invoiceStatusMessage={invoice.statusMessage}
            close={this.handleCloseTicketDialog}
          />
        </Dialog>

        {openPaymentModal && (
        <PaymentModal
          {...this.getPaymentModalProps()}
          hasQRCodeGenerated={hasQRCodeGenerated}
          qrCodeGenerated={qrCodeGenerated}
          pixCodeGenerated={pixCodeGenerated}
        />
        )}

        {openExpiredTimePixModal && (
        <ExpiredTimePixModal {...this.getExpiredPixModalProps()} />
        )}

        {openGenerateErrorPixModal && (
        <GenerateErrorPixModal {...this.getGenerateErrorPixModalProps()} />
        )}

        {openPaymentSuccessPixModal && (
          <PaymentSuccessPixModal
            {...this.getPaymentSuccessPixModalProps()}
          />
        )}

        {openTicketModal && (
        <TicketRedirectModal
          {...this.getTicketModalProps()}
        />
        )}
      </Fragment>
    );
  }
}

UnpaidInvoiceItem.propTypes = {
  t: PropTypes.func.isRequired,
  invoice: invoiceType.isRequired,
  invoicePaymentMethods: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    formControl: PropTypes.string,
    inputLabelCustom: PropTypes.string,
    selectCustom: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getPaymentMethod,
  payTicketInvoiceForES,
  enqueueSnackbar,
  getBoletoUrl,
  checkBoletoUserData,
  preChatSetOpen,
  setPreChatInvoiceId,
  preChatSetScreen,
  setPreChatBillingGenerated,
  setPreChatTrustReactivationInvoiceId,
  getDiagnostic,
}, dispatch);

const mapStateToProps = state => ({
  loading: state.invoices.loading,
  invoicePaymentMethods: state.invoicePaymentMethods.methods,
  loadingDataCheck: state.invoices.boletoDataCheck.loading,
  loadingDataCheckInvoiceId: state.invoices.boletoDataCheck.invoiceId,
  boletoDataCheck: state.invoices.boletoDataCheck,
  gatorpayFeatureToggle: state.featureToggles.toggles.portal.gatorpay,
  gatorpayPaypalFeatureToggle: state.featureToggles.toggles.portal.gatorpay_paypal,
  preChatBillingGenerated: state.preChat.billingGenerated,
  preChatTrustReactivationStates: state.preChat.trustReactivation,
  invoiceIdFromPreChat: state.preChat.invoiceId,
  holdDomainRedirect: state.preChat.triage.holdDomainRedirect,
  ticketModalFeatureToggle: false,
});
export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(UnpaidInvoiceItem))));
