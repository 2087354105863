import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './ChangePaymentModal.styles';
import { Modal } from '@/pages/common';
import { useResize } from '@/hooks/useResize';
import {
  FLOW_ORIGIN, GA_RULES, MODAL_STEPS,
} from './ChangePaymentModal.types';
import { Button, Checkbox, IconCheck } from 'gatorcomponents';
import Snappy from '@/media/billing/snappy-with-shadow.svg';
import useLocale from '@/hooks/useLocale';
import { formatDate } from '@/utils/Formatters/formatDate';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { useDispatch } from 'react-redux';
import { billingActions } from '@/redux/modules';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import { billingAnalytics } from '@/analytics';

const ChangePaymentModal = ({
  origin,
  cancelFlowAction,
  hideModal,
  productsList,
  loadingChangePaymentMethod,
  statusChangePaymentMethod,
  step = MODAL_STEPS.SUCCESS_CC_REGISTER,
}) => {
  const [modalStep, setModalStep] = useState(step);
  const [updateRequested, setUpdateRequested] = useState(false);

  const changeMethodListDefault = productsList.map(product => ({ id: product.id, type: product.type, checked: true }));
  const [productsToChangeMethod, setProductsToChangeMethod] = useState(changeMethodListDefault);

  const dispatch = useDispatch();
  const windowRect = useResize();
  const { billing: billingLocale } = useLocale();
  const changePaymentModalLocale = billingLocale.changePaymentMethodModal;
  const modalTitleLabel = () => {
    switch (origin) {
      case FLOW_ORIGIN.MANAGE_SUBSCRIPTION:
        return changePaymentModalLocale.defaultStep.signaturePaymentChanged;
      case FLOW_ORIGIN.CREDIT_CARD:
      default:
        return changePaymentModalLocale.defaultStep.successRegister;
    }
  };

  const { width } = windowRect;
  const mobile = width < 600;

  const hasSelectedProducts = productsToChangeMethod.find(product => product.checked === true);

  const callGaEvent = useCallback((gaEvent = GA_RULES.CLOSE_MODAL) => {
    const gaRules = {
      CLOSE_MODAL: {
        [MODAL_STEPS.SUCCESS_CC_REGISTER]: billingAnalytics.changePaymentModal.successCcRegisterCloseModal,
        [MODAL_STEPS.SELECT_PRODUCTS]: billingAnalytics.changePaymentModal.selectProductsCloseModal,
      },
      CTA_CANCEL: {
        [MODAL_STEPS.SUCCESS_CC_REGISTER]: billingAnalytics.changePaymentModal.successCcRegisterCtaNoThanks,
        [MODAL_STEPS.SELECT_PRODUCTS]: billingAnalytics.changePaymentModal.selectProductsCtaCancel,
      },
      CTA_CHANGE_PAYMENT_METHOD: {
        [MODAL_STEPS.SUCCESS_CC_REGISTER]: billingAnalytics.changePaymentModal.successCcRegisterCtaChangeToCc,
        [MODAL_STEPS.SELECT_PRODUCTS]: billingAnalytics.changePaymentModal.selectProductsCtaChangeToCc,
      },
    };

    const gaEventFunction = gaRules[gaEvent][modalStep];

    modalStep !== MODAL_STEPS.SUCCESS_CHANGE_PRODUCTS_METHOD && gaEventFunction();
  }, [modalStep]);

  const handleHideModal = useCallback((gaEvent = GA_RULES.CLOSE_MODAL) => {
    callGaEvent(gaEvent);
    hideModal && hideModal();
  }, [hideModal, callGaEvent]);

  const handleCancel = () => {
    cancelFlowAction && cancelFlowAction();
    handleHideModal(GA_RULES.CTA_CANCEL);
  };

  const handleMarkCheckbox = (productId) => {
    setProductsToChangeMethod(productsToChangeMethod.map((product) => {
      if (product.id === productId) {
        return { ...product, checked: !product.checked };
      }
      return product;
    }));
  };

  const handleUpdateModalStep = ({ newModalStep, gaEvent }) => {
    callGaEvent(gaEvent);
    setModalStep(newModalStep);
  };

  const handleChangeToCreditCard = () => {
    const listToRequestChangePaymentMethod = productsToChangeMethod.filter(product => product.checked).map(product => (
      { id: product.id, type: product.type }
    ));

    dispatch(billingActions.subscriptions.updateSubscriptionsNotInCreditCard({ list: listToRequestChangePaymentMethod }));
    callGaEvent(GA_RULES.CTA_CHANGE_PAYMENT_METHOD);
  };

  const confirmPaymentMethodChange = useCallback(() => {
    if (loadingChangePaymentMethod) {
      setUpdateRequested(true);
    }

    if (modalStep === MODAL_STEPS.SELECT_PRODUCTS && updateRequested) {
      if (statusChangePaymentMethod === 'success') {
        setModalStep(MODAL_STEPS.SUCCESS_CHANGE_PRODUCTS_METHOD);
      }
      if (statusChangePaymentMethod === 'error') {
        dispatch(enqueueSnackbar(changePaymentModalLocale.changeMethodStep.errorToast, notifierError));
        handleHideModal();
      }
    }
  }, [
    loadingChangePaymentMethod,
    statusChangePaymentMethod,
    changePaymentModalLocale,
    modalStep,
    updateRequested,
    dispatch,
    handleHideModal,
  ]);

  useEffect(() => { confirmPaymentMethodChange(); }, [confirmPaymentMethodChange]);

  return (
    <Modal fullScreen={mobile} onClose={handleHideModal} maxWidth={800} maxHeight={800} testId="changePaymentModal">
      {modalStep === MODAL_STEPS.SUCCESS_CC_REGISTER && (
        <Styles.Wrapper>
          <Styles.TopContent>
            <IconCheck color="activePure" size="xl" />
            <Styles.ModalTitle noMargin={origin === FLOW_ORIGIN.MANAGE_SUBSCRIPTION}>
              {modalTitleLabel()}
            </Styles.ModalTitle>
            {origin === FLOW_ORIGIN.MANAGE_SUBSCRIPTION && (
              <Styles.ModalDescription>
                {changePaymentModalLocale.defaultStep.signaturePaymentChangedSubtitle}
              </Styles.ModalDescription>
            )}
          </Styles.TopContent>
          <Styles.BottomContent>
            <Styles.Image src={Snappy} />
            <Styles.ChangeMethodContainer>
              <Styles.Description>
                {changePaymentModalLocale.defaultStep.changePaymentLabel}
              </Styles.Description>
              <Styles.Text>
                {changePaymentModalLocale.defaultStep.changePaymentDescription}
              </Styles.Text>
              <Styles.ButtonsContainer>
                <Button label={changePaymentModalLocale.defaultStep.closeModalButtonLabel} onClick={handleCancel} variant="tertiary" width="fit" />
                <Button label={changePaymentModalLocale.defaultStep.changeToCcButtonLabel} onClick={() => handleUpdateModalStep({ newModalStep: MODAL_STEPS.SELECT_PRODUCTS, gaEvent: GA_RULES.CTA_CHANGE_PAYMENT_METHOD })} width="fit" />
              </Styles.ButtonsContainer>
            </Styles.ChangeMethodContainer>
          </Styles.BottomContent>
        </Styles.Wrapper>
      )}
      {modalStep === MODAL_STEPS.SELECT_PRODUCTS && (
        <Styles.SelectProductsWrapper>
          <Styles.ListTitle>{changePaymentModalLocale.changeMethodStep.changeToCcLabel}</Styles.ListTitle>
          <Styles.ListDescription>{changePaymentModalLocale.changeMethodStep.descriptionLabel}</Styles.ListDescription>
          <Styles.List>
            {productsList.map((product, index) => {
              const checkboxProps = productsToChangeMethod.find(item => item.id === product.id);

              return (
                <Styles.ListItem lastItem={index === productsList.length - 1} key={product.id}>
                  <Styles.CheckboxLabel onClick={() => handleMarkCheckbox(product.id)}>
                    <Checkbox checked={checkboxProps.checked} id={`$${product.type}-${product.id}-checkbox`} />
                  </Styles.CheckboxLabel>
                  <Styles.ListItemInfos>
                    <Styles.ListItemField>
                      <Styles.ListItemLabel>{changePaymentModalLocale.changeMethodStep.tableLabels.product}</Styles.ListItemLabel>
                      <Styles.ListItemValue title={product.product} isProduct>{product.product}</Styles.ListItemValue>
                    </Styles.ListItemField>
                    <Styles.ListItemField>
                      <Styles.ListItemLabel>{changePaymentModalLocale.changeMethodStep.tableLabels.paymentMethod}</Styles.ListItemLabel>
                      <Styles.ListItemValue>{billingLocale.paymentMethods[product.paymentMethod] || ''}</Styles.ListItemValue>
                    </Styles.ListItemField>
                    <Styles.ListItemField>
                      <Styles.ListItemLabel>{changePaymentModalLocale.changeMethodStep.tableLabels.nextRenew}</Styles.ListItemLabel>
                      <Styles.ListItemValue>{formatDate(product.nextDueDate) || ''}</Styles.ListItemValue>
                    </Styles.ListItemField>
                    <Styles.ListItemField>
                      <Styles.ListItemLabel>{changePaymentModalLocale.changeMethodStep.tableLabels.renewValue}</Styles.ListItemLabel>
                      <Styles.ListItemValue>{formatCurrency(product.renewAmount) || ''}</Styles.ListItemValue>
                    </Styles.ListItemField>
                  </Styles.ListItemInfos>
                </Styles.ListItem>
              );
            })}
          </Styles.List>
          <Styles.ButtonsContainer centralized>
            <Button
              label={changePaymentModalLocale.changeMethodStep.cancellButtonLabel}
              variant="tertiary"
              size="large"
              onClick={handleCancel}
              width="fit"
            />
            <Button
              label={changePaymentModalLocale.changeMethodStep.confirmButtonLabel}
              size="large"
              testId="call-change-payment-method-button"
              onClick={handleChangeToCreditCard}
              width="fit"
              disabled={!hasSelectedProducts}
              loading={loadingChangePaymentMethod}
            />
          </Styles.ButtonsContainer>
        </Styles.SelectProductsWrapper>
      )}
      {modalStep === MODAL_STEPS.SUCCESS_CHANGE_PRODUCTS_METHOD && (
        <Styles.SuccessWrapper>
          <Styles.TopContent>
            <IconCheck color="activePure" size="xl" />
            <Styles.SuccessTitle noMargin>
              {changePaymentModalLocale.successChangeMethodStep.successChangeLabel}
            </Styles.SuccessTitle>
          </Styles.TopContent>
          <Styles.ButtonsContainer centralized>
            <Button
              label={changePaymentModalLocale.successChangeMethodStep.confirmButtonLabel}
              onClick={() => handleHideModal()}
              size="large"
              width="fit"
            />
          </Styles.ButtonsContainer>
        </Styles.SuccessWrapper>
      )}
    </Modal>
  );
};

export default ChangePaymentModal;
