import { collect } from '@/analytics';

const EVENT = {
  NAME: 'gaEvent',
  CATEGORY: {
    REFUND_DIAGNOSTIC: 'Solução - Diagnóstico de Reembolso',
    TRUST_REACTIVATION_PORTAL: 'Solução- Portal do Cliente Reativação por Confiança - Clique CTA',
    TRUST_REACTIVATION_DIAGNOSTIC: 'Solução- Diagnóstico Reativação por Confiança - Clique CTA',
    CHAT_SELF_SERVICE: 'Solução - Autoatendimento Chat',
    DIAGNOSTIC_MODULE: 'Solução - Módulo de Diagnóstico',
    DIAGNOSTIC_PROPAGATION: 'Solução- Diagnóstico propagação - Clique CTA',
    DIAGNOSTIC_CONFIGURATION: 'Solução- Diagnóstico configuração de domínio - Clique CTA',
    DIAGNOSTIC_CONTRACT: 'Solução- Diagnóstico domínio não contratado - Clique CTA',
    SHOW_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Alerta apresentado com sucesso',
    CTA_SETUP_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Clique no cta',
    CHAT_SETUP_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Clique no chat',
    SHOW_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - Alerta apresentado com sucesso',
    CTA_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - Clique no cta',
    CHAT_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - Clique no chat',
    SHOW_EPP_KEY: 'Solução - Autoatendimento Chave EPP - Alerta apresentado com sucesso',
    CTA_EPP_KEY: 'Solução - Autoatendimento Chave EPP - Clique no cta',
    CHAT_EPP_KEY: 'Solução - Autoatendimento Chave EPP - Clique no chat',
    HOLD_DOMAIN_ALERT: 'Solução - Autoatendimento domínio congelado - Alerta apresentado com sucesso',
    HOLD_DOMAIN_CTA: 'Solução - Autoatendimento domínio congelado - Clique no cta',
    HOLD_DOMAIN_MODAL: 'Solução - Autoatendimento domínio congelado - Modal exibida',
    BACKUP_ALERT: 'Solução - Autoatendimento fluxo de backup - Alerta apresentado com sucesso',
    BACKUP_CTA_CLICK: 'Autoatendimento fluxo de backup - Clique no cta',
    DUPLICATE_ORDER_ALERT: 'Solução - Diagnóstico Pedidos duplicados - Alerta apresentado com sucesso',
    DUPLICATE_ORDER_CTA_CLICK: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta',
    DIAGNOSTIC_CTA: 'Solução - Diagnóstico  - Clique no cta',
    PROCESSING_ORDER_ALERT: 'Solução - Diagnóstico Pedidos em análise - Alerta apresentado com sucesso',
    PROCESSING_ORDER_CTA_CLICK: 'Solução - Diagnóstico Pedidos em análise - Clique no cta',
    CANCELLATION_REDIRECT: 'redirecionamento cancelamento',
    REFUND_REDIRECT: 'redirecionamento reembolso',
    TERMINATED_REDIRECT: 'redirecionamento plano encerrado',
    DNS_REDIRECT: 'redirecionamento DNS',
    WORDPRESS_REDIRECT: 'redirecionamento Instalar WordPress',
    SITEBUILDER_REDIRECT: 'redirecionamento ativar criador de sites',
  },
  ACTION: {
    SHOW_DOMAIN_TO_RESERVED: 'Solução - Alerta apresentado com sucesso - Alerta de domínio apontado para o reservado apresentado com sucesso',
    CTA_SETUP_DOMAIN_TO_RESERVED: 'Solução - Alerta apresentado com sucesso - cta configurar domínio',
    CHAT_SETUP_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Clique no cta',
    SHOW_CLEAR_CACHE: 'Solução - Alerta apresentado com sucesso - Alerta para limpar o cache apresentado com sucesso',
    CTA_CLEAR_CACHE: 'Solução - Alerta apresentado com sucesso - cta como limpar o cache do navegador',
    CHAT_CLEAR_CACHE: 'Solução - Alerta apresentado com sucesso - cta continuar para o chat',
    SHOW_EPP_KEY: 'Solução - Autoatendimento Chave EPP -  apresentado com sucesso',
    CTA_EPP_KEY: 'Solução - Autoatendimento Chave EPP - cta gerar Chave',
    CHAT_EPP_KEY: 'Solução - Autoatendimento Chave EPP- cta continuar para o chat',
    HOLD_DOMAIN_ALERT: 'Solução - Alerta apresentado com sucesso - Alerta de domínio congelado apresentado com sucesso',
    HOLD_DOMAIN_PAY_INVOICE: 'Solução - Alerta apresentado com sucesso - Cta Pagar fatura e reativar domínio',
    HOLD_DOMAIN_MODAL_CTA: 'Solução - Modal para pagamento exibida com sucesso - Cta Pagar fatura e reativar domínio',
    HOLD_DOMAIN_INVOICE_PAYED: 'Solução - Alerta apresentado com sucesso - Cta Já paguei a fatura',
    HOLD_DOMAIN_OK_CTA: 'Solução - Alerta apresentado com sucesso - Cta Ok, entendi',
    HOLD_DOMAIN_CONTINUE_TO_CHAT: 'Solução - Alerta apresentado com sucesso - Cta Continuar para o chat',
    HOLD_DOMAIN_CTA_REACTIVATE_DOMAIN: 'Solução - Alerta apresentado com sucesso - Cta Ver como reativar o domínio',
    HOLD_DOMAIN_CTA_SOLVED: 'Solução - Feedback de problema resolvido - Cta Sim, foi resolvido!',
    HOLD_DOMAIN_CTA_NEED_HELP: 'Solução - Feedback de problema resolvido - Cta Não, ainda preciso de ajuda',
    BACKUP_ALERT: 'Alerta apresentado com sucesso - Alerta de backup apresentado com sucesso',
    BACKUP_CTA_CLICK: 'Alerta apresentado com sucesso - cta Ir para backup',
    BACKUP_CONTINUE_TO_CHAT: 'Alerta apresentado com sucesso - cta Continuar para o chat',
    BACKUP_PROBLEM_SOLVED: 'Alerta apresentado com sucesso - cta Sim, foi resolvido!',
    BACKUP_NEED_HELP: 'Alerta apresentado com sucesso - cta Não, ainda preciso de ajuda',
    DUPLICATE_ORDER_ALERT: 'Solução - Alerta apresentado com sucesso - Alerta de pedido duplicado apresentado com sucesso',
    DUPLICATE_ORDER_CTA_CLICK: 'Solução - Alerta apresentado com sucesso - Cta ver pedidos duplicados',
    DIAGNOSTIC_CTA: 'Solução - Alerta apresentado com sucesso - Cta quero falar sobre outro assunto',
    PROCESSING_ORDER_ALERT: 'Solução - Alerta apresentado com sucesso - Alerta de pedidos em análise',
    PROCESSING_ORDER_CTA_CLICK: 'Solução - Clique no cta Pedidos em análise - Cta ver pedidos',
    DOMAIN_CANCEL_ALERT: 'exibição do banner de cancelamento de domínio',
    HOST_CANCEL_ALERT: 'exibição do banner de cancelamento de plano',
    CTA_CLICK: 'clique no CTA',
    DOMAIN_REFUND_ALERT: 'exibição do banner de reembolso de domínio',
    TERMINATED_HOST_ALERT: 'exibição do banner de plano encerrado',
    DNS_ALERT: 'exibição do banner de DNS',
    WORDPRESS_ALERT: 'exibição do banner de Instalar WordPress',
    SITEBUILDER_ALERT: 'exibição do banner de ativar criador de sites',
  },
  LABEL: {
    SHOW_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Alerta de domínio apontado para o reservado apresentado com sucesso',
    CTA_SETUP_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - clique no cta configurar domínio',
    CHAT_SETUP_DOMAIN_TO_RESERVED: 'Solução - Autoatendimento domínio apontado para o reservado - Clique no cta',
    SHOW_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - Alerta para limpar o cache apresentado com sucesso',
    CTA_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - clique no cta como limpar o cache do navegador',
    CHAT_CLEAR_CACHE: 'Solução - Autoatendimento limpar cache - clique no cta continuar para o chat',
    SHOW_EPP_KEY: 'Solução - Autoatendimento Chave EPP- Alerta de Chave EPP apresentado com sucesso',
    CTA_EPP_KEY: 'Solução - Autoatendimento Chave EPP - clique no cta gerar chave',
    CHAT_EPP_KEY: 'Solução - Autoatendimento Chave EPP - clique no cta continuar para o chat',
    HOLD_DOMAIN_ALERT: 'Solução - Autoatendimento domínio congelado - Alerta de domínio congelado apresentado com sucesso',
    HOLD_DOMAIN_PAY_INVOICE: 'Solução - Autoatendimento domínio congelado - Clique no cta Pagar fatura e reativar domínio',
    HOLD_DOMAIN_INVOICE_PAYED: 'Solução - Autoatendimento domínio congelado - Clique no cta Já paguei a fatura',
    HOLD_DOMAIN_OK_CTA: 'Solução - Autoatendimento domínio congelado - Clique no cta Ok, entendi',
    HOLD_DOMAIN_CONTINUE_TO_CHAT: 'Solução - Autoatendimento domínio congelado - Clique no cta Continuar para o chat',
    HOLD_DOMAIN_CTA_REACTIVATE_DOMAIN: 'Solução - Autoatendimento domínio congelado - Clique no cta Ver como reativar o domínio',
    HOLD_DOMAIN_CTA_SOLVED: 'Solução - Autoatendimento domínio congelado - Clique no cta Sim, foi resolvido!',
    HOLD_DOMAIN_CTA_NEED_HELP: 'Solução - Autoatendimento domínio congelado - Clique no cta Não, ainda preciso de ajuda',
    BACKUP_ALERT: 'Autoatendimento fluxo de backup - Alerta de backup apresentado com sucesso',
    BACKUP_CTA_CLICK: 'Autoatendimento fluxo de backup - clique no cta Ir para backup',
    BACKUP_CONTINUE_TO_CHAT: 'Autoatendimento fluxo de backup - clique no cta Continuar para o chat',
    BACKUP_PROBLEM_SOLVED: 'Autoatendimento fluxo de backup - clique no cta Sim, foi resolvido!',
    BACKUP_NEED_HELP: 'Autoatendimento fluxo de backup - clique no cta Não, ainda preciso de ajuda',
    DUPLICATE_ORDER_ALERT: 'Solução - Diagnóstico Pedidos duplicados - Alerta de pedido duplicado apresentado com sucesso',
    DUPLICATE_ORDER_CTA_CLICK: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta ver pedidos duplicados',
    DIAGNOSTIC_CTA: 'Solução - Diagnóstico - Clique no cta quero falar sobre outro assunto',
    PROCESSING_ORDER_ALERT: 'Solução - Diagnóstico Pedidos em análise - Alerta de pedido em análise apresentado com sucesso',
    PROCESSING_ORDER_CTA_CLICK: 'Solução - Diagnóstico Pedidos em análise - Clique no cta ver pedidos',
    ALERT_SUCCESS_DISPLAYED: 'Banner exibido com sucesso',
    CTA_CANCEL_SIGNATURE_RENEWAL: 'Clique no CTA Desejo cancelar a renovação da assinatura',
    CTA_CANCEL_SIGNATURE: 'Clique no CTA Desejo cancelar a assinatura',
    CTA_BUY_NEW_HOST: 'Clique no CTA Contratar novo plano de hospedagem',
    CTA_CONTINUE_TO_CHAT: 'Clique no CTA Continuar para o chat',
    CTA_CONFIGURE_DNS: 'Clique no CTA Desejo configurar o domínio/alterar DNS',
    CTA_INSTALL_WORDPRESS: 'Clique no CTA Instalar WordPress',
    CTA_ACTIVATE_SITEBUILDER: 'Clique no CTA Ativar criador de sites',
  },
};

const domainCancelAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CANCELLATION_REDIRECT,
  gaEventAction: EVENT.ACTION.DOMAIN_CANCEL_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const hostCancelAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CANCELLATION_REDIRECT,
  gaEventAction: EVENT.ACTION.HOST_CANCEL_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const cancelRenewalCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CANCELLATION_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CANCEL_SIGNATURE_RENEWAL,
});

const cancelSignatureCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CANCELLATION_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CANCEL_SIGNATURE,
});

const domainRefundAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_REDIRECT,
  gaEventAction: EVENT.ACTION.DOMAIN_REFUND_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const signatureCancelCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CANCEL_SIGNATURE_RENEWAL,
});

const cancelledHostAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TERMINATED_REDIRECT,
  gaEventAction: EVENT.ACTION.TERMINATED_HOST_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const cancelledHostCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TERMINATED_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_BUY_NEW_HOST,
});

const cancelledHostChatCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TERMINATED_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CONTINUE_TO_CHAT,
});

const dnsAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DNS_REDIRECT,
  gaEventAction: EVENT.ACTION.DNS_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const dnsAlertCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DNS_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CONFIGURE_DNS,
});

const wordpressAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.WORDPRESS_REDIRECT,
  gaEventAction: EVENT.ACTION.WORDPRESS_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const wordpressInstallCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.WORDPRESS_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_INSTALL_WORDPRESS,
});

const wordpressChatCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.WORDPRESS_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CONTINUE_TO_CHAT,
});

const sitebuilderAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SITEBUILDER_REDIRECT,
  gaEventAction: EVENT.ACTION.SITEBUILDER_ALERT,
  gaEventLabel: EVENT.LABEL.ALERT_SUCCESS_DISPLAYED,
});

const sitebuilderInstallCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SITEBUILDER_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_ACTIVATE_SITEBUILDER,
});

const sitebuilderChatCTA = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SITEBUILDER_REDIRECT,
  gaEventAction: EVENT.ACTION.CTA_CLICK,
  gaEventLabel: EVENT.LABEL.CTA_CONTINUE_TO_CHAT,
});

const clickChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico- chat',
  gaEventAction: 'Clique CTA Chat',
  gaEventLabel: 'Solução -Diagnóstico- chat',
});

const loadingDiagnostic = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Carregando Diagnóstico - Requisição do serviço',
  gaEventAction: 'Solução - Exibindo Carregando Diagnóstico - Requisição do serviço',
  gaEventLabel: 'Solução - Carregando Diagnóstico - Requisição do serviço',
});

const errorDiagnostic = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura- Alerta apresentado com falha',
  gaEventAction: 'Solução - Alerta apresentado com falha - Requisição do serviço',
  gaEventLabel: 'Solução - Diagnóstico da fatura - Alerta apresentado com falha',
});

const successInvoiceDiagnostic = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - Banner fatura em aberto/vencida',
  gaEventAction: 'Diagnóstico - exibição do banner',
  gaEventLabel: 'Diagnóstico de fatura - exibição do banner',
});

const seeOpenInvoicesClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - Clique CTA',
  gaEventAction: 'Clique CTA - Ver faturas em aberto/vencidas',
  gaEventLabel: 'Solução -Diagnóstico de fatura - Clique CTA',
});

const anotherSubjectClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - Clique CTA Não, quero falar sobre outro assunto',
  gaEventAction: 'Clique CTA - Não, quero falar sobre outro assunto',
  gaEventLabel: 'Solução -Diagnóstico de fatura - Clique CTA Não, quero falar sobre outro assunto',
});

const goToPaymentClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - ir para o pagamento',
  gaEventAction: 'Solução Diagnóstico de fatura - clique CTA ir para o pagamento',
  gaEventLabel: 'Diagnóstico de fatura - clique CTA ir para o pagamento',
});

const invoiceFinalStepSuccessClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - etapa final',
  gaEventAction: 'Solução Diagnóstico de fatura - clique CTA -  Sim, foi resolvido!',
  gaEventLabel: 'Solução Diagnóstico de fatura - clique CTA -  Sim, foi resolvido!',
});

const invoiceFinalStepStillNeedsHelpClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução -Diagnóstico de fatura - etapa final',
  gaEventAction: 'Solução Diagnóstico de fatura - clique CTA -  Não, ainda preciso de ajuda!',
  gaEventLabel: 'Solução Diagnóstico de fatura - clique CTA -  Não, ainda preciso de ajuda!',
});

const invoiceFinalStepReturnDiagnosticClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico de fatura - etapa final -  Retornar Diagnóstico da conta',
  gaEventAction: 'Solução Diagnóstico de fatura - clique CTA -  retornar para tela de consulta do diagnóstico da sua conta!',
  gaEventLabel: 'Solução Diagnóstico de fatura - clique CTA -  retornar para tela de consulta do diagnóstico da sua conta!',
});

const refundFlux = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Alerta apresentado com sucesso',
  gaEventLabel: 'Requisição do serviço',
});

const seeRefundStatus = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Consulta de status de reembolso',
  gaEventLabel: 'Visualização de status de reembolso',
});

const refundFluxCompleted = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Fechamento de chat',
  gaEventLabel: 'Conclusão do atendimento',
});

const refundSeeHelp = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Acesso ao material de ajuda',
  gaEventLabel: 'Leitura do material de ajuda',
});

const openRefundAnotherSubjectClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Seleção de reembolso em andamento',
  gaEventLabel: 'Direcionamento para operação',
});

const closedRefundAnotherSubjectClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Alerta - reembolso realizado/Direcionamento para operação',
  gaEventLabel: 'Direcionamento para operação',
});

const avoidRefundAnotherSubjectClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.REFUND_DIAGNOSTIC,
  gaEventAction: 'Alerta de reembolso apresentado - início',
  gaEventLabel: 'Direcionamento para operação',
});

const subscriptionPageReactivationClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TRUST_REACTIVATION_PORTAL,
  gaEventAction: 'Clique CTA - Reativar minha assinatura',
  gaEventLabel: 'Solução - Portal reativação por confiança',
});

const successTrustReactivation = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TRUST_REACTIVATION_PORTAL,
  gaEventAction: 'Clique CTA - Reativar minha assinatura',
  gaEventLabel: 'Solução - Portal reativação por confiança sucesso',
});

const failTrustReactivation = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TRUST_REACTIVATION_PORTAL,
  gaEventAction: 'Clique CTA - Reativar minha assinatura',
  gaEventLabel: 'Solução - Portal reativação por confiança falha',
});

const wantSubscriptionReactivationClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TRUST_REACTIVATION_DIAGNOSTIC,
  gaEventAction: 'Clique CTA - Quero reativar minha assinatura',
  gaEventLabel: 'Diagnóstico reativação por confiança',
});

const dontWantSubscriptionReactivationClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.TRUST_REACTIVATION_DIAGNOSTIC,
  gaEventAction: 'Clique CTA - Não quero reativar minha assinatura',
  gaEventLabel: 'Diagnóstico Não reativar por confiança',
});

const continueToChatClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_SELF_SERVICE,
  gaEventAction: 'Clique CTA - Continuar para o chat',
  gaEventLabel: 'Continuar para o chat',
});

const problemIsSolvedClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_SELF_SERVICE,
  gaEventAction: 'Clique CTA - problema resolvido',
  gaEventLabel: 'Sim, foi resolvido!',
});

const problemIsNotSolvedClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_SELF_SERVICE,
  gaEventAction: 'Clique CTA - problema resolvido',
  gaEventLabel: 'Não, ainda preciso de ajuda',
});

const clickCloseChat = ({ module, flow, step }) => {
  const stepLabel = step ? ` - ${step}` : '';

  collect({
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_MODULE,
    gaEventAction: 'Clique no Botão Fechar',
    gaEventLabel: `Solução - ${module} - ${flow}${stepLabel}`,
  });
};

const clickConfirmCloseChat = ({ module, flow, step }) => {
  const stepLabel = step ? ` - ${step}` : '';

  collect({
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_MODULE,
    gaEventAction: 'Clique - Sim, Fechar Chat',
    gaEventLabel: `Solução - ${module} - ${flow}${stepLabel}`,
  });
};

const clickCancelCloseChat = ({ module, flow, step }) => {
  const stepLabel = step ? ` - ${step}` : '';

  collect({
    event: EVENT.NAME,
    gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_MODULE,
    gaEventAction: 'Clique - Não, Manter Chat Aberto',
    gaEventLabel: `Solução - ${module} - ${flow}${stepLabel}`,
  });
};

const domainDiagnosticSuccess = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico Domínio - Alerta apresentado com sucesso',
  gaEventAction: 'Alerta apresentado com sucesso - Requisição do serviço',
  gaEventLabel: 'Solução - Diagnóstico Domínio - Alerta apresentado com sucesso Requisição do serviço',
});

const seeDomainsPropagationClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_PROPAGATION,
  gaEventAction: 'Clique CTA - ver domínios em propagação',
  gaEventLabel: 'Diagnóstico ver domínios em propagação',
});

const anotherSubjectPropagationClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_PROPAGATION,
  gaEventAction: 'Clique CTA - Não, quero falar sobre outro assunto',
  gaEventLabel: 'Solução - Diagnóstico falar sobre outro assunto',
});

const configureDomainClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_CONFIGURATION,
  gaEventAction: 'Clique CTA - Configurar domínio',
  gaEventLabel: 'Solução - Diagnóstico configuração de domínio',
});

const anotherSubjectConfigureClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_CONFIGURATION,
  gaEventAction: 'Clique CTA - Não, quero falar sobre outro assunto',
  gaEventLabel: 'Solução - Diagnóstico falar sobre outro assunto',
});

const contractDomainClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_CONTRACT,
  gaEventAction: 'Clique CTA - Contratar domínio',
  gaEventLabel: 'Solução - Diagnóstico contratação de domínio',
});

const anotherSubjectContractClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_CONTRACT,
  gaEventAction: 'Clique CTA - Não, quero falar sobre outro assunto',
  gaEventLabel: 'Solução - Diagnóstico falar sobre outro assunto',
});

const diagnosticTosSuccess = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico Site Offline TOS - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - Requisição do serviço',
  gaEventLabel: 'Solução - Diagnóstico Site Offline TOS - Alerta apresentado com sucesso Requisição do serviço',
});

const goToTosTicket = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico Site Offline TOS - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - direcionamento para o chamado',
  gaEventLabel: 'Solução - Diagnóstico Site Offline TOS - Ir para o chamado',
});

const tosModalDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico Site Offline TOS - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - modal apresentada no portal do cliente',
  gaEventLabel: 'Solução - Diagnóstico Site Offline TOS - Modal ir para o chamado',
});

const tosGoToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Diagnóstico Site Offline TOS - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - direcionamento para o chat',
  gaEventLabel: 'Solução - Diagnóstico Site Offline TOS - Entrar no chat',
});

const changePlanAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar plano - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - Alerta alterar plano apresentado com sucesso',
  gaEventLabel: 'Solução - Autoatendimento alterar plano - Alerta alterar plano apresentado com sucesso',
});

const goToChangePlan = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar plano',
  gaEventAction: 'Solução - Autoatendimento alterar plano - direcionamento para o portal',
  gaEventLabel: 'Solução - Autoatendimento alterar plano - Ir para alteração de plano',
});

const changePlanGoToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar plano',
  gaEventAction: 'Solução - Autoatendimento alterar plano - continuar para o chat',
  gaEventLabel: 'Solução - Autoatendimento alterar plano - continuar para o chat',
});

const vpsUpgradeAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento upgrade de VPS - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - Alerta upgrade de VPS apresentado com sucesso',
  gaEventLabel: 'Solução - Autoatendimento upgrade de VPS - Alerta upgrade de VPS apresentado com sucesso',
});

const vpsUpgradeOpenTicket = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento upgrade de VPS',
  gaEventAction: 'Autoatendimento upgrade de VPS - direcionamento para o chamado no portal',
  gaEventLabel: 'Solução - Autoatendimento upgrade de VPS - Abrir chamado de upgrade',
});

const vpsUpgradeModal = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento upgrade de VPS',
  gaEventAction: 'Solução - Autoatendimento upgrade de VPS - modal de upgrade no portal',
  gaEventLabel: 'Solução - Autoatendimento upgrade de VPS - Ok, entendi',
});

const vpsUpgradeGoToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento upgrade de VPS',
  gaEventAction: 'Solução - Autoatendimento upgrade de VPS - continuar para o chat',
  gaEventLabel: 'Solução - Autoatendimento upgrade de VPS - continuar para o chat',
});

const changeCycleAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar ciclo de pagamento - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - Alerta alterar ciclo de pagamento apresentado com sucesso',
  gaEventLabel: 'Solução - Autoatendimento alterar ciclo de pagamento - Alerta alterar ciclo de pagamento apresentado com sucesso',
});

const goToChangeCycle = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar ciclo de pagamento',
  gaEventAction: 'Solução - Autoatendimento alterar ciclo de pagamento - direcionamento para o portal',
  gaEventLabel: 'Solução - Autoatendimento alterar ciclo de pagamento - Ir para alteração de ciclo de pagamento',
});

const changeCycleGoToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar ciclo de pagamento',
  gaEventAction: 'Solução - Autoatendimento alterar ciclo de pagamento - continuar para o chat',
  gaEventLabel: 'Solução - Autoatendimento alterar ciclo de pagamento - continuar para o chat',
});

const changePaymentMethodAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar método de pagamento - Alerta apresentado com sucesso',
  gaEventAction: 'Solução - Alerta apresentado com sucesso - Alerta alterar método de pagamento apresentado com sucesso',
  gaEventLabel: 'Solução - Autoatendimento alterar método de pagamento - Alerta alterar método de pagamento apresentado com sucesso',
});

const goToChangePaymentMethod = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar método de pagamento',
  gaEventAction: 'Solução - Autoatendimento alterar método de pagamento - direcionamento para o portal',
  gaEventLabel: 'Solução - Autoatendimento alterar método de pagamento - Ir para alteração do método de pagamento',
});

const changePaymentMethodGoToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: 'Solução - Autoatendimento alterar método de pagamento',
  gaEventAction: 'Solução - Autoatendimento alterar método de pagamento - continuar para o chat',
  gaEventLabel: 'Solução - Autoatendimento alterar método de pagamento - continuar para o chat',
});

const showRservedDomainAlert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SHOW_DOMAIN_TO_RESERVED,
  gaEventAction: EVENT.ACTION.SHOW_DOMAIN_TO_RESERVED,
  gaEventLabel: EVENT.LABEL.SHOW_DOMAIN_TO_RESERVED,
});

const ctaRservedDomainAlert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CTA_SETUP_DOMAIN_TO_RESERVED,
  gaEventAction: EVENT.ACTION.CTA_SETUP_DOMAIN_TO_RESERVED,
  gaEventLabel: EVENT.LABEL.CTA_SETUP_DOMAIN_TO_RESERVED,
});

const chatRservedDomainAlert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_SETUP_DOMAIN_TO_RESERVED,
  gaEventAction: EVENT.ACTION.CHAT_SETUP_DOMAIN_TO_RESERVED,
  gaEventLabel: EVENT.LABEL.CHAT_SETUP_DOMAIN_TO_RESERVED,
});

const showClearCache = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SHOW_CLEAR_CACHE,
  gaEventAction: EVENT.ACTION.SHOW_CLEAR_CACHE,
  gaEventLabel: EVENT.LABEL.SHOW_CLEAR_CACHE,
});

const ctaClearCache = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CTA_CLEAR_CACHE,
  gaEventAction: EVENT.ACTION.CTA_CLEAR_CACHE,
  gaEventLabel: EVENT.LABEL.CTA_CLEAR_CACHE,
});

const chatClearCache = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_CLEAR_CACHE,
  gaEventAction: EVENT.ACTION.CHAT_CLEAR_CACHE,
  gaEventLabel: EVENT.LABEL.CHAT_CLEAR_CACHE,
});

const showEppKey = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.SHOW_EPP_KEY,
  gaEventAction: EVENT.ACTION.SHOW_EPP_KEY,
  gaEventLabel: EVENT.LABEL.SHOW_EPP_KEY,
});

const ctaEppKey = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CTA_EPP_KEY,
  gaEventAction: EVENT.ACTION.CTA_EPP_KEY,
  gaEventLabel: EVENT.LABEL.CTA_EPP_KEY,
});

const chatEppKey = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CHAT_EPP_KEY,
  gaEventAction: EVENT.ACTION.CHAT_EPP_KEY,
  gaEventLabel: EVENT.LABEL.CHAT_EPP_KEY,
});

const holdDomainAlertDisplayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_ALERT,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_ALERT,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_ALERT,
});

const holdDomainPayInvoice = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_PAY_INVOICE,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_PAY_INVOICE,
});

const holdDomainModalPayInvoice = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_MODAL,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_MODAL_CTA,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_PAY_INVOICE,
});

const holdDomainInvoicePayed = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_INVOICE_PAYED,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_INVOICE_PAYED,
});

const holdDomainOkUnderstood = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_OK_CTA,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_OK_CTA,
});

const holdDomainContinueToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_CONTINUE_TO_CHAT,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_CONTINUE_TO_CHAT,
});

const holdDomainReactivate = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_CTA_REACTIVATE_DOMAIN,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_CTA_REACTIVATE_DOMAIN,
});

const holdDomainProblemSolved = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_CTA_SOLVED,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_CTA_SOLVED,
});

const holdDomainNeedHelp = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.HOLD_DOMAIN_CTA,
  gaEventAction: EVENT.ACTION.HOLD_DOMAIN_CTA_NEED_HELP,
  gaEventLabel: EVENT.LABEL.HOLD_DOMAIN_CTA_NEED_HELP,
});

const backupAlert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_ALERT,
  gaEventAction: EVENT.ACTION.BACKUP_ALERT,
  gaEventLabel: EVENT.LABEL.BACKUP_ALERT,
});

const backupCTAClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_CTA_CLICK,
  gaEventAction: EVENT.ACTION.BACKUP_CTA_CLICK,
  gaEventLabel: EVENT.LABEL.BACKUP_CTA_CLICK,
});

const backupContinueToChat = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_CTA_CLICK,
  gaEventAction: EVENT.ACTION.BACKUP_CONTINUE_TO_CHAT,
  gaEventLabel: EVENT.LABEL.BACKUP_CONTINUE_TO_CHAT,
});

const backupProblemSolved = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_CTA_CLICK,
  gaEventAction: EVENT.ACTION.BACKUP_PROBLEM_SOLVED,
  gaEventLabel: EVENT.LABEL.BACKUP_PROBLEM_SOLVED,
});

const backupNeedHelp = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_CTA_CLICK,
  gaEventAction: EVENT.ACTION.BACKUP_NEED_HELP,
  gaEventLabel: EVENT.LABEL.BACKUP_NEED_HELP,
});

const duplicateOrderALert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_ALERT,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_ALERT,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_ALERT,
});

const duplicateOrderCTAClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_CTA_CLICK,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_CTA_CLICK,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_CTA_CLICK,
});

const goToChatTriage = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DIAGNOSTIC_CTA,
  gaEventAction: EVENT.ACTION.DIAGNOSTIC_CTA,
  gaEventLabel: EVENT.LABEL.DIAGNOSTIC_CTA,
});

const processingOrderAlert = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.PROCESSING_ORDER_ALERT,
  gaEventAction: EVENT.ACTION.PROCESSING_ORDER_ALERT,
  gaEventLabel: EVENT.LABEL.PROCESSING_ORDER_ALERT,
});

const processingOrderCTAClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.PROCESSING_ORDER_CTA_CLICK,
  gaEventAction: EVENT.ACTION.PROCESSING_ORDER_CTA_CLICK,
  gaEventLabel: EVENT.LABEL.PROCESSING_ORDER_CTA_CLICK,
});

const analytics = {
  clickChat,
  loadingDiagnostic,
  errorDiagnostic,
  successInvoiceDiagnostic,
  seeOpenInvoicesClick,
  anotherSubjectClick,
  goToPaymentClick,
  invoiceFinalStepSuccessClick,
  invoiceFinalStepStillNeedsHelpClick,
  invoiceFinalStepReturnDiagnosticClick,
  refundFlux,
  seeRefundStatus,
  refundFluxCompleted,
  refundSeeHelp,
  openRefundAnotherSubjectClick,
  closedRefundAnotherSubjectClick,
  avoidRefundAnotherSubjectClick,
  subscriptionPageReactivationClick,
  successTrustReactivation,
  failTrustReactivation,
  wantSubscriptionReactivationClick,
  dontWantSubscriptionReactivationClick,
  continueToChatClick,
  problemIsSolvedClick,
  problemIsNotSolvedClick,
  clickCloseChat,
  clickConfirmCloseChat,
  clickCancelCloseChat,
  domainDiagnosticSuccess,
  seeDomainsPropagationClick,
  anotherSubjectPropagationClick,
  configureDomainClick,
  anotherSubjectConfigureClick,
  contractDomainClick,
  anotherSubjectContractClick,
  diagnosticTosSuccess,
  goToTosTicket,
  tosModalDisplayed,
  tosGoToChat,
  changePlanAlertDisplayed,
  goToChangePlan,
  changePlanGoToChat,
  vpsUpgradeAlertDisplayed,
  vpsUpgradeOpenTicket,
  vpsUpgradeModal,
  vpsUpgradeGoToChat,
  changeCycleAlertDisplayed,
  goToChangeCycle,
  changeCycleGoToChat,
  changePaymentMethodAlertDisplayed,
  goToChangePaymentMethod,
  changePaymentMethodGoToChat,
  showRservedDomainAlert,
  ctaRservedDomainAlert,
  chatRservedDomainAlert,
  showClearCache,
  ctaClearCache,
  chatClearCache,
  showEppKey,
  ctaEppKey,
  chatEppKey,
  holdDomainAlertDisplayed,
  holdDomainPayInvoice,
  holdDomainModalPayInvoice,
  holdDomainInvoicePayed,
  holdDomainOkUnderstood,
  holdDomainContinueToChat,
  holdDomainReactivate,
  holdDomainProblemSolved,
  holdDomainNeedHelp,
  backupAlert,
  backupCTAClick,
  backupContinueToChat,
  backupProblemSolved,
  backupNeedHelp,
  duplicateOrderALert,
  duplicateOrderCTAClick,
  goToChatTriage,
  processingOrderAlert,
  processingOrderCTAClick,
  domainCancelAlertDisplayed,
  hostCancelAlertDisplayed,
  cancelRenewalCTA,
  cancelSignatureCTA,
  domainRefundAlertDisplayed,
  signatureCancelCTA,
  cancelledHostAlertDisplayed,
  cancelledHostCTA,
  cancelledHostChatCTA,
  dnsAlertDisplayed,
  dnsAlertCTA,
  wordpressAlertDisplayed,
  wordpressInstallCTA,
  wordpressChatCTA,
  sitebuilderAlertDisplayed,
  sitebuilderInstallCTA,
  sitebuilderChatCTA,
};

export default analytics;
