import React from 'react';
import * as Style from './IconMinimize.style';

const IconMinimize = ({
  testId = 'icon-minimize', onClick, title, smallEmbed,
}) => (
  <Style.Wrapper data-testid={testId} onClick={onClick} title={title}>
    {!smallEmbed && (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_60_3713" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_60_3713)">
          <path d="M2.26659 14.6667L1.33325 13.7334L5.73325 9.33337H2.66659V8.00004H7.99992V13.3334H6.66658V10.2667L2.26659 14.6667ZM7.99992 8.00004V2.66671H9.33325V5.73337L13.7333 1.33337L14.6666 2.26671L10.2666 6.66671H13.3333V8.00004H7.99992Z" fill="white" />
        </g>
      </svg>
    )}
    {smallEmbed && (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="#D9D9D9" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 14V8H2V12H6V14H0ZM12 6V2H8V0H14V6H12Z" fill="white" />
      </svg>
    )}

  </Style.Wrapper>
);


export default IconMinimize;
