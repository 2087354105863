import {
  SET_AUTH_TOKEN, UNSET_AUTH_TOKEN, SET_JWT_TOKEN, SET_REFRESH_TOKEN,
} from './actionsTypes';

export const setAuthToken = token => ({
  type: SET_AUTH_TOKEN,
  token,
});

export const unsetAuthToken = () => ({
  type: UNSET_AUTH_TOKEN,
});

export const setJWT = token => ({
  type: SET_JWT_TOKEN,
  token,
});

export const setRefreshToken = token => ({
  type: SET_REFRESH_TOKEN,
  token,
});
