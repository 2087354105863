import React, { useEffect } from 'react';
import { IconWarning } from '@/icons';
import { Link, Button } from '@/pages/common';
import * as Styles from './Confirmation.styles';

const Confirmation = ({
  title = 'Please read the information below before confirming or canceling your subscription:',
  attentionLabel = 'Attention',
  backupFilesLinkLabel = 'See how to back up your files',
  backupFilesLinkUrl = '',
  stopCancelLabel = "I don't want to cancel the renewal anymore",
  onStopCancel,
  topics = [],
  testId = 'confirmation',
  showBackfilesLink = false,
  onClickBackupLink,
  onShowPageAction,
}) => {
  useEffect(() => {
    onShowPageAction && onShowPageAction();
  }, [onShowPageAction]);

  const handleOnStopCancelClick = () => {
    onStopCancel && onStopCancel();
  };

  const hadleOnClickBackupLink = () => {
    onClickBackupLink && onClickBackupLink();
  };

  const topicsMap = topics.map(topic => <>{topic}</>);
  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title>
        {title}
      </Styles.Title>
      <Styles.Warning>
        <Styles.Attention>
          <IconWarning size={22} />
          <Styles.AttentionLabel>
            {attentionLabel}
          </Styles.AttentionLabel>
        </Styles.Attention>
        <Styles.Content>

          <Styles.List>
            { topicsMap }
          </Styles.List>

          <Styles.Links>
            { showBackfilesLink && (
              <Styles.LinksItem>
                <Link
                  onClick={() => hadleOnClickBackupLink()}
                  href={backupFilesLinkUrl}
                  text={backupFilesLinkLabel}
                  testId={`${testId}-backupfiles-link`}
                  target="_blank"
                />
              </Styles.LinksItem>
            )}
            <Styles.LinksItem>
              <Button
                variant="link"
                size="large"
                align="left"
                onClick={handleOnStopCancelClick}
                label={stopCancelLabel}
                testId={`${testId}-stopcancel-button`}
              />
            </Styles.LinksItem>
          </Styles.Links>
        </Styles.Content>
      </Styles.Warning>
    </Styles.Wrapper>
  );
};

export default Confirmation;
