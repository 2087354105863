import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@/components/Layout/Menu/Menu';
import { TopAdvertisementHandler } from '@/pages/advertisement/TopAdvertisementHandler';
import Trakto from '@/media/partners/traktoHG.svg';
import Cobrefacil from '@/media/partners/cobrefacilHG.svg';
import Creditas from '@/media/partners/creditasHG.svg';
import styles, { Styles } from './styles';
import { TopTitanAdvertisementHandler } from '@/pages/titanAdvertisement';
import { useHistory } from 'react-router';
import { TitanAdvertisementModal } from '@/pages/sites/components/TitanAdvertisementModal';
import {
  requestGetProEmailList,
} from '@/redux/actions/emails';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import { ERROR_GET_PRO_EMAILS_LIST } from '@/redux/actions/actionsTypes';
import { useResize } from '@/hooks/useResize';
import { ThemeContext } from 'styled-components';
import { useMenu } from '@/hooks/useMenu';
import HeaderV2 from '../Menu/HeaderV2/HeaderV2';
import { SERVICE_STATUS_URL } from '@/config/urls/statusHGUrls';
import { LOGOUT_URL } from '@/config/whmcsUrls';
import { MANAGE_USERS_URL } from '@/config/urls/usersUrls';
import { billingAnalytics } from '@/analytics';
import { financialIds, profileIds } from '../Menu/HeaderV2/HeaderV2.enum';
import { track as trackAmplitude } from '@amplitude/analytics-browser';
import {
  ALPHA_FRONT_TITAN_PROMOTE_IN_SITES, CHARLIE_BRADCRUMB_TO_HISTORY_BACK, CHARLIE_FORCE_UPDATE_JWT_IN_REDUCERS, CHARLIE_LOGOUT_MODAL, EXPERIENCE_ACCOUNT_SECURITY,
  NEW_SUPPORT,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import ModalGtm from '@/components/Modal/ModalGtm';
import { executeUniqueEvent } from '@/utils/ThirdParties/tagManager';
import { LogoutModal } from './components/LogoutModal';
import useLocale from '@/hooks/useLocale';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from './Template.types';
import { Guides } from './components/Guides';
import { preChatActions } from '@/redux/modules';

const partnerImages = {
  trakto: Trakto,
  cobrefacil: Cobrefacil,
  creditas: Creditas,
};

const Template = ({
  classes, width, children, t,
}) => {
  const [asaasVariation] = useState('variation_A');
  const changeBreadcrumbToGoBack = useFeatureIsOn(CHARLIE_BRADCRUMB_TO_HISTORY_BACK);
  const moreThanOneUser = useSelector(state => state.summary.isContacts);
  const { hasRefreshError } = useSelector(state => state.auth);
  const { template: templateLocale } = useLocale();
  const enableLogoutModal = useFeatureIsOn(CHARLIE_LOGOUT_MODAL);
  const forceUpdateJwtInReducers = useFeatureIsOn(CHARLIE_FORCE_UPDATE_JWT_IN_REDUCERS);
  const isAccountSecurityEnabled = useFeatureIsOn(EXPERIENCE_ACCOUNT_SECURITY);
  const useNewSupport = useFeatureIsOn(NEW_SUPPORT);

  const [open, setOpen] = useState(isWidthUp('sm', width));
  const isMobile = isWidthDown('sm', width);
  const dispatch = useDispatch();
  const windowRect = useResize();
  const theme = useContext(ThemeContext);
  const crumbs = useSelector(state => state.breadCrumbs.crumbs);

  const [partner, setPartner] = useState(null);
  const [displayModalTitanAdvertisementHandler, setDisplayModalTitanAdvertisementHandler] = useState(false);

  const bannerTop = '';
  const enabledNewStatusDomain = useFeatureIsOn(ALPHA_FRONT_TITAN_PROMOTE_IN_SITES);
  const history = useHistory();
  const isSitePage = [t('routes.sitesPage'), t('routes.sites')].includes(history.location.pathname);

  const [shouldRenderTopBanner, setShouldRenderTopBanner] = useState(false);
  const [domainOptions, setDomainOptions] = useState([]);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const handleClick = ({ id, route, label }) => {
    if (label) {
      trackAmplitude(`click header item ${label}`);
    }

    if (id && id === financialIds.subscriptions) {
      subscriptionAnalytics.subscriptionsMenuClick();
    }

    const shouldOpenNewTab = id && (id === profileIds.serviceStatus || id === profileIds.manageUsers);
    const isLogout = id && (id === profileIds.logout);

    if (shouldOpenNewTab) {
      localStorage.removeItem('token');
      window.open(route);
    }

    if (isLogout) {
      localStorage.removeItem('token');
      window.location = route;
    }

    if (!shouldOpenNewTab && !isLogout) {
      if (useNewSupport && route === t('routes.help')) {
        dispatch(preChatActions.preChat.setShowSidePanel());
        return;
      }

      history.push(route);
    }
  };

  const menu = useMenu({
    moreThanOneUser, handleClick, profileIds, MANAGE_USERS_URL,
  });

  const [eventReady, setEventReady] = useState({
    one: false,
    two: false,
  });
  const allEmails = useSelector(state => state.emails.proEmailList);
  const domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');

  const typeAdvertisement = 'Top Banner';

  useEffect(() => {
    switch (bannerTop) {
      case 'cobre_facil': return setPartner('cobrefacil');
      case 'trakto': return setPartner('trakto');
      default: return setPartner(null);
    }
  }, [bannerTop]);

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!eventReady.one && isSitePage && domainOptions.length > 0) {
      executeUniqueEvent(t('titanAdvertisement.modalGtm.event.one'));
      setEventReady({ ...eventReady, one: true });
    }
  }, [t, eventReady, isSitePage, domainOptions]);

  useEffect(() => {
    if (!eventReady.two && isSitePage && domainWithoutEmail) {
      executeUniqueEvent(t('titanAdvertisement.modalGtm.event.two'));
      setEventReady({ ...eventReady, two: true });
    }
  }, [t, eventReady, isSitePage, domainWithoutEmail]);

  const handleToggleMenu = useCallback(() => {
    const isMobile = windowRect.width <= theme.v1.breakpoints.md.replace(/px/, '');
    isMobile && setOpen(!open);
  }, [open, windowRect, theme]);

  useEffect(() => {
    if (isSitePage) {
      const isTypeTopBanner = true;
      setShouldRenderTopBanner(isTypeTopBanner);
    }
  }, [isSitePage]);

  useEffect(() => {
    if (enabledNewStatusDomain && isSitePage) {
      dispatch(requestGetProEmailList(28)).then((response) => {
        if (response.type === ERROR_GET_PRO_EMAILS_LIST) {
          dispatch(enqueueSnackbar(t('proEmail.failedToLoadList'), notifierError));
          return null;
        }
        setDomainOptions(response.data.filter(item => item.status && item.status.toLowerCase() === 'active' && !item.configured));
      });
    }
  }, [dispatch, t, isSitePage, enabledNewStatusDomain]);

  const renderMenu = useCallback(() => (
    <Menu
      items={menu.items({ asaasVariation })}
      open={open}
      onMenuClick={handleToggleMenu}
    />
  ), [
    handleToggleMenu,
    open,
    menu,
    asaasVariation,
  ]);

  const getHeaderV2Props = () => {
    const defaultProps = {
      onToggleMenu: handleToggleMenu,
      routeMyData: t('routes.myData'),
      labelMyData: t('menu.myData'),
      routeChangePassword: t('routes.changePassword'),
      labelChangePassword: t('menu.changePassword'),
      routeAccountSecurity: t('routes.accountSecurity'),
      labelAccountSecurity: t('menu.accountSecurity'),
      routeServicesStatus: SERVICE_STATUS_URL,
      labelServicesStatus: t('menu.servicesStatus'),
      routeLogout: LOGOUT_URL,
      labelLogout: t('menu.quit'),
      moreThanOneUser,
      routeManageUsers: MANAGE_USERS_URL,
      routeSupport: t('routes.help'),
      labelSupport: t('menu.help'),
      labelManageUsers: t('menu.manageUsers'),
      routesInvoices: t('routes.invoices'),
      labelInvoices: t('menu.billing'),
      rotutesSubscriptions: t('routes.subscriptions'),
      labelSubscriptions: t('menu.subscriptions'),
      routeCreditCard: t('routes.creditcard'),
      labelCreditCard: t('menu.creditCard'),
      financialLabel: t('menu.newBilling'),
      profileLabel: t('menu.profile'),
      myAlertsLabel: t('menu.myAlerts'),
      allNotificationsLabel: t('menu.allNotifications'),
      ticketNotificationsLabel: t('menu.ticketNotifications'),
      seeMoreLabel: t('menu.seeMore'),
      alertsTooltipLabel: t('menu.alertsTooltip'),
      noNotificationsLabel: t('menu.noNotifications'),
    };

    const pageIcon = crumbs[0] ? crumbs[0].icon : '';
    const hasGoBackButton = crumbs.length >= 2;

    const changePasswordItem = {
      id: profileIds.changePassword,
      leftIcon: 'IconPassword',
      leftIconSize: 'md',
      label: defaultProps.labelChangePassword,
      onClick: () => handleClick({ id: profileIds.changePassword, route: defaultProps.routeChangePassword, label: defaultProps.labelChangePassword }),
    };

    const accountSecurityItem = {
      id: profileIds.accountSecurity,
      leftIcon: 'IconPassword',
      leftIconSize: 'md',
      label: defaultProps.labelAccountSecurity,
      onClick: () => handleClick({ id: profileIds.accountSecurity, route: defaultProps.routeAccountSecurity, label: defaultProps.labelAccountSecurity }),
    };

    const profileItems = () => {
      const items = [
        {
          id: profileIds.myData,
          leftIcon: 'IconData',
          leftIconSize: 'md',
          label: defaultProps.labelMyData,
          onClick: () => handleClick({ id: profileIds.myData, route: defaultProps.routeMyData, label: defaultProps.labelMyData }),
        },
        {
          id: profileIds.serviceStatus,
          leftIcon: 'IconStatus',
          leftIconSize: 'md',
          label: defaultProps.labelServicesStatus,
          onClick: () => handleClick({ id: profileIds.serviceStatus, route: defaultProps.routeServicesStatus, label: defaultProps.labelServicesStatus }),
        },
      ];

      if (moreThanOneUser) {
        items.push({
          id: profileIds.manageUsers,
          leftIcon: 'IconPerfil',
          leftIconSize: 'md',
          label: defaultProps.labelManageUsers,
          onClick: () => handleClick({ id: profileIds.manageUsers, route: defaultProps.routeManageUsers, label: defaultProps.labelManageUsers }),
        });
      }

      items.push({
        id: profileIds.logout,
        leftIcon: 'IconExit',
        leftIconSize: 'md',
        label: defaultProps.labelLogout,
        onClick: () => handleClick({ id: profileIds.logout, route: defaultProps.routeLogout, label: defaultProps.labelLogout }),
      });

      if (!moreThanOneUser) {
        const manageIndex = items.findIndex(item => item.id === profileIds.manageUsers);
        items.splice(manageIndex, 0);
      }

      isAccountSecurityEnabled ? items.splice(1, 0, accountSecurityItem) : items.splice(1, 0, changePasswordItem);

      return (items);
    };

    const financialItems = () => {
      const items = [
        {
          id: financialIds.invoices,
          leftIcon: 'IconInvoice',
          leftIconSize: 'md',
          label: defaultProps.labelInvoices,
          onClick: () => handleClick({ id: financialIds.invoices, route: defaultProps.routesInvoices, label: defaultProps.labelInvoices }),
        },
        {
          id: financialIds.subscriptions,
          leftIcon: 'IconSubscription',
          leftIconSize: 'md',
          label: defaultProps.labelSubscriptions,
          onClick: () => handleClick({ id: financialIds.subscriptions, route: defaultProps.rotutesSubscriptions, label: defaultProps.labelSubscriptions }),
        },
        {
          id: financialIds.creditCard,
          leftIcon: 'IconCreditCard',
          leftIconSize: 'md',
          label: defaultProps.labelCreditCard,
          onClick: () => handleClick({ id: financialIds.creditCard, route: defaultProps.routeCreditCard, label: defaultProps.labelCreditCard }),
        },
      ];

      return items;
    };

    const handleChangeRoute = () => {
      if (changeBreadcrumbToGoBack && history.length) {
        history.goBack();
      } else {
        history.push(crumbs[0].path);
      }
    };

    const pageTitle = () => {
      if (hasGoBackButton) {
        return crumbs[1] && crumbs[1].name;
      }

      return crumbs[0] && crumbs[0].name;
    };

    return {
      ...defaultProps,
      handleClick,
      profileItems: profileItems(),
      financialItems: financialItems(),
      handleChangeRoute,
      pageIcon,
      hasGoBackButton,
      pageTitle,
    };
  };

  const getLogoutModalProps = () => {
    const defaultProps = {
      onClose: () => { },
      title: templateLocale.logoutModal.title,
      description: templateLocale.logoutModal.description,
      cta: templateLocale.logoutModal.cta,
    };

    const onClose = () => {
      window.location.href = LOGOUT_URL;
    };

    return {
      ...defaultProps,
      onClose,
    };
  };

  const setCookieForReduxToggles = useCallback(() => {
    Cookies.set(TEMPLATE_COOKIES.ENABLE_LOGOUT_MODAL, enableLogoutModal);
    Cookies.set(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS, forceUpdateJwtInReducers);
  }, [enableLogoutModal, forceUpdateJwtInReducers]);

  useEffect(() => {
    setCookieForReduxToggles();
  }, [setCookieForReduxToggles]);

  return (
    <div className={classes.root}>
      {hasRefreshError && (
        <LogoutModal {...getLogoutModalProps()} />
      )}

      <Guides />

      {enabledNewStatusDomain && isSitePage && domainOptions.length > 0 && (
        <TitanAdvertisementModal
          domainOptions={domainOptions}
          typeAdvertisement={typeAdvertisement}
          displayModal={displayModalTitanAdvertisementHandler}
          setDisplayModal={setDisplayModalTitanAdvertisementHandler}
        />
      )}

      <CssBaseline />
      {renderMenu()}

      <main className={classes.content}>
        <Styles.HeaderWrapper>
          {partner && (
            <TopAdvertisementHandler
              ctaLabel={t(`advertisement.top.${partner}.ctaLabel`)}
              title={t(`advertisement.top.${partner}.title`)}
              logo={partnerImages[partner]}
              partnerInfo={partner}
              href={t(`advertisement.top.${partner}.href`)}
            />
          )}

          {shouldRenderTopBanner && enabledNewStatusDomain && isSitePage && domainOptions.length > 0 && (
            <TopTitanAdvertisementHandler
              newTag={t('titanAdvertisement.top.new')}
              title={t('titanAdvertisement.top.title')}
              description={t('titanAdvertisement.top.description_0')}
              descriptionDomain={t('titanAdvertisement.top.description_1')}
              labelLink={t('titanAdvertisement.top.labelLink')}
              typeAdvertisement={typeAdvertisement}
              displayModalTitanAdvertisementHandler={displayModalTitanAdvertisementHandler}
              setDisplayModalTitanAdvertisementHandler={setDisplayModalTitanAdvertisementHandler}
            />
          )}
          <HeaderV2 {...getHeaderV2Props()} />
        </Styles.HeaderWrapper>

        <Styles.PageContent>
          {children}
        </Styles.PageContent>
      </main>
      <ModalGtm />
    </div>
  );
};

export default withI18n()(withWidth()(withStyles(styles)(Template)));
