import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';

import styles from './styles';


const FilterCard = ({
  title, children, classes, button, useMediaWidth, className, searchBar,
}) => {
  const isMobile = useMediaQuery(`(max-width:${useMediaWidth}px)`);

  return (
    <>
      {isMobile && (
        <>
          <div className={`${classes.card} ${className}`}>
            <div>
              <p className={`${classes.title} ${(isMobile && button) && classes.titleBottom}`}>{title}</p>
              {button}
            </div>
            <div className={`${classes.searchBarDiv}`}>
              {searchBar}
            </div>
          </div>
        </>
      )}
      <div>
        {children}
      </div>
    </>
  );
};

export default withStyles(styles)(FilterCard);
