import React from 'react';
import { Link } from '@/pages/common';
import * as Styles from './TextDescriptor.styles';

export const textBold = (value, options = { tag: '', tagStyles: null }) => ({
  ...options,
  style: 'bold',
  type: 'text',
  value,
});

export const textRegular = (value, options = { tag: '', tagStyles: null }) => ({
  ...options,
  style: 'regular',
  type: 'text',
  value,
});

export const textLink = (value, url, options = { tag: '', tagStyles: null }) => ({
  ...options,
  type: 'link',
  url,
  value,
});

const TextDescriptor = ({
  descriptors = [
    {
      type: 'text',
      style: 'bold',
      value: 'Important: ',
    },
    {
      type: 'text',
      style: 'regular',
      value: 'Access the ',
    },
    {
      type: 'link',
      url: 'https://www.hostgator.com',
      value: 'link',
    },
  ],
  testId = 'text-descriptor',
}) => {
  const parseMethods = {
    text({
      style, value, testId, tag, tagStyle,
    }) {
      const textHandled = (style === 'bold') ? (
        <Styles.Bold data-testid={testId}>
          {value}
        </Styles.Bold>
      ) : (
        <Styles.Regular data-testid={testId}>
          {value}
        </Styles.Regular>
      );

      return React.createElement(
        tag || 'span',
        {
          key: testId,
          ...(tagStyle ? { style: tagStyle } : {}),
        },
        textHandled,
      );
    },
    link({ url, value, testId }) {
      return <Link variant="link" key={testId} href={url} text={value} testId={testId} />;
    },
  };

  return (
    <span data-testid={testId}>
      {descriptors.map((descriptor, index) => {
        const { type } = descriptor;
        return parseMethods[type]({ ...descriptor, testId: `${testId}-${type}-${index}` });
      })}
    </span>
  );
};

export default TextDescriptor;
