import React from 'react';
import {
  withStyles, Dialog, Slide, IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';

import styles from './styles';


const Transition = props => <Slide direction="up" {...props} />;

const ModalRebootHistory = ({
  open, onClose, classes, t, logs, product, domain,
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    classes={{
      paper: classes.modal,
    }}
  >
    <div
      className={classes.content}
    >
      <IconButton
        onClick={onClose}
        aria-label="Close"
        className={classes.btnClose}
      >
        <Close />
      </IconButton>
      <h3 className={classes.title}>{t('reboot.modalHistory.title')}</h3>
      <p className={classes.message}>
        <Trans i18nKey="reboot.modalHistory.message" values={{ product, domain }}>
          0
          <strong>1</strong>
          2
          <strong>3</strong>
        </Trans>
      </p>
      <div className={classes.logWrapper}>
        {logs.map(log => (
          <div className={classes.logItem} key={log.id}>
            <div className={classes.logSubItem}>
              <span className={classes.logLabel}>{t('reboot.modalHistory.date')}</span>
              <span className={classes.logValue}>{log.date}</span>
            </div>
            <div className={classes.logSubItem}>
              <span className={classes.logLabel}>{t('reboot.modalHistory.time')}</span>
              <span className={classes.logValue}>{log.time.join(' / ')}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Dialog>
);

export default withI18n()(withStyles(styles)(ModalRebootHistory));
