export const CommonActionTypes = {
  DEFAULT: 'COMMON_DEFAULT',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
  SET_LOADING: 'SET_LOADING',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_REDIRECT: 'SET_REDIRECT',
  SET_SERVER_TIME: 'SET_SERVER_TIME',
  REQUEST_SERVER_TIME: 'REQUEST_SERVER_TIME',
};
