import { Modal, Button } from '@/pages/common';
import React, { useContext } from 'react';
import { IconCheck, IconArrowLeft } from '@/icons';
import * as Styles from './DomainRegisteredModal.styles';
import { useResize } from '@/hooks/useResize';
import { ThemeContext } from 'styled-components';


export default function DomainRegisteredModal({
  handleBack,
  handleCloseModal,
  domain,
  domainAndSubdomain,
  handleSelectChange,
  title,
  domainLabel,
  registered,
  isCorrect,
  continueLabel,
  incorrect,
  backButton,
  continueButton,
}) {
  const theme = useContext(ThemeContext);
  const { color } = theme;
  const screenSize = useResize();
  const isMobile = screenSize.width < 700;

  const handleClickBack = () => {
    handleBack('domain-registered');
  };

  const handleClickContinue = () => {
    handleSelectChange(domainAndSubdomain, 'domain-registered');
    handleCloseModal();
  };

  return (
    <Modal onClose={() => handleCloseModal(true)} maxWidth={600} testId="domain-registered-modal">
      <Styles.ModalContainer>
        <Styles.ModalTitle>{title}</Styles.ModalTitle>
        <Styles.ModalBody>
          <Styles.FirstParagraph>
            <IconCheck color={color.white} size={isMobile ? '55' : '35'} variant="outlined" />
            <div>
              <Styles.ModalText>
                {domainLabel}
                &quot;
                <strong>{domain}</strong>
                &quot;
                {registered}
                <br />
                <strong>{isCorrect}</strong>
                {continueLabel}
              </Styles.ModalText>
            </div>
          </Styles.FirstParagraph>
          <Styles.ModalText>
            {incorrect}
          </Styles.ModalText>
        </Styles.ModalBody>
        <Styles.ModalFooter>
          <Styles.BackButtonWrapper>
            <Button
              testId="back-button"
              onClick={handleClickBack}
              variant="newTertiary"
              iconLeft={<IconArrowLeft color={theme.palette.secondary.primary} />}
              font="secondary"
              size="large"
              label={backButton}
            />
          </Styles.BackButtonWrapper>

          <Button
            testId="continue-button"
            onClick={handleClickContinue}
            variant="newPrimary"
            font="secondary"
            size="large"
            label={continueButton}
          />
        </Styles.ModalFooter>
      </Styles.ModalContainer>
    </Modal>
  );
}
