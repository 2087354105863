import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { PROPAGATION_STATUS_TYPE, SSL_STATUS } from '@/enums/sites/status.enum';

const useTechnicalStatus = () => {
  const getAllPropagationStatus = propagationItem => ({
    externalNotConfigured: propagationItem.status === PROPAGATION_STATUS.NOT_CONFIGURED && propagationItem.type === 'external',
    internalNotConfigured: propagationItem.status === PROPAGATION_STATUS.NOT_CONFIGURED && propagationItem.type === 'internal',
    inProgress: propagationItem.status === PROPAGATION_STATUS.IN_PROGRESS,
    propagated: propagationItem.status === PROPAGATION_STATUS.PROPAGATED,
  });

  const setPropagationAlertStatus = (propagationStatus) => {
    if (propagationStatus.externalNotConfigured) return PROPAGATION_STATUS_TYPE.EXTERNAL_NOT_CONFIGURED;
    if (propagationStatus.internalNotConfigured) return PROPAGATION_STATUS_TYPE.INTERNAL_NOT_CONFIGURED;
    if (propagationStatus.propagated) return PROPAGATION_STATUS_TYPE.PROPAGATED;
    if (propagationStatus.inProgress) return PROPAGATION_STATUS_TYPE.IN_PROPAGATION;
  };

  const getPropagationStatus = (propagation) => {
    const allPropagationStatus = getAllPropagationStatus(propagation);
    return setPropagationAlertStatus(allPropagationStatus);
  };


  const getSSLStatus = (sitePropagation, siteSSL) => {
    if (siteSSL) {
      if (siteSSL.status === 'active') return SSL_STATUS.ACTIVE;
    }

    if (sitePropagation) {
      if (sitePropagation.status === PROPAGATION_STATUS.NOT_CONFIGURED) return SSL_STATUS.NOT_ACTIVE;
      if (sitePropagation.status === PROPAGATION_STATUS.IN_PROGRESS || sitePropagation.status === PROPAGATION_STATUS.NOT_PROPAGATED || (sitePropagation.status === PROPAGATION_STATUS.PROPAGATED && !siteSSL)) return SSL_STATUS.IN_PROGRESS;
    }

    if (siteSSL) {
      if (siteSSL.status === 'activation_failed') return SSL_STATUS.ACTIVATION_FAILED;
    }
  };

  return {
    getPropagationStatus,
    getSSLStatus,
  };
};

export default useTechnicalStatus;
