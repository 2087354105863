/* eslint-disable no-unused-vars */
import React from 'react';
import * as Styles from './AsaasSecondRow.styles';
import { Button } from '@/pages/common/v1/Button';
import firstRowImage from '@/media/asaas/LPSecondRowFirstImg.png';
import secondRowImage from '@/media/asaas/LPSecondRowSecondImg.png';
import asaasSymbol from '@/media/asaas/asaas-symbol.svg';

const AsaasSecondRow = ({
  testId = 'asaas-second-row',
  title = 'main title',
  secondSectionTitle = 'section title',
  secondSectionDescription = 'description',
  secondSectionButtonLabel = 'button label',
  partnership = 'partnership',
  mainTaxPrice = 'main tax price',
  mensality = 'mensality',
  tax = [],
  moveScreenToTop,
  onClickTaxes,
}) => (
  <Styles.Container data-testid={testId}>
    <Styles.Title data-testid={`${testId}-main-title`}>{title}</Styles.Title>

    <Styles.FirstRow>
      <Styles.ImageWrapper>
        <Styles.RowImage src={firstRowImage} />
      </Styles.ImageWrapper>

      <Styles.MainTaxWrapper>
        <Styles.PartnerShipWrapper>
          <Styles.PartnerShip>
            <Styles.AsaasSymbol src={asaasSymbol} />

            <Styles.PartnerShipContent data-testid={`${testId}-partnership`}>
              {partnership}
            </Styles.PartnerShipContent>

          </Styles.PartnerShip>
        </Styles.PartnerShipWrapper>

        <Styles.Price data-testid={`${testId}-main-tax`}>
          {mainTaxPrice}
        </Styles.Price>

        <Styles.Mensality data-testid={`${testId}-mensality`}>
          {mensality}
        </Styles.Mensality>
      </Styles.MainTaxWrapper>

      <Styles.TaxWrapper>
        <Styles.Taxs>
          {tax.map(tax => (
            <Styles.TaxContent key={tax}>
              {tax(onClickTaxes)}
            </Styles.TaxContent>
          ))}
        </Styles.Taxs>
      </Styles.TaxWrapper>
    </Styles.FirstRow>

    <Styles.SecondRow>
      <Styles.SecondRowContent>
        <Styles.SecondRowTitle data-testid={`${testId}-title`}>
          {secondSectionTitle}
        </Styles.SecondRowTitle>

        <Styles.SecondRowDescription data-testid={`${testId}-description`}>
          {secondSectionDescription}
        </Styles.SecondRowDescription>

        <Styles.SecondRowButtonWrapper>
          <Button
            testId={`${testId}-button`}
            label={secondSectionButtonLabel}
            onClick={() => moveScreenToTop()}
          />
        </Styles.SecondRowButtonWrapper>

      </Styles.SecondRowContent>

      <Styles.ImageWrapper>
        <Styles.RowImage src={secondRowImage} />
      </Styles.ImageWrapper>
    </Styles.SecondRow>
  </Styles.Container>
);

export default AsaasSecondRow;
