import { collect } from '@/analytics';

const EVENT = {
  NAME: 'gaEvent',
  CATEGORY: {
    DUPLICATE_ORDER_MODAL_SHOW: 'Solução - Diagnóstico Pedidos duplicados - Exibição da modal',
    DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW: 'Solução - Diagnóstico Pedidos duplicados - Modal de feedback',
    DUPLICATE_ORDER_CTA_CLICK: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta',
  },
  ACTION: {
    DUPLICATE_ORDER_MODAL_SHOW: 'Solução - Modal apresentada com sucesso - Modal Exibida',
    DUPLICATE_ORDER_MODAL_SEE_ORDER: 'Solução - Modal apresentada com sucesso - Cta ver fatura',
    DUPLICATE_ORDER_MODAL_CANCEL_ORDER: 'Solução - Modal apresentada com sucesso - Cta cancelar pedido',
    DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW: 'Solução - Modal de feedback - Modal de sucesso apresentada',
    DUPLICATE_ORDER_FEEDBACK_MODAL_OK_CLICK: 'Solução - Modal de feedback - Cta ok, entendi',
    DUPLICATE_ORDER_FEEDBACK_MODAL_SEE_ORDER: 'Solução - Modal de feedback - Cta ver fatura',
    DUPLICATE_ORDER_FAIL_FEEDBACK_MODAL_SHOW: 'Solução - Modal de feedback - Modal de falha apresentada',
    DUPLICATE_ORDER_FAIL_FEEDBACK_TRY_AGAIN: 'Solução - Modal de feedback - Cta tentar novamente',
  },
  LABEL: {
    DUPLICATE_ORDER_MODAL_SHOW: 'Solução - Diagnóstico Pedidos duplicados - Modal Exibida',
    DUPLICATE_ORDER_MODAL_SEE_ORDER: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta ver fatura',
    DUPLICATE_ORDER_MODAL_CANCEL_ORDER: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta cancelar pedido',
    DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW: 'Solução - Diagnóstico Pedidos duplicados - Exibição da modal de sucesso apresentada',
    DUPLICATE_ORDER_FEEDBACK_MODAL_OK_CLICK: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta ok, entendi',
    DUPLICATE_ORDER_FAIL_FEEDBACK_MODAL_SHOW: 'Solução - Diagnóstico Pedidos duplicados - Exibição da modal de falha apresentada',
    DUPLICATE_ORDER_FAIL_FEEDBACK_TRY_AGAIN: 'Solução - Diagnóstico Pedidos duplicados - Clique no cta tentar novamente',
  },
};

const duplicateOrderModalShow = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_MODAL_SHOW,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_MODAL_SHOW,
});

const duplicateOrderSeeOrder = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_CTA_CLICK,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_MODAL_SEE_ORDER,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_MODAL_SEE_ORDER,
});

const duplicateOrderCancelOrder = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_MODAL_CANCEL_ORDER,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_MODAL_CANCEL_ORDER,
});

const duplicateOrderPositiveFeedback = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
});

const duplicateOrderFailFeedback = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_FAIL_FEEDBACK_MODAL_SHOW,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_FAIL_FEEDBACK_MODAL_SHOW,
});

const duplicateOrderClickOkUnderstand = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_FEEDBACK_MODAL_OK_CLICK,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_FEEDBACK_MODAL_OK_CLICK,
});

const duplicateOrderModalSeeOrder = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_MODAL_SEE_ORDER,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_MODAL_SEE_ORDER,
});

const duplicateOrderModalTryAgain = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.DUPLICATE_ORDER_FEEDBACK_MODAL_SHOW,
  gaEventAction: EVENT.ACTION.DUPLICATE_ORDER_FAIL_FEEDBACK_TRY_AGAIN,
  gaEventLabel: EVENT.LABEL.DUPLICATE_ORDER_FAIL_FEEDBACK_TRY_AGAIN,
});

const analytics = {
  duplicateOrderModalShow,
  duplicateOrderSeeOrder,
  duplicateOrderCancelOrder,
  duplicateOrderPositiveFeedback,
  duplicateOrderClickOkUnderstand,
  duplicateOrderModalSeeOrder,
  duplicateOrderFailFeedback,
  duplicateOrderModalTryAgain,
};

export default analytics;
