import {
  Button, IconCheck, IconDanger, IconLoading, Tag,
} from 'gatorcomponents';
import * as Styles from './Log.styles';
import React from 'react';
import { differenceInCalendarDays, format } from 'date-fns';

const Log = ({
  history = [],
  page = 1,
  itemsPerPage = 10,
  loadMore = () => { },
  title = 'title',
  description = 'description',
  tagSuccess = 'completed',
  mountInfoSuccess,
  mountInfoWarning,
  mountInfoEmpty,
  mountInfoError,
  loadMoreLabel = 'more',
  tagWarning,
  tagError,
  loadingLog,
  currentProduct,
  goToCPanel,
}) => {
  const getDate = (backup) => {
    const createdAt = new Date(backup && backup.created_at);
    const today = new Date();
    const diff = differenceInCalendarDays(today, createdAt);
    return diff;
  };

  const handleLoadMore = () => {
    loadMore && loadMore();
  };

  const daysToShow = (days) => {
    if (days > 7 && days <= 15) {
      return 7;
    }
    if (days > 15 && days <= 30) {
      return 15;
    }
    if (days > 30) {
      return 30;
    }
  };

  const mountMessage = (log) => {
    if (!log) {
      return mountInfoEmpty(goToCPanel);
    }

    if (log.status === 'error') {
      return mountInfoError(goToCPanel);
    }

    if (log.status === 'completed') {
      const dateDiff = getDate(log);

      if (dateDiff < 7) {
        return mountInfoSuccess(
          format(new Date(log.created_at), 'dd/MM/yyyy'),
        );
      }
      return mountInfoWarning(daysToShow(dateDiff), goToCPanel);
    }
  };

  const mountIcon = (log) => {
    if (!log || (log && log.status === 'error')) {
      return <IconDanger color="problemMedium" />;
    }

    if (log.status === 'completed') {
      const dateDiff = getDate(log);
      if (dateDiff < 7) {
        return <IconCheck color="activePure" />;
      }
      return <IconDanger color="problemMedium" />;
    }
  };

  const mountTag = (log) => {
    const dateDiff = getDate(log);

    if (!log) {
      return <Tag label={tagWarning} variant="problem" />;
    }

    if (log.status === 'completed' && dateDiff > 7) {
      return <Tag label={tagWarning} variant="problem" />;
    }

    if (log.status === 'completed') {
      return <Tag label={tagSuccess} variant="active" />;
    }

    if (log.status === 'error') {
      return <Tag label={tagError} variant="problem" />;
    }
  };

  const mountCard = log => (
    <Styles.Log key={`${log && log.name}-${log && log.date}`}>
      <Styles.NameWrapper>
        <Styles.LogName>
          {currentProduct.productname}
        </Styles.LogName>
        <Styles.LogDate>
          {currentProduct.domain}
        </Styles.LogDate>
      </Styles.NameWrapper>

      <Styles.StatusWrapper>
        {mountTag(log)}
      </Styles.StatusWrapper>

      <Styles.InfoWrapper>
        {mountIcon(log)}
        <Styles.InfoText>
          {mountMessage(log)}
        </Styles.InfoText>
      </Styles.InfoWrapper>
    </Styles.Log>
  );

  if (loadingLog || !currentProduct) {
    return (
      <Styles.LoadingWrapper data-testid="laoder">
        <IconLoading />
      </Styles.LoadingWrapper>
    );
  }

  return (
    <>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Description>
        {description}
      </Styles.Description>

      <Styles.LogsWrapper>
        {history.length ? (
          history.slice(0, (page * itemsPerPage)).map(log => (
            mountCard(log)
          ))
        ) : (
          mountCard()
        )}
      </Styles.LogsWrapper>

      {history.length > (page * itemsPerPage) && (
        <Styles.ButtonWrapper>
          <Button
            variant="secondary"
            label={loadMoreLabel}
            onClick={handleLoadMore}
          />
        </Styles.ButtonWrapper>
      )}
    </>
  );
};

export default Log;
