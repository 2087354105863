import React, {
  useState, useCallback, useEffect, useRef, useMemo,
} from 'react';
import { IconArrowDown, IconArrowDownOutlined } from '@/icons';
import * as Styles from './Select.styles';
import Loader from '@/components/Layout/Loader';

const Select = ({
  defaultOption = null,
  defaultOptionLabel = 'Select',
  defaultOptionValue = '',
  disabled = false,
  error = '',
  label = '',
  onChange,
  options = [],
  testId = 'select',
  customDropdownIcon = '',
  animatedDropdownIcon = false,
  placeholder = '',
  variant = 'default',
  fixedBottomOption = '',
  fixedBottomOptionAction = () => {},
  customSelectedValue,
  loading = false,
  maxHeight = '203px',
  autoSelectIfOnOption = true,
  selectedOption = null,
  searchable,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOption);
  const [isEmpty, setIsEmpty] = useState(false);
  const [inputedValue, setInputedValue] = useState('');

  const defaultSelectOption = useMemo(
    () => ({
      id: 'default',
      label: defaultOptionLabel,
      value: defaultOptionValue,
    }),
    [defaultOptionLabel, defaultOptionValue],
  );

  const initialOptions = placeholder ? [...options] : [defaultSelectOption, ...options];
  const [selectOptions, setSelectOptions] = useState(initialOptions);

  const handlePlaceholderAndDefault = useCallback(() => {
    if (defaultOption && !selected && selectOptions.length) {
      setSelected({ value: selectOptions[defaultOption], callOnChange: false });
    }

    if (!placeholder && !defaultOption) {
      if (JSON.stringify(selectOptions) !== JSON.stringify([defaultSelectOption, ...options])) {
        setSelectOptions([defaultSelectOption, ...options]);
        setSelected(null);
      }

      if (!selected && selectOptions.length > 0) {
        const clamped = Math.min(Math.max(defaultOption, 0), selectOptions.length - 1);
        setSelected({ value: selectOptions[clamped], callOnChange: false });
      }
    }

    if (placeholder && !selected && selectOptions.length === 1) {
      if (autoSelectIfOnOption) {
        const clamped = Math.min(Math.max(defaultOption, 0), selectOptions.length - 1);
        setSelected({ value: selectOptions[clamped], callOnChange: false });
      }
    }
  }, [defaultOption, selectOptions, selected, options, defaultSelectOption, placeholder, autoSelectIfOnOption]);

  useEffect(() => {
    handlePlaceholderAndDefault();
  }, [handlePlaceholderAndDefault]);

  const onSelectClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const getArrowStyle = () => {
    if (variant === 'sites') {
      return <IconArrowDownOutlined />;
    }
    return <IconArrowDown />;
  };

  const onOptionClick = useCallback(
    (option) => {
      // Prevent updates when option is the current selection
      if (selected && selected.value.id === option.id) {
        setIsOpen(false);
        return;
      }
      setSelected({ value: option, callOnChange: true });
      setIsOpen(false);
      setInputedValue('');
    },
    [selected],
  );

  useEffect(() => {
    if (selected && onChange && selected.callOnChange) {
      onChange && onChange(selected.value);
      setSelected({ value: selected.value, callOnChange: false });
    }
  }, [onChange, selected]);

  const onMouseDown = useCallback(
    (event) => {
      const targetTestId = event.target.getAttribute('data-testid');
      const insideWrapper = (wrapperRef.current && wrapperRef.current.contains(event.target))
       || (targetTestId && targetTestId.includes(`${testId}-option`));
      if (isOpen && !insideWrapper) {
        setIsOpen(false);
        setInputedValue('');
      }
    },
    [isOpen, wrapperRef, testId],
  );

  const handleChangeInputedValue = useCallback(() => {
    if (searchable) {
      const newOptions = options.filter(item => JSON.stringify(item).toLocaleLowerCase().includes(inputedValue.toLocaleLowerCase()));
      setSelectOptions(newOptions);
    }
  }, [inputedValue, options, searchable]);

  useEffect(() => {
    handleChangeInputedValue();
  }, [handleChangeInputedValue]);

  const optionsMap = selectOptions.map((option, index) => (
    <Styles.Option data-testid={`${testId}-option-${option.id}`} key={`id-${option.id}-order-${index.toString()}`} onClick={() => onOptionClick(option)} variant={variant}>
      <Styles.OptionLabel>{option.label}</Styles.OptionLabel>
    </Styles.Option>
  ));

  const onKeyDown = useCallback((e) => {
    const esc = 27;
    if (e.keyCode === esc) {
      setIsOpen(false);
      setInputedValue('');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.addEventListener('keydown', onKeyDown);
    };
  }, [onMouseDown, onKeyDown]);

  useEffect(() => {
    if ((placeholder && !selectOptions.length) || !placeholder) { setIsEmpty(selectOptions.length === 1); }
  }, [selectOptions.length, isEmpty, selectOptions, label, placeholder]);

  useEffect(() => {
    if (placeholder) {
      setSelectOptions([...options]);
    }
  }, [options, placeholder]);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.label) {
        const option = selectOptions.find(options => options.label === selectedOption.label);
        if (option) {
          setSelected({ value: option, callOnChange: false });
        }
      }
    } else if (!selectedOption) {
      setSelected(null);
    }
  }, [selectedOption, selectOptions]);


  return (
    <Styles.Wrapper data-testid={testId} ref={wrapperRef} disabled={isEmpty || disabled} variant={variant}>
      {label && (
        <Styles.FixedLabel
          data-testid={`${testId}-fixed-label`}
          className={`${error ? 'error' : ''} ${isEmpty || disabled ? 'disabled' : ''}`}
        >
          {label}
        </Styles.FixedLabel>
      )}
      <Styles.Field
        data-testid={`${testId}-field`}
        onClick={onSelectClick}
        disabled={isEmpty || disabled}
        className={error ? 'error' : ''}
        variant={variant}
        isOpen={isOpen}
        animatedDropdownIcon={animatedDropdownIcon}
      >
        {(searchable && isOpen) && (
          <Styles.Input
            type="text"
            autoFocus
            value={inputedValue}
            onChange={e => setInputedValue(e.target.value)}
          />
        )}

        {(!searchable || !isOpen) && (
          <>
            <Styles.Label
              variant={variant}
              selected={selected || customSelectedValue}
              data-testid={`${testId}-label`}
            >
              {selected
                ? selected.label || selected.value.label
                : placeholder}
            </Styles.Label>

            {!isEmpty && !disabled && (
              <Styles.IconAdapter variant={variant}>
                {customDropdownIcon || getArrowStyle()}
              </Styles.IconAdapter>
            )}
          </>
        )}

        <Styles.OptionsWrapper show={isOpen} fixedBottomOption={fixedBottomOption}>
          {loading
            && <Loader size={20} />
          }

          {(options.length > 0 && !loading) && (
            <Styles.Options maxHeight={maxHeight} data-testid={`${testId}-options`} show={isOpen} variant={variant}>
              {optionsMap}
            </Styles.Options>
          )}

          {(fixedBottomOption && !loading)
            && <Styles.FixedBottomOption onClick={fixedBottomOptionAction} data-testid="fixed-option">{fixedBottomOption}</Styles.FixedBottomOption>
          }
        </Styles.OptionsWrapper>

      </Styles.Field>

      {error && <Styles.Error data-testid={`${testId}-error`}>{error}</Styles.Error>}
    </Styles.Wrapper>
  );
};

export default Select;
