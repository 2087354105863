const styles = theme => ({
  button: {
    margin: 5,
    height: 31,
    color: theme.color.indigo,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.01,
    textTransform: 'none',
    background: theme.color.white,
    boxShadow: `0px 3px 6px ${theme.color.shadowBlue}`,
    padding: '6px 16px',
    borderRadius: 19,
    opacity: 1,
    transition: '0.2s box-shadow, 0.2s background',

    '&:hover': {
      background: theme.color.skyHover,
    },

    '&$disabled': {
      color: theme.color.indigo,
    },
  },
  root: {
    '&:disabled': {
      color: theme.color.indigo,
      boxShadow: 'none',
      opacity: 0.5,
    },
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  disabled: {
    boxShadow: 'none',
    opacity: 0.5,
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
  },
});

export default styles;
