/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCourses, getTokens, getPrice, getDetails, getPaymentMethods,
} from '@/redux/actions/academyPass';
import { tagList } from './values';
import { ACADEMY_PASS_SUBJECTS } from '@/config/products/academypass';

export const AcademyPassContext = createContext();

const AcademyPassContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const academyPass = useSelector(state => state.academyPass);
  const academyPassCourses = useSelector(state => state.academyPass.courses);
  const academyPassTokens = useSelector(state => state.academyPass.tokens);
  const academyPassCta = useSelector(state => state.academyPass.cta);
  const academyPassDetail = useSelector(state => state.academyPass.detail);
  const [filteredOptions, setFilteredOptions] = useState(tagList);
  const [multiSelectOptions, setMultiSelectOptions] = useState(ACADEMY_PASS_SUBJECTS);
  const [filterTyped, setFilterTyped] = useState('');
  const [renderedCourses, setRenderedCourses] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);

  useEffect(() => {
    if (academyPassCta.price === null && (academyPassCta.isLoadingPrice === false)) {
      dispatch(getPrice());
    }
    if (academyPassCta.paymentMethods === null && (academyPassCta.isLoadingPayment === false)) {
      dispatch(getPaymentMethods());
    }
    if (academyPassDetail.data === null && academyPassDetail.isLoading === false) {
      dispatch(getDetails());
    }
  }, [academyPassCourses, academyPassTokens, academyPassCta]);

  const clearFilter = () => {
    const options = [...filteredOptions];
    options.map((option) => {
      const newOption = option;
      newOption.checked = false;
      return newOption;
    });

    options[0].checked = true;

    const checkOptions = [...multiSelectOptions];
    checkOptions.map((selectOption) => {
      const newSelectedOption = selectOption;
      newSelectedOption.checked = false;
      return newSelectedOption;
    });

    setFilteredOptions(options);
    setMultiSelectOptions(checkOptions);
    setSubjectOptions([]);
    setFilterTyped('');
  };

  const togglePaidOrFreeCourses = ({ options, index }) => {
    const newOptions = [...options];
    if (index === 1) {
      if (newOptions[index].checked) {
        newOptions[2].checked = false;
      }
    }
    if (index === 2) {
      if (newOptions[index].checked) {
        newOptions[1].checked = false;
      }
    }
    return newOptions;
  };

  const selectOptions = () => {
    const selectedOptions = multiSelectOptions
      .filter(subject => subject.checked)
      .map(subject => ({
        value: subject.value,
        label: subject.label,
      }));

    const newSubjectOptions = selectedOptions.length !== 0 ? selectedOptions : [];

    setSubjectOptions(newSubjectOptions);
  };

  useEffect(() => {
    if (multiSelectOptions) {
      selectOptions();
    }
  }, [multiSelectOptions]);

  const { coursesList } = academyPassCourses;

  const getOptionsValues = () => filteredOptions
    .filter(option => option.checked)
    .map(option => option.value);

  useEffect(() => {
    if (!coursesList) {
      dispatch(getTokens());
      dispatch(getCourses());
    }
  }, [coursesList]);

  useEffect(() => {
    const options = getOptionsValues();

    const isAllSelected = () => options.includes('all');
    const isMostRatedSelected = () => options.includes('rating');
    const isFreeSelected = course => options.includes('free') && course.isFree;
    const isPaidSelected = course => options.includes('hg_pass') && !course.isFree;
    const isSubjectsSelected = course => (subjectOptions.length === 0) || subjectOptions.find(subject => subject.label.toLowerCase() === decodeURI(course.subject).toLowerCase());
    const containTextInName = course => (filterTyped === '') || course.name.normalize().toLowerCase().includes(filterTyped.toLowerCase());

    if (!coursesList) {
      return;
    }

    const courses = coursesList
      .filter(course => isAllSelected()
        || isFreeSelected(course)
        || isPaidSelected(course)
        || isMostRatedSelected())
      .filter(course => isSubjectsSelected(course))
      .filter(course => containTextInName(course));

    if (isMostRatedSelected()) {
      courses.sort((courseA, courseB) => courseB.ratingAverage - courseA.ratingAverage);
    }

    setRenderedCourses(courses);
  }, [coursesList, filteredOptions, subjectOptions, filterTyped]);

  return (
    <AcademyPassContext.Provider
      value={{
        academyPass,
        filteredOptions,
        setFilteredOptions,
        clearFilter,
        filterTyped,
        setFilterTyped,
        renderedCourses,
        multiSelectOptions,
        setMultiSelectOptions,
        togglePaidOrFreeCourses,
      }}
      t={key => key}
    >
      {children}
    </AcademyPassContext.Provider>
  );
};

AcademyPassContextProvider.propTypes = {
  children: PropTypes.node,
};

AcademyPassContextProvider.defaultProps = {
  children: null,
};

export default AcademyPassContextProvider;
