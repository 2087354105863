import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './ChangePlanModal.styles';
import {
  Button, IconArrow, IconSearchFail, Modal, Radio,
  TextField,
} from 'gatorcomponents';

import PropTypes from 'prop-types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CLOUDFLARE_CHANGE_PLAN_V2 } from '@/config/GrowthBook/constants';

export const ChangePlanModal = ({
  sites,
  onClose,
  currentPlan,
  handleSetPlan,
  backCta = 'Voltar',
  continueCta = 'Continuar',
  mainDomain = 'Domínio principal: ',
  title = 'Selecione uma hospedagem',
  emptyMessage = 'Nenhum resultado encontrado para sua busca',
}) => {
  const changePlanWithInput = useFeatureIsOn(CHARLIE_CLOUDFLARE_CHANGE_PLAN_V2);
  const [inputedFilter, setInputedFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState(currentPlan);

  const [filteredDomains, setFilteredDomains] = useState(sites);

  const filterCards = useCallback(() => {
    setFilteredDomains(sites.filter(item => item.domain.toLowerCase().includes(inputedFilter.toLowerCase())));
  }, [
    inputedFilter,
    sites,
  ]);

  useEffect(() => {
    filterCards();
  }, [filterCards]);

  const handleChangePlan = () => {
    handleSetPlan && handleSetPlan(selectedItem);
    onClose && onClose();
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  return (
    <Modal
      title={title}
      onClose={onClose}
      maxHeight={472}
      maxWidth={847}
    >

      {changePlanWithInput && (
        <>
          <TextField
            label="Pesquisar por domínio"
            placeholder="Digite o domínio"
            onChange={e => setInputedFilter(e.target.value)}
            value={inputedFilter}
            traillingIcon="IconSearch"
          />
          <Styles.DomainsWrapper>
            {(filteredDomains.length) && filteredDomains.map((item, index) => {
              const isSelected = item.hosting_id === selectedItem.hosting_id
                  && item.domain === selectedItem.domain;

              return (
                <React.Fragment
                  key={`${item.product_name}_${item.domain}`}
                >
                  <Styles.DomainItem
                    isSelected={isSelected}
                    onClick={() => handleSelect(item)}
                  >
                    <Styles.Domain isSelected={isSelected}>
                      {item.domain}
                    </Styles.Domain>

                    <Styles.Plan isSelected={isSelected}>
                      {`Hospedagem: ${item.product_name}`}
                    </Styles.Plan>

                  </Styles.DomainItem>
                  {(index < filteredDomains.length - 1 && !isSelected) && <Styles.Divider />}
                </React.Fragment>
              );
            })}


            {filteredDomains.length === 0 && (
            <Styles.EmptyStateWrapper>

              <IconSearchFail />
              {emptyMessage}
            </Styles.EmptyStateWrapper>
            )}

          </Styles.DomainsWrapper>
        </>
      )}

      {!changePlanWithInput && (
        <Styles.PlanWrapper>
          {(sites.length) && sites.map(item => (
            <Styles.PlanItem
              role="button"
              onClick={() => handleSelect(item)}
              key={`${item.product_name}_${item.domain}`}
            >
              <Radio
                allowToggle
                variant="blue"
                checked={(
                  item.hosting_id === selectedItem.hosting_id
                  && item.domain === selectedItem.domain
                )}
              />
              <Styles.Column>

                <Styles.PlanName>{item.product_name}</Styles.PlanName>
                <Styles.MainDomainLabel>
                  {mainDomain}
                  <Styles.MainDomainItem>
                    {item.domain}
                  </Styles.MainDomainItem>
                </Styles.MainDomainLabel>
              </Styles.Column>
            </Styles.PlanItem>
          ))}
        </Styles.PlanWrapper>
      )}

      <Styles.ButtonWrapper changePlanWithInput={changePlanWithInput}>
        {!changePlanWithInput && (
        <Button
          size="medium2"
          variant="tertiary"
          label={backCta}
          onClick={() => onClose()}
        />
        )}

        <Button
          testId="changePlan"
          label={continueCta}
          size="medium2"
          variant={changePlanWithInput ? 'primary' : 'secondary'}
          onClick={handleChangePlan}
          iconRight={changePlanWithInput ? null : (
            <IconArrow
              direction="right"
            />
          )}
        />
      </Styles.ButtonWrapper>
    </Modal>
  );
};

ChangePlanModal.propTypes = {
  sites: PropTypes.array,
  onClose: PropTypes.func,
  currentPlan: PropTypes.object,
  handleSetPlan: PropTypes.func,
  backCta: PropTypes.string,
  continueCta: PropTypes.string,
  mainDomain: PropTypes.string,
  title: PropTypes.string,
};

export default ChangePlanModal;
