import React from 'react';
import * as Styles from './styles';
import { usePropagationStatus } from '@/hooks/domains/usePropagationStatus';
import useLocale from '@/hooks/useLocale';
import { Tooltip } from '@/pages/common/v1';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';

const PropagationStatusLabel = ({ domain, hideTooltip, isOnDomainList }) => {
  const propagationStatus = usePropagationStatus(domain);
  const { domains: domainsLocale } = useLocale();
  const dotBr = domain && domain.includes('.com.br');
  const statusTexts = domainsLocale && domainsLocale.dnsStatus(propagationStatus, dotBr);
  const hasNoRestrictions = isOnDomainList ? propagationStatus && propagationStatus !== PROPAGATION_STATUS.NOT_CONTRACTED && propagationStatus !== PROPAGATION_STATUS.NOT_CONFIGURED : true;
  const validStatusLabel = !!propagationStatus && !!statusTexts && hasNoRestrictions;

  return (
    <>
      {validStatusLabel && (
        <Styles.LabelWrapper data-testid="dns-propagation-status-label">
          {!hideTooltip && (
            <Tooltip content={statusTexts && statusTexts.labelTooltip}>
              <Styles.StatusLabel propagationStatus={propagationStatus}>
                {statusTexts && statusTexts.labelText}
              </Styles.StatusLabel>
            </Tooltip>
          )}
          {hideTooltip && (
            <Styles.StatusLabel propagationStatus={propagationStatus}>
              {statusTexts && statusTexts.labelText}
            </Styles.StatusLabel>
          )}
        </Styles.LabelWrapper>
      )}
    </>
  );
};

export default PropagationStatusLabel;
