import React, { useEffect } from 'react';
import { myDataAnalytics } from '@/analytics/myData';
import { USER_DATA_CHANGE_EMAIL_FLOW, USER_DATA_CHANGE_PHONE_FLOW } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import MyDataHandler from '../MyDataHandler/MyDataHandler';

const MyDataWrapper = () => {
  const newPhoneFlowEnabled = useFeatureIsOn(USER_DATA_CHANGE_PHONE_FLOW);
  const newEmailFlowEnabled = useFeatureIsOn(USER_DATA_CHANGE_EMAIL_FLOW);

  useEffect(() => {
    myDataAnalytics.accessMyDataPage();
  }, []);

  return (
    <MyDataHandler
      newPhoneFlowEnabled={newPhoneFlowEnabled}
      newEmailFlowEnabled={newEmailFlowEnabled}
    />
  );
};

export default MyDataWrapper;
