import { AlertsActionTypes } from './alerts.types';

export const initialState = {
  diagnosticAlerts: [],
  ticketsAlerts: [],
  clickOnAlertDomainToPoint: false,
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case AlertsActionTypes.SET_DIAGNOSTIC_ALERTS: {
      return {
        ...state,
        diagnosticAlerts: action.payload,
      };
    }
    case AlertsActionTypes.SET_TICKETS_ALERTS: {
      return {
        ...state,
        ticketsAlerts: action.payload,
      };
    }
    case AlertsActionTypes.ADD_NEW_DIAGNOSTIC_ALERT: {
      return {
        ...state,
        diagnosticAlerts: [action.payload, ...state.diagnosticAlerts],
      };
    }
    case AlertsActionTypes.CLICK_ON_ALERT_DOMAIN_TO_POINT: {
      return {
        ...state,
        clickOnAlertDomainToPoint: action.payload,
      };
    }
    default:
      return state;
  }
};

export default alertsReducer;
