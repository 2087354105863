import React from 'react';
import {
  withStyles, Card, Typography,
} from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { watchHgAcademyClick, getHgAcademyClick } from '@/utils/ThirdParties/tagManager';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { config } from '@/config';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '@/redux/actions/academyPass';
import { useHistory } from 'react-router';

const CourseCard = ({
  classes, t, course, userId, isPassActive, isPaidAndActive, cardType,
}) => {
  const { PASS_BASE_URL } = config;
  const courseTitle = course.name.length > 55 ? course.name.substr(0, 55).concat('...') : course.name;
  const { jwt } = useSelector(state => state.academyPass.tokens);
  const dispatch = useDispatch();
  const history = useHistory();

  const courseImage = () => (
    <div className={classes.imageWrapper}>
      <img className={classes.cardImage} src={course.thumbnail} alt={course.name} data-testid="course-info-image" />
      <div className={classnames([classes.cardType, course.isFree ? classes.cardTypeFree : classes.cardTypePass])} data-testid="course-plan-type">
        {course.isFree ? t('academypass.card.free') : t('academypass.card.pass')}
      </div>
    </div>
  );

  const courseInfo = () => (
    <div className={classes.infoWrapper}>
      <div className={classes.info} data-testid="course-info-content-count">
        <VideoLibrary />
        {`${course.contentCount ? course.contentCount : 0} ${t('academypass.card.classes')}`}
      </div>
      <div className={classes.info} data-testid="course-info-workload">
        <AccessTime />
        {`${course.workload ? course.workload : 0} ${t('academypass.card.minutes')}`}
      </div>
    </div>
  );

  const ratingInfo = (value) => {
    const stars = [];

    for (let i = 0; i < value; i += 1) {
      stars.push(<Star data-testid="rating-star" key={`ratingstar${i}`} />);
    }

    for (let j = 0; j < (5 - value); j += 1) {
      stars.push(<StarBorder data-testid="rating-empty-star" key={`retingemptystar${j}`} />);
    }
    return stars;
  };

  const accessCourseLaunchLab = () => {
    dispatch(getTokens());
    watchHgAcademyClick(course.name);

    window.open(`${PASS_BASE_URL}/public/login?token=${jwt}&redirect_url=${PASS_BASE_URL}/users/${userId}/products/${course.idProduct}`, '_blank').focus();
  };

  const handleClick = () => {
    const showLP = false;
    getHgAcademyClick(course.name);
    showLP ? history.push(t('routes.coursesTrial')) : history.push(t('routes.coursesCta'));
  };

  return (
    <Card className={classes.card} data-testid={cardType} key={course.idProduct}>
      {courseImage()}
      <div className={classes.detailsWrapper}>
        <Typography className={classes.courseTitle} data-testid="course-card-title">
          {courseTitle}
        </Typography>
        {courseInfo()}
        <div className={classes.ratingStars}>
          {ratingInfo(parseInt(course.ratingAverage, 10))}
        </div>
        {(isPaidAndActive && isPassActive) || course.isFree ? (
          <OutlineButton onClick={accessCourseLaunchLab} className={classes.watchButton} component={Link} rel="noopener noreferrer" data-testid="btn-card-watch">
            {t('academypass.card.watch')}
          </OutlineButton>
        ) : (
          <PrimaryButton onClick={handleClick} className={classes.buyButton} data-testid="btn-card-buy">
            {t('academypass.card.buy')}
          </PrimaryButton>
        )}
      </div>
    </Card>
  );
};

export default withI18n()(withStyles(styles, { withTheme: true })(CourseCard));
