import React, { useCallback, useState } from 'react';
import * as Styles from './FinancialDoubts.styles';
import {
  Button,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const FinancialDoubts = ({
  opening,
  submitForm,
  selectedProduct,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { financialDoubts: financialDoubtsLocale, names: namesLocale } = ticketsLocale.forms;
  const [file, setFile] = useState('');
  const [fileBinary, setFileBinary] = useState();
  const [description, setDescription] = useState('');

  const handleFile = (e) => {
    setFileBinary(e.target.files[0]);
    setFile(e.currentTarget.value);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const productServer = selectedProduct.serverhostname || selectedProduct.dedicatedip || '';

    const data = {
      tags: [financialDoubtsLocale.tag, productServer, selectedProduct.name],
      subject: financialDoubtsLocale.subject,
      automation: false,
      key: V2Forms.FINANCIAL_DOUBTS,
      files: [fileBinary],
      body: `• ${financialDoubtsLocale.product}: ${selectedProduct.name} (${selectedProduct.domain}) - ${financialDoubtsLocale.user}: ${selectedProduct.username}
       • Servidor: ${productServer}
       • ${financialDoubtsLocale.solicitation}: ${namesLocale.financialDoubts}
       • ${financialDoubtsLocale.descriptionLabel}: ${description}
       • ${financialDoubtsLocale.productId}: ${selectedProduct.id || ''}`,
      pid: selectedProduct.pid || '',
      product: selectedProduct.id || '',
    };

    submitForm && submitForm(data);
  }, [submitForm, fileBinary, selectedProduct, description, financialDoubtsLocale, namesLocale]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Title>
        {financialDoubtsLocale.title}
      </Styles.Title>

      <Styles.Info>
        {financialDoubtsLocale.info}
      </Styles.Info>

      <Styles.InputWrapper>
        <Input
          type="textarea"
          testId="description"
          placeholder=""
          label={financialDoubtsLocale.descriptionLabel}
          value={description}
          onChange={e => setDescription(e.target.value)}
          maxLength={280}
          rows={3}
          counter
        />

        <Styles.FileDescription>
          {financialDoubtsLocale.addDocumentLabel}
        </Styles.FileDescription>

        <Styles.FileWrapper>
          <Styles.FileButton htmlFor="fileInput" file={!!file}>
            {(fileBinary && fileBinary.name) || financialDoubtsLocale.addDocumentButton}
          </Styles.FileButton>
          <Styles.FileInput
            type="file"
            id="fileInput"
            data-testid="fileInput"
            accept=".jpg,.jpeg,.png,.pdf"
            value={file}
            onChange={e => handleFile(e)}
          />
        </Styles.FileWrapper>
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={!description || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default FinancialDoubts;
