import React, {
  useCallback, useEffect, useState,
} from 'react';
import { SELECT_MODAL_STEPS, optionsList } from './useDnsModal.types';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { sitesActions } from '@/redux/modules/sites';
import { CheckingStatusStep } from './components/CheckingStatusStep';
import { DomainOriginalDestinationStep } from './components/DomainOriginalDestinationStep';
import { HowToSetupStep } from './components/HowToSetupStep';
import { FinishDomainSetupStep } from './components/FinishDomainSetupStep';
import { commonActions } from '@/redux/modules';
import { useConfiguredDomainStatus } from '@/hooks/domains/useConfiguredDomainStatus';
import { Modal } from 'gatorcomponents';
import { CART_URL } from '@/config';
import { DomainNotRegistered } from './components/DomainNotRegistered';

export const UseDnsModal = ({
  initialStep = SELECT_MODAL_STEPS.CHECKING_STATUS,
  onClose,
  domain = 'domainSample.com',
  handleActivateDomain,
  cloudflare,
  clearCloudFlareLastActivation,
}) => {
  const { useDnsModal: useDnsModalLocale } = useLocale();
  const { manualSelectDns: manualSelectDnsLocale } = useDnsModalLocale;

  const dispatch = useDispatch();
  const { loading: sitesLoading, domainDns } = useSelector(state => state.sites);
  const [selectManualDnsStep, setSelectManualDnsStep] = useState(initialStep);
  const [selectedManualDns, setSelectedManualDns] = useState(null);

  const onDomainNotRegistered = () => {
    setSelectManualDnsStep(SELECT_MODAL_STEPS.DOMAIN_NOT_REGISTERED);
  };

  const requestDomainDnsFromWhoIs = useCallback((domain) => {
    dispatch(sitesActions.domainDns.request(domain, onDomainNotRegistered));
  }, [dispatch]);
  const requestPlanDns = useCallback(planId => dispatch(sitesActions.planDns.request(planId)), [dispatch]);


  const setSelectManualDnsNextStep = useCallback(({ nextStep }) => {
    if (nextStep) {
      setSelectManualDnsStep(nextStep);
    }

    if (!nextStep) {
      setSelectManualDnsStep(+1);
    }
  }, []);

  const handleCloudflareLoaded = useCallback(() => {
    if (cloudflare.lastActivation.cloudflare_ns1) {
      setSelectManualDnsNextStep({ nextStep: SELECT_MODAL_STEPS.HOW_TO_SETUP });
    }
  }, [cloudflare, setSelectManualDnsNextStep]);

  useEffect(() => {
    handleCloudflareLoaded();
  }, [handleCloudflareLoaded]);

  const {
    loadedStatus,
    loadingStatus,
    configuredDomainStatus,
    verifyDomainConfiguration,
  } = useConfiguredDomainStatus(domain);

  const handleClose = useCallback(() => {
    onClose && onClose();
    setSelectManualDnsStep(SELECT_MODAL_STEPS.CHECKING_STATUS);
    clearCloudFlareLastActivation && clearCloudFlareLastActivation();
  }, [
    onClose,
    setSelectManualDnsStep,
    clearCloudFlareLastActivation,
  ]);

  const showCopyNotification = useCallback(() => {
    dispatch(commonActions.notifications.set({
      label: (
        <p>
          Texto copiado com sucesso!
        </p>
      ),
      type: 'success',
    }));
  }, [dispatch]);

  const getCheckingStatusStepProps = useCallback(() => ({
    title: manualSelectDnsLocale.step.checkStatus.title,
    subtitle: manualSelectDnsLocale.step.checkStatus.subtitle,
    description: manualSelectDnsLocale.step.checkStatus.description,
  }), [manualSelectDnsLocale]);

  const getDomainOriginalDestinationStepProps = useCallback(({ domain }) => {
    const handleContinue = () => {
      handleActivateDomain();
    };

    return (
      {
        subtitle: manualSelectDnsLocale.step.domainOriginalRegistration.subtitle,
        alertDescription: manualSelectDnsLocale.step.domainOriginalRegistration.alertDescription,
        domain,
        inputLabel: manualSelectDnsLocale.step.domainOriginalRegistration.inputLabel,
        optionsList,
        selectedManualDns,
        setSelectedManualDns,
        backLabel: manualSelectDnsLocale.backLabel,
        continueLabel: manualSelectDnsLocale.continueLabel,
        onHowToSetupClick: handleContinue,
        onCancelClick: handleClose,
        selectPlaceholder: manualSelectDnsLocale.step.domainOriginalRegistration.selectPlaceholder,
        loading: cloudflare.loadingActivation,
      }
    );
  }, [
    manualSelectDnsLocale,
    selectedManualDns,
    handleClose,
    handleActivateDomain,
    cloudflare,
  ]);

  const getHowToSetupProps = useCallback(() => ({
    howToSectionTitle: manualSelectDnsLocale.step.howToSetup.howToSectionTitle,
    selectedManualDns,
    showCopyNotification,
    onClickBack: () => setSelectManualDnsNextStep({ nextStep: SELECT_MODAL_STEPS.GET_DOMAIN_ORIGINAL_REGISTRATION }),
    onHowToSetupClick: () => setSelectManualDnsNextStep({ nextStep: SELECT_MODAL_STEPS.FINISH_SETUP_STEP }),
    serverLabel: index => manualSelectDnsLocale.step.howToSetup.serverLabel(index),
    goTo: selectedManualDns => manualSelectDnsLocale.step.howToSetup.goTo(selectedManualDns),
    alreadyMadeChanges: manualSelectDnsLocale.step.howToSetup.alreadyMadeChanges,
    backLabel: manualSelectDnsLocale.backLabel,
    steps: manualSelectDnsLocale.step.howToSetup.steps({ from: selectedManualDns }),
    domainDns: {
      ns1: domainDns ? domainDns[0] : null,
      ns2: domainDns ? domainDns[1] : null,
    },
    cloudflareDns: {
      ns1: cloudflare.lastActivation.cloudflare_ns1,
      ns2: cloudflare.lastActivation.cloudflare_ns2,
    },
  }), [
    manualSelectDnsLocale,
    domainDns,
    selectedManualDns,
    showCopyNotification,
    setSelectManualDnsNextStep,
    cloudflare,
  ]);

  const getDomainNotRegistered = useCallback(({ domain }) => {
    const newDomain = domain.split('.');
    const goTo = () => {
      window.open(`${CART_URL}?pid=d&sld=${newDomain[0]}&tld=.${newDomain[1]}`, '_blank');
    };

    return {
      title: manualSelectDnsLocale.step.domainNotRegistered.title(domain),
      description: manualSelectDnsLocale.step.domainNotRegistered.description,
      redirectLabel: manualSelectDnsLocale.step.domainNotRegistered.redirectLabel,
      goTo,
    };
  }, [
    manualSelectDnsLocale,
  ]);

  const getFinishDomainSetupStep = useCallback(({ domain }) => {
    const goTo = selectedManualDns => manualSelectDnsLocale.step.howToSetup.goTo(selectedManualDns);

    const onCheckDnsStatus = () => {
      verifyDomainConfiguration(domain);
    };

    const onGoBack = () => {
      setSelectManualDnsNextStep({ nextStep: SELECT_MODAL_STEPS.HOW_TO_SETUP });
    };

    return {
      title: manualSelectDnsLocale.step.finishDomainSetup.title,
      subtitle: manualSelectDnsLocale.step.finishDomainSetup.subtitle,
      box: manualSelectDnsLocale.step.finishDomainSetup.box,
      howToSectionTitle: manualSelectDnsLocale.step.finishDomainSetup.howToSectionTitle,
      searchLabel: manualSelectDnsLocale.step.finishDomainSetup.searchLabel,
      serverLabel: manualSelectDnsLocale.step.finishDomainSetup.serverLabel,
      actualDnsAtLabel: manualSelectDnsLocale.step.finishDomainSetup.actualDnsAtLabel,
      hostGatorDnsLabel: manualSelectDnsLocale.step.finishDomainSetup.hostGatorDnsLabel,
      actualDns: manualSelectDnsLocale.step.finishDomainSetup.actualDns,
      goTo,
      alreadyMadeChanges: manualSelectDnsLocale.step.finishDomainSetup.alreadyMadeChanges,
      alertDescription: manualSelectDnsLocale.step.finishDomainSetup.alertDescription,
      backtoInstructions: manualSelectDnsLocale.step.finishDomainSetup.backtoInstructions,
      onCheckDnsStatus,
      onGoBack,
      loadedStatus,
      loadingStatus,
      configuredDomainStatus,
      configuredDomainStatusTexts: manualSelectDnsLocale.step.finishDomainSetup.propagationText,
      onClose: handleClose,
      backToInstructionsLabel: manualSelectDnsLocale.backToInstructions,
      closeLabel: manualSelectDnsLocale.close,
      verifyStatusLabel: manualSelectDnsLocale.step.finishDomainSetup.verifyStatusLabel,
      selectedManualDns,
      finishLabel: manualSelectDnsLocale.finishLabel,
      backLabel: manualSelectDnsLocale.backLabel,
    };
  }, [
    handleClose,
    manualSelectDnsLocale,
    verifyDomainConfiguration,
    loadedStatus,
    loadingStatus,
    configuredDomainStatus,
    selectedManualDns,
    setSelectManualDnsNextStep,
  ]);

  const selectManualDnsModal = () => {
    const steps = {
      [SELECT_MODAL_STEPS.CHECKING_STATUS]: (
        <Modal
          onClose={handleClose}
          maxHeight="unset"
          maxWidth={960}
          testId="select-dns-modal"
          disablePadding
          title={manualSelectDnsLocale.step.checkStatus.title}
        >
          <CheckingStatusStep
            {...getCheckingStatusStepProps()}
          />
        </Modal>
      ),
      [SELECT_MODAL_STEPS.GET_DOMAIN_ORIGINAL_REGISTRATION]: (
        <Modal
          onClose={handleClose}
          maxHeight="unset"
          maxWidth={960}
          testId="select-dns-modal"
          title={manualSelectDnsLocale.step.domainOriginalRegistration.title}
        >

          <DomainOriginalDestinationStep
            {...getDomainOriginalDestinationStepProps({ domain })}
          />
        </Modal>
      ),
      [SELECT_MODAL_STEPS.HOW_TO_SETUP]: (
        <Modal
          onClose={handleClose}
          maxHeight="unset"
          maxWidth={960}
          testId="select-dns-modal"
          disablePadding
          title={manualSelectDnsLocale.step.howToSetup.title}
        >

          <HowToSetupStep
            {...getHowToSetupProps()}
          />
        </Modal>
      ),
      [SELECT_MODAL_STEPS.FINISH_SETUP_STEP]: (
        <Modal
          onClose={handleClose}
          maxHeight="unset"
          maxWidth={674}
          testId="select-dns-modal"
          disablePadding
        >
          <FinishDomainSetupStep
            {...getFinishDomainSetupStep({ domain })}
          />
        </Modal>
      ),
      [SELECT_MODAL_STEPS.DOMAIN_NOT_REGISTERED]: (
        <Modal
          onClose={handleClose}
          maxHeight="unset"
          maxWidth={960}
          testId="select-dns-modal"
          disablePadding
        >
          <DomainNotRegistered
            {...getDomainNotRegistered({ domain })}
          />
        </Modal>
      ),
    };

    return steps[selectManualDnsStep];
  };


  const useDnsModalData = {
    selectManualDnsModal,
    requestDomainDnsFromWhoIs,
    sitesLoading,
    selectManualDnsStep,
    setSelectManualDnsStep,
    setSelectManualDnsNextStep,
    requestPlanDns,
    domainDns,
  };

  return useDnsModalData;
};

export default UseDnsModal;
