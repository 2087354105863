import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    border-radius: 8px ;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    margin: 16px 0px 0px 0px;
    padding: 16px 0px 16px 0px;
  `}
`;

export const ContentText = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    height: fit-content;
    margin: 2px 24px 0px 16px;
     `}
`;


export const Icon = styled.img`
    margin: 0px 0px 0px 24px;
    `;

export const Title = styled.span`
s  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    margin: 0 0 8px 0;
`}
`;
