import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';


class NewSiteTitle extends React.PureComponent {
  render() {
    const { title, classes, className } = this.props;
    return (
      <h1 className={`${classes.title} ${className}`}>{title}</h1>
    );
  }
}

export default withStyles(styles)(NewSiteTitle);
