const styles = theme => ({
  recordListContainer: {
    width: '100%',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      marginTop: 29,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 36,
    },
  },

  loadingWrapper: {
    backgroundColor: 'rgba(255,255,255,.8)',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
