import { CALL_API } from '@/middleware/api';

import {
  ACCOUNT_INFORMATION_URL,
  PERSONA_URL,
  STATES_URL,
  CITIES_URL,
  COUNTRIES_URL,
  ZIP_CODE_URL,
  SUMMARY_URL,
} from '@/config/api';
import { COUNTRY } from '@/config';
import { formatPhoneNumber } from '@/utils/Formatters/phoneNumber';
import {
  ONBOARDING_DISMISS, RECEIVE_SUMMARY,
  REQUEST_SUMMARY, ERROR_SUMMARY,
  SUCCESS_SAVE_PERSONA, REQUEST_SAVE_PERSONA,
  ERROR_SAVE_PERSONA, RECEIVE_LOAD_STATES,
  REQUEST_LOAD_STATES, ERROR_LOAD_STATES,
  REQUEST_LOAD_CITIES_BY_STATES, RECEIVE_LOAD_CITIES_BY_STATES,
  ERROR_LOAD_CITIES_BY_STATES, REQUEST_LOAD_COUNTRIES,
  ERROR_LOAD_COUNTRIES, RECEIVE_LOAD_COUNTRIES,
  REQUEST_LOAD_ZIP_CODE, ERROR_LOAD_ZIP_CODE, RECEIVE_LOAD_ZIP_CODE,
  REQUEST_UPDATE_SUMMARY, RECEIVE_UPDATE_SUMMARY,
  ERROR_UPDATE_SUMMARY, ABORT_LOAD_STATES, NEW_SITE_FLUX_DISMISS,
  EMAIL_VERIFY_SKIP,
  PHONENUMBER_VERIFY_SKIP,
  SET_PHONENUMBER_VERIFIED,
} from './actionsTypes';


export const websiteBuilderDismiss = () => ({ type: ONBOARDING_DISMISS });
export const newSiteFluxDismiss = () => ({ type: NEW_SITE_FLUX_DISMISS });

export const emailFlowSkip = () => ({ type: EMAIL_VERIFY_SKIP });
export const phoneNumberFlowSkip = () => ({ type: PHONENUMBER_VERIFY_SKIP });

export const receiveSummary = summary => ({
  type: RECEIVE_SUMMARY,
  summary,
});

const receivePersona = data => ({
  type: SUCCESS_SAVE_PERSONA,
  data,
});

const receiveStates = (data, country) => ({
  type: RECEIVE_LOAD_STATES,
  data,
  country,
});

const receiveCities = (data, state) => ({
  type: RECEIVE_LOAD_CITIES_BY_STATES,
  data,
  state,
});

const receiveCountries = (data, countryAbbreviation) => {
  const findCountry = data.find(item => item.abbreviation.toLowerCase() === countryAbbreviation.toLowerCase());

  return {
    type: RECEIVE_LOAD_COUNTRIES,
    countryName: (findCountry && findCountry.name) || '',
    data,
  };
};

const receiveZipCodeAdress = data => ({
  type: RECEIVE_LOAD_ZIP_CODE,
  data,
});

const receiveUpdateSummary = (data) => {
  const indexSub = data.name.indexOf(' ');
  const firstName = data.name.substring(0, indexSub);
  const lastName = data.name.substring((indexSub + 1), data.name.lenght);

  return {
    type: RECEIVE_UPDATE_SUMMARY,
    firstName,
    lastName,
    data,
  };
};

export const setPhoneVerified = payload => ({
  type: SET_PHONENUMBER_VERIFIED,
  payload,
});

export const loadSummary = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ACCOUNT_INFORMATION_URL,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SUMMARY }),
      success: (response) => {
        if (COUNTRY === 'br' && response.data.nationality === '') {
          response.data.nationality = 'BR';
        }
        const phoneNumber = formatPhoneNumber({
          phoneNumber: response.data.phoneNumber,
          phoneNumberCountry: response.data.phoneNumberCountry,
        });
        response.data.phoneNumber = phoneNumber;
        return receiveSummary(response.data);
      },
      error: () => ({ type: ERROR_SUMMARY }),
    },
  },
});

export const choosePersona = idSelectedPersona => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PERSONA_URL,
    method: 'PUT',
    body: { persona: idSelectedPersona },
    actionTypes: {
      request: () => ({ type: REQUEST_SAVE_PERSONA }),
      success: response => receivePersona({ idSelectedPersona, nameSelectedPersona: response.data.persona }),
      error: () => ({ type: ERROR_SAVE_PERSONA }),
    },
  },
});

export const loadCountries = country => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: COUNTRIES_URL,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_LOAD_COUNTRIES }),
      success: data => receiveCountries((data.data.countries || []), country),
      error: () => ({ type: ERROR_LOAD_COUNTRIES }),
    },
  },
});

export const loadStatesByCountry = country => (dispatch, getState) => {
  const { summary } = getState();
  if (summary.locationData.lastCountryLoad === country) {
    return new Promise(resolve => resolve(dispatch({
      type: ABORT_LOAD_STATES,
    })));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: STATES_URL(country),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_LOAD_STATES }),
        success: data => receiveStates(data.data.states || [], country),
        error: () => ({ type: ERROR_LOAD_STATES }),
      },
    },
  });
};

export const loadCitiesByCountryAndState = (country, state) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: CITIES_URL(country, state),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_LOAD_CITIES_BY_STATES }),
      success: data => receiveCities(data.data.cities || [], state),
      error: () => ({ type: ERROR_LOAD_CITIES_BY_STATES }),
    },
  },
});

export const loadZipCode = zipCode => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ZIP_CODE_URL(zipCode),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_LOAD_ZIP_CODE }),
      success: response => receiveZipCodeAdress(response.data || null),
      error: () => ({ type: ERROR_LOAD_ZIP_CODE }),
    },
  },
});

export const updateSummary = userData => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: SUMMARY_URL,
    method: 'PUT',
    body: userData,
    actionTypes: {
      request: () => ({ type: REQUEST_UPDATE_SUMMARY }),
      success: response => receiveUpdateSummary(userData, response.data),
      error: response => ({ type: ERROR_UPDATE_SUMMARY, data: response.response.data.notifications[0] }),
    },
  },
});
