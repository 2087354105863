import styled, { css } from 'styled-components';

export const DnsWizardZoneRecordsHandlerWrapper = styled.div`
  overflow: hidden;
`;

export const TextBlock = styled.div``;

export const Subtitle = styled.div`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.v1.font.weights.regular};
    line-height: ${theme.font.lineHeights.lg};
    margin: 16px 0px 24px 40px;
    > &:first-of-type {
      margin-top: 8px;
    }
  `}
`;
