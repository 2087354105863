import React from 'react';
import * as Styles from './DnsWizardPlanCard.styles';
import {
  IconHosting,
  IconVPS,
  IconResale,
  IconDedicatedLinux,
  IconDedicatedWindows,
} from '@/icons';
import { PLAN_TYPES } from '@/enums/domains/dns.enum';
import { formatDomainEllipsis } from '@/utils/Formatters/formatDomainEllipsis';

const DnsWizardPlanCard = ({
  plan = 'Title',
  principalDomain = 'principaldomain.com',
  otherDomains = [],
  type = 'hostingaccount',
  selected = false,
  handleSelected,
  id = 0,
  mainDomainText,
  otherDomainsText,
  domainText,
  domainTextPlural,
  planSelectGa,
  selectedPlan,
  platformId = null,
  setPlatformId = p => p,
}) => {
  const domainsLengthCalc = otherDomains.length - 2;
  const domainsPluralCalc = domainsLengthCalc > 1 ? domainTextPlural : domainText;

  const handleCardClick = (type) => {
    handleSelected(id);
    planSelectGa(plan);
    selectedPlan(type);
    setPlatformId(platformId);
  };

  const setCardPlanIcon = (planType) => {
    switch (planType) {
      case PLAN_TYPES.PLAN:
        return <IconHosting />;
      case PLAN_TYPES.DEDICATED: {
        if (plan.match(/^.*vps.*$/i)) {
          return <IconVPS />;
        }
        if (plan.match(/^.*windows.*$/i)) {
          return <IconDedicatedWindows />;
        }
        return <IconDedicatedLinux />;
      }
      case PLAN_TYPES.RESALE:
        return <IconResale />;
      default:
        return null;
    }
  };

  const formatDomainQuantity = () => ` (+${domainsLengthCalc} ${domainsPluralCalc})`;

  return (
    <Styles.DnsWizardPlanCardWrapper
      selected={selected}
      onClick={() => handleCardClick(type)}
      data-testid={`planCard-${id}`}
    >
      <Styles.Icon>{setCardPlanIcon(type)}</Styles.Icon>
      <Styles.DnsWizardContainer>
        <Styles.Plan>{plan}</Styles.Plan>
        <Styles.MainDomain>
          {mainDomainText}
          <br />
          {' '}
          {formatDomainEllipsis(principalDomain, 20)}
        </Styles.MainDomain>
        { otherDomains.length > 0 && (
        <Styles.OtherDomains>
          {otherDomainsText}
          <br />
          { ' ' }
          { formatDomainEllipsis(otherDomains[0], 20) }
          { otherDomains.length > 1 && ` / ${formatDomainEllipsis(otherDomains[1], 20)}` }
          { otherDomains.length > 2 && formatDomainQuantity()}
        </Styles.OtherDomains>
        )}
      </Styles.DnsWizardContainer>
    </Styles.DnsWizardPlanCardWrapper>

  );
};

export default DnsWizardPlanCard;
