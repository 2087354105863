import React from 'react';
import { withStyles, Link } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import styles from './styles';


const AlertBox = ({
  classes, message, title, urlTitle, url,
}) => (
  <div role="alert" data-testid="alert-box" className={classes.AlertBox}>
    <Warning data-testid="warning" />
    <p>
      <strong>{title}</strong>
      {message}
      {' '}
      {url && <Link href={url} rel="noreferrer" target="_blank">{urlTitle}</Link>}
    </p>
  </div>
);

export default withStyles(styles, { withTheme: true })(AlertBox);
