import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import {
  Grid, CardContent, CardActions, IconButton, withStyles, Collapse, Button,
} from '@material-ui/core';
import classnames from 'classnames';
import { ExpandMore } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import IconInvoice from '@material-ui/icons/Receipt';
import invoiceType from '@/types/invoiceType';
import { invoiceCardTagManager, visualizeNFSeTagManager } from '@/utils/ThirdParties/tagManager';
import { formatDate } from '@/utils/Formatters/formatDate';
import Loader from '@/components/Layout/Loader';
import InvoiceDetails from '@/components/Invoices/InvoiceDetails';
import ModalInvoiceWrapped from '@/components/Invoices/ModalInvoiceWrapped';
import CurrencyFormat from '@/components/CurrencyFormat';
import { INVOICES_STATUS } from '@/config/invoices/invoiceStatus';
import { loadInvoiceDetails } from '@/redux/actions/invoices';
import InvoiceStatusCard from '@/components/Cards/InvoiceStatusCard';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { DOWNLOAD_INVOICE_VOUCHER } from '@/config/api';
import styles from './styles';
import SaversDiscountBanner from '../SaversDiscountBanner';
import { DiscountType } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';


class InvoiceItem extends Component {
  constructor(props) {
    super(props);
    this.handleMatchMedia = this.handleMatchMedia.bind(this);
  }

  state = {
    expandDetails: false,
    loadingDetails: false,
    nfseModalOpen: false,
    nfseModalRender: false,
    showCollapseMiddle: false,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.handleMatchMedia);
    this.handleMatchMedia();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleMatchMedia);
  }

  expandDetails = async () => {
    const { invoice, loadInvoiceDetails } = this.props;

    this.setState(prevState => ({
      ...prevState,
      expandDetails: !prevState.expandDetails,
    }));

    if (!invoice.details) {
      this.setState({ loadingDetails: true });
      await loadInvoiceDetails(invoice.id);
      this.setState({ loadingDetails: false });
    }
  }

  handleCloseNfseModal = () => {
    this.setState({
      nfseModalRender: false,
      nfseModalOpen: false,
    });
  }

  handleOpenNfseModal = () => {
    const { userSummary } = this.props;
    const isJuridicPerson = userSummary.personType === 'BUSINESS';
    visualizeNFSeTagManager(isJuridicPerson);

    this.setState({
      nfseModalRender: true,
      nfseModalOpen: true,
    });
  }

  handleMatchMedia = () => {
    const screenMinimalDesktop = window.matchMedia('(min-width: 960px) and (max-width: 1100px)');
    const screenSmartphones = window.matchMedia('(max-width: 792px)');

    if (screenMinimalDesktop.matches || screenSmartphones.matches) {
      this.setState({
        showCollapseMiddle: true,
      });
    } else {
      this.setState({
        showCollapseMiddle: false,
      });
    }
  }

  isUnpaid = (status) => {
    if (status === 'paid' || status === 'cancelled' || status === 'refunded') { return false; }
    return true;
  }

  isPaid = status => (status === 'paid');

  render() {
    const {
      classes,
      invoice,
      content,
      t,
      invoiceValueLabel,
      action,
      type,
      enabledNewModalPayment,
    } = this.props;

    const {
      expandDetails,
      loadingDetails,
      nfseModalRender,
      nfseModalOpen,
      showCollapseMiddle,
    } = this.state;

    let arrowClass;
    let cardContainerClass;
    let firstWrapperClass;
    let secondWrapperClass;

    if (this.isUnpaid(invoice.statusMessage)) {
      arrowClass = classes.arrowIcon;
      cardContainerClass = classes.cardContainer;
      firstWrapperClass = classes.firstWrapper;
      secondWrapperClass = enabledNewModalPayment ? classes.secondWrapperNotUnpaidWithNewModalPayment : classes.secondWrapper;
    } else {
      arrowClass = classes.arrowIconNotUnpaid;
      cardContainerClass = classes.cardContainerNotUnpaid;
      firstWrapperClass = classes.firstWrapperNotUnpaid;
      secondWrapperClass = classes.secondWrapperNotUnpaid;
    }

    return (
      <Grid item xs={12} className={classes.content}>
        <InvoiceStatusCard invoiceStatus={invoice.statusMessage}>
          <CardContent
            className={`${invoice.status} ${invoice.isExpired ? 'isExpired' : ''} ${classes.contentFlex}`}
          >

            <div className={cardContainerClass}>
              <div className={firstWrapperClass}>
                <div className={classes.statusContainer}>
                  <IconInvoice onClick={this.handlePaypalModal} className={`${'status-icon'} ${classes.statusIcon}`} />
                  <span className={classes.iconLabel}>
                    <span className={classes.id}>{invoice.id}</span>
                    <span className={`${classes.label} status-label`}>{t(`invoices.${invoice.statusMessage}`)}</span>
                  </span>
                </div>
                <div className={classes.dueContainer}>
                  <div className={classes.dueWrapper}>
                    <span className={classes.subtitle}>{t('invoiceDueDateLabel')}</span>
                    <p className={classes.subValue}>{formatDate(invoice.duedate)}</p>
                  </div>
                  <div className={classes.valueWrapper}>
                    <span className={classes.subtitle}>{t(invoiceValueLabel)}</span>
                    <p className={classes.subValue}>
                      <CurrencyFormat>{invoice.totalStatus}</CurrencyFormat>
                    </p>
                  </div>
                </div>
              </div>

              {showCollapseMiddle && this.isUnpaid(invoice.statusMessage) && (
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Collapse in={expandDetails || loadingDetails} timeout="auto">
                      <CardContent className={classes.preTable}>
                        {Object.prototype.hasOwnProperty.call(invoice, 'details') === false && loadingDetails && <Loader />}
                        {invoice.details && <InvoiceDetails invoice={invoice.details} />}
                      </CardContent>
                    </Collapse>
                  </Grid>
                </Grid>
              )}

              <div className={secondWrapperClass}>
                {content && (
                  content
                )}
                <div className={classes.actionContainer}>
                  <CardActions className={classes.actions}>
                    {action}
                    {type === INVOICES_STATUS.PAID && invoice.nfse.length > 0 && (
                      <div>
                        <Button
                          className={`${'cardPayButton'} ${classes.pay}`}
                          onClick={this.handleOpenNfseModal}
                        >
                          {t('showReceiptAction')}
                        </Button>

                        {nfseModalRender && (
                          <ModalInvoiceWrapped
                            modal={invoice.id}
                            nfse={invoice.nfse}
                            open={nfseModalOpen}
                            onClose={this.handleCloseNfseModal}
                          />
                        )}
                      </div>
                    )}
                  </CardActions>
                  <IconButton
                    className={classnames(classes.expand, { [classes.expandOpen]: expandDetails }, arrowClass)}
                    onClick={() => {
                      InvoiceItem.gaEvent(expandDetails);
                      this.expandDetails();
                    }}
                  >
                    <ExpandMore className={classes.arrow} />
                  </IconButton>
                </div>
              </div>
            </div>

            {!showCollapseMiddle && this.isUnpaid(invoice.statusMessage) && (
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Collapse in={expandDetails || loadingDetails} timeout="auto">
                    <CardContent className={classes.preTable}>
                      {Object.prototype.hasOwnProperty.call(invoice, 'details') === false && loadingDetails && <Loader />}
                      {invoice.details && <InvoiceDetails invoice={invoice.details} />}
                    </CardContent>
                  </Collapse>
                </Grid>
              </Grid>
            )}

            {!this.isUnpaid(invoice.statusMessage) && (
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Collapse in={expandDetails || loadingDetails} timeout="auto">
                    <CardContent className={classes.preTable}>
                      {Object.prototype.hasOwnProperty.call(invoice, 'details') === false && loadingDetails && <Loader />}
                      {invoice.details && <InvoiceDetails invoice={invoice.details} />}
                      {this.isPaid(invoice.statusMessage) && (
                        <div className={classes.downloadContainer}>
                          <OutlineButton
                            component="a"
                            href={DOWNLOAD_INVOICE_VOUCHER(invoice.id)}
                          >
                            {t('invoices.download')}
                          </OutlineButton>
                        </div>
                      )}
                    </CardContent>
                  </Collapse>
                </Grid>
              </Grid>
            )}
            {invoice.discount && invoice.discount.type === DiscountType.SAVERS35OFF && (
              <SaversDiscountBanner />
            )
            }
          </CardContent>
        </InvoiceStatusCard>
      </Grid>
    );
  }
}

InvoiceItem.gaEvent = expandDetails => !expandDetails && invoiceCardTagManager();

InvoiceItem.propTypes = {
  invoice: invoiceType.isRequired,
  action: PropTypes.node,
  enabledNewModalPayment: PropTypes.bool,
  content: PropTypes.node,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    status: PropTypes.string,
    expand: PropTypes.string,
    expandOpen: PropTypes.string,
    cardAction: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  loading: state.invoices.loading,
  userSummary: state.summary,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadInvoiceDetails,
}, dispatch);

export default withStyles(styles)(withI18n()(connect(mapStateToProps, mapDispatchToProps)(InvoiceItem)));
