import React from 'react';
import * as Style from './IconClose.style';

const IconClose = ({ color = '#4A4A4A', testId = 'icon-close', size = 24 }) => (
  <Style.Wrapper data-testid={testId}>
    <svg fill={color} width={size} height={size} viewBox="0 0 24 24">
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  </Style.Wrapper>
);


export default IconClose;
