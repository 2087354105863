import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 32px 32px 24px 32px;
  `}
`;

export const WarningWrapper = styled.div`
  margin: 0px 32px 16px;
  display: flex;

  > span {
    align-self: center;
    display: flex;
    margin-right: 14px;
  }
`;

export const SecondWarningWrapper = styled.div`
  display: flex;
  margin: 0px 32px 20px;
  padding-left: 40px;

  > span {
    align-self: center;
    display: flex;
    margin-right: 14px;
  }
`;

export const Warning = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.medium};
    line-height: 18px;
    margin: 0px;

    > span, > a {
      color: ${theme.v1.color.text.featured};
    }
  `}
`;

export const CheckboxWrapper = styled.label`
    ${({ theme }) => css`
      align-items: center;
      color: ${theme.v1.color.text.primary};
      cursor: pointer;
      display: flex;
      font-family: ${theme.v1.font.family.primary};
      font-size: 12px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 16px;
      margin: 0px 32px;
      padding-left: 40px;
      width: fit-content;

      > span { 
        margin: 0px 11px 0px 0px;
      }
    `}
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 26px 0px 40px;

  @media (max-width: 600px){
    margin: auto 0px 40px;
  }
`;
