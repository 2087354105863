import React from 'react';
import { locale } from '@/utils/locale';
import { formatDate } from '@/utils/Formatters/formatDate';
import { COUNTRY } from '@/config';

export const invoiceUrl = id => `${locale('routes.invoices')}${locale('routes.unpaid')}/${id}/${COUNTRY === 'br' ? 'boleto' : 'payu_boleto'}`;

// DOMAIN EXPIRED DEFAULT MESSAGES
export const domainExpiredDefaultMessage = domain => (
  <>
    {locale('cardAlerts.domain.messages.expiredInvoice.0')}
    <strong>{`${locale('cardAlerts.domain.messages.expiredInvoice.1')} ${formatDate(domain.expiryDate)}`}</strong>
    {locale('cardAlerts.domain.messages.expiredInvoice.2')}
  </>
);
export const domainExpiredDefaultLinkMessage = () => locale('cardAlerts.domain.messages.expiredInvoice.3');

// DOMAIN EXPIRED ON GRACE PERIOD MESSAGES
export const domainExpiredOnGracePeriodMessage = link => (
  <>
    {locale('cardAlerts.domain.messages.expiredOnGracePeriod.0')}
    {link}
    {locale('cardAlerts.domain.messages.expiredOnGracePeriod.2')}
  </>
);
export const domainExpiredOnGracePeriodLinkMessage = () => locale('cardAlerts.domain.messages.expiredOnGracePeriod.1');

// DOMAIN EXPIRED GRACE PERIOD OVERDUE
export const expiredGracePeriodOverdue = domain => (
  <>
    {locale('cardAlerts.domain.messages.expiredGracePeriodOverdue.0')}
    <strong>
      {locale('cardAlerts.domain.messages.expiredGracePeriodOverdue.1')}
      {formatDate(domain.expiryDate)}
    </strong>
    {locale('cardAlerts.domain.messages.expiredGracePeriodOverdue.2')}
  </>
);

// DOMAIN CANCELLED RECOVERABLE MESSAGES
export const domainCancelledRecoverableMessage = domain => (
  <>
    {locale('cardAlerts.domain.messages.cancelledDomain.0')}
    <strong>
      {locale('cardAlerts.domain.messages.cancelledDomain.1')}
    </strong>
    {formatDate(domain.expiryDate)}
    {locale('cardAlerts.domain.messages.cancelledDomain.2')}
  </>
);
export const domainCancelledRecoverableLinkMessage = () => (locale('cardAlerts.domain.messages.cancelledDomain.3'));

// DOMAIN CANCELLED UNRECOVERABLE MESSAGES
export const domainCancelledUnRecoverableMessage = domain => (
  <>
    {locale('cardAlerts.domain.messages.cancelledDomain.0')}
    <strong>
      {locale('cardAlerts.domain.messages.cancelledDomain.1')}
    </strong>
    {formatDate(domain.expiryDate)}
    {'. '}
  </>
);
export const domainCancelledUnRecoverableLinkMessage = () => (locale('cardAlerts.domain.messages.cancelledDomain.3'));

// DOMAIN CANCELLED BY TOS ABUSE MESSAGES
export const domainCancelledByTosAbuseMessage = () => (
  <>
    {locale('cardAlerts.domain.messages.cancelledByAbuseTOS.0')}
    <strong>
      {locale('cardAlerts.domain.messages.cancelledByAbuseTOS.1')}
    </strong>
    {locale('cardAlerts.domain.messages.cancelledByAbuseTOS.2')}
  </>
);
export const domainCancelledByTosAbuseLinkMessage = () => (locale('cardAlerts.domain.messages.cancelledByAbuseTOS.3'));
