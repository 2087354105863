import { BillingActionTypes } from './billing.types';

const getSubscriptionsRequest = data => ({ type: BillingActionTypes.GET_SUBSCRIPTIONS_REQUEST, payload: data });
const getSubscriptionsSuccess = () => ({ type: BillingActionTypes.GET_SUBSCRIPTIONS_SUCCESS });
const getSubscriptionsFailure = () => ({ type: BillingActionTypes.GET_SUBSCRIPTIONS_FAILURE });

const requestSubscriptionsNotInCreditCard = () => ({ type: BillingActionTypes.GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD });
const successRequestSubscriptionsNotInCreditCard = () => ({ type: BillingActionTypes.SUCCESS_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD });
const failureRequestSubscriptionsNotInCreditCard = () => ({ type: BillingActionTypes.FAILURE_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD });
const setLoadingSubscriptionsNotInCreditCard = bool => ({ type: BillingActionTypes.SET_LOADING_SUBSCRIPTIONS_NO_IN_CREDIT_CARD, payload: bool });
const setSubscriptionsNotInCreditCard = list => ({ type: BillingActionTypes.SET_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD, payload: { list } });
const updateSubscriptionsNotInCreditCard = ({ list }) => ({ type: BillingActionTypes.UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD, payload: { list } });
const setLoadingUpdateSubscriptionsNotInCreditCard = bool => ({ type: BillingActionTypes.SET_LOADING_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD, payload: bool });
const successUpdateSubscriptionsNotInCreditCard = () => ({ type: BillingActionTypes.SUCCESS_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD });
const failureUpdateSubscriptionsNotInCreditCard = () => ({ type: BillingActionTypes.FAILURE_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD });
const setSubscriptionsLoadingStatus = bool => ({ type: BillingActionTypes.SET_LOADING_SUBSCRIPTIONS_STATUS, payload: { bool } });

const setSubscriptions = (subscriptions = []) => ({
  type: BillingActionTypes.SET_SUBSCRIPTIONS,
  payload: {
    subscriptions,
  },
});

const getPaymentMethodsRequest = () => ({ type: BillingActionTypes.GET_INVOICE_PAYMENT_METHODS_REQUEST });
const getPaymentMethodsSuccess = () => ({ type: BillingActionTypes.GET_INVOICE_PAYMENT_METHODS_SUCCESS });
const getPaymentMethodsFailure = () => ({ type: BillingActionTypes.GET_INVOICE_PAYMENT_METHODS_FAILURE });

const setPaymentMethods = (paymentMethods = []) => ({
  type: BillingActionTypes.SET_PAYMENT_METHODS,
  payload: {
    paymentMethods,
  },
});

const getSubscriptionsQuestionsRequest = type => ({
  type: BillingActionTypes.GET_SUBCRIPTIONS_QUESTIONS_REQUEST,
  payload: {
    type,
  },
});
const getSubscriptionsQuestionsSuccess = () => ({
  type: BillingActionTypes.GET_SUBCRIPTIONS_QUESTIONS_SUCCESS,
});
const getSubscriptionsQuestionsFailure = () => ({
  type: BillingActionTypes.GET_SUBCRIPTIONS_QUESTIONS_FAILURE,
});
const setSubscriptionQuestion = (question, type) => ({
  type: BillingActionTypes.SET_SUBSCRIPTIONS_QUESTION,
  payload: {
    type,
    question,
  },
});

const setSubscriptionCancel = payload => ({
  type: BillingActionTypes.SET_SUBSCRIPTIONS_CANCEL,
  payload,
});

const setSubscriptionRefundMethodSelected = (methodSelected, subscriptionId, subscriptionType) => ({
  type: BillingActionTypes.SET_SUBSCRIPTION_REFUND_METHOD_SELECTED,
  payload: {
    methodSelected,
    subscriptionId,
    subscriptionType,
  },
});

const subscriptionCancelRenewalRequest = (subscription, productId, productType, redirect) => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_RENEWAL_REQUEST,
  payload: {
    subscription,
    productId,
    productType,
    redirect,
  },
});
const subscriptionCancelRenewalSuccess = () => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_RENEWAL_SUCCESS,
});
const subscriptionCancelRenewalFailure = () => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_RENEWAL_FAILURE,
});

const subscriptionActivateRenewalRequest = (subscription, setModalById) => ({
  type: BillingActionTypes.SUBSCRIPTION_ACTIVATE_RENEWAL_REQUEST,
  payload: {
    subscription,
    setModalById,
  },
});
const subscriptionActivateRenewalSuccess = () => ({
  type: BillingActionTypes.SUBSCRIPTION_ACTIVATE_RENEWAL_SUCCESS,
});
const subscriptionActivateRenewalFailure = () => ({
  type: BillingActionTypes.SUBSCRIPTION_ACTIVATE_RENEWAL_FAILURE,
});

const subscriptionCancelSignatureRequest = ({
  subscription, productId, productType, history, cancel, openPreChatCancelFlow,
}) => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_SIGNATURE_REQUEST,
  payload: {
    subscription,
    productId,
    productType,
    history,
    cancel,
    openPreChatCancelFlow,
  },
});
const subscriptionCancelSignatureSuccess = () => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_SIGNATURE_SUCCESS,
});
const subscriptionCancelSignatureFailure = () => ({
  type: BillingActionTypes.SUBSCRIPTION_CANCEL_SIGNATURE_FAILURE,
});


const getSubscriptionsRefundCheckRequest = ({
  subscriptionId,
  subscriptionType,
}) => ({
  type: BillingActionTypes.SUBSCRIPTION_REFUND_CHECK_REQUEST,
  payload: {
    subscriptionId,
    subscriptionType,
  },
});
const getSubscriptionsRefundCheckFailure = () => ({ type: BillingActionTypes.SUBSCRIPTION_REFUND_CHECK_FAILURE });
const getSubscriptionsRefundCheckSuccess = () => ({ type: BillingActionTypes.SUBSCRIPTION_REFUND_CHECK_SUCCESS });
const setRefund = (refund, subscriptionId, subscriptionType) => ({
  type: BillingActionTypes.SET_SUBSCRIPTION_REFUND,
  payload: { refund, subscriptionId, subscriptionType },
});

const subscriptionSwitchPaymentMethodRequest = (subscription, paymentMethod, onSuccess) => ({
  type: BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_REQUEST,
  payload: { subscription, paymentMethod, onSuccess },
});

const subscriptionSwitchPaymentMethodSuccess = () => ({ type: BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_SUCCESS });
const subscriptionSwitchPaymentMethodFailure = () => ({ type: BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_FAILURE });
const subscriptionSwitchPaymentMethodClearStatus = () => ({ type: BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_CLEAR_STATUS });
const subscriptionSwitchPaymentMethodLoading = isLoading => ({ type: BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_LOADING, payload: { isLoading } });

const getSubscriptionAbleToDiscountRequest = hostingId => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_REQUEST,
  payload: { hostingId },
});
const getSubscriptionAbleToDiscountSuccess = () => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_SUCCESS,
});
const getSubscriptionAbleToDiscountFailure = () => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT_FAILURE,
});


const getSubscriptionAbleToCancelOfferRequest = (subscriptionId, subscriptionType, optionId, actionSuccess, actionFail) => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_REQUEST,
  payload: {
    subscriptionId, subscriptionType, optionId, actionSuccess, actionFail,
  },
});
const getSubscriptionAbleToCancelOfferSuccess = () => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_SUCCESS,
});
const getSubscriptionAbleToCancelOfferFailure = () => ({
  type: BillingActionTypes.GET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER_FAILURE,
});
const setSubscriptionAbleToCancelOffer = offerRetentionCancel => ({
  type: BillingActionTypes.SET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER,
  payload: {
    offerRetentionCancel,
  },
});
const setSubscriptionAbleToDiscount = (subscriptionInfos, hostingId) => ({
  type: BillingActionTypes.SET_SUBSCRIPTIONS_ABLE_TO_DISCOUNT,
  payload: {
    subscriptionInfos,
    hostingId,
  },
});

const setLoadingStatusAcceptRetentionOffer = bool => ({
  type: BillingActionTypes.SET_LOADING_STATUS_ACCEPT_RETENTION_OFFER,
  payload: { bool },
});
const acceptRetentionOfferRequest = ({
  subscriptionId, subscriptionType, offer, actionSuccess, showMessage,
}) => ({
  type: BillingActionTypes.ACCEPT_RETENTION_OFFER_REQUEST,
  payload: {
    subscriptionId, subscriptionType, offer, actionSuccess, showMessage,
  },
});
const acceptRetentionOfferSuccess = () => ({
  type: BillingActionTypes.ACCEPT_RETENTION_OFFER_SUCCESS,
});
const acceptRetentionOfferFailure = () => ({
  type: BillingActionTypes.ACCEPT_RETENTION_OFFER_FAILURE,
});
const setAcceptRetentionOffer = isAcceptOfferRedutionCancel => ({
  type: BillingActionTypes.SET_ACCEPT_RETENTION_OFFER,
  payload: { isAcceptOfferRedutionCancel },
});


const getSubscriptionDiscountValuesRequest = hostingId => ({
  type: BillingActionTypes.GET_DISCOUNT_VALUES_REQUEST,
  payload: { hostingId },
});

const getSubscriptionDiscountValuesSuccess = () => ({
  type: BillingActionTypes.GET_DISCOUNT_VALUES_SUCCESS,
});

const getSubscriptionDiscountValuesFailure = () => ({
  type: BillingActionTypes.GET_DISCOUNT_VALUES_FAILURE,
});

const setSubscriptionDiscountValues = (values, hostingId) => ({
  type: BillingActionTypes.SET_DISCOUNT_VALUES,
  payload: {
    values,
    hostingId,
  },
});

const getHostBenefitBillingRequest = hostingId => ({
  type: BillingActionTypes.GET_HOST_BENEFIT_BILLING_REQUEST,
  payload: { hostingId },
});

const getHostBenefitBillingFailure = () => ({
  type: BillingActionTypes.GET_HOST_BENEFIT_BILLING_FAILURE,
});

const getHostBenefitBillingSuccess = () => ({
  type: BillingActionTypes.GET_HOST_BENEFIT_BILLING_SUCCESS,
});

const setHostBenefitBilling = invoice => ({
  type: BillingActionTypes.SET_HOST_BENEFIT_BILLING,
  payload: {
    invoice,
  },
});

const resetBenefitBilling = () => ({
  type: BillingActionTypes.RESET_HOST_BENEFIT_BILLING,
});

const subscriptionReactivationRequest = ({ invoiceId, redirect }) => ({
  type: BillingActionTypes.SUBSCRIPTION_REACTIVATION_REQUEST,
  payload: { invoiceId, redirect },
});

const subscriptionReactivationSuccess = () => ({
  type: BillingActionTypes.SUBSCRIPTION_REACTIVATION_SUCCESS,
});

const subscriptionReactivationFailure = () => ({
  type: BillingActionTypes.SUBSCRIPTION_REACTIVATION_FAILURE,
});

const setSubscriptionReactivation = invoiceId => ({
  type: BillingActionTypes.SET_SUBSCRIPTION_REACTIVATION,
  payload: {
    invoiceId,
  },
});

const setSubscriptionReactivationLoading = isLoading => ({ type: BillingActionTypes.SET_SUBSCRIPTION_REACTIVATION_LOADING, payload: { isLoading } });


const getDraftInvoicePublishRequest = ({ invoiceId, executePayment }) => ({
  type: BillingActionTypes.SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_REQUEST,
  payload: { invoiceId, executePayment },
});

const getDraftInvoicePublishFailure = () => ({
  type: BillingActionTypes.SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_FAILURE,
});

const getDraftInvoicePublishSuccess = () => ({
  type: BillingActionTypes.SUBSCRIPTION_PUBLISH_DRAFT_INVOICE_SUCCESS,
});

const getHostingAbleDiscountRequest = ({ subscriptionId, redirectPage }) => ({
  type: BillingActionTypes.CHECK_HOSTING_ABLE_DISCOUNT_REQUEST,
  payload: { subscriptionId, redirectPage },
});

const getHostingAbleDiscountFailure = () => ({
  type: BillingActionTypes.CHECK_HOSTING_ABLE_DISCOUNT_FAILURE,
});

const getHostingAbleDiscountSuccess = () => ({
  type: BillingActionTypes.CHECK_HOSTING_ABLE_DISCOUNT_SUCCESS,
});

const setHostingAbleDiscountData = subscription => ({
  type: BillingActionTypes.SET_CHECK_HOSTING_ABLE_DISCOUNT_DATA,
  payload: {
    subscription,
  },
});

const actions = {
  subscriptions: {
    failure: getSubscriptionsFailure,
    request: getSubscriptionsRequest,
    set: setSubscriptions,
    success: getSubscriptionsSuccess,
    requestSubscriptionsNotInCreditCard,
    setSubscriptionsNotInCreditCard,
    setLoadingSubscriptionsNotInCreditCard,
    successRequestSubscriptionsNotInCreditCard,
    failureRequestSubscriptionsNotInCreditCard,
    updateSubscriptionsNotInCreditCard,
    setLoadingUpdateSubscriptionsNotInCreditCard,
    successUpdateSubscriptionsNotInCreditCard,
    failureUpdateSubscriptionsNotInCreditCard,
    setSubscriptionsLoadingStatus,
    setLoadingStatusAcceptRetentionOffer,
    questions: {
      failure: getSubscriptionsQuestionsFailure,
      request: getSubscriptionsQuestionsRequest,
      success: getSubscriptionsQuestionsSuccess,
      set: setSubscriptionQuestion,
    },
    cancel: {
      set: setSubscriptionCancel,
    },
    cancelRenewal: {
      request: subscriptionCancelRenewalRequest,
      success: subscriptionCancelRenewalSuccess,
      failure: subscriptionCancelRenewalFailure,
    },
    activateRenewal: {
      request: subscriptionActivateRenewalRequest,
      success: subscriptionActivateRenewalSuccess,
      failure: subscriptionActivateRenewalFailure,
    },
    cancelSignature: {
      request: subscriptionCancelSignatureRequest,
      success: subscriptionCancelSignatureSuccess,
      failure: subscriptionCancelSignatureFailure,
    },
    switchPaymentMethod: {
      request: subscriptionSwitchPaymentMethodRequest,
      success: subscriptionSwitchPaymentMethodSuccess,
      failure: subscriptionSwitchPaymentMethodFailure,
      clearStatus: subscriptionSwitchPaymentMethodClearStatus,
      setLoading: subscriptionSwitchPaymentMethodLoading,
    },
    ableToDiscount: {
      request: getSubscriptionAbleToDiscountRequest,
      success: getSubscriptionAbleToDiscountSuccess,
      failure: getSubscriptionAbleToDiscountFailure,
      set: setSubscriptionAbleToDiscount,
    },
    ableToCancelOffer: {
      request: getSubscriptionAbleToCancelOfferRequest,
      success: getSubscriptionAbleToCancelOfferSuccess,
      failure: getSubscriptionAbleToCancelOfferFailure,
      set: setSubscriptionAbleToCancelOffer,
    },
    acceptRetentionOffer: {
      request: acceptRetentionOfferRequest,
      success: acceptRetentionOfferSuccess,
      failure: acceptRetentionOfferFailure,
      set: setAcceptRetentionOffer,
    },
    discountValues: {
      request: getSubscriptionDiscountValuesRequest,
      success: getSubscriptionDiscountValuesSuccess,
      failure: getSubscriptionDiscountValuesFailure,
      set: setSubscriptionDiscountValues,
    },
    reactivation: {
      request: subscriptionReactivationRequest,
      success: subscriptionReactivationSuccess,
      failure: subscriptionReactivationFailure,
      set: setSubscriptionReactivation,
      setLoading: setSubscriptionReactivationLoading,
    },
  },
  subscription: {
    refund: {
      method: {
        set: setSubscriptionRefundMethodSelected,
      },
      check: {
        failure: getSubscriptionsRefundCheckFailure,
        request: getSubscriptionsRefundCheckRequest,
        success: getSubscriptionsRefundCheckSuccess,
        set: setRefund,
      },
    },
  },
  paymentMethod: {
    request: getPaymentMethodsRequest,
    failure: getPaymentMethodsFailure,
    success: getPaymentMethodsSuccess,
    set: setPaymentMethods,
  },
  invoice: {
    request: getHostBenefitBillingRequest,
    failure: getHostBenefitBillingFailure,
    success: getHostBenefitBillingSuccess,
    set: setHostBenefitBilling,
    reset: resetBenefitBilling,
  },
  draftPublish: {
    request: getDraftInvoicePublishRequest,
    failure: getDraftInvoicePublishFailure,
    success: getDraftInvoicePublishSuccess,
  },
  hostingAbleDiscountRequest: {
    request: getHostingAbleDiscountRequest,
    failure: getHostingAbleDiscountFailure,
    success: getHostingAbleDiscountSuccess,
    set: setHostingAbleDiscountData,
  },
};

export default actions;
