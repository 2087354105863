import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, withStyles, CardContent, Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import ProductCard from '@/components/Cards/ProductCard';
import LinkButton from '@/components/Buttons/LinkButton';
import formatDateFns from '@/utils/Formatters/formatDateFns';
import styles from './styles';


const CardItem = ({ t, classes, product }) => {
  const nextinvoicedate = new Date(product.nextinvoicedate);
  const formatedNextInvoice = formatDateFns({ productDate: nextinvoicedate, additionalDays: 1 });
  const cancelDate = formatDateFns({ productDate: nextinvoicedate, additionalDays: 31 });

  return (
    <Grid item xs={12} className={classes.cardWrapper}>
      <ProductCard elevation={1}>
        <CardContent className={`${product.status.toLowerCase()} ${classes.cardContent}`}>
          <Grid container spacing={0}>
            <Grid container item xs={12} md={2} spacing={0} className={classes.cardItem}>

              <Typography className={`${classes.truncate} ${classes.itemTitle}`}>{t('oldReseller.productPlan')}</Typography>
              <Typography
                className={classnames(classes.truncate, {
                  [classes.fieldValue]: true,
                })}
                title={product.productname}
              >
                {product.productname}
              </Typography>

            </Grid>

            <Grid container item xs={5} md={2} spacing={0} className={classes.cardItem}>
              <Typography className={`${classes.truncate} ${classes.itemTitle}`}>{t('oldReseller.productStatus')}</Typography>
              <Typography
                className={classnames(classes.truncate, {
                  [classes.fieldValue]: true,
                })}
              >
                {t(`oldReseller.${product.status}`)}
              </Typography>
            </Grid>

            <Grid container item xs={4} md={5} spacing={0} className={classes.cardItem}>
              <Typography className={`${classes.truncate} ${classes.itemTitle}`} data-testid="productStatus">{product.status === 'Suspended' ? t('oldReseller.productCancelation') : t('oldReseller.productExpiration')}</Typography>
              <Typography
                className={classnames(classes.truncate, {
                  [classes.fieldValue]: true,
                })}
              >
                {product.status === 'Suspended' ? cancelDate : formatedNextInvoice}
              </Typography>
            </Grid>

            <Grid container item xs={12} md={3} spacing={0} className={classes.cardItem}>
              {product.actions.filter(action => (action.type === 'plan-switch')).map(action => (
                <LinkButton to={action.linkUrl} target="_blank" className={classes.LinkButton} key={action.linkUrl}>
                  {t('oldReseller.ModalCta')}
                </LinkButton>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </ProductCard>
    </Grid>
  );
};

CardItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default withStyles(styles)(withI18n()(CardItem));
