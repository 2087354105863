import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const CentralizedContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  min-height: 240px;
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
`;

export const Line = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-wrap: wrap;
    gap: 48px 80px;
    justify-content: flex-start;
    padding-bottom: 24px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      gap: 30px;
    }
  `}
`;

export const Data = styled.div`
  ${({ isStatus }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: ${isStatus ? '80px' : 'auto'};
  `}

`;

export const DataTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;

export const DataValue = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  width: 100%;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    max-width: 1020px;
  `};
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    display: flex;
    font-size: ${theme.v2.font.sizes.xs};
    color: ${theme.v2.colors.neutral.lowMedium};
    height: 314px;
    max-width: 566px;
    padding: 32px 0;
    text-align: center;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      align-self: normal;
      font-size: ${theme.v2.font.sizes.xxs};
      text-align: start;
    }
  `};
`;

export const ConfigureFTPWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 96px;
    margin-top: 80px;
`;

export const ConfigureFTPAlert = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    max-width: 579px;
  `};
`;
