import React from 'react';
import { IconWarning } from '@/icons';
import * as Styles from './Message.style';

const Message = ({
  borderColor = '#4A4A4A',
  Icon = <IconWarning />,
  iconColor = '#4A4A4A',
  testId = 'message',
  text = 'text',
  variant = 'default',
  onClick,
}) => {
  const handleOnClick = (event) => {
    onClick && onClick(event);
  };

  return (
    <Styles.Wrapper
      borderColor={borderColor}
      data-testid={testId}
      variant={variant}
      onClick={handleOnClick}
    >
      <Styles.Icon iconColor={iconColor} data-testid={`${testId}-icon`}>
        {!!Icon && Icon}
      </Styles.Icon>
      <Styles.Text data-testid={`${testId}-text`}>
        {text}
      </Styles.Text>
    </Styles.Wrapper>
  );
};

export default Message;
