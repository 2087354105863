import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 0px;
  `}
`;

export const LogsWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-drection: column;
    flex-wrap: wrap;
    margin: 16px 0px 0px;
  `}
`;

export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    padding: 32px 16px 16px;
  `}
`;

export const Log = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 100%;
    gap: 30px;
    padding: 25px 0px 22px;


    @media (max-width: ${theme.v2.breakpoints.md}){
      gap: 24px 0px;
    }
  `}
`;

export const NameWrapper = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 240px;
  width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}){
      max-width: 50%;
    }
  `}
`;

export const LogName = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const LogDate = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 4px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const StatusWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 194px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}){
      align-items: flex-end;
      max-width: 50%;
    }
  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  width: 100%;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin: 0px 16px 0px 0px;
  }
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;

    span, strong {
      display: inline-block;
    }

    strong {
      font-weight: ${theme.v2.font.weights.medium};
      margin-left: 5px;
    }

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
      font-weight: ${theme.v2.font.weights.regular};
    }
  `}
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 32px 0px 8px;
`;
