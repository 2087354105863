const styles = theme => ({
  subtitle: {
    textAlign: 'left',
    font: 'normal normal normal 24px/29px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
  },
  description: {
    font: 'normal normal normal 14px/17px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    marginTop: '5px',
  },
  formContainer: {
    maxWidth: '470px',
  },
  formContainerUpdate: {
    maxWidth: '100%',
  },
  FormField: {
    marginTop: '16px',
  },
  outlinedButton: {
    marginTop: '16px',
  },
  whiteButton: {
    marginTop: '16px',
    background: `${theme.color.white}`,
    color: theme.color.indigo,
    '&:hover': {
      color: theme.color.white,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  formSpacing: {
    padding: '24px',
  },
});

export default styles;
