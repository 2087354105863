import styled, { css } from 'styled-components';

const defaultWrapperWidth = '498px';

export const Form = styled.form`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const BlockTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const DestinationBlockTitle = styled(BlockTitle)`
  margin: 24px 0px
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0px 0px;
  max-width: ${defaultWrapperWidth};

  div {
    width: 100%;
  }
`;

export const RadioWrapper = styled(InputWrapper)`
  flex-direction: row;
  margin: 0px 0px 20px;
`;

export const CheckboxWrapper = styled(InputWrapper)`
  gap: 8px;
  margin: 24px 0px;
  max-width: unset;
`;

export const RadioLabel = styled.label`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    cursor: pointer;
    display: flex;
    font-size:  ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 4px;

    label {
      height: 19px;
      width: 19px;
    }
  `}
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0px 0px 4px;

  label {
    height: 19px;
    width: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 0px;

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const InfoText = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activeMedium};
    display: inline-block;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;


export const Warning = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px;

    strong {
      font-weight: ${theme.v2.font.family.medium};
      font-size: ${theme.v2.font.sizes.xs};
    }
  `}
`;

export const Alert = styled.span`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 20px 0px 40px;

    svg {
      flex-shrink: 0;
    }
  `}
`;

export const AlertText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.feedback.problemMedium};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 2px 0;
  `}
`;

export const AlertDescription = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    max-width: 1006px;

    svg {
      flex-shrink: 0;
    }
  `}
`;

export const WarningList = styled.ul`
  margin: 0px;
  padding: 0px 0px 0px 24px;

  a {
    font-size: 14px;
  }

  li {
    padding-bottom: 4px;
  }
`;

export const LinksWrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin: 24px 0px 27px;

  svg {
    margin-right: 4px;
    flex-shrink: 0;
  }
`;

export const Link = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.feedback.activeMedium};
    display: inline-flex;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    gap: 4px;
    line-height: ${theme.v2.font.lineHeight.text};

    a {
      font-size: ${theme.v2.font.sizes.xxs};
      line-height: ${theme.v2.font.lineHeight.text};
    }

    svg {
      margin-right: 4px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  button {
    max-height: 48px;
  }
`;
