import styled, { css } from 'styled-components';

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 16px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 16px 0px;
  `}
`;

export const SelectWrapper = styled.div`
  margin: 23px 0px 0px;
  max-width: 498px;
  padding: 0px 16px;
`;

export const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 32px 0px 16px;

  @media (max-width: 600px){
    margin: auto 0px 60px
  }
`;
