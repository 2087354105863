import React, { useContext } from 'react';
import {
  Modal, Button,
} from '@/pages/common';
import * as Styles from './OthersSiteTypesModal.styles';
import { IconArrowLeft } from '@/icons';
import { ThemeContext } from 'styled-components';
import { Input } from '@/pages/common/v1/inputs/index';

const OthersSiteTypesModal = ({
  handleOnCloseOthersModal, title, buttonBack, buttonSave, inputPlaceholder, othersEditFieldValue, handleOthersEditFieldValue, handleOnSaveOthersModal, inputMaxLength, maxWidth,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Modal testId="modal-others-site-types" onClose={() => handleOnCloseOthersModal('close')} disablePadding maxWidth={maxWidth}>
      <Styles.Title data-testid="other-site-types-title">
        {title}
      </Styles.Title>
      <Styles.InputContent>
        <Input testId="modal-others-site-types-input" maxLength={inputMaxLength} counter onChange={event => handleOthersEditFieldValue(event.target.value, inputMaxLength)} value={othersEditFieldValue} type="text" placeholder={inputPlaceholder} />
      </Styles.InputContent>
      <Styles.Action>
        <Styles.BackButtonWrapper>
          <Button
            testId="modal-others-site-types-back-button"
            variant="newTertiary"
            iconLeft={<IconArrowLeft color={theme.palette.secondary.primary} />}
            font="secondary"
            size="large"
            label={buttonBack}
            onClick={() => handleOnCloseOthersModal('back')}
          />
        </Styles.BackButtonWrapper>
        <Button onClick={handleOnSaveOthersModal} disabled={othersEditFieldValue.length < 2} testId="modal-others-site-types-save-button" font="secondary" variant="newPrimary" label={buttonSave} size="large" />
      </Styles.Action>
    </Modal>
  );
};

export default OthersSiteTypesModal;
