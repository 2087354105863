import shortid from 'shortid';
import { CALL_API } from '@/middleware/api';
import {
  PRO_EMAIL_ACTIVE_EMAIL_CPANEL,
  EMAILS_URL,
  HOSTING_TYPES,
  EMAIL_ACCOUNT_URL,
  EMAIL_HOST_DOMAIN,
  CREATE_EMAIL,
  PRO_EMAIL_LIST,
  PRO_EMAIL_AUTH_TOKEN,
  TITAN_PRODUCT_BY_GROUP,
  UPGRADE_SUMMARY_CREDITS,
  UPGRADE_MAIL_INVOICE,
  CHANGE_TITAN_PLAN,
  CHANGE_TITAN_PLAN_FAST_CHECKOUT,
  EXISTENT_INVOICE_VALIDATION_DOMAIN,
  EXISTENT_INVOICE_VALIDATION_HOSTING,
  CHECK_DOMAIN_AVAILABLE,
  PRO_EMAIL_HOSTING_PLANS,
  PRO_EMAIL_REACTIVATE_FREE_PLAN,
} from '@/config/api';
import { config, COUNTRY } from '@/config';
import { sortAlphabetic } from '@/utils/Application/sort';
import { ESSENTIALS_PLAN, TRIAL_PLAN, ULTRA_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import {
  REQUEST_EMAILS,
  RECEIVE_EMAILS,
  ERROR_EMAILS,
  REQUEST_HOSTING_TYPES,
  RECEIVE_HOSTING_TYPES,
  ERROR_HOSTING_TYPES,
  ABORT_HOSTING_TYPES,
  ABORT_EMAILS,
  FILTERED_EMAILS,
  REQUEST_DELETE_EMAILS,
  SUCCESS_DELETE_EMAILS,
  ERROR_DELETE_EMAILS,
  REQUEST_HOST_DOMAIN,
  RECEIVE_HOST_DOMAIN,
  ERROR_HOST_DOMAIN,
  ABORT_HOST_DOMAIN,
  REQUEST_CREATE_EMAIL,
  SUCCESS_CREATE_EMAIL,
  ERROR_CREATE_EMAIL,
  REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS,
  SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS,
  ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS,
  REQUEST_ALTER_PASSWORD_EMAILS,
  SUCCESS_ALTER_PASSWORD_EMAILS,
  ERROR_ALTER_PASSWORD_EMAILS,
  RECEIVE_PRO_EMAILS_LIST,
  REQUEST_GET_PRO_EMAILS_LIST,
  ERROR_GET_PRO_EMAILS_LIST,
  RECEIVE_PRO_EMAILS_AUTH,
  REQUEST_GET_PRO_EMAILS_AUTH,
  ERROR_GET_PRO_EMAILS_AUTH,
  REQUEST_GET_UPGRADE_SUMMARY,
  ERROR_GET_UPGRADE_SUMMARY,
  RECEIVE_GET_UPGRADE_SUMMARY,
  REQUEST_UPGRADE_MAIL_INVOICE,
  ERROR_UPGRADE_MAIL_INVOICE,
  RECEIVE_EXISTENT_INVOICE_VALIDATION,
  REQUEST_EXISTENT_INVOICE_VALIDATION,
  ERROR_EXISTENT_INVOICE_VALIDATION,
  REQUEST_GET_CHECK_DOMAIN_AVAILABLE,
  ERROR_GET_CHECK_DOMAIN_AVAILABLE,
  RECEIVE_GET_CHECK_DOMAIN_AVAILABLE,
  REQUEST_GET_PRO_EMAIL_HOSTING_PLANS,
  ERROR_GET_PRO_EMAIL_HOSTING_PLANS,
  RECEIVE_GET_PRO_EMAIL_HOSTING_PLANS,
  REQUEST_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN,
  ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN,
  RECEIVE_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN,
  RECEIVE_GET_ACTIVE_EMAIL_CPANEL,
  REQUEST_GET_ACTIVE_EMAIL_CPANEL,
  ERROR_GET_ACTIVE_EMAIL_CPANEL,
  REQUEST_GET_PRO_EMAIL_UPGRADE_PLANS,
  ERROR_GET_PRO_EMAIL_UPGRADE_PLANS,
  RECEIVE_PRO_EMAIL_UPGRADE_PLANS,
  REQUEST_CHECK_EMAILS_UPDATE,
  ERROR_CHECK_EMAILS_UPDATE,
  RECEIVE_UPGRADE_TITAN,
} from './actionsTypes';

const getProEmailKey = (planId) => {
  const essentialInboxID = 1;
  const premiumInboxID = COUNTRY === 'br' ? 7 : 3;
  const ultraInboxID = COUNTRY === 'br' ? 17 : 5;

  if (planId === ESSENTIALS_PLAN || planId === TRIAL_PLAN) {
    return essentialInboxID;
  }
  if (planId === ULTRA_PLAN) {
    return ultraInboxID;
  }

  return premiumInboxID;
};

const receiveCreateEmails = (response, data, hostingId) => (
  dispatch,
  getState,
) => {
  if (response.data.success) {
    const { list } = getState().emails;
    const newEmail = {
      email: `${data.user}@${data.domain}`,
      diskQuota: data.quota,
      diskUnlimited: data.unlimited,
      diskUsed: 0,
      domain: data.domain,
      hostingId,
      user: data.user,
      id: shortid.generate(),
    };

    list.push(newEmail);
    const sortEmails = sortAlphabetic(list, 'email');

    return dispatch({
      type: SUCCESS_CREATE_EMAIL,
      data: response,
      emailsList: sortEmails,
      total: sortEmails.length,
      loadedDate: Date.now(),
    });
  }
  return dispatch({
    type: ERROR_CREATE_EMAIL,
    data: response,
  });
};

export const createEmailAccount = (data, hostingId) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: CREATE_EMAIL(hostingId),
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_CREATE_EMAIL }),
      success: response => receiveCreateEmails(response, data, hostingId),
      error: () => ({ type: ERROR_CREATE_EMAIL }),
    },
  },
});

const receiveEmails = (emails) => {
  const sortEmails = sortAlphabetic(emails, 'email');
  return {
    type: RECEIVE_EMAILS,
    emails: sortEmails.map(item => ({
      ...item,
      id: shortid.generate(),
    })),
    total: emails.length,
    loadedDate: Date.now(),
  };
};

export const loadEmails = () => (dispatch, getState) => {
  const { loadedDate } = getState().emails;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_EMAILS }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: EMAILS_URL,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_EMAILS }),
        success: response => receiveEmails(response.data),
        error: () => ({ type: ERROR_EMAILS }),
      },
    },
  });
};

const receiveHostDomain = (hostDomain) => {
  const hostDomainWithId = hostDomain.map(item => ({
    ...item,
    id: shortid.generate(),
  }));
  const primaryDomain = hostDomainWithId.slice(0, 1)[0] || [];
  return {
    type: RECEIVE_HOST_DOMAIN,
    hostDomain: hostDomainWithId,
    loadedDate: Date.now(),
    primaryDomain,
  };
};

export const loadHostDomain = () => (dispatch, getState) => {
  const { loadedDate } = getState().emails.hostDomain;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_HOST_DOMAIN }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: EMAIL_HOST_DOMAIN,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_HOST_DOMAIN }),
        success: response => receiveHostDomain(response.data),
        error: () => ({ type: ERROR_HOST_DOMAIN }),
      },
    },
  });
};

const receiveHostingTypes = hostings => ({
  type: RECEIVE_HOSTING_TYPES,
  hostings,
  loadedDate: Date.now(),
});

export const loadHostingTypes = () => (dispatch, getState) => {
  const { loadedDate } = getState().emails.hostings;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_HOSTING_TYPES }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: HOSTING_TYPES,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_HOSTING_TYPES }),
        success: response => receiveHostingTypes(response.data),
        error: () => ({ type: ERROR_HOSTING_TYPES }),
      },
    },
  });
};

export const filterEmailsAddress = email => (dispatch, getState) => {
  const allEmails = getState().emails.bkp;
  let filteredEmails = allEmails;

  if (email !== '') {
    filteredEmails = allEmails.filter(item => item.email.toString().includes(email));
  }

  return dispatch({
    type: FILTERED_EMAILS,
    data: filteredEmails,
  });
};

const successDeleteEmailAccount = (hostingId, emailAddress, emailsList) => {
  const emailIndex = emailsList.findIndex(
    item => item.hostingId === hostingId && item.email === emailAddress,
  );
  emailsList.splice(emailIndex, 1);

  return {
    type: SUCCESS_DELETE_EMAILS,
    data: emailsList,
    total: emailsList.length,
  };
};

export const deleteEmailAccount = (hostingId, emailAddress) => (
  dispatch,
  getState,
) => {
  const emailsList = getState().emails.bkp;
  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: EMAIL_ACCOUNT_URL(hostingId, emailAddress),
      method: 'DELETE',
      actionTypes: {
        request: () => ({ type: REQUEST_DELETE_EMAILS }),
        success: () => successDeleteEmailAccount(hostingId, emailAddress, emailsList),
        error: () => ({ type: ERROR_DELETE_EMAILS }),
      },
    },
  });
};

export const alterEmailPassword = (hostingId, alterPasswordData) => ({
  [CALL_API]: {
    authenticated: true,
    body: alterPasswordData.data,
    endpoint: EMAIL_ACCOUNT_URL(hostingId, alterPasswordData.emailAddress),
    method: 'PUT',
    actionTypes: {
      request: () => ({ type: REQUEST_ALTER_PASSWORD_EMAILS }),
      success: () => ({ type: SUCCESS_ALTER_PASSWORD_EMAILS }),
      error: () => ({ type: ERROR_ALTER_PASSWORD_EMAILS }),
    },
  },
});
const successChangeStorageEmailAccount = (
  storageOptions,
  emailsListReceive,
  hostingId,
  emailAddress,
) => {
  const emailsList = emailsListReceive;
  const emailIndex = emailsList.findIndex(
    item => item.hostingId === hostingId && item.email === emailAddress,
  );
  emailsList[emailIndex].diskQuota = storageOptions.quota;
  emailsList[emailIndex].diskUnlimited = storageOptions.unlimited;

  return {
    type: SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS,
    list: emailsList,
  };
};

export const changeStorageEmailAccount = (
  hostingId,
  emailAddress,
  storageOptions,
) => (dispatch, getState) => {
  const emailsList = getState().emails.bkp;
  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: EMAIL_ACCOUNT_URL(hostingId, emailAddress),
      method: 'PUT',
      body: storageOptions,
      actionTypes: {
        request: () => ({ type: REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS }),
        success: () => successChangeStorageEmailAccount(
          storageOptions,
          emailsList,
          hostingId,
          emailAddress,
        ),
        error: () => ({ type: ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS }),
      },
    },
  });
};

const receiveProEmailHostingPlans = response => ({
  type: RECEIVE_GET_PRO_EMAIL_HOSTING_PLANS,
  data: response.data,
});

export const requestProEmailHostingPlans = domainName => async dispatch => dispatch({
  [CALL_API]: {
    endpoint: PRO_EMAIL_HOSTING_PLANS(domainName),
    method: 'GET',
    authorizationType: 'aws',
    actionTypes: {
      request: () => ({ type: REQUEST_GET_PRO_EMAIL_HOSTING_PLANS }),
      success: response => receiveProEmailHostingPlans(response),
      error: () => ({ type: ERROR_GET_PRO_EMAIL_HOSTING_PLANS }),
    },
  },
});

const receiveReactivateProEmailFreePlan = response => ({
  type: RECEIVE_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN,
  data: response.data,
});

const receiveReactivateProEmailFreePlanERR = response => ({
  type: ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN,
  data: response.data,
});

export const reactivateProEmailFreePlan = (hostingId, domainId) => (
  dispatch,
  getState,
) => {
  const { summary } = getState();
  const url = PRO_EMAIL_REACTIVATE_FREE_PLAN(domainId);

  return dispatch({
    [CALL_API]: {
      endpoint: url,
      method: 'PUT',
      body: { hosting_id: hostingId },
      authorizationType: 'jwt',
      customHeaders: {
        'X-Brand': COUNTRY,
        'X-Client-ID': summary.id,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN }),
        success: response => receiveReactivateProEmailFreePlan(response),
        error: response => receiveReactivateProEmailFreePlanERR(response),
      },
    },
  });
};

const receiveCheckAvailableDomain = response => ({
  type: RECEIVE_GET_CHECK_DOMAIN_AVAILABLE,
  data: response.data,
});

export const requestCheckDomainAvailable = domain => async dispatch => dispatch({
  [CALL_API]: {
    endpoint: CHECK_DOMAIN_AVAILABLE(domain),
    method: 'GET',
    authorizationType: 'aws',
    actionTypes: {
      request: () => ({ type: REQUEST_GET_CHECK_DOMAIN_AVAILABLE }),
      success: response => receiveCheckAvailableDomain(response),
      error: () => ({ type: ERROR_GET_CHECK_DOMAIN_AVAILABLE }),
    },
  },
});

const receiveActiveEmailCPanel = response => ({
  type: RECEIVE_GET_ACTIVE_EMAIL_CPANEL,
  data: response,
});

export const requestReceiveActiveEmailCPanel = (idProEmail, type) => async (
  dispatch,
  getState,
) => {
  const { summary } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: PRO_EMAIL_ACTIVE_EMAIL_CPANEL(idProEmail),
      method: 'PUT',
      authorizationType: 'jwt',
      body: {
        setup_type: type,
      },
      customHeaders: {
        'X-Brand': COUNTRY,
        'X-Client-ID': summary.id,
      },

      actionTypes: {
        request: () => ({ type: REQUEST_GET_ACTIVE_EMAIL_CPANEL }),
        success: response => receiveActiveEmailCPanel(response),
        error: () => ({ type: ERROR_GET_ACTIVE_EMAIL_CPANEL }),
      },
    },
  });
};

export const receiveGetEmailList = (response) => {
  const eligibleDomainOptions = response.data.filter(item => item.status.toLowerCase() === 'active' && !item.configured);
  const eligibleForTitanUpgrade = eligibleDomainOptions.length > 0;

  return ({
    type: RECEIVE_PRO_EMAILS_LIST,
    data: response.data,
    eligibleDomainOptions,
    eligibleForTitanUpgrade,
  });
};

export const receiveProEmailAuth = data => ({
  type: RECEIVE_PRO_EMAILS_AUTH,
  data,
});

export const requestGetProEmailList = () => async dispatch => dispatch({
  [CALL_API]: {
    endpoint: PRO_EMAIL_LIST,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_GET_PRO_EMAILS_LIST }),
      success: response => receiveGetEmailList(response),
      error: () => ({ type: ERROR_GET_PRO_EMAILS_LIST }),
    },
  },
});

const checkProEmails = response => (dispatch, getState) => {
  const { proEmailList } = getState().emails;
  const { data: newProEmailList } = response;
  const hasChanges = JSON.stringify(proEmailList) !== JSON.stringify(newProEmailList);
  hasChanges && dispatch(receiveGetEmailList(response));
};

export const checkProEmailUpdate = () => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRO_EMAIL_LIST,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_CHECK_EMAILS_UPDATE }),
      success: response => checkProEmails(response),
      error: () => ({ type: ERROR_CHECK_EMAILS_UPDATE }),
    },
  },
});

export const proEmailAuth = planId => async (dispatch, getState) => {
  const { summary } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: PRO_EMAIL_AUTH_TOKEN(planId),
      method: 'GET',
      authorizationType: 'jwt',
      customHeaders: {
        'X-Brand': COUNTRY,
        'X-Client-ID': summary.id,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_GET_PRO_EMAILS_AUTH }),
        success: response => receiveProEmailAuth(response),
        error: () => ({ type: ERROR_GET_PRO_EMAILS_AUTH }),
      },
    },
  });
};

export const receiveGetProEmailPlans = response => ({
  type: RECEIVE_PRO_EMAIL_UPGRADE_PLANS,
  data: response.data,
});

export const requestUpgradePlans = () => async dispatch => dispatch({
  [CALL_API]: {
    endpoint: TITAN_PRODUCT_BY_GROUP(COUNTRY === 'br' ? 28 : 16),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_GET_PRO_EMAIL_UPGRADE_PLANS }),
      success: response => receiveGetProEmailPlans(response),
      error: () => ({ type: ERROR_GET_PRO_EMAIL_UPGRADE_PLANS }),
    },
  },
});

export const receiveUpgradeSummary = data => ({
  type: RECEIVE_GET_UPGRADE_SUMMARY,
  data,
});

export const getUpgradeSummary = ({
  inboxAmount,
  hostingId,
  planId,
  newProductId,
  paymentCycle,
}) => async (dispatch) => {
  let key = null;
  key = getProEmailKey(newProductId || planId);

  return dispatch({
    [CALL_API]: {
      endpoint: UPGRADE_SUMMARY_CREDITS,
      method: 'POST',
      body: {
        new_billing_cycle: paymentCycle,
        hosting_id: hostingId,
        new_product_id: newProductId || null,
        config_options: {
          [key]: inboxAmount,
        },
      },
      actionTypes: {
        request: () => ({ type: REQUEST_GET_UPGRADE_SUMMARY }),
        success: response => receiveUpgradeSummary(response),
        error: () => ({ type: ERROR_GET_UPGRADE_SUMMARY }),
      },
    },
  });
};

export const receiveUpgradeInvoice = data => ({
  type: RECEIVE_UPGRADE_TITAN,
  data,
});

export const upgradeMailGetInvoice = ({
  inboxAmount,
  hostingId,
  planId,
  newProductId,
  paymentCycle,
  offerContext = [],
}) => async (dispatch) => {
  let key = null;
  key = getProEmailKey(newProductId || planId);

  return dispatch({
    [CALL_API]: {
      endpoint: UPGRADE_MAIL_INVOICE,
      method: 'POST',
      body: {
        hosting_id: hostingId,
        new_product_id: newProductId || null,
        config_options: newProductId ? null : { [key]: inboxAmount },
        new_billing_cycle: paymentCycle,
        offer_contexts: offerContext,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_UPGRADE_MAIL_INVOICE }),
        success: response => receiveUpgradeInvoice(response),
        error: () => ({ type: ERROR_UPGRADE_MAIL_INVOICE }),
      },
    },
  });
};

export const changePlan = ({
  planId,
  domain,
  cycle,
  paymentMethod,
  inboxAmount,
  hostingId,
  isFree,
  offerContext = [],
}) => async (dispatch) => {
  const endpointUrl = isFree
    ? CHANGE_TITAN_PLAN_FAST_CHECKOUT
    : CHANGE_TITAN_PLAN;

  const key = getProEmailKey(planId);

  const bodyObject = isFree
    ? {
      products: [
        {
          id: planId,
          type: 'product',
          domain,
          quantity: 1,
          cycle,
          configoptions: {
            [key]: inboxAmount,
          },
        },
      ],
      payment_method: paymentMethod,
      offer_contexts: offerContext,
    }
    : {
      hosting_id: hostingId,
      new_product_id: planId,
      offer_contexts: offerContext,
      new_billing_cycle: cycle,
      config_options: {
        [key]: inboxAmount,
      },
    };

  return dispatch({
    [CALL_API]: {
      endpoint: endpointUrl,
      method: 'POST',
      body: bodyObject,
      actionTypes: {
        request: () => ({ type: REQUEST_UPGRADE_MAIL_INVOICE }),
        success: response => receiveUpgradeInvoice({ response, paymentMethod, domain }),
        error: () => ({ type: ERROR_UPGRADE_MAIL_INVOICE }),
      },
    },
  });
};

export const receiveUnpaidUpgradeInvoice = data => ({
  type: RECEIVE_EXISTENT_INVOICE_VALIDATION,
  data,
});

export const getUnpaidUpgradeInvoice = ({
  domain,
  hostingId,
  isFree,
}) => async dispatch => dispatch({
  [CALL_API]: {
    endpoint: isFree
      ? EXISTENT_INVOICE_VALIDATION_DOMAIN(domain)
      : EXISTENT_INVOICE_VALIDATION_HOSTING(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_EXISTENT_INVOICE_VALIDATION }),
      success: response => receiveUnpaidUpgradeInvoice(response),
      error: () => ({ type: ERROR_EXISTENT_INVOICE_VALIDATION }),
    },
  },
});
