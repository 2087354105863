import titleBR from './title.br';
import titleMX from './title.mx';
import titleCL from './title.cl';
import titleCO from './title.co';

const title = {
  BR: titleBR,
  MX: titleMX,
  CL: titleCL,
  CO: titleCO,
};

export default title;
