import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 32px;

    @media (max-width: ${theme.v1.breakpoints.md}}) {
      align-items: center;
      flex-direction: column;
      padding: 28px;
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      padding: 24px 32px 32px 32px;
    }

  `}
`;

export const ContentProduct = styled.div`
${({ theme }) => css`
  background: ${theme.v2.colors.white.primary};
  box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.20);
  display: flex;
  min-height: 40px;
  margin: 1px 0px 0px 0px;
  text-align: left;
  padding: 8px 16px 8px 16px;

  @media (max-width: ${theme.v2.breakpoints.sm}){
    display: block;
    width: 100%;

    >div:last-child {
      margin: 8px 8px 8px 8px;
       };
  }
`}
`;

export const Selection = styled.div`
    display: flex;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  >button {
    > label {
      height: 18px;
      width: 18px;
    }
  };
`;

export const ProductName = styled.div`
${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 14px;
  font-weight: ${theme.v2.font.weights.regular};
  margin-left: 4px;

  @media (max-width: ${theme.v2.breakpoints.sm}){
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`}
`;


export const ProductValue = styled.div`
${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  display: block;
  font-family: ${theme.v2.font.family.primary};
  font-size: 14px;
  font-weight: ${theme.v2.font.weights.regular};
  margin-left: auto;
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  width: 100%;
  @media (max-width: ${theme.v1.breakpoints.sm}) {
    margin: 0px 0px 50px 0px;
     }
  `}
`;

export const TitleModal = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    padding: 0;
  @media (max-width: ${theme.v2.breakpoints.sm}){
    position: absolute;
    left: 20px;
    }

  `}
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
`;

export const ControlButton = styled.div`
${({ theme }) => css`
    display: grid;
    gap: 16px;
    margin: 32px 0px 0px 0px;
    ${Button}:not(:first-child) {
      margin-left: 12px;
    }
    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:grid;
    }
`}
`;

export const InfoIcon = styled.span`
  ${({ theme }) => css`
     & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: inline-flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 21.6px;
  `}
`;


export const ContentData = styled.div`
${({ theme }) => css`
    flex-direction: column;
    text-align:center;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:block;
    }
  `}
`;
export const ContentIcon = styled.div`
${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 24px;

    svg {
      fill: ${theme.v2.colors.brand.primaryMedium};
    }
    `}
`;


export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    padding: 0px 0px 24px 0px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 32px 0px 0px 0px;
      text-align: initial;

    }
  `}
`;

export const Products = styled.div`
align - items: center;
boreder: 1px solid red;

`;
export const ContentInfo = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.v2.colors.brand.primaryUltraLight};
  display: flex;
  height: auto;
  margin: 32px 0px 32px 0px;
  padding: 16px 16px 16px 16px;
  text-align: left;

  @media (max-width: ${theme.v2.breakpoints.sm}){

    margin-top: 78px;
    padding: 16px;
    text-align: left;
    width: 100%;
  }

`}
`;

export const DescriptionInfo = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;
    padding: 0px 0px 0px 8px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      text-align: left;
      width: 100%;
    }
    span {
      font-weight: ${theme.v2.font.weights.bold};
    }
    a {
      font-size: 14px;
    }
  `}
`;
