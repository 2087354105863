import { COUNTRY } from '@/config';

const SUPPORT_URLS_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419',
  co: 'https://soporte-latam.hostgator.com/hc/es-419',
};

const SUPPORT_ARTICLES_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/',
};

const SUPPORT_ARTICLE_ZONEMANAGER_CPANEL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389554',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721158',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721158',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721158',
};

const SUPPORT_ARTICLE_ZONEMANAGER_WHM_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360054179034',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678232',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678232',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678232',
};

const SUPPORT_ARTICLE_DNS_THROUGH_WHM_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360056256934',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360052091152',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360052091152',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360052091152',
};

const SUPPORT_ARTICLE_DNS_REGISTROBR_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360056449953',
};

const SUPPORT_ARTICLE_DNS_UOLHOST_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360054626714',
};

const SUPPORT_ARTICLE_DNS_GODADDY_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360056450093',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858232',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858232',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858232',
};

const SUPPORT_ARTICLE_DNS_LOCAWEB_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360056450213',
};

const SUPPORT_ARTICLE_DNS_KINGHOST_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360054626834',
};

const SUPPORT_ARTICLE_DNS_WEBEMPRESA_BY_BRAND = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858332',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858332',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858332',
};

const SUPPORT_ARTICLE_DNS_COLOMBIAHOSTING_BY_BRAND = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360050315211',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360050315211',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360050315211',
};

const SUPPORT_ARTICLE_DNS_NEUBOX_BY_BRAND = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858512',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858512',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049858512',
};

const SUPPORT_ARTICLE_KNOW_MORE_DNS_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360019596774',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678152',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678152',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049678152',
};

const SUPPORT_ARTICLE_KNOW_MORE_DNS_POINTER_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360054298934',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049748272',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049748272',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360049748272',
};

const RESELLER_PRICE_UPDATE_EXPLANATION_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/1260800338969',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360053346131',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360053346131',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360053346131',
};

const GET_A_NEW_PLAN_BY_BRAND = {
  br: 'http://hostgator.com.br/email-profissional',
  mx: 'http://hostgator.mx/correo-profesional',
  cl: 'https://www.hostgator.cl/correo-profesional',
  co: 'https://www.hostgator.co/correo-profesional',
};

const TITAN_FAQ_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/categories/1260801368689',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/categories/360005756052',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/categories/360005756052',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/categories/360005756052',
};

const CHAT_SUPPORT_URL_BY_BRAND = {
  br: 'https://financeiro.hostgator.com.br/chat/?country=br&department=technical#',
  mx: 'https://billing.hostgator.mx/chat/?country=mx&department=technical#',
  cl: 'https://billing.hostgator.cl/chat/?country=cl&department=technical#',
  co: 'https://billing.hostgator.co/chat/?country=co&department=technical#',
};

const GET_A_NEW_DOMAIN_BY_BRAND = {
  br: 'https://www.hostgator.com.br/registro-de-dominio',
  mx: 'https://www.hostgator.mx/dominios',
  cl: 'https://www.hostgator.cl/dominios',
  co: 'https://www.hostgator.co/dominios',
};

const SERVICE_TERMS_BY_BRAND = {
  br: 'https://www.hostgator.com.br/termos-de-servico',
  mx: 'https://www.hostgator.mx/terminos',
  cl: 'https://www.hostgator.cl/terminos',
  co: 'https://www.hostgator.co/terminos',
};

const GET_ANALYZER_LINK_BY_BRAND = {
  br: 'analise@hostgator.com.br',
  mx: 'https://soporte-latam.hostgator.com',
  cl: 'https://soporte-latam.hostgator.com',
  co: 'https://soporte-latam.hostgator.com',
};

const CHANGE_REGISTROBR_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389114',
};

const CHANGE_GODADDY_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037642314',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037746791',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037746791',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037746791',
};

const CHANGE_LOCAWEB_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037640614',
};

const CHANGE_UOLHOST_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037642134',
};

const CHANGE_KINGHOST_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037644234',
};

const CHANGE_OTHERS_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000497973#registrado-em-outro-provedor',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957-C%C3%B3mo',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957-C%C3%B3mo',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957-C%C3%B3mo',
};

const CHANGE_WEBEMPRESA_DNS = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037758891',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037758891',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037758891',
};

const CHANGE_COLOMBIAHOSTING_DNS = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037389492',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037389492',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037389492',
};

const CHANGE_NEUBOX_DNS = {
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037755071',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037755071',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037755071',
};

const GODADDY_PAGE = {
  br: 'https://www.godaddy.com/',
  mx: 'https://www.godaddy.com/',
  cl: 'https://www.godaddy.com/',
  co: 'https://www.godaddy.com/',
};

const REGISTROBR_PAGE = {
  br: 'https://registro.br/',
};

const LOCAWEB_PAGE = {
  br: 'https://www.locaweb.com.br/',
};

const UOLHOST_PAGE = {
  br: 'https://uolhost.uol.com.br/',
};

const KINGHOST_PAGE = {
  br: 'https://king.host/',
};

const WEBEMPRESA_PAGE = {
  mx: 'https://www.webempresa.com/',
  cl: 'https://www.webempresa.com/',
  co: 'https://www.webempresa.com/',
};

const COLOMBIAHOSTING_PAGE = {
  mx: 'https://www.colombiahosting.com.co/',
  cl: 'https://www.colombiahosting.com.co/',
  co: 'https://www.colombiahosting.com.co/',
};

const NEUBOX_PAGE = {
  mx: 'https://neubox.com/',
  cl: 'https://neubox.com/',
  co: 'https://neubox.com/',
};


const EXTERNAL_PLATFORM_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/7499808508699',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7527058595995',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7527058595995',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7527058595995',
};

const PERSONAL_DNS_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389294',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7511270022939',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7511270022939',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7511270022939',
};

const DEFAULT_SERVER_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/7498793589147',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7513054970395',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7513054970395',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7513054970395',
};

const BACKUP_INFO_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173-Como-fazer-um-backup-completo',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691',
};

const WOOCOMMERCE_ARTICLE_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/6405989020571-O-que-%C3%A9-WooCommerce-',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7067232595995',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7067232595995',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/7067232595995',
};

const GMAIL_CREATE_ACCOUNT_BY_BRAND = {
  br: 'https://support.google.com/mail/answer/56256?hl=pt-BR',
  cl: 'https://support.google.com/mail/answer/56256?hl=es',
  co: 'https://support.google.com/mail/answer/56256?hl=es',
  mx: 'https://support.google.com/mail/answer/56256?hl=es',
};

const FIRST_STEP_ASAAS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091634807323',
};

const ASAAS_INTEGRATION = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091716998171',
};

const HOW_ACCESS_ASAAS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091679825563',
};

const ASAAS_TAX = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091657649947',
};

const ASAAS_HELP_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091680144283-O-que-%C3%A9-Asaas-',
};

const QUESTIONS_ABOUT_ASAAS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10091660667163',
};

const MORE_CONTENT_ASAAS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/sections/10091537440667',
};

const ASAAS_LOGIN = {
  br: 'https://www.asaas.com/login/',
};

const DNS_PROPAGATION = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693-O-que-%C3%A9-propaga%C3%A7%C3%A3o-#propaga%C3%A7%C3%A3o',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
};

const FIND_PLAN_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115004894674',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024790012',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024790012',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024790012',
};

const CLIENT_AREA_FEATURES = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360015388394',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360025090791',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360025090791',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360025090791',
};

const CONFIGURE_DOMAIN_DNS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000497973',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957--C%C3%B3mo-cambiar-el-DNS-',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957--C%C3%B3mo-cambiar-el-DNS-',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957--C%C3%B3mo-cambiar-el-DNS-',
};

const ADD_DOMAIN_TO_HOST = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389314',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217618308',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217618308',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217618308',
};

const CREATE_WORDPRESS_SITE = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001935813',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
};

const ACCESS_CPANEL = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000181933',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360021861252--C%C3%B3mo-acceder-al-cPanel-',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360021861252--C%C3%B3mo-acceder-al-cPanel-',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360021861252--C%C3%B3mo-acceder-al-cPanel-',
};

const BLOG = {
  br: 'https://www.hostgator.com.br/blog/',
  cl: 'https://www.hostgator.mx/blog/',
  co: 'https://www.hostgator.mx/blog/',
  mx: 'https://www.hostgator.mx/blog/',
};

const YOUTUBE = {
  br: 'https://www.youtube.com/HostGatorBRTV',
  cl: 'https://www.youtube.com/@HostGatorMexico',
  co: 'https://www.youtube.com/@HostGatorMexico',
  mx: 'https://www.youtube.com/@HostGatorMexico',
};

const HELP_CENTER = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419',
  co: 'https://soporte-latam.hostgator.com/hc/es-419',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419',
};


const DO_YOUR_ON_BACKUP_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/1260804497929',
};
export const DO_YOUR_ON_BACKUP = DO_YOUR_ON_BACKUP_BY_BRAND[COUNTRY];

export const GMAIL_CREATE_ACCOUNT = GMAIL_CREATE_ACCOUNT_BY_BRAND[COUNTRY];

export const SUPPORT_URL = SUPPORT_URLS_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLES = SUPPORT_ARTICLES_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_ZONEMANAGER_CPANEL = SUPPORT_ARTICLE_ZONEMANAGER_CPANEL_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_ZONEMANAGER_WHM = SUPPORT_ARTICLE_ZONEMANAGER_WHM_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_THROUGH_WHM = SUPPORT_ARTICLE_DNS_THROUGH_WHM_BY_BRAND[COUNTRY];
export const CHAT_SUPPORT_URL = CHAT_SUPPORT_URL_BY_BRAND[COUNTRY];

export const EXTERNAL_PLATFORM = EXTERNAL_PLATFORM_BY_BRAND[COUNTRY];
export const PERSONAL_DNS = PERSONAL_DNS_BY_BRAND[COUNTRY];
export const DEFAULT_SERVER = DEFAULT_SERVER_BY_BRAND[COUNTRY];

export const SUPPORT_ARTICLE_DNS_REGISTROBR = SUPPORT_ARTICLE_DNS_REGISTROBR_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_UOLHOST = SUPPORT_ARTICLE_DNS_UOLHOST_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_GODADDY = SUPPORT_ARTICLE_DNS_GODADDY_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_LOCAWEB = SUPPORT_ARTICLE_DNS_LOCAWEB_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_KINGHOST = SUPPORT_ARTICLE_DNS_KINGHOST_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_WEBEMPRESA = SUPPORT_ARTICLE_DNS_WEBEMPRESA_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_COLOMBIAHOSTING = SUPPORT_ARTICLE_DNS_COLOMBIAHOSTING_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_DNS_NEUBOX = SUPPORT_ARTICLE_DNS_NEUBOX_BY_BRAND[COUNTRY];

export const SUPPORT_ARTICLE_KNOW_MORE_DNS = SUPPORT_ARTICLE_KNOW_MORE_DNS_BY_BRAND[COUNTRY];
export const SUPPORT_ARTICLE_KNOW_MORE_DNS_POINTER = SUPPORT_ARTICLE_KNOW_MORE_DNS_POINTER_BY_BRAND[COUNTRY];
export const RESELLER_PRICE_UPDATE_EXPLANATION = RESELLER_PRICE_UPDATE_EXPLANATION_BY_BRAND[COUNTRY];
export const GET_A_NEW_PLAN = GET_A_NEW_PLAN_BY_BRAND[COUNTRY];
export const TITAN_FAQ = TITAN_FAQ_BY_BRAND[COUNTRY];
export const GET_A_NEW_DOMAIN = GET_A_NEW_DOMAIN_BY_BRAND[COUNTRY];
export const GET_ANALYZER_LINK = GET_ANALYZER_LINK_BY_BRAND[COUNTRY];

export const SERVICE_TERMS = SERVICE_TERMS_BY_BRAND[COUNTRY];

export const SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR = CHANGE_REGISTROBR_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_GODADDY = CHANGE_GODADDY_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB = CHANGE_LOCAWEB_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST = CHANGE_UOLHOST_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST = CHANGE_KINGHOST_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_OTHERS = CHANGE_OTHERS_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA = CHANGE_WEBEMPRESA_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING = CHANGE_COLOMBIAHOSTING_DNS[COUNTRY];
export const SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX = CHANGE_NEUBOX_DNS[COUNTRY];
export const GODADDY_HOMEPAGE = GODADDY_PAGE[COUNTRY];
export const REGISTROBR_HOMEPAGE = REGISTROBR_PAGE[COUNTRY];
export const LOCAWEB_HOMEPAGE = LOCAWEB_PAGE[COUNTRY];
export const UOLHOST_HOMEPAGE = UOLHOST_PAGE[COUNTRY];
export const KINGHOST_HOMEPAGE = KINGHOST_PAGE[COUNTRY];
export const WEBEMPRESA_HOMEPAGE = WEBEMPRESA_PAGE[COUNTRY];
export const COLOMBIAHOSTING_HOMEPAGE = COLOMBIAHOSTING_PAGE[COUNTRY];
export const NEUBOX_HOMEPAGE = NEUBOX_PAGE[COUNTRY];

export const BACKUP_INFO = BACKUP_INFO_BY_BRAND[COUNTRY];
export const WOOCOMMERCE_ARTICLE = WOOCOMMERCE_ARTICLE_BY_BRAND[COUNTRY];
export const ASAAS_INTEGRATION_ARTICLE = ASAAS_INTEGRATION[COUNTRY];
export const HOW_ACCESS_ASAAS_ARTICLE = HOW_ACCESS_ASAAS[COUNTRY];
export const FIRST_STEP_ASAAS_ARTICLE = FIRST_STEP_ASAAS[COUNTRY];
export const ASAAS_TAX_ARTICLE = ASAAS_TAX[COUNTRY];
export const QUESTIONS_ABOUT_ASAAS_ARTICLE = QUESTIONS_ABOUT_ASAAS[COUNTRY];
export const MORE_CONTENT_ASAAS_ARTICLE = MORE_CONTENT_ASAAS[COUNTRY];
export const ASAAS_LOGIN_LINK = ASAAS_LOGIN[COUNTRY];
export const ASAAS_HELP = ASAAS_HELP_BY_BRAND[COUNTRY];

export const DNS_PROPAGATION_ARTICLE = DNS_PROPAGATION[COUNTRY];
export const FIND_PLAN_DNS_ARTICLE = FIND_PLAN_DNS[COUNTRY];
export const CLIENT_AREA_FEATURES_ARTICLE = CLIENT_AREA_FEATURES[COUNTRY];
export const CONFIGURE_DOMAIN_DNS_ARTICLE = CONFIGURE_DOMAIN_DNS[COUNTRY];
export const ADD_DOMAIN_TO_HOST_ARTICLE = ADD_DOMAIN_TO_HOST[COUNTRY];
export const CREATE_WORDPRESS_SITE_ARTICLE = CREATE_WORDPRESS_SITE[COUNTRY];
export const ACCESS_CPANEL_ARTICLE = ACCESS_CPANEL[COUNTRY];

export const BLOG_URL = BLOG[COUNTRY];
export const YOUTUBE_URL = YOUTUBE[COUNTRY];
export const HELP_CENTER_URL = HELP_CENTER[COUNTRY];
