import React from 'react';
import * as Style from './IconCreditCard.style';

const IconCreditCard = ({
  color = '#2E578B',
  testId = 'icon-credit-card',
  size = 32,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="local_icon_cartao_credito" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color}>
      <g id="icon" transform="translate(-0.45 0.05)">
        <path id="Caminho_247517" data-name="Caminho 247517" d="M28.844,14.8H27.91l1.935-1.935a2.337,2.337,0,0,0,0-3.337L20.969.651a2.337,2.337,0,0,0-3.337,0L3.151,15.2a2.337,2.337,0,0,0,0,3.337l2.8,2.8v8.275A2.321,2.321,0,0,0,8.289,31.95H28.844a2.321,2.321,0,0,0,2.336-2.336V17.134A2.28,2.28,0,0,0,28.844,14.8Zm-.133-4.2a.768.768,0,0,1,0,1.135l-3.07,3.07H21.9l5.472-5.472ZM5.954,17.134v2L4.218,17.4a.768.768,0,0,1,0-1.135L18.767,1.785a.768.768,0,0,1,1.135,0L25.24,7.124,17.632,14.8h-4.2l.667-.667-.667-.667L12.093,14.8H10.158l2.336-2.336-.667-.667-3,3H8.356A2.379,2.379,0,0,0,5.954,17.134ZM29.645,29.681a.771.771,0,0,1-.8.8H8.356a.771.771,0,0,1-.8-.8V27.812H29.645v1.869Zm0-4.938H7.555V17.134a.771.771,0,0,1,.8-.8H28.844a.771.771,0,0,1,.8.8Z" fill="#2e578b" />
        <path id="Caminho_247518" data-name="Caminho 247518" d="M36.371,26.4a1.57,1.57,0,0,0-1.135.4A1.8,1.8,0,1,0,34.1,30a1.57,1.57,0,0,0,1.135-.4,1.8,1.8,0,0,0,2.936-1.4A1.838,1.838,0,0,0,36.371,26.4Z" transform="translate(-9.929 -8.798)" fill="#2e578b" />
        <rect id="Retângulo_15618" data-name="Retângulo 15618" width="7.808" height="0.934" transform="translate(9.023 18.536)" />
        <rect id="Retângulo_15619" data-name="Retângulo 15619" width="7.808" height="0.934" transform="translate(9.023 20.805)" />
        <path id="Caminho_247519" data-name="Caminho 247519" d="M25.528,10.6a1.99,1.99,0,0,0,.534-1.135A1.99,1.99,0,0,0,27.2,8.928a1.84,1.84,0,0,0-2.6-2.6A1.99,1.99,0,0,0,24.06,7.46a1.99,1.99,0,0,0-1.135.534,1.813,1.813,0,0,0,0,2.6A1.942,1.942,0,0,0,25.528,10.6Z" transform="translate(-6.628 -1.938)" fill="#2e578b" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconCreditCard;
