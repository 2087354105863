import React, { useCallback, useState } from 'react';
import * as Styles from './NationalDomainTransfer.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  Button, IconLoading, Input,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const NationalDomainTransfer = ({
  submitForm,
  opening,
}) => {
  const [domainToTransfer, setDomainToTransfer] = useState('');
  const { ticket: ticketsLocale } = useLocale();
  const { nationalDomainTransfer: nationalDomainTransferLocale } = ticketsLocale.forms;

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      tags: ['whmcs_form_transferencia_de_dominio_nacional', 'dominio_nacional'],
      subject: 'Minha Conta - Transferência de Domínio Nacional',
      key: V2Forms.NATIONAL_DOMAIN_TRANSFER,
      automation: false,
      pid: undefined,
      product: undefined,
      body: `• Produto: Transferência de Domínio Nacional
        • Solicitação: Transferência de Domínio Nacional
        • Domínio(s) a transferir: ${domainToTransfer}`,
    };


    submitForm && submitForm(data);
  }, [domainToTransfer, submitForm]);

  const handleNewDomainToTransfer = (domain) => {
    setDomainToTransfer(domain);
  };

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        {nationalDomainTransferLocale.subject}
      </Styles.Info>

      <Styles.Info>
        {nationalDomainTransferLocale.followTheSteps}
      </Styles.Info>

      <Styles.List>
        {nationalDomainTransferLocale.steps.map(step => (
          <Styles.ListItem>
            {step}
          </Styles.ListItem>
        ))}
      </Styles.List>

      <Styles.Info>
        {nationalDomainTransferLocale.remember}
      </Styles.Info>

      <Styles.List>
        {nationalDomainTransferLocale.rememberList.map(step => (
          <Styles.ListItem>
            {step}
          </Styles.ListItem>
        ))}
      </Styles.List>

      <Styles.Info>
        {nationalDomainTransferLocale.fillTheField}
      </Styles.Info>

      <Styles.InputWrapper>
        <Input
          label={nationalDomainTransferLocale.domainsToTransfer}
          placeholder=""
          value={domainToTransfer}
          onChange={e => handleNewDomainToTransfer(e.target.value)}
          testId="domainInput"
          type="textarea"
          rows={3}
        />
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : nationalDomainTransferLocale.send}
          disabled={opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default NationalDomainTransfer;
