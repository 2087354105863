import React from 'react';
import { withStyles } from '@material-ui/core';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import EmailsContextProvider from '@/contexts/Emails/context';
import styles from './styles';
import TitanCreateEMailPageWrapper from './TitanCreateEMailPageWrapper';

const TitanCreateEMail = ({ classes }) => (
  <ContentContainer className={classes.root}>
    <EmailsContextProvider>
      <TitanCreateEMailPageWrapper />
    </EmailsContextProvider>
  </ContentContainer>
);

export default withStyles(styles)(TitanCreateEMail);
