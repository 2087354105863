/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useState } from 'react';
import * as Styles from './InternalMigration.styles';
import {
  Button,
  Checkbox,
  IconCheck,
  IconData,
  IconInfo,
  Input, Radio, Select,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';
import Loader from '@/components/Layout/Loader';

export const InternalMigration = ({
  selectedProductOption,
  selectedProduct,
  destinationProductsOptions,
  submitForm,
  opening,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const [destiny, setDestiny] = useState(null);
  const options = [{ label: selectedProductOption }];
  const [destinyData, setDestinyData] = useState(null);
  const [migrationType, setMigrationType] = useState(null);
  const [migrationData, setMigrationData] = useState('');
  const [destinyDomain, setDestinyDomain] = useState('');
  const [destinyServer, setDestinyServer] = useState('');
  const [destinyUser, setDestinyUser] = useState('');
  const [readTerms, setReadTerms] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [ignoreServer, setIgnoreServer] = useState(false);

  const handleSelectDestiny = (label) => {
    const product = [...destinationProductsOptions].find(product => product.label === label);
    setDestinyDomain(product.label);
    setDestinyServer((product.serverhostname || product.dedicatedip || ''));
    setDestinyUser(product.username || '');
    setDestinyData(product);
    setIgnoreServer(!product.serverhostname);
  };

  const checkForm = useCallback(() => {
    let isOk = false;
    const boxes = !!readTerms && !!agreed;

    const partialValidation = () => {
      if (!migrationType) {
        return false;
      }

      if (migrationType === 'partial' && migrationData === '') {
        return false;
      }

      return true;
    };

    if (ignoreServer) {
      isOk = !!destinyDomain && !!destinyServer && boxes && partialValidation();

      return !isOk;
    }

    isOk = !!destinyDomain && !!destinyUser && !!destinyServer && boxes && partialValidation();
    return !isOk;
  }, [
    destinyDomain,
    destinyServer,
    destinyUser,
    ignoreServer,
    agreed,
    readTerms,
    migrationData,
    migrationType,
  ]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.internalMigration.name,
      key: V2Forms.INTERNAL_MIGRATION,
      tags: ['migracao_interna_automacao'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.internalMigration.name,
      },
      body: `
      ${ticketsLocale.forms.internalMigration.migrationTypeText}: ${migrationType}
      ${ticketsLocale.forms.internalMigration.whatToBeMigrated}: ${migrationData}
      ${ticketsLocale.forms.internalMigration.originDomainLabel}: ${selectedProductOption}
      ${ticketsLocale.forms.internalMigration.body.originServer}: ${selectedProduct.serverhostname || selectedProduct.dedicatedip}
      ${ticketsLocale.forms.internalMigration.body.originUser}: ${selectedProduct.username}
      ${destiny === 'myServer' ? ticketsLocale.forms.internalMigration.myServerLabelRadio : ticketsLocale.forms.internalMigration.otherServerLabelRadio}: ${ticketsLocale.forms.internalMigration.body.yes}
      ${ticketsLocale.forms.internalMigration.myDomainLabel}: ${destinyDomain}
      ${ticketsLocale.forms.internalMigration.body.destinyServer}: ${destinyServer}
      ${ticketsLocale.forms.internalMigration.body.destinyUser}: ${destinyUser}
      ${ticketsLocale.forms.internalMigration.body.agree}: ${readTerms ? ticketsLocale.forms.internalMigration.body.yes : ticketsLocale.forms.internalMigration.body.no}
      ${ticketsLocale.forms.internalMigration.body.agreePayment}: ${agreed ? ticketsLocale.forms.internalMigration.body.yes : ticketsLocale.forms.internalMigration.body.no}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    agreed,
    selectedProduct,
    destiny,
    destinyDomain,
    destinyServer,
    destinyUser,
    selectedProductOption,
    readTerms,
    submitForm,
    migrationData,
    migrationType,
  ]);

  const handleSetDestiny = (destiny) => {
    setDestinyDomain('');
    setDestinyServer('');
    setDestinyUser('');
    setDestiny(destiny);
  };

  const handleSetMigrationType = (type) => {
    setMigrationType(type);
  };

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {ticketsLocale.forms.terms}
      </Styles.Info>

      <Styles.LinksWrapper>
        <Styles.Link>
          <IconCheck size="sm" />
          <Styles.InfoText color="activeMedium">
            {ticketsLocale.forms.internalMigration.links.migration()}
          </Styles.InfoText>
        </Styles.Link>
        <Styles.Link>
          <IconCheck size="sm" />
          <Styles.InfoText color="activeMedium">
            {ticketsLocale.forms.internalMigration.links.compatibility()}
          </Styles.InfoText>
        </Styles.Link>
      </Styles.LinksWrapper>

      <Styles.Warning>
        <strong>{ticketsLocale.forms.internalMigration.warning.title}</strong>
        <Styles.WarningList>
          {ticketsLocale.forms.internalMigration.warning.item[0]()}
          {ticketsLocale.forms.internalMigration.warning.item[1]()}
          {ticketsLocale.forms.internalMigration.warning.item[2]()}
        </Styles.WarningList>
      </Styles.Warning>

      <Styles.Alert>
        <IconInfo color="problemPure" />
        <Styles.AlertDescription>
          {ticketsLocale.forms.internalMigration.alert}
        </Styles.AlertDescription>
      </Styles.Alert>

      <Styles.VideoWrapper>
        <Styles.Iframe
          src="https://www.youtube.com/embed/pXE7sEAhnIQ?si=rKmVR06XHYuyVn5V"
          title="Como Funcionam as Migrações Internas na HostGator"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Styles.VideoWrapper>

      <Styles.Title>
        {ticketsLocale.forms.internalMigration.title}
      </Styles.Title>

      <Styles.BlockTitle>
        {ticketsLocale.forms.internalMigration.originTitle}
      </Styles.BlockTitle>

      <Styles.BlockTitle>
        {ticketsLocale.forms.internalMigration.migrationTypeText}
      </Styles.BlockTitle>

      <Styles.RadioWrapper>
        <Styles.RadioLabel htmlFor="#fullMigration" onClick={() => handleSetMigrationType('full')} data-testid="fullMigration">
          <Radio checked={migrationType === 'full'} id="fullMigration" />
          {ticketsLocale.forms.internalMigration.fullMigration}
        </Styles.RadioLabel>

        <Styles.RadioLabel htmlFor="#partialMigration" onClick={() => handleSetMigrationType('partial')} data-testid="partialMigration">
          <Radio checked={migrationType === 'partial'} id="partialMigration" />
          {ticketsLocale.forms.internalMigration.partialMigration}
        </Styles.RadioLabel>
      </Styles.RadioWrapper>

      {(migrationType === 'partial') && (
        <Input
          name="parcial_info"
          placeholder={ticketsLocale.forms.internalMigration.whatToBeMigrated}
          label={migrationData.length ? ticketsLocale.forms.internalMigration.whatToBeMigrated : ''}
          value={migrationData}
          onChange={e => setMigrationData(e.target.value)}
          type="textarea"
        />
      )}

      <Styles.InputWrapper>
        <Select
          name="origin_domain"
          placeholder={ticketsLocale.forms.internalMigration.originDomainPlaceholder}
          label={ticketsLocale.forms.internalMigration.originDomainLabel}
          options={options}
          value={selectedProductOption}
          withScroll
        />

        <Input
          name="origin_server"
          placeholder={ticketsLocale.forms.internalMigration.originServerPlaceholder}
          label={ticketsLocale.forms.internalMigration.originServerLabel}
          value={selectedProduct.serverhostname || selectedProduct.dedicatedip}
          readOnly
        />

        <Input
          name="origin_user"
          placeholder={ticketsLocale.forms.internalMigration.originUserPlaceholder}
          label={ticketsLocale.forms.internalMigration.originUserLabel}
          value={selectedProduct.username}
          readOnly
        />
      </Styles.InputWrapper>

      <Styles.DestinationBlockTitle>
        {ticketsLocale.forms.internalMigration.destinationTitle}
      </Styles.DestinationBlockTitle>

      <Styles.RadioWrapper>
        <Styles.RadioLabel htmlFor="#myServer" onClick={() => handleSetDestiny('myServer')} data-testid="myServerRadio">
          <Radio checked={destiny === 'myServer'} id="myServer" />
          {ticketsLocale.forms.internalMigration.myServerLabelRadio}
        </Styles.RadioLabel>

        <Styles.RadioLabel htmlFor="#otherServer" onClick={() => handleSetDestiny('otherServer')} data-testid="otherServerRadio">
          <Radio checked={destiny === 'otherServer'} id="otherServer" />
          {ticketsLocale.forms.internalMigration.otherServerLabelRadio}
        </Styles.RadioLabel>
      </Styles.RadioWrapper>

      {destiny === 'myServer' && (
        <Styles.InputWrapper>
          <Select
            name="destiny_myServer_domain"
            placeholder={ticketsLocale.forms.internalMigration.myDomainPlaceholder}
            label={ticketsLocale.forms.internalMigration.myDomainLabel}
            options={destinationProductsOptions}
            value={destinyDomain}
            setValue={label => handleSelectDestiny(label)}
            testId="selectProduct"
            withScroll
          />

          {(destinyData && (destinyData.serverhostname || destinyData.dedicatedip) && destinyServer) && (
            <Input
              name="destiny_myServer_server"
              placeholder={ticketsLocale.forms.internalMigration.myServerPlaceholder}
              label={ticketsLocale.forms.internalMigration.myServerLabel}
              value={destinyServer}
              onChange={e => setDestinyServer(e.target.value)}
              readOnly
            />
          )}

          {(destinyData && destinyData.username && destinyUser) && (
            <Input
              name="destiny_myServer_user"
              placeholder={ticketsLocale.forms.internalMigration.myUserPlaceholder}
              label={ticketsLocale.forms.internalMigration.myUserLabel}
              value={destinyUser}
              onChange={e => setDestinyUser(e.target.value)}
              readOnly
            />
          )}
        </Styles.InputWrapper>
      )}

      {destiny === 'otherServer' && (
        <Styles.InputWrapper>
          <Input
            name="destiny_other_domain"
            testId="destiny_other_domain"
            placeholder={ticketsLocale.forms.internalMigration.otherDomainPlaceholder}
            label={ticketsLocale.forms.internalMigration.otherDomainLabel}
            value={destinyDomain}
            onChange={e => setDestinyDomain(e.target.value)}
          />

          <Input
            name="destiny_other_server"
            testId="destiny_other_server"
            placeholder={ticketsLocale.forms.internalMigration.otherServerPlaceholder}
            label={ticketsLocale.forms.internalMigration.otherServerLabel}
            value={destinyServer}
            onChange={e => setDestinyServer(e.target.value)}
          />

          <Input
            name="destiny_other_user"
            testId="destiny_other_user"
            placeholder={ticketsLocale.forms.internalMigration.otherUserPlaceholder}
            label={ticketsLocale.forms.internalMigration.otherUserLabel}
            value={destinyUser}
            onChange={e => setDestinyUser(e.target.value)}
          />
        </Styles.InputWrapper>
      )}

      {!!destiny && (
      <>
        <Styles.CheckboxWrapper>
          <Styles.CheckboxLabel
            htmlFor="#readTerms"
            data-testid="checkReadTerms"
            onClick={() => setReadTerms(!readTerms)}
          >
            <Checkbox checked={readTerms} id="readTerms" />
            <Styles.Text>
              {ticketsLocale.forms.internalMigration.checkbox.terms()}
            </Styles.Text>
          </Styles.CheckboxLabel>

          <Styles.CheckboxLabel
            htmlFor="#agreed"
            onClick={() => setAgreed(!agreed)}
            data-testid="checkAgreed"
          >
            <Checkbox checked={agreed} id="agreed" />
            <Styles.Text>
              {ticketsLocale.forms.internalMigration.checkbox.agreed()}
            </Styles.Text>
          </Styles.CheckboxLabel>
        </Styles.CheckboxWrapper>

        <Styles.ButtonWrapper>
          <Button
            size="large"
            label={opening ? <Loader size="12" /> : ticketsLocale.forms.submitLabel}
            disabled={checkForm() || opening}
            type="submit"
            testId="submit"
          />
        </Styles.ButtonWrapper>
      </>
      )}
    </Styles.Form>
  );
};

export default InternalMigration;
