export const PAYMENT_METHOD = {
  CREDIT_CARD: 'cc',
  BRASPAG_CREDIT: 'braspag_credit',
  BRASPAG_PARC_1X: 'braspag_credit_1x',
  BRASPAG_PARC_2X: 'braspag_credit_2x',
  BRASPAG_PARC_3X: 'braspag_credit_3x',
  BRASPAG_PARC_4X: 'braspag_credit_4x',
  BRASPAG_PARC_5X: 'braspag_credit_5x',
  BRASPAG_PARC_6X: 'braspag_credit_6x',
  BRASPAG_PARC_7X: 'braspag_credit_7x',
  BRASPAG_PARC_8X: 'braspag_credit_8x',
  BRASPAG_PARC_9X: 'braspag_credit_9x',
  BRASPAG_PARC_10X: 'braspag_credit_10x',
  BRASPAG_PARC_11X: 'braspag_credit_11x',
  BRASPAG_PARC_12X: 'braspag_credit_12x',
  HG_CREDITS: 'hg_credits',
  DLOCAL: 'dlocal',
  PIX: 'gp_pix',
  GP_PAYPAL: 'gp_paypal',
  PAYPAL: 'paypal',
  GP_BOLETO: 'gp_boleto',

  // CREDIT CARD ES
  CREDIT_CARD_ES: 'dlocal',

  // BOLETO MEXICO
  BOLETO_MX: 'payu_boleto',

  // BOLETO CHILE || COLOMBIA
  BOLETO_CL_CO: 'dlocalcash',
};

export const REFUND_TYPE = {
  PARTIAL: 'partial',
  INTEGRAL: 'integral',
};

export const REFUND_STATUS = {
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
};
