import styled, { css } from 'styled-components';

export const StepperWrapper = styled.div`
    display: flex;
`;

export const Step = styled.div`
  align-items: center;
  display:flex;
  min-width: 42px;
`;

export const StepDot = styled.span`
  ${({ theme, status }) => {
    const variants = {
      pending: {
        bgColor: theme.v2.colors.neutral.lowLight,
        flex: '0 0 15px',
        height: '15px',
      },
      finished: {
        bgColor: theme.v2.colors.brand.primaryMedium,
        flex: '0 0 19px',
        height: '19px',
      },
      error: {
        bgColor: theme.v2.colors.feedback.problemMedium,
        flex: '0 0 19px',
        height: '19px',
      },
    };

    return css`
    align-items: center;
    background-color: ${variants[status].bgColor};
    border-radius: 50%;
    display: flex;
    flex: ${variants[status].flex};
    justify-content: center;
    height: ${variants[status].height};
    position: relative;
  `;
  }}
`;

export const InnerDot = styled.span`
  ${({ theme, status }) => css`
    align-items:center;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid ${status === 'pending' ? 'transparent' : theme.v2.colors.white.primary};
    display: flex;
    flex: 0 0 15px;
    height: 15px;
    justify-content: center;
    position: relative;
  `}
`;

export const StepDash = styled.span`
  ${({ theme, status }) => {
    const variants = {
      pending: {
        bgColor: theme.v2.colors.neutral.lowLight,
      },
      finished: {
        bgColor: theme.v2.colors.brand.primaryMedium,
      },
      error: {
        bgColor: theme.v2.colors.brand.primaryMedium,
      },
    };

    return css`
    background-color: ${variants[status].bgColor};
    flex: 0 0 27px;
    height: 1px;
    min-width: 27px;
  `;
  }}
`;

export const Label = styled.span``;
