import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions } from '@/redux/modules/billing';
import * as Styles from './ManageSubscriptionPage.styles';
import { ManageSubscriptionNewHandler } from '@/components/billing/subscriptions/ManageSubscriptionNewHandler';
import { hasPaymentEnabled } from '@/utils/Validators/validation';
import {
  LIST_BOLETO_METHODS, LIST_CREDIT_CARD_METHODS, LIST_PAYPAL_METHODS, PIX,
} from '@/config/billing/paymentMethods';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  ALPHA_RETENTION_PAGE_FLOW,
  XRAY_ENABLED_MODAL_IN_SUBSCRIPTION_MANAGER_BY_URL,
  XRAY_ENABLED_PAYMENT_BY_CREDIT_CARD,
  XRAY_ENABLED_PAYMENT_BY_TICKET,
  XRAY_ENABLED_PAYMENT_METHOD_FILTER,
  XRAY_ENABLED_SWITCH_PAYMENT_METHOD_BY_PAYPAL,
  XRAY_PIX_PAYMENT_GATEWAY,
} from '@/config/GrowthBook/constants';

const ManageSubscriptionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { items } = useSelector(state => state.billing.subscriptions);
  const { enabledPaymentMethods } = useSelector(state => state.billing.paymentMethods);
  const preChatState = useSelector(state => state.preChat);
  const enabledReflundBlock = true;
  const enabledPaymentErroInfoMessageByCreditCard = true;
  const enabledPaymentByPayPal = useFeatureIsOn(XRAY_ENABLED_SWITCH_PAYMENT_METHOD_BY_PAYPAL);
  const enabledPaymentMethodFilter = useFeatureIsOn(XRAY_ENABLED_PAYMENT_METHOD_FILTER);
  const enabledRetentionPageFlux = useFeatureIsOn(ALPHA_RETENTION_PAGE_FLOW);
  const enabledNewLabelsWithPix = useFeatureIsOn(XRAY_PIX_PAYMENT_GATEWAY);

  const { subscriptionId, subscriptionType } = useParams();

  const enabledCreditCard = useFeatureIsOn(XRAY_ENABLED_PAYMENT_BY_CREDIT_CARD);
  const enabledTicket = useFeatureIsOn(XRAY_ENABLED_PAYMENT_BY_TICKET);
  const enabledPaymentByTicket = enabledPaymentMethodFilter ? enabledTicket && hasPaymentEnabled(enabledPaymentMethods, LIST_BOLETO_METHODS) : enabledTicket;
  const enabledPaymentByCreditCard = enabledPaymentMethodFilter ? enabledCreditCard && hasPaymentEnabled(enabledPaymentMethods, LIST_CREDIT_CARD_METHODS) : enabledCreditCard;
  const enabledPaymentByPix = useFeatureIsOn(XRAY_PIX_PAYMENT_GATEWAY) && (enabledPaymentMethodFilter ? hasPaymentEnabled(enabledPaymentMethods, [PIX]) : true);
  const enabledModalInSubscriptionManagerByUrl = useFeatureIsOn(XRAY_ENABLED_MODAL_IN_SUBSCRIPTION_MANAGER_BY_URL);

  const fetchItems = useCallback(() => {
    if (items.length) return;
    dispatch(billingActions.subscriptions.request());
  }, [dispatch, items]);

  const fetchEnabledPaymentMethods = useCallback(() => {
    if (enabledPaymentMethods !== null) return;
    dispatch(billingActions.paymentMethod.request());
  }, [dispatch, enabledPaymentMethods]);

  useEffect(() => {
    fetchItems();
    fetchEnabledPaymentMethods();
  }, [fetchItems, fetchEnabledPaymentMethods]);

  const onRedirect = (path = '/') => {
    history.push(path);
  };

  return (
    <Styles.Wrapper data-testid="manage-subscription-page">
      <ManageSubscriptionNewHandler
        items={items}
        subscriptionId={Number(subscriptionId)}
        subscriptionType={subscriptionType}
        redirectTo={onRedirect}
        displayReflundBlock={enabledReflundBlock}
        enabledPaymentErroInfoMessageByCreditCard={enabledPaymentErroInfoMessageByCreditCard}
        enabledPaymentByPayPal={enabledPaymentByPayPal && (enabledPaymentMethodFilter ? hasPaymentEnabled(enabledPaymentMethods, LIST_PAYPAL_METHODS) : true)}
        enabledPaymentByCreditCard={enabledPaymentByCreditCard}
        enabledPaymentByTicket={enabledPaymentByTicket}
        enabledPaymentByPix={enabledPaymentByPix && (enabledPaymentMethodFilter ? hasPaymentEnabled(enabledPaymentMethods, [PIX]) : true)}
        enabledRetentionPageFlux={enabledRetentionPageFlux}
        enabledNewLabelsWithPix={enabledNewLabelsWithPix}
        enabledModalInSubscriptionManagerByUrl={enabledModalInSubscriptionManagerByUrl}
        preChatState={preChatState}
      />
    </Styles.Wrapper>
  );
};

export default ManageSubscriptionPage;
