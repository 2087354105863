import React, { } from 'react';
import * as Styles from './TicketRedirectModal.styles';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/v1/Button';
import useLocale from '@/hooks/useLocale';

const TicketRedirectModal = ({
  onCloseClick,
  onRedirectButtonClick,
  position = 'fixed',
  urlTicket = '',
  hasOriginSavePDF = false,
}) => {
  const { billing: billingLocale } = useLocale();

  const {
    ticketRedirectModal: ticketRedirectModalLocale,
  } = billingLocale;

  const redirectTicketUrlLabel = ticketRedirectModalLocale.redirectTicketUrlButtonLabel;
  const downloadPDFLabel = ticketRedirectModalLocale.downloadPDFButtonLabel;
  const infoLabel = ticketRedirectModalLocale.infoTextLabel;

  const handleOnRedirectClick = (event) => {
    onRedirectButtonClick && onRedirectButtonClick(event);
    window.open(urlTicket, '_blank');
    onCloseClick && onCloseClick();
  };

  return (
    <Modal
      maxWidth={400}
      onClose={onCloseClick}
      testId="ticket-modal"
      position={position}
    >
      <Styles.Content>
        <Styles.Info>
          <Styles.InfoText
            data-testid="ticket-modal-text"
          >
            {infoLabel}
          </Styles.InfoText>
        </Styles.Info>
        <Styles.Actions>
          <Styles.ButtonAdapter>
            <Button
              testId="ticket-modal-redirect-button"
              label={hasOriginSavePDF ? downloadPDFLabel : redirectTicketUrlLabel}
              onClick={handleOnRedirectClick}
            />
          </Styles.ButtonAdapter>
        </Styles.Actions>
      </Styles.Content>
    </Modal>
  );
};

export default TicketRedirectModal;
