import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import {
  differenceInCalendarDays, parseISO, format, subDays,
} from 'date-fns';
import { ThemeContext } from 'styled-components';
import useLocale from '@/hooks/useLocale';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { CHANGE_PLAN_CYCLE_PRODUCTS, PAGE_RETENTION_VERSIONS, SUBSCRIPTION_STATUS } from '@/enums/billing/billing.enum';
import { Header, Button } from '@/pages/common';
import {
  SubscriptionResume, SubscriptionRenewalData, SubscriptionControls, RefundFeedback,
} from '@/components/billing/components';
import * as Styles from './ManageSubscriptionNewHandler.styles';
import {
  ManageSubscriptionProps, Modais, EnumFeedbackStatus, EnumActionOpeningModal,
} from './ManageSubscriptionNewHandler.types';
import {
  BRASPAG_PARC_10X,
  BRASPAG_PARC_11X,
  BRASPAG_PARC_12X,
  BRASPAG_CREDIT,
  BRASPAG_PARC_1X,
  BRASPAG_PARC_2X,
  BRASPAG_PARC_3X,
  BRASPAG_PARC_4X,
  BRASPAG_PARC_5X,
  BRASPAG_PARC_6X,
  BRASPAG_PARC_7X,
  BRASPAG_PARC_8X,
  BRASPAG_PARC_9X,
  CREDIT_CARD_ES,
  GATORPAY_PAYPAL,
  PAYPAL,
  PIX,
  LIST_BOLETO_METHODS,
} from '@/config/billing/paymentMethods';
import { SubscriptionCancelModal } from '../../components/manageSubscription/SubscriptionCancelModal';
import { IconError } from '@/icons';
import { SubscriptionActiveRenewalModal } from '../../components/manageSubscription/SubscriptionActiveRenewalModal';
import SubscriptionsFunctions from '../SubscriptionsHandler/SubscriptionsHandler.functions';
import { billingAnalytics } from '@/analytics';
import {
  refundFeedbackLocale,
} from './ManageSubscriptionNewHandler.locale';
import { PaymentModal } from '../PaymentModal';
import { SwitchPaymentMethodModal } from '../SwitchPaymentMethodModal';
import { FinishSwitchPaymentMethodModal } from '../FinishSwitchPaymentMethodModal';
import { useHistory } from 'react-router';
import { DiscountType, OriginCalling } from '../PaymentModal/PaymentModal.types';
import { ExpiredTimePixModal } from '../ExpiredTimePixModal';
import { PaymentSuccessPixModal } from '../PaymentSuccessPixModal';
import { GenerateErrorPixModal } from '../GenerateErrorPixModal';
import { ReactivationModal } from '../ReactivationModal';
import { isBRServer, isMobile } from '@/utils/Validators/validation';
import { formatDate } from '@/utils/Formatters/formatDate';
import { preChatAnalytics } from '@/analytics/preChat';
import { TicketRedirectModal } from '../TicketRedirectModal';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions, preChatActions } from '@/redux/modules';
import { WHMCS_URL } from '@/config';
import { ReactivationConfirmationModal } from '../../components/ReactivationConfirmationModal';
import { InvoiceGeneratorModal } from '../../components/InvoiceGeneratorModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { DiscountOfferBanner } from '../DiscountOfferBanner';
import {
  CHARLIE_SUBSCRIPTION_CHANGE_PAYMENT_METHOD_MODAL,
  FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE,
  XRAY_ENABLED_DISCOUNT_OFFER_BANNER,
  XRAY_ENABLED_DISCOUNT_RETENTION,
  XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_IMMEDIATE,
  XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_RENEWAL,
  XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND,
  XRAY_RETENTION_OFFER_VERSION_PAGE_AB_TEST,
} from '@/config/GrowthBook/constants';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import { CancelOptions } from '../../components/manageSubscription/SubscriptionActiveRenewalModal/SubscriptionActiveRenewalModal.types';
import { ChangePaymentModal } from '@/pages/CreditCard/components/ChangePaymentModal';
import { FLOW_ORIGIN, MODAL_STEPS } from '@/pages/CreditCard/components/ChangePaymentModal/ChangePaymentModal.types';

const ManageSubscriptionNewHandler = ({
  items = [],
  redirectTo = () => { },
  subscriptionId,
  subscriptionType,
  displayReflundBlock = true,
  enabledPaymentErroInfoMessageByCreditCard = false,
  enabledPaymentByPayPal = false,
  enabledChangePayPalAccount = false,
  enabledPaymentByCreditCard = false,
  enabledPaymentByTicket = false,
  enabledPaymentByPix = false,
  enabledRetentionPageFlux = false,
  limitDateToDisplayNewPixLabel = null,
  enabledNewLabelsWithPix = false,
  enabledModalInSubscriptionManagerByUrl = false,
  originCalling = OriginCalling.SUBSCRIPTION_MANAGER,
  preChatState,
}) => {
  const theme = useContext(ThemeContext);
  const enabledNewPixChipLabel = limitDateToDisplayNewPixLabel != null && differenceInCalendarDays(new Date(`${limitDateToDisplayNewPixLabel} 00:00:00`), new Date()) > 0;
  const enabledDiscountOfferBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_OFFER_BANNER);
  const enabledEcommercePlusCycleChange = useFeatureIsOn(FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE);
  const enabledDiscountRetention = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION);
  const enabledChangePaymentMethodModal = useFeatureIsOn(CHARLIE_SUBSCRIPTION_CHANGE_PAYMENT_METHOD_MODAL);

  const { requestDiagnostic } = useDiagnostic();

  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const {
    ecommercePlus: ecommercePlusLocale,
    manageSubscription: manageSubscriptionLocale,
    subscriptions: subscriptionsLocale,
    manageSubscriptionCancelModal: manageSubscriptionCancelModalLocale,
    manageSubscriptionActiveRenewalModal: manageSubscriptionActiveRenewalModalLocale,
    paymentCycle: paymentCycleLocale,
    manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale,
    manageSubscriptionSwitchPaymentMethodInvoiceModal: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale,
    reactivationConfirmationModal: reactivationConfirmationModalLocale,
    invoiceGeneratorModal: invoiceGeneratorModalLocale,
    manageSubscriptionFinishSwitchPaymentMethodInvoiceModal: manageSubscriptionFinishSwitchPaymentMethodInvoiceLocale,
    paymentMethods: paymentMethodsLocale,
    status: statusLocale,
  } = billingLocale;
  const {
    manageSubscription,
    cancelSubscription,
    cancelRenewal,
    retentionRenew,
    retentionSubscriptionConfirm,
    retentionSubscription,
  } = routesLocale;
  const [currentItem, setCurrentItem] = useState({});
  const [modalById, setModalById] = useState(Modais.NONE);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;
  const history = useHistory();
  const [newPaymentMethod, setNewPaymentMethod] = useState('');
  const [hasQRCodeGenerated, setHasQRCodeGenerated] = useState(false);
  const [qrCodeGenerated, setQRCodeGenerated] = useState('');
  const [pixCodeGenerated, setPixCodeGenerated] = useState('');
  const [hasOriginSavePDF, setOriginSavePDF] = useState(false);
  const [showRenewalSubscriptionModal, setShowRenewalSubscriptionModal] = useState(false);
  const [hasDraftInvoice, setDraftInvoice] = useState(false);

  const summary = useSelector(state => state.summary);
  const billingState = useSelector(state => state.billing);
  const { triage } = useSelector(state => state.preChat);
  const { subscription, subscriptions: subscriptionsState } = billingState;

  const retentionFlux = enabledRetentionPageFlux && currentItem.isSharedHostingAbleToRetention;
  const dispatch = useDispatch();
  const [urlTicket, setUrlTicket] = useState('');
  const enabledInvoiceGeneration = currentItem != null && currentItem.modal === EnumActionOpeningModal.MODAL_INVOICE_GENERATION;
  const enabledDiscountOffer = currentItem != null && currentItem.discount && currentItem.discount.type === DiscountType.RENEWAL;
  const canDisplayChangePaymentModal = enabledChangePaymentMethodModal && !!subscriptionsState.itemsNotInCreditcard.length;
  const changePaymentMethodLoading = subscriptionsState.loadingItemsNotInCreditCard || subscription.switchPaymentMethod.loading;
  const switchPaymentMethodStatus = subscription.switchPaymentMethod.status;

  const getCurrentItem = useCallback(() => {
    if (!items.length) return;
    items
      .filter(item => item.id === subscriptionId && item.type === subscriptionType)
      .forEach(item => setCurrentItem(item));
  }, [items, subscriptionId, subscriptionType]);

  useEffect(() => {
    getCurrentItem();
  }, [getCurrentItem]);

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionModalPageView('resumo_assinatura');
  }, [subscriptionAnalytics]);

  const hasCurrentItem = JSON.stringify(currentItem) !== '{}';

  useEffect(() => {
    if (hasCurrentItem && currentItem.discount && currentItem.discount.type === DiscountType.RETENTION && currentItem.unpaidInvoice.id === null) {
      setDraftInvoice(true);
    }
  }, [hasCurrentItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPathWithRetention = {
    cancelRenewalPath: `${manageSubscription}${retentionSubscriptionConfirm}${retentionRenew}/${subscriptionId}/${subscriptionType}`,
    cancelImmediatePath: `${manageSubscription}${retentionSubscriptionConfirm}${retentionSubscription}/${subscriptionId}/${subscriptionType}`,
  };


  const getStatus = useCallback(() => {
    if (hasCurrentItem) {
      const {
        status,
        substatus,
      } = currentItem;

      return `${status.toLowerCase()}/${substatus.toLowerCase()}`;
    }
  }, [currentItem, hasCurrentItem]);

  const getCurrentPaymentMethod = () => {
    if (hasCurrentItem) {
      const {
        paymentMethod,
      } = currentItem;

      return paymentMethod;
    }
  };

  const isStatusPermitted = (status = []) => currentItem && status.includes(currentItem.substatus.toLowerCase());

  const canShowRefundFeedback = useCallback(

    () => displayReflundBlock && hasCurrentItem && !!(isStatusPermitted(['cancelled']) && (currentItem && currentItem.refundFeedback)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItem, hasCurrentItem, displayReflundBlock],
  );

  const isDomain = useCallback(() => currentItem.type === 'domain', [currentItem]);

  const hasRenewalActive = useCallback(() => currentItem.cancelType === null, [currentItem]);

  const onCancelSubscriptionImmediatelyClick = useCallback(() => {
    const cancelSubscriptionImmediatelyPath = retentionFlux
      ? getPathWithRetention.cancelImmediatePath
      : `${manageSubscription}${cancelSubscription}/${subscriptionId}/${subscriptionType}`;

    redirectTo(cancelSubscriptionImmediatelyPath);
  }, [cancelSubscription, getPathWithRetention, manageSubscription, redirectTo, retentionFlux, subscriptionId, subscriptionType]);

  const onEcommercePlusCycleChangeClick = useCallback(() => {
    redirectTo(''); // TODO: Add path
  }, [redirectTo]);

  useEffect(() => {
    if (Object.keys(currentItem).length) {
      if (triage.subscriptionToCancel) {
        if (hasRenewalActive() && getStatus() !== SUBSCRIPTION_STATUS.PENDING_PAYMENT) {
          setModalById(Modais.CANCEL_SUBSCRIPTION);
        }
        if (!hasRenewalActive()) {
          onCancelSubscriptionImmediatelyClick();
        }
      }
    }
  }, [triage, hasRenewalActive, isDomain, getStatus, onCancelSubscriptionImmediatelyClick, currentItem, dispatch]);

  useEffect(() => {
    if (triage.subscriptionToChangePaymentMethod) {
      setModalById(Modais.ACTIVE_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION);
    }
  }, [triage.subscriptionToChangePaymentMethod]);

  const listPaymentToDisplayPaymentButton = [
    BRASPAG_PARC_10X,
    BRASPAG_PARC_11X,
    BRASPAG_PARC_12X,
    BRASPAG_PARC_1X,
    BRASPAG_PARC_2X,
    BRASPAG_PARC_3X,
    BRASPAG_PARC_4X,
    BRASPAG_PARC_5X,
    BRASPAG_PARC_6X,
    BRASPAG_PARC_7X,
    BRASPAG_PARC_8X,
    BRASPAG_PARC_9X,
    CREDIT_CARD_ES,
    GATORPAY_PAYPAL,
    PAYPAL,
  ];

  const canShowPaymentInvoceButton = (status = getStatus(), paymentMethod = getCurrentPaymentMethod()) => {
    const isCompatibleStatusWhenNotImmediatePayment = [
      SUBSCRIPTION_STATUS.PENDING_PAYMENT,
      SUBSCRIPTION_STATUS.OVERDUE,
      SUBSCRIPTION_STATUS.TO_DUE,
      SUBSCRIPTION_STATUS.SUSPENDED,
    ].includes(status);

    const isCompatibleStatusWhenImmediatePayment = [
      SUBSCRIPTION_STATUS.PENDING_PAYMENT,
      SUBSCRIPTION_STATUS.OVERDUE,
      SUBSCRIPTION_STATUS.SUSPENDED,
    ].includes(status);

    if (enabledDiscountRetention && currentItem && currentItem.discount && currentItem.discount.type === DiscountType.RETENTION && isMobile) {
      return false;
    }

    if (isCompatibleStatusWhenNotImmediatePayment && !listPaymentToDisplayPaymentButton.includes(paymentMethod)) {
      return true;
    }

    if (isCompatibleStatusWhenImmediatePayment && listPaymentToDisplayPaymentButton.includes(paymentMethod)) {
      return true;
    }

    return false;
  };

  const canShowSwitchPaymentMethodButton = (status = getStatus()) => ![
    SUBSCRIPTION_STATUS.OVERDUE,
    SUBSCRIPTION_STATUS.PENDING_PAYMENT,
  ].includes(status);

  const canShowRenewalData = (status = getStatus()) => hasRenewalActive() && ![
    SUBSCRIPTION_STATUS.PENDING,
    SUBSCRIPTION_STATUS.PENDING_ANALYSIS,
    SUBSCRIPTION_STATUS.PENDING_PAYMENT,
    SUBSCRIPTION_STATUS.CANCELLED,
    SUBSCRIPTION_STATUS.EXPIRED_CANCELLED,
    SUBSCRIPTION_STATUS.FRAUD_CANCELLED,
    SUBSCRIPTION_STATUS.TERMINATED_CANCELLED,
  ].includes(status);

  const canChangeCyclePlanProduct = () => {
    const brand = isBRServer ? 'BR' : 'ES';
    const isSignatureActive = getStatus() === SUBSCRIPTION_STATUS.ACTIVE;
    return isSignatureActive && CHANGE_PLAN_CYCLE_PRODUCTS[brand].some((product) => {
      const currentProductName = product.toLowerCase();
      const signatureProductName = currentItem.productName.toLowerCase();
      return signatureProductName.includes(currentProductName);
    });
  };

  const canShowPlanButton = canChangeCyclePlanProduct();

  const canShowControls = (status = getStatus()) => ![
    SUBSCRIPTION_STATUS.PENDING,
    SUBSCRIPTION_STATUS.PENDING_ANALYSIS,
    SUBSCRIPTION_STATUS.CANCELLED,
    SUBSCRIPTION_STATUS.EXPIRED_CANCELLED,
    SUBSCRIPTION_STATUS.FRAUD_CANCELLED,
    SUBSCRIPTION_STATUS.TERMINATED_CANCELLED,
  ].includes(status);

  const canShowSubscriptionCancelModal = () => modalById === Modais.CANCEL_SUBSCRIPTION;
  const canShowSubscriptionRenewalModal = () => modalById === Modais.ACTIVE_RENEWAL_SUBSCRIPTION;
  const canShowPaymentModal = () => modalById === Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION;
  const canShowSwitchPaymentMethodModal = () => modalById === Modais.ACTIVE_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION;
  const canShowFinishSwitchPaymentMethodModal = () => modalById === Modais.ACTIVE_FINISH_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION;
  const canShowExpiredTimePixModal = () => modalById === Modais.ACTIVE_EXPIRED_TIME_PIX_MODAL;
  const canShowPaymentSuccessPixModal = () => modalById === Modais.ACTIVE_PAYMENT_SUCCESS_PIX_MODAL;
  const canShowGenerateErrorPixModal = () => modalById === Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL;
  const canShowReactivationModal = () => modalById === Modais.ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL;
  const canShowTicketRedirectModal = () => modalById === Modais.ACTIVE_REDIRECT_TICKET_MODAL;
  const canShowReactivationConfirmationModal = () => modalById === Modais.ACTIVE_RENOVATION_SUBSCRIPTION_SUCCESS_MODAL;
  const canInvoiceGeneratorModal = () => modalById === Modais.ACTIVE_INVOICE_GENERATOR_MODAL;
  const canShowChangePaymentModal = () => modalById === Modais.CHANGE_PAYMENT_METHOD;
  const enabledDiscountRetentionNewFlux = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND);
  const enabledDiscountRetentionCancelImmediate = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_IMMEDIATE);
  const enabledDiscountRetentionCancelRenewal = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_RENEWAL);
  const retentionPageVersion = useFeatureIsOn(XRAY_RETENTION_OFFER_VERSION_PAGE_AB_TEST) ? PAGE_RETENTION_VERSIONS.NEW : PAGE_RETENTION_VERSIONS.OLD;

  useEffect(() => {
    dispatch(billingActions.hostingAbleDiscountRequest.request({ subscriptionId }));
  }, [subscriptionId, dispatch]);

  const getBoletoAndPixProducts = useCallback(() => {
    dispatch(billingActions.subscriptions.requestSubscriptionsNotInCreditCard());
  }, [dispatch]);

  const [dueDateFormated, setDueDateFormated] = useState('');

  const onDisplayModalPayment = () => {
    setHasQRCodeGenerated(false);
    subscriptionAnalytics.executePaymentClick('1_link_pague_agora');
    setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
  };

  useEffect(() => {
    if (preChatState && preChatState.reactivationRedirect) {
      setModalById(Modais.ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL);
    }
    if (preChatState && preChatState.triage.redirectManageSubscription) {
      setTimeout(() => {
        dispatch(preChatActions.subscription.redirectManageSubscription(false));
        dispatch(preChatActions.preChat.setOpen(true));
      }, 2000);
    }
  }, [preChatState, dispatch]);

  const onDisplayReactivationModal = () => {
    preChatAnalytics.subscriptionPageReactivationClick();
    setModalById(Modais.ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL);
  };

  const getBackgroundStatusColor = (status = getStatus()) => {
    switch (status) {
      case SUBSCRIPTION_STATUS.ACTIVE:
      case SUBSCRIPTION_STATUS.REGISTERED: {
        return theme.palette.success.primary;
      }

      case SUBSCRIPTION_STATUS.CANCELLED:
      case SUBSCRIPTION_STATUS.EXPIRED_CANCELLED:
      case SUBSCRIPTION_STATUS.FRAUD_CANCELLED:
      case SUBSCRIPTION_STATUS.TERMINATED_CANCELLED: {
        return theme.palette.grey.primary;
      }

      case SUBSCRIPTION_STATUS.PENDING_ANALYSIS:
      case SUBSCRIPTION_STATUS.PENDING_PAYMENT:
      case SUBSCRIPTION_STATUS.PENDING:
      case SUBSCRIPTION_STATUS.TRANSFERRED_AWAY: {
        return theme.palette.danger.primary;
      }

      case SUBSCRIPTION_STATUS.TO_DUE: {
        return theme.palette.warning.primary;
      }

      case SUBSCRIPTION_STATUS.EXPIRED:
      case SUBSCRIPTION_STATUS.OVERDUE:
      case SUBSCRIPTION_STATUS.SUSPENDED: {
        return theme.palette.error.primary;
      }

      default: {
        return theme.palette.grey.primary;
      }
    }
  };

  const getHeaderProps = () => {
    const defaultArgs = {
      testId: 'subscription-manage-handler-header',
      title: '...',
      description: '',
    };

    if (!hasCurrentItem) {
      return defaultArgs;
    }

    const {
      productName,
      domain,
    } = currentItem;

    const getPageTitle = () => {
      if (isDomain()) {
        return `${billingLocale.domainLabel} -`;
      }

      return domain ? `${productName} -` : productName;
    };

    return {
      ...defaultArgs,
      title: getPageTitle(),
      description: domain || '',
    };
  };


  const enabledPaymentInfo = () => {
    if (hasCurrentItem && enabledModalInSubscriptionManagerByUrl) {
      if (enabledInvoiceGeneration) {
        return false;
      }
    }
    return true;
  };

  const enableRenewalSubscriptionModal = () => {
    setModalById(Modais.ACTIVE_RENEWAL_SUBSCRIPTION);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modal = urlParams.get('modal');

    if (enabledModalInSubscriptionManagerByUrl && modal && currentItem.modal != null) {
      setShowRenewalSubscriptionModal(true);
      enableRenewalSubscriptionModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, setShowRenewalSubscriptionModal]);


  const getSubscriptionResumeProps = () => {
    const defaultProps = {
      loading: !hasCurrentItem,
      paymentCycleLabel: manageSubscriptionLocale.paymentCycleLabel,
      reactivationButtonLabel: manageSubscriptionLocale.reactivationButtonLabel,
      reactivationTooltipLabel: manageSubscriptionLocale.reactivationTooltipLabel,
      trustPeriodDescriptionLabel: manageSubscriptionLocale.trustPeriodDescriptionLabel,
      trustPeriodLabel: manageSubscriptionLocale.trustPeriodLabel,
      productNameLabel: manageSubscriptionLocale.productNameLabel,
      registerDateLabel: manageSubscriptionLocale.registerDateLabel,
      resumeTitleLabel: manageSubscriptionLocale.resumeTitleLabel,
      statusLabel: manageSubscriptionLocale.statusLabel,
      statusTextColor: theme.v1.color.white.primary,
      orderStatusLocale: manageSubscriptionLocale.orderUnderReview,
      paymentMethodsLocale,
    };

    if (!hasCurrentItem) {
      return defaultProps;
    }
    const {
      addonId,
      paymentCycle,
      productName,
      registerDate,
      substatus,
      trustReactivationAvailable,
      trustReactivationOnTheCycle,
      type,
      overrideDate,
      overrideSuspension,
      orderUnderReview,
      paymentMethod,
    } = currentItem;


    const transformPaymentCycle = () => paymentCycleLocale[paymentCycle.replace(/\s+|-+/, '')];

    const canShowPaymentCycle = (status = getStatus()) => hasRenewalActive() && ![
      SUBSCRIPTION_STATUS.CANCELLED,
      SUBSCRIPTION_STATUS.EXPIRED_CANCELLED,
      SUBSCRIPTION_STATUS.FRAUD_CANCELLED,
      SUBSCRIPTION_STATUS.TERMINATED_CANCELLED,
    ].includes(status);

    return {
      ...defaultProps,
      paymentCycleText: transformPaymentCycle(),
      productNameText: isDomain() ? billingLocale.domainLabel : productName,
      registerDateText: format(parseISO(registerDate), 'dd/MM/yyyy'),
      showPaymentCycle: canShowPaymentCycle(),
      statusBackgroundColor: getBackgroundStatusColor(),
      statusText: statusLocale[`${substatus}StatusLabel`],
      onDisplayReactivationModal,
      overrideDate: overrideDate && formatDate(overrideDate, true),
      trustReactivationAvailable,
      overrideSuspension,
      trustReactivationOnTheCycle,
      alreadyReactivatedTooltip: manageSubscriptionLocale.alreadyReactivatedTooltip,
      shouldRenderAlreadyReactivatedTooltip: substatus === 'suspended',
      enabledPaymentInfo: enabledPaymentInfo(),
      orderUnderReview,
      summary,
      substatus,
      paymentMethod: paymentMethodsLocale[paymentMethod],
      registerDate,
      isEcommercePlus: type === 'addon' && addonId === 14,
      ecommercePlusChangeCycleLabel: ecommercePlusLocale && ecommercePlusLocale.changeCycleLabel,
      enabledEcommercePlusCycleChange,
      onEcommercePlusCycleChangeClick,
    };
  };

  const isBoleto = paymentMethod => LIST_BOLETO_METHODS.includes(paymentMethod);

  const isPix = paymentMethod => [PIX].includes(paymentMethod);

  const isCreditCard = paymentMethod => [
    BRASPAG_PARC_10X,
    BRASPAG_PARC_11X,
    BRASPAG_PARC_12X,
    BRASPAG_PARC_1X,
    BRASPAG_PARC_2X,
    BRASPAG_PARC_3X,
    BRASPAG_PARC_4X,
    BRASPAG_PARC_5X,
    BRASPAG_PARC_6X,
    BRASPAG_PARC_7X,
    BRASPAG_PARC_8X,
    BRASPAG_PARC_9X,
    CREDIT_CARD_ES,
  ].includes(paymentMethod);

  const isPaypal = paymentMethod => [
    GATORPAY_PAYPAL,
    PAYPAL,
  ].includes(paymentMethod);


  const getDescriptionAlert = () => {
    if (!enabledPaymentInfo()) {
      return manageSubscriptionActiveRenewalModalLocale.descriptonConfirmationAlertlabel;
    }
    return (isPix(currentItem.paymentMethod) || isBoleto(currentItem.paymentMethod)) ? manageSubscriptionActiveRenewalModalLocale.descriptionPixBoletoAlertInfoLabel : manageSubscriptionActiveRenewalModalLocale.descriptionAlertInfoLabel;
  };

  const getSubscriptionRenewalDataProps = () => {
    const onDisplayModalPayment = () => {
      setHasQRCodeGenerated(false);
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    };

    const defaultProps = {
      installmentsAmountLabel: manageSubscriptionLocale.installmentsAmountLabel,
      installmentsPaymentFormTooltipText: enabledNewLabelsWithPix && isBRServer ? manageSubscriptionLocale.installmentsPaymentFormTooltipWithPixText : manageSubscriptionLocale.installmentsPaymentFormTooltipText,
      loading: !hasCurrentItem,
      nextRenovationDateLabel: manageSubscriptionLocale.nextRenovationDateLabel,
      paymentMethodLabel: manageSubscriptionLocale.paymentMethodLabel,
      paymentMethodTooltipText: manageSubscriptionLocale.paymentMethodTooltipText,
      renewalDataTitleLabel: manageSubscriptionLocale.renewalDataTitleLabel,
      renovationAmountLabel: manageSubscriptionLocale.renovationAmountLabel,
      renovationInfoText: hasDraftInvoice ? manageSubscriptionLocale.renovationDraftInvoiceInfoLabel : manageSubscriptionLocale.renovationInfoLabel,
      enabledPaymentInfo: enabledPaymentInfo(),
      enabledDiscountRetention,
      onDisplayModalPayment,
      paymantLink: manageSubscriptionLocale.payInvoice,
    };

    if (!hasCurrentItem) {
      return defaultProps;
    }

    const {
      amount: renovationAmount,
      dueDate,
      installments,
      paymentMethod,
      discount,
      paymentCycle,
    } = currentItem;

    const transformPaymentCycle = () => paymentCycleLocale[paymentCycle.replace(/\s+|-+/, '')];

    const transformPaymentMethodLabel = () => {
      if (isCreditCard(paymentMethod)) {
        return paymentMethodsLocale.creditCard;
      }

      if (isPaypal(paymentMethod)) {
        return paymentMethodsLocale.paypal;
      }

      if (isPix(paymentMethod)) {
        return paymentMethodsLocale.pix;
      }

      return paymentMethodsLocale.ticket;
    };

    return {
      ...defaultProps,
      ...(installments && {
      }),
      nextRenovationDateText: format(subDays(new Date(`${dueDate} 00:00:00`), 1), 'dd/MM/yyyy'),
      paymentMethodText: transformPaymentMethodLabel(),
      renovationAmountText: formatCurrency(renovationAmount),
      discount,
      paymentCycleText: transformPaymentCycle(),
      paymentCycleLabel: manageSubscriptionLocale.paymentCycleLabel,
    };
  };

  useEffect(() => {
    if (currentItem.dueDate !== undefined) { setDueDateFormated(format(new Date(`${currentItem.dueDate} 00:00:00`), 'dd/MM/yyyy')); }
  }, [currentItem]);

  const canShowBackupLink = () => {
    if (currentItem && currentItem.showFilesExclusionText !== undefined) {
      return currentItem.showFilesExclusionText;
    }
    return subscriptionType !== 'addon' && subscriptionType !== 'domain';
  };

  const onPaymentSuccessPix = () => {
    setModalById(Modais.ACTIVE_PAYMENT_SUCCESS_PIX_MODAL);
    requestDiagnostic('manageSubscriptionNewHandler');
  };

  const onPlanButtonClick = () => {
    subscriptionAnalytics.changePlanClick('1_link_alterar_plano');
    window.open(`${WHMCS_URL}/index.php?m=custom_upgrade&action=selectupgrade&id=${subscriptionId}`, '_blank');
  };

  const getSubscriptionControlsProps = () => {
    const defaultProps = {
      onDisplayModalPayment,
      activeSubscriptionRenewalButtonLabel: manageSubscriptionLocale.activeSubscriptionRenewalButtonLabel,
      cancelSubscriptionButtonLabel: manageSubscriptionLocale.cancelSubscriptionButtonLabel,
      cancelSubscriptionImmediatelyButtonLabel: manageSubscriptionLocale.cancelSubscriptionImmediatelyButtonLabel,
      switchPaymentMethodButtonLabel: manageSubscriptionLocale.switchPaymentMethodButtonLabel,
      payInvoiceButtonLabel: manageSubscriptionLocale.payInvoice,
      loading: !hasCurrentItem,
      showPlanButton: canShowPlanButton,
      planButtonLabel: manageSubscriptionLocale.planButtonLabel,
      onPlanButtonClick,
    };

    if (!hasCurrentItem) {
      return defaultProps;
    }

    const onActiveSubscriptionRenewalClick = () => {
      setModalById(Modais.ACTIVE_RENEWAL_SUBSCRIPTION);
    };

    const onSwitchPaymentMethodClick = () => {
      setModalById(Modais.ACTIVE_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION);
      subscriptionAnalytics.changePaymentMethodClick('1_link_alterar_pagamento');
    };


    const onCancelSubscriptionClick = () => {
      setModalById(Modais.CANCEL_SUBSCRIPTION);

      subscriptionAnalytics.cancelSubscriptionClick('1_link_cancelar_assinatura');
    };

    return {
      ...defaultProps,
      loading: !hasCurrentItem,
      invoiceId: currentItem.invoiceId,
      onActiveSubscriptionRenewalClick,
      onCancelSubscriptionClick,
      onCancelSubscriptionImmediatelyClick,
      onSwitchPaymentMethodClick,
      showActiveSubscriptionRenewalButton: !hasRenewalActive(),
      showCancelSubscriptionButton: hasRenewalActive() && getStatus() !== SUBSCRIPTION_STATUS.PENDING_PAYMENT,
      showCancelSubscriptionImmediatelyButton: !hasRenewalActive() && !isDomain(),
      showSwitchPaymentMethodButton: canShowSwitchPaymentMethodButton(),
      showPaymentInvoceButton: canShowPaymentInvoceButton(),
    };
  };

  const getPathRetention = (retentionType) => {
    const isAbleToDiscount = enabledDiscountRetentionNewFlux && subscription && subscription.reactivation[0] !== undefined;

    if (isAbleToDiscount) {
      const retentionTypeRouteParam = retentionType === CancelOptions.CANCEL_RENEWAL ? routesLocale.cancelRenewal : routesLocale.cancelSubscription;

      if (retentionPageVersion === PAGE_RETENTION_VERSIONS.OLD) {
        return `${routesLocale.manageSubscription}${routesLocale.retentionBenefit}${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
      }
      if (retentionPageVersion === PAGE_RETENTION_VERSIONS.NEW) {
        if (enabledDiscountRetentionCancelImmediate && retentionType === CancelOptions.CANCEL_RENEWAL) {
          return `${routesLocale.manageSubscription}${routesLocale.retentionSubscriptionOffer}${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
        } if (enabledDiscountRetentionCancelRenewal && retentionType === CancelOptions.CANCEL_IMMEDIATE) {
          return `${routesLocale.manageSubscription}${routesLocale.retentionSubscriptionOffer}${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
        }
      } else {
        return `${routesLocale.manageSubscription}${routesLocale.retentionBenefit}${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
      }
    }
  };


  const getSubscriptionCancelModalProps = () => {
    const cancelRenewalPath = retentionFlux
      ? getPathWithRetention.cancelRenewalPath
      : `${manageSubscription}${cancelRenewal}/${subscriptionId}/${subscriptionType}`;

    const cancelImmediatePath = retentionFlux
      ? getPathWithRetention.cancelImmediatePath
      : `${manageSubscription}${cancelSubscription}/${subscriptionId}/${subscriptionType}`;


    const cancelPaths = {
      CANCEL_RENEWAL: cancelRenewalPath,
      CANCEL_IMMEDIATE: cancelImmediatePath,
    };

    const onCloseClick = () => {
      setModalById(Modais.NONE);
      subscriptionAnalytics.cancelSubscriptionClick('2_opcao_desistir');
      if (triage.subscriptionToCancel) {
        dispatch(preChatActions.preChat.setOpen(true));
        dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
      }
    };

    const onQuitButtonClick = () => {
      setModalById(Modais.NONE);
      subscriptionAnalytics.cancelSubscriptionClick('2_opcao_desistir');
      if (triage.subscriptionToCancel) {
        dispatch(preChatActions.preChat.setOpen(true));
        dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
      }
    };

    const onContinueButtonClick = (event, option) => {
      const isAbleToDiscount = enabledDiscountRetentionNewFlux && subscription && subscription.reactivation && subscription.reactivation[0];
      const pathRedirection = !!isAbleToDiscount && !retentionFlux ? getPathRetention(option) : cancelPaths[option];
      redirectTo(pathRedirection);
      subscriptionAnalytics.cancelSubscriptionClick('2_opcao_continuar');
    };

    const isNotDomainType = subscriptionType !== 'domain';

    return {
      cancelRenewalDescriptionLabel: manageSubscriptionCancelModalLocale.cancelRenewalDescriptionLabel,
      cancelRenewalLabel: manageSubscriptionCancelModalLocale.cancelRenewalLabel,
      cancelImmediateDescriptionLabel: manageSubscriptionCancelModalLocale.cancelImmediateDescriptionLabel,
      cancelImmediateLabel: manageSubscriptionCancelModalLocale.cancelImmediateLabel,
      continueButtonLabel: manageSubscriptionCancelModalLocale.continueButtonLabel,
      descriptionLabel: manageSubscriptionCancelModalLocale.descriptionLabel,
      quitButtonLabel: manageSubscriptionCancelModalLocale.quitButtonLabel,
      titleLabel: manageSubscriptionCancelModalLocale.titleLabel,
      onCloseClick,
      onContinueButtonClick,
      onQuitButtonClick,
      canShowCancelImmediateButton: isNotDomainType,
      canShowCancelRenewalOption: subscriptionType !== 'addon',
    };
  };

  const getSubscriptionActivateRenewalModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    const onConfirmButtonClick = () => {
      if (enabledModalInSubscriptionManagerByUrl && enabledInvoiceGeneration) {
        setModalById(Modais.ACTIVE_INVOICE_GENERATOR_MODAL);
      } else {
        setModalById(Modais.NONE);
      }
      SubscriptionsFunctions.handleActivateSubscription(currentItem, enabledModalInSubscriptionManagerByUrl ? setModalById : null);
      SubscriptionsFunctions.handleOnModalClose();
    };

    const isNotDomainType = subscriptionType !== 'domain';

    return {
      descriptionAlertInfoLabel: getDescriptionAlert(),
      confirmButtonLabel: manageSubscriptionActiveRenewalModalLocale.confirmButtonLabel,
      titleLabel: manageSubscriptionActiveRenewalModalLocale.titleLabel,
      onCloseClick,
      onConfirmButtonClick,
      origin: showRenewalSubscriptionModal ? 'url' : 'subscription',
      canShowCancelImmediateButton: isNotDomainType,
      productNameText: isDomain() ? billingLocale.domainLabel : currentItem.productName,
      from: manageSubscriptionActiveRenewalModalLocale.from,
      to: manageSubscriptionActiveRenewalModalLocale.to,
      discountText: manageSubscriptionActiveRenewalModalLocale.discount,
      note: manageSubscriptionActiveRenewalModalLocale.note,
      enabledDiscountOfferBanner,
      ...getSubscriptionRenewalDataProps(),
    };
  };

  const getGenerateErrorPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    const onSwitchPaymentMethod = () => {
      setHasQRCodeGenerated(false);
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code || code !== '') && (qrCode || qrCode !== '')) {
        setPixCodeGenerated(code);
        setQRCodeGenerated(qrCode);
        setHasQRCodeGenerated(true);
        setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
      } else {
        setHasQRCodeGenerated(false);
      }
    };

    return {
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onPaymentSuccessPix,
      onCloseClick,
      invoiceId: currentItem.invoiceId,
      originCalling,
    };
  };

  const getExpiredPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    const onSwitchPaymentMethod = () => {
      setHasQRCodeGenerated(false);
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code || code !== '') && (qrCode || qrCode !== '')) {
        setHasQRCodeGenerated(true);
        setPixCodeGenerated(code);
        setQRCodeGenerated(qrCode);
        setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
      } else {
        setHasQRCodeGenerated(false);
        setModalById(Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL);
      }
    };

    return {
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onCloseClick,
      onPaymentSuccessPix,
      originCalling,
      invoiceId: currentItem.invoiceId,
    };
  };

  const getPaymentSuccessPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      redirectTo(`${routesLocale.subscriptions}`);
    };

    return {
      onCloseClick,
      onPaymentSuccessPix,
      isManageSubscription: true,
      originCalling,
    };
  };

  const getPaymentModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    const onConfirmButtonClick = () => {
      SubscriptionsFunctions.handleActivateSubscription(currentItem);
      SubscriptionsFunctions.handleOnModalClose();
      setModalById(Modais.NONE);
    };

    const onFinishPixTimer = () => {
      setModalById(Modais.ACTIVE_EXPIRED_TIME_PIX_MODAL);
    };

    const onGenerateErrorPix = () => {
      setModalById(Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL);
    };

    const onRedirectTicket = (urlTicket, isOriginSavePDF = false) => {
      setUrlTicket(urlTicket);
      setOriginSavePDF(isOriginSavePDF);
      setModalById(Modais.ACTIVE_REDIRECT_TICKET_MODAL);
    };

    const isNotDomainType = subscriptionType !== 'domain';

    return {
      enabledNewPixChipLabel,
      onCloseClick,
      invoiceId: currentItem.invoiceId,
      onConfirmButtonClick,
      originCalling,
      canShowCancelImmediateButton: isNotDomainType,
      productNameText: isDomain() ? billingLocale.domainLabel : currentItem.productName,
      ...getSubscriptionRenewalDataProps(),
      ...getSubscriptionResumeProps(),
      dueDate: dueDateFormated,
      onFinishPixTimer,
      onGenerateErrorPix,
      onPaymentSuccessPix,
      onRedirectTicket,
    };
  };

  const getFinishSwitchPaymentMethodModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      subscriptionAnalytics.changePaymentMethodClick('4_fechar');

      if (triage.subscriptionToChangePaymentMethod) {
        dispatch(preChatActions.subscription.setSubscriptionToChangePaymentMethod(null));
        dispatch(preChatActions.preChat.setOpen(true));
      }
    };

    const onBack = () => {
      history.push(routesLocale.subscriptions);
      subscriptionAnalytics.changePaymentMethodClick('4_voltar_gerenciamento');

      if (triage.subscriptionToChangePaymentMethod) {
        dispatch(preChatActions.subscription.setSubscriptionToChangePaymentMethod(null));
        dispatch(preChatActions.preChat.setOpen(true));
      }
    };

    return {
      titleLabel: manageSubscriptionFinishSwitchPaymentMethodInvoiceLocale.titleLabel,
      infoSwitchPaymentMethodLabel: manageSubscriptionFinishSwitchPaymentMethodInvoiceLocale.infoSwitchPaymentMethod,
      buttonBackLabel: manageSubscriptionFinishSwitchPaymentMethodInvoiceLocale.backButtonLabel,
      onBack,
      newPaymentMethod,
      onCloseClick,

    };
  };

  const clearSwitchPaymentMethodStatus = useCallback(() => {
    dispatch(billingActions.subscriptions.switchPaymentMethod.clearStatus());
  }, [dispatch]);

  useEffect(() => { clearSwitchPaymentMethodStatus(); }, [clearSwitchPaymentMethodStatus]);

  const setChangePaymentSuccessModalStep = useCallback(() => {
    const isSuccess = switchPaymentMethodStatus === 'success';

    const changeToCreditCard = newPaymentMethod.includes('credit') || newPaymentMethod.includes('dlocal') || newPaymentMethod.includes('crédit') || newPaymentMethod.includes('tarjeta');

    if (!changePaymentMethodLoading && canDisplayChangePaymentModal && !!newPaymentMethod && changeToCreditCard && isSuccess) {
      setModalById(Modais.CHANGE_PAYMENT_METHOD);
      return;
    }

    if (!changePaymentMethodLoading && !!newPaymentMethod && isSuccess) {
      setModalById(Modais.ACTIVE_FINISH_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION);
      return;
    }

    if (!changePaymentMethodLoading && triage.subscriptionToChangePaymentMethod) {
      dispatch(preChatActions.subscription.setSubscriptionToChangePaymentMethod(null));
      dispatch(preChatActions.preChat.setOpen(true));
    }
    if (!changePaymentMethodLoading && !!newPaymentMethod) {
      setModalById(Modais.NONE);
    }
  }, [changePaymentMethodLoading, canDisplayChangePaymentModal, switchPaymentMethodStatus, newPaymentMethod, dispatch]);

  useEffect(() => { setChangePaymentSuccessModalStep(); }, [setChangePaymentSuccessModalStep]);

  const getSwitchPaymentMethodModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);

      if (triage.subscriptionToChangePaymentMethod) {
        dispatch(preChatActions.subscription.setSubscriptionToChangePaymentMethod(null));
        dispatch(preChatActions.preChat.setOpen(true));
      }
    };

    const onSuccess = () => {
      enabledChangePaymentMethodModal && getBoletoAndPixProducts();
    };

    const onSwitchPaymentMethod = (paymentMethod) => {
      if (paymentMethod !== '') {
        setNewPaymentMethod(paymentMethod);
        SubscriptionsFunctions.handleSwitchPaymentMethod(currentItem, paymentMethod, onSuccess);
      }
    };

    const isNotDomainType = subscriptionType !== 'domain';
    return {
      canDisplayChangePaymentModal,
      enabledChangePayPalAccount,
      enabledPaymentByPayPal,
      enabledPaymentByCreditCard,
      enabledPaymentByTicket,
      enabledPaymentByPix,
      enabledNewPixChipLabel,
      boletoAndPixLoading: subscriptionsState.loadingItemsNotInCreditCard,
      requestsLoading: changePaymentMethodLoading,
      titleLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.titleLabel,
      selectPaymentMethodLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.selectPaymentMethodLabel,
      infoChangePaymentByTicketLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.infoChangePaymentByTicketLabel,
      infoChangePaymentByCreditCardLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.infoChangePaymentByCreditCardLabel,
      infoChangePaymentByPayPalLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.infoChangePaymentByPayPalLabel,
      confirmSwitchLabel: manageSubscriptionSwitchPaymentMethodInvoiceModalLocale.confirmSwitchLabel,
      onSwitchPaymentMethod,
      descriptionAlertInfoLabel: getDescriptionAlert(),
      generateTicketLabel: manageSubscriptionPaymentInvoiceModalLocale.generateTicketLabel,
      infoPaymentByPicPayLabel: manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByPicPayLabel,
      infoPaymentByCreditCardLabel: manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByCreditCardLabel,
      infoPaymentByCreditCardTitle: manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByCreditCardTitle,
      selectCardLabel: manageSubscriptionPaymentInvoiceModalLocale.selectCardLabel,
      infoAddNewCreditCardLabel: manageSubscriptionPaymentInvoiceModalLocale.infoAddNewCreditCardLabel,
      infoSendEmailWithPix: manageSubscriptionPaymentInvoiceModalLocale.infoSendEmailWithPix,
      onCloseClick,
      actualPaymentMethod: currentItem.paymentMethod,
      invoiceId: currentItem.invoiceId,
      subscriptionId: currentItem.id,
      subscriptionType: currentItem.type,
      canShowCancelImmediateButton: isNotDomainType,
      productNameText: isDomain() ? billingLocale.domainLabel : currentItem.productName,
      ...getSubscriptionRenewalDataProps(),
      ...getSubscriptionResumeProps(),
    };
  };

  const getTicketModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    return {
      onCloseClick,
      urlTicket,
      hasOriginSavePDF,
    };
  };

  const getReactivationModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    return {
      onCloseClick,
      invoiceId: currentItem.invoiceId,
      paymentMethod: currentItem.paymentMethod,
    };
  };

  const getReactivationConfirmationModalProps = () => {
    const onClose = () => {
      setModalById(Modais.NONE);
    };

    return {
      onClose,
      invoiceId: currentItem.invoiceId,
      paymentMethod: currentItem.paymentMethod,
      description: reactivationConfirmationModalLocale.descriptionLabel,
      title: reactivationConfirmationModalLocale.titleLabel,
      clouseLabel: reactivationConfirmationModalLocale.clouseButtonLabel,
    };
  };

  const getInvoiceGeneratorModalProps = () => (
    {
      description: invoiceGeneratorModalLocale.descriptionLabel,
      title: invoiceGeneratorModalLocale.titleLabel,
    }
  );

  const getChangePaymentMethodModalProps = () => {
    const hideModal = () => {
      setModalById(Modais.NONE);
      clearSwitchPaymentMethodStatus();
    };

    const redirectToSubscriptionsList = () => {
      history.push(routesLocale.subscriptions);
    };

    const defaultProps = {
      origin: FLOW_ORIGIN.MANAGE_SUBSCRIPTION,
      hideModal,
      cancelFlowAction: redirectToSubscriptionsList,
      productsList: subscriptionsState.itemsNotInCreditcard,
      loadingChangePaymentMethod: billingState.subscriptions.loadingUpdateItemsNotInCreditCard,
      statusChangePaymentMethod: billingState.subscriptions.updateItemsNotInCreditCardStatus,
      step: MODAL_STEPS.SUCCESS_CC_REGISTER,
    };

    return {
      ...defaultProps,
    };
  };

  const getRefundFeedbackProps = () => {
    const defaultProps = {
      titleLabel: refundFeedbackLocale.titleLabel,
      statusLabel: refundFeedbackLocale.statusLabel,
      valueLabel: refundFeedbackLocale.valueLabel,
      methodLabel: refundFeedbackLocale.methodLabel,
    };

    if (!currentItem.refundFeedback) {
      return defaultProps;
    }

    const {
      amount,
      message,
      method,
      status,
    } = currentItem.refundFeedback;

    const verifyMethod = method === BRASPAG_PARC_1X ? BRASPAG_CREDIT : method;

    const getStatus = () => EnumFeedbackStatus[status || 'ERROR'];

    return {
      ...defaultProps,
      status: getStatus(),
      statusText: refundFeedbackLocale.statusText[getStatus()],
      valueText: formatCurrency(amount || 0),
      methodText: refundFeedbackLocale.methodText(verifyMethod),
      messageText: message,
    };
  };

  const isActive = () => getStatus() && getStatus().includes(SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.REGISTERED);

  const isFailPaymentCC = () => hasCurrentItem && currentItem.unpaidInvoice && currentItem.unpaidInvoice.ccPaymentAttemptFailure;

  const isNextToDueDate = () => getStatus() && getStatus().includes(SUBSCRIPTION_STATUS.TO_DUE);

  const isOverDueDate = () => getStatus() && getStatus().includes(SUBSCRIPTION_STATUS.OVERDUE);

  const isSuspended = () => getStatus() && getStatus().includes(SUBSCRIPTION_STATUS.SUSPENDED);

  const isPendingPayment = () => getStatus() && getStatus().includes(SUBSCRIPTION_STATUS.PENDING_PAYMENT);

  const isExceedsRetriesCC = () => hasCurrentItem && !isOverDueDate() && canShowControls() && currentItem.unpaidInvoice && currentItem.isExceedsRetriesCC;

  const displayButtonToModal = linkText => (
    <Button
      variant="link"
      size="medium"
      align="left"
      onClick={onDisplayModalPayment}
      label={linkText || manageSubscriptionLocale.nextDueAlertLabel[1]}
      testId="link-invoice-next-almost-due-date"
    />
  );

  const displayLinkToPay = linkText => (
    <>
      {displayButtonToModal(linkText)}
    </>
  );

  const getDaysToCancel = () => {
    switch (currentItem.type) {
      case 'hosting':
        return 10;
      case 'domain':
        return 30;
      default:
        return 10;
    }
  };

  const getDaysToExpiration = () => {
    const daysToCancel = getDaysToCancel();
    const today = new Date();
    const daysAfterOverdue = differenceInCalendarDays(today, parseISO(currentItem.dueDate));
    const daysToExpiration = daysToCancel - daysAfterOverdue;

    return daysToExpiration;
  };

  const getAlertFailPaymentCC = () => (
    <Styles.Info>
      <Styles.ProblemIcon>
        <IconError
          size="26"
          testId="over-due-date-icon-attention"
        />
      </Styles.ProblemIcon>
      <Styles.InfoText
        data-testid="info-text-alert-payment-failed"
      >
        {(!enabledNewLabelsWithPix || !isBRServer) && (
          <>
            <strong>
              {manageSubscriptionLocale.paymentFailAlertLabel[0]}
            </strong>
            {displayLinkToPay(manageSubscriptionLocale.paymentFailAlertLabel[1], 'link-invoice-next-over-due-date')}
            {manageSubscriptionLocale.paymentFailAlertLabel[2]}
            {getDaysToExpiration()}
            {manageSubscriptionLocale.paymentFailAlertLabel[3]}
            <br />
          </>
        )}
        {enabledNewLabelsWithPix && isBRServer && (
          <>
            <strong>{manageSubscriptionLocale.newPaymentFailAlertLabel[0]}</strong>
            {manageSubscriptionLocale.newPaymentFailAlertLabel[1]}
            {manageSubscriptionLocale.newPaymentFailAlertLabel[2]}
            {manageSubscriptionLocale.newPaymentFailAlertLabel[3]}
            {manageSubscriptionLocale.newPaymentFailAlertLabel[4]}
          </>
        )}
      </Styles.InfoText>
    </Styles.Info>
  );

  const getAlertPaymentFailByAttemptsLimit = () => (
    <Styles.Info>
      <Styles.ProblemIcon>
        <IconError
          size="26"
          testId="over-due-date-icon-attention"
        />
      </Styles.ProblemIcon>
      <Styles.InfoText
        data-testid="info-text-alert-payment-failed-by-attempts"
      >
        {(isBRServer) && (
          <>
            <strong>
              {manageSubscriptionLocale.paymentFailByAttemptsLimitLabel[0]}
            </strong>
            {displayLinkToPay(manageSubscriptionLocale.paymentFailAlertLabel[1], 'link-invoice-next-over-due-date')}
            {manageSubscriptionLocale.paymentFailByAttemptsLimitLabel[2]}
            {manageSubscriptionLocale.paymentFailByAttemptsLimitLabel[3]}
            <br />
          </>
        )}
      </Styles.InfoText>
    </Styles.Info>
  );

  const getAlertNextDueDate = () => (
    <Styles.Info>
      <Styles.InfoIcon variant={!isPaypal(currentItem.paymentMethod) && !isCreditCard(currentItem.paymentMethod) ? 'default' : 'blue'}>
        <IconError
          size="26"
          testId="next-due-date-icon-attention"
        />
      </Styles.InfoIcon>
      <Styles.InfoText
        data-testid="info-text-alert-next-due-date-payment"
      >
        {(!enabledNewLabelsWithPix || !isBRServer) && (
          <>
            {manageSubscriptionLocale.nextDueAlertLabel[0]}
            {canShowPaymentInvoceButton() ? manageSubscriptionLocale.nextDueAlertLabel[1] : displayLinkToPay(manageSubscriptionLocale.nextDueAlertLabel[1], 'link-invoice-next-almost-due-date')}
            {manageSubscriptionLocale.nextDueAlertLabel[2]}
            <br />
          </>
        )}
        {enabledNewLabelsWithPix && isBRServer && (isBoleto(currentItem.paymentMethod) || isPix(currentItem.paymentMethod)) && (
          <>
            {manageSubscriptionLocale.newNextDueAlertLabel[0]}
            {manageSubscriptionLocale.newNextDueAlertLabel[1]}
            <strong>{manageSubscriptionLocale.newNextDueAlertLabel[2]}</strong>
            {manageSubscriptionLocale.newNextDueAlertLabel[3]}
            <strong>{manageSubscriptionLocale.newNextDueAlertLabel[4]}</strong>
          </>
        )}
        {isPaypal(currentItem.paymentMethod) && (
          <>
            {manageSubscriptionLocale.dueDatePaypalLabel}
          </>
        )
        }
        {isCreditCard(currentItem.paymentMethod) && (
          <>
            {manageSubscriptionLocale.dueDateCreditCardLabel}
          </>
        )
        }

      </Styles.InfoText>
    </Styles.Info>
  );

  const getAlertDueBaseMessage = () => {
    switch (currentItem.type) {
      case 'hosting': {
        if (currentItem.showFilesExclusionText) {
          return manageSubscriptionLocale.dueDateAlertLabel[0];
        }
        return manageSubscriptionLocale.dueDateAlertLabel[2];
      }
      case 'domain':
        return manageSubscriptionLocale.dueDateAlertLabel[1];
      default:
        return manageSubscriptionLocale.dueDateAlertLabel[2];
    }
  };

  const getAlerOverDueDate = () => (
    <Styles.Info>
      <Styles.ProblemIcon>
        <IconError
          size="26"
          testId="over-due-date-icon-attention"
        />
      </Styles.ProblemIcon>
      <Styles.InfoText
        data-testid="info-text-alert-over-due-date-payment"
      >

        {(!enabledNewLabelsWithPix || !isBRServer) && (
          <>
            <strong>
              {getAlertDueBaseMessage()}
              {getDaysToExpiration()}
              {manageSubscriptionLocale.dueDateAlertLabel[3]}
            </strong>
            {displayLinkToPay(manageSubscriptionLocale.dueDateAlertLabel[4], 'link-invoice-next-over-due-date')}
            {manageSubscriptionLocale.dueDateAlertLabel[5]}
          </>
        )}
        {enabledNewLabelsWithPix && isBRServer && (
          <>
            {getAlertDueBaseMessage()}
            {getDaysToExpiration()}
            {manageSubscriptionLocale.newDueDateAlertLabel[3]}
            {manageSubscriptionLocale.newDueDateAlertLabel[4]}
            <strong>{manageSubscriptionLocale.newDueDateAlertLabel[5]}</strong>
            {manageSubscriptionLocale.newDueDateAlertLabel[6]}
            <strong>{manageSubscriptionLocale.newDueDateAlertLabel[7]}</strong>
          </>
        )}
        <br />
      </Styles.InfoText>
    </Styles.Info>
  );

  const getAlertSuspedendBaseMessage = () => {
    switch (currentItem.type) {
      case 'hosting':
        return manageSubscriptionLocale.suspendedAlertLabel[0];
      case 'domain':
        return manageSubscriptionLocale.suspendedAlertLabel[1];
      default:
        return manageSubscriptionLocale.suspendedAlertLabel[2];
    }
  };

  const getAlerSuspended = () => (
    <Styles.Info>
      <Styles.ProblemIcon>
        <IconError
          size="26"
          testId="suspended-icon-attention"
        />
      </Styles.ProblemIcon>
      <Styles.InfoText
        data-testid="info-text-alert-suspended-payment"
      >
        {(!enabledNewLabelsWithPix || !isBRServer) && (
          <>
            {getAlertSuspedendBaseMessage()}
            {displayLinkToPay(manageSubscriptionLocale.suspendedAlertLabel[3])}
            <br />
            <strong>
              {manageSubscriptionLocale.suspendedAlertLabel[4]}
            </strong>
            <br />
          </>
        )}
        {enabledNewLabelsWithPix && isBRServer && (
          <>
            {manageSubscriptionLocale.newSuspendedAlertLabel[0]}
            <br />
            <strong>
              {manageSubscriptionLocale.newSuspendedAlertLabel[1]}
              {manageSubscriptionLocale.newSuspendedAlertLabel[2]}
              {manageSubscriptionLocale.newSuspendedAlertLabel[3]}
              {manageSubscriptionLocale.newSuspendedAlertLabel[4]}
            </strong>
          </>
        )}

      </Styles.InfoText>
    </Styles.Info>
  );

  const getAlertPendingPayment = () => (
    <Styles.Info>
      <Styles.WarningIcon>
        <IconError
          size="26"
          testId="pending-payment-icon-attention"
        />
      </Styles.WarningIcon>
      <Styles.InfoText
        data-testid="info-text-alert-pending-payment"
      >
        {(!enabledNewLabelsWithPix || !isBRServer) && (
          <>
            {manageSubscriptionLocale.pendingPaymentAlertLabel[0]}
            <br />
            {manageSubscriptionLocale.pendingPaymentAlertLabel[1]}
            {displayLinkToPay(manageSubscriptionLocale.pendingPaymentAlertLabel[2], 'link-invoice-pending-payment')}
            {manageSubscriptionLocale.pendingPaymentAlertLabel[3]}
          </>
        )}
        {enabledNewLabelsWithPix && isBRServer && (
          <>
            {manageSubscriptionLocale.newPendingPaymentAlertLabel[0]}
            <strong>{manageSubscriptionLocale.newPendingPaymentAlertLabel[1]}</strong>
            {manageSubscriptionLocale.newPendingPaymentAlertLabel[2]}
            <strong>{manageSubscriptionLocale.newPendingPaymentAlertLabel[3]}</strong>
            {manageSubscriptionLocale.newPendingPaymentAlertLabel[4]}
            <strong>{manageSubscriptionLocale.newPendingPaymentAlertLabel[5]}</strong>
          </>
        )}
      </Styles.InfoText>
    </Styles.Info>
  );


  return (
    <Styles.Wrapper data-testid="subscription-manage-handler">

      <Header {...getHeaderProps()} />

      <Styles.ContentWrapper>

        <SubscriptionResume {...getSubscriptionResumeProps()} />

        {canShowRenewalData() && (
          <>
            <Styles.Divisor />
            <Styles.SubscriptionRenewalDataWrapper>
              <SubscriptionRenewalData {...getSubscriptionRenewalDataProps()} />
            </Styles.SubscriptionRenewalDataWrapper>
          </>
        )}

        {isExceedsRetriesCC() && (
          getAlertPaymentFailByAttemptsLimit()
        )}

        {enabledPaymentErroInfoMessageByCreditCard && isFailPaymentCC() && !isExceedsRetriesCC() && (
          getAlertFailPaymentCC()
        )}

        <>
          {isNextToDueDate() && currentItem.invoiceId && !isFailPaymentCC() && !isExceedsRetriesCC() && (
            getAlertNextDueDate()
          )}

          {isOverDueDate() && currentItem.invoiceId && !isFailPaymentCC() && !isExceedsRetriesCC() && (
            getAlerOverDueDate()
          )}

          {isSuspended() && currentItem.invoiceId && !isFailPaymentCC() && !isExceedsRetriesCC() && (
            getAlerSuspended()
          )}

          {isPendingPayment() && currentItem.invoiceId && !isFailPaymentCC() && !isExceedsRetriesCC() && (
            getAlertPendingPayment()
          )}

        </>

        {!hasRenewalActive() && isActive() && (
          <Styles.Info>
            <Styles.InfoIcon>
              <IconError
                size="26"
                testId="deactivate-automatic-billing-modal-icon-attention"
              />
            </Styles.InfoIcon>
            <Styles.InfoText
              data-testid="desactive-automatic-auto-renew"
            >
              {manageSubscriptionLocale.noRenewAlertLabel[0]}
              <strong>
                {manageSubscriptionLocale.noRenewAlertLabel[1]}
                {dueDateFormated}
                .
              </strong>
              <br />
              {canShowBackupLink() && (
                <>
                  {manageSubscriptionLocale.noRenewAlertLabel[2]}
                  <a
                    data-testid="desactive-automatic-auto-renew-backup-link"
                    href={subscriptionsLocale.message.management.backupFilesLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {manageSubscriptionLocale.noRenewAlertLabel[3]}
                  </a>
                </>
              )}
            </Styles.InfoText>
          </Styles.Info>
        )}
        {canShowRefundFeedback() && (
          <Styles.Row borderBottom>
            <RefundFeedback {...getRefundFeedbackProps()} />
          </Styles.Row>
        )}

        {enabledDiscountOfferBanner && enabledDiscountOffer && (
          <Styles.DiscountWrapper>
            <DiscountOfferBanner handleAction={enableRenewalSubscriptionModal} origin="subscription" subscription={currentItem} />
          </Styles.DiscountWrapper>
        )}

        {canShowControls() && (
          <Styles.SubscriptionControlsWrapper>
            <SubscriptionControls {...getSubscriptionControlsProps()} />
          </Styles.SubscriptionControlsWrapper>
        )}

      </Styles.ContentWrapper>

      {canShowSubscriptionCancelModal() && (
        <SubscriptionCancelModal
          {...getSubscriptionCancelModalProps()}
        />
      )}

      {canShowSubscriptionRenewalModal() && (
        <SubscriptionActiveRenewalModal
          {...getSubscriptionActivateRenewalModalProps()}
        />
      )}

      {canShowPaymentModal() && (
        <PaymentModal
          {...getPaymentModalProps()}
          hasQRCodeGenerated={hasQRCodeGenerated}
          qrCodeGenerated={qrCodeGenerated}
          pixCodeGenerated={pixCodeGenerated}
          isExceedsRetriesCC={isExceedsRetriesCC()}
          renovationAmountText={formatCurrency(currentItem.unpaidInvoice !== undefined && currentItem.unpaidInvoice.totalInvoice !== undefined && currentItem.discount === null ? currentItem.unpaidInvoice.totalInvoice : currentItem.amount)}
        />
      )}

      {canShowSwitchPaymentMethodModal() && (
        <SwitchPaymentMethodModal
          {...getSwitchPaymentMethodModalProps()}
        />
      )}

      {canShowFinishSwitchPaymentMethodModal() && (
        <FinishSwitchPaymentMethodModal
          {...getFinishSwitchPaymentMethodModalProps()}
        />
      )}

      {canShowExpiredTimePixModal() && (
        <ExpiredTimePixModal
          {...getExpiredPixModalProps()}
        />
      )}

      {canShowPaymentSuccessPixModal() && (
        <PaymentSuccessPixModal
          {...getPaymentSuccessPixModalProps()}
        />
      )}

      {canShowGenerateErrorPixModal() && (
        <GenerateErrorPixModal {...getGenerateErrorPixModalProps()} />
      )}

      {canShowReactivationModal() && (
        <ReactivationModal {...getReactivationModalProps()} />
      )}

      {canShowTicketRedirectModal() && (
        <TicketRedirectModal
          {...getTicketModalProps()}
        />
      )}
      {canShowReactivationConfirmationModal() && (
        <ReactivationConfirmationModal {...getReactivationConfirmationModalProps()} />
      )}
      {canInvoiceGeneratorModal() && (
        <InvoiceGeneratorModal {...getInvoiceGeneratorModalProps()} />
      )}
      {canShowChangePaymentModal() && (
        <ChangePaymentModal {...getChangePaymentMethodModalProps()} />
      )}
    </Styles.Wrapper>
  );
};

ManageSubscriptionNewHandler.propTypes = ManageSubscriptionProps;

export default ManageSubscriptionNewHandler;
