const styles = theme => ({
  asideWrapper: {
    transformOrigin: 'top right',
    animation: 'opac 0.8s',
  },
  '@keyframes opac': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  wrapper: {
    boxShadow: 'none',
    margin: '12px 0 0 0',
    '&:first-child': {
      margin: 0,
    },
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: 'transparent',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    padding: '13px 23px 10px 23px',
    border: 'none',
    fontSize: 18,
    fontWeight: 500,
    color: theme.color.tudora,
    margin: 0,
  },
  summaryDone: {
    borderColor: theme.color.oceanGreen,
  },
  summaryContent: {
    margin: 0,
    '&$summaryExpanded': {
      margin: 0,
    },
  },
  summaryExpanded: {},
  summaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 5,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 11,
    marginTop: 3,
    '& svg': {
      color: theme.color.regentGray,
      fontSize: 14,
    },
    '& span': {
      display: 'inline-block',
      color: theme.color.regentGray,
      fontSize: 14,
      marginLeft: 5,
      fontWeight: 400,
    },
  },
  details: {
    paddingBottom: 0,
  },
  list: {
    listStyle: 'none',
    padding: '20px 0 0 0',
    margin: 0,
    width: '100%',
  },
  listItem: {
    position: 'relative',
    display: 'flex',
    marginBottom: 20,
  },
  progressWrapper: {
    marginRight: 10,
  },
  progressBall: {
    fontSize: 20,
    color: theme.color.regentGray,
  },
  progressBallDone: {
    color: theme.color.oceanGreen,
  },
  progressLine: {
    position: 'absolute',
    top: 16,
    left: 8,
    bottom: 0,
    width: 3,
    height: 'calc(100% + 8px)',
    backgroundColor: theme.color.regentGray,
  },
  progressLineDone: {
    backgroundColor: theme.color.oceanGreen,
  },
  listTitle: {
    color: theme.color.tundora,
  },
  wrapperVideoItem: {
    cursor: 'pointer',
  },
  selectedVideoItemAnimation: {
    animation: 'selectVideoNotation 0.15s linear',
    fontWeight: 500,
    transform: 'translateX(8px)',
  },
  selectedVideoItemRemoveAnimation: {
    animation: 'selectVideoNotationReverse 0.15s linear',
    fontWeight: 400,
    transform: 'translateX(0px)',
  },
  '@keyframes selectVideoNotation': {
    from: {
      transform: 'translateX(0px)',
      fontWeight: 400,
    },
    to: {
      transform: 'translateX(8px)',
      fontWeight: 500,
    },
  },

  '@keyframes selectVideoNotationReverse': {
    from: {
      transform: 'translateX(8px)',
      fontWeight: 500,
    },
    to: {
      transform: 'translateX(0px)',
      fontWeight: 400,
    },
  },
});

export default styles;
