const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },

  title: {
    color: theme.color.thundora,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 24,

    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      padding: '0 24px',
    },
  },
});
export default styles;
