import { CALL_API } from '@/middleware/api';
import { ALERTS_URL } from '@/config/api';
import { REQUEST_ALERTS, RECEIVE_ALERTS, ERROR_ALERTS } from './actionsTypes';


const receiveAlerts = (data) => {
  const alerts = data.filter(alert => alert.destination === 'Portal');
  return {
    type: RECEIVE_ALERTS,
    data: alerts,
  };
};

export const loadAlerts = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ALERTS_URL,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ALERTS }),
      success: data => receiveAlerts(data.data),
      error: () => ({ type: ERROR_ALERTS }),
    },
  },
});
