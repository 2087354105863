import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ disabled, display }) => css`
    position: relative;
    display: ${display === 'large' ? 'flex' : 'inline-flex'};

    &:after {
      display: ${disabled ? 'block' : 'none'};
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  `}
`;

export const Icon = styled.div`
  ${({ iconDirection }) => {
    const degs = {
      left: '0deg',
      top: '90deg',
      right: '180deg',
      bottom: '270deg',
    };

    return css`
      display: inline-flex;
      transform: rotate(${degs[iconDirection]});
      transition: transform 200ms ease;
    `;
  }}
`;

export const IconLeft = styled.div`
  ${({ iconDirection }) => {
    const degs = {
      left: '0deg',
      top: '90deg',
      right: '180deg',
      bottom: '270deg',
    };

    return css`
      display: inline-flex;
      transform: rotate(${degs[iconDirection]});
      transition: transform 200ms ease;
    `;
  }}
`;

export const IconRight = styled.div`
  ${({ iconDirection }) => {
    const degs = {
      left: '0deg',
      top: '90deg',
      right: '180deg',
      bottom: '270deg',
    };

    return css`
      display: inline-flex;
      transform: rotate(${degs[iconDirection]});
      transition: transform 200ms ease;
    `;
  }}
`;

export const Button = styled.button`
  ${({
    align,
    size,
    theme,
    variant,
    font,
  }) => {
    const variants = {
      default: {
        backgroundColor: theme.palette.white.light,
        borderColor: theme.palette.primary.main,
        borderRadius: '2px',
        color: theme.palette.primary.main,
        hoverBackgroundColor: theme.palette.primary.main,
        hoverBorderColor: theme.palette.primary.dark,
        hoverColor: theme.palette.white.light,
        whiteSpace: 'nowrap',
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      disabled: {
        backgroundColor: theme.palette.grey.primary,
        borderColor: theme.palette.grey.primary,
        borderRadius: '2px',
        color: theme.palette.grey.ultralight,
        hoverBackgroundColor: theme.palette.grey.primary,
        hoverBorderColor: theme.palette.grey.primary,
        hoverColor: theme.palette.grey.ultralight,
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      link: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '2px',
        color: theme.palette.primary.main,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.primary.dark,
        padding: '0',
        weight: theme.font.weights.regular,
        borderWidth: '1px',
      },
      action: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '2px',
        color: theme.palette.secondary.primary,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.blue.dark,
        padding: '11px 17px 11px 24px',
        weight: theme.font.weights.regular,
        borderWidth: '1px',
      },
      primary: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.secondary.dark,
        borderRadius: '2px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.primary.dark,
        hoverBorderColor: theme.palette.primary.dark,
        hoverColor: theme.palette.white.light,
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      yellow: {
        backgroundColor: theme.palette.yellow.main,
        borderColor: theme.palette.yellow.dark,
        borderRadius: '2px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.yellow.dark,
        hoverBorderColor: theme.palette.yellow.dark,
        hoverColor: theme.palette.white.light,
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      text: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '2px',
        color: theme.palette.primary.main,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.primary.dark,
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      darkHover: {
        backgroundColor: 'transparent',
        borderRadius: '2px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.secondary.dark,
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.white.light,
        whiteSpace: 'nowrap',
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      opacity: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.white.light,
        borderRadius: '2px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.white,
        hoverBorderColor: theme.palette.white.light,
        hoverColor: theme.palette.white.light,
        whiteSpace: 'nowrap',
        padding: '11px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      pill: {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        borderRadius: '19px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.primary.dark,
        hoverBorderColor: theme.palette.primary.dark,
        hoverColor: theme.palette.white.light,
        padding: '5px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      pillOutlined: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        borderRadius: '16px',
        color: theme.palette.primary.main,
        hoverBackgroundColor: theme.palette.primary.main,
        hoverBorderColor: theme.palette.primary.main,
        hoverColor: theme.palette.white.light,
        padding: '8px 12px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      secondaryPillOutlined: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.primary,
        borderRadius: '16px',
        color: theme.palette.secondary.primary,
        hoverBackgroundColor: theme.palette.blue.dark,
        hoverBorderColor: theme.palette.blue.dark,
        hoverColor: theme.palette.white.light,
        padding: '6px 16px',
        weight: theme.font.weights.regular,
        borderWidth: '1px',
      },
      bluePill: {
        backgroundColor: theme.color.vividBlue,
        borderColor: theme.color.vividBlue,
        borderRadius: '19px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.primary.light,
        hoverBorderColor: theme.palette.primary.light,
        hoverColor: theme.palette.white.light,
        padding: '5px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      bigBluePill: {
        backgroundColor: theme.color.vividBlue,
        borderColor: theme.color.vividBlue,
        borderRadius: '19px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.blue.dark,
        hoverBorderColor: theme.palette.blue.dark,
        hoverColor: theme.palette.white.light,
        padding: '9px 24px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
        disabledBackgroundColor: theme.palette.blue.ultralight,
        disabledBorderColor: theme.palette.blue.ultralight,
      },
      lightPill: {
        backgroundColor: theme.palette.white.light,
        borderColor: theme.palette.white.light,
        borderRadius: '19px',
        color: theme.palette.primary.light,
        hoverBackgroundColor: theme.palette.white.light,
        hoverBorderColor: theme.palette.white.light,
        hoverColor: theme.palette.primary.dark,
        padding: '5px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      darkBorderedPill: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.white.light,
        borderRadius: '20px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.palette.white.light,
        hoverBorderColor: theme.palette.white.light,
        hoverColor: theme.palette.grey.dark,
        padding: '9px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '2px',
      },
      lightBorderedPill: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.primary,
        borderRadius: '20px',
        color: theme.palette.secondary.primary,
        hoverBackgroundColor: theme.palette.secondary.primary,
        hoverBorderColor: theme.palette.secondary.primary,
        hoverColor: theme.palette.white.light,
        padding: '9px 16px',
        weight: theme.font.weights.medium,
        borderWidth: '2px',
      },
      secondaryText: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '2px',
        color: theme.palette.secondary.primary,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.secondary.primary,
        hoverTextDecoration: 'underline',
        padding: '0px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
      },
      newPrimary: {
        backgroundColor: theme.palette.secondary.primary,
        borderColor: theme.palette.secondary.primary,
        borderRadius: '19px',
        color: theme.palette.white.light,
        hoverBackgroundColor: theme.color.cyanCobaltBlue,
        hoverBorderColor: theme.color.cyanCobaltBlue,
        hoverColor: theme.palette.white.light,
        padding: '9px 24px',
        weight: theme.font.weights.medium,
        borderWidth: '1px',
        disabledBackgroundColor: theme.palette.blue.ultralight,
        disabledBorderColor: theme.palette.blue.ultralight,
      },
      newTertiary: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: theme.palette.secondary.primary,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.secondary.primary,
        hoverTextDecoration: 'underline',
        padding: '0',
        weight: theme.font.weights.medium,
        borderWidth: '0',
      },
    };

    const sizes = {
      small: {
        fontSize: theme.font.sizes.xxs,
      },
      medium: {
        fontSize: theme.font.sizes.sm,
      },
      large: {
        fontSize: theme.font.sizes.md,
      },
    };

    const aligns = {
      center: 'center',
      left: 'flex-start',
      right: 'flex-end',
    };

    return css`
      background-color: ${variants[variant].backgroundColor};
      border-radius: ${variants[variant].borderRadius};
      border: ${variants[variant].borderWidth} solid ${variants[variant].borderColor};
      color: ${variants[variant].color};
      cursor: pointer;
      display: flex;
      flex-direction: left;
      font-family:${theme.font.family[font]};
      font-size: ${sizes[size].fontSize};
      font-weight: ${variants[variant].weight};
      justify-content: ${aligns[align]};
      letter-spacing: 0.01px;
      padding: ${variants[variant].padding};
      text-align: ${align};
      text-decoration: ${variant === 'link' || variant === 'action' ? 'underline' : 'none'};
      transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
      white-space: ${variants[variant].whiteSpace};
      width: 100%;

      @media(max-width: ${theme.breakpoints.sm}){
        padding: ${variant === 'action' && '8px 0px'};
        justify-content: ${variant === 'action' && 'flex-start'};
      }

      &:hover {
        background-color: ${variants[variant].hoverBackgroundColor};
        border: ${variants[variant].borderWidth} solid ${variants[variant].hoverBorderColor};
        color: ${variants[variant].hoverColor};
        text-decoration:${variants[variant].hoverTextDecoration || undefined};
      }

      & ${IconLeft} {
        margin: 0 18px 0 0;

        svg {
          fill: ${variants[variant].color};
          transition-property: fill, opacity;
          transition-duration: 200ms ;
          transition-timing-function: ease-in-out;
          opacity: ${variant === 'newTertiary' ? '0' : '1'};
        }
      }

      & ${IconRight} {
        margin: 0 0 0 18px;

        svg {
          fill: ${variants[variant].color};
          transition-property: fill, opacity;
          transition-duration: 200ms;
          transition-timing-function: ease-in-out;
          opacity: ${variant === 'newTertiary' ? '0' : '1'};
        }
      }

      &:hover ${IconLeft},
      &:hover ${IconRight} {

        svg {
          fill: ${variants[variant].hoverColor};
          opacity: 1;
        }
      }

      &.disabled {
        background-color: ${variants[variant].disabledBackgroundColor || variants.disabled.backgroundColor};
        border: 1px solid ${variants[variant].disabledBorderColor || variants.disabled.borderColor};
        color: ${variants.disabled.color};

        &:hover {
          background-color: ${variants.disabled.hoverBackgroundColor};
          border: 1px solid ${variants.disabled.hoverBorderColor};
          color: ${variants.disabled.hoverColor};
        }
      }
    `;
  }
}
`;


export const Label = styled.span``;
