const styles = theme => ({
  status: {
    display: 'inline-block',
    height: 'fit-content',
    lineHeight: '20px',
    color: theme.color.white,
    fontSize: '12px',
    padding: '0 7px',
    borderRadius: 3,
    whiteSpace: 'nowrap',
  },
  statusActive: {
    backgroundColor: theme.color.oceanGreen,
  },
  statusPending: {
    backgroundColor: theme.color.brightSun,
  },
  statusPendingTransfer: {
    backgroundColor: theme.color.brightSun,
  },
  statusExpired: {
    backgroundColor: theme.color.grayLight,
  },
  statusCancelled: {
    backgroundColor: theme.color.geyser,
  },
  statusFraud: {
    backgroundColor: theme.color.brightSun,
  },
  statusSuspended: {
    backgroundColor: theme.color.valencia,
  },
  statusBuilding: {
    backgroundColor: theme.color.coral,
  },
  statusNotConfigured: {
    backgroundColor: theme.color.valencia,
  },
  statusOnConfigure: {
    backgroundColor: theme.color.brightSun,
  },
  statusInAnalysis: {
    backgroundColor: theme.color.brightSun,
  },
});

export default styles;
