export const FILE_EXTENSIONS_PERMITTED = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'pdf',
  'doc',
  'docx',
  'crt',
  'key',
];
