import styled, { css } from 'styled-components';

export const HostGatorOtherPlatformModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 16px 8px 16px 8px;
    @media (min-width: ${theme.breakpoints.sm}) {
      margin: 16px;
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeights.xxxxxl};
    margin: 0;
    max-width: 90%;
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: 14px;
    line-height: 19px;
    font-weight: ${theme.v1.font.weights.regular};
    margin: 0;

    p {
      margin-bottom: 16px;
    }

    p > span{
      color: ${theme.v1.color.text.featured};
      font-weight: ${theme.v1.font.weights.bold};
    }
  `}
`;

export const MoreInfo = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-weight: ${theme.v1.font.weights.bold};
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
      align-items: flex-start;
      display: flex;
      gap: 8px;
      margin-top: 16px;
      > p {
        color: ${theme.palette.grey.dark};
        font-family: ${theme.font.family.secondary};
        font-size: ${theme.font.sizes.xxs};
        font-weight: ${theme.font.weights.regular};
        line-height: ${theme.font.lineHeights.md};
        margin: 0;
        > span{
          display: block;
          font-size: ${theme.font.sizes.xxs};
          margin-top: 8px;
          width: 100%;
        }
      }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ position }) => css`
    align-self: ${position === 'left' ? 'flex-start' : 'center'} ;
  `}
`;

export const CheckboxWrapper = styled.div`
  ${({ theme }) => css`
      display: flex;
      align-items: flex-start;
      align-self: center;
      gap: 8px;
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${theme.font.sizes.xxs};
      line-height: ${theme.font.lineHeights.md};
      font-weight: ${theme.v1.font.weights.medium};

      > input {
        cursor: pointer;
        margin: 0;
        width: 41px;
        height: 24px;
        accent-color: ${theme.v1.color.action.hover};
      }

      @media (min-width: ${theme.v1.breakpoints.sm}) {
        align-items: center;

        > input {
          margin: 0;
          width: 24px;
          height: 24px;
        }
      }
  `}
`;
