import React, { useState, useEffect, useCallback } from 'react';
import { ProgressCircle } from '@/pages/common/ProgressCircle';
import { IconCheck } from '@/icons';
import * as Styles from './FeedbackStepper.styles';
import { FeedbackStepsStatus } from './FeedbackStepper.types';

const FeedbackStepper = ({
  testId = 'feedback-stepper',
  activeStep = 1,
  steps = [
    {
      id: 1,
      label: 'Answer Question',
    },
    {
      id: 2,
      label: 'Confirm Cancellation',
    },
  ],
  stepActiveColor,
  stepActiveBgColor,
  stepDoneColor,
  stepDoneBgColor,
}) => {
  const [feedbackActiveStep, setFeedbackActiveStep] = useState(null);
  const [feedbackSteps, setFeedbackSteps] = useState([]);
  const [currentFeedBackStep, setCurrentFeedBackStep] = useState(null);

  const changeActiveStep = useCallback((activeStep) => {
    const handledSteps = steps.map((step) => {
      let status = FeedbackStepsStatus.DISABLED;

      if (activeStep > step.id || step.markAsDone) {
        status = FeedbackStepsStatus.DONE;
      } else if (activeStep === step.id) {
        status = FeedbackStepsStatus.ACTIVE;
      }

      return {
        ...step,
        status,
      };
    });

    setFeedbackSteps(handledSteps);
  }, [steps]);

  useEffect(() => {
    if (activeStep !== feedbackActiveStep) {
      changeActiveStep(activeStep);
      setFeedbackActiveStep(activeStep);
    }
  }, [activeStep, changeActiveStep, feedbackActiveStep, feedbackSteps]);

  useEffect(() => {
    const isAllStepsDone = feedbackSteps.every(step => step.status === FeedbackStepsStatus.DONE);

    setCurrentFeedBackStep(
      isAllStepsDone
        ? feedbackSteps[feedbackSteps.length - 1]
        : feedbackSteps.find(step => step.status === FeedbackStepsStatus.ACTIVE),
    );
  }, [feedbackSteps]);

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.StepperDesktop>
        <Styles.Steps>
          {feedbackSteps.map(step => (
            <Styles.Step
              key={step.id}
              className={step.status}
              data-testid={`${testId}-step-${step.id}`}
              color={stepActiveColor}
              bgColor={stepActiveBgColor}
              stepDoneColor={stepDoneColor}
              stepDoneBgColor={stepDoneBgColor}
            >
              <Styles.StepCount>
                {
                  step.status === FeedbackStepsStatus.DONE ? (
                    <IconCheck size="20" />
                  ) : (
                    step.id
                  )
                }
              </Styles.StepCount>
              <Styles.StepLabel>{step.label}</Styles.StepLabel>
            </Styles.Step>
          ))}
        </Styles.Steps>
      </Styles.StepperDesktop>
      {currentFeedBackStep && (
        <Styles.StepperMobile>
          <Styles.Steps>
            <Styles.Step className={currentFeedBackStep.status}>
              <Styles.StepCounter>
                <ProgressCircle
                  current={currentFeedBackStep.id}
                  total={steps.length}
                  bgColor={stepActiveBgColor}
                />
                <Styles.StepCounterText>
                  {`${currentFeedBackStep.id} de ${feedbackSteps.length}`}
                </Styles.StepCounterText>
              </Styles.StepCounter>
              <Styles.StepLabel>{currentFeedBackStep.label}</Styles.StepLabel>
            </Styles.Step>
          </Styles.Steps>
        </Styles.StepperMobile>
      )}
    </Styles.Wrapper>
  );
};

export default FeedbackStepper;
