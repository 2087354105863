import React, { } from 'react';
import * as Styles from './RetentionBenefitHandler.styles';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { Button, Header } from '@/pages/common';
import { Button as ButtonV1 } from '@/pages/common/v1/Button';
import { IconArrowForward, IconFeaturedStar } from '@/icons';
import { Loader } from '@/pages/common/v1/Loader';
import { InvoiceModal } from '../../components';
import { isBRServer } from '@/utils/Validators/validation';
import useLocale from '@/hooks/useLocale';
import { useHistory } from 'react-router';

const RetentionBenefitHandler = ({
  currentItem = {},
  headerProps = {},
  colorText = '#4A4A4A',
  invoiceModalIsOpen = false,
  isRenewCanceling = false,
  invoiceIsLoading = false,
  enabledBenefitNewPrice = false,
  acceptBenefitClick,
  cancelButtonClick,
  closeInvoiceModal,
  redirectToInvoice,
  reactivationData = null,
}) => {
  const history = useHistory();
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { manageSubscriptionBenefit } = billingLocale;
  const benefitCycleInfos = currentItem && currentItem.paymentCycle;

  if (!reactivationData) {
    history.push(`${routesLocale.subscriptions}`);
    return (<></>);
  }

  const discountPercentage = reactivationData && reactivationData.percent;
  const currentPrice = formatCurrency(reactivationData.originalAmount);
  const futurePrice = formatCurrency(reactivationData.discountAmount);
  const isPageReady = !invoiceIsLoading;
  const disabledInstallments = isBRServer && reactivationData.installment.max > 1;

  const dataInstallment = () => ({
    price: formatCurrency(reactivationData.installment.discountValue),
    quantityInstallments: reactivationData.installment.max,
  });

  const currentMonthlyPrice = () => formatCurrency(reactivationData.installment.originalValue);
  const currentYearlyPrice = () => formatCurrency(reactivationData.originalAmount);

  const handleAcceptButtonClick = () => {
    acceptBenefitClick && acceptBenefitClick();
  };

  const handleCancelButtonClick = () => {
    cancelButtonClick && cancelButtonClick();
  };

  const isMonthlyPlan = benefitCycleInfos === 'Monthly';

  const benefitDescription = isMonthlyPlan
    ? manageSubscriptionBenefit.benefitDescriptionMonth
    : manageSubscriptionBenefit.benefitDescriptionYear;

  const benefitFuturePriceText = isMonthlyPlan
    ? manageSubscriptionBenefit.benefitFuturePriceTextMonth
    : manageSubscriptionBenefit.benefitFuturePriceTextYear;

  const benefitFinalText = isMonthlyPlan
    ? manageSubscriptionBenefit.benefitFinalTextMonth
    : manageSubscriptionBenefit.benefitFinalTextCycle;

  const isTotalPriceVisualization = !isMonthlyPlan && enabledBenefitNewPrice;

  const customButtonRadius = {
    borderRadius: '4px;',
  };

  const closeInvoiceModalHandler = () => {
    closeInvoiceModal && closeInvoiceModal();
  };

  const viewInvoiceGenerated = () => {
    redirectToInvoice && redirectToInvoice();
  };

  return (
    <Styles.Wrapper data-testid="retention-benefit-handler">
      <Header {...headerProps} />
      <Styles.ContentWrapper>
        {!isPageReady && <Loader />}
        {isPageReady && (
          <>
            <Styles.IconContent>
              <IconFeaturedStar />
            </Styles.IconContent>

            <Styles.BenefitTitle>
              {manageSubscriptionBenefit.benefitTitle}
            </Styles.BenefitTitle>

            <Styles.BenefitDescription>
              {benefitDescription}
            </Styles.BenefitDescription>

            <Styles.PricesContent>
              <Styles.CurrentPriceWrapper>
                <Styles.CurrentPriceContent>
                  {!isTotalPriceVisualization && (
                    <>
                      <Styles.CurrentPriceCycleText>
                        {manageSubscriptionBenefit.benefitCurrentPriceText(benefitCycleInfos)}
                      </Styles.CurrentPriceCycleText>
                      <Styles.CurrentPriceMonth>
                        {manageSubscriptionBenefit.benefitCurrentPriceCycleMonth(currentMonthlyPrice())}
                      </Styles.CurrentPriceMonth>
                      <Styles.CurrentPriceYear>
                        {manageSubscriptionBenefit.benefitCurrentPriceCycleYear(currentYearlyPrice())}
                      </Styles.CurrentPriceYear>
                    </>
                  )}
                  {isTotalPriceVisualization && (
                    <>
                      <Styles.CurrentPriceCycleText>
                        {manageSubscriptionBenefit.benefitCurrentPriceText(benefitCycleInfos)}
                      </Styles.CurrentPriceCycleText>
                      <Styles.CurrentPriceTotal>
                        {manageSubscriptionBenefit.benefitCurrentPriceTotal(currentPrice)}
                      </Styles.CurrentPriceTotal>
                    </>
                  )}
                </Styles.CurrentPriceContent>
                <Styles.ArrowWrapper>
                  <IconArrowForward fill={colorText} stroke={colorText} />
                </Styles.ArrowWrapper>
              </Styles.CurrentPriceWrapper>
              <Styles.DiscountPriceContent>
                <Styles.BenefitFuturePriceFirstText>
                  {benefitFuturePriceText}
                </Styles.BenefitFuturePriceFirstText>
                <Styles.BenefitFuturePriceDiscountWrapper>
                  <Styles.FuturePricesWrapper showInstallment={disabledInstallments}>
                    {disabledInstallments && manageSubscriptionBenefit.benefitFuturePriceCycleMonth(dataInstallment().quantityInstallments, dataInstallment().price)}
                    {manageSubscriptionBenefit.benefitFuturePriceCycleYear(futurePrice)}
                  </Styles.FuturePricesWrapper>
                  <Styles.DiscountTagWrapper>
                    <Styles.DiscountTagContent>
                      {manageSubscriptionBenefit.benefitFutureDiscount(discountPercentage)}
                    </Styles.DiscountTagContent>
                    {disabledInstallments && (
                      <Styles.DiscountTagContent>
                        {manageSubscriptionBenefit.benefitInstallments(dataInstallment().quantityInstallments)}
                      </Styles.DiscountTagContent>
                    )}
                  </Styles.DiscountTagWrapper>
                </Styles.BenefitFuturePriceDiscountWrapper>
                <ButtonV1 label={manageSubscriptionBenefit.benefitAcceptOfferButton} customStyles={customButtonRadius} testId="benefit-accept-discount" onClick={handleAcceptButtonClick} />
              </Styles.DiscountPriceContent>
            </Styles.PricesContent>

            <Styles.BenefitFinalExplanation>
              {benefitFinalText}
            </Styles.BenefitFinalExplanation>

            <Styles.FooterContent>
              <Button
                label={isRenewCanceling ? manageSubscriptionBenefit.benefitCancelRenewButton : manageSubscriptionBenefit.benefitCancelSignatureButton}
                testId="benefit-cancel-signature"
                onClick={handleCancelButtonClick}
              />
            </Styles.FooterContent>
          </>
        )}
      </Styles.ContentWrapper>
      {invoiceModalIsOpen && (
        <InvoiceModal
          modalClose={closeInvoiceModalHandler}
          title={manageSubscriptionBenefit.invoiceModal.title}
          descriptions={manageSubscriptionBenefit.invoiceModal.description}
          btnText={manageSubscriptionBenefit.invoiceModal.button}
          btnAction={viewInvoiceGenerated}
        />
      )}
    </Styles.Wrapper>
  );
};

export default RetentionBenefitHandler;
