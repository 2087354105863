export const PreChatActionTypes = {
  OFFER_CONTEXT_TO_NEW_ZENDESK: 'OFFER_CONTEXT_TO_NEW_ZENDESK',
  SET_LOADING_OFFER_CONTEXT_TO_NEW_ZENDESK: 'SET_LOADING_OFFER_CONTEXT_TO_NEW_ZENDESK',
  SET_SUCCESS_OFFER_CONTEXT: 'SET_SUCCESS_OFFER_CONTEXT',
  SET_FAILURE_OFFER_CONTEXT: 'SET_FAILURE_OFFER_CONTEXT',

  SET_DIAGNOSTIC_LOADING: 'SET_DIAGNOSTIC_LOADING',
  SET_PRODUCTS_BY_STATUS_LOADING: 'SET_PRODUCTS_BY_STATUS_LOADING',

  GET_DIAGNOSTIC_REQUEST: 'GET_DIAGNOSTIC_REQUEST',
  GET_DIAGNOSTIC_SUCCESS: 'GET_DIAGNOSTIC_SUCCESS',
  GET_DIAGNOSTIC_FAILURE: 'GET_DIAGNOSTIC_FAILURE',
  SET_DIAGNOSTIC: 'SET_DIAGNOSTIC',
  RELOAD_DIAGNOSTIC: 'RELOAD_DIAGNOSTIC',
  SET_OPEN: 'SET_OPEN',
  SET_INVOICE_ID: 'SET_INVOICE_ID',
  SET_HOLD_REDIRECT: 'SET_HOLD_REDIRECT',
  SET_SCREEN_TO_SHOW_IN_PRECHAT: 'SET_SCREEN_TO_SHOW_IN_PRECHAT',
  SET_SESSION: 'SET_SESSION',
  BILLING_GENERATED: 'BILLING_GENERATED',
  REACTIVATION_REDIRECT: 'REACTIVATION_REDIRECT',
  SET_TRUST_REACTIVATION_INVOICE_ID: 'SET_TRUST_REACTIVATION_INVOICE_ID',
  SET_INVOICE_TICKET_WILL_OPEN: 'SET_INVOICE_TICKET_WILL_OPEN',
  SET_SUBSCRIPTION_TO_CANCEL: 'SET_SUBSCRIPTION_TO_CANCEL',
  SET_SUBSCRIPTION_TO_CHANGE_PAYMENT_METHOD: 'SET_SUBSCRIPTION_TO_CHANGE_PAYMENT_METHOD',
  SET_SUBSCRIPTION_REDIRECT_FROM_PRECHAT: 'SET_SUBSCRIPTION_REDIRECT_FROM_PRECHAT',
  PRODUCT_BY_STATUS_REQUEST: 'PRODUCT_BY_STATUS_REQUEST',
  PRODUCT_BY_STATUS_SUCCESS: 'PRODUCT_BY_STATUS_SUCCESS',
  PRODUCT_BY_STATUS_FAILURE: 'PRODUCT_BY_STATUS_FAILURE',
  SET_PRODUCT_BY_STATUS: 'SET_PRODUCT_BY_STATUS',
  SET_TOS_REDIRECT: 'SET_TOS_REDIRECT',
  SET_VPS_REDIRECT: 'SET_VPN_REDIRECT',
  SET_VPS_PRODUCT_ID: 'SET_VPS_PRODUCT_ID',
  SET_VPS_TERMS_ACCEPTED: 'SET_VPS_TERMS_ACCEPTED',
  GET_PRODUCT_DETAILS_REQUEST: 'GET_PRODUCT_DETAILS_REQUEST',
  GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
  GET_PRODUCT_DETAILS_FAILURE: 'GET_PRODUCT_DETAILS_FAILURE',
  SET_PRODUCT_DETAILS_LOADING: 'SET_PRODUCT_DETAILS_LOADING',
  SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
  SET_EPP_KEY_FLOW: 'SET_EPP_KEY_FLOW',
  SET_RESERVED_DOMAIN_FLOW: 'SET_RESERVED_DOMAIN_FLOW',
  GET_WEEK_DAY_REQUEST: 'GET_WEEK_DAY_REQUEST',
  SET_WEEK_DAY: 'SET_WEEK_DAY',
  GET_WEEK_DAY_SUCCESS: 'GET_WEEK_DAY_SUCCESS',
  GET_WEEK_DAY_FAILURE: 'GET_WEEK_DAY_FAILURE',
  HANDLE_CHAT_DISPLAY: 'HANDLE_CHAT_DISPLAY',
  SET_SHOW_SIDE_PANEL: 'SET_SHOW_SIDE_PANEL',
};
