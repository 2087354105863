import React, { useCallback, useEffect } from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './VerifiedOverwriteSiteModal.styles';
import { Button, Checkbox, IconDanger } from 'gatorcomponents';
import { isMobile } from '@/utils/Validators/validation';

const VerifiedOverwriteSiteModal = ({
  title = 'Modal title',
  checkboxLabel = 'Checkbox label',
  continueButtonLabel = 'Continue label',
  backButtonLabel = 'Back label',
  maxWidth = '654',
  userAgreeOverwriteSite,
  handleToggleUserAgreeOverwriteSite,
  selectedDomain = null,
  onClose,
  warningDomain,
  warningDomainWithDns,
  warningAboutDns,
  warningLink,
  cancelOverwrite,
  onContinue,
  errorMessage,
  warnAboutDns,
  loadingUninstall,
  uninstallError,
  securityCopyOverwriteModalAnalytics,
  continueToVerifiedOverwriteAnalytics,
  showVerifiedOverwriteSiteModalAnalytics,
}) => {
  const handleChangeCheckbox = () => {
    handleToggleUserAgreeOverwriteSite && handleToggleUserAgreeOverwriteSite();
  };

  const handleCancel = () => {
    cancelOverwrite && cancelOverwrite();
    onClose && onClose();
  };

  const handleClose = () => {
    cancelOverwrite && cancelOverwrite();
    onClose && onClose();
  };

  const handleContinue = () => {
    continueToVerifiedOverwriteAnalytics && continueToVerifiedOverwriteAnalytics(warnAboutDns);
    onContinue && onContinue();
  };

  const handleWarningBackup = () => {
    securityCopyOverwriteModalAnalytics && securityCopyOverwriteModalAnalytics(warnAboutDns);
  };

  const handleShowVerifiedOverwriteSiteModal = useCallback(() => {
    showVerifiedOverwriteSiteModalAnalytics && showVerifiedOverwriteSiteModalAnalytics(warnAboutDns);
  }, [showVerifiedOverwriteSiteModalAnalytics, warnAboutDns]);

  useEffect(() => {
    handleShowVerifiedOverwriteSiteModal();
  }, [handleShowVerifiedOverwriteSiteModal]);

  return (
    <Modal testId="modal-verified-overwrite-site" onClose={loadingUninstall ? null : handleClose} disablePadding maxWidth={maxWidth}>
      <Styles.Title data-testid="verified-overwrite-site-title">
        {title}
      </Styles.Title>

      <Styles.WarningWrapper warnAboutDns={warnAboutDns}>
        <Styles.IconWrapper>
          <IconDanger color="attentionPure" size={isMobile ? 'md' : 'lg'} />
        </Styles.IconWrapper>
        <Styles.Warning>{warnAboutDns && selectedDomain.label ? warningDomainWithDns(selectedDomain.label) : warningDomain(selectedDomain.label)}</Styles.Warning>
      </Styles.WarningWrapper>

      {warnAboutDns && (
        <Styles.WarningAboutDns>{warningAboutDns()}</Styles.WarningAboutDns>
      )}

      <Styles.SecondWarningWrapper>
        <Styles.WarningBackup onClick={handleWarningBackup}>{warningLink()}</Styles.WarningBackup>
      </Styles.SecondWarningWrapper>

      <Styles.CheckboxWrapper onClick={() => handleChangeCheckbox()} data-testid="radioLabel">
        <Checkbox value={checkboxLabel} checked={userAgreeOverwriteSite} />
        <Styles.CheckboxLabel data-testid="confirmationRadio">{checkboxLabel}</Styles.CheckboxLabel>
      </Styles.CheckboxWrapper>

      <Styles.ActionWrapper uninstallError={uninstallError && !loadingUninstall}>
        <Button disabled={loadingUninstall} size="large" variant="tertiary" label={backButtonLabel} onClick={handleCancel} testId="backButton" />
        <Button loading={loadingUninstall} size="large" variant="primary" label={continueButtonLabel} disabled={!userAgreeOverwriteSite || loadingUninstall} onClick={handleContinue} testId="continueButton" />
      </Styles.ActionWrapper>
      {uninstallError && !loadingUninstall && (
        <Styles.ErrorWrapper>
          <IconDanger color="problemPure" />
          <Styles.Warning>{errorMessage}</Styles.Warning>
        </Styles.ErrorWrapper>
      )}
    </Modal>
  );
};

export default VerifiedOverwriteSiteModal;
