const styles = theme => ({
  card: {
    alignItems: 'center',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: 6,
    minHeight: '60px',
    padding: 7,
    position: 'relative',

    '@media(max-width: 1260px)': {
      padding: 2,
    },
  },

  pageTitle: {
    fontSize: '24px',
    fontWeight: 300,
    margin: '0 20px 0 32px',
  },

  iframeDomain: {
    alignItems: 'center',
    color: theme.color.regentGray,
    display: 'flex',
    fontSize: '18px',
    fontWeight: 300,
    letterSpacing: '0.01px',
    lineHeight: '22px',

    [theme.breakpoints.down(449)]: {
      marginLeft: '32px',
    },
  },
});
export default styles;
