import React from 'react';
import * as Style from './IconPaper.style';

const IconPaper = ({
  color = '#2e578b',
  testId = 'icon-paper',
  size = 36,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="icon-materiais" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color}>
      <path id="Retângulo_14298" data-name="Retângulo 14298" d="M796.293,393.706h24.936v10.268a7.823,7.823,0,0,1-7.823,7.823h-9.29a7.823,7.823,0,0,1-7.823-7.823V393.706Z" transform="translate(-796.293 -381.456)" fill="#ff9144" />
      <g id="Grupo_36330" data-name="Grupo 36330" transform="translate(11.735)">
        <path id="Caminho_238737" data-name="Caminho 238737" d="M820.294,368.651v31.808h13.213v-2.934H823.228v-25.94h22.442v27.407H848.6V368.651Z" transform="translate(-820.294 -368.651)" fill="#4a4a4a" />
        <rect id="Retângulo_13321" data-name="Retângulo 13321" width="4.889" height="2.794" transform="translate(16.431 28.975)" fill="#4a4a4a" />
        <rect id="Retângulo_13322" data-name="Retângulo 13322" width="14.437" height="2.5" transform="translate(6.982 8.454)" fill="#4a4a4a" />
        <rect id="Retângulo_13323" data-name="Retângulo 13323" width="14.437" height="2.5" transform="translate(6.982 14.141)" fill="#4a4a4a" />
        <rect id="Retângulo_13324" data-name="Retângulo 13324" width="9.625" height="2.5" transform="translate(6.982 19.828)" fill="#4a4a4a" />
      </g>
    </svg>

  </Style.Wrapper>
);


export default IconPaper;
