import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  ${({ noMinHeight }) => css`
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: ${noMinHeight ? 0 : '337px'};
    gap: 16px;
    align-items:center;
  `}
`;

export const Loader = styled.div`
  ${({ theme }) => css`
    display: block;
    height: 40px;
    width: 40px;
    -webkit-animation: loader-2-1 3s linear infinite;
    animation: loader-2-1 3s linear infinite;

    @-webkit-keyframes loader-2-1 {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes loader-2-1 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    & span {
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 40px;
      width: 40px;
      clip: rect(16px, 40px, 40px, 0);
      -webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
      animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }

    @-webkit-keyframes loader-2-2 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes loader-2-2 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    & span::before {
    content: "";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 40px;
    width: 40px;
    border: 3px solid transparent;
    border-top: 3px solid ${theme.palette.danger.primary};
    border-radius: 50%;
    -webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
      animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }

    @-webkit-keyframes loader-2-3 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes loader-2-3 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    & span::after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 40px;
      width: 40px;
      border: 3px solid ${theme.palette.danger.primary};
      border-radius: 50%;
      opacity: 0.5;
    }
`}
`;

export const Message = styled.p`
  ${({ theme }) => css`
    margin: 16px 0px 0px 0px !important;
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    line-height: ${theme.font.lineHeights.xl};
    font-weight: ${theme.font.weights.medium};
  `}
`;
