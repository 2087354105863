import React, { createContext, useState } from 'react';

export const DnsWizardContext = createContext();

const DnsWizardContextProvider = ({ children }) => {
  const [openDnsTable, setOpenDnsTable] = useState(false);

  const [selectedEmailPlatformId, setSelectedEmailPlatformId] = useState('');

  const values = {
    openDnsTable,
    setOpenDnsTable,
    selectedEmailPlatformId,
    setSelectedEmailPlatformId,
  };

  return (
    <DnsWizardContext.Provider value={values}>{children}</DnsWizardContext.Provider>
  );
};

export default DnsWizardContextProvider;
