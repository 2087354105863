import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryUltralight};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin: 24px 0px 0px;
    padding: 16px 0px 32px;
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    margin: 16px 0px 0px;
     span { 
      color: ${theme.v2.colors.brand.primaryMedium};
    }
  `}
`;

export const InpuWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  margin: 32px 0px;
  max-width: 400px;
  position: relative;
  width: 100%;


    
  > div {
    width: 100% !important;
  }
`;

export const InputLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const ActionsRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  margin: 32px 0px 0px;
`;
