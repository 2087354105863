import { CALL_API } from '@/middleware/api';

const apiGuards = () => next => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const {
    endpoint,
    method,
    actionTypes,
    mock = false,
    authorizationType,
  } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (typeof method !== 'string') {
    throw new Error('Specify a string method.');
  }

  if (typeof actionTypes === 'undefined') {
    throw new Error('Expected an object of action types.');
  }

  if (typeof actionTypes.request !== 'function') {
    throw new Error('Specify a function request action type.');
  }

  if (typeof actionTypes.success !== 'function') {
    throw new Error('Specify a function success action type.');
  }

  if (typeof actionTypes.error !== 'undefined' && typeof actionTypes.error !== 'function') {
    throw new Error('Error action type must be a function.');
  }

  if (typeof mock !== 'boolean') {
    throw new Error('Error mock type must be a boolean.');
  }

  if (typeof authorizationType !== 'undefined' && typeof authorizationType !== 'string') {
    throw new Error('Specify a string authorizationType.');
  }

  return next(action);
};

export default apiGuards;
