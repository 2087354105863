import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    width: 100%;
  `}
`;

export const Container = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-family: 'Galano';
  @media (max-width: ${theme.v2.breakpoints.xl}) {
    justify-content: baseline;
  }
  @media (max-width: ${theme.v2.breakpoints.md}) {
    flex-direction: column;
    gap: 28px;
    padding: 0px;
  }
`}
`;

export const HeroContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 50%;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
      gap: 24px;
      justify-content: unset;
      width: 100%;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;
      padding: 0;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 32px;
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0 0 8px 0px;
    z-index: 1;
    span {
      color: ${theme.v2.colors.brand.primaryMedium};
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: 20px;
    }
  `}
`;


export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    text-align: left;
    z-index: 1;
    span {
      font-weight: bold;
    }
  `}
`;

export const ImageAdapter = styled.div`
  z-index: 1;
`;

export const Image = styled.img`
  width: 100%;
`;


export const TagAdapter = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

export const AssignBox = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  gap: 16px;
  @media (max-width: ${theme.v2.breakpoints.lg}) {
    align-items: baseline;
    flex-direction: column;
    width: 100%;
  }
  `}
`;

export const Wordpress = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WordpressImg = styled.img``;


export const Strong = styled.strong`
${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    text-align: left;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const ButtonAdapter = styled.div`
  min-width: 202px;
  max-width: 200px;
  width: 100%;
  button {
    width: 100%;
  }
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    text-align: left;
    span {
      font-weight: bold;
    }
  `}
`;

export const Polygon = styled.img`
  position: absolute;
  right: -34px;
  top: -40px;
`;
