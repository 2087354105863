import { CALL_API } from '@/middleware/api';
import { COUNTRY_CODE } from '@/config/api';
import {
  REQUEST_COUNTRY_CODE, RECEIVE_COUNTRY_CODE, ERROR_COUNTRY_CODE,
} from './actionsTypes';


export const receiveCountryCodes = data => ({
  type: RECEIVE_COUNTRY_CODE,
  data,
});

export const loadCountryCodes = () => ({
  [CALL_API]: {
    endpoint: COUNTRY_CODE,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_COUNTRY_CODE }),
      success: ({ data }) => receiveCountryCodes(data),
      error: () => ({ type: ERROR_COUNTRY_CODE }),
    },
  },
});
