import { CALL_API } from '@/middleware/api';
import {
  SEND_PHONE_CODE, VALIDATE_PHONE_CODE, CHANGE_PHONE_NUMBER,
} from '@/config/api';
import {
  REQUEST_SEND_PHONE_CODE, RECEIVE_SEND_PHONE_CODE, ERROR_SEND_PHONE_CODE,
  REQUEST_VALIDATE_PHONE_CODE, RECEIVE_VALIDATE_PHONE_CODE, ERROR_VALIDATE_PHONE_CODE,
  REQUEST_CHANGE_PHONE_NUMBER, RECEIVE_CHANGE_PHONE_NUMBER, ERROR_CHANGE_PHONE_NUMBER, ERROR_SEND_PHONE_WRONG_PASSWORD, PHONENUMBER_CHANGE_FLOW,
} from './actionsTypes';


export const receiveSendCodeVerify = data => ({
  type: RECEIVE_SEND_PHONE_CODE,
  data,
});

export const sendPhoneCodeVerify = data => ({
  [CALL_API]: {
    statusCode: true,
    endpoint: SEND_PHONE_CODE,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_SEND_PHONE_CODE }),
      success: response => receiveSendCodeVerify(response),
      error: (response) => {
        if (response.data) {
          if (response.data.internal_code === 1) {
            return ({ type: ERROR_SEND_PHONE_WRONG_PASSWORD });
          }
        }

        return ({ type: ERROR_SEND_PHONE_CODE });
      },
    },
  },
});

export const receiveValidatePhoneCode = data => ({
  type: RECEIVE_VALIDATE_PHONE_CODE,
  data,
});

export const validatePhoneCode = smsToken => ({
  [CALL_API]: {
    endpoint: VALIDATE_PHONE_CODE(smsToken),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_VALIDATE_PHONE_CODE }),
      success: response => receiveValidatePhoneCode(response),
      error: data => ({ data, type: ERROR_VALIDATE_PHONE_CODE }),
    },
  },
});

export const receiveChangePhoneNumber = data => ({
  type: RECEIVE_CHANGE_PHONE_NUMBER,
  data,
});

export const changePhoneNumber = data => ({
  [CALL_API]: {
    endpoint: CHANGE_PHONE_NUMBER,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_CHANGE_PHONE_NUMBER }),
      success: response => receiveChangePhoneNumber(response),
      error: () => ({ type: ERROR_CHANGE_PHONE_NUMBER }),
    },
  },
});

export const phoneNumberChangeFlow = (bool, currentUrl) => ({ type: PHONENUMBER_CHANGE_FLOW, data: { bool, currentUrl } });
