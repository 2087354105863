const styles = theme => ({
  btns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },
  btn: {
    '& > span > img': {
      marginRight: '5px',
    },
    '&:focus': {
      border: `1px solid ${theme.color.chambray}`,
    },
    '& > .active': {
      border: `1px solid ${theme.color.chambray}`,
    },
    margin: '0 5px',
    fontWeight: 400,
    fontSize: '14px',
    textTransform: 'none',
    color: theme.color.emperor,
  },
  btnActive: {
    border: `0 0 0 0.2rem ${theme.color.chambray}`,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  dialog: {
    width: '500px',
    display: 'flex',
    flex: 'inherit',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: 400,
    color: theme.color.tundora,
    margin: '-5px 0 20px 0',
  },
  wrapper: {
    borderRadius: '0',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
  fillBtn: {
    margin: '0 5px',
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.color.tundora,
    cursor: 'pointer',
  },
});

export default styles;
