import { billingAnalytics } from '@/analytics';
import React, { useEffect } from 'react';
import { ReviewRefundBlock } from '../ReviewRefundBlock';
import * as Styles from './Review.styles';

const Review = ({
  title = 'Check your cancellation information:',
  signatureTitle = 'Signature',
  signature = 'Plan P',
  reasonTitle = 'Cancel reason',
  reasonOption = 'High renovation price',
  reasonDescription = '',
  testId = 'review',
  refundTitle = 'Refund method',
  totalLabel = 'Total to refund',
  methodOptions,
  refund,
}) => {
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('5_conferir_informacoes_cancelar_imediato');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title>{title}</Styles.Title>

      <Styles.DataWrapper>
        <Styles.ItemTitle>{signatureTitle}</Styles.ItemTitle>
        <Styles.List>
          <Styles.ListItem>{signature}</Styles.ListItem>
        </Styles.List>

        <Styles.ItemTitle>{reasonTitle}</Styles.ItemTitle>
        <Styles.List>
          <Styles.ListItem>{reasonOption}</Styles.ListItem>
        </Styles.List>

        {reasonDescription && reasonDescription !== '' && (
        <Styles.ReasonDescription>{reasonDescription}</Styles.ReasonDescription>
        )}
      </Styles.DataWrapper>

      {refund && refund.total > 0 && (
      <ReviewRefundBlock
        refundTitle={refundTitle}
        totalLabel={totalLabel}
        selectedRefundMethod={refund.methodSelected}
        methodOptions={methodOptions}
        amount={refund.total}
      />
      )}
    </Styles.Wrapper>
  );
};

export default Review;
