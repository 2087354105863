const styles = theme => ({
  container: {
    borderRadius: 0,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '& .detailParam': {
      fontFamily: 'Roboto, sans-serif',
      fontSize: theme.font.sizes.sm,
    },
    '& .cardSubTitle': {
      color: theme.color.regentGray,
    },
  },
  active: {
    borderLeft: `5px solid ${theme.color.oceanGreen}`,
    '& .status-icon': {
      color: theme.color.oceanGreen,
    },
    '& .barChecked': {
      '& ~ .roundBar': {
        backgroundColor: theme.color.oceanGreen,
      },
    },
    '& .roundIcon': {
      backgroundColor: theme.color.mystic,
    },
    '& .iconChecked': {
      backgroundColor: theme.color.oceanGreen,
    },
    '& .switchRoot': {
      '& .roundBar': {
        backgroundColor: theme.color.tundora,
      },
    },
  },
  pending: {
    borderLeft: `5px solid ${theme.color.brightSun}`,
    '& .status-icon': {
      color: theme.color.brightSun,
    },
    '& .warning-notice': {
      borderColor: theme.color.brightSun,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.brightSun,
    },
  },
  pendingTransfer: {
    borderLeft: `5px solid ${theme.color.coral}`,
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .warning-notice': {
      borderColor: theme.color.coral,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.coral,
    },
  },
  expired: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },
  registerError: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },
  cancelled: {
    borderLeft: `5px solid ${theme.color.geyser}`,
    '& .status-icon': {
      color: theme.color.geyser,
    },
    '& .warning-notice': {
      borderColor: theme.color.geyser,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.geyser,
    },
  },
  suspended: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },
  nextDueDate: {
    borderLeft: `5px solid ${theme.color.brightSun}`,
    '& .status-icon': {
      color: theme.color.brightSun,
    },
    '& .warning-notice': {
      borderColor: theme.color.brightSun,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.brightSun,
    },
  },
  dueDate: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-notice': {
      borderColor: theme.color.valencia,
    },
    '& .warningStatusColor': {
      borderColor: theme.color.valencia,
    },
  },
});

export default styles;
