const styles = theme => ({
  card: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 48px 80px',
    minHeight: 'calc(92vh - 92px)',
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    textAlign: 'center',
    boxSizing: 'border-box',
    '&:last-child': {
      paddingBottom: 80,
    },
    '& .status-icon': {
      color: theme.color.indigo,
      marginTop: 0,
    },
    '& p': {
      margin: '32px 0px 12px',
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      minHeight: '100vh',
      paddingBottom: 70,
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    zIndex: 2,
    userSelect: 'none',
    fontSize: '24px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.color.emperor,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '30px',
    },
  },
  contentWrapper: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
});

export default styles;
