import PropTypes from 'prop-types';
import {
  Button, IconAtencion, IconLoading, IconPreferences, IconSpeed, Modal,
} from 'gatorcomponents';
import React from 'react';
import * as Styles from './ActivateDeactivateInternalDomainModal.styles';
import { STATUS } from '../../CloudFlareHandler.types';


export const ActivateDeactivateInternalDomainModal = ({
  onClose,
  onContinueActivating,
  onContinueDeactivating,
  toActivateTitle,
  activatingTitle,
  toDeactivateTitle = 'Desativar o CDN',
  deactivatingTitle = 'Desativação do CDN em andamento',
  toActivateDescription,
  activatingDescription,
  deactivatingDescription = 'Ative o CDN e deixe seu site mais rápido e seguro. As mudanças  podem levar até 24 horas para domínios nacionais e até 72 horas para domínios internacionais.',
  toDeactivateDescription = 'Ative o CDN e deixe seu site mais rápido e seguro. As mudanças  podem levar até 24 horas para domínios nacionais e até 72 horas para domínios internacionais.',
  cancelCta = 'Cancelar',
  confirmationCta = 'Ok, entendi',
  continueActivationCta = 'Ativar o CDN',
  continueDeactivationCta = 'Desativar o CDN',
  currentStatus = STATUS.NONEXISTENT,
  loadingActivation,
  lastActivation,
  failedRequestTitle = 'Ocorreu um erro na sua solicitação',
  failedRequestDescription = 'Algo deu errado enquanto processavamos sua solicitação. Por favor, tente novamente.',
}) => {
  const toActivate = [STATUS.NONEXISTENT, STATUS.INACTIVE].includes(currentStatus);
  const successActivation = lastActivation.status === 'success';
  const failedActivation = lastActivation.status === 'failure';

  const handleCancel = () => {
    onClose && onClose();
  };

  const handleContinue = () => {
    if (toActivate) {
      onContinueActivating && onContinueActivating();
    }

    if (!toActivate) {
      onContinueDeactivating && onContinueDeactivating();
    }
  };

  const getTitle = () => {
    if (failedActivation) {
      return failedRequestTitle;
    }

    if (successActivation) {
      return toActivate ? activatingTitle : deactivatingTitle;
    }

    return toActivate ? toActivateTitle : toDeactivateTitle;
  };

  const getIcon = () => {
    if (failedActivation) {
      return <IconAtencion secondaryColor="problemLight" size="xl" />;
    }

    if (successActivation) {
      return <IconPreferences size="xl" />;
    }

    return (
      <IconSpeed
        secondaryColor={toActivate ? 'activePure' : 'problemLight'}
        size="xl"
        variant={toActivate ? 'fast' : 'slow'}
      />
    );
  };

  const getDescription = () => {
    if (failedActivation) {
      return failedRequestDescription;
    }

    if (successActivation) {
      return toActivate ? activatingDescription : deactivatingDescription;
    }

    return toActivate ? toActivateDescription : toDeactivateDescription;
  };

  const getContinueLabel = () => {
    if (loadingActivation) {
      return <IconLoading />;
    }

    return toActivate ? continueActivationCta : continueDeactivationCta;
  };

  return (
    <Modal onClose={onClose}>
      <Styles.Container id="activateDeactivateModalContainer">
        {getIcon()}

        <Styles.Title data-testid="title">
          {getTitle()}
        </Styles.Title>

        <Styles.Description data-testid="description">
          {getDescription()}
        </Styles.Description>

        <Styles.ActionsRow>
          {(successActivation || failedActivation) && (
            <Button
              size="large"
              testId="confirmation"
              label={confirmationCta}
              onClick={onClose}
            />
          )}

          {(!successActivation && !failedActivation) && (
          <>
            <Button
              size="large"
              testId="back"
              variant="tertiary"
              label={cancelCta}
              onClick={handleCancel}
              disabled={loadingActivation}
            />
            <Button
              size="large"
              testId="continue"
              label={getContinueLabel()}
              onClick={handleContinue}
              disabled={loadingActivation}
            />
          </>
          )}

        </Styles.ActionsRow>
      </Styles.Container>
    </Modal>
  );
};

ActivateDeactivateInternalDomainModal.propTypes = {
  onClose: PropTypes.func,
  onContinueActivating: PropTypes.func,
  onContinueDeactivating: PropTypes.func,
  toActivateTitle: PropTypes.string,
  activatingTitle: PropTypes.string,
  toDeactivateTitle: PropTypes.string,
  deactivatingTitle: PropTypes.string,
  toActivateDescription: PropTypes.string,
  activatingDescription: PropTypes.string,
  toDeactivateDescription: PropTypes.string,
  deactivatingDescription: PropTypes.string,
  cancelCta: PropTypes.string,
  confirmationCta: PropTypes.string,
  continueActivationCta: PropTypes.string,
  continueDeactivationCta: PropTypes.string,
  currentStatus: PropTypes.string,
  loadingActivation: PropTypes.bool,
  lastActivation: PropTypes.object,
  failedRequestTitle: PropTypes.string,
  failedRequestDescription: PropTypes.string,
};

export default ActivateDeactivateInternalDomainModal;
