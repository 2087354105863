import { useMemo } from 'react';
import rules from './useWizardTips.rules';

const useWizardTips = (selectedTool = {}) => {
  const tips = useMemo(
    () => rules.defaultTips[selectedTool],
    [selectedTool],
  );
  return { ...tips, id: selectedTool };
};

export default useWizardTips;
