import React, { useEffect, useState } from 'react';
import * as Styles from './PayPalChangeAccount.styles';
import { Radio } from '@/pages/common';
import { IconError } from '@/icons';
import { PaypalChangeAccountType } from './PayPalChangeAccount.type';

const PayPalChangeAccount = ({
  defaultAccountLabel = 'Utilizar outra conta PayPal cadastrada',
  addNewAccountLabel = 'Cadastar nova conta PayPal',
  defaultInfoUseDefaultAccountLabel = 'Substituirá a conta Paypal em todas as assinaturas vinculadas à ela.',
  userName = 'Snappy',
  userEmail = 'snappy@hostgator.com',
  selectActionPayPalLabel = 'Selecione qual conta PayPal gostaria de utilizar:',
  infoPaymentByPicPayLabel = 'Compensação rápida do pagamento',
  infoChangePaymentByPayPalLabel = 'Ao final do ciclo da assinatura faremos a cobrança automática na conta PayPal cadastrada.',
  updatePaymentOptionByPayPal,
  isSubscriptionsPage,
}) => {
  const [option, setOption] = useState(PaypalChangeAccountType.SELECT_ACTUAL_ACCOUNT);

  const isSelectedAddNewAccount = () => option === PaypalChangeAccountType.ADD_NEW_ACCOUNT;

  const isSelectedUseActualAccount = () => option === PaypalChangeAccountType.SELECT_ACTUAL_ACCOUNT;

  useEffect(() => {
    updatePaymentOptionByPayPal && updatePaymentOptionByPayPal(PaypalChangeAccountType.SELECT_ACTUAL_ACCOUNT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectNewAccount = () => {
    setOption(PaypalChangeAccountType.ADD_NEW_ACCOUNT);
    updatePaymentOptionByPayPal && updatePaymentOptionByPayPal(PaypalChangeAccountType.ADD_NEW_ACCOUNT);
  };

  const selectActualAccount = () => {
    setOption(PaypalChangeAccountType.SELECT_ACTUAL_ACCOUNT);
    updatePaymentOptionByPayPal && updatePaymentOptionByPayPal(PaypalChangeAccountType.SELECT_ACTUAL_ACCOUNT);
  };

  return (
    <Styles.Content variant={isSubscriptionsPage ? 'subscriptions' : 'invoices'}>
      <Styles.WrapperInfo data-testid="text-info-paypal">
        {infoPaymentByPicPayLabel}
      </Styles.WrapperInfo>
      <Styles.SelectTitle data-testid="title-change-account-paypal">
        {selectActionPayPalLabel}
      </Styles.SelectTitle>
      <Styles.ContentOptionsInSelectord data-testid="content-option-in-selector">
        <Styles.OptionItem
          data-testid="subscription-manage-switch-payment-method-credit-card-option"
          checked={isSelectedUseActualAccount()}
          onClick={selectActualAccount}
        >
          <Styles.OptionItemWrapper>
            <Styles.OptionRadio checked={isSelectedUseActualAccount()}>
              <Radio
                size="medium"
                checked={isSelectedUseActualAccount()}
                inputId="CancelOptions."
                variant="primary"
                testId="credit-card-option-selection"
              />
            </Styles.OptionRadio>
            <Styles.OptionContent>
              <Styles.OptionLabel
                checked={false}
                data-testid="subscription-manage-switch-payment-method-credit-card-option-label"
              >
                {defaultAccountLabel}
              </Styles.OptionLabel>
              <div>
                <Styles.UserName>
                  {userName}
                </Styles.UserName>
                <Styles.UserEmail>
                  {userEmail}
                </Styles.UserEmail>
              </div>
            </Styles.OptionContent>

          </Styles.OptionItemWrapper>
        </Styles.OptionItem>
        <Styles.OptionItem
          data-testid="subscription-manage-switch-payment-method-ticket-option"
          checked={isSelectedAddNewAccount()}
          onClick={selectNewAccount}
        >
          <Styles.OptionItemWrapper>
            <Styles.OptionRadio checked={isSelectedAddNewAccount()}>
              <Radio
                variant="primary"
                size="medium"
                checked={isSelectedAddNewAccount()}
                onChange
                testId="ticket-option-selection"
                inputId="CancelOptions."
              />
            </Styles.OptionRadio>
            <Styles.OptionContent>
              <Styles.OptionLabel
                checked={false}
                data-testid="subscription-manage-switch-payment-method-ticket-option-label"
              >
                {addNewAccountLabel}
              </Styles.OptionLabel>
              <Styles.UserName>
                {defaultInfoUseDefaultAccountLabel}
              </Styles.UserName>
            </Styles.OptionContent>
          </Styles.OptionItemWrapper>
        </Styles.OptionItem>

      </Styles.ContentOptionsInSelectord>
      <Styles.Info>
        <Styles.InfoIcon>
          <IconError
            size="26"
            testId="icon-info-text-change-account-paypal"
          />
        </Styles.InfoIcon>
        <Styles.InfoText
          data-testid="info-text-change-account-paypal"
        >
          {infoChangePaymentByPayPalLabel}

        </Styles.InfoText>
      </Styles.Info>
    </Styles.Content>
  );
};


export default PayPalChangeAccount;
