import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './PaymentSuccessPixModal.styles';
import useLocale from '@/hooks/useLocale';
import IconCheckGreen from '@/media/billing/circle-and-check-green.svg';
import { Button } from '@/pages/common';
import { loadInvoices } from '@/redux/actions/invoices';
import { useDispatch } from 'react-redux';
import { OriginCalling } from '../PaymentModal/PaymentModal.types';
import { billingAnalytics } from '@/analytics';


const PaymentSuccessPixModal = ({
  onCloseClick = null,
  isManageSubscription = false,
  originCalling,
}) => {
  const { billing: billingLocale } = useLocale();
  const dispatch = useDispatch();
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const {
    manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale,
    paymentSuccessPixModal: paymentSuccessPixModalLocale,
  } = billingLocale;

  const titleModalLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.titleLabel);

  const titleLabel = useState(paymentSuccessPixModalLocale.titleLabel);
  const descriptionLine1Label = useState(paymentSuccessPixModalLocale.descriptionLine1Label);
  const descriptionLine2Label = useState(paymentSuccessPixModalLocale.descriptionLine2Label);
  const linkBack = useState(isManageSubscription ? paymentSuccessPixModalLocale.backToSignatures : paymentSuccessPixModalLocale.backToInvoices);

  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;

  const handleOnCloseClick = (event) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('6_fechar');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('6_fechar');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('6_fechar');
    }
    if (!isManageSubscription) {
      dispatch(loadInvoices('Unpaid', true));
    }
    onCloseClick && onCloseClick(event);
  };


  const handleOnBackClick = (event) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('6_voltar');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('6_voltar');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('6_voltar');
    }
    if (!isManageSubscription) {
      dispatch(loadInvoices('Unpaid', true));
    }
    onCloseClick && onCloseClick(event);
  };

  return (
    <Modal
      maxWidth={726}
      onClose={handleOnCloseClick}
      testId="payment-success-pix-modal"
      disablePadding
    >
      <Styles.Content>
        <Styles.Header>
          <Styles.TitleModal data-testid="title-modal-payment-success-pix">{titleModalLabel}</Styles.TitleModal>
        </Styles.Header>
        <Styles.ContentData>
          <Styles.Cronometer
            src={IconCheckGreen}
            loading="lazy"
          />
          <Styles.Title data-testid="data-title-modal">
            {titleLabel}
          </Styles.Title>
          <Styles.Label data-testid="description-line-1-modal-data">
            {descriptionLine1Label}
          </Styles.Label>
          <Styles.Label data-testid="description-line-2-modal-data">
            {descriptionLine2Label}
          </Styles.Label>
        </Styles.ContentData>
        <Styles.ControlButtonLink>
          <Button
            testId="link-to-signatures"
            onClick={handleOnBackClick}
            label={linkBack[0]}
            variant="link"
            size="large"
          />
        </Styles.ControlButtonLink>
      </Styles.Content>
    </Modal>
  );
};


export default PaymentSuccessPixModal;
