import React from 'react';
import * as Styles from './CodeGuardLP.styles';
import { DetailsSections } from './components/DetailsSections';
import { DiscoverBenefits } from './components/DiscoverBenefits';
import { SnappyBanner } from './components/SnappyBanner';
import { GetNow } from './components/GetNow';

const CodeGuardLP = ({
  getNowProps, detailsSectionsProps, discoverBenefitsProps, snappyBannerProps, mainTitle,
}) => (
  <Styles.Container>
    <Styles.MainTitleWrapper>
      <Styles.MainTitle>{mainTitle}</Styles.MainTitle>
    </Styles.MainTitleWrapper>
    <GetNow {...getNowProps} />
    <DetailsSections {...detailsSectionsProps} />
    <DiscoverBenefits {...discoverBenefitsProps} />
    <SnappyBanner {...snappyBannerProps} />
  </Styles.Container>
);

export default CodeGuardLP;
