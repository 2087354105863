import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';


const PlanCard = ({
  classes, children,
}) => (
  <div className={classes.card} data-testid="planCard">
    {children}
  </div>
);

export default withStyles(styles)(PlanCard);
