import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    letter-spacing: 0px;
    line-height: ${theme.v2.font.lineHeight.list};
    margin: 28px 0px 10px 32px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 32px 0px 20px 24px;
    }
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;
    margin: 18px 0px 0px 0px;
    color: ${theme.v2.colors.feedback.attentionPure};
    fill: ${theme.v2.colors.feedback.attentionPure};
    height: 70px;
    width: 70px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      width: 30px;
      height: 30px;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const Action = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const ContentWrapper = styled.div`
${({ theme }) => css`
  display:flex;
  margin: 0px 31px;
  padding: 0;
  @media (max-width: ${theme.v2.breakpoints.sm}) {
    align-items:center;
    flex-direction:column;
    margin: 0px 24px;
    svg{
      height:60px;
      margin-bottom:20px;
      width: 60px;
    }
  }
  `}
`;

export const Content = styled.div`
${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: ${theme.v2.font.sizes.xxs};
  letter-spacing: 0px;
  line-height: ${theme.v2.font.lineHeight.text};
  margin: 0px 0px 40px 12px;
  a {
    color: ${theme.v2.colors.brand.primaryBlue};
    display:inline;
  }
  @media (max-width: ${theme.v2.breakpoints.sm}) {
    margin: 0px 0px 40px 0px;
  }
`}
`;
