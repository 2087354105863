import React, { useState, useEffect } from 'react';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/v1/Button';
import { Tooltip } from '@/pages/common/v1/Tooltip';
import { IconInfo, IconError } from '@/icons';
import { domainsAnalytics } from '@/analytics';
import { palette } from '@/styles/HgThemeProvider';
import * as Styles from './HostGatorConfigModal.styles';
import { DnsWizardPlanGrid } from '@/components/domain/components/DnsWizardPlanGrid';
import { HOSTING_PLATFORM_TYPES, PLAN_TYPES } from '@/enums/domains/dns.enum';

const HostGatorConfigModal = ({
  testId = 'hostgatorconfigmodal',
  hostGatorConfigModal = false,
  handleClose = () => {},
  handleSubmit = () => {},
  handleClickVpsOrDedicatedPlan = () => {},
  title = 'Title',
  subtitle = 'subTitle',
  tooltip = 'tooltip',
  alertMessageText = 'alertMessageText',
  configureButtonText = 'configureButtonText',
  mainDomainText,
  otherDomainsText,
  availablePlans = [],
  domainText,
  domainTextPlural,
  planSelectGa,
  platform = {
    platformID: null,
  },
  setSelectedPlanToShowInReview,
}) => {
  const { secondary, yellow } = palette;
  const { primary: lightBlueColor } = secondary;
  const { mustard } = yellow;
  const [selected, setSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;
  const {
    dnsWizardConfirmPlanSelection,
    dnsWizardPlanSelectClick,
  } = dnsWizardAnalytics;

  const handleSelectedPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleConfigureDomain = () => {
    dnsWizardConfirmPlanSelection();
    handleClose();
    if (selectedPlan === PLAN_TYPES.DEDICATED) {
      handleClickVpsOrDedicatedPlan();
    } else {
      handleSubmit(HOSTING_PLATFORM_TYPES.HOSTGATOR);
    }
  };

  useEffect(() => {
    setSelected(false);
    setSelectedPlan(false);
  }, [hostGatorConfigModal]);

  return (
    <>
      {hostGatorConfigModal
      && (
        <Modal onClose={handleClose} maxWidth={860} maxHeight={680}>
          <Styles.HostGatorConfigModalWrapper data-testid={testId}>
            <Styles.TitleWrapper>
              <Styles.Title>{title}</Styles.Title>
              <Styles.SubTitle>
                {subtitle}
                <Tooltip content={tooltip} preferredPosition="bottom"><IconInfo color={lightBlueColor} /></Tooltip>
              </Styles.SubTitle>
            </Styles.TitleWrapper>
            <DnsWizardPlanGrid
              testId="dnswizard-plan-grid"
              availablePlans={availablePlans}
              mainDomainText={mainDomainText}
              otherDomainsText={otherDomainsText}
              setSelected={setSelected}
              selected={selected}
              onClick={dnsWizardPlanSelectClick}
              domainText={domainText}
              domainTextPlural={domainTextPlural}
              planSelectGa={planSelectGa}
              selectedPlan={handleSelectedPlan}
              platformId={platform.platformID}
              setSelectedPlanToShowInReview={setSelectedPlanToShowInReview}
            />
            <Styles.MessageWrapper>
              <IconError size="24" color={mustard} />
              {alertMessageText}
            </Styles.MessageWrapper>
            <Styles.ButtonWrapper>

              <Button label={configureButtonText} disabled={!selected} onClick={handleConfigureDomain} testId="button-config" />

            </Styles.ButtonWrapper>
          </Styles.HostGatorConfigModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorConfigModal;
