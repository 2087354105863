import React from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './useBlockedWindowModal.styles';
import { Button, IconRefreshPage } from 'gatorcomponents';
import useLocale from '../useLocale';

export const useBlockedWindowModal = () => {
  const handleOpen = ({ url, closeModal }) => {
    window.open(url, '_blank');
    closeModal && closeModal();
  };

  const { useBlockedWindowModal: useBlockedWindowModalLocale } = useLocale();

  const renderBlockedWindowModal = ({
    url,
    closeModal,
  }) => (
    <Modal
      disablePadding
      onClose={closeModal}
    >
      <Styles.RefreshPageWrapper>
        <Styles.IconWrapper>
          <IconRefreshPage size="lg" color="primaryExtraDark" />
        </Styles.IconWrapper>

        <Styles.Description>
          {useBlockedWindowModalLocale.description}
        </Styles.Description>

        <Button
          label={useBlockedWindowModalLocale.cta}
          onClick={() => handleOpen({ url, closeModal })}
          size="large"
        />
      </Styles.RefreshPageWrapper>
    </Modal>
  );

  const useBlockedWindowModalData = {
    renderBlockedWindowModal,
  };

  return useBlockedWindowModalData;
};

export default useBlockedWindowModal;
