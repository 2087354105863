import React from 'react';
import * as Style from './IconDetailedMyAccount.style';

const IconDetailedMyAccount = ({
  size = '24',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.62695 3.81812V25.6041H26.1817L4.62695 3.81812Z" fill="#FFCF00" />
      <path d="M6.46259 25.6379H4.36353V3.81812H25.952V5.939H6.46259V25.6379Z" fill="#1F2044" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.9189 16.9281C18.6843 16.9281 17.6835 17.929 17.6835 19.1636C17.6835 20.3982 18.6843 21.399 19.9189 21.399C21.1535 21.399 22.1544 20.3982 22.1544 19.1636C22.1544 17.929 21.1535 16.9281 19.9189 16.9281ZM16.0471 19.1636C16.0471 17.0252 17.7806 15.2917 19.9189 15.2917C22.0573 15.2917 23.7908 17.0252 23.7908 19.1636C23.7908 21.3019 22.0573 23.0354 19.9189 23.0354C17.7806 23.0354 16.0471 21.3019 16.0471 19.1636Z" fill="#1F2044" />
      <path d="M9.73313 7.46436H7.96777V9.22971H9.73313V7.46436Z" fill="#1F2044" />
      <path d="M12.8869 7.46436H11.1216V9.22971H12.8869V7.46436Z" fill="#1F2044" />
      <path d="M15.5898 7.46411H13.8245V9.22946H15.5898V7.46411Z" fill="#1F2044" />
      <path d="M11.0625 21.2244H7.97314V22.9897H11.0625V21.2244Z" fill="#1F2044" />
      <path d="M13.7245 17.9475H7.98657V19.7129H13.7245V17.9475Z" fill="#1F2044" />
      <path d="M13.7245 14.301H7.98657V16.0664H13.7245V14.301Z" fill="#1F2044" />
      <path d="M25.9524 10.6553H8.29907V12.4205H25.9524V10.6553Z" fill="#1F2044" />
    </svg>

  </Style.Wrapper>
);


export default IconDetailedMyAccount;
