import React from 'react';
import * as Style from './IconDetailedEmail.style';

const IconDetailedEmail = ({
  color = '#4a4a4a',
  background = '#3c97ff',
  size = '25',
  testId = 'icon-detailed-email',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25">
      <g data-name="Grupo 40281" transform="translate(0 0)" fill={color}>
        <rect width="15" height="25" fill={background} />
        <path data-name="Caminho 239090" d="M222.873,500.8l8.9,8.213,9.069-8.213" transform="translate(-217.065 -499.532)" fill="#fff" />
        <g transform="translate(4.365 0)">
          <rect data-name="Retângulo 14418" width="21" height="14" transform="translate(-0.365 0)" fill="none" />
          <path data-name="Caminho 239091" d="M219.873,498.8v12.662h19.849V498.8Zm16.585,1.711L229.8,506.54l-6.53-6.027Zm-14.874,9.24v-8.471l8.207,7.576,8.219-7.444v8.338Z" transform="translate(-219.457 -498.217)" />
        </g>
        <path data-name="Path 85" d="M258.8,540.8c0,6.806-2.763,9.521-9.712,9.582h-.212v-2.053c5.821,0,7.942-1.985,7.984-7.365V540.8Z" transform="translate(-234.167 -525.843)" />
        <path data-name="Path 85 Copy" d="M255.033,540.8c0,4.359-1.622,5.781-6.023,5.818h-.137V544.5h.234c3.065-.038,3.95-.812,3.991-3.491V540.8Z" transform="translate(-234.167 -525.843)" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedEmail;
