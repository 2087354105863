import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { withI18n } from 'react-i18next';

import { MY_ACCOUNT_URL } from '@/config/whmcsUrls';
import styles from './styles';


const LinkAccount = withStyles(styles)(withI18n()(({ t, classes }) => (
  <Button href={MY_ACCOUNT_URL} className={classes.button} target="_blank" rel="noopener noreferrer">
    {t('snackbar.actions.open')}
  </Button>
)));

const LinkAccountAction = () => (
  <LinkAccount />
);

export default LinkAccountAction;
