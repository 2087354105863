import React from 'react';
import * as Style from './IconSearch.style';

const IconSearch = ({ color = '#707070', testId = 'icon-search' }) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
      <path id="Caminho_247528" data-name="Caminho 247528" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_247529" data-name="Caminho 247529" d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z" />
    </svg>
  </Style.Wrapper>
);


export default IconSearch;
