import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button, FeedbackStepper, Header, Tooltip, Survey,
} from '@/pages/common';
import { Confirmation, OrderProcessing } from '@/components/billing';
import Loader from '@/components/Layout/Loader';
import { IconArrowWardsLeft } from '@/icons';
import { SubscriptionsQuestionTypes } from '@/redux/modules/billing/billing.types';
import { billingActions, preChatActions } from '@/redux/modules';
import { formatDate } from '@/utils/Formatters/formatDate';
import useLocale from '@/hooks/useLocale';
import { billingAnalytics } from '@/analytics';
import * as Styles from './CancelSubscriptionRenewalHandler.styles';
import createSubscriptionCancelRenewalHandler from './CancelSubscriptionRenewalHandler.functions';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_RETENTION_OFFER } from '@/config/GrowthBook/constants';
import { RetentionOfferPage } from '@/pages/billing/subscriptions/RetentionOfferPage';

const CancelSubscriptionRenewalHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { subscriptions: subscriptionsLocale } = billingLocale;
  const { cancel: cancelLocale } = subscriptionsLocale;
  const {
    confirmation: confirmationLocale,
    feedbackStepper: feedbackStepperLocale,
    survey: surveyLocale,
  } = cancelLocale;

  const { subscriptionId, subscriptionType } = useParams();

  const loading = useSelector(state => state.common.loading);
  const redirect = useSelector(state => state.common.redirect);
  const subscriptions = useSelector(state => state.billing.subscriptions.items);
  const questions = useSelector(state => state.billing.subscriptions.questions);
  const cancel = useSelector(state => state.billing.subscriptions.cancel);
  const { triage } = useSelector(state => state.preChat);
  const [subscription, setSubscription] = useState(undefined);
  const enabledRetentionOffer = useFeatureIsOn(XRAY_ENABLED_RETENTION_OFFER);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const openPreChatCancelFlow = useCallback(() => {
    if (triage.subscriptionToCancel) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
    }
  }, [triage, dispatch]);

  const canShowBackupLink = () => {
    if (subscription && subscription.showFilesExclusionText !== undefined) {
      return subscription.showFilesExclusionText;
    }
    return subscriptionType !== 'addon' && subscriptionType !== 'domain';
  };

  const {
    questionId, optionId, reason, modal,
  } = cancel;

  // Steps Enum
  const stepsStatus = {
    SURVEY: 1,
    CONFIRMATION: 2,
    DONE: 3,
    NEXT_RENEW_OFFER: 99,
  };

  const steps = [
    {
      id: stepsStatus.SURVEY,
      label: feedbackStepperLocale.stepSurveyLabel,
    },
    {
      id: stepsStatus.CONFIRMATION,
      label: feedbackStepperLocale.stepConfirmationLabel,
    },
  ];

  const [step, setStep] = useState(stepsStatus.SURVEY);

  const verifyParamsInUrl = useCallback(() => {
    if (enabledRetentionOffer) {
      const containsStep = window.location.href.includes('step=');
      const step = window.location.href.includes('step=2') ? 2 : 1;
      const shouldOpenInvoice = containsStep && step === 2;

      if (shouldOpenInvoice) {
        setStep(stepsStatus.CONFIRMATION);
      }
    }
  }, [setStep, enabledRetentionOffer, stepsStatus.CONFIRMATION]);

  useEffect(() => {
    verifyParamsInUrl();
  }, [verifyParamsInUrl]);

  const subscriptionCancelRenewalFunctions = createSubscriptionCancelRenewalHandler({
    history,
    questions,
    step,
    setStep,
    subscriptionAnalytics,
    subscriptions,
    subscriptionId: Number(subscriptionId),
    subscriptionType,
    stepsStatus,
    questionId,
    optionId,
    setSubscription,
    dispatch,
    openPreChatCancelFlow,
  });

  const getConfirmationProps = () => {
    const defaultProps = {
      attentionLabel: confirmationLocale.attentionLabel,
      backupFilesLinkLabel: confirmationLocale.backupFilesLinkLabel,
      backupFilesLinkUrl: confirmationLocale.backupFilesLinkUrl,
      stopCancelLabel: confirmationLocale.stopCancelRenewalLabel,
      title: confirmationLocale.confirmationTitleCancelRenewalLabel,
      showBackfilesLink: canShowBackupLink(),

    };
    const onClickBackupLink = () => {
      subscriptionAnalytics.cancelSubscriptionClick('4_link_veja_como_fazer_backup_cancelar_renovacao');
    };

    const onShowPageAction = () => {
      subscriptionAnalytics.cancelSubscriptionPageView('4_confirmar_cancelamento_renovacao');
    };

    if (!subscription && !subscription.dueDate) {
      return defaultProps;
    }

    const {
      type,
      cancelDate,
      dueDate,
    } = subscription;

    const getCancelledAddonsTopics = () => {
      if (subscription && subscription.addons) {
        return subscription.addons.map(addon => confirmationLocale.addonCancellation(addon, formatDate(dueDate)));
      }

      return [];
    };

    const getTopics = () => {
      if (type === 'domain') {
        return confirmationLocale.domainAttentionCancelRenewalTexts(formatDate(cancelDate));
      }
      if (type === 'addon' || !canShowBackupLink()) {
        return confirmationLocale.addonAttentionCancelRenewalTexts(formatDate(dueDate));
      }
      return confirmationLocale.attentionCancelRenewalTexts(formatDate(dueDate));
    };

    return {
      ...defaultProps,
      onStopCancel: () => subscriptionCancelRenewalFunctions.handleOnStopCancel(),
      topics: [getTopics(), ...getCancelledAddonsTopics()],
      showBackfilesLink: canShowBackupLink(),
      onClickBackupLink: () => { onClickBackupLink(); },
      onShowPageAction: () => { onShowPageAction(); },

    };
  };

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('3_pesquisa_respondida_cancelar_renovacao');
    subscriptionAnalytics.cancelRenewalPageView();
  }, [subscriptionAnalytics]);

  useEffect(() => {
    if (redirect) {
      subscriptionCancelRenewalFunctions.redirect(redirect.url);
    }
  }, [redirect, subscriptionCancelRenewalFunctions]);

  useEffect(() => {
    !enabledRetentionOffer && subscriptionCancelRenewalFunctions.resetCancelRenewalSurvey();
    if (questions[SubscriptionsQuestionTypes.CANCEL_RENEWAL]) {
      subscriptionCancelRenewalFunctions.resetOptionsByQuestionName(SubscriptionsQuestionTypes.CANCEL_RENEWAL);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(billingActions.subscriptions.request());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriptions.length) {
      subscriptionCancelRenewalFunctions.findSubscription();
    }
  }, [subscriptions, subscriptionId, subscriptionCancelRenewalFunctions]);

  useEffect(() => {
    if (!questions[SubscriptionsQuestionTypes.CANCEL_RENEWAL]) {
      subscriptionCancelRenewalFunctions.requestQuestions();
    }
  }, [questions, subscriptionCancelRenewalFunctions]);

  useEffect(() => {
    if (subscriptions.length && !subscription) {
      subscriptionCancelRenewalFunctions.settingSubscriptions();
    }
  }, [subscriptions, subscription, subscriptionCancelRenewalFunctions]);

  useEffect(() => {
    modal && history.push(routesLocale.subscriptions);
  }, [history, modal, routesLocale.subscriptions]);


  const handleDeclineRenewDiscount = useCallback(() => {
    setStep(stepsStatus.CONFIRMATION);
  }, [stepsStatus.CONFIRMATION]);

  if (loading || !subscription || !questions[SubscriptionsQuestionTypes.CANCEL_RENEWAL]) {
    return <Loader />;
  }

  const onFowardStepClick = () => {
    const actionSuccess = () => {
      setStep(stepsStatus.NEXT_RENEW_OFFER);
    };

    const actionFail = () => {
      subscriptionCancelRenewalFunctions.onFowardStepClick();
    };

    if (enabledRetentionOffer && step < stepsStatus.CONFIRMATION) {
      dispatch(billingActions.subscriptions.ableToCancelOffer.request(
        subscriptionId, subscriptionType, optionId, actionSuccess, actionFail,
      ));
    } else {
      subscriptionCancelRenewalFunctions.onFowardStepClick();
    }
  };

  return (
    <Styles.Wrapper data-testid="subscription-cancel-renewal-handler">
      <Styles.Header>
        <Header
          title={
            `${subscription.type === 'domain'
              ? billingLocale.domainLabel
              : subscription.productName} - `
          }
          description={subscription.domain}
        />
      </Styles.Header>
      <Styles.Content>
        <FeedbackStepper
          activeStep={step}
          steps={steps}
        />
        <Styles.StepContent>
          { step === stepsStatus.NEXT_RENEW_OFFER && (
            <RetentionOfferPage handleDeclineRenewDiscount={handleDeclineRenewDiscount} />
          )}
          {
            step === stepsStatus.SURVEY && (
              <Styles.StepSurveyContainer data-testid="subscription-cancel-renewal-step-survey">
                <Survey
                  questions={questions[SubscriptionsQuestionTypes.CANCEL_RENEWAL]}
                  questionId={questionId}
                  optionId={optionId}
                  reason={reason}
                  testId="subscription-cancel-renewal-survey"
                  onOptionSelected={e => subscriptionCancelRenewalFunctions.handleOnOptionSelected(e)}
                  onReasonChange={e => subscriptionCancelRenewalFunctions.handleOnReasonChange(e)}
                  questionDescriptionLabel={surveyLocale.surveyQuestionDescriptionLabel}
                  reasonQuestionLabel={surveyLocale.surveyReasonQuestionLabel}
                />
              </Styles.StepSurveyContainer>
            )
          }
          {
            step === stepsStatus.CONFIRMATION && (
              <Styles.StepConfirmationContainer data-testid="subscription-cancel-renewal-step-confirmation">
                <Confirmation {...getConfirmationProps()} />
              </Styles.StepConfirmationContainer>
            )
          }
          {
            step === stepsStatus.DONE && (
              <Styles.StepDoneContainer data-testid="subscription-cancel-renewal-step-done">
                <OrderProcessing text={cancelLocale.processing} />
              </Styles.StepDoneContainer>
            )
          }
        </Styles.StepContent>

        {step !== stepsStatus.NEXT_RENEW_OFFER && (
          <Styles.Controls disabled={step === stepsStatus.DONE}>
            {
              step !== stepsStatus.DONE && (
                <>
                  <Styles.ControlLeft>
                    <Button
                      label={confirmationLocale.controlBackButtonLabel.toUpperCase()}
                      iconLeft={<IconArrowWardsLeft />}
                      onClick={() => subscriptionCancelRenewalFunctions.onPreviousStepClick()}
                      testId="subscription-cancel-renewal-back-button"
                    />
                  </Styles.ControlLeft>
                  <Styles.ControlRight>
                    <Tooltip
                      width="270"
                      position="left"
                      text={confirmationLocale.tooltipCancelRenewalText}
                      disabled={!subscriptionCancelRenewalFunctions.isSurveyStepComplete()}
                    >
                      <Button
                        disabled={subscriptionCancelRenewalFunctions.isSurveyStepComplete()}
                        iconRight={step === stepsStatus.CONFIRMATION ? null : <IconArrowWardsLeft />}
                        iconRightDirection="right"
                        label={
                          (step === stepsStatus.CONFIRMATION
                            ? confirmationLocale.controlFowardButtonStepConfirmationCancelRenewalLabel
                            : confirmationLocale.controlFowardButtonLabel).toUpperCase()
                        }
                        onClick={() => onFowardStepClick()}
                        testId="subscription-cancel-renewal-foward-button"
                        variant="primary"
                      />
                    </Tooltip>
                  </Styles.ControlRight>
                </>
              )
            }
          </Styles.Controls>
        )}
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default CancelSubscriptionRenewalHandler;
