import React from 'react';
import * as Styles from './DiscountBlock.styles';
import { Tag } from 'gatorcomponents';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import useLocale from '@/hooks/useLocale/useLocale';

const DiscountBlock = ({
  loading = false,
  renovationAmountText = '',
  discount = null,
  testId = 'discount-block',
  enabledStrongText = true,
  installmentsAmountText = '',
}) => {
  const { billing: billingLocale } = useLocale();
  const {
    manageSubscriptionActiveRenewalModal: manageSubscriptionActiveRenewalModalLocale,
    manageSubscription: manageSubscriptionLocale,
  } = billingLocale;

  return (
    <Styles.Content data-testid={testId}>
      <Styles.Item>
        <Styles.Label data-testid={`${testId}-name-label`} enabledStrongText={enabledStrongText}>
          {installmentsAmountText !== '' ? installmentsAmountText : manageSubscriptionLocale.installmentsAmountLabel}
        </Styles.Label>
        <Styles.Text data-testid={`${testId}-text`}>
          <Styles.LineThrough>
            {(loading || !renovationAmountText) ? '...' : renovationAmountText}
          </Styles.LineThrough>
          <>
            {enabledStrongText && (
              <strong>
                {formatCurrency(discount.amount)}
                *
              </strong>
            )}
            {!enabledStrongText && (
              <p>
                {formatCurrency(discount.amount)}
              </p>
            )}
            <Styles.TagWrapper>
              <Tag label={`${discount.percent}${manageSubscriptionActiveRenewalModalLocale.discount}`} testId={`${testId}-discount-text`} />
            </Styles.TagWrapper>
          </>
        </Styles.Text>
      </Styles.Item>
    </Styles.Content>
  );
};
export default DiscountBlock;
