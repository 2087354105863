import React from 'react';

import * as Styles from './TwoFactorAuth.styles';
import { IconAsterisk, IconCancel } from 'gatorcomponents';

const TwoFactorAuth = ({
  userEmail,
  twoFactorIsEnabled,
  twoFactorAuthLocale,
  twoAuthButtonClick,
}) => {
  const testId = 'two-factor-auth';
  const twoFactorAuthButtonText = twoFactorIsEnabled ? twoFactorAuthLocale.statusButtonLabel.disable : twoFactorAuthLocale.statusButtonLabel.enable;
  const TwoFactorAuthStatus = twoFactorIsEnabled ? twoFactorAuthLocale.status.enabled : twoFactorAuthLocale.status.disabled;

  return (
    <Styles.Container data-testid={testId}>
      <div><IconAsterisk size="sm" /></div>
      <Styles.TwoFactorInfos>
        <Styles.Title data-testid={`${testId}_title`}>
          {twoFactorAuthLocale.title}
        </Styles.Title>
        <Styles.Description data-testid={`${testId}_description`}>{twoFactorAuthLocale.description(userEmail)}</Styles.Description>
        <Styles.StatusContainer>
          <Styles.StatusTextContainer>
            <Styles.StatusTitle>{twoFactorAuthLocale.status.title}</Styles.StatusTitle>
            <Styles.StatusDescription data-testid={`${testId}_status`}>
              <IconCancel size="sm" color={twoFactorIsEnabled ? 'activeLight' : 'problemLight'} />
              {TwoFactorAuthStatus}
            </Styles.StatusDescription>
          </Styles.StatusTextContainer>
          <Styles.StatusButton onClick={twoAuthButtonClick} isTwoFactorEnabled={twoFactorIsEnabled} data-testid={`${testId}_button`}>
            {twoFactorAuthButtonText}
          </Styles.StatusButton>
        </Styles.StatusContainer>
      </Styles.TwoFactorInfos>
    </Styles.Container>
  );
};

export default TwoFactorAuth;
