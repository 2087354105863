import {
  REQUEST_TICKET_DETAIL, RECEIVE_TICKET_DETAIL, SAVE_TICKET_COMMENT_START, SAVE_TICKET_COMMENT_SUCCESS,
  SAVE_TICKET_COMMENT_ERROR, RECEIVE_COMMENT_TICKET_DETAIL, UPLOAD_TICKET_IMAGES_START,
  UPLOAD_TICKET_IMAGES_SUCCESS, UPLOAD_TICKET_IMAGES_ERROR, RECEIVE_UPLOAD_TICKET_DETAIL,
  REQUEST_SAVE_TICKET_FEEDBACK, ERROR_SAVE_TICKET_FEEDBACK, SUCCESS_SAVE_TICKET_FEEDBACK,
  SUCCESS_CLOSE_TICKET, ERROR_CLOSE_TICKET, UPDATE_TICKET_DETAIL_STATUS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  loadingStatusChange: false,
  id: '',
  status: '',
  subject: '',
  comments: [],
  body: '',
  loadingUpload: false,
};

export default function ticketDetail(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TICKET_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_TICKET_DETAIL:
      return {
        ...state,
        loading: false,
        loadingUpload: false,
        ...action.ticketDetail,
        comments: action.ticketDetail.comments.comments,
      };

    case RECEIVE_COMMENT_TICKET_DETAIL: {
      const newComments = [...state.comments];
      const newComment = action.ticketDetail.data.audit.events['0'];

      newComment.person = 'me';
      newComment.elapsedTime = [1, 'second'];
      newComment.created_at = action.ticketDetail.data.audit.created_at;
      newComments.push(newComment);

      return {
        ...state,
        loadingComment: false,
        comments: newComments,
      };
    }
    case SAVE_TICKET_COMMENT_START:
      return {
        ...state,
        loadingComment: true,
      };
    case SAVE_TICKET_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        comment: {
          body: action.data.comment,
        },
      };
    case SAVE_TICKET_COMMENT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    // UPLOAD
    case RECEIVE_UPLOAD_TICKET_DETAIL: {
      const newCommentsUpload = [...state.comments];
      const newCommentUpload = action.ticketDetail.data.audit.events['0'];

      newCommentUpload.id = action.ticketDetail.data.audit.id;
      newCommentUpload.person = 'me';
      newCommentUpload.elapsedTime = [1, 'second'];
      newCommentUpload.created_at = action.ticketDetail.data.audit.created_at;
      newCommentsUpload.push(newCommentUpload);

      return {
        ...state,
        loadingUpload: false,
        comments: newCommentsUpload,
      };
    }
    case UPLOAD_TICKET_IMAGES_START:
      return {
        ...state,
        loadingUpload: true,
      };
    case UPLOAD_TICKET_IMAGES_SUCCESS:
      return {
        ...state,
        loadingUpload: false,

        comment: {
          body: action.upload.comment,
          file: action.upload.file,
        },
      };
    case UPLOAD_TICKET_IMAGES_ERROR:
      return {
        ...state,
        error: true,
        loadingUpload: false,
      };
    case REQUEST_SAVE_TICKET_FEEDBACK:
      return {
        ...state,
        loadingStatusChange: true,
      };
    case SUCCESS_SAVE_TICKET_FEEDBACK:
      return {
        ...state,
        loadingStatusChange: false,
      };
    case ERROR_SAVE_TICKET_FEEDBACK:
      return {
        ...state,
        error: true,
        loadingStatusChange: false,
      };
    case ERROR_CLOSE_TICKET:
      return {
        ...state,
        error: true,
        loadingStatusChange: false,
      };
    case SUCCESS_CLOSE_TICKET:
      return {
        ...state,
        loadingStatusChange: false,
      };

    case UPDATE_TICKET_DETAIL_STATUS:
      return {
        ...state,
        status: 'open',
      };

    default:
      return state;
  }
}
