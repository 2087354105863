import React from 'react';
import * as Style from './IconVPS.styles';

const IconVPS = ({ testId = 'icon-vps' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="Grupo_40862" data-name="Grupo 40862" transform="translate(-587.498 -577)">
        <rect id="Retângulo_16281" data-name="Retângulo 16281" width="22" height="22" transform="translate(587.498 577)" fill="none" />
        <g id="Grupo_40811" data-name="Grupo 40811" transform="translate(587.498 578.486)">
          <path id="Caminho_238750" data-name="Caminho 238750" d="M829.226,152.656a11,11,0,0,1,22,0Z" transform="translate(-829.226 -137.642)" fill="#ffd54c" />
          <g id="Grupo_36339" data-name="Grupo 36339" transform="translate(1.098 7.641)">
            <path id="Caminho_238751" data-name="Caminho 238751" d="M842.254,160.16H832.187l5.034-8.719Zm-7.5-1.483h4.932l-2.465-4.271Z" transform="translate(-832.187 -151.441)" fill="#4a4a4a" />
          </g>
          <g id="Grupo_36340" data-name="Grupo 36340" transform="translate(10.978 7.641)">
            <path id="Caminho_238752" data-name="Caminho 238752" d="M868.908,160.16H858.841l5.034-8.719Zm-7.5-1.483h4.931l-2.465-4.271Z" transform="translate(-858.841 -151.441)" fill="#4a4a4a" />
          </g>
          <path id="Caminho_238753" data-name="Caminho 238753" d="M847.6,148.245l-6.83,11.83h13.66Z" transform="translate(-836.489 -141.789)" fill="#fff" />
          <path id="Caminho_238754" data-name="Caminho 238754" d="M837.3,149.853h16.228l-7.392-12.8V135.3h3.895v-4.471h-5.4V135.3h.019v1.821Zm8.817-17.545h2.431v1.506h-2.431Zm-6.249,16.063,5.546-9.605,5.546,9.605Z" transform="translate(-834.308 -130.825)" fill="#4a4a4a" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconVPS;
