import React from 'react';
import * as Styles from './GetNow.styles';
import Sitelock from '@/media/codeguard/codeguard-logo.png';
import SnappyFace from '@/media/sitelock/snappy-face.png';
import { GetNowForm } from './components/GetNowForm';

const GetNow = ({
  paymentCycles,
  paymentMethods,
  title = 'protect your website of cyber threats with SiteLock',
  description = 'The ideal solution to find and fix threats to your website automatically.',
  listTitle = 'hire the SiteLock Professional and count on:',
  featureList = {
    firstColumn: [
      'Proteção de até 100 páginas',
      'Selo de segurança do site',
      'Varreduras frequentes',
      'Remoção automática de malwares',
    ],
    secondColumn: [
      'Verificação diária de FTP',
      'Verificação de edição de arquivos',
      'Monitoramento de blocklist',
      'E muito mais',
    ],
  },
  formTitle = 'professional plan',
  cycleLabel = 'Select cycle:',
  setSelectedCycle,
  selectedCycle,
  selectePaymentLabel = 'Select the payment method:',
  activeCycle,
  toBePaid = 'To be paid',
  hgCodeguard,
  getCodeGuard,
  isLoadingPayment,
  setSelectedPaymentMethod,
  needMoreLabel,
  needMoreButtonText,
  codeGuardAnalytics,
}) => {
  const getNowProps = () => {
    const defaultPorps = {
      paymentCycles,
      paymentMethods,
      formTitle,
      cycleLabel,
      setSelectedCycle,
      selectedCycle,
      selectePaymentLabel,
      activeCycle,
      toBePaid,
      hgCodeguard,
      getCodeGuard,
      isLoadingPayment,
      setSelectedPaymentMethod,
      needMoreLabel,
      needMoreButtonText,
      codeGuardAnalytics,
    };

    return defaultPorps;
  };

  return (
    <Styles.Wrapper>
      <Styles.Left>
        <Styles.Logo src={Sitelock} />

        <Styles.TitleWrapper>
          {title}
        </Styles.TitleWrapper>

        <Styles.DescriptionWrapper>
          {description}
        </Styles.DescriptionWrapper>

        <Styles.MobileForm>
          <GetNowForm {...getNowProps()} />
        </Styles.MobileForm>

        <Styles.ListTitleWrapper>
          {listTitle}
        </Styles.ListTitleWrapper>

        <Styles.ListWrapper>
          <Styles.ListItems>
            {featureList.firstColumn.map(item => (<Styles.ListItem key={item}>{item}</Styles.ListItem>))}
          </Styles.ListItems>

          <Styles.ListItems>
            {featureList.secondColumn.map(item => (<Styles.ListItem key={item}>{item}</Styles.ListItem>))}
          </Styles.ListItems>
        </Styles.ListWrapper>
      </Styles.Left>

      <Styles.Right>
        <GetNowForm {...getNowProps()} />
      </Styles.Right>

      <Styles.BottomDetail>
        <Styles.SnappyDetail src={SnappyFace} />
      </Styles.BottomDetail>
    </Styles.Wrapper>
  );
};

export default GetNow;
