const styles = theme => ({
  container: {
    borderRadius: 0,
    marginTop: '20px',
    marginBottom: '15px',
    fontWeight: 500,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
  },
  unpaid: {
    borderLeft: `5px solid ${theme.color.brightSun}`,
    '& .receiptIcon': {
      color: theme.color.brightSun,
    },
  },
  unpaidExpired: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .receiptIcon': {
      color: theme.color.valencia,
    },
  },
});

export default styles;
