import React, { useEffect } from 'react';
import { Modal } from '@/pages/common';
import { Button } from '@/pages/common/v1';
import * as Styles from './ModalEcommercePlusOffer.styles';
import modalDecoLine from '@/media/newSiteModal/modal-decoline.svg';
import modalDeco from '@/media/newSiteModal/modal-deco.svg';
import builderAndWordpress from '@/media/products/wordpress-sitebuildbuilder.svg';
import { modalCloseAction } from '../../SitesHandler/SitesHandler.enum';

const ModalEcommercePlusOffer = ({
  title = 'title sample',
  buttonLabel = 'button label',
  backButtonLabel = 'back button label',
  descriptionFirst = () => 'description sample',
  contentDomainName = 'domain.com',
  descriptionSecond = 'description sample',
  onClose,
  onModalOpen,
}) => {
  const handleContinue = () => {
    onClose && onClose(modalCloseAction.createSite);
  };

  const handleClose = () => {
    onClose && onClose(modalCloseAction.close);
  };

  useEffect(() => {
    onModalOpen();
  }, [onModalOpen]);

  return (
    <Modal testId="ModalEcommercePlusOffer" maxWidth={633} disablePadding>
      <Styles.ContentWrapper>
        <Styles.Deco src={modalDeco} alt="Deco" />

        <Styles.Title data-testid="ModalEcommercePlusOfferTitle">
          {title}
        </Styles.Title>

        <Styles.Description data-testid="ModalEcommercePlusOfferDescription">
          {descriptionFirst(contentDomainName)}
        </Styles.Description>

        <Styles.Description>
          {descriptionSecond}
        </Styles.Description>

        <Styles.Action>
          <Button
            testId="createSiteButton"
            onClick={handleContinue}
            label={buttonLabel}
          />
          <Button
            variant="text"
            testId="backButton"
            onClick={handleClose}
            label={backButtonLabel}
          />
        </Styles.Action>

        <Styles.Image src={builderAndWordpress} alt={buttonLabel} />

        <Styles.DecoLine src={modalDecoLine} alt="DecoLine" />
        <Styles.DecoTriangle />
      </Styles.ContentWrapper>
    </Modal>
  );
};

export default ModalEcommercePlusOffer;
