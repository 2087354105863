import React, { useState, useEffect } from 'react';
import { withI18n } from 'react-i18next';
import { IconClose } from '@/icons';
import * as Style from './TopTitanAdvertisementHandler.styles';
import DecoLineLarge from '@/media/survey/decoline-large.svg';
import arrow from '@/media/icons/icon_arrow_right.svg';
import titanIcon from '@/media/icons/icon_titan.svg';
import { isBRServer } from '@/utils/Validators/validation';
import { executeWithParams } from '@/utils/ThirdParties/tagManager';

const TopTitanAdvertisementHandler = ({
  newTag, description, descriptionDomain, title, labelLink, displayModalTitanAdvertisementHandler,
  setDisplayModalTitanAdvertisementHandler, typeAdvertisement,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (visible) {
      executeWithParams(
        'gaEvent',
        'e-mail',
        `${typeAdvertisement} - ativação Titan em Meus Sites`,
        'Exibição top banner',
      );
    }
  }, [visible, typeAdvertisement]);


  const executeGAWhenClickDisplayPlans = () => {
    executeWithParams(
      'gaEvent',
      'e-mail',
      `${typeAdvertisement} - ativação Titan em Meus Sites`,
      ' Ver planos disponíveis',
    );
  };

  const handleClick = () => {
    setDisplayModalTitanAdvertisementHandler(!displayModalTitanAdvertisementHandler);
    executeGAWhenClickDisplayPlans();
  };

  const handleClose = () => {
    setVisible(false);

    executeWithParams(
      'gaEvent',
      'e-mail',
      `${typeAdvertisement} - ativação Titan em Meus Sites`,
      ' Fechar top banner',
    );
  };


  return (
    visible
    && (
    <>
      <Style.Wrapper data-testid="top-titan-advertisement-handler">
        <Style.Info>
          <Style.NewLabel isBr={isBRServer}>
            <span>{newTag}</span>
          </Style.NewLabel>
          <Style.ContentTitle>
            <Style.TitanIcon src={titanIcon} alt="TitanIcon" />
            <Style.Title>{title}</Style.Title>
          </Style.ContentTitle>
          <Style.ContentDescription>
            <Style.Description>
              {description}
              <Style.DescriptionDomain>{descriptionDomain}</Style.DescriptionDomain>
            </Style.Description>
          </Style.ContentDescription>
          <Style.CTA
            data-testid="top-titan-advertisement-link"
            isBRServer={isBRServer}
            onClick={handleClick}
            to="#"
            arrow={arrow}
          >
            {labelLink}
            <Style.ArrowIcon arrow={arrow} />
          </Style.CTA>
        </Style.Info>
        <Style.CloseButton onClick={handleClose} data-testid="top-advertisement-close">
          <IconClose color="gray" />
        </Style.CloseButton>

      </Style.Wrapper>
      <Style.Footer>
        <Style.DecoLine src={DecoLineLarge} alt="DecoLine" />
      </Style.Footer>
    </>
    )
  );
};

export default withI18n()(TopTitanAdvertisementHandler);
