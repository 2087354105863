import BannerToEnableTitanTrial from '@/components/Email/BannerToEnableTitanTrial';
import ModalTitanTrialColorful from '@/components/Email/ModalTitanTrialColorful';
import { ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM } from '@/config/GrowthBook/constants';
import { queryParams } from '@/utils/Application/queryParams';
import { executeGADisplayBannerFromDomains, executeWithParams } from '@/utils/ThirdParties/tagManager';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { locale } from '@/utils/locale';

const TitanTrialBanner = ({ domainWithoutEmail, essentialData }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [enableTrialState, setEnableTrialState] = useState({
    modalIsOpen: false,
    isEnabledByURLParam: false,
    hideBanner: true,
    currentDomain: {
      id: null,
      domain: null,
    },
  });

  const enableTrialTitanByUrlParam = useFeatureIsOn(ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM);
  const history = useHistory();

  const domainWithoutEmailFormattedToOptions = domainWithoutEmail.map(item => ({ value: item.id, label: `@${item.domain}` }));

  const enableTrialByBannerGAEventsByAction = useCallback((action) => {
    executeWithParams(
      'gaEvent',
      'e-mail',
      'Banner trial de Titan - aba de Domínios',
      action,
    );
  }, []);

  const handleCloseModal = useCallback(
    () => {
      enableTrialByBannerGAEventsByAction('Fechar modal');

      setEnableTrialState(previousEnableTrialState => ({
        ...previousEnableTrialState,
        modalIsOpen: false,
        currentDomain: { id: null, domain: null },
      }));
      if (enableTrialTitanByUrlParam && queryParams(history.location.search, 'dspActtrial')) {
        history.push(locale('routes.domains'));
      }
    }, [setEnableTrialState, enableTrialByBannerGAEventsByAction, enableTrialTitanByUrlParam, history],
  );

  const handlerChangeBannerSelect = useCallback(({ value, label }) => {
    enableTrialByBannerGAEventsByAction('Selecionar domínio');

    setEnableTrialState(previousEnableTrialState => ({
      ...previousEnableTrialState,
      modalIsOpen: true,
      currentDomain: { id: value, domain: label },
    }));
  }, [setEnableTrialState, enableTrialByBannerGAEventsByAction]);

  const onBannerLoaded = useCallback(() => {
    if (!isLoaded) {
      executeGADisplayBannerFromDomains();
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalTitanTrialColorful
        isOpen={enableTrialState.modalIsOpen}
        isEnabledByURLParam={enableTrialState.isEnabledByURLParam}
        onClose={handleCloseModal}
        selectedDomain={enableTrialState.currentDomain}
        essentialData={essentialData}
        offerContext={['domain_page_banner_trial_activation']}
        location="modalDomainList"
      />
      <BannerToEnableTitanTrial
        domainOptions={domainWithoutEmailFormattedToOptions}
        onDomainSelected={handlerChangeBannerSelect}
        productName={essentialData.name}
        onBannerLoaded={onBannerLoaded}
      />
    </>
  );
};

export default TitanTrialBanner;
