import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 0px;
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    padding: 20px 30px 26px;
    box-shadow: ${theme.v2.shadows.light};
    margin: 24px 0px 0px;
  `}
`;

export const AlertTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 16px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;

    svg {
      flex-shrink: 0;
    }
  `}
`;

export const AlertDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 16px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin: 24px 0px 20px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      display: flex;
      justify-content: center;
    }
  `}
`;

export const ProceedAlertTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-top: 22px;
  `}
`;

export const ProceedAlertDescriptionWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    margin: 27px 0px 0px 0px;

    svg {
      flex-shrink: 0;
    }
`;

export const ProceedAlertDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;
