import React from 'react';
import {
  Modal,
} from '@/pages/common';
import * as Styles from './WizardWordpressConfigModal.styles';
import Loader from '@/components/Layout/Loader';

const WizardWordpressConfigModal = ({ modalTitle }) => (
  <Modal testId="wordpress-install-modal" disablePadding maxWidth={624}>
    <Styles.Wrapper>
      <Styles.Title data-testid="wordpress-install-modal-title">
        {modalTitle}
      </Styles.Title>
      <Loader data-testid="wordpress-install-modal-loader" />
    </Styles.Wrapper>
  </Modal>
);

export default WizardWordpressConfigModal;
