import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
  display: inline-flex;
`;

export const Mark = styled.mark`
  ${({
    theme, variant, color, textColor, weight,
  }) => {
    const variants = {
      default: {
        color: theme.palette.green.dark,
        fontSize: theme.font.sizes.md,
        fontWeight: theme.font.weights.medium,
        textColor: theme.palette.grey.ultradark,
      },
      sunLight: {
        color: theme.palette.yellow.light,
        fontSize: theme.font.sizes.sm,
        fontWeight: theme.font.weights.medium,
        textColor: theme.palette.grey.ultradark,
      },
      green: {
        color: theme.palette.green.main,
        fontSize: theme.font.sizes.md,
        fontWeight: theme.font.weights.medium,
        textColor: theme.palette.grey.ultradark,
      },

    };
    const fontWeight = theme.font.weights[weight];

    return css`
    background: ${theme.palette.white.light} 0% 0% no-repeat padding-box;
    border-bottom: 4px solid ${color || variants[variant].color};
    border-left:none;
    border-right:none;
    border-top:none;
    color:${textColor || variants[variant].textColor};
    display: inline-flex;
    font-family: ${theme.font.family.secondary};
    font-size:${variants[variant].fontSize};
    font-weight:${fontWeight};
    opacity: 1;
    text-align:left;
    `;
  }}
`;
