import React from 'react';
import * as Style from './IconCopy.style';

const IconCopy = ({
  color = '#4480C5',
  testId = 'icon-copy',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="content_copy_black_24dp" xmlns="http://www.w3.org/2000/svg" width={13} height={16} viewBox="0 0 24 24">
      <path id="Caminho_247856" data-name="Caminho 247856" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_247857" data-name="Caminho 247857" d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Zm0,16H8V7H19Z" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconCopy;
