import styled, { css } from 'styled-components';

const defaultWrapperWidth = '498px';

export const Form = styled.form``;

export const Info = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 0px;

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0px 0px;
  max-width: ${defaultWrapperWidth};

  div {
    width: 100%;
  }
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 24px 0px 0px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};
    
    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const Alert = styled.span`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    lin-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 40px;
    gap: 8px;

    svg {
      flex-shrink: 0;
    }
  `}
`;

export const AlertDescription = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.family.regular};
    lin-height: ${theme.v2.font.lineHeight.text};
    max-width: 1006px;

    svg {
      flex-shrink: 0;
    }
  `}
`;
