import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { AsaasActionTypes } from './asaas.types';
import asaasActions from './asaas.actions';
import { assembledAccount, assembledError } from './asaas.assembly';
import { commonActions } from '../common';
import Cookies from 'js-cookie';
import { authActions } from '../auth';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';

function* createSubAccount(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const { connectionErrorValidationPart, conectionError, changeToFormConclusion } = payload;
  delete payload.connectionErrorValidationPart;
  delete payload.conectionError;
  delete payload.changeToFormConclusion;

  yield put(asaasActions.loading.set(true));
  try {
    const request = {
      url: '/api/v3/asaas/create-account',
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data: {
        ...payload,
      },
    };
    const response = yield call(api.whmcs.post, request);
    const createdAccount = assembledAccount(response);

    yield put(asaasActions.createAccount.success());
    yield put(asaasActions.createAccount.set(createdAccount));
    changeToFormConclusion && changeToFormConclusion('finished');
  } catch (e) {
    const assembledErrorMessage = assembledError(e);

    if (assembledErrorMessage.includes(connectionErrorValidationPart)) {
      changeToFormConclusion && changeToFormConclusion('error');
    }

    if (!assembledErrorMessage || (assembledErrorMessage && !assembledErrorMessage.includes(connectionErrorValidationPart))) {
      yield put(commonActions.notifications.set({
        label: conectionError,
        type: 'error',
      }));
    }

    yield put(asaasActions.createAccount.failure());
  }

  yield put(asaasActions.loading.set(false));
}

function* sagas() {
  yield takeLatest(AsaasActionTypes.ASAAS_CREATE_SUBACCOUNT_REQUEST, createSubAccount);
}

export default sagas;
