import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.light};
    padding-bottom: 350px;
    padding: 40px 8px 32px 8px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 24px 0px 24px;
      padding-bottom: 220px;
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.boxes};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.line.fields};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 28px 24px 24px 24px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.lg}) {
      padding: 24px 30px 32px 30px;
    }
  `}
`;

export const TitleRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center; 
      flex-direction: column;
      gap: 8px;
    }
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    height: 31px;
    margin-right: 16px;
    width: 31px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-right: 0px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0px 23px 0px 0px;
    text-align: center;

    @media (max-width: ${theme.v1.breakpoints.lg}) {
      margin: 0px;
    }
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin: 16px 0px 0px 0px;
    p {
      margin: 0;
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      margin-left: 14px;
      margin-right: 5px;
      margin-top: 15px;
    }
  `}
`;

export const HiddenInfo = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 16px 0px 0px 0px
    }
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-right: 15px;
    }
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      color: ${theme.v1.color.text.primary};
      font-size: ${theme.font.sizes.sm};
    }
  `}
`;

export const SeeBellowWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    margin-bottom: 24px;
    margin-top: 40px;
    span{
      color: ${theme.v1.color.text.featured};
    }
  `}
`;

export const SubtitleText = styled.p``;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      margin-top: 24px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      flex-direction: column;
    }
  `}
`;
