import axios, { API_URL_V3 } from '@/config/api';
import { LOGOUT_URL } from '@/config/whmcsUrls';
import { API_REQUEST_ERROR } from '@/redux/actions/actionsTypes';
import { notification } from '@/redux/actions/notifications';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { config, COUNTRY } from '@/config/index';
import { setAuthorizationToken, sanitizeHeaderByAuthorizationType } from '@/middleware/headerConfigs';
import { refreshToken } from '@/middleware/refreshToken';
import { setJWT, setRefreshToken } from '@/redux/actions/auth';

export const CALL_API = 'CALL_API';

const api = store => next => async (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const {
    endpoint,
    method,
    actionTypes,
    mock = false,
    mockData,
    body,
    customHeaders,
    authorizationType,
    statusCode = false,
    enableBrand = true,
  } = callAPI;

  const headers = {};
  const { auth, summary } = store.getState();

  const onSetJwtToken = (token) => {
    store.dispatch(setJWT(token));
  };

  const onSetRefreshToken = (newRefreshToken) => {
    store.dispatch(setRefreshToken(newRefreshToken));
  };

  const tokens = authorizationType === 'jwt' ? await refreshToken(auth, {
    onSetJwtToken,
    onSetRefreshToken,
    store: store.dispatch,
  }) : auth;

  headers.Authorization = setAuthorizationToken(authorizationType, tokens);

  next(actionTypes.request());

  if (mock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(next(actionTypes.success(mockData)));
      }, 1000);
    });
  }

  const defaultHeader = {
    ...headers,
    ...customHeaders,
    'Content-Type': 'application/json',
  };

  if (enableBrand) {
    defaultHeader.brand = COUNTRY;
  }

  const options = {
    headers: sanitizeHeaderByAuthorizationType(authorizationType, defaultHeader),
    method,
    url: endpoint,
    data: body,
  };

  const showNotifications = (notifications) => {
    notifications && notifications.forEach((item) => {
      if (item.errors && item.errors.error_tag) {
        store.dispatch(notification(item.errors.error_tag, notifierErrorLong));
      }
    });
  };

  const setLocalStorareUrl = async () => {
    const { id: userId } = summary;
    const content = {
      id: userId,
      location: document.location.pathname,
    };

    await localStorage.setItem('lastPath', JSON.parse(content));
  };

  const isExternalService = (url) => {
    if (url.indexOf('v3') !== -1) {
      return url.indexOf(API_URL_V3) === -1;
    }
    return url.indexOf(config.API_URL) === -1;
  };

  return axios(options)
    .then((resp) => {
      const { data } = resp;

      if (data && data.notifications) {
        const { notifications } = data;
        if (data.notifications[0] && data.notifications[0].errors && data.notifications[0].errors.error_tag !== 'cpanel_instability') {
          showNotifications(notifications);
        }
      }

      const innerData = statusCode ? resp : data;

      return next(actionTypes.success(innerData));
    })
    .catch((error) => {
      const { response, config } = error;

      if (!isExternalService(config ? config.url : '') && response && response.status === 401) {
        setLocalStorareUrl();
        window.location = LOGOUT_URL;
      }

      if (response && response.status === 400) {
        if (response && response.data) {
          const { notifications } = response.data;
          showNotifications(notifications);
        }
      }

      if (response && response.status && statusCode) {
        return next(actionTypes.error(response));
      }

      return next(actionTypes.error ? actionTypes.error(error) : { type: API_REQUEST_ERROR, error });
    });
};

export default api;
