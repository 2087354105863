import {
  isAfter, isBefore, isEqual, parse,
} from 'date-fns';
import { COUNTRY, ENVIRONMENT } from '@/config';

export const domainRegex = /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/;
export const domainRegexWithUpperCase = /\b((?=[aA-z0-9-]{1,63}\.)(xn--)?[aA-z0-9]+(-[aA-z0-9]+)*\.)+[a-z]{2,63}\b/;
export const domain = value => domainRegex.test(value);
export const format = (value) => {
  const regexPunycode = /(xn--)+/;
  const punycodeResult = regexPunycode.test(value);
  if (punycodeResult) {
    return false;
  }
  const regex = /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/;
  return regex.test(value);
};

export const onlyNumberDomain = (value) => {
  const valueWithoutExtension = value.split('.')[0];
  const regex = /(?!^\d+$)^.+$/;
  return regex.test(valueWithoutExtension);
};

export const domainOnlyWithHyphen = (value) => {
  const valueWithoutExtension = value.split('.')[0];
  const regex = /[^-]/;
  return regex.test(valueWithoutExtension);
};

export const domainWithSpecialCharacters = (value) => {
  const valueWithoutExtension = value.split('.')[0];
  const regex = /^[^$^+]+$/;
  return regex.test(valueWithoutExtension);
};

export const hasSpecialCharacters = (value) => {
  const regex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~¨Çªº€£¥¢§®©™µ∆∞≠≈≤≥∑∏√]/;
  return regex.test(value);
};

export const validateDomain = (fullDomain) => {
  const parts = fullDomain.split('.');
  let error = {};

  if (parts.length < 3) {
    error = { message: 'validations.domainParts' };
  }

  return {
    valid: !error.message,
    message: error,
  };
};

export const validate = (sld, tld, isDomainExists = false) => {
  let error;
  const maxLengthBr = 26;
  const maxLength = 63;
  const minLengthRegisterNewDomain = tld.indexOf('.br') !== -1 ? 2 : 3;
  const minLength = isDomainExists ? 2 : minLengthRegisterNewDomain;

  const fullDomain = sld + tld;

  if (!sld || !tld) {
    error = 'required';
  } else if (sld.length < minLength) {
    error = 'minlength';
  } else if (sld.length > maxLengthBr && tld.indexOf('.br') !== -1) {
    error = 'maxlength';
  } else if (fullDomain.length > maxLength) {
    error = 'maxlength';
  } else if (!format(fullDomain)) {
    error = 'invalid';
  } else if (!onlyNumberDomain(fullDomain)) {
    error = 'invalid';
  } else if (!domainWithSpecialCharacters(fullDomain)) {
    error = 'invalid';
  } else if (!domainOnlyWithHyphen(fullDomain)) {
    error = 'invalid';
  } else if (sld === 'www' || sld.indexOf('www.') !== -1) {
    error = 'invalid';
  }

  return {
    error,
    valid: !error,
  };
};

export const cnpjCpfSize = (userInput) => {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join('').length;
  }
  return numberLength;
};

export const replaceAccents = (value) => {
  if (ENVIRONMENT !== 'production') {
    console.error('replaceAccents is deprecated');
  }

  let strAccentsOut = [];
  const strAccentsLen = value.length;
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  for (let y = 0; y < strAccentsLen; y += 1) {
    if (accents.indexOf(value[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(value[y]), 1);
    } else {
      strAccentsOut[y] = value[y];
    }
  }
  strAccentsOut = strAccentsOut.join('');
  return strAccentsOut;
};

export const removeDomainPrefix = value => value.replace(/^(www|www2|www3)\.|^(http|https):(\/\/www.|\/\/|www)|^(ftp|ftps):\/\/|^file:\/\/|^mailto:|news:/, '');

export const removeAccents = (str) => {
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  const strSplitted = str.split('');
  const strSanitized = [];
  let x;

  strSplitted.forEach((letter) => {
    x = accents.indexOf(letter);
    if (x !== -1) {
      strSanitized.push(accentsOut[x]);
    } else {
      strSanitized.push(letter);
    }
  });

  return strSanitized.join('');
};

export const validateCpf = (value) => {
  const cpf = value.replace(/[^\d]+/g, '');
  if (cpf.length > 11) return true;
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999') {
    return false;
  }
  let add;
  let rev;
  let i;
  // Valida 1o digito
  add = 0;
  for (i = 0; i < 9; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
};

export const validateCnpj = (value) => {
  const cnpj = value.replace(/[^\d]+/g, '');

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) {
    return false;
  }

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Cálculo de validação
  const t = cnpj.length - 2;
  const d = cnpj.substring(t);
  const d1 = parseInt(d.charAt(0), 10);
  const d2 = parseInt(d.charAt(1), 10);
  const calc = (x) => {
    const n = cnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    for (let i = x; i >= 1; i -= 1) {
      // eslint-disable-next-line no-plusplus
      s += n.charAt(x - i) * y--;
      if (y < 2) {
        y = 9;
      }
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
};

export const validateEmail = (value) => {
  // eslint-disable-next-line
  const regex = /^([a-z0-9]+[a-z+-_0-9\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
  return regex.test(value);
};

export const validateFullName = (value) => {
  const regex = /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.-]{2,50})(\s[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.-]{1,50})+$/;
  return regex.test(value.replace(/\s+$/g, ''));
};

export const validateCompanyName = (name) => {
  const onlyLettersPattern = /^[a-zA-Z]+$/;
  return name && name.includes(' ') && name.replace(/\s/g, '').length <= 50 && onlyLettersPattern.test(name.replace(/\s/g, ''));
};

export const validatePersonName = (name) => {
  const onlyLettersPattern = /^[a-zA-Z]+$/;
  return name && name.includes(' ') && name.replace(/\s/g, '').length <= 40 && onlyLettersPattern.test(name.replace(/\s/g, ''));
};

export const validateEmailCpanel = (value) => {
  const regex = /[ !@#$%^&*()+=[\]{};':"\\|,<>/?]/;
  return regex.test(value);
};

export const validateEmailName = (value) => {
  const regex = /^[0-9.-_a-z-A-Z]+$/;
  if (regex.test(value)) {
    return value;
  }
  return value.slice(0, value.length - 1);
};

export const validateQuota = (value) => {
  if (value.length === 1 && value === '0') {
    return value.slice(0, value.length - 1);
  }
  return value;
};

export const required = value => value !== '';

export const minDate = (value, params) => {
  const date = parse(value, 'dd/MM/yyyy', new Date());
  const dateCompare = parse(params, 'dd/MM/yyyy', new Date());
  return isAfter(date, dateCompare) || isEqual(date, dateCompare);
};

export const maxDate = (value, params) => {
  const date = parse(value, 'dd/MM/yyyy', new Date());
  const dateCompare = parse(params, 'dd/MM/yyyy', new Date());
  return isBefore(date, dateCompare) || isEqual(date, dateCompare);
};

export const validatePhoneBR = (value) => {
  const regex = /(\([1-9][0-9]\))\s([9]{1})(\d{4})-(\d{4})$/;
  return regex.test(value);
};

export const validateOnlyNumbers = (value) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

export const validateCep = (value) => {
  const regex = /(^\d{2}\d{3}-\d{3}$)/;
  return regex.test(value);
};

export const validateSpace = (value) => {
  const regex = /\s/;
  return regex.test(value);
};

export const replaceSpaceForHyphen = string => string.replace(/\s+/g, '-');

export const parseToHref = (to) => {
  const parser = document.createElement('a');
  parser.href = to;
  return parser;
};

export const preventClick = (e, prevent) => {
  if (prevent) e.preventDefault();
};

export const hasOwnProperty = (entries, key) => Object.prototype.hasOwnProperty.call(entries, key);

export const isEmpty = obj => Object.keys(obj).length === 0;

export const isMobile = window.innerWidth < 700;

export const isBRServer = COUNTRY === 'br';

export const isCLServer = COUNTRY === 'cl';

export const isMXServer = COUNTRY === 'mx';

export const isCOServer = COUNTRY === 'co';

export const hasPaymentEnabled = (invoicePaymentMethods, typePayment) => invoicePaymentMethods !== null && Object.keys(invoicePaymentMethods).filter(
  value => typePayment.includes(invoicePaymentMethods[value].name) && invoicePaymentMethods[value].selectable,
).length > 0;

export const isNumber = char => /^\d+$/.test(char);

export const mockoonWarning = ({ name, message }) => {
  if (ENVIRONMENT === 'development') {
    message
      ? console.log(message)
      : console.log(`${name} necessita do mockoon para rodar em ambientes locais`);
  }
};
