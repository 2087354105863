import shortid from 'shortid';

import { CALL_API } from '@/middleware/api';
import {
  ONBOARDING_URL, ONBOARDING_THEMES_URL, ONBOARDING_SSO_URL, CREATESITE_DISMISS,
  PORTAL_WEBSITE_TRACE, ONBOARDING_WORDPRESS_URL, PRODUCT_DETAIL_URL,
} from '@/config/api';
import { config } from '@/config';
import {
  RECEIVE_ONBOARDING_DOMAINS, REQUEST_ONBOARDING_DOMAINS, RECEIVE_ONBOARDING_THEMES,
  REQUEST_ONBOARDING_THEMES, ERROR_ONBOARDING_DOMAINS, CLEAN_FILTERED_THEMES_BY_CATEGORIE,
  RECEIVE_FILTERED_THEMES_BY_CATEGORIE, SELECTED_TEMPLATE_ID, RECEIVE_LOAD_ONBOARDING_SSO,
  REQUEST_LOAD_ONBOARDING_SSO, ERROR_LOAD_ONBOARDING_SSO, CLEAN_SELECTED_TEMPLATE_ID,
  REQUEST_SITECREATE_DISMISS, RECEIVE_SITECREATE_DISMISS, ERROR_SITECREATE_DISMISS,
  SET_ONBOARDING_TOOL, REQUEST_ONBOARDING_TRACE, RECEIVE_ONBOARDING_TRACE,
  ERROR_ONBOARDING_TRACE, ONBOARDING_AGREE, SET_ONBOARDING_PRODUCT, CLEAN_ONBOARDING_TOOL,
  DISABLE_ONBOARDING_SELECT_DOMAIN, RECEIVE_TRACE_ONBOARDING_ACCESS, REQUEST_TRACE_ONBOARDING_ACCESS,
  ERROR_TRACE_ONBOARDING_ACCESS, RECEIVE_FILTERED_THEMES_BY_ID, REQUEST_ONBOARDING_EMAIL_DATA,
  RECEIVE_ONBOARDING_EMAIL_DATA, ERROR_ONBOARDING_EMAIL_DATA, REQUEST_ONBOARDING_DNS, ERROR_ONBOARDING_DNS,
  RECEIVE_ONBOARDING_DNS,
} from './actionsTypes';

export const receiveOnboardingDns = (dns) => {
  const { ns1, ns2 } = dns;
  return {
    type: RECEIVE_ONBOARDING_DNS,
    ns1,
    ns2,
  };
};

export const loadOnboardingDns = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_DETAIL_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ONBOARDING_DNS }),
      success: response => receiveOnboardingDns(response.data),
      error: () => ({ type: ERROR_ONBOARDING_DNS }),
    },
  },
});

export const receiveEmailAccountData = (data) => {
  const limit = data.maxPop;
  return {
    type: RECEIVE_ONBOARDING_EMAIL_DATA,
    limit,
  };
};

export const loadEmailAccountData = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ONBOARDING_WORDPRESS_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ONBOARDING_EMAIL_DATA }),
      success: response => receiveEmailAccountData(response.data),
      error: () => ({ type: ERROR_ONBOARDING_EMAIL_DATA }),
    },
  },
});

export const traceOnboardingAccess = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ONBOARDING_URL,
    body: data,
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_TRACE_ONBOARDING_ACCESS }),
      success: () => ({ type: RECEIVE_TRACE_ONBOARDING_ACCESS }),
      error: () => ({ type: ERROR_TRACE_ONBOARDING_ACCESS }),
    },
  },
});

export const disableSelectDomain = () => ({
  type: DISABLE_ONBOARDING_SELECT_DOMAIN,
});

const receiveWebSiteBuilderDomains = data => ({
  type: RECEIVE_ONBOARDING_DOMAINS,
  data: data.map(item => ({
    ...item,
    id: shortid.generate(),
  })),
});

export const loadOnboardingDomains = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ONBOARDING_URL,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ONBOARDING_DOMAINS }),
      success: data => receiveWebSiteBuilderDomains(data.data),
      error: () => ({ type: ERROR_ONBOARDING_DOMAINS }),
    },
  },
});

const receiveOnboardingThemes = data => ({
  type: RECEIVE_ONBOARDING_THEMES,
  data,
});

export const loadOnboardingThemes = () => {
  const getCountry = (lang) => {
    const templates = {
      pt: 'template_pt_BR',
      mx: 'template_es_MX',
      es: 'template_es_MX',
      cl: 'template_es_MX',
      co: 'template_es_MX',
      default: 'template_pt_BR',
    };
    return templates[lang] || templates.default;
  };

  const country = getCountry(config.LANG.code);

  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: ONBOARDING_THEMES_URL(country),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_ONBOARDING_THEMES }),
        success: data => receiveOnboardingThemes(data),
        error: () => ({ type: ERROR_ONBOARDING_DOMAINS }),
      },
    },
  };
};

export const cleanFilterThemesByCategories = () => ({ type: CLEAN_FILTERED_THEMES_BY_CATEGORIE });

const reveivefilteredCategorie = filteredThemesByCategorie => ({
  type: RECEIVE_FILTERED_THEMES_BY_CATEGORIE,
  data: filteredThemesByCategorie,
});

export const filterThemesByCategory = categorieName => (dispatch, getState) => {
  if (categorieName === '') {
    return dispatch(cleanFilterThemesByCategories());
  }
  const stateCategories = getState().onboarding.themes.categories;
  const stateThemes = getState().onboarding.themes.templates;
  const arrCategorieByIndex = stateCategories[stateCategories.findIndex(categorie => categorie.name === categorieName)];
  const filteredThemesByCategorie = [];
  arrCategorieByIndex.templateIds.forEach((themesIds) => {
    const themeIndex = stateThemes.findIndex(theme => theme.id === themesIds);
    filteredThemesByCategorie.push(stateThemes[themeIndex]);
  });

  return dispatch(reveivefilteredCategorie(filteredThemesByCategorie));
};

const receiveFilteredThemeById = theme => ({
  type: RECEIVE_FILTERED_THEMES_BY_ID,
  data: theme,
});

export const filterThemeById = themeId => (dispatch, getState) => {
  const { filteredThemes } = getState().onboarding;
  const theme = filteredThemes.filter(item => item.id === themeId);
  return dispatch(receiveFilteredThemeById(theme));
};

export const selectTemplateTheme = id => ({
  type: SELECTED_TEMPLATE_ID,
  data: id,
});

const receiveLoadSSOByDomainId = data => ({
  type: RECEIVE_LOAD_ONBOARDING_SSO,
  data,
});

export const loadSSOByDomainId = domainId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ONBOARDING_SSO_URL(domainId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_LOAD_ONBOARDING_SSO }),
      success: response => receiveLoadSSOByDomainId(response.data),
      error: () => ({ type: ERROR_LOAD_ONBOARDING_SSO }),
    },
  },
});

export const cleanSelectedTemplate = () => ({
  type: CLEAN_SELECTED_TEMPLATE_ID,
});

export const siteCreateDismiss = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: CREATESITE_DISMISS,
    method: 'POST',
    body: {
      id: hostingId,
      siteCreate: false,
    },
    actionTypes: {
      request: () => ({ type: REQUEST_SITECREATE_DISMISS }),
      success: () => ({ type: RECEIVE_SITECREATE_DISMISS }),
      error: () => ({ type: ERROR_SITECREATE_DISMISS }),
    },
  },
});

const setWebsiteBuilderTool = tool => ({
  type: SET_ONBOARDING_TOOL,
  data: tool,
});

export const cleanWebsiteBuilderProduct = () => ({ type: CLEAN_ONBOARDING_TOOL });

const postWebsiteBuilderTrace = (trace) => {
  const body = {
    hostingId: trace.hostingId,
    creationTool: trace.creationTool,
    creationToolType: trace.creationToolType,
    themeId: trace.themeId,
  };

  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: PORTAL_WEBSITE_TRACE,
      method: 'POST',
      body,
      actionTypes: {
        request: () => ({ type: REQUEST_ONBOARDING_TRACE }),
        success: () => ({ type: RECEIVE_ONBOARDING_TRACE }),
        error: () => ({ type: ERROR_ONBOARDING_TRACE }),
      },
    },
  };
};

export const sendWebsiteBuilderTrace = trace => (dispatch) => {
  const tool = trace.creationTool === 'websiteBuilder' ? trace.creationToolType : trace.creationTool;
  dispatch(setWebsiteBuilderTool(tool));
  return dispatch(postWebsiteBuilderTrace(trace));
};

export const updateSummaryWebsiteBuilderFlow = () => ({
  type: ONBOARDING_AGREE,
});

export const setWebsitebuilderProduct = (id, domain) => ({
  type: SET_ONBOARDING_PRODUCT,
  data: { id, domain },
});

export const activateWebsiteCreationFlow = (id, domain) => (dispatch, getState) => {
  const hasProduct = getState().onboarding.products.findIndex(product => product.id === parseInt(id, 10));
  return new Promise((resolve) => {
    dispatch(updateSummaryWebsiteBuilderFlow());
    hasProduct < 0 && dispatch(setWebsitebuilderProduct(id, domain));
    resolve();
  });
};
