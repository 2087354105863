import React from 'react';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import styles from './styles';

const CardStatus = ({ classes, children, status }) => (
  <span
    data-testid="card-status"
    className={classnames(classes.status, {
      [classes.statusActive]: status === 'Active' || status === 'active',
      [classes.statusPending]: status === 'Pending' || status === 'pending',
      [classes.statusPendingTransfer]: status === 'Pending_Transfer' || status === 'pending_transfer',
      [classes.statusExpired]: status === 'Expired' || status === 'expired',
      [classes.statusCancelled]: status === 'Cancelled' || status === 'cancelled',
      [classes.statusFraud]: status === 'Fraud' || status === 'fraud',
      [classes.statusSuspended]: status === 'Suspended' || status === 'suspended',
      [classes.statusBuilding]: status === 'Building' || status === 'building',
      [classes.statusNotConfigured]: status === 'not_configured',
      [classes.statusOnConfigure]: status === 'on_configure',
      [classes.statusInAnalysis]: status === 'in_analysis',
    })}
  >
    {children}
  </span>
);

export default withStyles(styles)(CardStatus);
