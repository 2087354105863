import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md}/${theme.font.sizes.xxxxxxl};
    letter-spacing: 0.01px;
    margin: 40px 0 16px;
    text-align: center;
  `};
`;

export const Description = styled.p`
 ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    letter-spacing: 0.01px;
    line-height: ${theme.font.lineHeights.xxl};
    margin: 0;
    text-align: center;

    strong {
      font-weight: ${theme.font.weights.medium};
    }
  `}
`;

export const ButtonWrapperForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 0 0 8px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column-reverse;
      align-items: center;

      button {
        width: 210px;
      }
    }
  `};
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;

    .widthWrapper {
      max-width: 512px;
    }

    ${Description} + ${ButtonWrapperForm} {
      margin-top: 32px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      align-items: center;
      height: 100vh;
      padding: 8px;
    }
  `};
`;
