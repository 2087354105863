import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './LogoutModal.styles';
import { Button, IconLoading } from 'gatorcomponents';

const LogoutModal = ({
  onClose = () => {},
  title = 'title',
  description = 'description',
  cta = 'cta',
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    onClose && onClose();
    setLoading(true);
  };

  return (
    <Modal onClose={handleClick} variant="regular">
      <Styles.Title data-testid="title">
        {title}
      </Styles.Title>

      <Styles.Description data-testid="description">
        {description}
      </Styles.Description>

      <Styles.ButtonWrapper>
        <Button
          testId="button"
          label={loading ? <IconLoading /> : cta}
          onClick={handleClick}
          size="large"
          disabled={loading}
        />
      </Styles.ButtonWrapper>
    </Modal>
  );
};

export default LogoutModal;
