import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { Close, Info } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  withStyles,
  CircularProgress,
} from '@material-ui/core/';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';


import PrimaryButton from '@/components/Buttons/PrimaryButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';


const ModalExcludeDnsZone = ({ classes, t, width }) => {
  const {
    confirmDeletion,
    loading,
    setExcludeRecordedDnsItem,
    setShowExcludeModalConfirmation,
    showExcludeModalConfirmation,
  } = useContext(DNSContext);

  const isWidthDownSm = isWidthDown('sm', width);
  return (
    <Dialog
      fullScreen={!!isWidthDownSm}
      open={showExcludeModalConfirmation}
      onClose={() => {
        setShowExcludeModalConfirmation(false);
        setExcludeRecordedDnsItem(null);
      }}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => {
          setShowExcludeModalConfirmation(false);
          setExcludeRecordedDnsItem(null);
        }}
      >
        <Close />
      </IconButton>

      <DialogTitle id="alert-dialog-title" className={classes.titleElement}>
        {t('dns.zoneManager.excludeRecord.title')}
      </DialogTitle>

      <DialogContent className={classes.containerWrapper}>
        <DialogContentText className={classes.warning}>
          {t('dns.zoneManager.excludeRecord.confirmation')}
        </DialogContentText>

        <DialogContentText id="alert-dialog-description" className={classes.explanation}>
          <Info />
          {t('dns.zoneManager.excludeRecord.explanation')}
        </DialogContentText>

        {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
        )}

      </DialogContent>

      <DialogActions className={classes.actionsWrapper}>
        <OutlineButton
          className={classes.outlinedCancel}
          disabled={loading}
          onClick={() => {
            setShowExcludeModalConfirmation(false);
            setExcludeRecordedDnsItem(null);
          }}
        >
          {t('dns.zoneManager.excludeRecord.cancelButton')}
        </OutlineButton>

        <PrimaryButton
          color="primary"
          disabled={loading}
          onClick={() => confirmDeletion()}
        >
          {t('dns.zoneManager.excludeRecord.excludeButton')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ModalExcludeDnsZone)));
