const styles = theme => ({
  creditCardTitle: {
    color: theme.color.tundora,
    fontFamily: theme.font.family.secondary,
    fontSize: theme.font.sizes.xxl,
    fontWeight: theme.font.weights.medium,
    marginBottom: '20px',
  },
  creditCardCard: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    padding: '20px 30px 35px 30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  container: {
    paddingTop: '20px',
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
    },
  },
  handleLoader: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '75px',
    },
  },
});

export default styles;
