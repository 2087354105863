const surveyBaseURL = 'https://pt.surveymonkey.com/r/';

const surveyMonkeyCountryBR = process.env.REACT_APP_SURVEYMONKEY_BR;
const surveyMonkeyCountryMX = process.env.REACT_APP_SURVEYMONKEY_MX;
const surveyMonkeyCountryCL = process.env.REACT_APP_SURVEYMONKEY_CL;
const surveyMonkeyCountryCO = process.env.REACT_APP_SURVEYMONKEY_CO;

const SURVEY_MONKEY_KEYS = {
  br: surveyMonkeyCountryBR,
  mx: surveyMonkeyCountryMX,
  cl: surveyMonkeyCountryCL,
  co: surveyMonkeyCountryCO,
};

const idSurveyMonkeyCountryBR = process.env.REACT_APP_SURVEYMONKEY_BR_ID;
const idSurveyMonkeyCountryMX = process.env.REACT_APP_SURVEYMONKEY_MX_ID;
const idSurveyMonkeyCountryCL = process.env.REACT_APP_SURVEYMONKEY_CL_ID;
const idSurveyMonkeyCountryCO = process.env.REACT_APP_SURVEYMONKEY_CO_ID;

const SURVEY_MONKEY_IDS = {
  br: idSurveyMonkeyCountryBR,
  mx: idSurveyMonkeyCountryMX,
  cl: idSurveyMonkeyCountryCL,
  co: idSurveyMonkeyCountryCO,
};

export const getConfigSurveyByCountry = country => ({
  country,
  url: `${surveyBaseURL}${SURVEY_MONKEY_KEYS[country] || SURVEY_MONKEY_KEYS.br}`,
  idLink: SURVEY_MONKEY_KEYS[country] || SURVEY_MONKEY_KEYS.br,
  idAPI: SURVEY_MONKEY_IDS[country] || SURVEY_MONKEY_IDS.br,
});
