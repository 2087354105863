import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: calc(100vh - 80px);
    padding: 40px;
    position: relative;

    @media (max-width:  ${theme.v2.breakpoints.sm}) {
      padding: 0;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    padding: 40px 10px;
    width: 100%;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight:${theme.v2.font.weights.medium};
    margin: 39px 0 0 0;
    padding: 0;
    text-align: center;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme, boldFont }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight:${boldFont ? theme.v2.font.weights.medium : theme.v2.font.weights.regular};
    padding: 0;
    margin: 30px 0 0 0;
    text-align: center;
  `}
`;

export const InputWrapper = styled.div`
  padding: 0;
  width: 320px;
`;

export const Countdown = styled.div`
  display: flex;
  margin-top: -10px;
  gap: 5px;
`;

export const ChangeNumberForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 26px;
`;

export const ChangeNumber = styled.div`

  margin-top: 10px;
`;

export const ValidationForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 115px;
  margin-top: 55px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export const CloseButtonWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 73px;
    top: 72px;

    @media (max-width:  ${theme.v2.breakpoints.sm}) {
      right: 16px;
      top: 16px;
    }
  `}
`;
