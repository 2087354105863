import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';
import { Wrapper as Radio, Dot as RadioDot } from '@/pages/common/inputs/Radio/Radio.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding: 32px;
  `}
`;

export const Data = styled.div`
  ${() => css`
    padding-top: 16px;
  `}
`;

export const Header = styled.header``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 0;
`;

export const OptionList = styled.ul`
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
`;

export const OptionItem = styled.div`
  ${({ checked }) => css`
    background-size: contain;
    border-radius: ${checked ? 0 : '2px'};
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const OptionItemWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    padding: 11px;
    padding-botton:0px;
  `}
`;

export const OptionRadio = styled.div`
  ${({ theme, checked }) => css`
    ${Radio} {
      border-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }

    ${RadioDot} {
      background-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }
  `}
`;

export const OptionContent = styled.div`
  padding-left: 9px;
`;

export const OptionLabel = styled.label`
  ${({ theme, checked }) => css`
    color: ${checked ? theme.palette.blue.dark : theme.palette.secondary.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: -0.19px;
    transition: color 200ms ease-in-out;
  `}
`;

export const OptionDescription = styled.p`
  font-size: 14px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const ControlButton = styled.div`
  ${() => css`
 
    &:not(:first-child) {
      margin-left: 12px;
    }

    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  `}
`;


export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;

export const InfoPix = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 36px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;


export const InfoIcon = styled.span`
  ${({ theme }) => css`
    display: contents;
    & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;
export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.label};
    display: inline-flex;
    font-size: 12px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
  `}
`;
export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
  `}
`;
export const WrapperData = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v1.font.family.primary};
    padding: 16px 24px 16px 24px;
  `}
`;
export const WrapperInfo = styled.section`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v1.font.family.primary};
    justify-content: center;
    padding: 16px 24px 16px 24px;
    text-align:center;
  `}
`;
export const DescriptionInfo = styled.p`
  ${({ theme }) => css`
      font-size:${theme.font.sizes.xxs};
      margin: 8px 0px 0px 0px;
 `}
`;
export const ContentCloseButton = styled.div`
${() => css`
    position: absolute;
    top: 0px;
    right: 0px;
`}
`;
export const ContentData = styled.div`
  ${({ theme }) => css`
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;
export const ContentPaymentSelector = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding-top: 0px;
  `}
`;

export const ContentOptionsInSelectord = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.primary};
    display:flex;
    font-family: ${theme.v1.font.family.primary};
    justify-content: center;
    padding-top: 23px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      display: block;
    }
  `}
`;

export const Chip = styled.span`
  ${() => css`
    margin-left:8px;
    position: relative;
    top: -2px;
  `}
`;
