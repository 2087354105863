const styles = theme => ({
  resultContainer: {
    marginRight: '6px',
    marginBottom: '6px',
    background: `${theme.color.white}`,
  },
  tableHeader: {
    paddingRight: '6px',
    border: 0,
  },
  smartscanHeader: {
    marginTop: '24px',
    whiteSpace: 'nowrap',
    color: theme.color.regentGray,
    font: 'normal normal normal 12px/14px Roboto',
  },
  smartscanBody: {
    whiteSpace: 'nowrap',
    color: theme.color.tundora,
    font: 'normal normal normal 14px/17px Roboto',
    marginTop: '6px',
    marginBottom: '36px',
  },
  btnIcon: {
    marginRight: '6px',
  },
  btn: {
    color: theme.color.indigo,
    marginRight: '10px',
    padding: '0 10px',
    '&:hover': {
      color: theme.color.chambray,
      background: 'none',
    },
  },
  downloadError: {
    color: theme.color.valencia,
    fontSize: '10px',
  },
  downloadCell: {
    textAlign: 'center',
  },
});

export default styles;
