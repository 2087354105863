import {
  REQUEST_RESEND_EMAIL, RECEIVE_RESEND_EMAIL, ERROR_RESEND_EMAIL,
  REQUEST_CHANGE_EMAIL, RECEIVE_CHANGE_EMAIL, ERROR_CHANGE_EMAIL,
  EMAIL_VERIFY_SKIP, EMAIL_CHANGE_FLOW,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loadingResend: false,
  loadingChange: false,
  emailFlowSkip: false,
  emailChangeFlow: false,
};

export default function emailVerify(state = initialState, action) {
  switch (action.type) {
    case REQUEST_RESEND_EMAIL:
      return {
        ...state,
        loadingResend: true,
      };
    case RECEIVE_RESEND_EMAIL:
      return {
        ...state,
        loadingResend: false,
      };
    case ERROR_RESEND_EMAIL:
      return {
        ...state,
        loadingResend: false,
      };
    case REQUEST_CHANGE_EMAIL:
      return {
        ...state,
        loadingChange: true,
      };
    case RECEIVE_CHANGE_EMAIL:
      return {
        ...state,
        loadingChange: false,
      };
    case ERROR_CHANGE_EMAIL:
      return {
        ...state,
        loadingChange: false,
      };
    case EMAIL_VERIFY_SKIP:
      return {
        ...state,
        emailFlowSkip: true,
      };
    case EMAIL_CHANGE_FLOW: {
      const { data } = action;
      return {
        ...state,
        emailChangeFlow: data.bool,
        forceUrl: data.currentUrl,
      };
    }
    default:
      return state;
  }
}
