export const RECORD_TYPES = {
  A: 'A',
  AAAA: 'AAAA',
  CAA: 'CAA',
  CNAME: 'CNAME',
  MX: 'MX',
  NS: 'NS',
  PTR: 'PTR',
  SOA: 'SOA',
  SRV: 'SRV',
  TXT: 'TXT',
};

export const RECORD_CLASSES = {
  IN: 'IN', // Internet
  CH: 'CH', // Chaosnet
  HS: 'HS', // Hesiod
};

export const EMAIL_PLATFORMS = {
  CPANEL: 'cpanel', // Cpanel
  TITAN: 'titan', // Titan
};

export const BRANDS_ENUM = {
  BR: 'BR',
  MX: 'MX',
  CL: 'CL',
  CO: 'CO',
};

export const HOSTING_PLATFORM_TYPES = {
  CUSTOM: 'custom',
  EXTERNAL: 'external',
  HOSTGATOR: 'hostgator',
  CUSTOM_DNS_ZONE: 'custom_dns_zone',
  LOJA_INTEGRADA: 'loja_integrada',
  NUVEMSHOP: 'nuvemshop',
  SHOPIFY: 'shopify',
  TIENDANUBE: 'tiendanube',
  RESERVED: 'reserved',
  UNDEFINED: 'undefined',
  MAIL: 'mail',
  CUSTOM_MAIL: 'custom_mail',
  DLOJA: 'dloja',
};

export const EMAIL_PLATFORM_NAMES = {
  CUSTOM: 'Custom',
  GMAIL: 'Gmail',
  TITAN: 'Titan',
  CPANEL: 'cPanel',
  HOSTGATOR: 'Hostgator',
  RESERVED: 'Reserved',
};

export const PLAN_TYPES = {
  DEDICATED: 'server',
  RESALE: 'reselleraccount',
  PLAN: 'hostingaccount',
};

export const REGISTRAR_MAX_PROPAGATION_HOURS = {
  enom: 72,
  registrobr: 24,
  resellerclub: 72,
  latam_rc: 72,
  latam_rc_premium: 72,
  redemption: 72,
};

export const RESERVED_NAME_SERVERS = {
  MASTER: 'dns1.hostgator.com.br',
  SLAVE: 'dns2.hostgator.com.br',
};

export const ERROR_MESSAGES = {
  DEFAULT: 'default',
  INVALID_SERVER: 'invalid_server',
  INVALID_IP: 'invalid_ip',
  REQUIRED: 'required',
};

export const PLATFORM_NAMES = {
  godaddy: 'Godaddy',
  hostgator: 'Hostgator',
  kinghost: 'Kinghost',
  locaweb: 'Locaweb',
  loja_integrada: 'Loja Integrada',
  nuvemshop: 'Nuvemshop',
  tiendanube: 'Tiendanube',
  uolhost: 'Uolhost',
  shopify: 'Shopify',
  registro_br: 'Registro.br',
  dloja: 'Loja HostGator',
};

export const CUSTOM_DNS_ZONE_PLATFORMS_URL = {
  loja_integrada: 'https://lojaintegrada.com.br/',
  nuvemshop: 'https://www.nuvemshop.com.br/',
  shopify: 'https://www.shopify.com/br',
  tiendanube: 'https://www.tiendanube.com/',
  shopify_br: 'https://www.shopify.com/br',
  shopify_mx: 'https://www.shopify.com/mx',
  shopify_co: 'https://www.shopify.com/co',
  shopify_cl: 'https://www.shopify.com/es',
  dloja: 'https://dlojavirtual.com/',
};
