import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Font, Image,
} from '@react-pdf/renderer';
import { withI18n, Trans } from 'react-i18next';

import collabplayLogo from '@/media/courses/certificate/logo-collabplay.png';
import background from '@/media/courses/certificate/background.png';
import hostgatorLogo from '@/media/courses/certificate/logo-hostgator.png';
import signature from '@/media/courses/certificate/signature.png';
import snappy from '@/media/courses/certificate/snappy.png';
import stylesheet from './styles';


const styles = StyleSheet.create(stylesheet);

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf',
      fontWeight: 700,
    },
  ],
});

const CourseCertificate = ({
  name, date, course, hours, t,
}) => (
  <Document>
    <Page size="RA4" orientation="landscape" style={styles.page}>
      <View style={styles.certificate}>
        <View style={styles.wrapper}>
          <Image style={styles.background} src={background} />
          <View style={styles.container}>
            <Image style={styles.snappy} src={snappy} />
            <View style={styles.contentWrapper}>
              <Image style={styles.collabplayLogo} src={collabplayLogo} />
              <View style={styles.content}>
                <Text style={styles.title}>{t('courses.certificate.title')}</Text>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.subtitle}>{t('courses.certificate.conclusion')}</Text>
                <Text style={styles.course}>{course}</Text>
                <Text style={styles.hours}>
                  <Trans i18nKey="courses.certificate.hours">
                    0
                    {{ hours }}
                    2
                  </Trans>
                </Text>
              </View>
              <View style={styles.footer}>
                <View style={styles.footerWrapper}>
                  <View style={styles.footerLeft}>
                    <Text style={styles.dataValue}>{date}</Text>
                    <View style={styles.divider} />
                    <Text style={styles.dataText}>{t('courses.certificate.date')}</Text>
                  </View>
                  <View style={styles.footerCenter}>
                    <Image style={styles.hostgatorLogo} src={hostgatorLogo} />
                  </View>
                  <View style={styles.footerRight}>
                    <View>
                      <View style={styles.signatureWrapper}>
                        <Image style={styles.signature} src={signature} />
                      </View>
                      <View style={styles.divider} />
                      <Text style={styles.dataText}>Robledo Ribeiro</Text>
                      <Text style={styles.signatureText}>{t('courses.certificate.founder')}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default withI18n()(CourseCertificate);
