import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
`;

export const Loader = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid ${theme.palette.danger.dark};
    border-right-color: transparent;
    animation: .8s ease-in-out infinite ${rotate};
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    margin-top: 12px;
    text-align: center;
  `}

  p:not(:first-child) {
    margin: 4px 0 0 0;
  }
`;
