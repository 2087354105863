import styled, { css } from 'styled-components';

export const SlideToggleWrapper = styled.div`
  ${({ theme, maxWidht }) => css`
    align-items: flex-start;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.line.wizard};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-widht: ${maxWidht};
    padding: 32px 36px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      padding: 22px 15px; 
    }
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
`;

export const ToggleAction = styled.div`
  ${({ clickable }) => css`
    align-items: flex-start;
    cursor: ${clickable ? 'pointer' : 'unset'};
    display: flex;
    flex-direction: row;
    width: 100%;
  `}
`;

export const ToggleHeader = styled.div`
  ${({ clickable }) => css`
    align-items: flex-start;
    cursor: ${clickable ? 'pointer' : 'unset'};
    display: flex;
    flex-direction: column;
    width: 100%;
  `}
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 29px - 16px);
  width: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 0px 0px 8px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 19px;
    margin: 0px;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      margin-left: -40px;
    }
  `}
`;

export const ToggleContent = styled.div`
  ${({ open, maxHeight }) => css`
    max-height: ${open ? maxHeight : '0px'};
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    width: 100%;
  `}
`;


export const Label = styled.span``;
