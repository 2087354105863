import React from 'react';
import * as Styles from './DetailsSection.styles';
import { Button } from '@/pages/common/v1/Button';
import firstRowImage from '@/media/sitelock/protect.png';
import secondRowImage from '@/media/sitelock/security.png';

const DetailsSection = ({
  title = 'title',
  firstSectionTitle = 'firstSectionTitle',
  firstSectionDescription = 'firstSectionDescription',
  firstSectionButtonLabel = 'firstSectionButtonLabel',
  secondSectionTitle = 'secondSectionTitle',
  secondSectionDescription = 'secondSectionDescription',
  secondSectionButtonLabel = 'secondSectionButtonLabel',
  gaProtectMySite,
}) => {
  const moveScreenToTop = () => {
    gaProtectMySite();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Styles.Container data-testid="cloud-backup-section">
      <Styles.Title>{title}</Styles.Title>

      <Styles.FirstRow>
        <Styles.ImageWrapper>
          <Styles.RowImage src={firstRowImage} />
        </Styles.ImageWrapper>

        <Styles.RowContent row={1}>
          <Styles.RowTitle>
            {firstSectionTitle}
          </Styles.RowTitle>

          <Styles.RowDescription>
            {firstSectionDescription}
          </Styles.RowDescription>

          <Styles.RowButtonWrapper>
            <Button
              testId="first-row-button"
              label={firstSectionButtonLabel}
              onClick={() => moveScreenToTop()}
            />
          </Styles.RowButtonWrapper>

        </Styles.RowContent>
      </Styles.FirstRow>

      <Styles.SecondRow>
        <Styles.RowContent row={2}>
          <Styles.RowTitle>
            {secondSectionTitle}
          </Styles.RowTitle>

          <Styles.RowDescription>
            {secondSectionDescription}
          </Styles.RowDescription>

          <Styles.RowButtonWrapper>
            <Button
              testId="second-row-button"
              label={secondSectionButtonLabel}
              onClick={() => moveScreenToTop()}
            />
          </Styles.RowButtonWrapper>

        </Styles.RowContent>

        <Styles.ImageWrapper>
          <Styles.RowImage src={secondRowImage} />
        </Styles.ImageWrapper>
      </Styles.SecondRow>
    </Styles.Container>
  );
};

export default DetailsSection;
