import styled, { css } from 'styled-components';

export const EmailPlatformsGridWrapper = styled.div`
  ${({ theme, customColumns }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      grid-template-columns: 1fr 1fr;

      > *:nth-child(2n-1):nth-last-of-type(1) {
        grid-column: ${customColumns.sm || 'span 1'};
      }
    }

    @media (min-width: ${theme.v1.breakpoints.md}) {
      grid-template-columns: ${customColumns.md || '233px 233px 233px'};
    }

    @media (min-width: ${theme.v1.breakpoints.lg}) {
      grid-template-columns: ${customColumns.lg || '233px 233px 233px '};
    }

    @media (min-width: ${theme.v1.breakpoints.xl}) {
      grid-template-columns: ${customColumns.xl || '233px 233px 233px'};
    }
  `}
`;
