import axios from 'axios';
import { config } from '@/config';

const instance = axios.create({
  baseURL: config.AWS_PRODUCT_LIFECYCLE_URL,
  headers: {
    brand: config.COUNTRY,
    'Content-Type': 'application/json',
  },
});

const aws = {
  instance,
  get: ({ url, headers }) => instance.get(url, { headers }),
  post: ({ url, headers, data }) => instance.post(url, data, { headers }),
  delete: ({ url, headers }) => instance.delete(url, { headers }),
};

export default aws;
