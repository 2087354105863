const styles = theme => ({
  cardPrice: {
    fontSize: '30px',
    letterSpacing: '0.02px',
    lineHeight: '43px',
    textAlign: 'center',
  },
  cardPriceOld: {
    color: theme.color.indigo,
    fontSize: '36px',
  },

  cardPriceSmall: {
    fontSize: '18px',
    lineHeight: '22px',
  },

  savedPercentual: {
    backgroundColor: theme.color.oceanGreen,
    borderRadius: '100px',
    color: theme.color.white,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '6px',
    padding: '3px 6px',
  },

  separator: {
    borderBottom: `1px solid ${theme.color.geyser}`,
    height: '1px',
    marginBottom: '12px',
    marginTop: '24px',
    width: '100%',
  },
});
export default styles;
