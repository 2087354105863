import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextField } from '@material-ui/core';


const MaskedInputTextField = (props) => {
  const { mask, ...rest } = props;
  return (
    <MaskedInput
      {...rest}
      mask={mask}
      guide={false}
      keepCharPositions
      render={(ref, props) => (
        <TextField inputRef={ref} {...props} />
      )}
    />
  );
};

export default MaskedInputTextField;
