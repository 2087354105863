import React, { useContext } from 'react';
import * as Styles from './FormConclusion.styles';
import congratsIcon from '@/media/asaas/congrats.svg';
import failIcon from '@/media/asaas/fail.svg';
import { Button } from '@/pages/common/v1/Button';
import {
  IconArrowForward,
} from '@/icons';
import { TextButton } from '@/pages/common/v1';
import { ThemeContext } from 'styled-components';
import { asaasAnalytics } from '@/analytics/asaas';

const FormConclusion = ({
  formData,
  resetForm,
  testId = 'form-conclusion',
  accountCreated,
  isInsideWizard,
}) => {
  const thirdStepWithError = !accountCreated;
  const theme = useContext(ThemeContext);

  const handleOpenLink = () => {
    asaasAnalytics.formStepThreeLogin();
    window.open(formData.texts[formData.activeStep].successButtonUrl);
  };
  return (
    <Styles.Container data-testid={testId}>
      <Styles.TitleContainer>
        <Styles.CongratsIcon data-testid={`${testId}-icon`} src={!thirdStepWithError ? congratsIcon : failIcon} />

        <Styles.Title data-testid={`${testId}-title`}>
          {!thirdStepWithError ? formData.texts[formData.activeStep].successTitle : formData.texts[formData.activeStep].failedTitle}
        </Styles.Title>
      </Styles.TitleContainer>

      <Styles.Description data-testid={`${testId}-description`} success={!thirdStepWithError}>
        {!thirdStepWithError ? formData.texts[formData.activeStep].successDescription : formData.texts[formData.activeStep].failedDescription}
      </Styles.Description>

      <Styles.ButtonWrapper success={!thirdStepWithError} isInsideWizard={isInsideWizard}>

        {thirdStepWithError && (
        <Styles.BackButtonWrapper>
          <IconArrowForward
            size={18}
            fill={theme.v1.color.action.primary}
            stroke={theme.v1.color.action.primary}
          />

          <TextButton
            text={formData.texts[formData.activeStep].failedButtonBackLabel}
            onClick={resetForm}
            testId={`${testId}-back-button`}
          />
        </Styles.BackButtonWrapper>
        )}

        {!isInsideWizard && (
          <Button
            label={!thirdStepWithError ? formData.texts[formData.activeStep].successButtonLabel : formData.texts[formData.activeStep].failedButtonLoginLabel}
            onClick={handleOpenLink}
            variant="primary"
            testId={`${testId}-login-button`}
          />
        )}
      </Styles.ButtonWrapper>
    </Styles.Container>
  );
};

export default FormConclusion;
