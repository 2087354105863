import emailValidationBR from './emailValidation.br';
import emailValidationCL from './emailValidation.cl';
import emailValidationCO from './emailValidation.co';
import emailValidationMX from './emailValidation.mx';

const emailValidation = {
  BR: emailValidationBR,
  CL: emailValidationCL,
  CO: emailValidationCO,
  MX: emailValidationMX,
};

export default emailValidation;
