/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import shortid from 'shortid';
import ProductDetail from '@/components/Products/ProductDetail';
import ModalSetPassword from '@/components/Modal/ModalSetPassword';
import Loader from '@/components/Layout/Loader';
import FaqProductDetail from '@/components/FrequentlyQuestions/FaqProductDetail';
import {
  loadProductDetail, removeProductDetail, healthcheck, toggleAccessPasswordModal,
  updateOptionsProductDetail,
} from '@/redux/actions/productDetail';
import { COUNTRY } from '@/config';
import {
  AVAILABLE_REBOOT_PRODUCT_ID_LIST, AVAILABLE_PRODUCT_TYPES, AVAILABLE_PRODUCT_DOMAIN_STATUS,
  AVAILABLE_DEDICATED_TYPES,
} from '@/config/products/availability';
import RebootCard from '@/components/Cards/RebootCard';
import ProductsContextProvider from '@/contexts/Products/context';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import styles from './styles';
import { ModalCreateSite } from './components/ModalCreateSite';
import locale from '@/lang/locale';
import useLocale from '@/hooks/useLocale';
import { productsCreateModalArray } from '../products.enum';
import { backupActions, productsActions } from '@/redux/modules';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BRAVO_PRODUCTS_ACCESS_SITES_WIZARD } from '@/config/GrowthBook/constants';

const LOCATION_PRODUCTS = 'produtos';


const ProductDetailPage = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasRebootCard, setHasRebootCard] = useState(false);
  const [isDedicated, setIsDedicated] = useState(false);
  const { product, loading: productDetailLoading } = useSelector(state => state.productDetail);
  const passwordChanged = useSelector(state => (state.productDetail.healthcheck ? state.productDetail.healthcheck.passChanged : true));
  const serverStatus = useSelector(state => (state.productDetail.healthcheck ? state.productDetail.healthcheck.status : null));
  const showAccessPasswordModal = useSelector(state => state.productDetail.showAccessPasswordModal);
  const isDedicatedType = product && AVAILABLE_DEDICATED_TYPES.includes(product.type);
  const displayCreateSitesModal = useFeatureIsOn(BRAVO_PRODUCTS_ACCESS_SITES_WIZARD);
  const [createSitesModal, toggleCreateSitesModal] = useState(false);
  const { id: productId } = useParams();
  const { products: productsLocale, routes: routesLocale } = useLocale();

  const handleSetProductVisualized = () => {
    dispatch(productsActions.productsVisualized.set(product.id, product.packageid));
  };

  const handleTogleCreateSitesModal = useCallback(() => {
    toggleCreateSitesModal(!createSitesModal);
    handleSetProductVisualized();
  }, [createSitesModal]);

  const handleContinueCreateSitesModal = useCallback(() => {
    const sitesWizard = `${routesLocale.sitesPage}${routesLocale.sitesCreate}`;

    history.push(sitesWizard);
    handleSetProductVisualized();
  }, [createSitesModal]);

  const updateOptionsAfterStandaloneActivation = async () => {
    const storageActiveKey = 'id-sitebuilder-standalone-activated';
    const siteBuilderStandaloneId = await localStorage.getItem(storageActiveKey);
    if (siteBuilderStandaloneId && siteBuilderStandaloneId === productId) {
      localStorage.removeItem(storageActiveKey);

      const arrActions = [
        {
          uuid: 'active-edit-site-1',
          relid: shortid.generate(),
          linkUrl: `${locale('routes.siteBuilderRedirectAccess')}/${product.domain}`,
          type: 'websitebuilder-active-edit',
          status: 'active',
        },
        {
          uuid: 'removeSite-1',
          relid: shortid.generate(),
          linkUrl: '_self',
          type: 'websitebuilder',
          status: 'active',
          removeSite: true,
        },
      ];

      dispatch(updateOptionsProductDetail(arrActions));
    }
  };

  const shouldDisplayCreateSitesModal = useCallback(() => {
    if (product) {
      if (productsCreateModalArray.includes(product.packageid)) {
        toggleCreateSitesModal(!product.visualized);
      }
    }
  }, [product]);

  useEffect(() => {
    product && window.addEventListener('storage', updateOptionsAfterStandaloneActivation);
    return () => {
      window.removeEventListener('storage', updateOptionsAfterStandaloneActivation);
    };
  }, [product]);

  useEffect(() => {
    dispatch(loadProductDetail(productId));
    dispatch(backupActions.backupLog.request({ id: productId }));
    return () => {
      dispatch(removeProductDetail());
    };
  }, [productId]);

  useEffect(() => {
    if (!passwordChanged && serverStatus === 'active') {
      dispatch(toggleAccessPasswordModal());
    }
  }, [passwordChanged, serverStatus]);

  useEffect(() => {
    shouldDisplayCreateSitesModal();
  }, [shouldDisplayCreateSitesModal]);


  const checkCardVisibilityById = id => AVAILABLE_REBOOT_PRODUCT_ID_LIST[COUNTRY].includes(id);

  useEffect(() => {
    if (product && isDedicatedType) {
      const isProductActive = product.domainstatus === 'Active';

      isProductActive && dispatch(healthcheck(productId));

      const rebootCard = (checkCardVisibilityById(product.packageid) && isProductActive);
      setHasRebootCard(rebootCard);
      setIsDedicated(isDedicatedType);
    }
  }, [
    product && product.domainstatus,
    product && product.packageid,
  ]);

  const handleCloseModalPassword = () => {
    dispatch(toggleAccessPasswordModal());
  };

  const handleOpenModalPassword = () => {
    dispatch(toggleAccessPasswordModal());
  };

  if (productDetailLoading) {
    return <Loader />;
  }

  if (!productDetailLoading
      && !!product
      && (!AVAILABLE_PRODUCT_TYPES.includes(product.type) || !AVAILABLE_PRODUCT_DOMAIN_STATUS.includes(product.domainstatus))) {
    return <Redirect to={`${locale('routes.products')}`} />;
  }

  return (
    <>
      {(createSitesModal && displayCreateSitesModal) && (
        <ModalCreateSite
          title={productsLocale.productsDetails.modals.createSite.title()}
          descriptionFirst={productsLocale.productsDetails.modals.createSite.description[0](product && product.domain)}
          descriptionSecond={productsLocale.productsDetails.modals.createSite.description[1]()}
          buttonLabel={productsLocale.productsDetails.modals.createSite.buttonLabel}
          backButtonLabel={productsLocale.productsDetails.modals.createSite.backButtonLabel}
          onContinue={handleContinueCreateSitesModal}
          onClose={handleTogleCreateSitesModal}
        />
      )}

      {product && (
        <ProductsContextProvider>
          <div className={classes.contentDetails}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ProductDetail
                  product={product}
                  isDedicated={isDedicated}
                  disabledActions={isDedicated ? !passwordChanged : false}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.contentSpacing} spacing={32}>

              {hasRebootCard && (
              <Grid item xs={12} sm={12} md={6} xl={4} className={classes.wrapperRight}>
                <RebootCard disabledActions={!passwordChanged} />
              </Grid>
              )}
            </Grid>
          </div>
          <ModalSetPassword
            open={showAccessPasswordModal}
            onOpen={handleOpenModalPassword}
            onClose={handleCloseModalPassword}
            editing={passwordChanged}
            product={product.productname}
          />
        </ProductsContextProvider>
      )}

      <div className={classes.bottomInfoWrapper} data-testid="bottom-faq-products">
        <FaqProductDetail packageid={product && product.packageid} />
        <BottomAdvertisementHandler advertisementLocal={LOCATION_PRODUCTS} />
      </div>
    </>
  );
};

export default withI18n()(withStyles(styles)(ProductDetailPage));
