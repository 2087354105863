/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { loadGeneralProducts } from '@/redux/actions/generalProducts';
import { sitesActions } from '@/redux/modules';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS } from '@/config/GrowthBook/constants';

export const ProductsContext = createContext();

const ProductsContextProvider = ({ children }) => {
  const [productsOldResellers, setProductsOldResellers] = useState([]);
  const [suspendedProducts, setSuspendedProducts] = useState([]);
  const [cloudflareAvailableProducts, setCloudflareAvailableProducts] = useState([]);
  const [hasAboutToSuspend, setHasAboutToSuspend] = useState(false);
  const [shouldDisplayResellerMessage, setShouldDisplayResellerMessage] = useState(false);
  const [showModal, toggleShowModal] = useState(false);
  const [visible, setVisible] = useState(5);

  const totalProducts = useSelector(state => state.generalProducts.bkpProducts);
  const products = useSelector(state => state.generalProducts.byId);
  const loading = useSelector(state => state.generalProducts.loading);
  const { list: sitesList } = useSelector(state => state.sites);
  const oldResellerId = [50, 51, 52, 53, 54];
  const featureOldResellerNotification = true;

  const dispatch = useDispatch();
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);

  const loadMore = () => {
    setVisible(products.length);
  };

  const checkForOldReseller = () => {
    const oldResellerProducts = products.filter(product => oldResellerId.find(id => id === product.packageid));
    const aboutToSuspend = oldResellerProducts.filter((product) => {
      const dateDiff = differenceInDays(new Date(product.nextinvoicedate), new Date());
      return dateDiff <= 10;
    });

    const newSuspendedProducts = oldResellerProducts.filter(product => (product.status === 'Suspended'));
    const newProductsOldResellers = oldResellerProducts.filter(product => (product.status !== 'Suspended' && product.status !== 'Cancelled'));
    const cloudflareAvailableProductsIds = sitesList.map(site => site.cloudflare_available && site.hosting_id);

    setProductsOldResellers(newProductsOldResellers);
    setSuspendedProducts(newSuspendedProducts);
    setCloudflareAvailableProducts(cloudflareAvailableProductsIds);
    setShouldDisplayResellerMessage(productsOldResellers.length > 0 || suspendedProducts.length > 0);
    setHasAboutToSuspend(aboutToSuspend.length > 0);
  };

  const requestProducts = useCallback(() => {
    dispatch(loadGeneralProducts());
    dispatch(sitesActions.sites.request({ withGatorSolutions }));
  }, []);

  useEffect(() => { requestProducts(); }, []);

  useEffect(() => {
    if (products.length > 0) {
      checkForOldReseller();
    }
  }, [products]);

  const values = {
    loading,
    loadMore,
    oldResellerId,
    products,
    productsOldResellers,
    cloudflareAvailableProducts,
    shouldDisplayResellerMessage,
    showModal,
    suspendedProducts,
    toggleShowModal,
    totalProducts,
    visible,
    featureOldResellerNotification,
    hasAboutToSuspend,
  };

  return (
    <ProductsContext.Provider value={values} t={key => key}>
      {children}
    </ProductsContext.Provider>
  );
};

ProductsContextProvider.propTypes = {
  children: PropTypes.node,
};

ProductsContextProvider.defaultProps = {
  children: null,
};

export default ProductsContextProvider;
