import React from 'react';
import * as Style from './IconFeatured.style';

const IconFeatured = ({ width = 23, height = 21 }) => (
  <Style.Wrapper data-testid="icon-featured">
    <svg width={width} height={height} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.763672 0L22.0905 21V0H0.763672Z" fill="#FFCF00" />
      <path d="M13.901 13.78L13.4667 11.2829L15.3086 9.51125L12.761 9.12943L11.621 6.87289L10.481 9.1638L7.93338 9.54561L9.77524 11.3173L9.34095 13.8143L11.621 12.6002L13.901 13.78ZM15.96 16.5711L11.621 14.326L7.28194 16.5711L8.13113 11.8136L4.59863 8.44598L9.44952 7.75107L11.621 3.43652L13.7924 7.75107L18.6433 8.44598L15.1302 11.8136L15.96 16.5711Z" fill="#1F2044" />
    </svg>
  </Style.Wrapper>
);


export default IconFeatured;
