const styles = theme => ({
  loader: {
    position: 'relative',
    width: '100%',
    padding: 20,
    textAlign: 'center',
  },
  fullscreenPositionFixed: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 999999999,
  },
  fullscreen: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    zIndex: 999999999,
  },
  progress: {
    display: 'inline-block',
    margin: theme.spacing.unit * 2,
  },
  progressFull: {
    position: 'absolute',
    left: 'calc(50% - 40px)',
    top: 'calc(50% - 40px)',
  },
});

export default styles;
