import styled, { css } from 'styled-components';

export const Status = styled.div`
  display: inline-flex;
  margin-top: 9px;
`;

export const Wrapper = styled.article`
  ${({ theme, borderLeftColor, variant }) => {
    const variants = {
      oldLayout: {
        borderLeft: `5px solid ${borderLeftColor}`,
        padding: '16px 20px',
        maxHeight: 'auto',
      },
      newLayout: {
        borderLeft: 'none',
        padding: '18px 24px',
        maxHeight: '80px',
      },
    };

    return css`
    align-items: center;
    background: ${theme.palette.white.light};
    border-left: ${variants[variant].borderLeft};
    box-shadow: 0px 1px 3px ${theme.shadows.grey.light};
    display: flex;
    heigth: 80px;
    justify-content: space-between;
    padding: ${variants[variant].padding};
    max-height: ${variants[variant].maxHeight};
    width: 100%;

    @media (max-width: 1194px){
      max-height: unset;
      flex-direction: column;
      align-items: flex-start;
    }

    `;
  }}
`;

export const SubscriptionInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    min-width: 291px;

    @media (max-width: ${theme.breakpoints.xs}){
      min-width: unset;
    }
  `}
`;

export const Icon = styled.div`
  ${({ color }) => css`
      display: flex;
      align-items: center;

      svg {
        fill: ${color};
      }
  `}
`;

export const SubscriptionInfoDetails = styled.div`
  margin-left: 20px;
`;


export const ProductName = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;


    @media (max-width:${theme.breakpoints.xs}){
      max-width: 230px;
    }
  `}
`;

export const Domain = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width:${theme.breakpoints.xs}){
      max-width: 230px;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
      margin-right: 40px;
      max-width: 465px;
      min-width: 312px;
      width: 100%;

      @media (max-width: 1110px){
        margin: 20px 0px;
      }

      @media (max-width: ${theme.v2.breakpoints.xs}){
        max-width: 312px;
        min-width: unset;
      }
  `}
`;

export const SubscriptionManage = styled.div`
  min-width: 176px;
  @media (max-width: 1110px){
    align-self: flex-end;
}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    background: none;
    border: 0 none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.primary.light};
    transition: color 200ms ease-in-out;

    &:hover {
      cursor: pointer;
      color: ${theme.palette.primary.dark};
    }
  `}
`;

export const StepperWrapper = styled.div`
  ${({ cancelled, theme }) => css`
    margin-top: ${cancelled ? '-35px' : '-26px'};

    > div {
      margin: 0px 30px 0px auto;
    }

    @media (max-width: ${theme.breakpoints.md}){
      margin-top: 0;
      margin-bottom: 40px;
    }
  `}
`;

export const StatusV2 = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 132px;

  @media (max-width: 1194px){
  }
   padding: 22px 22px;
`;

export const SubscriptionInfoV2 = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    max-width: 400px;

    @media (max-width: ${theme.breakpoints.xs}){
      min-width: unset;
    }
  `}
`;

export const IconV2 = styled.div`
  ${({ color }) => css`
    align-items: start;
    display: flex;
    height: 16px;
    width: 16px;

    svg {
      fill: ${color};
    }
  `}
`;

export const SubscriptionInfoDetailsV2 = styled.div`
  margin-left: 8px;
`;

export const MessageWrapperV2 = styled.div`
  ${({ theme }) => css`
    margin-left: 34px;
    max-width: 465px;
    min-width: 312px;
    width: 100%;

    @media (max-width: 1110px){
      margin: 0px 0px 0px 0px;
    }

    @media (max-width: ${theme.v2.breakpoints.xs}){
      max-width: 312px;
      min-width: unset;
    }

    @media (max-width: 1194px){
      > div {
      padding: 0px;

        > div {
          margin-left: 4px;
        }
      }
    }
  `}
`;

export const SubscriptionManageV2 = styled.div`
  display: contents;
  min-width: 176px;

  @media (max-width: 1110px){
    align-self: flex-end;
  }

  @media (max-width: 1194px){
    > button {
      margin-top: 10px;
      width: 100%;
    }

`;
