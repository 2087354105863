const styles = theme => ({
  expandPanel: {
    animationDuration: '0.50s',
    animationName: 'expand',
    transition: 'opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },


  formWrapaper: {
    padding: '10px 20px 0 29px',

    [theme.breakpoints.down(1291)]: {
      padding: '10px 0 0 0',
    },
  },

  inputsWrapper: {
    display: 'flex',
    position: 'relative',
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 9px 0',
  },

  button: {
    color: theme.color.indigo,
    fontWeight: 400,
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
    userSelect: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      outline: 'none',
      textDecoration: 'underline',
    },

    '&:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
      textDecoration: 'underline',
    },
  },
});

export default styles;
