import React, {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useResize } from '@/hooks/useResize';
import { TextDescriptor } from '@/pages/common';
import * as Styles from './Tooltip.styles';

const Tooltip = ({
  children,
  text,
  width = 200,
  testId = 'tooltip',
  disabled = false,
  fixedDesktopPosition = null,
  fixedMobilePosition = null,
  translateXMobile = '0%',
  variant = 'white',
}) => {
  const windowRect = useResize();
  const [active, setActive] = useState(false);
  const [toolTipPosition, setTooltipPosition] = useState('top');
  const toolTipRef = useRef();
  const nestedChildrenRef = useRef();

  const onSetPosition = useCallback(() => {
    let position = 'top';

    const {
      height: windowHeight,
      width: windowWidth,
    } = windowRect;
    const isMobile = windowRect.width < 600;

    const {
      left: elLeft,
      top: elTop,
      width: elWidth,
    } = nestedChildrenRef.current.getBoundingClientRect();

    const {
      width: tooltipWidth,
      height: tooltipHeight,
    } = toolTipRef.current.getBoundingClientRect();

    const defaultConditions = {
      left: elLeft > tooltipWidth && elTop < tooltipHeight,
      right: windowWidth - (elLeft + elWidth) > tooltipWidth,
      top: elTop > tooltipHeight && elLeft > tooltipWidth,
      bottom: elTop < tooltipHeight && elLeft < tooltipWidth && windowWidth - (elLeft + elWidth) < tooltipWidth,
    };
    const defaultPositionRules = {
      top: {
        valid: defaultConditions.top,
        position: 'top',
      },
      left: {
        valid: defaultConditions.left,
        position: 'left',
      },
      bottom: {
        valid: defaultConditions.bottom,
        position: 'bottom',
      },
      right: {
        valid: defaultConditions.right,
        position: 'right',
      },
    };

    const fixedConditions = {
      top: elTop > tooltipHeight && elLeft > tooltipWidth,
      bottom: windowHeight - (elTop - tooltipHeight) > 0,
      right: windowWidth - (elLeft + elWidth) > tooltipWidth,
      left: windowWidth - (elLeft - elWidth) > tooltipWidth,
    };
    const fixedPositionRules = {
      top: {
        valid: fixedConditions.top,
        position: 'top',
      },
      left: {
        valid: fixedConditions.left,
        position: 'left',
      },
      bottom: {
        valid: fixedConditions.bottom,
        position: 'bottom',
      },
      right: {
        valid: fixedConditions.right,
        position: 'right',
      },
      bottomLeft: {
        valid: fixedConditions.bottom,
        position: 'bottomLeft',
      },
      bottomCenter: {
        valid: fixedConditions.bottom,
        position: 'bottomCenter',
      },
    };

    const firstValidPosition = Object.keys(defaultPositionRules).find(item => defaultPositionRules[item].valid);
    if (firstValidPosition) {
      position = firstValidPosition;
    }

    const hasFixedPosition = fixedDesktopPosition || fixedMobilePosition;
    if (hasFixedPosition && fixedPositionRules[isMobile ? fixedMobilePosition : fixedDesktopPosition].valid) {
      const { position: newPosition } = fixedPositionRules[isMobile ? fixedMobilePosition : fixedDesktopPosition];
      position = newPosition;
    }

    setTooltipPosition(position);
  }, [windowRect, fixedDesktopPosition, fixedMobilePosition]);

  const onMouseEnter = useCallback((event) => {
    if (disabled) {
      return;
    }

    if (!event.target.closest('.tooltip')) {
      return;
    }

    onSetPosition();
    !active && setActive(true);
  }, [active, disabled, onSetPosition]);

  const onMouseOut = useCallback(() => {
    if (disabled) {
      return;
    }

    active && setActive(false);
  }, [active, disabled]);

  useLayoutEffect(() => {
    const nestedChildrenEl = nestedChildrenRef.current;

    nestedChildrenEl.addEventListener('mouseenter', onMouseEnter);
    nestedChildrenEl.addEventListener('mouseleave', onMouseOut);

    return () => {
      nestedChildrenEl.removeEventListener('mouseenter', onMouseEnter);
      nestedChildrenEl.removeEventListener('mouseleave', onMouseOut);
    };
  }, [onMouseEnter, onMouseOut]);

  return (
    <Styles.Wrapper className="tooltip" data-testid={testId}>
      <Styles.NestedChildren
        ref={nestedChildrenRef}
        data-testid={`${testId}-children`}
      >
        {children}
      </Styles.NestedChildren>
      <Styles.Tooltip
        data-testid={`${testId}-balloon`}
        active={active}
        ref={toolTipRef}
        className={toolTipPosition}
      >
        <Styles.Text width={width} variant={variant} translateXMobile={translateXMobile}>
          {
            Array.isArray(text)
              ? <TextDescriptor descriptors={text} />
              : text
          }
        </Styles.Text>

        {variant !== 'dark' && <Styles.Arrow />}

      </Styles.Tooltip>
    </Styles.Wrapper>
  );
};

export default Tooltip;
