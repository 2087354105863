import React, { useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons/';
import styles from './styles';

const TitanUpgradeTitle = ({
  t, classes, plan, domain, origin,
}) => {
  const [redirect, toggleRedirect] = useState(false);

  if (redirect && origin !== 'hirePlan') {
    return <Redirect to={`${t('routes.emailsList')}${t('routes.emailsTitan')}/${domain}`} />;
  }
  if (redirect && origin === 'hirePlan') {
    return <Redirect to={`${t('routes.emailsList')}`} />;
  }

  return (
    <div className={classes.card}>
      <button type="button" onClick={() => toggleRedirect(true)} className={classes.redirectButton}>
        <ArrowBackIcon />
      </button>

      <div>
        {plan
          && <Typography className={classes.plan} data-testid="titanUpgradeTitle" title={plan.name}>{plan.name}</Typography>
        }

        <Typography className={classnames(classes.title, { [classes.titleHasPlan]: plan })} data-testid="titanUpgradeTitle" title={domain}>
          {plan ? `(${domain})` : domain}
        </Typography>
      </div>
    </div>
  );
};

TitanUpgradeTitle.propTypes = {
  domain: PropTypes.string.isRequired,
  plan: PropTypes.object,
};

TitanUpgradeTitle.defaultProps = {
  plan: undefined,
};

export default withI18n()(withWidth()(withStyles(styles)(TitanUpgradeTitle)));
