import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core/';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { MktPlaceContext } from '@/contexts/MktPlace/context';
import CollabList from '@/components/MktPlace/CollabList';
import bannerDesktop from '@/media/mktPlace/collabItems/bannerDesktop.png';
import bannerMobile from '@/media/mktPlace/collabItems/bannerMobile.png';
import styles from './styles';

const ModalCollabPlay = ({ classes, width, t }) => {
  const {
    showModal, toggleShowModal,
  } = useContext(MktPlaceContext);

  const isWidthDownSm = isWidthDown('xs', width);
  return (
    <Dialog
      fullScreen={!!isWidthDownSm}
      open={showModal}
      onClose={() => toggleShowModal(false)}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="modalCollabPlay"
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => toggleShowModal(false)}
      >
        <Close data-testid="toggleModal" />
      </IconButton>

      <DialogTitle id="alert-dialog-title" className={classes.titleElement}>
        {t('mktPlace.colab.modalTitle')}
      </DialogTitle>

      <DialogContent className={classes.containerWrapper}>
        <DialogContentText className={classes.description}>
          {t('mktPlace.colab.modalDescription')}
        </DialogContentText>

        <img className={classes.banner} src={isWidthDownSm ? bannerMobile : bannerDesktop} alt="Aprenda a criar campanhas no google ads como um profissional" />

        <CollabList />
      </DialogContent>
    </Dialog>
  );
};

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ModalCollabPlay)));
