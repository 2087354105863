import React from 'react';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common';
import * as Styles from './ActivateRenewModal.styles';

const ActivateRenewModal = ({
  activateSubscription,
  onClose,
  handleActivateSubscription,
  testId = 'renew-subscription-modal',
  title = 'Do you want to activate subscription renewal?',
  content = 'Payment will be charged at the end of the cycle, preventing your product from being canceled and your data and files from being permanently deleted.',
  buttonRenewalLabel = 'Confirm activation',
}) => {
  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnActivateRenew = () => {
    if (handleActivateSubscription) {
      handleActivateSubscription(activateSubscription);
      handleOnClose();
    }
  };

  return (
    <Modal onClose={handleOnClose} testId={testId}>
      <Styles.ContentWrapper>
        <Styles.ModalTitle>{title}</Styles.ModalTitle>
        <Styles.ModalText>
          {content}
        </Styles.ModalText>
      </Styles.ContentWrapper>

      <Styles.FooterWrapper>
        <Button
          label={buttonRenewalLabel.toUpperCase()}
          onClick={handleOnActivateRenew}
          testId="renew-button"
          variant="primary"
        />
      </Styles.FooterWrapper>
    </Modal>
  );
};

export default ActivateRenewModal;
