import useLocale from '@/hooks/useLocale/useLocale';
import * as Styles from './Cards.styles';
import React from 'react';
import { Button } from 'gatorcomponents';
import { IconFeatured } from '@/icons';

const Cards = ({
  handleClickAccess,
}) => {
  const { security: securityLocale } = useLocale();
  const handleAccess = (id) => {
    handleClickAccess && handleClickAccess(id);
  };
  return (
    <Styles.Wrapper>
      <Styles.CardsContainer>
        <Styles.CardsTitle>
          {securityLocale.cards.title}
        </Styles.CardsTitle>

        <Styles.CardsDescription>
          {securityLocale.cards.description}
        </Styles.CardsDescription>

        <Styles.CardsWrapper>
          {securityLocale.cards.items.map(item => (
            <Styles.Card key={item.title} data-testid={`card-${item.id}`}>
              {item.id === 'codeguard' && (
                <Styles.FeatureWrapper>
                  <IconFeatured />
                </Styles.FeatureWrapper>
              )}

              <Styles.CardItemTitle>
                {item.title}
              </Styles.CardItemTitle>

              <Styles.CardImageWrapper>
                <Styles.CardImage src={item.imgSrc} />
              </Styles.CardImageWrapper>

              <Styles.CardList>
                {item.list.map(listItem => (
                  <Styles.CardListItem key={`${item.id}-${JSON.stringify(listItem)}`}>
                    {listItem}
                  </Styles.CardListItem>
                ))}
              </Styles.CardList>

              <Styles.CardActionWrapper>
                <Button
                  testId={`button-${item.id}-access`}
                  label={item.action}
                  onClick={() => handleAccess(item.id)}
                />
              </Styles.CardActionWrapper>
            </Styles.Card>
          ))}
        </Styles.CardsWrapper>
      </Styles.CardsContainer>
    </Styles.Wrapper>
  );
};

export default Cards;
