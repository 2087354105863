import React from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import styles from './styles';

const TitanMailTitle = ({
  t, classes, domain, name,
}) => (
  <Card className={classes.card}>
    <Typography variant="h6" className={classes.pageTitle} data-testid="titanMailTitle">
      {name !== 'free'
        ? name
        : `${t('proEmail.type.free')}`}
    </Typography>
    <Typography className={classes.iframeDomain} data-testid="iframeDomain">
      {`(${domain})`}
    </Typography>
  </Card>
);


TitanMailTitle.propTypes = {
  domain: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(TitanMailTitle));
