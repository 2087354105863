import { FormsActionTypes } from './forms.types';

export const initialState = {
  loadingHgBackupHistory: false,
  hgRoutineBackups: {},
  forceState: false,
  rules: {
    active: '',
    selectedProduct: null,
    selectedSector: null,
    selectedNeed: null,
    restorateBackupHgRoutine: {
      selectedRestorationType: null,
      selectedBackupOption: null,
    },
    userBackupRestore: {
      selectedRestorationType: null,
    },
  },
};

const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FormsActionTypes.POST_FORM: {
      return {
        ...state,
      };
    }
    case FormsActionTypes.SET_HG_BACKUP_HISTORY_LOAD_STATE: {
      return {
        ...state,
        loadingHgBackupHistory: action.payload,
      };
    }
    case FormsActionTypes.SET_HG_ROUTINE_BACKUPS: {
      return {
        ...state,
        hgRoutineBackups: { ...action.payload },
      };
    }
    case FormsActionTypes.SET_DEFAULT_FORCE_STATE:
      return {
        ...state,
        forceState: false,
        active: null,
      };
    case FormsActionTypes.SET_FORCE_STATE:
      return {
        ...state,
        forceState: true,
        rules: {
          ...state.rules,
          active: action.payload.key,
          selectedProduct: action.payload.params.selectedProduct,
          selectedSector: action.payload.params.selectedSector,
          selectedNeed: action.payload.params.selectedNeed,
          [action.payload.key]: action.payload.params,
        },
      };
    default:
      return state;
  }
};

export default formsReducer;
