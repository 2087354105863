const styles = theme => ({
  tagControl: {
    '&.active': {
      '&:active': {
        backgroundColor: theme.color.oceanGreen,
        color: theme.color.white,
      },
      '&:focus': {
        backgroundColor: theme.color.oceanGreen,
        color: theme.color.white,
      },
      '&:hover': {
        backgroundColor: theme.color.oceanGreen,
        color: theme.color.white,
      },
      border: `1px solid ${theme.color.oceanGreen}`,
    },
    '&.inactive': {
      '&:hover': {
        backgroundColor: theme.color.geyser,
      },
    },
    backgroundColor: theme.color.white,
  },
  tagDefault: {
    '&:hover': {
      backgroundColor: theme.color.white,
    },
    textTransform: 'normal',
    textDecoration: 'none',
    fontWeight: '400',
    fontSize: '12px',
    flexGrow: 1,
    margin: 5,
  },
  tagActive: {
    color: theme.color.tundora,
    border: `1px solid ${theme.color.oceanGreen}`,
    '&:hover': {
      backgroundColor: `${theme.color.oceanGreen}`,
    },
  },
  tagCloudflare: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  tagInactive: {
    color: theme.color.tundora,
    border: `1px solid ${theme.color.geyser}`,
  },
  disabled: {
    cursor: 'not-allowed',
  },
});

export default styles;
