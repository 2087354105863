import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 0px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    width: max-content;
  `}
`;

export const LabelCardNumber = styled.p`
${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
`}
`;


export const LabelCardExpDate = styled.p`
${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    opacity: 1;
    margin-top: 1px;
`}
`;
