export default {
  logoutModal: {
    title: 'Su sesión expiró',
    description: 'Su sesión ha estado activa durante mucho tiempo, por razones de seguridad y protección de datos, se ha cerrado. Para continuar en el Portal del Cliente, haga clic en "Entrar nuevamente" e inicie sesión en el portal.',
    cta: 'Entrar nuevamente',
  },
  linkNaBioGuide: {
    title: '¡Crea gratis!',
    description: 'Amplía tu audiencia con un enlace único para tu negocio.',
  },
};
