import React from 'react';
import * as Styles from './AcademyPassTrialPage.styles';
import { AcademyPassTrialHandler } from '@/components/academy/academyPassTrial/AcademyPassTrialHandler';

const AcademyPassTrialPage = () => (
  <Styles.Wrapper data-testid="academy-pass-trial-page">
    <AcademyPassTrialHandler />
  </Styles.Wrapper>
);

export default AcademyPassTrialPage;
