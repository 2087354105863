import React, { useContext, useEffect, useState } from 'react';
import { Collapse, withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PortalAddDNS from '@/components/Dns/ZoneManager/AddRecordForm/PortalAddDNS';
import AddRecordForm from '@/components/Dns/ZoneManager/AddRecordForm';
import { DNSContext } from '@/contexts/DNS/context';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import CopyDnsButton from '@/components/Buttons/CopyDnsButton';
import styles from './styles';

const ZoneManager = ({ width, t, classes }) => {
  const {
    domainName,
    loading,
    setToggleAddRecordForm,
    toggleAddRecordForm,
    renderedRecordedItems,
  } = useContext(DNSContext);

  const isWidthDownSm = isWidthDown('sm', width);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      t('dns.zoneManager.recordList.listLabel.type'),
      t('dns.zoneManager.recordList.listLabel.name'),
      t('dns.zoneManager.recordList.listLabel.class'),
      t('dns.zoneManager.recordList.listLabel.ttl'),
      t('dns.zoneManager.recordList.listLabel.address'),
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isWidthDownSm
        ? (
          <PortalAddDNS toggleAddRecordForm={toggleAddRecordForm}>
            <AddRecordForm domainName={domainName} />
          </PortalAddDNS>
        )
        : (
          <Collapse in={toggleAddRecordForm}>
            <AddRecordForm domainName={domainName} />
          </Collapse>
        )
      }

      {(!toggleAddRecordForm) && (
        <div className={classes.dnsWizardLegacyDnsTableButtons}>
          <PrimaryButton data-testid="addZoneRecord" id="dns-zone-record-button" type="submit" color="primary" onClick={() => setToggleAddRecordForm(!toggleAddRecordForm)} disabled={loading}>
            {t('dns.zoneManager.addRecord.button')}
          </PrimaryButton>
          {renderedRecordedItems && renderedRecordedItems.length > 0 && (
            <CopyDnsButton columns={columns} items={renderedRecordedItems}>{t('dns.zoneManager.copyDnsTable')}</CopyDnsButton>
          )}
        </div>
      )}
    </>
  );
};

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ZoneManager)));
