import PropTypes from 'prop-types';

export const SubscriptionRenewalDataProps = {
  installmentsAmountLabel: PropTypes.string,
  installmentsAmountText: PropTypes.string,
  installmentsPaymentFormLabel: PropTypes.string,
  installmentsPaymentFormText: PropTypes.string,
  installmentsPaymentFormTooltipText: PropTypes.string,
  loading: PropTypes.bool,
  nextRenovationDateLabel: PropTypes.string,
  nextRenovationDateText: PropTypes.string,
  paymentMethodLabel: PropTypes.string,
  paymentMethodText: PropTypes.string,
  paymentMethodTooltipText: PropTypes.string,
  renewalDataTitleLabel: PropTypes.string,
  renovationAmountLabel: PropTypes.string,
  renovationAmountText: PropTypes.string,
  showInstallments: PropTypes.bool,
};
