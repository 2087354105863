import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, CircularProgress } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import MobileRecordList from '@/components/Dns/ZoneManager/RecordList/MobileRecordList';
import DesktopRecordList from '@/components/Dns/ZoneManager/RecordList/DesktopRecordList';
import Filter from '@/components/Dns/ZoneManager/RecordList/Filter';
import { DNSContext } from '@/contexts/DNS/context';
import ExcludeModalConfirmation from '@/components/Modal/ModalExcludeDnsZone';
import styles from './styles';

const RecordList = ({ classes, width }) => {
  const { showExcludeModalConfirmation, loading } = useContext(DNSContext);
  const isWidthDownSm = isWidthDown('sm', width);
  return (
    <div className={classes.recordListContainer} data-testid="zoneManagerRecordListWrapper">
      <Filter />

      {showExcludeModalConfirmation
        && <ExcludeModalConfirmation />
      }

      {isWidthDownSm
        && <MobileRecordList />
          }

      {!isWidthDownSm
        && <DesktopRecordList />
      }

      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default (withI18n()(withWidth()(withStyles(styles)(RecordList))));
