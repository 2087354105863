import React, { useEffect, useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './SplitPaymentModal.styles';
import useLocale from '@/hooks/useLocale';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import Loader from '@/components/Layout/Loader';
import { isMobile } from '@/utils/Validators/validation';
import { Checkbox, IconInfo } from 'gatorcomponents';
import { useDispatch } from 'react-redux';
import { invoicesActions } from '@/redux/modules';
import CurrencyFormat from '@/components/CurrencyFormat';
import { enqueueSnackbar } from '@/redux/actions/notifications';

const SplitPaymentModal = ({
  onCloseClick = null,
  invoice = null,
  testId = 'split-payment-moda-',
  onExecutePayment = null,
  setInvoiceDetails,
  isLoading = true,
}) => {
  const { billing: billingLocale } = useLocale();
  const dispatch = useDispatch();

  const { splitPaymentModal: splitPaymentModalLocale } = billingLocale;

  const {
    title,
    alertDescription,
    description,
    selectPaymentLabel,
    cancelLabel,
  } = splitPaymentModalLocale;

  const [loading, setLoading] = useState(isLoading);
  const [listProdutcs, setListProdutcs] = useState([]);
  const [listCheckedProducts] = useState([]);
  const [listOriginalProdutcs] = useState([]);
  const [reloadingListProducts, setReloadingListProducts] = useState(true);

  useEffect(() => {
    loadReCaptcha();
  }, [setLoading]);

  const updateListProductsCheck = (productId) => {
    listCheckedProducts.includes(productId) ? listCheckedProducts.pop(productId) : listCheckedProducts.push(productId);
    setReloadingListProducts(true);
  };

  const getProductName = (product) => {
    if (product.description.includes('(')) {
      const regex = /\(\d{2}/;
      return product.description.split(regex)[0];
    }
    return product.description;
  };

  const addProductToList = (product, list) => {
    list.push(
      <Styles.ContentProduct>
        <Styles.Selection>
          <Styles.CheckboxLabel
            htmlFor="#readTerms"
            data-testid={`${testId}product-${product.id}`}
            onClick={() => updateListProductsCheck(product.id)}
          >
            <Checkbox
              checked={!listCheckedProducts.includes(product.id)}
              value={product.id}
            />
          </Styles.CheckboxLabel>
          <Styles.ProductName>
            {`${getProductName(product)}`}
          </Styles.ProductName>
        </Styles.Selection>
        <Styles.ProductValue>
          <CurrencyFormat>{product.amount}</CurrencyFormat>
        </Styles.ProductValue>
      </Styles.ContentProduct>,
    );
    setLoading(false);
  };

  const reloadProducts = () => {
    const list = [];
    listOriginalProdutcs && listOriginalProdutcs.forEach((product) => {
      addProductToList(product, list);
    });
    setListProdutcs(list);
    setReloadingListProducts(false);
  };

  useEffect(() => {
    if (reloadingListProducts && listCheckedProducts.length >= 0) {
      reloadProducts();
    }
  }, [listCheckedProducts, listProdutcs, reloadingListProducts]);

  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
  };

  const setDataInvoice = (data) => {
    const list = [];

    if (data && data.items && data.items.length > 0) {
      setInvoiceDetails(data);
      data.items.forEach((product) => {
        if (product.description !== '' && product.isSplittable) {
          listOriginalProdutcs.push(product);
          addProductToList(product, list);
        }
      });
    }
    setListProdutcs(list);
  };

  const checkSplitAction = (code) => {
    if (code !== 200) {
      dispatch(enqueueSnackbar(billingLocale.genericError));
    } else {
      setLoading(true);
      dispatch(invoicesActions.invoices.loadDetails.request(invoice.id, setDataInvoice)).then(() => {
        onExecutePayment && onExecutePayment(true);
      });
    }
  };

  const executePayment = () => {
    if (listCheckedProducts.length === 0) {
      onExecutePayment && onExecutePayment();
    } else {
      dispatch(invoicesActions.invoices.splitPayment.request(invoice.id, listCheckedProducts, checkSplitAction));
    }
  };

  useEffect(() => {
    setDataInvoice([]);
    dispatch(invoicesActions.invoices.loadDetails.request(invoice.id, setDataInvoice));
  }, [invoice.id]);

  return (
    <Modal
      maxWidth={726}
      onClose={handleOnCloseClick}
      testId={testId}
      disablePadding
      fullScreen={isMobile}
    >
      <Styles.Content>
        <Styles.Header>
          <Styles.TitleModal data-testid={`${testId}title`}>{title}</Styles.TitleModal>
        </Styles.Header>
        <Styles.ContentInfo>
          <Styles.ContentIcon>
            <IconInfo size="md" />
          </Styles.ContentIcon>
          <Styles.DescriptionInfo data-testid={`${testId}info`}>
            {alertDescription}
          </Styles.DescriptionInfo>
        </Styles.ContentInfo>
        {!loading && (
          <>
            <Styles.ContentData>
              <Styles.Info>
                <Styles.Label data-testid={`${testId}description`}>
                  {description}
                </Styles.Label>
              </Styles.Info>
              <Styles.Products>
                {listProdutcs}
              </Styles.Products>
            </Styles.ContentData>
            <Styles.Controls>
              <Styles.ControlButton>
                <Button
                  disabled={listCheckedProducts.length === listProdutcs.length}
                  testId={`${testId}payment-button`}
                  onClick={() => { executePayment(); }}
                  label={selectPaymentLabel}
                />
                <Button
                  testId={`${testId}cancel-button`}
                  onClick={() => { handleOnCloseClick(); }}
                  variant="text"
                  label={cancelLabel}
                />
              </Styles.ControlButton>
            </Styles.Controls>
          </>
        )}
        {loading && (
          <Loader />
        )}
      </Styles.Content>
    </Modal>
  );
};


export default SplitPaymentModal;
