export const AVAILABLE_PRODUCT_TYPES = ['vps', 'dedicated', 'windows', 'shared', 'reseller', 'websitebuilder'];
export const AVAILABLE_DEDICATED_TYPES = ['vps', 'dedicated', 'windows'];
export const AVAILABLE_PRODUCT_DOMAIN_STATUS = ['Active', 'Pending', 'Suspended'];
export const AVAILABLE_ACTIVATE_SITEBUILDER_PRODUCTS = ['shared', 'reseller'];

export const AVAILABLE_REBOOT_PRODUCT_ID_LIST = {
  br: [56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 68, 69, 70, 71, 72, 73, 74, 75, 76, 302, 305, 308, 311, 314, 317, 320, 323, 326, 343, 346],
  mx: [56, 57, 58, 59, 60, 61, 64, 65, 134, 136, 138, 140, 201, 202, 203, 204, 205, 206, 208, 209, 210, 215, 216],
  co: [56, 57, 58, 59, 60, 61, 64, 65, 134, 136, 138, 140, 201, 202, 203, 204, 205, 206, 208, 209, 210, 215, 216],
  cl: [56, 57, 58, 59, 60, 61, 64, 65, 134, 136, 138, 140, 201, 202, 203, 204, 205, 206, 208, 209, 210, 215, 216],
};

export const SERVER_INFO_ID_LIST = {
  br: [302, 305, 308, 311, 314, 317, 343, 346],
  mx: [201, 202, 203, 204, 205, 206, 215, 216],
  co: [201, 202, 203, 204, 205, 206, 215, 216],
  cl: [201, 202, 203, 204, 205, 206, 215, 216],
};
