export const BRAVO_TIMER_VALIDATE_PHONE = 'bravo_timer_validate_phone';
export const BRAVO_TIMER_VALIDATE_EMAIL = 'bravo_timer_validate_email';
export const BRAVO_SITES_ITEM_CHANGE_SUBSTATUS_TO_STATUS = 'bravo_sites_item_-_change_substatus_to_status';
export const BRAVO_PRODUCTS_ACCESS_SITES_WIZARD = 'bravo_products_access_sites_wizard';
export const BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN = 'bravo_wizard_sitebuilder_window_open';
export const BRAVO_ASAAS_INSIDE_WIZARD = 'bravo_asaas_inside_wizard';
export const BRAVO_EMAIL_FLOW = 'bravo_email_flow';
export const BRAVO_CHANGE_PHONE_FLOW = 'bravo_change_phone_flow';
export const BRAVO_DISABEL_SINGAPORE_FONE = 'bravo_disabel_singapore_fone';
export const BRAVO_NEW_CREATE_MODAL_BEHAVIOR = 'bravo_new_create_modal_behavior';
export const BRAVO_CREATE_SITE_SPECIALIST_GLOBAL = 'bravo_create_site_specialist_global';
export const SITES_FILTER_V2 = 'sites_filter_v2';
export const USER_DATA_CHANGE_PHONE_FLOW = 'user_data_-_change_phone_flow';
export const USER_DATA_CHANGE_EMAIL_FLOW = 'user_data_-_change_email_flow';
export const BRAVO_SMS_TIMER_DISABLE_ON_SEND = 'bravo_sms_timer_disable_on_send';
export const BRAVO_MAIL_TIMER_DISABLE_ON_SEND = 'bravo_mail_timer_disable_on_send';
export const BRAVO_REMOVE_CONFIGURE_ATTEMPT_WORDPRESS = 'bravo_remove_configure_attempt_wordpress';

export const ALPHA_DISABLED_CHECKBOX_IN_TRIAL_MODAL_IN_HIREPLAN = 'alpha_disabled_checkbox_in_trial_modal_in_hireplan';
export const ALPHA_DISABLED_CYCLE_PAYMENT_IN_UPGRADE_PLAN = 'alpha_disabled_cycle_payment_in_upgrade_plan';
export const ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_LEGACY = 'alpha_domains_list_dns_configuration_legacy';
export const ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD = 'alpha_domains_list_dns_configuration_wizard';
export const ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES = 'alpha_domains_list_dns_configuration_wizard_es';
export const ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG = 'alpha_domains_list_dns_propagation_tag';
export const ALPHA_DOMAINS_MANAGE_ADD_MANAGE_SUBSCRIPTION_BUTTON = 'alpha_domains_manage_add_manage_subscription_button';
export const ALPHA_DOMAINS_MANAGE_ENABLE_AUTO_RENEW_ACTIONS = 'alpha_domains_manage_enable_auto_renew_actions';
export const ALPHA_DOMAINS_MANAGE_ENABLE_MESSAGE_IMPROVEMENTS = 'alpha_domains_manage_enable_message_improvements';
export const ALPHA_DOMAINS_WIZARD_SETUP_THE_EMAIL_PLATFORM = 'alpha_domains_wizard_setup_the_email_platform';
export const ALPHA_ADMIN_TITAN_MAIL = 'alpha_admin_titan_mail';
export const ALPHA_ENABLE_COLORFUL_MODAL_FOR_TITAN_TRIAL_IN_EMAILS_PAGE = 'alpha_enable_colorful_modal_for_titan_trial_in_emails_page';
export const ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM = 'alpha_enable_trial_titan_by_url_param';
export const ALPHA_ENABLED_SORT_SUBDOMAINS_LIST = 'alpha_enabled_sort_subdomains_list';
export const ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_BANNER_IN_SITES = 'alpha_flow_to_sell_titan_trial_by_banner_in_sites';
export const ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN = 'alpha_flow_to_sell_titan_trial_by_modal_in_hireplan';
export const ALPHA_FRONT_NEW_STATUS_OF_DOMAIN = 'alpha_front_new_status_of_domain';
export const ALPHA_FRONT_TITAN_PROMOTE_IN_SITES = 'alpha_front_titan_promote_in_sites';
export const ALPHA_MODAL_TO_SHOW_COMPENSATION_OF_UPGRADE_TITAN_UPGRADE = 'alpha_modal_to_show_compensation_of_upgrade_titan_upgrade';
export const ALPHA_NEW_BENEFIT_PRICE_VISUALIZATION = 'alpha_new_benefit_price_visualization';
export const ALPHA_PLAN_TYPE_CHOICE_IN_EMAIL_LIST = 'alpha_plan_type_choice_in_email_list';
export const ALPHA_RETENTION_BENEFIT_PAGE = 'alpha_retention_benefit_page';
export const ALPHA_RETENTION_PAGE_FLOW = 'alpha_retention_page_flow';
export const ALPHA_TITAN_UPGRADE_PLAN_CYLE = 'alpha_titan_upgrade_plan_cyle';
export const ALPHA_TITANTRIAL_EMAILS_ENABLE_OLD_ROUTE = 'alpha_titantrial_emails_enable_old_route';
export const ALPHA_UPGRADE_BENEFIT_PAGE = 'alpha_upgrade_benefit_page';

export const CHARLIE_CHAT_DISABLE_SKILLED_DEPARTMENT = 'charlie_chat_disable_skilled_department';
export const CHARLIE_PRECHAT = 'charlie_prechat';
export const CHARLIE_PRECHAT_TRIAGE_SCREEN = 'charlie_prechat_triage_screen';
export const CHARLIE_TICKETS_STATUS_V2 = 'charlie_tickets_status_v2';
export const CHARLIE_GATORHELPER_FORCE_WEEKEND = 'charlie_gatorhelper_force_weekend';
export const CHARLIE_TOKEN_REFRESH = 'charlie_token_refresh';
export const CHARLIE_NEW_SITES_LIST = 'charlie_new_sites_list';
export const CHARLIE_MYALERT_SSL = 'charlie_myalert_ssl';
export const CHARLIE_SSL_MODAL = 'charlie_ssl_modal';
export const CHARLIE_EMAIL_VERIFY_V2 = 'charlie_email_verify_v2';
export const CHARLIE_NEW_CHANGE_PASSWORD_PAGE = 'charlie_new_change_password_page';
export const CHARLIE_PRECHAT_REARANGE_CATEGORIES = 'charlie_prechat_rearange_categories';
export const CHARLIE_MIGRATE_SUPPORT_FORMS = 'charlie_migrate_support_forms';
export const CHARLIE_SITELOCK = 'charlie_sitelock';
export const CHARLIE_PRIORITY_CHAT = 'charlie_priority_chat';
export const CHARLIE_DATADOG_DIAGNOSTIC = 'charlie_datadog_diagnostic';
export const CHARLIE_BRADCRUMB_TO_HISTORY_BACK = 'charlie_bradcrumb_to_history_back';
export const CHARLIE_WIZARD_DNS_DLOJA = 'charlie_wizard_dns_dloja';
export const CHARLIE_LOGOUT_MODAL = 'charlie_logout_modal';
export const CHARLIE_FORCE_UPDATE_JWT_IN_REDUCERS = 'charlie_force_update_jwt_in_reducers';
export const CHARLIE_DOMAIN_MANAGEMENT_V2 = 'charlie_domain_management_v2';
export const CHARLIE_NEW_PHONE_CARD = 'charlie_new_phone_card';
export const CHARLIE_CHECKOUT_PHONEINPUT_FLAGS = 'charlie_checkout_phoneinput_flags';
export const CHARLIE_NEW_DOMAINS_LIST = 'charlie_new_domains_list';
export const CHARLIE_MIGRATE_SUPPORT_FORMS_SECCOND_WAVE = 'charlie_migrate_support_forms_seccond_wave';
export const CHARLIE_MIGRATE_SUPPORT_NEW_VPS_UPGRADE = 'charlie_migrate_support_new_vps_upgrade';
export const CHARLIE_MIGRADE_SUPPORT_FORMS_CHANGE_PRIMARY_DOMAIN = 'charlie_migrade_support_forms_change_primary_domain';
export const CHARLIE_MIGRADE_SUPPORT_FORMS_GENMERAL_DOUBTS = 'charlie_migrade_support_forms_genmeral_doubts';
export const CHARLIE_DATADOG_ALL_LOGS = 'charlie_datadog_all_logs';
export const CHARLIE_DATADOG_GENERIC_LOGS = 'charlie_datadog_generic_logs';
export const CHARLIE_GROUP_SITES_CARDS = 'charlie_group_sites_cards';
export const CHARLIE_CPANEL_SSO_PORTAL = 'charlie_cpanel_sso_portal';
export const CHARLIE_SITES_WIZARD_AUTO_SSL = 'charlie_sites_wizard_auto_ssl';
export const CHARLIE_PROVISIONATED_SITELOCK_MODAL = 'charlie_provisionated_sitelock_modal';
export const CHARLIE_CHANGE_PAYMENT_METHOD_MODAL = 'charlie_change_payment_method_modal';
export const CHARLIE_SUBSCRIPTION_CHANGE_PAYMENT_METHOD_MODAL = 'charlie_subscription_change_payment_method_modal';
export const CHARLIE_VIDEO_CLASSES = 'charlie_video_classes';
export const CHARLIE_LIVE_TRACK = 'charlie_live_track';
export const CHARLIE_CREDIT_CARDV2 = 'charlie_credit_cardv2';
export const CHARLIE_LINK_NA_BIO = 'charlie_link_na_bio';
export const CHARLIE_DOMAINS_LIST_HANDLER_PAGINATION = 'charlie_domains_list_handler_pagination';
export const CHARLIE_SEARCHABLE_SELECT_INSIDE_SITESWIZARD = 'charlie_searchable_select_inside_siteswizard';
export const CHARLIE_LINK_NA_BIO_EMOJI = 'charlie_link_na_bio_emoji';
export const CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA = 'charlie_link_na_bio_social_media';
export const CHARLIE_ENABLE_START_PLAN_AT_SITESWIZARD = 'charlie_enable_start_plan_at_sitesWizard';
export const CHARLIE_SKIP_HG_ROUTINE_BACKUP = 'charlie_skip_hg_routine_backup';
export const CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS = 'charlie_change_sites_ProductSubscriptions_to_GatorSolutions';
export const CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS_PLANS = 'charlie_change_sites_productSubscriptions_to_gatorSolutions_plans';
export const CHARLIE_LINK_NA_BIO_DATA_ANALYSIS = 'charlie_link_na_bio_data_analysis';
export const CHARLIE_ZENDESK_V2 = 'charlie_zendesk_v2';
export const CHARLIE_NEW_ZENDESK_GATORHELPER = 'charlie_new_zendesk_gatorhelper';
export const CHARLIE_ZENDESK_COUNTER_TITLE = 'charlie_zendesk_counter_title';
export const CHARLIE_ZENDESK_CHAT_TITLE = 'charlie_zendesk_chat_title';
export const CHARLIE_ZENDESK_SEND_MESSAGE_BUTTON_TITLE = 'charlie_zendesk_send_message_button_title';
export const CHARLIE_ZENDESK_BOT_RESET = 'charlie_zendesk_bot_reset';
export const CHARLIE_LINK_NA_BIO_WHATSAPP = 'charlie_link_na_bio_whatsapp';
export const CHARLIE_NEW_ZENDESK_CATEGORIES_REARRANGE = 'charlie_new_zendesk_categories_rearrange';
export const CHARLIE_TICKET_HISTORY = 'charlie_ticket_history';
export const CHARLIE_LINK_NA_BIO_GENERAL_GUIDE = 'charlie_link_na_bio_general_guide';
export const CHARLIE_SHOW_CREATE_SITE_BANNER = 'charlie_show_create_site_banner';
export const CHARLIE_LINK_NA_BIO_CUSTOM_DOMAIN = 'charlie_link_na_bio_custom_domain';
export const REMOVE_TICKETS = 'remove_tickets';
export const CHARLIE_ZENDESK_BOT_BRAND = 'charlie_zendesk_bot_brand';
export const CHARLIE_ZENDESK_DELETE_SUNSHINE = 'charlie_zendesk_delete_sunshine';
export const CHARLIE_UPGRADE_PLAN_FORMS = 'charlie_upgrade_plan_forms';
export const CHARLIE_CHANGE_PAYMENT_CYCLE_FORM = 'charlie_change_payment_cycle_form';
export const CHARLIE_PRIVATE_SSL_CERTIFICATE = 'charlie_private_ssl_certificate';
export const CHARLIE_INSTALL_WHMCS = 'charlie_install_whmcs';
export const CHARLIE_REBUILD_RELOAD = 'charlie_rebuild_reload';
export const CHARLIE_FORM_MORE_EMAILS = 'charlie_form_more_emails';
export const CHARLIE_START_CHAT_MESSAGE = 'charlie_start_chat_message';
export const CHARLIE_SERVER_MIGRATION_VPS_DEDICATED = 'charlie_server_migration_vps_dedicated';
export const CHAR_UPDATE_WHMCS_LICENSE = 'char_update_whmcs_license';
export const CHARLIE_WHMCS_ACQUISITION_FORM = 'charlie_whmcs_acquisition_form';
export const CHARLIE_MIGRATE_RESELLER = 'charlie_migrate_reseller';
export const CHARLIE_ZENDESK_ALLOW_OLD_VERSION = 'charlie_zendesk_allow_old_version';
export const CHARLIE_CLOUDFLARE_MANAGE_CDN = 'charlie_cloudflare_manage_cdn';
export const CHARLIE_CLOUDFLARE = 'charlie_cloudflare';
export const CHARLIE_CLOUDFLARE_FEATURES = 'charlie_cloudflare_features';
export const CHARLIE_USE_MOCKOON = 'charlie_use_mockoon';
export const CHARLIE_CLOUDFLARE_CHANGE_PLAN_V2 = 'charlie_cloudflare_change_plan_v2';

export const XRAY_VERIFY_CARD_CHECK = 'xray_enable_verifycard_check';
export const XRAY_VERIFY_CARD_LOGS = 'xray_enable_verifycard_logs';
export const XRAY_MANAGEMENT_SIGNATURES_ENABLED_CYCLE_PAYMENT_BUTTON = 'xray_management_signatures_enabled_payment_cycle_button';
export const XRAY_ENABLED_NEW_LAYOUT_INVOICE_PAGE = 'xray_enabled_new_layout_invoice_page';
export const XRAY_ENABLED_VALIDATION_FILTER_WITH_PAGE_NAME = 'xray_enabled_validation_filter_with_page_name';
export const XRAY_MANAGE_REFUND_PAYMENT_CREDITCARD = 'xray_manage_refund_payment_creditcard';
export const XRAY_MANAGE_REFUND_PAYMENT_PAYPAL = 'xray_manage_refund_payment_paypal';
export const XRAY_MANAGE_REFUND_PAYMENT_PIX = 'xray_manage_refund_payment_pix';
export const XRAY_ENABLED_HEADER_INVOICE_PAGE = 'xray_enabled_header_invoice_page';
export const XRAY_PIX_PAYMENT_GATEWAY = 'xray_pix_payment_gateway';
export const XRAY_ENABLED_OPTION_TO_SELECT_PAYPAL_ACCOUNT = 'xray_enabled_option_to_select_paypal_account';
export const XRAY_ENABLED_NEW_TICKET_MODAL = 'xray_enabled_new_ticket_modal';
export const XRAY_ENABLED_BARCODE_DISPLAY = 'xray_enabled_barcode_display';
export const XRAY_ENABLED_SWITCH_PAYMENT_METHOD_BY_PAYPAL = 'xray_enabled_switch_payment_method_by_paypal';
export const XRAY_LIMIT_TIME_IN_SECONDS_TO_DISPLAY_PIX_CODE = 'xray_limit_time_in_seconds_to_display_pix_code';
export const XRAY_ENABLED_REDIRECT_TO_TICKET_MODAL = 'xray_enabled_redirect_to_ticket_modal';
export const XRAY_ENABLED_PAYMENT_METHOD_FILTER = 'xray_enabled_payment_method_filter';
export const XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL = 'xray_limit_date_to_display_new_pix_label';
export const XRAY_ENABLED_NEW_MESSAGES_IN_SUBSCRIPTION_LIST = 'xray_enabled_new_messages_in_subscription_list';
export const XRAY_ENABLED_MODAL_PAYMENT_IN_SUBSCRIPTION_LIST = 'xray_enabled_modal_payment_in_subscription_list';
export const XRAY_ENABLED_PAYMENT_BY_TICKET = 'xray_enabled_payment_by_ticket';
export const XRAY_ENABLED_PAYMENT_BY_CREDIT_CARD = 'xray_enabled_payment_by_credit_card';
export const XRAY_INSTALLMENTS_MODAL = 'xray_installments_modal';
export const XRAY_ENABLED_FINGERPRINT = 'xray_enabled_fingerprint';
export const XRAY_ENABLED_SUBSCRIPTIONS_IN_DIAGNOSTIC = 'xray_enabled_subscriptions_in_diagnostic';
export const XRAY_ENABLED_MODAL_IN_SUBSCRIPTION_MANAGER_BY_URL = 'xray_enabled_modal_in_subscription_manager_by_url';
export const XRAY_ENABLED_NEW_LAYOUT_SUBSCRIPTIONS_PAGE = 'xray_enabled_new_layout_subscriptions_page';
export const XRAY_ENABLED_DISCOUNT_OFFER_BANNER = 'xray_enabled_discount_offer_banner';
export const XRAY_ENABLED_DISCOUNT_SAVERS = 'xray_enabled_discount_savers';
export const XRAY_LIMIT_DATE_TO_DISPLAY_INVOICE_SPLIT_MESSAGE = 'xray_limit_date_to_display_invoice_split_message';
export const XRAY_LIMIT_DATE_TO_DISPLAY_SPLIT_PAYMENT_BANNER = 'xray_limit_date_to_display_split_payment_banner';
export const XRAY_ENABLED_SPLIT_PAYMENT = 'xray_enabled_split_payment';
export const XRAY_ENABLED_DISCOUNT_RETENTION = 'xray_enabled_discount_retention';
export const XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_IMMEDIATE = 'xray_enabled_discount_retention_cancel_immediate';
export const XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_RENEWAL = 'xray_enabled_discount_retention_cancel_renewal';
export const XRAY_RETENTION_OFFER_VERSION_PAGE_AB_TEST = 'xray_retention_offer_version_page_ab_test';
export const XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND = 'xray_enabled_discount_retention_with_new_backend';
export const XRAY_CHANGE_PRODUCT_GATEWAY_USER_REQUEST = 'xray_change_product_gateway_user_request';
export const XRAY_ENABLED_WELCOME_CHECKLIST = 'xray_enabled_welcome_checklist';
export const XRAY_ENABLED_RETENTION_OFFER = 'xray_enabled_retention_offer';
export const XRAY_ENABLED_NEW_NFSE_ENDPOINT_CALLING = 'xray_enabled_new_nfse_endpoint_calling';

export const FOXTROT_WIZARD_WONDERSUITE = 'foxtrot_wizard_wondersuite';
export const FOXTROT_WOOPREMIUM_OFFER = 'foxtrot_woopremium_offer';
export const FOXTROT_FASTCHECKOUT_ADDON = 'foxtrot_fastcheckout_addon';
export const FOXTROT_WIZARD_SITE_EXISTS_CHECK = 'foxtrot_wizard_site_exists_check';
export const FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE = 'foxtrot_ecommerce_plus_cycle_change';
export const FOXTROT_ECOMMERCE_PLUS_MODAL_TO_WIZARD = 'foxtrot_ecommerce_plus_modal_to_wizard';
export const FOXTROT_TITAN_ULTRA_SETUP_PAGE = 'foxtrot_titan_ultra_setup_page';
export const FOXTROT_REFUND_TAG = 'foxtrot_refund_tag';
export const FOXTROT_WORDPRESS_IN_WIZARD = 'foxtrot_wordpress_in_wizard';

export const SEND_ZENDESK_TAGS = 'send_zendesk_tags';
export const ZENDESK_WIDGET_KEY = 'zendesk_widget_key';

export const EXPERIENCE_SITES_ADITION_DOMAIN_V2 = 'experience_sites_adition_domain_v2';
export const EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY = 'experience_change_endpoint_product_category';
export const EXPERIENCE_ACCOUNT_SECURITY = 'experience_account_security';

export const PRE_CHAT_ESTIMATED_TIME_TO_CHAT = 'pre_chat_estimated_time_to_chat';
export const DELETE_ZENDESK_LOCAL_STORAGE = 'delete_zendesk_local_storage';
export const HIDE_CHAT_BUTTON = 'hide_chat_button';
export const MONITOR_CHAT_INACTIVITY = 'monitor_chat_inactivity';
export const ZENDESK_TICKET_ALERT = 'zendesk_ticket_alert';
export const ZENDESK_LOGIN_RETRY = 'zendesk_login_retry';
export const NEW_SUPPORT = 'new_support';
export const ZENDESK_CHAT_FINISH = 'zendesk_chat_finish';
