export const brSitebuilderProducts = [
  5,
  6,
  7,
  335,
  385,
  388,
  391,
  394,
  397,
  400,
  403,
  406,
  409,
  412,
  415,
  50,
  51,
  52,
  53,
  54,
];

export const esSitebuilderProducts = [
  174,
  5,
  6,
  7,
  213,
  50,
  229,
  230,
  231,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  240,
  51,
  52,
  53,
  54,
];

export const SITELOCK_PLANS = {
  BASIC: 'Sitelock Basic',
  PROFESSIONAL: 'Sitelock Professional',
  PREMIUM: 'Sitelock Premium',
  ENTERPRISE: 'Sitelock Enterprise',
  FREE: 'Sitelock Free',
};
