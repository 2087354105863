import domainsBR from './domains.br';
import domainsCL from './domains.cl';
import domainsCO from './domains.co';
import domainsMX from './domains.mx';

const locales = {
  BR: domainsBR,
  CL: domainsCL,
  CO: domainsCO,
  MX: domainsMX,
};

export default locales;
