import React from 'react';
import {
  Card, CardContent, Typography, withStyles,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import classnames from 'classnames';

import styles from './styles';


const StaticMessage = ({
  classes, className, oldReseller, children, action,
}) => (
  <Card
    className={classnames(`${classes.card} ${className}`, {
      [classes.alertCritical]: className === 'critical',
      [classes.oldResellerPaddingCard]: oldReseller,
    })}
  >
    <CardContent
      className={classnames(classes.cardContent, {
        [classes.oldResellerPaddingCardContent]: oldReseller,
      })}
    >
      <Typography className={classes.messageContent}>
        <Warning className={classes.messageIcon} />
        <span className={classes.messageBox}>
          <span className={classes.message}>
            {children}
            {' '}
            {action}
          </span>
        </span>
      </Typography>
    </CardContent>
  </Card>
);

export default withStyles(styles)(StaticMessage);
