import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './LinkModal.styles';
import { Button, IconDanger, Input } from 'gatorcomponents';
import { linkType, wizardStep2Actions } from '../../LinkNaBioWizardHandler.enum';
import withErrorBoundary from '@/utils/errorBoundary';

const LinkModal = ({
  linkAction = '',
  stepLocale = {},
  handleModalDisplay = () => null,
  modalData = {},
  updateModalData = () => null,
  handleKeyDown = () => null,
  sanitizeLinkURL = () => null,
  validateLink = () => null,
  submitLink = () => null,
  deleteLink = () => null,
  hasLinkError = false,
}) => (
  <>
    {(linkAction.action === wizardStep2Actions.ADD_LINK || linkAction.action === wizardStep2Actions.EDIT_LINK) && (
      <Modal
        maxWidth={480}
        testId="link-na-bio-modal"
        onClose={handleModalDisplay}
      >
        <Styles.ModalContent>
          <Styles.ModalTitle>
            {stepLocale.insertLink}
          </Styles.ModalTitle>

          <Input
            label={stepLocale.linkTitle}
            value={modalData.description}
            placeholder={stepLocale.linkTitlePlaceHolder}
            onChange={event => updateModalData(event.currentTarget.value, 'description')}
            maxLength={100}
            onKeyDown={({ key }) => handleKeyDown({ key, type: linkType.LINK })}
            testId={stepLocale.linkTitle}
          />

          <Input
            label={stepLocale.url}
            value={modalData.link}
            placeholder={stepLocale.urlPlaceHolder}
            onChange={event => updateModalData(event.currentTarget.value, 'link')}
            maxLength={modalData.link && modalData.link.startsWith('http') ? 255 : 245}
            onKeyDown={({ key }) => handleKeyDown({ key, type: linkType.LINK })}
            testId={stepLocale.url}
            onBlur={validateLink}
            errorMessage={hasLinkError ? stepLocale.invalidLink : ''}
            customMask={sanitizeLinkURL}
          />

          <Styles.ModalButtonsWrapper>
            <Button
              label={stepLocale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleModalDisplay}
            />
            <Button
              label={linkAction.action === wizardStep2Actions.EDIT_LINK ? stepLocale.edit : stepLocale.add}
              size="large"
              onClick={submitLink}
              disabled={!modalData.description || !modalData.link || hasLinkError}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    )}

    {linkAction.action === wizardStep2Actions.DELETE_LINK && (
      <Modal
        maxWidth={620}
        testId="link-na-bio-modal"
        onClose={handleModalDisplay}
      >
        <Styles.ModalContent>
          <Styles.ModalTitle>
            {stepLocale.deleteModalTitle}
          </Styles.ModalTitle>
          <Styles.ModalDescription>
            <IconDanger size="xl" color="attentionPure" />
            {stepLocale.delete}
          </Styles.ModalDescription>


          <Styles.ModalDeleteButtonsWrapper>
            <Button
              label={stepLocale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleModalDisplay}
            />
            <Button
              label={stepLocale.confirmDelete}
              size="large"
              onClick={deleteLink}
            />
          </Styles.ModalDeleteButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    )}
  </>
);

export default withErrorBoundary(LinkModal, 'LinkModal');
