import React, { useCallback, useEffect, useState } from 'react';


import useLocale from '@/hooks/useLocale';
import * as Styles from './QRCodePixCard.styles';
import { IconWatch, IconCopyContent } from '@/icons';

import CopyPasteButton from '../../../pages/common/v1/CopyPasteButton/CopyPasteButton';
import { Timer } from '@/pages/common/v1/Timer';
import { Input } from '@/pages/common/v1/inputs';
import { useDispatch } from 'react-redux';
import { invoicesActions } from '@/redux/modules';
import { INVOICES_STATUS } from '@/config/invoices/invoiceStatus';
import { billingAnalytics } from '@/analytics';
import { OriginCalling } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';
import { isMobile } from '@/utils/Validators/validation';

const QRCodePixCard = ({
  pixCode = '00020126360014BR.GOV.BCB.PIX0114+554899608447052040000530398654040.015802BR5912Luiz Gustavo6009Ararangua62150511119585989926304BD53',
  invoiceId = null,
  onFinishPixTimer = null,
  timeToVerifyInvoiceStatus = 14,
  limitTimeInSecondsToDisplayPixCode,
  onPaymentSuccessPix = null,
  qrCode = null,
  originCalling = null,

}) => {
  const { billing: billingLocale } = useLocale();
  const { manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale } = billingLocale;
  const [isFinishedTimeInTimer, setFinishedTimeInTimer] = useState(false);
  const timerPix = manageSubscriptionPaymentInvoiceModalLocale.timerPixLabel;
  const firstLineTitlePixQRCodeCard = manageSubscriptionPaymentInvoiceModalLocale.titlePixQRCodeCardLabel[0];
  const secoundLineTitlePixQRCodeCard = manageSubscriptionPaymentInvoiceModalLocale.titlePixQRCodeCardLabel[1];

  const copyCodePix = manageSubscriptionPaymentInvoiceModalLocale.copyCodePixLabel;
  const copiedCodePix = manageSubscriptionPaymentInvoiceModalLocale.copiedCodePixLabel;
  const step1Pix = manageSubscriptionPaymentInvoiceModalLocale.step1PixLabel;
  const step2Pix = manageSubscriptionPaymentInvoiceModalLocale.step2PixLabel;
  const step3Pix = manageSubscriptionPaymentInvoiceModalLocale.step3PixLabel;
  const doubtsPix = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixLabel;
  const doubtsLink = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixLink;
  const doubtsURL = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixURL;


  const [invoiceDetails, setInvoiceDetails] = useState({});
  const dispatch = useDispatch();

  const [verificationPaymentNumber, setVerificationPaymentNumber] = useState(0);

  const idContentDataPix = 'content-data-pix';
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;


  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;


  const onClickCopyCode = () => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('4_copiar_codigo');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('4_copiar_codigo');
    }

    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('4_copiar_codigo');
    }
  };

  const onClickSupportLink = () => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('4_central_ajuda');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('4_central_ajuda');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('4_central_ajuda');
    }
  };

  const onFocus = (inputPix) => {
    const buttonCopyPix = document.getElementById('qr-code-copy-paste-button');
    inputPix.target.select();
    buttonCopyPix.click();
  };

  const setDataInvoice = (data) => {
    setInvoiceDetails(data);

    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }
    setVerificationPaymentNumber(verificationPaymentNumber => verificationPaymentNumber + 1);
  };


  const startTimer = useCallback(() => {
    const id = window.setInterval(() => {
      dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, setDataInvoice));
    }, timeToVerifyInvoiceStatus * 1000);
    window.setTimeout(() => {
      window.clearInterval(id);
    }, timeToVerifyInvoiceStatus * 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    startTimer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationPaymentNumber]);

  const handlerFinishTimer = () => {
    setFinishedTimeInTimer(true);
    if (Object.keys(invoiceDetails).length === 0 || invoiceDetails.status === INVOICES_STATUS.UNPAID) {
      onFinishPixTimer && onFinishPixTimer();
    }
  };

  return (
    <>

      <Styles.ContentDataPix data-testid={idContentDataPix} id={idContentDataPix}>
        {!isFinishedTimeInTimer && (
        <Styles.PixDescription variant={isFinishedTimeInTimer ? 'withPix' : 'default'}>
          <>
            {!isMobile && (
              <>
                { firstLineTitlePixQRCodeCard}
                {secoundLineTitlePixQRCodeCard }
              </>
            )}
            {isMobile && (
              <>
                { firstLineTitlePixQRCodeCard}
                {'.'}
              </>
            )}
          </>
        </Styles.PixDescription>
        )}
        <Styles.PixDescription variant="withPix">
          <Styles.Icon>
            <IconWatch color="#2E93EE" size="16.4" />
          </Styles.Icon>
          <>
            {timerPix}
            {!isFinishedTimeInTimer && (
              <Timer
                startSecound={limitTimeInSecondsToDisplayPixCode === null ? 0 : limitTimeInSecondsToDisplayPixCode}
                startMinute={limitTimeInSecondsToDisplayPixCode === null ? 10 : 0}
                onFinish={handlerFinishTimer}
                displayHour={false}
              />
            )}
          </>
        </Styles.PixDescription>
        {!isMobile && (
          <Styles.ContentPixUpdater>

            <Styles.QRCode
              variant="default"
              hasPixGenerated={isFinishedTimeInTimer}
              src={`data:image/png;base64,${qrCode}`}
              loading="lazy"
            />
          </Styles.ContentPixUpdater>
        )}
      </Styles.ContentDataPix>
      {!isFinishedTimeInTimer && (
      <Styles.ContentPixCode data-testid="content-pix-code">
        <Styles.ContentInputPixCode
          data-testeid="content-input-pix-code"
          onFocus={onFocus}
        >
          <Input
            testId="input-pix-code"
            readOnly
            fullWidth
            type="text"
            value={pixCode}
          />
        </Styles.ContentInputPixCode>
        <CopyPasteButton
          id="qr-code-copy-paste-button"
          testId="qr-code-copy-paste-button"
          clipBoardText={pixCode}
          copyLabel={copyCodePix}
          copiedLabel={copiedCodePix}
          timeOut={5}
          onClick={() => { onClickCopyCode(); }}
          contentButtonId={idContentDataPix}
          icon={<IconCopyContent size="16" />}
        />
      </Styles.ContentPixCode>
      )}
      <Styles.Content
        variant={isFinishedTimeInTimer ? 'withPix' : 'default'}
        data-testid="content-data-info"
      >
        <Styles.List>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>01</Styles.Number>
              {step1Pix}
            </Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>02</Styles.Number>
              {step2Pix}
            </Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>03</Styles.Number>
              {step3Pix}
            </Styles.Description>
          </Styles.Item>
        </Styles.List>
      </Styles.Content>
      <Styles.ContentDoubts>
        {doubtsPix}
        <Styles.Link
          data-testid="generate-pix-link"
          href={doubtsURL}
          target="_blank"
          onClick={() => { onClickSupportLink(); }}
        >
          {doubtsLink}
        </Styles.Link>
      </Styles.ContentDoubts>
    </>
  );
};


export default QRCodePixCard;
