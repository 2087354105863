import { IconDetailedServer } from '@/icons';
import { IconArrowForward } from '@/icons/IconArrowForward';
import { Link } from '@/pages/common';
import React, { useCallback } from 'react';
import * as Styles from './EmptyList.styles';
import { IconTriangleDetail } from 'gatorcomponents';
import SnappyFace from '@/media/pageDescription/snappy-face.svg';

const EmptyList = ({
  handleEmptyClick,
  linkOptions = [
    {
      name: 'server',
      label: 'Gerenciar seu Servidor',
      icon: <IconDetailedServer />,
      route: '/',
    },
  ],
  withMessage,
  emptyLocale,
}) => {
  const handleClick = useCallback((option) => {
    handleEmptyClick && handleEmptyClick(option);
  }, [handleEmptyClick]);

  return (
    <>
      {withMessage && (
      <Styles.MessageWrapper>
        <IconTriangleDetail color="attentionPure" />

        <Styles.Image data-testid="page-description-snappy" src={SnappyFace} />

        <Styles.Title>{emptyLocale}</Styles.Title>
      </Styles.MessageWrapper>
      )}
      <Styles.Wrapper data-testid="emptyList">
        {linkOptions.map(option => (
          <Styles.OptionItem key={`action_${option.name}`}>
            {option.icon}
            <Link
              href={option.route}
              text={option.label}
              variant="noDefaultDecoration"
              testId={`link_${option.name}`}
              target={option.target}
              historyRedirect
              onClick={() => handleClick(option)}
            />
            <IconArrowForward size={18} />
          </Styles.OptionItem>
        ))}
      </Styles.Wrapper>
    </>
  );
};

export default EmptyList;
