import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import Autosuggest from 'react-autosuggest';
import {
  InstantSearch, Configure, Index, connectAutoComplete,
} from 'react-instantsearch-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Card, TextField } from '@material-ui/core/';
import { withI18n } from 'react-i18next';
import Hits from '@/components/Tickets/Hits';
import LinkButton from '@/components/Buttons/LinkButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { ZENDESK } from '@/config/application/zendeskConfig';
import { SUPPORT_URL } from '@/config/urls/supportUrls';
import styles from './styles';
import * as Styles from './Search.styles';
import { IconSearch } from 'gatorcomponents/';

const algoliaClient = algoliasearch(ZENDESK.appId, ZENDESK.apiKey);
const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return algoliaClient.search(requests);
  },
};

const Search = ({ classes, t, v2 }) => {
  if (v2) {
    return (
      <Styles.InputWrapper>
        <InstantSearch
          searchClient={searchClient}
          indexName={ZENDESK.indexName}
        >
          <AutoComplete v2 placeholder={v2.placeholder} />
          <Configure hitsPerPage={5} />
          <Index indexName={ZENDESK.indexName} />
        </InstantSearch>
        <Styles.SearchIconWrapper>
          <IconSearch color="primaryBlue" />
        </Styles.SearchIconWrapper>
      </Styles.InputWrapper>
    );
  }

  return (
    <Card className={classes.container}>
      <Typography className={classes.searchTitle}>{ t('help.search') }</Typography>
      <InstantSearch
        searchClient={searchClient}
        indexName={ZENDESK.indexName}
      >
        <AutoComplete />
        <Configure hitsPerPage={5} />
        <Index indexName={ZENDESK.indexName} />
      </InstantSearch>
    </Card>
  );
};

class Example extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { currentRefinement } = this.props;
    this.state = {
      value: currentRefinement,
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { refine } = this.props;
    refine(value);
  };

  onSuggestionsClearRequested = () => {
    const { refine } = this.props;
    return refine();
  }

  getSuggestionValue = hit => hit.title;

  renderSectionTitle = section => section.index;

  getSectionSuggestions = section => section.hits;

  renderSuggestion = hit => (<Hits hit={hit} />);

  renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }) => {
    const { t, classes } = this.props;

    return (
      <div {...containerProps}>
        {children}
        {
          <div className={classes.linkWrapper}>
            <LinkButton to={`${SUPPORT_URL}/search?utf8=✓&query=${query}`} target="blank" className={classes.link}>
              <PrimaryButton>{t('help.seeMoreResults')}</PrimaryButton>
            </LinkButton>
          </div>
        }
      </div>
    );
  };

  render() {
    const {
      hits,
      t,
      classes,
      v2,
      placeholder,
    } = this.props;
    const { value } = this.state;

    const renderInputComponent = inputProps => (
      <TextField
        fullWidth
        variant="outlined"
        {...inputProps}
        ref={null}
        inputRef={inputProps.ref}
        className={classes.input}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
      />
    );

    const inputProps = {
      label: v2 ? null : `${t('help.guidePlaceholder')}`,
      placeholder: placeholder || null,
      onChange: this.onChange,
      value,
    };

    return (
      <Autosuggest
        renderInputComponent={renderInputComponent}
        suggestions={hits}
        multiSection={false}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        renderSuggestionsWithoutFocus
      />
    );
  }
}

const AutoComplete = withStyles(styles)(withI18n()(connectAutoComplete(Example)));

export default withI18n()(withStyles(styles)(Search));
