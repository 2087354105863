import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  ${({ theme, isLoading }) => css`
    font-family: ${theme.v2.font.family.primary};
    color: ${theme.v2.colors.neutral.lowPure};

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: ${isLoading && 'center'};
      display: ${isLoading && 'flex'};
      height: 100%;

      //centralizar loading apenas e corrigir o fetch
    }
  `}
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 313px;
  justify-content: center;
  width: 100%;
`;

export const LoadingText = styled.div`
  text-align: center;
  width: 271px;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 45px 32px 33px
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
  `}
`;

export const Description = styled.div`
  display: flex;
  gap: 12px
`;

export const IconWrapper = styled.div`
  height: 27px;
  width: 27px;
`;

export const DescriptionMessage = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
      color: ${theme.v2.colors.brand.primaryMedium}
    }

    b {
      font-weight: ${theme.v2.font.weights.medium};
    }

    a {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const ActionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    justify-content: center;
    padding-top: 9px;
    gap: 6px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column;
      align-items: center;
      gap: 16px;

      button {
        width: fit-content;
      }

      button:nth-child(2) {
        order: -1;
      }
    }
  `}
`;
