import { SITE_HG, COUNTRY } from '@/config';

const HOSTING_URL_BY_BRAND = {
  br: `${SITE_HG}/hospedagem-de-sites`,
  cl: `${SITE_HG}/web-hosting`,
  co: `${SITE_HG}/web-hosting`,
  mx: `${SITE_HG}/web-hosting`,
};

export const HOSTING_URL = HOSTING_URL_BY_BRAND[COUNTRY];
