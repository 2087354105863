import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withI18n, Trans } from 'react-i18next';
import {
  Dialog, IconButton, DialogTitle, DialogContent, DialogActions, withStyles,
} from '@material-ui/core';
import {
  Close, Lock, Check, Lens,
} from '@material-ui/icons';
import PasswordField from '@/components/Fields/PasswordField';
import LinkButton from '@/components/Buttons/LinkButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { passwordScore } from '@/utils/Validators/passwordScore';
import { PASSWORD_GENERATOR_URL } from '@/config/urls/passwordUrls';
import { alterEmailPassword } from '@/redux/actions/emails';
import { SUCCESS_ALTER_PASSWORD_EMAILS } from '@/redux/actions/actionsTypes';
import styles from './styles';
import { commonActions } from '@/redux/modules';
import useLocale from '@/hooks/useLocale';


const AlterEmailPasswordModal = ({
  classes,
  openModal,
  handleCloseModal,
  hostingId,
  emailAddress,
  t,
}) => {
  const dispatch = useDispatch();
  const { alerts: alertsLocale } = useLocale();
  const loadingAlterPassword = useSelector(state => state.emails.loadingAlterPassword);
  const isMobile = window.innerWidth <= 490;

  const [strength, setStrength] = useState('');
  const [password, setPassword] = useState('');
  const [passwordReady, setPasswordReady] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [passwordFlagError, setPasswordFlagError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordReady, setConfirmPasswordReady] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const [confirmPasswordFlagError, setConfirmPasswordFlagError] = useState(false);
  const [checklistVisible, setChecklistVisible] = useState(true);
  const [upperAndLower, setUpperAndLower] = useState(false);
  const [number, setNumer] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [eightCharacters, setEightCharacters] = useState(false);
  const [equalInputValue, setEqualInputValue] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);

  const handleValidations = (input) => {
    if (input === 'password') {
      if (password === '') {
        setPasswordError(true);
        setPasswordErrorMsg(t('myData.changePassword.errorEmptyField'));
        setPasswordFlagError(true);
        setPasswordReady(false);
      } else if (strength < 50) {
        setPasswordError(true);
        setPasswordErrorMsg('');
        setPasswordReady(false);
      } else {
        setPasswordError(false);
        setPasswordErrorMsg('');
        setPasswordReady(true);
      }
    } else if (input === 'confirmPassword') {
      if (confirmPassword === '') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMsg(t('myData.changePassword.errorEmptyField'));
        setConfirmPasswordFlagError(true);
      } else if (confirmPassword !== password) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMsg(t('myData.changePassword.errorConfirmNew'));
        setConfirmPasswordFlagError(true);
      } else {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorMsg('');
        setConfirmPasswordReady(true);
      }
    }
  };

  const handleActionsVisible = () => {
    if (!!password || !!confirmPassword) {
      setActionsVisible(true);
    } else {
      setActionsVisible(false);
    }
  };

  const handleChange = (event, input) => {
    if (input === 'password') {
      const strength = passwordScore(event.target.value.trim());
      setPassword(event.target.value);
      setStrength(strength.strength);
    } else if (input === 'confirmPassword') {
      setConfirmPassword(event.target.value);
    }
  };

  const handleChecklist = (input) => {
    // Verify password change requirements are ready and hidden checklist
    if (passwordReady && password === confirmPassword) {
      setChecklistVisible(false);
    } else {
      setChecklistVisible(true);
    }

    // Handle regex clean password
    const cleanSymbols = password.replace(/\W/g, '');
    const numbers = cleanSymbols.replace(/[A-Z-a-z]/g, '');
    const symbols = password.replace(/\w/g, '');

    // Verify new password
    if (input === 'password') {
      // Check if have uppers and lowercase letters
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        setUpperAndLower(true);
      } else {
        setUpperAndLower(false);
      }
      // Check if have number
      if (numbers.length > 0) {
        setNumer(true);
      } else {
        setNumer(false);
      }
      // Check if have symbol
      if (symbols.length > 0) {
        setSymbol(true);
      } else {
        setSymbol(false);
      }
      // Check if have 8 characters
      if (password.length >= 8) {
        setEightCharacters(true);
      } else {
        setEightCharacters(false);
      }
    }
    // Verify confirm password is equal new password
    if (input === 'confirmPassword') {
      if (password === confirmPassword) {
        setEqualInputValue(true);
      } else {
        setEqualInputValue(false);
      }
    }
  };

  const handleKeyUp = (event, input) => {
    if (passwordFlagError) handleValidations(input);
    if (confirmPasswordFlagError) handleValidations(input);

    if (password !== '' && confirmPassword !== '') {
      if (password.length > 7) {
        if (strength > 49) {
          if (password === confirmPassword) {
            setSaveButtonDisable(false);
          } else {
            setSaveButtonDisable(true);
          }
        } else {
          setSaveButtonDisable(true);
        }
      } else {
        setSaveButtonDisable(true);
      }
    } else {
      setSaveButtonDisable(true);
    }

    handleChecklist(input);
    handleActionsVisible();
  };

  const handleBlur = (event, input) => {
    handleValidations(input);
  };

  const resetStates = () => {
    setStrength('');
    setPassword('');
    setPasswordReady(false);
    setPasswordError(false);
    setPasswordErrorMsg('');
    setPasswordFlagError(false);
    setConfirmPassword('');
    setConfirmPasswordReady(false);
    setConfirmPasswordError(false);
    setConfirmPasswordErrorMsg('');
    setConfirmPasswordFlagError(false);
    setChecklistVisible(true);
    setUpperAndLower(false);
    setNumer(false);
    setSymbol(false);
    setEightCharacters(false);
    setEqualInputValue(false);
    setActionsVisible(false);
    setSaveButtonDisable(true);
  };

  const handleSave = () => {
    if (loadingAlterPassword) return;

    if (passwordReady && confirmPasswordReady && equalInputValue) {
      const alterPasswordData = {
        emailAddress,
        data: {
          emailAddress,
          password,
          passwordConfirmation: confirmPassword,
        },
      };
      dispatch(alterEmailPassword(hostingId, alterPasswordData)).then((res) => {
        if (res.type === SUCCESS_ALTER_PASSWORD_EMAILS) {
          handleCloseModal('changePassword');
          dispatch(commonActions.notifications.set({
            label: alertsLocale.emailPassword.createSuccess,
            type: 'success',
          }));
          resetStates();
        } else {
          dispatch(commonActions.notifications.set({
            label: alertsLocale.emailPassword.weakPassword,
            type: 'error',
          }));
        }
      });
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-delete-account"
      aria-describedby="alert-dialog-confirmation-to-delete-email-account"
      classes={{
        container: classes.modalContainer,
        paper: classes.modalPaper,
      }}
      fullScreen={isMobile}
    >
      <IconButton onClick={() => handleCloseModal('changePassword')} className={classes.closeIconButton}>
        <Close />
      </IconButton>
      <DialogTitle
        id="alert-dialog-delete-email-account"
        classes={{
          root: classes.dialogTitle,
        }}
        data-id="email-change-password-form"
      >
        {t('emails.alterEmailPassword')}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.wrapperContentModal}>
          <div className={classes.passwordFieldWrapper}>
            <PasswordField
              emailName
              value={password}
              type="password"
              strength
              autoComplete="current-password"
              name="password"
              label={t('myData.changePassword.newPassword')}
              required
              variant="outlined"
              margin="normal"
              spellCheck="false"
              fullWidth
              onKeyUp={event => handleKeyUp(event, 'password')}
              onChange={event => handleChange(event, 'password')}
              onBlur={event => handleBlur(event, 'password')}
              error={passwordError}
              helperText={passwordErrorMsg}
              dataId="email-password"
            />
          </div>
          <div className={classes.passwordFieldWrapper}>
            <PasswordField
              emailName
              value={confirmPassword}
              type="password"
              strength
              autoComplete="confirm-password"
              name="password"
              label={t('emails.confirmPassword')}
              required
              variant="outlined"
              margin="normal"
              spellCheck="false"
              fullWidth
              onKeyUp={event => handleKeyUp(event, 'confirmPassword')}
              onChange={event => handleChange(event, 'confirmPassword')}
              onBlur={event => handleBlur(event, 'confirmPassword')}
              error={confirmPasswordError}
              helperText={confirmPasswordErrorMsg}
              dataId="email-confirm-password"
            />
          </div>
          {checklistVisible && (
            <div className={classes.checklistContainer}>
              <p className={classes.checklistTitle}>
                <Lock className={classes.lockIcon} />
                {t('emails.password')}
              </p>
              <ul className={classes.checklist}>
                <li className={`${upperAndLower ? classes.colorTrue : classes.colorFalse}`}>
                  {upperAndLower ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}
                  {t('myData.changePassword.checklist.upperAndLowercase')}
                </li>
                <li className={`${number ? classes.colorTrue : classes.colorFalse}`}>
                  {number ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}
                  {t('myData.changePassword.checklist.number')}
                </li>
                <li className={`${symbol ? classes.colorTrue : classes.colorFalse}`}>
                  {symbol ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}
                  {t('myData.changePassword.checklist.symbol')}
                </li>
                <li className={`${eightCharacters ? classes.colorTrue : classes.colorFalse}`}>
                  {eightCharacters ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}
                  {t('myData.changePassword.checklist.eightCharacters')}
                </li>
                <li className={`${equalInputValue ? classes.colorTrue : classes.colorFalse}`}>
                  {equalInputValue ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}
                  {t('myData.changePassword.checklist.sameInBothFields')}
                </li>
              </ul>
            </div>
          )}
          <div className={classes.generatorWrapper}>
            <span className={classes.generatorText}>
              <Trans i18nKey="myData.changePassword.generatorText">
                0
                <LinkButton to={PASSWORD_GENERATOR_URL} className={classes.generatorLink} target="_blank">1</LinkButton>
                2
              </Trans>
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions classes={{
        root: classes.dialogActionsContent,
      }}
      >
        {actionsVisible && (
          <>
            <OutlineButton
              onClick={() => handleCloseModal('changePassword')}
              color="primary"
            >
              {t('cancel')}
            </OutlineButton>
            <PrimaryButton
              onClick={() => handleSave()}
              color="primary"
              autoFocus
              onLoading={loadingAlterPassword}
              disabled={saveButtonDisable}
              className={classes.save}
              data-id="button-submit-email-change-password"
            >
              {t('emails.alter')}
            </PrimaryButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

AlterEmailPasswordModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  hostingId: PropTypes.number.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(withI18n()(AlterEmailPasswordModal));
