import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styles from './styles';

const OtherBlock = ({
  classes,
}) => {
  const native = useSelector(state => state.dnsDetails.details.nameserver.native);
  return (
    <div className={classes.expandPanel}>
      <ul className={classes.list}>
        <li className={classes.listLabel}>Master</li>
        <li className={classes.listItem}>{native.master}</li>
        <li className={classes.listLabel}>Slave 1</li>
        <li className={classes.listItem}>{native.slave}</li>
      </ul>
    </div>
  );
};

export default withI18n()(withStyles(styles)((OtherBlock)));
