import React, { useContext } from 'react';
import * as Styles from './RetentionSubscriptionHandler.styles';
import { useHistory } from 'react-router';
import { Button, Header } from '@/pages/common';
import useLocale from '@/hooks/useLocale';
import SnappyRetention from '@/media/subscriptions/retention/snappyRetention.svg';
import { ThemeContext } from 'styled-components';
import { IconCheck } from '@/icons';
import { SimpleConfirmation } from '../../components/SimpleConfirmation';
import { billingAnalytics } from '@/analytics';
import { Loader } from '@/pages/common/v1/Loader';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_IMMEDIATE,
  XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_RENEWAL,
  XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND,
  XRAY_RETENTION_OFFER_VERSION_PAGE_AB_TEST,
} from '@/config/GrowthBook/constants';
import { useSelector } from 'react-redux';
import { CancelOptions } from '../../components/manageSubscription/SubscriptionActiveRenewalModal/SubscriptionActiveRenewalModal.types';
import { PAGE_RETENTION_VERSIONS } from '@/enums/billing/billing.enum';

const RetentionSubscriptionHandler = ({
  currentItem = {},
  enableBenefitPage = false,
  enabledUpgradeBenefitPage = false,
  isRenewCanceling = false,
  retentionType,
  retentionTypeRouteParam,
  subscriptionId,
  subscriptionType,
  openPreChatCancelFlow,
}) => {
  const { loading } = useSelector(state => state.common);
  const history = useHistory();
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { manageSubscriptionRetention } = billingLocale;
  const { subscriptions: retentionAnalytics } = billingAnalytics;
  const allRetentionBenefits = manageSubscriptionRetention.retentionBenefits.benefits.map((benefit, index) => ({
    description: benefit,
    id: index,
  }));
  const hasRetentionInfos = !!currentItem.retentionInfos;
  const retentionInfos = hasRetentionInfos ? currentItem.retentionInfos : {};
  const isMonthlyCycle = retentionInfos.billingCycle === 'Monthly';
  const theme = useContext(ThemeContext);
  const enabledDiscountRetentionNewFlux = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND);
  const enabledDiscountRetentionCancelImmediate = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_IMMEDIATE);
  const enabledDiscountRetentionCancelRenewal = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_CANCEL_RENEWAL);
  const retentionPageVersion = useFeatureIsOn(XRAY_RETENTION_OFFER_VERSION_PAGE_AB_TEST) ? PAGE_RETENTION_VERSIONS.NEW : PAGE_RETENTION_VERSIONS.OLD;
  const hasCurrentItem = () => JSON.stringify(currentItem) !== '{}';
  const isDomain = () => currentItem.type === 'domain';
  const getHeaderProps = () => {
    const defaultArgs = {
      testId: 'retention-handler-header',
      title: '...',
      description: '',
    };

    if (!hasCurrentItem()) {
      return defaultArgs;
    }

    const {
      productName,
      domain,
    } = currentItem;

    const getPageTitle = () => {
      if (isDomain()) {
        return `${billingLocale.domainLabel} -`;
      }

      return domain ? `${productName} -` : productName;
    };

    return {
      ...defaultArgs,
      title: getPageTitle(),
      description: domain || '',
    };
  };

  const handleMailButtonClick = () => {
    retentionAnalytics.retentionAccessEmails();
    openPreChatCancelFlow && openPreChatCancelFlow();
    history.push(routesLocale.emailsList);
  };

  const subscription = useSelector(state => state.billing.subscription);

  const getRedirectPath = () => {
    const isPageToUpgrade = enabledUpgradeBenefitPage && isMonthlyCycle;
    const isPageToRenew = enableBenefitPage && !isMonthlyCycle;
    const isAbleToDiscount = enabledDiscountRetentionNewFlux && subscription && subscription.reactivation && subscription.reactivation[0] !== undefined;

    if ((isPageToUpgrade || isPageToRenew) && isAbleToDiscount) {
      if (retentionPageVersion === PAGE_RETENTION_VERSIONS.OLD) {
        return `${routesLocale.manageSubscription}${routesLocale.retentionBenefit}/${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
      }
      if (retentionPageVersion === PAGE_RETENTION_VERSIONS.NEW) {
        if (enabledDiscountRetentionCancelImmediate && retentionType === CancelOptions.CANCEL_RENEWAL) {
          return `${routesLocale.manageSubscription}${routesLocale.retentionSubscriptionOffer}/${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
        } if (enabledDiscountRetentionCancelRenewal && retentionType === CancelOptions.CANCEL_IMMEDIATE) {
          return `${routesLocale.manageSubscription}${routesLocale.retentionSubscriptionOffer}/${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
        }
      }
      return `${routesLocale.manageSubscription}${routesLocale.retentionBenefit}/${retentionTypeRouteParam}/${subscriptionId}/${subscriptionType}`;
    }

    const cancelRenewalPath = `${routesLocale.manageSubscription}${routesLocale.cancelRenewal}/${subscriptionId}/${subscriptionType}`;
    const cancelImmediatePath = `${routesLocale.manageSubscription}${routesLocale.cancelSubscription}/${subscriptionId}/${subscriptionType}`;
    const cancelPaths = {
      CANCEL_RENEWAL: cancelRenewalPath,
      CANCEL_IMMEDIATE: cancelImmediatePath,
    };
    return cancelPaths[retentionType];
  };

  const handleCancelButtonClick = () => {
    retentionAnalytics.retentionCancelSignature();
    history.push(getRedirectPath());
  };

  return (
    <Styles.Wrapper data-testid="retention-subscription-handler">
      <Header {...getHeaderProps()} />
      <Styles.ContentWrapper>
        {loading && <Loader />}
        {!loading && (
          <>
            <Styles.RetentionTitle>{manageSubscriptionRetention.retentionTitle}</Styles.RetentionTitle>
            <Styles.RetentionMailText>
              {manageSubscriptionRetention.retentionTextDescription}
            </Styles.RetentionMailText>
            <Styles.BenefitsContent>
              <div>
                <Styles.BenefitTitle>{manageSubscriptionRetention.retentionBenefits.title}</Styles.BenefitTitle>
                {allRetentionBenefits.map(benefit => (
                  <Styles.BenefitTopic key={benefit.id}>
                    <IconCheck color={theme.v1.color.action.primary} size={18} />
                    {benefit.description}
                  </Styles.BenefitTopic>
                ))}
              </div>
              <Styles.SnappyImg src={SnappyRetention} alt="HostGator" loading="lazy" />
            </Styles.BenefitsContent>
            <Styles.CenterButtonContent>
              <Button
                label={manageSubscriptionRetention.retentionMailButton}
                variant="primary"
                onClick={handleMailButtonClick}
                testId="retention-email-redirect-button"
              />
            </Styles.CenterButtonContent>
            <Styles.ConfirmationContent>
              <SimpleConfirmation
                attentionLabel={manageSubscriptionRetention.retentionAttention.title}
                text={manageSubscriptionRetention.retentionAttention.text}
              />
            </Styles.ConfirmationContent>
            <Styles.FooterContent>
              <Button
                label={isRenewCanceling ? manageSubscriptionRetention.retentionCancelRenewButton : manageSubscriptionRetention.retentionCancelButton}
                onClick={handleCancelButtonClick}
                testId="retention-cancel-button"
              />
            </Styles.FooterContent>
          </>
        )}
      </Styles.ContentWrapper>
    </Styles.Wrapper>
  );
};

export default RetentionSubscriptionHandler;
