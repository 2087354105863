import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
    padding: 32px;
`;

export const ModalTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 48px 0px;
  `}
`;
