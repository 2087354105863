const styles = theme => ({
  filter: {
    background: theme.color.white,
    minHeight: '60px',
    position: 'relative',
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    padding: '14px 14px 14px 24px',
    borderRadius: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1060)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: 0.01,
    color: theme.color.tundora,
    [theme.breakpoints.down(1060)]: {
      marginBottom: 10,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1060)]: {
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  formControl: {
    minWidth: 200,
    margin: 0,
    [theme.breakpoints.down(600)]: {
      minWidth: 'auto',
    },
  },
  input: {
    minWidth: 200,
    margin: '0px 14px 0 0',
    [theme.breakpoints.down(600)]: {
      minWidth: 'auto',
    },
    [theme.breakpoints.up(1115)]: {
      minWidth: 300,
    },
    [theme.breakpoints.up(1220)]: {
      minWidth: 400,
    },
  },
  label: {
    fontSize: 14,
  },
  selectFieldLabel: {
    fontSize: 14,
  },
  closeIcon: {
    color: theme.color.tundora,
    fontSize: 18,
    cursor: 'pointer',
    animation: 'isTyping 0.05s linear',
  },
  searchIcon: {
    color: theme.color.grayContour,
    fontSize: 18,
  },
  '@keyframes isTyping': {
    from: {
      transform: 'rotate(20deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
});

export default styles;
