import React from 'react';
import { faqTagManager } from '@/utils/ThirdParties/tagManager';
import * as Styles from './FaqDefault.styles';
import { locale } from '@/utils/locale';
import { FaqCard } from 'gatorcomponents';
import Loader from '@/components/Layout/Loader';

const FaqDefault = ({
  questions = [{
    id: '01',
    link: '#',
    question: 'sample',
  }],
  link = '#',
  loading = false,
  width = 'half',
  buttonLabel,
  withoutButton = false,
}) => {
  const getFaqCardProps = () => {
    const defaultProps = {
      title: locale('help.frequentlyQuestions'),
      seeMore: buttonLabel || locale('morev2'),
      seeMoreLink: !withoutButton && link,
      items: questions,
    };

    const handleOnClick = ({ question }) => {
      faqTagManager(question);
    };

    return {
      ...defaultProps,
      onClick: handleOnClick,
    };
  };

  return (
    <Styles.FaqV2Wrapper width={width}>
      {loading
        ? <Loader size={18} />
        : (
          <FaqCard
            {...getFaqCardProps()}
          />
        )
      }
    </Styles.FaqV2Wrapper>
  );
};

export default FaqDefault;
