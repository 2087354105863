import React from 'react';
import * as Style from './Card.styles';
import iconHospedagem from '@/media/siteBuilder/icon-hospedagem.svg';

const Card = ({
  testId = 'card',
  children,
  icon = iconHospedagem,
  title = '',
  selected = false,
  asButton = false,
  iconAction,
  action,
  onClick,
  cursorPointer,
}) => (

  <Style.Card data-testid={testId} selected={selected} asButton={asButton} onClick={onClick} cursorPointer={cursorPointer} iconAction={iconAction}>
    <Style.CardContainer>
      <Style.Title>
        {icon
      && <Style.Icon src={icon} alt="card-icon" />
      }
        {title}
      </Style.Title>
      {children
    && (
      <Style.Content>
        {children}
      </Style.Content>
    )
  }
    </Style.CardContainer>
    {(iconAction && action)
      && (
      <Style.IconAction onClick={action}>
        {iconAction}
      </Style.IconAction>
      )
    }
  </Style.Card>

);

export default Card;
