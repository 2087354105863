import React from 'react';
import * as Styles from './Mark.styles';

const FeaturedLabel = ({
  onClick,
  testId = 'mark-label',
  text = 'Title Sample',
  variant = 'default',
  textColor = '',
  color = '',
  weight = 'regular',

}) => {
  const handleOnClick = () => (
    onClick && onClick()
  );
  return (
    <Styles.Mark
      color={color}
      data-testid={testId}
      onClick={handleOnClick()}
      textColor={textColor}
      title={text}
      variant={variant}
      weight={weight}
    >
      {text}
    </Styles.Mark>
  );
};

export default FeaturedLabel;
