import React from 'react';
import * as Style from './smallTriangles.style';

const SmallTriangles = ({
  color = '#4480c5',
  size = '45',
  testId = 'smallTriangles',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 45.994 45.777">
      <path id="Caminho_238862" data-name="Caminho 238862" d="M22.856,0H0V22.856Z" transform="translate(23.138 22.922)" fill={color} />
      <path id="Caminho_238863" data-name="Caminho 238863" d="M22.856,0H0V22.856Z" transform="translate(23.138)" fill={color} />
      <path id="Caminho_238864" data-name="Caminho 238864" d="M22.856,0H0V22.856Z" transform="translate(0 22.922)" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default SmallTriangles;
