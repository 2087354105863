export const BOLETO = 'boleto';
export const CREDIT_CARD = 'braspag_credit_1x';
export const PAYPAL = 'paypal';

export const PARC_2X = 'cieloparc2';
export const PARC_3X = 'cieloparc3';
export const PARC_4X = 'cieloparc4';
export const PARC_5X = 'cieloparc5';
export const PARC_6X = 'cieloparc6';

export const BRASPAG_CREDIT = 'braspag_credit';
export const BRASPAG_PARC_1X = 'braspag_credit_1x';
export const BRASPAG_PARC_2X = 'braspag_credit_2x';
export const BRASPAG_PARC_3X = 'braspag_credit_3x';
export const BRASPAG_PARC_4X = 'braspag_credit_4x';
export const BRASPAG_PARC_5X = 'braspag_credit_5x';
export const BRASPAG_PARC_6X = 'braspag_credit_6x';
export const BRASPAG_PARC_7X = 'braspag_credit_7x';
export const BRASPAG_PARC_8X = 'braspag_credit_8x';
export const BRASPAG_PARC_9X = 'braspag_credit_9x';
export const BRASPAG_PARC_10X = 'braspag_credit_10x';
export const BRASPAG_PARC_11X = 'braspag_credit_11x';
export const BRASPAG_PARC_12X = 'braspag_credit_12x';
export const GATORPAY_BOLETO = 'gp_boleto';
export const GATORPAY_PAYPAL = 'gp_paypal';

/* LATAM PAYMENT METHODS */
/* CREDIT CARD ES */
export const CREDIT_CARD_ES = 'dlocal';
/* BOLETO CHILE | COLOMBIA */
export const BOLETO_CL_CO = 'dlocalcash';
/* BOLETO MEXICO */
export const BOLETO_MX = 'payu_boleto';

export const PIX = 'gp_pix';

export const LIST_CREDIT_CARD_METHODS = [CREDIT_CARD, CREDIT_CARD_ES, PARC_2X, PARC_3X,
  PARC_4X, PARC_5X, PARC_6X, BRASPAG_PARC_1X, BRASPAG_PARC_2X, BRASPAG_PARC_3X,
  BRASPAG_PARC_4X, BRASPAG_PARC_5X, BRASPAG_PARC_6X, BRASPAG_PARC_7X, BRASPAG_PARC_8X,
  BRASPAG_PARC_9X, BRASPAG_PARC_10X, BRASPAG_PARC_11X, BRASPAG_PARC_12X];

export const LIST_BOLETO_METHODS = [BOLETO_CL_CO, BOLETO_MX, BOLETO, GATORPAY_BOLETO];

export const LIST_PAYPAL_METHODS = [GATORPAY_PAYPAL, PAYPAL];
