import securityBR from './security.br';
import securityCL from './security.cl';
import securityCO from './security.co';
import securityMX from './security.mx';

const locales = {
  BR: securityBR,
  CL: securityCL,
  CO: securityCO,
  MX: securityMX,
};

export default locales;
