import React, { useState } from 'react';
import DomainDetailPage from './DomainDetailPage';
import { loadDomainDetail } from '@/redux/actions/domains';
import { loadSubdomains } from '@/redux/actions/subdomains';
import { useParams } from 'react-router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_DOMAIN_MANAGEMENT_V2 } from '@/config/GrowthBook/constants';
import { ManageDomainHandler } from '../ManageDomainHandler/ManageDomainHandler';

const DomainDetailPageWrapper = () => {
  const domainManagementV2 = useFeatureIsOn(CHARLIE_DOMAIN_MANAGEMENT_V2);
  const [canMapEppKeyButtons, setCanMapEppKeyButtons] = useState(true);
  const { id } = useParams();

  if (domainManagementV2) {
    return <ManageDomainHandler />;
  }

  return (
    <DomainDetailPage
      loadDomainDetail={loadDomainDetail}
      loadSubdomains={loadSubdomains}
      domainId={id}
      canMapEppKeyButtons={canMapEppKeyButtons}
      setCanMapEppKeyButtons={setCanMapEppKeyButtons}
    />
  );
};

export default DomainDetailPageWrapper;
