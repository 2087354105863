import styled, { css } from 'styled-components';

const defaultWrapperWidth = '540px';

export const Form = styled.form`
${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
`}
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 24px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    display: block;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;
    max-width: ${defaultWrapperWidth};
    color: ${theme.v2.colors.neutral.lowPure};

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }

    li {
      margin-bottom: 8px;
    }

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  button {
    max-height: 35px;
  }
`;
