const styles = theme => ({
  content: {
    borderRadius: 2,
    color: theme.color.white,
    display: 'inline-block',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: 'auto',
    marginTop: 4,
    padding: 6,
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
  active: {
    backgroundColor: theme.color.oceanGreen,
  },
  pending: {
    backgroundColor: theme.color.brightSun,
  },
  pendingTransfer: {
    backgroundColor: theme.color.coral,
  },
  expired: {
    backgroundColor: theme.color.valencia,
  },
  registerError: {
    backgroundColor: theme.color.valencia,
  },
  cancelled: {
    backgroundColor: theme.color.geyser,
  },
  nextDueDate: {
    backgroundColor: theme.color.brightSun,
  },
  dueDate: {
    backgroundColor: theme.color.valencia,
  },
});

export default styles;
