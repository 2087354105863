import styled, { css } from 'styled-components';
import TitanDarkDeco from '@/media/titan/titan_dark.svg';
import TitanLightDeco from '@/media/titan/titan_light.svg';
import TitanDarkLogo from '@/media/titan/titan_dark_logo.svg';
import TitanLightLogo from '@/media/titan/titan_light_logo.svg';

export const TitanUpgradeBottomAdvertisementWrapper = styled.div`
  ${({ variant, theme }) => css`
    background-color: ${variant === 'dark' ? theme.palette.grey.ultradark : theme.palette.white.light};
    background-image: url('${variant === 'dark' ? TitanDarkLogo : TitanLightLogo}');
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    color: ${variant === 'dark' ? theme.palette.grey.ultralight : theme.palette.blue.dark};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: ${theme.font.family.secondary};
    gap: 24px;
    justify-content: space-between;
    padding: 0px;
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ variant }) => css`
    align-items: flex-start;
    background-image: url('${variant === 'dark' ? TitanDarkDeco : TitanLightDeco}');
    background-origin: content-box;
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 24px 0px 24px;
  `}
`;

export const FooterWrapper = styled.div`
${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 0px 24px 56px 24px;

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 0px 24px 30px 24px;
    }
  `}
`;

export const Title = styled.h5`
  ${({ theme }) => css`
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.sizes.xxxxxxl};
    margin: 0;
    padding: 0;
    padding-top: 6px;
    text-align: left;
    text-transform: uppercase;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxxl};
    font-weight: ${theme.font.weights.medium};
    margin: 0;
    max-width: 283px;
    padding: 0;
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
      max-width: 430px;
    }
    
  `}
`;
