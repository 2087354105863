import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-color:${theme.v2.colors.feedback.canceledPure};
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    display: flex;
    min-width: 237px;

    fieldset {
      border: 0px !important;
    }

    input {
      padding: 8px
    }
  `}
`;

export const SearchIconWrapper = styled.div``;
