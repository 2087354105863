import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    margin: 0 24px;
  `};
`;

export const Container = styled.div`
  display: flex;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 248px;
  width: 100%;
`;

export const Section = styled.section`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 48px 0 48px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      margin: 8px 0 0 0;
      padding: 24px 20px;
    }
  `}
`;

export const SitelockLogo = styled.img`
  ${({ theme }) => css`
    height: 38px;
    user-select: none;
    -webkit-user-drag: none;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      height: 32px;
    }
  `};
`;

export const SubscriptTag = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    background: ${theme.v2.colors.feedback.attentionLight};
    border-radius: 20px;
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.bold};
    text-align: center;
    padding: 2px 10px;
    user-select: none;
    -webkit-user-drag: none;
  `};
`;

export const LogoWrapper = styled(Container)`
  align-items: center;
  gap: 16px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      font-size: ${theme.v2.font.sizes.sm};
      text-align: center;
    }
  `};
`;

export const PurchaseInfoWrapper = styled(Container)`
  ${({ theme }) => css`
    justify-content: space-between;
    gap: 8px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      flex-direction: column;
      gap: 24px;
    }
  `};
`;

export const DomainLabel = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    color: ${theme.v2.colors.neutral.lowPure};

    em {
      color: ${theme.v2.colors.brand.primaryDark};
      font-size: ${theme.v2.font.sizes.xs};
      font-style: normal;
      font-weight: ${theme.v2.font.weights.medium};
    }

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width: 100%;
      text-align: center;
    }
  `};
`;

export const PaymentCycleWrapper = styled(Container)`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 24px;
    justify-content: center;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
      gap: 16px;
      text-align: center;
    }
  `};
`;

export const PaymentCycleOptionsWrapper = styled(Container)`
  ${({ theme }) => css`
    gap: 8px;
    white-space: nowrap;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      justify-content: center;
      width: 100%;
    }
  `};
`;

export const PaymentCycleOptions = styled(Container)`
  ${({ theme }) => css`
    gap: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      width: 100%;
    }
  `};
`;

export const PaymentCycleFrameWrapper = styled(Container)`
  ${({ theme }) => css`
    gap: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      justify-content: space-between;
      padding: 0 24px;
      width: 100%;
    }
  `};
`;

export const RadioLabel = styled.label`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};

    label {
      width: 19px;
      height: 19px;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      width: 104px;
    }
  `};
`;

export const CardsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: flex-start;

    @media (max-width:${theme.v2.breakpoints.md}) {
      align-items: center;
      flex-direction: column;
      gap: 32px;
    }
  `}
`;

export const Card = styled.div`
  ${({ theme, isFree = false }) => css`
    border-radius: 8px;
    border: ${!isFree && `1px solid ${theme.v2.colors.feedback.canceledPure}`};
    background: ${isFree ? theme.v2.colors.feedback.canceledLight : theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    width: ${isFree ? '75%' : '100%'};

    @media (max-width:${theme.v2.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const FeatureWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const PlanInfoWrapper = styled.div`
  ${({ isFree = false }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: ${isFree ? '0 0 24px 0' : '24px 0'};
  `};
`;

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const CardSubtitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.sizes.bold};
    margin: 4px 0;
  `};
`;

export const PlanValueTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;

export const PlanValueLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    user-select: none;
    -webkit-user-drag: none;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      button {
        padding: 6px 24px;
      }
    }
  `};
`;

export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 120px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width: auto;
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: 120px;
    }
  `}
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  gap: 4px;
`;

export const IconWrapper = styled.div`
`;

export const ListText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};

    strong: {
      font-weight: ${theme.v2.font.weights.bold};
    }
  `};
`;

export const BottomDetail = styled.div`
  ${({ theme }) => css`
    height: 20px;
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      display: none;
    }
  `};
`;

export const SnappyDetail = styled.img`
  ${({ theme }) => css`
    margin-left: 20px;
    margin-top: auto;
    max-height: 64px;
    position: absolute;
    bottom: 0px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const SectionFootWrapper = styled.div`
  margin: 8px 0;
  justify-content: center;
  display: flex;
`;

export const SignNowSpan = styled.span`
  text-align: center;

  button {
    font-size: 16px;
  }
`;
