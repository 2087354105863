import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Modal } from '@/pages/common/Modal';
import { IconCheck } from '@/icons';
import * as Styles from './ReactivationConfirmationModal.styles';
import { Button } from '@/pages/common/v1';

const ReactivationConfirmationModal = ({
  displayModal = true,
  onClose,
  testId = 'reactivation-confirmation-modal',
  title = 'Renovação ativada',
  clouseLabel = 'Fechar',
  description = 'A renovação automática da assinatura foi ativada com sucesso!',
}) => {
  const theme = useContext(ThemeContext);

  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    displayModal && (
      <Modal maxWidth={550} onClose={handleOnClose} testId={testId} variant="regular">
        <Styles.IconWrapper>
          <IconCheck color={theme.v2.colors.white.primary} size={40} />
        </Styles.IconWrapper>

        <Styles.ContentWrapper>
          <Styles.ModalTitle data-testid={`${testId}-title`}>{title}</Styles.ModalTitle>
          <Styles.ModalText data-testid={`${testId}-description`}>{description}</Styles.ModalText>
        </Styles.ContentWrapper>

        <Styles.FooterWrapper>
          <Button align="center" label={clouseLabel} onClick={handleOnClose} testId={`${testId}-close-button`} />
        </Styles.FooterWrapper>
      </Modal>
    )
  );
};

export default ReactivationConfirmationModal;
