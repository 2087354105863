import React, { useEffect, useState } from 'react';
import {
  Button, IconDanger, Link, IconButton, IconClock, IconChevron, Select, IconLoading,
} from 'gatorcomponents';
import * as Styles from './ModalDomainNotConfigured.styles';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { Modal } from '@/pages/common';
import { COUNTRY } from '@/config';
import {
  COLOMBIAHOSTING_HOMEPAGE, GODADDY_HOMEPAGE, KINGHOST_HOMEPAGE, LOCAWEB_HOMEPAGE, NEUBOX_HOMEPAGE, REGISTROBR_HOMEPAGE, SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING, SUPPORT_ARTICLE_CHANGE_DNS_GODADDY, SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST, SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB, SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX, SUPPORT_ARTICLE_CHANGE_DNS_OTHERS, SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR, SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST, SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA, UOLHOST_HOMEPAGE, WEBEMPRESA_HOMEPAGE,
} from '@/config/urls/supportUrls';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions, domainsActions } from '@/redux/modules';
import { ConfiguredDomainStatus } from '../ConfiguredDomainStatus';
import { DOMAIN_NOT_CONFIGURED_STEPS, PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { sitesActions } from '@/redux/modules/sites';
import analytics from '@/analytics/domains/domains.analytics';

const optionsBr = ['Registro.br', 'Godaddy', 'Locaweb', 'Uol Host', 'Kinghost', 'Outros provedores'];
const optionsBrList = optionsBr.map((item, index) => ({ id: index, label: item, value: item }));
const optionsEs = ['Godaddy', 'Web Empresa', 'Colombia Hosting', 'Neubox', 'Otros proveedores'];
const optionsEsList = optionsEs.map((item, index) => ({ id: index, label: item, value: item }));

const providersHelpLinks = [
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_REGISTROBR, page: REGISTROBR_HOMEPAGE, name: 'Registro.br' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_GODADDY, page: GODADDY_HOMEPAGE, name: 'Godaddy' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_LOCAWEB, page: LOCAWEB_HOMEPAGE, name: 'Locaweb' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_UOLHOST, page: UOLHOST_HOMEPAGE, name: 'Uol Host' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_KINGHOST, page: KINGHOST_HOMEPAGE, name: 'Kinghost' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_WEBEMPRESA, page: WEBEMPRESA_HOMEPAGE, name: 'Web Empresa' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_COLOMBIAHOSTING, page: COLOMBIAHOSTING_HOMEPAGE, name: 'Colombia Hosting' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_NEUBOX, page: NEUBOX_HOMEPAGE, name: 'Neubox' },
  { tutorial: SUPPORT_ARTICLE_CHANGE_DNS_OTHERS, name: 'Others' },
];

const ModalDomainNotConfigured = ({ hideModal, isPreChat }) => {
  const { sites: sitesLocale, alerts: alertsLocale } = useLocale();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [domainNotConfigured, setDomainNotConfigured] = useState('');
  const [helpLink, setHelpLink] = useState({});

  const sitesState = useSelector(state => state.sites);
  const domainsFromPropagation = useSelector(state => state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONFIGURED));
  const domainsFromDiagnostic = useSelector(state => state.preChat.domainsPropagation && state.preChat.domainsPropagation.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONFIGURED));
  const domainNotConfiguredArray = isPreChat ? domainsFromDiagnostic : domainsFromPropagation;

  const selectOptions = domainNotConfiguredArray.map(domain => ({ value: domain.hostingId, label: domain.domain }));

  const { domainDns, loading } = sitesState;
  const { nameServer1, nameServer2 } = domainNotConfigured;

  useEffect(() => {
    providersHelpLinks.forEach((providerLinks) => {
      if (providerLinks.name === selectedProvider.label) setHelpLink(providerLinks);
      if (selectedProvider.label === 'Outros provedores' || selectedProvider.label === 'Otros proveedores') setHelpLink(providersHelpLinks[providersHelpLinks.length - 1]);
    });
  }, [selectedProvider]);

  useEffect(() => {
    if (domainNotConfigured) {
      dispatch(sitesActions.domainDns.request(domainNotConfigured.domain));
    }
  }, [dispatch, domainNotConfigured]);

  useEffect(() => {
    if (!domainNotConfigured && domainNotConfiguredArray && domainNotConfiguredArray.length === 1) {
      setDomainNotConfigured(domainNotConfiguredArray[0]);
      if (domainNotConfiguredArray[0].configured === 1) {
        setStep(DOMAIN_NOT_CONFIGURED_STEPS.STATUS);
      } else {
        setStep(DOMAIN_NOT_CONFIGURED_STEPS.SELECT_PROVIDER);
      }
    }
  }, [domainNotConfiguredArray, domainNotConfigured]);

  const handleHideModal = () => {
    dispatch(domainsActions.configuredStatusDomain.reset());
    hideModal && hideModal();
  };

  const handleSelectOption = (option) => {
    setSelectedProvider(option);
  };

  const handleSelectDomainToConfigure = (option) => {
    const domainToConfigure = domainNotConfiguredArray.find(domain => domain.hostingId === option.value);
    if (domainToConfigure) {
      setDomainNotConfigured(domainToConfigure);
    }
  };

  const handleDomainToConfigureClick = () => {
    if (domainNotConfigured.configured === 1) {
      setStep(DOMAIN_NOT_CONFIGURED_STEPS.STATUS);
    } else {
      setStep(actualStep => actualStep + 1);
    }
  };

  const goNextStep = () => {
    if (step < DOMAIN_NOT_CONFIGURED_STEPS.CONFIGURATION) {
      setStep(step + 1);
    }
  };

  const goPrevStep = () => {
    if (step > DOMAIN_NOT_CONFIGURED_STEPS.SELECT_DOMAIN) {
      setStep(step - 1);
    }
  };

  const goToInstructions = () => {
    analytics.dnsWizard.buttonBackInstructions();
    setStep(DOMAIN_NOT_CONFIGURED_STEPS.SELECT_PROVIDER);
  };

  const showCopyNotification = () => {
    dispatch(commonActions.notifications.set({
      label: sitesLocale.configuration.modal.stepTwo.copied,
      type: 'success',
    }));
  };

  const handleDNSClick = (nameserver) => {
    copyTextToClipboard(nameserver);
    showCopyNotification();
  };

  const handleDoneConfigurationClick = () => {
    analytics.dnsWizard.buttonAlreadyMadeChanges();
    dispatch(domainsActions.setDomainConfiguration.set(domainNotConfigured.hosting_id || domainNotConfigured.hostingId));
    setStep(actualStep => actualStep + 1);
  };

  const openNewPage = () => {
    analytics.dnsWizard.buttonGoToAnotherProvider();
    window.open(helpLink.page, '_blank').focus();
  };

  if (step === DOMAIN_NOT_CONFIGURED_STEPS.SELECT_DOMAIN) {
    return (
      <Modal
        maxWidth={625}
        onClose={handleHideModal}
        testId="select-domain-to-configure-modal"
      >
        <Styles.Wrapper>
          <Styles.Title data-testid="select-domain-to-configure-modal-title">
            {alertsLocale.whichDomain}
          </Styles.Title>
          <Styles.Info>
            <IconDanger color="problemPure" size="lg" />
            <Styles.InfoText
              data-testid="select-domain-to-configure-modal-text"
            >
              {alertsLocale.selectDomainToConfigure}
            </Styles.InfoText>
          </Styles.Info>
          <Styles.SelectDomainWrapper>
            <Styles.SelectDomain>
              <Select
                placeholder={alertsLocale.selectPlaceHolder}
                options={selectOptions}
                size="md"
                onClickSelect={handleSelectDomainToConfigure}
              />
            </Styles.SelectDomain>
          </Styles.SelectDomainWrapper>
          <Styles.Actions>
            <Styles.ButtonWrapper>
              <Button
                testId="select-domain-to-configure-modal-button"
                label={alertsLocale.configureDomain}
                onClick={handleDomainToConfigureClick}
                disabled={!domainNotConfigured}
                size="large"
              />
            </Styles.ButtonWrapper>
          </Styles.Actions>
        </Styles.Wrapper>
      </Modal>
    );
  }

  if (step === DOMAIN_NOT_CONFIGURED_STEPS.SELECT_PROVIDER) {
    return (
      <Modal onClose={handleHideModal} maxHeight={325} maxWidth={630} testId="select-dns-modal">
        <Styles.Wrapper>
          <Styles.Title>
            {sitesLocale.configuration.modal.stepOne.title}
          </Styles.Title>
          <Styles.DescriptionWrapper>
            <Styles.IconsWrapper>
              <IconDanger color="problemPure" size="lg" />
            </Styles.IconsWrapper>
            <Styles.TextDescription>
              {sitesLocale.configuration.modal.stepOne.description({ domain: domainNotConfigured && domainNotConfigured.domain })}
            </Styles.TextDescription>
          </Styles.DescriptionWrapper>
          <Styles.SelectWrapper>
            <Styles.TextSelect>
              {sitesLocale.configuration.modal.stepOne.question}
            </Styles.TextSelect>
            <Styles.Select>
              <Select
                placeholder={sitesLocale.configuration.modal.stepOne.placeholder}
                options={COUNTRY === 'br' ? optionsBrList : optionsEsList}
                size="md"
                testId="provider-select"
                onClickSelect={handleSelectOption}
              />
            </Styles.Select>
          </Styles.SelectWrapper>

          <Styles.ButtonWrapper>
            <Button
              testId="back-button"
              onClick={domainNotConfiguredArray && domainNotConfiguredArray.length > 1 ? goPrevStep : handleHideModal}
              variant="tertiary"
              size="large"
              label={sitesLocale.configuration.modal.stepOne.labelButtonBack}
            />
            <Button
              disabled={!selectedProvider.label}
              testId="continue-button"
              onClick={goNextStep}
              size="large"
              label={sitesLocale.configuration.modal.stepOne.labelButtonKeep}
            />
          </Styles.ButtonWrapper>
        </Styles.Wrapper>
      </Modal>
    );
  }

  if (step === DOMAIN_NOT_CONFIGURED_STEPS.CONFIGURATION) {
    return (
      <Modal onClose={handleHideModal} maxWidth={679} maxHeight={675} testId="select-dns-modal-step-2">
        <Styles.Wrapper>
          <Styles.Title>
            {sitesLocale.configuration.modal.stepTwo.title}
          </Styles.Title>

          <Styles.Text>
            {sitesLocale.configuration.modal.stepTwo.description}
            <Link
              href={helpLink.tutorial}
              target="_blank"
              text={sitesLocale.configuration.modal.stepTwo.tutorialLink}
            />
          </Styles.Text>

          <Styles.DnsWarning>
            <Styles.IconsWrapper>
              <IconClock color="pendingPure" />
            </Styles.IconsWrapper>
            <Styles.TextDescription>
              {sitesLocale.configuration.modal.stepTwo.waitTime()}
            </Styles.TextDescription>
          </Styles.DnsWarning>

          <Styles.InstructionSquare>
            {(selectedProvider.label === 'Outros provedores' || selectedProvider.label === 'Otros proveedores') ? (
              <Styles.VerticalInfosContainer>
                <Styles.Text>
                  {sitesLocale.configuration.modal.stepTwo.otherProvider.firstLine()}
                </Styles.Text>
                <Styles.Text>
                  {sitesLocale.configuration.modal.stepTwo.otherProvider.secondLine()}
                </Styles.Text>
                <Styles.Text>
                  {sitesLocale.configuration.modal.stepTwo.otherProvider.thirdLine()}
                </Styles.Text>
              </Styles.VerticalInfosContainer>
            ) : (
              <>
                <Styles.Text>
                  {sitesLocale.configuration.modal.stepTwo.replace}
                </Styles.Text>
                <Styles.SquareContentWrapper>
                  <Styles.SquareDnsColumn>
                    <Styles.TextBoldSquare>
                      {sitesLocale.configuration.modal.stepTwo.actualDns}
                      {selectedProvider.label}
                    </Styles.TextBoldSquare>
                    {loading && !domainDns && <IconLoading /> }
                    {!loading && domainDns && domainDns.length && (
                      <>
                        <Styles.UnderlinedText>
                          {sitesLocale.configuration.modal.stepTwo.server1}
                        </Styles.UnderlinedText>
                        <Styles.DnsContainer>
                          {domainDns && domainDns[0]}
                        </Styles.DnsContainer>
                        <Styles.UnderlinedText>
                          {sitesLocale.configuration.modal.stepTwo.server2}
                        </Styles.UnderlinedText>
                        <Styles.DnsContainer>
                          {domainDns && domainDns[1]}
                        </Styles.DnsContainer>
                      </>
                    )}
                    {!loading && !domainDns && helpLink && (
                      <Styles.DnsFailureText>
                        {sitesLocale.configuration.modal.stepTwo.failureDomainDns(helpLink.tutorial)}
                      </Styles.DnsFailureText>
                    )}
                  </Styles.SquareDnsColumn>

                  <Styles.IconWrapper>
                    <IconChevron direction="right" size="lg" />
                  </Styles.IconWrapper>

                  <Styles.SquareDnsColumn>
                    <Styles.TextBoldSquare>
                      {sitesLocale.configuration.modal.stepTwo.hgDns}
                    </Styles.TextBoldSquare>
                    {(nameServer1 || nameServer2) && (
                      <>
                        <Styles.UnderlinedText>
                          {sitesLocale.configuration.modal.stepTwo.server1}
                        </Styles.UnderlinedText>
                        <Styles.DnsContainer>
                          <>
                            {nameServer1 || '-'}
                            {nameServer1 && (
                              <IconButton
                                icon="IconCopy"
                                onClick={() => handleDNSClick(nameServer1)}
                              />
                            )}
                          </>
                        </Styles.DnsContainer>
                        <Styles.UnderlinedText>
                          {sitesLocale.configuration.modal.stepTwo.server2}
                        </Styles.UnderlinedText>
                        <Styles.DnsContainer>
                          <>
                            {nameServer2 || '-'}
                            {nameServer2 && (
                              <IconButton
                                icon="IconCopy"
                                onClick={() => handleDNSClick(nameServer2)}
                              />
                            )}
                          </>
                        </Styles.DnsContainer>
                      </>
                    )}
                    {!nameServer1 && !nameServer2 && helpLink && (
                      <Styles.DnsFailureText>
                        {sitesLocale.configuration.modal.stepTwo.failurePlanDns(helpLink.tutorial)}
                      </Styles.DnsFailureText>
                    )}
                  </Styles.SquareDnsColumn>
                </Styles.SquareContentWrapper>
              </>
            )}


            <Styles.ButtonsBottomWrapper>
              {helpLink && helpLink.page && (
                <Button
                  testId="go-to-provider-button"
                  size="large"
                  label={sitesLocale.configuration.modal.stepTwo.goToHost({ provider: selectedProvider.label })}
                  onClick={openNewPage}
                  customStyles={{
                    backColor: 'transparent',
                  }}
                />
              )}
              <Button
                testId="back-button"
                size="large"
                onClick={handleDoneConfigurationClick}
                variant="secondary"
                label={sitesLocale.configuration.modal.stepTwo.changesDone}
              />
            </Styles.ButtonsBottomWrapper>
          </Styles.InstructionSquare>

          <Styles.BackButtonWrapper>
            <Button
              testId="back-button"
              onClick={goToInstructions}
              variant="tertiary"
              label={sitesLocale.configuration.modal.stepTwo.labelButtonBack}
            />
          </Styles.BackButtonWrapper>

        </Styles.Wrapper>
      </Modal>
    );
  }

  if (step === DOMAIN_NOT_CONFIGURED_STEPS.STATUS) {
    return (
      <Modal onClose={handleHideModal} maxWidth={680} testId="select-dns-modal-step-3">
        <Styles.Wrapper step={step}>
          <Styles.CheckStatusModalTitle>
            {sitesLocale.configuration.modal.stepThree.title}
          </Styles.CheckStatusModalTitle>
          <Styles.CheckStatusModalDescription>
            {sitesLocale.configuration.modal.stepThree.description}
          </Styles.CheckStatusModalDescription>
          <Styles.ConfiguredDomainStatusWrapper>
            <ConfiguredDomainStatus
              configuredDomainStatusTexts={sitesLocale.configuration.modal.configuredDomainStatusTexts}
              domain={domainNotConfigured && domainNotConfigured.domain}
              externalDns={selectedProvider && selectedProvider.label}
              CheckStatusAnalyticEvent={analytics.dnsWizard.buttonCheckDomain}
            />
          </Styles.ConfiguredDomainStatusWrapper>
          <Styles.BackButtonWrapper>
            <Button
              testId="back-button"
              onClick={goToInstructions}
              variant="tertiary"
              label={sitesLocale.configuration.modal.stepTwo.labelButtonBack}
            />
          </Styles.BackButtonWrapper>

        </Styles.Wrapper>
      </Modal>
    );
  }
};

export default withI18n()((ModalDomainNotConfigured));
