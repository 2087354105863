import alertsBR from './alerts.br';
import alertsMX from './alerts.mx';
import alertsCL from './alerts.cl';
import alertsCO from './alerts.co';


const alerts = {
  BR: alertsBR,
  MX: alertsMX,
  CL: alertsCL,
  CO: alertsCO,
};

export default alerts;
