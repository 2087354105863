import { locale } from '@/utils/locale';

export const useMountCardStatusLocale = {
  status: {
    pending: {
      label: locale('warningStatus.pending.label'),
      tooltipSite: locale('warningStatus.pending.tooltip'),
      buttonLabel: locale('warningStatus.pending.buttonLabel'),
    },
    analysis: {
      label: locale('warningStatus.analysis.label'),
      tooltipSite: locale('warningStatus.analysis.tooltip'),
    },
    nearDue: {
      label: date => locale('warningStatus.nearDue.label', { date }),
      tooltipSite: locale('warningStatus.nearDue.tooltip'),
      buttonLabel: locale('warningStatus.nearDue.buttonLabel'),
      day: locale('warningStatus.nearDue.day'),
      days: locale('warningStatus.nearDue.days'),
    },
    overdue: {
      tooltipSite: locale('warningStatus.overdue.tooltip'),
      buttonLabel: locale('warningStatus.nearDue.buttonLabel'),
    },
    suspended: {
      subLabel: locale('warningStatus.suspended.subLabel'),
      subLabelTooltip: locale('warningStatus.suspended.subLabelTooltip'),
      label: date => locale('warningStatus.suspended.label', { date }),
      tooltipSite: locale('warningStatus.suspended.tooltip'),
      day: locale('warningStatus.nearDue.day'),
      days: locale('warningStatus.nearDue.days'),
      buttonLabel: locale('warningStatus.nearDue.buttonLabel'),
    },
  },
};
