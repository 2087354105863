import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

import styles from './styles';


const NoResultMessage = ({ classes, children }) => (
  <Typography variant="caption" className={classes.message}>{children}</Typography>
);

export default withStyles(styles)(NoResultMessage);
