const styles = theme => ({
  icon: {
    fontSize: '160px',
    position: 'absolute',
    top: '130px',
    right: '5px',
    [theme.breakpoints.only('xs')]: {
      top: '-10px',
      right: '-55px',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      right: '5px',
    },
  },
  st0: { fill: '#C5E0EF' },
  st1: { fill: '#D1D1D1' },
  st2: { fill: '#FFE49B' },
  st3: { fill: '#69D998' },
  st4: { fill: '#FFB8D5' },
});

export default styles;
