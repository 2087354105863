const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
  card: {
    padding: '24px',
    minWidth: '1040px',
    display: 'flex',
    flexDirection: 'column',
  },
  historyTitle: {
    color: theme.color.tundora,
    fontSize: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  historySubtitle: {
    color: theme.color.tundora,
    fontSize: '14px',
    marginBottom: '24px',
  },
  tableContainer: {
    background: `${theme.color.solitude}`,
    padding: '12px',
    display: 'flex',
    alignItems: 'stretch',
    border: `1px solid ${theme.color.geyser}`,
  },
  tableScroll: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '375px',
    width: '100%',
    paddingRight: '12px',
    overflowY: 'scroll',
    'scrollbar-color': `${theme.color.indigo} ${theme.color.white}`,
  },
  historyDescription: {
    maxWidth: '800px',
    marginBottom: '12px',
    font: 'normal normal normal 14px/17px Roboto',
    letterSpacing: '0.01px',
  },
});

export default styles;
