/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '@/pages/common';
import {
  Accordion, Button, Tag, IconCheck, IconClock, IconDanger, Select,
} from 'gatorcomponents';
import { useResize } from '@/hooks/useResize';
import useLocale from '@/hooks/useLocale/useLocale';
import { ANALYTICS, MODAL_STEP, ORDER_STATUS } from './DuplicateOrdersModal.types';
import * as Styles from './DuplicateOrdersModal.styles';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { locale } from '@/utils/locale';
import { useHistory } from 'react-router';
import {
  invoicesActions, productsActions, sitesActions,
} from '@/redux/modules';
import { invoicesAnalytics } from '@/analytics';
import { loadInvoices } from '@/redux/actions/invoices';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS, EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY } from '@/config/GrowthBook/constants';

const DuplicateOrdersModal = ({
  hideModal,
  showMyAlerts,
  defaultModalStep = MODAL_STEP.DEFAULT,
  defaultChosenOrder,
  defaultAccordionsOpened,
}) => {
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);
  const withGatorSolutionsProductCategory = useFeatureIsOn(EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY);
  const { duplicateOrders } = useSelector(state => state.preChat);
  const invoicesState = useSelector(state => state.invoicesV2);
  const [modalStep, setModalStep] = useState((duplicateOrders && duplicateOrders.length > 1) ? MODAL_STEP.DUPLICATED_ORDER_CHOICE : defaultModalStep);
  const [chosenOrder, setChosenOrder] = useState(defaultChosenOrder);
  const [optionSelected, setOptionSelected] = useState(undefined);
  const [accordionsOpened, setAccordionsOpened] = useState(defaultAccordionsOpened);
  const { billing: billingLocale } = useLocale();
  const history = useHistory();
  const dispatch = useDispatch();
  const { requestDiagnostic } = useDiagnostic();

  const windowRect = useResize();
  const { width } = windowRect;
  const mobile = width < 960;

  const orderGroupSelected = (duplicateOrders && duplicateOrders.length === 1) ? duplicateOrders[0] : optionSelected && duplicateOrders[optionSelected.value];

  const handleHideModal = () => {
    hideModal && hideModal();
  };

  const handleClose = (analyticsToDispatch) => {
    hideModal && hideModal();
    showMyAlerts && showMyAlerts();

    switch (analyticsToDispatch) {
      case (ANALYTICS.CLOSE_UNDERSTAND):
        invoicesAnalytics.duplicateOrderClickOkUnderstand();
        break;
      default:
    }
  };

  const handleOnClickSelect = (option) => {
    setOptionSelected(option);
  };

  const handleOnClickAccordion = (index) => {
    const newAccordionsOpened = [...accordionsOpened];

    newAccordionsOpened.forEach((accordion, i) => {
      if (i === index) {
        // eslint-disable-next-line no-param-reassign
        accordion.opened = !(accordion.opened);
      } else {
        // eslint-disable-next-line no-param-reassign
        accordion.opened = false;
      }
    });

    setAccordionsOpened(newAccordionsOpened);
  };

  const handleSeeInvoices = (invoiceId, successCancel = false, seeOrderFeedbacks = false) => {
    (successCancel || seeOrderFeedbacks) ? invoicesAnalytics.duplicateOrderModalSeeOrder() : invoicesAnalytics.duplicateOrderSeeOrder();
    const url = `${locale('routes.invoices')}${successCancel ? locale('routes.cancelled') : locale('routes.unpaid')}/${invoiceId}`;
    handleHideModal();
    history && history.push(url);
  };

  const cancelOrder = (order, tryAgain = false) => {
    tryAgain ? invoicesAnalytics.duplicateOrderModalTryAgain() : invoicesAnalytics.duplicateOrderCancelOrder();
    setChosenOrder(order);

    const reloadData = () => {
      dispatch(productsActions.products.request({ history: null, withGatorSolutions: withGatorSolutionsProductCategory }));
      dispatch(sitesActions.sites.request({ withGatorSolutions }));
      requestDiagnostic('duplicatedOrdersModal');
      const noCache = false;
      dispatch(loadInvoices('Cancelled', noCache));
      dispatch(loadInvoices('Paid', noCache));
      dispatch(loadInvoices('Unpaid', noCache));
    };

    reloadData();
    dispatch(invoicesActions.cancelOrder.request({ orderId: order.id, reloadData }));
    if (modalStep === MODAL_STEP.DEFAULT) {
      setModalStep(MODAL_STEP.LOADING_SECOND_SCREEN);
    }
  };

  const verifyIfDuplicatedHostings = (duplicateOrderGroup) => {
    const uniqueHostings = new Set();
    let hasDuplicatedHostings = false;

    duplicateOrderGroup.map((order => order.hosting_items && order.hosting_items.map((hostingItem) => {
      const key = `${hostingItem.product_name} - ${hostingItem.domain}`;
      if (uniqueHostings.has(key)) {
        hasDuplicatedHostings = true;
        return;
      }
      uniqueHostings.add(key);
    })));
    return hasDuplicatedHostings;
  };

  const selectOrderOptions = (duplicateOrdersList) => {
    const options = [];

    duplicateOrdersList.map((orderGroup, index) => {
      const isDuplicatedHostings = verifyIfDuplicatedHostings(orderGroup);

      if (isDuplicatedHostings) {
        options.push({
          value: index,
          label: billingLocale.duplicateOrdersModal.planNameAccordion(orderGroup[0].hosting_items[0].product_name, orderGroup[0].hosting_items[0].domain),
        });
      }
      if (!isDuplicatedHostings && orderGroup[0].domain_items) {
        options.push({
          value: index,
          label: billingLocale.duplicateOrdersModal.domainNameAccordion(orderGroup[0].domain_items[0].domain),
        });
      }
    });

    return options;
  };

  useEffect(() => {
    if ((modalStep === MODAL_STEP.CANCELLATION_FAILURE || modalStep === MODAL_STEP.LOADING_SECOND_SCREEN) && !invoicesState.orderCancelLoading && invoicesState.orderCancelStatus) {
      if (invoicesState.orderCancelStatus === 'canceled') {
        invoicesAnalytics.duplicateOrderPositiveFeedback();
        setModalStep(MODAL_STEP.CANCELLATION_SUCCESS);
      }
      if (invoicesState.orderCancelStatus === 'cancelation_fail') {
        invoicesAnalytics.duplicateOrderFailFeedback();
        setModalStep(MODAL_STEP.CANCELLATION_FAILURE);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesState.orderCancelStatus, invoicesState.orderCancelLoading]);

  useEffect(() => {
    invoicesAnalytics.duplicateOrderModalShow();
  }, []);

  const renderAccordion = (index, order, duplicatedHosting = false) => {
    const orderDate = format(new Date(order.invoice_date), 'dd/MM/yyyy');
    const orderHour = format(new Date(order.invoice_date), 'HH:mm');

    const mountTag = (
      <Styles.AccordionTag data-testid={`${order.id}-tag`}>
        {order.invoice_status === ORDER_STATUS.PAID ? (
          <Tag label={billingLocale.duplicateOrdersModal.payedTag} positionStatic testId={`invoice-${order.invoice_id}-tag-active`} />
        ) : (
          <Tag label={billingLocale.duplicateOrdersModal.pendingTag} variant="pending" positionStatic testId={`invoice-${order.invoice_id}-tag-pending`} />
        )}
      </Styles.AccordionTag>
    );

    const accordionTitle = (
      <Styles.AccordionContainer data-testid={`invoice-${order.invoice_id}-accordion`}>
        <Styles.AccordionTitle>
          <Styles.AccordionName>
            {order.invoice_status === ORDER_STATUS.PAID ? (
              <IconCheck color="activePure" />
            ) : (
              <IconClock color="pendingPure" />
            )}

            <Styles.AccordionNameSpan title={
              duplicatedHosting && order.hosting_items
                ? `${order.hosting_items[0].product_name} + ${order.hosting_items[0].domain}`
                : `${order.domain_items[0].domain}`
              }
            >
              {duplicatedHosting && order.hosting_items
                ? billingLocale.duplicateOrdersModal.planNameAccordion(order.hosting_items[0].product_name, order.hosting_items[0].domain)
                : order.domain_items && billingLocale.duplicateOrdersModal.domainNameAccordion(order.domain_items[0].domain)
              }
            </Styles.AccordionNameSpan>
          </Styles.AccordionName>
          {!mobile && mountTag}
        </Styles.AccordionTitle>
        <Styles.AccordionDescription>
          {order.invoice_status === ORDER_STATUS.PAID && billingLocale.duplicateOrdersModal.invoicePayedDescription(order.invoice_id, orderDate, orderHour)}
          {order.invoice_status !== ORDER_STATUS.PAID && billingLocale.duplicateOrdersModal.invoicePendingDescription(order.invoice_id, orderDate, orderHour)}
        </Styles.AccordionDescription>
        {mobile && mountTag}
      </Styles.AccordionContainer>
    );

    const accordionItem = () => (
      <>
        {order.invoice_status !== ORDER_STATUS.PAID && (
          <>
            <Styles.Description data-testid={`pending-description-${order.id}`} accordionDescription>
              {billingLocale.duplicateOrdersModal.invoicePendingText}
            </Styles.Description>
            <Styles.ButtonsWrapper paddingTop>
              <Button testId={`see-invoice-${order.invoice_id}-button`} label={billingLocale.duplicateOrdersModal.showInvoice} variant="tertiary" onClick={() => handleSeeInvoices(order.invoice_id)} />
              <Styles.ButtonLoadingContainer>
                {invoicesState.orderCancelLoading ? (
                  <Button testId={`order-${order.id}-loading-button`} width="full" loading />
                ) : (
                  <Button testId={`order-${order.id}-cancel-button`} label={billingLocale.duplicateOrdersModal.cancelOrder} onClick={() => cancelOrder(order)} width="full" />
                )}
              </Styles.ButtonLoadingContainer>
            </Styles.ButtonsWrapper>
          </>
        )}

        {order.invoice_status === ORDER_STATUS.PAID && (
        <Styles.Description data-testid={`payed-description-${order.id}`} accordionDescription>
          {billingLocale.duplicateOrdersModal.invoicePayedText}
        </Styles.Description>
        )}
      </>
    );

    if (order && order.invoice_status === ORDER_STATUS.PAID) {
      return (
        <Accordion
          title={accordionTitle}
          items={accordionItem}
          width="full"
          iconPositionStatic
          withIcon={false}
          alignStart
          open={accordionsOpened && accordionsOpened[index].opened}
          testId={`${order.id}-active-accordion`}
          onToggleAccordion={() => handleOnClickAccordion(index)}
        />
      );
    }
    if (order && order.invoice_status !== ORDER_STATUS.PAID) {
      return (
        <Accordion
          title={accordionTitle}
          items={accordionItem}
          width="full"
          iconPositionStatic
          withIcon={false}
          alignStart
          open={accordionsOpened && accordionsOpened[index].opened}
          testId={`${order.id}-pending-accordion`}
          onToggleAccordion={() => handleOnClickAccordion(index)}
        />
      );
    }
  };

  const controllAccordionsOpened = useCallback(() => {
    const firstPendingOrder = orderGroupSelected && orderGroupSelected.find(order => order.invoice_status !== ORDER_STATUS.PAID);
    const accordionsOpenedList = [];
    orderGroupSelected && orderGroupSelected.map(order => accordionsOpenedList.push({ opened: firstPendingOrder.id === order.id }));

    return accordionsOpenedList;
  }, [orderGroupSelected]);

  useEffect(() => {
    const accordionsOpened = controllAccordionsOpened();
    setAccordionsOpened(accordionsOpened);
  }, [controllAccordionsOpened]);

  const resultCancellationAlert = (cancellationStatus, domain, invoiceId, date, plan = null) => {
    const orderDate = format(new Date(date), 'dd/MM/yyyy');
    const orderHour = format(new Date(date), 'HH:mm');

    return (
      <Styles.NoticeContainer>
        <Styles.ResultIcon success={cancellationStatus === 'success'}>
          {cancellationStatus === 'success' ? (
            <IconCheck color="highPure" />
          ) : (
            <IconDanger color="highPure" size="lg" />
          )}
        </Styles.ResultIcon>
        <Styles.MessageWrapper>
          {cancellationStatus === 'success' ? (
            <Styles.Description>{billingLocale.duplicateOrdersModal.successAlertLabel(domain, invoiceId, orderDate, orderHour, plan)}</Styles.Description>
          ) : (
            <Styles.Description>{billingLocale.duplicateOrdersModal.failureAlertLabel(domain, invoiceId, orderDate, orderHour, plan)}</Styles.Description>
          )}
        </Styles.MessageWrapper>
      </Styles.NoticeContainer>
    );
  };

  return (
    <Styles.Wrapper>
      <Modal
        maxWidth={650}
        onClose={handleClose}
        variant="regular"
        testId="duplicate-orders-modal"
      >
        <Styles.ContentWrapper data-testid={modalStep}>
          {modalStep === MODAL_STEP.DUPLICATED_ORDER_CHOICE && (
            <>
              <Styles.Header>
                <Styles.Title>{billingLocale.duplicateOrdersModal.modalTitle}</Styles.Title>
              </Styles.Header>
              <Styles.ChoiceDescription data-testid="order-choice-description">
                <IconDanger color="pendingPure" size="lg" />
                <Styles.Description>
                  {billingLocale.duplicateOrdersModal.choiceOrderDescription}
                </Styles.Description>
              </Styles.ChoiceDescription>
              <Styles.SelectContainer data-testid="select-order-component">
                <Select
                  label={billingLocale.duplicateOrdersModal.selectOrderTitle}
                  placeholder={billingLocale.duplicateOrdersModal.selectOrderPlaceholder}
                  options={selectOrderOptions(duplicateOrders)}
                  onClickSelect={handleOnClickSelect}
                  withScroll
                  dropdownHeight="sm"
                />
              </Styles.SelectContainer>
              <Styles.ButtonsWrapper paddingBottom data-testid="order-choice-button">
                <Button
                  size="large"
                  label={billingLocale.duplicateOrdersModal.choiceOrderButtonLabel}
                  width="fit"
                  disabled={!optionSelected}
                  onClick={() => setModalStep(MODAL_STEP.DEFAULT)}
                />
              </Styles.ButtonsWrapper>
            </>
          )}

          {(modalStep === MODAL_STEP.DEFAULT || modalStep === MODAL_STEP.LOADING_SECOND_SCREEN) && (
            <>
              <Styles.Header>
                <Styles.Title>{billingLocale.duplicateOrdersModal.modalTitle}</Styles.Title>
              </Styles.Header>
              <Styles.Description data-testid="default-screen-description">
                {!mobile ? billingLocale.duplicateOrdersModal.modalDescription : billingLocale.duplicateOrdersModal.modalDescriptionMobile}
              </Styles.Description>
              {duplicateOrders.length && orderGroupSelected.map((order, index) => renderAccordion(index, order, verifyIfDuplicatedHostings(orderGroupSelected)))}
            </>
          )}

          {modalStep === MODAL_STEP.CANCELLATION_SUCCESS && (
            <>
              <Styles.Header>
                <Styles.Title>{billingLocale.duplicateOrdersModal.successTitle}</Styles.Title>
              </Styles.Header>
              {chosenOrder.hosting_items && chosenOrder.hosting_items.length
                ? resultCancellationAlert('success', chosenOrder.hosting_items[0].domain, chosenOrder.invoice_id, chosenOrder.invoice_date, chosenOrder.hosting_items[0].product_name)
                : resultCancellationAlert('success', chosenOrder.domain_items[0].domain, chosenOrder.invoice_id, chosenOrder.invoice_date)
              }
              <Styles.ButtonsWrapper secondScreen paddingTop>
                {mobile ? (
                  <>
                    <Button testId="success-close-button" label={billingLocale.duplicateOrdersModal.okButtonLabel} onClick={() => handleClose(ANALYTICS.CLOSE_UNDERSTAND)} />
                    <Button testId="success-see-invoice-button" label={billingLocale.duplicateOrdersModal.showInvoice} variant="tertiary" onClick={() => handleSeeInvoices(chosenOrder.invoice_id, true)} />
                  </>
                ) : (
                  <>
                    <Button testId="success-see-invoice-button" label={billingLocale.duplicateOrdersModal.showInvoice} variant="tertiary" onClick={() => handleSeeInvoices(chosenOrder.invoice_id, true)} />
                    <Button testId="success-close-button" label={billingLocale.duplicateOrdersModal.okButtonLabel} onClick={() => handleClose(ANALYTICS.CLOSE_UNDERSTAND)} size="large" />
                  </>
                )}

              </Styles.ButtonsWrapper>
            </>
          )}

          {modalStep === MODAL_STEP.CANCELLATION_FAILURE && (
            <>
              <Styles.Header>
                <Styles.Title>{billingLocale.duplicateOrdersModal.failureTitle}</Styles.Title>
              </Styles.Header>
              {chosenOrder.hosting_items && chosenOrder.hosting_items.length
                ? resultCancellationAlert('failure', chosenOrder.hosting_items[0].domain, chosenOrder.invoice_id, chosenOrder.invoice_date, chosenOrder.hosting_items[0].product_name)
                : resultCancellationAlert('failure', chosenOrder.domain_items[0].domain, chosenOrder.invoice_id, chosenOrder.invoice_date)
              }
              <Styles.ButtonsWrapper secondScreen paddingTop>
                {mobile ? (
                  <>
                    <Styles.ButtonLoadingContainer tryAgain>
                      {invoicesState.orderCancelLoading ? (
                        <Button testId="failure-try-again-button-loading" loading width="full" />
                      ) : (
                        <Button testId="failure-try-again-button" label={billingLocale.duplicateOrdersModal.tryAgainButtonLabel} onClick={() => cancelOrder(chosenOrder, true)} width="full" />
                      )}
                    </Styles.ButtonLoadingContainer>
                    <Button testId="failure-see-invoice-button" label={billingLocale.duplicateOrdersModal.showInvoice} variant="tertiary" onClick={() => handleSeeInvoices(chosenOrder.invoice_id, false, true)} />
                  </>
                ) : (
                  <>
                    <Button testId="failure-see-invoice-button" label={billingLocale.duplicateOrdersModal.showInvoice} variant="tertiary" onClick={() => handleSeeInvoices(chosenOrder.invoice_id, false, true)} />

                    <Styles.ButtonLoadingContainer tryAgain>
                      {invoicesState.orderCancelLoading ? (
                        <Button testId="failure-try-again-button-loading" loading width="full" size="large" />
                      ) : (
                        <Button testId="failure-try-again-button" label={billingLocale.duplicateOrdersModal.tryAgainButtonLabel} onClick={() => cancelOrder(chosenOrder, true)} size="large" width="full" />
                      )}
                    </Styles.ButtonLoadingContainer>
                  </>
                )}
              </Styles.ButtonsWrapper>
            </>
          )}
        </Styles.ContentWrapper>
      </Modal>
    </Styles.Wrapper>
  );
};

export default DuplicateOrdersModal;
