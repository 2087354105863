import { AuthActionsTypes } from './auth.types';

const updateJwtToken = () => ({ type: AuthActionsTypes.UPDATE_JWT_TOKEN });
const updateJwtTokenFinished = () => ({ type: AuthActionsTypes.UPDATE_JWT_TOKEN_FINISH });
const setJwtRefreshError = bool => ({ type: AuthActionsTypes.SET_JWT_REFRESH_ERROR, payload: { bool } });

const sendPhoneToken = payload => ({ type: AuthActionsTypes.SEND_PHONE_TOKEN, payload });
const sendPhoneTokenSuccess = () => ({ type: AuthActionsTypes.SEND_PHONE_TOKEN_SUCCESS });
const sendPhoneTokenFailure = () => ({ type: AuthActionsTypes.SEND_PHONE_TOKEN_FAILURE });

const actions = {
  tokens: {
    jwt: {
      update: updateJwtToken,
      updateFinished: updateJwtTokenFinished,
      errorDuringRefresh: setJwtRefreshError,
    },
  },
  user: {
    phoneToken: {
      send: sendPhoneToken,
      success: sendPhoneTokenSuccess,
      failure: sendPhoneTokenFailure,
    },
  },
};

export default actions;
