import packageJson from '@/../package.json';

import { getLangByCountry } from '@/config/application/langConfig';
import { getGTMByCountry } from '@/config/application/gtmConfig';
import { getConfigSurveyByCountry } from '@/config/application/surveyMonkeyConfig';

import {
  MAIN_URL_BRASIL_PROD, MAIN_URL_MEXICO_PROD, MAIN_URL_COLOMBIA_PROD, MAIN_URL_CHILE_PROD,
  MAIN_URL_BRASIL_DEV, MAIN_URL_MEXICO_DEV, MAIN_URL_CHILE_DEV, MAIN_URL_COLOMBIA_DEV, MAIN_URL_BRASIL_HOMOLOG, MAIN_URL_MEXICO_HOMOLOG,
  MAIN_URL_CHILE_HOMOLOG, MAIN_URL_COLOMBIA_HOMOLOG, MAIN_URL_BRASIL_DELTA, MAIN_URL_CHILE_DELTA,
  MAIN_URL_COLOMBIA_DELTA, MAIN_URL_MEXICO_DELTA, MAIN_URL_BRASIL_ALPHA, MAIN_URL_CHILE_ALPHA,
  MAIN_URL_COLOMBIA_ALPHA, MAIN_URL_MEXICO_ALPHA, MAIN_URL_BRASIL_BRAVO, MAIN_URL_CHILE_BRAVO,
  MAIN_URL_COLOMBIA_BRAVO, MAIN_URL_MEXICO_BRAVO, MAIN_URL_BRASIL_CHARLIE, MAIN_URL_CHILE_CHARLIE,
  MAIN_URL_COLOMBIA_CHARLIE, MAIN_URL_MEXICO_CHARLIE, MAIN_URL_BRASIL_XRAY, MAIN_URL_CHILE_XRAY,
  MAIN_URL_COLOMBIA_XRAY, MAIN_URL_MEXICO_XRAY, MAIN_URL_BRASIL_YANKEE, MAIN_URL_CHILE_YANKEE,
  MAIN_URL_COLOMBIA_YANKEE, MAIN_URL_MEXICO_YANKEE, MAIN_URL_BRASIL_FOXTROT, MAIN_URL_CHILE_FOXTROT,
  MAIN_URL_COLOMBIA_FOXTROT, MAIN_URL_MEXICO_FOXTROT, MAIN_URL_BRASIL_SIERRA, MAIN_URL_CHILE_SIERRA,
  MAIN_URL_COLOMBIA_SIERRA, MAIN_URL_MEXICO_SIERRA, MAIN_URL_BRASIL_QA, MAIN_URL_CHILE_QA, MAIN_URL_COLOMBIA_QA,
  MAIN_URL_MEXICO_QA, MAIN_URL_BRASIL_WHMCS_TRAINING, MAIN_URL_CHILE_WHMCS_TRAINING, MAIN_URL_COLOMBIA_WHMCS_TRAINING,
  MAIN_URL_MEXICO_WHMCS_TRAINING, MAIN_URL_BRASIL_LOCAL_DEVELOPMENT, MAIN_URL_CHILE_LOCAL_DEVELOPMENT,
  MAIN_URL_COLOMBIA_LOCAL_DEVELOPMENT, MAIN_URL_MEXICO_LOCAL_DEVELOPMENT, MAIN_URL_BRASIL_AWS_TEST, MAIN_URL_CHILE_AWS_TEST,
  MAIN_URL_COLOMBIA_AWS_TEST, MAIN_URL_MEXICO_AWS_TEST, MAIN_URL_BRASIL_STORYBOOK_DEV, MAIN_URL_CHILE_STORYBOOK_DEV,
  MAIN_URL_COLOMBIA_STORYBOOK_DEV, MAIN_URL_MEXICO_STORYBOOK_DEV, MAIN_URL_BRASIL_STORYBOOK_PROD, MAIN_URL_MEXICO_STORYBOOK_PROD,
} from '@/config/environments';
import {
  getClientIdByCountry,
  IFRAME_PROD,
  IFRAME_SANDBOX,
  IFRAME_PAGENAME,
} from '@/config/application/paas';
import {
  BRASPAG_ORG_ID,
  BRASPAG_PROVIDER_MERCHANT_ID,
  IFRAME_URL_FINGERPRINT,
} from '@/config/application/braspag';
import brConfig from './country/br.json';
import mxConfig from './country/mx.json';
import coConfig from './country/co.json';
import clConfig from './country/cl.json';


const configByOrigin = {
  // PRODUCTION
  [MAIN_URL_BRASIL_PROD]: brConfig.prod,
  [MAIN_URL_CHILE_PROD]: clConfig.prod,
  [MAIN_URL_COLOMBIA_PROD]: coConfig.prod,
  [MAIN_URL_MEXICO_PROD]: mxConfig.prod,
  // DEV
  [MAIN_URL_BRASIL_DEV]: brConfig.dev,
  [MAIN_URL_CHILE_DEV]: clConfig.dev,
  [MAIN_URL_COLOMBIA_DEV]: coConfig.dev,
  [MAIN_URL_MEXICO_DEV]: mxConfig.dev,
  // HOMOLOG
  [MAIN_URL_BRASIL_HOMOLOG]: brConfig.homolog,
  [MAIN_URL_CHILE_HOMOLOG]: clConfig.homolog,
  [MAIN_URL_COLOMBIA_HOMOLOG]: coConfig.homolog,
  [MAIN_URL_MEXICO_HOMOLOG]: mxConfig.homolog,
  // SQUAD DELTA
  [MAIN_URL_BRASIL_DELTA]: brConfig.delta,
  [MAIN_URL_CHILE_DELTA]: clConfig.delta,
  [MAIN_URL_COLOMBIA_DELTA]: coConfig.delta,
  [MAIN_URL_MEXICO_DELTA]: mxConfig.delta,
  // SQUAD ALPHA
  [MAIN_URL_BRASIL_ALPHA]: brConfig.alpha,
  [MAIN_URL_CHILE_ALPHA]: clConfig.alpha,
  [MAIN_URL_COLOMBIA_ALPHA]: coConfig.alpha,
  [MAIN_URL_MEXICO_ALPHA]: mxConfig.alpha,
  // SQUAD BRAVO
  [MAIN_URL_BRASIL_BRAVO]: brConfig.bravo,
  [MAIN_URL_CHILE_BRAVO]: clConfig.bravo,
  [MAIN_URL_COLOMBIA_BRAVO]: coConfig.bravo,
  [MAIN_URL_MEXICO_BRAVO]: mxConfig.bravo,
  // SQUAD CHARLIE
  [MAIN_URL_BRASIL_CHARLIE]: brConfig.charlie,
  [MAIN_URL_CHILE_CHARLIE]: clConfig.charlie,
  [MAIN_URL_COLOMBIA_CHARLIE]: coConfig.charlie,
  [MAIN_URL_MEXICO_CHARLIE]: mxConfig.charlie,
  // SQUAD XRAY
  [MAIN_URL_BRASIL_XRAY]: brConfig.xray,
  [MAIN_URL_CHILE_XRAY]: clConfig.xray,
  [MAIN_URL_COLOMBIA_XRAY]: coConfig.xray,
  [MAIN_URL_MEXICO_XRAY]: mxConfig.xray,
  // SQUAD YANKEE
  [MAIN_URL_BRASIL_YANKEE]: brConfig.yankee,
  [MAIN_URL_CHILE_YANKEE]: clConfig.yankee,
  [MAIN_URL_COLOMBIA_YANKEE]: coConfig.yankee,
  [MAIN_URL_MEXICO_YANKEE]: mxConfig.yankee,
  // SQUAD FOXTROT
  [MAIN_URL_BRASIL_FOXTROT]: brConfig.foxtrot,
  [MAIN_URL_CHILE_FOXTROT]: clConfig.foxtrot,
  [MAIN_URL_COLOMBIA_FOXTROT]: coConfig.foxtrot,
  [MAIN_URL_MEXICO_FOXTROT]: mxConfig.foxtrot,
  // SQUAD SIERRA
  [MAIN_URL_BRASIL_SIERRA]: brConfig.sierra,
  [MAIN_URL_CHILE_SIERRA]: clConfig.sierra,
  [MAIN_URL_COLOMBIA_SIERRA]: coConfig.sierra,
  [MAIN_URL_MEXICO_SIERRA]: mxConfig.sierra,
  // QA
  [MAIN_URL_BRASIL_QA]: brConfig.qa,
  [MAIN_URL_CHILE_QA]: clConfig.qa,
  [MAIN_URL_COLOMBIA_QA]: coConfig.qa,
  [MAIN_URL_MEXICO_QA]: mxConfig.qa,
  // WHMCS_TRAINING
  [MAIN_URL_BRASIL_WHMCS_TRAINING]: brConfig.whmcs_training,
  [MAIN_URL_CHILE_WHMCS_TRAINING]: clConfig.whmcs_training,
  [MAIN_URL_COLOMBIA_WHMCS_TRAINING]: coConfig.whmcs_training,
  [MAIN_URL_MEXICO_WHMCS_TRAINING]: mxConfig.whmcs_training,
  // LOCAL DEVELOPMENT
  [MAIN_URL_BRASIL_LOCAL_DEVELOPMENT]: brConfig.localdevelop,
  [MAIN_URL_CHILE_LOCAL_DEVELOPMENT]: clConfig.localdevelop,
  [MAIN_URL_COLOMBIA_LOCAL_DEVELOPMENT]: coConfig.localdevelop,
  [MAIN_URL_MEXICO_LOCAL_DEVELOPMENT]: mxConfig.localdevelop,
  // AWS TEST
  [MAIN_URL_BRASIL_AWS_TEST]: brConfig.homolog,
  [MAIN_URL_CHILE_AWS_TEST]: clConfig.homolog,
  [MAIN_URL_COLOMBIA_AWS_TEST]: coConfig.homolog,
  [MAIN_URL_MEXICO_AWS_TEST]: mxConfig.homolog,
  // LOCAL STORYBOOK
  [MAIN_URL_BRASIL_STORYBOOK_DEV]: brConfig.dev,
  [MAIN_URL_CHILE_STORYBOOK_DEV]: clConfig.dev,
  [MAIN_URL_COLOMBIA_STORYBOOK_DEV]: coConfig.dev,
  [MAIN_URL_MEXICO_STORYBOOK_DEV]: mxConfig.dev,
  // PRODUCTION STORYBOOK
  [MAIN_URL_BRASIL_STORYBOOK_PROD]: brConfig.dev,
  [MAIN_URL_MEXICO_STORYBOOK_PROD]: mxConfig.dev,
};

const jsonConfig = configByOrigin[window.location.origin];

export const {
  COUNTRY,
  ENVIRONMENT,
  SITE_HG,
  WHMCS_URL,
  AWS_DNS_SERVICE_URL,
  AWS_AHC_URL,
  DATADOG_PUBLIC_CLIENT_KEY,
  CART_URL,
  AMPLITUDE_KEY,
  AWS_GATOR_SOLUTIONS_URL,
  CACHED_AWS_GATOR_SOLUTIONS_URL,
  GROWTHBOOK_CLIENT_KEY,
  ECOMMERCE_RD_TOKEN,
  LINK_NA_BIO_URL,
  CHAT_TOKEN,
  ZENDESK_AUTH_TOKEN,
  ZENDESK_AUTH_KEY,
} = jsonConfig;

export const IS_PROD = ENVIRONMENT === 'production';

const VIMEO_TOKEN = IS_PROD ? 'e16beae5a3dbd4e7175704daa30be829' : 'b49be9c65d5d18c845bbe4201ede99c7';
const PAYPAL_ENVIRONMENT = IS_PROD ? 'production' : 'sandbox';

const CLIENT_ID_PAAS = getClientIdByCountry(COUNTRY);

const PAAS_CONFIG = IS_PROD ? {
  IFRAME_URL: IFRAME_PROD,
  CLIENT_ID: CLIENT_ID_PAAS,
  IFRAME_PAGENAME,
} : {
  IFRAME_URL: IFRAME_SANDBOX,
  CLIENT_ID: CLIENT_ID_PAAS,
  IFRAME_PAGENAME,
};

const BRASPAG_CONFIG = {
  IFRAME_URL_FINGERPRINT,
  MERCHANT_ID: BRASPAG_PROVIDER_MERCHANT_ID,
  ORG_ID: BRASPAG_ORG_ID,
};

export const countrySurvey = {
  br: 1,
  mx: 2,
  cl: 3,
  co: 4,
};

export const wizardCountrySurvey = {
  br: 5,
  mx: 6,
  cl: 7,
  co: 8,
};

export const costumerPortalSurvey = {
  br: 9,
  mx: 10,
  cl: 11,
  co: 12,
};

export const config = {
  LANG: getLangByCountry(COUNTRY),
  CACHE_TIME: 60000,
  GTM: getGTMByCountry(COUNTRY),
  VIMEO_TOKEN,
  AWS_AHC_URL,
  PAYPAL_ENVIRONMENT,
  AWS_DNS_SERVICE_URL,
  PAAS_CONFIG,
  BRASPAG_CONFIG,
  AWS_GATOR_SOLUTIONS_URL,
  CACHED_AWS_GATOR_SOLUTIONS_URL,
  ECOMMERCE_RD_TOKEN,
  SURVEY_CONFIG: getConfigSurveyByCountry(COUNTRY),
  CHAT_TOKEN,
  ZENDESK_AUTH_TOKEN,
  ZENDESK_AUTH_KEY,
  ...jsonConfig,
};

export const APP_VERSION = `Versão ${packageJson.version}`;
