import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const Content = styled.div`
    width: max-content;
`;

export const CheckboxLabel = styled.label`
${({ theme }) => css`
  cursor: pointer;
  display: flex;
  width: max-content;
  @media (max-width: ${theme.v2.breakpoints.md}) {
    width: fit-content;
  }
  >button {
    > label {
      height: 20px;
      width: 20px;
    }
  };
  `}
`;
export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin-left: 2px;
  `}
`;
