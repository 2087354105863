const styles = theme => ({
  statusActive: {
    color: theme.color.oceanGreen,
  },
  statusPending: {
    color: theme.color.brightSun,
  },
  statusPendingTransfer: {
    color: theme.color.brightSun,
  },
  statusExpired: {
    color: theme.color.valencia,
  },
  statusCancelled: {
    color: theme.color.geyser,
  },
  statusFraud: {
    color: theme.color.brightSun,
  },
  statusSuspended: {
    color: theme.color.valencia,
  },
  statusBuilding: {
    color: theme.color.coral,
  },
});

export default styles;
