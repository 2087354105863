import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    padding: 23px 24px 23px 186px;
    position: relative;

    @media (max-width: ${theme.breakpoints.sm}){
      width: 100%;
      padding: 23px 24px;
    }
  `}
`;

export const TrianglesWrapper = styled.div`
  left: 8px;
  position: absolute;
  top: 8px;
`;

export const SnappyImage = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    height: 40px;
    left: 62px;
    position:absolute;
    width: 90px;

    @media (max-width: ${theme.breakpoints.sm}){
      left: 8px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme, maxWidth }) => css`
    color: ${theme.palette.blue.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0;
    max-width: ${maxWidth};

    @media (max-width: ${theme.breakpoints.sm}){
      margin-left: auto;
      max-width: 250px;
      text-align:right;
      width: 100%;
    }
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme, maxWidth }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0;
    max-width: ${maxWidth};

    @media (max-width: ${theme.breakpoints.sm}){
      margin-left: auto;
      max-width: 250px;
      text-align:right;
      width: 100%;
    }
  `}
`;
