/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import {
  changeDnsToNative,
  changeDnsToOther,
  changeDnsToHosting,
  saveDnsOther,
  updateDomainDnsState,
  loadDnsDetails,
  saveDnsNative,
  saveDnsHosting,
} from '@/redux/actions/dnsForward';
import { DNSContext } from '@/contexts/DNS/context';
import { validateDomain } from '@/utils/Validators/validation';
import { forceDnsLoadingStatus } from '@/redux/actions/dnsZoneManager';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import {
  SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS,
  SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS,
  SAVE_CHANGE_TO_DNS_OTHER_SUCCESS,
} from '@/redux/actions/actionsTypes';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import { tagManagerDnsChange } from '@/utils/tagManagerDnsChange';
import OtherBlock from './OtherBlock';
import RadioBlock from './RadioBlock';
import ActionButtons from './ActionButtons';
import HostingBlock from './HostingBlock';
import styles from './styles';
import NativeBlock from './NativeBlock';

const DomainSetupOptions = ({
  classes,
  disabledBtn,
}) => {
  const { details: DNSContextDetails, featureTogglesZoneManager } = useContext(DNSContext);

  const dispatch = useDispatch();
  const details = useSelector(state => state.dnsDetails.details);
  const showHosting = useSelector(state => state.dnsDetails.details.showHostingBox);
  const hostingOptions = useSelector(state => state.dnsDetails.details.availableHostings).map(item => item.name);
  const loadingOther = useSelector(state => state.dnsDetails.loadingOther);
  const loadingNative = useSelector(state => state.dnsDetails.loadingNative);
  const loadingHosting = useSelector(state => state.dnsDetails.loadingHosting);

  const [disabledButtons, setDisabledButtons] = useState(disabledBtn);
  const [hostingSelected, setHostingSelected] = useState('');
  const [hosting, setHosting] = useState({
    id: null,
    master: '',
    slave: '',
  });
  const isEmptyNameSeverOther = ('nameserver' in details) && ('other' in details.nameserver) && details.nameserver.other.length === 0;

  const [arrayOtherDns, setArrayOtherDns] = useState(() => {
    let list = [];

    if (isEmptyNameSeverOther) {
      list = [
        {
          key: shortid.generate(),
          host: '',
          ip: '',
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        },
        {
          key: shortid.generate(),
          host: '',
          ip: '',
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        },
      ];
    }

    if (!isEmptyNameSeverOther) {
      list = details.nameserver.other.map(option => (
        {
          key: shortid.generate(),
          host: option.host,
          ip: option.ip,
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        }
      ));
    }
    return list;
  });

  const clearAll = () => {
    setDisabledButtons(true);
    setHostingSelected('');
    setHosting({
      id: null,
      master: '',
      slave: '',
    });

    let list = [];
    if (isEmptyNameSeverOther) {
      list = [
        {
          key: shortid.generate(),
          host: '',
          ip: '',
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        },
        {
          key: shortid.generate(),
          host: '',
          ip: '',
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        },
      ];
    }
    if (!isEmptyNameSeverOther) {
      list = details.nameserver.other.map(option => (
        {
          key: shortid.generate(),
          host: option.host,
          ip: option.ip,
          showIpField: false,
          validate: {
            valid: true,
            message: '',
          },
        }
      ));
    }
    setArrayOtherDns(list);
  };

  const handleLoadData = () => {
    clearAll(true);

    if (details.hosting) {
      if (details.nameserver.hosting) {
        const hostingSelected = details.availableHostings.find(item => item.id === details.nameserver.hosting.id);

        if (hostingSelected) {
          setDisabledButtons(true);
          setHostingSelected(hostingSelected.name);
          setHosting({
            id: hostingSelected.id,
            master: hostingSelected.master,
            slave: hostingSelected.slave,
          });
        }
      }

      if (!details.dbData.hosting && hostingSelected !== '') {
        setDisabledButtons(false);
      }
    }

    if (details.native) {
      if (!details.dbData.native) {
        setDisabledButtons(false);
      }
    }
  };

  const handleExpand = (clickedValue) => {
    if (clickedValue === 'hosting') {
      dispatch(changeDnsToHosting());
    }

    if (clickedValue === 'other') {
      dispatch(changeDnsToOther());
    }

    if (clickedValue === 'native') {
      dispatch(changeDnsToNative());
    }
  };

  const handleChangeSelectValue = (event) => {
    if (event.target.value === '') {
      return clearAll();
    }

    const hosting = details.availableHostings.find(item => item.name === event.target.value);
    setDisabledButtons(details.nameserver.hosting ? hosting.id === details.nameserver.hosting.id : false);
    setHostingSelected(hosting.name);
    setHosting({
      id: hosting.id,
      master: hosting.master,
      slave: hosting.slave,
    });
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    handleLoadData();

    if (details.dbData.hosting) {
      dispatch(changeDnsToHosting());
    }

    if (details.dbData.other) {
      dispatch(changeDnsToOther());
    }

    if (details.dbData.native) {
      dispatch(changeDnsToNative());
    }
  };

  const handleChangeNs = (index, e) => {
    e.persist();
    const value = e.target.value.replace(/ /g, '');

    const regex = /[^-a-z0-9.]+/;
    if (!regex.test(value)) {
      let showIpField = false;
      const includesDomain = value.includes(details.domainName);

      if (includesDomain) {
        showIpField = true;
      }

      const stateToSave = {
        host: value,
        showIpField,
      };

      const validate = validateDomain(value);

      const newOther = [...arrayOtherDns];
      newOther[index] = {
        ...newOther[index],
        ...stateToSave,
        validate: {
          valid: validate.valid,
          message: validate.message.message || '',
        },
        ip: showIpField ? newOther[index].ip : '',
      };

      setArrayOtherDns(newOther);

      if (newOther[0].host !== '' && newOther[1].host !== '') {
        setDisabledButtons(false);
      }
    }
  };

  const addSlave = () => {
    const count = arrayOtherDns.length;
    if (count < 5) {
      const newKey = {
        key: shortid.generate(),
        host: '',
        ip: '',
        showIpField: false,
        validate: {
          valid: true,
          message: '',
        },
      };

      setArrayOtherDns([...arrayOtherDns, newKey]);
    }
  };

  const handleIpChange = (index, e) => {
    const { value } = e.target;
    const newOther = [...arrayOtherDns];
    newOther[index] = {
      ...newOther[index],
      ip: value,
    };

    setArrayOtherDns(newOther);
  };

  const removeSlave = (index) => {
    setArrayOtherDns(arrayOtherDns.filter((item, i) => i !== index));
    setDisabledButtons(false);
  };

  const handleSave = ({ nameServer, featureTogglesZoneManager }) => {
    dispatch(forceDnsLoadingStatus(true));

    if (nameServer === 'hosting') {
      if (!loadingHosting) {
        dispatch(saveDnsHosting(details.domainId, hosting)).then((response) => {
          const selectedHostingDropdown = details.availableHostings.find(item => item.id === hosting.id);
          const key = selectedHostingDropdown.type === 'reselleraccount' ? 'hostingWhm' : 'hostingCpanel';

          if (response.type === SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS) {
            dispatch(enqueueSnackbar('dnsSetup.warningSuccess', notifierSuccess));
            dispatch(updateDomainDnsState('dnsOk', details.domainId));

            setDisabledButtons(true);

            tagManagerDnsChange(details);
            dispatch(loadDnsDetails(details.domainId)).then(() => {
              if (!featureTogglesZoneManager[key]) {
                dispatch(forceDnsLoadingStatus(false));
              }
            });
            setDisabledButtons(true);
          } else {
            dispatch(enqueueSnackbar('dnsSetup.warningError', notifierError));
            dispatch(forceDnsLoadingStatus(false));
          }
        });
      }
    }

    if (nameServer === 'other') {
      const hasOtherValue = arrayOtherDns[0].host !== '' && arrayOtherDns[1].host !== '';
      if (hasOtherValue && !loadingOther) {
        let data = arrayOtherDns.filter(item => item.host !== '');
        setArrayOtherDns(data);

        data = data.map((item, index) => {
          const {
            validate, showIpField, key, ...rest
          } = item;

          return {
            name: index === 0 ? 'master' : `slave${index}`,
            ...rest,
          };
        });

        const dataObj = {};
        data.forEach((item) => {
          dataObj[item.name] = {
            name: item.name,
            host: item.host,
            ip: item.ip,
          };
        });

        dispatch(saveDnsOther(details.domainId, dataObj)).then((response) => {
          if (response.type === SAVE_CHANGE_TO_DNS_OTHER_SUCCESS) {
            dispatch(enqueueSnackbar('dnsSetup.warningSuccess', notifierSuccess));
            dispatch(updateDomainDnsState('dnsOk', details.domainId));

            setDisabledButtons(true);

            tagManagerDnsChange(details);

            dispatch(loadDnsDetails(details.domainId)).then(() => {
              if (!featureTogglesZoneManager.other) {
                dispatch(forceDnsLoadingStatus(false));
              }
            });
            setDisabledButtons(true);
          } else {
            dispatch(enqueueSnackbar('dnsSetup.warningError', notifierError));
            dispatch(forceDnsLoadingStatus(false));
          }
        });
      } else {
        dispatch(forceDnsLoadingStatus(false));
      }
    }

    if (nameServer === 'native') {
      if (!loadingNative) {
        dispatch(saveDnsNative(details.domainId)).then((response) => {
          if (response.type === SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS) {
            dispatch(enqueueSnackbar('dnsSetup.warningSuccess', notifierSuccess));
            dispatch(updateDomainDnsState('dnsMissing', details.domainId));

            dispatch(loadDnsDetails(details.domainId));

            tagManagerDnsChange(details);
            setDisabledButtons(true);
          } else {
            dispatch(enqueueSnackbar('dnsSetup.warningError', notifierError));
            dispatch(forceDnsLoadingStatus(false));
          }
        });
      }
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [(details && details.hosting), (details && details.other), (details && details.native)]);

  const nameServerOptions = {
    native: DNSContextDetails.native,
    other: DNSContextDetails.other,
  };

  if (showHosting) {
    nameServerOptions.hosting = DNSContextDetails.hosting;
  }

  return (
    <div
      className={classnames(classes.itemsWrapper, {
        [classes.twoChild]: !showHosting,
      })}
    >
      { Object.keys(nameServerOptions).map((nameServer) => {
        const showHostingBlock = nameServer === 'hosting' && DNSContextDetails[nameServer] === true;
        const showOtherBlock = nameServer === 'other' && DNSContextDetails[nameServer] === true;
        const showNativeBlock = nameServer === 'native' && DNSContextDetails[nameServer] === true;

        return (
          <div
            className={classnames(classes.item, {
              [classes.selectedItem]: DNSContextDetails[nameServer],
              [classes.withMargin]: showHosting && nameServer === 'other',
              [classes.withoutMargin]: !showHosting && nameServer === 'other',
            })}
            onClick={() => handleExpand(nameServer)}
            onKeyDown={() => null}
            role="button"
            tabIndex="0"
            key={`${nameServer}Block`}
            data-testid={`${nameServer}Block`}
          >
            <RadioBlock nameServer={nameServer} />

            {showHostingBlock && (
              <HostingBlock
                handleChangeSelectValue={handleChangeSelectValue}
                hosting={hosting}
                hostingOptions={hostingOptions}
                hostingSelected={hostingSelected}
              />
            )}

            {showOtherBlock && (
            <OtherBlock
              arrayOtherDns={arrayOtherDns}
              addSlave={() => addSlave()}
              handleChangeNs={handleChangeNs}
              setDisabledButtons={() => setDisabledButtons(false)}
              handleIpChange={handleIpChange}
              removeSlave={removeSlave}
            />
            )}

            {showNativeBlock && (
            <NativeBlock />
            )}

            {DNSContextDetails[nameServer] && !disabledButtons && (
              <ActionButtons
                handleCancel={handleCancel}
                disabledButtons={disabledButtons}
                handleSave={() => handleSave({ nameServer, featureTogglesZoneManager })}
                loading={loadingHosting || loadingOther || loadingNative}
              />
            )}
          </div>
        );
      })}

    </div>
  );
};

DomainSetupOptions.defaultProps = {
  disabledBtn: true,
};

export default withI18n()(withStyles(styles)((DomainSetupOptions)));
