import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import { CircularProgress, withStyles } from '@material-ui/core';
import {
  getWHMPanelAccess,
  healthcheck,
  loadProductDetail,
} from '@/redux/actions/productDetail';
import { RECEIVE_WHM_PANEL_ACCESS, ERROR_HEALTHCHECK_WHM_PANEL_ACCESS } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import styles from './styles';


const RedirectToWHMPage = ({ classes, t }) => {
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const productLoading = useSelector(state => state.productDetail.loading);
  const productDetail = useSelector(state => state.productDetail.product);
  const productHealthcheck = useSelector(state => state.productDetail.healthcheck);
  const featureToggles = useSelector(state => state.featureToggles.toggles);
  const loadingFeatureToggles = useSelector(state => state.featureToggles.loading);

  useEffect(() => {
    dispatch(loadProductDetail(productId));
    dispatch(healthcheck(productId));
  }, [dispatch, productId]);

  const handleGetWHMPanelAccess = useCallback(
    () => {
      dispatch(getWHMPanelAccess()).then((response) => {
        switch (response.type) {
          case RECEIVE_WHM_PANEL_ACCESS:
            window.location = response.data.url;
            break;
          case ERROR_HEALTHCHECK_WHM_PANEL_ACCESS:
            window.location = response.data;
            break;
          default:
            dispatch(enqueueSnackbar(t('ErrorSSO'), notifierError));
            break;
        }
      });
    },
    [dispatch, t],
  );

  useEffect(() => {
    if (!loadingFeatureToggles) {
      const { generateSSOWhm } = featureToggles && ('portal' in featureToggles) && featureToggles.portal;
      generateSSOWhm && productHealthcheck.heiSuccess && handleGetWHMPanelAccess();

      if (!generateSSOWhm && !productLoading && !!productDetail && ('actions' in productDetail)) {
        window.location = productDetail.actions[0].linkUrl;
      }
    }
  }, [productHealthcheck, productDetail, productLoading, loadingFeatureToggles, featureToggles, handleGetWHMPanelAccess]);

  return (
    <div className={classes.container}>
      <p>{t('redirectPageMessage.WHMPanel')}</p>
      <CircularProgress size={50} data-testid="loader" />
    </div>
  );
};

export default withRouter(withI18n()(withStyles(styles)(RedirectToWHMPage)));
