import React from 'react';
import {
  ASAAS_INTEGRATION_ARTICLE,
  HOW_ACCESS_ASAAS_ARTICLE,
  FIRST_STEP_ASAAS_ARTICLE,
  ASAAS_TAX_ARTICLE,
  QUESTIONS_ABOUT_ASAAS_ARTICLE,
  MORE_CONTENT_ASAAS_ARTICLE,
  ASAAS_HELP,
} from '@/config/urls/supportUrls';

export default {
  banner: {
    title: 'CONTA DIGITAL COMPLETA COM ASAAS',
    newChip: 'NOVO',
    text: {
      0: 'Cobre por ',
      1: 'Pix, boleto, cartões ',
      2: 'e gerencie tudo da sua loja online em um só lugar com as melhores taxas do mercado.',
    },
    buttonLabel: 'Quero saber mais',
  },
  lp: {
    pageTitle: 'Assinatura Asaas',
    firstRow: {
      title: () => (
        <>
          {'Receba dos seus clientes com '}
          <br />
          Boleto, Pix e Cartão de Crédito
        </>
      ),
      description: () => (
        <>
          {'Com a Asaas você da aos seus clientes mais opções de pagamento com as '}
          <b>menores taxas do mercado</b>
          {' e gerencia suas cobranças tudo em '}
          <b>um só lugar.</b>
        </>
      ),
      imageItems: [
        'Cobre com Pix, Boleto e Cartões',
        'Emissão de Notas Fiscais',
        'Crie links para pagamento',
        'Antecipe seus recebíveis',
        'Atendimento 24h com a Asaas',
        'E muito mais',
      ],
      form: {
        texts: {
          titleFirstStep: 'Abra sua conta grátis',
          descriptionFirstStep: () => (
            <>
              {'Informe os dados para '}
              <b>abrir a sua conta no Asaas</b>
              {' em poucos passos:'}
            </>
          ),
          questionDocument: 'Trabalho como:',
          placeholderName: 'Nome completo',
          placeholderMail: 'E-mail',
          placeholderBirthday: 'Data de nascimento',
          placeholderCep: 'CEP',
          placeholderCity: 'Cidade',
          placeholderStreet: 'Rua',
          placeholderDistrict: 'Bairro',
          placeholderNumber: 'Número',
          placeholderComplement: 'Complemento (Opcional)',
          placeholderCompanyType: 'Selecione o tipo da sua empresa:',
          placeholderMonthlyIncome: 'Qual sua renda mensal?',
          labelCPF: 'CPF',
          labelCNPJ: 'CNPJ',
          buttonLabel: 'Continuar',
          acceessAsaas: clickAction => (
            <>
              {'Já tem conta no Asaas? '}
              <a href="https://www.asaas.com/login/" target="_blank" rel="noreferrer" onClick={clickAction}>Fazer login</a>
              .
            </>
          ),
          titleSecondStep: 'Dados principais',
          descriptionSecondStep: 'Agora, informe seus dados de endereço:',
          myCepLabel: 'Não sei meu CEP',
          myCepUrl: 'https://buscacepinter.correios.com.br/app/endereco/index.php',
          backButtonLabel: 'Voltar',
          agreementRadioLabel: 'Declaro que li e concordo com os ',
          agreementLinkLabel: 'Termos de uso do Asaas.',
          agreementUrl: 'https://ajuda.asaas.com/pt-BR/articles/102021-termos-e-condicoes-de-uso',
          successTitle: 'Conta criada com sucesso!',
          successDescription: () => (
            <>
              Nós enviamos para o seu
              <span> e-mail de cadastro </span>
              os dados de acesso para a sua conta, como
              <span> login e senha. </span>
              :)
            </>
          ),
          successButtonLabel: 'Acessar minha conta do Asaas',
          successButtonUrl: 'https://www.asaas.com/login/',
          failedTitle: 'O e-mail já possui uma conta Asaas',
          failedDescription: () => (
            <>
              Notamos que o seu e-mail já possui um cadastro no Asaas. Você pode tentar
              <span> logar na conta já existente </span>
              ou
              <span> refazer o cadastro usando outra conta de e-mail.</span>
            </>
          ),
          failedButtonBackLabel: 'Refazer cadastro',
          failedButtonLoginLabel: 'Logar no site do Asaas',
          articlesTitle: 'Quer saber mais? Confira os conteúdos da Central de Ajuda:',
          articlesTitleHelp: 'Precisa de ajuda? Confira os conteúdos da nossa Central:',
          articles: [
            {
              label: 'Primeiros passos com Asaas',
              link: FIRST_STEP_ASAAS_ARTICLE,
            },
            {
              label: 'Como integrar o Asaas no WooCommerce',
              link: ASAAS_INTEGRATION_ARTICLE,
            },
            {
              label: 'Como acessar o Asaas',
              link: HOW_ACCESS_ASAAS_ARTICLE,
            },
            {
              label: 'Quais são as taxas do Asaas na Hostgator',
              link: ASAAS_TAX_ARTICLE,
            },
            {
              label: 'Principais dúvidas sobre o Asaas',
              link: QUESTIONS_ABOUT_ASAAS_ARTICLE,
            },
          ],
          showMoreArticles: 'Ver mais conteúdos',
          showMoreArticlesURL: MORE_CONTENT_ASAAS_ARTICLE,
          cnjpTypes: {
            mei: {
              label: 'MEI (Micro Empreendedor Individual)',
              value: 'MEI',
            },
            limited: {
              label: 'Empresa Limitada',
              value: 'LIMITED',
            },
            individual: {
              label: 'Empresa Individual',
              value: 'INDIVIDUAL',
            },
            association: {
              label: 'Associação',
              value: 'ASSOCIATION',
            },
          },
        },
        error: {
          emptyData: 'Esse campo é obrigatório',
          invalidData: 'O dado preenchido não corresponde ao padrão necessário para esse campo.',
          connectionErrorValidationPart: 'Já existe uma conta no Asaas',
          conectionError: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
          cepNotFound: 'O CEP informado não retornou seu endereço. Verifique o CEP informado e tente novamente.',
        },
      },
    },
    secondRow: {
      title: 'Suas cobranças com zero mensalidades e sem taxa de emissão ',
      AsaasHG: 'Com Asaas + Hostgator',
      mensality: 'Você não paga nada na mensalidade e taxa de emissão.',
      mainTaxPrice: 'R$ 0',
      tax: {
        pix: () => (
          <>
            <b>Pix</b>
            <span> R$ 1,89 </span>
            por transação recebida
          </>
        ),
        billet: () => (
          <>
            <b>Boleto</b>
            <span> R$ 1,89 </span>
            por transação recebida
          </>
        ),
        creditCard: () => (
          <>
            <b>Cartões </b>
            a partir de
            <span> 2,89%</span>
          </>
        ),
        others: clickAction => (
          <>
            Veja todas as taxas
            {' '}
            <a href="https://suporte.hostgator.com.br/hc/pt-br/articles/10091657649947" target="_blank" rel="noreferrer" onClick={clickAction}>clicando aqui</a>
          </>
        ),
      },
      advantagesTitle: () => (
        <>
          Você tem acesso a taxas especiais na parceria
          <span> Asaas e Hostgator</span>
        </>
      ),
      advantagesText: () => (
        <>
          Aproveite as
          <span> vantagens exclusivas </span>
          para facilitar toda a gestão de cobrança do seu negócio.
        </>
      ),
      buttonLabel: 'Abrir conta grátis',
    },
    thirdRow: {
      title: 'Porque usar o Asaas no meu site',
      benefits: [
        {
          title: 'Ofereça várias formas de pagamento',
          description: 'Personalize as condições de cobrança e venda usando Pix, cartão de débito ou crédito, link de pagamento e boleto bancário.',
        },
        {
          title: 'Gerencie seu dinheiro como e quando quiser',
          description: 'Desfrute dos benefícios de uma conta digital com movimentações grátis todo mês e cartão sem anuidade.',
        },
        {
          title: 'Parcelamento e antecipação de cobranças',
          description: 'Solicite a antecipação dos seus recebíveis e, após a aprovação do pedido, receba o dinheiro em até 2 dias úteis.',
        },
        {
          title: 'Pagamentos recorrentes automatizados',
          description: 'Envie todo mês a fatura para seu cliente de forma automática: notificações por voz, e-mail e SMS para reduzir a inadimplência.',
        },
      ],
      erpTitle: 'Solução completa de gestão do seu negócio (ERP)',
      erpDescription: () => (
        <>
          Cuide do financeiro do seu negócio, crie propostas comerciais, organize o estoque e emita notas fiscais com um único sistema. Com o
          <span> ERP do Asaas </span>
          você centraliza todo o fluxo de trabalho do seu negócio e mantém sua equipe focada em um só objetivo: vender mais. Necessário ser cliente CNPJ.
        </>
      ),
    },
    fourthRow: {
      title: 'Conta digital completa e sem mensalidades com o Asaas',
      description: () => (
        <>
          Uma instituição financeira homologada pelo Banco Central,
          <span> a forma mais rápida e barata </span>
          de gerenciar o financeiro do seu negócio.
        </>
      ),
      buttonLabel: 'Contrate agora',
    },
    modal: {
      createSite: {
        title: 'Estamos criando a sua conta...',
        description: 'Falta pouco para ter a sua conta Asaas!',
      },
    },
  },
  wizard: {
    firstRow: {
      title: () => (
        <>
          {'Receba dos seus clientes com '}
          <br />
          Boleto, Pix e Cartão de Crédito
        </>
      ),
      description: clickAction => (
        <>
          {'Com a Asaas você da aos seus clientes mais opções de pagamento com as '}
          <b>menores taxas do mercado</b>
          {' e gerencia suas cobranças tudo em um só lugar.'}
          <p>
            {'Veja todas as vantagens desse gerenciador de pagamentos acessando a nossa '}
            <a href={ASAAS_HELP} target="_blank" rel="noreferrer" onClick={clickAction}>Central de Ajuda</a>
            .
          </p>
        </>
      ),
      imageItems: [
        <>
          <b>{'Pix '}</b>
          <span>{' R$ 1,89'}</span>
          {' por transação recebida'}
        </>,
        <>
          <b>{'Boleto '}</b>
          <span>{' R$ 1,89'}</span>
          {' por transação recebida'}
        </>,
        <>
          <b>{'Cartões '}</b>
          {'a partir de '}
          <span>{' 2,89%'}</span>
        </>,
        <>
          <b>{'Gere '}</b>
          {' links para pagamento'}
        </>,
        <>
          <b>{'Atendimento '}</b>
          {' 24h no site do Asaas'}
        </>,
        'E muito mais',
      ],
      form: {
        texts: {
          titleFirstStep: 'Abra sua conta grátis',
          descriptionFirstStep: () => (
            <>
              {'Informe os dados para '}
              <b>abrir a sua conta no Asaas</b>
              {' em poucos passos:'}
            </>
          ),
          questionDocument: 'Trabalho como:',
          placeholderName: 'Nome completo',
          placeholderMail: 'E-mail',
          placeholderBirthday: 'Data de nascimento',
          placeholderCep: 'CEP',
          placeholderCity: 'Cidade',
          placeholderStreet: 'Rua',
          placeholderDistrict: 'Bairro',
          placeholderNumber: 'Número',
          placeholderComplement: 'Complemento (Opcional)',
          placeholderCompanyType: 'Selecione o tipo da sua empresa:',
          placeholderMonthlyIncome: 'Qual sua renda mensal?',
          labelCPF: 'CPF',
          labelCNPJ: 'CNPJ',
          buttonLabel: 'Continuar para instalação Asaas',
          acceessAsaas: clickAction => (
            <>
              {'Já tem conta no Asaas? '}
              <a href="https://www.asaas.com/login/" target="_blank" rel="noreferrer" onClick={clickAction}>Fazer login</a>
              .
            </>
          ),
          titleSecondStep: 'Dados principais',
          descriptionSecondStep: 'Agora, informe seus dados de endereço:',
          myCepLabel: 'Não sei meu CEP',
          myCepUrl: 'https://buscacepinter.correios.com.br/app/endereco/index.php',
          backButtonLabel: 'Voltar',
          agreementRadioLabel: 'Declaro que li e concordo com os ',
          agreementLinkLabel: 'Termos de uso do Asaas.',
          agreementUrl: 'https://ajuda.asaas.com/pt-BR/articles/102021-termos-e-condicoes-de-uso',
          successTitle: 'Conta criada com sucesso!',
          successDescription: () => (
            <>
              Nós enviamos para o seu
              <span> e-mail de cadastro </span>
              os dados de acesso para a sua conta, como
              <span> login e senha. </span>
              :)
            </>
          ),
          successButtonLabel: 'Acessar minha conta do Asaas',
          successButtonUrl: 'https://www.asaas.com/login/',
          failedTitle: 'O e-mail já possui uma conta Asaas',
          failedDescription: () => (
            <>
              Notamos que o seu e-mail já possui um cadastro no Asaas. Você pode tentar
              <span> logar na conta já existente </span>
              ou
              <span> refazer o cadastro usando outra conta de e-mail.</span>
            </>
          ),
          failedButtonBackLabel: 'Refazer cadastro',
          failedButtonLoginLabel: 'Logar no site do Asaas',
          articlesTitle: 'Quer saber mais? Confira os conteúdos da Central de Ajuda:',
          articlesTitleHelp: 'Precisa de ajuda? Confira os conteúdos da nossa Central:',
          articles: [
            {
              label: 'Primeiros passos com Asaas',
              link: FIRST_STEP_ASAAS_ARTICLE,
            },
            {
              label: 'Como integrar o Asaas no WooCommerce',
              link: ASAAS_INTEGRATION_ARTICLE,
            },
            {
              label: 'Como acessar o Asaas',
              link: HOW_ACCESS_ASAAS_ARTICLE,
            },
            {
              label: 'Quais são as taxas do Asaas na Hostgator',
              link: ASAAS_TAX_ARTICLE,
            },
            {
              label: 'Principais dúvidas sobre o Asaas',
              link: QUESTIONS_ABOUT_ASAAS_ARTICLE,
            },
          ],
          showMoreArticles: 'Ver mais conteúdos',
          showMoreArticlesURL: MORE_CONTENT_ASAAS_ARTICLE,
          cnjpTypes: {
            mei: {
              label: 'MEI (Micro Empreendedor Individual)',
              value: 'MEI',
            },
            limited: {
              label: 'Empresa Limitada',
              value: 'LIMITED',
            },
            individual: {
              label: 'Empresa Individual',
              value: 'INDIVIDUAL',
            },
            association: {
              label: 'Associação',
              value: 'ASSOCIATION',
            },
          },
        },
        error: {
          emptyData: 'Esse campo é obrigatório',
          invalidData: 'O dado preenchido não corresponde ao padrão necessário para esse campo.',
          connectionErrorValidationPart: 'Já existe uma conta no Asaas',
          conectionError: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
          cepNotFound: 'O CEP informado não retornou seu endereço. Verifique o CEP informado e tente novamente.',
        },
      },
      modal: {
        createSite: {
          title: 'Estamos criando a sua conta...',
          description: 'Falta pouco para ter a sua conta Asaas!',
        },
      },
    },
    createAsaasContinueLabel: 'Finalizar e instalar o Wordpress',
    dontCreateAsaasContinueLabel: 'Não quero criar minha conta Asaas agora',
  },
};
