import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 24px;
`;

export const Amount = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.contrastText}40;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    max-width: 684px;
    padding: 24px;
    position: relative;
  `}
`;

export const AmountTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 18px;
    margin: 0px 0px 20px 0px;
  `}
`;

export const NoRefundWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  `}
`;

export const NoRefundExplanation = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    margin: 0px 20px 0px 0px;
  `}
`;

export const TipWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.contrastText}40;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    margin-top: 24px;
    max-width: 684px;
    padding: 24px;
    position: relative;
  `}
`;

export const Tip = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
  `}
`;

export const TipLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    margin-left: 12px;
  `}
`;

export const Content = styled.div``;

export const TipContent = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-left: 34px;
  `}
`;
