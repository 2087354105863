export const V2Forms = {
  INTERNAL_MIGRATION: 'Migração Interna',
  USER_BACKUP_RESTORE: 'Restauração de backup do cliente',
  RESTORATE_HG_ROUTINE: 'Restaurar Backup - Arquivos da HostGator',
  WITHOUT_EMAIL_ACCESS: 'Não tenho acesso ao e-mail cadastrado',
  CHANGE_PRIMARY_DOMAIN: 'Alteração de Domínio Primário',
  CHANGE_DUE_DATE: 'Alterar Data de Vencimento',
  GOOGLE_ADWORDS_COUPON: 'Cupom Google AdWords',
  EXTERNAL_SSL: 'Instalação de SSL de Outra Empresa',
  FINANCIAL_DOUBTS: 'FINANCIAL_DOUBTS',
  RESTORATE_CANCELLED_PLAN: 'Restauração de Plano Cancelado',
  GENERATE_CSR: 'Gerar CSR',
  RESTORATE_SITEBUILDER_ACCESS: 'Restauração de acesso ao editor do Criador de Sites',
  AFFILIATE_PROGRAM: 'Dúvidas exclusivamente sobre o Programa de Afiliados',
  NATIONAL_DOMAIN_TRANSFER: 'Transferência de Domínio Nacional',
  UPGRADE_VPS: 'Upgrade de Plano',
  UPGRADE_SITELOCK: 'Upgrade de Plano - sitelock',
  UPGRADE_CODEGUARD: 'Upgrade de Plano - codeguard',
  CHANGE_PAYMENT_CYCLE: 'Alterar Ciclo de Pagamento',
  PRIVATE_SSL: 'Certificado SSL Privado',
  WILDCARD_SSL: 'Certificado SSL Wildcard',
  ENTERPRISE_SSL: 'Certificado SSL Empresarial',
  INSTALL_WHMCS: 'Instalação do WHMCS',
  RELOAD_REBUILD: 'Solicitação de Reload/Rebuild',
  MORE_EMAILS: 'Aumento no envio de e-mails',
  MIGRATE_VPS_DEDICATED: 'Migração de Servidor Dedicado e VPS',
  MIGRATE_ACCOUNT_VPS_DEDICATED: 'Migración de Cuenta de VPS/Dedicado',
  UPDATE_WHMCS_LICENSE: 'Atualização de Licença WHMCS',
  WHMCS_ACQUISITION: 'Aquisição Licença WHMCS',
  MIGRATE_RESELLER: 'Migração de Revenda',
  EXTERNAL_MIGRATION: 'Migração Externa',
};
