const styles = theme => ({
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  phoneChangeWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '360px',
    width: '100%',
    '& p': {
      margin: '8px 12px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  passwordInputDescriptionD: {
    marginTop: 20,
  },
  passwordWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 24px',
    maxWidth: '360px',
    width: '100%',
    '& p': {
      margin: '8px 12px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  selectCountryCode: {
    minWidth: 122,
    marginRight: 10,
    height: 56,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 15,
    },
  },

  formText: {
    color: theme.color.regentGray,
  },

  formInput: {
    margin: 0,
  },

  button: {
    marginLeft: 10,
    minWidth: 109,
    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      color: theme.color.tundora,
    },
  },
  wrapperBoxLinks: {
    marginTop: 'auto',
  },
});

export default styles;
