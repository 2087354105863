import myDataBR from './myData.br';
import myDataCL from './myData.cl';
import myDataCO from './myData.co';
import myDataMX from './myData.mx';

const locales = {
  BR: myDataBR,
  CL: myDataCL,
  CO: myDataCO,
  MX: myDataMX,
};

export default locales;
