const styles = theme => ({
  dialog: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 40,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  overidePaperWidthSm: {
    maxWidth: 629,
  },
  wrapper: {
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
  iconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    '& svg': {
      fill: theme.color.tundora,
    },
  },
  title: {
    fontSize: 24,
    lineHeight: '24px',
    marginTop: 25,
    color: theme.color.tundora,
    marginBottom: 5,
  },
  info: {
    textAlign: 'center',
    maxWidth: 270,
    fontSize: 14,
    color: theme.color.gray,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    opacity: 0.5,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.color.gray,
  },
});

export default styles;
