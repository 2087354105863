import styled from 'styled-components';

export const Wrapper = styled.span`
  width: auto;
  position: inherit;
  padding: 10px 8px 8px 8px;
  svg {
    display: block;
  }
`;
