/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { CodeGuardLP } from './CodeGuardLP';
import { codeGuardCtaLocale } from './CodeGuardCta.locale';
import { useHistory } from 'react-router';
import { IconBell, IconCloud, IconBackup } from '@/icons';
import {
  getPaymentMethodsCG, getPrices,
  buyCodeGuard,
} from '@/redux/actions/codeguard';
import { CODEGUARD_PRODUCT_ID } from '@/config/products/codeguard';
import Loader from '@/components/Layout/Loader';
import { codeGuardAnalytics } from '@/analytics/security/codeguard';

const CodeGuardCta = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { prices, paymentMethods } = useSelector(state => state.codeguard.cta);
  const history = useHistory();
  const { redirectToInvoices, invoiceId, paymentMethod } = useSelector(state => state.codeguard.buyFastcheckout);
  const loading = (prices === null) || (paymentMethods === null);

  const [selectedCycle, setSelectedCycle] = useState('annually');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('braspag_credit_1x');
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  useEffect(() => {
    if (redirectToInvoices && invoiceId) {
      history.push(`${t('routes.billing')}${t('routes.unpaid')}/${invoiceId}/${paymentMethod}`);
    }
  }, [t, invoiceId, paymentMethod, redirectToInvoices]);

  const getCodeGuardProps = () => {
    const defaultProps = {
      mainTitle: codeGuardCtaLocale.mainTitle,
      getNowProps: {
        prices,
        setSelectedCycle,
        selectedCycle,
        isLoadingPayment,
        setSelectedPaymentMethod,
        title: codeGuardCtaLocale.getNow.title(),
        description: codeGuardCtaLocale.getNow.description,
        listTitle: codeGuardCtaLocale.getNow.listTitle(),
        featureList: codeGuardCtaLocale.getNow.featureList,
        formTitle: codeGuardCtaLocale.getNow.formTitle,
        selectePaymentLabel: codeGuardCtaLocale.getNow.selectePaymentLabel,
        cycleLabel: codeGuardCtaLocale.getNow.cycleLabel,
        toBePaid: codeGuardCtaLocale.getNow.toBePaid,
        hgCodeguard: codeGuardCtaLocale.getNow.hgCodeguard,
        needMoreLabel: codeGuardCtaLocale.getNow.needMoreLabel,
        needMoreButtonText: codeGuardCtaLocale.getNow.needMoreButtonText,
        codeGuardAnalytics,
      },
      detailsSectionsProps: {
        title: codeGuardCtaLocale.detailsSections.title(),
        firstSectionTitle: codeGuardCtaLocale.detailsSections.firstSection.title(),
        firstSectionDescription: codeGuardCtaLocale.detailsSections.firstSection.description(),
        firstSectionButtonLabel: codeGuardCtaLocale.detailsSections.firstSection.buttonLabel,
        secondSectionTitle: codeGuardCtaLocale.detailsSections.secondSection.title(),
        secondSectionDescription: codeGuardCtaLocale.detailsSections.secondSection.description(),
        secondSectionButtonLabel: codeGuardCtaLocale.detailsSections.secondSection.buttonLabel,
        codeGuardAnalytics,
      },
      discoverBenefitsProps: {
        title: codeGuardCtaLocale.discoverBenefits.title,
        benefits: [
          {
            icon: <IconCloud />,
            id: 0,
            title: codeGuardCtaLocale.discoverBenefits.benefits[0].title,
            description: codeGuardCtaLocale.discoverBenefits.benefits[0].description,
          },
          {
            icon: <IconBackup />,
            id: 1,
            title: codeGuardCtaLocale.discoverBenefits.benefits[1].title,
            description: codeGuardCtaLocale.discoverBenefits.benefits[1].description,
          },
          {
            icon: <IconBell />,
            id: 2,
            title: codeGuardCtaLocale.discoverBenefits.benefits[2].title,
            description: codeGuardCtaLocale.discoverBenefits.benefits[2].description,
          },
        ],
        videoTitle: codeGuardCtaLocale.discoverBenefits.videoTitle,
        videoDescription: codeGuardCtaLocale.discoverBenefits.videoDescription,
        buttonLabel: codeGuardCtaLocale.discoverBenefits.buttonLabel,
        codeGuardAnalytics,
      },
      snappyBannerProps: {
        title: codeGuardCtaLocale.snappyBanner.title,
        buttonLabel: codeGuardCtaLocale.snappyBanner.buttonLabel,
        codeGuardAnalytics,
      },

    };
    const paymentCycles = prices.length && [...prices].map((item) => {
      const newPrice = {
        title: codeGuardCtaLocale.getNow.cycleOptions[item.cycle],
        name: item.cycle,
        featured: item.cycle === 'annually' || false,
      };

      return newPrice;
    });

    const paymentMethodsOptions = paymentMethods.length && [...paymentMethods].map((item) => {
      const newPaymentMethod = {
        label: item[1].description,
        value: item[1].name,
        id: item[1].name,
      };

      return newPaymentMethod;
    });

    const activeCycle = prices.length && [...prices].find(item => item.cycle === selectedCycle);
    activeCycle.per = codeGuardCtaLocale.getNow.cyclePer[selectedCycle];

    const getCodeGuard = () => {
      const payment = paymentMethods.find(item => item[0] === selectedPaymentMethod);
      const fastCheckout = payment[0];

      setIsLoadingPayment(true);
      dispatch(buyCodeGuard({
        productId: CODEGUARD_PRODUCT_ID.br,
        paymentMethod: fastCheckout,
        productCycle: selectedCycle,
      }));
    };

    return {
      ...defaultProps,
      getNowProps: {
        ...defaultProps.getNowProps,
        paymentCycles,
        paymentMethods: paymentMethodsOptions,
        activeCycle,
        getCodeGuard,
      },
    };
  };

  useEffect(() => {
    dispatch(getPaymentMethodsCG());
    dispatch(getPrices());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.wrapper} data-testid="codeguard-cta" id="ancora">
      <CodeGuardLP {...getCodeGuardProps()} />
    </div>
  );
};

export default withI18n()(withStyles({ withTheme: true })(CodeGuardCta));
