import React from 'react';
import * as Styles from './PhoneCard.styles';
import Logo from '@/media/logo/HgOrangeLogo/HgOrangeLogo';
import useLocale from '@/hooks/useLocale';
import {
  Button, IconButton, Input, PhoneInput,
} from 'gatorcomponents';
import { FORM_STEPS } from '../PhoneVerify';
import { Timer } from '@/pages/common/v1';
import { COUNTRY } from '@/config';

const PhoneCard = ({
  canSendForm,
  closePhoneFlow,
  countDownResend,
  featureToggleEmailNumberFlowSkip,
  handleChangePhoneNumber,
  handleSendPhoneCode,
  handleValidateCode,
  loadingChange,
  loadingValidate,
  phoneCardForm,
  setCountDownResend,
  setPhoneCardForm,
  updateField,
  values,
  waitingToResend,
  threatedPhoneNumber,
  renderFlags,
  countryCode,
}) => {
  const { phoneValidation: phoneValidationLocale } = useLocale();

  const handleChangePhone = () => {
    handleChangePhoneNumber && handleChangePhoneNumber();
  };

  const handleGoBack = () => {
    setPhoneCardForm && setPhoneCardForm(FORM_STEPS.CHANGE_PHONE_NUMBER);
  };

  const updatePhoneField = (value, name) => {
    const dataToUpdateField = {
      target: {
        name,
        value,
      },
    };

    updateField(dataToUpdateField);
  };

  return (
    <Styles.Container>
      <Styles.Content>
        {(featureToggleEmailNumberFlowSkip) && (
        <Styles.CloseButtonWrapper>
          <IconButton
            icon="IconClose"
            onClick={closePhoneFlow}
          />
        </Styles.CloseButtonWrapper>
        )}
        <Logo />
        <Styles.Title>
          {phoneValidationLocale.updateNumber}
        </Styles.Title>

        {phoneCardForm === FORM_STEPS.CHANGE_PHONE_NUMBER && (
        <>
          <Styles.Subtitle>
            {phoneValidationLocale.attention}
          </Styles.Subtitle>

          <Styles.ChangeNumberForm>
            <Styles.InputWrapper>
              <PhoneInput
                name="phone"
                key="phone"
                label={phoneValidationLocale.phone}
                inputValue={values.changePhoneNumberInput.value || ''}
                onChangeInput={value => updatePhoneField(value, 'changePhoneNumberInput')}
                onChangeSelect={value => updatePhoneField(value, 'countryCode')}
                selected={countryCode}
                error={values.changePhoneNumberInput.error}
                brand={COUNTRY}
                renderFlags={renderFlags}
              />
            </Styles.InputWrapper>

            <Styles.InputWrapper>
              <Input
                name="password"
                key="password"
                testId="password"
                label={phoneValidationLocale.password}
                placeholder={phoneValidationLocale.typePassword}
                type="password"
                onChange={updateField}
                error={values.password.error}
                value={values.password.value}
              />
            </Styles.InputWrapper>

            <Styles.ChangeNumber>
              <Button
                label={countDownResend > 0 ? <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} /> : phoneValidationLocale.sendCode}
                onClick={handleChangePhone}
                size="large"
                loading={loadingChange}
                disabled={loadingChange || canSendForm() || countDownResend >0}
                testId="submitButton"
              />
            </Styles.ChangeNumber>
          </Styles.ChangeNumberForm>
        </>
        )}

        {phoneCardForm === FORM_STEPS.VERIFICATION_CODE && (
          <>
            <Styles.Subtitle>
              {phoneValidationLocale.codeSended(threatedPhoneNumber)}
            </Styles.Subtitle>

            <Styles.ValidationForm>
              <Styles.InputWrapper>
                <Input
                  name="codeInput"
                  key="codeInput"
                  testId="codeInput"
                  label={phoneValidationLocale.insertCode}
                  placeholder={phoneValidationLocale.codePlaceholder}
                  onChange={updateField}
                  error={values.codeInput.error}
                  value={values.codeInput.value || ''}
                  type="password"
                />
              </Styles.InputWrapper>

              <Button
                label={phoneValidationLocale.buttons.validate}
                onClick={handleValidateCode}
                size="large"
                loading={loadingValidate}
                disabled={loadingValidate}
                testId="codeSubmit"
              />
            </Styles.ValidationForm>

            <Styles.Subtitle boldFont>
              {phoneValidationLocale.notReceive}
            </Styles.Subtitle>

            {countDownResend > 0 && (
              <Styles.Countdown>
                <Styles.Subtitle>
                  {phoneValidationLocale.requestOtherCode}
                </Styles.Subtitle>
                <Styles.Subtitle boldFont>
                  <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
                </Styles.Subtitle>
                .
              </Styles.Countdown>
            )}

            <Styles.ButtonsWrapper>
              <Button
                label={phoneValidationLocale.changePhone}
                onClick={handleGoBack}
                variant="tertiary"
              />
              <Button
                label={phoneValidationLocale.resentCode}
                loading={loadingChange}
                variant="secondary"
                onClick={handleSendPhoneCode}
                disabled={waitingToResend}
              />
            </Styles.ButtonsWrapper>
          </>
        )}
      </Styles.Content>
    </Styles.Container>
  );
};

export default PhoneCard;
