import React from 'react';
import * as Styles from './Card.styles';

function Card({
  children = null,
  variant = 'default',
  unspaced = false,
  hover = false,
  testId = 'card',
  selected = false,
}) {
  return (
    <Styles.Card variant={variant} data-testid={`${testId}`} hover={hover} selected={selected}>
      <Styles.Container variant={variant} unspaced={unspaced} data-testid={`${testId}-inner`} hover={hover}>
        {children}
      </Styles.Container>
    </Styles.Card>
  );
}

export default Card;
