const styles = theme => ({
  linkWrapper: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    height: '28px',
    '& > a': {
      color: theme.color.tundora,
      '&:hover': {
        color: theme.color.tundora,
      },
      '&:focus': {
        color: theme.color.tundora,
      },
      '&:active': {
        color: theme.color.tundora,
      },
    },
  },
});

export default styles;
