import React from 'react';
import { locale } from '@/utils/locale';
import { COUNTRY } from '@/config';

export const sitesBuilderLocale = {
  routeSitesPage: locale('routes.sitesPage'),
  altSnappy: locale('sitesWizard.altSnappy'),
  altColorLine: locale('sitesWizard.altColorLine'),
};

export const wizardLocale = {
  wizardStepConfigurations: locale('sitesWizard.steps.configurations'),
  wizardStepAbout: locale('sitesWizard.steps.about'),
  wizardStepTools: locale('sitesWizard.steps.tools'),
  wizardStepTips: locale('sitesWizard.steps.tips'),
  backButton: locale('sitesWizard.actions.backButton'),
  backHome: locale('sitesWizard.actions.backHome'),
  routeSitesPage: locale('routes.sitesPage'),
  continueButton: locale('sitesWizard.actions.continue'),
  showMore: locale('sitesWizard.actions.showMore'),
  showLess: locale('sitesWizard.actions.showLess'),
  about: {
    title: (
      <>
        {locale('sitesWizard.about.title.0')}
        {<span>{locale('sitesWizard.about.title.1')}</span>}
      </>
    ),
    subtitle: locale('sitesWizard.about.subtitle'),
    question: locale('sitesWizard.about.question'),
    questionTooltip: locale('sitesWizard.about.questionTooltip'),
    defaultOptions: {
      institucionalTitle: locale('sitesWizard.about.institucional.title'),
      institucionalSubtitle: locale('sitesWizard.about.institucional.subtitle'),
      personalTitle: locale('sitesWizard.about.personal.title'),
      blogTitle: locale('sitesWizard.about.blog.title'),
      ecommerceTitle: locale('sitesWizard.about.ecommerce.title'),
      ecommerceSubtitle: locale('sitesWizard.about.ecommerce.subtitle'),
      landingTitle: locale('sitesWizard.about.landing.title'),
      landingSubtitle: locale('sitesWizard.about.landing.subtitle'),
      otherTitle: locale('sitesWizard.about.other.title'),
    },
    objectivesQuestion: locale('sitesWizard.about.objectives.question'),
    objectivesTooltip: locale('sitesWizard.about.objectives.tooltip'),
    objectivesPlaceholder: locale('sitesWizard.about.objectives.placeholder'),
    defaultObjectives: {
      goodSite: locale('sitesWizard.about.objectives.defaultObjectives.goodSite'),
      visibility: locale('sitesWizard.about.objectives.defaultObjectives.visibility'),
      access: locale('sitesWizard.about.objectives.defaultObjectives.access'),
      prospection: locale('sitesWizard.about.objectives.defaultObjectives.prospection'),
      results: locale('sitesWizard.about.objectives.defaultObjectives.results'),
      satisfaction: locale('sitesWizard.about.objectives.defaultObjectives.satisfaction'),
      other: locale('sitesWizard.about.objectives.defaultObjectives.other'),
    },
    othersSitesModal: {
      title: locale('sitesWizard.about.othersSitesModal.title'),
      placeholder: locale('sitesWizard.about.othersSitesModal.placeholder'),
      back: locale('sitesWizard.about.othersSitesModal.back'),
      save: locale('sitesWizard.about.othersSitesModal.save'),
    },
    othersObjectivesModal: {
      title: locale('sitesWizard.about.othersObjectivesModal.title'),
      placeholder: locale('sitesWizard.about.othersObjectivesModal.placeholder'),
      back: locale('sitesWizard.about.othersObjectivesModal.back'),
      save: locale('sitesWizard.about.othersObjectivesModal.save'),
    },
  },
  tools: {
    title: {
      default: locale('sitesWizard.tools.title.0'),
      ecommerce: locale('sitesWizard.tools.title.ecommerce'),
      ecommercePlus: locale('sitesWizard.tools.title.ecommercePlus'),
      institucional: locale('sitesWizard.tools.title.institucional'),
      personal: locale('sitesWizard.tools.title.personal'),
      blog: locale('sitesWizard.tools.title.blog'),
      landing: locale('sitesWizard.tools.title.landing'),
      other: locale('sitesWizard.tools.title.other'),
    },
    subtitle: {
      ecommerce: locale('sitesWizard.tools.subtitle.ecommerce'),
      ecommercePlus: locale('sitesWizard.tools.subtitle.ecommercePlus'),
      institucional: locale('sitesWizard.tools.subtitle.institucional'),
      personal: locale('sitesWizard.tools.subtitle.personal'),
      blog: locale('sitesWizard.tools.subtitle.blog'),
      landing: locale('sitesWizard.tools.subtitle.landing'),
      other: locale('sitesWizard.tools.subtitle.other'),
    },
    wordPressInstall: {
      defaultWordpressValue: locale('sitesWizard.wordPressInstall.defaultWordpressValue'),
      titleActionWoocommerce: (
        <>
          {locale('sitesWizard.wordPressInstall.title.0')}
          <span>{locale('sitesWizard.wordPressInstall.title.actionWoocommerce')}</span>
        </>
      ),
      titleActionWordPress: (
        <>
          {locale('sitesWizard.wordPressInstall.title.0')}
          <span>{locale('sitesWizard.wordPressInstall.title.actionWordPress')}</span>
        </>
      ),
      info: {
        title: locale('sitesWizard.wordPressInstall.info.title'),
        description: locale('sitesWizard.wordPressInstall.info.description'),
        questions: {
          name: {
            label: locale('sitesWizard.wordPressInstall.info.questions.name.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.name.placeholder'),
            tooltip: locale('sitesWizard.wordPressInstall.info.questions.name.tooltip'),
            errorMessage: locale('sitesWizard.wordPressInstall.info.questions.name.errorMessage'),
          },
          description: {
            label: locale('sitesWizard.wordPressInstall.info.questions.description.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.description.placeholder'),
            tooltip: locale('sitesWizard.wordPressInstall.info.questions.description.tooltip'),
          },
          email: {
            label: locale('sitesWizard.wordPressInstall.info.questions.email.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.email.placeholder'),
            errorMessage: locale('sitesWizard.wordPressInstall.info.questions.email.errorMessage'),
          },
          user: {
            label: locale('sitesWizard.wordPressInstall.info.questions.user.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.user.placeholder'),
            errorMessage: locale('sitesWizard.wordPressInstall.info.questions.user.errorMessage'),
            errorMessageWithWondersuite: locale('sitesWizard.wordPressInstall.info.questions.user.errorMessageWithWondersuite'),
          },
          password: {
            label: locale('sitesWizard.wordPressInstall.info.questions.password.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.password.placeholder'),
          },
          confirmPassword: {
            label: locale('sitesWizard.wordPressInstall.info.questions.confirmPassword.label'),
            placeholder: locale('sitesWizard.wordPressInstall.info.questions.confirmPassword.placeholder'),
          },
        },
        nextStepButton: locale('sitesWizard.wordPressInstall.info.nextStepButton'),
      },
      passwordChecklistTexts: {
        description: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.description'),
        hasLowerAndUpperCase: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.hasLowerAndUpperCase'),
        hasNumber: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.hasNumber'),
        hasSymbol: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.hasSymbol'),
        hasAtLeastEight: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.hasAtLeastEight'),
        matchConfirmation: locale('sitesWizard.wordPressInstall.info.passwordChecklistTexts.matchConfirmation'),
      },
      userChecklistTexts: {
        description: locale('sitesWizard.wordPressInstall.info.userChecklistTexts.description'),
        hasAtLeastEight: locale('sitesWizard.wordPressInstall.info.userChecklistTexts.hasAtLeastEight'),
        hasAtLeastFour: locale('sitesWizard.wordPressInstall.info.userChecklistTexts.hasAtLeastFour'),
        notHaveSymbol: locale('sitesWizard.wordPressInstall.info.userChecklistTexts.notHaveSymbol'),
      },
      subtitle: locale('sitesWizard.wordPressInstall.subtitle'),
      theme: {
        title: locale('sitesWizard.wordPressInstall.theme.title'),
        description: locale('sitesWizard.wordPressInstall.theme.description'),
        tooltip: locale('sitesWizard.wordPressInstall.theme.tooltip'),
        see: locale('sitesWizard.wordPressInstall.theme.see'),
        use: locale('sitesWizard.wordPressInstall.theme.use'),
        selected: locale('sitesWizard.wordPressInstall.theme.selected'),
        nextStepButton: locale('sitesWizard.wordPressInstall.theme.nextStepButton'),
      },
      plugin: {
        title: (
          <>
            {locale('sitesWizard.wordPressInstall.plugin.title')}
            <span>{locale('sitesWizard.wordPressInstall.plugin.optional')}</span>
          </>
        ),
        description: locale('sitesWizard.wordPressInstall.plugin.description'),
        tooltip: locale('sitesWizard.wordPressInstall.plugin.tooltip'),
        use: locale('sitesWizard.wordPressInstall.plugin.use'),
        selected: locale('sitesWizard.wordPressInstall.plugin.selected'),
        nextStepButton: locale('sitesWizard.wordPressInstall.plugin.nextStepButton'),
        nextStepButtonWithAsaas: locale('sitesWizard.wordPressInstall.plugin.nextStepButtonWithAsaas'),
      },
      asaas: {
        title: (
          <>
            {locale('sitesWizard.wordPressInstall.asaas.title')}
            <span>{locale('sitesWizard.wordPressInstall.asaas.optional')}</span>
          </>
        ),
        description: locale('sitesWizard.wordPressInstall.asaas.description'),
      },
      installModal: locale('sitesWizard.wordPressInstall.installModal'),
    },
    wondersuite: {
      title: locale('sitesWizard.wondersuite.title'),
      features: {
        items: [
          {
            icon: locale('sitesWizard.wondersuite.features.items.0.icon'),
            title: locale('sitesWizard.wondersuite.features.items.0.title'),
            description: locale('sitesWizard.wondersuite.features.items.0.description'),
          },
          {
            icon: locale('sitesWizard.wondersuite.features.items.1.icon'),
            title: locale('sitesWizard.wondersuite.features.items.1.title'),
            description: locale('sitesWizard.wondersuite.features.items.1.description'),
          },
          {
            icon: locale('sitesWizard.wondersuite.features.items.2.icon'),
            title: locale('sitesWizard.wondersuite.features.items.2.title'),
            description: locale('sitesWizard.wondersuite.features.items.2.description'),
          },
          {
            icon: locale('sitesWizard.wondersuite.features.items.3.icon'),
            title: locale('sitesWizard.wondersuite.features.items.3.title'),
            description: locale('sitesWizard.wondersuite.features.items.3.description'),
          },
          {
            icon: locale('sitesWizard.wondersuite.features.items.4.icon'),
            title: locale('sitesWizard.wondersuite.features.items.4.title'),
            description: locale('sitesWizard.wondersuite.features.items.4.description'),
          },
          {
            icon: locale('sitesWizard.wondersuite.features.items.5.icon'),
            title: locale('sitesWizard.wondersuite.features.items.5.title'),
            description: locale('sitesWizard.wondersuite.features.items.5.description'),
          },
        ],
      },
      tools: {
        title: locale('sitesWizard.wondersuite.tools.title'),
      },
      wondersuiteHeroBanner: {
        title: () => (
          <>
            {locale('sitesWizard.wondersuite.wondersuiteHeroBanner.title.0')}
            <span>{locale('sitesWizard.wondersuite.wondersuiteHeroBanner.title.1')}</span>
            {locale('sitesWizard.wondersuite.wondersuiteHeroBanner.title.2')}
          </>
        ),
        description: locale('sitesWizard.wondersuite.wondersuiteHeroBanner.description'),
        tagLabel: locale('sitesWizard.wondersuite.wondersuiteHeroBanner.tagLabel'),
        btnLabel: locale('sitesWizard.wondersuite.wondersuiteHeroBanner.btnLabel'),
        btnGA: locale('sitesWizard.wondersuite.wondersuiteHeroBanner.btnGA'),
        assignValue: () => (
          <>
            {locale('sitesWizard.wondersuite.wondersuiteHeroBanner.assignValue.0')}
            <span>{locale('sitesWizard.wondersuite.wondersuiteHeroBanner.assignValue.1')}</span>
            {locale('sitesWizard.wondersuite.wondersuiteHeroBanner.assignValue.2')}
          </>
        ),
        wordpressExperience: locale('sitesWizard.wondersuite.wondersuiteHeroBanner.wordpressExperience'),
      },
    },
    woocommerce: {
      title: locale('sitesWizard.tools.woocommerce.title'),
      titleText: locale('sitesWizard.tools.woocommerce.titleText'),
      items: [
        locale('sitesWizard.tools.woocommerce.items.0'),
        locale('sitesWizard.tools.woocommerce.items.1'),
        locale('sitesWizard.tools.woocommerce.items.2'),
        locale('sitesWizard.tools.woocommerce.items.3'),
        locale('sitesWizard.tools.woocommerce.items.4'),
        locale('sitesWizard.tools.woocommerce.items.5'),
      ],
      warning: locale('sitesWizard.tools.woocommerce.warning'),
      action: locale('sitesWizard.tools.woocommerce.action'),
    },
    wordpress: {
      title: locale('sitesWizard.tools.wordpress.title'),
      titleText: locale('sitesWizard.tools.wordpress.titleText'),
      description: locale('sitesWizard.tools.wordpress.description'),
      items: [
        locale('sitesWizard.tools.wordpress.items.0'),
        locale('sitesWizard.tools.wordpress.items.1'),
        locale('sitesWizard.tools.wordpress.items.2'),
        locale('sitesWizard.tools.wordpress.items.3'),
        locale('sitesWizard.tools.wordpress.items.4'),
      ],
      warning: locale('sitesWizard.tools.wordpress.warning'),
      action: locale('sitesWizard.tools.wordpress.action'),
    },
    wizard: {
      title: locale('sitesWizard.tools.wizard.title'),
      titleText: locale('sitesWizard.tools.wizard.titleText'),
      items: [
        locale('sitesWizard.tools.wizard.items.0'),
        locale('sitesWizard.tools.wizard.items.1'),
        locale('sitesWizard.tools.wizard.items.2'),
        locale('sitesWizard.tools.wizard.items.3'),
        locale('sitesWizard.tools.wizard.items.4'),
      ],
      action: locale('sitesWizard.tools.wizard.action'),
    },
    otherCommerce: {
      title: locale('sitesWizard.tools.otherCommerce.title'),
      titleText: locale('sitesWizard.tools.otherCommerce.titleText'),
      description: locale('sitesWizard.tools.otherCommerce.description'),
      items: [
        locale('sitesWizard.tools.otherCommerce.items.0'),
        locale('sitesWizard.tools.otherCommerce.items.1'),
      ],
      otherCommerceOption: locale('sitesWizard.tools.otherCommerce.otherIconLabel'),
      warning: locale('sitesWizard.tools.otherCommerce.warning'),
      action: locale('sitesWizard.tools.otherCommerce.action'),
    },
    otherSite: {
      title: locale('sitesWizard.tools.otherSite.title'),
      titleText: locale('sitesWizard.tools.otherSite.titleText'),
      description: locale('sitesWizard.tools.otherSite.description'),
      items: [
        locale('sitesWizard.tools.otherSite.items.0'),
        locale('sitesWizard.tools.otherSite.items.1'),
      ],
      otherSiteOption: locale('sitesWizard.tools.otherSite.otherIconLabel'),
      warning: locale('sitesWizard.tools.otherSite.warning'),
      action: locale('sitesWizard.tools.otherSite.action'),
    },
    cardIcons: {
      joomla: 'Joomla',
      drupal: 'Drupal',
      moodle: 'Moodle',
      flarum: 'Flarum',
      magento: 'Magento',
      openCart: 'Opencart',
      others: locale('sitesWizard.tools.icons.other'),
    },
  },
  configurations: {
    modalSelectDnsManualStep2: {
      title: locale('sitesWizard.modalSelectDnsManualStep2.title'),
      description: locale('sitesWizard.modalSelectDnsManualStep2.description'),
      tutorialLink: locale('sitesWizard.modalSelectDnsManualStep2.tutorialLink'),
      replace: locale('sitesWizard.modalSelectDnsManualStep2.replace'),
      actualDns: locale('sitesWizard.modalSelectDnsManualStep2.actualDns'),
      hgDns: locale('sitesWizard.modalSelectDnsManualStep2.hgDns'),
      server1: locale('sitesWizard.modalSelectDnsManualStep2.server1'),
      server2: locale('sitesWizard.modalSelectDnsManualStep2.server2'),
      goToHost: locale('sitesWizard.modalSelectDnsManualStep2.goToHost'),
      waitTime: locale('sitesWizard.modalSelectDnsManualStep2.waitTime'),
      waitTimeStrong: locale('sitesWizard.modalSelectDnsManualStep2.waitTimeStrong'),
      warning: locale('sitesWizard.modalSelectDnsManualStep2.warning'),
      labelButtonBack: locale('sitesWizard.modalSelectDnsManualStep2.labelButtonBack'),
      labelButtonContinue: locale('sitesWizard.modalSelectDnsManualStep2.labelButtonContinue'),
      copied: locale('sitesWizard.modalSelectDnsManualStep2.copied'),
    },
    modalAnotherDomain: {
      title: locale('sitesWizard.useAnotherDomain.title'),
      subTitle: locale('sitesWizard.useAnotherDomain.subtitle'),
      placeholder: locale('sitesWizard.useAnotherDomain.placeholder'),
      buttonLabel: locale('sitesWizard.useAnotherDomain.buttonLabel'),
      messageErrorBr: locale('sitesWizard.useAnotherDomain.messageErrorBr'),
      messageError: locale('sitesWizard.useAnotherDomain.messageError'),
      tldMessageError: locale('sitesWizard.useAnotherDomain.tldMessageError'),
    },
    modalChoosePlan: {
      title: locale('sitesWizard.modalChangePlan.title'),
      subtitle: locale('sitesWizard.modalChangePlan.subtitle'),
      tooltip: locale('sitesWizard.modalChangePlan.tooltip'),
      mainDomain: locale('sitesWizard.modalChangePlan.mainDomain'),
      otherDomains: locale('sitesWizard.modalChangePlan.otherDomains'),
      noOtherDomains: locale('sitesWizard.modalChangePlan.noOtherDomains'),
      domains: locale('sitesWizard.modalChangePlan.domains'),
      domain: locale('sitesWizard.modalChangePlan.domain'),
      choosePlan: locale('sitesWizard.modalChangePlan.choosePlan'),
    },
    modalSelectDnsManual: {
      title: locale('sitesWizard.modalSelectDnsManual.title'),
      descriptionOne: locale('sitesWizard.modalSelectDnsManual.descriptionOne'),
      descriptionTwo: locale('sitesWizard.modalSelectDnsManual.descriptionTwo'),
      question: locale('sitesWizard.modalSelectDnsManual.question'),
      placeholder: locale('sitesWizard.modalSelectDnsManual.placeholder'),
      labelButtonBack: locale('sitesWizard.modalSelectDnsManual.labelButtonBack'),
      labelButtonKeep: locale('sitesWizard.modalSelectDnsManual.labelButtonKeep'),
    },
    modalNotRegistered: {
      title: locale('sitesWizard.modalNotRegistered.title'),
      domain: locale('sitesWizard.modalNotRegistered.domain'),
      noRegister: locale('sitesWizard.modalNotRegistered.noRegister'),
      registerNow: locale('sitesWizard.modalNotRegistered.registerNow'),
      otherDomain: locale('sitesWizard.modalNotRegistered.otherDomain'),
      wishKeep: locale('sitesWizard.modalNotRegistered.wishKeep'),
      notAvailable: locale('sitesWizard.modalNotRegistered.notAvailable'),
      backButton: locale('sitesWizard.modalNotRegistered.backButton'),
      continueButton: locale('sitesWizard.modalNotRegistered.continueButton'),
    },
    modalRegistered: {
      title: locale('sitesWizard.modalRegistered.title'),
      domain: locale('sitesWizard.modalRegistered.domain'),
      registered: locale('sitesWizard.modalRegistered.registered'),
      isCorrect: locale('sitesWizard.modalRegistered.isCorrect'),
      continue: locale('sitesWizard.modalRegistered.continue'),
      incorrect: locale('sitesWizard.modalRegistered.incorrect'),
      backButton: locale('sitesWizard.modalRegistered.backButton'),
      continueButton: locale('sitesWizard.modalRegistered.continueButton'),
    },
    title: locale('sitesWizard.configurationStep.title'),
    titleStrong: locale('sitesWizard.configurationStep.titleStrong'),
    text: locale('sitesWizard.configurationStep.text'),
    selectText: locale('sitesWizard.configurationStep.selectText'),
    selectPlaceholder: locale('sitesWizard.configurationStep.selectPlaceholder'),
    wishOtherDomain: locale('sitesWizard.configurationStep.wishOtherDomain'),
    selectedPlan: locale('sitesWizard.configurationStep.selectedPlan'),
    choosePlan: locale('sitesWizard.configurationStep.choosePlan'),
    mainDomain: locale('sitesWizard.configurationStep.mainDomain'),
    continue: locale('sitesWizard.configurationStep.continue'),
    tooltipReplace: locale('sitesWizard.configurationStep.tooltipReplace'),
    tooltipSinglePlan: locale('sitesWizard.configurationStep.tooltipSinglePlan'),
    planLimit: {
      title: locale('sitesWizard.planLimit.title'),
      text: locale('sitesWizard.planLimit.text'),
      changePlan: locale('sitesWizard.planLimit.changePlan'),
      buyOtherPlan: locale('sitesWizard.planLimit.buyOtherPlan'),
    },
    hirePlanTitle: locale('sitesWizard.configurationStep.hirePlanTitle'),
    hirePlanSubtitle: locale('sitesWizard.configurationStep.hirePlanSubtitle'),
    hirePlanCta: locale('sitesWizard.configurationStep.hirePlanCta'),
    registerDomainTitle: locale('sitesWizard.configurationStep.registerDomainTitle'),
    registerDomainSubtitle: locale('sitesWizard.configurationStep.registerDomainSubtitle'),
    registerDomainCta: locale('sitesWizard.configurationStep.registerDomainCta'),
  },
  tips: {
    siteBuilder: {
      baseUrl: locale('routes.siteBuilderRedirectAccess'),
      title: locale('sitesWizard.tips.createSites.title'),
      buttonLabel: locale('sitesWizard.tips.createSites.buttonLabel'),
      subtitle: {
        0: locale('sitesWizard.tips.createSites.subtitleOne'),
        1: locale('sitesWizard.tips.createSites.subtitleTwo'),
      },
      tooltip: locale('sitesWizard.tips.createSites.tooltip'),
    },
    pageWordPress: {
      title: locale('sitesWizard.tips.wordpress.title'),
      buttonLabel: locale('sitesWizard.tips.wordpress.buttonLabel'),
      subtitle: locale('sitesWizard.tips.wordpress.subtitle'),
      subtitleOne: locale('sitesWizard.tips.wordpress.subtitleOne'),
      subtitleTwo: locale('sitesWizard.tips.wordpress.subtitleTwo'),
      tooltip: locale('sitesWizard.tips.wordpress.tooltip'),
      routeSitesPages: locale('routes.sitesPage'),
    },
    softaculous: {
      title: locale('sitesWizard.tips.softaculous.title'),
      subtitleOne: locale('sitesWizard.tips.softaculous.subtitleOne'),
      subtitleTwo: locale('sitesWizard.tips.softaculous.subtitleTwo'),
      tooltip: locale('sitesWizard.tips.softaculous.tooltip'),
    },
    asaasLoginBanner: {
      title: locale('sitesWizard.tips.asaasLoginBanner.title'),
      description: locale('sitesWizard.tips.asaasLoginBanner.description'),
      button: locale('sitesWizard.tips.asaasLoginBanner.button'),
    },
  },
  modalDns: {
    title: locale('modalDnsAutomatic.title'),
    descriptionOne: {
      0: locale('modalDnsAutomatic.descriptionOne.0'),
      1: <strong>{locale('modalDnsAutomatic.descriptionOne.1')}</strong>,
      2: locale('modalDnsAutomatic.descriptionOne.2'),
    },
    descriptionTwo: {
      0: locale('modalDnsAutomatic.descriptionTwo.0'),
      1: <strong>{locale('modalDnsAutomatic.descriptionTwo.1')}</strong>,
    },
    link: locale('modalDnsAutomatic.descriptionOne.3'),
    button: locale('modalDnsAutomatic.button'),
  },
  faqContent: {
    title: locale('help.contentHelpCenter'),
    button: locale('help.seeMoreResults'),
    buttonRedirectWordPress: locale('help.buttonRedirectWordPress'),
    buttonRedirectSitesBuilder: locale('help.buttonRedirectSitesBuilder'),
    buttonRedirectSoftaculos: locale('help.buttonRedirectSoftaculos'),
  },
  modalOverwrite: {
    title: locale('sitesWizard.modalOverwrite.title'),
    warningDomain: domain => locale('sitesWizard.modalOverwrite.warningDomain')(domain),
    warningLink: handleClickMaterials => locale('sitesWizard.modalOverwrite.warningLink')(handleClickMaterials),
    checkboxLabel: locale('sitesWizard.modalOverwrite.checkboxLabel'),
    continueButtonLabel: locale('sitesWizard.modalOverwrite.continueButtonLabel'),
    backButtonLabel: locale('sitesWizard.modalOverwrite.backButtonLabel'),
  },
  modalVerifiedOverwrite: {
    title: locale('sitesWizard.modalVerifiedOverwrite.title'),
    warningDomain: domain => locale('sitesWizard.modalVerifiedOverwrite.warningDomain')(domain),
    warningLink: handleClickMaterials => locale('sitesWizard.modalVerifiedOverwrite.warningLink')(handleClickMaterials),
    warningDomainWithDns: domain => locale('sitesWizard.modalVerifiedOverwrite.warningDomainWithDns')(domain),
    warningAboutDns: locale('sitesWizard.modalVerifiedOverwrite.warningAboutDns'),
    checkboxLabel: locale('sitesWizard.modalVerifiedOverwrite.checkboxLabel'),
    continueButtonLabel: locale('sitesWizard.modalVerifiedOverwrite.continueButtonLabel'),
    backButtonLabel: locale('sitesWizard.modalVerifiedOverwrite.backButtonLabel'),
    error: locale('sitesWizard.modalVerifiedOverwrite.error'),
  },
  asaasBanner: {
    title: locale('sitesWizard.asaasBanner.title'),
    chip: locale('sitesWizard.asaasBanner.chip'),
    description: () => (
      <>
        {locale('sitesWizard.asaasBanner.description.0')}
        <span>{locale('sitesWizard.asaasBanner.description.1')}</span>
        {locale('sitesWizard.asaasBanner.description.2')}
      </>
    ),
    buttonLabel: locale('sitesWizard.asaasBanner.buttonLabel'),
    asaasRoute: locale('routes.asaas'),
  },
};

const countryFlow = {
  br: 'Brasil',
  mx: 'México',
  cl: 'Chile',
  co: 'Colômbia',
};

export const surveyCountry = countryFlow[COUNTRY];
