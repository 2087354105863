import styled, { css } from 'styled-components';

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    margin: 26px 0px 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      flex-direction: row-reverse;
      flex-wrap: nowrap;
    }
  `}
`;

export const Logo = styled.img`
  max-width:140px;
`;

export const CustomTag = styled.span`
  ${({ theme }) => css`
    background-color:${theme.v2.colors.feedback.attentionLight};
    border-radius: 50vh;
    color:${theme.v2.colors.brand.primaryExtradark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    padding: 0px 20px;
  `}
`;

export const TitleWrapper = styled.h2`
  ${({ theme }) => css`
    align-items: center;
    color:${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.regular};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px auto 0px;
    text-align:center;

    @media(min-width:${theme.v2.breakpoints.sm}){
      font-size: ${theme.v2.font.sizes.md};
    }
  `}
`;

export const DescriptionWrapper = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color:${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 4px auto 0px;
    max-width: 736px;
    text-align:center;

    @media(min-width:${theme.v2.breakpoints.sm}){
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    margin: 40px 0px 0px;
    padding: 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      display: inline-flex;
      flex-direction:row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    align-items: center;
    color:${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    font-weight: ${theme.v2.font.weights.bold};
    gap: 4px;
    justify-content: center;
    max-width: 234px;

    svg {
      flex: 0 0 24px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 40px 0px 0px;
`;
