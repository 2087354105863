export const STATUS_TYPE = {
  SUSPENDED: 'isSuspended',
  OVERDUE: 'isOverDue',
  TODUE: 'isToDue',
  ANALYSIS: 'isInAnalysis',
  PENDING: 'isPending',
  ACTIVE: 'active',
};

export const PROPAGATION_STATUS_TYPE = {
  EXTERNAL_NOT_CONFIGURED: 'isExternalNotConfigured',
  INTERNAL_NOT_CONFIGURED: 'isInternalNotConfigured',
  IN_PROPAGATION: 'isInPropagation',
  PROPAGATED: 'isPropagated',
};

export const SSL_STATUS = {
  NOT_ACTIVE: 'NOT_ACTIVE',
  ACTIVE: 'ACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  ACTIVATION_FAILED: 'ACTIVATION_FAILED',
};

export const SITELOCK_STATUS = {
  NOT_ACTIVE: 'not_active',
  ACTIVE: 'active',
};

export const AUTO_SSL_STATUS = {
  START_SUCCESS: 'START_SUCCESS',
  START_FAILURE: 'START_FAILURE',
  PROGRESS_FAILURE: 'PROGRESS_FAILURE',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAILURE: 'CHECK_FAILURE',
  REGISTERED: 'registered',
  NOT_REGISTERED: 'not_registered',
  CAN_INSTALL_SSL: 'can_install_ssl',
  EXTERNAL_NS: 'external_ns',
  CAN_CHANGE_A_RECORD: 'can_change_a_record',
};

export const STATUS_ALERTS_VARIANTS = {
  financial: {
    [STATUS_TYPE.PENDING]: 'timed',
    [STATUS_TYPE.ANALYSIS]: 'timed',
    [STATUS_TYPE.TODUE]: 'warning',
    [STATUS_TYPE.OVERDUE]: 'error',
    [STATUS_TYPE.SUSPENDED]: 'error',
  },
  propagation: {
    [PROPAGATION_STATUS_TYPE.EXTERNAL_NOT_CONFIGURED]: 'warning',
    [PROPAGATION_STATUS_TYPE.INTERNAL_NOT_CONFIGURED]: 'warning',
    [PROPAGATION_STATUS_TYPE.IN_PROPAGATION]: 'timed',
    [PROPAGATION_STATUS_TYPE.PROPAGATED]: 'success',
  },
  ssl: {
    [SSL_STATUS.ACTIVATION_FAILED]: 'error',
    [SSL_STATUS.ACTIVE]: 'success',
    [SSL_STATUS.IN_PROGRESS]: 'timed',
    [SSL_STATUS.NOT_ACTIVE]: 'warning',
  },
  sitelock: {
    [SITELOCK_STATUS.ACTIVE]: 'success',
    [SITELOCK_STATUS.NOT_ACTIVE]: 'info',
  },
};
