import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core/Typography';
import { withStyles, Card } from '@material-ui/core';
import ReCaptcha from '@/components/ReCaptcha';
import PageTitle from '@/components/PageTitle';
import Loader from '@/components/Layout/Loader';
import { CreditCardPreview } from '@/components/CreditCard/CreditCardPreview';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import { loadCreditCard, removeCreditCard } from '@/redux/actions/creditCard';
import { config } from '@/config';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import { DELETE_CREDIT_CARD_ERROR, RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD } from '@/redux/actions/actionsTypes';
import { CHARLIE_CHANGE_PAYMENT_METHOD_MODAL } from '@/config/GrowthBook/constants';

import { enqueueSnackbar } from '@/redux/actions/notifications';
import CreditCardPaas from '@/components/CreditCard/CreditCardPaas';
import styles from './styles';
import PageMessages from '../common/PageMessages';
import { useDispatch, useSelector } from 'react-redux';
import { locale } from '@/utils/locale';
import { ChangePaymentModal } from './components/ChangePaymentModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FLOW_ORIGIN, MODAL_STEPS } from './components/ChangePaymentModal/ChangePaymentModal.types';

const CreditCardPage = ({ classes }) => {
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [successLoadCreditCard, setSuccessLoadCreditCard] = useState(false);
  const [displayChangePaymentModal, setDisplayChangePaymentModal] = useState(false);

  const dispatch = useDispatch();
  const creditCard = useSelector(state => state.creditCard);
  const billingState = useSelector(state => state.billing);
  const enabledChangePaymentMethodModal = useFeatureIsOn(CHARLIE_CHANGE_PAYMENT_METHOD_MODAL);

  const [modalStep, setModalStep] = useState(MODAL_STEPS.SUCCESS_CC_REGISTER);

  const itemsToChangePaymentMethod = billingState.subscriptions.itemsNotInCreditcard;

  useEffect(() => {
    loadReCaptcha();
    dispatch(loadCreditCard());
  }, [dispatch]);

  const verifyCallbackTokenreCaptcha = (token) => {
    !creditCard.loading && setRecaptchaToken(token);
  };

  const hideChangePaymentModal = () => {
    setDisplayChangePaymentModal(false);
    setSuccessLoadCreditCard(false);
  };

  const loaderCreditCard = (type) => {
    if (type === RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD) {
      setModalStep(MODAL_STEPS.SUCCESS_CC_REGISTER);
      dispatch(loadCreditCard());
      dispatch(enqueueSnackbar('snackbar.creditCard.create', notifierSuccess));
      !billingState.subscriptions.loadingItemsNotInCreditCard && setSuccessLoadCreditCard(true);
      return true;
    }

    dispatch(enqueueSnackbar('snackbar.creditCard.create_error', notifierError));
  };

  const handleRemoveCreditCard = () => {
    dispatch(removeCreditCard(recaptchaToken)).then((action) => {
      if (action.type === DELETE_CREDIT_CARD_ERROR) {
        dispatch(enqueueSnackbar('snackbar.creditCard.remove_error', notifierError));
      } else {
        dispatch(enqueueSnackbar('snackbar.creditCard.remove', notifierSuccess));
      }
    });
  };

  const getChangePaymentModalProps = () => {
    const defaultProps = {
      origin: FLOW_ORIGIN.CREDIT_CARD,
      hideModal: hideChangePaymentModal,
      productsList: itemsToChangePaymentMethod,
      loadingChangePaymentMethod: billingState.subscriptions.loadingUpdateItemsNotInCreditCard,
      statusChangePaymentMethod: billingState.subscriptions.updateItemsNotInCreditCardStatus,
      step: modalStep,
    };

    return {
      ...defaultProps,
    };
  };

  const enableChangePaymentModal = useCallback(() => {
    if (successLoadCreditCard && enabledChangePaymentMethodModal && !!itemsToChangePaymentMethod.length) {
      setDisplayChangePaymentModal(true);
    }
  }, [enabledChangePaymentMethodModal, itemsToChangePaymentMethod, successLoadCreditCard]);

  useEffect(() => {
    enableChangePaymentModal();
  }, [enableChangePaymentModal]);

  if (creditCard.loading) {
    return <Loader classesToProps={classes.handleLoader} />;
  }

  return (
    <>
      <PageMessages
        displayInvoiceMessage={false}
        displayDomainAlertManager={false}
      />

      <ContentContainer className={classes.container}>
        <Card className={classes.creditCardCard}>
          {creditCard.errors && (
            <Typography color="secondary">{locale('errorSavingCreditCard')}</Typography>
          )}
          {creditCard.cardType ? (
            <div>
              <PageTitle className={classes.creditCardTitle} variant="h6">
                {locale('savedCreditCard')}
              </PageTitle>
              <CreditCardPreview
                creditCard={creditCard}
                onRemoveCreditCard={handleRemoveCreditCard}
                setDisplayChangePaymentModal={setDisplayChangePaymentModal}
                setModalStep={setModalStep}
              />
            </div>
          ) : (
            <div>
              <PageTitle
                className={classes.creditCardTitle}
                variant="h6"
              >
                {locale('createCreditCard')}
              </PageTitle>
              <CreditCardPaas onSave={loaderCreditCard} />
            </div>
          )}
        </Card>
        <ReCaptcha
          action="creditcardpage"
          sitekey={config.RECAPTCHA_SITE_KEY}
          verifyCallback={verifyCallbackTokenreCaptcha}
          elementID="g-recaptcha-credit-card-page"
        />
      </ContentContainer>

      {displayChangePaymentModal
        && <ChangePaymentModal {...getChangePaymentModalProps()} />
      }
    </>
  );
};

export default (withStyles(styles, { withTheme: true }))(CreditCardPage);
