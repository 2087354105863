import { COUNTRY } from '@/config';

export const BASIC_PLAN = 1988108;
export const ESSENTIALS_PLAN = COUNTRY === 'br' ? 421 : 242;
export const PREMIUM_PLAN = COUNTRY === 'br' ? 424 : 243;
export const TRIAL_PLAN = COUNTRY === 'br' ? 430 : 244;
export const ULTRA_PLAN = COUNTRY === 'br' ? 443 : 245;
export const FREE_PLAN = COUNTRY === 'br' ? 418 : 241;

export const TITAN_PRODUCTS_NAMES = {
  PREMIUM: 'PREMIUM',
  ESSENTIALS: 'ESSENTIALS',
  ESSENTIALS_TRIAL: 'ESSENTIALS_TRIAL',
  ULTRA: 'ULTRA',
};

export const getTitanNameById = {
  [ESSENTIALS_PLAN]: TITAN_PRODUCTS_NAMES.ESSENTIALS,
  [PREMIUM_PLAN]: TITAN_PRODUCTS_NAMES.PREMIUM,
  [ULTRA_PLAN]: TITAN_PRODUCTS_NAMES.ULTRA,
  [TRIAL_PLAN]: TITAN_PRODUCTS_NAMES.ESSENTIALS_TRIAL,
};
