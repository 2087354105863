
import React from 'react';

export default {
  emailNotRecived: '¿Ya no tiene acceso a su correo electrónico actual?',
  resendText: 'Enviar nuevamente en ',
  validateEmail: {
    title: 'Confirme su correo electrónico',
    description: ({ email }) => (
      <>
        {'Para su seguridad, le enviamos un enlace a '}
        <strong>{email}</strong>
        . Acceda a su correo electrónico para finalizar el procedimiento.
      </>
    ),
    tryResendDescription: 'Intente volver a enviar el correo electrónico de validación o abrir un ticket para que podamos validar que la cuenta es suya y luego cambiamos el correo electrónico de registro.',
    resendButtonLabel: 'Reenviar correo de validación',
    changeEmail: 'Cambiar correo electrónico',
  },
  changeMail: {
    tip: (
      <span>
        <strong>Consejo:</strong>
        {' Use una dirección de correo electrónico personal (por ejemplo, @gmail) para no correr el riesgo de perder el acceso.'}
      </span>
    ),
    title: 'Cambie su correo electrónico',
    description: ({ email }) => (
      <>
        {'Por seguridad, enviaremos un enlace a su correo electrónico actual '}
        <strong>{email}</strong>
        {' para validar el cambio.'}
      </>
    ),
    form: {
      mailLabel: 'Nuevo correo electrónico',
      mailPlaceholder: 'Digite su correo electrónico',
      checkEmailLabel: 'Confirme su nuevo correo  electrónico',
      checkEmailPlaceholder: 'Digite nuevamente su correo electrónico',
      passwordLabel: 'Contraseña del Portal del Cliente',
      passwordPlaceholder: 'Digite su contraseña',
      errors: {
        invalidEmail: 'Introduzca un correo electrónico válido',
        emailsDoesntMatch: '¡Ops! Parece que los correos electrónicos no están iguales.',
        requiredEmail: 'Campo obligatorio',
        passwordTooShort: 'La contraseña debe tener un mínimo de 6 caracteres.',
      },
      submitLabel: 'Enviar correo de validación',
    },
    noAccessTitle: '¿Ya no tiene acceso a su correo electrónico actual? ',
    noAccessDescription: 'Será necesario abrir un ticket para que podamos validar que la cuenta es suya y luego cambiar el correo electrónico de registro.',
    noAccessForm: 'Abrir ticket',
  },
  sentEmail: {
    title: 'Valide el cambio del correo electrónico de registro',
    description: ({ email }) => (
      <>
        {'Ingrese a su correo actual '}
        <strong>{email}</strong>
        {' y en el correo electrónico que enviamos, valide el cambio.'}
      </>
    ),
    tryResendDescription: 'Intente volver a enviar el correo electrónico de validación o abrir un ticket para que podamos validar que la cuenta es suya y luego cambiamos el correo electrónico de registro.',
    resendButtonLabel: 'Reenviar correo de validación',
    openFormButtonLabel: 'Abrir ticket',
  },
  feedback: {
    noMoreAttempts: () => 'Ha agotado las solicitudes de confirmación, inténtalo de nuevo mañana',
    emailSendAttempts: ({ attempts, used }) => `Ha solicitado ${used} correo${used > 1 ? 's' : ''} electrónico${used > 1 ? 's' : ''} en las últimas 24 horas, quedan ${attempts} solicitudes`,
    alreadyValidated: ({ hours, date }) => `Correo electrónico confirmado hace menos de 24 horas, puede cambiar este correo electrónico a las ${hours} horas del día ${date}.`,
    resendSuccess: 'Reenviamos. Por favor, verifica tu correo electrónico nuevamente.',
    resendError: 'No fue posible realizar el reenvío. Por favor, cambia tu correo o intenta nuevamente más tarde',
    changeSuccess: 'Compruebe su correo electrónico y haga clic en el enlace de confirmación.',
    changeError: 'Inténtelo de nuevo más tarde o cambie el correo electrónico.',
    password: 'Contraseña incorrecta. Por favor, intente de nuevo.',
    dataInUse: 'El correo electrónico informado ya está en uso en su registro. Para continuar, informe otro correo electrónico.',
    emailInDataBase: '¡Ups! Este correo electrónico ya está registrado. Por favor, elija una nueva dirección de correo electrónico.',
  },
};
