import { CALL_API } from '@/middleware/api';
import { INVOICE_PAYMENT_METHODS } from '@/config/api';
import {
  RECEIVE_INVOICE_PAYMENT_METHODS, REQUEST_INVOICE_PAYMENT_METHODS, ERROR_INVOICE_PAYMENT_METHOD,
} from './actionsTypes';


const receiveInvoicePaymentMethod = paymentMethods => ({
  type: RECEIVE_INVOICE_PAYMENT_METHODS,
  paymentMethods,
});

export function getInvoicePaymentMethods() {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: INVOICE_PAYMENT_METHODS,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_INVOICE_PAYMENT_METHODS }),
        success: data => receiveInvoicePaymentMethod(data.data),
        error: () => ({ type: ERROR_INVOICE_PAYMENT_METHOD }),
      },
    },
  };
}
