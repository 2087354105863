import React, {
  useLayoutEffect, useState, useEffect, useContext, useCallback,
} from 'react';
import * as Styles from './DnsWizardDomainHandler.styles';
import { Loader } from '@/pages/common/v1/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useConfigurationStatus, useFilterPlatforms } from '@/hooks/domains/wizard';
import { domainsAnalytics } from '@/analytics';
import { DnsWizardContext } from '@/contexts/DnsWizard';
import {
  HOSTING_PLATFORM_TYPES,
  PLAN_TYPES,
  REGISTRAR_MAX_PROPAGATION_HOURS,
} from '@/enums/domains/dns.enum';
import { dnsWizardActions } from '@/redux/modules/dnsWizard';
import { formatDomainEllipsis } from '@/utils/Formatters/formatDomainEllipsis';
import { loadDnsDetails } from '@/redux/actions/dnsForward';
import { IconHosting, IconWatch } from '@/icons';
import { DnsWizardHeader } from '@/components/domain/components/DnsWizardHeader';
import { DnsWizardSuccessMessage } from '@/components/domain/components/DnsWizardSuccessMessage';
import { DnsWizardDomainHeader } from '@/components/domain/components/DnsWizardDomainHeader';
import { DnsWizardHostingPlatformsGrid } from '@/components/domain/components/DnsWizardHostingPlatformsGrid';
import { DnsWizardBannerHosting } from '@/components/domain/components/DnsWizardBannerHosting';
import { alertsActions, preChatActions } from '@/redux/modules';

// Configuration Cards
import {
  DnsWizardConfigCardCustom,
  DnsWizardConfigCardDnsZone,
  DnsWizardConfigCardExternal,
  DnsWizardConfigCardHostGator,
  DnsWizardConfigCardReserved,
} from '@/components/domain/components/configurationCards';

// Modals
import {
  HostGatorAlterPlatformModal,
  HostGatorConfigModal,
  HostGatorCustomDnsZoneModal,
  HostGatorOtherPlatformModal,
  HostGatorExternalModal,
  AdvancedDnsZoneModal,
  DomainConfigurationReview,
} from '@/components/domain/components/modals';
import { Alert } from 'gatorcomponents';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_WIZARD_DNS_DLOJA } from '@/config/GrowthBook/constants';
import ReinforcedModal from './components/ReinforcedModal/ReinforcedModal';
import { InBetweenDlojaModal } from './components/InBetweenDlojaModal';

const DnsWizardDomainHandler = ({
  testId = 'dns-wizard-handler',
  hostingPlatforms = [],
  availablePlans = [],
  domain = {},
  controls = {},
  selectedConfiguration = {},
  dnsWizardLocale = {},
  domainId,
  goToAdvancedDNSZone,
  showAdvanceDNSZoneModal,
  setShowAdvanceDNSZoneModal,
}) => {
  const dispatch = useDispatch();
  const enabledDloja = useFeatureIsOn(CHARLIE_WIZARD_DNS_DLOJA);
  const onDomainReservedFlow = useSelector(
    state => state.preChat.triage.domainReservedFlow,
  );
  const clickOnAlertDomainToPoint = useSelector(
    state => state.alertsV2.clickOnAlertDomainToPoint,
  );

  const {
    loading,
  } = useSelector(state => state.common);

  const { setOpenDnsTable } = useContext(DnsWizardContext);
  const IS_STORYBOOK = process.env.STORYBOOK_IS_SB === '1';

  const { modals: modalsLocale } = dnsWizardLocale;
  const { errors: errorsLocale } = dnsWizardLocale;
  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;

  const filteredPlatforms = useFilterPlatforms(hostingPlatforms, enabledDloja);

  const [hostGatorConfigModal, setHostGatorConfigModal] = useState(false);
  const [hostGatorAlterPlatformModal, setHostGatorAlterPlatformModal] = useState(false);
  const [hostGatorOtherPlatformModal, setHostGatorOtherPlatformModal] = useState(false);
  const [externalModal, setExternalModal] = useState(false);
  const [customDnsZoneModal, setCustomDnsZoneModal] = useState(false);
  const [renderDomainConfigurationReviewModal, setRenderDomainConfigurationReviewModal] = useState(false);
  const [inbetweenDlojaModal, setInbetweenDlojaModal] = useState(false);

  const [selectedPlatformType, setSelectedPlatformType] = useState(null);
  const [nameServers, setNameServers] = useState([]);
  const [externalNameServers, setExternalNameServers] = useState([]);
  const [selectedPlanToShowInReview, setSelectedPlanToShowInReview] = useState();
  const [selectedPlatformToPoint, setSelectedPlatformToPoint] = useState();
  const [dataToSubmitAfterReview, setDataToSubmitAfterReview] = useState();
  const [reinforceModal, setReinforceModal] = useState(false);
  const additionalPlanOfferCard = true;
  const additionalNoHostGatorPlanCard = true;

  const {
    pageLoading,
    domainConfigurationLoading,
    hasAvailableHostGatorPlan,
    hasHostGatorPlan,
    skippedWizard,
    changedPlan,
  } = controls;

  const {
    name: domainName,
    registrar,
    configurationStatus,
    planDetails,
    nameServers: storedNameServers,
    platformName,
  } = domain;
  const domainEllipsis = formatDomainEllipsis(domainName, 15);
  const mainDomainEllipsis = formatDomainEllipsis(planDetails.domain, 19);

  const {
    isPropagationPeriod,
    wasRecentlyConfigured,
    isConfiguredThroughWizard,
    isConfiguredInHostGator,
  } = configurationStatus;

  const {
    dnsWizardHostingPlatformsView,
    dnsWizardPlanSelectClick,
    dnsWizardClosePlanSelectionModal,
    dnsWizardAlterPlatformClick,
    dnsWizardAlterHostingPlanClick,
    dnsWizardCustomConfigurationClick,
    dnsWizardCloseCustomConfigurationClick,
    dnsWizardConfirmCustomConfigurationClick,
    dnsWizardHelpMaterialCustomConfigurationClick,
    dnsWizardPlatformDefinitionHelpMaterialClick,
    dnsWizardHelpMaterialPlanSelection,
    dnsWizardHelpMaterialDnsZoneStatsClick,
    dnsWizardLojaIntegradaCardClick,
    dnsWizardNuvemshopCardClick,
    dnsWizardShopifyCardClick,
    dnsWizardExternalCardClick,
    dnsWizardExternalEditPlatformCardClick,
    dnsWizardBannerNewPlanClick,
    dnsWizardBannerUpgradePlanClick,
    dnsWizardBannerSeePlansClick,
    dnsWizardDLojaCardClick,
  } = dnsWizardAnalytics;
  // Rendering Rules
  const shouldRenderPlatformSelection = !isConfiguredThroughWizard && !skippedWizard;
  const shouldRenderConfigurationDetailsCard = isConfiguredThroughWizard;
  const shouldRenderSuccessMessage = !skippedWizard && wasRecentlyConfigured;
  const shouldRenderDnsZoneWarning = skippedWizard && wasRecentlyConfigured;

  // Business Rules
  const status = useConfigurationStatus(configurationStatus, platformName);
  const isPointedToReservedServer = status === HOSTING_PLATFORM_TYPES.RESERVED;
  const isPointedToHostGatorPlan = status === HOSTING_PLATFORM_TYPES.HOSTGATOR;
  const isPointedToResale = planDetails.type === PLAN_TYPES.RESALE;
  const isPointedToCustomServer = status === HOSTING_PLATFORM_TYPES.CUSTOM;

  const shouldRenderAdvancedDNSZoneButton = isConfiguredThroughWizard && isConfiguredInHostGator && !isPointedToCustomServer && planDetails.type !== PLAN_TYPES.RESALE;

  const shouldRenderAdditionalPlanOfferCard = hasHostGatorPlan && !hasAvailableHostGatorPlan && (additionalPlanOfferCard || IS_STORYBOOK);
  const shouldRenderNoHostgatorPlan = !hasHostGatorPlan && (additionalNoHostGatorPlanCard || IS_STORYBOOK);
  const showHostGatorPlanOffer = shouldRenderAdditionalPlanOfferCard || shouldRenderNoHostgatorPlan;
  const { planStatus } = planDetails;
  const attentionMessage = dnsWizardLocale.configCardAttentionMessage(REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??', undefined, dnsWizardHelpMaterialDnsZoneStatsClick);
  const attentionMessageCustom = dnsWizardLocale.configCardAttentionMessage(REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??', true, dnsWizardHelpMaterialDnsZoneStatsClick);

  const handleConfigureHostGatorPlan = () => {
    setOpenDnsTable(false);
    dispatch(dnsWizardActions.hostGatorPlanConfiguration.request(domainId, selectedConfiguration));
    dispatch(loadDnsDetails(domainId));
  };

  const handleConfigureCustomDns = (platformId) => {
    dnsWizardConfirmCustomConfigurationClick();
    dispatch(dnsWizardActions.customConfiguration.request(domainId, platformId));
    dispatch(loadDnsDetails(domainId));
  };

  const handleConfigureCustomDnsZone = (customDnsZonePayload, platform, currentType) => {
    if (currentType !== PLAN_TYPES.PLAN) {
      dispatch(dnsWizardActions.skipWizard.set());
      dispatch(dnsWizardActions.customDnsZoneConfiguration.request(domainId, customDnsZonePayload, platform));
      return;
    }
    dispatch(dnsWizardActions.customDnsZoneFromPlanConfiguration.request(domainId, customDnsZonePayload, platform));
  };

  const handleEditPlan = () => {
    dnsWizardAlterHostingPlanClick();
    setHostGatorConfigModal(true);
  };

  const handleChangePlatform = () => {
    dnsWizardAlterPlatformClick();
    domainsAnalytics.dnsWizard.changePlatformCtaClick();
    setHostGatorAlterPlatformModal(true);
  };

  const handleAdvanceDNSZoneModalShow = () => {
    const dontShowAlert = localStorage.getItem('dnsAdvancedZoneDontShowModal');
    if (dontShowAlert) {
      goToAdvancedDNSZone && goToAdvancedDNSZone();
      dnsWizardAnalytics.dnsWizardEditDnsZone();
    } else {
      if (!showAdvanceDNSZoneModal) {
        dnsWizardAnalytics.dnsWizardEditDnsZone();
      }
      setShowAdvanceDNSZoneModal(!showAdvanceDNSZoneModal);
    }
  };


  const closeDomainConfigurationReviewModal = () => {
    setRenderDomainConfigurationReviewModal(false);
    setSelectedPlanToShowInReview(undefined);
    setSelectedPlatformToPoint(undefined);
    setDataToSubmitAfterReview(undefined);
  };

  const handleOtherPlatformMClick = () => {
    dnsWizardCustomConfigurationClick();
    setHostGatorOtherPlatformModal(true);
  };

  const handleCloseModal = () => {
    dnsWizardClosePlanSelectionModal();
    setHostGatorConfigModal(false);
  };

  const handleClickVpsOrDedicatedPlan = () => {
    handleOtherPlatformMClick();
  };

  const handleCloseOtherPlatformModal = () => {
    dnsWizardCloseCustomConfigurationClick();
    setHostGatorOtherPlatformModal(false);
  };

  const handleCloseCustomDnsZoneModal = () => {
    setCustomDnsZoneModal(false);
  };

  const handleCloseExternalModal = () => {
    setExternalModal(false);
  };

  const handleCloseAlterPlatformModal = () => {
    dnsWizardAnalytics.dnsWizardCloseEditPlatformModal();
    setHostGatorAlterPlatformModal(false);
  };

  const helpClickGa = () => {
    dnsWizardHelpMaterialCustomConfigurationClick();
  };

  const configurationHelpClickGa = () => {
    dnsWizardHelpMaterialPlanSelection();
  };

  const handleSkipWizard = () => {
    dnsWizardAnalytics.dnsWizardSkipWizardClick();
    dispatch(dnsWizardActions.skipWizard.request(domainId));
    dispatch(dnsWizardActions.skipWizard.set());
    if (!isPointedToHostGatorPlan) {
      setNameServers(storedNameServers);
    }
  };

  const handleConfigureExternalPlatform = (payload) => {
    dispatch(dnsWizardActions.externalConfiguration.request(domainId, payload));
  };
  const handleNewPlanFromBanner = () => {
    dnsWizardBannerNewPlanClick();
  };

  const handleUpgradePlanFromBanner = () => {
    dnsWizardBannerUpgradePlanClick();
  };

  const handleDnsWizardBannerSeePlansClick = () => {
    dnsWizardBannerSeePlansClick();
  };

  const loadDnsDetailsFunc = useCallback((id) => {
    dispatch(loadDnsDetails(id));
  }, [dispatch]);

  useEffect(() => {
    if (changedPlan) {
      loadDnsDetailsFunc(domainId);
    }
  }, [changedPlan, domainId, loadDnsDetailsFunc]);

  const getNameServers = platform => [platform.nsServer1, platform.nsServer2, platform.nsServer3, platform.nsServer4].filter(e => e !== '');

  const handleSubmitDomainConfiguration = (platformToPoint, dataToSubmit) => {
    const platform = platformToPoint || selectedPlatformToPoint;
    const newDomainDnsData = dataToSubmit || dataToSubmitAfterReview;

    switch (platform) {
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        handleConfigureHostGatorPlan();
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM:
        handleConfigureCustomDns(newDomainDnsData);
        break;
      case HOSTING_PLATFORM_TYPES.EXTERNAL:
        handleConfigureExternalPlatform(newDomainDnsData);
        break;
      case HOSTING_PLATFORM_TYPES.RESERVED:
        handleSkipWizard();
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE:
        if (newDomainDnsData) {
          handleConfigureCustomDnsZone(newDomainDnsData.platformId, newDomainDnsData.type, newDomainDnsData.currentType);
        }
        break;
      default:
        break;
    }
  };

  const handleDomainConfigurationReviewModalShow = (submitDesired, data) => {
    if (!isPointedToReservedServer) {
      if (submitDesired) {
        setSelectedPlatformToPoint(submitDesired);
        setDataToSubmitAfterReview(data);
      }

      setRenderDomainConfigurationReviewModal(!renderDomainConfigurationReviewModal);
    } else {
      handleSubmitDomainConfiguration(submitDesired, data);
    }
  };

  const handleAlterPlatformContinue = (platformType, destinationPlatformName) => {
    dnsWizardAnalytics.dnsWizardEditPlatformContinueClick();
    setHostGatorAlterPlatformModal(false);

    switch (platformType) {
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        setHostGatorConfigModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM:
        setHostGatorOtherPlatformModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.NUVEMSHOP:
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.NUVEMSHOP);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.TIENDANUBE:
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.TIENDANUBE);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA:
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.SHOPIFY:
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.SHOPIFY);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.DLOJA:
        setInbetweenDlojaModal(true);
        setCustomDnsZoneModal(true);
        setExternalNameServers(getNameServers(hostingPlatforms.find(e => e.platformName.toLowerCase() === destinationPlatformName.toLowerCase())));
        break;
      case HOSTING_PLATFORM_TYPES.EXTERNAL:
        setSelectedPlatformType(destinationPlatformName);
        setExternalModal(true);
        setExternalNameServers(getNameServers(hostingPlatforms.find(e => e.platformName.toLowerCase() === destinationPlatformName.toLowerCase())));
        break;
      case HOSTING_PLATFORM_TYPES.RESERVED:
        handleDomainConfigurationReviewModalShow(HOSTING_PLATFORM_TYPES.RESERVED);
        setExternalNameServers(getNameServers(hostingPlatforms.find(e => e.platformName.toLowerCase() === destinationPlatformName.toLowerCase())));
        break;
      default:
        break;
    }
  };


  const handleOpenModal = useCallback((modalType, destinationPlatformName) => {
    switch (modalType) {
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        dnsWizardAnalytics.dnsWizardHostGatorCardClick();
        setHostGatorConfigModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM:
        dnsWizardCustomConfigurationClick();
        setHostGatorOtherPlatformModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.NUVEMSHOP:
        dnsWizardNuvemshopCardClick();
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.NUVEMSHOP);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.TIENDANUBE:
        dnsWizardNuvemshopCardClick();
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.TIENDANUBE);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA:
        dnsWizardLojaIntegradaCardClick();
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.DLOJA:
        dnsWizardDLojaCardClick();
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.DLOJA);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.SHOPIFY:
        dnsWizardShopifyCardClick();
        setSelectedPlatformType(HOSTING_PLATFORM_TYPES.SHOPIFY);
        setCustomDnsZoneModal(true);
        break;
      case HOSTING_PLATFORM_TYPES.EXTERNAL:
        dnsWizardExternalCardClick(destinationPlatformName);
        setSelectedPlatformType(destinationPlatformName);
        setExternalModal(true);
        setExternalNameServers(getNameServers(hostingPlatforms.find(e => e.platformName.toLowerCase() === destinationPlatformName.toLowerCase())));
        break;
      default:
        break;
    }
  }, [
    dnsWizardCustomConfigurationClick,
    dnsWizardDLojaCardClick,
    dnsWizardExternalCardClick,
    dnsWizardLojaIntegradaCardClick,
    dnsWizardNuvemshopCardClick,
    dnsWizardShopifyCardClick,
    dnsWizardAnalytics,
    hostingPlatforms,
  ]);

  const renderDnsWizardConfigCard = (statusType) => {
    switch (statusType) {
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        return (
          <DnsWizardConfigCardHostGator
            testId="dns-wizard-hg-config-card"
            changePlatformText={dnsWizardLocale.changePlatformText}
            editAdvancedDNSZone={dnsWizardLocale.editAdvancedDNSZone}
            planDetails={planDetails}
            nameServers={nameServers}
            domain={dnsWizardLocale.principalDomainMessage(mainDomainEllipsis)}
            shouldRenderChangePlanButton={hasAvailableHostGatorPlan}
            serverText={dnsWizardLocale.serverText}
            attentionMessage={planStatus === 'Active' ? attentionMessage : dnsWizardLocale.planStatusCancelled}
            resellerMessage={dnsWizardLocale.resellerMessage}
            isPointedToResale={isPointedToResale}
            changePlatformClick={handleChangePlatform}
            handleAdvanceDNSZoneModalShow={handleAdvanceDNSZoneModalShow}
            editPlanClick={handleEditPlan}
            shouldRenderPropagationMessage={isPropagationPeriod}
            shouldRenderAdvancedDNSZoneButton={shouldRenderAdvancedDNSZoneButton}
          />
        );
      case HOSTING_PLATFORM_TYPES.CUSTOM:
        return (
          <DnsWizardConfigCardCustom
            testId="dns-wizard-custom-config-card"
            nameServers={nameServers}
            serverText={dnsWizardLocale.serverText}
            changePlatformText={dnsWizardLocale.changePlatformText}
            changePlatformClick={handleChangePlatform}
            shouldRenderPropagationMessage={isPropagationPeriod}
            attentionMessage={attentionMessageCustom}
            otherPlatformAttentionMessage={dnsWizardLocale.otherPlatformAttentionMessage}
            otherPlatformCardTitle={dnsWizardLocale.otherPlatformCardTitle}
          />
        );
      case HOSTING_PLATFORM_TYPES.RESERVED:
        return (
          <DnsWizardConfigCardReserved
            testId="dns-wizard-reserved-config-card"
            changePlatformText={dnsWizardLocale.changePlatformText}
            editAdvancedDNSZone={dnsWizardLocale.editAdvancedDNSZone}
            nameServers={nameServers}
            serverText={dnsWizardLocale.serverText}
            changePlatformClick={handleChangePlatform}
            handleAdvanceDNSZoneModalShow={handleAdvanceDNSZoneModalShow}
            editPlanClick={handleEditPlan}
            shouldRenderChangePlanButton={hasAvailableHostGatorPlan}
            noPlanSelectedMessage={dnsWizardLocale.noPlanSelectedMessage}
            noPlatformMessage={dnsWizardLocale.domainNotConfiguredMessage()}
            shouldRenderAdvancedDNSZoneButton={shouldRenderAdvancedDNSZoneButton}
            loading={loading}
          />
        );
      case HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE:
        return (
          <DnsWizardConfigCardDnsZone
            platformName={platformName}
            hostingPlatforms={hostingPlatforms}
            serverText={dnsWizardLocale.serverText}
            nameServers={nameServers}
            attentionMessage={attentionMessage}
            changePlatformText={dnsWizardLocale.changePlatformText}
            changePlatformClick={handleChangePlatform}
            tooltip={dnsWizardLocale.configCardTooltip(platformName)}
            accessText={dnsWizardLocale.access}
            resellerMessage={dnsWizardLocale.resellerMessage}
            isPointedToResale={isPointedToResale}
          />
        );
      case HOSTING_PLATFORM_TYPES.EXTERNAL:
        return (
          <DnsWizardConfigCardExternal
            serverText={dnsWizardLocale.serverText}
            nameServers={nameServers}
            attentionMessage={attentionMessage}
            changePlatformText={dnsWizardLocale.changePlatformText}
            changePlatformClick={handleChangePlatform}
            tooltip={dnsWizardLocale.tooltip}
            accessText={dnsWizardLocale.access}
            platform={hostingPlatforms.find(e => e.platformName.toLowerCase() === platformName.toLowerCase())}
            externalPlatformAttentionMessage={dnsWizardLocale.externalPlatformAttentionMessage}
          />
        );
      default:
        return null;
    }
  };

  useLayoutEffect(() => {
    dnsWizardHostingPlatformsView();
  }, [dnsWizardHostingPlatformsView]);

  useEffect(() => {
    if ((status === HOSTING_PLATFORM_TYPES.CUSTOM)
      || (status === HOSTING_PLATFORM_TYPES.EXTERNAL)
      || ((status === HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE) && (planDetails.type !== PLAN_TYPES.PLAN))) {
      setNameServers(storedNameServers);
    }

    if (isPointedToHostGatorPlan
      || ((status === HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE) && (planDetails.type === PLAN_TYPES.PLAN))
    ) {
      setNameServers([
        planDetails.master,
        planDetails.slave,
      ]);
    }

    if (isPointedToReservedServer) {
      setNameServers(storedNameServers);
    }
  },
  [planDetails, storedNameServers, isPointedToHostGatorPlan, status, configurationStatus, isPointedToReservedServer]);

  const hostGatorConfigModalComponent = domainConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorConfigModal
        hostGatorConfigModal={hostGatorConfigModal}
        handleClose={handleCloseModal}
        availablePlans={availablePlans}
        handleSubmit={handleConfigureHostGatorPlan}
        handleSkipWizard={handleSkipWizard}
        title={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].title}
        subtitle={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].subtitle}
        tooltip={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].tooltip}
        checkboxText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].checkbox}
        configureButtonText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].button}
        mainDomainText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].mainDomain}
        otherDomainsText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].otherDomains}
        domainText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].domain}
        domainTextPlural={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].domains}
        alertMessageText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].alertMessage(REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??', configurationHelpClickGa)}
        planSelectGa={dnsWizardPlanSelectClick}
        handleClickVpsOrDedicatedPlan={handleClickVpsOrDedicatedPlan}
        firstTimeConfigure={!isConfiguredThroughWizard}
        dnsConfigureText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].dnsConfigText}
        platform={hostingPlatforms.find(e => e.platformType === HOSTING_PLATFORM_TYPES.HOSTGATOR)}
        setSelectedPlanToShowInReview={setSelectedPlanToShowInReview}
      />
    );

  const customDnsModalComponent = domainConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorOtherPlatformModal
        domain={domainName}
        hostGatorOtherPlatformModal={hostGatorOtherPlatformModal}
        handleClose={handleCloseOtherPlatformModal}
        title={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].title}
        subtitle={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].subtitle}
        needHelp={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].needHelp()}
        alertMessageText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].alertMessage(REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??', helpClickGa)}
        serverWithNumberText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].serverWithNumber}
        nsWithNumberText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].nsWithNumber}
        ipWithNumberText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].ipWithNumber}
        ipPlaceholderText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].ipPlaceholder}
        addServerText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].addServer}
        buttonText={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM].button}
        handleConfigureCustomDns={handleDomainConfigurationReviewModalShow}
        errorsLocale={errorsLocale}
      />
    );

  const dnsWizardBannerHostingProps = useCallback(() => {
    if (shouldRenderAdditionalPlanOfferCard) {
      return {
        hasHostGatorPlan: true,
        planId: controls.upgradablePlanId,
        upgradePlanOrSeePlansFromBannerClick: handleUpgradePlanFromBanner,
        newPlanFromBannerClick: handleNewPlanFromBanner,
        text: dnsWizardLocale.bannerChangeOrNewPlanText,
        upgradePlanFromBannerText: dnsWizardLocale.bannerChangeOrNewPlanButtonUpgradePlan,
        newPlanFromBannerText: dnsWizardLocale.bannerChangeOrNewPlanButtonNewPlan,
      };
    }

    if (shouldRenderNoHostgatorPlan) {
      return {
        hasHostGatorPlan: false,
        upgradePlanOrSeePlansFromBannerClick: handleDnsWizardBannerSeePlansClick,
        text: dnsWizardLocale.bannerNewPlanText,
        upgradePlanFromBannerText: dnsWizardLocale.bannerNewPlanButton,
      };
    }
  }, [
    controls.upgradablePlanId,
    dnsWizardLocale.bannerChangeOrNewPlanButtonNewPlan,
    dnsWizardLocale.bannerChangeOrNewPlanButtonUpgradePlan,
    dnsWizardLocale.bannerChangeOrNewPlanText,
    dnsWizardLocale.bannerNewPlanButton,
    dnsWizardLocale.bannerNewPlanText,
    handleDnsWizardBannerSeePlansClick,
    handleNewPlanFromBanner,
    handleUpgradePlanFromBanner,
    shouldRenderAdditionalPlanOfferCard,
    shouldRenderNoHostgatorPlan,
  ]);

  const getDnsWizardHostingPlatformsGridProps = useCallback(() => {
    const defaultProps = {
      hostingPlatforms: filteredPlatforms,
      handleOpen: handleOpenModal,
      hasAvailableHostGatorPlan,
      testId: 'platform-grid',
      cardContentText: dnsWizardLocale.otherPlatformCardText,
      reservedCardText: dnsWizardLocale.reservedPlatform,
      handleClickReserved: handleDomainConfigurationReviewModalShow,
      isPointedToReservedServer,
    };

    return {
      ...defaultProps,
    };
  }, [
    dnsWizardLocale,
    filteredPlatforms,
    handleOpenModal,
    hasAvailableHostGatorPlan,
    isPointedToReservedServer,
    handleDomainConfigurationReviewModalShow,
  ]);

  const handleReinforceModal = useCallback(() => {
    setReinforceModal(true);
  }, []);


  const hostGatorAlterPlatformModalComponent = domainConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorAlterPlatformModal
        hostGatorAlterPlatformModal={hostGatorAlterPlatformModal}
        title={dnsWizardLocale.modals.alterPlatform.title}
        subtitle={dnsWizardLocale.modals.alterPlatform.subtitle}
        buttonText={dnsWizardLocale.modals.alterPlatform.button}
        handleClose={handleCloseAlterPlatformModal}
        handleContinue={handleAlterPlatformContinue}
        handleSkipWizard={handleSkipWizard}
        tooltipMessage={dnsWizardLocale.modals.alterPlatform.tooltip}
        hostingPlatforms={filteredPlatforms}
        cardContentText={dnsWizardLocale.otherPlatformCardText}
        customSelectGa={dnsWizardAnalytics.dnsWizardCustomEditPlatformCardClick}
        hgSelectGa={dnsWizardAnalytics.dnsWizardHostGatorEditPlatformCardClick}
        dnsWizardExternalEditPlatformCardClick={dnsWizardExternalEditPlatformCardClick}
        status={status}
        showHostGatorPlanOffer={showHostGatorPlanOffer}
        dnsWizardBannerHostingProps={dnsWizardBannerHostingProps}
        dnsConfigureText={modalsLocale[HOSTING_PLATFORM_TYPES.HOSTGATOR].dnsConfigText}
        selectedPlatform={platformName}
        reservedCardText={dnsWizardLocale.reservedPlatform}
        handleClickReserved={handleDomainConfigurationReviewModalShow}
        isPointedToReservedServer={isPointedToReservedServer}
        shouldRenderAdditionalPlanOfferCard={shouldRenderAdditionalPlanOfferCard}
        shouldRenderNoHostgatorPlan={shouldRenderNoHostgatorPlan}
        handleReinforceModal={handleReinforceModal}
      />
    );

  const customDnsZoneModalComponent = domainConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorCustomDnsZoneModal
        handleClose={handleCloseCustomDnsZoneModal}
        customDnsZoneModal={customDnsZoneModal}
        modalsLocale={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE]}
        type={selectedPlatformType}
        domainName={domainName}
        domainIp={planDetails.ip}
        platform={filteredPlatforms.filter(e => e.platformType === selectedPlatformType).pop()}
        propagationHours={REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??'}
        analytics={dnsWizardAnalytics}
        handleConfigureCustomDnsZone={handleDomainConfigurationReviewModalShow}
        currentType={planDetails.type}
        firstTimeConfigure={!isConfiguredThroughWizard}
        currentStatus={status}
      />
    );

  const HostGatorExternalModalComponent = domainConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorExternalModal
        handleClose={handleCloseExternalModal}
        externalModal={externalModal}
        platformName={selectedPlatformType}
        platform={hostingPlatforms.find(e => e.platformName === selectedPlatformType)}
        modalsLocale={modalsLocale[HOSTING_PLATFORM_TYPES.EXTERNAL]}
        propagationHours={REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??'}
        analytics={dnsWizardAnalytics}
        defaultNameServers={externalNameServers}
        handleConfigureExternalPlatform={handleDomainConfigurationReviewModalShow}
        errorsLocale={errorsLocale}
        firstTimeConfigure={!isConfiguredThroughWizard}
      />
    );

  const advanceDNSZoneModal = showAdvanceDNSZoneModal && (
    <AdvancedDnsZoneModal
      handleCloseModal={handleAdvanceDNSZoneModalShow}
      goToAdvancedDNSZone={goToAdvancedDNSZone}
    />
  );

  const domainConfigurationReview = renderDomainConfigurationReviewModal && (
    <DomainConfigurationReview
      handleCloseModal={closeDomainConfigurationReviewModal}
      nameServers={storedNameServers}
      handleContinueClick={handleSubmitDomainConfiguration}
      availablePlans={availablePlans}
      selectedPlanId={selectedPlanToShowInReview}
    />
  );

  const openChatSuccessMessage = useCallback(() => {
    if (onDomainReservedFlow && shouldRenderSuccessMessage && !pageLoading) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.reservedDomain.onFlow(false));
    }
  }, [onDomainReservedFlow, shouldRenderSuccessMessage, dispatch, pageLoading]);

  useEffect(() => {
    openChatSuccessMessage();
  }, [openChatSuccessMessage]);

  useEffect(() => {
    if (clickOnAlertDomainToPoint && !pageLoading) {
      setHostGatorConfigModal(true);
      dispatch(alertsActions.set.clickOnAlertDomainToPoint(false));
    }
  }, [clickOnAlertDomainToPoint, pageLoading, dispatch]);


  const getReinforceModalProps = useCallback(() => {
    const defaultProps = {
      title: shouldRenderAdditionalPlanOfferCard
        ? dnsWizardLocale.reinforcment.noHostGatorPlan.title
        : dnsWizardLocale.reinforcment.aditionalPlanOffer.title,
      dnsWizardBannerHostingProps,
    };

    const handleClose = () => {
      setReinforceModal(false);
      handleCloseModal();
    };

    return ({
      ...defaultProps,
      handleClose,
    });
  }, [
    dnsWizardBannerHostingProps,
    dnsWizardLocale,
    handleCloseModal,
    shouldRenderAdditionalPlanOfferCard,
  ]);

  const getInbetweenDlojaModalProps = useCallback(() => {
    const defaultProps = {
      hostingPlatforms,
      title: dnsWizardLocale.reinforcment.dLojaExplanation.title,
      description: dnsWizardLocale.reinforcment.dLojaExplanation.description,
      alert: dnsWizardLocale.reinforcment.dLojaExplanation.alert,
      onContinue: () => setSelectedPlatformType(HOSTING_PLATFORM_TYPES.DLOJA),
      checkbox: dnsWizardLocale.reinforcment.dLojaExplanation.checkbox,
      cta: dnsWizardLocale.reinforcment.dLojaExplanation.cta,
    };

    const handleClose = () => {
      setInbetweenDlojaModal(false);
      handleCloseModal();
    };

    return {
      ...defaultProps,
      handleClose,
    };
  }, [
    handleCloseModal,
    hostingPlatforms,
    dnsWizardLocale,
  ]);

  const reinforcedFlow = reinforceModal || inbetweenDlojaModal;

  return (
    <Styles.DnsWizardHandlerWrapper data-testid={testId}>

      {reinforceModal && (
        <ReinforcedModal
          {...getReinforceModalProps()}
        />
      )}

      {inbetweenDlojaModal && (
        <InBetweenDlojaModal
          {...getInbetweenDlojaModalProps()}
        />
      )}

      {(!reinforcedFlow && hostGatorConfigModal) && hostGatorConfigModalComponent}
      {(!reinforcedFlow && externalModal) && HostGatorExternalModalComponent}
      {(!reinforcedFlow && hostGatorOtherPlatformModal) && customDnsModalComponent}
      {(!reinforcedFlow && hostGatorAlterPlatformModal) && hostGatorAlterPlatformModalComponent}
      {(!reinforcedFlow && customDnsZoneModal) && customDnsZoneModalComponent}
      {!reinforcedFlow && advanceDNSZoneModal}
      {!reinforcedFlow && domainConfigurationReview}

      <Styles.DnsWizardHandlerContainer>
        {pageLoading ? (<Loader message={dnsWizardLocale.pageLoadingMessage} />)
          : (
            <>
              <DnsWizardDomainHeader title={dnsWizardLocale.selectedDomain} domain={domainName} />
              {shouldRenderSuccessMessage && (
                <DnsWizardSuccessMessage
                  testId="success-message"
                  title={dnsWizardLocale.successTitle(domainName, domainEllipsis)}
                  icon={<IconWatch />}
                  message={dnsWizardLocale.successMessage(REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??')}
                />
              )}

              {shouldRenderDnsZoneWarning && (
                <Alert
                  title={dnsWizardLocale.dnsZoneAlertTitle}
                  description={dnsWizardLocale.dnsZoneAlertDescription}
                  variant="success"
                  width="full"
                />
              )}

              {shouldRenderConfigurationDetailsCard && (
                <>
                  <Styles.TextBlock>
                    <DnsWizardHeader
                      variation="configured"
                      icon={<IconHosting />}
                      title={dnsWizardLocale.domainConfiguredTitle}
                      tooltip={dnsWizardLocale.domainConfiguredTooltip}
                    />
                  </Styles.TextBlock>
                  {renderDnsWizardConfigCard(status)}
                </>
              )}

              {shouldRenderPlatformSelection && (
                <>
                  <Styles.TextBlock>
                    <DnsWizardHeader
                      icon={<IconHosting />}
                      title={dnsWizardLocale.chooseYourPlatformTitle}
                      tooltip={dnsWizardLocale.chooseYourPlatformTooltip}
                    />
                    {dnsWizardLocale.chooseYourPlatformText(dnsWizardPlatformDefinitionHelpMaterialClick)}
                  </Styles.TextBlock>

                  {showHostGatorPlanOffer && (
                    <DnsWizardBannerHosting {...dnsWizardBannerHostingProps()} />
                  )}

                  <DnsWizardHostingPlatformsGrid
                    {...getDnsWizardHostingPlatformsGridProps()}
                  />
                </>
              )}
            </>
          )}
      </Styles.DnsWizardHandlerContainer>


    </Styles.DnsWizardHandlerWrapper>
  );
};

export default DnsWizardDomainHandler;
