import routesBR from './routes.br';
import routesCL from './routes.cl';
import routesCO from './routes.co';
import routesMX from './routes.mx';

const locales = {
  BR: routesBR,
  CL: routesCL,
  CO: routesCO,
  MX: routesMX,
};

export default locales;
