const styles = theme => ({
  modal: {
    borderRadius: 0,
    maxWidth: '475px',
  },
  content: {
    position: 'relative',
    padding: '35px 40px',
  },
  btnClose: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: theme.color.tundora,
    padding: '6px',
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 5px',
  },
  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },
  warning: {
    padding: '15px',
    marginBottom: '30px',
    borderRadius: '2px',
  },
  actions: {
    textAlign: 'center',
  },
  btnCancel: {
    borderColor: theme.color.geyser,
    marginRight: '10px',
    color: theme.color.indigo,
  },
});

export default styles;
