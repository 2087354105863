import shortid from 'shortid';
import { CALL_API } from '@/middleware/api';
import { SITES_AND_PLANS_URL } from '@/config/api';
import { COUNTRY } from '@/config';
import { REQUEST_SITES_AND_PLANS, ERROR_SITES_AND_PLANS, RECEIVE_SITES_AND_PLANS } from './actionsTypes';

const setFilterStatus = (plans) => {
  const filterStatus = [];
  plans.forEach((plan) => {
    if (!filterStatus.includes(plan.status)) {
      filterStatus.push(plan.status);
    }
  });

  return filterStatus;
};

const addKeyInArray = (arr) => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  if (arr.length > 0) {
    return arr.map(item => ({
      ...item,
      key: shortid.generate(),
    }));
  }

  return [];
};

const receiveSitesPlans = (data) => {
  const sitesPlans = data.map(plan => ({
    ...plan,
    key: shortid.generate(),
    items: addKeyInArray(plan.items || []),
    ctas: addKeyInArray(plan.ctas || []),
  }));

  return {
    type: RECEIVE_SITES_AND_PLANS,
    loadedDate: Date.now(),
    sitesPlans,
    filterStatus: setFilterStatus(sitesPlans),
  };
};

export const loadSitesPlans = userId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: SITES_AND_PLANS_URL,
    method: 'GET',
    authorizationType: 'jwt',
    customHeaders: {
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    actionTypes: {
      request: () => ({ type: REQUEST_SITES_AND_PLANS }),
      success: response => receiveSitesPlans(response || []),
      error: () => ({ type: ERROR_SITES_AND_PLANS }),
    },
  },
});
