
import { COUNTRY } from '@/config';
/**
 * Convert currency to number
 */


export const unformat = {
  mx: value => parseFloat(value.replace(/[^0-9.-]+/g, '')),
  cl: value => parseFloat(value.replace(/[^0-9-]+/g, '')),
  co: value => parseFloat(value.replace(/[^0-9-]+/g, '')),
  br: value => parseFloat(value.replaceAll('.', '').replace(',', '.').replace(/[^0-9.-]+/g, '')),
};

export const unformatCurrency = unformat[COUNTRY] ? unformat[COUNTRY] : unformat.br;
