import React, { useEffect } from 'react';
import * as Styles from './GetNowForm.styles';
import { Input } from '@/pages/common/v1/inputs';
import {
  Button, RadioOption, Select, RadioToggle,
} from '@/pages/common/v1';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { IconArrowForward } from '@/icons';
import Loader from '@/components/Layout/Loader';

const GetNowForm = ({
  paymentCycles = [
    { title: 'Anual', name: 'annually', featured: true },
    { title: 'Semestral', name: 'semiannually', featured: false },
    { title: 'Trimestral', name: 'quarterly', featured: false },
    { title: 'Mensal', name: 'monthly', featured: false },
  ],
  paymentMethods = [
    { label: '1x Cartão Crédito', value: 'braspag_credit_1x', id: 'braspag_credit_1x' },
    { label: 'Boleto', value: 'gp_boleto', id: 'gp_boleto' },
    { label: 'Boleto Bank of America', value: 'boleto', id: 'boleto' },
    { label: 'PayPal Antigo', value: 'paypal', id: 'paypal' },
    { label: 'Paypal', value: 'gp_paypal', id: 'gp_paypal' },
  ],
  domains = [
    { label: '20220713local02.com', value: 26, id: 26 },
    { label: '20220713local.com', value: 25, id: 25 },
  ],
  setSelectedDomain,
  formTitle = 'professional plan',
  formDescription = 'Choose your domain:',
  formFirstToggleLabel = 'Select a domain contracted with HostGator',
  formSecondToggleLabel = 'Enter another domain',
  formSecondTogglePlaceholder = 'Enter your domain',
  cycleLabel = 'Select cycle:',
  handleOnToggle,
  activeDomainToggle,
  selectedDomain = null,
  typedDomain = '',
  setTypedDomain,
  setSelectedCycle,
  selectedCycle = 'annually',
  selectePaymentLabel = 'Select the payment method:',
  activeCycle = { price: '279.99', cycle: 'annually', per: '/ano' },
  disabledButton = true,
  toBePaid = 'To be paid',
  hgSiteLock = 'https://www.hostgator.com.br/sitelock',
  getDomain,
  isLoadingPayment = false,
  setSelectedPaymentMethod,
  needMoreLabel,
  needMoreButtonText,
  gaSelectDomain,
  gaTypeDomain,
  gaCyclePaymentClick,
  gaSelectPaymentMethod,
  gaBuySitelock,
  gaSeeAllPlans,
}) => {
  const handleSelect = (e) => {
    setSelectedDomain && setSelectedDomain(e);
  };

  const handleSelectMethod = ({ value }) => {
    if (setSelectedPaymentMethod !== value) {
      gaSelectPaymentMethod(value);
      setSelectedPaymentMethod && setSelectedPaymentMethod(value);
    }
  };

  const handleToggle = ({ toggleOpen, name }) => {
    if (name === 'select-domain') {
      gaSelectDomain();

      if (!selectedDomain) {
        setSelectedDomain && setSelectedDomain(domains[0]);
      }
    }

    if (name === 'type-domain') {
      gaTypeDomain();
    }

    handleOnToggle && handleOnToggle({ toggleOpen, name });
  };

  const handleTypeDomain = (e) => {
    setTypedDomain && setTypedDomain(e.target.value);
  };

  const onChangeCycle = ({ name }) => {
    if (selectedCycle !== name) {
      gaCyclePaymentClick(name);
      setSelectedCycle && setSelectedCycle(name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    gaBuySitelock();
    getDomain && getDomain();
  };

  const handleSeeMore = () => {
    gaSeeAllPlans();
  };

  useEffect(() => {
    if (domains.length === 0) {
      handleOnToggle && handleOnToggle({ toggleOpen: true, name: 'type-domain' });
    }
  }, [domains.length, handleOnToggle]);

  return (
    <Styles.Form onSubmit={handleSubmit} data-testid="GetNow-form">
      <Styles.FormTitle>{formTitle}</Styles.FormTitle>
      <Styles.DomainLabel>{formDescription}</Styles.DomainLabel>

      <Styles.ListOfToggles>
        {domains.length > 0 && (
          <RadioToggle
            title={formFirstToggleLabel}
            onToggle={handleToggle}
            name="select-domain"
            testId="select-domain"
            open={activeDomainToggle === 'select-domain'}
          >
            {domains && (
              <Select options={domains} defaultOption={0} onChange={handleSelect} />
            )}
          </RadioToggle>
        )}

        <RadioToggle
          title={formSecondToggleLabel}
          onToggle={handleToggle}
          name="type-domain"
          testId="type-domain"
          open={activeDomainToggle === 'type-domain'}
        >
          <Styles.TypeDomainWrapper>
            <Input
              type="text"
              variant="marketing"
              placeholder={formSecondTogglePlaceholder}
              value={typedDomain}
              onChange={handleTypeDomain}
            />
          </Styles.TypeDomainWrapper>
        </RadioToggle>
      </Styles.ListOfToggles>

      <Styles.CyclesBlock>
        <Styles.Label>
          {cycleLabel}
        </Styles.Label>
        <Styles.CyclesWrapper>
          {paymentCycles.map(cycle => (
            <RadioOption
              key={cycle.title}
              testId={cycle.name}
              title={cycle.title}
              basis="1 0 123px"
              featured={cycle.featured}
              onToggle={onChangeCycle}
              checked={selectedCycle === cycle.name}
              name={cycle.name}
            />
          ))}
        </Styles.CyclesWrapper>
      </Styles.CyclesBlock>

      <Styles.MethodsBlock>
        {(paymentMethods && paymentMethods.length) && (
          <>
            <Styles.Label>
              {selectePaymentLabel}
            </Styles.Label>
            <Select options={paymentMethods} defaultOption={0} onChange={handleSelectMethod} />
          </>
        )}
      </Styles.MethodsBlock>
      <Styles.ValueBlock>
        <Styles.Label>{toBePaid}</Styles.Label>
        <Styles.Price>
          {formatCurrency(activeCycle.price)}
          <small>{activeCycle.per}</small>
        </Styles.Price>

        <Button
          label={isLoadingPayment ? <Loader size={16} /> : 'Contrate agora'}
          disabled={disabledButton || isLoadingPayment}
          type="submit"
        />
      </Styles.ValueBlock>

      <Styles.NeedMoreWrapper>
        <Styles.Label>
          {needMoreLabel}
        </Styles.Label>
        <Styles.NeedMoreLink
          href={hgSiteLock}
          target="_blank"
          rel="noreferrer"
          onClick={handleSeeMore}
        >
          {needMoreButtonText}
          <IconArrowForward size={16} />
        </Styles.NeedMoreLink>
      </Styles.NeedMoreWrapper>

    </Styles.Form>
  );
};

export default GetNowForm;
