const styles = () => ({
  icon: {
    marginTop: '30px',
    fontSize: '200px',
    height: '44px',
  },
  a: { fill: '#4580c2', fillRule: 'evenodd' },
  b: { fill: '#2e578c', fillRule: 'evenodd' },
  c: { fill: '#fbce3b', fillRule: 'evenodd' },
  d: { fill: '#eca93e', fillRule: 'evenodd' },
  e: { fill: '#edb248', fillRule: 'evenodd' },
  f: { fill: '#f1f1f2' },
  g: { fill: '#f68b51', fillRule: 'evenodd' },
  k: { fill: '#f68b51' },
  h: { fill: '#f26a23', fillRule: 'evenodd' },
  i: { fill: '#c56428', fillRule: 'evenodd' },
  j: { fill: '#ebeef2', fillRule: 'evenodd' },
});

export default styles;
