import React from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './AlterEmailPlatform.styles';
import DnsWizardEmailPlatformsGrid from '../../DnsWizardEmailPlatformsGrid/DnsWizardEmailPlatformsGrid';
import { Button } from '@/pages/common/v1';
import { EMAIL_PLATFORM_NAMES } from '@/enums/domains/dns.enum';

const AlterEmailPlatform = ({
  handleClose = () => {},
  title = 'Change e-mail plataform',
  subtitle = 'You can change your e-mail plataform <....>',
  continueLabel = 'Continue',
  selectedPlatformType = '',
  emailPlataforms = [
    {
      platformName: 'Titan',
      platformType: 'mail',
    },
    {
      platformName: 'Cpanel',
      platformType: 'cpanel',
    },
    {
      platformName: 'Gmail',
      platformType: 'mail',
    },
    {
      platformName: 'Custom',
      platformType: 'mail',
    },
  ],
  emailLocale,
  setEmailModal,
  dnsWizardAnalytics = { dnsWizardAlterEmailClose: () => {}, dnsWizardAlterEmailContinueButton: () => {} },
  selected = false,
  setSelectedEmailPlatformId,
  setSelected,
  selectedPlatformName = null,
  setSelectedPlatformType,
  setSelectedPlatformName,
  setCustomEmailModal,
  activeEmailPlatform = 'None',
  reserved = false,
}) => {
  const { dnsWizardAlterEmailClose, dnsWizardAlterEmailContinueButton } = dnsWizardAnalytics;
  const onClose = () => {
    dnsWizardAlterEmailClose();
    handleClose && handleClose();
  };

  const handleSelect = (emailPlatformId) => {
    setSelected(true);
    setSelectedEmailPlatformId(emailPlatformId);
  };

  const handleClick = () => {
    dnsWizardAlterEmailContinueButton();
    handleClose && handleClose();
    if (selectedPlatformType === 'mail') {
      setEmailModal && setEmailModal(true);
      return;
    }
    setCustomEmailModal(true);
  };

  const filteredPlatforms = () => {
    let list = [...emailPlataforms];

    if (reserved) {
      list = list.filter(platform => platform.platformName !== EMAIL_PLATFORM_NAMES.CPANEL);
    }

    if (activeEmailPlatform) {
      list = list.filter(
        platform => platform.platformName !== activeEmailPlatform
         && platform.platformName !== EMAIL_PLATFORM_NAMES.HOSTGATOR
         && platform.platformName !== EMAIL_PLATFORM_NAMES.RESERVED,
      );
    }

    return list;
  };

  return (
    <Modal onClose={onClose} maxWidth={1036} data-testid="AlterEmailPlatform">
      <Styles.ContentWrapper>
        <Styles.Title>{title}</Styles.Title>
        <Styles.Subtitle>{subtitle}</Styles.Subtitle>

        <DnsWizardEmailPlatformsGrid
          selectedPlatform={selectedPlatformName}
          setSelectedPlatformType={setSelectedPlatformType}
          setSelectedPlatformName={setSelectedPlatformName}
          setSelectedEmailPlatformId={setSelectedEmailPlatformId}
          setSelected={handleSelect}
          selected={selected}
          platforms={filteredPlatforms()}
          emailLocale={emailLocale}
          analytics={dnsWizardAnalytics}
          insideModal
          customColumns={{
            sm: '',
            md: '1 span',
            lg: '1fr 1fr 1fr 1fr',
            xl: '1fr 1fr 1fr 1fr',
          }}
        />

        <Styles.ButtonWrapper>
          <Button label={continueLabel} disabled={!selected} onClick={handleClick} />
        </Styles.ButtonWrapper>
      </Styles.ContentWrapper>
    </Modal>
  );
};

export default AlterEmailPlatform;
