import React from 'react';
import * as Styles from './DnsWizardEmailPlatformsGrid.styles';
import { Card } from '@/pages/common/v1/Card';
import { EMAIL_PLATFORM_NAMES, HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import { DnsWizardEmailPlatformCard } from '@/components/domain/components/DnsWizardEmailPlatformCard';
import otherPlatform from '@/media/domains/other-card.png';

const DnsWizardEmailPlatformsGrid = ({
  testId = 'emailplatformsgrid',
  selectedPlatform,
  setSelectedPlatformType,
  setSelectedPlatformName,
  setSelected,
  insideModal = false,
  setSelectedEmailPlatformId = () => {},
  selected,
  platforms,
  emailLocale = {
    emailZoneContentText: p => p,
    customEmailPlatformName: '',
  },
  analytics = {},
  customColumns = {
    sm: '',
    md: '',
    lg: '',
    xl: '',
  },
  hostgatorMailPlatformName,
}) => {
  const { emailZoneContentText, customEmailPlatformName } = emailLocale;
  const customEmailPlatform = {
    imageUrl: otherPlatform,
    platformType: HOSTING_PLATFORM_TYPES.CUSTOM_MAIL,
    platformName: customEmailPlatformName,
    platformID: HOSTING_PLATFORM_TYPES.CUSTOM_MAIL,
  };
  const handleSelectPlatform = (platformName, platformId, platformType) => {
    const {
      dnsWizardEmailPlatformGmailCardClick,
      dnsWizardEmailPlatformCustomCardClick,
      dnsWizardEmailPlatformTitanCardClick,
      dnsWizardEmailPlatformCpanelCardClick,
      dnsWizardAlterEmailPlatformCardClick,
      dnsWizardEmailPlatformCpanelAndTitanCardClick,
    } = analytics;

    switch (platformName) {
      case customEmailPlatformName:
        insideModal ? dnsWizardAlterEmailPlatformCardClick(platformName) : dnsWizardEmailPlatformCustomCardClick();
        break;
      case EMAIL_PLATFORM_NAMES.TITAN:
        insideModal ? dnsWizardAlterEmailPlatformCardClick(platformName) : dnsWizardEmailPlatformTitanCardClick();
        break;
      case EMAIL_PLATFORM_NAMES.GMAIL:
        insideModal ? dnsWizardAlterEmailPlatformCardClick(platformName) : dnsWizardEmailPlatformGmailCardClick();
        break;
      case EMAIL_PLATFORM_NAMES.CPANEL:
        insideModal ? dnsWizardAlterEmailPlatformCardClick(platformName) : dnsWizardEmailPlatformCpanelCardClick();
        break;
      case EMAIL_PLATFORM_NAMES.HOSTGATOR:
        insideModal ? dnsWizardAlterEmailPlatformCardClick(platformName) : dnsWizardEmailPlatformCpanelAndTitanCardClick();
        break;
      default:
        break;
    }

    setSelected && setSelected(platformId, platformType, platformName);
    setSelectedPlatformType && setSelectedPlatformType(platformType);
    setSelectedPlatformName && setSelectedPlatformName(platformName);
    setSelectedEmailPlatformId && setSelectedEmailPlatformId(platformId);
  };

  return (
    <Styles.EmailPlatformsGridWrapper data-testid={testId} customColumns={customColumns}>
      {platforms && platforms.map(platform => (
        <Card
          unspaced
          variant="rounded"
          selected={selected && selectedPlatform === platform.platformName}
          hover
          key={`emailPlatformCard${platform.platformName}`}
        >
          <DnsWizardEmailPlatformCard
            platformId={platform.platformID}
            platformName={platform.platformName}
            platformType={platform.platformType}
            selectedPlan={selectedPlatform}
            handleOnClick={handleSelectPlatform}
            imageUrl={platform.imageUrl}
            contentText={emailZoneContentText(platform.platformName)}
            hostgatorMailPlatformName={hostgatorMailPlatformName}
          />
        </Card>
      ))}
      <Card
        unspaced
        variant="rounded"
        selected={selected && selectedPlatform === customEmailPlatform.platformName}
        hover
        key={`emailPlatformCard${customEmailPlatform.platformName}`}
      >
        <DnsWizardEmailPlatformCard
          platformId={customEmailPlatform.platformID}
          platformName={customEmailPlatform.platformName}
          platformType={customEmailPlatform.platformType}
          selectedPlan={selectedPlatform}
          handleOnClick={handleSelectPlatform}
          imageUrl={customEmailPlatform.imageUrl}
          contentText={emailZoneContentText(customEmailPlatform.platformName)}
        />
      </Card>
    </Styles.EmailPlatformsGridWrapper>
  );
};

export default DnsWizardEmailPlatformsGrid;
