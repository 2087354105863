import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    margin: 16px 0px 16px 0px;
    padding: 24px;
    width: 100%;
  `}
`;

export const Title = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;


export const Description = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    text-align: left;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    text-align: left;
    span {
      font-weight: bold;
    }
  `}
`;

export const ContentCards = styled.div`
${({ isOpen }) => css`
      display: flex;
      height: ${isOpen ? 'fit-content' : '0px'};
      margin-top: ${isOpen ? '2px' : '0px'};
      margin: ${isOpen ? '2px' : '0px'};
      opacity: ${isOpen ? '2px' : '0px'};
      overflow: auto;
      transition: 0.2s;
  `}
`;

export const Header = styled.div`
${({ isOpen }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: ${isOpen ? '24px' : '0px'};
 `}
`;

export const Container = styled.div`
    align-items: center;
    display: grid;
    gap: 4px;
    width: 100 %;
`;

export const ContentButtons = styled.div`
${({ isOpen }) => css`
      border: none;
      cursor: pointer;
      display: flex;
      gap: 18px;
      outline: none;
      right: 24px;
      > button {
        > svg {
          transition: 0.2s ease-in-out;
          transform: ${isOpen ? 'rotate(-180deg)' : ''};
        }
      }
}
  `}
`;
