
const styles = theme => ({
  resellerMessageWrapper: {
    display: 'flex',
    margin: '24px 0px 0px 0px',
    width: '85%',
  },

  messageStyle: {
    fontSize: 13,
    color: theme.color.tundora,
    lineHeight: '15px',
    letterSpacing: '0.01px',

    '& button, & a': {
      background: 'transparent',
      border: 0,
      color: theme.color.indigo,
      cursor: 'pointer',
      padding: 0,
      textDecoration: 'underline',
    },
  },

  listCursos: {
    cursor: 'pointer',
  },

  productDetail: {
    marginBottom: 24,
    marginTop: 14,
  },
});

export default styles;
