import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DnsFormGroup from '@/components/Dns/DnsFormGroup';
import styles from './styles';

const FormItem = ({
  i, arrayOtherDns, handleChangeNs, classes, handleIpChange, removeSlave,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <DnsFormGroup
      required={i < 2}
      label={i === 0 ? 'Master' : `Slave ${i}`}
      valueNs={arrayOtherDns[i].host}
      handleChangeNs={handleChangeNs}
      error={!arrayOtherDns[i].validate.valid}
      index={i}
      objectKey={i}
      onHandleIpChange={handleIpChange}
      handleMouseEnter={() => { setMouseOver(true); }}
      handleMouseLeave={() => { setMouseOver(false); }}
      otherDns={arrayOtherDns[i]}
    >
      <div className={classes.iconWrapper}>
        <Close
          data-testid="removeSlaveButton"
          id={`group${i}`}
          onClick={() => removeSlave(i)}
          className={classnames(classes.closeIcon, {
            [classes.showIcon]: mouseOver,
          })}
        />
      </div>
    </DnsFormGroup>
  );
};

FormItem.propTypes = {
  i: PropTypes.number.isRequired,
  arrayOtherDns: PropTypes.array.isRequired,
  handleChangeNs: PropTypes.func.isRequired,
  handleIpChange: PropTypes.func.isRequired,
  removeSlave: PropTypes.func.isRequired,
};

export default withStyles(styles)((FormItem));
