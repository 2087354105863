import styled, { css } from 'styled-components';

export const Form = styled.form`
  ${({ theme }) => css`
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.background.wizard};
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 554px;
    padding: 32px 16px;

    @media (max-width: 1296px){
      width: 100%;
    }
  `}
`;

export const FormTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 0px 0px 8px;

    @media (max-width: 1296px){
      text-align: center;
    }
  `}
`;

export const DomainLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 18px;
    margin: 0px 0px 8px;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 18px;
    margin: 8px 0px;
  `}
`;

export const ListOfToggles = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}
`;

export const ConfirmButtonWrapper = styled.div`
    margin: 17px 0px 0px;
`;

export const SelectedDomainMessageWrapper = styled.div`
${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: -12px;

    button {
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      padding: 0px;
      text-align: left;
      text-decoration: underline;
      width: fit-content;
    }
  `}
`;

export const SelectedDomainMessageTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 19px;
    margin: 0px 0px 18px;
  `}
`;

export const SelectedDomainMessageLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin: 0px 0px 11px;
  `}
`;

export const TypeDomainWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const CyclesBlock = styled.div``;
export const MethodsBlock = styled.div``;
export const ValueBlock = styled.div`
    button {
      min-width: 162px;

      span {
        padding: 0;
      }

      div {
        margin: 0;
        padding: 0;
      }
    }
`;

export const CyclesWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    
    > div {
      flex: 1 0 45%;
    }
  }
`;

export const Price = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.action.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 2px 0px 10px;

    small {
      font-size: 14px;
      line-height: 18px;
    }
  `}
`;

export const NeedMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 16px 0px 10px;
    max-width: 275px;
  }
`;

export const NeedMoreLink = styled.a`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.action.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 26px;
    text-decoration: none;

    svg {
      fill: ${theme.v1.color.action.primary};
      margin-left: 12px;
      stroke: ${theme.v1.color.action.primary};
    }
  `}
`;

export const NeedMore = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 18px;
    max-width: 275px;
  `}
`;
