import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import CourseCard from './CourseCard';
import styles from './styles';
import { AcademyPassContext } from '@/contexts/academyPass/context';

const AcademyPassCoursesList = ({
  billStatus, classes, limit, cardType, renderCourses,
}) => {
  const { coursesList } = useSelector(state => state.academyPass.courses);
  const { jwt, userId } = useSelector(state => state.academyPass.tokens);
  const { isPassActive } = useSelector(state => state.academyPass.courses);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const { renderedCourses } = useContext(AcademyPassContext);
  const [shouldRenderCourseList, setShouldRenderCourseList] = useState(renderCourses);

  const isVisible = (course) => {
    if (course && course.visibility !== undefined) {
      return course.visibility === 'public';
    }
  };

  useEffect(() => {
    if (renderedCourses && !shouldRenderCourseList) {
      setFilteredCourses(renderedCourses.filter(course => (isVisible(course) && (course.isFree || course.amount === 0 || course.amount === undefined))).slice(0, limit));
    }

    if (coursesList && coursesList.length > 0 && shouldRenderCourseList) {
      setFilteredCourses(coursesList.filter(course => (isVisible(course) && (course.isFree || course.amount === 0 || course.amount === undefined))).slice(0, limit));
      if (renderedCourses.length > 1) {
        setShouldRenderCourseList(false);
      }
    }
  }, [limit, renderedCourses, coursesList, shouldRenderCourseList]);

  const sortingCoursesByDate = filteredCourses.sort((a, b) => new Date(a.productIssueDate).getTime() - new Date(b.productIssueDate).getTime()).reverse();

  return (
    <div className={classnames([classes.wrapper, classes.cardGrid])} data-testid="courses-grid">
      {(renderedCourses || coursesList) && sortingCoursesByDate.map(course => (<CourseCard course={course} jwt={jwt} userId={userId} isPassActive={isPassActive} isPaidAndActive={billStatus !== null} cardType={cardType} key={course.idProduct} />))}
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPassCoursesList));
