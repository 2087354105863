import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const CardIcon = ({
  classes, icon, status, className,
}) => {
  const IconImage = icon;
  return (
    <IconImage
      className={classnames(className, {
        [classes.statusActive]: status === 'Active',
        [classes.statusPending]: status === 'Pending',
        [classes.statusPendingTransfer]: status === 'Pending_Transfer',
        [classes.statusExpired]: status === 'Expired',
        [classes.statusCancelled]: status === 'Cancelled',
        [classes.statusFraud]: status === 'Fraud',
        [classes.statusSuspended]: status === 'Suspended',
        [classes.statusBuilding]: status === 'Building',
      })}
    />
  );
};

export default withStyles(styles)(CardIcon);
