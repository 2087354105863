import colorfulLine from '@/media/icons/colorfulLine.svg';

const styles = theme => ({

  divDescriptionsPlan: {
    marginTop: 22,
  },

  featuredBlock: {
    alignItems: 'center',
    height: '21px',
    justifyContent: 'center',
    left: '-1px',
    position: 'absolute',
    textAlign: 'right',
    top: '0px',
    width: 'calc(100% + 2px)',
  },

  featuredBlockOld: {
    alignItems: 'center',
    backgroundColor: theme.color.coral,
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    height: '21px',
    justifyContent: 'center',
    left: '-1px',
    position: 'absolute',
    top: '-21px',
    width: 'calc(100% + 2px)',
  },

  featuredLabel: {
    color: theme.color.white,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  planPaymentStyle: {
    marginTop: '8px',

  },

  monthlyPrice: {
    color: theme.palette.blue.dark,
    fontFamily: theme.font.family.secondary,
    fontSize: '24px',
    fontWeight: 'bold',
  },

  totalPrice: {
    color: theme.palette.dark,
    fontFamily: theme.font.family.secondary,
    fontSize: '14px',
    lineHeight: '24px',
    maxWidth: '228px',
    textAlign: 'center',
    '& span': {
      color: theme.palette.blue.dark,
      fontWeight: theme.font.weights.bold,
    },
  },

  totalPriceWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
    width: '100%',
  },

  cycleName: {
    textTransform: 'lowercase',
  },

  monthlyPriceEachEmail: {
    color: theme.dark,
    fontFamily: theme.font.family.secondary,
    fontSize: '12px',
    lineHeight: '24px',
    paddingBottom: '16px',
  },

  defaultDiv: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: 'auto',
  },

  cardItem: {
    backgroundColor: theme.color.white,
    borderRadius: '5px',
    boxShadow: '0px 0px 10px #00000024',
    display: 'flex',
    flexDirection: 'column',
    margin: '2px 2px 33px 2px',
    maxWidth: '330px',
    minHeight: '878px',
    opacity: 1,
    padding: '4px',
    position: 'relative',
    width: '100%',
  },

  cardItemNew: {
    backgroundColor: theme.color.white,
    borderRadius: '0px',
    border: `1px solid ${theme.color.lightShade}`,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    maxWidth: '330px',
    minHeight: '1159px',
    opacity: 1,
    padding: '4px',
    position: 'relative',
    [theme.breakpoints.down(600)]: {
      marginButton: '16px',
      maxWidth: '265px',
      minHeight: '1100px',
    },
  },

  featuredCard: {
    borderColor: theme.color.geyser,
    borderRadius: '0px 0px 5px 5px',
  },

  featuredCardOld: {
    borderColor: theme.color.coral,
    borderRadius: '0px 0px 5px 5px',
  },

  itemTitle: {
    color: theme.color.tundora,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '18px',
    marginBottom: '12px',
    marginTop: '24px',
    textAlign: 'center',
  },
  itemTitleOld: {
    fontSize: '15px',
    marginTop: '',
  },

  descriptionFreemium: {
    alignItems: 'center',
    color: theme.color.greyDark,
    display: 'flex',
    fontSize: '16px',
    height: '106px',
    letterSpacing: '0.01px',
    textAlign: 'left',
  },

  gridDecriptionFreemium: {
    background: 'rgba(68, 128, 197, 0.04)',
    display: 'inherit',
    height: '152px',
    padding: '24px 24px 36px 24px',
    widthWidth: '300px',
  },

  perEmailLabel: {
    color: theme.color.regentGray,
    fontSize: theme.font.sizes.sm,
    letterSpacing: '0.01px',
    lineHeight: '19px',
    marginBottom: '24px',
    textAlign: 'center',
  },

  freemiumButton: {
    alignItems: 'center',
    backgroundColor: theme.color.white,
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 0px 0px ',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    height: '37px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: '17px',
    minWidth: '259px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.color.indigo}`,
      color: theme.color.white,

    },
  },

  logo: {
    marginRight: '8px',
    marginTop: '8px',
  },

  gridDescription: {
    margintTop: '340px',
  },
  featuredButton: {
    backgroundColor: theme.color.coral,
    borderColor: theme.color.brownRust,

    '&:hover': {
      backgroundColor: theme.color.brownRust,
    },
  },
  titanGbTitle: {
    marginTop: '8px',
    marginBottom: '4px',
    textAlign: 'center',
    fontSize: '36px',
    lineHeight: '120%',
    fontWeight: theme.font.weights.normal,
    color: theme.palette.grey.dark,
    fontFamily: theme.font.family.secondary,
    textTransform: 'uppercase',
  },
  descriptionPlans: {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: theme.font.family.secondary,
    fontWeight: theme.font.weights.regular,
  },
  chipWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
    marginBottom: '8px',
    '& div': {
      position: 'static',
      width: 'fit-content',
    },
  },
  titanNewTitle: {
    textAlign: 'center',
    fontSize: theme.font.sizes.sm,
    lineHeight: theme.font.lineHeights.lg,
    fontWeight: theme.font.weights.regular,
    color: theme.palette.grey.dark,
    fontFamily: theme.font.family.secondary,
  },
  titanBoldNewTitle: {
    textAlign: 'center',
    fontSize: theme.font.sizes.sm,
    lineHeight: theme.font.lineHeights.lg,
    fontWeight: theme.font.weights.bold,
    color: theme.palette.grey.dark,
    fontFamily: theme.font.family.secondary,
  },
  titanNewSubtitle: {
    textAlign: 'center',
    fontSize: theme.font.sizes.sm,
    lineHeight: theme.font.lineHeights.lg,
    fontWeight: theme.font.weights.regular,
    color: theme.palette.grey.ultradark,
    fontFamily: theme.font.family.secondary,
  },
  premiumHireButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '14px',
    width: '100%',
  },
  hireButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '18px',
    marginBottom: '32px',
  },
  descriptionLink: {
    color: theme.palette.secondary.primary,
    marginTop: '24px',
    display: 'inline-block',
    fontSize: theme.font.sizes.sm,
    fontFamily: theme.font.family.secondary,
  },
  hideLink: {
    display: 'none',
  },
  newColorfulCard: {
    backgroundImage: `url(${colorfulLine})`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  insetCardItem: {
    backgroundColor: theme.color.white,
    padding: '0px 24px',
    [theme.breakpoints.down(600)]: {
      padding: '0px 16px',
    },
  },
  boldContent: {
    fontWeight: theme.font.weights.bold,
  },
  selectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: '0 auto',
    maxWidth: '237px',
    paddingTop: '16px',
    paddingBottom: '16px',
    textAlign: 'left',
  },
});
export default styles;
