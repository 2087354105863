import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, customBreakPoint }) => css`
    align-items: center;
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 23px 24px 16px 186px;
    position: relative;

    @media (max-width: ${customBreakPoint || theme.v1.breakpoints.sm}){
      display: flex;
      flex-direction: column;
      padding: 29px 24px;
      width: 100%;
    }
  `}
`;

export const TrianglesWrapper = styled.div`
  left: 8px;
  position: absolute;
  top: 8px;
`;

export const SnappyImage = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    height: 40px;
    left: 62px;
    position:absolute;
    width: 90px;

    @media (max-width: ${theme.v1.breakpoints.md}){
      left: 43px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0px auto 0px 0px;

    @media (max-width: ${theme.v1.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 250px;
      text-align:right;
      width: 100%;
    }

    span {
      color: ${theme.v1.color.text.featured};
      font-weight: ${theme.v1.font.weights.medium};
    }
  `}
`;

export const FilterWrapper = styled.div`
  ${({ theme, mobileBreakPoint }) => css`
    align-self: start;
    background-color: ${theme.v1.color.white.primary};
    padding-top: 16px;
    width: 100%;
    button {
      font-family ${theme.v1.font.family.primary}
    }
    > div {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      @media (max-width: ${mobileBreakPoint}) {
        display: none;
      }
    }
  `}
`;

export const MobileFilterWrapper = styled.div`
  ${({ theme, mobileBreakPoint }) => css`
      background-color: ${theme.v1.color.white.primary};
      display: none;
      margin-bottom: 10px;
      padding: 12px 24px 8px 24px;
      @media (max-width: ${mobileBreakPoint}) {
        display: block;
      }
      article {
        line-height: 0px;
        margin: 0px;
      }
    }
  `}
`;
