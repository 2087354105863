import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';

import { uploadImagesTicketDetail } from '@/redux/actions/ticketDetail';
import styles from './styles';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const TicketAttachmentsModal = ({
  open = false,
  classes,
  closeImgModal,
  urlImage,
  altImage,
}) => (
  <Dialog
    open={open}
    onClose={closeImgModal}
    TransitionComponent={Transition}
    keepMounted
    classes={{ paper: classes.wrapper, root: classes.jairo }}
    maxWidth={false}
  >
    <DialogContent className={classes.dialog}>

      <div className={classes.close}>
        <Close onClick={closeImgModal} />
      </div>

      <div className={classes.dragArea}>
        <img src={urlImage} alt={altImage} className={classes.img} />
      </div>

    </DialogContent>
  </Dialog>
);

const mapStateToProps = state => ({
  userEmail: state.summary.email,
  ticketId: state.ticketDetail.id,
  loadingUpload: state.ticketDetail.loadingUpload,
});

const mapDispatchToProps = dispatch => ({
  onUploadImagesTicketDetail: (data, userEmail) => dispatch(uploadImagesTicketDetail(data, userEmail)),
});

export default withI18n()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)((TicketAttachmentsModal))));
