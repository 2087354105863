import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
  display: inline-flex;
`;

export const Anchor = styled.a`
  ${({
    theme, align, variant, inlineLink,
  }) => {
    const displayStyle = inlineLink ? 'inline-flex' : 'flex';

    const variants = {
      default: {
        decoration: 'underline',
        hoverDecoration: 'underline',
        color: theme.palette.primary.main,
        hoverColor: theme.palette.primary.dark,
      },
      link: {
        decoration: 'underline',
        hoverDecoration: 'underline',
        color: theme.palette.primary.main,
        hoverColor: theme.palette.primary.dark,
      },
      noDefaultDecoration: {
        decoration: 'none',
        hoverDecoration: 'underline',
        color: theme.palette.primary.main,
        hoverColor: theme.palette.primary.dark,
      },
      newDefault: {
        decoration: 'none',
        hoverDecoration: 'underline',
        color: theme.palette.secondary.primary,
        fontWeight: theme.font.weights.medium,
      },
    };

    return css`
      align-items: center;
      color: ${variants[variant].color};
      display: ${displayStyle};
      font-weight: ${variants[variant].fontWeight};
      text-align: ${align};
      text-decoration: ${variants[variant].decoration};
      transition: color 200ms ease-in-out;

      &:hover {
        color: ${variants[variant].hoverColor};
        text-decoration: ${variants[variant].hoverDecoration};
      }
    `;
  }}
`;

export const IconWrapper = styled.a`
  span {
    margin: 0;
    margin-left: 5px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;
