const styles = theme => ({
  selectFieldCustom: {
    marginTop: 26,
    '& > label': {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 18,
    },
    '&:first-child': {
      marginTop: 10,
    },
  },
  formWrapper: {
    width: '100%',
    marginTop: 10,
  },
  codeHeader: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    fontWeight: 500,
    marginTop: 24,
    marginBottom: 12,
  },
  codeTextArea: {
    width: '100%',
    minHeight: '92px',
    padding: '12px',
    resize: 'none',
    borderRadius: '5px',
    borderColor: theme.color.geyser,
    backgroundColor: theme.color.white,
    color: theme.color.tundora,
    border: '1px solid',
    fontSize: '14px',
    overflowWrap: 'break-word',
  },

  button: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    fontSize: '14px',
    fontWeight: '500',
    height: '37px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    textDecoration: 'none',
    transition: '300ms',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.color.chambray,
    },
  },
  description: {
    font: 'normal normal normal 14px/17px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    marginTop: '26px',
  },
  outlinedButton: {
    backgroundColor: theme.color.white,
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    '&:hover': {
      backgroundColor: theme.color.chambray,
      color: theme.color.white,
    },
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    paddingTop: '24px',
    borderTop: '1px solid',
    borderColor: theme.color.geyser,
  },
  borderless: {
    border: '0px',
  },
  copyShield: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  sealHelp: {
    fontSize: '14px',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    maxWidth: '345px',
    marginLeft: '12px',
    '& a': {
      color: theme.color.indigo,
    },
  },
});

export default styles;
