import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import * as Styles from './TicketHistoryHandler.styles';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  IconData,
  IconLoading,
  Input,
  Skeleton,
  Tag,
  IconSend,
  IconButton,
  IconHeadset,
  IconPerfil,
  IconDoubleChat,
} from 'gatorcomponents';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadTicketDetail, loadTicketDetailV2, onUploadImagesTicketDetailV2, saveCommentTicketDetail, saveCommentTicketDetailV2,
  uploadImagesTicketDetail,
} from '@/redux/actions/ticketDetail';
import { ERROR_TICKET_DETAIL } from '@/redux/actions/actionsTypes';
import DOMPurify from 'dompurify';
import useLocale from '@/hooks/useLocale';
import { statusTagVariants } from './TicketHistoryHandler.enum';
import { UploadFileModal } from './components/UploadFileModal';
import { ViewImageModal } from './components/ViewImageModal';

const TicketHistoryHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: ticketId, v2 } = useParams();
  const { email: userEmail, firstname: firstName, lastname: lastName } = useSelector(state => state.summary);
  const messagesWrapperRef = useRef(null);
  const { ticket: ticketLocale, routes: routesLocale } = useLocale();

  const acceptedDocumentsExtensionsList = useMemo(() => ['pdf', 'txt', 'doc', 'docx', 'crt', 'key', 'zip', 'csv'], []);

  const [image, setImage] = useState();
  const [ticketData, setTicketData] = useState(null);
  const [shouldRenderEmptyState, setShouldRenderEmptyState] = useState(false);
  const [imageModalIsVisible, setImageModalVisibale] = useState(false);
  const [message, setMessage] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [file, setFile] = useState(null);
  const [type, setType] = useState('label');
  const [uploadModal, setUploadModal] = useState(false);
  const accept = 'image/*';

  const isLoading = !ticketData;

  const onLoadTicketDetail = useCallback((data, userEmail) => dispatch(loadTicketDetail(data, userEmail)), [dispatch]);
  const onLoadTicketDetailV2 = useCallback((data, userEmail) => dispatch(loadTicketDetailV2(data, userEmail)), [dispatch]);
  const onSaveCommentTicketDetail = useCallback((data, userEmail) => dispatch(
    data.comment.file
      ? uploadImagesTicketDetail(data, userEmail)
      : saveCommentTicketDetail(data, userEmail),
  ), [dispatch]);
  const onSaveCommentTicketDetailV2 = useCallback((data, userEmail) => dispatch(
    data.comment.file
      ? onUploadImagesTicketDetailV2(data, userEmail)
      : saveCommentTicketDetailV2(data, userEmail),
  ), [dispatch]);

  const openImageModal = useCallback(({ image }) => {
    setImage(image);
    setImageModalVisibale(true);
  }, []);

  const listDocs = useCallback(({ comment }) => {
    const { attachments } = comment;
    const hasDoc = attachments.find(attachment => acceptedDocumentsExtensionsList.includes(attachment.file_name.split('.').pop()));

    if (hasDoc) {
      return (
        <Styles.DocWrapper>
          { attachments.map((attachment) => {
            const fileExtension = attachment.file_name.split('.').pop();
            const isDoc = acceptedDocumentsExtensionsList.includes(fileExtension);

            if (!isDoc) {
              return;
            }

            return (
              <Styles.DocumentButton
                onClick={() => window.open(attachment.content_url)}
                data-testid={attachment.file_name}
                key={`${attachment.id}-${attachment.file_name}`}
              >
                <IconData />
                {attachment.file_name}
              </Styles.DocumentButton>
            );
          })
          }
        </Styles.DocWrapper>
      );
    }
  }, [acceptedDocumentsExtensionsList]);

  const scrollWrapperToBottom = useCallback(() => {
    if (messagesWrapperRef.current) {
      const height = messagesWrapperRef.current.scrollHeight;
      messagesWrapperRef.current.scroll && messagesWrapperRef.current.scroll({
        top: height,
        behavior: 'smooth',
      });
    }
  }, [messagesWrapperRef]);

  const listImages = useCallback(({ comment }) => {
    const { attachments } = comment;
    const hasImage = attachments.find(attachment => !acceptedDocumentsExtensionsList.includes(attachment.file_name.split('.').pop()));

    if (hasImage) {
      return (
        <Styles.ImageWrapper>
          { attachments.length > 0 && attachments.map((attachment) => {
            const isDoc = acceptedDocumentsExtensionsList.includes(attachment.file_name);

            if (isDoc) {
              return;
            }

            const { thumbnails } = attachment;

            return thumbnails.map(
              thumb => (
                <Styles.Image
                  key={`${attachment.id}-${attachment.file_name}`}
                  data-testid={attachment.file_name}
                  src={thumb.content_url}
                  alt={attachment.file_name}
                  role="presentation"
                  onClick={() => openImageModal({ image: attachment.content_url })}
                />
              ),
            );
          })}
        </Styles.ImageWrapper>
      );
    }
  }, [acceptedDocumentsExtensionsList, openImageModal]);

  const handleFetch = useCallback(({ response }) => {
    if (response.type === ERROR_TICKET_DETAIL) {
      setShouldRenderEmptyState(true);
    }

    if (response.type !== ERROR_TICKET_DETAIL) {
      setTicketData(response.ticketDetail);
      setLoadingSubmit(false);
      setFile(false);
      setUploadModal(false);
      scrollWrapperToBottom();
      setMessage('');
    }
  }, [scrollWrapperToBottom]);

  const loadDetails = useCallback(() => {
    if (v2) {
      onLoadTicketDetailV2(ticketId, userEmail).then((response) => {
        handleFetch({ response });
      });
    }

    if (!v2) {
      onLoadTicketDetail(ticketId, userEmail).then((response) => {
        handleFetch({ response });
      });
    }
  }, [
    onLoadTicketDetail,
    onLoadTicketDetailV2,
    ticketId,
    userEmail,
    v2,
    handleFetch,
  ]);

  const handleClearFile = useCallback(() => {
    setFile(null);
  }, []);

  const handleSubmit = useCallback(() => {
    setLoadingSubmit(true);

    const comment = {
      id: ticketId,
      body: message,
    };

    if (file) {
      comment.file = [file];
      if (!message) {
        comment.body = `Arquivo anexado: ${file.name}`;
      }
    }

    if (v2) {
      onSaveCommentTicketDetailV2({ comment }, userEmail).then(() => {
        loadDetails();
      });
    }

    if (!v2) {
      onSaveCommentTicketDetail({ comment }, userEmail).then(() => {
        loadDetails();
      });
    }
  }, [onSaveCommentTicketDetail, onSaveCommentTicketDetailV2, ticketId, userEmail, v2, message, loadDetails, file]);


  const handleAddFile = useCallback(() => {
    setUploadModal(true);
  }, []);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  const getUploadFileModalProps = useCallback(() => {
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const filesList = e.dataTransfer.files;
      setFile(filesList[0]);
      setUploadModal(false);
    };

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleEnter = (e) => {
      handleDrag(e);
      setType('div');
    };

    const handleLeave = (e) => {
      handleDrag(e);
      setType('label');
    };

    return ({
      uploadModalIsVisible: uploadModal,
      setUploadModal,
      handleEnter,
      handleDrag,
      handleLeave,
      handleDrop,
      type,
      loadingSubmit,
      setFile,
      accept,
      title: ticketLocale.history.attachFile,
      draggableTitle: ticketLocale.history.draggableTitle,
      findFileLabel: ticketLocale.history.findFile,
    });
  }, [accept, loadingSubmit, ticketLocale, uploadModal, type]);

  const getViewImageModalProps = useCallback(() => ({
    imageModalIsVisible,
    setImageModalVisibale,
    image,
  }), [image, imageModalIsVisible, setImageModalVisibale]);

  const renderMessages = useCallback(() => {
    if (isLoading) {
      return [1, 2, 3].map(index => (
        <Styles.MessageBlock key={`${index}-skelleton`}>
          <Styles.Person as="div">
            <Skeleton variant="rectangle" width="100px" height="24px" />

            <Styles.Time>
              <Skeleton variant="rectangle" width="100px" height="24px" />
            </Styles.Time>
          </Styles.Person>

          <Skeleton variant="rectangle" height="100px" width="300px" />
        </Styles.MessageBlock>
      ));
    }

    if (!isLoading) {
      return ticketData.comments.comments.map(comment => (
        <Styles.MessageBlock key={comment.id}>
          <Styles.Person key={`${comment.id}-person`} data-testid={`${comment.id}-person`}>
            <Styles.PersonIconWrapper>
              {comment.person === 'me' ? <IconPerfil size="sm" /> : <IconHeadset size="sm" />}
            </Styles.PersonIconWrapper>
            {comment.person === 'me' ? `${firstName} ${lastName}` : 'Analista'}

            <Styles.Time key={`${comment.id}-time`}>
              {ticketLocale.history.ticketDate({ comment })}
            </Styles.Time>
          </Styles.Person>

          <Styles.Message
            key={`${comment.id}-message`}
            data-testid={`${comment.id}-message`}
            variant={comment.person}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.html_body) }}
          />

          {!!comment.attachments.length && (
          <Styles.AttachmentsWrapper>
            {listDocs({ comment })}

            {listImages({ comment })}
          </Styles.AttachmentsWrapper>
          )}
        </Styles.MessageBlock>
      ));
    }
  }, [isLoading, ticketData, firstName, lastName, listDocs, listImages, ticketLocale]);

  const renderTicketId = useCallback(() => {
    if (isLoading) {
      return (
        <>
          <Skeleton variant="rectangle" width="100px" height="24px" />
          <Skeleton variant="badge" />
        </>
      );
    }

    if (!isLoading) {
      return (
        <>
          {ticketId}
          <Tag variant={statusTagVariants[ticketData.status]} label={ticketLocale.history.status[ticketData.status]} />
        </>
      );
    }
  }, [isLoading, ticketData, ticketId, ticketLocale.history.status]);

  const renderInutWrapper = useCallback(() => {
    if (isLoading) {
      return <Skeleton variant="rectangle" width="100%" height="85px" />;
    }

    if (!isLoading) {
      return (
        <Styles.InputWrapper>
          <>
            <IconButton
              testId="addFile"
              icon="IconClip"
              onClick={handleAddFile}
            />

            <Styles.InputDelimiter>
              <Input
                testId="inputMessage"
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder={ticketLocale.history.placeHolder}
              />

              {file && (
                <Styles.AttatchedFile>
                  <IconData />
                  {file.name}
                  <IconButton
                    icon="IconClose"
                    onClick={handleClearFile}
                  />
                </Styles.AttatchedFile>
              )}
            </Styles.InputDelimiter>

            <Button
              testId="submit"
              type="button"
              onClick={handleSubmit}
              disabled={loadingSubmit || (!message && !file) || (ticketData && ticketData.status === 'solved')}
              label={loadingSubmit ? <IconLoading /> : ticketLocale.history.send}
              iconRight={loadingSubmit ? null : <IconSend />}
            />
          </>
        </Styles.InputWrapper>
      );
    }
  }, [
    file,
    isLoading,
    ticketLocale,
    handleAddFile,
    handleClearFile,
    handleSubmit,
    loadingSubmit,
    message,
    ticketData,
  ]);

  if (shouldRenderEmptyState) {
    return (
      <Styles.EmptyContainer data-testid="notFound">
        <IconDoubleChat color="primaryDark" size="xl" />
        <Styles.EmptyLabel>
          {ticketLocale.history.emptyLabel}
        </Styles.EmptyLabel>
        <Button label={ticketLocale.history.emptyCta} onClick={() => history.push(routesLocale.help)} size="large" />
      </Styles.EmptyContainer>
    );
  }

  return (
    <Styles.Container id="container">

      <UploadFileModal
        {...getUploadFileModalProps()}
      />

      <ViewImageModal
        {...getViewImageModalProps()}
      />

      <Styles.TicketId>
        {renderTicketId()}
      </Styles.TicketId>

      <Styles.TicketSubject>
        {isLoading
          ? <Skeleton variant="paragraph" height="75px" />
          : ticketData.subject
        }
      </Styles.TicketSubject>

      <Styles.MessagesWrapper ref={messagesWrapperRef}>
        {renderMessages()}
      </Styles.MessagesWrapper>

      {renderInutWrapper()}
    </Styles.Container>
  );
};

export default TicketHistoryHandler;
