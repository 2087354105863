import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import InputField from '@/components/Fields/InputField';
import SelectField from '@/components/Fields/SelectField';
import styles from './styles';

const FilterSitesPlans = ({
  t, classes, total, selectFilters,
}) => {
  const inputRef = useRef();

  const [inputFieldValue, setInputFieldValue] = useState('');
  const [selectFieldValue, setSelectFieldValue] = useState('');

  const showFilter = total > 1;

  const handleCleanInputField = () => {
    inputRef.current.focus();
    setInputFieldValue('');
  };

  return (
    <div className={classes.filter}>
      <span className={classes.title}>{t('lists.sitesPlans')}</span>

      {showFilter && (
        <div className={classes.content} data-testid="search-input-field">
          <InputField
            inputRef={inputRef}
            classes={{ input: classes.input, cssLabel: classes.label }}
            onChange={e => setInputFieldValue(e.target.value)}
            label={t('filter.placeholder.search')}
            value={inputFieldValue}
            icon={inputFieldValue === ''
              ? <SearchIcon className={classes.searchIcon} />
              : <CloseIcon onClick={handleCleanInputField} className={classes.closeIcon} data-testid="close-icon" />
            }
          />
          <SelectField
            classes={{
              formControl: classes.formControl,
              inputLabelCustom: classes.selectFieldLabel,
            }}
            handleChangeSelectValue={e => setSelectFieldValue(e.target.value)}
            menuItemDefaultText={t('filter.allSites')}
            selectValue={selectFieldValue}
            inputLabel={t('filter.allSites')}
            filterOptions={selectFilters}
            selectType="planStatus"
            labelWidth={94}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(withI18n()(FilterSitesPlans));
