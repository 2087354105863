/* chat */
export const HG_CHAT_URL = country => `https://latam-files.hostgator.com/chat/hg-chat.js?country=${country}`;
export const HG_CHAT_URL_V1 = country => `https://latam-files.hostgator.com/chat/hg-chat-v1.${country}.js`;

/* zendesk */
export const ZENDESK_URL = keyChatQueues => `https://static.zdassets.com/ekr/snippet.js?key=${keyChatQueues}`;

/* recaptcha */
export const RECAPTCHA_V3_URL = key => `https://www.google.com/recaptcha/api.js?render=${key}`;

/* wootric */
export const WOOTRIC_URL = 'https://cdn.wootric.com/wootric-sdk.js';

/* Billing */
export const PAYPAL_CHECKOUT_SCRIPT_URL = 'https://www.paypalobjects.com/api/checkout.js';
