import React from 'react';
import { useBenefitParams } from '@/hooks/billing/subscriptions/useBenefitParams';
import { useSelector } from 'react-redux';
import { RetentionOfferDiscountInNextRenew } from '@/components/billing';

const RetentionOfferDiscountInNextRenewPage = () => {
  const { currentItem } = useBenefitParams();
  const subscription = useSelector(state => state.billing.subscription);
  const reactivationData = subscription && subscription.reactivation && subscription.reactivation.length > 0 && subscription.reactivation[0];

  return (
    <>
      <RetentionOfferDiscountInNextRenew
        loading={false}
        titleVersion={0}
        currentItem={currentItem}
        reactivationData={reactivationData}
      />
    </>
  );
};

export default RetentionOfferDiscountInNextRenewPage;
