import { withStyles } from '@material-ui/core';
import React from 'react';
import { withI18n } from 'react-i18next';
import SelectField from '@/components/Fields/SelectField';
import styles from './styles';


const PaymentMethod = ({
  paymentMethod, setPaymentMethod, methodOptions,
}) => (

  <SelectField
    filterOptions={methodOptions}
    selectValue={paymentMethod}
    selectType="paymentMethod"
    menuItemDefaultText="hidden"
    handleChangeSelectValue={e => setPaymentMethod(e.target.value)}
    selectTestId="selectButton"
    selectInputTestId="selectField"
  />

);

export default withI18n()(withStyles(styles)(PaymentMethod));
