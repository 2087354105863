import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 40px 58px 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const HeadContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      border-radius: 0;
    };
  `}
`;

export const DomainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    margin: 0;
  };
`;

export const Text = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.primaryExtraDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
  `}
`;

export const DomainLabel = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
  `};
`;

export const ChangeDomainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    flex-direction: column;
  };
`;

export const PlanName = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.primaryExtraDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const FaqRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 68px;
`;

export const FooterText = styled(Text)`
  color: ${({ theme }) => theme.v2.colors.neutral.lowLight};
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    display: none;
  }
`;


// Skeleton

export const SkeletonItem = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    position: relative;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex-wrap: wrap;
      gap: 16px;
    }
  `}
`;

export const SkeletonText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 40px;
  width: calc(100% - 40px);
`;

export const SkeletonHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 609px;
  width: 100%;

  & > :first-child {
    margin-bottom: 8px;
  }
`;

export const SkeletonButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 100%;
  margin-right: 24px;
  position: absolute;
  right: 0;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    margin-top: 16px;
    position: relative;
  };
`;
