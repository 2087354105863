import React, {
  useContext, useEffect, useState, useMemo, useCallback,
} from 'react';
import { withStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { find, propEq } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import Loader from '@/components/Layout/Loader';
import { EmailsContext } from '@/contexts/Emails/context';
import { proEmailAuth } from '@/redux/actions/emails';
import { COUNTRY } from '@/config';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { loadFaqEmails, loadFaqParam } from '@/redux/actions/faq';
import { TITAN_FAQ } from '@/config/urls/supportUrls';
import TitanMailTitle from './TitanMailTitle';
import styles from './styles';
import faqParams from './faqParams';

const TitanMail = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { domain, section, action } = useParams();

  const faqQuestions = useSelector(state => state.faq);
  const [questions, setQuestions] = useState(null);
  const [faq, setFaq] = useState('');
  const history = useHistory();

  const { loadingProEmails, firstLoad, renderedEmailList } = useContext(EmailsContext);

  const loading = loadingProEmails || firstLoad;
  const planObject = useMemo(() => find(propEq('domain', domain), renderedEmailList), [domain, renderedEmailList]);
  const partnerId = process.env.REACT_APP_TITAN_PARTNER_ID;
  const titanUrl = process.env.REACT_APP_TITAN_URL;
  const [titanMailToken, setTitanMailToken] = useState(null);

  const postMessageListener = useCallback((event) => {
    if (event.data.event) {
      if (event.data.data.action === 'buyMoreAccounts') {
        history.push(`${t('routes.emailsList')}${t('routes.titanInboxUpgrade')}/${domain}`);
      }

      if (event.data.data.action === 'upgradePlan') {
        history.push(`${t('routes.emailsList')}${t('routes.titanUpgrade')}/${domain}`);
      }

      if (event.data.data.action === 'renewOrder') {
        history.push(`${t('routes.billing')}${t('routes.unpaid')}`);
      }

      setFaq(event.data.data.pageName);
    }
  }, [domain, t, history]);

  useEffect(() => {
    if (planObject && planObject.status !== 'active') {
      history.push(`${t('routes.emailsList')}`);
    }

    dispatch(loadFaqEmails());

    faqParams.forEach((param) => {
      dispatch(loadFaqParam(param));
    });

    window.addEventListener('message', event => postMessageListener(event));

    if (renderedEmailList.length > 0) {
      dispatch(proEmailAuth(planObject.pro_email_id)).then((response) => {
        if (response.data) {
          setTitanMailToken(response.data.token);
        }
      });
    }

    return () => { window.removeEventListener('message', event => postMessageListener(event)); };
  }, [renderedEmailList, dispatch, planObject, postMessageListener, t, history]);

  useEffect(() => {
    switch (faq) {
      case 'EMAIL_ACCOUNTS_LIST':
        if (faqQuestions['pro-account']) {
          setQuestions(faqQuestions['pro-account'].questions);
        }
        break;
      case 'IMPORT_EMAIL':
        if (faqQuestions['import-account']) {
          setQuestions(faqQuestions['import-account'].questions);
        }
        break;
      case 'CATCH_ALL_EMAIL':
        if (faqQuestions['pro-getall']) {
          setQuestions(faqQuestions['pro-getall'].questions);
        }
        break;
      case 'EMAIL_FORWARDER':
        if (faqQuestions['pro-internal-mail']) {
          setQuestions(faqQuestions['pro-internal-mail'].questions);
        }
        break;
      case 'DOMAIN_ACTIVATION':
        if (faqQuestions['pro-check-dns']) {
          setQuestions(faqQuestions['pro-check-dns'].questions);
        }
        break;
      case 'CONFIGURE_DESKTOP':
        if (faqQuestions['pro-desktop']) {
          setQuestions(faqQuestions['pro-desktop'].questions);
        }
        break;
      case 'DEVICE_DOWNLOAD':
        if (faqQuestions['pro-app']) {
          setQuestions(faqQuestions['pro-app'].questions);
        }
        break;
      case 'PRO_HOME':
      default:
        if (faqQuestions['pro-home']) {
          setQuestions(faqQuestions['pro-home'].questions);
        }
    }
  }, [faq, faqQuestions]);

  if (loading || planObject === undefined) {
    return <Loader />;
  }

  return (
    <>
      <TitanMailTitle domain={domain} name={planObject.plan} />
      <iframe
        className={classes.iframe}
        src={action ? `${titanUrl}partner/cpWidget?partnerId=${partnerId}&jwt=${titanMailToken}&section=${section || 'home'}&action=${action}&locale=${COUNTRY === 'br' ? 'pt-br' : 'es'}` : `${titanUrl}partner/cpWidget?partnerId=${partnerId}&jwt=${titanMailToken}&section=${section || 'home'}&locale=${COUNTRY === 'br' ? 'pt-br' : 'es'}`}
        title="Embedding of Titan iframe within Partner environment"
      />

      {(faq && questions) && (
        <FaqDefault
          className={classes.faqRemoveSpaces}
          questions={questions}
          link={TITAN_FAQ}
          loading={faqQuestions.loading}
        />
      )}
    </>
  );
};

export default withI18n()(withStyles(styles)(TitanMail));
