import { CALL_API } from '@/middleware/api';
import { DOMAIN_RENEWAL_URL } from '@/config/api';
import { updateDomainRenewalGeneralProducts } from './generalProducts';
import {
  RECEIVE_DOMAIN_DETAIL_RENEWAL, REQUEST_DOMAIN_RENEWAL, ERROR_DOMAIN_RENEWAL,
} from './actionsTypes';


function receiveDomainRenewal(domainRenewal) {
  return {
    type: RECEIVE_DOMAIN_DETAIL_RENEWAL,
    domainRenewal,
  };
}

const updateDomainRenewal = domainRenewal => (dispatch, getState) => {
  const state = getState();
  const generalProducts = state.generalProducts.byId;

  if (state.domainDetail.id !== undefined) {
    dispatch(receiveDomainRenewal(domainRenewal));
  }
  if (Object.keys(generalProducts).length > 0) {
    dispatch(updateDomainRenewalGeneralProducts(domainRenewal));
  }
};

export function loadDomainRenewal(domainId, donotrenew) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: DOMAIN_RENEWAL_URL(domainId),
      method: 'PUT',
      body: { donotrenew },
      actionTypes: {
        request: () => ({ type: REQUEST_DOMAIN_RENEWAL }),
        success: data => updateDomainRenewal(data.data),
        error: () => ({ type: ERROR_DOMAIN_RENEWAL }),
      },
    },
  };
}
