import React, { useState, useEffect, useRef } from 'react';
import * as Styles from './DnsWizardCustomServerFormField.styles';
import { IconChevronRight, IconThrashCan } from '@/icons';
import { domainsAnalytics } from '@/analytics';
import { Button } from '@/pages/common/v1/Button';
import { ERROR_MESSAGES } from '@/enums/domains/dns.enum';

const DnsWizardCustomServerFormField = ({
  testId = 'dnswizardcustomserverformfield',
  nsLabelText = 'Servidor 1:',
  nsPlaceholderText = 'ns1.seuservidor.com',
  ipLabelText =
    <>
      {'Endereço IP '}
      <small>(servidor 1):</small>
    </>,
  ipPlaceholderText = 'digite endereço IP',
  domain = 'placeholder.com',
  setDns = () => {},
  dns = { required: true },
  index,
  handleDeleteByIndex = p => p,
  getMessage = p => p,
  focused,
}) => {
  const [shouldShowConfigIpAddress, setShouldShowConfigIpAddress] = useState(false);
  const [nameServer, setNameServer] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [nameServerError, setNameServerError] = useState(null);
  const [ipError, setIpError] = useState(null);
  const inputRef = useRef();


  const { required } = dns;

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;
  const {
    dnsWizardDeleteAdditionalNameServerClick,
  } = dnsWizardAnalytics;

  const validateNameServerInput = (ns) => {
    const regex = /[^-a-z0-9.]+/i;
    return !regex.test(ns);
  };

  const validateNameServer = (ns) => {
    if (ns === '') {
      return true;
    }
    const regex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(ns);
  };

  const validateIpAddressInput = (ip) => {
    const regex = /[^-0-9.]+/;
    return !regex.test(ip);
  };

  const validateIpAddress = (ip) => {
    if (ip === '') {
      return true;
    }
    const regex = /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/;
    return regex.test(ip);
  };

  const checkIfSameDomain = (ns) => {
    const re = new RegExp(`.*\\.${domain}$`, 'i');
    return !!ns.match(re);
  };

  const handleSetNameServer = (e) => {
    e.preventDefault();
    const nsInput = e.target.value;

    const isSameDomain = checkIfSameDomain(nsInput);
    setShouldShowConfigIpAddress(isSameDomain);

    if (validateNameServerInput(nsInput)) {
      setNameServer(nsInput);
    }
  };

  const handleValidateFullNameServer = () => {
    const isNsValid = validateNameServer(nameServer);
    const isNsNotEmpty = nameServer.trim() !== '';
    const valid = isNsValid && (isNsNotEmpty || !required);

    setNameServerError(isNsValid ? null : getMessage(ERROR_MESSAGES.INVALID_SERVER));

    setDns({
      ...dns, ip: ipAddress, ns: nameServer, valid,
    });
  };

  const handleDeleteField = () => {
    dnsWizardDeleteAdditionalNameServerClick();
    handleDeleteByIndex(index);
  };

  const handleSetIpAddress = (e) => {
    e.preventDefault();
    const ipInput = e.target.value;

    const isSameDomain = checkIfSameDomain(nameServer);

    if (isSameDomain && validateIpAddressInput(ipInput)) {
      setIpAddress(ipInput);
    }
  };

  useEffect(() => {
    setNameServer(dns.ns);
    setIpAddress(dns.ip);
  }, [dns]);

  useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    }
  }, [focused]);

  const RenderedButtons = ({ handleDeleteField = () => {}, hidden = false }) => (
    <Styles.Icon visible={!hidden && !required}>
      <Button onClick={handleDeleteField} variant="text" customStyles={{ padding: '0px' }} label={(<IconThrashCan size={24} />)} />
    </Styles.Icon>
  );

  const handleValidateFullIpAddress = () => {
    const isIpValid = validateIpAddress(ipAddress);
    const isIpNotEmpty = ipAddress.trim() !== '';
    if (!isIpNotEmpty) {
      setIpError(getMessage(ERROR_MESSAGES.REQUIRED));
      return;
    }
    const valid = isIpValid && (isIpNotEmpty || !required);

    setIpError(isIpValid ? null : getMessage(ERROR_MESSAGES.INVALID_IP));

    setDns({
      ...dns, ip: ipAddress, ns: nameServer, valid,
    });
  };

  useEffect(() => {
    setNameServer('');
    setIpAddress('');
    handleValidateFullNameServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.DnsWizardCustomServerFormFieldWrapper data-testid={testId}>
      <Styles.FieldSet visible data-testid="wizard-first-form">
        <Styles.Label htmlFor="name-server">{nsLabelText}</Styles.Label>
        <Styles.InputWrapper>
          <Styles.ErrorWrapper>
            <Styles.TextInput
              id="name-server"
              data-testid="name-server"
              placeholder={nsPlaceholderText}
              value={nameServer}
              onChange={handleSetNameServer}
              onBlur={handleValidateFullNameServer}
              ref={inputRef}
            />
            <Styles.ErrorText>{nameServerError}</Styles.ErrorText>
          </Styles.ErrorWrapper>
          <Styles.RenderedButtonsWrapper>
            <RenderedButtons handleDeleteField={handleDeleteField} />
          </Styles.RenderedButtonsWrapper>
        </Styles.InputWrapper>

      </Styles.FieldSet>
      <Styles.Divider visible={!required || shouldShowConfigIpAddress} data-testid="wizard-divider">
        {shouldShowConfigIpAddress ? (<IconChevronRight size={24} />) : (
          <RenderedButtons handleDeleteField={handleDeleteField} />
        )}
      </Styles.Divider>
      <Styles.FieldSet visible={shouldShowConfigIpAddress} forIpAddress data-testid="wizard-second-form">
        <Styles.Label htmlFor="ip-address">{ipLabelText}</Styles.Label>
        <Styles.InputWrapper>
          <Styles.ErrorWrapper>
            <Styles.TextInput
              id="ip-address"
              data-testid="ip-address"
              placeholder={ipPlaceholderText}
              value={ipAddress}
              onChange={handleSetIpAddress}
              onBlur={handleValidateFullIpAddress}
            />
            <Styles.ErrorText>{ipError}</Styles.ErrorText>
          </Styles.ErrorWrapper>
          <Styles.RenderedButtonsWrapper>
            <RenderedButtons handleDeleteField={handleDeleteField} hidden />
          </Styles.RenderedButtonsWrapper>
        </Styles.InputWrapper>
      </Styles.FieldSet>
      <Styles.Divider visible={shouldShowConfigIpAddress} data-testid="second-wizard-divider" isIp>
        <RenderedButtons handleDeleteField={handleDeleteField} hidden={!shouldShowConfigIpAddress} />
      </Styles.Divider>
    </Styles.DnsWizardCustomServerFormFieldWrapper>
  );
};

export default DnsWizardCustomServerFormField;
