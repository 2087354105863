import styled, { css } from 'styled-components';
import modalDecoLine from '@/media/modalCreateSite/decoline.png';

export const ContentWrapper = styled.div`
  ${({ theme, enableNewCreateSiteModalBehavior }) => css`
    padding: ${enableNewCreateSiteModalBehavior ? '40px 119px 60px 88px' : '40px 192px 90px 96px'};
    position:relative;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 100%;
      padding: 133px 73px 224px 36px;
    }
  `}
`;

export const Title = styled.h6`
  ${({ theme, enableNewCreateSiteModalBehavior }) => {
    const titleStyle = {
      true: {
        fontWeight: theme.v1.font.weights.regular,
        margin: '0px 0px 16px 0px',
        lineHeight: '24px',
        overflowX: 'unset',
      },
      false: {
        fontWeight: theme.v1.font.weights.bold,
        margin: '0px 0px 8px 0px',
        lineHeight: '32px',
        overflowX: 'hidden',
      },
    };

    return css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${titleStyle[enableNewCreateSiteModalBehavior].fontWeight};
    letter-spacing: 0px;
    line-height: ${titleStyle[enableNewCreateSiteModalBehavior].lineHeight};
    margin: ${titleStyle[enableNewCreateSiteModalBehavior].margin};
    overflow-x: ${titleStyle[enableNewCreateSiteModalBehavior].overflowX};
    text-overflow: ellipsis;
    span {
      color: ${theme.v1.color.action.hover};
      font-weight: ${theme.v1.font.weights.bold};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-bottom: 16px;
    }
  `;
  }}
`;

export const Content = styled.p`
  ${({ theme, enableNewCreateSiteModalBehavior }) => {
    const titleStyle = {
      true: {
        fontSize: '16px',
        lineHeight: '21.6px',
        margin: '0px 0px 26px 0px',
        fontWeight: '400',
        spanColor: theme.v1.color.text.primary,
        spanWeight: theme.v1.font.weights.bold,
      },
      false: {
        fontSize: '14px',
        lineHeight: '18px',
        margin: '0px 0px 18px 0px',
        fontWeight: '400',
        spanColor: theme.v1.color.action.hover,
        spanWeight: theme.v1.font.weights.medium,
      },
    };

    return css`
    color: ${theme.v1.color.text.primary};
    display: inline-block;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${titleStyle[enableNewCreateSiteModalBehavior].fontSize};
    line-height: ${titleStyle[enableNewCreateSiteModalBehavior].lineHeight};
    margin: ${titleStyle[enableNewCreateSiteModalBehavior].margin};
    span {
      color: ${titleStyle[enableNewCreateSiteModalBehavior].spanColor};
      font-weight: ${titleStyle[enableNewCreateSiteModalBehavior].spanWeight};
    }
  `;
  }}
`;

export const Action = styled.div`
  ${({ theme, enableNewCreateSiteModalBehavior }) => css`
    display: flex;
    gap: 30px;
    margin-top: 6px;
    position: relative;
    z-index: 4;

    button:last-child {
      background-color: ${theme.v1.color.white.primary};
      padding: 0px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
      gap: ${enableNewCreateSiteModalBehavior ? '8px' : '12px'};
      button:first-child {
        width: ${enableNewCreateSiteModalBehavior ? 'fit-content' : '120px'};
      }
      button:last-child {
        padding: ${enableNewCreateSiteModalBehavior ? '13px 20px' : '0px'};
        width: ${enableNewCreateSiteModalBehavior ? 'fit-content' : '260px'};
      }
    }
  `}
`;

export const DecoLine = styled.img`
  background-image: url(${modalDecoLine});
  bottom: 0;
  height:6px;
  left: 0;
  object-fit:none;
  position:absolute;
  width: 100%;
`;

export const Deco = styled.img`
  ${({ theme }) => css`
    height: 121px;
    left: 0;
    object-fit:none;
    position:absolute;
    top: 0;
    width: 53px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 80px;
    }
 `}
`;

export const DecoIcons = styled.div`
  ${({ theme }) => css`
    bottom: 51px;
    display: flex;
    height: 72px;
    position: absolute;
    right: 33px;
    z-index: ${theme.v1.layers.overlay};
    span:last-child{
      align-self: end;
      margin-left: 4px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      bottom: 63px;
      height: 105px;
      right: 50px;
    }
  `}
`;

export const DecoTriangle = styled.div`
  ${({ theme, enableNewCreateSiteModalBehavior }) => css`
    background:linear-gradient(to bottom right,#ffffff00 50%,${theme.v1.color.background.wizard} 50%);
    bottom: 0;
    height: ${enableNewCreateSiteModalBehavior ? '87px' : '165px'};
    position:absolute;
    right: 0;
    width: ${enableNewCreateSiteModalBehavior ? '110px' : '207px'};
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 243px;
      width: 306px
    }
  `}
`;

export const SnappyFullBody = styled.img`
  ${({ theme }) => css`
    display: none;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      bottom: 45px;
      display: block;
      position: absolute;
      right: 44px;
      z-index:2;
    }
  `}
`;
