import React from 'react';
import simpleEs from '@/media/security/simple_es.svg';
import codeguard from '@/media/security/codeguard.svg';
import { Link } from 'gatorcomponents';

export default {
  cards: {
    title: 'Backup y Seguridad',
    description: (
      <>
        {'¿Necesita restaurar sus datos o más seguridad en el sitio? '}
        <strong>Consulte a continuación las opciones disponibles:</strong>
      </>
    ),
    items: [
      {
        id: 'simple',
        title: 'BACKUP SIMPLE Y MANUAL',
        imgSrc: simpleEs,
        list: [
          <>
            <strong>Seguridad al alcance de su mano:</strong>
            {' Haga copias de su hosting cPanel sin costo adicional;'}
          </>,
          <>
            <strong>Restaure cuando necesite:</strong>
            {' Restaure versiones anteriores de su sitio fácilmente.'}
          </>,
          <>
            <strong>¿No tiene un backup (copia de seguridad) para restaurar?</strong>
            {' Solicite soporte por una pequeña tarifa.'}
          </>,
        ],
        action: 'Acceder',
      },
      {
        id: 'codeguard',
        title: 'BACKUP AUTOMATICO',
        imgSrc: codeguard,
        list: [
          <>
            <strong>Protección simplificada:</strong>
            {' Con CodeGuard, las copias de seguridad son automáticas;'}
          </>,
          <>
            <strong>Siempre seguro:</strong>
            {' Garantice la integridad de sus datos con copias de seguridad periódicas;'}
          </>,
          <>
            <strong>Restauración rápida:</strong>
            {' Recupere su sitio sin problemas y evite los procesos manuales.'}
          </>,
        ],
        action: 'Sepa más',
      },
    ],
  },
  modal: {
    title: 'Lista de Planes ',
    description: 'Seleccione el plan deseado para realizar su backup',
    select: {
      placeholder: 'Seleccione um plan para continuar',
      label: 'Planes',
    },
    goToLabel: 'Ir para Backup',
    status: {
      Active: 'Activo',
      Suspended: 'Suspendido',
      Cancelled: 'Cancelado',
      Terminated: 'Cancelado',
    },
  },
  detailsPage: {
    tabs: {
      threats_detector: {
        title: '',
        paidTitle: '',
        noThreatsFounded: '',
        neverScanned: '',
        willShowScan: '',
        hasSitelock: '',
        threatsFounded: threats => (
          <>
            {threats}
          </>
        ),
        lastCheck: '',
        nextCheck: '',
        verifiedPages: '',
        verifiedFiles: '',
        noMalwaresFounded: '',
        malwaresFounded: malwares => (
          <>
            {malwares}
          </>
        ),
        noVulnerabilitiesFounded: '',
        vulnerabilitiesFounded: vulnerabilities => (
          <>
            {vulnerabilities}
          </>
        ),
        noBlocklistsFounded: '',
        blocklistsFounded: blocklists => (
          <>
            {blocklists}
          </>
        ),
        paidPlanNote: () => '',
        freePlanNote: () => '',
        signSitelockCard: {
          howToFixThreats: '',
          howToFixThreatsDescription: '',
          tag: '',
          title: '',
          description: '',
          subscribeButton: '',
        },
      },
      last_scans: {
        title: '',
        scanDate: '',
        pagesVerified: '',
        filesVerified: '',
        threatsFound: '',
        filesFixed: '',
        filesNeedFix: '',
        status: '',
        safe: '',
        unsafe: '',
        description: () => (
          <>
          </>
        ),
        emptyStateLabel: '',
        loadMore: '',
        signSitelockCard: {
          howToFixThreats: '',
          howToFixThreatsDescription: '',
          tag: '',
          title: '',
          description: '',
          subscribeButton: '',
        },
        emptyScans: '',
        goToFTP: '',
      },
      security_seal: {
        title: 'Selo de Segurança',
        description: {
          0: 'Adicione um Selo de Segurança no seu site e mostre para os seus visitantes que ele é seguro para navegação.',
          1: (
            <>
              <strong>Atenção:</strong>
              {' Se houver problemas identificados na verificação do SiteLock, é importante corrigi-los em alguns dias para manter o selo. Caso contrário, o selo será removido. Não alertamos os visitantes sobre falhas na verificação.'}
            </>
          ),
        },
        preview: 'Pré-visualização:',
        saveToView: 'Salve as alterações para visualizar as mudanças',
        howToInsert: (
          <>
            <Link
              text="Como inserir o selo de segurança do SiteLock no site"
              href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000393034-Como-inserir-no-site-o-selo-de-seguran%C3%A7a-do-SiteLock-?_ga=2.38925859.1211142327.1705338544-165595683.1692209116"
            />
          </>
        ),
        labelCopyInput: 'Insira o código abaixo no seu site para instalar o selo:',
        edit: 'Editar',
        copy: 'Copiar Selo',
        cancel: 'Cancelar',
        save: 'Salvar',
        colors: {
          white: 'Branco',
          red: 'Vermelho',
        },
        sizes: {
          small: 'Pequeno',
          medium: 'Médio',
          big: 'Grande',
        },
        type: {
          mal: 'Sem Malware',
          secure: 'Seguro',
        },
        language: {
          br: 'Português',
          es: 'Espanhol',
          en: 'Inglês',
        },
        labelColor: 'Cor',
        labelSize: 'Tamanho',
        labelLanguage: 'Idioma',
        labelStyle: 'Estilo',
        successfullUpdate: 'Alterações no selo salvas!',
        failedfullUpdate: 'Erro ao salvar as alterações no selo.',
      },
      configurations: {
        title: '',
        ftpLabel: '',
        ftpDescription: '',
        serverUrl: '',
        serverUrlPlaceholder: '',
        username: '',
        usernamePlaceholder: '',
        password: '',
        passwordPlaceholder: '',
        save: '',
        tooltip: (
          <>
          </>
        ),
        doubts: () => (
          <>
          </>
        ),
        successfullUpdate: '',
        failedfullUpdate: '',
        required: '',
      },
    },
    sitelockPanelButton: '',
    faqButton: '',
  },
  upgrade: {
    tag: '',
    title: '',
    selectedDomain: domain => (
      <>
        <>{domain}</>
      </>
    ),
    paymentCicleLabel: '',
    cycleOptions: {
      annually: '',
      semiannually: '',
      quarterly: '',
      monthly: '',
    },
    cards: {
      currentPlanLabel: '',
      buyNowButtonLabel: '',
      valueToPaidLabel: '',
      defaultOnHostPlanLabel: '',
      items: [
        {
          plan: '',
          pid: null,
          title: '',
          list: [
            <></>,
            '',
            '',
          ],
        },
        {
          plan: '',
          pid: null,
          title: '',
          list: [
            <></>,
            '',
            '',
            '',
            '',
            <></>,
          ],
        },
        {
          plan: '',
          pid: null,
          title: '',
          list: [
            <></>,
            '',
            <></>,
          ],
        },
        {
          plan: '',
          pid: null,
          title: '',
          list: [
            <></>,
            '',
            <></>,
          ],
        },
      ],
    },
    wishOtherSitelock: () => (<></>),
    landingPageContent: {
      detailsSectionTitle: '',
      detailsSectionFirstSectionTitle: '',
      detailsSectionFirstSectionDescription: '',
      detailsSectionFirstSectionButtonLabel: '',
      detailsSectionSecondSectionTitle: '',
      detailsSectionSecondSectionDescription: '',
      detailsSectionSecondSectionButtonLabel: '',
      discoveryTitle: '',
      benefits: [
        {
          icon: <></>,
          id: 0,
          title: '',
          description: '',
        },
        {
          icon: <></>,
          id: 1,
          title: '',
          description: '',
        },
        {
          icon: <></>,
          id: 2,
          title: '',
          description: '',
        },
      ],
      ctaTitle: '',
      ctaDescription: '',
      buttonLabel: '',
      bannerTitle: '',
      bannerLabel: '',
    },
    successModal: {
      title: name => `Já estamos configurando o ${name} em sua conta!`,
      description: 'A remoção automática de ameaças e outros recursos do seu novo plano estarão disponíveis em breve. Veja abaixo os principais:',
      listItems: {
        professional: [
          'Proteção para até 100 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Todos os recursos do Grátis',
        ],
        premium: [
          'Proteção para até 500 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Todos os recursos do Grátis',
          'Verificação de segurança de rede',
        ],
        enterprise: [
          'Proteção para até 2500 páginas',
          'Remoção automática de Malwares',
          'Verificação diária de FTP',
          'Verificação de edição de arquivos',
          'Monitoramento de blocklist',
          'Todos os recursos do Grátis',
          'Verificação de segurança de rede',
        ],
      },
      buttonLabel: 'Ir para página do SiteLock',
    },
  },
  provisionatedModal: {
    tag: 'Novidade!',
    title: 'Agora você tem acesso ao SiteLock Grátis',
    description: 'Incluímos o SiteLock Grátis em todos os domínios dos Planos M e Turbo, com isso sua hospedagem ficou ainda melhor! Veja abaixo os recursos:',
    items: [
      'Detecção de ameaças automática e diária',
      'Selo de segurança para dar mais credibilidade ao seu site',
      'Proteção para até 5 páginas por site',
    ],
    cta: 'Ir para página do SiteLock',
  },
};
