const styles = theme => ({
  cardFlexBox: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
    },
    paddingLeft: '24px',
    display: 'flex',
    alignItens: 'center',
    '& > svg': {
      marginRight: '10px',
    },
    '& > p': {
      fontWeight: '500',
      fontSize: '16px',
    },
  },
  invoiceCardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardSummaryContent: {
    paddingTop: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  arrow: {
    fill: theme.color.tundora,
  },
  dialogActions: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: theme.font.weights.medium,
    justifyContent: 'space-between',
  },
  iconClose: {
    marginTop: '-10px',
  },
});

export default styles;
