import React from 'react';
import {
  withStyles, Typography, Tooltip, Collapse,
} from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import { Lens, ExpandMore, ExpandLess } from '@material-ui/icons';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import LinkButton from '@/components/Buttons/LinkButton';
import { REGISTER_DOMAIN_URL, TRANSFER_DOMAIN_TO_HG_URL } from '@/config/urls/domainsUrls';
import { CHANGE_DNS_OTHER_PROVIDER_URL } from '@/config/urls/dnsUrls';
import styles from './styles';


const AnotherProviderBlock = ({
  classes, t, transferDomain, changeDns, setTransferDomain, setChangeDns,
}) => {
  const handleArrows = open => (open ? <ExpandLess className={classes.arrow} /> : <ExpandMore className={classes.arrow} />);

  const handleCollapse = (collapse) => {
    if (collapse === 'transferDomain') {
      setTransferDomain(!transferDomain);
    }

    if (collapse === 'changeDns') {
      setChangeDns(!changeDns);
    }
  };

  return (
    <div className={`${classes.cardWrapper} ${classes.cardWrapperColumn}`} data-testid="anotherProviderSection">
      <Typography className={`${classes.title} ${classes.titleBottom} ${classes.dBlock}`} variant="h1">{t('whichDnsToChange.title2')}</Typography>
      <Typography className={classes.groupSubtitle}>
        {t('dns.choiceOption')}
                  &nbsp;
        <Tooltip
          classes={{
            tooltip: classes.customTooltip,
            popper: classes.customPopper,
          }}
          placement="top"
          title={(
            <Trans i18nKey="whichDnsToChange.text3Tooltip_1">
              <>0</>
              <i>1</i>
              <>2</>
            </Trans>
                      )}
        >
          <span>{t('dns.choiceOption_2')}</span>
        </Tooltip>
                  &nbsp;
        {t('dns.choiceOption_3')}
      </Typography>

      <div className={classes.optionWrapper}>

        <div className={`${classes.option} ${classes.optionTable}`}>
          <span className={classes.groupTitle}>{t('dns.notRegistred.title')}</span>
          <span className={classes.groupSubtitle}>{t('dns.notRegistred.text')}</span>
          <LinkButton to={REGISTER_DOMAIN_URL} className={classes.groupLink} target="blank">{t('dns.notRegistred.link')}</LinkButton>
        </div>

        <div className={classes.orOptionWrapper}>
          <Typography className={classes.orOption}>
            {t('dns.notRegistred.orText')}
          </Typography>
        </div>

        <div className={classes.option}>
          <span className={classes.groupTitle}>{t('dns.anotherProvider.title')}</span>
          <span className={classes.groupSubtitle}>{t('dns.anotherProvider.text')}</span>
          <div className={classes.collapseWrapper}>
            <button
              type="button"
              className={classes.button}
              onClick={() => handleCollapse('transferDomain')}
              data-testid="handleCollapseButtonOne"
            >
              <span>
                <Lens className={classes.lens} />
                {t('dns.transferDomain.title')}
              </span>
              {handleArrows(transferDomain)}
            </button>

            <Collapse in={transferDomain} timeout="auto" className={classes.collapse}>
              <div className={classes.collapseContent} data-testid="collapseTransferDomain">
                <span className={classes.groupSubtitle}>{t('dns.transferDomain.text')}</span>
                <LinkButton target="blank" className={classes.link} to={TRANSFER_DOMAIN_TO_HG_URL}>{t('dns.transferDomain.link')}</LinkButton>
              </div>
            </Collapse>

            <button
              type="button"
              className={classes.button}
              onClick={() => handleCollapse('changeDns')}
              data-testid="handleCollapseButtonTwo"
            >
              <span>
                <Lens className={classes.lens} />
                {t('dns.changeDns.title')}
              </span>
              {handleArrows(changeDns)}
            </button>

            <Collapse in={changeDns} timeout="auto" className={classes.collapse}>
              <span className={classes.groupSubtitle}>{t('dns.changeDns.textUp')}</span>
              <span className={classes.groupSubtitle}>{t('dns.changeDns.textDown')}</span>
              <div className={classes.providerLinkWrapper}>
                {CHANGE_DNS_OTHER_PROVIDER_URL.map(provider => (
                  <LinkButton
                    key={shortid.generate()}
                    target="blank"
                    to={provider.url}
                    className={classes.providerLink}
                  >
                    {provider.label}
                  </LinkButton>
                ))}
              </div>
              <span className={classes.groupSubtitle}>{t('dns.changeDns.textOtherProvider')}</span>
            </Collapse>
          </div>
        </div>
      </div>

    </div>
  );
};

AnotherProviderBlock.propTypes = {
  transferDomain: PropTypes.bool.isRequired,
  changeDns: PropTypes.bool.isRequired,
  setTransferDomain: PropTypes.func.isRequired,
  setChangeDns: PropTypes.func.isRequired,
};

export default (withI18n()(withStyles(styles)(AnotherProviderBlock)));
