import React from 'react';
import { IconCheck } from 'gatorcomponents';
import * as Styles from './Chip.styles';

const Chip = ({
  id,
  label,
  isChecked,
  onClick,
}) => (
  <Styles.Chip
    isChecked={isChecked}
    onClick={onClick}
    data-testid={`chip-${id}`}
    data-status={isChecked ? 'checked' : 'unchecked'}
  >
    { isChecked && (<IconCheck color="activeMedium" size="sm" />)}
    {label}
  </Styles.Chip>
);

export default Chip;
