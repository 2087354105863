/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  withStyles, Typography, CircularProgress,
} from '@material-ui/core';
import { Trans, withI18n } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import AcademyPassAlertMessage from '@/components/AcademyPass/AcademyPassAlertMessage';
import AcademyPassWatchAgain from '@/components/AcademyPass/AcademyPassWatchAgain';
import {
  RECEIVE_ALERTS, CLEAR_REDIRECT, CLEAR_MESSAGES, RECEIVE_ACADEMY_PASS_DETAILS,
} from '@/redux/actions/actionsTypes';
import { getTokens } from '@/redux/actions/academyPass';
import styles from './styles';
import AcademyPassKeepWatchingList from './AcademyPassKeepWatchingList';
import AcademyPassStartHere from './AcademyPassStartHere';
import AcademyPassCoursesList from './AcademyPassCoursesList';
import { AcademyPassFilterHandler } from './AcademyPassFilterHandler';
import PageMessages from '@/pages/common/PageMessages';

const AcademyPass = ({ classes, t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    aborted: coursesAborted, error: coursesError, isLoading: coursesLoading, coursesList,
  } = useSelector(state => state.academyPass.courses);
  const { aborted: tokensAborted, error: tokensError, jwt } = useSelector(state => state.academyPass.tokens);
  const { data: dataAlert } = useSelector(state => state.academyPass.detail);
  const { aborted: buyFastCheckoutAborted, error: buyFastCheckoutError } = useSelector(state => state.academyPass.buyFastcheckout);
  const { isPassActive } = useSelector(state => state.academyPass.courses);
  const isacademyPassV2Enabled = true;
  const isAcademyPassFilterEnabled = true;
  const [isRecentCustomer, setIsRecentCustomer] = useState(false);
  const [paymentDataActive, setPaymentDataActive] = useState(null);

  const isBoletoPayment = alert => (alert.payment_method === 'boleto' || alert.payment_method === 'gp_boleto');
  const isPaymentPending = alert => (alert.payment_status === 'Pending');
  const isInvoiceNotPaid = alert => (alert.invoice_status !== 'Paid');
  const isPaymentActive = detail => (detail.payment_status === 'Active');

  useEffect(() => {
    dispatch({
      type: CLEAR_REDIRECT,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGES,
    });
  }, [jwt]);

  useEffect(() => {
    if (dataAlert && dataAlert.data !== null) {
      dataAlert.map((detailsHgAcademy) => {
        if (isPaymentActive(detailsHgAcademy)) {
          setPaymentDataActive(detailsHgAcademy);
        }
        return null;
      });
    }
  });

  useEffect(() => {
    if ((coursesAborted || coursesError) && (location.pathname === t('routes.courses'))) {
      dispatch({
        type: RECEIVE_ALERTS,
        data: [
          {
            message: coursesAborted ? t('academypass.list.abort') : t('academypass.list.error'),
            severity: {
              level: 'Critical',
            },
          },
        ],
      });
    }
  }, [coursesAborted, coursesError]);

  useEffect(() => {
    if ((tokensAborted || tokensError) && (location.pathname === t('routes.courses'))) {
      dispatch({
        type: RECEIVE_ALERTS,
        data: [
          {
            message: tokensAborted ? t('academypass.card.abort') : t('academypass.card.error'),
            severity: {
              level: 'Critical',
            },
          },
        ],
      });
      if (tokensError) {
        dispatch(getTokens());
      }
    }
  }, [tokensAborted, tokensError]);

  useEffect(() => {
    if (isacademyPassV2Enabled && dataAlert && dataAlert.data !== null && (location.pathname === t('routes.courses'))) {
      dataAlert.map((alert) => {
        if (!alert.severity && isBoletoPayment(alert) && isPaymentPending(alert) && isInvoiceNotPaid(alert) && !alert.expired) {
          const alertComponent = (
            <Trans i18nKey="academypass.paymentSlip">
              0
              <Link to={t('routes.billing')} className={classes.boldText}>
                1
              </Link>
              2
              <b>
                3
              </b>
              4
            </Trans>
          );
          return dispatch({
            type: RECEIVE_ACADEMY_PASS_DETAILS,
            data: [
              {
                ...alert,
                message: alertComponent,
                severity: {
                  level: 'Warning',
                },
              },
            ],
          });
        }
        return true;
      });
    }
  }, [dataAlert]);

  useEffect(() => {
    if ((buyFastCheckoutAborted || buyFastCheckoutError) && (location.pathname === t('routes.courses'))) {
      dispatch({
        type: RECEIVE_ALERTS,
        data: [
          {
            message: buyFastCheckoutAborted ? t('academypass.fastCheckout.abort') : t('academypass.fastCheckout.error'),
            severity: {
              level: 'Critical',
            },
          },
        ],
      });
    }
  }, [buyFastCheckoutAborted, buyFastCheckoutError]);

  useEffect(() => {
    if (coursesList && coursesList.length > 0) {
      const watchedCoursesList = coursesList.filter(course => (
        (course.statistics === undefined)
          || (course.statistics.summary === undefined)
          || (course.statistics.summary.progress === 0)
          ? false : (course.statistics.summary !== 0)));
      setIsRecentCustomer(watchedCoursesList.length === 0);
    }
  }, [coursesList]);

  return (
    <div>
      <PageMessages
        displayInvoiceMessage={false}
        displayDomainAlertManager={false}
      />
      <AcademyPassAlertMessage className={classes.messageBox} />
      { isAcademyPassFilterEnabled && <AcademyPassFilterHandler />}

      <div className={classes.wrapper}>
        {(!coursesLoading && isPassActive && isRecentCustomer) ? (isacademyPassV2Enabled && <AcademyPassStartHere billStatus={paymentDataActive} />) : (isacademyPassV2Enabled && <AcademyPassKeepWatchingList billStatus={paymentDataActive} />)}
        {!coursesLoading
          && (
            <Typography className={classes.title}>
              {t('academypass.list.title')}
            </Typography>
          )}
        {
            coursesLoading ? (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            ) : <AcademyPassCoursesList billStatus={paymentDataActive} cardType="course-card" renderCourses />
          }


        {isacademyPassV2Enabled && !coursesLoading && !isRecentCustomer
        && (
        <AcademyPassWatchAgain billStatus={paymentDataActive} />
        )}
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPass));
