import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const DescriptionWrapper = styled.span`
  ${({ theme }) => css`
    display:flex;

    svg{
      margin-left: 8px;

      &:hover{
        fill: ${theme.v1.color.text.featured};
      }
    }

    @media (max-width: ${theme.v1.breakpoints.sm}){
      display:inline-block;

      > div {
        display:inline-block;
      }
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${() => css`
    max-width: 464px;
    margin: 40px auto;
  `}
`;

export const ThemesContainer = styled.div`
  ${() => css`
    display:flex;
    justify-content:center;
  `}
`;

export const ThemesWrapper = styled.div`
  ${({ theme }) => css`
    display:flex;
    max-width:752px;
    flex-wrap:wrap;
    margin-top: 40px;
    gap: 16px;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      max-width:496px;
    }
    @media (max-width: 630px) {
      max-width:240px;
    }
  `}
`;

export const ThemeImage = styled.img`
  ${() => css`
    width: 224px;
    height:151px;
  `}
`;
