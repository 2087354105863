import styled, { css } from 'styled-components';
import dots from '../../media/funny_dots.svg';
import { templates as templatesEnum } from '../../LinkNaBioWizardHandler.enum';

// THIS IS A MVP SO THE COLORS ARE FROM LINK NA BIO PRODUCT, NOT FROM CLIENT AREA
const colorSchemes = {
  HOSTGATOR_DARK_BLUE: {
    primaryColor: '#1F2044',
    secondaryColor: '#FFFFFF',
    headerTextColor: '#FFFFFF',
    linkColor: '#FFFFFF',
    linkTextColor: '#1F2044',
    funny: {
      color: '#1F2044',
      backgroundColor: '#FFFFFF',
      borderColor: '#040A10',
      textColor: '#FFFFFF',
    },
    minimalist: {
      borderColor: '#FFFFFF3D',
    },
  },
  NEON: {
    primaryColor: '#14FFEC',
    secondaryColor: '#212121',
    headerTextColor: '#212121',
    linkColor: '#212121',
    linkTextColor: '#14FFEC',
    funny: {
      color: '#212121',
      backgroundColor: '#FFFFFF',
      borderColor: '#212121',
      textColor: '#212121',
    },
    minimalist: {
      borderColor: '#2121213D',
    },
  },
  PASTEL: {
    primaryColor: '#FFF8E0',
    secondaryColor: '#171717',
    headerTextColor: '#171717',
    linkColor: '#171717',
    linkTextColor: '#FFF8E0',
    funny: {
      color: '#171717',
      backgroundColor: '#FFFFFF',
      borderColor: '#171717',
      textColor: '#171717',
    },
    minimalist: {
      borderColor: '#1717173D',
    },
  },
  WARM: {
    primaryColor: '#EBDAFB',
    secondaryColor: '#FD5F37',
    headerTextColor: '#FD5F37',
    linkColor: '#FD5F37',
    linkTextColor: '#FFFFFF',
    funny: {
      color: '#040A10',
      backgroundColor: '#FFFFFF',
      borderColor: '##040A10',
      textColor: '#040A10',
    },
    minimalist: {
      borderColor: '#FD5F373D',
    },
  },
  HOSTGATOR_BLUE: {
    primaryColor: '#FFFFFF',
    secondaryColor: '#0070D1',
    headerTextColor: '#0070D1',
    linkColor: '#0070D1',
    linkTextColor: '#FFFFFF',
    funny: {
      color: '#0070D1',
      backgroundColor: '#FFFFFF',
      borderColor: '#0070D1',
      textColor: '#0070D1',
    },
    minimalist: {
      borderColor: '#0070D13D',
    },
  },
  SIMPLE: {
    primaryColor: '#FFFFFF',
    secondaryColor: '#1F2044',
    headerTextColor: '#1F2044',
    linkColor: '#1F2044',
    linkTextColor: '#FFFFFF',
    funny: {
      color: '#1F2044',
      backgroundColor: '#FFFFFF',
      borderColor: '#1F2044',
      textColor: '#1F2044',
    },
    minimalist: {
      borderColor: '#1F20443D',
    },
  },
  GRADIENT_ROSE: {
    isGradient: true,
    primaryColor: '#F44369',
    secondaryColor: '#3E3B92',
    headerTextColor: '#FFFFFF',
    linkColor: '#FFFFFF',
    linkTextColor: '#040A10',
    funny: {
      color: '#040A10',
      backgroundColor: '#FFFFFF',
      borderColor: '#040A10',
      textColor: '#FFFFFF',
    },
    minimalist: {
      borderColor: '#FFFFFF3D',
      iconFill: '#000000',
    },
  },
  GRADIENT_WARM: {
    isGradient: true,
    primaryColor: '#2E93EE',
    secondaryColor: '#F69E52',
    headerTextColor: '#FFFFFF',
    linkColor: '#FFFFFF',
    linkTextColor: '#040A10',
    funny: {
      color: '#040A10',
      backgroundColor: '#FFFFFF',
      borderColor: '#040A10',
      textColor: '#FFFFFF',
    },
    minimalist: {
      borderColor: '#FFFFFF3D',
      iconFill: '#000000',
    },
  },
  GRADIENT_DARK: {
    isGradient: true,
    primaryColor: '#0E1C26',
    secondaryColor: '#294861',
    headerTextColor: '#FFFFFF',
    linkColor: '#FFFFFF',
    linkTextColor: '#040A10',
    funny: {
      color: '#040A10',
      backgroundColor: '#FFFFFF',
      borderColor: '#040A10',
      textColor: '#FFFFFF',
    },
    minimalist: {
      borderColor: '#FFFFFF3D',
      iconFill: '#000000',
      icon: '#FFFFFF',
    },
  },
};

const typography = {
  MINIMALIST: {
    titleSize: '24px',
    titleWeight: '700',
    titleLine: '30px',

    descriptionSize: '10px',
    descriptionWeight: '500',
    descriptionLine: '15px',

    linkSize: '18px',
    linkWeight: '500',
    linkLine: '26px',

    font: 'Poppins',
  },
  FUNNY: {
    titleSize: '18px',
    titleWeight: '800',
    titleLine: '21px',

    descriptionSize: '15px',
    descriptionWeight: '600',
    descriptionLine: '19px',

    linkSize: '12px',
    linkWeight: '700',
    linkLine: '18px',

    font: 'Raleway',
  },
  MODERN: {
    titleSize: '15px',
    titleWeight: '500',
    titleLine: '18px',

    descriptionSize: '10px',
    descriptionWeight: '400',
    descriptionLine: '15px',

    linkSize: '12px',
    linkWeight: '600',
    linkLine: '18px',

    font: 'Poppins',
  },
  FRIENDLY: {
    titleSize: '24px',
    titleWeight: '700',
    titleLine: '30px',

    descriptionSize: '12px',
    descriptionWeight: '500',
    descriptionLine: '15px',

    linkSize: '12px',
    linkWeight: '700',
    linkLine: '18px',

    font: 'Galano',
  },
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 70px;
    position: relative;
    z-index: 1;
    
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      margin-right: 0;
    }
  `}
`;

export const Card = styled.div`
  ${({ theme, variant, withDots }) => css`
    background: ${colorSchemes[variant].isGradient ? `linear-gradient(${colorSchemes[variant].primaryColor}, ${colorSchemes[variant].secondaryColor})` : colorSchemes[variant].primaryColor};
    background-image: ${colorSchemes[variant].isGradient ? `url(${withDots && dots}), linear-gradient(${colorSchemes[variant].primaryColor}, ${colorSchemes[variant].secondaryColor})` : `url(${withDots && dots})`};
    border-radius: 16px;
    border: 0.75px solid ${theme.v2.colors.neutral.highMedium};
    box-shadow: 0px 2.25px 4.49px 0px #00000033;
    display: flex;
    flex-direction: column;
    min-height: 480px;
    width: 264px;

    @media (max-width: ${theme.breakpoints.sm}) {    
      margin-top: 46px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`;

export const IconCloseWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const PreviewLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: 16px;
    margin-bottom: 50px;
    text-align: center;
    width: 215px;
  `}
`;

export const Description = styled.h4`
  ${({ templateType }) => css`
    font-family: ${typography[templateType].font};
    font-size: ${typography[templateType].descriptionSize};
    font-weight: ${typography[templateType].descriptionWeight};
    margin-top: 4px;
    margin-bottom: 7px;
    opacity: 0.64;
    word-break: break-word;
  `}
`;

export const Title = styled.h2`
  ${({ templateType }) => css`
    font-family: ${typography[templateType].font};
    font-size: ${typography[templateType].titleSize};
    font-weight: ${typography[templateType].titleWeight};
    margin: 0;
    word-break: break-word;
  `}
`;

export const Header = styled.div`
  ${({ variant, templateType }) => {
    const templates = {
      MODERN: {
        alignItems: 'center',
        color: colorSchemes[variant].headerTextColor,
        justifyContent: 'flex-start',
        textAlign: 'center',
      },
      FRIENDLY: {
        alignItems: 'center',
        color: colorSchemes[variant].headerTextColor,
        justifyContent: 'flex-start',
        textAlign: 'center',
      },
      MINIMALIST: {
        alignItems: 'flex-start',
        color: colorSchemes[variant].headerTextColor,
        justifyContent: 'flex-start',
      },
      FUNNY: {
        alignItems: 'flex-start',
        color: colorSchemes[variant].funny.textColor,
        justifyContent: 'flex-start',
      },
    };

    return css`
      align-items: ${templates[templateType].alignItems};
      border-radius: 16px 16px 0 0;
      color: ${templates[templateType].color};
      display: flex;
      flex-direction: column;
      justify-content: ${templates[templateType].justifyContent};
      padding:  16px;
      padding-top: 42px;
      text-align: ${templates[templateType].textAlign};

      svg {
        fill: ${templates[templateType].color}
      }
    `;
  }}
`;

export const Link = styled.a`
  ${({ variant, templateType }) => {
    const templates = {
      MODERN: {
        background: colorSchemes[variant].linkColor,
        color: colorSchemes[variant].linkTextColor,
        justifyContent: 'center',
      },
      FRIENDLY: {
        background: colorSchemes[variant].linkColor,
        borderRadius: '30px',
        color: colorSchemes[variant].linkTextColor,
        justifyContent: 'center',
      },
      MINIMALIST: {
        background: 'transparent',
        borderColor: colorSchemes[variant].minimalist.borderColor,
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        color: colorSchemes[variant].isGradient ? colorSchemes[variant].linkColor : colorSchemes[variant].secondaryColor,
        justifyContent: 'space-between',
      },
      FUNNY: {
        background: colorSchemes[variant].funny.backgroundColor,
        borderColor: colorSchemes[variant].funny.borderColor,
        borderRadius: '12px',
        borderStyle: 'solid',
        borderWidth: '1px 6px 6px 1px',
        color: colorSchemes[variant].funny.color,
        hoverBorderWidth: '1px 2px 2px 1px',
        justifyContent: 'center',
      },
    };

    return css`
      
      align-items: center;
      background:  ${templates[templateType].background};
      border-color:  ${templates[templateType].borderColor};
      border-radius: ${templates[templateType].borderRadius};
      border-style: ${templates[templateType].borderStyle};
      border-width: ${templates[templateType].borderWidth};
      color:  ${templates[templateType].color};
      display: flex;
      font-family: ${typography[templateType].font};
      font-size: ${typography[templateType].linkSize};
      font-weight: ${typography[templateType].linkWeight};
      min-height: 54px;
      justify-content: ${templates[templateType].justifyContent};
      margin-bottom: 12px;
      text-decoration: none;
      transition: border-width 0.2s linear;
      width: 230px; 
      word-break: break-all;
 
      div {
        align-items: center;
        background-color: ${colorSchemes[variant].isGradient ? colorSchemes[variant].linkColor + 52 : colorSchemes[variant].secondaryColor + 52};
        display: flex;
        justify-content: center;

        svg {
          fill: ${colorSchemes[variant].isGradient ? colorSchemes[variant].linkColor : colorSchemes[variant].secondaryColor};
        }
      }

      &:last-child{
        border: ${templateType === templatesEnum.MINIMALIST && 0};
      }

      &:hover {    
        border-width: ${templates[templateType].hoverBorderWidth};

        div {
        background-color: ${colorSchemes[variant].isGradient ? colorSchemes[variant].linkColor : colorSchemes[variant].secondaryColor};

          svg {
            fill: ${colorSchemes[variant].isGradient ? colorSchemes[variant].minimalist.iconFill : colorSchemes[variant].primaryColor};
          }
        }
      }
    `;
  }}
`;

export const LinkMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    text-align: center;
    margin-top: 90px;
    width: 165px;
  `}
`;

export const Links = styled.div`
  ${({ withPadding }) => css`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: ${withPadding && '7px 16px 16px 16px'};
  `}
`;

export const IconArrowWrapper = styled.div`
  border-radius: 8px;
  height: 32px;
  width: 32px;
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  gap: 18px;
`;

export const SocialLink = styled.a`
  &:hover {
    opacity: 0.7;
  }
`;
