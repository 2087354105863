const styles = theme => ({
  container: {
    marginBottom: '20px',
  },
  /** Category * */
  category: {
    fontSize: '20px',
    borderBottom: `1px solid ${theme.color.geyser}`,
    height: '18px',
    marginBottom: '16px',
    color: '#aaa',
  },
  categoryContent: {
    backgroundColor: theme.color.white,
    paddingRight: '16px',
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '20px',
  },

  /** section * */
  section: {
    width: '20%',
    textAlign: 'right',
    paddingRight: '16px',
    paddingTop: '4px',
    fontSize: 16,
    letterSpacing: '.2px',
    color: theme.color.indigo,
    [theme.breakpoints.down('xs')]: {
      width: '30%',
    },
  },
  /** content * */
  content: {
    width: '80%',
    padding: '4px 4px 4px 8px',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
      paddingRight: '0',
    },
    '&:hover': {
      backgroundColor: '#D9D9D94D',
    },
  },
  contentBorder: {
    borderLeft: `1px solid ${theme.color.alto}`,
  },
  wrapperWithoutLeftTitle: {
    width: '100%',
  },
  title: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: theme.color.tundora,
  },
  highlight: {
    position: 'relative',
    zIndex: '1',
    '& > em': {
      backgroundColor: '#e6f4f9',
      color: theme.color.indigo,
    },
    '&:before': {
      content: '',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      opacity: '.1',
      zIndex: '-1',
    },
  },
  /** body * */
  body: {
    fontSize: '13px',
    color: theme.color.regentGray,
    marginTop: '4px',
    lineHeight: '1.25em',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export default styles;
