import { path } from 'ramda';
import {
  FORCE_DNS_LOADING_STATUS,
  ERROR_DNS_ZONE_MANAGER_ADD_RECORD,
  REQUEST_DNS_ZONE_MANAGER_ADD_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD,
  REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD,
  ERROR_DNS_ZONE_MANAGER_EDIT_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD,
  ERROR_DNS_ZONE_MANAGER_DELETE_RECORD,
  REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD,
  REQUEST_DNS_ZONE_MANAGER_GET_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_GET_RECORD,
  ERROR_DNS_ZONE_MANAGER_GET_RECORD,
} from '@/redux/actions/actionsTypes';
import { CALL_API } from '@/middleware/api';
import { DNS_ZONE_MANAGER } from '@/config/api';
import { COUNTRY } from '@/config';

export const receiveAddRecord = data => ({
  type: RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD,
  data,
});

export const handleError = (data, type) => {
  const errors = path(['response', 'data', 'errors'], data);
  return ({
    type,
    data: errors,
  });
};

export const requestAddRecord = data => async (dispatch, getState) => {
  const { dnsDetails, summary } = getState();
  const { details } = dnsDetails;

  let hostingSelected = null;
  if (details.dbData && details.dbData.hosting) {
    hostingSelected = details.nameserver.hosting;
  } else {
    hostingSelected = details.availableHostings.find(item => item.domain === details.domainName);
  }

  return dispatch({
    [CALL_API]: {
      endpoint: DNS_ZONE_MANAGER(details.domainName.replace(/ /g, '')),
      method: 'POST',
      authorizationType: 'jwt',
      body: data,
      customHeaders: details.native
        ? {
          'X-NameServer-Host': details.nameserver.native.hostname,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        }
        : {
          'X-Account-ID': hostingSelected.id,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        },
      actionTypes: {
        request: () => ({ type: REQUEST_DNS_ZONE_MANAGER_ADD_RECORD }),
        success: response => receiveAddRecord(response),
        error: response => handleError(response, ERROR_DNS_ZONE_MANAGER_ADD_RECORD),
      },
    },
  });
};

export const receiveEditRecord = data => ({
  type: RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD,
  data,
});

export const requestEditRecord = data => (dispatch, getState) => {
  const { dnsDetails, summary } = getState();
  const { details } = dnsDetails;

  let hostingSelected = null;
  if (details.dbData && details.dbData.hosting) {
    hostingSelected = details.nameserver.hosting;
  } else {
    hostingSelected = details.availableHostings.find(item => item.domain === details.domainName);
  }


  return dispatch({
    [CALL_API]: {
      endpoint: `${DNS_ZONE_MANAGER(details.domainName.replace(/ /g, ''))}/${data.line}`,
      method: 'PATCH',
      authorizationType: 'jwt',
      body: data,
      customHeaders: details.native
        ? {
          'X-NameServer-Host': details.nameserver.native.hostname,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        }
        : {
          'X-Account-ID': hostingSelected.id,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        },
      actionTypes: {
        request: () => ({ type: REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD }),
        success: response => receiveEditRecord(response),
        error: response => handleError(response, ERROR_DNS_ZONE_MANAGER_EDIT_RECORD),
      },
    },
  });
};

export const receiveDeleteRecord = data => ({
  type: RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD,
  data,
});

export const requestDeleteRecord = data => (dispatch, getState) => {
  const { dnsDetails, summary } = getState();
  const { details } = dnsDetails;

  let hostingSelected = null;
  if (details.dbData && details.dbData.hosting) {
    hostingSelected = details.nameserver.hosting;
  } else {
    hostingSelected = details.availableHostings.find(item => item.domain === details.domainName);
  }


  return dispatch({
    [CALL_API]: {
      endpoint: `${DNS_ZONE_MANAGER(details.domainName.replace(/ /g, ''))}/${data.line}`,
      method: 'DELETE',
      authorizationType: 'jwt',
      customHeaders: details.native
        ? {
          'X-NameServer-Host': details.nameserver.native.hostname,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        }
        : {
          'X-Account-ID': hostingSelected.id,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        },
      actionTypes: {
        request: () => ({ type: REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD }),
        success: response => receiveDeleteRecord(response),
        error: () => ({ type: ERROR_DNS_ZONE_MANAGER_DELETE_RECORD }),
      },
    },
  });
};

export const receiveGetRecord = data => ({
  type: RECEIVE_DNS_ZONE_MANAGER_GET_RECORD,
  data,
});

export const requestGetRecord = data => async (dispatch, getState) => {
  const { dnsDetails, summary } = getState();
  const { details } = dnsDetails;

  let hostingSelected = null;
  if (details.dbData.hosting) {
    hostingSelected = details.nameserver.hosting;
  } else {
    hostingSelected = details.availableHostings.find(item => item.domain === details.domainName);
  }

  return dispatch({
    [CALL_API]: {
      endpoint: DNS_ZONE_MANAGER(details.domainName.replace(/ /g, '')),
      method: 'GET',
      authorizationType: 'jwt',
      body: data,
      customHeaders: details.native
        ? {
          'X-NameServer-Host': details.nameserver.native.hostname,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        }
        : {
          'X-Account-ID': hostingSelected.id,
          'X-Brand': COUNTRY,
          'X-Client-ID': summary.id,
        },
      actionTypes: {
        request: () => ({ type: REQUEST_DNS_ZONE_MANAGER_GET_RECORD }),
        success: response => receiveGetRecord(response),
        error: () => ({ type: ERROR_DNS_ZONE_MANAGER_GET_RECORD }),
      },
    },
  });
};

export const forceDnsLoadingStatus = bool => ({
  type: FORCE_DNS_LOADING_STATUS,
  payload: { bool },
});
