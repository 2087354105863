import React, { useEffect, useState } from 'react';
import * as Styles from './SelectButton.styles';

const SelectButton = ({
  variant = 'default',
  title,
  subtitle,
  onChange,
  iconLeft,
  iconRight,
  type = 'radio',
  checked = false,
  id = 'SelectButton',
  testId = 'SelectButton',
  value = null,
  justify = 'flex-start',
  align = 'center',
  name = 'selectName',
  truncate = false,
  truncateMaxWidth,
  truncateTitle,
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  const handleChange = (event) => {
    onChange && onChange(event, value);

    type === 'radio'
      ? setCurrentChecked(!checked)
      : setCurrentChecked(!currentChecked);
  };

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);

  return (
    <Styles.SelectButtonWrapper data-testid={testId}>
      <Styles.SelectInput
        type={type}
        value={value}
        id={`${id}-input`}
        data-testid={`${testId}-input`}
        checked={currentChecked}
        onChange={event => handleChange(event)}
        variant={variant}
        name={name}
      />

      <Styles.SelectButtonLabel
        data-testid={`${testId}-label`}
        id={`${id}Label`}
        variant={variant}
        justify={justify}
        align={align}
        htmlFor={`${id}-input`}
      >
        {iconLeft && iconLeft}

        <Styles.LabelContent>
          {title && <Styles.Title title={truncateTitle} truncateMaxWidth={truncateMaxWidth} truncate={truncate} variant={variant}>{title}</Styles.Title>}
          {subtitle && <Styles.Subtitle variant={variant}>{subtitle}</Styles.Subtitle>}
        </Styles.LabelContent>
        {iconRight && iconRight}
      </Styles.SelectButtonLabel>
    </Styles.SelectButtonWrapper>
  );
};

export default SelectButton;
