import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PlanCard from '@/components/Cards/PlanCard';
import PlanItem from '@/components/SitesPlans/PlanItem';
import FilterSitesPlans from '@/components/Filters/FilterSitesPlans';
import Loader from '@/components/Layout/Loader';
import { loadSitesPlans } from '@/redux/actions/sitesPlans';

import styles from './styles';

const SitesPlans = ({ t, classes }) => {
  const [redirectUnauthorizedUser, setRedirectUnauthorizedUser] = useState(false);
  const { id: userId } = useSelector(state => state.summary);
  const sitesPlans = useSelector(state => state.sitesPlans);
  const {
    products: plans,
    loading: loadingPlans,
    filterStatus: filterStatusPlans,
  } = sitesPlans;

  const dispatch = useDispatch();

  useEffect(() => {
    const isUserAuthorized = false;
    setRedirectUnauthorizedUser(!isUserAuthorized);
    dispatch(loadSitesPlans(userId));
  }, [dispatch, userId]);

  // WIP - handle unauthorized user
  if (redirectUnauthorizedUser) {
    return <Redirect to={t('routes.sitesPage')} />;
  }

  return (
    <div className={classes.contentWrapper}>
      {loadingPlans
        ? <Loader />
        : (
          <>
            <div className={classes.filterWrapper}>
              <FilterSitesPlans
                selectFilters={filterStatusPlans}
                total={plans.length}
              />
            </div>
            {plans.map(plan => (
              <div className={classes.planWrapper} key={plan.key}>
                <PlanCard>
                  <PlanItem plan={plan} />
                </PlanCard>
              </div>
            ))}
          </>
        )
      }
    </div>
  );
};

export default withStyles(styles)(withI18n()(SitesPlans));
