import { SitesHandler } from '@/pages/sites/SitesHandler';
import { WizardHandler } from '@/pages/sites/WizardHandler';
import SitesPlans from '@/pages/SitesPlans';
import EmailsPage from '@/pages/Emails/EmailsPage';
import DomainsPage from '@/pages/Domains/DomainsPage';
import TicketDetails from '@/pages/SupportPage/TicketDetails';
import ProductDetailPage from '@/pages/Products/ProductDetailPage';
import Dns from '@/pages/Dns';
import SupportForms from '@/components/SupportForms';
import ChangePasswordPage from '@/pages/MyData/ChangePasswordPage';
import CreateEmail from '@/pages/Emails/CreateEmail';
import SupportPage from '@/pages/SupportPage';
import CoursesPage from '@/pages/Courses/CoursesPage';
import AcademyPassCtaPage from '@/pages/Courses/AcademyPassCtaPage';
import AcademyPassTrialPage from '@/pages/academy/landing/AcademyPassTrialPage/AcademyPassTrialPage.jsx';
import MktPlace from '@/pages/MktPlace';
import SecurityPageWrapper from '@/pages/Security/SecurityPageWrapper';
import SecurityCta from '@/pages/Security/SecurityCta/SecurityCta';
import CodeGuardCta from '@/pages/Security/CodeGuardCta/CodeGuardCta';
import SitelockProductDetailsPageWrapper from '@/pages/Security/Sitelock/SitelockProductDetailsPageWrapper';
import CoursePageDetail from '@/pages/Courses/CourseDetailPage';
import RedirectToWHMPage from '@/pages/SSO/RedirectToWHMPage';
import RedirectToSiteBuilderPage from '@/pages/SSO/RedirectToSiteBuilderPage/index';
import Error404Page from '@/pages/Error404Page';
import DomainDetailPage from '@/pages/Domains/DomainDetailPage/index';
import CreditCardPage from '@/pages/CreditCard';
import TitanMail from '@/pages/Emails/TitanMail';
import TitanUpgrade from '@/pages/Emails/TitanUpgrade';
import TitanChangeCycle from '@/pages/Emails/TitanChangeCycle';
import EmailsList from '@/pages/Emails/EmailsList';
import TitanHirePlan from '@/pages/Emails/TitanHirePlan';
import {
  CancelSubscriptionPage,
  CancelSubscriptionRenewalPage,
  RetentionSubscriptionPage,
  RetentionBenefitPage,
  ManageSubscriptionPage,
  SubscriptionsPage,
} from '@/pages/billing';
import { DnsWizardPage } from '@/pages/domains';
import TitanCreateEMail from '@/pages/Emails/TitanMail/TitanCreateEMail';
import MyDataWrapper from '@/pages/MyData/MyDataWrapper';
import { Redirect } from 'react-router';
import React from 'react';
import { AsaasHandler } from '@/pages/asaas';
import VueSupportForms from '@/components/VueSupportForms/VueSupportForms';
import {
  BackupHandler,
  ProductsPage,
} from '@/pages/Products';
import InvoicesPage from '@/pages/Invoices/InvoicesPage';
import { ChangePasswordV2 } from '@/pages/MyData/ChangePasswordV2';
import { AccountSecurity } from '@/pages/MyData/AccountSecurity';
import SitelockDetailsHandler from '@/pages/Security/Sitelock/SitelockDetailsHandler';
import SiteLockUpgradeHandler from '@/pages/Security/Sitelock/SiteLockUpgradeHandler';
import DomainsListHandler from '@/pages/Domains/DomainsListHandler';
import { LinkNaBioStatistic, LinkNaBioHandler, LinkNaBioWizardHandler } from '@/pages/linkNaBio';
import { RetentionOfferPage } from '@/pages/billing/subscriptions/RetentionOfferPage';
import { RetentionOfferDiscountInNextRenewPage } from '@/pages/billing/subscriptions';
import CloudFlareHandler from '@/pages/Products/CloudFlare/CloudFlareHandler';
import ManageCloudFlareHandler from '@/pages/Products/CloudFlare/ManageCloudFlareHandler';

export const routesV2 = (t, dynamicName, featureToggles) => ([
  {
    path: `${t('routes.products')}${t('routes.cloudflare')}/:hostingId?`,
    name: t('title.v2.cloudflare'),
    icon: '',
    Component: CloudFlareHandler,
  },
  {
    path: `${t('routes.products')}${t('routes.cloudflare')}${t('routes.domaindetail')}/:domainId?`,
    name: t('title.v2.cloudflare'),
    icon: '',
    Component: ManageCloudFlareHandler,
  },
  {
    path: `${t('routes.asaas')}`,
    name: t('title.v2.asaas'),
    icon: 'gatorIconAsaas',
    Component: AsaasHandler,
  },
  {
    path: `${t('routes.linkNaBio')}`,
    name: t('title.v2.linkNaBio'),
    icon: 'gatorIconSites',
    Component: featureToggles && featureToggles.renderLinkNaBio ? LinkNaBioHandler : Error404Page,
  },
  {
    path: `${t('routes.linkNaBio')}/${t('routes.wizard')}/:profileId?`,
    name: t('title.v2.linkNaBio'),
    icon: 'gatorIconSites',
    Component: featureToggles && featureToggles.renderLinkNaBio ? LinkNaBioWizardHandler : Error404Page,
  },
  {
    path: `${t('routes.linkNaBio')}/${t('routes.dataAnalysis')}/:profileId?`,
    name: t('title.v2.dataAnalysis'),
    icon: 'gatorIconSites',
    Component: featureToggles && featureToggles.renderLinkNaBioDataAnalysis ? LinkNaBioStatistic : Error404Page,
  },
  {
    path: t('routes.sites'),
    name: t('title.v2.sites'),
    icon: 'gatorIconSites',
    Component: () => <Redirect to={t('routes.sitesPage')} />,
  },
  {
    path: t('routes.goalsObjectives'),
    name: '',
    icon: '',
    Component: () => <Redirect to={t('routes.sitesPage')} />,
  },
  {
    path: t('routes.sitesPage'),
    name: t('title.v2.sitesPage'),
    icon: 'gatorIconSites',
    Component: SitesHandler,
  },
  {
    path: `${t('routes.sitesPage')}${t('routes.sitesCreate')}/:domain?/:ecommerceplus?`,
    name: t('title.v2.sitesCreate'),
    icon: 'gatorIconSites',
    Component: WizardHandler,
  },
  {
    path: t('routes.sitesPlans'),
    name: t('title.sitesPlans'),
    icon: 'gatorIconSites',
    Component: SitesPlans,
  },
  {
    path: t('routes.emails'),
    name: t('title.v2.emails'),
    icon: 'gatorIconEmail',
    Component: EmailsPage,
  },
  {
    path: `${t('routes.filteredEmails')}/:domain`,
    name: t('title.v2.manageEmailsCpanel'),
    icon: 'gatorIconEmail',
    Component: EmailsPage,
  },
  {
    path: t('routes.products'),
    name: t('title.v2.hostsAndserver'),
    icon: 'gatorIconFile',
    Component: ProductsPage,
  },
  {
    path: t('routes.domains'),
    name: t('title.v2.domains'),
    icon: 'gatorIconDomain',
    Component: featureToggles && featureToggles.newDomainsPage ? DomainsListHandler : DomainsPage,
  },
  {
    path: t('routes.courses'),
    name: t('title.v2.courses'),
    icon: 'gatorIconCourses',
    Component: CoursesPage,
  },
  {
    path: t('routes.coursesCta'),
    name: t('title.v2.academyPass'),
    icon: 'HGAcademy',
    Component: AcademyPassCtaPage,
  },
  {
    path: t('routes.coursesTrial'),
    name: t('title.v2.subscribeAcademyPass'),
    icon: 'HGAcademy',
    Component: AcademyPassTrialPage,
  },
  {
    path: t('routes.mktPlace'),
    name: t('title.v2.toolsAndServices'),
    icon: 'gatorIconServices',
    Component: MktPlace,
  },
  {
    path: t('routes.security'),
    name: t('title.v2.securityAndBackup'),
    icon: 'gatorIconSecurity',
    Component: SecurityPageWrapper,
  },
  {
    path: t('routes.sitelock'),
    name: t('title.v2.subscribeSitelock'),
    icon: 'gatorIconSecurity',
    Component: SecurityCta,
  },
  {
    path: t('routes.codeguard'),
    name: t('title.v2.subscribeCodeGuard'),
    icon: 'gatorIconSecurity',
    Component: CodeGuardCta,
  },
  {
    path: `${t('routes.security')}/:productType/:productId`,
    name: t('title.v2.manageSiteLock'),
    icon: 'gatorIconSecurity',
    Component: SitelockProductDetailsPageWrapper,
  },
  {
    path: `${t('routes.security')}${t('routes.productdetail')}/:productType/:productId`,
    name: t('title.v2.manageSiteLock'),
    icon: 'gatorIconSecurity',
    Component: featureToggles && featureToggles.newSitelockPage ? SitelockDetailsHandler : SitelockProductDetailsPageWrapper,
  },
  {
    path: `${t('routes.security')}${t('routes.upgrade')}/:productType/:productId`,
    name: t('title.v2.upgradeSiteLock'),
    icon: 'gatorIconSecurity',
    Component: featureToggles && featureToggles.newSitelockPage ? SiteLockUpgradeHandler : Error404Page,
  },
  {
    path: `${t('routes.courses')}/:id`,
    name: dynamicName,
    icon: 'gatorIconDomain',
    Component: CoursePageDetail,
  },
  {
    path: `${t('routes.invoices')}:tab(${t('routes.unpaid')}|${t('routes.paid')}|${t('routes.cancelled')})/:invoiceId?`,
    name: t('title.v2.invoice'),
    icon: 'gatorIconInvoice',
    Component: InvoicesPage,
  },
  {
    path: `${t('routes.invoices')}:tab(${t('routes.unpaid')}|${t('routes.paid')}|${t('routes.cancelled')})/:invoiceId?openInvoice=true`,
    name: t('title.v2.invoice'),
    icon: 'gatorIconInvoice',
    Component: InvoicesPage,
  },
  {
    path: `${t('routes.invoices')}:tab(${t('routes.unpaid')}|${t('routes.paid')}|${t('routes.cancelled')})/:invoiceId/:paymentMethod`,
    name: t('title.v2.invoice'),
    icon: 'gatorIconInvoice',
    Component: InvoicesPage,
  },
  {
    path: `${t('routes.subscriptions')}`,
    name: t('title.v2.subscription'),
    icon: 'gatorIconSubscription',
    Component: SubscriptionsPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.cancelRenewal')}/:subscriptionId/:subscriptionType`,
    name: t('title.v2.cancelSubscription'),
    icon: 'gatorIconSubscription',
    Component: CancelSubscriptionRenewalPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.cancelSubscription')}/:subscriptionId/:subscriptionType`,
    name: t('title.v2.cancelSubscription'),
    icon: 'gatorIconSubscription',
    Component: CancelSubscriptionPage,
  },
  {
    path: `${t('routes.subscriptions')}${t('routes.manageSubscription')}/:subscriptionId/:subscriptionType`,
    name: t('title.v2.manageSubscriptions'),
    icon: 'gatorIconSubscription',
    Component: ManageSubscriptionPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.retentionSubscriptionConfirm')}/:retentionType/:subscriptionId/:subscriptionType`,
    name: t('title.v2.manageSubscriptions'),
    icon: 'gatorIconSubscription',
    Component: RetentionSubscriptionPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.retentionSubscriptionOffer')}/:retentionType/:subscriptionId/:subscriptionType`,
    name: t('title.v2.manageSubscriptions'),
    icon: 'gatorIconSubscription',
    Component: RetentionOfferDiscountInNextRenewPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.retentionBenefit')}/:retentionType/:subscriptionId/:subscriptionType`,
    name: t('title.v2.manageSubscriptions'),
    icon: 'gatorIconSubscription',
    Component: RetentionBenefitPage,
  },
  {
    path: `${t('routes.manageSubscription')}${t('routes.retentionSubscriptionOfferAfterQuestions')}/:retentionType/:subscriptionId/:subscriptionType/:path?`,
    name: t('title.v2.manageSubscriptions'),
    icon: 'gatorIconSubscription',
    Component: RetentionOfferPage,
  },
  {
    path: t('routes.creditcard'),
    name: t('title.v2.manageCreditCard'),
    icon: 'gatorIconCreditCard',
    Component: CreditCardPage,
  },
  {
    path: t('routes.help'),
    name: t('title.v2.support'),
    icon: 'gatorIconSupport',
    Component: SupportPage,
  },
  {
    path: `${t('routes.ticket')}/:id/:v2?`,
    name: t('title.v2.seeTicket'),
    icon: '',
    Component: TicketDetails,
  },
  {
    path: `${t('routes.sites')}${t('routes.productdetail')}/:id`,
    name: t('title.v2.manageHostOrServer'),
    icon: 'gatorIconFile',
    Component: ProductDetailPage,
  },
  {
    path: `${t('routes.products')}${t('routes.productdetail')}/:id`,
    name: t('title.v2.manageHostOrServer'),
    icon: 'gatorIconFile',
    Component: ProductDetailPage,
  },
  {
    path: `${t('routes.domains')}${t('routes.domaindetail')}/:id`,
    name: t('title.v2.manageDomain'),
    icon: 'Dashboard',
    Component: DomainDetailPage,
  },
  {
    path: `${t('routes.products')}${t('routes.domaindetail')}/:id${t('routes.dns')}`,
    name: t('title.v2.manageDomain'),
    icon: '',
    Component: Dns,
  },
  {
    path: t('routes.dns'),
    name: t('title.v2.setupDomain'),
    icon: '',
    Component: Dns,
  },
  {
    path: `${t('routes.domains')}${t('routes.dns')}/:id`,
    name: t('title.v2.setupDomain'),
    icon: '',
    Component: Dns,
  },
  {
    path: `${t('routes.help')}${t('routes.supportforms')}`,
    name: t('title.v2.openTicket'),
    icon: '',
    Component: SupportForms,
  },
  {
    path: `${t('routes.help')}${t('routes.createTicket')}/:selectedProduct?`,
    name: t('title.v2.openTicket'),
    icon: '',
    Component: VueSupportForms,
  },
  {
    path: `${t('routes.help')}${t('routes.createTicket')}/:selectedProduct/:selectedSector/:selectedNeed`,
    name: t('title.v2.openTicket'),
    icon: '',
    Component: VueSupportForms,
  },
  {
    path: t('routes.myData'),
    name: t('title.v2.myData'),
    icon: 'gatorIconData',
    Component: MyDataWrapper,
  },
  {
    path: t('routes.changePassword'),
    name: t('title.v2.changePassword'),
    icon: 'gatorIconPassword',
    Component: featureToggles && featureToggles.newChangePwPage ? ChangePasswordV2 : ChangePasswordPage,
  },
  {
    path: t('routes.accountSecurity'),
    name: t('title.v2.accountSecurity'),
    icon: 'gatorIconPassword',
    Component: featureToggles && featureToggles.renderAccountSecurity ? AccountSecurity : Error404Page,
  },
  {
    path: `${t('routes.emails')}${t('routes.createEmail')}/:domain?`,
    name: t('title.v2.manageEmailsCpanel'),
    icon: 'gatorIconEmail',
    Component: CreateEmail,
  },
  {
    path: `${t('routes.emailsList')}`,
    name: t('title.v2.emails'),
    icon: 'gatorIconEmail',
    Component: EmailsList,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.byHost')}/:hosting`,
    name: t('title.v2.emails'),
    icon: 'gatorIconEmail',
    Component: EmailsList,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanHire')}/:domain`,
    name: t('title.titanHire'),
    icon: 'gatorIconEmail',
    Component: TitanHirePlan,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanHireFreePlan')}/:domain`,
    name: t('title.titanHireFreePlan'),
    icon: 'gatorIconEmail',
    Component: TitanHirePlan,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/:domain`,
    name: t('title.v2.setEmailPlatform'),
    icon: 'gatorIconEmail',
    Component: TitanHirePlan,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/:domain`,
    name: t('title.v2.setEmailPlatform'),
    icon: 'gatorIconEmail',
    Component: TitanHirePlan,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.emailsTitan')}/:domain`,
    name: t('title.v2.manageEmailsTitan'),
    icon: 'gatorIconEmail',
    Component: TitanMail,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.createFirstEmailsTitan')}/:domain`,
    name: t('title.v2.manageEmailsTitan'),
    icon: 'gatorIconEmail',
    Component: TitanCreateEMail,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.emailsTitan')}/:domain/:section/:action?`,
    name: t('title.v2.manageEmailsTitan'),
    icon: 'gatorIconEmail',
    Component: TitanMail,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanUpgrade')}/:domain`,
    name: t('title.v2.manageEmailsTitan'),
    icon: 'gatorIconEmail',
    Component: TitanChangeCycle,
  },
  {
    path: `${t('routes.emailsList')}${t('routes.titanInboxUpgrade')}/:domain`,
    name: t('title.v2.manageEmailsTitan'),
    icon: 'gatorIconEmail',
    Component: TitanUpgrade,
  },
  {
    path: `${t('routes.whmRedirectAccess')}/:id`,
    name: '',
    icon: '',
    Component: RedirectToWHMPage,
  },
  {
    path: `${t('routes.siteBuilderRedirectAccess')}/:domain/:hostingId?`,
    name: '',
    icon: '',
    Component: RedirectToSiteBuilderPage,
  },
  {
    path: `${t('routes.domains')}${t('routes.dnsWizard')}/:domainId`,
    name: t('title.v2.setupDomain'),
    icon: '',
    Component: DnsWizardPage,
  },
  {
    path: `${t('routes.products')}${t('routes.productdetail')}/:id${t('routes.backup')}/:wizard?/:category?`,
    name: '',
    icon: '',
    Component: BackupHandler,
  },
  {
    path: '*',
    name: t('title.404Page'),
    icon: '',
    Component: Error404Page,
  },
]);

export default routesV2;
