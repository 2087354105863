import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DomainMessage from '@/components/Domain/DomainMessage';
import ModalDomainWrapped from '@/components/Domain/ModalDomainWrapped';
import { loadDomainsVerificationPending } from '@/redux/actions/domains';

const DomainAlertManager = () => {
  const dispatch = useDispatch();
  const domainsVerificationPending = useSelector(state => state.domains.verificationPending.list);
  const domainEmailVerification = useSelector(state => state.domains.emailVerification.emailVerified);
  const domainEmailVerificationSuccess = useSelector(state => state.domains.emailVerification.success);
  const loadingEmailVerification = useSelector(state => state.domains.emailVerification.loadingEmailVerification);

  const [domainModalOpen, setDomainModalOpen] = useState(false);
  const isEmptyDomainVerification = domainsVerificationPending && domainsVerificationPending.length === 0;

  const domainStatusSuspended = (!isEmptyDomainVerification && domainsVerificationPending) ? domainsVerificationPending.filter(domain => domain.verification_status === 'suspended') : false;

  const domainStatusPending = (!isEmptyDomainVerification && domainsVerificationPending) ? domainsVerificationPending.filter(domain => domain.verification_status === 'pending') : false;

  const handleToggleDomainModal = () => {
    setDomainModalOpen(!domainModalOpen);
  };

  useEffect(() => {
    dispatch(loadDomainsVerificationPending());
  }, [dispatch]);

  return (
    <>
      <DomainMessage
        action={handleToggleDomainModal}
        domainStatusPending={domainStatusPending}
        domainStatusSuspended={domainStatusSuspended}
      />

      {domainModalOpen && (
        <ModalDomainWrapped
          modal={3}
          loadingEmailVerification={loadingEmailVerification}
          domainEmailVerification={domainEmailVerification}
          domainEmailVerificationSuccess={domainEmailVerificationSuccess}
          domainsVerificationPending={domainsVerificationPending}
          open={domainModalOpen}
          onClose={handleToggleDomainModal}
        />
      )}
    </>
  );
};

export default DomainAlertManager;
