export const SITELOCK_SEAL_FORM_OPTIONS = {
  formColorOptions: [
    {
      value: 'white',
    },
    {
      value: 'red',
    },
  ],
  formSizeOptions: [
    {
      value: 'small',
    },
    {
      value: 'medium',
    },
    {
      value: 'big',
    },
  ],
  formTypeOptions: [
    {
      value: 'mal',
    },
    {
      value: 'secure',
    },
  ],
  formLanguageOptions: [
    {
      value: 'br',
    },
    {
      value: 'es',
    },
    {
      value: 'en',
    },
  ],
};

export const SITELOCK_PRODUCT_NAME = {
  SITELOCK_BASIC: 'Sitelock Basic',
  SITELOCK_PROFESSIONAL: 'Sitelock Professional',
  SITELOCK_PREMIUM: 'Sitelock Premium',
  SITELOCK_ENTERPRISE: 'Sitelock Enterprise',
};

export const SITELOCK_PROFESSIONAL_PRODUCT_ID = {
  br: 108,
};
