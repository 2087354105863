import React, {
  useState, useContext, useEffect, useCallback, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { sitesBuilderLocale, wizardLocale, surveyCountry } from './WizardHandler.locale';
import * as Styles from './WizardHandler.styles';
import { sitesActions } from '@/redux/modules/sites';
import { FeedbackStepper } from '@/pages/common';
import { ThemeContext } from 'styled-components';
import snappyDesktop from '@/media/siteBuilder/snappy-desktop.svg';
import snappyMobile from '@/media/siteBuilder/snappy-mobile.svg';
import colorLine from '@/media/siteBuilder/color-line.svg';
import Configurations from '../components/Configurations';
import { useResize } from '@/hooks/useResize';
import { WizardAbout } from '../components/WizardAbout';
import { WizardWordPressConfig } from '../components/WizardWordPressConfig';
import {
  IconArrowForward,
  IconDetailedInstitutional,
  IconDetailedPersonalPortfoil,
  IconDetailedBlog,
  IconDetailedStore,
  IconDetailedLandingPage,
  IconDetailedOther,
} from '@/icons';
import { TextButton } from '@/pages/common/v1';
import { WizardTools } from '../components/WizardTools';
import { sitesBuilderAnalytics } from '@/analytics';
import { registerCustomerProfileSurvey } from '@/redux/actions/customerProfileSurvey';
import { COUNTRY, IS_PROD, config } from '@/config';
import { faqActions, preChatActions } from '@/redux/modules';
import { useWizardTips } from '@/hooks/sites/wizard/useWizardTips';
import { WizardTips } from '../components/WizardTips';
import { wizardEnum } from './WizardHandler.enum';
import useCompareDns from '@/hooks/sites/wizard/useCompareDns/useCompareDns';
import useVerifyDomainNative from '@/hooks/sites/wizard/useVerifyDomainNative/useVerifyDomainNative';
import { useWizardWordpressThemes } from '@/hooks/sites/wizard/useWizardWordpressThemes';
import { useWizardWordpressSetupForm } from '@/hooks/sites/wizard/useWizardWordpressSetupForm';
import { useWizardWordpressPlugins } from '@/hooks/sites/wizard/useWizardWordpressPlugins';
import { useWizardTools } from '@/hooks/sites/wizard/useWizardTools';
import { sortAlphabetic } from '@/utils/Application/sort';
import { OverwriteSiteModal } from '../components/OverwriteSiteModal';
import { WizardHandlerModals } from '../components/WizardHandlerModals';
import { useAsaasRegisterForm } from '@/hooks/asaas/useAsaasRegisterForm';
import useLocale from '@/hooks/useLocale';
import { isBRServer } from '@/utils/Validators/validation';
import {
  ASAAS_LOGIN_LINK,
} from '@/config/urls/supportUrls';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  BRAVO_ASAAS_INSIDE_WIZARD, BRAVO_REMOVE_CONFIGURE_ATTEMPT_WORDPRESS, BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN, CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS, CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS_PLANS, CHARLIE_SITES_WIZARD_AUTO_SSL, FOXTROT_FASTCHECKOUT_ADDON, FOXTROT_WIZARD_SITE_EXISTS_CHECK, FOXTROT_WIZARD_WONDERSUITE, FOXTROT_WOOPREMIUM_OFFER, FOXTROT_WORDPRESS_IN_WIZARD, EXPERIENCE_SITES_ADITION_DOMAIN_V2,
} from '@/config/GrowthBook/constants';
import { WizardWondersuite } from '../components/WizardWondersuite';
import { VerifiedOverwriteSiteModal } from '../VerifiedOverwriteSiteModal';
import { IconLoading } from 'gatorcomponents';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import { getProductAddons } from '@/redux/actions/productDetail';

const WizardHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const theme = useContext(ThemeContext);
  const { color } = theme;
  const isSitesBuilderEnabled = true;
  const isHostgatorPluginEnabled = true;
  const displayOverwriteModal = COUNTRY === 'br';
  const bringDomainsWithBuilderTools = true;
  const isSiteBuilderWindowOpenEnabled = useFeatureIsOn(BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN);
  const isBravoInsideWizardEnabled = useFeatureIsOn(BRAVO_ASAAS_INSIDE_WIZARD);
  const isWondersuiteEnabled = useFeatureIsOn(FOXTROT_WIZARD_WONDERSUITE);
  const isWoopremiumOfferEnabled = useFeatureIsOn(FOXTROT_WOOPREMIUM_OFFER);
  const isFastCheckoutAddonEnabled = useFeatureIsOn(FOXTROT_FASTCHECKOUT_ADDON);
  const isSiteExistsCheckEnabled = useFeatureIsOn(FOXTROT_WIZARD_SITE_EXISTS_CHECK);
  const isWordpressToolsEnabled = useFeatureIsOn(FOXTROT_WORDPRESS_IN_WIZARD);
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);
  const withGatorSolutionsPlans = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS_PLANS);
  const withAditionDomainV2 = useFeatureIsOn(EXPERIENCE_SITES_ADITION_DOMAIN_V2);
  const sitesState = useSelector(state => state.sites);
  const summary = useSelector(state => state.summary);
  const { loading: displayCreateAccountModal, accountCreated } = useSelector(state => state.asaas);
  const {
    wordpressLoaded, wordpressLoading, plans, wordPressInstalled, hasSiteBuilderActive, loading: sitesLoading, domainDnsStatus, loadingVerifyIfSiteExist, siteExistVerifyFinished, currentlyInstallInfo, uninstallCompleted, loadingUninstall, uninstallError, needToExcludeDefaultFiles, loadingDefaultFilesExclude,
  } = useSelector(state => state.sites);
  const screenSize = useResize();
  const isMobile = screenSize.width < 700;
  const { id: userId } = useSelector(state => state.summary);
  const containerRef = useRef();
  const previousActiveSetupStepRef = useRef(null);
  const faqQuestions = useSelector(state => state.newFaq);
  const { email: userEmail } = useSelector(state => state.summary);
  const { product } = useSelector(state => state.productDetail);
  const { asaas: asaasLocale } = useLocale();
  const initialPluginsSelected = isWondersuiteEnabled ? [] : ['Yoast_admin'];
  const dispatchAutoSSl = useFeatureIsOn(CHARLIE_SITES_WIZARD_AUTO_SSL);
  const removeAttemptConfigureWordpress = useFeatureIsOn(BRAVO_REMOVE_CONFIGURE_ATTEMPT_WORDPRESS);

  const {
    stepsStatus,
    setupSteps,
    setupStepsInWondersuite,
    toolsNames,
    modals,
    siteBuilderPlansIds,
    singleDomainsPlansIds,
    multiDomainsPlansIds,
    wordPressPlansIds,
  } = wizardEnum;

  const verifyIfIsWordpress = label => label === toolsNames.WORDPRESS || label === toolsNames.WOOCOMMERCE || label === toolsNames.ECOMMERCEPLUS_TRIAL || label === toolsNames.ECOMMERCEPLUS;
  const verifyIfIsWoocommerce = label => label === toolsNames.WOOCOMMERCE || label === toolsNames.ECOMMERCEPLUS_TRIAL || label === toolsNames.ECOMMERCEPLUS;

  const steps = [
    {
      id: stepsStatus.CONFIGURATION,
      label: wizardLocale.wizardStepConfigurations,
    },
    {
      id: stepsStatus.ABOUT,
      label: wizardLocale.wizardStepAbout,
    },
    {
      id: stepsStatus.TOOLS,
      label: wizardLocale.wizardStepTools,
    },
    {
      id: stepsStatus.TIPS,
      label: wizardLocale.wizardStepTips,
    },
  ];

  const {
    sitesBuilderConfigurations: wizardConfigurationsAnalytics,
    sitesBuilderAbout: wizardAboutAnalytics,
    sitesBuilderTools: wizardToolsAnalytics,
    sitesBuilderTips: wizardTipsAnalytics,
  } = sitesBuilderAnalytics.newGa;

  const addDomainToPlan = useCallback((subdomain, planExternalId) => {
    dispatch(sitesActions.aditionalDomain.request(subdomain, planExternalId, withAditionDomainV2));
  }, [dispatch, withAditionDomainV2]);

  const resetTldAndSld = useCallback(() => dispatch(sitesActions.domainAvailability.set({
    domainAvailability: false,
    domainTldAvailability: false,
    sld: undefined,
    tld: undefined,
  })), [dispatch]);
  const changeDomainDns = useCallback((domainId, planId, selectedDomain) => dispatch(sitesActions.changeDomainDns.request(domainId, planId, selectedDomain)), [dispatch]);
  const getDomainAndPlanDns = useCallback((domainLabel, planId) => {
    dispatch(sitesActions.domainDns.request(domainLabel));
    dispatch(sitesActions.domainDnsStatusCheck.request({ domain: domainLabel }));
    dispatch(sitesActions.planDns.request(planId));
  }, [dispatch]);

  const shuffledObjectivesOptions = useMemo(() => [
    {
      id: 'option-0', label: wizardLocale.about.defaultObjectives.goodSite, value: wizardLocale.about.defaultObjectives.goodSite, gaValue: 'Ter um bom site',
    },
    {
      id: 'option-1', label: wizardLocale.about.defaultObjectives.visibility, value: wizardLocale.about.defaultObjectives.visibility, gaValue: 'Visibilidade e divulgação',
    },
    {
      id: 'option-2', label: wizardLocale.about.defaultObjectives.access, value: wizardLocale.about.defaultObjectives.access, gaValue: 'Alto número de acessos',
    },
    {
      id: 'option-3', label: wizardLocale.about.defaultObjectives.prospection, value: wizardLocale.about.defaultObjectives.prospection, gaValue: 'Captação de clientes',
    },
    {
      id: 'option-4', label: wizardLocale.about.defaultObjectives.results, value: wizardLocale.about.defaultObjectives.results, gaValue: 'Resultados financeiros',
    },
    {
      id: 'option-5', label: wizardLocale.about.defaultObjectives.satisfaction, value: wizardLocale.about.defaultObjectives.satisfaction, gaValue: 'Satisfação dos meus clientes',
    },
  ].sort(() => Math.random() - 0.5), []);

  const [objectiveOptions, setObjectiveOptions] = useState([
    ...shuffledObjectivesOptions,
    {
      id: 'option-6', label: wizardLocale.about.defaultObjectives.other, value: wizardLocale.about.defaultObjectives.other, gaValue: 'Outro objetivo',
    },
  ]);

  const ecommerceSiteTypeValue = 'ecommerce';
  const defaultSiteTypeValue = '';
  const defaultObjectiveIndexValue = null;

  const [displayOthersSitesModal, setDisplayOthersSitesModal] = useState(false);
  const [displayOthersObjectivesModal, setDisplayOthersObjectivesModal] = useState(false);
  const [othersSitesEditFieldValue, setOthersSitesEditFieldValue] = useState('');
  const [othersObjectivesEditFieldValue, setOthersObjectivesEditFieldValue] = useState('');
  const [othersSitesFieldValue, setOthersSitesFieldValue] = useState(wizardLocale.about.defaultOptions.otherTitle);
  const [othersObjectivesFieldValue, setOthersObjectivesFieldValue] = useState(wizardLocale.about.defaultObjectives.other);
  const [othersSitesFieldEditMode, setOthersSitesFieldEditMode] = useState(false);
  const [siteType, setSiteType] = useState(defaultSiteTypeValue);
  const [recommendedTool, setRecommendedTool] = useState('');
  const [selectedObjectiveIndex, setSelectedObjectiveIndex] = useState(defaultObjectiveIndexValue);
  const [overwriteSite, setOverwriteSite] = useState(false);
  const [continueToOverwrite, toggleContinueToOverwrite] = useState(false);
  const [dnsAnalyzeStarted, setDnsAnalyzeStarted] = useState(false);
  const [skipSiteExistCheck, setSkipSiteExistCheck] = useState(false);
  const [userAgreeOverwriteSite, setUserAgreeOverwriteSite] = useState(false);

  const [tools, setTools] = useState([]);
  const [toolSelected, setToolSelected] = useState('');
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [asaasReachedThridStep, setAsaasReachedThridStep] = useState(false);

  const tips = useWizardTips(toolSelected);
  const wordpressThemes = useWizardWordpressThemes(toolSelected);
  const wordpressSetupFunctions = useWizardWordpressSetupForm(userEmail, isWondersuiteEnabled);
  const wordpressPluginsFunctions = useWizardWordpressPlugins();
  const [wordPressSetupReadyToContinue, toggleWordPressSetupReadyToContinue] = useState(false);
  const [wodpressSetupValues, setWordpressSetupValues] = useState(wordpressSetupFunctions.defaultFormState);
  const [themeSelected, setThemeSelected] = useState(null);
  const [displayThemeInfoModal, setDisplayThemeInfoModal] = useState(false);
  const [modalTheme, setModalTheme] = useState('');
  const [activeSetupStep, setActiveSetupStep] = useState(1);
  const [userSelectedWondersuiteTrial, setUserSelectedWondersuiteTrial] = useState(false);
  const [pluginsSelected, setPluginsSelected] = useState(initialPluginsSelected);
  const [userSelectedUseEcommercePlus, setUserSelectedUseEcommercePlus] = useState(false);

  const [selectedDomain, setSelectedDomain] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const { compareDns } = useCompareDns();
  const { domainNative } = useVerifyDomainNative();
  const [userDomainList, setUserDomainList] = useState([]);
  const [chosenPlan, setChosenPlan] = useState({});
  const [multiDomainsPlans, setMultiDomainsPlans] = useState([]);
  const [singleDomainsPlans, setSingleDomainsPlans] = useState([]);
  const [domainsFilteredPerType, setDomainsFilteredPerType] = useState(false);
  const [selectedDomainPlan, setSelectedDomainPlan] = useState(null);
  const [costumerNeedToUpgradePlan, setCostumerNeedToUpgradePlan] = useState(false);
  const [customDomain, setCustomDomain] = useState(null);
  const [comparingDns, setComparingDns] = useState(false);
  const [isPlanSiteBuilder, setIsPlanSiteBuilder] = useState(false);
  const [questionsFaq, setQuestionsFaq] = useState('');
  const [onUpgradePlanPage, setOnUpgradePlanPage] = useState(false);
  const [hgPluginIsInstall, setHgPluginIsInstall] = useState(false);

  const isWordpressInstall = verifyIfIsWordpress(toolSelected);
  const needToShowDnsWarningModal = domainDnsStatus === wizardEnum.dnsStatusInProgress && isWordpressInstall;
  const siteIsVirtualStore = siteType === ecommerceSiteTypeValue;
  const shouldRenderWooopremiumOffer = isWoopremiumOfferEnabled && siteIsVirtualStore && isWordpressToolsEnabled;
  const shouldRenderEcommercePlusCard = sitesState.hostingAlreadyHasWooPremium && isWordpressToolsEnabled;
  const userSawEcommercePlusOffer = shouldRenderWooopremiumOffer && !shouldRenderEcommercePlusCard;

  const asaasInsideWizardShouldAppear = isBravoInsideWizardEnabled && isBRServer;

  const wordPressPlugins = wordpressPluginsFunctions.defaultPluginsList;

  const requestAutoSsl = useCallback(() => {
    if (dispatchAutoSSl) {
      dispatch(sitesActions.ssl.start.request({ domain: selectedSite.label, hostingId: selectedPlan.external_id }));
    }
  }, [dispatch, dispatchAutoSSl, selectedSite, selectedPlan]);

  const handleShowModal = (modal) => {
    setShowModal(modal);
  };

  const submitToSurvey = useCallback((needUpgradePlan) => {
    let plugins = '';
    if (wordPressPlugins.length && isWordpressInstall) {
      wordPressPlugins.forEach((plugin) => {
        if (pluginsSelected.includes(plugin.value)) {
          plugins += `${plugin.title},`;
        }
      });
    }
    const answers = {
      'Fluxo de criação de sites': surveyCountry,
      'Etapa 1': needUpgradePlan ? 'Cliente direcionado para upgrade/compra de plano' : {
        Dominio: selectedSite.label,
        Plano: selectedPlan.name,
      },
      'Etapa 2': {
        'Tipo de Site': siteType,
        'Finalidade do Site': selectedObjectiveIndex !== null && objectiveOptions[selectedObjectiveIndex].label,
      },
      'Etapa 3': {
        'Ferramenta escolhida': toolSelected,
        'Tema escolhido': isWordpressInstall ? themeSelected : '',
        'Plugins escolhidos': plugins,
      },
    };

    if (hgPluginIsInstall) {
      answers['Etapa 3'] = {
        ...answers['Etapa 3'],
        'Plugins nativos': 'HostGator_admin',
      };
    }

    if (asaasReachedThridStep) {
      answers['Etapa 3'] = {
        ...answers['Etapa 3'],
        'Conta Asaas': 'Criada',
      };
    }

    if (activeStep < stepsStatus.ABOUT) {
      delete answers['Etapa 2'];
      delete answers['Etapa 3'];
    }

    if (activeStep < stepsStatus.TOOLS) {
      delete answers['Etapa 3'];
    }

    const payload = {
      answers,
      country: COUNTRY,
      user_id: userId.toString(),
      hosting_id: needUpgradePlan ? `need-upgrade-plan-${userId.toString()}` : `${selectedPlan.external_id}-${selectedSite.label}`,
      domain: needUpgradePlan ? `need-upgrade-plan-${userId.toString()}` : selectedSite.label,
    };

    dispatch(registerCustomerProfileSurvey({ data: payload, type: 'wizard' }));
  }, [
    activeStep,
    dispatch,
    objectiveOptions,
    pluginsSelected,
    selectedObjectiveIndex,
    selectedPlan,
    selectedSite,
    siteType,
    themeSelected,
    toolSelected,
    userId,
    wordPressPlugins,
    stepsStatus,
    hgPluginIsInstall,
    asaasReachedThridStep,
    isWordpressInstall,
  ]);

  const resetFirstStepStates = useCallback(() => {
    dispatch(sitesActions.addonCheck.reset());
    dispatch(sitesActions.verifyIfSiteExist.reset());
    setSkipSiteExistCheck(false);
    setDnsAnalyzeStarted(false);
  }, [dispatch, setSkipSiteExistCheck, setDnsAnalyzeStarted]);

  const handleContinueToNextStep = useCallback((specificStep, submit = true) => {
    const nextStep = specificStep || (activeStep + 1);
    setActiveStep(nextStep);
    if (submit) {
      submitToSurvey();
    }
    if (activeStep === stepsStatus.CONFIGURATION) {
      resetFirstStepStates();
    }

    if (specificStep === stepsStatus.TOOLS) {
      wizardAboutAnalytics.continueToThirdStep();
      if (siteIsVirtualStore && IS_PROD && isBRServer && wizardEnum.plansToCallRdWhenVirtualStore.includes(selectedPlan.whmcs_product_id)) {
        dispatch(sitesActions.rdStation.request({
          domain: selectedDomain.label,
          plan: selectedPlan.name,
        }));
      }
    }
  }, [wizardAboutAnalytics, setActiveStep, activeStep, submitToSurvey, stepsStatus, resetFirstStepStates, siteIsVirtualStore, dispatch, selectedDomain, selectedPlan]);

  const handleToggleUserAgreeOverwriteSite = () => {
    setUserAgreeOverwriteSite(!userAgreeOverwriteSite);
  };

  useEffect(() => {
    if (activeStep === stepsStatus.TIPS && toolSelected) {
      submitToSurvey();
    }
  }, [toolSelected, activeStep, stepsStatus.TIPS, submitToSurvey]);

  const wizardTools = useWizardTools({
    activeStep,
    selectedSite,
    setToolSelected,
    handleContinueToNextStep,
    setUserSelectedWondersuiteTrial,
    setUserSelectedUseEcommercePlus,
    selectedPlan,
  });
  const { mountTools } = wizardTools;

  const shuffledSitesTypesOptions = [
    {
      title: wizardLocale.about.defaultOptions.institucionalTitle,
      subtitle: wizardLocale.about.defaultOptions.institucionalSubtitle,
      iconLeft: <IconDetailedInstitutional />,
      id: 'institucional_button',
      value: 'institucional',
      checked: false,
      gaValue: 'Site institucional',
    },
    {
      title: wizardLocale.about.defaultOptions.personalTitle,
      subtitle: null,
      iconLeft: <IconDetailedPersonalPortfoil />,
      id: 'personal_button',
      value: 'personal',
      checked: false,
      gaValue: 'Site pessoal/portifólio',
    },
    {
      title: wizardLocale.about.defaultOptions.blogTitle,
      subtitle: null,
      iconLeft: <IconDetailedBlog />,
      id: 'blog_button',
      value: 'blog',
      checked: false,
      gaValue: 'Blog',
    },
    {
      title: wizardLocale.about.defaultOptions.ecommerceTitle,
      subtitle: wizardLocale.about.defaultOptions.ecommerceSubtitle,
      iconLeft: <IconDetailedStore />,
      id: 'ecommerce_button',
      value: ecommerceSiteTypeValue,
      checked: false,
      gaValue: 'Loja',
    },
    {
      title: wizardLocale.about.defaultOptions.landingTitle,
      subtitle: wizardLocale.about.defaultOptions.landingSubtitle,
      iconLeft: <IconDetailedLandingPage />,
      id: 'landing_button',
      value: 'landing',
      checked: false,
      gaValue: 'Captação de clientes',
    },
  ].sort(() => Math.random() - 0.5);

  const [siteTypes, setSiteTypes] = useState(
    [
      ...shuffledSitesTypesOptions,
      {
        title: wizardLocale.about.defaultOptions.otherTitle,
        subtitle: null,
        iconLeft: <IconDetailedOther />,
        id: 'other_button',
        value: 'other',
        checked: false,
        gaValue: 'Outro tipo de site',
      },
    ],
  );

  useEffect(() => {
    if (wordpressLoaded === true && activeStep === stepsStatus.TOOLS && wordPressInstalled) {
      handleContinueToNextStep(stepsStatus.TIPS, false);
      dispatch(sitesActions.wordpressInstallation.reset());
      dispatch(sitesActions.addonCheck.reset());
    }
  }, [wordpressLoaded, activeStep, handleContinueToNextStep, stepsStatus, wordPressInstalled, dispatch]);

  const mountCards = useCallback(({ option, type }) => {
    const tools = [];
    const defaultTools = mountTools;

    if (option !== null && type !== null) {
      const featureWordpress = (type === 'blog') || (type === 'other') || ((type !== ecommerceSiteTypeValue) && [
        wizardLocale.about.defaultObjectives.prospection,
        wizardLocale.about.defaultObjectives.results,
        wizardLocale.about.defaultObjectives.satisfaction,
      ].includes(objectiveOptions[option].value));

      if (isPlanSiteBuilder) {
        defaultTools.sitebuilder.featured = true;
        tools.push(defaultTools.sitebuilder);
      } else if (type === ecommerceSiteTypeValue) {
        if (isWoopremiumOfferEnabled) {
          defaultTools.ecommercePlus.featured = true;
          shouldRenderEcommercePlusCard ? tools.push(defaultTools.ecommercePlus) : tools.push(defaultTools.wondersuiteWoocommerce);
          tools.push(defaultTools.wondersuiteOtherSite);
        } else {
          if (defaultTools.sitebuilder) {
            defaultTools.sitebuilder.featured = false;
          }
          defaultTools.woocommerce.featured = true;
          tools.push(defaultTools.woocommerce);
          tools.push(defaultTools.otherCommerce);
        }
      } else if (featureWordpress) {
        if (defaultTools.sitebuilder) {
          defaultTools.sitebuilder.featured = false;
          tools.push(defaultTools.sitebuilder);
        }
        defaultTools.wordpress.featured = true;
        tools.push(defaultTools.wordpress);
        tools.push(defaultTools.otherSite);
      } else {
        if (defaultTools.sitebuilder) {
          defaultTools.sitebuilder.featured = true;
          defaultTools.wordpress.featured = false;
          tools.push(defaultTools.sitebuilder);
        } else {
          defaultTools.wordpress.featured = true;
        }
        tools.push(defaultTools.wordpress);
        tools.push(defaultTools.otherSite);
      }

      const filteredTools = isWordpressToolsEnabled ? tools : tools.filter(tool => !tool.isWordPress);
      if (!isWordpressToolsEnabled) {
        filteredTools[0].featured = true;
      }

      const featuredTool = tools.find(tool => tool.featured);

      if (featuredTool) {
        setRecommendedTool(featuredTool.titleText);
      }

      setTools(filteredTools);
    }
  }, [selectedPlan, objectiveOptions, mountTools, isPlanSiteBuilder, isWoopremiumOfferEnabled, shouldRenderEcommercePlusCard, selectedPlan, isWordpressToolsEnabled]);

  const callMountCards = useCallback(() => {
    if (activeStep === stepsStatus.TOOLS) {
      mountCards({ option: selectedObjectiveIndex, type: siteType });
    }
  }, [activeStep, mountCards, selectedObjectiveIndex, siteType, stepsStatus.TOOLS]);

  useEffect(() => {
    callMountCards();
  }, [callMountCards]);

  const validateFields = useCallback(() => {
    const hasInvalidName = wodpressSetupValues.name.empty || wodpressSetupValues.name.error;
    const hasInvalidEmail = wodpressSetupValues.email.empty || wodpressSetupValues.email.error;
    const hasInvalidUser = wodpressSetupValues.user.empty || wodpressSetupValues.user.error;
    const hasInvalidPassword = wodpressSetupValues.password.empty || wodpressSetupValues.password.error;
    const hasInvalidConfirmPassword = wodpressSetupValues.confirmPassword.empty || wodpressSetupValues.confirmPassword.error;

    toggleWordPressSetupReadyToContinue(
      hasInvalidName
      || hasInvalidEmail
      || hasInvalidUser
      || hasInvalidPassword
      || hasInvalidConfirmPassword,
    );
  }, [
    wodpressSetupValues.name.empty,
    wodpressSetupValues.name.error,
    wodpressSetupValues.email.empty,
    wodpressSetupValues.email.error,
    wodpressSetupValues.user.empty,
    wodpressSetupValues.user.error,
    wodpressSetupValues.password.empty,
    wodpressSetupValues.password.error,
    wodpressSetupValues.confirmPassword.empty,
    wodpressSetupValues.confirmPassword.error,
  ]);

  useEffect(() => {
    validateFields();
  }, [validateFields]);

  const dispathRequests = useCallback(() => {
    dispatch(sitesActions.plans.request({ withGatorSolutions: withGatorSolutions && withGatorSolutionsPlans }));
    dispatch(sitesActions.tlds.request());
    dispatch(faqActions.faq.request.siteBuilder());
    dispatch(faqActions.faq.request.wordpress());
    dispatch(faqActions.faq.request.softaculous());
  }, [dispatch, withGatorSolutions, withGatorSolutionsPlans]);

  const dispatchDomains = useCallback(() => {
    dispatch(sitesActions.domains.request(bringDomainsWithBuilderTools));
  }, [dispatch, bringDomainsWithBuilderTools]);

  useEffect(() => {
    dispathRequests();
  }, [dispathRequests]);

  useEffect(() => {
    dispatchDomains();
  }, [dispatchDomains]);

  const getSelectedSiteTypeGaValue = useCallback(() => siteTypes.find(type => type.value === siteType).gaValue, [siteTypes, siteType]);

  const handleOnChangeHome = () => {
    history.push(wizardLocale.tips.pageWordPress.routeSitesPages);
    wizardTipsAnalytics.goToSites(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
  };

  const {
    formData: asaasFormData,
    loadingCep,
    resetForm: asaasResetForm,
    getCep,
    onChange: asaasOnChange,
    onContinue: asaasOnContinue,
    enabledFormOpenAccountContinue: asaasEnabledFormOpenAccountContinue,
    enabledMainDataContinue: asaasEnabledMainDataContinue,
    onGoback: asaasOnGoback,
    onSubmit: asaasOnSubmit,
    cnpjTypes: asaasCnpjTypes,
    callformStepTwoMyCEP: asaasCallformStepTwoMyCEP,
  } = useAsaasRegisterForm({
    summary, location: 'wizard', setAsaasReachedThridStep, accountCreated, getSelectedSiteTypeGaValue, toolSelected, userSawEcommercePlusOffer,
  });

  const requestFaqs = useCallback(() => {
    if (
      faqQuestions
      && faqQuestions.softaculous.loaded
      && faqQuestions.sitebuilder.loaded
      && faqQuestions.wordpress.loaded
      && activeStep === stepsStatus.TIPS) {
      if (toolSelected === 'softaculous') {
        setQuestionsFaq(faqQuestions.softaculous.questions);
      }
      if (toolSelected === 'sitebuilder') {
        setQuestionsFaq(faqQuestions.sitebuilder.questions);
      }
      if (isWordpressInstall) {
        setQuestionsFaq(faqQuestions.wordpress.questions);
      }
    }
  }, [activeStep, faqQuestions, setQuestionsFaq, toolSelected, stepsStatus, isWordpressInstall]);

  useEffect(() => {
    requestFaqs();
  }, [requestFaqs]);

  const scrollTop = useCallback(() => {
    if (containerRef && containerRef.current && containerRef.current.offsetTop) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 300);
    }
  }, []);

  useEffect(() => {
    scrollTop();
    setShowModal(null);
  }, [activeStep, scrollTop]);

  const [previousActiveSetupStep, setPreviousActiveSetupStep] = useState(previousActiveSetupStepRef.current);

  useEffect(() => {
    previousActiveSetupStepRef.current = activeSetupStep;
    setPreviousActiveSetupStep(previousActiveSetupStepRef.current);
  }, [activeSetupStep]);

  useEffect(() => {
    if (!currentlyInstallInfo.showOverwriteModal) {
      toggleContinueToOverwrite(false);
    }
  }, [currentlyInstallInfo, toggleContinueToOverwrite]);


  const getWordPressStepMoveGaEvent = useCallback(() => {
    if (isWondersuiteEnabled) {
      switch (activeSetupStep) {
        case setupStepsInWondersuite.UTILS:
          if (previousActiveSetupStep > setupStepsInWondersuite.UTILS) {
            return wizardToolsAnalytics.backToWordPressConfig(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
          }
          break;
        case setupStepsInWondersuite.ASAAS:
          return wizardToolsAnalytics.nextToAsaasStepWithWondersuite(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        default: break;
      }
    }

    switch (activeSetupStep) {
      case setupSteps.UTILS:
        if (previousActiveSetupStep > setupSteps.UTILS) {
          return wizardToolsAnalytics.backToWordPressConfig(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        }
        break;
      case setupSteps.THEME:
        if (previousActiveSetupStep === setupSteps.PLUGIN) {
          return wizardToolsAnalytics.backToWordPressTheme(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        }
        return wizardToolsAnalytics.continueToChooseTheme(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      case setupSteps.PLUGIN:
        if (previousActiveSetupStep > setupSteps.PLUGIN) {
          return wizardToolsAnalytics.backFromAsaasToPluginStep(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        }
        return wizardToolsAnalytics.continueToChoosePlugin(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      case setupSteps.ASAAS:
        return wizardToolsAnalytics.nextToAsaasStep(getSelectedSiteTypeGaValue());
      default: break;
    }
  }, [activeSetupStep, previousActiveSetupStep, setupSteps, wizardToolsAnalytics, toolSelected, isWondersuiteEnabled, setupStepsInWondersuite, getSelectedSiteTypeGaValue, userSawEcommercePlusOffer]);

  useEffect(() => {
    if (isWordpressInstall && (previousActiveSetupStep !== activeSetupStep)) {
      getWordPressStepMoveGaEvent();
    }
  }, [isWordpressInstall, activeSetupStep, setupSteps, wizardToolsAnalytics, toolSelected, previousActiveSetupStep, getWordPressStepMoveGaEvent]);

  const setWordpressSetupValuesToWondersuite = useCallback(() => {
    const defaultFieldsValueInWondersuite = {
      empty: false,
      error: false,
      value: wizardLocale.tools.wordPressInstall.defaultWordpressValue,
    };

    const defaultFieldsInWondersuite = {
      name: defaultFieldsValueInWondersuite,
      description: defaultFieldsValueInWondersuite,
    };
    setWordpressSetupValues((prevState => ({ ...prevState, ...defaultFieldsInWondersuite })));
  }, []);

  useEffect(() => {
    if (isWondersuiteEnabled && isWordpressInstall) {
      setWordpressSetupValuesToWondersuite();
    }
  }, [isWondersuiteEnabled, isWordpressInstall, setWordpressSetupValuesToWondersuite]);


  const resetFirstStepFields = () => {
    dispatch(sitesActions.verifyIfSiteExist.reset());
    setSelectedDomainPlan(null);
    setSelectedSite(null);
    setCustomDomain(null);
    setChosenPlan({});
    setOverwriteSite(false);
    resetFirstStepStates();
    setSelectedDomain(null);
    setUserAgreeOverwriteSite(false);
  };

  const handleBack = () => {
    if (sitesState.anotherDomain && sitesState.domainAvailability) {
      wizardConfigurationsAnalytics.upgradePlanStepBack();
      resetFirstStepFields();
      setCostumerNeedToUpgradePlan(false);
      dispatch(sitesActions.domainAvailability.set({ domainAvailability: false, domainTldAvailability: false, domain: null }));
    } else if (costumerNeedToUpgradePlan) {
      wizardConfigurationsAnalytics.upgradePlanStepBack();
      setOnUpgradePlanPage(false);
      resetFirstStepFields();
      setCostumerNeedToUpgradePlan(false);
    } else if (toolSelected && activeStep === stepsStatus.TOOLS) {
      setToolSelected('');
      scrollTop();
      wizardToolsAnalytics.backWordPressInstall(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    } else {
      switch (activeStep) {
        case stepsStatus.ABOUT:
          wizardAboutAnalytics.moveBackFirstStepSiteBuilder();
          break;
        case stepsStatus.TOOLS:
          dispatch(sitesActions.addonCheck.reset());
          setUserSelectedUseEcommercePlus(false);
          wizardToolsAnalytics.moveBackSecondStepSiteBuilder(getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
          wizardAboutAnalytics.accessSecondStepSiteBuilder();
          break;
        default: break;
      }
      setActiveStep(activeStep - 1);
    }
  };

  const getSingleDomainsPlans = useCallback((plan) => {
    const { whmcs_product_id: productId } = plan;
    return singleDomainsPlansIds.includes(productId);
  }, [singleDomainsPlansIds]);

  const getMultiDomainsPlans = useCallback((plan) => {
    const { whmcs_product_id: productId } = plan;
    return multiDomainsPlansIds.includes(productId);
  }, [multiDomainsPlansIds]);

  const validateOtherDomain = useCallback(({
    anotherDomain,
    domainAndSubdomain,
    domainAvailability,
  }) => {
    setSelectedDomain({
      ...selectedDomain,
      label: anotherDomain,
      domainAndSubdomain,
    });

    if (domainAvailability) {
      setShowModal(wizardEnum.modals.DOMAIN_NOT_REGISTERED);
    } else {
      setShowModal(wizardEnum.modals.DOMAIN_REGISTERED);
    }
  }, [selectedDomain]);

  const renderOtherDomainModal = useCallback(() => {
    const isInAnotherDomainFlow = sitesState.anotherDomain && showModal === wizardEnum.modals.ANOTHER_DOMAIN;

    if (isInAnotherDomainFlow && sitesState.domainTldAvailability) {
      validateOtherDomain({
        anotherDomain: sitesState.anotherDomain,
        domainAndSubdomain: sitesState.domainAndSubdomain,
        domainAvailability: sitesState.domainAvailability,
      });
    }
  }, [
    sitesState.domainAvailability,
    sitesState.domainTldAvailability,
    validateOtherDomain,
    sitesState.anotherDomain,
    showModal,
  ]);

  useEffect(() => {
    renderOtherDomainModal();
  }, [renderOtherDomainModal]);

  const getAssembledDomainList = useCallback(() => {
    const userDomainListSortedAlphabetically = sortAlphabetic(sitesState.domainList, 'domain');
    return (
      userDomainListSortedAlphabetically.map((item, index) => ({
        id: index,
        externalId: item.external_id,
        domain_id: item.domain_id,
        label: item.domain,
        value: index,
      }))
    );
  }, [sitesState.domainList]);

  const setOnlyDomainSelected = useCallback(() => {
    setSelectedDomain(userDomainList[0]);
    setSelectedSite(userDomainList[0]);
  }, [userDomainList]);

  useEffect(() => {
    if (typeof userDomainList === 'object' && userDomainList.length === 1) {
      setOnlyDomainSelected();
    }
  }, [userDomainList, setOnlyDomainSelected]);

  const handleChangeObjective = useCallback((option) => {
    const optionIndex = objectiveOptions.findIndex(item => item.id === option.id);
    mountCards({ option: optionIndex, type: siteType });
    wizardAboutAnalytics.chooseSiteObjective(option.gaValue);

    setSelectedObjectiveIndex(optionIndex);
  }, [mountCards, objectiveOptions, siteType, wizardAboutAnalytics]);

  const handleChangeSiteType = useCallback((event, siteTypeValue) => {
    const value = event ? event.target.value : siteTypeValue;
    if (!othersSitesFieldEditMode && value === 'other') {
      setOthersSitesFieldEditMode(true);
      setDisplayOthersSitesModal(true);
    }
    setSiteType(value);
    const siteType = siteTypes.find(siteType => siteType.value === value);
    wizardAboutAnalytics.chooseSiteType(siteType.gaValue);

    mountCards({ option: selectedObjectiveIndex, type: value });
  }, [mountCards, othersSitesFieldEditMode, selectedObjectiveIndex, setSiteType, siteTypes, wizardAboutAnalytics]);

  const setConfigurationsDefaultState = useCallback(() => {
    if (sitesState.domainList.length) {
      const assembledDomainList = getAssembledDomainList();
      const paramsDomain = params.domain;
      const userDomainOfParams = assembledDomainList.find(site => site.label === paramsDomain);
      setUserDomainList(assembledDomainList);

      if (paramsDomain && userDomainOfParams) {
        setSelectedDomain(userDomainOfParams);
        setSelectedSite(userDomainOfParams);
      }
    }
    if (sitesState.plans.length && sitesState.plans.length === 1) {
      setChosenPlan(sitesState.plans[0]);
      setSelectedPlan(sitesState.plans[0]);
    }
  }, [sitesState.plans, sitesState.domainList, getAssembledDomainList, params.domain]);

  const setDefaultStateWhenEcommercePlus = useCallback(() => {
    if (params.ecommerceplus === wizardEnum.ecommercePlusParamValue) {
      const financialObjective = shuffledObjectivesOptions.find(item => item.value === wizardLocale.about.defaultObjectives.results);
      handleChangeObjective(financialObjective);
      handleChangeSiteType(null, ecommerceSiteTypeValue);
    }
  }, [handleChangeObjective, handleChangeSiteType, params.ecommerceplus, shuffledObjectivesOptions]);

  useEffect(() => {
    if (siteType === defaultSiteTypeValue && selectedObjectiveIndex === defaultObjectiveIndexValue) {
      setDefaultStateWhenEcommercePlus();
    }
  }, [setDefaultStateWhenEcommercePlus, selectedObjectiveIndex, siteType]);

  useEffect(() => {
    setConfigurationsDefaultState();
  }, [setConfigurationsDefaultState]);

  const validatePlanSiteBuilder = useCallback(() => {
    if (chosenPlan.name) {
      const { whmcs_product_id: productId } = chosenPlan;
      const isSiteBuilder = siteBuilderPlansIds.includes(productId);
      setIsPlanSiteBuilder(isSiteBuilder);
    }
  }, [chosenPlan, siteBuilderPlansIds]);

  useEffect(() => {
    validatePlanSiteBuilder();
  }, [validatePlanSiteBuilder]);

  const checkSelectedDomainHasPlan = useCallback((plan) => {
    if (plan.status !== 'active') return;

    if (plan.domain === selectedDomain.label) return plan;

    if (plan.sites && plan.sites.length) {
      if (plan.sites.some(site => site.site === selectedDomain.label)) return plan;
    }
  }, [selectedDomain]);


  const checkDomainAvailability = useCallback(({ domain }) => {
    wizardConfigurationsAnalytics.otherDomainModalValidateDomain();
    return dispatch(sitesActions.domainAvailability.request({ domain }));
  }, [dispatch, wizardConfigurationsAnalytics]);

  const findSelectedDomainPlan = useCallback(() => {
    if (selectedDomain && sitesState.loadedDomains && domainsFilteredPerType) {
      let domainPlan = null;


      if (!multiDomainsPlans.length) {
        domainPlan = singleDomainsPlans.find(checkSelectedDomainHasPlan);
        setSelectedDomainPlan(domainPlan);

        if (!domainPlan) {
          setCostumerNeedToUpgradePlan(true);
          if (!onUpgradePlanPage) {
            wizardConfigurationsAnalytics.accessUpgradePlanPage();
            submitToSurvey({ needUpgradePlan: true });
          }
          setOnUpgradePlanPage(true);
        }
      }

      if (multiDomainsPlans.length) {
        const domainPlanPreValidation = sitesState.plans.find(checkSelectedDomainHasPlan);

        if (domainPlanPreValidation && !wordPressPlansIds.includes(domainPlanPreValidation.whmcs_product_id)) {
          domainPlan = domainPlanPreValidation;
          setSelectedDomainPlan(domainPlan);
        }
      }

      if (domainPlan) {
        setChosenPlan(domainPlan);
        setSelectedPlan(domainPlan);
      }

      if (!domainPlan && multiDomainsPlans.length === 1) {
        setChosenPlan(multiDomainsPlans[0]);
        setSelectedPlan(multiDomainsPlans[0]);
      }
    }
  }, [
    checkSelectedDomainHasPlan,
    multiDomainsPlans,
    setSelectedPlan,
    sitesState.plans,
    singleDomainsPlans,
    selectedDomain,
    wordPressPlansIds,
    domainsFilteredPerType,
    sitesState.loadedDomains,
    onUpgradePlanPage,
    submitToSurvey,
    wizardConfigurationsAnalytics,
  ]);

  useEffect(() => {
    findSelectedDomainPlan();
  }, [findSelectedDomainPlan]);

  useEffect(() => {
    if (sitesState.loadedDomains && sitesState.plansLoaded) {
      const onlyMultiDomainsPlans = sitesState.plans.filter(getMultiDomainsPlans);
      setMultiDomainsPlans(onlyMultiDomainsPlans);

      const onylSingleDomainsPlans = sitesState.plans.filter(getSingleDomainsPlans);
      setSingleDomainsPlans(onylSingleDomainsPlans);

      setDomainsFilteredPerType(true);
    }
  }, [sitesState.plans, getMultiDomainsPlans, getSingleDomainsPlans, sitesState.loadedDomains, sitesState.plansLoaded]);

  const selectDomainOnContinueCompareDns = useCallback(async () => {
    setComparingDns(true);
    const domainHasPlan = !!checkSelectedDomainHasPlan(chosenPlan);

    if (!domainHasPlan) {
      const newSites = [...chosenPlan.sites, selectedDomain.label];

      setChosenPlan({ ...chosenPlan, sites: newSites });
      setSelectedPlan({ ...chosenPlan, sites: newSites });
      if (!uninstallCompleted) {
        addDomainToPlan(selectedDomain.label, parseInt(chosenPlan.external_id, 10));
      }
    }
    const dnsAreEquals = await compareDns(selectedDomain.label, chosenPlan.external_id);
    if (dnsAreEquals) {
      setComparingDns(false);
      handleContinueToNextStep();
      return;
    }
    getDomainAndPlanDns(selectedDomain.label, chosenPlan.external_id);


    const isDomainNative = await domainNative(selectedDomain.label);

    const hasSubdomain = !!selectedDomain.label.replace(sitesState.anotherDomain, '') && !!sitesState.anotherDomain;

    const shouldDisplayAutomaticDnsModal = isDomainNative && !dnsAreEquals;
    const shouldRenderSelectDns = !isDomainNative && !dnsAreEquals;

    if (shouldDisplayAutomaticDnsModal) {
      handleShowModal(wizardEnum.modals.DNS_AUTOMATIC);
      !hasSubdomain && changeDomainDns(selectedDomain.domain_id, chosenPlan.external_id, selectedDomain);
    }

    if (!shouldDisplayAutomaticDnsModal && shouldRenderSelectDns) {
      handleShowModal(wizardEnum.modals.SELECT_DNS);
    }
    setComparingDns(false);
  }, [addDomainToPlan, changeDomainDns, checkSelectedDomainHasPlan, chosenPlan, compareDns, domainNative, getDomainAndPlanDns, handleContinueToNextStep, selectedDomain, uninstallCompleted]);


  const uninstallSite = useCallback(() => {
    const domainHasPlan = !!checkSelectedDomainHasPlan(chosenPlan);
    dispatch(sitesActions.uninstallSite.request({
      domain: selectedDomain.label,
      hostingId: chosenPlan.external_id,
      domainId: selectedDomain.domain_id,
      domainHasPlan,
      needToExcludeDefaultFiles,
      currentlyInstallInfo,
    }));
  }, [checkSelectedDomainHasPlan, chosenPlan, dispatch, currentlyInstallInfo, selectedDomain, needToExcludeDefaultFiles]);

  useEffect(() => {
    if (needToExcludeDefaultFiles) {
      uninstallSite();
    }
  }, [needToExcludeDefaultFiles, uninstallSite]);

  const continueToDnsAnalyze = useCallback(() => {
    setDnsAnalyzeStarted(true);
    selectDomainOnContinueCompareDns();
    dispatch(sitesActions.verifyIfSiteExist.reset());
  }, [setDnsAnalyzeStarted, selectDomainOnContinueCompareDns, dispatch]);

  useEffect(() => {
    if (uninstallCompleted && !dnsAnalyzeStarted) {
      continueToDnsAnalyze();
    }
  }, [uninstallCompleted, continueToDnsAnalyze, dnsAnalyzeStarted]);

  useEffect(() => {
    if ((siteExistVerifyFinished || skipSiteExistCheck) && !dnsAnalyzeStarted && activeStep === stepsStatus.CONFIGURATION && !currentlyInstallInfo.showOverwriteModal) {
      continueToDnsAnalyze();
    }
  }, [siteExistVerifyFinished, selectDomainOnContinueCompareDns, dnsAnalyzeStarted, skipSiteExistCheck, activeStep, stepsStatus, currentlyInstallInfo, continueToDnsAnalyze]);

  const handleCloseModal = (pressedCloseButton = false, action) => {
    if (pressedCloseButton) {
      switch (showModal) {
        case wizardEnum.modals.ANOTHER_DOMAIN:
          wizardConfigurationsAnalytics.otherDomainModalCloseModal();
          break;
        case wizardEnum.modals.DOMAIN_NOT_REGISTERED:
          wizardConfigurationsAnalytics.domainNotRegisteredModalCloseModal();
          break;
        case wizardEnum.modals.DOMAIN_REGISTERED:
          wizardConfigurationsAnalytics.domainRegisteredModalCloseModal();
          break;
        case wizardEnum.modals.UPGRADE_PLAN:
          wizardConfigurationsAnalytics.upgradePlanModalCloseModal();
          break;
        case wizardEnum.modals.SELECT_DNS:
          wizardConfigurationsAnalytics.DNSManualModalCloseModal(action);
          break;
        case wizardEnum.modals.CHOOSE_PLAN:
          wizardConfigurationsAnalytics.selectOtherPlanModalCloseModal();
          break;
        default:
          setShowModal(null);
      }
    }
    setShowModal(null);
  };

  const requestProductAddons = useCallback(() => {
    selectedPlan && dispatch(getProductAddons(selectedPlan.external_id));
  }, [dispatch, selectedPlan]);

  useEffect(() => {
    requestProductAddons();
  }, [requestProductAddons]);

  const onClickTipsButton = () => {
    const wordPressClick = () => {
      if (needToShowDnsWarningModal && showModal === null) {
        setShowModal(wizardEnum.modals.DNS_IN_PROGRESS);
        return;
      }
      const wpBaseUrl = `https://${selectedSite.label}/wp-admin`;
      window.open(`${wpBaseUrl}${isWondersuiteEnabled ? '/index.php?page=nfd-onboarding' : ''}`, '_blank');
      wizardTipsAnalytics.accessToolWordPress(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      if (showModal === wizardEnum.modals.DNS_IN_PROGRESS) {
        handleCloseModal();
      }
    };

    const hasSiteBuilder = product.addons.some(addon => addon.domain === selectedSite.label);
    const hostingId = !hasSiteBuilder ? selectedPlan.external_id : '';

    const rules = {
      [toolsNames.SITEBUILDER]: () => {
        window.open(`${wizardLocale.tips.siteBuilder.baseUrl}/${selectedSite.label}/${hostingId}`);
        wizardTipsAnalytics.accessBuilderTool(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      },
      [toolsNames.WORDPRESS]: () => {
        wordPressClick();
      },
      [toolsNames.WOOCOMMERCE]: () => {
        wordPressClick();
      },
      [toolsNames.ECOMMERCEPLUS_TRIAL]: () => {
        wordPressClick();
      },
      [toolsNames.ECOMMERCEPLUS]: () => {
        wordPressClick();
      },
    };

    return rules[toolSelected]();
  };

  const handleChangeDomain = (selectedValue, modal) => {
    switch (modal) {
      case 'domain-registered':
        wizardConfigurationsAnalytics.domainRegisteredModalContinue();
        break;
      case 'domain-not-registered':
        wizardConfigurationsAnalytics.domainNotRegisteredModalContinue();
        break;
      default:
        wizardConfigurationsAnalytics.selectListedDomain();
    }

    const isDomainOnList = userDomainList.some(domain => domain.label === selectedValue);
    const domainSelectedOnList = selectedValue.label;

    if (domainSelectedOnList) {
      checkDomainAvailability({ domain: selectedValue.label });
      setSelectedDomain(selectedValue);
      setSelectedSite(selectedValue);
      setSelectedDomainPlan(null);
    }

    if (!domainSelectedOnList && !isDomainOnList) {
      const assembledDomainList = getAssembledDomainList();
      setUserDomainList([...assembledDomainList, { label: selectedValue }]);
      setSelectedDomain({ label: selectedValue });
      setSelectedSite({ label: selectedValue });
      setCustomDomain(selectedValue);
      setSelectedDomainPlan(null);
    }

    if (!domainSelectedOnList && isDomainOnList) {
      checkDomainAvailability({ domain: selectedValue });
      setSelectedDomain({ label: selectedValue });
      setSelectedSite({ label: selectedValue });
      setSelectedDomainPlan(null);
    }
  };

  const handleContinueToOverwrite = () => {
    toggleContinueToOverwrite(!continueToOverwrite);
  };

  const onContinueOverwriting = () => {
    setOverwriteSite(false);
  };

  const verifyIfPlanHasWooPremium = useCallback(() => {
    dispatch(sitesActions.addonCheck.request({ hostingId: chosenPlan.external_id }));
  }, [dispatch, chosenPlan]);

  useEffect(() => {
    if (shouldRenderEcommercePlusCard !== null && shouldRenderWooopremiumOffer && activeStep === stepsStatus.ABOUT) {
      handleContinueToNextStep(stepsStatus.TOOLS);
    }
  }, [handleContinueToNextStep, shouldRenderEcommercePlusCard, shouldRenderWooopremiumOffer, stepsStatus, activeStep]);

  const getWordpressConfigInfoButtonLabel = () => {
    if (!isWondersuiteEnabled) {
      return wizardLocale.tools.wordPressInstall.info.nextStepButton;
    }
    if (asaasInsideWizardShouldAppear) {
      return wizardLocale.tools.wordPressInstall.plugin.nextStepButtonWithAsaas;
    }
    return wizardLocale.tools.wordPressInstall.plugin.nextStepButton;
  };

  const getOverwriteSitesModalProps = () => {
    const defaultProps = {
      title: wizardLocale.modalOverwrite.title,
      warningDomain: wizardLocale.modalOverwrite.warningDomain,
      warningLink: wizardLocale.modalOverwrite.warningLink,
      checkboxLabel: wizardLocale.modalOverwrite.checkboxLabel,
      continueButtonLabel: wizardLocale.modalOverwrite.continueButtonLabel,
      backButtonLabel: wizardLocale.modalOverwrite.backButtonLabel,
      toggleContinueToOverwrite: handleContinueToOverwrite,
      cancelOverwrite: resetFirstStepFields,
      onClose: resetFirstStepFields,
      onContinue: onContinueOverwriting,
      selectedDomain,
      continueToOverwrite,
      continueToOverwriteAnalytics: wizardConfigurationsAnalytics.continueToOverwriteAnalytics,
      closeOverwriteAnalytics: wizardConfigurationsAnalytics.closeOverwriteAnalytics,
      goBackOverwriteAnalytics: wizardConfigurationsAnalytics.goBackOverwriteAnalytics,
      checkboxOverwriteAnalytics: wizardConfigurationsAnalytics.checkboxOverwriteAnalytics,
      materialOverwriteAnalytics: wizardConfigurationsAnalytics.materialOverwriteAnalytics,
    };

    return defaultProps;
  };

  const getVerifiedOverwriteSitesModalProps = () => {
    const defaultProps = {
      title: wizardLocale.modalVerifiedOverwrite.title,
      warningDomain: wizardLocale.modalVerifiedOverwrite.warningDomain,
      warningDomainWithDns: wizardLocale.modalVerifiedOverwrite.warningDomainWithDns,
      warningAboutDns: wizardLocale.modalVerifiedOverwrite.warningAboutDns,
      warningLink: wizardLocale.modalVerifiedOverwrite.warningLink,
      checkboxLabel: wizardLocale.modalVerifiedOverwrite.checkboxLabel,
      continueButtonLabel: wizardLocale.modalVerifiedOverwrite.continueButtonLabel,
      backButtonLabel: wizardLocale.modalVerifiedOverwrite.backButtonLabel,
      errorMessage: wizardLocale.modalVerifiedOverwrite.error,
      cancelOverwrite: resetFirstStepFields,
      onClose: resetFirstStepFields,
      onContinue: uninstallSite,
      loadingUninstall,
      selectedDomain,
      uninstallError,
      userAgreeOverwriteSite,
      handleToggleUserAgreeOverwriteSite,
      warnAboutDns: currentlyInstallInfo.sharedDocumentRoot,
      continueToVerifiedOverwriteAnalytics: wizardConfigurationsAnalytics.continueToVerifiedOverwrite,
      securityCopyOverwriteModalAnalytics: wizardConfigurationsAnalytics.securityCopyVerifiedOverwriteModal,
      showVerifiedOverwriteSiteModalAnalytics: wizardConfigurationsAnalytics.showVerifiedOverwriteSiteModal,
    };

    return defaultProps;
  };

  const onToolCardClick = (tool) => {
    wizardToolsAnalytics.chooseTool(tool, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
  };

  useEffect(() => {
    switch (activeStep) {
      case stepsStatus.CONFIGURATION:
        wizardConfigurationsAnalytics.accessFirstStepSiteBuilder();
        break;
      case stepsStatus.ABOUT:
        if (siteType === defaultSiteTypeValue) {
          wizardAboutAnalytics.accessSecondStepSiteBuilder();
        }
        break;
      case stepsStatus.TOOLS:
        if (isWordpressInstall) {
          wizardToolsAnalytics.accessWordPressInstall(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        } else {
          wizardToolsAnalytics.accessThirdStepSiteBuilder(getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        }
        break;
      case stepsStatus.TIPS:
        wizardTipsAnalytics.accessFourthStepSiteBuilder(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
        break;
      default: break;
    }
  }, [activeStep, toolSelected, wizardConfigurationsAnalytics, wizardAboutAnalytics, wizardToolsAnalytics, stepsStatus, wizardTipsAnalytics, isWordpressInstall, shouldRenderWooopremiumOffer, siteType, siteTypes, getSelectedSiteTypeGaValue, shouldRenderEcommercePlusCard, userSawEcommercePlusOffer]);

  const openSiteBuilderWindowLink = useCallback(() => {
    if (activeStep === stepsStatus.TIPS && isSiteBuilderWindowOpenEnabled && toolSelected === 'sitebuilder') {
      const urlToOpen = hasSiteBuilderActive
        ? `${wizardLocale.tips.siteBuilder.baseUrl}/${selectedSite.label}`
        : `${wizardLocale.tips.siteBuilder.baseUrl}/${selectedSite.label}/${selectedPlan.external_id}`;
      window.open(urlToOpen);
    }
  }, [activeStep, selectedSite, stepsStatus, isSiteBuilderWindowOpenEnabled, hasSiteBuilderActive, toolSelected, selectedPlan]);

  useEffect(() => {
    openSiteBuilderWindowLink();
  }, [openSiteBuilderWindowLink]);

  const displayWarningModal = useCallback(() => {
    const activeDomain = userDomainList.find(domain => selectedSite && selectedSite.label === domain.label);
    const isListedDomain = activeDomain && activeDomain.externalId;

    if (isListedDomain) {
      setOverwriteSite(true);
    }
  }, [selectedSite, userDomainList]);

  useEffect(() => {
    displayWarningModal();
  }, [displayWarningModal]);

  useEffect(() => {
    getCep(asaasFormData.fields.cep.value);
  }, [getCep, asaasFormData.fields.cep.value]);

  if (!isSitesBuilderEnabled) {
    return <Redirect to={sitesBuilderLocale.routeSitesPage} />;
  }

  const getConfigurationsProps = () => {
    const defaultProps = {
      choosePlan: wizardLocale.configurations.choosePlan,
      chosenPlan,
      comparingDns,
      continueText: wizardLocale.configurations.continue,
      costumerNeedToUpgradePlan,
      customDomain,
      getSingleDomainsPlans,
      getMultiDomainsPlans,
      handleChangeDomain,
      handleShowModal,
      mainDomain: wizardLocale.configurations.mainDomain,
      modals: wizardEnum.modals,
      multiDomainsPlans,
      planLimitBuyOtherPlan: wizardLocale.configurations.planLimit.buyOtherPlan,
      planLimitChangePlan: wizardLocale.configurations.planLimit.changePlan,
      planLimitText: wizardLocale.configurations.planLimit.text,
      planLimitTitle: wizardLocale.configurations.planLimit.title,
      selectedDomain,
      selectedDomainPlan,
      selectedPlan: wizardLocale.configurations.selectedPlan,
      selectPlaceholder: wizardLocale.configurations.selectPlaceholder,
      selectText: wizardLocale.configurations.selectText,
      showModal,
      sitesState,
      text: wizardLocale.configurations.text,
      title: wizardLocale.configurations.title,
      titleStrong: wizardLocale.configurations.titleStrong,
      tooltipReplace: wizardLocale.configurations.tooltipReplace,
      tooltipSinglePlan: wizardLocale.configurations.tooltipSinglePlan,
      userDomainList,
      wishOtherDomain: wizardLocale.configurations.wishOtherDomain,
      singleDomainsPlans,
      selectedSite,
      showOtherPlans: wizardConfigurationsAnalytics.selectOtherPlan,
      displayOverwriteModal,
      hirePlanTitle: wizardLocale.configurations.hirePlanTitle,
      hirePlanSubtitle: wizardLocale.configurations.hirePlanSubtitle,
      hirePlanCta: wizardLocale.configurations.hirePlanCta,
      registerDomainTitle: wizardLocale.configurations.registerDomainTitle,
      registerDomainSubtitle: wizardLocale.configurations.registerDomainSubtitle,
      registerDomainCta: wizardLocale.configurations.registerDomainCta,
      withGatorSolutionsPlans,
    };

    const handleBuyOtherPlanClick = () => {
      wizardConfigurationsAnalytics.upgradePlanBuyNewPlan();
    };

    const handleChangePlanClick = () => {
      handleShowModal(wizardEnum.modals.UPGRADE_PLAN);
      wizardConfigurationsAnalytics.upgradePlanButtonClick();
    };

    const handleSelectDomainOnContinue = async () => {
      wizardConfigurationsAnalytics.continueToSecondStep();
      setDnsAnalyzeStarted(false);
      setSkipSiteExistCheck(false);
      if (isSiteExistsCheckEnabled) {
        dispatch(sitesActions.verifyIfSiteExist.reset());
      }
      if (isSiteExistsCheckEnabled) {
        return dispatch(sitesActions.verifyIfSiteExist.request({ domain: selectedDomain.label, hostingId: chosenPlan.external_id }));
      }
      setSkipSiteExistCheck(true);
    };

    const handleWishOtherDomain = () => {
      wizardConfigurationsAnalytics.selectUnlistedDomain();
      handleShowModal(wizardEnum.modals.ANOTHER_DOMAIN);
    };

    const handleClickHirePlan = () => {
      window.open(HOSTING_URL, '_blank').focus();
    };

    const handleClickRegisterDomain = () => {
      const splitedOtherDomain = sitesState.anotherDomain.split('.');

      window.open(`${config.CART_URL}?pid=d&sld=${splitedOtherDomain[0]}&tld=.${splitedOtherDomain[1]}`, '_blank').focus();
    };

    return {
      ...defaultProps,
      handleBuyOtherPlanClick,
      handleChangePlanClick,
      handleSelectDomainOnContinue,
      handleWishOtherDomain,
      handleClickHirePlan,
      handleClickRegisterDomain,
      checkDomainAvailability,
    };
  };

  const getWizardWordPressConfigProps = () => {
    const defaultProps = {
      title: verifyIfIsWoocommerce(toolSelected)
        ? wizardLocale.tools.wordPressInstall.titleActionWoocommerce
        : wizardLocale.tools.wordPressInstall.titleActionWordPress,
      subtitle: wizardLocale.tools.wordPressInstall.subtitle,
      wodpressSetupValues,
      wordPressSetupReadyToContinue,
      wordpressInfoTitle: wizardLocale.tools.wordPressInstall.info.title,
      wordpressInfoDescription: wizardLocale.tools.wordPressInstall.info.description,
      infoNextStepButton: getWordpressConfigInfoButtonLabel(),
      questions: wizardLocale.tools.wordPressInstall.info.questions,
      passwordChecklistTexts: wizardLocale.tools.wordPressInstall.passwordChecklistTexts,
      userChecklistTexts: wizardLocale.tools.wordPressInstall.userChecklistTexts,
      themeTitle: wizardLocale.tools.wordPressInstall.theme.title,
      themeDescription: wizardLocale.tools.wordPressInstall.theme.description,
      themeTooltip: wizardLocale.tools.wordPressInstall.theme.tooltip,
      themes: wordpressThemes,
      themeSelected,
      nextStepButtonLabel: wizardLocale.tools.wordPressInstall.theme.nextStepButton,
      setupSteps: isWondersuiteEnabled ? setupStepsInWondersuite : setupSteps,
      activeSetupStep,
      pluginTitle: wizardLocale.tools.wordPressInstall.plugin.title,
      pluginDescription: wizardLocale.tools.wordPressInstall.plugin.description,
      pluginTooltip: wizardLocale.tools.wordPressInstall.plugin.tooltip,
      asaasToggleTitle: wizardLocale.tools.wordPressInstall.asaas.title,
      asaasToggleDescription: wizardLocale.tools.wordPressInstall.asaas.description,
      wordPressPlugins,
      pluginsSelected,
      finishLabel: asaasInsideWizardShouldAppear ? wizardLocale.tools.wordPressInstall.plugin.nextStepButtonWithAsaas : wizardLocale.tools.wordPressInstall.plugin.nextStepButton,
      handleContinueToNextStep,
      activeStep,
      modalTitle: wizardLocale.tools.wordPressInstall.installModal,
      loadingWordpressInstallation: wordpressLoading,
      isBravoInsideWizardEnabled,
      asaasFormData,
      accountCreated,
      asaasOnChange,
      asaasOnContinue,
      loadingCep,
      asaasEnabledFormOpenAccountContinue,
      asaasEnabledMainDataContinue,
      asaasOnGoback,
      asaasOnSubmit,
      asaasResetForm,
      asaasCnpjTypes,
      asaasCallformStepTwoMyCEP,
      asaasTitle: asaasLocale.wizard.firstRow.title(),
      asaasDescription: asaasLocale.wizard.firstRow.description(),
      asaasReachedThridStep,
      createAsaasContinueLabel: asaasLocale.wizard.createAsaasContinueLabel,
      dontCreateAsaasContinueLabel: asaasLocale.wizard.dontCreateAsaasContinueLabel,
      asaasInsideWizardShouldAppear,
      isWondersuiteEnabled,
    };

    const { imageItems } = asaasLocale.wizard.firstRow;
    let descriptionImageAlt = '';
    imageItems.forEach((item, idx) => {
      descriptionImageAlt += (idx + 1) < imageItems.length ? `${item}, ` : item;
    });

    const handleUpdateWordpressSetupValues = (event) => {
      const values = wordpressSetupFunctions.handleUpdateWordpressSetupValues({ event, wodpressSetupValues });
      values && setWordpressSetupValues(values);
    };

    const handleBlurWordpressSetupValues = (event) => {
      const values = wordpressSetupFunctions.handleBlurWordpressSetupValues({ event, wodpressSetupValues });
      wizardToolsAnalytics.fillFormInputs(event.target.name, toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      values && setWordpressSetupValues(values);
    };

    const handleChangeTheme = (selectedTheme) => {
      setThemeSelected(selectedTheme);

      const themeSelected = wordpressThemes.find(theme => theme.value === selectedTheme);
      wizardToolsAnalytics.chooseTheme(toolSelected, themeSelected.title, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    };

    const handleOnOpenThemeModal = (option) => {
      setDisplayThemeInfoModal(true);
      setModalTheme(option);
      const themeSelected = wordpressThemes.find(theme => theme.value === option);
      wizardToolsAnalytics.visualizeTheme(toolSelected, themeSelected.title, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    };

    const onContinueSetup = (currentStep) => {
      const nextSetupStep = currentStep + 1;
      setActiveSetupStep(nextSetupStep);
    };

    const handleChangePlugin = (clickedPlugin) => {
      const activePlugins = wordpressPluginsFunctions.getSelectedPlugins({
        clickedPlugin,
        pluginsSelected,
      });

      const isActive = activePlugins.find(plugin => plugin === clickedPlugin);

      if (isActive) {
        const pluginSelected = wordPressPlugins.find(plugin => plugin.value === clickedPlugin);
        wizardToolsAnalytics.choosePlugin(toolSelected, pluginSelected.gaValue, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      }
      setPluginsSelected(activePlugins);
    };

    const handleLoadingWordpress = () => {
      wizardToolsAnalytics.finishWordPressInstall(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      const sets = [...pluginsSelected];

      if (verifyIfIsWoocommerce(toolSelected)) {
        sets.push('Woocommerce_admin');
      }

      if (!isWondersuiteEnabled) {
        sets.push(themeSelected);
      }

      if (isHostgatorPluginEnabled && !isWondersuiteEnabled) {
        sets.push('HostGator_admin');
      }

      if (asaasReachedThridStep) {
        sets.push('Asaas_admin');
      }

      const wordpress = {
        domain: selectedDomain.label,
        admin: wodpressSetupValues.user.value,
        password: wodpressSetupValues.password.value,
        email: wodpressSetupValues.email.value,
        name: wodpressSetupValues.name.value,
        description: wodpressSetupValues.description.value,
        sets,
        external_id: parseInt(chosenPlan.external_id, 10),
      };

      if (asaasInsideWizardShouldAppear && !asaasReachedThridStep) {
        wizardToolsAnalytics.asaasDontWantAsaas(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      }

      if (asaasInsideWizardShouldAppear && asaasReachedThridStep) {
        wizardToolsAnalytics.finishAndInstallWithAsaas(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      }

      const themeId = isWondersuiteEnabled ? 'wp-default' : '';

      dispatch(sitesActions.wordpressInstallation.request({
        wordpress,
        attempt: removeAttemptConfigureWordpress ? 0 : 1,
        setHgPluginIsInstall,
        isWondersuiteEnabled,
        themeId,
        userSelectedWondersuiteTrial,
        isFastCheckoutAddonEnabled,
        userSelectedUseEcommercePlus,
        requestAutoSsl,
      }));
    };

    const validateWordpressInfo = () => {
      const validatedValues = wordpressSetupFunctions.validateWordPressInfoInputs({ wodpressSetupValues });

      setWordpressSetupValues(validatedValues);
    };

    return {
      ...defaultProps,
      handleUpdateWordpressSetupValues,
      handleBlurWordpressSetupValues,
      handleChangeTheme,
      handleOnOpenThemeModal,
      onContinueSetup,
      handleChangePlugin,
      handleLoadingWordpress,
      validateWordpressInfo,
      descriptionImageAlt,
      imageItems,
    };
  };

  const getWizardHandlerModalsProps = () => {
    const defaultProps = {
      displayOthersSitesModal,
      wizardLocale,
      othersSitesEditFieldValue,
      displayOthersObjectivesModal,
      othersObjectivesEditFieldValue,
      displayThemeInfoModal,
      showModal,
      modals,
      sitesState,
      wizardConfigurationsAnalytics,
      selectedDomain,
      handleChangeDomain,
      chosenPlan,
      selectedDomainPlan,
      setChosenPlan,
      singleDomainsPlans,
      multiDomainsPlans,
      handleContinueToNextStep,
      displayCreateAccountModal,
      asaasLocale,
      loadingVerifyIfSiteExist,
      loadingDefaultFilesExclude,
      userDomainList,
      withGatorSolutionsPlans,
    };

    const handleOnCloseOthersSitesModal = (action) => {
      setDisplayOthersSitesModal(false);
      if (othersSitesFieldValue !== wizardLocale.about.defaultOptions.otherTitle) {
        setOthersSitesEditFieldValue(othersSitesFieldValue);
      }
      wizardAboutAnalytics.otherSiteTypeModalClose(action);
    };

    const handleOthersSitesEditFieldValue = (eventValue, maxLength) => {
      if (eventValue.length <= maxLength) {
        setOthersSitesEditFieldValue(eventValue);
      }
    };

    const handleOnSaveOthersSitesModal = () => {
      setOthersSitesFieldValue(othersSitesEditFieldValue);
      const newSiteTypesOptions = [...siteTypes];
      newSiteTypesOptions[siteTypes.length - 1].title = othersSitesEditFieldValue;
      setSiteTypes(newSiteTypesOptions);
      setDisplayOthersSitesModal(false);
      wizardAboutAnalytics.otherSiteTypeModalSave();
    };

    const handleOnCloseOthersObjectivesModal = (action) => {
      setDisplayOthersObjectivesModal(false);
      if (othersObjectivesFieldValue !== wizardLocale.about.defaultObjectives.other) {
        setOthersObjectivesEditFieldValue(othersObjectivesFieldValue);
      }
      wizardAboutAnalytics.otherSiteObjectiveModalClose(action);
    };

    const handleOthersObjectivesEditFieldValue = (eventValue, maxLength) => {
      if (eventValue.length <= maxLength) {
        setOthersObjectivesEditFieldValue(eventValue);
      }
    };

    const handleOnSaveOthersObjectivesModal = () => {
      setOthersObjectivesFieldValue(othersObjectivesEditFieldValue);
      const newObjectiveOptions = [...objectiveOptions];
      newObjectiveOptions[objectiveOptions.length - 1].label = othersObjectivesEditFieldValue;
      setObjectiveOptions(newObjectiveOptions);
      setDisplayOthersObjectivesModal(false);
      wizardAboutAnalytics.otherSiteObjectiveModalSave();
    };

    const handleOnCloseThemeInfoModal = (theme) => {
      wizardToolsAnalytics.closeWordPressThemeModal(toolSelected, theme.title, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      setDisplayThemeInfoModal(false);
    };

    const getModalTheme = () => (wordpressThemes.find(theme => theme.value === modalTheme));

    const handleModalChangeTheme = (selectedTheme) => {
      setDisplayThemeInfoModal(false);
      setThemeSelected(selectedTheme);
      const themeSelected = wordpressThemes.find(theme => theme.value === selectedTheme);
      wizardToolsAnalytics.chooseThemeWordPressThemeModal(toolSelected, themeSelected.title, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    };
    const backAnotherDomainModal = (modal) => {
      resetTldAndSld();
      wizardConfigurationsAnalytics.backToAnotherDomainModal(modal);
      handleShowModal(wizardEnum.modals.ANOTHER_DOMAIN);
    };

    const getActivePlans = () => plans.filter(plan => plan.status === 'active');

    return {
      ...defaultProps,
      handleOnCloseOthersSitesModal,
      handleOthersSitesEditFieldValue,
      handleOnSaveOthersSitesModal,
      handleOnCloseOthersObjectivesModal,
      handleOthersObjectivesEditFieldValue,
      handleOnSaveOthersObjectivesModal,
      handleOnCloseThemeInfoModal,
      handleContinueDnsInProgressModal: onClickTipsButton,
      getModalTheme,
      handleModalChangeTheme,
      handleCloseModal,
      backAnotherDomainModal,
      getActivePlans,
      checkDomainAvailability,
    };
  };

  const getWizardTipsProps = () => {
    const { redirectFromPreChat } = sitesState;
    const defaultProps = {
      title: tips.title,
      hiddenInfo: tips.hiddenInfo,
      subtitle: tips.subtitle,
      buttonLabel: tips.buttonLabel,
      seeBellow: tips.seeBellow,
      ssoUrl: tips.ssoUrl,
      faqLocaleTitle: tips.faqContent.title,
      faqLocaleButton: tips.faqContent.button,
      questionsFaq,
      asaasTag: wizardLocale.asaasBanner.chip,
      asaasTitle: wizardLocale.asaasBanner.title,
      asaasDescription: wizardLocale.asaasBanner.description(),
      asaasDescriptionClick: () => wizardToolsAnalytics.accessAsaasHelpCenter(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer),
      asaasButtonLabel: wizardLocale.asaasBanner.buttonLabel,
      asaasLoginBannerTitle: wizardLocale.tips.asaasLoginBanner.title,
      asaasLoginBannerDescription: wizardLocale.tips.asaasLoginBanner.description,
      asaasLoginBannerButton: wizardLocale.tips.asaasLoginBanner.button,
      id: tips.id,
      asaasInsideWizardShouldAppear,
      asaasReachedThridStep,
      handleShowModal,
    };

    const checkPreChat = () => {
      if (redirectFromPreChat) {
        dispatch(preChatActions.preChat.setOpen(true));
        dispatch(sitesActions.chat.setRedirectFromPreChat(false));
      }
    };

    const handleMoreContent = () => {
      const rules = {
        [toolsNames.OTHER]: wizardLocale.faqContent.buttonRedirectSoftaculos,
        [toolsNames.SITEBUILDER]: wizardLocale.faqContent.buttonRedirectSitesBuilder,
        [toolsNames.WORDPRESS]: wizardLocale.faqContent.buttonRedirectWordPress,
        [toolsNames.WOOCOMMERCE]: wizardLocale.faqContent.buttonRedirectWordPress,
        [toolsNames.ECOMMERCEPLUS_TRIAL]: wizardLocale.faqContent.buttonRedirectWordPress,
        [toolsNames.ECOMMERCEPLUS]: wizardLocale.faqContent.buttonRedirectWordPress,
      };
      wizardTipsAnalytics.showMoreMaterials(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      window.open(rules[toolSelected], '_blank');
    };

    const onClickAsaasLoginBanner = () => {
      window.open(ASAAS_LOGIN_LINK);
    };

    const onClickMaterial = (materialNumber) => {
      wizardTipsAnalytics.accessMaterial(toolSelected, materialNumber, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    };

    const asaasBannerClick = () => {
      wizardTipsAnalytics.asaasBannerClick(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      history.push(wizardLocale.asaasBanner.asaasRoute);
    };

    return {
      ...defaultProps,
      handleMoreContent,
      onClickButton: onClickTipsButton,
      onClickAsaasLoginBanner,
      onClickMaterial,
      asaasBannerClick,
      checkPreChat,
      requestAutoSsl,
    };
  };

  const getFeedbackStepperProps = () => {
    const defaultProps = {
      steps,
      activeStep,
      stepActiveColor: color.white,
      stepActiveBgColor: color.cyanCobaltBlue,
      stepDoneBgColor: color.shamrockGreen,
      stepDoneColor: color.white,
      testId: 'wizard',
    };

    return {
      ...defaultProps,
    };
  };

  const getWizardAboutProps = () => {
    const defaultProps = {
      title: wizardLocale.about.title,
      subtitle: wizardLocale.about.subtitle,
      question: {
        text: wizardLocale.about.question,
        tooltip: wizardLocale.about.questionTooltip,
      },
      siteTypes,
      checkedItem: siteType,
      objectiveOptions,
      selectedObjectiveIndex,
      objectivesQuestion: wizardLocale.about.objectivesQuestion,
      objectivesTooltip: wizardLocale.about.objectivesTooltip,
      continueButton: wizardLocale.continueButton,
      onContinue: shouldRenderWooopremiumOffer && isBRServer ? verifyIfPlanHasWooPremium : handleContinueToNextStep,
      sitesLoading,
      objectivesPlaceholder: wizardLocale.about.objectivesPlaceholder,
      setDisplayOthersModal: setDisplayOthersSitesModal,
      othersFieldEditMode: othersSitesFieldEditMode,

    };

    const handleClickObjective = (option) => {
      if (option.value === wizardLocale.about.defaultObjectives.other) {
        setDisplayOthersObjectivesModal(true);
      }
    };

    return {
      ...defaultProps,
      onChange: handleChangeSiteType,
      handleChangeObjective,
      handleClickObjective,
    };
  };

  const getWizardToolsProps = () => {
    const siteClassification = shouldRenderEcommercePlusCard ? 'ecommercePlus' : siteType;
    const defaultProps = {
      title: [
        wizardLocale.tools.title.default,
        `"${recommendedTool}"`,
        wizardLocale.tools.title[siteClassification],
      ],
      subtitle: isPlanSiteBuilder ? '' : wizardLocale.tools.subtitle[siteClassification],
      tools,
      showMoreText: wizardLocale.showMore,
      showLessText: wizardLocale.showLess,
      selectedSite,
      siteType,
      shouldRenderEcommercePlusCard,
      onWoocommerceHelpMaterialClick: () => wizardToolsAnalytics.accessWoocommerceHelpMaterial(getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer),
      requestAutoSsl,
    };

    return {
      ...defaultProps,
      onToolCardClick,
    };
  };

  const getWizardWondersuiteProps = () => {
    const onWondersuiteTrialStart = () => {
      setUserSelectedWondersuiteTrial(true);
      setToolSelected(toolsNames.ECOMMERCEPLUS_TRIAL);
      onToolCardClick(wizardLocale.tools.wondersuite.wondersuiteHeroBanner.btnGA);
    };

    const defaultProps = {
      tools: {
        title: wizardLocale.tools.wondersuite.tools.title,
        tools,
        showMoreText: wizardLocale.showMore,
        showLessText: wizardLocale.showLess,
        onToolCardClick,
        sitesLoading,
      },
      items: wizardLocale.tools.wondersuite.features,
      heroBanner: {
        onWondersuiteTrialStart,
        title: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.title(),
        description: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.description,
        sitesLoading,
        tagLabel: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.tagLabel,
        btnLabel: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.btnLabel,
        assignValue: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.assignValue(),
        wordpressExperience: wizardLocale.tools.wondersuite.wondersuiteHeroBanner.wordpressExperience,
      },
    };

    return { ...defaultProps };
  };

  if (!sitesState.plansLoaded || !sitesState.loadedDomains) {
    return (
      <Styles.Wrapper data-testid="site-builder-handler" ref={containerRef}>
        <FeedbackStepper {...getFeedbackStepperProps()} />
        <Styles.Container loading>
          <IconLoading />
        </Styles.Container>
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper data-testid="site-builder-handler" ref={containerRef}>
      <FeedbackStepper {...getFeedbackStepperProps()} />

      <Styles.Container>
        {activeStep === stepsStatus.CONFIGURATION && (
          <Configurations {...getConfigurationsProps()} />
        )}

        {(overwriteSite && displayOverwriteModal && !isSiteExistsCheckEnabled) && (
          <OverwriteSiteModal {...getOverwriteSitesModalProps()} />
        )}

        {currentlyInstallInfo.showOverwriteModal && isSiteExistsCheckEnabled && (
          <VerifiedOverwriteSiteModal {...getVerifiedOverwriteSitesModalProps()} />
        )}

        {activeStep === stepsStatus.ABOUT && (
          <WizardAbout {...getWizardAboutProps()} />
        )}

        {activeStep === stepsStatus.TOOLS && !isWordpressInstall && (!shouldRenderWooopremiumOffer || shouldRenderEcommercePlusCard) && (
          <WizardTools {...getWizardToolsProps()} />
        )}

        {activeStep === stepsStatus.TOOLS && !isWordpressInstall && (shouldRenderWooopremiumOffer && !shouldRenderEcommercePlusCard) && (
          <WizardWondersuite {...getWizardWondersuiteProps()} />
        )}

        {(activeStep === stepsStatus.TOOLS && isWordpressInstall) && (
          <WizardWordPressConfig {...getWizardWordPressConfigProps()} />
        )}

        { (activeStep === stepsStatus.TIPS && toolSelected !== '') && (
          <WizardTips {...getWizardTipsProps()} />
        )}

        <Styles.ContainerBottomRow>
          <Styles.BackButtonWrapper>
            {((activeStep > 1 || costumerNeedToUpgradePlan || sitesActions.domainAvailability) && (!!sitesState.plans.length)) && (
              <>
                <IconArrowForward
                  size={18}
                  fill={theme.v1.color.action.primary}
                  stroke={theme.v1.color.action.primary}
                />
                <TextButton
                  text={activeStep === stepsStatus.TIPS ? wizardLocale.backHome : wizardLocale.backButton}
                  onClick={activeStep === stepsStatus.TIPS ? handleOnChangeHome : handleBack}
                  target="_blank"
                />
              </>
            )}
          </Styles.BackButtonWrapper>

          <Styles.Snappy src={isMobile ? snappyMobile : snappyDesktop} alt={sitesBuilderLocale.altSnappy} data-testid="snappy-image" />
        </Styles.ContainerBottomRow>
      </Styles.Container>

      <Styles.BottomLine
        src={colorLine}
        alt={sitesBuilderLocale.altColorLine}
        data-testid="colored-line-image"
      />

      <WizardHandlerModals {...getWizardHandlerModalsProps()} />
    </Styles.Wrapper>
  );
};

export default WizardHandler;
