import React from 'react';
import * as Styles from './HowToSetupStep.styles';
import {
  Alert, Button,
  IconArrow,
  IconButton,
} from 'gatorcomponents';
import PropTypes from 'prop-types';
import { PROVIDERS_LINKS } from '../../useDnsModal.types';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';

export const HowToSetupStep = ({
  howToSectionTitle,
  domainDns = {
    ns1: 'ns1.domain.com',
    ns2: 'ns2.domain.com',
  },
  showCopyNotification,
  selectedManualDns,
  onClickBack,
  onHowToSetupClick,
  serverLabel,
  goTo,
  alreadyMadeChanges,
  backLabel,
  steps = [
    {
      id: 'firstStep',
      description: 'Acesse o site do provedor onde seu domínio está registrado',
    },
    {
      id: 'secondStep',
      description: 'Localize os nomes de servidores (DNS) atuais no site da Godaddy',
    },
    {
      id: 'thirdStep',
      description: 'Substitua o nomes de servidores (DNS) que está no site da Godaddy pelo da CloudFlare. Basta copiar e colar os valores abaixo:',
      alert: 'Ao continuar suas configurações atuais do domínio serão alteradas',
    },
  ],
  cloudflareDns = {
    ns1: 'ns1.cloudflare.com',
    ns2: 'ns2.cloudflare.com',
  },
}) => {
  const handleGoNext = () => {
    onHowToSetupClick && onHowToSetupClick();
  };

  const handleGoBack = () => {
    onClickBack && onClickBack();
  };

  const handleOpenLink = ({ label }) => {
    const url = PROVIDERS_LINKS.find(provider => provider.name === label).page;
    window.open(url, '_blank');
  };

  const handleCopy = (text) => {
    copyTextToClipboard(text);
    showCopyNotification();
  };

  return (
    <Styles.Wrapper data-testid="HowToSetupStep">
      <Styles.HowToBackground>
        <Styles.HowToTitle data-testid="howToTitle">
          {howToSectionTitle}
        </Styles.HowToTitle>

        <Styles.Steps>
          {steps.map((step, index) => (
            <Styles.StepItem key={step.id} id={step.id}>
              <Styles.StepContainer>
                <Styles.StepDescription>
                  {step.description}
                </Styles.StepDescription>

                {index === 2 && (
                <Styles.StepAlertWrapper>
                  <Alert
                    withoutBackground
                    description={step.alert}
                    variant="info"
                    testId="alert"
                  />
                </Styles.StepAlertWrapper>
                )}

                {index === 1 && (
                  <Styles.StepDnsBox>
                    <Styles.StepDnsBoxRow>
                      <Styles.StepLabel>
                        {serverLabel(1)}
                      </Styles.StepLabel>
                      <Styles.StepValue data-testid="domainDns">
                        {domainDns && domainDns.ns1}
                      </Styles.StepValue>
                    </Styles.StepDnsBoxRow>

                    <Styles.StepDnsBoxRow>
                      <Styles.StepLabel>
                        {serverLabel(2)}
                      </Styles.StepLabel>
                      <Styles.StepValue data-testid="domainDns">
                        {domainDns && domainDns.ns2}
                      </Styles.StepValue>
                    </Styles.StepDnsBoxRow>
                  </Styles.StepDnsBox>
                )}

                {index === 2 && (
                  <>
                    <Styles.StepDnsRow>
                      <Styles.StepLabel>
                        {serverLabel(1)}
                      </Styles.StepLabel>
                      <Styles.StepValue data-testid="cloudflareDns">
                        {cloudflareDns.ns1}
                      </Styles.StepValue>

                      <IconButton
                        size="sm"
                        onClick={() => handleCopy(cloudflareDns.ns1)}
                        icon="IconCopy"
                      />
                    </Styles.StepDnsRow>

                    <Styles.StepDnsRow>
                      <Styles.StepLabel>
                        {serverLabel(2)}
                      </Styles.StepLabel>
                      <Styles.StepValue data-testid="cloudflareDns">
                        {cloudflareDns.ns2}
                      </Styles.StepValue>

                      <IconButton
                        size="sm"
                        onClick={() => handleCopy(cloudflareDns.ns2)}
                        icon="IconCopy"
                      />
                    </Styles.StepDnsRow>
                  </>
                )}
              </Styles.StepContainer>
            </Styles.StepItem>
          ))}
        </Styles.Steps>

        <Styles.ButtonWrapper>
          <Button
            size="large"
            variant="secondary"
            width="fit-content"
            label={goTo(selectedManualDns)}
            onClick={() => handleOpenLink({ label: selectedManualDns })}
            testId="goToLinkButton"
          />
        </Styles.ButtonWrapper>
      </Styles.HowToBackground>

      <Styles.ActionsRow>
        <Button
          onClick={handleGoBack}
          size="large"
          label={backLabel}
          variant="tertiary"
          testId="goBackButton"
        />
        <Button
          size="large"
          label={alreadyMadeChanges}
          onClick={handleGoNext}
          testId="goToNextButton"
          iconRight={<IconArrow direction="right" />}
        />
      </Styles.ActionsRow>
    </Styles.Wrapper>
  );
};

HowToSetupStep.propTypes = {
  howToSectionTitle: PropTypes.string,
  domainDns: PropTypes.shape({
    ns1: PropTypes.string,
    ns2: PropTypes.string,
  }),
  showCopyNotification: PropTypes.func,
  selectedManualDns: PropTypes.string,
  onClickBack: PropTypes.func,
  onHowToSetupClick: PropTypes.func,
  serverLabel: PropTypes.func,
  goTo: PropTypes.func,
  alreadyMadeChanges: PropTypes.string,
  backLabel: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    alert: PropTypes.string,
  })),
  cloudflareDns: PropTypes.shape({
    ns1: PropTypes.string,
    ns2: PropTypes.string,
  }),
};

export default HowToSetupStep;
