import styled, { css } from 'styled-components';

export const Card = styled.button`
  ${({
    theme, selected,
  }) => css`
    align-items: center;
    background-color: ${selected ? theme.v2.colors.brand.primaryUltraLight : 'transparent'};
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.feedback.canceledPure};
    cursor: pointer;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    justify-content: flex-start;
    min-height: 75px;
    overflow-x: hidden;
    padding: 15px;
    transition: background-color 200ms ease-in-out;
    width: 303px;
    
    &:hover {
      background-color: ${theme.v2.colors.brand.primaryUltraLight}
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    },
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-size: ${theme.v2.font.family.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    text-align: left;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0em;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    padding: 0px;
    text-align: left;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: ${theme.v2.font.sizes.xxxs};
  font-weight: ${theme.v2.font.weights.regular};
  letter-spacing: 0em;
  line-height: ${theme.v2.font.lineHeight.text};
  margin: 0px;
  padding: 0px;
  text-align: left;

  strong {
    font-weight: ${theme.v2.font.weights.medium};
  }
  `}
`;

export const Icon = styled.img`
  ${() => css`
    height: 20px;
    padding: -3px;
    margin: 0px 8px 0px 0px;
  `}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
`;
