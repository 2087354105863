import React from 'react';
import * as Styles from './Separator.styles';

const Separator = ({
  primaryColor = '#F1F3F6',
  width = '100%',
  height = '2px',
  testId = 'separator',
}) => (
  <Styles.Separator
    data-testid={`${testId}`}
    width={width}
    height={height}
    primaryColor={primaryColor}
  />
);

export default Separator;
