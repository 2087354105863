/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles, Typography, Card, Modal, CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import { SITELOCK_RESET_TOAST } from '@/redux/actions/actionsTypes';
import { requestScan, checkScanStatus, configureSMART } from '@/redux/actions/sitelock';
import LinkButton from '@/components/Buttons/LinkButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { SITELOCK_PANEL, SITELOCK_SMARTSCAN_HELP_URL } from '@/config/urls/sitelock';
import { detailsSweepSitelock, doSweepSitelock } from '@/utils/ThirdParties/tagManager';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { SUPPORT_SECURITY_URL } from '@/config/urls/domainsUrls';
import Status from './Status/Status';
import History from './History';
import SMARTHistory from './SMARTHistory';
import SitelockShield from '../SitelockShield';
import SitelockSMARTScanForm from '../SitelockSMARTScanForm';
import SMARTScanUpdateForm from './SMARTScanUpdateForm';
import styles from './styles';

const SitelockDetails = ({ classes, t, testid }) => {
  const sitelockCardTitle = (domain = null, product = null) => (
    <div className={classes.header}>
      <Typography variant="h6" className={classes.pageTitle}>
        {domain}
        {' '}
        {product && (
        <small>
          (
          {product}
          )
        </small>
        )}

      </Typography>
      <LinkButton to={SITELOCK_PANEL} target="_blank" className={classes.panelButton}>
        {t('sitelock.panel')}
      </LinkButton>
    </div>
  );

  const {
    scanning, scanLimitReached, domain, product, issues, status, scanned, smartscan,
  } = useSelector(state => state.sitelock);

  const { config, history } = useSelector(state => state.sitelock.smartscan);
  const needsConfig = smartscan.active && !smartscan.configured;
  const isSMART = smartscan.active && smartscan.configured;
  const faq = useSelector(state => state.faq);
  const dispatch = useDispatch();
  const { productType, productId } = useParams();
  const isStandalone = productType === 'standalone';
  const [modalHistory, setModalHistory] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => { dispatch({ type: SITELOCK_RESET_TOAST }); }, 5000);
    return () => { clearTimeout(timer); };
  }, [status, config]);

  const buttonText = () => {
    if (scanLimitReached || scanned) {
      return t('sitelock.limitReached');
    }
    return scanning ? t('sitelock.checkScan') : t('sitelock.doScan');
  };


  const handleOpenHistory = () => {
    if (product != null) {
      detailsSweepSitelock(product);
    }
    setModalHistory(true);
  };

  const handleCloseHistory = () => {
    setModalHistory(false);
  };

  const handleScanClick = () => {
    if (scanned || scanLimitReached) {
      if (product != null && scanned) {
        doSweepSitelock(product);
      }
      return;
    }
    if (!(scanLimitReached || scanning)) {
      dispatch(requestScan(productType, productId));
    } else {
      dispatch(checkScanStatus(productType, productId)).then(() => {
        setTimeout(() => { dispatch({ type: SITELOCK_RESET_TOAST }); }, 2000);
      });
    }
  };

  const handleConfigClick = () => {
    if (config.pending) return;
    dispatch(configureSMART(productType, productId)).then(() => {
      setTimeout(() => { dispatch({ type: SITELOCK_RESET_TOAST }); }, 2000);
    });
  };

  return domain ? (
    <div className={classes.root} data-testid={testid}>
      <Card className={classnames([classes.card, classes.headerCard])} style={{ paddingTop: '7px', paddingBottom: '7px' }}>
        {(domain != null) && sitelockCardTitle(domain, product) }
      </Card>
      <div className={classes.productDetails}>
        <div className={classes.leftCol}>
          <div className={classes.scanContainer}>
            <Card className={classnames([classes.card, classes.flexCard])}>
              { !needsConfig && (
                <div className={classes.verticalFlexCard}>
                  <Typography variant="h5" className={classes.subtitle}>{t('sitelock.SLtitle')}</Typography>
                  <Typography variant="h1" className={classes.description}>{t('sitelock.SLsubtitle')}</Typography>
                  <Status />
                  <div className={classes.buttons}>
                    <PrimaryButton
                      rel="noopener noreferrer"
                      color="primary"
                      variant="contained"
                      data-id="scanButton"
                      data-testid="scan-button"
                      onClick={handleScanClick}
                      className={classnames(classes.button, { [classes.disabledButton]: (scanLimitReached || scanned) })}
                    >
                      {buttonText()}
                    </PrimaryButton>
                    <PrimaryButton disabled={history === null} className={classnames([classes.button, classes.outlinedButton])} onClick={handleOpenHistory}>{t('sitelock.scanComplete')}</PrimaryButton>
                    <Modal
                      className={classes.modal}
                      open={modalHistory}
                      onClose={handleCloseHistory}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      {isSMART ? (<SMARTHistory handleCloseFn={handleCloseHistory} />) : (<History />)}
                    </Modal>
                  </div>
                  { (scanLimitReached || scanned) && (
                  <p className={classes.scanLimit}>
                    <strong>{t('sitelock.limit1')}</strong>
                    {' '}
                    {t('sitelock.limit2')}
                  </p>
                  )}
                </div>
              )}
              {(needsConfig && isStandalone) && (<SitelockSMARTScanForm />)}
              {(needsConfig && !isStandalone) && (
                <div className={classes.verticalFlexCard}>
                  <Typography variant="h5" className={classes.subtitle}>{t('sitelock.SLtitle')}</Typography>
                  <Typography variant="h1" className={classes.description}>
                    <Trans i18nKey="sitelock.smartscan.subtitleMaterial" helpUrl={SITELOCK_SMARTSCAN_HELP_URL}>
                      <a target="_blank" rel="noopener noreferrer" href={SITELOCK_SMARTSCAN_HELP_URL}>
                        {SITELOCK_SMARTSCAN_HELP_URL}
                      </a>
                    </Trans>
                  </Typography>
                  <Typography variant="h1" className={classnames(classes.description, classes.checkDomainDescription)}>{t('sitelock.smartscan.subtitleCheckDomain')}</Typography>
                  <PrimaryButton className={classnames(classes.button, classes.checkButton, { [classes.disabledButton]: (config.lockButton) })} onClick={handleConfigClick}>{t('sitelock.smartscan.checkDomainBtn')}</PrimaryButton>
                </div>
              )}
              <div>
                {isSMART && (<SMARTScanUpdateForm />)}
              </div>
            </Card>
            <div>
              <div className={classnames(classes.toast, classes.toastVulnerable, !smartscan.configured && smartscan.error && classes.toastOpen)}>{t('sitelock.smartscan.form.error')}</div>
              <div className={classnames(classes.toast, classes.toastPending, !smartscan.configured && smartscan.pending && classes.toastOpen)}>{t('sitelock.smartscan.form.pending')}</div>
              <div className={classnames(classes.toast, classes.toastSecure, !smartscan.configured && smartscan.success && classes.toastOpen)}>
                <CheckIcon />
                {t('sitelock.smartscan.form.success')}
              </div>
              <div className={classnames(classes.toast, classes.toastVulnerable, !smartscan.configured && config.error && classes.toastOpen)}>{t('sitelock.smartscan.config.error')}</div>
              <div className={classnames(classes.toast, classes.toastPending, !smartscan.configured && config.pending && classes.toastOpen)}>{t('sitelock.smartscan.config.pending')}</div>
              <div className={classnames(classes.toast, classes.toastSecure, config.success && classes.toastOpen)}>
                <CheckIcon />
                {t('sitelock.smartscan.config.success')}
              </div>
              <div className={classnames(classes.toast, classes.toastPending, status.scanning && classes.toastOpen)}>{t('sitelock.toastPending')}</div>
              <div className={classnames(classes.toast, classes.toastSecure, status.secure && classes.toastOpen)}>
                <CheckIcon />
                {`${t('sitelock.toastDone')} ${t('sitelock.toastSecure')}`}
              </div>
              <div className={classnames(classes.toast, classes.toastVulnerable, status.vulnerable && classes.toastOpen)}>
                <CheckIcon />
                {`${t('sitelock.toastDone')} ${issues} ${t('sitelock.toastVulnerable')}`}
              </div>
            </div>
          </div>
          <div className={classes.faq}>
            <FaqDefault
              questions={faq.security.questions}
              link={SUPPORT_SECURITY_URL}
              fullwidth
              padded={false}
              loading={faq.loading}
            />
          </div>
        </div>
        <SitelockShield />
      </div>
    </div>
  ) : <div className={classes.loader}><CircularProgress data-testid="loading-spinner" /></div>;
};
export default withI18n()(withStyles(styles, { withTheme: true })(SitelockDetails));
