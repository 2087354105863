import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './InvoiceFilter.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import { PageTitle } from 'gatorcomponents';
import { isNumber } from '@/utils/Validators/validation';

const InvoiceFilter = ({
  invoices = [],
  setInvoices = null,
  paginationData = null,
  onLoadInvoices = null,
  onChangeChip = null,
  currentInvoiceStatus = 'all',
}) => {
  const { billing: billingLocale } = useLocale();
  const testId = 'invoice-filter';
  const [selectChip, setSelectChip] = useState('all');
  const MAX_INVOCEID_SIZE = 12;
  const defaultFilteredOptions = [
    {
      id: '0',
      label: billingLocale.invoices.filterOptions.all,
      value: 'all',
      checked: currentInvoiceStatus === 'unpaid',
    },
    {
      id: '1',
      label: billingLocale.invoices.filterOptions.financialPending,
      value: 'unpaid',
      checked: false,
    },
    {
      id: '2',
      label: billingLocale.invoices.filterOptions.paid,
      value: 'paid',
      checked: currentInvoiceStatus === 'paid',
    },
    {
      id: '3',
      label: billingLocale.invoices.filterOptions.cancelled,
      value: 'cancelled',
      checked: currentInvoiceStatus === 'cancelled',
    },
  ];

  const [filterText, setFilterText] = useState('');
  const [filterOptions, setFilterOptions] = useState(defaultFilteredOptions);

  useEffect(() => {
    setFilterOptions(defaultFilteredOptions);
  }, [currentInvoiceStatus]);

  useEffect(() => {
    if (filterText === '') {
      onLoadInvoices('', selectChip);
    }
  }, [filterText]);

  const clearFilter = (filteredOptions, setFilteredOptions, setFilterText) => {
    const options = [...filteredOptions]
      .map((option, index) => ({ ...option, checked: index === 0 }));

    setFilteredOptions(options);
    setFilterText('');
  };

  const getCheckedOptionsValues = useCallback(options => options
    .filter(option => option.checked)
    .map(option => option.value), []);

  const FILTER_OPTION_ALL = 'all';
  const financialPendingStatus = [
    'unpaid',
    'unpaidExpired',
    'paymentPending',
    'pendingAnalysis',
    'pending',
    'expired',
    'suspended',
    'overdue',
    'toDue',
    'active'];

  const sortOrderByStatus = [
    'expired',
    'overdue',
    'unpaidExpired',
    'unpaid',
    'paymentPending',
    'pendingAnalysis',
    'paid',
    'refunded',
    'cancelled',
    'others'];

  const orderByStatus = useCallback(
    (
      { statusMessage: statusA },
      { statusMessage: statusB },
    ) => (sortOrderByStatus.indexOf(statusA) - sortOrderByStatus.indexOf(statusB)), [],
  );

  const filterByOptions = useCallback((items) => {
    const optionsValues = getCheckedOptionsValues(filterOptions);
    if (optionsValues.includes('unpaid')) {
      return financialPendingStatus.includes(items.statusMessage);
    }

    return optionsValues.includes(items.statusMessage) || optionsValues.includes(FILTER_OPTION_ALL);
  }, [filterOptions, getCheckedOptionsValues]);

  const filterByNameInProducts = useCallback((item) => {
    if (item && item.id.toString().includes(filterText.toLowerCase())) {
      return true;
    }
    let hasTextInProducts = false;

    item.products && item.products.forEach((product) => {
      if (product.domain && product.domain.toLowerCase().includes(filterText.toLowerCase())) {
        hasTextInProducts = true;
      }
      if (product.product && product.product.toLowerCase().includes(filterText.toLowerCase())) {
        hasTextInProducts = true;
      }
      if (product.plan && product.plan.toLowerCase().includes(filterText.toLowerCase())) {
        hasTextInProducts = true;
      }
    });
    return hasTextInProducts;
  }, [filterText]);

  useEffect(() => {
    if (paginationData[selectChip] && invoices.length <= paginationData[selectChip].total && filterText.length > 6) {
      if ((filterText.length <= MAX_INVOCEID_SIZE && isNumber(filterText)) || !isNumber(filterText)) {
        const timer = setTimeout(() => {
          onLoadInvoices(filterText, selectChip);
          const itemsFiltered = invoices
            .filter(filterByNameInProducts)
            .sort(orderByStatus);
          setInvoices(itemsFiltered);
        }, 500);
        return () => clearTimeout(timer);
      }
    }
    if (paginationData[selectChip] !== null || (paginationData[selectChip] && invoices.length >= paginationData[selectChip].total) || filterText.length === 0) {
      if (invoices.length !== 0) {
        const itemsFiltered = invoices
          .filter(filterByOptions)
          .filter(filterByNameInProducts)
          .sort(orderByStatus);
        setInvoices(itemsFiltered);
      }
    }
  }, [invoices, filterOptions, filterText]);

  const getFilterProps = () => {
    const defaultProps = {
      testId: 'filter-invoices',
      title: billingLocale.invoices.invoiceListTitle,
      filterLabel: billingLocale.filterLabel,
      filterChips: filterOptions,
      filterByPlaceholder: billingLocale.invoices.searchByProductInvoice,
      clearFilterLabel: billingLocale.clearLabel,
      filterByValue: filterText,
      handleClearFilter: () => clearFilter(filterOptions, setFilterOptions, setFilterText),
    };


    const clearOptions = () => {
      const newOptions = [...defaultFilteredOptions].map((option) => {
        const newOption = { ...option };
        newOption.checked = false;
        return newOption;
      });
      return newOptions;
    };


    const handleChangeChip = (chip) => {
      setSelectChip(chip.value);
      onChangeChip && onChangeChip(chip.value);
      const newOptions = clearOptions();

      const chipIndex = newOptions.findIndex(option => option.id === chip.id);

      if (!chip.checked) {
        newOptions[0].checked = true;
      }

      if (chip.checked) {
        newOptions[chipIndex].checked = true;
      }
      setFilterOptions(newOptions);
    };

    const handleChangeFilterBy = (text) => {
      setFilterText(text);
    };

    return {
      ...defaultProps,
      handleChangeFilterBy,
      handleChangeChip,
    };
  };
  return (
    <Styles.Wrapper data-testid={`${testId}-content`}>
      <PageTitle {...getFilterProps()} />
    </Styles.Wrapper>
  );
};


export default InvoiceFilter;
