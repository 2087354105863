import {
  ERROR_FEEDBACK_FORM, ERROR_SAVE_FEEDBACK_FORM, RECEIVE_FEEDBACK_FORM,
  RECEIVE_SAVE_FEEDBACK_FORM, REQUEST_FEEDBACK_FORM, REQUEST_SAVE_FEEDBACK_FORM,
} from '@/redux/actions/actionsTypes';


const initialState = {
  question: '',
  scores: [],
  ready: false,
  error: false,
  loading: false,
};

export default function feedbackForm(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FEEDBACK_FORM:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FEEDBACK_FORM:
      return {
        ...state,
        question: action.feedbackForm.question,
        scores: action.feedbackForm.scores,
        loading: false,
        ready: true,
      };
    case ERROR_FEEDBACK_FORM:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case REQUEST_SAVE_FEEDBACK_FORM:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SAVE_FEEDBACK_FORM:
      return {
        ...state,
        loading: false,
        ready: true,
      };
    case ERROR_SAVE_FEEDBACK_FORM:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
