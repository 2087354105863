import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { FaqActionTypes } from './faq.types';
import faqActions from './faq.actions';
import faqAssembly from './faq.assembly';
import { FAQ_URL } from '@/config/api';

function* getFaqWordpressSetup() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/create-site-wordpress`,
    };

    const response = yield call(api.whmcs.get, request);


    const faq = faqAssembly.receiveFaq({ response, key: 'wordpress' });
    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.wordpress());
  } catch (e) {
    yield put(faqActions.faq.failure.wordpress());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqSitebuilderSetup() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/create-site-websitebuilder`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'sitebuilder' });
    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.siteBuilder());
  } catch (e) {
    yield put(faqActions.faq.failure.siteBuilder());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqSoftaculousSetup() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/create-site-softaculous`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'softaculous' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.softaculous());
  } catch (e) {
    yield put(faqActions.faq.failure.softaculous());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqMainSupportPage() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/main-support`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'mainSupportPage' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqMain());
  } catch (e) {
    yield put(faqActions.faq.failure.faqMain());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqFinancialSupportPage() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/billing-support`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'financialSupportPage' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqFinancial());
  } catch (e) {
    yield put(faqActions.faq.failure.faqFinancial());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqDnsSupportPage() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/dns-support`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'dnsSupportPage' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqDns());
  } catch (e) {
    yield put(faqActions.faq.failure.faqDns());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqMailSupportPage() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/mail-support`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'mailSupportPage' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqMail());
  } catch (e) {
    yield put(faqActions.faq.failure.faqMail());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqSiteSupportPage() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/site-support`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'siteSupportPage' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqSite());
  } catch (e) {
    yield put(faqActions.faq.failure.faqSite());
  }
  yield put(faqActions.faq.load(false));
}

function* getFaqCloudflare() {
  const { token } = yield select(state => state.auth);
  yield put(faqActions.faq.load(true));

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `${FAQ_URL}/cloudflare`,
    };

    const response = yield call(api.whmcs.get, request);

    const faq = faqAssembly.receiveFaq({ response, key: 'cloudflare' });

    yield put(faqActions.faq.set(faq));
    yield put(faqActions.faq.success.faqSite());
  } catch (e) {
    yield put(faqActions.faq.failure.faqSite());
  }
  yield put(faqActions.faq.load(false));
}

function* sagas() {
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_WORDPRESS, getFaqWordpressSetup);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_SITEBUILDER, getFaqSitebuilderSetup);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_SOFTACULOUS, getFaqSoftaculousSetup);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_MAIN, getFaqMainSupportPage);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_FINANCIAL, getFaqFinancialSupportPage);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_DNS, getFaqDnsSupportPage);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_MAIL, getFaqMailSupportPage);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_SITE, getFaqSiteSupportPage);
  yield takeLatest(FaqActionTypes.REQUEST_FAQ_CLOUDFLARE, getFaqCloudflare);
}

export default sagas;
