const styles = theme => ({
  selectFieldTextOn: {
    color: theme.color.regentGray,
  },
  formControl: {
    margin: '6px 8px 5px 0',
    width: '100%',
    maxWidth: '342px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: 12,
      margin: '6px 0 5px 0',
    },
  },
  selectCustom: {
    '& div > div': {
      paddingBottom: 6,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderRadius: '2px',
    },
  },
  inputLabelCustom: {
    transform: 'translate(14px, 10px) scale(1)',
    zIndex: 0,
  },
});

export default styles;
