import React from 'react';
import * as Style from './IconDetailedSecurity.style';

const IconDetailedSecurity = ({
  color = '#4a4a4a',
  background = '#ff9144',
  size = '24',
  testId = 'icon-detailed-security',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g id="Grupo_39181" data-name="Grupo 39181" transform="translate(-1252 -749.388)" stroke={color}>
        <circle id="Elipse_1830" data-name="Elipse 1830" cx="10" cy="10" r="10" transform="translate(1252 753.388)" fill={background} stroke="none" />
        <g id="Retângulo_15243" data-name="Retângulo 15243" transform="translate(1260 756.388)" fill="none" stroke="#4a4a4a" strokeWidth="2">
          <rect width="16" height="13" stroke="none" />
          <rect x="1" y="1" width="14" height="11" fill="none" />
        </g>
        <g id="Retângulo_15244" data-name="Retângulo 15244" transform="translate(1262 749.388)" fill="none" stroke="#4a4a4a" strokeWidth="2">
          <path d="M6,0H6a6,6,0,0,1,6,6V9a0,0,0,0,1,0,0H0A0,0,0,0,1,0,9V6A6,6,0,0,1,6,0Z" stroke="none" />
          <path d="M6,1H6a5,5,0,0,1,5,5V7.444A.556.556,0,0,1,10.444,8H1.556A.556.556,0,0,1,1,7.444V6A5,5,0,0,1,6,1Z" fill="none" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedSecurity;
