import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import {
  Table, TableRow, TableCell, TableBody, TableHead, withStyles,
} from '@material-ui/core';

import invoiceType from '@/types/invoiceType';
import CurrencyFormat from '@/components/CurrencyFormat';
import styles from './styles';


const InvoiceDetails = ({
  classes,
  invoice,
  t,
  variant,
}) => {
  const getProductName = (name) => {
    if (name.includes('(') && variant === 'clean') {
      const regex = /\(\d{2}/;
      return name.split(regex)[0];
    }
    return name;
  };

  return (
    <Table className={classes.tableMain}>
      {variant !== 'clean' && (
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>{t('invoiceDescription')}</TableCell>
            <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>{t('invoiceValue')}</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody className={classes.tableBody}>
        {invoice.items && Object.keys(invoice.items).map(id => (
          <TableRow key={id}>
            <TableCell>
              {getProductName(invoice.items[id].description)}
            </TableCell>
            <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>
              <CurrencyFormat>{invoice.items[id].amount}</CurrencyFormat>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell className={classes.tRowAlign}>
            {t('invoiceSubtotal')}
          </TableCell>
          <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>
            <CurrencyFormat>{invoice.subtotal}</CurrencyFormat>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tRowAlign}>
            {t('invoiceCredit')}
          </TableCell>
          <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>
            <CurrencyFormat>{invoice.credit}</CurrencyFormat>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tRowAlign}>
            {t('invoiceTaxes')}
          </TableCell>
          <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>
            <CurrencyFormat>{invoice.tax}</CurrencyFormat>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tRowAlign}>
            {t('invoicePaidValue')}
          </TableCell>
          <TableCell className={`${classes.tRowBorder} ${classes.tRowAlign}`}>
            <CurrencyFormat>{invoice.amountpaid}</CurrencyFormat>
          </TableCell>
        </TableRow>

        <TableRow className="tableRowBold">
          <TableCell className={classes.tRowAlign}>
            <span className={classes.tableTextBold}>{t('invoiceUnpaidValue')}</span>
          </TableCell>
          <TableCell className={`${classes.tableValueTd} ${classes.tRowBorder} ${classes.tRowAlign}`}>
            <span className={classes.tableTextBold}><CurrencyFormat>{invoice.balance}</CurrencyFormat></span>
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>
  );
};

InvoiceDetails.propTypes = {
  invoice: invoiceType.isRequired,
  variant: PropTypes.string,
  classes: PropTypes.shape({
    tableHeader: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(withI18n()(InvoiceDetails));
