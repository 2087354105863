const styles = theme => ({
  '@keyframes openEffect': {
    from: {
      opacity: 0,
      transform: 'scale(0)',
      transformOrigin: 'top left',
    },
    to: {
      opacity: 1,
      transform: 'scale(1) translateZ(0px)',
    },
  },

  wrapper: {
    display: 'flex',
    position: 'relative',
    userSelect: 'none',

    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '15px',
      width: '100%',
    },
  },

  dropdown: {
    animationDuration: '0.14s',
    animationName: 'openEffect',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    display: 'block',
    maxHeight: '50vh',
    minWidth: '150px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '8px 0 0 0',
    position: 'absolute',
    top: '-6px',
    transition: 'opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    width: '100%',
    zIndex: '5000',
  },

  list: {
    listStyle: 'none',
    margin: '0 0 15px 0',
    paddingLeft: '0',

    '&:last-child': {
      marginBottom: '8px',
    },
  },

  item: {
    padding: '8px 35px 8px 35px',
    transition: 'background-color 0.2s',
    userSelect: 'none',
    wordBreak: 'break-all',

    '&:hover': {
      backgroundColor: '#ebebeb',
      cursor: 'pointer',
    },

    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },

  categorie: {
    color: theme.color.regentGray,
    fontSize: '14px',
    fontWeight: 400,
    padding: '0px 18px 8px 18px',
    textTransform: 'uppercase',
    userSelect: 'none',
    wordBreak: 'break-word',

    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 8px 18px',
    },
  },

  input: {
    border: '1px solid #c3c4c3',
    borderRadius: '2px',
    cursor: 'pointer',
    fontSize: '14px',
    height: '34px',
    minWidth: '340px',
    overflow: 'hidden',
    padding: '0 18px',
    textOverflow: 'ellipsis',
    transition: 'border-color 0.2s',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    willChange: 'border-color',

    '@media only screen and (max-width: 1179px)': {
      minWidth: '295px',
    },

    '@media only screen and (min-width: 1180px)': {
      minWidth: '340px',
    },

    '&:hover': {
      borderColor: theme.color.chambray,
    },

    '&:focus': {
      border: '1px solid #c3c4c3',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      borderColor: theme.color.chambray,
      outline: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      minWidth: '100%',
      width: '100%',
    },
  },

  label: {
    backgroundColor: theme.color.white,
    color: '#747474',
    cursor: 'pointer',
    mozOsxFontSmoothing: 'grayscale',
    padding: '0 6px',
    position: 'absolute',
    transform: 'translate(14px, 8px) scale(1)',
    transition: 'transform 0.2s',
    userSelect: 'none',
    webkitFontSmoothing: 'antialiased',
    willChange: 'transform',
    zIndex: 0,
  },

  focus: {
    transform: 'translate(-7px, -10px) scale(0.75)',
  },

  color: {
    color: theme.color.chambray,
  },

  svg: {
    cursor: 'pointer',
    fill: '#747474',
    position: 'absolute',
    right: 0,
    top: 5,
  },

  backlist: {
    listStyle: 'none',
    margin: '0 0 15px 0',
    padding: 0,
  },

  backitem: {
    backgroundColor: '#dbdbdb',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    fontSize: '16px',
    fontStyle: 'italic',
    padding: '15px 18px',
  },
});

export default styles;
