import React from 'react';
import AlertMessage from '@/components/AlertMessage';
import DomainAlertManager from '@/components/Domain/DomainAlertManager';
import * as Styles from './PageMessage.styles';

const PageMessages = ({
  displayAlertMessage = true,
  displayDomainAlertManager = true,
}) => (
  <Styles.MessageWrapper>
    {displayAlertMessage && <AlertMessage />}
    {displayDomainAlertManager && <DomainAlertManager />}
  </Styles.MessageWrapper>
);

export default PageMessages;
