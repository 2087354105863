import {
  REQUEST_GENERAL_PRODUCTS, RECEIVE_GENERAL_PRODUCTS, UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS,
  FILTER_GENERAL_PRODUCTS, CLEAR_FILTER_GENERAL_PRODUCTS, ABORT_REQUEST_GENERAL_PRODUCTS,
  SELECT_STATUS_PRODUCTS, ALTER_VISIBLE_PRODUCTS,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  visible: 4,
  byId: [],
  bkpProducts: [],
  selectFilters: {
    site: [],
    status: [],
    selectedStatus: [],
  },
  loadedDate: null,
};

export default function generalProducts(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GENERAL_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case FILTER_GENERAL_PRODUCTS:
      return {
        ...state,
        byId: action.filterProduct,
      };
    case RECEIVE_GENERAL_PRODUCTS:
      return {
        ...state,
        loading: false,
        byId: action.initialProducts,
        bkpProducts: action.generalProducts,
        loadedDate: action.loadedDate,
        selectFilters: {
          ...state.selectFilters,
          status: action.filterStatus,
          site: action.filterDomain,
          selectedStatus: action.filterSelectedStatus,
        },
      };
    case SELECT_STATUS_PRODUCTS:
      return {
        ...state,
        selectFilters: {
          ...state.selectFilters,
          selectedStatus: action.arrayStatus,
        },
      };
    case ALTER_VISIBLE_PRODUCTS:
      return {
        ...state,
        visible: action.productsNumber,
      };
    case UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS:
      return {
        ...state,
        byId: action.domainRenewalProducts,
      };
    case CLEAR_FILTER_GENERAL_PRODUCTS:
    case ABORT_REQUEST_GENERAL_PRODUCTS:
      return {
        ...state,
        byId: state.byId,
      };
    default:
      return state;
  }
}
