import React, { useState } from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import * as actions from '@/redux/actions/emails';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { hireTitanFreeWithTitanTrial } from '@/utils/ThirdParties/tagManager';
import { RECEIVE_GET_ACTIVE_EMAIL_CPANEL } from '@/redux/actions/actionsTypes';
import styles from './styles';
import { emailsActions } from '@/redux/modules/emails';
import { useHistory } from 'react-router';
import { EmailsActionTypes } from '@/redux/modules/emails/email.types';
import { ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { IconLoading } from 'gatorcomponents';


const ModalConfirmFreeTitan = ({
  onClose, open, classes, t, domainObject, idProEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );
  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;
  const domainConfigured = domainObject && alphaFlowToSellTitanTrialByModalInHirePlan && domainObject.domain_configured === false && domainObject.configured === true;
  const history = useHistory();

  const handlerReactivePlanFree = async () => {
    if (shouldRenderNewTitanCards) {
      hireTitanFreeWithTitanTrial();
    }
    if (domainConfigured) {
      const updateDomainSetupData = {
        enabledUpdateDomainSetup: true,
        domain: domainObject.domain,
        customerEmail: domainObject.customer_email,
        type: 'proeEmail',
      };

      setLoading(true);
      dispatch(emailsActions.emails.updateDomainSetup.request(updateDomainSetupData)).then((responseData) => {
        if (responseData.type === EmailsActionTypes.UPDATE_DOMAIN_SETUP_REQUEST) {
          history.push(`${t('routes.emailsList')}?frActivate=true&domain=${domainObject.domain}`);
        } else {
          dispatch(enqueueSnackbar(t('snackbar.error.is_not_possible_active_email_cpanel'), notifierErrorLong));
        }
        setLoading(false);
        onClose();
      });
    } else {
      setLoading(true);
      dispatch(actions.requestReceiveActiveEmailCPanel(idProEmail, 'titan')).then((responseData) => {
        if (responseData.type === RECEIVE_GET_ACTIVE_EMAIL_CPANEL) {
          history.push(`${t('routes.emailsList')}?frActivate=true&domain=${domainObject.domain}`);
        } else {
          dispatch(enqueueSnackbar(t('snackbar.error.is_not_possible_active_email_cpanel'), notifierErrorLong));
        }
        setLoading(false);
        onClose();
      });
    }
  };
  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      open={open}

    >
      <div className={classes.activeDialog}>
        <div id="dialog-title" style={{ textAlign: 'right' }}>
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography className={classes.titleDialog}>{t('proEmail.hirePlan.continueFreePlanTitle')}</Typography>
          <Typography className={classes.descriptionDialog}>
            <Trans i18nKey="proEmail.hirePlan.continueFreePlanDescription" values={{ hostingName: t('proEmail.hirePlan.hostingPlan') }}>
              <>0</>
              <b>1</b>
              <>2</>
            </Trans>
          </Typography>
          <Typography className={classes.descriptionDialog}>{t('proEmail.hirePlan.continueFreePlanDescriptionAdvice')}</Typography>
          <Button onClick={loading ? () => {} : handlerReactivePlanFree} className={classes.btActive} data-testid="linkButton" disabled={loading}>
            {loading ? <IconLoading /> : t('proEmail.hirePlan.continueFreePlanAction')}
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalConfirmFreeTitan));
