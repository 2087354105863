import { COUNTRY } from '@/config';

const SITEBUILDER_IDS_ES = [5, 6, 7, 50, 51, 52, 53, 54, 174, 213];

const SITEBUILDER_TRADICIONAL_IDS_BY_BRAND = {
  br: [5, 6, 7, 50, 51, 52, 53, 54, 335, 341],
  mx: SITEBUILDER_IDS_ES,
  cl: SITEBUILDER_IDS_ES,
  co: SITEBUILDER_IDS_ES,
};

export const SITEBUILDER_TRADICIONAL_IDS = SITEBUILDER_TRADICIONAL_IDS_BY_BRAND[COUNTRY];
