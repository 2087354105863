import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as Styles from './ManageDomainHandler.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Link,
  Skeleton,
  Tag,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import {
  format, parseISO,
} from 'date-fns';
import { STATUS } from './ManageDomainHandler.types';
import { ModalEppKey } from '@/components/Domain/ModalEppKey';
import { domainsActions } from '@/redux/modules';
import { domainsAnalytics } from '@/analytics';
import { ManageOptionCard } from './components/ManageOptionCard';
import { DnsCard } from './components/DnsCard';
import { EmailCard } from './components/EmailCard';
import { useResize } from '@/hooks/useResize';
import { useBillingStatus } from '@/hooks/domains/useBillingStatus';


const { dnsWizard: dnsWizardAnalytics, eppKey: eppKeyAnalytics } = domainsAnalytics;
export const ManageDomainHandler = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { detail: detailState, eppKey: eppkeyState } = useSelector(state => state.newDomains);
  const diagnosticState = useSelector(state => state.preChat);
  const [requestedDomainDetails, setRequestedDomainDetails] = useState(false);
  const { domains: domainsLocale, routes: routesLocale } = useLocale();
  const { manageDomainHandler: manageDomainHandlerLocale } = domainsLocale;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [propagationStatus, setPropagationStatus] = useState(null);
  const [hasProtect, setHasProtect] = useState(null);
  const [hasContact, setHasContact] = useState(null);
  const [hasRegister, setHasRegister] = useState(null);
  const [renderEppKey, setRenderEppKey] = useState(false);
  const [domainStatus, setDomainStatus] = useState(null);
  const windowRect = useResize();
  const { width } = windowRect;
  const isMobile = width < 600;

  const { getIsNextDueDate, getIsOverDue } = useBillingStatus();

  const checkLoaders = useCallback(() => {
    setLoading(!!diagnosticState.loading || !!detailState.loading);
  }, [diagnosticState.loading, detailState.loading]);

  const checkPropagationStatus = useCallback(() => {
    if (diagnosticState && diagnosticState.domainsPropagation.length) {
      setPropagationStatus(diagnosticState.domainsPropagation.find(item => item.domain === detailState.domain));
    }
  }, [detailState.domain, diagnosticState]);

  const checkActions = useCallback(() => {
    if (detailState && detailState.actions.length) {
      setHasProtect(detailState.actions.find(item => item.name === 'protectId'));
      setHasContact(detailState.actions.find(item => item.name === 'editContact'));
      setHasRegister(detailState.actions.find(item => item.name === 'registrationLock'));

      const domainStatusIsActive = detailState.status === 'active';
      const isDotBR = detailState.domain && detailState.domain.includes('.com.br');
      const isEnom = detailState.registrar && detailState.registrar.toLowerCase() === 'enom';
      const renderEppKey = !isDotBR && !isEnom && domainStatusIsActive;
      setRenderEppKey(renderEppKey);
    }
  }, [detailState]);

  const mountFinancialStatus = useCallback(() => {
    if (detailState) {
      if (detailState.status === 'active') {
        const isNextDueDate = getIsNextDueDate(detailState);
        const isOverDue = getIsOverDue(detailState);

        if (isNextDueDate && !isOverDue) {
          setDomainStatus('nextDueDate');
        }

        if (isNextDueDate && isOverDue) {
          setDomainStatus('overDueDate');
        }

        if (!isNextDueDate && !isOverDue) {
          setDomainStatus(detailState.status);
        }
      }

      if (detailState.status !== 'active') {
        setDomainStatus(detailState.status);
      }
    }
  }, [detailState, getIsNextDueDate, getIsOverDue]);

  const requestDomainDetailById = useCallback(() => {
    if (id && !requestedDomainDetails) {
      dispatch(domainsActions.domains.requestDetail(id));
      setRequestedDomainDetails(true);
    }
  }, [dispatch, id, requestedDomainDetails]);

  const isPointedToReserved = detailState.pointedToReserved;
  const isInPropagation = !detailState.pointedToReserved
    && propagationStatus
    && propagationStatus.status === STATUS.inProgress;

  useEffect(() => {
    checkLoaders();
  }, [checkLoaders]);

  useEffect(() => {
    checkPropagationStatus();
  }, [checkPropagationStatus]);

  useEffect(() => {
    checkActions();
  }, [checkActions]);

  useEffect(() => {
    requestDomainDetailById();
  }, [requestDomainDetailById]);

  useEffect(() => {
    mountFinancialStatus();
  }, [mountFinancialStatus]);

  const cardOptionSkeleton = () => (
    <>
      <Skeleton variant="textLine" />
      <Skeleton variant="textLine" />
      <Skeleton variant="button" />
    </>
  );

  const dataItem = ({ title, value, manage }) => {
    let href = null;

    if (loading) {
      return (
        <Styles.DataItem>
          <Skeleton variant="rectangle" width="120px" height="40px" />
        </Styles.DataItem>
      );
    }

    if (manage) {
      href = `${routesLocale.subscriptions}${routesLocale.manageSubscription}/${detailState.id}/domain`;

      return (
        <Styles.DataItem>
          <Link
            text={manageDomainHandlerLocale.domainData.manageSubscription}
            historyRedirect
            history={history}
            href={href}
          />
        </Styles.DataItem>
      );
    }

    return (
      <Styles.DataItem>
        <>
          <Styles.DataTitle>
            {title}
          </Styles.DataTitle>
          <Styles.DataValue>
            {value}
          </Styles.DataValue>
        </>
      </Styles.DataItem>
    );
  };

  const getTagVariant = (status) => {
    if (status === 'nextDueDate') {
      return 'attention';
    }

    if (status === 'overDueDate') {
      return 'problem';
    }

    return 'active';
  };

  const redirectToInvoices = () => {
    history.push(`${routesLocale.invoices}${routesLocale.unpaid}`);
  };

  const getDnsCardProps = useCallback(() => {
    const defaultProps = {
      manageDomainHandlerLocale,
      isInPropagation,
      propagationStatus,
      detailState,
      isPointedToReserved,
    };


    const redirectToDnsWizard = () => {
      history.push(`${routesLocale.domains}${routesLocale.dnsWizard}/${detailState.id}`);
    };

    return {
      ...defaultProps,
      redirectToDnsWizard,
    };
  }, [
    detailState,
    history,
    isInPropagation,
    isPointedToReserved,
    manageDomainHandlerLocale,
    routesLocale,
    propagationStatus,
  ]);

  const getEmailCardProps = useCallback(() => {
    const defaultProps = {
      manageDomainHandlerLocale,
    };


    const redirectToEmail = () => {
      history.push(`${routesLocale.emailsList}`);
    };

    return {
      ...defaultProps,
      redirectToEmail,
    };
  }, [
    history,
    manageDomainHandlerLocale,
    routesLocale,
  ]);

  const getManageOptionCardProps = useCallback(() => {
    const defaultProps = {
      loading,
      hasProtect,
      hasContact,
      hasRegister,
      renderEppKey,
      manageDomainHandlerLocale,
      dnsWizardAnalytics,
    };

    const handleRedirectAction = ({
      href, eppKey, hasProtect, hasRegister,
    }) => {
      if (eppKey) {
        eppKeyAnalytics.eppKeyButtonClick();
        dispatch(domainsActions.modalEppKey.open(detailState.domain, detailState.id));
        return null;
      }

      if (hasProtect) {
        window.open(`${href}${detailState.id}#tabAddons`, '_blank');
        return null;
      }

      if (hasRegister) {
        window.open(`${href}${detailState.id}#tabReglock`, '_blank');
        return null;
      }

      if (href && href.startsWith('/')) {
        history.push(href);
      } else {
        window.open(`${href}${detailState.id}`, '_blank');
      }
    };

    return {
      ...defaultProps,
      handleRedirectAction,
    };
  }, [
    hasProtect,
    hasContact,
    hasRegister,
    loading,
    manageDomainHandlerLocale,
    renderEppKey,
    detailState,
    dispatch,
    history,
  ]);

  return (
    <Styles.Container>
      {eppkeyState.modalIsOpen && <ModalEppKey />}
      <Styles.Header>
        {loading
          ? (
            <Styles.SkeletonRow>
              <Skeleton variant="headline" width="392px" />
              <Skeleton variant="rectangle" width="80px" height="24px" />
            </Styles.SkeletonRow>
          )
          : (
            <Styles.DomainName>
              {detailState.domain}
              {(domainStatus && [STATUS.active, STATUS.nextDueDate, STATUS.overDueDate].includes(domainStatus)) && (
                <Tag
                  variant={getTagVariant(domainStatus)}
                  label={manageDomainHandlerLocale.tags[domainStatus]}
                  testId={`${domainStatus}-tag`}
                />
              )}
            </Styles.DomainName>
          )
        }
      </Styles.Header>

      <Styles.DataContainer $loading={loading}>
        {dataItem({
          title: manageDomainHandlerLocale.domainData.registerDate,
          value: detailState.registrationDate && format(parseISO(detailState.registrationDate), 'dd/MM/yyyy'),
        })}

        {dataItem({
          title: manageDomainHandlerLocale.domainData.nextDueDate,
          value: detailState.nextDueDate && format(parseISO(detailState.nextDueDate), 'dd/MM/yyyy'),
        })}

        {dataItem({
          title: manageDomainHandlerLocale.domainData.cycle,
          value: detailState.billingCycle,
        })}

        {dataItem({
          manage: true,
        })}
      </Styles.DataContainer>

      {(domainStatus && [STATUS.nextDueDate, STATUS.overDueDate].includes(domainStatus)) && (
        <Styles.AlertContainer status={domainStatus}>
          <Alert
            variant={domainStatus === STATUS.overDueDate ? 'error' : 'warning'}
            customIcon="IconDanger"
            withoutBackground
            centerIcon={!isMobile}
            description={
              manageDomainHandlerLocale.alert[domainStatus]({
                nextDueDate: format(parseISO(detailState.nextDueDate), 'dd/MM/yyyy'),
                expiryDate: format(parseISO(detailState.expiryDate), 'dd/MM/yyyy'),
              })
            }
            testId={`${domainStatus}-alert`}
          />
          <Button
            label={manageDomainHandlerLocale.alert.goToPayment}
            onClick={redirectToInvoices}
            testId="alertCta"
          />
        </Styles.AlertContainer>
      )}

      <Styles.ManageMentContainer>
        <Styles.ContainerColumn>
          <Styles.Card>
            {loading
              ? cardOptionSkeleton()
              : (
                <DnsCard {...getDnsCardProps()} />
              )}
          </Styles.Card>

          <Styles.Card>
            {loading
              ? cardOptionSkeleton()
              : (
                <EmailCard {...getEmailCardProps()} />
              )
            }
          </Styles.Card>
        </Styles.ContainerColumn>

        <Styles.ContainerColumn>
          <Styles.Card>
            <Styles.CardTitle>
              {manageDomainHandlerLocale.cardOptions.manage.title}
            </Styles.CardTitle>

            <ManageOptionCard {...getManageOptionCardProps()} />
          </Styles.Card>
        </Styles.ContainerColumn>
      </Styles.ManageMentContainer>
    </Styles.Container>
  );
};
