import { useMountCardStatusLocale } from './useMountCardStatus.locale';
import { STATUS_TYPE } from '@/enums/sites/status.enum';

export const rules = {
  [STATUS_TYPE.SUSPENDED]: (theme, siteItem, allSiteStatus) => {
    let suspendedMessage = '';
    if (allSiteStatus.planSuspended && allSiteStatus.domainSuspended) {
      const closerToCancel = siteItem.days_to_cancel_plan >= siteItem.days_to_cancel_domain ? siteItem.days_to_cancel_domain : siteItem.days_to_cancel_plan;

      suspendedMessage = `${closerToCancel} ${useMountCardStatusLocale.status.suspended.label(closerToCancel === 1 ? useMountCardStatusLocale.status.suspended.day : useMountCardStatusLocale.status.suspended.days)}`;
    }

    if (allSiteStatus.planSuspended && !allSiteStatus.domainSuspended) {
      suspendedMessage = `${siteItem.days_to_cancel_plan} ${useMountCardStatusLocale.status.suspended.label(siteItem.days_to_cancel_plan === 1 ? useMountCardStatusLocale.status.suspended.day : useMountCardStatusLocale.status.suspended.days)}`;
    }

    if (allSiteStatus.domainSuspended && !allSiteStatus.planSuspended) {
      suspendedMessage = `${siteItem.days_to_cancel_domain} ${useMountCardStatusLocale.status.suspended.label(siteItem.days_to_cancel_domain === 1 ? useMountCardStatusLocale.status.suspended.day : useMountCardStatusLocale.status.suspended.days)}`;
    }
    return {
      label: suspendedMessage,
      icon: theme.color.valencia,
      tooltip: useMountCardStatusLocale.status.suspended.tooltipSite,
      payButton: useMountCardStatusLocale.status.suspended.buttonLabel,
      sublabel: true,
      subLabelTooltip: useMountCardStatusLocale.status.suspended.subLabelTooltip,
      subLabelContent: useMountCardStatusLocale.status.suspended.subLabel,
    };
  },
  [STATUS_TYPE.OVERDUE]: (theme, siteItem, allSiteStatus) => {
    let overDueMessage = '';
    if (allSiteStatus.planIsOverdue && allSiteStatus.domainIsOverdue) {
      const closerToDue = siteItem.days_to_suspend_plan >= siteItem.days_to_suspend_domain ? siteItem.days_to_suspend_domain : siteItem.days_to_suspend_plan;

      overDueMessage = `${closerToDue} ${useMountCardStatusLocale.status.nearDue.label(closerToDue === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }

    if (allSiteStatus.planIsOverdue && !allSiteStatus.domainIsOverdue) {
      overDueMessage = `${siteItem.days_to_suspend_plan} ${useMountCardStatusLocale.status.nearDue.label(siteItem.days_to_suspend_plan === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }

    if (allSiteStatus.domainIsOverdue && !allSiteStatus.planIsOverdue) {
      overDueMessage = `${siteItem.days_to_suspend_domain} ${useMountCardStatusLocale.status.nearDue.label(siteItem.days_to_suspend_domain === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }
    return {
      label: overDueMessage,
      icon: theme.color.valencia,
      tooltip: useMountCardStatusLocale.status.overdue.tooltipSite,
      payButton: useMountCardStatusLocale.status.overdue.buttonLabel,
    };
  },
  [STATUS_TYPE.TODUE]: (theme, siteItem, allSiteStatus) => {
    let toDueMessage = '';
    if (allSiteStatus.planIsToDue && allSiteStatus.domainIsToDue) {
      const closerToDue = siteItem.days_to_suspend_plan >= siteItem.days_to_suspend_domain ? siteItem.days_to_suspend_domain : siteItem.days_to_suspend_plan;

      toDueMessage = `${closerToDue} ${useMountCardStatusLocale.status.nearDue.label(closerToDue === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }

    if (allSiteStatus.planIsToDue && !allSiteStatus.domainIsToDue) {
      toDueMessage = `${siteItem.days_to_suspend_plan} ${useMountCardStatusLocale.status.nearDue.label(siteItem.days_to_suspend_plan === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }

    if (allSiteStatus.domainIsToDue && !allSiteStatus.planIsToDue) {
      toDueMessage = `${siteItem.days_to_suspend_domain} ${useMountCardStatusLocale.status.nearDue.label(siteItem.days_to_suspend_domain === 1 ? useMountCardStatusLocale.status.nearDue.day : useMountCardStatusLocale.status.nearDue.days)}`;
    }
    return {
      label: toDueMessage,
      icon: theme.palette.yellow.main,
      tooltip: useMountCardStatusLocale.status.nearDue.tooltipSite,
      payButton: useMountCardStatusLocale.status.nearDue.buttonLabel,
    };
  },
  [STATUS_TYPE.ANALYSIS]: theme => ({
    label: useMountCardStatusLocale.status.analysis.label,
    icon: theme.color.coral,
    tooltip: useMountCardStatusLocale.status.analysis.tooltipSite,
    payButton: '',
  }),
  [STATUS_TYPE.PENDING]: theme => ({
    label: useMountCardStatusLocale.status.pending.label,
    icon: theme.color.coral,
    tooltip: useMountCardStatusLocale.status.pending.tooltipSite,
    payButton: useMountCardStatusLocale.status.pending.buttonLabel,
  }),
  [STATUS_TYPE.ACTIVE]: () => ({
    label: '',
    icon: '',
    tooltip: '',
    payButton: '',
  }),
};
