import React from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './InvoiceModal.styles';
import { Button } from '@/pages/common/v1';
import { IconInfo } from '@/icons';

const InvoiceModal = ({
  title = 'Discount already applied',
  descriptions = [
    'The discount has already been applied to your invoice. Click the button below to access it and then be able to make the payment.',
    'If you have already paid the invoice, it may take up to 72 hours to clear.',
  ],
  btnText = 'See invoice',
  btnAction,
  modalClose,
}) => {
  const invoiceClickHandler = () => {
    btnAction && btnAction();
  };
  const invoiceModalCloseHandler = () => {
    modalClose && modalClose();
  };
  return (
    <Modal onClose={invoiceModalCloseHandler} testId="invoice-modal-component">
      <Styles.Title>
        {title}
      </Styles.Title>
      <Styles.ContentWrapper>
        <Styles.Icon>
          <IconInfo color="inherit" size="100%" />
        </Styles.Icon>
        <Styles.Content>
          {descriptions.map(description => (
            <Styles.Description key={description}>
              {description}
            </Styles.Description>
          ))}
        </Styles.Content>
      </Styles.ContentWrapper>
      <Styles.Action>
        <Button testId="view-invoice-click" label={btnText} onClick={invoiceClickHandler} />
      </Styles.Action>

    </Modal>
  );
};

export default InvoiceModal;
