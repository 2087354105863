const styles = theme => ({
  content: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    border: 'none',
    padding: '27px 24px',
    background: theme.color.white,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  planContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
  },
  planName: {
    marginBottom: 3,
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'left',
    letterSpacing: 0.01,
    color: theme.color.tundora,
  },
  planStatus: {
    display: 'flex',
  },
  planDomain: {
    display: 'inline-block',
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.01,
    color: theme.color.regentGray,
  },
  actionsContent: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
  },
  actionsMobile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonsList: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  expandButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  expandText: {
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
    display: 'inline-block',
    marginRight: 2,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.01,
    color: theme.color.indigo,
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  warningWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '70%',
  },
  expandIcon: {
    fill: theme.color.indigo,
  },
  expandMore: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandLess: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  collapse: {
    [theme.breakpoints.down('sm')]: {
      padding: '7px 0',

    },
    display: 'flex',
    flexWrap: 'wrap',
    padding: '19px 18px',
    background: theme.color.geyser,
  },
  siteWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 12px)',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '3px 5px',
    },
    width: 'calc(50% - 12px)',
    margin: '5px 6px',
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
  },
});

export default styles;
