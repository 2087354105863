import { IconCheck, IconClose } from '@/icons';
import React from 'react';
import * as Styles from './FormStepper.styles';

const FormStepper = ({
  testId = 'form-stepper',
  items = [
    {
      id: 0,
      status: 'finished',
    },
    {
      id: 1,
      status: 'finished',
    },
    {
      id: 2,
      status: 'error',
    },
    {
      id: 3,
      status: 'pending',
    },
  ],
}) => {
  const mountSteps = () => items.map((item, idx) => {
    const isLastItem = idx === (items.length - 1);
    return (
      <Styles.Step key={item.id}>
        {idx > 0 && (
          <Styles.StepDash status={item.status} />
        )}

        <Styles.StepDot status={item.status} data-testid={`${testId}-${item.id}-StepDot`}>
          <Styles.InnerDot status={item.status}>
            {item.status === 'finished' && (
              <IconCheck size={9} color="white" />
            )}

            {item.status === 'error' && (
              <IconClose size={9} color="white" />
            )}
          </Styles.InnerDot>
        </Styles.StepDot>

        {!isLastItem && (
          <Styles.StepDash status={item.status} />
        )}
      </Styles.Step>
    );
  });

  return (
    <Styles.StepperWrapper data-testid={testId}>
      {mountSteps()}
    </Styles.StepperWrapper>
  );
};

export default FormStepper;
