import React from 'react';
import {
  withStyles, Dialog, Slide, IconButton,
} from '@material-ui/core';
import { Close, Warning } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CardWarning from '@/components/Cards/CardWarning';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';

const Transition = props => <Slide direction="up" {...props} />;

const ModalRemoveSite = ({
  open,
  onClose,
  classes,
  t,
  onRemove,
  productName,
}) => {
  const loadingRemoveSite = useSelector(state => state.siteBuilder.loadingRemoveSite);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      classes={{
        paper: classes.modal,
        container: classes.container,
      }}
    >
      <div className={classes.content}>
        <IconButton
          onClick={!loadingRemoveSite ? onClose : null}
          aria-label="Close"
          className={classes.btnClose}
        >
          <Close />
        </IconButton>

        <h3 className={classes.title}>{t('sitesWizard.modalRemoveSite.title')}</h3>
        <p className={classes.message}>
          <Trans i18nKey="sitesWizard.modalRemoveSite.message" values={{ productName }}>
            0
            <strong>1</strong>
          </Trans>
        </p>

        <CardWarning
          className={classes.warning}
          status="Pending"
          icon={Warning}
          iconSize="small"
          message={t('sitesWizard.modalRemoveSite.warning')}
        />

        <div className={classes.actions}>
          <PrimaryButton
            onClick={onRemove}
            onLoading={loadingRemoveSite}
            className={classes.btn}
          >
            {t('sitesWizard.modalRemoveSite.buttonConfirmation')}
          </PrimaryButton>
        </div>
      </div>
    </Dialog>
  );
};

ModalRemoveSite.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  productName: PropTypes.string,
};

ModalRemoveSite.defaultProps = {
  onClose: () => {},
  onRemove: () => {},
  productName: '',
};

export default withI18n()(withStyles(styles)(ModalRemoveSite));
