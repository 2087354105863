import { useMemo } from 'react';
import {
  contactform,
  elementor,
  jivochat,
  sitekit,
  yoast,
} from '@/media/siteBuilder/plugins';
import { useWizardWordpressPluginsLocale } from './useWizardWordpressPlugins.locale';

const useWizardWordpressPlugins = () => {
  const defaultPluginsList = useMemo(() => [
    {
      title: 'Yoast SEO',
      description: useWizardWordpressPluginsLocale.yoast,
      image: yoast,
      value: 'Yoast_admin',
      gaValue: 'Yoast SEO',
      id: 1,
    },
    {
      title: 'Elementor Website Builder',
      description: useWizardWordpressPluginsLocale.elementor,
      image: elementor,
      value: 'Elementor_admin',
      gaValue: 'Elementor',
      id: 2,
    },
    {
      title: 'Site Kit by Google',
      description: useWizardWordpressPluginsLocale.googleKit,
      image: sitekit,
      value: 'Google_admin',
      gaValue: 'Site Kit by Google',
      id: 3,
    },
    {
      title: 'Contact Form 7',
      description: useWizardWordpressPluginsLocale.contactForm,
      image: contactform,
      value: 'Contact_admin',
      gaValue: 'Contact Form 7',
      id: 4,
    },
    {
      title: 'JivoChat',
      description: useWizardWordpressPluginsLocale.jivoChat,
      image: jivochat,
      value: 'JivoChat_admin',
      gaValue: 'Jivo Chat',
      id: 5,
    },
  ], []);

  const getSelectedPlugins = (plugins) => {
    const { clickedPlugin, pluginsSelected } = plugins;
    const pluginArray = [...pluginsSelected];
    const activePluginIndex = pluginsSelected.findIndex(plugin => plugin === clickedPlugin);
    const isActive = activePluginIndex > -1;

    if (isActive) {
      pluginArray.splice(activePluginIndex, 1);
    } else if (!isActive) {
      pluginArray.push(clickedPlugin);
    }

    return pluginArray;
  };

  const wordpressPluginsData = {
    defaultPluginsList,
    getSelectedPlugins,
  };

  return wordpressPluginsData;
};

export default useWizardWordpressPlugins;
