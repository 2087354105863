import React, { useCallback } from 'react';
import * as Styles from './ThreatsDetector.styles';
import {
  IconCheck, IconClock, IconDanger, IconInfo, Skeleton,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { UpgradeBanner } from '../UpgradeBanner';

const ThreatsDetector = ({
  isFree, productName, scanDetails, handleSignSitelock,
}) => {
  const { security: securityLocale } = useLocale();
  const threatsLocale = securityLocale.detailsPage.tabs.threats_detector;

  const noVulnerabilities = !scanDetails.vulnerabilities || scanDetails.vulnerabilities === 'not_found';
  const noBlocklists = !scanDetails.blocklists || scanDetails.blocklists === 'not_found';

  const noErrors = !scanDetails.malwares && noVulnerabilities && noBlocklists;
  const neverScanned = !scanDetails.last_check;

  const malwaresAlertProps = useCallback(() => ({
    variant: scanDetails.malwares ? 'problem' : 'success',
    label: scanDetails.malwares ? threatsLocale.malwaresFounded(scanDetails.malwares) : threatsLocale.noMalwaresFounded,
  }), [scanDetails.malwares, threatsLocale]);

  const vulnerabilitiesAlertProps = useCallback(() => ({
    variant: noVulnerabilities ? 'success' : 'problem',
    label: threatsLocale.vulnerabilities[scanDetails.vulnerabilities] || threatsLocale.vulnerabilities.not_found,
  }), [scanDetails.vulnerabilities, threatsLocale, noVulnerabilities]);

  const blocklistsAlertProps = useCallback(() => ({
    variant: noBlocklists ? 'success' : 'problem',
    label: threatsLocale.blocklists[scanDetails.blocklists] || threatsLocale.blocklists.not_found,
  }), [scanDetails.blocklists, threatsLocale, noBlocklists]);

  const mountScanInfo = ({ label, value }) => (
    <Styles.VerticalContainer>
      <Styles.CheckResultTitle>{label}</Styles.CheckResultTitle>
      <Styles.CheckResultContent>{value}</Styles.CheckResultContent>
    </Styles.VerticalContainer>
  );

  const mountScanResult = ({ variant = 'success', label }) => (
    <Styles.ScanDetailItem smText={variant === 'info'}>
      <div>
        {variant === 'success' && <IconCheck color="activePure" />}
        {variant === 'problem' && <IconDanger color="problemPure" />}
        {variant === 'info' && <IconInfo size="md" color="primaryMedium" />}
        {variant === 'pending' && <IconClock size="md" color="pendingPure" />}
      </div>
      <Styles.Text>
        {label}
      </Styles.Text>
    </Styles.ScanDetailItem>
  );

  const upgradeBannerProps = () => ({
    bannerLocale: threatsLocale.signSitelockCard,
    handleSignSitelock,
  });

  if (scanDetails.loading) {
    return (
      <Styles.Wrapper noGap data-testid="skeletonWrapper">
        <Styles.SekeletonRow>
          <Skeleton variant="rectangle" height="48px" width="495px" />
        </Styles.SekeletonRow>

        <Styles.SekeletonRow margin>
          <Skeleton variant="rectangle" height="40px" width="119px" />
          <Skeleton variant="rectangle" height="40px" width="119px" />
          <Skeleton variant="rectangle" height="40px" width="119px" />
          <Skeleton variant="rectangle" height="40px" width="119px" />
        </Styles.SekeletonRow>

        <Styles.SekeletonRow margin>
          <Skeleton variant="rectangle" height="156px" />
        </Styles.SekeletonRow>

        <Styles.SekeletonRow margin column>
          <Skeleton variant="headline" />
          <Skeleton variant="textLine" />
        </Styles.SekeletonRow>

        <Styles.SekeletonRow margin>
          <Skeleton variant="rectangle" height="162px" />
        </Styles.SekeletonRow>
      </Styles.Wrapper>
    );
  }

  if (neverScanned) {
    return (
      <>
        <Styles.TitleContainer>
          <IconClock size="xl" color="pendingPure" />
          <Styles.Title>{threatsLocale.neverScanned}</Styles.Title>
        </Styles.TitleContainer>

        <Styles.Card variant="info" withMarginTop>
          <Styles.ScanDetailsContainer>
            {mountScanResult({ label: threatsLocale.willShowScan, variant: 'pending' })}
            {isFree
              ? mountScanResult({ variant: 'info', label: threatsLocale.freePlanNote(handleSignSitelock) })
              : mountScanResult({ variant: 'info', label: threatsLocale.paidPlanNote(productName) })
            }
          </Styles.ScanDetailsContainer>
        </Styles.Card>
        {isFree && <UpgradeBanner {...upgradeBannerProps()} />}
      </>
    );
  }

  return (
    <Styles.Wrapper>
      <Styles.TitleContainer>
        {noErrors ? (
          <>
            <IconCheck size="xl" color="activePure" />
            <Styles.Title>{threatsLocale.noThreatsFounded}</Styles.Title>
          </>
        ) : (
          <>
            <IconDanger size="xl" color="problemPure" />
            <Styles.Title>{threatsLocale.threatsFounded(scanDetails.malwares)}</Styles.Title>
          </>
        )}
      </Styles.TitleContainer>

      <Styles.CheckResultsContainer>
        {mountScanInfo({ label: threatsLocale.lastCheck, value: scanDetails.last_check || '' })}
        {mountScanInfo({ label: threatsLocale.nextCheck, value: scanDetails.next_check || '' })}
        {mountScanInfo({ label: threatsLocale.verifiedPages, value: scanDetails.verified_pages })}
        {mountScanInfo({ label: threatsLocale.verifiedFiles, value: scanDetails.verified_files })}
      </Styles.CheckResultsContainer>
      <Styles.Card variant={noErrors ? 'info' : 'problem'}>
        <Styles.ScanDetailsContainer>
          {mountScanResult({ ...malwaresAlertProps() })}
          {mountScanResult({ ...vulnerabilitiesAlertProps() })}
          {scanDetails.blocklists && mountScanResult({ ...blocklistsAlertProps() })}
          {isFree
            ? mountScanResult({ variant: 'info', label: threatsLocale.freePlanNote(handleSignSitelock) })
            : mountScanResult({ variant: 'info', label: threatsLocale.paidPlanNote(productName) })
              }
        </Styles.ScanDetailsContainer>
      </Styles.Card>

      {isFree && <UpgradeBanner {...upgradeBannerProps()} />}
    </Styles.Wrapper>
  );
};

export default ThreatsDetector;
