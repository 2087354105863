import React, { useContext } from 'react';
import { SlideToggle } from '@/pages/common/v1/SlideToggle';
import * as Styles from './WizardWordpressConfigAsaas.styles';
import { IconDetailedPay } from '@/icons';
import { ThemeContext } from 'styled-components';
import { AsaasFirstRow } from '@/pages/asaas/AsaasHandler/components/AsaasFirstRow';
import { Button } from '@/pages/common/v1';

const WizardWordpressConfigAsaas = ({
  wordPressAsaasBlock,
  open,
  asaasToggleTitle,
  asaasToggleDescription,
  wodpressSetupValues,
  wordPressSetupReadyToContinue,
  pluginsSelected,
  onContinueSetup,
  wordPressPluginsBlock,
  wordPressInfoBlock,
  asaasFormData,
  accountCreated,
  asaasOnChange,
  asaasOnContinue,
  loadingCep,
  asaasEnabledFormOpenAccountContinue,
  asaasEnabledMainDataContinue,
  asaasOnGoback,
  asaasOnSubmit,
  asaasResetForm,
  asaasCnpjTypes,
  asaasCallformStepTwoMyCEP,
  asaasTitle,
  asaasDescription,
  asaasReachedThridStep,
  createAsaasContinueLabel,
  dontCreateAsaasContinueLabel,
  themeSelected,
  wordPressThemesBlock,
  activeSetupStep,
  setupSteps,
  handleLoadingWordpress,
  descriptionImageAlt,
  imageItems,
  isWondersuiteEnabled,
}) => {
  const theme = useContext(ThemeContext);
  const continueLabel = asaasReachedThridStep ? createAsaasContinueLabel : dontCreateAsaasContinueLabel;
  const continueVariant = asaasReachedThridStep ? 'primary' : 'text';

  const handleOnToggle = () => {
    const hasError = wodpressSetupValues.name.error || wodpressSetupValues.email.error || wodpressSetupValues.user.error;
    if (isWondersuiteEnabled) {
      if (hasError) {
        onContinueSetup(0);
        return window.scrollTo({
          top: wordPressInfoBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
      return onContinueSetup(1);
    }

    if (!wordPressSetupReadyToContinue) {
      if (activeSetupStep !== setupSteps.PLUGIN) {
        onContinueSetup(2);
        window.scrollTo({
          top: wordPressPluginsBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (!themeSelected) {
        onContinueSetup(1);
        window.scrollTo({
          top: wordPressThemesBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (hasError) {
        onContinueSetup(0);
        window.scrollTo({
          top: wordPressInfoBlock.current.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (pluginsSelected && activeSetupStep === setupSteps.PLUGIN) {
        onContinueSetup(3);
      }
    }

    if (wordPressSetupReadyToContinue) {
      onContinueSetup(0);
      window.scrollTo({
        top: wordPressInfoBlock.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const getAsaasFirstRow = () => {
    const defaultProps = {
      formData: asaasFormData,
      accountCreated,
      onChange: asaasOnChange,
      onContinue: asaasOnContinue,
      loadingCep,
      enabledFormOpenAccountContinue: asaasEnabledFormOpenAccountContinue,
      enabledMainDataContinue: asaasEnabledMainDataContinue,
      onGoback: asaasOnGoback,
      onSubmit: asaasOnSubmit,
      resetForm: asaasResetForm,
      cnpjTypes: asaasCnpjTypes,
      callformStepTwoMyCEP: asaasCallformStepTwoMyCEP,
      isInsideWizard: true,
      title: asaasTitle,
      description: asaasDescription,
      descriptionImageAlt,
      imageItems,
    };

    return defaultProps;
  };

  const handleOnClick = () => {
    handleLoadingWordpress && handleLoadingWordpress();
  };

  return (
    <Styles.Wrapper ref={wordPressAsaasBlock}>
      <SlideToggle
        open={open}
        onToggle={handleOnToggle}
        maxHeight="2000px"
        controlledByFather
        testId="slide-toggle-asaas"
        icon={<IconDetailedPay width={22} height={30} color={theme.v2.colors.neutral.lowPure} backgroundColor="#0030B9" />}
        title={<Styles.Title>{asaasToggleTitle}</Styles.Title>}
        description={(
          <Styles.DescriptionWrapper>
            {asaasToggleDescription}
          </Styles.DescriptionWrapper>
        )}
      >
        <AsaasFirstRow {...getAsaasFirstRow()} />

        <Styles.ButtonWrapper>
          <Button
            onClick={() => handleOnClick()}
            testId="theme-next-step-button"
            label={continueLabel}
            variant={continueVariant}
          />
        </Styles.ButtonWrapper>
      </SlideToggle>
    </Styles.Wrapper>
  );
};

export default WizardWordpressConfigAsaas;
