import React from 'react';
import PropType from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const TicketHeaderTitle = ({ title, classes }) => (
  <p className={classes.title}>{title}</p>
);

TicketHeaderTitle.propType = {
  title: PropType.string.isRequired,
};

export default withStyles(styles)(TicketHeaderTitle);
