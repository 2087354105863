import React from 'react';
import * as Style from './IconDropdownArrow.style';

const IconDropdownArrow = ({
  color = '#2e578b',
  testId = 'icon-dropdown-arrow',
  size = 24,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill={color}>
      <path id="Caminho_36" data-name="Caminho 36" d="M16.6,16,12,11.4,7.4,16,6,14.6l6-6,6,6Z" transform="translate(0 -0.6)" />
      <path id="Caminho_37" data-name="Caminho 37" d="M0,24H24V0H0Z" fill="none" />
    </svg>
  </Style.Wrapper>

);

export default IconDropdownArrow;
