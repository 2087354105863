import { billingActions } from '@/redux/modules';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const useBenefitParams = () => {
  const { items } = useSelector(state => state.billing.subscriptions);
  const [currentItem, setCurrentItem] = useState({});
  const { subscriptionId, subscriptionType } = useParams();
  const dispatch = useDispatch();
  const currentItemIsReady = !!Object.keys(currentItem).length;
  const retentionInfos = currentItem.retentionInfos && currentItem.retentionInfos;

  useEffect(() => {
    const getSubscriptions = () => {
      if (!items.length) {
        dispatch(billingActions.subscriptions.request());
      }
    };

    const getCurrentSubscription = () => {
      if (items.length) {
        const filteredItem = items.find(({ id, type }) => String(id) === subscriptionId && type === subscriptionType);
        setCurrentItem(filteredItem);
      }
    };

    const getDiscountValues = () => {
      if (currentItemIsReady && typeof currentItem.values === 'undefined') {
        dispatch(billingActions.subscriptions.discountValues.request(currentItem.id));
      }
    };

    getSubscriptions();
    getCurrentSubscription();
    getDiscountValues();
  }, [items, subscriptionId, subscriptionType, currentItem, currentItemIsReady, dispatch]);

  return {
    currentItem,
    retentionInfos,
  };
};

export default useBenefitParams;
