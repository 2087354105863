import styled, { css } from 'styled-components';

export const DnsWizardPlanGridWrapper = styled.section`
  ${({ theme }) => css`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
    @media (min-width: ${theme.v1.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;
