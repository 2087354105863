import { SET_CRUMBS, SET_DYNAMIC_BREADCRUMBS_TITLE } from './actionsTypes';


export const setBreadCrumb = crumbs => ({
  type: SET_CRUMBS,
  crumbs,
});

export const setDynamicBreadCrumbTitles = data => ({
  type: SET_DYNAMIC_BREADCRUMBS_TITLE,
  data,
});
