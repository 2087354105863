import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v2.font.weights.bold};
    line-height: 32px;
    margin: 32px 32px 24px 32px;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 32px 0px 24px 24px;
      max-width: 262px;
    }
  `}
`;

export const WarningWrapper = styled.div`
  ${({ theme, warnAboutDns }) => css`
    margin: ${warnAboutDns ? '0px 32px 0px' : '0px 32px 16px;'};
    display: flex;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: ${warnAboutDns ? '0px 43px 0px 24px' : '0px 43px 16px 24px;'};
    }
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const SecondWarningWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 0px 32px 20px;
    padding-left: 40px;

    > span {
      align-self: center;
      display: flex;
      margin-right: 14px;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 0px 43px 24px 56px;
      padding-left: 0px;
    }
  `}
`;

export const Warning = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    line-height: 18px;
    margin: 0px;

    span:first-child {
      color: ${theme.v2.colors.brand.primaryMedium};
    }
    span {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const WarningAboutDns = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    line-height: 18px;
    margin: 0px 32px 20px;
    padding-left: 40px;

    span {
      font-weight: ${theme.v2.font.weights.medium};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 16px 43px 24px 56px;
      padding-left: 0px;
    }
  `}
`;

export const WarningBackup = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    line-height: 18px;
    margin: 0px;

    > span, > a {
      color: ${theme.v2.colors.brand.primaryBlue}
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const CheckboxWrapper = styled.div`
    ${({ theme }) => css`
      align-items: center;
      cursor: pointer;
      display: flex;
      margin: 0px 32px 32px 32px;
      padding-left: 40px;
      button {
        padding: 0px 8px 0px 0px;
      }
      svg {
        flex: 0 1 auto;
      }
      @media (max-width: ${theme.v2.breakpoints.sm}){
        margin: 0px 34px 32px 56px;
        padding-left: 0px;
      }
    `}
`;

export const CheckboxLabel = styled.p`
    ${({ theme }) => css`
      align-items: center;
      color: ${theme.v2.colors.neutral.lowPure};
      cursor: pointer;
      display: flex;
      font-family: ${theme.v2.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v2.font.weights.regular};
      line-height: 16px;
      user-select: none;
    `}
`;

export const ActionWrapper = styled.div`
  ${({ theme, uninstallError }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: ${uninstallError ? '0px 0px 16px' : '0px 0px 32px'};
    @media (max-width: ${theme.v2.breakpoints.sm}){
      flex-direction: column;
      button:first-child {
        order: 2;
      }
    }
  `}
`;

export const ErrorWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    padding: 0px 43px 0px 24px;
    svg {
      margin-right: 8px;
    }
    p {
      color: ${theme.v2.colors.feedback.problemPure}
    }
  `}
`;
