import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './InvoiceCard.styles';
import { Button, IconInvoice, Tag } from 'gatorcomponents';
import { INVOICES_STATUS_WITHOUT_CAPITALIZE } from '@/config/invoices/invoiceStatus';
import { PAYMENT_METHOD } from '@/components/Domain/RefundAlertsModal/RefundAlertsModal.enum';
import useLocale from '@/hooks/useLocale/useLocale';
import { billingAnalytics } from '@/analytics';
import { format } from 'date-fns';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import SaversDiscountBanner from '../SaversDiscountBanner';
import { DiscountType, OriginCalling } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';
import { GroupPaymentModal } from '@/components/billing/subscriptions/GroupPaymentModal';
import { DOWNLOAD_INVOICE_VOUCHER } from '@/config/api';
import { visualizeNFSeTagManager } from '@/utils/ThirdParties/tagManager';
import { useDispatch, useSelector } from 'react-redux';
import ModalInvoiceWrapped from '../ModalInvoiceWrapped';
import { useResize } from '@/hooks/useResize';
import { commonActions, invoicesActions } from '@/redux/modules';
import { isBRServer } from '@/utils/Validators/validation';
import { XRAY_ENABLED_NEW_NFSE_ENDPOINT_CALLING } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const InvoiceCard = ({
  invoice = null,
  index = null,
  openInvoiceOnMount = false,
  onExecuteAction = null,
}) => {
  const { billing: billingLocale } = useLocale();
  const clientInfo = useSelector(state => state.summary);
  const dispatch = useDispatch();
  const invoicesState = useSelector(state => state.invoicesV2);

  const windowRect = useResize();
  const { width } = windowRect;
  const isTablet = width === 1024;
  const testId = 'invoice-card';

  const invoiceId = invoice.id;

  const isPaymentPendingPhishingCheck = invoice => invoice.statusMessage === INVOICES_STATUS_WITHOUT_CAPITALIZE.PAYMENT_PENDING;

  const [displayModalPayment, setDisplayModalPayment] = useState(openInvoiceOnMount);

  const [nfseModalOpen, setNfseModalOpen] = useState(false);
  const [nfseModalRender, setNfseModalRender] = useState(false);
  const { listOfNfse, currentInvoiceId } = invoicesState.invoices;

  const enabledNewNfseEndpointCalling = useFeatureIsOn(XRAY_ENABLED_NEW_NFSE_ENDPOINT_CALLING);


  const generateNameProductToList = (product) => {
    if (product.product === 'hosting') {
      return `${product.plan} - ${product.domain}`;
    }
    if (product.product === 'upgrade') {
      if (product.type === 'cycle') {
        return `${billingLocale.invoices.alterCycle} ${product.plan} ${product.domain}`;
      }
      return `${billingLocale.invoices.alterPlan} ${product.plan} ${product.domain}`;
    }
    if (product.product === 'domain') {
      switch (product.type) {
        case 'renew':
          return `${billingLocale.invoices.renewDomain} - ${product.domain}`;
        case 'register':
          return `${billingLocale.invoices.registerDomain}- ${product.domain}`;
        case 'cycle':
          return `${product.plan}`;
        case 'transfer':
          return `${product.plan}`;
        case 'plan':
          return `${product.plan}`;
        default:
          break;
      }
    }
    if (product.product === 'addon') {
      return `${product.plan}`;
    }
  };

  const mountListProduct = () => {
    const products = [];
    invoice.products && invoice.products.forEach((product) => {
      products.push(generateNameProductToList(product));
    });

    return products;
  };

  const listProducts = mountListProduct();

  const getTagStatus = () => {
    if (invoice.substatus === INVOICES_STATUS_WITHOUT_CAPITALIZE.PHISHING) {
      return (<Tag variant="pending" label={billingLocale.invoices.analysing} rounded="false" />);
    }
    if (invoice.substatus === INVOICES_STATUS_WITHOUT_CAPITALIZE.FRAUD) {
      return (<Tag variant="pending" label={billingLocale.invoices.analysing} rounded="false" />);
    }

    switch (invoice.statusMessage) {
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="active" label={billingLocale.invoices.paid} rounded="false" />);
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.UNPAID:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="attention" label={billingLocale.invoices.unpaid} rounded="false" />);
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.PAYMENT_EXPIRED:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="problem" label={billingLocale.invoices.unpaidExpired} rounded="false" />);
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.CANCELLED:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="cancel" label={billingLocale.invoices.cancelled} rounded="false" />);
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.PAYMENT_PENDING:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="pending" label={billingLocale.invoices.awaitingPayment} rounded="false" />);
      case INVOICES_STATUS_WITHOUT_CAPITALIZE.REFUNDED:
        return (<Tag testId={`${testId}-invoice-status-${index}`} variant="refund" label={billingLocale.invoices.refunded} rounded="false" />);
      default:
        return (<></>);
    }
  };

  const isPaidedInvoice = () => invoice && invoice.statusMessage === INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID;

  const paymentMethodLabel = () => {
    if (invoice !== null) {
      switch (invoice.paymentmethod) {
        case PAYMENT_METHOD.BRASPAG_CREDIT:
        case PAYMENT_METHOD.BRASPAG_PARC_1X:
        case PAYMENT_METHOD.BRASPAG_PARC_2X:
        case PAYMENT_METHOD.BRASPAG_PARC_3X:
        case PAYMENT_METHOD.BRASPAG_PARC_4X:
        case PAYMENT_METHOD.BRASPAG_PARC_5X:
        case PAYMENT_METHOD.BRASPAG_PARC_6X:
        case PAYMENT_METHOD.BRASPAG_PARC_7X:
        case PAYMENT_METHOD.BRASPAG_PARC_8X:
        case PAYMENT_METHOD.BRASPAG_PARC_9X:
        case PAYMENT_METHOD.BRASPAG_PARC_10X:
        case PAYMENT_METHOD.BRASPAG_PARC_11X:
        case PAYMENT_METHOD.BRASPAG_PARC_12X:
        case PAYMENT_METHOD.CREDIT_CARD_ES:
        case PAYMENT_METHOD.CREDIT_CARD:
          return billingLocale.paymentMethods.creditCard;
        case PAYMENT_METHOD.PAYPAL:
        case PAYMENT_METHOD.GP_PAYPAL:
          return billingLocale.paymentMethods.paypal;
        case PAYMENT_METHOD.PIX:
          return billingLocale.paymentMethods.pix;
        case PAYMENT_METHOD.GP_BOLETO:
        case PAYMENT_METHOD.BOLETO_MX:
        case PAYMENT_METHOD.BOLETO_CL_CO:
          return billingLocale.paymentMethods.ticket;
        default:
          return billingLocale.paymentMethods.hgCredits;
      }
    }
  };

  const displayPaymentMethod = () => invoice !== null && ![INVOICES_STATUS_WITHOUT_CAPITALIZE.CANCELLED].includes(invoice.statusMessage);

  const onDisplaPaymentModal = (invoiceIdFromPreChat) => {
    setDisplayModalPayment(true);

    if (invoiceIdFromPreChat) {
      return;
    }

    const { subscriptions: subscriptionAnalytics } = billingAnalytics;
    subscriptionAnalytics.executePaymentInvoiceManagerClick('1_link_pagar_fatura');
  };

  const enabledToPay = () => ![INVOICES_STATUS_WITHOUT_CAPITALIZE.REFUNDED, INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID, INVOICES_STATUS_WITHOUT_CAPITALIZE.CANCELLED].includes(invoice.statusMessage);

  const DownloadInvoiceVoucher = () => {
    window.open(DOWNLOAD_INVOICE_VOUCHER(invoice.id), '_blank');
  };

  const onCloseNfseModal = () => {
    dispatch(invoicesActions.invoices.getNfse.set([], null));
    setNfseModalOpen(false);
    setNfseModalRender(false);
  };

  const displayNfseModal = useCallback(() => {
    if (currentInvoiceId !== invoiceId || !listOfNfse.length) return;

    const isJuridicPerson = clientInfo.personType === 'BUSINESS';
    visualizeNFSeTagManager(isJuridicPerson);
    setNfseModalOpen(true);
    setNfseModalRender(true);
  }, [listOfNfse]);

  useEffect(() => {
    displayNfseModal();
  }, [displayNfseModal]);

  const onDisplayNfseModal = () => {
    if (enabledNewNfseEndpointCalling) {
      dispatch(invoicesActions.invoices.getNfse.request(invoice.id));
    }
    if (!enabledNewNfseEndpointCalling && invoice.nfse.length) {
      const isJuridicPerson = clientInfo.personType === 'BUSINESS';
      visualizeNFSeTagManager(isJuridicPerson);

      setNfseModalOpen(true);
      setNfseModalRender(true);
    }
    if (!enabledNewNfseEndpointCalling && !invoice.nfse.length) {
      dispatch(commonActions.notifications.set({
        label: (<p>{billingLocale.invoices.infotReceiptGeneration}</p>),
        type: 'error',
      }));
    }
  };

  const getInvoiceAction = () => {
    const actions = [];
    if (invoice != null) {
      if (enabledToPay() && !invoice.needContactUs && !isPaymentPendingPhishingCheck(invoice)) {
        actions.push(<Button
          testId={`${testId}-content-button-pay-${index}`}
          onClick={() => onDisplaPaymentModal()}
          label={billingLocale.invoices.goToPayment}
        />);
      }
      if (invoice.statusMessage === INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID) {
        actions.push(<Button
          testId={`${testId}-content-button-view-proof-${index}`}
          variant="secondary"
          onClick={DownloadInvoiceVoucher}
          label={billingLocale.invoices.showProof}
        />);
      }

      if (invoice.statusMessage === INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID && isBRServer) {
        actions.push(<Button
          testId={`${testId}-content-button-view-nfse-${index}`}
          variant="secondary"
          onClick={() => onDisplayNfseModal()}
          label={billingLocale.invoices.showReceiptAction}
        />);
      }
    }
    return actions;
  };

  const generateListProducts = () => {
    const listProductsFormated = [];
    listProducts != null && listProducts.forEach((item) => {
      listProductsFormated.push(<Styles.Title>{item}</Styles.Title>);
    });
    return listProductsFormated;
  };

  const getDueDateFormated = () => {
    try {
      return format(new Date(`${invoice.duedate} 00:00:00`), 'dd/MM/yyyy');
    } catch (error) {
      console.log('Error: %s', error);
    }
    return '-';
  };

  const hasValidDueDate = () => getDueDateFormated() !== '-';

  return (
    <Styles.Wrapper data-testid={`${testId}-content`}>
      <Styles.WrapperContent>
        <Styles.ContentDetails>
          <Styles.Content>
            <Styles.Block>
              <Styles.Icon>
                <IconInvoice />
              </Styles.Icon>
            </Styles.Block>
            <Styles.InvoiceIdDescription>
              <Styles.InvoiceId data-testid={`${testId}-invoice`}>{invoiceId}</Styles.InvoiceId>
            </Styles.InvoiceIdDescription>
            <Styles.Block data-testid={`${testId}-status`}>
              {getTagStatus()}
            </Styles.Block>
          </Styles.Content>
          <Styles.ContentProducts data-testid={`${testId}-products`}>
            {generateListProducts()}
          </Styles.ContentProducts>
        </Styles.ContentDetails>
        <Styles.WrapperDetails isPaid={isPaidedInvoice()}>
          <Styles.ContentDetails>
            <Styles.Title>{billingLocale.manageSubscription.dueDateLabel}</Styles.Title>
            <Styles.Description data-testid={`${testId}-duedate`}>{getDueDateFormated()}</Styles.Description>
          </Styles.ContentDetails>
          <Styles.ContentDetails>
            <Styles.Title>{billingLocale.invoices.totalInvoice}</Styles.Title>
            <Styles.Description data-testid={`${testId}-total`}>{formatCurrency(invoice.subtotal)}</Styles.Description>
          </Styles.ContentDetails>
          {displayPaymentMethod() && (
            <Styles.ContentDetails>
              <Styles.Title>{billingLocale.manageSubscription.installmentsPaymentFormLabel}</Styles.Title>
              <Styles.Description data-testid={`${testId}-paymentmethod`}>{paymentMethodLabel()}</Styles.Description>
            </Styles.ContentDetails>
          )}
        </Styles.WrapperDetails>
        {!isTablet && (
          <Styles.ContentActions status={invoice.statusMessage}>
            {getInvoiceAction(invoice)}
          </Styles.ContentActions>
        )}
      </Styles.WrapperContent>
      {isTablet && (
        <Styles.ContentActions status={invoice.statusMessage}>
          {getInvoiceAction(invoice)}
        </Styles.ContentActions>
      )}
      {
        invoice.discount && invoice.discount.type === DiscountType.SAVERS35OFF && (
          <SaversDiscountBanner />
        )
      }
      {
        invoice && invoice.id !== undefined && hasValidDueDate() && (
          <GroupPaymentModal
            displayModalPayment={displayModalPayment}
            setDisplayModalPayment={setDisplayModalPayment}
            invoice={invoice}
            originCalling={OriginCalling.INVOICES_MANAGER}
            onExecuteAction={onExecuteAction}
          />
        )
      }
      {nfseModalRender && (
      <ModalInvoiceWrapped
        modal={invoice.id}
        nfse={enabledNewNfseEndpointCalling ? listOfNfse : invoice.nfse}
        open={nfseModalOpen}
        onClose={() => onCloseNfseModal()}
      />
      )}
    </Styles.Wrapper>
  );
};


export default InvoiceCard;
