import React from 'react';
import * as Style from './IconHosting.styles';

const IconHosting = ({ testId = 'IconHosting' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="icon-hospedagem" xmlns="http://www.w3.org/2000/svg" width="31.798" height="31.265" viewBox="0 0 31.798 31.265">
      <path id="Oval-2" d="M235.289,165.45l-31.418-31.213V165.45Z" transform="translate(-203.871 -134.237)" fill="#ffd54c" />
      <g id="Rectangle-4" transform="translate(15.9 15.235)">
        <rect id="Retângulo_13328" data-name="Retângulo 13328" width="12.586" height="13.248" fill="none" />
        <g id="Grupo_36335" data-name="Grupo 36335" transform="translate(0 0)">
          <path id="Caminho_238743" data-name="Caminho 238743" d="M240.459,170.485H227.873V157.237h12.586Zm-9.936-2.65h7.287v-7.949h-7.287Z" transform="translate(-227.873 -157.237)" fill="#1f2044" />
        </g>
      </g>
      <rect id="Rectangle-5" width="2.65" height="2.65" transform="translate(5.301 5.299)" fill="#1f2044" />
      <rect id="Rectangle-6" width="2.65" height="2.65" transform="translate(9.938 5.299)" fill="#1f2044" />
      <rect id="Rectangle-7" width="2.65" height="2.65" transform="translate(14.837 5.299)" fill="#1f2044" />
      <rect id="Rectangle_Copy_18" data-name="Rectangle Copy 18" width="4.637" height="2.65" transform="translate(5.301 25.172)" fill="#1f2044" />
      <rect id="Rectangle_Copy_21" data-name="Rectangle Copy 21" width="8.611" height="2.65" transform="translate(5.301 20.535)" fill="#1f2044" />
      <rect id="Rectangle_Copy_23" data-name="Rectangle Copy 23" width="8.611" height="2.65" transform="translate(5.301 15.235)" fill="#1f2044" />
      <rect id="Rectangle_Copy_25" data-name="Rectangle Copy 25" width="26.497" height="2.65" transform="translate(5.301 9.936)" fill="#1f2044" />
      <path id="Caminho_238744" data-name="Caminho 238744" d="M235.669,134.8h-31.8v30.892h2.65V137.451h29.146Z" transform="translate(-203.871 -134.427)" fill="#1f2044" />
    </svg>
  </Style.Wrapper>
);


export default IconHosting;
