import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
`;

export const PasswordContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const ChangePasswordButton = styled.span`
  ${({ theme }) => css`
    background-color: transparent;
    border: none;
    color: ${theme.v2.colors.brand.primaryMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    cursor: pointer;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
  `}
`;
