import React from 'react';

export default {
  goToPlaylist: 'Ver playlist',
  bannerDescription: (
    <>
      Acompanhe as lives diárias com nossos especialistas e
      {' '}
      <strong>inicie sua jornada online.</strong>
    </>
  ),
  live: 'LIVE',
  daily: isDesktop => (
    <>
      {'De Segunda a Sexta, '}
      {isDesktop && <br />}
      <strong>das 9h às 11h e 14h às 16h.</strong>
    </>
  ),
  close: 'Fechar',
  notShowAgainLabel: 'Não mostrar novamente',
};
