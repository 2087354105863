const styles = theme => ({
  cardsWrappers: {
    display: 'flex',
    [theme.breakpoints.down(1280)]: {
      flexDirection: 'column',
    },
  },

  cardsWrappersWithoutSearch: {
    display: 'unset',
  },

  iconSearch: {
    color: `${theme.palette.grey.darklight}`,
    paddingLeft: '-3px',
  },

  card: {
    alignItems: 'center',
    borderRadius: '0',
    boxShadow: `0px 2px 0px 0px ${theme.color.alto}`,
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: 6,
    minHeight: '60px',
    padding: '12px 24px',
    position: 'relative',

    [theme.breakpoints.down(700)]: {
      boxShadow: '0px 1px 3px #00000029',
      marginBottom: 4,
      marginTop: '16px',
    },
  },

  middleCard: {
    flex: 1,

    [theme.breakpoints.down(700)]: {
      marginTop: '4px',
    },
    [theme.breakpoints.up(1280)]: {
      paddingRight: 0,
    },
  },

  rightCard: {
    [theme.breakpoints.up(1280)]: {
      paddingLeft: 12,
    },
  },

  pageTitle: {
    fontSize: '24px',
    fontWeight: 300,
  },

  label: {
    color: theme.color.tundora,
    fontSize: 14,
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginLeft: 'auto',
  },

  textField: {
    border: '1px solid #CDD8DF',
    borderRadius: '2px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    '& fieldset': {
      borderRadius: 2,
    },

    '& input': {
      padding: '8px 12px',
    },

    [theme.breakpoints.down(1280)]: {
      marginLeft: 0,
      width: '100%',
    },
  },

  adornment: {
    '& > svg': {
      fontSize: 18,
      fill: 'rgba(0, 0, 0, 0.23)',
    },

    [theme.breakpoints.up(1280)]: {
      display: 'none',
    },
  },

  selectCheckbox: {
    margin: '6px 8px 5px 0',
    width: '199px',
    '@media(max-width: 1260px)': {
      width: '100%',
      margin: '5px 0px 18px 0px',
    },
  },

  select: {
    margin: 0,
    marginLeft: 12,
    width: '220px',

    '& > div': {
      border: '1px solid #CDD8DF',
      borderRadius: '2px',
      marginRight: 0,
      padding: 0,
      transition: '300ms',

      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },

      '& > fieldset': {
        display: 'none',
      },

      '& > before': {
        display: 'none',
      },
    },

    [theme.breakpoints.down(1280)]: {
      marginLeft: 0,
      width: '100%',

      '& div': {
        border: 0,
        padding: 0,
      },
    },
  },
});
export default styles;
