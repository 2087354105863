import React, { Fragment, Component } from 'react';
import { withStyles, CardContent, IconButton } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Photo } from '@material-ui/icons';
import TicketHeader from '@/components/Tickets/TicketHeader';
import TicketContent from '@/components/Tickets/TicketContent';
import TicketFooter from '@/components/Tickets/TicketFooter';
import TicketFeedbackModal from '@/components/Tickets/TicketFeedbackModal';
import TicketDivider from '@/components/Tickets/TicketDivider';
import TicketTextInput from '@/components/Tickets/TicketTextInput';
import TicketUploadModal from '@/components/Tickets/TicketUploadModal';
import TicketButtonLoader from '@/components/Tickets/TicketButtonLoader';
import Loader from '@/components/Layout/Loader';
import TicketCard from '@/components/Cards/TicketCard';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import {
  loadTicketDetail, loadTicketDetailV2, saveCommentTicketDetail, saveCommentTicketDetailV2, updateTicketDetailStatus,
} from '@/redux/actions/ticketDetail';
import { API_REQUEST_ERROR } from '@/redux/actions/actionsTypes';
import styles from './styles';
import { preChatActions } from '@/redux/modules';


class TicketChat extends Component {
  state = {
    commentLoader: false,
    canSubmit: true,
    ticketMessage: '',
    redirect: false,
    modalFeedback: false,
    modalUpload: false,
    showInputMessage: false,
    canUpload: true,
  }

  componentDidMount() {
    const {
      onLoadTicketDetail,
      onLoadTicketDetailV2,
      ticketId,
      userEmail,
      v2,
    } = this.props;

    if (v2) {
      onLoadTicketDetailV2(ticketId, userEmail).then((res) => {
        if (res.type === API_REQUEST_ERROR) {
          this.setState({
            redirect: true,
          });
        }
      });
    }

    if (!v2) {
      onLoadTicketDetail(ticketId, userEmail).then((res) => {
        if (res.type === API_REQUEST_ERROR) {
          this.setState({
            redirect: true,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    const {
      tosTicketRedirect,
      preChatTosCancelRedirect,
      preChatSetScreenScreen,
      preChatSetOpen,
    } = this.props;

    if (tosTicketRedirect) {
      preChatSetScreenScreen();
      preChatTosCancelRedirect();
      preChatSetOpen();
    }
  }

  handleChangeInput = (event) => {
    this.setState({ ticketMessage: event.target.value });
  }

  onKeyDown = (e) => {
    const { ticketId } = this.props;
    if (e.keyCode === 13 && !e.shiftKey) {
      this.handleSubmit(ticketId);
    }
  }

  handleOpenModalFeedback = () => {
    this.setState({
      modalFeedback: true,
    });
  }

  handleCloseModalFeedback = () => {
    this.setState({
      modalFeedback: false,
    });
  }

  handleReply = () => {
    this.setState({
      showInputMessage: true,
    });
  }

  handleOpenModalUpload = () => {
    this.setState({
      modalUpload: true,
    });
  }

  handleCloseModalUpload = () => {
    const { canUpload } = this.state;
    canUpload && this.setState({
      modalUpload: false,
    });
  }

  resetMessage = () => {
    this.setState({
      ticketMessage: '',
    });
  }

  handleSubmit(id) {
    this.setState({
      commentLoader: true,
      canUpload: false,
    });


    const { canSubmit, ticketMessage } = this.state;
    const {
      v2,
      onSaveCommentTicketDetail,
      onSaveCommentTicketDetailV2,
      userEmail,
      ticket,
      onUpdateTicketDetailStatus,
    } = this.props;
    if (canSubmit) {
      this.setState({ canSubmit: false });
      if (v2) {
        onSaveCommentTicketDetailV2({
          comment: {
            id,
            body: ticketMessage,
          },
        },
        userEmail).then(() => {
          if (ticket.status === 'solved') {
            onUpdateTicketDetailStatus();
          }
          this.setState({
            commentLoader: false,
            canSubmit: true,
            ticketMessage: '',
            canUpload: true,
          });
        });
      }

      if (!v2) {
        onSaveCommentTicketDetail({
          comment: {
            id,
            body: ticketMessage,
          },
        },
        userEmail).then(() => {
          if (ticket.status === 'solved') {
            onUpdateTicketDetailStatus();
          }
          this.setState({
            commentLoader: false,
            canSubmit: true,
            ticketMessage: '',
            canUpload: true,
          });
        });
      }
    }
  }

  render() {
    const {
      showInputMessage,
      redirect,
      modalFeedback,
      ticketMessage,
      commentLoader,
      modalUpload,
    } = this.state;
    const {
      t,
      classes,
      loading,
      ticket,
      v2,
    } = this.props;
    const showButton = !showInputMessage && ticket.status && !ticket.loading && ticket.status === 'solved';

    if (redirect) {
      return <Redirect to="/404" />;
    }

    if (loading) {
      return <Loader />;
    }

    const EmptyRegex = RegExp(/^ +$/);

    return (
      <TicketCard className={classes.container}>
        <CardContent className={`${classes.ticket} ${ticket.status}`}>
          <TicketHeader id={ticket.id} status={ticket.status} title={ticket.subject} />
          <TicketContent status={ticket.status} />

          {ticket.status !== 'closed' && (
            <TicketFooter>
              {showButton && (
                <Fragment>
                  <div className={classes.ticketSolved}>
                    <OutlineButton
                      onClick={this.handleReply}
                      className={classes.buttonSolved}
                    >
                      {t('tickets.reply')}
                    </OutlineButton>
                    <PrimaryButton
                      onClick={this.handleOpenModalFeedback}
                      className={`${classes.buttonSolved} ${classes.buttonCustomHeight}`}
                    >
                      {t('tickets.close')}
                    </PrimaryButton>
                  </div>
                  <TicketFeedbackModal open={modalFeedback} handleClose={this.handleCloseModalFeedback} />
                </Fragment>
              )}

              <TicketDivider className={classes.divider} />
              <TicketTextInput
                value={ticketMessage}
                onChange={this.handleChangeInput}
                onKeyDown={this.onKeyDown}
                onDisabled={commentLoader}
              />

              <div className={classes.uploadBox}>
                <IconButton onClick={this.handleOpenModalUpload} className={classes.upload}>
                  <Photo className={classes.icon} />
                </IconButton>

                <TicketUploadModal
                  open={modalUpload}
                  handleClose={this.handleCloseModalUpload}
                  comment={ticketMessage}
                  resetComment={this.resetMessage}
                  v2={v2}
                />
              </div>

              <div className={classes.buttonBox}>
                <PrimaryButton
                  onClick={() => this.handleSubmit(ticket.id)}
                  className={classes.send}
                  disabled={EmptyRegex.test(ticketMessage) || ticketMessage.length === 0}
                >
                  {commentLoader ? <TicketButtonLoader /> : t('help.send') }
                </PrimaryButton>
              </div>

            </TicketFooter>
          )}
        </CardContent>
      </TicketCard>
    );
  }
}

const mapStateToProps = state => ({
  userEmail: state.summary.email,
  ticket: state.ticketDetail,
  comments: state.ticketDetail.comments,
  loading: state.ticketDetail.loading,
  tosTicketRedirect: state.preChat.tos.redirect,
});

const mapDispatchToProps = dispatch => ({
  onLoadTicketDetail: (data, userEmail) => dispatch(loadTicketDetail(data, userEmail)),
  onLoadTicketDetailV2: (data, userEmail) => dispatch(loadTicketDetailV2(data, userEmail)),
  onSaveCommentTicketDetail: (data, userEmail) => dispatch(saveCommentTicketDetail(data, userEmail)),
  onSaveCommentTicketDetailV2: (data, userEmail) => dispatch(saveCommentTicketDetailV2(data, userEmail)),
  onUpdateTicketDetailStatus: () => dispatch(updateTicketDetailStatus()),
  preChatTosCancelRedirect: () => dispatch(preChatActions.tos.redirect(false)),
  preChatSetScreenScreen: () => dispatch(preChatActions.preChat.setScreen('problemSolved')),
  preChatSetOpen: () => dispatch(preChatActions.preChat.setOpen(true)),
});

export default withI18n()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketChat)));
