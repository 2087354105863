import { FEATURES } from '@/pages/Products/CloudFlare/ManageCloudFlareHandler/ManageCloudFlareHandler.types';
import { Link } from 'gatorcomponents';
import React from 'react';

export default {
  hostingLabel: 'Hospedagem de site',
  change: 'Alterar',
  cloudflareCdnExplanation: 'A funcionalidade da CDN é integrada com os recursos da CloudFlare.',
  searchPerDomain: 'Pesquisar por domínio',
  activateCdn: 'Ativar CDN',
  status: {
    nonexistentexternal: 'Pendente',
    pendinginternal: 'Em andamento',
    pendingexternal: 'Em andamento',
    delayinternal: 'Em andamento',
    delayexternal: 'Em andamento',
    activationpointingerrorinternal: 'Em andamento',
    activationpointingerrorexternal: 'Em andamento',
    pendingdeactivationinternal: 'Em andamento',
    pendingdeactivationexternal: 'Em andamento',
    inactivationpointingerrorinternal: 'Em andamento',
    inactivationpointingerrorexternal: 'Em andamento',
  },
  manage: 'Gerenciar',
  externalDomain: 'Esse domínio foi registrado em outro lugar. Verifique-o antes de ativar o CDN.',
  cantActivate: 'Não é possível alterar a CDN enquanto a ativação está em andamento',
  ongoingActivation: (
    <>
      {'A CDN será ativada automaticamente após a configuração do domínio. Isso pode levar até 72 horas. '}
      <Link
        text="Entenda o prazo."
        href="https://suporte.hostgator.com.br/hc/pt-br/articles/30812694986771-O-que-%C3%A9-propaga%C3%A7%C3%A3o"
        target="_blank"
      />
    </>
  ),
  ongoingDeactivation: (
    <>
      {'A CDN será desativada automaticamente. Isso pode levar até 72 horas. '}
      <Link
        text="Entenda o prazo."
        href="https://suporte.hostgator.com.br/hc/pt-br/articles/30812694986771-O-que-%C3%A9-propaga%C3%A7%C3%A3o"
        target="_blank"
      />
    </>
  ),
  noDomains: 'Nenhum resultado encontrado para sua busca',
  pendingTooltip: 'Não é possível alterar a CDN enquanto a ativação está em andamento',
  cdnAlreadyActive: (
    <span>
      <strong>
        A CDN
      </strong>
      {' já está ativa nesse site, deixando-o mais rápido e seguro.'}
    </span>
  ),
  showMore: 'Mostrar mais',
  verifyDomain: 'Verificar domínio',
  changePlanModal: {
    title: 'Selecione uma hospedagem',
    mainDomain: 'Domínio principal',
    back: 'Voltar',
    continue: 'Continuar',
  },
  activateDeactivateInternalModal: {
    toActivateTitle: 'Ativar o CDN',
    toDeactivateTitle: 'Desativar o CDN',
    activatingTitle: 'Ativação do CDN em andamento',
    deactivatingTitle: 'Desativação do CDN em andamento',
    toActivateDescription: 'Ative o CDN e deixe seu site mais rápido e seguro. As mudanças podem levar até 24 horas para domínios nacionais e até 72 horas para domínios internacionais.',
    toDeactivateDescription: 'Desativar o CDN pode deixar seu site mais lento e menos seguro. As mudanças podem levar até 24 horas para domínios nacionais e 72 horas para domínios internacionais.',
    activatingDescription: 'A ativação do seu CDN foi solicitada e está em andamento. Acompanhe a atualização do status na tela de gerenciamento.',
    deactivatingDescription: 'A desativação do seu CDN foi solicitada e está em andamento. Acompanhe a atualização do status na tela de gerenciamento.',
    cancelCta: 'Cancelar',
    confirmationCta: 'Ok, entendi',
    continueActivationCta: 'Ativar o CDN',
    continueDeactivationCta: 'Desativar o CDN',
    failedRequestTitle: 'Ocorreu um erro na sua solicitação',
    failedRequestDescription: 'Algo deu errado enquanto processavamos sua solicitação. Por favor, tente novamente.',
  },
  nameServerTooltip: (ns1, ns2) => (
    <>
      <strong>
        {'Servidor 1: '}
      </strong>
      {ns1}
      <br />
      <strong>
        {'Servidor 2: '}
      </strong>
      {ns2}
    </>
  ),
  manageCdnDisabledLabel: 'Essa funcionalidade estará disponível em breve para você gerenciar sua CDN',
  refreshStatus: 'Atualizar status da listagem',
  noOpenStatus: 'Você não possui status em aberto',
  waitRefreshTimeLimit: 'Aguarde até 1 hora para atualizar o status novamente',
  domainsError: 'Ocorreu um problema ao processar sua solicitação. Caso persista, tente novamente mais tarde.',
  refreshList: 'Recarregar lista',
  manageCdnHandler: {
    hostingLabel: planName => `Hospedagem: ${planName}`,
    selectedDomainLabel: 'Domínio selecionado',
    changeButtonLabel: 'Alterar',
    readMoreButton: toggleOn => (toggleOn ? 'Ler menos' : 'Ler mais'),
    footerLabel: 'Essas funcionalidades utilizam os recusos da Cloudflare',
    cdnCards: {
      [FEATURES.CLEAN_CACHE]: {
        cardTitle: 'Limpar cache',
        cardDescription: 'Apague os arquivos em cache para forçar o sistema a buscar uma nova versão desses arquivos do seu servidor web. Limpar o cache pode fazer com que o seu site fique mais lento temporariamente e sobrecarregar o seu servidor.',
        button: {
          buttonLabel: 'Limpar cache',
        },
      },
      [FEATURES.DEVELOPER_MODE]: {
        cardTitle: 'Visualizar mudanças no site em tempo real',
        cardDescription: 'Desativa temporariamente alguns recursos como armazenamento em cache, compressão e otimização de arquivos, por três horas (a menos que você os desative antes).  Isso permite que você veja imediatamente qualquer mudança que faça no seu site.',
        tooltipLabel: (
          <>
            {'Essa funcionalidade utiliza o '}
            <strong>modo desenvolvedor</strong>
            {' da Cloudflare'}
          </>
        ),
      },
      [FEATURES.UNDER_ATTACK_MODE]: {
        cardTitle: 'Modo sob ataque',
        cardDescription: 'Faz verificações extras para proteger seu site contra ataques de vírus. Ele permite o bloqueio do tráfego suspeito. Use este modo quando seu site estiver sendo atacado. Quando ativado, os visitantes verão uma página de espera antes de acessar o site.',
      },
      [FEATURES.ALWAYS_ONLINE]: {
        cardTitle: 'Manter site sempre online',
        cardDescription: 'Mantenha o seu site acessível mesmo quando o servidor estiver fora do ar. Essa função usa cópias das suas páginas guardadas no Wayback Machine (serviço online que arquiva e permite visualizar versões antigas de sites da internet).',
        tooltipLabel: (
          <>
            {'Essa funcionalidade utiliza o '}
            <strong>always online</strong>
            {' da Cloudflare'}
          </>
        ),
      },
    },
    cleanCacheModal: {
      loadingTitle: 'Limpeza de cache em andamento',
      successTitle: 'Limpeza de cache realizada',
      successDescription: 'Seu site será atualizado com a última versão disponível no servidor original.',
      okButtonLabel: 'Ok, entendi',
    },
  },
};
