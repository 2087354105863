import React from 'react';
import * as Styles from './SentEmail.styles';
import { SendAgain } from '../SendAgain';
import { Button, IconLoading } from 'gatorcomponents';

const SentEmail = ({
  emailVerifyLocale,
  sentEmailLocale,
  clientInfo,
  countDownResend,
  setCountDownResend,
  handleResendEmail,
  loadingChange,
  openForm,
}) => (
  <Styles.Wrapper data-testid="SentEmail">
    <Styles.Title>
      {sentEmailLocale.title}
    </Styles.Title>

    <Styles.Description>
      {sentEmailLocale.description({ email: clientInfo.email })}
    </Styles.Description>

    {countDownResend > 0
      ? (
        <Styles.SendAgainWrapper>
          <SendAgain
            emailVerifyLocale={emailVerifyLocale}
            countDownResend={countDownResend}
            setCountDownResend={setCountDownResend}
          />
        </Styles.SendAgainWrapper>
      )
      : (
        <>

          <Styles.TitleNotReceived>{emailVerifyLocale.emailNotRecived}</Styles.TitleNotReceived>
          <Styles.DescriptionNotReceived>
            {sentEmailLocale.tryResendDescription}
          </Styles.DescriptionNotReceived>

          <Styles.ButtonWrapper>
            <Button
              type="button"
              width="fit"
              label={sentEmailLocale.openFormButtonLabel}
              onClick={openForm}
              variant="tertiary"
            />

            <Button
              type="submit"
              testId="submit"
              width="fit"
              label={loadingChange ? <IconLoading /> : sentEmailLocale.resendButtonLabel}
              onClick={handleResendEmail}
              variant="secondary"
              disabled={loadingChange}
            />
          </Styles.ButtonWrapper>
        </>
      )}
  </Styles.Wrapper>
);

export default SentEmail;
