export const TLD_LIST = [
  '.academy',
  '.agency',
  '.associates',
  '.bargains',
  '.bike',
  '.biz',
  '.boutique',
  '.builders',
  '.business',
  '.cab',
  '.cafe',
  '.camera',
  '.camp',
  '.cards',
  '.care',
  '.cash',
  '.catering',
  '.cc',
  '.center',
  '.chat',
  '.cheap',
  '.church',
  '.city',
  '.cl',
  '.cleaning',
  '.clothing',
  '.club',
  '.co',
  '.coffee',
  '.com',
  '.com.co',
  '.com.mx',
  '.community',
  '.company',
  '.computer',
  '.construction',
  '.contractors',
  '.cool',
  '.deals',
  '.digital',
  '.direct',
  '.directory',
  '.discount',
  '.dog',
  '.domains',
  '.education',
  '.email',
  '.enterprises',
  '.equipment',
  '.estate',
  '.events',
  '.exchange',
  '.exposed',
  '.express',
  '.fail',
  '.farm',
  '.fitness',
  '.florist',
  '.football',
  '.foundation',
  '.fun',
  '.fyi',
  '.gallery',
  '.gifts',
  '.glass',
  '.graphics',
  '.gratis',
  '.gripe',
  '.group',
  '.guide',
  '.guru',
  '.host',
  '.house',
  '.immo',
  '.in',
  '.industries',
  '.info',
  '.institute',
  '.international',
  '.kitchen',
  '.la',
  '.land',
  '.life',
  '.lighting',
  '.limited',
  '.live',
  '.management',
  '.marketing',
  '.mba',
  '.me',
  '.media',
  '.mobi',
  '.money',
  '.mx',
  '.net',
  '.network',
  '.news',
  '.online',
  '.org',
  '.parts',
  '.photography',
  '.photos',
  '.place',
  '.plumbing',
  '.plus',
  '.press',
  '.productions',
  '.properties',
  '.reisen',
  '.rentals',
  '.repair',
  '.report',
  '.run',
  '.sarl',
  '.school',
  '.schule',
  '.services',
  '.shoes',
  '.show',
  '.singles',
  '.site',
  '.soccer',
  '.solar',
  '.solutions',
  '.space',
  '.store',
  '.style',
  '.supplies',
  '.supply',
  '.support',
  '.systems',
  '.team',
  '.tech',
  '.technology',
  '.tips',
  '.today',
  '.tools',
  '.top',
  '.town',
  '.toys',
  '.training',
  '.tv',
  '.vacations',
  '.vision',
  '.watch',
  '.website',
  '.works',
  '.world',
  '.wtf',
  '.xyz',
  '.zone',
  '.com.br',
  '.net.br',
  '.agr.br',
  '.art.br',
  '.esp.br',
  '.far.br',
  '.imb.br',
  '.ind.br',
  '.inf.br',
  '.radio.br',
  '.rec.br',
  '.srv.br',
  '.tmp.br',
  '.tur.br',
  '.tv.br',
  '.etc.br',
  '.adm.br',
  '.adv.br',
  '.arq.br',
  '.ato.br',
  '.bio.br',
  '.bmd.br',
  '.cim.br',
  '.cng.br',
  '.cnt.br',
  '.ecn.br',
  '.eng.br',
  '.eti.br',
  '.fnd.br',
  '.fot.br',
  '.fst.br',
  '.jor.br',
  '.lel.br',
  '.mat.br',
  '.med.br',
  '.mus.br',
  '.not.br',
  '.ntr.br',
  '.odo.br',
  '.ppg.br',
  '.pro.br',
  '.psc.br',
  '.qsl.br',
  '.slg.br',
  '.taxi.br',
  '.teo.br',
  '.trd.br',
  '.vet.br',
  '.zlg.br',
  '.blog.br',
  '.flog.br',
  '.vlog.br',
  '.wiki.br',
  '.eco.br',
  '.jampa.br',
  '.floripa.br',
  '.poa.br',
  '.vix.br',
  '.belem.br',
  '.recife.br',
  '.macapa.br',
  '.abc.br',
  '.sjc.br',
  '.osasco.br',
  '.bsb.br',
  '.maceio.br',
  '.aju.br',
  '.cuiaba.br',
  '.udi.br',
  '.riobranco.br',
  '.natal.br',
  '.gru.br',
  '.palmas.br',
  '.fortal.br',
  '.londrina.br',
  '.niteroi.br',
  '.aparecida.br',
  '.sorocaba.br',
  '.bhz.br',
  '.contagem.br',
  '.jab.br',
  '.the.br',
  '.jdf.br',
  '.ribeirao.br',
  '.salvador.br',
  '.campinas.br',
  '.sampa.br',
  '.boavista.br',
  '.caxias.br',
  '.goiania.br',
  '.pvh.br',
  '.slz.br',
  '.feira.br',
  '.joinville.br',
  '.morena.br',
  '.curitiba.br',
  '.anani.br',
  '.9guacu.br',
  '.manaus.br',
  '.foz.br',
  '.saogonca.br',
  '.riopreto.br',
  '.santamaria.br',
  '.campinagrande.br',
  '.santoandre.br',
  '.saobernardo.br',
  '.maringa.br',
  '.rio.br',
  '.ltda',
  '.rep.br',
  '.des.br',
  '.det.br',
  '.enf.br',
  '.coz.br',
  '.geo.br',
  '.app.br',
  '.dev.br',
  '.log.br',
  '.seg.br',
  '.tec.br',
  '.bib.br',
];
