import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { countBy, propEq } from 'ramda';
import ChipLinkButton from '@/components/Buttons/ChipLinkButton/index';
import { activateWebsiteCreationFlow } from '@/redux/actions/onboarding';
import { ProductsContext } from '@/contexts/Products/context';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';
import classnames from 'classnames';
import { IconLoading } from 'gatorcomponents';
import { productsActions } from '@/redux/modules';
import { useHistory } from 'react-router';
import useLocale from '@/hooks/useLocale/useLocale';
import { backupAnalytics } from '@/analytics';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CPANEL_SSO_PORTAL } from '@/config/GrowthBook/constants';

const AddonListButton = ({
  t,
  group,
  onRedirectToProductDetail,
  productGroups,
  domain,
  createSiteLabel,
  classes,
  productId,
}) => {
  const context = useContext(ProductsContext);
  const { oldResellerId, featureOldResellerNotification } = context || {};
  const dispatch = useDispatch();
  const featureToggleSiteBuilderActivate = useSelector(state => state.featureToggles.toggles.portal
    && state.featureToggles.toggles.portal.websitebuilderTraditional);
  const loadingCpanelSSO = useSelector(state => state.products.cPanelLoading);
  const history = useHistory();
  const { routes: routesLocale, sites: sitesLocale } = useLocale();
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);

  const getDomain = group => group.domain.domain || group.domain;

  const siteBuilderConfig = () => {
    const [{ status }] = group.actions.filter(item => item.type === 'websitebuilder');
    const isSiteActive = status === 'active';
    const isSiteInactive = status === 'inactive' && featureToggleSiteBuilderActivate;

    if (!isSiteActive && !featureToggleSiteBuilderActivate && group.isStandalone) {
      return {
        onClick: () => dispatch(activateWebsiteCreationFlow(group.id, getDomain(group))),
      };
    }

    return {
      onClick: isSiteInactive ? () => onRedirectToProductDetail() : null,
      component: Link,
      to: `${t('routes.siteBuilderRedirectAccess')}/${getDomain(group)}${isSiteActive ? '' : `/${group.id}`}`,
      target: '_blank',
    };
  };

  const setupMailLink = () => {
    const multipleDomains = countBy(propEq('hostname', group.hostname), productGroups);

    const settedDomain = domain || group.domain;
    const titanMailRedirectUrl = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${group.hostname}` : `${t('routes.emailsList')}${t('routes.emailsTitan')}/${settedDomain}`;
    const cpanelMailRedirectUrl = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${group.hostname}` : `${t('routes.filteredEmails')}/${settedDomain}`;

    let link = group.setupMail === 'proemail' ? titanMailRedirectUrl : cpanelMailRedirectUrl;

    if (!group.configuredMail) {
      link = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${group.hostname}` : `${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${getDomain(group)}?idPro=${group.proemailId}`;
    }

    if (group.domainstatus === 'Pending' || group.domainstatus === 'Suspended' || group.domainstatus === 'Cancelled') {
      link = t('routes.emailsList');
    }

    return link;
  };

  const goToCPanel = (defaultCPanelUrl) => {
    dispatch(productsActions.cPanelSSO.requestLink({
      hostingId: group.id, defaultCPanelUrl, setLoadingWithId: true, enableCpanelSSO,
    }));
  };

  const showBackupRedirectModal = () => {
    history.push(`${routesLocale.products}${routesLocale.productdetail}/${productId}${routesLocale.backup}`);
    backupAnalytics.clickBackupButtonProductsList();
  };

  const cloudflareRedirect = () => {
    history.push(`${routesLocale.products}${routesLocale.cloudflare}/${productId}`);
  };

  return group.actions.filter(addon => Object.prototype.hasOwnProperty.call(addon, 'linkUrl')).map((addon) => {
    let config = {};

    switch (addon.type) {
      case 'cpanel':
        config = {
          onClick: () => goToCPanel(addon.linkUrl.toString()),
          label: loadingCpanelSSO === group.id ? <IconLoading /> : t(`actions.${addon.type}.${addon.status}`),
        };
        break;
      case 'cloudflare':
        config = {
          onClick: cloudflareRedirect,
        };
        break;
      case 'cpanel-backup':
        config = {
          onClick: showBackupRedirectModal,
        };
        break;
      case 'domainLink':
      case 'wphosting':
      case 'cpanel-addon':
      case 'codeguard':
        config = {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: addon.linkUrl.toString(),
        };
        break;
      case 'whm':
        if (addon.linkUrl === '_self') {
          config = {
            component: Link,
            to: `${t('routes.whmRedirectAccess')}/${group.id}`,
            target: '_blank',
          };
        } else {
          config = {
            href: addon.linkUrl.toString(),
            target: '_blank',
          };
        }
        break;
      case 'websitebuilder':
        config = {
          ...config,
          ...siteBuilderConfig(),
          label: t(`actions.${addon.type}.${addon.status}`),
        };
        break;
      case 'setup-mail':
        config = {
          href: setupMailLink(),
          label: t('proEmail.chipLinkLabel'),
        };
        break;
      case 'sitebuilder':
        config = {
          href: addon.linkUrl.toString(),
          label: createSiteLabel,
        };
        break;
      default:
        config = {
          target: '_self',
          href: addon.linkUrl.toString(),
        };
        break;
    }

    let isOldRseller = false;
    if (context && oldResellerId) {
      isOldRseller = oldResellerId.find(packageid => group.packageid === packageid);
    }

    const showOldResellerLabel = isOldRseller && featureOldResellerNotification && addon.type === 'plan-switch';

    if (!config) {
      return null;
    }

    if (addon.type === 'sitebuilder') {
      return (
        <LinkButton
          key="sitebuilder_active"
          to={`${addon.linkUrl.toString()}`}
          className={classnames(classes.cardLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {createSiteLabel}
        </LinkButton>
      );
    }

    return (
      <ChipLinkButton
        key={`${addon.type}_${addon.status}`}
        status={addon.status}
        type={addon.type}
        cloudflareTagLabel={sitesLocale.page.tagNew}
        label={showOldResellerLabel ? t(`actions.old-reseller-plan-switch.${addon.status}`) : t(`actions.${addon.type}.${addon.status}`)}
        {...config}
      />
    );
  });
};

export default withStyles(styles)(withWidth()((withI18n()(AddonListButton))));
