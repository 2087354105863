import {
  Button, Typography, withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ModalCheckDomainAvailable from '@/components/Modal/ModalCheckDomainAvailable';

import ModalSelectionHostingPlans from '@/components/Modal/ModalSelectionHostingPlans';
import * as actions from '@/redux/actions/emails';
import styles from './styles';
import { executeGAContractNewPlanInListEmails } from '@/utils/ThirdParties/tagManager';


const InactiveBlock = ({
  classes, status, domain, t,
}) => {
  const pendingMessage = status === 'pending' || status === 'provisioning';
  const [modalCheckDomainAvailable, setModalCheckDomainAvailable] = useState(false);
  const [modalSelectionHostingPlans, setModalSelectionHostingPlans] = useState(false);
  const [hostings, setHostings] = useState(new Map());
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOpen = async () => {
    executeGAContractNewPlanInListEmails();
    dispatch(actions.requestCheckDomainAvailable(domain)).then((responseData) => {
      if (responseData.data.available) {
        setModalCheckDomainAvailable(true);
      } else {
        dispatch(actions.requestProEmailHostingPlans(domain)).then((response) => {
          if (response.data.length > 1) {
            setHostings(response.data);
            setModalSelectionHostingPlans(true);
          } else if (response.data.length === 1) {
            history.push(`${t('routes.emailsList')}${t('routes.titanHireFreePlan')}/${response.data[0].domain}?id=${response.data[0].hostingId}`);
          } else {
            history.push(`${t('routes.emailsList')}${t('routes.titanHire')}/${domain}`);
          }
        });
      }
    });
  };

  return (
    <>
      <ModalSelectionHostingPlans
        onClose={() => setModalSelectionHostingPlans(false)}
        open={modalSelectionHostingPlans}
        classes={classes}
        domain={domain}
        hostings={hostings}
      />

      <ModalCheckDomainAvailable
        onClose={() => setModalCheckDomainAvailable(false)}
        open={modalCheckDomainAvailable}
        classes={classes}
        domain={domain}
      />
      {pendingMessage && (
      <div className={classes.activeBlockLink}>
        <div className={classes.proEmailActions} data-testid="pendingButton">
          <Typography
            className={classes.manageLinkButton}
            data-testid="manageLinkButton"
          >
            {t('proEmail.managerEmail')}
          </Typography>
        </div>
      </div>
      )}
      {(status === 'suspended') && (
        <div className={classes.activeBlockLink}>
          <div className={classes.proEmailActions} data-testid="pendingButton">
            <Typography
              className={classes.manageLinkButton}
              data-testid="manageLinkButton"
            >
              {t('proEmail.managerEmail')}
            </Typography>
          </div>
        </div>
      )}
      {(status === 'terminated' || status === 'expired') && (
      <div className={classes.proEmailInactiveActions}>
        <Button onClick={handleOpen} className={classes.contractNewPlanLinkButton} data-testid="proEmailContractNewEmailPlanButton">
          {t('proEmail.contractNewEmailPlan')}
        </Button>
      </div>
      )}
    </>
  );
};

InactiveBlock.propTypes = {
  status: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(InactiveBlock));
