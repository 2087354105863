import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 32px 35px 25px 35px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      padding: 32px 45px 0px 24px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    align-self: start;
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    font-family: ${theme.v2.font.family.primary};
    letter-spacing: 0;
    margin: 0;
    opacity: 100%;
    text-align: left;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      max-width: 262px;
    }
  `}
`;

export const DnsWarningWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 11px;
    margin: 24px 0px 32px 0px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: flex-start;
      margin: 24px 0px 50px 0px;
    }
  `}
`;

export const DnsWarningIconWrapper = styled.div`
  align-self: start;
`;

export const DnsWarning = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxs};
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    max-width: 523px;
  `}
`;

export const Action = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content:center;
    div:first-child button{
      margin-right: 32px;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: column;
      gap: 32px;
      div:first-child button{
        margin-right: 0px;
      }
    }
  `}
`;
export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      order: 2;
    }
  `}
`;
