import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class FireBottomLeft extends React.PureComponent {
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={`${className} ${classes.icon}`} viewBox="0 0 376.1 512">
        <g id="Agrupar_1186" transform="matrix(-0.438, 0.899, -0.899, -0.438, 106.257, 19.015)">
          <path
            id="Caminho_2467"
            className={classes.st0}
            d="M61.4-190.2c6.1,0,11-4.9,11-11c0-3.5-1.7-6.8-4.5-8.9c-4.6-3.2-8.8-6.8-12.7-10.9
            c6.1-1.9,11.1-6.5,13.5-12.4c4.5-13.5-8.4-25.7-20-34.9c-4.8-3.8-11.7-2.9-15.5,1.8c-3.8,4.8-2.9,11.7,1.8,15.5l0,0
            c4,2.9,7.6,6.3,10.8,10.1c-6.4,1.8-11.5,6.5-14,12.6c-4.4,13.7,9.2,25.9,23,36C56.8-190.9,59-190.2,61.4-190.2z"
          />
          <path
            id="Caminho_2468"
            className={classes.st1}
            d="M-58-89.8c2.3,0,4.6-0.7,6.5-2.1c13.8-10.1,27.4-22.3,23-36c-2.5-6.1-7.6-10.8-14-12.6
            c3.2-3.7,6.8-7.1,10.8-10.1c4.8-3.8,5.6-10.7,1.8-15.5s-10.7-5.6-15.5-1.8l0,0c-11.7,9.2-24.5,21.4-20,34.9
            c2.4,6,7.3,10.5,13.5,12.4c-3.8,4.1-8.1,7.7-12.7,10.9c-4.9,3.6-6,10.5-2.4,15.4C-64.8-91.4-61.5-89.8-58-89.8z M-44.2-137.8
            L-44.2-137.8z"
          />
          <path
            id="Caminho_2469"
            className={classes.st2}
            d="M3.3-164.2c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C7-155.9,3.3-159.6,3.3-164.2C3.3-164.2,3.3-164.2,3.3-164.2z"
          />
          <path
            id="Caminho_2470"
            className={classes.st0}
            d="M69.2-105.2c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C72.9-97,69.2-100.7,69.2-105.2z"
          />
          <path
            id="Caminho_2471"
            className={classes.st0}
            d="M-9.9-290.2c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C-6.3-282-9.9-285.7-9.9-290.2z"
          />
          <path
            id="Caminho_2472"
            className={classes.st1}
            d="M421.6-190.2c6.1,0,11-4.9,11-11c0-3.5-1.7-6.8-4.5-8.9c-4.6-3.2-8.9-6.8-12.7-10.9
            c6.1-1.9,11.1-6.5,13.5-12.4c4.5-13.5-8.4-25.7-20-34.9c-4.8-3.7-11.7-2.7-15.4,2.1c-3.6,4.7-2.8,11.4,1.8,15.2
            c4,3,7.6,6.3,10.8,10.1c-6.4,1.8-11.5,6.5-14,12.6c-4.4,13.7,9.2,25.9,23,36C417-190.9,419.3-190.2,421.6-190.2z"
          />
          <path
            id="Caminho_2473"
            className={classes.st2}
            d="M302.2-89.8c2.3,0,4.6-0.7,6.5-2.1c13.8-10.1,27.4-22.3,23-36c-2.5-6.1-7.6-10.8-14-12.6
            c3.2-3.7,6.8-7.1,10.8-10.1c4.8-3.8,5.6-10.7,1.8-15.5s-10.7-5.6-15.5-1.8l0,0c-11.7,9.2-24.5,21.4-20,34.9
            c2.4,6,7.3,10.5,13.5,12.4c-3.8,4.1-8.1,7.7-12.7,10.9c-4.9,3.6-6,10.5-2.4,15.4C295.4-91.4,298.7-89.8,302.2-89.8z M316-137.8
            L316-137.8z"
          />
          <path
            id="Caminho_2474"
            className={classes.st0}
            d="M363.6-164.2c0-4.6,3.7-8.2,8.3-8.2s8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C367.3-155.9,363.6-159.6,363.6-164.2C363.6-164.2,363.6-164.2,363.6-164.2z"
          />
          <path
            id="Caminho_2475"
            className={classes.st3}
            d="M237.1-227c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C240.8-218.7,237.1-222.4,237.1-227z"
          />
          <path
            id="Caminho_2476"
            className={classes.st0}
            d="M429.5-105.2c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C433.2-97,429.5-100.7,429.5-105.2z"
          />
          <path
            id="Caminho_2477"
            className={classes.st2}
            d="M350.3-290.2c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C354-282,350.3-285.7,350.3-290.2C350.3-290.2,350.3-290.2,350.3-290.2z"
          />
          <path
            id="Caminho_2478"
            className={classes.st4}
            d="M130.9-282c0-4.6,3.7-8.2,8.3-8.2c4.6,0,8.2,3.7,8.2,8.3c0,4.5-3.7,8.2-8.2,8.2
            C134.6-273.7,130.9-277.4,130.9-282C130.9-282,130.9-282,130.9-282z"
          />
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(FireBottomLeft);
