import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import commonActions from './common.actions';
import { CommonActionTypes } from './common.types';
import { config } from '@/config';
import { isBRServer } from '@/utils/Validators/validation';
import logger from '@/utils/logger';


const { WHMCS_URL } = config;

function* requestServerTime() {
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v3/datetime`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const serverTimeInfos = response && response.data;
    yield put(commonActions.serverTime.set(serverTimeInfos));
  } catch (requestError) {
    const error = { ...requestError, userRequestData: request };
    logger.error('Error sagas common - method requestServerTime', error);
  }
}


function* sagas() {
  yield takeLatest(CommonActionTypes.REQUEST_SERVER_TIME, requestServerTime);
}

export default sagas;
