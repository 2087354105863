const styles = theme => ({
  wrapper: {
    alignItems: 'center',
    backgroundColor: theme.color.geyser,
    color: theme.color.tundora,
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    justifyContent: 'center',
    marginBottom: '58px',
    marginTop: '60px',
    padding: 48,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      padding: 20,
    },
  },
  typography: {
    paddingRight: '50px',
    [theme.breakpoints.down(600)]: {
      paddingRight: '0px',
    },
  },
  title: {
    font: 'normal normal bold 24px/32px Roboto',
    paddingBottom: '20px',
  },
  priceDiscount: {
    fontSize: '30px',
    '& span': {
      color: theme.color.doveGray,
      font: 'normal normal normal 14px/19px Roboto',
      letterSpacing: '0.01px',
    },
  },
  select: {
    backgroundColor: theme.color.white,
    paddingLeft: '6px',
  },
  rcol: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '265px',
    [theme.breakpoints.down(600)]: {
      marginTop: '18px',
      width: '292px',
    },
  },
});

export default styles;
