import {
  REQUEST_CHANGE_PASSWORD, SUCCESS_CHANGE_PASSWORD, ERROR_CHANGE_PASSWORD,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
};

export default function changePassword(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
      };
    case ERROR_CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
