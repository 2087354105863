import { WHMCS_URL } from '@/config/';
import { getCookie } from '@/utils/Application/cookies';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { LOGOUT_URL } from '@/config/whmcsUrls';
import { productsActions } from '@/redux/modules';


export const getURLCpanel = ({
  externalId, params, openInNewTab, dispatch, backup, enableCpanelSSO, setLoadingCpanelSSOProductId, setBlockedWindowUrl,
}) => {
  const cPanelSSO = async (defaultCPanelUrl) => {
    dispatch(productsActions.cPanelSSO.requestLink({
      hostingId: externalId, defaultCPanelUrl, backup, enableCpanelSSO, setLoadingCpanelSSOProductId, setBlockedWindowUrl,
    }));
  };

  const cpaneUrl = params
    ? `${WHMCS_URL}/clientarea.php?action=productdetails&id=${externalId}&dosinglesignon=1${params}`
    : `${WHMCS_URL}/clientarea.php?action=productdetails&id=${externalId}&dosinglesignon=1`;

  const token = getCookie('hg-token');
  const tokenParts = token && token.split('.');
  if (tokenParts) {
    const decoded = atob(tokenParts[1]);
    const expDate = decoded.split('"exp":').pop().replace(/\D+/g, '');
    const sanitizedDate = fromUnixTime(expDate);
    const dateDiff = differenceInSeconds(new Date(sanitizedDate), new Date());

    if (dateDiff <= 0) {
      if (openInNewTab) {
        window.location.href = LOGOUT_URL;
        return;
      }
      return LOGOUT_URL;
    }

    if (openInNewTab) {
      cPanelSSO(cpaneUrl);
      return;
    }

    return cpaneUrl;
  }

  if (openInNewTab) {
    window.location.href = LOGOUT_URL;
    return;
  }
  return LOGOUT_URL;
};
