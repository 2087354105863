const styles = theme => ({
  container: {
    margin: '0 40px 20px',
    [theme.breakpoints.down(960)]: {
      margin: '20px 10px',
    },
  },

  messageButton: {
    color: theme.color.white,
    display: 'inline-block',
    fontSize: '13px',
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '15px',
    padding: 0,
    textAlign: 'left',
    textTransform: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '& a': {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      padding: 0,
      textDecoration: 'underline',
    },
  },

  affectedMessageButton: {
    color: theme.color.tundora,

    '& a': {
      background: 'transparent',
      border: 0,
      color: theme.color.indigo,
      cursor: 'pointer',
      padding: 0,
      textDecoration: 'underline',
    },
  },

  affectedULink: {
    background: 'transparent',
    border: 0,
    color: theme.color.indigo,
    cursor: 'pointer',
    padding: 0,
    textDecoration: 'underline',
  },

  suspendedULink: {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    padding: 0,
    textDecoration: 'underline',
  },
});

export default styles;
