import styled, { css } from 'styled-components';

export const Chip = styled.div`
  ${({
    theme, primaryColor, secondaryColor, variant, weight, uppercase, clickable,
  }) => {
    const textTransform = uppercase ? 'uppercase' : 'capitalized';

    const colorScheme = {};
    let borderRadius = '5px';
    let padding = '5px 8px';

    switch (variant) {
      case 'outlined':
        colorScheme.fontColor = primaryColor;
        colorScheme.borderColor = primaryColor;
        colorScheme.backgroundColor = secondaryColor;
        break;
      case 'straightEdges':
        borderRadius = '0px';
        colorScheme.fontColor = secondaryColor;
        colorScheme.borderColor = primaryColor;
        colorScheme.backgroundColor = primaryColor;
        padding = '2px 8px';
        break;
      default:
        colorScheme.fontColor = secondaryColor;
        colorScheme.borderColor = primaryColor;
        colorScheme.backgroundColor = primaryColor;
        break;
    }

    const fontWeight = theme.font.weights[weight];
    const cursor = clickable ? 'pointer' : 'auto';

    return css`
      align-self: normal;
      background-color: ${colorScheme.backgroundColor};
      border-radius: ${borderRadius};
      border: 1px solid ${colorScheme.borderColor};
      color: ${colorScheme.fontColor};
      cursor: ${cursor};
      display: inline-flex;
      font-size: 12px;
      font-weight: ${fontWeight};
      justify-content: center;
      line-height: 14px;
      padding: ${padding};
      text-transform: ${textTransform};
      white-space: nowrap;
      }
    `;
  }}
`;
