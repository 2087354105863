const styles = theme => ({
  modalContainer: {
    [theme.breakpoints.up(491)]: {
      height: 'inherit',
      marginTop: 70,
    },
  },
  save: {
    minWidth: 95,
    marginLeft: 8,
  },
  passwordFieldWrapper: {
    width: 325,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dialogContent: {
    paddingBottom: 5,
  },
  generatorWrapper: {
    margin: '15px 0',
  },
  generatorText: {
    margin: '26px 0',
    color: theme.color.regentGray,
    fontSize: '12px',
  },
  generatorLink: {
    display: 'contents',
    color: theme.color.indigo,
    fontSize: '12px',
  },
  wrapperContentModal: {
    color: theme.color.tundora,
  },
  modalPaper: {
    borderRadius: 0,
    padding: '10px 16px',
    width: 475,
    [theme.breakpoints.down(491)]: {
      minWidth: '100%',
      padding: 0,
      paddingTop: 10,
    },
  },
  dialogTitle: {
    paddingBottom: 8,
  },
  closeIconButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  dialogActionsContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 6,
    minHeight: 38,
  },
  '@keyframes showChecklist': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  checklistContainer: {
    transition: 'opacity',
    animationName: 'showChecklist',
    animationDuration: '0.3s',
  },
  checklist: {
    listStyle: 'none',
    paddingLeft: '5px',
    margin: '10px 0 0 0',
    '& li': {
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      padding: '3px 0',
    },
  },
  checklistTitle: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.regentGray,
    fontSize: '14px',
    margin: '20px 0 0 0',
  },
  checkIcon: {
    fontSize: '14px',
    marginRight: '6px',
    marginLeft: '-4px',
  },
  lensIcon: {
    fontSize: '6px',
    marginRight: '10px',
  },
  lockIcon: {
    fontSize: '14px',
    marginRight: '7px',
  },
  colorTrue: {
    color: theme.color.tundora,
  },
  colorFalse: {
    color: theme.color.valencia,
  },
});

export default styles;
