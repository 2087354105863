import React from 'react';
import * as Styles from './IconButton.styles';


export default function IconButton({ icon, onClick, testId = 'icon-button' }) {
  return (
    <>
      <Styles.Button onClick={onClick} data-testid={testId}>
        {icon}
      </Styles.Button>
    </>
  );
}
