import React from 'react';
import { useHistory } from 'react-router';
import { Alert } from 'gatorcomponents';
import * as Styles from './NewAlerts.styles';
import useLocale from '@/hooks/useLocale';
import analytics from '@/analytics/domains/domains.analytics';
import { billingAnalytics } from '@/analytics/billing';

import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { ALERT_TYPES, BACKUP_STATUS } from '../../Layout/Menu/MyAlerts/MyAlerts.types';
import { alertsAnalytics } from '@/analytics/alerts';
import { format, parseISO } from 'date-fns';

const NewAlerts = ({
  alertType,
  handleShowModalDnsPropagationAlert,
  handleShowModalDomainNotContracted,
  handleShowModalDomainNotConfigured,
  handleShowModalDomainsInPropagation,
  handleRedirectToSubscriptionManager,
  handleRedirectToSubscriptionAnalysis,
  handleClickOnAlertDomainToPoint,
  handleRedirectToBackupScreen,
  handleShowRefundModal,
  totalInvoice,
  invoiceSum,
  setVisible,
  alert,
  index,
  setAlertAsRead,
  handleDuplicateOrdersClick,
  enabledDiscountOfferBanner = false,
}) => {
  const { alerts: alertsLocale, billing: billingLocale, routes } = useLocale();
  const history = useHistory();

  const backupAlertSuccess = () => alert.backup_type === BACKUP_STATUS.REQUESTED && alert.days_since_last <= 7;

  const backupContent = () => {
    const handleBackup = () => {
      handleRedirectToBackupScreen(alert.hosting_id);
      setAlertAsRead(index);
      setVisible(false);
    };

    const daysToShowInBackupAlert = () => {
      if (alert.days_since_last > 7 && alert.days_since_last <= 15) {
        return 7;
      }
      if (alert.days_since_last > 15 && alert.days_since_last <= 30) {
        return 15;
      }
      if (alert.days_since_last > 30) {
        return 30;
      }
    };

    const planLabel = alert.type && alert.type === ALERT_TYPES.BACKUP && `${alert.plan_name} (${alert.domain})`;

    if (alert.days_since_last <= 7 && alert.backup_type === BACKUP_STATUS.REQUESTED) {
      return {
        title: alertsLocale.backup.backupCreated.title(planLabel),
        description: alertsLocale.backup.backupCreated.description({ handleClick: handleBackup }),
      };
    }

    if (alert.days_since_last > 30 && alert.backup_type === BACKUP_STATUS.REQUESTED) {
      return {
        title: alertsLocale.backup.oldBackup.title(planLabel, daysToShowInBackupAlert()),
        description: alertsLocale.backup.oldBackup.description({ handleClick: handleBackup }),
      };
    }

    if (alert.days_since_last > 7 && alert.days_since_last <= 30 && alert.backup_type === BACKUP_STATUS.REQUESTED) {
      return {
        title: alertsLocale.backup.oldBackup.title(planLabel, daysToShowInBackupAlert()),
        description: alertsLocale.backup.oldBackup.description({
          handleClick: () => {
            handleBackup();
            alertsAnalytics.backupCTAClick();
          },
        }),
      };
    }

    return {
      title: alertsLocale.backup.noBackup.title(planLabel),
      description: alertsLocale.backup.noBackup.description({
        handleClick: () => {
          handleBackup();
          alertsAnalytics.backupCTAClick();
        },
      }),
    };
  };

  const alertNotPropagatedDescription = () => alertsLocale.domainNotPropagated({
    handleClick: () => {
      analytics.dnsWizard.dnsUnpointedDomain();
      handleShowModalDnsPropagationAlert();
      setAlertAsRead(index);
    },
  });

  const alertInPropagationDescription = () => alertsLocale.domainInPropagation({
    handleClick: () => {
      analytics.dnsWizard.dnsPropagationUnderstandTerm();
      handleShowModalDomainsInPropagation();
      setAlertAsRead(index);
    },
  });

  const alertNotContractedDescription = () => alertsLocale.domainNotContracted({
    handleClick: () => {
      analytics.dnsWizard.bannerDomainNotContracted();
      handleShowModalDomainNotContracted();
      setAlertAsRead(index);
    },
  });

  const alertDomainForPointingDescription = () => {
    const domain = alert.domain && alert.domain.length > 15 ? `${alert.domain.slice(0, 14)}...` : alert.domain;

    return alertsLocale.domainForPointing({
      handleClick: () => {
        handleClickOnAlertDomainToPoint(alert.id);
        setAlertAsRead(index);
      },
      domain,
    });
  };

  const alertNotConfiguredDescription = () => alertsLocale.domainNotConfigured({
    handleClick: () => {
      analytics.dnsWizard.buttonHowToConfigure();
      handleShowModalDomainNotConfigured();
      setAlertAsRead(index);
    },
  });

  const alertSubscriptionToDue = () => alertsLocale.subscriptionToDue({
    handleClick: () => {
      handleRedirectToSubscriptionManager(index);
      setAlertAsRead(index);
    },
    daysToDue: alert.daysToDue,
  });

  const alertSubscriptionToCancel = () => alertsLocale.subscriptionToCancel({
    handleClick: () => {
      handleRedirectToSubscriptionManager(index);
      setAlertAsRead(index);
    },

    dueDate: format(parseISO(alert.dueDate), 'dd/MM/yyyy'),
  });

  const refundAlertDescription = () => alertsLocale.refund({
    handleClick: () => {
      handleShowRefundModal(alert);
      setAlertAsRead(index);
    },
    refundMethod: alert && alert.method && billingLocale.paymentMethods[alert.method],
    refundValue: alert && alert.amount,
  });

  const tosAlert = {
    title: alert && alert.tag && alertsLocale.tos[alert.tag] && alertsLocale.tos[alert.tag].title,
    description: alert && alert.tag && alertsLocale.tos[alert.tag] && alertsLocale.tos[alert.tag].description({
      handleClick: () => {
        history.push(routes.help);
        setAlertAsRead(index);
      },
    }),
  };

  const alertDuplicateOrdersDescription = () => alertsLocale.duplicateOrders({
    handleClick: () => {
      setAlertAsRead(index);
      handleDuplicateOrdersClick(true);
      setVisible(false);
    },
  });

  const alertPendingOrderDescription = () => alertsLocale.orderAnalysis[alert.orderStatus] && alertsLocale.orderAnalysis[alert.orderStatus].description({
    productName: alert.productName,
    handleClick: () => {
      alertsAnalytics.pendingOrderClick();
      handleRedirectToSubscriptionAnalysis(alert.productId, alert.productType);
      setAlertAsRead(index);
      setVisible(false);
    },
    domain: alert.productDomain,
  });

  const alertSSL = () => alertsLocale.ssl[alert.status] && alertsLocale.ssl[alert.status]({ domain: alert.domain });

  const alertTitle = () => {
    if (alert.subject) {
      return alert.subject.length > 48 ? `${alert.subject.slice(0, 45)}...` : alert.subject;
    }

    if (alert.description) {
      return alert.description.length > 48 ? `${alert.description.slice(0, 45)}...` : alert.description;
    }
  };

  const callTicketCtaAnalyticEvents = (ticketStatus) => {
    switch (ticketStatus) {
      case ALERT_TYPES.TICKET_OPEN:
        alertsAnalytics.ticketOpenClick();
        break;
      case ALERT_TYPES.TICKET_PENDING:
        alertsAnalytics.ticketWaitingResponseClick();
        break;
      case ALERT_TYPES.TICKET_SOLVED:
        alertsAnalytics.ticketSolvedClick();
        break;
      default:
        alertsAnalytics.ticketNewClick();
    }
  };

  const ticketAlert = {
    title: alert && alertsLocale.tickets && alertsLocale.tickets[alert.type] && `${alertsLocale.tickets[alert.type].title} - ${alertTitle()}`,
    description: alert && alertsLocale.tickets && alertsLocale.tickets[alert.type] && alertsLocale.tickets[alert.type].description({
      handleClick: () => {
        if (history.location.pathname.includes(routes.ticket)) {
          history.push(routes.help);
          setTimeout(() => {
            history.push(`${routes.ticket}/${alert.id}`);
          }, 10);
        } else {
          history.push(`${routes.ticket}/${alert.id}`);
        }

        callTicketCtaAnalyticEvents(alert.type);

        setAlertAsRead(alert.id, 'tickets');
      },
    }),
  };

  const handleInvoicesClick = () => {
    history.push(routes.billing);
    setVisible(false);
    billingAnalytics.invoicesAlertClick();
    setAlertAsRead(index);
  };

  const mountSSLVariant = () => {
    if (alert.status === 'active') {
      return 'success';
    }

    if (alert.status === 'activation_failed') {
      return 'error';
    }

    return 'timed';
  };

  const currencyFormated = formatCurrency(invoiceSum);

  const alertToShow = () => {
    switch (alertType) {
      case ALERT_TYPES.BACKUP:
        return (
          <Alert
            width="full"
            variant={backupAlertSuccess() ? 'success' : 'warning'}
            description={backupContent().description}
            withoutBackground
            title={backupContent().title}
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.INVOICES:
        return (
          <Alert
            width="full"
            variant="warning"
            description={alertsLocale.invoiceStaticMessage({ totalInvoice, sum: currencyFormated, handleClick: handleInvoicesClick })}
            withoutBackground
          />
        );
      case ALERT_TYPES.DUPLICATE_ORDERS:
        return (
          <Alert
            width="full"
            variant="warning"
            description={alertDuplicateOrdersDescription()}
            withoutBackground
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.DOMAIN_NOT_CONTRACTED:
        return (
          <Alert
            width="full"
            variant="error"
            description={alertNotContractedDescription()}
            withoutBackground
          />
        );
      case ALERT_TYPES.DOMAIN_NOT_PROPAGATED:
        return (
          <Alert
            width="full"
            variant="error"
            description={alertNotPropagatedDescription()}
            withoutBackground
          />
        );
      case ALERT_TYPES.DOMAIN_FOR_POINTING:
        return (
          <Alert
            width="full"
            variant="warning"
            description={alertDomainForPointingDescription()}
            withoutBackground
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.DOMAIN_NOT_CONFIGURED:
        return (
          <Alert
            width="full"
            variant="error"
            description={alertNotConfiguredDescription()}
            withoutBackground
          />
        );
      case ALERT_TYPES.SUSBCRIPTION_OVERDUE:
        return (
          <>
            {enabledDiscountOfferBanner && (
              <Alert
                width="full"
                variant={alert.discountEnable ? 'error' : 'warning'}
                description={alert.discountEnable ? alertSubscriptionToCancel() : alertSubscriptionToDue()}
                withoutBackground
              />
            )}
            {!enabledDiscountOfferBanner && (
              <Alert
                width="full"
                variant="warning"
                description={alertSubscriptionToDue()}
                withoutBackground
              />
            )}
          </>
        );
      case ALERT_TYPES.DOMAIN_IN_PROGRESS:
        return (
          <Alert
            width="full"
            variant="timed"
            description={alertInPropagationDescription()}
            withoutBackground
          />
        );
      case ALERT_TYPES.REFUND:
        return (
          <Alert
            width="full"
            variant="success"
            description={refundAlertDescription()}
            withoutBackground
            customIcon="IconDanger"
          />
        );
      case ALERT_TYPES.TOS:
        return (
          <Alert
            width="full"
            variant="error"
            description={tosAlert.description}
            withoutBackground
            title={tosAlert.title}
          />
        );
      case ALERT_TYPES.TICKET_NEW:
      case ALERT_TYPES.TICKET_OPEN:
        return (
          <Alert
            width="full"
            variant="warning"
            description={ticketAlert.description}
            withoutBackground
            title={ticketAlert.title}
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.TICKET_PENDING:
        return (
          <Alert
            width="full"
            variant="timed"
            description={ticketAlert.description}
            withoutBackground
            title={ticketAlert.title}
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.TICKET_SOLVED:
        return (
          <Alert
            width="full"
            variant="success"
            description={ticketAlert.description}
            withoutBackground
            title={ticketAlert.title}
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.PENDING_ORDER:
        return (
          <Alert
            width="full"
            variant={alert.orderStatus === 'cancelled' ? 'error' : 'timed'}
            description={alertPendingOrderDescription()}
            withoutBackground
            customIcon="IconInfo"
          />
        );
      case ALERT_TYPES.SSL:
        return (
          <Alert
            width="full"
            variant={mountSSLVariant()}
            description={alertSSL()}
            withoutBackground
          />
        );
      default:
        return (<></>);
    }
  };

  return (
    <Styles.Container data-testid={`${alertType}-alert`}>
      {alertToShow()}
    </Styles.Container>
  );
};

export default NewAlerts;
