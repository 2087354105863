import React from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';


const ModalReactivateAutomaticSubscription = ({
  onClose, open, classes, t, handleActivateSubscription,
  title,
  loading,
  description,
  confirmLabel,
}) => {
  const isMobile = window.innerWidth < 700;

  const executeClick = () => {
    handleActivateSubscription();
    onClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      aria-labelledby="responsive-dialog-title"
      open={open}
    >
      <div id="dialog-title" className={classes.dialogTitle}>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.titleDialog} data-testid="modalTitle">{title || t('proEmail.titleConfirmRenovation')}</Typography>
        <Typography className={classes.descriptionDialog} data-testid="modalDescription">{description || t('proEmail.descriptionConfirmRenovation')}</Typography>
        <Button target="_blank" onClick={executeClick} className={classes.checkAvailabilityPlanLinkButton} data-testid="linkButton">
          {!loading && (confirmLabel || t('proEmail.bottonConfirmRenovation'))}
          {loading && (
          <div className={classes.wrapper}>
            <CircularProgress size={20} color="#FFFFFF" data-testid="buttonCircularProgress" />
          </div>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalReactivateAutomaticSubscription));
