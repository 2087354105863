export default {
  title: 'Confirme su correo electrónico',
  subTitleWithEmail: '<0>Para su seguridad, le enviamos un enlace a </0> <1>{{ email }}</1><2>. Acceda a su correo electrónico para finalizar el procedimiento.</2>',
  subtitle: 'Verifica en tu correo electrónico el link de verificación que acabamos de enviar.',
  emailNotRecived: '¿No ha recibido el correo electrónico?',
  resendText: 'Volver a enviar en ',
  passwordDescription: 'Introduzca la contraseña de acceso al Portal del Cliente. Esta información es necesaria para su seguridad.',
  changeEmail: {
    title: 'Cambie su correo electrónico',
    subtitle: 'Atención, solo se guardará después de enviar el correo electrónico de confirmación y validación.',
    placeholder: 'Ingrese aquí su nuevo correo electrónico.',
    text: 'Tu correo electrónico de registro será actualizado por este nuevo correo electrónico.',
    hint: 'Consejo: Usar un correo electrónico personal (por ejemplo, "@gmail.com"), garantiza que siempre tendrá acceso a su cuenta en HostGator, independientemente del estado financiero de sus productos.',
  },
  errors: {
    invalidEmail: 'Introduzca un correo electrónico válido',
    requiredEmail: 'Campo obligatorio',
    passwordMinimumCharacters: 'La contraseña debe tener un mínimo de 6 caracteres.',
  },
  buttons: {
    cancel: 'Volver',
    change: 'Validar correo electrónico',
    send: 'Reenviar correo electrónico',
    sendLoading: 'Enviando...',
    changeEmail: 'Cambiar correo electrónico',
  },
  feedback: {
    noMoreAttempts: () => 'Ha agotado las solicitudes de confirmación, inténtalo de nuevo mañana',
    emailSendAttempts: ({ attempts, used }) => `Ha solicitado ${used} correo${used > 1 ? 's' : ''} electrónico${used > 1 ? 's' : ''} en las últimas 24 horas, quedan ${attempts} solicitudes`,
    alreadyValidated: ({ hours, date }) => `Correo electrónico confirmado hace menos de 24 horas, puede cambiar este correo electrónico a las ${hours} horas del día ${date}.`,
    resendSuccess: 'Reenviamos. Por favor, verifica tu correo electrónico nuevamente.',
    resendError: 'No fue posible realizar el reenvío. Por favor, cambia tu correo o intenta nuevamente más tarde',
    changeSuccess: 'Compruebe su correo electrónico y haga clic en el enlace de confirmación.',
    changeError: 'Inténtelo de nuevo más tarde o cambie el correo electrónico.',
    password: 'Contraseña incorrecta. Por favor, intente de nuevo.',
    dataInUse: 'El correo electrónico informado ya está en uso en su registro. Para continuar, informe otro correo electrónico.',
    emailInDataBase: '¡Ups! Este correo electrónico ya está registrado. Por favor, elija una nueva dirección de correo electrónico.',
  },
};
