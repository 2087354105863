import React from 'react';
import { SubscriptionActiveRenewalModalProps } from '../SubscriptionActiveRenewalModal/SubscriptionActiveRenewalModal.types';
import * as Styles from './SubscriptionRenewalModalData.styles';
import { DiscountBlock } from '../../DiscountBlock';
import { DiscountType } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';

const SubscriptionRenewalModalData = ({
  installmentsAmountLabel = 'Installments amount',
  loading = false,
  nextRenovationDateLabel = 'Next renovation',
  nextRenovationDateText = '',
  paymentMethodLabel = 'Payment method',
  productLabel = 'Product',
  paymentMethodText = '',
  renovationAmountText = '',
  productNameText = '',
  enabledPaymentInfo = true,
  discount,
  paymentCycleLabel = 'Renovation Cycle',
  paymentCycleText = '',
  enabledDiscountOfferBanner,
}) => (
  <Styles.Wrapper data-testid="subscription-renewaldata">

    <Styles.Content>
      <Styles.Item>
        <Styles.Label data-testid="subscription-renewaldata-product-name-label">
          {productLabel}
        </Styles.Label>
        <Styles.Text data-testid="subscription-renewaldata-product-name-text">
          {(loading || !productNameText) ? '...' : productNameText}
        </Styles.Text>
      </Styles.Item>
      <Styles.Item>
        <Styles.Label data-testid="subscription-renewaldata-paymentcycle-label">
          {paymentCycleLabel}
        </Styles.Label>
        <Styles.Text data-testid="subscription-renewaldata-paymentcycle-text">
          {(loading || !paymentCycleText) ? '...' : paymentCycleText}
        </Styles.Text>
      </Styles.Item>
      <Styles.Item>
        <Styles.Label data-testid="subscription-renewaldata-nextrenovationdate-label">
          {nextRenovationDateLabel}
        </Styles.Label>
        <Styles.Text data-testid="subscription-renewaldata-nextrenovationdate-text">
          {(loading || !nextRenovationDateText) ? '...' : nextRenovationDateText}
        </Styles.Text>
      </Styles.Item>
    </Styles.Content>
    {enabledPaymentInfo && (
      <Styles.Content>
        <Styles.Item>
          <Styles.Label data-testid="subscription-renewaldata-paymentmethod-label">
            {paymentMethodLabel}
            {enabledPaymentInfo === true}
          </Styles.Label>
          <Styles.Text data-testid="subscription-renewaldata-paymentmethod-text">
            {(loading || !paymentMethodText) ? '...' : paymentMethodText}
          </Styles.Text>
        </Styles.Item>
      </Styles.Content>
    )}

    {(enabledPaymentInfo || (discount && discount.type !== DiscountType.RENEWAL)) && (
      <Styles.Content>
        <Styles.Item>
          <Styles.Label data-testid="subscription-renewaldata-label">
            {installmentsAmountLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-renewaldata-text">
            <Styles.LineThrough discount={false}>
              {(loading || !renovationAmountText) ? '...' : renovationAmountText}
            </Styles.LineThrough>
          </Styles.Text>
        </Styles.Item>
      </Styles.Content>
    )}

    {((discount && discount.type === DiscountType.RENEWAL)) && enabledDiscountOfferBanner && (
      <DiscountBlock
        discount={discount}
        loading={loading}
        renovationAmountText={renovationAmountText}
        testId="subscription-renewaldata"
      />
    )}
  </Styles.Wrapper>
);

SubscriptionRenewalModalData.propTypes = SubscriptionActiveRenewalModalProps;


export default SubscriptionRenewalModalData;
