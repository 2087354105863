/* eslint-disable no-unused-vars */
import React from 'react';
import * as Style from './IconComment.style';

const IconComment = ({
  color = '#4A4A4A', backgroundColor = '#35CF8B', testId = 'icon-comment', size = 24,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.26904 3.40039L18.938 16.1094L3.31304 16.2504L3.26904 3.40039Z" fill={backgroundColor} />
      <path d="M21.99 4C21.99 3.4713 21.7807 2.9641 21.4078 2.58931C21.0348 2.21452 20.5287 2.00264 20 2H4C3.47005 2.00158 2.96227 2.2128 2.58753 2.58753C2.2128 2.96227 2.00158 3.47005 2 4V16C2.00158 16.5299 2.2128 17.0377 2.58753 17.4125C2.96227 17.7872 3.47005 17.9984 4 18H18L22 22L21.99 4ZM20 4V17.17L18.83 16H4V4H20ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z" fill={color} />
    </svg>

  </Style.Wrapper>
);


export default IconComment;
