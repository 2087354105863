import {
  Button, Radio, Select, Skeleton,
} from 'gatorcomponents';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './SupportForms.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import { useSupportForms } from '@/hooks/supportForms';
import { useHistory } from 'react-router';
import { InternalMigration } from './components/InternalMigration/InternalMigration';
import { UserBackupRestore } from './components/UserBackupRestore/UserBackupRestore';
import { GenerateBackup } from './components/GenerateBackup/GenerateBackup';
import { HgProducts } from './SupportForms.types';
import { useDispatch, useSelector } from 'react-redux';
import { formsActions } from '@/redux/modules';
import { Modal } from '@/pages/common';
import { RestorateBackupHgRoutine } from './components/RestorateBackupHgRoutine';
import { WithoutEmailAccess } from './components/WithoutEmailAccess';
import { ChangePrimaryDomain } from './components/ChangePrimaryDomain';
import { ExternalMigration } from './components/ExternalMigration';
import { ChangeDueDate } from './components/ChangeDueDate';
import { GoogleAdwordsCoupon } from './components/GoogleAdwordsCoupon';
import { InstallExternalSsl } from './components/InstallExternalSsl';
import { FinancialDoubts } from './components/GeneralDoubts';
import { RestorateCancelledPlan } from './components/RestorateCancelledPlan';
import { GenerateCSR } from './components/GenerateCSR';
import { RestorateSiteBuilderAccess } from './components/RestorateSiteBuilderAccess';
import { AffiliateProgram } from './components/AffiliateProgram';
import { NationalDomainTransfer } from './components/NationalDomainTransfer';
import { UpgradeVps } from './components/UpgradeVps';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_ZENDESK_V2 } from '@/config/GrowthBook/constants';
import { IS_PROD } from '@/config';
import { SiteLockUpgrade } from './components/SiteLockUpgrade';
import { CodeGuardUpgrade } from './components/CodeGuardUpgrade';
import { ChangePaymentcycle } from './components/ChangePaymentcycle';
import { PrivateSslCertificate } from './components/PrivateSslCertificate';
import { WildcardSslCertificate } from './components/WildcardSslCertificate';
import { EnterpriseSslCertificate } from './components/EnterpriseSslCertificate';
import { InstallWhmcs } from './components/InstallWhmcs';
import { ReloadRebuild } from './components/ReloadRebuild';
import { MoreEmails } from './components/MoreEmails';
import { MigrateVpsDedicated } from './components/MigrateVpsDedicated';
import { MigrateAccountVpsDedicated } from './components/MigrateAccountVpsDedicated';
import { UpdateWhmcsLicense } from './components/UpdateWhmcsLicense';
import { WhmcsAcquisition } from './components/WhmcsAcquisition';
import { MigrateResellerBr } from './components/MigrateResellerBr';
import { isBRServer } from '@/utils/Validators/validation';
import { MigrateResellerEs } from './components/MigrateResellerEs';

const SupportForms = () => {
  const { ticket: ticketsLocale, routes: routesLocale } = useLocale();
  const history = useHistory();
  const dispatch = useDispatch();
  const [succesModal, setSuccesModal] = useState(false);
  const [opening, setOpening] = useState(false);
  const clientInfo = useSelector(state => state.summary);
  const useZendeskV2 = useFeatureIsOn(CHARLIE_ZENDESK_V2);

  const {
    forceState, rules,
  } = useSelector(state => state.formsReducer);

  const {
    requestForms,
    productsOptions,
    loading,
    checkProduct,
    selectedProductOption,
    selectedProduct,
    checkNeed,
    checkSector,
    handleSelectedProductOption,
    setSelectedSector,
    selectedSector,
    selectedNeed,
    needsOptions,
    setSelectedNeed,
    activeProductsOptions,
    setSelectedProductData,
    requestedProducts,
    selectedProductData,
  } = useSupportForms({
  });

  const setForcedState = useCallback(() => {
    if (
      forceState
      && handleSelectedProductOption
      && productsOptions.length
      && rules
      && rules.active
    ) {
      const ruleProduct = rules.selectedProduct || rules.active;
      const selectedOption = productsOptions.find((option) => {
        if (ruleProduct.id) {
          return option.value === ruleProduct.id;
        }
        return option.label === ruleProduct;
      });
      const fullSelectedProductData = requestedProducts.find(product => product.id === ruleProduct.id);

      setSelectedProductData(fullSelectedProductData);
      if (selectedOption) {
        handleSelectedProductOption(selectedOption.label);
      }
      setSelectedSector(rules.selectedSector);
      setTimeout(() => {
        setSelectedNeed(rules.selectedNeed);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forceState,
    rules,
    rules.active,
    rules.restorateBackupHgRoutine,
    setSelectedSector,
    handleSelectedProductOption,
    productsOptions,
    setSelectedProductData,
    setSelectedNeed,
    requestedProducts,
  ]);

  useEffect(() => {
    setForcedState();
  }, [setForcedState]);

  useEffect(() => {
    requestForms();
  }, [requestForms]);

  useEffect(() => {
    checkProduct();
  }, [checkProduct]);

  useEffect(() => {
    checkSector();
  }, [checkSector]);

  useEffect(() => {
    checkNeed();
  }, [checkNeed]);

  const addNewZendeskData = useCallback((data) => {
    const newData = { ...data };
    if (selectedSector === 'technical') {
      newData.domain_id = '27835972869267';
      newData.server_id = '27836218257939';
      newData.plan_id = '28285611660947';
    }

    if (selectedSector !== 'technical') {
      newData.domain_id = '27835972869267';
      newData.server_id = '28285611660947';
    }

    newData.server_value = selectedProductData ? selectedProductData.serverhostname : '';
    newData.domain_value = selectedProductData ? selectedProductData.domain : '';
    newData.plan_value = selectedProductData ? selectedProductData.name : '';

    switch (data.key) {
      case V2Forms.INTERNAL_MIGRATION:
        newData.group_id = IS_PROD ? '27293098116243' : '24966089236755';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_migracao';
        newData.sub_category_id = '28254219203219';
        newData.sub_category_value = 'hg_migracao_interna_cliente_existente';
        break;
      case V2Forms.EXTERNAL_MIGRATION:
        newData.group_id = '27293098116243';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_migracao';
        newData.sub_category_id = '28254219203219';
        newData.sub_category_value = 'hg_migracao_externa_novo_cliente';
        break;
      case V2Forms.USER_BACKUP_RESTORE:
        newData.group_id = '27292926799507';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_restauracao_de_backup';
        newData.sub_category_id = '28253974154131';
        newData.sub_category_value = 'hg_restauracao_de_backup';
        break;
      case V2Forms.RESTORATE_HG_ROUTINE:
        newData.group_id = '27292926799507';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_restauracao_de_backup';
        newData.sub_category_id = '28253974154131';
        newData.sub_category_value = 'hg_restauracao_de_backup';
        break;
      case V2Forms.WITHOUT_EMAIL_ACCESS:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_recuperacao_de_acesso_e-mail';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.CHANGE_PRIMARY_DOMAIN:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_dominios';
        newData.sub_category_id = '29794347165715';
        newData.sub_category_value = 'hg_dominios_alteracao_dominio_primario';
        break;
      case V2Forms.CHANGE_DUE_DATE:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_alteracao_data_de_vencimento';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.GOOGLE_ADWORDS_COUPON:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_google_adwords';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.EXTERNAL_SSL:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_instalacao_de_ssl';
        newData.sub_category_id = '28254262891667';
        newData.sub_category_value = 'hg_instalacao_de_ssl';
        break;
      case V2Forms.FINANCIAL_DOUBTS:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_outros_financeiro';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.RESTORATE_CANCELLED_PLAN:
        newData.group_id = '27292926799507';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_restauracao_de_backup';
        newData.sub_category_id = '28253974154131';
        newData.sub_category_value = 'hg_restauracao_de_backup';
        break;
      case V2Forms.GENERATE_CSR:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_instalacao_de_ssl';
        newData.sub_category_id = '28254262891667';
        newData.sub_category_value = 'hg_csr_ssl';
        break;
      case V2Forms.RESTORATE_SITEBUILDER_ACCESS:
        newData.group_id = '23450116897812';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_restauracao_de_backup';
        newData.sub_category_id = '28253974154131';
        newData.sub_category_value = 'hg_restauracao_acesso_criador_de_sites';
        break;
      case V2Forms.AFFILIATE_PROGRAM:
        newData.group_id = '27292861425427';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_afiliados';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.NATIONAL_DOMAIN_TRANSFER:
        newData.group_id = '27911666087059';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_transferencia_de_dominio_nacional';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.UPGRADE_VPS:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_upgrade/downgrade';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.UPGRADE_SITELOCK:
        newData.group_id = '27293010048019';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_upgrade/downgrade';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.UPGRADE_CODEGUARD:
        newData.group_id = '27293010048019';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_upgrade/downgrade';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.CHANGE_DUE_DATE3:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_alteracao_data_de_vencimento';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.CHANGE_PAYMENT_CYCLE:
        newData.group_id = IS_PROD ? '27293010048019' : '24966089236755';
        newData.category_id = '28250390746387';
        newData.category_value = 'hg_alteracao_ciclo_de_pagamento';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.PRIVATE_SSL:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_instalacao_de_ssl';
        newData.sub_category_id = '28254262891667';
        newData.sub_category_value = 'hg_instalacao_de_ssl';
        break;
      case V2Forms.WILDCARD_SSL:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_instalacao_de_ssl';
        newData.sub_category_id = '28254262891667';
        newData.sub_category_value = 'hg_instalacao_de_ssl';
        break;
      case V2Forms.ENTERPRISE_SSL:
        newData.group_id = '23450116897811';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_instalacao_de_ssl';
        newData.sub_category_id = '28254262891667';
        newData.sub_category_value = 'hg_instalacao_de_ssl';
        break;
      case V2Forms.INSTALL_WHMCS:
        newData.group_id = '27292955547155';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_whmcs';
        newData.sub_category_id = '28254313912211';
        newData.sub_category_value = 'hg_outros_whmcs';
        break;
      case V2Forms.RELOAD_REBUILD:
        newData.group_id = '27292955547155';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_reload_rebuild';
        newData.sub_category_id = '';
        newData.sub_category_value = '';
        break;
      case V2Forms.MIGRATE_VPS_DEDICATED:
        newData.group_id = IS_PROD ? '27293098116243' : '24966089236755';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_migracao';
        newData.sub_category_id = '28254219203219';
        newData.sub_category_value = 'hg_migracao_vps_dedicado';
        break;
      case V2Forms.MIGRATE_ACCOUNT_VPS_DEDICATED:
        newData.group_id = IS_PROD ? '27293098116243' : '24966089236755';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_migracao';
        newData.sub_category_id = '28254219203219';
        newData.sub_category_value = 'hg_migracao_vps_dedicado';
        break;
      case V2Forms.UPDATE_WHMCS_LICENSE:
        newData.group_id = '27292955547155';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_whmcs';
        newData.sub_category_id = '28254313912211';
        newData.sub_category_value = 'hg_solicitar_licenca';
        break;
      case V2Forms.WHMCS_ACQUISITION:
        newData.group_id = '27292955547155';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_whmcs';
        newData.sub_category_id = '28254313912211';
        newData.sub_category_value = 'hg_solicitar_licenca';
        break;
      case V2Forms.MIGRATE_RESELLER:
        newData.group_id = IS_PROD ? '27293098116243' : '24966089236755';
        newData.category_id = '28251033477139';
        newData.category_value = 'hg_migracao';
        newData.sub_category_id = '28254219203219';
        newData.sub_category_value = 'hg_migracao_revenda';
        break;
      default:
        break;
    }

    return newData;
  }, [selectedSector, selectedProductData]);

  const submitForm = useCallback((data) => {
    setOpening(true);
    const newData = useZendeskV2 ? addNewZendeskData(data) : data;
    const onSuccess = () => {
      setSuccesModal(true);
      setOpening(false);
    };

    newData.onSuccess = onSuccess;
    dispatch(formsActions.send.request({ data: newData, useZendeskV2 }));
  }, [dispatch, useZendeskV2, addNewZendeskData]);

  const getInternalMigrationProps = useCallback(() => {
    let destinationProductsOptions = [];

    if (activeProductsOptions) {
      destinationProductsOptions = [...activeProductsOptions].map((product) => {
        const newProduct = { ...product };
        newProduct.label = `${newProduct.name} - ${newProduct.domain}`;
        return newProduct;
      });

      destinationProductsOptions = [...destinationProductsOptions].filter((product) => {
        const isHosting = HgProducts.HOSTING_ALL.includes(product.pid);
        return product.label !== selectedProductOption && isHosting;
      });
    }

    const defaultProps = {
      selectedProduct,
      selectedProductOption,
      destinationProductsOptions,
      submitForm: e => submitForm(e),
      opening,
    };

    return defaultProps;
  }, [selectedProduct, activeProductsOptions, selectedProductOption, submitForm, opening]);

  const getUserBackupRestoreProps = useCallback(() => {
    let destinationProductsOptions = [];

    if (activeProductsOptions) {
      destinationProductsOptions = [...activeProductsOptions].map((product) => {
        const newProduct = { ...product };
        newProduct.label = `${newProduct.name} - ${newProduct.domain}`;
        return newProduct;
      });

      destinationProductsOptions = [...destinationProductsOptions].filter((product) => {
        const isHosting = HgProducts.HOSTING_ALL.includes(product.pid);
        return product.label !== selectedProductOption && isHosting;
      });
    }

    const defaultProps = {
      selectedProduct,
      selectedProductOption,
      destinationProductsOptions,
      submitForm: e => submitForm(e),
      opening,
    };

    return defaultProps;
  }, [selectedProduct, activeProductsOptions, selectedProductOption, submitForm, opening]);

  const getWithoutEmailAccess = useCallback(() => {
    const defaultProps = {
      submitForm: e => submitForm(e),
      email: clientInfo && clientInfo.email,
      document: clientInfo && clientInfo.document,
      opening,
    };

    return defaultProps;
  }, [
    submitForm,
    clientInfo,
    opening,
  ]);

  const getGenerateCSRProps = useCallback(() => {
    const customerSummary = {
      name: clientInfo.name,
      email: clientInfo.email,
      city: clientInfo.city,
      state: clientInfo.state,
      country: clientInfo.country,
      company: clientInfo.companyname,
    };

    const defaultProps = {
      submitForm: e => submitForm(e),
      selectedProduct,
      customerSummary,
      opening,
    };

    return defaultProps;
  }, [selectedProduct, clientInfo, opening, submitForm]);

  const getNationalDomainTransferProps = useCallback(() => {
    const defaultProps = {
      submitForm,
      opening,
    };

    return {
      ...defaultProps,
    };
  }, [submitForm, opening]);

  const getDefaultFormProps = useCallback(() => {
    const defaultProps = {
      submitForm,
      selectedProduct,
      opening,
    };

    return {
      ...defaultProps,
    };
  }, [
    submitForm,
    selectedProduct,
    opening,
  ]);

  const onCloseModal = useCallback(() => history.push(routesLocale.help), [history, routesLocale]);

  const getModalProps = () => ({
    onClose: onCloseModal,
  });

  if (loading) {
    return (
      <Styles.PageContainer data-testid="loaderContainer">
        <Styles.SkeletonWrapper data-testid="loader">
          <Skeleton variant="headline" width="380px" />
          <Skeleton variant="input" width="498px" />
        </Styles.SkeletonWrapper>
      </Styles.PageContainer>
    );
  }

  const renderForm = {
    [ticketsLocale.forms.names.internalMigration]: <InternalMigration {...getInternalMigrationProps()} />,
    [ticketsLocale.forms.names.userBackupRestore]: <UserBackupRestore {...getUserBackupRestoreProps()} />,
    [ticketsLocale.forms.names.restorateBackupHgRoutine]: <RestorateBackupHgRoutine {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.restorateCancelledPlan]: <RestorateCancelledPlan {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.generateBackup]: <GenerateBackup {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.withoutEmailAccess]: <WithoutEmailAccess {...getWithoutEmailAccess()} />,
    [ticketsLocale.forms.names.changePrimaryDomain]: <ChangePrimaryDomain {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.changeDueDate]: <ChangeDueDate {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.googleAdwordsCoupon]: <GoogleAdwordsCoupon {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.externalMigration]: <ExternalMigration {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.installExternalSsl]: <InstallExternalSsl {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.financialDoubts]: <FinancialDoubts {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.generateCSR]: <GenerateCSR {...getGenerateCSRProps()} />,
    [ticketsLocale.forms.names.restorateSiteBuilderAccess]: <RestorateSiteBuilderAccess {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.affiliateProgram]: <AffiliateProgram {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.nationalDomainTransfer]: <NationalDomainTransfer {...getNationalDomainTransferProps()} />,
    [ticketsLocale.forms.names.upgradeVps]: <UpgradeVps {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.upgradeSiteLock]: <SiteLockUpgrade {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.upgradeCodeGuard]: <CodeGuardUpgrade {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.ChangePaymentcycle]: <ChangePaymentcycle {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.privateSslCertificate]: <PrivateSslCertificate {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.widlcardSslCertificate]: <WildcardSslCertificate {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.enterPrisessslCertificate]: <EnterpriseSslCertificate {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.installWhmcs]: <InstallWhmcs {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.reloadRebuild]: <ReloadRebuild {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.moreEmails]: <MoreEmails {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.migrateVpsDedicated]: <MigrateVpsDedicated {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.migrateAccountVpsDedicated]: <MigrateAccountVpsDedicated {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.updateWhmcsLicense]: <UpdateWhmcsLicense {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.whmcsAcquisition]: <WhmcsAcquisition {...getDefaultFormProps()} />,
    [ticketsLocale.forms.names.migrateReseller]: isBRServer
      ? <MigrateResellerBr {...getDefaultFormProps()} />
      : <MigrateResellerEs {...getDefaultFormProps()} />,
  };

  return (
    <>
      {succesModal && (
        <Modal {...getModalProps()}>
          <Styles.Title>{ticketsLocale.forms.successModal.title}</Styles.Title>
          <Styles.Description>
            {ticketsLocale.forms.successModal.description}
          </Styles.Description>
          <Styles.ButtonWrapper>
            <Button
              label={ticketsLocale.forms.successModal.closeLabel}
              onClick={onCloseModal}
            />
          </Styles.ButtonWrapper>
        </Modal>
      )}
      <Styles.PageContainer>
        <Styles.CreateTicketWrapper selectedProduct={!!selectedProduct}>
          <Styles.Title>{ticketsLocale.openNewTicketPage.title}</Styles.Title>
          <Styles.SelectProductWrapper>
            <Select
              label={selectedProductOption ? ticketsLocale.openNewTicketPage.selecteProductOptionPlaceholder : ''}
              placeholder={ticketsLocale.openNewTicketPage.selecteProductOptionPlaceholder}
              options={productsOptions}
              value={selectedProductOption}
              setValue={label => handleSelectedProductOption(label)}
              testId="selectProduct"
              withScroll
            />
          </Styles.SelectProductWrapper>

          {selectedProduct && (
            <Styles.RadioSection>
              <Styles.Label>{ticketsLocale.openNewTicketPage.selecteSectorOptionPlaceholder}</Styles.Label>
              <Styles.RadioWrapper>
                <Styles.RadioLabel htmlFor="#billing" onClick={() => setSelectedSector('billing')} data-testId="billingRadio">
                  <Radio checked={selectedSector === 'billing'} id="billing" />
                  {ticketsLocale.openNewTicketPage.sectorBilling}
                </Styles.RadioLabel>

                {selectedProduct && selectedProduct.active && (
                  <Styles.RadioLabel htmlFor="#technical" onClick={() => setSelectedSector('technical')} data-testid="technicalRadio">
                    <Radio checked={selectedSector === 'technical'} id="technical" />
                    {ticketsLocale.openNewTicketPage.sectorTechnical}
                  </Styles.RadioLabel>
                )}
              </Styles.RadioWrapper>
            </Styles.RadioSection>
          )}

          {selectedSector && (
            <Styles.SelectNeedWrapper>
              <Select
                label={selectedNeed ? ticketsLocale.openNewTicketPage.selecteNeedOptionPlaceholder : ''}
                placeholder={ticketsLocale.openNewTicketPage.selecteNeedOptionPlaceholder}
                options={needsOptions}
                value={selectedNeed}
                setValue={setSelectedNeed}
                withScroll
              />
            </Styles.SelectNeedWrapper>
          )}

          {renderForm[selectedNeed || selectedProductOption]}
        </Styles.CreateTicketWrapper>
      </Styles.PageContainer>
    </>
  );
};

export default SupportForms;
