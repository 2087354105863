import {
  call,
  takeLatest, put,
  select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import productsActions from './products.actions';
import { COUNTRY, CACHED_AWS_GATOR_SOLUTIONS_URL } from '@/config';
import { authActions } from '@/redux/modules/auth';
import { ProductsActionTypes } from './products.types';
import productsAssembly from './products.assembly';
import { isBRServer } from '@/utils/Validators/validation';
import { locale } from '@/utils/locale';
import {
  identify as identifyAmplitude, Identify as IdentifyObject,
} from '@amplitude/analytics-browser';
import { backupActions } from '../backup';
import { preChatActions } from '../preChat';
import { PRODUCTS_URL } from '@/config/api';
import logger from '@/utils/logger';


const getHomeRouteFromActiveProduct = ({ products }) => {
  const hasProduct = productType => products.find(product => product.category === productType && product.has_product);

  if (products) {
    const identifyObject = new IdentifyObject();
    const ownedProducts = {
      shared: !!hasProduct('shared'),
      wordpress: !!hasProduct('wordpress'),
      sitebuilder: !!hasProduct('sitebuilder'),
      reseller: !!hasProduct('reseller'),
      domain: !!hasProduct('domain'),
      proemail: !!hasProduct('proemail'),
      dedicated: !!hasProduct('dedicated'),
      sitelock: !!hasProduct('sitelock'),
      codeguard: !!hasProduct('codeguard'),
      academy: !!hasProduct('academy'),
    };
    identifyObject.set('products', ownedProducts);
    identifyAmplitude(identifyObject);
  }

  const routes = {
    courses: locale('routes.courses'),
    domains: locale('routes.domains'),
    emailsList: locale('routes.emailsList'),
    products: locale('routes.products'),
    security: locale('routes.security'),
    sites: locale('routes.sitesPage'),
  };

  if (hasProduct('shared') || hasProduct('wordpress') || hasProduct('sitebuilder') || hasProduct('reseller')) {
    return routes.sites;
  }

  if (hasProduct('domain')) {
    return routes.domains;
  }

  if (hasProduct('proemail')) {
    return routes.emailsList;
  }

  if (hasProduct('dedicated')) {
    return routes.products;
  }

  if (hasProduct('sitelock') || hasProduct('codeguard')) {
    return routes.security;
  }

  if (hasProduct('academy')) {
    return routes.courses;
  }

  return routes.sites;
};

function* getProductsPerCategoryRequest(action) {
  const { history, withGatorSolutions } = action.payload;

  yield put(authActions.tokens.jwt.update());
  const { jwt: jwtToken, token } = yield select(state => state.auth);

  const { id: userId } = yield select(state => state.summary);
  const brand = isBRServer ? 'br' : 'es';

  let request = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: '/client/products/category',
  };

  if (withGatorSolutions) {
    request = {
      url: `${CACHED_AWS_GATOR_SOLUTIONS_URL}/client/products/category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-region': brand,
        'X-Client-ID': userId,
      },
    };
  }

  try {
    const response = yield call(api.aws.get, request);

    yield put(productsActions.products.set(response.data || []));
    yield put(productsActions.products.success());

    const homePage = getHomeRouteFromActiveProduct({ products: response.data });
    if (history) {
      history.push(homePage);
    }
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getProductsPerCategoryRequest', error);
    yield put(productsActions.products.failure());
  }
}

function* requestAllProducts() {
  yield put(authActions.tokens.jwt.update());
  const { token } = yield select(state => state.auth);

  const request = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: PRODUCTS_URL,
  };

  try {
    const response = yield call(api.whmcs.get, request);
    yield put(productsActions.products.setAllProductsList(response.data.data.data));
    yield put(productsActions.products.setRequestAllSuccess());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas requestAllProducts', error);
    yield put(productsActions.products.setRequestAllFailure());
  }
}

function* handleSetProductVisualized({ payload }) {
  const { productId, hostingId } = payload;
  const { token } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);

  const request = {
    headers: {
      Authorization: token,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: `api/v3/product/${productId}/hosting/${hostingId}/change-visualized`,
  };

  try {
    yield call(api.whmcs.put, request);
    yield put(productsActions.productsVisualized.success());
  } catch (e) {
    yield put(productsActions.productsVisualized.failure());
  }
}

function* cPanelSSO({ payload }) {
  const {
    defaultCPanelUrl, hostingId, setLoadingWithId, backup, backupWizard, enableCpanelSSO, setLoadingCpanelSSOProductId, setBlockedWindowUrl,
  } = payload;
  const { token } = yield select(state => state.auth);
  const { redirectFromPreChat } = yield select(state => state.backup);
  yield put(productsActions.cPanelSSO.loading(setLoadingWithId ? hostingId : true));

  const request = {
    headers: {
      Authorization: token,
    },
    url: `api/v2/clientarea/${COUNTRY}/cpanelsso/${hostingId}`,
  };

  let newWindow = null;
  let newWindowUrl = null;
  try {
    const result = yield call(api.whmcs.get, request);
    const cPanelUrl = result.data.data;

    if (backup) {
      if (enableCpanelSSO) {
        newWindowUrl = `${cPanelUrl}&goto_uri=/frontend/jupiter/backup/index.html`;
      } else {
        newWindowUrl = defaultCPanelUrl;
      }
      yield put(backupActions.set.showModalBackupRedirect({ productId: null, showModal: false }));
    }

    if (backupWizard) {
      if (enableCpanelSSO) {
        newWindowUrl = `${cPanelUrl}&goto_uri=/frontend/jupiter/backup/wizard.html`;
      } else {
        newWindowUrl = defaultCPanelUrl;
      }
    }

    if (redirectFromPreChat && (backup || backupWizard)) {
      yield put(preChatActions.preChat.setScreen('problemSolved'));
      yield put(preChatActions.preChat.setOpen(true));
      yield put(backupActions.set.redirectFromPreChat(false));
    }

    if (!backup && !backupWizard) {
      if (enableCpanelSSO) {
        newWindowUrl = cPanelUrl;
      } else {
        newWindowUrl = defaultCPanelUrl;
      }
    }

    if (newWindowUrl) {
      newWindow = window.open(newWindowUrl, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        setBlockedWindowUrl && setBlockedWindowUrl(newWindowUrl);
      }
    }
  } catch (e) {
    if (defaultCPanelUrl) {
      newWindowUrl = defaultCPanelUrl;

      if (newWindowUrl) {
        newWindow = window.open(newWindowUrl, '_blank');

        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          setBlockedWindowUrl && setBlockedWindowUrl(newWindowUrl);
        }
      }
    }
  }

  yield put(productsActions.cPanelSSO.loading(false));
  setLoadingCpanelSSOProductId && setLoadingCpanelSSOProductId(null);
}

function* clearNewAlerts({ payload }) {
  const clearedList = productsAssembly.clearNewAlerts(payload);
  yield put(productsActions.myAlerts.setClear(clearedList));
}

function* sagas() {
  yield takeLatest(ProductsActionTypes.REQUEST_GET_PRODUCTS, getProductsPerCategoryRequest);
  yield takeLatest(ProductsActionTypes.SET_PRODUCT_VISUALIZED, handleSetProductVisualized);
  yield takeLatest(ProductsActionTypes.GET_CPANEL_LINK, cPanelSSO);
  yield takeLatest(ProductsActionTypes.REQUEST_CLEAR_NEW_ALERTS, clearNewAlerts);
  yield takeLatest(ProductsActionTypes.GET_ALL_PRODUCTS, requestAllProducts);
}

export default sagas;
