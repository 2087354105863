import { combineReducers } from 'redux';
import auth from '@/redux/reducers/auth';

import {
  billingReducer as billing,
  commonReducer as common,
  sitesReducer as sites,
  productsReducer as products,
  faqReducer as newFaq,
  dnsWizardReducer as dnsWizard,
  asaasReducer as asaas,
  domainsReducer as newDomains,
  preChatReducer as preChat,
  securityReducer as security,
  alertsReducer as alertsV2,
  formsReducer,
  backupReducer as backup,
  invoicesReducer as invoicesV2,
  videoClassesReducer,
  linkNaBioReducer as linkNaBio,
  cloudflareReducer as cloudflare,
} from '@/redux/modules';

import summary from '@/redux/reducers/summary';
import invoices from '@/redux/reducers/invoices';
import invoicePaymentMethods from '@/redux/reducers/invoicePaymentMethods';
import productGroups from '@/redux/reducers/productGroups';
import generalProducts from '@/redux/reducers/generalProducts';
import subdomains from '@/redux/reducers/subdomains';
import creditCard from '@/redux/reducers/creditCard';
import feedbackForm from '@/redux/reducers/feedbackForm';
import domainRenewal from '@/redux/reducers/domainRenewal';
import faq from '@/redux/reducers/faq';
import ticketDetail from '@/redux/reducers/ticketDetail';
import tickets from '@/redux/reducers/tickets';
import dnsList from '@/redux/reducers/dnsForward';
import dnsDetails from '@/redux/reducers/dnsDetails';
import notifications from '@/redux/reducers/notifications';
import featureToggles from '@/redux/reducers/featureToggles';
import changePassword from '@/redux/reducers/changePassword';
import twoFactorAuth from '@/redux/reducers/twoFactorAuth';
import domains from '@/redux/reducers/domains';
import productDetail from '@/redux/reducers/productDetail';
import alerts from '@/redux/reducers/alerts';
import emails from '@/redux/reducers/emails';
import onboarding from '@/redux/reducers/onboarding';
import breadCrumbs from '@/redux/reducers/breadCrumbs';
import videos from '@/redux/reducers/videos';
import emailVerify from '@/redux/reducers/emailVerify';
import phoneNumberVerify from '@/redux/reducers/phoneNumberVerify';
import countryPhoneCodes from '@/redux/reducers/countryPhoneCodes';
import siteBuilder from '@/redux/reducers/siteBuilder';
import paypal from '@/redux/reducers/paypal';
import dnsZoneManager from '@/redux/reducers/dnsZoneManager';
import sitesPlans from '@/redux/reducers/sitesPlans';
import survey from '@/redux/reducers/survey';
import customerProfileSurvey from '@/redux/reducers/customerProfileSurvey';
import sitelock from '@/redux/reducers/sitelock';
import codeguard from '@/redux/reducers/codeguard';
import academyPass from '@/redux/reducers/academyPass';
import creditCardAttempt from '@/redux/reducers/creditCardPaas';


const allReducers = combineReducers({
  videoClassesReducer,
  academyPass,
  alerts,
  alertsV2,
  asaas,
  auth,
  backup,
  billing,
  breadCrumbs,
  changePassword,
  twoFactorAuth,
  codeguard,
  common,
  countryPhoneCodes,
  creditCard,
  creditCardAttempt,
  customerProfileSurvey,
  dnsDetails,
  dnsList,
  dnsWizard,
  dnsZoneManager,
  domainRenewal,
  domains,
  emails,
  emailVerify,
  faq,
  featureToggles,
  feedbackForm,
  generalProducts,
  invoicePaymentMethods,
  invoices,
  invoicesV2,
  newDomains,
  newFaq,
  notifications,
  onboarding,
  paypal,
  phoneNumberVerify,
  preChat,
  productDetail,
  productGroups,
  products,
  security,
  siteBuilder,
  sitelock,
  sites,
  sitesPlans,
  subdomains,
  summary,
  survey,
  ticketDetail,
  tickets,
  videos,
  formsReducer,
  linkNaBio,
  cloudflare,
});

const rootReducer = (stateReceived, action) => allReducers(stateReceived, action);

export default rootReducer;
