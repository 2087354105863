import React, { useState, useRef } from 'react';
import { Radio, Textarea } from '@/pages/common';
import * as Styles from './Survey.styles';

const Survey = ({
  onOptionSelected,
  onReasonChange,
  optionId = null,
  questionDescriptionLabel = 'Your feedback is very important to us.',
  questionId = null,
  questions = [{
    id: 1,
    value: 'this is a test question!',
    options: [{
      id: 2,
      value: 'this is a test option!',
      selected: true,
    },
    {
      id: 3,
      value: 'this is a test option!',
      selected: true,
    }],
  }],
  reason = '',
  reasonQuestionLabel = 'Could you tell us a little more about the selected reason?',
  testId = 'survey',
}) => {
  const reasonRef = useRef();
  const [surveyQuestionId, setSurveyQuestionId] = useState(questionId);
  const [surveyOptionId, setSurveyOptionId] = useState(optionId);
  const [reasonValue, setReasonValue] = useState(reason);

  const onControlOptions = (currentQuestionId, currentOptionId) => {
    const selected = surveyOptionId === currentOptionId;
    const question = questions.find(question => question.id === currentQuestionId);

    question && question.options.forEach((option) => {
      const currentOption = option;
      if (option.id !== currentOptionId) {
        currentOption.selected = !option.selected;
      }
    });

    !selected && reasonRef.current.focus();

    const questionIdValue = selected ? null : currentQuestionId;
    const optionIdValue = selected ? null : currentOptionId;
    const optionLabelValue = selected ? '' : question.options.find(option => option.selected).value;

    setReasonValue('');
    setSurveyQuestionId(questionIdValue);
    setSurveyOptionId(optionIdValue);

    onOptionSelected && onOptionSelected({
      questionId: questionIdValue,
      optionId: optionIdValue,
      questionValue: optionLabelValue,
      ...(selected && { reason: '' }),
    });
  };

  const onOptionClick = (currentQuestionId, currentOptionId) => {
    onControlOptions(currentQuestionId, currentOptionId);
  };

  const handleOnReasonChange = (event) => {
    setReasonValue(event.target.value);
    onReasonChange && onReasonChange({
      questionId: surveyQuestionId,
      optionId: surveyOptionId,
      reason: event.target.value,
    });
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      {questions.map(question => (
        <Styles.Question key={question.id} data-testid={`${testId}-question-${question.id}`}>
          <Styles.QuestionTitle>{ question.value }</Styles.QuestionTitle>
          <Styles.QuestionDescription>{questionDescriptionLabel}</Styles.QuestionDescription>
          <Styles.Options>
            {question.options.map(option => (
              <Styles.Option
                key={option.id}
                onClick={() => onOptionClick(question.id, option.id)}
                selected={surveyOptionId === option.id}
                visible={option.selected}
                data-testid={`${testId}-option-${option.id}`}
              >
                <Styles.OptionContent>
                  <Styles.OptionButton>
                    <Radio
                      value={option.value}
                      checked={surveyOptionId === option.id}
                      testId={`${testId}-option-${option.id}-radio`}
                    />
                  </Styles.OptionButton>
                  <Styles.OptionText
                    data-testid={`${testId}-option-${option.id}-text`}
                  >
                    {option.value}
                  </Styles.OptionText>
                </Styles.OptionContent>
              </Styles.Option>
            ))}
          </Styles.Options>

          <Styles.Reason visible={surveyOptionId !== null} data-testid={`${testId}-reason`}>
            <Styles.ReasonQuestion>
              { reasonQuestionLabel }
            </Styles.ReasonQuestion>
            <Styles.ReasonOption>
              <Textarea
                forwardRef={reasonRef}
                onChange={handleOnReasonChange}
                placeholder="Achei que a ferramenta já vinha configurada e como não tenho experiência tive dificuldades e por isso estou cancelando."
                value={reasonValue}
                testId={`${testId}-reason-textarea`}
              />
            </Styles.ReasonOption>
          </Styles.Reason>
        </Styles.Question>
      ))}
    </Styles.Wrapper>
  );
};

export default Survey;
