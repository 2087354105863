import styled, { css } from 'styled-components';

export const SlideToggleWrapper = styled.div`
  ${({ theme, maxWidht }) => css`
    align-items: flex-start;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.line.wizard};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-widht: ${maxWidht};
    padding: 16px 18px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      padding: 22px 15px; 
    }
  `}
`;

export const ToggleAction = styled.div`
  ${() => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
  `}
`;

export const ToggleHeader = styled.div`
  ${({ clickable }) => css`
    align-items: flex-start;
    cursor: ${clickable ? 'pointer' : 'unset'};
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    width: 100%;
  `}
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 29px - 16px);
  width: 100%;
`;

export const Title = styled.p`
  ${({ theme, active }) => {
    const variant = {
      color: active ? theme.v1.color.action.primary : theme.v1.color.text.primary,
      weight: active ? theme.v1.font.weights.bold : theme.v1.font.weights.regular,
    };

    return css`
    color: ${variant.color};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${variant.weight};
    line-height: 19px;
    margin: 0px;
  `;
  }}
`;

export const ToggleContent = styled.div`
  ${({ open, maxHeight }) => css`
    margin: ${open ? '12px 0px 0px 0px' : '0px'};
    max-height: ${open ? maxHeight : '0px'};
    overflow:  ${open ? 'unset' : 'hidden'};
    transition: max-height 200ms ease-in-out, margin 200ms ease-in-out;
    width: 100%;
  `}
`;


export const Label = styled.span``;
