const styles = theme => ({
  closeButton: {
    margin: '15px 17px 0 auto',
    maxHeight: 24,
    maxWidth: 24,
    position: 'absolute',
    right: '0px',
  },

  titleElement: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '32px',
    padding: '24px 24px 8px',

    [theme.breakpoints.down(960)]: {
      padding: '24px 40px 8px 24px',
    },
  },

  subtitle: {
    color: theme.color.tundora,
    fontSize: '16px',
    letterSpacing: '0.01px',
    lineHeight: '21px',
    padding: '0px 0px 40px',
  },

  innerTitle: {
    color: theme.color.tundora,
    display: 'flex',
    fontSize: '18px',
    fontWeight: 500,
    justifyContent: 'space-between',
    letterSpacing: '0.01px',
    lineHeight: '24px',
    padding: '0px 0px 4px',

    '& span': {
      alignItems: 'center',
      backgroundColor: theme.color.brightSun,
      borderRadius: '2px',
      color: theme.color.white,
      display: 'flex',
      fontSize: '12px',
      fontWeight: 400,
      justifyContent: 'center',
      letterSpacing: '0.01px',
      lineHeight: '16px',
      marginLeft: '10px',
      marginRight: 'auto',
      padding: '3px 6px',
    },
  },

  innerSubtitle: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',
    padding: '0px 0px 20px',
  },

  linkButton: {
    color: theme.color.indigo,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '19px',
    textTransform: 'uppercase',
  },

  ongoingInvoice: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '6px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '24px',
    maxWidth: '672px',
    padding: '16px',
  },

  invoiceItem: {
    padding: '0px 12px 16px',

    '&:first-child': {
      paddingLeft: '0px',
      flexGrow: 0,
    },

    '&:nth-child(2)': {
      [theme.breakpoints.down(960)]: {
        paddingLeft: '0px',
      },
    },

    '&:nth-child(3)': {
      paddingRight: '0px',
    },

    '&:nth-child(4)': {
      paddingRight: '0px',
    },


    [theme.breakpoints.down(960)]: {
      paddingLeft: '0px',
    },
  },

  planName: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '21px',
    marginBottom: '4px',
  },

  planData: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  invoiceItemTitle: {
    color: theme.color.regentGray,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '16px',
    marginBottom: '4px',
  },


  invoiceItemData: {
    color: theme.color.tundora,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  warning: {
    alignItems: 'center',
    border: `1px solid ${theme.color.brightSun}`,
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    margin: '0px',
    padding: '10px',

    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.01px',
    color: theme.color.tundora,

    '& svg': {
      marginRight: '16px',
      width: '17px',
    },
  },

  newInvoice: {
    backgroundColor: theme.color.wildSand,
    borderRadius: '6px',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    maxWidth: '672px',
    padding: '16px',
  },

  actionsWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
    overflow: 'hidden',
  },
});

export default styles;
