import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './BackupHandler.styles';
import { Tabs } from 'gatorcomponents';
import { TABS } from './BackupHandler.types';
import { Create } from './components/Create';
import useLocale from '@/hooks/useLocale/useLocale';
import { Log } from './components/Log';
import { BackupGeneralVision } from './components/BackupGeneralVision';
import { useDispatch, useSelector } from 'react-redux';
import {
  productsActions, backupActions, formsActions, preChatActions,
} from '@/redux/modules';
import { useParams, useHistory } from 'react-router';
import Loader from '@/components/Layout/Loader';
import { Restore } from './components/Restore';
import { backupAnalytics } from '@/analytics';
import { loadGeneralProducts } from '@/redux/actions/generalProducts';
import { WizardBackup } from './components/WizardBackup';
import { wizardActions, wizardRestorationOptios } from './components/WizardBackup/WizardBackup.types';
import { HgProducts } from '@/components/SupportForms/SupportForms.types';

const BackupPage = () => {
  const params = useParams();
  const { backup: backupLocale, ticket: ticketsLocale, routes: routesLocale } = useLocale();
  const [activeTab, setActiveTab] = useState(TABS.GENERAL);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, category } = useParams();
  const { cPanelLoading } = useSelector(state => state.products);
  const {
    backupGeneration, loadingLog, backupLog, loadingInodes, inodesAndSizeOk, redirectFromPreChat,
  } = useSelector(state => state.backup);
  const products = useSelector(state => state.generalProducts.bkpProducts);
  const [showWizard, setShowWizard] = useState(!!params.wizard);
  const [wizardAction, setWizardAction] = useState(null);
  const [haveFile, setHaveFile] = useState(null);

  const [wizardRestorationOption, setWizardRestorationOption] = useState(null);

  const currentProduct = products ? [...products].find((product) => {
    const foundedProduct = String(product.id) === id;
    return foundedProduct;
  }) : {};

  const checkProduct = useCallback(() => {
    if (currentProduct) {
      const isAllowed = [...HgProducts.SHARED_ALL, ...HgProducts.RESELLER_ALL, ...HgProducts.PLUS_ALL].includes(currentProduct.packageid);
      if ((currentProduct.status.toLowerCase() === 'pending' || !isAllowed)) {
        history.push(`${routesLocale.products}${routesLocale.productdetail}/${currentProduct.id}`);
      }
    }
  }, [currentProduct, history, routesLocale]);

  useEffect(() => {
    checkProduct();
  }, [checkProduct]);

  const requestData = useCallback(() => {
    if (id) {
      dispatch(backupActions.backupGeneration.clearStatus());
      dispatch(backupActions.backupLog.request({ id }));
      dispatch(loadGeneralProducts());
      dispatch(backupActions.inodes.request({ id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const setIinitialRestorationOption = useCallback(() => {
    if (category) {
      setWizardAction(category.includes('generate') ? wizardActions.GENERATE : wizardActions.RESTORE);
    }
  }, [category]);

  useEffect(() => {
    setIinitialRestorationOption();
  }, [setIinitialRestorationOption]);

  const goToCPanel = useCallback((wizard) => {
    if (wizard) {
      dispatch(productsActions.cPanelSSO.requestLink({ hostingId: id, backupWizard: true, enableCpanelSSO: true }));
    } else {
      dispatch(productsActions.cPanelSSO.requestLink({ hostingId: id, backup: true, enableCpanelSSO: true }));
    }
  }, [dispatch, id]);

  const createBackup = () => {
    dispatch(backupActions.backupGeneration.request({ id }));
    backupAnalytics.backupButtonOnTabBackupClick();
  };

  const handleChangeTabs = useCallback((tabId) => {
    switch (tabId) {
      case TABS.GENERAL: {
        backupAnalytics.generalVision();
        break;
      }
      case TABS.CREATE: {
        backupAnalytics.clickOnDoBackupTab();
        break;
      }
      case TABS.RESTORE: {
        backupAnalytics.clickRestoreTab();
        break;
      }
      case TABS.LOG: {
        backupAnalytics.clickLogTab();
        break;
      }
      case TABS.CPANEL: {
        backupAnalytics.clickCPanelTab();
        break;
      }
      default: {
        return null;
      }
    }

    if (tabId !== TABS.CPANEL) {
      setActiveTab(tabId);
    }

    if (tabId === TABS.CPANEL && !cPanelLoading) {
      goToCPanel();
    }
  }, [cPanelLoading, goToCPanel]);

  const openFormUserFile = () => {
    let restorationType = ticketsLocale.forms.userBackupRestore.completeRestoration;

    if (wizardRestorationOption === wizardRestorationOptios.ROOT) {
      restorationType = ticketsLocale.forms.userBackupRestore.filesRestoration;
    }

    if (wizardRestorationOption === wizardRestorationOptios.DATABASE) {
      restorationType = ticketsLocale.forms.userBackupRestore.databaseRestoration;
    }

    dispatch(formsActions.forceState.set({
      key: 'userBackupRestore',
      params: {
        selectedProduct: currentProduct,
        selectedSector: 'technical',
        selectedRestorationType: restorationType,
        selectedBackupOption: null,
        selectedNeed: ticketsLocale.forms.names.userBackupRestore,
      },
    }));
    history.push(`${routesLocale.help}${routesLocale.supportforms}`);
  };

  const openFormHgRoutine = (forceFull) => {
    let restorationType = ticketsLocale.forms.restorateBackupHgRoutine.selectRestorationType.full;

    if (!forceFull && wizardRestorationOption === wizardRestorationOptios.ROOT) {
      restorationType = ticketsLocale.forms.restorateBackupHgRoutine.selectRestorationType.file;
    }

    if (!forceFull && wizardRestorationOption === wizardRestorationOptios.DATABASE) {
      restorationType = ticketsLocale.forms.restorateBackupHgRoutine.selectRestorationType.database;
    }

    dispatch(formsActions.forceState.set({
      key: 'restorateBackupHgRoutine',
      params: {
        selectedProduct: currentProduct,
        selectedSector: 'technical',
        selectedRestorationType: restorationType,
        selectedBackupOption: null,
        selectedNeed: ticketsLocale.forms.names.restorateBackupHgRoutine,
      },
    }));
    history.push(`${routesLocale.help}${routesLocale.supportforms}`);
  };

  const openGenerateBackup = () => {
    dispatch(formsActions.forceState.set({
      key: 'generateBackup',
      params: {
        selectedProduct: currentProduct,
        selectedSector: 'technical',
        selectedNeed: ticketsLocale.forms.names.generateBackup,
      },
    }));
    history.push(`${routesLocale.help}${routesLocale.supportforms}`);
  };

  const getActiveProductTabsProps = useCallback(() => ({
    options: [
      {
        label: backupLocale.tabs.labels.general,
        id: TABS.GENERAL,
        onClick: () => handleChangeTabs(TABS.GENERAL),
      },
      {
        label: backupLocale.tabs.labels.create,
        id: TABS.CREATE,
        onClick: () => handleChangeTabs(TABS.CREATE),
      },
      {
        label: backupLocale.tabs.labels.restore,
        id: TABS.RESTORE,
        onClick: () => handleChangeTabs(TABS.RESTORE),
      },
      {
        label: backupLocale.tabs.labels.log,
        id: TABS.LOG,
        onClick: () => handleChangeTabs(TABS.LOG),
      },
      {
        id: TABS.CPANEL,
        label: cPanelLoading ? (
          <>
            <Loader size="12" />
            {` ${backupLocale.tabs.labels.cpanel}`}
          </>
        ) : backupLocale.tabs.labels.cpanel,
        onClick: () => handleChangeTabs(TABS.CPANEL),
        preventActivation: true,
      },
    ],
  }), [cPanelLoading, backupLocale, handleChangeTabs]);

  const getNotActiveProductTabsProps = useCallback(() => ({
    options: [
      {
        label: backupLocale.tabs.labels.general,
        id: TABS.GENERAL,
        onClick: () => handleChangeTabs(TABS.GENERAL),
      },
      {
        label: backupLocale.tabs.labels.log,
        id: TABS.LOG,
        onClick: () => handleChangeTabs(TABS.LOG),
      },
    ],
  }), [cPanelLoading, backupLocale, handleChangeTabs]);

  const getGeneralVisionProps = () => ({
    generalVisionLocale: backupLocale.tabs.generalVision,
    loadingLog,
    lastBackup: backupLog[0],
    currentProduct,
    goToCPanel,
    setShowWizard,
    firstStep: backupLocale.tabs.generalVision.firstStep,
  });

  const getCreateProps = () => ({
    createBackup,
    title: backupLocale.tabs.create.title,
    description: backupLocale.tabs.create.description,
    alertTitle: backupLocale.tabs.create.alertTitle,
    alertDescription: backupLocale.tabs.create.alertDescription2(() => openGenerateBackup()),
    backupLabel: backupLocale.tabs.create.backupLabel,
    hostingId: id,
    proceedAlertDescription: null,
    loading: backupGeneration.loading,
    backupStatus: backupGeneration.status,
    backupSuccessTitle: backupLocale.tabs.create.backupSuccess.title,
    backupSuccessDescription: backupLocale.tabs.create.backupSuccess.description,
    backupErrorTitle: backupLocale.tabs.create.backupError.title,
    backupErrorDescription: backupLocale.tabs.create.backupError.description,
  });

  const getRestoreProps = () => ({
    title: backupLocale.tabs.restore.title,
    description: backupLocale.tabs.restore.description,
    alertTitle: backupLocale.tabs.restore.alertTitle,
    alertDescription: backupLocale.tabs.restore.alertDescription,
    agreement: backupLocale.tabs.restore.agreement,
    uploadLabel: backupLocale.tabs.restore.uploadLabel,
    goToCPanel,
    cPanelLoading,
  });

  const getLogProps = () => {
    let page = 1;

    const loadMore = () => {
      page += 1;
    };

    return ({
      history: backupLog,
      page,
      itemsPerPage: 10,
      loadMore,
      title: backupLocale.tabs.log.title,
      description: backupLocale.tabs.log.description,
      tagSuccess: backupLocale.tabs.log.tags.success,
      tagError: backupLocale.tabs.log.tags.error,
      tagWarning: backupLocale.tabs.log.tags.warning,
      mountInfoSuccess: backupLocale.tabs.log.info.success,
      mountInfoWarning: backupLocale.tabs.log.info.warning,
      mountInfoEmpty: backupLocale.tabs.log.info.empty,
      mountInfoError: backupLocale.tabs.log.info.error,
      loadMoreLabel: backupLocale.tabs.log.loadMoreLabel,
      loadingLog,
      currentProduct,
      goToCPanel,
    });
  };

  const getWizardBackupProps = () => {
    const defaultProps = {
      currentProduct,
      wizardAction,
      setWizardAction,
      TABS,
      setActiveTab,
      setShowWizard,
      wizardRestorationOption,
      setWizardRestorationOption,
      haveFile,
      setHaveFile,
      goToCPanel,
      cPanelLoading,
      openFormUserFile,
      openFormHgRoutine,
      openGenerateBackup,
      loadingInodes,
      inodesAndSizeOk,
      redirectFromPreChat,
      ...backupLocale.wizard,
    };

    const clearRedirectFromPrechat = () => {
      dispatch(backupActions.set.redirectFromPreChat(false));
      dispatch(preChatActions.preChat.setOpen(true));
    };

    return {
      ...defaultProps,
      clearRedirectFromPrechat,
    };
  };

  const tabs = {
    [TABS.GENERAL]: <BackupGeneralVision {...getGeneralVisionProps()} />,
    [TABS.CREATE]: <Create {...getCreateProps()} />,
    [TABS.RESTORE]: <Restore {...getRestoreProps()} />,
    [TABS.LOG]: <Log {...getLogProps()} />,
  };

  return (
    <Styles.Wrapper>
      {showWizard && (
        <WizardBackup {...getWizardBackupProps()} />
      )}

      {!showWizard && (
        <Styles.Container>
          <Styles.TabsWrapper>
            {(!currentProduct || currentProduct.status === 'Suspended' || currentProduct.status === 'Cancelled' || currentProduct.status === 'Pending') ? (
              <Tabs {...getNotActiveProductTabsProps()} currentActiveTab={activeTab} />
            ) : (
              <Tabs {...getActiveProductTabsProps()} currentActiveTab={activeTab} />
            )}
          </Styles.TabsWrapper>

          {tabs[activeTab]}
        </Styles.Container>
      )}
    </Styles.Wrapper>
  );
};

export default BackupPage;
