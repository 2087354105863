import React, { useState } from 'react';
import {
  withStyles, TextField, InputAdornment, IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import styles from './styles';


const PasswordField = ({
  classes, onChange, passwordAutoFocus, cssRootInput, onClickDisplayKey, dataId, ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const executeShowPassword = () => {
    setShowPassword(!showPassword);
    onClickDisplayKey();
  };
  return (
    <TextField
      {...rest}
      autoFocus={passwordAutoFocus}
      type={showPassword ? 'text' : 'password'}
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.cssFocused,
          marginDense: classes.labelMarginDense,
          shrink: classes.labelShrink,
          outlined: classes.labelOutlined,
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex="-1"
              aria-label="Toggle password visibility"
              onClick={() => executeShowPassword()}
              disableRipple
              className={classes.icon}
              data-testid={`${dataId}-show-password`}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          root: cssRootInput || classes.cssOutlinedInput,
          focused: classes.cssFocused,
          input: classes.input,
          inputMarginDense: classes.inputMarginDense,
          disabled: classes.disabled,
        },
        inputProps: {
          maxLength: 100,
          'data-testid': 'password-input-field',
        },
      }}
      data-id={dataId}
    />
  );
};

export default withStyles(styles)(PasswordField);
