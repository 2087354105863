import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    padding: 16px;
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const RefreshButtonRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 4px;
`;

export const EmptyCard = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 500px;
    padding: 71px;
  `}
`;

export const NoDomainText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm2};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0px;
    max-width: 451px;
    text-align: center;
  `}
`;

export const FaqRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;

export const SkeletonItem = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-directino: row;
    gap: 110px;
    justify-content: space-between;
    padding: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex-wrap: wrap;
      gap: 16px;
    }
  `}
`;

export const SkeletonHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 609px;
  width: 100%;

  & > :first-child {
    margin-bottom: 8px;
  }
`;

export const SkeletonButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 100%;
`;
