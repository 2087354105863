import React from 'react';
import { IconFeaturedStar } from '@/icons';
import * as Styles from './DnsWizardHostingPlatformCard.styles';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import { domainsAnalytics } from '@/analytics';

const DnsWizardHostingPlatformCard = ({
  testId = 'hostingcompanycard',
  imageUrl = 'https://www.fillmurray.com/300/200',
  featured = false,
  platformType = HOSTING_PLATFORM_TYPES.HOSTGATOR,
  platformName = '',
  handleOnClick = () => {},
  contentText,
}) => {
  const handleClick = () => {
    domainsAnalytics.dnsWizard.onlyDnsZoneCtaClick();
    handleOnClick(platformType, platformName);
  };

  return (
    <Styles.HostingPlatformCardWrapper
      data-testid={testId}
      onClick={handleClick}
    >
      {featured && <IconFeaturedStar testId="featured-star-icon" />}
      <img
        src={imageUrl}
        alt={platformType}
        data-testid="hostingcompanycard-image"
      />
      {contentText && (
        <Styles.ContentText data-testid="hostingcompanycard-text">
          {contentText}
        </Styles.ContentText>
      )}
    </Styles.HostingPlatformCardWrapper>
  );
};

export default DnsWizardHostingPlatformCard;
