import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import TicketHeaderIcon from '@/components/Tickets/TicketHeaderIcon';
import TicketHeaderId from '@/components/Tickets/TicketHeaderId';
import TicketStatusLabel from '@/components/Tickets/TicketStatusLabel';
import TicketHeaderTitle from '@/components/Tickets/TicketHeaderTitle';
import TicketDivider from '@/components/Tickets/TicketDivider';
import styles from './styles';


const TicketHeader = ({
  id, status, title, classes, t,
}) => (
  <div className={classes.header}>
    <div className={classes.subheader}>
      <TicketHeaderIcon />
      <TicketHeaderId id={id} />
      <TicketStatusLabel ticketStatus={status}>
        {t(`tickets.${status}`)}
      </TicketStatusLabel>
    </div>
    <TicketHeaderTitle title={title} />
    <TicketDivider />
  </div>
);

TicketHeader.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(TicketHeader));
