import React, {
  useContext, useEffect, useState, useMemo, useCallback,
} from 'react';
import { withStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { find, propEq } from 'ramda';
import { useDispatch } from 'react-redux';
import { withI18n } from 'react-i18next';
import Loader from '@/components/Layout/Loader';
import { EmailsContext } from '@/contexts/Emails/context';
import { proEmailAuth } from '@/redux/actions/emails';
import { COUNTRY } from '@/config';
import { loadFaqEmails, loadFaqParam } from '@/redux/actions/faq';
import TitanMailTitle from './TitanMailTitle';
import styles from './styles';
import faqParams from './faqParams';

const TitanCreateEMail = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { domain } = useParams();


  const { loadingProEmails, firstLoad, renderedEmailList } = useContext(EmailsContext);

  const loading = loadingProEmails || firstLoad;
  const planObject = useMemo(() => find(propEq('domain', domain), renderedEmailList), [domain, renderedEmailList]);
  const partnerId = process.env.REACT_APP_TITAN_PARTNER_ID;
  const titanUrl = process.env.REACT_APP_TITAN_URL;
  const [titanMailToken, setTitanMailToken] = useState(null);
  const postMessageListener = useCallback((event) => {
    if (event.data.event) {
      if (event.data.data.action === 'buyMoreAccounts') {
        window.location.href = `${t('routes.emailsList')}${t('routes.titanInboxUpgrade')}/${domain}`;
      }

      if (event.data.data.action === 'upgradePlan') {
        window.location.href = `${t('routes.emailsList')}${t('routes.titanUpgrade')}/${domain}`;
      }

      if (event.data.data.action === 'renewOrder') {
        window.location.href = `${t('routes.billing')}${t('routes.unpaid')}`;
      }
    }
  }, [domain, t]);

  useEffect(() => {
    if (planObject && planObject.status !== 'active') {
      window.location.href = `${t('routes.emailsList')}`;
    }

    dispatch(loadFaqEmails());

    faqParams.forEach((param) => {
      dispatch(loadFaqParam(param));
    });

    window.addEventListener('message', event => postMessageListener(event));

    if (renderedEmailList.length > 0) {
      dispatch(proEmailAuth(planObject.pro_email_id)).then((response) => {
        if (response.data) {
          setTitanMailToken(response.data.token);
        }
      });
    }

    return () => { window.removeEventListener('message', event => postMessageListener(event)); };
  }, [renderedEmailList, dispatch, planObject, postMessageListener, t]);


  if (loading || planObject === undefined) {
    return <Loader />;
  }

  return (
    <>
      <TitanMailTitle domain={domain} name={planObject.plan} />
      <iframe
        className={classes.iframe}
        src={`${titanUrl}partner/cpWidget?partnerId=${partnerId}&jwt=${titanMailToken}&section='home'&locale=${COUNTRY === 'br' ? 'pt-br' : 'es'}`}
        title="Embedding of Titan iframe within Partner environment"
      />
    </>
  );
};

export default withI18n()(withStyles(styles)(TitanCreateEMail));
