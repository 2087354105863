import React from 'react';

const IconCardElo = ({ className }) => (
  <svg
    className={className}
    ixmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.03 22.93"
  >
    <g>
      <path d="M2,0H34.11A1.92,1.92,0,0,1,36,1.92h0L36,21a1.92,1.92,0,0,1-1.92,1.92H1.92A1.92,1.92,0,0,1,0,21H0L0,1.92A1.92,1.92,0,0,1,2,0Z" />
      <path d="M7.23,8.52a3.38,3.38,0,0,1,1-.17A3.29,3.29,0,0,1,11.5,11l2.25-.47A5.59,5.59,0,0,0,7.16,6.16c-.22,0-.44.11-.65.18Z" fill="#fff100" />
      <path d="M4.57,15.83,6.09,14.1a3.29,3.29,0,0,1-.28-4.64,2.47,2.47,0,0,1,.28-.28L4.57,7.45a5.59,5.59,0,0,0-.48,7.9,5.44,5.44,0,0,0,.48.48Z" fill="#00a3df" />
      <path d="M11.5,12.3a3.3,3.3,0,0,1-3.9,2.56l-.37-.1L6.5,16.94a5.59,5.59,0,0,0,7.08-3.53,4.77,4.77,0,0,0,.17-.65Z" fill="#ee4023" />
      <path d="M21.28,13.84a2,2,0,0,1-1.43.58,1.92,1.92,0,0,1-1-.31l-.74,1.19a3.43,3.43,0,0,0,4.2-.45ZM19.93,9A3.42,3.42,0,0,0,17,14.27l6.18-2.65A3.43,3.43,0,0,0,19.93,9Zm-2.05,3.63a1.93,1.93,0,0,1,0-.24,2,2,0,0,1,2-2,2,2,0,0,1,1.5.72Zm7.26-5.16v6.59l1.14.47-.54,1.3-1.13-.47a1.28,1.28,0,0,1-.56-.47,1.46,1.46,0,0,1-.22-.81V7.47Zm4.12,3.05A2,2,0,0,1,31.8,11.8a.83.83,0,0,1,.06.21l1.38-.28A3.42,3.42,0,0,0,29.89,9a3.52,3.52,0,0,0-1.07.17ZM27.63,15l.93-1.05a2,2,0,0,1-.16-2.84l.16-.17-.93-1.05a3.42,3.42,0,0,0-.29,4.82l.29.29Zm4.23-2.15a2,2,0,0,1-2.35,1.57l-.25-.07-.44,1.34a3.42,3.42,0,0,0,4.32-2.15c0-.13.08-.27.11-.41Z" fill="#fff" />
    </g>
  </svg>
);

export default IconCardElo;
