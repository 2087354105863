import styled, { css } from 'styled-components';
import starBackground from '@/media/subscriptions/retention/starBackground.svg';
import bgFrameDesktop from '@/media/billing/bg-frame-desk.svg';
import bgFrameMobile from '@/media/billing/bg-frame-mob.svg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    height: 100%;
    margin: 0px 24px 0px 24px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      width: 100%;
      margin: 0px;
    }
  `}
`;

export const ContentStar = styled.div`
      display: flex;
      flex-direction: row-reverse;
`;

export const StarImg = styled.img`
  ${({ theme }) => css`
    background-image: url(${starBackground});
    max-width: 209px;
    margin-bottom: 10px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      max-width: 100%;
      margin-bottom: 0;
      padding-right: 0;
    }
  `}
`;

export const Content = styled.div`
`;

export const BenefitsWrapper = styled.div`
${({ theme }) => css`
      display: flex;
      justify-content: center;
      padding: 0 40px;
      @media (max-width: ${theme.v2.breakpoints.sm}) {
        padding: 0 24px;
      }
    `}
`;

export const BenefitsContent = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    gap: 10px;
    justify-content: center;
    line-height: 22px;
    padding-top: 28px;
    width: 100%;
    @media (min-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: row;
      gap: 30px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.medium};
  line-height: normal;
  margin: 0px 0px 8px 0px;
  text-align: left;

  > p {
    margin: 0px;
    > span {
        font-weight: ${theme.v2.font.weights.medium};
      }
    }

  `}
`;

export const GreenDescription = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.feedback.activePure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 24px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.medium};
  line-height: normal;
  margin-top: 8px;
  text-align: left;
  width: 100%;
  
  @media (max-width: ${theme.v2.breakpoints.sm}) {
    margin-top: 16px;
  }

  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin: 24px 0px 24px 0px;
  text-align: left;
  max-width: 437px;
  `}
`;

export const ControlWrapper = styled.div`
  ${({ theme }) => css`
    > button {
      height: 48px;
      background-color: ${theme.v2.colors.brand.primaryMedium};
      
      @media (max-width: ${theme.v2.breakpoints.sm}) {
        height: 32px;
      }
    }
    `}
`;

export const SnappyImg = styled.img`
  ${({ theme }) => css`
    margin-bottom: 10px;
    padding-right: 12px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
    }
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin-top: 24px;
  text-align: left;
  width: 446px;

  @media (max-width: ${theme.v2.breakpoints.sm}) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
  }
  `}
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    justify-content: right;
    margin: 111px 40px 0px 40px;
    padding: 0 40px;
    padding: 38px 0px;
    > button {
      color: #0070D1;
      font-size: 16px;
      font-style: normal;
      font-weight: ${theme.v2.font.weights.medium};
      gap: 3px;
      line-height: normal;
      text-align: center;
      text-decoration: auto;
      > svg {
        color: #0070D1;
        height: 24px;
        width: 24px;
      }
      }
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      justify-content: center;
      margin: 38px 40px 0px 40px;
      padding: 24px 0px;

    }
  `}
`;
export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    background-image: url(${bgFrameDesktop});
    margin-top: 95px;
    max-height: 290px;
    max-width:  636px;
    padding:  0px 36px 0px 36px;
    > img {
      position: relative;
      bottom: 134px;
      left: 478px;
   }

   @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-top: 18px;
      max-width: 360px;
      max-height: 400px;
      padding: 0px 20px 0px 20px;
      background-image: url(${bgFrameMobile});
      width: 312px;
      > img {
        position: relative;
        bottom: 189px;
        left: 256px;
     }
     }

     @media (max-width: ${theme.v2.breakpoints.xs}) {
      > img {
        position: relative;
        bottom: 189px;
        left: 329px;
     }
     }
  `}
`;
