export const DnsWizardActionTypes = {
  DEFAULT: 'DNS_WIZARD_DEFAULT',

  GET_HOSTING_PLATFORMS_REQUEST: 'GET_HOSTING_PLATFORMS_REQUEST',
  GET_HOSTING_PLATFORMS_SUCCESS: 'GET_HOSTING_PLATFORMS_SUCCESS',
  GET_HOSTING_PLATFORMS_FAILURE: 'GET_HOSTING_PLATFORMS_FAILURE',
  SET_HOSTING_PLATFORMS: 'SET_HOSTING_PLATFORMS',

  GET_EMAIL_PLATFORMS_REQUEST: 'GET_EMAIL_PLATFORMS_REQUEST',
  GET_EMAIL_PLATFORMS_SUCCESS: 'GET_EMAIL_PLATFORMS_SUCCESS',
  GET_EMAIL_PLATFORMS_FAILURE: 'GET_EMAIL_PLATFORMS_FAILURE',
  SET_EMAIL_PLATFORMS: 'SET_EMAIL_PLATFORMS',

  REQUEST_SETTED_EMAIL_PLATFORM: 'REQUEST_SETTED_EMAIL_PLATFORM',
  REQUEST_SETTED_EMAIL_PLATFORM_SUCCESS: 'REQUEST_SETTED_EMAIL_PLATFORM_SUCCESS',
  REQUEST_SETTED_EMAIL_PLATFORM_FAILURE: 'REQUEST_SETTED_EMAIL_PLATFORM_FAILURE',
  SET_CURRENT_EMAIL_PLATAFORM: 'SET_CURRENT_EMAIL_PLATAFORM',

  PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_REQUEST: 'PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_REQUEST',
  PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_SUCCESS: 'PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_SUCCESS',
  PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_FAILURE: 'PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_FAILURE',

  PUT_CONFIGURE_CUSTOM_DNS_REQUEST: 'PUT_CONFIGURE_CUSTOM_DNS_REQUEST',
  PUT_CONFIGURE_CUSTOM_DNS_SUCCESS: 'PUT_CONFIGURE_CUSTOM_DNS_SUCCESS',
  PUT_CONFIGURE_CUSTOM_DNS_FAILURE: 'PUT_CONFIGURE_CUSTOM_DNS_FAILURE',

  PUT_CONFIGURE_EXTERNAL_PLATFORM_REQUEST: 'PUT_CONFIGURE_EXTERNAL_PLATFORM_REQUEST',
  PUT_CONFIGURE_EXTERNAL_PLATFORM_SUCCESS: 'PUT_CONFIGURE_EXTERNAL_PLATFORM_SUCCESS',
  PUT_CONFIGURE_EXTERNAL_PLATFORM_FAILURE: 'PUT_CONFIGURE_EXTERNAL_PLATFORM_FAILURE',

  PUT_CONFIGURE_CUSTOM_DNS_ZONE_REQUEST: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_REQUEST',
  PUT_CONFIGURE_CUSTOM_DNS_ZONE_SUCCESS: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_SUCCESS',
  PUT_CONFIGURE_CUSTOM_DNS_ZONE_FAILURE: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_FAILURE',

  PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_REQUEST: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_REQUEST',
  PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_SUCCESS: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_SUCCESS',
  PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_FAILURE: 'PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_FAILURE',

  PUT_CONFIGURE_EMAIL_PLATFORM_REQUEST: 'PUT_CONFIGURE_EMAIL_PLATFORM_REQUEST',
  PUT_CONFIGURE_EMAIL_PLATFORM_SUCCESS: 'PUT_CONFIGURE_EMAIL_PLATFORM_SUCCESS',
  PUT_CONFIGURE_EMAIL_PLATFORM_FAILURE: 'PUT_CONFIGURE_EMAIL_PLATFORM_FAILURE',

  PUT_SKIP_WIZARD_REQUEST: 'PUT_SKIP_WIZARD_REQUEST',
  PUT_SKIP_WIZARD_SUCCESS: 'PUT_SKIP_WIZARD_SUCCESS',
  PUT_SKIP_WIZARD_FAILURE: 'PUT_SKIP_WIZARD_FAILURE',

  GET_DNS_ZONES_REQUEST: 'GET_DNS_ZONES_REQUEST',
  GET_DNS_ZONES_SUCCESS: 'GET_DNS_ZONES_SUCCESS',
  GET_DNS_ZONES_FAILURE: 'GET_DNS_ZONES_FAILURE',
  SET_DNS_ZONES: 'SET_DNS_ZONES',

  SET_EXTERNAL_PLATFORM_CONFIGURATION: 'SET_EXTERNAL_PLATFORM_CONFIGURATION',
  SET_HOSTING_CONFIGURATION: 'SET_HOSTING_CONFIGURATION',
  SET_SELECTED_CONFIGURATION: 'SET_SELECTED_CONFIGURATION',
  SET_CUSTOM_DNS_CONFIGURATION: 'SET_CUSTOM_DNS_CONFIGURATION',
  SET_CUSTOM_DNS_ZONE_CONFIGURATION: 'SET_CUSTOM_DNS_ZONE_CONFIGURATION',
  SET_CHANGED_PLAN: 'SET_CHANGED_PLAN',
  SET_RESERVED_NS: 'SET_RESERVED_NS',

  RESET_CUSTOM_DNS_CONFIGURATION: 'RESET_CUSTOM_DNS_CONFIGURATION',

  SET_PROPAGATING_STATUS: 'SET_PROPAGATING_STATUS',
};
