import { CALL_API } from '@/middleware/api';
import { SUBDOMAINS_URL } from '@/config/api';
import { config } from '@/config';
import {
  RECEIVE_SUBDOMAINS, ABORT_REQUEST_SUBDOMAINS, REQUEST_SUBDOMAINS,
  ERROR_SUBDOMAINS,
} from './actionsTypes';


function receiveSubdomains(domainId, subdomains) {
  return {
    type: RECEIVE_SUBDOMAINS,
    subdomains,
    loadedDate: Date.now(),
    id: domainId,
  };
}

const abortRequestSubdomains = (subdomains, loadedDate) => ({
  type: ABORT_REQUEST_SUBDOMAINS,
  subdomains,
  loadedDate,
});

export function loadSubdomains(domainId) {
  return (dispatch, getState) => {
    const { loadedDate, byId, id } = getState().subdomains;

    if (id === domainId && Date.now() - loadedDate < config.CACHE_TIME) {
      return new Promise(resolve => resolve(dispatch(abortRequestSubdomains(byId, loadedDate))));
    }

    return dispatch({
      [CALL_API]: {
        authenticated: true,
        endpoint: SUBDOMAINS_URL(domainId),
        method: 'GET',
        actionTypes: {
          request: () => ({ type: REQUEST_SUBDOMAINS }),
          success: (response) => {
            const subdomain = {
              ...response.data,
            };
            return receiveSubdomains(domainId, subdomain);
          },
          error: () => ({ type: ERROR_SUBDOMAINS }),
        },
      },
    });
  };
}
