import React from 'react';

const IconCardHiper = ({ className }) => (
  <svg className={className} id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.24 22.76">
    <g>
      <path d="M33.69,0H2.56A2.53,2.53,0,0,0,0,2.5V20.26a2.53,2.53,0,0,0,2.56,2.5H33.69a2.52,2.52,0,0,0,2.55-2.5V2.5A2.52,2.52,0,0,0,33.69,0Z" fill="#ff7900" />
      <polygon points="9.03 6.09 9.03 9.32 5.92 9.32 5.92 6.09 3.73 6.09 3.73 14.91 5.92 14.91 5.92 11.22 9.03 11.22 9.03 14.91 11.22 14.91 11.22 6.09 9.03 6.09" fill="#fffbff" />
      <path d="M19,8.28c-2,0-2,1-2,1V8.51H15.23v4.9c-1.07,0-1-1.18-1-1.18V8.51H12.06v3.66c0,2.74,3.08,2.91,3.08,2.91v2.25h2.1V15.14c4,0,4.5-1.7,4.5-3.37S20.93,8.28,19,8.28Zm-1,5.13h-.75V10.79a1,1,0,0,1,1.1-.84c1,0,1.24,1.27,1.24,1.93S19.38,13.41,18,13.41Z" fill="#fff" />
      <path d="M14.19,6.8a1.14,1.14,0,1,1-1.14-1.14A1.14,1.14,0,0,1,14.19,6.8Z" fill="#fccf11" />
      <path d="M25.17,8.28c-3.31,0-3.11,3.75-3.11,3.69a3,3,0,0,0,3.25,3.14,7.59,7.59,0,0,0,2.51-.49l-.2-1.44s-3.4.92-3.69-.86h4.12S28.48,8.28,25.17,8.28Zm1.15,2.62H23.9s-.08-1.35,1.21-1.35A1.21,1.21,0,0,1,26.32,10.9Z" fill="#fffbff" />
      <path d="M30.49,8.51H28.57v6.4h2.25V11.22s-.12-1.32,1.73-1v-2a1.84,1.84,0,0,0-2.08,1Z" fill="#fffbff" />
    </g>
  </svg>
);

export default IconCardHiper;
