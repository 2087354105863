import PropTypes from 'prop-types';


const invoiceType = PropTypes.shape({
  id: PropTypes.number,
  date: PropTypes.string,
  duedate: PropTypes.string,
  totalStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  statusMessage: PropTypes.string,
  isExpired: PropTypes.bool,
  status: PropTypes.string,
  invoice_detail: PropTypes.string,
});

export default invoiceType;
