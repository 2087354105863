import { FaqActionTypes } from './faq.types';

const requestFaqWordpress = () => ({ type: FaqActionTypes.REQUEST_FAQ_WORDPRESS });
const getFaqWordpressSuccess = () => ({ type: FaqActionTypes.GET_FAQ_WORDPRESS_SUCCESS });
const getFaqWordpressFailure = () => ({ type: FaqActionTypes.GET_FAQ_WORDPRESS_FAILURE });

const requestSoftaculous = () => ({ type: FaqActionTypes.REQUEST_FAQ_SOFTACULOUS });
const getFaqSoftaculousSuccess = () => ({ type: FaqActionTypes.GET_FAQ_SOFTACULOUS_SUCCESS });
const getFaqSoftaculousFailure = () => ({ type: FaqActionTypes.GET_FAQ_SOFTACULOUS_FAILURE });

const requestSiteBuilder = () => ({ type: FaqActionTypes.REQUEST_FAQ_SITEBUILDER });
const getFaqSiteBuilderSuccess = () => ({ type: FaqActionTypes.GET_FAQ_SITEBUILDER_SUCCESS });
const getFaqSiteBuilderFailure = () => ({ type: FaqActionTypes.GET_FAQ_SITEBUILDER_FAILURE });

const requestFaqMain = () => ({ type: FaqActionTypes.REQUEST_FAQ_MAIN });
const getFaqMainSuccess = () => ({ type: FaqActionTypes.GET_FAQ_MAIN_SUCCESS });
const getFaqMainFailure = () => ({ type: FaqActionTypes.GET_FAQ_MAIN_FAILURE });

const requestFaqFinancial = () => ({ type: FaqActionTypes.REQUEST_FAQ_FINANCIAL });
const getFaqFinancialSuccess = () => ({ type: FaqActionTypes.GET_FAQ_FINANCIAL_SUCCESS });
const getFaqFinancialFailure = () => ({ type: FaqActionTypes.GET_FAQ_FINANCIAL_FAILURE });

const requestFaqDns = () => ({ type: FaqActionTypes.REQUEST_FAQ_DNS });
const getFaqDnsSuccess = () => ({ type: FaqActionTypes.GET_FAQ_DNS_SUCCESS });
const getFaqDnsFailure = () => ({ type: FaqActionTypes.GET_FAQ_DNS_FAILURE });

const requestFaqMail = () => ({ type: FaqActionTypes.REQUEST_FAQ_MAIL });
const getFaqMailSuccess = () => ({ type: FaqActionTypes.GET_FAQ_MAIL_SUCCESS });
const getFaqMailFailure = () => ({ type: FaqActionTypes.GET_FAQ_MAIL_FAILURE });

const requestFaqSite = () => ({ type: FaqActionTypes.REQUEST_FAQ_SITE });
const getFaqSiteSuccess = () => ({ type: FaqActionTypes.GET_FAQ_SITE_SUCCESS });
const getFaqSiteFailure = () => ({ type: FaqActionTypes.GET_FAQ_SITE_FAILURE });

const requestFaqCloudflare = () => ({ type: FaqActionTypes.REQUEST_FAQ_CLOUDFLARE });
const getFaqCloudflareSuccess = () => ({ type: FaqActionTypes.GET_FAQ_CLOUDFLARE_SUCCESS });
const getFaqCloudflareFailure = () => ({ type: FaqActionTypes.GET_FAQ_CLOUDFLARE_FAILURE });

const setLoading = boolean => ({
  type: FaqActionTypes.LOADING_FAQ, payload: { boolean },
});
const setFaq = faq => ({
  type: FaqActionTypes.SET_FAQ, payload: { faq },
});


const actions = {
  faq: {
    request: {
      wordpress: requestFaqWordpress,
      softaculous: requestSoftaculous,
      siteBuilder: requestSiteBuilder,
      faqMain: requestFaqMain,
      faqFinancial: requestFaqFinancial,
      faqDns: requestFaqDns,
      faqMail: requestFaqMail,
      faqSite: requestFaqSite,
      faqCloudflare: requestFaqCloudflare,
    },
    success: {
      wordpress: getFaqWordpressSuccess,
      softaculous: getFaqSoftaculousSuccess,
      siteBuilder: getFaqSiteBuilderSuccess,
      faqMain: getFaqMainSuccess,
      faqFinancial: getFaqFinancialSuccess,
      faqDns: getFaqDnsSuccess,
      faqMail: getFaqMailSuccess,
      faqSite: getFaqSiteSuccess,
      faqCloudflare: getFaqCloudflareSuccess,
    },
    failure: {
      wordpress: getFaqWordpressFailure,
      softaculous: getFaqSoftaculousFailure,
      siteBuilder: getFaqSiteBuilderFailure,
      faqMain: getFaqMainFailure,
      faqFinancial: getFaqFinancialFailure,
      faqDns: getFaqDnsFailure,
      faqMail: getFaqMailFailure,
      faqSite: getFaqSiteFailure,
      faqCloudflare: getFaqCloudflareFailure,
    },
    set: setFaq,
    load: setLoading,
  },
};

export default actions;
