import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import {
  Dialog, IconButton, DialogTitle, DialogContent, DialogActions, withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { deleteEmailAccount } from '@/redux/actions/emails';
import { SUCCESS_DELETE_EMAILS } from '@/redux/actions/actionsTypes';
import { notifierSuccess } from '@/utils/Application/notifier';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import styles from './styles';


const DeleteEmailAccountModal = ({
  classes,
  openModal,
  handleCloseModal,
  hostingId,
  emailAddress,
  t,
}) => {
  const dispatch = useDispatch();
  const loadingDeleteAccount = useSelector(state => state.emails.loadingDeleteAccount);
  const isMobile = window.innerWidth <= 490;

  const onHandleCloseModal = () => {
    handleCloseModal('deleteEmailAccount');
  };

  const handleDeleteAccount = () => {
    if (loadingDeleteAccount) return;

    dispatch(deleteEmailAccount(hostingId, emailAddress)).then((res) => {
      if (res.type === SUCCESS_DELETE_EMAILS) {
        onHandleCloseModal();
        dispatch(enqueueSnackbar('snackbar.emails.deleteEmailAccount', notifierSuccess));
      }
    });
  };

  return (
    <Dialog
      open={openModal}
      onClose={onHandleCloseModal}
      aria-labelledby="alert-dialog-delete-account"
      aria-describedby="alert-dialog-confirmation-to-delete-email-account"
      classes={{
        container: classes.modalContainer,
        paper: classes.modalPaper,
      }}
      fullScreen={isMobile}
    >
      <IconButton onClick={onHandleCloseModal} className={classes.closeIconButton}>
        <Close />
      </IconButton>
      <DialogTitle
        id="alert-dialog-delete-email-account"
        classes={{
          root: classes.dialogTitle,
        }}
        data-id="email-delete-form"
      >
        {t('emails.deleteEmailAccount')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.wrapperContentModal}>
          <p className={classes.modalText}>{t('emails.confirmationDeleteEmail')}</p>
          <p className={`${classes.modalText} ${classes.dividerWarning}`}>
            <strong>
              &quot;
              {emailAddress}
              &quot;
            </strong>
            ?
          </p>
          <WarningNotice
            message={t('emails.warningDeleteEmail')}
            className={classes.warningNotice}
          />
        </div>
      </DialogContent>
      <DialogActions classes={{
        root: classes.dialogActionsContent,
      }}
      >
        <OutlineButton
          onClick={onHandleCloseModal}
          color="primary"
        >
          {t('cancel')}
        </OutlineButton>
        <PrimaryButton
          onClick={() => handleDeleteAccount()}
          color="primary"
          autoFocus
          onLoading={loadingDeleteAccount}
          className={classes.save}
          data-id="button-submit-email-delete"
        >
          {t('emails.buttonConfirmation')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteEmailAccountModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  hostingId: PropTypes.number.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(withI18n()(DeleteEmailAccountModal));
