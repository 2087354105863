import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Arrow = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    border: 1px solid ${theme.palette.grey.primary};
    border-width: 1px 1px 0 0;
    height: 12px;
    position: absolute;
    width: 12px;
  `}
`;

export const Tooltip = styled.div`
  ${({ theme, active }) => css`
    max-width: ${active ? 'unset' : '0px'};
    opacity: ${active ? '1' : '0'};
    overflow: ${active ? 'unset' : 'hidden'};
    padding: 16px;
    pointer-events: none;
    position: absolute;
    transition: opacity 350ms ease-in-out;
    visibility: ${active ? 'visible' : 'hidden'};
    z-index: ${theme.layers.important};

    &.top {
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);

      & ${Arrow} {
        left: 50%;
        top: 100%;
        transform: translate(-50%, -22px) rotate(136deg);
      }
    }

    &.right {
      top: 50%;
      left: 100%;
      transform: translate(0, -50%);

      & ${Arrow} {
        left: 0;
        top: 50%;
        transform: translate(10px, -50%) rotate(230deg);
      }
    }

    &.bottom {
      left: auto;
      padding: 0;
      right: auto;
      transform: unset;

      & ${Arrow} {
        left: 50%;
        top: 0;
        transform: translate(-50%, 10px) rotate(315deg);
      }
    }

    &.bottomLeft {
      left: -300px;
      top: 25px;
      padding: 0;
      right: auto;
      transform: unset;

      & ${Arrow} {
        left: 50%;
        top: 0;
        transform: translate(-50%, 10px) rotate(315deg);
      }
    }

    &.bottomCenter {
      left: -100px;
      top: 25px;
      padding: 0;
      right: auto;
      transform: unset;

      & ${Arrow} {
        left: 50%;
        top: 0;
        transform: translate(-50%, 10px) rotate(315deg);
      }
    }

    &.left {
      top: 50%;
      transform: translate(-100%, -50%);

      & ${Arrow} {
        left: 100%;
        top: 50%;
        transform: translate(-22px, -50%) rotate(45deg);
      }
    }
  `}
`;

export const NestedChildren = styled.div`
    display: flex;
`;

export const Text = styled.div`
  ${({
    theme, width, variant, translateXMobile,
  }) => {
    const variants = {
      white: {
        backgroundColor: theme.palette.white.light,
        border: `1px solid ${theme.palette.grey.primary}`,
        borderRadius: '0px',
        fontFamily: theme.font.family.primary,
        fontSize: theme.font.sizes.sm,
        padding: '16px',
        textColor: theme.palette.grey.dark,
      },
      dark: {
        backgroundColor: theme.palette.grey.darklight,
        border: `1px solid ${theme.palette.grey.darklight}`,
        borderRadius: '3px',
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.xxs,
        padding: '8px 16px',
        textColor: theme.palette.white.light,
      },
    };

    return css`
      background-color: ${variants[variant].backgroundColor};
      border-radius:${variants[variant].borderRadius};
      border: ${variants[variant].border};
      box-shadow: 3px 4px 12px ${theme.shadows.grey.light};
      color: ${variants[variant].textColor};
      font-family: ${variants[variant].fontFamily};
      font-size: ${variants[variant].fontSize};
      font-weight: ${theme.font.weights.regular};
      min-width: ${width}px;
      padding: ${variants[variant].padding};

      @media (max-width: ${theme.breakpoints.md}) {
        transform: translateX(${translateXMobile});
      }
    `;
  }}
`;
