import styled, { css } from 'styled-components';

export const SelectOptionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;

    span {
      display: block;
      max-width: 218px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: ${theme.v2.breakpoints.sm}){
        max-width: 180px;
      }
    }

    div {
      display: inline-flex;
      position: relative;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color:${theme.v2.colors.white.primary};
    padding: 24px;
  `}
`;
