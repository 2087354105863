import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.wizard};
    height: 100%;
    margin: 0 80px 80px 80px;
    max-width: 100%;
    min-height: calc(100vh - 80px);
    position: relative;
    padding-bottom: 50px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const WrapperCircularProgress = styled.div`
  ${({ theme }) => css`
    height: 100%;
    margin: 0 80px 80px 80px;
    max-width: 100%;
    min-height: calc(100vh - 80px);
    position: relative;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const TitleCard = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 0;
    box-shadow: 0px 2px 4px 0px ${theme.v1.color.shadow.primary};
    display: flex;
    margin-bottom: 24;
    margin-top: 24;
    min-height: 60px;
    padding: 7px 24px;
    position: relative;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      box-shadow: none;
    }
  `}
`;

export const PageTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font: normal normal 300 24px/32px Roboto;
    letter-spacing: 0.01px;
    text-align: left;
  `}
`;

export const SectionSlanted = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.wizard};
    height: 40px;
    margin-top: -10px;
    transform: skewY(-1deg);
  `}
`;

export const SectionMainWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 10px;
    padding: 20px 46px 25px 46px;
  `}
`;

export const MainContent = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: 1194px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const MainContentLeft = styled.div`
  ${() => css`
    width: 38%;

    @media (max-width: 1194px) {
      width: 100%;
    }

    img {
      margin-left: -8px;
    }
  `}
`;

export const ImageLogo = styled.img``;

export const Paragraph = styled.p``;

export const MainText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 32px;
    font-weight: ${theme.v1.font.weights.bold};
    margin-top: 10px;

    ${Paragraph}:nth-child(1) {
      font-size: 17px;
      font-weight: ${theme.v1.font.weights.regular};
    }

    ${Paragraph}:nth-child(2) {
      font-size: 17px;
      font-weight: ${theme.v1.font.weights.bold};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${() => css`
    margin-top: 45px;
  `}
`;

export const TextInfo = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.canceled};
    font-weight: ${theme.v1.font.weights.regular};
    font-size: 11px;
    margin-top: 32px;

    @media (max-width: 1194px) {
      font-size: 12px;
    }
  `}
`;

export const MainContentRight = styled.div`
  ${() => css`
    align-items: flex-end;
    display: flex;

    span {
      align-items: center;
      background-color: blue;
      border-radius: 50%;
      display: flex;
      height: 186px;
      text-align: center;
      width: 186px;
    }
  `}
`;

export const Image = styled.img`
  ${() => css`
    margin-bottom: -24px;
    z-index: 1;

    @media (max-width: 1194px) {
      margin-top: 10px;
      width: 300px;
    }
  `}
`;

export const Footer = styled.div`
  ${() => css`
    color: #a6a6a6;
    display: flex;
    font-size: 11px;
    justify-content: center;
    margin-top: 32px;

    p {
      @media (max-width: 1194px) {
        font-size: 12px;
        width: 100%;
      }
    }
  `}
`;

export const MiddleWrapper = styled.div`
  ${() => css`
    padding: 20px 46px 25px 46px;
  `}
`;

export const WrapperVideo = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 0;
    box-shadow: 0px 2px 4px 0px ${theme.v1.color.shadow.primary};
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const Section = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary}
    margin-top: 24;
    min-height: 60px;
    padding: 30px 0px 30px 0px;
    text-align: center;
    width: 55%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      box-shadow: none;
      width: 90%;
    }

    @media (max-width: 1194px) {
      width: 90%;
    }
  `}
`;

export const PageTitlePartTwo = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 32px;
    font-weight: ${theme.v1.font.weights.bold};

    @media (max-width: 1194px) {
      font-size: 2rem;
    }
  `}
`;

export const CardVideo = styled.div`
  @media (max-width: 1194px) {
    width: 90%;
  }

  iframe {
    @media (max-width: 1194px) {
      width: 100%;
    }
  }
`;

export const ButtonWrapperVideo = styled.div`
  ${() => css`
    margin-top: 0px;
  `}
`;

export const ListInfAcademyPass = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.background.tooltip};
    display: flex;
    justify-content: space-around;
    padding: 46px;
    width: 62%;

    @media (max-width: 1194px) {
      margin-left: 0;
      width: 100%;
    }
  `}
`;

export const Icon = styled.div`
  color: #f67921;
  padding: 0px 13px 0px 0px;
`;

export const InfOne = styled.div`
  ${() => css`
    display: flex;
    font-size: 18px;
  `}
`;

export const InfTwo = styled.div`
  ${() => css`
    display: flex;
    font-size: 18px;
  `}
`;

export const InfThree = styled.div`
  ${() => css`
    display: flex;
    font-size: 18px;
  `}
`;

export const FooterVideo = styled.div`
  ${() => css`
    margin-bottom: 50px;
    padding-top: 25px;
    width: 28%;

    @media (max-width: 1194px) {
      width: 75%;
    }
  `}
`;

export const TextFooterVideo = styled.p`
  ${() => css`
    color: #a6a6a6;
    font-size: 11px;

    @media (max-width: 1194px) {
      font-size: 12px;
    }
  `}
`;

export const WrapperSecond = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: 0;
    box-shadow: 0px 2px 4px 0px ${theme.v1.color.shadow.primary};
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const Cards = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 1194px) {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `}
`;

export const WrapperThree = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.primary.main};
    border-radius: 0;
    box-shadow: 0px 2px 4px 0px ${theme.v1.color.shadow.primary};
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const SectionLine = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    width: 100%;
  }
`;

export const SectionSelo = styled.div`
  position: relative;
`;

export const PageTitleFooter = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-family: ${theme.v1.font.family.primary}
    font-size: 32px;
    font-weight: ${theme.v1.font.weights.bold};
  `}
`;

export const SectionFooter = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    text-align: center;
    padding-top: 10px;
    width: 55%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      box-shadow: none;
    }
  `}
`;

export const SectionTextFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: ${theme.v1.font.family.primary};
    text-align: center;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      box-shadow: none;
    }
  `}
`;

export const PageTextFooter = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-size: 17px;
    width: 36%;
    @media (max-width: 1194px) {
      width: 70%;
    }
  `}
`;

export const ButtonFooter = styled.div`
  margin-top: 20px;
  z-index: 1;
`;

export const DivFooter = styled.div`
  ${() => css`
    margin-top: 68px;
    width: 28%;
    @media (max-width: 1194px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `}
`;

export const TextFooter = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-size: 11px;
    @media (max-width: 1194px) {
      font-size: 12px;
      width: 75%;
    }
  `}
`;

export const WrapperSnapFooter = styled.div`
  ${({ theme }) => css`
    align-items: baseline;
    display: flex;
    background-color: ${theme.v1.color.white.primary};
    border-radius: 0;
    box-shadow: 0px 2px 4px 0px ${theme.v1.color.shadow.primary};
    padding-bottom: 46px;
    width: 100%;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const ImgSnapFooter = styled.img`
  @media (max-width: 1194px) {
    display: none;
  }
`;

export const DivImage = styled.div`
  display: flex;
  margin-top: -243px;
  margin-bottom: -65px;
  width: 92%;
  @media (max-width: 1194px) {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
