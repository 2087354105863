import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding: 32px;
  `}
`;

export const Data = styled.div`
    padding-top: 24px;
`;

export const Header = styled.header``;

export const TitleModal = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.attention};
    font-family: ${theme.v1.font.family.primary};
    font-size: 22px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 16px 0 16px 0;
    padding: 0;
  `}
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 0;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin: 56px 0 16px 0;
`;

export const ControlButton = styled.div`
${({ theme }) => css`
    ${Button}:not(:first-child) {
      margin-left: 12px;
    }
    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      display: grid;
    }
  `}
`;

export const InfoIcon = styled.span`
  ${({ theme }) => css`
    display: contents;
    margin: 24px 0 0 0;
    & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.label};
    display: inline-flex;
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
  `}
`;

export const ContentCloseButton = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

export const ContentData = styled.div`
  ${({ theme }) => css`
    text-align:center;

    &:not(:first-child) {
      margin-top: 48px;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const Cronometer = styled.img`
${({ theme }) => css`
    & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;
