import React from 'react';
import * as Styles from './ConfiguredDomainStatus.styles';
import { Button } from 'gatorcomponents';
import { useConfiguredDomainStatus } from '@/hooks/domains/useConfiguredDomainStatus';

const ConfiguredDomainStatus = ({
  configuredDomainStatusTexts,
  domain,
  externalDns,
  CheckStatusAnalyticEvent = () => {},
}) => {
  const {
    loadedStatus,
    loadingStatus,
    configuredDomainStatus,
    verifyDomainConfiguration,
  } = useConfiguredDomainStatus(domain);

  const handleVerifyStatusClick = () => {
    CheckStatusAnalyticEvent();
    !loadingStatus && verifyDomainConfiguration();
  };

  return (
    <Styles.Wrapper data-testid="configure-domain-status-card">
      <Styles.Title>
        {configuredDomainStatusTexts.title}
      </Styles.Title>
      <Styles.PlatformDescription>
        {configuredDomainStatusTexts.description(externalDns)}
      </Styles.PlatformDescription>
      {!loadedStatus && (
        <Styles.ButtonWrapper>
          <Button
            onClick={handleVerifyStatusClick}
            size="large"
            label={configuredDomainStatusTexts.buttonLabel}
            title={configuredDomainStatusTexts.buttonLabel}
            loading={loadingStatus}
            testId="configure-status-get-result"
          />
        </Styles.ButtonWrapper>
      )}
      {loadedStatus && (
        <Styles.StatusResultWrapper data-testid="configured-status-result">
          {configuredDomainStatusTexts.result(configuredDomainStatus)}
        </Styles.StatusResultWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default ConfiguredDomainStatus;
