import React, { } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './InvoiceGeneratorModal.styles';
import { IconLoading } from 'gatorcomponents';

const InvoiceGeneratorModal = ({
  displayModal = true,
  testId = 'invoice-generate-modal',
  title = 'Por favor, aguarde',
  description = ['Estamos te levando para o pagamento.', 'Não feche esta janela.'],
}) => (
  displayModal && (
    <Modal maxWidth={550} testId={testId} variant="regular">
      <Styles.IconWrapper>
        <IconLoading size={48} />
      </Styles.IconWrapper>

      <Styles.ContentWrapper>
        <Styles.ModalTitle data-testid={`${testId}-title`}>{title}</Styles.ModalTitle>
        <Styles.ModalText data-testid={`${testId}-description`}>{description[0]}</Styles.ModalText>
        <Styles.ModalText data-testid={`${testId}-description-2`}>{description[1]}</Styles.ModalText>
      </Styles.ContentWrapper>
    </Modal>
  )
);

export default InvoiceGeneratorModal;
