import { LinkNaBioActionTypes } from './linkNaBio.types';

const linkNaBioAvailabilityRequest = ({ link }) => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_REQUEST, payload: { link } });
const linkNaBioAvailabilitySuccess = () => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_SUCCESS });
const linkNaBioAvailabilityFailure = () => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_FAILURE });
const linkNaBioAvailabilitySet = payload => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_SET, payload });

const createLinkNaBioRequest = ({
  linkName, title, description, template, colorScheme, links, callback, icons,
}) => ({
  type: LinkNaBioActionTypes.CREATE_LINK_NA_BIO_REQUEST,
  payload: {
    linkName, title, description, template, colorScheme, links, callback, icons,
  },
});
const createLinkNaBioSuccess = () => ({ type: LinkNaBioActionTypes.CREATE_LINK_NA_BIO_SUCCESS });
const createLinkNaBioFailure = () => ({ type: LinkNaBioActionTypes.CREATE_LINK_NA_BIO_FAILURE });

const getLinkNaBioRequest = () => ({ type: LinkNaBioActionTypes.GET_LINK_NA_BIO_REQUEST });
const getLinkNaBioSuccess = () => ({ type: LinkNaBioActionTypes.GET_LINK_NA_BIO_SUCCESS });
const getLinkNaBioFailure = () => ({ type: LinkNaBioActionTypes.GET_LINK_NA_BIO_FAILURE });

const updateLinkNaBioRequest = ({
  linkName, title, description, template, colorScheme, profileId, callback, domainToLink, isRemovingDomain, isExternalDomain, nameServer, hostingId, domainId,
}) => ({
  type: LinkNaBioActionTypes.UPDATE_LINK_NA_BIO_REQUEST,
  payload: {
    linkName, title, description, template, colorScheme, profileId, callback, domainToLink, isRemovingDomain, isExternalDomain, nameServer, hostingId, domainId,
  },
});
const updateLinkNaBioSuccess = () => ({ type: LinkNaBioActionTypes.UPDATE_LINK_NA_BIO_SUCCESS });
const updateLinkNaBioFailure = () => ({ type: LinkNaBioActionTypes.UPDATE_LINK_NA_BIO_FAILURE });

const deleteLinkNaBioRequest = ({ profileId }) => ({ type: LinkNaBioActionTypes.DELETE_LINK_NA_BIO_REQUEST, payload: { profileId } });
const deleteLinkNaBioSuccess = () => ({ type: LinkNaBioActionTypes.DELETE_LINK_NA_BIO_SUCCESS });
const deleteLinkNaBioFailure = () => ({ type: LinkNaBioActionTypes.DELETE_LINK_NA_BIO_FAILURE });

const addLinkRequest = ({ links, profileId }) => ({ type: LinkNaBioActionTypes.ADD_LINKS_REQUEST, payload: { links, profileId } });
const addLinkSuccess = () => ({ type: LinkNaBioActionTypes.ADD_LINKS_SUCCESS });
const addLinkFailure = () => ({ type: LinkNaBioActionTypes.ADD_LINKS_FAILURE });

const updateLinkRequest = ({ link, profileId }) => ({ type: LinkNaBioActionTypes.UPDATE_LINK_REQUEST, payload: { link, profileId } });
const updateLinkSuccess = () => ({ type: LinkNaBioActionTypes.ADD_LINKS_SUCCESS });
const updateLinkFailure = () => ({ type: LinkNaBioActionTypes.ADD_LINKS_FAILURE });

const sortLinkRequest = ({ links, profileId }) => ({ type: LinkNaBioActionTypes.SORT_LINKS_REQUEST, payload: { links, profileId } });
const sortLinkSuccess = () => ({ type: LinkNaBioActionTypes.SORT_LINKS_SUCCESS });
const sortLinkFailure = () => ({ type: LinkNaBioActionTypes.SORT_LINKS_FAILURE });

const deleteLinkRequest = ({ linkId, profileId }) => ({ type: LinkNaBioActionTypes.DELETE_LINK_REQUEST, payload: { linkId, profileId } });
const deleteLinkSuccess = () => ({ type: LinkNaBioActionTypes.DELETE_LINK_SUCCESS });
const deleteLinkFailure = () => ({ type: LinkNaBioActionTypes.DELETE_LINK_FAILURE });

const addSocialMediaRequest = ({ links, profileId }) => ({ type: LinkNaBioActionTypes.ADD_SOCIAL_MEDIA_REQUEST, payload: { links, profileId } });
const addSocialMediaSuccess = () => ({ type: LinkNaBioActionTypes.ADD_SOCIAL_MEDIA_SUCCESS });
const addSocialMediaFailure = () => ({ type: LinkNaBioActionTypes.ADD_SOCIAL_MEDIA_FAILURE });

const updateSocialMediaRequest = ({ link, profileId }) => ({ type: LinkNaBioActionTypes.UPDATE_SOCIAL_MEDIA_REQUEST, payload: { link, profileId } });
const updateSocialMediaSuccess = () => ({ type: LinkNaBioActionTypes.UPDATE_SOCIAL_MEDIA_SUCCESS });
const updateSocialMediaFailure = () => ({ type: LinkNaBioActionTypes.UPDATE_SOCIAL_MEDIA_FAILURE });

const sortSocialMediaRequest = ({ links, profileId }) => ({ type: LinkNaBioActionTypes.SORT_SOCIAL_MEDIA_REQUEST, payload: { links, profileId } });
const sortSocialMediaSuccess = () => ({ type: LinkNaBioActionTypes.SORT_SOCIAL_MEDIA_SUCCESS });
const sortSocialMediaFailure = () => ({ type: LinkNaBioActionTypes.SORT_SOCIAL_MEDIA_FAILURE });

const deleteSocialMediaRequest = ({ linkId, profileId }) => ({ type: LinkNaBioActionTypes.DELETE_SOCIAL_MEDIA_REQUEST, payload: { linkId, profileId } });
const deleteSocialMediaSuccess = () => ({ type: LinkNaBioActionTypes.DELETE_SOCIAL_MEDIA_SUCCESS });
const deleteSocialMediaFailure = () => ({ type: LinkNaBioActionTypes.DELETE_SOCIAL_MEDIA_FAILURE });

const socialMediaIconsRequest = () => ({ type: LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_REQUEST });
const socialMediaIconsSuccess = () => ({ type: LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_SUCCESS });
const socialMediaIconsFailure = () => ({ type: LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_FAILURE });
const socialMediaIconsSet = payload => ({ type: LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_SET, payload });

const statisticRequest = ({ profileId, periodIntervalInDays }) => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_REQUEST, payload: { profileId, periodIntervalInDays } });
const statisticSuccess = () => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_SUCCESS });
const statisticFailure = () => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_FAILURE });
const statisticSet = payload => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_SET, payload });

const linkNaBioLoading = payload => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_LOADING, payload });
const socialMediaLoading = payload => ({ type: LinkNaBioActionTypes.SOCIAL_MEDIA_FROM_LINK_NA_BIO_LOADING, payload });
const linksLoading = payload => ({ type: LinkNaBioActionTypes.LINKS_FROM_LINK_NA_BIO_LOADING, payload });
const linkNaBioProfilesSet = payload => ({ type: LinkNaBioActionTypes.LINK_NA_BIO_PROFILES_SET, payload });

const verifyDomainToLinkExistsReset = () => ({ type: LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_RESET });
const verifyDomainToLinkExistsSuccess = () => ({ type: LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_SUCCESS });
const verifyDomainToLinkExistsFailure = () => ({ type: LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_FAILURE });

const actions = {
  profile: {
    availability: {
      request: linkNaBioAvailabilityRequest,
      success: linkNaBioAvailabilitySuccess,
      failure: linkNaBioAvailabilityFailure,
      set: linkNaBioAvailabilitySet,
    },
    create: {
      request: createLinkNaBioRequest,
      success: createLinkNaBioSuccess,
      failure: createLinkNaBioFailure,
    },
    get: {
      request: getLinkNaBioRequest,
      success: getLinkNaBioSuccess,
      failure: getLinkNaBioFailure,
    },
    update: {
      request: updateLinkNaBioRequest,
      success: updateLinkNaBioSuccess,
      failure: updateLinkNaBioFailure,
    },
    delete: {
      request: deleteLinkNaBioRequest,
      success: deleteLinkNaBioSuccess,
      failure: deleteLinkNaBioFailure,
    },
    loading: linkNaBioLoading,
    set: linkNaBioProfilesSet,
  },
  link: {
    loading: linksLoading,
    add: {
      request: addLinkRequest,
      success: addLinkSuccess,
      failure: addLinkFailure,
    },
    update: {
      request: updateLinkRequest,
      success: updateLinkSuccess,
      failure: updateLinkFailure,
    },
    sort: {
      request: sortLinkRequest,
      success: sortLinkSuccess,
      failure: sortLinkFailure,
    },
    delete: {
      request: deleteLinkRequest,
      success: deleteLinkSuccess,
      failure: deleteLinkFailure,
    },
  },
  socialMedia: {
    loading: socialMediaLoading,
    add: {
      request: addSocialMediaRequest,
      success: addSocialMediaSuccess,
      failure: addSocialMediaFailure,
    },
    update: {
      request: updateSocialMediaRequest,
      success: updateSocialMediaSuccess,
      failure: updateSocialMediaFailure,
    },
    sort: {
      request: sortSocialMediaRequest,
      success: sortSocialMediaSuccess,
      failure: sortSocialMediaFailure,
    },
    delete: {
      request: deleteSocialMediaRequest,
      success: deleteSocialMediaSuccess,
      failure: deleteSocialMediaFailure,
    },
    icons: {
      request: socialMediaIconsRequest,
      success: socialMediaIconsSuccess,
      failure: socialMediaIconsFailure,
      set: socialMediaIconsSet,
    },
  },
  statistic: {
    request: statisticRequest,
    success: statisticSuccess,
    failure: statisticFailure,
    set: statisticSet,
  },
  domainVerify: {
    reset: verifyDomainToLinkExistsReset,
    success: verifyDomainToLinkExistsSuccess,
    failure: verifyDomainToLinkExistsFailure,
  },
};

export default actions;
