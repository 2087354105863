import styled, { css } from 'styled-components';


export const Wrapper = styled.div`
  padding-top: 24px;
`;

export const DescriptionWrapper = styled.span`
  ${({ theme }) => css`
    display:flex;
    svg{
      margin-left: 8px;

      &:hover{
        fill: ${theme.v1.color.text.featured};
      }
    }
    @media (max-width: ${theme.v1.breakpoints.sm}){
      display:inline-block;

      > div {
        display:inline-block;
      }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    span{
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.v1.font.weights.medium};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:center;
  margin: 40px auto 0px auto;
  max-width: 502px;
`;

export const PluginContainer = styled.div`
  display:flex;
  justify-content:center;
`;

export const PluginWrapper = styled.div`
  ${({ theme }) => css`
    display:flex;
    flex-wrap:wrap;
    gap: 16px;
    margin-top: 40px;
    max-width:752px;

    @media (max-width: ${theme.v1.breakpoints.lg}) {
      max-width:496px;
    }

    @media (max-width: 630px) {
      max-width:240px;
    }

  `}
`;
