const styles = theme => ({
  orOptionWrapper: {
    margin: '0px 36px',
    alignSelf: 'center',
  },
  orOption: {
    fontSize: 16,
    lineHeight: '19px',
    color: theme.color.thundora,
    fontWeight: 500,
  },
  providerLinkWrapper: {
    margin: '15px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  providerLink: {
    color: theme.color.indigo,
    fontWeight: 500,
    fontSize: 12,
    marginRight: 20,
    marginBottom: 10,
  },
  collapseWrapper: {
    marginTop: 15,
  },
  optionWrapper: {
    display: 'flex',
    marginTop: 23,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  option: {
    display: 'inline-block',
    flex: 1,
    border: `1px solid ${theme.color.geyser}`,
    padding: '15px 20px',
    '&:last-child': {
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0 0 0',
      },
    },
    [theme.breakpoints.down(1220)]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  optionTable: {
    display: 'table',
  },
  titleBottom: {
    marginBottom: 5,
  },
  groupTitle: {
    display: 'block',
    fontSize: 14,
    fontWeight: 500,
    color: theme.color.tundora,
    marginBottom: 10,
  },
  groupSubtitle: {
    color: theme.color.regentGray,
    display: 'block',
    fontSize: '16px',
    lineHeight: '26px',
    margin: '8px 0 2px 0',

    '& > span': {
      borderBottom: `1px dashed ${theme.color.indigo}`,
      color: theme.color.indigo,
    },
  },
  collapseContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  groupLink: {
    display: 'block',
    fontSize: 14,
    color: theme.color.indigo,
    marginTop: 5,
  },
  link: {
    alignItems: 'center',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: 16,
    marginLeft: 5,
  },
  arrow: {
    fill: theme.color.tundora,
  },
  collapse: {
    padding: '0 8px',
  },
  lens: {
    fontSize: 6,
    marginRight: 5,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    userSelect: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    outline: 'none',
    textTransform: 'none',
    color: theme.color.tundora,
    padding: '5px 0',
    fontSize: 12,
    fontWeight: 500,
    '&:hover': {
      background: 'none',
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      background: 'none',
      border: 'none',
      outline: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  dBlock: {
    display: 'block',
  },
  firstCardWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: '0px 2px 4px 0px #DBDBDB',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 12,
    padding: '24px 38px 24px 36px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  cardWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: '0px 2px 4px 0px #DBDBDB',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    padding: '24px 38px 24px 36px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  cardWrapperColumn: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    fontSize: '24px',
    color: theme.color.tundora,
    userSelect: 'none',
    [theme.breakpoints.up(379)]: {
      whiteSpace: 'nowrap',
    },
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
  },
});
export default styles;
