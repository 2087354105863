const styles = theme => ({
  memorial: {
    border: `1px solid ${theme.color.indigo}`,
    display: 'flex',
    marginBottom: '24px',
    padding: '12px',
  },

  memorialText: {
    color: theme.color.tundora,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
  },

  coinSvg: {
    alignSelf: 'center',
    fill: theme.color.indigo,
    marginRight: '12px',
  },
});

export default styles;
