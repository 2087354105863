import React from 'react';
import * as Style from './IconDetailedGear.style';

const IconDetailedGear = ({
  color = '#4a4a4a',
  backgroundColor = '#3c97ff',
  width = '35',
  height = '28',
  testId = 'icon-detailed-gear',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34.987 27.739" fill={color}>
      <g id="Grupo_39945" data-name="Grupo 39945" transform="translate(-312 -325)">
        <path id="Oval" d="M298.943,560.093A15.366,15.366,0,0,0,314.3,544.73H283.58a15.363,15.363,0,0,0,15.363,15.363Z" transform="translate(32.682 -207.354)" fill={backgroundColor} />
        <g id="Oval-2" transform="translate(320.102 333.109)">
          <ellipse id="Elipse_1162" data-name="Elipse 1162" cx="5.761" cy="5.761" rx="5.761" ry="5.761" transform="translate(0 0.001)" fill="#fff" />
          <g id="Grupo_37654" data-name="Grupo 37654" transform="translate(0)">
            <path id="Caminho_244175" data-name="Caminho 244175" d="M296.084,547.67a5.761,5.761,0,1,1,5.761-5.761A5.768,5.768,0,0,1,296.084,547.67Zm0-8.837a3.076,3.076,0,1,0,3.076,3.076A3.08,3.08,0,0,0,296.084,538.832Z" transform="translate(-290.323 -536.147)" />
          </g>
        </g>
        <g id="icone-cpanel" transform="translate(312 325)">
          <g id="cog-outline">
            <path id="Caminho_244176" data-name="Caminho 244176" d="M293.011,549.451h-8.527l-.528-3.864a10.249,10.249,0,0,1-1.263-.752l-3.514,1.451-2.088-3.342-2.172-4.159,3.008-2.414-.051-.718.052-.752-3.039-2.39,4.253-7.5,3.558,1.455a10.423,10.423,0,0,1,1.259-.743l.528-3.859,4.086-.021,4.44.02.528,3.859a10.416,10.416,0,0,1,1.26.744l3.549-1.452,2.146,3.683,2.1,3.827-3.031,2.383.052.752-.052.752,3.047,2.4-2.208,3.844-2.04,3.656-3.568-1.459a10.571,10.571,0,0,1-1.26.743Zm-6.455-2.424h4.384l.428-3.127.632-.264a8.149,8.149,0,0,0,1.9-1.124l.54-.427,2.882,1.179,2.18-3.849-2.4-1.886.129-1.877-.129-1.876,2.415-1.9-1.086-1.977-1.09-1.87-2.9,1.186-.54-.427a8.091,8.091,0,0,0-1.9-1.123l-.632-.265-.428-3.131-2.367-.01-2.013.01-.428,3.131-.631.265a8.1,8.1,0,0,0-1.9,1.124l-.54.427-2.892-1.183-2.18,3.849,2.407,1.892-.129,1.875.13,1.827-2.438,1.956,1.217,2.329.95,1.518,2.938-1.213.544.44a7.793,7.793,0,0,0,1.892,1.128l.634.264Z" transform="translate(-274.889 -521.839)" />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconDetailedGear;
