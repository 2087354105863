const styles = theme => ({
  save: {
    minWidth: 176,
  },
  wrapperContentModal: {
    color: theme.color.tundora,
  },
  modalContainer: {
    [theme.breakpoints.up(491)]: {
      height: 'inherit',
      marginTop: 70,
    },
  },
  modalTitle: {
    fontSize: 24,
    margin: 0,
    padding: 0,
  },
  modalText: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
  modalPaper: {
    borderRadius: 0,
    padding: '10px 16px',
    minWidth: 475,
    [theme.breakpoints.down(491)]: {
      minWidth: '100%',
      padding: 0,
      paddingTop: 10,
    },
  },
  dialogTitle: {
    paddingBottom: 8,
  },
  dividerWarning: {
    marginBottom: 20,
  },
  closeIconButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  warningNotice: {
    marginTop: 10,
    marginBottom: 10,
  },
  dialogActionsContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 6,
  },
});

export default styles;
