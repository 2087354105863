import { COUNTRY } from '@/config';

const countrySufix = () => (COUNTRY !== 'br' ? COUNTRY : 'com.br');

export const getBuyProductService = () => {
  const routeWebHosting = COUNTRY === 'br' ? 'hospedagem-de-sites' : 'web-hosting';
  return `https://www.hostgator.${countrySufix()}/${routeWebHosting}`;
};

export const getLinkServerStatus = () => `https://status.hostgator.${countrySufix()}`;
