import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 24px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Row = styled.div`
  ${({ theme, isTitle }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    min-height: 80px;
    justify-content: space-between;
    padding: 23px 34px;
    width: 100%;

    margin-bottom: ${isTitle && '8px'};

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 23px 10px;
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme, lastRow }) => css`
    background-color: ${theme.v2.colors.feedback.canceledPure};
    border-left: 34px solid ${theme.v2.colors.neutral.highPure};
    border-right: 34px solid ${theme.v2.colors.neutral.highPure};
    height: ${!lastRow && '1px'};
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      border-left: 10px solid ${theme.v2.colors.neutral.highPure};
      border-right: 10px solid ${theme.v2.colors.neutral.highPure};
    }
  `}
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    margin: 0;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const Profiles = styled.div`
`;

export const ListItem = styled.div`
`;

export const Dropdown = styled.div`
  ${({ theme }) => css`
  display: none;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: block;
    }
  `}
`;

export const ProfileActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      width: 100%;

      div:last-child{
        display: none;
      }
    }
  `}
`;

export const ProfileLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    word-break: break-all;
  `}
`;

export const ButtonWrapper = styled.div`
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 30px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 16px 16px 16px;
`;

export const ModalTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: 0;
    margin-bottom: 3px;
  `}
`;

export const ModalAlert = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};

    p {
      line-height: 19px;
      margin: 0;
    }
  `}
`;

export const DomainLinkedWrapper = styled.div`
    ${({ theme, isMobileComponent }) => css`
    display: ${isMobileComponent ? 'none' : 'flex'};
    width: 100%;
    
    svg {
      fill: ${({ theme }) => theme.v2.colors.brand.menuPrimaryDark};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: ${isMobileComponent ? 'flex' : 'none'};
    }
  `}
`;

export const LinkText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
  `}
`;

export const DomainLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    word-break: break-all;
  `}
`;

export const Banner = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.menuPrimaryDark};
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    min-height: 80px;
    overflow: hidden;
    padding: 16px 280px 16px 30px;
    position: relative;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 16px 50px 16px 30px;
    }
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.v2.colors.neutral.highPure};
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    height: 48px;
    line-height: 20.57px;
    margin-left: -42px;
    padding: 16px 24px;
    transform: rotate(-2.5deg);

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.xxs};
      margin-top: -20px;
    }
  `}
`;

export const BannerTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.highPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;

    strong {
      color: ${theme.v2.colors.feedback.attentionPure};
    }
  `}
`;

export const BannerDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.highPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    margin: 0;
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const IconCloseWrapper = styled.div`
  position: absolute;
  right: 22px;
  top: 26px;
`;

export const Rainbow = styled.div`
  background: linear-gradient(90deg, #1D5297 0%, #FFCF00 9.63%, #2E93EE 19.15%, #F67922 28.15%, #2E93EE 39.33%, #FFE885 52.38%, #F67922 63.48%, #2E93EE 73.53%, #1D5297 82.76%, #FFCF00 91.23%, #F67922 100%);
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
`;


export const BannerButtonWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 64px;
    top: 24px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      position: static;
    }
  `}
`;
