import styled, { css } from 'styled-components';
import modalDecoLine from '@/media/modalCreateSite/decoline.png';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: 40px 119px 60px 88px};
    position:relative;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 100%;
      padding: 133px 73px 224px 36px;
    }
  `}
`;

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.regular};
    letter-spacing: 0px;
    line-height: 24px;
    margin: 0px 0px 16px 0px;
    overflow-x: unset;
    text-overflow: ellipsis;
    span {
      color: ${theme.v1.color.action.hover};
      font-weight: ${theme.v1.font.weights.bold};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-bottom: 16px;
    }
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: inline-block;
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    line-height: 21.6px;
    margin: 0px 0px 26px 0px;
    span {
      color: ${theme.v1.color.text.primary};
      font-weight: ${theme.v1.font.weights.bold};
    }
  `}
`;

export const Action = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 30px;
    margin-top: 6px;
    position: relative;
    z-index: 4;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
      gap: 8px;
      button:first-child {
        width: fit-content;
      }
    }
  `}
`;

export const DecoLine = styled.img`
  background-image: url(${modalDecoLine});
  bottom: 0;
  height:6px;
  left: 0;
  object-fit:none;
  position:absolute;
  width: 100%;
`;

export const Deco = styled.img`
  ${({ theme }) => css`
    height: 121px;
    left: 0;
    object-fit:none;
    position:absolute;
    top: 0;
    width: 53px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 80px;
    }
 `}
`;

export const DecoIcons = styled.div`
  ${({ theme }) => css`
    bottom: 51px;
    display: flex;
    height: 72px;
    position: absolute;
    right: 33px;
    z-index: ${theme.v1.layers.overlay};
    span:last-child{
      align-self: end;
      margin-left: 4px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      bottom: 63px;
      height: 105px;
      right: 50px;
    }
  `}
`;

export const DecoTriangle = styled.div`
  ${({ theme }) => css`
    background:linear-gradient(to bottom right,#ffffff00 50%,${theme.v1.color.background.wizard} 50%);
    bottom: 0;
    height: 87px;
    position:absolute;
    right: 0;
    width: 110px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 243px;
      width: 306px
    }
  `}
`;

export const SnappyFullBody = styled.img`
  ${({ theme }) => css`
    display: none;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      bottom: 45px;
      display: block;
      position: absolute;
      right: 44px;
      z-index:2;
    }
  `}
`;
