import styled, { css } from 'styled-components';

export const CardText = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    position: relative;
  `}
`;

export const ManageList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 8px 0px 0px 0px;
    padding: 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      gap: 24px;
    }
  `}
`;

export const ManageSkeletonList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 8px 0px 0px 0px;
    padding: 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      gap: 24px;
    }
  `}
`;

export const ManageItemSkeleton = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding: 0px;
    
    @media(min-width:${theme.v2.breakpoints.sm}){
      display: flex;
      flex-direction: row;
      gap: 32px;
      
      > div:first-child {
        flex: 1 1 320px
      }
    }
  `}
`;

export const ManageItem = styled.li`
  list-style-type: none;
`;

export const ManageItemTitle = styled(CardTitle)`
  ${({ theme }) => css`
    font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const ButtonWrapperDesktop = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    display: none;

    @media(min-width:${theme.v2.breakpoints.sm}){
      padding: 0px 0px 0px 32px;
      display: block;
    }
  `}
`;

export const ButtonWrapperMobile = styled.div`
  ${({ theme }) => css`
    display: block;
    margin-left: auto;
    margin: 14px 0px 6px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      display: none;
      margin: 0px;
      padding: 0px 0px 0px 32px;
    }
  `}
`;

export const ManageItemDescription = styled(CardText)`
  ${({ theme }) => css`
    font-weight: ${theme.v2.font.weights.regular};

    @media(min-width:${theme.v2.breakpoints.sm}){
      padding: 0px 0px 0px 32px;
    }
  `}
`;

export const ManageDivider = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure}
  `}
`;
