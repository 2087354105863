import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    padding: 34px 0px;
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0 0 8px 0px;

    span {
      color: ${theme.v1.color.text.featured};
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 0px 0px 40px 0px;
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 8px;

    svg:hover {
        fill: ${theme.v1.color.text.featured};
      }
  `}
`;

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0px 0px 25px 0px;
`;

export const EditIconWrapper = styled.span`
  margin: 0px;
`;

export const Triangle = styled.div`
  ${({ theme }) => css`
    border-bottom: 23px solid transparent;
    border-left: 23px solid ${theme.v1.color.warning.attention};
    height: 0;
    margin-right: 18px;
    width: 0;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v1.color.text.featured};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    line-height: 22px;
    margin: 0;
  `}
`;

export const TypesWrapper = styled.div`
${({ theme }) => css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 40px;
  max-width: 1020px;

  label {
    min-height: 74px;
  }
  div:nth-last-child(1) div label span:nth-last-child(1) svg{
    position:absolute;
    right:19px;
    top :27px;
    margin-right: 0px;
    &:hover {
      fill: ${theme.palette.blue.dark}
    }
  }
  `}
`;

export const ButtonWrapper = styled.div`
  max-width: 324px;
  width: 100%;
  position: relative;
`;

export const SelectWrapper = styled.div`
  margin: 0px 0px 40px;
`;

export const ContinueWrapper = styled.div`
  button{
    min-width:128px;
    min-height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span div {
      display: flex;
    }
  }
  div{
    padding: 0;
    margin: 0;
  }
`;
