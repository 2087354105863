import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import shortid from 'shortid';
import ShadowButton from '@/components/Buttons/ShadowButton';
import { chipLinkOfProductTagManager } from '@/utils/ThirdParties/tagManager';
import { getURLCpanel } from '@/config/urls/cpanelUrls';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CPANEL_SSO_PORTAL } from '@/config/GrowthBook/constants';

const PlanButtonsList = ({
  status,
  externalId,
  t,
}) => {
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);

  const getManagementButton = disabled => ({
    label: t('plan.detail'),
    url: `${t('routes.products')}${t('routes.productdetail')}/${externalId}`,
    disabled: disabled === 'disabled',
  });

  const getButtonsByStatus = (status) => {
    switch (status) {
      case 'on_configure':
      case 'cancelled':
      case 'expired':
        return [
          getManagementButton('disabled'),
        ];
      case 'in_analysis':
        return [
          getManagementButton('enabled'),
        ];
      case 'pending':
      case 'suspended':
        return [
          {
            label: t('payInvoiceAction'),
            url: `${t('routes.billing')}${t('routes.unpaid')}`,
          },
          getManagementButton('enabled'),
        ];
      case 'active':
        return [
          getManagementButton('enabled'),
          {
            label: 'cPanel',
            url: getURLCpanel({ externalId, enableCpanelSSO }),
            type: 'cpanel',
          },
        ];
      default:
        return [];
    }
  };

  return (
    getButtonsByStatus(status).map(button => (
      <ShadowButton
        key={shortid.generate()}
        blank
        to={button.url}
        disabled={button.disabled}
        onClick={() => chipLinkOfProductTagManager(button.label)}
      >
        {button.label}
      </ShadowButton>
    ))
  );
};

PlanButtonsList.propTypes = {
  status: PropTypes.string.isRequired,
  externalId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withI18n()(PlanButtonsList);
