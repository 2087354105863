// DOMAIN-AVAILABILITY

import {
  differenceInDays, isAfter, isBefore, parse,
} from 'date-fns';

const receiveLive = (rawData, datecreated) => {
  const { data } = rawData;

  if (!data || data.length === 0) return {};

  const onlyActiveLives = data.filter(live => live.status === 1);

  const onlyForAllowedUsers = onlyActiveLives.filter((live) => {
    const userCreationDate = parse(datecreated, 'yyyy-MM-dd', new Date());
    const daysSinceCreation = differenceInDays(new Date(), userCreationDate);

    return !live.recent_user || daysSinceCreation < live.recent_user;
  });

  const onlyAllowedDates = onlyForAllowedUsers.filter((live) => {
    const liveStart = parse(live.start_at, 'yyyy-MM-dd HH:mm:ss', new Date());
    const liveStop = parse(live.stop_at, 'yyyy-MM-dd HH:mm:ss', new Date());
    const now = new Date();

    return !live.stop_at || (isBefore(liveStart, now) && isAfter(liveStop, now));
  });

  const sortedLivesByStartTime = onlyAllowedDates.sort((a, b) => {
    const aStart = parse(a.start_at, 'yyyy-MM-dd HH:mm:ss', new Date());
    const bStart = parse(b.start_at, 'yyyy-MM-dd HH:mm:ss', new Date());

    return isAfter(aStart, bStart) || !a.start_at ? 1 : -1;
  });

  const liveData = {
    liveID: sortedLivesByStartTime[0].video_id,
    liveTitle: sortedLivesByStartTime[0].title,
    liveTag: sortedLivesByStartTime[0].tag,
    enabledBanner: !!sortedLivesByStartTime[0].banner,
    enableChat: !!sortedLivesByStartTime[0].chat,
    bannerImage: sortedLivesByStartTime[0].banner,
  };

  return liveData;
};

const assembly = {
  receiveLive,
};

export default assembly;
