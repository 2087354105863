export const COOKIES = {
  HG_SERVICE_TOKEN: 'hg-service-token',
  HG_REFRESH_TOKEN: 'hg-refresh-token',
};

export const SMS_ERROR = {
  INVALID_PASSWORD: 1,
  GENERIC_ERROR: 2,
  NO_MORE_ATTEMPTS: 3,
  DATA_IN_USE: 4,
  ALREADY_VALIDATED: 5,
  REQUEST_DISABLED: 6,
};
