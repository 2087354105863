import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    gap: 12px;
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 8px 0px 0px;
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryLowLight};
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin: 24px 0px 0px;
    padding: 16px 16px 32px;
  `}
`;

export const BoxTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const BoxDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryExtraDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const AlertWrapper = styled.div`
  max-width: 585px;

  > div {
    padding: 0px;
  }
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 24px 0px 0px;
`;
