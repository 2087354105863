import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, TextField } from '@material-ui/core';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import MaskIpv4 from '@/components/Fields/InputMasks/MaskIpv4';
import styles from './styles';


const DnsFormGroup = ({
  classes,
  label,
  handleMouseEnter,
  handleMouseLeave,
  index,
  children,
  required,
  valueNs,
  handleChangeNs,
  objectKey,
  error,
  t,
  onHandleIpChange,
  otherDns,
}) => (
  <div
    className={classes.group}
    onMouseEnter={index > 1 ? () => handleMouseEnter(index) : () => false}
    onMouseLeave={index > 1 ? () => handleMouseLeave(index) : () => false}
    data-testid="dnsMasterWrapper"
  >
    <div className={classes.masterWrapper}>
      <TextField
        required={required}
        id={`dns-${index}`}
        label={label}
        value={valueNs}
        onChange={e => handleChangeNs(objectKey, e)}
        error={error}
        variant="outlined"
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          'data-testid': 'masterWrapperTexField',
          inputProps: {
            maxLength: 200,
            'data-testid': `masterWrapperInput_${index}`,
          },
        }}

      />
      {children}
    </div>

    {otherDns.showIpField && (
      <div className={classes.subLine} data-testid="dnsFormGroupSubLine">
        <SubdirectoryArrowRight className={classes.svg} />
        <TextField
          id={`ip-${index}`}
          label={t('dnsSetup.other.ipAddress')}
          value={otherDns.ip}
          onChange={e => onHandleIpChange(objectKey, e)}
          margin="normal"
          variant="outlined"
          placeholder="___.___.___.___"
          className={classes.input}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            'data-testid': 'dnsFormGroupSubLineText',
            inputComponent: MaskIpv4,
            inputProps: {
              'data-testid': `showIpField_${index}`,
            },
          }}
        />
      </div>
    )}
  </div>
);

export default withI18n()(withStyles(styles)(DnsFormGroup));
