import { zoneManagerChangeDns } from '@/utils/ThirdParties/tagManager';

export const tagManagerDnsChange = (dnsDetails) => {
  let actualValue = null;
  let newValue = null;

  Object.values(dnsDetails.dbData).forEach((value, idx) => {
    if (value) {
      actualValue = Object.keys(dnsDetails.dbData)[idx];
    }
  });

  if (dnsDetails.native) {
    newValue = 'native';
  }

  if (dnsDetails.other) {
    newValue = 'other';
  }

  if (dnsDetails.hosting) {
    newValue = 'hosting';
  }

  const changeDnsText = `from_${actualValue}_to_${newValue}`;
  zoneManagerChangeDns(changeDnsText);
  return changeDnsText;
};
