/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Typography,
  Card,
  withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useSelector } from 'react-redux';
import CtaHeader from './CtaHeader';
import CtaTestimonials from './CtaTestimonials';
import CtaAdvantages from './CtaAdvantages';
import CtaFooter from './CtaFooter';
import CtaVideo from './CtaVideo';
import styles from './styles';
import { Redirect } from 'react-router';
import { isBRServer } from '@/utils/Validators/validation';

const AcademyPassCta = ({ classes, t }) => {
  const { redirectToInvoices, invoiceId, paymentMethod } = useSelector(state => state.academyPass.buyFastcheckout);

  useEffect(() => {
    if (redirectToInvoices && invoiceId) {
      window.location.href = (`${t('routes.billing')}${t('routes.unpaid')}/${invoiceId}/${paymentMethod}`);
    }
  }, [redirectToInvoices, invoiceId, paymentMethod, t]);

  if (isBRServer) {
    return <Redirect to={`${t('routes.courses')}`} />;
  }

  return (
    <div className={classes.wrapper} data-testid="academypass-cta">
      <Card data-testid="list-title-area" className={classes.titleCard}>
        <Typography data-testid="list-title" variant="h6" className={classes.pageTitle}>
          {t('academypass.cta.title')}
        </Typography>
      </Card>
      <div className={classes.ctaCards}>
        <CtaHeader />
        <CtaAdvantages />
        <CtaVideo />
        <CtaTestimonials />
        <CtaFooter />
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPassCta));
