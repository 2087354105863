const styles = theme => ({
  tableWrapper: {
    alignItems: 'center',
    border: `1px solid ${theme.color.darkSilver}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    maxHeight: '418px',
    overflow: 'hidden',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.color.silver,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: theme.color.indigo,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.indigo,
    },

  },
});

export default styles;
