import styled, { css } from 'styled-components';

export const Dot = styled.span`
  border-radius: 50%;
  height: 13px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 200ms ease-in-out;
  width: 13px;
  z-index: 0;
`;

export const Wrapper = styled.span`
  ${({
    theme, checked, size, variant,
  }) => {
    const variants = {
      default: {
        border: `2px solid ${checked ? theme.palette.primary.main : theme.palette.grey.dark}`,
        width: '24px',
        background: theme.palette.primary.main,
      },
      primary: {
        border: `2px solid ${checked ? theme.palette.blue.dark : theme.palette.secondary.primary}`,
        width: '20px',
        background: theme.palette.primary.main,
      },
      v1: {
        border: `2px solid ${checked ? theme.v1.color.action.primary : theme.v1.color.text.primary}`,
        width: '24px',
        background: theme.v1.color.action.primary,
      },
      sizes: {
        small: '16px',
        medium: '20px',
        default: '24px',
      },
    };

    return css`
    border-radius: 50%;
    border: ${variants[variant].border};
    display: inline-flex;
    flex: 0 0 ${size === 'small' || size === 'medium' ? variants.sizes[size] : '24px'};
    height: ${size === 'small' || size === 'medium' ? variants.sizes[size] : '24px'};
    position: relative;
    transition: border-color 200ms ease-in-out;
    width: ${size === 'small' || size === 'medium' ? variants.sizes[size] : '24px'};

    ${Dot} {
      background: ${variants[variant].background};
      flex: 0 0 ${size === 'small' ? '10px' : '13px'};
      height: ${size === 'small' ? '10px' : '13px'};
      width: ${size === 'small' ? '10px' : '13px'};
    }
  `;
  }}
`;

export const Input = styled.input`
  display: block;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  &:checked + ${Dot} {
    opacity: 1;
  }
`;
