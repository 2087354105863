/* API ERROR */
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';
/* API ERROR */

/* Auth */
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_VIMEO_TOKEN = 'SET_VIMEO_TOKEN';
export const UNSET_AUTH_TOKEN = 'UNSET_AUTH_TOKEN';
/* Auth */

/* Credit Card */
export const REQUEST_CREDIT_CARD = 'REQUEST_CREDIT_CARD';
export const RECEIVE_CREDIT_CARD = 'RECEIVE_CREDIT_CARD';
export const ERROR_CREDIT_CARD = 'ERROR_CREDIT_CARD';
export const DELETE_CREDIT_CARD_START = 'DELETE_CREDIT_CARD_START';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_ERROR = 'DELETE_CREDIT_CARD_ERROR';
export const SAVE_CREDIT_CARD_START = 'SAVE_CREDIT_CARD_START';
export const SAVE_CREDIT_CARD_SUCCESS = 'SAVE_CREDIT_CARD_SUCCESS';
export const SAVE_CREDIT_CARD_ERROR = 'SAVE_CREDIT_CARD_ERROR';
export const OPEN_PAYMENT_PAYPAL_NEW_TAB = 'OPEN_PAYMENT_PAYPAL_NEW_TAB';
/* Credit Card */

/* Credit Card Installments */
export const CREDIT_CARD_INSTALLMENTS_SUCCESS = 'CREDIT_CARD_INSTALLMENTS_SUCCESS';
export const CREDIT_CARD_INSTALLMENTS_ERROR = 'CREDIT_CARD_INSTALLMENTS_ERROR';
export const REQUEST_CREDIT_CARD_INSTALLMENTS = 'REQUEST_CREDIT_CARD_INSTALLMENTS';
/* Credit Card Installments */

/* Dns Forward */
export const DNS_DOMAIN_LIST_REQUEST = 'DNS_DOMAIN_LIST_REQUEST';
export const DNS_DOMAIN_LIST_RECEIVE = 'DNS_DOMAIN_LIST_RECEIVE';
export const DNS_DOMAIN_LIST_ERROR = 'DNS_DOMAIN_LIST_ERROR';
export const DNS_DETAILS_REQUEST = 'DNS_DETAILS_REQUEST';
export const DNS_DETAILS_RECEIVE = 'DNS_DETAILS_RECEIVE';
export const DNS_DETAILS_ERROR = 'DNS_DETAILS_ERROR';
export const SAVE_CHANGE_TO_DNS_NATIVE_REQUEST = 'SAVE_CHANGE_TO_DNS_NATIVE_REQUEST';
export const SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS = 'SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS';
export const SAVE_CHANGE_TO_DNS_NATIVE_ERROR = 'SAVE_CHANGE_TO_DNS_NATIVE_ERROR';
export const CHANGE_TO_OTHER_DNS = 'CHANGE_TO_OTHER_DNS';
export const CHANGE_TO_NATIVE_DNS = 'CHANGE_TO_NATIVE_DNS';
export const CHANGE_TO_HOSTING_DNS = 'CHANGE_TO_HOSTING_DNS';
export const SAVE_CHANGE_TO_DNS_OTHER_REQUEST = 'SAVE_CHANGE_TO_DNS_OTHER_REQUEST';
export const SAVE_CHANGE_TO_DNS_OTHER_SUCCESS = 'SAVE_CHANGE_TO_DNS_OTHER_SUCCESS';
export const SAVE_CHANGE_TO_DNS_OTHER_ERROR = 'SAVE_CHANGE_TO_DNS_OTHER_ERROR';
export const DNS_DOMAIN_LIST_EXTERNAL_REQUEST = 'DNS_DOMAIN_LIST_EXTERNAL_REQUEST';
export const DNS_DOMAIN_LIST_EXTERNAL_RECEIVE = 'DNS_DOMAIN_LIST_EXTERNAL_RECEIVE';
export const DNS_DOMAIN_LIST_EXTERNAL_ERROR = 'DNS_DOMAIN_LIST_EXTERNAL_ERROR';
export const SAVE_CHANGE_TO_DNS_HOSTING_REQUEST = 'SAVE_CHANGE_TO_DNS_HOSTING_REQUEST';
export const SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS = 'SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS';
export const SAVE_CHANGE_TO_DNS_HOSTING_ERROR = 'SAVE_CHANGE_TO_DNS_HOSTING_ERROR';
export const CHANGE_DNS_DOMAIN_STATE = 'CHANGE_DNS_DOMAIN_STATE';
export const FORCE_LOADING_STATUS = 'FORCE_LOADING_STATUS';
export const SET_CHOSEN_DOMAIN = 'SET_CHOSEN_DOMAIN';
/* Dns Forward */

/* Emails */
export const REQUEST_EMAILS = 'REQUEST_EMAILS';
export const RECEIVE_EMAILS = 'RECEIVE_EMAILS';
export const ERROR_EMAILS = 'ERROR_EMAILS';
export const ABORT_EMAILS = 'ABORT_EMAILS';
export const REQUEST_HOSTING_TYPES = 'REQUEST_HOSTING_TYPES';
export const RECEIVE_HOSTING_TYPES = 'RECEIVE_HOSTING_TYPES';
export const ERROR_HOSTING_TYPES = 'ERROR_HOSTING_TYPES';
export const ABORT_HOSTING_TYPES = 'ABORT_HOSTING_TYPES';
export const FILTERED_EMAILS = 'FILTERED_EMAILS';
export const REQUEST_HOST_DOMAIN = 'REQUEST_HOST_DOMAIN';
export const RECEIVE_HOST_DOMAIN = 'RECEIVE_HOST_DOMAIN';
export const ERROR_HOST_DOMAIN = 'ERROR_HOST_DOMAIN';
export const ABORT_HOST_DOMAIN = 'ABORT_HOST_DOMAIN';
export const REQUEST_CREATE_EMAIL = 'REQUEST_CREATE_EMAIL';
export const SUCCESS_CREATE_EMAIL = 'SUCCESS_CREATE_EMAIL';
export const ERROR_CREATE_EMAIL = 'ERROR_CREATE_EMAIL';
export const REQUEST_DELETE_EMAILS = 'REQUEST_DELETE_EMAILS';
export const SUCCESS_DELETE_EMAILS = 'SUCCESS_DELETE_EMAILS';
export const ERROR_DELETE_EMAILS = 'ERROR_DELETE_EMAILS';
export const REQUEST_ALTER_PASSWORD_EMAILS = 'REQUEST_ALTER_PASSWORD_EMAILS';
export const ERROR_ALTER_PASSWORD_EMAILS = 'ERROR_ALTER_PASSWORD_EMAILS';
export const SUCCESS_ALTER_PASSWORD_EMAILS = 'SUCCESS_ALTER_PASSWORD_EMAILS';
export const REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS = 'REQUEST_CHANGE_STORAGE_ACCOUNT_EMAILS';
export const SUCCESS_CHANGE_STORAGE_ACCOUNT_EMAILS = 'SUCCESS_CHANGE_STORAGE_EMAILS';
export const ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS = 'ERROR_CHANGE_STORAGE_ACCOUNT_EMAILS';
export const REQUEST_GET_PRO_EMAILS_LIST = 'REQUEST_GET_PRO_EMAILS_LIST';
export const RECEIVE_PRO_EMAILS_LIST = 'RECEIVE_PRO_EMAILS_LIST';
export const ERROR_GET_PRO_EMAILS_LIST = 'ERROR_GET_PRO_EMAILS_LIST';
export const REQUEST_GET_PRO_EMAILS_AUTH = 'REQUEST_GET_PRO_EMAILS_AUTH';
export const RECEIVE_PRO_EMAILS_AUTH = 'RECEIVE_PRO_EMAILS_AUTH';
export const ERROR_GET_PRO_EMAILS_AUTH = 'ERROR_GET_PRO_EMAILS_AUTH';
export const REQUEST_GET_UPGRADE_SUMMARY = 'REQUEST_GET_UPGRADE_SUMMARY';
export const RECEIVE_GET_UPGRADE_SUMMARY = 'RECEIVE_GET_UPGRADE_SUMMARY';
export const ERROR_GET_UPGRADE_SUMMARY = 'ERROR_GET_UPGRADE_SUMMARY';
export const REQUEST_UPGRADE_MAIL_INVOICE = 'REQUEST_UPGRADE_MAIL_INVOICE';
export const ERROR_UPGRADE_MAIL_INVOICE = 'ERROR_UPGRADE_MAIL_INVOICE';
export const RECEIVE_UPGRADE_MAIL_INVOICE = 'RECEIVE_UPGRADE_MAIL_INVOICE';
export const RECEIVE_UPGRADE_TITAN = 'RECEIVE_UPGRADE_TITAN';
export const RECEIVE_EXISTENT_INVOICE_VALIDATION = 'RECEIVE_EXISTENT_INVOICE_VALIDATION';
export const REQUEST_EXISTENT_INVOICE_VALIDATION = 'REQUEST_EXISTENT_INVOICE_VALIDATION';
export const ERROR_EXISTENT_INVOICE_VALIDATION = 'ERROR_EXISTENT_INVOICE_VALIDATION';
export const REQUEST_GET_CHECK_DOMAIN_AVAILABLE = 'REQUEST_GET_CHECK_DOMAIN_AVAILABLE';
export const ERROR_GET_CHECK_DOMAIN_AVAILABLE = 'ERROR_GET_CHECK_DOMAIN_AVAILABLE';
export const RECEIVE_GET_CHECK_DOMAIN_AVAILABLE = 'RECEIVE_GET_CHECK_DOMAIN_AVAILABLE';
export const REQUEST_GET_PRO_EMAIL_HOSTING_PLANS = 'REQUEST_GET_PRO_EMAIL_HOSTING_PLANS';
export const ERROR_GET_PRO_EMAIL_HOSTING_PLANS = 'ERROR_GET_PRO_EMAIL_HOSTING_PLANS';
export const RECEIVE_GET_PRO_EMAIL_HOSTING_PLANS = 'RECEIVE_GET_PRO_EMAIL_HOSTING_PLANS';
export const REQUEST_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN = 'REQUEST_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN';
export const ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN = 'ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN';
export const RECEIVE_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN = 'RECEIVE_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN';
export const REQUEST_GET_ACTIVE_EMAIL_CPANEL = 'REQUEST_GET_ACTIVE_EMAIL_CPANEL';
export const ERROR_GET_ACTIVE_EMAIL_CPANEL = 'ERROR_GET_ACTIVE_EMAIL_CPANEL';
export const RECEIVE_GET_ACTIVE_EMAIL_CPANEL = 'RECEIVE_GET_ACTIVE_EMAIL_CPANEL';
export const REQUEST_GET_PRO_EMAIL_UPGRADE_PLANS = 'REQUEST_GET_PRO_EMAIL_UPGRADE_PLANS';
export const RECEIVE_PRO_EMAIL_UPGRADE_PLANS = 'RECEIVE_PRO_EMAIL_UPGRADE_PLANS';
export const ERROR_GET_PRO_EMAIL_UPGRADE_PLANS = 'ERROR_GET_PRO_EMAIL_UPGRADE_PLANS';
export const REQUEST_CHECK_EMAILS_UPDATE = 'REQUEST_CHECK_EMAILS_UPDATE';
export const RECEIVE_CHECK_EMAILS_UPDATE = 'RECEIVE_CHECK_EMAILS_UPDATE';
export const ERROR_CHECK_EMAILS_UPDATE = 'ERROR_CHECK_EMAILS_UPDATE';

/* Emails */

/* Domains */
export const REQUEST_DOMAINS = 'REQUEST_DOMAINS';
export const RECEIVE_DOMAINS = 'RECEIVE_DOMAINS';
export const ERROR_DOMAINS = 'ERROR_DOMAINS';
export const ABORT_REQUEST_DOMAINS = 'ABORT_REQUEST_DOMAINS';
export const FILTER_DOMAINS = 'FILTER_DOMAINS';
export const SELECT_STATUS_DOMAINS = 'SELECT_STATUS_DOMAINS';
export const RECEIVE_DOMAIN_ITEM_RENEWAL = 'RECEIVE_DOMAIN_ITEM_RENEWAL';
/* Domains */

/* Sites */
export const REQUEST_SITES = 'REQUEST_SITES';
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const ERROR_SITES = 'ERROR_SITES';
export const ABORT_REQUEST_SITES = 'ABORT_REQUEST_SITES';
/* Sites */

/* Sitelock */
export const REQUEST_SITELOCK_DOMAINS = 'REQUEST_SITELOCK_DOMAINS';
export const RECEIVE_SITELOCK_DOMAINS = 'RECEIVE_SITELOCK_DOMAINS';
export const REQUEST_SITELOCK_STATUS_SCAN = 'REQUEST_SITELOCK_STATUS_SCAN';
export const RECEIVE_SITELOCK_STATUS_SCAN = 'RECEIVE_SITELOCK_STATUS_SCAN';
export const ERROR_SITELOCK_STATUS_SCAN = 'ERROR_SITELOCK_STATUS_SCAN';
export const REQUEST_SITELOCK_COMPLETE_SCAN = 'REQUEST_SITELOCK_COMPLETE_SCAN';
export const RECEIVE_SITELOCK_COMPLETE_SCAN = 'RECEIVE_SITELOCK_COMPLETE_SCAN';
export const REQUEST_SITELOCK_COMPLETE_SMARTSCAN = 'REQUEST_SITELOCK_COMPLETE_SMARTSCAN';
export const RECEIVE_SITELOCK_COMPLETE_SMARTSCAN = 'RECEIVE_SITELOCK_COMPLETE_SMARTSCAN';
export const ERROR_SITELOCK_COMPLETE_SMARTSCAN = 'ERROR_SITELOCK_COMPLETE_SMARTSCAN';
export const ERROR_SITELOCK_COMPLETE_SCAN = 'ERROR_SITELOCK_COMPLETE_SCAN';
export const REQUEST_SITELOCK_SCAN = 'REQUEST_SITELOCK_SCAN';
export const RECEIVE_SITELOCK_SCAN = 'RECEIVE_SITELOCK_SCAN';
export const ABORT_SITELOCK_PRICE = 'ABORT_SITELOCK_PRICE';
export const ABORT_SITELOCK_PAYMENT_METHOD = 'ABORT_SITELOCK_PAYMENT_METHOD';
export const REQUEST_SITELOCK_PAYMENT_METHOD = 'REQUEST_SITELOCK_PAYMENT_METHOD';
export const RECEIVE_SITELOCK_PAYMENT_METHOD = 'RECEIVE_SITELOCK_PAYMENT_METHOD';
export const ERROR_SITELOCK_PAYMENT_METHOD = 'ERROR_SITELOCK_PAYMENT_METHOD';
export const REQUEST_SITELOCK_SCAN_STATUS = 'REQUEST_SITELOCK_SCAN_STATUS';
export const RECEIVE_SITELOCK_SCAN_STATUS = 'RECEIVE_SITELOCK_SCAN_STATUS';
export const RECEIVE_SITELOCK_SCAN_STATUS_PENDING = 'RECEIVE_SITELOCK_SCAN_STATUS_PENDING';
export const ERROR_SITELOCK_DOMAINS = 'ERROR_SITELOCK_DOMAINS';
export const ABORT_SITELOCK_REQUEST_DOMAINS = 'ABORT_SITELOCK_REQUEST_DOMAINS';
export const SET_SITELOCK_PRODUCT = 'SET_SITELOCK_PRODUCT';
export const REQUEST_SITELOCK_PRICE = 'REQUEST_SITELOCK_PRICE';
export const RECEIVE_SITELOCK_PRICE = 'RECEIVE_SITELOCK_PRICE';
export const ERROR_SITELOCK_PRICE = 'ERROR_SITELOCK_PRICE';
export const ABORT_SITELOCK_REQUEST_PRICE = 'ABORT_SITELOCK_REQUEST_PRICE';
export const SITELOCK_RESET_TOAST = 'SITELOCK_RESET_TOAST';
export const REQUEST_SITELOCK_SHIELD = 'REQUEST_SITELOCK_SHIELD';
export const RECEIVE_SITELOCK_SHIELD = 'RECEIVE_SITELOCK_SHIELD';
export const ERROR_SITELOCK_SHIELD = 'ERROR_SITELOCK_SHIELD';
export const REQUEST_SITELOCK_SHIELD_UPDATE = 'REQUEST_SITELOCK_SHIELD_UPDATE';
export const RECEIVE_SITELOCK_SHIELD_UPDATE = 'RECEIVE_SITELOCK_SHIELD_UPDATE';
export const ERROR_SITELOCK_SHIELD_UPDATE = 'ERROR_SITELOCK_SHIELD_UPDATE';
export const REQUEST_SITELOCK_SMARTSCAN_UPDATE = 'REQUEST_SITELOCK_SMARTSCAN_UPDATE';
export const RECEIVE_SITELOCK_SMARTSCAN_UPDATE = 'RECEIVE_SITELOCK_SMARTSCAN_UPDATE';
export const ERROR_SITELOCK_SMARTSCAN_UPDATE = 'ERROR_SITELOCK_SMARTSCAN_UPDATE';
export const REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG = 'REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG';
export const RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG = 'RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG';
export const ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG = 'ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG';
export const REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD = 'REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD';
export const RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD = 'RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD';
export const ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD = 'ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD';
export const SITELOCK_CLEAR = 'SITELOCK_CLEAR';
export const ABORT_SITELOCK_PROFESSIONAL_PRICE = 'ABORT_SITELOCK_PROFESSIONAL_PRICE';
export const REQUEST_SITELOCK_PROFESSIONAL_PRICE = 'REQUEST_SITELOCK_PROFESSIONAL_PRICE';
export const RECEIVE_SITELOCK_PROFESSIONAL_PRICE = 'RECEIVE_SITELOCK_PROFESSIONAL_PRICE';
export const ERROR_SITELOCK_PROFESSIONAL_PRICE = 'ERROR_SITELOCK_PROFESSIONAL_PRICE';
export const ABORT_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT = 'ABORT_SITELOCK_PROFESSIONAL_FAST_CHECKOUT';
export const REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT = 'REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT';
export const RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT = 'RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT';
export const ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT = 'ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT';

/* Sitelock */

/* Academy Pass */
export const REQUEST_ACADEMY_PASS_COURSES_LIST = 'REQUEST_ACADEMY_PASS_COURSES_LIST';
export const RECEIVE_ACADEMY_PASS_COURSES_LIST = 'RECEIVE_ACADEMY_PASS_COURSES_LIST';
export const ABORT_ACADEMY_PASS_COURSES_LIST = 'ABORT_ACADEMY_PASS_COURSES_LIST';
export const ERROR_ACADEMY_PASS_COURSES_LIST = 'ERROR_ACADEMY_PASS_COURSES_LIST';
export const REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST = 'REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST';
export const RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST = 'RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST';
export const ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST = 'ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST';
export const ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST = 'ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST';
export const REQUEST_ACADEMY_PASS_TOKENS = 'REQUEST_ACADEMY_PASS_TOKENS';
export const RECEIVE_ACADEMY_PASS_TOKENS = 'RECEIVE_ACADEMY_PASS_TOKENS';
export const ABORT_ACADEMY_PASS_TOKENS = 'ABORT_ACADEMY_PASS_TOKENS';
export const ERROR_ACADEMY_PASS_TOKENS = 'ERROR_ACADEMY_PASS_TOKENS';
export const REQUEST_ACADEMY_PASS_PRICE = 'REQUEST_ACADEMY_PASS_PRICE';
export const RECEIVE_ACADEMY_PASS_PRICE = 'RECEIVE_ACADEMY_PASS_PRICE';
export const ABORT_ACADEMY_PASS_PRICE = 'ABORT_ACADEMY_PASS_PRICE';
export const ERROR_ACADEMY_PASS_PRICE = 'ERROR_ACADEMY_PASS_PRICE';
export const REQUEST_ACADEMY_PASS_PAYMENT_METHOD = 'REQUEST_ACADEMY_PASS_PAYMENT_METHOD';
export const RECEIVE_ACADEMY_PASS_PAYMENT_METHOD = 'RECEIVE_ACADEMY_PASS_PAYMENT_METHOD';
export const ABORT_ACADEMY_PASS_PAYMENT_METHOD = 'ABORT_ACADEMY_PASS_PAYMENT_METHOD';
export const ERROR_ACADEMY_PASS_PAYMENT_METHOD = 'ERROR_ACADEMY_PASS_PAYMENT_METHOD';
export const REQUEST_ACADEMY_PASS_DETAILS = 'REQUEST_ACADEMY_PASS_DETAILS';
export const RECEIVE_ACADEMY_PASS_DETAILS = 'RECEIVE_ACADEMY_PASS_DETAILS';
export const ABORT_ACADEMY_PASS_DETAILS = 'ABORT_ACADEMY_PASS_DETAILS';
export const ERROR_ACADEMY_PASS_DETAILS = 'ERROR_ACADEMY_PASS_DETAILS';
export const REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT = 'REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT';
export const RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT = 'RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT';
export const ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT = 'ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT';
export const ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT = 'ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
/* Academy Pass */

/* Codeguard */
export const REQUEST_CODEGUARD_DOMAINS = 'REQUEST_CODEGUARD_DOMAINS';
export const RECEIVE_CODEGUARD_DOMAINS = 'RECEIVE_CODEGUARD_DOMAINS';
export const ERROR_CODEGUARD_DOMAINS = 'ERROR_CODEGUARD_DOMAINS';
export const ABORT_CODEGUARD_DOMAINS = 'ABORT_CODEGUARD_DOMAINS';
export const REQUEST_CODEGUARD_PRICE = 'REQUEST_CODEGUARD_PRICE';
export const RECEIVE_CODEGUARD_PRICE = 'RECEIVE_CODEGUARD_PRICE';
export const ABORT_CODEGUARD_REQUEST_PRICE = 'ABORT_CODEGUARD_REQUEST_PRICE';
export const ERROR_CODEGUARD_PRICE = 'ERROR_CODEGUARD_PRICE';
export const REQUEST_CODEGUARD_BASIC_PRICE = 'REQUEST_CODEGUARD_BASIC_PRICE';
export const RECEIVE_CODEGUARD_BASIC_PRICE = 'RECEIVE_CODEGUARD_BASIC_PRICE';
export const ABORT_CODEGUARD_BASIC_PRICE = 'ABORT_CODEGUARD_BASIC_PRICE';
export const ERROR_CODEGUARD_BASIC_PRICE = 'ERROR_CODEGUARD_BASIC_PRICE';
export const ABORT_CODEGUARD_BUY_FAST_CHECKOUT = 'ABORT_CODEGUARD_BUY_FAST_CHECKOUT';
export const REQUEST_CODEGUARD_BUY_FAST_CHECKOUT = 'REQUEST_CODEGUARD_BUY_FAST_CHECKOUT';
export const RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT = 'RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT';
export const ERROR_CODEGUARD_BUY_FAST_CHECKOUT = 'ERROR_CODEGUARD_BUY_FAST_CHECKOUT';
export const ABORT_CODEGUARD_PAYMENT_METHOD = 'ABORT_CODEGUARD_PAYMENT_METHOD';
export const REQUEST_CODEGUARD_PAYMENT_METHOD = 'REQUEST_CODEGUARD_PAYMENT_METHOD';
export const RECEIVE_CODEGUARD_PAYMENT_METHOD = 'RECEIVE_CODEGUARD_PAYMENT_METHOD';
export const ERROR_CODEGUARD_PAYMENT_METHOD = 'ERROR_CODEGUARD_PAYMENT_METHOD';
/* Codeguard */

/* Domain Detail */
export const REQUEST_DOMAIN_DETAIL = 'REQUEST_DOMAIN_DETAIL';
export const RECEIVE_DOMAIN_DETAIL = 'RECEIVE_DOMAIN_DETAIL';
export const ERROR_DOMAIN_DETAIL = 'ERROR_DOMAIN_DETAIL';
/* Domain Detail */

/* Domain Elegible */
export const REQUEST_DOMAIN_ELEGIBLE = 'REQUEST_DOMAIN_ELEGIBLE';
export const RECEIVE_DOMAIN_ELEGIBLE = 'RECEIVE_DOMAIN_ELEGIBLE';
export const ERROR_DOMAIN_ELEGIBLE = 'ERROR_DOMAIN_ELEGIBLE';
/* Domain Elegible */

/* Domain Renewal */
export const REQUEST_DOMAIN_RENEWAL = 'REQUEST_DOMAIN_RENEWAL';
export const RECEIVE_DOMAIN_DETAIL_RENEWAL = 'RECEIVE_DOMAIN_DETAIL_RENEWAL';
export const ERROR_DOMAIN_RENEWAL = 'ERROR_DOMAIN_RENEWAL';
/* Domain Renewal */

/* Domains Verification */
export const REQUEST_DOMAINS_VERIFICATION_PENDING = 'REQUEST_DOMAINS_VERIFICATION_PENDING';
export const RECEIVE_DOMAINS_VERIFICATION_PENDING = 'RECEIVE_DOMAINS_VERIFICATION_PENDING';
export const ERROR_DOMAINS_VERIFICATION_PENDING = 'ERROR_DOMAINS_VERIFICATION_PENDING';

export const REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL = 'REQUEST_DOMAINS_RESEND_VERIFICATION_EMAIL';
export const RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL = 'RECEIVE_DOMAINS_RESEND_VERIFICATION_EMAIL';
export const ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL = 'ERROR_DOMAINS_RESEND_VERIFICATION_EMAIL';
/* Domains Verification */

/* FAQ */
export const REQUEST_FAQ_HELP = 'REQUEST_FAQ_HELP';
export const RECEIVE_FAQ_HELP = 'RECEIVE_FAQ_HELP';
export const ERROR_FAQ_HELP = 'ERROR_FAQ_HELP';
export const ABORT_FAQ_HELP = 'ABORT_FAQ_HELP';

export const REQUEST_FAQ_DNS = 'REQUEST_FAQ_DNS';
export const RECEIVE_FAQ_DNS = 'RECEIVE_FAQ_DNS';
export const ERROR_FAQ_DNS = 'ERROR_FAQ_DNS';
export const ABORT_FAQ_DNS = 'ABORT_FAQ_DNS';

export const REQUEST_FAQ_PRODUCTS = 'REQUEST_FAQ_PRODUCTS';
export const RECEIVE_FAQ_PRODUCTS = 'RECEIVE_FAQ_PRODUCTS';
export const ERROR_FAQ_PRODUCTS = 'ERROR_FAQ_PRODUCTS';
export const ABORT_FAQ_PRODUCTS = 'ABORT_FAQ_PRODUCTS';

export const REQUEST_FAQ_SECURITY = 'REQUEST_FAQ_SECURITY';
export const RECEIVE_FAQ_SECURITY = 'RECEIVE_FAQ_SECURITY';
export const ERROR_FAQ_SECURITY = 'ERROR_FAQ_SECURITY';
export const ABORT_FAQ_SECURITY = 'ABORT_FAQ_SECURITY';
/* faq products */

export const REQUEST_SITES_AND_PLANS = 'REQUEST_SITES_AND_PLANS';
export const RECEIVE_SITES_AND_PLANS = 'RECEIVE_SITES_AND_PLANS';
export const ERROR_SITES_AND_PLANS = 'ERROR_SITES_AND_PLANS';
export const ABORT_SITES_AND_PLANS = 'ABORT_SITES_AND_PLANS';

export const REQUEST_FAQ_PRODUCT_DETAIL = 'REQUEST_FAQ_PRODUCT_DETAIL';
export const RECEIVE_FAQ_PRODUCT_DETAIL = 'RECEIVE_FAQ_PRODUCT_DETAIL';
export const ERROR_FAQ_PRODUCT_DETAIL = 'ERROR_FAQ_PRODUCT_DETAIL';
export const ABORT_FAQ_PRODUCT_DETAIL = 'ABORT_FAQ_PRODUCT_DETAIL';

export const REQUEST_FAQ_DOMAIN = 'REQUEST_FAQ_DOMAIN';
export const RECEIVE_FAQ_DOMAIN = 'RECEIVE_FAQ_DOMAIN';
export const ERROR_FAQ_DOMAIN = 'ERROR_FAQ_DOMAIN';
export const ABORT_FAQ_DOMAIN = 'ABORT_FAQ_DOMAIN';

export const REQUEST_FAQ_INVOICES = 'REQUEST_FAQ_INVOICES';
export const RECEIVE_FAQ_INVOICES = 'RECEIVE_FAQ_INVOICES';
export const ERROR_FAQ_INVOICES = 'ERROR_FAQ_INVOICES';
export const ABORT_FAQ_INVOICES = 'ABORT_FAQ_INVOICES';

export const REQUEST_FAQ_MYSITES = 'REQUEST_FAQ_MYSITES';
export const RECEIVE_FAQ_MYSITES = 'RECEIVE_FAQ_MYSITES';
export const ERROR_FAQ_MYSITES = 'ERROR_FAQ_MYSITES';
export const ABORT_FAQ_MYSITES = 'ABORT_FAQ_MYSITES';

export const REQUEST_FAQ_DOMAIN_LIST = 'REQUEST_FAQ_DOMAIN_LIST';
export const RECEIVE_FAQ_DOMAIN_LIST = 'RECEIVE_FAQ_DOMAIN_LIST';
export const ERROR_FAQ_DOMAIN_LIST = 'ERROR_FAQ_DOMAIN_LIST';
export const ABORT_FAQ_DOMAIN_LIST = 'ABORT_FAQ_DOMAIN_LIST';

export const REQUEST_FAQ_EMAILS = 'REQUEST_FAQ_EMAILS';
export const RECEIVE_FAQ_EMAILS = 'RECEIVE_FAQ_EMAILS';
export const ERROR_FAQ_EMAILS = 'ERROR_FAQ_EMAILS';
export const ABORT_FAQ_EMAILS = 'ABORT_FAQ_EMAILS';

export const REQUEST_FAQ_PARAM = 'REQUEST_FAQ_PARAM';
export const RECEIVE_FAQ_PARAM = 'RECEIVE_FAQ_PARAM';
export const ERROR_FAQ_PARAM = 'ERROR_FAQ_PARAM';

/* FAQ */

/* Feedback Form */
export const REQUEST_FEEDBACK_FORM = 'REQUEST_FEEDBACK_FORM';
export const RECEIVE_FEEDBACK_FORM = 'RECEIVE_FEEDBACK_FORM';
export const ERROR_FEEDBACK_FORM = 'ERROR_FEEDBACK_FORM';
export const REQUEST_SAVE_FEEDBACK_FORM = 'REQUEST_SAVE_FEEDBACK_FORM';
export const RECEIVE_SAVE_FEEDBACK_FORM = 'RECEIVE_SAVE_FEEDBACK_FORM';
export const ERROR_SAVE_FEEDBACK_FORM = 'ERROR_SAVE_FEEDBACK_FORM';
/* Feedback Form */

/* General Products */
export const REQUEST_GENERAL_PRODUCTS = 'REQUEST_GENERAL_PRODUCTS';
export const RECEIVE_GENERAL_PRODUCTS = 'RECEIVE_GENERAL_PRODUCTS';
export const ERROR_GENERAL_PRODUCTS = 'ERROR_GENERAL_PRODUCTS';
export const FILTER_GENERAL_PRODUCTS = 'FILTER_GENERAL_PRODUCTS';
export const CLEAR_FILTER_GENERAL_PRODUCTS = 'CLEAR_FILTER_GENERAL_PRODUCTS';
export const ABORT_REQUEST_GENERAL_PRODUCTS = 'ABORT_REQUEST_GENERAL_PRODUCTS';
export const UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS = 'UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS';
export const SELECT_STATUS_PRODUCTS = 'SELECT_STATUS_PRODUCTS';
export const ALTER_VISIBLE_PRODUCTS = 'ALTER_VISIBLE_PRODUCTS';
export const UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS = 'UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS';
export const ABORT_UPDATE_SITEBUILDER_STATUS_ON_PRODUCTS = 'ABORT_UPDATE_SITEBUILDER_STATUS_ON_PRODUCTS';
/* General Products */

/* Invoice Payment Methods */
export const REQUEST_INVOICE_PAYMENT_METHODS = 'REQUEST_INVOICE_PAYMENT_METHODS';
export const RECEIVE_INVOICE_PAYMENT_METHODS = 'RECEIVE_INVOICE_PAYMENT_METHODS';
export const ERROR_INVOICE_PAYMENT_METHOD = 'ERROR_INVOICE_PAYMENT_METHOD';
/* Invoice Payment Methods */

/* Invoices */
export const REQUEST_INVOICES = 'REQUEST_INVOICES';
export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';
export const ERROR_INVOICES = 'ERROR_INVOICES';
export const REQUEST_INVOICE_DETAILS = 'REQUEST_INVOICE_DETAILS';
export const RECEIVE_INVOICE_DETAILS = 'RECEIVE_INVOICE_DETAILS';
export const ERROR_INVOICE_DETAILS = 'ERROR_INVOICE_DETAILS';
export const REQUEST_SECOND_INVOICE = 'REQUEST_SECOND_INVOICE';
export const REQUEST_UNPAID_INVOICES = 'REQUEST_UNPAID_INVOICES';
export const RECEIVE_UNPAID_INVOICES = 'RECEIVE_UNPAID_INVOICES';
export const UPDATE_UNPAID_INVOICES = 'UPDATE_UNPAID_INVOICES ';
export const ABORT_UNPAID_INVOICES = 'ABORT_UNPAID_INVOICES';
export const UPDATE_PAID_INVOICES = 'UPDATE_PAID_INVOICES';
export const REQUEST_PAYMENT_METHOD_TICKET_INVOICE_ES = 'REQUEST_PAYMENT_METHOD_TICKET_INVOICE_ES';
export const RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES = 'RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES';
export const ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES = 'ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES';
export const REQUEST_BOLETO_URL = 'REQUEST_BOLETO_URL';
export const RECEIVE_BOLETO_URL = 'RECEIVE_BOLETO_URL';
export const ERROR_BOLETO_URL = 'ERROR_BOLETO_URL';
export const REQUEST_BOLETO_VALIDATE_ADDRESS = 'REQUEST_BOLETO_VALIDATE_ADDRESS';
export const RECEIVE_BOLETO_VALIDATE_ADDRESS = 'RECEIVE_BOLETO_VALIDATE_ADDRESS';
export const ERROR_BOLETO_VALIDATE_ADDRESS = 'ERROR_BOLETO_VALIDATE_ADDRESS';
export const REQUEST_BOLETO_UPDATE_ADDRESS = 'REQUEST_BOLETO_UPDATE_ADDRESS';
export const SUCCESS_BOLETO_UPDATE_ADDRESS = 'SUCCESS_BOLETO_UPDATE_ADDRESS';
export const ERROR_BOLETO_UPDATE_ADDRESS = 'ERROR_BOLETO_UPDATE_ADDRESS';
/* Invoices */

/* Payment Method */
export const REQUEST_PAYMENT_METHOD = 'REQUEST_PAYMENT_METHOD';
export const ERROR_PAYMENT_METHOD = 'ERROR_PAYMENT_METHOD';
export const REQUEST_CC_METHOD = 'REQUEST_CC_METHOD';
export const ERROR_CC_METHOD = 'ERROR_CC_METHOD';
export const RECEIVE_CC_METHOD = 'RECEIVE_CC_METHOD';
/* Payment Method */

/* Product Groups */
export const REQUEST_PRODUCT_GROUPS = 'REQUEST_PRODUCT_GROUPS';
export const RECEIVE_PRODUCT_GROUPS = 'RECEIVE_PRODUCT_GROUPS';
export const ERROR_PRODUCT_GROUPS = 'ERROR_PRODUCT_GROUPS';
export const ABORT_REQUEST_PRODUCTS_GROUPS = 'ABORT_REQUEST_PRODUCTS_GROUPS';
export const FILTER_PRODUCTS_GROUPS = 'FILTER_PRODUCTS_GROUPS';
export const REMOVE_PRODUCT_GROUP = 'REMOVE_PRODUCT_GROUP';
/* Product Groups */

/* Subdomains */
export const REQUEST_SUBDOMAINS = 'REQUEST_SUBDOMAINS';
export const RECEIVE_SUBDOMAINS = 'RECEIVE_SUBDOMAINS';
export const ABORT_REQUEST_SUBDOMAINS = 'ABORT_REQUEST_SUBDOMAINS';
export const ERROR_SUBDOMAINS = 'ERROR_SUBDOMAINS';
/* Subdomains */

/* Summary */
export const REQUEST_SUMMARY = 'REQUEST_SUMMARY';
export const RECEIVE_SUMMARY = 'RECEIVE_SUMMARY';
export const ERROR_SUMMARY = 'ERROR_SUMMARY';
export const REQUEST_SAVE_PERSONA = 'REQUEST_SAVE_PERSONA';
export const SUCCESS_SAVE_PERSONA = 'SUCCESS_SAVE_PERSONA';
export const ERROR_SAVE_PERSONA = 'ERROR_SAVE_PERSONA';
export const ONBOARDING_DISMISS = 'ONBOARDING_DISMISS';
export const ONBOARDING_AGREE = 'ONBOARDING_AGREE';
export const REQUEST_LOAD_STATES = 'REQUEST_LOAD_STATES';
export const RECEIVE_LOAD_STATES = 'RECEIVE_LOAD_STATES';
export const ERROR_LOAD_STATES = 'ERROR_LOAD_STATES';
export const ABORT_LOAD_STATES = 'ABORT_LOAD_STATES';
export const REQUEST_LOAD_CITIES_BY_STATES = 'REQUEST_LOAD_CITIES_BY_STATES';
export const RECEIVE_LOAD_CITIES_BY_STATES = 'RECEIVE_LOAD_CITIES_BY_STATES';
export const ERROR_LOAD_CITIES_BY_STATES = 'ERROR_LOAD_CITIES_BY_STATES';
export const REQUEST_LOAD_COUNTRIES = 'REQUEST_LOAD_COUNTRIES';
export const RECEIVE_LOAD_COUNTRIES = 'RECEIVE_LOAD_COUNTRIES';
export const ERROR_LOAD_COUNTRIES = 'ERROR_LOAD_COUNTRIES';
export const REQUEST_LOAD_ZIP_CODE = 'REQUEST_LOAD_ZIP_CODE';
export const RECEIVE_LOAD_ZIP_CODE = 'RECEIVE_LOAD_ZIP_CODE';
export const ERROR_LOAD_ZIP_CODE = 'ERROR_LOAD_ZIP_CODE';
export const REQUEST_UPDATE_SUMMARY = 'REQUEST_UPDATE_SUMMARY';
export const RECEIVE_UPDATE_SUMMARY = 'RECEIVE_UPDATE_SUMMARY';
export const ERROR_UPDATE_SUMMARY = 'ERROR_UPDATE_SUMMARY';
export const REQUEST_ONBOARDING_EMAIL_DATA = 'REQUEST_ONBOARDING_EMAIL_DATA';
export const RECEIVE_ONBOARDING_EMAIL_DATA = 'RECEIVE_ONBOARDING_EMAIL_DATA';
export const ERROR_ONBOARDING_EMAIL_DATA = 'ERROR_ONBOARDING_EMAIL_DATA';
export const NEW_SITE_FLUX_DISMISS = 'NEW_SITE_FLUX_DISMISS';
export const REQUEST_ONBOARDING_DNS = 'REQUEST_ONBOARDING_DNS';
export const RECEIVE_ONBOARDING_DNS = 'RECEIVE_ONBOARDING_DNS';
export const ERROR_ONBOARDING_DNS = 'ERROR_ONBOARDING_DNS';
export const SET_PHONENUMBER_VERIFIED = 'SET_PHONENUMBER_VERIFIED';
/* Summary */

/* Ticket Detail */
export const REQUEST_TICKET_DETAIL = 'REQUEST_TICKET_DETAIL';
export const RECEIVE_TICKET_DETAIL = 'RECEIVE_TICKET_DETAIL';
export const ERROR_TICKET_DETAIL = 'ERROR_TICKET_DETAIL';
export const SAVE_TICKET_COMMENT_START = 'SAVE_TICKET_COMMENT_START';
export const SAVE_TICKET_COMMENT_SUCCESS = 'SAVE_TICKET_COMMENT_SUCCESS';
export const SAVE_TICKET_COMMENT_ERROR = 'SAVE_TICKET_COMMENT_ERROR';
export const RECEIVE_COMMENT_TICKET_DETAIL = 'RECEIVE_COMMENT_TICKET_DETAIL';
export const RECEIVE_UPLOAD_TICKET_DETAIL = 'RECEIVE_UPLOAD_TICKET_DETAIL';
export const UPLOAD_TICKET_IMAGES_START = 'UPLOAD_TICKET_IMAGES_START';
export const UPLOAD_TICKET_IMAGES_ERROR = 'UPLOAD_TICKET_IMAGES_ERROR';
export const UPLOAD_TICKET_IMAGES_SUCCESS = 'UPLOAD_TICKET_IMAGES_SUCCESS';
export const REQUEST_SAVE_TICKET_FEEDBACK = 'REQUEST_SAVE_TICKET_FEEDBACK';
export const ERROR_SAVE_TICKET_FEEDBACK = 'ERROR_SAVE_TICKET_FEEDBACK';
export const SUCCESS_SAVE_TICKET_FEEDBACK = 'SUCCESS_SAVE_TICKET_FEEDBACK';
export const REQUEST_CLOSE_TICKET = 'REQUEST_CLOSE_TICKET';
export const SUCCESS_CLOSE_TICKET = 'SUCCESS_CLOSE_TICKET';
export const ERROR_CLOSE_TICKET = 'ERROR_CLOSE_TICKET';
export const UPDATE_TICKET_DETAIL_STATUS = 'UPDATE_TICKET_DETAIL_STATUS';
export const REQUEST_SAVE_TICKET_TRANSFER_DOMAIN = 'REQUEST_SAVE_TICKET_TRANSFER_DOMAIN';
export const ERROR_SAVE_TICKET_TRANSFER_DOMAIN = 'ERROR_SAVE_TICKET_TRANSFER_DOMAIN';
export const RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN = 'RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN';
/* Ticket Detail */

/* Tickets */
export const REQUEST_PENDING_TICKETS_V2 = 'REQUEST_PENDING_TICKETS_V2';
export const REQUEST_CLOSED_TICKETS_V2 = 'REQUEST_CLOSED_TICKETS_V2';
export const RECEIVE_PENDING_TICKETS_V2 = 'RECEIVE_PENDING_TICKETS_V2';
export const RECEIVE_CLOSED_TICKETS_V2 = 'RECEIVE_CLOSED_TICKETS_V2';
export const REQUEST_ERROR_TICKETS_V2 = 'REQUEST_ERROR_TICKETS_V2';

export const REQUEST_PENDING_TICKETS = 'REQUEST_PENDING_TICKETS';
export const REQUEST_CLOSED_TICKETS = 'REQUEST_CLOSED_TICKETS';
export const RECEIVE_PENDING_TICKETS = 'RECEIVE_PENDING_TICKETS';
export const RECEIVE_FILTERED_PENDING_TICKETS = 'RECEIVE_FILTERED_PENDING_TICKETS';
export const RECEIVE_FILTERED_CLOSED_TICKETS = 'RECEIVE_FILTERED_CLOSED_TICKETS';
export const RECEIVE_CLOSED_TICKETS = 'RECEIVE_CLOSED_TICKETS';
export const REQUEST_ERROR_TICKETS = 'REQUEST_ERROR_TICKETS';
export const CLEAR_FILTER_PENDING_TICKETS = 'CLEAR_FILTER_PENDING_TICKETS';
export const CLEAR_FILTER_CLOSED_TICKETS = 'CLEAR_FILTER_CLOSED_TICKETS';
export const TICKET_STATUS = {
  open: 'open',
  new: 'new',
  pending: 'pending',
  conclude: 'conclude',
  solved: 'solved',
  hold: 'hold',
};
export const FIRSTROW = 'firstrow';
export const SECONDROW = 'secondrow';
/* Tickets */

/* Onboarding */
export const REQUEST_ONBOARDING_DOMAINS = 'REQUEST_ONBOARDING_DOMAINS';
export const RECEIVE_ONBOARDING_DOMAINS = 'RECEIVE_ONBOARDING_DOMAINS';
export const ERROR_ONBOARDING_DOMAINS = 'ERROR_ONBOARDING_DOMAINS';
export const REQUEST_ONBOARDING_THEMES = 'REQUEST_ONBOARDING_THEMES';
export const RECEIVE_ONBOARDING_THEMES = 'RECEIVE_ONBOARDING_THEMES';
export const ERROR_ONBOARDING_THEMES = 'ERROR_ONBOARDING_THEMES';
export const RECEIVE_FILTERED_THEMES_BY_CATEGORIE = 'RECEIVE_FILTERED_THEMES_BY_CATEGORIE';
export const RECEIVE_FILTERED_THEMES_BY_ID = 'RECEIVE_FILTERED_THEMES_BY_ID';
export const CLEAN_FILTERED_THEMES_BY_CATEGORIE = 'CLEAN_FILTERED_THEMES_BY_CATEGORIE';
export const SELECTED_TEMPLATE_ID = 'SELECTED_TEMPLATE_ID';
export const CLEAN_SELECTED_TEMPLATE_ID = 'CLEAN_SELECTED_TEMPLATE_ID';
export const REQUEST_LOAD_ONBOARDING_SSO = 'REQUEST_LOAD_ONBOARDING_SSO';
export const RECEIVE_LOAD_ONBOARDING_SSO = 'RECEIVE_LOAD_ONBOARDING_SSO';
export const ERROR_LOAD_ONBOARDING_SSO = 'ERROR_LOAD_ONBOARDING_SSO';
export const REQUEST_SITECREATE_DISMISS = 'REQUEST_SITECREATE_DISMISS';
export const RECEIVE_SITECREATE_DISMISS = 'RECEIVE_SITECREATE_DISMISS';
export const ERROR_SITECREATE_DISMISS = 'ERROR_SITECREATE_DISMISS';
export const REQUEST_ONBOARDING_TRACE = 'REQUEST_ONBOARDING_TRACE';
export const RECEIVE_ONBOARDING_TRACE = 'RECEIVE_ONBOARDING_TRACE';
export const ERROR_ONBOARDING_TRACE = 'ERROR_ONBOARDING_TRACE';
export const SET_ONBOARDING_TOOL = 'SET_ONBOARDING_TOOL';
export const SET_ONBOARDING_PRODUCT = 'SET_ONBOARDING_PRODUCT';
export const CLEAN_ONBOARDING_TOOL = 'CLEAN_ONBOARDING_TOOL';
export const REQUEST_TRACE_ONBOARDING_ACCESS = 'REQUEST_TRACE_ONBOARDING_ACCESS';
export const RECEIVE_TRACE_ONBOARDING_ACCESS = 'RECEIVE_TRACE_ONBOARDING_ACCESS';
export const ERROR_TRACE_ONBOARDING_ACCESS = 'ERROR_TRACE_ONBOARDING_ACCESS';
export const REQUEST_ONBOARDING = 'REQUEST_ONBOARDING';
export const RECEIVE_ONBOARDING = 'RECEIVE_ONBOARDING';
export const ERROR_ONBOARDING = 'ERROR_ONBOARDING';
export const DISABLE_ONBOARDING_SELECT_DOMAIN = 'DISABLE_ONBOARDING_SELECT_DOMAIN';
/* Onboarding */

/* Notifications */
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
/* Notifications */

/* Feature Toggles */
export const REQUEST_FEATURETOGGLES = 'REQUEST_FEATURETOGGLES';
export const RECEIVE_FEATURETOGGLES = 'RECEIVE_FEATURETOGGLES';
export const ERROR_FEATURETOGGLES = 'ERROR_FEATURETOGGLES';
/* Feature Toggles */

/* Change Password */
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';
/* Change Password */

/* Two Factor Authentication */
export const SEND_ENABLE_TWO_FACTOR_AUTH_CODE = 'ENABLE_TWO_FACTOR_AUTH';
export const RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE = 'RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE';

export const SEND_DISABLE_TWO_FACTOR_AUTH_CODE = 'DISABLE_TWO_FACTOR_AUTH';
export const RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE = 'RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE';

export const RESEND_TWO_FACTOR_AUTH_CODE = 'RESEND_TWO_FACTOR_AUTH_CODE';
export const RECEIVE_TWO_FACTOR_AUTH_CODE = 'RECEIVE_TWO_FACTOR_AUTH_CODE';

export const REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE = 'REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE';
export const SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE = 'SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE';

export const TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK = 'TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK';
/* Two Factor Authentication */

/* Site Builder */
export const REQUEST_SITEBUILDER_SSO = 'REQUEST_SITEBUILDER_SSO';
export const SUCCESS_SITEBUILDER_SSO = 'SUCCESS_SITEBUILDER_SSO';
export const ERROR_SITEBUILDER_SSO = 'ERROR_SITEBUILDER_SSO';

export const REQUEST_ACTIVATE_SITEBUILDER_ADDON = 'REQUEST_ACTIVATE_SITEBUILDER_ADDON';
export const RECEIVE_ACTIVATE_SITEBUILDER_ADDON = 'RECEIVE_ACTIVATE_SITEBUILDER_ADDON';
export const ERROR_ACTIVATE_SITEBUILDER_ADDON = 'ERROR_ACTIVATE_SITEBUILDER_ADDON';

export const REQUEST_ACTIVATE_SITEBUILDER_STANDALONE = 'REQUEST_ACTIVATE_SITEBUILDER_STANDALONE';
export const RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE = 'RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE';
export const ERROR_ACTIVATE_SITEBUILDER_STANDALONE = 'ERROR_ACTIVATE_SITEBUILDER_STANDALONE';

export const REQUEST_DELETE_SITE_FROM_SITEBUILDER = 'REQUEST_DELETE_SITE_FROM_SITEBUILDER';
export const SUCCESS_DELETE_SITE_FROM_SITEBUILDER = 'SUCCESS_DELETE_SITE_FROM_SITEBUILDER';
export const ERROR_DELETE_SITE_FROM_SITEBUILDER = 'ERROR_DELETE_SITE_FROM_SITEBUILDER';

export const REQUEST_ADDON_ID_FROM_SITE_BUILDER = 'REQUEST_ADDON_ID_FROM_SITE_BUILDER';
export const RECEIVE_ADDON_ID_FROM_SITE_BUILDER = 'RECEIVE_ADDON_ID_FROM_SITE_BUILDER';
export const ERROR_ADDON_ID_FROM_SITE_BUILDER = 'ERROR_ADDON_ID_FROM_SITE_BUILDER';

export const REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS = 'REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS';
export const RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS = 'RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS';
export const ERROR_SITE_BUILDER_AVAILABLE_DOMAINS = 'ERROR_SITE_BUILDER_AVAILABLE_DOMAINS';

export const REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN = 'REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN';
export const RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN = 'RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN';
export const ERROR_STATUS_SITE_BUILDER_BY_DOMAIN = 'ERROR_STATUS_SITE_BUILDER_BY_DOMAIN';

export const REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS = 'REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS';
export const RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS = 'RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS';
export const ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS = 'ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS';

export const UPDATE_ACTIONS_IN_PRODUCT_DETAILS = 'UPDATE_ACTIONS_IN_PRODUCT_DETAILS';

export const REQUEST_SITEBUILDER_PRODUCT_DETAIL = 'REQUEST_SITEBUILDER_PRODUCT_DETAIL';
export const RECEIVE_SITEBUILDER_PRODUCT_DETAIL = 'RECEIVE_SITEBUILDER_PRODUCT_DETAIL';
export const ERROR_SITEBUILDER_PRODUCT_DETAIL = 'ERROR_SITEBUILDER_PRODUCT_DETAIL';
/* Site Builder */

/* Product Detail */
export const REQUEST_PRODUCT_DETAIL = 'REQUEST_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_DETAIL = 'RECEIVE_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_DETAIL_ADDONS = 'RECEIVE_PRODUCT_DETAIL';
export const ERROR_PRODUCT_DETAIL = 'ERROR_PRODUCT_DETAIL';

export const REQUEST_ADDON_PARENT_PRODUCT_DETAIL = 'REQUEST_ADDON_PARENT_PRODUCT_DETAIL';
export const ERROR_ADDON_PARENT_PRODUCT_DETAIL = 'ERROR_ADDON_PARENT_PRODUCT_DETAIL';

export const REMOVE_PRODUCT_DETAIL = 'REMOVE_PRODUCT_DETAIL';
export const TOGGLE_ACCESS_PASSWORD_MODAL = 'TOGGLE_ACCESS_PASSWORD_MODAL';

export const REQUEST_PRODUCT_SAVE_PASSWORD = 'REQUEST_PRODUCT_SAVE_PASSWORD';
export const RECEIVE_PRODUCT_SAVE_PASSWORD = 'RECEIVE_PRODUCT_SAVE_PASSWORD';
export const ERROR_PRODUCT_SAVE_PASSWORD = 'ERROR_PRODUCT_SAVE_PASSWORD';

export const REQUEST_WHM_PANEL_ACCESS = 'REQUEST_WHM_PANEL_ACCESS';
export const RECEIVE_WHM_PANEL_ACCESS = 'RECEIVE_WHM_PANEL_ACCESS';
export const ERROR_WHM_PANEL_ACCESS = 'ERROR_WHM_PANEL_ACCESS';
export const ERROR_HEALTHCHECK_WHM_PANEL_ACCESS = 'ERROR_HEALTHCHECK_WHM_PANEL_ACCESS';

export const REQUEST_PRODUCT_ADDONS = 'REQUEST_PRODUCT_ADDONS';
export const ERROR_PRODUCT_ADDONS = 'ERROR_PRODUCT_ADDONS';
/* Product Detail */

/* Reboot History */
export const REQUEST_PRODUCT_REBOOT_HISTORY = 'REQUEST_PRODUCT_REBOOT_HISTORY';
export const RECEIVE_PRODUCT_REBOOT_HISTORY = 'RECEIVE_PRODUCT_REBOOT_HISTORY';
export const ERROR_PRODUCT_REBOOT_HISTORY = 'ERROR_PRODUCT_REBOOT_HISTORY';
/* Reboot History */

/* Product Healthcheck */
export const REQUEST_PRODUCT_HEALTHCHECK = 'REQUEST_PRODUCT_HEALTHCHECK';
export const RECEIVE_PRODUCT_HEALTHCHECK = 'RECEIVE_PRODUCT_HEALTHCHECK';
export const ERROR_PRODUCT_HEALTHCHECK = 'ERROR_PRODUCT_HEALTHCHECK';
/* Product Healthcheck */

export const SET_REBOOT_AVAILABILITY = 'SET_REBOOT_AVAILABILITY';

/* Reboot */
export const REQUEST_PRODUCT_REBOOT = 'REQUEST_PRODUCT_REBOOT';
export const RECEIVE_PRODUCT_REBOOT = 'RECEIVE_PRODUCT_REBOOT';
export const ERROR_PRODUCT_REBOOT = 'ERROR_PRODUCT_REBOOT';
/* Reboot */

/* Alerts */
export const REQUEST_ALERTS = 'REQUEST_ALERTS';
export const RECEIVE_ALERTS = 'RECEIVE_ALERTS';
export const ERROR_ALERTS = 'ERROR_ALERTS';
/* Alerts */

/* Breadcrumbs */
export const SET_CRUMBS = 'SET_CRUMBS';
export const SET_DYNAMIC_BREADCRUMBS_TITLE = 'SET_DYNAMIC_BREADCRUMBS_TITLE';
/* Breadcrumbs */

/* Videos */
export const REQUEST_VIMEO_SHOWCASES = 'REQUEST_VIMEO_SHOWCASES';
export const RECEIVE_VIMEO_SHOWCASES = 'RECEIVE_VIMEO_SHOWCASES';
export const ERROR_VIMEO_SHOWCASES = 'ERROR_VIMEO_SHOWCASES';
export const REQUEST_SHOWCASE_VIDEOS = 'REQUEST_SHOWCASE_VIDEOS';
export const RECEIVE_SHOWCASE_VIDEOS = 'RECEIVE_SHOWCASE_VIDEOS';
export const ERROR_SHOWCASE_VIDEOS = 'ERROR_SHOWCASE_VIDEOS';
export const REQUEST_TRACE_COURSE_ACCESS = 'REQUEST_TRACE_COURSE_ACCESS';
export const RECEIVE_TRACE_COURSE_ACCESS = 'RECEIVE_TRACE_COURSE_ACCESS';
export const ERROR_TRACE_COURSE_ACCESS = 'ERROR_TRACE_COURSE_ACCESS';
/* Videos */

/* Email Verify */
export const EMAIL_VERIFY_SKIP = 'EMAIL_VERIFY_SKIP';
export const EMAIL_CHANGE_FLOW = 'EMAIL_CHANGE_FLOW';

export const REQUEST_RESEND_EMAIL = 'REQUEST_RESEND_EMAIL';
export const RECEIVE_RESEND_EMAIL = 'RECEIVE_RESEND_EMAIL';
export const ERROR_RESEND_EMAIL = 'ERROR_RESEND_EMAIL';

export const REQUEST_CHANGE_EMAIL = 'REQUEST_CHANGE_EMAIL';
export const RECEIVE_CHANGE_EMAIL = 'RECEIVE_CHANGE_EMAIL';
export const ERROR_CHANGE_EMAIL = 'ERROR_CHANGE_EMAIL';
/* Email Verify */

/* Phone Verify */
export const PHONENUMBER_VERIFY_SKIP = 'PHONENUMBER_VERIFY_SKIP';
export const PHONENUMBER_CHANGE_FLOW = 'PHONENUMBER_CHANGE_FLOW';

export const REQUEST_SEND_PHONE_CODE = 'REQUEST_SEND_PHONE_CODE';
export const RECEIVE_SEND_PHONE_CODE = 'RECEIVE_SEND_PHONE_CODE';
export const ERROR_SEND_PHONE_CODE = 'ERROR_SEND_PHONE_CODE';
export const ERROR_SEND_PHONE_WRONG_PASSWORD = 'ERROR_SEND_PHONE_WRONG_PASSWORD';

export const REQUEST_VALIDATE_PHONE_CODE = 'REQUEST_VALIDATE_PHONE_CODE';
export const RECEIVE_VALIDATE_PHONE_CODE = 'RECEIVE_VALIDATE_PHONE_CODE';
export const ERROR_VALIDATE_PHONE_CODE = 'ERROR_VALIDATE_PHONE_CODE';

export const REQUEST_CHANGE_PHONE_NUMBER = 'REQUEST_CHANGE_PHONE_NUMBER';
export const RECEIVE_CHANGE_PHONE_NUMBER = 'RECEIVE_CHANGE_PHONE_NUMBER';
export const ERROR_CHANGE_PHONE_NUMBER = 'ERROR_CHANGE_PHONE_NUMBER';

export const REQUEST_COUNTRY_CODE = 'REQUEST_COUNTRY_CODE';
export const RECEIVE_COUNTRY_CODE = 'RECEIVE_COUNTRY_CODE';
export const ERROR_COUNTRY_CODE = 'ERROR_COUNTRY_CODE';
/* Phone Verify */

/* Paypal New API */
export const REQUEST_PAYPAL_VERIFY_AGREEMENT = 'REQUEST_PAYPAL_VERIFY_AGREEMENT';
export const RECEIVE_PAYPAL_VERIFY_AGREEMENT = 'RECEIVE_PAYPAL_VERIFY_AGREEMENT';
export const ERROR_PAYPAL_VERIFY_AGREEMENT = 'ERROR_PAYPAL_VERIFY_AGREEMENT';

export const REQUEST_PAYPAL_CREATE_AGREEMENT_TOKEN = 'REQUEST_PAYPAL_CREATE_AGREEMENT_TOKEN';
export const RECEIVE_PAYPAL_CREATE_AGREEMENT_TOKEN = 'RECEIVE_PAYPAL_CREATE_AGREEMENT_TOKEN';
export const ERROR_PAYPAL_CREATE_AGREEMENT_TOKEN = 'ERROR_PAYPAL_CREATE_AGREEMENT_TOKEN';

export const REQUEST_PAYPAL_CREATE_AGREEMENT = 'REQUEST_PAYPAL_CREATE_AGREEMENT';
export const RECEIVE_PAYPAL_CREATE_AGREEMENT = 'RECEIVE_PAYPAL_CREATE_AGREEMENT';
export const ERROR_PAYPAL_CREATE_AGREEMENT = 'ERROR_PAYPAL_CREATE_AGREEMENT';

export const REQUEST_PAYPAL_CANCEL_AGREEMENT = 'REQUEST_PAYPAL_CANCEL_AGREEMENT';
export const RECEIVE_PAYPAL_CANCEL_AGREEMENT = 'RECEIVE_PAYPAL_CANCEL_AGREEMENT';
export const ERROR_PAYPAL_CANCEL_AGREEMENT = 'ERROR_PAYPAL_CANCEL_AGREEMENT';

export const REQUEST_PAYPAL_PAYMENT_AGREEMENT = 'REQUEST_PAYPAL_PAYMENT_AGREEMENT';
export const RECEIVE_PAYPAL_PAYMENT_AGREEMENT = 'RECEIVE_PAYPAL_PAYMENT_AGREEMENT';
export const ERROR_PAYPAL_PAYMENT_AGREEMENT = 'ERROR_PAYPAL_PAYMENT_AGREEMENT';
/* Paypal New API */

/* Gatorpay Paas */
export const REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD = 'REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD';
export const RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD = 'RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD';
export const ERROR_GATORPAY_PAAS_SAVE_CREDITCARD = 'ERROR_GATORPAY_PAAS_SAVE_CREDITCARD';

export const REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT = 'REQUEST_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT';
export const RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT = 'RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT';
export const ERROR_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT = 'ERROR_GATORPAY_PAAS_SAVE_CREDITCARD_ATTEMPT';
export const ERROR_GATORPAY_PAAS_GET_USER_IP = 'ERROR_GATORPAY_PAAS_GET_USER_IP';

export const REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT = 'REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT';
export const RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT = 'RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT';
export const ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT = 'ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT';
/* Gatorpay Paas */

/* dnsZoneManager */
export const FORCE_DNS_LOADING_STATUS = 'FORCE_DNS_LOADING_STATUS';

export const REQUEST_DNS_ZONE_MANAGER_ADD_RECORD = 'REQUEST_DNS_ZONE_MANAGER_ADD_RECORD';
export const RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD = 'RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD';
export const ERROR_DNS_ZONE_MANAGER_ADD_RECORD = 'ERROR_DNS_ZONE_MANAGER_ADD_RECORD';
export const DNS_ZONE_MANAGER_ADD_RECORD_SELECT_TYPE = 'DNS_ZONE_MANAGER_ADD_RECORD_SELECT_TYPE';

export const REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD = 'REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD';
export const RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD = 'RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD';
export const ERROR_DNS_ZONE_MANAGER_EDIT_RECORD = 'ERROR_DNS_ZONE_MANAGER_EDIT_RECORD';
export const DNS_ZONE_MANAGER_EDIT_RECORD_SELECT_TYPE = 'DNS_ZONE_MANAGER_EDIT_RECORD_SELECT_TYPE';

export const REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD = 'REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD';
export const RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD = 'RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD';
export const ERROR_DNS_ZONE_MANAGER_DELETE_RECORD = 'ERROR_DNS_ZONE_MANAGER_DELETE_RECORD';

export const REQUEST_DNS_ZONE_MANAGER_GET_RECORD = 'REQUEST_DNS_ZONE_MANAGER_GET_RECORD';
export const RECEIVE_DNS_ZONE_MANAGER_GET_RECORD = 'RECEIVE_DNS_ZONE_MANAGER_GET_RECORD';
export const ERROR_DNS_ZONE_MANAGER_GET_RECORD = 'ERROR_DNS_ZONE_MANAGER_GET_RECORD';

export const RECEIVE_SURVEY = 'RECEIVE_SURVEY';
export const REQUEST_SURVEY = 'REQUEST_SURVEY';
export const ERROR_SURVEY = 'ERROR_SURVEY';

/* CUSTOMER SURVEY */
export const REQUEST_CUSTOMER_SURVEY = 'REQUEST_CUSTOMER_SURVEY';
export const SUCCESS_CUSTOMER_SURVEY = 'SUCCESS_CUSTOMER_SURVEY';
export const ERROR_CUSTOMER_SURVEY = 'ERROR_CUSTOMER_SURVEY';

export const REQUEST_VERIFY_CUSTOMER_SURVEY = 'REQUEST_VERIFY_CUSTOMER_SURVEY';
export const SUCCESS_VERIFY_CUSTOMER_SURVEY = 'SUCCESS_VERIFY_CUSTOMER_SURVEY';
export const ERROR_VERIFY_CUSTOMER_SURVEY = 'ERROR_VERIFY_CUSTOMER_SURVEY';
export const SKIP_CUSTOMER_SURVEY = 'SKIP_CUSTOMER_SURVEY';

/* CUSTOMER SURVEY */
