import React, { useEffect, useState, useContext } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import ActionButton from '@/components/Buttons/ActionButton';
import CourseCard from './CourseCard';
import styles from './styles';
import { AcademyPassContext } from '@/contexts/academyPass/context';


const AcademyPassKeepWatchingList = ({ billStatus, classes, t }) => {
  const { coursesList } = useSelector(state => state.academyPass.courses);
  const { jwt, userId } = useSelector(state => state.academyPass.tokens);
  const { isPassActive } = useSelector(state => state.academyPass.courses);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [listLimit, setListLimit] = useState(4);
  const { renderedCourses } = useContext(AcademyPassContext);
  const [shouldRenderCourseList, setShouldRenderCourseList] = useState(true);

  const isInProgress = course => (
    (course.statistics !== undefined) && (course.statistics.summary !== undefined) && (course.statistics.summary.progress > 0 && course.statistics.summary.progress < 100)
  );
  const isPremium = course => (
    !(course.isFree || course.amount === 0 || course.amount === undefined)
  );
  const isVisible = (course) => {
    if (course && course.visibility !== undefined) {
      return course.visibility === 'public';
    }
  };

  useEffect(() => {
    if (renderedCourses && !shouldRenderCourseList) {
      setFilteredCourses(renderedCourses.filter(course => (isVisible(course) && !isPremium(course) && isInProgress(course))).slice(0, listLimit));
    }
    if (coursesList && coursesList.length > 0 && shouldRenderCourseList) {
      setFilteredCourses(coursesList.filter(course => (isVisible(course) && !isPremium(course) && isInProgress(course))).slice(0, listLimit));
      if (renderedCourses.length > 1) {
        setShouldRenderCourseList(false);
      }
    }
  }, [coursesList, listLimit, renderedCourses, shouldRenderCourseList]);

  const loadMoreCourses = () => {
    setListLimit(filteredCourses.length);
  };

  const sortingCoursesByDate = filteredCourses.sort((a, b) => new Date(a.productIssueDate).getTime() - new Date(b.productIssueDate).getTime()).reverse();

  return (
    filteredCourses.length > 0
    && (
    <div data-testid="keep-watching-list" className={classes.keepWatching}>
      <Typography className={classes.title}>
        {t('academypass.keepWatching.title')}
      </Typography>
      <div className={classnames([classes.wrapper, classes.cardGrid])} data-testid="courses-grid">
        {(renderedCourses || coursesList) && sortingCoursesByDate.map(course => (<CourseCard course={course} jwt={jwt} userId={userId} isPassActive={isPassActive} isPaidAndActive={billStatus !== null} cardType="keep-watching-card" key={course.idProduct} />))}
      </div>
      {filteredCourses.length > listLimit && <ActionButton onClick={loadMoreCourses} data-testid="btn-loadmore-keepwatching">{t('academypass.keepWatching.loadMore')}</ActionButton>}
    </div>
    )
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPassKeepWatchingList));
