import PropTypes from 'prop-types';

export const SubscriptionCancelModalProps = {
  cancelRenewalDescriptionLabel: PropTypes.string,
  cancelRenewalLabel: PropTypes.string,
  cancelImmediateDescriptionLabel: PropTypes.string,
  cancelImmediateLabel: PropTypes.string,
  continueButtonLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  onCloseClick: PropTypes.func,
  onContinueButtonClick: PropTypes.func,
  onQuitButtonClick: PropTypes.func,
  quitButtonLabel: PropTypes.string,
  titleLabel: PropTypes.string,
};

export const CancelOptions = {
  CANCEL_RENEWAL: 'CANCEL_RENEWAL',
  CANCEL_IMMEDIATE: 'CANCEL_IMMEDIATE',
};
