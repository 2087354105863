import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryUltraLight};
    border-radius: 4px;
    border: 1px solid ${theme.v2.colors.feedback.canceledMedium};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    margin: 0;
    text-align: center;
  `}
`;

export const PlatformDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const StatusResultWrapper = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 10px;
    margin-top: 1.5rem;

    p {
      margin: 0;
    }

    a {
      font-size: ${theme.v2.font.sizes.xxs};
    }

    svg {
      flex-shrink: 0;
    }
  `}
`;
