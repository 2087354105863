const styles = theme => ({
  dialogContent: {
    textAlign: 'center',
  },

  dialogTitle: {
    height: '45px',
    textAlign: 'right',
    [theme.breakpoints.down(700)]: {
      height: '50%',
    },
  },

  btnClose: {
    color: theme.color.tundora,
    padding: '6px',
    position: 'absolute',
    right: '15px',
    top: '15px',
  },

  modal: {
    borderRadius: 0,
    height: '220px',
    maxWidth: '600px',
    [theme.breakpoints.down(700)]: {
      height: '100%',
      margin: '0px',
      maxWidth: '100%',
    },
  },

  content: {
    padding: '35px 40px',
    position: 'relative',
  },

  checkAvailabilityPlanLinkButton: {
    alignItems: 'center',
    background: theme.color.indigo,
    color: theme.color.white,
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    height: '40px',
    letterSpacing: '0.01px',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    width: '218px',
  },

  titleDialog: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    marginLeft: '26px',
    marginRight: '26px',
    [theme.breakpoints.down(700)]: {
      margin: '0px',
    },
  },

  descriptionDialog: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'Regular',
    letterSpacing: '0.01px',
    marginTop: '8px',
  },

  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 5px',
  },

  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },

  warning: {
    borderRadius: '2px',
    marginBottom: '30px',
    padding: '15px',
  },

  actions: {
    textAlign: 'center',
  },

  btnCancel: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    marginRight: '10px',
  },
});

export default styles;
