import React from 'react';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import styles from './styles';

const CtaTestimonials = ({ classes, t }) => (
  <div className={classes.wrapper} data-testid="cta-testimonials">
    <Typography className={classes.testimonialTitle}>{t('academypass.cta.testimonials.title')}</Typography>
    <div className={classes.testimonialList}>
      <blockquote>
        {t('academypass.cta.testimonials.testimonial01')}
        <cite>
          {t('academypass.cta.testimonials.testimonialName01')}
        </cite>
      </blockquote>
      <blockquote>
        {t('academypass.cta.testimonials.testimonial02')}
        <cite>
          {t('academypass.cta.testimonials.testimonialName02')}
        </cite>
      </blockquote>
      <blockquote>
        {t('academypass.cta.testimonials.testimonial03')}
        <cite>
          {t('academypass.cta.testimonials.testimonialName03')}
        </cite>
      </blockquote>
      <blockquote>
        {t('academypass.cta.testimonials.testimonial04')}
        <cite>
          {t('academypass.cta.testimonials.testimonialName04')}
        </cite>
      </blockquote>
    </div>
  </div>
);
export default withI18n()(withStyles(styles, { withTheme: true })(CtaTestimonials));
