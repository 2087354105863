import React, { useContext, useEffect } from 'react';
import { DnsWizardZoneTable } from '@/components/domain/components/DnsWizardZoneTable';
import { DnsWizardHeader } from '@/components/domain/components/DnsWizardHeader';
import { DnsWizardContext } from '@/contexts/DnsWizard';
import { useConfigurationStatus } from '@/hooks/domains/wizard';
import { HOSTING_PLATFORM_TYPES, PLAN_TYPES } from '@/enums/domains/dns.enum';
import { IconDomain } from '@/icons';
import * as Styles from './DnsWizardZoneRecordsHandler.styles';

function DnsWizardZoneRecordsHandler({
  testId = 'dns-wizard-zone-records-handler',
  dnsWizardLocale = {},
  domain = {},
  scrollToDNSZoneRecords = false,
  setScrollToDNSZoneRecords,
  setShowAdvanceDNSZoneModal,
}) {
  const { openDnsTable, setOpenDnsTable } = useContext(DnsWizardContext);

  const {
    configurationStatus,
    planDetails,
    platformName,
  } = domain;

  const {
    isConfiguredThroughWizard,
    isConfiguredInHostGator,
  } = configurationStatus;

  const {
    dnsZoneOpenButtonText,
    dnsZoneCloseButtonText,
    dnsZoneHeaderMessage,
    dnsZoneHeaderTitle,
  } = dnsWizardLocale;

  useEffect(() => {
    if (scrollToDNSZoneRecords) {
      if (!openDnsTable) {
        setOpenDnsTable(true);
      }

      setScrollToDNSZoneRecords && setScrollToDNSZoneRecords(false);

      setTimeout(() => {
        const dnsZoneRecordList = document.getElementById('dns-zone-record-button');
        dnsZoneRecordList && dnsZoneRecordList.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [scrollToDNSZoneRecords, setScrollToDNSZoneRecords, openDnsTable, setOpenDnsTable]);

  const status = useConfigurationStatus(configurationStatus, platformName);
  const isPointedToCustomServer = status === HOSTING_PLATFORM_TYPES.CUSTOM;
  const shouldRenderDnsZoneTable = isConfiguredThroughWizard && isConfiguredInHostGator && !isPointedToCustomServer && planDetails.type !== PLAN_TYPES.RESALE;

  return shouldRenderDnsZoneTable && (
    <Styles.DnsWizardZoneRecordsHandlerWrapper data-testId={testId} id="dns-wizard-zone-records">
      <Styles.TextBlock>
        <DnsWizardHeader
          variation="configured"
          icon={<IconDomain />}
          title={dnsZoneHeaderTitle}
        />
        <Styles.Subtitle>{dnsZoneHeaderMessage && dnsZoneHeaderMessage()}</Styles.Subtitle>
      </Styles.TextBlock>
      <DnsWizardZoneTable
        openDnsTable={openDnsTable}
        setOpenDnsTable={setOpenDnsTable}
        openText={dnsZoneOpenButtonText}
        closeText={dnsZoneCloseButtonText}
        setShowAdvanceDNSZoneModal={setShowAdvanceDNSZoneModal}
      />
    </Styles.DnsWizardZoneRecordsHandlerWrapper>
  );
}

export default DnsWizardZoneRecordsHandler;
