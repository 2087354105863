import { IS_PROD } from '../../config';
import '@/styles/wootric.css';


export const initWootric = (properties) => {
  const customProperties = { persona: properties.persona };

  if (!IS_PROD && localStorage.getItem('showWootric')) {
    window.wootric_survey_immediately = true;
    window.wootric_no_surveyed_cookie = true;
  }

  // eslint-disable-next-line
  window.wootricSettings = {
    email: properties.email,
    created_at: properties.created_at,
    account_token: properties.wootricToken,
    language: properties.language,
    properties: { ...properties.wootric, ...customProperties },
  };

  window.wootric('run');
};
