import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.color.indigo};
    color: ${theme.color.white};
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    left: 0;
    padding: 16px;
    z-index: 9999;
    @media (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.color.white};
    display: flex;
    flex: 1;
    gap: 24px;
    justify-content: center;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      padding-left: 24px;
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.white};
    font: normal normal 300 16px/19px Roboto;
    letter-spacing: 0.01px;
    @media (max-width: ${theme.breakpoints.md}) {
      text-align: center;
      font: normal normal 300 14px/19px Roboto;
    }
  `}
`;

export const Button = styled.div`
  ${({ theme }) => css`
    border-radius: 2px;
    border: 1px solid ${theme.color.white};
    color: ${theme.color.white};
    cursor: pointer;
    font: normal normal medium 16px/21px Roboto;
    letter-spacing: 0.01px;
    padding: 6px 16px;
    &:hover {
      background-color: ${theme.palette.secondary.dark}
    }
  `}
`;

export const CloseButton = styled.button`
${({ theme }) => css`
  align-items: center;
  background-color: unset;
  border: unset;
  color: ${theme.color.white};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  width: 24px;
  `}
`;
