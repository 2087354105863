import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Styles from './VideoClasses.styles';
import useLocale from '@/hooks/useLocale';
import { Button, IconButton } from 'gatorcomponents';
import withErrorBoundary from '@/utils/errorBoundary/errorBoundary.jsx';
import { IconMinimize } from './components/IconMinimize';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_VIDEO_CLASSES } from '@/config/GrowthBook/constants';
import { useDispatch, useSelector } from 'react-redux';
import { LiveModal } from './components/LiveModal';
import { videoClassesActions } from '@/redux/modules';
import { useTrack } from '@/utils/Tracker/tracker';
import ReactPlayer from 'react-player/lazy';
import { useResize } from '@/hooks/useResize';

const ClassTag = ({ label, isLive }) => (
  <Styles.ClassTag isLive={isLive} id="tag">
    {isLive && (
      <Styles.Pulse />
    )}
    {label}
  </Styles.ClassTag>
);

// THIS IS A POC, SOME PATTERS AND METHODS HERE ARE NOT IDEAL
const VideoClasses = ({ asBanner = false }) => {
  const [showVideoClasses, setShowVideoClasses] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const summary = useSelector(state => state.summary);
  const { jwt: jwtToken } = useSelector(state => state.auth);
  const { embedDisplayed, liveData } = useSelector(state => state.videoClassesReducer);
  const {
    liveID, liveTitle, liveTag, enabledBanner, enableChat, bannerImage,
  } = liveData;
  const [embedSize, setEmbedSize] = useState('small');
  const [startWatchingTime, setStartWatchingTime] = useState(null);

  const showVideoClass = useFeatureIsOn(CHARLIE_VIDEO_CLASSES);

  const windowRect = useResize();
  const { width } = windowRect;
  const isDesktop = width > 600;

  const { sendAnalytics } = useTrack();
  const dispatch = useDispatch();
  const { videoClasses: videoClassesLocale } = useLocale();

  const playerRef = useRef(null);

  const isSmallEmbed = embedSize === 'small';

  const liveUrl = `https://www.youtube.com/embed/${liveID}?autoplay=1`;
  const chatUrl = `https://www.youtube.com/live_chat?is_popout=1&v=${liveID}&embed_domain=${window.location.hostname}`;

  const getLiveData = useCallback(() => {
    if (jwtToken && summary.datecreated) {
      dispatch(videoClassesActions.live.request());
    }
  }, [dispatch, jwtToken, summary.datecreated]);

  useEffect(() => {
    getLiveData();

    const doNotShowLiveModal = localStorage.getItem('doNotShowLiveModal');

    if (doNotShowLiveModal) {
      setShowModal(false);
      dispatch(videoClassesActions.modal.setModalViewStatus(false));
      dispatch(videoClassesActions.embed.setEmbedViewStatus(false));
    }
  }, [getLiveData, dispatch]);

  useEffect(() => {
    if (summary.id && showVideoClass && showVideoClasses && liveID) {
      const eventData = {
        campaign: 'Live Youtube',
        action: liveID ? 'Click Botão Live' : 'Click Botão Playlist',
        label: liveID ? 'Exibição Botão Live' : 'Exibição Botão Playlist',
      };
      sendAnalytics(eventData);
    }
  }, [showVideoClass, showVideoClasses, liveID, summary.id, sendAnalytics]);

  const trackPlay = () => {
    const eventData = {
      campaign: 'Live Youtube',
      action: 'Video play',
      label: 'Play Live',
    };
    sendAnalytics(eventData);

    if (playerRef.current) {
      setStartWatchingTime(playerRef.current.getCurrentTime());
    }
  };

  const trackPause = () => {
    if (startWatchingTime) {
      const timeWatching = Math.round(playerRef.current.getCurrentTime() - startWatchingTime);

      const eventData = {
        campaign: 'Live Youtube',
        action: 'Video pause',
        label: timeWatching,
      };

      sendAnalytics(eventData);
      setStartWatchingTime(null);
    }
  };

  window.onbeforeunload = () => {
    if (embedDisplayed && liveID) {
      trackPause();
      return videoClassesLocale.close;
    }
  };

  const handleShowEmbed = () => {
    if (!embedDisplayed) {
      const eventData = {
        campaign: 'Live Youtube',
        action: liveID ? 'Live Click' : 'Playlist Click',
        label: liveID ? 'Acesso Live Youtube' : 'Acesso Playlist Youtube',
      };
      sendAnalytics(eventData);
    }
    if (embedDisplayed) {
      const eventData = {
        campaign: 'Live Youtube',
        action: liveID ? 'Live Click' : 'Playlist Click',
        label: liveID ? 'Fechou Live Youtube' : 'Fechou Playlist Youtube',
      };
      sendAnalytics(eventData);
      trackPause();
    }
    if (isSmallEmbed) {
      setEmbedSize('large');
    }
    dispatch(videoClassesActions.embed.setEmbedViewStatus(!embedDisplayed));
  };

  const showLiveWithoutClick = useCallback(() => {
    const doNotShowLiveModal = localStorage.getItem('doNotShowLiveModal');

    if (!asBanner && liveID && enabledBanner && !doNotShowLiveModal) {
      setShowModal(true);
      dispatch(videoClassesActions.modal.setModalViewStatus(true));
    }
    if (!asBanner && !liveID) {
      setShowModal(false);
      dispatch(videoClassesActions.modal.setModalViewStatus(false));
      setEmbedSize('small');
    }
  }, [asBanner, liveID, dispatch, enabledBanner]);

  useEffect(() => {
    showLiveWithoutClick();
  }, [showLiveWithoutClick]);

  const handleResizeEmbed = () => setEmbedSize(isSmallEmbed ? 'large' : 'small');

  const hideVideoClasses = () => {
    const eventData = {
      campaign: 'Live Youtube',
      action: liveID ? 'Click Botão Live' : 'Click Botão Playlist',
      label: liveID ? 'Oculta Botão Live Youtube' : 'Oculta Botão Playlist Youtube',
    };
    sendAnalytics(eventData);
    setShowVideoClasses(false);
  };

  const hideModal = () => {
    const eventData = {
      campaign: 'Live Youtube',
      action: 'Click Modal Live',
      label: 'Oculta Modal Live Youtube',
    };
    sendAnalytics(eventData);
    setShowModal(false);
    dispatch(videoClassesActions.modal.setModalViewStatus(false));
  };

  const showEmbedFromModal = () => {
    const eventData = {
      campaign: 'Live Youtube',
      action: 'Click Modal Live',
      label: 'Mostrar Live Youtube',
    };
    sendAnalytics(eventData);
    setShowModal(false);
    dispatch(videoClassesActions.modal.setModalViewStatus(false));
    liveID ? handleResizeEmbed() : handleShowEmbed();
  };

  const neverShowModalAgain = () => {
    localStorage.setItem('doNotShowLiveModal', 'true');
    setShowModal(false);
    dispatch(videoClassesActions.modal.setModalViewStatus(false));
    dispatch(videoClassesActions.embed.setEmbedViewStatus(false));
  };

  const mountLabel = () => {
    if (asBanner && liveID) {
      return (
        <>
          <Styles.BannerCollumn>
            <strong>{liveTitle}</strong>
            <span>{videoClassesLocale.bannerDescription}</span>
          </Styles.BannerCollumn>
          <Styles.BannerCollumnDaily>
            {videoClassesLocale.daily(isDesktop)}
          </Styles.BannerCollumnDaily>
        </>
      );
    }

    if (liveID) {
      return <strong>{liveTitle}</strong>;
    }
  };

  const renderCta = () => (
    <>
      <Styles.ClassButtonWrapper asBanner={asBanner}>
        <Button
          label={mountLabel()}
          onClick={handleShowEmbed}
          title="open video class"
          testId="open-video-button"
          iconLeft={<ClassTag label={liveTag || videoClassesLocale.live} isLive={liveID} />}
        />
      </Styles.ClassButtonWrapper>
      <Styles.CloseButton>
        <IconButton icon="IconClose" onClick={hideVideoClasses} variant="clear" title="fechar" size="sm" />
      </Styles.CloseButton>
    </>
  );

  const renderIframe = () => (
    <Styles.Wrapper showEmbed={embedDisplayed} smallEmbed={isSmallEmbed}>
      <Styles.EmbedMinimizeButton smallEmbed={isSmallEmbed}>
        <IconMinimize onClick={handleResizeEmbed} title={isSmallEmbed ? 'aumentar' : 'diminuir'} smallEmbed={isSmallEmbed} />
      </Styles.EmbedMinimizeButton>
      <Styles.EmbedCloseButton smallEmbed={isSmallEmbed}>
        <IconButton icon="IconClose" onClick={handleShowEmbed} title="fechar" variant="clear" />
      </Styles.EmbedCloseButton>

      {liveID && (
        <Styles.Frame smallEmbed={isSmallEmbed}>
          <ReactPlayer
            url={liveUrl}
            controls
            height="100%"
            width="100%"
            playing={embedDisplayed}
            muted="true"
            onPlay={trackPlay}
            onPause={trackPause}
            onEnded={trackPause}
            ref={playerRef}
          />
        </Styles.Frame>
      )}

      {liveID && enableChat && embedSize === 'large' && (
        <Styles.Frame isChat>
          <iframe src={chatUrl} width="380" height="520" title="HG live chat" frameBorder="0" />
        </Styles.Frame>
      )}
    </Styles.Wrapper>
  );

  if (!showVideoClass || !showVideoClasses || !liveID) return <></>;

  if (!embedDisplayed) {
    return (
      renderCta()
    );
  }

  return (
    <>
      {!asBanner && enabledBanner && showModal && liveID && (
        <LiveModal
          showLive={showEmbedFromModal}
          onClose={hideModal}
          bannerImage={enabledBanner && bannerImage}
          notShowAgain={neverShowModalAgain}
          notShowAgainLabel={videoClassesLocale.notShowAgainLabel}
        />
      )}

      {asBanner && (
        renderCta()
      )}

      {!asBanner && embedDisplayed && !showModal && renderIframe()}
    </>
  );
};

export default withErrorBoundary(VideoClasses, 'VideoClasses');
