import React, { useContext, useEffect, useState } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import CourseCard from '../AcademyPassCoursesList/CourseCard';
import styles from './styles';
import { AcademyPassContext } from '@/contexts/academyPass/context';

const AcademyPassStartHere = ({ billStatus, classes, t }) => {
  const { coursesList } = useSelector(state => state.academyPass.courses);
  const { jwt, userId } = useSelector(state => state.academyPass.tokens);
  const { isPassActive } = useSelector(state => state.academyPass.courses);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const { renderedCourses } = useContext(AcademyPassContext);
  const [shouldRenderCourseList, setShouldRenderCourseList] = useState(true);

  const isVisible = (course) => {
    if (course && course.visibility !== undefined) {
      return course.visibility === 'public';
    }
  };

  useEffect(() => {
    if (renderedCourses && !shouldRenderCourseList) {
      setFilteredCourses(renderedCourses.filter(course => (isVisible(course) && (course.isFree || course.amount === 0 || course.amount === undefined))).slice(0, 4));
    }

    if (coursesList && coursesList.length > 0 && shouldRenderCourseList) {
      setFilteredCourses(coursesList.filter(course => (isVisible(course) && (course.isFree || course.amount === 0 || course.amount === undefined))).slice(0, 4));
      if (renderedCourses.length > 1) {
        setShouldRenderCourseList(false);
      }
    }
  }, [coursesList, renderedCourses, shouldRenderCourseList]);

  const sortingCoursesByDate = filteredCourses.sort((a, b) => new Date(a.productIssueDate).getTime() - new Date(b.productIssueDate).getTime()).reverse();

  return (
    <div data-testid="first-access-to-section">
      <Typography className={classes.title}>
        {t('academypass.list.titleStarted')}
      </Typography>
      <Typography className={classes.subtitle}>
        {t('academypass.list.subtitleStarted')}
      </Typography>
      <div className={classnames([classes.wrapper, classes.cardGrid])} data-testid="courses-grid">
        {coursesList && sortingCoursesByDate.map(course => (<CourseCard course={course} jwt={jwt} userId={userId} isPassActive={isPassActive} isPaidAndActive={billStatus !== null} cardType="course-top-ranked" key={course.idProduct} />))}
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPassStartHere));
