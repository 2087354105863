import styled, { css } from 'styled-components';

export const HostGatorOtherPlatformModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 16px 8px 16px 8px;
    @media (min-width: ${theme.breakpoints.sm}) {
      margin: 16px;
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeights.xxxxxl};
    margin: 0;
    max-width: 90%;
  `}
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    line-height: ${theme.font.lineHeights.lg};
    font-weight: ${theme.font.weights.regular};
    margin: 0;
    > span{
      width: 100%;
      display: block;
      margin-top: 8px;
      font-size: ${theme.font.sizes.xxs};
    }
    > div {
      display: inline;
      margin-left: 3px;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
      align-items: flex-start;
      display: flex;
      gap: 8px;
      margin-top: 16px;
      > p {
        color: ${theme.palette.grey.dark};
        font-family: ${theme.font.family.secondary};
        font-size: ${theme.font.sizes.xxs};
        font-weight: ${theme.font.weights.regular};
        line-height: ${theme.font.lineHeights.md};
        margin: 0;
        > span{
          display: block;
          font-size: ${theme.font.sizes.xxs};
          margin-top: 8px;
          width: 100%;
        }
      }
  `}
`;

export const NameServerInputs = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ position }) => css`
    align-self: ${position === 'left' ? 'flex-start' : 'center'} ;
  `}
`;

export const TextWrapper = styled.div`
  ${() => css`
      align-items: center;
      display: flex;
  `}
`;

export const IconWrapper = styled.div`
  ${({ orientation }) => {
    const rotate = orientation === 'up' ? 'rotate(0)' : 'rotate(180deg)';
    return css`
      align-items: center;
      display: flex;
      transform: ${rotate};
  `;
  }}
`;

export const DnsRecordsTable = styled.table`
  ${({ theme }) => css`
    border-collapse: collapse;
    color: ${theme.palette.grey.dark};
    display: none;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    line-height: ${theme.font.lineHeights.lg};

    & td {
      border-top: 1px solid ${theme.v1.color.line.fields};
      vertical-align: top;
    }

    & th {
      color: ${theme.v1.color.text.featured};
      font-weight: ${theme.font.weights.medium};
    }

    & th, & td {
      padding: 8px;
      text-align: left;
    }

    & dl {
      margin: 0;
    }

    & dt {
      display: inline;
      font-weight: ${theme.font.weights.bold};
    }

    & dd {
      display: inline;
      margin: 0;
    }

    @media (min-width: ${theme.breakpoints.sm}) {
      display: table;
    }
  `}
`;


export const DnsRecordsTableSmall = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    display: grid;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    line-height: ${theme.font.lineHeights.lg};
    
    & table {
      overflow: hidden;
      padding: 24px 16px 24px 16px;
    }

    & table:nth-child(even) {
      background-color: ${theme.v1.color.background.wizard};
    }

    & tr {
      border-top: 1px solid ${theme.v1.color.line.fields};
      display: flex;
      justify-content: space-between;
      padding: 10px 0 10px 0;
    }

    & tr:first-child {
      border: 0;
    }

    & td {
      text-align: right;
      vertical-align: top;
    }

    & th {
      color: ${theme.v1.color.text.featured};
      font-weight: ${theme.font.weights.medium};
      text-align: left;
    }

    & dl {
      margin: 0;
    }

    & dt {
      font-weight: ${theme.font.weights.bold};
    }

    & dd {
      margin: 0;
      overflow: hidden;
    }

    @media (min-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  ${({ theme }) => css`
      display: flex;
      align-items: flex-start;
      align-self: center;
      gap: 8px;
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${theme.font.sizes.xxs};
      line-height: ${theme.font.lineHeights.md};
      font-weight: ${theme.v1.font.weights.medium};

      > input {
        cursor: pointer;
        margin: 0;
        width: 41px;
        height: 24px;
        accent-color: ${theme.v1.color.action.hover};
      }

      @media (min-width: ${theme.v1.breakpoints.sm}) {
        align-items: center;

        > input {
          margin: 0;
          width: 24px;
          height: 24px;
        }
      }
  `}
`;
