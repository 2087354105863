import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    position:relative;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 100vh;
    }
  `}
`;

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 40px 0px 16px 90px;
    max-width: 500px;
    overflow-x: hidden;

    span {
      color: ${theme.v1.color.text.featured};
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 133px 105px 24px 35px;
    }
  `}
`;

export const Action = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin: 8px 0px 40px 90px;

    button:first-child {
      margin-right: 24px;
    }
    button:last-child {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column;
      margin: auto 35px 110px;
      
      button:last-child {
        margin-top: 12px;
        padding: 0px;
      }

      > button {
        width: 100%;
      }
    }
  `}
`;

export const Description = styled.p`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  display: inline-block;
  font-family: ${theme.v1.font.family.primary};
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  margin: 0px 0px 24px 90px;
  max-width: 450px;

  span {
    color: ${theme.v1.color.text.featured};
    font-weight: ${theme.v1.font.weights.medium};
  }
  
  @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px 35px 24px 35px;
    }
`}
`;

export const DecoLine = styled.img`
  bottom:0;
  height:5px;
  object-fit:none;
  position:absolute;
  width: 100%;
  z-index: 3;
`;

export const Image = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    position:absolute;
    right: 30px;
    width: 85px;
    z-index: 3;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      bottom: 45px;
      right: 45px;
    }
 `}
`;

export const Deco = styled.img`
  height: 121px;
  object-fit:none;
  position:absolute;
  width: 53px;
  
`;

export const DecoTriangle = styled.div`
  ${({ theme }) => css`
    background:linear-gradient(to bottom right,#ffffff00 53%,${theme.v1.color.background.wizard} 50%);
    bottom: 0;
    height: 165px;
    position:absolute;
    right: 0;
    width: 207px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      height: 243px;
      width: 306px
    }
  `}
`;
