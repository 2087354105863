import { locale } from '@/utils/locale';

export const useWizardToolsLocale = {
  woocommerce: {
    title: locale('sitesWizard.tools.woocommerce.title'),
    titleText: locale('sitesWizard.tools.woocommerce.titleText'),
    items: [
      locale('sitesWizard.tools.woocommerce.items.0'),
      locale('sitesWizard.tools.woocommerce.items.1'),
      locale('sitesWizard.tools.woocommerce.items.2'),
      locale('sitesWizard.tools.woocommerce.items.3'),
      onWoocommerceHelpMaterialClick => locale('sitesWizard.tools.woocommerce.items.4')(onWoocommerceHelpMaterialClick),
      locale('sitesWizard.tools.woocommerce.items.5'),
    ],
    warning: locale('sitesWizard.tools.woocommerce.warning'),
    action: locale('sitesWizard.tools.woocommerce.action'),
  },
  wordpress: {
    title: locale('sitesWizard.tools.wordpress.title'),
    titleText: locale('sitesWizard.tools.wordpress.titleText'),
    description: locale('sitesWizard.tools.wordpress.description'),
    items: [
      locale('sitesWizard.tools.wordpress.items.0'),
      locale('sitesWizard.tools.wordpress.items.1'),
      locale('sitesWizard.tools.wordpress.items.2'),
      locale('sitesWizard.tools.wordpress.items.3'),
      locale('sitesWizard.tools.wordpress.items.4'),
    ],
    warning: locale('sitesWizard.tools.wordpress.warning'),
    action: locale('sitesWizard.tools.wordpress.action'),
  },
  sitebuilder: {
    title: locale('sitesWizard.tools.sitebuilder.title'),
    titleText: locale('sitesWizard.tools.sitebuilder.titleText'),
    description: locale('sitesWizard.tools.sitebuilder.description'),
    items: [
      locale('sitesWizard.tools.sitebuilder.items.0'),
      locale('sitesWizard.tools.sitebuilder.items.1'),
      locale('sitesWizard.tools.sitebuilder.items.2'),
      locale('sitesWizard.tools.sitebuilder.items.3'),
      locale('sitesWizard.tools.sitebuilder.items.4'),
    ],
    action: locale('sitesWizard.tools.sitebuilder.action'),
  },
  otherCommerce: {
    title: locale('sitesWizard.tools.otherCommerce.title'),
    titleText: locale('sitesWizard.tools.otherCommerce.titleText'),
    description: locale('sitesWizard.tools.otherCommerce.description'),
    items: [
      locale('sitesWizard.tools.otherCommerce.items.0'),
      locale('sitesWizard.tools.otherCommerce.items.1'),
    ],
    otherCommerceOption: locale('sitesWizard.tools.otherCommerce.otherIconLabel'),
    warning: locale('sitesWizard.tools.otherCommerce.warning'),
    action: locale('sitesWizard.tools.otherCommerce.action'),
  },
  otherSite: {
    title: locale('sitesWizard.tools.otherSite.title'),
    titleText: locale('sitesWizard.tools.otherSite.titleText'),
    description: locale('sitesWizard.tools.otherSite.description'),
    items: [
      locale('sitesWizard.tools.otherSite.items.0'),
      locale('sitesWizard.tools.otherSite.items.1'),
    ],
    otherSiteOption: locale('sitesWizard.tools.otherSite.otherIconLabel'),
    warning: locale('sitesWizard.tools.otherSite.warning'),
    action: locale('sitesWizard.tools.otherSite.action'),
  },
  wondersuiteWoocommerce: {
    title: locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.title'),
    description: locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.description'),
    items: [
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.0'),
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.1'),
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.2'),
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.3'),
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.4'),
      locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.items.5'),
    ],
    warning: locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.warning'),
    action: locale('sitesWizard.wondersuite.tools.toolsCards.woocommerce.action'),
  },
  wondersuiteOtherSite: {
    title: locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.title'),
    description: locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.description'),
    items: [
      locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.items.0'),
      locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.items.1'),
    ],
    warning: locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.warning'),
    action: locale('sitesWizard.wondersuite.tools.toolsCards.otherSite.action'),
  },
  ecommercePlus: {
    title: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.title'),
    subtitle: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.subtitle'),
    tag: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.tag'),
    preList: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.preList'),
    titleText: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.titleText'),
    description: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.description'),
    items: [
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.0'),
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.1'),
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.2'),
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.3'),
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.4'),
      locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.items.5'),
    ],
    warning: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.warning'),
    action: locale('sitesWizard.wondersuite.tools.toolsCards.ecommercePlus.action'),
  },
  cardIcons: {
    joomla: 'Joomla',
    drupal: 'Drupal',
    moodle: 'Moodle',
    flarum: 'Flarum',
    magento: 'Magento',
    openCart: 'Opencart',
    sitebuilder: 'SiteBuilder',
    yith: 'Yith',
    others: locale('sitesWizard.tools.icons.other'),
  },
};
