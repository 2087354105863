import { whmcs } from './whmcs';
import { aws } from './aws';
import { rdStation } from './rdStation';
import { customerSupport } from './customerSupport';

const api = {
  aws,
  whmcs,
  rdStation,
  customerSupport,
};

export default api;
