import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0px;
    line-height: ${theme.font.lineHeights.xxxxxxl};
    margin: 32px 64px 24px 32px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 32px 0px 40px 24px;
    }
  `}
`;

export const Action = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    justify-content:center;
    margin-bottom: 40px;
    div:first-child button{
      margin-right:32px;
    }
  `}
`;
export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    padding-top:8px;
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-left: -42px;
    }
  `}
`;

export const InputContent = styled.div`
  ${() => css`
    width: 100%;
    margin: 0px auto 40px auto;
    padding: 0px 32px;
  `}
`;
