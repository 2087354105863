import styled, { css } from 'styled-components';
import arrowLeftSvg from '@/media/icons/arrow_left.svg';
import arrowRightSvg from '@/media/icons/arrow_right.svg';

export const SlickWrapper = styled.div`
  ${({ theme, maxWidth }) => css`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: ${maxWidth};

  .slick-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .slick-list {
      min-width: 100%;
      order: 1;
      overflow: hidden;

      @media (min-width: 769px){
        display: flex;
      }
      
      .slick-track { 
        display: flex;
      }
    }

    .slick-arrow{
      background: transparent !important;
      border: unset !important;
      bottom: 0;
      color: transparent !important;
      cursor: pointer;
      height: 24px;
      width: 24px;

      &.slick-prev {
        left: 0;
        order: 2;
  
        &::before {
          background-image: url(${arrowLeftSvg});
          background-position: center center;
          background-repeat: no-repeat;
          content: '←';
        }
      }

      &.slick-next {
        order: 4;
        right: 0;
  
        &::before {
          background-image: url(${arrowRightSvg});
          background-position: center center;
          background-repeat: no-repeat;
          content: '→';
        }
      }
    }

    .slick-dots {
      display: inline-flex !important;
      list-style-type: none;
      margin: 5px 0px 43px 0px;
      order: 3;
      padding: 0;

        li {
          background-color: transparent;
          border-radius: 100%;
          border: 2px solid ${theme.palette.grey.dark};
          box-shadow: unset;
          height: 10px;
          margin: 0px 12px;
          overflow: hidden;
          width: 10px;
          transition: 300ms background-color ease-in-out, 300ms border ease-in-out;

          &.slick-active{
            border: 2px solid ${theme.palette.primary.main};
            background-color: ${theme.palette.primary.main};
          }

          button {
            font-size: 0px;
          }
        }
    }
  }
  `}
`;
