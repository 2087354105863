import styled, { css } from 'styled-components';

export const DnsWizardPlanCardWrapper = styled.div`
  ${({ theme, isToggleOn }) => css`
    background-color: ${theme.v1.color.background.boxes};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.line.wizard};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: ${isToggleOn ? 0 : '24px'} 24px 24px 24px;
  `}
  p{
    margin: 0;
  }
`;

export const Header = styled.div`
  ${({ theme, isToggleOn }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${isToggleOn ? '5px' : '10px'};
    margin-top: ${isToggleOn ? '23px' : '10px'};

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding-top: ${isToggleOn && '25px'};
      padding-bottom: ${isToggleOn && '10px'};
    }
  `}
`;

export const Logo = styled.img`
  max-width: 160px;
`;

export const Edit = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    & span svg:hover {
      filter: brightness(60%);
    }
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      position: initial;
    }
  `}
`;

export const Nameservers = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.v1.font.weights.medium};
    span{
      color: ${theme.v1.color.action.hover};
    }
  `}
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const PlatformDesktop = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.action.primary};
    cursor: pointer;
    display: none;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.medium};
    &:hover {
      color: ${theme.v1.color.action.hover};
    }
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: initial;
    }
  `}
`;

export const PlatformMobile = styled.span`
  ${({ theme }) => css`
    cursor: pointer;
    display: initial;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    margin-top: 16px;
    text-align: right;
    &:hover {
      color: ${theme.v1.color.action.hover};
    }
    color: ${theme.v1.color.action.primary};
    font-weight: ${theme.v1.font.weights.medium};
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const Body = styled.div``;

export const Product = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    border-bottom: 1px solid ${theme.v1.color.line.fields};
    color: ${theme.v1.color.text.primary};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.normal};
    justify-content: start;
    margin-bottom: 8px;
    padding: 0 0 8px 8px;
    position: relative;
    span{
      font-size: ${theme.font.sizes.xxs};
      &:last-child{
        cursor: pointer;
      }
      &:not(:last-child){
        margin-right: 8px;
      }
    }
    p{
      margin-right: 10px;
      &:first-child{
        min-width: 20%;
      }
    }
    @media (min-width: ${theme.v1.breakpoints.md}) {
      align-items: center;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 344px;
      p{
        margin-right: 3px;
        &:first-child{
          min-width: auto;
        }
      }
      span{
        &:not(:last-child){
          margin-right: 24px;
        }
      }
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 24px;
  span{
    align-self: center;
    margin-right: 8px;
  }
  div{
    align-self: center;
  }
`;

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display:flex;
    flex-wrap: wrap;
    gap: 8px;
    p{
      margin-left: 8px;
      font-size: ${theme.font.sizes.xl};
      font-family: ${theme.v1.font.family.primary};
      font-weight: ${theme.v1.font.weights.medium};
      color: ${theme.v1.color.text.featured};
      line-height: 27px;
    }
  `}
`;

export const FooterMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.v1.font.weights.light};
    line-height: 16px;
    max-width: 110ch;
    > a {
      display: inline;
      color: ${theme.v1.color.action.primary};
    }
    span {
      display: block;
      margin-top: 8px;
    }
  `}
`;

export const PlatformLinkWrapper = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
  `}
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;

    button{
      padding: 0 11px;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
        align-items: flex-end;
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 10px;

        button{
          padding: 5px 0px
        }
    }
  `}
`;
