import React, { useEffect, useState } from 'react';
import * as Styles from './Radio.styles';

const Radio = ({
  checked = false,
  onChange,
  testId = 'radio',
  value = 'value',
  inputId = null,
  size = 'default',
  variant = 'default',
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  const handleOnChange = (event) => {
    (checked && onChange) && onChange(event, value);
    setCurrentChecked(!checked);
  };

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);
  return (
    <Styles.Wrapper
      data-testid={testId}
      checked={currentChecked}
      size={size}
      variant={variant}
    >
      <Styles.Input
        type="radio"
        value={value}
        data-testid={`${testId}-input`}
        onChange={handleOnChange}
        checked={currentChecked}
        id={inputId}
      />
      <Styles.Dot data-testid={`${testId}-dot`} />
    </Styles.Wrapper>
  );
};

export default Radio;
