import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.white};
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 12px;
    padding: 24px 24px 10px 24px;
    width: 100%;
  `}
`;


export const FilterBy = styled.p`
${({ theme }) => css`
font: normal normal normal 14px/19px Roboto;
letter-spacing: 0.01px;
line-heigth: 17px;
text-align: left;
width: 63px;
height: 19px;
letter-spacing: 0.01px;
color: #4A4A4A;
opacity: 1;

@media (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: 14;
    },
  `}
`;

export const FirstRow = styled.div`
    ${({ theme }) => css`
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    flex: 1;
    margin-bottom: -14px;

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`;

export const FilterLabel = styled.p`
    ${({ theme }) => css`
    color: ${theme.color.thundora};
    text-align: left;
    font: normal normal 300 24px/32px Roboto;
    letter-spacing: 0.01px;
    color: #4A4A4A;
    opacity: 1;
    margin-top: -15px;
    margin-bottom: 17px;

    @media (max-width: ${theme.breakpoints.sm}){
      margin-bottom: 14;
    }
    `}
    `;

export const SecondRow = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: '100%';

  @media (max-width: ${theme.breakpoints.sm}){
    align-items: flex-start;
    flex-direction: column;
  }
  `}
`;
