import React, { useContext } from 'react';
import classnames from 'classnames';
import { withI18n, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import ProductDetailActions from '@/components/Products/ProductDetailActions';
import LinkButton from '@/components/Buttons/LinkButton';
import CardIcon from '@/components/Cards/CardIcon/index';
import CardStatus from '@/components/Cards/CardStatus/index';
import CardWarning from '@/components/Cards/CardWarning';
import OldResellerWarning from '@/components/Products/OldResellerWarning';
import { ProductsContext } from '@/contexts/Products/context';
import ChipLinkButton from '@/components/Buttons/ChipLinkButton';
import styles from './styles';


const ProductDetailCard = ({
  children,
  classes,
  t,
  status,
  title,
  subtitle,
  disabledActions,
  product,
}) => {
  const changePlanAction = product.actions.find(action => action.type === 'plan-switch');
  const {
    oldResellerId,
    featureOldResellerNotification,
    products,
    cloudflareAvailableProducts,
  } = useContext(ProductsContext);

  return (
    <div
      data-testid={`cardDetail${status}`}
      className={classnames(classes.card, {
        [classes.statusActive]: status === 'Active',
        [classes.statusPending]: status === 'Pending',
        [classes.statusPendingTransfer]: status === 'Pending_Transfer',
        [classes.statusExpired]: status === 'Expired',
        [classes.statusCancelled]: status === 'Cancelled',
        [classes.statusFraud]: status === 'Fraud',
        [classes.statusSuspended]: status === 'Suspended',
        [classes.statusBuilding]: status === 'Building',
      })}
    >
      <div className={classes.cardHeader} data-id="product-detail-header">
        <div className={classes.cardHeaderInner}>
          <CardIcon className={classes.cardIcon} icon={Folder} status={status} />
          <div className={classes.cardName}>
            <span className={classes.cardTitle}>{title}</span>
            <span className={classes.cardSubtitle}>{subtitle}</span>
          </div>
          <CardStatus className={classes.cardLabel} status={status}>{t(`domainStatus.${status.replace(' ', '_')}`)}</CardStatus>
        </div>

        {status === 'Active' && (
        <div className={classes.cardActions}>
          <ProductDetailActions
            disabledActions={disabledActions}
            product={product}
            arrayOldResellerId={oldResellerId}
            featureOldResellerNotification={featureOldResellerNotification}
            cloudflareAvailableProducts={cloudflareAvailableProducts}
            products={products}
          />
        </div>
        )}

        {status === 'Pending' && (
        <CardWarning
          className={classes.cardWarning}
          status={status}
          message={(
            <Trans i18nKey="cardAlerts.pendingPlanLink">
              0
              <LinkButton className={classes.cardWarningLink} to={t('routes.billing')}>1</LinkButton>
            </Trans>
              )}
        />
        )}

        {(status === 'Suspended' && oldResellerId.find(packageid => product.packageid === packageid)) && (
        <div className={classes.chiplinkWrapper}>
          <ChipLinkButton
            status="active"
            type="plan-switch"
            label={featureOldResellerNotification ? t('actions.old-reseller-plan-switch.active') : t('actions.plan-switch.active')}
            target="_self"
            href={changePlanAction.linkUrl}
          />
        </div>
        )}

        {(status === 'Suspended' && !oldResellerId.find(packageid => product.packageid === packageid)) && (
        <CardWarning
          className={classes.cardWarning}
          status={status}
          message={(
            <Trans i18nKey="cardAlerts.suspendedPlanLink">
              0
              <LinkButton className={classes.cardWarningLink} to={t('routes.billing')}>1</LinkButton>
            </Trans>
                )}
        />
        )}

        {(oldResellerId.find(packageid => product.packageid === packageid) && featureOldResellerNotification) && (
        <OldResellerWarning product={product} productDetail />
        )}
      </div>

      <div className={classes.cardContent} data-id="product-detail-content">
        {children}
      </div>
    </div>
  );
};

export default withI18n()(withStyles(styles)(ProductDetailCard));
