const styles = theme => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 34px',
    backgroundColor: theme.color.white,
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    marginBottom: '6px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  title: {
    lineHeight: '38px',
    fontSize: '24px',
    fontWeight: theme.font.weights.light,
    margin: '0 20px 0 0',
    padding: 0,
    color: theme.color.tundora,
  },
  searchBarDiv: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  contentDiv: {
    display: 'flex',
  },
  titleBottom: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
});

export default styles;
