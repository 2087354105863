import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const AppVersion = ({ classes }) => {
  const buildInfo = process.env.REACT_APP_BUILD_INFO || '';
  const [branch, commitHash, buildDate, tags] = buildInfo.split('|');


  return (
    <div className={classes.content}>
      <ul className={classes.contentInfo}>
        <li data-testid="branch-info">
          <span className={classes.item}>Branch:</span>
          {branch}
          {' - '}
        </li>
        <li data-testid="commit-info">
          <span className={classes.item}>Commit:</span>
          <a
            className={classes.commit}
            href={`https://stash.endurance.com/projects/LATAMD/repos/clientarea/commits/${commitHash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{commitHash}</span>
          </a>
        </li>
        <li data-testid="build-info">
          <span className={classes.item}>Build:</span>
          {buildDate}
          {' - '}
        </li>
        <li data-testid="version-info">
          <span className={classes.item}>Version:</span>
          {tags}
        </li>
      </ul>
    </div>
  );
};

export default withStyles(styles)(AppVersion);
