import useLocale from '@/hooks/useLocale';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CancelOptions } from '@/components/billing/components/manageSubscription/SubscriptionCancelModal/SubscriptionCancelModal.types';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumb } from '@/redux/actions/breadCrumbs';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND } from '@/config/GrowthBook/constants';

const useBenefitUrlVerification = (retentionInfos, t) => {
  const [retentionTypeParam, setRetentionTypeParam] = useState('');
  const breadCrumb = useSelector(state => state.breadCrumbs.crumbs[0]);
  const dispatch = useDispatch();
  const { retentionType } = useParams();
  const { routes } = useLocale();
  const { cancelSubscription, cancelRenewal } = routes;
  const history = useHistory();
  const isRenewCanceling = `/${retentionType}` === cancelRenewal;
  const enabledDiscountRetentionNewFlux = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION_WITH_NEW_BACKEND);

  useEffect(() => {
    const verifyURL = () => {
      const retentionTypeReturn = `/${retentionType}`.toLowerCase();

      if (retentionTypeReturn === cancelSubscription) {
        setRetentionTypeParam(CancelOptions.CANCEL_IMMEDIATE);
      } else if (retentionTypeReturn === cancelRenewal) {
        setRetentionTypeParam(CancelOptions.CANCEL_RENEWAL);
      } else {
        history.push(routes.subscriptions);
      }
    };

    const verifyIfUserIsAbleToDiscount = () => {
      const isUnableToDiscount = retentionInfos && (!retentionInfos.isSharedHostingAbleToDiscount || retentionInfos.isFullRefund);

      if (isUnableToDiscount) {
        history.push(routes.subscriptions);
      }
    };

    const changeBreadcrumbTitle = () => {
      if (t && isRenewCanceling) {
        breadCrumb.name = t('title.cancelRenewal');
        dispatch(setBreadCrumb([breadCrumb]));
      }
    };

    verifyURL();
    !enabledDiscountRetentionNewFlux && verifyIfUserIsAbleToDiscount();
    changeBreadcrumbTitle();
  }, [retentionType, cancelSubscription, cancelRenewal, routes, history, isRenewCanceling, breadCrumb, retentionInfos, t, dispatch]);


  return {
    retentionTypeParam,
    isRenewCanceling,
  };
};

export default useBenefitUrlVerification;
