export const copyTextToClipboard = (textToCopy, contentId) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }
  // local development or older browsers
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';

  const content = contentId != null ? document.getElementById(contentId) : document.body;
  content.appendChild(textArea);

  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  content && content.removeChild && content.removeChild(textArea);
};
