import React from 'react';
import * as Styles from './Chip.styles';

const Chip = ({
  clickable = false,
  primaryColor = '#000000',
  secondaryColor = '#FFFFFF',
  label = 'Label Text',
  onClick = () => {},
  testId = 'chip',
  uppercase = false,
  variant = 'default',
  weight = 'regular',
}) => (
  <Styles.Chip
    clickable={clickable}
    data-testid={`${testId}`}
    onClick={clickable ? onClick : undefined}
    uppercase={uppercase}
    variant={variant}
    weight={weight}
    primaryColor={primaryColor}
    secondaryColor={secondaryColor}
  >
    {label}
  </Styles.Chip>
);

export default Chip;
