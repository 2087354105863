import { SITELOCK_PLANS } from '@/enums/products/products.enum';

export const CYCLE_TYPES = {
  ANNUALLY: 'annually',
  SEMIANNUALLY: 'semiannually',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
};

export const PRICES = {
  [SITELOCK_PLANS.PROFESSIONAL]: {
    [CYCLE_TYPES.ANNUALLY]: 191.88,
    [CYCLE_TYPES.SEMIANNUALLY]: 95.94,
    [CYCLE_TYPES.QUARTERLY]: 47.97,
    [CYCLE_TYPES.MONTHLY]: 15.99,
  },
  [SITELOCK_PLANS.PREMIUM]: {
    [CYCLE_TYPES.ANNUALLY]: 323.88,
    [CYCLE_TYPES.SEMIANNUALLY]: 161.94,
    [CYCLE_TYPES.QUARTERLY]: 80.97,
    [CYCLE_TYPES.MONTHLY]: 26.99,
  },
  [SITELOCK_PLANS.ENTERPRISE]: {
    [CYCLE_TYPES.ANNUALLY]: 515.88,
    [CYCLE_TYPES.SEMIANNUALLY]: 257.94,
    [CYCLE_TYPES.QUARTERLY]: 128.97,
    [CYCLE_TYPES.MONTHLY]: 42.99,
  },
};

export const SITELOCK_ID = {
  108: 'professional',
  109: 'premium',
  110: 'enterprise',
};
