import styled, { css } from 'styled-components';


export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
    display: block;
    margin: 1px 0px 0px 0px;
    padding: 24px 24px 24px 24px;

    @media (max-width: ${theme.v2.breakpoints.lg}){
      width: 100%;
    }

  `}
`;

export const WrapperContent = styled.div`
${({ theme }) => css`
    display: flex;
    @media (max-width: ${theme.v2.breakpoints.md}){
      display: block;
    }
    `}
`;

export const ContentDetails = styled.div`
${({ theme }) => css`
    display: block;
    margin: 0px 0px 0px 24px;

    &:first-child {
      margin-left: 0px;
    };

    @media (max-width: ${theme.v2.breakpoints.xs}){
      margin: 8px 0px 0px 0px;

      &:nth-child(2) {
        margin: 8px 0px 0px 24px;
      }

    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    display: flex;
    height: 28px;
    min-width:214px;
    align-items: center;
    width: 320px;

  `}
`;

export const Block = styled.div`
  ${({ theme }) => css`
  background: ${theme.v2.colors.white.primary};
  padding: 0px 0px 0px 8px;


  &:first-child{
   padding: 0px 0px 0px 0px;
  }

  div:first-child{
    position: unset;
  }
  `}
`;

export const InvoiceIdDescription = styled.div`
  ${({ theme }) => css`
  background: ${theme.v2.colors.white.primary};
  padding: 0px 0px 0px 8px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div:first-child{
    position: unset;
  }

  @media (max-width: ${theme.v2.breakpoints.xs}){
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
  `}
`;

export const InvoiceId = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 16px;
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: normal;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `}
`;

export const Icon = styled.svg`
    width: 18px;
    height: 18px;
    margin: 4px 0px 0px 0px;
`;

export const ContentProducts = styled.div`
${({ theme }) => css`

    align-items: center;
    display: block;
    margin: 0px 0px 0px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 8px 0px 0px 24px;
    }
    `}
`;

export const WrapperDetails = styled.div`
${({ theme, isPaid }) => css`
    display: flex;
    margin: 0px 0px 0px 0px;
    flex-wrap: wrap;

    >div:last-child {

      @media (max-width: 1366px){
        margin: ${isPaid ? '16px 0px 0px 0px' : '0px 0px 0px 24px'};
      }

      @media (max-width: ${theme.v2.breakpoints.lg}){
        margin: ${isPaid ? '16px 0px 0px 0px' : '0px 0px 0px 24px'};
      }

      @media (max-width: 1024px){
        margin: 16px 0px 0px 0px;
      }

      @media (max-width: ${theme.v2.breakpoints.xs}){
        margin: 24px 0px 0px 0px;
      }

    };

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 0px 0px 0px 24px;
    }

    @media (max-width: 1366px){
      max-width: ${isPaid ? '300px' : 'fit-content'};
    }

    @media (max-width: ${theme.v2.breakpoints.lg}){
      min-width: ${isPaid ? '300px' : 'fit-content'};
    }

    @media (max-width: 1024px){
      max-width: 300px;
      min-width: min-content;
    }

`}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: ${theme.v2.font.weights.regular};
    line-height: normal;
    margin: 4px 0px 0px 0px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `}
`;

export const Title = styled.p`
${({ theme }) => css`
  align-items: center;
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin: 8px 0px 0px 0px;
  max-width: 276px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  `}
`;

export const ContentActions = styled.div`
${({ theme, status }) => css`
    display: block;
    margin-left: auto;

  & button:last-child {
    margin: 0px 0px 0px 16px;
  }

  @media (max-width: 1380px){
    min-width: fit-content;

  }


    @media (max-width: ${theme.v2.breakpoints.lg}){
      flex-wrap: wrap;
      margin: 24px 0px 0px 0px;
      text-align: center;
      width: 100%;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      display:${status === 'paid' ? 'flex' : 'block'};
      flex-wrap: unset;
      margin-left:${status === 'paid' ? '-12px' : 'auto'};
      margin-top: 24px;
      text-align: center;
      width: 100%;
    }
    `}
`;
