import React, { useState } from 'react';
import * as Styles from './ManageCard.styles';
import {
  Button,
  IconSupport,
  Tooltip,
  Toggle,
} from 'gatorcomponents';
import PropTypes from 'prop-types';

export const ManageCard = ({
  cardTitle = 'card title',
  testId = 'manage-card',
  cardDescription = 'card description',
  tooltipLabel,
  button,
  toggle,
  icon,
  readMoreButtonLabel,
  isMobile,
}) => {
  const [readMore, setReadMore] = useState(false);

  const mountCardDescription = () => {
    const needTruncateText = isMobile && cardDescription.length > 88 && readMore === false;

    if (needTruncateText) {
      return `${cardDescription.substring(0, 88)}...`;
    }
    return cardDescription;
  };

  return (
    <Styles.CardContainer data-testid={`${testId}-card`}>
      <Styles.CardHeadContainer>
        <Styles.IconContainer>
          {icon}
        </Styles.IconContainer>
        <Styles.CardTitle>
          {cardTitle}
          <Styles.TooltipIconContainer>
            {!!tooltipLabel && (
              <Tooltip content={tooltipLabel}>
                <IconSupport size="sm" color="primaryExtraDark" />
              </Tooltip>
            )}
          </Styles.TooltipIconContainer>
        </Styles.CardTitle>

        <Styles.ToggleContainer>
          {!!toggle && (
            <Toggle
              testId={`${testId}-toggle`}
              onMark={toggle.onMark}
              checked={toggle.checked}
            />
          )}
        </Styles.ToggleContainer>
      </Styles.CardHeadContainer>

      <Styles.CardDescription extraPadding={!!button}>
        {`${mountCardDescription()} `}
        {isMobile && (
          <Button
            label={readMoreButtonLabel(readMore)}
            variant="text"
            onClick={() => setReadMore(!readMore)}
          />
        )}
      </Styles.CardDescription>

      {!!button && (
        <Styles.ButtonContainer>
          <Button
            label={button.buttonLabel}
            onClick={button.onClick}
            width="fit"
            testId={`${testId}-button`}
          />
        </Styles.ButtonContainer>
      )}
    </Styles.CardContainer>
  );
};

ManageCard.propTypes = {
  cardTitle: PropTypes.string,
  testId: PropTypes.string,
  cardDescription: PropTypes.string,
  tooltipLabel: PropTypes.object,
  button: PropTypes.shape({
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func,
  }),
  toggle: PropTypes.shape({
    onMark: PropTypes.func,
    checked: PropTypes.bool,
  }),
  icon: PropTypes.node,
  readMoreButtonLabel: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default ManageCard;
