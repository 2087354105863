import React, { useCallback, useState } from 'react';
import * as Styles from './MigrateVpsDedicated.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const MigrateVpsDedicated = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { migrateVpsDedicated: migrateVpsDedicatedLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    ipOrDomain: '',
    user: '',
    password: '',
    selectedRootAccess: '',
    selectedWhmcsKey: '',
    installedDomain: '',
    detailedList: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.ipOrDomain
    && !!formData.user
    && !!formData.password
    && !!formData.selectedRootAccess
    && !!formData.selectedWhmcsKey
    && !!formData.agreed
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.migrateVpsDedicated,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.MIGRATE_VPS_DEDICATED,
      tags: [
        'whmcs_form_tecnico_migracao_dedicado_vps',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
         • ${migrateVpsDedicatedLocale.body.product(selectedProduct)}
         • ${migrateVpsDedicatedLocale.body.request}
         • ${migrateVpsDedicatedLocale.body.ipOrDomain(formData.ipOrDomain)}
         • ${migrateVpsDedicatedLocale.body.user(formData.user)}
         • ${migrateVpsDedicatedLocale.body.password(formData.password)}
         • ${migrateVpsDedicatedLocale.body.selectRootAccess(formData.selectRootAccess)}
         • ${migrateVpsDedicatedLocale.body.selectWhmcsKey(formData.selectWhmcsKey)}
         • ${migrateVpsDedicatedLocale.body.installedDomain(formData.installedDomain)}
         • ${migrateVpsDedicatedLocale.body.detailedList(formData.detailedList)}
         • ${migrateVpsDedicatedLocale.body.agreed(formData.agreed)}
         • ${migrateVpsDedicatedLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    migrateVpsDedicatedLocale,
  ]);

  const options = [
    { label: migrateVpsDedicatedLocale.placeholders.yes, value: 1 },
    { label: migrateVpsDedicatedLocale.placeholders.no, value: 2 },
  ];

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {migrateVpsDedicatedLocale.info}
      </Styles.Info>

      <Styles.Title>
        {migrateVpsDedicatedLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="ipOrDomain"
          placeholder={migrateVpsDedicatedLocale.placeholders.ipOrDomain}
          label={formData.ipOrDomain ? migrateVpsDedicatedLocale.placeholders.ipOrDomain : ''}
          value={formData.ipOrDomain}
          onChange={e => handleChange({ key: 'ipOrDomain', value: e.target.value })}
          type="text"
          testId="ipOrDomain"
        />

        <Input
          name="user"
          placeholder={migrateVpsDedicatedLocale.placeholders.user}
          label={formData.user ? migrateVpsDedicatedLocale.placeholders.user : ''}
          value={formData.user}
          onChange={e => handleChange({ key: 'user', value: e.target.value })}
          type="text"
          testId="user"
        />

        <Input
          name="password"
          placeholder={migrateVpsDedicatedLocale.placeholders.password}
          label={formData.password ? migrateVpsDedicatedLocale.placeholders.password : ''}
          value={formData.password}
          onChange={e => handleChange({ key: 'password', value: e.target.value })}
          type="password"
          testId="password"
        />

        <Select
          name="selectRootAccess"
          testId="selectRootAccess"
          placeholder={migrateVpsDedicatedLocale.placeholders.selectRootAccess}
          label={formData.selectedRootAccess ? migrateVpsDedicatedLocale.placeholders.selectRootAccess : ''}
          options={options}
          value={formData.selectedRootAccess}
          setValue={label => handleChange({ key: 'selectedRootAccess', value: label })}
          withScroll
        />

        <Select
          name="selectWhmcsKey"
          testId="selectWhmcsKey"
          placeholder={migrateVpsDedicatedLocale.placeholders.selectWhmcsKey}
          label={formData.selectedWhmcsKey ? migrateVpsDedicatedLocale.placeholders.selectWhmcsKey : ''}
          options={options}
          value={formData.selectedWhmcsKey}
          setValue={label => handleChange({ key: 'selectedWhmcsKey', value: label })}
          withScroll
        />

        <Input
          name="installedDomain"
          placeholder={migrateVpsDedicatedLocale.placeholders.installedDomain}
          label={formData.installedDomain ? migrateVpsDedicatedLocale.placeholders.installedDomain : ''}
          value={formData.installedDomain}
          onChange={e => handleChange({ key: 'installedDomain', value: e.target.value })}
          type="text"
          testId="installedDomain"
        />

        <Input
          name="detailedList"
          placeholder={migrateVpsDedicatedLocale.placeholders.detailedList}
          label={formData.detailedList ? migrateVpsDedicatedLocale.placeholders.detailedList : ''}
          value={formData.detailedList}
          onChange={e => handleChange({ key: 'detailedList', value: e.target.value })}
          type="textarea"
          rows={5}
          testId="detailedList"
        />
      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {migrateVpsDedicatedLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : migrateVpsDedicatedLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default MigrateVpsDedicated;
