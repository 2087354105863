import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';

import snappyMail from '@/media/icons/snappy_mail_trial.svg';
import assetProEmailBanner from '@/media/icons/assetProEmailBanner.svg';

import * as Styles from './BannerToEnableTitanTrial.styles';
import { SelectBottomDashed } from '@/pages/common/SelectBottomDashed';


const BannerToEnableTitan = ({
  t,
  onDomainSelected,
  domainOptions,
  productName,
  onBannerLoaded,
}) => {
  const [selectDomain, setSelectDomain] = useState({ value: '', label: '' });

  const handleDomainSelected = (domain) => {
    setSelectDomain(domain);
    onDomainSelected(domain);
  };

  useEffect(() => {
    onBannerLoaded && onBannerLoaded();
  }, [onBannerLoaded]);

  return (
    productName
    && (
    <Styles.BannerWrapper data-testid="banner-wrapper">
      <img
        className="assetTriangle"
        loading="lazy"
        src={assetProEmailBanner}
        alt="three yellow triangle"
      />
      <Styles.Figure>
        <img
          className="snappy"
          src={snappyMail}
          alt="Our mascot snappy, holding a letter"
          loading="lazy"
          data-testid="snappy-image"
        />
      </Styles.Figure>

      <Styles.ContentSection data-testid="content">
        <Styles.BannerTitle>
          <Styles.Title>
            {t('bannerTitanTrial.title', { productName })}
          </Styles.Title>
          <Styles.TagBanner>
            {t('bannerTitanTrial.tagOfTrialTime')}
          </Styles.TagBanner>
        </Styles.BannerTitle>

        <Styles.SelectContainer>
          <SelectBottomDashed
            label={t('bannerTitanTrial.selectLabel')}
            options={domainOptions}
            onChange={handleDomainSelected}
            value={selectDomain.value}
          />
        </Styles.SelectContainer>

      </Styles.ContentSection>
    </Styles.BannerWrapper>
    )
  );
};

BannerToEnableTitan.propTypes = {
  t: PropTypes.func.isRequired,
  onDomainSelected: PropTypes.func.isRequired,
  domainOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })),
  productName: PropTypes.string,
};

BannerToEnableTitan.defaultProps = {
  domainOptions: [],
  productName: '',
};


export default withI18n()(BannerToEnableTitan);
