import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import AddRecordForm from '@/components/Dns/ZoneManager/AddRecordForm';
import styles from './styles';

const CollapsedRow = ({
  classes,
  recordedItem,
  toggleCollapse,
}) => {
  const { domainName } = useContext(DNSContext);
  return (
    <div
      className={classes.editFormWrapper}
      data-testid="collapsedEditRow"
    >
      <AddRecordForm editing recordedItem={recordedItem} toggleCollapse={toggleCollapse} domainName={domainName} />
    </div>
  );
};
export default (withI18n()(withStyles(styles)(CollapsedRow)));
