import React from 'react';
import * as Styles from './DnsWizardConfigCardCustom.styles';
import IconAttentionYellow from '@/icons/IconAttentionYellow/IconAttentionYellow';
import otherPlatformIcon from '@/media/domains/other-card.png';
import { Button } from 'gatorcomponents';
import { isMobile } from '@/utils/Validators/validation';

const DnsWizardConfigCardCustom = ({
  changePlatformText = '',
  nameServers = [],
  serverText,
  attentionMessage,
  testId = 'dns-wizard-config-card',
  changePlatformClick,
  shouldRenderPropagationMessage = false,
  otherPlatformCardTitle = '',
  otherPlatformAttentionMessage,
}) => (
  <Styles.DnsWizardPlanCardWrapper data-testid={testId} isToggleOn>
    <Styles.Header isToggleOn>
      <Styles.LogoWrapper>
        <Styles.Logo
          src={otherPlatformIcon}
          loading="lazy"
          width={30}
        />
        <p>{otherPlatformCardTitle}</p>
      </Styles.LogoWrapper>

      {!isMobile && (
        <Styles.Actions>
          <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
        </Styles.Actions>
      )}

    </Styles.Header>
    <Styles.Body>
      {nameServers && nameServers.map((nameServer, index) => (
        <Styles.Nameservers key={nameServer}>
          {`${serverText} ${index + 1}: `}
          <span>{nameServer}</span>
        </Styles.Nameservers>
      ))}
    </Styles.Body>
    {((attentionMessage && shouldRenderPropagationMessage)) && (
      <Styles.Footer data-testid="attention-message">
        <IconAttentionYellow />
        <Styles.FooterMessage>
          {attentionMessage}
          {otherPlatformAttentionMessage}
        </Styles.FooterMessage>
      </Styles.Footer>
    )}

    {isMobile && (
      <Styles.Actions>
        <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
      </Styles.Actions>
    )}

  </Styles.DnsWizardPlanCardWrapper>
);

export default DnsWizardConfigCardCustom;
