import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.v1.color.white};
    margin: 0px;
    position: relative;
    width: 100%;
    border: 1px solid ${theme.palette.grey.primary};
    border-radius: 4px;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      font-size: 14px;
    }

    * {
      font-family: ${theme.v1.font.family.primary};
    }
  `}
`;
export const FaqWrapper = styled.div`
    margin: 0;
`;

export const FaqTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.medium};
    display: flex;
    letter-spacing: 0.01px;
    line-height: 32px;
    margin: 0;
    padding: 25px 24px 0px;

    @media (max-width: ${theme.v1.breakpoints.sm}){
      font-size: 14px;
    }
  `}
`;

export const FaqTextTitle = styled.div`
  margin-left: 15px;
`;

export const ListWrapper = styled.div`
  display: flex;
  padding: 23px 24px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.sizes.sm};
    list-style-type: none;
    margin-bottom: 12px;

    & > a {
      color: ${theme.v1.color.action.primary};
      text-decoration-color: transparent;
      transition: text-decoration-color 150ms ease-in-out;
    }

    & > a:hover {
      color: ${theme.v1.color.action.primary};
      text-decoration-color: ${theme.v1.color.action.primary};
    }

    & svg {
      fill: transparent;
      margin-left: 6px;
      stroke: transparent;
      transition: fill 150ms ease-in-out, stroke 150ms ease-in-out;
    }

    &:hover {
      svg {
        fill: ${theme.v1.color.action.primary};
        stroke: ${theme.v1.color.action.primary};
      }
    }
  `}
`;

export const FaqFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.sizes.sm};
    justify-content: flex-start;
    padding: 0px 24px 30px;

    & > a {
      color: ${theme.palette.primary.main};
      text-decoration-color: transparent;
      transition: text-decoration-color 150ms ease-in-out;
    }

    & > a:hover {
      color: ${theme.palette.primary.main};
      text-decoration-color: ${theme.palette.primary.main};
    }

    & svg {
      fill: transparent;
      margin-left: 6px;
      stroke: transparent;
      transition: fill 150ms ease-in-out, stroke 150ms ease-in-out;
    }

    &:hover {
      svg {
        fill: ${theme.palette.primary.main};
        stroke: ${theme.palette.primary.main};
      }
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:center;
  margin: 40px auto 0px auto;
  max-width: 464px;
`;
