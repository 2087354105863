import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 9px 31px;

    @media (max-width: ${theme.v2.breakpoints.md}}) {
      align-items: center;
      flex-direction: column;
    }

  `}
`;

export const Header = styled.header``;

export const TitleModal = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    margin-bottom: 25px;
  `}
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;


export const AlertWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 31px;
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-bottom: 15px;
  `}
`;

export const ContentData = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;


    @media (max-width: ${theme.v2.breakpoints.md}) {
      display:block;
    }
  `}
`;
