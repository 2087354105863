import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';

const UpgradeNotEnabled = ({ classes, t, domain }) => (
  <>
    <Typography className={classes.containerTitle}>{t('proEmail.upgrade.underConstruction.title')}</Typography>
    <Typography className={classes.description}>{t('proEmail.upgrade.underConstruction.description')}</Typography>

    <LinkButton
      className={classes.managementLinkButton}
      data-testid="manageLinkButton"
      to={`${t('routes.emailsList')}${t('routes.emailsTitan')}/${domain}`}
    >
      {t('proEmail.upgrade.underConstruction.managementButton')}
    </LinkButton>
  </>
);

UpgradeNotEnabled.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)(UpgradeNotEnabled));
