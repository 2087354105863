const styles = theme => ({
  cardMessageWrapper: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
    '& > .wrapper-me': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& > .wrapper-analyst': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    '& > div .cardMessage-me': {
      backgroundColor: theme.color.brightSunLight,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    '& > div .cardMessage-analyst': {
      backgroundColor: theme.color.indigoLight,
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  cardMessage: {
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    width: '70%',
    padding: '15px 20px',
    wordBreak: 'break-all',
  },
  cardMessageTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.color.tundora,
    fontWeight: 600,
    '& > span': {
      fontWeight: 400,
    },
  },
  cardMessageText: {
    color: theme.color.tundora,
  },
  imgWrapper: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '2px',
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: '4px',
    marginTop: '5px',
    padding: '4px',
    alignItems: 'center',

    '&>*': {
      cursor: 'pointer',
      transition: 'opacity 300ms ease-in-out',

      '&:hover': {
        opacity: 0.9,
      },
    },
  },
});

export default styles;
