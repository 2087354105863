import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withI18n } from 'react-i18next';
import TicketButtonLoader from '@/components/Tickets/TicketButtonLoader';
import PasswordCheckList from '@/components/PasswordCheckList';
import PasswordField from '@/components/Fields/PasswordField';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { SUCCESS_CHANGE_PASSWORD } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierSuccess, notifierError } from '@/utils/Application/notifier';
import { validateSpace } from '@/utils/Validators/validation';
import { passwordScore } from '@/utils/Validators/passwordScore';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import { saveChangePassword } from '@/redux/actions/changePassword';
import styles from './styles';
import PageMessages from '@/pages/common/PageMessages';


class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    loadReCaptcha();

    this.state = {
      currentPwValue: '',
      currentPwError: false,
      currentPwErrorMsg: '',
      currentPwReady: false,
      currentPwFlagError: false,
      newPwValue: '',
      newPwError: false,
      newPwErrorMsg: '',
      newPwReady: false,
      newPwFlagError: false,
      confirmPwValue: '',
      confirmPwError: false,
      confirmPwErrorMsg: '',
      confirmPwReady: false,
      confirmPwFlagError: false,
      pwStrength: '',
      pwLabel: '',
      cancelButtonDisable: true,
      saveButtonDisable: true,
      visibleActions: false,
      visibleChecklist: true,
      checklist: {
        upperAndLowercase: false,
        number: false,
        symbol: false,
        eightCharacters: false,
        sameInBothFields: false,
      },
    };
  }

  handleCleanInputs = () => {
    this.setState({
      currentPwValue: '',
      newPwValue: '',
      confirmPwValue: '',
      pwStrength: '',
      pwLabel: '',
      saveButtonDisable: true,
      cancelButtonDisable: true,
      visibleActions: false,
      visibleChecklist: true,
      checklist: {
        upperAndLowercase: false,
        number: false,
        symbol: false,
        eightCharacters: false,
        sameInBothFields: false,
      },
    });
  }

  handleCancel = () => {
    this.handleCleanInputs();
    this.setState({
      currentPwValue: '',
      currentPwError: false,
      currentPwErrorMsg: '',
      currentPwReady: false,
      newPwValue: '',
      newPwError: false,
      newPwErrorMsg: '',
      newPwReady: false,
      confirmPwValue: '',
      confirmPwError: false,
      confirmPwErrorMsg: '',
      confirmPwReady: false,
      pwStrength: '',
      pwLabel: '',
      cancelButtonDisable: true,
      saveButtonDisable: true,
      visibleChecklist: true,
      visibleActions: false,
      checklist: {
        upperAndLowercase: false,
        number: false,
        symbol: false,
        eightCharacters: false,
        sameInBothFields: false,
      },
    });
  }

  handleChecklist = (input) => {
    const {
      newPwValue, confirmPwValue, currentPwReady, newPwReady,
    } = this.state;

    // Verify password change requirements are ready and hidden checklist
    if (currentPwReady && newPwReady && newPwValue === confirmPwValue) {
      this.setState({
        visibleChecklist: false,
      });
    } else {
      this.setState({
        visibleChecklist: true,
      });
    }

    // Handle regex clean password
    const cleanSymbols = newPwValue.replace(/\W/g, '');
    const numbers = cleanSymbols.replace(/[A-Z-a-z]/g, '');
    const symbols = newPwValue.replace(/\w/g, '');

    // Verify new password
    if (input === 'new') {
      // Check if have uppers and lowercase letters
      if (newPwValue.match(/[a-z]/) && newPwValue.match(/[A-Z]/)) {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            upperAndLowercase: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            upperAndLowercase: false,
          },
        }));
      }
      // Check if have number
      if (numbers.length > 0) {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            number: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            number: false,
          },
        }));
      }
      // Check if have symbol
      if (symbols.length > 0) {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            symbol: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            symbol: false,
          },
        }));
      }
      // Check if have 8 characters
      if (newPwValue.length >= 8) {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            eightCharacters: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            eightCharacters: false,
          },
        }));
      }
    }
    // Verify confirm password is equal new password
    if (input === 'confirm') {
      const { confirmPwValue } = this.state;
      if (newPwValue === confirmPwValue) {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            sameInBothFields: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          checklist: {
            ...prevState.checklist,
            sameInBothFields: false,
          },
        }));
      }
    }
  }

  handleValidations = (input) => {
    if (input === 'current') {
      const { currentPwValue } = this.state;
      const { t } = this.props;
      if (currentPwValue === '') {
        this.setState({
          currentPwError: true,
          currentPwErrorMsg: t('myData.changePassword.errorEmptyField'),
          currentPwFlagError: true,
        });
      } else if (currentPwValue.length < 6) {
        this.setState({
          currentPwError: true,
          currentPwErrorMsg: t('myData.changePassword.errorCurrentMinimumCharacters'),
          currentPwFlagError: true,
        });
      } else {
        this.setState({
          currentPwError: false,
          currentPwErrorMsg: '',
          currentPwReady: true,
        });
      }
    } else if (input === 'new') {
      const {
        newPwValue, pwStrength,
      } = this.state;
      const { t } = this.props;
      if (newPwValue === '') {
        this.setState({
          newPwError: true,
          newPwErrorMsg: t('myData.changePassword.errorEmptyField'),
          newPwFlagError: true,
        });
      } else if (pwStrength < 50) {
        this.setState({
          newPwError: true,
          newPwErrorMsg: '',
          newPwReady: false,
        });
      } else {
        this.setState({
          newPwError: false,
          newPwErrorMsg: '',
          newPwReady: true,
        });
      }
    } else if (input === 'confirm') {
      const { confirmPwValue, newPwValue } = this.state;
      const { t } = this.props;
      if (confirmPwValue === '') {
        this.setState({
          confirmPwError: true,
          confirmPwErrorMsg: t('myData.changePassword.errorEmptyField'),
          confirmPwFlagError: true,
        });
      } else if (confirmPwValue !== newPwValue) {
        this.setState({
          confirmPwError: true,
          confirmPwErrorMsg: t('myData.changePassword.errorConfirmNew'),
          confirmPwFlagError: true,
        });
      } else {
        this.setState({
          confirmPwError: false,
          confirmPwErrorMsg: '',
          confirmPwReady: true,
        });
      }
    }
  }

  handleBlur = (event, input) => {
    this.handleValidations(input);
  }

  handleSave = () => {
    const inputs = ['current', 'new', 'confirm'];
    inputs.map(input => this.handleValidations(input));

    const { currentPwReady, newPwReady, confirmPwReady } = this.state;
    const { saveChangePassword, onEnqueueSnackbar, loading } = this.props;

    if (currentPwReady && newPwReady && confirmPwReady) {
      const { currentPwValue, newPwValue, confirmPwValue } = this.state;
      const passwords = {
        newpw: newPwValue,
        existingpw: currentPwValue,
        confirmpw: confirmPwValue,
      };
      if (!loading) {
        this.setState({ cancelButtonDisable: true });
        saveChangePassword(passwords).then((response) => {
          if (response.type === SUCCESS_CHANGE_PASSWORD) {
            onEnqueueSnackbar('myData.changePassword.nofifierSuccess', notifierSuccess);
            this.handleCleanInputs();
          } else {
            if (!response.error_message && response.notifications.length === 0) {
              onEnqueueSnackbar('myData.changePassword.notifierError', notifierError);
            }
            this.setState({ cancelButtonDisable: false });
          }
        });
      }
    }
  }

  handleCancelButtonDisable = () => {
    const { currentPwValue, newPwValue, confirmPwValue } = this.state;
    if (currentPwValue !== '' || newPwValue !== '' || confirmPwValue !== '') {
      this.setState({
        cancelButtonDisable: false,
      });
    } else if (currentPwValue === '' || newPwValue === '' || confirmPwValue === '') {
      this.setState({
        cancelButtonDisable: true,
      });
    }
  }

  handleKeyUp = (event, input) => {
    const {
      currentPwFlagError, newPwFlagError, confirmPwFlagError, currentPwValue, newPwValue, confirmPwValue, pwStrength,
    } = this.state;

    if (currentPwFlagError) this.handleValidations(input);
    if (newPwFlagError) this.handleValidations(input);
    if (confirmPwFlagError) this.handleValidations(input);
    this.handleCancelButtonDisable();

    if (currentPwValue !== '' && newPwValue !== '' && confirmPwValue !== '') {
      if (currentPwValue.length > 5) {
        if (pwStrength > 49) {
          if (newPwValue === confirmPwValue) {
            this.setState({
              saveButtonDisable: false,
            });
          } else {
            this.setState({
              saveButtonDisable: true,
            });
          }
        } else {
          this.setState({
            saveButtonDisable: true,
          });
        }
      } else {
        this.setState({
          saveButtonDisable: true,
        });
      }
    } else {
      this.setState({
        saveButtonDisable: true,
      });
    }

    // handle when need adjust new password with confirm password filled
    if (input === 'new' && confirmPwValue !== '') {
      this.handleValidations('confirm');
    }

    this.handleChecklist(input);
    this.handleActionsVisible();
  }

  handleActionsVisible = () => {
    const {
      currentPwValue, newPwValue, confirmPwValue,
    } = this.state;
    if (!!currentPwValue || !!newPwValue || !!confirmPwValue) {
      this.setState({
        visibleActions: true,
      });
    } else {
      this.setState({
        visibleActions: false,
      });
    }
  }

  handleChange = (event, input) => {
    event.persist();
    if (!validateSpace(event.target.value)) {
      this.setState(prevState => ({
        ...prevState,
        [input]: event.target.value,
      }));
    }

    if (input === 'newPwValue') {
      const strength = passwordScore(event.target.value.trim());
      this.setState({
        pwStrength: strength.strength,
        pwLabel: strength.label,
      });
    }
  }

  render() {
    const { classes, t, loading } = this.props;
    const {
      newPwValue,
      newPwError,
      newPwErrorMsg,
      confirmPwValue,
      confirmPwError,
      confirmPwErrorMsg,
      currentPwValue,
      currentPwError,
      currentPwErrorMsg,
      cancelButtonDisable,
      saveButtonDisable,
      visibleActions,
      checklist,
      visibleChecklist,
    } = this.state;

    return (
      <Fragment>
        <PageMessages
          displayInvoiceMessage={false}
          displayDomainAlertManager={false}
        />
        <ContentContainer className={classes.root}>
          <Card className={classes.border}>
            <div className={classes.content}>
              <Typography className={classes.title}>{t('myData.changePassword.title')}</Typography>
              <div>
                <Grid container spacing={0} alignItems="flex-start">
                  <Grid item xs={12} sm={6} lg={4}>
                    <PasswordField
                      current
                      value={currentPwValue}
                      type="password"
                      strength
                      autoComplete="current-password"
                      name="password"
                      label={t('myData.changePassword.currentPassword')}
                      required
                      variant="outlined"
                      margin="normal"
                      spellCheck="false"
                      fullWidth
                      onKeyUp={event => this.handleKeyUp(event, 'current')}
                      onChange={event => this.handleChange(event, 'currentPwValue')}
                      onBlur={event => this.handleBlur(event, 'current')}
                      error={currentPwError}
                      helperText={currentPwErrorMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="flex-start">
                  <Grid item xs={12} sm={6} lg={4}>
                    <PasswordField
                      value={newPwValue}
                      type="password"
                      strength
                      name="password"
                      label={t('myData.changePassword.newPassword')}
                      required
                      variant="outlined"
                      margin="normal"
                      spellCheck="false"
                      fullWidth
                      onKeyUp={event => this.handleKeyUp(event, 'new')}
                      onChange={event => this.handleChange(event, 'newPwValue')}
                      onBlur={event => this.handleBlur(event, 'new')}
                      error={newPwError}
                      helperText={newPwErrorMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="flex-start">
                  <Grid item xs={12} sm={6} lg={4}>
                    <PasswordField
                      value={confirmPwValue}
                      type="password"
                      strength
                      name="password"
                      label={t('myData.changePassword.confirmPassword')}
                      required
                      variant="outlined"
                      margin="normal"
                      spellCheck="false"
                      fullWidth
                      onKeyUp={event => this.handleKeyUp(event, 'confirm')}
                      onChange={event => this.handleChange(event, 'confirmPwValue')}
                      onBlur={event => this.handleBlur(event, 'confirm')}
                      error={confirmPwError}
                      helperText={confirmPwErrorMsg}
                    />
                  </Grid>
                </Grid>
                <PasswordCheckList
                  showCheckList={visibleChecklist}
                  upperAndLowercase={checklist.upperAndLowercase}
                  number={checklist.number}
                  symbol={checklist.symbol}
                  eightCharacters={checklist.eightCharacters}
                  sameInBothFields={checklist.sameInBothFields}
                />
                <Grid container>
                  {visibleActions && (
                    <>
                      <OutlineButton
                        disabled={cancelButtonDisable}
                        onClick={() => this.handleCancel()}
                      >
                        {t('cancel')}
                      </OutlineButton>
                      <PrimaryButton
                        disabled={saveButtonDisable}
                        onClick={() => this.handleSave()}
                        className={classes.save}
                      >
                        {loading ? <TicketButtonLoader /> : t('save')}
                      </PrimaryButton>
                    </>
                  )}
                </Grid>
              </div>
            </div>
          </Card>
        </ContentContainer>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveChangePassword: data => dispatch(saveChangePassword(data)),
  onEnqueueSnackbar: (message, options) => dispatch(enqueueSnackbar(message, options)),
});

const mapStateToProps = state => ({
  loading: state.changePassword.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withI18n()(ChangePasswordPage)));
