import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpgradeSummary } from '@/redux/actions/emails';
import { ERROR_GET_UPGRADE_SUMMARY } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import { COUNTRY } from '@/config';
import { ESSENTIALS_PLAN, TRIAL_PLAN, FREE_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { locale } from '@/utils/locale';
import { isBRServer } from '@/utils/Validators/validation';
import InvoiceTable from '../../InvoiceTable';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import PaymentCycle from './PaymentCycle';
import SelectNewPlan from './SelectNewPlan';
import * as Styles from './TitanUpgradePlanCycleContainer.styles';
import { ALPHA_DISABLED_CYCLE_PAYMENT_IN_UPGRADE_PLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const TitanUpgradePlanCycleContainer = ({
  plan, plans, domainObject, setPlan,
}) => {
  const validPaymentCycle = domainObject.billingcycle.toLowerCase() === 'monthly' || domainObject.billingcycle.toLowerCase() === 'quarterly' || domainObject.billingcycle.toLowerCase() === 'semiannually' || domainObject.billingcycle.toLowerCase() === 'annually' || domainObject.billingcycle.toLowerCase() === 'biennially';
  const dispatch = useDispatch();
  const initialPaymentMethod = isBRServer ? 'braspag_credit_1x' : 'dlocal';
  const initialPaymetCycle = validPaymentCycle ? domainObject.billingcycle.toLowerCase() : 'monthly';
  const [paymentCycle, setPaymentCycle] = useState(initialPaymetCycle);
  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);
  const [planOptions, setPlanOptions] = useState([]);
  const [summaryCalcs, setSummaryCalcs] = useState(null);
  const loadingUpgradeSummary = useSelector(state => state.emails.loadingUpgradeSummary);
  const [typing, setTyping] = useState(false);
  const [newAmount, setNewAmount] = useState(null);
  const [interacted, setInteracted] = useState(false);
  const [freePlanActivated, setFreePlanActivated] = useState(false);
  const isTrialPlan = plan.id === TRIAL_PLAN;
  const [upgradeButtonDisable, setUpgradeButtonDisable] = useState(isTrialPlan);
  const [isAccountNumberChanged, setAccountNumberChanged] = useState(false);
  const [isPaymetCycleChanged, setPaymetCycleChanged] = useState(false);
  const [isPaymentMethodChanged, setPaymentMethodChanged] = useState(false);
  const disabledCyclePaymentUpgrade = useFeatureIsOn(ALPHA_DISABLED_CYCLE_PAYMENT_IN_UPGRADE_PLAN);
  const [disabledPayment, setDisabledPayment] = useState(false);

  useEffect(() => {
    if (paymentCycle !== initialPaymetCycle) {
      setPaymetCycleChanged(true);
    } else {
      setPaymetCycleChanged(false);
    }

    if (paymentMethod !== initialPaymentMethod) {
      setPaymentMethodChanged(true);
    } else {
      setPaymentMethodChanged(false);
    }
    if (disabledCyclePaymentUpgrade) {
      setDisabledPayment((plan.id === ESSENTIALS_PLAN) && domainObject.plan !== 'free');
    }
  }, [domainObject.plan, paymentCycle, initialPaymetCycle, setPaymentCycle, paymentMethod, initialPaymentMethod, setPaymentMethod, disabledCyclePaymentUpgrade, plan.id]);

  useEffect(() => {
    if (isTrialPlan) {
      const disable = [isPaymetCycleChanged, isPaymentMethodChanged, isAccountNumberChanged].every(paymentChagend => !paymentChagend);
      setUpgradeButtonDisable(disable);
    } else {
      setUpgradeButtonDisable(isTrialPlan);
    }
  }, [isPaymetCycleChanged, isPaymentMethodChanged, isAccountNumberChanged, isTrialPlan]);

  const updatePaymetCycle = ((newPaymentCycle) => {
    setPaymentCycle(newPaymentCycle);
  });

  const updatePaymetMethod = (newPaymentMethod) => {
    setPaymentMethod(newPaymentMethod);
  };

  const upgradePlanLocale = useMemo(() => ({
    newPlanCardTitle: locale('proEmail.upgradePlan.newPlanCardTitle'),
    newPlanCardDescription: locale('proEmail.upgradePlan.newPlanCardDescription'),
    higherPlan: locale('proEmail.upgradePlan.higherPlan'),
    actualPlan: locale('proEmail.upgradePlan.actualPlan'),
    freeTrialPeriod: locale('proEmail.upgradePlan.freeTrialPeriod'),
    storage: locale('proEmail.upgradePlan.storage'),
    ilimitedMailBoxes: locale('proEmail.ilimitedMailBoxes'),
    monthlyPerMailBox: locale('proEmail.upgradePlan.monthlyPerMailBox'),
    whatIsincluded: locale('proEmail.upgradePlan.whatIsincluded'),
    whatIsincludedInPaid: locale('proEmail.upgradePlan.whatIsincludedInPaid'),
    free: {
      bennefits: locale('proEmail.upgradePlan.freeBenefits'),
      name: locale('proEmail.type.free'),
    },
    cycleOptions: {
      monthly: locale('proEmail.upgradePlan.Monthly'),
      quarterly: locale('proEmail.upgradePlan.Quarterly'),
      semiannually: locale('proEmail.upgradePlan.Semiannually'),
      annually: locale('proEmail.upgradePlan.Annually'),
      biennially: locale('proEmail.upgradePlan.Biennially'),
    },
    methodOptions: {
      creditCard: locale('proEmail.upgradePlan.creditCard'),
      gp_boleto: locale('proEmail.upgradePlan.gp_boleto'),
      gp_paypal: locale('proEmail.upgradePlan.gp_paypal'),
    },
  }), []);

  const filterPlans = useCallback((options) => {
    const trialId = options.findIndex(item => item.id === TRIAL_PLAN);

    if (trialId > -1 && (domainObject.plan === 'free' || domainObject.packageid === ESSENTIALS_PLAN)) {
      options.splice(trialId, 1);
    }

    const essentialId = options.findIndex(item => item.id === ESSENTIALS_PLAN);

    if (essentialId > -1 && domainObject.packageid === TRIAL_PLAN) {
      options.splice(essentialId, 1);
    }

    return options;
  }, [domainObject]);

  const setMultipleAccounts = useCallback(() => {
    let multiplied = null;
    if (newAmount) {
      multiplied = newAmount;
    } else if (domainObject.plan === 'free' || domainObject.packageid === TRIAL_PLAN) {
      multiplied = domainObject.used_accounts > 0 ? domainObject.used_accounts : 1;
    } else {
      multiplied = domainObject.total_accounts;
    }

    return multiplied;
  }, [domainObject, newAmount]);

  const activeProduct = useCallback(({ loopPlan, hasActiveFree, isActive }) => {
    let active = isActive;

    if (loopPlan.id === ESSENTIALS_PLAN && hasActiveFree && !interacted) {
      active = true;
    }

    return active;
  }, [interacted]);

  const mountNewPlan = useCallback(() => {
    const validPlans = filterPlans(plans);
    if (validPlans.find(option => option.id === TRIAL_PLAN)) {
      return validPlans.find(option => option.id === TRIAL_PLAN);
    }

    if (validPlans.find(option => option.id === ESSENTIALS_PLAN)) {
      return validPlans.find(option => option.id === ESSENTIALS_PLAN);
    }

    return {
      isFree: true,
      configoptions: [
        {
          monthly: '0.00',
          quarterly: '0.00',
          semiannually: '0.00',
          annually: '0.00',
        },
      ],
    };
  }, [filterPlans, plans]);

  const updateSummary = useCallback(({ multipliedAccounts, activePlan }) => {
    const shouldRequestSummary = domainObject.plan !== 'free';
    const hasSelectedOtherPlan = plan.name !== domainObject.plan;

    let newProductId = hasSelectedOtherPlan ? activePlan.id : null;

    if (plan.id === TRIAL_PLAN) {
      newProductId = ESSENTIALS_PLAN;
    }

    if (shouldRequestSummary) {
      dispatch(getUpgradeSummary({
        inboxAmount: multipliedAccounts,
        hostingId: domainObject.hosting_id,
        planId: plan.id,
        newProductId,
        paymentCycle,
      })).then((response) => {
        if (response.type === ERROR_GET_UPGRADE_SUMMARY) {
          if (domainObject.plan !== plan.name || (!plan.name && (plan.id !== 418 && plan.id !== 241))) {
            dispatch(enqueueSnackbar('proEmail.upgradePlan.error', notifierError));
          }
        } else {
          setSummaryCalcs(response.data.data);
        }
      });
    }
  }, [dispatch, domainObject, paymentCycle, plan]);

  const mountOptions = useCallback(() => {
    setTyping(true);
    const options = [];

    let hadSelected = false;
    let activePlan = null;
    const hasActiveFree = domainObject.plan === 'free';
    plans.forEach((loopPlan) => {
      const isFree = loopPlan.id === FREE_PLAN;

      let freeIsActive = false;
      if (!plan.name && isFree) {
        if (interacted) {
          freeIsActive = true;
        }
      }

      const isSelected = (plan.name) ? loopPlan.name === domainObject.plan : freeIsActive;

      const isActive = (plan.name) ? loopPlan.name === plan.name : freeIsActive;

      const object = {
        id: loopPlan.id,
        name: isFree ? upgradePlanLocale.free.name : loopPlan.name,
        size: isFree ? '1GB' : `${loopPlan.moduleParams.storageLimit}GB`,
        bennefits: isFree ? upgradePlanLocale.free.bennefits : loopPlan.description,
        price: {
          monthly: loopPlan.configoptions[0].monthly,
          quarterly: loopPlan.configoptions[0].quarterly,
          semiannually: loopPlan.configoptions[0].semiannually,
          annually: loopPlan.configoptions[0].annually,
          biennially: loopPlan.configoptions[0].biennially,
        },
        isSelected: isSelected || (isFree && domainObject.plan === 'free'),
        active: activeProduct({ loopPlan, hasActiveFree, isActive }),
        isFree,
      };

      if (isActive) {
        activePlan = object;
      }

      if (isSelected || domainObject.plan === 'free') {
        hadSelected = true;
      }
      if (hadSelected) {
        options.push(object);
      }
    });

    const validPlanOptions = filterPlans(options);

    setPlanOptions(validPlanOptions);

    if (hasActiveFree && !interacted) {
      setFreePlanActivated(true);

      const newPlan = mountNewPlan();

      setPlan(newPlan);
    }

    const multipliedAccounts = setMultipleAccounts();

    if (plan.name) {
      updateSummary({ multipliedAccounts, activePlan });
    }

    setTimeout(() => {
      setTyping(false);
    }, 300);
  }, [plans, plan, domainObject, upgradePlanLocale, interacted, setPlan, filterPlans, setMultipleAccounts, activeProduct, mountNewPlan, updateSummary]);

  useEffect(() => {
    mountOptions();
  }, [mountOptions]);

  const cycleOptions = [
    { name: upgradePlanLocale.cycleOptions.monthly, value: 'monthly' },
    { name: upgradePlanLocale.cycleOptions.quarterly, value: 'quarterly' },
    { name: upgradePlanLocale.cycleOptions.semiannually, value: 'semiannually' },
    { name: upgradePlanLocale.cycleOptions.annually, value: 'annually' },
    { name: upgradePlanLocale.cycleOptions.biennially, value: 'biennially' },
  ];

  const methodOptions = [
    { name: upgradePlanLocale.methodOptions.creditCard, value: COUNTRY === 'br' ? 'braspag_credit_1x' : 'dlocal', span: 'Acesso imediato ao plano' },
    { name: upgradePlanLocale.methodOptions.gp_boleto, value: COUNTRY === 'br' ? 'boleto' : 'payu_boleto' },
    { name: upgradePlanLocale.methodOptions.gp_paypal, value: 'paypal' },
  ];

  const handleSelectOption = (optionName) => {
    const newOptions = [...planOptions];
    const idx = newOptions.findIndex(option => option.name === optionName);

    newOptions.map((option) => {
      const newOption = option;
      return delete newOption.active;
    });

    newOptions[idx].active = true;

    setPlanOptions(newOptions);

    const newPlan = plans.find(option => option.name === optionName);

    setInteracted(true);
    setPlan(newPlan || {
      isFree: true,
      configoptions: [
        {
          monthly: '0.00',
          quarterly: '0.00',
          semiannually: '0.00',
          annually: '0.00',
          biennially: '0.00',
        },
      ],
    });
  };

  return (
    <Styles.ContainerCard>
      <SelectNewPlan
        freePlanActivated={freePlanActivated}
        planOptions={planOptions}
        paymentCycle={paymentCycle}
        handleSelectOption={handleSelectOption}
        newPlanCardTitle={upgradePlanLocale.newPlanCardTitle}
        newPlanCardDescription={upgradePlanLocale.newPlanCardDescription}
        higherPlan={upgradePlanLocale.higherPlan}
        actualPlan={upgradePlanLocale.actualPlan}
        freeTrialPeriod={upgradePlanLocale.freeTrialPeriod}
        storage={upgradePlanLocale.storage}
        ilimitedMailBoxes={upgradePlanLocale.ilimitedMailBoxes}
        monthlyPerMailBox={upgradePlanLocale.monthlyPerMailBox}
        whatIsincluded={upgradePlanLocale.whatIsincluded}
        whatIsincludedInPaid={upgradePlanLocale.whatIsincludedInPaid}
      />

      {(!plan.isFree && planOptions.length > 1) && (
        <Styles.Row>
          <PaymentCycle
            disabled={disabledPayment}
            paymentCycle={paymentCycle}
            setPaymentCycle={updatePaymetCycle}
            cycleOptions={cycleOptions}
          />
          <PaymentMethod
            disabled={disabledPayment}
            paymentMethod={paymentMethod}
            setPaymentMethod={updatePaymetMethod}
            methodOptions={methodOptions}
          />
        </Styles.Row>
      )}

      {planOptions.length > 1 && (
        <InvoiceTable
          disabledPayment={disabledPayment}
          plan={plan}
          domainObject={domainObject}
          paymentCycle={paymentCycle}
          paymentMethod={paymentMethod}
          calledFrom="cycleUpgrade"
          summaryCalcs={domainObject.plan !== 'free' && summaryCalcs}
          loadingUpgradeSummary={loadingUpgradeSummary}
          typing={typing}
          newAmount={newAmount}
          setNewAmount={setNewAmount}
          upgradeButtonDisable={upgradeButtonDisable}
          setUpgradeButtonDisable={setUpgradeButtonDisable}
          setAccountNumberChanged={setAccountNumberChanged}
        />
      )}

    </Styles.ContainerCard>
  );
};

export default TitanUpgradePlanCycleContainer;
