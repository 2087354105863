import styled, { css, keyframes } from 'styled-components';

const appearence = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const TooltipCard = styled.div`
  ${({ theme, width }) => css`
    animation: ${appearence} 200ms;
    background-color: ${theme.v1.color.background.tooltip};
    border-radius: 3px;
    color: ${theme.v1.color.white.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 12px;
    left: 0px;
    overflow: hidden;
    padding: 8px 16px;
    position: fixed;
    top: 0px;
    width: ${width};
    z-index: ${theme.v1.layers.important};
    max-width: 35ch;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: calc(100vw - 20px);
    }
  `}
`;
