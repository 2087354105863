import React, { useState } from 'react';
import { withI18n } from 'react-i18next';
import { Card, withStyles } from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  Schedule as ScheduleIcon,
  VideoLibrary as VideoLibraryIcon,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { formatSecondsToMinutes } from '@/utils/Formatters/formatTime';
import styles from './styles';

const CourseItem = ({
  classes,
  course,
  index,
  animationId,
  userProgress,
  t,
}) => {
  const firstItem = index === 0;
  const videoThumbnail = course.pictures.sizes[2].link;
  const isMobile = useMediaQuery('(max-width:1150px)');
  const isSmall = !firstItem || isMobile;

  const hasStorageCourseProgress = !!userProgress[course.keyName] && Object.keys(userProgress[course.keyName]);
  const watchedCourses = hasStorageCourseProgress && hasStorageCourseProgress.map(key => userProgress[course.keyName][key].watched);
  const courseWatched = watchedCourses && (watchedCourses.length === course.modules.length) && (watchedCourses.indexOf(false) === -1);

  const [redirect, setRedirect] = useState(false);
  const haveVideos = course.totalVideos !== 0;

  const handleRedirect = () => {
    haveVideos && setRedirect(true);
  };

  if (redirect) {
    return <Redirect to={`${t('routes.courses')}/${course.keyName}`} />;
  }

  return (
    <Card
      elevation={1}
      className={classnames(classes.container, {
        [classes.notWatched]: !courseWatched,
        [classes.watched]: courseWatched,
      })}
      id={animationId}
      data-testid="course-item-component"
      onClick={() => handleRedirect()}
    >
      <article className={classnames(classes.articleWrapper, { [classes.containerSmall]: isSmall })}>
        <figure className={classnames(classes.figure, { [classes.figureDecrease]: isSmall })}>
          <img src={videoThumbnail} alt={course.name} />
        </figure>
        <div className={classnames(classes.contentWrapper, { [classes.contentWrapperSmall]: isSmall })}>
          <div className={classnames(classes.content, { [classes.contentSmall]: isSmall })}>
            <span className={classes.title}>{course.name}</span>
            <div className={classes.infoWrapper}>
              <div className={classes.info}>
                <VideoLibraryIcon />
                <span>{`${course.totalVideos} ${t('courses.classes')}`}</span>
              </div>
              <div className={classes.info}>
                <ScheduleIcon />
                <span>
                  {formatSecondsToMinutes(course.duration)}
                  {' '}
                  {t('courses.minutes')}
                </span>
              </div>
            </div>
            {(firstItem && !isMobile) && (
              <span className={classes.description}>{course.description}</span>
            )}
            {courseWatched && <span className={classes.label}>{t('courses.watched')}</span>}
          </div>
          {haveVideos && (
            <div className={classnames(classes.linkWrapper, { [classes.linkWrapperSmall]: isSmall })}>
              <div
                role="presentation"
                className={classes.link}
              >
                {t('courses.seeClasses')}
                <ChevronRightIcon />
              </div>
            </div>
          )}
        </div>
      </article>
    </Card>
  );
};

CourseItem.propTypes = {
  course: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  animationId: PropTypes.string.isRequired,
  userProgress: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(CourseItem));
