import React from 'react';
import * as Styles from './DnsWizardHostingPlatformsGrid.styles';
import { Card } from '@/pages/common/v1/Card';
import { DnsWizardHostingPlatformCard } from '@/components/domain/components/DnsWizardHostingPlatformCard';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import otherPlatform from '@/media/domains/other-card.png';
import reservedPlatform from '@/media/domains/reserved.svg';

const DnsWizardHostingPlatformsGrid = ({
  testId = 'hostingcompaniesgrid',
  hasAvailableHostGatorPlan,
  hostingPlatforms = [],
  handleOpen = () => {},
  maxGridColumns = 5,
  selected,
  cardContentText,
  selectable,
  hgSelectGa,
  customSelectGa,
  selectedPlatform,
  dnsWizardExternalEditPlatformCardClick,
  reservedCardText,
  handleClickReserved,
  isPointedToReservedServer,
  handleSelectCard,
}) => {
  const handleSelectPlatform = (platform, platformName) => {
    handleSelectCard && handleSelectCard({
      platform,
      platformName,
    });

    switch (platform) {
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        hgSelectGa();
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM:
        customSelectGa();
        break;
      case HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA:
      case HOSTING_PLATFORM_TYPES.TIENDANUBE:
      case HOSTING_PLATFORM_TYPES.NUVEMSHOP:
      case HOSTING_PLATFORM_TYPES.SHOPIFY:
      case HOSTING_PLATFORM_TYPES.DLOJA:
      case HOSTING_PLATFORM_TYPES.EXTERNAL:
        dnsWizardExternalEditPlatformCardClick(platformName);
        break;
      default:
        break;
    }
  };

  const handlePointToReserved = () => {
    handleClickReserved && handleClickReserved(HOSTING_PLATFORM_TYPES.RESERVED);
  };

  const filteredPlatforms = hostingPlatforms.filter(
    platform => hasAvailableHostGatorPlan
      || platform.platformType !== HOSTING_PLATFORM_TYPES.HOSTGATOR,
  );

  return (
    <Styles.HostingPlatformsGridWrapper data-testid={testId} maxGridColumns={maxGridColumns}>
      {filteredPlatforms.map((platform, index) => (
        <Card
          unspaced
          variant="rounded"
          selected={selected && selectedPlatform === platform.platformName}
          hover
          key={`platformCard${index + 1}`}
        >
          <DnsWizardHostingPlatformCard
            selectedPlan={selectedPlatform}
            handleSelected={selectable && handleSelectPlatform}
            featured={platform.featured}
            imageUrl={platform.imageUrl}
            platformType={platform.platformType}
            platformName={platform.platformName}
            handleOnClick={selectable ? handleSelectPlatform : handleOpen}
            contentText={null}
          />
        </Card>
      ))}

      <Card
        unspaced
        variant="rounded"
        selected={
          selected && selectedPlatform === HOSTING_PLATFORM_TYPES.CUSTOM
        }
        hover
      >
        <DnsWizardHostingPlatformCard
          platformType={HOSTING_PLATFORM_TYPES.CUSTOM}
          platformName={HOSTING_PLATFORM_TYPES.CUSTOM}
          handleOnClick={selectable ? handleSelectPlatform : handleOpen}
          imageUrl={otherPlatform}
          contentText={cardContentText}
          handleSelected={() => selectable && handleSelectPlatform(HOSTING_PLATFORM_TYPES.CUSTOM)}
          selectedPlan={selectedPlatform}
        />
      </Card>

      {!isPointedToReservedServer && (
        <Card
          unspaced
          variant="rounded"
          selected={
            selected && selectedPlatform === HOSTING_PLATFORM_TYPES.RESERVED
          }
          hover
        >
          <DnsWizardHostingPlatformCard
            platformType={HOSTING_PLATFORM_TYPES.RESERVED}
            platformName={HOSTING_PLATFORM_TYPES.RESERVED}
            handleOnClick={selectable ? handleSelectPlatform : handlePointToReserved}
            imageUrl={reservedPlatform}
            contentText={reservedCardText}
            handleSelected={() => selectable && handleSelectPlatform(HOSTING_PLATFORM_TYPES.RESERVED)}
          />
        </Card>
      )}
    </Styles.HostingPlatformsGridWrapper>
  );
};

export default DnsWizardHostingPlatformsGrid;
