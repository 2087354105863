const styles = theme => ({
  textField: {
    fontSize: '14px',
    width: '100%',

    '& > div': {
      padding: 8,
    },


    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 1,
      fontSize: 14,
    },
    '& input': {
      padding: 8,
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      '& fieldset': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 12px 0px 0px',
    },
  },
  TxtBlockWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    ' & > div:first-child': {
      marginBottom: 15,
    },
  },
});

export default styles;
