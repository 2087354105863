import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class NewSiteManual extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 322.5 270.1">
        <path
          className={classes.st0}
          d="M303.6,270H18.9C8.5,270,0,261.6,0,251.1V19C0,8.5,8.5,0,19,0h97.1c4.2,0,8.2,1.8,10.9,5l20.9,24.6
          c2.7,3.2,6.7,5,10.9,5h144.7c10.5,0,19,8.5,19,19V251C322.5,261.5,314,270,303.6,270z"
        />
        <path
          className={classes.st1}
          d="M303.5,34.7h-35.2c0,103-83.5,186.5-186.5,186.5H0v30c0,10.4,8.4,18.9,18.9,18.9h284.7
          c10.4,0,18.9-8.4,18.9-18.9V53.7C322.5,43.2,314,34.7,303.5,34.7z"
        />
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(NewSiteManual);
