const styles = theme => ({
  main: {
    backgroundColor: theme.color.mystic,
    position: 'relative',
    padding: '46px 176px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '46px 0px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '46px 80px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '46px 176px',
    },
  },
});

export default styles;
