import styled, { css } from 'styled-components';

export const Content = styled.section`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 14px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 2px;

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme, alignStart, showSelect }) => css`
    align-items: ${alignStart ? 'flex-start' : 'center'};
    display: flex;
    gap: 10px;
    margin-top: 24px;
    margin-bottom:${showSelect ? '74px' : '0px'};

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;


    strong {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Actions = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `}
`;

export const IconAdapter = styled.div`
  ${() => css`
    margin-top: -25px;
  `}
`;

export const ButtonWrapper = styled.div`
  ${() => css`
    margin-top: 7px;
  `}
`;

export const Select = styled.div`
  ${({ theme }) => css`
    margin-left: 164px;
    margin-top: -50px;
    position: fixed;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 0;
      margin-top: -34px;
      position: relative;
    }
  `}
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: flex;
      justify-content: center;
    }
  `}
`;
