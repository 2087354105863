const styles = theme => ({
  titleElement: {
    paddingBottom: 6,
    paddingTop: 0,
    padding: 0,
    margin: '0 0 0 42px',

    '& h6': {
      color: theme.color.thundora,
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: '0.01px',
      lineHeight: '29px',
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: 26,
      paddingBottom: 11,
    },
  },

  closeButton: {
    margin: '15px 17px 0px auto',
    maxHeight: 24,
    maxWidth: 24,
  },

  containerWrapper: {
    flex: 'unset',
    margin: '0 20px 0px 42px',
    overflowY: 'unset',
    padding: 0,

    [theme.breakpoints.down(960)]: {
      margin: '0 20px 24px 26px',
    },
  },

  description: {
    color: theme.color.tundora,
    fontSize: 14,
    letterSpacing: '0.01px',
    lineHeight: '17px',

    '& b': {
      fontWeight: 500,
    },

    '& u, & a': {
      color: theme.color.indigo,
    },
  },

  listWrapper: {
    margin: '24px 17px 42px 42px',
    maxHeight: '320px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.color.silver,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: theme.color.indigo,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.indigo,
    },

    [theme.breakpoints.down(960)]: {
      margin: 0,
      maxHeight: 'unset',
      overflow: 'unset',
      padding: 0,
    },
  },
});

export default styles;
