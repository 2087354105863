const styles = theme => ({
  checklistTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.color.regentGray,
    fontSize: '14px',
  },
  lockIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  checklist: {
    listStyle: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  checkListItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    marginTop: '5px',
    color: theme.color.tundora,
  },
  checkListItemError: {
    color: theme.color.valencia,
  },
  iconWrapper: {
    width: '14px',
    height: '14px',
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkIcon: {
    fontSize: '14px',
  },
  lensIcon: {
    fontSize: '6px',
  },
  generatorText: {
    margin: '25px 0',
    color: theme.color.regentGray,
    fontSize: '12px',
  },
  generatorLink: {
    color: theme.color.indigo,
  },
});

export default styles;
