import styled, { css } from 'styled-components';

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0px;
    line-height: ${theme.font.lineHeights.xxxxxxl};
    margin: 32px 0px 24px 32px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 32px 0px 40px 24px;
    }
  `}
`;

export const DomainName = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.blue.dark};
    font-weight: ${theme.font.weights.medium};
  `}
`;

export const Action = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `}
`;

export const ContentWrapper = styled.div`
${({ theme }) => css`
  display:flex;
  margin: 0px 31px;
  @media (max-width: ${theme.breakpoints.sm}) {
    align-items:center;
    flex-direction:column;
    margin: 0px 24px;
    svg{
      height:45px;
      margin-bottom:40px;
      width: 45px;
    }
  }
  `}
`;

export const Content = styled.p`
${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.sm};
  letter-spacing: 0px;
  line-height: ${theme.font.lineHeights.lg};
  margin: 0px 0px 40px 16px;
  a{
    color: ${theme.palette.secondary.primary};
    display:inline;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 0px 0px 40px 0px;
  }
`}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background-color: unset;
    border: unset;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;

    svg {
      fill: ${theme.palette.grey.bluish};
      transition: fill 200ms ease-in-out;
      &:hover{
        fill: ${theme.palette.secondary.primary};
      }
    }
  `}
`;
