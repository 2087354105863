import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 36px;
`;

export const Label = styled.strong`
  ${({ theme }) => css`
    font-weight: ${theme.v2.font.weights.medium};
    font-size: ${theme.v2.font.sizes.xs};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.v2.font.weights.regular};
    font-size: ${theme.v2.font.sizes.xxs};
    margin-top: 5px;
    max-width: 877px;
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 360px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 13px;
  margin-bottom: 15px;
`;

export const AlertWrapper = styled.div`
  margin-left: -16px;
  max-width: 750px;
`;

export const Line = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin-bottom: 30px;
    margin-top: 14px;
  `}
`;

export const SkeletonRow = styled.div`
    ${({ top, row, maxWidth }) => css`
      display: flex;
      flex-direction: ${row ? 'row' : 'column'};
      gap: ${row ? '16px' : '8px'};
      margin: ${top} 0px 0px 0px;
      max-width: ${maxWidth || 'unset'};
    `}
`;
