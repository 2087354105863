export default {
  billing: '/faturas',
  byHost: '/by-host',
  cancelled: '/canceladas',
  cancelRenewal: '/cancelar-renovacao',
  cancelSubscription: '/cancelar-assinatura',
  changePassword: '/alterar-senha',
  accountSecurity: '/seguranca-da-conta',
  checkDomainAvailability: '/check-domain-availability',
  codeguard: '/codeguard',
  courses: '/cursos',
  coursesCta: '/cursos-cta',
  createEmail: '/criar-conta-de-e-mail',
  createFirstEmailsTitan: '/criar-primeira-conta-e-mail',
  creditcard: '/gerenciar-cartao-credito',
  dns: '/alterar-dns',
  domainConfig: '/configurar-dominio',
  domaindetail: '/gerenciar-dominio',
  domainRegister: '/registro-de-dominio',
  domains: '/dominios',
  emails: '/e-mails',
  emailsList: '/emails-list',
  emailsTitan: '/gerenciar',
  emailVerify: '/validar-email',
  feedbackSite: '/feedback-site',
  filteredEmails: '/filtro-e-mails',
  goalsObjectives: '/metas-e-objetivos',
  help: '/suporte',
  helpRedirect: '/ajuda',
  home: '/',
  homeRedirect: '/inicio',
  hosting: '/hospedagem-de-sites',
  invoices: '/faturas',
  manageSubscription: '/gerenciar-assinatura',
  mktPlace: '/potencialize-seu-negocio',
  myData: '/meus-dados',
  newSite: '/criar-site',
  onboarding: '/onboarding',
  paid: '/pagas',
  persona: '/nivel-de-conhecimento',
  phoneVerify: '/validar-celular',
  productdetail: '/gerenciar-produto',
  products: '/produtos',
  retentionSubscriptionConfirm: '/confirmar-cancelamento',
  retentionSubscriptionOffer: '/ofertar-beneficios',
  retentionSubscriptionOfferAfterQuestions: '/ofertar-vantagens',
  retentionSubscription: '/assinatura',
  retentionRenew: '/renovacao',
  retentionBenefit: '/beneficio',
  security: '/seguranca',
  siteBuilder: 'criador-de-sites',
  siteBuilderRedirectAccess: '/sso-site-builder',
  sitelock: '/sitelock',
  sites: '/meus-sites',
  sitesCreate: '/criar-site',
  sitesPage: '/sites',
  sitesPlans: '/sites-e-planos',
  subscriptions: '/assinaturas',
  supportforms: '/abrir-chamado',
  createTicket: '/criar-ticket',
  ticket: '/suporte/chamado',
  titanHire: '/titan-hire-plan',
  titanHireFreePlan: '/titan-hire-free-plan',
  titanHirePlan: '/titan-hire-free-plan',
  titanHirePlanActiveCPanel: '/titan-hire-plan-active-cpanel',
  titanInboxUpgrade: '/titan-inbox-upgrade',
  titanUpgrade: '/titan-upgrade',
  unpaid: '/pendentes',
  upgrade: '/upgrade',
  whmRedirectAccess: '/sso-whm-access',
  coursesTrial: '/cursos-trial',
  dnsWizard: '/dns-wizard',
  asaas: '/asaas',
  backup: '/backup',
  linkNaBio: '/link-na-bio',
  wizard: 'wizard',
  dataAnalysis: 'analise-de-dados',
  cloudflare: '/cloudflare',
};
