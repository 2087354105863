import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';


function TicketStatusLabel({
  classes,
  ticketStatus,
  children,
  className,
}) {
  return (
    <span
      className={`${classes.content} ${classes[ticketStatus]} ${className}`}
    >
      {children}
    </span>
  );
}

export default withStyles(styles, { withTheme: true })(TicketStatusLabel);
