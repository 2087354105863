import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  withStyles, CardContent, Card,
  Typography, TextField, CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import * as yup from 'yup';
import {
  RECEIVE_RESEND_EMAIL, ERROR_RESEND_EMAIL,
  RECEIVE_CHANGE_EMAIL, ERROR_CHANGE_EMAIL,
} from '@/redux/actions/actionsTypes';
import Logo from '@/media/logo/LogoMarca';
import Countdown from '@/components/Labels/Countdown';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import LinkButton from '@/components/Buttons/LinkButton';
import { resendEmailVerify, changeEmailVerify, emailChangeFlow } from '@/redux/actions/emailVerify';
import { emailFlowSkip } from '@/redux/actions/summary';
import { emailVerifySkipFlowTagManager, changeEmailTagManager, emailVerifyResendLinkTagManager } from '@/utils/ThirdParties/tagManager';
import isValidJsonString from '@/utils/Validators/isValidJsonString';
import styles from './styles';
import {
  BRAVO_TIMER_VALIDATE_EMAIL, USER_DATA_CHANGE_EMAIL_FLOW, BRAVO_MAIL_TIMER_DISABLE_ON_SEND,
  XRAY_ENABLED_VALIDATION_FILTER_WITH_PAGE_NAME,
} from '@/config/GrowthBook/constants';
import { useHistory, useLocation } from 'react-router';
import { queryParams } from '@/utils/Application/queryParams';
import { useParams } from 'react-router-dom';
import PasswordField from '@/components/Fields/PasswordField';
import { locale } from '@/utils/locale';
import { commonActions } from '@/redux/modules/common';
import {
  addSeconds, differenceInSeconds, format,
} from 'date-fns';
import { Timer } from '../../common/v1';
import { COUNTRY } from '@/config';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { emailErrors } from './EmailVerify.types';

const EmailVerifyV1 = ({ classes }) => {
  const history = useHistory();
  const forceUrl = useSelector(state => state.emailVerify.forceUrl);
  const emailTimer = useFeatureIsOn(BRAVO_TIMER_VALIDATE_EMAIL);
  const checkEmailTimer = useFeatureIsOn(BRAVO_MAIL_TIMER_DISABLE_ON_SEND);

  let emailValidationRequested = localStorage.getItem('emailValidationRequested');
  let emailValidationRequestedDiff = 0;

  if (emailValidationRequested) {
    emailValidationRequestedDiff = differenceInSeconds(new Date(emailValidationRequested), new Date());
  }
  if (emailValidationRequestedDiff <= 0) {
    emailValidationRequested = null;
    localStorage.removeItem('emailValidationRequested');
  }

  const [countDownResend, setCountDownResend] = useState(emailValidationRequested
    ? emailValidationRequestedDiff
    : 0);

  const newEmailFlow = useFeatureIsOn(USER_DATA_CHANGE_EMAIL_FLOW);

  const [showEmailChangeForm, setShowEmailChangeForm] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const countdownTime = 120;
  const loadingChange = useSelector(state => state.emailVerify.loadingChange);
  const loadingResend = useSelector(state => state.emailVerify.loadingResend);

  const sendCodeLabel = loadingResend ? locale('emailVerify.buttons.sendLoading') : locale('emailVerify.buttons.send');

  const featureToggleEmailNumberFlowSkip = useSelector(state => state.featureToggles.toggles.portal.emailFlowSkip);
  const clientInfo = useSelector(state => state.summary);
  const userId = useSelector(state => state.summary.id);
  const dispatch = useDispatch();
  const isEnabledNewFilter = useFeatureIsOn(XRAY_ENABLED_VALIDATION_FILTER_WITH_PAGE_NAME);

  const lastPath = localStorage.getItem('lastPath');
  const lastPaths = isValidJsonString(lastPath) ? JSON.parse(lastPath) : [];
  const location = useLocation();
  const params = useParams();
  const { changeFlow } = params;

  const executeFilterPath = () => {
    if (isEnabledNewFilter) {
      const selectedLocation = queryParams(location.search, 'location');

      if (selectedLocation != null && selectedLocation !== '') {
        return { id: userId, location: selectedLocation };
      }
    }

    return lastPaths ? lastPaths.filter(item => item.id === userId)[0] : [];
  };

  useEffect(() => {
    if (changeFlow === 'alterar') {
      setShowEmailChangeForm(true);
    }
  }, [changeFlow]);

  const pathFilter = executeFilterPath();
  const isValidPath = pathFilter && pathFilter.location && pathFilter.id && pathFilter.id === userId;
  const closePath = isValidPath ? pathFilter.location : locale('routes.sitesPage');

  const [values, setValues] = useState({
    emailInput: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });

  const optionsValidate = {
    abortEarly: false,
  };

  const schemaEmail = newEmailFlow
    ? yup.object().shape({
      emailInput: yup
        .string()
        .required('required')
        .email(),
      password: yup
        .string()
        .min(6)
        .required('required'),
    })
    : yup.object().shape({
      emailInput: yup
        .string()
        .required('required')
        .email(),
    });

  const updateField = (event) => {
    const inputValue = event.target.name === 'emailInput' ? event.target.value.toLowerCase() : event.target.value;

    setValues({
      ...values,
      [event.target.name]: {
        ...values[event.target.name],
        value: inputValue,
        error: '',
      },
    });
  };

  const handleResendEmail = () => {
    if (countDownResend <= 0) {
      dispatch(resendEmailVerify({
        clientId: clientInfo.id,
        clientEmail: clientInfo.email,
      })).then((response) => {
        let hasDisabledTil = false;
        switch (response.type) {
          case RECEIVE_RESEND_EMAIL:
            if (response.client.data && response.client.data.pending_attempts >= 0) {
              const attempts = response.client.data.pending_attempts;
              dispatch(commonActions.notifications.set({
                label: (
                  <p>
                    {locale('emailVerify.feedback.emailSendAttempts')({ attempts, used: COUNTRY === 'br' ? 10 - attempts : 5 - attempts })}
                  </p>
                ),
                type: 'success',
              }));
            } else {
              dispatch(commonActions.notifications.set({
                label: locale('emailVerify.feedback.resendSuccess'),
                type: 'success',
              }));
            }
            emailVerifyResendLinkTagManager();
            break;
          case ERROR_RESEND_EMAIL:
            if (response.client.data && response.client.data.unblock_time) {
              const unblock = new Date(response.client.data.unblock_time);
              dispatch(commonActions.notifications.set({
                label: (
                  <p>
                    {locale('emailVerify.feedback.noMoreAttempts')({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                  </p>
                ),
                type: 'error',
              }));
            } else if (response && response.client && response.client.data && response.client.data && response.client.data.disabled_til && checkEmailTimer) {
              hasDisabledTil = true;
              const pendingAmount = response.client.data.disabled_til;
              const tokenDiff = differenceInSeconds(new Date(pendingAmount), new Date());
              setCountDownResend(tokenDiff);
              localStorage.setItem('emailValidationRequested', new Date(pendingAmount));
            } else {
              dispatch(commonActions.notifications.set({
                label: locale('emailVerify.feedback.resendError'),
                type: 'error',
              }));
            }
            break;
          default: break;
        }

        if (emailTimer && !hasDisabledTil) {
          setCountDownResend(120);
          localStorage.setItem('emailValidationRequested', addSeconds(new Date(), 120));
        } else if (!checkEmailTimer) {
          setShowCountdown(true);
        }
      });
    }
  };

  const checkChangeEmailValidations = (clientNewEmail) => {
    schemaEmail.validate({ emailInput: clientNewEmail, password: values.password.value }, optionsValidate).then(() => {
      dispatch(changeEmailVerify({
        clientId: clientInfo.id,
        clientEmailCurrent: clientInfo.email,
        clientNewEmail,
        password: values.password.value,
      })).then((response) => {
        let isPasswordError = false;
        let isSameMail = false;
        let hasUnblockTime = false;
        let unblock = null;
        let attempts = false;
        let hasPendingAttempts = false;

        switch (response.type) {
          case RECEIVE_CHANGE_EMAIL:
            if (response && response.email && response.email.data && response.email.data.pending_attempts !== null && emailTimer) {
              attempts = response.email.data.pending_attempts;
              hasPendingAttempts = true;
              dispatch(commonActions.notifications.set({
                label: (
                  <p>
                    {locale('emailVerify.feedback.emailSendAttempts')({ attempts, used: COUNTRY === 'br' ? 10 - attempts : 5 - attempts })}
                  </p>
                ),
                type: 'success',
              }));
            }

            if (!hasPendingAttempts) {
              dispatch(commonActions.notifications.set({
                label: (
                  `${locale('emailVerify.feedback.changeSuccess')} `
                ),
                type: 'success',
              }));
            }
            setShowEmailChangeForm(false);
            changeEmailTagManager();
            break;
          case ERROR_CHANGE_EMAIL:
            if (response && response.info && response.info.response && response.info.response.data.internal_code === emailErrors.DATA_IN_USE) {
              isSameMail = true;
              dispatch(commonActions.notifications.set({
                label: (
                  `${locale('emailVerify.feedback.dataInUse')} `
                ),
                type: 'error',
              }));
            }

            if (response && response.info && response.info.response.data.data === 'Password dont match') {
              isPasswordError = true;
              dispatch(commonActions.notifications.set({
                label: (
                  `${locale('emailVerify.feedback.password')} `
                ),
                type: 'error',
              }));
            }

            if (response && response.info && response.info.response.data.message === 'Email already in use. ') {
              isPasswordError = true;
              dispatch(commonActions.notifications.set({
                label: (
                  `${locale('emailVerify.feedback.emailInDataBase')} `
                ),
                type: 'error',
              }));
            }

            if (!isSameMail && response && response.info && response.info.response && response.info.response.data.data && response.info.response.data.data.unblock_time && emailTimer) {
              hasUnblockTime = true;
              unblock = new Date(response.info.response.data.data.unblock_time);


              if (response.info.response.data.internal_code === emailErrors.ALREADY_VALIDATED) {
                dispatch(commonActions.notifications.set({
                  label: (
                    <p>
                      {locale('emailVerify.feedback.alreadyValidated')({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                    </p>
                  ),
                  type: 'error',
                }));
              } else {
                dispatch(commonActions.notifications.set({
                  label: (
                    <p>
                      {locale('emailVerify.feedback.noMoreAttempts')({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                    </p>
                  ),
                  type: 'error',
                }));
              }
            }

            if (!isSameMail && !isPasswordError && !hasUnblockTime) {
              dispatch(commonActions.notifications.set({
                label: (
                  `${locale('emailVerify.feedback.changeError')} `
                ),
                type: 'error',
              }));
            }
            break;
          default: break;
        }

        if (emailTimer && !isPasswordError && !isSameMail) {
          setCountDownResend(120);
          localStorage.setItem('emailValidationRequested', addSeconds(new Date(), 120));
        } else {
          setShowCountdown(true);
        }
      });
    }).catch((validationError) => {
      validationError && validationError.inner.forEach((error) => {
        let errorMessage;
        switch (error.type) {
          case 'required':
            errorMessage = locale('emailVerify.errors.requiredEmail');
            break;
          case 'min':
            errorMessage = locale('emailVerify.errors.passwordMinimumCharacters');
            break;
          default:
            errorMessage = locale('emailVerify.errors.invalidEmail');
            break;
        }
        setValues(prevValues => ({
          ...prevValues,
          [error.path]: {
            ...prevValues[error.path],
            error: errorMessage,
          },
        }));
      });
    });
  };

  const handleChangeEmail = (event) => {
    event.preventDefault();
    const clientNewEmail = event.target.emailInput.value;
    checkChangeEmailValidations(clientNewEmail);
  };

  const handleShowCountdown = (show) => {
    setShowCountdown(show);
  };

  const closeEmailFlow = () => {
    dispatch(emailFlowSkip(clientInfo.id));
    dispatch(emailChangeFlow(false));
    emailVerifySkipFlowTagManager();
  };

  return (
    <div className={classes.main} data-id="emailVerify" data-testid="EmailVerifyV1">
      <Card className={classes.card} id="cardHandleScroll">
        <CardContent className={classes.content}>
          <Logo />

          {!showEmailChangeForm
            && (
              <>
                <div className={classes.titleWrapper}>
                  <div className={classes.selectDomainWrapper}>
                    <h1 className={classes.title}>{locale('emailVerify.title')}</h1>
                    <Typography className={classes.selectDomainTitle}>
                      <Trans i18nKey="emailVerify.subTitleWithEmail">
                        <>
                          0
                        </>
                        <strong>{{ email: clientInfo.email }}</strong>
                        <>
                          2
                        </>
                      </Trans>
                    </Typography>
                  </div>
                </div>

                <div className={classes.contentWrapper}>
                  <div className={classes.emailVerifyContainer}>
                    <div className={classes.wrapperBoxLinks}>

                      {(countDownResend > 0 || showCountdown) && (
                        <>
                          <Typography>{locale('emailVerify.emailNotRecived')}</Typography>
                          <Typography className={classes.countdownText}>
                            {locale('emailVerify.resendText')}
                            <span className={classes.countdown}>
                              {emailTimer ? (
                                <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
                              ) : (
                                <Countdown countdownTime={countdownTime} handleShowCountdown={handleShowCountdown} />
                              )}
                            </span>
                          </Typography>
                        </>
                      )}

                      {countDownResend <= 0 && !showCountdown && (!showEmailChangeForm) && (
                        <>
                          <Typography>{locale('emailVerify.emailNotRecived')}</Typography>
                          <OutlineButton
                            className={classes.button}
                            onClick={() => setShowEmailChangeForm(true)}
                            disabled={loadingResend}
                          >
                            {locale('emailVerify.buttons.changeEmail')}
                          </OutlineButton>
                          <PrimaryButton
                            className={classes.button}
                            onClick={handleResendEmail}
                            disabled={loadingResend || countDownResend > 0}
                          >

                            {(countDownResend > 0 && emailTimer)
                              && <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
                            }

                            {(showCountdown && !emailTimer) && (
                              <Countdown countdownTime={countdownTime} handleShowCountdown={handleShowCountdown} />
                            )}

                            {loadingResend && <CircularProgress className={classes.loading} size={20} />}
                            {countDownResend <= 0 && sendCodeLabel}
                          </PrimaryButton>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </>
            )
          }

          {showEmailChangeForm && (
            <>
              <div className={classes.titleWrapper}>
                <div className={classes.selectDomainWrapper}>
                  <h1 className={classes.title}>{locale('emailVerify.changeEmail.title')}</h1>
                  <Typography className={classes.selectDomainTitle}>{locale('emailVerify.changeEmail.subtitle')}</Typography>
                </div>
              </div>

              <div className={classes.contentWrapper}>
                <form onSubmit={handleChangeEmail}>
                  <TextField
                    id="emailInput"
                    className={classes.formInput}
                    name="emailInput"
                    value={values.emailInput.value}
                    placeholder={locale('emailVerify.changeEmail.placeholder')}
                    onChange={updateField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={!!values.emailInput.error}
                    helperText={values.emailInput.error}
                  />

                  {newEmailFlow && (
                    <>
                      <Typography className={classes.passwordInputDescriptionD}>
                        {locale('emailVerify.passwordDescription')}
                      </Typography>

                      <div className={classes.passwordWrapper}>
                        <PasswordField
                          name="password"
                          autoComplete="off"
                          variant="outlined"
                          margin="normal"
                          type="password"
                          label={locale('phoneVerify.changePhoneNumber.passwordLabel')}
                          value={values.password.value}
                          onChange={updateField}
                          onClickDisplayKey={() => null}
                          className={classes.formInput}
                          error={!!values.password.error}
                          helperText={values.password.error}
                        />
                      </div>
                    </>
                  )}

                  <div className={classes.wrapperBoxLinks}>
                    <OutlineButton
                      className={classes.button}
                      onClick={() => {
                        if (history.length) {
                          dispatch(emailChangeFlow(false, forceUrl));
                          history.push(forceUrl);
                        }

                        setShowEmailChangeForm(false);
                      }}
                    >
                      {locale('emailVerify.buttons.cancel')}
                    </OutlineButton>
                    <PrimaryButton
                      className={classes.button}
                      type="submit"
                      disabled={loadingChange || countDownResend > 0}
                    >
                      {countDownResend > 0 && (
                        <>
                          <span className={classes.countdown}>
                            <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
                          </span>
                        </>
                      )}

                      {loadingChange && (<CircularProgress color="inherit" size={24} />)}
                      {(countDownResend <= 0) && locale('emailVerify.buttons.change')}
                    </PrimaryButton>
                  </div>
                </form>

                <Typography className={classes.formText}>
                  {locale('emailVerify.changeEmail.hint')}
                </Typography>
              </div>
            </>
          )}

          {featureToggleEmailNumberFlowSkip && (
            <LinkButton
              data-id="close-emailverify"
              to={closePath}
              className={classes.link}
              onClick={() => closeEmailFlow()}
            >
              <Close />
            </LinkButton>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withI18n()(withStyles(styles)(EmailVerifyV1));
