import React, { useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  SUBSCRIPTIONS_CATEGORY,
  SUBSCRIPTIONS_SUBSTATUS,
} from '@/enums/billing/billing.enum';
import { Tag } from '@/pages/common/Tag';
import {
  IconAcademy,
  IconEmail,
  IconFolder,
  IconLock,
  IconServer,
} from '@/icons';
import { Message } from '@/pages/common/Message';
import {
  Alert,
  Button,
  IconCourses,
  IconDomain,
  IconEmail as IconEmailV2,
  IconFile,
  Stepper,
  Tag as TagV2,
  IconSecurity,
} from 'gatorcomponents';

import * as Styles from './SubscriptionItem.styles';
import { isBRServer } from '@/utils/Validators/validation';
import { useHistory } from 'react-router';

const SubscriptionItem = ({
  activeStatusLabel = 'Active',
  buttonManageSubscriptionsLabel = 'Manage Subscription',
  cancelledStatusLabel = 'Cancelled',
  domainLabel = 'Domain',
  expiredStatusLabel = 'Expired',
  onMessageClick,
  onSubscriptionManageClick,
  overdueStatusLabel = 'Overdue',
  pendingAnalysisStatusLabel = 'Pending Analysis',
  pendingPaymentStatusLabel = 'Pending Payment',
  pendingStatusLabel = 'Pending',
  registeredStatusLabel = 'Registred',
  subscription,
  suspendedStatusLabel = 'Suspended',
  testId = 'subscription-item',
  toDueStatusLabel = 'To due',
  transferredAwayStatusLabel = 'Transferred',
  enabledNewLayoutSubscriptionsPage = false,
  orderStatusLocale,
  routesLocale,
  readMoreStepper,
  paymendMethodType,
  isCreditCard,
}) => {
  const history = useHistory();
  const isNewSuspendedMessage = subscription && subscription.status && subscription.status.toLowerCase() === 'suspended';
  const theme = useContext(ThemeContext);

  const {
    domain,
    id,
    productName,
    type,
    status,
    substatus,
    cancelType,
    category,
  } = subscription;

  const getIcon = (category) => {
    const iconsOldLayout = {
      [SUBSCRIPTIONS_CATEGORY.DOMAIN]: () => <IconServer />,
      [SUBSCRIPTIONS_CATEGORY.COURSE]: () => <IconAcademy />,
      [SUBSCRIPTIONS_CATEGORY.OTHERS]: () => <IconLock />,
      [SUBSCRIPTIONS_CATEGORY.SECURITY]: () => <IconLock />,
      [SUBSCRIPTIONS_CATEGORY.EMAIL]: () => <IconEmail />,
      [SUBSCRIPTIONS_CATEGORY.HOSTING]: () => <IconFolder />,
      default: () => <IconFolder />,
    };

    const iconsNewLayout = {
      [SUBSCRIPTIONS_CATEGORY.DOMAIN]: () => <IconDomain />,
      [SUBSCRIPTIONS_CATEGORY.COURSE]: () => <IconCourses />,
      [SUBSCRIPTIONS_CATEGORY.OTHERS]: () => <IconSecurity />,
      [SUBSCRIPTIONS_CATEGORY.SECURITY]: () => <IconSecurity />,
      [SUBSCRIPTIONS_CATEGORY.EMAIL]: () => <IconEmailV2 />,
      [SUBSCRIPTIONS_CATEGORY.HOSTING]: () => <IconFile />,
      default: () => <IconFile />,
    };

    const icons = enabledNewLayoutSubscriptionsPage ? iconsNewLayout : iconsOldLayout;

    return (icons[category] || icons.default)();
  };

  const handleOnClick = useCallback(() => {
    onSubscriptionManageClick && onSubscriptionManageClick({ id, type });
  }, [onSubscriptionManageClick, id, type]);

  const handleOnMessageClick = (event) => {
    event.preventDefault();
    onMessageClick && onMessageClick(event, subscription);
  };

  const getStatus = () => ((cancelType && substatus === SUBSCRIPTIONS_SUBSTATUS.TO_DUE)
    ? status.toLowerCase()
    : substatus);

  const getStatusLabel = () => {
    const labelsByStatus = {
      active: activeStatusLabel,
      cancelled: cancelledStatusLabel,
      expired: expiredStatusLabel,
      overdue: overdueStatusLabel,
      pending: pendingStatusLabel,
      pendingAnalysis: pendingAnalysisStatusLabel,
      pendingPayment: pendingPaymentStatusLabel,
      registered: registeredStatusLabel,
      suspended: suspendedStatusLabel,
      toDue: toDueStatusLabel,
      transferredAway: transferredAwayStatusLabel,
    };
    const labelSelected = labelsByStatus[getStatus()];

    return enabledNewLayoutSubscriptionsPage ? labelSelected.toUpperCase() : labelSelected;
  };

  const statusColors = {
    active: theme.palette.success.primary,
    cancelled: theme.palette.grey.primary,
    error: theme.palette.error.primary,
    expired: theme.palette.error.primary,
    overdue: theme.palette.error.primary,
    pendingAnalysis: theme.palette.danger.primary,
    pendingPayment: theme.palette.danger.primary,
    pending: theme.palette.danger.primary,
    registered: theme.palette.success.primary,
    suspended: theme.palette.error.primary,
    toDue: theme.palette.warning.primary,
    transferredAway: theme.palette.danger.primary,
  };

  const statusTagVariants = {
    active: 'active',
    cancelled: 'cancel',
    error: 'problem',
    expired: 'problem',
    overdue: 'problem',
    pendingAnalysis: 'pending',
    pendingPayment: 'pending',
    pending: 'pending',
    registered: 'active',
    suspended: 'problem',
    toDue: 'attention',
    transferredAway: 'pending',
  };

  const statusAlertVariants = {
    active: 'error',
    cancelled: '',
    error: 'error',
    expired: 'error',
    overdue: 'error',
    pendingAnalysis: 'warning',
    pendingPayment: 'warning',
    pending: 'warning',
    registered: 'error',
    suspended: 'error',
    toDue: 'warning',
    transferredAway: 'warning',
  };

  const title = type === 'domain' ? `${domainLabel} - ${domain}` : productName;

  const isNewLayoutSubscriptionsPage = () => (enabledNewLayoutSubscriptionsPage ? 'newLayout' : 'oldLayout');

  const { orderUnderReview } = subscription;

  const shouldRenderStepper = (substatus === 'pending' || substatus === 'cancelled') && isBRServer;

  const hasDate = date => date && date !== '0000-00-00 00:00:00';

  const getAnalysisStatus = () => {
    if (isCreditCard && hasDate(orderUnderReview.date_order) && orderUnderReview.status === 'pending_analysis') {
      return 'ongoing';
    }

    if (!isCreditCard && (!hasDate(orderUnderReview.date_paid) || !hasDate(orderUnderReview.date_reg_analysis))) {
      return 'pending';
    }

    if (orderUnderReview.status === 'cancelled') {
      return 'finished';
    }

    return 'ongoing';
  };

  const getAnalysisTooltip = () => {
    if (isCreditCard && hasDate(orderUnderReview.date_order) && orderUnderReview.status === 'pending_analysis') {
      return { content: orderStatusLocale.analysing.tooltip };
    }

    if (!isCreditCard && (!hasDate(orderUnderReview.date_paid) || !hasDate(orderUnderReview.date_reg_analysis))) {
      return null;
    }

    if (orderUnderReview.status === 'cancelled') {
      return null;
    }

    return { content: orderStatusLocale.analysing.tooltip };
  };

  const getPaidTooltip = () => {
    if (isCreditCard) {
      return { content: orderStatusLocale.paid.finished.tooltip.preAuth };
    }

    if (!isCreditCard && !hasDate(orderUnderReview.date_paid)) {
      return { content: orderStatusLocale.paid.ongoing.tooltip };
    }

    return undefined;
  };

  const getStepperProps = () => {
    const defaultProps = {
      direction: 'row',
      statusColor: substatus === 'pending' ? 'pendingMedium' : 'lowLight',
    };

    const itemsWithOrder = orderUnderReview && [
      {
        id: 0,
        status: 'finished',
        text: {
          title: orderStatusLocale.buy.title,
        },
      },
      {
        id: 1,
        status: (hasDate(orderUnderReview.date_paid) || isCreditCard) ? 'finished' : 'ongoing',
        text: {
          title: (hasDate(orderUnderReview.date_paid) || isCreditCard)
            ? orderStatusLocale.paid.finished.title[paymendMethodType]
            : orderStatusLocale.paid.ongoing.title,
        },
        tooltip: getPaidTooltip(),
      },
      {
        id: 2,
        status: getAnalysisStatus(),
        text: {
          title: orderStatusLocale.analysing[hasDate(orderUnderReview.date_update_analysis) ? 'cancelled' : 'pending'].title,
          lightTitle: orderStatusLocale.analysing[hasDate(orderUnderReview.date_update_analysis) ? 'cancelled' : 'pending'].lightTitle,
        },
        tooltip: getAnalysisTooltip(),
      },
      {
        id: 3,
        status: orderUnderReview.status === 'cancelled' ? 'error' : 'pending',
        text: {
          title: orderStatusLocale.finalStatus[hasDate(orderUnderReview.date_update_analysis) ? substatus : 'pending'].title,
          lightTitle: orderStatusLocale.finalStatus[hasDate(orderUnderReview.date_update_analysis) ? substatus : 'pending'].lightTitle,
        },
        link: substatus === 'cancelled' && {
          label: readMoreStepper,
          href: `${routesLocale.subscriptions}${routesLocale.manageSubscription}/${id}/${type}`,
          history,
          target: '_self',
        },
      },
    ];

    const itemsWithoutOrder = [
      {
        id: 0,
        status: 'finished',
        text: {
          title: orderStatusLocale.buy.title,
        },
      },
      {
        id: 1,
        status: 'ongoing',
        text: {
          title: orderStatusLocale.paid.ongoing.title,
        },
        tooltip: { content: orderStatusLocale.paid.ongoing.tooltip },
      },
      {
        id: 2,
        status: 'pending',
        text: {
          title: orderStatusLocale.analysing.pending.title,
          lightTitle: orderStatusLocale.analysing.pending.lightTitle,
        },
      },
      {
        id: 3,
        status: 'pending',
        text: {
          title: orderStatusLocale.finalStatus.pending.title,
          lightTitle: orderStatusLocale.finalStatus.pending.lightTitle,
        },
      },
    ];

    const items = orderUnderReview ? itemsWithOrder : itemsWithoutOrder;

    return {
      ...defaultProps,
      items,
    };
  };

  return (
    <Styles.Wrapper
      variant={isNewLayoutSubscriptionsPage()}
      borderLeftColor={statusColors[getStatus()]}
      data-testid={testId}
    >
      {!enabledNewLayoutSubscriptionsPage && (
        <>
          <Styles.SubscriptionInfo>
            <Styles.Icon
              color={statusColors[getStatus()]}
            >
              {getIcon(category)}
            </Styles.Icon>
            <Styles.SubscriptionInfoDetails>
              <Styles.ProductName title={title} data-testid={`${testId}-title`}>
                {title}
              </Styles.ProductName>
              {type !== 'domain' && (
                <Styles.Domain title={domain} data-testid={`${testId}-domain`}>
                  {domain}
                </Styles.Domain>
              )}
              <Styles.Status>
                <Tag
                  bgColor={statusColors[getStatus()]}
                  label={getStatusLabel()}
                  testId="tag-subscription-status"
                />
              </Styles.Status>
            </Styles.SubscriptionInfoDetails>
          </Styles.SubscriptionInfo>

          <Styles.MessageWrapper>
            {(subscription.message && subscription.message.text) && (
              isNewSuspendedMessage ? (
                <Alert
                  description={subscription.message.text}
                  variant="error"
                  centerIcon
                  withoutBackground
                />
              ) : (
                <Message
                  text={subscription.message.text}
                  borderColor={statusColors[subscription.message.status]}
                  iconColor={statusColors[subscription.message.status]}
                  onClick={handleOnMessageClick}
                />
              )
            )}
          </Styles.MessageWrapper>
          <Styles.SubscriptionManage>
            <Styles.Button onClick={handleOnClick} data-testid={`${testId}-button`}>
              {buttonManageSubscriptionsLabel}
            </Styles.Button>
          </Styles.SubscriptionManage>
        </>
      )
      }
      {
        enabledNewLayoutSubscriptionsPage && (
          <>
            <Styles.SubscriptionInfoV2>
              <Styles.IconV2>
                {getIcon(category)}
              </Styles.IconV2>
              <Styles.SubscriptionInfoDetailsV2>
                <Styles.ProductName title={title} data-testid={`${testId}-title`}>
                  {title}
                </Styles.ProductName>
                {type !== 'domain' && (
                  <Styles.Domain title={domain} data-testid={`${testId}-domain`}>
                    {domain}
                  </Styles.Domain>
                )}
              </Styles.SubscriptionInfoDetailsV2>
            </Styles.SubscriptionInfoV2>
            <Styles.StatusV2>
              <TagV2
                variant={statusTagVariants[getStatus()]}
                label={getStatusLabel()}
                testId="tag-subscription-status"
              />
            </Styles.StatusV2>
            <Styles.MessageWrapperV2>
              {(shouldRenderStepper && false) && (
                <Styles.StepperWrapper data-testid="StepperWrapper" id="StepperWrapper" cancelled={substatus === 'cancelled'}>
                  <Stepper {...getStepperProps()} />
                </Styles.StepperWrapper>
              )}
              {(subscription.message && subscription.message.text) && (
                <Alert
                  description={subscription.message.text}
                  variant={statusAlertVariants[getStatus()]}
                  centerIcon="true"
                  withoutBackground="true"
                  customIcon="IconDanger"
                />
              )}
            </Styles.MessageWrapperV2>
            <Styles.SubscriptionManageV2>
              <Button label={buttonManageSubscriptionsLabel} onClick={handleOnClick} testId={`${testId}-button`} variant="secondary" />
            </Styles.SubscriptionManageV2>
          </>
        )
      }
    </Styles.Wrapper>
  );
};

export default SubscriptionItem;
