import { REQUEST_SUBDOMAINS, RECEIVE_SUBDOMAINS } from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  byId: {},
  loadedDate: null,
};

export default function subdomains(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SUBDOMAINS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SUBDOMAINS:
      return {
        ...state,
        loading: false,
        loadedDate: action.loadedDate,
        id: action.id,
        byId: {
          ...action.subdomains,
        },
      };
    default:
      return state;
  }
}
