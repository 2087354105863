const styles = theme => ({
  card: {
    border: `1px solid ${theme.palette.grey.primary}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    padding: '24px',
  },

  flexRow: {
    flexDirection: 'row',
  },

  WrapperTitle: {
    color: theme.color.tundora,
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '22px',
    marginBottom: '24px',
  },

  diffAmountInfo: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    margin: '4px auto 0px',
    maxWidth: '312px',
    textAlign: 'center',
  },

  disabled: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
    opacity: '0.5',
  },

  titleRow: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  cardTitle: {
    color: theme.color.tundora,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '24px',
  },

  subTotal: {
    color: theme.color.oceanGreen,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '24px',

    '& small': {
      fontSize: '16px',
      fontWeight: 400,
      marginRight: '2px',
    },
  },

  paymentButton: {
    alignItems: 'center',
    display: 'flex',
    height: '37px',
    justifyContent: 'center',
    padding: '6px 0px',
    width: '140px',
  },

  descriptionItem: {
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '16px',
    marginBottom: '24px',
    marginRight: '48px',

    [theme.breakpoints.down(758)]: {
      flexGrow: 1,
      flexShrink: 0,
      width: 'calc(50% - 48px)',
    },

    [theme.breakpoints.down(502)]: {
      width: '100%',
    },
  },

  descriptionItemTitle: {
    color: theme.color.regentGray,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '16px',
  },

  descriptionItemValue: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '21px',
  },

  InvoiceTableTitleWrapper: {
    alignItems: 'center',
    backgroundColor: theme.color.geyser,
    display: 'flex',
    height: '41px',
    justifyContent: 'space-between',
    padding: '11px 9px 11px 8px',
  },

  InvoiceTableTitle: {
    color: theme.palette.grey.dark,
    fontSize: '14px',
    fontWeight: '700',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  InvoiceTableWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  InvoiceDescriptionWrapper: {
    alignItems: 'flex-start',
    border: `1px solid ${theme.color.geyser}`,
    borderTop: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    padding: '11px 9px 11px 8px',
    width: 'calc(100% - 95px)',
  },

  planDescription: {
    paddingBottom: '24px',
  },

  planName: {
    color: theme.color.regentGray,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  balance: {
    backgroundColor: theme.color.wildSand,
    fontWeight: 500,
  },

  InvoiceDataWrapper: {
    alignItems: 'flex-start',
    border: `1px solid ${theme.color.geyser}`,
    borderLeft: 0,
    borderTop: 0,
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
    maxWidth: '95px',
    padding: '16px 9px 0px 0px',
    width: '100%',
  },

  columnName: {
    alignItems: 'center',
    color: theme.palette.grey.dark,
    display: 'flex',
    fontSize: '14px',
    fontWeight: '700',
    letterSpacing: '0.01px',
    lineHeight: '19px',

    '& svg': {
      height: 14,
      marginLeft: 5,
      width: 14,
      fill: theme.color.indigo,
    },
  },

  firstColumnName: {
    textAlign: 'left',
  },

  columnValue: {
    color: theme.palette.grey.dark,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  marginBottom: {
    marginBottom: '20px',
  },
});

export default styles;
