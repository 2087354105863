import React from 'react';
import {
  withStyles, Tooltip, CircularProgress,
} from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { SITELOCK_SHIELD_IMAGE } from '@/config/urls/sitelock';
import styles from './styles';

const ShieldPreview = ({ classes, t }) => {
  const { domain } = useSelector(state => state.sitelock);

  const uri = `${SITELOCK_SHIELD_IMAGE}${domain}?${Date.now()}`;

  return (
    <div className={classes.previewBox}>
      {domain && uri ? <img id="sealimage" role="figure" data-testid="seal-image" className={classnames([classes.sealPreview])} src={uri} alt={t('sitelock.SealPreview')} /> : <CircularProgress /> }
      <Tooltip
        classes={{
          tooltip: classes.customTooltip,
          popper: classes.customPopper,
        }}
        title={t('sitelock.SealTooltip')}
        placement="right"
        data-testid="seal-tooltip"
      >
        <Info color="primary" fontSize="small" data-testid="seal-tooltip-icon" />
      </Tooltip>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(ShieldPreview));
