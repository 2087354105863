import React from 'react';
import { withI18n } from 'react-i18next';
import Trakto from '@/media/partners/traktoHG.svg';
import { bottomBannerPartnersClick } from '@/utils/ThirdParties/tagManager';
import * as Style from './BottomAdvertisement.styles';

const background = require('@/media/courses/certificate/background.png');

const BottomAdvertisement = ({
  buttonLabel = 'Get partnership',
  logo = Trakto,
  logoWidth = '289px',
  logoHeigth = '32px',
  description = 'The art editor that you need. Create mini-sites, social media, e-books, apresentations and more',
  partnerConfig = {
    image: background,
    imgMaxWidth: 450,
    imgPaddingTop: 0,
    imgPaddingRight: 0,
    imgPaddingBottom: 0,
    imgPaddingLeft: 0,
    imgMarginTop: 0,
    imgMarginRight: 0,
    imgMarginBottom: 0,
    imgMarginLeft: 0,
    bgColor: '#0096FB',
  },
  partner = 'trakto',
  advertisementLocal = '',
  visible = true,
  href = 'site.to.redirect.com',
  testId = 'bottom-advertisement-component',
}) => {
  const isBottomBannerEnabled = true;
  const checkValue = value => ((typeof value) === 'number' ? `${value}px` : value);
  const formatPartnerName = value => value.charAt(0).toUpperCase() + value.slice(1);

  const handleClick = () => {
    if (advertisementLocal && partner) {
      bottomBannerPartnersClick(`${formatPartnerName(partner)}_bottombanner_${advertisementLocal}`);
    }
    if (href && href !== '#') {
      return window.open(href, '_blank');
    }
  };

  return isBottomBannerEnabled && visible && partnerConfig ? (
    <Style.Wrapper data-testid={testId} alt={`${description} ${buttonLabel}`} bgColor={partnerConfig.bgColor} onClick={handleClick}>
      <Style.Logo src={logo} width={logoWidth} height={logoHeigth} />
      <Style.Description>
        {description}
      </Style.Description>
      <Style.Image
        src={checkValue(partnerConfig.image)}
        maxWidth={checkValue(partnerConfig.imgMaxWidth)}
        paddingTop={checkValue(partnerConfig.imgPaddingTop)}
        paddingRigth={checkValue(partnerConfig.imgPaddingRight)}
        paddingBottom={checkValue(partnerConfig.imgPaddingBottom)}
        paddingLeft={checkValue(partnerConfig.imgPaddingLeft)}
        marginTop={checkValue(partnerConfig.imgMarginTop)}
        marginRight={checkValue(partnerConfig.imgMarginRight)}
        marginBottom={checkValue(partnerConfig.imgMarginBottom)}
        marginLeft={checkValue(partnerConfig.imgMarginLeft)}
      />
      <Style.Button>
        {buttonLabel}
      </Style.Button>
    </Style.Wrapper>
  ) : <Style.EmptyDiv data-testid="disabled-bottom-banner-feature" />;
};
export default withI18n()(BottomAdvertisement);
