import React, {
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import * as Styles from './Tooltip.styles';
import TooltipCard from './TooltipCard/TooltipCard';

const Tooltip = ({
  children = <span>sample children</span>,
  content = 'tooltip sample content',
  testId = 'tooltip',
  preferredPosition = null,
  padding = 8,
  width = 'fit-content',
  visible = false,
}) => {
  const toolTipWrapper = useRef();
  const [hovered, setHovered] = useState(visible);

  const enter = () => setHovered(true);
  const leave = () => setHovered(false);

  useLayoutEffect(() => {
    const { current } = toolTipWrapper;

    if (current) {
      current.addEventListener('mouseenter', enter);
      current.addEventListener('mouseleave', leave);
      document.addEventListener('scroll', leave);
    }

    return () => {
      if (current) {
        current.removeEventListener('mouseenter', enter);
        current.removeEventListener('mouseleave', leave);
        document.removeEventListener('scroll', leave);
      }
    };
  }, [toolTipWrapper, hovered]);

  return (
    <Styles.Wrapper ref={toolTipWrapper} data-testid={testId}>
      {children}

      {hovered && (
        <TooltipCard
          toolTipWrapperCurrent={toolTipWrapper}
          content={content}
          preferredPosition={preferredPosition}
          padding={padding}
          width={width}
          testId={`${testId}-card`}
        />
      )}

    </Styles.Wrapper>
  );
};

export default Tooltip;
