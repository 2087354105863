import React from 'react';

export default {
  title: 'Datos personales y de contacto',
  address: 'Calle',
  privacy: 'Privacidad de los Datos',
  form: {
    addressLabel: 'Calle *',
    addressPlaceholder: 'Escriba aquí',
    birthCountryLabel: 'País de nascimento *',
    birthCountryPlaceholder: 'Selecione',
    birthDateLabel: 'Fecha de nacimiento *',
    birthDatePlaceholder: 'DD/MM/AAAA',
    cityLabel: 'Ciudad *',
    cityPlaceholder: 'Escriba aquí',
    companyNameLabel: 'Razón Social *',
    companyNameLabelPlaceholder: 'Escriba aquí',
    companyResponsible: 'Representante legal *',
    companyResponsiblePlaceholder: 'Escriba aquí',
    complementLabel: 'Complemento *',
    complementPlaceholder: 'Apt 00',
    countryLabel: 'País de residencia *',
    countryPlaceholder: 'Selecione',
    cpfCnpjLabel: '',
    documentLabel: '',
    documentPlaceholder: '',
    emailLabel: 'Correo Electrónico *',
    emailPlaceholder: 'Escriba aquí',
    foreignerLabel: '',
    isCompanyLabel: 'Soy Empresa',
    knowledgeLabel: 'Nivel de conocimiento',
    knowledgePlaceholder: 'Selecione',
    mailSubscribeLabel: 'Recibir notificaciones por e-mail',
    mailSubscribeTooltip: 'Con esta opción activada estarás autorizándonos a enviar mensajes promocionales y últimas actualizaciones de contenido de nuestras plataformas.',
    nameLabel: 'Nombre completo *',
    namePlaceholder: 'Escriba aquí',
    neighborhoodLabel: 'Colonia *',
    neighborhoodPlaceholder: 'Escriba aquí',
    numberAndComplementLabel: 'Número exterior/interior *',
    numberAndComplementPlaceholder: '00',
    phoneLabel: 'Celular *',
    phonePlaceholder: 'Escriba aquí',
    postalCodeHelp: '',
    postalCodeLabel: 'Código Postal *',
    postalCodePlaceholder: 'Escriba aquí',
    stateLabel: 'Estado *',
    statePlaceholder: 'Escriba aquí',
  },
  errors: {
    birthDate: 'Ingresa una fecha válida',
    postalCode: 'Código postal no válido',
    cnpj: '',
    cpf: '',
    email: 'Ingresa una dirección de correo electrónico válida',
    numberAndComplement: 'No se permiten caracteres especiales',
    name: 'El nombre debe tener 2 palabras con mínimo 2 letras y máximo 50',
    companyName: '',
    phone: 'Digite um telefone válido',
    required: 'Campo obligatorio',
    state: 'Estado no válido',
  },
  findMyCep: 'Buscar mi código postal',
  knowledgeLevels: {
    beginner: 'Principiante',
    intermediary: 'Intermedia',
    technical: 'Técnica',
  },
  cancel: 'Cancelar',
  save: 'Guardar alterações',
  snackBar: {
    updateSuccess: 'Tus datos fueron actualizados con suceso',
    updateError: 'Error al actualizar tus datos',
    emailAlreadyExists: 'Tu correo ya está registrado en nuestro sistema.',
  },

  changePassword: {
    title: 'Login Portal HostGator',
    modalTitle: 'Cambiar contraseña',
    contentDescription: () => (
      <>
        <div>Contraseña</div>
        <div>••••••••</div>
      </>
    ),
    SectionButtonLabel: 'Cambiar contraseña',
    currentPwLabel: 'Contraseña actual',
    currentPwPlaceholder: 'Introduzca tu contraseña actual',
    newPwLabel: 'Nueva contraseña',
    newPwPlaceholder: 'Introduzca la nueva contraseña',
    repeatNewPwLabel: 'Repita la nueva contraseña',
    repeatNewPwPlaceholder: 'Repita la nueva contraseña',

    errorCurrentEmpty: 'Escribe tu contraseña actual.',
    errorEmptyField: 'Completa este campo.',
    errorCurrentMinimumCharacters: 'La contraseña debe tener un mínimo de 6 caracteres.',
    errorMinimumCharacters: 'Debe tener mínino 8 caracteres.',
    errorMinimumRequirements: 'Tu contraseña no cumple con los requisitos mínimos de seguridad.',
    errorConfirmNew: 'Las contraseñas no coinciden.',
    notifierSuccess: 'Contraseña cambiada con éxito!',
    notifierError: 'No fue posible cambiar tu contraseña. Por favor entra en contacto con soporte.',

    requirementsList: {
      listTitle: 'Su nueva contraseña debe:',
      upperAndLowerItem: 'Incluir caracteres en mayúsculas y minúsculas',
      numberLabel: 'Incluir al menos 1 número',
      symbolLabel: 'Incluir al menos 1 símbolo',
      minCharLabel: 'Tener al menos 8 caracteres',
      sameOnBothLabel: 'Ser iguales en ambos campos',
      differentPasswords: 'La nueva contraseña no puede ser igual a la actual',
      matchConfirmation: 'Las contraseñas deben ser iguales.',
    },

    modalDescription: () => (
      <div>
        Solo se cambiará la
        {' '}
        <b>contraseña de inicio de sesión del Portal HostGator</b>
        {' '}
        con esta acción.
      </div>
    ),
    buttonLabel: 'Cambiar contraseña',
    secondaryButtonLabel: 'Cancelar',

    requirementsTitle: 'Su nueva contraseña debe tener:',
    chips: {
      hasAtLeastEight: 'Mínimo de 8 caracteres',
      hasUpperCase: 'Letra mayúscula',
      hasLowerCase: 'Letra minúscula',
      hasSymbol: 'Caracteres especiales',
      hasNumber: 'Números',
    },
  },
  twoFactorAuth: {
    title: 'Autenticación de dos factores',
    description: userEmail => (
      <>
        Los correos electrónicos con el código de verificación se envían a la dirección registrada:
        {' '}
        <b>{userEmail}</b>
      </>
    ),
    status: {
      title: 'Status',
      enabled: 'Activado',
      disabled: 'Desactivado',
    },
    statusButtonLabel: {
      enable: 'Activar',
      disable: 'Desactivar',
    },
    snackbarFeedback: type => `Autenticación de dos factores ${type === 'enable' ? 'activada' : 'desactivada'} con éxito`,
    validateCodeModal: {
      description: 'Los correos electrónicos con el código de verificación se envían a la dirección registrada:',
      codeInputPlaceholder: 'Código de 6 digitos',
      resendCodeMessage: 'Reenviar el código a: ',
      resendCodeButtonLabel: 'Reenviar código',
      confirmText: modalType => (
        <>
          ¿Está seguro de que quiere
          {' '}
          <b>{modalType === 'enable' ? 'activar' : 'desactivar'}</b>
          {' '}
          esta función?
        </>
      ),
      codeInputError: 'Código inválido',
      enable2FA: {
        title: 'Activar la autenticación de dos factores',
        buttonLabel: 'Si, activar',
      },
      disable2FA: {
        title: 'Desactivar la autenticación de dos factores',
        warning: {
          title: 'Esta acción disminuirá la seguridad de su cuenta',
          description: 'Recomendamos que mantenga esta capa adicional de protección para defender su cuenta de accesos no autorizados y posibles fraudes.',
        },
        buttonLabel: 'Si, desactivar',
      },
    },
  },
};
