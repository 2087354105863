import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './ChangePaymentcycle.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { HgProducts } from '../../SupportForms.types';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const ChangePaymentcicle = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { changePaymentCycle: changePaymentCycleLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    newCycle: '',
    generalComment: '',
    agreed: false,
  });

  const [productOptions, setProductOptions] = useState([]);

  const checkForm = useCallback(() => !(!!formData.agreed && !!formData.newCycle), [formData]);

  const createUpgradeOptions = useCallback(() => {
    const options = [];

    const monthly = {
      label: 'Mensal',
      value: 'Monthly',
    };

    const quarterly = {
      label: 'Trimestral',
      value: 'Quarterly',
    };

    const annualy = {
      label: 'Anual',
      value: 'Annually',
    };

    if (selectedProduct.billingcycle !== 'Monthly') {
      options.push(monthly);
    }

    if (selectedProduct.billingcycle !== 'Quarterly') {
      options.push(quarterly);
    }


    if (selectedProduct.billingcycle !== 'Annually') {
      if (!HgProducts.STREAMING_ALL.includes(selectedProduct.pid)) {
        options.push(annualy);
      }
    }

    options.filter(option => option.value !== selectedProduct.billingcycle);

    setProductOptions(options);
  }, [selectedProduct]);

  useEffect(() => {
    createUpgradeOptions();
  }, [createUpgradeOptions]);


  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: changePaymentCycleLocale.subject,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.CHANGE_PAYMENT_CYCLE,
      tags: [
        'whmcs_form_financeiro_alterar_ciclo',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
        • ${changePaymentCycleLocale.body.product(selectedProduct)}
        • ${changePaymentCycleLocale.body.request}
        • ${changePaymentCycleLocale.body.newCycle(formData.newCycle)}
        • ${changePaymentCycleLocale.body.agreed(formData.agreed)}
        • ${changePaymentCycleLocale.body.generalComment(formData.generalComment)}
        • ${changePaymentCycleLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    changePaymentCycleLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {changePaymentCycleLocale.info}
      </Styles.Info>

      <Styles.Title>
        {changePaymentCycleLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Select
          name="newCycle"
          testId="newCycle"
          placeholder={changePaymentCycleLocale.placeholders.newCycle}
          label={formData.newCycle ? changePaymentCycleLocale.placeholders.newCycle : ''}
          options={productOptions}
          value={formData.newCycle}
          setValue={label => handleChange({ key: 'newCycle', value: label })}
          withScroll
        />

        <Input
          type="textarea"
          testId="generalComment"
          label={formData.generalComment ? changePaymentCycleLocale.placeholders.generalComment : ''}
          placeholder={changePaymentCycleLocale.placeholders.generalComment}
          value={formData.generalComment}
          onChange={e => handleChange({
            key: 'generalComment',
            value: e.target.value,
          })}
          rows={3}
          counter
        />
      </Styles.InputsWrapper>

      <Styles.InputsWrapper>
        <Styles.CheckboxLabel
          htmlFor="#readTerms"
          data-testid="checkReadTerms"
          onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
        >
          <Checkbox checked={formData.agreed} id="readTerms" />
          <Styles.Text>
            {changePaymentCycleLocale.placeholders.agreed}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputsWrapper>


      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : changePaymentCycleLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default ChangePaymentcicle;
