import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Fab, Typography, SvgIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { NewChatButton } from '../NewChatButton';
import { FeaturesReady, useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import {
  CHARLIE_NEW_ZENDESK_GATORHELPER, CHARLIE_PRECHAT, CHARLIE_ZENDESK_CHAT_TITLE,
  CHARLIE_ZENDESK_DELETE_SUNSHINE,
  DELETE_ZENDESK_LOCAL_STORAGE,
  ZENDESK_CHAT_FINISH,
  ZENDESK_TICKET_ALERT,
  ZENDESK_WIDGET_KEY,
} from '@/config/GrowthBook/constants';
import {
  CHAT_TOKEN, ZENDESK_AUTH_KEY, ZENDESK_AUTH_TOKEN, COUNTRY,
} from '@/config';
import jwt from 'jsonwebtoken';
import { useDispatch, useSelector } from 'react-redux';
import { isBRServer } from '@/utils/Validators/validation';
import logger from '@/utils/logger';
import useLocale from '@/hooks/useLocale';
import { preChatActions } from '@/redux/modules';
import { addMinutes, differenceInMilliseconds, differenceInMinutes } from 'date-fns';
import { api } from '@/utils/api';

// BELIEVE ME, I'M SORRY FOR THIS FILE, CIRCUMSTANCES MADE ME DO THIS
const ChatButton = ({ classes }) => {
  const [hasActiveChat, setHasActiveChat] = useState(false);
  const [widgetIsOpen, setWidgetIsOpen] = useState(false);
  const { email, name, id } = useSelector(state => state.summary);
  const { token } = useSelector(state => state.auth);
  const [zendeskExists, setZendeskExists] = useState(!!window.zE);
  const [isTicket, setIsTicket] = useState(false);
  const { preChat: preChatLocale } = useLocale();
  const dispatch = useDispatch();

  const disablePreChat = useFeatureIsOn(CHARLIE_PRECHAT);
  const useNewZendesk = useFeatureIsOn(CHARLIE_NEW_ZENDESK_GATORHELPER);
  const chatTitle = useFeatureValue(CHARLIE_ZENDESK_CHAT_TITLE);
  const deleteSunshineUser = useFeatureIsOn(CHARLIE_ZENDESK_DELETE_SUNSHINE);
  const zendeskWidgetKey = useFeatureValue(ZENDESK_WIDGET_KEY);
  const deleteZendeskLocalStorage = useFeatureValue(DELETE_ZENDESK_LOCAL_STORAGE);
  const showZendeskTicketAlert = useFeatureValue(ZENDESK_TICKET_ALERT);
  const enableChatReset = useFeatureIsOn(ZENDESK_CHAT_FINISH);
  const intervalIds = useMemo(() => [], []);

  const downloadZendeskWebWidget = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetKey || CHAT_TOKEN}`;
    document.body.appendChild(script);
  }, [zendeskWidgetKey]);

  useEffect(() => {
    downloadZendeskWebWidget();
  }, [downloadZendeskWebWidget]);

  const verifyIfZendeskExists = useCallback(() => {
    if (!window.zE) {
      window.setTimeout(verifyIfZendeskExists, 1000);
      return;
    }

    setZendeskExists(true);
  }, []);

  useEffect(() => {
    verifyIfZendeskExists();
  }, [verifyIfZendeskExists]);

  const clearAllIntervals = useCallback(() => {
    while (intervalIds.length) {
      const id = intervalIds.pop();
      clearInterval(id);
    }
  }, [intervalIds]);

  const minimizeWidgetAndResetPrechat = useCallback(() => {
    // eslint-disable-next-line no-undef
    zE('messenger', 'close');
    setHasActiveChat(false);
    setWidgetIsOpen(false);
    localStorage.removeItem('hasTicketOpen');

    const hasConversationStarted = Object.keys(localStorage).filter(key => key.includes('.conversationStartedAt'))[0];
    if (hasConversationStarted) {
      localStorage.removeItem(hasConversationStarted);
    }

    clearAllIntervals();
    localStorage.removeItem('resetHasBeenTriggered');
  }, [clearAllIntervals]);

  const resetChatAfterFinishMessage = useCallback(() => {
    const chatComponent = document.querySelector(`[title="${chatTitle}"]`);
    const chatComponentDocument = chatComponent && chatComponent.contentWindow && chatComponent.contentWindow.document;
    const chatLog = chatComponentDocument && chatComponentDocument.querySelector('[role="log"]');
    const hasResetMessage = chatLog && chatLog.children && chatLog.children.length
      && [...chatLog.children].some(child => preChatLocale.resetMessages && preChatLocale.resetMessages.some(resetMessage => child.innerText.includes(resetMessage)) && child.innerHTML.includes(isBRServer ? 'Agora mesmo' : 'Hace un momento'));

    if (enableChatReset) {
      const resetHasBeenTriggered = localStorage.getItem('resetHasBeenTriggered');
      const timeSinceReset = differenceInMinutes(new Date(), new Date(resetHasBeenTriggered));

      if (hasResetMessage || (resetHasBeenTriggered && timeSinceReset < 10)) {
        const inputComponent = chatComponentDocument && chatComponentDocument.getElementsByTagName('textarea')[0];
        const chatBottom = inputComponent && inputComponent.parentElement && inputComponent.parentElement.parentElement && inputComponent.parentElement.parentElement.parentElement;
        if (chatBottom) {
          chatBottom.childNodes.forEach((child) => {
            // eslint-disable-next-line no-param-reassign
            child.style.display = 'none';
          });

          const alertWrapper = chatComponentDocument.createElement('div');
          alertWrapper.style.display = 'flex';
          alertWrapper.style.flexDirection = 'column';
          alertWrapper.style.fontFamily = 'system-ui';
          alertWrapper.style.justifyContent = 'center';
          alertWrapper.style.alignItems = 'center';
          alertWrapper.style.padding = '0px 16px 16px 16px';
          alertWrapper.style.bottom = '0px';
          alertWrapper.style.left = '0px';
          alertWrapper.style.width = '100%';
          alertWrapper.innerHTML = `
          <p style="color: #707070; text-align: center; font-size: 14px; font-weight: 400; max-width: 325px; margin-bottom: 16px;">
            ${preChatLocale.chatFinished}
          </p>
          `;
          chatBottom.appendChild(alertWrapper);

          const startNewChatButton = chatComponentDocument.createElement('button');
          startNewChatButton.style.height = '40px';
          startNewChatButton.style.padding = '0px 24px';
          startNewChatButton.style.justifyContent = 'center';
          startNewChatButton.style.alignItems = 'center';
          startNewChatButton.style.flexShrink = '0';
          startNewChatButton.style.borderRadius = '24px';
          startNewChatButton.style.background = '#0070D1';
          startNewChatButton.style.border = 'none';
          startNewChatButton.style.color = '#FFF';
          startNewChatButton.style.textAlign = 'center';
          startNewChatButton.style.fontFamily = 'system-ui';
          startNewChatButton.style.fontSize = '16px';
          startNewChatButton.style.fontStyle = 'normal';
          startNewChatButton.style.fontWeight = '500';
          startNewChatButton.style.lineHeight = 'normal';
          startNewChatButton.style.cursor = 'pointer';
          startNewChatButton.innerText = preChatLocale.startNewChat;
          alertWrapper.appendChild(startNewChatButton);

          startNewChatButton.addEventListener('mouseover', () => {
            startNewChatButton.style.backgroundColor = '#2E93EE';
          });

          startNewChatButton.addEventListener('mouseout', () => {
            startNewChatButton.style.backgroundColor = '#0070D1';
          });

          startNewChatButton.addEventListener('click', async () => {
            await minimizeWidgetAndResetPrechat();
            dispatch(preChatActions.preChat.handleChatDisplay());
          });
        }

        if (!intervalIds.length && timeSinceReset < 10) {
          const timeToDispatch = addMinutes(new Date(resetHasBeenTriggered), 10);
          const remainingTime = differenceInMilliseconds(timeToDispatch, new Date());
          const timeoutId = window.setTimeout(minimizeWidgetAndResetPrechat, remainingTime);
          intervalIds.push(timeoutId);
        }

        if (!resetHasBeenTriggered) {
          localStorage.setItem('resetHasBeenTriggered', new Date().toISOString());
        }
      } else {
        localStorage.removeItem('resetHasBeenTriggered');

        const inputComponent = chatComponentDocument && chatComponentDocument.getElementsByTagName('textarea')[0];
        const chatBottom = inputComponent && inputComponent.parentElement && inputComponent.parentElement.parentElement && inputComponent.parentElement.parentElement.parentElement;
        if (chatBottom) {
          chatBottom.childNodes.forEach((child) => {
            // eslint-disable-next-line no-param-reassign
            child.style.display = 'none';
          });

          chatBottom.childNodes[0].style.display = 'block';
          chatBottom.childNodes[2].style.display = 'block';
        }
      }
    }
  }, [chatTitle, minimizeWidgetAndResetPrechat, preChatLocale, enableChatReset, dispatch, intervalIds]);

  const observeZendeskChat = useCallback(() => {
    const chatComponent = document.querySelector(`[title="${chatTitle}"]`);
    const chatComponentDocument = chatComponent && chatComponent.contentWindow && chatComponent.contentWindow.document;
    const inputComponent = chatComponentDocument && chatComponentDocument.getElementsByTagName('textarea')[0];
    const chatLog = chatComponentDocument && chatComponentDocument.querySelector('[role="log"]');

    if (!inputComponent && !chatLog) {
      window.setTimeout(observeZendeskChat, 7000);
      return;
    }

    const config = { attributes: true, childList: true, subtree: true };

    const logObserver = new MutationObserver(resetChatAfterFinishMessage);
    logObserver.observe(chatLog, config);
  }, [chatTitle, resetChatAfterFinishMessage]);

  useEffect(() => {
    observeZendeskChat();
  }, [observeZendeskChat]);

  const generateZendeskToken = useCallback(async () => {
    if (deleteZendeskLocalStorage) {
      // eslint-disable-next-line no-undef
      await zE('messenger', 'logoutUser');

      await localStorage.removeItem('ZD-suid');
      await localStorage.removeItem('ZD-buid');
      await localStorage.removeItem('ZD-lastPlayedTimestamp');

      const zendeskLogin = Object.keys(localStorage).filter(key => key.includes('.appUserId'))[0];
      if (zendeskLogin) {
        await localStorage.removeItem(zendeskLogin);
      }

      const hasConversationStarted = Object.keys(localStorage).filter(key => key.includes('.conversationStartedAt'))[0];
      if (hasConversationStarted) {
        await localStorage.removeItem(hasConversationStarted);
      }
    }

    const zendeskCustomerId = `${isBRServer ? 'br_' : 'es_'}${String(id)}`;

    const token = jwt.sign(
      {
        external_id: zendeskCustomerId,
        name,
        email,
        email_verified: true,
        scope: 'user',
      },
      ZENDESK_AUTH_TOKEN,
      {
        keyid: ZENDESK_AUTH_KEY,
        algorithm: 'HS256',
      },
    );

    return token;
  }, [email, id, name, deleteZendeskLocalStorage]);

  const verifyUserHasActiveChat = useCallback(() => {
    const hasConversationStarted = Object.keys(localStorage).filter(key => key.includes('.conversationStartedAt'))[0];

    if (hasConversationStarted) {
      setHasActiveChat(true);
      const hasTicketOpen = localStorage.getItem('hasTicketOpen');
      setIsTicket(hasTicketOpen === 'true');
    } else {
      setHasActiveChat(false);
      localStorage.removeItem('hasTicketOpen');
    }
  }, []);

  const verifyZendeskLoginRetry = useCallback((retry) => {
    const loggedInZendesk = Object.keys(localStorage).filter(key => key.includes('.appUserId'))[0];
    if (loggedInZendesk) {
      verifyUserHasActiveChat();
    } else if (retry < 2) {
      const zendeskJWTToken = generateZendeskToken();

      // eslint-disable-next-line no-undef
      zE('messenger', 'loginUser', (callback) => {
        callback(zendeskJWTToken);
        setTimeout(() => verifyZendeskLoginRetry(retry + 1), 10000);
      });
    } else {
      const zendeskCustomerId = `${isBRServer ? 'br_' : 'es_'}${String(id)}`;
      logger.info('Zendesk - User is not logged in zendesk', { email, zendeskCustomerId, name });
    }
  }, [id, email, name, verifyUserHasActiveChat, generateZendeskToken]);

  const handleSunshine = useCallback(async (retry) => {
    const requestClearSunshineUser = {
      headers: {
        Authorization: token,
        email,
        brand: COUNTRY.toLowerCase(),
      },
      url: 'v2/clear/zendesk',
      data: {
        external_id: String(id),
      },
    };

    try {
      await api.customerSupport.post(requestClearSunshineUser);
      logger.info('Zendesk - Sunshine user cleared', { email, id });

      setTimeout(() => {
        const zendeskJWTToken = generateZendeskToken();

        // eslint-disable-next-line no-undef
        zE('messenger', 'loginUser', (callback) => {
          callback(zendeskJWTToken);
          setTimeout(() => verifyZendeskLoginRetry(0), 10000);
        });
      }, 10000);
    } catch (e) {
      if (e.response.status !== 404) {
        logger.error('Zendesk - Error clearing sunshine user', e);
        if (retry < 2) {
          handleSunshine(retry + 1);
          return;
        }
      }

      setTimeout(() => {
        const zendeskJWTToken = generateZendeskToken();

        // eslint-disable-next-line no-undef
        zE('messenger', 'loginUser', (callback) => {
          callback(zendeskJWTToken);
          setTimeout(() => verifyZendeskLoginRetry(0), 10000);
        });
      }, 10000);
    }
  }, [id, token, generateZendeskToken, email, verifyZendeskLoginRetry]);

  const verifyZendeskLogin = useCallback(() => {
    const loggedInZendesk = Object.keys(localStorage).filter(key => key.includes('.appUserId'))[0];
    if (loggedInZendesk) {
      verifyUserHasActiveChat();
    } else {
      deleteSunshineUser && handleSunshine(0);
    }
  }, [handleSunshine, deleteSunshineUser, verifyUserHasActiveChat]);

  const loginZendeskUser = useCallback(async () => {
    const zendeskJWTToken = await generateZendeskToken();

    // eslint-disable-next-line no-undef
    zE('messenger', 'loginUser', (callback) => {
      callback(zendeskJWTToken);
      setTimeout(verifyZendeskLogin, 10000);
    });
  }, [verifyZendeskLogin, generateZendeskToken]);

  useEffect(() => {
    if (name && email && id && useNewZendesk && zendeskExists) {
      // eslint-disable-next-line no-undef
      zE('messenger:set', 'locale', `${isBRServer ? 'pt-br' : 'es'}`);
      loginZendeskUser();
    }
  }, [zendeskExists, loginZendeskUser, email, id, name, useNewZendesk]);

  // eslint-disable-next-line no-undef
  zendeskExists && zE('messenger:on', 'open', () => {
    setTimeout(resetChatAfterFinishMessage, 500);
  });

  const showAlertIfIsTicket = useCallback(() => {
    if (showZendeskTicketAlert && isTicket && widgetIsOpen) {
      const chatComponent = document.querySelector(`[title="${chatTitle}"]`);
      const chatComponentDocument = chatComponent && chatComponent.contentWindow && chatComponent.contentWindow.document;
      const chatLog = chatComponentDocument && chatComponentDocument.querySelector('[role="log"]');

      if (!chatLog) {
        window.setTimeout(showAlertIfIsTicket, 1000);
        return;
      }

      const alertWrapper = chatComponentDocument.createElement('div');
      alertWrapper.style.backgroundColor = '#FFF1E5';
      alertWrapper.style.position = 'absolute';
      alertWrapper.style.display = 'flex';
      alertWrapper.style.flexDirection = 'column';
      alertWrapper.style.fontFamily = 'system-ui';
      alertWrapper.style.justifyContent = 'center';
      alertWrapper.style.padding = '16px';
      alertWrapper.style.top = '64px';
      alertWrapper.style.width = '100%';
      alertWrapper.innerHTML = '<p style="color: #1F2044; font-size: 14px; font-weight: 700; max-width: 255;">Essa demanda foi atribuída como um chamado à nossa equipe.</p><div><p style="color: #1F2044; font-size: 14px; font-weight: 500; max-width: 250;">A resolução ocorrerá em <strong style="color: #1F2044; font-size: 14px; font-weight: 700;">até 8 horas.</strong> Agradecemos pela paciência!</p></div>';
      chatComponentDocument.body.appendChild(alertWrapper);
    }
  }, [showZendeskTicketAlert, isTicket, widgetIsOpen, chatTitle]);

  useEffect(() => {
    showAlertIfIsTicket();
  }, [showAlertIfIsTicket]);

  const startZendeskChat = () => {
    // eslint-disable-next-line no-undef
    zE('messenger', 'open');
    setWidgetIsOpen(true);
  };

  return (
    <FeaturesReady timeout={5000}>
      {!disablePreChat && zendeskExists && (
        <NewChatButton
          hasActiveChat={hasActiveChat}
          setHasActiveChat={setHasActiveChat}
          chatTitle={chatTitle}
          setWidgetIsOpen={setWidgetIsOpen}
          widgetIsOpen={widgetIsOpen}
          setIsTicket={setIsTicket}
        />
      )}
      {disablePreChat && zendeskExists && (
        <Fab
          color="primary"
          aria-label="Chat"
          onClick={startZendeskChat}
          className={classes.chatButton}
          classes={{ label: classes.chatButtonLabel }}
        >
          <SvgIcon viewBox="0 0 477.6 477.6" className={classes.chatIcon}>
            <path d="M395.6,81.9c-41.9-41.9-97.7-65-156.9-65S123.9,40,81.9,81.9c-39,39-62,91-64.7,146.3c-2.6,54.2,14.5,106.9,48.1,149
          c-9,17.6-19.4,29.3-30.9,34.9c-9.7,4.8-15.2,15.2-13.6,26c1.7,10.8,10,19.2,20.7,20.8c5.4,0.8,10.8,1.2,16.1,1.2
          c26.7,0,54.4-8.7,78.4-24.6c31.5,16.4,66.9,25.1,102.7,25.1c59.3,0,115-23.1,156.9-65c41.9-41.9,65-97.6,65-156.9
          C460.6,179.6,437.5,123.8,395.6,81.9z M100.7,381.2c2.6-5.9,1.5-13-2.9-17.9c-65.8-74.3-62.3-187.5,7.9-257.7
          c35.5-35.5,82.8-55.1,133-55.1s97.5,19.6,133,55.1c73.4,73.5,73.4,192.9,0,266.3l0,0c-35.5,35.5-82.8,55.1-133,55.1
          c-33.6,0-66.5-8.9-95.3-25.9c-2.6-1.5-5.6-2.3-8.4-2.3c-3.7,0-7.3,1.2-10.3,3.5c-19,14.7-37.9,20.6-51.9,22.8
          C83.5,414.1,92.7,399.7,100.7,381.2z"
            />
          </SvgIcon>
          <Typography className={classes.chatButtonText}>Chat</Typography>
        </Fab>
      )}
    </FeaturesReady>
  );
};

export default withStyles(styles)(ChatButton);
