
const styles = theme => ({
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 9,
  },
  info: {
    fill: theme.color.indigo,
    fontSize: 17,
    marginLeft: 8,
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    userSelect: 'none',
  },
  explanation: {
    alignItems: 'center',
    color: theme.color.dustyGgray,
    display: 'block',
    flexWrap: 'wrap',
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 8,

    '& > span': {
      borderBottom: `1px dashed ${theme.color.indigo}`,
      color: theme.color.indigo,
    },

    '& > svg': {
      fill: theme.color.indigo,
      width: 16,
    },
  },
  knowMore: {
    marginBottom: 12,
  },
  explanationLink: {
    color: theme.color.indigo,
    fontWeight: 500,
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
  },
});

export default styles;
