import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import { IconError, IconArrowDownOutlined } from '@/icons';
import * as Styles from './HostGatorCustomDnsZoneModal.styles';
import { color, v1 } from '@/styles/HgThemeProvider';
import { Button } from '@/pages/common/v1/Button';
import { splitDomainAndTld } from '@/utils/Formatters/splitDomainAndTld';
import { PLATFORM_NAMES, HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const HostGatorCustomDnsZoneModal = ({
  testId = 'customdnszonemodal',
  customDnsZoneModal = false,
  handleClose = () => {},
  handleConfigureCustomDnsZone = () => {},
  modalsLocale = {
    title: 'title',
    subtitle: 'subtitle',
    alertMessageText: 'alertMessageText',
    checkboxText: 'checkboxText',
    buttonText: 'buttonText',
    alertMessage: () => {},
    tableHeaders: {
      type: '',
      value: '',
      name: '',
    },
  },
  type = null,
  platform = {
    dnsDefaultEntries: [],
    platformID: 'uuid',
  },
  currentType = '',
  domainName = '',
  propagationHours = '??',
  analytics = {
    dnsWizardCustomDnsZoneConfigureButtonClick: () => {},
    dnsWizardCustomDnsZonePropagationHelpClick: () => {},
    dnsWizardCustomDnsZoneShowRecordsClick: () => {},
    dnsWizardCustomDnsZoneHideRecordsClick: () => {},
    dnsWizardCustomDnsZoneCloseClick: () => {},
  },
  domainIp = '',
}) => {
  const { mustard } = color;

  const [openDnsRecordsTable, setOpenDnsRecordsTable] = useState(false);
  const [hoverColor, setHoverColor] = useState(v1.color.action.primary);

  const { platformID: platformId, dnsDefaultEntries } = platform;

  const {
    title,
    subtitle,
    configureButtonLabel,
    showButtonLabel,
    hideButtonLabel,
    tableHeaders,
    alertMessage,
  } = modalsLocale;

  const {
    dnsWizardCustomDnsZoneConfigureButtonClick,
    dnsWizardCustomDnsZoneShowRecordsClick,
    dnsWizardCustomDnsZoneHideRecordsClick,
    dnsWizardCustomDnsZoneCloseClick,
    dnsWizardCustomDnsZonePropagationHelpClick,
  } = analytics;

  const [domain, tld] = splitDomainAndTld(domainName);

  const {
    type: headerType, value: headerValue, name: headerName,
  } = tableHeaders;

  const handleSubmit = () => {
    handleConfigureCustomDnsZone(HOSTING_PLATFORM_TYPES.CUSTOM_DNS_ZONE, { platformId, type, currentType });
    dnsWizardCustomDnsZoneConfigureButtonClick(type);
    handleClose();
  };

  const handleCloseModal = () => {
    dnsWizardCustomDnsZoneCloseClick(type);
    handleClose();
  };

  const dnsRecordsTableSwitch = () => {
    if (openDnsRecordsTable) {
      dnsWizardCustomDnsZoneHideRecordsClick(type);
    } else {
      dnsWizardCustomDnsZoneShowRecordsClick(type);
    }
    setOpenDnsRecordsTable(!openDnsRecordsTable);
  };

  const formatRecordField = text => `${text}`.replace('{domain}', domain).replace('{tld}', tld).replace('{hosting-ip}', domainIp);

  const label = openDnsRecordsTable ? (
    <Styles.TextWrapper data-testid="dns-table-close-button">
      {hideButtonLabel}
      <Styles.IconWrapper orientation="down">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  ) : (
    <Styles.TextWrapper data-testid="dns-table-open-button">
      {showButtonLabel}
      <Styles.IconWrapper orientation="up">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  );

  const dnsRecordsTable = (
    <>

      <Styles.DnsRecordsTable>
        <tr>
          <th>{headerType}</th>
          <th>{headerValue}</th>
          <th>{headerName}</th>
        </tr>
        {dnsDefaultEntries && dnsDefaultEntries.map(e => (
          <tr>
            <td>{formatRecordField(e.type)}</td>
            <td>
              <dl>
                {
              Object.keys(e).filter(field => (field === 'address' || field === 'cname' || field === 'txt_data')).map(key => (
                <div>
                  <dd title={formatRecordField(e[key])}>
                    {`${formatRecordField(e[key])}`.slice(0, 35)}
                    {`${formatRecordField(e[key])}`.length > 35 && '...'}
                  </dd>
                </div>
              ))
              }
              </dl>
            </td>
            <td title={formatRecordField(e.name)}>
              {`${formatRecordField(e.name)}`.slice(0, 35)}
              {`${formatRecordField(e.name)}`.length > 35 && '...'}
            </td>
          </tr>
        ))}
      </Styles.DnsRecordsTable>
      <Styles.DnsRecordsTableSmall>
        {platform && platform.dnsDefaultEntries.map(e => (
          <table>
            <tr>
              <th>{headerType}</th>
              <td>{formatRecordField(e.type)}</td>
            </tr>
            <tr>
              <th>{headerValue}</th>
              {
            Object.keys(e).filter(field => (field === 'address' || field === 'cname' || field === 'txt_data')).map(key => (
              <td title={formatRecordField(e[key])}>
                {`${formatRecordField(e[key])}`.slice(0, 25)}
                {`${formatRecordField(e[key])}`.length > 25 && '...'}
              </td>
            ))
            }
            </tr>
            <tr>
              <th>{headerName}</th>
              <td title={formatRecordField(e.name)}>
                {`${formatRecordField(e.name)}`.slice(0, 25)}
                {`${formatRecordField(e.name)}`.length > 25 && '...'}
              </td>
            </tr>
          </table>
        ))}
      </Styles.DnsRecordsTableSmall>
    </>
  );
  return (
    <>
      {customDnsZoneModal
      && (
        <Modal onClose={handleCloseModal} maxWidth={780}>
          <Styles.HostGatorOtherPlatformModalWrapper data-testid={testId}>
            <Styles.TitleWrapper data-testid="customdnszonemodal-title">
              <Styles.Title>{title(type)}</Styles.Title>
              <Styles.SubTitle data-testid="customdnszonemodal-subtitle">
                {subtitle(type)}
              </Styles.SubTitle>
            </Styles.TitleWrapper>
            <Styles.ButtonWrapper
              onMouseEnter={() => setHoverColor(v1.color.white.primary)}
              onMouseLeave={() => setHoverColor(v1.color.action.primary)}
            >
              <Button label={label} onClick={dnsRecordsTableSwitch} variant="secondary" />
            </Styles.ButtonWrapper>
            {openDnsRecordsTable && dnsRecordsTable}

            <Styles.MessageWrapper data-testid="customdnszonemodal-message">
              <IconError size="24" color={mustard} />
              {alertMessage(propagationHours, PLATFORM_NAMES[type], () => dnsWizardCustomDnsZonePropagationHelpClick(type))}
            </Styles.MessageWrapper>
            <Styles.ButtonWrapper>
              <Button label={configureButtonLabel} onClick={handleSubmit} />
            </Styles.ButtonWrapper>
          </Styles.HostGatorOtherPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorCustomDnsZoneModal;
