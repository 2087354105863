import { COUNTRY } from '..';

const SUPPORT_URLS_BY_BRAND = {
  br: {
    fast: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360020883234',
    advanced: 'https://suporte.hostgator.com.br/hc/pt-br/sections/115000138513-Criador-de-sites',
    wordpress: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001935813',
    cpanel: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360005082713',
  },
  mx: {
    fast: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360026789532',
    advanced: 'https://soporte-latam.hostgator.com/hc/es-419/sections/115000230292-Creador-de-Sitios',
    wordpress: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
    cpanel: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360014249751-Como-vaciar-la-papelera-del-gerenciador-de-archivos',
  },
  cl: {
    fast: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360026789532',
    advanced: 'https://soporte-latam.hostgator.com/hc/es-419/sections/115000230292-Creador-de-Sitios',
    wordpress: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
    cpanel: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360014249751-Como-vaciar-la-papelera-del-gerenciador-de-archivos',
  },
  co: {
    fast: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360026789532',
    advanced: 'https://soporte-latam.hostgator.com/hc/es-419/sections/115000230292-Creador-de-Sitios',
    wordpress: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-Softaculous-C%C3%B3mo-instalar-WordPress-',
    cpanel: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360014249751-Como-vaciar-la-papelera-del-gerenciador-de-archivos',
  },
};

export const TUTORIALS_URLS = SUPPORT_URLS_BY_BRAND[COUNTRY];
