const styles = theme => ({
  root: {
    marginLeft: 100,
    marginTop: 191,
    color: theme.color.tundora,
    [theme.breakpoints.down(700)]: {
      marginLeft: 30,
      marginRight: 30,
      marginTop: 20,
    },
  },
  title: {
    fontSize: 48,
    margin: 0,
    [theme.breakpoints.down(700)]: {
      fontSize: 24,
    },
  },
  textDescription: {
    fontSize: 14,
    width: '100%',
    maxWidth: 384,
    marginBottom: 23,
  },
});

export default styles;
