import React, { useState } from 'react';
import { withI18n } from 'react-i18next';
import { Button } from '@/pages/common/Button';
import { IconClose } from '@/icons';
import { cobreFacilTopBanner, traktoTopBanner } from '@/utils/ThirdParties/tagManager';
import * as Style from './TopAdvertisementHandler.styles';

const TopAdvertisementHandler = ({
  ctaLabel, title, logo, href, callback, partnerInfo,
}) => {
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    if (href && href !== '#') {
      return window.open(href, '_blank');
    }
  };

  const handleClose = () => {
    setVisible(false);
    callback && callback();
  };

  const gaEventActionOnObtainPartnership = (partner) => {
    switch (partner) {
      case 'cobrefacil':
        cobreFacilTopBanner();
        break;
      case 'trakto':
        traktoTopBanner();
        break;
      default:
        break;
    }
  };

  return (
    visible
    && (
    <Style.Wrapper data-testid="advertisement-handler">
      <Style.Info>
        <Style.Logo src={logo} />
        <Style.Title>{title}</Style.Title>
        <Button
          label={ctaLabel}
          onClick={() => { handleClick(); gaEventActionOnObtainPartnership(partnerInfo); }}
          testId="partner-link-button"
          type="button"
          variant="darkHover"
        />
      </Style.Info>
      <Style.CloseButton onClick={handleClose} data-testid="top-ad-on-close">
        <IconClose color="white" />
      </Style.CloseButton>
    </Style.Wrapper>
    )
  );
};

export default withI18n()(TopAdvertisementHandler);
