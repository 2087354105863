import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 40px 0px;
    width: 100%;
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 32px 0px 119px 0px;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 32px;
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0 0 8px 0px;
    span {
      color: ${theme.v2.colors.brand.primaryMedium};
    }
  `}
`;
