import React from 'react';
import * as Style from './IconSuccess.styles';

const IconSuccess = ({ testId = 'icon-success' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
      <path id="Exclusão_1" data-name="Exclusão 1" d="M22.5,45A22.5,22.5,0,1,1,45,22.5,22.525,22.525,0,0,1,22.5,45ZM12.172,22.522h0L9,25.7l9,9,18-18L32.828,13.5,18,28.328l-5.828-5.806Z" fill="#35cf8b" />
    </svg>
  </Style.Wrapper>
);


export default IconSuccess;
