import shortid from 'shortid';

import {
  ENQUEUE_SNACKBAR, REMOVE_SNACKBAR,
} from '@/redux/actions/actionsTypes';
import LinkAccountAction from '@/components/ActionsSnackbar/LinkAccount';
import LinkDismissAction from '@/components/ActionsSnackbar/LinkDismiss';


const getMessage = (errorTag, options) => {
  const params = {
    message: `snackbar.error.${errorTag}`,
    options: {
      ...options,
    },
  };

  switch (errorTag) {
    case 'violated_policy':
      params.options.action = LinkAccountAction;
      break;
    default:
      params.options.action = LinkDismissAction;
      break;
  }
  return params;
};

export const enqueueSnackbar = (message, options, messageValues = {}) => {
  const notification = {
    message,
    messageValues,
    options: {
      ...options,
      key: shortid.generate(),
    },
  };
  return {
    type: ENQUEUE_SNACKBAR,
    notification,
  };
};

export const notification = (errorTag, options) => (dispatch) => {
  const params = getMessage(errorTag, options);
  if (params.message == null) return Promise.resolve();
  return dispatch(enqueueSnackbar(params.message, params.options));
};

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});
