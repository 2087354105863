import React from 'react';
import { OthersSiteTypesModal } from '../OthersSiteTypesModal';
import { ThemeInfoModal } from '../ThemeInfoModal';
import { DomainRegisteredModal } from '../DomainRegisteredModal';
import { ModalDnsAutomatic } from '../ModalDnsAutomatic';
import AnotherDomainModal from '../AnotherDomainModal/AnotherDomainModal';
import { ChoosePlanModal } from '../ChoosePlanModal';
import { SelectManualDnsModal } from '../SelectManualDnsModal';
import UpgradePlanModal from '../UpgradePlanModal/UpgradePlanModal';
import { AsaasCreateAccountModal } from '@/pages/asaas/AsaasHandler/components/AsaasCreateAccountModal';
import { VerifyingSiteExistModal } from '../VerifyingSiteExistModal';
import { DnsInProgressModal } from '../DnsInProgressModal';

const WizardHandlerModals = ({
  displayOthersSitesModal,
  handleOnCloseOthersSitesModal,
  wizardLocale,
  othersSitesEditFieldValue,
  handleOthersSitesEditFieldValue,
  handleOnSaveOthersSitesModal,
  displayOthersObjectivesModal,
  handleOnCloseOthersObjectivesModal,
  othersObjectivesEditFieldValue,
  handleOthersObjectivesEditFieldValue,
  handleOnSaveOthersObjectivesModal,
  displayThemeInfoModal,
  handleOnCloseThemeInfoModal,
  getModalTheme,
  handleModalChangeTheme,
  showModal,
  modals,
  sitesState,
  handleCloseModal,
  wizardConfigurationsAnalytics,
  selectedDomain,
  backAnotherDomainModal,
  handleChangeDomain,
  chosenPlan,
  selectedDomainPlan,
  setChosenPlan,
  singleDomainsPlans,
  multiDomainsPlans,
  handleContinueToNextStep,
  getActivePlans,
  displayCreateAccountModal,
  asaasLocale,
  handleContinueDnsInProgressModal,
  loadingVerifyIfSiteExist,
  loadingDefaultFilesExclude,
  checkDomainAvailability,
  userDomainList,
  withGatorSolutionsPlans,
}) => {
  const getOthersSitesTypesModalProps = () => {
    const defaultProps = {
      handleOnCloseOthersModal: handleOnCloseOthersSitesModal,
      title: wizardLocale.about.othersSitesModal.title,
      buttonBack: wizardLocale.about.othersSitesModal.back,
      buttonSave: wizardLocale.about.othersSitesModal.save,
      inputPlaceholder: wizardLocale.about.othersSitesModal.placeholder,
      othersEditFieldValue: othersSitesEditFieldValue,
      handleOthersEditFieldValue: handleOthersSitesEditFieldValue,
      handleOnSaveOthersModal: handleOnSaveOthersSitesModal,
      inputMaxLength: 20,
      maxWidth: 375,
    };

    return defaultProps;
  };

  const getOthersSiteTypesModalProps = () => {
    const defaultProps = {
      handleOnCloseOthersModal: handleOnCloseOthersObjectivesModal,
      title: wizardLocale.about.othersObjectivesModal.title,
      buttonBack: wizardLocale.about.othersObjectivesModal.back,
      buttonSave: wizardLocale.about.othersObjectivesModal.save,
      inputPlaceholder: wizardLocale.about.othersObjectivesModal.placeholder,
      othersEditFieldValue: othersObjectivesEditFieldValue,
      handleOthersEditFieldValue: handleOthersObjectivesEditFieldValue,
      handleOnSaveOthersModal: handleOnSaveOthersObjectivesModal,
      inputMaxLength: 20,
      maxWidth: 442,
    };

    return defaultProps;
  };

  const getThemeInfoModalProps = () => {
    const defaultProps = {
      handleOnCloseThemeInfoModal,
      theme: getModalTheme(),
      buttonLabel: wizardLocale.tools.wordPressInstall.theme.use,
      handleChangeTheme: handleModalChangeTheme,
    };

    return defaultProps;
  };

  const getAnotherDomainModalProps = () => {
    const defaultProps = {
      prefixDomain: null,
      loading: sitesState.loading || sitesState.loadingTlds || sitesState.loadingDomainValidation,
      onClose: handleCloseModal,
      title: wizardLocale.configurations.modalAnotherDomain.title,
      subtitle: wizardLocale.configurations.modalAnotherDomain.subTitle,
      placeholder: wizardLocale.configurations.modalAnotherDomain.placeholder,
      buttonLabel: wizardLocale.configurations.modalAnotherDomain.buttonLabel,
      titleMessageErrorBr: wizardLocale.configurations.modalAnotherDomain.messageErrorBr,
      messageError: wizardLocale.configurations.modalAnotherDomain.messageError,
      tldMessageError: wizardLocale.configurations.modalAnotherDomain.tldMessageError,
      checkDomainAvailability,
      tldAvailability: sitesState.domainTldAvailability,
      userDomainList,
      handleChangeDomain,
    };

    return defaultProps;
  };

  const getDomainRegisteredModalProps = () => {
    const defaultProps = {
      domain: selectedDomain.label,
      domainAndSubdomain: selectedDomain.domainAndSubdomain,
      handleBack: backAnotherDomainModal,
      handleCloseModal,
      handleSelectChange: handleChangeDomain,
      title: wizardLocale.configurations.modalRegistered.title,
      domainLabel: wizardLocale.configurations.modalRegistered.domain,
      registered: wizardLocale.configurations.modalRegistered.registered,
      isCorrect: wizardLocale.configurations.modalRegistered.isCorrect,
      continueLabel: wizardLocale.configurations.modalRegistered.continue,
      incorrect: wizardLocale.configurations.modalRegistered.incorrect,
      backButton: wizardLocale.configurations.modalRegistered.backButton,
      continueButton: wizardLocale.configurations.modalRegistered.continueButton,
    };

    return defaultProps;
  };

  const getChoosePlanModalProps = () => {
    const defaultProps = {
      chosenPlan,
      handleCloseModal,
      selectedDomainPlan,
      setChosenPlan,
      singleDomainsPlans,
      multiDomainsPlans,
      title: wizardLocale.configurations.modalChoosePlan.title,
      subtitle: wizardLocale.configurations.modalChoosePlan.subtitle,
      tooltip: wizardLocale.configurations.modalChoosePlan.tooltip,
      mainDomain: wizardLocale.configurations.modalChoosePlan.mainDomain,
      otherDomains: wizardLocale.configurations.modalChoosePlan.otherDomains,
      noOtherDomains: wizardLocale.configurations.modalChoosePlan.noOtherDomains,
      domains: wizardLocale.configurations.modalChoosePlan.domains,
      domain: wizardLocale.configurations.modalChoosePlan.domain,
      choosePlan: wizardLocale.configurations.modalChoosePlan.choosePlan,
      onChoosePlan: wizardConfigurationsAnalytics.selectOtherPlanModalSelectPlan,
      withGatorSolutionsPlans,
    };

    return defaultProps;
  };

  const getSelectManualDnsModalProps = () => {
    const defaultProps = {
      domain: selectedDomain.label,
      domainDns: sitesState.domainDns,
      handleBack: backAnotherDomainModal,
      handleCloseModal,
      onContinue: handleContinueToNextStep,
      planDns: sitesState.planDns,
      title: wizardLocale.configurations.modalSelectDnsManual.title,
      descriptionOne: wizardLocale.configurations.modalSelectDnsManual.descriptionOne,
      descriptionTwo: wizardLocale.configurations.modalSelectDnsManual.descriptionTwo,
      question: wizardLocale.configurations.modalSelectDnsManual.question,
      placeholder: wizardLocale.configurations.modalSelectDnsManual.placeholder,
      labelButtonBack: wizardLocale.configurations.modalSelectDnsManual.labelButtonBack,
      labelButtonKeep: wizardLocale.configurations.modalSelectDnsManual.labelButtonKeep,
      step2Title: wizardLocale.configurations.modalSelectDnsManualStep2.title,
      step2Description: wizardLocale.configurations.modalSelectDnsManualStep2.description,
      step2TutorialLink: wizardLocale.configurations.modalSelectDnsManualStep2.tutorialLink,
      step2Replace: wizardLocale.configurations.modalSelectDnsManualStep2.replace,
      step2ActualDns: wizardLocale.configurations.modalSelectDnsManualStep2.actualDns,
      step2HgDns: wizardLocale.configurations.modalSelectDnsManualStep2.hgDns,
      step2Server1: wizardLocale.configurations.modalSelectDnsManualStep2.server1,
      step2Server2: wizardLocale.configurations.modalSelectDnsManualStep2.server2,
      step2GoToHost: wizardLocale.configurations.modalSelectDnsManualStep2.goToHost,
      step2WaitTime: wizardLocale.configurations.modalSelectDnsManualStep2.waitTime,
      step2WaitTimeStrong: wizardLocale.configurations.modalSelectDnsManualStep2.waitTimeStrong,
      step2Warning: wizardLocale.configurations.modalSelectDnsManualStep2.warning,
      step2LabelButtonBack: wizardLocale.configurations.modalSelectDnsManualStep2.labelButtonBack,
      step2LabelButtonContinue: wizardLocale.configurations.modalSelectDnsManualStep2.labelButtonContinue,
      step2Copied: wizardLocale.configurations.modalSelectDnsManualStep2.copied,
      onChangeProvider: wizardConfigurationsAnalytics.DNSManualModalSelectProvider,
      onContinueNextModal: wizardConfigurationsAnalytics.DNSManualModalContinue,
      onBackStep: wizardConfigurationsAnalytics.DNSManualModalSecondStepBack,
      onClickHelpLink: wizardConfigurationsAnalytics.DNSManualModalSecondStepHelp,
      onClickRedirect: wizardConfigurationsAnalytics.DNSManualModalSecondStepRedirect,
      onCopyServer: wizardConfigurationsAnalytics.DNSManualModalSecondStepCopy,
      onContinueNextStep: wizardConfigurationsAnalytics.DNSManualModalSecondStepContinue,
    };

    return defaultProps;
  };

  const getUpgradePlanModalProps = () => {
    const defaultProps = {
      onClose: handleCloseModal,
      plans: getActivePlans(),
      buttonClick: wizardConfigurationsAnalytics.upgradePlanModalButton,
    };

    return defaultProps;
  };

  const getModalDnsAutomaticProps = () => {
    const defaultProps = {
      button: wizardLocale.modalDns.button,
      descriptionOne: wizardLocale.modalDns.descriptionOne,
      descriptionTwo: wizardLocale.modalDns.descriptionTwo,
      link: wizardLocale.modalDns.link,
      onContinue: handleContinueToNextStep,
      title: wizardLocale.modalDns.title,
      onAccessSupportMaterial: wizardConfigurationsAnalytics.DNSAutomaticModalAccessSupportMaterial,
      onClickButton: wizardConfigurationsAnalytics.DNSAutomaticModalContinue,
    };

    return defaultProps;
  };

  const getAsaasCreateAccountModal = () => {
    const defaultProps = {
      title: asaasLocale.wizard.firstRow.modal.createSite.title,
      description: asaasLocale.wizard.firstRow.modal.createSite.description,
    };

    return defaultProps;
  };

  const getDnsInProgressModalProps = () => {
    const defaultProps = {
      onClose: handleCloseModal,
      buttonClick: handleContinueDnsInProgressModal,
    };

    return defaultProps;
  };

  return (
    <>
      {displayOthersSitesModal && (
        <OthersSiteTypesModal {...getOthersSitesTypesModalProps()} />
      )}

      {displayOthersObjectivesModal && (
        <OthersSiteTypesModal {...getOthersSiteTypesModalProps()} />
      )}

      {displayThemeInfoModal && (
        <ThemeInfoModal {...getThemeInfoModalProps()} />
      )}

      {showModal === modals.ANOTHER_DOMAIN && (
        <AnotherDomainModal {...getAnotherDomainModalProps()} />
      )}

      {showModal === modals.DOMAIN_REGISTERED && (
        <DomainRegisteredModal {...getDomainRegisteredModalProps()} />
      )}

      {showModal === modals.CHOOSE_PLAN && (
        <ChoosePlanModal {...getChoosePlanModalProps()} />
      )}

      {showModal === modals.SELECT_DNS && (
        <SelectManualDnsModal {...getSelectManualDnsModalProps()} />
      )}

      {showModal === modals.UPGRADE_PLAN && (
        <UpgradePlanModal {...getUpgradePlanModalProps()} />
      )}

      {showModal === modals.DNS_AUTOMATIC && (
        <ModalDnsAutomatic {...getModalDnsAutomaticProps()} />
      )}

      {(loadingVerifyIfSiteExist || loadingDefaultFilesExclude) && (
        <VerifyingSiteExistModal />
      )}

      {displayCreateAccountModal && (
        <AsaasCreateAccountModal {...getAsaasCreateAccountModal()} />
      )}

      {showModal === modals.DNS_IN_PROGRESS && (
        <DnsInProgressModal {...getDnsInProgressModalProps()} />
      )}

    </>
  );
};

export default WizardHandlerModals;
