import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Lock, Check, Lens } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import LinkButton from '@/components/Buttons/LinkButton';
import { PASSWORD_GENERATOR_URL } from '@/config/urls/passwordUrls';
import styles from './styles';

const PasswordCheckList = ({
  classes,
  t,
  showCheckList,
  upperAndLowercase,
  number,
  symbol,
  eightCharacters,
  sameInBothFields,
  haveBothFields = true,
  generatePasswordLink = true,
}) => (
  <>
    {showCheckList && (
      <div className={classes.checklistContainer}>
        <p className={classes.checklistTitle}>
          <Lock className={classes.lockIcon} />
          <span>{t('myData.changePassword.checklist.title')}</span>
        </p>
        <ul className={classes.checklist} data-id="password-checklist">
          <li
            data-checked={upperAndLowercase}
            className={classnames(classes.checkListItem, {
              [classes.checkListItemError]: !upperAndLowercase,
            })}
          >
            <span className={classes.iconWrapper}>{upperAndLowercase ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}</span>
            <span>{t('myData.changePassword.checklist.upperAndLowercase')}</span>
          </li>
          <li
            data-checked={number}
            className={classnames(classes.checkListItem, {
              [classes.checkListItemError]: !number,
            })}
          >
            <span className={classes.iconWrapper}>{number ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}</span>
            <span>{t('myData.changePassword.checklist.number')}</span>
          </li>
          <li
            data-checked={symbol}
            className={classnames(classes.checkListItem, {
              [classes.checkListItemError]: !symbol,
            })}
          >
            <span className={classes.iconWrapper}>{symbol ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}</span>
            <span>{t('myData.changePassword.checklist.symbol')}</span>
          </li>
          <li
            data-checked={eightCharacters}
            className={classnames(classes.checkListItem, {
              [classes.checkListItemError]: !eightCharacters,
            })}
          >
            <span className={classes.iconWrapper}>{eightCharacters ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}</span>
            <span>{t('myData.changePassword.checklist.eightCharacters')}</span>
          </li>
          <li
            data-checked={sameInBothFields}
            className={classnames(classes.checkListItem, {
              [classes.checkListItemError]: !sameInBothFields,
            })}
          >
            {haveBothFields && (
              <>
                <span className={classes.iconWrapper}>{sameInBothFields ? <Check className={classes.checkIcon} /> : <Lens className={classes.lensIcon} />}</span>
                <span>{t('myData.changePassword.checklist.sameInBothFields')}</span>
              </>
            )}
          </li>
        </ul>
      </div>
    )}
    {generatePasswordLink && (
      <p className={classes.generatorText}>
        <Trans i18nKey="myData.changePassword.generatorText">
          0
          <LinkButton to={PASSWORD_GENERATOR_URL} className={classes.generatorLink} target="_blank">1</LinkButton>
          2
        </Trans>
      </p>
    )}
  </>
);

PasswordCheckList.propTypes = {
  showCheckList: PropTypes.bool.isRequired,
  upperAndLowercase: PropTypes.bool.isRequired,
  number: PropTypes.bool.isRequired,
  symbol: PropTypes.bool.isRequired,
  eightCharacters: PropTypes.bool.isRequired,
  sameInBothFields: PropTypes.bool,
};

export default withI18n()(withStyles(styles)(PasswordCheckList));
