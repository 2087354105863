import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    padding: 16px;
    padding-right: 42px;
  `}
`;

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
  `}
`;

export const ModalCaution = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 24px;

  p {
    margin: 0;
  }

  strong {
    display: block;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const ModalSelectWrapper = styled.div`
  margin-left: 42px;
  margin-top: 16px;
`;

export const ModalTicketWarning = styled.div`
  margin-left: 42px;
  margin-top: 16px;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
