import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      color: ${theme.v2.colors.brand.primaryBlue};
      font-weight: ${theme.v2.font.weights.regular};
      text-decoration: underline;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin: 0px 0px;
    }
    button {
      font-size: ${theme.v2.font.sizes.xxxs};
    }
    p {
      color: ${theme.v2.colors.neutral.lowDark};
      font-size: ${theme.v2.font.sizes.xxxs};
    }
  `}
`;

export const AlertContent = styled.div`
  ${({ theme }) => `
    button {
      background: none;
      border: none;
      color: ${theme.v2.colors.brand.primaryBlue};
      cursor: pointer;
      font-weight: ${theme.v2.font.weights.medium};
      padding: 0;
      text-decoration: underline;
      transition: 0.2s;

      &:hover{
        color: ${theme.v2.colors.brand.primaryLight};
      }
    }
  `}
`;
