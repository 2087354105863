export const STATUS = {
  nextDueDate: 'nextDueDate',
  overDueDate: 'overDueDate',
  active: 'active',
  inProgress: 'in_progress',
};

export const DAYS = {
  toDue: 5,
};
