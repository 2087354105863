import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORIES: {
    CHANGE_PAYMENT_METHOD: 'alterar_pagamento',
    CHANGE_PLAN: 'alterar_plano',
    CANCEL_SUBSCRIPTION: 'cancelar_assinatura',
    EXECUTE_PAYMENT_IN_MANAGE_SUBSCRIPTIONS: 'pague_agora',
    EXECUTE_PAYMENT_IN_MANAGE_INVOICES: 'pagar_fatura',
    MANAGE_SUBSCRIPTIONS: 'portal_cliente_assinaturas',
    CANCEL_RETENTION: 'Retenção_no_fluxo_de_cancelamento',
    EXECUTE_PAYMENT_IN_LIST_SUBSCRIPTIONS: 'pagar_fatura_lista',
    EXECUTE_CREDIT_CARD_FLOW: 'Incentivo_CC_Portal',
  },
  ACTIONS: {
    CLICK: 'click',
    CLICK_FILTRO: 'click_filtro',
    PAGEVIEW: 'pageview',
    FEEDBACK: 'feedback',
    RETENTION: 'Tela_argumentos_de_retenção',
    BENEFIT: 'Tela_oferta_de_desconto',
    BENEFIT_MONTH: 'Tela_oferta_de_desconto_mensal',
    CHANGE_PAYMENT_SUCCESS_CC_REGISTER: 'Modal_Incentivo_CC',
    CHANGE_PAYMENT_SELECT_PRODUCTS: 'Modal_Seleção_Planos',
  },
  DISCOUNT_BANNER: {
    ACTION_LABEL: {
      DISPLAY: ' - Exibir banner de desconto',
      LINK_CLICK: ' - Link Oba quero desconto',
      CLOSE: ' - Botao fechar',
    },
    ORIGINS: {
      SITES: 'Sites',
      SUBSCRIPTION_LIST: 'Lista assinaturas',
      SUBSCRIPTION: 'Gerenciamento de assinatura',
    },
    EVENT_LABELS: {
      DISPLAY: 'Ação: Exibir banner',
      LINK_CLICK: 'Ação: Link Oba quero desconto',
      CLOSE: 'Ação: Fechar banner',
    },
  },
  SAVERS_BANNER: {
    ACTION_LABEL: {
      LINK_CLICK: 'Sites - Savers Banner - Link Pagar fatura com desconto',
      CLOSE: 'Sites - Savers Banner - Botao fechar',
    },
    EVENT_LABELS: {
      LINK_CLICK: 'Ação: Pagar fatura com desconto',
      CLOSE: 'Ação: Fechar banner',
    },
  },
  RENEWAL_MODAL: {
    CATEGORIES: {
      DISPLAY: 'Modal Renovação - Exibir',
      DISPLAY_BY_URL: 'Modal Renovação - Exibir via URL',
      DISPLAY_DISCOUNT_BLOCK: 'Ação: Exibir desconto',
      CLICK_BUTTON: 'Modal Renovação - Confirmar Ativação',
      CLOSE: 'Modal Renovação - Botao fechar',
    },
    EVENT_LABELS: {
      DISPLAY: 'Ação: Exibir modal',
      DISPLAY_BY_URL: 'Ação: Exibir modal via URL',
      DISPLAY_DISCOUNT_BLOCK: 'Ação: Exibir desconto',
      CLICK_BUTTON: 'Ação: Confirmar Ativação',
      CLOSE: 'Ação: Fechar modal',
    },
  },
  ACCEPT_OFFER_SUCCESS_MODAL: {
    CATEGORIES: {
      DISPLAY: 'Modal Oferta Renovação Aceita - Exibir sem rascunho',
      DISPLAY_WITH_DRAFT: 'Modal Oferta Renovação Aceita - Exibir com rascunho',
      CLICK_BUTTON_RENEWAL_AFTER: 'Modal Oferta Renovação Aceita - Renova depois',
      CLICK_BUTTON_RENEWAL_NOW: 'Modal Oferta Renovação Aceita - Renovar agora mesmo',
      CLICK_BUTTON_PAY_NOW: 'Modal Oferta Renovação Aceita - Pagar agora mesmo',
      CLOSE: 'Modal Oferta Renovação Aceita - Botao fechar',
    },
    EVENT_LABELS: {
      DISPLAY: 'Ação: Exibir modal com rascunho',
      DISPLAY_WITH_DRAFT: 'Ação: Exibir modal sem rascunho',
      CLICK_BUTTON_RENEWAL_AFTER: 'Ação: Renova depois',
      CLICK_BUTTON_RENEWAL_NOW: 'Ação: Renovar agora mesmo',
      CLICK_BUTTON_PAY_NOW: 'Ação: Pagar agora mesmo',
      CLOSE: 'Ação: Fechar modal',
    },
  },
  CHANGE_PAYMENT_METHOD_MODAL: {
    SUCCESS_REGISTER_NO_THANKS: 'Não_Obrigado',
    SUCCESS_REGISTER_CHANGE_TO_CC: 'Alterar_para_CC',
    SUCCESS_REGISTER_CLOSE_MODAL: 'Fechar_Modal',
    SELECT_PRODUCTS_CANCEL: 'Cancelar',
    SELECT_PRODUCTS_CHANGE_TO_CC: 'Alterar_Para_CC',
    SELECT_PRODUCTS_CLOSE_MODAL: 'Fechar_Modal',
  },
};

const subscriptionsMenuClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: 'clique_acessar_assinaturas',
});

const activeRenewalModalClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.RENEWAL_MODAL.CATEGORIES.CLICK_BUTTON}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.RENEWAL_MODAL.EVENT_LABELS.CLICK_BUTTON,
});

const activeRenewalModalDisplay = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.RENEWAL_MODAL.CATEGORIES.DISPLAY}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.RENEWAL_MODAL.EVENT_LABELS.DISPLAY,
});

const activeRenewalModalDisplayByURL = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.RENEWAL_MODAL.CATEGORIES.DISPLAY_BY_URL}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.RENEWAL_MODAL.EVENT_LABELS.DISPLAY_BY_URL,
});

const activeRenewalModalDisplayDiscountBlock = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.RENEWAL_MODAL.CATEGORIES.DISPLAY_DISCOUNT_BLOCK}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.RENEWAL_MODAL.EVENT_LABELS.DISPLAY_DISCOUNT_BLOCK,
});

const activeRenewalModalClose = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.RENEWAL_MODAL.CATEGORIES.CLOSE}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.RENEWAL_MODAL.EVENT_LABELS.CLOSE,
});

const bannerDiscountClick = origin => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.DISCOUNT_BANNER.ORIGINS[origin]}${EVENTS.DISCOUNT_BANNER.ACTION_LABEL.LINK_CLICK}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.DISCOUNT_BANNER.EVENT_LABELS.LINK_CLICK,
});

const bannerDiscountDisplay = origin => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.DISCOUNT_BANNER.ORIGINS[origin]}${EVENTS.DISCOUNT_BANNER.ACTION_LABEL.DISPLAY}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.DISCOUNT_BANNER.EVENT_LABELS.DISPLAY,
});

const bannerDiscountClose = origin => collect({
  event: EVENTS.NAME,
  gaEventCategory: `${EVENTS.DISCOUNT_BANNER.ORIGINS[origin]}${EVENTS.DISCOUNT_BANNER.ACTION_LABEL.CLOSE}`,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.DISCOUNT_BANNER.EVENT_LABELS.CLOSE,
});

const bannerSaversClose = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.SAVERS_BANNER.ACTION_LABEL.CLOSE,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.SAVERS_BANNER.EVENT_LABELS.CLOSE,
});

const bannerSaversClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.SAVERS_BANNER.ACTION_LABEL.LINK_CLICK,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.SAVERS_BANNER.EVENT_LABELS.LINK_CLICK,
});

const subscriptionsPageView = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.PAGEVIEW,
  gaEventLabel: 'acessar_assinaturas',
});

const subscriptionsFilterClick = (filterOptions) => {
  const filtername = filterOptions
    .reduce((filters, filter) => (filter.checked
      ? `${filters},${filter.label.toLowerCase()}`
      : filters), '')
    .replace(/^,/, '');

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
    gaEventAction: EVENTS.ACTIONS.CLICK_FILTRO,
    gaEventLabel: `clique_filtro_[${filtername}]`,
  });
};

const manageSubscriptionClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: 'clique_acessar_gerenciar_assinaturas',
});

const cancelRenewalClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: 'clique_acessar_cancelar_renovação',
});

const cancelRenewalPageView = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.PAGEVIEW,
  gaEventLabel: 'acessar_cancelar_renovação',
});

const cancelRenewalReasonFeedback = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.FEEDBACK,
  gaEventLabel: 'preencher_campo_motivo',
});

const cancelRenewalConfirmationClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: 'clique_confirmar_cancelamento_renovação',
});

const activeRenewalClick = (way) => {
  const gaEventLabel = {
    modal: 'desistiu_cancelar_depois_da_pesquisa',
    lista: 'desistiu_cancelar_depois_visualizar_lista',
    resumo_assinaturas: 'desistiu_cancelar_indo_resumo_assinaturas',
  }[way];

  return collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
    gaEventAction: EVENTS.ACTIONS.CLICK,
    gaEventLabel,
  });
};

const cancelSubscriptionClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CANCEL_SUBSCRIPTION,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const cancelSubscriptionPageView = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CANCEL_SUBSCRIPTION,
  gaEventAction: EVENTS.ACTIONS.PAGEVIEW,
  gaEventLabel,
});

const cancelSubscriptionModalPageView = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.PAGEVIEW,
  gaEventLabel,
});

const changePaymentMethodClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CHANGE_PAYMENT_METHOD,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const executePaymentClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_PAYMENT_IN_MANAGE_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const executePaymentInSubscriptionsListClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_PAYMENT_IN_LIST_SUBSCRIPTIONS,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const executePaymentInvoiceManagerClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_PAYMENT_IN_MANAGE_INVOICES,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const changePlanClick = gaEventLabel => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CHANGE_PLAN,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel,
});

const retentionAccessEmails = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CANCEL_RETENTION,
  gaEventAction: EVENTS.ACTIONS.RETENTION,
  gaEventLabel: 'Acessar_meus_e-mails',
});

const retentionCancelSignature = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.CANCEL_RETENTION,
  gaEventAction: EVENTS.ACTIONS.RETENTION,
  gaEventLabel: 'Cancelar_assinatura',
});

const getBenefitAction = (cycle, isNewPage) => {
  if (isNewPage) {
    return 'Tela_oferta_de_desconto_new';
  }
  return ({
    Monthly: 'Tela_oferta_de_desconto_mensal',
  })[cycle] || 'Tela_oferta_de_desconto';
};

const benefitAccept = (retentionCycle, isNewPage = false) => {
  const benefitAcceptLabel = cycle => ({
    Quarterly: 'Aceitar_oferta_de_desconto_trimestral',
    'Semi-Annually': 'Aceitar_oferta_de_desconto_semestral',
    Annually: 'Aceitar_oferta_de_desconto_anual',
    Biennially: 'Aceitar_oferta_de_desconto_bienal',
    Triennially: 'Aceitar_oferta_de_desconto_trienal',
  })[cycle] || 'Aceitar_oferta_de_desconto';

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.CANCEL_RETENTION,
    gaEventAction: getBenefitAction(retentionCycle, isNewPage),
    gaEventLabel: benefitAcceptLabel(retentionCycle),
  });
};

const benefitDecline = (retentionCycle, isNewPage = false) => {
  const benefitDeclineLabel = cycle => ({
    Quarterly: 'Cancelar_assinatura_trimestral',
    'Semi-Annually': 'Cancelar_assinatura_semestral',
    Annually: 'Cancelar_assinatura_anual',
    Biennially: 'Cancelar_assinatura_bienal',
    Triennially: 'Cancelar_assinatura_trienal',
  })[cycle] || 'Cancelar_assinatura';

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.CANCEL_RETENTION,
    gaEventAction: getBenefitAction(retentionCycle, isNewPage),
    gaEventLabel: benefitDeclineLabel(retentionCycle),
  });
};

const benefitAccess = (retentionCycle) => {
  const benefitAccessAction = cycle => ({
    Monthly: 'Tela_oferta_de_desconto_mensal',
  })[cycle] || 'Tela_oferta_de_desconto';

  const benefitAccessLabel = cycle => ({
    Quarterly: 'Page_view_trimestral',
    'Semi-Annually': 'Page_view_semestral',
    Annually: 'Page_view_anual',
    Biennially: 'Page_view_bienal',
    Triennially: 'Page_view_trienal',
  })[cycle] || 'Page_view';

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.CANCEL_RETENTION,
    gaEventAction: benefitAccessAction(retentionCycle),
    gaEventLabel: benefitAccessLabel(retentionCycle),
  });
};

const invoicesAlertDisplayed = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Banner portal do cliente faturas',
  gaEventAction: 'Solução - Banner portal do cliente faturas - Alerta faturas apresentado com sucesso',
  gaEventLabel: 'Solução - Banner portal do cliente faturas - Alerta faturas apresentado com sucesso',
});

const invoicesAlertClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: 'Solução - Banner portal do cliente faturas',
  gaEventAction: 'Solução - Banner portal do cliente faturas - Alerta faturas apresentado com sucesso',
  gaEventLabel: 'Solução - Banner portal do cliente faturas - clique no banner faturas',
});

const acceptedOfferSuccessModalPayNowOption = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.CLICK_BUTTON_PAY_NOW,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.EVENT_LABELS.CLICK_BUTTON_PAY_NOW,
});

const acceptedOfferSuccessModalRenewalNowOption = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.CLICK_BUTTON_RENEWAL_NOW,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.EVENT_LABELS.CLICK_BUTTON_RENEWAL_NOW,
});

const acceptedOfferSuccessModalRenewalAfterOption = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.CLICK_BUTTON_RENEWAL_AFTER,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.EVENT_LABELS.CLICK_BUTTON_RENEWAL_AFTER,
});

const acceptedOfferSuccessModalDisplay = ({ hasDraft }) => collect({
  event: EVENTS.NAME,
  gaEventCategory: hasDraft ? EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.DISPLAY_WITH_DRAFT : EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.DISPLAY,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.EVENT_LABELS.CLOSE,
});

const acceptedOfferSuccessModalClose = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.CATEGORIES.CLOSE,
  gaEventAction: EVENTS.ACTIONS.CLICK,
  gaEventLabel: EVENTS.ACCEPT_OFFER_SUCCESS_MODAL.EVENT_LABELS.CLOSE,
});

const successCcRegisterCtaNoThanks = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SUCCESS_CC_REGISTER,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SUCCESS_REGISTER_NO_THANKS,
});

const successCcRegisterCtaChangeToCc = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SUCCESS_CC_REGISTER,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SUCCESS_REGISTER_CHANGE_TO_CC,
});

const successCcRegisterCloseModal = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SUCCESS_CC_REGISTER,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SUCCESS_REGISTER_CLOSE_MODAL,
});

const selectProductsCtaChangeToCc = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SELECT_PRODUCTS,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SELECT_PRODUCTS_CHANGE_TO_CC,
});

const selectProductsCtaCancel = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SELECT_PRODUCTS,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SELECT_PRODUCTS_CANCEL,
});

const selectProductsCloseModal = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORIES.EXECUTE_CREDIT_CARD_FLOW,
  gaEventAction: EVENTS.ACTIONS.CHANGE_PAYMENT_SELECT_PRODUCTS,
  gaEventLabel: EVENTS.CHANGE_PAYMENT_METHOD_MODAL.SELECT_PRODUCTS_CLOSE_MODAL,
});

const analytics = {
  subscriptions: {
    activeRenewalModalDisplay,
    activeRenewalModalClick,
    activeRenewalModalClose,
    activeRenewalModalDisplayDiscountBlock,
    activeRenewalModalDisplayByURL,
    bannerDiscountDisplay,
    bannerDiscountClick,
    bannerDiscountClose,
    bannerSaversClick,
    bannerSaversClose,
    subscriptionsMenuClick,
    subscriptionsPageView,
    subscriptionsFilterClick,
    manageSubscriptionClick,
    cancelRenewalClick,
    cancelRenewalPageView,
    cancelRenewalReasonFeedback,
    cancelRenewalConfirmationClick,
    activeRenewalClick,
    cancelSubscriptionClick,
    cancelSubscriptionPageView,
    cancelSubscriptionModalPageView,
    changePaymentMethodClick,
    executePaymentClick,
    executePaymentInSubscriptionsListClick,
    executePaymentInvoiceManagerClick,
    changePlanClick,
    retentionAccessEmails,
    retentionCancelSignature,
    benefitAccept,
    benefitDecline,
    benefitAccess,
    acceptedOfferSuccessModalClose,
    acceptedOfferSuccessModalDisplay,
    acceptedOfferSuccessModalRenewalAfterOption,
    acceptedOfferSuccessModalRenewalNowOption,
    acceptedOfferSuccessModalPayNowOption,
  },
  changePaymentModal: {
    successCcRegisterCtaNoThanks,
    successCcRegisterCtaChangeToCc,
    successCcRegisterCloseModal,
    selectProductsCtaChangeToCc,
    selectProductsCtaCancel,
    selectProductsCloseModal,
  },
  invoicesAlertDisplayed,
  invoicesAlertClick,
};

export default analytics;
