import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 730px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      height: 100%;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    line-height: ${theme.v2.font.lineHeight.text};
    text-align: center;
  `}
`;

export const Form = styled.form`
  width: 240px;
`;

export const ResendCodeMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const ActionInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      height: 100%;
      justify-content: flex-end;
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    width: 100%;
  `}
`;

export const AlertWrapper = styled.div`
  max-width: 730px;
`;

export const ConfirmActionText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    text-align: center;
  `}
`;
