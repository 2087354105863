import styled, { css } from 'styled-components';

export const Container = styled.section`
${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: column;
    padding: 40px 20px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 32px 16px;
    }
  `}
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxxxxl};
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 40px;
    margin: 0px 0px 56px 0px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      margin: 0px 0px 42px 0px;
      max-width: 388px;
    }

    @media (max-width: 360px) {
      max-width: 288px;
    }
  `}
`;

export const BenefitsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px;
  justify-content: space-around;
  margin: 0px 0px 80px 0px;
  padding:0px;
  width: 100%;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 25px;
    margin: 0px 0px 40px 0px;
  }
`;

export const BenefitWrapper = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const BenefitTitle = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 16px 0px 0px 0px;
    text-align: center;

    @media (min-width: 1300px) {
      max-width: 308px;
    }

    @media (min-width: 1920px) {
      max-width: unset;
    }

    @media (max-width: 768px) {
      font-size: 20px
    }
  `}
`;

export const BenefitDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.font.weights.regular};
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    max-width:320px;
    text-align: center;
  `}
`;


export const CodeguardBanner = styled.img`
  margin-bottom: 16px;
`;

export const VideoTitle = styled.h5`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 30px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 40px;
    margin: 0px 0px 40px 0px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      font-size: 24px;
      line-height: 32px;
    }
  `}
`;

export const VideoDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
        font-size: 14px;
    }
  `}
`;

export const VideoContainer = styled.div`
${({ theme }) => css`
    width: 684px;
    @media (max-width: ${theme.v1.breakpoints.md}) {
        max-width: 90%;
    }
  `}
`;

export const VideoLayer = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

export const Video = styled.iframe`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const RowButtonWrapper = styled.div`
${({ theme }) => css`
    margin: 16px 0px 40px 0px;
    @media (max-width: ${theme.v1.breakpoints.md}) {
      margin: 16px 0px 30px 0px;
    }
  `}
`;
