import {
  Button, TextField, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { Add as IncrementIcon, Remove as SubtractIcon } from '@material-ui/icons/';
import classnames from 'classnames';
import { Trans, withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import IntegerMaskedInput from '@/components/Fields/InputMasks/IntegerMaskedInput';
import styles from './styles';


const IncrementAccounts = ({
  classes, label, min, max, amount, setAmount, showSeparator, domainObject = null, setTyping, isAUpgrade = true,
}) => {
  const inputRef = useRef(null);


  const handleChange = ({ increment, value }) => {
    if (setTyping) {
      setTyping(true);
    }
    if (value && value !== '') {
      if (value > max || value < min) {
        setTyping(false);
        if (!isAUpgrade && value > max && inputRef) {
          setAmount(parseInt(max, 10));
          inputRef.current.blur();
        } else if (!isAUpgrade && value < min && inputRef) {
          setAmount(parseInt(min, 10));
          inputRef.current.blur();
        }
      } else {
        setAmount(parseInt(value, 10));
      }
    } else if (increment) {
      const newAmount = parseInt(amount, 10) + 1;
      setAmount(newAmount >= max ? max : newAmount);
    } else if (increment === false) {
      const newAmount = parseInt(amount, 10) - 1;
      setAmount(newAmount <= min ? min : newAmount);
    } else {
      setAmount(amount);
    }
  };

  const renderDecrementButton = () => (
    <Button
      className={classnames(classes.button, classes.decrement, !isAUpgrade ? classes.newButton : null)}
      onClick={() => handleChange({ increment: false })}
      disabled={amount === min}
      data-testid="decrementButton"
    >
      <SubtractIcon className={classnames(!isAUpgrade ? classes.newIcon : classes.icon, { [classes.disabledIcon]: amount === min })} />
    </Button>
  );

  return (
    <>
      <Typography className={isAUpgrade ? classes.incrementLabel : classes.newIncrementLabel} data-testid="mailBoxesLabel">{label}</Typography>
      <div className={isAUpgrade ? classes.incrementWrapper : classes.newIncrementWrapper}>

        {(amount === min && (domainObject && isAUpgrade && domainObject.used_accounts > 1))
          ? (
            <Tooltip
              title={(
                <Trans i18nKey="proEmail.upgradePlan.upgradeMailBoxMinExplanation">
                  <>0</>
                  {min}
                  <>2</>
                </Trans>
              )}
            >
              <div>
                {renderDecrementButton()}
              </div>
            </Tooltip>
          )
          : renderDecrementButton()
        }

        <TextField
          inputRef={inputRef}
          value={amount}
          onChange={e => handleChange({ value: e.target.value })}
          name="amount"
          variant="outlined"
          className={isAUpgrade ? classes.amountBlock : classes.newAmountBlock}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: isAUpgrade ? classes.cssOutlinedInput : classes.newInput,
              notchedOutline: classes.fieldset,
            },
            'data-testid': 'inputAmount',
            inputComponent: IntegerMaskedInput,
          }}
        />

        <Button
          className={classnames(classes.button, classes.increment, !isAUpgrade ? classes.newButton : null)}
          onClick={() => handleChange({ increment: true })}
          disabled={amount === max}
          data-testid="incrementButton"
        >
          <IncrementIcon className={classnames(!isAUpgrade ? classes.newIcon : classes.icon, { [classes.disabledIcon]: amount === max })} />
        </Button>
      </div>

      {showSeparator
        && <div className={classes.separator} />
      }
    </>
  );
};

IncrementAccounts.propTypes = {
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  showSeparator: PropTypes.bool,
};

IncrementAccounts.defaultValues = {
  showSeparator: true,
};

export default withI18n()(withStyles(styles)(IncrementAccounts));
