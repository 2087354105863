const styles = theme => ({
  row: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.color.silver}`,
    borderTop: `1px solid ${theme.color.silver}`,
    display: 'flex',
    marginLeft: '24px',
    padding: '10px 0px',
    width: 'calc(100% - 24px - 21px)',
  },
  item: {
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '17px',
  },
  type: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 'calc(71px + 28px)',
  },
  name: {
    minWidth: 'calc(152px + 38px)',
    maxWidth: 'calc(152px + 38px)',

    [theme.breakpoints.down(1380)]: {
      minWidth: 'calc(76px + 28px)',
      maxWidth: 'calc(76px + 28px)',
    },
  },
  class: {
    minWidth: 'calc(60px + 42px)',
    maxWidth: 'calc(60px + 42px)',

    [theme.breakpoints.down(1380)]: {
      minWidth: 'calc(30px + 42px)',
      maxWidth: 'calc(30px + 42px)',
    },
  },
  ttl: {
    minWidth: 'calc(77px + 23px)',
    maxWidth: 'calc(77px + 23px)',

    [theme.breakpoints.down(1135)]: {
      minWidth: 'calc(35px + 23px)',
      maxWidth: 'calc(35px + 23px)',
    },
  },
  value: {
    minWidth: 'calc(231px + 44px )',
    maxWidth: 'calc(231px + 44px )',

    [theme.breakpoints.down(1380)]: {
      minWidth: 'calc(178px + 44px)',
      maxWidth: 'calc(178px + 44px)',
    },

    [theme.breakpoints.down(1200)]: {
      minWidth: 'calc(108px + 44px)',
      maxWidth: 'calc(108px + 44px)',
    },
  },
  firstAction: {
    marginLeft: 'auto',
  },
  actions: {
    minWidth: 'calc(92px + 16px)',
  },
  wrapperData: {
    '& span': {
      fontWeight: 400,
    },
  },
  tableButton: {
    color: theme.color.indigo,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'unset',

    '& svg': {
      marginRight: 10,
      width: 16,
    },
  },
  chipElement: {
    height: 21,
    '& span': {
      userSelect: 'text',
    },
  },
  trunkateData: {
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default styles;
