import PropTypes from 'prop-types';

export const FinishSwitchPaymentMethodModalProps = {
  cancelRenewalDescriptionLabel: PropTypes.string,
  cancelRenewalLabel: PropTypes.string,
  cancelImmediateDescriptionLabel: PropTypes.string,
  cancelImmediateLabel: PropTypes.string,
  continueButtonLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  onCloseClick: PropTypes.func,
  onContinueButtonClick: PropTypes.func,
  onQuitButtonClick: PropTypes.func,
  quitButtonLabel: PropTypes.string,
  titleLabel: PropTypes.string,
};
