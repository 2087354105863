import styled, { css } from 'styled-components';
import { Link } from '@material-ui/core';

export const DomainItemMessageWrapper = styled.p`
`;

export const MessageLink = styled(Link)`
  ${({ theme }) => css`
  @media (min-width: ${theme.breakpoints.md}) {
    text-decoration: underline;
  }
  `}
`;
