import styled, { css } from 'styled-components';
import colorfulLine from '@/media/icons/colorfulLine.svg';

export const Card = styled.div`
  ${({ theme, featured }) => css`
    background-image: ${featured ? `url(${colorfulLine})` : undefined};
    background-size: cover;
    border-radius: 4px;
    border: ${featured ? '0px' : `1px solid ${theme.v1.color.line.wizard}`};
    display: flex;
    flex-direction: row;
    height: auto;
    max-width: 498px;
    position: relative;
    width: 100%;
  `}
`;

export const CardContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 4px;
    display:flex;
    flex-direction: column;
    margin: 5px;
    padding: 74px 19px 32px 19px;
    width: 100%;
  `}
`;

export const CardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 16px 0px 0px;
    text-align: center;
  `}
`;

export const CardSubtitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 8px 0px 0px;
    max-width: 265px;
    text-align: center;
  `}
`;

export const CardDescription = styled.h5`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 16px 0px;
    min-height: 54px;
    text-align: center;
    span {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const WithoutDesc = styled.h5`
    margin: 16px 0px;
    min-height: 10px;
    text-align: center;
`;

export const CardSeparator = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.canceledPure};
    height: 1px;
    margin: 24px auto 16px auto;
    max-width: 265px;
    width: 100%;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin: 24px auto;
    }
  `}
`;

export const CardWarningSeparator = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.canceledPure};
    height: 1px;
    margin: 0px auto 16px auto;
    width: 100%;
  `}
`;

export const ShowMoreWrapper = styled.div`
  ${({ theme, open }) => css`
    align-items: center;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    justify-content: center;
    letter-spacing: 0.01px;
    line-height: 22px;
    padding: ${open ? '0px 0px 24px 0px' : '0px'};
    transition: padding 200ms ease-in-out;
      
    & > a {
      color: ${theme.v2.colors.brand.primaryPure};
      text-decoration: none;
    }
    
    & svg {
      fill: transparent;
      margin-left: 6px;
      max-width: 0px;
      overflow: hidden;
      stroke: transparent;
      transform: ${open ? 'rotate(-90deg)' : 'rotate(90deg)'};
      transition: fill 150ms ease-in-out, stroke 150ms ease-in-out, width 150ms ease-in-out;
    }

    &:hover {
      svg {
        fill: ${theme.v2.colors.brand.primaryPure};
        max-width: 100px;
        stroke: ${theme.v2.colors.brand.primaryPure};
      }
    }

    > span { 
      @media (max-width: ${theme.v2.breakpoints.md}) {
        display: none;
      }
    }

    @media (min-width: ${theme.v2.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ open, theme }) => css`
    display: flex;
    flex-direction: column;
    transition: max-height 200ms ease-in-out, padding 200ms ease-in-out;
    flex-grow: 1;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      max-height: ${open ? '1000px' : '0px'};
      overflow: hidden;
    }
  `}
`;

export const ListIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 40px;
  justify-content: space-between;
`;

export const ListIcon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ListIconImage = styled.img`
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
`;

export const LabelWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0px 0px 16px 0px;
`;

export const FeaturesListIcon = styled.div`
  padding-right: 8px;
  svg {
    width: 19px;
  }
`;


export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: inline;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0px;
    line-height: 19px;
    margin: 0;

    > span { 
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const ActionWrapper = styled.div`
  ${({ featured }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      width: ${featured ? '100%' : 'auto'};
    }
  `}
`;

export const WarningIconWrapper = styled.div`
  margin: 0px 8px 0px 0px;
`;

export const WarningWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 32px;
  width: 100%;
  div {
    text-transform: uppercase;
  }
`;

export const PreList = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    margin-bottom: 24px;
    text-align: center;
  `}
`;
