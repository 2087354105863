const styles = () => ({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    flexShrink: 0,
    padding: '24px 30px 0 30px',
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    margin: 0,
  },
});

export default styles;
