import React from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useParams } from 'react-router';
import EmailsContextProvider from '@/contexts/Emails/context';
import styles from './styles';
import TitanUpgradeWrapper from './TitanUpgradeWrapper';

const TitanUpgrade = () => {
  const { domain } = useParams();

  return (
    <EmailsContextProvider>
      <TitanUpgradeWrapper domain={domain} />
    </EmailsContextProvider>
  );
};

export default withI18n()(withStyles(styles)(TitanUpgrade));
