import axios from 'axios';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import {
  LOGOUT_URL,
  WHMCS_JWT_PUBLIC_KEY_URL,
  WHMCS_JWT_REFRESH_TOKEN_URL,
} from '@/config/whmcsUrls';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import { authActions } from '@/redux/modules';
import logger from '@/utils/logger';


export const refreshToken = async (auth, actions = {}) => {
  const { onSetJwtToken, onSetRefreshToken, dispatch } = actions;
  let { jwt: jwtToken, refreshToken: newRefreshToken } = auth;

  const cookieJWT = Cookies.get('hg-service-token');
  if (cookieJWT) {
    jwtToken = cookieJWT;
  }

  const logoutFlow = () => {
    const enableLogoutModal = Cookies.get(TEMPLATE_COOKIES.ENABLE_LOGOUT_MODAL);
    if (enableLogoutModal === 'true') {
      dispatch && dispatch(authActions.tokens.jwt.errorDuringRefresh(true));
    } else {
      window.location.href = LOGOUT_URL;
    }
  };

  try {
    const key = Cookies.get('whmcs-public-key');

    onSetJwtToken && onSetJwtToken(jwtToken);
    onSetRefreshToken && onSetRefreshToken(newRefreshToken);

    if (undefined === key) {
      const res = await axios.get(WHMCS_JWT_PUBLIC_KEY_URL);
      const { key } = res.data;

      Cookies.set('whmcs-public-key', key, { expires: 1 });// expires in one day
    }

    jwt.verify(jwtToken, key, { algorithms: ['RS256'] });
  } catch (err) {
    const hasAllData = auth.refreshToken && auth.token && auth.jwt;

    if ((err instanceof jwt.TokenExpiredError) || (hasAllData)) {
      const newToken = await axios.post(WHMCS_JWT_REFRESH_TOKEN_URL(auth.refreshToken), {}, {
        headers: {
          Authorization: auth.token,
          'X-Service-Token': `Bearer ${auth.jwt}`,
        },
      });

      newRefreshToken = newToken.data.refresh_token;
      jwtToken = newToken.data.token;

      if (jwtToken && newRefreshToken) {
        let domain = window.location.hostname;
        if (domain.indexOf('cliente') > -1) {
          domain = domain.split('cliente').pop();
        }


        onSetJwtToken && onSetJwtToken(jwtToken);
        Cookies.set('hg-service-token', jwtToken, { domain, expires: 1 });// expires in one day

        onSetRefreshToken && onSetRefreshToken(newRefreshToken);
        Cookies.set('hg-refresh-token', newRefreshToken, { domain, expires: 1 });// expires in one day
      } else {
        logger.error('Error at WHMCS_JWT_REFRESH_TOKEN_URL (thunk)', err);
        logoutFlow();
      }
    } else {
      logger.error('Error at refreshToken (thunk)', err);
      logoutFlow();
    }
  }

  return {
    ...auth,
    jwt: jwtToken,
    refreshToken: newRefreshToken,
  };
};
