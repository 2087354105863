import {
  ACCESS_CPANEL_ARTICLE, ADD_DOMAIN_TO_HOST_ARTICLE, CLIENT_AREA_FEATURES_ARTICLE, CONFIGURE_DOMAIN_DNS_ARTICLE, CREATE_WORDPRESS_SITE_ARTICLE,
} from '@/config/urls/supportUrls';

export default {
  diagnostic: {
    helpLinks: [
      {
        title: 'Guía inicial de su Hospedaje ',
        link: CLIENT_AREA_FEATURES_ARTICLE,
      },
      {
        title: 'Cómo configurar el dominio (Cambiar DNS)',
        link: CONFIGURE_DOMAIN_DNS_ARTICLE,
      },
      {
        title: 'Cómo agregar un dominio en el hosting',
        link: ADD_DOMAIN_TO_HOST_ARTICLE,
      },
      {
        title: 'Cómo crear un sitio web en WordPress a través del Portal del Cliente',
        link: CREATE_WORDPRESS_SITE_ARTICLE,
      },
      {
        title: 'Cómo acceder al cPanel',
        link: ACCESS_CPANEL_ARTICLE,
      },
    ],
  },
  zopimDepartments: {
    builder: 'hges_chat_constructor_de_sitios',
    domain: 'hges_chat_dominios',
    financial: 'hges_chat_financeiro',
    mail: 'hges_chat_email',
    priority: 'priority',
    retention: 'hges_chat_retencao',
    shared: 'hges_chat_compartilhado',
    skilled: 'specialized',
    tos: 'hges_chat_abuse_tos',
  },
  resetMessage: 'Por ahora esta conversación sera finalizada, pero siempre estamos aquí si necesitas algo más. Pronto recibirás un correo electrónico con el historial de nuestra conversación',
  chatFinished: 'Esta conversación ha sido finalizada. Si aún necesitas ayuda, simplemente inicia una nueva conversación.',
  startNewChat: 'Iniciar nueva atención',
};
