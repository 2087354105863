
const styles = theme => ({
  title: {
    lineHeight: '1.2',
  },
  actionContainerCancelled: {
    paddingBottom: 24,
  },
  container: {
    position: 'relative',
    padding: '2px 0px 5px 0px',
  },
  cardTitle: {
    color: theme.color.tundora,
    margin: 0,
    fontWeight: theme.font.weights.regular,
    fontSize: theme.font.sizes.md,
    lineHeight: '1.2',
    marginBottom: 5,
    cursor: 'pointer',
  },
  cardSubTitle: {
    fontSize: theme.font.sizes.xxs,
    color: theme.color.regentGray,
    margin: 0,
  },
  cardTitleLetter: {
    margin: 0,
    color: theme.color.tundora,
    fontSize: theme.font.sizes.sm,
    letterSpacing: '-0.5px',
  },
  switch: {
    marginLeft: '-13px',
    '& .switchRoot': {
      '& > span:first-child': {
        height: 20,
      },
    },
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 100,
    '&:last-child': {
      padding: '16px 0 20px 30px',
    },
    [theme.breakpoints.down(1280)]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down(650)]: {
      flexDirection: 'column',
    },
  },

  linkBillDueDate: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'left',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      textDecoration: 'underline!important',
    },
  },

  linkNewDomain: {
    [theme.breakpoints.down('md')]: {
      textDecoration: 'underline!important',
    },

  },

  wrapperContent: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  statusWithDue: {
    '@media(max-width: 1280px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '70%',
    },
    '@media(max-width: 650px)': {
      width: '100%',
    },
  },
  statusWithoutDue: {
    '@media(max-width: 1280px)': {
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
    },
    '@media(max-width: 1050px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  statusRow: {
    '@media(max-width: 1280px)': {
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  descriptionContent: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 460,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  cardDateBox: {
    marginRight: '60px',
    '@media(min-width: 670px) and (max-width: 1280px)': {
      marginRight: '55px',
    },
    [theme.breakpoints.down(551)]: {
      marginRight: 0,
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 300,
    marginRight: '15px',
  },
  dueContainer: {
    width: '56%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(min-width: 650px) and (max-width: 1280px)': {
      width: '100%',
    },
    '@media(min-width: 960px) and (max-width: 1000px)': {
      marginTop: 17,
    },
    '@media(max-width: 650px)': {
      width: '100%',
      marginBottom: '5px',
      marginTop: '18px',
    },
  },
  renewTitle: {
    width: '160px',
    '@media(min-width: 960px) and (max-width: 1000px)': {
      width: 119,
    },
    '@media(min-width: 551px) and (max-width: 610px)': {
      width: 119,
    },
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    width: 'auto',
    [theme.breakpoints.down(1280)]: {
      marginTop: 6,
    },
    [theme.breakpoints.down(650)]: {
      marginRight: '24px',
    },
  },
  wrapperWarningNotice: {
    marginRight: 40,
    [theme.breakpoints.down(1280)]: {
      marginRight: 30,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '15%',
    '@media(max-width: 1280px)': {
      width: '30%',
    },
    '@media(max-width: 650px)': {
      width: '100%',
    },
  },
  cardActions: {
    display: 'initial',
    fontSize: theme.font.sizes.sm,
    marginRight: '24px',
    minWidth: '140px',
    textAlign: 'right',
    '& > a': {
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'none!important',
      },
      transition: 'color 0.2s',
      textTransform: 'uppercase',
      textDecoration: 'none',
      textAlign: 'right',
      color: theme.color.indigo,
      fontWeight: theme.font.weights.medium,
      margin: 0,
    },
    '@media(max-width: 1280px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-6px',
      paddingTop: 5,
      paddingRight: 30,
    },
    '@media(max-width: 596px)': {
      paddingBottom: 0,
      marginRight: '-6px',
      marginTop: '16px',

    },
  },
  addonList: {
    '@media(min-width: 1280px)': {
      paddingRight: '25px',
    },
  },
  linkMessage: {
    fontSize: theme.font.sizes.xxs,
    cursor: 'pointer',
  },
  domainNameWrapper: {
    display: 'inline-block',
    paddingLeft: '15px',
  },


});

export default styles;
