import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  ${({ theme, paddingBottom }) => css`
    align-items: center;
    background: ${theme.palette.white.light};
    box-shadow: 0px 1px 3px ${theme.shadows.grey.light};
    display: flex;
    padding: ${paddingBottom ? '14px 16px' : '16px 24px 0px'};
  `}
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.light};
    margin: 0;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.regular};
    margin-left: 6px;
  `}
`;

export const ChildrenContainer = styled.span`
  ${({ position }) => css`
    justify-self: ${position};
  `}
`;
