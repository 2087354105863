import React, { useCallback } from 'react';
import * as Styles from './DomainItem.styles';
import {
  Button, Tag, Toggle, Tooltip, IconSpeed,
} from 'gatorcomponents';
import { ORIGIN, STATUS } from '../../CloudFlareHandler.types';
import PropTypes from 'prop-types';
import snappy from '@/media/siteBuilder/woondersuite/snappy.svg';

export const DomainItem = ({
  domain,
  status,
  origin,
  nameServerTooltipContent,
  activateCdn,
  manageLabel,
  messageOngoingActivation,
  messageOngoingDeactivation,
  messageExternalDomain,
  statusLabel,
  pendingTooltip,
  cdnAlreadyActive,
  verifyDomainLabel,
  manageDomainCdn,
  manageCdnDisabledLabel,
  manageCdnIsEnabled,
  onClickToggle,
  onVerifyExternalDomain,
}) => {
  const isPendingDeactivation = [STATUS.PENDING_DEACTIVATION, STATUS.INACTIVATION_POINTING_ERROR].includes(status);
  const isPendingActivation = [STATUS.PENDING, STATUS.ACTIVATION_POINTING_ERROR, STATUS.DELAY].includes(status);

  const withTooltip = isPendingActivation || status === STATUS.ACTIVE;
  const canManageCDN = manageCdnIsEnabled && (!isPendingActivation && !isPendingDeactivation);


  const hasMessageAndTag = isPendingDeactivation
  || isPendingActivation
  || (status === STATUS.NONEXISTENT && origin === ORIGIN.EXTERNAL);

  const handleVerifyDomain = () => {
    onVerifyExternalDomain && onVerifyExternalDomain();
  };

  const mountMessage = useCallback(() => {
    if (isPendingDeactivation) {
      return messageOngoingDeactivation;
    }

    if (isPendingActivation) {
      return messageOngoingActivation;
    }

    if (status === STATUS.NONEXISTENT && origin === ORIGIN.EXTERNAL) {
      return messageExternalDomain;
    }

    return '';
  }, [
    isPendingActivation,
    isPendingDeactivation,
    messageExternalDomain,
    messageOngoingActivation,
    messageOngoingDeactivation,
    origin,
    status,
  ]);

  const getTagVariant = useCallback(() => {
    if (isPendingActivation || isPendingDeactivation) {
      return 'info';
    }

    if (status === STATUS.NONEXISTENT && origin === ORIGIN.EXTERNAL) {
      return 'pending';
    }
  }, [
    isPendingActivation,
    isPendingDeactivation,
    origin,
    status,
  ]);

  const getToggleCheckedStatus = useCallback(() => {
    if (isPendingDeactivation) {
      return false;
    }

    return status === STATUS.ACTIVE || isPendingActivation;
  }, [
    isPendingActivation,
    isPendingDeactivation,
    status,
  ]);

  const activeTooltipContent = () => (
    <Styles.ActiveTooltipContentWrapper>
      <IconSpeed
        secondaryColor="activeLight"
        size="lg"
      />
      {cdnAlreadyActive}
    </Styles.ActiveTooltipContentWrapper>
  );

  const handleManageDomainCDN = () => {
    manageDomainCdn && manageDomainCdn();
  };

  const manageCdnDisabledTooltipContent = (
    <Styles.DisabledManagerTooltipContentWrapper>
      <Styles.Image src={snappy} />
      {manageCdnDisabledLabel}
    </Styles.DisabledManagerTooltipContentWrapper>
  );

  const handleClickToggle = () => {
    onClickToggle && onClickToggle();
  };

  const isInternalOrOngoinging = (origin === ORIGIN.INTERNAL || (origin === ORIGIN.EXTERNAL && status !== STATUS.NONEXISTENT));

  return (
    <Styles.CardWrapper>
      {nameServerTooltipContent ? (
        <Tooltip content={nameServerTooltipContent}>
          <Styles.DomainName data-testid="domain">
            {domain}
          </Styles.DomainName>
        </Tooltip>
      ) : (
        <Styles.DomainName data-testid="domain">
          {domain}
        </Styles.DomainName>
      )}

      {hasMessageAndTag && (
        <Styles.TagWrapper>
          <Tag
            testId={`Tag-${domain}`}
            label={
              statusLabel[
                `${status.toLowerCase().replace(/_/g, '')}${origin.toLowerCase()}`
              ]
            }
            variant={getTagVariant()}
          />
        </Styles.TagWrapper>
      )}

      <Styles.LeftColumn>
        {isInternalOrOngoinging && (
          <>
            <Styles.ToggleWrapper>
              {(withTooltip) && (
              <Tooltip
                content={status === STATUS.ACTIVE ? activeTooltipContent() : pendingTooltip}
                preferredPosition="topLeft"
                variant={status === STATUS.ACTIVE ? 'light' : 'default'}
              >
                <Styles.ToggleLabel>
                  <Styles.ToggleLabel onClick={(isPendingActivation || isPendingDeactivation) ? null : handleClickToggle}>
                    <Toggle
                      checked={getToggleCheckedStatus()}
                      testId={`toggle-${domain}`}
                      disabled={isPendingActivation || isPendingDeactivation}
                      controlledByFather
                    />
                  </Styles.ToggleLabel>
                </Styles.ToggleLabel>
              </Tooltip>
              )}

              {!withTooltip && (
                <Styles.ToggleLabel onClick={(isPendingActivation || isPendingDeactivation) ? null : handleClickToggle}>
                  <Toggle
                    checked={getToggleCheckedStatus()}
                    testId={`toggle-${domain}`}
                    controlledByFather
                    disabled={isPendingActivation || isPendingDeactivation}
                  />
                </Styles.ToggleLabel>
              )}

              <Styles.ToggleText>
                {activateCdn}
              </Styles.ToggleText>
            </Styles.ToggleWrapper>

            {manageCdnIsEnabled ? (
              <Button
                testId="manageCta"
                label={manageLabel}
                size="medium2"
                disabled={!canManageCDN}
                onClick={handleManageDomainCDN}
              />
            ) : (
              <Tooltip content={manageCdnDisabledTooltipContent} variant="light" preferredPosition="topLeft">
                <Button
                  testId="manageCta"
                  label={manageLabel}
                  size="medium2"
                  disabled={!canManageCDN}
                  onClick={handleManageDomainCDN}
                />
              </Tooltip>
            )}
          </>
        )}

        {!isInternalOrOngoinging && (
          <Button
            variant="secondary"
            testId="verifyCta"
            label={verifyDomainLabel}
            size="medium2"
            onClick={handleVerifyDomain}
            disabled={isPendingActivation || isPendingDeactivation}
          />
        )}
      </Styles.LeftColumn>

      {hasMessageAndTag && (
        <Styles.ItemDescription data-testid="message">
          {mountMessage()}
        </Styles.ItemDescription>
      )}
    </Styles.CardWrapper>
  );
};

DomainItem.propTypes = {
  domain: PropTypes.string,
  status: PropTypes.string,
  origin: PropTypes.string,
  activateCdn: PropTypes.string,
  manageLabel: PropTypes.string,
  messageOngoingActivation: PropTypes.node,
  messageOngoingDeactivation: PropTypes.node,
  messageExternalDomain: PropTypes.string,
  statusLabel: PropTypes.object,
  pendingTooltip: PropTypes.string,
  cdnAlreadyActive: PropTypes.node,
  verifyDomainLabel: PropTypes.string,
  onClickToggle: PropTypes.func,
};


export default DomainItem;
