/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { withI18n, Trans } from 'react-i18next';
import Card from '@material-ui/core/Card';
import {
  Typography, TextField, Select, OutlinedInput, MenuItem, Checkbox,
  FormControlLabel, CircularProgress, Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Lock, Check, Lens, Info,
} from '@material-ui/icons';
import classnames from 'classnames';
import { find, propEq } from 'ramda';
import TicketButtonLoader from '@/components/Tickets/TicketButtonLoader';
import FaqEmails from '@/components/FrequentlyQuestions/FaqEmails';
import PasswordField from '@/components/Fields/PasswordField';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import LinkButton from '@/components/Buttons/LinkButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { passwordScore } from '@/utils/Validators/passwordScore';
import { validateEmailName, validateQuota, validateEmailCpanel } from '@/utils/Validators/validation';
import { commonActions } from '@/redux/modules';
import { PASSWORD_GENERATOR_URL } from '@/config/urls/passwordUrls';
import { loadHostDomain, createEmailAccount, loadHostingTypes } from '@/redux/actions/emails';
import { SUCCESS_CREATE_EMAIL } from '@/redux/actions/actionsTypes';
import styles from './styles';
import useLocale from '@/hooks/useLocale';

const CreateEmail = ({ t, classes }) => {
  const { domain: specificDomain } = useParams();
  const { alerts: alertsLocale } = useLocale();
  const dispatch = useDispatch();

  const stateEmails = useSelector(state => state.emails);

  const {
    list: hostings,
    loading: loadingHosting,
  } = stateEmails.hostings;

  const {
    list: hostDomainList,
    loading: hostDomainLoading,
    primaryDomain: hostDomainPrimary,
  } = stateEmails.hostDomain;
  const { loadingCreateAccount } = stateEmails;

  const [domain, setDomain] = useState('');
  const [domainName, setDomainName] = useState('');
  const [domainValue, setDomainValue] = useState('');
  const [strength, setStrength] = useState('');
  const [emailName, setEmailName] = useState('');
  const [emailNameReady, setEmailNameReady] = useState(false);
  const [emailNameError, setEmailError] = useState(false);
  const [emailNameErrorMsg, setEmailErrorMsg] = useState(false);
  const [emailNameFlagError, setEmailFlagError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordReady, setPasswordReady] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [passwordFlagError, setPasswordFlagError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordReady, setConfirmPasswordReady] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const [confirmPasswordFlagError, setConfirmPasswordFlagError] = useState(false);

  const [checklistVisible, setChecklistVisible] = useState(true);
  const [upperAndLower, setUpperAndLower] = useState(false);
  const [number, setNumer] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [notEqualCharacters, setNotEqualCharacters] = useState(false);
  const [notSequencialCharacters, setNotSequencialCharacters] = useState(false);
  const [eightCharacters, setEightCharacters] = useState(false);
  const [equalInputValue, setEqualInputValue] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [cancelButtonDisable, setCancelButtonDisable] = useState(true);
  const [redirectToEmailList, setRedirectToEmailsList] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [quota, setQuota] = useState('');
  const [unlimited, setUnlimited] = useState(false);


  useEffect(() => {
    dispatch(loadHostDomain());

    if (hostings.length === 0) {
      dispatch(loadHostingTypes());
    }
  }, []);

  useEffect(() => {
    setDomainValue(hostDomainPrimary);
    setDomain(hostDomainPrimary.hostingId);
    setDomainName(hostDomainPrimary.domain);
  }, [stateEmails]);

  const handleCancel = () => {
    setRedirectToEmailsList(true);
  };

  const handleChange = (event, input) => {
    if (input === 'password') {
      const strength = passwordScore(event.target.value.trim());
      setPassword(event.target.value);
      setStrength(strength.strength);
    } else if (input === 'confirmPassword') {
      setConfirmPassword(event.target.value);
    } else if (input === 'domain') {
      setDomainValue(event.target.value);
      setDomain(event.target.value.hostingId);
      setDomainName(event.target.value.domain);
    } else if (input === 'emailName') {
      setEmailName(validateEmailName(event.target.value));
    } else if (input === 'quota') {
      setQuota(validateQuota(event.target.value));
      if (event.target.value.length > 7) {
        setQuota(validateQuota(event.target.value.slice(0, event.target.value.length - 1)));
      }
    } else if (input === 'unlimited') {
      setUnlimited(!unlimited);
    }
  };

  const handleValidations = (input) => {
    const isPasswordInvalid = strength < 50 || !notSequencialCharacters || !notEqualCharacters;

    if (input === 'password') {
      if (password === '') {
        setPasswordError(true);
        setPasswordErrorMsg(t('myData.changePassword.errorEmptyField'));
        setPasswordFlagError(true);
      } else if (isPasswordInvalid) {
        setPasswordError(true);
        setPasswordErrorMsg('');
        setPasswordReady(false);
      } else {
        setPasswordError(false);
        setPasswordErrorMsg('');
        setPasswordReady(true);
      }
    } else if (input === 'confirmPassword') {
      if (confirmPassword === '') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMsg(t('myData.changePassword.errorEmptyField'));
        setConfirmPasswordFlagError(true);
      } else if (confirmPassword !== password) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMsg(t('myData.changePassword.errorConfirmNew'));
        setConfirmPasswordFlagError(true);
      } else {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorMsg('');
        setConfirmPasswordReady(true);
      }
    } else if (input === 'emailName') {
      if (emailName === '') {
        setEmailError(true);
        setEmailErrorMsg(t('myData.changePassword.errorEmptyField'));
        setEmailFlagError(true);
      } else if (validateEmailCpanel(emailName)) {
        setEmailError(true);
        setEmailErrorMsg(t('emails.specialCharacters'));
        setEmailFlagError(true);
      } else {
        setEmailError(false);
        setEmailErrorMsg('');
        setEmailNameReady(true);
      }
    }
  };

  const handleChecklist = (input) => {
    // Verify password change requirements are ready and hidden checklist
    if (passwordReady && password === confirmPassword) {
      setChecklistVisible(false);
    } else {
      setChecklistVisible(true);
    }

    // Handle regex clean password
    const cleanSymbols = password.replace(/\W/g, '');
    const numbers = cleanSymbols.replace(/[A-Z-a-z]/g, '');
    const symbols = password.replace(/\w/g, '');

    const verifyCharactersOnSequence = (alphabet) => {
      let haveCharactersOnSequence = false;

      for (let pointer = 0; pointer < password.length; pointer += 1) {
        for (let sequence = pointer + 3; sequence <= password.length; sequence += 1) {
          const substring = password.substring(pointer, sequence);
          if (alphabet.includes(substring)) {
            haveCharactersOnSequence = true;
            break;
          }
        }
      }

      return haveCharactersOnSequence;
    };

    const passwordHasSequencialCharacters = () => {
      const alphaNumbers = '0123456789';
      const alphaLetters = 'abcdefghijklmnopqrstuvwxyz';

      return verifyCharactersOnSequence(alphaNumbers)
          || verifyCharactersOnSequence(alphaNumbers.split('').reverse().join(''))
          || verifyCharactersOnSequence(alphaLetters)
          || verifyCharactersOnSequence(alphaLetters.toUpperCase());
    };

    // Verify new password
    if (input === 'password') {
      // Check if have uppers and lowercase letters
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        setUpperAndLower(true);
      } else {
        setUpperAndLower(false);
      }
      // Check if have number
      if (numbers.length > 0) {
        setNumer(true);
      } else {
        setNumer(false);
      }
      // Check if have symbol
      if (symbols.length > 0) {
        setSymbol(true);
      } else {
        setSymbol(false);
      }
      // Check equal characters
      if (password.match(/(.)\1{2}/)) {
        setNotEqualCharacters(false);
      } else {
        setNotEqualCharacters(true);
      }
      if (passwordHasSequencialCharacters()) {
        setNotSequencialCharacters(false);
      } else {
        setNotSequencialCharacters(true);
      }
      // Check if have 8 characters
      if (password.length >= 8) {
        setEightCharacters(true);
      } else {
        setEightCharacters(false);
      }
    }
    // Verify confirm password is equal new password
    if (input === 'confirmPassword') {
      if (password === confirmPassword) {
        setEqualInputValue(true);
      } else {
        setEqualInputValue(false);
      }
    }
  };

  const handleCancelButton = () => {
    if (password !== '' || confirmPasswordReady !== '') {
      setCancelButtonDisable(false);
    } else if (password === '' || confirmPassword === '') {
      setCancelButtonDisable(true);
    }
  };

  const handleActionsVisible = () => {
    if (!!emailName || !!password || !!confirmPassword) {
      setActionsVisible(true);
    } else {
      setActionsVisible(false);
    }
  };

  const handleKeyUp = (event, input) => {
    if (passwordFlagError) handleValidations(input);
    if (confirmPasswordFlagError) handleValidations(input);
    if (emailNameFlagError) handleValidations(input);
    handleCancelButton();

    if (emailName !== '' && password !== '' && confirmPassword !== '') {
      if (password.length > 7) {
        if (passwordReady) {
          if (password === confirmPassword) {
            setSaveButtonDisable(false);
          } else {
            setSaveButtonDisable(true);
          }
        } else {
          setSaveButtonDisable(true);
        }
      } else {
        setSaveButtonDisable(true);
      }
    } else {
      setSaveButtonDisable(true);
    }

    handleChecklist(input);
    handleActionsVisible();
  };

  const handleBlur = (event, input) => {
    handleValidations(input);
  };

  const clearAll = () => {
    const clearObject = {
      target: {
        value: '',
      },
    };
    handleChange(clearObject, 'emailName');
    handleChange(clearObject, 'password');
    handleChange(clearObject, 'confirmPassword');
  };

  const handleSave = () => {
    const domainObject = find(propEq('domain', specificDomain), hostDomainList);

    if (emailNameReady && passwordReady && confirmPasswordReady && equalInputValue) {
      const createEmailData = {
        user: emailName,
        domain: specificDomain ? domainObject.domain : domainName,
        quota,
        password,
        passwordConfirmation: confirmPassword,
        unlimited: quota === '',
      };
      dispatch(createEmailAccount(createEmailData, specificDomain ? domainObject.hostingId : domain)).then((response) => {
        if (response.type === SUCCESS_CREATE_EMAIL && response.data.notifications.length === 0) {
          dispatch(commonActions.notifications.set({
            label: alertsLocale.emailPassword.createSuccess,
            type: 'success',
          }));
          clearAll();
        }
        if (response.data.notifications.some(notification => notification.errors.error_tag === 'cpanel_instability')) {
          dispatch(commonActions.notifications.set({
            label: alertsLocale.emailPassword.weakPassword,
            type: 'error',
          }));
        }
      });
    }
  };

  if (loadingHosting) {
    return (
      <div className={classes.contentLoader} data-testid="hosting-loader-component">
        <CircularProgress />
      </div>
    );
  }

  // Redirect on cancel button
  if (redirectToEmailList) {
    return specificDomain
      ? <Redirect to={`${t('routes.filteredEmails')}/${specificDomain}`} />
      : <Redirect to={`${t('routes.emails')}`} />;
  }

  // Redirect if hanvent hosting
  if (hostings.hosting === 0) {
    return <Redirect to={`${t('routes.emails')}`} />;
  }

  return (
    <>
      <ContentContainer className={classes.root}>
        <Card className={classes.border}>
          <div className={classes.content}>
            <Typography className={classes.title} data-id="create-email-page-title">{t('emails.createAccount')}</Typography>
            <div className={classes.inputEmailWrapper}>
              <TextField
                label={t('emails.name')}
                type="text"
                id="email-name"
                className={classes.emailNameInput}
                margin="normal"
                variant="outlined"
                onKeyUp={event => handleKeyUp(event, 'emailName')}
                onChange={event => handleChange(event, 'emailName')}
                onBlur={event => handleBlur(event, 'emailName')}
                value={emailName}
                error={emailNameError}
                helperText={emailNameErrorMsg}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputProps: { maxLength: 64 },
                }}
                data-id="email-name"
              />
              <div className={classes.handleSelectPosition}>
                {specificDomain
                  ? (
                    <TextField
                      type="text"
                      className={classnames(classes.emailNameInput, classes.disabledInput)}
                      margin="normal"
                      variant="outlined"
                      value={`@${specificDomain}`}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                        inputProps: { maxLength: 64 },
                      }}
                      disabled
                    />
                  )
                  : (
                    <Select
                      disabled={hostDomainLoading}
                      value={domainValue}
                      onChange={event => handleChange(event, 'domain')}
                      className={classes.selectFieldTextOn}
                      startAdornment={hostDomainLoading && <CircularProgress className={classes.loading} size={20} data-id="loading-select-domain" />}
                      data-id="email-select-domain"
                      input={(
                        <OutlinedInput
                          classes={{
                            root: classes.selectCustom,
                            input: classes.selectCustomInput,
                            notchedOutline: classes.selectCustomOutline,
                            focused: classes.selectCustomFocused,
                          }}
                          labelWidth={0}
                          name="domain"
                          id="outlined-age-simple"
                        />
                )}
                    >
                      {hostDomainList.map(domain => (
                        <MenuItem value={domain} key={domain.id}>
                          @
                          {domain.domain}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
              </div>
            </div>
            <div className={classes.inputPasswordWrapper}>
              <PasswordField
                value={password}
                type="password"
                autoComplete="current-password"
                name="password"
                label={t('emails.passwordPlaceholder')}
                required
                variant="outlined"
                margin="normal"
                spellCheck="false"
                fullWidth
                onKeyUp={event => handleKeyUp(event, 'password')}
                onChange={event => handleChange(event, 'password')}
                onBlur={event => handleBlur(event, 'password')}
                error={passwordError}
                helperText={passwordErrorMsg}
                dataId="email-password"
                data-testid="email-password-field"
              />
            </div>
            <div className={`${classes.inputPasswordWrapper} ${classes.handleWrapper}`}>
              <PasswordField
                value={confirmPassword}
                type="password"
                autoComplete="current-password"
                name="password"
                label={t('emails.confirmPassword')}
                required
                variant="outlined"
                margin="normal"
                spellCheck="false"
                fullWidth
                onKeyUp={event => handleKeyUp(event, 'confirmPassword')}
                onChange={event => handleChange(event, 'confirmPassword')}
                onBlur={event => handleBlur(event, 'confirmPassword')}
                error={confirmPasswordError}
                helperText={confirmPasswordErrorMsg}
                dataId="email-confirm-password"
              />
            </div>

            {checklistVisible && (
              <div className={classes.checklistContainer} data-testid="checklist-password-validate-content">
                <p className={classes.checklistTitle}>
                  <Lock className={classes.lockIcon} />
                  {t('emails.password')}
                </p>
                <ul className={classes.checklist}>
                  <li className={`${upperAndLower ? classes.colorTrue : classes.colorFalse}`}>
                    {upperAndLower
                      ? <Check data-testid="password-check-upperAndLowerCase" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-upperAndLowerCase" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.upperAndLowercase')}
                  </li>
                  <li className={`${number ? classes.colorTrue : classes.colorFalse}`}>
                    {number
                      ? <Check data-testid="password-check-number" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-number" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.number')}
                  </li>
                  <li className={`${symbol ? classes.colorTrue : classes.colorFalse}`}>
                    {symbol
                      ? <Check data-testid="password-check-symbol" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-symbol" className={classes.lensIcon} />}
                    {t('myData.changePassword.checklist.symbol')}
                  </li>
                  <li className={`${eightCharacters ? classes.colorTrue : classes.colorFalse}`}>
                    {eightCharacters
                      ? <Check data-testid="password-check-eightCharacters" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-eightCharacters" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.eightCharacters')}
                  </li>
                  <li className={`${notEqualCharacters ? classes.colorTrue : classes.colorFalse}`}>
                    {notEqualCharacters
                      ? <Check data-testid="password-check-notEqualCharacters" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-notEqualCharacters" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.notEqualCharacters')}
                  </li>
                  <li className={`${notSequencialCharacters ? classes.colorTrue : classes.colorFalse}`}>
                    {notSequencialCharacters
                      ? <Check data-testid="password-check-notSequencialCharacters" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-notSequencialCharacters" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.notSequencialCharacters')}
                  </li>
                  <li className={`${equalInputValue ? classes.colorTrue : classes.colorFalse}`}>
                    {equalInputValue
                      ? <Check data-testid="password-check-equalInputValue" className={classes.checkIcon} />
                      : <Lens data-testid="password-lens-equalInputValue" className={classes.lensIcon} />
                    }
                    {t('myData.changePassword.checklist.sameInBothFields')}
                  </li>
                </ul>
              </div>
            )}

            <div className={classes.generatorWrapper} data-testid="waring-message">
              <span className={classes.generatorText}>
                <Trans i18nKey="myData.changePassword.generatorText">
                  0
                  <LinkButton to={PASSWORD_GENERATOR_URL} className={classes.generatorLink} target="_blank">1</LinkButton>
                  2
                </Trans>
              </span>
            </div>

            <div className={classes.settings}>
              <TextField
                disabled={unlimited}
                label={t('emails.quota')}
                type="number"
                id="email-quota"
                className={classes.quotaInput}
                margin="normal"
                variant="outlined"
                onChange={event => handleChange(event, 'quota')}
                value={quota}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                data-id="email-quota"
              />
              <span className={classes.textMb}>MB</span>
              <span className={classes.textOr}>{t('emails.or')}</span>
              <FormControlLabel
                data-testid="checkbox-unlimited-quota"
                control={(
                  <Checkbox
                    checked={unlimited}
                    onChange={event => handleChange(event, 'unlimited')}
                    value="checkedB"
                    color="primary"
                    data-id="email-unlimited-quota"
                  />
                )}
                label={t('emails.unlimited')}
              />
              <div className={classes.tooltipHelper}>
                <Tooltip
                  title={t('emails.unlimitedToolTip')}
                  interactive
                  placement="right"
                >
                  <Info className={classes.infoIcon} />
                </Tooltip>
              </div>
            </div>

            {actionsVisible && (
              <div className={classes.actionsWrapper} data-testid="submit-buttons-group">
                <OutlineButton
                  disabled={cancelButtonDisable}
                  onClick={handleCancel}
                >
                  {t('backToList')}
                </OutlineButton>
                <PrimaryButton
                  disabled={saveButtonDisable}
                  onClick={() => handleSave()}
                  className={classes.save}
                  data-id="button-submit-email"
                  data-testid="button-submit-email"
                >
                  {loadingCreateAccount ? <TicketButtonLoader data-testid="loader-submit-component" /> : t('emails.createButton')}
                </PrimaryButton>
              </div>
            )}
          </div>
        </Card>

        <FaqEmails className={classes.faq} />
      </ContentContainer>
    </>
  );
};

export default withI18n()(withStyles(styles)(CreateEmail));
