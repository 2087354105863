import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 24px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    margin: 0px 0px 31px 0px;
  `}
`;

export const Row = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;

    @media (max-width:768px){
      flex-direction: column;
    }
  `}
`;
