import { DomainsActionTypes } from './domains.types';

const getDomainsRequest = page => ({ type: DomainsActionTypes.GET_DOMAINS_REQUEST, payload: page });
const getDomainsSuccess = () => ({ type: DomainsActionTypes.GET_DOMAINS_SUCCESS });
const getDomainsFailure = () => ({ type: DomainsActionTypes.GET_DOMAINS_FAILURE });
const setDomains = list => ({ type: DomainsActionTypes.SET_DOMAINS, payload: list });

const getDomainDetail = id => ({ type: DomainsActionTypes.GET_DOMAIN_DETAIL_REQUEST, payload: { id } });
const getDomainDetailSuccess = () => ({ type: DomainsActionTypes.GET_DOMAINS_SUCCESS });
const getDomainDetailFailure = () => ({ type: DomainsActionTypes.GET_DOMAINS_FAILURE });
const setDomainDetail = detail => ({ type: DomainsActionTypes.SET_DOMAIN_DETAIL, payload: detail });

const getDomainsElegible = () => ({ type: DomainsActionTypes.REQUEST_DOMAINS_ELEGIBLE });
const getDomainsElegibleSuccess = () => ({ type: DomainsActionTypes.DOMAINS_ELEGIBLE_SUCCESS });
const getDomainsElegibleFailure = () => ({ type: DomainsActionTypes.DOMAINS_ELEGIBLE_FAILURE });
const setDomainsElegible = domainsElegible => ({ type: DomainsActionTypes.SET_DOMAINS_ELEGIBLE, payload: domainsElegible });

const domainsActivateRenewalRequest = (domainData, callBackFunction) => ({
  type: DomainsActionTypes.DOMAIN_ACTIVATE_RENEWAL_REQUEST,
  payload: { domainData, callBackFunction },
});

const domainsActivateRenewalSuccess = () => ({
  type: DomainsActionTypes.DOMAIN_ACTIVATE_RENEWAL_SUCCESS,
});

const domainsActivateRenewalFailure = () => ({
  type: DomainsActionTypes.DOMAIN_ACTIVATE_RENEWAL_FAILURE,
});

const domainsGenerateNewInvoiceRequest = (domainData, callBackFunction) => ({
  type: DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_REQUEST,
  payload: { domainData, callBackFunction },
});

const domainsGenerateNewInvoiceSuccess = () => ({
  type: DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_SUCCESS,
});

const domainsGenerateNewInvoiceFailure = () => ({
  type: DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_FAILURE,
});

const domainsGenerateNewInvoiceLoading = value => ({
  type: DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_LOADING,
  value,
});

const getDomainPropagationStatusRequest = domainId => ({
  type: DomainsActionTypes.GET_DOMAIN_PROPAGATION_STATUS_REQUEST,
  payload: domainId,
});

const getDomainPropagationStatusSuccess = () => ({
  type: DomainsActionTypes.GET_DOMAIN_PROPAGATION_STATUS_SUCCESS,
});

const getDomainPropagationStatusFailure = () => ({
  type: DomainsActionTypes.GET_DOMAIN_PROPAGATION_STATUS_FAILURE,
});

const setDomainPropagationStatus = allStatus => ({
  type: DomainsActionTypes.SET_DOMAIN_PROPAGATION_STATUS,
  payload: allStatus,
});

const getConfiguredDomainStatusRequest = domain => ({
  type: DomainsActionTypes.GET_CONFIGURED_DOMAIN_STATUS_REQUEST,
  payload: domain,
});

const getConfiguredDomainStatusSuccess = () => ({
  type: DomainsActionTypes.GET_CONFIGURED_DOMAIN_STATUS_SUCCESS,
});

const getConfiguredDomainStatusFailure = () => ({
  type: DomainsActionTypes.GET_CONFIGURED_DOMAIN_STATUS_FAILURE,
});

const setConfiguredDomainStatus = data => ({
  type: DomainsActionTypes.SET_CONFIGURED_DOMAIN_STATUS,
  payload: {
    id: data.id,
    domain: data.domain,
    status: data.status,
  },
});

const setEmptyDomainStatus = () => ({
  type: DomainsActionTypes.SET_EMPTY_DOMAIN_STATUS,
});

const setResetDomainStatus = () => ({
  type: DomainsActionTypes.SET_RESET_DOMAIN_STATUS,
});

const setLoadedDomainStatus = () => ({
  type: DomainsActionTypes.SET_LOADED_CONFIGURED_DOMAIN_STATUS,
});

const setDomainsHasConfigured = id => ({
  type: DomainsActionTypes.SET_DOMAIN_HAS_CONFIGURED,
  payload: id,
});

const setDomainsHasConfiguredSuccess = () => ({
  type: DomainsActionTypes.SET_DOMAIN_HAS_CONFIGURED_SUCCESS,
});

const setDomainsHasConfiguredFailure = () => ({
  type: DomainsActionTypes.SET_DOMAIN_HAS_CONFIGURED_FAILURE,
});

const setDomainEPPRedirect = redirect => ({
  type: DomainsActionTypes.SET_DOMAIN_EPP_REDIRECT,
  payload: redirect,
});

const setModalEppKeyOpen = (domainName, domainId) => ({
  type: DomainsActionTypes.SET_MODAL_EPP_KEY_IS_OPEN,
  payload: {
    name: domainName,
    id: domainId,
  },
});

const setModalEppKeyClose = () => ({
  type: DomainsActionTypes.SET_MODAL_EPP_KEY_IS_CLOSED,
});

const setModalEppKeyChange = (domainName, domainId) => ({
  type: DomainsActionTypes.SET_MODAL_EPP_KEY_IS_CHANGED,
  payload: {
    name: domainName,
    id: domainId,
  },
});

const getEppKeyGeneratedRequest = (domainId, setModalStatus) => ({
  type: DomainsActionTypes.GET_EPP_KEY_GENERATED_REQUEST,
  payload: {
    domainId,
    setModalStatus,
  },
});

const getEppKeyGeneratedSuccess = () => ({
  type: DomainsActionTypes.GET_EPP_KEY_GENERATED_SUCCESS,
});

const getEppKeyGeneratedFailure = () => ({
  type: DomainsActionTypes.GET_EPP_KEY_GENERATED_FAILURE,
});

const setEppKeyGenerated = keyGenerated => ({
  type: DomainsActionTypes.SET_EPP_KEY_GENERATED,
  payload: keyGenerated,
});

const getAllDomainsListRequest = setModalStatus => ({
  type: DomainsActionTypes.GET_ALL_DOMAINS_LIST_REQUEST,
  payload: setModalStatus,
});

const getAllDomainsListSuccess = () => ({
  type: DomainsActionTypes.GET_ALL_DOMAINS_LIST_SUCCESS,
});

const getAllDomainsListFailure = () => ({
  type: DomainsActionTypes.GET_ALL_DOMAINS_LIST_FAILURE,
});

const setAllDomainsList = domainsList => ({
  type: DomainsActionTypes.SET_ALL_DOMAINS_LIST,
  payload: domainsList,
});

const actions = {
  domains: {
    failure: getDomainsFailure,
    request: getDomainsRequest,
    success: getDomainsSuccess,
    set: setDomains,
    requestDetail: getDomainDetail,
    setDetail: setDomainDetail,
    detailSuccess: getDomainDetailSuccess,
    detailFailure: getDomainDetailFailure,
    activateRenewal: {
      request: domainsActivateRenewalRequest,
      success: domainsActivateRenewalSuccess,
      failure: domainsActivateRenewalFailure,
    },
    generateNewInvoice: {
      request: domainsGenerateNewInvoiceRequest,
      success: domainsGenerateNewInvoiceSuccess,
      failure: domainsGenerateNewInvoiceFailure,
      loading: domainsGenerateNewInvoiceLoading,
    },
  },
  propagationStatus: {
    get: getDomainPropagationStatusRequest,
    success: getDomainPropagationStatusSuccess,
    failure: getDomainPropagationStatusFailure,
    set: setDomainPropagationStatus,
  },
  configuredStatusDomain: {
    get: getConfiguredDomainStatusRequest,
    success: getConfiguredDomainStatusSuccess,
    failure: getConfiguredDomainStatusFailure,
    set: setConfiguredDomainStatus,
    empty: setEmptyDomainStatus,
    reset: setResetDomainStatus,
    loaded: setLoadedDomainStatus,
  },
  setDomainConfiguration: {
    set: setDomainsHasConfigured,
    success: setDomainsHasConfiguredSuccess,
    failure: setDomainsHasConfiguredFailure,
  },
  modalEppKey: {
    open: setModalEppKeyOpen,
    close: setModalEppKeyClose,
    change: setModalEppKeyChange,
    redirect: setDomainEPPRedirect,
    get: getEppKeyGeneratedRequest,
    set: setEppKeyGenerated,
    success: getEppKeyGeneratedSuccess,
    failure: getEppKeyGeneratedFailure,
  },
  activeDomainList: {
    get: getAllDomainsListRequest,
    set: setAllDomainsList,
    success: getAllDomainsListSuccess,
    failure: getAllDomainsListFailure,
  },
  domainsElegible: {
    get: getDomainsElegible,
    success: getDomainsElegibleSuccess,
    failure: getDomainsElegibleFailure,
    set: setDomainsElegible,
  },
};

export default actions;
