/* PRODUCTION */
export const MAIN_URL_BRASIL_PROD = 'https://cliente.hostgator.com.br';
export const MAIN_URL_CHILE_PROD = 'https://cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_PROD = 'https://cliente.hostgator.co';
export const MAIN_URL_MEXICO_PROD = 'https://cliente.hostgator.mx';

/* DEV */
export const MAIN_URL_BRASIL_DEV = 'http://cliente.whmcs.brasil:5100';
export const MAIN_URL_CHILE_DEV = 'http://cliente.whmcs.latam.cl:5103';
export const MAIN_URL_COLOMBIA_DEV = 'http://cliente.whmcs.latam.co:5102';
export const MAIN_URL_MEXICO_DEV = 'http://cliente.whmcs.latam.mx:5101';

/* HOMOLOG */
export const MAIN_URL_BRASIL_HOMOLOG = 'https://portal-homolog.hostgator.com.br';
export const MAIN_URL_CHILE_HOMOLOG = 'https://portal-homolog.hostgator.cl';
export const MAIN_URL_COLOMBIA_HOMOLOG = 'https://portal-homolog.hostgator.co';
export const MAIN_URL_MEXICO_HOMOLOG = 'https://portal-homolog.hostgator.mx';

/* SQUAD DELTA */
export const MAIN_URL_BRASIL_DELTA = 'https://delta-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_DELTA = 'https://delta-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_DELTA = 'https://delta-cliente.hostgator.co';
export const MAIN_URL_MEXICO_DELTA = 'https://delta-cliente.hostgator.mx';

/* SQUAD ALPHA */
export const MAIN_URL_BRASIL_ALPHA = 'https://alpha-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_ALPHA = 'https://alpha-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_ALPHA = 'https://alpha-cliente.hostgator.co';
export const MAIN_URL_MEXICO_ALPHA = 'https://alpha-cliente.hostgator.mx';

/* SQUAD BRAVO */
export const MAIN_URL_BRASIL_BRAVO = 'https://bravo-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_BRAVO = 'https://bravo-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_BRAVO = 'https://bravo-cliente.hostgator.co';
export const MAIN_URL_MEXICO_BRAVO = 'https://bravo-cliente.hostgator.mx';

/* SQUAD CHARLIE */
export const MAIN_URL_BRASIL_CHARLIE = 'https://charlie-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_CHARLIE = 'https://charlie-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_CHARLIE = 'https://charlie-cliente.hostgator.co';
export const MAIN_URL_MEXICO_CHARLIE = 'https://charlie-cliente.hostgator.mx';

/* SQUAD XRAY */
export const MAIN_URL_BRASIL_XRAY = 'https://xray-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_XRAY = 'https://xray-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_XRAY = 'https://xray-cliente.hostgator.co';
export const MAIN_URL_MEXICO_XRAY = 'https://xray-cliente.hostgator.mx';

/* SQUAD YANKEE */
export const MAIN_URL_BRASIL_YANKEE = 'https://yankee-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_YANKEE = 'https://yankee-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_YANKEE = 'https://yankee-cliente.hostgator.co';
export const MAIN_URL_MEXICO_YANKEE = 'https://yankee-cliente.hostgator.mx';

/* SQUAD FOXTROT */
export const MAIN_URL_BRASIL_FOXTROT = 'https://foxtrot-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_FOXTROT = 'https://foxtrot-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_FOXTROT = 'https://foxtrot-cliente.hostgator.co';
export const MAIN_URL_MEXICO_FOXTROT = 'https://foxtrot-cliente.hostgator.mx';

/* SQUAD SIERRA */
export const MAIN_URL_BRASIL_SIERRA = 'https://sierra-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_SIERRA = 'https://sierra-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_SIERRA = 'https://sierra-cliente.hostgator.co';
export const MAIN_URL_MEXICO_SIERRA = 'https://sierra-cliente.hostgator.mx';

/* QA */
export const MAIN_URL_BRASIL_QA = 'https://qa-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_QA = 'https://qa-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_QA = 'https://qa-cliente.hostgator.co';
export const MAIN_URL_MEXICO_QA = 'https://qa-cliente.hostgator.mx';

/* WHMCS_TRAINING */
export const MAIN_URL_BRASIL_WHMCS_TRAINING = 'https://whmcs-training-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_WHMCS_TRAINING = 'https://whmcs-training-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_WHMCS_TRAINING = 'https://whmcs-training-cliente.hostgator.co';
export const MAIN_URL_MEXICO_WHMCS_TRAINING = 'https://whmcs-training-cliente.hostgator.mx';

/* LOCAL DEVELOPMENT */
export const MAIN_URL_BRASIL_LOCAL_DEVELOPMENT = 'https://local-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_LOCAL_DEVELOPMENT = 'https://local-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_LOCAL_DEVELOPMENT = 'https://local-cliente.hostgator.co';
export const MAIN_URL_MEXICO_LOCAL_DEVELOPMENT = 'https://local-cliente.hostgator.mx';

/* AWS TEST DEVELOPMENT */
export const MAIN_URL_BRASIL_AWS_TEST = 'https://test-cliente.hostgator.com.br';
export const MAIN_URL_CHILE_AWS_TEST = 'https://test-cliente.hostgator.cl';
export const MAIN_URL_COLOMBIA_AWS_TEST = 'https://test-cliente.hostgator.co';
export const MAIN_URL_MEXICO_AWS_TEST = 'https://test-cliente.hostgator.mx';

/* LOCAL STORYBOOK */
export const MAIN_URL_BRASIL_STORYBOOK_DEV = 'http://localhost:5104';
export const MAIN_URL_CHILE_STORYBOOK_DEV = 'http://localhost:5105';
export const MAIN_URL_COLOMBIA_STORYBOOK_DEV = 'http://localhost:5106';
export const MAIN_URL_MEXICO_STORYBOOK_DEV = 'http://localhost:5107';

/* PRODUCTION STORYBOOK */
export const MAIN_URL_BRASIL_STORYBOOK_PROD = 'https://storybook-cliente.hostgator.com.br';
export const MAIN_URL_MEXICO_STORYBOOK_PROD = 'https://storybook-cliente.hostgator.mx';
