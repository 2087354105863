import React, {
  useRef, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { commonActions } from '@/redux/modules';
import * as Styles from './Notifications.styles';
import { Alert } from 'gatorcomponents';


const timeOut = null;
const Notifications = ({ notifications }) => {
  const dispatch = useDispatch();
  const domNode = document.body;
  const wrapperRef = useRef();
  const [maxWidth, setMaxWidth] = useState('513px');

  useEffect(() => {
    clearTimeout(timeOut);
    if (notifications.length) {
      setTimeout(() => {
        let lenght = 0;
        wrapperRef.current.childNodes.forEach((notification, index) => {
          const notficiationItem = notification;
          notficiationItem.style.bottom = `${lenght}px`;
          lenght += wrapperRef.current.childNodes[index].offsetHeight + 10;
        });
      }, 100);
    }
  }, [notifications, dispatch]);

  const removeNotification = (index) => {
    dispatch(commonActions.notifications.remove(index));
  };

  return ReactDOM.createPortal(
    <Styles.Wrapper
      ref={wrapperRef}
      maxWidth={maxWidth}
    >
      {notifications.map((notification, index) => {
        if (notification.maxWidth !== maxWidth) {
          setMaxWidth(notification.maxWidth);
        }

        return (
          <Styles.Notification
            index={index}
            data-testid={`notification-item-${index}`}
            maxWidth={notification.maxWidth}
            background={notification.backGround}
            key={`notification-${notification.label}`}
          >
            <Alert
              width="full"
              variant={notification.type}
              description={notification.label}
              onClose={() => removeNotification(index)}
            />
          </Styles.Notification>
        );
      })}
    </Styles.Wrapper>,
    domNode,
  );
};

export default Notifications;
