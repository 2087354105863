import React from 'react';
import * as Style from './IconChevronRight.style';

const IconChevronRight = ({ color = '#1d5297', testId = 'icon-chevron-right', size = 30 }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g id="Grupo_39196" data-name="Grupo 39196" transform="translate(-267.293 -49.171)">
        <g id="expand_more_black_24dp" transform="translate(267.293 79.171) rotate(-90)">
          <path id="Caminho_37341" data-name="Caminho 37341" d="M30,30H0V0H30Z" fill="none" opacity="0.87" />
          <path id="Caminho_37342" data-name="Caminho 37342" d="M24.807,0,14.055,10.728,3.3,0,0,3.3,14.055,17.358,28.109,3.3Z" transform="translate(0.945 6.546)" fill={color} />
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);


export default IconChevronRight;
