const styles = theme => ({
  card: {
    borderLeft: `5px solid ${theme.color.indigo}`,
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    background: '#fff',
    padding: '18px 35px',
    height: '240px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
    },
  },
  header: {
    display: 'flex',
    marginBottom: '15px',
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: 0,
    marginRight: '10px',
  },
  content: {
    flex: 1,
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
  contentLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warning: {
    padding: '15px',
    borderRadius: '2px',
  },
  warningLink: {
    color: theme.color.indigo,
  },
  actions: {
    textAlign: 'center',
  },
  btnHistory: {
    borderColor: theme.color.geyser,
    marginRight: '10px',
    color: theme.color.indigo,
  },
  btnLoading: {
    fontSize: '14px',
    color: theme.color.tundora,
  },
  loader: {
    marginRight: '5px',
    color: theme.color.tundora,
  },
});

export default styles;
