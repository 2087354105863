import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family.secondary};
    gap: 24px;
    margin: 8px;
  `}
`;

export const Title = styled.p`
  ${({ theme, isMobile }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${!isMobile && theme.font.weights.bold};
    margin: 0;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
  `}
`;

export const Description = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

export const SubDescription = styled(Description)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
  `}
`;

export const ButtonContainer = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

export const Container = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
  `}
`;

export const ItemsContainer = styled.div`
  ${({ isMobile }) => css`
    display: flex;
    flex-direction: ${isMobile && 'column'};
    gap: 24px;
    justify-content: ${!isMobile && 'space-between'};
  `}
`;

export const FrameWrapper = styled.div`
  ${() => css`
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 1px 3px #00000016;
    display: flex;
    justify-content: center;
    row-gap: 16px;
    padding: 16px;
  `}
`;

export const NoticeContainer = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

export const ResultIcon = styled.div`
  ${({ completed, theme }) => css`
    align-items: center;
    border-radius: 50%;
    display: flex;
    min-height: 40px;
    justify-content: center;
    margin-right: 16px;
    min-width: 40px;
    & svg {
      fill: #FFFF;
    };

    background-color: ${completed ? theme.v2.colors.feedback.activePure : theme.v2.colors.feedback.pendingPure};
  `}
`;

export const InfoAlert = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-size: 12px;
    gap: 8px;

    a {
      color: ${theme.v2.colors.brand.primaryBlue};
    }
  `}
`;

export const AccordionListContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    row-gap: 20px;
  `}
`;

export const ListItem = styled.div`
  ${() => css`
    display: flex;
    align-items: top;
    gap: 4px;

    p {
      margin: 0;
    };
  `}
`;

export const ListMarker = styled.p`
  ${() => css`
    user-select: none;
  `}
`;

export const ModalityContainer = styled.div`
  ${() => css`
    display: flex;
    gap: 4px;
  `}
`;

export const TooltipContent = styled.div`
  ${() => css`
    max-width: 232px;
  `}
`;

export const IconInfoContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;

    & svg {
      fill: ${theme.v2.colors.brand.primaryBlue};
    },
  `}
`;
