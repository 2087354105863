import React, { useState } from 'react';
import {
  withStyles, Typography, Card,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import AlertBox from '@/components/Security/AlertBox';
import { SITELOCK_SMARTSCAN_HELP_URL } from '@/config/urls/sitelock';
import { makeSealUrl } from '@/utils/Formatters/handleStrings';
import ShieldForm from './ShieldForm';
import ShieldPreview from './ShieldPreview';
import styles from './styles';

const SitelockShield = ({ classes, t }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [shieldCopy, setShieldCopy] = useState(false);
  const {
    domain, shieldSaved, shield, smartscan,
  } = useSelector(state => state.sitelock);

  const handleOpen = () => {
    setFormVisible(!formVisible);
  };

  const shouldRenderShield = smartscan.configured || !smartscan.active;

  const handleCopy = () => {
    const el = document.createElement('textarea');
    el.value = makeSealUrl(domain);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setShieldCopy(true);
    setTimeout(() => {
      setShieldCopy(false);
    }, 3000);
  };

  const renderShield = () => (
    <>
      <Typography role="heading" variant="h5" data-testid="seal-preview-title" className={classes.previewTitle}>{t('sitelock.SealPreview')}</Typography>
      <ShieldPreview />
      {!formVisible && (
      <div className={classes.buttons}>
        <PrimaryButton
          role="button"
          className={classes.button}
          onClick={handleOpen}
          data-testid="seal-btn-open"
        >
          {t('sitelock.SealEditBtn')}
        </PrimaryButton>
        <PrimaryButton
          role="button"
          className={classnames([classes.outlinedButton, classes.button])}
          data-testid="seal-btn-copy"
          onClick={handleCopy}
        >
          {t('sitelock.SealCopyBtn')}
        </PrimaryButton>
      </div>
      )}
      <div data-testid="form-container" className={classnames([classes.smooth, !formVisible && classes.hide])}>
        <ShieldForm handleOpen={handleOpen} handleCopy={handleCopy} sealUrl={makeSealUrl(domain)} shield={shield} />
      </div>
    </>
  );

  const renderAlert = () => (<AlertBox message={t('sitelock.smartscan.sealUnavailable')} url={SITELOCK_SMARTSCAN_HELP_URL} urlTitle={t('sitelock.smartscan.urlTitle')} />);

  return (
    <div className={classes.wrapper}>
      <Card className={classnames([classes.card, classes.flexTopCard])}>
        <div className={classes.verticalFlexCard}>
          <Typography role="heading" data-testid="seal-title" variant="h1" className={classes.subtitle}>{t('sitelock.SealTitle')}</Typography>
          <Typography variant="body1" data-testid="seal-subtitle" className={classes.description}>{t('sitelock.SealSubtitle')}</Typography>
          {shouldRenderShield ? renderShield() : renderAlert()}
        </div>
      </Card>
      <div>
        <div data-testid="seal-save-toast" className={classnames(classes.toast, classes.toastSuccess, shieldSaved && classes.toastOpen)}>{t('sitelock.sealSaved')}</div>
        <div data-testid="seal-success-toast" className={classnames(classes.toast, classes.toastSuccess, shieldCopy && classes.toastOpen)}>{t('sitelock.SealCopySuccess')}</div>
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(SitelockShield));
