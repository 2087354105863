import { CALL_API } from '@/middleware/api';
import {
  PAYPAL_AGREEMENT,
  PAYPAL_CANCEL_AGREEMENT,
  PAYPAL_CREATE_AGREEMENT_TOKEN,
  PAYPAL_PAYMENT_AGREEMENT,
} from '@/config/api';
import {
  REQUEST_PAYPAL_VERIFY_AGREEMENT,
  RECEIVE_PAYPAL_VERIFY_AGREEMENT,
  ERROR_PAYPAL_VERIFY_AGREEMENT,
  REQUEST_PAYPAL_CREATE_AGREEMENT_TOKEN,
  RECEIVE_PAYPAL_CREATE_AGREEMENT_TOKEN,
  ERROR_PAYPAL_CREATE_AGREEMENT_TOKEN,
  REQUEST_PAYPAL_CREATE_AGREEMENT,
  RECEIVE_PAYPAL_CREATE_AGREEMENT,
  ERROR_PAYPAL_CREATE_AGREEMENT,
  REQUEST_PAYPAL_PAYMENT_AGREEMENT,
  RECEIVE_PAYPAL_PAYMENT_AGREEMENT,
  ERROR_PAYPAL_PAYMENT_AGREEMENT,
  REQUEST_PAYPAL_CANCEL_AGREEMENT,
  RECEIVE_PAYPAL_CANCEL_AGREEMENT,
  ERROR_PAYPAL_CANCEL_AGREEMENT,
} from './actionsTypes';

export const verifyAgreementClient = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYPAL_AGREEMENT,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_VERIFY_AGREEMENT }),
      success: ({
        // eslint-disable-next-line camelcase
        success, agreement_id, payer_email, payer_first_name, payer_last,
      }) => ({
        type: RECEIVE_PAYPAL_VERIFY_AGREEMENT,
        success,
        agreementId: agreement_id,
        payerEmail: payer_email,
        payerFirstName: payer_first_name,
        payerLast: payer_last,
      }),
      error: () => ({ type: ERROR_PAYPAL_VERIFY_AGREEMENT }),
    },
  },
});


export const createAgreementToken = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYPAL_CREATE_AGREEMENT_TOKEN,
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_CREATE_AGREEMENT_TOKEN }),
      success: ({ token_id: tokenId }) => ({ type: RECEIVE_PAYPAL_CREATE_AGREEMENT_TOKEN, tokenId }),
      error: () => ({ type: ERROR_PAYPAL_CREATE_AGREEMENT_TOKEN }),
    },
  },
});

export const createAgreement = tokenId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYPAL_AGREEMENT,
    method: 'POST',
    body: { token_id: tokenId },
    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_CREATE_AGREEMENT }),
      success: () => ({ type: RECEIVE_PAYPAL_CREATE_AGREEMENT }),
      error: () => ({ type: ERROR_PAYPAL_CREATE_AGREEMENT }),
    },
  },
});

export const paymentAgreement = invoiceId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYPAL_PAYMENT_AGREEMENT,
    method: 'POST',
    body: { invoice_id: invoiceId },
    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_PAYMENT_AGREEMENT }),
      success: () => ({ type: RECEIVE_PAYPAL_PAYMENT_AGREEMENT }),
      error: ({ response }) => ({ type: ERROR_PAYPAL_PAYMENT_AGREEMENT, message: response.data.message }),
    },
  },
});

export const cancelAgreementWithBody = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${PAYPAL_CANCEL_AGREEMENT}`,
    method: 'POST',
    body: { cancel_on_paypal: true },

    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_CANCEL_AGREEMENT }),
      success: () => ({ type: RECEIVE_PAYPAL_CANCEL_AGREEMENT }),
      error: () => ({ type: ERROR_PAYPAL_CANCEL_AGREEMENT }),
    },
  },
});


export const cancelAgreement = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${PAYPAL_CANCEL_AGREEMENT}`,
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_PAYPAL_CANCEL_AGREEMENT }),
      success: () => ({ type: RECEIVE_PAYPAL_CANCEL_AGREEMENT }),
      error: () => ({ type: ERROR_PAYPAL_CANCEL_AGREEMENT }),
    },
  },
});
