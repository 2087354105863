import React from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './DomainConfigurationReview.styles';
import { Button } from '@/pages/common/v1/Button';
import { Alert } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { domainsAnalytics } from '@/analytics/domains';

const HostGatorAlterPlatformModal = ({
  handleCloseModal,
  nameServers,
  handleContinueClick,
  availablePlans,
  selectedPlanId,
}) => {
  const { domains } = useLocale();
  const { domainConfigurationReview } = domains && domains.dnsWizard && domains.dnsWizard.modals;
  const selectedPlan = availablePlans && availablePlans.find(plan => (plan.id === selectedPlanId));


  const handleButtonClick = () => {
    domainsAnalytics.dnsWizard.configureCtaClick();
    handleCloseModal && handleCloseModal();
    handleContinueClick && handleContinueClick();
  };

  const handleKeepConfigurationsClick = () => {
    domainsAnalytics.dnsWizard.keepConfigurationsCtaClick();
    handleCloseModal && handleCloseModal();
  };

  return (
    <Modal onClose={handleCloseModal} maxWidth={625}>
      <Styles.Wrapper>
        <Styles.Title>
          {domainConfigurationReview.title}
        </Styles.Title>

        <Styles.Subtitle>
          {selectedPlan ? domainConfigurationReview.subtitleWithHgPlan(selectedPlan) : domainConfigurationReview.subtitle}
        </Styles.Subtitle>

        <Alert description={domainConfigurationReview.description(nameServers)} variant="error" />

        <Styles.ButtonWrapper>
          <Button label={domainConfigurationReview.keepConfigurations} onClick={handleKeepConfigurationsClick} variant="tertiary" />
          <Button label={domainConfigurationReview.configure} onClick={handleButtonClick} />
        </Styles.ButtonWrapper>
      </Styles.Wrapper>
    </Modal>
  );
};

export default HostGatorAlterPlatformModal;
