const styles = theme => ({
  divider: {
    display: 'block',
    width: '100%',
    height: '1px',
    borderBottom: `1px dashed ${theme.color.mystic}`,
  },
});

export default styles;
