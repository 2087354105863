import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const AccordionItem = styled.div`
  align-self: center;
  margin: 24px 0px 48px 0px;
`;

export const Header = styled.header``;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: ${theme.v2.font.family.primary};
    line-height: normal;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    color: ${theme.v2.colors.neutral.lowPure};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.md}){
      font-weight: ${theme.font.weights.regular};
    }
  `}
`;

export const ChoiceDescription = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`;

export const SelectContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 38px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      padding: 0 24px;
    }
  `};
`;

export const Description = styled.div`
  ${({ theme, accordionDescription }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.font.weights.regular};
    color: ${theme.v2.colors.neutral.lowPure};
    margin: 0;

    strong {
      font-weight: ${theme.font.weights.medium};
    }

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin-left: ${accordionDescription && '24px'};
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({
    theme,
    secondScreen,
    paddingTop,
    paddingBottom,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${paddingTop ? '24px' : 0};
    padding-bottom: ${paddingBottom ? '16px' : 0};

    @media (max-width: ${theme.v2.breakpoints.md}){
      padding: ${secondScreen && 0};
      flex-direction: column;
      gap: 16px;
    }
  `};
`;

export const ButtonLoadingContainer = styled.div`
  ${({ tryAgain }) => css`
    min-width: ${tryAgain ? '192px' : '162px'};
  `};
`;

export const AccordionContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const AccordionTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}){
      flex-direction: column;
      gap: 12px;
      justify-content: start;
    }
  `}
`;

export const AccordionName = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    gap: 8px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      width: 100%;
    }
  `}
`;
export const AccordionNameSpan = styled.span`
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AccordionTag = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: right;
    margin-left: 28px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      width: 100%;
      justify-content: start;
      margin-left: 28px;
    }
  `}
`;

export const AccordionDescription = styled.div`
  ${({ theme }) => css`
    font-size: 12px;

    strong {
      font-weight: 500;
    }

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin-left: 28px;
    }
  `};
`;

export const NoticeContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 24px 24px 0 0;

    @media (max-width: ${theme.v2.breakpoints.md}){
      padding: 16px;
      border-radius: 4px;
      box-shadow: ${theme.v2.shadows.light};
      margin: 0;
    }
  `}
`;

export const ResultIcon = styled.div`
  ${({ theme, success }) => css`
    align-items: center;
    background-color: ${success ? theme.v2.colors.feedback.activePure : theme.v2.colors.feedback.problemMedium};
    border-radius: 50%;
    display: flex;
    min-height: 40px;
    justify-content: center;
    margin-right: 24px;
    min-width: 40px;
  `}
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
