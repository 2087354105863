import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './ModalTitanTrialColorful.styles';
import ProEmailActiveTrial from '../ProEmail/ProEmailsNewList/ProEmailActiveTrial';
import { trialActiveLocale } from './ModalTitanTrialColorful.locale';
import { Mark } from '@/pages/common/v1/Mark';
import { Chip } from '@/pages/common/v1/Chip';
import { ALPHA_DISABLED_CHECKBOX_IN_TRIAL_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ModalTitanTrialColorful = ({
  isOpen,
  onClose,
  selectedDomain,
  essentialData,
  offerContext,
  location,
  isEnabledByURLParam = false,
}) => {
  const [submittedOut, setSubmittedOut] = useState(isOpen);
  const disableConfirmationCheckbox = useFeatureIsOn(ALPHA_DISABLED_CHECKBOX_IN_TRIAL_MODAL_IN_HIREPLAN);

  return (
    isOpen && (
      <Modal onClose={onClose} disablePadding maxWidth={800} maxHeight={680} testId="modal-to-enable-titan-trial">
        <Styles.WrapperContent>
          <Styles.Header>
            <Styles.BackButton type="button" onClick={onClose} data-testid="back-button">
              <Styles.ArrowBackIcon />
            </Styles.BackButton>
            <Styles.WrapperTextsHeader>
              <Styles.Title data-testid="title-with-domain">
                {trialActiveLocale.title}
                <Chip
                  secondaryColor="#FFFFFF"
                  primaryColor="#30B47A"
                  label={trialActiveLocale.tagOfTrialTime}
                  weight="bold"
                  variant="outlined"
                />
              </Styles.Title>
            </Styles.WrapperTextsHeader>
          </Styles.Header>
          <Styles.Content>
            <Styles.DescriptionProduct data-testid="description-product">
              {trialActiveLocale.productDescription[1]}
              <strong>{essentialData.name}</strong>
              {trialActiveLocale.productDescription[2]}
            </Styles.DescriptionProduct>
            <Styles.DescriptionProduct>
              {trialActiveLocale.productDescription[3]}
              <strong><Mark variant="green" weight="bold" text={trialActiveLocale.productDescription[4]} /></strong>
            </Styles.DescriptionProduct>
          </Styles.Content>
        </Styles.WrapperContent>
        <Styles.WrapperForm>
          <ProEmailActiveTrial
            renderedEmailList={[]}
            domainObject={selectedDomain.domainObject}
            selectedDomain={selectedDomain.domain.replace(/@/g, '')}
            location={location}
            productName={essentialData.name}
            productValue={essentialData.price}
            offerContext={offerContext}
            submittedOut={submittedOut}
            setSubmittedOut={setSubmittedOut}
            disableConfirmationCheckbox={disableConfirmationCheckbox}
            isEnabledByURLParam={isEnabledByURLParam}
          />
        </Styles.WrapperForm>
      </Modal>
    )
  );
};


ModalTitanTrialColorful.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedDomain: PropTypes.shape({
    id: PropTypes.string,
    domain: PropTypes.string,
  }).isRequired,
  essentialData: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
};


export default ModalTitanTrialColorful;
