const styles = theme => ({
  cardWrapper: {
    marginBottom: 5,
  },

  cardContent: {
    '&:last-child': {
      display: 'flex',
      height: 59,
      padding: '0px 16px',


      [theme.breakpoints.down(960)]: {
        height: 'unset',
      },
    },
  },

  cardItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  itemTitle: {
    color: theme.color.silverChalice,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '20px',

    [theme.breakpoints.down(960)]: {
      margin: '12px 0px 0px 0px',
    },
  },

  truncate: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  fieldValue: {
    color: theme.color.emperor,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '17px',
  },

  LinkButton: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    boxShadow: 'none',
    color: theme.color.white,
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    padding: 0,
    textDecoration: 'unset',

    '&:disabled': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: theme.color.disabled,
    },

    [theme.breakpoints.down(960)]: {
      margin: '24px 0px 24px',
      maxWidth: '75%',
    },
  },
});

export default styles;
