const styles = theme => ({

  dialogContent: {
    textAlign: 'center',
  },

  closeDialog: {
    height: '45px',
    textAlign: 'right',
  },

  btnClose: {
    color: theme.color.tundora,
    padding: '6px',
    position: 'absolute',
    right: '15px',
    top: '15px',
  },

  cpanelDialogActiveDialog: {
    borderRadius: 0,
    maxHeight: '350px',
    width: '600px',
  },

  content: {
    padding: '35px 40px',
    position: 'relative',
  },
  activeCPanel: {
    alignItems: 'center',
    background: theme.color.indigo,
    color: theme.color.white,
    display: 'flex',
    fontSize: '16px',
    fontWeight: 400,
    height: '40px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '24px',
    maxWidth: '320px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.color.chambray,
      border: `1px solid ${theme.color.chambray}`,
      color: theme.color.white,

    },
  },

  titleDialog: {
    fontSize: '24px',
    fontStyle: 'medium',
    fontWeight: 'bold',
    letterSpacing: '0.01px',
    marginBottom: '24px',
    opacity: 1,

  },
  descriptionDialog: {
    color: theme.color.dark,
    fontSize: '16px',
    fontStyle: 'Regular',
  },

  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 5px',
  },
  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },
  warning: {
    borderRadius: '2px',
    marginBottom: '30px',
    padding: '15px',
  },
  actions: {
    textAlign: 'center',
  },
  btnCancel: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    marginRight: '10px',
  },
});

export default styles;
