import React, { useState } from 'react';
import * as Styles from './Textarea.styles';

const Textarea = ({
  onChange,
  forwardRef,
  testId = 'textarea',
  value = '',
  maxLength = 250,
}) => {
  const [textareaValue, setTextareaValue] = useState(value);
  const handleOnChange = (event) => {
    onChange && onChange(event);

    if (event.target.value.length > maxLength) {
      return;
    }

    setTextareaValue(event.target.value);
  };

  return (
    <Styles.Wrapper
      value={textareaValue}
      ref={forwardRef}
      onChange={handleOnChange}
      data-testid={testId}
    />
  );
};

export default Textarea;
