import styled, { css } from 'styled-components';

export const SitelockDetailsWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    gap: 8px;
    padding-bottom: 72px;
  `};
`;

export const Section = styled.section`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    margin: 0 24px;
    padding: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const HeadSection = styled(Section)`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 12px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: start;
      flex-direction: column;
    }
  `};
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: auto;

    button {
      white-space: nowrap;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 0;

      button {
        font-size: ${theme.v2.font.sizes.xxxs};
        padding: 4px 16px;
      }
    }
  `};
`;

export const ButtonLoadingWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: 1px 0;
    width: 128px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      padding: 0;
      width: 110px;
    }
  `};
`;

export const FaqSection = styled(Section)`
  ${({ theme }) => css`
    width: 560px;
    padding: 0;
    margin-top: 8px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width: 50%;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: auto;
    }
  `};
`;

export const ProductName = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    white-space: nowrap;

    media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const ProductDomain = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `};
`;

export const TabsWrapper = styled.div`
    > div button {
      div {
        padding: 0;
        margin: 0;
        width: fit-content;
      }
    }
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 26px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-top: 24px;
    }
  `};
`;

export const FaqHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 248px;
  width: 100%;
`;
