import React, { } from 'react';


import useLocale from '@/hooks/useLocale';
import * as Styles from './TicketCardInfo.styles';

const TicketCardInfo = ({
  enabledContentDoubts = true,
}) => {
  const { billing: billingLocale } = useLocale();
  const { manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale } = billingLocale;

  const infoSelectionTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.infoSelectionTicketOption;
  const doubtsTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketDescription;
  const doubtsTicketLinkLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketLink;
  const doubtsTicketURLLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketURL;

  return (
    <>
      <Styles.Content data-testid="ticket-card-info-content">
        <>
          {infoSelectionTicketLabel[0]}
          <br />
          <>
            <strong>{infoSelectionTicketLabel[1]}</strong>
            {infoSelectionTicketLabel[2]}
            <strong>{infoSelectionTicketLabel[3]}</strong>
          </>
        </>
      </Styles.Content>
      {enabledContentDoubts && (
      <Styles.ContentDoubts>
        {doubtsTicketLabel}
        <Styles.Link
          data-testid="generate-ticket-info-link"
          href={doubtsTicketURLLabel}
          target="_blank"
        >
          {doubtsTicketLinkLabel}
        </Styles.Link>
      </Styles.ContentDoubts>
      )}
    </>
  );
};


export default TicketCardInfo;
