const styles = theme => ({
  text: {
    color: theme.color.indigo,
  },
  wrapper: {
    padding: '0',
    position: 'relative',
  },
  cardGrid: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    gap: '22px',
    justifyContent: 'center',
    paddingBottom: '48px',
    [theme.breakpoints.up(600)]: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  title: {
    color: theme.color.tundora,
    font: 'normal normal normal 22px/29px Roboto',
    letterSpacing: '0.01px',
    marginTop: '36px',
    textAlign: 'left',
  },
  subtitle: {
    color: theme.color.tundora,
    font: 'normal normal normal 14px/16px Roboto',
    letterSpacing: '0.01px',
    marginTop: '12px',
    marginBottom: '24px',
    textAlign: 'left',
  },
});

export default styles;
