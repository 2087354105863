import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import * as Styles from './LinkNaBioWizardHandler.styles';
import {
  LINK_AVAILABLE, LINK_NOT_AVAILABLE, colorSchemes, linkNaBioEnum, templates,
} from './LinkNaBioWizardHandler.enum';
import { ThemeContext } from 'styled-components';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { linkNaBioActions } from '@/redux/modules';
import { FeedbackStepper } from '@/pages/common';
import { LinkNaBioStep1 } from './components/LinkNaBioStep1';
import { LinkNaBioStep2 } from './components/LinkNaBioStep2';
import { LinkNaBioStep3 } from './components/LinkNaBioStep3';
import { LinkNaBioStep4 } from './components/LinkNaBioStep4';
import { useHistory, useParams } from 'react-router';
import { linkNaBioAnalytics } from '@/analytics/linkNaBio';
import { isMobile } from '@/utils/Validators/validation';
import { CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import withErrorBoundary from '@/utils/errorBoundary';

const LinkNaBioWizardHandler = ({
  testId = 'link-na-bio-wizard',
}) => {
  const [activeStep, setActiveStep] = useState(linkNaBioEnum.steps.LINK_CHOOSE);
  const theme = useContext(ThemeContext);
  const { v2 } = theme;
  const [dataStep1, setDataStep1] = useState({
    link: '',
    checked: false,
    id: undefined,
  });
  const [dataStep2, setDataStep2] = useState({
    links: [],
    title: '',
    description: '',
    emoji: undefined,
    socialMediaLinks: [],
  });
  const [dataStep3, setDataStep3] = useState({
    colorScheme: colorSchemes.SIMPLE,
    template: templates.FUNNY,
  });
  const [fieldUpdate, setFieldUpdate] = useState(false);
  const [onMobile, setOnMobile] = useState(isMobile);

  const {
    loading, available, profiles, linksLoading, socialMediaLoading, socialMediaList,
  } = useSelector(state => state.linkNaBio);
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const history = useHistory();
  const showSocialMedia = useFeatureIsOn(CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA);

  const { linkNaBio: linkNaBioLocale, routes: routesLocale } = useLocale();

  window.addEventListener('resize', () => {
    const smallWidth = window.innerWidth < 700;
    setOnMobile(smallWidth);
  });

  const getSocialIcons = useCallback(() => {
    dispatch(linkNaBioActions.socialMedia.icons.request());
  }, [dispatch]);

  useEffect(() => {
    showSocialMedia && getSocialIcons();
  }, [showSocialMedia, getSocialIcons]);

  const setProfileDataToState = (profile) => {
    if (profile) {
      setDataStep1({
        checked: true,
        id: profile.id,
        link: profile.username,
      });

      setDataStep2({
        title: profile.title,
        description: profile.description,
        links: profile.links,
        socialMediaLinks: profile.icons,
      });

      setDataStep3({
        colorScheme: profile.color_scheme,
        template: profile.template,
      });
    }
  };

  const handleEditProfile = useCallback(() => {
    const selectedProfile = profiles.find(profile => profile.id === Number(profileId));

    if (selectedProfile && selectedProfile.id) {
      setActiveStep(linkNaBioEnum.steps.CONTENT);
      setProfileDataToState(selectedProfile);
    } else {
      history.push(routesLocale.linkNaBio);
    }
  }, [profileId, profiles, routesLocale, history]);

  useEffect(() => {
    if (profileId) {
      handleEditProfile();
    }
  }, [handleEditProfile, profileId]);


  const stepAnalytics = useCallback(() => {
    if (activeStep === linkNaBioEnum.steps.LINK_CHOOSE) {
      linkNaBioAnalytics.accessToStep1();
    }
    if (activeStep === linkNaBioEnum.steps.CONTENT) {
      linkNaBioAnalytics.accessToStep2();
    }
    if (activeStep === linkNaBioEnum.steps.TEMPLATE) {
      linkNaBioAnalytics.accessToStep3();
    }
    if (activeStep === linkNaBioEnum.steps.SHARE) {
      linkNaBioAnalytics.publishLinkNaBio();
    }
  }, [activeStep]);

  useEffect(() => {
    stepAnalytics();
  }, [stepAnalytics]);

  const availableErrorAnalytics = useCallback(() => {
    if (available === LINK_NOT_AVAILABLE && dataStep1.checked) {
      linkNaBioAnalytics.linkUnavailable();
    }
  }, [available, dataStep1.checked]);

  useEffect(() => {
    availableErrorAnalytics();
  }, [availableErrorAnalytics]);

  const steps = [
    {
      id: linkNaBioEnum.steps.LINK_CHOOSE,
      label: linkNaBioLocale.stepper.linkChoose,
    },
    {
      id: linkNaBioEnum.steps.CONTENT,
      label: linkNaBioLocale.stepper.content,
    },
    {
      id: linkNaBioEnum.steps.TEMPLATE,
      label: linkNaBioLocale.stepper.template,
    },
    {
      id: linkNaBioEnum.steps.SHARE,
      label: linkNaBioLocale.stepper.share,
      markAsDone: activeStep === linkNaBioEnum.steps.SHARE,
    },
  ];


  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);


  const getFeedbackStepperProps = () => {
    const defaultProps = {
      steps,
      activeStep,
      stepActiveColor: v2.colors.neutral.highPure,
      stepActiveBgColor: v2.colors.brand.primaryMedium,
      stepDoneBgColor: v2.colors.feedback.activeLight,
      stepDoneColor: v2.colors.neutral.highPure,
      testId: 'wizard',
    };

    return {
      ...defaultProps,
    };
  };

  const getStep1Props = () => {
    const sanitizeLink = (link) => {
      if (!link || typeof link !== 'string') {
        return '';
      }

      return link.toLowerCase().replace(/[^a-zA-Z0-9-_.]/g, '');
    };

    const setLinkValue = (linkValue) => {
      const newData = {
        ...dataStep1,
        link: linkValue,
      };

      if (dataStep1.checked) {
        newData.checked = false;
      }

      setDataStep1(newData);
    };

    const checkAvailability = () => {
      if (dataStep1.link) {
        const sanitizedLink = dataStep1.link;

        if (sanitizedLink) {
          dispatch(linkNaBioActions.profile.availability.request({ link: sanitizedLink }));
        }

        setDataStep1({ ...dataStep1, checked: true, link: sanitizedLink });
      }
    };

    const errorMessages = () => {
      if (available === LINK_NOT_AVAILABLE && dataStep1.checked) {
        return linkNaBioLocale.step1.error;
      }

      if (dataStep1.link) {
        const regex = /[^a-zA-Z0-9-_.]/;
        const hasError = regex.test(dataStep1.link);

        if (hasError) {
          return linkNaBioLocale.step1.error;
        }
      }

      return undefined;
    };

    const defaultProps = {
      checkAvailability,
      linkNaBioLoading: loading,
      errorMessage: errorMessages(),
      linkValue: dataStep1.link,
      nextStep,
      setLinkValue,
      stepLocale: linkNaBioLocale.step1,
      successMessage: available === LINK_AVAILABLE && dataStep1.checked ? linkNaBioLocale.step1.success : undefined,
      sanitizeLink,
      onMobile,
    };

    return {
      ...defaultProps,
    };
  };

  const getStep2Props = () => {
    const sanitizeLinkURL = (link) => {
      if (!link || typeof link !== 'string') {
        return '';
      }

      return link;
    };

    const updateLinkNaBio = () => {
      if (fieldUpdate) {
        const dataToSend = {
          title: dataStep2.emoji ? `${dataStep2.emoji} ${dataStep2.title}` : dataStep2.title,
          description: dataStep2.description,
          template: dataStep3.template,
          colorScheme: dataStep3.colorScheme,
          profileId,
          callback: () => null,
        };
        dispatch(linkNaBioActions.profile.update.request(dataToSend));
      }

      setFieldUpdate(false);
      nextStep();
    };

    const handleAddLink = (link) => {
      if (profileId) {
        dispatch(linkNaBioActions.link.add.request({ links: [link], profileId }));
        linkNaBioAnalytics.addLinkStep2();
      }
    };

    const handleUpdateLink = (link) => {
      if (profileId) {
        dispatch(linkNaBioActions.link.update.request({ link, profileId }));
        linkNaBioAnalytics.editLinkStep2();
      }
    };

    const handleDeleteLink = (linkId) => {
      if (profileId) {
        dispatch(linkNaBioActions.link.delete.request({ linkId, profileId }));
        linkNaBioAnalytics.removeLinkStep2();
      }
    };

    const handleReorderLinks = (links) => {
      if (profileId) {
        const assembledLinks = links.map(link => ({ ...link, sort: link.order + 1 }));

        dispatch(linkNaBioActions.link.sort.request({ links: assembledLinks, profileId }));
      }
    };

    const handleAddSocialMedia = (link) => {
      const dataToCreateSocialMedia = {
        value: link.link,
        profile_id: profileId,
        social_icon_id: link.id,
      };

      if (profileId) {
        dispatch(linkNaBioActions.socialMedia.add.request({ links: [dataToCreateSocialMedia], profileId }));
        linkNaBioAnalytics.addLinkStep2();
      }
    };

    const handleUpdateSocialMedia = (link) => {
      if (profileId) {
        dispatch(linkNaBioActions.socialMedia.update.request({ link, profileId }));
        linkNaBioAnalytics.editLinkStep2();
      }
    };

    const handleDeleteSocialMedia = (linkId) => {
      if (profileId) {
        dispatch(linkNaBioActions.socialMedia.delete.request({ linkId, profileId }));
        linkNaBioAnalytics.removeLinkStep2();
      }
    };

    const handleReorderSocialMedia = (links) => {
      if (profileId) {
        const assembledLinks = links.map(link => ({ ...link, sort: link.order + 1 }));

        dispatch(linkNaBioActions.socialMedia.sort.request({ links: assembledLinks, profileId }));
      }
    };

    const socialMediaAlreadyAdded = socialMedia => !dataStep2.socialMediaLinks.some(socialMediaLink => socialMedia.name === socialMediaLink.social_icon.name);
    const availableSocialMedias = socialMediaList.filter(socialMediaAlreadyAdded);

    const defaultProps = {
      handleNextStep: profileId ? updateLinkNaBio : nextStep,
      handleUpdateLink,
      handleDeleteLink,
      handleReorderLinks,
      handleAddLink,
      handleUpdateSocialMedia,
      handleDeleteSocialMedia,
      handleReorderSocialMedia,
      handleAddSocialMedia,
      prevStep,
      setStepData: setDataStep2,
      stepData: dataStep2,
      stepLocale: linkNaBioLocale.step2,
      isEditing: profileId,
      setFieldUpdate,
      previewData: { ...dataStep3 },
      linksLoading,
      socialMediaLoading,
      onMobile,
      sanitizeLinkURL,
      availableSocialMedias,
    };

    return {
      ...defaultProps,
    };
  };

  const getStep3Props = () => {
    const createLinkNaBio = () => {
      const assembledLinks = dataStep2.links.map((link) => {
        const assembledLink = { ...link };
        assembledLink.link = assembledLink.link && assembledLink.link.startsWith('http') ? assembledLink.link : `https://${assembledLink.link}`;
        delete assembledLink.id;
        return assembledLink;
      });

      const assembledSocialMediaLinks = dataStep2.socialMediaLinks.map((link) => {
        const assembledLink = { ...link };
        const isWhatsapp = assembledLink.social_icon && assembledLink.social_icon.name && assembledLink.social_icon.name.toLowerCase() === 'whatsapp';
        const isEmail = assembledLink.social_icon && assembledLink.social_icon.name && assembledLink.social_icon.name.toLowerCase() === 'e-mail';

        assembledLink.value = (assembledLink.value && assembledLink.value.startsWith('http')) || isEmail || isWhatsapp ? assembledLink.value : `https://${assembledLink.link}`;
        assembledLink.social_icon_id = link.social_icon.id;
        delete assembledLink.id;
        return assembledLink;
      });

      const dataToSend = {
        linkName: dataStep1.link,
        title: dataStep2.emoji ? dataStep2.emoji + dataStep2.title : dataStep2.title,
        description: dataStep2.description,
        links: assembledLinks,
        template: dataStep3.template,
        colorScheme: dataStep3.colorScheme,
        icons: assembledSocialMediaLinks,
        callback: nextStep,
      };

      dispatch(linkNaBioActions.profile.create.request(dataToSend));
    };

    const updateLinkNaBio = () => {
      if (fieldUpdate) {
        const dataToSend = {
          template: dataStep3.template,
          colorScheme: dataStep3.colorScheme,
          profileId,
          callback: nextStep,
        };
        dispatch(linkNaBioActions.profile.update.request(dataToSend));
      } else {
        nextStep();
      }

      setFieldUpdate(false);
    };

    const defaultProps = {
      prevStep,
      setStepData: setDataStep3,
      stepData: dataStep3,
      previewData: dataStep2,
      stepLocale: linkNaBioLocale.step3,
      handleNextStep: profileId ? updateLinkNaBio : createLinkNaBio,
      linkNaBioLoading: loading,
      isEditing: profileId,
      setFieldUpdate,
      onMobile,
    };

    return {
      ...defaultProps,
    };
  };

  const getStep4Props = () => {
    const defaultProps = {
      previewData: { ...dataStep2, ...dataStep3 },
      link: dataStep1.link,
      stepLocale: linkNaBioLocale.step4,
      onMobile,
    };

    return {
      ...defaultProps,
    };
  };

  return (

    <Styles.Wrapper data-testid={testId}>
      <Styles.Stepper>
        <FeedbackStepper {...getFeedbackStepperProps()} />
      </Styles.Stepper>

      {activeStep === linkNaBioEnum.steps.LINK_CHOOSE && (
        <LinkNaBioStep1 {...getStep1Props()} />
      )}

      {activeStep === linkNaBioEnum.steps.CONTENT && (
        <LinkNaBioStep2 {...getStep2Props()} />
      )}

      {activeStep === linkNaBioEnum.steps.TEMPLATE && (
        <LinkNaBioStep3 {...getStep3Props()} />
      )}
      {activeStep === linkNaBioEnum.steps.SHARE && (
        <LinkNaBioStep4 {...getStep4Props()} />
      )}
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(LinkNaBioWizardHandler, 'LinkNaBioWizardHandler');
