import styled, { css } from 'styled-components';

export const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Card = styled.div`
  ${({ theme, isDragging }) => css`
    background-color: ${isDragging && theme.v2.colors.neutral.highLight};
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    display: flex;
    gap: 8px;
    padding: 20px;
    max-width: 435px;
    min-height: 76px;
  `}
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  overflow: hidden;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    word-break: break-all;
  `}
`;

export const Url = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
  `}
`;

export const SocialMedia = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: 8px;
`;
