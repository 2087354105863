import React, { useEffect, useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './ExpiredTimePixModal.styles';
import useLocale from '@/hooks/useLocale';
import IconCronometer from '@/media/billing/cronometer.svg';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import { invoicesActions } from '@/redux/modules';
import { INVOICES_STATUS } from '@/config/invoices/invoiceStatus';
import { useDispatch } from 'react-redux';
import Loader from '@/components/Layout/Loader';
import { OriginCalling } from '../PaymentModal/PaymentModal.types';
import { billingAnalytics } from '@/analytics';
import { isMobile } from '@/utils/Validators/validation';


const ExpiredTimePixModal = ({
  onCloseClick = null,
  onUpdateQRCode = null,
  onPaymentSuccessPix = null,
  invoiceId,
  onSwitchPaymentMethod = null,
  originCalling = null,
}) => {
  const { billing: billingLocale } = useLocale();
  const dispatch = useDispatch();
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;

  const {
    manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale,
    expiredTimePixModal: expiredTimePixModalLocale,
  } = billingLocale;

  const titleModalLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.titleLabel);

  const titleLabel = useState(expiredTimePixModalLocale.titleLabel);
  const descriptionLine1 = expiredTimePixModalLocale.descriptionLine1Label;
  const descriptionLine2 = expiredTimePixModalLocale.descriptionLine2Label;
  const changePaymentMethodButtonLabel = useState(expiredTimePixModalLocale.changePaymentMethodLabel);
  const updateQRCodeButtonLabel = useState(expiredTimePixModalLocale.updateQRCodeLabel);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadReCaptcha();
  }, [setLoading]);

  const redirectToSwitchPaymentMethod = (data) => {
    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }

    onSwitchPaymentMethod && onSwitchPaymentMethod();
  };

  const redirectToUpdateQRCodeModal = (data) => {
    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }

    onUpdateQRCode && dispatch(invoicesActions.invoices.generatePix.request(invoiceId, onUpdateQRCode));
  };

  const closeClick = (data) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('5_fechar');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('5_fechar');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('5_fechar');
    }

    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }
    onCloseClick && onCloseClick();
  };

  const handleOnCloseClick = () => {
    setLoading(true);
    dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, closeClick));
  };

  const handleOnSwitchPaymentMethod = () => {
    setLoading(true);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('5_alterar_forma');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('5_alterar_forma');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('5_alterar_forma');
    }
    dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, redirectToSwitchPaymentMethod));
  };

  const handleOnUpdateQRCode = () => {
    setLoading(true);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('5_atualizar_qrcode');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('5_atualizar_qrcode');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('5_atualizar_qrcode');
    }
    dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, redirectToUpdateQRCodeModal));
  };

  return (
    <Modal
      maxWidth={726}
      onClose={handleOnCloseClick}
      testId="expired-time-pix-modal"
      disablePadding
    >
      <Styles.Content>
        <Styles.Header>
          <Styles.TitleModal data-testid="title-modal-expired-time-pix">{titleModalLabel}</Styles.TitleModal>
        </Styles.Header>
        <Styles.ContentData>
          <Styles.Cronometer
            src={IconCronometer}
            loading="lazy"
          />
          <Styles.Title data-testid="data-title-modal">
            {titleLabel}
          </Styles.Title>
          <Styles.Label data-testid="description-line-1-modal-data">
            {descriptionLine1[0]}
            {!isMobile && (
              descriptionLine1[1]
            )}
            {isMobile && (
              descriptionLine1[2]
            )}
            {descriptionLine1[3]}
          </Styles.Label>
          <Styles.Label data-testid="description-line-2-modal-data">
            {descriptionLine2}
          </Styles.Label>
        </Styles.ContentData>
        {!loading && (
        <Styles.Controls>
          <Styles.ControlButton>
            <Button
              testId="change-payment-method-button"
              onClick={handleOnSwitchPaymentMethod}
              variant="text"
              label={changePaymentMethodButtonLabel}
            />
            <Button
              testId="update-qrcode-pix"
              onClick={handleOnUpdateQRCode}
              label={updateQRCodeButtonLabel}
            />
          </Styles.ControlButton>
        </Styles.Controls>
        )}
        {loading && (
          <Loader />
        )}
      </Styles.Content>
    </Modal>
  );
};


export default ExpiredTimePixModal;
