const { COUNTRY } = require('..');

const ZENDESK_CONFIG_MX = {
  appId: 'R10XIKCG3E',
  apiKey: 'f8ba696bb2f40ca12b2a408ca5c1d4c3',
  indexName: 'zendesk_hostgatormx_articles',
};

const ZENDESK_CONFIG_BY_BRAND = {
  br: {
    appId: '79GJXL4E2Y',
    apiKey: '992f3df3d603bd7cb85bb66a023bb0f8',
    indexName: 'zendesk_hostgatorbr_articles',
  },
  mx: ZENDESK_CONFIG_MX,
  cl: ZENDESK_CONFIG_MX,
  co: ZENDESK_CONFIG_MX,
};


export const ZENDESK = ZENDESK_CONFIG_BY_BRAND[COUNTRY];
