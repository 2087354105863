import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    margin-top: 12px;
    margin: 0;
    padding: 0 32px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-top: 17px;
      padding: 0 12px;
    }
  `}
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.error};
    margin: 8px 0px 0px 0px;
    max-width: 408px;
    font-size: 12px;
    line-height: 18px;
    font-family: ${theme.v1.font.family.primary};

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding-top: 20px;
    },
  `}
`;
export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 15px 0px 0px;
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    margin: 8px 0px 30px 0px;
  `}
`;

export const InputsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const Fields = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 0px 8px 0px 0px;
    width: 100%;

    > span {
      width: 100%;
    }

    input {
      background-color: ${theme.v1.color.white.primary};
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px 0px 24px 0px;
    }
  `}
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 0px 24px 0px;

  div {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }

  button {
    min-height: 40px;
    min-width: 167px;
  }
`;
