const styles = theme => ({
  card: {
    border: `1px solid ${theme.color.regentGray}`,
    width: '212px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '2px',
    position: 'relative',
    boxSizing: 'border-box',
    paddingTop: '5px',
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '291px',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      height: 'auto',
      padding: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    color: theme.color.emperor,
    marginBottom: 10,
    marginTop: 0,
    fontWeight: 500,
    fontSize: '14px',
    padding: '0 20px',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  subtitle: {
    color: theme.color.regentGray,
    fontSize: '12px',
    padding: '0',
    display: 'inline-block',
    userSelect: 'none',
  },
  link: {
    display: 'block',
    color: theme.color.indigo,
    fontSize: '14px',
    userSelect: 'none',
    '&:hover': {
      color: theme.color.indigo,
    },
    '&:focus': {
      color: theme.color.indigo,
    },
    '&:active': {
      color: theme.color.indigo,
    },
  },
  image: {
    padding: 30,
    objectFit: 'contain',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      height: '53px',
      padding: '30px 18px 30px 30px',
    },
  },
  wrapperDescription: {
    textAlign: 'center',
    padding: '0 10%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingRight: 15,
      paddingLeft: 6,
    },
  },
});

export default styles;
