import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { IconFinancy, IconDropdownArrow, IconVerticalEllipsis } from '@/icons';
import * as Styles from './Dropdown.styles';

const Dropdown = ({
  label = 'Faturas e Assinaturas',
  testId = 'dropdown',
  iconLeft = <IconFinancy color="#4480C5" />,
  items = {
    alignItems: 'center',
    list: [
      {
        id: 'item-01', label: 'button text', icon: <IconFinancy color="#4480C5" />, onClick: () => {},
      },
      {
        id: 'item-02', label: 'button text', icon: <IconFinancy color="#4480C5" />, onClick: () => {},
      },
    ],
  },
  variantList = 'default',
  dropdownColor = '#4480C5',
  itemVariant = 'textHover',
  ellipsis = false,
}) => {
  const dropdownRef = useRef();
  const theme = useContext(ThemeContext);
  const [display, toggleDisplay] = useState(false);

  const handleClose = useCallback(() => {
    toggleDisplay(false);
  }, []);

  const handlePressKey = useCallback((event) => {
    if (event.keyCode === 27) { // Esc keyCode
      handleClose();
    }
  }, [handleClose]);

  const handleClickOutsideCard = useCallback((event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('keydown', handlePressKey, false);
    document.addEventListener('mousedown', handleClickOutsideCard, false);

    return () => {
      document.removeEventListener('keydown', handlePressKey, false);
      document.removeEventListener('mousedown', handleClickOutsideCard, false);
    };
  }, [handlePressKey, handleClickOutsideCard]);

  const isEllipsisDropdown = ellipsis;

  return (
    <Styles.ButtonWrapper
      displayed={display}
      ref={dropdownRef}
      data-testid={`${testId}-wrapper`}
    >
      <Styles.Button
        displayed={display}
        onClick={() => toggleDisplay(!display)}
        data-testid={`${testId}-button`}
        variant="text"
      >
        {isEllipsisDropdown
          ? (
            <Styles.Ellipsis fill={theme.palette.secondary.primary}>
              <IconVerticalEllipsis />
            </Styles.Ellipsis>
          )
          : (
            <>
              {iconLeft}
              {label}
              <Styles.DropdowIconWrapper displayed={display}>
                <IconDropdownArrow color={dropdownColor} />
              </Styles.DropdowIconWrapper>
            </>
          )
        }
      </Styles.Button>

      <Styles.ItemsList
        displayed={display}
        data-testid={`${testId}-list`}
        isEllipsisDropdown={isEllipsisDropdown}
        variant={variantList}
      >
        {items.list.map(item => (
          <Styles.Button
            key={item.id}
            data-testid={item.id}
            onClick={() => {
              item.onClick();
              toggleDisplay(false);
            }}
            variant={item.variant || itemVariant}
            disabled={item.variant === 'disabled'}
          >
            {item.icon}
            {item.label}
          </Styles.Button>
        ))}
      </Styles.ItemsList>
    </Styles.ButtonWrapper>
  );
};

export default Dropdown;
