import React from 'react';
import { withStyles } from '@material-ui/core';

import Recommended from '@/media/newsite/tag-recommended.png';
import styles from './styles';


const NewSiteCard = ({
  classes, option, type, onHandleSelect, dataId,
}) => (
  <div
    className={`${classes.card} ${option.selected ? classes.selected : classes.notSelected}`}
    onClick={() => (type !== undefined ? onHandleSelect(type, option.id) : onHandleSelect(option.id))}
    role="presentation"
    data-id={dataId}
  >
    {option.tag && (
      <img
        src={Recommended}
        className={`${classes.tag} ${option.selected ? classes.tagSeleted : classes.tagNotSeleted}`}
        alt="Description"
      />
    )}
    {option.icon && (
      <div className={classes.image}>
        {option.icon}
      </div>
    )}
    <div className={classes.wrapperDescription}>
      <p className={classes.title}>{option.title}</p>
      <span className={`${classes.subtitle} ${option.tag && classes.subtitleTag}`}>{option.subtitle}</span>
    </div>
  </div>
);

export default withStyles(styles)(NewSiteCard);
