export const getClassNamesFollowingDomainStatus = (domainStatus) => {
  switch (domainStatus) {
    case 'Active': return 'active';
    case 'Pending': return 'pending';
    case 'Pending Transfer': return 'pending_transfer';
    case 'Expired': return 'expired';
    case 'Transferred Away': return 'transferred_away';
    case 'Cancelled': return 'cancelled';
    case 'Fraud': return 'fraud';
    case 'NextDueDate': return 'nextDueDate';
    case 'DueDate': return 'dueDate';
    default: return domainStatus;
  }
};
