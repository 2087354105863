/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, withWidth } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';
import { Menu as MenuIcon, Lock as LockIcon } from '@material-ui/icons';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MenuOpenIcon from '@/media/icons/MenuOpen';
import ExternalServiceErrorMessage from '@/components/Notification/ExternalServiceErrorMessage';
import VimeoPlayer from '@/components/Courses/VimeoPlayer';
import CourseProgress from '@/components/Courses/CourseProgress';
import CourseCertificate from '@/components/Courses/CourseCertificate';
import Loader from '@/components/Layout/Loader';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { getVimeoShowcases, traceCourseAccess } from '@/redux/actions/videos';
import useLocalStorage from '@/hooks/useLocalStorage';
import { formatSecondsToHours } from '@/utils/Formatters/formatTime';
import { indexedDBGetCourseByUserId, indexedDBPutCourseProgress } from '@/config/indexedDB/handlers';
import {
  styles,
  headerKeyframes,
  widthKeyFrames,
  heightKeyFrames,
} from './styles';


const CourseDetailPage = ({ t, classes, width }) => {
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const stateCourses = useSelector(state => state.videos.courses);
  const loading = useSelector(state => state.videos.loading);
  const requestError = useSelector(state => state.videos.requestError);
  const userId = useSelector(state => state.summary.id);
  const userName = useSelector(state => state.summary.name);

  const [userProgress, setUserProgress] = useState('empty');
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [showModules, setShowModules] = useState(true);
  const [asideModulesIsOpen, setAsideModulesIsOpen] = useState('');
  const [menuHover, setMenuHover] = useState(false);
  const [video, setVideo] = useState();
  const [asideModulesSizes, setAsideModulesSizes] = useState({ height: null, width: null });
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [asideAnimationDone, setAsideAnimationDone] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalWatched, setTotalWatched] = useState(0);
  const [certificateProgress, setCertificateProgress] = useState(0);
  const [redirectToShowCases, setRedirectToShowCases] = useState(false);

  const [courseCertificate, setCourseCertificate] = useLocalStorage(`courseCertificate-${userId}`, []);

  const [handleCourse] = stateCourses.filter(item => item.keyName === courseId);
  const isHoverBurguerButton = !showModules && menuHover;

  const isMobile = isWidthDown('sm', width);
  const showMenuModules = isWidthDown('md', width);

  const isProgressOnIndexedDb = true;
  const isRemoveLocalStorage = true;

  const setLastUnwatchedVideo = () => {
    const {
      keyName: courseKeyName,
      modules: stateModules,
    } = handleCourse;
    const allModulesLength = stateModules.length;
    const currentModulesProgress = userProgress[courseKeyName];

    if (!currentModulesProgress) {
      return stateModules[0].videos[0];
    }

    const modulesKeysStoraged = Object.keys(currentModulesProgress);
    const modulesProgressLength = modulesKeysStoraged.length;

    const notWatchedModule = modulesKeysStoraged.findIndex(courseModule => courseModule.watched) !== -1;
    if (notWatchedModule && allModulesLength !== modulesProgressLength) {
      const notWatchedModuleIndex = stateModules.findIndex(courseModule => modulesKeysStoraged.indexOf(courseModule.keyName) === -1);
      return stateModules[notWatchedModuleIndex].videos[0];
    }

    let modulesHasWatched;
    let keyOfIncompleteModule = '';
    const modules = Object.keys(currentModulesProgress);

    for (let index = 0; index < modules.length; index += 1) {
      const key = modules[index];
      if (!currentModulesProgress[key]) {
        return stateModules[key].videos[0];
      }

      if (!currentModulesProgress[key].watched) {
        modulesHasWatched = false;
        keyOfIncompleteModule = key;
        break;
      }
      modulesHasWatched = true;
    }

    if (modulesHasWatched) {
      return stateModules[0].videos[0];
    }

    const { watchedLinks } = currentModulesProgress[keyOfIncompleteModule];

    const unwatchedModuleIndex = stateModules.findIndex(moduleItem => moduleItem.keyName === keyOfIncompleteModule);
    const safeUnwatchedModuleIndex = unwatchedModuleIndex < 0 ? 0 : unwatchedModuleIndex;

    const unwatchedModule = stateModules[safeUnwatchedModuleIndex];
    const unwatchedVideoIndex = unwatchedModule.videos.findIndex(videoItem => watchedLinks.indexOf(videoItem.link) === -1);
    const safeUnwatchedVideoIndex = unwatchedVideoIndex < 0 ? 0 : unwatchedVideoIndex;

    return stateModules[safeUnwatchedModuleIndex].videos[safeUnwatchedVideoIndex];
  };

  const getUserProgress = async () => {
    if (isProgressOnIndexedDb) {
      const indexedDBProgress = await indexedDBGetCourseByUserId(userId);
      if ('userProgress' in indexedDBProgress) {
        await setUserProgress(indexedDBProgress.userProgress);
      } else {
        setUserProgress({});
      }
    } else {
      const userProgress = await localStorage.getItem(`progress-${userId}`);
      setUserProgress(JSON.parse(userProgress) || {});
    }

    setVideo(setLastUnwatchedVideo());

    setLoadingProgress(false);
  };


  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!loading && stateCourses.length === 0) {
        dispatch(getVimeoShowcases());
      }

      getUserProgress();
    }
    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    if (userProgress && userProgress.length !== 0) {
      if (video && ('courseKeyName' in video) && userProgress[video.courseKeyName]) {
        const { courseKeyName } = video;
        let totalWatched = 0;
        Object.keys(userProgress[courseKeyName]).forEach((watchedModuleKey) => {
          totalWatched += userProgress[courseKeyName][watchedModuleKey].watchedLinks.length;
        });
        setTotalWatched(totalWatched);
      }
    }
  }, [userProgress, video]);

  useEffect(() => {
    if (handleCourse) {
      let totalVideos = 0;
      Object.keys(handleCourse.modules).forEach((keyModule) => {
        totalVideos += handleCourse.modules[keyModule].videos.length;
      });
      setTotalVideos(totalVideos);
    }
  }, [handleCourse]);

  useEffect(() => {
    if (totalWatched > 0) {
      const progressByVideo = 100 / totalVideos;
      const currentProgress = Math.round(totalWatched * progressByVideo);
      if (currentProgress !== 'Infinity') {
        setCertificateProgress(currentProgress);

        if (currentProgress === 100) {
          const { courseKeyName } = video;
          const data = new Date();
          const dataToLocale = data.toLocaleDateString();

          setCourseCertificate({
            ...courseCertificate,
            [courseKeyName]: {
              conclusion: dataToLocale,
            },
          });
        }
      }
    }
  }, [totalWatched, totalVideos]);

  useEffect(() => {
    if (video) {
      const data = {
        videoName: video.name,
        courseName: handleCourse.name,
        videoId: video.uri,
        userId,
      };
      dispatch(traceCourseAccess(data));
    }
  }, [video]);

  useEffect(() => {
    if (handleCourse && 'modules' in handleCourse && handleCourse.modules.length > 0) {
      setVideo(setLastUnwatchedVideo());
    } else {
      setRedirectToShowCases(true);
    }
  }, [stateCourses]);

  const saveUserCourseProgress = async (data) => {
    setUserProgress(data);

    if (isProgressOnIndexedDb) {
      await indexedDBPutCourseProgress({
        userId,
        userProgress: data,
      });
    }

    !isRemoveLocalStorage && await localStorage.setItem(`progress-${userId}`, JSON.stringify(data));
  };

  const handleMouseEnter = () => {
    if (!showModules) {
      setMenuHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (!showModules) {
      setMenuHover(false);
    }
  };

  const selectVideo = (moduleId, videoId) => {
    const [selectModules] = handleCourse.modules.filter(item => item.id === moduleId);
    const [selectVideos] = selectModules.videos.filter(item => item.id === videoId);
    setVideo(selectVideos);
    setVideoLoaded(false);
  };

  const handleMenu = () => {
    if (showMenuModules && !isMobile) {
      if (showModules) {
        setMenuHover(false);
        const asideContainerElement = document.getElementById('aside-courses');
        setAsideModulesSizes({
          height: asideContainerElement.offsetHeight,
          width: asideContainerElement.offsetWidth,
        });
      }

      setAsideModulesIsOpen(showModules ? 'close' : 'open');
      setShowModules(!showModules);
    }
  };

  const handleAnimation = (event, element) => {
    if (element === 'aside' && event.animationName === 'animeAsideOpen') {
      setAsideAnimationDone(true);
    }

    element === 'asideContent' && setIsAnimationDone(true);
  };

  const styles = StyleSheet.create({
    asideClose: {
      display: 'flex',
      justifyContent: 'center',
      width: 57,
      height: 57,
      animationName: [
        widthKeyFrames(asideModulesSizes.width, 57),
        heightKeyFrames(asideModulesSizes.height, 57),
      ],
      animationDuration: '0.3s, 0.24s',
    },
    asideOpen: {
      display: 'unset',
      justifyContent: 'unset',
      width: asideModulesSizes.width,
      height: asideModulesSizes.height,
    },
    asideHeader: {
      animationName: [headerKeyframes],
      animationDuration: '0.2s',
    },
  });

  const saveCourseProgress = async () => {
    const { courseKeyName, moduleKeyName, link } = video;
    const { [courseKeyName]: courseProgress } = userProgress;

    const hasProgress = Object.keys(userProgress).length > 0;
    const hasCourseProgress = hasProgress && !!courseProgress && Object.keys(courseProgress).length > 0;
    const hasModuleCourseProgress = hasCourseProgress && !!courseProgress[moduleKeyName];
    const verifyHasVideoWatched = hasProgress && hasCourseProgress && hasModuleCourseProgress;

    const courseModule = handleCourse.modules.filter(item => item.keyName === moduleKeyName)[0];
    const checkWatchedModule = courseProgress && courseProgress[moduleKeyName];

    const checkErrorOnWatchedModules = (
      !!courseModule
      && checkWatchedModule
      && (courseModule.total === checkWatchedModule.watchedLinks.length)
      && !checkWatchedModule.watched
    );

    if (checkErrorOnWatchedModules) {
      const fixCoursesProgress = {
        [moduleKeyName]: {
          ...courseProgress[moduleKeyName],
          watched: true,
        },
      };

      await saveUserCourseProgress({
        ...(hasProgress && userProgress),
        [courseKeyName]: fixCoursesProgress,
      });

      return;
    }


    const watchedLinks = hasModuleCourseProgress ? [
      ...courseProgress[moduleKeyName].watchedLinks,
      link,
    ] : [
      link,
    ];

    const hasWatchedModule = !!courseModule && courseModule.total === watchedLinks.length;

    const videosLinks = {
      watched: hasWatchedModule,
      watchedLinks,
    };

    const coursesProgress = hasCourseProgress ? {
      ...courseProgress,
      [moduleKeyName]: videosLinks,
    } : {
      [moduleKeyName]: videosLinks,
    };

    // check if the video link is on localstorage
    if (verifyHasVideoWatched && courseProgress[moduleKeyName].watchedLinks.indexOf(link) !== -1) {
      return;
    }

    saveUserCourseProgress({
      ...(hasProgress && userProgress),
      [courseKeyName]: coursesProgress,
    });
  };

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const renderMenuModules = () => (
    <>
      {isHoverBurguerButton && (
        <div className={classnames({ [classes.menuHover]: menuHover })}>
          <span className={classnames({ [classes.menuTitleHover]: menuHover })}>
            {t('courses.menuTitle')}
          </span>
        </div>
      )}

      <button
        type="button"
        data-testid="handle-toggle-menu"
        onClick={() => handleMenu()}
        className={classes.button}
      >
        {showModules ? <MenuIcon /> : <MenuOpenIcon />}
      </button>
    </>
  );

  const getConclusionDate = () => {
    const { courseKeyName } = video;
    return courseCertificate[courseKeyName].conclusion;
  };

  const getCourseName = () => {
    const { name } = handleCourse;
    return name;
  };

  const getCourseHours = () => {
    const { duration } = handleCourse;
    return formatSecondsToHours(duration + 3600);
  };

  if (redirectToShowCases) {
    return <Redirect to={t('routes.courses')} />;
  }

  if ((userProgress === 'empty') || (!video && !requestError) || loadingProgress) {
    return <Loader data-testid="loader" />;
  }

  return (
    <div className={classes.wrapper} data-testid="course-detail-page-item">
      {requestError ? (
        <ExternalServiceErrorMessage>
          {t('courses.vimeoServiceDown')}
        </ExternalServiceErrorMessage>
      ) : (
        <>
          <span className={classes.title}>{video.name}</span>
          <div className={classes.container}>
            <main className={classes.main}>
              <div className={classes.video}>
                <div className={classes.videoContent}>
                  {!videoLoaded && (
                    <div className={classes.loaderWrapper}>
                      <Loader classesToProps={classes.loader} />
                    </div>
                  )}
                  {video && (
                    <VimeoPlayer
                      videoUrl={video.link}
                      onFinish={saveCourseProgress}
                      onLoaded={handleVideoLoaded}
                    />
                  )}
                </div>
              </div>
            </main>

            <aside
              id="aside-courses"
              data-testid="aside-menu-courses"
              onAnimationEnd={event => handleAnimation(event, 'aside')}
              className={classnames(classes.aside, {
                [css(styles.asideClose)]: asideModulesIsOpen === 'close',
                [css(styles.asideOpen)]: asideModulesIsOpen === 'open',
              })}
            >
              <div>
                <div
                  className={classnames(classes.header, css(styles.asideHeader))}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >

                  {renderMenuModules()}

                  {showModules && (
                    <div className={classes.headerContent}>
                      <span
                        className={classnames({
                          [classes.menuTitle]: asideAnimationDone,
                        })}
                      >
                        {t('courses.menuTitle')}
                      </span>

                      <span
                        className={classnames(classes.certificateProgress, {
                          [classes.greyCertificateProgress]: certificateProgress === 0,
                          [classes.greenCertificateProgress]: certificateProgress > 0,
                        })}
                      >
                        {`${certificateProgress}% ${t('courses.certificate.progress')}`}
                      </span>
                    </div>
                  )}
                </div>

                {(showModules && handleCourse) && (
                  <CourseProgress
                    modules={handleCourse.modules}
                    handleSelectVideo={selectVideo}
                    selectedVideo={video}
                    isAnimationEnded={isAnimationDone}
                    userProgress={userProgress}
                  />
                )}
              </div>

              {showModules && (
                <div className={classes.certificateWrapper} data-testid="show-modules">
                  {certificateProgress < 100 && (
                    <div className={classes.certificateInfo}>
                      <LockIcon className={classes.lockIcon} />
                      <span className={classes.certificateText}>{t('courses.certificate.available')}</span>
                    </div>
                  )}
                  {certificateProgress === 100 && (
                    <div className={classes.wrapperPDFLink} data-testid="pdf-download-link">
                      <PDFDownloadLink
                        document={(
                          <CourseCertificate
                            name={userName}
                            course={getCourseName()}
                            date={getConclusionDate()}
                            hours={getCourseHours()}
                          />
                        )}
                        fileName={`${t('courses.certificate.fileName')}.pdf`}
                      >
                        <PrimaryButton className={classes.certificateButton}>{t('courses.certificate.download')}</PrimaryButton>
                      </PDFDownloadLink>
                    </div>
                  )}
                </div>
              )}
            </aside>
          </div>
        </>
      )}
    </div>
  );
};

export default withWidth()(withStyles(styles)(withI18n()(CourseDetailPage)));
