import { CloudflareActionTypes } from './cloudflare.types';

export const initialState = {
  loading: false,
  loaded: false,
  list: [],
  lastActivation: {},
  loadingActivation: false,
  domainsError: false,
};

const cloudflareReducer = (state = initialState, action) => {
  switch (action.type) {
    case CloudflareActionTypes.DOMAINS_ERROR: {
      return {
        ...state,
        domainsError: action.payload.bool,
      };
    }
    case CloudflareActionTypes.DOMAINS_SET: {
      return {
        ...state,
        list: action.payload,
        loaded: true,
      };
    }
    case CloudflareActionTypes.DOMAINS_LOADING: {
      return {
        ...state,
        loading: action.payload,
        loaded: !action.payload,
        lastActivation: action.payload ? {} : state.lastActivation,
        list: action.payload ? [] : state.list,
        domainsError: action.payload ? false : state.domainsError,
      };
    }
    case CloudflareActionTypes.LOADING_ACTIVATION: {
      return {
        ...state,
        loadingActivation: action.payload,
      };
    }
    case CloudflareActionTypes.SET_ACTIVATED_DOMAIN: {
      const newList = [...state.list];
      const domainIndex = newList.findIndex(listItem => listItem.domain === action.payload.domain.domain);

      if (domainIndex> -1 && action.payload.domain.status === 'success') {
        newList[domainIndex] = {
          ...newList[domainIndex],
          status: (newList[domainIndex].status === 'nonexistent' || newList[domainIndex].status === 'inactive')
            ? 'pending'
            : 'pendingDeactivation',
        };
      }

      return {
        ...state,
        lastActivation: action.payload.domain,
        list: newList,
      };
    }
    default:
      return state;
  }
};

export default cloudflareReducer;
