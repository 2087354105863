import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { productType } from '@/types';
import ProductItem from '@/components/Products/ProductItem';
import { loadSubdomains } from '@/redux/actions/subdomains';


class ProductPageItem extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    product: productType,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    product: null,
    loading: false,
  }

  state = {
    expandDetails: false,
    subdomainsList: {},
    loadingSubdomains: false,
  }

  expandDetails = () => {
    const { product, loadSubdomains } = this.props;
    const { expandDetails } = this.state;


    if (!expandDetails) {
      this.setState({ loadingSubdomains: true });
      loadSubdomains(product.id).then((result) => {
        this.setState({
          subdomainsList: result.subdomains,
          loadingSubdomains: false,
        });
      });
    }

    this.setState(prevState => ({
      ...prevState,
      expandDetails: !prevState.expandDetails,
    }));
  }

  render() {
    const {
      product, products,
    } = this.props;
    const { expandDetails, subdomainsList, loadingSubdomains } = this.state;

    const productProps = {
      onExpandDetails: () => this.expandDetails(),
      detailsExpanded: expandDetails,
      product,
      subdomainsList,
      onLoadingSubdomains: loadingSubdomains,
    };

    return <ProductItem {...productProps} products={products} />;
  }
}


const mapDispatchToProps = dispatch => bindActionCreators({
  loadSubdomains,
}, dispatch);

export default withI18n()(connect(null, mapDispatchToProps)(ProductPageItem));
