import PropTypes from 'prop-types';

export const SubscriptionResumeProps = {
  loading: PropTypes.bool,
  onPlanButtonClick: PropTypes.func,
  paymentCycleLabel: PropTypes.string,
  paymentCycleText: PropTypes.string,
  planButtonDisabled: PropTypes.bool,
  planButtonLabel: PropTypes.string,
  productNameLabel: PropTypes.string,
  productNameText: PropTypes.string,
  registerDateLabel: PropTypes.string,
  registerDateText: PropTypes.string,
  resumeTitleLabel: PropTypes.string,
  showPaymentCycle: PropTypes.bool,
  showPlanButton: PropTypes.bool,
  statusBackgroundColor: PropTypes.string,
  statusLabel: PropTypes.string,
  statusText: PropTypes.string,
  statusTextColor: PropTypes.string,
};
