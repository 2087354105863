import React, { useCallback } from 'react';
import * as Styles from './GroupedCards.styles';
import { IconLoading, Tag } from 'gatorcomponents';
import { CardGroup } from './components/CardGroup';
import { STATUS_TYPE } from '@/enums/sites/status.enum';
import withErrorBoundary from '@/utils/errorBoundary';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS, CHARLIE_CLOUDFLARE } from '@/config/GrowthBook/constants';
import { HgProducts } from '@/components/SupportForms/SupportForms.types';

const GroupedCards = ({
  groupedCards,
  propagationStatus,
  propagationAlertLabels,
  loadingCpanelSSOProductId,
  handleClickCpanel,
  cloudflareLabel,
  tagNewLabel,
  handleGoToCloudFlare,
  manageLabel,
  handleManage,
  getDropdownActions,
  getMainActions,
  loadMoreLabel,
  handlePayInvoice,
  closerPayment,
  loading,
  siteTagLabels,
  productsList,
  hostCount,
  emptyMessage,
  suspendedCpanelTooltip,
  handleEditSite,
  loadingRedirect,
  editSiteCta,
}) => {
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);
  const cloudflareEnabled = useFeatureIsOn(CHARLIE_CLOUDFLARE);

  const getCardGroupProps = useCallback((group) => {
    const defaultProps = {
      group,
      propagationStatus,
      propagationAlertLabels,
      getDropdownActions,
      getMainActions,
      loadMoreLabel,
      handlePayInvoice,
      closerPayment,
      loading,
      siteTagLabels,
      productsList,
      hostCount,
      handleEditSite,
      loadingRedirect,
      editSiteCta,
    };

    const handleAccessCpanel = (group) => {
      handleClickCpanel && handleClickCpanel(group);
    };

    const handleClickGoToCloudflare = (group) => {
      handleGoToCloudFlare && handleGoToCloudFlare(group);
    };

    const actions = (group, planStatus) => {
      const actions = [];
      const canDisplayCloudflareButton = cloudflareEnabled
      && group[0].cloudflare_available
      && planStatus === STATUS_TYPE.ACTIVE;

      const currentCpanelIsLoading = withGatorSolutions
        ? loadingCpanelSSOProductId === group[0].hosting_id
        : loadingCpanelSSOProductId === group[0].id;

      if (canDisplayCloudflareButton) {
        actions.push({
          label: (
            <Styles.NewActionButtonWrapper>
              {cloudflareLabel}
              <Tag label={tagNewLabel} size="sm" />
            </Styles.NewActionButtonWrapper>
          ),
          onClick: () => handleClickGoToCloudflare(group),
          testId: `cloudflare-${group[0].external_id}-cta`,
        });
      }

      if (withGatorSolutions
        ? HgProducts.SHARED_ALL.includes(group[0].package_id)
        : group[0].product_type === 'shared'
      ) {
        actions.push({
          label: currentCpanelIsLoading
            ? (
              <>
                <IconLoading />
                {' cPanel'}
              </>
            )
            : 'cPanel',
          onClick: () => handleAccessCpanel(group),
          testId: `cPanel-${group[0].external_id}-cta`,
          disabled: [STATUS_TYPE.ANALYSIS, STATUS_TYPE.PENDING, STATUS_TYPE.SUSPENDED].includes(planStatus),
          tooltip: [STATUS_TYPE.SUSPENDED].includes(planStatus) && suspendedCpanelTooltip,
        });
      }

      actions.push({
        label: manageLabel,
        onClick: () => handleManage(group),
        testId: `manage-${group[0].external_id}-cta`,
      });

      return actions;
    };

    return {
      ...defaultProps,
      actions,
    };
  }, [
    suspendedCpanelTooltip,
    withGatorSolutions,
    loadingCpanelSSOProductId,
    manageLabel,
    handleManage,
    handleClickCpanel,
    getDropdownActions,
    getMainActions,
    loadMoreLabel,
    loading,
    productsList,
    hostCount,
    closerPayment,
    handlePayInvoice,
    propagationAlertLabels,
    propagationStatus,
    siteTagLabels,
    handleEditSite,
    loadingRedirect,
    editSiteCta,
    cloudflareEnabled,
    cloudflareLabel,
    handleGoToCloudFlare,
    tagNewLabel,
  ]);

  return (
    <Styles.GroupedCardsWrapper data-testid="groupedCardsWrapper">
      {(groupedCards && groupedCards.length > 0) && groupedCards.map(group => (
        group && group.length && (
          <CardGroup
            {...getCardGroupProps(group)}
            key={group[0].external_id || group[0].hosting_id}
          />
        )
      ))}

      {(groupedCards && groupedCards.length === 0) && (
        <Styles.EmptyState>
          {emptyMessage}
        </Styles.EmptyState>
      )}
    </Styles.GroupedCardsWrapper>
  );
};

export default withErrorBoundary(GroupedCards, 'GroupedCards');
