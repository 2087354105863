import { videoClassesActionTypes } from './videoClasses.types';

const setEmbedViewStatus = bool => ({
  type: videoClassesActionTypes.SET_EMBED_VIEW_STATUS,
  payload: bool,
});

const setModalViewStatus = bool => ({
  type: videoClassesActionTypes.SET_MODAL_VIEW_STATUS,
  payload: bool,
});

const requestLiveData = () => ({
  type: videoClassesActionTypes.REQUEST_LIVE_DATA,
});

const successLiveData = () => ({
  type: videoClassesActionTypes.SUCCESS_LIVE_DATA,
});

const failureLiveData = () => ({
  type: videoClassesActionTypes.FAILURE_LIVE_DATA,
});

const setLiveData = data => ({
  type: videoClassesActionTypes.SET_LIVE_DATA,
  payload: data,
});

const actions = {
  embed: {
    setEmbedViewStatus,
  },
  modal: {
    setModalViewStatus,
  },
  live: {
    request: requestLiveData,
    success: successLiveData,
    failure: failureLiveData,
    set: setLiveData,
  },
};

export default actions;
