import React, { useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { BottomAdvertisement } from '@/pages/advertisement/BottomAdvertisement';
import Trakto from '@/media/partners/traktoHG.svg';
import Cobrefacil from '@/media/partners/cobrefacilHG.svg';
import Creditas from '@/media/partners/creditasHG.svg';


const traktoPromoImg = require('@/media/mktPlace/bottomBanner/traktoPromoImg.png');
const cobreFacilPromoImg = require('@/media/mktPlace/bottomBanner/cobreFacilPromoImg.png');

const BottomAdvertisementHandler = ({ advertisementLocal, t, width }) => {
  const [partner, setPartner] = useState(null);
  const bottomBannerPartners = 'off';

  const partnerImages = {
    trakto: Trakto,
    cobrefacil: Cobrefacil,
    creditas: Creditas,
  };

  const partnerBottomImages = {
    trakto: traktoPromoImg,
    cobrefacil: cobreFacilPromoImg,
  };

  const partnerStyleConfig = (partner) => {
    switch (partner) {
      case 'trakto':
        return {
          image: partnerBottomImages[partner],
          imgPaddingTop: 0,
          imgPaddingRight: 0,
          imgPaddingBottom: 0,
          imgPaddingLeft: 0,
          imgMaxWidth: 244,
          imgMarginTop: -78,
          imgMarginRight: -7,
          imgMarginBottom: 'auto',
          imgMarginLeft: 'auto',
          bgColor: '#0096FB',
        };
      case 'cobrefacil':
        return {
          image: partnerBottomImages[partner],
          imgPaddingTop: 0,
          imgPaddingRight: 24,
          imgPaddingBottom: 0,
          imgPaddingLeft: 0,
          imgMaxWidth: 175,
          imgMarginTop: 0,
          imgMarginRight: 0,
          imgMarginBottom: 'auto',
          imgMarginLeft: 'auto',
          bgColor: '#0059F1',
        };
      default:
        return null;
    }
  };

  const isMobile = isWidthDown('sm', width);

  useEffect(() => {
    switch (bottomBannerPartners) {
      case 'trakto':
        setPartner('trakto');
        break;
      case 'cobre_facil':
        setPartner('cobrefacil');
        break;
      case 'creditas':
        setPartner('creditas');
        break;
      default:
        setPartner('off');
    }
  }, [bottomBannerPartners]);

  return (
    <BottomAdvertisement
      partnerConfig={partnerStyleConfig(partner)}
      logo={partnerImages[partner]}
      logoWidth={314}
      logoHeigth={80}
      buttonLabel={t(`advertisement.bottom.${partner}.btnLabel`)}
      description={t(`advertisement.bottom.${partner}.label`)}
      href={t(`advertisement.bottom.${partner}.href`)}
      visible={!isMobile}
      advertisementLocal={advertisementLocal}
      partner={partner}
      testId="bottom-advertisement-component"
    />
  );
};
export default withI18n()(withWidth()(BottomAdvertisementHandler));
