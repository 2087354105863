const styles = theme => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '27px 24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.color.tundora,
    textAlign: 'left',
    userSelect: 'none',
  },
  subtitle: {
    display: 'inline-block',
    marginLeft: 5,
    fontSize: 12,
    color: theme.color.regentGray,
  },
});

export default styles;
