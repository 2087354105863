import { CALL_API } from '@/middleware/api';
import {
  SITELOCK_DOMAINS,
  SITELOCK_PRICE,
  SITELOCK_SCAN,
  SITELOCK_QUEUE,
  SITELOCK_COMPLETE_SCAN,
  SITELOCK_CHECK_SCAN_STATUS,
  SITELOCK_SHIELD,
  SITELOCK_SMARTSCAN,
  SITELOCK_COMPLETE_SMARTSCAN,
  SITELOCK_SMARTSCAN_CONFIG,
  SITELOCK_SMARTSCAN_FILE,
  SITELOCK_HML_PAYMENT_METHOD,
  SITELOCK_PROFESSIONAL_HML_CHECKOUT_PRODUCT,
  SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  CODEGUARD_PRICE,
} from '@/config/api';
import { COUNTRY, config } from '@/config';
import { makeParamsUrl } from '@/utils/Formatters/handleStrings';
import { checkoutProductAdapter } from '@/utils/Formatters/checkoutProductAdapter';
import {
  REQUEST_SITELOCK_DOMAINS,
  RECEIVE_SITELOCK_DOMAINS,
  ERROR_SITELOCK_DOMAINS,
  ABORT_SITELOCK_REQUEST_DOMAINS,
  SET_SITELOCK_PRODUCT,
  RECEIVE_SITELOCK_STATUS_SCAN,
  REQUEST_SITELOCK_STATUS_SCAN,
  ERROR_SITELOCK_STATUS_SCAN,
  RECEIVE_SITELOCK_SCAN,
  REQUEST_SITELOCK_SCAN,
  REQUEST_SITELOCK_PRICE,
  RECEIVE_SITELOCK_PRICE,
  ERROR_SITELOCK_PRICE,
  ABORT_SITELOCK_REQUEST_PRICE,
  REQUEST_SITELOCK_COMPLETE_SCAN,
  RECEIVE_SITELOCK_COMPLETE_SCAN,
  REQUEST_SITELOCK_COMPLETE_SMARTSCAN,
  RECEIVE_SITELOCK_COMPLETE_SMARTSCAN,
  ERROR_SITELOCK_COMPLETE_SMARTSCAN,
  ERROR_SITELOCK_COMPLETE_SCAN,
  REQUEST_SITELOCK_SCAN_STATUS,
  RECEIVE_SITELOCK_SCAN_STATUS,
  RECEIVE_SITELOCK_SCAN_STATUS_PENDING,
  REQUEST_SITELOCK_SHIELD,
  RECEIVE_SITELOCK_SHIELD,
  ERROR_SITELOCK_SHIELD,
  REQUEST_SITELOCK_SHIELD_UPDATE,
  RECEIVE_SITELOCK_SHIELD_UPDATE,
  ERROR_SITELOCK_SHIELD_UPDATE,
  REQUEST_SITELOCK_SMARTSCAN_UPDATE,
  RECEIVE_SITELOCK_SMARTSCAN_UPDATE,
  ERROR_SITELOCK_SMARTSCAN_UPDATE,
  REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG,
  RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG,
  ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG,
  SITELOCK_CLEAR,
  REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  ABORT_SITELOCK_PAYMENT_METHOD,
  REQUEST_SITELOCK_PAYMENT_METHOD,
  RECEIVE_SITELOCK_PAYMENT_METHOD,
  ERROR_SITELOCK_PAYMENT_METHOD,
  ABORT_SITELOCK_PROFESSIONAL_PRICE,
  REQUEST_SITELOCK_PROFESSIONAL_PRICE,
  RECEIVE_SITELOCK_PROFESSIONAL_PRICE,
  ERROR_SITELOCK_PROFESSIONAL_PRICE,
  ABORT_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  REQUEST_CODEGUARD_PRICE,
  ERROR_CODEGUARD_PRICE,
  RECEIVE_CODEGUARD_PRICE,
} from './actionsTypes';

export const receiveDomains = (sitelock) => {
  const {
    loaded_date: loadedDate, standalone, addon,
  } = sitelock;
  const total = standalone.length + addon.length;

  return {
    type: RECEIVE_SITELOCK_DOMAINS,
    loadedDate,
    standalone,
    addon,
    total,
  };
};

export const receiveShield = (sitelock) => {
  const {
    color, size, type, language,
  } = sitelock.data;
  const shield = {
    color,
    size,
    type,
    language,
  };

  return {
    type: RECEIVE_SITELOCK_SHIELD,
    shield,
  };
};


export const loadDomains = () => (dispatch, getState) => {
  const { loadedDate } = getState().sitelock;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_SITELOCK_REQUEST_DOMAINS }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: SITELOCK_DOMAINS,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_DOMAINS }),
        success: response => receiveDomains(response.data),
        error: () => ({ type: ERROR_SITELOCK_DOMAINS }),
      },
    },
  });
};

export const receiveAutoconfig = resp => (resp.success ? { type: RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG, data: resp.data } : { type: ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG });

export const receiveScanStatus = (sitelock) => {
  const status = sitelock.data;

  if (status.scanning) {
    return {
      type: RECEIVE_SITELOCK_SCAN_STATUS_PENDING,
      status,
    };
  }

  const {
    name, attention_flag: attentionFlag, last_scan: lastScan, next_scan: nextScan, issues,
  } = sitelock.api;

  return {
    type: RECEIVE_SITELOCK_SCAN_STATUS,
    status,
    scanning: false,
    name,
    attentionFlag: (attentionFlag === undefined) ? 'red' : attentionFlag,
    lastScan: new Date(`${lastScan}T00:00:00`),
    nextScan: new Date(`${nextScan}T00:00:00`),
    issues,
  };
};

export const buySiteLock = ({
  productId, paymentMethod, productCycle, productDomain,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().sitelock.buyFastcheckout;

  if (isLoading) {
    return Promise.resolve(dispatch({ type: ABORT_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT }));
  }

  const bodyObject = {
    products: [
      {
        id: productId,
        type: 'product',
        domain: productDomain,
        quantity: 1,
        cycle: productCycle,
      },
    ],
    payment_method: paymentMethod,
    offer_contexts: [
      'sitelock_landing_page_cta_top',
    ],
  };

  return dispatch({
    [CALL_API]: {
      endpoint: SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
      method: 'POST',
      body: bodyObject,
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT }),
        success: response => ({ type: RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT, data: { ...response, paymentMethod, invoice_id: response.data.invoice_id } }),
        error: () => ({ type: ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT }),
      },
    },
  });
};

export const receiveScan = (sitelock) => {
  const {
    name, attention_flag: attentionFlag, last_scan: lastScan, next_scan: nextScan, issues,
  } = sitelock.api;
  const {
    scan_limit: scanLimitReached, scanning, domain, product, smartscan,
  } = sitelock.data;
  return {
    type: RECEIVE_SITELOCK_STATUS_SCAN,
    name,
    attentionFlag,
    scanning,
    issues,
    domain,
    product,
    scanLimitReached,
    smartscan,
    lastScan: new Date(`${lastScan}T00:00:00`),
    nextScan: new Date(`${nextScan}T00:00:00`),
  };
};

export const receiveCompleteScan = sitelock => (
  {
    type: RECEIVE_SITELOCK_COMPLETE_SCAN,
    completeScan: sitelock.data,
  }
);

export const receiveCompleteSMARTScan = (sitelock) => {
  const SMARTScanHistory = [...sitelock.data];
  return (
    {
      type: RECEIVE_SITELOCK_COMPLETE_SMARTSCAN,
      history: SMARTScanHistory,
    }
  );
};

export const setDomain = (productType = 'addon', productId) => (dispatch) => {
  dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: makeParamsUrl(SITELOCK_SCAN, productType, productId),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_STATUS_SCAN }),
        success: response => receiveScan(response),
        error: () => ({ type: ERROR_SITELOCK_STATUS_SCAN }),
      },
    },
  });
  return dispatch({
    type: SET_SITELOCK_PRODUCT,
    productId: parseInt(productId, 10),
    productType,
  });
};

export const requestScan = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_QUEUE, productType, productId),
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SCAN }),
      success: response => dispatch({ type: RECEIVE_SITELOCK_SCAN, scanned: response.scanned }),
      error: () => ({ type: ERROR_SITELOCK_DOMAINS }),
    },
  },
});

export const checkScanStatus = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_CHECK_SCAN_STATUS, productType, productId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SCAN_STATUS }),
      success: response => receiveScanStatus(response),
      error: () => ({ type: ERROR_SITELOCK_DOMAINS }),
    },
  },
});

export const requestCompleteScan = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_COMPLETE_SCAN, productType, productId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_COMPLETE_SCAN }),
      success: response => receiveCompleteScan(response),
      error: () => ({ type: ERROR_SITELOCK_COMPLETE_SCAN }),
    },
  },
});

export const requestCompleteSMARTScan = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_COMPLETE_SMARTSCAN, productType, productId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_COMPLETE_SMARTSCAN }),
      success: response => receiveCompleteSMARTScan(response),
      error: () => ({ type: ERROR_SITELOCK_COMPLETE_SMARTSCAN }),
    },
  },
});

export const receiveSitelockPrice = (sitelock) => {
  const { basic_price: basicPrice } = sitelock;

  return {
    type: RECEIVE_SITELOCK_PRICE,
    basicPrice,
  };
};

export const receiveCodeGuardPrice = (codeGuard) => {
  const { basic_price: basicPrice } = codeGuard;

  return {
    type: RECEIVE_CODEGUARD_PRICE,
    basicPrice,
  };
};

export const getPrices = () => (dispatch, getState) => {
  const { priceLoadedDate } = getState().sitelock.cta;
  if ((Date.now() - priceLoadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_SITELOCK_PROFESSIONAL_PRICE }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: SITELOCK_PROFESSIONAL_HML_CHECKOUT_PRODUCT,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
        Accept: 'application/json, text/plain, */*',
      },
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_PROFESSIONAL_PRICE }),
        success: response => ({ type: RECEIVE_SITELOCK_PROFESSIONAL_PRICE, data: checkoutProductAdapter(response) }),
        error: () => ({ type: ERROR_SITELOCK_PROFESSIONAL_PRICE }),
      },
    },
  });
};

export const getPaymentMethods = () => (dispatch, getState) => {
  const { paymentLoadedDate } = getState().sitelock.cta;
  if ((Date.now() - paymentLoadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_SITELOCK_PAYMENT_METHOD }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: SITELOCK_HML_PAYMENT_METHOD,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_PAYMENT_METHOD }),
        success: response => ({ type: RECEIVE_SITELOCK_PAYMENT_METHOD, data: response }),
        error: () => ({ type: ERROR_SITELOCK_PAYMENT_METHOD }),
      },
    },
  });
};

export const loadBasicPrice = () => (dispatch, getState) => {
  const { loadedDate } = getState().sitelock;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_SITELOCK_REQUEST_PRICE }));
    });
  }

  dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: CODEGUARD_PRICE,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_CODEGUARD_PRICE }),
        success: response => receiveCodeGuardPrice(response.data),
        error: () => ({ type: ERROR_CODEGUARD_PRICE }),
      },
    },
  });

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: SITELOCK_PRICE,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_SITELOCK_PRICE }),
        success: response => receiveSitelockPrice(response.data),
        error: () => ({ type: ERROR_SITELOCK_PRICE }),
      },
    },
  });
};

export const requestShield = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_SHIELD, productType, productId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SHIELD }),
      success: response => receiveShield(response),
      error: () => ({ type: ERROR_SITELOCK_SHIELD }),
    },
  },
});

export const updateShield = (productType, productId, data) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_SHIELD, productType, productId),
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SHIELD_UPDATE }),
      success: () => dispatch({ type: RECEIVE_SITELOCK_SHIELD_UPDATE }),
      error: () => ({ type: ERROR_SITELOCK_SHIELD_UPDATE }),
    },
  },
});

export const updateSMART = (productType, productId, data) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_SMARTSCAN, productType, productId),
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SMARTSCAN_UPDATE }),
      success: () => response => dispatch({ type: RECEIVE_SITELOCK_SMARTSCAN_UPDATE, data: response.data }),
      error: () => ({ type: ERROR_SITELOCK_SMARTSCAN_UPDATE }),
    },
  },
});

export const configureSMART = (productType, productId) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: makeParamsUrl(SITELOCK_SMARTSCAN_CONFIG, productType, productId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG }),
      success: response => receiveAutoconfig(response),
      error: () => ({ type: ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG }),
    },
  },
});

export const downloadSMARTFile = download => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${SITELOCK_SMARTSCAN_FILE}${download}`,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD, file: download }),
      success: response => ({ type: RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD, file: download, fileData: response }),
      error: () => ({ type: ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD, file: download }),
    },
  },
});

export const clearSitelock = () => ({ type: SITELOCK_CLEAR });
