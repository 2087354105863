const styles = theme => ({
  subTitle: {
    fontSize: '14px',
    fontWeight: 500,
  },
  modalSubTitle: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 30px 24px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 30px',
    },
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 110,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    minHeight: 'auto',
    maxHeight: 600,
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '100vh',
      padding: `${theme.spacing.unit * 4}px 0px`,
    },
  },
  gridLink: {
    '& > a': {
      fontSize: '14px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: 20,
    },
  },
  cardContent: {
    padding: '12px 24px',
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingBottom: '24px',
      },
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  warningNoticeContainer: {
    margin: '12px 0',
  },
  wrapperWarningNotice: {
    color: theme.color.tundora,
  },
  wrapperWarningNoticeSuspended: {
    margin: '10px 0',
  },
  emailConfimationButton: {
    minHeight: 38,
    minWidth: 170,
  },
  listContainer: {
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      maxHeight: 320,
    },
  },
  linkMessage: {
    color: theme.color.indigo,
    textDecoration: 'none',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default styles;
