import React, { useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import DOMpurify from 'dompurify';

import { Button } from '@material-ui/core';
import { Modal } from '@/pages/common/Modal';
import PrimaryButton from '@/components/Buttons/PrimaryButton';

import { useResize } from '@/hooks/useResize';

import * as Styles from './ModalToAlertUpgradeInTrial.styles';

const ModalToAlertUpgradeInTrial = ({
  t,
  isOpen,
  onClose,
  handleConfirm,
  getTotalDaysToEndTrial,
  isLoading,
}) => {
  const screenSize = useResize();

  const descriptionSanitized = useMemo(() => DOMpurify.sanitize(
    t('modalToAlertUpgradeWhenInTrialPlan.description', {
      remainingPeriod: getTotalDaysToEndTrial() || 0,
    }), { ALLOWED_TAGS: ['strong'] },
  ), [getTotalDaysToEndTrial, t]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    handleConfirm && handleConfirm();
    onClose && onClose();
  }, [handleConfirm, onClose]);

  return (isOpen ? (
    <Modal maxWidth={560} maxHeight={screenSize.height} onClose={onClose}>
      <Styles.Wrapper>
        <div className="widthWrapper">
          <Styles.Title>{t('modalToAlertUpgradeWhenInTrialPlan.title')}</Styles.Title>
          <Styles.Description data-testid="descriptionWithRemainingPeriod" dangerouslySetInnerHTML={{ __html: descriptionSanitized }} />

          <Styles.ButtonWrapperForm onSubmit={handleSubmit}>
            <Button
              variant="outlined"
              type="button"
              onClick={onClose}
              disabled={isLoading}
              data-testid="cancelButton"
            >
              {t('modalToAlertUpgradeWhenInTrialPlan.buttons.cancel')}
            </Button>
            <PrimaryButton type="submit" onLoading={isLoading} data-testid="submitButton">
              {t('modalToAlertUpgradeWhenInTrialPlan.buttons.confirm')}
            </PrimaryButton>
          </Styles.ButtonWrapperForm>
        </div>
      </Styles.Wrapper>
    </Modal>
  ) : null);
};


ModalToAlertUpgradeInTrial.propTypes = {
  t: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  onClose: propTypes.func.isRequired,
  handleConfirm: propTypes.func.isRequired,
  getTotalDaysToEndTrial: propTypes.func.isRequired,
  isLoading: propTypes.bool,
};

ModalToAlertUpgradeInTrial.defaultProps = {
  isOpen: false,
  isLoading: false,
};


export default withI18n()(ModalToAlertUpgradeInTrial);
