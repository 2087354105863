import { FaqActionTypes } from './faq.types';

export const initialState = {
  loading: false,
  wordpress: {
    loaded: false,
    questions: [],
  },
  sitebuilder: {
    loaded: false,
    questions: [],
  },
  softaculous: {
    loaded: false,
    questions: [],
  },
  mainSupportPage: {
    loaded: false,
    questions: [],
  },
  financialSupportPage: {
    loaded: false,
    questions: [],
  },
  dnsSupportPage: {
    loaded: false,
    questions: [],
  },
  mailSupportPage: {
    loaded: false,
    questions: [],
  },
  siteSupportPage: {
    loaded: false,
    questions: [],
  },
  cloudflare: {
    loaded: false,
    questions: [],
  },
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FaqActionTypes.LOADING_FAQ: {
      const { boolean } = action.payload;
      return {
        ...state,
        loading: boolean,
      };
    }
    case FaqActionTypes.SET_FAQ:
      return {
        ...state,
        [action.payload.faq.key]: {
          loaded: true,
          questions: action.payload.faq.questions,
        },
      };
    default:
      return state;
  }
};

export default faqReducer;
