import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';

import {
  Chip,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';


const Filter = ({ classes, t }) => {
  const {
    dnsFilterOptions,
    handleChangeTypedFilterData,
    handleSetSelectedFilter,
    loading,
    typedFilterData,
  } = useContext(DNSContext);

  return (
    <>
      <TextField
        className={classes.textField}
        disabled={loading}
        label={t('dns.zoneManager.recordList.searchField')}
        name="search"
        onChange={e => handleChangeTypedFilterData(e.target.value)}
        placeholder={t('dns.zoneManager.recordList.searchField')}
        value={typedFilterData}
        variant="outlined"
      />

      <div className={classes.filterWrapper}>
        <Typography className={classes.filterLabel} id="Filter_Label">
          {t('dns.zoneManager.recordList.filterLabel')}
        </Typography>

        <ul className={classes.filterList}>
          {dnsFilterOptions.map((recordType, idx) => (
            <li key={recordType.name} data-id={`Filter-chip_${recordType.name}`}>
              <Chip
                className={`${classes.chipElement} ${recordType.active ? classes.chipElementActive : classes.chipElementInactive}`}
                color="primary"
                label={recordType.name}
                onClick={() => handleSetSelectedFilter(idx, recordType)}
                id={`Filter-chip_${recordType.name}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default (withI18n()((withStyles(styles)(Filter))));
