import { AsaasActionTypes } from './asaas.types';

export const initialState = {
  loading: false,
  accountCreated: false,
};

const asaasReducer = (state = initialState, action) => {
  switch (action.type) {
    case AsaasActionTypes.SET_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loading: isLoading,
      };
    }
    case AsaasActionTypes.ASAAS_SET_ACCOUNT_STATUS: {
      return {
        ...state,
        accountCreated: action.payload,
      };
    }
    default:
      return state;
  }
};

export default asaasReducer;
