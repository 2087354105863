import React, { useCallback } from 'react';
import * as Styles from './DomainsAlerts.styles';
import { Alert, Skeleton } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { useHistory } from 'react-router';
import { useBillingStatus } from '@/hooks/domains/useBillingStatus';

const DomainsAlerts = ({
  diagnostic,
  domains,
}) => {
  const history = useHistory();
  const { routes: routesLocale, sites: sitesLocale } = useLocale();
  const { page: pageLocale } = sitesLocale;
  const { groupedPage: groupedPageLocale } = pageLocale;
  const { getTagStatus } = useBillingStatus();

  const domainsUrl = `${routesLocale.domains}`;
  const invoicesUrl = `${routesLocale.invoices}${routesLocale.unpaid}`;

  const mountAlerts = useCallback(() => {
    if (diagnostic.loading || domains.loading) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Skeleton variant="rectangle" height="70px" />
        </Styles.DomainsAlertsWrapper>
      );
    }

    const hasExpired = domains.list.length && domains.list.find(domain => domain.status === 'expired' && domain.invoiceStatus === 'Unpaid');
    if (hasExpired) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Alert
            variant="error"
            description={groupedPageLocale.alerts.suspended({
              history,
              invoicesUrl,
            })}
            width="full"
            centerIcon
            testId="suspendedAlert"
          />
        </Styles.DomainsAlertsWrapper>
      );
    }

    const hasOverdue = domains.list.length && domains.list.find((domain) => {
      const status = getTagStatus(domain);
      return status === 'overDueDate' && domain.invoiceStatus === 'Unpaid';
    });
    if (hasOverdue) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Alert
            variant="error"
            description={groupedPageLocale.alerts.overdue({
              history,
              invoicesUrl,
            })}
            width="full"
            centerIcon
            testId="overdueAlert"
          />
        </Styles.DomainsAlertsWrapper>
      );
    }

    const hasDnsProblem = domains.list.length && domains.list.find(domain => domain.status === 'registerError');
    if (hasDnsProblem) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Alert
            variant="error"
            description={groupedPageLocale.alerts.dnsProblem({
              history,
              domainsUrl,
            })}
            width="full"
            centerIcon
            testId="dnsProblemAlert"
          />
        </Styles.DomainsAlertsWrapper>
      );
    }

    const hasToDue = domains.list.length && domains.list.find((domain) => {
      const status = getTagStatus(domain);
      return status === 'nextDueDate' && domain.invoiceStatus === 'Unpaid';
    });
    if (hasToDue) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Alert
            variant="warning"
            description={groupedPageLocale.alerts.to_due({
              history,
              invoicesUrl,
            })}
            width="full"
            centerIcon
            testId="toDueAlert"
          />
        </Styles.DomainsAlertsWrapper>
      );
    }

    const hasPending = domains.list.length && domains.list.find(domain => domain.status === 'pending');
    if (hasPending) {
      return (
        <Styles.DomainsAlertsWrapper>
          <Alert
            variant="timed"
            description={groupedPageLocale.alerts.pending({
              history,
              domainsUrl,
            })}
            width="full"
            centerIcon
            testId="pendingAlert"
          />
        </Styles.DomainsAlertsWrapper>
      );
    }

    return null;
  }, [
    groupedPageLocale,
    history,
    domains,
    diagnostic,
    domainsUrl,
    invoicesUrl,
    getTagStatus,
  ]);

  return (
    mountAlerts()
  );
};

export default DomainsAlerts;
