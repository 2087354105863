import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid, Typography, CardContent, Tooltip,
} from '@material-ui/core';
import { Tooltip as NewTooltip } from 'gatorcomponents';
import { withI18n } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { locale } from '@/utils/locale';
import { withStyles } from '@material-ui/core/styles';
import {
  Dns, SubdirectoryArrowRight, Info,
} from '@material-ui/icons';
import { domainsAnalytics } from '@/analytics';
import shortid from 'shortid';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDate } from '@/utils/Formatters/formatDate';
import DomainStatusLabel from '@/components/Domain/DomainStatusLabel';
import DomainRenewal from '@/components/Domain/DomainRenewal';
import DomainDetailCard from '@/components/Cards/DomainDetailCard';
import { COUNTRY } from '@/config';
import LinkButton from '@/components/Buttons/LinkButton';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import styles from './styles';
import { Message } from '@/pages/common';
import { IconWarning, IconInfo } from '@/icons';
import { useStatusColors } from '@/hooks/domains/list/useStatusColors';
import { DomainItemMessage } from '../DomainItem/DomainItemMessage';
import { PROPAGATION_STATUS, DOMAIN_STATUS, INVOICE_TYPE } from '@/enums/domains/domains.enum';
import { useInvoice } from '@/hooks/domains/useInvoice';
import { useHistory } from 'react-router';
import { PropagationStatusLabel } from '@/components/Domain/PropagationStatusLabel';
import { domainsActions } from '@/redux/modules';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ModalEppKey } from '../ModalEppKey';

import {
  ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_LEGACY,
  ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD,
  ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES,
  ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG,
  ALPHA_DOMAINS_MANAGE_ADD_MANAGE_SUBSCRIPTION_BUTTON,
  ALPHA_DOMAINS_MANAGE_ENABLE_AUTO_RENEW_ACTIONS,
  ALPHA_FRONT_NEW_STATUS_OF_DOMAIN,
} from '@/config/GrowthBook/constants';

const { dnsWizard: dnsWizardAnalytics, eppKey: eppKeyAnalytics } = domainsAnalytics;

const handleActions = (actions, classes, t, id) => (actions.map((action) => {
  let handleLink;
  let analyticsAction = null;

  switch (action.name) {
    case 'alterDns':
      handleLink = `${t('routes.domains')}${t('routes.dns')}/${id}`;
      break;
    case 'editContact':
      handleLink = `${action.link}${id}`;
      break;
    case 'renewDomain':
      handleLink = `${action.link}`;
      break;
    case 'protectId':
      handleLink = `${action.link}${id}#tabAddons`;
      break;
    case 'registrationLock':
      handleLink = `${action.link}${id}#tabReglock`;
      break;
    case 'manageSubscription':
      handleLink = `${action.link}${id}/domain`;
      break;
    case 'domainConfigurationWizard':
      handleLink = `${locale('routes.domains')}${locale('routes.dnsWizard')}/${id}`;
      analyticsAction = dnsWizardAnalytics.dnsWizardManageScreenButtonClick;
      break;
    default:
      handleLink = '';
      break;
  }

  return (
    <LinkButton
      onClick={analyticsAction}
      to={handleLink}
      key={shortid.generate()}
      className={`${classes.link} ${action.status}`}
      testId={`${action.name}Btn`}
    >
      <span>
        {t(`${'domaindetail.action'}.${action.name}`)}
      </span>
    </LinkButton>
  );
})
);

const handleNameServers = (arrayDns) => {
  const nameServers = arrayDns.join(' / ');
  return nameServers;
};

const DomainDetail = ({
  t,
  domain,
  classes,
  actions,
  eppKey,
  subdomains,
  domainPropagationStatus,
  canMapEppKeyButtons,
  setCanMapEppKeyButtons,
}) => {
  const enabledNewStatusDomain = useFeatureIsOn(ALPHA_FRONT_NEW_STATUS_OF_DOMAIN);
  const shouldRenderRenewActions = useFeatureIsOn(ALPHA_DOMAINS_MANAGE_ENABLE_AUTO_RENEW_ACTIONS);
  const shouldRenderManageSubscriptionActions = useFeatureIsOn(ALPHA_DOMAINS_MANAGE_ADD_MANAGE_SUBSCRIPTION_BUTTON);
  const shouldRenderExpiredOnGracePeriodMessage = true;
  const shouldRenderDnsLegacyButton = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_LEGACY);
  const shouldRenderDnsPropagationTag = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG);
  const shouldRenderDnsWizardButtonBr = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD);
  const shouldRenderDnsWizardButtonEs = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES);
  const shouldRenderDnsWizardButton = shouldRenderDnsWizardButtonBr || shouldRenderDnsWizardButtonEs;

  const domainStatusIsActive = domain.status === 'active';
  const isDotBR = domain.domain && domain.domain.includes('.com.br');
  const isEnom = domain.registrar && domain.registrar.toLowerCase() === 'enom';
  const renderEppKey = !isDotBR && !isEnom;

  const [filteredActions, setFilteredActions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let mappedActions = actions.map(e => ({
      name: e.name, link: e.name === 'manageSubscription' ? `${t('routes.subscriptions')}${t('routes.manageSubscription')}/` : e.link, label: e.label,
    }));

    if (!shouldRenderRenewActions) {
      mappedActions = mappedActions.filter(action => action.name !== 'renewDomain');
    }

    if (!shouldRenderManageSubscriptionActions) {
      mappedActions = mappedActions.filter(action => action.name !== 'manageSubscription');
    }

    if (!shouldRenderDnsWizardButton && shouldRenderDnsLegacyButton) {
      mappedActions = mappedActions.filter(action => action.name !== 'domainConfigurationWizard');
    }

    if (!shouldRenderDnsLegacyButton && shouldRenderDnsWizardButton) {
      mappedActions = mappedActions.filter(action => action.name !== 'alterDns');
    }

    if (!shouldRenderDnsWizardButton && !shouldRenderDnsLegacyButton) {
      mappedActions = mappedActions.filter(action => action.name !== 'domainConfigurationWizard');
    }

    setFilteredActions(mappedActions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, shouldRenderManageSubscriptionActions, shouldRenderRenewActions]);

  // duedate rules
  const productIsActive = domain.status === 'active';
  const today = new Date();
  const dueDate = domain.isGracePeriod ? parseISO(domain.gracePeriodDueDate) : parseISO(domain.nextDueDate);
  const dateDiff = differenceInCalendarDays(dueDate, today);
  const limitNextDueDate = 30;
  const isNextDueDate = dateDiff <= limitNextDueDate && productIsActive && !domain.isGracePeriod;
  const isOverDue = differenceInCalendarDays(parseISO(domain.nextDueDate), today) < 0 && !domain.isGracePeriod && productIsActive;
  const invoiceUrl = id => `${t('routes.invoices')}${t('routes.unpaid')}/${id}/${COUNTRY === 'br' ? 'boleto' : 'payu_boleto'}`;
  const productIsExpired = domain.status.match(/expired/i);
  const colors = useStatusColors();
  const valueDisabledRenovation = 1;
  const { generate } = useInvoice();
  const history = useHistory();

  const showEppKeyButton = renderEppKey && domainStatusIsActive;
  const showDisabledEppKeyButton = renderEppKey && !!productIsExpired;

  const eppKeyButtonDisplayedGA = useCallback(() => {
    if (showEppKeyButton && canMapEppKeyButtons) {
      eppKeyAnalytics.eppKeyButtonDisplayed();
      setCanMapEppKeyButtons && setCanMapEppKeyButtons(false);
    }
    if (showDisabledEppKeyButton && canMapEppKeyButtons) {
      eppKeyAnalytics.eppKeyButtonDisabled();
      setCanMapEppKeyButtons && setCanMapEppKeyButtons(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEppKeyButton, showDisabledEppKeyButton]);

  useEffect(() => {
    eppKeyButtonDisplayedGA();
  }, [eppKeyButtonDisplayedGA]);

  const getStatusLabel = (domainStatus) => {
    if (enabledNewStatusDomain) {
      if (isNextDueDate && !isOverDue) {
        return 'nextDueDate';
      }
      if (isOverDue) {
        return 'overDueDate';
      }
    }
    return domainStatus;
  };

  if (domain.status === 'cancelled') {
    return <Redirect to={t('routes.domains')} />;
  }

  const isDonotrenew = domain.status === 'active' ? domain.doNotRenew : 1;
  const status = enabledNewStatusDomain ? t(`statusProductDomain.${getStatusLabel(domain.status).replace(' ', '_')}`) : t(`statusProductDomain.${domain.status.replace(' ', '_')}`);
  const renewBlocked = domain.status !== 'active';
  const domainActive = domain.status === 'active';

  const callBackFunction = (idInvoice) => {
    history.push(invoiceUrl(idInvoice));
  };

  const redirectToGeneratedInvoice = () => {
    generate(domain, callBackFunction);
  };

  const generateEppKeyClickHandler = () => {
    eppKeyAnalytics.eppKeyButtonClick();
    dispatch(domainsActions.modalEppKey.open(domain.domain, domain.id));
  };

  const getMessageExpired = () => {
    if (
      shouldRenderExpiredOnGracePeriodMessage
      && !domain.gracePeriod
    ) {
      return (
        <DomainItemMessage
          variant={DOMAIN_STATUS.EXPIRED_GRACE_PERIOD_OVERDUE}
          domain={domain}
        />
      );
    }
    if (shouldRenderExpiredOnGracePeriodMessage
      && (domain.doNotRenew === valueDisabledRenovation || (
        domain.gracePeriod && !domain.isGracePeriod && (domain.invoiceType !== INVOICE_TYPE.RECOVERY))
      )) {
      return (
        <DomainItemMessage
          variant={DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_DISABLED}
          domain={domain}
          handleOnClick={redirectToGeneratedInvoice}
        />
      );
    }
    return (
      <DomainItemMessage
        variant={DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_ENABLED}
        domain={domain}
      />
    );
  };

  return (
    <Grid item xs={12} className={classes.customCard} data-testid="domain-detail">
      <DomainDetailCard
        domainStatus={domain.status}
        isNextDueDate={isNextDueDate && enabledNewStatusDomain}
        isDueDate={isOverDue && enabledNewStatusDomain}
      >
        <CardContent className={`${domain.status} ${classes.cardContentProduct}`}>
          <Grid container spacing={8} alignItems="center" className={classes.cardDetail}>

            <div className={classes.headerWrapper}>
              <div className={classes.topWrapper}>
                <Dns className="status-icon" />
                <div className={classes.domainWrapper}>
                  <Typography className={classes.cardTitle} variant="h6">
                    {domain.domain}
                  </Typography>
                  <DomainStatusLabel
                    domainStatus={domain.status}
                    isNextDueDate={isNextDueDate && enabledNewStatusDomain}
                    isDueDate={isOverDue && enabledNewStatusDomain}
                  >
                    {status}
                  </DomainStatusLabel>
                </div>
              </div>

              {domainActive && (
                <div className={classes.shortcuts}>
                  {handleActions(filteredActions, classes, t, domain.id)}
                  {showEppKeyButton && (
                    <button
                      className={`${classes.link}`}
                      type="button"
                      data-testid="generate-epp-key-btn"
                      onClick={generateEppKeyClickHandler}
                    >
                      <span>
                        {t('domaindetail.action.eppKey')}
                      </span>
                    </button>
                  )}
                </div>
              )}

              {productIsExpired && (
              <Message
                icon={<IconWarning />}
                borderColor={colors.expired.border}
                iconColor={colors.expired.icon}
                text={getMessageExpired()}
                testId="disabled_expired"
                variant="default"
              />
              )}
            </div>

            <div className={classes.contentWrapper}>
              <div className={classes.detailWrapper}>
                {!shouldRenderManageSubscriptionActions && (
                <div className="flexGrid">
                  <Typography className={classes.cardSubTitle} variant="caption">{t('nextDueDate')}</Typography>
                  <span className={classes.detailParam}>{formatDate(domain.nextDueDate)}</span>
                </div>
                )}

                {shouldRenderRenewActions && (
                  <div className="flexGrid">
                    <Typography className={classes.cardSubTitle} variant="caption">{t('autoRenewal')}</Typography>
                    <div className={classes.renewWrapper}>
                      <span className={classes.renew}>
                        <DomainRenewal
                          donotrenew={isDonotrenew}
                          domainId={domain.id}
                          disabled={domain.status !== 'active'}
                        />
                      </span>
                      {renewBlocked && (
                      <div className={classes.tooltipHelper}>
                        <Tooltip
                          title={t('domaindetail.renewBlocked')}
                          interactive
                          placement="top"
                        >
                          <Info className={classes.infoIcon} />
                        </Tooltip>
                      </div>
                      )}
                    </div>
                  </div>
                )
                  }
                <div className="flexGrid">
                  <Typography className={classes.cardSubTitle} variant="caption">{t('registerDate')}</Typography>
                  <span className={classes.detailParam}>{formatDate(domain.registrationDate)}</span>
                </div>
                {shouldRenderManageSubscriptionActions && domainStatusIsActive ? (
                  <div className="flexGridFullWidth">
                    <Message
                      Icon={<IconInfo />}
                      borderColor={colors.info.border}
                      iconColor={colors.info.icon}
                      testId="manageSubscriptionMessage"
                      text={(
                        <>
                          {t('manageSubscriptionMessage01')}
                          <strong>{t('manageSubscriptionMessage02')}</strong>
                          {t('manageSubscriptionMessage03')}
                        </>
                      )}
                      variant="default"
                    />
                  </div>
                ) : (
                  <>
                    <div className="flexGrid">
                      <Typography className={classes.cardSubTitle} variant="caption">{t('invoiceValueReminiscent')}</Typography>
                      <span className={classes.detailParam}>{formatCurrency(domain.recurringAmount)}</span>
                    </div>
                    <div className="flexGrid">
                      <Typography className={classes.cardSubTitle} variant="caption">{t('domainCyclePayment')}</Typography>
                      <span className={classes.detailParam}>{domain.billingCycle}</span>
                    </div>
                    <div className="flexGrid">
                      <Typography className={classes.cardSubTitle} variant="caption">{t('invoicePaymentMethod')}</Typography>
                      <span className={classes.detailParam}>{t(domain.paymentMethod)}</span>
                    </div>
                  </>
                )}

              </div>
              {domain.nameservers && (
                <div className={classes.dnsWrapper}>
                  <div className={classes.dnsContainer}>
                    <Typography className={classes.cardSubTitle} variant="caption">{t('dns.label')}</Typography>
                    <span className={classes.detailParam}>
                      {domain.nameservers.length === 0 ? '-' : handleNameServers(domain.nameservers)}
                    </span>
                  </div>
                  {showDisabledEppKeyButton && (
                    <NewTooltip disableMobile content={t('domaindetail.eppKeyDisabledTooltip')}>
                      <button
                        className={`${classes.link} linkDisabled`}
                        type="button"
                        data-testid="generate-disabled-epp-key-btn"
                      >
                        <span>
                          {t('domaindetail.action.eppKey')}
                        </span>
                      </button>
                    </NewTooltip>
                  )}
                </div>
              )}
            </div>

            {shouldRenderDnsPropagationTag && domainPropagationStatus && (domainPropagationStatus === PROPAGATION_STATUS.IN_PROGRESS || domainPropagationStatus === PROPAGATION_STATUS.NOT_PROPAGATED) && (
              <>
                <Grid item xs={12} className={classes.divider}>
                  <div />
                </Grid>

                <Grid className={classes.dnsPropagationWrapper}>
                  <Typography className={classes.cardSubTitle} variant="caption">{t('dns.dnsPropagation')}</Typography>

                  <Grid item xs={12} className={classes.dnsPropagation} alignItems="center">
                    <PropagationStatusLabel domain={domain.domain} hideTooltip />
                    <Grid>
                      <span className={classes.detailParam}>
                        {t('domains.dnsStatus')(domainPropagationStatus, isDotBR, `${t('routes.domains')}${t('routes.dnsWizard')}/${domain.id}`).labelDomainsDetails}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.divider}>
                  <div />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography className={classes.subdomainTitle}>
                <SubdirectoryArrowRight className={classes.subdomainsIcon} />
                <span className={classes.cardSubTitle}>{t('subdomains')}</span>
              </Typography>
              <Typography className={classes.detailSubDomain} variant="subtitle1">
                {Object.keys(subdomains).length
                  ? Object.values(subdomains).map(subdomain => (
                    <span key={subdomain.url} className={classes.subdomain}>{subdomain.url}</span>
                  ))
                  : <span>{t('details.product.emptySubdomains')}</span>}
              </Typography>
            </Grid>

          </Grid>
        </CardContent>
      </DomainDetailCard>
      {eppKey.modalIsOpen && <ModalEppKey />}
    </Grid>
  );
};
const mapStateToProps = state => ({
  actions: state.domains.detail.actions,
  eppKey: state.newDomains.eppKey,
});

export default connect(mapStateToProps, null)(withI18n()(withStyles(styles, { withTheme: true })(DomainDetail)));
