import styled, { css } from 'styled-components';

export const Wrapper = styled.li`
  ${({ theme, firstItem }) => css`
    border-top: ${!firstItem && `1px solid ${theme.v2.colors.feedback.canceledPure}`};
    display: flex;
    list-style: none;
    margin: none;
    padding: 24px 0;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      flex-direction: column;
    }
  `};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexWrap = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
      gap: 4px;
    }
  `};
`;

export const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DomainContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const DomainLabel = styled.a`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    cursor: pointer;
    font-weight: ${theme.v2.font.weights.medium};
    font-size: ${theme.v2.font.sizes.xs};
    margin: 0;
    text-decoration: none;
  `}
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const TagContainer = styled.div`
  div {
    height: fit-content;
    position: static;
  }
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: end;
    margin-left: auto;
    margin-bottom: auto;
    min-width: 316px;

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      flex-direction: row-reverse;
      justify-content: start;
      margin-left: 0;
      margin-top: 16px;
    }
  `};
`;

export const Alert = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    padding: 16px 24px 0;

    p {
      color: ${theme.v2.colors.neutral.lowMedium};
    }

    div {
      padding: 0;
    }
  `};
`;

export const AlertLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    margin-left: 8px;
  `};
`;
