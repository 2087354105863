const styles = theme => ({
  icon: {
    fontSize: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
    },
  },
  st0: { fill: '#C5E0EF' },
  st1: { fill: '#2898CD' },
});

export default styles;
