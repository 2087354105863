import React from 'react';
import { withStyles } from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import { SnackbarProvider } from 'notistack';
import LinkDismissAction from '@/components/ActionsSnackbar/LinkDismiss';
import styles from './styles';


const SnackbarProviderCustom = ({ children, classes }) => (
  <SnackbarProvider
    iconVariant={{
      success: <CheckCircle className={classes.iconNotification} />,
      error: <Error className={classes.iconNotification} />,
      warning: <Error className={classes.iconNotification} />,
      info: <Error className={classes.iconNotification} />,
    }}
    maxSnack={3}
    className={classes.snackbarCustom}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    action={LinkDismissAction}
  >
    {children}
  </SnackbarProvider>
);

export default withStyles(styles)(SnackbarProviderCustom);
