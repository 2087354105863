import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Button, IconDanger, Select } from 'gatorcomponents';
import * as Styles from './ModalDomainNotContracted.styles';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { Modal } from '@/pages/common';
import { CART_URL } from '@/config/';
import { useSelector } from 'react-redux';
import analytics from '@/analytics/domains/domains.analytics';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';

const ModalDomainNotContracted = ({ hideModal, isPreChat, setMyAlertsVisible }) => {
  const { domains: domainsLocale, alerts: alertsLocale } = useLocale();
  const [selectedDomain, setSelectedDomain] = useState('');
  const domainsFromPropagation = useSelector(state => state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONTRACTED));
  const domainsFromDiagnostic = useSelector(state => state.preChat.domainsPropagation && state.preChat.domainsPropagation.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_CONTRACTED));
  const domainsNotContracted = isPreChat ? domainsFromDiagnostic : domainsFromPropagation;

  const selectOptions = domainsNotContracted && domainsNotContracted.map(domain => ({ value: domain.id, label: domain.domain }));

  const setDefaultDomain = useCallback(() => {
    if (!selectedDomain && selectOptions && selectOptions.length && selectOptions.length === 1) {
      setSelectedDomain({ value: domainsNotContracted[0].id, label: domainsNotContracted[0].domain });
    }
  }, [selectOptions, domainsNotContracted, selectedDomain]);

  useEffect(() => {
    setDefaultDomain();
  }, [setDefaultDomain, selectOptions]);

  const handleHideModal = () => {
    analytics.dnsWizard.buttonCloseDomainNotContracted();
    hideModal && hideModal();
  };

  const handleSelectOption = (option) => {
    setSelectedDomain(option);
  };

  const handleNavigation = () => {
    if (selectedDomain && selectedDomain.label) {
      analytics.dnsWizard.buttonRegisterDomainNotContracted();
      const sld = selectedDomain.label.split('.')[0];
      const tld = sld && selectedDomain.label.slice(sld.length);
      const routeToCart = sld && tld && `${CART_URL}/?pid=d&sld=${sld}&tld=${tld}&domainCycle=2`;
      routeToCart && window.open(routeToCart, '_blank');
      handleHideModal();
      setMyAlertsVisible && setMyAlertsVisible(false);
    }
  };

  return (
    <Modal
      maxWidth={625}
      onClose={handleHideModal}
      testId="select-domain-to-register-modal"
    >
      <Styles.Content>
        <Styles.Title data-testid="select-domain-to-register-modal-title">
          {domainsLocale.register.modal.title}
        </Styles.Title>
        {domainsNotContracted && domainsNotContracted.length === 1 && (
        <Styles.Info>
          <Styles.IconAdapter>
            <IconDanger color="problemPure" size="lg" />
          </Styles.IconAdapter>
          <Styles.InfoText
            data-testid="select-domain-to-register-modal-text"
          >
            {domainsLocale.register.modal.singleDomain({ domain: selectedDomain.label })}
          </Styles.InfoText>
        </Styles.Info>
        )}
        {domainsNotContracted && domainsNotContracted.length > 1 && (
          <>
            <Styles.Info alignStart showSelect={domainsNotContracted && domainsNotContracted.length > 1}>
              <IconDanger color="problemPure" size="lg" />
              <Styles.InfoText
                data-testid="select-domain-to-register-modal-text"
              >
                {domainsLocale.register.modal.multipleDomains}
              </Styles.InfoText>
            </Styles.Info>
            <Styles.SelectWrapper>
              <Styles.Select>
                <Select
                  placeholder={alertsLocale.selectPlaceHolder}
                  options={selectOptions}
                  size="md"
                  onClickSelect={handleSelectOption}
                />
              </Styles.Select>
            </Styles.SelectWrapper>
          </>
        )}
        <Styles.Actions>
          <Styles.ButtonWrapper>
            <Button
              testId="select-domain-to-register-modal-button"
              label={domainsLocale.register.modal.registerDomain}
              onClick={handleNavigation}
              disabled={!selectedDomain}
              size="large"
            />
          </Styles.ButtonWrapper>
        </Styles.Actions>
      </Styles.Content>
    </Modal>
  );
};

export default withI18n()((ModalDomainNotContracted));
