import styled, { css } from 'styled-components';

export const ThemeInfoModalWrapper = styled.div`
    align-items:center;
    display:flex;
    flex-direction:column;

    button {
      align-self:center;
      margin-bottom: 24px;     
    }
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    align-self: flex-start;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 24px 0px 24px 32px;
  `}
`;

export const ThemeImage = styled.img`
  margin-bottom: 40px;
  width: calc(100% - 64px);
`;
