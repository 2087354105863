const backup = 'backup';
const easyPage = 'easyPage';
const exclude = 'exclude';
const manage = 'manage';
const signatures = 'signatures';
const ssl = 'ssl';
const sitelock = 'sitelock';

export const useCardActionsEnum = {
  backup,
  easyPage,
  exclude,
  manage,
  signatures,
  ssl,
  sitelock,
};
