import React from 'react';
import { SubscriptionModal, ActivateRenewModal } from '@/components/billing';
import { SubscriptionItem } from '../SubscriptionItem';
import * as Style from './SubscriptionList.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import { DiscountOfferBanner } from '../../subscriptions/DiscountOfferBanner';
import { DiscountType } from '../../subscriptions/PaymentModal/PaymentModal.types';
import { Skeleton } from 'gatorcomponents';

const SubscriptionList = ({
  activateSubscription = null,
  activeStatusLabel = 'Active',
  buttonManageSubscriptionsLabel = 'Manage Subscription',
  cancelledStatusLabel = 'Cancelled',
  domainLabel = 'Domain',
  expiredStatusLabel = 'Expired',
  handleActivateSubscription,
  handleCloseRenewModal,
  loading = false,
  onMessageClick,
  modal,
  modalActiveRenewalButtonLabel = 'Confirm activation',
  modalActiveRenewalContent = 'Payment will be charged at the end of the cycle, preventing your product from being canceled and your data and files from being permanently deleted.',
  modalActiveRenewalTitle = 'Do you want to activate subscription renewal?',
  modalContent,
  onHandleManageSubscription,
  onModalClose,
  overdueStatusLabel = 'Vencida',
  pendingAnalysisStatusLabel = 'Pending Analysis',
  pendingPaymentStatusLabel = 'Pending Payment',
  pendingStatusLabel = 'Pending',
  registeredStatusLabel = 'Registred',
  subscriptions = [],
  suspendedStatusLabel = 'Suspended',
  toDueStatusLabel = 'À Vencer',
  transferredAwayStatusLabel = 'Transferred',
  openPreChatCancelFlow,
  enabledNewLayoutSubscriptionsPage = false,
  enabledDiscountOfferBanner = false,
  orderStatusLocale,
  routesLocale,
  readMoreStepper,
}) => {
  const { billing: billingLocale } = useLocale();
  const enabledDiscountOffer = subscription => subscription.discount && subscription.discount.type === DiscountType.RENEWAL;

  const {
    paymentMethods: paymentMethodsLocale,
  } = billingLocale;

  const handleOnModalCloseClick = () => {
    openPreChatCancelFlow && openPreChatCancelFlow();
    onModalClose && onModalClose();
  };

  return (
    <Style.Wrapper data-testid="billing-subscriptions">

      {modal && (
        <SubscriptionModal
          displayModal={modal.show}
          modalContent={modalContent}
          handleActivateSubscription={handleActivateSubscription}
          onClose={handleOnModalCloseClick}
          date={modal.date}
          subscription={modal.subscription}
        />
      )}

      {activateSubscription && (
        <ActivateRenewModal
          activateSubscription={activateSubscription}
          onClose={handleCloseRenewModal}
          handleActivateSubscription={handleActivateSubscription}
          title={modalActiveRenewalTitle}
          content={modalActiveRenewalContent}
          buttonRenewalLabel={modalActiveRenewalButtonLabel}
        />
      )}

      {loading ? (
        <Style.SkeletonWrapper>
          <Skeleton variant="tableSimple" />
        </Style.SkeletonWrapper>
      ) : subscriptions.map((subscription) => {
        const {
          id, type,
        } = subscription;

        const key = `subscription-item-${id}-${type.toLowerCase()}`;
        const testId = `billing-subscriptions-item-${type.toLowerCase()}-${id}`;

        const paymentMethod = paymentMethodsLocale[subscription.paymentMethod];
        const isCreditCard = paymentMethod === 'Cartão de crédito';
        const paymendMethodType = isCreditCard ? 'creditcard' : 'other';

        return (
          <Style.SubscriptionAdapter key={key} variant={enabledNewLayoutSubscriptionsPage ? 'newLayout' : 'oldLayout'}>
            <SubscriptionItem
              activeStatusLabel={activeStatusLabel}
              buttonManageSubscriptionsLabel={buttonManageSubscriptionsLabel}
              cancelledStatusLabel={cancelledStatusLabel}
              domainLabel={domainLabel}
              expiredStatusLabel={expiredStatusLabel}
              onMessageClick={onMessageClick}
              onSubscriptionManageClick={onHandleManageSubscription}
              overdueStatusLabel={overdueStatusLabel}
              pendingAnalysisStatusLabel={pendingAnalysisStatusLabel}
              pendingPaymentStatusLabel={pendingPaymentStatusLabel}
              pendingStatusLabel={pendingStatusLabel}
              registeredStatusLabel={registeredStatusLabel}
              subscription={subscription}
              suspendedStatusLabel={suspendedStatusLabel}
              testId={testId}
              toDueStatusLabel={toDueStatusLabel}
              transferredAwayStatusLabel={transferredAwayStatusLabel}
              enabledNewLayoutSubscriptionsPage={enabledNewLayoutSubscriptionsPage}
              orderStatusLocale={orderStatusLocale}
              routesLocale={routesLocale}
              readMoreStepper={readMoreStepper}
              paymendMethodType={paymendMethodType}
              isCreditCard={isCreditCard}
            />
            {enabledDiscountOfferBanner && enabledDiscountOffer(subscription) && (
            <DiscountOfferBanner origin="subscription_list" subscription={subscription} />
            )}
          </Style.SubscriptionAdapter>
        );
      })}
    </Style.Wrapper>
  );
};

export default SubscriptionList;
