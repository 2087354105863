import React from 'react';
import * as Style from './IconPayPal.style';

const IconPayPal = ({
  color = '#2e578b',
  testId = 'icon-paypal',
  size = 36,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="local_icon_paypal" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color}>
      <rect id="boundary" width="32" height="32" opacity="0" />
      <g id="icon" transform="translate(-15.54 -10.5)">
        <path id="Caminho_247520" data-name="Caminho 247520" d="M37.317,39.853a.2.2,0,0,1,.2-.166h4.755a7.542,7.542,0,0,0,7.384-6.052l.859-4.286a4.561,4.561,0,0,0-3.909-5.42v.1l-.871,4.286a7.955,7.955,0,0,1-7.781,6.376H33.359L31.233,45.329h0L30.2,50.49h4.99Z" transform="translate(-6.342 -7.99)" />
        <path id="Caminho_247521" data-name="Caminho 247521" d="M24.526,37.1l2.131-10.641a.2.2,0,0,1,.2-.162h4.755A7.55,7.55,0,0,0,39,20.246l.855-4.277V15.92a.647.647,0,0,1,.028-.2,1.739,1.739,0,0,0,.028-.2A4.561,4.561,0,0,0,35.374,10.5H24.842l-5.3,26.6Z" transform="translate(0 0)" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconPayPal;
