import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.feedback.canceledLight};
    padding: 24px 32px;
    margin: 24px 0px 16px 0px;
    text-align: center;
  `}
`;

export const ContentDoubts = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    text-align: center;
    a:first-child {
      display:block;
    }
  `}
`;

export const Link = styled.a`
${({ theme }) => css`
  color: ${theme.v2.colors.brand.primaryBlue};
  margin-right: 5px;
  text-decoration: underline;
`}
`;
