import { locale } from '@/utils/locale';

export const proEmailActiveTrialLocale = {
  title: locale('proEmail.activeTrialTitle'),
  trialTag: locale('proEmail.activeTrialFreeTime'),
  description: locale('proEmail.activeTrialDescription'),
  passwordError: locale('myData.changePassword.errorMinimumCharacters'),
  emailError: locale('emails.specialCharacters'),
  firstList: {
    0: locale('proEmail.activeTrialInfoPersonalize'),
    1: locale('proEmail.activeTrialInfoSpace'),
    2: locale('proEmail.activeTrialInfoApp'),
  },
  secondList: {
    0: locale('proEmail.activeTrialInfoAntv'),
    1: locale('proEmail.activeTrialInfoCalendar'),
    2: locale('proEmail.activeTrialInfoMore'),
  },
  activeTrialEmailAddress: locale('proEmail.activeTrialEmailAddress'),
  activeTrialDomain: locale('proEmail.activeTrialDomain'),
  placeHolderEmailAddress: locale('proEmail.placeHolderEmailAddress'),
  activeTrialPassword: locale('proEmail.activeTrialPassword'),
  activeTrialButtonStart: locale('proEmail.activeTrialButtonStart'),
  activeTrialTerms: locale('proEmail.activeTrialTerms'),
  confirmText: locale('modalTitanTrialHirePlan.confirmText'),
  buttonAddAccountLabel: locale('modalTitanTrialHirePlan.buttonAddAccount.label'),
  emailSuggestions: {
    1: locale('modalTitanTrialHirePlan.emailSuggestions.1'),
    2: locale('modalTitanTrialHirePlan.emailSuggestions.2'),
    3: locale('modalTitanTrialHirePlan.emailSuggestions.3'),
    4: locale('modalTitanTrialHirePlan.emailSuggestions.4'),
    5: locale('modalTitanTrialHirePlan.emailSuggestions.5'),
  },
  routeEmailList: locale('routes.emailsList'),
};
