import { CALL_API } from '@/middleware/api';
import { CHANGE_PASSWORD } from '@/config/api';

import {
  ERROR_CHANGE_PASSWORD, SUCCESS_CHANGE_PASSWORD, REQUEST_CHANGE_PASSWORD,
} from './actionsTypes';


const receivePassword = () => ({
  type: SUCCESS_CHANGE_PASSWORD,
});

export const saveChangePassword = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: CHANGE_PASSWORD,
    method: 'PUT',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_CHANGE_PASSWORD }),
      success: (response) => {
        if (response.data.result === 'success') {
          return receivePassword();
        }
        return {
          type: ERROR_CHANGE_PASSWORD,
          error_message: response.data.error_message,
          notifications: response.notifications,
        };
      },
      error: () => ({ type: ERROR_CHANGE_PASSWORD }),
    },
  },
});
