const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
  border: {
    borderLeft: `5px solid ${theme.color.indigo}`,
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '& .cardLabel': {
      height: '20px',
    },
  },
  content: {
    padding: '20px 30px 30px 30px',
  },
  title: {
    margin: 0,
    padding: 0,
    fontSize: '24px',
    color: theme.color.tundora,
  },
  save: {
    minWidth: '87px',
    [theme.breakpoints.up('xs')]: {
      marginLeft: '10px',
    },
  },
});

export default styles;
