const styles = theme => ({
  container: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '&> .active': {
      borderLeft: `5px solid ${theme.color.oceanGreen}`,
      '& .status-icon': {
        color: theme.color.oceanGreen,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.oceanGreen,
      },
      '& .barChecked': {
        '& ~ .roundBar': {
          backgroundColor: theme.color.oceanGreen,
        },
      },
      '& .roundIcon': {
        backgroundColor: theme.color.mystic,
      },
      '& .iconChecked': {
        backgroundColor: theme.color.oceanGreen,
      },
      '& .switchRoot': {
        '& .roundBar': {
          backgroundColor: theme.color.tundora,
        },
      },
    },
    '&> .pending': {
      borderLeft: `5px solid ${theme.color.brightSun}`,
      '& .status-icon': {
        color: theme.color.brightSun,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.brightSun,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.brightSun,
      },
    },
    '&> .activationpending': {
      borderLeft: `5px solid ${theme.color.coral}`,
      '& .status-icon': {
        color: theme.color.coral,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.coral,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.coral,
      },
    },
    '&> .fraud': {
      borderLeft: `5px solid ${theme.color.brightSun}`,
      '& .status-icon': {
        color: theme.color.brightSun,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.brightSun,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.brightSun,
      },
    },
    '&> .suspended': {
      borderLeft: `5px solid ${theme.color.valencia}`,
      '& .status-icon': {
        color: theme.color.valencia,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.valencia,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.valencia,
      },
    },
    '&> .cancelled': {
      borderLeft: `5px solid ${theme.color.geyser}`,
      '& .status-icon': {
        color: theme.color.geyser,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.geyser,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.geyser,
      },
    },
    '&> .terminated': {
      borderLeft: `5px solid ${theme.color.geyser}`,
      '& .status-icon': {
        color: theme.color.geyser,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.geyser,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.geyser,
      },
    },
    '&> .building': {
      borderLeft: `5px solid ${theme.color.coral}`,
      '& .status-icon': {
        color: theme.color.coral,
      },
      '& .product-status-label': {
        backgroundColor: theme.color.coral,
      },
      '& .warningStatusColor': {
        borderColor: theme.color.coral,
      },
    },
    '& .cardTitle': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '1.2',
    },
    '& .cardSubTitle': {
      color: theme.color.regentGray,
    },
    '& .cardTitleLetter': {
      letterSpacing: '-0.5px',
    },
    '& .cardContentProduct': {
      paddingTop: '24px',
      paddingLeft: '30px',
      paddingRight: '30px',
      '&:last-child': {
        paddingBottom: '6px',
      },
      [theme.breakpoints.down('xs')]: {
        '&:last-child': {
          paddingBottom: '18px',
        },
      },
      [theme.breakpoints.up('sm')]: {
        '&:last-child': {
          paddingBottom: '24px',
        },
      },
    },
  },
});

export default styles;
