import React, { useContext, useEffect } from 'react';
import { Button } from '@/pages/common';
import * as Styles from './WizardTips.styles';
import celebration from '@/media/siteBuilder/celebration.png';
import { IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { FaqContent } from '@/pages/sites/components/FaqContent';
import { AsaasSitesAdvertisement } from '../AsaasSitesAdvertisement';
import { isBRServer } from '@/utils/Validators/validation';
import { WizardAsaasLoginBanner } from '../WizardAsaasLoginBanner';
import { BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

let timeoutAutoSsl = null;
const WizardTips = ({
  title = 'title sample',
  subtitle = null,
  seeBellow,
  hiddenInfo = 'hiddenInfo sample',
  buttonLabel = null,
  onClickButton,
  testId = 'sitebuilder_tips',
  faqLocaleTitle,
  faqLocaleButton,
  questionsFaq,
  handleMoreContent,
  onClickMaterial,
  asaasTag,
  asaasTitle,
  asaasDescription,
  asaasDescriptionClick,
  asaasButtonLabel,
  asaasBannerClick,
  id,
  asaasLoginBannerTitle,
  asaasLoginBannerDescription,
  asaasLoginBannerButton,
  onClickAsaasLoginBanner,
  asaasInsideWizardShouldAppear,
  asaasReachedThridStep = false,
  enabledAsaas,
  checkPreChat,
  requestAutoSsl,
}) => {
  const theme = useContext(ThemeContext);
  const isSiteBuilderWindowOpenEnabled = useFeatureIsOn(BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN);
  const { palette } = theme;
  const isSiteBuilderTips = id === 'sitebuilder';
  const shouldRenderButtonInSiteBuilder = isSiteBuilderTips && !isSiteBuilderWindowOpenEnabled;
  const shouldRenderSubtitleInSiteBuilder = isSiteBuilderTips && isSiteBuilderWindowOpenEnabled;
  const renderSubtitle = typeof subtitle === 'function' ? subtitle() : subtitle;
  const isWordPressTips = id === 'wordpress'
  || id === 'wordpress-woocommerce'
  || id === 'wordpress-woocommerce-trial'
  || id === 'wordpress-woocommerce-plus';

  useEffect(() => {
    checkPreChat && checkPreChat();
  }, [checkPreChat]);

  const handleClick = () => {
    onClickButton && onClickButton();

    if (shouldRenderButtonInSiteBuilder) {
      const tenSeconds = 10000;
      clearTimeout(timeoutAutoSsl);
      timeoutAutoSsl = setTimeout(() => {
        requestAutoSsl && requestAutoSsl();
      }, tenSeconds);
    }
  };

  const descriptionClick = () => {
    asaasDescriptionClick && asaasDescriptionClick();
  };

  return (
    <Styles.Container data-testid={testId}>
      <Styles.TitleWrapper>
        <Styles.ContentWrapper>
          <Styles.TitleRow>
            <Styles.Image src={celebration} alt="celebration" />

            <Styles.Title data-testid={`${testId}_title`}>{title}</Styles.Title>
          </Styles.TitleRow>

          {((!isSiteBuilderTips && buttonLabel)
          || shouldRenderButtonInSiteBuilder) && (
            <Styles.ButtonWrapper>
              <Button
                variant="newPrimary"
                font="secondary"
                size="large"
                label={buttonLabel}
                onClick={handleClick}
                testId={`${testId}_button`}
              />
            </Styles.ButtonWrapper>
          )}
        </Styles.ContentWrapper>

        {((!isSiteBuilderTips && subtitle)
        || shouldRenderSubtitleInSiteBuilder) && (
          <Styles.Subtitle data-testid={`${testId}_subtitle`}>
            <Styles.SubtitleText>
              {renderSubtitle}
            </Styles.SubtitleText>
          </Styles.Subtitle>
        )}

        <Styles.HiddenInfo>
          <Styles.Icon>
            <IconInfo color={palette.yellow.main} size={30} />
          </Styles.Icon>

          <Styles.Text>
            {hiddenInfo}
          </Styles.Text>
        </Styles.HiddenInfo>
      </Styles.TitleWrapper>

      {isBRServer && isWordPressTips && enabledAsaas && !asaasReachedThridStep && (
        <AsaasSitesAdvertisement
          newTag={asaasTag}
          title={asaasTitle}
          description={asaasDescription}
          onClick={() => descriptionClick()}
          knowMore={asaasButtonLabel}
          handleKnowMoreClick={asaasBannerClick}
          wizardVariant
        />
      )}

      {isWordPressTips && asaasInsideWizardShouldAppear && asaasReachedThridStep && (
        <WizardAsaasLoginBanner
          title={asaasLoginBannerTitle}
          description={asaasLoginBannerDescription}
          buttonLabel={asaasLoginBannerButton}
          onButtonClick={onClickAsaasLoginBanner}
        />
      )}

      <Styles.SeeBellowWrapper data-testid={`${testId}_seeBellow`}>
        {seeBellow[0]}
        <span>{seeBellow[1]}</span>
      </Styles.SeeBellowWrapper>

      <FaqContent
        questionsFaq={questionsFaq}
        faqLocaleTitle={faqLocaleTitle}
        faqLocaleButton={faqLocaleButton}
        handleMoreContent={handleMoreContent}
        onClickMaterial={onClickMaterial}
      />
    </Styles.Container>
  );
};

export default WizardTips;
