import React from 'react';
import * as Style from './IconOpenCart.style';

const IconOpenCart = ({
  color = '#4a4a4a',
  width = '37',
  height = '27',
  testId = 'icon-open-cart',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 37.504 26.991">
      <g id="Grupo_39831" data-name="Grupo 39831" transform="translate(-1112.248 -548.108)">
        <g id="_x32_38-opencart" transform="translate(1144.247 444.639)">
          <g id="Grupo_39662" data-name="Grupo 39662" transform="translate(-31.999 103.469)">
            <path id="Caminho_247733" data-name="Caminho 247733" d="M50.807,114.788a2.678,2.678,0,1,1-2.672-2.684A2.664,2.664,0,0,1,50.807,114.788ZM35.928,112.1a2.678,2.678,0,1,0,2.684,2.684A2.677,2.677,0,0,0,35.928,112.1ZM53.022,96.282c-17.709,0-22.221-.75-27.021-5.813,2.016,3.03,3.123,8.726,21.864,8.45,19.532-.293,7.618,5.046,4.149,11.07,10.941-9.769,18.717-13.707,1.008-13.707Z" transform="translate(-26.001 -90.469)" fill="#4a4a4a" />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconOpenCart;
