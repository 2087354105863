import React from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import TicketChat from '@/components/Tickets/TicketChat';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import styles from './styles';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_TICKET_HISTORY, REMOVE_TICKETS } from '@/config/GrowthBook/constants';
import { TicketHistoryHandler } from '../TicketHistoryHandler';
import * as Styles from './TicketDetails.styles';
import useLocale from '@/hooks/useLocale';

const TicketDetails = ({ classes }) => {
  const { id, v2 } = useParams();
  const enableTicketHistory = useFeatureIsOn(CHARLIE_TICKET_HISTORY);
  const shouldHideTicketsList = useFeatureIsOn(REMOVE_TICKETS);
  const { ticket: ticketLocale } = useLocale();

  if (shouldHideTicketsList) {
    return (
      <ContentContainer className={classes.root}>
        <Styles.UnderMaintenance>
          {ticketLocale.underMaintenance}
        </Styles.UnderMaintenance>
      </ContentContainer>
    );
  }

  if (enableTicketHistory) {
    return (
      <TicketHistoryHandler />
    );
  }

  return (
    <div>
      <ContentContainer className={classes.root}>
        <TicketChat ticketId={id} v2={v2} />
      </ContentContainer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(TicketDetails);
