import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import shortid from 'shortid';
import { Typography, withStyles } from '@material-ui/core';
import ProductPageItem from '@/components/Products/ProductPageItem';
import Loader from '@/components/Layout/Loader';
import FaqProducts from '@/components/FrequentlyQuestions/FaqProducts';
import FilterProducts from '@/components/Filters/FilterProducts';
import NoResultMessageTrans from '@/components/Notification/NoResultMessageTrans';
import FilterCard from '@/components/Cards/FilterCard';
import FilterButton from '@/components/Buttons/FilterButton';
import ActionButton from '@/components/Buttons/ActionButton';
import { SITE_HG } from '@/config';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import { ProductsContext } from '@/contexts/Products/context';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import styles from './styles';
import ResellerMessage from './ResellerMessage';
import PageMessages from '@/pages/common/PageMessages';

const LOCATION_PRODUCTS = 'produtos';

const ProductPageContent = ({ classes, t }) => {
  const {
    featureOldResellerNotification,
    loading,
    loadMore,
    oldResellerId,
    products,
    productsOldResellers,
    shouldDisplayResellerMessage,
    suspendedProducts,
    totalProducts,
    visible,
  } = useContext(ProductsContext);

  const filterButton = (
    <FilterButton target={SITE_HG}>
      {t('hireProduct')}
    </FilterButton>
  );

  if (loading) {
    return <Loader />;
  }

  if (totalProducts.length === 0) {
    return (
      <NoResultMessageTrans
        languageKey="noResults.tab.products"
        urlTarget={HOSTING_URL}
      />
    );
  }

  const showLoadMore = visible < products.length;

  return (
    <>
      { (shouldDisplayResellerMessage && featureOldResellerNotification) && (
        <ResellerMessage />
      )}

      <PageMessages
        displayDomainAlertManager={false}
        displayDomainPropagationAlert
      />

      <div className={classes.contentWrapper}>
        <FilterCard title={t('lists.products')} button={filterButton} useMediaWidth={1280}>
          <FilterProducts total={totalProducts.length} />
        </FilterCard>

        {products.length === 0 ? (
          <Typography variant="caption" className={classes.message}>{t('noResults.filter')}</Typography>
        ) : (
          products.length > 0 && products.slice(0, visible).map(product => (
            <ProductPageItem
              key={shortid.generate()}
              id={product.id}
              product={product}
              oldResellerId={oldResellerId}
              productsOldResellers={productsOldResellers}
              suspendedProducts={suspendedProducts}
              products={products}
            />
          ))
        )}

        {showLoadMore && (
          <ActionButton action={loadMore}>
            {t('btn.loadMore')}
          </ActionButton>
        )}
      </div>
      <div className={classes.bottomInfoWrapper} data-testid="bottom-faq-products-content">
        <FaqProducts />
        <BottomAdvertisementHandler advertisementLocal={LOCATION_PRODUCTS} />
      </div>
    </>
  );
};

export default withI18n()(withStyles(styles)(ProductPageContent));
