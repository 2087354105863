/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  IconButton, Typography, CardContent, CardActions, Collapse, withStyles, DialogActions, CircularProgress,
} from '@material-ui/core';
import { Close, Receipt, ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { loadInvoiceDetails, getBoletoUrl } from '@/redux/actions/invoices';
import InvoiceDetails from '@/components/Invoices/InvoiceDetails';
import InvoiceSummaryCardTicket from '@/components/Cards/InvoiceSummaryCardTicket';
import Loader from '@/components/Layout/Loader';
import styles from './styles';

const TicketDialog = ({
  invoiceId,
  invoiceStatusMessage,
  close,
  classes,
  t,
}) => {
  const { loading, urlBoleto } = useSelector(state => state.invoices.boletoDataCheck);
  const [dispatched, setDispatched] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (!dispatched && !loading) {
      dispatch(getBoletoUrl(invoiceId));
      setDispatched(true);
    }
  }, [loading, urlBoleto]);

  const handleExpand = async () => {
    setShowDetails(!showDetails);

    if (!Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      const data = await dispatch(loadInvoiceDetails(invoiceId));
      setInvoiceDetails(data.invoice);
    }
  };

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">
        <div className={classes.title}>
          <span>{t('dialogTicket.title')}</span>
          <IconButton aria-label="Fechar" onClick={close} className={classes.iconClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <InvoiceSummaryCardTicket status={invoiceStatusMessage}>
          <CardContent className={classes.cardSummaryContent}>
            <Grid container spacing={8} className={classes.invoiceCardBox}>
              <div className={classes.cardFlexBox}>
                <Receipt className="receiptIcon" />
                <Typography>{t('dialog.invoiceDetails')}</Typography>
              </div>
              <CardActions>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: showDetails,
                  })}
                  onClick={handleExpand}
                >
                  <ExpandMore className={classes.arrow} />
                </IconButton>
              </CardActions>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Collapse in={showDetails} timeout="auto">
                  {invoiceDetails.id ? (
                    <CardContent>
                      <InvoiceDetails invoice={invoiceDetails} />
                    </CardContent>
                  ) : (
                    <Loader />
                  )}
                </Collapse>
              </Grid>
            </Grid>
          </CardContent>
        </InvoiceSummaryCardTicket>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          data-id="dialog-button-view-invoice"
          className={classes.btnPrimary}
          variant="contained"
          color="primary"
          autoFocus
          disabled={loading}
          href={urlBoleto}
          target="_blank"
        >
          {loading ? (<CircularProgress className={classes.circularProgressBtn} size={20} />) : (
            t('dialogTicket.view')
          )}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

TicketDialog.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  invoiceStatusMessage: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withI18n()(withMobileDialog()(TicketDialog)),
);
