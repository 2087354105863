import React, { useCallback } from 'react';
import { IconClose } from 'gatorcomponents';
import * as Style from './ModalGtm.styles';

const ModalGtm = ({ id = 'gtm' }) => {
  const handleClose = useCallback(() => {
    const component = document.getElementById(`modal-wrapper-${id}`);
    component.classList.remove('in');
  }, [id]);

  const onClickHandler = (event) => {
    event.stopPropagation();
    const target = event.target.id;

    if (
      target === `modal-wrapper-${id}`
      || target === `modal-container-${id}`
      || target === `modal-button-close-${id}`
    ) {
      handleClose();
    }
  };

  return (
    <Style.GtmModalWrapper
      id={`modal-wrapper-${id}`}
      data-testid={`modal-wrapper-${id}`}
      onClick={onClickHandler}
      aria-hidden="true"
    >
      <Style.DefaultContainer id={`modal-container-${id}`}>
        <Style.DefaultPanel>
          <Style.GtmModalCloseButton
            id={`modal-button-close-${id}`}
            data-testid={`modal-button-close-${id}`}
          >
            <IconClose />
          </Style.GtmModalCloseButton>
          <Style.GtmModalContent id={`modal-content-${id}`} />
        </Style.DefaultPanel>
      </Style.DefaultContainer>
    </Style.GtmModalWrapper>
  );
};

export default ModalGtm;
