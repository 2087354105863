import {
  REQUEST_FAQ_HELP, RECEIVE_FAQ_HELP, ERROR_FAQ_HELP, ABORT_FAQ_HELP,
  REQUEST_FAQ_MYSITES, RECEIVE_FAQ_MYSITES, ERROR_FAQ_MYSITES, ABORT_FAQ_MYSITES,
  REQUEST_FAQ_INVOICES, RECEIVE_FAQ_INVOICES, ERROR_FAQ_INVOICES, ABORT_FAQ_INVOICES,
  REQUEST_FAQ_DOMAIN, RECEIVE_FAQ_DOMAIN, ERROR_FAQ_DOMAIN, ABORT_FAQ_DOMAIN,
  RECEIVE_FAQ_DNS, REQUEST_FAQ_DOMAIN_LIST, RECEIVE_FAQ_DOMAIN_LIST, ERROR_FAQ_DOMAIN_LIST,
  ABORT_FAQ_DOMAIN_LIST, REQUEST_FAQ_EMAILS, RECEIVE_FAQ_EMAILS, ERROR_FAQ_EMAILS,
  ABORT_FAQ_EMAILS, REQUEST_FAQ_DNS, ERROR_FAQ_DNS, ABORT_FAQ_DNS,
  REQUEST_FAQ_PRODUCTS, RECEIVE_FAQ_PRODUCTS, ERROR_FAQ_PRODUCTS, REQUEST_FAQ_PRODUCT_DETAIL,
  RECEIVE_FAQ_PRODUCT_DETAIL, ERROR_FAQ_PRODUCT_DETAIL, ABORT_FAQ_PRODUCTS, RECEIVE_FAQ_PARAM, REQUEST_FAQ_PARAM, ERROR_FAQ_PARAM,
  RECEIVE_FAQ_SECURITY, ERROR_FAQ_SECURITY, ABORT_FAQ_SECURITY, REQUEST_FAQ_SECURITY,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  loading: false,
  invoices: {
    loadedDate: null,
    questions: [],
  },
  mySites: {
    loadedDate: null,
    questions: [],
  },
  emails: {
    loadedDate: null,
    questions: [],
  },
  domainList: {
    loadedDate: null,
    questions: [],
  },
  domainDetail: {
    loadedDate: null,
    questions: [],
  },
  dns: {
    loadedDate: null,
    questions: [],
  },
  help: {
    loadedDate: null,
    questions: [],
  },
  products: {
    loadedDate: null,
    questions: [],
  },
  productDetail: {
    loadedDate: null,
    questions: [],
  },
  security: {
    loadedDate: null,
    questions: [],
  },
};

export default function faq(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FAQ_HELP:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_HELP:
      return {
        ...state,
        loading: false,
        help: {
          ...state.help,
          questions: action.faqHelp,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_HELP:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_HELP:
      return {
        ...state,
        help: {
          ...state.help,
          questions: state.help.questions,
        },
      };

    case REQUEST_FAQ_MYSITES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_MYSITES:
      return {
        ...state,
        loading: false,
        mySites: {
          ...state.mySites,
          questions: action.faqMySites,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_MYSITES:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_MYSITES:
      return {
        ...state,
        mySites: {
          ...state.mySites,
          questions: state.mySites.questions,
        },
      };

    case REQUEST_FAQ_INVOICES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_INVOICES:
      return {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          questions: action.faqInvoices,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_INVOICES:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          questions: state.invoices.questions,
        },
      };

    case REQUEST_FAQ_DOMAIN:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_DOMAIN:
      return {
        ...state,
        loading: false,
        domainDetail: {
          ...state.domainDetail,
          questions: action.faqDomainDetail,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_DOMAIN:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_DOMAIN:
      return {
        ...state,
        domainDetail: {
          ...state.domainDetail,
          questions: state.domainDetail.questions,
        },
      };

    case REQUEST_FAQ_DNS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_DNS:
      return {
        ...state,
        loading: false,
        dns: {
          ...state.dns,
          questions: action.faqDns,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_DNS:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_DNS:
      return {
        ...state,
        dns: {
          ...state.dns,
          questions: state.dns.questions,
        },
      };

    case REQUEST_FAQ_DOMAIN_LIST:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_DOMAIN_LIST:
      return {
        ...state,
        loading: false,
        domainList: {
          ...state.domainList,
          questions: action.faqDomainList,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_DOMAIN_LIST:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_DOMAIN_LIST:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          questions: state.domainList.questions,
        },
      };
    case REQUEST_FAQ_EMAILS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_EMAILS:
      return {
        ...state,
        loading: false,
        emails: {
          ...state.emails,
          questions: action.faqEmails,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_EMAILS:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_EMAILS:
      return {
        ...state,
        emails: {
          ...state.emails,
          questions: state.emails.questions,
        },
      };
    case REQUEST_FAQ_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FAQ_SECURITY:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FAQ_PRODUCT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: {
          ...state.products,
          questions: action.faqProducts,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_PRODUCTS:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          questions: state.products.questions,
        },
      };
    case RECEIVE_FAQ_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
        productDetail: {
          ...state.productDetail,
          questions: action.faqProductDetail,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_FAQ_PARAM:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FAQ_PARAM:
      if (action.faqParam[0].module) {
        return {
          ...state,
          [action.faqParam[0].module]: {
            ...state[action.faqParam[0].module],
            questions: action.faqParam,
            loadedDate: action.loadedDate,
          },
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };

    case ERROR_FAQ_PARAM:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_FAQ_SECURITY:
      return {
        ...state,
        loading: false,
        security: {
          ...state.security,
          questions: action.faqSecurity,
          loadedDate: action.loadedDate,
        },
      };
    case ERROR_FAQ_SECURITY:
      return {
        ...state,
        loading: false,
      };
    case ABORT_FAQ_SECURITY:
      return {
        ...state,
        security: {
          ...state.security,
          questions: state.security.questions,
        },
      };
    default:
      return state;
  }
}
