const styles = theme => ({
  card: {
    maxHeight: '554px',
    padding: '24px',
  },
  historyTitle: {
    color: theme.color.tundora,
    fontSize: '24px',
  },
  historySubtitle: {
    color: theme.color.tundora,
    fontSize: '14px',
    marginBottom: '24px',
  },
  table: {
    width: '750px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.color.regentGray,
  },
  tableHead: {
    borderBottom: '1px solid',
    color: theme.color.regentGray,
    display: 'flex',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  tableHeaderCell: {
    width: '16.66%',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.color.regentGray,
    paddingTop: '12px',
    paddingBottom: '12px',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  tableBody: {
    height: '240px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    margin: '12px',
    marginTop: '0px',
    borderRadius: '5px',
  },
  tableRowDetails: {
    fontSize: '14px',
    display: 'flex',
    paddingX: '12px',
    paddingY: '5px',
    color: theme.color.tundora,
  },
  tableCell: {
    height: '60px',
    width: '16.66%',
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
  },
  vulnerable: {
    color: theme.color.valencia,
    whiteSpace: 'nowrap',
    '&:before': {
      content: '"⬤ "',
      fontSize: '10px',
      position: 'relative',
      top: '-2px',
    },
  },
  secure: {
    color: theme.color.oceanGreen,
    whitespace: 'nowrap',
    '&:before': {
      content: '"⬤ "',
      fontSize: '10px',
      position: 'relative',
      top: '-2px',
    },
  },
  btnDetails: {
    padding: '6px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '20px',
    backgroundColor: theme.color.white,
    color: theme.color.indigo,
    borderColor: theme.color.indigo,
    border: '1px solid',
    cursor: 'pointer',
  },
  select: {
    marginBottom: '15px',
  },

  malwareDetails: {
    maxHeight: '0px',
    transform: 'all 1s ease',
    overflow: 'hidden',
  },
  malwareDetailsOpen: {
    maxHeight: '9999px',
    transform: 'all 1s ease',
  },
  malwareDetailsContainer: {
    borderTop: '1px solid',
    padding: '12px',
    width: '100%',
    borderColor: theme.color.darkSilver,
  },
  malwareDetailsTable: {
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.color.darkSilver,
    width: '100%',
  },
  malwareHeader: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.color.regentGray,
    textAlign: 'left',
    margin: '12px',
  },
});

export default styles;
