import styled, { css } from 'styled-components';

export const DnsWizardEmailHandlerWrapper = styled.div`
${() => css`
    background-position: bottom center;
    margin: 0px;
  `}
`;

export const DnsWizardEmailHandlerContainer = styled.div`
${({ selectedEmailPlataform }) => css`
    display: grid;
    margin: ${selectedEmailPlataform ? '24px 0px 0px 0px' : '0px'}
  `}
`;

export const TextBlock = styled.div``;

export const Subtitle = styled.div`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.v1.font.weights.regular};
    line-height: ${theme.font.lineHeights.lg};
    margin: 16px 0 24px 40px;
    max-width: 75ch;
    > &:first-of-type {
      margin-top: 8px;
    }
  `}
`;
