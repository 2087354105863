import styled, { css } from 'styled-components';

export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    margin-bottom: 8px;
  `}
`;

export const TicketsContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    padding: 16px;

    svg {
      flex: 0 0 24px;
      transform: scaleX(-1);
    }
  `}
`;

export const TicketsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0px 8px 0px;
`;

export const LinksContainer = styled(TicketsContainer)`
    margin-bottom: 16px
`;

export const LinksList = styled(TicketsList)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-direction: row;
    gap: 8px 24px;
    margin-bottom: 16px;
    padding: 24px 0px;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      flex: 0 0 335px;
    }
  `}
`;

export const LinkBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const UnderMaintenance = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin-bottom: 8px;
    padding: 32px;
    text-align: center;
  `}
`;
