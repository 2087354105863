import { withStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import { useHistory } from 'react-router-dom';
import { requestUpgradePlans } from '@/redux/actions/emails';
import Loader from '@/components/Layout/Loader';
import { EmailsContext } from '@/contexts/Emails/context';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import styles from './styles';
import UpgradeEnabled from './UpgradeEnabled/UpgradeEnabled';

const TitanUpgrade = ({ classes, domain }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isEnableRedirect = true;

  const currentEmail = useSelector(({ emails }) => emails.proEmailList.find(item => item.domain === domain));
  const isProEmailIsTrial = ({
    billingcycle, type, plan, packageid,
  }) => {
    const PRO_EMAIL_TRIAL_ID_BR = 430;
    const PRO_EMAIL_TRIAL_ID_ES = 244;

    return (
      type === 'proemail'
      && billingcycle === 'Free Account'
      && plan !== 'free'
      && [PRO_EMAIL_TRIAL_ID_BR, PRO_EMAIL_TRIAL_ID_ES].includes(packageid)
    );
  };

  useEffect(() => {
    if (isEnableRedirect && currentEmail && isProEmailIsTrial(currentEmail)) {
      history.goBack();
    }
  }, [currentEmail, history, isEnableRedirect]);

  const [plans, setPlans] = useState([]);
  const { renderedEmailList } = useContext(EmailsContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const domainObject = find(propEq('domain', domain), renderedEmailList);

  useEffect(() => {
    dispatch(requestUpgradePlans()).then((response) => {
      setPlans(response.data);
      setFirstLoad(false);
    });
  }, [dispatch]);

  if (
    renderedEmailList.length === 0
    || firstLoad
    || domainObject === undefined
  ) {
    return <Loader />;
  }

  return (
    <ContentContainer className={classes.root}>
      <UpgradeEnabled domainObject={domainObject} plans={plans} />
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles)(TitanUpgrade));
