const styles = theme => ({
  dnsWizardLegacyDnsTableCopyButton: {
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      textAlign: 'left',
      textTransform: 'uppercase',
      marginRight: '8px',
      fontSize: theme.font.sizes.xxs,
      fontWeight: theme.font.weights.medium,
      color: theme.color.cyanCobaltBlue,
      lineHeight: '19px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
});

export default styles;
