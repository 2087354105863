import React from 'react';
import * as Styles from './DiscoverBenefits.styles';
import { Button } from '@/pages/common/v1/Button';
import Sitelock from '@/media/sitelock/sitelock.svg';
import { IconDetailedAlert } from '@/icons/IconDetailedAlert';
import { IconDetailedBrush } from '@/icons/IconDetailedBrush';
import { IconDetailedProtection } from '@/icons/IconDetailedProtection';

const DiscoverBenefits = ({
  title = 'title',
  benefits = [
    {
      icon: <IconDetailedAlert />,
      id: 0,
      title: 'title',
      description: 'description',
    },
    {
      icon: <IconDetailedBrush />,
      id: 1,
      title: 'title',
      description: 'description',
    },
    {
      icon: <IconDetailedProtection />,
      id: 2,
      title: 'title',
      description: 'description',
    },
  ],
  ctaTitle = 'ctaTitle',
  ctaDescription = 'ctaDescription',
  buttonLabel = 'buttonLabel',
  gaWantMoreProtection,
}) => {
  const moveScreenToTop = () => {
    gaWantMoreProtection();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Styles.Container data-testid="discover-benefits">
      <Styles.Title>
        {title}
      </Styles.Title>

      <Styles.BenefitsWrapper>
        {benefits.map(benefit => (
          <Styles.BenefitWrapper key={benefit.id}>
            {benefit.icon}
            <Styles.BenefitTitle>
              {benefit.title}
            </Styles.BenefitTitle>
            <Styles.BenefitDescription>
              {benefit.description}
            </Styles.BenefitDescription>
          </Styles.BenefitWrapper>
        ))}
      </Styles.BenefitsWrapper>

      <Styles.Logo src={Sitelock} />

      <Styles.CTAtitle>
        {ctaTitle}
      </Styles.CTAtitle>

      <Styles.CTAdescription>
        {ctaDescription}
      </Styles.CTAdescription>

      <Styles.RowButtonWrapper>
        <Button
          testId="second-row-button"
          label={buttonLabel}
          onClick={() => moveScreenToTop()}
        />
      </Styles.RowButtonWrapper>
    </Styles.Container>
  );
};

export default DiscoverBenefits;
