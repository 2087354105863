import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './HostGatorExternalModal.styles';
import { IconError } from '@/icons';
import { Button } from '@/pages/common/v1/Button';
import { DnsWizardExternalFormField } from '@/components/domain/components/DnsWizardExternalFormField';

import { color } from '@/styles/HgThemeProvider';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const HostGatorExternalModal = ({
  testId = 'customdnszonemodal',
  handleClose = () => {},
  externalModal,
  platformName,
  platform,
  modalsLocale = {
    title: p => p,
    subtitle: 'subtitle',
    alertMessage: () => {},
    configureButtonLabel: 'button',
    checkboxText: 'checkboxText',
  },
  propagationHours = '??',
  analytics = {},
  defaultNameServers = [],
  firstTimeConfigure = false,
  handleConfigureExternalPlatform = p => p,
  errorsLocale = {},
}) => {
  const { mustard } = color;

  const {
    dnsWizardExternalPlatformModalCloseButtonClick,
    dnsWizardExternalPlatformModalConfirmButtonClick,
    dnsWizardExternalPlatformModalEditNameServerButtonClick,
    dnsWizardExternalPlatformModalHelpLinkClick,
  } = analytics;

  const [ns1, setNs1] = useState(defaultNameServers[0]);
  const [ns2, setNs2] = useState(defaultNameServers[1]);
  const [ns3, setNs3] = useState(defaultNameServers[2]);
  const [ns4, setNs4] = useState(defaultNameServers[3]);
  const [editingNs1, setEditingNs1] = useState(true);
  const [editingNs2, setEditingNs2] = useState(false);
  const [editingNs3, setEditingNs3] = useState(false);
  const [editingNs4, setEditingNs4] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSubmit = () => {
    const submitData = {
      platformId: platform.platformID,
      platformName,
      master: {
        name: 'master',
        host: ns1,
        ip: '',
      },
      slave1: {
        name: 'slave1',
        host: ns2,
        ip: '',
      },
      slave2: {
        name: 'slave2',
        host: ns3,
        ip: '',
      },
      slave3: {
        name: 'slave3',
        host: ns4,
        ip: '',
      },
    };

    handleConfigureExternalPlatform(HOSTING_PLATFORM_TYPES.EXTERNAL, submitData);

    dnsWizardExternalPlatformModalConfirmButtonClick(platformName);
    handleClose();
    setChecked(false);
  };

  const handleCloseModal = () => {
    dnsWizardExternalPlatformModalCloseButtonClick(platformName);
    setChecked(false);
    handleClose();
  };

  const {
    title, subtitle, alertMessage, configureButtonLabel, serverLabel,
  } = modalsLocale;

  const canGoToNextStep = useCallback(() => {
    const isNotEditing = !editingNs1 && !editingNs2 && !editingNs3 && !editingNs4;
    setChecked(isNotEditing);
  }, [editingNs1, editingNs2, editingNs3, editingNs4]);

  useEffect(() => {
    canGoToNextStep();
  }, [canGoToNextStep]);

  useEffect(() => {
    setNs1(defaultNameServers[0]);
    setNs2(defaultNameServers[1]);
    setNs3(defaultNameServers[2]);
    setNs4(defaultNameServers[3]);
  }, [defaultNameServers]);

  return (
    <>
      {externalModal
      && (
        <Modal onClose={handleCloseModal} maxWidth={780}>
          <Styles.HostGatorOtherPlatformModalWrapper data-testid={testId}>
            <Styles.TitleWrapper data-testid="externalmodal-title">
              <Styles.Title>
                {title(platformName)}
              </Styles.Title>
              <Styles.SubTitle data-testid="externalmodal-subtitle">{subtitle}</Styles.SubTitle>
            </Styles.TitleWrapper>
            <Styles.FieldsWrapper>
              {ns1 && (
              <DnsWizardExternalFormField
                label={`${serverLabel} 1:`}
                inputValue={ns1}
                controlName="ctrl01"
                handleEdit={() => { dnsWizardExternalPlatformModalEditNameServerButtonClick(platformName, 1); }}
                setValue={setNs1}
                getMessage={errorsLocale.getMessage}
                editing={editingNs1}
                setEditing={setEditingNs1}
                focused
              />
              )}
              {ns2 && (
              <DnsWizardExternalFormField
                label={`${serverLabel} 2:`}
                inputValue={ns2}
                controlName="ctrl02"
                handleEdit={() => { dnsWizardExternalPlatformModalEditNameServerButtonClick(platformName, 2); }}
                setValue={setNs2}
                getMessage={errorsLocale.getMessage}
                editing={editingNs2}
                setEditing={setEditingNs2}
              />
              )}
              {ns3 && (
              <DnsWizardExternalFormField
                label={`${serverLabel} 3:`}
                inputValue={ns3}
                controlName="ctrl03"
                handleEdit={() => { dnsWizardExternalPlatformModalEditNameServerButtonClick(platformName, 3); }}
                setValue={setNs3}
                getMessage={errorsLocale.getMessage}
                editing={editingNs3}
                setEditing={setEditingNs3}
              />
              )}
              {ns4 && (
              <DnsWizardExternalFormField
                label={`${serverLabel} 4:`}
                inputValue={ns4}
                controlName="ctrl04"
                handleEdit={() => { dnsWizardExternalPlatformModalEditNameServerButtonClick(platformName, 4); }}
                setValue={setNs4}
                getMessage={errorsLocale.getMessage}
                editing={editingNs4}
                setEditing={setEditingNs4}
              />
              )}
            </Styles.FieldsWrapper>

            <Styles.MessageWrapper data-testid="externalmodal-message">
              <IconError size="24" color={mustard} />
              {alertMessage(propagationHours, platformName, () => dnsWizardExternalPlatformModalHelpLinkClick(platformName))}
            </Styles.MessageWrapper>

            <Styles.ButtonWrapper>
              <Button disabled={!firstTimeConfigure && !checked} label={configureButtonLabel} onClick={handleSubmit} />
            </Styles.ButtonWrapper>
          </Styles.HostGatorOtherPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorExternalModal;
