const styles = theme => ({
  list: {
    listStyle: 'none',
    padding: '0 20px 0 29px',

    [theme.breakpoints.down(1291)]: {
      padding: '0 0 0 0',
    },
  },

  listLabel: {
    color: theme.color.regentGray,
    fontSize: '12px',
    userSelect: 'none',
  },

  listItem: {
    color: theme.color.tundora,
    fontSize: '14px',
    marginBottom: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
});

export default styles;
