const styles = theme => ({

  expandPanel: {
    animationDuration: '0.50s',
    animationName: 'expand',
    transition: 'opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },

  formWrapper: {
    padding: '10px 20px 0 29px',

    [theme.breakpoints.down(1291)]: {
      padding: '10px 0 0 0',
    },
  },

  list: {
    listStyle: 'none',
    padding: 0,
  },

  listLabel: {
    userSelect: 'none',
    color: theme.color.regentGray,
    fontSize: '12px',
  },

  listItem: {
    userSelect: 'none',
    color: theme.color.tundora,
    fontSize: '14px',
    marginBottom: '15px',
  },
});

export default styles;
