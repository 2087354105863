import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '@/pages/common/Modal';
import { IconError } from '@/icons';
import * as Styles from './HostGatorAlterPlatformModal.styles';
import { Button } from '@/pages/common/v1/Button';
import { Tooltip } from '@/pages/common/v1/Tooltip';
import { DnsWizardHostingPlatformsGrid } from '@/components/domain/components/DnsWizardHostingPlatformsGrid';
import { DnsWizardBannerHosting } from '../../DnsWizardBannerHosting';
import { domainsAnalytics } from '@/analytics';
import { PLATFORM_NAMES } from '@/enums/domains/dns.enum';

const HostGatorAlterPlatformModal = ({
  testId = 'hostgatoralterplatformmodal',
  hostGatorAlterPlatformModal = false,
  showHostGatorPlanOffer = false,
  handleClose = () => {},
  handleContinue = () => {},
  title = 'Title',
  subtitle = 'subTitle',
  buttonText = 'Configurar',
  tooltipMessage = 'tooltipMessage',
  cardContentText = 'cardContentText',
  selectedPlatform = '',
  hostingPlatforms = [],
  customSelectGa,
  hgSelectGa,
  dnsWizardExternalEditPlatformCardClick = () => {},
  dnsWizardBannerHostingProps = () => {},
  handleClickReserved,
  reservedCardText,
  isPointedToReservedServer,
  shouldRenderAdditionalPlanOfferCard,
  shouldRenderNoHostgatorPlan,
  handleReinforceModal,
}) => {
  const [selected, setSelected] = useState(false);
  const [selectedPlatformName, setSelectedPlatformName] = useState(null);
  const [selectedPlatformType, setSelectedPlatformType] = useState(null);
  const isHostGatorPlanOfferActive = true;

  const platformFormatter = (platform = '') => {
    const isString = typeof platform === 'string';
    return isString ? platform.replace(' ', '_').toLowerCase() : platform;
  };

  const filteredPlatforms = isHostGatorPlanOfferActive
    ? hostingPlatforms.filter((platform) => {
      const platformFormatted = platformFormatter(platform.platformName);
      const selectedPlatformFormatted = platformFormatter(selectedPlatform);
      return platformFormatted !== selectedPlatformFormatted;
    })
    : hostingPlatforms;

  const handleScrolBottom = useCallback(() => {
    if (selectedPlatformName) {
      const modalRef = document.getElementById('alterPlataformModal-card');

      if (modalRef) {
        modalRef.scrollTo({
          top: modalRef.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedPlatformName]);

  useEffect(() => {
    handleScrolBottom();
  }, [handleScrolBottom]);

  const MAX_GRID_COLUMNS = 4;


  const getDnsWizardHostingPlatformsGridProps = useCallback(() => {
    const defaultProps = {
      hostingPlatforms: filteredPlatforms,
      hasAvailableHostGatorPlan: true,
      testId: 'platform-grid',
      cardContentText,
      selected,
      selectable: true,
      customSelectGa,
      hgSelectGa,
      selectedPlatform: selectedPlatformName,
      dnsWizardExternalEditPlatformCardClick,
      maxGridColumns: MAX_GRID_COLUMNS,
      reservedCardText,
      handleClickReserved,
      isPointedToReservedServer,
    };

    const handleSelectCard = ({ platform, platformName }) => {
      setSelected(true);
      setSelectedPlatformType(platform);
      setSelectedPlatformName(platformName);

      if (platformName.toLowerCase() === PLATFORM_NAMES.hostgator.toLowerCase()) {
        if (shouldRenderAdditionalPlanOfferCard || shouldRenderNoHostgatorPlan) {
          handleReinforceModal && handleReinforceModal();
        }
      }
    };

    return {
      ...defaultProps,
      handleSelectCard,
    };
  }, [
    cardContentText,
    customSelectGa,
    dnsWizardExternalEditPlatformCardClick,
    filteredPlatforms,
    handleClickReserved,
    hgSelectGa,
    isPointedToReservedServer,
    reservedCardText,
    selected,
    selectedPlatformName,
    shouldRenderAdditionalPlanOfferCard,
    handleReinforceModal,
    shouldRenderNoHostgatorPlan,
  ]);

  const handleConfig = () => {
    domainsAnalytics.dnsWizard.continueCtaClick();
    handleContinue(selectedPlatformType, selectedPlatformName);
  };

  useEffect(() => {
    setSelected(false);
  }, [hostGatorAlterPlatformModal]);

  return (
    <>
      {hostGatorAlterPlatformModal
      && (
        <Modal onClose={handleClose} maxWidth={1067} maxHeight={660} testId="alterPlataformModal">
          <Styles.HostGatorAlterPlatformModalWrapper data-testid={testId}>
            <div>
              <Styles.TitleWrapper data-testid="hostgatoralterplatformmodal-title">
                <Styles.Title>
                  {title}
                  <Tooltip
                    content={tooltipMessage}
                    preferredPosition="bottom"
                    testId="hostgatoralterplatformmodal-tooltip"
                  >
                    <IconError />
                  </Tooltip>
                </Styles.Title>
              </Styles.TitleWrapper>
              <Styles.SubTitle data-testid="hostgatoralterplatformmodal-subtitle">
                {subtitle}
              </Styles.SubTitle>
            </div>
            {(showHostGatorPlanOffer && isHostGatorPlanOfferActive) && (
              <DnsWizardBannerHosting {...dnsWizardBannerHostingProps()} />
            )}
            <DnsWizardHostingPlatformsGrid
              {...getDnsWizardHostingPlatformsGridProps()}
            />
            <Styles.ButtonWrapper>
              <Button disabled={!selected} label={buttonText} onClick={handleConfig} testId="hostgatoralterplatformmodal-continuebutton" />
            </Styles.ButtonWrapper>
          </Styles.HostGatorAlterPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorAlterPlatformModal;
