import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './AdvancedDnsZoneModal.styles';
import { Button } from '@/pages/common/v1/Button';
import { Alert, Checkbox } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { domainsAnalytics } from '@/analytics';

const HostGatorAlterPlatformModal = ({
  handleCloseModal,
  goToAdvancedDNSZone,
}) => {
  const [checked, setChecked] = useState(false);

  const { domains } = useLocale();
  const { advancedDnsZone } = domains && domains.dnsWizard && domains.dnsWizard.modals;

  const handleSetChecked = () => setChecked(!checked);

  const handleButtonClick = () => {
    goToAdvancedDNSZone && goToAdvancedDNSZone();
    handleCloseModal && handleCloseModal();

    if (checked) {
      localStorage.setItem('dnsAdvancedZoneDontShowModal', true);
    }
    domainsAnalytics.dnsWizard.dnsWizardContinueToEditDnsZone();
  };

  return (
    <Modal onClose={handleCloseModal} maxWidth={625}>
      <Styles.Wrapper>
        <Styles.Title>
          {advancedDnsZone.title}
        </Styles.Title>

        <Alert description={advancedDnsZone.description()} />

        <Styles.CheckboxWrapper>
          <Checkbox checked={checked} onMark={handleSetChecked} />
          <Styles.CheckboxDescription>
            {advancedDnsZone.checkboxDescription}
          </Styles.CheckboxDescription>
        </Styles.CheckboxWrapper>

        <Styles.ButtonWrapper>
          <Button label={advancedDnsZone.button} onClick={handleButtonClick} />
        </Styles.ButtonWrapper>
      </Styles.Wrapper>
    </Modal>
  );
};

export default HostGatorAlterPlatformModal;
