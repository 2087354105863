const styles = theme => ({
  button: {
    display: 'inline-block',
    marginBottom: 20,
    marginLeft: 32,
    textDecoration: 'none',
  },
  outline: {
    color: theme.color.indigo,
  },
  contentWrapper: {
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
    [theme.breakpoints.up(960)]: {
      padding: '0px 40px',
    },
  },
});

export default styles;
