import styled, { css } from 'styled-components';

export const Container = styled.section`
${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.marketingBanner};
    display: flex;
    justify-content: center;
    gap: 72px;
    padding: 55px 0px;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      gap: 20px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column;
      gap: 40px;
      padding: 32px 0px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.white.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 30px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 40px;
    margin: 0px 0px 20px 0px;
    max-width: 380px;
    text-align: left;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      max-width: 260px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
        font-size: 20px;
        line-height: 27px;
        margin: 0px 0px 20px 0px;
        max-width: 288px;
        text-align: center;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 232px;

    @media (min-width: 1920px) {
      width: 335px;
    }

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 287px;
    }

    > button {
      width: 100%;
    }
  `}
`;

export const BannerSnappy = styled.img`
${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.md}) {
      width: 300px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 220px;
    }
  `}
`;
