import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_PUBLIC_CLIENT_KEY, ENVIRONMENT } from '@/config';

export const initDataDog = (setDatadogIsRunning, allLogs) => {
  const clientToken = DATADOG_PUBLIC_CLIENT_KEY || '';
  if (clientToken === '') {
    return;
  }
  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: allLogs,
    sampleRate: 100,
    service: 'clientarea',
    env: ENVIRONMENT,
    version: process.env.REACT_APP_BUILD_INFO || 'not-found',
  });
  setDatadogIsRunning(true);
};
