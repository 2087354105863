import React, { useState } from 'react';
import {
  withStyles,
  CircularProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import MaskedInputTextField from '@/components/Fields/InputMasks/MaskedInputTextField';
import { cellphoneMask, cellphonePlaceholder } from '@/utils/Masks/inputMasks';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { LATAM_COUNTRIES } from '@/config/application/operatingCountriesConfig';
import styles from './styles';
import PasswordField from '@/components/Fields/PasswordField';
import { Timer } from '@/pages/common/v1';


const ChangePhoneNumber = ({
  classes,
  t,
  handleChangePhoneNumber,
  phoneNumberCountry,
  handleCancel,
  loadingChange,
  updateField,
  values,
  phoneNumber,
  passwordDescription,
  passwordLabel,
  newPhoneFlow,
  countriesCode,
  canSendForm,
  countDownResend,
  setCountDownResend,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(phoneNumberCountry);
  const latamCountries = LATAM_COUNTRIES.map(country => country.toLowerCase());

  let checkCountry = '';

  if (latamCountries.includes(selectedCountryCode.toLowerCase())) {
    checkCountry = selectedCountryCode;
  } else {
    checkCountry = 'other';
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangePhoneNumber && handleChangePhoneNumber(e);
  };

  const handleSelectedCountry = (event) => {
    setSelectedCountryCode(event.target.value);
    updateField && updateField(event);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.phoneChangeWrapper} id="styledDiv">
        <Select
          className={classes.selectCountryCode}
          data-id="select-country-code"
          value={selectedCountryCode || phoneNumberCountry}
          onChange={handleSelectedCountry}
          input={(
            <OutlinedInput
              classes={{
                root: classes.selectCustom,
                input: classes.selectCustomInput,
                notchedOutline: classes.selectCustomOutline,
                focused: classes.selectCustomFocused,
              }}
              labelWidth={0}
              name="countryCode"
              id="selectCountryCode"
            />
          )}
        >
          {Object.keys(countriesCode).map(phoneNumberCountry => (
            <MenuItem
              key={countriesCode[phoneNumberCountry].name}
              value={countriesCode[phoneNumberCountry].short_name}
            >
              {countriesCode[phoneNumberCountry].short_name}
              {' +'}
              {countriesCode[phoneNumberCountry].code}
            </MenuItem>
          ))}
        </Select>
        <MaskedInputTextField
          id="changePhoneNumberInput"
          className={classes.formInput}
          type="tel"
          name="changePhoneNumberInput"
          placeholder={cellphonePlaceholder[checkCountry.toLowerCase()] || false}
          margin="normal"
          mask={cellphoneMask[checkCountry.toLowerCase()] || false}
          variant="outlined"
          fullWidth
          value={values.changePhoneNumberInput.value}
          onChange={updateField}
          error={!!values.changePhoneNumberInput.error}
          helperText={values.changePhoneNumberInput.error}
        />
      </div>

      {newPhoneFlow && (
        <>
          <Typography className={classes.passwordInputDescriptionD}>
            {passwordDescription}
          </Typography>

          <div className={classes.passwordWrapper}>
            <PasswordField
              name="password"
              autoComplete="off"
              variant="outlined"
              margin="normal"
              type="password"
              label={passwordLabel}
              value={values.password.value}
              onChange={updateField}
              onClickDisplayKey={() => null}
              className={classes.formInput}
              error={!!values.password.error}
              helperText={values.password.error}
            />
          </div>
        </>
      )}

      <div className={classes.wrapperBoxLinks}>
        {phoneNumber
          && (
            <OutlineButton
              className={classes.button}
              onClick={() => handleCancel()}
            >
              {t('phoneVerify.buttons.cancel')}
            </OutlineButton>
          )
        }
        <PrimaryButton
          className={classes.button}
          type="submit"
          disabled={loadingChange || canSendForm() || countDownResend >0}
        >

          {countDownResend > 0
            && <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
          }

          {loadingChange && <CircularProgress color="inherit" size={24} />}
          {countDownResend <= 0 && t('phoneVerify.buttons.change')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default withI18n()(withStyles(styles)(ChangePhoneNumber));
