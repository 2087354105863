import React from 'react';
import decoLine from '@/media/survey/decoline.svg';
import titanIcon from '@/media/icons/icon_titan.svg';
import arrow from '@/media/icons/icon_arrow_right.svg';
import { Button } from '@/pages/common/';
import { IconClose } from '@/icons/IconClose';
import * as theme from '@/styles/HgThemeProvider';
import * as Styles from './TitanUpgradeToast.styles';


function TitanUpgradeToast({
  isOpen = true,
  shouldRender = true,
  testId = 'titan-upgrade-toast',
  knowMore = 'Know More',
  description = 'Description',
  yourDomain = 'Your Domain',
  title = 'Title Tag',
  newTag = 'New',
  isBr = true,
  handleToggleOpen = () => {},
  handleKnowMoreClick = () => {},
}) {
  return (
    shouldRender && isOpen && (
    <Styles.TitanUpgradeToastWrapper data-testid={testId}>
      <Styles.HeaderWrapper>
        <Styles.NewLabel isBr={isBr}>
          <span>{newTag}</span>
        </Styles.NewLabel>
        <Styles.TitanIcon src={titanIcon} alt="TitanIcon" />
        <Styles.CloseButton>
          <Button testId={`${testId}-close-toast`} icon={<IconClose color={theme.palette.grey.dark} />} variant="text" onClick={handleToggleOpen} />
        </Styles.CloseButton>
      </Styles.HeaderWrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Description>
        {description}
        <Styles.YourDomain>{yourDomain}</Styles.YourDomain>
      </Styles.Description>
      <Styles.CTA data-testid={`${testId}-open-modal`} onClick={handleKnowMoreClick} to="#" arrow={arrow}>{knowMore}</Styles.CTA>
      <Styles.DecoLine src={decoLine} alt="DecoLine" />
    </Styles.TitanUpgradeToastWrapper>
    )
  );
}

export default TitanUpgradeToast;
