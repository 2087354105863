import React, { useState } from 'react';
import { useSetConfiguration } from '@/hooks/domains/wizard';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import { DnsWizardPlanCard } from '../DnsWizardPlanCard';
import * as Styles from './DnsWizardPlanGrid.styles';

const DnsWizardPlanGrid = ({
  testId = 'dnswizard-plan-grid',
  availablePlans = [],
  setSelected, selected,
  mainDomainText,
  otherDomainsText,
  domainText,
  domainTextPlural,
  planSelectGa,
  selectedPlan,
  platformId,
  setSelectedPlanToShowInReview,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const handleSelectPlan = (planId) => {
    setSelected(true);
    setSelectedId(planId);
    setSelectedPlanToShowInReview(planId);
  };

  useSetConfiguration(HOSTING_PLATFORM_TYPES.HOSTGATOR, selectedId, platformId);

  return (
    <Styles.DnsWizardPlanGridWrapper data-testid={testId}>
      {availablePlans ? availablePlans.map(hostingPlan => (
        <DnsWizardPlanCard
          key={`planCard${hostingPlan.id}`}
          id={hostingPlan.id}
          plan={hostingPlan.name}
          principalDomain={hostingPlan.domain}
          otherDomains={hostingPlan.domains}
          type={hostingPlan.type}
          selected={(selected && (selectedId === hostingPlan.id))}
          handleSelected={handleSelectPlan}
          mainDomainText={mainDomainText}
          otherDomainsText={otherDomainsText}
          domainText={domainText}
          domainTextPlural={domainTextPlural}
          planSelectGa={planSelectGa}
          selectedPlan={selectedPlan}
        />
      )) : null}
    </Styles.DnsWizardPlanGridWrapper>
  );
};

export default DnsWizardPlanGrid;
