import styled, { css } from 'styled-components';

const ContentPadding = '24px';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-bottom: 47px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      padding: 0 40px;
      padding-bottom: 80px;
    }
  `}
`;


export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    box-shadow: ${theme.v2.shadows.dark};
    margin-top: 8px;
    min-height: 400px;
    padding: ${ContentPadding};
  `}
`;

export const RetentionTitle = styled.strong`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: 32px;
    text-align: center;
    width: 100%;

    &::after {
      content: '';

      display: block;
      height: 1px;
      width: 100%;
      margin-top: 30px;
      background-color: ${theme.v2.colors.feedback.canceledPure};

      @media (min-width: ${theme.v2.breakpoints.sm}) {
        margin-top: 10px;
      }
    }
  `}
`;

export const RetentionMailText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    line-height: 32px;
    margin-top: 32px;
    text-align: center;
    width: 100%;
    p {
      margin: 0;
    }

    strong {
      color: ${theme.v2.colors.neutral.lowDark};
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;

export const BenefitsContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    line-height: 22px;
    margin-top: 48px;
    width: 100%;

    p {
      margin: 0;
      margin-bottom: 16px;
    }

    p:first-of-type {
      margin-bottom: 22px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: row;
      gap: 30px;
      margin-top: 18px;
    }
  `}
`;

export const BenefitTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
  `}
`;

export const BenefitTopic = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;

    strong {
      color: ${theme.v2.colors.brand.primaryMedium};
    }
  `}
`;

export const SnappyImg = styled.img`
  ${({ theme }) => css`
    max-width: 209px;
    margin-bottom: 10px;
    padding-right: 12px;
    @media (min-width: ${theme.v2.breakpoints.sm}) {
      max-width: 100%;
      margin-bottom: 0;
      padding-right: 0;
    }
  `}
`;

export const CenterButtonContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConfirmationContent = styled.div`
  margin-top: 24px;
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin: 28px -${ContentPadding} -${ContentPadding} -${ContentPadding};
    padding: 24px 28px;
  `}
`;
