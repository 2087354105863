import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme, bgColor }) => css`
  background-color: ${bgColor};
  border: none;
  cursor:pointer;
  display: grid;
  flex: 1;
  max-width: 812px;
  width: 100%;
  grid-template-areas:
  "logo ."
  "description image"
  "button image";

  @media (max-width: ${theme.breakpoints.sm}) {
    grid-template-areas:
      "logo logo"
      "description image"
      "button image";
  }
  `}
`;

export const EmptyDiv = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}) {
    display: none;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.color.white};
  display: flex;
  grid-area: info;
  `}
`;

export const Logo = styled.img`
  grid-area: logo;
  padding: 24px 0px 26px 24px;
`;

export const Image = styled.img`
${({
    paddingTop, paddingRigth, paddingBottom, paddingLeft, maxWidth,
    marginTop, marginRight, marginBottom, marginLeft,
  }) => css`
    grid-area: image;
    margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft};
    max-width: ${maxWidth};
    padding: ${paddingTop} ${paddingRigth} ${paddingBottom} ${paddingLeft};
  `}
`;

export const Description = styled.div`
${({ theme }) => css`
    color: ${theme.color.white};
    font-family: Roboto;
    font-size: 20px;
    grid-area: description;
    padding: 0px 24px 29px;
    text-align: left;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.color.oceanGreen};
  `}
`;

export const Button = styled.div`
  border: 1px solid white;
  grid-area: button;
  margin: 0px auto 24px 24px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  height: 32px;
  padding: 6px 16px 6px 16px;
  text-align: center;
  width: 125px;
  border-radius: 2px;
`;
