import React from 'react';
import { withStyles } from '@material-ui/core';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import EmailsContextProvider from '@/contexts/Emails/context';
import styles from './styles';
import ProEmailListWrapper from './ProEmailListWrapper';
import PageMessages from '@/pages/common/PageMessages';

const EmailsList = ({ classes }) => (
  <>
    <PageMessages displayDomainPropagationAlert />

    <ContentContainer className={classes.root}>
      <EmailsContextProvider>
        <ProEmailListWrapper />
      </EmailsContextProvider>
    </ContentContainer>
  </>
);

export default withStyles(styles)(EmailsList);
