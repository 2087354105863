import React from 'react';
import * as Styles from './LinkNaBioStep1.styles';
import {
  IconLink, IconCheck, Input, Button,
} from 'gatorcomponents';
import linNaBioIllustration from '../../media/step1.png';
import { FAQ_LINK_NA_BIO } from '@/config/urls/linkNaBioUrls';
import withErrorBoundary from '@/utils/errorBoundary';

const LinkNaBioStep1 = ({
  checkAvailability = () => null,
  linkNaBioLoading = false,
  errorMessage = undefined,
  linkValue = '',
  nextStep = () => null,
  setLinkValue = () => null,
  stepLocale = {},
  successMessage = undefined,
  testId = 'link-na-bio-step-1',
  sanitizeLink = () => null,
  onMobile = false,
}) => {
  const setNewLinkValue = (inputEvent) => {
    setLinkValue(inputEvent.currentTarget.value);
  };

  const handleKeyDown = ({ key }) => {
    if (key && key === 'Enter') {
      checkAvailability();
    }
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Content>
        <IconLink color="primaryExtraDark" />
        <Styles.Title>
          {stepLocale.title}
        </Styles.Title>
        <Styles.Description>
          {stepLocale.description}
        </Styles.Description>

        <Styles.Features>
          <Styles.Feature>
            <IconCheck color="primaryMedium" />
            {stepLocale.free}
          </Styles.Feature>
          <Styles.Feature>
            <IconCheck color="primaryMedium" />
            {stepLocale.customizable}
          </Styles.Feature>
          <Styles.Feature>
            <IconCheck color="primaryMedium" />
            {stepLocale.easy}
          </Styles.Feature>
        </Styles.Features>

        <Input
          label={stepLocale.chooseALink}
          placeholder={stepLocale.linkPlaceholder}
          value={linkValue}
          onChange={setNewLinkValue}
          maxLength={40}
          onBlur={checkAvailability}
          disabled={linkNaBioLoading}
          errorMessage={errorMessage}
          onKeyDown={handleKeyDown}
          prefix={stepLocale.olink}
          customMask={sanitizeLink}
        />
        <Styles.AvailableMessage>
          {successMessage}
        </Styles.AvailableMessage>

        <Styles.ButtonWrapper>
          <Button
            label={stepLocale.continue}
            onClick={nextStep}
            size="large"
            disabled={!successMessage}
            loading={linkNaBioLoading}
          />
        </Styles.ButtonWrapper>

        {!onMobile && (
          <Styles.Faq>
            <Styles.FaqTitle>
              {stepLocale.knowMore}
            </Styles.FaqTitle>
            {FAQ_LINK_NA_BIO.map(faq => (
              <Styles.FaqLink key={faq.id} target="_blank" href={faq.link}>{faq.question}</Styles.FaqLink>
            ))}

          </Styles.Faq>
        )}
      </Styles.Content>

      <Styles.Img src={linNaBioIllustration} alt="Link na Bio" />
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(LinkNaBioStep1, 'LinkNaBioStep1');
