import PropTypes from 'prop-types';

export const SwitchPaymentMethodModalProps = {
  cancelRenewalDescriptionLabel: PropTypes.string,
  cancelRenewalLabel: PropTypes.string,
  cancelImmediateDescriptionLabel: PropTypes.string,
  cancelImmediateLabel: PropTypes.string,
  continueButtonLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  onCloseClick: PropTypes.func,
  onContinueButtonClick: PropTypes.func,
  onQuitButtonClick: PropTypes.func,
  quitButtonLabel: PropTypes.string,
  titleLabel: PropTypes.string,
};

export const PaymentMethods = {
  CREDIT_CARD: 'CREDIT_CARD',
  TICKET: 'BOLETO',
  PAY_PAL: 'PAY_PAL',
  PIX: 'PIX',
};
