import React, { useEffect } from 'react';
import * as Styles from './AsaasHandler.styles';
import { Header } from '@/pages/common';
import useLocale from '@/hooks/useLocale';
import { AsaasSecondRow } from './components/AsaasSecondRow';
import { AsaasFirstRow } from './components/AsaasFirstRow';
import Loader from '@/components/Layout/Loader';
import { AsaasThirdRow } from './components/AsaasThirdRow';
import { IconDetailedPay } from '@/icons/IconDetailedPay';
import { IconDetailedCheck } from '@/icons/IconDetailedCheck';
import { IconDetailedComputer } from '@/icons/IconDetailedComputer';
import { IconDetailedPhone } from '@/icons/IconDetailedPhone';
import { useSelector } from 'react-redux';
import { AsaasFourthRow } from './components/AsaasFourthRow';
import { asaasAnalytics } from '@/analytics/asaas';
import { AsaasCreateAccountModal } from './components/AsaasCreateAccountModal';
import { useAsaasRegisterForm } from '@/hooks/asaas/useAsaasRegisterForm';
import PageMessages from '@/pages/common/PageMessages';

const AsaasHandler = () => {
  const { asaas: asaasLocale } = useLocale();
  const summary = useSelector(state => state.summary);
  const { loading: displayCreateAccountModal, accountCreated } = useSelector(state => state.asaas);

  const {
    formData,
    loadingCep,
    resetForm,
    getCep,
    onChange,
    onContinue,
    enabledFormOpenAccountContinue,
    enabledMainDataContinue,
    onGoback,
    onSubmit,
    cnpjTypes,
    callformStepTwoMyCEP,
  } = useAsaasRegisterForm({ summary });

  const moveScreenToTop = () => {
    asaasAnalytics.anchorLink();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getCep(formData.fields.cep.value);
  }, [getCep, formData.fields.cep.value]);

  const firstRowProps = () => {
    const defaultProps = {
      title: asaasLocale.lp.firstRow.title(),
      description: asaasLocale.lp.firstRow.description(),
      formData,
      accountCreated,
      onChange,
      onContinue,
      loadingCep,
      enabledFormOpenAccountContinue,
      enabledMainDataContinue,
      onGoback,
      onSubmit,
      resetForm,
      cnpjTypes,
      callformStepTwoMyCEP,
    };

    const { imageItems } = asaasLocale.lp.firstRow;
    let descriptionImageAlt = '';
    imageItems.forEach((item, idx) => {
      descriptionImageAlt += (idx + 1) < imageItems.length ? `${item}, ` : item;
    });

    return {
      ...defaultProps,
      descriptionImageAlt,
      imageItems,
    };
  };

  const secondRowProps = () => {
    const defaultProps = {
      title: asaasLocale.lp.secondRow.title,
      secondSectionTitle: asaasLocale.lp.secondRow.advantagesTitle(),
      secondSectionDescription: asaasLocale.lp.secondRow.advantagesText(),
      secondSectionButtonLabel: asaasLocale.lp.secondRow.buttonLabel,
      partnership: asaasLocale.lp.secondRow.AsaasHG,
      mainTaxPrice: asaasLocale.lp.secondRow.mainTaxPrice,
      mensality: asaasLocale.lp.secondRow.mensality,
      tax: [
        asaasLocale.lp.secondRow.tax.pix,
        asaasLocale.lp.secondRow.tax.billet,
        asaasLocale.lp.secondRow.tax.creditCard,
        asaasLocale.lp.secondRow.tax.others,
      ],
      moveScreenToTop,
    };

    const onClickTaxes = () => {
      asaasAnalytics.taxesMaterial();
    };

    return { ...defaultProps, onClickTaxes };
  };

  const thirdRowProps = () => {
    const defaultProps = {
      title: asaasLocale.lp.thirdRow.title,
      erpTitle: asaasLocale.lp.thirdRow.erpTitle,
      erpDescription: asaasLocale.lp.thirdRow.erpDescription(),
      benefits: [
        {
          icon: <IconDetailedPay />,
          title: asaasLocale.lp.thirdRow.benefits[0].title,
          description: asaasLocale.lp.thirdRow.benefits[0].description,
        },
        {
          icon: <IconDetailedPhone />,
          title: asaasLocale.lp.thirdRow.benefits[1].title,
          description: asaasLocale.lp.thirdRow.benefits[1].description,
        },
        {
          icon: <IconDetailedComputer />,
          title: asaasLocale.lp.thirdRow.benefits[2].title,
          description: asaasLocale.lp.thirdRow.benefits[2].description,
        },
        {
          icon: <IconDetailedCheck />,
          title: asaasLocale.lp.thirdRow.benefits[3].title,
          description: asaasLocale.lp.thirdRow.benefits[3].description,
        },
      ],
    };
    return { ...defaultProps };
  };

  const fourthRowProps = () => {
    const defaultProps = {
      title: asaasLocale.lp.fourthRow.title,
      description: asaasLocale.lp.fourthRow.description(),
      buttonLabel: asaasLocale.lp.fourthRow.buttonLabel,
      moveScreenToTop,
    };
    return { ...defaultProps };
  };

  if (summary.loading) {
    return <Loader />;
  }

  return (
    <>
      <PageMessages displayDomainPropagationAlert />

      <Styles.Container>
        <Styles.HeaderWrapper marginbottom>
          <Header
            title={asaasLocale.lp.pageTitle}
            description={null}
            paddingBottom={false}
          />
        </Styles.HeaderWrapper>

        <AsaasFirstRow {...firstRowProps()} />

        <AsaasSecondRow {...secondRowProps()} />

        <AsaasThirdRow {...thirdRowProps()} />

        <AsaasFourthRow {...fourthRowProps()} />

      </Styles.Container>

      {displayCreateAccountModal && (
        <AsaasCreateAccountModal
          title={asaasLocale.lp.modal.createSite.title}
          description={asaasLocale.lp.modal.createSite.description}
        />
      )}
    </>
  );
};

export default AsaasHandler;
