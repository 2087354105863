import React from 'react';
import { withI18n } from 'react-i18next';
import { Close, Warning as WarningIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core/';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import styles from './styles';
import Loader from '@/components/Layout/Loader';

const ModalValidateExistentInvoice = ({
  apllyableCredit,
  classes,
  displayInvoiceModal,
  handlePay,
  loading,
  ongoingInboxAmount,
  ongoingInvoiceId,
  ongoingMailBoxPrice,
  ongoingPaymentCycle,
  ongoingPlan,
  ongoingTotalPrice,
  t,
  toggleDisplayInvoiceModal,
  width,
}) => {
  const isWidthDownSm = isWidthDown('xs', width);
  return (
    <Dialog
      fullScreen={!!isWidthDownSm}
      onClose={() => toggleDisplayInvoiceModal(false)}
      open={displayInvoiceModal}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="modalCollabPlay"
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => toggleDisplayInvoiceModal(false)}
        data-testid="closeButton"
      >
        <Close data-testid="toggleModal" />
      </IconButton>

      <DialogTitle id="alert-dialog-title" className={classes.titleElement}>
        {t('proEmail.upgradePlan.modalOldInvoice.title')}
      </DialogTitle>

      <DialogContent className={classes.containerWrapper}>
        <DialogContentText className={classes.subtitle}>
          {t('proEmail.upgradePlan.modalOldInvoice.subtitle')}
        </DialogContentText>

        <DialogContentText className={classes.innerTitle}>
          {t('proEmail.upgradePlan.modalOldInvoice.ongoingTitle')}

          <span>{t('proEmail.upgradePlan.modalOldInvoice.pendingLabel')}</span>

          <a
            href={`${t('routes.billing')}${t('routes.unpaid')}/${ongoingInvoiceId}`}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.linkButton}
          >
            {t('proEmail.upgradePlan.modalOldInvoice.viewOngoingInvoiceLabel')}
          </a>
        </DialogContentText>

        <DialogContentText className={classes.innerSubtitle}>
          {t('proEmail.upgradePlan.modalOldInvoice.ongoingSubtitle')}
        </DialogContentText>

        <div className={classes.ongoingInvoice}>
          <div className={classes.invoiceItem}>
            <Typography className={classes.planName} data-testid="ongoingPlanName">{ongoingPlan}</Typography>
            <Typography className={classes.planData} data-testid="ongoingPerMailBoxValue">{`${ongoingMailBoxPrice} ${t(`proEmail.upgradePlan.${ongoingPaymentCycle.toLowerCase()}`)} ${t('proEmail.hirePlan.perMailBoxLabelOld')}`}</Typography>
          </div>

          <div className={classes.invoiceItem}>
            <Typography className={classes.invoiceItemTitle}>{t('proEmail.upgradePlan.modalOldInvoice.mailBoxItem')}</Typography>
            <Typography className={classes.invoiceItemData} data-testid="ongoingInboxamount">{ongoingInboxAmount}</Typography>
          </div>

          {apllyableCredit > 0 && (
            <div className={classes.invoiceItem}>
              <Typography className={classes.invoiceItemTitle}>{t('proEmail.upgradePlan.modalOldInvoice.aplliedCredit')}</Typography>
              <Typography className={classes.invoiceItemData} data-testid="ongoingTotalPrice">
                {formatCurrency(apllyableCredit)}
              </Typography>
            </div>
          )}

          <div className={classes.invoiceItem}>
            <Typography className={classes.invoiceItemTitle}>{t('proEmail.upgradePlan.modalOldInvoice.totalValue')}</Typography>
            <Typography className={classes.invoiceItemData} data-testid="ongoingTotalPrice">{`${ongoingTotalPrice} ${t(`proEmail.upgradePlan.${ongoingPaymentCycle.toLowerCase()}`)}`}</Typography>
          </div>

          <div className={classes.warning} data-testid="warningMessage">
            <WarningIcon />
            {t('proEmail.upgradePlan.modalOldInvoice.warning')}
          </div>
        </div>

      </DialogContent>

      <DialogActions className={classes.actionsWrapper}>
        <OutlineButton
          className={classes.outlinedCancel}
          disabled={loading}
          onLoading={loading}
          onClick={() => toggleDisplayInvoiceModal(false)}
          data-testid="outlineButton"
        >
          {t('proEmail.upgradePlan.modalOldInvoice.close')}
        </OutlineButton>

        <PrimaryButton
          color="primary"
          disabled={loading}
          onClick={() => handlePay()}
          data-testid="primaryButton"
        >
          {loading ? <Loader data-testid="existent-invoice-loader" size={20} /> : <>{t('proEmail.upgradePlan.modalOldInvoice.execute')}</>}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

ModalValidateExistentInvoice.propTypes = {
  apllyableCredit: PropTypes.number.isRequired,
  displayInvoiceModal: PropTypes.bool.isRequired,
  handlePay: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  ongoingInboxAmount: PropTypes.number.isRequired,
  ongoingInvoiceId: PropTypes.number.isRequired,
  ongoingMailBoxPrice: PropTypes.string.isRequired,
  ongoingPaymentCycle: PropTypes.string.isRequired,
  ongoingPlan: PropTypes.string.isRequired,
  ongoingTotalPrice: PropTypes.string.isRequired,
  toggleDisplayInvoiceModal: PropTypes.func.isRequired,
};

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ModalValidateExistentInvoice)));
