import React from 'react';
import * as Styles from './WizardWondersuite.styles';
import { WizardWondersuiteTools } from '../WizardWondersuiteTools';
import { WizardWondersuiteFeatures } from '../WizardWondersuiteFeatures';
import { WizardWonderSuiteHeroBanner } from '../WizardWonderSuiteHeroBanner';

const WizardWondersuite = ({
  tools,
  heroBanner,
  testId = 'wizard-wondersuite',
  items,
}) => (
  <>
    <Styles.Wrapper data-testid={testId}>
      <WizardWonderSuiteHeroBanner {...heroBanner} />
      <WizardWondersuiteFeatures {...items} />
      <WizardWondersuiteTools {...tools} />
    </Styles.Wrapper>
  </>
);

export default WizardWondersuite;
