import styled, { css } from 'styled-components';
import colorfulHorizontalLine from '@/media/icons/colorfulHorizontalLine.svg';

export const OuterWrapper = styled.section`
    padding-bottom: 40px;
`;

export const Wrapper = styled.div`
${({ theme }) => css`
    margin: 0px;
    padding-bottom: 5px;
    background-image: url(${colorfulHorizontalLine});
    background-position: bottom center;
    box-shadow: 0px 1px 3px hsla(0, 100%, 0%, 0.29);
    margin-bottom: 40px;

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px 40px 0 40px;
    }
  `}
`;

export const InnerWrapper = styled.div`
${({ theme }) => css`
    padding: 24px;
    background-color: ${theme.v1.color.white.primary};
    display: grid;
    gap: 40px;

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      padding: 40px;
    }
  `}
`;
