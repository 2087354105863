/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useContext, useState } from 'react';
import {
  Button, Modal, Radio, TextDescriptor,
} from '@/pages/common';
import * as Styles from './ModalExcludeContent.styles';
import { IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { textRegular, textBold } from '@/pages/common/TextDescriptor';
import { IconArrowForward } from '@/icons/IconArrowForward';
import Loader from '@/components/Layout/Loader';
import { sitesHandlerGa } from '@/utils/ThirdParties/tagManager';

const ModalExcludeContent = ({
  title = 'Exclude site content',
  descriptor = [
    'This action is irreversible ',
    'We will remove site builder all files from domain ',
    ' in yout host.',
  ],
  site = {
    site: 'sample.com',
  },
  radioText = 'I wish to exclude all content anyway.',
  cancelLabel = 'Guive up exclusion',
  continueLabel = 'Confirm exclusion',
  setSiteInExclusion,
  continueExclusion,
  loadingExclusion,
}) => {
  const theme = useContext(ThemeContext);
  const [allowExclusion, setAllowExclusion] = useState(false);

  const handleClose = useCallback(() => {
    sitesHandlerGa({
      gaEventAction: 'Modal excluir site',
      gaEventLabel: 'Fechar modal',
    });
    setSiteInExclusion(null);
  }, [setSiteInExclusion]);

  const handleCancel = useCallback(() => {
    sitesHandlerGa({
      gaEventAction: 'Modal excluir site',
      gaEventLabel: 'Desistir ação',
    });
    setSiteInExclusion(null);
  }, [setSiteInExclusion]);

  const handleConfirm = useCallback(() => {
    sitesHandlerGa({
      gaEventAction: 'Modal excluir site',
      gaEventLabel: 'Confirmar exclusão',
    });
    continueExclusion(site);
  }, [continueExclusion, site]);

  return (
    <Modal
      onClose={() => handleClose()}
      disablePadding
      testId="ModalExcludeContent"
    >
      <Styles.Title data-testid="title">{title}</Styles.Title>

      <Styles.ContentWrapper>
        <IconInfo color={theme.palette.yellow.mustard} size={33} />

        <Styles.Content>
          <TextDescriptor descriptors={[
            textBold(descriptor[0]),
            textRegular(descriptor[1]),
            textBold(site.site),
            textRegular(descriptor[2]),
          ]}
          />
        </Styles.Content>
      </Styles.ContentWrapper>

      <Styles.RadioWrapper>
        <Styles.RadioLabel htmlFor="confirmationRadio" onClick={() => setAllowExclusion(true)} data-testid="radioLabel">
          <Radio
            checked={!!allowExclusion}
            inputId="confirmationRadio"
          />
          {radioText}
        </Styles.RadioLabel>
      </Styles.RadioWrapper>

      <Styles.ActionsWrapper>
        <Button
          label={cancelLabel}
          font="secondary"
          size="large"
          variant="newTertiary"
          iconLeft={(
            <IconArrowForward
              size={16}
              fill={theme.palette.secondary.primary}
              stroke={theme.palette.secondary.primary}
            />
          )}
          onClick={() => handleCancel()}
          testId="cancelButton"
        />

        <Button
          disabled={!allowExclusion || loadingExclusion}
          font="secondary"
          label={loadingExclusion ? <Loader size={16} /> : continueLabel}
          size="large"
          variant="bigBluePill"
          onClick={() => handleConfirm()}
          testId="confirmButton"
        />
      </Styles.ActionsWrapper>
    </Modal>
  );
};

export default ModalExcludeContent;
