export const DomainsActionTypes = {
  GET_DOMAINS_FAILURE: 'GET_DOMAINS_FAILURE_DOMAINS_SAGAS',
  GET_DOMAINS_REQUEST: 'GET_DOMAINS_REQUEST_DOMAINS_SAGAS',
  GET_DOMAINS_SUCCESS: 'GET_DOMAINS_SUCCESS_DOMAINS_SAGAS',
  SET_DOMAINS: 'SET_DOMAINS_DOMAINS_SAGAS',

  SET_DOMAIN_DETAIL: 'SET_DOMAIN_DETAIL',
  GET_DOMAIN_DETAIL_REQUEST: 'GET_DOMAIN_DETAIL_REQUEST',
  GET_DOMAIN_DETAIL_SUCCESS: 'GET_DOMAIN_DETAIL_SUCCESS',
  GET_DOMAIN_DETAIL_FAILURE: 'GET_DOMAIN_DETAIL_FAILURE',

  DOMAIN_ACTIVATE_RENEWAL_FAILURE: 'DOMAIN_ACTIVATE_RENEWAL_FAILURE',
  DOMAIN_ACTIVATE_RENEWAL_REQUEST: 'DOMAIN_ACTIVATE_RENEWAL_REQUEST',
  DOMAIN_ACTIVATE_RENEWAL_SUCCESS: 'DOMAIN_ACTIVATE_RENEWAL_SUCCESS',

  DOMAIN_GENERATE_NEW_INVOICE_FAILURE: 'DOMAIN_GENERATE_NEW_INVOICE_FAILURE',
  DOMAIN_GENERATE_NEW_INVOICE_REQUEST: 'DOMAIN_GENERATE_NEW_INVOICE_REQUEST',
  DOMAIN_GENERATE_NEW_INVOICE_SUCCESS: 'DOMAIN_GENERATE_NEW_INVOICE_SUCCESS',
  DOMAIN_GENERATE_NEW_INVOICE_LOADING: 'DOMAIN_GENERATE_NEW_INVOICE_LOADING',

  SET_DOMAIN_INVOICE_ID: 'SET_DOMAIN_INVOICE_ID',

  GET_DOMAIN_PROPAGATION_STATUS_REQUEST: 'GET_DOMAIN_PROPAGATION_STATUS_REQUEST',
  GET_DOMAIN_PROPAGATION_STATUS_SUCCESS: 'GET_DOMAIN_PROPAGATION_STATUS_SUCCESS',
  GET_DOMAIN_PROPAGATION_STATUS_FAILURE: 'GET_DOMAIN_PROPAGATION_STATUS_FAILURE',
  SET_DOMAIN_PROPAGATION_STATUS: 'SET_DOMAIN_PROPAGATION_STATUS',

  GET_CONFIGURED_DOMAIN_STATUS_REQUEST: 'GET_CONFIGURED_DOMAIN_STATUS_REQUEST',
  GET_CONFIGURED_DOMAIN_STATUS_SUCCESS: 'GET_CONFIGURED_DOMAIN_STATUS_SUCCESS',
  GET_CONFIGURED_DOMAIN_STATUS_FAILURE: 'GET_CONFIGURED_DOMAIN_STATUS_FAILURE',
  SET_CONFIGURED_DOMAIN_STATUS: 'SET_CONFIGURED_DOMAIN_STATUS',
  SET_EMPTY_DOMAIN_STATUS: 'SET_EMPTY_DOMAIN_STATUS',
  SET_RESET_DOMAIN_STATUS: 'SET_RESET_DOMAIN_STATUS',
  SET_LOADED_CONFIGURED_DOMAIN_STATUS: 'SET_LOADED_CONFIGURED_DOMAIN_STATUS',

  SET_DOMAIN_HAS_CONFIGURED: 'SET_DOMAIN_HAS_CONFIGURED',
  SET_DOMAIN_HAS_CONFIGURED_SUCCESS: 'SET_DOMAIN_HAS_CONFIGURED_SUCCESS',
  SET_DOMAIN_HAS_CONFIGURED_FAILURE: 'SET_DOMAIN_HAS_CONFIGURED_FAILURE',

  SET_DOMAIN_EPP_REDIRECT: 'SET_DOMAIN_EPP_REDIRECT',

  SET_MODAL_EPP_KEY_IS_OPEN: 'SET_MODAL_EPP_KEY_IS_OPEN',
  SET_MODAL_EPP_KEY_IS_CLOSED: 'SET_MODAL_EPP_KEY_IS_CLOSED',
  SET_MODAL_EPP_KEY_IS_CHANGED: 'SET_MODAL_EPP_KEY_IS_CHANGED',

  GET_EPP_KEY_GENERATED_REQUEST: 'GET_EPP_KEY_GENERATED_REQUEST',
  GET_EPP_KEY_GENERATED_SUCCESS: 'GET_EPP_KEY_GENERATED_SUCCESS',
  GET_EPP_KEY_GENERATED_FAILURE: 'GET_EPP_KEY_GENERATED_FAILURE',
  SET_EPP_KEY_GENERATED: 'SET_EPP_KEY_GENERATED',

  GET_ALL_DOMAINS_LIST_REQUEST: 'GET_ALL_DOMAINS_LIST_REQUEST',
  GET_ALL_DOMAINS_LIST_SUCCESS: 'GET_ALL_DOMAINS_LIST_SUCCESS',
  GET_ALL_DOMAINS_LIST_FAILURE: 'GET_ALL_DOMAINS_LIST_FAILURE',
  SET_ALL_DOMAINS_LIST: 'SET_ALL_DOMAINS_LIST',

  REQUEST_DOMAINS_ELEGIBLE: 'REQUEST_DOMAINS_ELEGIBLE',
  SET_DOMAINS_ELEGIBLE: 'SET_DOMAINS_ELEGIBLE',
  DOMAINS_ELEGIBLE_SUCCESS: 'DOMAINS_ELEGIBLE_SUCCESS',
  DOMAINS_ELEGIBLE_FAILURE: 'DOMAINS_ELEGIBLE_FAILURE',
};
