import { PreChatActionTypes } from './preChat.types';

const productsByStatusRequest = ({ withCancelled, withPending, waitToken }) => ({ type: PreChatActionTypes.PRODUCT_BY_STATUS_REQUEST, payload: { withCancelled, withPending, waitToken } });
const productsByStatusSuccess = () => ({ type: PreChatActionTypes.PRODUCT_BY_STATUS_SUCCESS });
const productsByStatusFailure = () => ({ type: PreChatActionTypes.PRODUCT_BY_STATUS_FAILURE });

const diagnosticRequest = ({
  enabledSubscriptionInDiagnostic,
  enableDuplicateOrders,
  waitToken,
  enableSslAlert,
}) => ({
  type: PreChatActionTypes.GET_DIAGNOSTIC_REQUEST,
  payload: {
    enabledSubscriptionInDiagnostic,
    enableDuplicateOrders,
    waitToken,
    enableSslAlert,
  },
});
const diagnosticSuccess = () => ({ type: PreChatActionTypes.GET_DIAGNOSTIC_SUCCESS });
const diagnosticFailure = () => ({ type: PreChatActionTypes.GET_DIAGNOSTIC_FAILURE });

const setDiagnostic = diagnostic => ({
  type: PreChatActionTypes.SET_DIAGNOSTIC,
  payload: { diagnostic },
});

const setProductsByStatus = productsList => ({
  type: PreChatActionTypes.SET_PRODUCT_BY_STATUS,
  payload: { productsList },
});

const diagnosticLoading = isLoading => ({
  type: PreChatActionTypes.SET_DIAGNOSTIC_LOADING,
  payload: isLoading,
});

const productsLoading = isLoading => ({
  type: PreChatActionTypes.SET_PRODUCTS_BY_STATUS_LOADING,
  payload: isLoading,
});

const diagnosticReload = () => ({
  type: PreChatActionTypes.RELOAD_DIAGNOSTIC,
});

const preChatSetOpen = show => ({
  type: PreChatActionTypes.SET_OPEN,
  payload: show,
});

const setInvoiceId = invoiceId => ({
  type: PreChatActionTypes.SET_INVOICE_ID,
  payload: invoiceId,
});

const setHoldDomainRedirect = redirect => ({
  type: PreChatActionTypes.SET_HOLD_REDIRECT,
  payload: redirect,
});

const setScreenToShowInPreChat = screen => ({
  type: PreChatActionTypes.SET_SCREEN_TO_SHOW_IN_PRECHAT,
  payload: screen,
});

const setSession = session => ({
  type: PreChatActionTypes.SET_SESSION,
  payload: session,
});

const isBillingGenerated = generated => ({
  type: PreChatActionTypes.BILLING_GENERATED,
  payload: generated,
});

const isReactivationRedirect = redirect => ({
  type: PreChatActionTypes.REACTIVATION_REDIRECT,
  payload: redirect,
});

const setTrustReactivationInvoiceId = invoiceId => ({
  type: PreChatActionTypes.SET_TRUST_REACTIVATION_INVOICE_ID,
  payload: invoiceId,
});

const setSubscriptionToCancel = productId => ({
  type: PreChatActionTypes.SET_SUBSCRIPTION_TO_CANCEL,
  payload: productId,
});

const setSubscriptionToChangePaymentMethod = productId => ({
  type: PreChatActionTypes.SET_SUBSCRIPTION_TO_CHANGE_PAYMENT_METHOD,
  payload: productId,
});

const redirectManageSubscription = isRedirect => ({
  type: PreChatActionTypes.SET_SUBSCRIPTION_REDIRECT_FROM_PRECHAT,
  payload: isRedirect,
});

const setTosRedirect = isRedirect => ({
  type: PreChatActionTypes.SET_TOS_REDIRECT,
  payload: isRedirect,
});

const setVpsRedirect = isRedirect => ({
  type: PreChatActionTypes.SET_VPS_REDIRECT,
  payload: isRedirect,
});

const setVpsProductId = productId => ({
  type: PreChatActionTypes.SET_VPS_PRODUCT_ID,
  payload: productId,
});

const setVpsTermsAccepted = accepted => ({
  type: PreChatActionTypes.SET_VPS_TERMS_ACCEPTED,
  payload: accepted,
});


const productDetailsRequest = data => ({ type: PreChatActionTypes.GET_PRODUCT_DETAILS_REQUEST, payload: data });

const productDetailsSuccess = () => ({ type: PreChatActionTypes.GET_PRODUCT_DETAILS_SUCCESS });

const productDetailsFailure = () => ({ type: PreChatActionTypes.GET_PRODUCT_DETAILS_FAILURE });

const productDetailsLoading = isLoading => ({
  type: PreChatActionTypes.SET_PRODUCT_DETAILS_LOADING,
  payload: isLoading,
});

const productDetailsSet = data => ({
  type: PreChatActionTypes.SET_PRODUCT_DETAILS,
  payload: data,
});

const onEppKeyFlowSet = onFlow => ({
  type: PreChatActionTypes.SET_EPP_KEY_FLOW,
  payload: onFlow,
});

const onReservedDomainFlow = onFlow => ({
  type: PreChatActionTypes.SET_RESERVED_DOMAIN_FLOW,
  payload: onFlow,
});

const getWeekDayRequest = () => ({
  type: PreChatActionTypes.GET_WEEK_DAY_REQUEST,
});

const setWeekDay = weekDay => ({
  type: PreChatActionTypes.SET_WEEK_DAY,
  payload: weekDay,
});

const getWeekDaySuccess = () => ({
  type: PreChatActionTypes.GET_WEEK_DAY_SUCCESS,
});

const getWeekDayFailure = () => ({
  type: PreChatActionTypes.GET_WEEK_DAY_FAILURE,
});

const setLoadingOfferContext = bool => ({ type: PreChatActionTypes.SET_LOADING_OFFER_CONTEXT_TO_NEW_ZENDESK, payload: { bool } });
const setSuccessOfferContext = () => ({ type: PreChatActionTypes.SET_SUCCESS_OFFER_CONTEXT });
const setFailureOfferContext = () => ({ type: PreChatActionTypes.SET_FAILURE_OFFER_CONTEXT });
const postOfferContextToNewZendesk = ({
  hostingId,
  domain,
  category,
  subCategory,
  serviceType,
}) => ({
  type: PreChatActionTypes.OFFER_CONTEXT_TO_NEW_ZENDESK,
  payload: {
    hostingId,
    domain,
    category,
    subCategory,
    serviceType,
  },
});

const handleChatDisplay = () => ({
  type: PreChatActionTypes.HANDLE_CHAT_DISPLAY,

});
const setShowSidePanel = () => ({
  type: PreChatActionTypes.SET_SHOW_SIDE_PANEL,
});

const actions = {
  diagnostic: {
    get: diagnosticRequest,
    set: setDiagnostic,
    success: diagnosticSuccess,
    failure: diagnosticFailure,
    loading: diagnosticLoading,
    reload: diagnosticReload,
  },
  preChat: {
    setOpen: preChatSetOpen,
    setScreen: setScreenToShowInPreChat,
    setSessionOpen: setSession,
    handleChatDisplay,
    setShowSidePanel,
  },
  invoice: {
    set: {
      invoiceId: setInvoiceId,
      holdDomainRedirect: setHoldDomainRedirect,
    },
  },
  trustReactivation: {
    billingGenerated: isBillingGenerated,
    redirect: isReactivationRedirect,
    setInvoiceId: setTrustReactivationInvoiceId,
  },
  productByStatus: {
    request: productsByStatusRequest,
    success: productsByStatusSuccess,
    failure: productsByStatusFailure,
    loading: productsLoading,
    set: setProductsByStatus,
  },
  subscription: {
    setSubscriptionToCancel,
    setSubscriptionToChangePaymentMethod,
    redirectManageSubscription,
  },
  tos: {
    redirect: setTosRedirect,
  },
  vps: {
    redirect: setVpsRedirect,
    productId: setVpsProductId,
    termsAccepted: setVpsTermsAccepted,
  },
  productDetails: {
    get: productDetailsRequest,
    success: productDetailsSuccess,
    failure: productDetailsFailure,
    loading: productDetailsLoading,
    set: productDetailsSet,
  },
  weekDay: {
    get: getWeekDayRequest,
    set: setWeekDay,
    success: getWeekDaySuccess,
    failure: getWeekDayFailure,
  },
  eppKeyGen: {
    onFlow: onEppKeyFlowSet,
  },
  reservedDomain: {
    onFlow: onReservedDomainFlow,
  },
  newZendesk: {
    offerContext: postOfferContextToNewZendesk,
    setLoadingOfferContext,
    setSuccessOfferContext,
    setFailureOfferContext,
  },
};

export default actions;
