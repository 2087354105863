import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding: 32px;

    @media (max-width: ${theme.v1.breakpoints.md}}) {
      align-items: center;
      flex-direction: column;
      padding: 28px;
    }

  `}
`;

export const Header = styled.header``;

export const TitleModal = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 16px 0;
`;

export const ControlButton = styled.div`
${({ theme }) => css`

    ${Button}:not(:first-child) {
      margin-left: 12px;
    }
    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:grid;
    }
`}
`;

export const InfoIcon = styled.span`
  ${({ theme }) => css`
     & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 21.6px;
  `}
`;


export const ContentData = styled.div`
${({ theme }) => css`
    text-align:center;
    flex-direction: column;
    margin: 42px 0px 0px 0px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:block;
    }
  `}
`;

export const QRCodeError = styled.img`
${({ theme }) => css`
    margin: 42px 0px 0px 0px;
    & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin: 0px 0px 0px 35px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px 0px 0px 0px;
      text-align: initial;

    }
  `}
`;
