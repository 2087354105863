import React from 'react';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import { Warning, Info } from '@material-ui/icons';

import styles from './styles';


const AcademyPassAlertMessage = ({ classes, className }) => {
  const alerts = useSelector(state => state.academyPass.detail.data);
  if (alerts === null || alerts === undefined) {
    return null;
  }

  if (alerts && alerts.length === 0) {
    return null;
  }

  if (alerts[0].severity === undefined) {
    return null;
  }

  return (
    <div className={classnames(classes.container, className)} data-testid="academypass-alert-message">
      {alerts.map(alert => (
        <div
          className={classnames(classes.alert, {
            [classes.alertCritical]: alert.severity.level === 'Critical',
            [classes.alertImportant]: alert.severity.level === 'Important',
            [classes.alertImportant]: alert.severity.level === 'Warning',
          })}
          key={alert.alertId}
        >
          {alert.severity.level === 'Critical' && (
            <Warning className={classes.icon} />
          )}
          {alert.severity.level === 'Important' && (
            <Info className={classes.icon} />
          )}
          {alert.severity.level === 'Warning' && (
            <Warning className={classes.icon} />
          )}
          <Typography className={classes.message}>{alert.message}</Typography>
        </div>
      ))}
    </div>
  );
};

export default withI18n()(withStyles(styles)(AcademyPassAlertMessage));
