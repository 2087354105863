import styled, { css } from 'styled-components';
import checkedBackgroundSvg from '@/media/billing/checked-background.svg';
import { Button } from '@/pages/common/v1/Button/Button.styles';
import { Wrapper as Radio, Dot as RadioDot } from '@/pages/common/inputs/Radio/Radio.styles';

export const Wrapper = styled.article``;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    padding: 32px;
  `}
`;

export const Header = styled.header``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 0;
`;

export const OptionList = styled.ul`
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
`;

export const OptionItem = styled.li`
  ${({ theme, checked }) => css`
    background-image: ${checked ? `url(${checkedBackgroundSvg})` : 'none'};
    background-size: contain;
    border-radius: ${checked ? 0 : '2px'};
    box-shadow: ${checked ? 'none' : `0 0 0 1px ${theme.v1.color.border.primary}`};
    padding: 4px;

    &:not(:first-child) {
      margin-top: 24px;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const OptionItemWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    padding: 11px;
  `}
`;

export const OptionRadio = styled.div`
  ${({ theme, checked }) => css`
    ${Radio} {
      border-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }

    ${RadioDot} {
      background-color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    }
  `}
`;

export const OptionContent = styled.div`
  padding-left: 9px;
`;

export const OptionLabel = styled.label`
  ${({ theme, checked }) => css`
    color: ${checked ? theme.v1.color.text.featured : theme.v1.color.action.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: -0.19px;
    transition: color 200ms ease-in-out;
  `}
`;

export const OptionDescription = styled.p`
  font-size: 14px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const ControlButton = styled.div`
  ${({ theme }) => css`
    &:first-child ${Button} {
      background-color: transparent;
      border-color: transparent;
      color: ${theme.v1.color.action.primary};

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: ${theme.v1.color.text.featured};
      }
    }

    &:not(:first-child) {
      margin-left: 12px;
    }

    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  `}
`;
