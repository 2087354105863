import { config } from '@/config';
import { CALL_API } from '@/middleware/api';
import { UNPAID_INVOICES_URL } from '@/config/api';
import { REQUEST_UNPAID_INVOICES, RECEIVE_UNPAID_INVOICES, ABORT_UNPAID_INVOICES } from './actionsTypes';


function requestUnpaidInvoices() {
  return {
    type: REQUEST_UNPAID_INVOICES,
  };
}

const receiveUnpaidInvoices = data => ({
  type: RECEIVE_UNPAID_INVOICES,
  invoiceMessage: Date.now(),
  data,
});

export const loadUnpaidInvoices = () => (dispatch, getState) => {
  const { loadedDate } = getState().invoices;

  if (Date.now() - loadedDate.invoiceMessage < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch({ type: ABORT_UNPAID_INVOICES }));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: UNPAID_INVOICES_URL,
      method: 'GET',
      actionTypes: {
        request: requestUnpaidInvoices,
        success: data => receiveUnpaidInvoices(data.data),
      },
    },
  });
};
