import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '@/redux/actions/actionsTypes';


const initialState = [];

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [
        ...state,
        { ...action.notification },
      ];
    case REMOVE_SNACKBAR:
      return state.filter(
        notification => notification.options.key !== action.key,
      );
    default:
      return state;
  }
}
