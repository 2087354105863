import React, { useEffect, useState } from 'react';
import * as Styles from './ModalEppKey.styles';
import { Modal } from '@/pages/common';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  Alert, Button, IconCheck, IconInfo, Select, CopyPasteButton,
} from 'gatorcomponents';
import { ORDER_EPP_KEY_MODAL_STEP } from './ModalEppKey.enum';
import { useDispatch, useSelector } from 'react-redux';
import { domainsActions, preChatActions } from '@/redux/modules';
import { Loader } from '@/pages/common/v1/Loader';
import { useHistory } from 'react-router';
import { domainsAnalytics } from '@/analytics';

const ModalEppKey = () => {
  const [modalStatus, setModalStatus] = useState(ORDER_EPP_KEY_MODAL_STEP.LOADING_KEY);
  const { eppKey } = useSelector(state => state.newDomains);
  const { triage } = useSelector(state => state.preChat);
  const { domains: domainsLocale, routes } = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const { eppKey: eppKeyAnalytics } = domainsAnalytics;

  const activeDomainsOptions = eppKey.activeDomainsList.map(domain => ({
    value: domain.id,
    label: domain.domain,
  }));

  useEffect(() => {
    dispatch(domainsActions.activeDomainList.get(setModalStatus));
  }, [dispatch]);

  const handleOnClose = (clickedButton = '') => {
    dispatch(domainsActions.modalEppKey.close());
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.SELECT_DOMAIN);

    if (clickedButton === 'cancel') {
      eppKeyAnalytics.eppKeySecondtModalCancelClick();
    }
    if (clickedButton === 'understood') {
      eppKeyAnalytics.eppKeyThirdModalOk();
    }
    if (clickedButton === 'backToDomains') {
      eppKeyAnalytics.eppKeyFourthModalReturn();
    }

    if (triage.onEppKeyFlow) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.eppKeyGen.onFlow(false));
    }
  };

  const handleConfirmDomainClick = () => {
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.CONFIRM_ORDER);
    eppKeyAnalytics.eppKeyFirstModalButtonClick();
  };

  const handleGenerateEppKeyClick = () => {
    dispatch(domainsActions.modalEppKey.get(eppKey.domainId, setModalStatus));
    eppKeyAnalytics.eppKeySecondtModalButtonClick();
  };

  const handleOpenTicketClick = () => {
    dispatch(domainsActions.modalEppKey.redirect(true));
    eppKeyAnalytics.eppKeyFourthModalOpenTicket();
    history.push(`${routes.help}${routes.supportforms}`);
  };

  const handleDomainSelectionChange = (option) => {
    dispatch(domainsActions.modalEppKey.change(option.label, option.value));
  };

  return (
    <Modal onClose={handleOnClose} maxWidth={624} testId="modal-epp-key">
      <Styles.Content>
        {modalStatus === ORDER_EPP_KEY_MODAL_STEP.LOADING_KEY && (
          <Styles.LoaderContainer>
            <Loader testId="loading-modal-epp-key" noMinHeight />
          </Styles.LoaderContainer>
        )}

        {modalStatus === ORDER_EPP_KEY_MODAL_STEP.SELECT_DOMAIN && (
          <>
            <Styles.Title data-testid="select-domain-modal-epp-key">
              {domainsLocale.modalEppKey.defaultTitle}
            </Styles.Title>
            <Styles.RegularLabel>
              {domainsLocale.modalEppKey.orderEppKeyAdvice}
            </Styles.RegularLabel>
            <Styles.SelectWrapper>
              <Select
                label={domainsLocale.modalEppKey.selectDomainForEppLabel}
                placeholder={domainsLocale.modalEppKey.selectDomainForEppPlaceholder}
                options={activeDomainsOptions}
                value={eppKey.domainName}
                onClickSelect={handleDomainSelectionChange}
              />
            </Styles.SelectWrapper>
            <Styles.ButtonsWrapper>
              <Button
                label={domainsLocale.modalEppKey.generateEppKey}
                size="large"
                onClick={handleConfirmDomainClick}
                testId="confirm-domain-selected-button"
              />
            </Styles.ButtonsWrapper>
          </>
        )}

        {modalStatus === ORDER_EPP_KEY_MODAL_STEP.CONFIRM_ORDER && (
        <>
          <Styles.Title data-testid="confirm-generation-modal-epp-key">
            {domainsLocale.modalEppKey.defaultTitle}
          </Styles.Title>
          <Styles.RegularLabel>
            {domainsLocale.modalEppKey.confirmOrderAdvice}
          </Styles.RegularLabel>
          <Styles.AlertWrapper>
            <Alert
              variant="error"
              description={domainsLocale.modalEppKey.effectsOnGenerateKey}
            />
          </Styles.AlertWrapper>
          <Styles.ButtonsWrapper>
            <Button
              label={domainsLocale.modalEppKey.cancel}
              size="large"
              variant="tertiary"
              onClick={() => handleOnClose('cancel')}
              testId="cancel-epp-key-generation-button"
            />
            <Button
              label={domainsLocale.modalEppKey.generateEppKey}
              size="large"
              onClick={handleGenerateEppKeyClick}
              testId="generate-epp-key-button"
            />
          </Styles.ButtonsWrapper>
        </>
        )}

        {modalStatus === ORDER_EPP_KEY_MODAL_STEP.KEY_SUCCESS && (
          <>
            <Styles.Title data-testid="success-modal-epp-key">
              {domainsLocale.modalEppKey.successTitle}
            </Styles.Title>
            <Styles.ResultContainer>
              <Styles.IconWrapper>
                <IconCheck />
              </Styles.IconWrapper>
              <Styles.GeneratedKeyContainer>
                {domainsLocale.modalEppKey.keyGenerated(eppKey.domainName, eppKey.keyGenerated)}
              </Styles.GeneratedKeyContainer>
              <Styles.CopyButtonContainer onClick={eppKeyAnalytics.eppKeyThirdModalCopyCode}>
                <CopyPasteButton
                  label={domainsLocale.modalEppKey.copyCodeLabel}
                  direction="column"
                  size="large"
                  copiedTooltipText={domainsLocale.modalEppKey.copiedKeyTooltip}
                  notCopiedTooltipText={domainsLocale.modalEppKey.copyKeyTooltip}
                  textToCopy={eppKey.keyGenerated}
                  testId="copy-paste-epp-key-button"
                />
              </Styles.CopyButtonContainer>
            </Styles.ResultContainer>
            <Styles.AlertWrapper>
              <Alert
                description={domainsLocale.modalEppKey.successInfo}
              />
            </Styles.AlertWrapper>
            <Styles.ButtonsWrapper>
              <Button
                label={domainsLocale.modalEppKey.understood}
                size="large"
                onClick={() => handleOnClose('understood')}
                testId="understood-button"
              />
            </Styles.ButtonsWrapper>
          </>
        )}

        {modalStatus === ORDER_EPP_KEY_MODAL_STEP.KEY_FAILURE && (
          <>
            <Styles.Title data-testid="failure-modal-epp-key">
              {domainsLocale.modalEppKey.failureTitle}
            </Styles.Title>
            <Styles.ResultContainer>
              <Styles.IconWrapper isError>
                <IconInfo />
              </Styles.IconWrapper>
              <Styles.ErrorLabelContainer>
                {domainsLocale.modalEppKey.failureInfo(eppKey.domainName)}
              </Styles.ErrorLabelContainer>
            </Styles.ResultContainer>
            <Styles.ButtonsWrapper column>
              <Button
                label={domainsLocale.modalEppKey.openTicket}
                size="large"
                onClick={handleOpenTicketClick}
                testId="open-ticket-fail-generation-button"
              />
              <Button
                label={domainsLocale.modalEppKey.backToDomains}
                variant="tertiary"
                size="large"
                onClick={() => handleOnClose('backToDomains')}
                testId="back-to-domains-fail-generation-button"
              />
            </Styles.ButtonsWrapper>
          </>
        )}

      </Styles.Content>
    </Modal>
  );
};

export default ModalEppKey;
