import React from 'react';
import * as Styles from './DomainNotRegistered.styles';
import {
  Button,
  IconPreferences,
} from 'gatorcomponents';
import PropTypes from 'prop-types';

export const DomainNotRegistered = ({
  title = 'title',
  description = 'description',
  redirectLabel = 'redirectLabel',
  goTo,
}) => {
  const handleRedirect = () => {
    goTo && goTo();
  };

  return (
    <Styles.Wrapper data-testid="DomainNotRegistered">
      <Styles.Title data-testid="title">
        <IconPreferences size="xl" />
        {title}
      </Styles.Title>

      <Styles.Subtitle data-testid="description">
        {description}
      </Styles.Subtitle>


      <Styles.ButtonsWrapper>
        <Button
          testId="redirect"
          size="large"
          onClick={handleRedirect}
          label={redirectLabel}
        />
      </Styles.ButtonsWrapper>
    </Styles.Wrapper>
  );
};

DomainNotRegistered.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  redirectLabel: PropTypes.string,
  goTo: PropTypes.func,
};

export default DomainNotRegistered;
