import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    padding: 16px;
  `}
`;

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 224px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;
  `}
`;

export const RegularLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 20px;
    margin-top: 24px;

    a {
      font-size: ${theme.v2.font.sizes.xxs};
    }

    p {
      margin: 0;
    }
  `}
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    padding: 0 30px;
    word-break: break-all;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      padding: 0%;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ column }) => css`
    align-items: center;
    display: flex;
    gap: 8px;
    flex-direction: ${column && 'column'};
    justify-content: center;
    margin-top: 32px;
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;

    & > div {
      display: flex;
      align-items: center;
    }

    a {
      font-size: ${theme.v2.font.sizes.xxs};
    }

    p {
      margin: 0;
    }

    p > p:last-of-type {
      margin-top: 20px;
    }

    ul {
      margin: 0;
      padding-left: 22px;
    }

    strong {
      font-weight: 700;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme, isError }) => css`
    background-color: ${isError ? theme.v2.colors.feedback.problemMedium : theme.v2.colors.feedback.activePure};
    border-radius: 50%;
    display: flex;
    padding: 4px;

    svg {
      fill: ${theme.v2.colors.white.primary};
    }
  `}
`;

export const GeneratedKeyContainer = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    font-family: ${theme.v2.font.family.primary};

    p {
      margin: 0;
    }

    span {
      display: block;
      font-size: ${theme.v2.font.sizes.sm};
      font-weight: ${theme.v2.font.weights.bold};
      word-break: break-all;
    }
  `}
`;

export const CopyButtonContainer = styled.div`
  flex-shrink: 0;
`;

export const ResultContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 42px;

    p {
      margin: 0 0 8px;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      flex-direction:column;
    }
  `}
`;

export const ErrorLabelContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 20px;

    p {
      margin: 0;
    }
  `}
`;
