import styled, { css } from 'styled-components';

const defaultWrapperWidth = '498px';

export const PageContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 40px 100px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const CreateTicketWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px;
  `}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const SelectProductWrapper = styled.div`
  max-width: ${defaultWrapperWidth};
  width: 100%;
`;

export const RadioSection = styled.div`
  max-width: ${defaultWrapperWidth};
  width: 100%;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  max-width: ${defaultWrapperWidth};
  width: 100%;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px;
  `}
`;

export const RadioLabel = styled.label`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    cursor: pointer;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 4px;

    label {
      width: 19px;
      height: 19px;
    }
  `}
`;

export const SelectNeedWrapper = styled(SelectProductWrapper)`
    margin: 24px 0px 0px;
`;

export const FormsWrapper = styled(SelectProductWrapper)`
    margin: 24px 0px 0px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    lin-height: ${theme.v2.font.lineHeight.text};
    margin: 0 0px 24px;
    max-width: 1006px;
  `}
`;
export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
