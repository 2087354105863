const styles = theme => ({

  trialDivMobile: {
    [theme.breakpoints.down(700)]: {
      marginTop: '0px',
    },
  },

  trialTerminationDate: {
    color: theme.color.regentGray,
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
  },

  trialDivTerminated: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.regentGray}`,
    borderRadius: '2px',
    color: theme.color.regentGray,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '26px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '4px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      display: 'table',
      marginLeft: '4px',
      marginTop: '12px',
    },
  },

  trialDivOverDueDate: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.valencia}`,
    borderRadius: '2px',
    color: theme.color.valencia,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
    fontWeight: theme.font.weights.regular,
    height: '26px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '4px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      display: 'table',
      marginLeft: '4px',
      marginTop: '12px',
    },
  },

  trialDivToDueDate: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.brightSun}`,
    borderRadius: '2px',
    color: theme.color.black,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '26px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '4px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      marginLeft: '12px',
      marginTop: '2px',
    },
  },

  trialDiv: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.oceanGreen}`,
    borderRadius: '2px',
    color: theme.color.oceanGreen,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '26px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '4px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      marginLeft: '12px',
      marginTop: '2px',
    },
  },

  automaticReactivationButton: {
    color: theme.palette.primary.dark,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    fontWeight: theme.font.weights.regular,
    letterSpacing: '0.01px',
    margin: 0,
    opacity: 1,
    textAlign: 'left',
    textDecoration: 'underline',
    textTransform: 'none',
    transition: 'color 0.2s',
    '&:hover': {
      color: theme.color.indigo,
      backgroundColor: theme.color.white,
      textDecoration: 'underline',
    },
    [theme.breakpoints.down(700)]: {
      paddingLeft: '4px',
      marginBottom: '18px',
    },
  },

  proEmailExpiringDate: {
    minWidth: '183px',

    [theme.breakpoints.down(1285)]: {
      minWidth: '195px',
    },

    [theme.breakpoints.down(443)]: {
      maxWidth: '100%',
      minWidth: 'unset',
      paddingRight: 0,
    },
  },

  divWarningIcon: {
    display: 'initial',
    marginTop: '4px',
    verticalAlign: 'sub',
  },

  warningIcon: {
    fill: theme.color.valencia,
    height: '14px',
    marginRight: '4px',
    opacity: 1,
    width: '14px',
  },

  warningIconTrialDueDate: {
    fill: theme.color.black,
    height: '14px',
    marginRight: '4px',
    opacity: 1,
    width: '14px',
  },

  billButton: {
    color: theme.palette.primary.dark,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    fontWeight: theme.font.weights.regular,
    letterSpacing: '0.01px',
    margin: 0,
    opacity: 1,
    textAlign: 'left',
    textDecoration: 'underline',
    textTransform: 'none',
    transition: 'color 0.2s',
    '&:hover': {
      color: theme.color.indigo,
      backgroundColor: theme.color.white,
      textDecoration: 'underline',
    },
    [theme.breakpoints.down(443)]: {
      paddingLeft: 'px',
      marginBottom: '18px',
    },
  },

  suspendedDate: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.valencia}`,
    borderRadius: '2px',
    color: theme.color.valencia,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '24px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '2px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      marginLeft: '12px',
      marginTop: '12px',
    },
  },

  customPopper: {
    opacity: 1,
  },

  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: '3px',
    fontSize: theme.font.sizes.xxs,
    lineHeight: '14px',
    opacity: '0.9',
    padding: '8px 8px 8px 8px',
    textAlign: 'left',
  },

  paperMenu: {
    marginTop: '52px',
  },

  divEmptyIcon: {
    height: '40px',
    width: '80px',
  },

  divPlan: {
    float: 'left',
    [theme.breakpoints.down(700)]: {
      marginRight: '12px',
    },
  },


  terminationDate: {
    color: theme.color.tundora,
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
  },

  dueDate: {
    color: theme.color.tundora,
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(443)]: {
    },
  },

  textMenu: {
    color: theme.color.indigo,
    fontFamily: 'Roboto',
    fontSize: theme.font.sizes.sm,
    fontWeight: theme.font.weights.medium,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginLeft: '16px',
    marginRight: '16px',
    maxWidth: '100%',
    padding: '2px',
    textAlign: 'left',
    textDecoration: 'none',
    textTransform: 'none',
  },

  menuIcon: {
    color: theme.palette.grey.light,
    height: '52px',
    padding: '2px',
  },

  divSignatureMain: {
  },

  divSignature: {
    width: '100%',
  },

  divLogoTitle: {
    display: 'inline-block',
  },

  divMain: {
    marginLeft: '19px',
  },

  imgLogo: {
    display: 'inline-block',
  },

  divContentLogo: {
    textAlign: 'right',
    width: '70px',
  },

  divLogos: {
    marginRight: '16px',
    textAlign: 'end',
    [theme.breakpoints.down(700)]: {
      marginRight: '2px',
    },
  },

  logo: {
    display: 'inline-block',
    height: '24px',
    marginLeft: '16px',
    marginTop: '11px',
    width: '24px',
  },

  logoTitle: {
    color: theme.palette.grey.light,
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '0.01px',
    marginLeft: '4px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      display: 'table-caption',
    },
  },

  divFirstLine: {
    width: 'max-content',
    [theme.breakpoints.down(700)]: {
      width: '400px',
    },
  },

  divBillButton: {
    borderRadius: '2px',
    color: theme.color.tundora,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    height: '24px',
    letterSpacing: '0.01px',
    marginLeft: '12px',
    opacity: 1,
    [theme.breakpoints.down(700)]: {
      marginLeft: '0px',
      marginTop: '8px',
    },
  },

  divBillButtonTrial: {
    [theme.breakpoints.down(700)]: {
      marginLeft: '4px',
      marginTop: '24px',
    },
  },

  divDueDate: {
    backgroundColor: theme.color.mystic,
    borderRadius: '2px',
    color: theme.color.tundora,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    height: '24px',
    letterSpacing: '0.01px',
    marginLeft: '12px',
    opacity: 1,
    padding: '4px 8px 4px 8px',
  },

  almostCanceladDiv: {
    background: theme.color.white,
    border: `1px solid  ${theme.color.valencia}`,
    borderRadius: '2px',
    color: theme.color.valencia,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '24px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: '0.9',
    padding: '3px 8px 4px 8px',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      marginLeft: '12px',
      marginTop: '12px',
    },
  },


  proEmailStatus: {
    borderRadius: '2px',
    color: theme.color.white,
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    fontWeight: theme.font.weights.regular,
    height: '24px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginLeft: '12px',
    opacity: 1,
    padding: '4px 8px 4px 8px',
    [theme.breakpoints.down(700)]: {
      marginLeft: '4px',
      marginTop: '20px',
    },
  },

  simpleTitle: {
    color: theme.palette.grey.light,
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.font.sizes.xxs,
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '4px',
    },
  },

  activeProEmailStatusNew: { backgroundColor: theme.color.oceanGreen },
  pendingProEmailStatusNew: { backgroundColor: theme.color.coral },
  provisioningProEmailStatusNew: { backgroundColor: theme.color.coral },
  provisioningProEmailStatus: { backgroundColor: theme.color.brightSun },
  uponDueDateProEmailStatus: { backgroundColor: theme.color.brightSun },
  suspendedProEmailStatusNew: { backgroundColor: theme.color.valencia },
  terminatedProEmailStatusNew: { backgroundColor: theme.color.geyser },
  expiredProEmailStatusNew: { backgroundColor: theme.color.geyser },
  nullProEmailStatus: { backgroundColor: theme.color.coral },
  almostCanceladCardStatus: { backgroundColor: theme.color.valencia },

  proEmaiPlan: {
    color: theme.color.tundora,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: theme.font.weights.medium,
    letterSpacing: '0.01px',
    lineHeight: '19px',
    opacity: 1,
    position: 'relative',
    textAlign: 'left',
    top: '4px',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '4px',
      width: '100%',
    },

  },


});

export default styles;
