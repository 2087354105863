import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 16px;
    border: 1px solid ${theme.v1.color.line.wizard};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.v1.color.background.boxes};
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    @media (min-width: ${theme.v1.breakpoints.md}) {
      align-items: center;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
    margin: 8px 0 0 0;
    display: flex;
    align-items: initial;
    justify-content: center;
    @media (min-width: ${theme.v1.breakpoints.md}) {
      margin-bottom: 8px;
      align-items: center;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.bold};
    line-height: ${theme.font.lineHeights.xxxxl};
    text-align: left;
    margin: 0;
    font-size: ${theme.font.sizes.xxl};

    & .full {
      display: none;
    }
    span{
      color: ${theme.v1.color.text.featured};
      text-decoration: none;
    }
    @media (min-width: ${theme.v1.breakpoints.lg}) {
      text-align: center;

      & .full {
        display: inline;
      }
      & .ellipsis {
        display: none;
      }
    }
  `}
`;

export const Message = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    line-height: ${theme.font.lineHeights.xxxxl};
    margin:0;
    text-align: left;
    @media (min-width: ${theme.v1.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const Icon = styled.span`
  margin-right: 8px;
  max-height: 45px;
  max-width: 45px;
`;
