/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles, Button, CircularProgress } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import ModalRebootHistory from '@/components/Modal/ModalRebootHistory';
import ModalReboot from '@/components/Modal/ModalReboot';
import CardWarning from '@/components/Cards/CardWarning';
import LinkButton from '@/components/Buttons/LinkButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { loadRebootHistory, reboot } from '@/redux/actions/productDetail';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierSuccess, notifierError } from '@/utils/Application/notifier';
import styles from './styles';


const TIMER_TO_END_RESTART = 900000; // 15 minutos

const RebootCard = ({ classes, t, disabledActions }) => {
  const dispatch = useDispatch();

  const product = useSelector(state => state.productDetail.product);
  const history = useSelector(state => state.productDetail.history);
  const historyBkp = useSelector(state => state.productDetail.historyBkp);
  const productHealthcheck = useSelector(state => state.productDetail.healthcheck);
  const canReboot = useSelector(state => state.productDetail.canReboot);
  const loading = useSelector(state => state.productDetail.loadingHealthcheck || state.productDetail.loadingHistory);

  const [loadingReebot, setLoadingReboot] = useState(false);
  const [modalRebootHistory, setModalRebootHistory] = useState(false);
  const [modalReboot, setModalReboot] = useState(false);

  let timer;

  useEffect(() => () => clearTimeout(timer), []);

  useEffect(() => {
    if (product) {
      dispatch(loadRebootHistory(product.id));
    }
  }, [product]);

  useEffect(() => {
    if (productHealthcheck && historyBkp.length > 0) {
      const leftTimerToRestart = TIMER_TO_END_RESTART - differenceInMilliseconds(new Date(), parseISO(historyBkp[0].datetime));

      if (leftTimerToRestart > 0) {
        setLoadingReboot(true);
        timer = setTimeout(() => {
          setLoadingReboot(false);
          dispatch(enqueueSnackbar('reboot.feedback.success', notifierSuccess));
        }, leftTimerToRestart);
      } else {
        setLoadingReboot(false);
      }
      if (productHealthcheck.status !== 'active') {
        setLoadingReboot(true);
      }
    }
  }, [productHealthcheck, historyBkp]);

  const handleReboot = () => {
    setModalReboot(false);
    dispatch(reboot(product.id)).then((response) => {
      if (response.type === 'ERROR_PRODUCT_REBOOT') {
        dispatch(enqueueSnackbar('reboot.feedback.error', notifierError));
      } else {
        setLoadingReboot(true);
        timer = setTimeout(() => {
          setLoadingReboot(false);
          dispatch(enqueueSnackbar('reboot.feedback.success', notifierSuccess));
        }, TIMER_TO_END_RESTART);
      }
    });
  };

  return (
    <>
      <div className={classes.card}>
        <div className={classes.header}>
          <h3 className={classes.title}>{t('reboot.title')}</h3>
        </div>
        <div
          className={classnames(classes.content, {
            [classes.contentLoading]: loading,
          })}
        >
          {loading && (<CircularProgress size={35} />)}
          {canReboot && !loading && (
            <CardWarning
              className={classes.warning}
              status="Info"
              icon={Info}
              iconSize="small"
              message={(
                <Trans i18nKey="reboot.message">
                  <strong>0</strong>
                  1
                </Trans>
              )}
            />
          )}
          {!canReboot && !loading && (
            <CardWarning
              className={classes.warning}
              status="Pending"
              iconSize="small"
              message={(
                <Trans i18nKey="reboot.messageWarning">
                  0
                  <LinkButton to={t('routes.help')} className={classes.warningLink}>1</LinkButton>
                  2
                </Trans>
              )}
            />
          )}
        </div>
        {!loading && (
          <div className={classes.actions}>
            {history.length > 0 && (
              <OutlineButton className={classes.btnHistory} disabled={disabledActions} onClick={() => setModalRebootHistory(true)}>
                {t('reboot.history')}
              </OutlineButton>
            )}
            {!loadingReebot && (<PrimaryButton onClick={() => setModalReboot(true)} disabled={disabledActions}>{t('reboot.restart')}</PrimaryButton>)}
            {loadingReebot && (
              <Button className={classes.btnLoading}>
                <CircularProgress size={18} className={classes.loader} />
                <span>Reiniciando...</span>
              </Button>
            )}
          </div>
        )}
      </div>
      {product && (
        <>
          <ModalRebootHistory
            open={modalRebootHistory}
            logs={history}
            onClose={() => setModalRebootHistory(false)}
            product={product.productname}
            domain={product.domain}
          />
          <ModalReboot
            open={modalReboot}
            onClose={() => setModalReboot(false)}
            product={product.productname}
            domain={product.domain}
            onReboot={handleReboot}
          />
        </>
      )}
    </>
  );
};

export default withI18n()(withStyles(styles)(RebootCard));
