import {
  REQUEST_PRODUCT_DETAIL, RECEIVE_PRODUCT_DETAIL, REMOVE_PRODUCT_DETAIL,
  RECEIVE_PRODUCT_REBOOT_HISTORY, REQUEST_PRODUCT_REBOOT_HISTORY, ERROR_PRODUCT_REBOOT_HISTORY,
  RECEIVE_PRODUCT_HEALTHCHECK, SET_REBOOT_AVAILABILITY, REQUEST_PRODUCT_HEALTHCHECK,
  ERROR_PRODUCT_HEALTHCHECK, TOGGLE_ACCESS_PASSWORD_MODAL, RECEIVE_PRODUCT_SAVE_PASSWORD,
  REQUEST_WHM_PANEL_ACCESS, RECEIVE_WHM_PANEL_ACCESS, ERROR_WHM_PANEL_ACCESS,
  ERROR_HEALTHCHECK_WHM_PANEL_ACCESS, ERROR_PRODUCT_DETAIL,
  ERROR_PRODUCT_ADDONS, RECEIVE_PRODUCT_DETAIL_ADDONS, REQUEST_PRODUCT_ADDONS,
  REQUEST_ADDON_ID_FROM_SITE_BUILDER, RECEIVE_ADDON_ID_FROM_SITE_BUILDER,
  ERROR_ADDON_ID_FROM_SITE_BUILDER, RECEIVE_SITEBUILDER_PRODUCT_DETAIL,
  UPDATE_ACTIONS_IN_PRODUCT_DETAILS, REQUEST_SITEBUILDER_PRODUCT_DETAIL,
  ERROR_SITEBUILDER_PRODUCT_DETAIL,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  loadingHistory: false,
  loadingHealthcheck: false,
  loadingAddons: false,
  loadingAddonIdFromSiteBuilder: false,
  loadingSitebuilderProductDetail: false,
  product: null,
  history: [],
  historyBkp: [],
  healthcheck: {
    heiSuccess: null,
    code: null,
    id: null,
    status: null,
    ips: [],
    passChanged: null,
  },
  canReboot: true,
  showAccessPasswordModal: false,
  WHMAccess: {
    loadingSSO: false,
    loginUrlSSO: '',
  },
  addons: [],
};

const productDetail = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRODUCT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
        product: action.productDetail,
      };
    case RECEIVE_SITEBUILDER_PRODUCT_DETAIL:
      return {
        ...state,
        product: action.productDetail,
        loadingSitebuilderProductDetail: false,
      };
    case RECEIVE_PRODUCT_DETAIL_ADDONS:
      return {
        ...state,
        loading: false,
        product: action.productDetail,
        addons: action.addons,
      };
    case REMOVE_PRODUCT_DETAIL:
      return initialState;
    case REQUEST_PRODUCT_REBOOT_HISTORY:
      return {
        ...state,
        loadingHistory: true,
        history: [],
      };
    case RECEIVE_PRODUCT_REBOOT_HISTORY:
      return {
        ...state,
        history: action.history,
        historyBkp: action.historyBkp,
        loadingHistory: false,
      };
    case ERROR_PRODUCT_REBOOT_HISTORY:
      return {
        ...state,
        loadingHistory: false,
      };
    case REQUEST_PRODUCT_HEALTHCHECK:
      return {
        ...state,
        loadingHealthcheck: true,
      };
    case RECEIVE_PRODUCT_HEALTHCHECK:
      return {
        ...state,
        healthcheck: action.healthcheck,
        loadingHealthcheck: false,
      };
    case ERROR_PRODUCT_HEALTHCHECK:
      return {
        ...state,
        loadingHealthcheck: false,
      };
    case SET_REBOOT_AVAILABILITY:
      return {
        ...state,
        canReboot: action.canReboot,
      };
    case TOGGLE_ACCESS_PASSWORD_MODAL:
      return {
        ...state,
        showAccessPasswordModal: !state.showAccessPasswordModal,
      };
    case RECEIVE_PRODUCT_SAVE_PASSWORD: {
      return {
        ...state,
        healthcheck: {
          ...state.healthcheck,
          passChanged: true,
        },
      };
    }
    case REQUEST_WHM_PANEL_ACCESS:
      return {
        ...state,
        WHMAccess: {
          ...state.WHMAccess,
          loadingSSO: true,
        },
      };
    case RECEIVE_WHM_PANEL_ACCESS:
      return {
        ...state,
        WHMAccess: {
          ...state.WHMAccess,
          loadingSSO: false,
        },
      };
    case ERROR_WHM_PANEL_ACCESS:
    case ERROR_HEALTHCHECK_WHM_PANEL_ACCESS:
      return {
        ...state,
        WHMAccess: {
          ...state.WHMAccess,
          loadingSSO: false,
        },
      };
    case ERROR_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
      };
    case ERROR_PRODUCT_ADDONS: {
      return {
        ...state,
        loading: false,
        product: action.productDetail,
      };
    }
    case REQUEST_PRODUCT_ADDONS: {
      return {
        ...state,
        loadingAddons: true,
      };
    }
    case REQUEST_ADDON_ID_FROM_SITE_BUILDER:
      return {
        ...state,
        loadingAddonIdFromSiteBuilder: true,
      };
    case RECEIVE_ADDON_ID_FROM_SITE_BUILDER:
    case ERROR_ADDON_ID_FROM_SITE_BUILDER:
      return {
        ...state,
        loadingAddonIdFromSiteBuilder: false,
      };
    case UPDATE_ACTIONS_IN_PRODUCT_DETAILS:
      return {
        ...state,
        product: {
          ...state.product,
          actions: action.data,
        },
      };
    case REQUEST_SITEBUILDER_PRODUCT_DETAIL:
      return {
        ...state,
        loadingSitebuilderProductDetail: true,
      };
    case ERROR_SITEBUILDER_PRODUCT_DETAIL:
      return {
        ...state,
        loadingSitebuilderProductDetail: false,
      };
    default:
      return state;
  }
};

export default productDetail;
