import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:240px;
    border: 1px solid ${theme.v1.color.line.wizard};
    border-radius: 4px;
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color:${theme.v1.color.text.primary};
    font-size:${theme.font.sizes.md};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.medium};
    line-height: ${theme.font.lineHeights.xl};
    margin: 0;
    padding: 8px 0;
  `}
`;

export const ThemeImage = styled.img`
  ${() => css`
    width: 224px;
    height:151px;
    padding-bottom: 16px;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display:flex;
    justify-content:center;
    padding-bottom:16px;
    button:first-child {
      padding-top:6px;
      padding-right: 16px;
      font-family: ${theme.v1.font.family.primary};
      color: ${theme.v1.color.action.primary};
      &:hover{
        color: ${theme.v1.color.action.hover};
      }
    }
  `}
`;
