const styles = theme => ({
  card: {
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        outline: `2px solid ${theme.palette.primary.main}`,
        borderColor: 'transparent',
      },
    },
    cursor: 'pointer',
    width: '212px',
    height: '220px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '2px',
    position: 'relative',
    margin: '0 7px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '291px',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      height: 'auto',
      minHeight: '95px',
      margin: '5px 0',
    },
  },
  tag: {
    position: 'absolute',
    top: -1,
    right: -2,
  },
  tagSeleted: {
    top: -2,
    right: -3,
  },
  title: {
    textTransform: 'uppercase',
    color: theme.color.tundora,
    margin: '40px 0 8px 0',
    fontWeight: 500,
    fontSize: '14px',
    padding: '0',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: '3px 0 8px 0px',
    },
  },
  subtitle: {
    color: theme.color.regentGray,
    fontSize: '12px',
    padding: '0',
    display: 'inline-block',
    userSelect: 'none',
  },
  imageSelected: {
    padding: 29,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      paddingRight: 26,
    },
  },
  imageNoSelected: {
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 26,
    },
  },
  image: {
    objectFit: 'contain',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 14px 0 20px',
      height: '44px',
    },
  },
  wrapperDescription: {
    textAlign: 'center',
    padding: '0 10%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingRight: 15,
      paddingLeft: 6,
    },
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
  notSelected: {
    border: `1px solid ${theme.color.geyser}`,
  },
  subtitleTag: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 23,
    },
  },
});

export default styles;
