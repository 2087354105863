import React, { useEffect, useState, useContext } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import ActionButton from '@/components/Buttons/ActionButton';
import CourseCard from '../AcademyPassCoursesList/CourseCard';
import styles from './styles';
import { AcademyPassContext } from '@/contexts/academyPass/context';


const AcademyPassWatchAgain = ({ billStatus, classes, t }) => {
  const { coursesList } = useSelector(state => state.academyPass.courses);
  const { jwt, userId } = useSelector(state => state.academyPass.tokens);
  const { isPassActive } = useSelector(state => state.academyPass.courses);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [visible, setVisible] = useState(4);
  const { renderedCourses } = useContext(AcademyPassContext);
  const [shouldRenderCourseList, setShouldRenderCourseList] = useState(true);


  const isFinished = course => (
    (course.statistics !== undefined)
    && (course.statistics.summary !== undefined)
    && course.statistics.summary.progress === 100
  );

  const isPremium = course => course.isFree || course.amount === 0 || course.amount === undefined;

  const isVisible = (course) => {
    if (course && course.visibility !== undefined) {
      return course.visibility === 'public';
    }
  };

  useEffect(() => {
    if (renderedCourses && !shouldRenderCourseList) {
      setFilteredCourses(renderedCourses.filter(course => isVisible(course) && isPremium(course) && isFinished(course)));
    }
    if (coursesList && coursesList.length > 0 && shouldRenderCourseList) {
      setFilteredCourses(coursesList.filter(course => isVisible(course) && isPremium(course) && isFinished(course)));
      if (renderedCourses.length > 1) {
        setShouldRenderCourseList(false);
      }
    }
  }, [coursesList, renderedCourses, shouldRenderCourseList]);

  const loadMore = () => {
    setVisible(filteredCourses.length);
  };

  const sortingCoursesByDate = filteredCourses.sort((a, b) => new Date(a.productIssueDate).getTime() - new Date(b.productIssueDate).getTime()).reverse();

  return (
    filteredCourses.length > 0
    && (
    <div>
      <Typography className={classes.title}>
        {t('academypass.list.titleWatchAgain')}
      </Typography>

      <div className={classnames([classes.wrapper, classes.cardGrid])} data-testid="courses-grid">
        {(coursesList || renderedCourses) && sortingCoursesByDate.slice(0, visible).map(course => (
          <CourseCard course={course} jwt={jwt} userId={userId} isPassActive={isPassActive} isPaidAndActive={billStatus !== null} cardType="watch-again-card" key={course.idProduct} />
        ))}
      </div>
      {visible < sortingCoursesByDate.length && (
      <ActionButton action={loadMore} data-testid="btn-loadmore-watchagain" data-id="loadmore_button">
        {t('btn.loadMore')}
      </ActionButton>
      )}
    </div>
    )
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(AcademyPassWatchAgain));
