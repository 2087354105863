import React, { useCallback, useState } from 'react';
import * as Styles from './ReloadRebuild.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import {
  linuxWithCpanel, linuxWithoutCpanel, windowsWithPleskWithLicense, windowsWithPleskWithoutLicense,
} from './ReloadRebuild.types';

export const ReloadRebuild = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const {
    reloadRebuild: reloadRebuildLocale,
  } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    selectedImage: '',
    canExecuteInComercialHours: '',
    agreedBrasiliaHours: false,
    agreedRebuildReloadTerms: false,
    agreedValue: false,
    agreedResponsability: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.selectedImage
      && !!formData.canExecuteInComercialHours
      && !!formData.agreedBrasiliaHours
      && !!formData.agreedRebuildReloadTerms
      && !!formData.agreedResponsability
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: reloadRebuildLocale.subject,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.RELOAD_REBUILD,
      tags: [
        'whmcs_form_tecnico_reload-rebuild',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
        • ${reloadRebuildLocale.body.product(selectedProduct)}
        • ${reloadRebuildLocale.body.request}
        • ${reloadRebuildLocale.body.image(formData.selectedImage)}
        • ${reloadRebuildLocale.body.canExecuteInComercialHours(formData.canExecuteInComercialHours)}
        • ${reloadRebuildLocale.body.agreedBrasiliaHours(formData.agreedBrasiliaHours)}
        • ${reloadRebuildLocale.body.agreedRebuildReloadTerms(formData.agreedRebuildReloadTerms)}
        • ${reloadRebuildLocale.body.agreedValue(formData.agreedValue)}
        • ${reloadRebuildLocale.body.agreedResponsability(formData.agreedResponsability)}
        • ${reloadRebuildLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    reloadRebuildLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  const imageOptions = [
    {
      label: reloadRebuildLocale.placeholders.linuxWithCpanel,
      value: reloadRebuildLocale.placeholders.linuxWithCpanel,
      title: true,
    },
    ...linuxWithCpanel,
    {
      label: reloadRebuildLocale.placeholders.linuxWithoutCpanel,
      value: reloadRebuildLocale.placeholders.linuxWithoutCpanel,
      title: true,
    },
    ...linuxWithoutCpanel,
    {
      label: reloadRebuildLocale.placeholders.windowsWithPleskWithoutLicense,
      value: reloadRebuildLocale.placeholders.windowsWithPleskWithoutLicense,
      title: true,
    },
    ...windowsWithPleskWithoutLicense,
    {
      label: reloadRebuildLocale.placeholders.windowsWithPleskWithLicense,
      value: reloadRebuildLocale.placeholders.windowsWithPleskWithLicense,
      title: true,
    },
    ...windowsWithPleskWithLicense,
  ];

  const hoursOptions = [
    { value: reloadRebuildLocale.placeholders.yes, label: reloadRebuildLocale.placeholders.yes },
    { value: reloadRebuildLocale.placeholders.no, label: reloadRebuildLocale.placeholders.no },
  ];

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {reloadRebuildLocale.info}
      </Styles.Info>

      <Styles.Title>
        {reloadRebuildLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Select
          name="image"
          testId="image"
          placeholder={reloadRebuildLocale.placeholders.image}
          label={formData.selectedImage ? reloadRebuildLocale.placeholders.image : ''}
          options={imageOptions}
          value={formData.selectedImage}
          setValue={label => handleChange({ key: 'selectedImage', value: label })}
          withScroll
        />

        <Select
          name="hours"
          testId="hours"
          placeholder={reloadRebuildLocale.placeholders.canExecuteInComercialHours}
          label={formData.canExecuteInComercialHours ? reloadRebuildLocale.placeholders.canExecuteInComercialHours : ''}
          options={hoursOptions}
          value={formData.canExecuteInComercialHours}
          setValue={label => handleChange({ key: 'canExecuteInComercialHours', value: label })}
          withScroll
        />

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedBrasiliaHours"
          data-testid="agreedBrasiliaHours"
          onClick={() => handleChange({ key: 'agreedBrasiliaHours', value: !formData.agreedBrasiliaHours })}
        >
          <Checkbox checked={formData.agreedBrasiliaHours} id="readTermsagreedBrasiliaHours" />
          <Styles.Text>
            {reloadRebuildLocale.placeholders.agreedBrasiliaHours}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedRebuildReloadTerms"
          data-testid="agreedRebuildReloadTerms"
          onClick={() => handleChange({ key: 'agreedRebuildReloadTerms', value: !formData.agreedRebuildReloadTerms })}
        >
          <Checkbox checked={formData.agreedRebuildReloadTerms} id="readTermsagreedRebuildReloadTerms" />
          <Styles.Text>
            {reloadRebuildLocale.placeholders.agreedRebuildReloadTerms}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedValue"
          data-testid="agreedValue"
          onClick={() => handleChange({ key: 'agreedValue', value: !formData.agreedValue })}
        >
          <Checkbox checked={formData.agreedValue} id="readTermsagreedValue" />
          <Styles.Text>
            {reloadRebuildLocale.placeholders.agreedValue}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedResponsability"
          data-testid="agreedResponsability"
          onClick={() => handleChange({ key: 'agreedResponsability', value: !formData.agreedResponsability })}
        >
          <Checkbox checked={formData.agreedResponsability} id="readTermsagreedResponsability" />
          <Styles.Text>
            {reloadRebuildLocale.placeholders.agreedResponsability}
          </Styles.Text>
        </Styles.CheckboxLabel>

      </Styles.InputsWrapper>


      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : reloadRebuildLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default ReloadRebuild;
