import React from 'react';
import { Timer } from '@/pages/common/v1';
import * as Styles from './SendAgain.styles';

const SendAgain = ({
  emailVerifyLocale,
  countDownResend,
  setCountDownResend,
}) => (
  <Styles.SendWrapper data-testid="SendAgain">
    <Styles.Title>{emailVerifyLocale.emailNotRecived}</Styles.Title>

    <Styles.DescriptionWrapper>
      <Styles.Description>
        {emailVerifyLocale.resendText}
        <strong>
          <Timer
            startSecound={countDownResend}
            displayHour={false}
            onFinish={() => setCountDownResend(0)}
          />
        </strong>
      </Styles.Description>
    </Styles.DescriptionWrapper>
  </Styles.SendWrapper>
);

export default SendAgain;
