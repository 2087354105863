import React, { useState, useContext, useEffect } from 'react';
import * as Styles from './DnsWizardEmailHandler.styles';
import { IconMail } from '@/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useConfigurationStatus } from '@/hooks/domains/wizard';
import { Loader } from '@/pages/common/v1/Loader';
import { domainsAnalytics } from '@/analytics';
import {
  EMAIL_PLATFORM_NAMES, HOSTING_PLATFORM_TYPES, PLAN_TYPES, REGISTRAR_MAX_PROPAGATION_HOURS,
} from '@/enums/domains/dns.enum';
import { DnsWizardHeader } from '@/components/domain/components/DnsWizardHeader';
import { DnsWizardEmailPlatformsGrid } from '@/components/domain/components/DnsWizardEmailPlatformsGrid';
import { dnsWizardActions } from '@/redux/modules/dnsWizard';
import { DnsWizardEmailConfigCard } from '@/components/domain/components/configurationCards/DnsWizardEmailConfigCard';
import { DnsWizardContext } from '@/contexts/DnsWizard';

// Modals
import {
  AlterEmailPlatform,
  HostGatorCustomEmailModal,
  HostGatorEmailModal,
} from '@/components/domain/components/modals';
import { useHistory } from 'react-router';
import { emailsActions } from '@/redux/modules/emails';

const DnsWizardEmailHandler = ({
  testId = 'dns-wizard-email-handler',
  dnsWizardLocale = {},
  routesLocale = {},
  domain = {},
  emailPlatforms = [],
  domainId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedEmailPlatformId, setSelectedEmailPlatformId, setOpenDnsTable } = useContext(DnsWizardContext);
  const enabledForRenderToProEmail = false;

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;


  const {
    name: domainName,
    registrar,
    configurationStatus,
    proEmail,
    planDetails,
    platformName,
  } = domain;

  const {
    isConfiguredThroughWizard,
    isConfiguredInHostGator,
  } = configurationStatus;

  const {
    emails: emailLocale,
    modals: modalsLocale,
    hostgatorMailPlatform: hostgatorMailPlatformName,
  } = dnsWizardLocale;

  const {
    emailZoneTitle,
    emailZoneTitleWithDefinedPlatform,
    emailZoneTitleTooltip,
    emailZoneSubtitle,
  } = emailLocale;

  const [selected, setSelected] = useState(false);
  const [selectedPlatformName, setSelectedPlatformName] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [customEmailModal, setCustomEmailModal] = useState(false);
  const [selectedPlatformType, setSelectedPlatformType] = useState(null);
  const [alterEmailPlatform, toggleAlterEmailPlatform] = useState(false);
  const { controls, hostingPlatforms, domain: dnsWizardDomain } = useSelector(state => state.dnsWizard);
  const { proEmailList, loadingProEmails } = useSelector(state => state.emails);

  const { emailsConfigurationLoading, emailsPlatformsLoading } = controls;
  const shouldRenderLoading = loadingProEmails || emailsPlatformsLoading;

  const handleCloseEmailModal = () => {
    setEmailModal(false);
  };

  const status = useConfigurationStatus(configurationStatus, platformName);
  const isPointedToCustomServer = status === HOSTING_PLATFORM_TYPES.CUSTOM;
  const shouldRenderEmailConfigurationWizard = isConfiguredThroughWizard && isConfiguredInHostGator && !isPointedToCustomServer && planDetails.type !== PLAN_TYPES.RESALE;
  const isReserved = hostingPlatforms.selectedEmailPlatform === 'Reserved';
  const cardAction = (platformName) => {
    let isProEmailConfigured = false;
    if (enabledForRenderToProEmail) {
      const proEmail = proEmailList.find(email => email.domain === domain.name);
      isProEmailConfigured = proEmail && proEmail.domain_configured;
    } else {
      isProEmailConfigured = domain.proEmail.domain_configured;
    }

    let manageProEmailUrl = '';

    if (isProEmailConfigured) {
      manageProEmailUrl = `${routesLocale.emailsList}${routesLocale.emailsTitan}/${domain.name}`;
    }

    switch (platformName) {
      case EMAIL_PLATFORM_NAMES.TITAN:
        if (isProEmailConfigured) {
          history.push(manageProEmailUrl);
        } else {
          history.push(`${routesLocale.emailsList}?dspActtrial=${domain.name}`);
        }

        break;
      case EMAIL_PLATFORM_NAMES.HOSTGATOR:
        if (isProEmailConfigured) {
          history.push(manageProEmailUrl);
        } else {
          history.push(`${routesLocale.emailsList}${routesLocale.titanHirePlanActiveCPanel}/${domain.name}?idPro=${proEmail.id}&service=proemail`);
        }

        break;
      default:
        setEmailModal(true);
    }
  };


  const handleSelect = (emailPlatformId, platformType, platformName, insideModal) => {
    setSelected(true);
    switch (platformType) {
      case HOSTING_PLATFORM_TYPES.MAIL:
        insideModal ? setEmailModal(true) : cardAction(platformName);
        break;
      case HOSTING_PLATFORM_TYPES.HOSTGATOR:
        insideModal ? setEmailModal(true) : cardAction(platformName);
        break;
      case HOSTING_PLATFORM_TYPES.CUSTOM_MAIL:
        setCustomEmailModal(true);
        break;
      default:
        break;
    }
    setSelectedEmailPlatformId(emailPlatformId);
  };

  const handleConfigureEmail = (platformId) => {
    dispatch(dnsWizardActions.emailPlatformsConfiguration.request(domainId, platformId));
  };

  const emailModalComponent = emailsConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorEmailModal
        handleClose={handleCloseEmailModal}
        emailModal={emailModal}
        modalsLocale={modalsLocale[HOSTING_PLATFORM_TYPES.MAIL]}
        domainName={domainName}
        platform={emailPlatforms.filter(e => e.platformID === selectedEmailPlatformId).pop()}
        propagationHours={REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??'}
        analytics={dnsWizardAnalytics}
        handleConfigureEmail={handleConfigureEmail}
        currentType={planDetails.type}
        firstTimeConfigure={hostingPlatforms.selectedEmailPlatform === 'None'}
        currentStatus={status}
        domainIp={planDetails.ip}
        reserved={isReserved}

      />
    );

  const customEmailModalComponent = emailsConfigurationLoading
    ? (<Loader message={dnsWizardLocale.configurationLoadingMessage} modal />)
    : (
      <HostGatorCustomEmailModal
        handleClose={() => { setCustomEmailModal(false); }}
        emailModal={customEmailModal}
        modalsLocale={modalsLocale[HOSTING_PLATFORM_TYPES.CUSTOM_MAIL]}
        domainName={domainName}
        platform={emailPlatforms.filter(e => e.platformID === selectedEmailPlatformId).pop()}
        propagationHours={REGISTRAR_MAX_PROPAGATION_HOURS[registrar] || '??'}
        analytics={dnsWizardAnalytics}
        handleConfigureEmail={() => {
          setOpenDnsTable(true);
          const zoneRecords = document.getElementById('dns-wizard-zone-records');
          setTimeout(() => {
            window.scrollTo({
              behavior: 'smooth',
              top: zoneRecords.offsetTop,
            });
          }, 100);
        }}
        currentType={planDetails.type}
        firstTimeConfigure={hostingPlatforms.selectedEmailPlatform === 'None'}
        currentStatus={status}
        reserved={isReserved}
      />
    );

  const currentPlatform = () => emailPlatforms.find(item => item.platformName === hostingPlatforms.selectedEmailPlatform);

  const selectedEmailPlataform = !controls.settedEmailPlataformLoading && hostingPlatforms.selectedEmailPlatform && hostingPlatforms.selectedEmailPlatform !== 'None' && hostingPlatforms.selectedEmailPlatform !== 'Reserved';

  const filteredPlatforms = () => {
    const activeEmailPlatform = hostingPlatforms.selectedEmailPlatform;
    const { hostingId, reserved } = hostingPlatforms;
    const list = [...emailPlatforms].filter(platform => platform.platformName !== EMAIL_PLATFORM_NAMES.RESERVED);

    if ((activeEmailPlatform === 'None' || activeEmailPlatform === 'Reserved') && !hostingId && reserved) {
      const filtered = list.filter(platform => platform.platformName !== EMAIL_PLATFORM_NAMES.CPANEL
        && platform.platformName !== EMAIL_PLATFORM_NAMES.HOSTGATOR);
      return filtered;
    }

    if (activeEmailPlatform === 'None' && hostingId && !reserved) {
      const filtered = list.filter(platform => platform.platformName !== EMAIL_PLATFORM_NAMES.CPANEL
        && platform.platformName !== EMAIL_PLATFORM_NAMES.TITAN);
      return filtered;
    }

    const filtered = list.filter(platform => platform.platformName !== EMAIL_PLATFORM_NAMES.HOSTGATOR);
    return filtered;
  };

  useEffect(() => {
    if (enabledForRenderToProEmail) {
      dispatch(emailsActions.emails.request());
    }
  }, [dispatch, enabledForRenderToProEmail]);


  return shouldRenderEmailConfigurationWizard && (
    <Styles.DnsWizardEmailHandlerWrapper data-testid={testId}>
      {emailModalComponent}
      {customEmailModalComponent}
      {alterEmailPlatform && (
        <AlterEmailPlatform
          title={modalsLocale.alterEmailPlatform.title}
          subtitle={modalsLocale.alterEmailPlatform.subtitle}
          continueLabel={modalsLocale.alterEmailPlatform.continueLabel}
          handleClose={() => toggleAlterEmailPlatform(false)}
          emailPlataforms={emailPlatforms}
          emailLocale={emailLocale}
          dnsWizardAnalytics={dnsWizardAnalytics}
          setSelected={setSelected}
          setEmailModal={setEmailModal}
          selected={selected}
          selectedPlatformName={selectedPlatformName}
          setSelectedEmailPlatformId={setSelectedEmailPlatformId}
          setSelectedPlatformType={setSelectedPlatformType}
          selectedPlatformType={selectedPlatformType}
          setCustomEmailModal={setCustomEmailModal}
          setSelectedPlatformName={setSelectedPlatformName}
          activeEmailPlatform={hostingPlatforms.selectedEmailPlatform}
          reserved={hostingPlatforms.reserved}
        />
      )}

      <Styles.TextBlock>
        <DnsWizardHeader
          variation="configured"
          icon={<IconMail />}
          title={selectedEmailPlataform ? emailZoneTitleWithDefinedPlatform : emailZoneTitle}
          tooltip={emailZoneTitleTooltip}
        />

        {!selectedEmailPlataform && (
          <Styles.Subtitle>{emailZoneSubtitle}</Styles.Subtitle>
        )}
      </Styles.TextBlock>
      {shouldRenderLoading ? (
        <Loader />
      )
        : (
          <>
            <Styles.DnsWizardEmailHandlerContainer selectedEmailPlataform={selectedEmailPlataform}>
              {!selectedEmailPlataform && (
                <DnsWizardEmailPlatformsGrid
                  selectedPlatform={selectedPlatformName}
                  setSelectedPlatformType={setSelectedPlatformType}
                  setSelectedPlatformName={setSelectedPlatformName}
                  setSelectedEmailPlatformId={setSelectedEmailPlatformId}
                  setSelected={handleSelect}
                  selected={selected}
                  platforms={filteredPlatforms()}
                  emailLocale={emailLocale}
                  analytics={dnsWizardAnalytics}
                  hostgatorMailPlatformName={hostgatorMailPlatformName}
                  reserved={hostingPlatforms.selectedEmailPlatform === 'Reserved'}

                />
              )}

              {selectedEmailPlataform && (
                <DnsWizardEmailConfigCard
                  currentPlatform={currentPlatform}
                  changePlatformClick={() => toggleAlterEmailPlatform(true)}
                  emailLocale={emailLocale}
                  domain={planDetails.domain}
                  propagating={dnsWizardDomain.configurationStatus.propagating}
                  customEmailPlatformName={emailLocale.customEmailPlatformName}
                  dnsWizardAnalytics={dnsWizardAnalytics}
                />
              )}
            </Styles.DnsWizardEmailHandlerContainer>
          </>
        )}
    </Styles.DnsWizardEmailHandlerWrapper>
  );
};

export default DnsWizardEmailHandler;
