import { collect } from '@/analytics';
import { SSL_GA, SSL_STATUS } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';
import { STATUS_TYPE } from '@/enums/sites/status.enum';
import { MODAL_TYPE } from '@/hooks/sites/sitesList/useMountNotificationModal/useMountNotificationModal.enum';
import { modalCloseAction } from '@/pages/sites/SitesHandler/SitesHandler.enum';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: {
    MODALCANCELDOMAIN: 'Modal cancelamento domínio',
    MODALCANCELPLAN: 'Modal cancelamento plano',
    MODALCREATESITE: 'Modal novo site',
    BANNERNEWSITE: 'Banner novo site',
    ASAASBANNER: 'Sites Banner Asaas',
    TAG_SSL: 'Tag SSL',
    MODALECOMMERCEPLUS: 'Criar Site',
    WELCOMECHECK_LIST_BANNER: 'Sites - WelcomeChecklist Banner',
  },
  ACTION: {
    UNDERSTAND: 'Entendido',
    CLOSE: 'Fechar modal',
    MODAL_ECOMMERCE_PLUS_CLOSE: 'Modal Fechado',
    MODAL_ECOMMERCE_PLUS_SHOW: 'Modal exibido',
    MODAL_ECOMMERCE_PLUS_CONTINUE: 'Clique em criar loja online',
    CREATE: 'Criar site',
    CREATE_SPECIALIST: 'Criar site com especialista',
    KNOWMORE: 'Saber mais',
    VIEW_SSL_IN_PROGRESS: 'Visualização SSL em processo de ativação',
    SSL_CLICK_AUTO: 'SSL em processo de ativação -  clique no Auto SSL',
    VIEW_SSL_FAILED: 'Visualização Pendência SSL',
    SSL_FAILED_CLICK: 'Pendência SSL - Clique Auto SSL',
    VIW_SSL_ACTIVE: 'Visualização SSL  Ativo',
    VIEW_MODAL_SSL_REGISTER_DOMAIN: 'Visualização  - Modal Registrar domínio',
    VIEW_ACTIVATION_ERROR: 'Visualização  - Modal Erro na ativação (Auto SSL liberado)',
    ACTIVATION_ERROR_CLICK: 'Modal Erro na ativação (Clique no Auto SSL)',
    VIEW_SSL_MODAL_TO_OTHER: 'Visualização - Modal domínio não configurado para a HostGator',
    SSL_MODAL_TO_OTHER_CLICK: 'Clique- Modal domínio não configurado para a HostGator',
  },
  LABEL: {
    MODAL_ECOMMERCE_PLUS: 'Modal de Criar Loja na seção /Site',
  },
};

const modalWarningClick = (modalWarningType, action, enableNewCreateSiteModalBehavior) => {
  let categoryGa = '';
  let actionGa = '';
  const isEcommercePlusModal = modalWarningType === MODAL_TYPE.ECOMMERCEPLUS;
  switch (modalWarningType) {
    case MODAL_TYPE.PLANCANCELED:
      categoryGa = EVENTS.CATEGORY.MODALCANCELPLAN;
      break;
    case MODAL_TYPE.DOMAINCANCELED:
      categoryGa = EVENTS.CATEGORY.MODALCANCELDOMAIN;
      break;
    case MODAL_TYPE.NEWSITE:
      categoryGa = EVENTS.CATEGORY.MODALCREATESITE;
      break;
    case MODAL_TYPE.ECOMMERCEPLUS:
      categoryGa = EVENTS.CATEGORY.MODALECOMMERCEPLUS;
      break;
    default:
      break;
  }
  switch (action) {
    case modalCloseAction.understand:
      actionGa = EVENTS.ACTION.UNDERSTAND;
      break;
    case modalCloseAction.close:
      actionGa = !isEcommercePlusModal ? EVENTS.ACTION.CLOSE : EVENTS.ACTION.MODAL_ECOMMERCE_PLUS_CLOSE;
      break;
    case modalCloseAction.createSite:
      actionGa = !isEcommercePlusModal ? EVENTS.ACTION.CREATE : EVENTS.ACTION.MODAL_ECOMMERCE_PLUS_CONTINUE;
      break;
    case modalCloseAction.createWithSpecialist:
      actionGa = enableNewCreateSiteModalBehavior ? EVENTS.ACTION.CREATE_SPECIALIST : EVENTS.ACTION.CREATE;
      break;
    default:
      break;
  }

  if (categoryGa) {
    collect({
      event: EVENTS.NAME,
      gaEventCategory: categoryGa,
      gaEventAction: actionGa,
      gaEventLabel: !isEcommercePlusModal ? '' : EVENTS.LABEL.MODAL_ECOMMERCE_PLUS,
    });
  }
};

const handlePay = (status) => {
  let label = '';
  switch (status) {
    case STATUS_TYPE.TODUE:
      label = 'A vencer';
      break;
    case STATUS_TYPE.OVERDUE:
      label = 'Vencido';
      break;
    case STATUS_TYPE.SUSPENDED:
      label = 'Suspenso';
      break;
    default:
    case STATUS_TYPE.PENDING:
      label = 'Pendente';
      break;
  }

  collect({
    gaEventAction: 'Pagar fatura',
    gaEventLabel: label,
  });
};

const createNewSiteClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.BANNERNEWSITE,
  gaEventAction: EVENTS.ACTION.CREATE,
  gaEventLabel: '',
});

const asaasShowMore = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.ASAASBANNER,
  gaEventAction: EVENTS.ACTION.KNOWMORE,
  gaEventLabel: '',
});

const sslTags = ({ status, clicked }) => {
  let action;
  let label;

  if (clicked) {
    if (clicked.toLocaleLowerCase() === SSL_STATUS.AUTO_SSL.toLocaleLowerCase()) {
      action = EVENTS.ACTION.SSL_CLICK_AUTO;
      label = 'SSL em processo de ativação - Clique no Auto SSL';
    }
    if (clicked.toLocaleLowerCase() === SSL_STATUS.VIEW_PENDING.toLocaleLowerCase()) {
      action = EVENTS.ACTION.SSL_FAILED_CLICK;
      label = 'Pendência SSL Clique Auto SSL';
    }
  }

  if (!clicked) {
    if (status.toLocaleLowerCase() === SSL_STATUS.IN_PROGRESS.toLocaleLowerCase()) {
      action = EVENTS.ACTION.VIEW_SSL_IN_PROGRESS;
      label = 'Visualização SSL em processo de ativação';
    }

    if (status.toLocaleLowerCase() === SSL_STATUS.ACTIVATION_FAILED.toLocaleLowerCase()) {
      action = EVENTS.ACTION.VIEW_SSL_FAILED;
      label = 'Visualização Pendência SSL';
    }

    if (status.toLocaleLowerCase() === SSL_STATUS.ACTIVE.toLocaleLowerCase()) {
      action = EVENTS.ACTION.VIW_SSL_ACTIVE;
      label = 'Visualização SSL Ativo';
    }
  }

  return collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.TAG_SSL,
    gaEventAction: action,
    gaEventLabel: label,
  });
};

const sslModal = ({ view, click }) => {
  let action;
  let label;

  if (click) {
    if (click === SSL_GA.REGISTER_DOMAIN) {
      action = EVENTS.ACTION.VIEW_MODAL_SSL_REGISTER_DOMAIN;
      label = 'Clique - Registrar domínio';
    }
    if (click === SSL_GA.ACTIVATION_ERROR) {
      action = EVENTS.ACTION.ACTIVATION_ERROR_CLICK;
      label = 'Clique - Modal Erro na ativação';
    }
    if (click === SSL_GA.MODAL_TO_OTHER) {
      action = EVENTS.ACTION.SSL_MODAL_TO_OTHER_CLICK;
      label = 'Clique - Modal domínio não configurado para a HostGator';
    }
  }

  if (!click) {
    if (view === SSL_GA.REGISTER_DOMAIN) {
      action = EVENTS.ACTION.VIEW_MODAL_SSL_REGISTER_DOMAIN;
      label = 'Visualização  - Modal Registrar domínio';
    }
    if (view === SSL_GA.ACTIVATION_ERROR) {
      action = EVENTS.ACTION.VIEW_ACTIVATION_ERROR;
      label = 'Visualização  - Modal Erro na ativação (Auto SSL liberado)';
    }
    if (view === SSL_GA.MODAL_TO_OTHER) {
      action = EVENTS.ACTION.VIEW_SSL_MODAL_TO_OTHER;
      label = 'Visualização - Modal domínio não configurado para a HostGator';
    }
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.TAG_SSL,
    gaEventAction: action,
    gaEventLabel: label,
  });
};

const ecommercePlusModalOpen = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.MODALECOMMERCEPLUS,
  gaEventAction: EVENTS.ACTION.MODAL_ECOMMERCE_PLUS_SHOW,
  gaEventLabel: EVENTS.LABEL.MODAL_ECOMMERCE_PLUS,
});

const welcomeChecklistBanner = (action, label) => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.WELCOMECHECK_LIST_BANNER,
    gaEventAction: action,
    gaEventLabel: label,
  });
};

const checkStep1 = () => welcomeChecklistBanner('Check - Passo dominio-dn', 'Ação: Checar passo dominio-dns');
const checkStep2 = () => welcomeChecklistBanner('Check - Passo criar e-mail', 'Ação: Checar passo criar e-mail');
const checkStep3 = () => welcomeChecklistBanner('Check - Passo site profissional', 'Ação: Checar passo site profissional');
const clickCreateEmail = () => welcomeChecklistBanner('Click -  Criar conta de e-mail', 'Ação: Abrir criador de e-mail');
const clickCreateStore = () => welcomeChecklistBanner('Click -  Criar site ou Loja Virtual', 'Ação: Abrir criador de site');
const clickVerifyDNS = () => welcomeChecklistBanner('Click - Verificar configuração DNS', 'Ação: Abrir configuração de DNS');
const clickConfigHostgatorDomain = () => welcomeChecklistBanner('click - Configurar dominio Hostgator', 'Ação: Abrir configuração dominio hostgator');
const clickConfigExternalDomain = () => welcomeChecklistBanner('Click -  Configurar dominio externo', 'Ação: Abrir configuração dominio externo');
const clickEmailConfig = () => welcomeChecklistBanner('Click - Como configurar o e-mail', 'Ação: Abri configuração de email');
const clickWordpress = () => welcomeChecklistBanner('Click - Como criar um site no WordPress', 'Ação: Abrir como criar site no wordpress');
const clickVerifySSL = () => welcomeChecklistBanner('Click - Verificar (SSL)', 'Ação: Abrir link certificado de segurança');
const clickLinkLadingPage = () => welcomeChecklistBanner('Click - Dicas para uma Landing Page', 'Ação: Abrir link dicas landing page');
const noShowBanner = () => welcomeChecklistBanner('Click - Não quero ver este painel', 'Ação: Fechar banner');
const hideChecklist = () => welcomeChecklistBanner('Click - Ocultar checklist', 'Ação: Ocultar checklist');
const showChecklist = () => welcomeChecklistBanner('Click - Mostra checklist', 'Ação: Mostrar checklist');
const showWelcomeCheckListBanner = () => welcomeChecklistBanner('Visualização - Banner', 'Ação: Exibir banner');

const analytics = {
  sitesWarningModal: {
    modalWarningClick,
  },
  sitesPaymentWarnings: {
    handlePay,
  },
  bannerNewSite: {
    createNewSiteClick,
  },
  asaasBanner: {
    asaasShowMore,
  },
  ssl: {
    sslTags,
    sslModal,
  },
  ecommercePlusModal: {
    ecommercePlusModalOpen,
  },
  welcomeChecklistBanner: {
    checkStep1,
    checkStep2,
    checkStep3,
    clickCreateEmail,
    clickCreateStore,
    clickVerifyDNS,
    clickConfigHostgatorDomain,
    clickConfigExternalDomain,
    clickEmailConfig,
    clickWordpress,
    clickVerifySSL,
    clickLinkLadingPage,
    noShowBanner,
    hideChecklist,
    showChecklist,
    showWelcomeCheckListBanner,
  },
};

export default analytics;
