import styled, { css } from 'styled-components';

const ContentPadding = '24px';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-bottom: 47px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      padding: 0 40px;
      padding-bottom: 80px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    box-shadow: ${theme.v2.shadows.dark};
    margin-top: 8px;
    min-height: 400px;
    padding: ${ContentPadding};
    position: relative;
  `}
`;

export const IconContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const BenefitTitle = styled.strong`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: 26px;
    text-align: center;
    width: 100%;

    &::after {
      content: '';

      background-color: ${theme.v2.colors.feedback.canceledPure};
      display: block;
      height: 1px;
      margin-top: 30px;
      width: 100%;
    }

    @media (min-width: ${theme.v2.breakpoints.sm}) {
        font-size: ${theme.v2.font.sizes.md};
        line-height: 32px;
        margin-top: 10px;
      }
  `}
`;

export const BenefitDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    line-height: 29px;
    margin-top: 36px;
    text-align: center;
    width: 100%;
    p {
      margin: 0;
    }

    strong {
      font-weight: ${theme.v2.font.weights.bold};
    }

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      font-size: ${theme.v2.font.sizes.md};
      line-height: 32px;
      margin-top: 25px;
    }
  `}
`;

export const PricesContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    margin-top: 24px;

    @media (min-width: ${theme.v2.breakpoints.md}) {
      align-items: flex-start;
      flex-direction: row;
      margin-top: 40px;
    }
  `}
`;

export const CurrentPriceWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;

    @media (min-width: ${theme.v2.breakpoints.md}) {
      flex-direction: row;
    }
  `}
`;

export const CurrentPriceContent = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.v2.colors.feedback.canceledPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 34px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      max-width: 290px;
    }
  `}
`;

export const DiscountPriceContent = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.v2.colors.brand.primaryBlue};
    font-family: ${theme.v2.font.family.primary};
    padding: 34px;

    button {
      width: 100%;
      margin-top: 24px;
    }
  `}
`;

export const CurrentPriceCycleText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    line-height: ${theme.v2.font.lineHeight.text};

    p {
      margin: 0;
    }

    @media(min-width: ${theme.v2.breakpoints.sm}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `}
`;

export const CurrentPriceMonth = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.list};
    margin: 0;
    margin-top: 4px;
  `}
`;

export const CurrentPriceYear = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;

    strong {
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;

export const CurrentPriceTotal = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;

    strong {
      display: block;
      font-size: ${theme.v2.font.sizes.md};
      margin-bottom: 12px;
      margin-top: 24px;
    }
  `}
`;

export const ArrowWrapper = styled.div`
  ${({ theme }) => css`
    transform: rotate(90deg) translateY(3px);

    @media (min-width: ${theme.v2.breakpoints.md}) {
      transform: none;
    }
  `}
`;

export const BenefitFuturePriceFirstText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    line-height: ${theme.v2.font.lineHeight.text};

    p {
      margin: 0;
    }

    @media(min-width: ${theme.v2.breakpoints.sm}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `}
`;

export const BenefitFuturePriceDiscountWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 28px;
    justify-content: space-between;
    margin-top: 28px;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;
      flex-direction: row;
    }
  `}
`;

export const FuturePricesWrapper = styled.div`
  ${({ theme, showInstallment }) => css`
    display: flex;
    flex-direction: column;
    gap: 14px;

    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    line-height: ${theme.v2.font.lineHeight.text};

    strong {
      color: ${theme.v2.colors.brand.primaryBlue};
      font-size: ${theme.v2.font.sizes.sm};
    }

    p {
      margin: 0;
    }

    small {
      display: block;
      font-size: ${theme.v2.font.sizes.xxs};

      strong {
        font-size: ${theme.v2.font.sizes.xxs};
      }
    }

    ${!showInstallment && css`
      small {
          display: none;
        }
    `}

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      strong {
        font-size: ${theme.v2.font.sizes.md};
      }
    }
  `}
`;

export const DiscountTagWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;

    width: 100%;

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      max-width: 9.5rem;
    }
  `}
`;

export const DiscountTagContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    background-color: ${theme.v2.colors.feedback.attentionPure};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
  `}
`;

export const BenefitFinalExplanation = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryBlue};
    font-size: ${theme.v2.font.sizes.xxxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin-bottom: 0;
    margin-top: 32px;
    text-align: center;
    text-decoration: underline;

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      color: ${theme.v2.colors.neutral.lowPure};
      text-decoration: none;
    }
  `}
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin: 28px -${ContentPadding} -${ContentPadding} -${ContentPadding};
    padding: 24px 28px;
  `}
`;
