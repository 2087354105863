import React from 'react';
import * as Style from './IconDetailedPhone.style';

const IconDetailedPhone = ({
  color = '#FFCF00',
  backgroundColor = '#1F2044',
  width = '40',
  height = '50',
  testId = 'icon-detailed-phone',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0681 2V46.6512C21.4381 46.6512 26.588 44.299 30.3852 40.1122C34.1823 35.9253 36.3155 30.2467 36.3155 24.3256C36.3155 18.4045 34.1823 12.7259 30.3852 8.53901C26.588 4.35215 21.4381 2 16.0681 2Z" fill={color} />
      <g clipPath="url(#clip0_1172_21565)">
        <path d="M23.4692 7.23242H11.3762C9.29016 7.23242 7.59714 8.92545 7.59714 11.0115V36.7092C7.59714 38.7952 9.29016 40.4882 11.3762 40.4882H23.4692C25.5553 40.4882 27.2483 38.7952 27.2483 36.7092V11.0115C27.2483 8.92545 25.5553 7.23242 23.4692 7.23242ZM17.4227 38.9766C16.1681 38.9766 15.1553 37.9638 15.1553 36.7092C15.1553 35.4545 16.1681 34.4417 17.4227 34.4417C18.6774 34.4417 19.6902 35.4545 19.6902 36.7092C19.6902 37.9638 18.6774 38.9766 17.4227 38.9766ZM24.225 32.9301H10.6204V11.7673H24.225V32.9301Z" fill={backgroundColor} />
      </g>
      <defs>
        <clipPath id="clip0_1172_21565">
          <rect width="36.2791" height="36.2791" fill="white" transform="translate(0.0390015 5.7207)" />
        </clipPath>
      </defs>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedPhone;
