import React, { useCallback, useEffect } from 'react';
import * as Styles from './TicketItems.styles';
import { Button, IconChat, Tag } from 'gatorcomponents';
import { format } from 'date-fns';
import { supportAnalytics } from '@/analytics';

export const TicketItems = ({
  filteredTickets,
  domainRegexWithUpperCase,
  ticketLocale,
  handleOpenTicket,
  empty,
}) => {
  const handleClick = ({ id, v2 }) => {
    supportAnalytics.showTicketsCtaClick();
    handleOpenTicket && handleOpenTicket({ id, v2 });
  };

  const dispatchGaTags = useCallback(() => {
    if (filteredTickets.length) {
      supportAnalytics.showTicketsDisplayed();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchGaTags();
  }, [dispatchGaTags]);

  const statusTagVariants = {
    opened: 'attention',
    closed: 'cancel',
    open: 'attention',
    new: 'attention',
    solved: 'active',
    pending: 'pending',
  };

  return (
    <>
      {filteredTickets.map((ticket) => {
        let domain = ticket.description.match(domainRegexWithUpperCase);
        const { subject } = ticket;
        let newSubject = subject;

        if (subject) {
          const subjectDomainIndex = subject.search(domainRegexWithUpperCase);
          if (subjectDomainIndex > -1) {
            newSubject = subject.substring(0, subjectDomainIndex);
            if (!domain) {
              domain = subject.match(domainRegexWithUpperCase);
            }
          }
        }

        return (
          <Styles.Ticket key={ticket.id}>
            <IconChat />

            <Styles.TicketSubjectWrapper>
              <Styles.TicketTitleItem title={newSubject}>
                {newSubject}
              </Styles.TicketTitleItem>

              {(domain && domain[0]) && (
                <Styles.TicketValueItem title={domain[0]}>
                  {domain[0]}
                </Styles.TicketValueItem>
              )}
            </Styles.TicketSubjectWrapper>

            <Styles.TagWrapper>
              <Tag
                variant={statusTagVariants[ticket.status]}
                label={ticketLocale.listPage.tickets.status[ticket.status]}
              />
            </Styles.TagWrapper>

            <Styles.TicketNumberWrapper>
              <Styles.TicketTitleItem data-testid="ticketId">{ticket.id}</Styles.TicketTitleItem>
              <Styles.TicketValueItem>{ticketLocale.listPage.tickets.numberLabel}</Styles.TicketValueItem>
            </Styles.TicketNumberWrapper>

            <Styles.TicketDateWrapper>
              <Styles.TicketTitleItem data-testid="ticketDate">{format(new Date(ticket.created_at), 'dd/MM/yyyy')}</Styles.TicketTitleItem>
              <Styles.TicketValueItem>{ticketLocale.listPage.tickets.openingLabel}</Styles.TicketValueItem>
            </Styles.TicketDateWrapper>

            <Styles.TicketViewWrapper>
              <Button
                variant="secondary"
                label={ticketLocale.listPage.tickets.viewButtonLabel}
                onClick={() => handleClick({ id: ticket.id, v2: ticket.v2 })}
                testId="viewInvoice"
              />
            </Styles.TicketViewWrapper>
          </Styles.Ticket>
        );
      })}

      {filteredTickets.length === 0 && (
        <Styles.Ticket>
          <Styles.EmptyInfo>
            {empty}
          </Styles.EmptyInfo>
        </Styles.Ticket>
      )}
    </>
  );
};
