import React from 'react';
import * as Style from './IconFlarum.style';

const IconFlarum = ({
  color = '#4a4a4a',
  detail = '#2d2d2d',
  width = '24',
  height = '29',
  testId = 'icon-flarum',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={width} height={height} viewBox="0 0 24.359 29" fill={color}>
      <g id="Grupo_39725" data-name="Grupo 39725" transform="translate(0 -0.104)">
        <path id="Caminho_247784" data-name="Caminho 247784" d="M5.3,75H0l1.075,1.076,12.906,7.8V75Z" transform="translate(0 -54.831)" fill={detail} />
        <g id="Grupo_39726" data-name="Grupo 39726" transform="translate(0.193 0.104)">
          <g id="Grupo_39725-2" data-name="Grupo 39725" transform="translate(0)" clipPath="url(#clip-path)">
            <path id="Caminho_247785" data-name="Caminho 247785" d="M24.2,0H.807A.807.807,0,0,0,0,.807V20.165l.113.113.939.939A.147.147,0,0,0,1.3,21.1a4.9,4.9,0,0,1,.025-.935H24.2a.269.269,0,0,0,.269-.269V.269A.269.269,0,0,0,24.2,0" transform="translate(-0.192 -0.1)" />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconFlarum;
