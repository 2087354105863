import React, { useState } from 'react';
import { Trans, withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core/';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import IncrementAccounts from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/IncrementAccounts';
import getUsedAccounts from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/GetUsedAccoutns';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';

const ModalChangeInboxAmount = ({
  t,
  classes,
  width,
  showModal,
  toggleShowModal,
  setNewAmount,

  plan,
  multiple,
  paymentCycle,
  domainObject,
}) => {
  const isWidthDownSm = isWidthDown('sm', width);
  const [amount, setAmount] = useState(multiple);

  return (
    <Dialog
      fullScreen={!!isWidthDownSm}
      open={showModal}
      onClose={() => toggleShowModal(false)}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => toggleShowModal(false)}
        data-testid="closeButton"
      >
        <Close />
      </IconButton>

      <DialogTitle id="alert-dialog-title" className={classes.titleElement}>
        {t('proEmail.upgradePlan.modalTitle')}
      </DialogTitle>

      <DialogContent className={classes.containerWrapper}>
        <DialogContentText className={classes.description}>
          <Trans i18nKey="proEmail.upgradePlan.modalDescription">
            <>0</>
            <b>1</b>
          </Trans>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classnames(classes.containerWrapper, classes.containerWrapperCenter)}>
        <div>
          <IncrementAccounts label={t('proEmail.hirePlan.mailBoxes')} min={getUsedAccounts(domainObject.used_accounts)} max={plan.configoptions[0].max_value} amount={amount} setAmount={setAmount} domainObject={domainObject} />
        </div>
      </DialogContent>

      <DialogContent className={classes.containerWrapper}>
        <div className={classes.modalDescriptionRow}>
          <div className={classnames(classes.changeInboxDescription, classes.firstInboxBlock)}>
            <DialogContentText className={classes.tableITitle}>
              {t('proEmail.upgradePlan.modalPlanTitle')}
            </DialogContentText>
            <DialogContentText className={classes.tableItem}>
              {' '}
              {plan.name}
            </DialogContentText>
          </div>

          <div className={classes.changeInboxDescription}>
            <DialogContentText className={classes.tableITitle}>
              {t('proEmail.upgradePlan.modalPerMailTitle')}
            </DialogContentText>
            <DialogContentText className={classes.tableItem}>
              {formatCurrency(plan.configoptions[0][paymentCycle].toLowerCase())}
              {' '}
              <small>{t(`proEmail.upgradePlan.${paymentCycle.toLowerCase()}`)}</small>
            </DialogContentText>
          </div>

          <div className={classes.changeInboxDescription}>
            <DialogContentText className={classes.tableITitle}>
              {t('proEmail.upgradePlan.modalRecurrentTitle')}
            </DialogContentText>
            <DialogContentText className={classes.tableItem}>
              {formatCurrency(amount * plan.configoptions[0][paymentCycle].toLowerCase())}
              {' '}
              <small>{t(`proEmail.upgradePlan.${paymentCycle.toLowerCase()}`)}</small>
            </DialogContentText>
          </div>
        </div>
      </DialogContent>


      <DialogActions className={classes.actionsWrapper}>
        <OutlineButton
          className={classes.outlinedCancel}
          onClick={() => {
            toggleShowModal(false);
          }}
          data-testid="cancel"
        >
          {t('proEmail.upgradePlan.modalCancel')}
        </OutlineButton>

        <PrimaryButton
          color="primary"
          onClick={() => {
            toggleShowModal(false);
            setNewAmount(amount);
          }}
          data-testid="save"
        >
          {t('proEmail.upgradePlan.modalSave')}
        </PrimaryButton>
      </DialogActions>

    </Dialog>
  );
};

export default withI18n()(withWidth()(withStyles(styles)(ModalChangeInboxAmount)));
