import styled, { css } from 'styled-components';

export const Wrapper = styled.textarea`
  ${({ theme }) => css`
    border-radius: 5px;
    border: 1px solid ${theme.palette.grey.light};
    box-sizing: border-box;
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    min-height: 144px;
    outline: 0 none;
    padding: 16px;
    resize: none;
    transition: border-color 200ms ease-in-out;
    width: 100%;

    &:focus {
      border-color: ${theme.palette.primary.main};
    }
  `}
`;
