import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from 'react-i18next';
import { Collapse, withStyles, Tooltip } from '@material-ui/core';
import { ExpandLess as ExpandIcon } from '@material-ui/icons';
import classnames from 'classnames';
import SiteCard from '@/components/Cards/SiteCard';
import SiteItem from '@/components/SitesPlans/SiteItem';
import CardStatus from '@/components/Cards/CardStatus/';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import LinkButton from '@/components/Buttons/LinkButton';
import WarningNotice from '@/components/Notification/WarningNotice/';
import PlanButtonsList from '@/components/SitesPlans/PlanButtonsList';
import styles from './styles';

const PlanItem = ({
  classes,
  plan,
  t,
}) => {
  const {
    id,
    name,
    status,
    domain,
    external_id: externalId,
    items: sites,
  } = plan;

  const isActive = status === 'active';
  const activeTooltip = status === 'pending' || status === 'active' || status === 'suspended' || status === 'expired';
  const hasSite = sites.length > 0;
  const [isExpand, setIsExpand] = useState(false);

  const expand = () => {
    if (isActive && hasSite) {
      setIsExpand(!isExpand);
    }
  };

  const warningMessage = (status) => {
    switch (status) {
      case 'pending':
      case 'suspended':
        return (
          <Trans i18nKey={`plan.warning.${status}`}>
            0
            <strong>1</strong>
          </Trans>
        );
      case 'in_analysis':
      case 'on_configure':
        return t(`plan.warning.${status}`);
      case 'cancelled':
        return (
          <Trans i18nKey="plan.warning.cancelled">
            0
            <LinkButton to={HOSTING_URL} target="_blank">1</LinkButton>
          </Trans>
        );
      case 'expired':
        return (
          <Trans i18nKey="plan.warning.expired">
            0
            <LinkButton to={HOSTING_URL} target="_blank">1</LinkButton>
          </Trans>
        );
      default:
        return '';
    }
  };

  const renderCardStatus = () => (activeTooltip ? (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
        popper: classes.customPopper,
      }}
      placement="top"
      title={t(`plan.tooltip.${status}`)}
    >
      <div>
        <CardStatus status={status}>{`${t(`plan.status.${status}`)}`}</CardStatus>
      </div>
    </Tooltip>
  ) : (
    <CardStatus status={status}>{`${t(`plan.status.${status}`)}`}</CardStatus>
  ));

  return (
    <>
      <div
        className={classes.content}
        onClick={() => expand()}
        role="presentation"
        data-testid="plan-item-content"
      >
        <div className={classes.planContent}>
          <span className={classes.planName}>{name}</span>
          <div className={classes.planStatus}>
            {renderCardStatus()}
            <span className={classes.planDomain}>{domain}</span>
          </div>
        </div>

        <div className={classnames(classes.actionsContent, { [classes.actionsMobile]: !isActive })}>
          <div className={classnames({ [classes.buttonsList]: !isActive })}>
            <PlanButtonsList
              status={status}
              productId={id}
              externalId={externalId}
            />
          </div>

          {!isActive && (
            <div className={classes.warningWrapper}>
              <WarningNotice
                message={warningMessage(status)}
                border={status}
                radius="6px"
                fill="100%"
              />
            </div>
          )}

          {(isActive && hasSite) && (
            <div className={classes.expandButton}>
              <span className={classes.expandText}>{t('plan.expand')}</span>
              <ExpandIcon className={classnames(classes.expandIcon, {
                [classes.expandMore]: !isExpand,
                [classes.expandLess]: isExpand,
              })}
              />
            </div>
          )}
        </div>
      </div>

      {isActive && (
        <Collapse in={isExpand} data-testid="collpase-sub-items" timeout="auto">
          <div className={classes.collapse}>
            {sites.map(site => (
              <div className={classes.siteWrapper} key={site.key}>
                <SiteCard status={site.status}>
                  <SiteItem site={site} data-testid="site-item-component" />
                </SiteCard>
              </div>
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};

PlanItem.propTypes = {
  classes: PropTypes.object.isRequired,
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    external_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withI18n()(withStyles(styles)(PlanItem));
