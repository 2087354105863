import ticketBR from './ticket.br';
import ticketCL from './ticket.cl';
import ticketCO from './ticket.co';
import ticketMX from './ticket.mx';

const locales = {
  BR: ticketBR,
  CL: ticketCL,
  CO: ticketCO,
  MX: ticketMX,
};

export default locales;
