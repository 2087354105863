import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles, CircularProgress, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import SitelockDetails from '@/components/Security/SitelockDetails';
import styles from './styles';

const SitelockProductDetailsPage = ({ classes, t }) => {
  const {
    loadingDescription, loadingShield, loadingError,
  } = useSelector(state => state.sitelock);

  const renderContent = () => ((!loadingDescription && loadingShield) ? (<div className={classes.loading}><CircularProgress data-testid="spinner" /></div>) : (<SitelockDetails data-testid="sitelock-details" />));
  const renderErrorMessage = () => (
    <div className={classnames(classes.container)} data-testid="loading-error">
      <div className={classnames(classes.alert, classes.alertCritical)}>
        <Warning className={classes.icon} />
        <Typography className={classes.message}>{t('sitelock.errors.noConnection')}</Typography>
      </div>
    </div>
  );

  return (
    <ContentContainer>
      { loadingError ? renderErrorMessage() : renderContent()}
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles, { withTheme: true })(SitelockProductDetailsPage));
