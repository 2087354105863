import { formatDate } from '@/utils/Formatters/formatDate';

const scanDetailsResponse = (rawData) => {
  const scanDetails = rawData.data;

  return {
    ...scanDetails,
    last_check: formatDate(scanDetails.last_check, true),
    next_check: formatDate(scanDetails.next_check, true),
  };
};

const scanHistoryResponse = (rawData) => {
  const scanHistory = rawData.data;

  return scanHistory.map(scan => ({
    scanDate: formatDate(scan.scannedDate, true),
    filesVerified: scan.scanned,
    filesFixed: scan.fixed,
    filesNeedFix: scan.needFix,
  }));
};

const sitelockConfigurationResponse = rawData => (rawData.data && rawData.data.data && rawData.data.data.smartscan && rawData.data.data.smartscan.data) || {};

const assembly = {
  scanDetailsResponse,
  scanHistoryResponse,
  sitelockConfigurationResponse,
};

export default assembly;
