import React from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './BackupModal.styles';
import { Button, IconLoading, Select } from 'gatorcomponents';
import { useResize } from '@/hooks/useResize';

const BackupModal = ({
  onClose,
  securityLocale,
  productsLoading,
  productsOptions,
  selectedProduct,
  setSelectedProduct,
  goToBackup,
  setSelectedId,
}) => {
  const windowRect = useResize();
  const { width } = windowRect;
  const isMobile = width < 960;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleOpen = () => {
    goToBackup && goToBackup();
  };

  return (
    <Modal onClose={handleClose}>
      <Styles.Title>
        {securityLocale.modal.title}
      </Styles.Title>

      <Styles.Description>
        {securityLocale.modal.description}
      </Styles.Description>

      <Styles.SelectWrapper>
        {productsLoading
          ? <IconLoading />
          : (
            <Select
              name="plans_select"
              testId="plans_select"
              placeholder={securityLocale.modal.select.placeholder}
              label={securityLocale.modal.select.label}
              options={productsOptions}
              value={selectedProduct}
              onClickSelect={e => setSelectedId(e.value)}
              setValue={label => setSelectedProduct(label)}
              withScroll
              dropdownHeight={isMobile ? 'md' : 'sm'}
            />
          )
      }
      </Styles.SelectWrapper>

      <Styles.ActionsWrapper>
        <Button
          testId="submit"
          disabled={productsLoading || !selectedProduct}
          label={securityLocale.modal.goToLabel}
          size="large"
          onClick={handleOpen}
        />
      </Styles.ActionsWrapper>
    </Modal>
  );
};

export default BackupModal;
