import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 102px 0px;
`;

export const LoadingMessage = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin-top: 16px;
    max-width: 234px;
    text-align: center;
  `}
`;
