import { IconLoading } from 'gatorcomponents';
import * as Styles from './CheckingStatusStep.styles';
import React from 'react';
import PropTypes from 'prop-types';

export const CheckingStatusStep = ({
  subtitle,
  description,
}) => (
  <Styles.Wrapper data-testid="CheckingStatusStep">
    <Styles.Subtitle data-testid="subtitle">
      {subtitle}
    </Styles.Subtitle>

    <Styles.SelectManualDnsLoadingMessageWrapper>
      <Styles.SelectManualDnsLoadingMessage data-testid="description">
        {description}
      </Styles.SelectManualDnsLoadingMessage>

      <IconLoading />
    </Styles.SelectManualDnsLoadingMessageWrapper>
  </Styles.Wrapper>
);

CheckingStatusStep.propTypes = {
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default CheckingStatusStep;
