import { CALL_API } from '@/middleware/api';
import { PAYMENT_METHOD_URL, PAYMENT_METHOD_CC } from '@/config/api';
import {
  PAYPAL, CREDIT_CARD, CREDIT_CARD_ES, BRASPAG_PARC_1X, BRASPAG_PARC_2X,
  BRASPAG_PARC_3X, BRASPAG_PARC_4X, BRASPAG_PARC_5X, BRASPAG_PARC_6X,
  BRASPAG_PARC_7X, BRASPAG_PARC_8X, BRASPAG_PARC_9X, BRASPAG_PARC_10X,
  BRASPAG_PARC_11X, BRASPAG_PARC_12X,
} from '@/config/billing/paymentMethods';
import {
  REQUEST_PAYMENT_METHOD, REQUEST_CC_METHOD, RECEIVE_CC_METHOD, ERROR_CC_METHOD,
  ERROR_PAYMENT_METHOD, OPEN_PAYMENT_PAYPAL_NEW_TAB,
} from './actionsTypes';


const paypalMethod = (paypalPaymentData) => {
  const data = [`${paypalPaymentData.form_action}?`];
  Object
    .keys(paypalPaymentData)
    .forEach((key) => {
      if (key !== 'form_action') {
        data.push(`${key}=${encodeURIComponent(paypalPaymentData[key])}`);
      }
    });
  window.open(data.join('&'));
  return { type: OPEN_PAYMENT_PAYPAL_NEW_TAB };
};

export const ccMethod = (invoiceId, fingerPrintId = null, changeProductsPaymentMethodToCC = null) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYMENT_METHOD_CC(invoiceId),
    body: fingerPrintId != null ? { fpId: fingerPrintId, changeProductsPaymentMethodToCC } : { changeProductsPaymentMethodToCC },
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_CC_METHOD }),
      success: (data) => {
        if (!!data.data && data.success && data.data.result) {
          return ({ type: RECEIVE_CC_METHOD });
        }

        const { data: response } = data;
        if (Object.prototype.hasOwnProperty.call(response, 'key')) {
          return { type: ERROR_CC_METHOD, key: response.key, status: data.data.status };
        }
        return { type: ERROR_CC_METHOD, status: data.data.status };
      },
      error: () => ({ type: ERROR_CC_METHOD }),
    },
  },
});

const receivePaymentMethod = (method, invoiceId, paymentMethodData, fingerPrintId, changeProductsPaymentMethodToCC) => {
  switch (method) {
    case PAYPAL:
      return paypalMethod(paymentMethodData);
    case CREDIT_CARD:
    case CREDIT_CARD_ES:
    case BRASPAG_PARC_1X:
    case BRASPAG_PARC_2X:
    case BRASPAG_PARC_3X:
    case BRASPAG_PARC_4X:
    case BRASPAG_PARC_5X:
    case BRASPAG_PARC_6X:
    case BRASPAG_PARC_7X:
    case BRASPAG_PARC_8X:
    case BRASPAG_PARC_9X:
    case BRASPAG_PARC_10X:
    case BRASPAG_PARC_11X:
    case BRASPAG_PARC_12X:
      return ccMethod(invoiceId, fingerPrintId, changeProductsPaymentMethodToCC);
    default:
      return { type: ERROR_PAYMENT_METHOD };
  }
};

export const getPaymentMethod = (method, invoiceId, fingerPrintId = null, changeProductsPaymentMethodToCC = null) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYMENT_METHOD_URL(invoiceId),
    method: 'PUT',
    body: { method: method.toLowerCase() },
    actionTypes: {
      request: () => ({ type: REQUEST_PAYMENT_METHOD }),
      success: data => receivePaymentMethod(method, invoiceId, data.data, fingerPrintId, changeProductsPaymentMethodToCC),
      error: () => ({ type: ERROR_PAYMENT_METHOD }),
    },
  },
});
