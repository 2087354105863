import sitesBR from './sites.br';
import sitesCL from './sites.cl';
import sitesCO from './sites.co';
import sitesMX from './sites.mx';

const locales = {
  BR: sitesBR,
  CL: sitesCL,
  CO: sitesCO,
  MX: sitesMX,
};

export default locales;
