import styled, { css } from 'styled-components';

const mobileBreakpoint = 'max-width:1289px';
const extraSmallMobileBreakpoint = 'max-width:439px';
const leftIconSpacing = '40px';
const tagWrapperWidth = '170px';


export const TicketsList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
`;

export const Ticket = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
    padding: 25px 0px 22px;

    @media(${mobileBreakpoint}){
      padding: 8px 0px;
    }
  `}
`;

export const TicketSubjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px 30px 0px 16px;
  max-width: 370px;
  width: 100%;

  @media(${mobileBreakpoint}){
    margin: 0px 0px 8px 16px;
    max-width: unset;
    width: calc(100% - ${leftIconSpacing} - ${tagWrapperWidth});
  }

  @media(${extraSmallMobileBreakpoint}){
    margin: 0px 0px 8px auto;
    width: calc(100% - ${leftIconSpacing} - ${tagWrapperWidth} - 10px);
  }
`;

export const TicketTitleItem = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `}
`;

export const EmptyInfo = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px auto;
    margin: 0px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `}
`;

export const TicketValueItem = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const TagWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  white-space: nowrap;
  width: ${tagWrapperWidth};

  > div {
    position: relative;
    width: ${tagWrapperWidth};
  }

  @media(${mobileBreakpoint}){
    justify-content: flex-end;
    margin: 0px 0px 8px 0px;
  }

  @media(${extraSmallMobileBreakpoint}){
    margin: 0px 0px 8px auto;
  }
`;

export const TicketNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px 0px 0px 30px;
  max-width: 105px;

  @media(${mobileBreakpoint}){
    max-width: unset;
    margin: 0px 30px 8px ${leftIconSpacing};
  }
`;

export const TicketDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px 30px 0px 30px;
  max-width: 105px;

  @media(${mobileBreakpoint}){
    margin: 0px 0px 8px 0px;
    max-width: unset;
  }
`;

export const TicketViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  max-width: 141px;

  @media(${extraSmallMobileBreakpoint}){
    max-width:unset;
    width: 100%;
    button {
      width: 100% !important;
    }
  }
`;
