import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    font-family: ${theme.font.family.secondary};
    margin: 15px;
  `}
`;

export const ModalTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    margin: 0;
    margin-bottom: 9px;
  `}
`;

export const ModalSubtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin: 0;
  `}
`;

export const ModalBody = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 16px;
    margin: 40px 0;
    max-height: 250px;
    overflow-y: auto;
    scrollbar-color: ${theme.v1.color.line.divisor} ${theme.v1.color.white.primary};
    scrollbar-width: thin;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 30px 0;
      max-height: fit-content;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.v1.color.white.primary};
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.v1.color.line.divisor};
      border-left: 0;
      border-radius: 25px;
      border-right: 0;
      height: 30px;
      width: 100%;
    }
  `}
`;

export const ModalFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    gap: 32px;
    justify-content: center;
    margin-bottom: 24px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      flex-direction: column-reverse;
      gap: 24px;
    }
  `}
`;

export const ModalSubtitleWrapper = styled.div`
  display: flex;
`;

export const TooltipWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

export const Sites = styled.div`
  margin-top: 8px;
`;

export const MainDomain = styled.p`
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const OtherDomain = styled.p`
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
