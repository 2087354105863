import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 25px;
    padding: 0 24px;

    > section:first-child {
      box-shadow: 0px 1px 3px ${theme.v1.color.shadow.primary};
    }

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 10px;
      padding: 0;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme, loading }) => css`
    background-color: ${theme.color.white};
    border-top: 1px solid ${theme.color.mystic};
    box-shadow: 0px 1px 3px ${theme.color.tundoraLight};
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family.secondary};
    margin-bottom: -10px;
    min-height: calc(100vh - 200px);
    padding: 0px 32px;
    position: relative;

    ${loading && `
      svg {
        margin: auto;
      }
    `}

    @media (max-width: ${theme.breakpoints.sm}) {
      min-height: calc(100vh - 170px);
      margin-bottom: -15px;
      padding: 0 16px;
    }
  `}
`;

export const ContainerBottomRow = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    padding: 0px 0px 40px 0px;
      
    & svg {
      fill: transparent;
      margin: 0px 10px 0px;
      stroke: transparent;
      transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
      transform: rotate(180deg);
    }

    &:hover {
      svg {
        fill: ${theme.v1.color.action.primary};
        stroke: ${theme.v1.color.action.primary};
      }
    }
  `}
`;

export const Snappy = styled.img`
  ${({ theme, maxWidth }) => css`
    max-width: ${maxWidth || 'unset'};
    @media (max-width: ${theme.breakpoints.sm}) {
      height: 160px;
    }
  `}
`;

export const BottomLine = styled.img`
  ${({ theme }) => css`
    height: 5px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: -5px;
    }
  `}
`;
