const styles = () => ({
  divContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divContainerDialog: {
    width: '648px',
  },
  newdivContainerDialog: {
    width: 'auto',
  },
  iframe: {
    width: '100%',
    border: '0',
  },
  actions: {
    display: 'flex',
    gap: '40px',
  },
});

export default styles;
