import React, { useCallback, useState } from 'react';
import * as Styles from './UpdateWhmcsLicense.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const UpdateWhmcsLicense = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { updateWhmcsLicense: updateWhmcsLicenseLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    license: '',
    directory: '',
    accessLink: '',
    serverIp: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.domain
    && !!formData.license
    && !!formData.directory
    && !!formData.accessLink
    && !!formData.agreed
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.updateWhmcsLicense,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.UPDATE_WHMCS_LICENSE,
      tags: [
        'whmcs_form_tecnico_licenca_whmcs',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
         • ${updateWhmcsLicenseLocale.body.product(selectedProduct)}
         • ${updateWhmcsLicenseLocale.body.request}
         • ${updateWhmcsLicenseLocale.placeholders.domain}: ${formData.domain}
         • ${updateWhmcsLicenseLocale.placeholders.license}: ${formData.license}
         • ${updateWhmcsLicenseLocale.placeholders.directory}: ${formData.directory}
         • ${updateWhmcsLicenseLocale.placeholders.accessLink}: ${formData.accessLink}
         • ${updateWhmcsLicenseLocale.placeholders.serverIp}: ${formData.serverIp}
         • ${updateWhmcsLicenseLocale.body.agreed(formData.agreed)}
         • ${updateWhmcsLicenseLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    updateWhmcsLicenseLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {updateWhmcsLicenseLocale.info}
      </Styles.Info>

      <Styles.Title>
        {updateWhmcsLicenseLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Styles.InputWrapper>
          <Input
            name="domain"
            placeholder={updateWhmcsLicenseLocale.placeholders.domain}
            label={formData.domain ? updateWhmcsLicenseLocale.placeholders.domain : ''}
            value={formData.domain}
            onChange={e => handleChange({ key: 'domain', value: e.target.value })}
            type="text"
            testId="domain"
          />
          <Styles.InputInfoText>
            {updateWhmcsLicenseLocale.placeholders.domainInfo}
          </Styles.InputInfoText>
        </Styles.InputWrapper>

        <Input
          name="license"
          placeholder={updateWhmcsLicenseLocale.placeholders.license}
          label={formData.license ? updateWhmcsLicenseLocale.placeholders.license : ''}
          value={formData.license}
          onChange={e => handleChange({ key: 'license', value: e.target.value })}
          type="text"
          testId="license"
        />

        <Styles.InputWrapper>
          <Input
            name="directory"
            placeholder={updateWhmcsLicenseLocale.placeholders.directory}
            label={formData.directory ? updateWhmcsLicenseLocale.placeholders.directory : ''}
            value={formData.directory}
            onChange={e => handleChange({ key: 'directory', value: e.target.value })}
            type="directory"
            testId="directory"
          />

          <Styles.InputInfoText>
            {updateWhmcsLicenseLocale.placeholders.directoryInfo}
          </Styles.InputInfoText>
        </Styles.InputWrapper>

        <Input
          name="accessLink"
          placeholder={updateWhmcsLicenseLocale.placeholders.accessLink}
          label={formData.accessLink ? updateWhmcsLicenseLocale.placeholders.accessLink : ''}
          value={formData.accessLink}
          onChange={e => handleChange({ key: 'accessLink', value: e.target.value })}
          type="text"
          testId="accessLink"
        />

        <Styles.InputWrapper>
          <Input
            name="serverIp"
            placeholder={updateWhmcsLicenseLocale.placeholders.serverIp}
            label={formData.serverIp ? updateWhmcsLicenseLocale.placeholders.serverIp : ''}
            value={formData.serverIp}
            onChange={e => handleChange({ key: 'serverIp', value: e.target.value })}
            type="text"
            testId="serverIp"
          />
          <Styles.InputInfoText>
            {updateWhmcsLicenseLocale.placeholders.serverIpInfo}
          </Styles.InputInfoText>
        </Styles.InputWrapper>
      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {updateWhmcsLicenseLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : updateWhmcsLicenseLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default UpdateWhmcsLicense;
