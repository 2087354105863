import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import IncrementAccounts from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/IncrementAccounts';
import getUsedAccounts from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/GetUsedAccoutns';
import styles from './styles';

const MailBoxesBlock = ({
  t,
  classes,
  domainObject,
  amount,
  setAmount,
  plan,
  setTyping,
}) => (
  <div className={classes.MailBoxesWrapper}>
    <Typography className={classes.WrapperTitle} data-testid="mailBoxesTitle">{t('proEmail.upgradePlan.upgradeMailBoxSelectMailBoxAmount')}</Typography>

    <IncrementAccounts
      label=""
      min={getUsedAccounts(domainObject.used_accounts)}
      max={plan.configoptions[0].max_value}
      amount={amount}
      setAmount={setAmount}
      showSeparator={false}
      setTyping={setTyping}
      domainObject={domainObject}
    />
  </div>
);

MailBoxesBlock.propTypes = {
  domainObject: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(MailBoxesBlock));
