const styles = theme => ({
  featuredMessage: {
    color: theme.color.tundora,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '29px',
    marginBottom: 6,
    marginTop: 20,
    textAlign: 'center',
  },
  message: {
    color: theme.color.tundora,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19px',
    marginBottom: 25,
    maxWidth: '702px',
    textAlign: 'center',
  },
});

export default styles;
