import React from 'react';

export default {
  manualSelectDns: {
    backLabel: 'Volver',
    cancelLabel: 'Cancelar',
    continueLabel: 'Continar',
    finishLabel: 'Ok, entendí',
    howToSetupLabel: 'Ver cómo configurar',
    backToInstructions: 'Volver a las instrucciones',
    close: 'Cerrar',
    step: {
      checkStatus: {
        title: 'Verificar Dominio',
        subtitle: 'Verifica el estado de configuración de tu dominio.',
        description: 'Estamos revisando si todo está listo para activar tu CDN...',
        whereDomainIsConfiguredLabel: '¿Dónde se registró el dominio?',
        selectHostPlaceholder: 'Seleccionar proveedor',
      },
      domainOriginalRegistration: {
        title: 'Verificar dominio',
        subtitle: 'Verifica el estado de configuración de tu dominio.',
        alertDescription: domain => (
          <>
            {'Como el dominio '}
            <span>
              {`"${domain}" `}
            </span>
            no se registró en HostGator, necesitas acceder al sitio donde se registró y cambiar las configuraciones para que apunten a tu hospedaje en HostGator.
          </>
        ),
        inputLabel: 'Selecciona dónde se registró',
        selectPlaceholder: 'Seleccionar',
      },
      howToSetup: {
        title: 'Cómo configurar dominio en otro proveedor de sitio',
        howToSectionTitle: 'Sigue los pasos para cambiar tu nombre de servidor',
        steps: ({ from }) => [
          {
            id: 'firstStep',
            description: 'Accede al sitio del proveedor donde se registró tu dominio',
          },
          {
            id: 'secondStep',
            description: `Localiza los nombres de servidores (DNS) actuales en el sitio de ${from}`,
          },
          {
            id: 'thirdStep',
            description: `Reemplaza los nombres de servidores (DNS) que están en el sitio de ${from} por los de CloudFlare. Solo copia y pega los valores a continuación:`,
            alert: 'Al continuar, tus configuraciones actuales del dominio serán cambiadas',
          },
        ],
        serverLabel: index => `Servidor ${index + 1} (DNS): `,
        goTo: selectedManualDns => `ir a ${selectedManualDns}`,
        alreadyMadeChanges: 'Continuar',
        backtoInstructions: 'Volver',
      },
      finishDomainSetup: {
        title: 'Finalizando las configuraciones de dominio',
        subtitle: 'Después de haber realizado las configuraciones, sigue aquí el estado de tu dominio.',
        box: {
          title: 'Cambios que realizaste:',
          description: ({ from }) => `DNS actual en ${from} por DNS de Hostgator`,
          alert: (
            <>

              <strong>
                {'En proceso: '}
              </strong>
              {'La configuración del dominio aún está sucediendo y puede tardar hasta 72 '}
              <strong>72</strong>
              {' horas.'}
            </>
          ),
        },
      },
      domainNotRegistered: {
        title: domain => `El dominio “${domain}” aún no está registrado`,
        description: 'Para crear un nuevo sitio, el dominio necesita estar registrado y activo. Haz clic en el botón para registrarlo ahora mismo!',
        redirectLabel: 'Registrar dominio',
      },
    },
  },
};
