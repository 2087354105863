/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import {
  withStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  loadDnsDetails,
  setChosenDomain,
} from '@/redux/actions/dnsForward';
import { DNSContext } from '@/contexts/DNS/context';
import Loader from '@/components/Layout/Loader';
import ZoneManager from '@/components/Dns/ZoneManager';
import FaqDns from '@/components/FrequentlyQuestions/FaqDns';
import DomainSelectBlock from '@/components/Dns/DomainChoice/DomainSelectBlock';
import ChoosenDomainWithoutProvider from '@/components/Dns/DomainChoice/ChoosenDomainWithoutProvider';
import AnotherProviderBlock from '@/components/Dns/DomainChoice/AnotherProviderBlock';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import styles from './styles';

const LOCATION_DOMAINS = 'dominios';

const DomainChoice = ({
  domainId, classes,
}) => {
  const { featureTogglesZoneManager } = useContext(DNSContext);
  const chosenDomain = useSelector(state => state.dnsList.choosenDomain);
  const [anotherProvider, setAnotherProvider] = useState(false);
  const [transferDomain, setTransferDomain] = useState(false);
  const [changeDns, setChangeDns] = useState(false);
  const dnsList = useSelector(state => state.dnsList.domains);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const settingChosenDomain = (id, clearLoad) => {
    const allDns = [...dnsList.dnsOk, ...dnsList.dnsMissing];
    const dnsDomainSelected = allDns.find(item => item.id === parseInt(id, 10));
    if (dnsDomainSelected) {
      dispatch(loadDnsDetails(id)).then(() => {
        if (clearLoad) {
          setLoading(false);
        }
        dispatch(setChosenDomain(dnsDomainSelected.domain));
      });
    }
  };

  const loadDomainData = (id) => {
    setLoading(true);
    const clearLoad = true;
    settingChosenDomain(id, clearLoad);
  };

  useEffect(() => {
    loadDomainData(domainId);
    if (chosenDomain === '') {
      settingChosenDomain(domainId);
    }
  }, []);


  if (loading) {
    return (
      <div data-testid="loaderwrapper"><Loader /></div>
    );
  }

  const ShouldDisplayChoosenDomainWithoutProvider = chosenDomain !== '' && !anotherProvider;
  const ShouldDisplayZoneManager = featureTogglesZoneManager.native;

  return (
    <>
      <DomainSelectBlock
        setAnotherProvider={setAnotherProvider}
        loadDomainData={loadDomainData}
        chosenDomain={chosenDomain}
      />

      {ShouldDisplayChoosenDomainWithoutProvider
        && (
          <ChoosenDomainWithoutProvider
            loading={loading}
          />
        )
      }

      {anotherProvider
        && (
          <AnotherProviderBlock
            transferDomain={transferDomain}
            changeDns={changeDns}
            setTransferDomain={setTransferDomain}
            setChangeDns={setChangeDns}
          />
        )
      }

      {ShouldDisplayZoneManager
        && <ZoneManager />
      }
      <div className={classes.bottomInfoWrapper}>
        <FaqDns />
        <BottomAdvertisementHandler advertisementLocal={LOCATION_DOMAINS} />
      </div>
    </>
  );
};

DomainChoice.propTypes = {
  domainId: PropTypes.string.isRequired,
};

export default withRouter(withI18n()(withStyles(styles)(DomainChoice)));
