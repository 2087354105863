import React from 'react';
import * as Styles from './IconGraph.styles';

const IconGraph = ({
  testId = 'IconGraph',
}) => (
  <Styles.Svg
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <path d="M44.54 7.71143V42.9889H2.83789L44.54 7.71143Z" fill="#FFCF00" />
    <path d="M4.68716 22.3524H13.6269V43.032H16.5777V19.7559H1.73633V43.032H4.68716V22.3524Z" fill="#1F2044" />
    <path d="M25.0133 6.12344H33.953V43.0341H36.9039V3.52783H22.0625V43.035H25.0133V6.12344Z" fill="#1F2044" />
  </Styles.Svg>
);

export default IconGraph;
