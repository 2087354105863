import styled, { css } from 'styled-components';

export const HostGatorAlterPlatformModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 16px 8px;
    @media (min-width: ${theme.breakpoints.sm}) {
      margin: 8px 16px;
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    gap: 8px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeights.xxxxxl};
    margin: 0;
    max-width: 90%;
    > div {
      display: inline;
      margin-left:8px;
      > span {
        margin:0;
      }
    }
    > span {
      margin-top: 8px;
    }
  `}
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    line-height: ${theme.font.lineHeights.lg};
    font-weight: ${theme.font.weights.regular};
    margin: 8px 0 0 0;
    > p {
      > span{
        width: 100%;
        display: block;
        margin-top: 8px;
      }
      margin: 0;
    }
    > div {
      display: inline;
      margin-left: 3px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ position, theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: ${position === 'left' ? 'flex-start' : 'center'};
    text-align: center;
    > div {
      margin: 0 16px 32px 16px;
    }
    @media (min-width: ${theme.breakpoints.sm}) {
      flex-direction: row;
      text-align: left;
      align-items: center;
      > div {
        margin: 0 32px 0 0;
      }
    }
  `}
`;

export const ButtonConfig = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: 0;
    color: ${theme.v2.colors.brand.primaryBlue};
    cursor: pointer;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0 16px 26px;
    padding: 0;
    @media (min-width: ${theme.v2.breakpoints.sm}) {
      margin-bottom: 0;
    }
  `}
`;
