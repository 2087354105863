import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const DnsWizardHandlerWrapper = styled.div`
`;

export const DnsWizardHandlerContainer = styled.div`
${({ theme }) => css`
    display: grid;
    gap: 40px;

    > div > p {
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${theme.font.sizes.sm};
      font-weight: ${theme.v1.font.weights.regular};
      line-height: ${theme.font.lineHeights.lg};
      margin: 16px 0px 0px 0px;
      margin-bottom: 0px;
      margin-top: 16px;
      max-width: 75ch;

      > &:first-of-type {
        margin-top: 8px;
      }

      @media (min-width: ${theme.v1.breakpoints.sm}) {
        margin-left: 40px;
      }
    }
  `}
`;

export const TextBlock = styled.div``;

export const CTA = styled(Link)`
  ${({ theme, arrow }) => css`
    color: ${theme.v1.color.action.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.v1.font.weights.medium};
    line-height: ${theme.font.sizes.xl};
    text-align: center;
    text-decoration: none;

    & > span {
      content:"\a";
      white-space: pre;
    }

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      justify-self: end;
      text-align: right;
      transition: transform 0.3s, opacity 0.3s;
      white-space: nowrap;
      width: min-content;
      & > span {
        content:"";
        white-space: nowrap;
      }

    }

    &:hover {
      text-decoration: underline;

      @media (min-width: ${theme.v1.breakpoints.sm}) {
        transform: translate(-25px, 0px);
      }
    }

    &:after {
      content: url(${arrow});
      font-size: ${theme.font.sizes.xxxxxxl};
      font-weight: 1000;
      opacity: 0;
      position: absolute;
      transition: transform 0.1s, opacity 0.1s;

      @media (min-width: ${theme.v1.breakpoints.sm}) {
        transform: translate(-55px, -3px);
      }
    }

    &:hover:after {
      opacity: 1;
      transform: translate(5px, -3px);
    }
  `}
`;
