import React from 'react';
import * as Styles from './DnsWizardConfigCardExternal.styles';
import IconAttentionYellow from '@/icons/IconAttentionYellow/IconAttentionYellow';
import { Button } from 'gatorcomponents';
import { isMobile } from '@/utils/Validators/validation';

const DnsWizardConfigCardExternal = ({
  platform = {
    imageUrl: '',
    featured: false,
    platformID: '0004a9felocaweb-f681-4654-97a7-2ebeb34e60cd',
    platformName: 'Locaweb',
    nsServer1: 'ns1.locaweb.com.br',
    nsServer2: 'ns2.locaweb.com.br',
    nsServer3: 'ns3.locaweb.com.br',
    nsServer4: 'ns4.locaweb.com.br',
  },
  changePlatformText = '',
  nameServers = [],
  serverText,
  attentionMessage,
  externalPlatformAttentionMessage = p => p,
  testId = 'dns-wizard-config-card-dns-zone',
  changePlatformClick,
}) => {
  const { platformName, imageUrl } = platform;

  return (
    <Styles.DnsWizardPlanCardWrapper data-testid={testId} isToggleOn>
      <Styles.Header isToggleOn>
        <Styles.LogoWrapper>
          <Styles.Logo
            src={imageUrl}
            loading="lazy"
            data-testid={platformName}
          />
        </Styles.LogoWrapper>

        {!isMobile && (
          <Styles.Actions>
            <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
          </Styles.Actions>
        )}
      </Styles.Header>
      <Styles.Body>
        {nameServers && nameServers.map((nameServer, index) => nameServer && (
          <Styles.Nameservers key={nameServer}>
            {`${serverText} ${index + 1}: `}
            <span>{nameServer}</span>
          </Styles.Nameservers>
        ))}
      </Styles.Body>
      <Styles.Footer data-testid="attention-message">
        <IconAttentionYellow />
        <Styles.FooterMessage>
          {attentionMessage}
          {externalPlatformAttentionMessage(platformName)}
        </Styles.FooterMessage>
      </Styles.Footer>

      {isMobile && (
        <Styles.Actions>
          <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
        </Styles.Actions>
      )}

    </Styles.DnsWizardPlanCardWrapper>
  );
};

export default DnsWizardConfigCardExternal;
