const styles = theme => ({
  selectFieldTextOn: {
    color: theme.color.regentGray,
  },
  formControl: {
    margin: '6px 8px 5px 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: 12,
    },
  },
  selectCustom: {
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 6,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderRadius: '2px',
    },

    '& span': {
      color: theme.color.indigo,
      marginLeft: '5px',
    },
  },
  selectCustomInput: {},
  selectCustomOutline: {},
  selectCustomFocused: {},
  inputLabelCustom: {
    transform: 'translate(14px, 10px) scale(1)',
    zIndex: 0,
    [theme.breakpoints.down(1148)]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '80%',
    },
  },
  fullWidth: {
    maxWidth: '100%',
  },
  helperText: {
    marginLeft: 13,
    marginTop: 10,
    color: '#f44336',
    fontSize: 12,
  },

  highLightSpan: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',

    '&:active': {
      fontWeight: '500',
    },
    '&:hover': {
      fontWeight: '500',
    },
    '&:focus': {
      fontWeight: '500',
    },

    '& span': {
      color: theme.color.indigo,
      marginLeft: '5px',
    },
  },
});

export default styles;
