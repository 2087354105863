import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, customBreakPoint }) => css`
    align-items: center;
    background-color: ${theme.palette.white.light};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
    padding: 23px 24px 23px 186px;
    position: relative;

    @media (max-width: ${customBreakPoint || theme.breakpoints.sm}){
      display: flex;
      flex-direction: column;
      padding: 23px 24px;
      width: 100%;
    }
  `}
`;

export const ArrowWrapper = styled.div`
  ${({ theme, customArrowBreakPoint }) => css`
    align-items: center;
    display: flex;
    margin: 0px;
    
    @media (max-width: ${customArrowBreakPoint || theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme, customBreakPoint }) => css`
    margin: 0px 16px 0px 36px;
    min-width: 124px;
    
    @media (max-width: ${customBreakPoint || theme.breakpoints.sm}) {
      margin: 24px 0px 0px auto;
    }
  `}
`;

export const TrianglesWrapper = styled.div`
  left: 8px;
  position: absolute;
  top: 8px;
`;

export const SnappyImage = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    height: 40px;
    left: 62px;
    position:absolute;
    width: 90px;

    @media (max-width: ${theme.breakpoints.md}){
      left: 8px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme, maxWidth }) => css`
    color: ${theme.palette.blue.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0px auto 0px 0px;
    max-width: ${maxWidth};

    @media (max-width: ${theme.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 250px;
      text-align:right;
      width: 100%;
    }
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme, maxWidth }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 20px;
    margin: 0px auto 0px 0px;
    max-width: ${maxWidth};
    width: 100%;

    strong {
      color: ${theme.v1.color.action.primary};
      font-weight: ${theme.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 250px;
      text-align:right;
      width: 100%;
    }
  `}
`;
