const styles = theme => ({
  dragText: {
    color: theme.color.regentGray,
    fontSize: '14px',
  },
  dragArea: {
    position: 'relative',
    border: `1px dashed ${theme.color.mystic}`,
    padding: '15px 0 40px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: '0.2s border-color',
    willChange: 'border-color',
    '&.over': {
      borderColor: theme.color.indigo,
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  dialog: {
    width: '500px',
    display: 'flex',
    flex: 'inherit',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.color.tundora,
    margin: '-5px 0 20px 0',
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
  fillBtn: {
    margin: '0 5px',
  },
  fillBtnSend: {
    width: '85.45px',
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.color.tundora,
    cursor: 'pointer',
  },
  btnUpload: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.color.indigo,
    fontSize: '14px',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
  input: {
    display: 'none',
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: '60vh',
  },
  imgPreview: {
    width: '100%',
    padding: '20px 20px 0 20px',
    marginTop: '20px',
  },
  imgBox: {
    position: 'relative',
  },
  uploadingGradient: {
    display: 'flex',
    justfyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  textUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '15px',
  },
  fileWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '60px',
    '& > p': {
      color: theme.color.regentGray,
      fontSize: '12px',
      textAlign: 'center',
    },
  },
});

export default styles;
