/* eslint-disable react/no-danger */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { config } from '@/config';
import { useDispatch, useSelector } from 'react-redux';
import { domainsActions, preChatActions } from '@/redux/modules';
import { isBRServer } from '@/utils/Validators/validation';
import { useParams } from 'react-router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_ZENDESK_V2 } from '@/config/GrowthBook/constants';

const SupportFormVue = () => {
  const formRef = useRef(null);
  const preChatVpsUpgrade = useSelector(state => state.preChat.vpsUpgrade);
  const eppStateData = useSelector(state => state.newDomains.eppKey);
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedSector, setSelectedSector] = useState(false);
  const enableZendeskV2 = useFeatureIsOn(CHARLIE_ZENDESK_V2);
  const summary = useSelector(state => state.summary);

  const preChatAutoFill = useCallback((formData) => {
    if (preChatVpsUpgrade.termsAccepted) {
      const productSelected = JSON.parse(formData.products).find(product => String(product.id) === String(preChatVpsUpgrade.productId));

      if (productSelected) {
        setTimeout(() => {
          const productSelectField = document.querySelector('#form [name="product"]');
          const productOptionSelectedValue = productSelectField.querySelector(`[data-id="${productSelected.id}"]`).value;
          productSelectField.value = productOptionSelectedValue;
          productSelectField.dispatchEvent(new Event('change'));

          setTimeout(() => {
            const sectionSelectButton = document.querySelector('[name="support"][value="1"]');
            sectionSelectButton.checked = true;
            sectionSelectButton.dispatchEvent(new Event('change'));

            setTimeout(() => {
              const subjectSelectField = document.querySelector('[name="salessupport"]');
              const upgradeOption = [...subjectSelectField.querySelectorAll('option')].find(option => option.textContent.toLocaleLowerCase().includes('upgrade'));
              subjectSelectField.value = upgradeOption && upgradeOption.value;
              subjectSelectField.dispatchEvent(new Event('change'));
            }, 100);
          }, 100);
        }, 750);
      }
    }

    if (eppStateData.redirect) {
      const productSelected = eppStateData.domainName;

      if (productSelected) {
        setTimeout(() => {
          const productSelectField = document.querySelector('#form [name="product"]');

          if (isBRServer && productSelectField) {
            const productOptionSelectedValue = [...productSelectField.querySelectorAll('option')].find(selectOption => selectOption.text.includes(productSelected) && selectOption.text.includes('Domínio Internacional'));
            productSelectField.value = productOptionSelectedValue && productOptionSelectedValue.value;
            productSelectField.dispatchEvent(new Event('change'));

            setTimeout(() => {
              const sectionSelectButton = document.querySelector('[name="support"][value="2"]');
              sectionSelectButton.checked = true;
              sectionSelectButton.dispatchEvent(new Event('change'));

              setTimeout(() => {
                const subjectSelectField = document.querySelector('[name="techsupport"]');
                const EPPOption = [...subjectSelectField.querySelectorAll('option')].find(option => option && option.text && option.text.includes('EPP'));
                subjectSelectField.value = EPPOption && EPPOption.value;
                subjectSelectField.dispatchEvent(new Event('change'));
              }, 100);
            }, 100);
          }

          if (!isBRServer && productSelectField) {
            const generalDoubtsOptions = [...productSelectField.querySelectorAll('option')].find(selectOption => selectOption.text === 'Dudas Financieras Generales');
            productSelectField.value = generalDoubtsOptions && generalDoubtsOptions.value;
            productSelectField.dispatchEvent(new Event('change'));

            setTimeout(() => {
              const domainToTransferInput = document.querySelector('textarea[id="ticket-textarea"]');
              if (domainToTransferInput) {
                localStorage.setItem('eppTransferClientarea', true);
                domainToTransferInput.value = `Nombre del dominio: ${eppStateData.domainName}\n\nNecesito la clave EPP para poder transferir mi dominio`;
                domainToTransferInput.dispatchEvent(new Event('change'));
                document.dispatchEvent(new Event('redirect-epp-pre-chat'));
              }
            }, 100);
          }
        }, 750);
      }
    }
  }, [preChatVpsUpgrade, eppStateData]);

  const preChatCleanAutoFillTrigger = useCallback(() => {
    if (preChatVpsUpgrade.termsAccepted) {
      dispatch(preChatActions.vps.productId(null));
      dispatch(preChatActions.vps.termsAccepted(false));
      dispatch(preChatActions.preChat.setOpen(true));
    }

    if (eppStateData.redirect) {
      dispatch(domainsActions.modalEppKey.close());
      // dispatch(preChatActions.preChat.setOpen(true));
    }
  }, [preChatVpsUpgrade, eppStateData, dispatch]);

  useEffect(() => {
    if (summary && summary.id) {
      fetch(`${enableZendeskV2 ? `${config.API_URL}/formconfig_new` : `${config.API_URL}/formconfig`}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
        .then(response => response.json())
        .then((response) => {
          const formData = response.data || response;
          let user = JSON.stringify(summary);
          let { forms, products } = formData;

          if (response.data) {
            forms = JSON.stringify(forms);
            products = JSON.stringify(products);
          }

          if (!response.data) {
            user = formData.client;
          }

          window.createForm(formRef.current, forms, products, user, enableZendeskV2);
          preChatAutoFill(formData);
        });
      return () => {
        preChatCleanAutoFillTrigger();
      };
    }
  }, [preChatAutoFill, preChatCleanAutoFillTrigger, enableZendeskV2, summary]);

  const getSelectOfProducts = useCallback(() => {
    if (params.selectedProduct && !selectedProduct) {
      const selectOfProducts = document.querySelector('#productSelect');
      let selectOfProductsOption = document.querySelector(`#productSelect [value="${params.selectedProduct}"]`);

      if (!selectOfProductsOption) {
        selectOfProductsOption = document.querySelector(`#productSelect [data-id="${params.selectedProduct}"]`);
      }

      if (selectOfProducts) {
        if (selectOfProductsOption) {
          selectOfProductsOption.selected = true;
          selectOfProducts.dispatchEvent(new Event('change'));
          setSelectedProduct(true);
        }
      }
    }
  }, [selectedProduct, params]);

  const setSectorRadio = useCallback(() => {
    if (selectedProduct && params.selectedSector) {
      const sectorRadio = document.querySelectorAll('#sectorRadio');
      const index = params.selectedSector === 'technical' ? 1 : 0;
      sectorRadio[index].checked = true;
      sectorRadio[index].dispatchEvent(new Event('change'));
      setSelectedSector(true);
    }
  }, [selectedProduct, params.selectedSector]);

  useEffect(() => {
    setSectorRadio();
  }, [setSectorRadio]);

  const setNeedOption = useCallback(() => {
    if (selectedSector && params.selectedNeed) {
      const selectOfProducts = document.querySelectorAll('#productSelect');
      const selectOfProductsOption = document.querySelector(`#productSelect [value="${params.selectedNeed}"]`);

      if (selectOfProducts) {
        if (selectOfProductsOption) {
          selectOfProductsOption.selected = true;
          selectOfProducts[1].dispatchEvent(new Event('change'));
        }
      }
    }
  }, [selectedSector, params.selectedNeed]);

  useEffect(() => {
    setNeedOption();
  }, [setNeedOption]);

  useEffect(() => {
    document.addEventListener('DOMNodeInserted', getSelectOfProducts);

    return () => document.removeEventListener('DOMNodeInserted', getSelectOfProducts);
  }, [getSelectOfProducts]);

  return (
    <div
      id="form"
      ref={formRef}
      dangerouslySetInnerHTML={{
        __html:
          '<hg-zendesk-form :form-config="formConfig" :client-products="products" :client-data="clientData" :enableZendeskV2="enableZendeskV2"/>',
      }}
    />
  );
};

export default SupportFormVue;
