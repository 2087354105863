import { AsaasActionTypes } from './asaas.types';
// LOADING
const setLoading = isLoading => ({ type: AsaasActionTypes.SET_LOADING, payload: { isLoading } });

// ACCOUNT CREATION
const createSubAccountRequest = payload => ({ type: AsaasActionTypes.ASAAS_CREATE_SUBACCOUNT_REQUEST, payload });
const failedCreateSubAccountRequest = () => ({ type: AsaasActionTypes.ASAAS_CREATE_SUBACCOUNT_FAILURE });
const succeededCreatSubAccountRequest = () => ({ type: AsaasActionTypes.ASAAS_CREATE_SUBACCOUNT_SUCCESS });
const setAsaaAccountCreateStatus = payload => ({ type: AsaasActionTypes.ASAAS_SET_ACCOUNT_STATUS, payload });

const actions = {
  loading: {
    set: setLoading,
  },
  createAccount: {
    request: createSubAccountRequest,
    failure: failedCreateSubAccountRequest,
    success: succeededCreatSubAccountRequest,
    set: setAsaaAccountCreateStatus,
  },
};

export default actions;
