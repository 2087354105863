import React from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './LiveModal.styles';
import { Button } from 'gatorcomponents';

const LiveModal = ({
  children, showLive, onClose, bannerImage, notShowAgain, notShowAgainLabel,
}) => (
  <Modal
    maxWidth={800}
    maxHeight={440}
    testId="live-modal"
    disablePadding
    onCloseButtonAction={onClose}
    whiteIcon
  >
    <Styles.Content
      onClick={showLive}
      bannerImage={bannerImage}
    >
      <Styles.NotShowAgain>
        <Button
          label={notShowAgainLabel}
          onClick={notShowAgain}
          variant="tertiary"
          size="small"
        />
      </Styles.NotShowAgain>
      {children}
    </Styles.Content>
  </Modal>
);
export default LiveModal;
