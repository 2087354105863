import { billingActions } from '@/redux/modules/billing';
import store from '@/redux/store';
import { billingAnalytics } from '@/analytics';
import { locale } from '@/utils/locale';
import { SUBSCRIPTIONS_SUBSTATUS } from '@/enums/billing/billing.enum';
import { subscriptionsLocale } from './SubscriptionsHandler.locale';

const { dispatch } = store;
const { subscriptions: subscriptionAnalytics } = billingAnalytics;

// ANALYTICS
const onHandleManageSubscription = (obj, history) => {
  const { id, type } = obj;
  subscriptionAnalytics.manageSubscriptionClick();
  history.push(`${locale('routes.subscriptions')}${locale('routes.manageSubscription')}/${id}/${type}`);
};

// SUBSCRIPTION ACTIONS
const onHandleSubscriptionsLoad = () => {
  dispatch(billingActions.subscriptions.request());
};

const handleActivateSubscription = (subscription, setModalById = null) => {
  subscriptionAnalytics.cancelSubscriptionClick('5_modal_ativar_cancelar_renovacao');
  dispatch(billingActions.subscriptions.activateRenewal.request(subscription, setModalById));
};

const handleSwitchPaymentMethod = (subscription, paymentMethod, onSuccess) => {
  dispatch(billingActions.subscriptions.switchPaymentMethod.request(subscription, paymentMethod, onSuccess));
};


// Modal
const handleCloseRenewModal = (setActivateSubscription) => {
  subscriptionAnalytics.cancelSubscriptionClick('5_modal_fechar_cancelar_renovacao');
  setActivateSubscription(null);
};

const handleOnModalClose = () => {
  subscriptionAnalytics.cancelSubscriptionClick('5_modal_fechar_cancelar_renovacao');
  dispatch(billingActions.subscriptions.cancel.set({ modal: null }));
};

// FILTER
const sortByStatus = (subscriptions) => {
  const sortedList = [];
  Object.values(SUBSCRIPTIONS_SUBSTATUS)
    .forEach((substatus) => {
      [...subscriptions]
        .filter(subscription => subscription.substatus === substatus)
        .sort((subscriptionA, subscriptionB) => (subscriptionA.productName > subscriptionB.productName ? 1 : -1))
        .forEach(subscription => sortedList.push(subscription));
    });

  return sortedList;
};

const createInitialFilterItem = () => ({
  id: 0,
  label: 'Todas',
  value: 'all',
  checked: true,
});

const mountFilteredOptions = (filteredOptions, subscriptionsItems, setFilteredOptions) => {
  if (!filteredOptions.length && subscriptionsItems.length) {
    const options = [];
    const sortedList = sortByStatus(subscriptionsItems);

    sortedList.forEach((subscription) => {
      if (!options.length) {
        options.push(createInitialFilterItem());
      }

      let optionExists = options.find(option => option.value === subscription.substatus);
      const isActive = subscription.substatus === SUBSCRIPTIONS_SUBSTATUS.TO_DUE && subscription.cancelType;
      if (isActive) {
        optionExists = options.find(option => option.value === SUBSCRIPTIONS_SUBSTATUS.ACTIVE);
      }

      if (!optionExists) {
        const status = isActive ? subscription.status.toLowerCase() : subscription.substatus;

        options.push({
          id: options.length,
          label: subscriptionsLocale[`${status}StatusLabel`],
          value: status,
          checked: false,
        });
      }
    });

    setFilteredOptions(options);
  }
};

const clearFilter = (filteredOptions, setFilteredOptions, setFilterTyped) => {
  const options = [...filteredOptions]
    .map((option, index) => ({ ...option, checked: index === 0 }));

  setFilteredOptions(options);
  setFilterTyped('');
};

const handleOnFilterClick = (options) => {
  subscriptionAnalytics.subscriptionsFilterClick(options);
};


const functions = {
  clearFilter,
  handleActivateSubscription,
  handleCloseRenewModal,
  handleOnFilterClick,
  handleOnModalClose,
  mountFilteredOptions,
  onHandleManageSubscription,
  onHandleSubscriptionsLoad,
  handleSwitchPaymentMethod,
  sortByStatus,
};

export default functions;
