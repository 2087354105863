import React, { useState } from 'react';
import * as Styles from './DomainItem.styles';
import {
  Alert,
  Button, IconDomain, Tag, Tooltip,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { useHistory } from 'react-router';
import { useInvoice } from '@/hooks/domains/useInvoice';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { locale } from '@/utils/locale';
import {
  CREDIT_CARD, BRASPAG_PARC_1X, BRASPAG_PARC_2X, BRASPAG_PARC_3X, BRASPAG_PARC_4X, BRASPAG_PARC_5X,
  BRASPAG_PARC_6X, BRASPAG_PARC_7X, BRASPAG_PARC_8X, BRASPAG_PARC_9X, BRASPAG_PARC_10X,
  BRASPAG_PARC_11X, BRASPAG_PARC_12X, CREDIT_CARD_ES, LIST_BOLETO_METHODS, LIST_PAYPAL_METHODS,
} from '@/config/billing/paymentMethods';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD, ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES } from '@/config/GrowthBook/constants';
import { INVOICE_STATUS, INVOICE_TYPE, PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { isBRServer, isMobile } from '@/utils/Validators/validation';
import { domainsActions } from '@/redux/modules';
import { domainsAnalytics } from '@/analytics';
import ModalReactivateAutomaticSubscription from '@/components/Modal/ModalReactivateAutomaticSubscription';
import { handleDomainRenewal } from '@/redux/actions/domains';
import { formatDate } from '@/utils/Formatters/formatDate';
import { GET_A_NEW_DOMAIN } from '@/config/urls/supportUrls';
import { DOMAIN_STATUS } from '../../DomainsListHandler.types';
import { usePropagationStatus } from '@/hooks/domains/usePropagationStatus';
import { useBillingStatus } from '@/hooks/domains/useBillingStatus';

const DomainItem = ({ domain, statusTag, firstItem }) => {
  const [modalReactivation, setModalReactivation] = useState(false);
  const [isLoadingAction, setLoadingAction] = useState(false);

  const shouldRenderDnsWizardButtonBr = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD);
  const shouldRenderDnsWizardButtonEs = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES);
  const shouldRenderDnsWizardButton = shouldRenderDnsWizardButtonBr || shouldRenderDnsWizardButtonEs;

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;

  const {
    loading: isLoadingGenerateNewInvoice,
  } = useSelector(state => state.newDomains.domains.generateNewInvoice);

  const history = useHistory();
  const { generate } = useInvoice();
  const dispatch = useDispatch();
  const propagationStatus = usePropagationStatus(domain.domain);
  const { getIsDueDate, getIsOverDue, getIsNextDueDate } = useBillingStatus();
  const { domains: domainsLocale } = useLocale();
  const domainLocale = domainsLocale.domainsListPage;

  const today = new Date();
  const nextDueDate = formatDate(domain.nextDueDate);
  const expiryDate = formatDate(domain.expiryDate);
  const dueDate = getIsDueDate(domain);
  const daysToCancel = differenceInCalendarDays(addDays(dueDate, 31), today);

  const productIsActive = domain.status === 'active';
  const productIsExpired = domain.status === 'expired';
  const isCancelled = domain.status === 'cancelled' && (domain.cancelReason == null || domain.cancelReason === '');
  const domainExpired = productIsExpired && domain.isGracePeriod;
  const isOverDue = getIsOverDue(domain);
  const isNextDueDate = getIsNextDueDate(domain);
  const hasReasonToCancel = domain.cancelReason != null && domain.cancelReason !== '';
  const valueDisabledRenovation = 1;
  const disabledAutoRenovation = domain.doNotRenew === valueDisabledRenovation && productIsActive;

  const dotBr = domain && domain.domain.includes('.com.br');
  const disableManage = ['expired', 'pending', 'registerError', 'pendingTransfer'].includes(domain.status);
  const domainCharsLimit = isMobile ? 22 : 25;
  const showToolTip = domain && domain.domain.indexOf('.') > domainCharsLimit;
  const displayNewMessageImprovements = (disabledAutoRenovation
    || (domain.invoiceType === INVOICE_TYPE.RENEWAL && domain.invoiceStatus === INVOICE_STATUS.CANCELLED)
    || (domain.invoiceType === INVOICE_TYPE.REGISTRATION && (isNextDueDate || isOverDue || domainExpired))
    || (domain.invoiceId === 0));
  const displayStatusTag = !(['nextDueDate', 'overDueDate'].includes(statusTag));

  const propagationStatusText = domainLocale && domainLocale.propagationAlerts(propagationStatus, dotBr);
  const hasNoRestrictions = propagationStatus
    && (propagationStatus !== PROPAGATION_STATUS.NOT_CONTRACTED)
    && (propagationStatus !== PROPAGATION_STATUS.NOT_CONFIGURED)
    && (propagationStatus !== PROPAGATION_STATUS.NOT_PROPAGATED);
  const validPropagationStatus = !!propagationStatus && !!propagationStatusText && hasNoRestrictions;

  const canManageDns = productIsActive && !isNextDueDate && !isOverDue && !disabledAutoRenovation && !domainExpired;

  const creditCardPaymentForms = [
    CREDIT_CARD_ES, CREDIT_CARD, BRASPAG_PARC_1X,
    BRASPAG_PARC_2X, BRASPAG_PARC_3X,
    BRASPAG_PARC_4X, BRASPAG_PARC_5X,
    BRASPAG_PARC_6X, BRASPAG_PARC_7X,
    BRASPAG_PARC_8X, BRASPAG_PARC_9X,
    BRASPAG_PARC_10X, BRASPAG_PARC_11X,
    BRASPAG_PARC_12X];

  const isCreditCard = () => creditCardPaymentForms.includes(domain.paymentMethod);
  const isBoleto = () => LIST_BOLETO_METHODS.includes(domain.paymentMethod);
  const isPayPal = () => LIST_PAYPAL_METHODS.includes(domain.paymentMethod);

  const typePayment = () => {
    if (domain.paymentMethod && domain.paymentMethod.includes('boleto')) {
      return isBRServer ? 'boleto' : 'payu_boleto';
    }
    return domain.paymentMethod;
  };

  const invoiceUrl = id => `${locale('routes.invoices')}${locale('routes.unpaid')}/${id}/${typePayment()}`;

  const brokeDomain = (domain) => {
    if (domain.indexOf('.') > domainCharsLimit) {
      const split = domain.split('.');
      const second = split[2] ? `.${split[2]}` : '';
      const dotDomain = `${split[0].substring(0, domainCharsLimit)}...[${split[1]}${second}]`;
      return dotDomain;
    }
    return domain;
  };

  const reloadListDomains = () => {
    dispatch(domainsActions.domains.request());
  };

  const redirectToInvoice = (idInvoice) => {
    history.push(invoiceUrl(idInvoice));
  };

  const redirectToGeneratedInvoice = () => {
    generate(domain, redirectToInvoice);
  };

  const handleManageDomain = () => {
    const url = `${locale('routes.domains')}${locale('routes.domaindetail')}/${domain.id}`;
    history.push(url);
  };

  const handleConfigureDomain = () => {
    const url = `${locale('routes.domains')}${locale('routes.dnsWizard')}/${domain.id}`;
    history.push(url);
    dnsWizardAnalytics.dnsWizardButtonClick();
  };

  const handleOpen = async () => {
    setModalReactivation(true);
  };

  const handleActivateSubscription = () => {
    setLoadingAction(true);

    const dataDomain = {
      id: domain.id,
      donotrenew: false,
      name: domain.domain,
    };

    dispatch(domainsActions.domains.activateRenewal.request(dataDomain, reloadListDomains)).then((response) => {
      dispatch(handleDomainRenewal(response.payload.domainData));
      setLoadingAction(false);
    });
  };

  const tagRules = {
    [DOMAIN_STATUS.PENDING]: {
      label: domainLocale.statusProductDomain.pending,
      variant: 'attention',
    },
    [DOMAIN_STATUS.ACTIVE]: {
      label: domainLocale.statusProductDomain.active,
      variant: 'active',
    },
    [DOMAIN_STATUS.CANCELLED]: {
      label: domainLocale.statusProductDomain.cancelled,
      variant: 'cancel',
    },
    [DOMAIN_STATUS.PENDING_TRANSFER]: {
      label: domainLocale.statusProductDomain.pendingTransfer,
      variant: 'pending',
    },
    [DOMAIN_STATUS.REGISTER_ERROR]: {
      label: domainLocale.statusProductDomain.registerError,
      variant: 'problem',
    },
    [DOMAIN_STATUS.EXPIRED]: {
      label: domainLocale.statusProductDomain.expired,
      variant: 'problem',
    },
    [DOMAIN_STATUS.NEXT_DUE_DATE]: {
      label: domainLocale.statusProductDomain.nextDueDate,
      variant: 'pending',
    },
    [DOMAIN_STATUS.OVERDUE_DATE]: {
      label: domainLocale.statusProductDomain.overDueDate,
      variant: 'problem',
    },
  };

  const mountTag = ({ label, variant = 'active' }) => (
    <Styles.TagContainer>
      <Tag label={label} rounded={false} variant={variant} />
    </Styles.TagContainer>
  );

  const alertRules = {
    disabled_auto_renew: {
      label: domainLocale.alerts.disabled_auto_renew(nextDueDate, handleOpen),
      variant: 'danger',
    },
    pending_domain: {
      label: domainLocale.alerts.pending_domain,
      variant: 'warning',
    },
    next_due_invoice: {
      label: domainLocale.alerts.next_due_invoice(nextDueDate, invoiceUrl(domain.invoiceId)),
      variant: 'warning',
    },
    next_due_date_credit_card: {
      label: domainLocale.alerts.next_due_date_credit_card(nextDueDate),
      variant: 'warning',
    },
    next_due_date_paypal: {
      label: domainLocale.alerts.next_due_date_paypal(nextDueDate),
      variant: 'warning',
    },
    due_date_disabled_auto_renovation: {
      label: domainLocale.alerts.due_date_disabled_auto_renovation(nextDueDate, redirectToGeneratedInvoice, isLoadingGenerateNewInvoice),
      variant: 'warning',
    },
    due_date_invoice: {
      label: domainLocale.alerts.due_date_invoice(nextDueDate, daysToCancel, invoiceUrl(domain.invoiceId)),
      variant: 'danger',
    },
    overdue_disabled_auto_renew: {
      label: domainLocale.alerts.overdue_disabled_auto_renew(expiryDate, redirectToGeneratedInvoice, isLoadingGenerateNewInvoice),
      variant: 'danger',
    },
    expired_invoice: {
      label: domainLocale.alerts.expired_invoice(expiryDate, invoiceUrl(domain.invoiceId)),
      variant: 'danger',
    },
    expired_grace_period_overdue: {
      label: domainLocale.alerts.expired_grace_period_overdue(expiryDate),
      variant: 'danger',
    },
    expired_on_grace_period: {
      label: domainLocale.alerts.expired_on_grace_period(redirectToGeneratedInvoice),
      variant: 'danger',
    },
    domain_cancelled_by_tos: {
      label: domainLocale.alerts.domain_cancelled_by_tos,
      variant: 'danger',
    },
    cancelled_unrecoverable: {
      label: domainLocale.alerts.cancelled_unrecoverable(expiryDate, GET_A_NEW_DOMAIN),
      variant: 'danger',
    },
    cancelled_recoverable: {
      label: domainLocale.alerts.cancelled_recoverable(expiryDate, GET_A_NEW_DOMAIN),
      variant: 'danger',
    },
  };

  const getMessageWarningToNextDueDate = () => {
    if (!domain.paymentMethod) {
      return;
    }

    if (isBoleto()) {
      return alertRules.next_due_invoice;
    }
    if (isCreditCard()) {
      return alertRules.next_due_date_credit_card;
    }
    if (isPayPal()) {
      return alertRules.next_due_date_paypal;
    }

    if (displayNewMessageImprovements) {
      return alertRules.due_date_disabled_auto_renovation;
    }
  };

  const getMessageWarningDueDate = () => {
    if (!displayNewMessageImprovements) {
      return alertRules.due_date_invoice;
    }
    return alertRules.overdue_disabled_auto_renew;
  };

  const getMessageAutoRenovation = () => alertRules.disabled_auto_renew;

  const getMessageExpired = () => {
    const expiredOnGracePeriod = (domain.doNotRenew === valueDisabledRenovation || (domain.gracePeriod && !domain.isGracePeriod && (domain.invoiceType !== INVOICE_TYPE.RECOVERY)));

    if (!domain.gracePeriod) {
      return alertRules.expired_grace_period_overdue;
    }
    if (expiredOnGracePeriod) {
      return alertRules.expired_on_grace_period;
    }
    return alertRules.expired_invoice;
  };

  const getMessageCancelled = () => {
    if (hasReasonToCancel) {
      return alertRules.domain_cancelled_by_tos;
    }
    if (!hasReasonToCancel && domain.doNotRenew && !domain.isGracePeriod) {
      return alertRules.cancelled_unrecoverable;
    }
    return alertRules.cancelled_recoverable;
  };

  const mountAlert = ({ label, variant = 'info' }) => {
    const iconRules = {
      info: 'IconInfo',
      pending: 'IconClock',
      warning: 'IconDanger',
      danger: 'IconDanger',
    };

    return (
      <Styles.Alert>
        <Alert
          description={label}
          variant={variant === 'danger' ? 'error' : variant}
          centerIcon={!isMobile}
          withoutBackground
          customIcon={iconRules[variant]}
        />
      </Styles.Alert>
    );
  };

  return (
    <Styles.Wrapper firstItem={firstItem}>
      <ModalReactivateAutomaticSubscription
        onClose={() => setModalReactivation(false)}
        open={modalReactivation}
        handleActivateSubscription={handleActivateSubscription}
        domain="domain"
        loading={isLoadingAction}
        title={domainLocale.modalDomainAutoRenovation.title}
        description={domainLocale.modalDomainAutoRenovation.description}
        confirmLabel={domainLocale.modalDomainAutoRenovation.confirmLabel}
      />
      <Styles.LeftContainer>
        <Styles.FlexWrap>
          <Styles.DomainContainer>
            <IconDomain size="sm" />
            <Styles.DomainLabel href={`https://${domain.domain}`} target="_blank">
              {!showToolTip ? brokeDomain(domain.domain) : (
                <Tooltip content={domain.domain}>
                  {brokeDomain(domain.domain)}
                </Tooltip>
              )}
            </Styles.DomainLabel>
          </Styles.DomainContainer>
          <Styles.TagsWrapper>
            {displayStatusTag && mountTag(tagRules[statusTag])}
            {validPropagationStatus && mountTag({ label: propagationStatusText.labelTag, variant: 'pending' })}
          </Styles.TagsWrapper>
        </Styles.FlexWrap>
        <Styles.ColumnWrap>
          {isNextDueDate && !isOverDue && mountAlert({ ...getMessageWarningToNextDueDate() })}
          {isOverDue && mountAlert({ ...getMessageWarningDueDate() })}
          {productIsActive && disabledAutoRenovation && !isOverDue && !isNextDueDate && mountAlert({ ...getMessageAutoRenovation() })}
          {productIsExpired && mountAlert({ ...getMessageExpired() })}
          {(isCancelled || hasReasonToCancel) && mountAlert({ ...getMessageCancelled() })}
          {validPropagationStatus && mountAlert({ label: propagationStatusText.labelDomainsDetails, variant: 'info' })}
        </Styles.ColumnWrap>
      </Styles.LeftContainer>
      <Styles.ButtonsContainer>
        {shouldRenderDnsWizardButton && canManageDns && <Button label={domainLocale.configureButtonLabel} variant="secondary" onClick={handleConfigureDomain} />}
        {domain.status !== 'cancelled' && (
        <Button label={domainLocale.manageButtonLabel} disabled={disableManage} onClick={handleManageDomain} />
        )}
      </Styles.ButtonsContainer>
    </Styles.Wrapper>
  );
};

export default DomainItem;
