import React from 'react';
import * as Styles from './WizardWondersuiteFeatures.styles';
import {
  IconDetailedCart, IconDetailedFilters, IconDetailedGiftCertificate, IconDetailedMyAccount, IconDetailedSchedules, IconDetailedWishlist,
} from '@/icons';
import snappy from '@/media/siteBuilder/woondersuite/snappy.svg';
import { Slider } from '@/pages/common/Slider';
import { useResize } from '@/hooks/useResize';

const WizardWondersuiteFeatures = ({
  items = [],
}) => {
  const view = useResize();
  const isMobile = view.width < 960;

  const IconDictionary = {
    myAccount: <IconDetailedMyAccount />,
    cart: <IconDetailedCart />,
    filters: <IconDetailedFilters />,
    wishlist: <IconDetailedWishlist />,
    giftCertificate: <IconDetailedGiftCertificate />,
    schedules: <IconDetailedSchedules />,
  };

  const settings = {
    initialSlide: 0,
    slidesToShow: 1,
    speed: 500,
    dots: false,
    infinite: false,
    arrows: true,
    slidesToScroll: 1,
  };

  const itemsMap = items.map(item => (
    <Styles.Card>
      <Styles.IconWrapper>
        {IconDictionary[item.icon]}
      </Styles.IconWrapper>
      <Styles.InfoWrapper>
        <Styles.Title>
          {item.title}
        </Styles.Title>
        <Styles.Description>
          {item.description}
        </Styles.Description>
      </Styles.InfoWrapper>
    </Styles.Card>
  ));

  return (
    <>
      <Styles.Wrapper data-testid="woondersuite-features">
        {isMobile && (
          <Slider
            settings={settings}
            items={itemsMap}
          />
        )}
        {!isMobile && itemsMap}
        <Styles.Snappy src={snappy} />
        <Styles.FullWidthBorder />
      </Styles.Wrapper>
    </>
  );
};

export default WizardWondersuiteFeatures;
