import React, { useState } from 'react';
import * as Styles from './LinkNaBioStep3.styles';
import { IconDashboard, Button, IconCheck } from 'gatorcomponents';
import { Preview } from '../Preview';
import snappyDesktop from '@/media/siteBuilder/snappy-desktop.svg';
import snappyMobile from '@/media/siteBuilder/snappy-mobile.svg';
import { colorSchemes, templates } from '../../LinkNaBioWizardHandler.enum';
import withErrorBoundary from '@/utils/errorBoundary';

const templateTypes = Object.values(templates);
const colorSchemeTypes = Object.values(colorSchemes);

const LinkNaBioStep3 = ({
  testId = 'link-na-bio-step-3',
  prevStep = () => null,
  stepLocale = {},
  previewData = {
    links: [],
    title: '',
    description: '',
    emoji: undefined,
    socialMediaLinks: [],
  },
  stepData = {
    colorScheme: colorSchemes.SIMPLE,
    template: templates.FUNNY,
  },
  setStepData = () => null,
  handleNextStep = () => null,
  linkNaBioLoading = false,
  isEditing = false,
  setFieldUpdate = () => null,
  onMobile = false,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const updateStepData = (name, value) => {
    const newStepData = { ...stepData };
    newStepData[name] = value;
    setStepData(newStepData);

    if (isEditing) {
      setFieldUpdate(true);
    }
  };

  const handleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      {(!onMobile || (onMobile && !showPreview)) && (
        <Styles.Content>
          <IconDashboard />
          <Styles.Title>
            {stepLocale.customize}
          </Styles.Title>

          <Styles.Subtitle>
            {stepLocale.colorScheme}
          </Styles.Subtitle>

          <Styles.ColorForm>
            {colorSchemeTypes.map(colorScheme => (
              <Styles.ColorPill
                key={colorScheme}
                htmlFor={colorScheme}
                variant={colorScheme}
                onClick={() => updateStepData('colorScheme', colorScheme)}
                defaultChecked={stepData.colorScheme === colorScheme}
              >
                {stepData.colorScheme === colorScheme && (
                  <Styles.IconCheck pill>
                    <IconCheck color="primaryMedium" />
                  </Styles.IconCheck>
                )}
                <Styles.Radio
                  type="radio"
                  id={colorScheme}
                  name={colorScheme}
                  value={colorScheme}
                  defaultChecked={stepData.colorScheme === colorScheme}
                  data-testid={colorScheme}
                />
              </Styles.ColorPill>
            ))}
          </Styles.ColorForm>


          <Styles.Subtitle>
            {stepLocale.template}
          </Styles.Subtitle>


          <Styles.TemplateForm>
            {templateTypes.map(templateType => (
              <Styles.TemplateWrapper
                htmlFor={templateType}
                key={templateType}
                onClick={() => updateStepData('template', templateType)}
              >
                <Styles.TemplateCard
                  variant={stepData.colorScheme}
                  defaultChecked={stepData.template === templateType}
                >
                  {stepData.template === templateType && (
                    <Styles.IconCheck>
                      <IconCheck color="primaryBlue" />
                    </Styles.IconCheck>
                  )}
                  <Styles.Radio
                    type="radio"
                    id={templateType}
                    name={templateType}
                    value={templateType}
                    defaultChecked={stepData.template === templateType}
                    data-testid={templateType}
                  />
                  <Styles.TemplateHeader
                    template={templateType}
                  >
                    <Styles.TemplateRow variant={stepData.colorScheme} size="title" templateType={templateType} />
                    <Styles.TemplateRow variant={stepData.colorScheme} size="description" templateType={templateType} />
                  </Styles.TemplateHeader>

                  <Styles.TemplateBody>
                    <Styles.TemplateRow variant={stepData.colorScheme} size="row" templateType={templateType} />
                    <Styles.TemplateRow variant={stepData.colorScheme} size="row" templateType={templateType} />
                    <Styles.TemplateRow variant={stepData.colorScheme} size="row" templateType={templateType} />
                  </Styles.TemplateBody>

                </Styles.TemplateCard>
                <Styles.TemplateName>
                  {stepLocale[templateType]}
                </Styles.TemplateName>
              </Styles.TemplateWrapper>
            ))}
          </Styles.TemplateForm>

          <Styles.ButtonRow>
            <Styles.ButtonWrapper>
              <Button
                label={stepLocale.publish}
                size={onMobile ? 'medium' : 'large'}
                onClick={handleNextStep}
                loading={linkNaBioLoading}
              />
            </Styles.ButtonWrapper>

            {onMobile && (
              <Styles.ButtonWrapper>
                <Button
                  label={stepLocale.previewButton}
                  variant="secondary"
                  onClick={handleShowPreview}
                />
              </Styles.ButtonWrapper>
            )}
          </Styles.ButtonRow>

          <Styles.BackButton>
            <Button
              label={stepLocale.back}
              size="large"
              variant="tertiary"
              onClick={prevStep}
            />
          </Styles.BackButton>


        </Styles.Content>
      )}

      {(!onMobile || (onMobile && showPreview)) && (
        <Preview
          handleShowPreview={handleShowPreview}
          stepLocale={stepLocale}
          description={previewData.description}
          title={previewData.emoji ? `${previewData.emoji} ${previewData.title}` : previewData.title}
          links={previewData.links}
          variant={stepData.colorScheme}
          templateType={stepData.template}
          allowMobile
          onMobile={onMobile}
          socialMedia={previewData.socialMediaLinks}
        />
      )}
      <Styles.Snappy src={onMobile ? snappyMobile : snappyDesktop} alt="snappy" data-testid="snappy-image" />
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(LinkNaBioStep3, 'LinkNaBioStep3');
