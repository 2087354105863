import billing from './billing';
import domains from './domains';
import routes from './routes';
import sites from './sites';
import phoneValidation from './phoneValidation';
import emailValidation from './emailValidation';
import menu from './menu';
import title from './title';
import linkNaBio from './linkNaBio';

export default {
  appTitle: 'Portal del Cliente',
  services: 'Servicios',
  activeSsl: 'Activar Certificado SSL',
  more: 'Ver más',
  morev2: 'Ver más contenido de Ayuda.',
  showInvoices: 'Ver Facturas',
  nextDueDate: 'Vencimiento',
  autoRenewal: 'Renovación automática',
  no: 'No',
  yes: 'Sí',
  registerDate: 'Fecha de registro',
  status: 'Status',
  changeDNS: 'Cambiar DNS',
  notifyFailMessage: 'La operación solicitada ha fallado',
  notifySuccessMessage: 'Operación realizada con éxito',
  manageSubscriptionMessage01: 'Ahora las informaciones de su suscripción, como pago y cancelación, estaran disponibles a través del botón ',
  manageSubscriptionMessage02: 'Administrar Suscripción',
  manageSubscriptionMessage03: ' que se encuentra arriba.',
  dialogRegisterAndUseNewCard: 'Registrar y utilizar una nueva tarjeta',
  useExistingCard: 'Utilizar tarjeta guardada',
  useNewCard: 'Utilizar una Nueva Tarjeta',
  modalbetaDescription: 'Este Portal del Cliente aún se encuentra en versión beta, y por lo tanto, algunas páginas no están 100% listas. Al acceder a determinadas funcionalidades serás redireccionado a nuestra versión anterior.',
  actions: {
    ssl: {
      active: 'Certificado SSL',
      inactive: 'Certificado SSL',
    },
    webpresence: {
      active: 'Página Fácil',
      inactive: 'Crear Página Fácil',
    },
    webmail: {
      active: 'E-mail',
      inactive: 'E-mail',
    },
    cpanel: {
      active: 'cPanel',
      inactive: 'cPanel',
    },
    sitelock: {
      active: 'SiteLock',
      inactive: 'SiteLock',
    },
    websitebuilder: {
      active: 'Editar Sitio Web',
      inactive: 'Activar Creador de Sitios',
      access: 'Acesso Creador de Sitios',
      remove: 'Eliminar sitio',
    },
    'websitebuilder-active-edit': {
      active: 'Editar Sitio Web',
      inactive: 'Activar Creador de Sitios',
    },
    'websitebuilder-on-shared-plans': {
      active: 'Editar Sitio Web',
    },
    'manage-site': {
      active: 'Administrar Creador de Sitios Web',
    },
    'create-site': {
      active: 'Crear Sitio Web',
    },
    'cpanel-pwd-edit': {
      active: 'Modificar contraseña del cPanel',
    },
    'plan-switch': {
      active: 'Modificar Plan / Ciclo',
    },
    'old-reseller-plan-switch': {
      active: 'Escoger un Nuevo Plan',
    },
    'product-details': {
      active: 'Detalles de productos',
    },
    whm: {
      active: 'Panel WHM',
    },
    'whm-reseller': {
      access: 'Panel WHM',
      active: 'Panel WHM',
    },
    'whm-plesk': {
      active: 'Panel Plesk',
    },
    'edit-dns': {
      active: 'Configurar Dominio',
    },
    wizard: {
      active: 'Configurar Dominio',
    },
    wphosting: {
      active: 'WordPress',
    },
    domainLink: {
      active: 'Acceder al Sitio Web',
    },
    codeguard: {
      active: 'Acceder CodeGuard',
    },
    'cpanel-email': {
      active: 'Correos Electrónicos',
    },
    'cpanel-addon': {
      active: 'Dominios Adicionales',
    },
    'cpanel-backup': {
      active: 'Backup',
    },
    cloudflare: {
      active: 'Rendimiento de sitios',
    },
    'cpanel-dns': {
      active: 'Zona DNS',
    },
    addEmail: {
      active: 'Correos Electrónicos',
    },
    'contact-info': {
      active: 'Editar Infos Contacto',
    },
    'domain-renewal': {
      active: 'Renovar Dominio',
    },
    'domain-protected-id': {
      active: 'Protect ID',
    },
    'domain-register-lock': {
      active: 'Bloqueo de Registro',
    },
    'vps-reboot': {
      active: 'Reboot',
    },
    dataAccess: {
      active: 'Cambiar Contraseña de Acceso',
    },
    'sites-cloudflare': 'Rendimiento de sitios',
    'sites-gerenciar': 'Administrar alojamiento web',
    'sites-backup': 'Copia de seguridad',
    'sites-exclude': 'Desactivar el Creador de Sitios',
    'sites-easyPage': 'Página Fácil',
    'sites-signatures': 'Ver suscripciones vinculadas al sitio',
    'sites-ssl': 'Certificado SSL',
    'sites-sitelock': '',
    'sites-actions-wordpress': 'Acceder a WordPress',
    'sites-actions-sitebuilder': 'Acceder al Creador de Sitios',
    'sites-actions-reseller': 'Panel WHM',
  },
  hg_credits: 'Créditos HostGator',
  dlocal: 'Tarjeta de Crédito',
  creditCard: 'Tarjeta de Crédito',
  dlocalcash: 'Cupón de Pago',
  payu_boleto: 'Cupón de Pago',
  dlocal_boleto: 'dLocal Boleto',
  boleto: 'Efectivo',
  paypal: 'PayPal',
  gp_paypal: 'PayPal',
  payu: 'none',
  invoiceAction: {
    dlocal: 'Pagar con tarjeta',
    creditCard: 'Pagar con tarjeta',
    dlocalcash: 'Generar cupón',
    payu_boleto: 'Generar cupón',
    dlocal_boleto: 'Generar cupón',
    boleto: 'Generar cupón',
    paypal: 'Pagar con PayPal',
    gp_paypal: 'Pagar con Paypal',
    pay_invoice: 'Pagar Factura',
  },
  month: 'Mes',
  year: 'Año',
  save: 'Guardar',
  cancel: 'Cancelar',
  backToList: 'Volver a la lista',
  clean: 'Borrar',
  removeCreditCard: 'Eliminar tarjeta de crédito',
  creditCardExpDate: 'Vence el',
  savedCreditCard: 'Tarjeta Registrada',
  createCreditCard: 'Registrar Tarjeta de Crédito',
  saveCreditCard: 'Guardar',

  errorSavingCreditCard: 'Error al guardar tarjeta de crédito',
  showReceiptAction: 'Ver Factura',
  payInvoiceAction: 'Pagar',
  payExpiredInvoiceAction: 'Pagar factura vencida',
  unpaidInvoicesEmptyPageSubtitle: 'Por el momento no tienes facturas pendientes :)',
  paidInvoicesEmptyPageSubtitle: 'No tienes facturas pagadas hasta el momento :)',
  processingSelectedPaymentPleaseWait: 'Procesando el método de pago elegido. Por favor, no cierre esta ventana.',
  selectCreditCardInstallments: 'Seleccione el número de cuotas',
  creditCardInstallmentsQuestion: '¿En cuántas cuotas quieres pagar?',
  creditCardInstallmentsTooltipDescription: '<1>Las opciones de pago se definen de acuerdo al valor del producto y su ciclo de pago. Ver más en:</1> <2>Reglas de Cuotas</2>',
  creditCardInstallmentsTooltipDescriptionLink: 'https://soporte-latam.hostgator.com',
  creditCardInstallmentLabel: '{{installments}}x de {{installmentValue}}{{fees}}{{totalValue}}',
  creditCardInstallmentFees: '(con tarifa)',
  creditCardInstallmentsPopupTitle: '¡Ahora puedes dividir tu factura con tu tarjeta de crédito!',
  creditCardInstallmentsPopupKnowMoreURL: 'https://soporte-latam.hostgator.com',
  cancelledInvoicesEmptyPageSubtitle: 'No tienes facturas anuladas hasta el momento :)',
  Unpaid: 'Pendiente',
  Paid: 'Pagada',
  Cancelled: 'Anulada',
  invoiceDueDateLabel: 'Vencimiento',
  invoiceTotalLabel: 'Valor Total',
  selectPaymentMethod: 'Seleccione la forma de pago',
  domainCyclePayment: 'Ciclo de pago',
  invoicePaymentMethod: 'Forma de pago',
  invoiceDescription: 'Descripción',
  invoiceValue: 'Valor ($)',
  invoiceCredit: 'Crédito',
  invoiceSubtotal: 'Sub total',
  invoiceTaxes: 'Impuestos',
  invoicePaidValue: 'Ya fue pagado',
  invoiceUnpaidValue: 'Saldo a Pagar',
  invoiceTotalUnpaidValue: 'Valor a Pagar',
  invoiceFisrtPayment: 'Valor del primer pago',
  invoiceValueReminiscent: 'Valor',
  invoiceYouHave: 'Usted tiene',
  invoiceToPayWithTotal: 'factura (s) para pagar con un total de',
  invoiceStaticMessage: 'Tienes <1>{{ totalInvoice }} factura(s) pendientes para pagar</1> con un <3>total del </3>',
  domainStaticMessage: '¡Evite la suspensión temporal de su sitio web!  Haga <1>clic aquí</1> para confirmar su dirección de correo electrónico y evitar la suspensión.',
  domainCritialMessage: 'Usted tiene uno o más sitios web suspendidos temporalmente. Haga <1>clic aquí</1> para confirmar su dirección de correo electrónico y reactivar su sitio web.',
  myAccount: 'Mi Cuenta',
  myAccountAlertMessage: 'En esta nueva versión, todavía estamos creando las páginas de su cuenta (Mis Datos, Modificar Contraseña, Administrar Usuarios). Si desea modificar algo, clic abajo :)',
  myAccountAlertAction: 'Ver mi cuenta',
  Domain: 'Dominio',
  Sitelock: 'Sitelock',
  hosting: 'Administrar',
  addon: 'Ver más',
  domain: 'Administrar',
  subdomains: 'Subdominios',
  pendingStatus: 'Su sitio no está disponível por el momento. Pague la factura que está pendiente.',
  contactUs_invoice: 'Para generar una segunda vía de tu factura, pónte en contacto con nuestro soporte financiero',
  suspendedStatus: 'Sitio web suspendido. Realiza el pago de tu factura para reactivarlo.',
  nfseTitle: 'Facturas',
  nfseNumber: 'Nº Factura',
  nfseLink: 'Ver Factura',
  servername: 'Nombre del Servidor',
  server_ips: 'IPs Atribuidos',
  username: 'Nombre del Usuario',
  access_ssh: 'Accesso via SSH',
  hostnameFtp: 'Hostname FTP',
  temporaryLink: 'URL Temporal',
  host: 'Host',
  port: 'Puerta',
  hireDomain: 'Contratar Dominio',
  addSite: 'Agregar sitio',
  hireProduct: 'Contratar Producto',
  ErrorSSO: 'No se pudo acceder al panel. Inténtalo nuevamente o entra en contacto con el soporte.',
  redirectPageMessage: {
    WHMPanel: 'Aguarde por favor, lo estamos direccionando para el Panel WHMCS...',
    siteBuilder: 'Aguarde por favor, lo estamos direccionando para el Creador de Sitios...',
  },
  noResults: {
    tab: {
      sites: 'No hemos encontramos ningún sitio web. <1>Haz clic aquí</1> para conocer nuestros planes.',
      emails: 'Usted no tiene ninguna cuenta de correo electrónico creada.',
      host: 'En el momento no cuentas con un plan de hosting para administrar tus correos. Contrata un plan a través de nuestro <1>sitio web.</1>',
      domains: 'No hemos encontrado ningún dominio contratado. <1>Haz clic aquí</1> para contratar un dominio.',
      products: 'No hemos encontrado ningún producto o servicio contratado. <1>Haz clic aquí</1> para conocer nuestros servicios.',
    },
    filter: 'No hay resultados. Inténtalo de nuevo :)',
  },
  emails: {
    message: {
      withServer: 'Los correos de los planes de VPS y Dedicado NO se muestran a continuación. La administración se realiza únicamente en el cPanel. ',
    },
    buttonConfirmation: 'SÍ, ESTOY SEGURO',
    storage: 'Almacenamiento',
    changeStorage: 'Cambiar almacenamiento',
    changePassword: 'Cambiar contraseña',
    deleteAccount: 'Eliminar Cuenta',
    createAccount: 'Crear Cuenta',
    name: 'Nombre del Correo',
    password: 'Tu contraseña debe:',
    quota: 'Capacidad',
    unlimited: 'Ilimitado',
    unlimitedToolTip: 'Al definir la capacidad de almacenamiento, cuando esta llegue a su límite, no podrás recibir más correos.',
    createButton: 'Crear Cuenta de Correo',
    createButtonAndStay: 'Crear Cuenta de Correo y permanecer en esta pantalla',
    confirmPassword: 'Confirmar contraseña',
    specialCharacters: 'El correo electrónico solo permite punto, guión y subrayado como caracteres especiales.',
    createEmailSuccess: 'El correo electrónico ha sido creado exitosamente! :)',
    createEmailError: 'Ocurrió una falla en la creación de tu cuenta de correo. Por favor, inténtalo más tarde',
    or: 'o',
    deleteEmailAccount: 'Eliminar cuenta de correo',
    confirmationDeleteEmail: '¿Estás seguro de que deseas eliminar la cuenta de correo',
    seeEmail: 'VER CORREOS',
    warningDeleteEmail: '¡Eliminarás todos los correos!',
    alter: 'Cambiar',
    passwordPlaceholder: 'Contraseña',
    alterEmailPassword: 'Cambiar la contraseña del correo',
    toolTipQuota: 'Al definir la capacidad de almacenamiento, cuando esta llegue a su límite, no podrás recibir más correos',
    change: 'CAMBIAR',
    typeQuotaStorage: 'Ingrese la capacidad de almacenamiento que estará disponible para el correo electrónico <1>{{ emailAddress }}</1> o configúrelo como ilimitado:',
  },
  cardAlerts: {
    suspendedPlan: 'Producto suspendido. Paga la factura para reactivar.',
    suspendedSite: 'Sitio web suspendido. Realiza el pago de tu factura para reactivarlo.',
    pendingPlan: 'Producto no disponible. Paga la factura para activar tu producto.',
    pendingSitePlan: 'Sitio web no disponible. Realiza el pago de tu factura para activarlo.',
    pendingSite: 'Sitio web no disponible. Paga la factura para activarlo.',
    unpaidInvoice: 'Genera una copia de tu factura entrando en contacto con el soporte financiero.',
    invoicePhishingCheck: 'Espera mientras procesamos el pago.',
    cancelledProduct: 'Producto cancelado. No es posible reactivarlo. Por favor contrata un nuevo producto a través de nuestro sitio web.',
    domain: {
      pending: 'Dominio pendiente. <1>Realiza el pago de la factura abierta.</1>',
      pendingTransfer: 'Dominio en proceso de transferencia. Aguarda más instrucciones por correo.',
      registerError: 'Error en el registro del dominio. Aguarda más instrucciones por correo.',
      expired: 'Dominio vencido. <1>Realiza el pago de la factura abierta.</1>',
      expiredSimpleMessage: '<0>Su Dominio  </0><1>venció el {{data}}</1>. <2>Para más informaciones, comuníquese con soporte.</2>',
      cancelled: 'Dominio cancelado. No es posible reactivarlo. Contrata un nuevo dominio en nuestro <1>sitio web.</1>',
      emailConfirmSuccess: '<0>Un correo electrónico a sido enviado para {{email}}</0>. Siga las instrucciones en el mensaje para evitar suspender su dominio. <1>Si en algunos minutos usted no recibir el correo electrónico, por favor compruebe su carpeta de spam.</1> <2>Si desea cambiar la dirección de correo electrónico del contacto, haga</2> <3>clic aquí</3>.',
      emailSuspended: '<0>Un correo electrónico a sido enviado para {{email}}</0>. Siga las instrucciones en el mensaje para evitar suspender su dominio. <1>Si en algunos minutos usted no recibir el correo electrónico, por favor compruebe su carpeta de spam.</1> <2>Si desea cambiar la dirección de correo electrónico del contacto, haga</2> <3>clic aquí</3>. <4>1- Espere la propagación (entre 4h y 24h)</4> <5>2- Limpie la caché</5>',
      cancelledPendingInvoice: 'dominio pendiente. <1>Póngase en contacto con el servicio de asistencia.</1>',
      cancelledExpiredInvoice: 'dominio vencido. <1>Póngase en contacto con el servicio de asistencia.</1>',
      messages: {
        nextDueDateInvoice: {
          0: 'Su factura ',
          1: 'vence el día ',
          2: '¡Pague el cupón de pago ahora mismo',
          3: ' y no corra el riesgo de perder su dominio!',
        },
        nextDueDateInvoiceCreditCard: {
          0: 'Su factura ',
          1: 'vence el día',
          3: 'y se le cobrará automáticamente a través de Tarjeta de Crédito.',
        },
        nextDueDateInvoicePayPal: {
          0: 'Su factura ',
          1: 'vence el día ',
          3: 'y se le cobrará automáticamente a través de PayPal.',
        },
        dueDateInvoice: {
          0: 'Su factura ',
          1: 'venció el día ',
          2: ' y su dominio caducará en ',
          3: 'dias. ',
          4: 'dia. ',
          5: '¡Pague la factura ahora mismo!',
        },
        expiredInvoice: {
          0: 'Su factura ',
          1: 'venció el día ',
          2: ' y su dominio será cancelado en breve. ',
          3: '¡Pague la factura ahora mismo!',
        },
        expiredOnGracePeriod: {
          0: 'El dominio ha caducado y esta es la última oportunidad para renovarlo. ',
          1: 'Pague la factura ahora mismo',
          2: ' para no correr el riesgo de perderlo.',
        },
        expiredGracePeriodOverdue: {
          0: 'Su dominio ',
          1: 'venció el ',
          2: '. Para más informaciones, comuníquese con soporte.',
        },
        disableAutoRenovation: {
          0: 'Según lo solicitado, su dominio ',
          1: 'será cancelado el día  ',
        },
        newDisableAutoRenovation: {
          0: 'Su dominio ',
          1: 'vence el ',
          2: 'Active la renovación automática',
          3: ' para no correr el riesgo de perderlo.',
        },
        dueDateDisabledAutoRenovation: {
          0: 'Su dominio ',
          1: 'vence el ',
          2: 'Renueve ahora mismo',
          3: ' para no correr el riesgo de perderlo.',
        },
        overDueDateDisabledAutoRenovation: {
          0: 'Su dominio ',
          1: 'venció ',
          2: ' y caducará el ',
          3: ' Renueve ahora mismo',
          4: ' para no correr el riesgo de perderlo.',
        },
        cancelledDomain: {
          0: 'Su dominio ',
          1: 'fue cancelado el dia ',
          2: ' por falta de pago.  ',
          3: '¡Ingrese a nuestro sitio web y contrate un nuevo dominio!',
        },
        cancelledByAbuseTOS: {
          0: 'Su dominio ',
          1: 'fue cancelado',
          2: '. Para más informaciónes ingrese a nuestra ',
          3: 'Central de Ayuda.',
        },
      },
      suspendedPlanLink: 'Producto suspendido. <1>Por favor realiza el pago de tu factura.</1>',
      pendingPlanLink: 'Producto pendiente. <1>Por favor realiza el pago de tu factura.</1>',
      cancelledPlanLink: 'Producto cancelado. No es posible reactivarlo. <1>Por favor contrata un nuevo plan a través de nuestro sitio web.</1>',
      terminatedPlanLink: 'Producto finalizado. No es posible reactivarlo. <1>Por favor contrata un nuevo plan a través de nuestro sitio web.</1>',
      buildingPlanLink: 'Su servidor está siendo configurado. Aguarde hasta que esté disponible para el uso. ',
    },
  },
  billing: billing.MX,
  invoices: billing.MX.invoices,
  subscriptions: billing.MX.subscriptions,
  creditcard: {
    label: {
      number: 'Número de la tarjeta',
      date: 'Vencimiento',
      cvvShort: 'Código',
      cvvLong: 'Código de Seguridad (CVC)',
      name: 'Nombre como aparece en la tarjeta',
    },
    placeholder: {
      number: 'XXXX XXXX XXXX XXXX',
      date: 'MM/AA',
      cvv: 'Ex: 123',
      name: 'NOMBRE',
    },
  },
  product: {
    Pending: 'Pendiente',
    Active: 'Activo',
    Completed: 'Completo',
    Suspended: 'Suspendido',
    Terminated: 'Cerrado',
    Cancelled: 'Cerrado',
    Fraud: 'Fraude',
    Building: 'En Configuración',
    ActivationPending: 'Esperando activación',
  },
  statusProductDomain: {
    active: 'Registrado',
    pending: 'Abierto',
    pendingTransfer: 'Em Transferencia',
    registerError: 'Registro Fallido',
    expired: 'Caducado',
    cancelled: 'Cancelado',
    nextDueDate: 'Por vencer',
    overDueDate: 'Vencido',
  },
  details: {
    product: {
      domain: 'Administrar',
      subdomains: 'Subdominios',
      emptySubdomains: 'No hay subdominios',
      dedicated: 'Administrar',
      windows: 'Administrar',
      weebly: 'Ver más',
      reseller: 'Administrar',
      shared: 'Administrar',
      wordpress: 'Administrar',
      websitebuilder: 'Administrar',
      hostingaccount: 'Ver más',
      reselleraccount: 'Ver más',
      upgrade: 'Ver más',
      codeguard: 'Ver más',
      other: 'Ver más',
      protectedid: 'Ver más',
      ssl: 'Ver más',
      gapps: 'Ver más',
      jumpstart: 'Ver más',
      ip: 'Ver más',
      sitelock: 'Ver más',
      streaming: 'Ver más',
      vps: 'Administrar',
      sql: 'Ver más',
      revenda: 'Ver más',
      setup: 'Ver más',
      suspended: 'Pagar Factura',
    },
    service: {
      websitebuilder: 'Ver más',
      server: 'Ver más',
      wordpress: 'Ver más',
      shared: 'Ver más',
      hostingaccount: 'Ver más',
      reseller: 'Ver más',
      reselleraccount: 'Ver más',
      revenda: 'Ver más',
      suspended: 'Reactivar Sitio',
      dedicated: 'Ver más',
      weebly: 'Ver más',
      windows: 'Ver más',
    },
  },
  filter: {
    product: 'Producto',
    site: 'Sitio',
    siteName: 'Sitio Web',
    domainName: 'Dominio',
    emails: 'El correo',
    title: 'Filtrar por',
    status: 'Status',
    invoiceNumber: 'Número de la factura',
    Active: 'Activo',
    Pending: 'Pendiente',
    Suspended: 'Suspendido',
    Cancelled: 'Anulado',
    Terminated: 'Cerrado',
    Building: 'En Configuración',
    allSites: 'Todos los Sitios',
    allProducts: 'Todos los Productos',
    allStatus: 'Todos los Status',
    allDomains: 'Todos os Dominios',
    open: 'Abierto',
    hold: 'Abierto',
    conclude: 'Terminado',
    search: 'Asunto o número del ticket',
    placeholder: {
      search: 'Buscar',
      allSites: 'Todos los Sitios',
    },
  },
  feedback: {
    title: '¡Déjanos tu feedback!',
    chooseTier: '¿Cuál es el motivo de esta opinión?',
    comments: '¿Qué fue lo que más le gustó a usted y/o lo que todavía podemos mejorar?',
    commentsPlaceholder: 'Envíe su comentario',
    sendButton: 'Enviar feedback',
    hated: 'La detesté',
    regular: 'Es regular',
    loved: 'Me gusta',
    validation: {
      tiers: 'Seleccione por lo menos una opción.',
      score: 'Seleccione por lo menos una opción.',
    },
  },
  dialog: {
    title: 'Pagar con Tarjeta de Crédito',
    message: 'Los datos serán enviados de manera segura y criptografiados para reducir el riesgo de fraude.',
    subtitle: '¿Con cuál tarjeta desea pagar?',
    formCaption: 'Se reemplazará la tarjeta que ya habías registrado',
    invoiceDetails: 'Ver Resumen de la Factura',
  },
  snackbar: {
    creditCard: {
      create_error: '¡Discúlpenos!... no se puede registrar esta tarjeta por el momento. Por favor, inténtelo más tarde :)',
      create: '¡Tarjeta de Crédito registrada con éxito!',
      remove_error: '¡Discúlpenos!... no se puede excluir esta tarjeta por el momento. Por favor, inténtelo más tarde :)',
      remove: '¡Tarjeta eliminada con éxito!',
      invoice_error: 'Discúlpenos... no se puede pagar esta factura por el momento. Por favor, inténtelo más tarde :)',
      invoice_status_not_unpaid: 'Identificamos un problema con el pago y su factura fue cancelada, verifique sus datos e intente realizar una compra nuevamente a través de nuestro sitio web.',
      invoice: 'Factura paga con éxito',
      recaptcha_not_clicked: 'Confirma que no eres un robot haciendo clic en el reCaptcha abajo.',
      invoicePaymentSuccess: 'El pago de su factura ha sido efectuado con éxito! :)',
      invoicePaymentPending: 'El pago de su factura está siendo procesado y su estado será actualizado automáticamente apenas finalice el proceso.',
      exceeded_accounts: 'Has alcanzado el límite de creación de cuentas de correo para este dominio. Para crear una nueva cuenta de correo, debes eliminar una cuenta ya existente de este dominio.',
      paas: {
        errorSaveCreditCard: 'Complete todos los campos disponibles para el registro de la tarjeta de crédito :)',
        errorVerifyCard: 'Tarjeta inválida, revise los datos ingresados ​​o inserte una nueva tarjeta de crédito',
        errorCreditCardAttempt: 'No se pudo guardar en este momento, por favor, inténtelo más tarde',
      },
    },
    feedback: {
      sent: '¡Feedback enviado con éxito!',
      sent_error: 'Vaya... no hemos podido enviar tus feedback en el momento. Por favor, inténtalo más tarde :)',
    },
    dns: {
      changed: 'DNS cambiado con éxito!',
      changed_error: 'No fue posible modificar el DNS en el momento. Por favor, inténtalo más tarde :)',
    },
    actions: {
      open: 'Abrir',
    },
    emails: {
      deleteEmailAccount: 'El correo ha sido eliminado exitosamente',
      alterPasswordSuccess: 'La contraseña del correo ha sido modificada exitosamente.',
      alterPasswordError: 'Ocurrió una falla con el cambio de la contraseña de tu correo. Por favor, inténtalo más tarde .',
      changeStorage: 'El almacenamiento de correo ha sido modificado exitosamente',
    },
    siteBuilder: {
      successRemoveSite: '¡Sitio eliminado con éxito!',
      errorRemoveSite: 'No pudimos eliminar su sitio. Inténtalo de nuevo o ponte en contacto con el servicio de asistencia.',
      errorOnActivateSiteBuilder: 'No pudimos activar el Creador de Sitios. Inténtalo de nuevo',
    },
    error: {
      address_ip_invalid: 'Esta dirección IP no es válida. Por favor verifica que la información sea correcta o entra en contacto con soporte.',
      authorization_denied: 'Alteración de DNS no autorizada. Por favor verifica que la información sea correcta o entra en contacto con soporte.',
      violated_policy: 'No fue posible cambiar el DNS. Por favor verifica tu información de registro.',
      generic_error: 'No fue posible cambiar el DNS. Por favor entra en contacto con soporte.',
      domain_not_exist: 'No fue posible cambiar el DNS. Por favor entra en contacto con soporte.',
      invalid_nameserver: 'DNS no válido. Por favor verifica que la información sea correcta o entra en contacto con soporte.',
      frozen_domain: 'Este dominio se encuentra caducado. Por favor entra en contacto con soporte financiero.',
      duplicated_information: 'No fue posible cambiar el DNS. Por favor verifica que la información sea correcta o entra en contacto con soporte.',
      cpanel_authentication_fail: 'No fue posible cambiar el DNS. Por favor entra en contacto con soporte.',
      empty_params: 'No fue posible cambiar el DNS. Por favor entra en contacto con soporte.',
      unknown_domain_provider: 'No fue posible modificar el DNS. Verifica si tu dominio fue adicionado en el plan de otro proveedor.',
      unknown_domain: 'No fue posible cambiar el DNS. Por favor entra en contacto con soporte.',
      search_refused: 'No fue posible modificar el DNS. Entra en contacto con tu proveedor actual.',
      change_pass: 'No fue posible cambiar sus datos en el momento. Inténtelo de nuevo más tarde.',
      minimum_requirement: 'Tu contraseña no cumple con los requisitos mínimos de seguridad.',
      temporary_invalid: 'Este método de pago no está disponible temporalmente',
      changeStorageEmail: 'Ocurrió una falla en la creación de tu cuenta de correo. Por favor, inténtalo más tarde',
      email_already_exists: 'El correo electrónico ya existe.',
      access_denied_cpanel: 'Hubo un problema al crear su cuenta de correo electrónico. Póngase en contacto con el soporte.',
      username_invalid: 'Hubo un problema al crear su cuenta de correo electrónico. Póngase en contacto con el soporte.',
      exceeded_accounts: 'El límite de la cuenta de correo electrónico para el plan contratado ha alcanzado el límite. Elimine una cuenta de correo electrónico ya creada para continuar.',
      cpanel_instability: 'Hubo un problema al crear su cuenta de correo electrónico. Póngase en contacto con el soporte.',
      hosting_id_not_authorized: 'Ocurrió una falla en la creación de tu cuenta de correo. Por favor, inténtalo más tarde',
      is_not_possible_reactive_freemium_plan: 'Não foi possível reativar o seu plano. Entre em contato com o suporte',
      is_not_possible_active_email_cpanel: 'Erro ao ativa o e-mail cPanel',
    },
    paypal: {
      success: '¡Pago exitoso!',
      error: {
        service_error: 'No pudimos cargar con Paypal. Su pago no fue autorizado, confirme sus detalles de pago en la Cartera de Paypal e intente nuevamente.',
        agreement_cancelled: 'No pudimos cargar su billetera de PayPal. Puede vincular una billetera de PayPal volviendo a intentarlo.',
      },
    },
  },
  domainStatus: {
    Pending: 'Pendiente',
    Pending_Transfer: 'Todavía sin transferir',
    Active: 'Activo',
    Expired: 'Vencido',
    Transferred_Away: 'Transferido lejos',
    Cancelled: 'Anulado',
    Fraud: 'Rechazado',
    Suspended: 'Suspendido',
  },
  domaindetail: {
    renewBlocked: 'La renovación automática solo se puede cambiar cuando su dominio está activo',
    action: {
      alterDns: 'Configurar Dominio',
      editContact: 'Editar Infos Contacto',
      renewDomain: 'Renovar Dominio',
      protectId: 'Protect ID',
      registrationLock: 'Bloqueo de Registro',
      manageSubscription: 'Administrar Suscripción',
      domainConfigurationWizard: 'Configurar Dominio',
      eppKey: 'Generar clave EPP',
    },
    eppKeyDisabledTooltip: 'No se puede generar la clave porque el dominio ha expirado.',
  },
  routes: routes.MX,
  menu: menu.MX,
  title: title.MX,
  tag: {
    new: 'Nuevo',
  },
  lists: {
    sites: 'Lista de Sitios',
    sitesPage: 'Lista de Sitios',
    sitesPlans: 'Lista de Sitios e Planes',
    products: 'Lista de Productos',
    domains: 'Lista de Dominios',
    emails: 'Lista de Correos',
    tickets: 'Lista de Tickets',
    invoices: 'Lista de Facturas',
  },
  notFound: {
    error: 'Erro 404',
    title: 'UPS, nos perdimos…',
    description: 'Parece que el camino que estás buscando no existe o ya no está disponible.',
    textLink: 'IR A LA PÁGINA DE INICIO',
  },
  btn: {
    loadMore: 'Cargar más',
  },
  help: {
    frequentlyQuestions: 'Enlaces de ayuda',
    send: 'Enviar',
    me: 'Yo',
    analyst: 'Analista',
    search: 'Cuál es tu duda?',
    guidePlaceholder: 'Escribe aquí tu duda',
    faqCpanel: 'Cómo administrar archivos en el panel de cPanel',
    faqWebsiteAdvanced: 'Materiales de apoyo para el Creador de Sitios Avanzado',
    faqSoftaculous: 'Cómo instalar WordPress en el Softaculous',
    faqWebsiteFast: 'Cómo configurar el creador de sitios web rápido',
    seeMoreResults: 'Ver mais resultados',
    contentHelpCenter: 'Contenido Central de ayuda',
    buttonRedirectWordPress: 'https://soporte-latam.hostgator.com/hc/es-419/categories/6428647308187',
    buttonRedirectSitesBuilder: 'https://soporte-latam.hostgator.com/hc/es-419/sections/360004187832',
    buttonRedirectSoftaculos: 'https://soporte-latam.hostgator.com/hc/es-419/sections/115000065331',
  },
  home: {
    mySites: 'Mis Sitios',
    emails: 'Correos Electrónicos',
    products: 'Productos',
    domains: 'Dominios',
  },
  tickets: {
    pendingTab: 'Pendientes',
    finalizedTab: 'Finalizados',
    openTicket: 'Abrir Nuevo Ticket',
    tickets: 'Tickets',
    show: 'Ver Ticket',
    close: 'Finalizar Ticket',
    reply: 'Responder el ticket',
    attachment: 'Adjuntar',
    endMessage: 'En el caso de que no sea así, En 7 días se finalizará automáticamente.',
    attendance: '¿Qué creyó de la atención?',
    sendFeedback: 'Enviar opinión',
    sendFeedbackSuccess: 'Evaluación enviada con éxito.',
    sendFeedbackError: 'No hemos podido registrar su evaluación.',
    errorUploadFile: 'No se pudo cargar este archivo',
    errorUploadExtension: 'Se permiten archivos .jpg, .jpeg, .png, .pdf, .doc, .docx, .crt, .key',
    errorUploadSize: 'Se permiten archivos con hasta 20MB',
    anexo: 'Adjunto:',
    good: '¡Muy buena!',
    bad: '¡No me ayudó!',
    new: 'Abierto',
    open: 'Pendiente',
    pending: 'Esperando tu respuesta',
    hold: 'Pendiente',
    solved: 'Completado',
    conclude: 'Completado',
    closed: 'Finalizado',
    inputHere: 'Digite aquí...',
    pendingEmpty: 'Usted no tiene llamadas pendientes hasta el momento :)',
    closedEmpty: 'Usted no tiene llamadas finalizadas hasta el momento :)',
    uploadMessage: 'Arrastre el archivo aquí, o haga',
    sendImages: 'Adjuntar archivos',
    defaultMessage: 'Mensaje con el archivo adjunto.',
    uploadButton: 'upload.',
    back: 'hace',
    second: 'seg',
    seconds: 'seg',
    minute: 'min',
    minutes: 'min',
    hour: 'h',
    hours: 'h',
    day: 'd',
    days: 'd',
    week: 'sem',
    weeks: 'sem',
    month: 'mes',
    months: 'meses',
    year: 'año',
    years: 'años',
  },
  chat: {
    open: 'Abrir Chat',
  },
  persona: {
    beginner: {
      title: 'Principiante',
      subtitle: 'Entiendes poco y vas a necesitar mucha ayuda',
    },
    intermediary: {
      title: 'Intermedia',
      subtitle: 'Conoces el tema y pides ayuda cuando es realmente necesario',
    },
    technician: {
      title: 'Técnica',
      subtitle: 'Entiendes bastante y te desenvuelves en este campo',
    },
    welcome: '¡Hola! Te damos la bienvenida :)',
    description: 'Pensando en tecnología, hospedaje web y creación de sitios, te consideras una persona...',
    next: 'Siguiente',
    createLater: '¡No quiero crear mi sitio web ahora!',
    errorMsg: 'Ha habido un error al validar su elección. Inténtalo nuevamente más tarde.',
    successMsg: 'Su nivel de conocimiento fue grabado con éxito.',
  },
  newsite: {
    creator: {
      title: 'Creador de sitios web',
      subtitle: 'Un editor fácil con todos los recursos que usted necesita',
    },
    wordpress: {
      title: 'Wordpress',
      subtitle: 'Herramienta más utilizada y completa del mundo',
    },
    manual: {
      title: 'Manual (Archivos)',
      subtitle: 'Administra los archivos de tu sitio web a través de FTP',
    },
    fast: {
      title: 'Rápido',
      subtitle: '¡Crea tu sito web ahora mismo!',
    },
    advanced: {
      title: 'Avanzado',
      subtitle: 'Crea tu sitio a través de un editor lleno de recursos!',
    },
    siteModes: {
      title: 'Cómo le gustaría crear el sitio?',
      subtitle: 'Elige cómo te gustaría crearlo, si de una forma más rápida o más tradicional...',
      subtitle2: 'y selecciona un editor a continuación',
      subtitleWithoutExpress: 'Utiliza el Creador de Sitios Avanzado!',
      information: 'Selecciona el editor para continuar:',
    },
    themes: {
      title: 'Selecciona la categoría del sitio y el tema',
    },
    welcome: 'Enhorabuena, ya puedes crear tu sitio :)',
    description: 'Seleccione uno de los siguientes sitios',
    choice: 'y seleccione entre las opciones disponibles o la que recomendamos de acuerdo con su nivel de conocimiento ...',
    create: 'Criar Sitio',
    preview: 'Preview',
    useTheme: 'Utilizar',
    feedbackWelcome: '¡Felicitaciones por tu elección! :)',
    feedbackLink: 'Consulta nuestro tutorial',
    feedbackTitleWordpress: '¿Tienes dudas sobre cómo instalar el wordpress?',
    feedbackTitleFile: '¿Tienes dudas sobre cómo administrar archivos?',
    feedbackTitleCreator: '¿Tienes dudas sobre cómo configurar el creador de sitios?',
    chooseWebsite: 'Elegir el sitio',
    chooseCategoryWebsite: 'Selecciona la categoría del sitio *',
    categories: 'Categorías',
    backToSite: 'Volver a Mis Sitios',
  },
  dns: {
    label: 'DNS',
    message: 'Define hacia donde apuntará tu dominio :)',
    chooseDomain: 'Elige un dominio',
    selectDomain: 'Selecciona un dominio',
    selectHosting: 'Selecciona un plan',
    choiceOption: 'Su dominio no ha sido registrado en HostGator. Para editar el apuntamiento de',
    choiceOption_2: 'DNS (Name Server)',
    choiceOption_3: 'siga una de las dos opciones a continuación.',
    notRegistred: {
      title: 'Dominio aún no registrado',
      text: 'Para cambiar el DNS, es necesario registrar el dominio.',
      link: 'Registra tu dominio fácilmente a través de nuestro sitio web.',
      orText: 'O',
    },
    anotherProvider: {
      title: 'Domínio registrado em outro provedor',
      text: 'Este dominio no está registrado con nosotros, por eso, es necesario apuntar su DNS al hosting de HostGator. Solo hay que seguir una de las siguientes opciones:',
    },
    messages: {
      messageHosting: '<0>Para acceder a su editor de Zona de DNS acceda al panel de administración de cPanel. ¿No sabe cómo acceder al editor de Zona de DNS a través de cPanel? </0><1>Haga clic aquí.</1>',
      messageHosting_2: 'Si usted ha seleccionado un Plan Creador de Sitios Web o Plan de WordPress, solicite cambios en su Zona de DNS abriendo una llamada con el soporte.',
      messageWhm: '<0>Para acceder a su editor de Zona de DNS vaya al panel de administración de WHM. ¿No sabe cómo acceder al editor de Zona de DNS a través de WHM? </0><1>Haga clic aquí.</1>',
      messageOther: 'Si su dominio está apuntado a un servidor fuera de HostGator, acceda al panel de administración de su proveedor para editar la zona de DNS.',
      messageOtherSeeMore: 'Vea cómo acceder a la Zona de DNS de su dominio en otro proveedor:',
      messageOtherBellow: '<0>Si su dominio está apuntado a un plan VPS o un servidor Dedicado de HostGator, vaya al panel de administración de WHM. ¿No sabe cómo acceder al editor de Zona de DNS a través de WHM? </0><1>Haga clic aquí.</1>',
      messageError: 'Ha habido un error inesperado al acceder a la zona de DNS del servidor donde apunta su dominio.',
      messageError_2: 'Inténtelo de nuevo más tarde o abra una llamada con el soporte.',
      createTicket: 'ABRIR LLAMADA',
    },
    transferDomain: {
      title: 'Transferir tu dominio a HostGator',
      text: 'Puedes transferir tu dominio a HostGator y administrar todos tus productos en un solo lugar. Para realizar este proceso, solo debes ingresar a nuestra página de transferencia y realizar la solicitud:',
      link: 'Transferir dominio',
    },
    changeDns: {
      title: 'Cambiar DNS en otra empresa de registro',
      textUp: 'También podrás realizar el cambio de DNS directamente en la empresa donde registraste tu dominio. Para ayudarte creamos algunos tutoriales que puedes consultar a continuación.',
      textDown: 'Solo debes escoger la empresa donde registraste tu dominio y seguir el paso a paso:',
      textOtherProvider: 'En caso de que no hayas realizado el registro en alguna de estas empresas, por favor entra en contacto con el soporte de la empresa donde registraste tu dominio.',
    },
    zoneManager: {
      title: 'Zona de DNS',
      explanation: 'Administre los registros de DNS para utilizar su dominio de la manera que necesite.',
      explanationKnowMore: 'Obtenga más información sobre los',
      explanationKnowMore_2: 'registros de la Zona DNS.',
      explanationTooltip_1: 'El dominio es su dirección de Internet. Está representado por una serie de caracteres que identifican su sitio web, por ejemplo: "hostgator.com.mx".',
      copyDnsTable: 'Copiar registros DNS',
      planStatusCancelledZoneDNS: 'Su dominio está configurado para un plan de hosting cancelado. Ahora configure su dominio haciendo clic en "Cambiar plataforma"',

      addRecord: {
        button: 'Añadir Registro',
        form: {
          title: 'Crear Registro',
          editTitle: 'Editar Registro',
          closeForm: 'Fechar formulário',
          label: {
            type: 'Tipo',
            name: 'Nombre',
            class: 'Clase',
            ttl: 'TTL',
            typeAValueField: 'IPv4',
            typeAAAAValueField: 'IPv6',
            typeCAAValueField: 'Valor',
            typeMxPriorityField: 'Prioridad',
            typeMxDestinationField: 'Destino',
            typeTxtTextField: 'Texto',
            typeCnameValueField: 'Valor',
            typeSrvPriorityField: 'Prioridad',
            typeSrvPortField: 'Puerto',
            typeSrvWeightField: 'Peso',
            typeSrvDestinationField: 'Destino',
          },
          placeholder: {
            type: 'Tipo',
            name: 'Use @ para root',
            class: 'Clase',
            ttl: 'TTL',
            preference: 'Número entero positivo',
          },
          helperText: {
            typeA: 'Usted debe especificar una dirección IPv4 válida.',
            nameField: "Introduzca un dominio válido con letras de a-z, números de 0-9 y guión. Usted también puede introducir '_', como en 'sub._domainkey.ejemplo.com' o '*', como en '*.ejemplo.com'. No use espacios.",
            'nameFieldAs@': "El carácter '@' en nombre de registro corresponderá a la dirección raíz",
            ttl: 'Introduzca un número entero positivo.',
            caaIodefValue: "Usted debe introducir una dirección de correo electrónico en el formato 'mailto:ejemplo@email.com' o una URL válida en el formato 'https://ejemplo.com' o 'http://ejemplo.com'.",
            caaIssueValue: "Usted debe introducir solamente un punto y coma ‘;’ o una dirección válida en el formato 'ejemplo.com' o 'www.ejemplo.com'.",
            mxDestinationValue: 'Usted debe especificar un nombre de dominio válido en el formato “ejemplo.com” y sin espacios.',
            mxPriorityValue: 'El número debe ser menor o igual a 65535.',
            cnameValueField: 'Usted debe especificar un nombre de dominio válido en el formato “ejemplo.com” y sin espacios.',
            srvIntegerHelper: 'El número debe ser menor o igual a 65535.',
            srvDomainHelper: 'Usted debe especificar un nombre de dominio válido en el formato “ejemplo.com” o “www.ejemplo.com”.',
          },
        },
        addButton: 'Añadir',
        cancelButton: 'Cancelar',
        updateButton: 'Guardar',
        response: {
          success: 'Registro añadido con éxito.',
          error: 'Algo salió mal al añadir el registro. Inténtelo de nuevo en unos minutos o solicite soporte técnico.',
        },
        successMessage: 'Registro añadido con éxito.',
        errorMessage: 'Algo salió mal al añadir el registro. Inténtelo de nuevo en unos minutos o solicite soporte técnico.',
        errorMessageDomain: 'No puede tener un registro CNAME con el mismo nombre de dominio.',
      },
      excludeRecord: {
        title: 'Eliminar Registro',
        confirmation: '¿Seguro que desea eliminar este registro?',
        explanation: '¡Tenga cuidado! Al eliminar un registro de DNS usted puede impactar el funcionamiento de su sitio web.',
        excludeButton: 'Eliminar',
        cancelButton: 'Cancelar',
        successMessage: 'Registro eliminado con éxito',
        errorMessage: 'Algo salió mal al eliminar el registro. Inténtelo de nuevo en unos minutos o solicite soporte técnico.',
      },
      editRecord: {
        successMessage: 'Registro cambiado con éxito.',
        errorMessage: 'Algo salió mal al editar el registro. Inténtelo de nuevo en unos minutos o solicite soporte técnico.',
      },
      recordList: {
        searchField: 'Buscar Registro',
        filterLabel: 'Filtrar por:',
        loadMore: 'CARGAR MÁS',
        listLabel: {
          type: 'Tipo',
          name: 'Nombre',
          class: 'Clase',
          ttl: 'TTL',
          value: 'Valor',
          address: 'Valor',
          status: 'Status',
          editButton: 'Editar',
          removeButton: 'Eliminar',
          flag: 'Flag',
          tag: 'Tag',
          preference: 'Prioridad',
          exchange: 'Destino',
          txt_data: 'Texto',
          cname: 'Valor',
          weight: 'Peso',
          port: 'Puerto',
          target: 'Destino',
          priority: 'Prioridad',
        },
        allFiltersLabel: 'Todos',
        emptyMessage: 'Usted aún no tiene registros (clase IN) en la zona de DNS de su dominio.',
        emptyMessage_2: 'Haga clic en el botón "AÑADIR REGISTRO" para añadir su primer registro.',
        emptySearch: 'No encontramos ningún resultado para su búsqueda :(',
        emptySearch_2: 'Usted puede intentar buscar otra palabra clave y/o utilizar una combinación diferente de filtros para encontrar lo que busca.',
      },
      errorCode: {
        internal: {
          error: 'Algo salió mal. Inténtelo de nuevo en unos minutos o solicite soporte técnico.',
        },
        name: {
          dns_record_name: "Introduzca un dominio válido con letras de a-z, números de 0-9 y guión. Usted también puede introducir '_', como en 'sub._domainkey.ejemplo.com' o '*', como en '*.ejemplo.com'.",
          unique_1: 'Usted no puede tener un registro',
          unique_2: 'junto con otro registro CNAME con el mismo nombre.',
          unique_3: 'Usted no puede tener un registro CNAME junto con otros registros con el mismo nombre.',
        },
        address: {
          ip: 'Usted debe especificar una dirección IP válida.',
        },
        value: {
          dns_record_value: "A informação do campo 'valor' é inválida.",
        },
        exchange: {
          domain: 'Usted debe especificar un nombre de dominio válido en el formato “ejemplo.com” o “www.ejemplo.com',
        },
      },
    },
    dnsPropagation: 'Propagación DNS',
  },
  whichDnsToChange: {
    title: 'DNS (Name Server)',
    title2: 'No fue posible cambiar tu DNS',
    text: 'Por favor, ',
    text2: 'entra en contacto con soporte.',
    infoIconTooltip: 'El sistema de Nombre de Dominios (Domain Name System - DNS) es responsable de la traducción de la dirección de su sitio web (o dominio), por ejemplo "hostgator.com.mx", al lenguaje de Internet.',
    paragraphOne: 'Configure las direcciones de DNS (Name Server) para que los servidores de Internet puedan encontrar su dominio en la red mundial de computadoras.',
    wichDnsToChangeKnowMore: 'Sepa más sobre',
    wichDnsToChangeKnowMore_2: 'apuntamientos de Name Server.',
    text3Tooltip_1: '<0>El Sistema de Nombres de Dominios (</0><1>Domain Name System</1><2> - DNS) El Sistema de Nombres de Dominios (Domain Name System - DNS) es responsable de la traducción de la dirección de su sitio web (o dominio), por ejemplo "hostgator.com.mx", al lenguaje de internet. </2>',
    text3Tooltip_2: 'El dominio es su dirección de Internet. Está representado por una serie de caracteres que identifican su sitio web,  por ejemplo: "hostgator.com.mx".',
    description: 'Este dominio no es nuestro, o sea, no está registrado en HostGator. Para realizar una transferencia para este lugar, <1>vea este artículo</1> y <3>deje un mensaje</3>.',
  },
  dnsGroups: {
    dnsMissing: 'Falta Apuntar DNS',
    dnsOk: 'DNS Apuntado',
    anotherProvider: 'No está registrado en HostGator',
  },
  dnsSetup: {
    title: 'Por cuál DNS usted desea cambiar?',
    warning: '<1>El cambio puede tardar hasta 72 horas</1>, que es el período de propagación en Internet.',
    warningSuccess: 'Felicitaciones, el DNS ha sido modificado con éxito :)',
    warningError: 'No se pudo guardar en el momento. Por favor, inténtalo más tarde. :)',
    warningNative: 'Seleccione una de las opciones de DNS al lado :)',
    native: {
      title: 'No tengo un Servidor de Hosting',
      description: 'Utilice este apuntamiento para habilitar el editor de Zona de DNS de su dominio si usted no tiene un hosting.',
    },
    other: {
      title: 'Otro Servidor',
      description: 'Utilice un DNS personalizado o apunte su dominio a otro proveedor de hosting.',
      button: 'Agregar otro Slave',
      ipAddress: 'Dirección IP',
    },
    hosting: {
      title: 'Servidor de Hosting de HostGator',
      description: 'Utilice un Servidor de Hosting de HostGator para poner su sitio web en el aire.',
    },
  },
  myData: {
    foreign: 'Soy Empresa',
    companyName: 'Razón Social',
    typeHere: 'Escriba aquí',
    placeHolderEmail: 'seuemail@dominio.com',
    personalDataContact: 'Datos personales y de contacto',
    name: 'Nombre completo',
    nameResponsable: 'Representante legal',
    birthDate: 'Fecha de nacimiento',
    cellPhone: 'Celular',
    email: 'Correo Electrónico',
    knowledge: 'Nivel de conocimiento',
    addressTitle: 'Calle',
    address: 'Calle',
    findMyCep: 'Buscar mi código postal',
    countryResidence: 'País de residencia',
    cep: 'Código Postal',
    postalCode: 'Código Postal',
    numberComplement: 'Número exterior/interior',
    district: 'Colonia',
    state: 'Estado',
    stateSelect: 'Estado',
    city: 'Ciudad',
    citySelect: 'Ciudad',
    avatar: 'Avatar',
    chooseMyAvatar: 'Escolher Avatar',
    persona: {
      beginner: 'Principiante',
      intermediary: 'Intermedia',
      technical: 'Técnica',
    },
    errors: {
      required: 'Campo obligatorio',
      fullName: 'El nombre debe tener 2 palabras con mínimo 2 letras y máximo 50',
      birthDate: 'Ingresa una fecha válida',
      country: 'País no válido',
      phoneNumber: 'Digite um telefone válido',
      email: 'Ingresa una dirección de correo electrónico válida',
      cep: 'Código postal no válido',
      state: 'Estado no válido',
    },
    snackBar: {
      updateSuccess: 'Tus datos fueron actualizados con suceso',
      updateError: 'Error al actualizar tus datos',
      emailAlreadyExists: 'Tu correo ya está registrado en nuestro sistema.',
    },
    changePassword: {
      title: 'Cambia la contraseña del Portal del Cliente',
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirma la nueva contraseña',
      helperTextUp: 'La contraseña debe tener mínino <1>8 caracteres</1> que contengan al menos una <3>una letra mayúscula</3>, <5>una minúscula</5>, <7>un número</7> y <9>un carácter especial (Por ejemplo: !#$%&)</9>.',
      strength: 'Fuerza de la nueva contraseña',
      generatorText: 'Si necesitas ayuda para generar una contraseña fuerte y segura, usa nuestro <1>generador de contraseñas</1>.',
      errorCurrentEmpty: 'Escribe tu contraseña actual.',
      errorEmptyField: 'Completa este campo.',
      errorCurrentMinimumCharacters: 'La contraseña debe tener un mínimo de 6 caracteres.',
      errorMinimumCharacters: 'Debe tener mínino 8 caracteres.',
      errorMinimumRequirements: 'Tu contraseña no cumple con los requisitos mínimos de seguridad.',
      errorConfirmNew: 'Las contraseñas no coinciden.',
      nofifierSuccess: 'Contraseña cambiada con éxito!',
      notifierError: 'No fue posible cambiar tu contraseña. Por favor entra en contacto con soporte.',
      checklist: {
        title: 'Tu nueva contraseña debe:',
        upperAndLowercase: 'Contener mayúsculas y minúsculas',
        number: 'Contener al menos 1 número',
        symbol: 'Contener al menos 1 símbolo',
        eightCharacters: 'Tener al menos 8 caracteres',
        notEqualCharacters: 'No incluir combinaciones de caracteres repetidos (ej.: aaa)',
        notSequencialCharacters: 'No incluir combinaciones de caracteres secuenciales (ej.: 123)',
        sameInBothFields: 'Ser la misma en ambos campos',
      },
      error: {
        base: 'La contraseña debe tener un mínimo',
        uppercase: '1 letra mayúscula',
        lowercase: '1 letra minúscula',
        number: '1 número',
        symbol: '1 símbolo',
      },
    },
    dataPrivacy: {
      title: 'Privacidad de los Datos',
      checkboxLabel: 'Recibir notificaciones por e-mail',
      checkboxExplanation: 'Con esta opción activada estarás autorizándonos a enviar mensajes promocionales y últimas actualizaciones de contenido de nuestras plataformas.',
    },
  },
  billingcycle: {
    monthly: '1 pago mensual',
    quarterly: '1 pago cada 3 meses',
    semiannually: '1 pago cada 6 meses',
    annually: '1 pago cada año',
    biennially: '1 pago cada 2 años',
    triennially: '1 pago cada 3 años',
  },
  reboot: {
    title: 'Reboot (reiniciar la máquina)',
    message: '<0>Reiniciar la máquina tardará cerca de 15 minutos</0> y durante este periodo todos los servicios dejarán de estar disponibles',
    messageWarning: 'Haz reiniciado la máquina 3 veces en menos de 1 hora. Si lo deseas, <1>crea una solicitud al equipo de soporte</1> para que te puedan ayudar a solucionar el problema.',
    history: 'Ver el historial de reboots',
    restart: 'Reiniciar la máquina',
    modalHistory: {
      title: 'Historial de Reboots',
      message: 'Conoce las últimas veces que reiniciaste tu <1>{{product}}</1>, cuyo dominio principal es: <3>"{{domain}}"</3>',
      date: 'Fecha',
      time: 'Horarios',
    },
    modalReboot: {
      title: 'Reiniciar la máquina',
      message: '¿Estás seguro que deseas reiniciar tu <1>{{product}}</1> cuyo dominio principal es: <3>"{{domain}}"</3>?',
      cancel: 'Cancelar',
      confirm: 'Sí, quiero reiniciar',
    },
    feedback: {
      error: 'No ha sido posible reiniciar la máquina. Intentalo nuevamente o entra en contacto con el equipo de soporte',
      success: 'Reboot finalizado',
    },
  },
  titanAdvertisement: {
    top: {
      title: 'Correo Profesional Titan',
      description_0: 'Impulse aún más su marca con un correo profesional ',
      description_1: '@tudominio',
      labelLink: 'Quiero saber más',
      new: 'Nuevo',
    },
    toast: {
      knowMore: 'Quiero saber más',
      description: 'Impulse aún más su marca con un correo profesional ',
      yourDomain: '@tudominio',
      title: 'Correo Profesional Titan',
      newTag: 'Nuevo',
    },
    bannerDark: {
      knowMore: 'Quiero saber más',
      description: 'Impulse aún más su marca con un correo profesional',
      title: 'Correo Profesional Titan',
      newTag: 'Nuevo',
    },
    modalGtm: {
      event: {
        one: 'gtmModalTitanOffer1',
        two: 'gtmModalTitanOffer2',
      },
    },
  },
  modalTitanAdvertisement: {
    title: '¿Para cual dominio le gustaría crear su correo profesional Titan?',
    selectDomain: 'Seleccionar dominio',
    button: 'Continuar para más informaciones',
  },
  onboarding: {
    title: {
      whatYouCreateFirst: '¿Qué deseas crear primero?',
      whatYouWantDoFirst: '¿Qué quieres hacer primero?',
      createEmailAccountsAsYouLike: 'Crea todos los correos que quieras:',
      createYourEmailsRightNow: '¡Crea tus cuentas de correo ahora mismo!',
      chooseBelowCreationType: 'Escoge la herramienta para crear tu sitio:',
      wantCreateWebsiteNow: '¿Quieres crear un sitio web ahora?',
      makeWebsiteRightNow: '¡Crea un sitio web ahora mismo!',
      domainNotRegistered: 'Este dominio no fue registrado con nosotros :/',
      getHostingsPlans: '¡Adquiere uno de nuestros planes de Web Hosting!',
      getEmailProfissional: '¡Obtenga uno de nuestros planes de correo electrónico profesional!',
      createYourEmailAccounts: 'Crea tus cuentas de correo electrónico:',
      createEmailAsYouLike: 'Crea todos los correos que quieras:',
      chooseThemeCategory: 'Selecciona la categoría del sitio y el tema:',
    },
    subtitle: {
      createEmailAsYouLike: 'Crea todos los correos que quieras.',
      ifYesChooseBelowCreationType: 'De ser así, escoge la forma de creación a continuación.',
      chooseBelowCreationType: 'Escoge la herramienta para crear tu sitio.',
      chooseOneFollowingSites: 'Selecciona uno de los sitios a continuación',
      domainNotRegistered: 'Antes de continuar, debes apuntar el DNS para un Web Hosting de HostGator. <1>Si ya ha registrado este dominio con otra compañía, simplemente siga la opción a continuación:</1>',
      hostingsPlansToCreateSite: 'Para que puedas crear tu sitio web, es necesario tener un plan de Web Hosting.',
      hostingsPlansToCreateEmail: 'Para que puedas crear los correos, es necesario tener un plan de Web Hosting.',
      NewHostingsPlansToCreateEmail: 'Para que puedas crear un correo personalizado con tu dominio, puedes contratar un plan de Correo Profesional.',
      contactUs: 'Entra ahora mismo en nuestra web y elige la que más se adapte a tus necesidades.',
      fastOrAdvanced: 'Elige cómo te gustaría crearlo, si de una forma más rápida o más tradicional...',
    },
    option: {
      title: {
        createSite: 'Crear Sitio Web',
        createEmail: 'Crear Correos',
        siteCreator: 'Creador de sitios web',
        wordpress: 'WordPress',
        manualFiles: 'Manual (Archivos)',
        fast: 'Rápido',
        advanced: 'Avanzado',
        transferDomain: 'Transferir el dominio a HostGator',
        changeDnsExternal: 'Modificar el DNS en la empresa de registro',
        changeDns: 'Alterar DNS',
      },
      description: {
        createSite: '¡Publica tu proyecto ahora mismo!',
        createEmail: 'Crea todas las cuentas de correo que quieras.',
        siteCreator: 'Un editor fácil con todos los recursos que usted necesita.',
        wordpress: 'Herramienta más utilizada y completa del mundo.',
        manualFiles: 'Administra los archivos de tu sitio web a través de FTP.',
        changeDns: 'Apunta el dominio hacia un plan o un proveedor de web hosting.',
        fast: '¡Crea tu sito web ahora mismo!',
        advanced: 'Crea tu sitio a través de un editor lleno de recursos!',
      },
    },
    action: {
      button: 'Crear Sitio Web',
      linkSkipCreateEmails: '¡No quiero crear correos ahora!',
      linkSkipCreateSite: '¡No quiero crear mi sitio web ahora!',
      hostingsPlans: 'Ver Planes de Web Hosting',
      emailProfessional: 'Compra el plan de correo electrónico profesional',
    },
    emails: {
      createPassword: 'Contraseña*',
      createEmail: 'CREAR UN CORREO MÁS',
      selectAnEmail: 'Selecione um correo electrónico*',
      limitLength: 'Su correo electrónico debe contener hasta 64 caracteres.',
      emailExists: 'Este correo electrónico ya ha sido ingresado',
    },
    changeDns: {
      title: 'Cambio de DNS',
      subtitle: 'Para realizar el cambio, <1>consulte su DNS a continuación y elija el nombre de la empresa donde registró su dominio:</1>',
      note: 'Si no hiciste el registro en ninguna de estas empresas, por favor, solicita ayuda al equipo de soporte de la empresa donde contrataste el dominio.',
      link: 'Ir al portal del cliente',
    },
    transferDomain: {
      title: 'Transferencia de dominio',
      requirementsMessage: 'Asegúrate de que <1>todos los requisitos</1> para esta transferencia estén a día. <3>Este procedimiento puede llevar hasta 6 horas.</3>',
      eppMessage: 'Para que podamos realizar la transferencia de dominio, <1>solicita el código EPP al equipo de soporte de la empresa donde contrataste el dominio</1> y coloca la información en el campo a continuación. Este procedimiento puede llevar hasta 6 horas.',
      eppError: 'Campo obligatorio',
      label: 'Digita el código EPP aquí',
      link: 'Transferir dominio',
      feedback: 'Se ha abierto el llamado {{ ticket }} para la transferencia de tu dominio. Acompaña este llamado en la página de Soporte.',
    },
    obs: 'Si aún no has contratado un dominio. <1>¡Registralo ahora mismo!</1>',
    wordpress: {
      plan: {
        limitedPrefix: 'Crea hasta',
        limitedSuffix: 'cuentas de correo.',
        unlimited: 'Crea todos los correos que quieras.',
      },
    },
  },
  modalDomainAutoRenovation: {
    title: 'Habilitar la renovación automática',
    description: 'Si el método de pago es PayPal o Tarjeta de Crédito, el cobro se realizará automáticamente. Si el método es cupón de pago, la factura se emitirá 30 días antes de la fecha de vencimiento.',
    confirmLabel: 'Confirmar',
  },
  modalSetPassword: {
    setTitle: 'Definir la contraseña de acceso',
    editingTitle: 'Cambiar Contraseña de Acceso',
    message: 'Para comenzar a usar tu <1>{{ product }}</1>, define una contraseña de acceso:',
    setBtn: 'Definir la contraseña',
    changeBtn: 'Cambiar contraseña',
    passwordLabel: 'Contraseña',
    confirmPasswordLabel: 'Confirmar contraseña',
    newPasswordLabel: 'Nueva contraseña',
    confirmNewPasswordLabel: 'Confirmar nueva contraseña',
    required: 'Completa este campo',
    minimumRequirements: 'Tu contraseña no cumple con los requisitos mínimos de seguridad',
    confirmPassword: 'Las contraseñas no coinciden',
  },
  emailVerify: emailValidation.MX,
  phoneVerify: phoneValidation.MX,
  linkNaBio: linkNaBio.MX,
  courses: {
    info: 'Contenido gratuito con consejos y tutoriales para ayudarlo a comenzar a construir su sitio web.',
    menuTitle: 'Contenido del curso',
    classes: 'clases',
    seeClasses: 'Ver Clases',
    minutes: 'min',
    watched: 'Visto',
    vimeoServiceDown: 'No era posible buscar cursos en este momento. Por favor, inténtelo de nuevo más tarde.',
    certificate: {
      download: 'Descargar Certificado',
      available: 'Su Certificado estará disponible para descargar después de ver todos los videos.',
      progress: 'Completado',
      title: 'Certificamos que',
      conclusion: 'Ha completado con éxito el curso',
      hours: 'on una carga horaria de {{hours}}h',
      date: 'Fecha',
      founder: 'Fundador de HostGator',
      fileName: 'certificado',
    },
  },
  sitesWizard: sites.MX.wizard,
  modalDnsAutomatic: {
    title: 'Propagación de dominio en curso....',
    button: 'Continuar creando el sitio',
    descriptionOne: {
      0: 'La configuración del dominio (cambio de DNS) ya se ha realizado, pero las informaciones están en ',
      1: 'proceso de propagación',
      2: ' y esto puede demorar hasta 24h.',
      3: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
    },
    descriptionTwo: {
      0: 'Mientras tanto, ',
      1: 'siga creando su sitio web. Estará en línea después de completar esta etapa.',
    },
  },
  domains: domains.MX,
  dialogPaypal: {
    title: 'Pagar con PayPal',
    titleContainer: 'Cartera digital Paypal',
    featureTitleFirst: 'Tecnología',
    featureDescriptionFirst: 'Tarjeta protegida por criptografía',
    featureTitleSecond: 'Seguridad',
    featureDescriptionSecond: 'Al almacenar sus datos',
    featureTitleThree: 'One TouchTM',
    featureDescriptionThree: 'Pague con un clic',
  },
  plan: {
    detail: 'Administrar',
    expand: 'Ver sitios',
    status: {
      active: 'Activo',
      pending: 'Abierto',
      in_analysis: 'En análisis',
      on_configure: 'En configuración',
      cancelled: 'Cerrado',
      suspended: 'Suspendido',
      expired: 'Caducado',
    },
    warning: {
      pending: 'Aún no hemos identificado su pago. <1>Atención: los pagos mediante boleto pueden tardar hasta 72 horas en identificarse.</1>',
      in_analysis: 'Su plan está bajo análisis de registro, este proceso puede demorar hasta 24 horas. Después de este período, tendrá acceso a la creación y administración de sus sitios.',
      on_configure: 'Se está configurando su servidor, este proceso puede tardar hasta X horas. Pasado este período, tendrá acceso a la gestión de su plan.',
      cancelled: 'Se canceló su plan de alojamiento y se eliminaron todos los archivos. <1> Contrata un nuevo plan a través de nuestro sitio web.</1>',
      suspended: 'Este plan está suspendido. Realice el pago para reactivarlo. <1>Atención: los pagos mediante boleto pueden tardar hasta 72 horas en identificarse.</1>',
      expired: 'Debido a la falta de pago, su plan de hosting ha expirado y todos sus sitios y archivos han sido eliminados. <1> Contrata un nuevo plan a través de nuestro sitio web. </1>',
    },
    tooltip: {
      disabled: 'Esta acción está deshabilitada debido al estado en el que se encuentra actualmente su producto.',
      active: 'Su hosting está activo. Usted tiene acceso a todas las funcionalidades de su plan de hosting accediendo a la administración de al lado.',
      pending: 'Aún no hemos identificado su pago.  Atención: los pagos por boleto pueden tardar hasta 72 horas en ser identificados.',
      suspended: 'Este plan está suspendido. Realice el pago para reactivarlo. Atención: los pagos por boleto pueden tardar hasta 72 horas en ser identificados.',
      expired: 'Debido a la falta de pago, su plan de hosting expiró y todos sus sitios web y archivos fueron borrados.  Contrate un nuevo plan a través de nuestro sitio web',
    },
    filter: {
      active: 'Sitios Activos',
      pending: 'Sitios Abiertos',
      in_analysis: 'Sitios en análisis',
      on_configure: 'Sitios en configuración',
      cancelled: 'Sitios Cerrados',
      suspended: 'Sitios Suspendidos',
      expired: 'Sitios Caducados',
    },
  },
  site: {
    websitebuilder: '(Creador de Sitios)',
    action: {
      active: 'Administrar',
      notConfigured: 'Configurar Dominio',
    },
    status: {
      not_configured: 'Dominio no configurado',
    },
  },
  oldReseller: {
    ResellerSuspendedMessage: '<0>Tienes uno o más planes afectados por la </0><1>actualización de los precios y planes de Reseller Hosting.</1><2> Deberás </2><3>escoger un nuevo plan</3><4> para evitar que tus datos sean eliminados permanentemente.</4>',
    ResellerAffectedMessage: '<0>Tienes uno o más planes afectados por la </0><1>actualización de los precios y planes de Reseller Hosting.</1><2> Deberás </2><3>escoger un nuevo plan</3><4> en tu próxima renovación.</4>',
    cardSuspendedMessage: '<0>Tu plan {{productname}} ha sido suspendido y será anulado el día {{invoicedate}}. </0><1>Escoge un nuevo plan</1><2> y evita que tus datos sean eliminados permanentemente.</2>',
    cardAffectedMessage: '<0>Tu plan {{productname}} vence el día {{invoicedate}}. </0><1>Debido a la </1><2>actualización de los planes Reseller</2><3>, tu próxima factura de renovación será anulada automáticamente. Deberás </3><4>escoger un nuevo plan</4><5> al momento de renovar el servicio.</5>',
    cardAffectedMessageTilTenDays: '<0>Tu plan {{productname}} vence el día {{invoicedate}} y entrara en suspensión 10 días hábiles después de esa fecha. </0><1>Debido a la </1><2>actualización de los planes Reseller</2><3>, tu próxima factura de renovación será anulada automáticamente. Deberás </3><4>escoger un nuevo plan</4><5> al momento de renovar el servicio.</5>',
    ModalTitle: 'Planes pendientes',
    ModalOldResellerMessage: '<0>Tienes uno o más planes afectados por la </0><1>actualización de los precios y planes de Reseller Hosting.</1><2> Escoge un nuevo plan que se adapte a tus necesidades.</2>',
    productPlan: 'Plan',
    productStatus: 'Status del plan',
    productCancelation: 'Anulación en',
    productExpiration: 'Vence em',
    ModalCta: 'ESCOGER NUEVO PLAN',
    Active: 'Activo',
    Pending: 'Pendiente',
    Pending_Transfer: 'Todavía sin transferir',
    Expired: 'Vencido',
    Cancelled: 'Anulado',
    Fraud: 'Fraude',
    Suspended: 'Suspendido',
    Building: 'En Configuración',
  },
  proEmail: {
    searchDomainLabel: 'Buscar nombre del dominio',
    professionalTitanMailLabel: 'Correo profesional Titan',
    freeMailLabel: 'Correo gratuito del hosting',
    showMore: 'Mostrar más',
    showLess: 'Mostrar menos',
    infoPaidMailLabel: 'Las soluciones de correo electrónico profesional Titan Essential y Premium son perfectas para negocios en expansión, ya que ofrecen más funciones que el correo electrónico básico del hosting.',
    infoFreeMailLabel: 'El correo electrónico gratuito es un beneficio de su hosting. Usted puede usar la solución de correo electrónico básico de cPanel o el correo electrónico profesional Titan en su versión gratuita.',
    domainFilterLabel: 'hospedajens',
    subscriptionsLabel: 'Suscripción',
    domainLabel: 'Dominio',
    mailBox: 'Cuenta de Correo',
    titanLabel: 'Titan',
    cPanelLabel: 'cPanel',
    createFirstAccount: 'Cree su primera cuenta',
    createFirstAccountMenu: 'Cree su primera cuenta',
    createEmail: 'Definir plataforma de correo',
    managerEmail: 'Administrar Correos',
    hostingLabel: 'Hospedaje',
    dueDate: 'Fecha de Vencimiento',
    terminatedDate: 'Data de Cancelación',
    provisioningCardStatusNew: 'Activando Período de Prueba',
    pendingCardStatusNew: 'En Espera de Pago',
    toDueDateCardStatus: 'Por vencer',
    expiredCardStatusNew: 'Vencida',
    suspendedCardStatusNew: 'Suspendida',
    terminatedCardStatusNew: 'Cancelada',
    activeCardStatusNew: 'Activo',
    renovationButton: 'Activar Renovación',
    almostCanceladCardStatus: '<0>!Cancelación próxima - </0>{{amount}}<2> dias</2>',
    trialCardStatus: '{{amount}}<2> días disponibles de prueba</2>',
    trialCardStatusToDueDate: '{{amount}}<2> días finales de prueba grátis</2>',
    trialCardStatusOverDueDate: '{{amount}}<2> días para que se cancele su suscripción</2>',
    trialCardStatusTerminated: 'Período de prueba gratuito terminado',
    provisioningCardStatusInfo: 'Estamos activando su plan de correo electrónico y en instantes usted podrá aprovechar todos los beneficios del período de prueba.',
    pendingCardStatusInfo: 'Estamos esperando la confirmación de pago. La compensación de Cupones de pago puede tardar hasta 72 horas. Si ya ha pago, por favor espere.',
    infoAutomaticRenovation: 'La renovación automática de la suscripción ha sido cancelada. Active la renovación y evite que sus datos se eliminen permanentemente después de la fecha de vencimiento.',
    uponDueDateCardStatusInfo: 'Su suscripción vencerá pronto. Pague su factura y no se arriesgue a perder sus correos electrónicos.',
    uponDueDateTrialCardStatusInfo: 'El período de prueba está finalizando y la renovación de la suscripción caducará pronto. Pague su factura y no se arriesgue a perder sus correos electrónicos.',
    infoDueDateCardStatus: 'Su suscripción vencerá pronto. Pague su factura y no se arriesgue a perder sus correos electrónicos.',
    expiredCardStatusInfo: 'Su suscripción ha vencido y se suspenderá pronto. Pague su factura y no se arriesgue a perder sus correos electrónicos',
    suspendedCardStatusInfo: 'Su suscripción ha sido suspendida por falta de pago. Pague su factura para que no sea cancelada y sus datos sean eliminados definitivamente',
    terminatedCardStatusInfo: 'Su suscripción ha sido cancelada por falta de pago.',
    titleConfirmRenovation: '¿Quiere activar la renovación de la suscripción?',
    descriptionConfirmRenovation: 'El cobro del pago será realizado al final del ciclo, evitando que su producto sea cancelado y sus datos y archivos se eliminen permanentemente.',
    bottonConfirmRenovation: 'CONFIRMAR ACTIVACIÓN',
    titanIconInfo: 'Plataforma de Correo Titan',
    cPanelIconInfo: 'Plataforma de Correo cPanel',
    alertConfirmRenovation: 'La renovación de la suscripción (Nome da assinatura) fue activada',
    contractNewEmailPlan: 'Contratar Correo',
    contractNewEmailPlanMobile: 'Contratar Correo',
    chipLinkLabel: 'Configurar correo electrónico',
    activeTrialTitle: 'Cree su primera cuenta de correo profesional Titan Essentials',
    activeTrialDescription: 'El Correo Electrónico Profesional es una solución de HostGator para que los emprendedores tengan una dirección de correo personalizado con el nombre de su empresa.',
    activeTrialInfoPersonalize: 'Correo personalizado @tudominio',
    activeTrialInfoSpace: '10 GB de almacenamiento por cuenta',
    activeTrialInfoApp: 'Webmail y Aplicaciones para Android y iOS',
    activeTrialInfoAntv: 'Protección anti-spam y antivirus',
    activeTrialInfoCalendar: 'Calendarios y contactos integrados',
    activeTrialInfoMore: '¡Y mucho más!',
    activeTrialEmailAddress: 'Dirección de correo',
    placeHolderEmailAddress: 'Ej: contacto',
    activeTrialDomain: 'Dominio',
    activeTrialPassword: 'Contraseña',
    errorActivateTrial: 'Algo salió mal. Inténtelo nuevamente más tarde.',
    errorDomainRegistration: 'El dominio no está registrado. Elija un dominio que esté registrado para activar el período de prueba',
    successAtivateTrial: 'En instantes podrá aprovechar todos los beneficios del período de prueba.',
    accessEmailTrial: 'Haga clic aquí para ingresar a su correo',
    activeTrialButtonStart: 'Iniciar Prueba',
    activeTrialPosDescription: '<0>Después del periodo de prueba gratis, la suscripción mensual es </0>{{amount}}<1> por cuenta de correo, con renovación automática. Cancele cuando quiera. <1>',
    activeTrialTerms: 'Términos del servicio',
    activeTrialFreeTime: '30 DÍAS GRATIS DE PRUEBA',
    ilimitedMailBoxes: 'buzones de correo electrónico ilimitados',
    cpanelListInfo: 'Usted tiene una cuenta de correo electrónico vinculada a cPanel en este dominio. Manténgase actualizado con su plan de hosting para que su correo electrónico continúe funcionando sin problemas.',
    configAccountListInfo: 'Esta cuenta de correo electrónico está vinculada a un web hosting. Mantenga actualizado su plan de web Hosting para que no afecte el funcionamiento de su correo electrónico.',
    listName: 'Listado de Planes',
    activeCardStatus: 'Activo',
    pendingCardStatus: 'Pendiente',
    provisioningCardStatus: 'Pendiente',
    suspendedCardStatus: 'Suspendido',
    terminatedCardStatus: 'Cancelado',
    expiredCardStatus: 'Expirado',
    nullCardStatus: 'Sin Plan de Correo Electrónico',
    almostExcludedCardStatus: '<0>!Suspensión próxima - </0>{{amount}}<2> dias</2>',
    accountsUsed: 'Número de Cuentas de Correo',
    expiringDate: 'Vencimiento',
    managementLabel: 'Administrar',
    contractNewPlanLabel: 'CONTRATAR NUEVO PLAN DE CORREO ELECTRÓNICO ',
    checkAvailabilityLabel: 'CONSULTAR DISPONIBILIDAD',
    domainNoAvailabilityTitleLabel: 'El dominio seleccionado no está registrado.',
    domainNoAvailabilityDescriptionLabel: 'Para comprarlo, compruebe la disponibilidad en nuestro sitio web.',
    hostingPlansTitleLabel: 'El dominio {{domain}} está vinculado a más de un plan de hosting.',
    hostingPlansDescriptionLabel: 'Para continuar, seleccione abajo qué plan de hosting le gustaría vincular al nuevo plan de correo electrónico.',
    hostingPlansCreateDateLabel: 'Fecha de Creación',
    hostingPlansDueDateLabel: 'Vencimiento',
    hostingPlansViewPlansLabel: 'Ver planes de Correo Electrónico',
    billButton: 'Pagar Factura',
    failedToLoadList: 'No se puede cargar la lista de correo electrónico profesional.',
    getPlanLink: 'CONTRATAR PLAN DE CORREO ELECTRÓNICO',
    createEmailPlan: 'CREAR PLAN DE CORREO ELECTRÓNICO',
    notifications: {
      activateRenewal: {
        success: {
          0: 'La renovación del ',
          1: ' fue activado',
        },
        failure: {
          0: 'La activación de la renovación del ',
          1: ' no se pudo completar. Por favor, inténtelo de nuevo más tarde.',
        },
      },
    },
    type: {
      titan: 'TITAN',
      cpanel: 'cPanel',
      free: 'Plan de Correo Gratis',
    },
    emptyList: {
      title: 'No encontramos ningún resultado para su búsqueda :(',
      description: 'Puede intentar buscar otra palabra clave para encontrar lo que está buscando.',
    },
    noPlan: 'Sin plan de correo',
    proemail: {
      pendingMessage: '<0>Pendiente de pago. Realice el pago de este plan para tener acceso a la creación y administración de sus correos electrónicos.</0> <1>Atención: Los pagos por boleto pueden tardar hasta 72 horas en ser identificados.</1>',
      freemiumPendingMessage: '<0>Pendiente de pago. Realice el pago de su Plan de Hosting para tener acceso a la creación y administración de sus correos electrónicos.</0> <1>Atención: Los pagos por boleto pueden tardar hasta 72 horas en ser identificados.</1>',
      suspendedMessage: '<0>Este plan está suspendido. Realice el pago para reactivarlo.</0> <1>Atención: Los pagos por boleto pueden tardar hasta 72 horas en ser identificados.</1>',
      terminatedMessage: '<0>Según lo solicitado, su plan ha sido cancelado y todos los datos y registros han sido borrados.</0> <1>Contrate un nuevo plan a través de nuestro sitio web.</1>',
      expiredMessage: '<0>Debido a la falta de pago su plan de correo electrónico profesional ha expirado y todos los datos han sido borrados.</0> <1>Contrate un nuevo plan en nuestro sitio web.</1>',
      almostExpiredMessageFree: '<0>El plan de alojamiento vinculado a su cuenta de correo electrónico gratuita de TITAN ha expirado.</0> <1>Actualice su plan</1> <2>para mantenerte activo.</2>',
      almostExpiredMessageTitan: '<0>Este plan de correo electrónico ha vencido y pronto entrará en suspensión. Realice el pago de su </0><1>factura de renovación</1><2> para mantenerlo activo. </2><3>Atención: Los pagos por cupón de pago pueden tardar hasta 72 horas en identificarse.</3>',
      almostExpiredMessageCpanel: '<0>El plan de hosting al que está vinculado su correo electrónico ha expirado. Realice el pago de su </0><1>factura de renovación</1><2> para mantener su plan de correo electrónico activo. </2><3>Atención: Los pagos por cupón de pago pueden tardar hasta 72 horas en identificarse.</3>',
    },
    cpanel: {
      basic: 'Plan básico',
      pendingMessage: '<0>Pendiente de pago. Realice el pago de su plan de alojamiento para tener acceso a la creación y administración de sus correos electrónicos.</0> <1>Atención: Los pagos por boleto pueden tardar hasta 72 horas en ser identificados.</1>',
      suspendedMessage: '<0>Este plan está suspendido. Realice el pago de su plan de alojamiento para reactivarlo.</0> <1>Atención: Los pagos por boleto pueden tardar hasta 72 horas en ser identificados.</1>',
      terminatedMessage: '<0>Según lo solicitado, su plan de alojamiento ha sido cancelado y todos los datos y registros han sido borrados.</0> <1>Contrate un nuevo plan a través de nuestro sitio web.</1>',
      expiredMessage: '<0>Debido a la falta de pago su plan de alojamiento ha expirado y todos los datos han sido borrados.</0> <1>Contrate un nuevo plan en nuestro sitio web.</1>',
    },
    filterOptions: {
      all: 'Todos os Dominios',
      proemail: 'Con correo electrónico Titan',
      cpanel: 'Con Correo electrónico Básico del Hosting',
      withoutPlan: 'Dominio sin plan de correo electrónico',
    },
    upgrade: {
      underConstruction: {
        title: 'La actualización del plan aún no está disponible para este producto :/',
        description: 'Ya estamos trabajando para pronto ofrecer una solución.',
        managementButton: 'VOLVER A LA ADMINISTRACIÓN DEL PLAN',
      },
    },
    hirePlan: {
      annually: 'Anual',
      bestFormatToPayment: 'Seleccione la mejor forma de pago para usted:',
      containerDescription: 'Use las siguientes opciones para solicitar la contratar su nuevo plan de correo electrónico profesional.',
      containerDescriptionOld: 'Elija el plan de correo electrónico ideal para su proyecto',
      newContainerDescription: 'Elija el plan de correo electrónico profesional ideal para su negocio:',
      free: 'GRATIS',
      hireCTA: 'CONTRATAR PLAN',
      mailBoxes: 'Buzones de Correo Electrónico',
      monthly: 'Mensual',
      paymentCycle: 'Ciclo de Pago',
      perMailBoxLabel: 'mes / por cuentas de correo',
      perMailBoxLabelOld: 'por cuentas de correo',
      descriptionFreemium: 'Su plan de Web Hosting tiene un plan de correo electrónico Titan gratuito con 1 GB de almacenamiento por cuenta de correo.',
      activeFreemium: 'ACTIVAR GRATIS',
      freeMailBoxLabel: 'para usar cuando quiera',
      perMonth: '/mes',
      perYear: '/año',
      monthPayment: 'pagamento mensual',
      yearPayment: 'pago anual',
      recommended: ' (Recomendado)',
      save: 'Ahorros',
      savedPercent: '<0>{{savedAmount}}</0><1>% de descuento</1>',
      total: 'Total del ',
      totalOld: 'Total:',
      weRecommend: 'Le recomendamos',
      cpanelChangeTitle: '¿Prefiere usar un webmail básico de cPanel?',
      cpanelChangeDescription: '<0>A diferencia de los planes de correo electrónico profesional Titan, las cuentas de correo electrónico básico de cPanel (Roundcube)</0><1> utilizan el espacio de su hosting</1> <2>para almacenar los mensajes y archivos de correo electrónico.</2>',
      cpanelEnableChange: 'Activar correo electrónico básico de cPanel',
      cpanelDialogActiveTitle: '¿Continuar como cPanel?',
      cpanelDialogActiveDescription: '<0>Los siguientes dominios añadidos a </0><1>{{productName}}</1><2> tendrán correos electrónicos cPanel activados automáticamente.</2>',
      cpanelDialogActiveDescriptionAdvice: 'Al seleccionar la opción de correo electrónico, no podrá deshacer la acción, es decir, una vez que haya elegido el correo electrónico de cPanel, no podrá cambiar para Titan gratuito.',
      cpanelDialogActiveAction: 'CONTINUAR COMO CPANEL',
      continueFreePlanTitle: '¿Continuar como Titan Gratis?',
      continueFreePlanDescription: '<0>Los siguientes dominios añadidos a </0><1>{{hostingName}}</1><2> tendrán correos electrónicos Titan Gratis activados automáticamente.</2>',
      continueFreePlanDescriptionAdvice: 'Al seleccionar la opción de correo electrónico, no podrá deshacer la acción, es decir, una vez que haya elegido el correo electrónico de Titan, no podrá cambiar para cPanel',
      continueFreePlanAction: 'CONTINUAR COMO TITAN GRATIS',
      successActivateFreePlanTitle: '¡Correo Electrónico Titan Gratis activado con éxito! ',
      successActivateFreePlanDescription: 'Todo listo para que usted añada su primera cuenta de correo electrónico',
      successActivateFreePlanAction: 'AÑADIR CUENTA DE CORREO ELECTRÓNICO ',
      successMoveCPanelTitle: '¡Correo Electrónico cPanel activado con éxito! ',
      successMoveCPanelDescription: 'Todo listo para que usted añada su primera cuenta de correo electrónico',
      successMoveCPanelAction: 'AÑADIR CUENTA DE CORREO ELECTRÓNICO',
      hostingPlan: 'plan de hosting',
      titanBasic: {
        topLabel: 'Gratuito del Web Hosting',
        productName: 'Titan Básico',
        gb: '1Gb',
        storage: 'de almacenamiento',
        ctaButton: 'Activar Titan Básico',
        description: 'Titan Básico es una opción de cuenta de correo electrónico ilimitada y gratuita que no utiliza el espacio de almacenamiento de su alojamiento.',
        link: 'Conozca más',
      },
      titanEssentials: {
        topLabel: '30 días gratis de prueba',
        productName: 'Titan Essentials',
        gb: '10Gb',
        storage: 'de almacenamiento',
        ctaButton: 'Pruébelo Gratis',
        // eslint-disable-next-line no-template-curly-in-string
        description: '<0>Después del período de prueba gratuito, la suscripción mensual es de </0><1>${{price}}</1><2> por cuenta de correo electrónico con renovación automática ,se puede cancelar en cualquier momento.</2>',
        link: 'Términos del Servicio',
      },
      titanPremium: {
        topLabel: 'RECOMENDADO',
        productName: 'Titan Premium',
        gb: '50Gb',
        storage: 'de almacenamiento',
        ctaButton: 'Activar ahora',
        description: 'Más espacio de almacenamiento y potentes funciones de productividad',
        link: 'Términos del Servicio',
        monthlyPriceEachEmail: 'Por cuenta de correo',
        perMonth: '/mes',
        cycleTitle: 'Ciclo',
        paymentMethodTitle: 'Forma de pago',
        cycles: {
          monthly: '1 mes',
          quarterly: '3 meses',
          semiannually: '6 meses',
          annually: '1 año',
          biennially: '2 años',
        },
        totalPrice: '<0>Total </0><1>{{price}} </1><2>en el </2><3>ciclo {{cycle}}</3><4> para </4><5>{{numberAccounts}} </5><6>cuenta de correo electrónico.</6>',
        totalPriceMoreThanOne: '<0>Total </0><1>{{price}} </1><2>en el </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>cuentas de correo electrónico.</6>',
      },
      titanUltra: {
        topLabel: 'MÁS COMPLETO',
        productName: 'Titan Ultra',
        gb: '100GB',
        storage: 'de almacenamiento',
        ctaButton: 'Activar ahora',
        description: 'Más espacio de almacenamiento y potentes funciones de productividad',
        link: 'Términos del Servicio',
        monthlyPriceEachEmail: 'Por cuenta de correo',
        perMonth: '/mes',
        cycleTitle: 'Ciclo',
        paymentMethodTitle: 'Forma de pago',
        cycles: {
          monthly: '1 mes',
          quarterly: '3 meses',
          semiannually: '6 meses',
          annually: '1 año',
          biennially: '2 años',
        },
        totalPrice: '<0>Total </0><1>{{price}} </1><2>en el </2><3>ciclo {{cycle}}</3><4> para </4><5>{{numberAccounts}} </5><6>cuenta de correo electrónico.</6>',
        totalPriceMoreThanOne: '<0>Total </0><1>{{price}} </1><2>en el </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>cuentas de correo electrónico.</6>',
      },
    },
    upgradePlan: {
      whatIsincluded: 'QUE ESTÁ INCLUIDO',
      whatIsincludedInPaid: 'Todo de titan basic, más',
      downgradeMaessage: '<0>En caso de que quiera reducir el número de cuentas de correo, es necesario eliminarlas a través del link </0><1>Cuentas de Correo<1/><2></2>',
      changeInboxLabel: 'Cambiar',
      changeInboxLabelAlt: 'editar cantidad de inbox',
      changePlan: 'CAMBIAR PLAN',
      modalTitle: 'Seleccionar Cuentas de Correo',
      modalDescription: 'Use los botones a continuación para seleccionar la cantidad de cuentas de correo que le gustaría tener en su Plan de Correo Electrónico. <1>El valor de su plan será proporcional a la cantidad de cuentas de correo contratadas.</1>',
      modalPlanTitle: 'Plan',
      modalPerMailTitle: 'Valor por cuenta de correo',
      modalRecurrentTitle: 'Valor Recurrente',
      modalCancel: 'Cancelar',
      modalSave: 'Ahorrar',
      upgradeMemorial: {
        initialSingleInbox: 'cuenta',
        initialMultipleInbox: 'cuentas',
        singleInbox: 'cuenta',
        multipleInbox: 'cuentas',
        singleDays: 'dia',
        multipleDays: 'días',
        monthlyCycle: '30 dias',
        annuallyCycle: 'un año',
        singleRemnant: 'queda',
        multipleRemnant: 'quedan',
        singleCapsThis: 'Esto',
        multipleCapsThis: 'Estos',
        singleRemnantDays: 'restante',
        multipleRemnantDays: 'restantes',
        singleEquivalent: 'equivale',
        multipleEquivalent: 'equivalen',
        singleRemaining: 'restante',
        multipleRemaining: 'restantes',
        upgradeDefault: '<0>Usted ya pago {{amountUsed}} para usar {{cycleText}} su </0><1>{{planName}} - Titan con {{initialInboxAmount}} {{initialInboxText}} de correo </1><2>y todavía le {{remnantText}} {{remnantDays}} {{daysText}} para usar. {{thisTexts}} {{daysText}} {{remnantDaysText}} {{equivalentText}} a {{refund}} que serán devueltos en forma de descuento en su factura al realizar la actualización al </2><3>{{newPlanName}} - Titan con {{inboxAmount}} {{inboxText}} de correo.</3>',
        upgradeNegativeDays: '<0>Usted ya pagó {{amountUsed}} para usar {{cycleText}} en su </0><1>{{planName}} - Titan con {{initialInboxAmount}} {{initialInboxText}} de Correo Electrónico. </1><2>Su plan venció, pero permanecerá activo por más {{remnantDays}} {{daysText}}. Como usted ya usó el plan por el tiempo contratado, no se aplicará ningún descuento a su factura y al actualizar a un </2><3>{{newPlanName}} - Titan con {{inboxAmount}} {{inboxText}} de Correo Electrónico</3><4>, su suscripción se renovará automáticamente por otros {{cycleText}}.</4>',
        upgradeZeroDays: '<0>Usted ya pagó {{amountUsed}} para usar {{cycleText}} en su </0><1>{{planName}} - Titan con {{initialInboxAmount}} {{initialInboxText}} de Correo Electrónico. </1><2>Su plan actual vence hoy y como usted ya lo usó durante el tiempo contratado, no se aplicará ningún descuento a su factura. Al actualizar a un </2><3>{{newPlanName}} - Titan con {{inboxAmount}} {{inboxText}} de Correo Electrónico</3><4>, su suscripción se renovará automáticamente por otros {{cycleText}}.</4>',
      },
      modalOldInvoice: {
        title: '¿Desea cancelar la factura abierta y generar una nueva?',
        subtitle: 'Usted tiene una factura abierta para este plan',
        ongoingTitle: 'Factura abierta',
        viewOngoingInvoiceLabel: 'Ver factura',
        ongoingSubtitle: 'Al hacer clic en “Generar nueva factura” esta factura será cancelada',
        mailBoxItem: 'Cuentas de correo',
        totalValue: 'Saldo a Pagar',
        warning: 'Si ya ha pagado esta factura, solo espere la activación automática de su plan. Los pagos por Cupón de Pago pueden tardar hasta 72 horas en procesarse.',
        close: 'CERRAR',
        execute: 'GENERAR NUEVA FACTURA',
        pendingLabel: 'Pendiente',
        aplliedCredit: 'Descuentos aplicados',
      },
      actualPlan: 'Plan actual',
      newPlanCardTitle: 'Planes disponibles',
      newPlanCardDescription: 'Seleccione una de las siguientes opciones para solicitar la actualización de su plan y recibir más espacio de almacenamiento para sus buzones de correo:',
      storage: 'de almacenamiento',
      monthlyPerMailBox: ' ao mês / conta de e-mail',
      freeTrialPeriod: 'em Período Gratuito',
      annuallyPerMailBox: '/año por cuentas de correo',
      savedPercent: '<0>( </0>{{savedAmount}}<1>% de descuento )</1>',
      instantAccess: '( Acceso inmediato al plan )',
      paymentMethod: 'Forma de Pago',
      invoiceCardTitle: 'Factura',
      buyMoreAccountsLink: 'Quiero comprar más cuentas de correo para mi Plan de Correo Electrónico TITAN.',
      boughtMailBoxes: 'Cuentas de correo',
      valuePerMailBox: 'Valor por cuenta de correo',
      recurrentValue: 'Valor recurrente',
      nextDueDate: 'Vencimiento ',
      changePlanTo: '<0>Actualización para {{planName}} {{cycle}} con {{accounts}} cuentas de correo electrónico.</0><1>Periodo de contratación: ({{from}} - {{to}})</1>',
      freeBenefits: "<ul><li><strong>1 GB</strong> de almacenamiento por cuenta</li><li>Webmail y aplicaciones para Android e iOS</li><li>Soporte con Outlook, Gmail y otras aplicaciones</li><li>Potente filtro antispam</li><li>Protección antivirus avanzada</li><li>Calendario y contactos integrados <span title='Con Titan puede mantener sus contactos organizados y acceder a otros calendarios, como Google o Outlook, para tener un resumen completo de su día.'>i</span></li><li>Soporte multi-cuentas<span title='Configure cuentas de correo electrónico esenciales como ventas@tudominio.com y contacto@tudominio.com y cambielas sin esfuerzo con el soporte multi-cuenta de Titan.'>i</span></li><li>Importación de correos electrónicos y contactos existentes con un solo clic<span title='Una nueva cuenta de correo electrónico profesional no significa necesariamente dejar atrás sus antiguos contactos de correo electrónico. Las herramientas de importación integradas de Titan facilitan el inicio de una nueva cuenta.'>i</span></li></ul>",
      error: 'Ocurrió un error inesperado. inténtalo más tarde.',
      success: 'Su factura ha sido pagada y el plan de correo electrónico se ha cambiado correctamente.',
      successWithCredits: 'Los créditos de su cuenta se aplicaron al pago de la factura y el cambio al plan de correo electrónico se realizó correctamente.',
      upgradeMailBoxTitle: 'Comprar Cuentas de Correo',
      upgradeMailBoxDescription: 'Seleccione la cantidad de cuentas de correo electrónico que le gustaría tener a continuación y proceda a realizar el pago.',
      upgradeMailBoxSelectMailBoxAmount: 'Seleccionar Cuentas de Correo',
      upgradeMailBoxValuePerMailBoxAmount: 'Valor por Buzón de Correo Electrónico',
      upgradeMailBoxMinExplanation: 'Ya tienes {{min}} buzones de correo electrónico creados en esta cuenta, por lo que no es posible reducir esta cantidad.',
      monthly: '/mes',
      Monthly: 'Mensual',
      quarterly: '/trimestral',
      Quarterly: 'Trimestral',
      semiannually: '/semestral',
      Semiannually: 'Semestral',
      annually: '/año',
      Annually: 'Anual',
      Biennially: 'Bienal',
      biennially: '/bienal',
      creditCard: 'Tarjeta de Crédito',
      gp_boleto: 'Cupón de Pago',
      gp_paypal: 'PayPal',
      upgradeMailBoxTotalValue: 'Valor Total',
      upgradeMailBoxTotalValueExplanation: '<0>Este es el valor total recurrente que usted pagará mensualmente por su </0> {{planName}} <2>- TITAN después de cambiar su plan.</2>',
      upgradeMailBoxPaymentCycle: 'Ciclo de Pago',
      upgradeMailBoxNextDueDate: 'Próximo Pago',
      upgradeMailBoxNextDueDateExplanation: '<0>Esta es la nueva fecha de vencimiento de su factura para el plan</0> {{planName}} <2>- TITAN</2>',
      upgradeMailBoxAdvertisement: 'Los días restantes en el ciclo actual de su plan se aplicarán en forma de crédito en esta factura.',
      invoicesTitle: 'Factura',
      description: 'Descripción',
      value: 'Valor',
      upgradeMessage: '<0>Actualizar de </0>{{from}}<2> para </2>{{to}}<4> cuentas de correo </4>',
      downgradeMessage: '<0>Degradar de </0>{{from}}<2> para </2>{{to}}<4> cuentas de correo </4>',
      subTotal: 'Sub Total',
      apllyableCredit: 'Descuentos disponibles en esta factura',
      toPay: 'Saldo a Pagar',
      remnantCredit: 'Créditos restantes en la cuenta',
      remnantCreditExplanation: 'Los cŕeditos restantes se guardarán en su cuenta y se aplicarán en las próximas facturas.',
      changeCycleOrPlanLink: 'Quiero cambiar mi plan de correo electrónico y/o el ciclo de pago.',
      selectOther: 'Seleccione un nuevo valor de casilla de correo electrónico para continuar con el cambio de plan.',
      selectOtherCycle: 'Seleccione un nuevo plan o cambie el ciclo de pago para generar y pagar su factura.',
      newSelectOtherCycle: 'Seleccione un nuevo plan y defina el ciclo de pago para ver su factura actualizada.',
      selectOtherCycleFromFree: 'Seleccione un nuevo plan para generar y pagar su factura.',
      monthlyRecurrentValueTooltip: '<0>Esta es la cantidad total recurrente que pagará mensualmente por su </0> {{planName}}<1> - TITAN después del cambio.</1>',
      annuallyRecurrentValueTooltip: '<0>Esta es la cantidad total recurrente que pagará anualmente por su </0> {{planName}}<1> - TITAN después del cambio.</1>',
      newNextduedateTooltip: '<0>Esta es la nueva data vencida de su factura para el </0> {{planName}}<1> - TITAN.</1>',
      higherPlan: 'Ya tienes el plan con mayor capacidad de almacenamiento.',
      contractedPeriod: 'Periodo de contratación',
    },
  },
  customerProfileSurvey: {
    title: 'Cuéntenos un poco más sobre lo que le gustaría hacer con su sitio <1>{{ domain }}</1> para que podamos ayudarlo a tener una gran experiencia durante su viaje en HostGator :)',
    submitButton: 'Concluir',
    answersRequired: '* respuesta obligatoria',
    messageSuccess: 'Gracias por las respuestas :)',
    'question-1': {
      label: '¿Qué tipo de sitio web es?',
      otherLabel: 'Otro',
      options: {
        1: 'Sitio web personal',
        2: 'Sitio web institucional (de una empresa)',
        3: 'Blog',
        4: 'Tienda virtual (e-commerce)',
        5: 'Página para captar clientes (landing page)',
        6: 'Plataforma educación a distancia',
        7: 'Otro',
      },
    },
    'question-2': {
      label: '¿Cual plataforma se utilizó o se utilizará para crear el sitio?',
      otherLabel: 'Otra plataforma',
      options: {
        1: 'Creador de Sítios web de HostGator',
        2: 'WordPress',
        3: 'Aún no sé',
        4: 'No usaré una plataforma de creación de sitios web',
        5: 'Otra plataforma',
      },
    },
  },
  costumerPortalSurvey: {
    title: 'Complete su perfil',
    welcome: {
      0: 'Queremos ',
      1: 'saber más sobre su perfil para ofrecerle una mejor experiencia.',
    },
    explanation: {
      0: 'Son ',
      1: '4 preguntas rápidas de opción múltiple. ',
      2: 'Responda cuando quiera :)',
    },
    start: 'Iniciar',
    jump: 'Omitir',
    send: 'Enviar',
    selectPlaceholder: 'Seleccione',
    otherInputPlaceholder: 'Cual?',
    congrats: '¡Felicitaciones por concluir y completar su perfil! 👏',
    finalText: 'Estas respuestas nos ayudarán a ofrecer mejores productos y servicios. :)',
    finishLabel: 'Cerrar',
    counterLabel: 'Preguntas',
    questions: {
      'question-1': {
        label: 'Pensando en crear sitios web, ¿cuál es su nivel de conocimiento?',
        options: {
          1: 'Principiante - poca o ninguna experiencia',
          2: 'Intermedio - He creado un sitio web, pero aún no es algo que domine',
          3: 'Técnico - he creado varios sitios  y comprendo bien el tema',
        },
      },
      'question-2': {
        label: '¿Cómo definiría su perfil profesional?',
        options: {
          1: 'Freelancer - Atiendo a algunos clientes',
          2: 'Emprendedor(a) - Ya tengo o seré el(la) dueño(a) de un negocio',
          3: 'Contratada(o) por una empresa',
          4: 'Estudiante',
          5: 'Otro (¿Cuál?)',
        },
      },
      'question-3': {
        label: '¿De qué se trata el proyecto o negocio principal?',
        options: {
          1: 'Alimentación & Bebidas',
          2: 'Arte & Diseño',
          3: 'Belleza & Bienestar',
          4: 'Hogar & Decoración',
          5: 'Comunicación & Marketing',
          6: 'Construcción',
          7: 'Consultoría & Coaching',
          8: 'Contenido para adultos',
          9: 'Cultura (libros, música y cine)',
          10: 'Educación',
          11: 'Deporte & Fitness',
          12: 'Estilo de vida',
          13: 'Finanzas & Derecho',
          14: 'Fotografía',
          15: 'Moda & Ropa',
          16: 'Religión',
          17: 'Salud',
          18: 'Servicios & Mantenimiento',
          19: 'Tecnología & Desarrollo web',
          20: 'Viaje',
          21: 'Otro ¿Cuál?',
        },
      },
      'question-4': {
        label: '¿Quién es o será la(el) propietaria(o) del sitio/proyecto principal?',
        options: {
          1: 'Soy o seré la(el) propietaria(o)',
          2: 'Es o será de un cliente',
          3: 'Es o será de la empresa para la que trabajo',
          4: 'Es o será para un amigo o conocido',
        },
      },
    },
  },
  bannerTitanTrial: {
    title: 'Cree su nuevo {{ productName }}',
    tagOfTrialTime: '30 DÍAS GRATIS DE PRUEBA',
    selectLabel: 'Elija un dominio disponible',
  },
  modalTitanTrial: {
    title: 'Crear correo profesional <strong>{{ domain }}</strong>',
    tagOfTrialTime: '30 DÍAS GRATIS DE PRUEBA',
    productDescription: 'El <strong>{{ productName }}</strong> es una solución de HostGator para que los emprendedores tengan una dirección de correo personalizado con el nombre de su empresa.',
    advantages: {
      1: 'Correo personalizado @tudominio',
      2: '10 GB de almacenamiento por cuenta',
      3: 'Webmail y Aplicaciones para Android y iOS',
      4: 'Protección anti-spam y antivirus',
      5: 'Calendarios y contactos integrados',
      6: '¡Y mucho más!',
    },
    form: {
      emailAddressLabel: 'Dirección de correo',
      emailAddressPlaceholder: 'Ej: contacto',
      domainLabel: 'Dominio',
      passwordLabel: 'Contraseña',
      passwordError: 'Debe tener mínino 8 caracteres.',
      submitButton: 'Iniciar periodo de prueb',
      // eslint-disable-next-line no-template-curly-in-string
      termsOfService: 'Después del periodo de prueba gratis, la suscripción mensual es ${{ price }} por cuenta de correo, con renovación automática. Cancele cuando quiera. <a href="{{ termsUrl }}" target="_blank" rel="noreferrer" class="footer-text__link">Términos del servicio</a>',
    },
  },
  modalTitanTrialHirePlan: {
    buttonAddAccount: {
      label: 'Agregar una cuenta más',
    },
    emailSuggestions: {
      1: 'ej.: contato',
      2: 'ej.: ventas',
      3: 'ej.: atendimiento',
      4: 'ej.: soporte',
      5: 'ej.: financiero',
    },
    title: 'Titan Essentials 10GB',
    tagOfTrialTime: '30 DÍAS GRATIS DE PRUEBA',
    confirmText: 'Soy consciente de que cuando comience el período de prueba gratuito del correo electrónico Titan Essentials no podré cambiar a Titan Básico (gratuito del web hosting).',
    productDescription: {
      1: 'El  ',
      2: ' es una solución de HostGator para que los emprendedores tengan una dirección de correo electrónico personalizada con el nombre de su empresa.',
      3: '¡Aproveche ahora y cree su primera cuenta de correo profesional con ',
      4: '30 días gratis!',
    },
    termDescriptions: '<0>Después del período de prueba gratuito, la suscripción mensual al  </0><1> {{productName}} cuesta </1><2>{{amount}}  por cuenta de correo electrónico</2><3> con renovación automática y se puede cancelar en cualquier momento.</3>',
    titleForm: 'Cree su primera cuenta de correo profesional Titan Essentials',
  },
  bannerTitanTrialInSites: {
    title: 'Cree su primera cuenta de correo electrónico profesional Titan Essential',
    description: 'El correo electrónico profesional es una solución de HostGator para que los emprendedores tengan una dirección de correo electrónico personalizada con el nombre de su empresa.',
    tagOfTrialTime: '30 días gratis para probar',
  },
  modalToAlertUpgradeWhenInTrialPlan: {
    title: 'Cambio de plan',
    description: '<strong>Todavía tiene {{ remainingPeriod }} días de prueba gratuita</strong>, pero no se preocupe, no perderá lo que falta. Al confirmar el cambio de plan, <strong>vamos agregar lo que queda como días extras</strong> al final del ciclo contratado.',
    buttons: {
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR CAMBIO',
    },
  },
  sites: {
    tooltip: 'Esta pantalla está dedicada a la administración de sitios web de hosting compartidos.',
    newTooltip: {
      0: 'Vea sus',
      1: ' sitios web alojados aquí en HostGator :)',
    },
    pageDescription: {
      empty: {
        title: 'Esta pantalla está dedicada a la administración de sitios web de hosting compartidos.',
        subtitle: 'Vea abajo lo que desea realizar:',
      },
      hasOtherThanPendingStites: {
        singleSubtitle: 'Vea sus sitios alojados aquí en HostGator :)',
        composedSubtitle: {
          0: 'Elija la plataforma de creación ',
          1: 'para los sitios web a continuación u otros dominios que tenga',
          2: ' y comience a crear estos sitios ',
          3: ':)',
        },
        buttonLabel: 'Crear sitio',
      },
      onlyPendingSites: {
        subtitle: 'Espere la activación de las suscripciones para comenzar a crear su sitio web :)',
      },
    },
    emptyOptions: {
      hireHost: 'Contratar un web Hosting',
      hirePlan: 'Contratar un nuevo Dominio',
      manageServer: 'Administrar su servidor',
      manageEmails: 'Administrar cuentas de correo electrónico',
      manageDomains: 'Administrar dominios',
      manageSecurity: 'Administrar cuentas de correo electrónico',
      manageAcademy: 'Ver cursos de HostGator Academy',
    },
    modalExclusion: {
      title: 'Desactivación del Creador de Sitios',
      descriptor: {
        0: '¡Esta acción es irreversible! ',
        1: 'El Creador de Sitios del domínio ',
        2: ' se desactivará y no tendrá más acceso a la herramienta.',
      },
      radioText: 'Quiero eliminar el Creador de Sitios de todos modos.',
      cancelLabel: 'Renunciar a la acción',
      continueLabel: 'Confirmar desactivación',
    },
    exclusionToast: {
      success: 'El Creador de sitios web se ha desactivado y se ha eliminado el contenido de su sitio web.',
      failure: 'Esta acción no se pudo realizar. Vuelva a intentarlo más tarde.',
    },
    filter: {
      options: {
        all: 'Todos',
        out: 'Fuera de línea ',
        pending: 'Con pendencia financiera ',
        filterBy: 'Filtrar por:',
      },
      placeholder: 'Buscar por dominio',
      clear: 'Limpiar filtros',
      noResults: {
        field: 'Não encontramos nenhum site com o nome digitado acima.',
        option: '<0>No encontramos ningún sitio </0><1>"{{status}}"</1><2>.</2>',
      },
    },
    createSiteBanner: {
      title: {
        0: 'Cree un sitio web',
        1: ' y coloque su marca en internet',
      },
      subtitle: 'Elija la plataforma de creación, instálela, vea consejos de diseño y cómo crear el contenido :)',
      button: 'Crear sitio',
      tools: {
        wordpress: 'WordPress',
        sitebuilder: 'Creador de Sitios',
        other: 'Otras herramientas',
      },
    },
    siteModals: {
      domainCanceled: {
        title: 'Sitio web no disponible',
        contentDomainName: 'El dominio {{domain}} ya no está registrado con HostGator.',
        contentsDomainName: 'Los dominios {{domain}} ya no estan registrados con HostGator.',
        contentInfo: ' Estará disponible en la lista de sitios ya que su contenido permanece con nosotros. Si desea volver a registrar el dominio, ',
        contentsInfo: ' Estarán disponibles en la lista de sitios ya que su contenido permanece con nosotros. Si desea volver a registrar los dominios ',
        contentRedirect: 'visite el sitio web de HostGator.',
        button: 'Entendido',
      },
      planCanceled: {
        title: 'Sitio web eliminado',
        contentDomainName: '<0>Debido a la cancelación del plan, </0><1>{{domain}} y los demás dominios que estaban en este plan ya no están disponibles</1><2> en la lista de sitios. Si desea comprar un nuevo plan, </2>',
        contentRedirect: 'visite el sitio web de HostGator.',
        button: 'Entendido',
      },
      newSite: {
        title: '<0>Cree un sitio web ahora mismo para </0><1>{{domain}}</1>',
        contentInfo: 'Elija la plataforma de creación, instálela, vea consejos de diseño y cómo crear el contenido :)',
        button: 'Crear sitio',
      },
      createSite: {
        title: {
          0: '¿Vamos expandir su marca',
          1: ' creando un nuevo sitio web?',
        },
        description: {
          0: '¡5 mil millones de personas em el mundo están usando internet!',
          1: 'No pierda tiempo y cree un sitio web personalizado para',
          2: ' {{domain}}',
        },
        toolsText: {
          0: 'Usted puede usar el',
          1: ' Creador de Sitios web, WordPress',
          2: ' u otras herramientas para instalar y personalizar su sitio. :)',
        },
        buttonCreate: 'Crear sitio',
        buttonClose: 'No quiero expandir mi marca',
      },
      createSiteWithPartners: {
        title: {
          0: '¿Vamos expandir su marca',
          1: ' creando un nuevo sitio web?',
        },
        description: {
          0: '¡5 mil millones de personas em el mundo están usando internet!',
          1: 'No pierda tiempo y cree un sitio web personalizado para',
          2: ' {{domain}}',
        },
        toolsText: {
          0: 'Usted puede usar el',
          1: ' Creador de Sitios web, WordPress',
          2: ' u otras herramientas para instalar y personalizar su sitio. :)',
        },
        buttonCreate: 'Crear sitio',
        buttonClose: 'No quiero expandir mi marca',
      },
    },
  },
  warningStatus: {
    pending: {
      label: 'Esperando la compensación del pago',
      tooltip: 'La compensación del pago por cupón puede tardar hasta 72 horas. Si ya ha pagado, por favor espere.',
      buttonLabel: 'Pagar factura',
    },
    analysis: {
      label: 'Realizando el análisis de seguridad. Este proceso puede demorar hasta 48 horas',
      tooltip: 'Toda nueva compra pasa por el proceso de análisis de seguridad antes de ser aprobada definitivamente. Es un procedimiento normal, no se preocupe.',
    },
    nearDue: {
      day: 'día',
      days: 'días',
      label: '{{date}} para que el sitio web quede fuera de línea ',
      tooltip: 'Su factura de renovación ya está disponible. Realice el pago y evite que su sitio quede fuera de línea ',
      buttonLabel: 'Pagar factura',
    },
    overdue: {
      tooltip: 'Aún no identificamos el pago de su factura de renovación y ya está vencida. Realice el pago y evite que tu sitio web quede fuera de línea.',
      buttonLabel: 'Pagar factura',
    },
    suspended: {
      subLabel: 'Sitio web fuera de línea',
      subLabelTooltip: 'Su sitio web está fuera de línea porque aún no hemos identificado el pago de la factura de renovación',
      label: '{{date}} para que se cancele el sitio web y se eliminen sus archivos',
      tooltip: 'Aún no identificamos el pago de su factura de renovación. Realice el pago y evite perder su sitio web y archivos.',
      day: 'día',
      days: 'días',
    },
  },
  productDetailsBackup: {
    title: 'Copia de seguridad',
    description: {
      0: 'La última copia de seguridad ',
      1: ' se realizó el ',
    },
    buttonLabel: 'Realizar copia de seguridad',
  },
};
