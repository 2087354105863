import styled, { css } from 'styled-components';

export const DnsWizardCustomServerFormFieldWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      gap: 24px;
      align-items: flex-start;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    text-align: left;
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.medium};
    font-size: ${theme.font.sizes.md};
    line-height: ${theme.font.lineHeights.xl};
    
    & small {
      font-weight: ${theme.v1.font.weights.regular};
      color: ${theme.v1.color.text.primary};
      font-size: ${theme.font.sizes.xxs};
      line-height: ${theme.font.lineHeights.xl};
    }
  `}
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    border: 0;
    background: transparent;
    padding: 0 8px 8px 8px;
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.regular};
    font-size: ${theme.font.sizes.sm};
    line-height: ${theme.font.lineHeights.lg};
    border-bottom: 1px solid ${theme.v1.color.line.fields};
    outline: none;
    &::placeholder {
      color: ${theme.v1.color.text.label};
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme, visible }) => css`
    display: none;
    min-height: 16px;
    align-items: center;
    justify-content: center;
    align-self: center;
    @media (min-width: ${theme.breakpoints.md}) {
      display: ${visible ? 'flex' : 'none'};
      visibility: ${visible ? 'visible' : 'hidden'};
      display: flex;
    }
  `}
`;

export const FieldSet = styled.fieldset`
  ${({ theme, visible, forIpAddress }) => css`
    border: 0;
    padding: 0;
    margin: ${forIpAddress ? '0px 0px 32px 0px' : '0px'};
    display: ${visible ? 'flex' : 'none'};
    flex-direction: column;
    gap: 8px;
    flex: 1 1;

    & input {
      flex-grow: 1;
    }
    
    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: 0px;
      visibility: ${visible ? 'visible' : 'hidden'};
      display: flex;
    }

  `}
`;

export const Icon = styled.div`
  ${({ theme, visible }) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
    padding-left: 8px;

    & svg {
      & path {
        fill: ${theme.v1.color.action.primary};
      }
      &:hover {
        & path {
          fill: ${theme.v1.color.action.hover};
        }
      }
    }

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const RenderedButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    @media (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;


export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-grow: 1;

    @media (min-width: ${theme.breakpoints.md}) {
    }
  `}
`;

export const ErrorWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  `}
`;

export const ErrorText = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.error};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.regular};
    font-size: ${theme.font.sizes.xxs};
    line-height: ${theme.font.lineHeights.lg};
  `}
`;
