import React from 'react';
import * as Style from './IconMail.style';

const IconEmail = ({ color = '#ff9758' }) => (
  <Style.Wrapper data-testid="icon-mail">
    <svg id="icon-email" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
      <rect id="Rectangle" width="19" height="31" transform="translate(0 0)" fill={color} />
      <path id="Caminho_239090" data-name="Caminho 239090" d="M222.873,500.8l11.057,10.207L245.2,500.8" transform="translate(-215.655 -499.224)" fill="#fff" />
      <g id="Rectangle-2" transform="translate(5.166 0)">
        <rect id="Retângulo_14418" data-name="Retângulo 14418" width="26" height="18" transform="translate(-0.166 0)" fill="none" />
        <path id="Caminho_239091" data-name="Caminho 239091" d="M219.873,498.8v15.735h24.666V498.8Zm20.61,2.126-8.27,7.49-8.115-7.49ZM222,512.411V501.884l10.2,9.415,10.214-9.25v10.361Z" transform="translate(-219.097 -498.074)" fill="#1f2044" />
      </g>
      <path id="Path_85" data-name="Path 85" d="M261.206,540.8c0,8.457-3.434,11.831-12.07,11.908h-.263v-2.552c7.234,0,9.87-2.467,9.922-9.152v-.2Z" transform="translate(-230.598 -522.213)" fill="#1f2044" />
      <path id="Path_85_Copy" data-name="Path 85 Copy" d="M256.528,540.8c0,5.417-2.016,7.185-7.485,7.23h-.17v-2.637h.291c3.809-.047,4.908-1.009,4.959-4.338V540.8Z" transform="translate(-230.598 -522.213)" fill="#1f2044" />
    </svg>
  </Style.Wrapper>
);


export default IconEmail;
