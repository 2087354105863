import React, {
  useContext,
  useEffect,
} from 'react';
import { Info, Warning } from '@material-ui/icons/';
import { Typography, withStyles, CircularProgress } from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import { useDispatch } from 'react-redux';
import { DNSContext } from '@/contexts/DNS/context';
import LinkButton from '@/components/Buttons/LinkButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { SUPPORT_ARTICLE_DNS_THROUGH_WHM, SUPPORT_ARTICLE_ZONEMANAGER_CPANEL, SUPPORT_ARTICLE_ZONEMANAGER_WHM } from '@/config/urls/supportUrls';
import { forceDnsLoadingStatus } from '@/redux/actions/dnsZoneManager';
import styles from './styles';

const ZoneManager = ({
  classes,
  t,
}) => {
  const {
    couldConnectWithServer,
    details,
    dnsListLoading,
    featureTogglesZoneManager,
    hostingSelected,
    loading,
    otherProviderLinks,
  } = useContext(DNSContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(forceDnsLoadingStatus(false));
  }, [dispatch]);

  const cpanelToggleIsOnn = featureTogglesZoneManager.hostingCpanel;
  const whmToggleIsOnn = featureTogglesZoneManager.hostingWhm;
  const otherToggleIsOnn = featureTogglesZoneManager.other;

  const selectedHostIsFromCpanel = details.dbData.hosting && (hostingSelected && hostingSelected.type !== 'reselleraccount');
  const selectedHostIsFromWhm = details.dbData.hosting && (hostingSelected && hostingSelected.type === 'reselleraccount');
  const selectedHostIsFromOther = details.dbData.other;

  const hasCpanelMessage = !cpanelToggleIsOnn && selectedHostIsFromCpanel;
  const hasWhmMessage = !whmToggleIsOnn && selectedHostIsFromWhm;
  const hasOtherMessage = !otherToggleIsOnn && selectedHostIsFromOther;

  const hasErrorMessage = !couldConnectWithServer && (!hasCpanelMessage && !hasWhmMessage && !hasOtherMessage);

  if (loading || dnsListLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div className={`${classes.messageBlock} ${hasErrorMessage ? classes.messageError : null}`} data-testid="zoneManagerMessages">

      {hasErrorMessage
        ? <Warning />
        : <Info />
            }

      {hasCpanelMessage
        && (
          <div className={classes.messageWrapper}>
            <Typography className={classes.messageText}>
              <Trans i18nKey="dns.messages.messageHosting">
                <>0</>
                <a href={SUPPORT_ARTICLE_ZONEMANAGER_CPANEL} target="blank" className={classes.messageLink}>1</a>
              </Trans>
            </Typography>

            <Typography className={classes.messageText}>
              {t('dns.messages.messageHosting_2')}
            </Typography>

            <PrimaryButton type="button" color="primary" component={LinkButton} to={`${t('routes.help')}${t('routes.supportforms')}`}>
              {t('dns.messages.createTicket')}
            </PrimaryButton>
          </div>
        )
      }

      {hasWhmMessage
        && (
          <div className={classes.messageWrapper}>
            <Typography className={classes.messageText}>
              <Trans i18nKey="dns.messages.messageWhm">
                <>0</>
                <a href={SUPPORT_ARTICLE_ZONEMANAGER_WHM} target="blank" className={classes.messageLink}>1</a>
              </Trans>
            </Typography>
          </div>
        )
      }

      {hasOtherMessage
        && (
          <div className={classes.messageWrapper}>
            <Typography className={classes.messageText}>
              {t('dns.messages.messageOther')}
            </Typography>

            <Typography className={classes.messageText}>
              {t('dns.messages.messageOtherSeeMore')}
            </Typography>

            <ul className={classes.linkList} id="other_links_list">
              {otherProviderLinks.map((link, idx) => (
                <li id={`link_${idx}`}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a></li>
              ))}
            </ul>

            <Typography className={classes.messageText}>
              <Trans i18nKey="dns.messages.messageOtherBellow">
                <>0</>
                <a href={SUPPORT_ARTICLE_DNS_THROUGH_WHM} target="_blank" rel="noopener noreferrer" className={classes.messageLink}>1</a>
              </Trans>
            </Typography>
          </div>
        )
      }

      {hasErrorMessage
        && (
          <div className={classes.messageWrapper}>
            <Typography className={classes.messageText}>
              {t('dns.messages.messageError')}
              <br />
              {t('dns.messages.messageError_2')}
            </Typography>

            <PrimaryButton type="button" color="primary" component={LinkButton} to={`${t('routes.help')}${t('routes.supportforms')}`}>
              {t('dns.messages.createTicket')}
            </PrimaryButton>
          </div>
        )
      }
    </div>
  );
};
export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ZoneManager)));
