import { SitesActionTypes } from './sites.types';
// LOADING
const setLoading = isLoading => ({ type: SitesActionTypes.SET_LOADING, payload: { isLoading } });
const setLoadingExclusion = isLoading => ({ type: SitesActionTypes.SET_LOADING_EXCLUSION, payload: { isLoading } });
const setLoadingTlds = isLoading => ({ type: SitesActionTypes.SET_LOADING_TLDS, payload: { isLoading } });
const setLoadingDomains = isLoading => ({ type: SitesActionTypes.SET_LOADING_DOMAINS, payload: { isLoading } });
const setLoadingPlans = isLoading => ({ type: SitesActionTypes.SET_LOADING_PLANS, payload: { isLoading } });
const setLoadingSites = isLoading => ({ type: SitesActionTypes.SET_LOADING_SITES, payload: { isLoading } });
const setLoadingSslVerification = isLoading => ({ type: SitesActionTypes.SET_LOADING_SSL_VERIFICATION, payload: { isLoading } });
const setLoadingSslDomainConfiguration = isLoading => ({ type: SitesActionTypes.POST_DOMAIN_TO_HG_LOADING, payload: { isLoading } });
const setLoadingDomainValidation = isLoading => ({ type: SitesActionTypes.SET_LOADING_DOMAIN_VALIDATION, payload: { isLoading } });

// SITES
const requestSites = ({ withGatorSolutions }) => ({ type: SitesActionTypes.REQUEST_GET_SITES, payload: { withGatorSolutions } });
const getSitesSuccess = () => ({ type: SitesActionTypes.GET_SITES_SUCCESS });
const getSitesFailure = () => ({ type: SitesActionTypes.GET_SITES_FAILURE });
const setSites = (sites = []) => ({ type: SitesActionTypes.SET_SITES, payload: { sites } });
const deleteSite = ({ site, setSiteInExclusion, withGatorSolutions }) => ({ type: SitesActionTypes.DELETE_SITE, payload: { site, setSiteInExclusion, withGatorSolutions } });
const redirectToEdit = ({
  hostingId,
  domain,
  history,
  siteBuilderUrl,
  sitesWizardUrl,
  webPresenceUrl,
  cpanelUrl,
}) => ({
  type: SitesActionTypes.REDIRECT_TO_EDIT,
  payload: {
    hostingId,
    domain,
    history,
    siteBuilderUrl,
    sitesWizardUrl,
    webPresenceUrl,
    cpanelUrl,
  },
});
const loadRedirect = domain => ({ type: SitesActionTypes.SET_LOADING_REDIRECT, payload: { domain } });

// DOMAINS
const requestDomains = (getSitesWithBuilderTools = false) => ({ type: SitesActionTypes.REQUEST_GET_DOMAINS, payload: { getSitesWithBuilderTools } });
const getDomainsSuccess = () => ({ type: SitesActionTypes.GET_DOMAINS_SUCCESS });
const getDomainsFailure = () => ({ type: SitesActionTypes.GET_DOMAINS_FAILURE });
const setDomains = (domains = []) => ({
  type: SitesActionTypes.SET_SITES_DOMAINS,
  payload: {
    domains,
  },
});

//  TLDS
const requestTlds = () => ({ type: SitesActionTypes.REQUEST_GET_TLDS });
const getTldsFailure = () => ({ type: SitesActionTypes.GET_TLDS_FAILURE });
const getTldsSuccess = () => ({ type: SitesActionTypes.GET_TLDS_SUCCESS });
const setTlds = tlds => ({
  type: SitesActionTypes.SET_TLDS,
  payload: {
    tlds,
  },
});

// DOMAIN AVAILABILITY
const getDomainAvailabilityFailure = () => ({ type: SitesActionTypes.GET_DOMAIN_AVAILABILITY_FAILURE });
const getDomainAvailabilitySuccess = () => ({ type: SitesActionTypes.GET_DOMAIN_AVAILABILITY_SUCCESS });
const requestDomainAvailability = ({ domain }) => ({
  type: SitesActionTypes.REQUEST_GET_DOMAIN_AVAILABILITY,
  payload: { domain },
});
const setDomainAvailability = ({
  domainAvailability,
  domainTldAvailability,
  domain,
  domainAndSubdomain,
}) => ({
  type: SitesActionTypes.SET_DOMAIN_AVAILABILITY,
  payload: {
    domainAvailability, domainTldAvailability, domain, domainAndSubdomain,
  },
});


// PLANS
const requestPlans = ({ withGatorSolutions }) => ({ type: SitesActionTypes.REQUEST_GET_PLANS, payload: { withGatorSolutions } });
const getPlansSuccess = () => ({ type: SitesActionTypes.GET_PLANS_SUCCESS });
const getPlansFailure = () => ({ type: SitesActionTypes.GET_PLANS_FAILURE });
const setPlans = plans => ({
  type: SitesActionTypes.SET_PLANS,
  payload: {
    plans,
  },
});

// WARNINGS
const setWarningsSuccess = () => ({ type: SitesActionTypes.SET_WARNINGS_SUCCESS });
const setWarningsFailure = () => ({ type: SitesActionTypes.SET_WARNINGS_FAILURE });
const setWarningSeen = () => ({ type: SitesActionTypes.SET_WARNING_SEEN });

// SUBDOMAIN
const requestAditionalDomain = (domain, planExternalId, withAditionDomainV2 = false) => ({ type: SitesActionTypes.REQUEST_ADITIONAL_DOMAIN_TO_PLAN, payload: { domain, planExternalId, withAditionDomainV2 } });
const succeedRequestAditionalDomain = () => ({ type: SitesActionTypes.SUCCEED_ADITIONAL_DOMAIN_TO_PLAN });
const failRequestAditionalDomain = () => ({ type: SitesActionTypes.FAIL_ADITIONAL_DOMAIN_TO_PLAN });

// CHANGE DOMAIN DNS
const requestChangeDomainDns = (domainId, planId, selectedDomain) => ({ type: SitesActionTypes.REQUEST_CHANGE_DOMAIN_DNS, payload: { domainId, planId, selectedDomain } });
const changeDomainDnsSuccess = () => ({ type: SitesActionTypes.CHANGE_DOMAIN_DNS_SUCCESS });
const changeDomainDnsFailure = () => ({ type: SitesActionTypes.CHANGE_DOMAIN_DNS_FAILURE });

// GET DOMAIN DNS
const requestDomainDns = (domainLabel, onDomainNotRegistered) => ({ type: SitesActionTypes.REQUEST_DOMAIN_DNS, payload: { domainLabel, onDomainNotRegistered } });
const domainDnsSuccess = () => ({ type: SitesActionTypes.GET_DOMAIN_DNS_SUCCESS });
const domainDnsFailure = () => ({ type: SitesActionTypes.GET_DOMAIN_DNS_FAILURE });
const setDomainDns = domainDns => ({
  type: SitesActionTypes.SET_DOMAIN_DNS,
  payload: {
    domainDns,
  },
});

// GET PLAN DNS
const requestPlanDns = planId => ({ type: SitesActionTypes.REQUEST_PLAN_DNS, payload: { planId } });
const planDnsSuccess = () => ({ type: SitesActionTypes.GET_PLAN_DNS_SUCCESS });
const planDnsFailure = () => ({ type: SitesActionTypes.GET_PLAN_DNS_FAILURE });
const setPlanDns = planDns => ({
  type: SitesActionTypes.SET_PLAN_DNS,
  payload: {
    planDns,
  },
});

// SITEBUILDER ACTIVE

const setHasSiteBuilderActive = hasSiteBuilderActive => ({
  type: SitesActionTypes.SET_SITE_BUILDER_ACTIVE,
  payload: {
    hasSiteBuilderActive,
  },
});

// WORDPRESS INSTALLATION LOADING
const requestWordpressInstallation = ({
  wordpress,
  attempt,
  setHgPluginIsInstall,
  isWondersuiteEnabled,
  themeId,
  userSelectedWondersuiteTrial,
  isFastCheckoutAddonEnabled,
  userSelectedUseEcommercePlus,
  requestAutoSsl,
}) => ({
  type: SitesActionTypes.REQUEST_WORDPRESS_INSTALLATION,
  payload: {
    wordpress,
    attempt,
    setHgPluginIsInstall,
    isWondersuiteEnabled,
    themeId,
    userSelectedWondersuiteTrial,
    isFastCheckoutAddonEnabled,
    userSelectedUseEcommercePlus,
    requestAutoSsl,
  },
});
const setWordpressInstallationSuccess = () => ({ type: SitesActionTypes.SET_WORDPRESS_INSTALLATION_SUCCESS });
const setWordpressInstallationFailure = () => ({ type: SitesActionTypes.SET_WORDPRESS_INSTALLATION_FAILURE });
const setWordpressInstallationStatus = installed => ({ type: SitesActionTypes.SET_WORDPRESS_INSTALLATION_STATUS, payload: { installed } });
const setWordpressInstallationLoading = isLoading => ({ type: SitesActionTypes.SET_WORDPRESS_INSTALLATION_LOADING, payload: { isLoading } });
const setWordpressInstallationToDefault = () => ({ type: SitesActionTypes.SET_WORDPRESS_INSTALLATION_DEFAULT });

// SURVEY QUESTIONS ANSWERED
const requestSurveyQuestionsAnswered = surveyId => ({ type: SitesActionTypes.REQUEST_SURVEY_QUESTIONS_ANSWERED, payload: { surveyId } });
const surveyQuestionsAnsweredSuccess = () => ({ type: SitesActionTypes.GET_SURVEY_QUESTIONS_ANSWERED_SUCCESS });
const surveyQuestionsAnsweredFailure = () => ({ type: SitesActionTypes.GET_SURVEY_QUESTIONS_ANSWERED_FAILURE });
const setSurveyQuestionsAnswered = questions => ({
  type: SitesActionTypes.SET_SURVEY_QUESTIONS_ANSWERED,
  payload: {
    questions,
  },
});

// FORCE
const forceReload = bool => ({ type: SitesActionTypes.FORCE_RELOAD, payload: bool });

// ADDON CHECK
const requestAddonCheck = ({ hostingId }) => ({ type: SitesActionTypes.REQUEST_ADDON_CHECK, payload: { hostingId } });
const getAddonCheckSuccess = data => ({ type: SitesActionTypes.GET_ADDON_CHECK_SUCCESS, payload: data });
const getAddonCheckFailure = () => ({ type: SitesActionTypes.GET_ADDON_CHECK_FAILURE });
const setAddonCheckDefault = () => ({ type: SitesActionTypes.SET_ADDON_CHECK_DEFAULT });

// VERIFY IF SITE EXIST
const requestVerifyIfSiteExist = ({ hostingId, domain }) => ({ type: SitesActionTypes.REQUEST_VERIFY_SITE_EXIST, payload: { hostingId, domain } });
const requestVerifyIfSiteExistSuccess = data => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_SUCCESS, payload: data });
const requestVerifyIfSiteExistLoading = isLoading => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_LOADING, payload: { isLoading } });
const requestVerifyIfSiteExistFailure = () => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_FAILURE });
const requestVerifyIfSiteExistReset = () => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_RESET });
const requestVerifyIfSiteExistDefaultFilesExclude = data => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE, payload: data });
const requestVerifyIfSiteExistDefaultFilesExcludeSuccess = () => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_SUCCESS });
const requestVerifyIfSiteExistDefaultFilesExcludeFailure = () => ({ type: SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_FAILURE });

// UNINSTALL SITE
const requestUninstallSite = ({
  hostingId, domain, domainId, domainHasPlan, needToExcludeDefaultFiles, currentlyInstallInfo,
}) => ({
  type: SitesActionTypes.REQUEST_UNINSTALL_SITE,
  payload: {
    hostingId, domain, domainId, domainHasPlan, needToExcludeDefaultFiles, currentlyInstallInfo,
  },
});
const uninstallSiteSuccess = () => ({ type: SitesActionTypes.UNINSTALL_SITE_SUCCESS });
const uninstallSiteLoading = isLoading => ({ type: SitesActionTypes.UNINSTALL_SITE_LOADING, payload: { isLoading } });
const uninstallSiteFailure = () => ({ type: SitesActionTypes.UNINSTALL_SITE_FAILURE });

// DOMAIN DNS STATUS CHECK
const requestDomainDnsStatusCheck = ({ domain }) => ({ type: SitesActionTypes.REQUEST_DOMAIN_DNS_STATUS_CHECK, payload: { domain } });
const domainDnsStatusCheckSuccess = data => ({ type: SitesActionTypes.GET_DOMAIN_DNS_STATUS_CHECK_SUCCESS, payload: data });
const domainDnsStatusCheckFailure = () => ({ type: SitesActionTypes.GET_DOMAIN_DNS_STATUS_CHECK_FAILURE });

// DOMAIN DNS STATUS CHECK
const requestAllDomainsDnsStatusCheck = () => ({ type: SitesActionTypes.REQUEST_ALL_DOMAINS_DNS_STATUS_CHECK });
const domainsDnsStatusCheckSuccess = data => ({ type: SitesActionTypes.GET_ALL_DOMAINS_DNS_STATUS_CHECK_SUCCESS, payload: data });
const domainsDnsStatusCheckFailure = () => ({ type: SitesActionTypes.GET_ALL_DOMAINS_DNS_STATUS_CHECK_FAILURE });
const requestDomainsDnsStatusLoading = isLoading => ({ type: SitesActionTypes.GET_ALL_DOMAINS_DNS_STATUS_CHECK_LOADING, payload: { isLoading } });

const redirectFromPreChat = bool => ({ type: SitesActionTypes.SITES_FROM_PRECHAT, payload: bool });

// SSL
const clearSSLStatus = () => ({ type: SitesActionTypes.CLEAR_SSL_STATUS });

const requestAutoSSLStart = ({ domain, hostingId }) => ({ type: SitesActionTypes.AUTO_SSL_START_REQUEST, payload: { domain, hostingId } });
const successAutoSSLStart = () => ({ type: SitesActionTypes.AUTO_SSL_START_SUCCESS });
const failureAutoSSLStart = () => ({ type: SitesActionTypes.AUTO_SSL_START_FAILURE });

const requestAutoSSLProgress = ({ domain, hostingId }) => ({ type: SitesActionTypes.AUTO_SSL_PROGRESS_REQUEST, payload: { domain, hostingId } });
const loopAutoSSLProgress = ({ callLoop }) => ({ type: SitesActionTypes.AUTO_SSL_PROGRESS_LOOP, payload: { callLoop } });
const failureAutoSSLProgress = () => ({ type: SitesActionTypes.AUTO_SSL_PROGRESS_FAILURE });

const requestAutoSSLCheck = ({ hostingId, domain }) => ({ type: SitesActionTypes.AUTO_SSL_CHECK_REQUEST, payload: { hostingId, domain } });
const successAutoSSLCheck = () => ({ type: SitesActionTypes.AUTO_SSL_CHECK_SUCCESS });
const failureAutoSSLCheck = () => ({ type: SitesActionTypes.AUTO_SSL_CHECK_FAILURE });

const requestSslVerification = ({ domain, hostingId }) => ({ type: SitesActionTypes.SSL_VERIFICATION_REQUEST, payload: { domain, hostingId } });
const setSslVerification = data => ({ type: SitesActionTypes.SET_SSL_VERIFICATION, payload: data });

const postDomainToHgPlan = ({ domain }) => ({ type: SitesActionTypes.REQUEST_POST_DOMAIN_TO_HG, payload: { domain } });
const successPostDomainToHgPlan = () => ({ type: SitesActionTypes.POST_DOMAIN_TO_HG_SUCCESS });
const failurePostDomainToHgPlan = () => ({ type: SitesActionTypes.POST_DOMAIN_TO_HG_FAILURE });

const requestPostStepWelcomeChecklist = data => ({ type: SitesActionTypes.POST_STEP_WELCOME_CHECKLIST_REQUEST, payload: data });
const successPostStepWelcomeChecklist = data => ({ type: SitesActionTypes.POST_STEP_WELCOME_CHECKLIST_SUCCESS, payload: data });
const failurePostStepWelcomeChecklist = () => ({ type: SitesActionTypes.POST_STEP_WELCOME_CHECKLIST_FAILURE });

const requestGetStepWelcomeChecklist = ({ clientId, setData, action }) => ({ type: SitesActionTypes.GET_STEP_WELCOME_CHECKLIST_REQUEST, payload: { clientId, setData, action } });
const successGetStepWelcomeChecklist = data => ({ type: SitesActionTypes.GET_STEP_WELCOME_CHECKLIST_SUCCESS, payload: data });
const failureGetStepWelcomeChecklist = () => ({ type: SitesActionTypes.GET_STEP_WELCOME_CHECKLIST_FAILURE });

const setDataWelcomeChecklist = data => ({
  type: SitesActionTypes.SET_STEP_WELCOME_CHECKLIST,
  payload: {
    data,
  },
});

const requestRdCreateLead = data => ({ type: SitesActionTypes.RD_CREATE_LEAD_REQUEST, payload: data });


const actions = {
  loading: {
    set: setLoading,
  },
  loadingExclusion: {
    set: setLoadingExclusion,
  },
  loadingTlds: {
    set: setLoadingTlds,
  },
  loadingDomains: {
    set: setLoadingDomains,
  },
  loadingPlans: {
    set: setLoadingPlans,
  },
  loadingSites: {
    set: setLoadingSites,
  },
  loadingDomainValidation: {
    set: setLoadingDomainValidation,
  },
  sites: {
    failure: getSitesFailure,
    request: requestSites,
    success: getSitesSuccess,
    set: setSites,
  },
  site: {
    delete: deleteSite,
    loadRedirect,
    redirectToEdit,
  },
  domains: {
    failure: getDomainsFailure,
    request: requestDomains,
    success: getDomainsSuccess,
    set: setDomains,
  },
  tlds: {
    failure: getTldsFailure,
    request: requestTlds,
    success: getTldsSuccess,
    set: setTlds,
  },
  domainAvailability: {
    failure: getDomainAvailabilityFailure,
    request: requestDomainAvailability,
    success: getDomainAvailabilitySuccess,
    set: setDomainAvailability,
  },
  plans: {
    request: requestPlans,
    success: getPlansSuccess,
    failure: getPlansFailure,
    set: setPlans,
  },
  warnings: {
    success: setWarningsSuccess,
    failure: setWarningsFailure,
    set: setWarningSeen,
  },
  aditionalDomain: {
    request: requestAditionalDomain,
    success: succeedRequestAditionalDomain,
    failure: failRequestAditionalDomain,
  },
  changeDomainDns: {
    request: requestChangeDomainDns,
    success: changeDomainDnsSuccess,
    failure: changeDomainDnsFailure,
  },
  domainDns: {
    request: requestDomainDns,
    success: domainDnsSuccess,
    failure: domainDnsFailure,
    set: setDomainDns,
  },
  planDns: {
    request: requestPlanDns,
    success: planDnsSuccess,
    failure: planDnsFailure,
    set: setPlanDns,
  },
  wordpressInstallation: {
    request: requestWordpressInstallation,
    success: setWordpressInstallationSuccess,
    failure: setWordpressInstallationFailure,
    set: setWordpressInstallationLoading,
    setStatus: setWordpressInstallationStatus,
    reset: setWordpressInstallationToDefault,
  },
  surveyQuestionsAnswered: {
    request: requestSurveyQuestionsAnswered,
    success: surveyQuestionsAnsweredSuccess,
    failure: surveyQuestionsAnsweredFailure,
    set: setSurveyQuestionsAnswered,
  },
  hasSiteBuilderActive: {
    set: setHasSiteBuilderActive,
  },
  force: {
    reload: forceReload,
  },
  addonCheck: {
    request: requestAddonCheck,
    success: getAddonCheckSuccess,
    failure: getAddonCheckFailure,
    reset: setAddonCheckDefault,
  },
  chat: {
    setRedirectFromPreChat: redirectFromPreChat,
  },
  verifyIfSiteExist: {
    request: requestVerifyIfSiteExist,
    success: requestVerifyIfSiteExistSuccess,
    failure: requestVerifyIfSiteExistFailure,
    loading: requestVerifyIfSiteExistLoading,
    reset: requestVerifyIfSiteExistReset,
    defaultFilesExclude: {
      request: requestVerifyIfSiteExistDefaultFilesExclude,
      success: requestVerifyIfSiteExistDefaultFilesExcludeSuccess,
      failure: requestVerifyIfSiteExistDefaultFilesExcludeFailure,
    },
  },
  uninstallSite: {
    request: requestUninstallSite,
    success: uninstallSiteSuccess,
    failure: uninstallSiteFailure,
    loading: uninstallSiteLoading,
  },
  domainDnsStatusCheck: {
    request: requestDomainDnsStatusCheck,
    success: domainDnsStatusCheckSuccess,
    failure: domainDnsStatusCheckFailure,
  },
  allDomainsDnsStatusCheck: {
    request: requestAllDomainsDnsStatusCheck,
    success: domainsDnsStatusCheckSuccess,
    failure: domainsDnsStatusCheckFailure,
    loading: requestDomainsDnsStatusLoading,
  },
  ssl: {
    clearStatus: clearSSLStatus,
    start: {
      request: requestAutoSSLStart,
      success: successAutoSSLStart,
      failure: failureAutoSSLStart,
    },
    progress: {
      request: requestAutoSSLProgress,
      loop: loopAutoSSLProgress,
      failure: failureAutoSSLProgress,
    },
    check: {
      request: requestAutoSSLCheck,
      success: successAutoSSLCheck,
      failure: failureAutoSSLCheck,
    },
    verification: {
      request: requestSslVerification,
      set: setSslVerification,
      loading: setLoadingSslVerification,
    },
    pointDomain: {
      request: postDomainToHgPlan,
      success: successPostDomainToHgPlan,
      failure: failurePostDomainToHgPlan,
      loading: setLoadingSslDomainConfiguration,
    },
  },
  upateStepWelcomeChecklist: {
    request: requestPostStepWelcomeChecklist,
    success: successPostStepWelcomeChecklist,
    failure: failurePostStepWelcomeChecklist,
  },
  welcomeChecklist: {
    request: requestGetStepWelcomeChecklist,
    success: successGetStepWelcomeChecklist,
    failure: failureGetStepWelcomeChecklist,
    set: setDataWelcomeChecklist,
  },
  rdStation: {
    request: requestRdCreateLead,
  },
};

export default actions;
