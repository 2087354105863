import React, { useContext } from 'react';
import * as Styles from './AcademyPassFilterHandler.styles';
import { AcademyPassContext } from '@/contexts/academyPass/context';
import { filterHandlerLocale } from './AcademyPassFilterHandler.locale';
import { Filter } from '@/pages/common/Filter';

function AcademyPassFilterHandler() {
  const {
    filteredOptions,
    setFilteredOptions,
    clearFilter,
    filterTyped,
    setFilterTyped,
    setMultiSelectOptions,
    multiSelectOptions,
    togglePaidOrFreeCourses,
  } = useContext(AcademyPassContext);
  return (
    <Styles.AcademyPassFilterHandlerWrapper data-testid="academy-pass-filter-handler">
      <Filter
        data-testid="academy-pass-filter-handler"
        filterLabel={filterHandlerLocale.filterBy}
        filteredOptions={filteredOptions}
        searchPlaceholder={filterHandlerLocale.searchByCourseName}
        clearLabel={filterHandlerLocale.clearFilters}
        setFilteredOptions={setFilteredOptions}
        filterTyped={filterTyped}
        setFilterTyped={setFilterTyped}
        clearFilter={clearFilter}
        multiSelectOptions={multiSelectOptions}
        setMultiSelectOptions={setMultiSelectOptions}
        multiSelectLabel={filterHandlerLocale.multiSelectLabel}
        multiSelectVisible
        applySpecificRuleOnMark={e => togglePaidOrFreeCourses(e)}
      />
    </Styles.AcademyPassFilterHandlerWrapper>
  );
}

export default AcademyPassFilterHandler;
