import { domainsActions, sitesActions } from '@/redux/modules';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useConfiguredDomainStatus = (domain) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const {
    status: configuredDomainStatus,
    loaded: loadedStatus,
  } = useSelector(state => state.newDomains.domainConfiguredStatus);
  const dispatch = useDispatch();

  const verifyDomainConfiguration = () => {
    setLoadingStatus(true);
    dispatch(sitesActions.force.reload(true));

    if (domain) {
      dispatch(domainsActions.configuredStatusDomain.get(domain));
    } else {
      dispatch(domainsActions.configuredStatusDomain.empty());
    }
  };

  return {
    loadedStatus,
    loadingStatus,
    configuredDomainStatus,
    verifyDomainConfiguration,
  };
};

export default useConfiguredDomainStatus;
