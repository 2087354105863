import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';

const ActionButtons = ({
  classes, t, handleCancel, disabledButtons, handleSave, loading,
}) => (
  <div className={classes.actionsWrapper} data-testid="actionButtons">
    <OutlineButton
      onClick={e => handleCancel(e)}
      className={classes.action}
      disabled={disabledButtons}
      id="cancelSaveNameServerButton"
      data-testid="cancelSaveNameServerButton"
    >
      {t('cancel')}
    </OutlineButton>
    <PrimaryButton
      onClick={handleSave}
      className={classes.action}
      onLoading={loading}
      disabled={disabledButtons}
      id="saveNameServerButton"
      data-testid="saveNameServerButton"
    >
      {t('save')}
    </PrimaryButton>
  </div>
);

ActionButtons.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  disabledButtons: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ActionButtons.defaultProps = {
  loading: false,
};

export default withI18n()(withStyles(styles)((ActionButtons)));
