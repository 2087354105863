import styled, { css } from 'styled-components';
import colorfulLine from '@/media/icons/colorfulLine.svg';

export const Card = styled.div`
  ${({
    variant,
    theme,
    hover,
    selected,
  }) => {
    let padding = '0px';
    let border = '0';
    let borderRadius = '0';

    switch (variant) {
      case 'outlined':
        border = `1px solid ${theme.palette.grey.main}`;
        break;
      case 'rounded':
        border = `1px solid ${theme.color.grayVeryLight}`;
        borderRadius = '4px';
        padding = '4px';
        break;
      case 'featured':
        padding = '4px';
        break;
      default:
        break;
    }
    const backgroundImage = variant === 'featured' ? `url(${colorfulLine})` : 'none';

    return css`
      background-image: ${backgroundImage};
      background-size: 'cover';
      border: ${border};
      border-radius: ${borderRadius};
      box-sizing: border-box;
      cursor: pointer;
      padding: ${padding};

      &:hover {
        background-color: ${hover ? theme.palette.blue.light : theme.v1.color.white.primary};
        border-color: ${hover ? theme.palette.grey.main : theme.v1.color.white.primary};
      }
      border-color: ${!selected ? border : theme.palette.grey.main};
      background-color: ${selected ? theme.v1.color.background.boxes : theme.v1.color.white.primary};
  `;
  }}
`;

export const Container = styled.div`
  ${({ unspaced, theme, hover }) => {
    const padding = unspaced ? '0px' : '40px 24px';
    return css`
      background-color: ${hover ? 'transparent' : theme.v1.color.white.primary};
      color: ${theme.v1.color.text.primary};
      display: flex;
      flex-direction: column;
      font-family: ${theme.v1.font.family.primary};
      gap: 16px;
      justify-content: flex-start;
      padding: ${padding};
      & > * {
        margin: 0;
      }
  `;
  }}
`;
