/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    min-height: 400px;
    padding: 24px 24px 32px 24px;
    transition: 200ms ease-out;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 24px 0;
      transition: none;
    }
  `}
`;

export const Question = styled.div``;

export const QuestionTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    margin: 0;
  `}
`;

export const QuestionDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    margin: 7px 0 0 0;
  `}
`;

export const OptionContent = styled.div`
  align-items: center;
  display: flex;
  padding: 8px;
`;

export const Option = styled.li`
  ${({ theme, selected, visible }) => css`
    background-color: ${selected ? theme.palette.grey.ultralight : theme.palette.white.light};
    border-radius: 5px;
    max-height: ${visible ? '40px' : 0};
    max-width: 600px;
    opacity: ${visible ? 1 : 0};
    overflow: hidden;
    transition: 200ms ease-out;
    will-change: max-height;

    &:not(:first-child) {
      margin-top: ${visible ? (selected ? 0 : '8px') : 0} ;
    }

    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.grey.ultralight};
    }

    @media (max-width: ${theme.breakpoints.md}) {
      transition: none;
      max-height: ${visible ? '88px' : 0};
    }
  `}
`;

export const Options = styled.ul`
  list-style: none;
  margin: 32px 0 0 0;
  padding: 0;
`;

export const OptionButton = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      align-self: start;
    }
  `}
`;

export const OptionText = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    margin-left: 8px;

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 1;
      line-height: 24px;
    }
  `}
`;

export const Reason = styled.div`
  ${({ theme, visible }) => css`
    margin-top: ${visible ? '22px' : 0};
    max-height: ${visible ? '183px' : 0};
    opacity: ${visible ? 1 : 0};
    overflow: hidden;
    transition: 200ms ease-out;
    will-change: max-height;

    @media (max-width: ${theme.breakpoints.md}) {
      transition: none;
    }
  `}
`;

export const ReasonQuestion = styled.h4`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    margin: 0;
  `}
`;

export const ReasonOption = styled.div`
  margin-top: 16px;
  max-width: 600px;
`;
