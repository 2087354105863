import React from 'react';
import * as Style from './IconDetailedComputer.style';

const IconDetailedComputer = ({
  color = '#1F2044',
  backgroundColor = '#EF3120',
  width = '37',
  height = '26',
  testId = 'icon-detailed-computer',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="15" r="11" fill={backgroundColor} />
      <path d="M31.3333 20.125C32.8917 20.125 34.1525 18.8312 34.1525 17.25L34.1667 2.875C34.1667 1.29375 32.8917 0 31.3333 0H8.66667C7.10833 0 5.83333 1.29375 5.83333 2.875V17.25C5.83333 18.8312 7.10833 20.125 8.66667 20.125H3V23H37V20.125H31.3333ZM8.66667 2.875H31.3333V17.25H8.66667V2.875Z" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedComputer;
