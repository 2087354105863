// keep track of the loaded libraries
const loadedLibraries = [];

function registerLibraryLoaded(id) {
  // record the libs only if the array doesn't contain the same already
  if (loadedLibraries.indexOf(id) < 0) {
    loadedLibraries.push(id);
  }
}

// check if the script.id exist already on the page
// to add a listener because the library you asked to load
// might be on the loading process
// and after is loaded register the lib to avid this process twice
function appendUnique(script, next) {
  const appendedScript = document.getElementById(script.id);
  if (appendedScript) {
    appendedScript.addEventListener('load', function onLoadScript() {
      appendedScript.removeEventListener('load', onLoadScript);
      registerLibraryLoaded(script.id);
      next();
    });
    return;
  }

  // this will only add a new script tag if the lib is not already on the DOM
  // the above part of this function will handle the scenario where
  // even tho is already on the DOM might be still loading
  document.body.appendChild(script);
}

function loadScript({ id, src }) {
  const script = document.createElement('script');

  return new Promise((resolve, reject) => {
    // once the lib is registered you can resolve immediatelly
    // because it means that is fully loaded
    if (loadedLibraries.indexOf(id) > -1) {
      resolve(`${id} was loaded before`);
    }

    script.addEventListener('load', function onLoadScript() {
      script.removeEventListener('load', onLoadScript);
      registerLibraryLoaded(id);
      resolve(id);
    });

    script.onerror = function onErrorLoadingScript() {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(`Error on load script -> ${id}`);
    };

    script.id = id;
    script.src = src;
    appendUnique(script, resolve);
  });
}

export { loadScript };
