import styled, { css } from 'styled-components';

export const QuestionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0px 0px 24px 0px;
  max-width: 664px;
  position: relative;
  width: 100%;

  &:first-child {
    margin-top: 40px;
  }

  @media (max-width: 1098px){
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-right: auto;
  min-width: 250px;

  @media (max-width: 1098px){
    margin: 0px 0px 24px 0px;
    min-width: unset;
    position: relative;
  }
`;

export const Triangle = styled.div`
  ${({ theme }) => css`
    border-bottom: 23px solid transparent;
    border-left: 23px solid ${theme.v1.color.warning.attention};
    height: 0;
    margin-right: 18px;
    width: 0;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    line-height: 22px;
    margin: 0px auto 0px 0px;
    max-width: 664px;
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  align-items:flex-start;
  display: flex;
  justify-content:flex-start;
  margin: 0px 8px 0px 0px;
  width: 100%;


  > * {
    width: 100%;
  }

  @media (max-width: 1098px){
    min-width: unset;
  }
`;

export const TooltipWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: -14px;
    top: 2px;

    svg > g > g {
      fill: ${theme.v1.color.action.primary};
      stroke: ${theme.v1.color.action.primary};
      transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
    }

    &:hover {
      svg > g > g {
        fill: ${theme.v1.color.action.hover};
        stroke: ${theme.v1.color.action.hover};
      }
    }
    
    @media (max-width: 1098px){
      right: -20px;
      top: 4px;
    }
  `}
`;

export const CheckListWrapper = styled.div`
  max-width: 664px;
  padding: 0px;
  width: 100%;
`;

export const ListHeader = styled.p`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0px 0px 4px auto;
  max-width: 410px;
  padding: 0px;

  @media (max-width: 1098px){
    margin: 0px 0px 4px 0px;
  }
`;

export const ListInfo = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.font.family.primary};
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 0px 8px;
  `}
`;

export const List = styled.ul`
  align-items: center;
  margin: 0px 0px 0px auto;
  max-width: 410px;
  padding: 0;
  width: 100%;

  @media (max-width: 1098px){
    margin: 0px;
  }
`;

export const ListItem = styled.li`
  ${({ theme, valid }) => css`
    align-items: center;
    color: ${valid ? theme.v1.color.text.primary : theme.v1.color.warning.error};
    display: flex;
    font-family: ${theme.font.family.primary};
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 4px 0px;

    span:first-child { 
      margin: 0px 10px 0px 8px;
    }
  `}
`;

export const ListDot = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.warning.error};
    border-radius: 50%;
    height: 6px;
    width: 6px;
  `}
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 40px 0px 0px;
`;

export const ToggleWrapper = styled.div`
    margin: 0px 0px 24px 0px;
`;
