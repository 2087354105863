import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORIES: {
    SUPPORT_CTA_DISPLAYED: 'Solução - Suporte Chamados - CTA exibido',
    SUPPORT_CTA_CLICK: 'Solução - Suporte Chamados - Clique no CTA',
  },
  ACTIONS: {
    SHOW_TICKETS_DISPLAYED: 'Solução - CTA exibido - Ver chamado',
    SHOW_TICKETS_CTA_CLICK: 'Solução - Clique no CTA - Ver chamado',
  },
  LABELS: {
    SHOW_TICKETS_DISPLAYED: 'Solução - Suporte Chamados - CTA ver chamado exibido com sucesso',
    SHOW_TICKETS_CTA_CLICK: 'Solução - Suporte Chamados - Clique no CTA Ver chamado',
  },
};

const showTicketsDisplayed = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SUPPORT_CTA_DISPLAYED,
    gaEventAction: EVENTS.ACTIONS.SHOW_TICKETS_DISPLAYED,
    gaEventLabel: EVENTS.LABELS.SHOW_TICKETS_DISPLAYED,
  });
};

const showTicketsCtaClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SUPPORT_CTA_CLICK,
    gaEventAction: EVENTS.ACTIONS.SHOW_TICKETS_CTA_CLICK,
    gaEventLabel: EVENTS.LABELS.SHOW_TICKETS_CTA_CLICK,
  });
};

const analytics = {
  showTicketsDisplayed,
  showTicketsCtaClick,
};

export default analytics;
