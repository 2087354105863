import React, { useEffect } from 'react';
import {
  Modal,
} from '@/pages/common';
import * as Styles from './ModalCreateSite.styles';
import modalDeco from '@/media/newSiteModal/modal-deco.svg';
import { Button } from '@/pages/common/v1';
import {
  IconDetailedWordpress,
  IconDetailedSiteBuilder,
} from '@/icons';
import { useResize } from '@/hooks/useResize';
import SnappyFullBody from '@/media/siteBuilder/snappy/Snappy-Default.svg';
import { modalCloseAction } from '../../SitesHandler/SitesHandler.enum';

const ModalCreateSite = ({
  contentDomainName = 'dominio.com.br',
  handleOnCloseWarningModal,
  buttonCreate,
  buttonClose,
  description,
  toolsText,
  title,
  enableNewCreateSiteModalBehavior,
  createSitesWithSpecialistAnalytics,
}) => {
  const handleOnClose = (action) => {
    handleOnCloseWarningModal && handleOnCloseWarningModal(action);
  };

  const screenSize = useResize();
  const isMobile = screenSize.width <= 600;
  const iconsSize = isMobile ? 60 : 45;

  useEffect(() => {
    if (enableNewCreateSiteModalBehavior) {
      createSitesWithSpecialistAnalytics.createSiteModalView('site');
    }
  }, [enableNewCreateSiteModalBehavior, createSitesWithSpecialistAnalytics]);

  return (
    <Modal testId="modal-create-site" maxWidth={enableNewCreateSiteModalBehavior ? 630 : 750} disablePadding onClose={enableNewCreateSiteModalBehavior ? () => handleOnClose(modalCloseAction.close) : null}>
      <Styles.ContentWrapper enableNewCreateSiteModalBehavior={enableNewCreateSiteModalBehavior}>
        <Styles.Deco src={modalDeco} alt="Deco" />

        <Styles.Title data-testid="modal-title" enableNewCreateSiteModalBehavior={enableNewCreateSiteModalBehavior}>
          {title}
        </Styles.Title>

        <Styles.Content data-testid="modal-description" enableNewCreateSiteModalBehavior={enableNewCreateSiteModalBehavior}>
          {description(contentDomainName)}
        </Styles.Content>

        {!enableNewCreateSiteModalBehavior && (
          <Styles.Content data-testid="modal-tools-text" enableNewCreateSiteModalBehavior={false}>
            {toolsText}
          </Styles.Content>
        )}

        <Styles.Action enableNewCreateSiteModalBehavior={enableNewCreateSiteModalBehavior}>
          <Button testId="create-site-button" onClick={() => handleOnClose(enableNewCreateSiteModalBehavior ? modalCloseAction.createWithSpecialist : modalCloseAction.createSite)} label={buttonCreate} />

          <Button variant="text" testId="close-modal-button" onClick={() => handleOnClose(enableNewCreateSiteModalBehavior ? modalCloseAction.createSite : modalCloseAction.close)} label={buttonClose} />
        </Styles.Action>

        {!enableNewCreateSiteModalBehavior && (
          <Styles.DecoIcons>
            <IconDetailedWordpress size={iconsSize} />

            <IconDetailedSiteBuilder size={iconsSize} />
          </Styles.DecoIcons>
        )}

        {enableNewCreateSiteModalBehavior && (
          <Styles.SnappyFullBody src={SnappyFullBody} />
        )}

        <Styles.DecoTriangle enableNewCreateSiteModalBehavior={enableNewCreateSiteModalBehavior} />

        <Styles.DecoLine />
      </Styles.ContentWrapper>
    </Modal>
  );
};

export default ModalCreateSite;
