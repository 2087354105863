import React from 'react';
import OldCountDown from './featureToggle/OldCountdown';

const Countdown = props => (
  <>
    <OldCountDown {...props} />
  </>
);

export default Countdown;
