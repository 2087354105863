const styles = theme => ({
  container: {
    borderRadius: '0',
    borderLeft: '5px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    padding: '12px 30px 12px 30px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  containerCollapse: {
    width: '100%',
    background: 'transparent',
    display: 'flex',
    paddingRight: '17px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '23px',
      paddingRight: '23px',
    },
  },
  searchTitle: {
    minWidth: '320px',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '25px',
  },
  link: {
    textDecoration: 'none',
  },
  cssOutlinedInput: {
    '& > input': {
      padding: '9px 14px 5px 14px',
      fontSize: '14px',
    },
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  cssLabel: {
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  input: {
    '& div': {
      '& > fieldset': {
        borderRadius: '2px',
      },
    },
    '& > label': {
      transform: 'translate(14px, 10px) scale(1)',
    },
  },
});

export default styles;
