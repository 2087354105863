import React, { useContext } from 'react';
import { Trans, withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core/';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { ProductsContext } from '@/contexts/Products/context';
import { RESELLER_PRICE_UPDATE_EXPLANATION } from '@/config/urls/supportUrls';
import styles from './styles';
import CardItem from './CardItem';


const ModalOldResellerManagement = ({ t, classes, width }) => {
  const isWidthDownSm = isWidthDown('sm', width);
  const {
    showModal, toggleShowModal, suspendedProducts, productsOldResellers,
  } = useContext(ProductsContext);
  return (
    <Dialog
      fullScreen={!!isWidthDownSm}
      open={showModal}
      onClose={() => toggleShowModal(false)}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => toggleShowModal(false)}
        data-testid="closeButton"
      >
        <Close />
      </IconButton>

      <DialogTitle id="alert-dialog-title" className={classes.titleElement}>
        {t('oldReseller.ModalTitle')}
      </DialogTitle>

      <DialogContent className={classes.containerWrapper}>
        <DialogContentText className={classes.description}>
          <Trans i18nKey="oldReseller.ModalOldResellerMessage">
            <b>0</b>
            <a href={RESELLER_PRICE_UPDATE_EXPLANATION} target="_blank" rel="noopener noreferrer" className={classes.listCursos}> 1 </a>
            <>2</>
          </Trans>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.listWrapper}>
        {suspendedProducts.map(product => (
          <CardItem product={product} key={product.id} />
        ))}

        {productsOldResellers.map(product => (
          <CardItem product={product} key={product.id} />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default withI18n()(withWidth()(withStyles(styles)(ModalOldResellerManagement)));
