const styles = theme => ({

  formInput: {
    width: 245,
  },
  button: {
    marginLeft: 10,
    minWidth: 109,
    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      color: theme.color.tundora,
    },
  },

  wrapperBoxLinks: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  },
});

export default styles;
