export const passwordScore = (value) => {
  // Reset Count
  let score = 0;

  // Isolates each Input Type
  // Remove Symbols
  const cleanSymbols = value.replace(/\W/g, '');

  // Remove Numbers
  const cleanNumbers = cleanSymbols.replace(/\d+/g, '');
  const numbers = cleanSymbols.replace(/[A-Z-a-z]/g, '');
  const uppers = cleanNumbers.replace(/[a-z]/g, '');
  const lowers = cleanNumbers.replace(/[A-Z]/g, '');
  const symbols = value.replace(/\w/g, '');

  // Check Uppercase and Score
  if (uppers.length > 0) {
    score += 10;
  }

  // Check Lowercase and Score
  if (lowers.length > 0) {
    score += 10;
  }

  // Check Numbers and Score
  if (numbers.length > 0) {
    score += 10;
  }

  // Check Symbols and Score
  if (symbols.length > 0) {
    score += 10;
  }

  // Check Minimum Characters and Score
  if (value.length > 7) {
    score += 10;
  }

  // BONUS 1: Check if have more than 8 Characters and Score
  if (uppers.length > 0 && lowers.length > 0 && numbers.length > 0 && symbols.length > 0 && value.length > 7) {
    score += 12.5;
  }

  // BONUS 2: Check if have more than 1 Numbers and Score
  if (uppers.length > 0 && lowers.length > 0 && numbers.length > 1 && symbols.length > 0 && value.length > 7) {
    score += 12.5;
  }

  // BONUS 3: Check if have more than 1 Symbols and Score
  if (uppers.length > 0 && lowers.length > 0 && numbers.length > 0 && symbols.length > 1 && value.length > 7) {
    score += 12.5;
  }

  // BONUS 3: Check if have more than 1 Uppercase and Score
  if (uppers.length > 1 && lowers.length > 0 && numbers.length > 0 && symbols.length > 0 && value.length > 9) {
    score += 12.5;
  }

  // Certify maximum and minimum score value
  let pwStrength = score;
  if (pwStrength < 0) {
    pwStrength = 0;
  }
  if (pwStrength > 100) {
    pwStrength = 100;
  }

  // Set tag value
  let pwLabel;
  if (pwStrength === 0) {
    pwLabel = 'default';
  } else if (pwStrength < 30) {
    pwLabel = 'low';
  } else if (pwStrength < 75) {
    pwLabel = 'medium';
  } else {
    pwLabel = 'strong';
  }

  const result = {
    strength: pwStrength,
    label: pwLabel,
  };

  return result;
};
