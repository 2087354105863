import Loader from '@/components/Layout/Loader';
import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './AsaasCreateAccountModal.styles';

const AsaasCreateAccountModal = ({
  title = 'Estamos criando a sua conta...',
  description = 'Falta pouco para ter a sua conta Asaas!',
}) => (
  <Modal>
    <Styles.Title>{title}</Styles.Title>

    <Styles.LoaderWrapper>
      <Loader size={40} />
    </Styles.LoaderWrapper>

    <Styles.Description>{description}</Styles.Description>
  </Modal>

);

export default AsaasCreateAccountModal;
