import React from 'react';
import * as Style from './IconDetailedGiftCertificate.style';

const IconDetailedGiftCertificate = ({
  size = '30',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1715 7.42505H11.8286V27.0302H18.1715V7.42505Z" fill="#F67922" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.9563 9.2019H25.0446V16.0279H4.9563V9.2019ZM6.59266 10.8383V14.3915H23.4082V10.8383H6.59266Z" fill="#1F2044" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.54932 14.8357H7.18568V23.955H23.5907V25.5913H5.54932V14.8357Z" fill="#1F2044" />
      <path d="M8.2439 2.72729L16.4095 9.29676H8.2439V2.72729Z" fill="#F67922" />
      <path d="M21.2166 2.72729L13.051 9.29676H21.2166V2.72729Z" fill="#F67922" />
    </svg>

  </Style.Wrapper>
);


export default IconDetailedGiftCertificate;
