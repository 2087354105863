import React, { useState, useEffect } from 'react';
import {
  withStyles, Dialog, Slide, IconButton, Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import PasswordCheckList from '@/components/PasswordCheckList';
import PasswordField from '@/components/Fields/PasswordField';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { savePassword } from '@/redux/actions/productDetail';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierSuccess, notifierError } from '@/utils/Application/notifier';
import { RECEIVE_PRODUCT_SAVE_PASSWORD } from '@/redux/actions/actionsTypes';
import styles from './styles';


const Transition = props => <Slide direction="up" {...props} />;

const optionsValidate = {
  abortEarly: false,
};

const schema = yup.object().shape({
  password: yup
    .string()
    .required('required')
    .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'lowercaseUppercase')
    .matches(/[0-9]/, 'number')
    .matches(/\W/, 'symbol')
    .min(8, 'minCharacters'),
  confirmPassword: yup
    .string()
    .required('required')
    .oneOf([yup.ref('password')], 'sameInBothFields'),
});

const ModalSetPassword = ({
  open, onClose, onOpen, classes, t, product, editing,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { id: productId } = useParams();

  const history = useHistory();

  const [didMount, setDidMount] = useState(true);

  const [checklist, setCheckList] = useState({
    upperAndLowercase: false,
    number: false,
    symbol: false,
    eightCharacters: false,
    sameInBothFields: false,
  });

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [formDataError, setFormDataError] = useState({
    password: '',
    confirmPassword: '',
  });

  const checkValidations = (formSubmit) => {
    schema.validate(formData, optionsValidate).then(() => {
      if (formSubmit) {
        setLoading(true);
        dispatch(savePassword(productId, formData)).then((response) => {
          setLoading(false);
          onClose();
          if (response.type === RECEIVE_PRODUCT_SAVE_PASSWORD) {
            dispatch(enqueueSnackbar('myData.changePassword.nofifierSuccess', notifierSuccess));
          } else {
            onOpen();
            dispatch(enqueueSnackbar('myData.changePassword.notifierError', notifierError));
          }
        });
      }
      setCheckList({
        upperAndLowercase: true,
        number: true,
        symbol: true,
        eightCharacters: true,
        sameInBothFields: true,
      });
    }).catch((validationError) => {
      if (formSubmit) {
        const filterValidationError = validationError.inner.filter(({ path }, index, array) => array.findIndex(elem => elem.path === path) === index);
        filterValidationError.forEach((error) => {
          let message;
          if (error.path === 'password') {
            message = error.type === 'required' ? t('modalSetPassword.required') : t('modalSetPassword.minimumRequirements');
          }
          if (error.path === 'confirmPassword') {
            message = error.type === 'required' ? t('modalSetPassword.required') : t('modalSetPassword.confirmPassword');
          }

          setFormDataError(prevValues => ({
            ...prevValues,
            [error.path]: message,
          }));
        });
      }
      setCheckList({
        ...checklist,
        eightCharacters: !validationError.errors.includes('minCharacters'),
        upperAndLowercase: !validationError.errors.includes('lowercaseUppercase'),
        number: !validationError.errors.includes('number'),
        symbol: !validationError.errors.includes('symbol'),
        sameInBothFields: !validationError.errors.includes('sameInBothFields') && validationError.value.confirmPassword !== '',
      });
    });
  };

  useEffect(() => {
    setDidMount(false);
  }, []);

  useEffect(() => {
    if (!didMount) {
      checkValidations(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const updateField = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setFormDataError({
      ...formDataError,
      [event.target.name]: '',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkValidations(true);
  };

  const handleCloseModal = () => {
    !editing ? history.replace(t('routes.products')) : onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      classes={{
        paper: classes.modal,
      }}
    >
      <div
        className={classes.content}
      >
        <IconButton
          onClick={handleCloseModal}
          aria-label="Close"
          className={classes.btnClose}
        >
          <Close />
        </IconButton>
        <h3 className={classes.title}>{editing ? t('modalSetPassword.editingTitle') : t('modalSetPassword.setTitle')}</h3>
        {!editing && (
          <p className={classes.message}>
            <Trans i18nKey="modalSetPassword.message" values={{ product }}>
              0
              <strong>1</strong>
              2
            </Trans>
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={10}>
              <PasswordField
                value={formData.password}
                name="password"
                autoComplete="new-password"
                label={editing ? t('modalSetPassword.newPasswordLabel') : t('modalSetPassword.passwordLabel')}
                variant="outlined"
                margin="dense"
                spellCheck="false"
                fullWidth
                onChange={updateField}
                error={!!formDataError.password}
                helperText={formDataError.password}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={10}>
              <PasswordField
                value={formData.confirmPassword}
                name="confirmPassword"
                autoComplete="new-password"
                label={editing ? t('modalSetPassword.confirmNewPasswordLabel') : t('modalSetPassword.confirmPasswordLabel')}
                variant="outlined"
                margin="dense"
                spellCheck="false"
                fullWidth
                onChange={updateField}
                error={!!formDataError.confirmPassword}
                helperText={formDataError.confirmPassword}
              />
            </Grid>
          </Grid>
          <PasswordCheckList
            showCheckList
            upperAndLowercase={checklist.upperAndLowercase}
            number={checklist.number}
            symbol={checklist.symbol}
            eightCharacters={checklist.eightCharacters}
            sameInBothFields={checklist.sameInBothFields}
          />
          <div className={classes.actions}>
            {editing && (
              <OutlineButton className={classes.btnCancel} onClick={onClose}>{t('cancel')}</OutlineButton>
            )}
            <PrimaryButton onLoading={loading}>{editing ? t('modalSetPassword.changeBtn') : t('modalSetPassword.setBtn')}</PrimaryButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalSetPassword));
