import {
  call, takeLatest, select, put,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { FormsActionTypes } from './forms.types';
import { commonActions } from '../common';
import { formsActions } from '.';
import { AWS_GATOR_SOLUTIONS_URL } from '@/config';
import { isBRServer, mockoonWarning } from '@/utils/Validators/validation';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import { authActions } from '../auth';
import logger from '@/utils/logger';

export function* skipHgRoutineBackup({ payload }) {
  const { id } = payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${id}/skip-backup`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  try {
    yield call(api.whmcs.get, request);
  } catch (e) {
    logger.error('Error create RD lead', e);
  }
}

function* openTicket({ payload }) {
  const { data, useZendeskV2 } = payload;
  const { token } = yield select(state => state.auth);

  const form = { ...data };
  let shouldSkipHgRoutineBackup = null;

  const { locale, onSuccess } = data;
  const formData = new FormData();
  delete form.root;
  delete form.locale;
  delete form.onSuccess;
  if (form.skipHgRoutineBackup) {
    shouldSkipHgRoutineBackup = form.skipHgRoutineBackup;
    delete form.onSuccess;
  }

  const datakeys = Object.keys(form);
  datakeys.forEach((key) => {
    if (!Array.isArray(form[key])) {
      formData.append(key, form[key]);
    }

    if (Array.isArray(form[key])) {
      form[key].forEach((item) => {
        if (item) {
          formData.append(`${key}[${item.label || ''}]`, item.value || item);
        }
      });
    }
  });

  const request = {
    url: useZendeskV2 ? '/api/v3/submitForm' : '/api/v2/submitForm',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  try {
    if (shouldSkipHgRoutineBackup) {
      yield put(formsActions.backupHgRoutine.skipHgRoutine({ id: shouldSkipHgRoutineBackup }));
    }
    yield call(api.whmcs.post, request);
    onSuccess && onSuccess();
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: locale.forms.error,
      type: 'error',
    }));
  }
}

function* getHgRoutineBackups(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { id } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'getHgRoutineBackups' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${id}/available-backups`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(formsActions.backupHgRoutine.loading(true));
  try {
    const response = yield call(api.aws.get, request);
    yield put(formsActions.backupHgRoutine.set(response.data));
    yield put(formsActions.backupHgRoutine.success());
  } catch (e) {
    yield put(formsActions.backupHgRoutine.failure());
  }
  yield put(formsActions.backupHgRoutine.loading(false));
}

function* sagas() {
  yield takeLatest(FormsActionTypes.POST_FORM, openTicket);
  yield takeLatest(FormsActionTypes.REQUEST_HG_ROUTINE_BACKUPS, getHgRoutineBackups);
  yield takeLatest(FormsActionTypes.SKIP_HG_ROUTINE_BACKUPS, skipHgRoutineBackup);
}

export default sagas;
