import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import { DNSContext } from '@/contexts/DNS/context';
import ZoneManagerTitle from '@/components/Dns/ZoneManager/ZoneManagerTitle';
import ZoneManagerExplanation from '@/components/Dns/ZoneManager/ZoneManagerExplanation';
import ZoneManagerMessages from '@/components/Dns/ZoneManager/ZoneManagerMessages';
import ZoneManagerFormBlock from '@/components/Dns/ZoneManager/ZoneManagerFormBlock';
import RecordList from '@/components/Dns/ZoneManager/RecordList';
import styles from './styles';

const ZoneManager = ({ classes, dnsTableOnly = false }) => {
  const {
    couldConnectWithServer,
    details,
    featureTogglesZoneManager,
    hostingSelected,
  } = useContext(DNSContext);

  const hasDbData = details && details.dbData;

  const messageFromCpanel = hasDbData && details.dbData.hosting && (hostingSelected && hostingSelected.type !== 'reselleraccount') && (!featureTogglesZoneManager.hostingCpanel);
  const messageFromWhm = hasDbData && details.dbData.hosting && (hostingSelected && hostingSelected.type === 'reselleraccount') && (!featureTogglesZoneManager.hostingWhm);
  const messageFromOther = hasDbData && details.dbData.other && (!featureTogglesZoneManager.other);

  const hasMessages = messageFromCpanel
  || messageFromWhm
  || messageFromOther
  || couldConnectWithServer === false;

  return (
    <div className={!dnsTableOnly ? classes.cardWrapper : classes.tableOnlyWrapper} data-testid="zoneManagerSection">
      <div className={classes.zoneManager}>
        {!dnsTableOnly && <ZoneManagerTitle />}

        {!dnsTableOnly && <ZoneManagerExplanation />}

        {hasMessages
          && <ZoneManagerMessages />
        }

        {!hasMessages
          && <ZoneManagerFormBlock />
        }
      </div>

      {!hasMessages
        && <RecordList />
      }
    </div>
  );
};

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ZoneManager)));
