/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  TextField, withStyles, Typography, IconButton, CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import SelectField from '@/components/Fields/SelectField';
import TypeAValueField from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeAValueField';
import TypeAAAAValueField from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeAAAAValueField';
import TypeCAAValueField from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeCAAValueField';
import TypeMXValueFields from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeMXValueFields';
import TypeTXTValueFields from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeTXTValueFields';
import TypeCNAMEValueFields from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeCNAMEValueFields';
import TypeSRVValueFields from '@/components/Dns/ZoneManager/AddRecordForm/TypeFields/TypeSRVValueFields';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import OutlineButton from '@/components/Buttons/OutlineButton';
import { DNSContext } from '@/contexts/DNS/context';
import NumberMaskedInput from '@/components/Fields/InputMasks/NumberMaskedInput';
import styles from './styles';

let validationsTimeOut = null;

const AddRecordForm = ({
  classes, t, width, editing, recordedItem, closeForm, toggleCollapse, domainName,
}) => {
  const {
    loading, setToggleAddRecordForm, handleSubmit, recordTypes,
  } = useContext(DNSContext);
  const isWidthDownSm = isWidthDown('sm', width);
  const [formData, setFormData] = useState(null);
  const [isInvalidBlock, setIsInvalidBlock] = useState(false);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidDestination, setIsInvalidDestination] = useState(false);
  const [isInvalidDomain, setIsInvalidDomain] = useState(false);
  const [isInvalidPriority, setIsInvalidPriority] = useState(false);
  const [isInvalidPort, setIsInvalidPort] = useState(false);
  const [isInvalidWeight, setIsInvalidWeight] = useState(false);

  const removeMxParamaters = (data) => {
    const newData = data;
    delete newData.preference;
    delete newData.exchange;
    return newData;
  };

  const removeCaaParameters = (data) => {
    const newData = data;
    delete newData.flag;
    delete newData.tag;
    delete newData.value;
    return newData;
  };

  const removeAParameters = (data) => {
    const newData = data;
    delete newData.address;
    return newData;
  };

  const removeTxtParameters = (data) => {
    const newData = data;
    delete newData.txt_data;
    return newData;
  };

  const removeCnameParameters = (data) => {
    const newData = data;
    delete newData.cname;
    return newData;
  };

  const removeSrvParameters = (data) => {
    const newData = data;
    delete newData.priority;
    delete newData.port;
    delete newData.weight;
    delete newData.target;
    return newData;
  };

  const removeParameters = (data, type) => {
    let newData = data;

    if (type !== 'A' && type !== 'AAAA') {
      newData = removeAParameters(newData);
    }

    if (type !== 'CAA') {
      newData = removeCaaParameters(newData);
    }

    if (type !== 'MX') {
      newData = removeMxParamaters(newData);
    }


    if (type !== 'TXT') {
      newData = removeTxtParameters(newData);
    }

    if (type !== 'CNAME') {
      newData = removeCnameParameters(newData);
    }

    if (type !== 'SRV') {
      newData = removeSrvParameters(newData);
    }


    return newData;
  };

  const createParameters = (data, type) => {
    const newData = data;

    switch (type) {
      case 'CAA':
        newData.flag = '0';
        newData.tag = 'issue';
        newData.value = '';
        break;

      case 'MX':
        newData.preference = '';
        newData.exchange = '';
        break;

      case 'TXT':
        newData.txt_data = '';
        break;

      case 'CNAME':
        newData.cname = '';
        break;

      case 'SRV':
        newData.priority = '';
        newData.port = '';
        newData.weight = '';
        newData.target = '';
        break;

      case 'A':
      case 'AAAA':
      default:
        newData.address = '';
    }

    return newData;
  };

  const validateName = (name) => {
    if (name.length === 0 || name === '@') {
      setIsInvalidName(false);
      return;
    }

    let isValidDomain = true;

    const dotSplitedName = name.split('.');
    dotSplitedName.forEach((part) => {
      const regexUnderScore = RegExp(/(^_)?/);
      const regexDomain = RegExp(/(?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.?/);
      const validationOk = regexUnderScore.test(part) && regexDomain.test(part);
      const underscoreFound = part.match(/_/g);

      if (!validationOk || (underscoreFound && underscoreFound.length > 1)) {
        isValidDomain = false;
      }
    });

    const hasDomain = name.includes(domainName);
    if (hasDomain) {
      const splitedValue = name.split(`${domainName}`);

      let lastStringFromFirstBlock = splitedValue[0];
      lastStringFromFirstBlock = lastStringFromFirstBlock.slice(-1);

      if ((splitedValue[1] !== '' && splitedValue[1] !== '.') || (lastStringFromFirstBlock !== '.' && lastStringFromFirstBlock !== '')) {
        isValidDomain = false;
      }
    }

    if (name.includes('*')) {
      const regexWildCard = RegExp(/^(\*\.)/);
      isValidDomain = regexWildCard.test(name);

      const wildCardFound = name.match(/\*/g);
      if (wildCardFound && wildCardFound.length > 1) {
        isValidDomain = false;
      }
    }

    if (name.includes('@')) {
      isValidDomain = false;
    }

    setIsInvalidName(!isValidDomain);
  };

  const validateIodef = (hasHttp, isValidDomain, isMailTo) => {
    if (hasHttp && isValidDomain) {
      setIsInvalidBlock(false);
      return;
    }

    if (isMailTo) {
      setIsInvalidBlock(false);
      return;
    }

    setIsInvalidBlock(true);
  };

  const validateIssues = (hasHttp, isValidDomain, isMail, isSemicolon) => {
    if (hasHttp || isMail) {
      setIsInvalidBlock(true);
      return;
    }

    if (isValidDomain) {
      setIsInvalidBlock(false);
      return;
    }

    if (isSemicolon) {
      setIsInvalidBlock(false);
      return;
    }

    setIsInvalidBlock(true);
  };

  const validateCaaValue = (tag, value) => {
    const tagIsIodef = tag === 'iodef';
    const isSemicolon = value === ';';
    const isMail = value.includes('mailto') || value.includes('@');

    const regexDomain = RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/);
    const isValidDomain = regexDomain.test(value);

    const httpRegex = RegExp(/https?:?/);
    const hasHttp = httpRegex.test(value);

    const mailToRegex = RegExp(/^mailto:[a-z0-9]{1,64}@[a-z0-9]{1,63}\.[a-z0-9-]{1,61}/);
    const isMailTo = mailToRegex.test(value);

    if (value.length > 0 && value.lastIndexOf('/') === value.length - 1) {
      setIsInvalidBlock(true);
      return;
    }

    if (tagIsIodef) {
      validateIodef(hasHttp, isValidDomain, isMailTo);
    } else {
      validateIssues(hasHttp, isValidDomain, isMail, isSemicolon);
    }
  };

  const validateMxPriority = (preference) => {
    setIsInvalidPriority(parseInt(preference, 10) > 65535);
  };

  const validateMxTarget = (exchange) => {
    const hasAt = exchange.includes('@');

    if (hasAt) {
      setIsInvalidDestination(hasAt);
      return;
    }

    const regexDomain = RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i);
    const isValidDomain = regexDomain.test(exchange) && exchange.lastIndexOf('/') !== exchange.length - 1;
    setIsInvalidDestination(!isValidDomain);
  };

  const validateCnameDomain = (cname) => {
    let isValidDomain = true;

    const dotSplitedName = cname.split('.');
    dotSplitedName.forEach((part) => {
      const regexUnderScore = RegExp(/(^_)?/);
      const regexDomain = RegExp(/(?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.?/);
      const validationOk = regexUnderScore.test(part) && regexDomain.test(part);
      const underscoreFound = part.match(/_/g);

      if (!validationOk || (underscoreFound && underscoreFound.length > 1)) {
        isValidDomain = false;
      }
    });

    if (cname.includes('*')) {
      const regexWildCard = RegExp(/^(\*\.)/);
      isValidDomain = regexWildCard.test(cname);

      const wildCardFound = cname.match(/\*/g);
      if (wildCardFound && wildCardFound.length > 1) {
        isValidDomain = false;
      }
    }

    if (cname.includes('@')) {
      isValidDomain = false;
    }

    setIsInvalidDomain(!isValidDomain);
  };

  const validateSrvDomain = (
    priority,
    port,
    weight,
    target,
  ) => {
    setIsInvalidPriority(parseInt(priority, 10) > 65535);
    setIsInvalidPort(parseInt(port, 10) > 65535);
    setIsInvalidWeight(parseInt(weight, 10) > 65535);

    if (target.length > 0) {
      const destinationRegex = RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/);
      const isValidDestination = (destinationRegex.test(target));
      setIsInvalidDestination(!isValidDestination);
      return;
    }

    setIsInvalidDestination(false);
  };

  const validations = (formData) => {
    validateName(formData.name);

    if (formData.type === 'A') {
      const addressBlocks = formData.address.split('.').length;
      const formDataAdress = formData.address.split('.');
      formDataAdress.pop();
      if (formData.address.length > 0) {
        setIsInvalidBlock(addressBlocks !== 4 || formDataAdress === '' || formDataAdress === '.');
      } else {
        setIsInvalidBlock(false);
      }
    }

    if (formData.type === 'CAA') {
      if (formData.value && formData.value.length > 0) {
        validateCaaValue(formData.tag, formData.value);
      } else {
        setIsInvalidBlock(false);
      }
    }

    if (formData.type === 'MX') {
      if (formData.preference && formData.preference.length > 0) {
        validateMxPriority(formData.preference);
      } else {
        setIsInvalidPriority(false);
      }

      if (formData.exchange && formData.exchange.length > 0) {
        validateMxTarget(formData.exchange);
      } else {
        setIsInvalidDestination(false);
      }
    }

    if (formData.type === 'CNAME') {
      if (formData.cname && formData.cname.length > 0) {
        validateCnameDomain(formData.cname);
      } else {
        setIsInvalidDomain(false);
      }
    }

    if (formData.type === 'SRV') {
      validateSrvDomain(
        formData.priority,
        formData.port,
        formData.weight,
        formData.target,
      );
    }
  };

  const onHandleChangeInput = (eventTarget) => {
    const { name: key } = eventTarget;
    const { value } = eventTarget;
    let newFormData = { ...formData };

    if (key === 'type') {
      newFormData = removeParameters(newFormData, value);
      newFormData = createParameters(newFormData, value);
    }

    setFormData({
      ...newFormData,
      [key]: value,
    });

    if (key === 'name') {
      clearTimeout(validationsTimeOut);
      validationsTimeOut = setTimeout(() => {
        validations({
          ...newFormData,
          [key]: value,
        });
      }, 500);
    } else {
      validations({
        ...newFormData,
        [key]: value,
      });
    }
  };

  const handleFocusOutName = (value) => {
    let newValue = value;
    if (newValue.length > 0 && newValue !== '@') {
      const hasDomain = newValue.includes(domainName);
      const regexOnlyWhiteSpaces = RegExp(/^ +$/);
      const isOnlySpaces = regexOnlyWhiteSpaces.test(newValue);

      const lastCharIsDot = newValue[newValue.length - 1] === '.';
      if (hasDomain) {
        newValue = lastCharIsDot ? newValue : `${newValue}.`;
      } else if (!isOnlySpaces) {
        newValue = lastCharIsDot ? `${value}${domainName}.` : `${value}.${domainName}.`;
      }

      setFormData({
        ...formData, name: newValue,
      });


      validations({
        ...formData, name: newValue,
      });
    }
  };

  const startingFormdata = () => {
    let newFormData = null;

    if (editing) {
      newFormData = { ...recordedItem };

      if (!isNil(newFormData.flag)) {
        newFormData.flag = newFormData.flag.toString();
      }

      if (!isNil(newFormData.preference)) {
        newFormData.preference = newFormData.preference.toString();
      }
    } else {
      newFormData = {
        type: 'A',
        name: '',
        class: 'IN',
        ttl: 14400,
        address: '',
      };
    }

    setFormData(newFormData);
  };

  const setKeepEditing = (editing, isWidthDownSm, closeForm, toggleCollapse, contextSetToggleAddRecordForm) => {
    if (!editing) {
      return contextSetToggleAddRecordForm;
    }

    return isWidthDownSm ? closeForm : toggleCollapse;
  };

  const setCantSubmit = (contextLoading, formData, isInvalidBlock, isInvalidName, isInvalidDestination, isInvalidDomain) => {
    if (formData === null) {
      return true;
    }

    let cantSubmit = false;

    const hasInvalidOption = contextLoading || formData.ttl < 1 || isInvalidBlock || isInvalidName || isInvalidDestination || isInvalidDomain || isInvalidPriority;

    if (hasInvalidOption) {
      cantSubmit = true;
    } else {
      Object.keys(formData).forEach((key) => {
        if (formData[key] === '') {
          cantSubmit = true;
        }
      });
    }

    return cantSubmit;
  };

  useEffect(() => {
    startingFormdata();
  }, [editing, recordedItem]);

  const keepEditing = setKeepEditing(editing, isWidthDownSm, closeForm, toggleCollapse, setToggleAddRecordForm);
  const cantSubmit = setCantSubmit(loading, formData, isInvalidBlock, isInvalidName, isInvalidDestination, isInvalidDomain);
  let nameHelperText = null;

  if (isInvalidName) {
    nameHelperText = t('dns.zoneManager.addRecord.form.helperText.nameField');
  }

  if (formData && formData.name === '@') {
    nameHelperText = `${t('dns.zoneManager.addRecord.form.helperText.nameFieldAs@')} '${domainName}'`;
  }

  return (
    formData !== null
    && (
      <form
        onSubmit={(e) => {
          handleSubmit(e, formData, editing, keepEditing);
          startingFormdata();
        }}
        className={classes.addDNSForm}
        data-testid="recordForm"
      >

        {isWidthDownSm
          && (
            <div className={classes.header}>
              <IconButton
                data-testid="zoneManagerCloseButton"
                aria-label={t('dns.zoneManager.addRecord.form.closeForm')}
                className={classes.closeFormPortal}
                onClick={() => {
                  editing
                    ? closeForm()
                    : setToggleAddRecordForm(false);
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography
                variant="h6"
                className={classes.addRecordTitle}
              >
                {editing
                  ? t('dns.zoneManager.addRecord.form.editTitle')
                  : t('dns.zoneManager.addRecord.form.title')
                }
              </Typography>
            </div>
          )
        }

        <div className={classes.container}>
          <SelectField
            disabled={loading}
            className={classes.selectField}
            setMaxWidth={isWidthDownSm ? 'unset' : 110}
            inputLabel={t('dns.zoneManager.addRecord.form.label.type')}
            labelWidth={calcLabelWidth(t('dns.zoneManager.addRecord.form.label.type'))}
            filterOptions={recordTypes}
            selectValue={formData.type}
            selectType="type"
            name="type"
            handleChangeSelectValue={e => onHandleChangeInput(e.target)}
            menuItemDefaultText={t('dns.zoneManager.addRecord.form.label.type')}
            selectTestId="zoneManagerTypeSelectButton"
            selectInputTestId="zoneManagerTypeField"
          />

          <TextField
            disabled={loading}
            className={classes.textField}
            name="name"
            variant="outlined"
            required
            label={t('dns.zoneManager.addRecord.form.label.name')}
            value={formData.name}
            onChange={e => onHandleChangeInput(e.target)}
            onBlur={e => handleFocusOutName(e.target.value)}
            InputProps={{
              inputProps: {
                maxLength: formData.name.includes(`${domainName}.`) ? 254 : 253,
                'data-testid': 'zoneManagerNameField',
              },
            }}
            error={isInvalidName}
            helperText={nameHelperText}
          />

          <TextField
            disabled
            className={classes.textField}
            name="class"
            variant="outlined"
            required
            label={t('dns.zoneManager.addRecord.form.label.class')}
            placeholder={t('dns.zoneManager.addRecord.form.placeholder.class')}
            value={formData.class}
            onChange={e => onHandleChangeInput(e.target)}
            inputProps={{
              'data-testid': 'zoneManagerClassField',
            }}
          />

          <TextField
            disabled={loading}
            className={classes.textField}
            name="ttl"
            variant="outlined"
            required
            label={t('dns.zoneManager.addRecord.form.label.ttl')}
            placeholder={t('dns.zoneManager.addRecord.form.placeholder.ttl')}
            value={formData.ttl}
            onChange={e => onHandleChangeInput(e.target)}
            InputProps={{
              inputComponent: NumberMaskedInput,
              inputProps: {
                maxLength: 10,
                'data-testid': 'zoneManagerTtlField',
              },
            }}
            error={formData.ttl < 1}
            helperText={formData.ttl < 1 ? t('dns.zoneManager.addRecord.form.helperText.ttl') : null}
          />

          {formData.type === 'A' && <TypeAValueField formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} isInvalidBlock={isInvalidBlock} />}
          {formData.type === 'AAAA' && <TypeAAAAValueField formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} />}
          {formData.type === 'CAA' && <TypeCAAValueField formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} isInvalidBlock={isInvalidBlock} />}
          {formData.type === 'MX' && <TypeMXValueFields formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} isInvalidDestination={isInvalidDestination} isInvalidPriority={isInvalidPriority} />}
          {formData.type === 'TXT' && <TypeTXTValueFields formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} />}
          {formData.type === 'CNAME' && <TypeCNAMEValueFields formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} isInvalidDomain={isInvalidDomain} />}
          {formData.type === 'SRV' && <TypeSRVValueFields formData={formData} onHandleChangeInput={onHandleChangeInput} editing={editing} isInvalidPriority={isInvalidPriority} isInvalidPort={isInvalidPort} isInvalidWeight={isInvalidWeight} isInvalidDestination={isInvalidDestination} />}
        </div>

        {(loading && isWidthDownSm) && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
        )}

        <div className={classes.buttonsWrapper}>
          <PrimaryButton
            disabled={cantSubmit}
            type="submit"
            color="primary"
            data-testid="zoneManagerSaveButon"
          >
            {editing
              ? t('dns.zoneManager.addRecord.updateButton')
              : t('dns.zoneManager.addRecord.addButton')
            }
          </PrimaryButton>
          <OutlineButton
            className={classes.outlinedCancel}
            onClick={() => keepEditing(false)}
            disabled={loading}
            data-testid="zoneManagerCancelButon"
          >
            {t('dns.zoneManager.addRecord.cancelButton')}
          </OutlineButton>
        </div>
      </form>
    )
  );
};

AddRecordForm.propTypes = {
  editing: PropTypes.bool,
  recordedItem: PropTypes.object,
  closeForm: PropTypes.func,
  toggleCollapse: PropTypes.func,
  domainName: PropTypes.string.isRequired,
};

AddRecordForm.defaultProps = {
  editing: false,
  recordedItem: null,
  closeForm: null,
  toggleCollapse: null,
};

export default (withI18n()(withWidth()(withStyles(styles)(AddRecordForm))));
