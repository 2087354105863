import {
  IconDetailedGear, IconDoubt, IconLock, IconCheck,
} from '@/icons';
import { SlideToggle, Button, Tooltip } from '@/pages/common/v1';
import * as Styles from './WizardWordpressConfigInfo.styles';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Input } from '@/pages/common/v1/inputs/index';

const WizardWordpressConfigInfo = ({
  testId = 'wordpress-info',
  title = 'title',
  infoNextStepButton = 'next step label',
  description = 'description',
  questions = {
    name: {
      label: 'name',
      placeholder: 'name placeholder',
      tooltip: 'name tooltip',
      errorMessage: 'Name field error',
    },
    description: {
      label: 'description',
      placeholder: 'description placeholder',
      tooltip: 'description tooltip',
    },
    email: {
      label: 'email',
      placeholder: 'email placeholder',
      errorMessage: 'email field error',
    },
    user: {
      label: 'user',
      placeholder: 'user placeholder',
      errorMessage: 'user field error',
      errorMessageWithWondersuite: 'user with wondersuite field error',
    },
    password: {
      label: 'password',
      placeholder: 'password placeholder',
    },
    confirmPassword: {
      label: 'confirm password',
      placeholder: 'confirm password placeholder',
    },
  },
  wodpressSetupValues = {
    name: {
      value: '',
      empty: true,
      error: false,
    },
    description: {
      value: '',
      empty: true,
      error: false,
    },
    email: {
      value: '',
      empty: true,
      error: false,
    },
    user: {
      value: '',
      empty: true,
      error: false,
    },
    password: {
      value: '',
      empty: true,
      error: false,
      hasLowerAndUpperCase: false,
      hasNumber: false,
      hasSymbol: false,
      hasAtLeastEight: false,
      matchConfirmation: false,
    },
    confirmPassword: {
      value: '',
      empty: true,
      error: false,
    },
  },
  handleUpdateWordpressSetupValues,
  handleBlurWordpressSetupValues,
  passwordChecklistTexts = {
    description: 'password description:',
    hasLowerAndUpperCase: 'password uppercase',
    hasNumber: 'password number',
    hasSymbol: 'password symbol',
    hasAtLeastEight: 'password 8 charcater',
    matchConfirmation: 'password match',
  },
  userChecklistTexts = {
    description: 'user description:',
    hasAtLeastEight: 'user 8 character',
    hasAtLeastFour: 'user 4 character',
    notHaveSymbol: 'user symbol',
  },
  wordPressSetupReadyToContinue,
  onContinueSetup,
  open,
  setupSteps,
  activeSetupStep,
  wordPressInfoBlock,
  isWondersuiteEnabled,
  handleLoadingWordpress,
  asaasInsideWizardShouldAppear,
}) => {
  const infoIsTheUnicBlock = isWondersuiteEnabled && !asaasInsideWizardShouldAppear;
  const theme = useContext(ThemeContext);

  const handleChangeInput = (event) => {
    handleUpdateWordpressSetupValues && handleUpdateWordpressSetupValues(event);
  };

  const handleBlur = (event) => {
    handleBlurWordpressSetupValues && handleBlurWordpressSetupValues(event);
  };

  const handleContinue = (currentStep) => {
    if (infoIsTheUnicBlock) {
      return handleLoadingWordpress();
    }
    onContinueSetup && onContinueSetup(currentStep);
  };

  const handleOnToggle = () => {
    if (activeSetupStep !== setupSteps.UTILS && !infoIsTheUnicBlock) {
      onContinueSetup(0);
    }
  };

  const listDetail = valid => (valid ? <IconCheck size={14} /> : <Styles.ListDot />);

  const userChecklistSize = isWondersuiteEnabled ? userChecklistTexts.hasAtLeastFour : userChecklistTexts.hasAtLeastEight;
  const isUserSpecialCharacterRuleValid = wodpressSetupValues.user.notHaveSymbol || wodpressSetupValues.user.value.length === 0;
  const isUserSizeRuleValid = wodpressSetupValues.user.hasRequiredSize;

  return (
    <Styles.ToggleWrapper ref={wordPressInfoBlock}>
      <SlideToggle
        icon={<IconDetailedGear />}
        title={title}
        description={description}
        testId={testId}
        open={open}
        controlledByFather={activeSetupStep === setupSteps.UTILS}
        toggleOnIcon={activeSetupStep !== setupSteps.UTILS}
        onToggle={handleOnToggle}
      >

        {!isWondersuiteEnabled && (
        <>
          <Styles.QuestionWrapper>
            <Styles.LabelWrapper>
              <Styles.Triangle data-testid="triangle" />

              <Styles.Label data-testid="select-label">
                {questions.name.label}
              </Styles.Label>

              <Styles.TooltipWrapper>
                <Tooltip content={questions.name.tooltip} width="218px">
                  <IconDoubt />
                </Tooltip>
              </Styles.TooltipWrapper>
            </Styles.LabelWrapper>

            <Styles.InputWrapper data-testid="InputWrapper">
              <Input
                testId={`${testId}-input-name`}
                name="name"
                maxLength={100}
                counter
                errorMessage={wodpressSetupValues.name.error ? questions.name.errorMessage : ''}
                onChange={event => handleChangeInput(event)}
                onBlur={event => handleBlur(event)}
                value={wodpressSetupValues.name.value}
                type="text"
                placeholder={questions.name.placeholder}
              />
            </Styles.InputWrapper>
          </Styles.QuestionWrapper>
          <Styles.QuestionWrapper>
            <Styles.LabelWrapper>
              <Styles.Triangle data-testid="triangle" />

              <Styles.Label data-testid="select-label">
                {questions.description.label}
              </Styles.Label>

              <Styles.TooltipWrapper>
                <Tooltip content={questions.description.tooltip} width="218px">
                  <IconDoubt />
                </Tooltip>
              </Styles.TooltipWrapper>
            </Styles.LabelWrapper>

            <Styles.InputWrapper>
              <Input
                testId={`${testId}-input-description`}
                name="description"
                maxLength={100}
                counter
                onChange={event => handleChangeInput(event)}
                onBlur={event => handleBlur(event)}
                value={wodpressSetupValues.description.value}
                type="textarea"
                placeholder={questions.description.placeholder}
                rows="3"
              />
            </Styles.InputWrapper>
          </Styles.QuestionWrapper>
        </>
        )}

        <Styles.QuestionWrapper>
          <Styles.LabelWrapper>
            <Styles.Triangle data-testid="triangle" />

            <Styles.Label data-testid="select-label">
              {questions.email.label}
            </Styles.Label>
          </Styles.LabelWrapper>

          <Styles.InputWrapper>
            <Input
              testId={`${testId}-input-email`}
              name="email"
              onChange={event => handleChangeInput(event)}
              onBlur={event => handleBlur(event)}
              errorMessage={wodpressSetupValues.email.error ? questions.email.errorMessage : ''}
              value={wodpressSetupValues.email.value}
              type="email"
              placeholder={questions.email.placeholder}
            />
          </Styles.InputWrapper>
        </Styles.QuestionWrapper>

        <Styles.QuestionWrapper>
          <Styles.LabelWrapper>
            <Styles.Triangle data-testid="triangle" />

            <Styles.Label data-testid="select-label">
              {questions.user.label}
            </Styles.Label>
          </Styles.LabelWrapper>


          <Styles.InputWrapper>
            <Input
              testId={`${testId}-input-user`}
              name="user"
              maxLength={isWondersuiteEnabled ? 20 : 10}
              counter
              onChange={event => handleChangeInput(event)}
              onBlur={event => handleBlur(event)}
              value={wodpressSetupValues.user.value}
              type="text"
              placeholder={questions.user.placeholder}
            />
          </Styles.InputWrapper>
        </Styles.QuestionWrapper>
        <Styles.CheckListWrapper>
          <Styles.ListHeader>
            <Styles.ListInfo>{userChecklistTexts.description}</Styles.ListInfo>
          </Styles.ListHeader>
          <Styles.List>
            <Styles.ListItem valid={isUserSizeRuleValid}>
              {listDetail(isUserSizeRuleValid)}
              {userChecklistSize}
            </Styles.ListItem>

            <Styles.ListItem valid={isUserSpecialCharacterRuleValid}>
              {listDetail(isUserSpecialCharacterRuleValid)}
              {userChecklistTexts.notHaveSymbol}
            </Styles.ListItem>
          </Styles.List>
        </Styles.CheckListWrapper>

        <Styles.QuestionWrapper>
          <Styles.LabelWrapper>
            <Styles.Triangle data-testid="triangle" />

            <Styles.Label data-testid="select-label">
              {questions.password.label}
            </Styles.Label>
          </Styles.LabelWrapper>

          <Styles.InputWrapper>
            <Input
              testId={`${testId}-input-password`}
              name="password"
              maxLength={100}
              counter
              onChange={event => handleChangeInput(event)}
              onBlur={event => handleBlur(event)}
              value={wodpressSetupValues.password.value}
              type="password"
              placeholder={questions.password.placeholder}
              iconColor={theme.v1.color.action.primary}
            />
          </Styles.InputWrapper>
        </Styles.QuestionWrapper>

        <Styles.QuestionWrapper>
          <Styles.LabelWrapper>
            <Styles.Triangle data-testid="triangle" />

            <Styles.Label data-testid="select-label">
              {questions.confirmPassword.label}
            </Styles.Label>
          </Styles.LabelWrapper>

          <Styles.InputWrapper>
            <Input
              testId={`${testId}-input-confirmPassword`}
              name="confirmPassword"
              maxLength={100}
              counter
              onChange={event => handleChangeInput(event)}
              onBlur={event => handleBlur(event)}
              value={wodpressSetupValues.confirmPassword.value}
              type="password"
              placeholder={questions.confirmPassword.placeholder}
              iconColor={theme.v1.color.action.primary}
            />
          </Styles.InputWrapper>
        </Styles.QuestionWrapper>

        <Styles.CheckListWrapper>
          <Styles.ListHeader>
            <IconLock
              color={theme.v1.color.text.primary}
            />
            <Styles.ListInfo>{passwordChecklistTexts.description}</Styles.ListInfo>
          </Styles.ListHeader>

          <Styles.List>
            <Styles.ListItem valid={wodpressSetupValues.password.hasLowerAndUpperCase}>
              {listDetail(wodpressSetupValues.password.hasLowerAndUpperCase)}
              {passwordChecklistTexts.hasLowerAndUpperCase}
            </Styles.ListItem>

            <Styles.ListItem valid={wodpressSetupValues.password.hasNumber}>
              {listDetail(wodpressSetupValues.password.hasNumber)}
              {passwordChecklistTexts.hasNumber}
            </Styles.ListItem>

            <Styles.ListItem valid={wodpressSetupValues.password.hasSymbol}>
              {listDetail(wodpressSetupValues.password.hasSymbol)}
              {passwordChecklistTexts.hasSymbol}
            </Styles.ListItem>

            <Styles.ListItem valid={wodpressSetupValues.password.hasAtLeastEight}>
              {listDetail(wodpressSetupValues.password.hasAtLeastEight)}
              {passwordChecklistTexts.hasAtLeastEight}
            </Styles.ListItem>

            <Styles.ListItem valid={wodpressSetupValues.password.matchConfirmation}>
              {listDetail(wodpressSetupValues.password.matchConfirmation)}
              {passwordChecklistTexts.matchConfirmation}
            </Styles.ListItem>
          </Styles.List>
        </Styles.CheckListWrapper>

        <Styles.ActionWrapper>
          <Button
            disabled={wordPressSetupReadyToContinue}
            onClick={() => handleContinue(1)}
            label={infoNextStepButton}
            testId={`${testId}-continue`}
          />
        </Styles.ActionWrapper>
      </SlideToggle>
    </Styles.ToggleWrapper>
  );
};

export default WizardWordpressConfigInfo;
