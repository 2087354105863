import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, noMarginTop }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    margin: ${noMarginTop ? '16px' : '48px 16px 16px'};
  `};
`;

export const SelectProductsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 16px;
`;

export const SuccessWrapper = styled(Wrapper)`
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 546px;
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BottomContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    border-radius: 4px;
    padding: 24px;
  `};
`;

export const ChangeMethodContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 32px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 0;
    };
  `};
`;

export const ButtonsContainer = styled.div`
  ${({ theme, centralized }) => css`
    display: flex;
    gap: 24px;
    justify-content: ${centralized && 'center'};
    margin-top: 16px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      align-self: center;
      flex-direction: column-reverse;
      width: fit-content;
    }
  `};
`;

export const ModalTitle = styled.h2`
  ${({ theme, noMargin }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: ${noMargin ? '0' : '4px 0 32px'};
    text-align: center;
  `};
`;

export const ModalDescription = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 32px 0;
    text-align: center;
  `};
`;

export const ListTitle = styled(ModalTitle)`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    margin: 0 0 24px;
    text-align: start;
  `};
`;

export const SuccessTitle = styled(ModalTitle)`
  margin: 4px 0 8px;
`;

export const Description = styled.b`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
  `};
`;

export const ListDescription = styled(Description)`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
  `};
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;

export const Image = styled.img`
  ${({ theme }) => css`
    max-width: 124px;
    user-select: none;
    -webkit-user-drag: none;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    margin: 24px 0 0;
    max-height: 379px;
    overflow: auto;
    padding: 0;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      max-height: 462px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.v2.colors.white.primary};
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.v2.colors.neutral.highMedium};
      border-left: 12px;
      border-radius: 25px;
      border-right: 0;
      height: 30px;
      width: 100%;
    }
  `};
`;

export const ListItem = styled.li`
  ${({ theme, lastItem }) => css`
    border-bottom: ${!lastItem && `1px solid ${theme.v2.colors.neutral.highMedium}`};
    display: flex;
    padding: 16px;
    margin-right: 14px;
  `};
`;

export const ListItemInfos = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 24px;
    height: 44px;
    list-style-type: none;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-wrap: wrap;
      height: auto;
    }
  `};
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    margin-right: 12px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: start;
    }
  `}:
`;

export const ListItemField = styled.div`
`;

export const ListItemLabel = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0 0 4px;
  `};
`;

export const ListItemValue = styled.p`
  ${({ theme, isProduct }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${isProduct && '180px'};
    white-space: nowrap;
  `};
`;
