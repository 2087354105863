import { SELECTED_TOOL } from './useWizardWordpressThemes.enum';
import {
  blocksyP,
  blocksy,
  bravadaP,
  bravada,
  colibriP,
  colibri,
  helloP,
  hello,
  hestiaP,
  hestia,
  rifeP,
  rife,
} from '@/media/siteBuilder/wordpressThemes';
import {
  asheP,
  ashe,
  astraP,
  astra,
  envoP,
  envo,
  shopmaxP,
  shopmax,
  storefrontP,
  storefront,
  supermarketP,
  supermarket,
} from '@/media/siteBuilder/woocommerceThemes';

const woocommerceThemes = [
  {
    title: 'Ashe',
    image: asheP,
    imageDetails: ashe,
    value: 'Ashe_admin',
    id: 1,
  },
  {
    title: 'Astra',
    image: astraP,
    imageDetails: astra,
    value: 'Astra_admin',
    id: 2,
  },
  {
    title: 'Envo',
    image: envoP,
    imageDetails: envo,
    value: 'Envo_admin',
    id: 3,
  },
  {
    title: 'ShopMax',
    image: shopmaxP,
    imageDetails: shopmax,
    value: 'ShopMax_admin',
    id: 4,
  },
  {
    title: 'Storefront',
    image: storefrontP,
    imageDetails: storefront,
    value: 'Storefront_admin',
    id: 5,
  },
  {
    title: 'Supermarket',
    image: supermarketP,
    imageDetails: supermarket,
    value: 'Supermarket_admin',
    id: 6,
  },
];

const themes = {
  [SELECTED_TOOL.WORDPRESS]: [
    {
      title: 'Blocksy',
      image: blocksyP,
      imageDetails: blocksy,
      value: 'Blocksy_admin',
      id: 1,
    },
    {
      title: 'Bravada',
      image: bravadaP,
      imageDetails: bravada,
      value: 'Bravada_admin',
      id: 2,
    },
    {
      title: 'Colibri',
      image: colibriP,
      imageDetails: colibri,
      value: 'Colibri_admin',
      id: 3,
    },
    {
      title: 'Hello',
      image: helloP,
      imageDetails: hello,
      value: 'Hello_admin',
      id: 4,
    },
    {
      title: 'Hestia',
      image: hestiaP,
      imageDetails: hestia,
      value: 'Hestia_admin',
      id: 5,
    },
    {
      title: 'Rife',
      image: rifeP,
      imageDetails: rife,
      value: 'Free_admin',
      id: 6,
    },
  ],
  [SELECTED_TOOL.ECOMMERCEPLUS_TRIAL]: woocommerceThemes,
  [SELECTED_TOOL.ECOMMERCEPLUS]: woocommerceThemes,
  [SELECTED_TOOL.WOOCOMMERCE]: woocommerceThemes,
};

const rules = {
  themes,
};

export default rules;
