import React, { useEffect, useState } from 'react';
import { IconClose } from '@/icons';
import * as Style from './DiscountSaversBanner.styles';
import saversBellIcon from '@/media/subscriptions/retention/saversBellIcon.svg';
import { Button } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { useHistory } from 'react-router';
import { billingAnalytics } from '@/analytics';

const DiscountSaversBanner = ({
  invoice = null,
}) => {
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { offerSaversBanner: offerSaversBannerLocale } = billingLocale;
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const {
    description,
    title,
    buttonLabel,
  } = offerSaversBannerLocale;

  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.bannerDiscountDisplay(origin.toUpperCase());
  }, []);

  const handleClick = () => {
    let url = routesLocale.invoices;

    if (invoice !== null && invoice.id !== undefined) {
      url = `${url}${routesLocale.unpaid}/${invoice.id}?open_invoice=true`;
    }

    setVisible(!visible);
    subscriptionAnalytics.bannerSaversClick();
    history.push(url);
  };

  const handleClose = () => {
    setVisible(false);
    subscriptionAnalytics.bannerSaversClose();
  };

  return (
    visible
    && (
      <Style.Wrapper>
        <Style.TopLine />
        <Style.Content data-testid="discount-offer-banner">
          <Style.GiftIcon variant={origin} src={saversBellIcon} alt="discountGift" />
          <Style.ContentText>
            <Style.Info>
              <Style.ContentTitle>
                <Style.Title data-testid="title-banner">
                  {invoice === null && (
                    `${title[0]}`
                  )}
                  {invoice !== null && invoice.id !== null && (
                    `${title[0]} ${title[1]} ${invoice.due_date}`
                  )}
                </Style.Title>
              </Style.ContentTitle>
              <Style.ContentDescription>
                <Style.Description data-testid="description" variant={origin}>
                  {description}
                </Style.Description>

              </Style.ContentDescription>

            </Style.Info>
            <Style.ContentButton>
              <Button
                label={buttonLabel}
                testId="top-discount-banner-button-redirect"
                onClick={handleClick}
                variant="secondary"
              />
            </Style.ContentButton>

          </Style.ContentText>
          <Style.CloseButton onClick={handleClose} data-testid="top-discount-banner-close">
            <IconClose color="white" />
          </Style.CloseButton>
        </Style.Content>
        <Style.NoGiveUp alt="noGiveup" />
      </Style.Wrapper>
    )
  );
};

export default DiscountSaversBanner;
