import preChatBR from './preChat.br';
import preChatMX from './preChat.mx';
import preChatCL from './preChat.cl';
import preChatCO from './preChat.co';


const preChat = {
  BR: preChatBR,
  MX: preChatMX,
  CL: preChatCL,
  CO: preChatCO,
};

export default preChat;
