
const themeV2 = {
  colors: {
    white: {
      primary: '#FFFFFF',
    },
    brand: {
      primaryBlue: '#2E93EE',
      primaryLowLight: '#E7F3FD',
      primaryUltraLight: '#F3F9FF',
      primaryLight: '#74C1FF',
      primaryMedium: '#0070D1',
      primaryDark: '#1D5297',
      primaryExtraDark: '#1F2044',
      menuPrimaryMedium: '#1D5297',
      menuPrimaryDark: '#1F2044',
    },
    neutral: {
      highPure: '#FFFFFF',
      highLight: '#F1F3F6',
      highMedium: '#CDD8DF',
      highDark: '#A6B6CC',
      lowPure: '#4A4A4A',
      lowLight: '#959EA3',
      lowMedium: '#707070',
      lowDark: '#363636',
      blackPure: '#000',
    },
    feedback: {
      pendingPure: '#FF9442',
      pendingUltraLight: '#FFF1E5',
      pendingLight: '#FFA464',
      pendingMedium: '#F67922',
      attentionPure: '#FFCF00',
      attentionUltraLight: '#FFFAE5',
      attentionLight: '#FFE885',
      attentionMedium: '#EEC100',
      activePure: '#30B47A',
      activeUltraLight: '#EBFAF3',
      activeLight: '#35CF8B',
      activeMedium: '#2CA26E',
      problemPure: '#FC3C2B',
      problemUltraLight: '#FFE8E6',
      problemLight: '#F16A5E',
      problemMedium: '#DA4336',
      canceledPure: '#CDD8DF',
      canceledLight: '#F1F3F6',
      canceledMedium: '#A6B6CC',
    },
  },
  font: {
    family: {
      primary: "'Galano', 'Roboto',sans-serif,'Helvetica Neue',sans-serif",
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
    sizes: {
      xxxs: '12px',
      xxs: '14px',
      xs: '16px',
      sm2: '18px',
      sm: '20px',
      md: '24px',
      lg: '30px',
    },
    lineHeight: {
      text: '1.35em',
      list: '1.90em',
    },
  },
  breakpoints: {
    xs: '360px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
  layers: {
    hidden: -1,
    base: 0,
    header: 20,
    overlay: 30,
    modal: 40,
    awaysOnTop: 50,
    important: 99999,
  },
  shadows: {
    light: '0px 1px 3px #00000016',
    dark: '0px 3px 6px #00000020',
  },
};

export default themeV2;
