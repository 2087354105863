import esContent from './domains.es';
import React from 'react';

export default {
  ...esContent,
  dnsWizard: {
    ...esContent.dnsWizard,
    modals: {
      ...esContent.dnsWizard.modals,
      alterEmailPlatform: {
        title: 'Cambiar plataforma de correo',
        subtitle: 'Usted puede cambiar para la plataforma de correo Titan, cPanel, Gmail o otra plataforma que desee.',
        continueLabel: 'Continuar',
      },
    },
  },
  manageDomainHandler: {
    ...esContent.manageDomainHandler,
    alert: {
      ...esContent.manageDomainHandler.alert,
      nextDueDate: () => (
        <>
          {'La suscripción vencerá pronto. Le recomendamos que utilice un método de pago con '}
          <strong>compensación inmediata,</strong>
          {' como '}
          <strong>Tarjeta de crédito o PayPal.</strong>
        </>
      ),
    },
  },
};
