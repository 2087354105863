import React from 'react';
import { Button, Alert, Accordion } from 'gatorcomponents';
import * as Styles from './ModalDomainInPropagation.styles';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { Modal } from '@/pages/common';

const ModalDomainInPropagation = ({ domainsList, hideModal, mobile }) => {
  const { alerts: alertsLocale } = useLocale();

  const DomainsListContent = () => {
    const domainsContent = () => (
      <>
        {domainsList.length <= 4 && (
          <strong>{alertsLocale.modalDomainInPropagation.alertTitle}</strong>
        )}
        {domainsList.map(item => (
          <li>{item.domain}</li>
        ))}
      </>
    );

    return (
      <>
        {domainsList.length > 4 ? (
          <Accordion testId="domains-in-progress-accordion" items={domainsContent} title={alertsLocale.modalDomainInPropagation.accordionTitle} leftIcon="IconClock" leftIconColor="pendingPure" withShadow width="full" />
        ) : (
          <Alert testId="domains-in-progress-alert" description={domainsContent()} variant="timed" customIcon="IconClock" width="full" />
        )}
      </>
    );
  };

  const handleHideModal = () => {
    hideModal && hideModal();
  };

  return (
    <Modal onClose={handleHideModal} maxWidth={624} testId="domain-in-propagation-modal">
      <Styles.Wrapper>
        <Styles.Title isMobile={mobile}>
          {alertsLocale.modalDomainInPropagation.title}
        </Styles.Title>
        <Styles.Container>
          {alertsLocale.modalDomainInPropagation.description}
        </Styles.Container>
        <Styles.Description>
          <strong>{alertsLocale.modalDomainInPropagation.knowMoreLabel}</strong>
          <Styles.Container>{alertsLocale.modalDomainInPropagation.helpCentralLabel}</Styles.Container>
        </Styles.Description>
        <Styles.Container>
          <DomainsListContent />
        </Styles.Container>
        <Styles.ButtonContainer>
          <Button
            testId="ok-button"
            label={alertsLocale.modalDomainInPropagation.okUnderstoodButtonLabel}
            size="large"
            onClick={handleHideModal}
          />
        </Styles.ButtonContainer>
      </Styles.Wrapper>
    </Modal>
  );
};

export default withI18n()((ModalDomainInPropagation));
