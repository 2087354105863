import React, { useCallback, useState } from 'react';
import * as Styles from './InstallExternalSsl.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';

export const InstallExternalSsl = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { installExternalSsl: installExternalSslLocale } = ticketsLocale.forms;
  const [urlToInstall, setUrlToInstall] = useState('');

  const [sslFile, setSslFile] = useState('');
  const [sslFileBinary, setSslFileBinary] = useState();
  const [sslCaTextarea, setSslCaTextarea] = useState('');
  const [pKeyTextarea, setPKeyTextarea] = useState('');

  const [agreed, setAgreed] = useState(false);

  const checkForm = useCallback(() => {
    let isOk = false;

    if (urlToInstall && sslFileBinary && pKeyTextarea && agreed) {
      isOk = true;
    }

    return !isOk;
  }, [urlToInstall, sslFileBinary, pKeyTextarea, agreed]);

  const handleFile = (e) => {
    setSslFile(e.currentTarget.value);
    setSslFileBinary(e.target.files[0]);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.installExternalSsl,
      key: V2Forms.EXTERNAL_SSL,
      tags: [
        installExternalSslLocale.bodyFields.tag,
        selectedProduct.serverhostname || '',
        selectedProduct.name.toLowerCase().replace(' ', '_'),
      ],
      files: [sslFileBinary],
      body: `
        • ${installExternalSslLocale.bodyFields.product}: ${selectedProduct.name} - ${installExternalSslLocale.bodyFields.user}: ${selectedProduct.username}
        • ${installExternalSslLocale.bodyFields.server}: ${(selectedProduct.serverhostname || selectedProduct.dedicatedip) || ''}
        • ${installExternalSslLocale.bodyFields.solicitation}: ${ticketsLocale.forms.names.installExternalSsl}
        • ${installExternalSslLocale.bodyFields.sslUrl}: ${urlToInstall}
        • ${installExternalSslLocale.bodyFields.sslCa}: ${sslCaTextarea}
        • ${installExternalSslLocale.bodyFields.privateKey}: ${pKeyTextarea}
        • ${installExternalSslLocale.bodyFields.agree}: ${agreed ? installExternalSslLocale.bodyFields.yes : installExternalSslLocale.bodyFields.no}
        • ${installExternalSslLocale.bodyFields.productId}: ${selectedProduct.id}
      `,
    };

    submitForm && submitForm(data);
  }, [
    sslFileBinary,
    pKeyTextarea,
    sslCaTextarea,
    urlToInstall,
    ticketsLocale,
    submitForm,
    agreed,
    selectedProduct,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {installExternalSslLocale.info}
      </Styles.Info>

      <Styles.InputsWrapper>
        <Styles.InputContainer>
          <Input
            name="urlToInstall"
            testId="urlToInstall"
            placeholder={installExternalSslLocale.urlInputPlaceholder}
            value={urlToInstall}
            onChange={e => setUrlToInstall(e.target.value)}
          />
          <Styles.InputDescription>
            {installExternalSslLocale.urlInputLabel}
          </Styles.InputDescription>
        </Styles.InputContainer>

        <Styles.FileInputContainer>
          <Styles.InputTitle>{installExternalSslLocale.sslCertificate}</Styles.InputTitle>
          <Styles.FileWrapper>
            <Styles.FileButton htmlFor="ssl-certificate-file" file={!!sslFile}>
              {(sslFileBinary && sslFileBinary.name) || installExternalSslLocale.addDocumentButton}
            </Styles.FileButton>
            <Styles.FileInput
              type="file"
              id="ssl-certificate-file"
              data-testid="ssl-certificate-file"
              accept=".crt"
              value={sslFile}
              onChange={e => handleFile(e)}
            />
          </Styles.FileWrapper>
          <Styles.InputDescription>{installExternalSslLocale.sslCertififateFileType}</Styles.InputDescription>

          <Styles.TextAreaWrapper>
            <Input
              type="textarea"
              testId="ssl-certificate-ca"
              label={installExternalSslLocale.sslCertificateCA}
              placeholder={installExternalSslLocale.orPasteContentPlaceholder}
              value={sslCaTextarea}
              onChange={e => setSslCaTextarea(e.target.value)}
              rows={3}
              counter
            />
          </Styles.TextAreaWrapper>
          <Styles.TextAreaWrapper>
            <Input
              type="textarea"
              testId="ssl-private-key"
              label={installExternalSslLocale.privateKey}
              placeholder={installExternalSslLocale.orPasteContentPlaceholder}
              value={pKeyTextarea}
              onChange={e => setPKeyTextarea(e.target.value)}
              rows={3}
              counter
            />
          </Styles.TextAreaWrapper>
        </Styles.FileInputContainer>

        <Styles.CheckboxLabel
          htmlFor="#authorizeTax"
          data-testid="checkAuthorizeTax"
          onClick={() => setAgreed(!agreed)}
        >
          <Checkbox checked={agreed} id="agreed" />
          <Styles.Text>
            {installExternalSslLocale.checkboxLabel}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputsWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : installExternalSslLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default InstallExternalSsl;
