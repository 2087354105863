import {
  ERROR_CHANGE_PHONE_NUMBER,
  ERROR_SEND_PHONE_CODE,
  ERROR_VALIDATE_PHONE_CODE,
  PHONENUMBER_VERIFY_SKIP,
  PHONENUMBER_CHANGE_FLOW,
  RECEIVE_CHANGE_PHONE_NUMBER,
  RECEIVE_SEND_PHONE_CODE,
  REQUEST_CHANGE_PHONE_NUMBER,
  REQUEST_SEND_PHONE_CODE,
  REQUEST_VALIDATE_PHONE_CODE,
  ERROR_SEND_PHONE_WRONG_PASSWORD,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loadingSend: false,
  loadingValidate: false,
  loadingChange: false,
  phoneNumberFlowSkip: false,
  phoneNumberChangeFlow: false,
  forceUrl: undefined,
};

export default function phoneNumberVerify(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEND_PHONE_CODE:
      return {
        ...state,
        loadingSend: true,
      };
    case RECEIVE_SEND_PHONE_CODE:
      return {
        loadingSend: false,
      };
    case ERROR_SEND_PHONE_CODE:
    case ERROR_SEND_PHONE_WRONG_PASSWORD:
      return {
        loadingSend: false,
      };
    case REQUEST_VALIDATE_PHONE_CODE:
      return {
        ...state,
        loadingValidate: true,
      };
    case ERROR_VALIDATE_PHONE_CODE:
      return {
        ...state,
        loadingValidate: false,
      };
    case REQUEST_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        loadingChange: true,
      };
    case RECEIVE_CHANGE_PHONE_NUMBER:
      return {
        ...state,
      };
    case ERROR_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        loadingChange: false,
      };
    case PHONENUMBER_VERIFY_SKIP:
      return {
        ...state,
        phoneNumberFlowSkip: true,
      };
    case PHONENUMBER_CHANGE_FLOW: {
      const { data } = action;
      return {
        ...state,
        phoneNumberChangeFlow: data.bool,
        forceUrl: data.currentUrl,
      };
    }
    default:
      return state;
  }
}
