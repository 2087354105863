const styles = theme => ({
  menuItemText: {
    paddingLeft: 0,
  },
  menuItem: {
    paddingLeft: 7,
  },
  formControl: {
    maxWidth: '100%',
  },
  selectCustom: {
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 6,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderRadius: '2px',
    },
  },
  selectCustomInput: {},
  selectCustomOutline: {},
  selectCustomFocused: {},
  inputLabelCustom: {
    transform: 'translate(14px, 10px) scale(1)',
    zIndex: 0,
  },
  shrinkReplace: {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
});

export default styles;
