import styled, { css } from 'styled-components';

const mobileBreakpoint = 'max-width:1122px';
const extraSmallMobileBreakpoint = 'max-width:1122px';

export const SanppyImage = styled.img`
    bottom: -38px;
    left: 78px;
    position: absolute;

    @media(${mobileBreakpoint}) {
      display: none;
      visibility: hidden;
    }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;

  svg{
    flex: 0 0 45px;
    @media(${extraSmallMobileBreakpoint}){
      display: none;
      visibility: none;
    }
  }

  @media(${extraSmallMobileBreakpoint}){
    flex-direction: column;
    padding: 8px
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 8px 0px 8px 182px;

    strong {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v2.font.weights.medium};
    }

    @media(${mobileBreakpoint}) {
      margin: 8px 0px 8px 16px;
    }

    @media(${extraSmallMobileBreakpoint}){
      margin: 0px 0px 8px 0px;
    }
  `}
`;
