import { locale } from '@/utils/locale';

export const trialActiveLocale = {
  title: locale('modalTitanTrialHirePlan.title'),
  description: locale('modalTitanTrialHirePlan.description'),
  tagOfTrialTime: locale('modalTitanTrialHirePlan.tagOfTrialTime'),
  productDescription: {
    1: locale('modalTitanTrialHirePlan.productDescription.1'),
    2: locale('modalTitanTrialHirePlan.productDescription.2'),
    3: locale('modalTitanTrialHirePlan.productDescription.3'),
    4: locale('modalTitanTrialHirePlan.productDescription.4'),
  },
  titleForm: locale('modalTitanTrialHirePlan.titleForm'),
};
