import sitelockBR from './sitelock.br';
import sitelockCL from './sitelock.cl';
import sitelockCO from './sitelock.co';
import sitelockMX from './sitelock.mx';

const locales = {
  BR: sitelockBR,
  CL: sitelockCL,
  CO: sitelockCO,
  MX: sitelockMX,
};

export default locales;
