const styles = theme => ({
  titleElement: {
    paddingBottom: 6,
    paddingTop: 0,
    padding: 0,
    margin: '0 0 0 42px',

    '& h6': {
      color: theme.color.thundora,
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: '0.01px',
      lineHeight: '29px',
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: 26,
      paddingBottom: 11,
    },
  },

  closeButton: {
    margin: '15px 17px 0px auto',
    maxHeight: 24,
    maxWidth: 24,
  },

  containerWrapper: {
    flex: 'unset',
    margin: '0 20px 0px 42px',
    overflowY: 'unset',
    padding: 0,

    [theme.breakpoints.down(960)]: {
      margin: '0 20px 24px 26px',
    },
  },

  containerWrapperCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 12,

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginTop: 0,
    },
  },

  modalDescriptionRow: {
    borderBottom: `1px solid ${theme.color.geyser}`,
    borderTop: `1px solid ${theme.color.geyser}`,
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 0px',
    padding: '12px 0px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: 0,
    },
  },

  changeInboxDescription: {
    flexGrow: 1,
  },

  firstInboxBlock: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
      width: '100%',
    },
  },

  description: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
  },

  tableITitle: {
    color: theme.color.regentGrey,
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
  },

  tableItem: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  actionsWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      margin: 0,
    },
  },
});

export default styles;
