import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 24px;
    background-color: ${theme.palette.error.ultralight};
    color: ${theme.v1.color.text.primary};
    border-radius: 6px;
  `}
`;

export const AttentionLabel = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      fill: ${theme.v1.color.warning.error};
    }
  `}
`;
