import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/v1/Button';
import * as Style from './AnotherDomainModal.styles';
import { Input } from '@/pages/common/v1/inputs';
import Loader from '@/components/Layout/Loader';
import { domain as isDomain, removeDomainPrefix } from '@/utils/Validators/validation';

const AnotherDomainModal = ({
  onClose,
  prefixDomain = 'www.',
  loading,
  title,
  subTitle,
  placeholder,
  buttonLabel,
  titleMessageErrorBr,
  messageError,
  tldMessageError,
  checkDomainAvailability,
  tldAvailability,
  userDomainList,
  handleChangeDomain,
}) => {
  const [domain, setDomain] = useState('');
  const [error, setError] = useState(undefined);
  const [isDomainChecked, setIsDomainChecked] = useState(false);

  const handleOnClose = () => {
    onClose && onClose(true);
  };

  const handleOnInputChange = useCallback((event) => {
    const { target } = event;
    const { value } = target;
    setError(undefined);

    const newValue = value ? value.toLowerCase() : '';

    setDomain(newValue);
  }, []);

  const handleOnClick = useCallback(() => {
    const domainExisists = userDomainList.find(item => item.label === domain);
    if (domainExisists) {
      handleChangeDomain(domain);
      onClose && onClose();
    }

    if (!domainExisists) {
      const domainIsValid = isDomain(domain);

      if (!domainIsValid && domain.includes('.br')) {
        setError(titleMessageErrorBr);
        return;
      }

      if (!domainIsValid && !domain.includes('.br')) {
        setError(messageError);
        return;
      }

      checkDomainAvailability && checkDomainAvailability({ domain: removeDomainPrefix(domain) });
      setIsDomainChecked(true);
    }
  }, [
    checkDomainAvailability,
    domain,
    messageError,
    titleMessageErrorBr,
    handleChangeDomain,
    userDomainList,
    onClose,
  ]);

  useEffect(() => {
    if (!tldAvailability && !loading && isDomainChecked) {
      setError(tldMessageError);
    }
  }, [tldAvailability, loading, isDomainChecked]);

  return (
    <Modal onClose={handleOnClose} data-testid="another-domain-modal">
      <Style.Wrapper>
        <Style.Title data-testid="another-domain-title">
          {title}
        </Style.Title>
        <Style.SubTitle data-testid="another-domain-subtitle">
          {subTitle}
        </Style.SubTitle>

        <Style.InputsWrapper>
          <Style.Fields>
            {prefixDomain ? (
              <Input
                value={prefixDomain}
                placeholder={prefixDomain}
                testId="another-domain-modal-prefix"
                type="text"
                disabled
                maxWidth="58px"
              />
            ) : null}
            <Input
              value={domain}
              onChange={handleOnInputChange}
              placeholder={placeholder}
              type="text"
              mask="domain"
              testId="another-domain-modal-input"
            />
          </Style.Fields>
        </Style.InputsWrapper>

        {error && (
          <Style.ErrorMessage data-testid="another-domain-error-message">
            {error}
          </Style.ErrorMessage>
        )}

        <Style.ActionWrapper>
          <Button
            label={loading ? <Loader size={12} /> : buttonLabel}
            onClick={handleOnClick}
            disabled={loading}
            testId="another-domain-modal-button"
          />
        </Style.ActionWrapper>
      </Style.Wrapper>
    </Modal>
  );
};

export default AnotherDomainModal;
