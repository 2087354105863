import styled, { css } from 'styled-components';

export const Chip = styled.div`
  ${({
    theme, recommended, altLayout, bold,
  }) => {
    const colors = {
      background: recommended ? theme.palette.danger.primary : theme.palette.primary.main,
      fontColor: theme.color.white,
      border: altLayout && !recommended ? `solid 1px ${theme.color.white}` : 'transparent',
    };

    const fontWeight = bold ? theme.font.weights.medium : theme.font.weights.regular;

    return css`
      align-items: center;
      background-color: ${colors.background};
      border-radius: 16px;
      border: ${colors.border};
      color: ${colors.fontColor};
      display: flex;
      font-size: 12px;
      height: 22px;
      letter-spacing: 0.01px;
      line-height: 14px;
      padding: 0 16px;
      font-weight: ${fontWeight};
      white-space: nowrap;
      }
    `;
  }}
`;
