import React from 'react';
import {
  withStyles, Card, Typography, Button,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { withI18n, Trans } from 'react-i18next';
import { SITELOCK_SMARTSCAN_HELP_URL } from '@/config/urls/sitelock';
import SMARTScanResult from './SMARTScanResult';
import styles from './styles';

const SMARTHistory = ({ classes, t, handleCloseFn }) => {
  const { history } = useSelector(state => state.sitelock.smartscan);
  const { domain } = useSelector(state => state.sitelock);

  return (
    <Card className={classes.card}>
      <Typography className={classes.historyTitle} data-testid="title-name">
        {t('sitelock.smartscan.historyTitle')}
        <Button size="small" aria-label="Close" onClick={() => { handleCloseFn(); }}>
          <Close />
        </Button>
      </Typography>
      <Typography className={classes.historyDescription} data-testid="title-description">
        <Trans i18nKey="sitelock.smartscan.historyDescription" helpUrl={SITELOCK_SMARTSCAN_HELP_URL}>
          <a target="_blank" rel="noopener noreferrer" href={SITELOCK_SMARTSCAN_HELP_URL}>
            {SITELOCK_SMARTSCAN_HELP_URL}
          </a>
        </Trans>
      </Typography>
      <div className={classes.tableContainer}>
        <div className={classes.tableScroll}>
          {history && history.map(element => (<SMARTScanResult history={element} domainName={domain} key={element.smartscan} />))}
        </div>
      </div>
    </Card>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(SMARTHistory));
