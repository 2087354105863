import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Row = styled.section`
  ${({ marginbottom }) => css`
    margin-bottom: ${marginbottom ? '10px' : '0px'};

    > header {
      box-shadow: none;
      padding: 16px 24px
    }
  `}
`;

export const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 8px;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.white};
    border-radius: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    padding: 0px 24px 5px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const FaqWrapper = styled.section`
  ${({ theme }) => css`
    display: flex;
    gap: 24px;
    margin: 40px 0px 0px;
    max-width: 100%;
    padding-bottom: 50px;

    @media (max-width: ${theme.breakpoints.md}) {
      gap: unset;
      max-width: unset;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 24px;
      max-width: unset;
      flex-direction: column;
    }
  `}
`;

export const FilterHeadContainer = styled.div`
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    margin-bottom: 4px;
  `};
`;

export const Description = styled.p`
  ${({ theme, marginBottom }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    margin: 0;
    margin-bottom: ${marginBottom && '16px'};

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `};
`;

export const ToolsList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    margin: 0 0 0 16px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      gap: 8px;
    }
  `}
`;

export const Tool = styled.li`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: ${theme.v1.layers.header};
  `}
`;

export const ToolTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 10px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 13px;
    margin: 5px 0 0 0;
    text-align: center;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px;
  `}
`;

export const FilterHeadWrapper = styled.div`
  display: flex;
`;

export const FilterMessage = styled.div`
  max-width: 75%;
`;

export const ToolslistWrapper = styled.div`
  position: relative;
`;

export const PageTitleWrapper = styled.div`
  ${({ theme }) => css`
    max-width: calc(100% - 270px);

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      max-width: 100%;
    }
  `};
`;

export const TitanMessage = styled.aside`
  position: fixed;
  right: 16px;
  top: 120px;
  z-index: 9999;
`;

export const SearchNoResultMessage = styled.span`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey.primary};
    color:${theme.color.greyDark};
    display: block;
    font-family: ${theme.font.family.secondary};
    font-size:${theme.font.sizes.md}
    font-weight: ${theme.font.weights.regular};
    padding: 67px 0px;
    text-align:center;
  `}
`;

export const FloatingSurveyContainer = styled.aside`
  ${({ theme }) => css`
    position:fixed;
    right: 0px;
    z-index: ${theme.v2.layers.overlay};
  `}
`;

export const SkeletonContainer = styled.div`
  ${({ theme, multipleSkelletons }) => css`
    background-color: ${theme.color.white};
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: ${multipleSkelletons ? '8px' : '0px'};
    margin-bottom: 6px;
    padding: ${multipleSkelletons ? '24px' : '0px 24px 5px'};

    last-child() {
      border: 0px;
      border-bottom: 0px;
    }
  `}
`;

export const SkeletonCard = styled.div`
    display: flex;
    padding: 32px 0px 0px;
    flex-direction: column;
`;

export const FirstSkeletonRow = styled.section`
  ${({ theme, marginTop, marginBottom }) => css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: ${marginTop ? '16px' : '0px'};
    margin-bottom: ${marginBottom ? '32px' : '0px'};

    & div:nth-child(2) {
      margin-left: auto;
      @media(max-width: ${theme.v2.breakpoints.sm}) {
        display: none;
      }
    }

    & div:nth-child(3) {
      @media(max-width: ${theme.v2.breakpoints.sm}) {
        margin-left: auto;
      }
    }
  `}
`;

export const LastSkeletonRow = styled.section`
  ${({ theme }) => css`
    display: none;
    flex-direction: row;
    gap: 8px;
    margin: 16px 0px 24px;
    max-width: 72px;

    @media(max-width: ${theme.v2.breakpoints.sm}) {
      display: flex;
    }
  `}
`;

export const SkeletonRow = styled.section`
  ${({ theme, marginTop, marginBottom }) => css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: ${marginTop ? '16px' : '0px'};
    margin-bottom: ${marginBottom ? '32px' : '0px'};

    & div:nth-child(2) {
      margin-left: auto;
    }

    @media(max-width: ${theme.v2.breakpoints.sm}) {
      margin-bottom: 0px;
    }
  `}
`;

export const LinkNaBioGuide = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
`;

export const GuideTexts = styled.div`
  max-width: 140px;
`;

export const Image = styled.img`
    max-width: 56px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`;

export const GuideTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;
  `};
`;

export const GuideDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;

const boxShadowGrowth = ({ theme }) => keyframes`
  0% {
    top: 53%;
    left: 56%;
    width: 0vh;
    height: 0vh;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 0vw ${theme.v2.colors.brand.primaryExtraDark}99;

    }
    100% {
      top: 0%;
      left: 0%;
      width: 98vw;
      height: 98vh;
      border-radius: 0%;
      box-shadow: 0px 0px 0px 120vw ${theme.v2.colors.brand.primaryExtraDark}99;
  }
`;

export const GuideShadow = styled.div`
  ${({ theme }) => css`
    animation: ${boxShadowGrowth} 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: absolute;
    background-color: ${theme.v2.colors.brand.primaryExtraDark}99;
  `};
`;
