import {
  Accordion, Alert, Button, Dropdown, IconLoading, IconSites, Link, Skeleton, Tag,
} from 'gatorcomponents';
import Cookies from 'js-cookie';
import React, { useCallback, useState } from 'react';
import * as Styles from './CardGroup.styles';
import { useMountCardStatus } from '@/hooks/sites/sitesList/useMountCardStatus';
import { STATUS_ALERTS_VARIANTS, STATUS_TYPE } from '@/enums/sites/status.enum';
import SiteAlert from '../../../SiteAlert';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { useResize } from '@/hooks/useResize';
import withErrorBoundary from '@/utils/errorBoundary';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BRAVO_SITES_ITEM_CHANGE_SUBSTATUS_TO_STATUS, CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS } from '@/config/GrowthBook/constants';

const CardGroup = ({
  group,
  propagationStatus,
  actions,
  getDropdownActions,
  getMainActions,
  loadMoreLabel,
  handlePayInvoice,
  closerPayment,
  loading,
  siteTagLabels,
  propagationAlertLabels,
  productsList,
  hostCount,
  handleEditSite,
  loadingRedirect,
  editSiteCta,
}) => {
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);
  const substatusToStatus = useFeatureIsOn(BRAVO_SITES_ITEM_CHANGE_SUBSTATUS_TO_STATUS);
  const statusOnCookie = withGatorSolutions ? Cookies.get(`${group[0].hosting_id}-accordion-status`) : Cookies.get(`${group[0].external_id}-accordion-status`);
  const accordionStatus = statusOnCookie ? statusOnCookie === 'true' : true;
  const [open, setOpen] = useState(accordionStatus);
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const { getSiteStatus } = useMountCardStatus({ substatusToStatus, justPlan: true, withGatorSolutions });
  const mainSite = group[0];
  const planStatus = getSiteStatus(mainSite);
  const windowRect = useResize();
  const mobile = windowRect.width < 600;

  const external = withGatorSolutions ? group[0].hosting_id : group[0].external_id;
  const product = productsList.find(productItem => productItem.id === external);

  const shouldRenderLoadMore = useCallback(() => {
    const shoudRender = mainSite && mainSite.cpanel_domains && mainSite.cpanel_domains.length > (page * itemsPerPage);
    return shoudRender;
  }, [page, mainSite]);

  const handleClickEditSite = useCallback(({ hostingId, domain }) => {
    handleEditSite && handleEditSite({
      hostingId,
      domain,
    });
  }, [handleEditSite]);

  const mountAccordionItems = useCallback((group) => {
    const tagRules = {
      is_main_domain: <Tag label={siteTagLabels.is_main_domain} variant="info" />,
      site_offline: <Tag label={siteTagLabels.site_offline} variant="problem" />,
    };

    return (
      <Styles.ListWrapper data-testid={`listwrapper${withGatorSolutions
        ? group[0].hosting_id
        : group[0].external_id}`}
      >
        {group.map((item) => {
          if (withGatorSolutions) {
            return item.cpanel_domains && item.cpanel_domains.slice(0, (itemsPerPage * page)).map((cpanelItem) => {
              const isMain = cpanelItem.domain === item.domain && cpanelItem.type === 'main';

              const dropdownActions = getDropdownActions({ siteItem: item, grouped: true });
              const mainActions = getMainActions({ siteItem: item, accordionItem: true });
              const propagationItem = propagationStatus.find(domainItem => domainItem.domain === item.site);
              const validStatus = [PROPAGATION_STATUS.IN_PROGRESS, PROPAGATION_STATUS.NOT_CONTRACTED];
              const isValidPropagationStatus = propagationItem && validStatus.includes(propagationItem.status);
              const siteIsOffline = ['cancelled', 'terminated', 'suspended'].includes(item.status.toLowerCase());

              const mountPropagationAlert = () => {
                if (!propagationItem) {
                  return;
                }

                const alertRules = {
                  [PROPAGATION_STATUS.IN_PROGRESS]: <Alert
                    variant="timed"
                    description={propagationAlertLabels.in_progress}
                    withoutBackground
                    centerIcon={!mobile}
                  />,
                  [PROPAGATION_STATUS.NOT_CONTRACTED]: <Alert
                    variant="warning"
                    description={propagationAlertLabels.not_contracted}
                    customIcon="IconDanger"
                    withoutBackground
                    centerIcon={!mobile}
                  />,
                };

                if (propagationItem && isValidPropagationStatus) {
                  return alertRules[propagationItem.status];
                }
              };

              return (
                <Styles.CardItem key={item.id}>
                  <IconSites />

                  <Link
                    text={cpanelItem.domain}
                    href={`https://${cpanelItem.domain}`}
                  />

                  <Styles.TagsWrapper noDisplay={!isMain && !siteIsOffline}>
                    {isMain && tagRules.is_main_domain}
                    {siteIsOffline && tagRules.site_offline}
                  </Styles.TagsWrapper>

                  <Styles.MainActionsWrapper noMargin={isValidPropagationStatus} noDisplay={!mainActions.length}>
                    <Button
                      key={`${item}_${cpanelItem.domain}_edit`}
                      label={loadingRedirect === cpanelItem.domain ? <IconLoading /> : editSiteCta}
                      variant="secondary"
                      onClick={() => handleClickEditSite({ hostingId: group[0].hosting_id, domain: cpanelItem.domain })}
                      testId={`${item}_${cpanelItem.domain}_edit`}
                      size="small"
                      disabled={[STATUS_TYPE.ANALYSIS, STATUS_TYPE.PENDING].includes(planStatus) || loadingRedirect === cpanelItem.domain}
                    />
                  </Styles.MainActionsWrapper>

                  {dropdownActions.list.length > 0 && (
                    <Styles.DropdownButtonWrapper>
                      <Dropdown
                        items={dropdownActions.list}
                      />
                    </Styles.DropdownButtonWrapper>
                  )}

                  <Styles.ItemAlert noDisplay={!isValidPropagationStatus}>
                    {mountPropagationAlert()}
                  </Styles.ItemAlert>
                </Styles.CardItem>
              );
            });
          }

          const dropdownActions = getDropdownActions({ siteItem: item, grouped: true });
          const mainActions = getMainActions({ siteItem: item, accordionItem: true });
          const propagationItem = propagationStatus.find(domainItem => domainItem.domain === item.site);
          const validStatus = [PROPAGATION_STATUS.IN_PROGRESS, PROPAGATION_STATUS.NOT_CONTRACTED];
          const isValidPropagationStatus = propagationItem && validStatus.includes(propagationItem.status);
          const siteIsOffline = ['cancelled', 'terminated', 'suspended'].includes(item.status_plan);

          const mountPropagationAlert = () => {
            const alertRules = {
              [PROPAGATION_STATUS.IN_PROGRESS]: <Alert
                variant="timed"
                description={propagationAlertLabels.in_progress}
                withoutBackground
                centerIcon={!mobile}
              />,
              [PROPAGATION_STATUS.NOT_CONTRACTED]: <Alert
                variant="warning"
                description={propagationAlertLabels.not_contracted}
                customIcon="IconDanger"
                withoutBackground
                centerIcon={!mobile}
              />,
            };

            if (!propagationItem) {
              return (
                <Skeleton variant="rectangle" width="100px" height="10px" />
              );
            }

            if (propagationItem && isValidPropagationStatus) {
              return alertRules[propagationItem.status];
            }
          };

          return (
            <Styles.CardItem key={item.id}>
              <IconSites />

              <Link
                text={item.site}
                href={`https://${item.site}`}
              />

              <Styles.TagsWrapper noDisplay={(!item.is_main_domain) && !siteIsOffline}>
                {item.is_main_domain && tagRules.is_main_domain}
                {siteIsOffline && tagRules.site_offline}
              </Styles.TagsWrapper>

              <Styles.MainActionsWrapper noMargin={isValidPropagationStatus} noDisplay={!mainActions.length}>
                {mainActions.map(action => (
                  <Button
                    key={`${item}_${action.testId}`}
                    label={action.label}
                    variant="secondary"
                    onClick={action.onClick}
                    testId={action.testId}
                    size="small"
                    disabled={[STATUS_TYPE.STATUS_TYPE, STATUS_TYPE.PENDING].includes(planStatus)}
                  />
                ))}
              </Styles.MainActionsWrapper>

              {dropdownActions.list.length > 0 && (
                <Styles.DropdownButtonWrapper>
                  <Dropdown
                    items={dropdownActions.list}
                  />
                </Styles.DropdownButtonWrapper>
              )}

              <Styles.ItemAlert noDisplay={!isValidPropagationStatus}>
                {mountPropagationAlert()}
              </Styles.ItemAlert>
            </Styles.CardItem>
          );
        })}

        {shouldRenderLoadMore() && (
          <Styles.ButtonWrapper>
            <Button
              variant="secondary"
              onClick={() => setPage(page + 1)}
              testId="loadMore"
              label={loadMoreLabel}
            />
          </Styles.ButtonWrapper>
        )}
      </Styles.ListWrapper>
    );
  }, [
    page,
    shouldRenderLoadMore,
    getDropdownActions,
    getMainActions,
    loadMoreLabel,
    mobile,
    propagationAlertLabels,
    propagationStatus,
    siteTagLabels,
    planStatus,
    withGatorSolutions,
    handleClickEditSite,
    editSiteCta,
    loadingRedirect,
  ]);

  const handleToggle = (group) => {
    Cookies.set(`${withGatorSolutions ? group[0].hosting_id : group[0].external_id}-accordion-status`, !open);
    setOpen(!open);
  };

  const financialAlertProps = () => {
    const alertVariant = STATUS_ALERTS_VARIANTS.financial[planStatus];

    const defaultProps = {
      status: planStatus,
      type: 'financial',
      alertVariant,
      handleClick: handlePayInvoice,
      daysForPayment: closerPayment && closerPayment(planStatus, mainSite),
      newSitesAlert: true,
      groupMainDomain: withGatorSolutions && group[0].domain,
      invoiceId: withGatorSolutions && group[0].invoice.id,
    };

    return defaultProps;
  };

  const planNotification = () => {
    if (withGatorSolutions) {
      if (group[0].sub_status !== STATUS_TYPE.ACTIVE) {
        return (
          <Styles.AlertWrapper>
            <SiteAlert {...financialAlertProps()} />
          </Styles.AlertWrapper>
        );
      }
    }

    if (!withGatorSolutions) {
      if (planStatus && planStatus !== STATUS_TYPE.ACTIVE) {
        return (
          <Styles.AlertWrapper>
            <SiteAlert {...financialAlertProps()} />
          </Styles.AlertWrapper>
        );
      }
    }
  };

  const shouldrender = withGatorSolutions
    ? group[0].product_name
    : product && product.productname;

  return (
    shouldrender && (
      <Accordion
        key={`dropdown${withGatorSolutions ? group[0].hosting_id : group[0].external_id}`}
        testId={`dropdown${withGatorSolutions ? group[0].hosting_id : group[0].external_id}`}
        items={() => mountAccordionItems(group)}
        width="full"
        title={`${withGatorSolutions ? group[0].product_name : product && product.productname}`}
        justifyContent="flex-start"
        subtitle={hostCount({ group })}
        actions={actions(group, planStatus)}
        open={planStatus === STATUS_TYPE.SUSPENDED ? false : open}
        onToggleAccordion={() => handleToggle(group)}
        leftIcon="IconFile"
        notification={planNotification()}
        loading={loading}
        allowToggle={planStatus !== STATUS_TYPE.SUSPENDED}
        actionsBreakpoint={1080}
      />
    )
  );
};

export default withErrorBoundary(CardGroup, 'CardGroup');
