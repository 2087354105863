import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from '@/pages/common/Modal';
import { IconError } from '@/icons';
import * as Styles from './HostGatorOtherPlatformModal.styles';
import { DnsWizardCustomServerFormField } from '@/components/domain/components/DnsWizardCustomServerFormField';
import { color } from '@/styles/HgThemeProvider';
import { Button } from '@/pages/common/v1/Button';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const HostGatorOtherPlatformModal = ({
  testId = 'hostgatorotherplatformmodal',
  hostGatorOtherPlatformModal = false,
  handleClose = () => {},
  handleConfigureCustomDns = () => {},
  title = 'Title',
  subtitle = 'subTitle',
  needHelp = 'needHelp',
  alertMessageText = 'alertMessageText',
  serverWithNumberText = () => {},
  nsWithNumberText = () => {},
  ipWithNumberText = () => {},
  ipPlaceholderText = 'digite endereço IP',
  addServerText = 'Adicionar mais um servidor',
  buttonText = 'Configurar',
  domain = 'domain.com',
  errorsLocale,
}) => {
  const { mustard } = color;

  const baseDns = {
    ns: '',
    ip: '',
    valid: false,
    required: false,
  };

  const [master, setMaster] = useState({ ...baseDns, required: true });
  const [slave1, setSlave1] = useState({ ...baseDns, required: true });
  const [slave2, setSlave2] = useState({ ...baseDns, required: false });
  const [slave3, setSlave3] = useState({ ...baseDns, required: false });
  const [slave4, setSlave4] = useState({ ...baseDns, required: false });

  const customConfiguration = useMemo(() => [
    { name: 'master', dns: master, setDns: setMaster },
    { name: 'slave1', dns: slave1, setDns: setSlave1 },
    { name: 'slave2', dns: slave2, setDns: setSlave2 },
    { name: 'slave3', dns: slave3, setDns: setSlave3 },
    { name: 'slave4', dns: slave4, setDns: setSlave4 },
  ], [master, slave1, slave2, slave3, slave4, setMaster, setSlave1, setSlave2, setSlave3, setSlave4]);

  const [numberOfInputs, setNumberOfInputs] = useState(2);
  const [readyForNextStep, setReadyForNextStep] = useState(false);
  const [inputs, setInputs] = useState([customConfiguration[0], customConfiguration[1]]);

  const handleAddInputs = () => {
    if (numberOfInputs < 5) {
      setReadyForNextStep(false);
      setNumberOfInputs(numberOfInputs + 1);
    }
  };

  const resetAndClose = () => {
    setNumberOfInputs(2);
    setMaster({ ...baseDns, required: true });
    setSlave1({ ...baseDns, required: true });
    setSlave2({ ...baseDns, required: false });
    setSlave3({ ...baseDns, required: false });
    setSlave4({ ...baseDns, required: false });
    setTimeout(() => {
      setInputs([customConfiguration[0], customConfiguration[1]]);
    }, 0);
    handleClose();
  };

  const formatCustomDns = (dns) => {
    const payload = {};
    dns.forEach(({ name, dns: hostInfo }) => {
      const { ns: host, ip } = hostInfo;
      payload[name] = { name, host, ip };
    });
    return payload;
  };

  const handleSubmit = () => {
    const payload = formatCustomDns(customConfiguration);
    handleConfigureCustomDns(HOSTING_PLATFORM_TYPES.CUSTOM, payload);
    resetAndClose();
  };

  const handleDeleteByIndex = (index) => {
    for (let i = index; i < customConfiguration.length; i += 1) {
      if (i < customConfiguration.length - 1) {
        const { dns: currDns } = customConfiguration[i];
        const { dns: nextDns } = customConfiguration[i + 1];
        customConfiguration[i].setDns({ ...nextDns, required: currDns.required });
        customConfiguration[i + 1].setDns(baseDns);
      }
    }
    if (numberOfInputs > 2) {
      setNumberOfInputs(numberOfInputs - 1);
    }
  };

  const shouldShowAddButton = numberOfInputs < 5;

  useEffect(() => {
    const tempInputs = [];
    for (let index = 0; index < numberOfInputs; index += 1) {
      tempInputs.push(customConfiguration[index]);
    }
    setInputs(tempInputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfInputs, customConfiguration]);

  useEffect(() => {
    let isValid = true;

    for (let index = 0; index < numberOfInputs; index += 1) {
      const { ns, ip } = customConfiguration[index].dns;

      if (ns === '') {
        isValid = false;
        break;
      }

      const re = new RegExp(`.*\\.${domain}$`, 'i');
      const sameDomain = !!ns.match(re);

      if (sameDomain && ip === '') {
        isValid = false;
        break;
      }
      isValid = isValid && customConfiguration[index].dns.valid;
    }
    setReadyForNextStep(isValid);
  }, [
    numberOfInputs,
    customConfiguration,
    domain,
  ]);

  useEffect(() => {
    setNumberOfInputs(2);
  }, [hostGatorOtherPlatformModal]);

  return (
    <>
      {hostGatorOtherPlatformModal
      && (
        <Modal onClose={resetAndClose} maxWidth={780}>
          <Styles.HostGatorOtherPlatformModalWrapper data-testid={testId}>
            <Styles.TitleWrapper data-testid="hostgatorotherplatformmodal-title">
              <Styles.Title>{title}</Styles.Title>
              <Styles.SubTitle data-testid="hostgatorotherplatformmodal-subtitle">
                {subtitle}
              </Styles.SubTitle>
            </Styles.TitleWrapper>

            <Styles.HelpWrapper>
              {needHelp}
            </Styles.HelpWrapper>

            <Styles.NameServerInputs>
              { Object.keys(inputs).map((dnsItem) => {
                const index = parseInt(dnsItem, 10);
                const serverNumber = index + 1;
                return (
                  <DnsWizardCustomServerFormField
                    key={`custom-server-input-${serverNumber}`}
                    nsLabelText={serverWithNumberText({ serverNumber, required: inputs[dnsItem].dns.required })}
                    nsPlaceholderText={nsWithNumberText(serverNumber)}
                    ipLabelText={ipWithNumberText(serverNumber)}
                    ipPlaceholderText={ipPlaceholderText}
                    domain={domain}
                    dns={inputs[dnsItem].dns}
                    setDns={inputs[dnsItem].setDns}
                    index={index}
                    handleDeleteByIndex={handleDeleteByIndex}
                    getMessage={errorsLocale.getMessage}
                    focused={index === 0}
                  />
                );
              })}
            </Styles.NameServerInputs>
            {shouldShowAddButton
            && (
            <Styles.ButtonWrapper position="left">
              <Button label={addServerText} variant="secondary" size="small" onClick={handleAddInputs} />
            </Styles.ButtonWrapper>
            )
            }
            <Styles.MessageWrapper data-testid="hostgatorotherplatformmodal-message">
              <IconError size="24" color={mustard} />
              {alertMessageText}
            </Styles.MessageWrapper>
            <Styles.ButtonWrapper>
              <Button label={buttonText} disabled={!readyForNextStep} onClick={handleSubmit} />
            </Styles.ButtonWrapper>
          </Styles.HostGatorOtherPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorOtherPlatformModal;
