import React, { useState } from 'react';
import * as Styles from './CopyPasteButton.styles';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import { IconCopyContent } from '@/icons';

const CopyPasteButton = ({
  id = '',
  disabled = false,
  copyLabel = 'Copy',
  copiedLabel = 'It is copied',
  onClick,
  clipBoardText = '',
  testId = 'button',
  type = 'button',
  variant = 'text',
  size = 'regular',
  display = 'inline',
  title = null,
  timeOut = null,
  icon = <IconCopyContent />,
  contentButtonId = null,
  customStyles = {
    color: null,
    backColor: null,
    borderStyle: null,
    hoverBackColor: null,
    hoverBorderColor: null,
    hoverColor: null,
    disabledColor: null,
    disabledBackColor: null,
    padding: null,
  },
}) => {
  const [isCopied, setCopied] = useState(false);

  const handleOnClick = (event) => {
    if (!isCopied) {
      setCopied(!isCopied);
      copyTextToClipboard(clipBoardText, contentButtonId);
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setCopied(false);
      }, 1000 * timeOut);
    }
    onClick && onClick(event);
  };

  const getLabelButton = () => (isCopied ? copiedLabel : copyLabel);

  return (
    <>
      {icon}
      <Styles.Button
        id={id}
        data-testid={testId}
        disabled={disabled}
        display={display}
        onClick={handleOnClick}
        size={size}
        title={title}
        type={type}
        variant={variant}
        customStyles={customStyles}
      >
        <Styles.Label data-testid={`${testId}-label`}>{getLabelButton()}</Styles.Label>
      </Styles.Button>
    </>
  );
};

export default CopyPasteButton;
