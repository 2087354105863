import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
} from '@/pages/common';
import {
  IconDanger, IconCheck,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { sitesAnalytics } from '@/analytics';
import { SSL_GA } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';
import { useDispatch, useSelector } from 'react-redux';
import { AUTO_SSL_STATUS } from '@/enums/sites/status.enum';
import { preChatActions, sitesActions } from '@/redux/modules';
import { ModalAutoSslTypes } from './ModalAutoSSL.types';
import { ModalContent } from './components/ModalContent';
import { CART_URL } from '@/config';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';

const ModalAutoSSL = ({
  handleCloseModal,
  domainToConfigureSSL,
  initialStep = ModalAutoSslTypes.steps.SSL_AUTO_SSL_ACTIVATION,
}) => {
  const { steps } = ModalAutoSslTypes;
  const [step, setStep] = useState(initialStep);
  const isLoading = step === steps.VERIFYING_PENDENCIES || step === steps.SSL_AUTO_SSL_ACTIVATION;
  const { sites: sitesLocale } = useLocale();
  const { ssl, sslVerification, sslConfigureDomain } = useSelector(state => state.sites);
  const dispatch = useDispatch();
  const { requestDiagnostic } = useDiagnostic();

  const closeModal = useCallback(() => {
    handleCloseModal && handleCloseModal();
    setStep(steps.SSL_AUTO_SSL_ACTIVATION);
    dispatch(sitesActions.ssl.clearStatus());
  }, [setStep, handleCloseModal, dispatch, steps]);

  const verifySSLProgress = useCallback(() => {
    dispatch(sitesActions.ssl.progress.request({ ...domainToConfigureSSL }));
  }, [
    dispatch,
    domainToConfigureSSL,
  ]);

  const callInitialRequest = useCallback(() => {
    if (initialStep === steps.SSL_AUTO_SSL_ACTIVATION) {
      dispatch(sitesActions.ssl.start.request(domainToConfigureSSL));
    }
    if (initialStep === steps.VERIFYING_PENDENCIES) {
      dispatch(sitesActions.ssl.verification.request(domainToConfigureSSL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, domainToConfigureSSL]);

  const verifyDomainStatus = useCallback(() => {
    if (sslVerification.loading) {
      setStep(steps.VERIFYING_PENDENCIES);
      return;
    }

    switch (sslVerification.status) {
      case AUTO_SSL_STATUS.EXTERNAL_NS:
        setStep(steps.EXTERNAL_NOT_POINTED);
        break;
      case AUTO_SSL_STATUS.CAN_CHANGE_A_RECORD:
      case AUTO_SSL_STATUS.REGISTERED:
        setStep(steps.INTERNAL_NOT_POINTED);
        break;
      case AUTO_SSL_STATUS.CAN_INSTALL_SSL:
        setStep(steps.SSL_AUTO_SSL_ERROR);
        break;
      case AUTO_SSL_STATUS.NOT_REGISTERED:
        setStep(steps.DOMAIN_NOT_REGISTERED);
        break;
      default:
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sslVerification.loading]);

  const checkDomainConfiguration = useCallback(() => {
    if (sslConfigureDomain.status === 'success') {
      setStep(steps.INTERNAL_KEEP_IN_HG_SUCCESS);
    }
    if (sslConfigureDomain.status === 'error') {
      setStep(steps.INTERNAL_KEEP_IN_HG_FAIL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sslConfigureDomain.status]);

  const checkAutoSslProgress = useCallback(() => {
    const rules = {
      [AUTO_SSL_STATUS.START_SUCCESS]: () => setTimeout(verifySSLProgress, 60000),
      [AUTO_SSL_STATUS.START_FAILURE]: () => setStep(steps.SSL_AUTO_SSL_ERROR),
      [AUTO_SSL_STATUS.PROGRESS_FAILURE]: () => setStep(steps.SSL_AUTO_SSL_ERROR),
      [AUTO_SSL_STATUS.CHECK_SUCCESS]: () => setStep(steps.SSL_ACTIVATION_SUCCESS),
      [AUTO_SSL_STATUS.CHECK_FAILURE]: () => setStep(steps.SSL_ACTIVATION_ERROR),
    };

    rules[ssl.status] && rules[ssl.status]();
  }, [steps, verifySSLProgress, ssl.status]);

  const dispatchViewTags = useCallback(() => {
    if (step === steps.DOMAIN_NOT_REGISTERED) {
      sitesAnalytics.ssl.sslModal({ view: SSL_GA.REGISTER_DOMAIN.toLowerCase() });
    }

    if (step === steps.SSL_AUTO_SSL_ERROR) {
      sitesAnalytics.ssl.sslModal({ view: SSL_GA.ACTIVATION_ERROR.toLowerCase() });
    }

    if (step === steps.EXTERNAL_NOT_POINTED) {
      sitesAnalytics.ssl.sslModal({ view: SSL_GA.MODAL_TO_OTHER.toLowerCase() });
    }
  }, [step, steps]);

  useEffect(() => {
    if (ssl.callLoop > 0) {
      setTimeout(verifySSLProgress, 60000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssl.callLoop]);

  useEffect(() => {
    callInitialRequest();
  }, [callInitialRequest]);

  useEffect(() => {
    if (initialStep === steps.VERIFYING_PENDENCIES) {
      verifyDomainStatus();
    }
  }, [verifyDomainStatus, initialStep, steps.VERIFYING_PENDENCIES, sslVerification.status]);

  useEffect(() => {
    checkDomainConfiguration();
  }, [checkDomainConfiguration]);

  useEffect(() => {
    checkAutoSslProgress();
  }, [checkAutoSslProgress]);

  useEffect(() => {
    dispatchViewTags();
  }, [dispatchViewTags]);

  const getModalContentProps = useCallback(() => {
    const startZendeskChat = () => {
      closeModal();
      dispatch(preChatActions.preChat.handleChatDisplay());
    };

    const retryStartAutoSSL = () => {
      dispatch(sitesActions.ssl.clearStatus());
      dispatch(sitesActions.ssl.start.request(domainToConfigureSSL));
      setStep(steps.SSL_AUTO_SSL_ACTIVATION);
    };

    const handlePointDomainToHg = () => {
      dispatch(sitesActions.ssl.pointDomain.request({ domain: domainToConfigureSSL.domain }));
      setStep(steps.INTERNAL_KEEP_IN_HG_LOADING);
    };

    const handleHowToConfigure = () => {
      sitesAnalytics.ssl.sslModal({ click: SSL_GA.MODAL_TO_OTHER });
      window.open(sitesLocale.sslModal.externalDomainNotPointed.materialUrl);
    };

    const handleRegisterDomain = () => {
      const splitedDomain = domainToConfigureSSL.domain.split('.');
      window.open(`${CART_URL}?pid=d&sld=${splitedDomain[0]}&tld=.${splitedDomain[1]}${splitedDomain[2] ? `.${splitedDomain[2]}` : ''}`);
      sitesAnalytics.ssl.sslModal({ click: SSL_GA.REGISTER_DOMAIN.toLocaleLowerCase() });
    };

    const handleActivationSuccess = () => {
      closeModal();
      requestDiagnostic('modalAutoSSl');
    };

    const rules = {
      [steps.SSL_AUTO_SSL_ACTIVATION]: {
        loading: true,
        description: sitesLocale.sslModal.loading,
      },
      [steps.VERIFYING_PENDENCIES]: {
        loading: true,
        description: sitesLocale.sslModal.verifyPendencyLoading,
      },
      [steps.INTERNAL_KEEP_IN_HG_LOADING]: {
        loading: true,
        description: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.pointingDnsLoading,
      },
      [steps.SSL_AUTO_SSL_ERROR]: {
        title: sitesLocale.sslModal.autoSSL.error.title,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.autoSSL.error.description(domainToConfigureSSL && domainToConfigureSSL.domain),
        actions: [
          {
            label: sitesLocale.sslModal.autoSSL.error.button,
            size: 'large',
            onClick: () => {
              sitesAnalytics.ssl.sslModal({ click: SSL_GA.ACTIVATION_ERROR });
              retryStartAutoSSL();
            },
          },
        ],
      },
      [steps.SSL_ACTIVATION_SUCCESS]: {
        title: sitesLocale.sslModal.autoSSL.active.title,
        description: sitesLocale.sslModal.autoSSL.active.description(domainToConfigureSSL && domainToConfigureSSL.domain),
        icon: <IconCheck size="lg" color="activeLight" />,
        actions: [
          {
            label: sitesLocale.sslModal.autoSSL.active.button,
            size: 'large',
            onClick: handleActivationSuccess,
          },
        ],
      },
      [steps.SSL_ACTIVATION_ERROR]: {
        title: sitesLocale.sslModal.autoSSL.activation_error.title,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.autoSSL.activation_error.description(domainToConfigureSSL && domainToConfigureSSL.domain),
        actions: [
          {
            label: sitesLocale.sslModal.autoSSL.activation_error.cancel,
            variant: 'tertiary',
            onClick: closeModal,
          },
          {
            label: sitesLocale.sslModal.autoSSL.activation_error.button,
            size: 'large',
            onClick: startZendeskChat,
          },
        ],
      },
      [steps.DOMAIN_NOT_REGISTERED]: {
        title: sitesLocale.sslModal.domainNotRegistered.domainNotRegisteredTitle,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.domainNotRegistered.domainNotRegisteredDescription({ domain: domainToConfigureSSL.domain }),
        actions: [
          {
            label: sitesLocale.sslModal.backButtonLabel,
            variant: 'tertiary',
            onClick: closeModal,
            size: 'large',
          },
          {
            label: sitesLocale.sslModal.domainNotRegistered.registerDomainButtonLabel,
            onClick: () => {
              handleRegisterDomain();
              closeModal();
            },
            size: 'large',

          },
        ],
      },
      [steps.EXTERNAL_NOT_POINTED]: {
        title: sitesLocale.sslModal.externalDomainNotPointed.externalDomainNotPointedTitle,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.externalDomainNotPointed.externalDomainNotPointedDescription({ domain: domainToConfigureSSL.domain }),
        actions: [{
          label: sitesLocale.sslModal.externalDomainNotPointed.howToConfigure,
          onClick: handleHowToConfigure,
          size: 'large',
        }],
      },
      [steps.INTERNAL_NOT_POINTED]: {
        title: sitesLocale.sslModal.internalDomainNotPointed.internalDomainNotPointedTitle,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.internalDomainNotPointed.internalDomainNotPointedDescription({ domain: domainToConfigureSSL.domain }),
        actions: [
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInOtherHostButtonLabel,
            variant: 'tertiary',
            onClick: () => setStep(steps.INTERNAL_KEEP_IN_OTHER_HOST),
            size: 'large',
          },
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgatorButtonLabel,
            onClick: () => setStep(steps.INTERNAL_KEEP_IN_HG),
            size: 'large',
          },
        ],
      },
      [steps.INTERNAL_KEEP_IN_OTHER_HOST]: {
        title: sitesLocale.sslModal.internalDomainNotPointed.keepInOtherHost.keepInOtherHostTitle,
        description: sitesLocale.sslModal.internalDomainNotPointed.keepInOtherHost.keepInOtherHostDescription({ domain: domainToConfigureSSL.domain }),
        actions: [
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInOtherHost.understoodButtonLabel,
            onClick: closeModal,
            size: 'large',
          },
        ],
      },
      [steps.INTERNAL_KEEP_IN_HG]: {
        title: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHostgatorTitle,
        description: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHostgatorDescription({ domain: domainToConfigureSSL.domain }),
        actions: [
          {
            label: sitesLocale.sslModal.backButtonLabel,
            variant: 'tertiary',
            onClick: () => setStep(steps.INTERNAL_NOT_POINTED),
            size: 'large',
          },
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.agreeButtonLabel,
            onClick: handlePointDomainToHg,
            size: 'large',
            width: 'fit',
          },
        ],
      },
      [steps.INTERNAL_KEEP_IN_HG_SUCCESS]: {
        title: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHgSuccessTitle,
        icon: <IconCheck size="lg" color="activePure" />,
        description: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHgSuccessDescription({ domain: domainToConfigureSSL.domain }),
        actions: [{
          label: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.closeButton,
          onClick: closeModal,
          size: 'large',
        }],
      },
      [steps.INTERNAL_KEEP_IN_HG_FAIL]: {
        title: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHgErrorTitle,
        icon: <IconDanger size="lg" color="problemLight" />,
        description: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.keepInHgErrorDescription({ domain: domainToConfigureSSL.domain }),
        actions: [
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.cancelButtonLabel,
            variant: 'tertiary',
            onClick: closeModal,
            size: 'large',
          },
          {
            label: sitesLocale.sslModal.internalDomainNotPointed.keepInHostgator.openChatButtonLabel,
            onClick: startZendeskChat,
            size: 'large',
          },
        ],
      },
    };

    return {
      ...rules[step],
    };
  }, [
    step,
    sitesLocale,
    steps,
    dispatch,
    domainToConfigureSSL,
    closeModal,
    requestDiagnostic,
  ]);

  return (
    <Modal
      testId="modal-configure-ssl"
      onClose={!isLoading && closeModal}
      maxWidth={655}
      maxHeight={500}
      disablePadding
    >
      <ModalContent
        {...getModalContentProps()}
      />
    </Modal>
  );
};

export default ModalAutoSSL;
