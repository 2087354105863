import React from 'react';
import * as Styles from './DomainItemMessage.styles';
import { GET_A_NEW_DOMAIN, GET_ANALYZER_LINK } from '@/config/urls/supportUrls';
import { isBRServer } from '@/utils/Validators/validation';
import {
  invoiceUrl,
  domainExpiredDefaultMessage,
  domainExpiredDefaultLinkMessage,
  domainExpiredOnGracePeriodMessage,
  domainExpiredOnGracePeriodLinkMessage,
  expiredGracePeriodOverdue,
  domainCancelledRecoverableMessage,
  domainCancelledRecoverableLinkMessage,
  domainCancelledUnRecoverableMessage,
  domainCancelledUnRecoverableLinkMessage,
  domainCancelledByTosAbuseMessage,
  domainCancelledByTosAbuseLinkMessage,
} from './DomainItemMessage.locale';
import { DOMAIN_STATUS } from '@/enums/domains/domains.enum';
import { Button } from '@/pages/common';

function DomainItemMessage({
  testId = 'domain-item-message',
  domain = {},
  variant = DOMAIN_STATUS.CANCELED_RECOVERABLE,
  handleOnClick = () => {},
}) {
  switch (variant) {
    case DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_ENABLED:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {domainExpiredDefaultMessage(domain)}
          <Styles.MessageLink href={invoiceUrl(domain.invoiceId)} target="_blank" rel="noreferrer" data-testid="link_invoice_due_date">
            {domainExpiredDefaultLinkMessage()}
          </Styles.MessageLink>
        </Styles.DomainItemMessageWrapper>
      );
    case DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_DISABLED:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {domainExpiredOnGracePeriodMessage(
            <Button
              size="small"
              variant="link"
              onClick={handleOnClick}
              label={domainExpiredOnGracePeriodLinkMessage()}
              data-testid="link_invoice_due_date"
            />,
          )}
        </Styles.DomainItemMessageWrapper>
      );
    case DOMAIN_STATUS.EXPIRED_GRACE_PERIOD_OVERDUE:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {expiredGracePeriodOverdue(domain)}
        </Styles.DomainItemMessageWrapper>
      );
    case DOMAIN_STATUS.CANCELED_BY_TOS_ABUSE:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {domainCancelledByTosAbuseMessage()}
          {isBRServer ? (
            <>
              <strong>
                {GET_ANALYZER_LINK}
              </strong>
              {domainCancelledByTosAbuseLinkMessage()}
            </>
          ) : (
            <Styles.MessageLink href={GET_ANALYZER_LINK} target="_blank" rel="noreferrer" data-testid="link_invoice_due_date">
              {domainCancelledByTosAbuseLinkMessage()}
            </Styles.MessageLink>
          )
          }
        </Styles.DomainItemMessageWrapper>
      );
    case DOMAIN_STATUS.CANCELED_UNRECOVERABLE:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {domainCancelledUnRecoverableMessage(domain)}
          <Styles.MessageLink href={GET_A_NEW_DOMAIN} target="_blank" rel="noreferrer" data-testid="link_new_domain">
            {domainCancelledUnRecoverableLinkMessage()}
          </Styles.MessageLink>
        </Styles.DomainItemMessageWrapper>
      );
    case DOMAIN_STATUS.CANCELED_RECOVERABLE:
      return (
        <Styles.DomainItemMessageWrapper data-testid={testId}>
          {domainCancelledRecoverableMessage(domain)}
          <Styles.MessageLink href={GET_A_NEW_DOMAIN} target="_blank" rel="noreferrer" data-testid="link_new_domain">
            {domainCancelledRecoverableLinkMessage()}
          </Styles.MessageLink>
        </Styles.DomainItemMessageWrapper>
      );
    default:
      return null;
  }
}

export default DomainItemMessage;
