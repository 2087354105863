import React, { useCallback, useState } from 'react';
import * as Styles from './AffiliateProgram.styles';
import {
  Button,
  IconData,
  IconLoading,
  Input,
  Radio,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';

export const AffiliateProgram = ({
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { affiliateProgram: affiliateProgramLocale } = ticketsLocale.forms;
  const [affiliate, setAffiliate] = useState(null);
  const [doubt, setDoubt] = useState('');

  const checkForm = useCallback(() => {
    let isOk = false;

    isOk = affiliate && doubt;

    return !isOk;
  }, [affiliate, doubt]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: undefined,
      product: undefined,
      subject: ticketsLocale.forms.affiliateProgram.subject,
      key: V2Forms.AFFILIATE_PROGRAM,
      tags: ['afiliados'],
      body: `
        ${affiliateProgramLocale.bodyProduct}
        ${affiliateProgramLocale.bodyRequest}
        ${affiliateProgramLocale.bodyIsAffiliate(affiliate)}
        ${affiliateProgramLocale.bodyDoubts(doubt)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    submitForm,
    affiliate,
    doubt,
    affiliateProgramLocale,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {affiliateProgramLocale.info}
      </Styles.Info>


      <Styles.InputsWrapper>
        <Styles.RadioOptionsWrapper>
          <Styles.RadioQuestionTitle>
            {affiliateProgramLocale.labelAffiliate}
          </Styles.RadioQuestionTitle>

          <Styles.RadioLabel
            htmlFor="#isAffiliateRadio"
            data-testid="isAffiliate"
            onClick={() => setAffiliate(affiliateProgramLocale.affiliateOptions.yes)}
          >
            <Radio checked={affiliate === affiliateProgramLocale.affiliateOptions.yes} id="isAffiliateRadio" />
            <Styles.Text>
              {affiliateProgramLocale.affiliateOptions.yes}
            </Styles.Text>
          </Styles.RadioLabel>

          <Styles.RadioLabel
            htmlFor="#isNotAffiliateRadio"
            data-testid="isNotAffiliate"
            onClick={() => setAffiliate(affiliateProgramLocale.affiliateOptions.no)}
          >
            <Radio checked={affiliate === affiliateProgramLocale.affiliateOptions.no} id="isNotAffiliateRadio" />
            <Styles.Text>
              {affiliateProgramLocale.affiliateOptions.no}
            </Styles.Text>
          </Styles.RadioLabel>
        </Styles.RadioOptionsWrapper>

        <Input
          type="textarea"
          testId="doubt"
          label={doubt ? affiliateProgramLocale.labelDoubts : ''}
          placeholder={affiliateProgramLocale.labelDoubts}
          value={doubt}
          onChange={e => setDoubt(e.target.value)}
          rows={3}
          counter
        />
      </Styles.InputsWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : affiliateProgramLocale.sendButtonLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default AffiliateProgram;
