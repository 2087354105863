import React, { useEffect, useState } from 'react';
import * as Styles from './WelcomeCheckListBanner.styles';
import { Button, IconChevron } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { WelcomeCheckListCard } from '../WelcomeCheckListCard';
import dnsStep from '@/media/newsite/icons-steps.svg';
import emailStep from '@/media/newsite/icon-email.svg';
import creatorStep from '@/media/newsite/icon-criador.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { VERSION_BANNER } from './WelcomCheckListBannerTypes';
import { sitesActions } from '@/redux/modules';
import { steps } from '../../SitesHandler/SitesHandler.enum';

const WelcomeCheckListBanner = ({
  testId = 'Welcome-banner',
  open = false,
  originTypeBanner = 0,
  hostingId = null,
  listCheckedsLoaded = [],
  onCloseBanner = null,
  welcomeChecklistBannerAnalytics = null,
}) => {
  const history = useHistory();
  const summary = useSelector(state => state.summary);
  const { sites: sitesLocale, routes: routesLocale } = useLocale();
  const [listCheckeds, setListCheckeds] = useState(listCheckedsLoaded);
  const [listViewed, setListViewed] = useState(listCheckedsLoaded);
  const [typeBanner, setTypeBanner] = useState(originTypeBanner);

  const [isOpen, setIsOpen] = useState(open);
  const dispatch = useDispatch();
  const isEnabledGA = welcomeChecklistBannerAnalytics;

  useEffect(() => {
    isEnabledGA && welcomeChecklistBannerAnalytics.showWelcomeCheckListBanner();
  }, [welcomeChecklistBannerAnalytics, isEnabledGA]);

  const handleOnToggleAccordion = (open) => {
    open && isEnabledGA ? welcomeChecklistBannerAnalytics.showChecklist() : welcomeChecklistBannerAnalytics.hideChecklist();

    setIsOpen(open);
  };

  const generateTitle = () => {
    switch (typeBanner) {
      case VERSION_BANNER.START:
        return sitesLocale.welcomeChecklistBanner.header.titleStart(summary.firstname);
      case VERSION_BANNER.EDITION:
        return sitesLocale.welcomeChecklistBanner.header.titleStart(summary.firstname);
      case VERSION_BANNER.COMPLETED:
        return sitesLocale.welcomeChecklistBanner.header.titleFinish;
      default:
        return '';
    }
  };

  const generateDescription = () => {
    switch (typeBanner) {
      case VERSION_BANNER.START:
        return sitesLocale.welcomeChecklistBanner.header.descriptions.start;
      case VERSION_BANNER.EDITION:
        return sitesLocale.welcomeChecklistBanner.header.descriptions.continue;
      case VERSION_BANNER.COMPLETED:
        return sitesLocale.welcomeChecklistBanner.header.descriptions.finish;
      default:
        return '';
    }
  };

  const createSite = () => {
    isEnabledGA && welcomeChecklistBannerAnalytics.clickCreateStore();
    history.push(`${routesLocale.sitesPage}${routesLocale.sitesCreate}`);
  };

  const createEmail = () => {
    isEnabledGA && welcomeChecklistBannerAnalytics.clickCreateEmail();
    history.push(`${routesLocale.emailsList}`);
  };

  const getTypeWelcomeChecklistTypeBanner = (listCheckeds) => {
    if (listCheckeds.length === 0) {
      return 0;
    }
    if (listCheckeds.length > 0 && listCheckeds.length < 3) {
      return 1;
    }
    return 2;
  };

  useEffect(() => {
    setTypeBanner(getTypeWelcomeChecklistTypeBanner(listCheckeds));

    listCheckeds.length > 2 && setIsOpen(false);
  }, [listCheckeds]);

  const executeGAInStep = (step) => {
    switch (step) {
      case 1:
        welcomeChecklistBannerAnalytics.checkStep1();
        break;
      case 2:
        welcomeChecklistBannerAnalytics.checkStep2();
        break;
      case 3:
        welcomeChecklistBannerAnalytics.checkStep3();
        break;
      default:
        break;
    }
  };

  const onCheckCard = (stepInt, isChecked) => {
    const step = `${stepInt}`;
    const data = {
      clientId: summary.id,
      hostingId,
      step,
      check: isChecked,
    };

    isChecked && isEnabledGA && executeGAInStep(stepInt);

    dispatch(sitesActions.upateStepWelcomeChecklist.request(data));

    if (step === steps.finishStep) {
      isEnabledGA && welcomeChecklistBannerAnalytics.noShowBanner();
      onCloseBanner && onCloseBanner(false);
    }

    if (!listCheckeds.includes(step) && step !== steps.finishStep) {
      setListCheckeds([...listCheckeds, step]);

      if (!listViewed.includes(step)) {
        setListViewed([...listViewed, step]);
      }
    }

    if (listCheckeds.includes(step) && !isChecked) {
      setListCheckeds(listCheckeds.filter(item => item !== step));
    }
  };

  const isChecked = (steps) => {
    let isChecked = false;
    steps.forEach((step) => {
      if (listCheckeds.includes(`${step}`) && !isChecked) {
        isChecked = true;
      }
    });

    return isChecked;
  };

  const isViewded = (steps) => {
    let isChecked = false;
    steps.forEach((step) => {
      if (listViewed.includes(`${step}`)) {
        isChecked = true;
      }
    });

    return isChecked;
  };

  const getWelcomeCheckListCardProps = () => {
    const props = [
      {
        testId: 'wclc-dns',
        descriptions: [sitesLocale.welcomeChecklistBanner.dnsCard.description1, sitesLocale.welcomeChecklistBanner.dnsCard.description2],
        links: [
          { dataLink: sitesLocale.welcomeChecklistBanner.dnsCard.link1, ga: 'clickVerifyDNS' },
          { dataLink: sitesLocale.welcomeChecklistBanner.dnsCard.link2, ga: 'clickConfigHostgatorDomain' },
          { dataLink: sitesLocale.welcomeChecklistBanner.dnsCard.link3, ga: 'clickConfigExternalDomain' },
        ],
        logo: dnsStep,
        step: 1,
        wasViewed: true,
        isChecked: isChecked([1]),
        enabledCheckButton: true,
        enabledUnCheckButton: !isChecked([2]),
        title: sitesLocale.welcomeChecklistBanner.dnsCard.title,
      },
      {
        testId: 'wclc-email',
        descriptions: [sitesLocale.welcomeChecklistBanner.emailCard.description1, sitesLocale.welcomeChecklistBanner.emailCard.description2],
        links: [
          { dataLink: sitesLocale.welcomeChecklistBanner.emailCard.link1, ga: 'clickEmailConfig' },
        ],
        logo: emailStep,
        button: (<Button testId="wclc-email-btn" variant="primary" label={sitesLocale.welcomeChecklistBanner.emailCard.button} onClick={() => createEmail()} />),
        step: 2,
        wasViewed: isViewded([1, 2]),
        isChecked: isChecked([2]),
        enabledCheckButton: isChecked([1]),
        enabledUnCheckButton: !isChecked([3]),
        title: sitesLocale.welcomeChecklistBanner.emailCard.title,
      },
      {
        testId: 'wclc-create-sites',
        descriptions: [sitesLocale.welcomeChecklistBanner.createSiteCard.description1, sitesLocale.welcomeChecklistBanner.createSiteCard.description2],
        links: [
          { dataLink: sitesLocale.welcomeChecklistBanner.createSiteCard.link1, ga: 'clickWordpress' },
          { dataLink: sitesLocale.welcomeChecklistBanner.createSiteCard.link2, ga: 'clickVerifySSL' },
          { dataLink: sitesLocale.welcomeChecklistBanner.createSiteCard.link3, ga: 'clickLinkLadingPage' },
        ],
        logo: creatorStep,
        button: (<Button testId="wclc-create-sites-btn" variant="primary" label={sitesLocale.welcomeChecklistBanner.createSiteCard.button} onClick={() => createSite()} />),
        step: 3,
        wasViewed: isViewded([2, 3]),
        isChecked: isChecked([3]),
        enabledCheckButton: isChecked([1]) && isChecked([2]),
        enabledUnCheckButton: true,
        title: sitesLocale.welcomeChecklistBanner.createSiteCard.title,
      },
    ];
    return props;
  };

  const generateWelcomeCheckListCard = () => getWelcomeCheckListCardProps().map(e => <WelcomeCheckListCard welcomeChecklistBannerAnalytics={welcomeChecklistBannerAnalytics} {...e} onClick={(step, isChecked) => { onCheckCard(step, isChecked); }} />);

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Header isOpen={isOpen}>
        <Styles.Container>
          <Styles.Title testId={`${testId}-title`}>{generateTitle()}</Styles.Title>
          <Styles.Description testId={`${testId}-wclc-description`}>{generateDescription()}</Styles.Description>
        </Styles.Container>
        <Styles.ContentButtons isOpen={isOpen}>
          {typeBanner === VERSION_BANNER.COMPLETED && (
            <Button
              testId={`${testId}-wclc-no-show`}
              variant="text"
              onClick={() => onCheckCard(steps.finishStep, true)}
              label={sitesLocale.welcomeChecklistBanner.header.dontShowThisBanner}
            />
          )}
          <Button
            testId={`${testId}-button`}
            variant={isOpen ? 'secondary' : 'primary'}
            iconRight={<IconChevron />}
            onClick={() => handleOnToggleAccordion(!isOpen)}
            label={isOpen ? sitesLocale.welcomeChecklistBanner.header.hideChecklist : sitesLocale.welcomeChecklistBanner.header.showChecklist}
          />
        </Styles.ContentButtons>
      </Styles.Header>

      <Styles.ContentCards isOpen={isOpen}>
        {generateWelcomeCheckListCard()}
      </Styles.ContentCards>
    </Styles.Wrapper>
  );
};

export default WelcomeCheckListBanner;
