import React from 'react';
import { locale } from '@/utils/locale';

export const billingLocale = {
  domainLabel: locale('billing.domainLabel'),
};

export const subscriptionLocale = {
  controlBackButtonLabel: locale('subscriptions.cancel.confirmation.controlBackButtonLabel'),
  controlFowardButtonLabel: locale('subscriptions.cancel.confirmation.controlFowardButtonLabel'),
  controlFowardButtonStepConfirmationCancelSignatureLabel: locale('subscriptions.cancel.confirmation.controlFowardButtonStepConfirmationCancelSignatureLabel'),
  tooltipCancelSignatureText: locale('subscriptions.cancel.confirmation.tooltipCancelSignatureText'),
  confirmationTitleCancelSignatureLabel: locale('subscriptions.cancel.confirmation.confirmationTitleCancelSignatureLabel'),
  attentionLabel: locale('subscriptions.cancel.confirmation.attentionLabel'),
  stopCancelSignatureLabel: locale('subscriptions.cancel.confirmation.stopCancelSignatureLabel'),
  backupFilesLinkLabel: locale('subscriptions.cancel.confirmation.backupFilesLinkLabel'),
  backupFilesLinkUrl: locale('subscriptions.cancel.confirmation.backupFilesLinkUrl'),
  stepSurveyLabel: locale('subscriptions.cancel.feedbackStepper.stepSurveyLabel'),
  stepConfirmationLabel: locale('subscriptions.cancel.feedbackStepper.stepConfirmationLabel'),
  stepRefundLabel: locale('subscriptions.cancel.feedbackStepper.stepRefundLabel'),
  stepReviewLabel: locale('subscriptions.cancel.feedbackStepper.stepReviewLabel'),
  surveyQuestionDescriptionLabel: locale('subscriptions.cancel.survey.surveyQuestionDescriptionLabel'),
  surveyReasonQuestionLabel: locale('subscriptions.cancel.survey.surveyReasonQuestionLabel'),
  withRefund: {
    title: locale('subscriptions.cancel.withRefund.title'),
    totalLabel: locale('subscriptions.cancel.withRefund.totalLabel'),
    refundPolitics: locale('subscriptions.cancel.withRefund.refundPolitics'),
    tipTitle: locale('subscriptions.cancel.withRefund.tipTitle'),
    tipText: date => locale('subscriptions.cancel.withRefund.tipText', { date }),
    giveUp: locale('subscriptions.cancel.withRefund.giveUp'),
    selectMethodTitle: locale('subscriptions.cancel.withRefund.selectMethodTitle'),
  },
  noRefund: {
    amountTitle: locale('subscriptions.cancel.noRefund.amountTitle'),
    withoutRefund: locale('subscriptions.cancel.noRefund.withoutRefund'),
    refundPolitics: locale('subscriptions.cancel.noRefund.refundPolitics'),
    tipTitle: locale('subscriptions.cancel.noRefund.tipTitle'),
    tipText: locale('subscriptions.cancel.noRefund.tipText'),
    stopCancelLabel: locale('subscriptions.cancel.noRefund.stopCancelLabel'),
  },
  review: {
    title: locale('subscriptions.cancel.review.title'),
    totalLabel: locale('subscriptions.cancel.review.totalLabel'),
    signatureTitle: locale('subscriptions.cancel.review.signatureTitle'),
    reasonTitle: locale('subscriptions.cancel.review.reasonTitle'),
    refundTitle: locale('subscriptions.cancel.review.refundTitle'),
  },
  refundMethodOptions: {
    hg_credits: {
      name: locale('subscriptions.cancel.refundMethodOptions.hg_credits.name'),
      description: locale('subscriptions.cancel.refundMethodOptions.hg_credits.description'),
      info: locale('subscriptions.cancel.refundMethodOptions.hg_credits.info'),
    },
    braspag_credit_1x: {
      name: locale('subscriptions.cancel.refundMethodOptions.braspag_credit_1x.name'),
      description: locale('subscriptions.cancel.refundMethodOptions.braspag_credit_1x.description'),
      info: locale('subscriptions.cancel.refundMethodOptions.braspag_credit_1x.info'),
    },
    dlocal: {
      name: locale('subscriptions.cancel.refundMethodOptions.dlocal.name'),
      description: locale('subscriptions.cancel.refundMethodOptions.dlocal.description'),
      info: locale('subscriptions.cancel.refundMethodOptions.dlocal.info'),
    },
    gp_boleto: {
      name: locale('subscriptions.cancel.refundMethodOptions.gp_boleto.name'),
      description: locale('subscriptions.cancel.refundMethodOptions.gp_boleto.description'),
      info: locale('subscriptions.cancel.refundMethodOptions.gp_boleto.info'),
    },
    gp_paypal: {
      name: locale('subscriptions.cancel.refundMethodOptions.gp_paypal.name'),
      description: locale('subscriptions.cancel.refundMethodOptions.gp_paypal.description'),
      info: locale('subscriptions.cancel.refundMethodOptions.gp_paypal.info'),
    },
  },
  processing: locale('subscriptions.cancel.processing'),
};

export const refundModalLocaleV2 = {
  title: locale('subscriptions.cancel.refundModalV2.title'),
  refundPolicyButtonLabel: locale('subscriptions.cancel.refundModalV2.refundPolicyButtonLabel'),
  serviceTermsButtonLabel: locale('subscriptions.cancel.refundModalV2.serviceTermsButtonLabel'),
  serviceTermsUrl: locale('subscriptions.cancel.refundModalV2.serviceTermsUrl'),
  accordionCancelledBeforeContent: {
    title: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.title'),
    titleHighlight: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.titleHighlight'),
    refundableSubscriptions: {
      title: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableSubscriptions.title'),
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableSubscriptions.text'),
    },
    nonRefundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.nonRefundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.nonRefundableSubscriptions.text'),
    },
    refundableCicles: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableCicles.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableCicles.text'),
    },
    refundableWays: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableWays.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.refundableWays.text'),
    },
    exception: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.exception.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.exception.text'),
    },
    footerNote: locale('subscriptions.cancel.refundModalV2.accordionCancelledBefore.footerNote'),
  },
  accordionCancelledAfterContent: {
    title: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.title'),
    titleHighlight: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.titleHighlight'),
    refundableSubscriptions: {
      title: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundableSubscriptions.title'),
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundableSubscriptions.text'),
    },
    nonRefundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundableSubscriptions.text'),
    },
    nonRefundableCicles: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundableCicles.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundableCicles.text'),
    },
    refundableWays: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundableWays.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundableWays.text'),
    },
    exception: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.exception.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.exception.text'),
    },
    cancelAfterContract: {
      title: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.cancelAfterContract.title'),
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.cancelAfterContract.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.cancelAfterContract.text'),
    },
    nonRefundContract: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundContract.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundContract.text'),
    },
    nonRefundCycles: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundCycles.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.nonRefundCycles.text'),
    },
    refundCalculation: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundCalculation.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundCalculation.text'),
    },
    refundWays: {
      label: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundWays.label'),
      text: locale('subscriptions.cancel.refundModalV2.accordionCancelledAfter.refundWays.text'),
    },
  },
};

export const refundModalLocale = {
  title: locale('subscriptions.cancel.refundModal.title'),
  refundPolicyButtonLabel: locale('subscriptions.cancel.refundModal.refundPolicyButtonLabel'),
  serviceTermsButtonLabel: locale('subscriptions.cancel.refundModal.serviceTermsButtonLabel'),
  serviceTermsUrl: locale('subscriptions.cancel.refundModal.serviceTermsUrl'),
  accordionCancelledBeforeContent: {
    title: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.title'),
    titleHighlight: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.titleHighlight'),
    refundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableSubscriptions.text'),
    },
    nonRefundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.nonRefundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.nonRefundableSubscriptions.text'),
    },
    refundableCicles: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableCicles.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableCicles.text'),
    },
    refundableWays: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableWays.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.refundableWays.text'),
    },
    exception: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.exception.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.exception.text'),
    },
    footerNote: locale('subscriptions.cancel.refundModal.accordionCancelledBefore.footerNote'),
  },
  accordionCancelledAfterContent: {
    title: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.title'),
    titleHighlight: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.titleHighlight'),
    refundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableSubscriptions.text'),
    },
    nonRefundableSubscriptions: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.nonRefundableSubscriptions.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.nonRefundableSubscriptions.text'),
    },
    refundableCicles: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableCicles.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableCicles.text'),
    },
    nonRefundableCicles: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.nonRefundableCicles.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.nonRefundableCicles.text'),
    },
    refundableCalculation: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableCalculation.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableCalculation.text'),
    },
    exampleCalculation: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.exampleCalculation.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.exampleCalculation.text'),
    },
    amountOwed: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountOwed.label'),
      annualContractAmount: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountOwed.annualContractAmount'),
      annualContractLabel: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountOwed.annualContractLabel'),
      usedAmount: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountOwed.usedAmount'),
      usedLabel: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountOwed.usedLabel'),
    },
    amountAvailableRefund: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountAvailableRefund.label'),
      totalAmount: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountAvailableRefund.totalAmount'),
      totalLabel: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountAvailableRefund.totalLabel'),
      terminationFinePercents: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountAvailableRefund.terminationFinePercents'),
      terminationFineLabel: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.amountAvailableRefund.terminationFineLabel'),
    },
    refundableWays: {
      label: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableWays.label'),
      text: locale('subscriptions.cancel.refundModal.accordionCancelledAfter.refundableWays.text'),
    },
  },
};

export const signatureConfirmationLocale = {
  attentionCancelSignature: (
    <p>
      {locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.0')}
      <strong>{locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.1')}</strong>
      {locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.2')}
      <strong>{locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.3')}</strong>
    </p>
  ),
  addonCancellationAlert: addon => (
    <p>
      {locale('subscriptions.cancel.confirmation.addonCancellationAlert.0')}
      {' '}
      <strong>{addon}</strong>
      {' '}
      {locale('subscriptions.cancel.confirmation.addonCancellationAlert.1')}
      {' '}
      <strong>
        {locale('subscriptions.cancel.confirmation.addonCancellationAlert.2')}
      </strong>
    </p>
  ),
  attentionCancelSignatureAddon: (
    <p>
      {locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.0')}
      <strong>
        {locale('subscriptions.cancel.confirmation.attentionCancelSignatureTexts.1')}
        .
      </strong>
    </p>
  ),
};
