/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Player from '@vimeo/player';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const videoPlayerId = 'video-wrapper';

const VimeoPlayer = ({
  classes,
  videoUrl,
  onPlay,
  onFinish,
  onLoaded,
}) => {
  useEffect(() => {
    const videoPlayer = new Player(videoPlayerId, {
      url: videoUrl,
      autoplay: true,
    });

    videoPlayer.on('play', videoData => onPlay(videoData));
    videoPlayer.on('ended', videoData => onFinish(videoData));
    videoPlayer.on('loaded', videoData => onLoaded(videoData));

    return () => {
      videoPlayer.destroy();
    };
  }, [videoUrl]);

  return (
    <div className={classes.playerWrapper} id={videoPlayerId} />
  );
};

export default withStyles(styles)(VimeoPlayer);
