import styled, { css } from 'styled-components';
import IconCheck from '@/media/icons/check.svg';

export const DescriptionWrapper = styled.div`
  ${({ theme, location, needsMargin }) => {
    const marginBottom = needsMargin ? '77px' : '0px';
    const layout = {
      default: {
        fontFamily: '',
        marginButtonLi: '16px',
        padding: '0px 6px',
      },
      hirePlan: {
        fontFamily: theme.font.family.secondary,
        marginButtonLi: '16px',
        padding: '0px 0px',
      },
    };
    return css`
      & ul {
        list-style-type: none;
        margin: unset;
        padding: ${location ? layout[location].padding : layout.default.padding};
        text-align: left;
        font-family: ${location ? layout[location].fontFamily : layout.default.fontFamily};

        & li {
          color: ${theme.palette.grey.dark};
          display: flex;
          font-size: 14px;
          letter-spacing: 0.01px;
          line-height: 21px;
          margin-bottom:   ${location ? layout[location].marginButtonLi : layout.default.marginButtonLi};
          position: relative;

          & > strong, & > b {
            display: contents;
          }

          & span {
            align-items: center;
            background: ${theme.palette.secondary.primary};
            border-radius: 50%;
            color: ${theme.palette.white.light};
            display: flex;
            font-size: 12px;
            height: 15px;
            justify-content: center;
            margin-left: 8px;
            max-width: 15px;
            position: relative;
            top: 4px;
            width: 100%;
          }

          &:before {
            background-image: url('${IconCheck}');
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 16px;
            margin-right: 8px;
            min-width: 16px;
            position: relative;
            top: 2px;
          }
        }

        & li:last-child {
          margin-bottom: 0px,
        }
      }
      @media only screen and (min-width: 1035px) {
        margin-bottom: ${marginBottom};
      }
  `;
  }}
`;

export const IncludedLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01px;
    line-height: 19px;
    margin: 0px 0px 16px 0px;
    text-align: left;
    text-transform: uppercase
  `}
`;

export const Separator = styled.hr`
  ${({ theme }) => css`
    border: 0;
    border-bottom: 1px solid ${theme.palette.grey.main};
    width: 100%;
    margin: 16px 0px
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-bottom: 8px;
    text-align: left;
  `}
`;
