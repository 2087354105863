import React, { useEffect, useState } from 'react';
import {
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  IconCheck, IconDanger, IconClose, Button, Alert,
} from 'gatorcomponents';
import * as Styles from './ReactivationModal.styles';
import { billingActions, preChatActions } from '@/redux/modules';
import { FlowStep, ReactivationModalProps } from './ReactivationModal.types';
import useLocale from '@/hooks/useLocale';
import { preChatAnalytics } from '@/analytics/preChat';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ReactivationModal = ({
  onCloseClick,
  invoiceId,
  paymentMethod,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [screen, setScreen] = useState(FlowStep.INITIAL);
  const [onFirstStep, setOnFirstStep] = useState(true);
  const { reactivation: reactivationRequest } = useSelector(state => state.billing);
  const { reactivationRedirect: preChatRedirect } = useSelector(state => state.preChat);
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { manageSubscriptionReactivationModal: reactivationLocale } = billingLocale;

  const reactivationStatusSuccess = (reactivationRequest.hostings.every(hosting => hosting.success === true));

  useEffect(() => {
    if (!onFirstStep && !reactivationRequest.loading && reactivationRequest.success && reactivationStatusSuccess) {
      setScreen(FlowStep.SUCCESS);
    }
    if (!onFirstStep && !reactivationRequest.loading && reactivationRequest.success && !reactivationStatusSuccess) {
      setScreen(FlowStep.FAILURE);
    }
    if (!onFirstStep && !reactivationRequest.loading && !reactivationRequest.success) {
      setScreen(FlowStep.FAILURE);
    }
  }, [onFirstStep, reactivationRequest.loading, reactivationRequest.success, reactivationStatusSuccess]);

  useEffect(() => {
    if (screen === FlowStep.SUCCESS) {
      preChatAnalytics.successTrustReactivation();
    } else if (screen === FlowStep.FAILURE) {
      preChatAnalytics.failTrustReactivation();
    }
  }, [screen]);

  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
    if (preChatRedirect) {
      dispatch(preChatActions.trustReactivation.redirect(false));
      dispatch(preChatActions.preChat.setScreen('problemSolved'));
      dispatch(preChatActions.preChat.setOpen(true));
    }
  };

  const handleOnReactivateButtonClick = () => {
    if (invoiceId) {
      let redirect = history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceId}`);

      if (paymentMethod) {
        redirect = history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceId}/${paymentMethod}`);
      }

      dispatch(billingActions.subscriptions.reactivation.request({ invoiceId, redirect }));
      setOnFirstStep(false);
    }
  };

  const multipleReactivationsAlert = () => (
    <Alert variant="info" description={reactivationLocale.infoAlertReactivation} />
  );

  const screenToShow = screenToShow => ({
    [FlowStep.SUCCESS]: (
      <>
        <Styles.Header>
          <Styles.Title data-testid="subscription-reactivation-success-title">{reactivationLocale.reactivationSuccessLabel}</Styles.Title>
        </Styles.Header>
        <Styles.ResultContainer>
          <Styles.ResultIcon variant="success">
            <IconCheck />
          </Styles.ResultIcon>
          <Styles.ResultAlertText>
            <div>{reactivationLocale.infoSuccessLabel}</div>
            <div>{reactivationLocale.infoSuccessDescriptionLabel}</div>
          </Styles.ResultAlertText>
        </Styles.ResultContainer>
        {multipleReactivationsAlert()}
        <Styles.ButtonContainer>
          <Button
            testId="reactivate-success-button"
            onClick={handleOnCloseClick}
            size="large"
            label={reactivationLocale.undestoodButtonLabel}
          />
        </Styles.ButtonContainer>
      </>
    ),
    [FlowStep.FAILURE]: (
      <>
        <Styles.Header>
          <Styles.Title data-testid="subscription-reactivation-failure-title">{reactivationLocale.reactivationFailureLabel}</Styles.Title>
        </Styles.Header>
        <Styles.ResultContainer>
          <Styles.ResultIcon variant="failure">
            <IconDanger />
          </Styles.ResultIcon>
          <Styles.ResultAlertText>
            <div>{reactivationLocale.infoFailureLabel}</div>
            <div>{reactivationLocale.infoFailureDescriptionLabel}</div>
          </Styles.ResultAlertText>
        </Styles.ResultContainer>
        <Styles.ButtonContainer>
          <Styles.TryAgainButtonBox>
            <Button
              testId="try-again-subscription-button"
              onClick={handleOnReactivateButtonClick}
              loading={!invoiceId || reactivationRequest.loading}
              size="large"
              width="full"
              label={reactivationLocale.tryAgainButtonLabel}
            />
          </Styles.TryAgainButtonBox>
          <Button
            testId="try-again-subscription-button"
            onClick={handleOnCloseClick}
            size="large"
            variant="tertiary"
            label={reactivationLocale.goBackSubscriptionButtonLabel}
          />
        </Styles.ButtonContainer>
      </>
    ),
  })[screenToShow] || (
    <>
      <Styles.Header>
        <Styles.Title data-testid="subscription-reactivation-title">{reactivationLocale.reactivateTitleLabel}</Styles.Title>
      </Styles.Header>
      <Styles.Data data-testid="subscription-reactivation-description">
        {reactivationLocale.descriptionLabel}
      </Styles.Data>
      <Styles.InfoAlert data-testid="subscription-reactivation-info-alert">
        <div>
          {reactivationLocale.infoAlertLabel}
        </div>
      </Styles.InfoAlert>
      {multipleReactivationsAlert()}
      <Styles.ButtonContainer>
        <Styles.ButtonBox>
          <Button
            testId="reactivate-subscription-button"
            onClick={handleOnReactivateButtonClick}
            size="large"
            loading={!invoiceId || reactivationRequest.loading}
            width="full"
            label={reactivationLocale.reactivateButtonLabel}
          />
        </Styles.ButtonBox>
      </Styles.ButtonContainer>
    </>
  );

  return (
    <Dialog
      fullWidth
      open
      onClose={handleOnCloseClick}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          maxWidth: '624px',
        },
      }}
    >
      <Styles.Content>
        <Styles.ContentCloseButton>
          <IconButton data-testid="btn-close-modal" aria-label="Fechar" onClick={handleOnCloseClick} disabled={reactivationRequest.loading}>
            <IconClose />
          </IconButton>
        </Styles.ContentCloseButton>
        {screenToShow(screen)}
      </Styles.Content>
    </Dialog>
  );
};

ReactivationModal.propTypes = ReactivationModalProps;

export default ReactivationModal;
