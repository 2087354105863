import styled, { css } from 'styled-components';

export const Icon = styled.div`
  ${({ iconColor }) => css`
    display: flex;

    svg {
      fill: ${iconColor};
    }
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    p {
      margin: 0;
      color: ${theme.palette.grey.dark};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme, borderColor, variant }) => {
    const variants = {
      small: {
        padding: '2px 4px',
        borderRadius: '2px',
        iconScale: 0.9,
        fontSize: theme.font.sizes.xxs,
        lineHeight: '16px',
        marginLeft: '4px',
        fontFamily: theme.font.family.primary,
      },
      default: {
        padding: '16px',
        borderRadius: '5px',
        iconScale: 1,
        fontSize: theme.font.sizes.xxs,
        lineHeight: '16px',
        marginLeft: '16px',
        fontFamily: theme.font.family.primary,
      },
      large: {
        padding: '16px',
        borderRadius: '5px',
        iconScale: 1.4,
        fontSize: theme.font.sizes.md,
        lineHeight: '24px',
        marginLeft: '16px',
        fontFamily: theme.font.family.primary,
      },
      warning: {
        color: theme.palette.grey.dark,
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.xxs,
        marginLeft: '8px',
        padding: '0px 24px',
      },
      altWarning: {
        color: theme.palette.grey.dark,
        fontFamily: theme.font.family.secondary,
        fontSize: theme.font.sizes.xxs,
        marginLeft: '8px',
        padding: '0px 24px 0px 0px',
      },
    };

    return css`
      display: flex;
      align-items: flex-start;
      border: 1px solid ${borderColor};
      padding: ${(variant === 'warning') ? '18px 0px' : variants[variant].padding};
      border-radius: ${variants[variant].borderRadius};
      font-family: ${variants[variant].fontFamily};

      & ${Icon} svg {
        transform: scale(${variants[variant].iconScale});
        margin-top: 9px;
        @media (min-width: ${theme.breakpoints.sm}){
          margin-top: 0px;
        }
      }

      & ${Text} {
        margin-left: ${variants[variant].marginLeft};
        font-size: ${variants[variant].fontSize};
        line-height: ${variants[variant].lineHeight};
        color: ${variants[variant].color}
      }
      @media (min-width: ${theme.breakpoints.sm}){
        padding: ${variants[variant].padding};
        align-items: center;
      }
    `;
  }
}
`;
