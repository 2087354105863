import React from 'react';
import * as Style from './IconDetailedLandingPage.style';

const IconDetailedLandingPage = ({
  color = '#4a4a4a',
  backgroundColor = '#3c97ff',
  size = '30',
  testId = 'icon-detailed-landing-page',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill={color}>
      <g id="Grupo_40179" data-name="Grupo 40179" transform="translate(-717 -646)">
        <g id="Group_7" data-name="Group 7" transform="translate(728.217 657.217)">
          <circle id="Oval" cx="9.5" cy="9.5" r="9.5" transform="translate(-0.218 -0.218)" fill={backgroundColor} />
        </g>
        <path id="Caminho_247863" data-name="Caminho 247863" d="M15.819,12.435a4.217,4.217,0,1,0-4.234-4.217A4.225,4.225,0,0,0,15.819,12.435Zm0-6.326A2.109,2.109,0,1,1,13.7,8.217,2.119,2.119,0,0,1,15.819,6.109Zm0,8.435c-2.826,0-8.468,1.413-8.468,4.217v2.109H24.287V18.761C24.287,15.956,18.645,14.543,15.819,14.543ZM9.468,18.761c.233-.759,3.5-2.109,6.351-2.109s6.139,1.36,6.351,2.109ZM6.292,15.6V12.435H9.468V10.326H6.292V7.163H4.175v3.163H1v2.109H4.175V15.6Z" transform="translate(716.322 647.774)" />
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconDetailedLandingPage;
