import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
  margin: 0 24px;
  padding: 0px 0px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const CardsContainer = styled.div`
  ${({ theme }) => css`
    background-color:${theme.v2.colors.white.primary};
    padding: 32px;

    @media (max-width:${theme.v2.breakpoints.lg}) {
      padding: 24px 20px;
    }
  `}
`;

export const CardsTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const CardsDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0px 0px;
  `}
`;

export const CardsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
    margin: 32px 0px 0px;
    max-width:1032px;

    @media (max-width:${theme.v2.breakpoints.lg}) {
      align-items: center;
      flex-direction: column;
      gap: 32px;
    }
`}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-direction: column;
    max-width: 328px;
    min-width: 328px;
    padding: 37px 24px 39px;
    position: relative;
    width: 100%;
  `}
`;

export const FeatureWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const CardItemTitle = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.attentionLight};
    border-radius: 20px;
    color: ${theme.v2.colors.brand.primaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px auto;
    padding: 0px 10px;
    text-align: center;
    width: fit-content;
  `}
`;

export const CardImageWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    margin: 18px 0px;
    min-height: 60px;
`;

export const CardImage = styled.img`
    display: block;
    margin: 0px auto;
`;

export const CardList = styled.ul`
  margin: 0px 0px 18px;
  padding: 0px 0px 0px 18px;
`;

export const CardListItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.list};
    
    strong {
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;

export const CardActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0px 0px;
`;

export const SelectOptionWrapper = styled.div`
  display: flex;
  gap: 16px;

  span {
    display: block;
    max-width: 218px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div {
    display: inline-flex;
    position: relative;
  }
`;
