import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, TextField,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import IntegerMaskedInput from '@/components/Fields/InputMasks/IntegerMaskedInput';
import ZoneManagerSubdomainInputMask from '@/components/Fields/InputMasks/ZoneManagerSubdomainInputMask';
import styles from './styles';


const TypeCAAValueField = ({
  t, classes, formData, onHandleChangeInput, isInvalidDestination, isInvalidPriority,
}) => {
  const { loading } = useContext(DNSContext);

  return (
    <div className={classes.MxBlockWrapper}>
      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeMxPriorityField')}
        value={formData.preference}
        onChange={e => onHandleChangeInput(e.target)}
        name="preference"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        placeholder={t('dns.zoneManager.addRecord.form.placeholder.preference')}
        InputProps={{
          inputComponent: IntegerMaskedInput,
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputProps: {
            maxLength: 5,
            'data-testid': 'zoneManagerMxPriorityField',
          },
        }}
        error={isInvalidPriority}
        helperText={isInvalidPriority ? t('dns.zoneManager.addRecord.form.helperText.mxPriorityValue') : null}
      />

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeMxDestinationField')}
        value={formData.exchange}
        onChange={e => onHandleChangeInput(e.target)}
        name="exchange"
        required
        variant="outlined"
        className={classes.textField}
        placeholder="Domínio Completo"
        InputProps={{
          inputComponent: ZoneManagerSubdomainInputMask,
          inputProps: {
            maxLength: 255,
            'data-testid': 'zoneManagerMxDestinationField',
          },
        }}
        error={isInvalidDestination}
        helperText={isInvalidDestination ? t('dns.zoneManager.addRecord.form.helperText.mxDestinationValue') : null}
      />

    </div>

  );
};

export default (withI18n()(withStyles(styles)(TypeCAAValueField)));
