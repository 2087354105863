import { COUNTRY } from '@/config';

const productsCreateBr = {
  planoP: 5,
  planoM: 6,
  planoStarter: 437,
  planoTurbo: 335,
  planoBusiness: 7,
  hospedagemPlus: 47,
  hospedagemColaboradoresInternos: 247,
  criadorSitesPessoal: 278,
  criadorSitesProfissional: 284,
  criadorSitesLojaOnline: 272,
  revenda1: 50,
  revenda2: 51,
  revenda3: 52,
  revenda4: 53,
  revenda5: 54,
  revendaComecando: 385,
  revendaCrescendo: 388,
  revendaExpandindo: 391,
  revendaVeterano: 394,
  revendaVeterano100: 397,
  revendaVeterano150: 409,
  revendaVeterano200: 400,
  revendaVeterano300: 403,
  revendaVeterano500: 406,
  revendaVeterano1000: 412,
  revendaVeterano1200: 415,
};

const productsCreateModalES = {
  planPersonal: 174,
  planEmpreendedor: 5,
  planNegocios: 6,
  planProfessional: 7,
  planTurbo: 213,
  creadorSitiosPersonal: 164,
  creadorSitiosProfessional: 165,
  creadorSitiosTiendEnLinea: 166,
  resellerCobre: 51,
  resellerPlata: 52,
  resellerOro: 53,
  resellerDiamante: 54,
  resellerAluminio: 50,
  resellerIniciando: 229,
  resellerCreciendo: 230,
  resellerExpandiendo: 231,
  resellerVeterano: 232,
  resellerVeterano100: 233,
  resellerVeterano150: 234,
  resellerVeterano200: 235,
  resellerVeterano300: 236,
  resellerVeterano500: 237,
  resellerVeterano1000: 240,
  resellerVeterano1200: 238,
};

const brPlans = [
  productsCreateBr.planoP,
  productsCreateBr.planoM,
  productsCreateBr.planoStarter,
  productsCreateBr.planoTurbo,
  productsCreateBr.planoBusiness,
  productsCreateBr.hospedagemPlus,
  productsCreateBr.hospedagemColaboradoresInternos,
  productsCreateBr.criadorSitesPessoal,
  productsCreateBr.criadorSitesProfissional,
  productsCreateBr.criadorSitesLojaOnline,
  productsCreateBr.revenda1,
  productsCreateBr.revenda2,
  productsCreateBr.revenda3,
  productsCreateBr.revenda4,
  productsCreateBr.revenda5,
  productsCreateBr.revendaComecando,
  productsCreateBr.revendaCrescendo,
  productsCreateBr.revendaExpandindo,
  productsCreateBr.revendaVeterano,
  productsCreateBr.revendaVeterano100,
  productsCreateBr.revendaVeterano150,
  productsCreateBr.revendaVeterano200,
  productsCreateBr.revendaVeterano300,
  productsCreateBr.revendaVeterano500,
  productsCreateBr.revendaVeterano1000,
  productsCreateBr.revendaVeterano1200,
];

const esPlans = [
  productsCreateModalES.planPersonal,
  productsCreateModalES.planEmpreendedor,
  productsCreateModalES.planNegocios,
  productsCreateModalES.planProfessional,
  productsCreateModalES.planTurbo,
  productsCreateModalES.creadorSitiosPersonal,
  productsCreateModalES.creadorSitiosProfessional,
  productsCreateModalES.creadorSitiosTiendEnLinea,
  productsCreateModalES.resellerCobre,
  productsCreateModalES.resellerPlata,
  productsCreateModalES.resellerOro,
  productsCreateModalES.resellerDiamante,
  productsCreateModalES.resellerAluminio,
  productsCreateModalES.resellerIniciando,
  productsCreateModalES.resellerCreciendo,
  productsCreateModalES.resellerExpandiendo,
  productsCreateModalES.resellerVeterano,
  productsCreateModalES.resellerVeterano100,
  productsCreateModalES.resellerVeterano150,
  productsCreateModalES.resellerVeterano200,
  productsCreateModalES.resellerVeterano300,
  productsCreateModalES.resellerVeterano500,
  productsCreateModalES.resellerVeterano1000,
  productsCreateModalES.resellerVeterano1200,
];

const productsCreateModalArrayByBrand = {
  br: [...brPlans],
  mx: [...esPlans],
  cl: [...esPlans],
  co: [...esPlans],
};

export const productsCreateModalArray = productsCreateModalArrayByBrand[COUNTRY];
