import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Form = styled.form`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-wrap: wrap;
    gap: 24px 32px;
    padding: 24px 24px 50px 24px;
  `};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  min-width: 230px;
`;

export const Row = styled.div`
 ${({ theme, noWrap, margin }) => css`
    align-items: center;
    display: flex;
    gap: 24px;
    margin: ${margin || '0px'};

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-wrap: ${noWrap ? 'nowrap' : 'wrap'};
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const PrivacyTitle = styled.div`
    margin-top: 8px;
`;
