import styled, { css } from 'styled-components';

const defaultWrapperWidth = '498px';

export const Form = styled.form`
  margin-bottom: 48px;
`;

export const Info = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 0px;
    max-width: 540px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    > svg {
      position: relative;
      top: 5px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 24px;
  `}
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px 0px 0px 0px;
  max-width: ${defaultWrapperWidth};

  div {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  ${({ error }) => css`
    margin-bottom: ${error ? '4px' : '24px'};
  `};
`;

export const BlockTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0 24px 0;
  `}
`;

export const RadioWrapper = styled(InputsWrapper)`
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
`;

export const RadioLabel = styled.label`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    cursor: pointer;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 4px;

    label {
      width: 19px;
      height: 19px;
    }
  `}
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 24px 0px 0px;

  label {
    width: 19px;
    height: 19px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: inline-block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    gap: 6px;
    line-height: ${theme.v2.font.lineHeight.text};

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;
