const styles = theme => ({
  cardWarningBox: {
    border: `1px solid ${theme.color.brightSun}`,
    padding: '5px 15px 5px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '35px',
    '& svg': {
      marginRight: '12px',
      color: theme.color.tundora,
    },
    '& span': {
      lineHeight: '1.4',
      letterSpacing: '0',
    },
  },
  paymentPending: {
    borderColor: theme.color.coral,
    justifyContent: 'flex-start',
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .label': {
      backgroundColor: theme.color.coral,
    },
    '@media(min-width: 960px) and (max-width: 1160px)': {
      justifyContent: 'center',
    },
  },
  cardWarningText: {
    fontSize: 12,
    margin: 0,
    '& a': {
      color: theme.color.indigo,
    },
  },
  statusCancelled: {
    borderColor: theme.color.geyser,
  },
  statusTerminated: {
    borderColor: theme.color.geyser,
  },
  statusOnConfigure: {
    borderColor: theme.color.brightSun,
  },
  statusInAnalysis: {
    borderColor: theme.color.brightSun,
  },
  statusExpired: {
    borderColor: theme.color.grayLight,
  },
  statusSuspended: {
    borderColor: theme.color.valencia,
  },
});

export default styles;
