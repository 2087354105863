import React from 'react';
import { Modal, Button, Accordion } from '@/pages/common';
import { COUNTRY } from '@/config';
import * as Styles from './RefundModal.styles';


const RefundModal = ({
  accordionCancelledAfterContent,
  accordionCancelledAfterExpanded = false,
  accordionCancelledAfterLabel = 'Cancellations made after 7 days of contracting',
  accordionCancelledAfterTitleHighlightPattern = 'after 7 days',
  accordionCancelledBeforeContent,
  accordionCancelledBeforeExpanded = false,
  accordionCancelledBeforeLabel = 'Cancellations made within 7 days of contracting',
  accordionCancelledBeforeTitleHighlightPattern = 'within 7 days',
  onRefundModalClose,
  onRefundPolicyClick,
  onServiceTermsClick,
  enableRefundToggle,
  open = false,
  refundPolicyButtonLabel = 'see more about refund policy',
  serviceTermsButtonLabel = 'see terms of service',
  title = 'Refund Policy',
  refundModalLocaleV2,
}) => {
  const isBr = COUNTRY === 'br';

  const handleOnClose = () => {
    onRefundModalClose && onRefundModalClose();
  };

  const handleOnRefundPolicyClick = () => {
    onRefundPolicyClick && onRefundPolicyClick();
  };

  const handleOnServiceTermsClick = () => {
    onServiceTermsClick && onServiceTermsClick();
  };

  return (
    <Styles.Wrapper
      data-testid="refund-modal-wrapper"
      open={open}
    >
      { open && (
        <Modal
          onClose={handleOnClose}
          testId="refund-modal"
          maxWidth={1015}
          maxHeight={630}
        >
          <Styles.Header>
            <Styles.Title data-testid="refund-modal-title">{title}</Styles.Title>
          </Styles.Header>

          <Styles.ContentWrapper>
            <Styles.Content>
              <Styles.ContentItem>
                {enableRefundToggle && isBr ? (
                  <Accordion
                    title={refundModalLocaleV2.accordionCancelledBeforeContent.title}
                    titleHighlightPattern={refundModalLocaleV2.accordionCancelledBeforeContent.titleHighlight}
                    expanded={accordionCancelledBeforeExpanded}
                  >
                    <Styles.List>
                      {refundModalLocaleV2.accordionCancelledBeforeContent.refundableSubscriptions.title && (
                      <Styles.TitleAccordion>{refundModalLocaleV2.accordionCancelledBeforeContent.refundableSubscriptions.title}</Styles.TitleAccordion>
                      )}
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledBeforeContent.refundableSubscriptions.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledBeforeContent.refundableSubscriptions.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledBeforeContent.nonRefundableSubscriptions.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledBeforeContent.nonRefundableSubscriptions.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledBeforeContent.refundableWays.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledBeforeContent.refundableWays.text}
                        </Styles.Text>
                      </Styles.ListItem>
                    </Styles.List>
                    <Styles.Block>
                      <Styles.Label>{refundModalLocaleV2.accordionCancelledBeforeContent.exception.label}</Styles.Label>
                      <Styles.Text>
                        {refundModalLocaleV2.accordionCancelledBeforeContent.exception.text}
                      </Styles.Text>
                    </Styles.Block>
                  </Accordion>
                )
                  : (
                    <Accordion
                      title={accordionCancelledBeforeLabel}
                      titleHighlightPattern={accordionCancelledBeforeTitleHighlightPattern}
                      expanded={accordionCancelledBeforeExpanded}
                    >
                      <Styles.List>
                        {accordionCancelledBeforeContent.refundableSubscriptions.title && (
                        <Styles.TitleAccordion>{accordionCancelledBeforeContent.refundableSubscriptions.title}</Styles.TitleAccordion>
                        )}
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledBeforeContent.refundableSubscriptions.label}</Styles.Label>
                            {' '}
                            {accordionCancelledBeforeContent.refundableSubscriptions.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledBeforeContent.nonRefundableSubscriptions.label}</Styles.Label>
                            {' '}
                            {accordionCancelledBeforeContent.nonRefundableSubscriptions.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        {accordionCancelledBeforeContent.refundableCicles.label && (
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledBeforeContent.refundableCicles.label}</Styles.Label>
                            {' '}
                            {accordionCancelledBeforeContent.refundableCicles.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        )}
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledBeforeContent.refundableWays.label}</Styles.Label>
                            {' '}
                            {accordionCancelledBeforeContent.refundableWays.text}
                          </Styles.Text>
                        </Styles.ListItem>
                      </Styles.List>

                      <Styles.Block>
                        <Styles.Label>{accordionCancelledBeforeContent.exception.label}</Styles.Label>
                        <Styles.Text>
                          {accordionCancelledBeforeContent.exception.text}
                        </Styles.Text>
                      </Styles.Block>
                      {accordionCancelledBeforeContent.footerNote && (
                      <Styles.Text>
                        {accordionCancelledBeforeContent.footerNote}
                      </Styles.Text>
                      )}
                    </Accordion>
                  )
                }
              </Styles.ContentItem>

              <Styles.ContentItem>
                {enableRefundToggle && isBr ? (
                  <Accordion
                    title={refundModalLocaleV2.accordionCancelledAfterContent.title}
                    titleHighlightPattern={refundModalLocaleV2.accordionCancelledAfterContent.titleHighlight}
                    expanded={accordionCancelledAfterExpanded}
                  >
                    <Styles.List>
                      {refundModalLocaleV2.accordionCancelledAfterContent.refundableSubscriptions.title && (
                      <Styles.TitleAccordion>{refundModalLocaleV2.accordionCancelledAfterContent.refundableSubscriptions.title}</Styles.TitleAccordion>
                      )}
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.refundableSubscriptions.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.refundableSubscriptions.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.nonRefundableSubscriptions.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.nonRefundableSubscriptions.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      {refundModalLocaleV2.accordionCancelledAfterContent.nonRefundableCicles.label && (
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.nonRefundableCicles.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.nonRefundableCicles.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      )}
                    </Styles.List>
                    {refundModalLocaleV2.accordionCancelledAfterContent.refundableWays.label && (
                    <Styles.List>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.refundableWays.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.refundableWays.text}
                        </Styles.Text>
                      </Styles.ListItem>
                    </Styles.List>
                    )}
                    {refundModalLocaleV2.accordionCancelledAfterContent.exception.label && (
                    <Styles.Block>
                      <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.exception.label}</Styles.Label>
                      <Styles.Text>
                        {refundModalLocaleV2.accordionCancelledAfterContent.exception.text}
                      </Styles.Text>
                    </Styles.Block>
                    )}
                    {refundModalLocaleV2.accordionCancelledAfterContent.cancelAfterContract.title && (
                    <Styles.List>
                      <Styles.TitleAccordion>{refundModalLocaleV2.accordionCancelledAfterContent.cancelAfterContract.title}</Styles.TitleAccordion>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.cancelAfterContract.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.cancelAfterContract.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.nonRefundContract.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.nonRefundContract.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.nonRefundCycles.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.nonRefundCycles.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.refundCalculation.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.refundCalculation.text}
                        </Styles.Text>
                      </Styles.ListItem>
                      <Styles.ListItem>
                        <Styles.Text>
                          <Styles.Label>{refundModalLocaleV2.accordionCancelledAfterContent.refundWays.label}</Styles.Label>
                          {' '}
                          {refundModalLocaleV2.accordionCancelledAfterContent.refundWays.text}
                        </Styles.Text>
                      </Styles.ListItem>
                    </Styles.List>
                    )}
                  </Accordion>
                )
                  : (
                    <Accordion
                      title={accordionCancelledAfterLabel}
                      titleHighlightPattern={accordionCancelledAfterTitleHighlightPattern}
                      expanded={accordionCancelledAfterExpanded}
                    >
                      <Styles.List>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.refundableSubscriptions.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.refundableSubscriptions.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.nonRefundableSubscriptions.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.nonRefundableSubscriptions.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.refundableCicles.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.refundableCicles.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.nonRefundableCicles.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.nonRefundableCicles.text}
                          </Styles.Text>
                        </Styles.ListItem>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.refundableCalculation.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.refundableCalculation.text}
                          </Styles.Text>
                        </Styles.ListItem>
                      </Styles.List>
                      <Styles.Block>
                        <Styles.Label>{accordionCancelledAfterContent.exampleCalculation.label}</Styles.Label>
                        <Styles.Text>
                          {accordionCancelledAfterContent.exampleCalculation.text}
                        </Styles.Text>
                        <Styles.BlockSection>
                          <Styles.Block>
                            <Styles.Label>{accordionCancelledAfterContent.amountOwed.label}</Styles.Label>
                            <Styles.Expression>
                              <Styles.ExpressionBase>
                                <Styles.ExpressionPrice>
                                  {accordionCancelledAfterContent.amountOwed.annualContractAmount}
                                </Styles.ExpressionPrice>
                                <Styles.ExpressionText>
                                  {accordionCancelledAfterContent.amountOwed.annualContractLabel}
                                </Styles.ExpressionText>
                              </Styles.ExpressionBase>
                              <Styles.SymbolMinus />
                              <Styles.ExpressionBase>
                                <Styles.ExpressionPrice>
                                  {accordionCancelledAfterContent.amountOwed.usedAmount}
                                </Styles.ExpressionPrice>
                                <Styles.ExpressionText>
                                  {accordionCancelledAfterContent.amountOwed.usedLabel}
                                </Styles.ExpressionText>
                              </Styles.ExpressionBase>
                            </Styles.Expression>
                          </Styles.Block>
                          <Styles.SymbolEqual />
                          <Styles.Block>
                            <Styles.Label>{accordionCancelledAfterContent.amountAvailableRefund.label}</Styles.Label>
                            <Styles.Expression>
                              <Styles.ExpressionBase>
                                <Styles.ExpressionPrice>
                                  {accordionCancelledAfterContent.amountAvailableRefund.totalAmount}
                                </Styles.ExpressionPrice>
                                <Styles.ExpressionText>
                                  {accordionCancelledAfterContent.amountAvailableRefund.totalLabel}
                                </Styles.ExpressionText>
                              </Styles.ExpressionBase>
                              <Styles.SymbolMinus />
                              <Styles.ExpressionBase>
                                <Styles.ExpressionPrice>
                                  {accordionCancelledAfterContent.amountAvailableRefund.terminationFinePercents}
                                </Styles.ExpressionPrice>
                                <Styles.ExpressionText>
                                  {accordionCancelledAfterContent.amountAvailableRefund.terminationFineLabel}
                                </Styles.ExpressionText>
                              </Styles.ExpressionBase>
                            </Styles.Expression>
                          </Styles.Block>
                        </Styles.BlockSection>
                      </Styles.Block>
                      <Styles.List>
                        <Styles.ListItem>
                          <Styles.Text>
                            <Styles.Label>{accordionCancelledAfterContent.refundableWays.label}</Styles.Label>
                            {' '}
                            {accordionCancelledAfterContent.refundableWays.text}
                          </Styles.Text>
                        </Styles.ListItem>
                      </Styles.List>
                    </Accordion>
                  )
                }
              </Styles.ContentItem>
            </Styles.Content>
          </Styles.ContentWrapper>

          <Styles.Footer>
            {enableRefundToggle && isBr ? (
              null
            ) : (
              <Styles.ButtonAdapter>
                <Button
                  label={refundPolicyButtonLabel.toUpperCase()}
                  onClick={handleOnRefundPolicyClick}
                  testId="refund-modal-policy-button"
                />
              </Styles.ButtonAdapter>
            )}
            <Styles.ButtonAdapter>
              <Button
                variant="text"
                label={serviceTermsButtonLabel.toUpperCase()}
                onClick={handleOnServiceTermsClick}
                testId="refund-modal-service-terms-button"
              />
            </Styles.ButtonAdapter>
          </Styles.Footer>
        </Modal>
      )}
    </Styles.Wrapper>
  );
};

export default RefundModal;
