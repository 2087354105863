const styles = theme => ({
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,

    ' & > div': {
      maxWidth: 'calc(33% - 13px)',
      width: '100%',
    },

    '& > div:first-child': {
      marginLeft: 0,
    },

    '& > div:last-child': {
      marginRight: 0,
    },

    [theme.breakpoints.down(1148)]: {
      flexDirection: 'column',
      '& > div': {
        margin: '0 0 12px 0',
        maxWidth: '100%',
      },
    },
  },

  twoChild: {
    ' & > div': {
      maxWidth: 'unset',
      width: 'unset',
      flex: 1,
    },

    '& > div:first-child': {
      marginLeft: 6,
    },

    '& > div:last-child': {
      marginRight: 6,
    },


    [theme.breakpoints.down(1148)]: {
      '& > div:first-child': {
        marginLeft: 0,
      },

      '& > div:last-child': {
        marginRight: 0,
      },
    },
  },

  item: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color.geyser}`,
    cursor: 'pointer',
    height: 'fit-content',
    minHeight: 121,
    outline: 'none',
    padding: '15px 18px',
    textAlign: 'left',
    width: '100%',

    [theme.breakpoints.down(1258)]: {
      minHeight: 137,
    },

    [theme.breakpoints.down(1196)]: {
      minHeight: 153,
    },

    [theme.breakpoints.down(1153)]: {
      minHeight: 169,
    },

    [theme.breakpoints.down(1148)]: {
      margin: '0',
      marginBottom: 12,
      minHeight: 'unset',
    },

    '&.expand': {
      cursor: 'auto',
      height: 'auto',
    },
  },

  selectedItem: {
    border: `2px solid ${theme.color.chambray}`,
  },

  withMargin: {
    margin: '0 20px 0 20px',

    [theme.breakpoints.down(1148)]: {
      margin: '0 0 12px 0',
    },
  },

  withoutMargin: {
    margin: 0,

    [theme.breakpoints.down(1148)]: {
      margin: '0 0 12px 0',
    },
  },
});

export default styles;
