import React, { useEffect, useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Separator } from '@/pages/common/v1/Separator';
import {
  Dialog,
  CircularProgress,
  IconButton,
} from '@material-ui/core'; import * as Styles from './PaymentModal.styles';
import {
  DiscountType,
  OriginCalling,
  PaymentMethods,
  PaymentModalProps,
} from './PaymentModal.types';
import { locale } from '@/utils/locale';
import useLocale from '@/hooks/useLocale';
import { Accordion, Radio } from '@/pages/common';
import {
  BOLETO,
  BOLETO_CL_CO,
  BOLETO_MX,
  CREDIT_CARD_ES,
  GATORPAY_BOLETO,
  LIST_BOLETO_METHODS,
  LIST_CREDIT_CARD_METHODS,
  LIST_PAYPAL_METHODS,
  PIX,
} from '@/config/billing/paymentMethods';
import { IS_PROD, config, COUNTRY } from '@/config/index';
import { invoiceUnpaidCardTagManager } from '@/utils/ThirdParties/tagManager';
import {
  isBRServer, isCLServer, isCOServer, isMXServer, isMobile, hasPaymentEnabled,
} from '@/utils/Validators/validation';
import {
  getBoletoUrl, checkBoletoUserData, payTicketInvoiceForES,
} from '@/redux/actions/invoices';
import { Chip } from '@/pages/common/v1/Chip';
import { BOLETO_URL } from '@/config/whmcsUrls';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import { useDispatch, useSelector } from 'react-redux';
import PaypalDialog from '@/components/Paypal/PaypalDialog';
import CreditCardDialog from '@/components/CreditCard/CreditCardDialog';
import { Close } from '@material-ui/icons';
import {
  ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES,
  RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES,
} from '@/redux/actions/actionsTypes';
import { PAYMENT_STATUS } from '@/pages/Invoices/types';
import { useHistory } from 'react-router';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import InvoiceDetails from '@/components/Invoices/InvoiceDetails';
import Loader from '@/components/Layout/Loader';
import { invoicesActions } from '@/redux/modules/invoices';
import { billingAnalytics } from '@/analytics';
import { PaypalActionTypes } from '@/components/Paypal/PaypalDialog/Paypal.types';
import PixCard from '@/components/Pix/PixCard';
import QRCodePixCard from '@/components/Pix/QRCodePixCard';
import { billingActions, preChatActions } from '@/redux/modules';
import TicketCardInfo from '@/components/Tickets/TicketCardInfo';
import TicketBarcodeCard from '@/components/Tickets/TicketBarcodeCard';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { differenceInCalendarDays } from 'date-fns';
import {
  XRAY_CHANGE_PRODUCT_GATEWAY_USER_REQUEST,
  XRAY_ENABLED_BARCODE_DISPLAY,
  XRAY_ENABLED_DISCOUNT_RETENTION,
  XRAY_ENABLED_DISCOUNT_SAVERS,
  XRAY_ENABLED_FINGERPRINT,
  XRAY_ENABLED_NEW_TICKET_MODAL,
  XRAY_ENABLED_OPTION_TO_SELECT_PAYPAL_ACCOUNT,
  XRAY_ENABLED_PAYMENT_BY_CREDIT_CARD,
  XRAY_ENABLED_PAYMENT_BY_TICKET,
  XRAY_ENABLED_PAYMENT_METHOD_FILTER,
  XRAY_ENABLED_REDIRECT_TO_TICKET_MODAL,
  XRAY_ENABLED_SWITCH_PAYMENT_METHOD_BY_PAYPAL,
  XRAY_INSTALLMENTS_MODAL,
  XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL,
  XRAY_LIMIT_TIME_IN_SECONDS_TO_DISPLAY_PIX_CODE,
  XRAY_PIX_PAYMENT_GATEWAY,
} from '@/config/GrowthBook/constants';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import { IconCheck, IconInfo } from 'gatorcomponents';
import { DiscountBlock } from '../../components/DiscountBlock';
import { getPaymentMethod } from '@/redux/actions/paymentMethod';

const PaymentModal = ({
  onCloseClick,
  productNameText = '',
  paymentCycleText = '',
  invoiceId = '',
  renovationAmountText = '',
  dueDate = '',
  displayOnlyInvoiceId = true,
  originCalling = '',
  onFinishPixTimer = null,
  hasQRCodeGenerated = false,
  onGenerateErrorPix = null,
  onPaymentSuccessPix = null,
  qrCodeGenerated = '',
  pixCodeGenerated = '',
  isExceedsRetriesCC = false,
  onRedirectTicket = null,
  discount = null,
  totalDiscounts = '',
  hasSuccessSplitInvoice = false,
  handleUpgradeSitelock,
  showCreditCardCheckbox = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;
  const { billing: billingLocale } = useLocale();

  const { requestDiagnostic } = useDiagnostic();

  const { enabledPaymentMethods } = useSelector(state => state.billing.paymentMethods);

  const enabledPaymentMethodFilter = useFeatureIsOn(XRAY_ENABLED_PAYMENT_METHOD_FILTER);
  const enabledPaymentByPayPal = useFeatureIsOn(XRAY_ENABLED_SWITCH_PAYMENT_METHOD_BY_PAYPAL) && (enabledPaymentMethodFilter ? hasPaymentEnabled(enabledPaymentMethods, LIST_PAYPAL_METHODS) : true);
  const enabledCreditCard = useFeatureIsOn(XRAY_ENABLED_PAYMENT_BY_CREDIT_CARD);
  const enabledTicket = useFeatureIsOn(XRAY_ENABLED_PAYMENT_BY_TICKET);
  const enabledCreditCardPaymentAsDefault = useFeatureIsOn(XRAY_CHANGE_PRODUCT_GATEWAY_USER_REQUEST);

  const enabledPaymentByTicket = enabledPaymentMethodFilter ? enabledTicket && hasPaymentEnabled(enabledPaymentMethods, LIST_BOLETO_METHODS) : enabledTicket;
  const enabledPaymentByCreditCard = enabledPaymentMethodFilter ? enabledCreditCard && hasPaymentEnabled(enabledPaymentMethods, LIST_CREDIT_CARD_METHODS) : enabledCreditCard;
  const enabledPaymentByPix = useFeatureIsOn(XRAY_PIX_PAYMENT_GATEWAY) && (enabledPaymentMethodFilter ? hasPaymentEnabled(enabledPaymentMethods, [PIX]) : true);
  const isCreditCardInstallmentsEnabled = useFeatureIsOn(XRAY_INSTALLMENTS_MODAL);

  const {
    IFRAME_URL_FINGERPRINT,
    MERCHANT_ID,
    ORG_ID,
  } = config.BRASPAG_CONFIG;

  const clientInfo = useSelector(state => state.summary);
  const providerIdentifier = `${COUNTRY}${clientInfo.id}${Date.now()}`;
  const enabledFingerprint = useFeatureIsOn(XRAY_ENABLED_FINGERPRINT);
  const providerMerchantIdProviderIdentifier = `${MERCHANT_ID}${providerIdentifier}`;
  const enabledInvoiceDetails = true;
  const enabledChangePayPalAccount = useFeatureIsOn(XRAY_ENABLED_OPTION_TO_SELECT_PAYPAL_ACCOUNT);

  const enabledNewTicketModal = useFeatureIsOn(XRAY_ENABLED_NEW_TICKET_MODAL);
  const enabledBarcodeDisplay = useFeatureIsOn(XRAY_ENABLED_BARCODE_DISPLAY);
  const enabledTimeToDisplayQRCode = useFeatureIsOn(XRAY_LIMIT_TIME_IN_SECONDS_TO_DISPLAY_PIX_CODE);
  const enabledRedirectToTickeModal = useFeatureIsOn(XRAY_ENABLED_REDIRECT_TO_TICKET_MODAL);


  const enabledLimitDateNewPixLabel = useFeatureIsOn(XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL);
  const limitDateNewPixLabel = useFeatureValue(XRAY_LIMIT_DATE_TO_DISPLAY_NEW_PIX_LABEL);
  const limitDateToDisplayNewPixLabel = enabledLimitDateNewPixLabel && limitDateNewPixLabel.limitDate != null ? limitDateNewPixLabel.limitDate : null;

  const enabledLimitTimeToDisplayPixCode = useFeatureIsOn(XRAY_LIMIT_TIME_IN_SECONDS_TO_DISPLAY_PIX_CODE);
  const limitTimeToDisplayPixCode = useFeatureValue(XRAY_LIMIT_TIME_IN_SECONDS_TO_DISPLAY_PIX_CODE);
  const limitTimeInSecondsToDisplayPixCode = enabledLimitTimeToDisplayPixCode && limitTimeToDisplayPixCode.time_in_secounds != null ? limitTimeToDisplayPixCode.time_in_secounds : null;
  const enabledNewPixChipLabel = limitDateToDisplayNewPixLabel != null && differenceInCalendarDays(new Date(`${limitDateToDisplayNewPixLabel} 00:00:00`), new Date()) > 0;

  const { manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale } = billingLocale;
  const titleLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.titleLabel);
  const selectPaymentMethodLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.selectPaymentMethodLabel);
  const generateTicketLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.generateTicketLabel);
  const infoPaymentByTicketLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByTicketLabel);
  const infoPaymentByPicPayLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByPicPayLabel);
  const infoPaymentByCreditCardLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByCreditCardLabel);
  const infoPaymentByCreditCardTitle = useState(manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByCreditCardTitle);
  const selectCardLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.selectCardLabel);
  const infoAddNewCreditCardLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.infoAddNewCreditCardLabel);
  const infoPaymentByPixLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.infoPaymentByPixLabel);
  const payButtonByPixLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.payButtonByPixLabel);
  const changeNextPaymentMethodLabel = manageSubscriptionPaymentInvoiceModalLocale.changeNextPaymentMethod;

  const [hasQRCodePix, setQRCodePix] = useState(hasQRCodeGenerated);
  const [hasBarcode, setBarcode] = useState(false);
  const [dataTicket, setDataTicket] = useState({});
  const [qrCode, setQRCode] = useState(qrCodeGenerated);
  const [pixCode, setPixCode] = useState(pixCodeGenerated);
  const [option, setOption] = useState(hasQRCodeGenerated ? PaymentMethods.PIX : '');
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceDetailsExpanded, setIinvoiceDetailsExpanded] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER || OriginCalling.SITELOCK_UPGRADE;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;

  const isCreditCardSelected = () => option === PaymentMethods.CREDIT_CARD;

  const isTicketSelected = () => option === PaymentMethods.TICKET;

  const isPayPalSelected = () => option === PaymentMethods.PAY_PAL;

  const isPixSelected = () => option === PaymentMethods.PIX;

  const [fingerBlock, setFingerBlock] = useState(null);
  const enabledDiscountSavers = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_SAVERS);
  const enabledDiscountRetention = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_RETENTION);

  const isEnabledDiscountOfferBanner = () => {
    if (discount) {
      if (enabledDiscountSavers && discount.type === DiscountType.SAVERS35OFF) {
        return true;
      }
      if (enabledDiscountRetention && discount.type === DiscountType.RETENTION) {
        return true;
      }
    }
    return false;
  };

  const enabledDiscountOfferBanner = isEnabledDiscountOfferBanner();

  const changePaymentMethod = (paymentMethod) => {
    dispatch(getPaymentMethod(paymentMethod, invoiceId));
  };

  useEffect(() => {
    if (enabledFingerprint) {
      setFingerBlock(
        <Styles.ContentFingerprint
          src={`${IFRAME_URL_FINGERPRINT}/fp/tags?org_id=${ORG_ID}&session_id=${providerMerchantIdProviderIdentifier}`}
          id="fp-iframe"
          title="fp"
        />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToSwitchPaymentMethod = (code, qrCode) => {
    if ((code || code !== '') && (qrCode || qrCode !== '')) {
      setQRCode(qrCode);
      setPixCode(code);
      setQRCodePix(true);
    } else {
      onGenerateErrorPix(true);
    }
    setLoading(false);
  };

  const generateQRCodePix = () => {
    setLoading(true);
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('3_pago_pix');
    }

    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('3_pago_pix');
    }

    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_pago_pix');
    }

    dispatch(invoicesActions.invoices.generatePix.request(invoiceId, redirectToSwitchPaymentMethod));
    changePaymentMethod(paymentMethod);
  };

  useEffect(() => {
    loadReCaptcha();
  }, []);

  const getCallingOrigin = () => {
    if (isCallingModalBySubscription()) {
      return PaypalActionTypes.CALLING_BY_PAYMENT_MODAL;
    }
    if (isCallingModalBySubscriptionList()) {
      return PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS;
    }
    return PaypalActionTypes.CALLING_BY_MANAGER_INVOICES;
  };

  const handleOnCloseClick = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    if (hasQRCodePix && isPixSelected() && isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('4_fechar');
    }

    if (!hasQRCodePix && !isPixSelected() && isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('2_opção_fechar');
    }

    if (!isPixSelected() && !hasQRCodePix && isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_opção_fechar');
    }

    if (hasQRCodePix && isPixSelected() && isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('4_fechar');
    }

    if (hasQRCodePix && isPixSelected() && isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('4_fechar');
    }

    onCloseClick && onCloseClick(event);
  };

  const {
    manageSubscription: manageSubscriptionLocale,
    paymentMethods: paymentMethodsLocale,
    manageSubscriptionPaymentInvoiceModal: labelsLocale,
    splitPaymentModal: splitPaymentModalLocale,
  } = billingLocale;


  const clickCreditCard = () => {
    setOption(PaymentMethods.CREDIT_CARD);

    if (!isBRServer) {
      setPaymentMethod(CREDIT_CARD_ES);
    }
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('2_selecionar_cartão');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('2_selecionar_cartão');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_selecionar_cartão');
    }
  };

  const getTypeTicket = () => {
    if (isCLServer || isCOServer) {
      return BOLETO_CL_CO;
    }

    if (isMXServer) {
      return BOLETO_MX;
    }

    return BOLETO;
  };

  const getTypePIX = () => PIX;

  const onPaymentPayPalExecuted = (event, route) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('3_pago_paypal');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('3_pago_paypal');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_pago_paypal');
    }
    if (event && event.status === PAYMENT_STATUS.SUCCESS) {
      if (originCalling === OriginCalling.SITELOCK_UPGRADE) {
        handleUpgradeSitelock();
        return;
      }
      requestDiagnostic('paymentModal1');
      if (isCallingModalBySubscriptionList) {
        dispatch(billingActions.subscriptions.request());
      }
      history.push(route);
    }
  };

  const onPaymentCreditCardExecuted = (event, route) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('3_pago_cc');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('3_pago_cc');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_pago_cc');
    }
    if (event && (event.status === PAYMENT_STATUS.SUCCESS || event.status === PAYMENT_STATUS.PENDING_ANALYSIS)) {
      if (originCalling === OriginCalling.SITELOCK_UPGRADE) {
        handleUpgradeSitelock();
      } else {
        requestDiagnostic('paymentModal2');
        if (isCallingModalBySubscriptionList) {
          dispatch(billingActions.subscriptions.request());
          history.push(route);
        }
        if (isCallingModalByInvoice) {
          window.location.href = route;
        }
      }
    }
  };

  const clickTiket = () => {
    const typeTicket = getTypeTicket();
    setOption(PaymentMethods.TICKET);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('2_selecionar_boleto');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('2_selecionar_boleto');
    }

    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_selecionar_boleto');
    }

    setPaymentMethod(typeTicket);
  };


  const clickPix = () => {
    const typePix = getTypePIX();
    setOption(PaymentMethods.PIX);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('2_selecionar_pix');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('2_selecionar_pix');
    }

    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_selecionar_pix');
    }
    setPaymentMethod(typePix);
  };

  const clickPayPal = () => {
    setOption(PaymentMethods.PAY_PAL);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('2_selecionar_paypal');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('2_selecionar_paypal');
    }

    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_selecionar_paypal');
    }
  };

  const setAbleToTrustReactivation = () => {
    dispatch(preChatActions.trustReactivation.billingGenerated(true));
  };

  const handleRedirectToBoleto = async ({ urlBoleto }) => {
    dispatch(checkBoletoUserData(invoiceId)).then((response) => {
      if (!response || response.data === undefined || !response.data.success) {
        dispatch(enqueueSnackbar(manageSubscriptionLocale.processBoleto, notifierError));
        setLoading(false);
        return;
      }

      setLoading(false);
      setAbleToTrustReactivation();

      if (enabledRedirectToTickeModal && onRedirectTicket) {
        onRedirectTicket(urlBoleto);
        if (isCallingModalByInvoice()) {
          handleOnCloseClick();
        }
      } else {
        window.open(urlBoleto, '_blank');
        handleOnCloseClick();
      }
    });
  };

  const handleGetBoletoURL = async () => {
    if (isBRServer) {
      setLoading(true);
      dispatch(getBoletoUrl(invoiceId)).then((response) => {
        const dataCheck = response && response.dataCheck;
        if (dataCheck !== undefined && dataCheck.data.result) {
          if (enabledNewTicketModal) {
            setAbleToTrustReactivation();
            setBarcode(true);
            setDataTicket(dataCheck.data);
          } else {
            handleRedirectToBoleto({
              urlBoleto: dataCheck.data.url,
            });
          }
        }
      });
    } else {
      window.open(BOLETO_URL(invoiceId));
    }
  };

  const handleGetBoletoURLES = async () => {
    setLoading(true);

    dispatch(payTicketInvoiceForES(invoiceId)).then((response) => {
      if (response.type === RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES) {
        window.open(response.url, '_blank');
        setAbleToTrustReactivation();
        handleOnCloseClick();
      }
      if (response.type === ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES) {
        dispatch(enqueueSnackbar('notifyFailMessage', notifierError));
      }
    });
  };

  const printTicket = () => {
    handleRedirectToBoleto({
      urlBoleto: dataTicket.url,
    });
  };

  const saveTicketAsPDF = () => {
    if (enabledRedirectToTickeModal && onRedirectTicket) {
      onRedirectTicket(dataTicket.pdf_url, true);
      if (isCallingModalByInvoice()) {
        handleOnCloseClick();
      }
    } else {
      window.open(dataTicket.pdf_url, '_blank');
      handleOnCloseClick();
    }
  };

  const payInvoice = () => {
    invoiceUnpaidCardTagManager(paymentMethod);
    changePaymentMethod(paymentMethod);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('3_gerar_boleto');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('3_gerar_boleto');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_gerar_boleto');
    }
    switch (paymentMethod) {
      case BOLETO:
      case GATORPAY_BOLETO:
        handleGetBoletoURL();
        break;
      case BOLETO_CL_CO:
      case BOLETO_MX:
        handleGetBoletoURLES();
        break;

      default:
        // eslint-disable-next-line no-console
        !IS_PROD && console.error(`Payment method not implemented ${paymentMethod}`);
        break;
    }
  };

  const setDataInvoice = (data) => {
    setInvoiceDetails(data);
  };
  const handleExpand = () => {
    if (invoiceId && !Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, setDataInvoice)).then(() => {
      });
    }
    setIinvoiceDetailsExpanded(!invoiceDetailsExpanded);

    if (!invoiceDetailsExpanded) {
      if (isCallingModalBySubscription()) {
        subscriptionAnalytics.executePaymentClick('2_descricao_detalhada');
      }
      if (isCallingModalByInvoice()) {
        subscriptionAnalytics.executePaymentInvoiceManagerClick('2_descricao_detalhada');
      }
      if (isCallingModalBySubscriptionList()) {
        subscriptionAnalytics.executePaymentInSubscriptionsListClick('2_descricao_detalhada');
      }
    }
  };

  const invoiceResume = (
    <Styles.ContentData>
      {!displayOnlyInvoiceId && (
        <>
          <Styles.Item>
            <Styles.Label data-testid="subscription-renewaldata-product-name-label">
              {locale(manageSubscriptionLocale.productNameLabel)}
            </Styles.Label>
            <Styles.Text data-testid="subscription-renewaldata-product-name-text">
              {productNameText}
            </Styles.Text>
          </Styles.Item>
          <Styles.Item>
            <Styles.Label data-testid="subscription-payment-cycle-label">
              {manageSubscriptionLocale.paymentCycleLabel}
            </Styles.Label>
            <Styles.Text data-testid="subscription-payment-cycle-text">
              {paymentCycleText}
            </Styles.Text>
          </Styles.Item>
        </>
      )}
      <Styles.BlockItemResume>
        {displayOnlyInvoiceId && (
          <Styles.Item>
            <Styles.Label data-testid="subscription-payment-invoice-label">
              {locale(manageSubscriptionLocale.invoiceLabel)}
            </Styles.Label>
            <Styles.Text data-testid="subscription-payment-invoice-name-text">
              {invoiceId}
            </Styles.Text>
          </Styles.Item>
        )}
        <Styles.Item>
          <Styles.Label data-testid="subscription-due-date-label">
            {labelsLocale.duedate}
          </Styles.Label>
          <Styles.Text data-testid="subscription-due-date-text">
            {dueDate}
          </Styles.Text>
        </Styles.Item>
      </Styles.BlockItemResume>
      <Styles.BlockItemResume>
        {totalDiscounts !== '' && (
          <Styles.ItemDiscount>
            <Styles.Label data-testid="total-discount-label">
              {labelsLocale.discount}
            </Styles.Label>
            <Styles.Text data-testid="total-discount-text">
              {formatCurrency(totalDiscounts)}
            </Styles.Text>
          </Styles.ItemDiscount>
        )}
        {!enabledDiscountOfferBanner && (
          <Styles.ItemDiscount>
            <Styles.Label data-testid="subscription-amount-label">
              {manageSubscriptionLocale.installmentsAmountLabel}
            </Styles.Label>
            <Styles.Text data-testid="subscription-amount-text">
              {renovationAmountText}
            </Styles.Text>
          </Styles.ItemDiscount>
        )}
      </Styles.BlockItemResume>
      {enabledDiscountOfferBanner && (
        <Styles.BlockItemResume>
          <Styles.ItemDiscount>
            <DiscountBlock
              discount={discount}
              loading={loading}
              installmentsAmountText={manageSubscriptionLocale.installmentsAmountLabel}
              testId="subscription-renewaldata-discount"
              enabledStrongText={false}
              renovationAmountText={discount && discount.originalAmount ? discount.originalAmount : renovationAmountText}
            />
          </Styles.ItemDiscount>
        </Styles.BlockItemResume>
      )
      }
    </Styles.ContentData>
  );


  return (
    <Dialog
      fullWidth
      open
      onClose={handleOnCloseClick}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          maxWidth: '800px',
        },
      }
      }
      fullScreen={isMobile}
    >
      <Styles.Content>
        <Styles.ContentCloseButton>
          <IconButton data-testid="btn-close-modal" aria-label="Fechar" onClick={handleOnCloseClick}>
            <Close />
          </IconButton>
        </Styles.ContentCloseButton>
        <Styles.Header>
          <Styles.Title data-testid="subscription-change-payment-method-title">{titleLabel}</Styles.Title>
        </Styles.Header>
        <Styles.Data hasInfo={hasSuccessSplitInvoice}>
          {hasSuccessSplitInvoice && (
            <>
              <Styles.ContentInfoSucess hasSplitInfo={hasSuccessSplitInvoice}>
                <Styles.ContentIcon>
                  <IconCheck
                    size="md"
                    color="activeMedium"
                  />
                </Styles.ContentIcon>
                <Styles.DescriptionInfoSuccess data-testid="info">
                  {manageSubscriptionPaymentInvoiceModalLocale.successInvoiceGenerate}
                </Styles.DescriptionInfoSuccess>
              </Styles.ContentInfoSucess>
              <Styles.ContentInfoSplitPayment>
                <Styles.ContentIcon>
                  <IconInfo
                    size="md"
                    color="primaryMedium"
                  />
                </Styles.ContentIcon>
                <Styles.DescriptionInfoSplitPayment data-testid="info">
                  {splitPaymentModalLocale.alertDescription}
                </Styles.DescriptionInfoSplitPayment>
              </Styles.ContentInfoSplitPayment>
            </>
          )}
          {enabledInvoiceDetails && (
            <Styles.ContentInvoiceDetails>
              <Accordion
                title={locale(labelsLocale.invoiceDetailsTitle)}
                expanded={invoiceDetailsExpanded}
                enabledAutoHeight
                onToggleClick={handleExpand}
                variant="clean"
                invoiceResume={invoiceResume}
              >
                {!invoiceDetails.id && (
                  <Loader />
                )}
              </Accordion>
              {invoiceDetails.id && invoiceDetailsExpanded && (
                <InvoiceDetails invoice={invoiceDetails} variant="clean" />
              )}
            </Styles.ContentInvoiceDetails>
          )}
          {!hasQRCodePix && !hasBarcode && (
            <Styles.ContentPaymentSelector>
              {selectPaymentMethodLabel}
              <Styles.ContentOptionsInSelectord>
                <Styles.BlockoOptionItem>
                  {enabledPaymentByCreditCard && (
                    <Styles.OptionItem
                      data-testid="subscription-manage-change-payment-method-credit-card-option"
                      checked={isCreditCardSelected()}
                      onClick={clickCreditCard}
                    >
                      <Styles.OptionItemWrapper>
                        <Styles.OptionRadio checked={isCreditCardSelected()}>
                          <Radio
                            size="medium"
                            checked={isCreditCardSelected()}
                            inputId="CancelOptions."
                            variant="primary"
                            testId="credit-card-option-selection"
                          />
                        </Styles.OptionRadio>
                        <Styles.OptionContent>
                          <Styles.OptionLabel
                            checked={isCreditCardSelected()}
                            data-testid="subscription-manage-change-payment-method-credit-card-option-label"
                          >
                            {paymentMethodsLocale.creditCard}
                          </Styles.OptionLabel>

                        </Styles.OptionContent>
                      </Styles.OptionItemWrapper>
                    </Styles.OptionItem>
                  )}
                  {enabledPaymentByPix && (
                    <Styles.OptionItem
                      data-testid="subscription-manage-change-payment-method-pix-option"
                      onClick={clickPix}
                      checked={isPixSelected()}
                    >
                      <Styles.OptionItemWrapper>
                        <Styles.OptionRadio checked={isPixSelected()}>
                          <Radio
                            variant="primary"
                            size="medium"
                            checked={isPixSelected()}
                            onChange
                            testId="pix-option-selection"
                            inputId="CancelOptions."
                          />
                        </Styles.OptionRadio>
                        <Styles.OptionContent>
                          <Styles.OptionLabel
                            checked={isPixSelected()}
                            data-testid="subscription-manage-change-payment-method-pix-option-label"
                          >
                            {paymentMethodsLocale.pix}
                            {enabledNewPixChipLabel && (
                              <Styles.Chip>
                                <Chip
                                  data-testid="new-pix-label"
                                  label={paymentMethodsLocale.new}
                                  primaryColor={isPixSelected() ? '#1D5297' : '#2E93EE'}
                                  variant="straightEdges"
                                  weight="bold"
                                  uppercase
                                />
                              </Styles.Chip>
                            )}
                          </Styles.OptionLabel>
                        </Styles.OptionContent>
                      </Styles.OptionItemWrapper>

                    </Styles.OptionItem>
                  )}
                </Styles.BlockoOptionItem>
                <Styles.BlockoOptionItem>
                  {enabledPaymentByTicket && (
                    <Styles.OptionItem
                      data-testid="subscription-manage-change-payment-method-ticket-option"
                      onClick={clickTiket}
                      checked={isTicketSelected()}
                    >
                      <Styles.OptionItemWrapper>
                        <Styles.OptionRadio checked={isTicketSelected()}>
                          <Radio
                            variant="primary"
                            size="medium"
                            checked={isTicketSelected()}
                            onChange
                            testId="ticket-option-selection"
                            inputId="CancelOptions."
                          />
                        </Styles.OptionRadio>
                        <Styles.OptionContent>
                          <Styles.OptionLabel
                            checked={isTicketSelected()}
                            data-testid="subscription-manage-change-payment-method-ticket-option-label"
                          >
                            {paymentMethodsLocale.ticket}
                          </Styles.OptionLabel>

                        </Styles.OptionContent>
                      </Styles.OptionItemWrapper>
                    </Styles.OptionItem>
                  )}
                  {enabledPaymentByPayPal && (
                    <Styles.OptionItem
                      data-testid="subscription-manage-change-payment-method-pay-pal-option"
                      checked={isPayPalSelected()}
                      onClick={clickPayPal}
                    >
                      <Styles.OptionItemWrapper>
                        <Styles.OptionRadio checked={isPayPalSelected()}>
                          <Radio
                            size="medium"
                            checked={isPayPalSelected()}
                            inputId="CancelOptions."
                            testId="paypal-option-selection"
                            variant="primary"
                          />
                        </Styles.OptionRadio>
                        <Styles.OptionContent>
                          <Styles.OptionLabel
                            checked={isPayPalSelected()}
                            data-testid="subscription-manage-change-payment-method-pay-pal-option-label"
                          >
                            {paymentMethodsLocale.paypal}
                          </Styles.OptionLabel>
                        </Styles.OptionContent>
                      </Styles.OptionItemWrapper>
                    </Styles.OptionItem>
                  )}
                </Styles.BlockoOptionItem>
              </Styles.ContentOptionsInSelectord>

            </Styles.ContentPaymentSelector>
          )}
          {option !== '' && (
            <Separator
              data-testid="subscription-payment-separator"
            />
          )}
        </Styles.Data>
        {hasBarcode && (
          <TicketBarcodeCard
            onPrintTicket={printTicket}
            barcode={dataTicket.barcode}
            digitableLine={dataTicket.digitable_line}
            onSaveTicketAsPDF={saveTicketAsPDF}
            enabledBarcodeDisplay={enabledBarcodeDisplay}
          />
        )}
        {isTicketSelected() && (
          <>
            {enabledNewTicketModal && !hasBarcode && (
              <TicketCardInfo />
            )}
            {!enabledNewTicketModal && (
              <Styles.WrapperInfo data-testid="text-info-ticket">
                {infoPaymentByTicketLabel}
              </Styles.WrapperInfo>
            )}
            {!hasBarcode && (
              <Styles.Controls>
                <Styles.ControlButton>
                  <Button
                    testId="subscription-payment-generate-invoce-ticket-button"
                    onClick={payInvoice}
                    label={loading
                      ? (
                        <>
                          <CircularProgress color="#00000" size={16} data-id="to-ticket-loader" />
                          {'   '}
                          {generateTicketLabel}
                        </>
                      )

                      : generateTicketLabel}
                  />
                </Styles.ControlButton>
              </Styles.Controls>
            )}
          </>
        )}
        {isPayPalSelected() && (
          <>
            {!enabledChangePayPalAccount && (
              <Styles.WrapperInfo data-testid="text-info-paypal">
                {infoPaymentByPicPayLabel}
              </Styles.WrapperInfo>
            )}
            <div data-testid="info-paypal">
              <PaypalDialog
                open
                enabledChangePayPalAccount={enabledChangePayPalAccount}
                invoiceId={invoiceId}
                showResumeData={false}
                enabledChangeAccount
                close={handleOnCloseClick}
                onPaymentExecuted={onPaymentPayPalExecuted}
                isSubscriptionsPage
                labelsLocale={labelsLocale}
                callingOrigin={getCallingOrigin()}
              />
            </div>
          </>
        )}
        {isCreditCardSelected() && (
          <Styles.WrapperCreditCard>
            <Styles.WrapperInfo data-testid="text-info-credit-card-title">
              {infoPaymentByCreditCardTitle}
              <Styles.DescriptionInfo data-testid="text-info-credit-card-info">
                {infoPaymentByCreditCardLabel}
              </Styles.DescriptionInfo>

            </Styles.WrapperInfo>
            <CreditCardDialog
              invoiceId={invoiceId}
              newPaymentMethod={paymentMethod}
              showResumeData={false}
              displayActionButton
              selectCardLabel={selectCardLabel}
              infoAddNewCreditCardLabel={infoAddNewCreditCardLabel}
              changeNextPaymentMethodLabel={changeNextPaymentMethodLabel}
              showCreditCardCheckbox={showCreditCardCheckbox && enabledCreditCardPaymentAsDefault}
              onClose={handleOnCloseClick}
              callingOrigin={getCallingOrigin()}
              isCreditCardInstallmentsEnabled={isCreditCardInstallmentsEnabled}
              subscriptionAnalytics={subscriptionAnalytics}
              onPaymentExecuted={onPaymentCreditCardExecuted}
              isExceedsRetriesCC={isExceedsRetriesCC}
              providerIdentifier={enabledFingerprint && providerIdentifier}
            />
          </Styles.WrapperCreditCard>
        )}
        {isPixSelected() && (
          <>
            <Styles.WrapperInfoPix data-testid="text-info-pix">
              {infoPaymentByPixLabel}
            </Styles.WrapperInfoPix>
            {hasQRCodePix && (
              <QRCodePixCard
                onFinishPixTimer={onFinishPixTimer}
                invoiceId={invoiceId}
                limitTimeInSecondsToDisplayPixCode={enabledTimeToDisplayQRCode ? limitTimeInSecondsToDisplayPixCode : null}
                onPaymentSuccessPix={onPaymentSuccessPix}
                pixCode={pixCode}
                qrCode={qrCode}
                originCalling={originCalling}
              />
            )}

            {!hasQRCodePix && (
              <>
                <PixCard originCalling={originCalling} />
                <Styles.Controls isPix>
                  <Styles.ControlButton>
                    {!loading && (
                      <Button
                        testId="subscription-payment-generate-invoce-pix-button"
                        onClick={() => { generateQRCodePix(); }}
                        label={payButtonByPixLabel}
                      />
                    )}
                    {loading && (
                      <CircularProgress size={22} data-id="to-pix-loader" />
                    )}
                  </Styles.ControlButton>
                </Styles.Controls>
              </>
            )}
          </>
        )}
      </Styles.Content>
      {enabledFingerprint && fingerBlock}
    </Dialog>
  );
};

PaymentModal.propTypes = PaymentModalProps;

export default PaymentModal;
