import React from 'react';
import * as Styles from './WizardThemeCard.styles';
import { Button as LinkButton } from '@/pages/common/Button';
import { SelectChip } from '@/pages/common/v1/SelectChip';
import { wizardLocale } from '@/pages/sites/WizardHandler/WizardHandler.locale.jsx';

const WizardThemeCard = ({
  title, image, handleChangeTheme, selected, cardValue, handleOnOpenThemeModal,
}) => {
  const handleChange = (selectedCard) => {
    handleChangeTheme && handleChangeTheme(selectedCard);
  };
  return (
    <Styles.Wrapper>
      <Styles.Title data-testid="theme-card-title">{title}</Styles.Title>

      <Styles.ThemeImage src={image} />

      <Styles.ButtonsWrapper>
        <LinkButton
          onClick={() => handleOnOpenThemeModal(cardValue)}
          size="small"
          variant="link"
          label={wizardLocale.tools.wordPressInstall.theme.see}
          testId="theme-card-modal-button"
        />

        <SelectChip
          onChange={() => handleChange(cardValue)}
          title={selected ? wizardLocale.tools.wordPressInstall.theme.selected : wizardLocale.tools.wordPressInstall.theme.use}
          checked={selected}
          value={cardValue}
          justify="center"
          id={cardValue}
          testId="theme-card-select-button"
        />
      </Styles.ButtonsWrapper>
    </Styles.Wrapper>
  );
};

export default WizardThemeCard;
