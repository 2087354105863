import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { backupActions } from '.';
import { BackupActionTypes, BackupGenerationStatus } from './backup.types';
import { AWS_GATOR_SOLUTIONS_URL, COUNTRY } from '@/config';
import { preChatActions } from '../preChat';
import { backupAnalytics } from '@/analytics';
import { isBRServer, mockoonWarning } from '@/utils/Validators/validation';
import Cookies from 'js-cookie';
import { authActions } from '../auth';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';

function* generateBackup(action) {
  const { token } = yield select(state => state.auth);
  const { redirectFromPreChat } = yield select(state => state.backup);
  const { payload } = action;

  yield put(backupActions.backupGeneration.loading(true));
  try {
    const request = {
      url: `/api/v3/backup/generate/${payload.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-brand': COUNTRY,
      },
    };

    const response = yield call(api.whmcs.get, request);
    if (response.data && response.data.data) {
      window.open(response.data.data);
    }

    backupAnalytics.backupGenerationSuccess();
    yield put(backupActions.backupGeneration.success(BackupGenerationStatus.SUCCESS));
  } catch (e) {
    backupAnalytics.backupGenerationError();
    yield put(backupActions.backupGeneration.failure(BackupGenerationStatus.FAIL));
  }

  if (redirectFromPreChat) {
    yield put(preChatActions.preChat.setScreen('problemSolved'));
    yield put(preChatActions.preChat.setOpen(true));
    yield put(backupActions.set.redirectFromPreChat(false));
  }

  yield put(backupActions.backupGeneration.loading(false));
}

function* getLog(action) {
  const { token } = yield select(state => state.auth);
  const { payload } = action;
  const { id } = payload;

  yield put(backupActions.backupLog.loading(true));
  try {
    const request = {
      url: `/api/v3/backup/${id}?limit=20`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = yield call(api.whmcs.get, request);
    if (response) {
      yield put(backupActions.backupLog.set(response.data.data));
    }
    yield put(backupActions.backupLog.success());
  } catch (e) {
    yield put(backupActions.backupLog.failure());
  }

  yield put(backupActions.backupLog.loading(false));
}

function* getInodes(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { id } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'getInodes' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${id}/allowed-backup`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(backupActions.inodes.loading(true));
  try {
    const response = yield call(api.aws.get, request);
    yield put(backupActions.inodes.set(response.data.available));
    yield put(backupActions.inodes.success());
  } catch (e) {
    yield put(backupActions.inodes.failure());
  }
  yield put(backupActions.inodes.loading(false));
}


function* sagas() {
  yield takeLatest(BackupActionTypes.REQUEST_BACKUP_GENERATION, generateBackup);
  yield takeLatest(BackupActionTypes.REQUEST_BACKUP_LOG, getLog);
  yield takeLatest(BackupActionTypes.REQUEST_INODES_STATUS, getInodes);
}

export default sagas;
