import React, { useCallback, useMemo } from 'react';
import {
  IconDetailedOtherSquare,
  IconJoomla,
  IconOpenCart,
  IconOtherMiniSquares,
  IconMagento,
  IconDrupal,
  IconMoodle,
  IconFlarum,
} from '@/icons';
import { useWizardToolsLocale } from './useWizardTools.locale';
import { getURLCpanel } from '@/config/urls/cpanelUrls';
import {
  joomlaTool, wordpressTool, woocommerceTool, sitebuilderTool, othersTool, magentoTool, opencartTool, yithTool,
} from '@/media/siteBuilder/wondersuite';
import { wizardEnum } from '@/pages/sites/WizardHandler/WizardHandler.enum';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CPANEL_SSO_PORTAL, CHARLIE_ENABLE_START_PLAN_AT_SITESWIZARD } from '@/config/GrowthBook/constants';
import { isBRServer } from '@/utils/Validators/validation';

const useWizardTools = ({
  activeStep,
  selectedSite,
  setToolSelected,
  handleContinueToNextStep,
  setUserSelectedWondersuiteTrial,
  setUserSelectedUseEcommercePlus,
  selectedPlan,
}) => {
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);
  const allowStartPlan = useFeatureIsOn(CHARLIE_ENABLE_START_PLAN_AT_SITESWIZARD);

  const mountSoftaculousUrl = externalId => `${getURLCpanel({ externalId, enableCpanelSSO })}&app=Softaculous_Home`;

  const {
    toolsNames,
  } = wizardEnum;

  const setOthersToolsSelected = useCallback(() => {
    setToolSelected('softaculous');
    const softaculousUrl = mountSoftaculousUrl(selectedSite.externalId);
    window.open(softaculousUrl, '_blank').focus();
    handleContinueToNextStep(activeStep + 1, false);
  }, [activeStep, selectedSite, setToolSelected, handleContinueToNextStep]);

  const cardIcons = useMemo(() => ({
    joomla: {
      icon: <IconJoomla />, title: useWizardToolsLocale.cardIcons.joomla,
    },
    drupal: {
      icon: <IconDrupal />, title: useWizardToolsLocale.cardIcons.drupal,
    },
    moodle: {
      icon: <IconMoodle />, title: useWizardToolsLocale.cardIcons.moodle,
    },
    flarum: {
      icon: <IconFlarum />, title: useWizardToolsLocale.cardIcons.flarum,
    },
    magento: {
      icon: <IconMagento />, title: useWizardToolsLocale.cardIcons.magento,
    },
    openCart: {
      icon: <IconOpenCart />, title: useWizardToolsLocale.cardIcons.openCart,
    },
    others: {
      icon: <IconOtherMiniSquares />, title: useWizardToolsLocale.cardIcons.others,
    },
  }), []);

  const mountTools = useMemo(() => {
    const isStartPlan = selectedPlan && selectedPlan.whmcs_product_id === 437 && isBRServer;
    const tools = {
      woocommerce: {
        iconList: [
          { icon: wordpressTool, title: useWizardToolsLocale.wordpress.title },
          { icon: woocommerceTool, title: useWizardToolsLocale.woocommerce.title },
        ],
        description: useWizardToolsLocale.wondersuiteWoocommerce.description,
        title: useWizardToolsLocale.wondersuiteWoocommerce.title,
        subtitle: useWizardToolsLocale.wondersuiteWoocommerce.subtitle,
        items: useWizardToolsLocale.wondersuiteWoocommerce.items,
        titleText: useWizardToolsLocale.woocommerce.titleText,
        warning: useWizardToolsLocale.wondersuiteWoocommerce.warning,
        action: () => {
          setUserSelectedWondersuiteTrial(false);
          setToolSelected(toolsNames.WOOCOMMERCE);
        },
        actionLabel: useWizardToolsLocale.wondersuiteWoocommerce.action,
        testId: 'wordpress-woocommerce-tool',
        isWordPress: true,
      },
      wordpress: {
        featured: false,
        iconList: [
          { icon: wordpressTool, title: useWizardToolsLocale.cardIcons.joomla },
        ],
        title: useWizardToolsLocale.wordpress.title,
        titleText: useWizardToolsLocale.wordpress.titleText,
        description: useWizardToolsLocale.wordpress.description,
        items: useWizardToolsLocale.wordpress.items,
        warning: useWizardToolsLocale.wordpress.warning,
        action: () => setToolSelected(toolsNames.WORDPRESS),
        actionLabel: useWizardToolsLocale.wordpress.action,
        testId: 'wordpress-tool',
        isWordPress: true,
      },
      otherCommerce: {
        iconList: [
          { icon: joomlaTool, title: useWizardToolsLocale.cardIcons.joomla },
          { icon: magentoTool, title: useWizardToolsLocale.cardIcons.magento },
          { icon: opencartTool, title: useWizardToolsLocale.openCart },
          { icon: othersTool, title: useWizardToolsLocale.cardIcons.others },
        ],
        description: useWizardToolsLocale.wondersuiteOtherSite.description,
        title: useWizardToolsLocale.wondersuiteOtherSite.title,
        items: useWizardToolsLocale.wondersuiteOtherSite.items,
        warning: useWizardToolsLocale.wondersuiteOtherSite.warning,
        titleText: useWizardToolsLocale.otherSite.titleText,
        action: setOthersToolsSelected,
        actionLabel: useWizardToolsLocale.wondersuiteOtherSite.action,
        testId: 'other-commerce-tool',
        isWordPress: false,
      },
      otherSite: {
        featured: false,
        icon: <IconDetailedOtherSquare />,
        iconList: [
          { icon: joomlaTool, title: useWizardToolsLocale.cardIcons.joomla },
          { icon: magentoTool, title: useWizardToolsLocale.cardIcons.magento },
          { icon: opencartTool, title: useWizardToolsLocale.openCart },
          { icon: othersTool, title: useWizardToolsLocale.cardIcons.others },
        ],
        title: useWizardToolsLocale.otherSite.title,
        titleText: useWizardToolsLocale.otherSite.titleText,
        description: useWizardToolsLocale.otherSite.description,
        items: useWizardToolsLocale.otherSite.items,
        warning: useWizardToolsLocale.otherSite.warning,
        action: setOthersToolsSelected,
        actionLabel: useWizardToolsLocale.otherSite.action,
        testId: 'softaculous-tool',
        isWordPress: false,
      },
      wondersuiteWoocommerce: {
        iconList: [
          { icon: wordpressTool, title: useWizardToolsLocale.wordpress.title },
          { icon: woocommerceTool, title: useWizardToolsLocale.woocommerce.title },
        ],
        description: useWizardToolsLocale.wondersuiteWoocommerce.description,
        title: useWizardToolsLocale.wondersuiteWoocommerce.title,
        subtitle: useWizardToolsLocale.wondersuiteWoocommerce.subtitle,
        items: useWizardToolsLocale.wondersuiteWoocommerce.items,
        titleText: useWizardToolsLocale.woocommerce.titleText,
        warning: useWizardToolsLocale.wondersuiteWoocommerce.warning,
        action: () => {
          setUserSelectedWondersuiteTrial(false);
          setToolSelected(toolsNames.WOOCOMMERCE);
        },
        actionLabel: useWizardToolsLocale.wondersuiteWoocommerce.action,
        testId: 'wordpress-woocommerce-tool',
        isWordPress: true,
      },
      wondersuiteOtherSite: {
        iconList: [
          { icon: joomlaTool, title: useWizardToolsLocale.cardIcons.joomla },
          { icon: magentoTool, title: useWizardToolsLocale.cardIcons.magento },
          { icon: opencartTool, title: useWizardToolsLocale.openCart },
          { icon: othersTool, title: useWizardToolsLocale.cardIcons.others },
        ],
        description: useWizardToolsLocale.wondersuiteOtherSite.description,
        title: useWizardToolsLocale.wondersuiteOtherSite.title,
        items: useWizardToolsLocale.wondersuiteOtherSite.items,
        warning: useWizardToolsLocale.wondersuiteOtherSite.warning,
        titleText: useWizardToolsLocale.otherSite.titleText,
        action: setOthersToolsSelected,
        actionLabel: useWizardToolsLocale.wondersuiteOtherSite.action,
        testId: 'other-commerce-tool',
        isWordPress: false,
      },
      ecommercePlus: {
        iconList: [
          { icon: wordpressTool, title: useWizardToolsLocale.wordpress.title },
          { icon: woocommerceTool, title: useWizardToolsLocale.woocommerce.title },
          { icon: yithTool, title: useWizardToolsLocale.cardIcons.yith },
        ],
        description: useWizardToolsLocale.ecommercePlus.description,
        preList: useWizardToolsLocale.ecommercePlus.preList,
        titleText: useWizardToolsLocale.ecommercePlus.titleText,
        title: useWizardToolsLocale.ecommercePlus.title,
        subtitle: useWizardToolsLocale.ecommercePlus.subtitle,
        tag: useWizardToolsLocale.ecommercePlus.tag,
        items: useWizardToolsLocale.ecommercePlus.items,
        warning: useWizardToolsLocale.ecommercePlus.warning,
        action: () => {
          setToolSelected(toolsNames.ECOMMERCEPLUS);
          setUserSelectedUseEcommercePlus(true);
        },
        actionLabel: useWizardToolsLocale.ecommercePlus.action,
        testId: 'ecommerce-plus-tool',
        isWordPress: true,
      },
    };


    if (!isStartPlan || (allowStartPlan && isStartPlan)) {
      tools.sitebuilder = {
        featured: false,
        iconList: [
          { icon: sitebuilderTool, title: useWizardToolsLocale.cardIcons.sitebuilder },
        ],
        title: useWizardToolsLocale.sitebuilder.title,
        titleText: useWizardToolsLocale.sitebuilder.titleText,
        description: useWizardToolsLocale.sitebuilder.description,
        items: useWizardToolsLocale.sitebuilder.items,
        warning: useWizardToolsLocale.sitebuilder.warning,
        action: () => {
          setToolSelected(toolsNames.SITEBUILDER);
          handleContinueToNextStep(activeStep + 1, false);
        },
        actionLabel: useWizardToolsLocale.sitebuilder.action,
        testId: 'sitebuilder-tool',
        isWordPress: false,
      };
    }

    return (tools);
  }, [
    selectedPlan,
    setToolSelected,
    handleContinueToNextStep,
    activeStep,
    setOthersToolsSelected,
    setUserSelectedWondersuiteTrial,
    setUserSelectedUseEcommercePlus,
    toolsNames,
    allowStartPlan,
  ]);


  const wordpressSetupData = {
    mountSoftaculousUrl,
    cardIcons,
    mountTools,
  };

  return wordpressSetupData;
};

export default useWizardTools;
