const styles = theme => ({
  content: {
    display: 'inline-block',
    height: 'auto',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.color.white,
    fontWeight: 'normal',
    fontSize: '12px',
    userSelect: 'none',
    padding: '0 7px',
    borderRadius: 3,
    whiteSpace: 'normal',
  },
  new: {
    backgroundColor: theme.color.brightSun,
  },
  open: {
    backgroundColor: theme.color.brightSun,
  },
  pending: {
    backgroundColor: theme.color.pendingPure,
  },
  solved: {
    backgroundColor: theme.color.oceanGreen,
  },
  hold: {
    backgroundColor: theme.color.brightSun,
  },
  closed: {
    backgroundColor: theme.color.geyser,
  },
});

export default styles;
