import { collect } from '@/analytics';

const EVENT = {
  NAME: 'gaEvent',
  CATEGORY: {
    LP_ASAAS: 'Landing Page Asaas',
    STEP_2: 'Etapa 2 - Sobre o site',
    STEP_3: 'Etapa 3 - Definir ferramenta de criação',
    STEP_4: 'Etapa 4 - Dicas para começar',
  },
  ACTION: {
    FORM_STEP_ONE: 'Etapa 1 do formulário de cadastro',
    FORM_STEP_TWO: 'Etapa 2 do formulário de cadastro',
    FORM_STEP_THREE: 'Etapa 3 do formulário de cadastro',
    ANCHOR: 'Âncora para cadastro no Asaas',
    TAXES_MATERIAL: 'Material sobre taxas',
  },
};

export const formStepOneContinue = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Ação: Continuar',
});

export const formStepOneCPF = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Ação: Pessoa física',
});

export const formStepOneCNPJ = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Ação: Pessoa jurídica',
});

export const formStepOneMEI = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Tipo de empresa: MEI',
});

export const formStepOneLimited = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Tipo de empresa: Empresa Limitada',
});

export const formStepOneIndividual = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Tipo de empresa: Empresa Individual',
});

export const formStepOneAssociation = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Tipo de empresa: Associação',
});

export const formStepOneLogin = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_ONE,
  gaEventLabel: 'Ação: Login no Asaas',
});

export const formStepTwoMyCEP = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_TWO,
  gaEventLabel: 'Ação: Não sei meu CEP',
});

export const formStepTwoUseTerm = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_TWO,
  gaEventLabel: 'Ação: Marcou checkbox dos termos de uso',
});

export const formStepTwoContinue = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_TWO,
  gaEventLabel: 'Ação: Continuar',
});

export const formStepThreeRemake = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Refazer cadastro',
});

export const formStepThreeLogin = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Login no Asaas',
});

export const formStepThreeMaterialOne = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Material 1',
});

export const formStepThreeMaterialTwo = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Material 2',
});

export const formStepThreeMaterialThree = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Material 3',
});

export const formStepThreeMaterialFour = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Material 4',
});

export const formStepThreeMaterialFive = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Material 5',
});

export const formStepThreeViewMore = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.FORM_STEP_THREE,
  gaEventLabel: 'Ação: Ver mais conteúdos',
});

export const taxesMaterial = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.TAXES_MATERIAL,
  gaEventLabel: '',
});

export const anchorLink = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LP_ASAAS,
  gaEventAction: EVENT.ACTION.ANCHOR,
  gaEventLabel: '',
});

const analytics = {
  formStepOneContinue,
  formStepOneCPF,
  formStepOneCNPJ,
  formStepOneMEI,
  formStepOneLimited,
  formStepOneIndividual,
  formStepOneAssociation,
  formStepOneLogin,
  formStepTwoMyCEP,
  formStepTwoUseTerm,
  formStepTwoContinue,
  formStepThreeRemake,
  formStepThreeLogin,
  formStepThreeMaterialOne,
  formStepThreeMaterialTwo,
  formStepThreeMaterialThree,
  formStepThreeMaterialFour,
  formStepThreeMaterialFive,
  formStepThreeViewMore,
  taxesMaterial,
  anchorLink,
};

export default analytics;
