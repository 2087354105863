import React from 'react';
import * as Styles from './DnsWizardSuccessMessage.styles';


const DnsWizardSuccessMessage = ({
  testId = 'dns-wizard-success-message',
  title = 'Title',
  icon,
  message,
}) => (
  <Styles.Wrapper data-testid={testId}>
    <Styles.Title data-testid="header-title">
      {title}
    </Styles.Title>
    {message && (
    <Styles.MessageWrapper>
      {icon
            && (
              <Styles.Icon data-testid="message-icon">
                {icon}
              </Styles.Icon>
            )
          }
      <Styles.Message data-testid="footer-message">{message}</Styles.Message>
    </Styles.MessageWrapper>
    )}
  </Styles.Wrapper>
);

export default DnsWizardSuccessMessage;
