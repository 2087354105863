import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 0px 0px 47px 0px;
    }
  `}
`;


export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    box-shadow: 0px 1px 3px ${theme.v1.shadows.grey.light};
    margin-top: 8px;
    min-height: 400px;
    padding: 24px;
  `}
`;

export const Divisor = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v1.color.line.divisor};
    margin-top: 32px;
  `}
`;

export const SubscriptionRenewalDataWrapper = styled.div`
  margin-top: 24px;
`;

export const SubscriptionControlsWrapper = styled.div`
  margin-top: 32px;
`;


export const DiscountWrapper = styled.div`
  margin-top: 24px;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;


export const InfoIcon = styled.span`
  ${({ theme, variant }) => {
    const variants = {
      default: { color: theme.v1.color.warning.attention },
      blue: { color: theme.v1.color.action.houver },
    };

    return css`
    & svg {
      fill: ${variant ? variants[variant].color : variants.default.color};
    }`;
  }}
`;

export const ProblemIcon = styled.span`
  ${({ theme }) => css`
    & svg {
      fill: ${theme.v1.color.warning.error};
    }
  `}
`;

export const WarningIcon = styled.span`
  ${({ theme }) => css`
    & svg {
      fill: ${theme.color.coral};
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Row = styled.div`
  ${({
    maxWidth, borderBottom, theme, marginTop,
  }) => css`
    border-bottom: ${borderBottom ? `1px solid ${theme.color.geyser}` : 'unset'};
    display: flex;
    flex-wrap: wrap;
    margin-bottom:${borderBottom ? '32px' : 'unset'};
    margin-top: ${marginTop ? '32px' : '0px'};
    max-width: ${maxWidth ? '525px' : 'unset'};
    padding-bottom: ${borderBottom ? '32px' : 'unset'};

    @media (max-width: ${theme.breakpoints.md}) {
      max-width: unset;
    }

    @media (max-width: 959px) {
      flex-direction: column;
    }
  `}
`;
