import { COUNTRY } from '@/config';

const LINK_NA_BIO = {
  br: [
    {
      id: '01',
      link: 'https://suporte.hostgator.com.br/hc/pt-br/articles/24845439817371-O-que-%C3%A9-e-por-que-ter-um-link-na-bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=O-que-e-por-que-ter-um-link-na-bio&utm_id=linknabio-br',
      question: 'O que é e por que ter um link na bio',
    },
    {
      id: '02',
      link: 'https://suporte.hostgator.com.br/hc/pt-br/articles/24845185786011-Como-criar-um-Link-na-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-criar-um-Link-na-Bio&utm_id=linknabio-br',
      question: 'Como criar um Link na Bio',
    },
    {
      id: '03',
      link: 'https://suporte.hostgator.com.br/hc/pt-br/articles/24845208436251-Como-adicionar-meu-Link-na-Bio-nas-redes-sociais?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-adicionar-meu-Link-na-Bio-nas-redes-sociais&utm_id=linknabio-br',
      question: 'Como adicionar meu Link na Bio nas redes sociais',
    },
    {
      id: '04',
      link: 'https://suporte.hostgator.com.br/hc/pt-br/articles/24845268238235-Como-editar-ou-excluir-meu-Link-na-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-editar-ou-excluir-meu-Link-na-Bio&utm_id=linknabio-br',
      question: 'Como editar ou excluir um Link na Bio',
    },
  ],
  cl: [
    {
      id: '01',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002439426203-Qu%C3%A9-es-y-por-qu%C3%A9-tener-un-enlace-en-la-biograf%C3%ADa?utm_source=portal&utm_medium=etapa-1&utm_campaign=Que-es-y-por-que-tener-un-enlace-en-la-biografia&utm_id=linknabio-es',
      question: 'Qué es y por qué tener un enlace en la biografía.',
    },
    {
      id: '02',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002226871451-C%C3%B3mo-crear-un-Enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-crear-un-Enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo crear un Enlace en la Bio.',
    },
    {
      id: '03',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002295472795-C%C3%B3mo-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales&utm_id=linknabio-es',
      question: 'Cómo agregar mi enlace de la bio en las redes sociales (Instagram, Facebook, TikTok, YouTube, LinkedIn).',
    },
    {
      id: '04',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002348704027-C%C3%B3mo-editar-o-eliminar-el-enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-editar-o-eliminar-el-enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo editar o eliminar el enlace en la Bio.',
    },
  ],
  co: [
    {
      id: '01',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002439426203-Qu%C3%A9-es-y-por-qu%C3%A9-tener-un-enlace-en-la-biograf%C3%ADa?utm_source=portal&utm_medium=etapa-1&utm_campaign=Que-es-y-por-que-tener-un-enlace-en-la-biografia&utm_id=linknabio-es',
      question: 'Qué es y por qué tener un enlace en la biografía.',
    },
    {
      id: '02',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002226871451-C%C3%B3mo-crear-un-Enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-crear-un-Enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo crear un Enlace en la Bio.',
    },
    {
      id: '03',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002295472795-C%C3%B3mo-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales&utm_id=linknabio-es',
      question: 'Cómo agregar mi enlace de la bio en las redes sociales (Instagram, Facebook, TikTok, YouTube, LinkedIn).',
    },
    {
      id: '04',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002348704027-C%C3%B3mo-editar-o-eliminar-el-enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-editar-o-eliminar-el-enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo editar o eliminar el enlace en la Bio.',
    },
  ],
  mx: [
    {
      id: '01',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002439426203-Qu%C3%A9-es-y-por-qu%C3%A9-tener-un-enlace-en-la-biograf%C3%ADa?utm_source=portal&utm_medium=etapa-1&utm_campaign=Que-es-y-por-que-tener-un-enlace-en-la-biografia&utm_id=linknabio-es',
      question: 'Qué es y por qué tener un enlace en la biografía.',
    },
    {
      id: '02',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002226871451-C%C3%B3mo-crear-un-Enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-crear-un-Enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo crear un Enlace en la Bio.',
    },
    {
      id: '03',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002295472795-C%C3%B3mo-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-agregar-mi-enlace-de-la-Bio-en-las-redes-sociales&utm_id=linknabio-es',
      question: 'Cómo agregar mi enlace de la bio en las redes sociales (Instagram, Facebook, TikTok, YouTube, LinkedIn).',
    },
    {
      id: '04',
      link: 'https://soporte-latam.hostgator.com/hc/es-419/articles/25002348704027-C%C3%B3mo-editar-o-eliminar-el-enlace-en-la-Bio?utm_source=portal&utm_medium=etapa-1&utm_campaign=Como-editar-o-eliminar-el-enlace-en-la-Bio&utm_id=linknabio-es',
      question: 'Cómo editar o eliminar el enlace en la Bio.',
    },
  ],
};

const CUSTOM_DOMAIN_LINKS = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/31201728427539-Como-conectar-um-domínio-registrado-no-Link-na-Bio',
  cl: 'https://soporte.hostgator.mx/hc/es-419/articles/31203235769491-Como-conectar-un-dominio-registrado-en-el-Enlace-en-la-Bio',
  co: 'https://soporte.hostgator.mx/hc/es-419/articles/31203235769491-Como-conectar-un-dominio-registrado-en-el-Enlace-en-la-Bio',
  mx: 'https://soporte.hostgator.mx/hc/es-419/articles/31203235769491-Como-conectar-un-dominio-registrado-en-el-Enlace-en-la-Bio',
};

export const FAQ_LINK_NA_BIO = LINK_NA_BIO[COUNTRY];
export const CUSTOM_DOMAIN_TO_LINK_NA_BIO = CUSTOM_DOMAIN_LINKS[COUNTRY];
