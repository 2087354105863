const headerTokens = {
  vimeo: auth => `bearer ${auth.vimeoToken}`,
  aws: auth => `Bearer ${auth.token}`,
  jwt: auth => `Bearer ${auth.jwt}`,
};

export const setAuthorizationToken = (authorizationType, auth) => {
  if (authorizationType) {
    return headerTokens[authorizationType](auth);
  }

  return auth.token;
};

export const sanitizeHeaderByAuthorizationType = (authorizationType, header) => {
  const handleHeader = header;

  switch (authorizationType) {
    case 'vimeo':
      delete handleHeader.brand;
      return handleHeader;
    default:
      return handleHeader;
  }
};
