import React, { useContext } from 'react';
import * as Styles from './Configurations.styles';
import {
  Select, Button, Link, Tooltip,
} from '@/pages/common';
import {
  IconDropdownArrow, IconError, IconArrowForward, IconEdit, IconInfo,
} from '@/icons';
import { ThemeContext } from 'styled-components';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import Loader from '@/components/Layout/Loader';
import { Card } from '../Card';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_SEARCHABLE_SELECT_INSIDE_SITESWIZARD } from '@/config/GrowthBook/constants';
import { IconLoading } from 'gatorcomponents';

export default function Configurations({
  choosePlan,
  chosenPlan,
  comparingDns,
  continueText,
  costumerNeedToUpgradePlan,
  customDomain,
  getSingleDomainsPlans,
  getMultiDomainsPlans,
  handleBuyOtherPlanClick,
  handleChangeDomain,
  handleChangePlanClick,
  handleSelectDomainOnContinue,
  handleShowModal,
  handleWishOtherDomain,
  mainDomain,
  modals,
  multiDomainsPlans,
  planLimitBuyOtherPlan,
  planLimitChangePlan,
  planLimitText,
  planLimitTitle,
  selectedDomain,
  selectedDomainPlan,
  selectedPlan,
  selectedSite,
  selectPlaceholder,
  selectText,
  sitesState,
  text,
  title,
  titleStrong,
  tooltipReplace,
  tooltipSinglePlan,
  userDomainList,
  wishOtherDomain,
  showOtherPlans,
  displayOverwriteModal,
  handleClickHirePlan,
  hirePlanTitle,
  hirePlanSubtitle,
  hirePlanCta,
  registerDomainTitle,
  registerDomainSubtitle,
  registerDomainCta,
  handleClickRegisterDomain,
  withGatorSolutionsPlans,
}) {
  const shouldDisplayEditIcon = !sitesState.loadingDomainValidation && !sitesState.loading && !comparingDns;
  const enableSearchableSelect = useFeatureIsOn(CHARLIE_SEARCHABLE_SELECT_INSIDE_SITESWIZARD);
  const theme = useContext(ThemeContext);
  const { color } = theme.v1;
  const hasChosenPlanId = withGatorSolutionsPlans ? chosenPlan.external_id : chosenPlan.id;
  const hasMultiplePlans = multiDomainsPlans.length > 1 || (selectedDomainPlan && getSingleDomainsPlans(selectedDomainPlan) && multiDomainsPlans.length >= 1);

  const hasJustOnePlan = (selectedDomainPlan && sitesState.plans.length === 1)
   || (selectedDomainPlan && getSingleDomainsPlans(selectedDomainPlan) && !hasMultiplePlans)
   || (selectedDomainPlan && getMultiDomainsPlans(selectedDomainPlan) && multiDomainsPlans.length === 1)
   || (!selectedDomainPlan && multiDomainsPlans.length === 1);

  const domainWithoutPlan = !selectedDomainPlan && !hasChosenPlanId && hasMultiplePlans;
  const clientHasOnlyOnePlan = hasChosenPlanId && hasJustOnePlan;


  const hasMultiplePlanOptions = (hasChosenPlanId || selectedDomainPlan) && hasMultiplePlans;

  const hasActivePlan = sitesState.plans.find(plan => plan.status === 'active');

  if (!sitesState.plans.length || (sitesState.plans.length && !hasActivePlan)) {
    return (
      <>
        <Styles.Box>
          <Styles.IconWrapper>
            <IconError color={color.warning.mustard} size="50" />
          </Styles.IconWrapper>

          <div>
            <Styles.Title data-testid="hirePlantitle">
              {hirePlanTitle}
            </Styles.Title>
            <Styles.Subtitle data-testid="hirePlansubtitle">
              {hirePlanSubtitle}
            </Styles.Subtitle>
          </div>
        </Styles.Box>

        <Styles.Box>
          <Button
            testId="hire-plan-button"
            variant="newPrimary"
            font="secondary"
            size="large"
            label={hirePlanCta}
            onClick={handleClickHirePlan}
          />
        </Styles.Box>
      </>
    );
  }

  if (sitesState.anotherDomain && sitesState.domainAvailability && sitesState.domainTldAvailability) {
    return (
      <>
        <Styles.Box>
          <Styles.IconWrapper>
            <IconError color={color.warning.mustard} size="50" />
          </Styles.IconWrapper>

          <div>
            <Styles.Title data-testid="hirePlantitle">
              {registerDomainTitle && registerDomainTitle(sitesState.anotherDomain)}
            </Styles.Title>
            <Styles.Subtitle data-testid="hirePlansubtitle">
              {registerDomainSubtitle}
            </Styles.Subtitle>
          </div>
        </Styles.Box>

        <Styles.Box>
          <Button
            testId="hire-plan-button"
            variant="newPrimary"
            font="secondary"
            size="large"
            label={registerDomainCta}
            onClick={handleClickRegisterDomain}
          />
        </Styles.Box>
      </>
    );
  }

  if (costumerNeedToUpgradePlan) {
    return (
      <>
        <Styles.Box>
          <Styles.IconWrapper>
            <IconError color={color.warning.pending} size="50" />
          </Styles.IconWrapper>

          <div>
            <Styles.Title data-testid="title">
              {planLimitTitle}
            </Styles.Title>
            <Styles.Subtitle data-testid="subtitle">{planLimitText}</Styles.Subtitle>
          </div>
        </Styles.Box>

        <Styles.Box>
          <Button
            testId="change-plan-button"
            variant="newPrimary"
            font="secondary"
            size="large"
            label={planLimitChangePlan}
            onClick={handleChangePlanClick}
          />
          <Styles.LinkWrapper>
            <Link
              onClick={handleBuyOtherPlanClick}
              href={HOSTING_URL}
              text={planLimitBuyOtherPlan}
              variant="newDefault"
              testId="buy-plan-button"
              target="_blank"
              iconRight={<IconArrowForward fill={theme.palette.secondary.primary} stroke={theme.palette.secondary.primary} size="18" />}
            />
          </Styles.LinkWrapper>
        </Styles.Box>
      </>
    );
  }

  return (
    <Styles.ConfigurationsWrapper>
      <Styles.Title data-testid="title">
        {title}
        <span>{titleStrong}</span>
      </Styles.Title>

      <Styles.Subtitle data-testid="subtitle">{text}</Styles.Subtitle>

      <Styles.SelectWrapper>
        <Styles.LabelWrapper>
          <Styles.Triangle data-testid="triangle" />
          <Styles.Label data-testid="select-label">{selectText}</Styles.Label>
        </Styles.LabelWrapper>

        <Styles.Select>
          <Select
            animatedDropdownIcon
            customDropdownIcon={<IconDropdownArrow testId="icon-dropdown-arrow-domain" />}
            customSelectedValue={customDomain}
            defaultOption={selectedDomain && selectedDomain.value}
            defaultOptionLabel={selectedDomain && selectedDomain.label}
            fixedBottomOption={wishOtherDomain}
            fixedBottomOptionAction={handleWishOtherDomain}
            loading={sitesState.loadingDomains || sitesState.loadingTlds}
            onChange={handleChangeDomain}
            options={userDomainList}
            placeholder={customDomain || selectPlaceholder}
            testId="domain-select"
            variant="newStandard"
            autoSelectIfOnOption={false}
            selectedOption={selectedDomain}
            searchable={enableSearchableSelect}
          />
          {(selectedSite && !displayOverwriteModal) && (
            <Styles.TooltipReplaceWrapper>
              <Tooltip
                width="290"
                fixedDesktopPosition="bottom"
                fixedMobilePosition="bottomLeft"
                text={tooltipReplace}
                variant="dark"
              >
                <IconError color={color.warning.error} size={18} />
              </Tooltip>
            </Styles.TooltipReplaceWrapper>
          )}
        </Styles.Select>
      </Styles.SelectWrapper>

      {sitesState.loadingDomainValidation && (
        <IconLoading />
      )}

      {(selectedDomain && !sitesState.loadingDomainValidation) && (
        <>
          <Styles.PlanChooseWrapper>
            <Styles.LabelWrapper marginBottom>
              <Styles.Triangle data-testid="triangle2" />
              <Styles.Label data-testid="choose-plan-label">
                {selectedPlan}

                {hasChosenPlanId && hasJustOnePlan && (
                  <Styles.SinglePlanTooltipWrapper>
                    <Tooltip
                      width="330"
                      fixedDesktopPosition="right"
                      fixedMobilePosition="bottomCenter"
                      text={tooltipSinglePlan}
                      variant="dark"
                    >
                      <IconInfo color={color.action.primary} size={18} />
                    </Tooltip>
                  </Styles.SinglePlanTooltipWrapper>
                )}
              </Styles.Label>
            </Styles.LabelWrapper>
          </Styles.PlanChooseWrapper>

          <Styles.CardWrapper>
            {domainWithoutPlan && (
              <Card
                testId="plan-card"
                asButton
                title={(
                  <Button
                    testId="plan-card-button"
                    variant="newTertiary"
                    iconRight={<IconArrowForward fill={theme.palette.secondary.primary} stroke={theme.palette.secondary.primary} size="18" />}
                    font="secondary"
                    size="large"
                    label={choosePlan}
                    onClick={() => {
                      showOtherPlans();
                      handleShowModal(modals.CHOOSE_PLAN);
                    }}
                  />
                )}
              />
            )}

            {clientHasOnlyOnePlan && (
              <Card
                testId="plan-card"
                selected
                title={chosenPlan.name}
              >
                <Styles.TextDomain>
                  {mainDomain}
                  {chosenPlan.domain}
                </Styles.TextDomain>
              </Card>
            )}

            {hasMultiplePlanOptions && (
              <Card
                testId="plan-card"
                selected
                title={chosenPlan.name}
                iconAction={shouldDisplayEditIcon && <IconEdit />}
                action={multiDomainsPlans.length ? () => {
                  handleShowModal(modals.CHOOSE_PLAN);
                  showOtherPlans();
                } : null}
              >
                <Styles.TextDomain>
                  {mainDomain}
                  {chosenPlan.domain}
                </Styles.TextDomain>
              </Card>
            )}
          </Styles.CardWrapper>
        </>
      )}

      {!sitesState.loadingDomainValidation && (
        <Styles.ContinueButtonWrapper>
          <Button
            disabled={!hasChosenPlanId || !selectedDomain || comparingDns || sitesState.loading}
            font="secondary"
            label={comparingDns ? <Loader size={12} /> : continueText}
            onClick={handleSelectDomainOnContinue}
            size="large"
            testId="continue-button"
            variant="newPrimary"
          />
        </Styles.ContinueButtonWrapper>
      )}
    </Styles.ConfigurationsWrapper>
  );
}
