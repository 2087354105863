import React from 'react';
import { Button } from '@/pages/common';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import * as Styles from './RefundValueBlock.styles';

const RefundValueBlock = ({
  testId = 'refundValueBlock',
  totalLabel = 'Total to refund',
  refundPolitics = 'Read our refund politics',
  amount = '0.00',
  onRefundPoliticsClick,
  loading = false,
}) => {
  const handleOnRefundPoliticsClick = () => {
    onRefundPoliticsClick && onRefundPoliticsClick();
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.TotalLabel>
        {totalLabel}
      </Styles.TotalLabel>
      <Styles.Amount>
        {loading ? '...' : formatCurrency(amount)}
      </Styles.Amount>
      <Button variant="link" label={refundPolitics} onClick={handleOnRefundPoliticsClick} testId="politics-button" />
    </Styles.Wrapper>
  );
};

export default RefundValueBlock;
