import { createStore } from 'redux';

import middleware, { sagaMiddleware } from '@/middleware/index';
import rootReducer from './rootReducer';
import rootSaga from './modules/rootSaga';

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(rootSaga);

export default store;
