import styled, { css } from 'styled-components';

export const Wrapper = styled.svg`
  transform: rotate(-90deg);
`;

export const Background = styled.circle`
  ${({ theme }) => css`
    fill: none;
    stroke: ${theme.palette.grey.variant};
  `}
`;

export const Foreground = styled.circle`
  ${({ theme, bgColor }) => css`
    fill: none;
    stroke: ${bgColor || theme.palette.primary.main};
  `}
`;
