import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-family: ${theme.font.family.secondary};
`
}`;

export const ModalTitle = styled.p`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-size: ${theme.font.sizes.xxl};
  font-weight: ${theme.font.weights.bold};
  margin: 16px 0 40px 29px;
`
}`;

export const ModalBody = styled.div`
${({ theme }) => css`
  color: ${theme.color.tundora};
  font-size: ${theme.font.sizes.sm};
  margin-bottom: 40px;
  padding-left: 80px;
  padding-right: 20px;


  @media (max-width: ${theme.breakpoints.sm}) {
    margin-left: 9px;
    margin-right: 9px;
    padding: 0;
  }
`
}`;

export const FirstParagraph = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  gap: 24px;
  margin-left: -51px;
  margin-bottom: 28px;

  strong:first-child {
      color: ${theme.color.cyanCobaltBlue};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    margin-left: 0;
  }
`}
`;

export const ModalFooter = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  font-family: ${theme.font.family.secondary};
  gap: 32px;
  justify-content: center;
  margin-bottom: 24px;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    gap: 24px;
  }
`
}`;

export const ModalText = styled.p`
  margin: 0;
`;

export const ModalLink = styled.a`
${({ theme }) => css`
  color: ${theme.palette.secondary.primary};
  margin-right: 5px;
  text-decoration: underline;
`}
`;

export const CheckIcon = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.shamrockGreen};
    border-radius: 50%;
    padding: 7px;
  `}
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-left: -42px;
    }
  `}
`;
