const styles = theme => ({

  priceLabel: {
    color: theme.color.tundora,
    fontSize: '16px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    textAlign: 'right',
  },

  priceLabelOld: {
    fontSize: '14px',
    fontWeight: '500',
  },


  paymentMethodComponent: { marginTop: '23px' },

  priceDiv: {
    marginButton: '16px',
    marginTop: '23px',
  },

  price: {
    marginTop: '16px',
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  priceOld: {
    fontSize: '18px',
    marginTop: '0px',
    textAlign: 'right',
  },

  savedMoney: {
    color: theme.color.coral,
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    textAlign: 'right',
  },

  button: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    display: 'flex',
    fontSize: '14px/19px',
    fontWeight: '500',
    height: '40px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: '16px',
    maxWidth: '100%',
    textDecoration: 'none',
    transition: '300ms',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.color.chambray,
    },

    [theme.breakpoints.down(700)]: {
      marginButton: '16px',
    },
  },

  featuredButton: {
    backgroundColor: theme.color.coral,
    borderColor: theme.color.brownRust,

    '&:hover': {
      backgroundColor: theme.color.brownRust,
    },
  },
  hireButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    marginTop: '18px',
    width: '100%',
  },
});
export default styles;
