const styles = theme => ({
  warningWrapper: {
    margin: '15px 0 0 0',
    padding: '10px 20px 0 29px',

    [theme.breakpoints.down(1148)]: {
      padding: '10px 0 0 0',
    },
  },
});

export default styles;
