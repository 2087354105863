import React, { useCallback, useState } from 'react';
import * as Styles from './ChangeDueDate.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  Button, Checkbox, IconLoading, Input,
} from 'gatorcomponents';
import { isValid } from 'date-fns';

export const ChangeDueDate = ({
  submitForm,
  selectedProduct,
  opening,
}) => {
  const [agreed, setAgreed] = useState(false);
  const [newDueDate, setNewDueDate] = useState();
  const { ticket: ticketsLocale } = useLocale();
  const { changeDueDate: changeDueDateLocale } = ticketsLocale.forms;

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const productServer = selectedProduct.serverhostname || selectedProduct.dedicatedip || '';

    const data = {
      tags: [productServer, 'whmcs_form_financeiro_alterar_data_de_vencimento'],
      subject: 'Suporte Financeiro - Alterar Data de Vencimento',
      automation: false,
      body: `• Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}
        • Servidor: ${productServer}
        • Solicitação: Alterar Data de Vencimento
        • Qual a data de vencimento desejada?: ${newDueDate ? newDueDate.split('-').reverse().join('/') : ''}
        • Confirmo que li e concordo com os termos acima: ${agreed ? 'sim' : 'não'}
        • ID do produto: ${selectedProduct.id}`,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
    };

    const isValidDate = isValid(new Date(newDueDate));
    if (isValidDate) {
      submitForm && submitForm(data);
    }
  }, [agreed, newDueDate, selectedProduct, submitForm]);

  const handleNewDueDate = (dueDate) => {
    setNewDueDate(dueDate);
  };

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        {changeDueDateLocale.info()}
      </Styles.Info>

      <Styles.InputWrapper>
        <Input
          label={changeDueDateLocale.desiredDueDate}
          placeholder={changeDueDateLocale.desiredDueDatePlaceHolder}
          value={newDueDate}
          onChange={e => handleNewDueDate(e.target.value)}
          type="date"
          testId="dateInput"
        />
      </Styles.InputWrapper>

      <Styles.Agreed>
        <Styles.CheckboxLabel
          htmlFor="#agreed"
          onClick={() => setAgreed(!agreed)}
          data-testid="checkAgreed"
        >
          <Checkbox checked={agreed} id="agreed" />
        </Styles.CheckboxLabel>
        {' '}
        {changeDueDateLocale.agreement}
      </Styles.Agreed>


      <Button
        size="large"
        label={opening ? <IconLoading /> : changeDueDateLocale.send}
        disabled={!agreed || opening}
        type="submit"
        testId="submit"
      />
    </Styles.Form>
  );
};

export default ChangeDueDate;
