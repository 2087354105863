import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  Button, IconFilledInfo, Tooltip, IconBell, IconClose,
} from 'gatorcomponents';
import * as Styles from './MyAlerts.styles';
import { useSelector, useDispatch } from 'react-redux';
import { alertsActions, productsActions } from '@/redux/modules';
import NewAlerts from '@/components/Domain/NewAlerts';
import ModalDomainDnsPropagationAlert from '@/components/Domain/ModalDomainDnsPropagationAlert';
import ModalDomainNotContracted from '@/components/Domain/ModalDomainNotContracted';
import ModalDomainNotConfigured from '@/components/Domain/ModalDomainNotConfigured';
import ModalDomainInPropagation from '@/components/Domain/ModalDomainInPropagation';
import RefundModal from '@/components/Domain/RefundAlertsModal';
import { DuplicateOrdersModal } from '@/components/billing/components/DuplicateOrdersModal';
import { alertsAnalytics } from '@/analytics/alerts';
import { Modal } from '@/pages/common';
import { locale } from '@/utils/locale';
import { useHistory } from 'react-router';
import {
  CHARLIE_TICKETS_STATUS_V2, XRAY_ENABLED_DISCOUNT_OFFER_BANNER,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  ALERT_TYPES, BACKUP_GA, BACKUP_STATUS, PENDING_ORDER_GA, SSL_GA, SSL_STATUS,
} from './MyAlerts.types';
import { billingAnalytics } from '@/analytics';

const TABS = {
  ALL: 'all',
  TICKETS: 'tickets',
};

const MyAlerts = ({
  label,
  titleLabel = 'Meus Avisos',
  allNotificationsLabel,
  seeMoreLabel,
  alertsTooltipLabel,
  noNotificationsLabel,
  isMobile,
  ticketNotificationsLabel,
}) => {
  const [showModalDnsPropagationAlert, setShowModalDnsPropagationAlert] = useState(false);
  const [showModalDomainNotContracted, setShowModalDomainNotContracted] = useState(false);
  const [showModalDomainNotConfigured, setShowModalDomainNotConfigured] = useState(false);
  const [showModalDomainInPropagation, setShowModalDomainInPropagation] = useState(false);
  const [showDuplicateOrdersModal, setShowDuplicateOrdersModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [GAsCategoriesCollected, setGAsCategoriesCollected] = useState([]);
  const [renderAlertsAmount, setRenderAlertsAmount] = useState(3);
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const [alertsArray, setAlertsArray] = useState([]);
  const [refundToShowOnModal, setRefundToShowOnModal] = useState({});

  const visible = useSelector(state => state.products.alertsVisibility);
  const diagnostic = useSelector(state => state.preChat);
  const { diagnosticAlerts, ticketsAlerts } = useSelector(state => state.alertsV2);
  const renderAllTickets = useFeatureIsOn(CHARLIE_TICKETS_STATUS_V2);
  const enabledDiscountOfferBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_OFFER_BANNER);

  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const hasModalOpen = showModalDnsPropagationAlert || showModalDomainNotContracted || showModalDomainNotConfigured;
  const history = useHistory();

  const domainInPropagation = diagnostic.domainsPropagation && diagnostic.domainsPropagation.filter(propagation => propagation.propagation_status === 'in_progress');
  const tos = useMemo(() => (diagnosticAlerts && diagnosticAlerts.filter(tosTicket => tosTicket.type === ALERT_TYPES.TOS)) || [], [diagnosticAlerts]);
  const filteredTicketsAlerts = useMemo(() => {
    const ticketsWithoutTos = (renderAllTickets ? ticketsAlerts.filter(ticket => !tos.some(singleTos => singleTos.id === ticket.id)) : []);
    const onlyTicketsPending = ticketsWithoutTos.filter(ticket => ticket.type === ALERT_TYPES.TICKET_PENDING);
    const withoutTicketsPending = ticketsWithoutTos.filter(ticket => ticket.type !== ALERT_TYPES.TICKET_PENDING);

    return [...onlyTicketsPending, ...withoutTicketsPending];
  }, [ticketsAlerts, tos, renderAllTickets]);

  const tabAlert = useMemo(() => ({
    all: [...alertsArray],
    tickets: [...tos, ...filteredTicketsAlerts],
  }), [alertsArray, filteredTicketsAlerts, tos]);

  const alertsToRender = isMobile ? tabAlert[activeTab] : tabAlert[activeTab].slice(0, renderAlertsAmount);
  const renderEmpty = alertsToRender.length === 0;

  const setVisible = (isVisible) => {
    dispatch(productsActions.myAlerts.setVisibility(isVisible));
  };

  const handleShowModalDnsPropagationAlert = () => {
    setShowModalDnsPropagationAlert(!showModalDnsPropagationAlert);
  };

  const handleShowModalDomainNotContracted = () => {
    setShowModalDomainNotContracted(!showModalDomainNotContracted);
  };

  const handleShowModalDomainNotConfigured = () => {
    setShowModalDomainNotConfigured(!showModalDomainNotConfigured);
  };

  const handleShowModalDomainInPropagation = () => {
    setShowModalDomainInPropagation(!showModalDomainInPropagation);
    setVisible(!visible);
  };

  const handleShowDuplicateOrdersModal = (open) => {
    setShowDuplicateOrdersModal(!showDuplicateOrdersModal);

    if (open) {
      alertsAnalytics.duplicateOrderClick();
    }
  };

  const handleRedirectToSubscriptionAnalysis = (productId, productType) => {
    const url = `${locale('routes.subscriptions')}${locale('routes.manageSubscription')}/${productId}/${productType}`;
    history.push(url);
    setVisible(!visible);
  };

  const handleShowRefundModal = (refund) => {
    setShowRefundModal(!showRefundModal);
    setVisible(!visible);
    setRefundToShowOnModal(refund);
  };

  const handleRedirectToBackupScreen = (hostingId) => {
    history.push(`${locale('routes.products')}${locale('routes.productdetail')}/${hostingId}${locale('routes.backup')}`);
  };

  const handleRedirectToSubscriptionManager = (alertIndex) => {
    alertsAnalytics.redirectToSubscriptionManager(alertsToRender[alertIndex].daysToDue, alertsToRender[alertIndex].discountEnable);
    const url = `${locale('routes.subscriptions')}${locale('routes.manageSubscription')}/${alertsToRender[alertIndex].toDueSubscriptionId}/${alertsToRender[alertIndex].productType}?modal=true`;
    setVisible(!visible);
    history && history.push(url);
  };

  const handleOnSeeMore = () => {
    const newAlertAmount = renderAlertsAmount + 3;

    setRenderAlertsAmount(newAlertAmount);
  };

  const handleClickOnAlertDomainToPoint = (id) => {
    history.push(`${locale('routes.domains')}${locale('routes.dnsWizard')}/${id}`);
    dispatch(alertsActions.set.clickOnAlertDomainToPoint(true));
  };

  const setAlertAsRead = (index, alertOrigin = 'diagnostic') => {
    setVisible(false);
    switch (alertOrigin) {
      case 'diagnostic': {
        const alertToUpdate = diagnosticAlerts[index];
        alertToUpdate.isNew = false;
      }
        break;
      case 'tickets': {
        const alertToUpdate = ticketsAlerts.find(ticket => ticket.id === index);
        alertToUpdate.isNew = false;
      }
        break;
      default:
    }
  };

  const handleClick = () => {
    if (!visible) {
      alertsAnalytics.viewAlerts();
    }
    setVisible(!visible);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (visible === true && !hasModalOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef, hasModalOpen, visible]);

  useEffect(() => {
    if (!visible) {
      setRenderAlertsAmount(3);
    }
    if (isMobile && visible) {
      alertsAnalytics.viewAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isMobile]);

  useEffect(() => {
    if (visible) {
      const GAsToDispatch = [];
      const alreadyCollectedGA = alertGAType => GAsCategoriesCollected.some(category => category === alertGAType);
      const hasBackups = hostingId => diagnostic.backups && diagnostic.backups.some(backup => backup.hosting_id === hostingId);

      alertsToRender.forEach((alert) => {
        switch (alert.type) {
          case ALERT_TYPES.SSL:
            if (!alreadyCollectedGA(SSL_GA.ACTIVE) && alert.status === SSL_STATUS.ACTIVE) {
              GAsToDispatch.push(SSL_GA.ACTIVE);
            }
            if (!alreadyCollectedGA(SSL_GA.ACTIVATION_FAILED) && alert.status === SSL_STATUS.ACTIVATION_FAILED) {
              GAsToDispatch.push(SSL_GA.ACTIVATION_FAILED);
            }
            if (!alreadyCollectedGA(SSL_GA.IN_PROGRESS) && alert.status === SSL_STATUS.IN_PROGRESS) {
              GAsToDispatch.push(SSL_GA.IN_PROGRESS);
            }
            break;
          case ALERT_TYPES.INVOICES:
            if (!alreadyCollectedGA(ALERT_TYPES.INVOICES)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.DOMAIN_NOT_CONTRACTED:
            if (!alreadyCollectedGA(ALERT_TYPES.DOMAIN_NOT_CONTRACTED)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.DOMAIN_IN_PROGRESS:
            if (!alreadyCollectedGA(ALERT_TYPES.DOMAIN_IN_PROGRESS)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.DOMAIN_NOT_CONFIGURED:
            if (!alreadyCollectedGA(ALERT_TYPES.DOMAIN_NOT_CONFIGURED)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.BACKUP:
            if (!alreadyCollectedGA(BACKUP_GA.SUCCESS) && alert.backup_type === BACKUP_STATUS.REQUESTED && alert.days_since_last <= 7) {
              GAsToDispatch.push(BACKUP_GA.SUCCESS);
              break;
            }
            if (!alreadyCollectedGA(BACKUP_GA.SEVEN_DAYS) && alert.backup_type === BACKUP_STATUS.REQUESTED && alert.days_since_last > 7 && alert.days_since_last <= 15) {
              GAsToDispatch.push(BACKUP_GA.SEVEN_DAYS);
              break;
            }
            if (!alreadyCollectedGA(BACKUP_GA.FIFTEEN_DAYS) && alert.backup_type === BACKUP_STATUS.REQUESTED && alert.days_since_last > 15 && alert.days_since_last <= 30) {
              GAsToDispatch.push(BACKUP_GA.FIFTEEN_DAYS);
              break;
            }
            if (!alreadyCollectedGA(BACKUP_GA.THIRTY_DAYS) && alert.backup_type === BACKUP_STATUS.REQUESTED && alert.days_since_last > 30) {
              GAsToDispatch.push(BACKUP_GA.THIRTY_DAYS);
              break;
            }
            if (!alreadyCollectedGA(BACKUP_GA.WITHOUT) && alert && !hasBackups(alert.hosting_id)) {
              GAsToDispatch.push(BACKUP_GA.WITHOUT);
            }
            break;
          case ALERT_TYPES.DUPLICATE_ORDERS:
            if (!alreadyCollectedGA(ALERT_TYPES.DUPLICATE_ORDERS)) {
              GAsToDispatch.push(ALERT_TYPES.DUPLICATE_ORDERS);
            }
            break;
          case ALERT_TYPES.TICKET_NEW:
            if (!alreadyCollectedGA(ALERT_TYPES.TICKET_NEW)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.TICKET_OPEN:
            if (!alreadyCollectedGA(ALERT_TYPES.TICKET_OPEN)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.TICKET_PENDING:
            if (!alreadyCollectedGA(ALERT_TYPES.TICKET_PENDING)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.TICKET_SOLVED:
            if (!alreadyCollectedGA(ALERT_TYPES.TICKET_SOLVED)) {
              GAsToDispatch.push(alert.type);
            }
            break;
          case ALERT_TYPES.PENDING_ORDER:
            if (!alreadyCollectedGA(PENDING_ORDER_GA.CANCELLED) && alert.orderStatus === 'cancelled') {
              GAsToDispatch.push(PENDING_ORDER_GA.CANCELLED);
            }
            if (!alreadyCollectedGA(PENDING_ORDER_GA.PENDING_ANALYSIS) && alert.orderStatus === 'pending_analysis') {
              GAsToDispatch.push(PENDING_ORDER_GA.PENDING_ANALYSIS);
            }
            break;
          default:
            break;
        }
      });

      const notDuplicatedGAsToDispatch = [...new Set(GAsToDispatch)];

      if (notDuplicatedGAsToDispatch.length) {
        const onlyNewGAs = notDuplicatedGAsToDispatch.filter(GA => !alreadyCollectedGA(GA));
        setGAsCategoriesCollected([...GAsCategoriesCollected, ...onlyNewGAs]);

        onlyNewGAs.forEach((analyticType) => {
          switch (analyticType) {
            case ALERT_TYPES.INVOICES:
              alertsAnalytics.viewInvoicesAlert();
              billingAnalytics.invoicesAlertDisplayed();
              break;
            case ALERT_TYPES.DOMAIN_NOT_CONTRACTED:
              alertsAnalytics.viewContractDomainAlert();
              break;
            case ALERT_TYPES.DOMAIN_IN_PROGRESS:
              alertsAnalytics.viewPropagationAlert();
              break;
            case ALERT_TYPES.DOMAIN_NOT_CONFIGURED:
              alertsAnalytics.viewSetupDomainAlert();
              break;
            case BACKUP_GA.SUCCESS:
              alertsAnalytics.backupSuccess();
              break;
            case BACKUP_GA.SEVEN_DAYS:
              alertsAnalytics.backupSevenDays();
              break;
            case BACKUP_GA.FIFTEEN_DAYS:
              alertsAnalytics.backupFifteenDays();
              break;
            case BACKUP_GA.THIRTY_DAYS:
              alertsAnalytics.backupThirtyDays();
              break;
            case BACKUP_GA.WITHOUT:
              alertsAnalytics.withoutBackup();
              break;
            case ALERT_TYPES.DUPLICATE_ORDERS:
              alertsAnalytics.duplicateOrder();
              break;
            case ALERT_TYPES.TICKET_NEW:
              renderAllTickets && alertsAnalytics.ticketNew();
              break;
            case ALERT_TYPES.TICKET_OPEN:
              renderAllTickets && alertsAnalytics.ticketOpen();
              break;
            case ALERT_TYPES.TICKET_PENDING:
              renderAllTickets && alertsAnalytics.ticketWaitingResponse();
              break;
            case ALERT_TYPES.TICKET_SOLVED:
              renderAllTickets && alertsAnalytics.ticketSolved();
              break;
            case PENDING_ORDER_GA.CANCELLED:
              alertsAnalytics.canceledOrder();
              break;
            case PENDING_ORDER_GA.PENDING_ANALYSIS:
              alertsAnalytics.analyzingOrder();
              break;
            case SSL_GA.ACTIVE:
              alertsAnalytics.sslActiveDisplayed();
              break;
            case SSL_GA.ACTIVATION_FAILED:
              alertsAnalytics.sslErrorDisplayed();
              break;
            case SSL_GA.IN_PROGRESS:
              alertsAnalytics.sslInProgressDisplayed();
              break;
            default:
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, alertsToRender]);


  useEffect(() => {
    setAlertsArray([...diagnosticAlerts, ...filteredTicketsAlerts]);
  }, [diagnosticAlerts, filteredTicketsAlerts]);

  const notificationItems = () => (
    <>
      {alertsToRender.map((alert, index) => (
        <Styles.NotificationItem isNew={alert.isNew} data-testid={alert.type} key={alert.id ? alert.id + alert.type : alert.type}>
          <NewAlerts
            alertType={alert.type}
            handleShowModalDnsPropagationAlert={handleShowModalDnsPropagationAlert}
            handleShowModalDomainNotContracted={handleShowModalDomainNotContracted}
            handleShowModalDomainNotConfigured={handleShowModalDomainNotConfigured}
            handleShowModalDomainsInPropagation={handleShowModalDomainInPropagation}
            handleShowRefundModal={handleShowRefundModal}
            handleRedirectToBackupScreen={handleRedirectToBackupScreen}
            handleRedirectToSubscriptionManager={handleRedirectToSubscriptionManager}
            handleRedirectToSubscriptionAnalysis={handleRedirectToSubscriptionAnalysis}
            handleClickOnAlertDomainToPoint={handleClickOnAlertDomainToPoint}
            totalInvoice={alert.totalInvoices}
            invoiceSum={alert.invoicesSum}
            setVisible={setVisible}
            alert={alert}
            index={index}
            setAlertAsRead={setAlertAsRead}
            handleDuplicateOrdersClick={handleShowDuplicateOrdersModal}
            enabledDiscountOfferBanner={enabledDiscountOfferBanner}
          />
        </Styles.NotificationItem>
      ))}
    </>
  );

  const changeActiveTab = (tab) => {
    const alertsRenderInitialValue = 3;

    setActiveTab(tab);
    setRenderAlertsAmount(alertsRenderInitialValue);
  };

  const renderAlerts = () => (
    <>
      <Styles.Header>
        <Styles.HeaderTop>
          <Styles.TitleContainer>
            <Styles.Title>{titleLabel}</Styles.Title>
            {!isMobile && (
              <Tooltip content={alertsTooltipLabel} preferredPosition="bottomRight" width={244}>
                <IconFilledInfo size="sm" color="primaryMedium" />
              </Tooltip>
            )}
          </Styles.TitleContainer>
        </Styles.HeaderTop>
        <Styles.Tabs>
          <Button
            label={`${allNotificationsLabel} (${tabAlert.all.length})`}
            variant={activeTab === TABS.ALL ? 'tabActive' : 'tab'}
            onClick={() => changeActiveTab(TABS.ALL)}
            testId={`tab-${TABS.ALL}-button`}
          />
          <Button
            label={`${ticketNotificationsLabel} (${tabAlert.tickets.length})`}
            variant={activeTab === TABS.TICKETS ? 'tabActive' : 'tab'}
            onClick={() => changeActiveTab(TABS.TICKETS)}
            testId={`tab-${TABS.TICKETS}-button`}
          />
        </Styles.Tabs>
      </Styles.Header>
      {renderEmpty ? (
        <Styles.EmptyState isMobile={isMobile}>
          {noNotificationsLabel}
        </Styles.EmptyState>
      ) : (
        <>
          {notificationItems()}
        </>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <>
          {visible && (
            <Modal disablePadding>
              <Styles.MobileWrapper>
                <Styles.CloseButton onClick={() => setVisible(false)}>
                  <IconClose testId="closeButton" />
                </Styles.CloseButton>
                {renderAlerts()}
              </Styles.MobileWrapper>
            </Modal>
          )}
        </>
      ) : (
        <Styles.Wrapper data-testid="my-alerts">
          {(!!alertsArray.some(alert => alert.isNew === true)) && (
            <Styles.NotificationsCircle onClick={handleClick}>
              {alertsArray.filter(alert => alert.isNew === true).length}
            </Styles.NotificationsCircle>
          )}
          <Button
            onClick={handleClick}
            testId="alerts-button"
            iconLeft={<IconBell />}
            variant="headerButton"
            label={label}
            size="large"
          />
          {visible && (
            <Styles.FloatingMenu ref={menuRef} data-testid={`floating-menu-tab-${activeTab}`}>
              {renderAlerts()}
              <Styles.SeeMoreContainer hideButton={renderAlertsAmount >= tabAlert[activeTab].length}>
                <Button label={seeMoreLabel} size="large" variant="tertiary" onClick={handleOnSeeMore} testId="see-more-button" />
              </Styles.SeeMoreContainer>
            </Styles.FloatingMenu>
          )}
        </Styles.Wrapper>
      )}
      {showModalDnsPropagationAlert && <ModalDomainDnsPropagationAlert hideModal={handleShowModalDnsPropagationAlert} setMyAlertsVisible={setVisible} />}
      {showModalDomainNotContracted && <ModalDomainNotContracted hideModal={handleShowModalDomainNotContracted} isPreChat setMyAlertsVisible={setVisible} />}
      {showModalDomainNotConfigured && <ModalDomainNotConfigured hideModal={handleShowModalDomainNotConfigured} isPreChat />}
      {showModalDomainInPropagation && <ModalDomainInPropagation hideModal={handleShowModalDomainInPropagation} domainsList={domainInPropagation} mobile={isMobile} />}
      {showRefundModal && <RefundModal customerRefund={refundToShowOnModal} hideModal={handleShowRefundModal} mobile={isMobile} />}
      {showDuplicateOrdersModal && <DuplicateOrdersModal hideModal={handleShowDuplicateOrdersModal} showMyAlerts={() => setVisible(true)} />}
    </>
  );
};

export default MyAlerts;
