import React, { useState } from 'react';
import {
  withStyles, Fab, Tooltip, Modal, Card,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import MoreVert from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import SitelockSMARTScanForm from '../../SitelockSMARTScanForm';
import styles from './styles';

const SMARTScanUpdateForm = ({
  classes, t,
}) => {
  const [smartForm, setSmartForm] = useState(false);
  const {
    configured, error, success, pending,
  } = useSelector(state => state.sitelock.smartscan);
  const handleOpen = () => {
    setSmartForm(true);
  };
  const handleClose = () => {
    setSmartForm(false);
  };
  return (
    <div data-testid="smartscan-config-form">
      <Tooltip title={t('sitelock.smartscan.updateForm.tooltip')}>
        <Fab size="small" aria-label="More" className={classes.configButton} data-testid="more-btn" onClick={handleOpen}>
          <MoreVert fontSize="small" />
        </Fab>
      </Tooltip>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={smartForm}
        onClose={handleClose}
        className={classes.cardContainer}
        data-testid="update-form-fields"
      >
        <Card className={classes.formCard}>
          <SitelockSMARTScanForm update closeModal={handleClose} />
          <div className={classnames(classes.toast, classes.toastError, configured && error && classes.toastOpen)}>{t('sitelock.smartscan.form.error')}</div>
          <div className={classnames(classes.toast, classes.toastPending, configured && pending && classes.toastOpen)}>{t('sitelock.smartscan.form.pending')}</div>
          <div className={classnames(classes.toast, classes.toastSuccess, configured && success && classes.toastOpen)}>{t('sitelock.smartscan.form.success')}</div>
        </Card>
      </Modal>
    </div>
  );
};

export default withI18n()(withStyles(styles, { withTheme: true })(SMARTScanUpdateForm));
