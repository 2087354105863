const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
  pageTitle: {
    font: 'normal normal 300 24px/22px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    '& small': {
      font: 'normal normal 300 18px/22px Roboto',
      color: theme.color.regentGray,
    },
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '10px',
  },
  scanContainer: {
    backgroundColor: theme.color.white,
    justifyContent: 'space-between',
  },
  leftCol: {
    minWidth: '50%',
  },
  cardContainer: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  card: {
    alignItems: 'center',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    display: 'flex',
    minHeight: '60px',
    padding: '21px 24px',
    position: 'relative',

    [theme.breakpoints.up(768)]: {
      boxShadow: 'none',
    },
  },
  topCard: {
    paddingTop: '7px',
    paddingBottom: '7px',
  },
  verticalFlexCard: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '470px',
  },
  subtitle: {
    textAlign: 'left',
    font: 'normal normal normal 24px/29px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
  },
  description: {
    font: 'normal normal normal 14px/17px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    marginTop: '5px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    alignItems: 'center',
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    fontSize: '14px',
    fontWeight: '500',
    height: '37px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    textDecoration: 'none',
    transition: '300ms',
    '&:hover': {
      backgroundColor: theme.color.chambray,
    },
  },
  checkButton: {
    maxWidth: '156px',
    marginTop: '24px',
  },
  checkDomainDescription: {
    marginTop: '24px',
  },
  outlinedButton: {
    backgroundColor: theme.color.white,
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    '&:hover': {
      backgroundColor: theme.color.chambray,
      color: theme.color.white,
    },
  },
  disabledButton: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.color.disabled,
    cursor: 'default',
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: theme.color.disabled,
      cursor: 'default',
    },
  },
  buttons: {
    marginTop: '17px',
    display: 'flex',
    gap: '10px',
  },
  flexCard: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  flexTopCard: {
    flex: 1,
    alignItems: 'flex-start',
  },
  headerCard: {
    flex: 1,
    marginBottom: 6,
  },
  scanLimit: {
    color: theme.color.valencia,
    fontSize: '12px',
  },
  loadingCard: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outLineSLRounded: {
    alignItems: 'center',
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '19px',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: 12,
    height: 35,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.color.indigo}`,
      color: theme.color.white,
    },
  },
  toast: {
    height: '26px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    font: 'normal normal normal 12px/14px Roboto',
    maxHeight: '0px',
    overflow: 'hidden',
    transition: 'all 0.1s ease',
  },
  toastOpen: {
    maxHeight: '25px',
    transition: 'all 0.1s ease',
  },
  toastSecure: {
    backgroundColor: theme.color.oceanGreen,
    color: theme.color.white,
  },
  toastPending: {
    backgroundColor: theme.color.brightSun,
    color: theme.color.tundora,
  },
  toastVulnerable: {
    backgroundColor: theme.color.valencia,
    color: theme.color.white,
  },
  panelButton: {
    alignItems: 'center',
    alignSelf: 'center',
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '200px',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '12px',
    height: 32,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    textDecoration: 'none',
    transition: '300ms',
    width: 116,

    '&:hover': {
      backgroundColor: theme.color.indigo,
      color: theme.color.white,
    },

    [theme.breakpoints.down(1285)]: {
      alignSelf: 'flex-start',
      marginBottom: '24px',
    },

    [theme.breakpoints.down(745)]: {
      alignSelf: 'center',
    },
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  faq: {
    marginTop: '16px',
  },
});

export default styles;
