import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Card = styled.div`
  ${({ theme }) => css`

    border-radius: 0px 60px 60px 60px;
    background: ${theme.v1.color.action.primary};
    color: ${theme.v1.color.white.primary};
    font-size: 17px;
    height: 310px;
    text-align: center;
    width: 300px;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 1194px) {
        margin-bottom: 15px;
    }
  `}
`;

export const DivCardText = styled.div`
  ${() => css`
    width: 82%;
  `}
`;

export const CardText = styled.p``;

export const CardTextAutor = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.v1.font.weights.bold}
  `}
`;

export const CardTextFooter = styled.p``;

export const IconCard = styled.div`
    margin-top: 30px;
`;
