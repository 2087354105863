import {
  Button, IconCheck, Input, IconChecklist,
} from 'gatorcomponents';
import * as Styles from './ChangePasswordV2.styles';
import React, { useState, useCallback, useEffect } from 'react';
import useLocale from '@/hooks/useLocale';
import changePwValidations from '@/utils/Validators/changePassword';
import { saveChangePassword } from '@/redux/actions/changePassword';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import { SUCCESS_CHANGE_PASSWORD } from '@/redux/actions/actionsTypes';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '@/redux/actions/notifications';

const defaultFormData = {
  currentPassword: { value: '', error: '' },
  inputPassword: { value: '', error: '' },
  inputConfirmPassword: { value: '', error: '' },
};

const ChangePasswordV2 = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const [formValidation, setFormValidation] = useState({});

  const dispatch = useDispatch();
  const { myData: myDataLocale } = useLocale();
  const changePasswordState = useSelector(state => state.changePassword);

  const changePasswordLocale = myDataLocale.changePassword;

  const noValues = !formData.currentPassword.value
    && !formData.inputPassword.value
    && !formData.inputConfirmPassword.value;

  const noErrors = !formData.currentPassword.error
    && !formData.inputPassword.error
    && !formData.inputConfirmPassword.error;

  const canSavePassword = noErrors && (
    formValidation.hasLowerAndUpperCase
    && formValidation.hasNumber
    && formValidation.hasSymbol
    && formValidation.hasAtLeastEight
    && formValidation.matchConfirmation
    && formValidation.differentPasswords
  );

  const validateFormRules = () => {
    const hasLowerAndUpperCase = changePwValidations.hasLowerAndUper(formData.inputPassword.value);
    const hasNumber = changePwValidations.hasNumber(formData.inputPassword.value);
    const hasSymbol = changePwValidations.hasSpecial(formData.inputPassword.value);
    const hasAtLeastEight = formData.inputPassword.value.length >= 8;
    const matchConfirmation = formData.inputPassword.value.length && formData.inputConfirmPassword.value === formData.inputPassword.value;
    const differentPasswords = formData.inputPassword.value && formData.currentPassword.value && formData.currentPassword.value !== formData.inputPassword.value;

    const valitadions = {
      hasLowerAndUpperCase,
      hasNumber,
      hasSymbol,
      hasAtLeastEight,
      matchConfirmation,
      differentPasswords,
    };

    return valitadions;
  };

  const onChangeField = (field, value) => {
    const newFormData = { ...formData };
    newFormData[field].value = value;
    newFormData[field].error = '';

    setFormData(newFormData);
    validateFormRules();
  };

  const onBlurField = (field, value) => {
    const newFormData = { ...formData };

    const verifyFieldErrors = () => {
      const successInputValidations = formValidation.hasLowerAndUpperCase
        && formValidation.hasNumber
        && formValidation.hasSymbol
        && formValidation.hasAtLeastEight;

      if (value === '') {
        return field === 'currentPassword' ? changePasswordLocale.errorCurrentEmpty : changePasswordLocale.errorEmptyField;
      }

      if (field === 'currentPassword' && value.length < 6) {
        return changePasswordLocale.errorCurrentMinimumCharacters;
      }

      if (field === 'inputPassword') {
        if (value.length < 8) {
          return changePasswordLocale.errorMinimumCharacters;
        }
        if (!successInputValidations) {
          return changePasswordLocale.errorMinimumRequirements;
        }
      }

      if (field === 'inputConfirmPassword' && !formValidation.matchConfirmation) {
        return changePasswordLocale.errorConfirmNew;
      }
    };

    newFormData[field].error = verifyFieldErrors() || '';
    setFormData(newFormData);
  };

  const setValidations = useCallback(() => {
    setFormValidation(validateFormRules());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSave = () => {
    const passwords = {
      existingpw: formData.currentPassword.value,
      newpw: formData.inputPassword.value,
      confirmpw: formData.inputConfirmPassword.value,
    };

    if (!changePasswordState.loading) {
      dispatch(saveChangePassword(passwords)).then((response) => {
        if (response.type === SUCCESS_CHANGE_PASSWORD) {
          dispatch(enqueueSnackbar(changePasswordLocale.notifierSuccess, notifierSuccess));
        } else if (!response.error_message && response.notifications.length === 0) {
          dispatch(enqueueSnackbar(changePasswordLocale.notifierError, notifierError));
        }
      });
    }
  };

  const resetFormData = useCallback(() => {
    const emptyFormData = {
      currentPassword: { value: '', error: '' },
      inputPassword: { value: '', error: '' },
      inputConfirmPassword: { value: '', error: '' },
    };

    if (!changePasswordState.loading) {
      setFormData(emptyFormData);
    }
  }, [changePasswordState.loading]);

  useEffect(() => {
    setValidations();
  }, [setValidations]);

  useEffect(() => {
    resetFormData();
  }, [resetFormData]);

  const mountListItem = ({ testid, label, success }) => (
    <Styles.ListItem success={success}>
      <Styles.IconContainer>
        {success ? (
          <IconCheck testId={`${testid}-checked`} size="sm" color="activeMedium" />
        ) : (
          <Styles.IconDot />
        )}
      </Styles.IconContainer>
      {label}
    </Styles.ListItem>
  );

  return (
    <Styles.Wrapper>
      <Styles.Container>
        <Styles.Title>{changePasswordLocale.title}</Styles.Title>
        <Styles.PasswordFormContainer>
          <Styles.FormContainer>
            <Styles.InputContainer error={formData.currentPassword.error.length}>
              <Input
                id="currentPassword"
                testId="currentPassword"
                name="currentPassword"
                type="password"
                label={changePasswordLocale.currentPwLabel}
                placeholder={changePasswordLocale.currentPwPlaceholder}
                onChange={fieldData => onChangeField('currentPassword', fieldData.currentTarget.value)}
                onBlur={({ name, value }) => onBlurField(name, value)}
                value={formData.currentPassword.value}
                errorMessage={formData.currentPassword.error}
              />
            </Styles.InputContainer>
            <Styles.InputContainer error={formData.inputPassword.error.length}>
              <Input
                id="inputPassword"
                testId="inputPassword"
                name="inputPassword"
                type="password"
                label={changePasswordLocale.newPwLabel}
                placeholder={changePasswordLocale.newPwPlaceholder}
                onChange={fieldData => onChangeField('inputPassword', fieldData.currentTarget.value)}
                onBlur={({ name, value }) => onBlurField(name, value)}
                value={formData.inputPassword.value}
                errorMessage={formData.inputPassword.error}
              />
            </Styles.InputContainer>
          </Styles.FormContainer>

          <Styles.ThirdFormContainer>
            <Styles.ThirdFormField>
              <Styles.InputContainer error={formData.inputConfirmPassword.error.length}>
                <Input
                  id="inputConfirmPassword"
                  testId="inputConfirmPassword"
                  name="inputConfirmPassword"
                  type="password"
                  label={changePasswordLocale.repeatNewPwLabel}
                  placeholder={changePasswordLocale.newPwPlaceholder}
                  onChange={fieldData => onChangeField('inputConfirmPassword', fieldData.currentTarget.value)}
                  onBlur={({ name, value }) => onBlurField(name, value)}
                  value={formData.inputConfirmPassword.value}
                  errorMessage={formData.inputConfirmPassword.error}
                />
              </Styles.InputContainer>
            </Styles.ThirdFormField>

            {(!noValues || !noErrors) && (
              <Styles.ButtonContainer>
                <Button
                  label={changePasswordLocale.buttonLabel}
                  size="large"
                  width="fit"
                  onClick={handleSave}
                  disabled={!canSavePassword}
                  loading={changePasswordState.loading}
                />
              </Styles.ButtonContainer>
            )}
          </Styles.ThirdFormContainer>

          <Styles.ListContainer>
            <Styles.RequirementsTitleContainer>
              <Styles.IconContainer>
                <IconChecklist size="md" />
              </Styles.IconContainer>
              <Styles.RequirementsTitle>
                {changePasswordLocale.requirementsList.listTitle}
              </Styles.RequirementsTitle>
            </Styles.RequirementsTitleContainer>
            <Styles.RequirementsList>
              {mountListItem({ testid: 'upper-n-lower', label: changePasswordLocale.requirementsList.upperAndLowerItem, success: formValidation.hasLowerAndUpperCase })}
              {mountListItem({ testid: 'number', label: changePasswordLocale.requirementsList.numberLabel, success: formValidation.hasNumber })}
              {mountListItem({ testid: 'symbol', label: changePasswordLocale.requirementsList.symbolLabel, success: formValidation.hasSymbol })}
              {mountListItem({ testid: 'min-char', label: changePasswordLocale.requirementsList.minCharLabel, success: formValidation.hasAtLeastEight })}
              {mountListItem({ testid: 'same-on-both', label: changePasswordLocale.requirementsList.sameOnBothLabel, success: formValidation.matchConfirmation })}
              {mountListItem({ testid: 'different-password', label: changePasswordLocale.requirementsList.differentPasswords, success: formValidation.differentPasswords })}
            </Styles.RequirementsList>
          </Styles.ListContainer>
        </Styles.PasswordFormContainer>
      </Styles.Container>
    </Styles.Wrapper>
  );
};

export default ChangePasswordV2;
