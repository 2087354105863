import styled, { css } from 'styled-components';

export const Separator = styled.hr`
  ${({
    width, height, primaryColor,
  }) => css`
     background-color:${primaryColor};
     border:none;
     height:${height};
     margin:24px 0px 24px 0px;
     width:${width};
    `}
`;
