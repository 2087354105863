import { LinkNaBioActionTypes } from './linkNaBio.types';

export const initialState = {
  loading: false,
  linksLoading: false,
  socialMediaLoading: false,
  available: undefined,
  shouldGetProfiles: true,
  profiles: [],
  socialMediaList: [],
  statistics: {},
  domainToLinkExists: undefined,
};

const linkAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case LinkNaBioActionTypes.LINK_NA_BIO_AVAILABILITY_SET: {
      return {
        ...state,
        available: action.payload,
      };
    }
    case LinkNaBioActionTypes.LINK_NA_BIO_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case LinkNaBioActionTypes.LINKS_FROM_LINK_NA_BIO_LOADING: {
      return {
        ...state,
        linksLoading: action.payload,
      };
    }
    case LinkNaBioActionTypes.SOCIAL_MEDIA_FROM_LINK_NA_BIO_LOADING: {
      return {
        ...state,
        socialMediaLoading: action.payload,
      };
    }
    case LinkNaBioActionTypes.LINK_NA_BIO_PROFILES_SET: {
      return {
        ...state,
        shouldGetProfiles: false,
        profiles: action.payload,
      };
    }
    case LinkNaBioActionTypes.CREATE_LINK_NA_BIO_SUCCESS:
    case LinkNaBioActionTypes.DELETE_LINK_NA_BIO_SUCCESS:
    case LinkNaBioActionTypes.SORT_SOCIAL_MEDIA_SUCCESS:
    case LinkNaBioActionTypes.DELETE_SOCIAL_MEDIA_SUCCESS:
    case LinkNaBioActionTypes.ADD_SOCIAL_MEDIA_SUCCESS:
    case LinkNaBioActionTypes.UPDATE_SOCIAL_MEDIA_SUCCESS:
    case LinkNaBioActionTypes.UPDATE_LINK_NA_BIO_SUCCESS: {
      return {
        ...state,
        shouldGetProfiles: true,
      };
    }
    case LinkNaBioActionTypes.SOCIAL_MEDIA_ICONS_SET: {
      return {
        ...state,
        socialMediaList: action.payload,
      };
    }
    case LinkNaBioActionTypes.LINK_NA_BIO_STATISTIC_SET: {
      return {
        ...state,
        statistics: action.payload,
      };
    }
    case LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_RESET: {
      return {
        ...state,
        domainToLinkExists: undefined,
      };
    }
    case LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_SUCCESS: {
      return {
        ...state,
        domainToLinkExists: true,
      };
    }
    case LinkNaBioActionTypes.VERIFY_DOMAIN_TO_LINK_EXISTS_FAILURE: {
      return {
        ...state,
        domainToLinkExists: false,
      };
    }
    default:
      return state;
  }
};

export default linkAvailabilityReducer;
