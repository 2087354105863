import { CALL_API } from '@/middleware/api';
import { RESEND_EMAIL, CHANGE_EMAIL } from '@/config/api';
import {
  REQUEST_RESEND_EMAIL, RECEIVE_RESEND_EMAIL, ERROR_RESEND_EMAIL,
  REQUEST_CHANGE_EMAIL, RECEIVE_CHANGE_EMAIL, ERROR_CHANGE_EMAIL,
  EMAIL_CHANGE_FLOW,
} from './actionsTypes';

export const receiveResendEmail = client => ({
  type: RECEIVE_RESEND_EMAIL,
  client,
});

export const resendEmailVerify = data => ({
  [CALL_API]: {
    endpoint: RESEND_EMAIL,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_RESEND_EMAIL }),
      success: response => receiveResendEmail(response),
      error: response => ({ type: ERROR_RESEND_EMAIL, client: response.response.data }),
    },
  },
});

export const receiveChangeEmail = email => ({
  type: RECEIVE_CHANGE_EMAIL,
  email,
});

export const changeEmailVerify = data => ({
  [CALL_API]: {
    endpoint: CHANGE_EMAIL,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_CHANGE_EMAIL }),
      success: response => receiveChangeEmail(response),
      error: response => ({ type: ERROR_CHANGE_EMAIL, info: response }),
    },
  },
});

export const emailChangeFlow = (bool, currentUrl) => ({ type: EMAIL_CHANGE_FLOW, data: { bool, currentUrl } });
