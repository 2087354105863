import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    align-items: center;
    background-color:${theme.palette.primary.contrastText};
    display: flex;
    flex-direction: row;
    max-width: 604px;
    padding: 24px;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 30px 0 15px 0;
    text-align: left;
  `}
`;

export const IconWrapper = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const MethodName = styled.h4`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 21px;
    margin: 0 0 14px 0;
    text-align: left;
  `}
`;

export const TotalLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.lg};
    margin: 0px 0px 2px 0px;
  `}
`;

export const Amount = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 21px;
    margin: 0px 0px 16px 0px;
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 19px;
    margin: 0;
  `}
`;
