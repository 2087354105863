import { IconArrowForward, IconInfo } from '@/icons/';
import { TextButton } from '@/pages/common/v1';
import React, { useContext, useState } from 'react';
import * as Styles from './WizardWondersuiteToolCard.styles';
import { ThemeContext } from 'styled-components';
import { Button, IconCheck, Tag } from 'gatorcomponents';
import { useWizardToolsLocale } from '@/hooks/sites/wizard/useWizardTools/useWizardTools.locale.jsx';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN } from '@/config/GrowthBook/constants';

let timeoutAutoSsl = null;
const WizardWondersuiteToolCard = ({
  tool,
  showMoreText,
  showLessText,
  testId,
  onToolCardClick,
  sitesLoading,
  requestAutoSsl,
}) => {
  const theme = useContext(ThemeContext);
  const [open, toggleOpen] = useState(tool.featured);
  const isSiteBuilderWindowOpenEnabled = useFeatureIsOn(BRAVO_WIZARD_SITEBUILDER_WINDOW_OPEN);

  const handleShowMore = () => {
    toggleOpen(!open);
  };

  const toolCardClick = (toolTitle) => {
    tool.action && tool.action();
    onToolCardClick && onToolCardClick(toolTitle);

    if (toolTitle === useWizardToolsLocale.sitebuilder.title && isSiteBuilderWindowOpenEnabled) {
      const tenSeconds = 10000;
      clearTimeout(timeoutAutoSsl);
      timeoutAutoSsl = setTimeout(() => {
        requestAutoSsl && requestAutoSsl();
      }, tenSeconds);
    }
  };

  return (
    <Styles.Card featured={tool.featured} data-testid={testId}>
      {tool.tag && (
      <Styles.TagWrapper>
        <Tag label={tool.tag} />
      </Styles.TagWrapper>
      )}
      <Styles.CardContainer>
        <Styles.CardHeader>
          {tool.iconList && (
          <Styles.ListIconWrapper>
            {tool.iconList.map(iconObject => (
              <Styles.ListIcon key={iconObject.title}>
                <Styles.ListIconImage alt={iconObject.title} src={iconObject.icon} />
              </Styles.ListIcon>
            ))}
          </Styles.ListIconWrapper>
          )}
          <Styles.CardTitle>
            {tool.title}
          </Styles.CardTitle>
          {tool.subtitle && (
            <Styles.CardSubtitle>
              {tool.subtitle}
            </Styles.CardSubtitle>
          )}
          {tool.description() !== null ? (
            <Styles.CardDescription>
              {tool.description()}
            </Styles.CardDescription>
          )
            : <Styles.WithoutDesc />
          }
          <Styles.ActionWrapper featured={tool.featured}>
            <Button
              testId={`button-${testId}`}
              variant={tool.featured ? 'primary' : 'secondary'}
              size="medium"
              label={tool.actionLabel}
              loading={sitesLoading}
              onClick={() => toolCardClick(tool.titleText)}

            />
          </Styles.ActionWrapper>
        </Styles.CardHeader>

        <Styles.CardSeparator />

        <Styles.ShowMoreWrapper open={open}>
          <TextButton
            text={open ? showLessText : showMoreText}
            onClick={() => handleShowMore()}
          />
          <IconArrowForward size={18} />
        </Styles.ShowMoreWrapper>

        <Styles.ContentWrapper open={open} data-testid="content-wrapper">
          {tool.preList && (<Styles.PreList>{tool.preList}</Styles.PreList>)}
          {tool.items.map(item => ((
            <Styles.LabelWrapper key={item()}>
              <Styles.FeaturesListIcon>
                <IconCheck size="sm" color="activeMedium" />
              </Styles.FeaturesListIcon>
              <Styles.Label
                data-testid="item"
              >
                {item && item()}
              </Styles.Label>
            </Styles.LabelWrapper>
          )))}
          {tool.warning && (
            <>
              <Styles.CardWarningSeparator />
              <Styles.WarningWrapper>
                <Styles.WarningIconWrapper>
                  <IconInfo size="16" color={theme.v2.colors.brand.primaryBlue} />
                </Styles.WarningIconWrapper>
                <Styles.Label data-testid="select-label">
                  {tool.warning}
                </Styles.Label>
              </Styles.WarningWrapper>
            </>
          )}
        </Styles.ContentWrapper>

      </Styles.CardContainer>
    </Styles.Card>
  );
};

export default WizardWondersuiteToolCard;
