import React from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { cPanelWebmailTagManager, hireTitanCPanelWebmailWithTitanTrial } from '@/utils/ThirdParties/tagManager';
import * as actions from '@/redux/actions/emails';
import { emailsActions } from '@/redux/modules/emails';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { RECEIVE_GET_ACTIVE_EMAIL_CPANEL } from '@/redux/actions/actionsTypes';
import { BASIC_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import styles from './styles';
import { useHistory } from 'react-router';
import { EmailsActionTypes } from '@/redux/modules/emails/email.types';
import { ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ModalActiveCPanel = ({
  onClose, open, classes, t, idProEmail, domain, domainObject,
}) => {
  const dispatch = useDispatch();

  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );

  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;
  const history = useHistory();
  const domainConfigured = domainObject && alphaFlowToSellTitanTrialByModalInHirePlan && domainObject.domain_configured === false && domainObject.configured === true;

  const executeAction = () => {
    if (shouldRenderNewTitanCards) {
      hireTitanCPanelWebmailWithTitanTrial();
    }
    if (domainConfigured) {
      const updateDomainSetupData = {
        enabledUpdateDomainSetup: true,
        domain: domainObject.domain,
        customerEmail: domainObject.customer_email,
      };

      dispatch(emailsActions.emails.updateDomainSetup.request(updateDomainSetupData)).then((responseData) => {
        if (responseData.type === EmailsActionTypes.UPDATE_DOMAIN_SETUP_REQUEST) {
          cPanelWebmailTagManager(BASIC_PLAN);
          history.push(`${t('routes.emailsList')}?cpActivate=true&domain=${domain}`);
        } else {
          dispatch(enqueueSnackbar(t('snackbar.error.is_not_possible_active_email_cpanel'), notifierErrorLong));
        }
      });
    } else {
      dispatch(actions.requestReceiveActiveEmailCPanel(idProEmail, 'local')).then((responseData) => {
        if (responseData.type === RECEIVE_GET_ACTIVE_EMAIL_CPANEL) {
          cPanelWebmailTagManager(BASIC_PLAN);
          history.push(`${t('routes.emailsList')}?cpActivate=true&domain=${domain}`);
        } else {
          dispatch(enqueueSnackbar(t('snackbar.error.is_not_possible_active_email_cpanel'), notifierErrorLong));
        }
      });
    }
    onClose();
  };
  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      open={open}

    >
      <div className={classes.cpanelDialogActiveDialog}>
        <div id="dialog-title" className={classes.closeDialog}>
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.titleDialog}>{t('proEmail.hirePlan.cpanelDialogActiveTitle')}</Typography>
          <Typography className={classes.descriptionDialog}>
            <Trans i18nKey="proEmail.hirePlan.cpanelDialogActiveDescription" values={{ productName: t('proEmail.hirePlan.hostingPlan') }}>
              <>0</>
              <b>1</b>
              <>2</>
            </Trans>
          </Typography>
          <Typography className={classes.descriptionDialog}>{t('proEmail.hirePlan.cpanelDialogActiveDescriptionAdvice')}</Typography>
          <Button onClick={executeAction} className={classes.activeCPanel} data-testid="linkButton">
            {t('proEmail.hirePlan.cpanelDialogActiveAction')}
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default withI18n()(withStyles(styles)(ModalActiveCPanel));
