import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button } from 'gatorcomponents';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { config, COUNTRY } from '@/config';
import logger from '@/utils/logger';
import { getCreditCardAttempt, saveCreditCard, saveCreditCardAttempt } from '@/redux/actions/creditCardPaas';
import { ERROR_GATORPAY_PAAS_SAVE_CREDITCARD, RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD } from '@/redux/actions/actionsTypes';
import Loader from '@/components/Layout/Loader';
import { notifierError } from '@/utils/Application/notifier';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { generateRecaptchaToken, loadReCaptchaPaaS } from '@/utils/Application/reCaptcha';
import { isBRServer } from '@/utils/Validators/validation';
import styles from './styles';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_PAYMENT_METHOD_MODAL, XRAY_VERIFY_CARD_CHECK, XRAY_VERIFY_CARD_LOGS } from '@/config/GrowthBook/constants';
import { billingActions } from '@/redux/modules';

const {
  IFRAME_URL,
  CLIENT_ID,
  IFRAME_PAGENAME,
} = config.PAAS_CONFIG;


const CreditCardPaas = ({
  classes,
  t,
  onSave,
  isDialog,
  gaEvent,
  isNewLayout = false,
}) => {
  const shouldHandleNewCreditCardDispatch = useFeatureIsOn(CHARLIE_CHANGE_PAYMENT_METHOD_MODAL);
  const [isButtonEnabled, setButtonEnabled] = useState(true);
  const [isLoadingEnabled, setLoadingEnabled] = useState(true);
  const [heightIframe, setHeightIframe] = useState(260);
  const isVerifyCardCheckActive = useFeatureIsOn(XRAY_VERIFY_CARD_CHECK);
  const isVerifyCardLogsActive = useFeatureIsOn(XRAY_VERIFY_CARD_LOGS);
  const isEnabledAttemptCreditCard = true;
  const dispatch = useDispatch();
  const creditCardAttempt = useSelector(state => state.creditCardAttempt);
  const isEnabledNewRecaptchaPass = true;

  const checkSubscriptionsToChangePaymentMethod = () => {
    shouldHandleNewCreditCardDispatch && dispatch(billingActions.subscriptions.requestSubscriptionsNotInCreditCard());
  };

  const postMessage = (body) => {
    const { contentWindow } = document.getElementById('token-iframe');
    contentWindow.postMessage(JSON.stringify({
      securePayMessage: true,
      body,
    }), IFRAME_URL);
  };

  const getLoader = () => (
    isLoadingEnabled ? <Loader /> : <div />
  );

  const callGetToken = (token) => {
    postMessage({
      callFunction: {
        name: 'hgLatamGetToken',
        arguments: [token],
      },
    });
  };

  const updateHeight = (h) => {
    setHeightIframe(h + 30);
  };

  const onMessage = (event) => {
    if (event.data.securePayMessage !== true) {
      return false;
    }
    let { body } = event.data;
    if (body.constructor === String) {
      try {
        body = JSON.parse(body);
      // eslint-disable-next-line no-console
      } catch (e) { console.log(e); }
    }
    if (body.constructor === Object) {
      if (body.isValid !== undefined && body.fields !== undefined) {
        if (!body.isValid) {
          dispatch(enqueueSnackbar('snackbar.creditCard.paas.errorSaveCreditCard', notifierError));
          setButtonEnabled(true);
          setLoadingEnabled(true);
          return;
        }
        generateRecaptchaToken(isEnabledNewRecaptchaPass ? config.NEW_RECAPTCHA_PAAS : config.RECAPTCHA_PAAS, 'userexistis').then((token) => {
          callGetToken(token);
        }).catch(() => {
          dispatch(enqueueSnackbar('snackbar.creditCard.paas.errorSaveCreditCard', notifierError));
        });
      }
      if (body.creditCard !== undefined && body.creditCard.data !== '') {
        body.creditCard.token = body.id;
        // Verify creditcard only BR
        if (isVerifyCardLogsActive) {
          logger.info(`VerifyCreditCard response - Code: '${body.properties.ProviderReturnCode}', Message: '${body.properties.ProviderReturnMessage}'`);
        }
        if (isVerifyCardCheckActive) {
          if (body.properties !== undefined && body.properties.Status !== undefined && body.properties.Status !== 1) {
            dispatch(enqueueSnackbar('snackbar.creditCard.paas.errorVerifyCard', notifierError));
            setButtonEnabled(true);
            setLoadingEnabled(false);

            if (isEnabledAttemptCreditCard && isBRServer) {
              dispatch(saveCreditCardAttempt(body.creditCard.token));
            }
            return;
          }
        }

        setButtonEnabled(false);
        dispatch(saveCreditCard(body.creditCard)).then((data) => {
          onSave(data.type);
          checkSubscriptionsToChangePaymentMethod();
          setLoadingEnabled(false);
          if (data.type === ERROR_GATORPAY_PAAS_SAVE_CREDITCARD) {
            setButtonEnabled(true);
            setLoadingEnabled(true);
          }

          if (isEnabledAttemptCreditCard && isBRServer && data.type === RECEIVE_GATORPAY_PAAS_SAVE_CREDITCARD) {
            dispatch(saveCreditCardAttempt(body.creditCard.token, 'ip'));
          }
        });
      }
      if (body.size !== undefined && body.size.height !== 0) {
        updateHeight(body.size.height);
      }
    }
  };

  const submitCreditCard = () => {
    if (isEnabledAttemptCreditCard && isBRServer) {
      dispatch(getCreditCardAttempt());
      if (creditCardAttempt !== undefined && !creditCardAttempt.response) {
        dispatch(enqueueSnackbar('snackbar.creditCard.paymentFailByAttemptsLimit', notifierError));
        setButtonEnabled(true);
        setLoadingEnabled(false);
        return;
      }
    }

    setButtonEnabled(false);
    postMessage({
      callFunction: {
        name: 'hgLatamValidateAll',
        arguments: [],
      },
    });
  };

  const clearFormData = () => {
    const { contentWindow } = document.getElementById('token-iframe');
    const origin = IFRAME_URL;
    contentWindow.postMessage(JSON.stringify({
      securePayMessage: true,
      body: {
        callFunction: {
          name: 'hgLatamClear',
          arguments: [],
        },
      },
    }), origin);
  };
  useEffect(() => {
    loadReCaptchaPaaS(isEnabledNewRecaptchaPass);
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    dispatch(getCreditCardAttempt());
  }, [dispatch]);

  return (
    <div className={classnames(classes.divContainer, { [isNewLayout ? classes.newdivContainerDialog : classes.divContainerDialog]: isDialog })}>
      <iframe
        className={classnames(classes.iframe, {})}
        height={`${heightIframe}px`}
        src={`${IFRAME_URL}/payment/${IFRAME_PAGENAME}?creditshow=show&portal=show&clientId=${CLIENT_ID}&country=${COUNTRY}`}
        id="token-iframe"
        title="cc"
      />
      {isButtonEnabled
        ? (
          <div>
            <div id="goo" />
            <div className={classes.actions}>
              <Button
                label={t('saveCreditCard')}
                variant="secondary"
                onClick={submitCreditCard}
                testId="save-button"
              />
              <Button
                label={t('clean')}
                variant="text"
                onClick={() => { clearFormData(); gaEvent(); }}
                testId="clean-button"
              />
            </div>
          </div>

        ) : (getLoader())
      }
    </div>
  );
};

CreditCardPaas.propTypes = {
  onSave: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(
  withI18n()(withMobileDialog()(CreditCardPaas)),
);
