import React, { useContext } from 'react';
import {
  Button,
  Link,
  Modal,
} from '@/pages/common';
import * as Styles from './ModalPlanCanceled.styles';
import { IconClose, IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { locale } from '@/utils/locale';
import { Trans } from 'react-i18next';
import { modalCloseAction } from '../../SitesHandler/SitesHandler.enum';

const ModalPlanCanceled = ({
  title, button, contentRedirect, contentDomainName, handleOnCloseWarningModal,
}) => {
  const handleOnClose = action => (
    handleOnCloseWarningModal && handleOnCloseWarningModal(action)
  );
  const theme = useContext(ThemeContext);
  return (
    <Modal maxWidth={625} testId="modal-plan-canceled" disablePadding>
      <Styles.Title data-testid="modal-plan-canceled-title">
        {title}
      </Styles.Title>
      <Styles.CloseButton onClick={() => handleOnClose(modalCloseAction.close)}>
        <IconClose testId="close-button" />
      </Styles.CloseButton>
      <Styles.ContentWrapper>
        <IconInfo color={theme.palette.yellow.mustard} size="26" />
        <Styles.Content data-testid="ModalPlanCanceledContent">
          <Trans i18nKey="sites.siteModals.planCanceled.contentDomainName" values={{ domain: contentDomainName }}>
            <>0</>
            <Styles.DomainName>
              <>1</>
            </Styles.DomainName>
            <>2</>
          </Trans>
          <Link target="_blank" href={locale('routes.domainRegister')} text={contentRedirect} />
        </Styles.Content>
      </Styles.ContentWrapper>
      <Styles.Action>
        <Button testId="understand-button" onClick={() => handleOnClose(modalCloseAction.understand)} font="secondary" variant="newPrimary" label={button} size="large" />
      </Styles.Action>
    </Modal>
  );
};

export default ModalPlanCanceled;
