import { Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withI18n } from 'react-i18next';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons/';
import { Redirect } from 'react-router';
import styles from './styles';

const TitanUpgradePlanCycleTitle = ({
  classes, domainObject, domain, t,
}) => {
  const [redirect, toggleRedirect] = useState(false);

  if (redirect) {
    return <Redirect to={`${t('routes.emailsList')}${t('routes.emailsTitan')}/${domainObject.domain}`} />;
  }

  return (
    <div className={classes.titleCard}>
      <button type="button" onClick={() => toggleRedirect(true)} className={classes.redirectButton}>
        <ArrowBackIcon />
      </button>

      <div>
        <Typography className={classes.actualPlan}>
          {domainObject.plan !== 'free' ? domainObject.plan : `${t('proEmail.type.free')}`}
          <span className={classes.actualDomainSpan}>{`(${domain})`}</span>
        </Typography>
      </div>
    </div>
  );
};

export default withI18n()(withStyles(styles)(TitanUpgradePlanCycleTitle));
