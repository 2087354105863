import styled from 'styled-components';

export const Wrapper = styled.span`
  display: inline-block;

  svg {
    display: block;
    height: 14px;
    width: 14px;
  }
`;
