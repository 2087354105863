import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import { Redirect, useParams } from 'react-router';
import NoResultMessage from '@/components/Notification/NoResultMessage';
import NoResultMessageTrans from '@/components/Notification/NoResultMessageTrans';
import Loader from '@/components/Layout/Loader';
import FaqEmails from '@/components/FrequentlyQuestions/FaqEmails';
import FilterEmails from '@/components/Filters/FilterEmails';
import FilterCard from '@/components/Cards/FilterCard';
import FilterButton from '@/components/Buttons/FilterButton';
import ActionButton from '@/components/Buttons/ActionButton';
import ManageEmailCpanel from '@/components/Email/ManageEmailCpanel';
import EmailItem from '@/components/Email/EmailItem';
import { loadEmails, loadHostingTypes } from '@/redux/actions/emails';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import styles from './styles';
import PageMessages from '@/pages/common/PageMessages';

const EmailsPage = ({ t, maxVisible, classes }) => {
  const stateEmails = useSelector(state => state.emails);
  const loadingHostings = stateEmails.hostings.loading;
  const hostings = stateEmails.hostings.list;
  const emailsLoading = stateEmails.loading;
  const totalEmails = stateEmails.total;
  const { domain } = useParams();
  let emails = stateEmails.list;

  if (domain) {
    emails = stateEmails.list.filter(email => email.domain === domain);
  }

  const isEmptyEmails = emails.length === 0;

  const [visible, setVisible] = useState(maxVisible);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadHostingTypes());
    dispatch(loadEmails());
  }, [dispatch]);

  const loadMore = (length) => {
    setVisible(visible + (length - visible));
  };

  const handleEmailsMessages = (
    <>
      { // Show message if have shared host but havent any email account created
        hostings.hosting > 0 && totalEmails === 0 && (
          <NoResultMessage>{t('noResults.tab.emails')}</NoResultMessage>
        )}

      { // Show message if dont have any shared host
        hostings.hosting === 0 && (
          <NoResultMessageTrans
            languageKey="noResults.tab.host"
            urlTarget={HOSTING_URL}
          />
        )}
    </>
  );

  if (loadingHostings || emailsLoading) {
    return <Loader />;
  }

  const filterButton = (
    <FilterButton target={`${t('routes.emails')}${t('routes.createEmail')}`}>
      {t('emails.createAccount')}
    </FilterButton>
  );

  if (domain === undefined) {
    return <Redirect to={`${t('routes.emailsList')}`} />;
  }

  return (
    <>
      <PageMessages
        displayDomainAlertManager={false}
        displayDomainPropagationAlert
      />

      <div className={classes.contentWrapper}>
        <FilterCard title={t('lists.emails')} button={hostings.hosting > 0 ? filterButton : ''} useMediaWidth={1165}>
          <FilterEmails />
        </FilterCard>
      </div>

      { // Show blue alert if have VPS or Dedicated Server
      hostings.server > 0 && (
        <ManageEmailCpanel />
      )}

      {totalEmails === 0 && (
        handleEmailsMessages
      )}

      {totalEmails !== 0 && (
        <div className={classes.contentWrapper}>

          {isEmptyEmails ? (
            <NoResultMessage>{t('noResults.filter')}</NoResultMessage>
          ) : (
            <>
              {emails.length > 0 && emails.slice(0, visible).map(email => (
                <EmailItem key={email.id} email={email} />
              ))}
              {visible < emails.length && (
              <ActionButton action={() => loadMore(emails.length)}>
                {t('btn.loadMore')}
              </ActionButton>
              )}
            </>
          )}
        </div>
      )}
      <div className={classes.bottomInfoWrapper}>
        <FaqEmails />
      </div>
    </>
  );
};

EmailsPage.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  maxVisible: PropTypes.number,
};

EmailsPage.defaultProps = {
  maxVisible: 5,
};

export default withStyles(styles)(withI18n()(EmailsPage));
