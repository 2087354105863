const styles = theme => ({
  header: {
    borderBottom: `1px solid ${theme.color.regentGray}`,
    display: 'flex',
    height: 40,
    marginBottom: 5,
    marginLeft: 6,
    width: 'calc(100% - 12px)',
  },
  headerItem: {
    alignItems: 'center',
    color: theme.color.regentGray,
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '17px',
    padding: '0px 0px 0px 23px',
    position: 'sticky',
  },
  headerItemType: {
    minWidth: 'calc(71px + 28px)',
  },
  headerItemName: {
    minWidth: 'calc(152px + 38px)',
    '@media screen and (max-width:1340px)': {
      minWidth: 'calc(76px + 28px)',
      maxWidth: 'calc(76px + 28px)',
    },
  },
  headerItemClass: {
    minWidth: 'calc(60px + 42px)',
    '@media screen and (max-width:1340px)': {
      minWidth: 'calc(30px + 42px)',
      maxWidth: 'calc(30px + 42px)',
    },
  },
  headerItemTtl: {
    minWidth: 'calc(77px + 23px)',
    '@media screen and (max-width:1135px)': {
      minWidth: 'calc(35px + 23px)',
      maxWidth: 'calc(35px + 23px)',
    },
  },
  headerItemValue: {
    minWidth: 'calc(231px + 44px )',
    '@media screen and (max-width:1340px)': {
      minWidth: 'calc(178px + 44px)',
      maxWidth: 'calc(178px + 44px)',
    },
    '@media screen and (max-width:1135px)': {
      minWidth: 'calc(108px + 44px)',
      maxWidth: 'calc(108px + 44px)',
    },
  },
});

export default styles;
