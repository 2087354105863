import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { IconSearch } from '@/icons/iconSearch';
import { IconDropdownArrow } from '@/icons/IconDropdownArrow';
import * as Styles from './Filter.styles';
import { Button } from '../Button';
import { MultiSelect } from '../inputs/MultiSelect';

const Filter = ({
  filterLabel = 'Filter by',
  filteredOptions = [
    {
      id: 0, label: 'Todas', value: 'all', checked: true,
    },
    {
      id: 1, label: 'Ativas', value: 'active', checked: false,
    },
    {
      id: 2, label: 'Vencidas', value: 'to_due', checked: false,
    },
  ],
  multiSelectLabel = 'Subjects',
  multiSelectOptions = [],
  setMultiSelectOptions,
  multiSelectVisible = false,
  termFilterVisible = true,
  setFilteredOptions,
  allowMultipleOptions = true,
  clearFilterVariant = 'text',
  filterTyped,
  setFilterTyped,
  clearFilter,
  clearButtonSize,
  inputIconSide = 'left',
  testId = 'filter',
  clearLabel = 'Clear label',
  inputMaxLength,
  variant = 'default',
  searchPlaceholder = 'Search placeholder',
  onFilterClick,
  applySpecificRuleOnMark = null,
  fontFamily = 'primary',
  mobileBreakPoint = '960px',
}) => {
  const [display, toggleDisplay] = useState(false);
  const theme = useContext(ThemeContext);
  const shouldRenderMultiSelect = multiSelectVisible;
  const handleMark = (index) => {
    let newOptions = [...filteredOptions];

    if (!newOptions[index].checked && newOptions[0].checked) {
      newOptions[0].checked = false;
    }

    if (!allowMultipleOptions) {
      newOptions = newOptions.map((option, idx) => {
        if (index !== idx) {
          const newOption = { ...option };
          newOption.checked = false;
          return newOption;
        }
        return option;
      });
      newOptions[index].checked = !newOptions[index].checked;
    }

    if (allowMultipleOptions) {
      if (index === 0 && !newOptions[0].checked) {
        newOptions = newOptions.map((option) => {
          const newOption = { ...option };
          newOption.checked = false;
          return newOption;
        });
      }

      newOptions[index].checked = !newOptions[index].checked;
    }

    const checkedItems = newOptions.filter(item => (item.checked)).length;
    if (!checkedItems) {
      newOptions[0].checked = true;
    }

    onFilterClick && onFilterClick(newOptions);

    if (applySpecificRuleOnMark) {
      newOptions = applySpecificRuleOnMark({ options: newOptions, index });
    }

    setFilteredOptions(newOptions);
  };

  const handleChange = (event) => {
    const { value } = event.target;

    setFilterTyped && setFilterTyped(value);
  };

  return (
    <Styles.FilterWrapper data-testid={testId} displayed={display} mobileBreakPoint={mobileBreakPoint}>
      <Styles.Row mobileBreakPoint={mobileBreakPoint} variant={variant}>
        {termFilterVisible && (
          <>
            <Styles.FilterLabel data-testid={`${testId}-label`} fontFamily={fontFamily} variant={variant} mobileBreakPoint={mobileBreakPoint}>
              {filterLabel}
              <Styles.DropdownButton displayed={display} onClick={() => toggleDisplay(!display)} mobileBreakPoint={mobileBreakPoint}>
                <IconDropdownArrow color={variant === 'default' ? theme.palette.primary.main : theme.palette.secondary.primary} />
              </Styles.DropdownButton>
            </Styles.FilterLabel>
            <Styles.OptionsWrapper data-testid={`${testId}-options-wrapper`} mobileBreakPoint={mobileBreakPoint}>
              {filteredOptions.map((option, index) => (
                <Styles.OptionItem
                  key={`option-${option.id}`}
                  variant={variant}
                >
                  <Styles.OptionInput id={`item-${option.id}`} type="checkbox" checked={option.checked} value={option.value} onChange={() => handleMark(index)} data-testid={`${testId}-checkbox-${option.value}`} />
                  <Styles.OptionLabel
                    htmlFor={`item-${option.id}`}
                    fontFamily={fontFamily}
                    variant={variant}
                    data-testid={`label-${testId}-checkbox-${option.value}`}
                  >
                    {option.label}
                  </Styles.OptionLabel>
                </Styles.OptionItem>
              ))}
            </Styles.OptionsWrapper>
          </>
        )}

        {shouldRenderMultiSelect && (
        <Styles.MultiSelectWrapper>
          <MultiSelect
            options={multiSelectOptions}
            setMultiSelected={setMultiSelectOptions}
            defaultOptionLabel={multiSelectLabel}
            size="small"
          />
        </Styles.MultiSelectWrapper>
        )}

        <Styles.InputWrapper iconSide={inputIconSide} variant={variant} mobileBreakPoint={mobileBreakPoint}>
          <IconSearch variant={variant} />
          <Styles.InputField
            type="text"
            value={filterTyped}
            placeholder={searchPlaceholder}
            onChange={event => handleChange(event)}
            data-testid={`${testId}-input`}
            maxLength={inputMaxLength}
            variant={variant}
            fontFamily={fontFamily}
            mobileBreakPoint={mobileBreakPoint}
          />
        </Styles.InputWrapper>
        <Button size={clearButtonSize} variant={clearFilterVariant} label={clearLabel} onClick={clearFilter} testId={`${testId}-button`} />
      </Styles.Row>
    </Styles.FilterWrapper>
  );
};

export default Filter;
