import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class LogoMarca extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 199.426 35.042">
        <g transform="translate(-16 -23.479)">
          <g transform="translate(16 23.479)">
            <g transform="translate(16.294 25.659)">
              <path
                className={classes.a}
                d="M61,72.4c-.993.961-1.986,2.272-2.979,3.232.477,2.506-.456,3.609.668,4.507.406.324,2.875,1.013,4.258.214.031-1.3-.68-1.687-1.439-2.175-.767-1.649-.492-4.155-.507-5.779"
                transform="translate(-58.019 -72.404)"
              />
              <path
                className={classes.b}
                d="M64.694,90.419a2.264,2.264,0,0,0-1.4-1.647,1.83,1.83,0,0,1,.872,1.65C64.342,90.43,64.519,90.43,64.694,90.419Z"
                transform="translate(-61.145 -82.103)"
              />
              <path
                className={classes.b}
                d="M67.614,89.806a2.259,2.259,0,0,0-1.392-1.62,1.8,1.8,0,0,1,.855,1.8A2.76,2.76,0,0,0,67.614,89.806Z"
                transform="translate(-62.88 -81.755)"
              />
            </g>
            <g transform="translate(17.836 12.099)">
              <path
                className={classes.a}
                d="M62.079,39.711c-.511.744-.74,2.311,3.34,4.6a35.178,35.178,0,0,1-2.257,3.5,2.469,2.469,0,0,0-.343,2.708,6.446,6.446,0,0,0,.939,1.047A3.956,3.956,0,0,0,65.69,51.5a2.614,2.614,0,0,0,.388-2.9S69.518,45,69.3,43.918,63.7,37.346,62.079,39.711Z"
                transform="translate(-61.804 -39.125)"
              />
              <path
                className={classes.b}
                d="M69.758,62.386s-1.394,1.149-1.744,1.348a4.085,4.085,0,0,0,.81-.35s.618.756.165,1.395a1.107,1.107,0,0,0,.275-1.573,6.515,6.515,0,0,0,.511-.744Z"
                transform="translate(-65.483 -52.908)"
              />
            </g>
            <g transform="translate(0 3.9)">
              <g transform="translate(2.188 15.312)">
                <path
                  className={classes.a}
                  d="M36.431,59.709a1.483,1.483,0,0,1-.237.749c-.235.373-.371.328.216.649a4.417,4.417,0,0,0,1.394.433c.826.135.632.042.268-.729a6.455,6.455,0,0,0-.563-.993c-.8-1.119-1.045-1.043-1.078-.108"
                  transform="translate(-30.862 -58.011)"
                />
                <path
                  className={classes.a}
                  d="M29.748,57.895c-.3.559.16.646.753.854a3.025,3.025,0,0,0,.681.152c.667.072.494-.024.2-.564a9.874,9.874,0,0,0-.59-.96c-1.166-1.677-.609-.3-1.047.519"
                  transform="translate(-27.106 -56.583)"
                />
                <path
                  className={classes.a}
                  d="M23.518,57.82a3.119,3.119,0,0,1-.014.385c-.044.465-.1.387.48.438a3.351,3.351,0,0,0,.768-.008c.735-.11.467-.366.084-.793a3.229,3.229,0,0,0-.434-.4c-1.452-1.118-.9-.332-.885.381"
                  transform="translate(-23.401 -56.759)"
                />
              </g>
              <path
                className={classes.a}
                d="M40.443,19s-4.159,2.143-4.122,2.821,1.173.586,1.631,1.5c0,0-2.547,1.759-2.382,2.565s1.8.513,2.217,1.154c0,0-.4,1.6-.036,2.191s.311.081.824.832A29.152,29.152,0,0,1,40.443,19Z"
                transform="translate(-28.419 -19.004)"
              />
              <path
                className={classes.a}
                d="M29.182,23.286a31.1,31.1,0,0,0,5,3.345A11.062,11.062,0,0,1,37.2,29.289c1.01,1.352.43,2.946.66,4.6.079.564.117,1.135.14,1.7a21.93,21.93,0,0,1-.09,3.108c-.254,2.473-.382,4.484-2.124,6.334-2.178,2.313-4.468,2.728-7.186.731a13.375,13.375,0,0,1-2.7-2.118c-.555-.648-1.249-1.064-1.85-1.672a8.656,8.656,0,0,0-2.671-1.928,2.6,2.6,0,0,0-2.293.382c-.268.122-.633.359-.929.123a.307.307,0,0,1-.1-.372,2.73,2.73,0,0,1,.685-.974,5.468,5.468,0,0,1,6.124-.467,6.12,6.12,0,0,0,2.368.89A2.259,2.259,0,0,0,28.9,39.16a2.19,2.19,0,0,0,.682-1.272,3.919,3.919,0,0,0-.143-2.043c-.314-.944-.879-1.8-1.243-2.732-1.244-3.175-1.647-6.792.352-9.759l.277-.412Z"
                transform="translate(-18.031 -21.338)"
              />
              <g transform="translate(3.693 5.804)">
                <path
                  className={classes.c}
                  d="M27.094,46.3a16.05,16.05,0,0,1,4.322,3.972c1.869,2.219,6.541,5.49,10.4.35,3.631-4.841,1.921-11.625,1.711-12.4l-.024-.078c-.122-.157-3.36-4.288-6.326-4.9-.791.687-.945,1.9.109,3.981,1.772,3.507,3.188,8.622.039,11.764a4.273,4.273,0,0,1-1.678,1.058c-2.239.739-3.425-.62-4.825-1.929A6.769,6.769,0,0,0,27.094,46.3Z"
                  transform="translate(-27.094 -33.248)"
                />
                <path
                  className={classes.d}
                  d="M58.838,72.119a7.475,7.475,0,0,1-3.741-1.53A5.181,5.181,0,0,0,58.4,72.746C58.559,72.54,58.7,72.331,58.838,72.119Z"
                  transform="translate(-43.687 -55.374)"
                />
                <path
                  className={classes.d}
                  d="M48.818,75.107a8.682,8.682,0,0,0,2.257,2.929,4.892,4.892,0,0,0,.889-.3A7.212,7.212,0,0,1,48.818,75.107Z"
                  transform="translate(-39.966 -58.051)"
                />
                <path
                  className={classes.d}
                  d="M37.136,73.5q.116.134.23.27l.107.124a1.55,1.55,0,0,1,.1-.985A5.261,5.261,0,0,0,37.136,73.5Z"
                  transform="translate(-33.044 -56.749)"
                />
                <path
                  className={classes.d}
                  d="M40.951,76.627q.243.2.505.386a2.353,2.353,0,0,1,.018-1.473A5.4,5.4,0,0,0,40.951,76.627Z"
                  transform="translate(-35.305 -58.307)"
                />
                <path
                  className={classes.e}
                  d="M33.465,69.781q.179.182.359.37a3.157,3.157,0,0,1,.083-.776A3.739,3.739,0,0,0,33.465,69.781Z"
                  transform="translate(-30.869 -54.654)"
                />
                <path
                  className={classes.d}
                  d="M57.559,62.825a7.535,7.535,0,0,1,4.349-.005c.037-.2.069-.4.1-.6A5.361,5.361,0,0,0,57.559,62.825Z"
                  transform="translate(-45.145 -50.297)"
                />
                <path
                  className={classes.d}
                  d="M61.929,52.039c-.02-.244-.045-.478-.071-.7h0a6.668,6.668,0,0,0-4.574,1.394A7.371,7.371,0,0,1,61.929,52.039Z"
                  transform="translate(-44.983 -43.965)"
                />
                <path
                  className={classes.d}
                  d="M59.262,42.725c-.118-.134-.249-.28-.393-.436-3.208,0-4.074,1.49-4.074,1.49A7.091,7.091,0,0,1,59.262,42.725Z"
                  transform="translate(-43.508 -38.605)"
                />
                <path
                  className={classes.d}
                  d="M57.727,42.5a18.964,18.964,0,0,0-.355-2.154c-.077-.046-.154-.094-.229-.143-1.026-.668-5.529-3.4-6.852-4.186a2.65,2.65,0,0,0-.014.6C51.679,38.555,54.788,41.839,57.727,42.5Z"
                  transform="translate(-40.824 -34.892)"
                />
              </g>
            </g>
            <g transform="translate(8.046 24.251)">
              <path
                className={classes.a}
                d="M40.107,69.854a2.041,2.041,0,0,1,1.859-.892c2.063.269,1.411,2.709.916,4.146a11.7,11.7,0,0,0-.585,3.2c0,1.418.542,1.845-.118,2.4a5.606,5.606,0,0,1-3.6,1c-1.145-.259-1.177-2.615.649-3.216a7.2,7.2,0,0,0,.445-3.4c-.026-.794-.1-2.463.435-3.247"
                transform="translate(-37.776 -68.949)"
              />
              <path
                className={classes.b}
                d="M42.415,92.412c.163-.023.324-.053.482-.09a2.1,2.1,0,0,1,.569-1.907A2.267,2.267,0,0,0,42.415,92.412Z"
                transform="translate(-40.525 -81.668)"
              />
              <path
                className={classes.b}
                d="M38.992,92.267a2.407,2.407,0,0,1,.553-2.124,2.18,2.18,0,0,0-1.037,1.825A.93.93,0,0,0,38.992,92.267Z"
                transform="translate(-38.21 -81.507)"
              />
            </g>
            <g transform="translate(5.823 11.416)">
              <path
                className={classes.b}
                d="M42.262,60.093s-.238,1.365.687,1.823a1.863,1.863,0,0,0,2.226-.178c.664-.8,1.649-2.443-1.017-4.046-1.647-.99-1.731,2.374-1.731,2.374Z"
                transform="translate(-38.195 -49.335)"
              />
              <path
                className={classes.b}
                d="M42.861,45.158c.348.753.661,1.336.676,1.388,0,0,2.153-2.666,1.752-3.107C44.845,42.952,43.2,44.568,42.861,45.158Z"
                transform="translate(-38.566 -40.945)"
              />
              <path
                className={classes.a}
                d="M38.71,37.76c2.308,2.226-2.487,5.18-2.487,5.18A8.452,8.452,0,0,1,37.9,45.221c.495.824.687.687,1.291,1.374S38.072,48.3,38.072,48.3s-5.769-3.118-5.751-5.114a14.943,14.943,0,0,1,2.693-3.847C35.694,38.648,37.606,36.7,38.71,37.76Z"
                transform="translate(-32.321 -37.449)"
              />
              <path
                className={classes.a}
                d="M42.4,59.989s-.431,2.125.838,2.743a2.95,2.95,0,0,0,1.552-.307s1.3-2,.33-3.032S42.4,59.989,42.4,59.989Z"
                transform="translate(-38.253 -50.248)"
              />
              <path
                className={classes.b}
                d="M42.417,61.726s1.669.895,1.959.976a1.559,1.559,0,0,1-.853-.128s-.429.813.068,1.322c0,0-.766-.137-.492-1.414l-.7-.719Z"
                transform="translate(-38.295 -51.834)"
              />
            </g>
            <g transform="translate(10.23)">
              <path
                className={classes.a}
                d="M46.11,14.639s.868-5.118,2.993-5.206c1.4-.058,2.1,1.723,2.1,1.723a4.462,4.462,0,0,1,2.414.717.939.939,0,0,1,1.056-.478c.666.227,1.383,1.71,1.119,4.149a2.118,2.118,0,0,1,1.521,1.044,1.125,1.125,0,0,1-.729.465c-.44.038-2.338-.654-2.854-.2a1.641,1.641,0,0,0-.679,1.622,5.028,5.028,0,0,1-3.03-1.4C49.078,15.884,48.285,13.57,46.11,14.639Z"
                transform="translate(-44.899 -9.432)"
              />
              <path
                className={classes.f}
                d="M63.84,19.92s.522-1.717,1.525-2.2a18.572,18.572,0,0,0,.27,2.482A15.043,15.043,0,0,0,63.84,19.92Z"
                transform="translate(-55.405 -14.344)"
              />
              <path
                className={classes.f}
                d="M51.563,14.474s1.291.618,2.143,3.3a18.49,18.49,0,0,0-2.926-.261A7.006,7.006,0,0,0,51.563,14.474Z"
                transform="translate(-47.666 -12.419)"
              />
              <path
                className={classes.b}
                d="M53.188,17.389c.315,0,.57.352.57.786s-.255.785-.57.785-.57-.352-.57-.785.255-.786.57-.786"
                transform="translate(-48.755 -14.147)"
              />
              <path
                className={classes.b}
                d="M65.186,20.784a.618.618,0,0,0,0-1.189.347.347,0,0,0-.137.031,1.588,1.588,0,0,0-.308.52c0,.014,0,.028,0,.043A.537.537,0,0,0,65.186,20.784Z"
                transform="translate(-55.936 -15.454)"
              />
              <path
                className={classes.g}
                d="M48.908,24.786s-.879-1.017-1.731-.192c-.7.683-.178,1.827-.178,1.827s.051-.988.508-.921c0,0,.385,2.459,3.985,4.919s4.6,2.239,5.386,2.034,1.676-.6,1.374-1.237a13.66,13.66,0,0,0-2.143-2.2,2.627,2.627,0,0,1-2.391.068,13.149,13.149,0,0,1-5.469-4.094s.073-.46.607-.2"
                transform="translate(-45.314 -18.224)"
              />
              <path
                className={classes.h}
                d="M50.78,32.157a6.111,6.111,0,0,1,5.642,1.52l-.4-.22s1.85-.092,2.107.165a4.381,4.381,0,0,1,1.915,3.252c.056.632-1.443.989-4.772-.669C53.193,35.171,50.78,32.157,50.78,32.157Z"
                transform="translate(-47.666 -22.797)"
              />
              <g transform="translate(7.164 9.78)">
                <path
                  className={classes.i}
                  d="M60.719,33.434l.077.038Z"
                  transform="translate(-60.719 -33.434)"
                />
              </g>
              <path
                className={classes.j}
                d="M66.74,35.366S66.292,37.2,65.6,37c-.233-.069-.137-.591-.233-.948s-.357-1.044-.357-1.044Z"
                transform="translate(-56.097 -24.587)"
              />
              <path
                className={classes.j}
                d="M75.1,34.076s-.145,1.887-.861,1.8c-.242-.03-.231-.561-.384-.9S73.338,34,73.338,34Z"
                transform="translate(-61.033 -23.991)"
              />
              <path
                className={classes.c}
                d="M57.972,24.884c-.092-1.327-1.845-1.911-3.129-.977-1.051-.234-2.219-.935-3.271.117a1.2,1.2,0,0,0-.318,1.46s-2.185-.352-2.94-1.465-1.731-2.6-2.8-2.707a1.818,1.818,0,0,0-2.061.934c-.55,1.072-.824,3.009,3.243,6.073s5.861,3.889,6.746,3.888c1.131,0,1.2-.422,2.5-.6.664-.093,1.168-1.223.44-2.2a13.174,13.174,0,0,0-2.446-2.157,13.66,13.66,0,0,1,2.143,2.2c.3.632-.591,1.03-1.374,1.237s-1.786.426-5.386-2.034-3.985-4.919-3.985-4.919c-.457-.067-.508.921-.508.921S44.3,23.506,45,22.823c.852-.824,1.731.192,1.731.192l-.053,0c-.534-.257-.607.2-.607.2a13.149,13.149,0,0,0,5.469,4.094,2.627,2.627,0,0,0,2.391-.068,15.178,15.178,0,0,0,2.226-.179A1.938,1.938,0,0,0,57.972,24.884Z"
                transform="translate(-43.138 -16.454)"
              />
              <path
                className={classes.d}
                d="M66.3,29.749c.366-.118.647.375.165.59-.068.182.041.38.337.45a.579.579,0,0,1-.5-1.041"
                transform="translate(-56.687 -21.46)"
              />
              <path
                className={classes.d}
                d="M76.268,29.381c-.3-.1-.534.31-.136.487.056.15-.034.314-.278.372a.478.478,0,0,0,.414-.859"
                transform="translate(-62.523 -21.244)"
              />
            </g>
          </g>
          <g transform="translate(47.989 28.772)">
            <path
              className={classes.k}
              d="M207.779,49.269c.247,1.518,1.624,2.083,3.142,2.083,1.483,0,2.667-.636,2.7-1.412.053-1.173-.867-1.87-2.173-2.121-.9-.174-3.6-.706-3.6-.706-3.283-.6-5.155-1.551-5.155-4.34,0-3.637,3.142-6,7.626-6,4.414,0,7.344,2.012,8.05,5.084l-5.155,1.024a2.751,2.751,0,0,0-2.966-2.083c-1.518,0-2.278.638-2.42,1.377-.093.486.142.891,1.749,1.236,1.04.224,4.167.848,4.167.848,3.354.706,4.908,2.719,4.908,5.331,0,3.919-3.39,5.967-8.05,5.967-4.166,0-7.732-1.518-8.332-5.155Z"
              transform="translate(-159.188 -30.923)"
            />
            <path
              className={classes.k}
              d="M305.864,32.706a17.239,17.239,0,0,1,.142,2.163c0,7.2-5.142,12.377-12.306,12.377a12.417,12.417,0,1,1-.106-24.825c5.284,0,9.93,2.482,11.6,7.128l-5.639,1.632a6.119,6.119,0,0,0-5.958-3.4,6.8,6.8,0,0,0-6.81,7.057,6.893,6.893,0,0,0,6.987,7.093,6.231,6.231,0,0,0,6.277-4.256h-7.27V32.706Z"
              transform="translate(-205.777 -22.421)"
            />
            <g transform="translate(0 0.128)">
              <path
                className={classes.k}
                d="M118.208,46.5h-5.816V37.1h-10v9.4H96.538V22.735h5.852v9.256h10V22.735h5.816Z"
                transform="translate(-96.538 -22.735)"
              />
              <path
                className={classes.k}
                d="M172.9,45.589c0,5.639-4.469,9.4-9.753,9.4-5.391,0-9.86-3.759-9.86-9.4,0-5.6,4.469-9.363,9.86-9.363C168.427,36.226,172.9,39.985,172.9,45.589Zm-5.462,0a4.352,4.352,0,1,0-4.291,4.611A4.429,4.429,0,0,0,167.434,45.589Z"
                transform="translate(-130.161 -30.729)"
              />
              <path
                className={classes.k}
                d="M259.278,45.717a9.67,9.67,0,0,1-4.859,1.312c-4.433,0-7.093-2.412-7.093-7.093V33.2H244.1v-4.5h3.227V23.957L253,22.735v5.958h5.249v4.5H253v6.171c0,1.844.887,2.589,2.376,2.589a5.794,5.794,0,0,0,2.589-.709Z"
                transform="translate(-183.973 -22.735)"
              />
              <path
                className={classes.k}
                d="M361.251,43.109V54.387h-5.462V53.075a7.867,7.867,0,0,1-5.32,1.844c-3.972,0-6.632-2.305-6.632-5.816,0-3.476,2.66-5.745,7.412-5.745h4.54V43a2.373,2.373,0,0,0-2.518-2.482c-1.7,0-2.7.674-2.979,2.234l-5.355-1.1c.78-3.44,3.9-5.6,8.157-5.6C358.094,36.052,361.251,38.57,361.251,43.109Zm-5.462,4.256v-.39h-3.9c-1.418,0-2.3.745-2.3,1.845,0,1.135.993,1.844,2.482,1.844A3.488,3.488,0,0,0,355.789,47.365Z"
                transform="translate(-243.07 -30.626)"
              />
              <path
                className={classes.k}
                d="M403.836,45.717a9.669,9.669,0,0,1-4.859,1.312c-4.433,0-7.093-2.412-7.093-7.093V33.2h-3.227v-4.5h3.227V23.957l5.674-1.222v5.958h5.249v4.5h-5.249v6.171c0,1.844.886,2.589,2.376,2.589a5.8,5.8,0,0,0,2.589-.709Z"
                transform="translate(-269.628 -22.735)"
              />
              <path
                className={classes.k}
                d="M443.343,45.589c0,5.639-4.469,9.4-9.753,9.4-5.391,0-9.859-3.759-9.859-9.4,0-5.6,4.469-9.363,9.859-9.363C438.875,36.226,443.343,39.985,443.343,45.589Zm-5.462,0a4.352,4.352,0,1,0-4.291,4.611A4.429,4.429,0,0,0,437.882,45.589Z"
                transform="translate(-290.41 -30.729)"
              />
              <path
                className={classes.k}
                d="M488.427,36.89l-.249,5.674H487.15c-3.972,0-6.136,2.057-6.136,6.668V54.8H475.34V38.219L481.014,37v3.4a6.876,6.876,0,0,1,6.136-3.653A4.865,4.865,0,0,1,488.427,36.89Z"
                transform="translate(-320.99 -31.038)"
              />
            </g>
          </g>
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(LogoMarca);
