import React from 'react';
import { Alert } from 'gatorcomponents';
import * as Styles from './DomainMessage.styles';
import useLocale from '@/hooks/useLocale';

const DomainMessage = ({
  action,
  domainStatusSuspended,
  domainStatusPending,
}) => {
  const { alerts: alertsLocale } = useLocale();
  const handleClick = () => {
    action && action();
  };

  const descriptionSuspended = (
    <Styles.DescriptionWrapper>
      {alertsLocale.domainCritialMessage({ handleClick })}
    </Styles.DescriptionWrapper>
  );

  const descriptionPending = (
    <Styles.DescriptionWrapper>
      {alertsLocale.domainStaticMessage({ handleClick })}
    </Styles.DescriptionWrapper>
  );

  return (
    <Styles.Container data-testid="domain-message-container">
      {domainStatusSuspended.length > 0 && (
        <Alert
          width="full"
          variant="error"
          description={descriptionSuspended}
        />
      )}

      {domainStatusPending.length > 0 && (
        <Alert
          width="full"
          variant="warning"
          description={descriptionPending}
        />
      )}
    </Styles.Container>
  );
};

export default DomainMessage;
