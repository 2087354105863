import { ProductsActionTypes } from './products.types';

const initialState = {
  loading: false,
  loaded: false,
  owned: [
    {
      category: 'shared',
      has_product: false,
    },
    {
      category: 'domain',
      has_product: false,
    },
    {
      category: 'titan',
      has_product: false,
    },
    {
      category: 'dedicated',
      has_product: false,
    },
    {
      category: 'security',
      has_product: false,
    },
    {
      category: 'academy',
      has_product: false,
    },
  ],
  cPanelLoading: false,
  alertsList: [],
  alertsVisibility: false,
  listLoading: false,
  listLoaded: false,
  list: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductsActionTypes.GET_ALL_PRODUCTS:
      return {
        ...state,
        listLoading: true,
      };
    case ProductsActionTypes.SET_ALL_PRODUCTS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case ProductsActionTypes.GET_ALL_PRODUCTS_FAILURE:
    case ProductsActionTypes.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        listLoaded: true,
        listLoading: false,
      };
    case ProductsActionTypes.REQUEST_GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case ProductsActionTypes.GET_PRODUCTS_SET:
      return {
        ...state,
        owned: action.payload,
      };
    case ProductsActionTypes.GET_PRODUCTS_SUCCESS:
    case ProductsActionTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ProductsActionTypes.SET_CPANEL_LOADING:
      return {
        ...state,
        cPanelLoading: action.payload,
      };
    case ProductsActionTypes.SET_ADD_ALERT:
      return {
        ...state,
        alertsList: [action.payload, ...state.alertsList],
      };
    case ProductsActionTypes.SET_CLEAR_NEW_ALERTS:
      return {
        ...state,
        alertsList: action.payload,
      };
    case ProductsActionTypes.SET_ALERTS_VISIBILITY:
      return {
        ...state,
        alertsVisibility: action.payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
