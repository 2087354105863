const styles = () => ({
  input: {
    '& > div > fieldset': {
      borderRadius: '2px',
    },
    '& > div > div': {
      marginRight: '100px',
      marginLeft: '50px',
    },
  },
});

export default styles;
