import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({
    theme,
    variant,
    size,
    customStyles,
  }) => {
    const sizes = {
      regular: {
        padding: '7px 20px',
        fontSize: '16px',
        lineHeight: '22px',
        borderWidth: customStyles && customStyles.borderWidth ? customStyles.borderWidth : '2px',
        weight: theme.v1.font.weights.medium,
      },
      small: {
        padding: '6px 16px',
        fontSize: '12px',
        lineHeight: '16px',
        borderWidth: customStyles && customStyles.borderWidth ? customStyles.borderWidth : '1px',
        weight: theme.v1.font.weights.regular,
      },
    };

    const variants = {
      primary: {
        color: theme.v1.color.white.primary,
        backColor: theme.v1.color.action.primary,
        borderColor: theme.v1.color.action.primary,
        borderStyle: 'solid',
        hoverBackColor: theme.v1.color.action.hover,
        hoverBorderColor: theme.v1.color.action.hover,
        hoverColor: theme.v1.color.white.primary,
        disabledBackColor: theme.v1.color.action.disabled,
        disabledBorderColor: theme.v1.color.action.disabled,
        disabledColor: theme.v1.color.white.primary,
      },
      secondary: {
        color: theme.v1.color.action.primary,
        backColor: theme.v1.color.white.primary,
        borderColor: theme.v1.color.action.primary,
        borderStyle: 'solid',
        hoverBackColor: theme.v1.color.action.hover,
        hoverBorderColor: theme.v1.color.action.hover,
        hoverColor: theme.v1.color.white.primary,
        disabledColor: theme.v1.color.action.disabled,
        disabledBackColor: theme.v1.color.white.primary,
        disabledBorderColor: theme.v1.color.action.disabled,
      },
      tertiary: {
        color: theme.v1.color.action.primary,
        backColor: theme.v1.color.white.primary,
        borderColor: theme.v1.color.white.primary,
        borderStyle: 'solid',
        hoverBackColor: theme.v1.color.action.hover,
        hoverBorderColor: theme.v1.color.action.hover,
        hoverColor: theme.v1.color.white.primary,
        disabledColor: theme.v1.color.action.disabled,
        disabledBackColor: theme.v1.color.white.primary,
        disabledBorderColor: theme.v1.color.action.disabled,
      },
      text: {
        color: theme.v1.color.action.primary,
        backColor: 'transparent',
        borderColor: '',
        borderStyle: 'none',
        hoverBackColor: '',
        hoverBorderColor: '',
        hoverColor: '',
        disabledColor: theme.v1.color.action.disabled,
        disabledBackColor: '',
        disabledBorderColor: '',
      },
    };

    return css`
      background-color:${(customStyles && customStyles.backColor) ? customStyles.backColor : variants[variant].backColor};
      border-color: ${(customStyles && customStyles.borderColor) ? customStyles.borderColor : variants[variant].borderColor};
      border-radius: ${(customStyles && customStyles.borderRadius) ? customStyles.borderRadius : '20px'};
      border-style: ${(customStyles && customStyles.borderStyle) ? customStyles.borderStyle : variants[variant].borderStyle};
      border-width: ${(customStyles && customStyles.borderWidth) ? customStyles.borderWidth : sizes[size].borderWidth};
      color:${(customStyles && customStyles.color) ? customStyles.color : variants[variant].color};
      cursor: pointer;
      font-family: ${theme.v1.font.family.primary};
      font-size: ${(customStyles && customStyles.fontSize) ? customStyles.fontSize : sizes[size].fontSize};
      font-weight:${(customStyles && customStyles.weight) ? customStyles.weight : sizes[size].weight};
      letter-spacing: 0.01px;
      line-height: ${(customStyles && customStyles.lineHeight) ? customStyles.lineHeight : sizes[size].lineHeight};
      padding: ${(customStyles && customStyles.padding) ? customStyles.padding : sizes[size].padding};
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out, color 150ms ease-in-out;

      &:hover{
        background-color:${(customStyles && customStyles.hoverBackColor) ? customStyles.hoverBackColor : variants[variant].hoverBackColor};
        border-color:${(customStyles && customStyles.hoverBorderColor) ? customStyles.hoverBorderColor : variants[variant].hoverBorderColor};
        color:${(customStyles && customStyles.hoverColor) ? customStyles.hoverColor : variants[variant].hoverColor};
      }

      &:disabled{
        background-color:${(customStyles && customStyles.disabledBackColor) ? customStyles.disabledBackColor : variants[variant].disabledBackColor};
        border-color:${(customStyles && customStyles.disabledBorderColor) ? customStyles.disabledBorderColor : variants[variant].disabledBorderColor};
        color:${(customStyles && customStyles.disabledColor) ? customStyles.disabledColor : variants[variant].disabledColor};
      }
    `;
  }
}
`;


export const Label = styled.span``;
