import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, withStyles, Typography, CardContent, CardActions, IconButton, Collapse,
} from '@material-ui/core';
import {
  Folder, More, Lock, Cloud, LocalPhone, Email, Settings, ExpandMore, ExpandLess,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import { findIndex, propEq } from 'ramda';
import { productType } from '@/types';
import { formatDomain } from '@/utils/Formatters/domain';
import { getBuyProductService } from '@/utils/Application/links';
import { formatDate } from '@/utils/Formatters/formatDate';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import ProductCard from '@/components/Cards/ProductCard';
import LinkButton from '@/components/Buttons/LinkButton';
import AddonListButton from '@/components/Buttons/AddonListButton';
import Loader from '@/components/Layout/Loader';
import { AVAILABLE_PRODUCT_TYPES } from '@/config/products/availability';
import { ProductsContext } from '@/contexts/Products/context';
import ChipLinkButton from '@/components/Buttons/ChipLinkButton';
import styles from './styles';
import OldResellerWarning from '../OldResellerWarning';
import { brSitebuilderProducts, esSitebuilderProducts } from '@/enums/products/products.enum';
import { isBRServer } from '@/utils/Validators/validation';
import { productItemLocale } from './ProductItem.locale';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE, CHARLIE_CLOUDFLARE } from '@/config/GrowthBook/constants';
import useLocale from '@/hooks/useLocale';


const ProductItem = ({
  product,
  subdomainsList,
  t,
  classes,
  onExpandDetails,
  detailsExpanded,
  onLoadingSubdomains, products,
}) => {
  const { oldResellerId, featureOldResellerNotification, cloudflareAvailableProducts } = useContext(ProductsContext);

  const { routes: routesLocale } = useLocale();

  const enabledEcommercePlusCycleChange = useFeatureIsOn(FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE);
  const enableCloudflare = useFeatureIsOn(CHARLIE_CLOUDFLARE);

  let icon;
  switch (product.type) {
    case 'other':
      icon = <More className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    case 'sitelock':
      icon = <Lock className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    case 'codeguard':
      icon = <Cloud className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    case 'gapps':
      icon = <Email className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    case 'jumpstart':
      icon = <LocalPhone className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    case 'setup':
      icon = <Settings className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
    default:
      icon = <Folder className="status-icon" style={{ verticalAlign: 'super' }} />;
      break;
  }

  // CTA label
  const productLabel = t(`details.product.${product.type}`);

  const productIsActive = product.status === 'Active';
  const productIsActivationPending = product.status === 'ActivationPending';
  const productIsPending = product.status === 'Pending';
  const productIsSuspended = product.status === 'Suspended';
  const productIsCancelled = product.status === 'Cancelled';
  const productIsTerminated = product.status === 'Terminated';
  const productIsBuilding = product.status === 'Building';
  const isMobile = window.innerWidth < 551;
  const showActions = productIsActive || productIsActivationPending;
  const isAvailableDetailLink = !productIsCancelled
    && !productIsTerminated
    && !productIsBuilding
    && (product.type === 'websitebuilder' || product.details !== '');

  const generateLink = () => {
    if (product.origin === 'hosting' && AVAILABLE_PRODUCT_TYPES.includes(product.type)) {
      return <LinkButton to={`${t('routes.products')}${t('routes.productdetail')}/${product.id}`}>{productLabel}</LinkButton>;
    }
    if (productIsPending) {
      return <LinkButton to={`${t('routes.billing')}${t('routes.unpaid')}`}>{productLabel}</LinkButton>;
    }
    return <LinkButton to={`${product.details}`}>{productLabel}</LinkButton>;
  };

  const renderSubDomains = subdomainsList => (Object.keys(subdomainsList).length > 0
    ? Object.values(subdomainsList).map(subdomain => <span key={subdomain.url} className={classes.subdomain}>{subdomain.url}</span>)
    : <span>{t('details.product.emptySubdomains')}</span>);

  const renderNextInvoiceDueDate = () => {
    const htmlNextInvoiceDueDate = (
      <div className={classes.dueContainer}>
        {product.nextinvoicedate && (
          <div className={classes.cardDateBox}>
            <Typography className="cardSubTitle" variant="caption">{t('nextDueDate')}</Typography>
            <Typography className="cardTitleLetter">{formatDate(product.nextinvoicedate)}</Typography>
          </div>
        )}
      </div>
    );

    if (isMobile) {
      return product.nextinvoicedate ? htmlNextInvoiceDueDate : null;
    }
    return htmlNextInvoiceDueDate;
  };

  const sortActions = () => {
    const newProduct = { ...product };
    const defaultActions = [...product.actions];

    const setupMail = {
      type: 'setup-mail',
      emailType: product.setupMail,
      linkUrl: '_self',
      status: 'active',
    };

    const setupMailIndex = findIndex(propEq('type', 'setup-mail'), defaultActions);
    const cpanelIndex = findIndex(propEq('type', 'cpanel'), newProduct.actions);

    if (setupMailIndex === -1) {
      if (product.setupMail) {
        newProduct.actions.splice(cpanelIndex, 0, setupMail);
      }
    }

    const ecommercePlusAction = {
      type: 'ecommerce-plus-cycle-change',
      status: 'active',
      linkUrl: '', // TODO: add path
    };
    const hasEcommercePlus = product.ecommercePlus && product.ecommercePlus.status === 'Active';
    const ecommercePlusActionIndex = findIndex(propEq('type', ecommercePlusAction.type), defaultActions);

    if (ecommercePlusActionIndex === -1 && hasEcommercePlus && enabledEcommercePlusCycleChange) {
      newProduct.actions.push(ecommercePlusAction);
    }

    newProduct.actions = [...defaultActions];

    const siteBuilderIdList = isBRServer ? brSitebuilderProducts : esSitebuilderProducts;

    const productIsCloudflareAvailable = cloudflareAvailableProducts.includes(product.id);

    if (siteBuilderIdList.includes(product.packageid)) {
      newProduct.actions.unshift({
        type: 'sitebuilder',
        linkUrl: `${productItemLocale.routeSitesPages}${productItemLocale.routesNewSite}`,
        status: 'active',
      });
    }

    if (enableCloudflare && productIsCloudflareAvailable) {
      newProduct.actions.push({
        type: 'cloudflare',
        linkUrl: `${routesLocale.products}${routesLocale.routesNewSite}/${product.id}`,
        status: 'active',
      });
    }

    return newProduct;
  };

  const shouldDisplayOldResellerWarning = oldResellerId.find(packageid => product.packageid === packageid) && featureOldResellerNotification && product.status !== 'Cancelled';
  const changePlanAction = product.actions.find(action => action.type === 'plan-switch');
  return (
    <Grid item xs={12} className={classes.container}>
      <ProductCard elevation={1}>
        <CardContent className={`${product.status.toLowerCase()} ${classes.cardContentProduct} ${classes.cardPadding}`}>

          <div className={classes.cardContainer}>
            <div className={`${classes.statusDueButtonContainer} ${product.nextinvoicedate ? classes.statusWithDue : classes.statusWithoutDue}`}>
              <div className={classes.statusDueContainer}>
                <div className={classes.statusContainer}>
                  {icon}
                  <div className={classes.titlesWrapper}>
                    <Typography className="cardTitle" variant="h6">{`${t(product.productname)}`}</Typography>
                    <Typography className="cardSubTitle" variant="caption">{formatDomain(product.domain)}</Typography>
                    <span className={`${classes.label} product-status-label`}>{t(`product.${product.status}`)}</span>
                  </div>
                </div>
                {renderNextInvoiceDueDate()}
              </div>
              <div className={`${classes.buttonContainer} ${!product.nextinvoicedate && classes.buttonContainerWithoutDue}`}>

                {showActions && <AddonListButton createSiteLabel={productItemLocale.createSiteButton} group={sortActions()} productGroups={products} productId={product.id} />}

                {productIsPending && (
                <WarningNotice
                  border={product.status}
                  message={(
                    <Trans i18nKey="cardAlerts.pendingPlanLink">
                      0
                      <LinkButton to={t('routes.billing')}>1</LinkButton>
                    </Trans>
                        )}
                  fill="100%"
                />
                )}
                {(productIsSuspended && oldResellerId.find(packageid => product.packageid === packageid)) && (
                <ChipLinkButton
                  status="active"
                  type="plan-switch"
                  label={t('actions.old-reseller-plan-switch.active')}
                  target="_self"
                  href={changePlanAction.linkUrl}
                />
                )}
                {(productIsSuspended && !oldResellerId.find(packageid => product.packageid === packageid)) && (
                <WarningNotice
                  border={product.status}
                  message={(
                    <Trans i18nKey="cardAlerts.suspendedPlanLink">
                      0
                      <LinkButton to={t('routes.billing')}>1</LinkButton>
                    </Trans>
                          )}
                  fill="100%"
                />
                )}
                {productIsCancelled && (
                <WarningNotice
                  border={product.status}
                  message={(
                    <Trans i18nKey="cardAlerts.cancelledPlanLink">
                      0
                      <LinkButton to={getBuyProductService()} target="_blank">1</LinkButton>
                    </Trans>
                        )}
                  fill="100%"
                />
                )}
                {productIsTerminated && (
                <WarningNotice
                  border={product.status}
                  message={(
                    <Trans i18nKey="cardAlerts.terminatedPlanLink">
                      0
                      <LinkButton to={getBuyProductService()} target="_blank">1</LinkButton>
                    </Trans>
                        )}
                  fill="100%"
                />
                )}
                {productIsBuilding && (
                <WarningNotice
                  border={product.status}
                  message={t('cardAlerts.buildingPlanLink')}
                  fill="100%"
                />
                )}
              </div>

              {shouldDisplayOldResellerWarning && (
              <OldResellerWarning product={product} />
              )}
            </div>

            <div className={classes.actionContainer}>
              <CardActions className={classes.cardActions}>
                {isAvailableDetailLink && (
                  generateLink()
                )}
                {product.type === 'domain' && (
                <IconButton
                  onClick={onExpandDetails}
                  className={classes.iconButton}
                >
                  {detailsExpanded ? <ExpandLess className={classes.arrow} /> : <ExpandMore className={classes.arrow} />}
                </IconButton>
                )}
              </CardActions>
            </div>
          </div>

          <Grid container spacing={8} alignItems="center">
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Collapse in={detailsExpanded} timeout="auto">
                  <Grid className="cardCollapseBox" container item xs={12}>
                    <div className="cardCollapseIconBox">
                      <SubdirectoryArrowRight />
                    </div>
                    <div>
                      <Typography className="cardSubTitle" variant="caption">
                        {t('details.product.subdomains')}
                      </Typography>
                      <Typography className="cardCollapseDomain" variant="subtitle1">
                        {onLoadingSubdomains ? <Loader style={{ paddingBottom: 0, paddingTop: 0 }} size={20} /> : renderSubDomains(subdomainsList)}
                      </Typography>
                    </div>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ProductCard>
    </Grid>
  );
};

ProductItem.propTypes = {
  onExpandDetails: PropTypes.func.isRequired,
  detailsExpanded: PropTypes.bool,
  product: productType.isRequired,
};

ProductItem.defaultProps = {
  detailsExpanded: false,
};

export default withStyles(styles)(withI18n()(ProductItem));
