import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, variant }) => {
    const variants = {
      sites: {
        borderRadius: 'none',
        height: '116px',
        margin: '0px 0px 8px 0px',
        padding: '24px 0px 24px 0px',
        width: '100%',
        marginVariants: '0px 0px 8px 0px',
      },
      subscription_list: {
        borderRadius: '8px',
        height: '64px',
        margin: '0px 24px 0px 24px',
        padding: '16px 40px 16px 14px',
        width: 'auto',
        marginVariants: '0px 24px 0px 24px',
      },
      subscription: {
        borderRadius: '8px',
        height: '64px',
        margin: '0px',
        padding: '16px 40px 16px 14px',
        width: 'auto',
        marginVariants: '0px 0px 0px 0px',
      },
    };

    return css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryDark};
    border-radius: ${variants[variant].borderRadius};
    color: ${theme.v2.colors.white.primary};
    display: flex;
    font-size: ${theme.v2.font.sizes.xs};
    height: ${variants[variant].height};
    justify-content: space-between;
    left: 0;
    margin: ${variants[variant].margin};
    padding: 0px;
    z-index: unset;

    > div {
      margin-right: auto;
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      height:auto;
        > div {
          padding: ${variants[variant].padding};
          }
    }
    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width:  ${variants[variant].width};
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: ${variants[variant].marginVariants};

    }
    @media (max-width: ${theme.v2.breakpoints.xs}) {
      margin: ${variants[variant].marginVariants};
    }
  `;
  }}
`;

export const Content = styled.div`
  ${({ theme, variant }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryDark};
    color: ${theme.v2.colors.white.primary};
    display: flex;
    font-size: ${theme.v2.font.sizes.xs};
    justify-content: space-between;
    left: 0;
    margin: 0px 0px 0px 0px;
    padding: 0px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      display: block;
      height:auto;
      width: 100%;
    }

    @media (max-width: ${theme.v2.breakpoints.xs}) {
      width:  ${variant === 'sites' ? '100%' : 'auto'};
    }

  `}
`;

export const GiftIcon = styled.img`
  ${({ variant }) => {
    const variants = {
      sites: {
        height: '88px',
        width: '65px',
      },
      subscription: {
        height: 'initial',
        width: '52px',
      },
      subscription_list: {
        height: 'initial',
        width: '52px',
      },
    };
    return css`
    align-self: flex-end;
    height: ${variants[variant].height};
    object-fit: contain;
    width: ${variants[variant].width};

  `;
  }}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    max-width: fit-content;
    margin: 0px 0px 0px 8px;
    color: ${theme.v2.colors.white.primary};
    flex: 1;
    gap: 242px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
      gap: 2px;
      margin-bottom: 8px;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px 0px 0px 0px;
    }
  `}
`;

export const ContentTitle = styled.div`
  display:flex;
`;

export const Title = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.white.primary};
  font-family: ${theme.v2.font.family.secondary};
  font-size: 16px;
  font-weight: ${theme.v2.font.weights.bold};
  margin-bottom: 8px;
  margin-top:auto;
  max-width: fit-content;
  letter-spacing: 0.01px;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const Discount = styled.text`
${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activePure};
    font-weight: ${theme.v2.font.weights.bold};
    margin-left: 4px;
`}
`;

export const ContentDescription = styled.div`
  width: 100%;
  @media (max-width: 1260px) {
    display:block;
  }
`;

export const Description = styled.p`
  ${({ theme, variant }) => css`
  color: ${theme.v2.colors.white.primary};
  font-family: ${theme.v2.font.family.primary};
  font-size: ${variant === 'sites' ? theme.v2.font.sizes.xxs : theme.v2.font.sizes.xs};
  font-weight: ${theme.v2.font.weights.regular};
  letter-spacing: 0.01px;
  line-height: 1.4;
  margin: 0px;
  opacity: 1;
  text-align: start;
  width: max-content;

  @media (max-width: ${theme.v2.breakpoints.sm}) {
    width: auto;
  }

  `}
`;

export const ContentButton = styled.div`
${({ theme }) => css`
   margin: 0px 0px 0px 24px;
   > button {
     background: transparent;
     border-color: ${theme.v2.colors.feedback.activePure};
     color: ${theme.v2.colors.feedback.activePure};
     font-size: ${theme.v2.font.sizes.xxs};
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 16px 0px 0px 0px;
    }
    `}
  `;

export const CloseButton = styled.button`
${({ theme }) => css`
  align-items: center;
  align-self: flex-start;
  background-color: unset;
  border: unset;
  color: ${theme.v2.colors.white.primary};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 16px 8px 8px 0px;
  object-fit: contain;
  opacity: 1;
  padding: 0;
  width: 24px;
  `}
`;
