import React from 'react';
import {
  call, takeLatest, put, select, take,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { config, COUNTRY } from '@/config';
import { commonActions } from '@/redux/modules/common';
import { locale } from '@/utils/locale';
import {
  PRO_EMAIL_DOMAIN_SETUP, PRO_EMAIL_FAST_CHECKOUT, PRO_EMAIL_LIST, PRO_EMAIL_PAYMENT_METHODS,
} from '@/config/api';
import { receiveGetEmailList } from '@/redux/actions/emails';
import emailsActions from './email.actions';
import { EmailsActionTypes } from './email.types';
import { receiveProEmailPaymentMethods } from './emails.assembly';
import { isBRServer } from '@/utils/Validators/validation';
import Cookies from 'js-cookie';
import { authActions } from '../auth';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';

function* getEmailsRequest() {
  const { token } = yield select(state => state.auth);

  yield put(emailsActions.emails.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: PRO_EMAIL_LIST,
    };

    const response = yield call(api.whmcs.get, request);

    yield put(receiveGetEmailList(response.data));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.errorActivateTrial')} `}
        </p>
      ),
      type: 'error',
      backGround: '#D94445',
    }));
  }
  yield put(emailsActions.emails.loading.set(false));
}

function* updateDomainSetupRequest(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { dataDomainSetup } = action.payload;
  const { id: userId } = yield select(state => state.summary);

  const { jwt: jwtToken } = yield select(state => state.auth);

  try {
    const request = {
      data: {
        setup: dataDomainSetup.enabledUpdateDomainSetup,
        domain: dataDomainSetup.domain,
        customer_email: dataDomainSetup.customerEmail,
      },
      headers: {
        Authorization: `${jwtToken}`,
        'X-Client-ID': userId,
        'X-Brand': COUNTRY,
      },
      url: PRO_EMAIL_DOMAIN_SETUP,
    };

    yield call(api.whmcs.put, request);
    yield put(emailsActions.emails.updateDomainSetup.success());
  } catch (e) {
    yield put(emailsActions.emails.updateDomainSetup.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.errorActivateTrial')} `}
        </p>
      ),
      type: 'error',
      backGround: '#D94445',
    }));
  }
}

function* createEmailTrialRequest(action) {
  const { token } = yield select(state => state.auth);
  const { data } = action.payload;

  try {
    const request = {
      data: {
        domain: data.domain,
        email_account: data.email,
        email_password: data.password,
        offer_contexts: data.offerContext,
        accounts: data.accounts,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: 'api/v2/pro-emails/trial',
    };

    yield call(api.whmcs.post, request);

    yield put(commonActions.notifications.set({
      label: (
        <p>
          {locale('proEmail.successAtivateTrial')}
        </p>
      ),
      type: 'success',
      maxWidth: '600px',
    }));

    yield put(emailsActions.emails.request());
  } catch (e) {
    yield put(emailsActions.emails.createEmailTrial.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.errorDomainRegistration')} `}
        </p>
      ),
      type: 'error',
      backGround: '#D94445',
    }));
  }
}

function* requestActivatingRenewal(action) {
  const { email, callBackFunction } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {
    data: {
      serviceType: email.type,
      serviceId: email.id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `api/v2/clientarea/${config.COUNTRY}/signatures/active_renewal`,
  };

  try {
    yield call(api.whmcs.post, request);

    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.notifications.activateRenewal.success.0')} `}
          <strong>{email.productname}</strong>
          {`${locale('proEmail.notifications.activateRenewal.success.1')} `}
        </p>
      ),
      type: 'success',
    }));
    yield put(emailsActions.emails.request());
    callBackFunction();
    yield take([EmailsActionTypes.GET_EMAILS_SUCCESS, EmailsActionTypes.GET_EMAILS_FAILURE]);
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.notifications.activateRenewal.failure.0')} `}
          <strong>{email.productname}</strong>
          {`${locale('proEmail.notifications.activateRenewal.failure.1')} `}
        </p>
      ),
      type: 'error',
    }));
  }
}

function* getProEmailPaymentMethods() {
  const { token } = yield select(state => state.auth);

  yield put(emailsActions.emails.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: PRO_EMAIL_PAYMENT_METHODS,
    };

    const response = yield call(api.whmcs.get, request);
    yield put(emailsActions.emails.paymentMethods.success(receiveProEmailPaymentMethods(response)));
  } catch (e) {
    yield put(emailsActions.emails.paymentMethods.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.errorActivateTrial')} `}
        </p>
      ),
      type: 'error',
      backGround: '#D94445',
    }));
  }
  yield put(emailsActions.emails.loading.set(false));
}

function* buyProEmailPremium(action) {
  const {
    productId, productCycle, paymentMethod, quantity, domain, isUltra,
  } = action.payload;
  const { token } = yield select(state => state.auth);
  const { isLoading } = yield select(state => state.emails.buyFastcheckout.isLoading);

  if (isLoading) {
    return yield put(emailsActions.emails.buyTitanPremium.abort());
  }

  yield put(emailsActions.emails.loading.set(true));

  let emailBoxId = null;

  if (isUltra) {
    emailBoxId = isBRServer ? 17 : 5;
  } else {
    emailBoxId = isBRServer ? 7 : 3;
  }

  const configOptions = {
    [emailBoxId]: quantity,
  };

  const bodyObject = {
    products: [
      {
        id: productId,
        domain,
        cycle: productCycle,
        type: 'product',
        quantity: 1,
        configoptions: configOptions,
      },
    ],
    payment_method: paymentMethod,
    offer_contexts: [
      'setup_page_premium_activation',
    ],
  };

  try {
    const request = {
      data: bodyObject,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: PRO_EMAIL_FAST_CHECKOUT,
    };

    const response = yield call(api.whmcs.post, request);
    yield put(emailsActions.emails.buyTitanPremium.success({
      ...response, paymentMethod, invoice_id: response.data.data.invoice_id, domain,
    }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('proEmail.errorActivateTrial')} `}
        </p>
      ),
      type: 'error',
      backGround: '#D94445',
    }));

    yield put(emailsActions.emails.buyTitanPremium.failure());
  }
  yield put(emailsActions.emails.loading.set(false));
}

function* sagas() {
  yield takeLatest(EmailsActionTypes.GET_EMAILS_REQUEST, getEmailsRequest);
  yield takeLatest(EmailsActionTypes.EMAIL_ACTIVATE_RENEWAL_REQUEST, requestActivatingRenewal);
  yield takeLatest(EmailsActionTypes.EMAIL_CREATE_EMAIL_TRIAL_REQUEST, createEmailTrialRequest);
  yield takeLatest(EmailsActionTypes.UPDATE_DOMAIN_SETUP_REQUEST, updateDomainSetupRequest);
  yield takeLatest(EmailsActionTypes.GET_PRO_EMAIL_PAYMENT_METHODS, getProEmailPaymentMethods);
  yield takeLatest(EmailsActionTypes.REQUEST_TITAN_PREMIUM_BUY_FAST_CHECKOUT, buyProEmailPremium);
}


export default sagas;
