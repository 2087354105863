import { isBRServer } from '@/utils/Validators/validation';

export const HgProducts = !isBRServer
  ? {
    STREAMING_ALL: [

    ],
    CODEGUARD_ALL: [

    ],
    SITELOCK_ALL: [

    ],
    HOSTING_ALL: [
      5,
      6,
      7,
      8,
      9,
      10,
      174,
      211,
      212,
      213,
      50,
      51,
      52,
      53,
      54,
      229,
      230,
      231,
      232,
      233,
      234,
      235,
      236,
      237,
      238,
      240,
      56,
      57,
      58,
      59,
      60,
      61,
      64,
      65,
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      201,
      202,
      203,
      204,
      205,
      206,
      208,
      209,
      210,
      215,
      216,
    ],
    LINUX_PROFESIONAL: 7,
    SSL_FREE: 176,
    SHARED_ALL: [
      5,
      6,
      7,
      174,
      211,
      212,
      213,
    ],
    PLAN_EMPRENDEDOR: 5,
    PLAN_D_NEGOCIOS: 6,
    PLAN_PROFESSIONAL: 7,
    SHARED_GID: 1,
    WEB_HOSTING_COMPARTIDO_WINDOWS_PLAN_EMPRENDEDOR: 8,
    WEB_HOSTING_COMPARTIDO_WINDOWS_PLAN_DE_NEGOCIOS: 9,
    WEB_HOSTING_COMPARTIDO_WINDOWS_PLAN_PROFESSIONAL: 10,
    ALOJAMENTO_3: 213,
    GMAIL_PROFESIONAL: 12,
    RESELLER_GID: 4,
    RESELLER_ALUMINIO: 50,
    RESELLER_COBRE: 51,
    RESELLER_PLATA: 52,
    RESELLER_ORO: 53,
    RESELLER_DIAMANTE: 54,
    RESELLER_INICIANDO: 229,
    RESELLER_CRECIENDO: 230,
    RESELLER_EXPANDIENDO: 231,
    RESELLER_VETERANO: 232,
    RESELLER_VETERANO_100: 233,
    RESELLER_VETERANO_150: 234,
    RESELLER_VETERANO_200: 235,
    RESELLER_VETERANO_300: 236,
    RESELLER_VETERANO_500: 237,
    RESELLER_VETERANO_1000: 240,
    RESELLER_VETERANO_1200: 238,
    RESELLER_ALL: [
      4,
      50,
      51,
      52,
      53,
      54,
      229,
      230,
      231,
      232,
      233,
      234,
      235,
      236,
      237,
      240,
      238,
    ],
    DEDICATED_GID: 5,
    SERVIDOR_DEDICADO_LINUX_PLAN_BASICO: 56,
    SERVIDOR_DEDICADO_WINDOWS_PLAN_BASICO: 57,
    SERVIDOR_DEDICADO_LINUX_PLAN_ESTANDAR: 58,
    SERVIDOR_DEDICADO_WINDOWS_PLAN_ESTANDAR: 59,
    SERVIDOR_DEDICADO_LINUX_PLAN_ELITE: 60,
    SERVIDOR_DEDICADO_WINDOWS_PLAN_ELITE: 61,
    SERVIDOR_DEDICADO_LINUX_PLAN_PRO: 64,
    SERVIDOR_DEDICADO_WINDOWS_PLAN_PRO: 65,
    DEDICADO_LINUX_BLACK: 204,
    DEDICADO_LINUX_SAPPHIRE: 205,
    DEDICADO_LINUX_DIAMOND: 206,
    DEDICADO_WINDOWS_BLACK: 208,
    DEDICADO_WINDOWS_SAPPHIRE: 209,
    DEDICADO_WINDOWS_DIAMOND: 210,
    DEDICATED_ALL: [
      56,
      57,
      58,
      59,
      60,
      61,
      64,
      65,
      204,
      205,
      206,
      208,
      209,
      210,
    ],
    BLOG: 100,
    BUSINESS: 101,
    PRO: 102,
    VPS_GID: 8,
    VPS_SNAPPY_500: 133,
    VPS_SNAPPY_1000_CPANEL: 134,
    VPS_SNAPPY_1000: 135,
    VPS_SNAPPY_2000_CPANEL: 136,
    VPS_SNAPPY_2000: 137,
    VPS_SNAPPY_4000_CPANEL: 138,
    VPS_SNAPPY_4000: 139,
    VPS_SNAPPY_8000_CPANEL: 140,
    VPS_SNAPPY_8000: 141,
    VPS_STANDARD: 203,
    VPS_OPTIMIZED: 202,
    VPS_PLATINUM: 201,
    VPS_ENTRY: 215,
    VPS_PREMIUM: 216,
    VPS_ALL: [
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      203,
      202,
      201,
      215,
      216,
    ],
    TRANSFERENCIA: 143,
    HORA_TECNICA: 144,
    CAMBIO_DE_DOMINIO_PRIMARIO: 146,
    POSITIVE_SSL: 147,
    EV_SSL: 148,
    EXTENDED_SSL: 149,
    IP_DEDICADA: 150,
    INSTALACION_DE_SSL: 152,
    RESTAURACION_DE_COPIA_DE_SEGURIDAD: 153,
    CHIP_RAM_12GB: 155,
    COMODO_WILDCARD_SSL: 156,
    CODEGUARD_PLAN_BASICO: 157,
    CODEGUARD_PLAN_INICIANTE: 158,
    CODEGUARD_PLAN_PROFESIONAL: 159,
    CODEGUARD_PLAN_EMPRESARIAL: 160,
    CODEGUARD_PLAN_PREMIUM: 244,
    WEEBLY_BASICO: 161,
    WEEBLY_INICIANTE: 162,
    WEEBLY_PROFESIONAL: 163,
    WEEBLY_ALL: [
      161,
      162,
      163,
    ],
    CREADOR_DE_SITIOS_GID_ADDON: 13,
    CREADOR_DE_SITIOS_GID: 14,
    CREADOR_DE_SITIOS_PERSONAL: 164,
    CREADOR_DE_SITIOS_PROFESIONAL: 165,
    CREADOR_DE_SITIOS_TIENDA_EN_LINEA: 166,
    CREADOR_DE_SITIOS_PERSONAL_ADDON: 167,
    CREADOR_DE_SITIOS_ADDON: 214,
    CREADOR_DE_SITIOS_PROFESIONAL_ADDON: 168,
    CREADOR_DE_SITIOS_TIENDA_EN_LINEA_ADDON: 169,
    CREADOR_DE_SITIOS: [
      164,
      165,
      166,
      167,
      168,
      169,
      214,
    ],
    WEB_HOSTING_CREADOR_DE_SITIOS_PERSONAL: 170,
    WEB_HOSTING_CREADOR_DE_SITIOS_PROFESIONAL: 171,
    WEB_HOSTING_CREADOR_DE_SITIOS_TIENDA_EN_LINEA: 172,
    HOSTING_CREADOR_DE_SITIOS: [
      170,
      171,
      172,
    ],
    PROTECCION_WHOIS: 173,
    PLAN_PERSONAL: 174,
    SSL_GRATUITO: 176,
    SSL_PRIVADO: 177,
    SSL_EV: 178,
    SSL_WILDCARD: 179,
    PRODUCT_GROUPS: {
      ssl: [
        176,
        178,
        177,
        179,
      ],
      websitebuilder: [
        170,
        171,
        172,
        164,
        165,
        166,
        167,
        168,
        169,
        214,
      ],
      reseller: [50,
        51,
        52,
        53,
        54],
    },
    STANDALONE_GROUPS: [],
    PRODUCT_GIDS: {
      vps: 8,
      dedicated: 5,
      reseller: 4,
      shared: 1,
      wphosting: [],
      weebly: [],
      websitebuilder: [],
      gapps: [],
    },
    PORTAL_PRODUCT_IDS: [
      174,
      5,
      6,
      7,
      50,
      51,
      52,
      53,
      54,
      56,
      58,
      60,
      64,
      57,
      59,
      61,
      65,
      134,
      136,
      138,
      140,
      161,
      162,
      163,
      164,
      165,
      166,
    ],
    PRO_EMAIL_GID: 16,
    PRO_EMAIL_PRO_TRIAL: 244,
    PRO_EMAIL_PRO: 242,
    PRO_EMAIL_PREMIUM: 243,
    PRO_EMAIL_ULTRA: 245,
    PRO_EMAIL_ALL: [
      242,
      243,
      244,
      245,
    ],
    WPHOSTING_ALL: [

    ],
    PLUS_ALL: [
    ],
  }
  : {
    HOSTING_ALL: [
      5,
      6,
      437,
      7,
      47,
      329,
      332,
      335,
      50,
      51,
      52,
      53,
      54,
      86,
      385,
      388,
      391,
      394,
      397,
      400,
      403,
      406,
      409,
      412,
      415,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
      65,
      68,
      69,
      70,
      71,
      72,
      73,
      74,
      75,
      76,
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      183,
      302,
      305,
      308,
      311,
      314,
      317,
      320,
      323,
      326,
      343,
      346,
    ],
    BUSINESS: 7,
    PLUS: 47,
    SHARED_GID: 1,
    PLANO_P: 5,
    PLANO_M: 6,
    PLANO_STARTER: 437,
    PLANO_BUSINESS: 7,
    HOSPEDAGEM_3: 335,
    HOSPEDAGEM_COLABORADORES: 247,
    SHARED_ALL: [
      5,
      6,
      437,
      7,
      247,
      335,
    ],
    RESELLER_GID: 4,
    REVENDA_1: 50,
    REVENDA_2: 51,
    REVENDA_3: 52,
    REVENDA_4: 53,
    REVENDA_5: 54,
    REVENDA_COMECANDO: 385,
    REVENDA_CRESCENDO: 388,
    REVENDA_EXPANDINDO: 391,
    REVENDA_VETERANO: 394,
    REVENDA_VETERANO_100: 397,
    REVENDA_VETERANO_200: 400,
    REVENDA_VETERANO_300: 403,
    REVENDA_VETERANO_500: 406,
    REVENDA_VETERANO_150: 409,
    REVENDA_VETERANO_1000: 412,
    REVENDA_VETERANO_1200: 415,
    RESELLER_ALL: [
      50,
      51,
      52,
      53,
      54,
      385,
      388,
      391,
      394,
      397,
      400,
      403,
      406,
      409,
      412,
      415,
    ],
    DEDICATED_GID: 5,
    DEDICADO_LINUX_BASICO: 56,
    DEDICADO_WINDOWS_BASICO: 57,
    DEDICADO_LINUX_STANDARD: 58,
    DEDICADO_WINDOWS_STANDARD: 59,
    DEDICADO_LINUX_ELITE: 60,
    DEDICADO_WINDOWS_ELITE: 61,
    DEDICADO_HG1_EMPRESA: 62,
    DEDICADO_HG2_EMPRESA: 63,
    DEDICADO_LINUX_PRO: 64,
    DEDICADO_WINDOWS_PRO: 65,
    DEDICADO_LINUX_BLACK: 311,
    DEDICADO_LINUX_SAPPHIRE: 314,
    DEDICADO_LINUX_DIAMOND: 317,
    DEDICADO_WINDOWS_BLACK: 320,
    DEDICADO_WINDOWS_SAPPHIRE: 326,
    DEDICADO_WINDOWS_DIAMOND: 323,
    DEDICATED_ALL: [
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
      65,
      311,
      314,
      317,
      320,
      326,
      323,
    ],
    VPS_GID: 6,
    VPS_1: 68,
    VPS_2: 69,
    VPS_3: 70,
    VPS_4: 71,
    VPS_5: 72,
    VPS_6: 73,
    VPS_7: 74,
    VPS_8: 75,
    VPS_9: 76,
    VPS_500: 133,
    VPS_1000_WHM: 134,
    VPS_1000_SEM_WHM: 135,
    VPS_2000_WHM: 136,
    VPS_2000_SEM_WHM: 137,
    VPS_4000_WHM: 138,
    VPS_4000_SEM_WHM: 139,
    VPS_8000_WHM: 140,
    VPS_8000_SEM_WHM: 141,
    VPS_OPTIMIZED: 302,
    VPS_PLATINUM: 305,
    VPS_STANDARD: 308,
    VPS_ENTRY: 343,
    VPS_PREMIUM: 346,
    VPS_ALL: [
      68,
      69,
      70,
      71,
      72,
      73,
      74,
      75,
      76,
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      302,
      305,
      308,
      343,
      346,
    ],
    CRIADORDESITES: [
      272,
      278,
      284,
    ],
    SERVICES_GID: 7,
    CERTIFICADO_SSL: 8,
    CERTIFICADO_SSL_IP: 9,
    CERTIFICADO_SSL_EMPRESARIAL: 10,
    HORA_TECNICA_SYSADMIN: 13,
    INSTALACAO_WHMCS: 14,
    IP_DEDICADO: 15,
    PROTECAO_WHOIS_ENOM: 48,
    PLACA_RAID: 49,
    UPGRADE_UPLINK_100: 67,
    WHMCS_VPS: 78,
    CPANEL_VPS: 85,
    STREAMING_ADICIONAL_128: 88,
    STREAMING_AUTODJ_2GB: 89,
    INSTALACAO_SSL: 90,
    UPGRADE_RAM: 91,
    MSSQL_2014_STANDARD: 92,
    MSSQL_2014_ENTERPRISE: 93,
    RESTAURACAO_BACKUP: 94,
    SMARTERMAIL_PROFESSIONAL_UNLIMITED_MENSAL: 95,
    UPGRADE_HD_250GB: 96,
    UPGRADE_HD_500GB: 97,
    UPGRADE_HD_750GB: 98,
    UPGRADE_HD_1TB: 99,
    ALTERACAO_DOMINIO_PRIMARIO: 100,
    UPGRADE_HD_2TB: 101,
    UPGRADE_HD_3TB: 102,
    LICENCA_TERMINAL_SERVICES: 103,
    MSSQL_2014_WEB: 104,
    UPGRADE_UPLINK_1000: 105,
    SMARTERMAIL_ENTERPRISE_UNLIMITED_MENSAL: 106,
    SITEBUILDER_50_LICENCAS: 111,
    SITEBUILDER_100_LICENCAS: 112,
    SITEBUILDER_500_LICENCAS: 113,
    SMARTERMAIL_ENTERPRISE_250_MENSAL: 114,
    SMARTERMAIL_ENTERPRISE_250_UNICO: 115,
    UPGRADE_SAN_PROVO_100GB: 117,
    UPGRADE_SAN_PROVO_250GB: 118,
    UPGRADE_SAN_PROVO_500GB: 119,
    UPGRADE_SAN_PROVO_750GB: 120,
    UPGRADE_SAN_PROVO_1TB: 121,
    UPGRADE_SAN_PROVO_2TB: 122,
    GOMOBI: 123,
    UPGRADE_SAN_PROVO_2_5TB: 125,
    UPGRADE_SAN_PROVO_3TB: 126,
    UPGRADE_SAN_PROVO_3_5TB: 127,
    UPGRADE_SAN_PROVO_4TB: 128,
    LOGO_FREELOGOSERVICES: 129,
    CARTOES_250_FREELOGOSERVICES: 130,
    JUMPSTART: 132,
    UPGRADE_RAM_PROVO_4GB: 142,
    UPGRADE_RAM_PROVO_8GB: 143,
    UPGRADE_RAM_PROVO_12GB: 144,
    UPGRADE_RAM_PROVO_16GB: 145,
    UPGRADE_RAM_PROVO_20GB: 146,
    UPGRADE_RAM_PROVO_24GB: 147,
    UPGRADE_RAM_PROVO_28GB: 148,
    WEEBLY_INICIANTE: 151,
    WEEBLY_PRO: 152,
    WEEBLY_EMPRESARIAL: 153,
    WEEBLY_BASICO: 154,
    SMARTERMAIL_ENTERPRISE_1000_UNICO: 156,
    SMARTERMAIL_PROFESSIONAL_1000_UNICO: 157,
    SMARTERMAIL_PROFESSIONAL_250_UNICO: 165,
    SMARTERMAIL_PROFESSIONAL_250_MENSAL: 168,
    SMARTERMAIL_PROFESSIONAL_1000_MENSAL: 171,
    SMARTERMAIL_PROFESSIONAL_UNLIMITED_UNICO: 174,
    SMARTERMAIL_ENTERPRISE_1000_MENSAL: 177,
    SMARTERMAIL_ENTERPRISE_UNLIMITED_UNICO: 180,
    SMARTERSTATS_PROFESSIONAL_50_UNICO: 186,
    SMARTERSTATS_PROFESSIONAL_50_MENSAL: 189,
    SMARTERSTATS_PROFESSIONAL_250_UNICO: 192,
    SMARTERSTATS_PROFESSIONAL_250_MENSAL: 195,
    SMARTERSTATS_PROFESSIONAL_1000_UNICO: 198,
    SMARTERSTATS_PROFESSIONAL_1000_MENSAL: 201,
    SMARTERSTATS_ENTERPRISE_50_UNICO: 204,
    SMARTERSTATS_ENTERPRISE_50_MENSAL: 207,
    SMARTERSTATS_ENTERPRISE_250_UNICO: 210,
    SMARTERSTATS_ENTERPRISE_250_MENSAL: 213,
    SMARTERSTATS_ENTERPRISE_1000_UNICO: 216,
    SMARTERSTATS_ENTERPRISE_1000_MENSAL: 219,
    SMARTERTRACK_PROFESSIONAL_2: 222,
    SMARTERTRACK_PROFESSIONAL_5: 225,
    SMARTERTRACK_PROFESSIONAL_10: 228,
    SMARTERTRACK_ENTERPRISE_5: 231,
    SMARTERTRACK_ENTERPRISE_10: 234,
    CERTIFICADO_SSL_WILDCARD: 261,
    CRIADOR_SITES_LOJA: 272,
    CRIADOR_SITES_LOJA_ADDON: 275,
    CRIADOR_SITES_PESSOAL: 278,
    CRIADOR_SITES_PESSOAL_ADDON: 281,
    CRIADOR_SITES_PROFISSIONAL: 284,
    CRIADOR_SITES_PROFISSIONAL_ADDON: 287,
    CRIADOR_SITES_ADDON: 338,
    SSL_FREE: 290,
    SSL_PRIVADO: 293,
    SSL_EMPRESARIAL: 296,
    SSL_WILDCARD: 299,
    MSSQL_2017_2CORE_WEB: 349,
    MSSQL_2017_2CORE_STANDARD: 352,
    MSSQL_2017_2CORE_ENTERPRISE: 355,
    MSSQL_2017_4CORE_WEB: 358,
    MSSQL_2017_4CORE_STANDARD: 361,
    MSSQL_2017_4CORE_ENTERPRISE: 364,
    MSSQL_2017_8CORE_WEB: 367,
    MSSQL_2017_8CORE_STANDARD: 370,
    MSSQL_2017_8CORE_ENTERPRISE: 373,
    MSSQL_2017_16CORE_WEB: 376,
    MSSQL_2017_16CORE_STANDARD: 379,
    MSSQL_2017_16CORE_ENTERPRISE: 382,
    SERVICES_ALL: [
      8,
      9,
      10,
      13,
      14,
      15,
      48,
      49,
      67,
      78,
      85,
      88,
      89,
      90,
      91,
      92,
      93,
      94,
      95,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      106,
      107,
      108,
      109,
      110,
      111,
      112,
      113,
      114,
      115,
      117,
      118,
      119,
      120,
      121,
      122,
      123,
      125,
      126,
      127,
      128,
      129,
      130,
      132,
      142,
      143,
      144,
      145,
      146,
      147,
      148,
      151,
      152,
      153,
      154,
      156,
      157,
      165,
      168,
      171,
      174,
      177,
      180,
      186,
      189,
      192,
      195,
      198,
      201,
      204,
      207,
      210,
      213,
      216,
      219,
      222,
      225,
      228,
      231,
      234,
      261,
      272,
      275,
      278,
      281,
      284,
      287,
      290,
      293,
      296,
      299,
      338,
      349,
      352,
      355,
      358,
      361,
      364,
      367,
      370,
      373,
      376,
      379,
      382,
    ],
    SITELOCK_BASIC: 107,
    SITELOCK_PROFESSIONAL: 108,
    SITELOCK_PREMIUM: 109,
    SITELOCK_ENTERPRISE: 110,
    SITELOCK_ALL: [
      107,
      108,
      109,
      110,
    ],
    PLUS_GID: 10,
    HOSPEDAGEM_PLUS: 47,
    PLUS_2: 83,
    PLUS_3: 84,
    REVENDA_PLUS: 86,
    PLUS_ALL: [
      47,
      83,
      84,
      86,
    ],
    DOMAINS_GID: 12,
    DOMINIO_INTERNACIONAL: 11,
    DOMINIO_NACIONAL: 12,
    TRANSFERENCIA_DOMINIO_INTERNACIONAL: 66,
    DOMAINS_ALL: [
      11,
      12,
      66,
    ],
    STREAMING_GID: 13,
    RADIO_ONLINE_1: 79,
    RADIO_ONLINE_2: 80,
    RADIO_ONLINE_3: 81,
    RADIO_ONLINE_4: 82,
    STREAMING_1_AUTOMATICO: 116,
    STREAMING_ALL: [
      79,
      80,
      81,
      82,
      116,
    ],
    WEEBLY_GID: 15,
    WEEBLY_HOST_INICIANTE: 149,
    WEEBLY_HOST_PRO: 150,
    WEEBLY_HOST_BASICO: 155,
    WEEBLY_ALL: [
      149,
      150,
      154,
      155,
    ],
    GAPPS_GID: 16,
    GOOGLE_APPS: 159,
    GAPPS_ALL: [
      159,
    ],
    GAPPS_ADDON_MONTH: 6,
    GAPPS_ADDON_ANNUALY: 12,
    CODEGUARD_GID: 19,
    CODEGUARD_BASICO: 235,
    CODEGUARD_INICIANTE: 238,
    CODEGUARD_PROFISSIONAL: 241,
    CODEGUARD_PREMIUM: 244,
    CODEGUARD_ALL: [
      235,
      238,
      241,
      244,
    ],
    WPHOSTING_GID: 22,
    WORDPRESS_BLOG: 250,
    WORDPRESS_STARTUP: 253,
    WORDPRESS_PRO: 256,
    WORDPRESS_VIP: 259,
    WPHOSTING_ALL: [
      250,
      253,
      256,
      259,
    ],
    CRIADOR_SITES_GID: 23,
    CRIADOR_SITES_HOST_PESSOAL: 263,
    CRIADOR_SITES_HOST_PROFISSIONAL: 266,
    CRIADOR_SITES_HOST_LOJA: 269,
    CRIADOR_SITES_ALL: [
      263,
      266,
      269,

    ],
    CRIADOR_SITES_ALL_AND_ADDONS: [
      263,
      266,
      269,
      272,
      278,
      284,
      275,
      281,
      287,
      338,
    ],
    PRODUCT_GROUPS: {
      ssl: [
        296,
        290,
        293,
        299,
      ],
      websitebuilder: [
        263,
        266,
        269,
        272,
        278,
        284,
        275,
        281,
        287,
        338,
      ],
      wphosting: [
        250,
        253,
        256,
        259,
      ],
      sitelock: [
        107,
        108,
        109,
        110,
      ],
      reseller: [
        50,
        51,
        52,
        53,
        54,
      ],
      webpresence: [

      ],
    },
    STANDALONE_GROUPS: {
      sitelock: [
        107,
        108,
        109,
        110,
      ],
      websitebuilder: [
        263,
        266,
        269,
        272,
        278,
        284,
        275,
        281,
        287,
        338,
      ],
      wphosting: [
        250,
        253,
        256,
        259,
      ],
      codeguard: [
        235,
        238,
        241,
        244,
      ],
      weebly: [
        149,
        150,
        155,
      ],
      gapps: [
        159,
      ],
      radio: [
        79,
        80,
        81,
        82,
        116,
      ],
    },
    PRODUCT_GIDS: {
      vps: 6,
      dedicated: 5,
      reseller: 4,
      shared: 1,
      wphosting: 22,
      weebly: 15,
      websitebuilder: 23,
      gapps: 16,
      plus: 10,
    },
    PORTAL_PRODUCT_IDS: [
      247,
      5,
      6,
      7,
      155,
      149,
      150,
      50,
      51,
      52,
      53,
      54,
      56,
      58,
      60,
      64,
      57,
      59,
      61,
      65,
      134,
      136,
      138,
      140,
      47,
      250,
      256,
      253,
      259,
      272,
      278,
      284,
    ],
    PRO_EMAIL_GID: 28,
    PRO_EMAIL_PRO_TRIAL: 430,
    PRO_EMAIL_PRO: 421,
    PRO_EMAIL_PREMIUM: 424,
    PRO_EMAIL_ULTRA: 443,
    PRO_EMAIL_ALL: [
      421,
      424,
      430,
      443,
    ],
  };
