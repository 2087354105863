import React from 'react';
import * as Styles from './FinishDomainSetupStep.styles';
import {
  Alert,
  Button,
  IconPreferences,
} from 'gatorcomponents';
import PropTypes from 'prop-types';

export const FinishDomainSetupStep = ({
  title,
  subtitle,
  box,
  from = 'Godaddy',
  onGoBack,
  onClose,
  finishLabel,
  backLabel,
}) => {
  const handleBack = () => {
    onGoBack && onGoBack();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Styles.Wrapper data-testid="FinishDomainSetupStep">
      <Styles.Title data-testid="title">
        <IconPreferences size="xl" />
        {title}
      </Styles.Title>

      <Styles.Subtitle data-testid="subtitle">
        {subtitle}
      </Styles.Subtitle>

      <Styles.Box>
        <Styles.BoxTitle data-testid="boxTitle">
          {box.title}
        </Styles.BoxTitle>

        <Styles.BoxDescription data-testid="boxDescription">
          {box.description({ from })}
        </Styles.BoxDescription>

        <Styles.AlertWrapper>
          <Alert
            testId="boxAlert"
            variant="timed"
            withoutBackground
            description={box.alert}
          />
        </Styles.AlertWrapper>


      </Styles.Box>

      <Styles.ButtonsWrapper>
        <Button
          testId="back"
          size="large"
          variant="tertiary"
          onClick={handleBack}
          label={backLabel}
        />

        <Button
          testId="close"
          size="large"
          onClick={handleClose}
          label={finishLabel}
        />
      </Styles.ButtonsWrapper>
    </Styles.Wrapper>
  );
};
FinishDomainSetupStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default FinishDomainSetupStep;
