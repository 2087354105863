import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    gap: 8px;
    margin: 0 24px;
    padding-bottom: 72px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `};
`;

export const Section = styled.section`
  ${({ noPadding, theme }) => css`
    background: ${theme.v2.colors.white.primary};
    padding: ${noPadding ? 0 : '0 24px'};
  `}
`;

export const DomainsList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LoadingWrapper = styled.div`
  margin: 24px 0;
`;

export const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px;
`;

export const EmptyStateLabel = styled.span`
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
    white-space: nowrap;

    media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const Filter = styled.div`
`;

export const LoadMoreWrapper = styled.div`
  ${({ withoutBottom }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${withoutBottom ? '20px 0px 0px 20px' : '20px 0'};
    width: 100%;
  `}
`;
