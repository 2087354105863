import { CALL_API } from '@/middleware/api';
import { GROUPED_PRODUCTS_URL } from '@/config/api';
import { config } from '@/config';
import { sortAlphabeticProductGroupsByDomain } from '@/utils/Application/sort';
import {
  RECEIVE_PRODUCT_GROUPS, FILTER_PRODUCTS_GROUPS, ABORT_REQUEST_PRODUCTS_GROUPS,
  REQUEST_PRODUCT_GROUPS, ERROR_PRODUCT_GROUPS, ABORT_UPDATE_SITEBUILDER_STATUS_ON_PRODUCTS,
  UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS, REMOVE_PRODUCT_GROUP,
} from './actionsTypes';


const receiveProductGroups = (productGroups) => {
  let activeProductGroups = productGroups.filter(products => products.domainstatus === 'Active');
  let suspendedProducts = productGroups.filter(products => products.domainstatus === 'Suspended');
  let pendingProducts = productGroups.filter(products => products.domainstatus === 'Pending' || products.domainstatus === 'Fraud');

  activeProductGroups = sortAlphabeticProductGroupsByDomain(activeProductGroups);
  suspendedProducts = sortAlphabeticProductGroupsByDomain(suspendedProducts);
  pendingProducts = sortAlphabeticProductGroupsByDomain(pendingProducts);

  const allProductGroups = [
    ...activeProductGroups,
    ...suspendedProducts,
    ...pendingProducts,
  ];

  const filterDomain = [];
  const filterStatus = [];
  allProductGroups.forEach((element) => {
    const { domain } = element.domain;
    const { domainstatus } = element;
    if (domain && filterDomain.indexOf(domain) === -1) {
      filterDomain.push(domain);
    }
    if (domainstatus && (domainstatus !== 'Terminated') && filterStatus.indexOf(domainstatus) === -1) {
      filterStatus.push(domainstatus);
    }
  });

  return {
    type: RECEIVE_PRODUCT_GROUPS,
    productGroups: allProductGroups,
    filterDomain: filterDomain.sort(),
    loadedDate: Date.now(),
    filterStatus,
  };
};

const receiveFilteredGroups = allProductGroups => ({
  type: FILTER_PRODUCTS_GROUPS,
  filterProductGroups: allProductGroups,
});

export const filterProductGroups = (site, status) => (dispatch, getState) => {
  const groups = getState().productGroups.bkpProducts;
  let allProductGroups;


  if (site !== '') {
    allProductGroups = groups.filter((product) => {
      if (product.domain.domain === site) {
        return product;
      }
      return null;
    });
  } else {
    allProductGroups = groups;
  }

  if (status !== '') {
    allProductGroups = allProductGroups.filter((product) => {
      if (product.domainstatus === status) {
        return product;
      }
      return null;
    });
  }

  return dispatch(receiveFilteredGroups(allProductGroups));
};

export const removeProductGroup = idToRemove => (dispatch, getState) => {
  const groups = getState().productGroups.byId;

  const newGroups = groups.splice(groups.findIndex(item => item.id === Number(idToRemove)), 1);

  return {
    type: REMOVE_PRODUCT_GROUP,
    newGroups,
  };
};

const abortRequestProductGroups = () => ({
  type: ABORT_REQUEST_PRODUCTS_GROUPS,
});

export const loadProductGroups = () => (dispatch, getState) => {
  const { loadedDate } = getState().productGroups;

  // check expiration data
  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return dispatch(abortRequestProductGroups());
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: GROUPED_PRODUCTS_URL,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_PRODUCT_GROUPS }),
        success: data => receiveProductGroups(data.data),
        error: () => ({ type: ERROR_PRODUCT_GROUPS }),
      },
    },
  });
};

const changeSiteBuilderStatus = ({ products, status, hostingId }) => products.map((item) => {
  const handleItem = item;
  if (handleItem.id === Number(hostingId)) {
    handleItem.activeBuilder = status;
    const handleActions = handleItem.actions.map((action) => {
      const handleActionItem = action;
      if (handleActionItem.type === 'websitebuilder') {
        handleActionItem.status = status ? 'active' : 'inactive';
      }

      return handleActionItem;
    });

    handleItem.actions = handleActions;
  }
  return handleItem;
});

export const updateSiteBuilderStatusOnMySitesPage = (hostingId, status) => (dispatch, getState) => {
  const { bkpProducts } = getState().productGroups;
  if (!bkpProducts) {
    return dispatch({
      type: ABORT_UPDATE_SITEBUILDER_STATUS_ON_PRODUCTS,
    });
  }
  const updatedBkpProducts = changeSiteBuilderStatus({ products: bkpProducts, status, hostingId });

  return dispatch({
    type: UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS,
    data: updatedBkpProducts,
  });
};
