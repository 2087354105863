import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const TwoFactorInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xss};
    font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StatusTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const StatusDescription = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const StatusButton = styled.span`
  ${({ theme, isTwoFactorEnabled }) => css`
    background-color: transparent;
    border: none;
    color: ${isTwoFactorEnabled ? theme.v2.colors.feedback.problemMedium : theme.v2.colors.brand.primaryMedium};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    cursor: pointer;
  `}
`;
