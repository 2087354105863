import styled from 'styled-components';

export const Wrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;

  svg {
    display: block;
  }
`;
