import styled, { css } from 'styled-components';

export const Container = styled.section`
${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: column;
    padding: 0px 20px 40px 20px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 32px 16px;
    }
  `}
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 40px 0px 62px 0px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 0px 0px 40px 0px;
      max-width: 322px;
    }
  `}
`;

export const BenefitsWrapper = styled.ul`
  ${({ theme }) => css`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:10px;
    justify-content: space-around;
    margin: 0px 0px 60px 0px;
    padding:0px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
      gap: 20px;
      margin: 0px 0px 40px 0px;
    }
  `}
`;

export const BenefitWrapper = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const BenefitTitle = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 21px;
    margin: 20px 0px 0px 0px;
    max-width: 234px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      font-size: 20px;
      max-width: 323px;
    }
  `}
`;

export const BenefitDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 21px;
    margin: 16px 0px 0px 0px;
    max-width:220px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      font-size: 14px;
      line-height: 18px;
      max-width: 322px;
    }
  `}
`;

export const Logo = styled.img`
  margin-bottom: 16px;
`;

export const ErpTitle = styled.h5`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 20px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 27px;
    margin: 0px;
    text-align: center;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      max-width: 500px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      max-width: 323px;
    }
  `}
`;

export const ErpDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    max-width: 808px;
    text-align: center;
    margin: 16px 0px 16px;
    span {
      font-weight: bold;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 14px;
      max-width: 500px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      max-width: 320px;
    }
  `}
`;

export const RowButtonWrapper = styled.div``;
