const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& > *': {
      flex: 1,
      marginTop: 0,
    },
  },
  selectField: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 25px 0px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 12px 0px 0px',
    },
  },
  textField: {
    fontSize: '14px',
    width: '100%',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 1,
      fontSize: 14,
    },
    '& input': {
      padding: 8,
      fontSize: 14,
    },
    '& > p': {
      margin: '8px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 25px 0px',
      maxWidth: '100%',

      '& fieldset': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 12px 0px 0px',
    },
  },
  addDNSForm: {
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.color.indigo}`,
      borderRadius: '2px',
      padding: '32px 24px 24px 24px',
    },
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  buttonsWrapper: {
    display: 'flex !important',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
    },

    '& button': {
      marginRight: 12,
    },
  },
  outlinedCancel: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
  },
  header: {
    marginBottom: 24,
  },
  closeFormPortal: {
    padding: 0,
    display: 'block',
    marginLeft: 'auto',
  },
  addRecordTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '29px',
  },
});

export default styles;
