import React, { } from 'react';
import useLocale from '@/hooks/useLocale';
import * as Styles from './PixCard.styles';
import { billingAnalytics } from '@/analytics';
import { OriginCalling } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';

const PixCard = ({
  enabledContentDoubts = true,
  originCalling,
}) => {
  const { billing: billingLocale } = useLocale();
  const { manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale } = billingLocale;

  const securityTitlePix = manageSubscriptionPaymentInvoiceModalLocale.securityTitlePixLabel;
  const securityDescriptionPix = manageSubscriptionPaymentInvoiceModalLocale.securityDescriptionPixLabel;
  const agilityTitlePix = manageSubscriptionPaymentInvoiceModalLocale.agilityTitlePixLabel;
  const agilityDescriptionPix = manageSubscriptionPaymentInvoiceModalLocale.agilityDescriptionPixLabel;
  const taxTitlePix = manageSubscriptionPaymentInvoiceModalLocale.taxTitlePixLabel;
  const taxDescriptionPix = manageSubscriptionPaymentInvoiceModalLocale.taxDescriptionPixLabel;
  const doubtsPix = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixLabel;
  const doubtsLink = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixLink;
  const doubtsURL = manageSubscriptionPaymentInvoiceModalLocale.doubtsPixURL;
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;

  const onClickSupportLink = () => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('4_central_ajuda');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('4_central_ajuda');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('4_central_ajuda');
    }
  };

  return (
    <>
      <Styles.Content data-testid="pix-card-content">
        <Styles.List>
          <Styles.Item>
            <Styles.Title data-testid="security-title-pix-label">{securityTitlePix}</Styles.Title>
            <Styles.Description data-testid="security-description-pix-label">{securityDescriptionPix}</Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Title>{agilityTitlePix}</Styles.Title>
            <Styles.Description>{agilityDescriptionPix}</Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Title>{taxTitlePix}</Styles.Title>
            <Styles.Description>{taxDescriptionPix}</Styles.Description>
          </Styles.Item>
        </Styles.List>
      </Styles.Content>
      {enabledContentDoubts && (
      <Styles.ContentDoubts>
        {doubtsPix}
        <Styles.Link
          data-testid="generate-pix-link"
          href={doubtsURL}
          target="_blank"
          onClick={() => { onClickSupportLink(); }}
        >
          {doubtsLink}
        </Styles.Link>
      </Styles.ContentDoubts>
      )}
    </>
  );
};


export default PixCard;
