import { useState, useEffect, useCallback } from 'react';

const FILTER_OPTION_ALL = 'all';

export const statusOrderRules = [
  'pendingPayment',
  'pendingAnalysis',
  'pending',
  'expired',
  'suspended',
  'overdue',
  'toDue',
  'active',
  'registered',
  'transferredAway',
  'cancelled',
];

const useSubscriptionsFilter = ({
  items = [],
} = {}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const getCheckedOptionsValues = useCallback(options => options
    .filter(option => option.checked)
    .map(option => option.value), []);

  const getTimestamp = dateString => new Date(dateString).getTime();

  const orderByDate = useCallback(
    (
      { registerDate: dateStringA },
      { registerDate: dateStringB },
    ) => -(getTimestamp(dateStringA) - getTimestamp(dateStringB)), [],
  );

  const filterByOptions = useCallback((items) => {
    const optionsValues = getCheckedOptionsValues(filterOptions);
    return optionsValues.includes(items.substatus) || optionsValues.includes(FILTER_OPTION_ALL);
  }, [filterOptions, getCheckedOptionsValues]);

  const filterByName = useCallback((item) => {
    const hasProductName = item.productName.toLowerCase().includes(filterText.toLowerCase());
    const hasDomain = item.domain.toLowerCase().includes(filterText.toLowerCase());
    const showAnyway = filterText === '';

    return hasProductName || hasDomain || showAnyway;
  }, [filterText]);

  useEffect(() => {
    if (items.length !== 0) {
      const itemsFiltered = statusOrderRules
        .flatMap(substatus => items
          .filter(item => item.substatus === substatus)
          .sort(orderByDate))
        .filter(filterByOptions)
        .filter(filterByName);

      setFilteredItems(itemsFiltered);
    }
  }, [filterByName, filterByOptions, orderByDate, items, filterOptions, filterText]);

  return {
    filteredItems,
    filterOptions,
    setFilterOptions,
    filterText,
    setFilterText,
  };
};

export default useSubscriptionsFilter;
