import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans, withI18n } from 'react-i18next';
import {
  withStyles, IconButton, CardContent, CardActions, Collapse, CircularProgress, MenuItem, Select, FormControl, InputLabel, OutlinedInput, Tooltip, ClickAwayListener,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Close, ExpandMore, Receipt } from '@material-ui/icons';
import classnames from 'classnames';
import {
  loadCreditCard, removeCreditCard, saveCreditCard, getCreditCardInstallments, getCreditCardInstallmentsByProductData,
} from '@/redux/actions/creditCard';
import { creditCardType } from '@/types';
import {
  notifierError, notifierSuccess, notifierErrorLong, notifierSuccessLong,
} from '@/utils/Application/notifier';
import { isDateLowerThanCurrent } from '@/utils/Validators/isDateLowerThanCurrent';
import { getCreditCardTypeInfo } from '@/utils/Application/getCreditCardTypeInfo';
import { generateRecaptchaToken } from '@/utils/Application/reCaptcha';
import { loadInvoiceDetails, updateStatusInvoiceToConfirmingPayment, loadInvoices } from '@/redux/actions/invoices';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import Loader from '@/components/Layout/Loader';
import InvoiceDetails from '@/components/Invoices/InvoiceDetails';
import InvoiceSummaryCard from '@/components/Cards/InvoiceSummaryCard';
import { getPaymentMethod } from '@/redux/actions/paymentMethod';
import { ERROR_CC_METHOD, SAVE_CREDIT_CARD_ERROR } from '@/redux/actions/actionsTypes';
import { config } from '@/config';
import { PHISHING_STATUS_ANALYSIS } from '@/config/invoices/invoiceStatus';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import CreditCardPaas from '@/components/CreditCard/CreditCardPaas';
import { cancelBuyCCByPortalClick } from '@/utils/ThirdParties/tagManager';
import { CreditCardPreview } from '../CreditCardPreview';
import { CreditCardPreview as CreditCardPreviewNewVersion } from '../NewCreditCardPreview';
import { Button as ButtonV1 } from '@/pages/common/v1/Button';

import { PAYMENT_STATUS } from '@/pages/Invoices/types';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { IconInfo } from '@/icons';
import styles from './styles';
import { PaypalActionTypes } from '@/components/Paypal/PaypalDialog/Paypal.types';
import { CheckedDefaultCreditCardPayment } from '../CheckedDefaultCreditCardPayment';

class CreditCardDialog extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    const isEnabled = true;

    this.state = {
      isEnabled,
      cardForm: {
        ccname: '',
        ccnumber: '',
        ccvnumber: '',
        expdate: '',
      },
      saveNewCreditCard: false,
      cardInfo: {
        minLength: 0,
        maxLength: 16,
        codeSize: 4,
        maxLengthName: 25,
        minLengthName: 3,
      },
      focusedField: 'number',
      canSubmit: false,
      existsCreditCard: false,
      invoiceDetails: {},
      creditCardInstallments: [],
      selectedCreditCardInstallment: '',
      showCreditCardInstallmentsTooltip: false,
      showDetails: false,
      loadingCardCredit: false,
      loadingDetailsInvoice: false,
      enabledChangeProductsPaymentMethodToCC: true,
      loadingCreditCardInstallments: true,
      recaptchaToken: '',
      labelCvv: t('creditcard.label.cvvShort'),
      hasValidCreditCardSelected: false,
    };
  }

  componentDidMount() {
    this.recaptchaRef = React.createRef();
    this.handleLoadCreditCard();
    this.setState({ loadingPaymentFlux: false });
    this.handleCreditCardInstallments();
  }

  get language() {
    const { i18n } = this.props;
    return i18n.language;
  }

  verifyCallback = (token) => {
    this.setState({
      recaptchaToken: token,
    });
  }

handletValidCreditCardSelectedStatus = (isValid) => {
  const { onSetCreditCardSelected } = this.props;
  onSetCreditCardSelected && onSetCreditCardSelected(isValid);
}

  handleExpand = async () => {
    const { showDetails, invoiceDetails } = this.state;
    this.setState({
      showDetails: !showDetails,
      loadInvoiceDetails: true,
    });

    if (!Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      const { loadInvoiceDetails, invoiceId } = this.props;

      const data = await loadInvoiceDetails(invoiceId);
      this.setState({
        invoiceDetails: data.invoice,
        loadingDetailsInvoice: false,
      });
    }
  }

  getMdGridAddNewCard = (existsCreditCard, showResumeData) => {
    if (!showResumeData) { return 12; }
    return !existsCreditCard ? 12 : 6;
  }

  handleChangeCreditCardSelected = () => {
    const {
      saveNewCreditCard, existsCreditCard, creditCardInstallments, selectedCreditCardInstallment,
    } = this.state;

    const {
      subscriptionAnalytics, callingOrigin, isCreditCardInstallmentsEnabled,
    } = this.props;

    const hasSelectedCreditCardInstallment = creditCardInstallments && creditCardInstallments.filter(installment => installment.gateway === selectedCreditCardInstallment).length > 0;
    const hasValidCreditCardSelected = existsCreditCard && !saveNewCreditCard;
    this.setState(prevState => ({
      ...prevState,
      saveNewCreditCard: !saveNewCreditCard,
      hasValidCreditCardSelected,
      canSubmit: isCreditCardInstallmentsEnabled ? hasSelectedCreditCardInstallment && existsCreditCard && !saveNewCreditCard : existsCreditCard && !saveNewCreditCard,
    }));

    this.handletValidCreditCardSelectedStatus(hasValidCreditCardSelected);

    if (subscriptionAnalytics) {
      callingOrigin === PaypalActionTypes.CALLING_BY_PAYMENT_MODAL && subscriptionAnalytics.executePaymentClick(hasValidCreditCardSelected ? '3_usar_cartão_existente' : '3_usar_cartão_novo');
      callingOrigin === PaypalActionTypes.CALLING_BY_MANAGER_INVOICES && subscriptionAnalytics.executePaymentInvoiceManagerClick(hasValidCreditCardSelected ? '3_usar_cartão_existente' : '3_usar_cartão_novo');
      callingOrigin === PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS && subscriptionAnalytics.executePaymentInSubscriptionsListClick(hasValidCreditCardSelected ? '3_usar_cartão_existente' : '3_usar_cartão_novo');
    }
  }

  handleLoadCreditCard = () => {
    const { creditCardInstallments, selectedCreditCardInstallment } = this.state;
    const { loadCreditCard } = this.props;
    loadCreditCard().then((data) => {
      this.setState({ loadingCardCredit: true });
      const {
        subscriptionAnalytics, callingOrigin, isCreditCardInstallmentsEnabled,
      } = this.props;

      const hasSelectedCreditCardInstallment = creditCardInstallments.filter(installment => installment.gateway === selectedCreditCardInstallment).length > 0;

      const canSubmit = !isCreditCardInstallmentsEnabled ? true : hasSelectedCreditCardInstallment;

      if (data.creditCard && data.creditCard.cardtype) {
        this.setState({
          saveNewCreditCard: true,
          radioDisabled: false,
          canSubmit,
          hasValidCreditCardSelected: true,
          existsCreditCard: true,
        });

        if (subscriptionAnalytics) {
          callingOrigin === PaypalActionTypes.CALLING_BY_PAYMENT_MODAL && subscriptionAnalytics.executePaymentClick('3_usar_cartão_existente');
          callingOrigin === PaypalActionTypes.CALLING_BY_MANAGER_INVOICES && subscriptionAnalytics.executePaymentInvoiceManagerClick('3_usar_cartão_existente');
          callingOrigin === PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS && subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_usar_cartão_existente');
        }

        this.handletValidCreditCardSelectedStatus(true);
      } else if (subscriptionAnalytics) {
        callingOrigin === PaypalActionTypes.CALLING_BY_PAYMENT_MODAL && subscriptionAnalytics.executePaymentClick('3_usar_cartão_novo');
        callingOrigin === PaypalActionTypes.CALLING_BY_MANAGER_INVOICES && subscriptionAnalytics.executePaymentInvoiceManagerClick('3_usar_cartão_novo');
        callingOrigin === PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS && subscriptionAnalytics.executePaymentInSubscriptionsListClick('3_usar_cartão_novo');
      }
    });
  }

  handleCreditCardInstallments = async () => {
    const {
      getCreditCardInstallmentsByProductData,
      getCreditCardInstallments, invoiceId,
      subscriptionId, subscriptionType,
    } = this.props;

    if (invoiceId && invoiceId !== undefined) {
      await getCreditCardInstallments(invoiceId).then((data) => {
        this.setState({ loadingCreditCardInstallments: false });
        this.setState({ creditCardInstallments: data.installments });
      });
    } else {
      await getCreditCardInstallmentsByProductData(subscriptionId, subscriptionType).then((data) => {
        this.setState({ loadingCreditCardInstallments: false });
        this.setState({ creditCardInstallments: data.installments });
      });
    }
  }

  cleanHgAcademyGAEvent = async () => {
    const { invoiceDetails } = this.state;
    const { loadInvoiceDetails, invoiceId } = this.props;
    if (!Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      const data = await loadInvoiceDetails(invoiceId);
      const invoiceDesc = data.invoice.items[0] ? data.invoice.items[0].description : '';
      const matched = invoiceDesc.match(/Hostgator Academy Pass/);
      if (matched && matched.length > 0) {
        cancelBuyCCByPortalClick();
      }
    }
  };

   isCallingModalByManagerInvoices = () => {
     const {
       callingOrigin,
     } = this.props;
     return [PaypalActionTypes.CALLING_BY_PAYMENT_MODAL, PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS].includes(callingOrigin);
   }

  paymentHandle = async () => {
    const {
      enqueueSnackbar,
      getPaymentMethod,
      invoiceId,
      loadInvoices,
      newPaymentMethod,
      onClose,
      t,
      onPaymentExecuted,
      updateStatusInvoiceToConfirmingPayment,
      isExceedsRetriesCC,
      providerIdentifier,
      showCreditCardCheckbox,
    } = this.props;

    const {
      selectedCreditCardInstallment,
      saveNewCreditCard,
      enabledChangeProductsPaymentMethodToCC,
    } = this.state;

    this.setState({ loadingPaymentFlux: true });

    if (saveNewCreditCard && isExceedsRetriesCC) {
      enqueueSnackbar('snackbar.creditCard.paymentFailByAttemptsLimit', notifierError);
      this.setState({ loadingPaymentFlux: false });
      return;
    }

    const currentPaymentMethod = selectedCreditCardInstallment && selectedCreditCardInstallment.trim().length > 0 ? selectedCreditCardInstallment : newPaymentMethod;

    getPaymentMethod(currentPaymentMethod, invoiceId, providerIdentifier, showCreditCardCheckbox ? enabledChangeProductsPaymentMethodToCC : false).then((action) => {
      const isError = action.type === ERROR_CC_METHOD;

      if (isError) {
        switch (action.status) {
          case 'INVALID_INVOICE_STATUS':
            enqueueSnackbar('snackbar.creditCard.invoice_status_not_unpaid', notifierErrorLong);
            updateStatusInvoiceToConfirmingPayment(invoiceId);
            onClose();
            break;
          case 'PENDING_ANALYSIS':
            loadInvoices('Unpaid', true);
            enqueueSnackbar('snackbar.creditCard.invoicePaymentPending', notifierSuccessLong);
            onPaymentExecuted({ status: PAYMENT_STATUS.PENDING_ANALYSIS }, this.isCallingModalByManagerInvoices() ? t('routes.invoices') : t('routes.subscriptions'));
            onClose();
            break;
          default:
            enqueueSnackbar('snackbar.creditCard.invoice_error', notifierError);
            if (Object.prototype.hasOwnProperty.call(action, 'key') && action.key === PHISHING_STATUS_ANALYSIS) {
              const { onClose, loadInvoices } = this.props;
              onClose();
              const noCache = true;
              loadInvoices('Unpaid', noCache);
            }
            break;
        }
      } else {
        onClose();
        onPaymentExecuted({ status: PAYMENT_STATUS.SUCCESS }, this.isCallingModalByManagerInvoices() ? t('routes.invoices') : t('routes.subscriptions'));
        enqueueSnackbar('snackbar.creditCard.invoicePaymentSuccess', notifierSuccess);
        updateStatusInvoiceToConfirmingPayment(invoiceId);
      }
      this.setState({ loadingPaymentFlux: false });
    });
  }

  cardSaveHandle = (cardForm) => {
    const {
      saveCreditCard,
      enqueueSnackbar,
    } = this.props;
    this.setState({ loadingPaymentFlux: true });
    saveCreditCard(cardForm).then((action) => {
      const isSuccess = action.type !== SAVE_CREDIT_CARD_ERROR;

      if (isSuccess) {
        enqueueSnackbar('snackbar.creditCard.create', notifierSuccess);
        this.paymentHandle();
      } else {
        enqueueSnackbar('snackbar.creditCard.create_error', notifierError);
        this.setState({ loadingPaymentFlux: false });
      }
    });
  }

  onSaveCreditCard = () => {
    const { creditCardInstallments, selectedCreditCardInstallment } = this.state;
    const { isCreditCardInstallmentsEnabled } = this.props;

    const hasSelectedCreditCardInstallment = creditCardInstallments.filter(installment => installment.gateway === selectedCreditCardInstallment).length > 0;

    const canSubmit = !isCreditCardInstallmentsEnabled ? true : hasSelectedCreditCardInstallment;

    this.setState({ canSubmit, hasValidCreditCardSelected: true });
    this.handletValidCreditCardSelectedStatus(true);
  }

  onFocus = () => {
    const { t } = this.props;
    this.setState({
      labelCvv: t('creditcard.label.cvvLong'),
    });
  }

  onBlur = (event) => {
    const { t } = this.props;
    if (!event.target.value) {
      this.setState({
        labelCvv: t('creditcard.label.cvvShort'),
      });
    }
  }

  handleChangeCreditCardInstallment = (event) => {
    this.setState({ selectedCreditCardInstallment: event.target.value });

    const {
      displayActionButton,
      onSelectCreditCardInstallment,
    } = this.props;

    const {
      hasValidCreditCardSelected, creditCardInstallments,
    } = this.state;
    const hasSelectedCreditCardInstallment = creditCardInstallments.filter(installment => installment.gateway === event.target.value).length > 0;

    this.setState({ canSubmit: hasSelectedCreditCardInstallment && hasValidCreditCardSelected });

    if (!displayActionButton && onSelectCreditCardInstallment) {
      onSelectCreditCardInstallment(event.target.value);
    }
  }

  handleOnOpenCreditCardInstallmentsTooltip = () => {
    this.setState({ showCreditCardInstallmentsTooltip: true });
  }

  handleOnCloseCreditCardInstallmentsTooltip = () => {
    this.setState({ showCreditCardInstallmentsTooltip: false });
  }

  async submit(event) {
    event.preventDefault();

    this.setState({ loadingPaymentFlux: true });

    await generateRecaptchaToken(config.RECAPTCHA_SITE_KEY, 'creditcardpayment').then((token) => {
      this.verifyCallback(token);
    });
    this.paymentHandle();
  }

  canSubmit() {
    let hasErrors = false;

    const {
      cardInfo,
      cardForm,
      isEnabled,
      creditCardInstallments,
      selectedCreditCardInstallment,
    } = this.state;

    const {
      ccnumber,
      ccvnumber,
      expdate,
      ccname,
    } = cardForm;

    const { isCreditCardInstallmentsEnabled } = this.props;

    const ccNumberLength = ccnumber.length;

    if (ccNumberLength < cardInfo.minLength || ccNumberLength > cardInfo.maxLength) {
      hasErrors = true;
    }

    if (ccname.length < cardInfo.minLengthName) {
      hasErrors = true;
    }

    if (expdate.replace(/_/g, '').length !== 5 || isDateLowerThanCurrent(expdate, 'MM/YY', null, isEnabled)) {
      hasErrors = true;
    }

    if (ccvnumber.length < cardInfo.codeSize - 1) {
      hasErrors = true;
    }

    if (isCreditCardInstallmentsEnabled && !creditCardInstallments.includes(selectedCreditCardInstallment)) {
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState({ canSubmit: false, hasValidCreditCardSelected: false });
    } else {
      this.setState({ canSubmit: true, hasValidCreditCardSelected: true });
    }
  }

  updateCreditCardValue(name, value) {
    if (name === 'ccvnumber') {
      this.setState({ focusedField: 'cvc' });
    } else {
      this.setState({ focusedField: 'number' });
    }

    const letters = /^[a-zA-Z\s]*$/;
    if (name === 'ccname' && (value.search('  ') > -1 || value.search(letters))) {
      this.canSubmit();
      return;
    }

    if (name === 'cctype') {
      this.setState(prevState => ({
        cardInfo: {
          ...prevState.cardInfo,
          ...getCreditCardTypeInfo(value),
        },
      }));
    }

    this.setState(prevState => ({
      cardForm: {
        ...prevState.cardForm,
        [name]: value.toLowerCase(),
      },
    }),
    () => this.canSubmit());
  }

  handleEvents(type, event) {
    const {
      removeCreditCard,
      creditCard,
      enqueueSnackbar,
    } = this.props;
    const { recaptchaToken } = this.state;

    switch (type) {
      case 'remove':
        removeCreditCard(recaptchaToken).then(() => {
          creditCard.error
            ? enqueueSnackbar('notifyFailMessage', notifierError)
            : enqueueSnackbar('notifySuccessMessage', notifierSuccess);
        });
        break;
      case 'save':
        this.submit(event);
        break;
      case 'changeInputValue':
        this.updateCreditCardValue(event.target.name, event.target.value);
        break;
      default:
        enqueueSnackbar('notifyFailMessage', notifierError);
        break;
    }
  }

  creditCardInstallmentsRender() {
    const {
      classes,
      t,
      showResumeData,
    } = this.props;
    const {
      creditCardInstallments,
      selectedCreditCardInstallment,
      showCreditCardInstallmentsTooltip,
    } = this.state;

    const mappedInstallments = creditCardInstallments.map((installment, index) => (
      <MenuItem
        // eslint-disable-next-line react/no-array-index-key
        key={`installment-${index}`}
        value={installment.gateway}
      >
        <Trans
          i18nKey="creditCardInstallmentLabel"
          values={{
            installments: installment.installments,
            installmentValue: formatCurrency(installment.installmentValue),
            fees: (installment.fees && installment.fees > 0 ? ` ${t('creditCardInstallmentFees')}` : ''),
            totalValue: index > 0 ? ` - ${formatCurrency(installment.totalValue)}` : '',
          }}
        />
      </MenuItem>
    ));

    return (
      <ClickAwayListener onClickAway={this.handleOnCloseCreditCardInstallmentsTooltip}>
        <div className={`${classes.creditCardInstallmentsQuestionAdapter}`}>
          <p className={`${showResumeData ? classes.creditCardInstallmentsQuestion : classes.newCreditCardInstallmentsQuestion}`}>{t('creditCardInstallmentsQuestion')}</p>
          <Tooltip
            interactive
            disableFocusListener
            open={showCreditCardInstallmentsTooltip}
            placement="right"
            title={(
              <Trans i18nKey="creditCardInstallmentsTooltipDescription">
                <>0</>
                <>1</>
                <a
                  className={`${classes.creditCardInstallmentsTooltipLink}`}
                  href={t('creditCardInstallmentsTooltipDescriptionLink')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  2
                </a>
              </Trans>
            )}
          >
            <div
              className={`${showResumeData ? classes.creditCardInstallmentsIconAdapter : classes.newCreditCardInstallmentsIconAdapter}`}
              onBlur={this.handleOnCloseCreditCardInstallmentsTooltip}
              onFocus={this.handleOnOpenCreditCardInstallmentsTooltip}
              onMouseOut={this.handleOnCloseCreditCardInstallmentsTooltip}
              onMouseOver={this.handleOnOpenCreditCardInstallmentsTooltip}
              role="button"
              tabIndex={0}
            >
              <IconInfo
                color={showResumeData ? '#4480c5' : '#2E93EE'}
                size={18}
                onClose={this.handleOnCloseCreditCardInstallmentsTooltip}
              />
            </div>
          </Tooltip>
        </div>
        <FormControl variant="outlined" className={`${classes.creditCardInstallmentsFormControl}`}>
          <InputLabel className={classes.creditCardInstallmentsInputLabelCustom} htmlFor="credit-card-installment-outlined-input">
            {t('selectCreditCardInstallments')}
          </InputLabel>
          <Select
            id="select_number_installments"
            value={selectedCreditCardInstallment}
            className={classes.creditCardInstallmentsSelectCustom}
            onChange={this.handleChangeCreditCardInstallment}
            input={(
              <OutlinedInput
                classes={{
                  root: classes.creditCardInstallmentsSelectCustom,
                  input: classes.creditCardInstallmentsSelectCustomInput,
                  notchedOutline: classes.creditCardInstallmentsSelectCustomOutline,
                  focused: classes.creditCardInstallmentsSelectCustomFocused,
                }}
                labelWidth={calcLabelWidth(t('selectCreditCardInstallments'))}
                id="credit-card-installment-outlined-input"
              />
            )}
          >
            {mappedInstallments}
          </Select>
        </FormControl>
      </ClickAwayListener>
    );
  }

  creditCardRender() {
    const {
      showResumeData,
    } = this.props;
    return (
      <CreditCardPaas isNewLayout={!showResumeData} isDialog onSave={this.onSaveCreditCard} gaEvent={this.cleanHgAcademyGAEvent} />
    );
  }

  render() {
    const {
      classes,
      fullScreen,
      onClose,
      creditCard,
      t,
      invoiceStatusMessage,
      showResumeData,
      displayActionButton,
      selectCardLabel,
      infoAddNewCreditCardLabel,
      isCreditCardInstallmentsEnabled,
      changeNextPaymentMethodLabel,
      showCreditCardCheckbox,
    } = this.props;

    const {
      canSubmit,
      cardType,
      existsCreditCard,
      hasValidCreditCardSelected,
      invoiceDetails,
      loadingCardCredit,
      loadingCreditCardInstallments,
      loadingPaymentFlux,
      saveNewCreditCard,
      showDetails,
      enabledChangeProductsPaymentMethodToCC,
    } = this.state;

    const getClassDefault = () => {
      if (!showResumeData) {
        return classes.newBorderDefault;
      }
      return classes.borderDefault;
    };

    const handleChangeProductsPaymentMethodToCC = (isChecked) => {
      this.setState({ enabledChangeProductsPaymentMethodToCC: isChecked });
    };

    const boxCard = saveNewCreditCard ? `${classes.borderActive}` : `${getClassDefault()}`;
    const boxForm = !saveNewCreditCard ? `${classes.borderActive}` : `${getClassDefault()}`;
    const newFormatCreditCardNumber = creditCard.cardLastFour && `**** ${(creditCard.cardLastFour).replace(/\*/g, '')}`;

    return (
      <Fragment>
        {loadingCreditCardInstallments ? (
          <DialogContent className={classes.creditCardInstallmentsLoadingDialog} style={{ textAlign: 'center' }}>
            <CircularProgress size={42} />
            <p className={classes.creditCardInstallmentsLoadingDialogText}>
              {t('processingSelectedPaymentPleaseWait')}
            </p>
          </DialogContent>
        ) : (
          <>
            {showResumeData && (
            <DialogTitle id="responsive-dialog-title">
              <IconButton aria-label="Fechar" onClick={onClose} className={classes.closeButton}>
                <Close />
              </IconButton>
              {t('dialog.title')}
            </DialogTitle>
            )}
            <DialogContent className={showResumeData ? '' : classes.newDialogContent}>
              {showResumeData && (

              <Grid item xs={12}>
                <WarningNotice message={t('dialog.message')} fill="100%" />
                <InvoiceSummaryCard status={invoiceStatusMessage}>
                  <CardContent className={classes.cardSummaryContent}>
                    <Grid container spacing={8} className={classes.invoiceCardBox}>
                      <div className={classes.cardFlexBox}>
                        <Receipt className="receiptIcon" />
                        <Typography>{t('dialog.invoiceDetails')}</Typography>
                      </div>
                      <CardActions>
                        <IconButton
                          className={classnames(classes.expand, {
                            [classes.expandOpen]: showDetails,
                          })}
                          onClick={this.handleExpand}
                        >
                          <ExpandMore className={classes.arrow} />
                        </IconButton>
                      </CardActions>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Collapse in={showDetails} timeout="auto">
                          {invoiceDetails.id ? (
                            <CardContent>
                              <InvoiceDetails invoice={invoiceDetails} />
                            </CardContent>
                          ) : (
                            <Loader />
                          )}
                        </Collapse>
                      </Grid>
                    </Grid>
                  </CardContent>
                </InvoiceSummaryCard>
              </Grid>
              )}
              {!loadingCardCredit && <Loader />}
              {loadingCardCredit && existsCreditCard && (
              <DialogContentText className={showResumeData ? classes.container : classes.containerLeft} align="center">
                <strong>
                  {showResumeData && (t('dialog.subtitle'))}
                  {!showResumeData && (selectCardLabel)}
                </strong>
              </DialogContentText>
              )}
              {creditCard.errors && <Typography color="secondary">{t('errorSavingCreditCard')}</Typography>}

              {loadingCardCredit && (
              <Grid container spacing={24}>
                {existsCreditCard && (
                <Grid item xs={12} md={showResumeData ? 6 : 12} className={classes.creditCard}>
                  <div className={`${boxCard} ${showResumeData ? classes.cardSize : classes.cardSizeNewLayout}`}>
                    <FormControlLabel
                      value={`${cardType}`}
                      label={
                        showResumeData
                          ? t('useExistingCard') : <Typography className={saveNewCreditCard ? classes.useExistingCardSelected : classes.useExistingCard}>{t('useExistingCard')}</Typography>}
                      control={(
                        <Radio
                          checked={saveNewCreditCard}
                          onChange={this.handleChangeCreditCardSelected}
                          name="radio-button-demos"
                          color="primary"
                          disabled={!creditCard.cardType}
                        />
                  )}
                    />
                    {(!saveNewCreditCard || creditCard.cardType) && (
                    <>
                      {showResumeData && (
                      <div className={classes.container}>
                        <CreditCardPreview
                          creditCard={creditCard}
                          hideButtons
                          dialogTemplate
                          newFormatCreditCardNumber={newFormatCreditCardNumber}
                          onRemoveCreditCard={() => this.handleEvents('remove')}
                        />

                      </div>
                      )}
                      {!showResumeData && (
                      <div className={classes.containerNewLayout}>
                        <CreditCardPreviewNewVersion
                          creditCard={creditCard}
                          hideButtons
                          dialogTemplate
                          newFormatCreditCardNumber={newFormatCreditCardNumber}
                          onRemoveCreditCard={() => this.handleEvents('remove')}
                          creditCardExpDateLabel={t('creditCardExpDate')}
                        />
                      </div>
                      )}
                    </>
                    )}
                  </div>
                </Grid>
                )}

                <Grid
                  item
                  className={classes.creditCard}
                  xs={12}
                  md={this.getMdGridAddNewCard(existsCreditCard, showResumeData)}
                >
                  <div
                    className={`${boxForm} ${!existsCreditCard
                      ? classes.cardSizeFormFull
                      : classes.cardSizeForm}`}
                  >
                    <FormControlLabel
                      value={`${!cardType}`}
                      label={
                      showResumeData
                        ? t('dialogRegisterAndUseNewCard')
                        : <Typography className={classes.useExistingCardSelected}>{t('dialogRegisterAndUseNewCard')}</Typography>}
                      control={(
                        <Radio
                          checked={!saveNewCreditCard}
                          onChange={this.handleChangeCreditCardSelected}
                          name="radio-button-demo"
                          color="primary"
                        />
                  )}
                    />
                    {!showResumeData && (
                      <Typography className={[!creditCard.loading ? classes.paddingCardFormEmpty : '', classes.newCardFormCaption]} variant="caption">{infoAddNewCreditCardLabel}</Typography>
                    )}
                    {showResumeData && (
                      <Typography variant="caption">
                        {t('dialog.formCaption')}
                      </Typography>
                    )}
                    {(!saveNewCreditCard
                  || (!creditCard.loading
                  && !creditCard.cardType)) && (
                  <div
                    className={`${showResumeData ? classes.container : classes.containerAddNewCard} ${classes.handleCardForm} ${fullScreen && classes.cardSizeFormFull}`}
                  >
                    {this.creditCardRender()}
                  </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              )}

              {isCreditCardInstallmentsEnabled && this.creditCardInstallmentsRender()}
              {showCreditCardCheckbox && (
                <CheckedDefaultCreditCardPayment isChecked={enabledChangeProductsPaymentMethodToCC} description={changeNextPaymentMethodLabel} onClickAction={handleChangeProductsPaymentMethodToCC} />
              )}
            </DialogContent>

            {showResumeData && (
            <DialogActions className={classes.dialogActions}>
              <Button
                data-id="dialog-button-pay-invoice"
                className={classes.btnPrimary}
                variant="contained"
                color="primary"
                autoFocus
                onClick={e => this.handleEvents('save', e)}
                disabled={!canSubmit || !hasValidCreditCardSelected}
              >
                {t('payInvoiceAction')}
              </Button>
            </DialogActions>

            )}
            {!showResumeData && displayActionButton && (
            <DialogActions className={classes.newDialogActions}>
              <ButtonV1
                label={t('dialog.title')}
                onClick={e => this.handleEvents('save', e)}
                disabled={!canSubmit || !hasValidCreditCardSelected}
              />
            </DialogActions>

            )}

            {loadingPaymentFlux && <Loader fullscreen positionFixed={!showResumeData} />}
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard,
  paymentMethod: state.paymentMethod,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadInvoices,
    loadCreditCard,
    removeCreditCard,
    saveCreditCard,
    getPaymentMethod,
    getCreditCardInstallments,
    getCreditCardInstallmentsByProductData,
    loadInvoiceDetails,
    updateStatusInvoiceToConfirmingPayment,
    enqueueSnackbar,
  },
  dispatch,
);

CreditCardDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  creditCard: creditCardType.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  loadCreditCard: PropTypes.func.isRequired,
  onPaymentExecuted: PropTypes.func,
  removeCreditCard: PropTypes.func.isRequired,
  saveCreditCard: PropTypes.func.isRequired,
  getPaymentMethod: PropTypes.func.isRequired,
  getCreditCardInstallments: PropTypes.func.isRequired,
  getCreditCardInstallmentsByProductData: PropTypes.func.isRequired,
  invoiceId: PropTypes.number.isRequired,
  isCreditCardInstallmentsEnabled: PropTypes.bool,
  showResumeData: PropTypes.bool,
  displayActionButton: PropTypes.bool,
  onSelectCreditCardInstallment: PropTypes.func,
  onSetCreditCardSelected: PropTypes.func,
  selectCardLabel: PropTypes.string,
  infoAddNewCreditCardLabel: PropTypes.string,
  changeNextPaymentMethodLabel: PropTypes.string,
  showCreditCardCheckbox: PropTypes.bool,
  callingOrigin: PropTypes.string,
  subscriptionAnalytics: PropTypes.any,
  isExceedsRetriesCC: PropTypes.bool,
  providerIdentifier: PropTypes.string,
  classes: PropTypes.shape({
    btnOutline: PropTypes.string,
    closeButton: PropTypes.string,
    creditCard: PropTypes.string,
    borderDefault: PropTypes.string,
    borderActive: PropTypes.string,
    cardSize: PropTypes.string,
    cardSizeForm: PropTypes.string,
    cardSizeFormFull: PropTypes.string,
    cardFormCaption: PropTypes.string,
    container: PropTypes.string,
    dialogActions: PropTypes.string,
    cardFlexBox: PropTypes.string,
    invoiceCardBox: PropTypes.string,
    cardSummary: PropTypes.string,
    cardSummaryContent: PropTypes.string,
    receiptIcon: PropTypes.string,
    expand: PropTypes.string,
    expandOpen: PropTypes.string,
    dialogContainer: PropTypes.string,
    creditCardFormCustomRegister: PropTypes.string,
    creditCardFormCustomReplace: PropTypes.string,
  }).isRequired,
};


export default withStyles(styles, { withTheme: true })(
  withI18n()(connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(CreditCardDialog))),
);
