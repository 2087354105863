import React from 'react';
import classnames from 'classnames';
import { Warning } from '@material-ui/icons';
import { Typography, withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import styles from './styles';


const WarningNotice = ({
  classes,
  message,
  fill,
  width,
  border,
  className,
  phishingCheck,
  icon,
  contentClassName,
  radius,
}) => {
  const isMobile = isWidthDown('sm', width);

  return (
    <div style={{ width: !isMobile ? fill : '100%' }}>
      <div
        style={{ borderRadius: radius || 0 }}
        className={classnames(classes.cardWarningBox, border, 'warningStatusColor', contentClassName, {
          [classes.statusCancelled]: border === 'Cancelled' || border === 'cancelled',
          [classes.statusTerminated]: border === 'Terminated' || border === 'terminated',
          [classes.statusExpired]: border === 'Expired' || border === 'expired',
          [classes.statusSuspended]: border === 'Suspended' || border === 'suspended',
          [classes.statusInAnalysis]: border === 'in_analysis',
          [classes.statusOnConfigure]: border === 'on_configure',
          [classes.paymentPending]: phishingCheck,
        })}
      >
        {icon || <Warning />}
        <Typography className={`${classes.cardWarningText} ${className}`} variant="caption">{message}</Typography>
      </div>
    </div>
  );
};

export default withWidth()(withStyles(styles)(withI18n()(WarningNotice)));
