import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import { IconError } from '@/icons';
import * as Styles from './HostGatorCustomEmailModal.styles';
import { color } from '@/styles/HgThemeProvider';
import { Button } from '@/pages/common/v1/Button';

const HostGatorCustomEmailModal = ({
  testId = 'customdnszonemodal',
  emailModal = false,
  handleClose = () => {},
  handleConfigureEmail = () => {},
  domainName,
  firstTimeConfigure = false,
  modalsLocale = {},
  propagationHours = '??',
  platform = {
    dnsDefaultEntries: [],
    platformID: 'uuid',
    platformName: '',
  },
  analytics = {},
  reserved = false,
}) => {
  const { platformID: platformId } = platform;
  const displayCheckbox = !firstTimeConfigure && (platform.platformName === '' && reserved === true) === false;

  const {
    title,
    description,
    button: configureButtonLabel,
    checkbox: checkboxText,
    alertMessage,
    moreInfo,
  } = modalsLocale;

  const {
    dnsWizardAlterEmailCustomCloseClick,
    dnsWizardAlterEmailCustomCheckbox,
    dnsWizardAlterEmailCustomHelpLink,
    dnsWizardAlterEmailCustomConfigureButton,
  } = analytics;

  const { mustard } = color;

  const [checked, setChecked] = useState(false);

  const buttonIsDisabled = !firstTimeConfigure && !checked && !reserved;
  const handleChange = () => {
    dnsWizardAlterEmailCustomCheckbox();
    setChecked(!checked);
  };

  const handleSubmit = () => {
    handleConfigureEmail(platformId);
    dnsWizardAlterEmailCustomConfigureButton();
    handleClose();
    setChecked(false);
  };

  const handleCloseModal = () => {
    dnsWizardAlterEmailCustomCloseClick();
    setChecked(false);
    handleClose();
  };

  return (
    <>
      {emailModal
      && (
        <Modal onClose={handleCloseModal} maxWidth={780}>
          <Styles.HostGatorOtherPlatformModalWrapper data-testid={testId}>
            <Styles.TitleWrapper data-testid="customdnszonemodal-title">
              <Styles.Title>{title}</Styles.Title>
            </Styles.TitleWrapper>
            <Styles.Description>
              {description(domainName)}
              <p>{moreInfo(dnsWizardAlterEmailCustomHelpLink)}</p>
            </Styles.Description>
            <Styles.MessageWrapper data-testid="customdnszonemodal-message">
              <IconError size="24" color={mustard} />
              {alertMessage(propagationHours, dnsWizardAlterEmailCustomHelpLink)}
            </Styles.MessageWrapper>
            {displayCheckbox && (
            <Styles.CheckboxWrapper>
              <input type="checkbox" checked={checked} onChange={handleChange} data-testid="checkbox" />
              <div>
                {checkboxText}
              </div>
            </Styles.CheckboxWrapper>
            )}
            <Styles.ButtonWrapper>
              <Button disabled={buttonIsDisabled} label={configureButtonLabel} onClick={handleSubmit} />
            </Styles.ButtonWrapper>
          </Styles.HostGatorOtherPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorCustomEmailModal;
