import React from 'react';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import SelectField from '@/components/Fields/SelectField';
import { locale } from '@/utils/locale';
import * as Styles from './PaymentCycle.styles';

const PaymentCycle = ({
  paymentCycle, setPaymentCycle, cycleOptions, disabled,
}) => (
  <Styles.PaymentCycleWrapper data-testid="paymentCycleBlock">
    <SelectField
      disabled={disabled}
      inputLabel={locale('proEmail.hirePlan.paymentCycle')}
      labelWidth={calcLabelWidth(locale('proEmail.hirePlan.paymentCycle'))}
      filterOptions={cycleOptions}
      selectValue={paymentCycle}
      selectType="paymentCycle"
      menuItemDefaultText="hidden"
      handleChangeSelectValue={e => setPaymentCycle(e.target.value)}
      selectTestId="selectButton"
      selectInputTestId="selectField"
    />
  </Styles.PaymentCycleWrapper>
);

export default PaymentCycle;
