import styled, { css } from 'styled-components';
import colorfulLine from '@/media/subscriptions/retention/border.svg';
import starBackground from '@/media/subscriptions/retention/starBackground.svg';

const ContentPadding = '0px 85px 24px 32px';


export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    height: 100%;
    margin: 0px 24px 0px 24px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px;
    }
  `}
`;

export const ContentStar = styled.div`
      display: flex;
      flex-direction: row-reverse;
`;

export const StarImg = styled.img`
  ${({ theme }) => css`
    background-image: url(${starBackground});
    max-width: 209px;
    margin-bottom: 10px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      max-width: 100%;
      margin-bottom: 0;
      padding-right: 0;
    }
  `}
`;

export const Content = styled.div`

`;

export const BenefitsWrapper = styled.div`
      display: flex;
      justify-content: center;
      padding: 0 40px;
`;

export const BenefitsContent = styled.div`
  ${({ theme, version }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    gap: 10px;
    justify-content: center;
    line-height: 22px;
    padding-top: 28px;
    width: ${version === 0 ? '100%' : '440px'};

    @media (min-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: row;
      gap: 30px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme, version }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin: ${version === 0 ? '0px' : '0px 0px 8px 0px'};
  text-align: left;

  > p {
    margin: 0px;
    > span {
        font-weight: ${theme.v2.font.weights.medium};
      }
    }

  `}
`;

export const BenefitDescription = styled.p`
  ${({ theme, version }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 24px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin-top: ${version === 0 ? '8px' : '16px'};
  text-align: left;
  width: ${version === 0 ? '100%' : '324px'};
   > strong {
    color: ${theme.v2.colors.feedback.activePure};
    font-weight: ${theme.v2.font.weights.bold};

    }
  `}
`;

export const OfferDescription = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.medium};
  line-height: normal;
  margin: 0px;
  text-align: left;
  width: 268px;
  > p {
    margin: 0px
    ;
  }

  `}
`;

export const ControlWrapper = styled.div`
  margin-top: 24px;
    > button {
      height: 48px;
    }
`;

export const SnappyImg = styled.img`
  ${({ theme }) => css`
    max-width: 209px;
    margin-bottom: 10px;
    padding-right: 12px;
    @media (min-width: ${theme.v2.breakpoints.sm}) {
      max-width: 100%;
      margin-bottom: 0;
      padding-right: 0;
    }
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: ${theme.v2.font.weights.regular};
  line-height: normal;
  margin-top: 24px;
  text-align: left;
  `}
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    justify-content: right;
    margin: 111px 40px 0px 40px;
    padding: 0 40px;
    padding: 38px 0px;
    > button {
      color: #0070D1;
      font-size: 16px;
      font-style: normal;
      font-weight: ${theme.v2.font.weights.medium};
      gap: 3px;
      line-height: normal;
      text-align: center;
      text-decoration: auto;
      > svg {
        color: #0070D1;
        height: 24px;
        width: 24px;
      }
      }
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    background-image: url(${colorfulLine});
    margin-top: 95px;
    max-height: 322px;
    max-width:  494px;
    padding: ${ContentPadding};
    > img {
      position: relative;
      bottom: 166px;
      left: 356px;
   }

   @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-top: 18px;
      max-width: 360px;
      max-height: 322px;
      padding: 0px 8px 14px 24px;
    }
  `}
`;
