import moment from 'moment';

export const formatSecondsToMinutes = (time) => {
  const minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;
  seconds = String(seconds).length > 1 ? seconds : `0${seconds}`;
  return `${minutes}:${seconds}`;
};

const enhancedFormatSecondsToHours = time => Math.floor(time / (60 * 60));
const oldFormatSecondsToHours = time => Number(moment.duration(Number(time), 'seconds').hours());

export const formatSecondsToHours = (time, isEnabled) => (isEnabled ? enhancedFormatSecondsToHours(time) : oldFormatSecondsToHours(time));
