import React from 'react';
import { locale } from '@/utils/locale';

export const SecurityCtaLocale = {
  title:
  <>
    <span>{locale('sitelockLP.pageTitle.0')}</span>
    {locale('sitelockLP.pageTitle.1')}
  </>,
  description: locale('sitelockLP.description'),
  listTitle:
  <>
    {locale('sitelockLP.listTitle.0')}
    <b>{locale('sitelockLP.listTitle.1')}</b>
    {locale('sitelockLP.listTitle.2')}
  </>,
  featureList: {
    firstColumn: [
      locale('sitelockLP.featureList.1'),
      locale('sitelockLP.featureList.2'),
      locale('sitelockLP.featureList.3'),
      locale('sitelockLP.featureList.4'),
    ],
    secondColumn: [
      locale('sitelockLP.featureList.5'),
      locale('sitelockLP.featureList.6'),
      locale('sitelockLP.featureList.7'),
      locale('sitelockLP.featureList.8'),
    ],
  },
  formTitle: locale('sitelockLP.formTitle'),
  formDescription: locale('sitelockLP.formDescription'),
  formFirstToggleLabel: locale('sitelockLP.formFirstToggleLabel'),
  formSecondToggleLabel: locale('sitelockLP.formSecondToggleLabel'),
  formSecondTogglePlaceholder: locale('sitelockLP.formSecondTogglePlaceholder'),
  selectePaymentLabel: locale('sitelockLP.selectePaymentLabel'),
  cycleLabel: locale('sitelockLP.cycleLabel'),
  cycleOptions: {
    annually: locale('sitelockLP.cycleOptions.annually'),
    semiannually: locale('sitelockLP.cycleOptions.semiannually'),
    quarterly: locale('sitelockLP.cycleOptions.quarterly'),
    monthly: locale('sitelockLP.cycleOptions.monthly'),
  },
  cyclePer: {
    annually: locale('sitelockLP.cyclePer.annually'),
    semiannually: locale('sitelockLP.cyclePer.semiannually'),
    quarterly: locale('sitelockLP.cyclePer.quarterly'),
    monthly: locale('sitelockLP.cyclePer.monthly'),
  },
  toBePaid: locale('sitelockLP.toBePaid'),
  hgSiteLock: locale('sitelockLP.hgSiteLock'),
  needMoreLabel: locale('sitelockLP.needMoreLabel'),
  needMoreButtonText: locale('sitelockLP.needMoreButtonText'),
  detailsSectionTitle: locale('sitelockLP.detailsSectionTitle'),
  detailsSectionFirstSectionTitle: locale('sitelockLP.detailsSectionFirstSectionTitle'),
  detailsSectionFirstSectionDescription: locale('sitelockLP.detailsSectionFirstSectionDescription'),
  detailsSectionFirstSectionButtonLabel: locale('sitelockLP.detailsSectionFirstSectionButtonLabel'),
  detailsSectionSecondSectionTitle: locale('sitelockLP.detailsSectionSecondSectionTitle'),
  detailsSectionSecondSectionDescription: locale('sitelockLP.detailsSectionSecondSectionDescription'),
  detailsSectionSecondSectionButtonLabel: locale('sitelockLP.detailsSectionSecondSectionButtonLabel'),
  discoverBenefitsTitle: locale('sitelockLP.discoveryTitle'),
  discoverBenefitsBenefits: locale('sitelockLP.benefits'),
  discoverBenefitsCtaTitle: locale('sitelockLP.ctaTitle'),
  discoverBenefitsCtaDescription: locale('sitelockLP.ctaDescription'),
  discoverBenefitsButtonLabel: locale('sitelockLP.buttonLabel'),
  bannerTitle: locale('sitelockLP.bannerTitle'),
  bannerLabel: locale('sitelockLP.bannerLabel'),
};
