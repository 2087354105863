import React from 'react';
import Link from '@/pages/common/Link/Link';
import { Button, Link as NewLink } from 'gatorcomponents';
import {
  DNS_WIZARD_HELP_URL,
  DNS_WIZARD_PROPAGATION,
  DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION,
  DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM,
  DNS_WIZARD_EMAIL_HELP,
  DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL,
  HOW_TO_TRANSFER_A_DOMAIN,
  HOW_TO_CREATE_OR_ALTER_DNS_REGISTER,
  REGISTER_DOMAIN_URL,
} from '@/config/urls/domainsUrls';
import { ERROR_MESSAGES, PLATFORM_NAMES, EMAIL_PLATFORM_NAMES } from '@/enums/domains/dns.enum';
import {
  CONFIGURE_DOMAIN_DNS_ARTICLE,
  DEFAULT_SERVER, DNS_PROPAGATION_ARTICLE, EXTERNAL_PLATFORM, GMAIL_CREATE_ACCOUNT, PERSONAL_DNS,
} from '@/config/urls/supportUrls';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import analytics from '@/analytics/domains/domains.analytics';

export default {
  // TODO: Review all the links below and refactor them into JSX and the new locale styles
  domainStatusTitle: 'Status do Domínio',
  domainExpirationDate: 'Data de Suspensão',
  emailConfirmation: 'Confirmar E-mail',
  domainModalSubtitle: '<0>Você possui alguns sites suspensos ou prestes a serem suspensos por estarem com a confirmação de e-mail pendente.</0> Confirme-os abaixo para reativar ou evitar que sejam suspensos.',
  verificationStatusPending: 'Aguardando Confirmação',
  verificationStatusSuspended: 'Suspenso Temporariamente',
  notifications: {
    activateRenewal: {
      success: {
        0: 'A renovação do domínio ',
        1: ' foi ativada',
      },
      failure: {
        0: 'O cancelamento da renovação do domínio ',
        1: ' não pôde ser concluído. Por favor, tente novamente mais tarde.',
      },
    },
    generateNewInvoice: {
      failure: {
        0: 'Não foi possível gerar uma fatura neste momento. Aguarde uns instantes e tente novamente.',
      },
    },
  },

  // New Locales
  dnsWizard: {
    reinforcment: {
      noHostGatorPlan: {
        title: 'Nenhum plano de hospedagem com espaço foi encontrado',
      },
      aditionalPlanOffer: {
        title: 'Você ainda não possui um plano de hospedagem da HostGator',
      },
      dLojaExplanation: {
        title: 'Você está iniciando a configuração de DNS para a Loja HostGator.',
        description: (
          <>
            <strong>Anteriormente conhecida como DLoja Virtual</strong>
            {' , a Loja HostGator é a plataforma de e-commerce da HostGator, contando com tudo que você precisa para criar sua loja e vender muito.'}
            <br />
            Na Loja HostGator você irá administrar a sua loja online, cadastrar produtos e categorias, acompanhar pedidos e estoque, gerar relatórios, entre outros gerenciamentos.
          </>
        ),
        alert: (
          <>
            <strong>Lembrando</strong>
            {': As configurações de DNS da Loja HostGator são diferentes do seu plano de hospedagem da HostGator. Depois de confirmada a alteração, a criação de site deverá ser feita na '}
            <Link
              href="https://www.hostgator.com.br/loja-hostgator"
              text="Loja Hostgator"
              target="_blank"
            />
            . Registros na Zona de DNS, apontamento de e-mail e outras configurações continuarão sendo feitas na HostGator.
          </>
        ),
        checkbox: 'Estou ciente das diferenças entre a hospedagem HostGator e a Loja HostGator',
        cta: 'Continuar para configuração de domínio',
      },
    },
    bannerChangeOrNewPlanText: 'Seu plano de hospedagem atual na HostGator suporta apenas uma configuração de domínio. Altere sua assinatura para um plano maior ou compre outro plano.',
    bannerChangeOrNewPlanButtonNewPlan: 'Comprar outro plano',
    bannerChangeOrNewPlanButtonUpgradePlan: 'Alterar plano de hospedagem',

    bannerNewPlanButton: 'Ver planos',
    bannerNewPlanText: 'Centralize seu negócio com a gente. Assine agora um plano de hospedagem da Hostgator!',

    title: 'Configurar Domínio',
    selectedDomain: 'Domínio selecionado:',
    chooseYourPlatformTitle: 'Escolha a plataforma de site:',
    chooseYourPlatformTooltip: 'Plataforma de site é o local onde o site ficará hospedado e/ou será criado.',
    domainConfiguredTitle: 'Domínio está configurado na plataforma de site:',
    dnsZoneHeaderTitle: 'Fazer configuração avançada na Zona de DNS',
    dnsZoneHeaderMessage: () => (
      <>
        A Zona de DNS possibilita ações como direcionar site, subdomínio, e-mail, etc. Para mais informações
        {' '}
        <NewLink
          href={HOW_TO_CREATE_OR_ALTER_DNS_REGISTER}
          target="_blank"
          rel="noreferrer"
          text="acesse a nossa Central de Ajuda."
        />
      </>
    ),
    domainConfiguredTooltip: 'Plataforma de site é o local onde o site ficará hospedado e/ou será criado.',
    pageLoadingMessage: 'Carregando plataformas de sites...',
    configurationLoadingMessage: 'Carregando configuração do domínio...',
    dnsZoneOpenButtonText: 'Mostrar configuração manual da Zona de DNS',
    dnsZoneCloseButtonText: 'Fechar Zona de DNS',
    serverText: 'Servidor',
    planStatusCancelled: 'Seu domínio está configurado para um plano de hospedagem cancelado. Configure agora mesmo seu domínio para um plano ou plataforma de hospedagem clicando em "Alterar Plataforma".',
    emails: {
      emailZoneTitle: 'Configurar plataforma de e-mail do domínio',
      emailZoneTitleWithDefinedPlatform: 'Configurar plataforma de e-mail do domínio',
      propagationMessage: dotBr => (
        <>
          Após configurar, pode levar até
          {' '}
          {dotBr ? 24 : 72}
          {' '}
          horas para que as informações sejam
          {' '}
          <a href={DNS_WIZARD_PROPAGATION} target="_blank" rel="noreferrer">propagadas</a>
          , portanto só será possível utilizar o e-mail após esse período e se houver uma conta de e-mail criada.
        </>
      ),
      emailZoneTitleTooltip: 'Aqui você realiza apenas a configuração de e-mail do domínio (apontamento de DNS), para que ele funcione você precisa ter um conta de e-mail criada na plataforma escolhida.',
      emailZoneSubtitle: 'Você pode configurar para as plataformas de e-mail da HostGator, Gmail ou outra que desejar.',
      changePlatformText: 'Alterar plataforma',
      planStatusCancelled: 'Seu domínio está configurado para um plano de hospedagem cancelado. Configure agora mesmo seu domínio para um plano ou plataforma de hospedagem clicando em "Alterar Plataforma".',
      customEmailPlatformName: 'Outra Plataforma de e-mail',
      emailZoneContentText: (platformType) => {
        if (platformType === EMAIL_PLATFORM_NAMES.HOSTGATOR) {
          return (
            <>
              Plataformas de e-mail
              <br />
              <b>HostGator</b>
            </>
          );
        }
        if (platformType === EMAIL_PLATFORM_NAMES.GMAIL) {
          return 'Gmail';
        }
        if (platformType === EMAIL_PLATFORM_NAMES.CUSTOM) {
          return 'Outra Plataforma de e-mail';
        }
      },
    },
    dnsZoneTitle:
  <>
    {'Agora '}
    <span>você já pode fazer os registros de Zona de DNS</span>
    {' no fim da página!'}
  </>,
    dnsZoneAlertTitle: 'Configuração do domínio realizada com sucesso',
    dnsZoneAlertDescription: 'Agora você pode seguir com as instruções abaixo.',
    successTitle: (domainName, domainElipsis) => (
      <>
        {'Seu domínio '}
        <span className="full">{domainName}</span>
        <span className="ellipsis">{domainElipsis}</span>
        {' foi configurado com sucesso!'}
      </>
    ),
    successMessage: (propagationTime = 24) => (
      `Aguarde o período de propagação de até ${propagationTime} horas para que seu site possa estar no ar.`
    ),
    configCardAttentionMessage: (propagationTime = 24, shouldRenderOtherPlatform, helpClickGa = () => { }) => (
      <>
        {`Pode levar até ${propagationTime} horas para o domínio estar na nova plataforma (período de propagação do DNS). Seu site poderá ficar online após esse período.`}
        <br />
        Caso queira acompanhar o status da configuração,
        {' '}
        <Link href={DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION} onClick={helpClickGa} inlineLink target="_blank" text="veja como verificar se a propagação do DNS foi concluída." />
      </>
    ),

    resellerMessage:
  <p>
    <span>
      <strong>{'Importante: '}</strong>
      Utilize o painel WHM para gerenciar a revenda, e o painel cPanel para gerenciar as contas dos clientes.
    </span>
  </p>,
    otherPlatformAttentionMessage:
  <>
    <span>
      <b>
        Importante
      </b>
      :
      A criação de site, registros na Zona de DNS, apontamentos de e-mail e outras configurações, deverão ser feitas no outro provedor. Caso o domínio tenha sido configurado para um servidor da HostGator, como VPS e servidor dedicado, os registros de Zona de DNS devem ser gerenciado pelo painel Plesk (VPS ou servidor dedicado Windows) ou painel WHM (servidor dedicado Linux).
    </span>
  </>,
    externalPlatformAttentionMessage: platformName => (
      <>
        <span>
          <b>
            Importante:
          </b>
          {` A criação de site, registros na Zona de DNS, apontamentos de e-mail e outras configurações, deverão ser feitas na ${platformName}.`}
        </span>
      </>
    ),
    otherPlatformCardTitle: 'Outra plataforma de hospedagem',
    changePlatformText: 'Alterar plataforma',
    editAdvancedDNSZone: 'Editar Zona Avançada de DNS',
    noPlanSelectedMessage: 'Servidores de DNS (Name Servers)',
    principalDomainMessage: domainSelected => (
      <>
        {domainSelected ? `Domínio principal: ${domainSelected}` : ''}
      </>
    ),
    chooseYourPlatformText: (helpClickGa = () => { }) => (
      <>
        <p>Selecione uma das plataforma abaixo para configurar automaticamente o domínio (alterar DNS).</p>
        <p>
          Caso não seja cliente de nenhuma delas, configure manualmente na opção outra plataforma de hospedagem ou faça uma configuração avançada. Se necessário,
          {' '}
          <Link href={DNS_WIZARD_HELP_URL} onClick={helpClickGa} target="_blank" text="veja como configurar." inlineLink />
        </p>
      </>
    ),
    advancedConfiguration:
  <>
    <span>Ir para configuração avançada</span>
    {' '}
    <span>(Zona de DNS)</span>
  </>,
    otherPlatformCardText: (
      <>
        Outra plataforma de
        <br />
        hospedagem
      </>
    ),
    reservedPlatform: (
      <>
        Sem hospedagem
        <br />
        (apenas Zona de DNS)
      </>
    ),
    noPlatformSelectedMessage: () => (
      <>
        {'Altere a zona de DNS para configurar seu domínio para a plataformas de site, e-mails, dentre outros. '}
        <Link href={DEFAULT_SERVER} target="_blank" text="Veja mais." inlineLink />
      </>
    ),
    domainNotConfiguredMessage: () => (
      <>
        Seu domínio está com a gente, porém ele
        {' '}
        <strong>ainda não está configurado</strong>
        {' '}
        para um plano de hospedagem.
        <Link href={CONFIGURE_DOMAIN_DNS_ARTICLE} target="_blank" text="Veja como configurar o seu domínio." inlineLink />
      </>
    ),
    tooltip: 'Plataforma de site é o local onde o site ficará hospedado e/ou será criado.',
    configCardTooltip: platformName => (`Seu domínio está apontado para ${PLATFORM_NAMES[platformName]}, portanto a criação do seu site deve ser realizada nessa plataforma. As configurações na Zona de DNS continuam sendo feitas aqui na HostGator.`),
    access: 'Acessar',
    modals: {
      hostgator: {
        title: 'Em qual plano ou servidor você deseja configurar o domínio?',
        subtitle: 'Selecione abaixo em qual plano de hospedagem o domínio ficará hospedado.',
        tooltip: 'Você pode selecionar qualquer plano listado aqui. É necessário fazer essa configuração para que o domínio tenha um servidor onde suas informações ficarão armazenadas.',
        checkbox: 'Estou ciente que as atuais configurações do domínio serão perdidas ao alterar o local de hospedagem do domínio.',
        dnsConfigText: 'Ir para configuração avançada (Zona de DNS)',
        button: 'Configurar',
        domain: 'domínio',
        domains: 'domínios',
        mainDomain: 'Domínio principal:',
        otherDomains: 'Outros domínios:',
        alertMessage: (propagationTime = 24, helpClickGa = () => { }) => (
          <p>
            Após configurar, pode levar até
            {' '}
            {propagationTime}
            {' '}
            horas para que as informações sejam
            {' '}
            <Link href={DNS_WIZARD_PROPAGATION} onClick={helpClickGa} target="_blank" text="propagadas" inlineLink />
            {' '}
            no plano de hospedagem escolhido. Seu site poderá ficar online após esse período.
          </p>
        ),
      },
      custom_dns_zone: {
        title: type => `Configurar para ${PLATFORM_NAMES[type]}`,
        subtitle: type => `Não se preocupe, faremos toda a configuração para você. Serão apontados os registros padrões da ${PLATFORM_NAMES[type]} na Zona de DNS do domínio. Caso deseje, confira abaixo.`,
        configureButtonLabel: 'Configurar',
        showButtonLabel: 'Mostrar registros padrões',
        hideButtonLabel: 'Fechar registros padrões',
        tableHeaders: {
          type: 'Tipo',
          value: 'Valor',
          name: 'Nome',
        },
        checkboxText: 'Estou ciente que as atuais configurações do domínio serão perdidas ao alterar a plataforma de site do domínio.',
        alertMessage: (propagationTime = 24, platformName = '', helpClickGa = () => {}) => (
          <p>
            {`Após configurar, pode levar até ${propagationTime} horas para que as informações sejam `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' no plano de hospedagem escolhido. Seu site poderá ficar online após esse período.'}
            <span>
              <b>Importante:</b>
              {` Depois de confirmada essa ação, a criação de site deverá ser feita na ${platformName}. Registros na Zona de DNS, apontamento de e-mail e outras configurações serão feitas na HostGator.`}
            </span>
          </p>
        ),
      },
      custom: {
        title: 'Outra plataforma de hospedagem',
        serverWithNumber: ({ serverNumber = 0, required = false }) => (`Servidor ${serverNumber}${required ? '*' : ''}:`),
        nsWithNumber: number => (`ns${number}.seuservidor.com`),
        ipWithNumber: number => (
          <>
            {'Endereço IP* '}
            <small>{`(servidor ${number}):`}</small>
          </>
        ),
        ipPlaceholder: 'digite endereço IP',
        addServer: 'Adicionar mais um servidor',
        button: 'Configurar',
        subtitle: 'Indique abaixo os servidores padrões ou personalizados (Name server/DNS) da plataforma ou provedor onde deseja configurar o domínio.',
        needHelp: () => (
          <p>
            {'Precisa de ajuda? Entenda melhor '}
            <Link href={EXTERNAL_PLATFORM} inlineLink target="_blank" text="como hospedar o domínio em provedores externos" />
            {' e '}
            <Link href={PERSONAL_DNS} inlineLink target="_blank" text="o que é um servidor personalizado." />
          </p>

        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => { }) => (
          <p>
            {`Após configurar, pode levar até ${propagationTime} horas para que as informações sejam `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' no plano de hospedagem escolhido. Seu site poderá ficar online após esse período.'}
            <span>
              <b>Importante:</b>
              {' Caso a configuração seja feita para um servidor fora da HostGator, a criação de site, registros na Zona de DNS, apontamentos de e-mail e outras configurações, deverão ser feitas no outro provedor.'}
            </span>
          </p>
        ),
      },
      external: {
        title: platformName => `Configurar para ${platformName}`,
        subtitle: 'Não se preocupe, faremos toda a configuração para você. Ao confirmar, os servidores (Name server/DNS) do seu domínio serão:',
        configureButtonLabel: 'Configurar',
        serverLabel: 'Servidor',
        checkboxText: 'Estou ciente que as atuais configurações do domínio serão perdidas ao alterar a plataforma de site do domínio.',
        alertMessage: (propagationTime = 24, platformName = '', helpClickGa = () => { }) => (
          <p>
            {`Após configurar, pode levar até ${propagationTime} horas para que as informações sejam `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' no plano de hospedagem escolhido. Seu site poderá ficar online após esse período.'}
            <span>
              <b>Importante:</b>
              {` Depois de confirmada essa ação, a criação de site, registros na Zona de DNS, apontamentos de e-mail e outras configurações, deverão ser feitas na ${platformName}.`}
            </span>
          </p>
        ),
      },
      alterPlatform: {
        title: 'Alterar plataforma de site',
        checkboxText: 'Estou ciente que as atuais configurações do domínio serão perdidas ao alterar a plataforma de site do domínio.',
        subtitle:
  <p>
    Selecione uma das plataforma abaixo para configurar automaticamente o domínio (alterar DNS).
    <span>
      {'Caso não seja cliente de nenhuma delas, configure manualmente na opção outra plataforma de hospedagem ou faça uma configuração avançada. Se necessário, '}
      <Link href={DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM} inlineLink target="_blank" text="veja como configurar" />
      .
    </span>
  </p>,
        button: 'Continuar',
        tooltip: 'Plataforma de site é o local onde o site ficará hospedado e/ou será criado.',
      },
      mail: {
        title: platformName => `Configurar para ${platformName}`,
        subtitle: (platformName, domainName, helpClickGa) => (
          <>
            {`Aqui vamos apenas realizar os apontados dos registros padrões da ${platformName} na Zona de DNS do domínio, mas para que o e-mail funcione você precisa ter uma conta "`}
            <span className="mark">
              {`@${domainName}`}
            </span>
            {`" na ${platformName}. Se precisar `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL} inlineLink target="_blank" text="veja como fazer isso." />
          </>
        ),
        gmailSubtitle: (platformName, domainName, helpClickGa) => (
          <>
            {'Vamos realizar a configuração de e-mail do domínio (registros padrões de DNS), mas para que ele funcione você precisa ter uma conta de e-mail '}
            &quot;
            <span className="mark">
              {`@${domainName}`}
            </span>
            {`" no ${platformName}. Se precisar `}
            <Link onClick={helpClickGa} href={GMAIL_CREATE_ACCOUNT} inlineLink target="_blank" text="veja como fazer isso." />
          </>
        ),
        configureButtonLabel: 'Configurar',
        showButtonLabel: 'Mostrar registros padrões',
        hideButtonLabel: 'Fechar registros padrões',
        tableHeaders: {
          type: 'Tipo',
          value: 'Valor',
          name: 'Nome',
        },
        checkboxText: () => (
          <>
            <p>
              Estou ciente que ao clicar no botão abaixo, a atual configuração de plataforma de e-mail será excluída e que por isso
              <strong> não será mais possivel usá-la, </strong>
              podendo apenas visualizar os e-mails existentes.
            </p>
          </>
        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => {}) => (
          <p>
            {`Após configurar, pode levar até ${propagationTime} horas para que as informações sejam `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' portanto só será possível utilizar o e-mail após esse período e se houver uma conta de e-mail criada.'}
          </p>
        ),
      },
      custom_mail: {
        title: 'Configurar para outra plataforma de e-mail',
        button: 'Ir para zona de DNS',
        checkbox:
  <>
    {'Estou ciente que ao clicar no botão abaixo, a atual configuração de plataforma de e-mail será excluída e que por isso '}
    <strong>
      não será mais possivel usá-la
    </strong>
    , podendo apenas visualizar os e-mails existentes.
  </>,
        moreInfo: (analyticsClick = () => {}) => <Link onClick={analyticsClick} href={DNS_WIZARD_EMAIL_HELP} target="_blank" text="Veja como apontar seu domínio para uma plataforma de e-mail externa" />,
        description: domain => (
          <>
            <p>Vamos direcionar você para a Zona de DNS, onde será necessário inserir manualmente os registros padrões da plataforma de e-mail desejada.</p>
            <p>
              Você consegue obter essas informações entrando em contato diretamente com a empresa que irá utilizar. Lembre-se de criar a conta de e-mail &quot;
              <span>
                @
                {domain}
              </span>
              &quot; por lá.
            </p>
          </>
        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => { }) => (
          <p>
            {`Após configurar, pode levar até ${propagationTime} horas para que as informações sejam `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            , portanto só será possível utilizar o e-mail após esse período e se houver uma conta de e-mail criada.
          </p>
        ),
      },
      alterEmailPlatform: {
        title: 'Alterar plataforma de e-mail',
        subtitle: 'Você pode alterar para a plataforma de e-mail Titan, cPanel, Gmail ou outra que desejar.',
        continueLabel: 'Continuar',
      },
      advancedDnsZone: {
        title: 'Atenção',
        description: () => (
          <>
            Essa é uma Zona de configuração avançada de DNS. A configuração incorreta poderá deixar o seu site ou serviço
            {' '}
            <strong>fora do ar</strong>
            .
            <br />
            Tenha cuidado ao realizar alterações e verifique sempre as configurações antes de salvar.
          </>
        ),
        checkboxDescription: 'Não exibir esta mensagem novamente.',
        button: 'Ok, continuar para a Zona de DNS',
      },
      domainConfigurationReview: {
        title: 'Configurar domínio',
        subtitle: 'Essa opção permitirá que você gerencie seu domínio mesmo que não tenha um plano de hospedagem.',
        subtitleWithHgPlan: plan => (
          <>
            Ao selecionar essa opção, o seu domínio será apontado para o plano:
            {' '}
            <strong>
              &quot;
              {plan.name}
              &quot;
            </strong>
            {' '}
            -
            {' '}
            {plan.domain}
          </>
        ),
        description: ns => (
          <>
            Identificamos que o seu domínio está apontado para uma hospedagem. Ao continuar, você perderá essa configuração atual.
            <br />
            <br />
            <strong>A sua configuração atual é:</strong>
            <br />
            <strong>Servidor 1:</strong>
            {' '}
            {ns && ns[0]}
            <br />
            <strong>Servidor 2:</strong>
            {' '}
            {ns && ns[1]}
            {ns && ns[2] && (
              <>
                <br />
                <strong>Servidor 2:</strong>
                {' '}
                {ns[2]}
              </>
            )}
            {ns && ns[3] && (
              <>
                <br />
                <strong>Servidor 2:</strong>
                {' '}
                {ns[3]}
              </>
            )}
            <br />
            <br />
            Se preferir, leia mais sobre
            {' '}
            <a href={HOW_TO_CREATE_OR_ALTER_DNS_REGISTER} target="_blank" rel="noreferrer">configuração de Zona de DNS</a>
            {' '}
            antes de continuar.
          </>
        ),
        keepConfigurations: 'Manter configuração',
        configure: 'Configurar',
      },
    },
    errors: {
      default: 'Não foi possível configurar seu domínio no momento. Tente mais tarde ou entre em contato com nosso suporte via chat.',
      getMessage: (errorType) => {
        switch (errorType) {
          case ERROR_MESSAGES.DEFAULT:
            return 'Não foi possível configurar seu domínio no momento. Tente mais tarde ou entre em contato com nosso suporte via chat.';
          case ERROR_MESSAGES.REQUIRED:
            return 'Preencha o campo para continuar.';
          case ERROR_MESSAGES.INVALID_SERVER:
            return 'Servidor inválido! Não pode conter caracteres especiais (exceto hífen), iniciar e finalizar com hífen ou conter apenas números.';
          case ERROR_MESSAGES.INVALID_IP:
            return 'Endereço IP inválido! Deve conter apenas números e ponto. Os números permitidos vão de 0 a 255.';
          default:
            break;
        }
      },
    },
    hostgatorMailPlatform: (
      <>
        Plataformas de e-mail
        <br />
        <b>HostGator</b>
      </>
    ),
  },
  dnsStatus: (status, dotBr, url) => ({
    [PROPAGATION_STATUS.IN_PROGRESS]: {
      labelText: 'Domínio em propagação',
      labelTooltip: (
        <span>
          <strong>Domínio em período de propagação:</strong>
          {` pode levar até ${dotBr ? 24 : 72}h para seu domínio ficar online.`}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          {`Em período de propagação: pode levar até ${dotBr ? 24 : 72}h para seu domínio ficar online.`}
          <br />
          <Link href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" text="Entenda o prazo." />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_PROPAGATED]: {
      labelText: 'Domínio não propagado',
      labelTooltip: (
        <span>
          <strong>Domínio não propagado:</strong>
          {' seu site ainda não está disponível na internet, pois está em processo de atualização. Aguarde algumas horas para que ele esteja disponível'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Domínio não apontado: seu site está fora do ar por falta de configuração do domínio (DNS).
          <br />
          <Link href={url} text="Configure agora" />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_CONTRACTED]: {
      labelText: 'Domínio não contratado',
      labelTooltip: (
        <span>
          <strong>Domínio não contratado:</strong>
          {' você tem um ou mais domínios que ainda não foram adquiridos.'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Domínio não contratado: você tem um ou mais domínios que ainda não foram adquiridos.
          <br />
          <Link
            href={url}
            text="Contrate agora"
            onClick={() => {
              analytics.dnsWizard.bannerDomainNotContracted();
            }}
          />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_CONFIGURED]: {
      labelText: 'Configuração de domínio pendente',
      labelTooltip: (
        <span>
          <strong>Configuração de domínio pendente:</strong>
          {' você tem um ou mais sites com configuração do domínio (alteração de DNS) pendente.'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Configuração de domínio pendente: você tem um ou mais sites com configuração do domínio (alteração de DNS) pendente.
          <br />
          <Link
            href={url}
            text="Veja como configurar."
            onClick={() => {
              analytics.dnsWizard.buttonHowToConfigure();
            }}
          />
        </span>
      ),
    },
  })[status],
  register: {
    modal: {
      title: 'Registro de domínio',
      singleDomain: ({ domain }) => (
        <span>
          O domínio &quot;
          <strong>
            {domain}
          </strong>
          &quot; ainda não está registrado, por isso ele está fora do ar.
          <br />
          <br />
          O endereço do domínio está correto? Se sim, continue para registrar.
        </span>
      ),
      multipleDomains: 'Você tem mais de um domínio não registrado. Para qual você gostaria de continuar com o registro agora?',
      registerDomain: 'Registrar domínio',
    },
  },
  modalEppKey: {
    defaultTitle: 'Solicitar chave EPP',
    successTitle: 'Chave EPP gerada com sucesso',
    failureTitle: 'Erro ao gerar chave EPP',
    orderEppKeyAdvice: (
      <p>
        Selecione o domínio abaixo para gerar a chave.
      </p>
    ),
    selectDomainForEppLabel: 'Para qual domínio deseja solicitar a chave EPP?',
    selectDomainForEppPlaceholder: 'Selecione o domínio',
    generateEppKey: 'Gerar chave EPP',
    confirmOrderAdvice: (
      <>
        <p>
          Para evitar a perda por transferências realizadas fora do prazo é aconselhável que o seu domínio tenha sido registrado
          <strong>{' por mais de 60 dias, não esteja expirado e tenha, pelo menos, 30 dias restantes antes de expirar.'}</strong>
        </p>
        <p>
          Ao gerar a chave, você está ciente que:
        </p>
      </>
    ),
    effectsOnGenerateKey: (
      <ul>
        <li>
          Transferências realizadas fora do prazo informado podem gerar a
          <strong>{' perda do domínio '}</strong>
          durante o processo;
        </li>
        <li>
          O domínio será desbloqueado para transferência;
        </li>
        <li>
          Desabilitaremos a renovação automática em seu portal e, se houver uma fatura em aberto, ela será cancelada.
        </li>
      </ul>
    ),
    cancel: 'Cancelar',
    keyGenerated: (domain, key) => (
      <>
        <p>
          Sua chave EPP para o domínio
          <strong>{` ${domain} `}</strong>
          é:
        </p>
        <span>{key}</span>
      </>
    ),
    successInfo: (
      <>
        <p>
          Essa chave deve ser enviada para seu novo provedor e tem a validade de 7 dias. Após o envio para o novo provedor, o prazo é de 5 a 15 dias para concluir a transferência.
        </p>
        <p>
          Leia mais no nosso material de apoio:
        </p>
        <NewLink text="Como transferir meu domínio com a chave EPP?" href={HOW_TO_TRANSFER_A_DOMAIN} />
      </>
    ),
    understood: 'Ok, entendi',
    failureInfo: domain => (
      <>
        <p>
          Tivemos um problema ao
          <strong>{' gerar a chave EPP '}</strong>
          para o domínio
          <strong>
            {` ${domain}`}
          </strong>
          .
        </p>
        <p>
          Fale com um especialista via chamado ou volte para a tela de gerenciar domínios.
        </p>
      </>
    ),
    openTicket: 'Abrir um chamado',
    backToDomains: 'Voltar para gerenciar domínios',
    copyKeyTooltip: 'Copiar chave',
    copiedKeyTooltip: 'Copiado!',
    copyCodeLabel: 'Copiar código',
  },
  manageDomainHandler: {
    tags: {
      pending: 'Pendente',
      active: 'Registrado',
      cancelled: 'Cancelado',
      pendingTransfer: 'Em Transferência',
      registerError: 'Falha no registro',
      expired: 'Expirado',
      nextDueDate: 'À Vencer',
      overDueDate: 'Vencido',
      in_progress: 'Em propagação',
    },
    alert: {
      overDueDate: ({
        expiryDate,
      }) => (
        <>
          {`Seu domínio venceu e irá expirar dia ${expiryDate}. Renove agora mesmo para não correr o risco de perdê-lo.`}
        </>
      ),
      nextDueDate: () => (
        <>
          {'A assinatura vencerá em breve. Recomendamos que você utilize um método de pagamento com '}
          <strong>compensação imediata,</strong>
          { ' como '}
          <strong>Pix, Cartão de Crédito ou PayPal.</strong>
        </>
      ),
      goToPayment: 'Ir para pagamento',
    },
    domainData: {
      registerDate: 'Data de Registro',
      nextDueDate: 'Data do Vencimento',
      cycle: 'Ciclo',
      manageSubscription: 'Gerenciar Assinatura',
    },
    cardOptions: {
      dns: {
        title: 'Servidores DNS',
        tooltip: 'Os servidores DNS traduzem nomes de domínio em endereços IP. As informações de DNS são importantes para configurar domínios, e-mails, gerenciar subdomínios e garantir que o site e e-mails fiquem online e funcionais. ',
        cta: 'Configurar domínio',
        alert: {
          in_progress: ({ dotBr }) => (
            <>
              {`Em período de propagação: pode levar até ${dotBr ? 24 : 72}h para seu domínio ficar online.`}
              <br />
              <Link
                href={DNS_PROPAGATION_ARTICLE}
                target="_blank"
                rel="noreferrer"
                text="Entenda o prazo."
                testId="in_progress-cta"
              />
            </>
          ),
          reserved: (
            <>
              {'Seu domínio está com a gente, '}
              <strong>porém ele ainda não está configurado</strong>
              {' para um plano de hospedagem.'}
              <Link
                href={CONFIGURE_DOMAIN_DNS_ARTICLE}
                target="_blank"
                text="Veja como configurar o seu domínio."
                inlineLink
                testId="reserved-cta"
              />
            </>
          ),
        },
      },
      mail: {
        title: 'E-mail profissional',
        description: 'Gerencie seus e-mails já criados ou crie uma conta profissional com um e-mail personalizado @seudominio.',
        cta: 'Ir para lista de e-mails',
      },
      manage: {
        title: 'Opções de gerenciamento',
        protect: {
          title: 'Protect ID',
          description: 'Oculte suas informações pessoais em sites como o WHOIS.',
          cta: 'Ver detalhes',
          testId: 'protect',
        },
        contactInfo: {
          title: 'Informações de contato',
          description: 'Altere as informações de contato vinculadas ao seu domínio.',
          cta: 'Alterar',
          testId: 'contact',
        },
        register: {
          title: 'Trava de registro',
          description: 'Bloqueie o seu domínio para prevenir que ele seja transferido sem a sua autorização.',
          cta: 'Gerenciar',
          testId: 'register',
        },
        eppKey: {
          title: 'Chave EPP',
          description: 'Código vinculado ao registro do domínio internacional.',
          cta: 'Solicitar chave',
          testId: 'eppKey',
        },
      },
    },
  },
  domainsListPage: {
    emptyState: (
      <>
        {'Não localizamos nenhum domínio contratado. '}
        <NewLink text="Clique aqui" target="_blank" rel="noreferrer" href={REGISTER_DOMAIN_URL} />
        {' para contratar um domínio.'}
      </>
    ),
    noFilterResult: 'Nenhum resultado. Tente novamente :)',
    filter: {
      title: 'Listagem de Domínios',
      filterLabel: 'Filtrar por',
      all: 'Todos',
      billingPending: 'Com pendência financeira',
      registerFail: 'Falha de registro',
      search: 'Busque por domínio',
      clearLabel: 'Limpar filtro',
    },
    modalDomainAutoRenovation: {
      title: 'Habilitar renovação automática',
      description: 'Caso o método de pagamento seja PayPal ou Cartão de Crédito, a cobrança será feita automaticamente. Se o método for boleto, a fatura será emitida 30 dias antes do vencimento.',
      confirmLabel: 'Confirmar',
    },
    alerts: {
      disabled_auto_renew: (date, onClick) => (
        <>
          {'Seu domínio vence dia '}
          <strong>{date}</strong>
          {'. '}
          <Button onClick={onClick} label="Ative a renovação automática" variant="text" />
          {' para não correr o risco de perdê-lo.'}
        </>
      ),
      register_fail: 'Falha no registro do domínio. Aguarde novas instruções por e-mail.',
      in_transfer: 'Domínio em processo de transferência. Aguarde novas instruções por e-mail.',
      pending_domain: onClick => (
        <>
          <strong>Domínio pendente:</strong>
          {' Efetue o pagamento da fatura em aberto. '}
          <Button onClick={onClick} label="Pagar fatura" variant="text" />
        </>
      ),
      next_due_invoice: (date, url) => (
        <>
          {'Sua fatura vence dia '}
          <strong>{date}</strong>
          {'. '}
          <Link href={url} text="Pague o boleto agora mesmo" inlineLink rel="noreferrer" />
          {' e não corra o risco de perder seu domínio.'}
        </>
      ),
      next_due_date_credit_card: date => (
        <>
          {'Sua fatura '}
          <strong>{`vence dia ${date}`}</strong>
          {' e você será cobrado automaticamente via Cartão de Crédito.'}
        </>
      ),
      next_due_date_paypal: date => (
        <>
          {'Sua fatura '}
          <strong>{`vence dia ${date}`}</strong>
          {' e você será cobrado automaticamente via PayPal.'}
        </>
      ),
      due_date_disabled_auto_renovation: (date, onClick, loading) => (
        <>
          {'Seu domínio '}
          <strong>{`vence dia ${date}. `}</strong>
          <Button onClick={onClick} disabled={loading} label="Renove agora mesmo" variant="text" />
          {' para não correr o risco de perdê-lo.'}
        </>
      ),
      due_date_invoice: (date, days, url) => (
        <>
          {'Sua fatura venceu dia '}
          <strong>{date}</strong>
          {' e seu domínio será expirado em '}
          <strong>{days === 1 ? `${days} dia. ` : `${days} dias. `}</strong>
          <Link href={url} text="Pague o boleto agora mesmo!" inlineLink rel="noreferrer" />
        </>
      ),
      overdue_disabled_auto_renew: (date, onClick, loading) => (
        <>
          {'Seu domínio venceu e irá '}
          <strong>{`expirar dia ${date}. `}</strong>
          <Button onClick={onClick} disabled={loading} label="Renove agora mesmo" variant="text" />
          {' para não correr o risco de perdê-lo.'}
        </>
      ),
      expired_invoice: (date, url) => (
        <>
          {'Sua fatura '}
          <strong>{`venceu dia ${date}. `}</strong>
          {'e seu domínio será cancelado em breve. '}
          <Link href={url} text="Pague o boleto agora mesmo!" inlineLink rel="noreferrer" />
        </>
      ),
      expired_grace_period_overdue: date => (
        <>
          {'Seu Domínio '}
          <strong>{`venceu dia ${date}`}</strong>
          . Para maiores informações entre em contato com o suporte.
        </>
      ),
      expired_on_grace_period: onClick => (
        <>
          {'Seu domínio '}
          <strong>expirou</strong>
          {' e esta é a última chance de renová-lo. '}
          <Button onClick={onClick} label="Pague a fatura agora mesmo" variant="text" />
          {' para não correr o risco de perdê-lo.'}
        </>
      ),
      domain_cancelled_by_tos: (
        <>
          {'Seu '}
          <strong>domínio foi cancelado</strong>
          {'. Entre em contato com '}
          <strong>analise@hostgator.com.br</strong>
          {' para mais informações.'}
        </>
      ),
      cancelled_unrecoverable: (date, url) => (
        <>
          {'Seu '}
          <strong>{`domínio foi cancelado dia ${date}. `}</strong>
          <Link href={url} text="Acesse nosso site e contrate um novo domínio!" inlineLink rel="noreferrer" target="_blank" />
        </>
      ),
      cancelled_recoverable: (date, url) => (
        <>
          {'Seu '}
          <strong>{`domínio foi cancelado dia ${date}`}</strong>
          {' por falta de pagamento. '}
          <Link href={url} text="Acesse nosso site e contrate um novo domínio!" inlineLink rel="noreferrer" target="_blank" />
        </>
      ),
    },
    propagationAlerts: (status, dotBr) => ({
      [PROPAGATION_STATUS.IN_PROGRESS]: {
        labelTag: 'Em propagação',
        labelDomainsDetails: (
          <>
            {`Em período de propagação: pode levar até ${dotBr ? 24 : 72}h para seu domínio ficar online. `}
            <Link href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" text="Entenda o prazo." inlineLink />
          </>
        ),
      },
    })[status],
    statusProductDomain: {
      pending: 'Pendente',
      active: 'Registrado',
      cancelled: 'Cancelado',
      pendingTransfer: 'Em Transferência',
      registerError: 'Falha no registro',
      expired: 'Expirado',
      nextDueDate: 'À Vencer',
      overDueDate: 'Vencido',
    },
    contractNewButtonLabel: 'Contratar novo domínio',
    configureButtonLabel: 'Configurar domínio',
    manageButtonLabel: 'Gerenciar',
    loadMoreButtonLabel: 'Carregar mais',
  },
};
