const styles = theme => ({
  button: {
    display: 'block',
    margin: '20px auto',
    backgroundColor: theme.color.white,
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.mystic}`,
    color: theme.color.tundora,
    padding: '6px 16px',
  },
});

export default styles;
