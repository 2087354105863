import React from 'react';


const HgLogoV2 = () => (
  <svg width="141" height="21" viewBox="0 0 141 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="140.235" height="20.4627" fill="#1F2044" />
    <path d="M17.5464 20.4627H12.836V12.7103H4.73863V20.4627H0V0.863525H4.73863V8.49821H12.836V0.863525H17.5464V20.4627Z" fill="white" />
    <path d="M34.4275 12.8457C34.4324 13.871 34.2293 14.8865 33.8305 15.831C33.4316 16.7754 32.845 17.6292 32.1068 18.3406C31.3685 19.052 30.4939 19.6063 29.5353 19.9699C28.5767 20.3335 27.5542 20.4989 26.5298 20.4561C22.1666 20.4561 18.5464 17.4119 18.5464 12.8457C18.5464 8.30794 22.1645 5.26481 26.5298 5.26481C27.5519 5.22123 28.5724 5.38516 29.5294 5.74665C30.4864 6.10814 31.3603 6.6597 32.0984 7.36812C32.8364 8.07655 33.4232 8.92713 33.8236 9.86855C34.2239 10.81 34.4293 11.8227 34.4275 12.8457V12.8457ZM30.0055 12.8457C29.9645 12.157 29.7223 11.4955 29.3089 10.9431C28.8954 10.3908 28.3286 9.97197 27.6793 9.73861C27.03 9.50525 26.3268 9.46761 25.6563 9.63035C24.9858 9.79309 24.3779 10.1491 23.9078 10.6541C23.4378 11.1592 23.1263 11.7911 23.012 12.4715C22.8978 13.1519 22.9859 13.8509 23.2652 14.4818C23.5445 15.1126 24.0026 15.6476 24.5831 16.0204C25.1637 16.3932 25.8409 16.5874 26.5308 16.5789C27.0048 16.5644 27.4714 16.456 27.9032 16.26C28.3351 16.0641 28.7238 15.7844 29.0468 15.4372C29.3698 15.09 29.6207 14.6822 29.785 14.2374C29.9494 13.7925 30.0242 13.3195 30.0045 12.8457H30.0055Z" fill="white" />
    <path d="M39.8833 15.3729C40.0863 16.6017 41.2023 17.0603 42.427 17.0603C43.6285 17.0603 44.5866 16.5449 44.616 15.9168C44.6586 14.967 43.9139 14.4028 42.8566 14.1988C42.127 14.0578 39.9413 13.6266 39.9413 13.6266C37.2817 13.1405 35.7665 12.3714 35.7665 10.1126C35.7665 7.16995 38.3113 5.25217 41.942 5.25217C45.5158 5.25217 47.8885 6.8818 48.4608 9.36886L44.286 10.1978C44.1571 9.67395 43.8427 9.21452 43.4011 8.90449C42.9596 8.59445 42.4211 8.4548 41.8845 8.5114C40.6547 8.5114 40.0396 9.02786 39.9249 9.62756C39.8499 10.0213 40.0395 10.349 41.3454 10.6281C42.1866 10.8097 44.7196 11.3141 44.7196 11.3141C47.4349 11.8853 48.6932 13.5149 48.6932 15.6306C48.6932 18.8046 45.9481 20.4627 42.1744 20.4627C38.8004 20.4627 35.9137 19.2328 35.4276 16.2882L39.8833 15.3729Z" fill="white" />
    <path d="M61.9832 19.381C60.7925 20.0877 59.4338 20.4612 58.0492 20.4627C54.4592 20.4627 52.3058 18.4739 52.3058 14.6139V9.05839H49.6932V5.34451H52.3058V1.43993L56.9007 0.432281V5.34556H61.1503V9.05938H56.8977V14.1471C56.8977 15.6692 57.6161 16.2781 58.8256 16.2781C59.5609 16.2526 60.2795 16.0523 60.9219 15.6936L61.9832 19.381Z" fill="white" />
    <path d="M83.2448 8.47735C83.3197 9.06871 83.3582 9.66408 83.3598 10.2602C83.3598 16.1932 79.1991 20.4611 73.395 20.4611C72.04 20.485 70.694 20.2395 69.4347 19.7388C68.1754 19.2382 67.0283 18.4924 66.0596 17.5447C65.091 16.597 64.32 15.4662 63.7919 14.2182C63.2638 12.9701 62.989 11.6296 62.9833 10.2745C62.9776 8.9193 63.2412 7.57651 63.7587 6.32405C64.2762 5.0716 65.0375 3.9344 65.9982 2.97855C66.9588 2.0227 68.0999 1.26727 69.355 0.756019C70.61 0.244768 71.9537 -0.0120632 73.3088 0.000435184C77.5878 0.000435184 81.3497 2.04607 82.7003 5.8756L78.1341 7.2201C77.7068 6.32049 77.0171 5.57112 76.1562 5.0704C75.2953 4.56968 74.303 4.34101 73.3098 4.41441C72.5615 4.42018 71.8224 4.57688 71.1362 4.87507C70.4499 5.17326 69.8306 5.60681 69.3158 6.14984C68.801 6.69286 68.4013 7.33419 68.1401 8.03539C67.8789 8.73659 67.7621 9.48326 67.7962 10.2307C67.7676 10.9903 67.8926 11.7477 68.1644 12.4575C68.4362 13.1673 68.8492 13.8148 69.3778 14.361C69.9064 14.9071 70.5401 15.3407 71.2407 15.6355C71.9413 15.9303 72.6939 16.0803 73.454 16.0765C74.5697 16.1365 75.6741 15.8247 76.5937 15.1899C77.5133 14.5551 78.1964 13.6332 78.5359 12.5686H72.6503V8.47629L83.2448 8.47735Z" fill="white" />
    <path d="M98.4602 10.8957V20.0281H94.0372V18.9657C92.8349 19.9781 91.3006 20.5101 89.7296 20.4594C86.514 20.4594 84.3598 18.5933 84.3598 15.7501C84.3598 12.9353 86.5141 11.0976 90.3618 11.0976H94.0372V10.8104C94.0503 10.5406 94.0065 10.2712 93.9084 10.0195C93.8102 9.7679 93.6597 9.53974 93.4674 9.35005C93.2751 9.16035 93.045 9.01335 92.7921 8.91862C92.5391 8.82389 92.2694 8.7836 91.9998 8.80033C91.722 8.75608 91.4381 8.76808 91.165 8.83569C90.8919 8.9033 90.635 9.02515 90.4099 9.19391C90.1848 9.36268 89.9959 9.57495 89.8545 9.81814C89.713 10.0613 89.6218 10.3304 89.5864 10.6095L85.2496 9.71966C85.8818 6.93428 88.4087 5.18182 91.8557 5.18182C95.9044 5.18081 98.4602 7.21941 98.4602 10.8957ZM94.0372 14.3457V14.0291H90.8786C89.73 14.0291 89.0122 14.6319 89.0122 15.5228C89.0122 16.4421 89.8158 17.0164 91.0223 17.0164C91.3967 17.0424 91.7725 16.9935 92.1277 16.8725C92.4829 16.7515 92.8107 16.5609 93.0914 16.3119C93.3721 16.0628 93.6003 15.7603 93.7627 15.422C93.9251 15.0837 94.0184 14.7165 94.0372 14.3417V14.3457Z" fill="white" />
    <path d="M111.751 19.381C110.561 20.0876 109.202 20.4612 107.817 20.4627C104.227 20.4627 102.074 18.4739 102.074 14.6139V9.05839H99.4602V5.34451H102.074V1.43993L106.669 0.432281V5.34556H110.918V9.05938H106.669V14.1471C106.669 15.6692 107.386 16.2781 108.597 16.2781C109.332 16.2525 110.05 16.0521 110.693 15.6936L111.751 19.381Z" fill="white" />
    <path d="M128.632 12.8458C128.637 13.8709 128.434 14.8864 128.035 15.8308C127.637 16.7752 127.051 17.6289 126.313 18.3403C125.574 19.0517 124.7 19.606 123.741 19.9696C122.783 20.3333 121.76 20.4988 120.736 20.4561C116.373 20.4561 112.751 17.412 112.751 12.8458C112.751 8.30799 116.37 5.26486 120.736 5.26486C121.758 5.22142 122.778 5.38539 123.735 5.74695C124.692 6.1085 125.566 6.66013 126.304 7.36854C127.042 8.07695 127.628 8.9275 128.028 9.86884C128.429 10.8102 128.634 11.8229 128.632 12.8458V12.8458ZM124.21 12.8458C124.169 12.1571 123.927 11.4955 123.514 10.9432C123.1 10.3909 122.534 9.97202 121.885 9.73866C121.235 9.5053 120.532 9.46766 119.861 9.6304C119.191 9.79314 118.583 10.1491 118.113 10.6542C117.643 11.1592 117.331 11.7911 117.217 12.4715C117.103 13.1519 117.191 13.8509 117.47 14.4818C117.749 15.1127 118.208 15.6477 118.788 16.0205C119.369 16.3933 120.046 16.5874 120.736 16.5789C121.21 16.5644 121.676 16.4561 122.108 16.2601C122.54 16.0641 122.929 15.7844 123.252 15.4373C123.575 15.0901 123.826 14.6823 123.99 14.2374C124.155 13.7926 124.229 13.3196 124.209 12.8458H124.21Z" fill="white" />
    <path d="M140.235 5.96042L140.032 10.555H139.199C135.984 10.555 134.227 12.2212 134.227 15.9543V20.4627H129.632V7.03701L134.227 6.04769V8.80464C134.705 7.90344 135.422 7.15098 136.299 6.62936C137.176 6.10774 138.179 5.83703 139.199 5.84677C139.548 5.83864 139.897 5.87685 140.235 5.96042V5.96042Z" fill="white" />
  </svg>
);

export default HgLogoV2;
