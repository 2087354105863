import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';

import { InvoicesActionTypes } from './invoices.types';
import {
  GENERATE_PIX, INVOICE_DETAIL_URL, INVOICE_SPLIT_PAYMENT_URL, NFSE_BY_INVOICE_URL,
} from '@/config/api';
import { invoicesActions } from '.';
import { AWS_GATOR_SOLUTIONS_URL } from '@/config';
import { isBRServer, mockoonWarning } from '@/utils/Validators/validation';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import { authActions } from '../auth';
import { commonActions } from '../common';
import { locale } from '@/utils/locale';


function* splitPayment(action) {
  const { invoiceId, listProductsId, callBackFuction } = action.payload;
  const { token } = yield select(state => state.auth);
  const request = {

    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: INVOICE_SPLIT_PAYMENT_URL(invoiceId),
    data: { items: listProductsId },
  };

  try {
    const response = yield call(api.whmcs.post, request);
    callBackFuction(response.status);
    yield put(invoicesActions.invoices.splitPayment.success);
  } catch (e) {
    callBackFuction('');
  }
}

function* loadDetails(action) {
  const { invoiceId, callBackFuction } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {

    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: INVOICE_DETAIL_URL(invoiceId),
  };

  try {
    const response = yield call(api.whmcs.get, request);

    const invoice = {
      ...response.data.data.invoice,
      items: response.data.data.invoiceItems,
      nfse: response.data.data.nfse,
    };
    callBackFuction(invoice);
    yield put(invoicesActions.invoices.success());
  } catch (e) {
    callBackFuction('');
  }
}

function* generatePix(action) {
  const { invoiceId, callBackFuction } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {

    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: GENERATE_PIX(invoiceId),
  };

  try {
    const response = yield call(api.whmcs.post, request);
    callBackFuction(response.data.code, response.data.qrCode);
  } catch (e) {
    callBackFuction('', '');
  }
}

function* cancelOrder(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { orderId, reloadData } = action.payload;
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'cancelOrder' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/order/${orderId}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(invoicesActions.cancelOrder.loading(true));
  try {
    yield call(api.aws.post, request);
    yield put(invoicesActions.cancelOrder.success('canceled'));
    reloadData && reloadData();
  } catch (e) {
    yield put(invoicesActions.cancelOrder.failure('cancelation_fail'));
  }
  yield put(invoicesActions.cancelOrder.loading(false));
}

function* getNFSEByInvoice(action) {
  const { invoiceId } = action.payload;
  const { token } = yield select(state => state.auth);
  const errorNotification = commonActions.notifications.set({
    label: locale('invoices.infotReceiptGeneration'),
    type: 'error',
  });

  const request = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: NFSE_BY_INVOICE_URL(invoiceId),
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const listOfNfse = response.data ? response.data.data : [];

    if (!listOfNfse.length) {
      yield put(invoicesActions.invoices.getNfse.set([], invoiceId));
      yield put(errorNotification);
    }
    if (listOfNfse.length) {
      const nfseData = response.data.data;
      yield put(invoicesActions.invoices.getNfse.set(nfseData, invoiceId));
    }

    yield put(invoicesActions.invoices.success());
  } catch (e) {
    yield put(invoicesActions.invoices.getNfse.set([], invoiceId));
    yield put(errorNotification);
    yield put(invoicesActions.invoices.failure());
  }
}
function* sagas() {
  yield takeLatest(InvoicesActionTypes.INVOICE_DETAILS_REQUEST, loadDetails);
  yield takeLatest(InvoicesActionTypes.GENERATE_PIX_REQUEST, generatePix);
  yield takeLatest(InvoicesActionTypes.CANCEL_ORDER_REQUEST, cancelOrder);
  yield takeLatest(InvoicesActionTypes.SPLIT_PAYMENT_REQUEST, splitPayment);
  yield takeLatest(InvoicesActionTypes.GET_NFSE_REQUEST, getNFSEByInvoice);
}

export default sagas;
