import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import i18n from '@/lang/i18n';
import { LocaleProvider } from './components/providers/LocaleProvider';
import locale from '@/lang/locale';
import store from '@/redux/store';
import ScrollToTop from '@/components/ScrollToTop';
import SnackbarProviderCustom from '@/components/Notification/SnackbarProviderCustom/index';
import * as defaultTheme from '@/styles/HgThemeProvider';
import GlobalStyle from '@/styles/GlobalStyle';
import Pages from '@/pages/index';

import { initConfigs } from '@/config/application/initConfigs';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { Growthbook } from './config/GrowthBook';

const {
  color,
  palette,
  typography,
  font,
} = defaultTheme;

const muiTheme = createMuiTheme({
  color,
  palette,
  typography,
  font,
});

initConfigs(muiTheme);


const App = () => (
  <LocaleProvider content={locale.getAll()}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <BrowserRouter>
              <SnackbarProviderCustom>
                <GrowthBookProvider growthbook={Growthbook}>
                  <ScrollToTop>
                    <Route render={props => <Pages {...props} />} />
                  </ScrollToTop>
                </GrowthBookProvider>
              </SnackbarProviderCustom>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </I18nextProvider>
  </LocaleProvider>
);

export default App;
