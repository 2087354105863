import React from 'react';
import * as Style from './IconVerticalEllipsis.style';

const IconVerticalEllipsis = ({
  color = '#707070',
  size = 24,
  testId = 'icon-verticalEllipsis',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" width={size} height={size} fill={color}>
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  </Style.Wrapper>
);


export default IconVerticalEllipsis;
