const styles = theme => ({
  content: {
    padding: '2px 0 5px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '2px 0px 5px 0px',
    },
  },
  statusIcon: {
    verticalAlign: 'middle',
    marginRight: 20,
    marginLeft: '-3px',
  },
  expand: {
    margin: 0,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  arrow: {
    fill: theme.color.tundora,
  },
  subtitle: {
    display: 'inline-block',
    color: theme.color.regentGray,
    fontSize: '12px',
    userSelect: 'none',
  },
  subValue: {
    color: theme.color.tundora,
    fontSize: '14px',
    userSelect: 'none',
    letterSpacing: '-0.5px',
    margin: '4px 0 0 0',
  },
  id: {
    color: theme.color.tundora,
    display: 'block',
    fontSize: '16px',
    marginBottom: '4px',
  },
  iconLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  label: {
    display: 'inline-block',
    height: 'auto',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.color.white,
    fontWeight: 'normal',
    fontSize: '12px',
    userSelect: 'none',
    padding: '0 7px',
    borderRadius: 3,
    whiteSpace: 'nowrap',
    '@media(min-width: 1100px) and (max-width: 1280px)': {
      whiteSpace: 'normal',
      maxWidth: '82px',
    },
    '@media(min-width: 793px) and (max-width: 960px)': {
      whiteSpace: 'normal',
      maxWidth: '82px',
    },
    '@media(min-width: 420px) and (max-width: 510px)': {
      whiteSpace: 'normal',
      maxWidth: '82px',
    },
  },
  contentFlex: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 30px',
    '@media(min-width: 960px) and (max-width: 1160px)': {
      paddingRight: '22px',
    },
    '@media(min-width: 420px) and (max-width: 972px)': {
      paddingRight: '22px',
    },
  },
  cardContainerNotUnpaid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 420px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(min-width: 960px) and (max-width: 1100px)': {
      flexDirection: 'column',
    },
    '@media(max-width: 960px)': {
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    '@media(max-width: 420px)': {
      flexDirection: 'column',
    },
  },
  firstWrapperNotUnpaid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media(max-width: 420px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  secondWrapperNotUnpaid: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondWrapperNotUnpaidWithNewModalPayment: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
  },
  firstWrapper: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media(min-width: 1100px) and (max-width: 1200px)': {
      minWidth: '300px',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '@media(max-width: 960px)': {
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '@media(max-width: 420px)': {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  secondWrapper: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .select-payment': {
      '@media(max-width: 420px)': {
        width: '100%',
        margin: '6px 0 15px 0',
      },
    },
    '@media(min-width: 1160px) and (max-width: 1290px)': {
      width: '58%',
    },
    '@media(min-width: 1100px) and (max-width: 1160px)': {
      width: '58%',
      justifyContent: 'space-between',
    },
    '@media(min-width: 960px) and (max-width: 1160px)': {
      minWidth: '382px',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
      justifyContent: 'center',
      marginTop: '15px',
      width: '100%',
    },
    '@media(max-width: 960px)': {
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: '376px',
    },
    '@media(min-width: 0px) and (max-width: 792px)': {
      width: '100%',
      marginTop: '24px',
      minWidth: 'auto',
    },
    '@media(min-width: 420px) and (max-width: 792px)': {
      justifyContent: 'center',
    },
    '@media(max-width: 420px)': {
      marginTop: '10px',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '188.67px',
    width: '50%',
    '@media(max-width: 1360px)': {
      minWidth: '134px',
    },
    '@media(max-width: 960px)': {
      minWidth: '167px',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
      minWidth: '220px',
    },
    '@media(min-width: 0px) and (max-width: 792px)': {
      minWidth: '210px',
    },
    '@media(min-width: 420px) and (max-width: 510px)': {
      minWidth: '140px',
    },
  },
  dueContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    paddingRight: '5%',
    '@media(min-width: 1260px) and (max-width: 1500px)': {
      width: '50%',
    },
    '@media(min-width: 1160px) and (max-width: 1260px)': {
      paddingLeft: '5%',
    },
    '@media(max-width: 1160px)': {
      paddingLeft: 0,
    },
    '@media(max-width: 960px)': {
      minWidth: '170px',
      justifyContent: 'flex-start',
    },
    '@media(max-width: 420px)': {
      marginRight: '60px',
      marginBottom: '6px',
    },
  },
  dueWrapper: {
    marginRight: '30px',
    '@media(max-width: 420px)': {
      width: '50%',
      display: 'inline-block',
      marginTop: '16px',
      marginRight: '50px',
    },
  },
  valueWrapper: {
    '@media(max-width: 420px)': {
      width: '50%',
      display: 'inline-block',
      marginTop: '16px',
    },
  },
  arrowIconNotUnpaid: {
    '@media(min-width: 0px) and (max-width: 420px)': {
      position: 'absolute',
      top: 14,
      right: 12,
    },
  },
  arrowIcon: {
    '@media(min-width: 960px) and (max-width: 1100px)': {
      position: 'absolute',
      top: 14,
      right: 12,
    },
    '@media(max-width: 792px)': {
      position: 'absolute',
      top: 14,
      right: 12,
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: '36px',
    '@media(min-width: 960px) and (max-width: 1100px)': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 0,
    },
    '@media(max-width: 420px)': {
      marginRight: '-10px',
    },
  },
  actions: {
    height: 52,
    width: '182.52px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: '0 4px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '@media(min-width: 1160px)': {
      width: '100%',
      maxWidth: '140px',
    },
    '@media(min-width: 1100px) and (max-width: 1160px)': {
      justifyContent: 'flex-end',
    },
    '@media(min-width: 960px) and (max-width: 1160px)': {
      width: '100%',
    },
  },
  preTable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '24px',
    '&:last-child': {
      '@media(max-width: 960px)': {
        paddingBottom: 0,
      },
    },
    '@media(max-width: 960px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  pay: {
    minWidth: '136.42px',
    margin: 0,
    '&:hover': {
      textDecoration: 'none',
    },
    '& span': {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '@media(max-width: 960px)': {
      margin: 0,
    },
  },
  downloadContainer: {
    paddingTop: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '9px',
  },
});

export default styles;
