import React from 'react';
import * as Styles from './IconArrow.styles';

const IconArrow = ({
  testId = 'IconArrow',
  color = '#ffffff',
}) => (
  <Styles.Svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <mask id="mask0_1373_21975" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill={color} />
    </mask>
    <g mask="url(#mask0_1373_21975)">
      <path d="M3.59984 13.3334L2.6665 12.4L10.3998 4.66671H5.99984V3.33337H12.6665V10H11.3332V5.60004L3.59984 13.3334Z" />
    </g>
  </Styles.Svg>
);

export default IconArrow;
