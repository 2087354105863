import React from 'react';
import * as Style from './IconDetailedProtection.style';

const IconDetailedProtection = ({
  line = '#4a4a4a',
  color = '#2E93EE',
  size = '40',
  testId = 'icon-protection',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.6429 1.94336H17.6084V37.2784H37.6429V1.94336Z" fill={color} />
      <path d="M17.7001 4.12945L4.54576 8.55196V25.0681C6.28912 30.1117 13.8919 33.7416 17.5202 35.032C19.8513 33.9631 27.4958 30.1799 30.6962 24.9682V7.52622L17.6951 4.13108L17.7001 4.12945ZM17.6201 1.94482L32.7841 5.90441V25.5343C28.8295 32.6559 17.6201 37.2749 17.6201 37.2749C17.6201 37.2749 4.81383 33.2603 2.45605 25.3944V7.04165L17.6201 1.94482Z" fill={line} />
      <path d="M17.6335 37.4781L17.5686 37.4581C17.4404 37.4182 4.64069 33.3237 2.28125 25.4527V25.3995V6.90688L17.6252 1.74512L17.6784 1.75843L32.9824 5.75461V25.5826L32.959 25.6259C29.0178 32.7242 17.815 37.4015 17.7018 37.4481L17.6335 37.4781ZM2.64762 25.3711C4.88552 32.7509 16.6344 36.7571 17.6168 37.0801C18.5193 36.6954 28.8579 32.178 32.6027 25.4893V6.04933L17.6302 2.13974L2.64427 7.17833L2.64762 25.3711ZM17.5319 35.2269L17.4603 35.2019C16.3397 34.8039 6.449 31.1324 4.37096 25.1231L4.361 25.0615V8.41049L17.6917 3.92802L17.7451 3.94133L30.886 7.37306V25.0132L30.8594 25.0581C27.6258 30.3232 19.893 34.1479 17.6002 35.1935L17.5319 35.2269ZM4.73398 25.0298C6.7321 30.7477 16.1232 34.321 17.5136 34.8239C19.8564 33.7483 27.3493 30.0084 30.513 24.9082V7.67114L17.7051 4.326L4.73398 8.68682V25.0298Z" fill={line} />
      <path d="M15.762 10.7569L9.92245 12.7384V23.1369C10.7433 25.3565 13.4675 27.1514 15.762 28.2754V10.7569ZM17.8517 7.83301V31.3891C17.8517 31.3891 9.39134 28.7133 7.83447 23.4699V11.2331L17.8517 7.83301Z" fill={line} />
      <path d="M18.0381 31.6438L17.795 31.5672C17.4454 31.4556 9.22314 28.7999 7.65628 23.5215L7.64795 23.4682V11.0965L18.0381 7.56982V31.6438ZM8.02093 23.4415C9.40463 28.0189 16.2798 30.6414 17.6652 31.1277V8.086L8.02256 11.3663L8.02093 23.4415ZM15.9484 28.5684L15.6803 28.4368C13.8587 27.5443 10.6651 25.6762 9.74762 23.1952L9.73593 23.1302V12.6018L15.9484 10.4937V28.5684ZM10.1089 23.0953C10.9531 25.3232 13.7821 27.0582 15.5755 27.9657V11.0166L10.1089 12.8715V23.0953Z" fill={line} />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedProtection;
