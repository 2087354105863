const styles = theme => ({
  title: {
    fontWeight: 500,
    fontSize: '16px',
    color: theme.color.tundora,
    marginTop: '9px',
    marginBottom: '17px',
  },
});

export default styles;
