import React, { useContext } from 'react';
import { IconNetwork, IconError } from '@/icons';
import * as Styles from './SiteItem.styles';
import { Button, Link, Dropdown } from '@/pages/common';
import { ThemeContext } from 'styled-components';
import { Message } from '@/pages/common/Message';
import { Tooltip } from '@/pages/common/Tooltip';
import { Tag as CommonTag } from '@/pages/common/Tag';
import { PropagationStatusLabel } from '@/components/Domain/PropagationStatusLabel';
import { ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG, CHARLIE_SSL_MODAL } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { IconSites, Tag } from 'gatorcomponents';
import SiteAlert from '../SiteAlert';
import useLocale from '@/hooks/useLocale';
import {
  PROPAGATION_STATUS_TYPE, STATUS_TYPE, STATUS_ALERTS_VARIANTS,
} from '@/enums/sites/status.enum';
import { useHistory } from 'react-router';
import { isBRServer } from '@/utils/Validators/validation';
import { SITELOCK_PLANS } from '@/enums/products/products.enum';

const SiteItem = ({
  url = '#',
  id = 'sample id',
  actions,
  financialStatus,
  warnings = {
    label: '',
    icon: null,
    tooltip: '',
    payButton: false,
  },
  testId = 'site-item',
  dropdownParams = {
    alignItems: 'center',
    list: [
      {
        id: 'item-01', label: 'SSL Certificate', icon: null, onClick: () => {}, testId: 'button_ssl',
      },
      {
        id: 'item-02', label: 'Backup', icon: null, onClick: () => {}, testId: 'button_backup',
      },
      {
        id: 'item-03', label: 'Manage Hosts', icon: null, onClick: () => {}, testId: 'button_manage',
      },
      {
        id: 'item-04', label: 'View subscriptions linked to this page', icon: null, onClick: () => {}, testId: 'button_subscriptions',
      },
    ],
  },
  handleConfigureDomain,
  handlePayInvoice,
  closerPayment,
  handleAccessSite,
  handlePay,
  paymentUrl = '#',
  propagationStatus,
  propagationItemV1 = {},
  displayV2 = false,
  sslStatus,
  sslAnalytics,
  openAutoSSLModal,
  siteSitelock,
  planStatus,
}) => {
  const theme = useContext(ThemeContext);
  const shouldRenderDnsPropagationTag = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG);
  const showSSLModal = useFeatureIsOn(CHARLIE_SSL_MODAL);
  const { sites: sitesLocale, routes } = useLocale();
  const history = useHistory();

  const handlePropagationClick = () => {
    (propagationStatus === PROPAGATION_STATUS_TYPE.INTERNAL_NOT_CONFIGURED && handleConfigureDomain) && handleConfigureDomain();
  };

  const financialAlertProps = () => {
    const alertVariant = STATUS_ALERTS_VARIANTS.financial[financialStatus];

    const defaultProps = {
      status: financialStatus,
      type: 'financial',
      alertVariant,
      customIcon: alertVariant === 'warning' && 'IconDanger',
      handleClick: handlePayInvoice,
      daysForPayment: closerPayment,
    };

    return defaultProps;
  };

  const propagationAlertProps = () => {
    const alertVariant = STATUS_ALERTS_VARIANTS.propagation[propagationStatus];

    const defaultProps = {
      status: propagationStatus,
      type: 'propagation',
      alertVariant,
      customIcon: alertVariant === 'warning' && 'IconDanger',
      handleClick: handlePropagationClick,
    };

    return defaultProps;
  };

  const SSLAlertProps = () => {
    const alertVariant = STATUS_ALERTS_VARIANTS.ssl[sslStatus];

    const defaultProps = {
      status: sslStatus,
      type: 'ssl',
      alertVariant,
      sslAnalytics,
      customIcon: alertVariant === 'warning' && 'IconDanger',
      handleClick: openAutoSSLModal,
    };

    return defaultProps;
  };

  const SitelockAlertProps = () => {
    const sitelockStatus = siteSitelock && siteSitelock.status;
    const alertVariant = STATUS_ALERTS_VARIANTS.sitelock[sitelockStatus];

    const defaultProps = {
      status: sitelockStatus,
      type: 'sitelock',
      alertVariant,
      handleClick: () => history.push(routes.sitelock),
    };

    return defaultProps;
  };

  const siteActions = actions.map(action => (
    <Button
      key={`${id}_${action.testId}`}
      label={action.label}
      variant="secondaryPillOutlined"
      onClick={action.onClick}
      testId={action.testId}
      size="small"
    />
  ));

  const siteItemV1 = (
    <Styles.Wrapper data-testid={testId}>
      <Styles.LinkContainer>
        <Styles.SiteContainer>
          <IconNetwork color={theme.palette.grey.dark} size={14} />
          <Link href={`http://${url}`} text={url} target="_blank" rel="noreferrer" testId="site-item-link" onClick={handleAccessSite} />
        </Styles.SiteContainer>
        <Styles.DnsPropagationTagWrapper>
          {shouldRenderDnsPropagationTag && propagationItemV1 && <PropagationStatusLabel domain={propagationItemV1.domain} />}
        </Styles.DnsPropagationTagWrapper>
        {warnings.label !== ''
        && (
          <>
            {warnings.sublabel
            && (
              <Styles.SublabelContainer>
                <Tooltip width="383" variant="dark" text={warnings.subLabelTooltip} fixedDesktopPosition="top" fixedMobilePosition="top">
                  <CommonTag testId="site-status" bgColor={theme.palette.error.main} label={warnings.subLabelContent} />
                </Tooltip>
              </Styles.SublabelContainer>
            )}
            <Tooltip width="383" variant="dark" text={warnings.tooltip} fixedDesktopPosition="top" fixedMobilePosition="top">
              <Message testId="site-message" Icon={<IconError color={warnings.icon} size="14" />} iconColor={warnings.icon} variant="altWarning" borderColor="none" text={warnings.label} />
            </Tooltip>
            {warnings.payButton !== '' && (
              <Styles.PayButtonContainer>
                <Link href={paymentUrl} text={warnings.payButton} target="_blank" onClick={handlePay} />
              </Styles.PayButtonContainer>
            )}
          </>
        )}
      </Styles.LinkContainer>
      <Styles.SiteActions data-testid="site-actions">
        {siteActions}
      </Styles.SiteActions>
      {dropdownParams.list.length > 0 && (
        <Styles.DropdownButtonWrapper>
          <Dropdown
            align="left"
            items={dropdownParams}
            size="medium"
            testId="dropdown"
            ellipsis
            variantList="secondary"
            itemVariant="secondary"
          />
        </Styles.DropdownButtonWrapper>
      )}
    </Styles.Wrapper>
  );

  const siteItemV2 = (
    <Styles.SiteItemWrapper data-testid={testId}>
      <Styles.ItemContainer hasActions={actions.length}>
        <Styles.SiteContainer>
          <Styles.SiteTitle>
            <IconSites color="lowPure" size="sm" />
            <Link href={`http://${url}`} text={url} target="_blank" rel="noreferrer" testId="site-item-link" onClick={handleAccessSite} />
          </Styles.SiteTitle>
          {financialStatus === STATUS_TYPE.SUSPENDED && (
            <Styles.SiteTag hasTag={financialStatus === STATUS_TYPE.SUSPENDED}>
              <Tag label={sitesLocale.page.alerts.financial.isSuspended.subLabel} variant="problem" positionStatic />
            </Styles.SiteTag>
          )}
        </Styles.SiteContainer>

        <Styles.SiteAlertsContainer>
          {financialStatus !== STATUS_TYPE.ACTIVE && (
            <SiteAlert {...financialAlertProps()} />
          )}
          {!!propagationStatus && (
            <SiteAlert {...propagationAlertProps()} />
          )}
          {!!sslStatus && (
            <SiteAlert {...SSLAlertProps()} showSSLModal={showSSLModal} />
          )}
          {!!siteSitelock && isBRServer && !(siteSitelock.plan === SITELOCK_PLANS.FREE && (planStatus === 'cancelled' || planStatus === 'terminated')) && (
            <SiteAlert {...SitelockAlertProps()} />
          )}
        </Styles.SiteAlertsContainer>
      </Styles.ItemContainer>
      <Styles.SiteActions data-testid="site-actions">
        {siteActions}
      </Styles.SiteActions>
      {dropdownParams.list.length > 0 && (
        <Styles.DropdownButtonWrapper>
          <Dropdown
            align="left"
            items={dropdownParams}
            size="medium"
            testId="dropdown"
            ellipsis
            variantList="secondary"
            itemVariant="secondary"
          />
        </Styles.DropdownButtonWrapper>
      )}
    </Styles.SiteItemWrapper>
  );

  return displayV2 ? siteItemV2 : siteItemV1;
};

export default SiteItem;
