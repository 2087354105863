const styles = theme => ({
  container: {
    borderRadius: 0,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '& .cardPayButton': {
      textTransform: 'uppercase',
      textDecoration: 'none',
      textAlign: 'right',
      color: theme.color.indigo,
      fontWeight: '400',
      whiteSpace: 'nowrap',
      '&:disabled': {
        color: theme.color.disabled,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.color.chambray,
        textDecoration: 'underline',
      },
      [theme.breakpoints.down(960)]: {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: '-12px',
      },
    },
  },
  paid: {
    borderLeft: `5px solid ${theme.color.oceanGreen}`,
    '& .status-icon': {
      color: theme.color.oceanGreen,
    },
    '& .warning-payment': {
      borderColor: theme.color.oceanGreen,
    },
    '& .status-label': {
      backgroundColor: theme.color.oceanGreen,
    },
  },
  unpaid: {
    borderLeft: `5px solid ${theme.color.brightSun}`,
    '& .status-icon': {
      color: theme.color.brightSun,
    },
    '& .warning-payment': {
      borderColor: theme.color.brightSun,
    },
    '& .status-label': {
      backgroundColor: theme.color.brightSun,
    },
  },
  unpaidExpired: {
    borderLeft: `5px solid ${theme.color.valencia}`,
    '& .status-icon': {
      color: theme.color.valencia,
    },
    '& .warning-payment': {
      borderColor: theme.color.valencia,
    },
    '& .status-label': {
      backgroundColor: theme.color.valencia,
    },
  },
  paymentPending: {
    borderLeft: `5px solid ${theme.color.coral}`,
    '& .status-icon': {
      color: theme.color.coral,
    },
    '& .warning-payment': {
      borderColor: theme.color.coral,
    },
    '& .status-label': {
      backgroundColor: theme.color.coral,
    },
  },
  cancelled: {
    borderLeft: `5px solid ${theme.color.geyser}`,
    '& .status-icon': {
      color: theme.color.geyser,
    },
    '& .warning-payment': {
      borderColor: theme.color.geyser,
    },
    '& .status-label': {
      backgroundColor: theme.color.geyser,
    },
  },
  refunded: {
    borderLeft: `5px solid ${theme.color.geyser}`,
    '& .status-icon': {
      color: theme.color.geyser,
    },
    '& .warning-payment': {
      borderColor: theme.color.geyser,
    },
    '& .status-label': {
      backgroundColor: theme.color.geyser,
    },
  },
});

export default styles;
