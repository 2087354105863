/**
 * sort alphabetic array of objects by property key
 *
 * @param {Array} arr
 * @param {String} key
 */

export const sortAlphabetic = (arr, key) => arr.sort((a, b) => {
  if (b[key] > a[key]) {
    return -1;
  }
  return 1;
});

export const sortAlphabeticProductGroupsByDomain = arr => arr.sort((a, b) => {
  if (b.domain.domain > a.domain.domain) {
    return -1;
  }
  return 1;
});
