import { defaultTheme, defaultThemeV2 } from '../themes';

export const palette = {
  primary: {
    main: '#4480C5',
    light: '#458bc5',
    dark: '#2E578B',
    darkest: '#1F2044',
    contrastText: '#EBEFF3',
  },
  secondary: {
    main: '#609BE0',
    primary: '#2E93EE',
    dark: '#274B77',
    darkest: '#284B77',
    contrastText: '#EBEFF3',
  },
  white: {
    dark: '#F1F1F1',
    light: '#FFFFFF',
    opacity: '#ABBCD0',
    polar: '#F7FAFD',
  },
  black: {
    dark: '#000000',
  },
  dark: '#363636',
  grey: {
    main: '#A6B6CC',
    lightbluish: '#CDD8E0',
    darklight: '#707070',
    dark: '#4A4A4A',
    ultradark: '#363636',
    light: '#959EA3',
    primary: '#CDD8DF',
    ultralight: '#F1F3F6',
    variant: '#E6EBEF',
    variantlight: '#F7F9FA',
    opacityLight: '#F4F8FB',
  },
  green: {
    light: '#35CF8B',
    main: '#30B47A',
    dark: '#2CA26E',
  },
  success: {
    primary: '#40B186',
  },
  warning: {
    primary: '#FACE3B',
  },
  danger: {
    primary: '#F68D4F',
    dark: '#FF8D4E',
  },
  error: {
    main: '#D94445',
    primary: '#D94445',
    ultralight: '#FDF5F6',
  },
  yellow: {
    dark: '#E0B835',
    light: '#FFE36A',
    main: '#FBCE3B',
    mustard: '#FFD54C',
    opacity: '#FFFDF5',
  },
  blue: {
    ultralight: '#C9E3FA',
    light: '#F2F8FC',
    dark: '#1D5297',
    darkest: '#123A6E',
    main: '#4EB0FF',
  },
};

export const color = {
  alto: '#DBDBDB',
  astronaut: '#274b77',
  black: '#000000',
  brightSun: '#FBCE3B',
  brightSunLight: '#FFFAEB',
  brownRust: '#AC5F34',
  casper: '#ABBCD0',
  chambray: '#2E578B',
  concrete: '#F3F3F3',
  coral: '#FF8D4E',
  cyanCobaltBlue: '#1D5297',
  darkSilver: '#B8B8B8',
  disabled: 'rgba(0,0,0,0.26)',
  doveGray: '#717171',
  dustyGgray: '#949494',
  emperor: '#555555',
  energyYellow: '#F8D256;',
  gallery: 'rgba(237, 237, 237, 0.39)',
  geyser: '#CDD8DF',
  gray: '#8B8B8B',
  grayContour: 'rgba(0,0,0,0.4)',
  grayLight: '#d9e1e6',
  grayVeryLight: '#D2DAE5',
  greyDark: '#4A4A4A',
  halfBaked: '#8cb7db',
  havelockBlue: '#609BE0',
  indigo: '#4480C5',
  vividBlue: '#3C97FF',
  indigoLight: '#EFF5FC',
  lightBlue: '#F2F8FC',
  simpleLightBlue: '#F2F7FA',
  lavender: '#EEEEF6',
  lightShade: '#cdd8df',
  loblolly: '#C5D1D7',
  mercury: '#E1E1E1',
  mustard: '#FFD54C',
  mystic: '#EBEFF3',
  oceanGreen: '#40B186',
  darkOceanGreen: '#269062',
  porcelain: '#E9EBED',
  regentGray: '#959EA3',
  shadowBlue: '#4480C580',
  shamrockGreen: '#35CF8B',
  silver: 'rgba(184, 184, 184, 0.30)',
  silverChalice: '#A5A5A5',
  skyHover: '#4480c508',
  solitude: '#E5EAEE',
  tacao: '#EFAA75',
  tango: '#F67921',
  tropicalBlue: '#C2DDF4',
  tundora: '#4A4A4A',
  tundoraLight: '#00000029',
  valencia: '#D94445',
  purpleDark: '#181936',
  white: '#FFFFFF',
  whiteSmoke: '#EFEFEF',
  wildSand: '#F6F6F6',
  weakBlue: '#F5F9FC',
  pendingPure: '#FF9442',
};

export const shadows = {
  grey: {
    light: 'rgba(0, 0, 0, 0.16)',
    dark: 'rgba(0, 0, 0, 0.05)',
    darkest: 'rgba(0, 0, 0, 0.5)',
  },
};

export const font = {
  sizes: {
    xxxxs: '10px',
    xxxs: '11px',
    xxs: '12px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '22px',
    xxl: '24px',
    xxxl: '26px',
    xxxxl: '28px',
    xxxxxl: '30px',
    xxxxxxl: '32px',
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    xxxxs: '10px',
    xxxs: '11px',
    xxs: '12px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '22px',
    xxl: '24px',
    xxxl: '26px',
    xxxxl: '28px',
    xxxxxl: '30px',
    xxxxxxl: '32px',
    xxxxxxxl: '34px',
    xxxxxxxxl: '36px',
    xxxxxxxxxl: '38px',
    xxxxxxxxxxl: '40px',
  },
  family: {
    primary: "'Roboto',sans-serif,'Helvetica Neue',sans-serif",
    secondary: "'Galano', 'Roboto',sans-serif,'Helvetica Neue',sans-serif",
  },
};

export const typography = {
  useNextVariants: true,
  body1: {
    color: color.tundora,
  },
  body2: {
    color: color.tundora,
  },
  subtitle1: {
    color: color.tundora,
  },
  subtitle2: {
    color: color.tundora,
  },
  h1: {
    color: color.tundora,
  },
  h2: {
    color: color.tundora,
  },
  h3: {
    color: color.tundora,
  },
  h4: {
    color: color.tundora,
  },
  h5: {
    color: color.tundora,
  },
  h6: {
    color: color.tundora,
  },
};

export const layers = {
  base: 0,
  header: 20,
  overlay: 30,
  modal: 40,
  awaysOnTop: 50,
  important: 99999,
};

export const breakpoints = {
  xs: '360px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

export const v1 = defaultTheme;
export const v2 = defaultThemeV2;
