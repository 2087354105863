import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.contrastText};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding: 24px;

    @media (max-width: 768px){
      margin: 0px 0px 24px 0px;
    }
  `}
`;

export const TotalLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.lg};
    margin: 0;
  `}
`;

export const Amount = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.xxl};
    margin: 8px 0px auto;
  `}
`;
