import React from 'react';
import * as Style from './IconLock.style';

const IconLock = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-lock">
    <svg id="Grupo_1172" data-name="Grupo 1172" xmlns="http://www.w3.org/2000/svg" fill={color} width="24" height="24" viewBox="0 0 24 24">
      <path id="Caminho_44" data-name="Caminho 44" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_45" data-name="Caminho 45" d="M18,8H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8Zm-6,9a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,17Zm3.1-9H8.9V6a3.1,3.1,0,0,1,6.2,0Z" />
    </svg>
  </Style.Wrapper>
);


export default IconLock;
