import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, showEmbed, smallEmbed }) => css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    position: fixed;
    right: 62px;
    bottom: 140px;
    z-index: 130;

    @media (max-width: ${theme.v2.breakpoints.lg}){
      right: ${showEmbed && '30px'};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      gap: 0;
      top: ${smallEmbed ? 'auto' : 0};
      bottom: ${smallEmbed ? '80px' : 'auto'};
      right: 0;
    }
    

  `}
`;

export const ClassButtonWrapper = styled.div`
  ${({ theme, asBanner }) => {
    const variant = {
      true: {
        position: 'unset',
        width: '100%',
        borderRadius: '4px',
        justifyContent: 'flex-start',
        marginBottom: '8px',
        backgroundColor: theme.v2.colors.brand.menuPrimaryDark,
        minHeight: '88px',
        minWidth: '100%',
        maxWidth: 'unset',
      },
      false: {
        position: 'fixed',
        width: 'fit-content',
        borderRadius: '50vh',
        justifyContent: 'flex-start',
        marginBottom: '0px',
        backgroundColor: theme.v2.colors.brand.menuPrimaryDark,
        minWidth: 'unset',
        maxWidth: '141px',
      },
    };


    return css`
    bottom: 44px;
    position: ${variant[asBanner].position};
    right: 210px;
    z-index: 1000;

    //it's a POC, some standards will be disrespected for now
    > button {
      background-color: ${variant[asBanner].backgroundColor};
      font-size: ${theme.v2.font.sizes.xxs};
      font-weight: ${theme.v2.font.weights.medium};
      padding-right: 35px;
      padding-left: 12px;
      padding-top: 9px;
      padding-bottom: 9px;
      border-radius: ${variant[asBanner].borderRadius};
      min-height: ${variant[asBanner].minHeight};
      width: ${variant[asBanner].width};
      justify-content: ${variant[asBanner].justifyContent};
      margin-bottom: ${variant[asBanner].marginBottom};
      gap: 8px;

      strong {
        font-weight: ${theme.v2.font.weights.bold};
      }

      ${asBanner && css`
        &:hover {
          background-color: ${variant[asBanner].backgroundColor};
        }
      `}
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      bottom: 87px;
      max-width: ${variant[asBanner].maxWidth};
      right: 12px; 
      min-width: ${variant[asBanner].minWidth};

      > button {
        display: flex;
        flex-wrap: wrap;
        font-size: ${theme.v2.font.sizes.xxxs};
        font-weight: ${theme.v2.font.weights.medium};
        justify-content: start;
        border-radius: 8px;
        padding: 8px;
        text-align: start;
        gap: 0;
      }
    }
  `;
  }}
`;

export const CloseButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 58px;
    height: 25px;
    position: fixed;
    right: 218px;
    width: 25px;
    z-index: 1000;

    //it's a POC, some standards will be disrespected for now
    button {
      border-radius: 50%;
    }

    svg {
      fill: ${theme.v2.colors.neutral.highPure};

      &:hover {
        fill: ${theme.v2.colors.neutral.highPure};
      }
    }

    &:hover {
      background-color ${theme.v2.colors.brand.primaryBlue};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      bottom: 130px;
      max-width: 141px;
      right: 15px; 
    }
  `}
`;

export const Frame = styled.div`
  ${({ theme, isChat, smallEmbed }) => css`
  iframe {
    height: 520px;
    width: ${isChat ? '380px' : '916px'}; 
  }
  
    @media (max-width: 1400px){
      iframe {
        height: 400px;
        width: ${isChat ? '300px' : '800px'}; 
      }
    }

    iframe {
      height: ${smallEmbed && '240px'}; 
      width: ${smallEmbed && '420px'}; 
    }

    @media (max-width: 1170px){
      margin-bottom: -4px;
      width: 100%;

      iframe {
        height: ${isChat ? '320px' : '300px'};
        width: 100%;
      }
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin-bottom: -4px;
      width: 100%;

      iframe {
        height: ${isChat ? '420px' : '200px'};
        width: 100%;
      }
    }
  `}
`;

export const ClassTag = styled.div`
  ${({ theme, isLive }) => css`
    align-items: center;
    background-color: ${isLive ? theme.v2.colors.feedback.problemLight : theme.v2.colors.feedback.attentionPure};
    border-radius: 15px;
    color: ${isLive ? theme.v2.colors.neutral.highPure : theme.v2.colors.brand.menuPrimaryDark};
    display: flex;
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    gap: 4px;
    min-height: 32px;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    word-break: break-word;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      font-size: ${theme.v2.font.sizes.xxxs};
      margin-bottom: 2px;
    }
  `}
`;

const opacityAnimation = keyframes`
  0% {
    opacity: 0.0;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  70% {
    opacity: 0.0;
    transform: translate(-50%, -50%) scale(4);
  }
  to {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.0;
  }
`;

export const Pulse = styled.i`
  ${({ theme }) => css`
    animation: ${opacityAnimation} 1s linear;
    background-color: ${theme.v2.colors.neutral.highPure};
    border-radius: 100%;
    color: transparent;
    display: inline-block;
    flex: 0 0 auto;
    height: 8px;
    margin: 0 6px;
    position: relative;
    vertical-align: 1px;
    width: 8px;

    &:before {
      animation: ${pulseAnimation} 2s infinite;
      border: 2px solid ${theme.v2.colors.neutral.highPure};
      border-radius: 100%;
      content: "";
      height: 100%;
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%) scale(4);
      width: 100%;
    }
  `}
`;


export const EmbedCloseButton = styled.div`
  ${({ theme, smallEmbed }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryMedium};
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    position: fixed;
    right: 62px;
    bottom: ${smallEmbed ? '400px' : '700px'};
    width: 32px;
    z-index: 1000;

    svg {
      fill: ${theme.v2.colors.neutral.highPure};

      &:hover {
        fill: ${theme.v2.colors.neutral.highPure};
      }
    }

    @media (max-width: 2000px){
      bottom: ${smallEmbed ? '400px' : '680px'};
      right: ${!smallEmbed && '62px'}
    }

    @media (max-width: 1400px){
      bottom: ${smallEmbed ? '400px' : '560px'};
      right: ${!smallEmbed && '62px'}
    }

    @media (max-width: 1170px){
      bottom: ${smallEmbed ? '456px' : '792px'};  
      right: ${!smallEmbed && '30px'}
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      bottom: ${smallEmbed ? '40px' : '185px'};
      right: ${!smallEmbed && '25px'}
    }
  `}
`;

export const EmbedMinimizeButton = styled.div`
  ${({ theme, smallEmbed }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryMedium};
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    position: fixed;
    right: 110px;
    bottom: ${smallEmbed ? '400px' : '700px'};
    width: 32px;
    z-index: 1000;

    svg {
      fill: ${theme.v2.colors.neutral.highPure};

      &:hover {
        fill: ${theme.v2.colors.neutral.highPure};
      }
    }

    @media (max-width: 2000px){
      bottom: ${smallEmbed ? '400px' : '680px'};   
      right: ${!smallEmbed && '110px'}
    }

    @media (max-width: 1400px){
      bottom: ${smallEmbed ? '400px' : '560px'};  
      right: ${!smallEmbed && '110px'}
    }

    @media (max-width: 1170px){
      bottom: ${smallEmbed ? '456px' : '792px'};       
      right: ${!smallEmbed && '78px'}
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      bottom: ${smallEmbed ? '40px' : '185px'};
      right: ${!smallEmbed && '78px'}
    }
  `}
`;

export const BannerCollumn = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0px;

    strong {
      font-size: ${theme.v2.font.sizes.sm};
    }

    span {
      font-size: ${theme.v2.font.sizes.xs};
    }

    span > strong {
      font-size: ${theme.v2.font.sizes.xs};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin-top: 8px;
    }
  `}
`;

export const BannerCollumnDaily = styled.div`
  ${({ theme, fitContent }) => css`
    display: block;
    font-size: ${theme.v2.font.sizes.xs};
    gap: 0px;
    margin-left: auto;
    max-width: ${fitContent ? 'fit-content' : '198px'};
    text-align: justify;

    > button {
      background-color: ${theme.v2.colors.brand.primaryMedium};
    }
    
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin-left: unset;
      margin-top: 16px;
      max-width: unset;
    }
  `}
`;

export const VimeoWrapper = styled.div`  
  ${({ smallEmbed }) => css`
    padding: 177.78% 0 0 0;
    position: relative;
    width: ${smallEmbed ? '180px' : '360px'};
  `}
`;

export const VimeoFrame = styled.iframe`  
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;
