import { CALL_API } from '@/middleware/api';
import { SURVEY_URL } from '@/config/api';
import {
  RECEIVE_SURVEY, REQUEST_SURVEY, ERROR_SURVEY,
} from './actionsTypes';

const saveSurvey = data => ({
  type: RECEIVE_SURVEY,
  data,
});

const receiveSurvey = ({ status }) => {
  if (status === 200) {
    return ({
      type: RECEIVE_SURVEY,
      data: {
        save: true,
      },
    });
  }
};

const errorSurvey = ({ status }) => {
  if (status === 404) {
    return ({
      type: RECEIVE_SURVEY,
      data: { save: false },
    });
  }

  return ({ type: ERROR_SURVEY });
};

const loadSurvey = (surveyId, userId) => ({
  [CALL_API]: {
    method: 'GET',
    statusCode: true,
    enableBrand: false,
    endpoint: SURVEY_URL(surveyId, userId),
    actionTypes: {
      request: () => ({ type: REQUEST_SURVEY }),
      success: data => receiveSurvey(data),
      error: response => errorSurvey(response),
    },
  },
});

export const surveyActions = {
  saveSurvey,
  loadSurvey,
};
