import {
  Button, Checkbox, IconData, IconLoading,
} from 'gatorcomponents';
import * as Styles from './RestorateSiteBuilderAccess.styles';
import React, { useState } from 'react';
import useLocale from '@/hooks/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const RestorateSiteBuilderAccess = ({
  submitForm,
  selectedProduct,
  opening,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { restorateSiteBuilderAccess: restorateSiteBuilderAccessLocale } = ticketsLocale.forms;
  const [chargeAgreed, setChargeAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.name,
      subject: restorateSiteBuilderAccessLocale.subject,
      key: V2Forms.RESTORATE_SITEBUILDER_ACCESS,
      tags: ['bkp-builder'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.restorateSiteBuilderAccess,
      },
      body: `
        ${restorateSiteBuilderAccessLocale.bodyProduct(selectedProduct)}
        ${restorateSiteBuilderAccessLocale.bodyRequest(restorateSiteBuilderAccessLocale.subject)}
        ${restorateSiteBuilderAccessLocale.bodyAgreedCharge(chargeAgreed)}
        ${restorateSiteBuilderAccessLocale.bodyAgreedTerms(termsAgreed)}
      `,
    };

    submitForm && submitForm(data);
  };

  const checkForm = () => {
    const isOk = chargeAgreed && termsAgreed;

    return !isOk;
  };

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {restorateSiteBuilderAccessLocale.terms}
      </Styles.Info>

      <Styles.Info>
        {restorateSiteBuilderAccessLocale.termsItems}
      </Styles.Info>

      <Styles.Title>
        {ticketsLocale.forms.internalMigration.title}
      </Styles.Title>

      <Styles.CheckboxWrapper>
        <Styles.CheckboxLabel
          htmlFor="#chargeAgreed"
          data-testid="testChargeAgreed"
          onClick={() => setChargeAgreed(!chargeAgreed)}
        >
          <Checkbox checked={chargeAgreed} id="chargeAgreed" />
          <Styles.Text>
            {restorateSiteBuilderAccessLocale.chargeAgreed}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#termsAgreed"
          data-testid="testTermsAgreed"
          onClick={() => setTermsAgreed(!termsAgreed)}
        >
          <Checkbox checked={termsAgreed} id="readTerms" />
          <Styles.Text>
            {restorateSiteBuilderAccessLocale.termsAgreed}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.CheckboxWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default RestorateSiteBuilderAccess;
