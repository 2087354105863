import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0px 40px;

    a {
      color: ${theme.v2.colors.brand.primaryBlue};
      font-weight: ${theme.v2.font.weights.bold};
      text-decoration: underline;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin: 0px 0px;
    }
  `}
`;
