import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction:column;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    align-self: flex-start;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    letter-spacing: 0px;
    line-height: 32px;
    margin: 32px 0px 0px 32px;
  `}
`;
