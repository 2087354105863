import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Modal } from '@/pages/common/Modal';
import { IconCheck } from '@/icons';
import { formatDate } from '@/utils/Formatters/formatDate';
import * as Styles from './SubscriptionModal.styles';
import { Button } from '@/pages/common/v1';

const SubscriptionModal = ({
  displayModal = false,
  modalContent,
  onClose,
  date = '2021-05-09',
  testId = 'subscription-modal',
  handleActivateSubscription,
  subscription,
}) => {
  const theme = useContext(ThemeContext);

  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnActivateRenew = () => {
    if (handleActivateSubscription) {
      handleActivateSubscription(subscription);
      handleOnClose();
    }
  };

  return (
    displayModal && (
      <Modal onClose={handleOnClose} testId={testId} variant="regular" maxWidth={550}>
        <Styles.IconWrapper>
          <IconCheck color={theme.palette.white.light} size={40} />
        </Styles.IconWrapper>

        <Styles.ContentWrapper>
          <Styles.ModalTitle>{modalContent.title}</Styles.ModalTitle>
          <Styles.ModalText>{`${modalContent.firstLinecontent} ${formatDate(date)}.`}</Styles.ModalText>
          <Styles.ModalText>{modalContent.secondLinecontent}</Styles.ModalText>
        </Styles.ContentWrapper>

        <Styles.FooterWrapper>
          <Button label={modalContent.activateRenew} onClick={handleOnActivateRenew} testId={`${testId}-renew-button`} />
          <Button variant="text" align="center" label={modalContent.close} onClick={handleOnClose} testId={`${testId}-close-button`} />
        </Styles.FooterWrapper>
      </Modal>
    )
  );
};

export default SubscriptionModal;
