import { useMemo } from 'react';
import { rules } from './useMountNotificationModal.rules';
import { MODAL_TYPE } from './useMountNotificationModal.enum';

const useMountNotificationModal = ({ sites, enabledEcommercePlusModalToWizard }) => {
  const getModalType = (filteredPlans) => {
    if (filteredPlans.filteredSitesPlanCanceled.length) {
      return MODAL_TYPE.PLANCANCELED;
    }
    if (filteredPlans.filteredSitesDomainCanceled.length) {
      return MODAL_TYPE.DOMAINCANCELED;
    }
    if (filteredPlans.filteredNewEcommercePlus.length && enabledEcommercePlusModalToWizard) {
      return MODAL_TYPE.ECOMMERCEPLUS;
    }
    if (filteredPlans.filteredNewSites.length) {
      return MODAL_TYPE.NEWSITE;
    }
    if (!filteredPlans.filteredNewSites.length) {
      return MODAL_TYPE.SPECIALIST;
    }
    return null;
  };
  const mountMessage = useMemo(() => {
    const filteredPlans = {
      filteredSitesPlanCanceled: sites.filter(site => site.show_plan_canceled_warning === true && site.is_main_domain === true),
      filteredSitesDomainCanceled: sites.filter(site => site.show_domain_canceled_warning === true),
      filteredNewSites: sites.filter(site => site.show_new_site_warning === true && site.status_plan === 'active'),
      filteredNewEcommercePlus: sites.filter(site => site.show_ecommerce_plus === true && site.status_plan === 'active'),
      specialist: sites.filter(site => site.show_new_site_warning === true && site.status_plan === 'active'),
    };

    const modalType = getModalType(filteredPlans);
    const modalInfo = rules[modalType](filteredPlans);

    return modalInfo;
  }, [sites]);
  return mountMessage;
};

export default useMountNotificationModal;
