import React from 'react';
import DetailsSection from './components/DetailsSection/DetailsSection';
import { DiscoverBenefits } from './components/DiscoverBenefits';
import GetNow from './components/GetNow/GetNow';
import { SnappyBanner } from './components/SnappyBanner';

const SiteLockLP = ({
  paymentCycles,
  paymentMethods,
  domains,
  setSelectedDomain,
  title,
  description,
  listTitle,
  featureList,
  formTitle,
  formDescription,
  formFirstToggleLabel,
  formSecondToggleLabel,
  formSecondTogglePlaceholder,
  cycleLabel,
  handleOnToggle,
  activeDomainToggle,
  selectedDomain,
  typedDomain,
  setTypedDomain,
  setSelectedCycle,
  selectedCycle,
  selectePaymentLabel,
  activeCycle,
  disabledButton,
  toBePaid,
  hgSiteLock,
  getDomain,
  isLoadingPayment,
  setSelectedPaymentMethod,
  needMoreLabel,
  needMoreButtonText,
  detailsSectionTitle,
  detailsSectionFirstSectionTitle,
  detailsSectionFirstSectionDescription,
  detailsSectionFirstSectionButtonLabel,
  detailsSectionSecondSectionTitle,
  detailsSectionSecondSectionDescription,
  detailsSectionSecondSectionButtonLabel,
  discoverBenefitsTitle,
  discoverBenefitsBenefits,
  discoverBenefitsCtaTitle,
  discoverBenefitsCtaDescription,
  discoverBenefitsButtonLabel,
  bannerTitle,
  bannerLabel,
  gaSelectDomain,
  gaTypeDomain,
  gaCyclePaymentClick,
  gaSelectPaymentMethod,
  gaBuySitelock,
  gaSeeAllPlans,
  gaProtectMySite,
  gaWantMoreProtection,
  gaGetNowAnchor,
}) => {
  const getNowProps = () => {
    const defaultProps = {
      paymentCycles,
      paymentMethods,
      domains,
      setSelectedDomain,
      title,
      description,
      listTitle,
      featureList,
      formTitle,
      formDescription,
      formFirstToggleLabel,
      formSecondToggleLabel,
      formSecondTogglePlaceholder,
      cycleLabel,
      handleOnToggle,
      activeDomainToggle,
      selectedDomain,
      typedDomain,
      setTypedDomain,
      setSelectedCycle,
      selectedCycle,
      selectePaymentLabel,
      activeCycle,
      disabledButton,
      toBePaid,
      hgSiteLock,
      getDomain,
      isLoadingPayment,
      setSelectedPaymentMethod,
      needMoreLabel,
      needMoreButtonText,
      detailsSectionTitle,
      detailsSectionFirstSectionTitle,
      detailsSectionFirstSectionDescription,
      detailsSectionFirstSectionButtonLabel,
      detailsSectionSecondSectionTitle,
      detailsSectionSecondSectionDescription,
      detailsSectionSecondSectionButtonLabel,
      gaSelectDomain,
      gaTypeDomain,
      gaCyclePaymentClick,
      gaSelectPaymentMethod,
      gaBuySitelock,
      gaSeeAllPlans,
    };

    return defaultProps;
  };

  const getDetailsSectionProps = () => {
    const defaultProps = {
      title: detailsSectionTitle,
      firstSectionTitle: detailsSectionFirstSectionTitle,
      firstSectionDescription: detailsSectionFirstSectionDescription,
      firstSectionButtonLabel: detailsSectionFirstSectionButtonLabel,
      secondSectionTitle: detailsSectionSecondSectionTitle,
      secondSectionDescription: detailsSectionSecondSectionDescription,
      secondSectionButtonLabel: detailsSectionSecondSectionButtonLabel,
      gaProtectMySite,
    };

    return defaultProps;
  };

  const getDiscoverBenefitsProps = () => {
    const defaultProps = {
      title: discoverBenefitsTitle,
      benefits: discoverBenefitsBenefits,
      ctaTitle: discoverBenefitsCtaTitle,
      ctaDescription: discoverBenefitsCtaDescription,
      buttonLabel: discoverBenefitsButtonLabel,
      gaWantMoreProtection,
    };

    return defaultProps;
  };

  const getBannerProps = () => {
    const defaultProps = {
      title: bannerTitle,
      buttonLabel: bannerLabel,
      gaGetNowAnchor,
    };

    return defaultProps;
  };

  return (
    <>
      <GetNow {...getNowProps()} />
      <DetailsSection {...getDetailsSectionProps()} />
      <DiscoverBenefits {...getDiscoverBenefitsProps()} />
      <SnappyBanner {...getBannerProps()} />
    </>
  );
};

export default SiteLockLP;
