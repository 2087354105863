import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.palette.success.primary};
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    margin-top: 24px;
    width: 56px;
  `}
`;

export const ContentWrapper = styled.div`
  align-self: center;
  margin-top: 40px;
`;

export const ModalTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 24px;
    letter-spacing: 0.01px;
    line-height: 32px;
    margin: 0px 0px 16px 0px;
    text-align: center;
  `}
`;

export const ModalText = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 16px;
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 0;
    text-align: center;
  `}
`;

export const FooterWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 38px 0px 0px;
  
  & button {
    width: 100%;
  }
  
  & button:first-child {
    margin-bottom: 6px;
  }
`;
