import styled, { css } from 'styled-components';

export const Materials = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    @media (max-width: ${theme.v1.breakpoints.md}){
      align-items: center;
    }
  `}
`;

export const MaterialsTitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 14px;
  margin-top: 24px;
`;

export const MaterialTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    line-height: 21px;
    margin: 0px;
    max-width: 429px;
    width: 100%;
    @media (max-width: ${theme.v1.breakpoints.md}){
      font-size: 14px;
      width: 208px;
    }
  `}
`;

export const MaterialsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  margin: 0px;
  padding: 0px;
`;

export const Material = styled.li`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 19px;
    & > a {
      color: ${theme.v1.color.action.primary};
      text-decoration-color: transparent;
      transition: text-decoration-color 150ms ease-in-out;
    }
    & > a:hover {
      color: ${theme.v1.color.action.primary};
      text-decoration-color: ${theme.v1.color.action.primary};
    }
  `}
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 152px;
`;
