import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'gatorcomponents';
import * as Styles from './AlertMessage.styles';


const AlertMessage = () => {
  const alerts = useSelector(state => state.alerts.data.filter((elem, index) => index === 0));

  if (alerts.length === 0) {
    return null;
  }

  return (
    <Styles.Container data-testid="alert-message-container">
      {alerts.map((alert) => {
        const variants = {
          Critical: 'error',
          Important: 'warning',
          Warning: 'warning',
        };

        return (
          <Alert
            width="full"
            variant={variants[alert.severity.level] || 'info'}
            description={alert.message}
            key={alert.alertId}
          />
        );
      })}
    </Styles.Container>
  );
};

export default AlertMessage;
