import {
  SET_AUTH_TOKEN, UNSET_AUTH_TOKEN, SET_VIMEO_TOKEN, SET_JWT_TOKEN, SET_REFRESH_TOKEN,
} from '@/redux/actions/actionsTypes';
import { AuthActionsTypes } from '@/redux/modules/auth/auth.types';
import { config } from '@/config';

export const initialState = {
  token: '',
  jwt: '',
  refreshToken: '',
  vimeoToken: config.VIMEO_TOKEN,
  loadingSendToken: false,
  hasRefreshError: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.token,
      };
    case SET_JWT_TOKEN:
      return {
        ...state,
        jwt: action.token,
      };
    case SET_VIMEO_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case UNSET_AUTH_TOKEN:
      return {
        ...state,
        token: '',
        jwt: '',
        refreshToken: '',
        vimeoToken: '',
      };
    case AuthActionsTypes.SEND_PHONE_TOKEN:
      return {
        ...state,
        loadingSendToken: true,
      };
    case AuthActionsTypes.SEND_PHONE_TOKEN_SUCCESS:
    case AuthActionsTypes.SEND_PHONE_TOKEN_FAILURE:
      return {
        ...state,
        loadingSendToken: false,
      };
    case AuthActionsTypes.SET_JWT_REFRESH_ERROR:
      return {
        ...state,
        hasRefreshError: action.payload.bool,
      };
    default:
      return state;
  }
}
