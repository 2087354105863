import React from 'react';
import * as Styles from './CloudFlareHeader.styles';
import {
  Button, IconRefresh, IconShieldSuccess, TextField,
  Tooltip,
} from 'gatorcomponents';
import PropTypes from 'prop-types';

export const CloudFlareHeader = ({
  hostingLabel = 'Hosting Label',
  changeCta = 'change cta',
  cloudflareCdnExplanation,
  searchPerDomainLabel = 'search per domain label',
  refreshStatusLabel,
  noOpenStatusLabel,
  waitRefreshTimeLimitLabel,
  handleRefreshStatus,
  setLastRefreshOnCookie,
  refreshTimeLimitHasEnded,
  hasOpenStatus,
  cloudflareStatusLoading,
  inputedFilter,
  setInputedFilter,
  currentPlan,
  currentDomain,
  handleChangePlan,
  sites,
}) => {
  const handleClickChangePlan = () => {
    handleChangePlan && handleChangePlan();
  };

  const canRefresh = refreshTimeLimitHasEnded && !cloudflareStatusLoading && hasOpenStatus;
  const refreshTooltipLabel = !hasOpenStatus ? noOpenStatusLabel : waitRefreshTimeLimitLabel;

  const handleClickRefreshStatus = () => {
    const now = Date.now();
    setLastRefreshOnCookie && setLastRefreshOnCookie(now);
    handleRefreshStatus && handleRefreshStatus();
  };

  const handleTypeOnFilter = (e) => {
    setInputedFilter && setInputedFilter(e.target.value);
  };

  const refreshButtonLabel = () => {
    const iconRefreshColor = canRefresh ? 'primaryDark' : 'lowLight';

    return (
      <>
        {refreshStatusLabel}
        <Styles.IconRefreshContainer loading={cloudflareStatusLoading}>
          <IconRefresh color={iconRefreshColor} direction="mirror" />
        </Styles.IconRefreshContainer>
      </>
    );
  };

  return (
    <Styles.Header data-testid="cloudFlareHeader">
      <Styles.Column>
        <Styles.HostingLabel>
          {hostingLabel}
        </Styles.HostingLabel>

        <Styles.ChangePlanWrapper>
          <Styles.PlanName>
            {`${currentPlan} - `}
          </Styles.PlanName>
          <Styles.MainDomain>
            {currentDomain}
          </Styles.MainDomain>

          <Button
            variant="text"
            onClick={handleClickChangePlan}
            label={changeCta}
            disabled={sites.length < 2}
          />
        </Styles.ChangePlanWrapper>

        <Styles.CloudFlareCdnExplanation>
          <IconShieldSuccess color="activeMedium" />
          {cloudflareCdnExplanation}
        </Styles.CloudFlareCdnExplanation>
      </Styles.Column>

      <Styles.RightColumn>
        <Styles.InputWrapper>
          <TextField
            label={searchPerDomainLabel}
            value={inputedFilter}
            onChange={handleTypeOnFilter}
            traillingIcon="IconSearch"
          />
        </Styles.InputWrapper>
        {canRefresh ? (
          <Styles.RefreshButtonContainer>
            <Button
              testId="refresh-button"
              variant="text"
              label={refreshButtonLabel()}
              onClick={handleClickRefreshStatus}
              disabled={!canRefresh}
            />
          </Styles.RefreshButtonContainer>
        ) : (
          <Styles.RefreshButtonContainer>
            <Tooltip preferredPosition="topLeft" content={refreshTooltipLabel}>
              <Button
                testId="refresh-button"
                variant="text"
                label={refreshButtonLabel()}
                onClick={handleClickRefreshStatus}
                disabled={!canRefresh}
              />
            </Tooltip>
          </Styles.RefreshButtonContainer>
        )}
      </Styles.RightColumn>
    </Styles.Header>
  );
};

CloudFlareHeader.propTypes = {
  hostingLabel: PropTypes.string,
  changeCta: PropTypes.string,
  cloudflareCdnExplanation: PropTypes.string,
  searchPerDomainLabel: PropTypes.string,
  refreshStatusLabel: PropTypes.string,
  noOpenStatusLabel: PropTypes.string,
  waitRefreshTimeLimitLabel: PropTypes.string,
  handleRefreshStatus: PropTypes.func,
  setLastRefreshOnCookie: PropTypes.func,
  refreshTimeLimitHasEnded: PropTypes.bool,
  hasOpenStatus: PropTypes.bool,
  cloudflareStatusLoading: PropTypes.bool,
  inputedFilter: PropTypes.string,
  setInputedFilter: PropTypes.func,
  currentPlan: PropTypes.string,
  currentDomain: PropTypes.string,
  handleChangePlan: PropTypes.func,
  sites: PropTypes.array,
};

export default CloudFlareHeader;
