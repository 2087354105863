import * as Styles from './NewPageDescription.styles';
import React, { useContext } from 'react';
import { SmallTriangles } from '@/icons/SmallTriangles';
import { ThemeContext } from 'styled-components';
import SnappyFace from '@/media/pageDescription/snappy-face.svg';
import { Filter } from '@/pages/common';

const NewPageDescription = ({
  testId = 'new-page-description',
  title = 'This screen is dedicated to shared site hosting management.',
  image = SnappyFace,
  customBreakpoint = null,
  filteredOptions,
  setFilteredOptions,
  setFilterTyped,
  clearFilter,
  filterTyped,
  clearLabel,
  searchPlaceholder,
  filterLabel,
  sitesLength,
}) => {
  const theme = useContext(ThemeContext);
  const mobileBreakpoint = '1130px';
  const shouldRenderFilter = sitesLength > 4;

  const renderFilter = () => (
    <Filter
      filteredOptions={filteredOptions}
      setFilteredOptions={setFilteredOptions}
      allowMultipleOptions={false}
      setFilterTyped={setFilterTyped}
      clearFilter={clearFilter}
      filterTyped={filterTyped}
      clearLabel={clearLabel}
      searchPlaceholder={searchPlaceholder}
      clearFilterVariant="action"
      inputMaxLength={63}
      clearButtonSize="small"
      variant="secondary"
      inputIconSide="right"
      testId="filter"
      fontFamily="secondary"
      filterLabel={filterLabel}
      mobileBreakPoint={mobileBreakpoint}
    />
  );
  return (
    <>
      {shouldRenderFilter && (
      <Styles.MobileFilterWrapper data-testid="mobile-filter-wrapper" mobileBreakPoint={mobileBreakpoint}>
        {renderFilter()}
      </Styles.MobileFilterWrapper>
      )}
      <Styles.Wrapper data-testid={testId} customBreakPoint={customBreakpoint}>
        <Styles.TrianglesWrapper>
          <SmallTriangles color={theme.color.mustard} />
        </Styles.TrianglesWrapper>

        <Styles.SnappyImage data-testid="page-description-snappy" src={image} />

        <Styles.Title data-testid="new-page-description-title">{title}</Styles.Title>

        {shouldRenderFilter && (
        <Styles.FilterWrapper data-testid="filter-wrapper" mobileBreakPoint={mobileBreakpoint}>
          {renderFilter()}
        </Styles.FilterWrapper>
        )}
      </Styles.Wrapper>
    </>

  );
};

export default NewPageDescription;
