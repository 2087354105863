import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.palette.success.primary};
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    margin-top: 24px;
    width: 56px;
  `}
`;

export const ContentWrapper = styled.div`
    align-self: center;
    margin-top:9px;
    padding-left:20px;
    padding-right:20px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.primary.darkest};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    opacity: 1;
    text-align: left;
  `}
`;


export const FooterWrapper = styled.div`
${({ theme }) => css`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 54px 0px 24px;
  & button {
    background: ${theme.palette.primary.main};
    color: ${theme.palette.white.light};
    font-family: 'Roboto';
    text-transform: uppercase;
    width: 100%;
    &:hover {
      background: ${theme.palette.primary.dark};
    }
  }
  & button:first-child {
    margin-bottom: 6px;
  }
  & button:disabled {
    color: ${theme.palette.grey.light};
    background: ${theme.palette.grey.ultralight};
  }
  `}
`;
export const SelectContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.primary.darkest};
    display: inline-block;
    height: 32px;
    position: relative;
    width: 288px;
    z-index: 2;
    @media (max-width: ${theme.breakpoints.sm}) {
      width: none;
    }
  `}
`;

export const DivSelector = styled.div`
    margin-top:40px;
    text-align: center;
`;
