import { AUTO_SSL_STATUS } from '@/enums/sites/status.enum';
import { SitesActionTypes } from './sites.types';


export const initialState = {
  failedWhoIsAttempt: false,
  forceReloadPropagationData: false,
  loading: false,
  plansLoading: false,
  plansLoaded: false,
  loadingExclusion: false,
  loadingDomains: false,
  loadedDomains: false,
  loadingSites: false,
  loadingDomainValidation: false,
  loadingRedirect: false,
  loadedSites: false,
  anotherDomain: null,
  domainAvailability: false,
  domainTldAvailability: false,
  list: [],
  tlds: [],
  loadingTlds: false,
  domainList: [],
  plans: [],
  domainDns: null,
  planDns: null,
  wordpressLoading: false,
  wordpressLoaded: false,
  wordPressInstalled: false,
  questionsAnswered: { ids: [], answers: {} },
  hasSiteBuilderActive: null,
  hostingAlreadyHasWooPremium: null,
  redirectFromPreChat: false,
  loadingVerifyIfSiteExist: false,
  siteExistVerifyFinished: null,
  needToExcludeDefaultFiles: null,
  loadingDefaultFilesExclude: false,
  currentlyInstallInfo: {
    showOverwriteModal: null,
    sharedDocumentRoot: null,
    onlyDefaultFiles: null,
    siteType: null,
    siteId: null,
  },
  loadingUninstall: false,
  uninstallCompleted: false,
  uninstallError: false,
  domainDnsStatus: null,
  allDomainsDnsStatus: [],
  loadingAllDomainsDnsStatus: false,
  ssl: {
    status: null,
    callLoop: 0,
  },
  sslVerification: {
    loading: false,
    status: null,
  },
  sslConfigureDomain: {
    status: null,
    loading: false,
  },
  welcomeCheckListSteps: {
    data: [],
  },
};

const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SitesActionTypes.GET_DOMAIN_DNS_FAILURE: {
      return {
        ...state,
        failedWhoIsAttempt: true,
      };
    }
    case SitesActionTypes.SITES_FROM_PRECHAT: {
      return {
        ...state,
        redirectFromPreChat: action.payload,
      };
    }
    case SitesActionTypes.SET_LOADING_REDIRECT: {
      return {
        ...state,
        loadingRedirect: action.payload.domain,
      };
    }
    case SitesActionTypes.FORCE_RELOAD: {
      return {
        ...state,
        forceReloadPropagationData: action.payload,
      };
    }
    case SitesActionTypes.SET_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loading: isLoading,
        failedWhoIsAttempt: isLoading ? false : state.failedWhoIsAttempt,
      };
    }
    case SitesActionTypes.SET_LOADING_EXCLUSION: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingExclusion: isLoading,
      };
    }
    case SitesActionTypes.SET_LOADING_TLDS: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingTlds: isLoading,
      };
    }
    case SitesActionTypes.SET_LOADING_DOMAINS: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingDomains: isLoading,
        loadedDomains: !isLoading,
      };
    }
    case SitesActionTypes.SET_LOADING_PLANS: {
      const { isLoading } = action.payload;
      return {
        ...state,
        plansLoading: isLoading,
        plansLoaded: !isLoading,
      };
    }
    case SitesActionTypes.SET_LOADING_SITES: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingSites: isLoading,
        loadedSites: !isLoading,
      };
    }
    case SitesActionTypes.SET_LOADING_DOMAIN_VALIDATION: {
      return {
        ...state,
        loadingDomainValidation: action.payload.isLoading,
      };
    }
    case SitesActionTypes.SET_SITES: {
      const { sites } = action.payload;
      return {
        ...state,
        list: sites,
      };
    }
    case SitesActionTypes.SET_SITES_DOMAINS: {
      const { domains } = action.payload;
      return {
        ...state,
        domainList: domains,
      };
    }
    case SitesActionTypes.SET_TLDS: {
      const { tlds } = action.payload;
      return {
        ...state,
        tlds,
      };
    }
    case SitesActionTypes.SET_DOMAIN_AVAILABILITY: {
      const {
        domainAvailability,
        domainTldAvailability,
        domain,
        domainAndSubdomain,
      } = action.payload;
      return {
        ...state,
        anotherDomain: domain,
        domainAvailability,
        domainTldAvailability,
        domainAndSubdomain,
      };
    }
    case SitesActionTypes.SET_PLANS: {
      const { plans } = action.payload;
      return {
        ...state,
        plans,
      };
    }
    case SitesActionTypes.SET_DOMAIN_DNS: {
      const { domainDns } = action.payload;
      return {
        ...state,
        domainDns,
        failedWhoIsAttempt: false,
      };
    }
    case SitesActionTypes.SET_PLAN_DNS: {
      const { planDns } = action.payload;
      return {
        ...state,
        planDns,
      };
    }
    case SitesActionTypes.SET_WORDPRESS_INSTALLATION_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        wordpressLoading: isLoading,
        wordpressLoaded: !isLoading,
      };
    }
    case SitesActionTypes.SET_WORDPRESS_INSTALLATION_STATUS: {
      const { installed } = action.payload;
      return {
        ...state,
        wordPressInstalled: installed,
      };
    }
    case SitesActionTypes.SET_WORDPRESS_INSTALLATION_DEFAULT: {
      return {
        ...state,
        wordpressLoaded: false,
        wordPressInstalled: false,
      };
    }
    case SitesActionTypes.SET_SURVEY_QUESTIONS_ANSWERED: {
      const { questions } = action.payload;
      return {
        ...state,
        questionsAnswered: questions,
      };
    }
    case SitesActionTypes.SET_SITE_BUILDER_ACTIVE: {
      const { hasSiteBuilderActive } = action.payload;
      return {
        ...state,
        hasSiteBuilderActive,
      };
    }
    case SitesActionTypes.GET_ADDON_CHECK_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        hostingAlreadyHasWooPremium: data,
      };
    }
    case SitesActionTypes.SET_ADDON_CHECK_DEFAULT: {
      return {
        ...state,
        hostingAlreadyHasWooPremium: null,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingVerifyIfSiteExist: isLoading,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_SUCCESS: {
      return {
        ...state,
        siteExistVerifyFinished: true,
        currentlyInstallInfo: action.payload,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE: {
      return {
        ...state,
        needToExcludeDefaultFiles: true,
        currentlyInstallInfo: action.payload,
        loadingDefaultFilesExclude: true,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_SUCCESS: {
      return {
        ...state,
        needToExcludeDefaultFiles: false,
        siteExistVerifyFinished: false,
        loadingDefaultFilesExclude: false,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_DEFAULT_FILES_EXCLUDE_FAILURE: {
      return {
        ...state,
        needToExcludeDefaultFiles: false,
        siteExistVerifyFinished: false,
        loadingDefaultFilesExclude: false,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_FAILURE: {
      return {
        ...state,
        siteExistVerifyFinished: false,
        loadingDefaultFilesExclude: false,
      };
    }
    case SitesActionTypes.GET_VERIFY_SITE_EXIST_RESET: {
      return {
        ...state,
        siteExistVerifyFinished: false,
        needToExcludeDefaultFiles: null,
        loadingDefaultFilesExclude: false,
        currentlyInstallInfo: {
          showOverwriteModal: null,
          sharedDocumentRoot: null,
          onlyDefaultFiles: null,
          siteType: null,
          siteId: null,
        },
        uninstallCompleted: false,
        uninstallError: false,
      };
    }
    case SitesActionTypes.UNINSTALL_SITE_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingUninstall: isLoading,
      };
    }
    case SitesActionTypes.UNINSTALL_SITE_SUCCESS: {
      return {
        ...state,
        uninstallCompleted: true,
        uninstallError: false,
      };
    }
    case SitesActionTypes.UNINSTALL_SITE_FAILURE: {
      return {
        ...state,
        uninstallCompleted: false,
        uninstallError: true,
      };
    }
    case SitesActionTypes.GET_DOMAIN_DNS_STATUS_CHECK_SUCCESS: {
      const status = action.payload;
      return {
        ...state,
        domainDnsStatus: status,
      };
    }
    case SitesActionTypes.GET_DOMAIN_DNS_STATUS_CHECK_FAILURE: {
      return {
        ...state,
        domainDnsStatus: null,
      };
    }
    case SitesActionTypes.GET_ALL_DOMAINS_DNS_STATUS_CHECK_SUCCESS: {
      const status = action.payload;
      return {
        ...state,
        allDomainsDnsStatus: status,
      };
    }
    case SitesActionTypes.GET_ALL_DOMAINS_DNS_STATUS_CHECK_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        loadingAllDomainsDnsStatus: isLoading,
      };
    }
    case SitesActionTypes.CLEAR_SSL_STATUS: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: null,
          callLoop: 0,
        },
        sslVerification: {
          ...state.sslVerification,
          status: null,
        },
        sslConfigureDomain: {
          ...state.sslConfigureDomain,
          status: null,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_START_SUCCESS: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: AUTO_SSL_STATUS.START_SUCCESS,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_START_FAILURE: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: AUTO_SSL_STATUS.START_FAILURE,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_PROGRESS_LOOP: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          callLoop: action.payload.callLoop,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_PROGRESS_FAILURE: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: AUTO_SSL_STATUS.PROGRESS_FAILURE,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_CHECK_SUCCESS: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: AUTO_SSL_STATUS.CHECK_SUCCESS,
        },
      };
    }
    case SitesActionTypes.AUTO_SSL_CHECK_FAILURE: {
      return {
        ...state,
        ssl: {
          ...state.ssl,
          status: AUTO_SSL_STATUS.CHECK_FAILURE,
        },
      };
    }
    case SitesActionTypes.SET_LOADING_SSL_VERIFICATION: {
      return {
        ...state,
        sslVerification: {
          ...state.sslVerification,
          loading: action.payload.isLoading,
        },
      };
    }
    case SitesActionTypes.SET_SSL_VERIFICATION: {
      return {
        ...state,
        sslVerification: {
          ...state.sslVerification,
          status: action.payload,
        },
      };
    }
    case SitesActionTypes.POST_DOMAIN_TO_HG_LOADING: {
      return {
        ...state,
        sslConfigureDomain: {
          ...state.sslConfigureDomain,
          loading: action.payload.isLoading,
        },
      };
    }
    case SitesActionTypes.POST_DOMAIN_TO_HG_SUCCESS: {
      return {
        ...state,
        sslConfigureDomain: {
          ...state.sslConfigureDomain,
          status: 'success',
        },
      };
    }
    case SitesActionTypes.POST_DOMAIN_TO_HG_FAILURE: {
      return {
        ...state,
        sslConfigureDomain: {
          ...state.sslConfigureDomain,
          status: 'error',
        },
      };
    }
    case SitesActionTypes.SET_STEP_WELCOME_CHECKLIST: {
      const { data } = action.payload;
      return {
        ...state,
        welcomeCheckListSteps: {
          data,
        },
      };
    }
    default:
      return state;
  }
};

export default sitesReducer;
