import PropTypes from 'prop-types';


const creditCardType = PropTypes.shape({
  id: PropTypes.number,
  cardType: PropTypes.string,
  cardLastFour: PropTypes.string,
  expDate: PropTypes.string,
});

export default creditCardType;
