import { ProductsActionTypes } from './products.types';

const requestAllProducts = () => ({ type: ProductsActionTypes.GET_ALL_PRODUCTS });
const getAllProductsFailure = () => ({ type: ProductsActionTypes.GET_ALL_PRODUCTS_FAILURE });
const getAllProductsSuccess = () => ({ type: ProductsActionTypes.GET_ALL_PRODUCTS_SUCCESS });
const setAllProductsList = list => ({ type: ProductsActionTypes.SET_ALL_PRODUCTS_LIST, payload: list });

const requestProductsPerCategory = ({ history, withGatorSolutions }) => ({ type: ProductsActionTypes.REQUEST_GET_PRODUCTS, payload: { history, withGatorSolutions } });
const getProductsSuccess = () => ({ type: ProductsActionTypes.GET_PRODUCTS_SUCCESS });
const getProductsSet = products => ({ type: ProductsActionTypes.GET_PRODUCTS_SET, payload: products });
const getProductsFailure = () => ({ type: ProductsActionTypes.GET_PRODUCTS_FAILURE });
const setProductVisualized = (hostingId, productId) => ({ type: ProductsActionTypes.SET_PRODUCT_VISUALIZED, payload: { hostingId, productId } });
const setProductVisualizedFailure = () => ({ type: ProductsActionTypes.SET_PRODUCT_VISUALIZED_FAILURE });
const setCPanelSSOLoading = loading => ({ type: ProductsActionTypes.SET_CPANEL_LOADING, payload: loading });
const requestCPanelSSO = ({
  hostingId, defaultCPanelUrl, setLoadingWithId, backup, backupWizard, enableCpanelSSO, setLoadingCpanelSSOProductId, setBlockedWindowUrl,
}) => ({
  type: ProductsActionTypes.GET_CPANEL_LINK,
  payload: {
    hostingId, defaultCPanelUrl, setLoadingWithId, backup, backupWizard, enableCpanelSSO, setLoadingCpanelSSOProductId, setBlockedWindowUrl,
  },
});
const addToAlertsList = alertItem => ({ type: ProductsActionTypes.SET_ADD_ALERT, payload: alertItem });
const clearNewAlerts = alertsList => ({ type: ProductsActionTypes.REQUEST_CLEAR_NEW_ALERTS, payload: alertsList });
const setClearNewAlerts = payload => ({ type: ProductsActionTypes.SET_CLEAR_NEW_ALERTS, payload });
const setAlertsVisibility = payload => ({ type: ProductsActionTypes.SET_ALERTS_VISIBILITY, payload });

const actions = {
  products: {
    request: requestProductsPerCategory,
    failure: getProductsFailure,
    success: getProductsSuccess,
    set: getProductsSet,
    requestAll: requestAllProducts,
    setRequestAllFailure: getAllProductsFailure,
    setRequestAllSuccess: getAllProductsSuccess,
    setAllProductsList,
  },
  productsVisualized: {
    set: setProductVisualized,
    failure: setProductVisualizedFailure,
  },
  cPanelSSO: {
    loading: setCPanelSSOLoading,
    requestLink: requestCPanelSSO,
  },
  myAlerts: {
    add: addToAlertsList,
    clearNew: clearNewAlerts,
    setClear: setClearNewAlerts,
    setVisibility: setAlertsVisibility,
  },
};

export default actions;
