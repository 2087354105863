import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};

    & div:nth-child(3) {
       & div {
        display: inline;
        & input {
          width: calc(100% - 18px);
        }
         & div:nth-child(2) {
          right: 0px;
         }
      }
    }
  `}
`;
