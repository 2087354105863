import React from 'react';
import * as Style from './IconEmail.style';

const IconEmail = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-email">
    <svg id="icon_gmd_alternate_email_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width="24" height="24" viewBox="0 0 24 24">
      <path id="Caminho_247489" data-name="Caminho 247489" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_247490" data-name="Caminho 247490" d="M12,1.95a10,10,0,0,0,0,20h5v-2H12a8.1,8.1,0,0,1-8-8,8.1,8.1,0,0,1,8-8,8.1,8.1,0,0,1,8,8v1.43a1.616,1.616,0,0,1-1.5,1.57A1.616,1.616,0,0,1,17,13.38V11.95a5,5,0,1,0-1.46,3.53,3.7,3.7,0,0,0,2.96,1.47A3.513,3.513,0,0,0,22,13.38V11.95a10,10,0,0,0-10-10Zm0,13a3,3,0,1,1,3-3A3,3,0,0,1,12,14.95Z" />
    </svg>
  </Style.Wrapper>
);


export default IconEmail;
