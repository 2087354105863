const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
  cardContainer: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '15px',
    },
    padding: '20px 30px',
  },
});

export default styles;
