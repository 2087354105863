const styles = theme => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '48px',
    marginTop: '60px',
    backgroundColor: theme.color.lavender,
  },
  headerTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    letterSpacing: '0.02px',
    marginTop: '28px',
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: '17px',
    letterSpacing: '0.02px',
    marginLeft: '24px',
    marginRight: '24px',
    marginTop: '14px',
    textAlign: 'center',
  },
  listContainer: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-around',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '0px',
    },
  },
  topImage: {
    maxWidth: '426px',
    marginTop: '20px',
    width: '100%',
    [theme.breakpoints.up(600)]: {
      display: 'none',
    },
  },
  sealImg: {
    maxWidth: '426px',
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  advantagesList: {
    maxWidth: '484px',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
    },
    '& li': {
      font: 'normal normal normal 17px/23px Roboto',
      color: theme.color.tundora,
      [theme.breakpoints.down(600)]: {
        marginTop: '20px',
      },
      '& span': {
        color: theme.color.tango,
        marginRight: '6px',
      },
    },
  },
});

export default styles;
