import React from 'react';
import {
  withStyles, Dialog, Slide, IconButton,
} from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { withI18n, Trans } from 'react-i18next';
import CardWarning from '@/components/Cards/CardWarning';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';


const Transition = props => <Slide direction="up" {...props} />;

const ModalReboot = ({
  open, onClose, classes, t, product, domain, onReboot,
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    classes={{
      paper: classes.modal,
    }}
  >
    <div
      className={classes.content}
    >
      <IconButton
        onClick={onClose}
        aria-label="Close"
        className={classes.btnClose}
      >
        <Close />
      </IconButton>
      <h3 className={classes.title}>{t('reboot.modalReboot.title')}</h3>
      <p className={classes.message}>
        <Trans i18nKey="reboot.modalReboot.message" values={{ product, domain }}>
          0
          <strong>1</strong>
          2
          <strong>3</strong>
        </Trans>
      </p>
      <CardWarning
        className={classes.warning}
        status="Info"
        icon={Info}
        iconSize="small"
        message={(
          <Trans i18nKey="reboot.message">
            <strong>0</strong>
            1
          </Trans>
        )}
      />
      <div className={classes.actions}>
        <OutlineButton className={classes.btnCancel} onClick={onClose}>{t('reboot.modalReboot.cancel')}</OutlineButton>
        <PrimaryButton onClick={onReboot}>{t('reboot.modalReboot.confirm')}</PrimaryButton>
      </div>
    </div>
  </Dialog>
);

export default withI18n()(withStyles(styles)(ModalReboot));
