/* eslint-disable react/jsx-curly-brace-presence */
export default {
  logoutModal: {
    title: 'Sua sessão expirou',
    description: 'Sua sessão já está ativa há bastante tempo, por questões de segurança e proteção de dados, ela foi encerrada. Para continuar no Portal do Cliente, clique em "Entrar novamente" e realize o login no portal.',
    cta: 'Entrar novamente',
  },
  linkNaBioGuide: {
    title: 'Crie gratuitamente!',
    description: 'Expanda sua audiência com um link único para o seu negócio.',
  },
};
