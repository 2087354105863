import { isBRServer } from '@/utils/Validators/validation';


export const linuxWithCpanel = [
  { label: 'CentOS 7 cPanel', value: 'CentOS 7 cPanel' },
];

export const linuxWithoutCpanel = [
  { label: 'CentOS 8', value: 'CentOS 8' },
  { label: 'CentOS 7', value: 'CentOS 7' },
  { label: 'Debian 10', value: 'Debian 10' },
  { label: 'Debian 9', value: 'Debian 9' },
  { label: 'Ubuntu 20.04', value: 'Ubuntu 20.04' },
  { label: 'Ubuntu 18.04', value: 'Ubuntu 18.04' },
];

export const windowsWithPleskWithoutLicense = isBRServer
  ? [
    { label: 'CentOS 8 com Plesk', value: 'CentOS 8 com Plesk' },
    { label: 'CentOS 7 com Plesk', value: 'CentOS 7 com Plesk' },
    { label: 'Debian 10 com Plesk', value: 'Debian 10 com Plesk' },
    { label: 'Debian 9 com Plesk', value: 'Debian 9 com Plesk' },
    { label: 'Ubuntu 20.04 com Plesk', value: 'Ubuntu 20.04 com Plesk' },
    { label: 'Ubuntu 18.04 com Plesk', value: 'Ubuntu 18.04 com Plesk' },
  ]
  : [
    { label: 'CentOS 8 con Plesk', value: 'CentOS 8 con Plesk' },
    { label: 'CentOS 7 con Plesk', value: 'CentOS 7 con Plesk' },
    { label: 'Debian 10 con Plesk', value: 'Debian 10 con Plesk' },
    { label: 'Debian 9 con Plesk', value: 'Debian 9 con Plesk' },
    { label: 'Ubuntu 20.04 con Plesk', value: 'Ubuntu 20.04 con Plesk' },
    { label: 'Ubuntu 18.04 con Plesk', value: 'Ubuntu 18.04 con Plesk' },
  ];

export const windowsWithPleskWithLicense = isBRServer
  ? [
    { label: 'Windows Server 2016', value: 'Windows Server 2016' },
    { label: 'Windows Server 2019', value: 'Windows Server 2019' },
  ]
  : [
    { label: 'Windows Server 2016', value: 'Windows Server 2016' },
    { label: 'Windows Server 2019', value: 'Windows Server 2019' },
  ];
