import { CALL_API } from '@/middleware/api';
import {
  COUNTRY, countrySurvey, wizardCountrySurvey, costumerPortalSurvey,
} from '@/config';
import { CUSTOMER_PROFILE_SURVEY_URL, VERIFY_CUSTOMER_PROFILE_SURVEY_URL } from '@/config/api';
import {
  REQUEST_CUSTOMER_SURVEY,
  ERROR_CUSTOMER_SURVEY,
  SUCCESS_CUSTOMER_SURVEY,
  REQUEST_VERIFY_CUSTOMER_SURVEY,
  SUCCESS_VERIFY_CUSTOMER_SURVEY,
  ERROR_VERIFY_CUSTOMER_SURVEY,
  SKIP_CUSTOMER_SURVEY,
} from './actionsTypes';

const receiveCustomerProfileSurvey = () => ({
  type: SUCCESS_CUSTOMER_SURVEY,
});

const receiveVerifyCustomerProfileSurvey = () => ({
  type: SUCCESS_VERIFY_CUSTOMER_SURVEY,
});

export const skipCustomerSurvey = payload => ({
  type: SKIP_CUSTOMER_SURVEY,
  payload,
});

const mountSurveyId = (type) => {
  switch (type) {
    case 'wizard':
      return wizardCountrySurvey[COUNTRY];
    case 'costumerPortal':
      return costumerPortalSurvey[COUNTRY];
    default:
      return countrySurvey[COUNTRY];
  }
};

export const registerCustomerProfileSurvey = ({ data, type }) => {
  const surveyId = mountSurveyId(type);

  return ({
    [CALL_API]: {
      statusCode: true,
      endpoint: CUSTOMER_PROFILE_SURVEY_URL(surveyId),
      method: 'POST',
      body: data,
      actionTypes: {
        request: () => ({ type: REQUEST_CUSTOMER_SURVEY }),
        success: (response) => {
          if (response.status === 200) {
            return receiveCustomerProfileSurvey();
          }
          return {
            type: ERROR_CUSTOMER_SURVEY,
          };
        },
        error: () => ({ type: ERROR_CUSTOMER_SURVEY }),
      },
    },
  });
};

export const verifyResponseCustomerProfileSurvey = (userId, hostingId) => ({
  [CALL_API]: {
    statusCode: true,
    endpoint: VERIFY_CUSTOMER_PROFILE_SURVEY_URL({ userId, hostingId }),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_VERIFY_CUSTOMER_SURVEY }),
      success: (response) => {
        if (response.status === 200) {
          return receiveVerifyCustomerProfileSurvey();
        }
        return {
          type: ERROR_VERIFY_CUSTOMER_SURVEY,
        };
      },
      error: () => ({ type: ERROR_VERIFY_CUSTOMER_SURVEY }),
    },
  },
});
