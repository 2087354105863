import React from 'react';
import * as Style from './IconDetailedFilters.style';

const IconDetailedFilters = ({
  size = '30',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3756 26.2871L4.07617 4.90967V26.2871H25.3756Z" fill="#74C1FF" />
      <path d="M9.95936 9.17822H8.14136V11.0148H9.95936V9.17822Z" fill="#1F2044" />
      <path d="M9.95936 13.3374H8.14136V15.1739H9.95936V13.3374Z" fill="#1F2044" />
      <path d="M9.95936 17.4966H8.14136V19.3331H9.95936V17.4966Z" fill="#1F2044" />
      <path d="M9.95936 21.6555H8.14136V23.4921H9.95936V21.6555Z" fill="#1F2044" />
      <path d="M25.3805 9.17822H10.9712V11.0148H25.3805V9.17822Z" fill="#1F2044" />
      <path d="M25.3805 13.3374H10.9712V15.1739H25.3805V13.3374Z" fill="#1F2044" />
      <path d="M25.3805 17.4966H10.9712V19.3331H25.3805V17.4966Z" fill="#1F2044" />
      <path d="M25.3805 21.6555H10.9712V23.4921H25.3805V21.6555Z" fill="#1F2044" />
      <path d="M25.6363 4.90918H3.81812V26.3226H5.63611V6.74627H25.6352L25.6363 4.90918Z" fill="#1F2044" />
    </svg>

  </Style.Wrapper>
);


export default IconDetailedFilters;
