export default {
  billing: '/facturas',
  byHost: '/by-host',
  cancelled: '/anuladas',
  cancelRenewal: '/cancelar-renovacion',
  cancelSubscription: '/cancelar-suscripcion',
  changePassword: '/cambiar-contrasena',
  accountSecurity: '/seguridad-de-cuenta',
  checkDomainAvailability: '/check-domain-availability',
  courses: '/cursos',
  createEmail: '/crear-cuenta-de-correo',
  creditcard: '/gestionar-tarjeta-credito',
  dns: '/cambiar-dns',
  domainConfig: 'configuracion-de-dominio',
  domaindetail: '/gestionar-dominio',
  domainRegister: '/dominios',
  domains: '/dominios',
  emails: '/e-mails',
  emailsList: '/emails-list',
  emailsTitan: '/administrar',
  emailVerify: '/confirmar-correo-electronico',
  feedbackSite: '/feedback-sitio-web',
  filteredEmails: '/filtro-e-mails',
  goalsObjectives: '/metas-y-objetivos',
  help: '/soporte',
  helpRedirect: '/ayuda',
  home: '/',
  homeRedirect: '/inicio',
  hosting: '/web-hosting',
  invoices: '/facturas',
  manageSubscription: '/administar-suscripciones',
  myData: '/mis-datos',
  newSite: '/crear-sitio-web',
  onboarding: '/onboarding',
  paid: '/pagadas',
  persona: '/nivel-de-conocimiento',
  phoneVerify: '/confirmar-celular',
  productdetail: '/gestionar-producto',
  products: '/productos',
  retentionSubscriptionConfirm: '/confirmar-cancelacion',
  retentionSubscriptionOffer: '/ofrecer-beneficios',
  retentionSubscriptionOfferAfterQuestions: '/ofrecer-ventajas',
  retentionSubscription: '/suscripcion',
  retentionRenew: '/renovacion',
  retentionBenefit: '/beneficio',
  siteBuilder: 'creador-de-sitios',
  siteBuilderRedirectAccess: '/sso-site-builder',
  sites: '/mis-sitios-web',
  sitesCreate: '/crear-sitio-web',
  sitesPage: '/sitios-web',
  sitesPlans: '/sitios-web-y-planes',
  subscriptions: '/suscripciones',
  supportforms: '/abrir-ticket',
  createTicket: '/crear-ticket',
  ticket: '/soporte/ticket',
  titanHire: '/titan-hire-plan',
  titanHireFreePlan: '/titan-hire-free-plan',
  titanHirePlan: '/titan-hire-free-plan',
  titanHirePlanActiveCPanel: '/titan-hire-plan-active-cpanel',
  titanInboxUpgrade: '/titan-inbox-upgrade',
  titanUpgrade: '/titan-upgrade',
  unpaid: '/pendientes',
  upgrade: '/upgrade',
  whmRedirectAccess: '/sso-whm-access',
  dnsWizard: '/dns-wizard',
  backup: '/backup',
  linkNaBio: '/enlace-en-la-bio',
  wizard: 'wizard',
  dataAnalysis: 'analisis-de-datos',
  cloudflare: '/cloudflare',
};
