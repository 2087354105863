import styled, { css } from 'styled-components';

export const Content = styled.section`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    padding: 14px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    margin: 0;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 74px;
      text-align: center;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;

export const InfoIcon = styled.span`
  ${({ theme }) => css`
    & svg {
      fill: ${theme.v1.color.warning.attention};
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const ButtonAdapter = styled.div``;

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 40px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;
