import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    background-color: ${theme.v1.color.white.primary};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 24px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: 'center';

    ${Button} {
      height: 28px;
    }

    &:not(:first-child) {
      margin-left: 24px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;
