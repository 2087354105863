import React from 'react';
import * as Style from './IconDetailedAcademy.style';

const IconDetailedAcademy = ({
  primaryColor = '#2e93ee',
  secondaryColor = '#1d5297',
  tertiaryColor = '#f67922',
  width = '21',
  height = '26',
  testId = 'icon-detailed-academy',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.431 26">
      <g data-name="Group 1521" transform="translate(-3 -1)">
        <path data-name="Caminho 247972" d="M22.483,18.093,10.4,11.136V25.051Zm1.949,0a1.93,1.93,0,0,1-.976,1.689L11.378,26.74a1.949,1.949,0,0,1-2.921-1.689V11.136a1.949,1.949,0,0,1,2.921-1.689L23.455,16.4a1.931,1.931,0,0,1,.976,1.689Z" fill={primaryColor} />
        <path data-name="Caminho 247973" d="M22.483,9.906,10.4,2.949V16.864Zm1.949,0a1.931,1.931,0,0,1-.976,1.689L11.378,18.553a1.949,1.949,0,0,1-2.921-1.689V2.949A1.949,1.949,0,0,1,11.378,1.26L23.455,8.217a1.931,1.931,0,0,1,.976,1.689Z" fill={secondaryColor} />
        <path data-name="Caminho 247974" d="M18,12.5a1.949,1.949,0,0,1,0,3.378L5.923,22.84A1.949,1.949,0,0,1,3,21.152V7.236A1.949,1.949,0,0,1,5.923,5.548Z" fill={tertiaryColor} />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedAcademy;
