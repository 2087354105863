import React from 'react';
import * as Styles from './DnsWizardDomainHeader.styles';

const DnsWizardDomainHeader = ({ testId = 'domainheader', domain = 'mydomain.com', title = 'Domain Header Title' }) => (
  <Styles.DomainHeaderWrapper data-testid={testId}>
    <Styles.Header>
      <Styles.Triangle />
      {title}
    </Styles.Header>
    <Styles.Domain>
      {domain}
    </Styles.Domain>
  </Styles.DomainHeaderWrapper>
);

export default DnsWizardDomainHeader;
