import React from 'react';
import * as Styles from './AsaasThirdRow.styles';
import baseLogo from '@/media/asaas/base-logo.svg';
import { IconDetailedPay } from '@/icons/IconDetailedPay';
import { IconDetailedCheck } from '@/icons/IconDetailedCheck';
import { IconDetailedComputer } from '@/icons/IconDetailedComputer';
import { IconDetailedPhone } from '@/icons/IconDetailedPhone';

const AsaasThirdRow = ({
  testId = 'asaas-third-row',
  title = 'title',
  benefits = [
    {
      icon: <IconDetailedPay />,

      title: 'title',
      description: 'description',
    },
    {
      icon: <IconDetailedCheck />,
      title: 'title',
      description: 'description',
    },
    {
      icon: <IconDetailedComputer />,
      title: 'title',
      description: 'description',
    },
    {
      icon: <IconDetailedPhone />,
      title: 'title',
      description: 'description',
    },
  ],
  erpTitle = 'ERP Title',
  erpDescription = 'ERP Description',
}) => (
  <Styles.Container data-testid={testId}>
    <Styles.Title data-testid={`${testId}-title`}>
      {title}
    </Styles.Title>

    <Styles.BenefitsWrapper>
      {benefits.map((benefit, index) => (
        <Styles.BenefitWrapper key={benefit.title}>
          {benefit.icon}
          <Styles.BenefitTitle data-testid={`${testId}-benefit-${index}-title`}>
            {benefit.title}
          </Styles.BenefitTitle>
          <Styles.BenefitDescription data-testid={`${testId}-benefit-${index}-description`}>
            {benefit.description}
          </Styles.BenefitDescription>
        </Styles.BenefitWrapper>
      ))}
    </Styles.BenefitsWrapper>

    <Styles.Logo src={baseLogo} />

    <Styles.ErpTitle data-testid={`${testId}-erp-title`}>
      {erpTitle}
    </Styles.ErpTitle>

    <Styles.ErpDescription data-testid={`${testId}-erp-description`}>
      {erpDescription}
    </Styles.ErpDescription>
  </Styles.Container>
);

export default AsaasThirdRow;
