import axios from 'axios';
import { config, COUNTRY } from '@/config';

const {
  API_URL,
  TICKETS_API_URL,
  TICKETS_API_URL_V2,
  AWS_AHC_URL,
  AWS_SITEBUILDER_URL,
  AWS_DNS_SERVICE_URL,
  AWS_PRODUCT_LIFECYCLE_URL,
  SURVEY_API,
  AWS_PRO_EMAIL_URL,
} = config;

const BASE_URL = `${API_URL}/clientarea/${COUNTRY}`;
export const API_URL_V3 = API_URL.replace('v2', 'v3');

const API = axios.create();
export default API;


/* SITES */
export const SITES_URL = `${AWS_PRODUCT_LIFECYCLE_URL}/sites`;
/* SITES */

/* SITES AND PLANS */
export const SITES_AND_PLANS_URL = `${AWS_PRODUCT_LIFECYCLE_URL}/plans`;
/* SITES AND PLANS */

/* EMAILS */
export const HOSTING_TYPES = `${BASE_URL}/user/hostings/type`;
export const EMAILS_URL = `${BASE_URL}/user/emails`;
export const CREATE_EMAIL = hostingId => `${BASE_URL}/user/products/${hostingId}/email`;
export const EMAIL_ACCOUNT_URL = (hostingId, emailAddress) => `${BASE_URL}/user/products/${hostingId}/emails/${emailAddress}`;
export const EMAIL_HOST_DOMAIN = `${BASE_URL}/user/host/domain`;
export const PRO_EMAIL_LIST = `${API_URL}/pro-emails`;
export const PRO_EMAIL_AUTH_TOKEN = planId => `${AWS_PRO_EMAIL_URL}/pro-emails/${planId}/provider-auth`;
export const TITAN_PRODUCT_BY_GROUP = groupId => `${API_URL}/upgrades/groups/${groupId}`;
export const UPGRADE_SUMMARY_CREDITS = `${API_URL}/upgrades/summary`;
export const UPGRADE_MAIL_INVOICE = `${API_URL}/upgrades/order`;
export const EXISTENT_INVOICE_VALIDATION_HOSTING = hostingId => `${API_URL}/upgrades/invoices/hosting/${hostingId}`;
export const EXISTENT_INVOICE_VALIDATION_DOMAIN = hostingId => `${API_URL}/upgrades/invoices/domain/${hostingId}`;
export const CHANGE_TITAN_PLAN = `${API_URL}/upgrades/order`;
export const CHANGE_TITAN_PLAN_FAST_CHECKOUT = `${API_URL}/fastcheckout`;
export const CHECK_DOMAIN_AVAILABLE = domain => `${PRO_EMAIL_LIST}/check-domain-availability/${domain}`;
export const PRO_EMAIL_HOSTING_PLANS = domain => `${PRO_EMAIL_LIST}/hosting/plans/${domain}`;
export const PRO_EMAIL_REACTIVATE_FREE_PLAN = hostingId => `${AWS_PRO_EMAIL_URL}/pro-emails/${hostingId}/reactivate`;
export const PRO_EMAIL_ACTIVE_EMAIL_CPANEL = idProEmail => `${AWS_PRO_EMAIL_URL}/pro-emails/${idProEmail}/order_setup`;
export const PRO_EMAIL_DOMAIN_SETUP = `${AWS_PRO_EMAIL_URL}/pro-emails/domain-setup`;
export const PRO_EMAIL_PAYMENT_METHODS = `${API_URL}/clientarea/br/paymentmethods`;
export const PRO_EMAIL_FAST_CHECKOUT = `${API_URL}/fastcheckout`;

/* EMAILS */

/* USER ACCOUNT */
export const ACCOUNT_INFORMATION_URL = `${BASE_URL}/summary`;
export const SUMMARY_URL = `${BASE_URL}/summary`;
export const FEATURE_TOGGLES = `${BASE_URL}/featuretoggles`;
export const CREDIT_CARD_URL = `${BASE_URL}/creditcard`;
export const FEEDBACK_ENDPOINT = `${BASE_URL}/feedback`;
export const PERSONA_URL = `${BASE_URL}/persona/save`;
export const CREATESITE_DISMISS = `${BASE_URL}/website/creation-flow`;
export const PORTAL_WEBSITE_TRACE = `${BASE_URL}/website/trace`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/password`;
export const TWO_FACTOR_AUTH = `${BASE_URL}/user/2fa`;
/* USER ACCOUNT */

/* FAQ */
export const FAQ_URL = `${BASE_URL}/questions`;
/* FAQ */

/* MY DATA */
export const COUNTRIES_URL = `${BASE_URL}/countries`;
export const STATES_URL = country => `${BASE_URL}/country/${country}/states`;
export const CITIES_URL = (country, state) => `${BASE_URL}/country/${country}/state/${state}/cities`;
export const ZIP_CODE_URL = zipCoe => `${BASE_URL}/zip-code/${zipCoe}`;
/* MY DATA */

/* PRODUCTS */
export const PRODUCTS_URL = `${BASE_URL}/products`;
export const ADDONS_URL = `${BASE_URL}/addons`;
export const GROUPED_PRODUCTS_URL = `${BASE_URL}/groupProducts`;
export const GENERAL_PRODUCTS_URL = `${BASE_URL}/generalProducts`;
export const ONBOARDING_URL = `${BASE_URL}/user/onboarding`;
export const ONBOARDING_WORDPRESS_URL = hostingId => `${BASE_URL}/user/onboarding/cpanel/${hostingId}`;
export const PRODUCT_REBOOT_HISTORY_URL = hostingId => `${BASE_URL}/product/hosting/${hostingId}/server/history?action=reboot&limit=3`;
export const PRODUCT_REBOOT_URL = hostingId => `${BASE_URL}/product/hosting/${hostingId}/server/reboot`;
export const PRODUCT_HEALTHCHECK_URL = hostingId => `${BASE_URL}/product/hosting/${hostingId}/server`;
export const PRODUCT_SAVE_PASSWORD_URL = hostingId => `${BASE_URL}/product/hosting/${hostingId}/server/change-password`;
export const PRODUCT_DETAIL_URL = hostingId => `${BASE_URL}/product/hosting/${hostingId}`;
export const PANEL_WHM_URL = serverId => `${AWS_AHC_URL}/servers/${serverId}/sso?service=whostmgrd&user=root`;
export const HOSTINGS_ADDONS = hostingId => `${BASE_URL}/hostings/${hostingId}/addons`;
/* PRODUCTS */

/* DOMAINS */
export const DOMAINS_URL = `${BASE_URL}/domains`;
export const USER_DOMAINS_URL = `${BASE_URL}/user/domains`;
export const USER_DOMAINS_ELEGIBLE = `${BASE_URL}/user/domains/eligible`;
export const DOMAIN_DETAIL_URL = domainId => `${BASE_URL}/user/domain/${domainId}`;
export const SUBDOMAINS_URL = domainId => `${BASE_URL}/domain/${domainId}/subdomain`;
export const DOMAIN_RENEWAL_URL = domainId => `${BASE_URL}/domain/${domainId}/renewal`;
export const DOMAINS_VERIFICATION_PENDING_URL = `${API_URL_V3}/domains/verification-pending`;
export const DOMAINS_RESEND_VERIFICATION_EMAIL_URL = `${API_URL_V3}/domains/resend-verification-email`;
export const DOMAIN_GENERATE_NEW_INVOICE = domainId => `${BASE_URL}/domain/${domainId}/renew-or-recovery-invoice`;

/* DOMAINS */

/* SITELOCK */
export const SITELOCK_DOMAINS = `${API_URL}/sitelock/domains`;
export const SITELOCK_SCAN = `${API_URL}/sitelock/scan/product/`;
export const SITELOCK_COMPLETE_SCAN = `${API_URL}/sitelock/scan/complete/`;
export const SITELOCK_COMPLETE_SMARTSCAN = `${API_URL}/sitelock/smartscan/complete/`;
export const SITELOCK_QUEUE = `${API_URL}/sitelock/scan/enqueue/`;
export const SITELOCK_CHECK_SCAN_STATUS = `${API_URL}/sitelock/scan/status/`;
export const SITELOCK_PRICE = `${API_URL}/sitelock/basic-price`;
export const SITELOCK_SHIELD = `${API_URL}/sitelock/scan/shield/`;
export const SITELOCK_SMARTSCAN = `${API_URL}/sitelock/smartscan/`;
export const SITELOCK_SMARTSCAN_CONFIG = `${API_URL}/sitelock/smartscan/auto-config/`;
export const SITELOCK_SMARTSCAN_FILE = `${API_URL}/sitelock/smartscan/file/`;
export const SITELOCK_HML_PAYMENT_METHOD = `${API_URL}/clientarea/br/paymentmethods`;
export const SITELOCK_BUY_FAST_CHECKOUT = `${API_URL}/fastcheckout`;
export const SITELOCK_PROFESSIONAL_HML_CHECKOUT_PRODUCT = `${API_URL_V3}/checkout/product/108`;
export const SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT = `${API_URL}/fastcheckout`;
export const SITELOCK_OPEN_NEW_MODAL = `${API_URL}/sitelock/free/open-new-modal`;
export const SITELOCK_CLOSE_NEW_MODAL = `${API_URL}/sitelock/free/mark-as-read`;
/* SITELOCK */

/* ACADEMY PASS */
export const ACADEMY_PASS_COURSES = `${API_URL}/academypass/courses`;
export const ACADEMY_PASS_TOKENS = `${API_URL}/academypass/tokens`;
export const ACADEMY_PASS_DETAILS = `${API_URL}/academypass`;
export const ACADEMY_PASS_HML_CHECKOUT_PRODUCT = `${API_URL_V3}/checkout/product/427`;
export const ACADEMY_PASS_HML_PAYMENT_METHOD = `${API_URL}/clientarea/br/paymentmethods`;
export const ACADEMY_PASS_BUY_FAST_CHECKOUT = `${API_URL}/fastcheckout`;
export const ACADEMY_PASS_FILTERED_COURSES = category => `${API_URL}/academypass/${category}/courses`;

/* ACADEMY PASS */

/* CODEGUARD */
export const CODEGUARD_DOMAINS = `${API_URL}/codeguard/domains`;
export const CODEGUARD_PRICE = `${API_URL}/codeguard/basic-price`;
export const CODEGUARD_HML_PAYMENT_METHOD = `${API_URL}/clientarea/br/paymentmethods`;
export const CODEGUARD_BUY_FAST_CHECKOUT = `${API_URL}/fastcheckout`;
export const CODEGUARD_HML_CHECKOUT_PRODUCT = `${API_URL_V3}/checkout/product/235`;
/* CODEGUARD */

/* WEBSITE BUILDER */
export const ONBOARDING_THEMES_URL = themeCountry => `${API_URL}/websitebuilder/${themeCountry}.json`;
export const ONBOARDING_SSO_URL = domainId => `${BASE_URL}/website/hosting-sso/${domainId}`;
export const PORTAL_ONBOARDING_PROCCESS_URL = (hostingId, domain, templateId) => `${BASE_URL}/website/proccess/${hostingId}/tradicional/${domain}/${templateId}`;
export const PORTAL_ONBOARDING_EXPRESS_URL = (hostingId, domain) => `${BASE_URL}/website/proccess/${hostingId}/express/${domain}`;
export const SITEBUILDER_SSO_URL = (builderEmail, domain) => `${AWS_SITEBUILDER_URL}/sitebuilder/sso/${builderEmail}/${domain}`;
export const SITE_BUILDER_DOMAINS_AVAILABLE_URL = hostingId => `${API_URL}/sitebuilder/${hostingId}/domains/available`;
export const ACTIVATE_SITEBUILDER_ADDON_URL = `${API_URL}/sitebuilder/account/activateAddon`;
export const ACTIVATE_SITEBUILDER_STANDALONE_URL = `${API_URL}/sitebuilder/account/activateStandalone`;
export const SITEBUILDER_URL = `${AWS_SITEBUILDER_URL}/sitebuilder`;
export const SITE_BUILDER_STATUS_BY_DOMAIN_URL = (builderEmail, domain) => `${AWS_SITEBUILDER_URL}/sitebuilder/status/${builderEmail}/${domain}`;
export const DELETE_SITE_FROM_SITEBUILDER_URL = (builderEmail, domain) => `${AWS_SITEBUILDER_URL}/sitebuilder/${builderEmail}/${domain}`;
export const ADDON_ID_FROM_SITE_BUILDER_ADDON = (hostingId, domain) => `${API_URL}/sitebuilder/${hostingId}/addon/${domain}`;
/* WEBSITE BUILDER */

/* INVOICES */
export const INVOICES_URL = `${BASE_URL}/invoices`;
export const UNPAID_INVOICES_URL = `${BASE_URL}/invoices/getAmount`;
export const SECOND_INVOICE_URL = invoiceId => `${BASE_URL}/invoice/${invoiceId}/secondInvoice`;
export const INVOICE_DETAIL_URL = invoiceId => `${BASE_URL}/invoice/${invoiceId}`;
export const INVOICE_SPLIT_PAYMENT_URL = invoiceId => `${BASE_URL}/invoice/${invoiceId}/split`;
export const PAYMENT_METHOD_URL = invoiceId => `${BASE_URL}/invoice/${invoiceId}/changePaymentMethod`;
export const PAYMENT_METHOD_CC = invoiceId => `${BASE_URL}/invoice/${invoiceId}/process`;
export const PAYMENT_METHOD_TICKET_INVOICE_ES = invoiceId => `${API_URL}/portal/${COUNTRY}/invoice/${invoiceId}/process/boleto`;
export const INVOICE_BOLETO_PROCESS = invoiceId => `${BASE_URL}/invoice/${invoiceId}/process/boleto`;
export const INVOICE_PAYMENT_METHODS = `${BASE_URL}/paymentmethods`;
export const CHECK_USER_DATA_FOR_PAYMENT_BOLETO = invoiceID => `${API_URL_V3}/gatorpay/test/boleto/${invoiceID}`;
export const BOLETO_UPDATE_ADDRESS_URL = `${API_URL_V3}/gatorpay/retry/boleto`;
export const DOWNLOAD_INVOICE_VOUCHER = invoiceId => `${BASE_URL}/invoice/${invoiceId}/download`;
export const NFSE_BY_INVOICE_URL = invoiceId => `${BASE_URL}/invoice/nfse/${invoiceId}`;
/* INVOICES */

/* TICKETS */
export const TICKET_DETAIL_URL = ticketId => `${TICKETS_API_URL}/tickets/${ticketId}`;
export const TICKET_DETAIL_URL_V2 = ticketId => `${TICKETS_API_URL_V2}/tickets/${ticketId}`;
export const TICKETS_URL = `${TICKETS_API_URL}/tickets/search`;
export const TICKETS_URL_V2 = `${TICKETS_API_URL_V2}/tickets/search`;
export const TICKET_FEEDBACK_DETAIL_URL = ticketId => `${TICKETS_API_URL}/tickets/${ticketId}/satisfaction`;
export const TICKET_TRANSFER_DOMAIN_URL = `${BASE_URL}/tickets/domains/transfer`;
/* TICKETS */

/* DNS */
export const DNS_DOMAIN_LIST = `${BASE_URL}/dnsdomainlist`;
export const DNS_DOMAIN_LIST_EXTERNAL = `${BASE_URL}/dnsdomainlist/external`;
export const DNS_DETAILS = domainId => `${BASE_URL}/dns/domain/${domainId}`;

// export const DNS_ZONE_MANAGER = (hostingId, domain) => `${AWS_DNS_SERVICE_URL}/accounts/${hostingId}/dns/${domain}/zone`;
export const DNS_ZONE_MANAGER = domain => `${AWS_DNS_SERVICE_URL}/dns/${domain}/zone`;
/* DNS */

/* ALERTS */
export const ALERTS_URL = `${API_URL.replace('v2', 'v3')}/alerts`;
/* ALERTS */

/* COURSES */
export const API_URL_VIMEO = 'https://api.vimeo.com';
export const VIMEO_URL_ALBUMS = `${API_URL_VIMEO}/me/albums`;
export const COURSES_VIEWS_URL = `${BASE_URL}/user/courses/views`;
/* COURSES */

/* EMAIL VERIFY */
export const RESEND_EMAIL = `${API_URL_V3}/checkout/email/verification/resend`;
export const CHANGE_EMAIL = `${API_URL_V3}/checkout/email/verification/send`;
/* EMAIL VERIFY */

/* PHONE VERIFY */
export const SEND_PHONE_CODE = `${API_URL_V3}/checkout/sms/verification/send`;
export const VALIDATE_PHONE_CODE = smsToken => `${API_URL_V3}/checkout/sms/verification/verify/${smsToken}`;
export const CHANGE_PHONE_NUMBER = `${API_URL_V3}/checkout/sms/update-phone`;
export const COUNTRY_CODE = `${API_URL_V3}/countries/phone-codes`;
/* PHONE VERIFY */

/* PAYPAL NEW API */
export const PAYPAL_AGREEMENT = `${API_URL_V3}/gatorpay/paypal/agreement`;
export const PAYPAL_CANCEL_AGREEMENT = `${API_URL_V3}/gatorpay/paypal/agreement/cancel`;
export const PAYPAL_CREATE_AGREEMENT_TOKEN = `${API_URL_V3}/gatorpay/paypal/agreement/token`;
export const PAYPAL_PAYMENT_AGREEMENT = `${API_URL_V3}/gatorpay/paypal/payment`;
/* PAYPAL NEW API */

/* PIX */
export const GENERATE_PIX = invoiceId => `${API_URL_V3}/gatorpay/pix/${invoiceId}`;
/* PIX */

/** GATORPAY PAAS */
export const GATORPAY_PAAS_SAVE_CREDITCARD = `${API_URL_V3}/gatorpay/paas/creditcard`;
export const GATORPAY_PAAS_SAVE_ATTEMPT_CREDITCARD = `${API_URL_V3}/gatorpay/paas/creditcard/attempts/portal`;
export const GATORPAY_PAAS_GET_ATTEMPT_CREDITCARD = `${API_URL_V3}/gatorpay/paas/creditcard/attempts/portal`;
/** GATORPAY PAAS */

/** SURVEY MONKEY */
export const SURVEY_URL = (surveyID, userID) => `${SURVEY_API}/surveys/${surveyID}/users/${userID}`;
/** SURVEY MONKEY */

/** CUSTOMER PROFILE SURVEY */
export const CUSTOMER_PROFILE_SURVEY_URL = surveyID => `${SURVEY_API}/surveys/${surveyID}`;
export const VERIFY_CUSTOMER_PROFILE_SURVEY_URL = ({ userId, hostingId }) => `${SURVEY_API}/users/${userId}/hosting/${hostingId}`;
/** CUSTOMER PROFILE SURVEY */

/** CREDIT CARD INSTALLMENTS */
export const CREDIT_CARD_INSTALLMENTS = invoiceId => `${BASE_URL}/paymentmethods/installments/${invoiceId}`;
export const CREDIT_CARD_INSTALLMENTS_BY_PRODUCT_DATA = (subscriptionId, subscriptionType) => `${BASE_URL}/signatures/installments/${subscriptionType}/${subscriptionId}`;
/** CREDIT CARD INSTALLMENTS */

/** BLACK LIST */
export const BLACK_LIST_GET_IP_USER = `${API_URL_V3}/blacklist/check-ip`;
/** BLACK LIST */
