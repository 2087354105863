import { TOS_STATUS } from '@/enums/preChat/preChat.enum';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDate } from '@/utils/Formatters/formatDate';

const diagnosticResponse = (rawData) => {
  const diagnostic = rawData.data;

  if (diagnostic && diagnostic.invoices && diagnostic.invoices.overdue.length) {
    const overdueTreatment = diagnostic.invoices.overdue.map(overdue => ({
      ...overdue,
      due_date: formatDate(overdue.due_date, true),
      total: formatCurrency(overdue.total),
    }));
    diagnostic.invoices.overdue = overdueTreatment;
  }

  if (diagnostic && diagnostic.invoices && diagnostic.invoices.to_due.length) {
    const toDueTreatment = diagnostic.invoices.to_due.map(toDue => ({
      ...toDue,
      due_date: formatDate(toDue.due_date, true),
      total: formatCurrency(toDue.total),
    }));
    diagnostic.invoices.to_due = toDueTreatment;
  }

  if (diagnostic && diagnostic.refunds && diagnostic.refunds.length) {
    const refundsTreatment = diagnostic.refunds.map(refund => ({
      ...refund,
      amount: formatCurrency(refund.amount),
      date_cancel: formatDate(refund.date_cancel, true),
      invoice_date_paid: formatDate(refund.invoice_date_paid, true),
    }));
    diagnostic.refunds = refundsTreatment;
  }

  if (diagnostic && diagnostic.customer) {
    const customerTreatment = {
      ...diagnostic.customer,
      credit_balance: formatCurrency(diagnostic.customer.credit_balance),
    };
    diagnostic.customer = customerTreatment;
  }

  if (diagnostic && diagnostic.trust_reactivation) {
    const trustReactivation = diagnostic.trust_reactivation.map(reactivation => ({
      ...reactivation,
      override_date: formatDate(reactivation.override_date),
    }));

    delete diagnostic.trust_reactivation;
    diagnostic.trustReactivation = trustReactivation;
  }

  if (diagnostic && diagnostic.tickets) {
    const adjustedTickets = diagnostic.tickets.map(ticket => ({
      ...ticket,
      tag: ticket.tag.toLowerCase(),
    }));
    const filteredTickets = adjustedTickets.length ? adjustedTickets.filter(ticket => Object.values(TOS_STATUS).some(tos => tos === ticket.tag)) : [];

    diagnostic.tickets = filteredTickets;
  }

  if (diagnostic && diagnostic.backups && diagnostic.backups.length) {
    const backupsTreatment = diagnostic.backups.map(backup => ({
      ...backup,
      backup_type: backup.type,
    }));
    diagnostic.backups = backupsTreatment;
  }

  return diagnostic;
};

const productDetailsResponse = (data) => {
  const isHosting = Object.keys(data).includes('hosting_id');

  if (isHosting) {
    return {
      origin: data.domain.origin,
      domain: data.domain.domain,
      registered: data.domain.registered,
      domainId: data.domain.domain_id,
      openInvoice: data.domain.open_invoice,
      invoiceId: data.domain.invoice_id,
      holdDomainStatus: !!data.domain.whois_frozen_status,
      isOnHgReserved: !!data.domain.pointed_to_the_reserved,
      couldBePointedToInternalHost: !!data.domain.has_available_hostgator_plan,
    };
  }

  return {
    origin: data.origin,
    domain: data.domain,
    registered: data.registered,
    domainId: data.domain_id,
    openInvoice: data.open_invoice,
    invoiceId: data.invoice_id,
    holdDomainStatus: !!data.whois_frozen_status,
    isOnHgReserved: !!data.pointed_to_the_reserved,
    couldBePointedToInternalHost: !!data.has_available_hostgator_plan,
  };
};

const getWeekDayResponse = rawData => rawData.data.date;

const assembly = {
  diagnosticResponse,
  productDetailsResponse,
  getWeekDayResponse,
};

export default assembly;
