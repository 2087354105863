import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectField from '@/components/Fields/SelectField';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import WarningBlock from '../WarningBlock';
import styles from './styles';

const HostingBlock = ({
  classes, t, hostingOptions, hostingSelected, handleChangeSelectValue, hosting,
}) => (
  <>
    <div className={classes.expandPanel}>
      <div className={classes.formWrapper}>
        <SelectField
          inputLabel={t('dns.selectHosting')}
          labelWidth={calcLabelWidth(t('dns.selectHosting'))}
          filterOptions={hostingOptions}
          selectValue={hostingSelected}
          selectType="hosting"
          menuItemDefaultText={t('dns.selectHosting')}
          handleChangeSelectValue={e => handleChangeSelectValue(e)}
          selectTestId="hostingBlockSelect"
          selectInputTestId="hostingBlockInput"
        />
        { hosting.master && hosting.slave && (
          <ul className={classes.list} data-testid="masterSlaveList">
            <li className={classes.listLabel}>Master</li>
            <li className={classes.listItem}>{hosting.master}</li>
            <li className={classes.listLabel}>Slave 1</li>
            <li className={classes.listItem}>{hosting.slave}</li>
          </ul>
        )}
      </div>
    </div>

    <WarningBlock />
  </>
);

HostingBlock.propTypes = {
  hostingOptions: PropTypes.array.isRequired,
  hostingSelected: PropTypes.string.isRequired,
  handleChangeSelectValue: PropTypes.func.isRequired,
  hosting: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)((HostingBlock)));
