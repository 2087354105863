import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    margin: 0 24px;
    padding: 30px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0;
    }
  `}
`;

export const Skeleton = styled.div`
  ${({ gap }) => css`
    margin-bottom: ${gap}px;
  `}
`;

export const SkeletonRow = styled.div`
  ${({ gap }) => css`
    align-items: center;
    display: flex;
    gap: ${gap}px
  `}
`;

export const FaqSection = styled.section`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    margin: 16px 24px;
    width: 560px;
    
    a{
      color: ${theme.v2.colors.brand.primaryMedium};
    }

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width: 50%;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: auto;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 10px 0;
    }
  `};
`;

export const LinkNaBioUser = styled.h3`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0 24px 6px;
    padding: 25px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0 0 6px;
    }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    margin-top: 5px;
    margin-bottom: 28px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: 6px;
    margin-bottom: 24px;
  `}
`;

export const TotalData = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: 8px; 
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    justify-content: space-between;
    margin-bottom: 53px;
    max-width: 716px;
    padding: 23px 38px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      align-items: start;
      flex-direction: column;
      gap: 30px;
      padding: 16px 24px;
    }
  `}
`;

export const Circle = styled.div`
  ${({ variant, theme }) => {
    const backgroundVariant = {
      access: theme.v2.colors.brand.primaryMedium,
      clicks: theme.v2.colors.brand.primaryExtraDark,
      conversion: theme.v2.colors.feedback.activePure,
    };

    return css`
      border-radius: 50%;
      height: 16px;
      min-width: 16px;
      background-color: ${backgroundVariant[variant]};
    `;
  }}
`;

export const Data = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      margin: 0
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      span {
        display: none;
      };
    }
  `}
`;

export const VerticalDivider = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.v2.colors.neutral.highMedium};
    height: 32px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const PeriodFilter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    flex-wrap: wrap;
    gap: 33px;
    justify-content: space-between;
    max-width: 716px;
    width: 100%;
  `}
`;

export const FilterButtons = styled.div`
  display: flex;
  gap: 16px;

  button {
    padding: 0;
  }
`;

export const ChartWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    max-width: 716px;
    width: 100%;

    canvas {
      min-height: 220px;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-top: 20px;
    }
  `}
`;

export const EmptyState = styled(Container)`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 480px;
`;

export const EmptyStateTitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: 21.6px;
    margin: 0;
    margin-top: 9px;
    text-align: center;
  `}
`;

export const EmptyStateDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    text-align: center;
    margin: 3px;
    max-width: 535px;
  `}
`;
