import styled, { css } from 'styled-components';

const defaultWrapperWidth = '540px';

export const Form = styled.form`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
  `}
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  ${({ theme }) => css`
    font-size:  ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin-bottom: 8px;
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;
    max-width: ${defaultWrapperWidth};
  `}
`;

export const InputWrapper = styled.div`
  max-width: 514px;
`;

export const ButtonWrapper = styled.div`
  margin: 35px 0;
`;
