import React from 'react';
import * as Styles from './CleanCacheModal.styles';
import { Button, IconCheckBg, Modal } from 'gatorcomponents';

const CleanCacheModal = ({
  onClose,
  cleanCacheLoading,
  loadingTitle,
  successTitle,
  successDescription,
  okButtonLabel,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal
      maxWidth={502}
      onClose={onClose}
      testId="clean-cache-modal"
    >
      <Styles.Wrapper>
        {!cleanCacheLoading && <IconCheckBg size="xl" secondaryColor="activeLight" />}
        <Styles.ModalTitle>
          {cleanCacheLoading
            ? loadingTitle
            : successTitle
          }
        </Styles.ModalTitle>
        {!cleanCacheLoading && (
          <Styles.ModalText>
            {successDescription}
          </Styles.ModalText>
        )}

        <Styles.ButtonContainer>
          <Button
            variant="primary"
            label={okButtonLabel}
            size="large"
            onClick={handleClose}
            loading={cleanCacheLoading}
            disabled={cleanCacheLoading}
          />
        </Styles.ButtonContainer>
      </Styles.Wrapper>
    </Modal>
  );
};

export default CleanCacheModal;
