import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 0 40px 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 8px 0 0 0;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme, width }) => css`
    background: ${theme.v2.colors.white.primary};
    padding: 24px;
    margin-bottom: 16px;
    width: ${width === 'half' ? '50%' : 'full'};

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 600px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;

export const Divider = styled.hr`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin: 40px 0px 16px;
  `}
`;
