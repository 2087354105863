import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Styles from './LinkNaBioStep2.styles';
import {
  IconFilledFile, Input, Tooltip, IconFilledInfo, Button,
} from 'gatorcomponents';
import { DragAndDropList } from '../DragAndDropList';
import { Preview } from '../Preview';
import snappyDesktop from '@/media/siteBuilder/snappy-desktop.svg';
import snappyMobile from '@/media/siteBuilder/snappy-mobile.svg';
import { linkType, wizardStep2Actions } from '../../LinkNaBioWizardHandler.enum';
import EmojiPicker, { EmojiStyle, SuggestionMode } from 'emoji-picker-react';
import { IconEmoji } from '../IconEmoji';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_LINK_NA_BIO_EMOJI, CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA, CHARLIE_LINK_NA_BIO_WHATSAPP } from '@/config/GrowthBook/constants';
import { SocialMediaModal } from '../SocialMediaModal';
import { LinkModal } from '../LinkModal';
import withErrorBoundary from '@/utils/errorBoundary';
import { COUNTRY } from '@/config';

const LinkNaBioStep2 = ({
  testId = 'link-na-bio-step-2',
  handleNextStep = () => null,
  handleUpdateLink = () => null,
  handleDeleteLink = () => null,
  handleReorderLinks = () => null,
  handleAddLink = () => null,
  prevStep = () => null,
  stepLocale = {},
  stepData = {
    links: [],
    title: '',
    description: '',
    emoji: undefined,
    socialMediaLinks: [],
  },
  setStepData = () => null,
  isEditing = false,
  setFieldUpdate = () => null,
  previewData = {},
  linksLoading = false,
  socialMediaLoading = false,
  onMobile = false,
  sanitizeLinkURL = () => null,
  handleUpdateSocialMedia = () => null,
  handleDeleteSocialMedia = () => null,
  handleReorderSocialMedia = () => null,
  handleAddSocialMedia = () => null,
  availableSocialMedias = [],
}) => {
  const modalDataInitialState = {
    description: '', link: '', ddi: '', phoneNumber: '',
  };
  const linkActionInitialState = { action: wizardStep2Actions.NONE, linkIndex: undefined, selectedSocialMedia: '' };

  const [modalData, setModalData] = useState(modalDataInitialState);
  const [linkAction, setLinkAction] = useState(linkActionInitialState);
  const [showPreview, setShowPreview] = useState(false);
  const [hasLinkError, setHasLinkError] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  const showEmoji = useFeatureIsOn(CHARLIE_LINK_NA_BIO_EMOJI);
  const showSocialMedia = useFeatureIsOn(CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA);
  const showNewWhatsappInput = useFeatureIsOn(CHARLIE_LINK_NA_BIO_WHATSAPP);
  const emojiPickerRef = useRef(null);
  const emojiIconRef = useRef(null);

  const defineInitialCountryOnSelect = useCallback(() => {
    switch (COUNTRY) {
      case 'co':
        return '+57';
      case 'mx':
        return '+52';
      case 'cl':
        return '+56';
      case 'br':
      default:
        return '+55';
    }
  }, []);

  const countryCode = modalData.ddi || defineInitialCountryOnSelect();

  const updateStepData = (name, value) => {
    const newStepData = { ...stepData };
    newStepData[name] = value;
    setStepData(newStepData);

    if (isEditing) {
      setFieldUpdate(true);
    }

    if (stepData.title && stepData.description && (stepData.links.length || stepData.socialMediaLinks.length)) {
      setHasError(false);
    }
  };

  const updateModalData = (value, name) => {
    let newValue = value;
    if (name === 'link') {
      setHasLinkError(false);
    }

    if (name === 'phoneNumber') {
      setHasLinkError(false);
      newValue = value;
    }
    if (name === 'ddi') {
      setHasLinkError(false);
      newValue = value;
    }

    const newLinkData = { ...modalData };
    newLinkData[name] = newValue;
    setModalData(newLinkData);

    if (stepData.title && stepData.description && (stepData.links.length || stepData.socialMediaLinks.length)) {
      setHasError(false);
    }
  };

  const handleModalDisplay = (actionData) => {
    if (actionData && actionData.action) {
      const { action, linkIndex, selectedSocialMedia } = actionData;

      if (action === wizardStep2Actions.EDIT_LINK) {
        const linkToEdit = { ...stepData.links[linkIndex] };
        setModalData(linkToEdit);
      }

      if (action === wizardStep2Actions.EDIT_SOCIAL_MEDIA) {
        const linkToEdit = { ...stepData.socialMediaLinks[linkIndex] };
        let newModalData = {};
        if (linkToEdit && linkToEdit.social_icon && linkToEdit.social_icon.name && linkToEdit.social_icon.name.toLowerCase() === 'whatsapp') {
          newModalData = {
            ...linkToEdit.social_icon, link: linkToEdit.value, id: linkToEdit.id, ddi: linkToEdit.formatted_value && String(linkToEdit.formatted_value.ddi), phoneNumber: linkToEdit.formatted_value && linkToEdit.formatted_value.number,
          };
        } else {
          newModalData = {
            ...linkToEdit.social_icon, link: linkToEdit.value, id: linkToEdit.id, ddi: '', phoneNumber: '',
          };
        }
        setModalData(newModalData);
      }

      if (action === wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP2) {
        const newModalData = { ...selectedSocialMedia, link: '' };
        setModalData(newModalData);
      }

      setLinkAction({ action, linkIndex });
    } else {
      setLinkAction(linkActionInitialState);
      setModalData(modalDataInitialState);
      setHasLinkError(false);
    }
  };

  const handleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  const validateLink = (validationModel) => {
    let hasError = false;

    if (validationModel === linkType.LINK) {
      const linkValidation = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
      hasError = !linkValidation.test(modalData.link);
    }

    if (validationModel === linkType.SOCIAL_MEDIA) {
      switch (modalData.name.toLowerCase()) {
        case 'whatsapp':
          hasError = false;
          break;
        case 'e-mail':
        case 'medium':
          hasError = !new RegExp(modalData.regex.replace('/', '').replace('/i', ''), 'i').test(modalData.link);
          break;
        default:
          hasError = !new RegExp(modalData.regex.replace('/', '').replace('+/i', '+'), 'i').test(modalData.link);
          break;
      }
    }

    setHasLinkError(hasError);
    if (stepData.title && stepData.description && !hasError) {
      setHasError(false);
    }

    return hasError;
  };

  const submitLink = async () => {
    const hasError = await validateLink(linkType.LINK);
    if (hasError) return;

    const linkData = { ...modalData };
    linkData.link = linkData.link && linkData.link.startsWith('http') ? linkData.link : `https://${linkData.link}`;

    const newLinks = [...stepData.links];
    const isEditing = linkAction.action === wizardStep2Actions.EDIT_LINK;
    const indexLinkIsEditing = linkAction.linkIndex;

    if (isEditing) {
      handleUpdateLink(linkData);
      newLinks[indexLinkIsEditing] = { id: newLinks[indexLinkIsEditing].id, ...linkData };
    } else {
      handleAddLink(linkData);
      newLinks.push({ ...linkData, id: new Date().getTime() });
    }

    updateStepData('links', newLinks);
    handleModalDisplay();
  };

  const reorderList = (newLinks) => {
    handleReorderLinks(newLinks);
    updateStepData('links', newLinks);
  };

  const deleteLink = () => {
    const { linkIndex } = linkAction;
    const newLinks = [...stepData.links];
    const [linkToDelete] = newLinks.splice(linkIndex, 1);
    handleDeleteLink(linkToDelete.id);
    updateStepData('links', newLinks);
    handleModalDisplay();
  };

  const submitSocialMedia = async () => {
    const hasError = await validateLink(linkType.SOCIAL_MEDIA);
    if (hasError) return;

    const isWhatsapp = modalData.name.toLowerCase() === 'whatsapp';
    const isEmail = modalData.name.toLowerCase() === 'e-mail';

    const socialMediaData = { ...modalData };
    if (isWhatsapp) {
      if (!socialMediaData.ddi) {
        socialMediaData.ddi = countryCode;
      }
      socialMediaData.link = showNewWhatsappInput ? socialMediaData.ddi + socialMediaData.phoneNumber.replace(/\D/g, '') : `https://api.whatsapp.com/send?phone=${socialMediaData.link}`;
    } else {
      socialMediaData.link = (socialMediaData.link && socialMediaData.link.startsWith('http')) || isEmail ? socialMediaData.link : `https://${socialMediaData.link}`;
    }

    const newSocialMediaLinks = [...stepData.socialMediaLinks];
    const isEditing = linkAction.action === wizardStep2Actions.EDIT_SOCIAL_MEDIA;
    const indexLinkIsEditing = linkAction.linkIndex;

    if (isEditing) {
      handleUpdateSocialMedia(socialMediaData);
      newSocialMediaLinks[indexLinkIsEditing] = {
        id: newSocialMediaLinks[indexLinkIsEditing].id,
        social_icon: { ...socialMediaData },
        value: socialMediaData.link,
        formatted_value: {
          ddi: socialMediaData.ddi,
          number: socialMediaData.phoneNumber,
        },
      };
    } else {
      handleAddSocialMedia(socialMediaData);
      newSocialMediaLinks.push({
        social_icon: { ...socialMediaData },
        value: socialMediaData.link,
        id: new Date().getTime(),
        formatted_value: {
          ddi: socialMediaData.ddi,
          number: socialMediaData.phoneNumber,
        },
      });
    }

    updateStepData('socialMediaLinks', newSocialMediaLinks);
    handleModalDisplay();
  };

  const deleteSocialMedia = () => {
    const { linkIndex } = linkAction;
    const newLinks = [...stepData.socialMediaLinks];
    const [linkToDelete] = newLinks.splice(linkIndex, 1);
    handleDeleteSocialMedia(linkToDelete.id);
    updateStepData('socialMediaLinks', newLinks);
    handleModalDisplay();
  };

  const reorderSocialMedia = (newLinks) => {
    handleReorderSocialMedia(newLinks);
    updateStepData('socialMediaLinks', newLinks);
  };

  const handleKeyDown = ({ key, type }) => {
    if (key && key === 'Enter') {
      if (modalData.link && type === linkType.SOCIAL_MEDIA) {
        submitSocialMedia();
        return;
      }
      if (modalData.description && modalData.link && type === linkType.LINK) {
        submitLink();
      }
    }
  };

  const handleShowEmojiPicker = () => {
    setEmojiPickerOpen(!emojiPickerOpen);
  };

  const handleCloseEmojiPicker = useCallback(() => {
    const handleClickOutside = (event) => {
      const emojiIconButtonClick = emojiPickerRef.current && emojiIconRef.current.contains(event.target);
      const emojiPickerClick = emojiPickerRef.current && emojiPickerRef.current.contains(event.target);

      if (!emojiIconButtonClick && !emojiPickerClick && emojiPickerOpen) {
        handleShowEmojiPicker();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiPickerRef, emojiPickerOpen]);

  useEffect(() => {
    handleCloseEmojiPicker();
  }, [handleCloseEmojiPicker]);


  const handleEmojiClick = (value) => {
    const { emoji } = value;
    updateStepData('emoji', emoji);
    handleShowEmojiPicker();
  };

  const emojiOnInput = () => showEmoji && (
    <Styles.EmojiButton
      onClick={handleShowEmojiPicker}
      ref={emojiIconRef}
    >
      {!stepData.emoji && <IconEmoji />}
      {stepData.emoji}
    </Styles.EmojiButton>
  );

  const submitStepData = () => {
    if (!(stepData.title && stepData.description && (stepData.links.length || stepData.socialMediaLinks.length))) {
      setHasError(true);
      return;
    }

    handleNextStep();
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      {(!onMobile || (onMobile && !showPreview)) && (
        <Styles.Content>
          <IconFilledFile />
          <Styles.Title>
            {stepLocale.title}
          </Styles.Title>
          <Styles.Description>
            {stepLocale.description}
          </Styles.Description>

          <Styles.Subtitle>
            {stepLocale.about}
          </Styles.Subtitle>

          <Styles.LinkTitleInput>
            {showEmoji && (
              <Styles.EmojiPicker ref={emojiPickerRef}>
                <EmojiPicker
                  open={emojiPickerOpen}
                  onEmojiClick={handleEmojiClick}
                  emojiStyle={EmojiStyle.NATIVE}
                  suggestedEmojisMode={SuggestionMode.RECENT}
                  width={380}
                  previewConfig={{ showPreview: false }}
                />
              </Styles.EmojiPicker>
            )}
            <Input
              label={stepLocale.linkTitle}
              value={stepData.title}
              placeholder={stepLocale.titlePlaceHolder}
              onChange={event => updateStepData('title', event.currentTarget.value)}
              maxLength={60}
              testId="link-title"
              prefix={emojiOnInput()}
              errorMessage={hasError && !stepData.title ? stepLocale.fieldRequired : undefined}
            />
          </Styles.LinkTitleInput>

          <Input
            label={stepLocale.linksDescription}
            value={stepData.description}
            placeholder={stepLocale.descriptionPlaceholder}
            onChange={event => updateStepData('description', event.currentTarget.value)}
            maxLength={150}
            testId="link-description"
            errorMessage={hasError && !stepData.description ? stepLocale.fieldRequired : undefined}
          />

          {showSocialMedia && (
            <>
              <Styles.Subtitle>
                {stepLocale.socialLinks}
                <Tooltip content={stepLocale.tooltipSocialMedia} width={258}>
                  <IconFilledInfo color="primaryBlue" />
                </Tooltip>
              </Styles.Subtitle>

              {!!stepData.socialMediaLinks.length && (
                <DragAndDropList
                  list={stepData.socialMediaLinks}
                  setList={reorderSocialMedia}
                  deleteLink={linkIndex => handleModalDisplay({ action: wizardStep2Actions.DELETE_SOCIAL_MEDIA, linkIndex })}
                  editLink={linkIndex => handleModalDisplay({ action: wizardStep2Actions.EDIT_SOCIAL_MEDIA, linkIndex })}
                  linksLoading={socialMediaLoading}
                  model={linkType.SOCIAL_MEDIA}
                />
              )}

              <Styles.SocialLinkButtonWrapper>
                <Button
                  label={stepLocale.addSocialLink}
                  onClick={() => handleModalDisplay({ action: wizardStep2Actions.ADD_SOCIAL_MEDIA_STEP1 })}
                  disabled={stepData.socialMediaLinks.length > 5}
                />
              </Styles.SocialLinkButtonWrapper>
            </>
          )}

          <Styles.Subtitle>
            {stepLocale.links}
            <Tooltip content={stepLocale.tooltip} width={315}>
              <IconFilledInfo color="primaryBlue" />
            </Tooltip>
          </Styles.Subtitle>

          {!!stepData.links.length && (
            <DragAndDropList
              list={stepData.links}
              setList={reorderList}
              deleteLink={linkIndex => handleModalDisplay({ action: wizardStep2Actions.DELETE_LINK, linkIndex })}
              editLink={linkIndex => handleModalDisplay({ action: wizardStep2Actions.EDIT_LINK, linkIndex })}
              linksLoading={linksLoading}
              model={linkType.LINK}
            />
          )}

          <Styles.AddLinkButton>
            <Button
              label={stepLocale.addLink}
              onClick={() => handleModalDisplay({ action: wizardStep2Actions.ADD_LINK })}
              disabled={stepData.links.length > 14}
            />
          </Styles.AddLinkButton>

          <Styles.ButtonRow>

            <Styles.ButtonWrapper>
              <Button
                label={stepLocale.continue}
                size={onMobile ? 'medium' : 'large'}
                disabled={hasError}
                onClick={submitStepData}
              />
            </Styles.ButtonWrapper>

            {onMobile && (
              <Styles.ButtonWrapper>
                <Button
                  label={stepLocale.previewButton}
                  variant="secondary"
                  onClick={handleShowPreview}
                />
              </Styles.ButtonWrapper>
            )}
          </Styles.ButtonRow>

          <Styles.ErrorMessage>
            {hasError && stepLocale.required}
          </Styles.ErrorMessage>

          {!isEditing && (
            <Styles.BackButton>
              <Button
                label={stepLocale.back}
                size="large"
                variant="tertiary"
                onClick={prevStep}
              />
            </Styles.BackButton>
          )
          }


        </Styles.Content>
      )}

      {(!onMobile || (onMobile && showPreview)) && (
        <Preview
          handleShowPreview={handleShowPreview}
          stepLocale={stepLocale}
          description={stepData.description}
          title={stepData.emoji ? `${stepData.emoji} ${stepData.title}` : stepData.title}
          links={stepData.links}
          templateType={previewData.template}
          variant={previewData.colorScheme}
          allowMobile
          onMobile={onMobile}
          socialMedia={stepData.socialMediaLinks}
        />
      )}

      <LinkModal
        linkAction={linkAction}
        stepLocale={stepLocale}
        handleModalDisplay={handleModalDisplay}
        modalData={modalData}
        updateModalData={updateModalData}
        handleKeyDown={handleKeyDown}
        sanitizeLinkURL={sanitizeLinkURL}
        submitLink={submitLink}
        deleteLink={deleteLink}
        validateLink={() => validateLink(linkType.LINK)}
        hasLinkError={hasLinkError}
      />

      <SocialMediaModal
        linkAction={linkAction}
        handleKeyDown={handleKeyDown}
        handleModalDisplay={handleModalDisplay}
        modalData={modalData}
        availableSocialMedias={availableSocialMedias}
        submitSocialMedia={submitSocialMedia}
        stepLocale={stepLocale}
        updateModalData={updateModalData}
        deleteSocialMedia={deleteSocialMedia}
        validateLink={() => validateLink(linkType.SOCIAL_MEDIA)}
        hasLinkError={hasLinkError}
        socialMediaLinks={stepData.socialMediaLinks}
        countryCode={countryCode}
        showNewWhatsappInput={showNewWhatsappInput}
      />

      <Styles.Snappy src={onMobile ? snappyMobile : snappyDesktop} alt="snappy" data-testid="snappy-image" />
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(LinkNaBioStep2, 'LinkNaBioStep2');
