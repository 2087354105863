const styles = theme => ({
  input: {
    paddingTop: 14.5,
    paddingBottom: 14.5,
    height: 'auto',
    '&$inputMarginDense': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      fontSize: '14px',
    },
  },
  inputMarginDense: {},
  disabled: {
    backgroundColor: '#EFEFEF',
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: '16px',
    '&$labelMarginDense': {
      transform: 'translate(14px, 10px) scale(1)',
    },
    '&$labelShrink': {
      transform: 'translate(14px, -5px) scale(0.75)',
    },
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  labelMarginDense: {},
  labelShrink: {},
  labelOutlined: {
    transform: 'translate(14px, 17px) scale(1)',
  },
  icon: {
    color: theme.color.regentGray,
    padding: '5px',
  },
  notchedOutline: {
    borderRadius: '2px',
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
});

export default styles;
