const styles = theme => ({
  statusArea: {
    border: `1px solid ${theme.color.geyser}`,
    background: `0% 0% no-repeat padding-box ${theme.color.white}`,
    borderRadius: '2px',
    marginTop: '30px',
    marginBottom: '12px',
    padding: '0 12px 12px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  statusLabel: {
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.doveGray,
    background: theme.color.white,
    position: 'relative',
    top: '-7px',
    padding: '0 2px',
    width: 'auto',
    minWidth: '20px',
  },
  statusMessage: {
    display: 'flex',
    '& span': {
      margin: '0 9px 0 7px',
    },
  },
  statusDates: {
    marginTop: '6px',
  },
  customPopper: {
    opacity: 1,
  },
  customTooltip: {
    backgroundColor: theme.color.doveGray,
    borderRadius: 0,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
  },
  malwareAlert: {
    borderTop: `1px solid ${theme.color.geyser}`,
    marginTop: '12px',
    textAlign: 'left',
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.01px',
    color: theme.color.tundora,
    '& strong': {
      font: 'normal normal medium 12px/14px Roboto',
      letterSpacing: '0.3px',
    },
    '& a': {
      color: theme.color.indigo,
      textDecorationLine: 'none',
    },
    '& a:hover': {
      textDecorationLine: 'underline',
    },
  },
  safe: {
    color: theme.color.oceanGreen,
  },
  issues: {
    color: theme.color.valencia,
  },
  pending: {
    color: theme.color.brightSun,
  },
  bullet: {
    '&:before': {
      content: '"⬤ "',
      fontSize: '10px',
      position: 'relative',
      top: '-2px',
    },
  },
  link: {
    fontWeight: '700',
  },
});

export default styles;
