import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BackButtonWrapper = styled.div`
  display: block;
  left: 38px;
  position: absolute;
  top: 38px;
`;

export const RelativeIpunt = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 40px 0px 0px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px 0px;
  `}
`;

export const Tip = styled.span`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0;
    position: absolute;
    right: -220px;
    width: 204px;

    svg {
      flex: 0 0 24px;
      margin: 4px 0px 0px 0px;
    }

    @media screen and (max-width: ${theme.v2.breakpoints.md}){
      align-items: center;
      margin: 0px;
      margin: 10px 0px 0px 0px;
      position: relative;
      right: unset;
      width: unset;

      svg {
        flex: 0 0 24px;
        margin: 0px;
      }
    }
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 0px 67px 0px;
  max-width: 498px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 8px 0px 0px 0px;
    width: 100%;

    @media screen and (max-width: ${theme.v2.breakpoints.md}){ 
      flex-direction: column
    }
  `}
`;

export const NoAccessTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: auto 0px 0px 0px;
  `}
`;

export const NoAccessDescription = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 8px 0px 0px 0px;
  `}
`;

export const OpenFormButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px 0px 40px 0px;
`;
