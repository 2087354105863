/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, withWidth } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isWidthDown } from '@material-ui/core/withWidth';
import ExternalServiceErrorMessage from '@/components/Notification/ExternalServiceErrorMessage';
import CourseItem from '@/components/Courses/CourseItem';
import Loader from '@/components/Layout/Loader';
import BlueDoodle from '@/media/courses/BlueDoodle';
import SnappyDefault from '@/media/courses/SnappyDefault';
import { getVimeoShowcases } from '@/redux/actions/videos';
import { indexedDBPutCourseProgress } from '@/config/indexedDB/handlers';
import { COUNTRY } from '@/config';
import AcademyPassContextProvider from '@/contexts/academyPass/context';
import AcademyPass from '@/components/AcademyPass';
import styles from './styles';

const CoursesPage = ({ t, classes, width }) => {
  const dispatch = useDispatch();

  const courses = useSelector(state => state.videos.courses);
  const loading = useSelector(state => state.videos.loading);
  const userId = useSelector(state => state.summary.id);

  const [userProgress, setUserProgress] = useState({});

  const isMobile = isWidthDown('xs', width);

  const dataIsReady = !!(courses[0] && courses[0].keyName);
  const coursesLength = courses.length;

  const isSaveProgressOnIndexedDb = true;
  const isRemoveLocalStorage = true;

  const isBrazil = COUNTRY === 'br';
  const shouldRenderCharlieAcademyPass = isBrazil;

  useEffect(() => {
    let localStorageProgress = '';
    const progressKey = `progress-${userId}`;

    const getUserProgress = () => {
      localStorageProgress = JSON.parse(localStorage.getItem(progressKey));

      if (localStorageProgress) {
        setUserProgress(localStorageProgress);
      }
    };

    const removeLocalStorageProgress = async (key) => {
      if (!isRemoveLocalStorage) {
        return;
      }
      await localStorage.removeItem(key);
    };

    const moveLocalStorageToIndexedDB = async () => {
      if (!localStorageProgress) {
        return;
      }

      if (Object.entries(localStorageProgress).length === 0) {
        await removeLocalStorageProgress(progressKey);
        return;
      }

      await indexedDBPutCourseProgress({
        userId,
        userProgress: localStorageProgress,
      });

      await removeLocalStorageProgress(progressKey);
    };

    getUserProgress();

    isSaveProgressOnIndexedDb && moveLocalStorageToIndexedDB();

    if (!loading && coursesLength === 0) {
      dispatch(getVimeoShowcases());
    }
  }, []);

  if ((coursesLength > 0 && !dataIsReady) || loading) {
    return <Loader />;
  }

  return shouldRenderCharlieAcademyPass ? (
    <AcademyPassContextProvider>
      <AcademyPass />
    </AcademyPassContextProvider>
  ) : (
    <div className={classes.wrapper}>
      {!isMobile && courses.length < 7 && (
        <div className={classes.background} data-testid="container-snappy">
          <BlueDoodle className={classes.blueDoodle} />
          <SnappyDefault className={classes.snappyDefault} />
        </div>
      )}
      {coursesLength === 0 ? (
        <ExternalServiceErrorMessage>
          {t('courses.vimeoServiceDown')}
        </ExternalServiceErrorMessage>
      ) : (
        <>
          <span className={classes.info}>{t('courses.info')}</span>
          <main className={classes.courses}>
            {courses.map((course, index) => (
              <CourseItem
                animationId={`courseCardTop${index}`}
                key={course.id}
                index={index}
                finished={course.finished}
                course={course}
                userProgress={userProgress}
              />
            ))}
          </main>
        </>
      )}
    </div>
  );
};

export default withWidth()(withI18n()(withStyles(styles)(CoursesPage)));
