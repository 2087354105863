import React, { useState, useEffect } from 'react';
import * as Styles from './ProgressCircle.styles';

const ProgressCircle = ({
  testId = 'progress-circle',
  size = 56,
  current = 1,
  total = 4,
  strokeWidth = 4,
  bgColor,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const [progress, setProgress] = useState(1 - current * 100 / total / 100);
  const [offset, setOffset] = useState(progress * circumference);

  useEffect(() => {
    setProgress(1 - current * 100 / total / 100);
  }, [current, total]);

  useEffect(() => {
    setOffset(progress * circumference);
  }, [progress, circumference]);

  return (
    <Styles.Wrapper width={size} height={size} data-testid={testId}>
      <Styles.Background
        cx="50%"
        cy="50%"
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
      />
      <Styles.Foreground
        cx="50%"
        cy="50%"
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        bgColor={bgColor}
      />
    </Styles.Wrapper>
  );
};

export default ProgressCircle;
