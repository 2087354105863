import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORIES: {
    MY_ALERTS: 'Meus Avisos',
    INVOICES: 'Solução - Meus avisos - Exibição da notificação faturas',
    PROPAGATION: 'Solução - Meus avisos - Exibição da notificação propagação',
    CONTRACT_DOMAIN: 'Solução - Meus avisos - Exibição da notificação contratar domínio',
    SETUP_DOMAIN: 'Solução - Meus avisos - Exibição da notificação configurar domínio',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_30: 'Solução - Meus avisos - Link Ativar Assinatura 30',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_30: 'Solução - Meus avisos - Link Ativar Assinatura menos 30',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_10: 'Solução - Meus avisos - Link Ativar Assinatura 10',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_10: 'Solução - Meus avisos - Link Ativar Assinatura menos 10',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_5: 'Solução - Meus avisos - Link Ativar Assinatura 5',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_5: 'Solução - Meus avisos - Link Ativar Assinatura menos 5',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_WITH_DISCOUNT: 'Solução - Meus avisos - Link Ativar Assinatura 5 com desconto',
    WITHOUT_BACKUP: 'Solução - Meus avisos fluxo de backup - Exibição do alerta não possui backup',
    BACKUP_CTA_CLICK: 'Solução - Meus avisos fluxo de backup - Clique no cta',
    BACKUP_SEVEN_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 7 dias',
    BACKUP_FIFTEEN_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 15 dias',
    BACKUP_THIRTY_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 30 dias',
    BACKUP_SUCCESS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta backup concluído com sucesso',
    BACKUP_RESTORED: 'Solução - Meus avisos fluxo de backup - Exibição do alerta restauração concluída com sucesso',
    DUPLICATE_ORDER: 'Solução - Meus avisos Pedidos duplicados - Alerta apresentado com sucesso',
    DUPLICATE_ORDER_CLICK: 'Solução - Meus avisos Pedidos duplicados - Clique no cta',
    TICKET_ALERTS: 'Solução - Status dos chamados meus avisos',
    PENDING_ORDER: 'Solução - Meus avisos pedidos em análise - Alerta apresentado com sucesso',
    PENDING_ORDER_CLICK: 'Solução - Meus avisos pedidos em análise - Clique no cta',
    SSL_ALERT: 'Solução - SSL Meus Avisos - Alerta apresentado com sucesso',
  },
  ACTIONS: {
    CLICK_MY_ACTIONS: 'Clique CTA: Meus Avisos',
    ALERT_EXHIBITION: 'Solução - Alerta apresentado com sucesso - Meus avisos - Exibição da notificação',
    CLICK_LINK_SUBSCRIPTION_MANAGER: 'Click',
    WITHOUT_BACKUP: 'Solução - Exibição de alerta  - Exibição do alerta não possui backup',
    BACKUP_CTA_CLICK: 'Solução - Clique no cta  - Fazer backup',
    BACKUP_SEVEN_DAYS: 'Solução - Exibição de alerta  - Exibição do alerta ultimo backup 7 dias',
    BACKUP_FIFTEEN_DAYS: 'Solução - Exibição de alerta  - Exibição do alerta ultimo backup 15 dias',
    BACKUP_THIRTY_DAYS: 'Solução - Exibição de alerta  - Exibição do alerta ultimo backup 30 dias',
    BACKUP_SUCCESS: 'Solução - Exibição de alerta  - Exibição do alerta backup concluído com sucesso',
    BACKUP_RESTORED: 'Solução - Exibição de alerta  - Exibição do alerta restauração concluída com sucesso',
    DUPLICATE_ORDER: 'Solução - Alerta apresentado com sucesso - Alerta de pedido duplicado apresentado com sucesso',
    DUPLICATE_ORDER_CLICK: 'Solução - Alerta apresentado com sucesso - Cta ver pedidos duplicados',
    TICKET_NEW_ALERT: 'Solução - Exibição Status dos chamados - Alerta de chamado aberto',
    TICKET_NEW_CTA: 'Solução - Clique no cta - Cta ver chamado aberto',
    TICKET_OPEN_ALERT: 'Solução - Exibição Status dos chamados - Alerta de chamado em aberto',
    TICKET_OPEN_CTA: 'Solução - Clique no cta - Cta ver chamado em aberto',
    TICKET_WAITING_RESPONSE_ALERT: 'Solução - Exibição Status dos chamados - Alerta de chamado aguardando resposta',
    TICKET_WAITING_RESPONSE_CTA: 'Solução - Clique no cta - Cta ver chamado aguardando resposta',
    TICKET_SOLVED_ALERT: 'Solução - Exibição Status dos chamados - Alerta de chamado concluído',
    TICKET_SOLVED_CTA: 'Solução - Clique no cta - Cta ver chamado concluído',
    ANALYZING_ORDER_ALERT: 'Solução - Alerta apresentado com sucesso - Alerta pedido em análise',
    CANCELED_ORDER_ALERT: 'Solução - Alerta apresentado com sucesso - Alerta pedido cancelado',
    PROCESSING_ORDER_ALERT_CLICK: 'Solução - Clique no cta Pedidos em análise - Cta ver detalhes',
    SSL_IN_PROGRESS: 'Solução - Alerta apresentado com sucesso - SSL em processo de ativação',
    SSL_ACTIVE: 'Solução - Alerta apresentado com sucesso - SSL ativado com sucesso',
    SSL_ERROR: 'Solução - Alerta apresentado com sucesso - Não foi possível instalar o SSL',
  },
  LABELS: {
    VIEW_ALERT: 'Ação: Visualizar notificações de aviso',
    NOTIFICATION_VIEW: 'Solução - Meus avisos - Exibição da notificação',
    SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED: 'Ação: Link Ativar Assinatura',
    WITHOUT_BACKUP: 'Solução - Meus avisos fluxo de backup - Exibição do alerta não possui backup',
    BACKUP_CTA_CLICK: 'Solução - Meus avisos fluxo de backup - Clique no cta Fazer backup',
    BACKUP_SEVEN_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 7 dias',
    BACKUP_FIFTEEN_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 15 dias',
    BACKUP_THIRTY_DAYS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta ultimo backup 30 dias',
    BACKUP_SUCCESS: 'Solução - Meus avisos fluxo de backup - Exibição do alerta backup concluído com sucesso',
    BACKUP_RESTORED: 'Solução - Meus avisos fluxo de backup - Exibição do alerta restauração concluída com sucesso',
    DUPLICATE_ORDER: 'Solução - Meus avisos Pedidos duplicados - Alerta de pedido duplicado apresentado com sucesso',
    DUPLICATE_ORDER_CLICK: 'Solução - Meus avisos Pedidos duplicados - Clique no cta ver pedidos duplicados',
    TICKET_NEW_ALERT: 'Solução - Status dos chamados meus avisos - Alerta de chamado aberto exibido com sucesso',
    TICKET_NEW_CTA: 'Solução - Status dos chamados meus avisos - Clique no cta ver chamado aberto',
    TICKET_OPEN_ALERT: 'Solução - Status dos chamados meus avisos - Alerta de chamado em aberto exibido com sucesso',
    TICKET_OPEN_CTA: 'Solução - Status dos chamados meus avisos - Clique no cta ver chamado em aberto',
    TICKET_WAITING_RESPONSE_ALERT: 'Solução - Status dos chamados meus avisos - Alerta de chamado aguardando resposta exibido com sucesso',
    TICKET_WAITING_RESPONSE_CTA: 'Solução - Status dos chamados meus avisos - Clique no cta ver chamado aguardando resposta',
    TICKET_SOLVED_ALERT: 'Solução - Status dos chamados meus avisos - Alerta de chamado concluído exibido com sucesso',
    TICKET_SOLVED_CTA: 'Solução - Status dos chamados meus avisos - Clique no cta ver chamado concluído',
    ANALYZING_ORDER_ALERT: 'Solução - Meus avisos pedidos em análise - Alerta de pedido em análise apresentado com sucesso',
    CANCELED_ORDER_ALERT: 'Solução - Meus avisos pedidos em análise - Alerta de pedido cancelado apresentado com sucesso',
    PROCESSING_ORDER_ALERT_CLICK: 'Solução - Meus avisos pedidos em análise - Clique no cta ver detalhes',
    SSL_IN_PROGRESS_DISPLAYED: 'Solução - SSL Meus Avisos - Alerta de SSL em processo de ativação exibido com sucesso',
    SSL_ACTIVE_DISPLAYED: 'Solução - SSL Meus Avisos - Alerta de SSL ativado exibido com sucesso',
    SSL_ERROR_DISPLAYED: 'Solução - SSL Meus Avisos - Alerta de não foi possível instalar o SSL exibido com sucesso',
  },
};

const redirectToSubscriptionManager = (daysToCancel, discountEnable) => {
  let category;
  if (discountEnable) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_WITH_DISCOUNT;
  } else if (daysToCancel === 30) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_30;
  } else if (daysToCancel < 30 && daysToCancel > 10) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_30;
  } else if (daysToCancel === 10) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_10;
  } else if (daysToCancel < 10 && daysToCancel > 5) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_10;
  } else if (daysToCancel === 5) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_5;
  } else if (daysToCancel < 5) {
    category = EVENTS.CATEGORIES.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED_LT_5;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: category,
    gaEventAction: EVENTS.ACTIONS.CLICK_LINK_SUBSCRIPTION_MANAGER,
    gaEventLabel: EVENTS.LABELS.SUBSCRIPTIONS_WITHOUT_RENEW_ENABLED,
  });
};

const viewAlerts = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.MY_ALERTS,
    gaEventAction: EVENTS.ACTIONS.CLICK_MY_ACTIONS,
    gaEventLabel: EVENTS.LABELS.VIEW_ALERT,
  });
};

const viewInvoicesAlert = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.INVOICES,
    gaEventAction: EVENTS.ACTIONS.ALERT_EXHIBITION,
    gaEventLabel: EVENTS.LABELS.NOTIFICATION_VIEW,
  });
};

const viewPropagationAlert = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.PROPAGATION,
    gaEventAction: EVENTS.ACTIONS.ALERT_EXHIBITION,
    gaEventLabel: EVENTS.LABELS.NOTIFICATION_VIEW,
  });
};

const viewContractDomainAlert = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.CONTRACT_DOMAIN,
    gaEventAction: EVENTS.ACTIONS.ALERT_EXHIBITION,
    gaEventLabel: EVENTS.LABELS.NOTIFICATION_VIEW,
  });
};
const viewSetupDomainAlert = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SETUP_DOMAIN,
    gaEventAction: EVENTS.ACTIONS.ALERT_EXHIBITION,
    gaEventLabel: EVENTS.LABELS.NOTIFICATION_VIEW,
  });
};
const withoutBackup = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.WITHOUT_BACKUP,
    gaEventAction: EVENTS.ACTIONS.WITHOUT_BACKUP,
    gaEventLabel: EVENTS.LABELS.WITHOUT_BACKUP,
  });
};
const backupCTAClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_CTA_CLICK,
    gaEventAction: EVENTS.ACTIONS.BACKUP_CTA_CLICK,
    gaEventLabel: EVENTS.LABELS.BACKUP_CTA_CLICK,
  });
};
const backupSevenDays = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_SEVEN_DAYS,
    gaEventAction: EVENTS.ACTIONS.BACKUP_SEVEN_DAYS,
    gaEventLabel: EVENTS.LABELS.BACKUP_SEVEN_DAYS,
  });
};
const backupFifteenDays = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_FIFTEEN_DAYS,
    gaEventAction: EVENTS.ACTIONS.BACKUP_FIFTEEN_DAYS,
    gaEventLabel: EVENTS.LABELS.BACKUP_FIFTEEN_DAYS,
  });
};
const backupThirtyDays = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_THIRTY_DAYS,
    gaEventAction: EVENTS.ACTIONS.BACKUP_THIRTY_DAYS,
    gaEventLabel: EVENTS.LABELS.BACKUP_THIRTY_DAYS,
  });
};
const backupSuccess = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_SUCCESS,
    gaEventAction: EVENTS.ACTIONS.BACKUP_SUCCESS,
    gaEventLabel: EVENTS.LABELS.BACKUP_SUCCESS,
  });
};
const backupRestored = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.BACKUP_RESTORED,
    gaEventAction: EVENTS.ACTIONS.BACKUP_RESTORED,
    gaEventLabel: EVENTS.LABELS.BACKUP_RESTORED,
  });
};
const duplicateOrder = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.DUPLICATE_ORDER,
    gaEventAction: EVENTS.ACTIONS.DUPLICATE_ORDER,
    gaEventLabel: EVENTS.LABELS.DUPLICATE_ORDER,
  });
};
const duplicateOrderClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.DUPLICATE_ORDER_CLICK,
    gaEventAction: EVENTS.ACTIONS.DUPLICATE_ORDER_CLICK,
    gaEventLabel: EVENTS.LABELS.DUPLICATE_ORDER_CLICK,
  });
};

const ticketNew = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_NEW_ALERT,
    gaEventLabel: EVENTS.LABELS.TICKET_NEW_ALERT,
  });
};

const ticketNewClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_NEW_CTA,
    gaEventLabel: EVENTS.LABELS.TICKET_NEW_CTA,
  });
};

const ticketOpen = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_OPEN_ALERT,
    gaEventLabel: EVENTS.LABELS.TICKET_OPEN_ALERT,
  });
};

const ticketOpenClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_OPEN_CTA,
    gaEventLabel: EVENTS.LABELS.TICKET_OPEN_CTA,
  });
};

const ticketWaitingResponse = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_WAITING_RESPONSE_ALERT,
    gaEventLabel: EVENTS.LABELS.TICKET_WAITING_RESPONSE_ALERT,
  });
};

const ticketWaitingResponseClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_WAITING_RESPONSE_CTA,
    gaEventLabel: EVENTS.LABELS.TICKET_WAITING_RESPONSE_CTA,
  });
};

const ticketSolved = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_SOLVED_ALERT,
    gaEventLabel: EVENTS.LABELS.TICKET_SOLVED_ALERT,
  });
};

const ticketSolvedClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.TICKET_ALERTS,
    gaEventAction: EVENTS.ACTIONS.TICKET_SOLVED_CTA,
    gaEventLabel: EVENTS.LABELS.TICKET_SOLVED_CTA,
  });
};

const analyzingOrder = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.PENDING_ORDER,
    gaEventAction: EVENTS.ACTIONS.ANALYZING_ORDER_ALERT,
    gaEventLabel: EVENTS.LABELS.ANALYZING_ORDER_ALERT,
  });
};

const canceledOrder = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.PENDING_ORDER,
    gaEventAction: EVENTS.ACTIONS.CANCELED_ORDER_ALERT,
    gaEventLabel: EVENTS.LABELS.CANCELED_ORDER_ALERT,
  });
};

const pendingOrderClick = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.PENDING_ORDER_CLICK,
    gaEventAction: EVENTS.ACTIONS.PROCESSING_ORDER_ALERT_CLICK,
    gaEventLabel: EVENTS.LABELS.PROCESSING_ORDER_ALERT_CLICK,
  });
};

const sslInProgressDisplayed = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SSL_ALERT,
    gaEventAction: EVENTS.ACTIONS.SSL_IN_PROGRESS,
    gaEventLabel: EVENTS.LABELS.SSL_IN_PROGRESS_DISPLAYED,
  });
};

const sslActiveDisplayed = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SSL_ALERT,
    gaEventAction: EVENTS.ACTIONS.SSL_ACTIVE,
    gaEventLabel: EVENTS.LABELS.SSL_ACTIVE_DISPLAYED,
  });
};

const sslErrorDisplayed = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORIES.SSL_ALERT,
    gaEventAction: EVENTS.ACTIONS.SSL_ERROR,
    gaEventLabel: EVENTS.LABELS.SSL_ERROR_DISPLAYED,
  });
};

const analytics = {
  viewAlerts,
  redirectToSubscriptionManager,
  viewInvoicesAlert,
  viewPropagationAlert,
  viewContractDomainAlert,
  viewSetupDomainAlert,
  withoutBackup,
  backupCTAClick,
  backupSevenDays,
  backupFifteenDays,
  backupThirtyDays,
  backupSuccess,
  backupRestored,
  duplicateOrder,
  duplicateOrderClick,
  ticketNew,
  ticketNewClick,
  ticketOpen,
  ticketOpenClick,
  ticketWaitingResponse,
  ticketWaitingResponseClick,
  ticketSolved,
  ticketSolvedClick,
  analyzingOrder,
  canceledOrder,
  pendingOrderClick,
  sslInProgressDisplayed,
  sslActiveDisplayed,
  sslErrorDisplayed,
};

export default analytics;
