import React, { useCallback, useState } from 'react';
import * as Styles from './ChangePrimaryDomain.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { isBRServer } from '@/utils/Validators/validation';
import { HgProducts } from '../../SupportForms.types';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const ChangePrimaryDomain = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { changePrimaryDomain: changePrimaryDomainLocale, names: formNamesLocale } = ticketsLocale.forms;
  const [newDomain, setNewDomain] = useState('');
  const [why, setWhy] = useState('');
  const [agreed, setAgreed] = useState(false);

  const isHosting = HgProducts.SHARED_ALL.includes(selectedProduct.pid) || HgProducts.PLUS_ALL.includes(selectedProduct.pid);

  const checkForm = useCallback(() => {
    let isOk = false;

    if (why.length && newDomain.length && agreed) {
      isOk = true;
    }

    return !isOk;
  }, [why, newDomain, agreed]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const productName = selectedProduct.name && selectedProduct.name.toLowerCase().replace(' ', '_');

    const data = {
      tags: ['whmcs_form_tecnico_alterar_dominio_primario', productName, selectedProduct.serverhostname],
      subject: changePrimaryDomainLocale.subject,
      key: V2Forms.CHANGE_PRIMARY_DOMAIN,
      automation: isBRServer ? 'alterar_dominio_primario' : false,
      newdomain: newDomain,
      body: `• ${changePrimaryDomainLocale.product}: ${selectedProduct.name} (${selectedProduct.domain}) - ${changePrimaryDomainLocale.userLabel}: ${selectedProduct.username}
        • Servidor: ${(selectedProduct.serverhostname || selectedProduct.dedicatedip) || ''}
        • ${changePrimaryDomainLocale.solicitation}: ${formNamesLocale.changePrimaryDomain}
        • ${changePrimaryDomainLocale.newDomainLabel}: ${newDomain}
        • ${changePrimaryDomainLocale.whyLabel}: ${why}
        • ${changePrimaryDomainLocale.agreedLabel}: ${agreed ? changePrimaryDomainLocale.yes : changePrimaryDomainLocale.no}
        • ${changePrimaryDomainLocale.productId}: ${selectedProduct.id}
      `,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
    };

    submitForm && submitForm(data);
  }, [
    submitForm,
    agreed,
    selectedProduct,
    why,
    newDomain,
    changePrimaryDomainLocale,
    formNamesLocale,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {changePrimaryDomainLocale.info(isHosting)}
      </Styles.Info>

      <Styles.Title>
        {changePrimaryDomainLocale.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Input
          name="newDomain"
          testId="newDomain"
          label={newDomain ? changePrimaryDomainLocale.newDomainLabel : ''}
          placeholder={changePrimaryDomainLocale.newDomainLabel}
          value={newDomain}
          onChange={e => setNewDomain(e.target.value)}
        />

        <Input
          name="why"
          testId="why"
          type="textarea"
          label={why ? changePrimaryDomainLocale.whyLabel : ''}
          placeholder={changePrimaryDomainLocale.whyLabel}
          value={why}
          onChange={e => setWhy(e.target.value)}
          maxLength={280}
          rows={3}
          counter
        />

        <Styles.CheckboxLabel
          htmlFor="#readTerms"
          data-testid="checkReadTerms"
          onClick={() => setAgreed(!agreed)}
        >
          <Checkbox checked={agreed} id="agreed" />
          <Styles.Text>
            {changePrimaryDomainLocale.agreedLabel}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default ChangePrimaryDomain;
