import PropTypes from 'prop-types';


const productType = PropTypes.shape({
  id: PropTypes.number,
  domain: PropTypes.string,
  username: PropTypes.string,
  domainstatus: PropTypes.string,
  nextinvoicedate: PropTypes.string,
  productname: PropTypes.string,
  type: PropTypes.string,
  gid: PropTypes.number,
  product_id: PropTypes.number,
  origin: PropTypes.string,
  orderid: PropTypes.number,
  paginafacil: PropTypes.string,
  cpanel: PropTypes.string,
  webmail: PropTypes.string,
});

export default productType;
