import styled, { css } from 'styled-components';

export const UnderMaintenance = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin-bottom: 8px;
    padding: 32px;
    text-align: center;
  `}
`;
