
import { registrobrActions, intResellerActions } from '@/config/components/ctas';

const propagationStatus = (rawData) => {
  const { data } = rawData.data;
  return data.map(domain => ({
    domain: domain.domain,
    id: domain.domain_id,
    status: domain.status ? domain.status : domain.propagation_status,
    hostingId: domain.hosting_id,
    nameServer1: domain.hosting_nameserver_1,
    nameServer2: domain.hosting_nameserver_2,
    configured: domain.configured,
  }));
};

const configuredDomainStatus = (rawData) => {
  const data = rawData.data.data[0];
  return {
    id: data.domain_id,
    domain: data.domain,
    status: data.status,
  };
};

const eppKeyGenerated = rawData => rawData.data.data.eppcode;

const allActiveDomainsList = (rawData) => {
  const { data } = rawData.data;
  const domains = data.domains || data;
  return domains.filter(domain => domain.status === 'active' && !domain.domain.includes('.com.br'));
};

const sortDomains = (domainsList) => {
  const activeList = domainsList.filter(domain => domain.status === 'active');
  const expiredList = domainsList.filter(domain => domain.status === 'expired');
  const pendingTransferList = domainsList.filter(domain => domain.status === 'pendingTransfer');
  const pendingList = domainsList.filter(domain => domain.status === 'pending');
  const cancelledList = domainsList.filter(domain => domain.status === 'cancelled');
  const registerErrorList = domainsList.filter(domain => domain.status === 'registerError');

  return [
    ...activeList,
    ...pendingTransferList,
    ...pendingList,
    ...expiredList,
    ...registerErrorList,
    ...cancelledList,
  ];
};

const mountDomainsList = (rawData) => {
  if (rawData && rawData.data && rawData.data.data) {
    if (rawData.data.data.domains) {
      return ({
        domains: sortDomains(rawData.data.data.domains),
        total: rawData.data.data.paginate.total,
      });
    }

    return sortDomains(rawData.data.data);
  }

  return [];
};

const mountDetails = (rawData) => {
  if (rawData && rawData.data && rawData.data.data) {
    const detail = rawData.data.data;
    detail.actions = detail.registrar === 'registrobr' ? registrobrActions : intResellerActions;
    return detail;
  }

  return {};
};

const assembly = {
  propagationStatus,
  configuredDomainStatus,
  eppKeyGenerated,
  allActiveDomainsList,
  mountDomainsList,
  mountDetails,
};

export default assembly;
