import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import { MktPlaceContext } from '@/contexts/MktPlace/context';

import { tagList } from '@/contexts/MktPlace/values';
import { Card } from '@/pages/MktPlace/Card';
import {
  mktPlaceAcquireButton, cardCobreFacilClickButton, cardTraktoClickButton, cardCreditasClickButton,
} from '@/utils/ThirdParties/tagManager';
import Loader from '@/components/Layout/Loader';
import ModalCollabPlay from '@/components/Modal/ModalCollabPlay';
import styles from './styles';

const ListSection = ({ t, classes }) => {
  const {
    filterLoading, renderedCards, toggleShowModal,
  } = useContext(MktPlaceContext);

  const handleClick = ({ name, modal }) => {
    if (name === 'Cobre Fácil') {
      cardCobreFacilClickButton();
    } else if (name === 'Trakto') {
      cardTraktoClickButton();
    } else if (name === 'Creditas') {
      cardCreditasClickButton();
    } else {
      mktPlaceAcquireButton({ name, menu: false });

      if (modal) {
        toggleShowModal(true);
      }
    }
  };

  return (
    filterLoading
      ? <Loader data-testid="loader" />
      : (
        <div className={classes.cardsWrapper} data-testid="cardWrapper">
          <ModalCollabPlay />

          {renderedCards.length === 0
            ? (
              <div className={classes.emptyCard}>
                <Typography className={classes.emptyTitle}>{t('mktPlace.emptyCardTitle')}</Typography>
                <Typography className={classes.emptySubTitle}>{t('mktPlace.emptyCardSubTitle')}</Typography>
              </div>
            )
            : renderedCards.map(card => (
              <Card
                testId="cardItem"
                card={card}
                handleClick={handleClick}
                modal={card.name === 'HostGator Academy'}
                key={`card_${card.name}`}
                btnLabel={t('mktPlace.acquireB')}
                tagList={tagList}
                altLayout={card.altLayout}
                featured={card.coloredBorder}
                internLink={card.internLink}
              />
            ))}
        </div>
      )
  );
};
export default withI18n()(withStyles(styles)(ListSection));
