import { useDispatch } from 'react-redux';
import { preChatActions } from '@/redux/modules';
import {
  CHARLIE_DATADOG_DIAGNOSTIC,
  CHARLIE_MYALERT_SSL,
  CHARLIE_TOKEN_REFRESH,
  XRAY_ENABLED_SUBSCRIPTIONS_IN_DIAGNOSTIC,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import logger from '@/utils/logger';

const useDiagnostic = () => {
  const [lastDispatch, setLastDispath] = useState(null);
  const dispatch = useDispatch();
  const enabledSubscriptionInDiagnostic = useFeatureIsOn(XRAY_ENABLED_SUBSCRIPTIONS_IN_DIAGNOSTIC);
  const waitToken = useFeatureIsOn(CHARLIE_TOKEN_REFRESH);
  const blockRequestsInLessThan10Sec = useFeatureIsOn(CHARLIE_DATADOG_DIAGNOSTIC);
  const enableSslAlert = useFeatureIsOn(CHARLIE_MYALERT_SSL);

  const requestDiagnostic = (location) => {
    let dateDiff = null;
    if (lastDispatch) {
      dateDiff = differenceInSeconds(new Date(), lastDispatch);
    }

    if (dateDiff && dateDiff <= 10) {
      logger.info(`tentativa de requisição de diagnostic em menos de 10s: '${location}'`);
    }

    if (((!dateDiff || dateDiff > 10) && blockRequestsInLessThan10Sec) || (!blockRequestsInLessThan10Sec)) {
      setLastDispath(new Date());
      dispatch(
        preChatActions.diagnostic.get({
          enabledSubscriptionInDiagnostic,
          waitToken,
          enableSslAlert,
        }),
      );
    }
  };

  return {
    requestDiagnostic,
  };
};

export default useDiagnostic;
