import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import LogoV2 from '@/media/logo/HgLogoV2';
import { IconNetwork, IconDropdownArrow } from '@/icons';
import { outGoingTagManager } from '@/utils/ThirdParties/tagManager';
import { useResize } from '@/hooks/useResize';
import * as Styles from './Menu.styles';
import { IconButton } from 'gatorcomponents';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

const Menu = ({
  items = [
    {
      icon: <IconNetwork />,
      label: 'Meus Sites',
      to: '/',
    },
  ],
  open = false,
  onMenuClick,
}) => {
  const windowRect = useResize();
  const history = useHistory();
  const [menuItems, setMenuItems] = useState(items);
  const location = useLocation();

  const redirectTo = (route) => {
    if (!/https?/i.test(route)) {
      return history.push(route);
    }

    window.location.href = route;
  };

  const handleOnLogoClick = () => redirectTo('/');

  const gaEvent = exitApp => exitApp && outGoingTagManager();

  const toggleActiveItem = (item, items = menuItems) => items.map(menuItem => ({
    ...menuItem,
    active: menuItem.label === item.label,
    ...(menuItem.children.length > 0 && {
      children: menuItem.children.map(child => ({
        ...child,
        active: false,
      })),
    }),
  }));

  const toggleDropdownActiveItem = (item, items = menuItems) => items
    .map(menuItem => ({
      ...menuItem,
      ...(menuItem.children.length > 0 && {
        children: menuItem.children.map(child => ({
          ...child,
          active: child.label === item.label,
        })),
      }),
      active: false,
    }));

  const toggleOpenItem = (item, items = menuItems) => items.map(menuItem => (menuItem.label === item.label ? {
    ...menuItem,
    open: !menuItem.open,
  } : menuItem));

  const handleOnMenuClick = (item, options = {}) => {
    onMenuClick && onMenuClick();

    if (item) {
      trackAmplitude(`click menu item ${item.label}`);
      setMenuItems(
        !options.dropdown
          ? toggleActiveItem(item, menuItems)
          : toggleDropdownActiveItem(item, menuItems),
      );

      gaEvent(item.exitApp);
      item.onClick();
      redirectTo(item.to);
    }
  };

  const handleOnDropdownClick = item => setMenuItems(toggleOpenItem(item, menuItems));

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [open]);

  useEffect(() => {
    const { width } = windowRect;
    if (width > 959) {
      document.body.style.overflowY = 'unset';
    }
  }, [windowRect]);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  const menuVariant = 'v2';

  const renderTag = ({ item, tagInText, tagShaking }) => (
    <Styles.Tag tagInText={tagInText} variant={menuVariant} shake={tagShaking}>
      {item.tag.toUpperCase()}
    </Styles.Tag>
  );

  const handleMenuClick = () => {
    onMenuClick && onMenuClick();
  };


  return (
    <Styles.Wrapper data-testid="menu" open={open}>
      <Styles.Content open={open}>
        <Styles.LogoButton
          data-testid="menu-logo-button"
          onClick={handleOnLogoClick}
        >
          <LogoV2 />
        </Styles.LogoButton>
        <Styles.CloseWrapper>
          <IconButton onClick={handleMenuClick} />
        </Styles.CloseWrapper>

        <Styles.ListWrapper>
          <Styles.List>
            {menuItems.map((item) => {
              if (!item.show) {
                return;
              }

              let isActive = item.to === location.pathname || item.to.split('/')[1] === location.pathname.split('/')[1];

              if (item.id === 'drawer-menu-button-emails') {
                const firstPartOfLocation = location.pathname.split('/')[1];
                isActive = firstPartOfLocation.includes('emails') || firstPartOfLocation.includes('e-mails');
              }

              return (
                <Styles.Item
                  hide={item.hide}
                  key={item.id}
                  showInDevice={item.showInDevice}
                >
                  {item.children.length > 0 ? (
                    <>
                      <Styles.Button
                        data-testid={item.id}
                        open={item.open}
                        onClick={() => handleOnDropdownClick(item)}
                        variant={menuVariant}
                      >
                        <Styles.ButtonIcon>
                          {item.icon}
                        </Styles.ButtonIcon>
                        <Styles.ButtonText variant={menuVariant}>
                          {item.label}
                        </Styles.ButtonText>
                        <Styles.DropdownIcon>
                          <IconDropdownArrow />
                        </Styles.DropdownIcon>
                      </Styles.Button>

                      <Styles.Dropdown open={item.open}>
                        {item.children.map(child => child.show && (
                          <Styles.DropdownItem key={child.label} active={child.active} variant={menuVariant}>
                            <Styles.Button
                              data-testid={child.id}
                              onClick={() => handleOnMenuClick(child, { dropdown: true })}
                              variant={menuVariant}
                              insideDropdown
                            >
                              <Styles.ButtonIcon>
                                {child.icon}
                              </Styles.ButtonIcon>
                              <Styles.ButtonText variant={menuVariant}>
                                {child.label}
                              </Styles.ButtonText>
                            </Styles.Button>
                          </Styles.DropdownItem>
                        ))}
                      </Styles.Dropdown>
                    </>
                  ) : (
                    <Styles.Button
                      active={isActive}
                      data-testid={item.id}
                      onClick={() => handleOnMenuClick(item)}
                      variant={menuVariant}
                    >
                      <Styles.ButtonIcon>
                        {item.icon}
                      </Styles.ButtonIcon>
                      <Styles.ButtonText variant={menuVariant}>
                        {item.label}

                        {(item.tagInText || item.tag) && (
                          renderTag({ item, tagInText: true, tagShaking: item.tagShaking })
                        )}
                      </Styles.ButtonText>
                    </Styles.Button>
                  )}
                </Styles.Item>
              );
            })}
          </Styles.List>
        </Styles.ListWrapper>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default withI18n()(Menu);
