import useLocale from '@/hooks/useLocale';
import { useEffect, useState } from 'react';

const useSubscriptionHeaderProps = (item, testId = 'subscription-header-handler') => {
  const [headerProps, setHeaderProps] = useState({
    testId,
    title: '...',
    description: '',
  });
  const { billing: billingLocale } = useLocale();

  useEffect(() => {
    if (item.type) {
      const isDomain = item.type === 'domain';
      const {
        productName,
        domain,
      } = item;
      const getPageTitle = () => {
        if (isDomain) {
          return `${billingLocale.domainLabel} -`;
        }

        return domain ? `${productName} -` : productName;
      };

      setHeaderProps(props => ({
        ...props,
        title: getPageTitle(),
        description: domain || '',
      }));
    }
  }, [billingLocale, item]);

  return headerProps;
};

export default useSubscriptionHeaderProps;
