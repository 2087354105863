import { WHMCS_URL } from '@/config';


export const BOLETO_URL = invoiceId => `${WHMCS_URL}/modules/gateways/boleto/boleto.php?invoiceid=${invoiceId}`;
export const OLD_CLIENT_AREA = `${WHMCS_URL}/clientarea.php`;
export const MY_ACCOUNT_URL = `${OLD_CLIENT_AREA}?action=details`;
export const PRODUCT_DETAILS = `${OLD_CLIENT_AREA}?action=productdetails`;
export const LINK_URL = `${WHMCS_URL}`;
export const LOGOUT_URL = `${LINK_URL}/api/v2/logout`;
export const PREVIEW_WEBSITEBUILDER_TEMPLATE = templateId => `http://preview.websitebuilder.com/website-templates/template/${templateId}/desktop`;

export const WHMCS_JWT_PUBLIC_KEY_URL = `${WHMCS_URL}/api/v2/jwt/key`;
export const WHMCS_JWT_REFRESH_TOKEN_URL = token => `${WHMCS_URL}/api/v2/jwt/refresh/${token}`;
