import React, { useCallback, useState } from 'react';
import { BRAVO_MAIL_TIMER_DISABLE_ON_SEND, BRAVO_TIMER_VALIDATE_EMAIL, CHARLIE_EMAIL_VERIFY_V2 } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { EmailVerifyV1 } from './v1';
import * as Styles from './EmailVerify.styles';
import HgOrangeLogo from '@/media/logo/HgOrangeLogo/HgOrangeLogo';
import { EmailSteps, emailErrors } from './EmailVerify.types';
import { ChangeEmail } from './components/ChangeEmail';
import useLocale from '@/hooks/useLocale/useLocale';
import { FieldValidators } from '@/utils/FieldValidators';
import { useDispatch, useSelector } from 'react-redux';
import { changeEmailVerify, emailChangeFlow, resendEmailVerify } from '@/redux/actions/emailVerify';
import {
  RECEIVE_CHANGE_EMAIL, ERROR_CHANGE_EMAIL, RECEIVE_RESEND_EMAIL, ERROR_RESEND_EMAIL,
} from '@/redux/actions/actionsTypes';
import { commonActions, formsActions } from '@/redux/modules';
import { addSeconds, differenceInSeconds, format } from 'date-fns';
import { COUNTRY } from '@/config';
import { changeEmailTagManager, emailVerifyResendLinkTagManager, emailVerifySkipFlowTagManager } from '@/utils/ThirdParties/tagManager';
import { SentEmail } from './components/SentEmail';
import { ValidateEmail } from './components/ValidateEmail';
import { IconButton } from 'gatorcomponents';
import { emailFlowSkip } from '@/redux/actions/summary';
import { useHistory, useParams } from 'react-router';

const changeMailInitialState = {
  email: {
    value: '',
    error: '',
  },
  checkEmail: {
    value: '',
    error: '',
  },
  password: {
    value: '',
    error: '',
  },
};

const EmailVerify = ({
  forceScreen = null,
}) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { changeFlow } = params;

  const {
    emailVerify: emailVerifyLocale,
    ticket: ticketsLocale,
    routes: routesLocale,
  } = useLocale();
  const {
    changeMail: changeEmailLocale,
    sentEmail: sentEmailLocale,
    validateEmail: validateEmailLocale,
  } = emailVerifyLocale;

  const clientInfo = useSelector(state => state.summary);
  const forceUrl = useSelector(state => state.emailVerify.forceUrl);
  const { loadingChange, loadingResend } = useSelector(state => state.emailVerify);
  const featureToggleEmailNumberFlowSkip = useSelector(state => state.featureToggles.toggles.portal.emailFlowSkip);

  const initialStep = () => {
    if (forceScreen) {
      return forceScreen;
    }

    return changeFlow
      ? EmailSteps.CHANGE_EMAIL
      : EmailSteps.VALIDATE_EMAIL;
  };

  const [currentStep, setCurrentStep] = useState(initialStep());
  const [changeEmailForm, setChangeEmailForm] = useState(changeMailInitialState);

  const emailTimer = useFeatureIsOn(BRAVO_TIMER_VALIDATE_EMAIL);
  const useEmailVerifyV2 = useFeatureIsOn(CHARLIE_EMAIL_VERIFY_V2);
  const checkEmailTimer = useFeatureIsOn(BRAVO_MAIL_TIMER_DISABLE_ON_SEND);

  let emailValidationRequested = localStorage.getItem('emailValidationRequested');
  let emailValidationRequestedDiff = 0;

  if (emailValidationRequested) {
    emailValidationRequestedDiff = differenceInSeconds(new Date(emailValidationRequested), new Date());
  }
  if (emailValidationRequestedDiff <= 0) {
    emailValidationRequested = null;
    localStorage.removeItem('emailValidationRequested');
  }

  const [countDownResend, setCountDownResend] = useState(emailValidationRequested
    ? emailValidationRequestedDiff
    : 0);

  const closeEmailFlow = useCallback(() => {
    dispatch(emailFlowSkip(clientInfo.id));
    dispatch(emailChangeFlow(false));
    emailVerifySkipFlowTagManager();
  }, [dispatch, clientInfo]);

  const openForm = useCallback(() => {
    dispatch(formsActions.forceState.set({
      key: ticketsLocale.forms.names.withoutEmailAccess,
      params: {
        selectedProduct: ticketsLocale.forms.namewithoutEmailAccess,
      },
    }));
    closeEmailFlow();
    history.push(`${routesLocale.help}${routesLocale.supportforms}`);
  }, [dispatch, history, routesLocale, ticketsLocale, closeEmailFlow]);

  const requestEmailChange = useCallback(() => {
    dispatch(changeEmailVerify({
      clientId: clientInfo.id,
      clientEmailCurrent: clientInfo.email,
      clientNewEmail: changeEmailForm.email.value,
      password: changeEmailForm.password.value,
    })).then((response) => {
      let isPasswordError = false;
      let isSameMail = false;
      let hasUnblockTime = false;
      let unblock = null;
      let attempts = false;
      let hasPendingAttempts = false;

      switch (response.type) {
        case RECEIVE_CHANGE_EMAIL:
          if (response && response.email && response.email.data && response.email.data.pending_attempts !== null && emailTimer) {
            attempts = response.email.data.pending_attempts;
            hasPendingAttempts = true;
            dispatch(commonActions.notifications.set({
              label: (
                <p>
                  {emailVerifyLocale.feedback.emailSendAttempts({
                    attempts,
                    used: COUNTRY === 'br' ? 10 - attempts : 5 - attempts,
                  })}
                </p>
              ),
              type: 'success',
            }));
          }

          if (!hasPendingAttempts) {
            dispatch(commonActions.notifications.set({
              label: (
                `${emailVerifyLocale.feedback.changeSuccess}`
              ),
              type: 'success',
            }));
          }
          setCurrentStep(EmailSteps.SENT_EMAIL);
          changeEmailTagManager();
          break;
        case ERROR_CHANGE_EMAIL:
          if (response && response.info && response.info.response && response.info.response.data.internal_code === emailErrors.DATA_IN_USE) {
            isSameMail = true;
            dispatch(commonActions.notifications.set({
              label: (
                `${emailVerifyLocale.feedback.dataInUse}`
              ),
              type: 'error',
            }));
          }

          if (response && response.info && response.info.response.data.data === 'Password dont match') {
            isPasswordError = true;
            dispatch(commonActions.notifications.set({
              label: (
                `${emailVerifyLocale.feedback.password}`
              ),
              type: 'error',
            }));
          }

          if (response && response.info && response.info.response.data.message === 'Email already in use. ') {
            isPasswordError = true;
            dispatch(commonActions.notifications.set({
              label: (
                `${emailVerifyLocale.feedback.emailInDataBase}`
              ),
              type: 'error',
            }));
          }

          if (!isSameMail && response && response.info && response.info.response && response.info.response.data.data && response.info.response.data.data.unblock_time && emailTimer) {
            hasUnblockTime = true;
            unblock = new Date(response.info.response.data.data.unblock_time);


            if (response.info.response.data.internal_code === emailErrors.ALREADY_VALIDATED) {
              dispatch(commonActions.notifications.set({
                label: (
                  <p>
                    {emailVerifyLocale.feedback.alreadyValidated({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                  </p>
                ),
                type: 'error',
              }));
            } else {
              dispatch(commonActions.notifications.set({
                label: (
                  <p>
                    {emailVerifyLocale.feedback.noMoreAttempts({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                  </p>
                ),
                type: 'error',
              }));
            }
          }

          if (!isSameMail && !isPasswordError && !hasUnblockTime) {
            dispatch(commonActions.notifications.set({
              label: (
                `${emailVerifyLocale.feedback.changeError}`
              ),
              type: 'error',
            }));
          }
          break;
        default: break;
      }

      if (emailTimer && !isPasswordError && !isSameMail) {
        setCountDownResend(120);
        localStorage.setItem('emailValidationRequested', addSeconds(new Date(), 120));
      }
    });
  }, [changeEmailForm, clientInfo, emailTimer, dispatch, emailVerifyLocale]);

  const getValidateEmailProps = useCallback(() => {
    const handleResendEmail = () => {
      if (countDownResend <= 0) {
        dispatch(resendEmailVerify({
          clientId: clientInfo.id,
          clientEmail: clientInfo.email,
        })).then((response) => {
          let hasDisabledTil = false;
          switch (response.type) {
            case RECEIVE_RESEND_EMAIL:
              if (response.client.data && response.client.data.pending_attempts >= 0) {
                const attempts = response.client.data.pending_attempts;
                dispatch(commonActions.notifications.set({
                  label: (
                    <p>
                      {emailVerifyLocale.feedback.emailSendAttempts({ attempts, used: COUNTRY === 'br' ? 10 - attempts : 5 - attempts })}
                    </p>
                  ),
                  type: 'success',
                }));
              } else {
                dispatch(commonActions.notifications.set({
                  label: emailVerifyLocale.feedback.resendSuccess,
                  type: 'success',
                }));
              }
              emailVerifyResendLinkTagManager();
              break;
            case ERROR_RESEND_EMAIL:
              if (response.client.data && response.client.data.unblock_time) {
                const unblock = new Date(response.client.data.unblock_time);
                dispatch(commonActions.notifications.set({
                  label: (
                    <p>
                      {emailVerifyLocale.feedback.noMoreAttempts({ hours: format(unblock, 'HH:mm'), date: format(unblock, 'dd/MM/yyyy') })}
                    </p>
                  ),
                  type: 'error',
                }));
              } else if (response && response.client && response.client.data && response.client.data && response.client.data.disabled_til && checkEmailTimer) {
                hasDisabledTil = true;
                const pendingAmount = response.client.data.disabled_til;
                const tokenDiff = differenceInSeconds(new Date(pendingAmount), new Date());
                setCountDownResend(tokenDiff);
                localStorage.setItem('emailValidationRequested', new Date(pendingAmount));
              } else {
                dispatch(commonActions.notifications.set({
                  label: emailVerifyLocale.feedback.resendError,
                  type: 'error',
                }));
              }
              break;
            default: break;
          }

          if (emailTimer && !hasDisabledTil) {
            setCountDownResend(120);
            localStorage.setItem('emailValidationRequested', addSeconds(new Date(), 120));
          }
        });
      }
    };

    const handleChangeEmail = () => {
      setCurrentStep(EmailSteps.CHANGE_EMAIL);
    };

    return ({
      emailVerifyLocale,
      validateEmailLocale,
      clientInfo,
      countDownResend,
      setCountDownResend,
      handleResendEmail,
      loadingResend,
      handleChangeEmail,
      openForm,
    });
  }, [
    emailVerifyLocale,
    validateEmailLocale,
    clientInfo,
    countDownResend,
    setCountDownResend,
    checkEmailTimer,
    dispatch,
    emailTimer,
    loadingResend,
    openForm,
  ]);

  const handleGoBack = useCallback(() => {
    if (history.length) {
      dispatch(emailChangeFlow(false, forceUrl));
      history.push(forceUrl);
    }

    setCurrentStep(EmailSteps.VALIDATE_EMAIL);
  }, [dispatch, forceUrl, history]);

  const getChangeEmailProps = useCallback(() => {
    const validateFields = () => {
      const newFormValue = { ...changeEmailForm };
      let hasErrors = false;

      const hasInvalidEmail = !FieldValidators.email(newFormValue.email.value);
      const emailsDoesntMatch = newFormValue.checkEmail.value.length && newFormValue.checkEmail.value !== newFormValue.email.value;
      const passwordTooShort = newFormValue.password.value.length && newFormValue.password.value.length < 6;

      if (hasInvalidEmail) {
        hasErrors = true;
        newFormValue.email.error = changeEmailLocale.form.errors.invalidEmail;
      }

      if (emailsDoesntMatch) {
        hasErrors = true;
        newFormValue.checkEmail.error = changeEmailLocale.form.errors.emailsDoesntMatch;
      }

      if (passwordTooShort) {
        hasErrors = true;
        newFormValue.password.error = changeEmailLocale.form.errors.passwordTooShort;
      }

      setChangeEmailForm(newFormValue);
      return (hasErrors);
    };

    const disabledSubmit = () => {
      let isDisabled = false;
      const { email, checkEmail, password } = changeEmailForm;

      if (!email.value.length || !checkEmail.value.length || password.value.length < 6) {
        isDisabled = true;
      }

      if (email.error || checkEmail.error || password.error) {
        isDisabled = true;
      }

      return isDisabled;
    };

    return ({
      changeEmailLocale,
      changeEmailForm,
      setChangeEmailForm,
      validateFields,
      email: clientInfo.email,
      disabledSubmit,
      requestEmailChange,
      countDownResend,
      setCountDownResend,
      loadingChange,
      emailVerifyLocale,
      handleGoBack,
      openForm,
      changeFlow,
    });
  }, [
    changeEmailLocale,
    changeEmailForm,
    clientInfo,
    countDownResend,
    loadingChange,
    emailVerifyLocale,
    requestEmailChange,
    openForm,
    changeFlow,
    handleGoBack,
  ]);

  const getSentEmailProps = useCallback(() => {
    const handleResendEmail = () => {
      if (countDownResend <= 0) {
        requestEmailChange();
      }
    };

    return {
      sentEmailLocale,
      clientInfo,
      countDownResend,
      emailVerifyLocale,
      setCountDownResend,
      handleResendEmail,
      loadingChange,
      openForm,
    };
  }, [sentEmailLocale, clientInfo, countDownResend, setCountDownResend, emailVerifyLocale, requestEmailChange, loadingChange, openForm]);

  const steps = {
    [EmailSteps.VALIDATE_EMAIL]: <ValidateEmail {...getValidateEmailProps()} />,
    [EmailSteps.CHANGE_EMAIL]: <ChangeEmail {...getChangeEmailProps()} />,
    [EmailSteps.SENT_EMAIL]: <SentEmail {...getSentEmailProps()} />,
  };

  if (!useEmailVerifyV2) {
    return <EmailVerifyV1 />;
  }

  return (
    <Styles.ContainerWrapper>
      <Styles.Container>
        {(featureToggleEmailNumberFlowSkip || changeFlow) && (
          <Styles.CloseButtonWrapper>
            <IconButton
              icon="IconClose"
              onClick={changeFlow ? handleGoBack : closeEmailFlow}
            />
          </Styles.CloseButtonWrapper>
        )}

        <Styles.LogoWrapper>
          <HgOrangeLogo />
        </Styles.LogoWrapper>

        {steps[currentStep]}
      </Styles.Container>
    </Styles.ContainerWrapper>
  );
};

export default EmailVerify;
