const styles = theme => ({
  loader: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    color: theme.color.white,
  },
  fullscreen: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    zIndex: 999999999,
    color: theme.color.white,
  },
  progress: {
    display: 'inline-block',
    marginTop: '2px',
    color: theme.color.white,
  },
  progressFull: {
    position: 'absolute',
    left: 'calc(50% - 40px)',
    top: 'calc(50% - 40px)',
  },
});

export default styles;
