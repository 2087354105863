import React from 'react';
import AcademyPassContextProvider from '@/contexts/academyPass/context';
import AcademyPassCta from '@/components/AcademyPass/AcademyPassCta';

const CoursesPage = () => (
  <AcademyPassContextProvider>
    <AcademyPassCta />
  </AcademyPassContextProvider>
);

export default (CoursesPage);
