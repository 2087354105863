import React from 'react';
import esContent from './ticket.es';
import { Link } from 'gatorcomponents';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';

export default {
  ...esContent,
  forms: {
    ...esContent.forms,
    internalMigration: {
      ...esContent.forms.internalMigration,
      links: {
        ...esContent.forms.internalMigration.links,
        migration: () => (
          <>
            {'Lea nuestros '}
            <Link
              text="Términos de Migración"
              href="https://www.hostgator.mx/terminos-de-migracion"
              target="_blank"
            />
            .
          </>
        ),
      },
      checkbox: {
        ...esContent.forms.internalMigration.checkbox,
        terms: () => (
          <>
            {'Confirmo que '}
            <strong>he leído y acepto </strong>
            {' los '}
            <Link
              text="Términos de migración."
              target="_blank"
              href="https://www.hostgator.mx/terminos-de-migracion"
            />
          </>
        ),
      },
    },
    installExternalSsl: {
      ...esContent.forms.installExternalSsl,
      checkboxLabel: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $240 MXN',
      bodyFields: {
        ...esContent.forms.installExternalSsl.bodyFields,
        agree: 'Si, estoy de acuerdo con el cobro del Certificado SSL por $240 MXN',
      },
    },
    privateSslCertificate: {
      title: '¡Complete los datos de abajo!',
      send: 'Enviar',
      info: (
        <>
          <strong>Importante:</strong>
          <ul>
            <li>
              Para que el SSL solicitado sea generado, será adicionada una cuenta de correo electrónico &quot;hostmaster@dominio&quot;. En algunos casos, solicitaremos la creación de esta cuenta. Si tienes alguna duda, entra en contacto con nuestro equipo a través de nuestros Canales de Atención.
            </li>
            <li>
              Este certificado no da derecho a la barra de direcciones verde de Comodo. En caso de que necesites un certificado SSL con esta caracterísitica, por favor llena la solicitud del Certificado SSL EV.
            </li>
            <li>
              En el campo URL para la instalación del SSL, asegúrate de informar el dominio correcto, ya que no es posible emitir el certificado nuevamente para otro dominio.
            </li>
            <li>
              El SSL funcionará unicamente para el dominio informado a continuación (por ejemplo: nombrededominio.com).
            </li>
            <li>
              El certificado SSL es válido únicamente para ser instalado en los servidores de HostGator.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año`,
      },
      subject: 'Soporte Técnico - Certificado SSL Privado',
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Privado',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(516.00)} MXN/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
    widlcardSslCertificate: {
      ...esContent.forms.widlcardSslCertificate,
      placeholders: {
        domain: 'URL para la instalación del SSL',
        domainInfo: 'El SSL funcionará únicamente en la URL informada, la cual puede ser un dominio o un subdominio. Por ejemplo, puedes usar nombredeldominio.com o tienda.nombredeldominio.com. ',
        agreed: `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(2892.00)} MXN/año`,
      },
      body: {
        product: selectedProduct => `Producto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuario: ${selectedProduct.username}`,
        request: 'Solicitación: Certificado SSL Wildcard',
        domain: domain => `URL para la instalación del SSL: ${domain}`,
        agreed: agreed => `Si, estoy de acuerdo con el cobro del Certificado SSL por ${formatCurrency(2892.00)} MXN/año: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do producto: ${selectedProduct.id}`,
      },
    },
  },
};
