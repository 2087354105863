import useLocale from '@/hooks/useLocale';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faqActions } from '@/redux/modules';

const useHelpLinks = () => {
  const dispatch = useDispatch();
  const { ticket: ticketLocale } = useLocale();

  const defaultFilterChips = [
    {
      id: 'main',
      label: ticketLocale.listPageFaq.helpLinks.filter.chips.main,
      value: 'main',
      checked: true,
    },
    {
      id: 'financial',
      label: ticketLocale.listPageFaq.helpLinks.filter.chips.financial,
      value: 'financial',
      checked: false,
    },
    {
      id: 'domain',
      label: ticketLocale.listPageFaq.helpLinks.filter.chips.domain,
      value: 'domain',
      checked: false,
    },
    {
      id: 'mail',
      label: ticketLocale.listPageFaq.helpLinks.filter.chips.mail,
      value: 'mail',
      checked: false,
    },
    {
      id: 'site',
      label: ticketLocale.listPageFaq.helpLinks.filter.chips.site,
      value: 'site',
      checked: false,
    },
  ];

  const [filterOptions, setFilterOptions] = useState(defaultFilterChips);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const faqQuestions = useSelector(state => state.newFaq);

  const requestFaq = useCallback(() => {
    dispatch(faqActions.faq.request.faqMain());
    dispatch(faqActions.faq.request.faqFinancial());
    dispatch(faqActions.faq.request.faqDns());
    dispatch(faqActions.faq.request.faqMail());
    dispatch(faqActions.faq.request.faqSite());
  }, [dispatch]);

  const hasFaq = useCallback(() => faqQuestions
    && faqQuestions.mainSupportPage.loaded
    && faqQuestions.financialSupportPage.loaded
    && faqQuestions.dnsSupportPage.loaded
    && faqQuestions.mailSupportPage.loaded
    && faqQuestions.siteSupportPage.loaded,
  [faqQuestions]);

  const filterLinks = useCallback(() => {
    if (hasFaq()) {
      const activeOption = [...filterOptions].find(option => option.checked);

      if (activeOption.id === 'main') {
        setFilteredLinks(faqQuestions.mainSupportPage.questions);
      }
      if (activeOption.id === 'financial') {
        setFilteredLinks(faqQuestions.financialSupportPage.questions);
      }
      if (activeOption.id === 'domain') {
        setFilteredLinks(faqQuestions.dnsSupportPage.questions);
      }
      if (activeOption.id === 'mail') {
        setFilteredLinks(faqQuestions.mailSupportPage.questions);
      }
      if (activeOption.id === 'site') {
        setFilteredLinks(faqQuestions.siteSupportPage.questions);
      }
    }
  }, [filterOptions, setFilteredLinks, faqQuestions, hasFaq]);

  const setFaq = useCallback(() => {
    filterLinks();
  }, [filterLinks]);

  const useHelpLinksData = {
    defaultFilterChips,
    setFilterOptions,
    filterOptions,
    filteredLinks,
    filterLinks,
    requestFaq,
    faqQuestions,
    setFaq,
  };

  return useHelpLinksData;
};

export default useHelpLinks;
