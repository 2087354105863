import React, { useEffect, useState } from 'react';
import * as Styles from './WelcomeCheckListCard.styles';
import { Link } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import checkOn from '@/media/newsite/check_circle.svg';
import checkOff from '@/media/newsite/unchecked_circle.svg';
import lock from '@/media/newsite/lock.svg';

const WelcomeCheckListCard = ({
  testId = 'welcomecard',
  descriptions = [],
  button = null,
  links = [{ label: 'link', url: 'https://www.hostgator.com.br/' }],
  onClick = null,
  logo = null,
  title = 'Title',
  step = 1,
  wasViewed = false,
  isChecked = false,
  enabledCheckButton = false,
  welcomeChecklistBannerAnalytics = null,
  enabledUnCheckButton = false,
}) => {
  const { sites: sitesLocale } = useLocale();
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isChecked);
  }, [isChecked]);

  const handlerCheckStep = () => {
    if (enabledCheckButton && enabledUnCheckButton) {
      onClick && onClick(step, !isSelected);
      setSelected(!isSelected);
    }
  };

  const executeGA = (ga) => {
    if (welcomeChecklistBannerAnalytics) {
      switch (ga) {
        case 'clickVerifyDNS':
          welcomeChecklistBannerAnalytics.clickVerifyDNS();
          break;
        case 'clickConfigHostgatorDomain':
          welcomeChecklistBannerAnalytics.clickConfigHostgatorDomain();
          break;
        case 'clickConfigExternalDomain':
          welcomeChecklistBannerAnalytics.clickConfigExternalDomain();
          break;
        case 'clickEmailConfig':
          welcomeChecklistBannerAnalytics.clickEmailConfig();
          break;
        case 'clickWordpress':
          welcomeChecklistBannerAnalytics.clickWordpress();
          break;
        case 'clickVerifySSL':
          welcomeChecklistBannerAnalytics.clickVerifySSL();
          break;
        case 'clickLinkLadingPage':
          welcomeChecklistBannerAnalytics.clickLinkLadingPage();
          break;
        default:
          break;
      }
    }
  };

  return (
    <Styles.Wrapper data-testid={testId} isChecked={isSelected}>
      <Styles.Content>
        <Styles.Header>
          <Styles.Container>
            <Styles.Step testId={`${testId}-step`}>
              {sitesLocale.welcomeChecklistBanner.header.step}
              {' '}
              {step}
            </Styles.Step>
          </Styles.Container>
          <Styles.IconCheck disabled={!(enabledCheckButton && enabledUnCheckButton)} testId={`${testId}-check-${step}`} onClick={() => handlerCheckStep()} src={isSelected ? checkOn : checkOff} />
        </Styles.Header>

        <Styles.ContentTitle>
          <Styles.Logo src={logo} />
          {title}
        </Styles.ContentTitle>

        {wasViewed && (
          <Styles.ContentButtons>
            {button}
          </Styles.ContentButtons>
        )}

        <Styles.ContentDescriptions testId={`${testId}-descriptions`} wasViewed>
          {descriptions.map((description, index) => (<Styles.Description testId={`${testId}-description-${index}`}>{description}</Styles.Description>))}
        </Styles.ContentDescriptions>

        {wasViewed && (
          <Styles.ContentLinks testId={`${testId}-links`}>
            {links.map((data, index) => (
              <Link testId={`${testId}-link-${index}`} text={data.dataLink.label} href={data.dataLink.url} onClick={() => executeGA(data.ga)} />
            ))}
          </Styles.ContentLinks>
        )}

      </Styles.Content>

      {!wasViewed && (
        <Styles.ContentDisabledBlock>
          <Styles.IconLock testId={`${testId}-lock-${step}`} src={lock} />
          <Styles.BlockedText>
            {sitesLocale.welcomeChecklistBanner.blockMessage}
          </Styles.BlockedText>
        </Styles.ContentDisabledBlock>
      )}
    </Styles.Wrapper>
  );
};

export default WelcomeCheckListCard;
