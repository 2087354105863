import { useMemo } from 'react';
import rules from './useWizardWordpressThemes.rules';

const useWizardWordpressThemes = (selectedTheme = {}) => {
  const themes = useMemo(
    () => rules.themes[selectedTheme],
    [selectedTheme],
  );

  return themes;
};

export default useWizardWordpressThemes;
