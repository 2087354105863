import React from 'react';
import * as Styles from './WizardPluginCard.styles';
import { SelectChip } from '@/pages/common/v1/SelectChip';
import { wizardLocale } from '@/pages/sites/WizardHandler/WizardHandler.locale.jsx';

const WizardPluginCard = ({
  title = 'Plugin Card',
  description = 'Plugin Description',
  image,
  handleChangePlugin,
  selected,
  cardValue,
  id,
}) => {
  const handleChange = (selectedCard) => {
    handleChangePlugin && handleChangePlugin(selectedCard);
  };

  return (
    <Styles.Wrapper>

      <Styles.TitleWrapper>
        <Styles.PluginImage src={image} />
        <Styles.Title data-testid="plugin-card-title">{title}</Styles.Title>
      </Styles.TitleWrapper>

      <Styles.PluginDescription data-testid="plugin-card-description">
        {description}
      </Styles.PluginDescription>

      <Styles.ButtonsWrapper>
        <SelectChip
          onChange={() => handleChange(cardValue)}
          title={selected ? wizardLocale.tools.wordPressInstall.plugin.selected : wizardLocale.tools.wordPressInstall.plugin.use}
          checked={selected}
          value={cardValue}
          justify="center"
          id={id}
          testId="plugin-card-select-button"
          type="checkbox"
        />
      </Styles.ButtonsWrapper>
    </Styles.Wrapper>
  );
};

export default WizardPluginCard;
