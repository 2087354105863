import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 156px;
    padding: 80px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 20px;
      gap: 52px
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 360px;
    padding-top: 90px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding-top: 0;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.lg};
    font-weight: ${theme.v2.font.weights.medium};
    margin-bottom: 5px;
    margin-top: 0px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.md};
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-bottom: 33px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const InputWrapper = styled.div`
  width: 300px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 48px;
  margin-top: 32px;
`;

export const Img = styled.img`
  width: 30px;
`;
export const Confetti = styled.img`
  left: -200px;
  position: absolute;
  top: 50px;
  width: 650px;
`;

export const PreviewWrapper = styled.div`
${({ theme }) => css`
  padding-top: 60px;
  position: relative;
  transform: rotate(8deg);

  @media (max-width: ${theme.v2.breakpoints.md}) {
    padding-top: 0;
    }
  `}
`;

export const Faq = styled.div`
  ${({ theme }) => css`
    margin-bottom: 66px;
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    width: 400px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: auto;
    }
  `}
`;

export const FaqTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-bottom: 25px;
  `}
`;

export const FaqLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    text-decoration: underline;
  `}
`;
