import React from 'react';
import {
  call, takeLatest, put, select, take,
} from 'redux-saga/effects';
import { authActions } from '@/redux/modules/auth';
import { api } from '@/utils/api';
import { commonActions } from '@/redux/modules/common';
import { locale } from '@/utils/locale';
import { DomainsActionTypes } from './domains.types';
import {
  DOMAIN_RENEWAL_URL, DOMAIN_GENERATE_NEW_INVOICE, USER_DOMAINS_URL, DOMAIN_DETAIL_URL, USER_DOMAINS_ELEGIBLE,
} from '@/config/api';
import domainsActions from './domains.actions';
import domainsAssembly from './domains.assembly';
import { ORDER_EPP_KEY_MODAL_STEP } from '@/components/Domain/ModalEppKey/ModalEppKey.enum';
import logger from '@/utils/logger';

function* requestActivatingRenewal(action) {
  const { domainData, callBackFunction } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {
    data: {
      donotrenew: domainData.donotrenew,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: DOMAIN_RENEWAL_URL(domainData.id),
  };

  try {
    yield call(api.whmcs.put, request);

    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('domains.notifications.activateRenewal.success.0')} `}
          <strong>{domainData.name}</strong>
          {`${locale('domains.notifications.activateRenewal.success.1')} `}
        </p>
      ),
      type: 'success',
    }));
    callBackFunction();
    yield take([DomainsActionTypes.GET_DOMAINS_SUCCESS, DomainsActionTypes.GET_DOMAINS_FAILURE]);
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('domains.notifications.activateRenewal.failure.0')} `}
          <strong>{domainData.name}</strong>
          {`${locale('domains.notifications.activateRenewal.failure.1')} `}
        </p>
      ),
      type: 'error',
    }));
  }
}

function* requestGenerationNewInvoice(action) {
  const { domainData, callBackFunction } = action.payload;
  const { token } = yield select(state => state.auth);

  const request = {
    data: {
      donotrenew: domainData.id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: DOMAIN_GENERATE_NEW_INVOICE(domainData.id),
  };

  try {
    yield put(domainsActions.domains.generateNewInvoice.loading(true));
    const response = yield call(api.whmcs.put, request);

    if (response.data.data.invoiceid === undefined) {
      throw new Error('Its not returned invoice id');
    }

    callBackFunction(response.data.data.invoiceid);
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('domains.notifications.generateNewInvoice.failure.0')} `}
        </p>
      ),
      type: 'error',
    }));
    yield put(domainsActions.domains.generateNewInvoice.failure());
    yield put(domainsActions.domains.generateNewInvoice.loading(false));
  }
}

function* getPropagationStatus() {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: 'api/v2/clientarea/br/domains/propagation',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const propagationStatus = domainsAssembly.propagationStatus(response);
    yield put(domainsActions.propagationStatus.success());
    yield put(domainsActions.propagationStatus.set(propagationStatus));
  } catch (e) {
    yield put(domainsActions.propagationStatus.failure());
  }
}

function* getConfiguredDomainStatus(action) {
  const domain = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/domains/domain-propagation/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const propagationStatus = domainsAssembly.configuredDomainStatus(response);
    yield put(domainsActions.configuredStatusDomain.success());
    yield put(domainsActions.configuredStatusDomain.set(propagationStatus));
  } catch (e) {
    yield put(domainsActions.configuredStatusDomain.failure());
  } finally {
    yield put(domainsActions.configuredStatusDomain.loaded());
  }
}

function* setConfiguredDomain(action) {
  const id = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: 'api/v2/clientarea/br/domains/set-external-domain-configured-flag',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: {
      hosting_id: id,
      configured: true,
    },
  };

  try {
    yield call(api.whmcs.post, request);
    yield put(domainsActions.setDomainConfiguration.success());
  } catch (e) {
    yield put(domainsActions.setDomainConfiguration.failure());
  }
}

function* setAllActiveDomainsList(action) {
  const setModalStatus = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: USER_DOMAINS_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  setModalStatus(ORDER_EPP_KEY_MODAL_STEP.LOADING_KEY);

  try {
    const response = yield call(api.whmcs.get, request);
    const activeDomainList = domainsAssembly.allActiveDomainsList(response);
    yield put(domainsActions.activeDomainList.set(activeDomainList));
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.SELECT_DOMAIN);
    yield put(domainsActions.activeDomainList.success());
  } catch (e) {
    yield put(domainsActions.activeDomainList.failure());
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.KEY_FAILURE);
  }
}

function* setGeneratedEppKey(action) {
  const { domainId, setModalStatus } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/domain/${domainId}/eppcode`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  setModalStatus(ORDER_EPP_KEY_MODAL_STEP.LOADING_KEY);

  try {
    const response = yield call(api.whmcs.get, request);
    const keyGenerated = domainsAssembly.eppKeyGenerated(response);
    yield put(domainsActions.modalEppKey.set(keyGenerated));
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.KEY_SUCCESS);
    yield put(domainsActions.modalEppKey.success());
  } catch (e) {
    setModalStatus(ORDER_EPP_KEY_MODAL_STEP.KEY_FAILURE);
    yield put(domainsActions.setDomainConfiguration.failure());
  }
}

function* requestDomains(action) {
  const { payload } = action;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `${(payload && payload.page) ? `${USER_DOMAINS_URL}?page=${payload.page}` : USER_DOMAINS_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const domains = domainsAssembly.mountDomainsList(response);
    yield put(domainsActions.domains.set(payload ? { ...domains, page: payload && payload.page } : domains));
    yield put(domainsActions.domains.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas requestDomains', error);
    yield put(domainsActions.domains.failure());
  }
}

function* requestDomainDetail(action) {
  const { id } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: DOMAIN_DETAIL_URL(id),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    const details = domainsAssembly.mountDetails(response);
    yield put(domainsActions.domains.setDetail(details));
    yield put(domainsActions.domains.detailSuccess());
  } catch (e) {
    yield put(domainsActions.domains.getDomainDetailFailure());
  }
}

function* requestDomainsElegible() {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `${USER_DOMAINS_ELEGIBLE}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    yield put(domainsActions.domainsElegible.set(response.data));
    yield put(domainsActions.domainsElegible.success());
  } catch (e) {
    yield put(domainsActions.domainsElegible.failure());
  }
}

function* sagas() {
  yield takeLatest(DomainsActionTypes.DOMAIN_ACTIVATE_RENEWAL_REQUEST, requestActivatingRenewal);
  yield takeLatest(DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_REQUEST, requestGenerationNewInvoice);
  yield takeLatest(DomainsActionTypes.GET_DOMAIN_PROPAGATION_STATUS_REQUEST, getPropagationStatus);
  yield takeLatest(DomainsActionTypes.GET_CONFIGURED_DOMAIN_STATUS_REQUEST, getConfiguredDomainStatus);
  yield takeLatest(DomainsActionTypes.SET_DOMAIN_HAS_CONFIGURED, setConfiguredDomain);
  yield takeLatest(DomainsActionTypes.GET_ALL_DOMAINS_LIST_REQUEST, setAllActiveDomainsList);
  yield takeLatest(DomainsActionTypes.GET_EPP_KEY_GENERATED_REQUEST, setGeneratedEppKey);
  yield takeLatest(DomainsActionTypes.GET_EPP_KEY_GENERATED_REQUEST, setGeneratedEppKey);
  yield takeLatest(DomainsActionTypes.GET_DOMAINS_REQUEST, requestDomains);
  yield takeLatest(DomainsActionTypes.GET_DOMAIN_DETAIL_REQUEST, requestDomainDetail);
  yield takeLatest(DomainsActionTypes.REQUEST_DOMAINS_ELEGIBLE, requestDomainsElegible);
}

export default sagas;
