import { CALL_API } from '@/middleware/api';
import {
  ACADEMY_PASS_COURSES,
  ACADEMY_PASS_TOKENS,
  ACADEMY_PASS_HML_PAYMENT_METHOD,
  ACADEMY_PASS_HML_CHECKOUT_PRODUCT,
  ACADEMY_PASS_BUY_FAST_CHECKOUT,
  ACADEMY_PASS_DETAILS,
  ACADEMY_PASS_FILTERED_COURSES,
} from '@/config/api';
import { config, COUNTRY } from '@/config';
import {
  REQUEST_ACADEMY_PASS_COURSES_LIST,
  RECEIVE_ACADEMY_PASS_COURSES_LIST,
  ABORT_ACADEMY_PASS_COURSES_LIST,
  ERROR_ACADEMY_PASS_COURSES_LIST,
  REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST,
  RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST,
  ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST,
  ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST,
  REQUEST_ACADEMY_PASS_TOKENS,
  RECEIVE_ACADEMY_PASS_TOKENS,
  ABORT_ACADEMY_PASS_TOKENS,
  ERROR_ACADEMY_PASS_TOKENS,
  REQUEST_ACADEMY_PASS_PAYMENT_METHOD,
  RECEIVE_ACADEMY_PASS_PAYMENT_METHOD,
  ABORT_ACADEMY_PASS_PAYMENT_METHOD,
  ERROR_ACADEMY_PASS_PAYMENT_METHOD,
  REQUEST_ACADEMY_PASS_PRICE,
  RECEIVE_ACADEMY_PASS_PRICE,
  ABORT_ACADEMY_PASS_PRICE,
  ERROR_ACADEMY_PASS_PRICE,
  REQUEST_ACADEMY_PASS_DETAILS,
  RECEIVE_ACADEMY_PASS_DETAILS,
  ABORT_ACADEMY_PASS_DETAILS,
  ERROR_ACADEMY_PASS_DETAILS,
  REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT,
} from './actionsTypes';
import { checkoutProductAdapter } from '@/utils/Formatters/checkoutProductAdapter';

export const getCourses = () => (dispatch, getState) => {
  const { loadedDate } = getState().academyPass.courses;
  if (loadedDate) {
    if ((Date.now() - loadedDate) > config.CACHE_TIME) {
      return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_COURSES_LIST }));
    }
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: ACADEMY_PASS_COURSES,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_COURSES_LIST }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_COURSES_LIST, data: response }),
        error: () => ({ type: ERROR_ACADEMY_PASS_COURSES_LIST }),
      },
    },
  });
};

export const getFilteredCourses = filter => (dispatch, getState) => {
  const { loadedDate } = getState().academyPass.courses;
  if (loadedDate) {
    if ((Date.now() - loadedDate) > config.CACHE_TIME) {
      return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST }));
    }
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: ACADEMY_PASS_FILTERED_COURSES(filter),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST, data: response }),
        error: () => ({ type: ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST }),
      },
    },
  });
};

export const getTokens = () => (dispatch, getState) => {
  const { loadedDate } = getState().academyPass.tokens;
  if ((Date.now() - loadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_TOKENS }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: ACADEMY_PASS_TOKENS,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_TOKENS }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_TOKENS, data: response }),
        error: () => ({ type: ERROR_ACADEMY_PASS_TOKENS }),
      },
    },
  });
};

export const getPrice = () => (dispatch, getState) => {
  const { priceLoadedDate } = getState().academyPass.cta;
  if ((Date.now() - priceLoadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_PRICE }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: ACADEMY_PASS_HML_CHECKOUT_PRODUCT,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
        Accept: 'application/json, text/plain, */*',
      },
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_PRICE }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_PRICE, data: checkoutProductAdapter(response) }),
        error: () => ({ type: ERROR_ACADEMY_PASS_PRICE }),
      },
    },
  });
};

export const getPaymentMethods = () => (dispatch, getState) => {
  const { paymentLoadedDate } = getState().academyPass.cta;
  if ((Date.now() - paymentLoadedDate) < config.CACHE_TIME) {
    return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_PAYMENT_METHOD }));
  }

  return dispatch({
    [CALL_API]: {
      authenticated: false,
      endpoint: ACADEMY_PASS_HML_PAYMENT_METHOD,
      method: 'GET',
      customHeaders: {
        brand: COUNTRY,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_PAYMENT_METHOD }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_PAYMENT_METHOD, data: response }),
        error: () => ({ type: ERROR_ACADEMY_PASS_PAYMENT_METHOD }),
      },
    },
  });
};


export const buyAcademyPass = ({
  planId, paymentMethod, paymentLocation,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().academyPass.buyFastcheckout;

  if (isLoading) {
    return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT }));
  }

  let cntxBypaymentLocation = '';

  if (paymentLocation === 'header') {
    cntxBypaymentLocation = 'hg_akdmy_landing_page_cta_top';
  }
  if (paymentLocation === 'footer') {
    cntxBypaymentLocation = 'hg_akdmy_landing_page_cta_bottom';
  }

  const bodyObject = {
    products: [
      {
        id: planId,
        type: 'product',
        domain: 'fims.com',
        quantity: 1,
        cycle: 'monthly',
      },
    ],
    payment_method: paymentMethod,
    offer_contexts: [
      cntxBypaymentLocation,
    ],
  };

  return dispatch({
    [CALL_API]: {
      endpoint: ACADEMY_PASS_BUY_FAST_CHECKOUT,
      method: 'POST',
      body: bodyObject,
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT, data: { ...response, paymentMethod, invoice_id: response.data.invoice_id } }),
        error: () => ({ type: ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT }),
      },
    },
  });
};

export const getDetails = () => (dispatch, getState) => {
  const { isLoading } = getState().academyPass.detail;
  if (isLoading) {
    return Promise.resolve(dispatch({ type: ABORT_ACADEMY_PASS_DETAILS }));
  }
  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: ACADEMY_PASS_DETAILS,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_ACADEMY_PASS_DETAILS }),
        success: response => ({ type: RECEIVE_ACADEMY_PASS_DETAILS, data: response }),
        error: () => ({ type: ERROR_ACADEMY_PASS_DETAILS }),
      },
    },
  });
};
