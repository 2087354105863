import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import styles from './styles';

const OutlineButton = ({
  classes,
  children,
  className,
  ...rest
}) => (
  <Button
    className={`${classes.btn} ${className}`}
    variant="outlined"
    {...rest}
  >
    {children}
  </Button>
);


export default withStyles(styles)(OutlineButton);
