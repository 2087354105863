const styles = theme => ({
  zoneTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '22px',
    fontWeight: 300,
    marginBottom: 12,

    '& > span': {
      fontSize: 18,
      color: theme.color.dustyGgray,
    },
  },
});

export default styles;
