import { find, propEq } from 'ramda';
import React, { useEffect, useState } from 'react';
import TitanUpgradePlanCycleContainer from '@/components/Email/TitanUpgradePlanCycle/TitanUpgradePlanCycleContainer';
import TitanUpgradePlanCycleTitle from '@/components/Email/TitanUpgradePlanCycle/TitanUpgradePlanCycleTitle';

const UpgradePlanCycleEnabled = ({ domain, domainObject, plans }) => {
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    if (plan === null) {
      const selectedPlan = find(propEq('id', domainObject.packageid), plans);
      setPlan(selectedPlan || {
        isFree: true,
        configoptions: [
          {
            monthly: '0.00',
            quarterly: '0.00',
            semiannually: '0.00',
            annually: '0.00',
          },
        ],
      });
    }
  }, [setPlan, domainObject, plans, plan]);

  return (
    <>
      <TitanUpgradePlanCycleTitle domainObject={domainObject} domain={domain} />
      {plan !== null
        && <TitanUpgradePlanCycleContainer plan={plan} plans={plans} domainObject={domainObject} setPlan={setPlan} />
      }
    </>
  );
};

export default UpgradePlanCycleEnabled;
