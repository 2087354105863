import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    justify-content: space-between;
    margin: 8px 0px;
    padding: 16px 28px;
    position: relative;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding-right: 10px;
    }
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    line-height: 21px;
    margin: 0 0 6px 0;
    span {
      color: ${theme.v1.color.action.hover};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-bottom: 14px;
    }
  `}
`;

export const Tools = styled.div`
  ${({ theme }) => css`
    height: 100%;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-self: end;
    }
  `}
`;

export const ToolsList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    margin: 0 0 0 16px;
    padding: 0;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      gap: 8px;
    }
  `}
`;

export const Tool = styled.li`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: ${theme.v1.layers.header};
  `}
`;

export const ToolTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 10px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 13px;
    margin: 5px 0 0 0;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin: 0 0 16px 0;
    span {
      color: ${theme.v1.color.action.hover}
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin: 0px 0px 14px 0px;
  min-width: 120px;
  width: fit-content;
`;

export const DecoTriangle = styled.div`
  ${({ theme }) => css`
    background:linear-gradient(to bottom right,#ffffff00 50%,${theme.v1.color.background.wizard} 50%);
    height: 100%;
    position:absolute;
    right: 0;
    top: 0;
    width: 177px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 143px;
    }
  `}
`;

export const DecoLine = styled.img`
  bottom: 0px;
  height: 5px;
  left: 0px;
  position: absolute;
  width: 100%;
`;
