import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    gap: 16px;
    justify-content: center;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      height: 100%;
      justify-content: center;
      margin-bottom: 52px;
    }
  `};
`;

export const ModalTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.extraDark};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    text-align: center;
  `};
`;

export const ModalText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: ${theme.v2.font.sizes.xxs};
    margin: 0;
    text-align: center;
  `};
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  width: fit-content;
`;
