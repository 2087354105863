import {
  Button, IconButton, withStyles, Menu, MenuItem,
} from '@material-ui/core';
import React, { useState } from 'react';

import { withI18n } from 'react-i18next';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import LinkButton from '@/components/Buttons/LinkButton';
import * as actions from '@/redux/actions/emails';
import ModalSelectionHostingPlans from '@/components/Modal/ModalSelectionHostingPlans';
import ModalCheckDomainAvailable from '@/components/Modal/ModalCheckDomainAvailable';

import styles from './styles';
import { ALPHA_TITANTRIAL_EMAILS_ENABLE_OLD_ROUTE } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ProEmailMenuMobilePlans = ({
  t, classes, proEmail, isProEmailInactive, isConfiguredAccounts, domain, almostExcluded, almostOverdue, isTrialPlan, shouldSetService, type,
  customerEmail,
}) => {
  const hasPlan = proEmail !== undefined && proEmail.plan !== undefined;
  const dispatch = useDispatch();

  const shouldRedirectToOldRoute = useFeatureIsOn(ALPHA_TITANTRIAL_EMAILS_ENABLE_OLD_ROUTE);
  const shouldRenderCreateFirstAccountButton = false;

  const idProEmail = proEmail !== undefined && proEmail.id !== undefined ? proEmail.id : undefined;

  const [modalCheckDomainAvailable, setModalCheckDomainAvailable] = useState(false);
  const [modalSelectionHostingPlans, setModalSelectionHostingPlans] = useState(false);
  const [hostings, setHostings] = useState(new Map());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayManagerButton = !almostExcluded && hasPlan && !isProEmailInactive && isConfiguredAccounts
  && ((isTrialPlan && !almostOverdue) || (!isTrialPlan && almostOverdue) || (proEmail.status === 'active' && !almostOverdue));

  const handleOpen = () => {
    dispatch(actions.requestCheckDomainAvailable(domain)).then((responseData) => {
      if (responseData.data.available) {
        setModalCheckDomainAvailable(true);
      } else {
        dispatch(actions.requestProEmailHostingPlans(domain)).then((response) => {
          if (!shouldRedirectToOldRoute) {
            history.push(`${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${domain}?idPro=${idProEmail}&service=${type}`);
          } else if (response.data.length > 1) {
            setHostings(response.data);
            setModalSelectionHostingPlans(true);
          } else if (response.data.length === 1) {
            history.push(`${t('routes.emailsList')}${t('routes.titanHireFreePlan')}/${response.data[0].domain}?id=${response.data[0].hostingId}`);
          } else {
            history.push(`${t('routes.emailsList')}${t('routes.titanHire')}/${domain}`);
          }
        });
      }
    });
  };

  const handleCreateEmailClick = async () => {
    const dataToFilter = type === 'proemail' ? `idPro=${idProEmail}` : `cust=${customerEmail}`;

    if (!shouldRedirectToOldRoute) {
      return history.push(`${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${domain}?${dataToFilter}&service=${type}`);
    }

    if (!isConfiguredAccounts && shouldSetService) {
      history.push(`${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${domain}?${dataToFilter}&service=${type}`);
    } else {
      history.push(`${t('routes.emailsList')}${t('routes.titanHire')}/${domain}&service=${type}`);
    }
  };

  return (
    <>
      <ModalSelectionHostingPlans
        onClose={() => setModalSelectionHostingPlans(false)}
        open={modalSelectionHostingPlans}
        classes={classes}
        domain={domain}
        hostings={hostings}
      />

      <ModalCheckDomainAvailable
        onClose={() => setModalCheckDomainAvailable(false)}
        open={modalCheckDomainAvailable}
        classes={classes}
        domain={domain}
      />

      <div>
        <IconButton
          className={classes.menuIcon}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.paperMenu,
          }}
        >
          {((!almostExcluded && !isConfiguredAccounts) || shouldSetService) && (
            <MenuItem className={classes.menuIcon} onClick={handleClose}>
              <Button className={classes.textMenu} onClick={handleCreateEmailClick}>
                {t('proEmail.createEmail')}
              </Button>
            </MenuItem>
          )}

          {(shouldRenderCreateFirstAccountButton || !shouldSetService)
          && proEmail.used_accounts === 0
          && proEmail.status !== 'terminated'
          && isConfiguredAccounts
          && (
            <MenuItem className={classes.menuIcon} onClick={handleClose}>
              <LinkButton
                className={classes.textMenu}
                to={proEmail.type !== 'cpanel'
                  ? `${t('routes.emailsList')}${t('routes.createFirstEmailsTitan')}/${proEmail.domain}`
                  : `${t('routes.emails')}${t('routes.createEmail')}/${proEmail.domain}`
                }
              >
                {t('proEmail.createFirstAccount')}
              </LinkButton>
            </MenuItem>
          )}

          {!shouldSetService && displayManagerButton && (
          <MenuItem className={classes.menuIcon} onClick={handleClose}>
            <LinkButton
              className={classes.textMenu}
              data-testid="manageLinkButton"
              to={proEmail.type !== 'cpanel'
                ? `${t('routes.emailsList')}${t('routes.emailsTitan')}/${proEmail.domain}`
                : `${t('routes.filteredEmails')}/${proEmail.domain}`
                }
            >
              {t('proEmail.managerEmail')}
            </LinkButton>

          </MenuItem>
          )}
          { !shouldSetService && (proEmail.status === 'terminated') && (
            <MenuItem className={classes.menuIcon} onClick={handleClose}>
              <Button onClick={handleOpen} className={classes.textMenu}>
                {t('proEmail.contractNewEmailPlanMobile')}
              </Button>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
};


export default withI18n()(withStyles(styles)(ProEmailMenuMobilePlans));
