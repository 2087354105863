const styles = theme => ({
  cardWarning: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 15px',
    border: '1px solid #000',
  },
  cardWarningIcon: {
    marginRight: '15px',
    color: theme.color.tundora,
  },
  cardWarningText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.color.tundora,
    margin: 0,
  },
  statusInfo: {
    borderColor: theme.color.indigo,
  },
  statusActive: {
    borderColor: theme.color.oceanGreen,
  },
  statusPending: {
    borderColor: theme.color.brightSun,
  },
  statusPendingTransfer: {
    borderColor: theme.color.brightSun,
  },
  statusExpired: {
    borderColor: theme.color.valencia,
  },
  statusCancelled: {
    borderColor: theme.color.geyser,
  },
  statusFraud: {
    borderColor: theme.color.brightSun,
  },
  statusSuspended: {
    borderColor: theme.color.valencia,
  },
  statusBuilding: {
    borderColor: theme.color.coral,
  },
});

export default styles;
