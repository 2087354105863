import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class FireBottomLeft extends React.PureComponent {
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={`${className} ${classes.icon}`} viewBox="0 0 376.1 512">
        <g id="Agrupar_1186" transform="matrix(-0.438, 0.899, -0.899, -0.438, 106.257, 19.015)">
          <path
            id="Caminho_2467"
            className={classes.st0}
            d="M240.8-282.9c0.6-17.1-0.7-35.4-14.2-40.3c-6.3-1.9-13.2-0.7-18.5,3.2
            c-1-4.8-1.4-9.8-1.3-14.7l0,0c0-6.1-5-11-11-11c-6.1,0-11,5-11,11c0,14.8,1.8,32.5,15.1,37.3c6.2,1.8,12.8,0.7,18.1-2.9
            c0.8,5.5,1.1,11.1,0.8,16.7c-0.1,3.5,1.5,6.9,4.2,9c4.8,3.7,11.7,2.9,15.4-1.9C239.9-278.3,240.7-280.6,240.8-282.9z"
          />
          <path
            id="Caminho_2468"
            className={classes.st1}
            d="M393.1-318.4c-1.7-5.8-7.7-9.2-13.6-7.6c-5.3,1.7-10.8,2.8-16.4,3.3
            c2.3-6,1.7-12.7-1.5-18.3c-7.9-11.8-25.4-9.3-39.8-5.7l0,0c-5.9,1.4-9.5,7.4-8.1,13.3c1.4,5.9,7.4,9.5,13.3,8.1
            c4.8-1.3,9.7-2.1,14.6-2.3c-2.5,6.1-2,13.1,1.3,18.8c8.1,11.9,26,8.7,42.5,4.1c2.2-0.6,4.2-2,5.7-3.8
            C393.3-311.4,394-315,393.1-318.4z M344.9-327.3L344.9-327.3z"
          />
          <path
            id="Caminho_2469"
            className={classes.st2}
            d="M294.7-306.1c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6
            C286-308.3,291.1-308.9,294.7-306.1C294.7-306.1,294.7-306.1,294.7-306.1z"
          />
          <path
            id="Caminho_2470"
            className={classes.st0}
            d="M302-206.3c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6s8-4.2,11.6-1.4
            C304.1-215.1,304.8-209.9,302-206.3z"
          />
          <path
            id="Caminho_2471"
            className={classes.st0}
            d="M205.1-382.7c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4
            C207.3-391.5,207.9-386.3,205.1-382.7z"
          />
          <path
            id="Caminho_2472"
            className={classes.st1}
            d="M18.8,0.8c0.5-17.1-0.7-35.3-14.2-40.3c-6.3-1.9-13.2-0.7-18.5,3.2c-1-4.8-1.4-9.8-1.3-14.7
            C-15.3-57-20.1-61.7-26-61.8C-32.1-61.9-37.1-57-37.2-51c0,14.8,1.8,32.5,15.1,37.3C-15.9-11.9-9.2-13-4-16.6
            c0.8,5.5,1.1,11.1,0.8,16.7c-0.1,3.5,1.5,6.9,4.2,9c4.8,3.7,11.7,2.9,15.4-1.9C17.9,5.4,18.7,3.1,18.8,0.8z"
          />
          <path
            id="Caminho_2473"
            className={classes.st2}
            d="M171-34.7c-1.7-5.8-7.7-9.2-13.6-7.6c-5.3,1.7-10.8,2.8-16.4,3.3c2.3-6,1.7-12.7-1.5-18.3
            c-7.9-11.8-25.4-9.3-39.8-5.7l0,0c-5.9,1.4-9.5,7.4-8.1,13.3s7.4,9.5,13.3,8.1c4.8-1.3,9.7-2.1,14.6-2.3c-2.5,6.1-2,13.1,1.3,18.8
            c8.1,11.9,26,8.7,42.5,4.1c2.2-0.6,4.2-2,5.7-3.8C171.3-27.7,172-31.3,171-34.7z M122.8-43.6L122.8-43.6z"
          />
          <path
            id="Caminho_2474"
            className={classes.st0}
            d="M72.7-22.4c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6
            S69.1-25.2,72.7-22.4C72.7-22.4,72.7-22.4,72.7-22.4z"
          />
          <path
            id="Caminho_2475"
            className={classes.st3}
            d="M102.7-149.2c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4
            C104.8-158,105.5-152.8,102.7-149.2z"
          />
          <path
            id="Caminho_2476"
            className={classes.st0}
            d="M79.9,77.4c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4
            C82.1,68.6,82.7,73.8,79.9,77.4z"
          />
          <path
            id="Caminho_2477"
            className={classes.st2}
            d="M-18.4-110.6c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6
            S-22-113.4-18.4-110.6C-18.4-110.6-18.4-110.6-18.4-110.6z"
          />
          <path
            id="Caminho_2478"
            className={classes.st4}
            d="M123.4-278.3c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4c-3.6-2.8-4.2-8-1.4-11.6
            C114.6-280.5,119.8-281.1,123.4-278.3C123.4-278.3,123.4-278.3,123.4-278.3z"
          />
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(FireBottomLeft);
