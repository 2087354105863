import React, { useState, useEffect, useCallback } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { Typography, withStyles } from '@material-ui/core';
import { loadDomains, loadDomainsEligible } from '@/redux/actions/domains';
import NoResultMessageTrans from '@/components/Notification/NoResultMessageTrans';
import Loader from '@/components/Layout/Loader';
import FaqDomains from '@/components/FrequentlyQuestions/FaqDomains';
import FilterDomains from '@/components/Filters/FilterDomains';
import FilterCard from '@/components/Cards/FilterCard';
import FilterButton from '@/components/Buttons/FilterButton';
import ActionButton from '@/components/Buttons/ActionButton';
import DomainItem from '@/components/Domain/DomainItem';
import { REGISTER_DOMAIN_URL } from '@/config/urls/domainsUrls';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import styles from './styles';
import { emailsActions } from '@/redux/modules/emails';
import PageMessages from '@/pages/common/PageMessages';
import { INITIAL_VISIBLE_DOMAINS } from '@/enums/domains/domains.enum';

const LOCATION_DOMAINS = 'dominios';

const DomainsPage = ({ classes, t }) => {
  const dispatch = useDispatch();

  const enableRenderProEmail = false;
  const { loading: loadingDomains, list: domains, pagination } = useSelector(state => state.domains);
  const { loading: loadingEmails } = useSelector(state => state.emails);
  const loading = loadingDomains || (enableRenderProEmail && loadingEmails);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [visible, setVisible] = useState(INITIAL_VISIBLE_DOMAINS);

  const total = useSelector(state => state.domains.total);

  const isEmptyDomains = domains.length === 0;

  const handleShowLoadMoreButton = useCallback(() => {
    if (pagination && visible < pagination.total) {
      setShowLoadMoreButton(true);
      return;
    }
    if (visible < domains.length && !pagination) {
      setShowLoadMoreButton(true);
      return;
    }
    setShowLoadMoreButton(false);
  }, [pagination, domains.length, visible]);

  useEffect(() => {
    handleShowLoadMoreButton();
  }, [handleShowLoadMoreButton]);

  useEffect(() => {
    dispatch(loadDomains({}));
    if (enableRenderProEmail) {
      dispatch(emailsActions.emails.request());
    } else {
      dispatch(loadDomainsEligible());
    }
  }, [dispatch, enableRenderProEmail]);

  const loadMore = () => {
    const paginationControl = {
      currentPage: pagination ? pagination.currentPage : 1,
      perPage: pagination ? pagination.perPage : domains.length,
    };
    if (visible === INITIAL_VISIBLE_DOMAINS) {
      setVisible(paginationControl.currentPage * paginationControl.perPage);
      return;
    }
    dispatch(loadDomains({ page: paginationControl.currentPage + 1 }));
    setVisible((paginationControl.currentPage + 1) * paginationControl.perPage);
  };

  const handleDomainsMessages = (
    <NoResultMessageTrans
      languageKey="noResults.tab.domains"
      urlTarget={REGISTER_DOMAIN_URL}
    />
  );

  const filterButton = (
    <FilterButton target={REGISTER_DOMAIN_URL}>
      {t('hireDomain')}
    </FilterButton>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageMessages displayDomainPropagationAlert />

      <div className={classes.contentWrapper}>
        <FilterCard title={t('lists.domains')} button={filterButton} useMediaWidth={1260}>
          <FilterDomains total={total} />
        </FilterCard>
      </div>

      {total === 0 && (
        handleDomainsMessages
      )}

      {total !== 0 && (
        <div className={classes.contentWrapper}>
          {isEmptyDomains ? (
            <Typography variant="caption" className={classes.message}>{t('noResults.filter')}</Typography>
          ) : (
            <>
              {domains.length > 0 && domains.slice(0, visible).map(domain => (
                <DomainItem domain={domain} key={shortid.generate()} />
              ))}

              {showLoadMoreButton && (
                <ActionButton action={loadMore} data-id="loadmore_button">
                  {t('btn.loadMore')}
                </ActionButton>
              )}
            </>
          )}
        </div>
      )}

      <div className={classes.bottomInfoWrapper}>
        <FaqDomains />
        <BottomAdvertisementHandler advertisementLocal={LOCATION_DOMAINS} />
      </div>
    </>
  );
};

export default withI18n()(withStyles(styles)(DomainsPage));
