const styles = theme => ({
  configButton: {
    color: theme.color.indigo,
    border: `1px solid ${theme.color.indigo}`,
    background: `${theme.color.white}`,
    width: '36px',
    height: '36px',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  formCard: {
    maxWidth: '623px',
  },
  toast: {
    height: '26px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    font: 'normal normal normal 12px/14px Roboto',
    maxHeight: '0px',
    overflow: 'hidden',
    transition: 'all 0.1s ease',
  },
  toastOpen: {
    maxHeight: '25px',
    transition: 'all 0.1s ease',
  },
  toastSuccess: {
    backgroundColor: theme.color.oceanGreen,
    color: theme.color.white,
  },
  toastPending: {
    backgroundColor: theme.color.brightSun,
    color: theme.color.tundora,
  },
  toastError: {
    backgroundColor: theme.color.valencia,
    color: theme.color.white,
  },
});

export default styles;
