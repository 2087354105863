import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 0px;
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.v2.shadows.dark};
    fit-content;
    margin: 24px 0px 0px;
    padding: 19px 29px 26px 29px;
  `}
`;

export const AlertTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 16px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;

    svg {
      flex-shrink: 0;
    }
  `}
`;

export const AlertDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: block;
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 16px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px;

    a {
      font-size:  ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const AgreementWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 8px;
  margin: 24px 0px 0px 0px;

  svg {
      flex-shrink: 0;
    }
`;

export const Agreement = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 24px 0px 0px 0px;
`;
