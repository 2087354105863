import React from 'react';
import { IconPassword } from 'gatorcomponents';
import * as Styles from './ChangePassword.styles';
import useLocale from '@/hooks/useLocale';

const ChangePassword = ({ togglePasswordModal }) => {
  const testId = 'change-password';

  const { myData: myDataLocale } = useLocale();
  const changePasswordLocale = myDataLocale.changePassword;

  return (
    <Styles.Container>
      <IconPassword size="md" />
      <Styles.Infos>
        <Styles.Title data-testid={`${testId}_title`}>
          {changePasswordLocale.title}
        </Styles.Title>
        <Styles.Content>
          <Styles.PasswordContainer>
            {changePasswordLocale.contentDescription()}
          </Styles.PasswordContainer>
          <Styles.ChangePasswordButton onClick={togglePasswordModal}>
            {changePasswordLocale.SectionButtonLabel}
          </Styles.ChangePasswordButton>
        </Styles.Content>
      </Styles.Infos>
    </Styles.Container>
  );
};

export default ChangePassword;
