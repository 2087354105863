import React from 'react';
import * as Style from './IconDetailedAlert.style';

const IconDetailedAlert = ({
  line = '#4a4a4a',
  color = '#FF9442',
  size = '40',
  testId = 'icon-alert',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 6.64795V39.9642H35.3571L0.5 6.64795Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7126 6.99789C20.2024 6.49885 17.6005 6.75541 15.2361 7.73515C12.8716 8.71489 10.8508 10.3738 9.42922 12.502C8.00762 14.6303 7.24908 17.1323 7.24951 19.6917C7.2501 23.1232 8.61367 26.414 11.0403 28.8403C13.467 31.2665 16.758 32.6295 20.1895 32.6295C22.7489 32.6295 25.2508 31.8705 27.3788 30.4486C29.5068 29.0266 31.1654 27.0055 32.1447 24.6409C33.1241 22.2763 33.3802 19.6744 32.8807 17.1642C32.3812 14.6541 31.1486 12.3484 29.3387 10.5388C27.5288 8.72917 25.2229 7.49693 22.7126 6.99789ZM13.8963 4.50174C16.9002 3.25701 20.2059 2.93104 23.3951 3.56507C26.5843 4.19909 29.5139 5.76463 31.8134 8.06369C34.1128 10.3628 35.6788 13.2921 36.3134 16.4812C36.948 19.6703 36.6226 22.976 35.3784 25.9802C34.1341 28.9844 32.027 31.5521 29.3234 33.3587C26.6198 35.1653 23.4412 36.1295 20.1895 36.1295C15.8298 36.1295 11.6487 34.3978 8.56567 31.3153C5.48265 28.2329 3.75025 24.052 3.74951 19.6923C3.74896 16.4407 4.71268 13.2619 6.51879 10.558C8.32491 7.85407 10.8923 5.74648 13.8963 4.50174Z" fill={line} />
      <path d="M21.9243 10.2562L21.5065 22.936H18.4675L18.037 10.2562H21.9243ZM17.9357 26.8616C17.9357 26.3265 18.1214 25.8833 18.4928 25.5321C18.8727 25.1726 19.3708 24.9928 19.987 24.9928C20.6117 24.9928 21.1097 25.1726 21.4811 25.5321C21.8526 25.8833 22.0383 26.3265 22.0383 26.8616C22.0383 27.38 21.8526 27.8189 21.4811 28.1785C21.1097 28.538 20.6117 28.7178 19.987 28.7178C19.3708 28.7178 18.8727 28.538 18.4928 28.1785C18.1214 27.8189 17.9357 27.38 17.9357 26.8616Z" fill={line} />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedAlert;
