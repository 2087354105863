const styles = theme => ({
  faq: {
    padding: '29px 0 17px 0',
  },
  loading: {
    flex: 'none',
  },
  contentLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  handleSelectPosition: {
    marginLeft: '-1px',
  },
  save: {
    minWidth: 200,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginBottom: 13,
    },
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 23,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
    },
  },
  textMb: {
    fontSize: 13,
    color: theme.color.tundora,
    marginLeft: 10,
    display: 'inline-block',
  },
  textOr: {
    fontSize: 12,
    color: theme.color.regentGray,
    margin: '0 20px',
    display: 'inline-block',
  },
  generatorWrapper: {
    margin: '15px 0',
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.color.indigo,
    fontSize: '20px',
  },
  tooltipHelper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  generatorText: {
    margin: '26px 0',
    color: theme.color.regentGray,
    fontSize: '12px',
  },
  generatorLink: {
    display: 'contents',
    color: theme.color.indigo,
    fontSize: '12px',
  },
  handleWrapper: {
    marginTop: '-8px',
    marginBottom: '5px',
    [theme.breakpoints.down(815)]: {
      marginTop: '-14px',
    },
  },
  '@keyframes showChecklist': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  checklistContainer: {
    transition: 'opacity',
    animationName: 'showChecklist',
    animationDuration: '0.3s',
  },
  checklist: {
    listStyle: 'none',
    paddingLeft: '5px',
    margin: '10px 0 0 0',
    '& li': {
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      padding: '3px 0',
    },
  },
  checklistTitle: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.regentGray,
    fontSize: '14px',
    margin: '20px 0 0 0',
  },
  checkIcon: {
    fontSize: '14px',
    marginRight: '6px',
    marginLeft: '-4px',
  },
  lensIcon: {
    fontSize: '6px',
    marginRight: '10px',
  },
  lockIcon: {
    fontSize: '14px',
    marginRight: '7px',
  },
  colorTrue: {
    color: theme.color.tundora,
  },
  colorFalse: {
    color: theme.color.valencia,
  },
  inputEmailWrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down(815)]: {
      flexDirection: 'column',
    },
  },
  inputPasswordWrapper: {
    width: '325px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    margin: '0 0 25px 0',
    padding: 0,
    fontSize: '24px',
    color: theme.color.tundora,
  },
  content: {
    padding: '20px 30px 30px 30px',
  },
  border: {
    borderLeft: `5px solid ${theme.color.indigo}`,
    borderRadius: '0',
    marginBottom: '40px',
    position: 'relative',
    '& .cardLabel': {
      height: '20px',
    },
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
  },
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  selectFieldTextOn: {
    color: theme.color.tundora,
    width: '325px',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  quotaInput: {
    width: '100%',
    fontSize: '14px',
    margin: '0',
    maxWidth: '140px',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 0,
    },
    '& input': {
      padding: '7px',
      paddingBottom: '8px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      '& fieldset': {
        width: '100%',
      },
    },
  },
  emailNameInput: {
    width: '325px',
    fontSize: '14px',
    margin: '0',
    '& fieldset': {
      borderTopLeftRadius: '2px',
      borderBottomLeftRadius: '2px',
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 0,
    },
    '& input': {
      padding: '7px',
      paddingBottom: '8px',
    },
    [theme.breakpoints.down(1166)]: {
      margin: 0,
      '& fieldset': {
        width: '100%',
      },
    },
    [theme.breakpoints.down(1096)]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  disabledInput: {
    backgroundColor: theme.color.concrete,
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
  },

  cssLabel: {
    '&$cssFocused': {
      color: theme.color.chambray,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.color.chambray}`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  selectCustom: {
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 8,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '2px',
      borderTopRightRadius: '2px',
    },
  },
  selectCustomInput: {},
  selectCustomOutline: {},
  selectCustomFocused: {},
});

export default styles;
