const styles = theme => ({
  icon: {
    fontSize: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
    },
  },
  st0: { fill: '#E9EDF1' },
  st1: { fill: '#C5E0EF' },
  st2: { fill: '#0070BB' },
  st3: { fill: '#FECBAA' },
  st4: { fill: '#FFAE85' },
  st5: { fill: '#727272' },
  st6: { fill: '#4C4C4C' },
  st7: { fill: '#2898CD' },
  st8: { fill: '#D1D1D1' },
});

export default styles;
