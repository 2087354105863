import React, { useContext } from 'react';
import * as Styles from './WizardWordpressConfigThemes.styles';
import { SlideToggle } from '@/pages/common/v1/SlideToggle';
import { Tooltip } from '@/pages/common/v1/';
import { IconInfo, IconDetailedTheme } from '@/icons';
import { WizardThemeCard } from '../WizardThemeCard';
import { Button } from '@/pages/common/v1/Button';
import { ThemeContext } from 'styled-components';

const WizardWordpressConfigThemes = ({
  themeTitle,
  themeDescription,
  themeTooltip,
  themes,
  themeSelected,
  handleChangeTheme,
  handleOnOpenThemeModal,
  nextStepButtonLabel,
  activeSetupStep,
  onContinueSetup,
  open,
  setupSteps,
  wordPressThemesBlock,
  wordPressSetupReadyToContinue,
  wodpressSetupValues,
  wordPressInfoBlock,
  validateWordpressInfo,
}) => {
  const theme = useContext(ThemeContext);

  const handleOnClick = () => {
    onContinueSetup && onContinueSetup(2);
  };

  const scrollToInfo = () => {
    window.scrollTo({
      top: wordPressInfoBlock.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleOnToggle = () => {
    validateWordpressInfo();

    const hasError = wodpressSetupValues.name.error || wodpressSetupValues.email.error || wodpressSetupValues.user.error;

    if (!wordPressSetupReadyToContinue) {
      if (hasError) {
        scrollToInfo();
      }

      if (!hasError) {
        onContinueSetup(1);
      }
    }

    if (wordPressSetupReadyToContinue) {
      scrollToInfo();
    }

    if (activeSetupStep > setupSteps.THEME) {
      onContinueSetup(1);
    }
  };

  return (
    <Styles.Wrapper ref={wordPressThemesBlock}>
      <SlideToggle
        open={open}
        controlledByFather={activeSetupStep !== setupSteps.PLUGIN}
        toggleOnIcon={activeSetupStep >= setupSteps.PLUGIN}
        onToggle={handleOnToggle}
        testId="slide-toggle-theme"
        maxHeight={1825}
        icon={<IconDetailedTheme />}
        title={themeTitle}
        description={(
          <Styles.DescriptionWrapper>
            {themeDescription}
            <Tooltip width="376px" content={themeTooltip}>
              <IconInfo size={18} color={theme.v1.color.action.primary} />
            </Tooltip>
          </Styles.DescriptionWrapper>
          )}
      >
        <Styles.ThemesContainer>
          <Styles.ThemesWrapper>
            {themes && themes.map(theme => (
              <WizardThemeCard
                selected={theme.value === themeSelected}
                handleChangeTheme={handleChangeTheme}
                title={theme.title}
                image={theme.image}
                cardValue={theme.value}
                key={theme.id}
                handleOnOpenThemeModal={handleOnOpenThemeModal}
              />
            ))}
          </Styles.ThemesWrapper>
        </Styles.ThemesContainer>

        <Styles.ButtonWrapper>
          <Button onClick={handleOnClick} testId="theme-next-step-button" disabled={themeSelected === null} label={nextStepButtonLabel} />
        </Styles.ButtonWrapper>
      </SlideToggle>
    </Styles.Wrapper>
  );
};

export default WizardWordpressConfigThemes;
