import React from 'react';

const IconCardMaster = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.26 22.77"
  >
    <g>
      <g>
        <rect width="36.26" height="22.77" rx="2" />
        <path d="M10.69,20.39v-1.2a.69.69,0,0,0-.63-.76H10a.78.78,0,0,0-.68.33.69.69,0,0,0-.64-.33.66.66,0,0,0-.58.28v-.25H7.67v1.93h.38v-1.1a.45.45,0,0,1,.39-.5h.09c.28,0,.44.18.44.5v1.1h.38v-1.1a.45.45,0,0,1,.39-.5h.09c.28,0,.44.18.44.5v1.1Zm6.25-1.9h-.7v-.58h-.38v.58h-.4v.34h.4v.9c0,.44.16.7.64.7a1.34,1.34,0,0,0,.52-.14L16.9,20a.75.75,0,0,1-.36.1.27.27,0,0,1-.3-.26s0,0,0-.06v-.92h.7v-.33Zm3.56-.06a.58.58,0,0,0-.52.28v-.25H19.6v1.93H20V19.31c0-.32.16-.52.42-.52a.75.75,0,0,1,.26,0l.12-.37-.28,0Zm-5.38.2a1.3,1.3,0,0,0-.79-.2c-.48,0-.78.22-.78.6s.22.5.64.56l.2,0c.23,0,.37.12.37.22s-.16.24-.47.24a1,1,0,0,1-.64-.2l-.2.3a1.44,1.44,0,0,0,.83.24c.56,0,.86-.26.86-.62s-.25-.5-.66-.56l-.2,0c-.18,0-.34-.08-.34-.2s.16-.24.38-.24a1.41,1.41,0,0,1,.6.16Zm5.8.8a1,1,0,0,0,.89,1h.11a1,1,0,0,0,.68-.22l-.2-.3a.87.87,0,0,1-.5.18.64.64,0,0,1-.59-.69.63.63,0,0,1,.59-.59.75.75,0,0,1,.5.18l.2-.3a.93.93,0,0,0-.68-.22.89.89,0,0,0-1,.8.86.86,0,0,0,0,.16Zm-2.68-1a.91.91,0,0,0-.94.89.41.41,0,0,0,0,.11,1,1,0,0,0,.88,1h.1a1.28,1.28,0,0,0,.78-.26l-.2-.28a1,1,0,0,1-.56.2.57.57,0,0,1-.58-.5h1.42v-.16a.9.9,0,0,0-.79-1Zm0,.36a.48.48,0,0,1,.5.45v0h-1a.53.53,0,0,1,.54-.48ZM13,19.43v-1h-.38v.24a.72.72,0,0,0-.62-.28,1,1,0,0,0,0,2,.74.74,0,0,0,.62-.28v.24H13Zm-1.54,0a.6.6,0,0,1,.64-.55.6.6,0,0,1,0,1.19.58.58,0,0,1-.6-.55v-.09Zm14.8-1a.58.58,0,0,0-.52.28v-.24h-.38v1.92h.38V19.31c0-.32.16-.52.42-.52a.75.75,0,0,1,.26,0l.12-.37-.28,0Zm-1.48,1v-1h-.38v.24a.72.72,0,0,0-.62-.28,1,1,0,1,0,0,2,.74.74,0,0,0,.62-.28v.24h.38Zm-1.54,0a.59.59,0,1,1,1.18.09.6.6,0,0,1-.58.55.6.6,0,0,1-.61-.56.22.22,0,0,1,0-.08Zm5.4,0V17.71h-.38v1a.72.72,0,0,0-.62-.28,1,1,0,0,0,0,2,.74.74,0,0,0,.62-.28v.24h.38Zm-1.54,0a.6.6,0,0,1,.64-.55.6.6,0,0,1,0,1.19.58.58,0,0,1-.6-.56Z" fill="#fff" />
        <path d="M14.68,3.89h7V15.22H14.68Z" fill="#ff5f00" />
        <path d="M15.38,9.55a7.23,7.23,0,0,1,2.74-5.66,7.21,7.21,0,1,0,0,11.33A7.23,7.23,0,0,1,15.38,9.55Z" fill="#eb001b" />
        <path d="M29.8,9.55a7.21,7.21,0,0,1-11.66,5.67A7.23,7.23,0,0,0,19.33,5.07a7,7,0,0,0-1.19-1.18A7.21,7.21,0,0,1,29.8,9.55Z" fill="#f79e1b" />
      </g>
    </g>
  </svg>
);

export default IconCardMaster;
