const styles = theme => ({
  wrapper: {
    padding: '0 40px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: theme.color.havelockBlue,
    color: theme.color.white,
    fontSize: '12px',
    marginTop: 7,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  icon: {
    marginRight: 12,
  },
});

export default styles;
