import React, { useEffect, useState, useCallback } from 'react';
import { withI18n } from 'react-i18next';
import { useParams } from 'react-router';
import { CircularProgress, withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getSiteBuilderSso, activateSiteBuilderStandAlone, activateSiteBuilderAddon } from '@/redux/actions/siteBuilder';
import {
  SUCCESS_SITEBUILDER_SSO, RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE, RECEIVE_ACTIVATE_SITEBUILDER_ADDON, RECEIVE_PRODUCT_DETAIL,
} from '@/redux/actions/actionsTypes';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { loadProductDetail } from '@/redux/actions/productDetail';
import styles from './styles';


const RedirectToSiteBuilderPage = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { domain, hostingId } = useParams();
  const { email, loading: summaryLoading } = useSelector(state => state.summary);
  const [loading, setLoading] = useState(true);

  const setLocalStoragekey = (key, hostingId) => localStorage.setItem(key, hostingId);

  const handleErrorSSO = useCallback(() => {
    setLoading(false);
    dispatch(enqueueSnackbar('ErrorSSO', notifierErrorLong));
  }, [dispatch]);

  const getSSO = useCallback(() => {
    dispatch(getSiteBuilderSso(domain)).then((response) => {
      if (response.type === SUCCESS_SITEBUILDER_SSO) {
        window.location.replace(response.data.sso);
      } else {
        handleErrorSSO();
      }
    });
  }, [dispatch, handleErrorSSO, domain]);

  const activateStandalone = useCallback(
    (sitebuilderType) => {
      const dataToSend = {
        hosting_id: parseInt(hostingId, 10),
        domain,
        product: sitebuilderType,
      };

      dispatch(activateSiteBuilderStandAlone(dataToSend)).then((response) => {
        if (response.type === RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE) {
          setLocalStoragekey('id-sitebuilder-standalone-activated', hostingId);
          window.location.replace(response.data.data.sso);
        } else {
          handleErrorSSO();
        }
      });
    },
    [dispatch, domain, handleErrorSSO, hostingId],
  );

  const activateAddon = useCallback((domain, hostingId) => {
    dispatch(activateSiteBuilderAddon({ domain, hostingId })).then((response) => {
      if (response.type === RECEIVE_ACTIVATE_SITEBUILDER_ADDON) {
        setLocalStoragekey('id-sitebuilder-addon-activated', response.data.siteBuilderAddonId);
        window.location.replace(response.data.sso);
      } else {
        handleErrorSSO();
      }
    });
  }, [dispatch, handleErrorSSO]);


  const handleLoadProductDetails = useCallback(
    (domain) => {
      if (hostingId) {
        dispatch(loadProductDetail(hostingId)).then((response) => {
          if (response.type === RECEIVE_PRODUCT_DETAIL) {
            if (response.productDetail.type === 'websitebuilder' && response.productDetail.productType === 'standalone') {
              activateStandalone(response.productDetail.sitebuilderType);
            } else {
              activateAddon(domain, hostingId);
            }
          }
        });
      } else {
        getSSO();
      }
    }, [dispatch, activateAddon, activateStandalone, getSSO, hostingId],
  );

  useEffect(() => {
    if (email && domain && !summaryLoading) {
      handleLoadProductDetails(domain);
    }
  }, [email, summaryLoading, domain, handleLoadProductDetails]);


  return (
    <div className={classes.container}>
      {loading && (
        <>
          <p>{t('redirectPageMessage.siteBuilder')}</p>
          <CircularProgress size={50} />
        </>
      )}
    </div>
  );
};

export default withI18n()(withStyles(styles)(RedirectToSiteBuilderPage));
