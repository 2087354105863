import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import RecordedItemBlock from '@/components/Dns/ZoneManager/RecordList/MobileRecordList/RecordedItemBlock';
import OutlineButton from '@/components/Buttons/OutlineButton';
import EmptyList from '@/components/Dns/ZoneManager/RecordList/EmptyList';
import styles from './styles';

const MobileRecordList = ({ t, classes }) => {
  const {
    mobileListAmount,
    renderedRecordedItems,
    handleLoadMoreMobile,
  } = useContext(DNSContext);

  const hasRenderedItems = renderedRecordedItems && renderedRecordedItems.length > 0;
  return (
    hasRenderedItems
      ? (
        <div>
          {renderedRecordedItems.map((recordedItem, idx) => (
            idx < mobileListAmount
                  && <RecordedItemBlock key={`${recordedItem.name}`} recordedItem={recordedItem} idx={idx} />
          ))}

          {mobileListAmount < renderedRecordedItems.length
            && (
              <div className={classes.buttonWrapper}>
                <OutlineButton className={classes.outlinedButton} onClick={() => handleLoadMoreMobile()}>
                  {t('dns.zoneManager.recordList.loadMore')}
                </OutlineButton>
              </div>
            )
          }
        </div>
      )
      : <EmptyList />
  );
};

export default withI18n()(withStyles(styles)(MobileRecordList));
