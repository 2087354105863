import React, { } from 'react';

import * as Styles from './CheckedDefaultCreditCardPayment.styles';
import { Checkbox } from 'gatorcomponents';

const CheckedDefaultCreditCardPayment = ({
  description = 'Descrição da seleção',
  onClickAction = null,
  isChecked = true,
  testId = 'check-default-credit-card-payment',
}) => {
  const onClickActionHandler = (isChecked) => {
    onClickAction && onClickAction(isChecked);
  };

  return (
    <Styles.Wrapper>
      <Styles.Content data-testid={`${testId}-content`}>
        <Styles.CheckboxLabel onClick={() => onClickActionHandler(!isChecked)}>
          <Checkbox checked={isChecked} id={`${testId}-check-box`} />
          <Styles.Text data-testid={`${testId}-text`}>
            {description}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default CheckedDefaultCreditCardPayment;
