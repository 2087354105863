import React from 'react';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import apseal from '@/media/courses/AcademyPass/apseal.png';
import apseal2x from '@/media/courses/AcademyPass/apseal@2x.png';
import styles from './styles';

const CtaAdvantages = ({ classes, t }) => (
  <div className={classes.wrapper} data-testid="cta-advantagens">
    <img
      src={apseal}
      srcSet={`${apseal} 360w, ${apseal2x} 600w`}
      sizes="(min-width: 360px), (min-width: 600px)"
      alt={t('academypass.cta.alt')}
      className={classes.topImage}
    />
    <Typography className={classes.headerTitle}>{t('academypass.cta.advantages.title')}</Typography>
    <Typography className={classes.headerSubtitle}>{t('academypass.cta.advantages.subtitle')}</Typography>
    <div className={classes.listContainer}>
      <img
        src={apseal}
        srcSet={`${apseal} 360w, ${apseal2x} 600w`}
        sizes="(min-width: 360px), (min-width: 600px)"
        alt={t('academypass.cta.alt')}
        className={classes.sealImg}
      />
      <ul className={classes.advantagesList}>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem1')}
        </li>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem2')}
        </li>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem3')}
        </li>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem4')}
        </li>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem5')}
        </li>
        <li>
          <span>{t('academypass.cta.advantages.bullet')}</span>
          {t('academypass.cta.advantages.listItem6')}
        </li>
      </ul>
    </div>
  </div>
);
export default withI18n()(withStyles(styles, { withTheme: true })(CtaAdvantages));
