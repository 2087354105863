import React from 'react';
import * as Styles from './Create.styles';
import {
  Button, IconWarning, IconDanger, Alert,
} from 'gatorcomponents';
import { BackupGenerationStatus } from '@/redux/modules/backup/backup.types';

const Create = ({
  title,
  description,
  alertTitle,
  alertDescription,
  backupLabel,
  proceedAlertDescription,
  loading,
  createBackup,
  backupStatus,
  backupSuccessTitle,
  backupSuccessDescription,
  backupErrorTitle,
  backupErrorDescription,
}) => (
  <Styles.Wrapper>
    <Styles.Title>{title}</Styles.Title>
    <Styles.Description>{description}</Styles.Description>
    <Styles.AlertWrapper>
      <Styles.AlertTitle>
        <IconWarning color="attentionMedium" />
        {alertTitle}
      </Styles.AlertTitle>

      <Styles.AlertDescription>
        {alertDescription}
      </Styles.AlertDescription>
    </Styles.AlertWrapper>

    {proceedAlertDescription && (
      <Styles.ProceedAlertDescriptionWrapper>
        <IconDanger color="primaryBlue" />
        <Styles.ProceedAlertDescription>
          {proceedAlertDescription}
        </Styles.ProceedAlertDescription>
      </Styles.ProceedAlertDescriptionWrapper>
    )}

    <Styles.ButtonWrapper>
      <Button
        size="large"
        label={backupLabel}
        loading={loading}
        onClick={createBackup}
      />
    </Styles.ButtonWrapper>

    {backupStatus === BackupGenerationStatus.SUCCESS && (
      <Alert description={backupSuccessDescription} title={backupSuccessTitle} />
    )}
    {backupStatus === BackupGenerationStatus.FAIL && (
      <Alert description={backupErrorDescription} title={backupErrorTitle} variant="error" />
    )}
  </Styles.Wrapper>
);

export default Create;
