import React, { useState, useEffect, useCallback } from 'react';
import * as Styles from './OpenFloatingSurvey.styles';
import { IconComment } from '@/icons/iconComment';
import * as theme from '@/styles/HgThemeProvider';
import { IconClose } from '@/icons/IconClose';
import { Button } from '@/pages/common/';
import { Button as NewButton } from '@/pages/common/v1/Button';
import SnappyFace from '@/media/pageDescription/snappy-face.svg';
import { SelectQuestion } from './SelectQuestion';

const OpenFloatingSurvey = ({
  toggleOpen,
  survey,
  questionsAnswered,
  setQuestionsAnswered,
  onFinishSurvey,
  dispatch,
  handleStartSurvey,
  handleClose,
  handleJump,
}) => {
  const {
    title, welcome, explanation, startLabel, congrats, finalText, finishLabel, counterLabel,
  } = survey;

  const alreadyAnsweredOne = questionsAnswered.ids.length > 0;
  const [answeredAll, setAnsweredAll] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState({ id: 0, label: '' });
  const [questionsJumped, setQuestionsJumped] = useState([]);
  const [surveyStarted, setSurveyStarted] = useState(alreadyAnsweredOne);
  const [displayOtherOptionField, setDisplayOtherOptionField] = useState(false);
  const [otherOptionFieldValue, setOtherOptionFieldValue] = useState('');

  const verifyAllQuestionsAnswered = useCallback(() => {
    if (questionsAnswered.ids.length === survey.questions.length) {
      setAnsweredAll(true);
    }
  }, [questionsAnswered, survey.questions]);

  const resetOtherOptionField = () => {
    setDisplayOtherOptionField(false);
    setOtherOptionFieldValue('');
  };

  const handleAnswerChange = (answer) => {
    setCurrentQuestionAnswer(answer);
    answer.value === 'other' ? setDisplayOtherOptionField(true) : resetOtherOptionField();
  };

  const handleJumpQuestion = (question) => {
    resetOtherOptionField();
    setQuestionsJumped([...questionsJumped, question]);
    handleJump(currentQuestion.id);
  };

  const handleSendAnswer = () => {
    const answers = {
      ...questionsAnswered.answers,
      [`${currentQuestion.id}`]: displayOtherOptionField
        ? otherOptionFieldValue
        : currentQuestionAnswer.value,
    };

    dispatch(answers, currentQuestion.id);

    setQuestionsAnswered({
      ...questionsAnswered,
      ids: [...questionsAnswered.ids, currentQuestion.id],
      answers,
    });

    resetOtherOptionField();
  };

  useEffect(() => {
    const question = survey.questions.filter(question => questionsAnswered.ids.indexOf(question.id) === -1 && questionsJumped.indexOf(question.id) === -1)[0];
    setCurrentQuestion(question);
    setCurrentQuestionAnswer({ id: 0, label: '', value: '' });
  }, [questionsAnswered, survey.questions, questionsJumped]);

  useEffect(() => {
    verifyAllQuestionsAnswered();
    if ((questionsAnswered.ids.length + questionsJumped.length === survey.questions.length) && (questionsAnswered.ids.length !== survey.questions.length)) {
      toggleOpen();
    }
  }, [questionsAnswered, questionsJumped, survey.questions, toggleOpen, verifyAllQuestionsAnswered]);

  const startSurvey = () => {
    setSurveyStarted(true);
    handleStartSurvey();
  };

  const getCurrentStep = () => {
    let currentStep = '';
    if (!surveyStarted) {
      currentStep = 'start';
    } else if (answeredAll) {
      currentStep = 'end';
    } else {
      currentStep = currentQuestion.id;
    }
    return currentStep;
  };

  return (
    <Styles.OpenFloatingSurveyContainer>
      <Styles.Header>
        <Styles.TitleContainer>
          <IconComment />
          <Styles.Title>
            {title}
          </Styles.Title>
        </Styles.TitleContainer>
        <Styles.CloseContainer>
          <Button
            icon={<IconClose color={theme.palette.grey.main} />}
            label="Close button"
            variant="text"
            onClick={() => {
              toggleOpen();
              const currentStep = getCurrentStep();
              handleClose(currentStep);
            }}
          />
        </Styles.CloseContainer>
      </Styles.Header>

      {!surveyStarted && (
      <Styles.WelcomeContainer>
        <Styles.Welcome>
          {welcome}
        </Styles.Welcome>

        <Styles.Explanation>
          {explanation}
        </Styles.Explanation>

        <Styles.ButtonContainer>
          <NewButton label={startLabel} size="regular" onClick={startSurvey} />
        </Styles.ButtonContainer>
      </Styles.WelcomeContainer>
      )}

      {(!answeredAll && surveyStarted) && (
        <Styles.QuestionsContainer>
          <Styles.QuestionCounter>
            {`${questionsAnswered.ids.length + questionsJumped.length + 1}/${survey.questions.length} ${counterLabel}`}
          </Styles.QuestionCounter>
          {currentQuestion && (
            <SelectQuestion
              currentQuestionAnswer={currentQuestionAnswer}
              currentQuestion={currentQuestion}
              displayOtherOptionField={displayOtherOptionField}
              handleAnswerChange={handleAnswerChange}
              handleJumpQuestion={handleJumpQuestion}
              handleSendAnswer={handleSendAnswer}
              otherOptionFieldValue={otherOptionFieldValue}
              setOtherOptionFieldValue={setOtherOptionFieldValue}
            />
          )}
        </Styles.QuestionsContainer>
      )}

      {answeredAll && (
        <Styles.FinishContainer>
          <Styles.Congrats>
            {congrats}
          </Styles.Congrats>
          <Styles.FinalText>
            {finalText}
          </Styles.FinalText>
          <Styles.ButtonContainer>
            <NewButton label={finishLabel} onClick={onFinishSurvey} />
          </Styles.ButtonContainer>
        </Styles.FinishContainer>
      )}
      <Styles.SnappyImage src={SnappyFace} />
    </Styles.OpenFloatingSurveyContainer>
  );
};

export default OpenFloatingSurvey;
