import React from 'react';
import * as Styles from './DiscoverBenefits.styles';
import codeGuardBanner from '@/media/codeguard/codeguard-banner.png';
import { Button } from '@/pages/common/v1/Button';
import { videoEmbedUrl } from '@/config/urls/codeguard';

const DiscoverBenefits = ({
  title,
  benefits = [],
  videoTitle,
  videoDescription,
  buttonLabel,
  codeGuardAnalytics,
}) => {
  const moveScreenToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    codeGuardAnalytics.thirdRowButtonClick();
  };
  return (
    <Styles.Container data-testid="discover-benefits">
      <Styles.Title>
        {title}
      </Styles.Title>

      <Styles.BenefitsWrapper>
        {benefits.map(benefit => (
          <Styles.BenefitWrapper key={benefit.id}>
            {benefit.icon}
            <Styles.BenefitTitle>
              {benefit.title}
            </Styles.BenefitTitle>
            <Styles.BenefitDescription>
              {benefit.description}
            </Styles.BenefitDescription>
          </Styles.BenefitWrapper>
        ))}
      </Styles.BenefitsWrapper>

      <Styles.CodeguardBanner src={codeGuardBanner} />

      <Styles.VideoTitle>
        {videoTitle}
      </Styles.VideoTitle>

      <Styles.VideoContainer>
        <Styles.VideoLayer>
          <Styles.Video src={videoEmbedUrl} title="codeguard-video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </Styles.VideoLayer>
      </Styles.VideoContainer>

      <Styles.VideoDescription>
        {videoDescription}
      </Styles.VideoDescription>

      <Styles.RowButtonWrapper>
        <Button
          testId="second-row-button"
          label={buttonLabel}
          onClick={() => moveScreenToTop()}
        />
      </Styles.RowButtonWrapper>
    </Styles.Container>
  );
};

export default DiscoverBenefits;
