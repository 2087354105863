import styled, { css } from 'styled-components';


export const DropdownButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: auto;

    * {
      text-align: left;
    }

    ul {
      min-width: 259px;
    }

    @media(min-width:${theme.v2.breakpoints.sm}){
      margin-left: 24px;
    }
  `}
`;

export const MainActionsWrapper = styled.div`
  ${({ theme, noMargin, noDisplay }) => css`
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-left: auto;

      @media (max-width: ${theme.v2.breakpoints.sm}) {
        display: ${noDisplay ? 'none' : 'flex'};
        margin-top: ${noMargin ? 0 : '16px'};
        min-width: 100%;
        order: 99;
      }
  `}
`;

export const CardItem = styled.div`
  ${({ theme }) => css`
      align-items: center;
      border-top: 1px solid ${theme.v2.colors.neutral.highMedium};
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 24px 0px;

      @media(max-width:${theme.v2.breakpoints.sm}){
        flex-wrap: wrap;
      }
  `}
`;

export const TagsWrapper = styled.div`
  ${({ theme, noDisplay }) => css`
    display: ${noDisplay ? 'none' : 'flex'};
    gap: 8px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      order: 1;
      width: calc(100% - 32px);
    }
  `};
`;

export const ItemAlert = styled.div`
  ${({ theme, noDisplay }) => css`
    display: ${noDisplay && 'none'};
    min-width: 100%;

    p {
      color: ${theme.v2.colors.neutral.lowMedium};
    }

    a {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `};
`;

export const ButtonWrapper = styled.div`
  > button {
    margin: auto;
    display: block;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  & > div:last-child {
    padding-bottom: 0px;
  }
`;

export const AlertWrapper = styled.div`
  margin: -1px 24px 15px 24px;
`;
