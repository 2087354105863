const receiveFaq = ({ response, key }) => ({
  key,
  questions: [...response.data.data],
});

const faqAssembly = {
  receiveFaq,
};

export default faqAssembly;
