import { InvoicesActionTypes } from './invoices.types';

const getInvoicesRequest = () => ({ type: InvoicesActionTypes.GET_INVOICES_REQUEST });
const getInvoicesSuccess = () => ({ type: InvoicesActionTypes.GET_INVOICES_SUCCESS });
const getInvoicesFailure = () => ({ type: InvoicesActionTypes.GET_INVOICES_FAILURE });

const splitPaymentSuccess = () => ({ type: InvoicesActionTypes.SPLIT_PAYMENT_SUCCESS });

const splitPaymentFailure = () => ({ type: InvoicesActionTypes.SPLIT_PAYMENT_FAILURE });

const splitPaymentRequest = (invoiceId, listProductsId, callBackFuction) => ({
  type: InvoicesActionTypes.SPLIT_PAYMENT_REQUEST,
  payload: { invoiceId, listProductsId, callBackFuction },
});

const loadInvoiceDetailsRequest = (invoiceId, callBackFuction) => ({
  type: InvoicesActionTypes.INVOICE_DETAILS_REQUEST,
  payload: { invoiceId, callBackFuction },
});

const loadInvoiceDetailsSuccess = () => ({
  type: InvoicesActionTypes.INVOICE_DETAILS_SUCCESS,
});

const loadInvoiceDetailsFailure = () => ({
  type: InvoicesActionTypes.INVOICE_DETAILS_FAILURE,
});

const generatePixRequest = (invoiceId, callBackFuction) => ({
  type: InvoicesActionTypes.GENERATE_PIX_REQUEST,
  payload: { invoiceId, callBackFuction },
});

const generatePixSuccess = () => ({
  type: InvoicesActionTypes.GENERATE_PIX_SUCCESS,
});

const generatePixFailure = () => ({
  type: InvoicesActionTypes.GENERATE_PIX_FAILURE,
});

const cancelOrderRequest = ({ orderId, reloadData }) => ({
  type: InvoicesActionTypes.CANCEL_ORDER_REQUEST,
  payload: { orderId, reloadData },
});

const cancelOrderSuccess = status => ({
  type: InvoicesActionTypes.CANCEL_ORDER_SUCCESS,
  payload: status,
});

const cancelOrderFailure = status => ({
  type: InvoicesActionTypes.CANCEL_ORDER_FAILURE,
  payload: status,
});

const cancelOrderLoading = loading => ({
  type: InvoicesActionTypes.CANCEL_ORDER_LOADING,
  payload: loading,
});

const getNFSEByInvoiceRequest = invoiceId => ({
  type: InvoicesActionTypes.GET_NFSE_REQUEST,
  payload: { invoiceId },
});

const getNFSEByInvoiceSuccess = () => ({
  type: InvoicesActionTypes.GET_NFSE_SUCCESS,
});

const getNFSEByInvoiceFailure = () => ({
  type: InvoicesActionTypes.GET_NFSE_FAILURE,
});

const setNFSEByInvoice = (listOfNfse = [], currentInvoiceId = null) => ({
  type: InvoicesActionTypes.SET_NFSE,
  payload: {
    listOfNfse,
    currentInvoiceId,
  },
});

const actions = {
  invoices: {
    failure: getInvoicesFailure,
    request: getInvoicesRequest,
    success: getInvoicesSuccess,

    loadDetails: {
      request: loadInvoiceDetailsRequest,
      success: loadInvoiceDetailsSuccess,
      failure: loadInvoiceDetailsFailure,
    },
    generatePix: {
      request: generatePixRequest,
      success: generatePixSuccess,
      failure: generatePixFailure,
    },
    splitPayment: {
      request: splitPaymentRequest,
      success: splitPaymentSuccess,
      failure: splitPaymentFailure,
    },
    getNfse: {
      request: getNFSEByInvoiceRequest,
      success: getNFSEByInvoiceSuccess,
      failure: getNFSEByInvoiceFailure,
      set: setNFSEByInvoice,
    },
  },
  cancelOrder: {
    request: cancelOrderRequest,
    success: cancelOrderSuccess,
    failure: cancelOrderFailure,
    loading: cancelOrderLoading,
  },
};

export default actions;
