/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
  BACKUP_INFO, WOOCOMMERCE_ARTICLE, DNS_PROPAGATION_ARTICLE, FIND_PLAN_DNS_ARTICLE, GET_A_NEW_DOMAIN,
} from '@/config/urls/supportUrls';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { PLATFORM_NAMES } from '@/enums/domains/dns.enum';
import {
  Link, IconCheck, IconClock, IconDanger, Button,
} from 'gatorcomponents';

export default {
  wizard: {
    isActivated: 'Você já possui um Criador de Sites ativo para este domínio. Selecione a opção abaixo para acessar o gerenciamento do seu Criador de Sites',
    access: 'Acessar criador de sites',
    domains: 'Domínios',
    activate: {
      title: 'Criador Sites',
      description: 'Ative a ferramenta Criador de Sites para seus domínios gratuitamente!',
      selectField: 'Selecione um domínio e clique em Ativar Criador de Sites',
      placeholder: 'dominio.com.br',
      manage: 'Gerenciar Criador de Sites',
      suspended: 'O plano de hospedagem não está ativo. Regularize seu plano para que possa ativar o Criador de Sites para novos domínios.',
      ready: 'Pronto, você já pode acessar seu criador de sites',
    },
    modalRemoveSite: {
      title: 'Excluir Site',
      message: 'Você tem certeza que deseja excluir o site <1>{{productName}}</1> criado na ferramenta Criador de Sites?',
      warning: 'irá apagar tudo o que você já criou!',
      buttonConfirmation: 'SIM, QUERO EXCLUIR',
    },
    steps: {
      configurations: 'Configurações iniciais',
      about: 'Sobre o site',
      tools: 'Definir ferramenta de criação',
      tips: 'Dicas para começar',
    },
    about: {
      title: {
        0: 'É hora de ',
        1: 'definir o tipo e objetivo do seu site!',
      },
      subtitle: 'Com base em suas respostas vamos indicar a melhor ferramenta de criação :)',
      question: 'Qual tipo de site você deseja iniciar criando?',
      questionTooltip: 'Escolha o tipo mais próximo da sua necessidade e não se preocupe, depois você poderá criar outros tipos se desejar.',
      institucional: {
        title: 'Site institucional',
        subtitle: '(de uma empresa)',
      },
      personal: {
        title: 'Site pessoal ou portfólio',
      },
      blog: {
        title: 'Blog',
      },
      ecommerce: {
        title: 'Loja virtual',
        subtitle: '(e-commerce)',
      },
      landing: {
        title: 'Página de captação de clientes',
        subtitle: '(landing page)',
      },
      other: {
        title: 'Outro tipo de site',
      },
      objectives: {
        question: 'Selecione o objetivo principal que pretende alcançar com seu site:',
        tooltip: 'Mesmo que você tenha mais de um objetivo, escolha aquele que mais deseja alcançar no momento.',
        placeholder: 'Selecionar objetivo',
        defaultObjectives: {
          goodSite: 'Ter um bom site (bonito, rápido e seguro)',
          visibility: 'Visibilidade e divulgação da minha marca',
          access: 'Alto número de acessos no site',
          prospection: 'Prospecção e captação de clientes',
          results: 'Resultados financeiros',
          satisfaction: 'Satisfação dos meus clientes',
          other: 'Outro objetivo',
        },
      },
      othersSitesModal: {
        title: 'Qual outro tipo de site pretende criar?',
        placeholder: 'digitar tipo do site',
        back: 'Voltar',
        save: 'Salvar',
      },
      othersObjectivesModal: {
        title: 'Qual outro objetivo pretende alcançar com o site?',
        placeholder: 'digitar objetivo',
        back: 'Voltar',
        save: 'Salvar',
      },
    },
    tips: {
      createSites: {
        title: 'Tudo pronto para iniciar o site agora mesmo!',
        buttonLabel: 'Acessar Criador de Sites',
        altSubtitle: {
          0: 'Abrimos o Criador de Sites em uma nova aba do seu navegador. Caso queira acessar novamente, utilize o atalho na página ',
          1: 'Sites',
          2: ' :)',
        },
        subtitleOne: 'Veja abaixo algumas ',
        subtitleTwo: 'dicas para você trabalhar o visual e o conteúdo do site!',
        hiddenInfo: 'Para uma melhor experiência crie o seu site em um computador.',
      },
      wordpress: {
        title: 'WordPress instalado com sucesso!',
        buttonLabel: 'Acessar WordPress',
        subtitle: 'Também encaminhamos para o e-mail de cadastro os dados de acesso a ferramenta :)',
        subtitleOne: 'Veja abaixo algumas ',
        subtitleTwo: 'dicas para você trabalhar o visual e o conteúdo do site!',
        hiddenInfo: 'Para uma melhor experiência crie o seu site em um computador.',
      },
      softaculous: {
        title: 'Parabéns, você já pode criar seu site usando o Softaculous!',
        subtitleOne: 'Veja abaixo algumas ',
        subtitleTwo: 'dicas para você trabalhar o visual e o conteúdo do site!',
        hiddenInfo: 'Para uma melhor experiência crie o seu site em um computador.',
      },
      asaasLoginBanner: {
        title: 'Acesse sua conta Asaas',
        description: 'Faça login direto na plataforma Asaas com o usuário e senha que enviamos para o seu e-mail de cadastro na plataforma.',
        button: 'Acessar minha conta Asaas',
      },
      dnsInProgressModal: {
        title: 'Seu domínio ainda está propagando',
        description: 'Seu domínio ainda está em período de propagação. Geralmente a propagação é rápida, mas pode ser que leve até 24 horas para que você possa usar a plataforma.',
        backButton: 'Voltar',
        continueButton: 'Tentar acessar mesmo assim',
      },
    },
    wordPressInstall: {
      defaultWordpressValue: 'Meu site',
      title: {
        0: 'Antes de continuar, ',
        actionWoocommerce: 'precisamos instalar o WordPress e Woocommerce!',
        actionWordPress: 'precisamos instalar o WordPress!',
      },
      subtitle: 'Preencha os campos e grupos abaixo para então ter acesso a ferramenta e criar o site :)',
      info: {
        title: 'Informações úteis',
        description: 'Descreva o site e defina os dados de acesso ao painel do WordPress.',
        questions: {
          name: {
            label: 'Nome do site',
            placeholder: 'Nome da marca',
            tooltip: 'Informe o nome que você usará em seu site.',
            errorMessage: 'O campo deve ter no mínimo 3 caracteres.',
          },
          description: {
            label: 'Descrição do site',
            placeholder: 'Diga sobre o que é o site ou negócio',
            tooltip: 'Faça uma pequena descrição sobre o seu site, pode ser uma explicação sobre o que ele aborda, por exemplo.',
          },
          email: {
            label: 'E-mail',
            placeholder: 'E-mail de acesso ao WordPress',
            errorMessage: 'O e-mail digitado é inválido.',
          },
          user: {
            label: 'Usuário',
            placeholder: 'Usuário de acesso ao WordPress',
          },
          password: {
            label: 'Senha',
            placeholder: 'Crie uma senha',
          },
          confirmPassword: {
            label: 'Confirmar senha',
            placeholder: 'Confirme a senha',
          },
        },
        passwordChecklistTexts: {
          description: 'A senha deve ter:',
          hasLowerAndUpperCase: 'Incluir caracteres maiúsculos e minúsculos',
          hasNumber: 'Incluir pelo menos 1 número',
          hasSymbol: 'Incluir pelo menos 1 símbolo',
          hasAtLeastEight: 'Ter pelo menos 8 caracteres',
          matchConfirmation: 'Ser a mesma em ambos os campos',
        },
        userChecklistTexts: {
          description: 'O usuário deve:',
          hasAtLeastEight: 'Ter pelo menos 8 caracteres',
          hasAtLeastFour: 'Ter pelo menos 4 caracteres',
          notHaveSymbol: 'Não conter nenhum símbolo (exemplo: !#$%&)',
        },
        nextStepButton: 'Ver abaixo as opções para o tema do site',
      },
      theme: {
        title: 'Escolher tema do site',
        description: 'Veja alguns temas ideais para o site e escolha o que possui a composição que mais lhe agrada.',
        tooltip: 'Você pode alterar imagens, cores e textos dos temas, para combinar com o assunto do seu negócio.',
        see: 'Visualizar tema',
        use: 'Usar tema',
        selected: 'Selecionado',
        nextStepButton: 'Ver abaixo as opções de plugins para adicionar ao site',
      },
      plugin: {
        title: 'Escolher plugins',
        optional: ' (Opcional)',
        description: 'Separamos alguns plugins populares para você. Selecione todos os que desejar usar junto com o WordPress.',
        tooltip: 'Plugins servem para melhorar o site e possibilitam aplicar diferentes funcionalidades a ele. Dentro da ferramenta será possível instalar estes e vários outros.',
        use: 'Usar plugin',
        selected: 'Selecionado',
        nextStepButton: 'Finalizar e instalar WordPress',
        nextStepButtonWithAsaas: 'Ver abaixo opção de adicionar gerenciador de pagamentos',
        descriptions: {
          elementor: 'Com esse plugin é possível criar de maneira fácil e rápida, arrastando e soltando os componentes que desejar para construir o seu site.',
          contactForm: 'Facilite a comunicação do cliente com a sua marca por meio de formulários de contato personalizados.',
          jivoChat: 'Converse e monitore visitantes em tempo real por chat online, Facebook Messenger, telefone e e-mail.',
          googleKit: 'Use um kit com a melhores ferramentas do Google para capitar informações e insights sobre o seu site de maneira centralizada. Você terá acesso ao Analytics, Adsense, dentre outros.',
          yoast: 'Facilite o cliente encontrar o seu site na internet. Use esse plugin para melhorar o ranqueamento nas ferramentas de busca como o Google.',
        },
      },
      asaas: {
        title: 'Receber por Pix, cartões e boleto',
        optional: ' (Opcional)',
        description: 'Instale um gerenciador de pagamentos para receber dos seus clientes, com as menores taxas do mercado.',
      },
      installModal: 'Estamos instalando o WordPress...',
      errorToast: 'Ocorreu um erro inesperado, tente novamente mais tarde',
    },
    wondersuite: {
      features: {
        items: {
          0: {
            icon: 'myAccount',
            title: 'Página Minha Conta',
            description: 'Crie experiências personalizadas para seus clientes',
          },
          1: {
            icon: 'cart',
            title: 'Carrinho customizado',
            description: 'Ofereça ofertas customizadas no checkout da sua loja, com soluções como “compre junto”',
          },
          2: {
            icon: 'filters',
            title: 'Filtros personalizados',
            description: 'Permita que seus clientes selecionem produtos por cor, tamanho, preço, categoria e mais',
          },
          3: {
            icon: 'wishlist',
            title: 'Lista de Desejos',
            description: 'Acompanhe o interesse dos seus clientes e faça as melhores ofertas',
          },
          4: {
            icon: 'giftCertificate',
            title: 'Vale presentes',
            description: 'Aumente sua receita e conquiste novos clientes',
          },
          5: {
            icon: 'schedules',
            title: 'Reservas e agendamentos',
            description: 'Gerencie aluguéis, reservas de viagem, estadias, locações e agendamentos de serviços',
          },
        },
      },
      tools: {
        title: 'Outras opções de ferramentas',
        toolsCards: {
          woocommerce: {
            title: 'E-commerce Padrão',
            description: () => 'Crie sua loja virtual utilizando a instalação padrão de WordPress + plugin WooCommerce',
            action: 'Experiência padrão WordPress',
            items: {
              0: () => 'Assistente de configuração que permite que você crie uma loja customizada em poucos passos',
              1: () => 'Pacote de plugins pré-instalados, com foco em fazer você ter sucesso online',
              2: () => 'Temas personalizados e responsivos, para que você ofereça a melhor experiência para seus clientes',
              3: () => 'Fácil integração com meios de pagamento e entrega',
              4: () => 'Gerenciamento fácil de produtos e estoque via WooCommerce (plugin de loja online)',
              5: () => 'Pacote de plugins de SEO, newsletter e comunicação pré-instalados e prontos para usar',
            },
            warning: 'A instalação padrão do WordPress + WooCommerce requer um pouco de conhecimento para um melhor uso. Mas não se preocupe, vamos conteúdos que podem te ajudar neste processo',
          },
          otherSite: {
            title: 'Outras ferramentas',
            description: () => (
              'Esta e outras ferramentas você encontra no Softaculous (gerenciador de aplicativos da hospedagem). Nele você encontra mais informações sobre cada ferramenta'
            ),
            action: 'Ver ferramentas',
            items: {
              0: () => (
                <>
                  Indicado para soluções mais
                  <span> complexas</span>
                </>
              ),
              1: () => (
                <>
                  Ideal para quem deseja criar
                  <span> um site personalizado</span>
                </>
              ),
            },
            warning: 'As ferramentas exigem um pouco de conhecimento técnico para seu uso',
          },
          ecommercePlus: {
            title: 'E-commerce Plus',
            subtitle: 'Sua Loja Online absoluta para WooCommerce',
            tag: 'Produto adquirido',
            preList: 'Todos os recursos da versão padrão +',
            titleText: 'E-commerce Plus',
            description: () => (
              <>
                Configure sua loja online para o
                {' '}
                <span> sucesso com a combinação perfeita de ferramentas WooCommerce, plugins YITH e orientação profissional. </span>
              </>
            ),
            action: 'Utilizar E-commerce Plus',
            items: {
              0: () => 'Plugins YITH premium para loja online, que vão transformar sua loja em uma máquina de vendas',
              1: () => 'Personalização total da página Minha Conta, permitindo que você crie experiências únicas para seus clientes',
              2: () => 'Criação de lista de desejos, para que você acompanhe o interesse dos seus clientes e faça as melhores ofertas',
              3: () => 'Inserção de filtros personalizados, para que seus clientes possam selecionar seus produtos por cor, tamanho, preço, categoria e muito mais',
              4: () => 'Criação de vales presente para aumentar a receita e conquistar novos clientes',
              5: () => 'Criação de um sistema de reversas, locação ou agendamentos',
            },
            warning: 'Os plugins incluídos neste pacote possuem o selo de qualidade YITH, empresa especializada em soluções para personalizar o WooCommerce e permitir a criação de lojas de sucesso :)',
          },
        },
      },
      wondersuiteHeroBanner: {
        title: {
          0: 'Indicamos o ',
          1: 'E-commerce Plus ',
          2: 'para você começar sua loja WooCommerce com confiança',
        },
        description: 'Construa, promova e comece a vender agora! Configure sua loja para o sucesso com a combinação perfeita de ferramentas WooCommerce, plugins profissionais YITH e criação de loja guiada.',
        tagLabel: 'TESTE GRÁTIS POR 14 DIAS',
        btnLabel: 'Experimente Grátis',
        btnGA: 'Oferta E-commerce Plus',
        assignValue: {
          0: 'Após o período de teste de 14 dias, o valor da assinatura é de ',
          1: 'R$ 24,99 ',
          2: 'por mês',
        },
        wordpressExperience: 'Toda a experiência WordPress + WooCommerce + Yith',
      },
    },
    tools: {
      title: {
        0: 'Indicamos a ferramenta ',
        ecommerce: ' para criar a loja virtual.',
        ecommercePlus: ' para criar sua loja online',
        institucional: ' para criar o site desejado.',
        personal: ' para criar o site desejado.',
        blog: ' para criar o site desejado.',
        landing: ' para criar o site desejado.',
        other: ' para criar o site desejado.',
      },
      subtitle: {
        ecommerce: 'Essa é a melhor opção para quem deseja criar uma loja online, mas você também pode escolher uma outra ferramenta.',
        ecommercePlus: 'Você já adquiriu o WooPremium para criar sua loja. Para iniciar, basta clicar no botão "Ativar WooPremium"',
        institucional: 'Se preferir, escolha outra opção apresentada abaixo :)',
        personal: 'Se preferir, escolha outra opção apresentada abaixo :)',
        blog: 'Se preferir, escolha outra opção apresentada abaixo :)',
        landing: 'Se preferir, escolha outra opção apresentada abaixo :)',
        other: 'Se preferir, escolha outra opção apresentada abaixo :)',
      },
      woocommerce: {
        title: 'WordPress + <br/> plugin Woocommerce',
        titleText: 'E-commerce Padrão',
        items: {
          0: 'Ideal para <strong>criar loja virtual</strong>',
          1: '<strong>Milhares de plugins</strong> para customizar a loja online',
          2: 'Diversos <strong>temas prontos e responsivos</strong> para diferentes tipos de negócios',
          3: 'Solução <strong>ideal para integrar diversos sistemas de envio e de pagamento</strong>',
          4: onWoocommerceHelpMaterialClick => (
            <>
              <strong>Cadastre produtos e gerencie a loja com o </strong>
              <u>
                <a href={WOOCOMMERCE_ARTICLE} onClick={() => onWoocommerceHelpMaterialClick()} target="_blank" rel="noreferrer">
                  Woocommerce
                </a>
              </u>
              {' (plugin para venda online)'}
            </>
          ),
          5: 'Ideal para quem deseja <strong>criar um site personalizado</strong>',
        },
        warning: 'Essas ferramentas requerem um pouco de conhecimento para um melhor uso. Temos conteúdos que podem ajudar nesse processo :)',
        action: 'Escolher WordPress',
      },
      wordpress: {
        title: 'WordPress',
        titleText: 'WordPress',
        description: () => (
          <>
            Crie sua loja virtual utilizando a instalação padrão de WordPress + plugin WooCommerce
          </>
        ),
        items: {
          0: () => (
            <>
              Indicado para
              {' '}
              <span>soluções mais complexas</span>
            </>
          ),
          1: () => (
            <>
              <span>Tempo de criação variável</span>
              {' '}
              conforme nível de conhecimento e objetivo do site
            </>
          ),
          2: () => (
            <>
              <span>Milhares de temas prontos e responsivos</span>
              {' '}
              para diferentes tipos de negócios
            </>
          ),
          3: () => (
            <>
              Ideal para quem deseja
              {' '}
              <span>criar um site personalizado</span>
            </>
          ),
          4: () => (
            <>
              <span>Centenas de plugins</span>
              {' '}
              para customizar o site
            </>
          ),
        },
        warning: 'Essa ferramenta requer um pouco de conhecimento para um melhor uso.',
        action: 'Escolher WordPress',
      },
      sitebuilder: {
        title: 'Criador de sites',
        titleText: 'Criador de sites',
        description: () => 'Crie um site em minutos, mesmo sem ter conhecimento técnico. É fácil e rápido.',
        items: {
          0: () => (
            <>
              Indicado para
              {' '}
              <span>soluções simples</span>
            </>
          ),
          1: () => (
            <>
              <span>Crie seu site rapidamente</span>
            </>
          ),
          2: () => (
            <>
              <span>Centenas de temas prontos e responsivos</span>
              {' '}
              para diferentes tipos de negócios
            </>
          ),
          3: () => (
            <>
              <span>Fácil de usar,</span>
              {' '}
              possui um guia para criar o site sem nenhum código
            </>
          ),
          4: () => (
            <>
              <span>Banco de imagem</span>
              {' '}
              , repleto de fotos para tornar o site mais atrativo
            </>
          ),
        },
        action: 'Escolher Criador de sites',
      },
      otherCommerce: {
        title: 'Outras ferramentas',
        titleText: 'Outras ferramentas',
        description: '<strong>Essas e outras ferramentas você encontra no Softaculous</strong> (gerenciador de aplicativos da hospedagem). Nele você encontra mais informações sobre cada ferramenta.',
        items: {
          0: 'Indicado para <strong>soluções mais complexas</strong>',
          1: 'Ideal para quem deseja <strong>criar um site personalizado</strong>',
        },
        otherIconLabel: 'Outros',
        warning: 'Essas ferramentas requerem um pouco de conhecimento para um melhor uso.',
        action: 'Ver ferramentas',
      },
      otherSite: {
        title: 'Outras ferramentas',
        titleText: 'Outras ferramentas',
        description: () => (
          <>
            <span>Essas e outras ferramentas você encontra no Softaculous</span>
            {' '}
            (gerenciador de aplicativos da hospedagem). Nele você encontra mais informações sobre cada ferramenta.
          </>
        ),
        items: {
          0: () => (
            <>
              Indicado para
              {' '}
              <span>soluções mais complexas</span>
            </>
          ),
          1: () => (
            <>
              Ideal para quem deseja criar
              {' '}
              <span>um site personalizado</span>
            </>
          ),
        },
        otherIconLabel: 'Outros',
        warning: 'Essas ferramentas requerem um pouco de conhecimento para um melhor uso.',
        action: 'Ver ferramentas',
      },
      icons: {
        other: 'Outros',
      },
    },
    actions: {
      continue: 'Continuar',
      backButton: 'Voltar',
      backHome: 'Ir para tela principal de Sites',
      showMore: 'Ver benefícios',
      showLess: 'Ver menos',
    },
    altSnappy: 'logotipo',
    altColorLine: 'linha colorida',
    configurationStep: {
      title: 'Pronto para alcançar o ',
      titleStrong: 'sucesso online?',
      text: 'Inicie a criação do site agora mesmo!',
      selectText: 'Qual será o endereço do site?',
      selectPlaceholder: 'selecionar domínio ou subdomínio',
      wishOtherDomain: 'Quero usar outro domínio ou subdomínio',
      selectedPlan: 'Veja em qual plano de hospedagem o site ficará:',
      choosePlan: 'Escolher plano',
      mainDomain: 'Domínio principal: ',
      continue: 'Continuar',
      tooltipReplace: 'Caso você já tenha um site criado para esse domínio ao continuar ele será substituído.',
      tooltipSinglePlan: 'Seu site ficará hospedado no plano abaixo por ele ser seu único plano disponível ou por seu domínio já estar adicionado à ele.',
      hirePlanTitle: 'Você não tem um plano de hospedagem contratado e ativo.',
      hirePlanSubtitle: 'Para criar um novo site, clique no botão abaixo para contratar um plano agora mesmo!',
      hirePlanCta: 'Contratar hospedagem',
      registerDomainTitle: domain => `O domínio “${domain}” ainda não está registrado.`,
      registerDomainSubtitle: 'Para criar um novo site o domínio precisa estar registrado e ativo. Clique no botão abaixo para registrá-lo agora mesmo!',
      registerDomainCta: 'Registrar domínio',
    },
    useAnotherDomain: {
      title: 'Qual endereço deseja utilizar para o site?',
      subtitle: 'Digite abaixo, selecione a extensão e veja se está pronto para uso.',
      placeholder: 'Digite seu domínio ou subdomínio (ex: meudominio.com.br).',
      buttonLabel: 'Validar domínio',
      messageErrorBr: 'Domínio inválido! Não pode conter caracteres especiais (exceto hífen), iniciar e finalizar com hífen, conter apenas números ou ter mais de 26 caracteres.',
      messageError: 'Domínio inválido! Não pode conter caracteres especiais (exceto hífen), iniciar e finalizar com hífen, conter apenas números ou ter mais de 63 caracteres.',
      tldMessageError: 'Não foi possível adicionar o domínio informado ao seu plano. Tente novamente mais tarde, ou entre em contato com o suporte.',
      selectText: 'Qual será o endereço do site?',
    },
    modalNotRegistered: {
      title: 'Validação de domínio',
      domain: 'O domínio ',
      noRegister: ' ainda não está registrado.',
      registerNow: 'Aproveite e registre agora mesmo',
      otherDomain: 'ou volte e indique outro domínio.',
      wishKeep: 'Se desejar, continue com a criação do site sem registrar o domínio, ',
      notAvailable: 'mas ao publicá-lo ele não estará disponível para acesso online.',
      backButton: 'Voltar',
      continueButton: 'Continuar criando o site',
    },
    modalRegistered: {
      title: 'Validação de domínio',
      domain: 'O domínio ',
      registered: ' já está registrado em outro provedor.',
      isCorrect: 'O endereço está correto?',
      continue: ' Se sim, continue criando o novo site :)',
      incorrect: 'Caso não seja seu ou esteja incorreto, volte e indique outro domínio.',
      backButton: 'Voltar',
      continueButton: 'Continuar criando o site',
    },
    planLimit: {
      title: 'Seu plano de hospedagem atual já chegou no limite de sites!',
      text: 'Para criar um novo site você precisa mudar para um plano maior ou comprar outro.',
      changePlan: 'Alterar plano de hospedagem',
      buyOtherPlan: 'Comprar outro plano',
    },
    modalUpgradePlan: {
      title: 'Qual plano de hospedagem você deseja alterar?',
      subtitle: 'Selecione em qual plano você gostaria de criar mais sites e veja as opções disponíveis.',
      buttonLabel: 'Ver planos disponíveis',
      domainDirector: 'Domínio principal: ',
    },
    modalChangePlan: {
      title: 'Em qual plano você deseja que o site fique?',
      subtitle: 'Selecione abaixo em qual plano de hospedagem o site ficará hospedado.',
      tooltip: 'Todo site precisa ficar hospedado em um plano. Na dúvida, selecione qualquer um abaixo. Listamos apenas os que estão ativos e aptos para criar novos sites.',
      mainDomain: 'Domínio principal: ',
      otherDomains: 'Outros domínios: ',
      noOtherDomains: 'Ainda não há outros domínios adicionados ao plano.',
      domains: ' domínios',
      domain: ' domínio',
      choosePlan: 'Escolher plano',
    },
    modalSelectDnsManual: {
      title: 'Agora falta você configurar o domínio',
      descriptionOne: 'Como o domínio ',
      descriptionTwo: ' não foi registrado aqui na HostGator, você precisa ir no outro provedor e apontar o DNS desse domínio para o seu plano de hospedagem daqui.',
      question: 'Onde o domínio foi registrado?',
      placeholder: 'selecionar provedor',
      labelButtonBack: 'Voltar',
      labelButtonKeep: 'Ver como configurar',
    },
    modalSelectDnsManualStep2: {
      title: 'Veja como configurar o domínio no outro provedor',
      description: 'Use as informações abaixo para configurar seu domínio (alterar DNS) no outro provedor e se necessário ',
      tutorialLink: 'veja como fazer essa configuração passo a passo:',
      replace: 'Substitua as informações de DNS atuais do outro provedor pelas da HostGator:',
      actualDns: 'DNS atual na ',
      hgDns: 'DNS da HostGator',
      server1: 'Servidor 1 (DNS):',
      server2: 'Servidor 2 (DNS):',
      goToHost: 'Ir para o outro provedor fazer a alteração de DNS',
      waitTime: 'Após a configuração acima, ',
      waitTimeStrong: 'a propagação de DNS do seu domínio pode levar até 24h.',
      warning: 'Se desejar, continue criando o site, mas saiba que ele não ficará online até que esta configuração esteja finalizada e propagada.',
      labelButtonBack: 'Voltar',
      labelButtonContinue: 'Continuar criando o site',
      copied: 'Copiado',
    },
    modalVerifyingSiteExist: {
      message: 'Estamos verificando se está tudo certo para você continuar...',
    },
    modalOverwrite: {
      title: 'Você já possui um site criado?',
      warningDomain: domain => (
        <>
          Caso você tenha um site criado para o domínio
          &quot;
          <span>{domain}</span>
          &quot;,
          ao avançar, você perderá todo o conteúdo publicado!
        </>
      ),
      warningLink: handleClickMaterials => (
        <>
          {'Você pode fazer uma '}
          <a href={BACKUP_INFO} target="_blank" rel="noreferrer" onClick={handleClickMaterials}>cópia (backup) de segurança</a>
          {' da sua hospedagem, ou avançar com a criação do seu novo site.'}
        </>
      ),
      checkboxLabel: 'Estou ciente e desejo continuar mesmo assim.',
      continueButtonLabel: 'Continuar criando o site',
      backButtonLabel: 'Voltar',
    },
    modalVerifiedOverwrite: {
      title: 'Você já possui um site criado para esse domínio',
      error: 'Ocorreu um erro inesperado, tente novamente mais tarde',
      warningDomain: domain => (
        <>
          Você já tem um site criado no domínio
          &quot;
          <span>{domain}</span>
          &quot;.
          <span> Ao avançar para a criação de um novo site, você perderá todo o conteúdo já publicado!</span>
        </>
      ),
      warningDomainWithDns: domain => (
        <>
          Você já tem um site criado no domínio
          &quot;
          <span>{domain}</span>
          &quot;.
        </>
      ),
      warningAboutDns: () => (
        <>
          Ao avançar para a criação de um novo site,
          {' '}
          <span>você perderá todo o conteúdo já publicado e ainda vamos precisar atualizar a configuração desse domínio (registros de DNS).</span>
        </>
      ),
      warningLink: handleClickMaterials => (
        <>
          Se desejar, faça antes uma
          <a href={BACKUP_INFO} target="_blank" rel="noreferrer" onClick={handleClickMaterials}> cópia de segurança (backup) </a>
          da sua hospedagem.
        </>
      ),
      checkboxLabel: 'Estou ciente da exclusão do conteúdo e desejo continuar mesmo assim.',
      continueButtonLabel: 'Continuar criando o site',
      backButtonLabel: 'Cancelar',
    },
    asaasBanner: {
      title: 'CRIE SUA CONTA DIGITAL COMPLETA COM ASAAS',
      chip: 'NOVO',
      description: {
        0: 'Cobre por',
        1: ' Pix, boleto, cartões ',
        2: 'e gerencie tudo da sua loja online em um só lugar. Tenha as melhores taxas do mercado com a parceria Hostgator e Asaas.',
      },
      buttonLabel: 'Quero saber mais',
    },
  },
  configuration: {
    modal: {
      stepOne: {
        title: 'Configurar a DNS do domínio para a HostGator',
        description: ({ domain }) => (
          <>
            {'Como o domínio '}
            <span>
              &quot;
              {domain}
              &quot;
            </span>
            {' não foi registrado aqui na HostGator, você precisa ir no outro provedor e apontar o DNS desse domínio para o seu plano de hospedagem daqui.'}
          </>
        ),
        question: 'Onde o domínio foi registrado?',
        placeholder: 'selecionar provedor',
        labelButtonBack: 'Voltar',
        labelButtonKeep: 'Ver como configurar',
      },
      stepTwo: {
        title: 'Veja como configurar o domínio no outro provedor',
        description: 'Use as informações abaixo para configurar seu domínio (alterar DNS) no outro provedor e se necessário ',
        tutorialLink: 'veja como fazer essa configuração passo a passo:',
        replace: 'Substitua as informações de DNS atuais do outro provedor pelas da HostGator:',
        actualDns: 'DNS atual na ',
        hgDns: 'DNS da HostGator',
        server1: 'Servidor 1 (DNS):',
        server2: 'Servidor 2 (DNS):',
        goToHost: ({ provider }) => (`Ir para a ${provider}`),
        waitTime: () => (
          <>
            {'Após a configuração acima, a propagação de DNS do seu domínio pode levar até '}
            <strong>
              24h
            </strong>
            {' domínios nacionais e até '}
            <strong>
              72h
            </strong>
            {' para domínios internacionais. '}
            <a href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer">Ler mais.</a>
          </>
        ),
        otherProvider: {
          firstLine: () => (
            <>
              <strong>
                1.
              </strong>
              {' Acesse o '}
              <strong>
                painel do seu outro provedor
              </strong>
              {' de hospedagem;'}
            </>
          ),
          secondLine: () => (
            <>
              <strong>
                2.
              </strong>
              {' No domínio que deseja configurar (alterar DNS) para o seu plano aqui da HostGator, busque por “ '}
              <strong>
                Alterar DNS
              </strong>
              {' ou '}
              <strong>
                Alterar Servidores
              </strong>
              ” - se não achar, contate o suporte desse provedor;
            </>
          ),
          thirdLine: () => (
            <>
              <strong>
                3.
              </strong>
              {' Na parte onde pede que você altere os “DNS / Servidores”, coloque os '}
              <strong>
                DNS do seu plano de hospedagem aqui da HostGator
              </strong>
              {' - Se necessário, '}
              <a href={FIND_PLAN_DNS_ARTICLE} target="_blank" rel="noreferrer">veja como localizar</a>
              .
            </>
          ),
        },
        labelButtonBack: 'Voltar para instruções',
        copied: 'Copiado',
        changesDone: 'Já fiz as alterações',
        failureDomainDns: link => (
          <>
            {'Tivemos um problema ao encontrar as '}
            <strong>informações de DNS do seu provedor atual.</strong>
            <br />
            {'Copie as informações para garantir o apontamento correto, '}
            <a href={link} target="_blank" rel="noreferrer">clique aqui e veja como fazer o passo a passo.</a>
          </>
        ),
        failurePlanDns: link => (
          <>
            {'Tivemos um problema ao encontrar as '}
            <strong>informações de DNS da HostGator.</strong>
            <br />
            {'Copie as informações para garantir o apontamento correto, '}
            <a href={link} target="_blank" rel="noreferrer">clique aqui e veja como fazer o passo a passo.</a>
          </>
        ),
      },
      stepThree: {
        title: 'Finalizando a configuração de domínio',
        description: 'Após realizar todas as alterações, acompanhe aqui o status de seu domínio.',
      },
      configuredDomainStatusTexts: {
        title: 'Alterações que você realizou:',
        description: externalDns => ({
          [PLATFORM_NAMES.registro_br]: 'DNS atual na Registro.br para DNS da Hostgator',
          [PLATFORM_NAMES.godaddy]: 'DNS atual na Godaddy para DNS da Hostgator',
          [PLATFORM_NAMES.locaweb]: 'DNS atual na Locaweb para DNS da Hostgator',
          [PLATFORM_NAMES.uolhost]: 'DNS atual na Uol Host para DNS da Hostgator',
          [PLATFORM_NAMES.kinghost]: 'DNS atual na Kinghost para DNS da Hostgator',
        })[externalDns] || 'DNS atual em outro provedor para DNS da Hostgator',
        buttonLabel: 'Verificar status do domínio',
        result: status => ({
          [PROPAGATION_STATUS.PROPAGATED]: (
            <>
              <IconCheck color="activeMedium" />
              <p>
                <strong>Propagado:</strong>
                {' A configuração do domínio aconteceu corretamente.'}
              </p>
            </>
          ),
          [PROPAGATION_STATUS.IN_PROGRESS]: (
            <>
              <IconClock color="pendingPure" />
              <p>
                <strong>Em propagação:</strong>
                {' A configuração do domínio ainda está acontecendo e pode levar até '}
                <strong>24h</strong>
                {' para domínios nacionais e até '}
                <strong>72h</strong>
                {' para domínios internacionais. '}
                <Link text="Ler mais." href={DNS_PROPAGATION_ARTICLE} />
              </p>
            </>
          ),
          [PROPAGATION_STATUS.NOT_PROPAGATED]: (
            <>
              <IconDanger color="problemMedium" />
              <div>
                <p>
                  <strong>Não propagado:</strong>
                  {' A configuração do domínio ainda não foi encontrada. Mas fique tranquilo: pode levar algumas horas para a atualização na rede acontecer.'}
                </p>
                <p>
                  {'Se já faz mais de 72h horas, verifique com seu provedor se a configuração foi feita corretamente. '}
                  <Link text="Ler mais." href={DNS_PROPAGATION_ARTICLE} />
                </p>
              </div>
            </>
          ),
        })[status]
          || (
            <>
              <IconDanger color="problemMedium" />
              <div>
                <p>
                  <strong>Não propagado:</strong>
                  {' A configuração do domínio ainda não foi encontrada. Mas fique tranquilo: pode levar algumas horas para a atualização na rede acontecer.'}
                </p>
                <p>
                  {'Se já faz mais de 72h horas, verifique com seu provedor se a configuração foi feita corretamente. '}
                  <Link text="Ler mais." href={DNS_PROPAGATION_ARTICLE} />
                </p>
              </div>
            </>
          ),
      },
    },
  },
  page: {
    editSiteCta: 'Editar Site',
    suspendedCpanelTooltip: 'Seu plano de hospedagem foi suspenso e, por isso, o acesso ao cPanel está indisponível. Para recuperar o acesso, efetue o pagamento da assinatura.',
    unlimited: 'Ilimitados',
    hostCount: ({
      withGatorSolutions,
      group,
    }) => {
      const amount = withGatorSolutions
        ? (group && group[0] && group[0].cpanel_domains && group[0].cpanel_domains.length) || 0
        : group.length;
      if (amount) {
        return (
          <>
            <strong>Sites:</strong>
            {` ${amount}`}
          </>
        );
      }
    },
    empty: 'Adquira uma hospedagem e domínio e dê o primeiro passo para criar seu site!',
    title: 'Listagem de sites',
    description: 'Veja os seus sites hospedados aqui na HostGator :)',
    createNewSiteButton: 'Criar site ou Loja virtual',
    seeMoreButton: 'Carregar mais sites',
    emptyState: 'Não encontramos nenhum site que corresponda aos filtros aplicados.',
    alerts: {
      financial: {
        isPending: {
          label: () => (
            <p>
              <strong>Aguardando a compensação de pagamento das assinaturas</strong>
            </p>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isInAnalysis: {
          label: () => (
            <>
              <strong>Estamos realizando a análise de segurança das assinaturas</strong>
            </>
          ),
        },
        isToDue: {
          label: () => (
            <>
              <strong>A fatura de renovação de Domínio e/ou Plano de Hospedagem já está disponível para pagamento</strong>
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isOverDue: {
          label: ({ daysForPayment }) => (
            <>
              <strong>{'Pendência financeira: '}</strong>
              {daysForPayment === 1 ? ` ${daysForPayment} dia para o site ficar fora do ar` : ` ${daysForPayment} dias para o site ficar fora do ar`}
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isSuspended: {
          label: ({ daysForPayment }) => (
            <>
              <strong>{'Pendência financeira: '}</strong>
              {daysForPayment === 1 ? ` ${daysForPayment} dia para o site ser cancelado e ter seus arquivos excluídos` : ` ${daysForPayment} dias para o site ser cancelado e ter seus arquivos excluídos`}
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button
              label={withGatorSolutions
                ? 'Efetue o pagamento da fatura em aberto'
                : 'Pagar fatura'}
              variant="text"
              size="medium"
              onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()}
            />
          ),
          subLabel: 'SITE FORA DO AR',
        },
      },
      propagation: {
        isExternalNotConfigured: {
          label: () => (
            <>
              <strong>{'Domínio ainda não configurado (alterado DNS): '}</strong>
              configure na empresa onde tem o domínio registrado e ativo
            </>
          ),
          button: () => (
            <Link text="Veja como fazer" href="https://suporte.hostgator.com.br/hc/pt-br/articles/6597512530331" />
          ),
        },
        isInternalNotConfigured: {
          label: () => (
            <>
              <strong>Domínio ainda não configurado (alterado DNS)</strong>
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Configurar domínio" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isInPropagation: {
          label: () => (
            <>
              <strong>{'Domínio em propagação: '}</strong>
              Aguarde até 24h para a propagação do seu domínio
            </>
          ),
        },
        isPropagated: {
          label: () => (
            <>
              <strong>Domínio propagado</strong>
            </>
          ),
        },
      },
      ssl: {
        NOT_ACTIVE: {
          label: () => (
            <>
              <strong>{'SSL ainda não ativado: '}</strong>
              Configure o domínio primeiro e aguarde até 24h após a propagação para o SSL ficar ativo
            </>
          ),
        },
        IN_PROGRESS: {
          label: () => (
            <>
              <strong>{'SSL em processo de ativação: '}</strong>
              Ele será instalado automaticamente em até 24h após a propagação do domínio
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Ativar agora mesmo!" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        ACTIVE: {
          label: () => (
            <>
              <strong>SSL ativo</strong>
            </>
          ),
        },
        ACTIVATION_FAILED: {
          label: () => (
            <>
              <strong>{'Pendência SSL: '}</strong>
              Você precisa tomar alguma ação para que o SSL seja ativado
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Ver pendência" variant="text" size="medium" onClick={handleClick} />
          ),
        },
      },
      sitelock: {
        not_active: {
          label: () => (
            <>
              <strong>Seu site ainda não está monitorado com o SiteLock</strong>
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Monitorar site" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        active: {
          label: () => (
            <>
              <strong>Site monitorado com SiteLock</strong>
            </>
          ),
        },
      },
    },
    tags: {
      is_main_domain: 'Domínio principal',
      site_offline: 'Site fora do ar',
    },
    groupedPage: {
      alerts: {
        suspended: ({ history, invoicesUrl }) => (
          (
            <>
              {'Você possui um ou mais domínios expirados. Renove agora mesmo para que seus sites e e-mails não fiquem fora do ar. '}
              <Link
                text="Ver faturas"
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="suspendedCta"
              />
            </>
          )
        ),
        overdue: ({ history, invoicesUrl }) => (
          (
            <>
              {'Você possui um ou mais domínios com pendências financeiras. Seus sites e e-mails podem ficar fora do ar. '}
              <Link
                text="Ver faturas"
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="overdueCta"
              />
            </>
          )
        ),
        dnsProblem: ({
          history,
          domainsUrl,
        }) => (
          <>
            {'Ocorreu uma falha no registro de um ou mais domínios. '}
            <Link
              text="Ver domínios"
              href={domainsUrl}
              historyRedirect
              history={history}
              target="_self"
              testId="dnsProblemCta"
            />
          </>
        ),
        to_due: ({ history, invoicesUrl }) => (
          (
            <>
              {'Você possui um ou mais domínios com pendências financeiras. Seus sites e e-mails podem ficar fora do ar. '}
              <Link
                text="Ver faturas"
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="toDueCta"
              />
            </>
          )
        ),
        pending: ({ history, domainsUrl }) => (
          (
            <>
              {'Você possui um ou mais domínios com compensação de pagamento pendente. '}
              <Link
                text="Ver domínios."
                href={domainsUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="pendingCta"
              />
            </>
          )
        ),
        in_analysis: 'Estamos realizando a análise de segurança de um ou mais domínios. Não se preocupe, esse é um procedimento normal.',
      },
    },
    newAlerts: {
      financial: {
        isPending: {
          label: ({ groupMainDomain }) => (
            <>
              Aguardando a compensação de pagamento da assinatura do seu plano com o domínio principal
              {' '}
              <strong>{groupMainDomain}</strong>
              .
              {' '}
              Caso já tenha pago, por favor aguarde.
              {' '}
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isInAnalysis: {
          label: () => 'Estamos realizando a análise de segurança da assinatura do plano. Não se preocupe, esse é um procedimento normal.',
        },
        isToDue: {
          label: () => 'A fatura de renovação do plano de Hospedagem já está disponível para pagamento. ',
          button: ({ handleClick }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isOverDue: {
          label: ({ daysForPayment }) => (daysForPayment === 1 ? ` ${daysForPayment} dia para seu plano ser suspenso e seus sites ficarem fora do ar. ` : ` ${daysForPayment} dias para seu plano ser suspenso e seus sites ficarem fora do ar. `),
          button: ({ handleClick }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isSuspended: {
          label: ({ daysForPayment, withGatorSolutions, groupMainDomain }) => {
            if (withGatorSolutions) {
              return (
                <>
                  {'Seu plano com domínio principal '}
                  <strong>{groupMainDomain}</strong>
                  {` foi suspenso e os sites vinculados a ele estão fora do ar. Em ${daysForPayment} dias ele será cancelado e seus arquivos serão excluídos.`}
                </>
              );
            }

            return (daysForPayment === 1
              ? `Seu plano foi suspenso e os sites vinculados a ele estão fora do ar. Em ${daysForPayment} dia ele será cancelado e seus arquivos serão excluídos. `
              : `Seu plano foi suspenso e os sites vinculados a ele estão fora do ar. Em ${daysForPayment} dias ele será cancelado e seus arquivos serão excluídos. `
            );
          },
          button: ({ handleClick }) => (
            <Button label="Pagar fatura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
      },
      propagation: {
        not_contracted: (
          <>
            <strong>Domínio não registrado</strong>
            {': Este domínio ainda não foi adquirido. '}
            <Link text="Contrate agora." href={GET_A_NEW_DOMAIN} target="_blank" />
          </>
        ),
        in_progress: (
          <>
            <strong>Domínio em propagação</strong>
            {': Aguarde até 24h para a propagação do seu domínio'}
          </>
        ),
      },
    },
    tagNew: 'Novo',
  },
  sslModal: {
    loading: 'Aguarde enquanto tentamos ativar o Certificado SSL. Este processo pode levar alguns minutos...',
    verifyPendencyLoading: 'Estamos verificando qual a pendência da ativação do Certificado SSL...',
    backButtonLabel: 'Voltar',

    domainNotRegistered: {
      domainNotRegisteredTitle: 'Domínio ainda não registrado',
      domainNotRegisteredDescription: ({ domain }) => (
        <>
          {'O domínio "'}
          <strong>{domain}</strong>
          {'" ainda não está registrado. '}
          <b>Clique no botão abaixo para registrá-lo</b>
          {', e depois que ele estiver ativo e propagado, o Certificado SSL será automaticamente ativado em até 24h.'}
        </>
      ),
      registerDomainButtonLabel: 'Registrar domínio',
    },
    externalDomainNotPointed: {
      externalDomainNotPointedTitle: 'O domínio não está configurado para a HostGator',
      externalDomainNotPointedDescription: ({ domain }) => (
        <>
          {'Vimos que o domínio "'}
          <strong>{domain}</strong>
          {'" está configurado para um provedor diferente da HostGator.'}
          <br />
          <br />
          Clique no botão abaixo para ver como fazer essa correção nas principais empresas de hospedagem ou contate o Suporte desse provedor.
        </>
      ),
      howToConfigure: 'Ver como configurar',
      materialUrl: 'https://suporte.hostgator.com.br/hc/pt-br/articles/6597512530331',
    },

    internalDomainNotPointed: {
      internalDomainNotPointedTitle: 'Domínio não configurado para a hospedagem HostGator',
      internalDomainNotPointedDescription: ({ domain }) => (
        <>
          {'Vimos que o domínio "'}
          <strong>{domain}</strong>
          {'" não está configurado (DNS apontado) para o Plano de Hospedagem da HostGator.'}
          <br />
          <br />
          Para que você possa gerenciar seu site e ter o Certificado SSL ativo nessa hospedagem, será necessário configurar seu domínio (registro tipo A na Zona de DNS) para o seu plano.
          <br />
          <br />
          Caso você opte por manter o apontamento para outro plano de hospedagem, você deve acessar o painel do seu plano para gerenciar o certificado SSL.
        </>
      ),
      keepInOtherHostButtonLabel: 'Manter em outro provedor',
      keepInHostgatorButtonLabel: 'Manter na HostGator',

      keepInHostgator: {
        keepInHostgatorTitle: 'Manter site e SSL na HostGator',
        keepInHostgatorDescription: ({ domain }) => (
          <>
            {'Faremos os ajustes dos registros de Zona DNS no domínio "'}
            <strong>{domain}</strong>
            {'" e por conta disso passará por um novo período de '}
            <Link text="propagação." href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693" />
            <br />
            <br />
            {'Após propagado, o '}
            <b>Certificado SSL será ativado automaticamente</b>
            {' em até 24h.'}
          </>
        ),
        agreeButtonLabel: 'Estou ciente e concordo',
        pointingDnsLoading: 'Aguarde enquanto realizamos os ajustes...',

        keepInHgSuccessTitle: 'Ajustes realizados na Zona DNS',
        keepInHgSuccessDescription: ({ domain }) => (
          <>
            {'Os ajustes na Zona DNS do domínio "'}
            <strong>{domain}</strong>
            {'" foram realizados! Aguarde os prazos de propagação do domínio e de ativação do Certificado SSL.'}
          </>
        ),
        closeButton: 'Fechar',

        keepInHgErrorTitle: 'Não foi possível fazer os ajustes na Zona DNS',
        keepInHgErrorDescription: ({ domain }) => (
          <>
            {'Não foi possível realizar os ajustes na Zona DNS do domínio "'}
            <strong>{domain}</strong>
            {'". Clique no botão abaixo para abrir um chat com nossa equipe de Suporte para que possam verificar.'}
          </>
        ),
        cancelButtonLabel: 'Cancelar',
        openChatButtonLabel: 'Abrir chat',
      },
      keepInOtherHost: {
        keepInOtherHostTitle: 'Manter site e SSL em outro provedor',
        keepInOtherHostDescription: ({ domain }) => (
          <>
            {'Como você optou por manter o site e SSL do domínio "'}
            <strong>{domain}</strong>
            {'" configurados para outro provedor, a ativação do Certificado SSL através da HostGator não é possível. Sugerimos que você entre em contato com o Suporte dessa outra empresa.'}
          </>
        ),
        understoodButtonLabel: 'Entendi',
      },
    },

    autoSSL: {
      error: {
        title: 'Houve algum erro na ativação do SSL',
        description: domain => (
          <>
            Não foi possível ativar o Certificado SSL do domínio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            . Clique no botão abaixo para tentarmos ativar novamente.
          </>
        ),
        button: 'Tentar ativar novamente',
      },
      active: {
        title: 'Certificado SSL ativado com sucesso',
        description: domain => (
          <>
            O Certificado SSL do domínio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            {' '}
            foi ativado com sucesso!
          </>
        ),
        button: 'Fechar',
      },
      activation_error: {
        title: 'Não foi possível ativar o SSL',
        description: domain => (
          <>
            Não foi possível concluir a ativação do Certificado SSL do domínio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            ! Clique no botão abaixo para abrir um chat com nossa equipe de Suporte para que possam verificar.
          </>
        ),
        button: 'Abrir chat',
        cancel: 'Cancelar',
      },
    },
  },
  welcomeChecklistBanner: {
    header: {
      titleStart: userName => (`Olá, ${userName}! Vamos começar? 👋`),
      titleContinue: userName => (`Olá, ${userName}! Os seus projetos estão te aguardando! 😊`),
      titleFinish: 'Retorne ao checklist do seu projeto quando quiser 🐊✨',
      showChecklist: 'Mostrar checklist',
      hideChecklist: 'Ocultar checklist',
      dontShowThisBanner: 'Não quero mais ver este painel',
      descriptions: {
        start: 'Preparamos este guia para te auxiliar nos primeiros passos dos seus projetos.',
        continue: 'Nossos materiais foram selecionados para te ajudar a tirar a sua ideia do papel.',
        finish: 'Nosso guia estará sempre aqui para te auxiliar em todas as etapas do seu projeto.',
      },
      step: 'PASSO ',
    },
    blockMessage: 'Conclua o passo anterior para acessar o conteúdo e continuar.',
    dnsCard: {
      title: 'Configurar domínio e DNS',
      description1: (
        <>
          O domínio é o
          <strong> ponto de partida </strong>
          do seu projeto online, fundamental para fortalecer a sua marca na web.
        </>),
      description2: 'Para garantir que seu site e e-mail funcionem de forma eficiente, certifique-se de que o domínio está configurado corretamente.',
      link1: {
        label: 'Verificar configuração do DNS',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360052823753-Como-saber-se-o-DNS-est%C3%A1-apontando-corretamente',
      },
      link2: {
        label: 'Configurar domínio registrado na HostGator',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000497973-Como-configurar-o-dom%C3%ADnio-alterar-DNS#HostGator',
      },
      link3: {
        label: 'Configurar domínio registrado externamente',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/6597512530331-Como-configurar-o-dom%C3%ADnio-alterar-DNS-em-outro-provedor-Registro-br-UOL-Host-GoDaddy-Locaweb-KingHost',
      },
    },
    emailCard: {
      title: 'Criar E-mail Profissional',
      description1: 'Reforce a credibilidade e o reconhecimento da sua marca ao se comunicar com clientes e parceiros. ',
      description2: (
        <>
          Crie endereços de e-mail ilimitados utilizando o seu domínio, como por exemplo
          <strong> vendas@seudominio.com.br.</strong>
        </>),
      button: 'Criar contas de e-mail',
      link1: {
        label: 'Como usar o e-mail em plataformas externas (Gmail, Outlook e outros)',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000388014-Como-usar-o-e-mail-em-plataformas-externas-Gmail-Outlook-e-outros?utm_source=portal&utm_medium=passo2&utm_campaign=configurar-email-plataformas-externas&utm_id=checklist-br',
      },
    },
    createSiteCard: {
      title: 'Criar Site Profissional',
      description1: 'Crie um site que inspire confiança e profissionalismo aos seus visitantes, fortalecendo a reputação da sua marca ou projeto.',
      description2: 'Construa a sua presença online e expanda seu negócio. ',
      button: 'Criar site ou Loja virtual',
      link1: {
        label: 'Como criar um site em WordPress',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001935813-Como-criar-um-site-em-WordPress-atrav%C3%A9s-do-Portal-do-Cliente',
      },
      link2: {
        label: 'Verificar certificado de segurança (SSL)',
        url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001963913-Ativa%C3%A7%C3%A3o-do-Certificado-SSL-gratuito-na-Hospedagem',
      },
      link3: {
        label: 'Dicas para criar uma Landing Page de sucesso',
        url: 'https://www.hostgator.com.br/guias/landing-page/',
      },
    },

  },
};
