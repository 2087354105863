import React, { useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Radio } from '@/pages/common/inputs/Radio';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './SubscriptionCancelModal.styles';
import { SubscriptionCancelModalProps, CancelOptions } from './SubscriptionCancelModal.types';
import { billingAnalytics } from '@/analytics';

const SubscriptionCancelModal = ({
  cancelRenewalDescriptionLabel = 'You have already paid and you can continue using the subscription until the end of the cycle (contracted period) and you will not pay anything else for it. At the end of the cycle, your subscription will be canceled automatically.',
  cancelRenewalLabel = 'I no longer want to renew the subscription',
  cancelImmediateDescriptionLabel = 'By choosing to cancel immediately, all data and files will be erased and deleted immediately.',
  cancelImmediateLabel = 'I want to cancel immediately',
  continueButtonLabel = 'Continue',
  descriptionLabel = 'Select the form you want below:',
  onCloseClick,
  onContinueButtonClick,
  onQuitButtonClick,
  quitButtonLabel = 'Give up action',
  titleLabel = 'Cancel subscription',
  canShowCancelRenewalOption = true,
  canShowCancelImmediateButton = false,
}) => {
  const initialRadioOptionState = canShowCancelRenewalOption ? CancelOptions.CANCEL_RENEWAL : CancelOptions.CANCEL_IMMEDIATE;
  const [option, setOption] = useState(initialRadioOptionState);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
  };

  const handleOnQuitButtonClick = (event) => {
    onQuitButtonClick && onQuitButtonClick(event);
  };

  const handleOnContinueButtonClick = (event) => {
    onContinueButtonClick && onContinueButtonClick(event, option);
  };

  const onOptionItemClick = option => (event) => {
    if (option === CancelOptions.CANCEL_IMMEDIATE) {
      subscriptionAnalytics.cancelSubscriptionClick('2_opcao_cancelar_imediato');
    } else {
      subscriptionAnalytics.cancelSubscriptionClick('2_opcao_cancelar_renovacao');
    }

    event.stopPropagation();
    setOption(option);
  };

  const isCancelRenewalSelected = () => option === CancelOptions.CANCEL_RENEWAL;

  const isCancelImmediateSelected = () => option === CancelOptions.CANCEL_IMMEDIATE;

  return (
    <Styles.Wrapper>
      <Modal
        maxWidth={726}
        disablePadding
        onClose={handleOnCloseClick}
        testId="subscription-manage-cancel-modal"
      >
        <Styles.Content>
          <Styles.Header>
            <Styles.Title data-testid="subscription-manage-cancel-modal-title">{titleLabel}</Styles.Title>
            <Styles.Description data-testid="subscription-manage-cancel-modal-description">{descriptionLabel}</Styles.Description>
          </Styles.Header>
          <Styles.OptionList>
            {canShowCancelRenewalOption && (
            <Styles.OptionItem
              data-testid="subscription-manage-cancel-modal-renewal-option"
              checked={isCancelRenewalSelected()}
              onClick={onOptionItemClick(CancelOptions.CANCEL_RENEWAL)}
            >
              <Styles.OptionItemWrapper>
                <Styles.OptionRadio checked={isCancelRenewalSelected()}>
                  <Radio
                    size="small"
                    checked={isCancelRenewalSelected()}
                    inputId={CancelOptions.CANCEL_RENEWAL}
                  />
                </Styles.OptionRadio>
                <Styles.OptionContent>
                  <Styles.OptionLabel
                    checked={isCancelRenewalSelected()}
                    data-testid="subscription-manage-cancel-modal-option-renewal-label"
                    htmlFor={CancelOptions.CANCEL_RENEWAL}
                  >
                    {cancelRenewalLabel}
                  </Styles.OptionLabel>
                  <Styles.OptionDescription
                    data-testid="subscription-manage-cancel-modal-option-renewal-description"
                  >
                    {cancelRenewalDescriptionLabel}
                  </Styles.OptionDescription>
                </Styles.OptionContent>
              </Styles.OptionItemWrapper>
            </Styles.OptionItem>
            )}

            {canShowCancelImmediateButton && (

            <Styles.OptionItem
              data-testid="subscription-manage-cancel-modal-immediate-option"
              checked={isCancelImmediateSelected()}
              onClick={onOptionItemClick(CancelOptions.CANCEL_IMMEDIATE)}
            >
              <Styles.OptionItemWrapper>
                <Styles.OptionRadio checked={isCancelImmediateSelected()}>
                  <Radio
                    size="small"
                    checked={isCancelImmediateSelected()}
                    inputId={CancelOptions.CANCEL_IMMEDIATE}
                  />
                </Styles.OptionRadio>
                <Styles.OptionContent>
                  <Styles.OptionLabel
                    checked={isCancelImmediateSelected()}
                    data-testid="subscription-manage-cancel-modal-option-immediate-label"
                    htmlFor={CancelOptions.CANCEL_IMMEDIATE}
                  >
                    {cancelImmediateLabel}
                  </Styles.OptionLabel>
                  <Styles.OptionDescription
                    data-testid="subscription-manage-cancel-modal-option-immediate-description"
                  >
                    {cancelImmediateDescriptionLabel}
                  </Styles.OptionDescription>
                </Styles.OptionContent>
              </Styles.OptionItemWrapper>
            </Styles.OptionItem>
            )}
          </Styles.OptionList>
          <Styles.Controls>
            <Styles.ControlButton>
              <Button
                testId="subscription-manage-cancel-modal-quit-button"
                onClick={handleOnQuitButtonClick}
                label={quitButtonLabel}
              />
            </Styles.ControlButton>
            <Styles.ControlButton>
              <Button
                testId="subscription-manage-cancel-modal-continue-button"
                onClick={handleOnContinueButtonClick}
                label={continueButtonLabel}
              />
            </Styles.ControlButton>
          </Styles.Controls>
        </Styles.Content>
      </Modal>
    </Styles.Wrapper>
  );
};

SubscriptionCancelModal.propTypes = SubscriptionCancelModalProps;

export default SubscriptionCancelModal;
