import {
  Typography, withStyles, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import React, {
  useContext, useEffect, useState, useCallback, useLayoutEffect, useMemo,
} from 'react';
import { Trans, withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import Button from '@material-ui/core/Button';
import { Button as ButtonV1 } from '@/pages/common/v1/Button';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import TitanUpgradeTitle from '@/components/Email/TitanUpgrade/TitanUpgradeTitle';
import { requestUpgradePlans } from '@/redux/actions/emails';
import Loader from '@/components/Layout/Loader';
import { EmailsContext } from '@/contexts/Emails/context';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import {
  TRIAL_PLAN, ESSENTIALS_PLAN, BASIC_PLAN, ULTRA_PLAN,
} from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { TITAN, CPANEL } from '@/components/Email/TypeServiceEmails';
import ModalActiveCPanel from '@/components/Modal/ModalActiveCPanel';
import * as actions from '@/redux/actions/emails';
import { queryParams } from '@/utils/Application/queryParams';
import styles from './TitanHirePlan.styles';
import SelectHirePlan from './SelectNewPlan';
import ModalTitanTrialColorful from '@/components/Email/ModalTitanTrialColorful';
import { isBRServer } from '@/utils/Validators/validation';
import { renderEmailPlatformSelectionPageWithTitanTrial, executeGACloseInModalHirePlan } from '@/utils/ThirdParties/tagManager';
import {
  ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM, ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN, ALPHA_PLAN_TYPE_CHOICE_IN_EMAIL_LIST, FOXTROT_TITAN_ULTRA_SETUP_PAGE,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { emailsActions } from '@/redux/modules/emails';

const TitanHirePlan = ({ classes, domain, t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const ativePlanTypeChoiceEmailList = useFeatureIsOn(ALPHA_PLAN_TYPE_CHOICE_IN_EMAIL_LIST);
  const enableTrialTitanByUrlParam = useFeatureIsOn(ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM);
  const enableTitanUltra = useFeatureIsOn(FOXTROT_TITAN_ULTRA_SETUP_PAGE);
  const {
    redirectToInvoices, invoiceId, paymentMethod, domainsWithInvoices,
  } = useSelector(state => state.emails.buyFastcheckout);

  const [plans, setPlans] = useState([]);
  const { renderedEmailList, loadingCpanel, loadingProEmails } = useContext(EmailsContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const domainObject = find(propEq('domain', domain), renderedEmailList);
  const [paymentCycle, setPaymentCycle] = useState('monthly');

  const filteredPlans = enableTitanUltra ? plans : plans.filter(plan => plan.id !== ULTRA_PLAN);

  const location = useLocation();
  const idProEmail = queryParams(location.search, 'idPro');
  const typeServiceEmail = queryParams(location.search, 'service');
  const isHostingSelected = queryParams(location.search, 'selected') === 'true';
  const cpanelUrl = '/emails-list/titan-hire-plan-active-cpanel';
  const titanFreeUrl = '/emails-list/titan-hire-free-plan';

  const [hasCPanel, setHasCPanel] = useState((window.location.pathname).includes(cpanelUrl));
  const [hasFreemium, setHasFreemium] = useState((window.location.pathname).includes(titanFreeUrl) || (window.location.pathname).includes(cpanelUrl));

  useEffect(() => {
    if (ativePlanTypeChoiceEmailList) {
      if (typeServiceEmail === CPANEL) {
        setHasCPanel(true);
        setHasFreemium(false);
      } else if (typeServiceEmail === TITAN) {
        setHasFreemium(true);
      }
    }
  }, [ativePlanTypeChoiceEmailList, typeServiceEmail]);

  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(cpanelUrl);

  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;

  const [modalActiveCPanelAvailable, setModalActiveCPanelAvailable] = useState(false);
  const [idHosting, setIdHosting] = useState(queryParams(location.search, 'id'));

  const [essentialData, setEssentialData] = useState({ name: '', price: '' });
  const [ModalTitanTrialColorfulAvailable, setModalTitanTrialColorfulAvailable] = useState(false);
  const displayModalTrialByUrlParam = queryParams(location.search, 'dspActtrial') != null && enableTrialTitanByUrlParam;
  const [isEnabledByURLParam, setEnabledByURLParam] = useState(false);

  useLayoutEffect(() => {
    if (shouldRenderNewTitanCards) {
      renderEmailPlatformSelectionPageWithTitanTrial();
    }
  }, [shouldRenderNewTitanCards]);


  const getDataOfEssential = useCallback(() => dispatch(
    requestUpgradePlans(),
  ).then(({ data }) => {
    const [planData] = data.filter(plan => plan.id === ESSENTIALS_PLAN);
    let dataEssential = { name: '', price: '' };
    if (planData) {
      const { name, configoptions } = planData;
      const [{ monthly: price }] = configoptions;

      dataEssential = { name, price };

      if (isBRServer) {
        dataEssential.price = price.replace('.', ',');
      }
    }

    return dataEssential;
  }), [dispatch]);
  const enabledCardCPanel = useMemo(() => (
    ativePlanTypeChoiceEmailList
      ? (typeServiceEmail === CPANEL || ((domainObject != null && !domainObject.configured) && isTitanHirePlanActiveCpanel))
      : hasCPanel),
  [ativePlanTypeChoiceEmailList, domainObject, hasCPanel, typeServiceEmail, isTitanHirePlanActiveCpanel]);

  useEffect(() => {
    const thisDomainAlreadyHasPremiumInvoice = domainsWithInvoices.includes(domain);
    if (redirectToInvoices && invoiceId && paymentMethod && thisDomainAlreadyHasPremiumInvoice) {
      history.push(`${t('routes.billing')}${t('routes.unpaid')}/${invoiceId}/${paymentMethod}`);
    }
  }, [t, invoiceId, paymentMethod, redirectToInvoices, history, domainsWithInvoices, domain]);

  useEffect(() => {
    if (shouldRenderNewTitanCards) {
      getDataOfEssential().then(setEssentialData);
    }
  }, [getDataOfEssential, shouldRenderNewTitanCards]);

  useEffect(() => {
    if (hasCPanel) {
      dispatch(actions.requestProEmailHostingPlans(domain))
        .then(({ data }) => (data[0] ? data[0].hostingId : null))
        .then(setIdHosting);
    }
  }, [hasCPanel, dispatch, domain]);

  useEffect(() => {
    dispatch(emailsActions.emails.paymentMethods.request());
  }, [dispatch]);

  if (idHosting === null && hasFreemium && !hasCPanel) {
    history.push(t('routes.emailsList'));
  }


  const displayTrialTitanModalByUrl = useCallback(() => {
    if (enableTrialTitanByUrlParam && displayModalTrialByUrlParam) {
      if (domainObject && domainObject.id) {
        setModalTitanTrialColorfulAvailable(true);
        setEnabledByURLParam(true);
      }
    }
  }, [domainObject, enableTrialTitanByUrlParam, displayModalTrialByUrlParam]);

  useEffect(() => {
    displayTrialTitanModalByUrl();
  }, [displayTrialTitanModalByUrl]);


  useEffect(() => {
    dispatch(requestUpgradePlans()).then((response) => {
      if (shouldRenderNewTitanCards) {
        const [planData] = response.data.filter(plan => plan.id === ESSENTIALS_PLAN);
        if (planData) {
          const { name, configoptions } = planData;
          const [{ monthly: price }] = configoptions;

          const dataEssential = { name, price };

          if (isBRServer) {
            dataEssential.price = price.replace('.', ',');
          }
        }
        getDataOfEssential().then(setEssentialData);
      }

      if (hasFreemium) {
        response.data.unshift({
          id: BASIC_PLAN,
          type: 'product',
          currency: 1,
          name: t('proEmail.hirePlan.free'),
          tax: false,
          description: t('proEmail.upgradePlan.freeBenefits'),
          configoptions: [
            {
              id: 1,
              gid: 37,
              monthly: '0.00',
              annually: '0.00',
            },
          ],
        });
      }

      response.data = response.data.filter(plan => plan.id !== TRIAL_PLAN);

      setPlans(response.data);

      setFirstLoad(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasFreemium, t]);

  if (firstLoad || loadingCpanel || loadingProEmails || domainObject === undefined) {
    return <Loader />;
  }

  const handleChange = (event) => {
    setPaymentCycle(event.target.value);
  };

  const handleOpen = async () => {
    setModalActiveCPanelAvailable(true);
  };

  const handleOpenModalTitanTrialColorful = async () => {
    setModalTitanTrialColorfulAvailable(true);
  };

  const handleCloseModalTitanTrialColorful = async () => {
    setModalTitanTrialColorfulAvailable(false);
    executeGACloseInModalHirePlan();

    if (displayModalTrialByUrlParam) {
      history.push(t('routes.emailsList'));
    }
  };

  return (

    <ContentContainer className={classes.root}>
      { (shouldRenderNewTitanCards || displayModalTrialByUrlParam) && (
      <>
        <ModalTitanTrialColorful
          isOpen={ModalTitanTrialColorfulAvailable}
          isEnabledByURLParam={isEnabledByURLParam}
          selectedDomain={{ domain, domain_id: domainObject.domain_id, domainObject }}
          essentialData={essentialData}
          onClose={handleCloseModalTitanTrialColorful}
          offerContext={['setup_page_trial_activation']}
          location="modalHirePlan"
        />
      </>
      )}

      <ModalActiveCPanel
        onClose={() => setModalActiveCPanelAvailable(false)}
        open={modalActiveCPanelAvailable}
        idProEmail={idProEmail}
        domainObject={domainObject}
        domain={domain}
      />
      <TitanUpgradeTitle domain={domain} origin="hirePlan" />
      <div className={classes.planContainer}>


        { (!shouldRenderNewTitanCards) && (
          <Typography className={classes.containerTitle}>
            {t('proEmail.hirePlan.containerDescription')}
          </Typography>
        )}

        { (!shouldRenderNewTitanCards) && (
          <Typography className={classes.containerSubTitle}>
            {t('proEmail.hirePlan.bestFormatToPayment')}
          </Typography>
        )}

        {shouldRenderNewTitanCards && (
          <Typography className={classes.containerNewTitle}>
            {t('proEmail.hirePlan.newContainerDescription')}
          </Typography>
        )}

        { (!shouldRenderNewTitanCards) && (
          <RadioGroup
            row
            className={classes.gridRadio}
            value={paymentCycle}
            onChange={handleChange}
          >

            <FormControlLabel
              value="annually"
              className={classes.gridList}
              label={(
                <div className={classes.annuallyPriceWrapper}>
                  <Typography className={classes.labelRadio}>
                    {t('proEmail.hirePlan.annually')}
                  </Typography>
                  <Typography className={classes.reductionSquare}>
                    <Trans i18nKey="proEmail.hirePlan.savedPercent">
                      <>0</>
                      {{ savedAmount: 15 }}
                      <>1</>
                    </Trans>
                  </Typography>
                </div>
              )}
              control={<Radio className={classes.radio} color="primary" data-testid="ciclyAnnually" />}
            />
            <FormControlLabel
              value="monthly"
              label={(
                <Typography className={classes.labelRadio}>
                  {t('proEmail.hirePlan.monthly')}
                </Typography>
              )}
              className={classes.gridList}
              control={<Radio className={classes.radio} color="primary" data-testid="ciclyMonthly" />}
            />
          </RadioGroup>
        )}

        <SelectHirePlan
          domainObject={domainObject}
          idHosting={idHosting}
          isHostingSelected={isHostingSelected}
          idProEmail={idProEmail}
          planOptions={filteredPlans}
          paymentCycle={paymentCycle}
          setPaymentCycle={setPaymentCycle}
          handleOpenModalTitanTrialColorful={handleOpenModalTitanTrialColorful}
          enableTitanUltra={enableTitanUltra}
        />

        {enabledCardCPanel && (
          <div className={classes.mainGridActiveCPanel}>
            <div className={classes.gridActiveCPanel}>
              <Typography className={shouldRenderNewTitanCards ? classes.gridActiveCPanelNewTitle : classes.gridActiveCPanelTitle}>
                {t('proEmail.hirePlan.cpanelChangeTitle')}
              </Typography>
              <p className={shouldRenderNewTitanCards ? classes.gridActiveCPanelNewDescription : classes.gridActiveCPanelDescription}>
                <Trans i18nKey="proEmail.hirePlan.cpanelChangeDescription">
                  <>0</>
                  <b>1</b>
                  <>2</>
                </Trans>
              </p>
              {shouldRenderNewTitanCards ? (
                <div className={classnames(classes.activeCPanelButtonWrapper)}>
                  <ButtonV1
                    data-id="cpanelActiveButton"
                    data-testid="cpanelActiveButton"
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleOpen}
                    label={t('proEmail.hirePlan.cpanelEnableChange')}
                  />
                </div>
              ) : (
                <Button
                  data-id="cpanelActiveButton"
                  data-testid="cpanelActiveButton"
                  className={classnames(classes.activeCPanelButton)}
                  variant="contained"
                  color="primary"
                  autoFocus
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleOpen}
                >
                  {t('proEmail.hirePlan.cpanelEnableChange')}
                </Button>
              ) }

            </div>
          </div>
        )}
      </div>
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles)(TitanHirePlan));
