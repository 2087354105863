import React, { useState } from 'react';
import * as Styles from './InBetweenDlojaModal.styles';
import { Modal } from '@/pages/common';
import { Alert, Button, Checkbox } from 'gatorcomponents';
import PropTypes from 'prop-types';
import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';

const InBetweenDlojaModal = ({
  hostingPlatforms,
  handleClose,
  title,
  description,
  alert,
  onContinue,
  checkbox,
  cta,
}) => {
  const [knowTheDifference, setKnowTheDifference] = useState(false);

  const handleClickContinue = () => {
    onContinue && onContinue();
    handleClose();
  };

  return (
    <Modal
      disablePadding
      maxWidth={800}
      onClose={handleClose}
    >
      <Styles.ModalWrapper>
        <Styles.Image
          src={
          hostingPlatforms.find(
            platform => platform.platformType === HOSTING_PLATFORM_TYPES.DLOJA,
          ).imageUrl
        }
        />

        <Styles.Title>
          {title}
        </Styles.Title>

        <Styles.Description>
          {description}
        </Styles.Description>

        <Styles.AlertWrapper>
          <Alert
            variant="warning"
            withoutBackground
            customIcon="IconInfo"
            description={alert}
          />
        </Styles.AlertWrapper>

        <Styles.CheckBoxWrapper>
          <Styles.CheckboxLabel
            htmlFor="#readTerms"
            data-testid="checkReadTerms"
            onClick={() => setKnowTheDifference(!knowTheDifference)}
          >
            <Checkbox checked={knowTheDifference} id="readTerms" />
            <Styles.Text>
              {checkbox}
            </Styles.Text>
          </Styles.CheckboxLabel>
        </Styles.CheckBoxWrapper>

        <Styles.ButtonWrapper>
          <Button
            disabled={!knowTheDifference}
            onClick={handleClickContinue}
            label={cta}
            testId="continue"
          />
        </Styles.ButtonWrapper>
      </Styles.ModalWrapper>
    </Modal>
  );
};

InBetweenDlojaModal.propTypes = {
  handleClose: PropTypes.func,
  hostingPlatforms: PropTypes.array,
};

export default InBetweenDlojaModal;
