import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 900px;
    padding: 52px;
    position: relative;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 24px;
      padding-bottom: 100px;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  overflow: auto;
`;

export const AddLinkButton = styled.div`
  margin-bottom: 48px;
`;

export const SocialLinkButtonWrapper = styled.div`
  margin-bottom: 4px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`;

export const BackButton = styled.div`
  position: absolute;
  bottom: 30px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 7px 0;
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    gap: 8px;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-top: 25px;
    margin-bottom: 18px;
  `}
`;

export const LinkTitleInput = styled.div`
    display: flex;
    align-items: flex-end;
    margin-top: -10px;
    margin-bottom: 20px;
    position: relative;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: -2px;
    margin-bottom: 8px;
  `}
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 16px 16px 16px;
`;

export const ModalButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 24px;
    margin-top: 35px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 80px;

      div {
        margin-bottom: 0px;
      }
    }
  `}
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: -26px;
    margin-bottom: 30px;
  `}
`;

export const ModalTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    margin-top: 0;
    margin-bottom: 3px;
  `}
`;

export const ModalDescription = styled.h4`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 10px;
    margin-top: 0;
    max-width: 500px;
  `}
`;

export const ModalDeleteButtonsWrapper = styled(ModalButtonsWrapper)`
  ${({ theme }) => css`
    margin-top: 0;
    
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 80px;

      div {
        margin-bottom: 0px;
      }
    }
  `}
`;

export const Snappy = styled.img`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: ${theme.breakpoints.sm}) {
      height: 160px;
    }
  `}
`;

export const EmojiButton = styled.button`
  ${({ theme }) => css`
    background: none;
    border: none;
    border-right: 1px solid ${theme.v2.colors.neutral.lowLight};
    cursor: pointer;
    display: flex;
    margin-right: 7px;
    margin-top: 5px;
    padding-right: 8px;
  `}
`;

export const EmojiPicker = styled.div`
  position: absolute;
  z-index: 1;
  top: 66px;
`;

export const SocialMediaList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 18px;
`;

export const SocialMediaDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 18.9px;
    color: ${theme.v2.colors.brand.lowPure};
    margin: 3px 0;
  `}
`;

export const SocialLink = styled.button`
  ${({ theme }) => css`
    align-items: center;
    background: none;
    border-radius: 25px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 8px 16px 8px 8px;
    width: 164px;
  `}
`;
