import React from 'react';
import { withI18n } from 'react-i18next';
import SecurityContextProvider from '@/contexts/Security/context';
import { SecurityHandler } from './SecurityHandler';

const SecurityPageWrapper = () => (
  <div data-testid="security-context">
    <SecurityContextProvider>
      <SecurityHandler />
    </SecurityContextProvider>
  </div>
);

export default withI18n()(SecurityPageWrapper);
