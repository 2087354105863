const styles = theme => ({
  wrapper: {
    position: 'relative',
    minHeight: 'calc(100vh - 80px)',
    padding: '0 24px',
    width: '100%',
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '0px',
    },
  },
  ctaCards: {
    paddingTop: '40px',
    backgroundColor: theme.color.white,
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
  },
  pageTitle: {
    fontSize: '12px 24px',
    fontWeight: 300,
  },
  titleCard: {
    alignItems: 'center',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    display: 'flex',
    marginBottom: 24,
    marginTop: 24,
    minHeight: '60px',
    padding: '7px 24px',
    position: 'relative',
    [theme.breakpoints.up(768)]: {
      boxShadow: 'none',
    },

  },
});

export default styles;
