const styles = theme => ({
  root: {
    fontSize: '14px',
    color: theme.color.regentGray,
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '26px',
    fontWeight: '400',
    lineHeight: '19px',
    padding: '0 20px',
  },
});

export default styles;
