import React, { useState, useEffect } from 'react';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { withI18n, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import SelectField from '@/components/Fields/SelectField';
import { updateShield } from '@/redux/actions/sitelock';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import { SITELOCK_SEAL_FORM_OPTIONS, SITELOCK_PRODUCT_NAME } from '@/config/products/sitelock';
import { SITELOCK_HELP_URL } from '@/config/urls/sitelock';
import styles from './styles';

const ShieldForm = ({
  classes, t, handleOpen, handleCopy, sealUrl,
}) => {
  const { product, shield } = useSelector(state => state.sitelock);
  const [data, setData] = useState({ ...shield });
  const dispatch = useDispatch();
  const params = useParams();
  const {
    formColorOptions, formSizeOptions, formTypeOptions, formLanguageOptions,
  } = SITELOCK_SEAL_FORM_OPTIONS;
  const { SITELOCK_PREMIUM, SITELOCK_ENTERPRISE } = SITELOCK_PRODUCT_NAME;

  useEffect(() => {
    setData(shield);
  }, [shield, setData]);

  const handleColorChange = (e) => {
    setData({ ...data, color: e.target.value });
  };
  const handleSizeChange = (e) => {
    setData({ ...data, size: e.target.value });
  };
  const handleTypeChange = (e) => {
    setData({ ...data, type: e.target.value });
  };
  const handleLanguageChange = (e) => {
    setData({ ...data, language: e.target.value });
  };

  const handleSave = async () => {
    await dispatch(updateShield(params.productType, params.productId, data));
    handleOpen();
  };

  return (
    <div data-testid="seal-form">
      <Typography variant="body1" data-testid="seal-warning" className={classes.description}>{t('sitelock.SealWarning')}</Typography>
      <div className={classes.formWrapper}>
        <SelectField
          id="color"
          className={classes.selectFieldCustom}
          inputLabel={t('sitelock.shield.color')}
          labelWidth={(calcLabelWidth(t('sitelock.shield.color')))}
          filterOptions={formColorOptions}
          selectValue={data.color}
          selectType="sitelockshield"
          menuItemDefaultText="hidden"
          handleChangeSelectValue={handleColorChange}
          fullWidth
          required
          disabled={false}
          data-testid="seal-input-color"
        />
        <SelectField
          id="size"
          className={classes.selectFieldCustom}
          inputLabel={t('sitelock.shield.size')}
          labelWidth={(calcLabelWidth(t('sitelock.shield.size')))}
          filterOptions={formSizeOptions}
          selectValue={data.size}
          selectType="sitelockshield"
          menuItemDefaultText="hidden"
          handleChangeSelectValue={handleSizeChange}
          fullWidth
          required
          disabled={false}
          data-testid="seal-input-size"
        />
        {(product === SITELOCK_PREMIUM || product === SITELOCK_ENTERPRISE) && (
        <SelectField
          id="type"
          className={classes.selectFieldCustom}
          inputLabel={t('sitelock.shield.type')}
          labelWidth={(calcLabelWidth(t('sitelock.shield.type')))}
          filterOptions={formTypeOptions}
          selectValue={data.type}
          selectType="sitelockshield"
          menuItemDefaultText="hidden"
          handleChangeSelectValue={handleTypeChange}
          fullWidth
          required
          disabled={false}
          data-testid="seal-input-type"
        />
        )}
        <SelectField
          id="type"
          className={classes.selectFieldCustom}
          inputLabel={t('sitelock.shield.language')}
          labelWidth={(calcLabelWidth(t('sitelock.shield.language')))}
          filterOptions={formLanguageOptions}
          selectValue={data.language}
          selectType="sitelockshield"
          menuItemDefaultText="hidden"
          handleChangeSelectValue={handleLanguageChange}
          fullWidth
          required
          disabled={false}
          data-testid="seal-input-language"
        />
        <Typography variant="h5" className={classes.codeHeader}>{t('sitelock.formCode')}</Typography>
        <div data-testid="seal-code" className={classes.codeTextArea}>
          {sealUrl}
        </div>
      </div>
      <div className={classes.copyShield}>
        <PrimaryButton role="button" className={classes.button} onClick={handleCopy}>{t('sitelock.SealCopyLongBtn')}</PrimaryButton>
        <div className={classes.sealHelp}>
          <Trans i18nKey="sitelock.SealHelp" helpUrl={SITELOCK_HELP_URL}>
            <a target="_blank" rel="noopener noreferrer" href={SITELOCK_HELP_URL}>
              {SITELOCK_HELP_URL}
            </a>
          </Trans>
        </div>
      </div>
      <div className={classes.buttons}>
        <PrimaryButton
          role="button"
          className={classes.button}
          onClick={handleSave}
        >
          {t('sitelock.SealSaveBtn')}
        </PrimaryButton>
        <PrimaryButton data-testid="seal-btn-close" role="button" onClick={handleOpen} className={classnames([classes.button, classes.outlinedButton])}>{t('sitelock.SealCancelBtn')}</PrimaryButton>
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(ShieldForm));
