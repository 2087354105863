export const DOMAIN_STATUS = {
  PENDING: 'pending',
  EXPIRED: 'expired',
  OVERDUE_DATE: 'overDueDate',
  NEXT_DUE_DATE: 'nextDueDate',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  PENDING_TRANSFER: 'pendingTransfer',
  REGISTER_ERROR: 'registerError',
};

export const domainsChips = [
  {
    id: '0',
    value: 'all',
    checked: true,
  },
  {
    id: '1',
    value: 'billingPending',
    checked: false,
  },
  {
    id: '2',
    value: 'registerFail',
    checked: false,
  },
];
