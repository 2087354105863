import React, { Component } from 'react';
import { NavLink, Link as ReactLink } from 'react-router-dom';


class LinkButton extends Component {
  parseTo = (to) => {
    const parser = document.createElement('a');
    parser.href = to;
    return parser;
  };

  isInternal = target => window.location.host === target.host;

  render() {
    const {
      to,
      target,
      dataId,
      children,
      className,
      activeClassName,
      ...rest
    } = this.props;
    const toLocation = this.parseTo(to);

    if (this.isInternal(toLocation)) {
      return activeClassName ? (
        <NavLink
          to={toLocation.pathname}
          activeClassName={activeClassName}
          className={className}
          {...rest}
        >
          {children}
        </NavLink>
      ) : (
        <ReactLink
          to={toLocation.pathname}
          className={className}
          {...rest}
        >
          {children}
        </ReactLink>
      );
    }

    return (
      <a
        data-id={dataId}
        href={to}
        target={target}
        rel="noopener noreferrer"
        className={className}
        {...rest}
      >
        {children}
      </a>
    );
  }
}

export default LinkButton;
