import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import ActionButton from '@/components/Buttons/ActionButton/index';
import styles from './styles';


const NewSiteThemeCards = ({
  classes,
  themes,
  t,
  onOpenPreview,
  onSelectTemplate,
  selectedTemplateId,
  onLoadMore,
}) => (
  <div className={classes.wrapperThemesCard}>
    {themes.slice(0, onLoadMore).map((template) => {
      if (template) {
        return (
          <div
            key={template.id}
            className={`${classes.contentCard} ${selectedTemplateId === template.id && classes.selected}`}
            style={{ backgroundImage: `url(${template.thumbnailUrl})`, backgroundSize: 'cover', backgroundColor: '#cccccc' }}
          >
            <div className={`${classes.contentThemeName} ${selectedTemplateId === template.id && classes.contentThemeNameSelected}`}>
              <Typography className={classes.titleTheme}>{template.name}</Typography>
            </div>
            <div className={`${classes.contentTheme} ${selectedTemplateId === template.id && classes.contentThemeSelected}`}>
              <ActionButton
                className={`${classes.buttonCustom} ${classes.buttonLeftBorderCustom} ${selectedTemplateId === template.id ? classes.borderButtomLeftSeleted : classes.borderButtomLeft}`}
                action={() => onOpenPreview(template.id)}
              >
                {t('newsite.preview')}
              </ActionButton>
              <ActionButton
                className={`${classes.buttonCustom} ${classes.buttonRightBorderCustom} ${selectedTemplateId === template.id ? classes.borderButtomRightSelected : classes.borderButtomRight}`}
                action={() => onSelectTemplate(template.id)}
              >
                {t('newsite.useTheme')}
              </ActionButton>
            </div>
          </div>
        );
      }
      return null;
    })}
  </div>
);

const matStateToProps = state => ({
  selectedTemplateId: state.onboarding.selectedTemplateId,
});

export default withI18n()(withStyles(styles)(connect(matStateToProps)(NewSiteThemeCards)));
