import React, { useEffect } from 'react';
import * as Styles from './WithRefund.styles';
import { RefundValueBlock, RefundTipBlock } from '../index';
import { RefundMethodsBlock } from '../RefundMethodsBlock';
import { REFUND_PAYMENT_METHODS } from '@/enums/billing/billing.enum';
import { billingAnalytics } from '@/analytics';

const WithRefund = ({
  totalLabel = 'Total to refund',
  refundPolitics = 'Read our refund politics',
  onRefundPoliticsClick,
  tipTitle = 'Tip:',
  tip = 'For this amount, it is more advantageous to use the subscription until the end of the cycle, on 09/01/2022.',
  stopCancelLabel = 'Give up Cancellation',
  handleStopCancel,
  title = 'See the refund possibilities available for your subscription:',
  testId = 'refund',
  selectMethodTitle = 'Select a refund mode:',
  methodOptions,
  onChangeSelected,
  refund = null,
}) => {
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('4_visualizar_reembolso_cancelar_imediato');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title>
        {title}
      </Styles.Title>

      <Styles.Row id="row">
        <RefundValueBlock
          testId="refund-value-block"
          totalLabel={totalLabel}
          refundPolitics={refundPolitics}
          amount={refund ? refund.total : undefined}
          onRefundPoliticsClick={onRefundPoliticsClick}
          loading={!refund}
        />
        <RefundTipBlock
          display={refund ? refund.showHint : undefined}
          testId="refund-tip-block"
          tipTitle={tipTitle}
          tip={tip}
          stopCancelLabel={stopCancelLabel}
          handleStopCancel={handleStopCancel}
        />
      </Styles.Row>

      <RefundMethodsBlock
        testId="refund-methods-block"
        title={selectMethodTitle}
        methods={refund.methods || [REFUND_PAYMENT_METHODS.HG_CREDITS]}
        methodOptions={methodOptions}
        selected={refund.methodSelected}
        onChangeSelected={onChangeSelected}
        loading={!refund}
      />
    </Styles.Wrapper>

  );
};

export default WithRefund;
