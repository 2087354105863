import { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withI18n } from 'react-i18next';

import { removeSnackbar } from '@/redux/actions/notifications';


class Notifier extends Component {
    displayed = [];

    shouldComponentUpdate({ notifications: newSnacks = [] }) {
      const { notifications: currentSnacks } = this.props;
      let notExists = false;
      for (let i = 0; i < newSnacks.length; i += 1) {
        if (!notExists) {
          notExists = notExists || !currentSnacks.filter(({ options }) => newSnacks[i].options.key === options.key).length;
        }
      }
      return notExists;
    }

    componentDidUpdate() {
      const { t, notifications = [] } = this.props;

      notifications.forEach((notification) => {
        const { enqueueSnackbar, onRemoveSnackbar } = this.props;
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(notification.options.key)) return;
        // Display snackbar using notistack
        enqueueSnackbar(t(notification.message, notification.messageValues), notification.options);
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(notification.options.key);
        // Dispatch action to remove snackbar from redux store
        onRemoveSnackbar(notification.options.key);
      });
    }

    storeDisplayed = (id) => {
      this.displayed = [...this.displayed, id];
    }

    render() {
      return null;
    }
}

const mapStateToProps = store => ({
  notifications: store.notifications,
});

const mapDispatchToProps = dispatch => ({
  onRemoveSnackbar: key => dispatch(removeSnackbar(key)),
});

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier)));
