import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.nav`
  ${({ theme, open }) => css`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: visibility 200ms ease-in-out;
    visibility: ${open ? 'visible' : 'hidden'};
    width: 100%;
    z-index: ${theme.layers.important};

    @media (min-width: ${theme.v1.breakpoints.md}){
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      height: 100%;
      outline: none;
      overflow-y: auto;
      position: fixed;
      top: 0;
      width: 200px;
      z-index: 1200;
    }
  `}
`;

export const Overlay = styled.div`
  ${({ theme, open }) => css`
    background-color: ${theme.shadows.grey.darkest};
    height: 100%;
    left: 0;
    opacity: ${open ? 1 : 0};
    position: absolute;
    top: 0;
    transition: opacity 200ms ease-in-out;
    width: 100%;
    z-index: ${theme.layers.base};

    @media (min-width: ${theme.v1.breakpoints.md}){
      display: none;
      visibility: hidden;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme, open }) => css`
    background-color: ${theme.v2.colors.brand.menuPrimaryDark};
    box-shadow: 1px 0 3px ${theme.shadows.grey.light};
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(${open ? '0' : '-100%'});
    transition: transform 200ms ease-in-out;
    width: 100vw;
    z-index: ${theme.layers.header};

    @media (min-width: ${theme.v1.breakpoints.md}){
      max-width: 100%;
      width: 100%;
    }
  `}
`;

export const LogoButton = styled.button`
  ${({ theme }) => css`
    background: none;
    border: 0 none;
    display: flex;
    justify-content: flex-start;
    padding: 32px;
    width: 100%;

    svg {
      height: auto;
      max-width: 141px;
      width: 100%;
    }

    @media (min-width: ${theme.v1.breakpoints.md}){
      justify-content: 'flex-start';
      padding: 32px;
    }
  `}
`;

export const MyAccount = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.v1.breakpoints.md}){
      max-width: 100%;
      width: 100%;
    }

    @media (min-width: ${theme.v1.breakpoints.md}){
      display: none;
    }
  `}
`;

export const ListWrapper = styled.div`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: ${theme.breakpoints.md}) {
      li:nth-child(9) { 
        margin-bottom: 24px; 
      } 

      li:last-child {
        margin-bottom 24px;
      }

      > li:last-child {
        margin-bottom 48px;
      }
    }
  `}
`;

export const Item = styled.li`
  ${({ theme, showInDevice }) => css`
    display: ${['mobile', 'both'].includes(showInDevice) ? 'block' : 'none'};
    margin-top: 0px;
    position: relative;

    &:before {
      border-top: 2px dotted ${theme.palette.primary.main};
      content: '';
      visibility: hidden;
      left: 30px;
      position: absolute;
      top: -2px;
      width: calc(100% - 60px);
    }

    &:last-child {
      margin-top: auto;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      display: ${['desktop', 'both'].includes(showInDevice) ? 'block' : 'none'};
    }
  `}
`;

export const ButtonIcon = styled.span`
  display: flex;

  svg {
    transition: fill 200ms ease-in-out
  }
`;

export const ButtonText = styled.span`
  ${({ theme, variant }) => {
    const variants = {
      v1: {
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: theme.font.weights.regular,
        marginLeft: '15px',
        lineHeight: 'inherit',
      },
      v2: {
        fontFamily: theme.v2.font.family.primary,
        fontSize: theme.v2.font.sizes.xxs,
        fontWeight: theme.v2.font.weights.medium,
        marginLeft: '10px',
        lineHeight: theme.v2.font.lineHeight.text,
      },
    };

    return css`
    font-family: ${variants[variant].fontFamily};
    font-size:  ${variants[variant].fontSize};
    font-weight: ${variants[variant].fontWeight};
    line-height: ${variants[variant].lineHeight};
    margin-left: ${variants[variant].marginLeft};
    text-align: left;
    transition: color 200ms ease-in-out;
  `;
  }}
`;

export const DropdownIcon = styled.span`
  display: flex;
  margin-left: 2px;
`;

export const Button = styled.button`${(
  {
    theme, active, open, variant, insideDropdown,
  },
) => {
  const variants = {
    v1: {
      bgColor: active ? theme.palette.secondary.darkest : 'transparent',
      padding: '15px 24px',
      visibility: 'hidden',
      buttonColor: (active || open) ? theme.palette.white.light : theme.palette.white.opacity,
      buttonColorHover: (active || open) ? theme.palette.white.light : theme.palette.white.opacity,
      fontFamily: 'inherit',
    },
    v2: {
      bgColor: theme.v2.colors.brand.menuPrimaryDark,
      padding: insideDropdown ? '17px 0px' : '16px 18px 16px 16px',
      visibility: 'visible',
      buttonColor: (active || open) ? `${theme.v2.colors.brand.primaryLight} !important` : theme.v2.colors.feedback.canceledPure,
      buttonColorHover: `${theme.v2.colors.brand.primaryLight} !important`,
      fontFamily: theme.v2.font.family.primary,
    },
  };

  return css`
    align-items: center;
    background-color: ${variants[variant].bgColor};
    border: 0 none;
    display: flex;
    padding: ${variants[variant].padding};
    position: relative;
    transition: background-color 200ms ease-in-out;
    width: 100%;

    &:before{
      width: 4px;
      background-color:${active ? theme.v2.colors.brand.primaryLight : 'transparent'};
      left: 0px;
      top: 0px;
      position: absolute;
      height: 100%;
      content: ' ';
      border-radius: 0px 50px 50px 0px;
      visibility: ${active ? variants[variant].visibility : 'hidden'};
    }

    &:hover {
      cursor: pointer;

      & ${ButtonText} {
        color: ${variants[variant].buttonColorHover};
      }

      & ${ButtonIcon} svg, svg {
        fill: ${variants[variant].buttonColorHover};
      }
    }

    & ${ButtonText} {
      color: ${variants[variant].buttonColor};
    }

    & ${ButtonIcon} svg, svg {
      fill: ${variants[variant].buttonColor};
    }

    & ${DropdownIcon} {
      transform: rotate(${open ? '0deg' : '180deg'});
      transition: transform 200ms ease-in-out;

      svg {
        fill: ${variants[variant].buttonColor}
      }
    }
  `;
}}
`;

const tagShake = () => keyframes`
  0% { transform: rotate(0deg); }
  5% { transform: rotate(5deg); }
  10% { transform: rotate(0deg); }
  15% { transform: rotate(-5deg); }
  20% { transform: rotate(0deg); }
`;

export const Tag = styled.span`
  ${({
    theme, tagInText, variant, shake,
  }) => {
    const variants = {
      v1: {
        margin: '0px',
        minHeight: 'unset',
      },
      v2: {
        margin: '8px 0px 0px',
        minHeight: '24px',
      },
    };

    return css`
    -webkit-animation: ${shake && tagShake} 2s infinite linear;
    animation: ${shake && tagShake} 2s infinite linear;
    animation-iteration-count: infinite;
    bottom: 15px;
    display: flex;
    margin: ${variants[variant].margin};
    min-height: ${variants[variant].minHeight};
    position: ${tagInText ? 'inherit' : 'absolute'};
    right: 18px;
    padding: 4px 16px;
    border-radius: 50vh;
    background-color: ${theme.v2.colors.feedback.attentionLight};
    color: ${theme.v2.colors.brand.primaryExtraDark};
  `;
  }}
`;

export const Dropdown = styled.ul`
  ${({ open }) => css`
    max-height: ${open ? '305px' : 0};
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: 200ms ${open ? 'ease-in' : 'ease-out'};

    & ${Button} {
      padding: 8px 17px;
    }
  `}
`;

export const DropdownItem = styled.li`
  ${({ theme, active }) => css`

    & ${ButtonIcon} svg {
      fill: ${active ? theme.palette.white.light : theme.palette.white.opacity};
    }

    & ${ButtonText} {
      color: ${active ? theme.palette.white.light : theme.palette.white.opacity};
    }

    &:last-child ${Button} {
      padding-bottom: 15px;
    }
  `}
`;

export const CloseWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: absolute;
    right: 16px;
    top: 16px;

    @media (min-width: ${theme.v1.breakpoints.md}){
      visibility: hidden;
      display: none;
    }
  `}
`;
