import { PRODUCT_DETAILS } from '@/config/whmcsUrls';

export const brokeDomain = (domain) => {
  if (domain.indexOf('.') > 22) {
    const split = domain.split('.');
    const second = split[2] ? `.${split[2]}` : '';
    const dotDomain = `${split[0].substring(0, 22)}...[${split[1]}${second}]`;
    return dotDomain;
  }
  return domain;
};

export const brokenEmailAddress = (email, maxCharacters) => {
  const emailLength = email.length;
  if (maxCharacters <= 3 || emailLength <= 3) {
    throw new Error('maxCharacters needs a value greater than 3 and email needs a string greater than 3 characters');
  }
  let dotEmail = email;
  if (emailLength > maxCharacters) {
    dotEmail = `${email.substring(0, (maxCharacters - 3))}...`;
  }
  return dotEmail;
};

export const makeSealUrl = domain => `<a href="#" onclick="window.open('https://www.sitelock.com/verify.php?site=${domain}','SiteLock','width=600,height=600,left=160,top=170);" ><img class="img-responsive" alt="SiteLock" title="SiteLock" src="//shield.sitelock.com/shield/${domain}" /></a>`;

export const makeParamsUrl = (url, param1, param2) => `${url}${param1}/${param2}`;

export const makeCodeguardUrl = codeguardId => `${PRODUCT_DETAILS}&id=${codeguardId}`;
