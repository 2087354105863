const styles = theme => ({


  icon: {
    color: theme.color.indigo,
  },

  paperProps: {
    marginTop: '50px',
  },

  selectCustom: {
    height: '40px',
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 8,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '2px',
      borderTopRightRadius: '2px',
    },
  },
  selectCustomInput: {},
  selectCustomOutline: {},
  selectCustomFocused: {},

  link: {
    color: theme.color.indigo,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.01px',
    opacity: 1,
    textAlign: 'center',
    textDecoration: 'underline',
    [theme.breakpoints.down(700)]: {
      marginTop: '16px',
    },
  },
});

export default styles;
