import {
  format, parseISO, differenceInHours,
} from 'date-fns';
import shortid from 'shortid';
import { CALL_API } from '@/middleware/api';
import {
  PRODUCT_DETAIL_URL, PRODUCT_REBOOT_HISTORY_URL, PRODUCT_REBOOT_URL, PRODUCT_HEALTHCHECK_URL,
  PRODUCT_SAVE_PASSWORD_URL, PANEL_WHM_URL, HOSTINGS_ADDONS,
  ADDON_ID_FROM_SITE_BUILDER_ADDON,
} from '@/config/api';
import { COUNTRY } from '@/config';
import { loadGeneralProducts } from '@/redux/actions/generalProducts';
import { AVAILABLE_ACTIVATE_SITEBUILDER_PRODUCTS } from '@/config/products/availability';
import {
  RECEIVE_PRODUCT_DETAIL, REQUEST_PRODUCT_DETAIL, ERROR_PRODUCT_DETAIL, REMOVE_PRODUCT_DETAIL,
  REQUEST_PRODUCT_REBOOT_HISTORY, RECEIVE_PRODUCT_REBOOT_HISTORY, ERROR_PRODUCT_REBOOT_HISTORY,
  ERROR_PRODUCT_REBOOT, REQUEST_PRODUCT_REBOOT, RECEIVE_PRODUCT_REBOOT, REQUEST_PRODUCT_HEALTHCHECK,
  RECEIVE_PRODUCT_HEALTHCHECK, ERROR_PRODUCT_HEALTHCHECK, SET_REBOOT_AVAILABILITY,
  TOGGLE_ACCESS_PASSWORD_MODAL, REQUEST_PRODUCT_SAVE_PASSWORD, ERROR_PRODUCT_SAVE_PASSWORD,
  RECEIVE_PRODUCT_SAVE_PASSWORD, REQUEST_WHM_PANEL_ACCESS, RECEIVE_WHM_PANEL_ACCESS,
  ERROR_WHM_PANEL_ACCESS, ERROR_HEALTHCHECK_WHM_PANEL_ACCESS, REQUEST_PRODUCT_ADDONS,
  ERROR_PRODUCT_ADDONS, RECEIVE_PRODUCT_DETAIL_ADDONS, REQUEST_ADDON_PARENT_PRODUCT_DETAIL,
  ERROR_ADDON_PARENT_PRODUCT_DETAIL, REQUEST_ADDON_ID_FROM_SITE_BUILDER,
  RECEIVE_ADDON_ID_FROM_SITE_BUILDER, ERROR_ADDON_ID_FROM_SITE_BUILDER,
  REQUEST_SITEBUILDER_PRODUCT_DETAIL, RECEIVE_SITEBUILDER_PRODUCT_DETAIL, ERROR_SITEBUILDER_PRODUCT_DETAIL,
  UPDATE_ACTIONS_IN_PRODUCT_DETAILS,
} from './actionsTypes';


const sanitizeAddonProductDetail = ({ addonParentDetail, productDetail }) => {
  const {
    ns1,
    ns2,
    serverName,
    assignedips,
  } = addonParentDetail;

  const addonParentProductDetail = {
    ...productDetail,
    ns1,
    ns2,
    serverName,
    assignedips,
    showBillingCycle: false,
  };

  return addonParentProductDetail;
};

const receiveAddonParentProductDetail = ({ addons, sanitizeData }) => ({
  type: RECEIVE_PRODUCT_DETAIL_ADDONS,
  addons,
  productDetail: sanitizeAddonProductDetail(sanitizeData),
});

const errorAddonParentProductDetail = ({ addons, sanitizeData }) => ({
  type: ERROR_ADDON_PARENT_PRODUCT_DETAIL,
  addons,
  productDetail: sanitizeAddonProductDetail(sanitizeData),
});

const loadAddonParentProductDetail = ({ hostingId, addons, productDetail }) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_DETAIL_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ADDON_PARENT_PRODUCT_DETAIL }),
      success: ({ data }) => receiveAddonParentProductDetail({
        addons,
        sanitizeData: {
          productDetail,
          addonParentDetail: data,
        },
      }),
      error: () => errorAddonParentProductDetail({ addons, productDetail }),
    },
  },
});

const receiveProductAddons = (data, productDetail) => (dispatch, getState) => {
  if (!productDetail) {
    return dispatch({
      type: RECEIVE_PRODUCT_DETAIL,
      productDetail: { addons: data.addons },
    });
  }

  const { generalProducts } = getState();
  const [addonParentProduct] = generalProducts.bkpProducts.filter(item => (
    item.domain === data.domain
    && item.type !== 'websitebuilder'
    && AVAILABLE_ACTIVATE_SITEBUILDER_PRODUCTS.includes(item.type)
  ));

  if (!addonParentProduct && productDetail.productType === 'addon') {
    const [addonParentProductAditionalDomain] = generalProducts.bkpProducts.filter(item => (
      item.id === productDetail.parentId && item.type !== 'websitebuilder'
    ));

    const newProductDetail = {
      ...productDetail,
      aditionalDomain: true,
    };

    return dispatch(loadAddonParentProductDetail({
      hostingId: addonParentProductAditionalDomain.id,
      addons: data.addons,
      productDetail: newProductDetail,
    }));
  }

  if (addonParentProduct) {
    return dispatch(loadAddonParentProductDetail({
      hostingId: addonParentProduct.id,
      addons: data.addons,
      productDetail,
    }));
  }

  const handleProductDetail = {
    ...productDetail,
    showBillingCycle: true,
  };

  return dispatch({
    type: RECEIVE_PRODUCT_DETAIL,
    productDetail: handleProductDetail,
  });
};

export const getProductAddons = (hostingId, productDetail) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: HOSTINGS_ADDONS(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_ADDONS }),
      success: ({ data }) => receiveProductAddons(data, productDetail),
      error: () => ({ type: ERROR_PRODUCT_ADDONS }),
    },
  },
});

export const removeProductDetail = () => ({
  type: REMOVE_PRODUCT_DETAIL,
});

const addSitebuilderActionsOnSharedAndResellerPlans = (product) => {
  if (
    ('type' in product)
    && AVAILABLE_ACTIVATE_SITEBUILDER_PRODUCTS.includes(product.type)
    && product.hasSiteBuilderActive
  ) {
    const randomId = shortid.generate();

    product.actions.unshift({
      relid: randomId,
      status: 'active',
      type: 'websitebuilder-on-shared-plans',
      uuid: `websitebuilder-on-shared-plans-${randomId}`,
    });
  }

  return product;
};

const receiveProductDetail = productDetail => (dispatch, getState) => {
  let handleProductDetail = productDetail;
  handleProductDetail = addSitebuilderActionsOnSharedAndResellerPlans(handleProductDetail);

  if (
    handleProductDetail
    && ('type' in handleProductDetail)
    && handleProductDetail.type === 'websitebuilder'
    && ('productType' in handleProductDetail)
    && handleProductDetail.productType === 'addon'
  ) {
    const { generalProducts } = getState();

    if (generalProducts.bkpProducts.length === 0) {
      return dispatch(loadGeneralProducts()).then(() => dispatch(getProductAddons(handleProductDetail.id, handleProductDetail)));
    }

    return dispatch(getProductAddons(handleProductDetail.id, handleProductDetail));
  }

  return dispatch({
    type: RECEIVE_PRODUCT_DETAIL,
    productDetail,
  });
};

export const loadProductDetail = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_DETAIL_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_DETAIL }),
      success: ({ data }) => receiveProductDetail(data),
      error: () => ({ type: ERROR_PRODUCT_DETAIL }),
    },
  },
});

const receiveProductRebootHistory = (history) => {
  const newHistory = Object.values(history.reduce((prev, { datetime }) => {
    const newObj = prev;
    const date = format(parseISO(datetime), 'dd/MM/yyyy');
    const time = format(parseISO(datetime), 'HH\'h\'mm');
    if (!newObj[date]) {
      newObj[date] = {
        id: shortid.generate(),
        date,
        time: [time],
      };
    } else {
      newObj[date].time.push(time);
    }
    return newObj;
  }, {}));

  return {
    type: RECEIVE_PRODUCT_REBOOT_HISTORY,
    history: newHistory,
    historyBkp: history,
  };
};

const checkRebootAvailability = (history) => {
  const now = new Date();
  const canReboot = !(history.length === 3 && history.every(item => differenceInHours(now, parseISO(item.datetime)) < 1));

  return {
    type: SET_REBOOT_AVAILABILITY,
    canReboot,
  };
};

export const loadRebootHistory = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_REBOOT_HISTORY_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_REBOOT_HISTORY }),
      success: response => (dispatch) => {
        dispatch(receiveProductRebootHistory(response.data));
        dispatch(checkRebootAvailability(response.data));
      },
      error: () => ({ type: ERROR_PRODUCT_REBOOT_HISTORY }),
    },
  },
});

export const reboot = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_REBOOT_URL(hostingId),
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_REBOOT }),
      success: () => ({ type: RECEIVE_PRODUCT_REBOOT }),
      error: () => ({ type: ERROR_PRODUCT_REBOOT }),
    },
  },
});

export const toggleAccessPasswordModal = () => ({
  type: TOGGLE_ACCESS_PASSWORD_MODAL,
});

const receiveProductHealthcheck = data => ({
  type: RECEIVE_PRODUCT_HEALTHCHECK,
  healthcheck: data,
});

export const healthcheck = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_HEALTHCHECK_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_HEALTHCHECK }),
      success: response => receiveProductHealthcheck(response.data),
      error: () => ({ type: ERROR_PRODUCT_HEALTHCHECK }),
    },
  },
});

export const savePassword = (hostingId, data) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_SAVE_PASSWORD_URL(hostingId),
    method: 'PUT',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_PRODUCT_SAVE_PASSWORD }),
      success: () => ({ type: RECEIVE_PRODUCT_SAVE_PASSWORD }),
      error: () => ({ type: ERROR_PRODUCT_SAVE_PASSWORD }),
    },
  },
});


const receiveWHMPanelAccess = data => ({
  type: RECEIVE_WHM_PANEL_ACCESS,
  data,
});

const errorWHMPanelAccess = (healthcheck) => {
  const [vpsIP] = healthcheck.ips;

  return vpsIP ? ({
    type: ERROR_HEALTHCHECK_WHM_PANEL_ACCESS,
    data: `http://${vpsIP}:2086`,
  }) : ({
    type: ERROR_WHM_PANEL_ACCESS,
  });
};

export const getWHMPanelAccess = () => (dispatch, getState) => {
  const { healthcheck } = getState().productDetail;
  const { id: clientId } = getState().summary;
  const isGetAccess = healthcheck && ('id' in healthcheck);

  if (isGetAccess) {
    return dispatch({
      [CALL_API]: {
        authenticated: true,
        authorizationType: 'jwt',
        endpoint: PANEL_WHM_URL(healthcheck.id),
        method: 'GET',
        customHeaders: {
          'X-Brand': `${COUNTRY.toLowerCase()}`,
          'X-Client-ID': clientId,
        },
        actionTypes: {
          request: () => ({ type: REQUEST_WHM_PANEL_ACCESS }),
          success: response => receiveWHMPanelAccess(response.data),
          error: () => errorWHMPanelAccess(healthcheck),
        },
      },
    });
  }

  return new Promise((resolve) => {
    resolve(dispatch(errorWHMPanelAccess(healthcheck)));
  });
};

export const getAddonIdFromSiteBuilderAddon = ({ hostingId, domain }) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ADDON_ID_FROM_SITE_BUILDER_ADDON(hostingId, domain),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_ADDON_ID_FROM_SITE_BUILDER }),
      success: ({ data }) => ({ type: RECEIVE_ADDON_ID_FROM_SITE_BUILDER, data }),
      error: () => ({ type: ERROR_ADDON_ID_FROM_SITE_BUILDER }),
    },
  },
});

export const updateOptionsProductDetail = actions => ({
  type: UPDATE_ACTIONS_IN_PRODUCT_DETAILS,
  data: actions,
});

export const loadProductDetailSitebuilder = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PRODUCT_DETAIL_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITEBUILDER_PRODUCT_DETAIL }),
      success: ({ data }) => ({
        type: RECEIVE_SITEBUILDER_PRODUCT_DETAIL,
        productDetail: addSitebuilderActionsOnSharedAndResellerPlans(data),
      }),
      error: () => ({ type: ERROR_SITEBUILDER_PRODUCT_DETAIL }),
    },
  },
});
