/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, Typography, Collapse, IconButton,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import { filterProductGroups } from '@/redux/actions/productGroups';
import { filterGeneralProducts, productStatus } from '@/redux/actions/generalProducts';
import SelectField from '@/components/Fields/SelectField';
import SelectCheckboxField from '@/components/Fields/SelectCheckboxField';
import FilterButton from '@/components/Buttons/FilterButton';
import { calcLabelWidth } from '@/utils/Formatters/calcWidthLabel';
import { config } from '@/config';
import styles from './styles';


const FilterProducts = ({
  t,
  total,
  classes,
}) => {
  const selectedStatus = useSelector(state => state.generalProducts.selectFilters.selectedStatus);
  const filterProducts = useSelector(state => state.generalProducts.selectFilters.site);
  const filterStatus = useSelector(state => state.generalProducts.selectFilters.status);

  const [expand, setExpand] = useState(false);
  const [product, setProduct] = useState('');

  const isMobile = useMediaQuery('(max-width:1280px)');
  const { SITE_HG } = config;

  const dispatch = useDispatch();

  const handleCollapse = () => {
    isMobile ? setExpand(false) : setExpand(true);
  };

  useEffect(() => {
    handleCollapse();
  }, []);

  useEffect(() => {
    dispatch(filterProductGroups(product, selectedStatus));
    dispatch(filterGeneralProducts(product, selectedStatus));
  }, [product]);

  useEffect(() => {
    dispatch(filterGeneralProducts(product, selectedStatus));
  }, [selectedStatus]);

  const handleExpandCollapse = () => {
    setExpand(!expand);
  };

  const handleChange = (event) => {
    setProduct(event.target.value);
  };

  const handleSelect = (event) => {
    dispatch(productStatus(event.target.value));
  };

  return (
    <Card className={classes.card} data-id="page-filter-card">
      {!isMobile && (
        <div className={classes.startContentWrapper}>
          <Typography variant="h6" className={classes.listTitle} data-id="products-page-title">{t('lists.products')}</Typography>
          <FilterButton target={SITE_HG}>
            {t('hireProduct')}
          </FilterButton>
        </div>
      )}
      <div className={classes.filterContainer}>
        {total > 4 && (
          <>
            <Typography variant="h6" className={classes.filterTitle}>{t('filter.title')}</Typography>
            <Collapse in={expand || !isMobile} className={classes.collapseWrapper}>
              <div className={classes.root}>
                <SelectField
                  className={classes.selectField}
                  filterOptionsTypeOf="array"
                  filterOptions={filterProducts}
                  selectType="product"
                  selectValue={product}
                  inputLabel={t('filter.product')}
                  menuItemDefaultText={t('filter.allProducts')}
                  labelWidth={calcLabelWidth(t('filter.product'))}
                  handleChangeSelectValue={event => handleChange(event)}
                />
                <SelectCheckboxField
                  totalProducts={total}
                  className={classes.selectCheckbox}
                  filterStatus={filterStatus}
                  selectType="status"
                  menuItemDefaultText={t('filter.allStatus')}
                  inputLabel={t('filter.status')}
                  selectValue={selectedStatus}
                  handleChangeSelectCheckbox={event => handleSelect(event)}
                  labelWidth={calcLabelWidth(t('filter.status'))}
                />
              </div>
            </Collapse>

            {isMobile && (
              <div className={classes.expandArrow}>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expand,
                  })}
                  onClick={() => handleExpandCollapse()}
                >
                  <ExpandMore className={classes.arrow} />
                </IconButton>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default withWidth()(withStyles(styles, { withTheme: true })(withI18n()(FilterProducts)));
