import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/Button';
import * as Style from './UpgradePlanModal.styles';
import { upgradePlanModalLocale } from './UpgradePlanModal.locale';
import iconHospedagem from '@/media/siteBuilder/icon-hospedagem.svg';
import { WHMCS_URL } from '@/config';

const UpgradePlanModal = ({
  onClose,
  plans,
  ellipsis = '...',
  buttonClick,
}) => {
  const [currentPlanId, setCurrentPlanId] = useState(undefined);
  const handleOnClose = () => {
    onClose && onClose(true);
  };

  const handleSubmitProduct = (productId) => {
    setCurrentPlanId(productId);
  };

  const handleOnClick = (event) => {
    buttonClick();
    event.preventDefault();
    window.location.href = `${WHMCS_URL}/index.php?m=custom_upgrade&action=selectupgrade&id=${currentPlanId}`;
  };

  return (
    <Modal onClose={handleOnClose} maxWidth={786}>
      <Style.Wrapper data-testid="upgrade-plan-modal">
        <Style.Title>
          {upgradePlanModalLocale.title}
        </Style.Title>
        <Style.SubTitle>
          {upgradePlanModalLocale.subTitle}
        </Style.SubTitle>
        <Style.Cards>
          {plans && plans.map(item => (
            <Style.Card
              selected={currentPlanId === item.external_id}
              onClick={
                () => { handleSubmitProduct(item.external_id); }}
              key={item.external_id}
            >
              <Style.CardIcon src={iconHospedagem} />
              <Style.CardTitle>
                {item.name}
              </Style.CardTitle>
              <Style.CardSubTitle>
                <Style.DomainDirector>{upgradePlanModalLocale.domainDirector}</Style.DomainDirector>
                {item.domain.substring(0, 10)}
                {ellipsis}
                {item.domain.substring(item.domain.indexOf('.'))}
              </Style.CardSubTitle>
            </Style.Card>
          ))}

        </Style.Cards>
        <Style.Action>
          <Style.Button>
            <Button
              disabled={currentPlanId === undefined}
              variant="newPrimary"
              font="secondary"
              size="large"
              label={upgradePlanModalLocale.buttonLabel}
              onClick={handleOnClick}
            />
          </Style.Button>
        </Style.Action>
      </Style.Wrapper>
    </Modal>
  );
};

export default UpgradePlanModal;
