import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 49px;
    top: 72px;
    width: 285px;
    z-index: ${theme.layers.modal};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 232px;
  text-align: center;
`;

export const CornerTag = styled.div`
  ${({ theme }) => css`
    border-color: ${theme.palette.primary.main} transparent transparent ${theme.palette.primary.main};
    border-style: solid;
    border-width: 25px 25px 25px 25px;
    height: 0;
    line-height: 0;
    margin: 4px 0px 0px 4px;
    width: 0;
  `}
`;

export const CornerTagTextContainer = styled.div`
  align-items: center;
  display: flex;
  height: 15px;
  justify-content: center;
  left: 7px;
  position: absolute;
  top: 14px;
  transform: rotate(-45deg);
  width: 30px;
`;

export const CornerTagText = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.white.light};
    font-size: ${theme.font.sizes.xxxs};
    font-weight: bold;
  `}
`;

export const CreditCardInstallmentsIconAdapter = styled.div`
    display: flex;
    justify-content: center;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 16px;
`;

export const CloseButtonAdapter = styled.div`
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
`;

export const TextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    margin: 0px 14px;
    text-align: center;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: bold;
    margin: 26px 0px 6px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    margin: 0px 0px 10px 0px;
  `}
`;

export const Link = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.blue.main};
    cursor: pointer;
    font-size: 16px;
    margin: 0px;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.yellow.main};
    background: linear-gradient(
      90deg,
      ${theme.palette.yellow.main} 11%,
      ${theme.palette.danger.dark} 11%,
      ${theme.palette.danger.dark} 27%,
      ${theme.palette.secondary.primary} 27%,
      ${theme.palette.secondary.primary} 46%,
      ${theme.palette.primary.dark} 46%,
      ${theme.palette.primary.dark} 64%,
      ${theme.palette.yellow.main} 64%,
      ${theme.palette.yellow.main} 77%,
      ${theme.palette.secondary.primary} 77%,
      ${theme.palette.secondary.primary} 88%,
      ${theme.palette.danger.dark} 88%);
    bottom: 0px;
    height: 5px;
    position: absolute;
    width: 100%;
  `}
`;
