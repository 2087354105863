import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip } from '@material-ui/core';
import { chipLinkOfProductTagManager } from '@/utils/ThirdParties/tagManager';
import styles from './styles';
import { Tag } from 'gatorcomponents';

const ChipLinkButton = ({
  classes,
  status,
  type,
  target,
  onClickable = true,
  cloudflareTagLabel,
  label,
  onClick,
  ...rest
}) => {
  const getTagClasses = (type, status) => {
    if (type === 'sitelock') {
      return `${classes.tagDefault} ${classes.tagActive}`;
    }

    if (type === 'ssl') {
      return `${classes.tagDefault} ${classes.tagActive}`;
    }

    if (status === 'active') {
      return `${classes.tagDefault} ${classes.tagActive} ${status}`;
    }

    if (status === 'inactive') {
      return `${classes.tagDefault} ${classes.tagInactive} ${status}`;
    }

    return '';
  };

  const handleClick = () => {
    chipLinkOfProductTagManager(label);
    onClick && onClick();
  };

  const mountLabel = type !== 'cloudflare' ? label : (
    <span className={`${classes.tagCloudflare}`}>
      {label}
      <Tag label={cloudflareTagLabel} size="sm" />
    </span>
  );

  return (
    <Chip
      classes={{ root: classes.tagControl }}
      clickable={onClickable}
      component="a"
      target={target}
      variant="outlined"
      className={getTagClasses(type, status)}
      onClick={handleClick}
      label={mountLabel}
      {...rest}
    />
  );
};

ChipLinkButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChipLinkButton);
