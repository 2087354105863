import { CALL_API } from '@/middleware/api';
import { CREDIT_CARD_INSTALLMENTS, CREDIT_CARD_INSTALLMENTS_BY_PRODUCT_DATA, CREDIT_CARD_URL } from '@/config/api';
import {
  REQUEST_CREDIT_CARD, RECEIVE_CREDIT_CARD, ERROR_CREDIT_CARD,
  DELETE_CREDIT_CARD_START, DELETE_CREDIT_CARD_SUCCESS, DELETE_CREDIT_CARD_ERROR,
  SAVE_CREDIT_CARD_START, SAVE_CREDIT_CARD_SUCCESS, SAVE_CREDIT_CARD_ERROR, CREDIT_CARD_INSTALLMENTS_SUCCESS, REQUEST_CREDIT_CARD_INSTALLMENTS, CREDIT_CARD_INSTALLMENTS_ERROR,
} from './actionsTypes';


function receiveCreditCard(creditCard) {
  return {
    type: RECEIVE_CREDIT_CARD,
    creditCard,
  };
}

export function loadCreditCard() {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: CREDIT_CARD_URL,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_CREDIT_CARD }),
        success: response => receiveCreditCard(response.data),
        error: () => ({ type: ERROR_CREDIT_CARD }),
      },
    },
  };
}


export function removeCreditCard() {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: `${CREDIT_CARD_URL}/remove`,
      method: 'DELETE',
      actionTypes: {
        request: () => ({ type: DELETE_CREDIT_CARD_START }),
        success: () => ({ type: DELETE_CREDIT_CARD_SUCCESS }),
        error: () => ({ type: DELETE_CREDIT_CARD_ERROR }),
      },
    },
  };
}

function verifyOnSaveCreditCard(response, data) {
  // eslint-disable-next-line no-param-reassign
  delete data.recaptcha;
  if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'success') && response.data.success) {
    const { ccnumber, expdate, ...restData } = data;
    return {
      type: SAVE_CREDIT_CARD_SUCCESS,
      data: {
        ...restData,
        cardlastfour: ccnumber.replace(/.(?=.{4,}$)/g, '*').padStart(15, '*'),
        expdate: expdate.split('-').reverse().join('/'),
      },
    };
  }
  return {
    type: SAVE_CREDIT_CARD_ERROR,
  };
}

export function saveCreditCard(data) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: `${CREDIT_CARD_URL}`,
      method: 'PUT',
      body: data,
      actionTypes: {
        request: () => ({ type: SAVE_CREDIT_CARD_START }),
        success: response => verifyOnSaveCreditCard(response, data),
        error: () => ({ type: SAVE_CREDIT_CARD_ERROR }),
      },
    },
  };
}

function onReceiveCreditCardInstallments(installments) {
  return {
    type: CREDIT_CARD_INSTALLMENTS_SUCCESS,
    installments,
  };
}

export function getCreditCardInstallments(invoiceId) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: CREDIT_CARD_INSTALLMENTS(invoiceId),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_CREDIT_CARD_INSTALLMENTS }),
        success: response => onReceiveCreditCardInstallments(response.data),
        error: () => ({ type: CREDIT_CARD_INSTALLMENTS_ERROR }),
      },
    },
  };
}

export function getCreditCardInstallmentsByProductData(serviceType, serviceId) {
  return {
    [CALL_API]: {
      authenticated: true,
      endpoint: CREDIT_CARD_INSTALLMENTS_BY_PRODUCT_DATA(serviceType, serviceId),
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_CREDIT_CARD_INSTALLMENTS }),
        success: response => onReceiveCreditCardInstallments(response.data),
        error: () => ({ type: CREDIT_CARD_INSTALLMENTS_ERROR }),
      },
    },
  };
}
