/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import { useHistory } from 'react-router';
import {
  loadBasicPrice, getPaymentMethods, getPrices, buySiteLock,
} from '@/redux/actions/sitelock';
import { loadDomains as loadAllDomains } from '@/redux/actions/domains';
import { SITELOCK_PROFESSIONAL_PRODUCT_ID } from '@/config/products/sitelock';
import Loader from '@/components/Layout/Loader';
import { SecurityCtaLocale } from './SecurityCta.locale';
import SiteLockLP from './SiteLockLP/SiteLockLP';
import { sitelockAnalytics } from '@/analytics/security/sitelock';

const SecurityCta = ({ classes, t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    prices, paymentMethods,
  } = useSelector(state => state.sitelock.cta);
  const { list: domainsList } = useSelector(state => state.newDomains);
  const { redirectToInvoices, invoiceId, paymentMethod } = useSelector(state => state.sitelock.buyFastcheckout);
  const loading = !prices.length || !paymentMethods.length;
  const [activeDomainToggle, setActiveDomaintoggle] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [typedDomain, setTypedDomain] = useState('');
  const [selectedCycle, setSelectedCycle] = useState('annually');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('braspag_credit_1x');
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const getSiteLockProps = () => {
    const defaultProps = {
      prices,
      title: SecurityCtaLocale.title,
      description: SecurityCtaLocale.description,
      listTitle: SecurityCtaLocale.listTitle,
      featureList: SecurityCtaLocale.featureList,
      formTitle: SecurityCtaLocale.formTitle,
      formDescription: SecurityCtaLocale.formDescription,
      formFirstToggleLabel: SecurityCtaLocale.formFirstToggleLabel,
      formSecondToggleLabel: SecurityCtaLocale.formSecondToggleLabel,
      formSecondTogglePlaceholder: SecurityCtaLocale.formSecondTogglePlaceholder,
      cycleLabel: SecurityCtaLocale.cycleLabel,
      confirmDomainLabel: SecurityCtaLocale.confirmDomainLabel,
      setSelectedDomain,
      selectedDomain,
      activeDomainToggle,
      typedDomain,
      setTypedDomain,
      setSelectedCycle,
      selectedCycle,
      selectePaymentLabel: SecurityCtaLocale.selectePaymentLabel,
      toBePaid: SecurityCtaLocale.toBePaid,
      hgSiteLock: SecurityCtaLocale.hgSiteLock,
      isLoadingPayment,
      setSelectedPaymentMethod,
      needMoreLabel: SecurityCtaLocale.needMoreLabel,
      needMoreButtonText: SecurityCtaLocale.needMoreButtonText,
      detailsSectionTitle: SecurityCtaLocale.detailsSectionTitle(),
      detailsSectionFirstSectionTitle: SecurityCtaLocale.detailsSectionFirstSectionTitle,
      detailsSectionFirstSectionDescription: SecurityCtaLocale.detailsSectionFirstSectionDescription(),
      detailsSectionFirstSectionButtonLabel: SecurityCtaLocale.detailsSectionFirstSectionButtonLabel,
      detailsSectionSecondSectionTitle: SecurityCtaLocale.detailsSectionSecondSectionTitle,
      detailsSectionSecondSectionDescription: SecurityCtaLocale.detailsSectionSecondSectionDescription(),
      detailsSectionSecondSectionButtonLabel: SecurityCtaLocale.detailsSectionSecondSectionButtonLabel,
      discoverBenefitsTitle: SecurityCtaLocale.discoverBenefitsTitle,
      discoverBenefitsBenefits: SecurityCtaLocale.discoverBenefitsBenefits(),
      discoverBenefitsCtaTitle: SecurityCtaLocale.discoverBenefitsCtaTitle,
      discoverBenefitsCtaDescription: SecurityCtaLocale.discoverBenefitsCtaDescription,
      discoverBenefitsButtonLabel: SecurityCtaLocale.discoverBenefitsButtonLabel,
      bannerTitle: SecurityCtaLocale.bannerTitle,
      bannerLabel: SecurityCtaLocale.bannerLabel,
      gaSelectDomain: sitelockAnalytics.gaSelectDomain,
      gaTypeDomain: sitelockAnalytics.gaTypeDomain,
      gaCyclePaymentClick: sitelockAnalytics.gaCyclePaymentClick,
      gaSelectPaymentMethod: sitelockAnalytics.gaSelectPaymentMethod,
      gaBuySitelock: sitelockAnalytics.gaBuySitelock,
      gaSeeAllPlans: sitelockAnalytics.gaSeeAllPlans,
      gaProtectMySite: sitelockAnalytics.gaProtectMySite,
      gaWantMoreProtection: sitelockAnalytics.gaWantMoreProtection,
      gaGetNowAnchor: sitelockAnalytics.gaGetNowAnchor,
    };

    let domains = [];
    if (domainsList && domainsList.length) {
      domains = [...domainsList].map((item) => {
        const domainOption = {
          label: item.domain,
          value: item.id,
          id: item.id,
        };

        return domainOption;
      });
    }

    let paymentCycles = [];
    if (prices && prices.length) {
      paymentCycles = [...prices].map((item) => {
        const newPrice = {
          title: SecurityCtaLocale.cycleOptions[item.cycle],
          name: item.cycle,
          featured: item.cycle === 'annually' || false,
        };

        return newPrice;
      });
    }

    let paymentMethodsOptions = [];
    if (paymentMethods && paymentMethods.length) {
      paymentMethodsOptions = [...paymentMethods].map((item) => {
        const newPaymentMethod = {
          label: item[1].description,
          value: item[1].name,
          id: item[1].name,
        };

        return newPaymentMethod;
      });
    }

    let activeCycle = [];
    if (prices && prices.length) {
      activeCycle = [...prices].find(item => item.cycle === selectedCycle);
    }

    if (activeCycle.length) {
      activeCycle.per = SecurityCtaLocale.cyclePer[selectedCycle];
    }

    const handleOnToggle = ({ toggleOpen, name }) => {
      if (!toggleOpen) {
        setActiveDomaintoggle(null);
      }

      if (toggleOpen) {
        setActiveDomaintoggle(name);
      }
    };

    const getDomain = () => {
      const payment = paymentMethods.find(item => item[0] === selectedPaymentMethod);
      const fastCheckout = payment[0];

      setIsLoadingPayment(true);
      dispatch(buySiteLock({
        productId: SITELOCK_PROFESSIONAL_PRODUCT_ID.br,
        paymentMethod: fastCheckout,
        productCycle: selectedCycle,
        productDomain: activeDomainToggle === 'select-domain' ? selectedDomain.label : typedDomain,
      }));
    };

    let disabledButton = true;

    if (activeDomainToggle) {
      if (activeDomainToggle === 'select-domain' && selectedDomain) {
        disabledButton = false;
      }


      if (activeDomainToggle === 'type-domain' && typedDomain) {
        disabledButton = false;
      }
    }

    return {
      ...defaultProps,
      getDomain,
      domains,
      paymentCycles,
      handleOnToggle,
      paymentMethods: paymentMethodsOptions,
      activeCycle,
      disabledButton,
    };
  };

  useEffect(() => {
    if (redirectToInvoices && invoiceId) {
      history.push(`${t('routes.billing')}${t('routes.unpaid')}/${invoiceId}/${paymentMethod}`);
    }
  }, [t, invoiceId, paymentMethod, redirectToInvoices]);

  useEffect(() => {
    dispatch(getPaymentMethods());
    dispatch(getPrices());
    dispatch(loadBasicPrice());
    dispatch(loadAllDomains({}));
  }, []);


  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.wrapper} data-testid="sitelock-cta" id="ancora">
        <Card data-testid="list-title-area" className={classes.titleCard}>
          <Typography data-testid="list-title" variant="h6" className={classes.pageTitle}>
            {t('sitelock.cta.title')}
          </Typography>
        </Card>

        <SiteLockLP {...getSiteLockProps()} />
      </div>
    </>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(SecurityCta));
