import React from 'react';
import * as Styles from './DetailsSections.styles';
import { Button } from '@/pages/common/v1/Button';
import firstRowImage from '@/media/codeguard/problems.png';
import secondRowImage from '@/media/codeguard/howprotect.png';

const DetailsSections = ({
  title,
  firstSectionTitle,
  firstSectionDescription,
  firstSectionButtonLabel,
  secondSectionTitle,
  secondSectionDescription,
  secondSectionButtonLabel,
  codeGuardAnalytics,
}) => {
  const moveScreenToTop = (row) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    row === 1
      ? codeGuardAnalytics.firstSectionButtonClick()
      : codeGuardAnalytics.secondSectionButtonClick();
  };
  return (
    <Styles.Container data-testid="details-sections">
      <Styles.Title>{title}</Styles.Title>

      <Styles.FirstRow>
        <Styles.ImageWrapper>
          <Styles.RowImage src={firstRowImage} />
        </Styles.ImageWrapper>

        <Styles.RowContent row={1}>
          <Styles.RowTitle>
            {firstSectionTitle}
          </Styles.RowTitle>

          <Styles.RowDescription>
            {firstSectionDescription}
          </Styles.RowDescription>

          <Styles.RowButtonWrapper>
            <Button
              testId="first-row-button"
              label={firstSectionButtonLabel}
              onClick={() => moveScreenToTop(1)}
            />
          </Styles.RowButtonWrapper>

        </Styles.RowContent>
      </Styles.FirstRow>

      <Styles.SecondRow>
        <Styles.RowContent row={2}>
          <Styles.RowTitle>
            {secondSectionTitle}
          </Styles.RowTitle>

          <Styles.RowDescription>
            {secondSectionDescription}
          </Styles.RowDescription>

          <Styles.RowButtonWrapper>
            <Button
              testId="second-row-button"
              label={secondSectionButtonLabel}
              onClick={() => moveScreenToTop(2)}
            />
          </Styles.RowButtonWrapper>

        </Styles.RowContent>

        <Styles.ImageWrapper>
          <Styles.RowImage src={secondRowImage} />
        </Styles.ImageWrapper>
      </Styles.SecondRow>
    </Styles.Container>
  );
};

export default DetailsSections;
