const styles = theme => ({
  filterCard: {
    marginTop: 6,
    '@media(max-width: 1340px)': {
      marginTop: 20,
    },
  },
  container: {
    '& .pending-invoice-message-help-page': {
      [theme.breakpoints.down(960)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.up(960)]: {
        marginLeft: 40,
        marginRight: 40,
      },
    },
  },
  content: {
    paddingTop: 0,
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
  title: {
    marginBottom: '18px',
  },
});

export default styles;
