const styles = () => ({
  root: {
    padding: '32px',
    height: '100%',

    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 20px',
    },
  },
  titleContainer: {
    padding: '0 0 32px 0',
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '24px',

    '@media (max-width: 960px)': {
      fontSize: '20px',
      gap: '8px',
    },
  },
  contentWrapper: {
    padding: '0',
  },
  actions: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    padding: '32px 0 0 0',
    margin: 0,

    '@media (max-width: 960px)': {
      gap: '24px',
    },
  },
});

export default styles;
