import { withStyles } from '@material-ui/core';
import React, { } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withI18n } from 'react-i18next';
import styles from './styles';
import ProEmailPlan from './ProEmailPlan';

const CardStatus = ({
  classes, proEmail, almostOverdue, dateDiff, almostExcluded, isAutomaticRenovationDisabled, daysTrial,
}) => {
  const plans = ['null', 'pending', 'provisioning', 'suspended', 'terminated', 'expired'];

  const isConfiguredAccounts = proEmail.configured ? proEmail.configured : plans.includes(proEmail.status.toLowerCase());


  return (
    <div className={classes.details} data-testid="cardStatusBlock">
      <div className={classnames(classes.proEmailDataWrapper, { [classes.forceCenter]: proEmail.status.toLowerCase() === null })}>
        <ProEmailPlan
          proEmail={proEmail}
          isConfiguredAccounts={isConfiguredAccounts}
          almostOverdue={almostOverdue}
          almostExcluded={almostExcluded}
          dateDiff={dateDiff}
          isAutomaticRenovationDisabled={isAutomaticRenovationDisabled}
          daysTrial={daysTrial}
        />
      </div>
    </div>
  );
};

CardStatus.propTypes = {
  proEmail: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(CardStatus));
