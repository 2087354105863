import React, { useEffect, useState } from 'react';
import HostGator from '@/media/subscriptions/refund/HostGator.svg';
import { IconCreditCard, IconPix } from '@/icons';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import * as Styles from './ReviewRefundBlock.styles';

const ReviewRefundBlock = ({
  testId = 'review-refund-block',
  refundTitle = 'Refund Mode',
  totalLabel = 'Total to refund',
  amount = '10.00',
  selectedRefundMethod = 'hg_credits',
  methodOptions = {
    hg_credits: {
      image: <img src={HostGator} alt="hostgator" />,
      name: 'HostGator Credits',
      description: 'The refund will be deducted from your next invoice.',
      info: 'The refund will be deducted from the amount of your next invoice (if any).',
    },
    braspag_credit_1x: {
      image: <IconCreditCard />,
      name: 'Credit Card',
      description: 'You will receive the refund directly on the credit card bill used in the contract.',
      info: 'The refund will be deducted from the amount of your next invoice (if any).',
    },
    dlocal: {
      image: <IconCreditCard />,
      name: 'Credit Card',
      description: 'You will receive the refund directly on the credit card bill used in the contract.',
      info: 'The refund will be deducted from the amount of your next invoice (if any).',
    },
    gp_boleto: {
      image: <IconPix />,
      name: 'Pix',
      description: '',
      info: '',
    },
    gp_paypal: {
      image: <IconCreditCard />,
      name: 'PayPal',
      description: '',
      info: '',
    },
  },
}) => {
  const [methodSelected, setMethodSelected] = useState('hg_credits');

  useEffect(() => {
    setMethodSelected(selectedRefundMethod);
  }, [selectedRefundMethod]);

  return (
    <>
      <Styles.Title>
        {refundTitle}
      </Styles.Title>
      <Styles.Wrapper data-testid={testId}>
        <Styles.IconWrapper>
          {methodOptions[methodSelected].image}
        </Styles.IconWrapper>

        <Styles.DataWrapper>
          <Styles.MethodName>{methodOptions[methodSelected].name}</Styles.MethodName>
          <Styles.TotalLabel>{totalLabel}</Styles.TotalLabel>
          <Styles.Amount>
            {formatCurrency(amount)}
          </Styles.Amount>

          <Styles.Info>
            {methodOptions[methodSelected].info}
          </Styles.Info>
        </Styles.DataWrapper>
      </Styles.Wrapper>
    </>
  );
};

export default ReviewRefundBlock;
