import React from 'react';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectDomainCustom from '@/components/Dns/SelectDomainCustom';
import { zoneManagerDomainChoice } from '@/utils/ThirdParties/tagManager';
import styles from './styles';

const DomainSelectBlock = ({
  classes, t, setAnotherProvider, loadDomainData,
}) => {
  const chosenDomain = useSelector(state => state.dnsList.choosenDomain);
  const dnsList = useSelector(state => state.dnsList.domains);

  const handleChangeSelectValue = (newChosenDomain, keySelected, optionId) => {
    setAnotherProvider(keySelected === 'anotherProvider');
    let gaEventLabel = '';
    switch (keySelected) {
      case 'dnsMissing':
        gaEventLabel = 'dns_missing';
        break;
      case 'dnsOk':
        gaEventLabel = 'dns_ok';
        break;
      case 'anotherProvider':
        gaEventLabel = 'another_domain_provider';
        break;
      default:
        gaEventLabel = keySelected;
        break;
    }

    zoneManagerDomainChoice(gaEventLabel);
    if (keySelected !== 'anotherProvider' && newChosenDomain !== '') {
      loadDomainData(optionId);
    }
  };

  return (
    <div className={classes.firstCardWrapper} data-testid="domainChoiceClassWrapper">
      <div className={classes.headerWrapper}>
        <Typography className={classes.title} variant="h1">{t('dns.chooseDomain')}</Typography>
        <span className={classes.asterisk}>*</span>
      </div>

      <SelectDomainCustom
        options={dnsList}
        onHandleChangeSelectValue={handleChangeSelectValue}
        selectedValue={chosenDomain}
      />
    </div>
  );
};

DomainSelectBlock.propTypes = {
  setAnotherProvider: PropTypes.func.isRequired,
  loadDomainData: PropTypes.func.isRequired,
};

export default (withI18n()(withStyles(styles)(DomainSelectBlock)));
