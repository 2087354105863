import React from 'react';
import {
  Button, IconCheck, IconClock, IconDanger, Accordion, IconFilledInfo, Tooltip,
} from 'gatorcomponents';
import * as Styles from './RefundAlertsModal.styles';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { Modal } from '@/pages/common';
import { PAYMENT_METHOD, REFUND_TYPE, REFUND_STATUS } from './RefundAlertsModal.enum';

const RefundAlertsModal = ({ customerRefund, hideModal, mobile }) => {
  const { alerts: alertsLocale } = useLocale();

  const handleHideModal = () => {
    hideModal && hideModal();
  };

  const refundType = customerRefund.refund_type === REFUND_TYPE.PARTIAL ? 'Parcial' : 'Integral';

  const paymentMethodLabel = () => {
    switch (customerRefund.method) {
      case PAYMENT_METHOD.BRASPAG_CREDIT:
      case PAYMENT_METHOD.BRASPAG_PARC_1X:
      case PAYMENT_METHOD.BRASPAG_PARC_2X:
      case PAYMENT_METHOD.BRASPAG_PARC_3X:
      case PAYMENT_METHOD.BRASPAG_PARC_4X:
      case PAYMENT_METHOD.BRASPAG_PARC_5X:
      case PAYMENT_METHOD.BRASPAG_PARC_6X:
      case PAYMENT_METHOD.BRASPAG_PARC_7X:
      case PAYMENT_METHOD.BRASPAG_PARC_8X:
      case PAYMENT_METHOD.BRASPAG_PARC_9X:
      case PAYMENT_METHOD.BRASPAG_PARC_10X:
      case PAYMENT_METHOD.BRASPAG_PARC_11X:
      case PAYMENT_METHOD.BRASPAG_PARC_12X:
      case PAYMENT_METHOD.CREDIT_CARD_ES:
      case PAYMENT_METHOD.CREDIT_CARD:
        return alertsLocale.refundModal.paymentMethods.creditCard;
      case PAYMENT_METHOD.PAYPAL:
      case PAYMENT_METHOD.GP_PAYPAL:
        return alertsLocale.refundModal.paymentMethods.paypal;
      case PAYMENT_METHOD.HG_CREDITS:
      case PAYMENT_METHOD.PIX:
      case PAYMENT_METHOD.GP_BOLETO:
      case PAYMENT_METHOD.BOLETO_MX:
      case PAYMENT_METHOD.BOLETO_CL_CO:
        return alertsLocale.refundModal.paymentMethods.hgCredits;
      default:
        return alertsLocale.refundModal.paymentMethods.hgCredits;
    }
  };

  const methodChosenLabel = () => {
    if (customerRefund.refund_type === 'integral') {
      switch (customerRefund.method) {
        case PAYMENT_METHOD.HG_CREDITS:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.wholeCreditsLabel;
        case PAYMENT_METHOD.PIX:
        case PAYMENT_METHOD.GP_BOLETO:
        case PAYMENT_METHOD.BOLETO_MX:
        case PAYMENT_METHOD.BOLETO_CL_CO:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.wholePixLabel;
        case PAYMENT_METHOD.PAYPAL:
        case PAYMENT_METHOD.GP_PAYPAL:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.wholePaypalLabel;
        case PAYMENT_METHOD.BRASPAG_CREDIT:
        case PAYMENT_METHOD.BRASPAG_PARC_1X:
        case PAYMENT_METHOD.BRASPAG_PARC_2X:
        case PAYMENT_METHOD.BRASPAG_PARC_3X:
        case PAYMENT_METHOD.BRASPAG_PARC_4X:
        case PAYMENT_METHOD.BRASPAG_PARC_5X:
        case PAYMENT_METHOD.BRASPAG_PARC_6X:
        case PAYMENT_METHOD.BRASPAG_PARC_7X:
        case PAYMENT_METHOD.BRASPAG_PARC_8X:
        case PAYMENT_METHOD.BRASPAG_PARC_9X:
        case PAYMENT_METHOD.BRASPAG_PARC_10X:
        case PAYMENT_METHOD.BRASPAG_PARC_11X:
        case PAYMENT_METHOD.BRASPAG_PARC_12X:
        case PAYMENT_METHOD.CREDIT_CARD_ES:
        case PAYMENT_METHOD.CREDIT_CARD:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.wholeCreditCardLabel;
        default:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialCreditsLabel;
      }
    } else {
      switch (customerRefund.method) {
        case PAYMENT_METHOD.HG_CREDITS:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialCreditsLabel;
        case PAYMENT_METHOD.PIX:
        case PAYMENT_METHOD.GP_BOLETO:
        case PAYMENT_METHOD.BOLETO_MX:
        case PAYMENT_METHOD.BOLETO_CL_CO:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialPixLabel;
        case PAYMENT_METHOD.PAYPAL:
        case PAYMENT_METHOD.GP_PAYPAL:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialPaypalLabel;
        case PAYMENT_METHOD.BRASPAG_CREDIT:
        case PAYMENT_METHOD.BRASPAG_PARC_1X:
        case PAYMENT_METHOD.BRASPAG_PARC_2X:
        case PAYMENT_METHOD.BRASPAG_PARC_3X:
        case PAYMENT_METHOD.BRASPAG_PARC_4X:
        case PAYMENT_METHOD.BRASPAG_PARC_5X:
        case PAYMENT_METHOD.BRASPAG_PARC_6X:
        case PAYMENT_METHOD.BRASPAG_PARC_7X:
        case PAYMENT_METHOD.BRASPAG_PARC_8X:
        case PAYMENT_METHOD.BRASPAG_PARC_9X:
        case PAYMENT_METHOD.BRASPAG_PARC_10X:
        case PAYMENT_METHOD.BRASPAG_PARC_11X:
        case PAYMENT_METHOD.BRASPAG_PARC_12X:
        case PAYMENT_METHOD.CREDIT_CARD_ES:
        case PAYMENT_METHOD.CREDIT_CARD:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialCreditCardLabel;
        default:
          return alertsLocale.refundModal.modeAndTerm.refundMethods.partialCreditsLabel;
      }
    }
  };

  const ProductsList = () => (
    <>
      {customerRefund.items.map(item => (
        <Styles.ListItem key={item.description}>
          <Styles.ListMarker>•</Styles.ListMarker>
          <p>{item.description}</p>
        </Styles.ListItem>
      ))}
    </>
  );

  const accordionList = () => (
    <Styles.AccordionListContainer>
      <ProductsList />
    </Styles.AccordionListContainer>
  );

  const CanceledProducts = () => (
    <>
      {customerRefund.items && customerRefund.items.length > 1 ? (
        <Accordion
          title={alertsLocale.refundModal.accordionTitle}
          items={accordionList}
          width="full"
          testId="refunds-resume"
        />
      ) : (
        <Styles.Description data-testid="one-refund-resume">
          <strong>{alertsLocale.refundModal.canceledProduct.label}</strong>
          <Styles.Container>{alertsLocale.refundModal.canceledProduct.value(customerRefund.items && customerRefund.items[0].description)}</Styles.Container>
        </Styles.Description>
      )}
    </>
  );

  return (
    <Modal onClose={handleHideModal} maxWidth={624} testId="refunds-modal">
      <Styles.Wrapper>
        <Styles.Title isMobile={mobile}>
          {alertsLocale.refundModal.title}
        </Styles.Title>

        <Styles.FrameWrapper>
          <Styles.NoticeContainer data-testid="notice-container">
            <Styles.ResultIcon completed={customerRefund.status === REFUND_STATUS.COMPLETED}>
              {customerRefund.status === REFUND_STATUS.COMPLETED ? (
                <IconCheck />
              ) : (
                <IconClock />
              )}
            </Styles.ResultIcon>
          </Styles.NoticeContainer>

          {customerRefund.status === REFUND_STATUS.COMPLETED ? (
            <Styles.Text data-testid="completed-refund">{alertsLocale.refundModal.refundCompletedCardLabel(customerRefund.amount, paymentMethodLabel())}</Styles.Text>
          ) : (
            <Styles.Text data-testid="pending-refund">{alertsLocale.refundModal.refundPendingCardLabel(customerRefund.amount, paymentMethodLabel())}</Styles.Text>
          )}

        </Styles.FrameWrapper>

        <CanceledProducts />

        <Styles.ItemsContainer isMobile={mobile} data-testid="refund-details">
          <Styles.Description>
            <strong>{alertsLocale.refundModal.canceledDate.label}</strong>
            <Styles.Container>{customerRefund.date_cancel}</Styles.Container>
          </Styles.Description>
          <Styles.Description>
            <Styles.ModalityContainer>
              <strong>{alertsLocale.refundModal.canceledModality.label}</strong>
              <Tooltip content={<Styles.TooltipContent>{alertsLocale.refundModal.oneWeekCancel}</Styles.TooltipContent>} disableMobile={mobile}>
                {!mobile && (
                  <div>
                    <Styles.IconInfoContainer>
                      <IconFilledInfo />
                    </Styles.IconInfoContainer>
                  </div>
                )}
              </Tooltip>
            </Styles.ModalityContainer>
            <Styles.Container>{refundType}</Styles.Container>
          </Styles.Description>
          <Styles.Description>
            <strong>{alertsLocale.refundModal.refundValue.label}</strong>
            <Styles.Container>{alertsLocale.refundModal.refundValue.value(customerRefund.amount)}</Styles.Container>
          </Styles.Description>
        </Styles.ItemsContainer>

        <Styles.Description>
          <strong>{alertsLocale.refundModal.modeAndTerm.title}</strong>
          <Styles.Container>{methodChosenLabel()}</Styles.Container>
        </Styles.Description>
        <Styles.InfoAlert data-testid="know-more-alert">
          <IconDanger color="primaryBlue" size="lg" />
          {alertsLocale.refundModal.knowMoreLabel}
        </Styles.InfoAlert>
        {!mobile && (
          <Styles.ButtonContainer>
            <Button
              testId="ok-button"
              label={alertsLocale.refundModal.okUnderstoodButtonLabel}
              size="large"
              onClick={handleHideModal}
            />
          </Styles.ButtonContainer>
        )}
      </Styles.Wrapper>
    </Modal>
  );
};

export default withI18n()((RefundAlertsModal));
