import React, { Component, Fragment } from 'react';
import {
  Card, CardContent, withStyles, Fade,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import NewSiteThemeCards from '@/components/NewSite/NewSiteThemeCards';
import Loader from '@/components/Layout/Loader';
import SelectField from '@/components/Fields/SelectField';
import SelectFieldCategories from '@/components/Fields/SelectFieldCategories';
import NewSiteCard from '@/components/Cards/NewSiteCard';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import ActionButton from '@/components/Buttons/ActionButton';
import LinkButton from '@/components/Buttons/LinkButton';
import {
  loadOnboardingDomains, loadOnboardingThemes, filterThemesByCategory, cleanFilterThemesByCategories,
  selectTemplateTheme, loadSSOByDomainId, cleanSelectedTemplate, siteCreateDismiss, sendWebsiteBuilderTrace,
} from '@/redux/actions/onboarding';
import CreatorAdvanced from '@/media/newsite/icons/CreatorAdvanced';
import CreatorFast from '@/media/newsite/icons/CreatorFast';
import { websiteBuilderDismiss } from '@/redux/actions/summary';
import { PORTAL_ONBOARDING_PROCCESS_URL } from '@/config/api';
import LogoMarca from '@/media/logo/LogoMarca';
import { COUNTRY } from '@/config';
import { PREVIEW_WEBSITEBUILDER_TEMPLATE } from '@/config/whmcsUrls';
import NewSiteWebsiteBuilder from '@/media/newsite/icons/NewSiteWebsiteBuilder';
import NewSiteWordpress from '@/media/newsite/icons/NewSiteWordpress';
import NewSiteManual from '@/media/newsite/icons/NewSiteManual';
import { updateSiteBuilderStatusOnMySitesPage } from '@/redux/actions/productGroups';
import { SITEBUILDER_TRADICIONAL_IDS } from '@/config/products/sitebuilder';
import styles from './styles';


class NewSitePage extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.themeCards = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      options: [
        {
          id: 1,
          icon: <NewSiteWebsiteBuilder />,
          title: t('newsite.creator.title'),
          subtitle: t('newsite.creator.subtitle'),
          type: 'websiteBuilder',
          selected: false,
          tag: false,
        },
        {
          id: 2,
          icon: <NewSiteWordpress />,
          title: t('newsite.wordpress.title'),
          subtitle: t('newsite.wordpress.subtitle'),
          type: 'wordpress',
          selected: false,
          tag: false,
        },
        {
          id: 3,
          icon: <NewSiteManual />,
          title: t('newsite.manual.title'),
          subtitle: t('newsite.manual.subtitle'),
          type: 'cpanel',
          selected: false,
          tag: false,
        },
      ],
      modes: [
        {
          id: 10,
          icon: <CreatorFast />,
          title: t('newsite.fast.title'),
          subtitle: t('newsite.fast.subtitle'),
          selected: false,
          mode: 'fast',
          tag: false,
        },
        {
          id: 20,
          icon: <CreatorAdvanced />,
          title: t('newsite.advanced.title'),
          subtitle: t('newsite.advanced.subtitle'),
          selected: false,
          mode: 'advanced',
          tag: false,
        },
      ],
      showOptions: false,
      showButtonCreate: false,
      showModeOptions: false,
      redirectToFeedback: false,
      selectedDomain: '',
      advancedOptions: false,
      themeCategorie: '',
      linkButton: '',
      RedirectToHome: false,
      loadMore: 6,
      wordpress: '',
      selectedMode: '',
      title: '',
      description: '',
    };
  }

  componentDidMount() {
    const { domains, onloadOnboardingDomains, activateWebsitebuilderPerProduct } = this.props;
    const { options } = this.state;
    const webSiteBuilderDomainsLength = domains.length;
    webSiteBuilderDomainsLength === 0
      && onloadOnboardingDomains().then((response) => {
        if (response.data.length === 1) {
          this.handleSetFirstWebSiteBuilderDomain();
        } else if (response.data.length === 0) {
          this.handleRedirectToHome();
        }
      });
    webSiteBuilderDomainsLength === 1 && this.handleSetFirstWebSiteBuilderDomain();

    this.setRecommendedToolByPersona();
    if (activateWebsitebuilderPerProduct) {
      const newOptions = options[0];
      newOptions.selected = true;
      this.setState({
        options: [
          newOptions,
        ],
        showModeOptions: true,
      });
    }
  }

  setRecommendedToolByPersona = () => {
    const { options, modes } = this.state;
    const { persona } = this.props;
    if (persona !== 0) {
      const newOptions = options.map((option) => {
        const item = option;
        if (item.id === persona) {
          item.tag = true;
        }
        return option;
      });

      const newModes = modes;
      if (persona === 1) {
        modes[0].tag = true;
      } else {
        modes[1].tag = true;
      }

      this.setState({
        options: newOptions,
        modes: newModes,
      });
    }
  }

  handleSelect = (mode, id) => {
    const { selectedDomain, options, modes } = this.state;
    const { t } = this.props;
    const {
      domains,
      selectedTemplateId,
      onCleanSelectedTemplate,
      themes,
      onloadOnboardingThemes,
      filteredThemes,
      onCleanFilterThemesByCategory,
    } = this.props;

    if (selectedDomain === '') {
      return;
    }

    const handleOptions = mode === 'options' ? options : modes;
    let showModeOptions = false;
    let showButtonCreate = false;
    let advancedOptions = false;
    let linkButton = '';

    if (!advancedOptions) {
      const { modes } = this.state;
      modes.forEach((item) => {
        const element = item;
        element.selected = false;
      });
      this.setState({
        modes,
        selectedMode: mode,
      });
    }

    handleOptions.forEach((element) => {
      const item = element;
      item.selected = false;
      if (item.id === parseInt(id, 10)) {
        item.selected = true;
        showModeOptions = mode === 'options' ? item.id === 0 : true;
        advancedOptions = mode === 'modes' && item.mode === 'advanced';
        showButtonCreate = !advancedOptions;
        const { selectedDomain } = this.state;
        const { sso } = this.props;
        const theDomainSelected = domains.filter(domain => domain.id === parseInt(selectedDomain, 10));


        if (mode === 'options' && item.id === 2) {
          linkButton = 'wordpress';
        } else if ((mode === 'options' && item.id === 1) || (mode === 'modes' && item.mode === 'fast')) {
          linkButton = `${t('routes.siteBuilderRedirectAccess')}/${theDomainSelected[0].domain}/${selectedDomain}`;
        } else {
          linkButton = sso[item.type];
        }
      }
    });

    if (showButtonCreate && selectedTemplateId !== -1) {
      onCleanSelectedTemplate();
    }

    if (advancedOptions) {
      !Object.prototype.hasOwnProperty.call(themes, 'templates') && onloadOnboardingThemes();
    } else if (filteredThemes.length > 0) {
      onCleanFilterThemesByCategory();
      this.setState({
        themeCategorie: '',
      });
    }

    this.setState(
      {
        [mode]: handleOptions,
        showModeOptions,
        showButtonCreate,
        advancedOptions,
        linkButton,
      },
      () => {
        if (showModeOptions && !advancedOptions) {
          this.handleScrollScreen(700);
        }

        if (showModeOptions && advancedOptions) {
          this.handleScrollScreen(900);
        }
      },
    );
  }

  handleSetFirstWebSiteBuilderDomain = () => {
    const { domains } = this.props;
    this.setState(
      {
        selectedDomain: domains[0].id.toString(),
        showOptions: true,
      },
      () => {
        this.handleScrollScreen(400);
      },
    );

    this.loadSSO();
  }

  loadSSO = () => {
    const { onLoadSSOByDomainId, domains, activateWebsitebuilderPerProduct } = this.props;
    onLoadSSOByDomainId(domains[0].id).then(() => {
      const { sso } = this.props;
      this.setState(prevState => ({
        ...prevState,
        modes: this.getCreationOptionsModeWithoutExpress(),
        showModeOptions: activateWebsitebuilderPerProduct || prevState.showModeOptions,
        wordpress: sso.wordpress,
      }));
    });
  }

  getCreationOptionsModeWithoutExpress = () => {
    const { modes } = this.state;
    const { websiteBuilderTraditional, featureToggles, t } = this.props;

    if (featureToggles.deprecatedTraditional) {
      if (SITEBUILDER_TRADICIONAL_IDS.includes(websiteBuilderTraditional)) {
        this.setState({ title: t('newsite.siteModes.title') });
        return modes.filter(mode => mode.id === 10);
      }
      this.setState({ description: t('newsite.siteModes.subtitle') });
      this.setState({ title: t('newsite.siteModes.title') });
    }

    return modes;
  }

  handleChangeSelectDomain = (e) => {
    const { onCleanFilterThemesByCategory, onLoadSSOByDomainId } = this.props;
    if (e.target.value === '') {
      const { options } = this.state;
      options.forEach((element) => {
        const item = element;
        item.selected = false;
      });
      onCleanFilterThemesByCategory();
      this.setState({
        selectedDomain: e.target.value.toString(),
        options,
        showButtonCreate: false,
        showModeOptions: false,
        advancedOptions: false,
        themeCategorie: '',
        showOptions: false,
      });
    } else {
      this.setState(
        {
          selectedDomain: e.target.value.toString(),
          showOptions: true,
        },
        () => {
          this.handleScrollScreen(250);
        },
      );
    }


    onLoadSSOByDomainId(e.target.value).then(() => {
      const { sso } = this.props;
      this.setState(({
        modes: this.getCreationOptionsModeWithoutExpress(),
        wordpress: sso.wordpress,
      }));
    });
  }

  handleSelectThemeCategorie = (e) => {
    let loadMore = 0;
    const { width } = this.props;
    if (isWidthDown('sm', width)) {
      loadMore = 3;
    } else {
      loadMore = 6;
    }

    this.setState(
      {
        themeCategorie: e.target.value.toString(),
        loadMore,
      },
      () => {
        const { themeCategorie } = this.state;
        const { onFilterThemesByCategories } = this.props;
        onFilterThemesByCategories(themeCategorie);
        setTimeout(() => {
          this.handleScrollScreen(1200);
        }, 100);
      },
    );
  }

  handleRedirectToFeedback = () => {
    const { options, modes, selectedDomain } = this.state;
    const {
      selectedTemplateId, onSendWebsiteBuilderTrace, onUpdateSiteBuilderStatusOnMySitesPage,
    } = this.props;
    const indexOptions = options.findIndex(option => option.selected);
    const isWebsiteBuilder = options[indexOptions].type === 'websiteBuilder';
    let indexModes = 0;

    if (isWebsiteBuilder) {
      indexModes = modes.findIndex(mode => mode.selected);
    }

    const trace = {
      hostingId: selectedDomain,
      creationTool: options[indexOptions].type,
      creationToolType: isWebsiteBuilder ? modes[indexModes].mode : undefined,
      themeId: selectedTemplateId !== -1 ? selectedTemplateId : undefined,
    };

    onSendWebsiteBuilderTrace(trace);
    onUpdateSiteBuilderStatusOnMySitesPage(selectedDomain, true);

    this.setState({
      redirectToFeedback: true,
    });
  }

  handleRedirectToHome = () => {
    this.setState({
      RedirectToHome: true,
    });
  }

  handleOpenPreview = (templateId) => {
    const CONFIG_SCREEN_PREVIEW_TEMPLATES = 'scrollbars=yes, Toolbar=no, Status=no, Menubar=no, resizable=yes, top=500, left=500, width=4000, height=4000';
    window.open(PREVIEW_WEBSITEBUILDER_TEMPLATE(templateId), '_blank', CONFIG_SCREEN_PREVIEW_TEMPLATES);
  };

  handleSelectTemplate = (templateId) => {
    const { onSelectTemplateTheme } = this.props;
    onSelectTemplateTheme(templateId);
    this.handleScrollScreen(99300);
  }

  handleLoadMore = () => {
    const { filteredThemes } = this.props;
    this.setState(
      {
        loadMore: filteredThemes.length,
      },
      () => {
        this.handleScrollScreen(1300);
      },
    );
  }

  handleWebsiteBuilderDismiss = () => {
    const { selectedDomain } = this.state;
    const {
      onSiteCreateDismiss,
      onWebsiteBuilderDismiss,
      onCleanFilterThemesByCategory,
    } = this.props;
    onSiteCreateDismiss(selectedDomain);
    onWebsiteBuilderDismiss();
    onCleanFilterThemesByCategory();
  }

  handleScrollScreen = (top) => {
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.getElementById('form-wordpress');
    form.submit();
    this.handleRedirectToFeedback();
  }

  render() {
    const {
      modes,
      options,
      loadMore,
      linkButton,
      showOptions,
      selectedDomain,
      themeCategorie,
      RedirectToHome,
      showModeOptions,
      advancedOptions,
      showButtonCreate,
      redirectToFeedback,
      wordpress,
      title,
      description,
    } = this.state;
    const {
      t,
      themes,
      domains,
      classes,
      categories,
      loadingThemes,
      selectedTemplateId,
      loadingWebsiteBuilderFlux,
      filteredThemes,
      activateWebsitebuilderPerProduct,
      featureToggles,
    } = this.props;

    const isShowThemeList = advancedOptions && Object.prototype.hasOwnProperty.call(themes, 'templates');
    const filteredDomain = selectedDomain !== '' ? domains.filter(domain => domain.id === parseInt(selectedDomain, 10)) : false;
    const websiteBuilderDomain = filteredDomain && filteredDomain[0].domain;

    const isBr = COUNTRY === 'br';

    if (redirectToFeedback) {
      return <Redirect to={`${t('routes.newSite')}${t('routes.feedbackSite')}`} />;
    }

    if (RedirectToHome) {
      return <Redirect to={`${t('routes.sitesPage')}`} />;
    }

    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div className={classes.contentWrapper}>
              <LogoMarca />

              <h1 className={classes.title}>{t('newsite.welcome')}</h1>

              <div className={classes.selectWrapper}>
                <p className={classes.description}>{t('newsite.description')}</p>
                <SelectField
                  className={classes.select}
                  filterOptionsTypeOf="array"
                  filterOptions={domains}
                  selectType="websiteBuilder"
                  selectValue={selectedDomain}
                  inputLabel={t('newsite.chooseWebsite')}
                  menuItemDefaultText={t('newsite.chooseWebsite')}
                  labelWidth={102}
                  handleChangeSelectValue={this.handleChangeSelectDomain}
                  disabled={activateWebsitebuilderPerProduct}
                />
              </div>

              <Fade in={showOptions}>
                {(showOptions && options.length > 1) ? (
                  <Fragment>
                    <p className={classes.choice}>{t('newsite.siteModes.subtitle2')}</p>

                    <div className={classes.cardsWrapper}>
                      {options.map(option => (
                        <NewSiteCard
                          key={option.id}
                          option={option}
                          type="options"
                          onHandleSelect={this.handleSelect}
                        />
                      ))}
                    </div>
                  </Fragment>
                ) : (
                  <div />
                )}
              </Fade>

              <Fade in={showModeOptions}>
                {showModeOptions ? (
                  <div className={classes.contentWrapper}>

                    {
                      options.length === 1 ? (
                        <p className={`${classes.description} ${classes.descriptionMT20}`}>
                          {featureToggles.deprecatedTraditional ? description : t('newsite.siteModes.subtitle2')}
                        </p>
                      ) : (
                        <>
                          <h1 className={classes.title}>
                            {featureToggles.deprecatedTraditional ? title : t('newsite.siteModes.title')}
                          </h1>
                          <p className={classes.description}>
                            {featureToggles.deprecatedTraditional ? description : t('newsite.siteModes.subtitle')}
                          </p>
                        </>
                      )
                    }
                    <div className={classes.cardsWrapper}>
                      {!loadingWebsiteBuilderFlux ? (
                        modes.map(option => (
                          <NewSiteCard
                            key={option.id}
                            option={option}
                            type="modes"
                            onHandleSelect={this.handleSelect}
                          />
                        ))
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </Fade>

              <Fade in={showButtonCreate}>
                {showButtonCreate ? (
                  <div className={`${classes.buttonWrapper} ${classes.buttonCreateSite}`}>
                    {linkButton === 'wordpress' ? (
                      <form
                        action={`${wordpress.hostname}/login`}
                        method="post"
                        target="_blank"
                        onSubmit={this.handleSubmit}
                        id="form-wordpress"
                      >
                        <input
                          type="hidden"
                          name="user"
                          value={wordpress.username}
                        />
                        <input
                          type="hidden"
                          name="hostname"
                          value={wordpress.hostname}
                        />
                        <input type="hidden" name="pass" value={wordpress.pass} />
                        <input
                          type="hidden"
                          name="goto_uri"
                          value={wordpress.goto_uri}
                        />
                        <PrimaryButton>{t('newsite.create')}</PrimaryButton>
                      </form>
                    ) : (
                      <PrimaryButton
                        onClick={this.handleRedirectToFeedback}
                        href={linkButton}
                        target="_blank"
                      >
                        {t('newsite.create')}
                      </PrimaryButton>
                    )}
                  </div>
                ) : (
                  <div />
                )}
              </Fade>
            </div>

            {!isShowThemeList && loadingThemes && <Loader classesToProps={classes.loader} />}
            <Fade in={isShowThemeList}>
              {isShowThemeList ? (
                <div className={classes.selectWrapperThemes}>
                  <h1 className={classes.title}>{t('newsite.themes.title')}</h1>
                  <SelectFieldCategories
                    className={classes.select}
                    filterOptionsTypeOf="array"
                    filterOptions={categories}
                    selectType="product"
                    selectValue={themeCategorie}
                    inputLabel={t('newsite.chooseCategoryWebsite')}
                    menuItemDefaultText={t('newsite.categories')}
                    labelWidth={isBr ? 220 : 236}
                    handleChangeSelectValue={this.handleSelectThemeCategorie}
                  />
                </div>
              ) : (
                <div />
              )}
            </Fade>
            {isShowThemeList && filteredThemes.length > 0 && (
              <Fragment>
                <NewSiteThemeCards
                  ref={this.themeCards}
                  themes={filteredThemes}
                  onOpenPreview={this.handleOpenPreview}
                  onSelectTemplate={this.handleSelectTemplate}
                  onLoadMore={loadMore}
                />
                {loadMore < 7 && filteredThemes.length > loadMore && (
                  <ActionButton action={() => this.handleLoadMore()}>{t('btn.loadMore')}</ActionButton>
                )}
              </Fragment>
            )}

            <Fade in={selectedTemplateId !== -1}>
              {selectedTemplateId !== -1 ? (
                <div
                  className={`${classes.buttonWrapper} ${classes.buttonCreateSite} ${classes.buttonAfterThemes}`}
                >
                  <PrimaryButton
                    onClick={this.handleRedirectToFeedback}
                    href={PORTAL_ONBOARDING_PROCCESS_URL(selectedDomain, websiteBuilderDomain, selectedTemplateId)}
                    target="_blank"
                  >
                    {t('newsite.create')}
                  </PrimaryButton>
                </div>
              ) : (
                <div />
              )}
            </Fade>

            <div className={classes.linkWrapper}>
              <LinkButton
                onClick={() => this.handleWebsiteBuilderDismiss()}
                to={`${t('routes.sitesPage')}`}
              >
                {t('persona.createLater')}
              </LinkButton>
            </div>

          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  domains: state.onboarding.products,
  filteredThemes: state.onboarding.filteredThemes,
  themes: state.onboarding.themes,
  loadingThemes: state.onboarding.loadingThemes,
  categories: state.onboarding.themes.categories,
  selectedTemplateId: state.onboarding.selectedTemplateId,
  sso: state.onboarding.sso,
  websiteBuilderTraditional: state.onboarding.webSitebuilderTraditional,
  persona: state.summary.persona,
  activateWebsitebuilderPerProduct: state.onboarding.activateWebsitebuilderPerProduct,
  loadingWebsiteBuilderFlux: state.onboarding.loading,
  featureToggles: state.featureToggles.toggles,
});

const mapDispatchToProps = dispatch => ({
  onloadOnboardingDomains: () => dispatch(loadOnboardingDomains()),
  onloadOnboardingThemes: () => dispatch(loadOnboardingThemes()),
  onFilterThemesByCategories: categorieName => dispatch(filterThemesByCategory(categorieName)),
  onCleanFilterThemesByCategory: () => dispatch(cleanFilterThemesByCategories()),
  onSelectTemplateTheme: templateId => dispatch(selectTemplateTheme(templateId)),
  onLoadSSOByDomainId: domainId => dispatch(loadSSOByDomainId(domainId)),
  onCleanSelectedTemplate: () => dispatch(cleanSelectedTemplate()),
  onWebsiteBuilderDismiss: () => dispatch(websiteBuilderDismiss()),
  onSiteCreateDismiss: hostingid => dispatch(siteCreateDismiss(hostingid)),
  onSendWebsiteBuilderTrace: trace => dispatch(sendWebsiteBuilderTrace(trace)),
  onUpdateSiteBuilderStatusOnMySitesPage: (id, status) => dispatch(updateSiteBuilderStatusOnMySitesPage(id, status)),
});

export default withWidth()(withI18n()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewSitePage))));
