const styles = theme => ({
  card: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '5px',
    boxShadow: '0px 1px 3px #00000029',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    maxWidth: '829px',
    padding: '26px 24px 35px',
  },

  titleRow: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  cardTitle: {
    color: theme.color.tundora,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '24px',
  },

  subTotal: {
    color: theme.color.oceanGreen,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '24px',

    '& small': {
      fontSize: '16px',
      fontWeight: 400,
      marginRight: '2px',
    },
  },

  paymentButton: {
    alignItems: 'center',
    display: 'flex',
    height: '37px',
    justifyContent: 'center',
    width: '200px',
  },

  descriptionItem: {
    fontSize: '12px',
    letterSpacing: '0.01px',
    lineHeight: '16px',
    margin: '0px 80px 24px 0px',

    [theme.breakpoints.down(758)]: {
      flexGrow: 1,
      flexShrink: 0,
      width: 'calc(50% - 48px)',
    },

    [theme.breakpoints.down(502)]: {
      width: '100%',
    },
  },

  firstDescriptionItem: {
    display: 'flex',
    margin: '0px 0px 24px 0px',

    [theme.breakpoints.down(768)]: {
      flexWrap: 'wrap',
    },
  },

  descriptionRow: {
    borderTop: `1px solid ${theme.color.geyser}`,
    marginTop: '26px',
  },

  planDescription: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '590px',
    padding: '24px 0px 0px',
    '& > div:nth-child(4)': {
      margin: '0px 0px 24px 0px',
    },
  },

  centeredItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',

    '& button': {
      alignItems: 'center',
    },
  },

  changeInboxButton: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '100%',
    cursor: 'pointer',
    display: 'flex',
    height: '18px',
    justifyContent: 'center',
    marginLeft: 6,
    width: '18px',
  },

  changeInboxIcon: {
    fill: `${theme.color.indigo}`,
    height: '9px',
  },

  descriptionItemTitle: {
    color: theme.color.regentGray,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '19px',
    marginBottom: '4px',
    minWidth: '106px',
  },

  descriptionItemValue: {
    color: theme.palette.grey.dark,
    fontSize: '16px',
    letterSpacing: '0.01px',
    lineHeight: '32px',
    marginRight: '8px',
  },

  downgradeMessage: {
    backgroundColor: theme.palette.grey.opacityLight,
    border: `1px solid ${theme.palette.grey.primary}`,
    borderRadius: '5px',
    color: theme.palette.grey.dark,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '21px',
    margin: '0px 0px 0px 24px',
    maxWidth: '461px',
    padding: '16px',
    '& > a ': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },

    [theme.breakpoints.down(768)]: {
      margin: '24px 0px 0px 0px',
    },
  },

  info: {
    fill: theme.color.indigo,
    height: '14px',
    marginLeft: '5px',
    width: '14px',
  },
});

export default styles;
