/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal } from '@/pages/common/Modal';
import { Button } from '@/pages/common/Button';
import * as Styles from './DnsInProgressModal.styles';
import { dnsInProgressModalLocale } from './DnsInProgressModal.locale';
import { IconClock } from 'gatorcomponents';

const DnsInProgressModal = ({
  onClose,
  buttonClick,
}) => {
  const handleOnClose = () => {
    onClose && onClose(true);
  };

  const handleOnClick = (event) => {
    buttonClick();
    handleOnClose();
    event.preventDefault();
  };

  return (
    <Modal testId="modal-dns-in-progress" onClose={() => handleOnClose()} disablePadding maxWidth={624}>
      <Styles.Wrapper>
        <Styles.Title data-testid="other-site-types-title">
          {dnsInProgressModalLocale.title}
        </Styles.Title>
        <Styles.DnsWarningWrapper>
          <Styles.DnsWarningIconWrapper>
            <IconClock size="md" color="pendingPure" />
          </Styles.DnsWarningIconWrapper>
          <Styles.DnsWarning>
            {dnsInProgressModalLocale.description}
          </Styles.DnsWarning>
        </Styles.DnsWarningWrapper>
        <Styles.Action>
          <Styles.BackButtonWrapper>
            <Button
              testId="modal-dns-in-progress-back-button"
              variant="newTertiary"
              font="secondary"
              size="large"
              label={dnsInProgressModalLocale.backButton}
              onClick={() => handleOnClose('back')}
            />
          </Styles.BackButtonWrapper>
          <Button onClick={handleOnClick} testId="modal-dns-in-progress-continue-button" font="secondary" variant="newPrimary" label={dnsInProgressModalLocale.continueButton} size="large" />
        </Styles.Action>
      </Styles.Wrapper>
    </Modal>
  );
};

export default DnsInProgressModal;
