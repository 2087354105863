const styles = theme => ({
  chatButton: {
    position: 'fixed',
    bottom: '13px',
    right: '22px',
    zIndex: '1000',
    width: '107.72px',
    height: '45.95px',
    borderRadius: '22.5px',
    background: '#004DA3',
  },
  chatButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  chatButtonText: {
    color: theme.color.white,
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '-0.2px',
  },
  chatIcon: {
    height: 16,
    color: theme.color.white,
    paddingRight: 4,
  },
});

export default styles;
