const styles = theme => ({
  card: {
    background: theme.color.white,
    boxShadow: `0px 1px 3px ${theme.color.tundoraLight}`,
    borderLeft: '5px solid transparent',
  },

  statusActive: {
    borderColor: theme.color.indigo,
  },
  statusNotConfigured: {
    borderColor: theme.color.valencia,
  },
});

export default styles;
