import PropTypes from 'prop-types';

export const FlowStep = {
  INITIAL: 'INITIAL_SCREEN',
  SUCCESS: 'SUCCESS_SCREEN',
  FAILURE: 'FAILURE_SCREEN',
};

export const ReactivationModalProps = {
  onCloseClick: PropTypes.func,
  invoiceId: PropTypes.number,
};
