import React from 'react';

import * as Style from './styles';

const ContentContainer = ({
  children, className, dataId,
}) => (
  <Style.Wrapper className={className} data-id={dataId}>
    {children}
  </Style.Wrapper>
);

export default ContentContainer;
