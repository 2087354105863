import PropTypes from 'prop-types';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';


const CurrencyFormat = ({ children }) => formatCurrency(children);

CurrencyFormat.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CurrencyFormat.defaultProps = {
  children: 0,
};

export default CurrencyFormat;
