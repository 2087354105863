import styled, { css } from 'styled-components';

export const WizardAsaasLoginBannerWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border: solid 1px ${theme.v2.colors.feedback.canceledLight};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: ${theme.v2.font.family.primary};
    gap: 16px;
    margin-top: 26px;
    padding: 0px;
    position:relative;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 29px;
    position: relative;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      align-items: flex-start;
      margin-left: 22px;
    }
  `}
`;

export const AsaasStamp = styled.img`
  ${({ theme }) => css`
    left: 0;
    position: absolute;
    top: 0;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      left: auto;
      right: 0;
      transform: scaleX(-1);
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: 32px;
    margin: 17px 18px 0px 18px;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 17px 36px 0px 0px;
      text-align: left;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.normal};
    line-height: 19px;
    margin: 16px;
    max-width: 579px;
    text-align: center;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin-left: 0px;
      margin-right: 61px;
      text-align: left;
    }
  `}
`;
