export const Tabs = [
  { label: 'Visão Geral', id: 'Visão Geral' },
  { label: 'Fazer Backup', id: 'Fazer Backup' },
  { label: 'Restaurar Backup', id: 'Restaurar Backup' },
  { label: 'Últimos Backups', id: 'Últimos Backups' },
  { label: 'Backup pelo cPanel', id: 'Backup pelo cPanel', preventActivation: true },
];

export const TABS = {
  GENERAL: 'GENERAL',
  CREATE: 'CREATE',
  RESTORE: 'RESTORE',
  LOG: 'LOG',
  CPANEL: 'CPANEL',
};

export const GeneralVisionTags = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  ATTENTION: 'ATTENTION',
};
