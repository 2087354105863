import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  `}
`;

export const NameServerWrapper = styled.div`
  ${({ visible, theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.v1.color.line.fields};
    display: ${visible ? 'flex' : 'none'};
    justify-content: space-between;
    padding: 8px;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      flex-grow: 1;
    }
  `}
`;

export const EditWrapper = styled.div`
  ${({ visible, theme }) => css`
    display: ${visible ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      flex-grow: 1;
    }
  `}
`;

export const EditFieldsWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v1.color.line.fields};
    display: flex;
    justify-content: space-between;
    padding: 8px;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      flex-grow: 1;
    }
  `} 
`;

export const IconWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 8px;
  `}
`;


export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0px;
    line-height: ${theme.font.lineHeights.xl};
    white-space: nowrap;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    background-color: transparent;
    border: 0;
    color: ${theme.v1.color.text.primary};
    flex-grow: 1;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    margin: 0;
    outline: none;
    padding: 0;
  `}
`;

export const NameServer = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0px;
    line-height: ${theme.font.lineHeights.lg};
    margin: 0;
  `}
`;

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.error};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.regular};
    font-size: ${theme.font.sizes.xxs};
    line-height: ${theme.font.lineHeights.lg};
  `}
`;

export const Edit = styled.div`
  ${() => css`
    display: flex;
    cursor: pointer;
    ${({ theme }) => css`
    & span svg:hover {
      filter: brightness(60%);
    }
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      position: initial;
    }
  `}
  `}
`;

export const SaveAndClose = styled.div`
  ${() => css`
    display: flex;
    cursor: pointer;
    ${({ theme }) => css`
    & span svg:hover {
      filter: brightness(60%);
    }
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      position: initial;
    }
  `}
  `}
`;
