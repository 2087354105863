import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    div {
      padding: 0;
      align-items: center;
    };

    button {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v2.font.weights.regular};
    };

    a {
      font-size: ${theme.v2.font.sizes.xxs};
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v2.font.weights.regular};
    };

    @media (max-width: ${theme.v2.breakpoints.lg}){
      div {
        align-items: start;
      };
    };
  `}
`;

export const Text = styled.div`
  p {
    margin: 0;
  };
`;

export const Description = styled.div`
  ${({ theme, newSitesAlert }) => css`
    display: flex;
    color: ${newSitesAlert ? theme.v2.colors.neutral.lowDark : theme.v2.colors.neutral.lowMedium};

    button, a {
      margin-left: ${newSitesAlert ? '4px' : '16px'};
    };

    @media (max-width: ${theme.v2.breakpoints.lg}){
      display: inline-block;

      button, a {
        margin: ${newSitesAlert ? '4px 0 0 0' : '8px 0 0 0'};
      }
    };
  `};
`;
