import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  withStyles,
  Typography,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import * as yup from 'yup';
import { Close } from '@material-ui/icons';
import { locale } from '@/utils/locale';
import {
  validatePhoneCode, changePhoneNumber, phoneNumberChangeFlow,
} from '@/redux/actions/phoneNumberVerify';
import { loadSummary, phoneNumberFlowSkip, setPhoneVerified } from '@/redux/actions/summary';
import {
  ERROR_CHANGE_PHONE_NUMBER,
  ERROR_VALIDATE_PHONE_CODE,
  RECEIVE_CHANGE_PHONE_NUMBER,
  RECEIVE_VALIDATE_PHONE_CODE,
} from '@/redux/actions/actionsTypes';
import { handleCoverPhoneNumber } from '@/utils/handlers/handleCoverPhoneNumber';
import { generateRecaptchaToken, loadReCaptchaPaaS } from '@/utils/Application/reCaptcha';
import PhoneCard from '@/components/PhoneVerify/PhoneCard';
import NewPhoneCard from './PhoneCard';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import LinkButton from '@/components/Buttons/LinkButton';
import ChangePhoneNumber from '@/pages/PhoneVerify/ChangePhoneNumber';
import VerificationCode from '@/pages/PhoneVerify/VerificationCode';
import PhoneConfirmation from '@/pages/PhoneVerify/PhoneConfirmation';
import {
  changePhoneNumberTagManager,
  phoneNumberCodeValidTagManager,
  phoneNumberSendVerificationCodeTagManager,
  phoneNumberVerifySkipFlowTagManager,
} from '@/utils/ThirdParties/tagManager';
import isValidJsonString from '@/utils/Validators/isValidJsonString';
import styles from './styles';

import { queryParams } from '@/utils/Application/queryParams';
import { useHistory, useLocation } from 'react-router';

import { useParams } from 'react-router-dom';
import { authActions } from '@/redux/modules/auth';
import { countryCodesEnum } from './PhoneVerify.locale';
import { COUNTRY, config } from '@/config';
import { Timer } from '../common/v1';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  BRAVO_DISABEL_SINGAPORE_FONE, BRAVO_TIMER_VALIDATE_PHONE, USER_DATA_CHANGE_PHONE_FLOW, BRAVO_SMS_TIMER_DISABLE_ON_SEND,
  XRAY_ENABLED_VALIDATION_FILTER_WITH_PAGE_NAME,
  CHARLIE_NEW_PHONE_CARD,
  CHARLIE_CHECKOUT_PHONEINPUT_FLAGS,
} from '@/config/GrowthBook/constants';
import Countdown from '@/components/Labels/Countdown';
import { commonActions } from '@/redux/modules';

export const FORM_STEPS = {
  PHONE_CONFIRMATION: 'phoneConfirmation',
  CHANGE_PHONE_NUMBER: 'changePhoneNumber',
  VERIFICATION_CODE: 'verificationCode',
};

const PhoneVerify = ({ classes }) => {
  const history = useHistory();
  const phoneTimer = useFeatureIsOn(BRAVO_TIMER_VALIDATE_PHONE);
  const checkPhoneTimerOnSend = useFeatureIsOn(BRAVO_SMS_TIMER_DISABLE_ON_SEND);

  const [tokenRequestDiff, setTokenRequestDiff] = useState(0);

  const [countDownResend, setCountDownResend] = useState(tokenRequestDiff>0
    ? tokenRequestDiff
    : 0);
  const newPhoneFlow = useFeatureIsOn(USER_DATA_CHANGE_PHONE_FLOW);
  const disableSingaporeSMS = useFeatureIsOn(BRAVO_DISABEL_SINGAPORE_FONE);
  const renderNewPhoneVerify = useFeatureIsOn(CHARLIE_NEW_PHONE_CARD);
  const renderFlags = useFeatureIsOn(CHARLIE_CHECKOUT_PHONEINPUT_FLAGS);

  const [phoneCardForm, setPhoneCardForm] = useState(FORM_STEPS.PHONE_CONFIRMATION);
  const [showCountdown, setShowCountdown] = useState(false);
  const [phoneNumberAgreement, setPhoneNumberAgreement] = useState(false);
  const [values, setValues] = useState({
    codeInput: {
      value: undefined,
      error: '',
    },
    changePhoneNumberInput: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });
  const params = useParams();
  const { changeFlow } = params;

  const countdownTime = 120;
  const loadingSend = useSelector(state => state.phoneNumberVerify.loadingSend);
  const { loadingSendToken } = useSelector(state => state.auth);
  const loadingValidate = useSelector(state => state.phoneNumberVerify.loadingValidate);
  const forceUrl = useSelector(state => state.phoneNumberVerify.forceUrl);
  const clientInfo = useSelector(state => state.summary);
  const phoneNumberCountry = useSelector(state => state.summary.phoneNumberCountry);
  const loadingSummary = useSelector(state => state.summary.loading);
  const featureTogglePhoneNumberFlowSkip = useSelector(state => state.featureToggles.toggles && state.featureToggles.toggles.portal && state.featureToggles.toggles.portal.phoneNumberFlowSkip);
  const userId = useSelector(state => state.summary.id);
  const dispatch = useDispatch();
  const { id: clientId, phoneNumber } = clientInfo;

  const lastPath = localStorage.getItem('lastPath');
  const lastPaths = isValidJsonString(lastPath) ? JSON.parse(lastPath) : [];
  const isEnabledNewFilter = useFeatureIsOn(XRAY_ENABLED_VALIDATION_FILTER_WITH_PAGE_NAME);
  const location = useLocation();

  const executeFilterPath = () => {
    if (isEnabledNewFilter) {
      const selectedLocation = queryParams(location.search, 'location');

      if (selectedLocation != null && selectedLocation !== '') {
        return { id: userId, location: selectedLocation };
      }
    }
    return lastPaths ? lastPaths.filter(item => item.id === userId)[0] : [];
  };

  const pathFilter = executeFilterPath();
  const isValidPath = pathFilter && pathFilter.location && pathFilter.id && pathFilter.id === userId;
  const closePath = isValidPath ? pathFilter.location : locale('routes.sitesPage');

  const [newPhone, setNewPhone] = useState(null);
  const countriesCode = useSelector(state => state.countryPhoneCodes.countryCode);
  const [countryCode, setCountryCode] = useState(phoneNumberCountry);

  const defineInitialCountryOnSelect = useCallback(() => {
    switch (COUNTRY) {
      case 'co':
        setCountryCode('+57');
        break;
      case 'mx':
        setCountryCode('+52');
        break;
      case 'cl':
        setCountryCode('+56');
        break;
      case 'br':
      default:
        setCountryCode('+55');
    }
  }, []);

  const manipulateLocalStorageTokenRequested = useCallback(() => {
    const tokenRequested = localStorage.getItem('tokenRequested');
    const tokenDiff = differenceInSeconds(new Date(tokenRequested), new Date());

    if (tokenRequested) {
      if (tokenDiff > 0) {
        setTokenRequestDiff(tokenDiff);
        setCountDownResend(tokenDiff);
      }

      if (tokenDiff <= 0) {
        setTokenRequestDiff(0);
        setCountDownResend(0);
        localStorage.removeItem('tokenRequested');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCardForm]);

  useEffect(() => {
    manipulateLocalStorageTokenRequested();
  }, [manipulateLocalStorageTokenRequested]);

  useEffect(() => {
    renderNewPhoneVerify && defineInitialCountryOnSelect();
  }, [renderNewPhoneVerify, defineInitialCountryOnSelect]);

  useEffect(() => {
    if (!closePath) {
      manipulateLocalStorageTokenRequested();
      setPhoneCardForm(FORM_STEPS.CHANGE_PHONE_NUMBER);
    }
  }, [phoneNumber, setPhoneCardForm, closePath, manipulateLocalStorageTokenRequested]);

  useEffect(() => {
    if (changeFlow === 'alterar') {
      manipulateLocalStorageTokenRequested();
      setPhoneCardForm(FORM_STEPS.CHANGE_PHONE_NUMBER);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFlow]);

  useEffect(() => {
    loadReCaptchaPaaS();
  }, []);

  const optionsValidate = {
    abortEarly: false,
  };
  const schemaPhone = newPhoneFlow
    ? yup.object().shape({
      changePhoneNumberInput: yup
        .string()
        .min(6)
        .max(15)
        .required('required'),
      password: yup
        .string()
        .min(6)
        .required('required'),
    })
    : yup.object().shape({
      changePhoneNumberInput: yup
        .string()
        .min(8)
        .max(15)
        .required('required'),
    });

  const schemaCode = yup.object().shape({
    codeInput: yup
      .number()
      .typeError()
      .required('required'),
  });

  const updateField = (event) => {
    const { name } = event.target;

    if (name === 'countryCode') {
      setCountryCode(event.target.value);
    }

    if (name !== 'countryCode') {
      const newValues = {
        ...values,
        [event.target.name]: {
          ...values[event.target.name],
          value: event.target.value,
          error: '',
        },
      };

      if (name === 'changePhoneNumberInput' && countryCode === countryCodesEnum.SINGAPORE && disableSingaporeSMS) {
        newValues.changePhoneNumberInput.error = locale('phoneVerify.errors.countryCode');
      }

      setValues(newValues);
    }
  };

  const closePhoneFlow = () => {
    dispatch(phoneNumberFlowSkip(clientId));
    dispatch(phoneNumberChangeFlow(false));
    phoneNumberVerifySkipFlowTagManager();
  };

  const setNewPhoneDispatch = () => {
    const clientPhoneNumberNewClear = phoneNumber.replace(/\D/g, '');
    const newClientPhoneNumberNewClear = values.changePhoneNumberInput.value.replace(/\D/g, '');

    dispatch(changePhoneNumber({
      clientId,
      clientPhoneNumberNew: newClientPhoneNumberNewClear || clientPhoneNumberNewClear,
      countryCode,
      clientPhoneAgreement: phoneNumberAgreement,
    })).then((response) => {
      if (response.type === RECEIVE_CHANGE_PHONE_NUMBER) {
        dispatch(loadSummary());
        changePhoneNumberTagManager();

        if (newPhoneFlow) {
          dispatch(commonActions.notifications.set({
            label: locale('phoneVerify.feedback.changePhoneNumberSuccess'),
            type: 'success',
          }));
          dispatch(setPhoneVerified(true));
          closePhoneFlow();
        } else {
          setPhoneCardForm(FORM_STEPS.PHONE_CONFIRMATION);
        }
      } else if (response.type === ERROR_CHANGE_PHONE_NUMBER) {
        dispatch(commonActions.notifications.set({
          label: locale('phoneVerify.feedback.changePhoneNumberError'),
          type: 'error',
        }));
      }
      setShowCountdown(true);
    });
  };

  const handleSendPhoneCode = async ({ clientPhoneNumberNewClear }) => {
    const assembledCountryCode = countryCode.replace('+', '');
    const dial = renderNewPhoneVerify && countryCode.includes('+') ? assembledCountryCode : countriesCode[countryCode].code;
    let phoneNumber = clientPhoneNumberNewClear;
    const recaptchaToken = await generateRecaptchaToken(config.RECAPTCHA_PAAS, 'changePhoneNumber');

    if (!phoneNumber) {
      phoneNumber = newPhone || null;
    }

    if (countDownResend === 0) {
      dispatch(authActions.user.phoneToken.send({
        clientId,
        password: values.password.value,
        countryCode: dial,
        phoneNumber,
        clientPhoneAgreement: phoneNumberAgreement,
        recaptchaToken,
        phoneTimer,
        setVerificationStep: () => {
          if (phoneTimer) {
            localStorage.setItem('tokenRequested', addSeconds(new Date(), countdownTime));
          }
          setPhoneCardForm(FORM_STEPS.VERIFICATION_CODE);
          phoneNumberSendVerificationCodeTagManager();
        },
        checkPhoneTimerOnSend,
        setPendingCounter: (pendingAmount) => {
          if (pendingAmount) {
            const tokenDiff = differenceInSeconds(new Date(pendingAmount), new Date());
            setCountDownResend(tokenDiff);
            localStorage.setItem('tokenRequested', new Date(pendingAmount));
            if (renderNewPhoneVerify) {
              setPhoneCardForm(FORM_STEPS.VERIFICATION_CODE);
            }
          } else {
            setCountDownResend(countdownTime);
            localStorage.setItem('tokenRequested', addSeconds(new Date(), countdownTime));
            if (renderNewPhoneVerify) {
              setPhoneCardForm(FORM_STEPS.VERIFICATION_CODE);
            }
          }
        },
      }));
    }
  };

  const checkChangePhoneValidations = (clientPhoneNumberNew, action) => {
    const clientPhoneNumberNewClear = clientPhoneNumberNew.replace(/\D/g, '');

    schemaPhone.validate({ changePhoneNumberInput: clientPhoneNumberNewClear, password: values.password.value }, optionsValidate).then(() => {
      if (action === 'formSubmit') {
        if (newPhoneFlow) {
          setNewPhone(clientPhoneNumberNewClear);
          handleSendPhoneCode({ clientPhoneNumberNewClear });
        } else {
          setNewPhoneDispatch();
        }
      }
    }).catch((validationError) => {
      validationError && validationError.inner.forEach((error) => {
        let errorMessage;
        switch (error.type) {
          case 'required':
            errorMessage = locale('phoneVerify.errors.requiredCode');
            break;
          case 'min':
            if (!renderNewPhoneVerify) {
              errorMessage = locale('phoneVerify.errors.passwordMinimumCharacters');
            } else {
              errorMessage = '';
            }
            break;
          default:
            errorMessage = locale('phoneVerify.errors.invalidPhoneNumber');
            break;
        }
        setValues(prevValues => ({
          ...prevValues,
          [error.path]: {
            ...prevValues[error.path],
            error: errorMessage,
          },
        }));
      });
    });
  };

  const checkCodeValidations = (verificationCode, action) => {
    schemaCode.validate({ codeInput: verificationCode }, optionsValidate).then(() => {
      if (action === 'formSubmit') {
        dispatch(validatePhoneCode(
          verificationCode,
        )).then((response) => {
          if (response.type === RECEIVE_VALIDATE_PHONE_CODE) {
            if (values.changePhoneNumberInput.values && values.password.values) {
              setNewPhoneDispatch();
            } else {
              dispatch(commonActions.notifications.set({
                label: locale('phoneVerify.feedback.changePhoneNumberSuccess'),
                type: 'success',
              }));
              dispatch(setPhoneVerified(true));
              closePhoneFlow();
            }
            phoneNumberCodeValidTagManager();
          } else if (response && response.data && response.data.response.data.data === 'Token not found.') {
            dispatch(commonActions.notifications.set({
              label: locale('phoneVerify.feedback.invalidToken'),
              type: 'error',
            }));
          } else if (response.type === ERROR_VALIDATE_PHONE_CODE) {
            dispatch(commonActions.notifications.set({
              label: locale('phoneVerify.feedback.validateCodeError'),
              type: 'error',
            }));
          }
          setShowCountdown(true);
        });
      }
    }).catch((validationError) => {
      validationError && validationError.inner.forEach((error) => {
        setValues(prevValues => ({
          ...prevValues,
          [error.path]: {
            ...prevValues[error.path],
            error: error.type === 'required'
              ? locale('phoneVerify.errors.requiredCode')
              : locale('phoneVerify.errors.invalidCode'),
          },
        }));
      });
    });
  };

  const handleChangePhoneNumber = (data) => {
    if (!renderNewPhoneVerify) {
      data.preventDefault();
      const selectedCountryCode = data.target.selectCountryCode.value;
      setCountryCode(selectedCountryCode);
    }

    checkChangePhoneValidations(values.changePhoneNumberInput.value, 'formSubmit');
  };

  const handleValidateCode = (event) => {
    event.preventDefault();
    checkCodeValidations(values.codeInput.value, 'formSubmit');
  };

  const handleShowCountdown = (show) => {
    setShowCountdown(show);
  };
  const handleCancel = () => {
    if (history.length) {
      dispatch(phoneNumberChangeFlow(false, forceUrl));
      history.push(forceUrl);
    }

    if (!history.length) {
      manipulateLocalStorageTokenRequested();
      setPhoneCardForm(FORM_STEPS.PHONE_CONFIRMATION);
    }
  };

  const handleShowChangePhoneNumberForm = () => {
    manipulateLocalStorageTokenRequested();
    setPhoneCardForm(FORM_STEPS.CHANGE_PHONE_NUMBER);
  };

  const handleValidateDdi = useCallback(() => {
    if (countryCode === countryCodesEnum.SINGAPORE) {
      setValues({
        ...values,
        changePhoneNumberInput: {
          ...values.changePhoneNumberInput,
          error: locale('phoneVerify.errors.countryCode'),
        },
      });
    } else {
      setValues({
        ...values,
        changePhoneNumberInput: {
          value: '',
          error: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useEffect(() => {
    handleValidateDdi();
  }, [handleValidateDdi]);

  const canSendForm = useCallback(() => {
    const hasErros = values.codeInput.error.length || values.changePhoneNumberInput.error.length || values.password.error.length || countryCode === countryCodesEnum.SINGAPORE;
    const hasEmptyFields = !values.changePhoneNumberInput.value || !values.password.value;
    const cantContinue = hasErros || hasEmptyFields;

    return cantContinue;
  }, [values, countryCode]);

  const invalidClientPhoneNumber = useCallback(() => {
    if (disableSingaporeSMS) {
      const singapooreDDI = '65';
      const starts = clientInfo.phoneNumber && clientInfo.phoneNumber.startsWith(singapooreDDI || `+${singapooreDDI}`);
      return starts;
    }

    return false;
  }, [clientInfo, disableSingaporeSMS]);

  const phoneCardContent = {
    changePhoneNumber: {
      title: phoneNumber ? locale('phoneVerify.changePhoneNumber.title') : locale('phoneVerify.emptyPhoneNumber.title'),
      subtitle: phoneNumber ? locale('phoneVerify.changePhoneNumber.subtitle') : locale('phoneVerify.emptyPhoneNumber.subtitle'),
    },

    verificationCode: {
      title: locale('phoneVerify.verifyCodeForm.title'),
      subtitle: locale('phoneVerify.verifyCodeForm.subtitle')(handleCoverPhoneNumber({
        phoneNumber: newPhone || phoneNumber,
        phoneNumberCountry,
      })),
    },

    phoneConfirmation: {
      title: locale('phoneVerify.title'),
      subtitle: locale('phoneVerify.subtitle'),
    },
  };

  const sendCodeLabel = loadingSend ? locale('phoneVerify.buttons.resendingLoading') : locale('phoneVerify.buttons.resend');

  const phoneCardChild = {
    changePhoneNumber: (
      <div className={classes.contentWrapper}>
        <ChangePhoneNumber
          handleChangePhoneNumber={handleChangePhoneNumber}
          phoneNumberCountry={phoneNumberCountry}
          handleCancel={handleCancel}
          loadingChange={loadingSendToken}
          updateField={updateField}
          values={values}
          phoneNumber={phoneNumber}
          passwordDescription={locale('phoneVerify.changePhoneNumber.passwordDescription')}
          passwordLabel={locale('phoneVerify.changePhoneNumber.passwordLabel')}
          newPhoneFlow={newPhoneFlow}
          countriesCode={countriesCode}
          canSendForm={canSendForm}
          countDownResend={countDownResend}
          setCountDownResend={setCountDownResend}
        />
      </div>
    ),
    verificationCode: (
      <div className={classes.phoneContentWrapper}>
        <VerificationCode
          handleValidateCode={handleValidateCode}
          loadingValidate={loadingValidate}
          updateField={updateField}
          values={values}
        />
        <div className={classes.footer}>
          {showCountdown ? (
            <>
              <Typography>
                {locale('phoneVerify.codeNotRecived')}
              </Typography>
              <Typography className={classes.countdownText}>
                {locale('phoneVerify.resendText')}
                <span className={classes.countdown}>
                  <Countdown countdownTime={countdownTime} handleShowCountdown={handleShowCountdown} />
                </span>
              </Typography>
            </>
          ) : (
            <div className={classes.wrapperSendCodeBoxLinks}>
              <Typography>{locale('phoneVerify.codeNotRecived')}</Typography>
              <OutlineButton
                className={classes.button}
                onClick={handleShowChangePhoneNumberForm}
                disabled={loadingSend}
              >
                {locale('phoneVerify.buttons.changePhoneNumber')}
              </OutlineButton>
              <PrimaryButton
                className={classes.button}
                onClick={handleSendPhoneCode}
                disabled={loadingSend || loadingSendToken || countDownResend > 0}
              >
                {countDownResend > 0
                  && <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
                }

                {(loadingSend || loadingSendToken) && <CircularProgress className={classes.loading} size={20} />}
                {countDownResend <= 0 && sendCodeLabel}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    ),
    phoneConfirmation: (
      <div className={classes.phoneVerifyContainer}>
        <PhoneConfirmation
          phoneNumber={handleCoverPhoneNumber({
            phoneNumber: newPhone || phoneNumber,
            phoneNumberCountry,
          })}
          handleSendPhoneCode={handleSendPhoneCode}
          setPhoneCardForm={setPhoneCardForm}
          loadingSend={loadingSendToken}
          loadingSummary={loadingSummary}
          invalidClientPhoneNumber={invalidClientPhoneNumber}
          countDownResend={countDownResend}
          setCountDownResend={setCountDownResend}
        />

        <Typography>
          <Checkbox
            checked={phoneNumberAgreement}
            onChange={() => setPhoneNumberAgreement(!phoneNumberAgreement)}
          />
          {locale('phoneVerify.phoneAgreement')}
        </Typography>
      </div>
    ),
  };


  if (renderNewPhoneVerify && phoneCardForm !== FORM_STEPS.PHONE_CONFIRMATION) {
    return (
      <NewPhoneCard
        canSendForm={canSendForm}
        closePhoneFlow={closePhoneFlow}
        countDownResend={countDownResend}
        featureToggleEmailNumberFlowSkip={featureTogglePhoneNumberFlowSkip}
        handleChangePhoneNumber={handleChangePhoneNumber}
        handleSendPhoneCode={handleSendPhoneCode}
        handleValidateCode={handleValidateCode}
        loadingChange={loadingSendToken}
        loadingValidate={loadingValidate}
        phoneCardForm={phoneCardForm}
        setCountDownResend={setCountDownResend}
        setPhoneCardForm={setPhoneCardForm}
        updateField={updateField}
        values={values}
        waitingToResend={loadingSend || countDownResend > 0}
        threatedPhoneNumber={handleCoverPhoneNumber({ phoneNumber: newPhone || phoneNumber, phoneNumberCountry })}
        renderFlags={renderFlags}
        countryCode={countryCode}
      />
    );
  }

  return (
    <div className={classes.main} data-id={phoneCardForm}>
      <PhoneCard
        title={phoneCardContent[phoneCardForm].title}
        subtitle={phoneCardContent[phoneCardForm].subtitle}
      >
        {phoneCardChild[phoneCardForm]}
        {featureTogglePhoneNumberFlowSkip && (
          <LinkButton
            data-id={`close-${phoneCardForm}`}
            to={closePath}
            className={classes.link}
            onClick={() => closePhoneFlow()}
          >
            <Close />
          </LinkButton>
        )}
      </PhoneCard>
    </div>
  );
};

export default withI18n()(withStyles(styles)(PhoneVerify));
