import React from 'react';
import { withStyles } from '@material-ui/core';
import { IconCopy } from '@/icons';
import { copyTableToClipboard } from '@/utils/Application/copyTableToClipboard';
import styles from './styles';
import { withI18n } from 'react-i18next';


const CopyDnsButton = ({
  classes,
  children,
  items,
  columns,
  t,
}) => {
  const handleCopy = () => {
    copyTableToClipboard(items, columns, t);
  };
  return (
    <div className={classes.dnsWizardLegacyDnsTableCopyButton} onClick={handleCopy} aria-hidden="true">
      <p>{children}</p>
      <IconCopy size={16} />
    </div>
  );
};

export default withI18n()(withStyles(styles)(CopyDnsButton));
