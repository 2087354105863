
import React from 'react';

export default {
  emailNotRecived: 'Não recebeu o e-mail?',
  resendText: 'Enviar novamente em ',
  validateEmail: {
    title: 'Confirme seu e-mail',
    description: ({ email }) => (
      <>
        {'Para sua segurança, enviamos um link para '}
        <strong>{email}</strong>
        . Acesse seu e-mail para finalizar o procedimento.
      </>
    ),
    tryResendDescription: 'Tente o reenvio do e-mail de validação ou abra um chamado para que possamos validar que a conta é sua e então fazermos a troca do e-mail de cadastro.',
    resendButtonLabel: 'Reenviar e-mail de validação',
    changeEmail: 'Alterar e-mail',
  },
  changeMail: {
    tip: (
      <span>
        <strong>Dica:</strong>
        {' Use um e-mail pessoal (ex: @gmail) para não correr o risco de perder o acesso.'}
      </span>
    ),
    title: 'Altere seu e-mail de cadastro',
    description: ({ email }) => (
      <>
        {'Por segurança, enviaremos um link para o e-mail atual '}
        <strong>{email}</strong>
        {' para que você valide a troca.'}
      </>
    ),
    form: {
      mailLabel: 'Novo e-mail',
      mailPlaceholder: 'Digite seu e-mail',
      checkEmailLabel: 'Confirme seu novo e-mail',
      checkEmailPlaceholder: 'Digite novamente seu e-mail',
      passwordLabel: 'Senha do portal do cliente',
      passwordPlaceholder: 'Digite sua senha',
      errors: {
        invalidEmail: 'Digite um email válido',
        emailsDoesntMatch: 'Ops! Parece que os e-mails não estão iguais.',
        requiredEmail: 'Campo obrigatório',
        passwordTooShort: 'A senha deve ter no mínimo 6 caracteres.',
      },
      submitLabel: 'Enviar e-mail de validação',
    },
    noAccessTitle: 'Não tem mais acesso ao seu e-mail atual?',
    noAccessDescription: 'Será necessário abrir um chamado para que possamos validar que a conta é sua e então fazermos a troca do e-mail de cadastro.',
    noAccessForm: 'Abrir chamado',
  },
  sentEmail: {
    title: 'Valide a alteração do e-mail de cadastro',
    description: ({ email }) => (
      <>
        {'Acesse o seu e-mail atual '}
        <strong>{email}</strong>
        {' e no e-mail que enviamos, valide a alteração.'}
      </>
    ),
    tryResendDescription: 'Tente o reenvio do e-mail de validação ou abra um chamado para que possamos validar que a conta é sua e então fazermos a troca do e-mail de cadastro.',
    resendButtonLabel: 'Reenviar e-mail de validação',
    openFormButtonLabel: 'Abrir chamado',
  },
  feedback: {
    emailSendAttempts: ({ attempts, used }) => `Você solicitou ${used} e-mail${used > 1 ? 's' : ''} nas últimas 24 horas, restam ${attempts} solicitações`,
    noMoreAttempts: () => 'Você esgotou as solicitações de confirmação, tente novamente amanhã',
    resendError: 'Não foi possível fazer o reenvio neste momento. Por favor, mude o e-mail ou tente novamente mais tarde.',
    changeSuccess: 'Verifique seu e-mail e clique no link de confirmação.',
    dataInUse: 'O e-mail informado já está em uso no seu cadastro. Para prosseguir, informe outro e-mail.',
    password: 'Senha incorreta. Por favor, tente novamente.',
    emailInDataBase: 'Ops! Este e-mail já está cadastrado. Por favor, escolha um novo endereço de e-mail.',
    alreadyValidated: ({ hours, date }) => `E-mail confirmado à menos de 24 horas, você poderá alterar este e-mail às ${hours} horas do dia ${date}.`,
    changeError: 'Tente novamente mais tarde ou altere o e-mail.',
    resendSuccess: 'Reenviamos. Por favor, verifique seu e-mail novamente.',
  },
};
