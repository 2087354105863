/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { IconDanger, Link } from 'gatorcomponents';
import { TOS_STATUS } from '@/enums/preChat/preChat.enum';
import {
  BLOG_URL, HELP_CENTER_URL, YOUTUBE_URL, DO_YOUR_ON_BACKUP, REGISTROBR_HOMEPAGE,
} from '@/config/urls/supportUrls';
import { format, isSameDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { COUNTRY } from '@/config';

export default {
  listPageFaq: {
    title: 'Links de ajuda',
    filterLabel: 'Filtrar por',
    filterByPlaceholder: 'Busque por palavra-chave',
    clearFilterLabel: 'Limpar filtros',
    helpLinks: {
      filter: {
        chips: {
          main: 'Principais dúvidas',
          financial: 'Financeiro',
          domain: 'Domínio e DNS',
          mail: 'E-mail',
          site: 'Site',
        },
      },
    },
    listDescription: (
      <>
        {'Veja abaixo os principais conteúdos, '}
        <br />
        {'mas não deixe de visitar nosso '}
        <Link href={BLOG_URL} text="Blog" />
        {', a '}
        <Link href={HELP_CENTER_URL} text="Central de Ajuda" />
        {' e o '}
        <Link href={YOUTUBE_URL} text="Youtube" />
        {' da HostGator :)'}
      </>
    ),
  },
  listPage: {
    title: 'Listagem de Chamados',
    filterLabel: 'Filtrar por',
    filterByPlaceholder: 'Busque por assunto',
    clearFilterLabel: 'Limpar filtros',
    openNewTicketLabel: 'Novo chamado',
    startChat: 'Fale conosco',
    listDescription: open => (
      <>
        {open > 0 ? (
          <>
            {'Você tem '}
            <strong>{`${open} chamado${open === 1 ? '' : 's'} em aberto.`}</strong>
            {' O prazo para a primeira resposta é de '}
            <strong>até 48h úteis.</strong>
            <br />
            {`Fique sempre de olho antes que ele${open === 1 ? '' : 's'} seja${open === 1 ? '' : 'm'} finalizado${open === 1 ? '' : 's'}!`}
          </>
        ) : 'Você não possui chamados em aberto.'}

      </>
    ),
    tickets: {
      status: {
        closed: 'FINALIZADO',
        solved: 'CONCLUÍDO',
        new: 'ABERTO',
        open: 'EM ABERTO',
        opened: 'EM ABERTO',
        pending: 'AGUARDANDO RESPOSTA',
      },
      numberLabel: 'Número chamado',
      openingLabel: 'Abertura',
      viewButtonLabel: 'Ver chamado',
      filter: {
        chips: {
          all: 'Todos',
          closed: 'Finalizados',
          solved: 'Concluídos',
          new: 'Aberto',
          open: 'Em aberto',
          opened: 'Abertos',
          pending: 'Aguardando resposta',
        },
      },
    },
    loadMoreLabel: 'Carregar mais',
    empty: 'Você não possui chamados',
  },
  ticketOpenModal: {
    title: 'Chamados em aberto',
    cautionText: (
      <>
        <IconDanger color="problemPure" size="lg" />
        <p>
          Você possui mais de um chamado urgente para resolver. Entre no chamado para ser orientado pelos nossos especialistas.
        </p>
      </>
    ),
    selectLabel: 'Chamados',
    selectPlaceholder: 'Selecione o chamado',
    ticketStatusLabel: status => ({
      [TOS_STATUS.DISK]: 'Alerta de espaço em disco',
      [TOS_STATUS.INODES]: 'Alerta de limite de arquivos',
      [TOS_STATUS.BACKUP]: 'Alerta de backup',
      [TOS_STATUS.MYSQL]: 'Alerta de uso de recursos do banco de dados',
      [TOS_STATUS.CPU]: 'Alerta de uso de recursos do processador',
      [TOS_STATUS.DMCA]: 'Alerta de direitos autorais',
      [TOS_STATUS.PISHING]: 'Alerta de arquivo infectado',
    })[status] || '',
    ticketWarningText: status => ({
      [TOS_STATUS.DISK]: (
        <p>
          <strong>{'Alerta de espaço em disco: '}</strong>
          Seu espaço em disco está cheio! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.INODES]: (
        <p>
          <strong>{'Alerta de limite de arquivos: '}</strong>
          A conta chegou ao limite de arquivos e diretórios! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.BACKUP]: (
        <p>
          <strong>{'Alerta de backup: '}</strong>
          Você só pode armazenar um backup em sua conta! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.MYSQL]: (
        <p>
          <strong>{'Alerta de uso de recursos do banco de dados: '}</strong>
          Seu banco de dados MySQL atingiu o limite de uso de recursos! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.CPU]: (
        <p>
          <strong>{'Alerta de uso de recursos do processador: '}</strong>
          Seu uso de recursos do processador chegou ao limite! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.DMCA]: (
        <p>
          <strong>{'Alerta de direitos autorais: '}</strong>
          Existe um problema relacionado a direitos autorais em seu site! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
      [TOS_STATUS.PISHING]: (
        <p>
          <strong>{'Alerta de arquivo infectado: '}</strong>
          Detectamos conteúdos potencialmente perigosos em seu plano de hospedagem! Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
        </p>
      ),
    })[status] || '',
    goToTicketBtnText: 'Ir para o chamado',
  },
  ticketUpgradeVpsModal: {
    title: 'Upgrade de VPS',
    firstLabel: () => (
      <p>
        Esse tipo de upgrade é somente realizado através de
        <strong>{' abertura de chamado '}</strong>
        com o atendimento de um especialista.
      </p>
    ),
    secondLabel: () => (
      <p>
        Por isso,
        <strong>{' preencha as informações faltantes '}</strong>
        (IP do Servidor, Novo produto e Horário para execução) e envie o formulário de abertura de chamado. Assim que um especialista atender,
        <strong>{' a resposta será enviada por e-mail.'}</strong>
      </p>
    ),
    buttonLabel: 'Ok, entendi',
  },
  openNewTicketPage: {
    title: 'Preencha as informações abaixo',
    selecteProductOptionPlaceholder: 'Para qual produto/serviço deseja suporte? *',
    selecteSectorOptionPlaceholder: 'Qual o setor? *',
    sectorBilling: 'Financeiro',
    sectorTechnical: 'Técnico',
    selecteNeedOptionPlaceholder: 'Qual a sua necessidade? *',
    textManipulation: {
      startsWith: {
        billing: 'Suporte Financeiro',
        technical: 'Suporte Técnico',
        myAccount: 'Minha Conta',
        afiliated: 'Programa de Afiliados',
        nationalDomainTransfer: 'transferência de domínio nacional',
      },
    },
    selectProductGroups: {
      myAccount: 'Minha Conta',
      activeProducts: 'Produtos/Serviços Ativos',
      suspendedProducts: 'Produtos/Serviços Suspensos',
    },
  },
  forms: {
    error: 'Não foi possível abrir o chamado no momento. Por favor, tente mais tarde :)',
    terms: (
      <>
        {'Leia com atenção os '}
        <strong>termos</strong>
        {' a seguir:'}
      </>
    ),
    submitLabel: 'Abrir chamado',
    internalMigration: {
      title: 'Preencha o formulário abaixo',
      originTitle: 'Dados da origem:',
      destinationTitle: 'Dados do destino:',
      links: {
        migration: () => (
          <>
            {'Leia os nossos '}
            <Link
              text="Termos de Migração"
              href="https://www.hostgator.com.br/termos-de-migracao"
              target="_blank"
            />
            .
          </>
        ),
        compatibility: () => (
          <>
            {'Veja também '}
            <Link
              text="quais são as nossas compatibilidades"
              href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000384693"
              terget="_blank"
            />
            {' para ver se está adequado a sua necessidade.'}
          </>
        ),
      },
      warning: {
        title: 'ATENÇÃO:',
        item: {
          0: () => (
            <li>NÃO CANCELE seu plano antigo até que os arquivos tenham sido migrados, testados e o DNS alterado corretamente.</li>
          ),
          1: () => (
            <li>Lembre-se de aguardar o prazo de propagação, de 24h à 72h de dependendo da extensão de seu domínio.</li>
          ),
          2: () => (
            <li>Não nos responsabilizamos pelo conteúdo migrado, pois não temos controle de versão.</li>
          ),
        },
      },
      checkbox: {
        terms: () => (
          <>
            {'Confirmo que '}
            <strong>li e concordo</strong>
            {' com os '}
            <Link
              text="Termos de Migração."
              target="_blank"
              href="https://www.hostgator.com.br/termos-de-migracao"
            />
          </>
        ),
        agreed: () => (
          <>
            {'Confirmo que '}
            <strong>concordo</strong>
            {' com eventual cobrança se não me adequar aos limites de migração descritos no termo.'}
          </>
        ),
      },
      migrationTypeText: 'Tipo de migração',
      partialMigration: 'Migração Parcial',
      fullMigration: 'Migração completa',
      whatToBeMigrated: 'O que deve ser migrado?',
      name: 'Migração Interna',
      alert: 'Este formulário é para migração de toda a conta. Ao solicitar a migração, todo o conteúdo do site e/ou, banco de dados serão substituídos pelo conteúdo migrado, não podendo voltar ao conteúdo anterior, se você possui mais de um plano de hospedagem certifique-se que está solicitando para o produto correto.',
      originDomainPlaceholder: 'Selecione entre os seus planos ativos',
      originDomainLabel: 'Domínio principal *',
      originServerPlaceholder: 'Digite o servidor aqui',
      originServerLabel: 'Servidor',
      originUserPlaceholder: 'Digite o usuário aqui',
      originUserLabel: 'Usuário',
      myServerLabelRadio: 'Para o meu painel HostGator',
      otherServerLabelRadio: 'Para o outro painel HostGator',
      myDomainPlaceholder: 'Selecione o domínio principal aqui',
      myDomainLabel: 'Domínio principal*',
      myServerPlaceholder: 'Digite o servidor aqui',
      myServerLabel: 'Servidor',
      myUserPlaceholder: 'Digite o usuário aqui',
      myUserLabel: 'Usuário',
      otherDomainPlaceholder: 'Digite o domínio principal',
      otherDomainLabel: 'Domínio principal*',
      otherServerPlaceholder: 'Digite o servidor aqui',
      otherServerLabel: 'Servidor',
      otherUserPlaceholder: 'Digite o usuário aqui',
      otherUserLabel: 'Usuário',
      body: {
        originServer: 'Servidor de origem',
        originUser: 'Usuário de origem',
        destinyServer: 'Servidor de destino',
        destinyUser: 'Usuário de destino',
        agree: 'Concordo com os termos',
        agreePayment: 'Concordo com uma eventual cobrança',
        yes: 'Sim',
        no: 'Não',
      },
    },
    restorateBackupHgRoutine: {
      terms: (
        <>
          {'Leia com atenção os '}
          <strong>termos</strong>
          {' a seguir:'}
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li>
              {'Há uma '}
              <strong>cobrança no valor de R$100,00</strong>
              {' para este procedimento. '}
              <strong>Ao término da restauração será gerada a cobrança na sua conta.</strong>
            </li>
            <li>Não temos controle de versão, portanto, não nos responsabilizamos pelo conteúdo restaurado.</li>
            <li>
              {'Caso seus e-mails sejam Titan, não fazemos restauração de backup. Você é o responsável por '}
              <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/1260804497929" text="fazer o seu próprio backup." />
            </li>
            <li>Caso o seu site tenha sido desenvolvido com o Criador de Sites da HostGator, depois você deverá solicitar a restauração do editor através do formulário “Restauração de acesso ao editor criador de sites”.</li>
          </ul>
        </>
      ),
      alert: {
        title: 'ATENÇÃO!',
        description: (
          <>
            {'Este formulário é para restauração de toda a conta. Ao solicitar a restauração, todo o conteúdo do site e/ou banco de dados serão substituídos pelo conteúdo restaurado, '}
            <strong>não podendo voltar ao conteúdo anterior.</strong>
          </>
        ),
      },
      title: 'Preencha os dados abaixo',
      selectRestorationType: {
        full: 'Completa',
        database: 'Banco de dados',
        file: 'Arquivos',
        domain: 'Domínio',
      },
      fields: {
        restorationType: 'Tipo da Restauração',
        user: 'Digite o usuário aqui',
        userLabel: 'Usuário',
        server: 'Digite o servidor aqui',
        serverLabel: 'Servidor',
        domain: 'Digite o domínio aqui',
        domainLabel: 'Domínio',
        backup: 'Escolha uma das datas para restaurar',
        specificDomains: 'Informe abaixo os domínios que deseja restaurar separados por vírgula',
        specificDatabase: 'Informe abaixo os Bancos que deseja restaurar separados por vírgula',
        specificFiles: 'Informe abaixo os nomes dos arquivos deseja restaurar separados por vírgula',
      },
      options: {
        daily: 'Diário',
        monthly: 'Mensal',
        weekly: 'Semanal',
      },
      selectBackupTitle: 'Backups disponíveis para serem restaurados:',
      months: {
        0: 'Janeiro',
        1: 'Fevereiro',
        2: 'Março',
        3: 'Abril',
        4: 'Maio',
        5: 'Junho',
        6: 'Julho',
        7: 'Agosto',
        8: 'Setembro',
        9: 'Outubro',
        10: 'Novembro',
        11: 'Dezembro',
      },
      agreed: (
        <>
          {'Confirmo que '}
          <strong>li e concordo</strong>
          {' com os termos acima e com a cobrança na minha conta do valor de R$100,00.'}
        </>
      ),
      open: 'Abrir chamado',
      body: {
        productId: 'ID do produto',
        server: 'Servidor',
        user: 'Usuário',
        domain: 'Domínio',
        backupName: 'Nome do backup utilizado para restauração',
        uninformed: 'não informado',
        agree: 'Concordo com os termos',
        yes: 'Sim',
        no: 'Não',
      },
    },
    generateBackup: {
      terms: (
        <>
          {'Leia com atenção os '}
          <strong>termos</strong>
          {' a seguir:'}
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li>
              O arquivo de backup será enviado por e-mail, e você poderá acompanhar o andamento na tela de Suporte, na área de Chamados.
            </li>
          </ul>
        </>
      ),
      title: 'Preencha os dados abaixo',
      fields: {
        user: 'Digite o usuário aqui',
        userLabel: 'Usuário',
        server: 'Digite o servidor aqui',
        serverLabel: 'Servidor',
        domain: 'Digite o domínio aqui',
        domainLabel: 'Domínio',
      },
      agreed: (
        <>
          {'Confirmo que '}
          <strong>li e concordo</strong>
          {' com os termos de geração de backup acima.'}
        </>
      ),
      open: 'Abrir chamado',
      body: {
        productId: 'ID do produto',
        server: 'Servidor',
        user: 'Usuário',
        agree: 'Concordo com os termos',
        yes: 'Sim',
        no: 'Não',
      },
    },
    userBackupRestore: {
      name: 'Restaurar backup - Cópia do cliente',
      title: 'Preencha os dados abaixo',
      restorationType: 'Tipo da Restauração',
      completeRestoration: 'Completa',
      databaseRestoration: 'Banco de dados',
      filesRestoration: 'Arquivos',
      domainRestorarion: 'Domínio',
      alert: 'Este formulário é para solicitar a restauração de toda a conta. Todo o conteúdo do site e/ou banco de dados serão substituídos pelo conteúdo restaurado, não podendo voltar ao conteúdo anterior. Se você possui mais de um plano de hospedagem, certifique-se que está solicitando para o produto correto.',
      fileNameInputLabel: 'Informe o nome do arquivo de backup a ser restaurado',
      fileNameInputPlaceholder: 'Digite o nome do arquivo de backup',
      databaseNameInputLabel: 'Nome do Banco de dados',
      databaseNameInputPlaceholder: 'Digite o nome do banco de dados',
      checkboxAgreed: (
        <>
          Confirmo que
          <strong>{' li e concordo '}</strong>
          com os termos de restauração de backup acima.
        </>
      ),
      warning: {
        title: (
          <strong>ATENÇÃO!</strong>
        ),
        item: {
          0: (
            <li>
              {'Certifique-se de realizar o Upload do arquivo de backup para o cPanel antes de solicitar a restauração. '}
              <Link text="'Como realizar upload de arquivos'" href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000909954-Como-transferir-arquivos-para-a-hospedagem-pelo-FileZilla-" />
            </li>
          ),
          1: (
            <li>Não temos controle de versão, portanto, não nos responsabilizamos pelo conteúdo restaurado.</li>
          ),
          2: (
            <li>
              {'Caso seus emails sejam Titan, não fazemos restauração de backup. Você é o responsável por '}
              <Link text="fazer o seu próprio backup." href="https://suporte.hostgator.com.br/hc/pt-br/articles/1260804497929" />
            </li>
          ),
          3: (
            <li>Caso o seu site tenha sido desenvolvido com o Criador de Sites da HostGator, depois você deverá solicitar a restauração do editor através do formulário “Restauração de acesso ao editor criador de sites”.</li>
          ),
        },
      },
      selectedProduct: 'Produto selecionado',
      backupFileName: 'Arquivo de backup',
      databaseFileName: 'Banco de dados',
      domain: 'Digite o domínio aqui',
      domainLabel: 'Domínio',
      server: 'Digite o servidor aqui',
      serverLabel: 'Servidor',
      user: 'Digite o usuário aqui',
      userLabel: 'Usuário',
      agree: 'Concordo com os termos',
      yes: 'Sim',
      no: 'Não',
    },
    withoutEmailAccess: {
      name: 'Não tenho acesso ao e-mail cadastrado',
      title: 'Preencha os dados abaixo',
      info: 'Para garantir a segurança da sua conta e prosseguir com a alteração do seu e-mail cadastrado, solicitamos que preencha as informações abaixo. Após o envio, nossa equipe de suporte entrará em contato em até 48 horas.',
      emailLabel: 'E-mail de cadastro',
      documentLabel: 'CPF/CNPJ do titular da conta',
      contactEmailPlaceholder: 'Digite aqui um e-mail válido para que possamos entrar em contato com você.',
      contactEmailLabel: 'E-mail para contato',
      addDocumentLabel: 'Para concluir o processo de verificação, solicitamos que você envie uma foto segurando o documento de identificação (RG ou CNPJ/contrato social) utilizado no cadastro da conta. Por favor, clique no botão "Anexar documento" abaixo para fazer o upload do arquivo. Aceitamos os formatos JPEG, PNG e PDF.',
      addDocumentButton: 'Anexar documento',
      descriptionLabel: 'Por favor, descreva sua solicitação em detalhes no campo abaixo',
      emailError: 'Digite um email válido',
      body: {
        registeredEmail: 'E-mail cadastrado',
        document: 'Documento',
        contactEmail: 'E-mail de contato',
        description: 'Descrição do problema',
      },
    },
    changePrimaryDomain: {
      title: 'Preencha os dados abaixo',
      info: isHosting => (
        <>
          <strong>Para a alteração de domínio principal, verifique os 2 passos a seguir:</strong>
          <ul>
            <li>
              {'O novo domínio precisa estar registrado. Se necessário, '}
              <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000636134" text="veja Como registrar um domínio" />
              .
            </li>
            <li>
              {isHosting ? (
                <>
                  {'O domínio não deve estar adicionado no cPanel. Se o domínio já estiver adicionado e contiver conteúdo (arquivos do site e e-mails), '}
                  <Link
                    href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173#cpanel"
                    text="faça um backup desses dados"
                    target="_blank"
                  />
                  {' e, em seguida, '}
                  <Link
                    href="https://suporte.hostgator.com.br/hc/pt-br/articles/115002113493"
                    text="remova o domínio do cPanel"
                    target="_blank"
                  />
                  {' antes de prosseguir com a sua solicitação.'}
                </>
              )
                : (
                  <>
                    {'O domínio deve estar adicionado em uma instância do cPanel. Sendo assim, '}
                    <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000388613-Como-criar-uma-conta-no-WHM-" text="crie a conta no servidor" />
                    {' antes de prosseguir com a sua solicitação.'}
                  </>
                )
              }
            </li>
          </ul>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              Caso o site tenha sido desenvolvido com o Criador de Sites da HostGator, ao alterar o domínio principal, você perderá acesso à ferramenta de edição. Para recuperar o acesso será necessário criar um novo site.
            </li>
          </ul>
        </>
      ),
      newDomainLabel: 'Qual o nome do novo domínio registrado que será usado como principal da hospedagem?',
      whyLabel: 'Motivo da mudança',
      agreedLabel: 'Confirmo que li e concordo com os termos acima e autorizo a alteração de domínio principal.',
      productId: 'ID do produto',
      solicitation: 'Solicitação',
      product: 'Produto',
      yes: 'Sim',
      no: 'Não',
      userLabel: 'Usuário',
      subject: 'Suporte Técnico - Alteração de domínio primário',
    },
    installExternalSsl: {
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>{'No campo abaixo, para preenchimento da URL, cuide para informar o endereço correto, '}</strong>
              pois o certificado não pode ser instalado para um domínio diferente do emitido;
            </li>
            <li>
              <strong>{'Caso possua um Servidor Dedicado Windows anterior a 2012, '}</strong>
              a instalação deste SSL exigirá um IP Dedicado disponível. Como o IP será alterado,
              <strong>{' é possível que tenha instabilidades no acesso por até 4h após a instalação'}</strong>
              ;
            </li>
            <li>
              <strong>A instalação deste 'SSL de outra empresa' tem uma taxa de instalação de R$60,00</strong>
              ;
            </li>
          </ul>
        </>
      ),
      urlInputPlaceholder: 'Endereço (URL) onde o SSL irá funcionar *',
      urlInputLabel: 'O SSL funcionará apenas na URL informada, que pode ser um domínio ou subdomínio. Por exemplo, use nomedodominio.com.br ou loja.nomedodominio.com.br',
      sslCertificate: 'Certificado SSL *',
      addDocumentButton: 'Anexar arquivo',
      sslCertififateFileType: 'Arquivo com extensão .crt',
      sslCertificateCA: 'Insira o conteúdo do arquivo do Certificado SSL CA (Trusted Authority) no campo abaixo',
      privateKey: 'Insira o conteúdo do arquivo da Private Key no campo abaixo *',
      orPasteContentPlaceholder: 'Se preciso, abra o arquivo em um bloco de notas, copie o conteúdo e cole aqui',
      checkboxLabel: 'Confirmo que li e concordo com os termos acima e com a cobrança de R$60,00.',
      send: 'Enviar',

      bodyFields: {
        tag: 'whmcs_form_tecnico_ssl_outra_empresa',

        product: 'Produto',
        user: 'Usuário',
        server: 'Servidor',
        solicitation: 'Solicitação',
        sslUrl: 'Endereço (URL) onde o SSL irá funcionar',
        sslCa: 'Conteúdo do arquivo do Certificado SSL CA (Trusted Authority)',
        privateKey: 'Conteúdo do arquivo da Private Key',
        agree: 'Confirmo que li e concordo com os termos acima e com a cobrança de R$60,00.',
        yes: 'Sim',
        no: 'Não',
        productId: 'ID do produto',
      },
    },
    changeDueDate: {
      info: () => (
        <>
          <p><strong>Leia com atenção os termos a seguir:</strong></p>
          <ul>
            <li>
              A nova data precisa ser
              {' '}
              <strong>de até 30 dias a partir da data de vencimento atual</strong>
              {' '}
              do serviço;
            </li>
            <li>
              Alterar a data de vencimento
              {' '}
              <strong>poderá gerar um acréscimo de valor na sua próxima fatura</strong>
              {' '}
              por conta da readequação da data.

            </li>
          </ul>
        </>
      ),
      desiredDueDate: 'Qual a data de vencimento desejada?',
      desiredDueDatePlaceHolder: 'mm/dd/yyyy',
      agreement: 'Confirmo que li e concordo com os termos acima.',
      send: 'Enviar',
    },
    financialDoubts: {
      title: 'Preencha os dados abaixo',
      info: (
        <>
          Caso não encontre a opção desejada na lista abaixo, inicie um
          {' '}
          <strong>atendimento por chat</strong>
          .
        </>
      ),
      descriptionLabel: 'Qual sua dúvida financeira?',
      addDocumentLabel: 'Possui Anexo? Nos Envie!',
      addDocumentButton: 'Anexar documento',
      solicitation: 'Solicitação',
      product: 'Produto',
      productId: 'ID do produto',
      user: 'Usuário',
      subject: 'Suporte Financeiro - Dúvidas Gerais',
      tag: 'whmcs_form_financeiro_duvidas_financeiras',
    },
    googleAdwordsCoupon: {
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>Como cliente da HostGator você tem direito a 1 cupom por cada plano de hospedagem contratado;</li>
            <li>
              Como cliente da HostGator
              <strong>{' você tem direito a um cupom por plano de hospedagem '}</strong>
              contratado;
            </li>
            <li>
              Apenas
              <strong>{' 1 crédito promocional '}</strong>
              pode ser obtido
              <strong>{' por cliente'}</strong>
              ;
            </li>
            <li>
              Você tem direito ao cupom se for novo no Google AdWords e tiver uma conta com pelo menos 14 dias de existência, ou se já for um cliente do Google AdWords e ativar um novo recurso ou produto ou ainda reativar uma conta inativa;
            </li>
            <li>
              <strong>{'Nós vamos te enviar um código promocional que deverá ser inserido em sua conta do Google Adwords '}</strong>
              - esse código não têm valor imediato, ele irá servir apenas para iniciar o processo de qualificação para o crédito promocional a ele associado;
            </li>
            <li>
              <strong>Para obter o crédito</strong>
              , após inserir o código em sua conta do Google Ads,
              <strong>{' você deverá investir até R$ 1200,00 '}</strong>
              (excluindo qualquer imposto)
              <strong>{' em suas campanhas publicitárias durante um período de 60 dias'}</strong>
              . Você receberá um cupom de um valor igual ao gasto total durante este período, com um limite máximo de R$ 1200,00.
            </li>
            <li>
              <strong>O crédito não se aplica aos custos acumulados antes da introdução do código promocional</strong>
              . Ou seja, o crédito poderá ser usado unicamente para os gastos de publicidade futuros.
              Para informações detalhadas, confira os
              <Link text="Termos e Condições de Uso do Google AdWords." href="https://www.google.com/intl/pt-BR_br/ads/coupons/terms/" />
            </li>
          </ul>

        </>
      ),
      checkbox: 'Confirmo que li e concordo com os termos acima.',
      sendButtonLabel: 'Enviar',
      bodyFields: {
        product: 'Produto',
        user: 'Usuário',
        server: 'Servidor',
        solicitation: 'Solicitação',
        agree: 'Li e concordo com os termos do Google AdWords',
        productId: 'ID do produto',
      },
    },
    externalMigration: {
      title: 'Preencha os dados abaixo',
      info: (
        <>
          {'Leia com atenção os itens a seguir: '}
          <br />
          <ul>
            <li>
              Leia os nossos
              <Link
                href="https://www.hostgator.com.br/termos-de-migracao"
                text="Termos de Migração"
              />
            </li>
            <li>
              {'Veja também '}
              <Link
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000384693"
                text="quais são as nossas compatibilidades"
              />
              {' para ver se está adequado a sua necessidade.'}
            </li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>
              <strong>NÃO CANCELE</strong>
              {' seu plano antigo até que os arquivos tenham sido migrados, testados e o DNS alterado corretamente.'}
            </li>
            <li>
              Lembre-se de aguardar o prazo de propagação, de 24h à 72h de penpendendo da extensão de seu domínio
            </li>
            <li>
              Não nos responsabilizamos pelo conteúdo migrado, pois não temos controle de versão.
            </li>
          </ul>
          <br />
          <strong>ATENÇÃO</strong>
          <ul>
            <li>
              Este formulário é para migração de toda a conta. Ao solicitar a migração, todo o conteúdo do site e/ou, banco de dados
            </li>
          </ul>
          <br />
          <br />
        </>
      ),
      oldHostDomain: 'Domínio registrado na empresa de hospedagem anterior *',
      oldHostLogin: 'Informe o login para acessar o painel administrativo da hospedagem anterior *',
      oldHostPassword: 'Informe a senha para acessar o painel administrativo da hospedagem anterior *',
      oldHostLinkAdmin: 'Link para acesso ao painel administrativo da hospedagem anterior *',
      oldHostAdminPanel: 'Selecione o painel administrativo da empresa de hospedagem anterior *',
      oldHostProEmail: 'Selecione o tipo de e-mail utilizado na hospedagem anterior *',
      actualProEmail: 'Selecione o tipo de e-mail utilizado na sua atual hospedagem com a HostGator *',
      aditionalInfo: 'Utilize o campo abaixo para inserir quaisquer informações que julgar importante para a transferência, como por exemplo, alguma observação específica',
      terms: 'Confirmo que li e concordo com os Termos de Migração.',
      payment: 'Confirmo que concordo com eventual cobrança se não me adequar aos limites de migração descritos no termo.',
      dontKnow: 'Não sei',
      bodyProduct: selectedProduct => `Produto: ${selectedProduct.name} - Usuário: ${selectedProduct.username}`,
      solicitation: 'Migração Externa',
      bodyDomain: oldHostDomain => `Domínio registrado na empresa de hospedagem anterior: ${oldHostDomain}`,
      bodyLogin: oldHostLogin => `Informe o login para acessar o painel administrativo da hospedagem anterior: ${oldHostLogin}`,
      bodyPassword: oldHostPassword => `Informe a senha para acessar o painel administrativo da hospedagem anterior: ${oldHostPassword}`,
      bodyLink: oldHostLinkAdmin => `Link para acesso ao painel administrativo da hospedagem anterior: ${oldHostLinkAdmin}`,
      bodyPanel: oldHostAdminPanel => `Selecione o painel administrativo da empresa de hospedagem anterior: ${oldHostAdminPanel}`,
      bodyOldEmail: oldHostProEmail => `Selecione o tipo de e-mail utilizado na hospedagem anterior: ${oldHostProEmail}`,
      bodyNewEmail: actualProEmai => `Selecione o tipo de e-mail utilizado na sua atual hospedagem com a HostGator: ${actualProEmai}`,
      bodyAditionalInfo: aditionalInfo => `Utilize o campo abaixo para inserir quaisquer informações que julgar importante para a transferência, como por exemplo, alguma observação específica: ${aditionalInfo}`,
      bodyTerms: terms => `Confirmo que li e concordo com os Termos de Migração.: ${terms ? 'Sim' : 'Não'}`,
      bodyPayment: payment => `Confirmo que concordo com eventual cobrança se não me adequar aos limites de migração descritos no termo.: ${payment ? 'Sim' : 'Não'}`,
      bodyProductId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      subject: 'Suporte Técnico - Migração Externa',
    },
    restorateCancelledPlan: {
      title: 'Preencha os dados abaixo!',
      subject: 'Suporte Técnico - Restauração de Plano Cancelado',
      terms: (
        <>
          {'Leia com atenção os '}
          <strong>termos</strong>
          {' a seguir:'}
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li><strong>É OBRIGATÓRIO que já tenha contratado novamente o mesmo plano com o mesmo domínio para que a restauração seja realizada.</strong></li>
            <li>
              <strong>{'Há uma cobrança no valor de R$100,00 '}</strong>
              para este procedimento. Ao término da restauração será gerado a cobrança na sua conta.
            </li>
            <li>Não nos responsabilizamos pelo conteúdo restaurado, pois não temos controle de versão.</li>
            <li>Nossa rotina de backups é semanal e inclui contas de até 20 GB de dados, que possuem até 100 mil nós (arquivos, pastas, e-mails etc.). Pode ocorrer de não termos como restaurar sua conta, por ela estar fora da rotina de backup antes do cancelamento;</li>
            <li>Todos os domínios abaixo de seu usuário que tivermos backups serão restaurando independentemente se eles foram removidos previamente.</li>
            <li>
              {'Caso seus emails sejam Titan, não fazemos restauração de backup. Você é o responsável por '}
              <Link href={DO_YOUR_ON_BACKUP} text="fazer o seu próprio backup." />
            </li>
            <li>Caso o seu site tenha sido desenvolvido com o Criador de Sites da HostGator, você deverá solicitar também a restauração o editor através do formulário Restauração de acesso ao editor criador de sites</li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>Este formulário é para restauração de conta cancelada.</li>
            <li>Ao solicitar a restauração recuperaremos todo o conteúdo do site, banco de dados e emails do último estado da sua conta antes do cancelamento, não sendo possível restaurar somente o domínio principal ou somente domínios adicionais.</li>
          </ul>
        </>
      ),
      mainDomainPlaceholder: 'Informe o domínio principal do plano cancelado que deseja restaurar',
      chargeAgreed: 'Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$100,00',
      termsAgreed: 'Confirmo que li e concordo com os termos de restauração de backup',
      bodyProduct: selectedProduct => `Produto: ${selectedProduct.name} - Usuário: ${selectedProduct.username}`,
      bodyServer: selectedProduct => `Servidor: ${selectedProduct.serverhostname || selectedProduct.dedicatedip}`,
      bodyRequest: subject => `Assunto: ${subject}`,
      bodyProductId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      bodyMainDomain: domain => `Informe abaixo o domínio principal do plano cancelado que deseja restaurar: ${domain}`,
    },
    generateCSR: {
      title: 'Preencha o formulário abaixo',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>CSR é uma solicitação de uma emissão do certificado de uma empresa terceira;</li>
            <li>
              <strong>{'Somente faça esta solicitação caso vá comprar um Certificado SSL em outra empresa '}</strong>
              {' - caso queira adquirir um Certificado com a gente, '}
              <Link text="veja como contratar" href="https://suporte.hostgator.com.br/hc/pt-br/articles/360007433554-Como-contratar-um-certificado-SSL" />
              ;
            </li>
            <li>
              <strong>{"Este formulário de 'Gerar CSR' serve principalmente para clientes de planos Plano P, M, Business, Plus ou Turbo. "}</strong>
              {'Para clientes de Revenda e Servidores VPS e Dedicado, veja o material de '}
              <Link text="'Como instalar um certificado SSL pago no WHM?'" href="https://suporte.hostgator.com.br/hc/pt-br/articles/360006132994-Como-instalar-um-certificado-SSL-pago-no-WHM" />
            </li>
          </ul>
        </>
      ),

      isWildCard: 'O Certificado SSL que irá contratar em outra empresa será "SSL Wildcard"?',
      checkbox: 'Confirmo que li e concordo com os termos acima',
      sendButtonLabel: 'Enviar',
      placeholders: {
        email: 'Digite seu e-mail',
        name: 'Digite seu nome completo',
        city: 'Digite sua cidade',
        state: 'Digite seu estado',
        country: 'Digite seu país',
        company: 'Digite o nome da empresa',
        sslUrl: 'Digite o endereço (URL)',
      },
      bodyFields: {
        email: 'E-mail',
        name: 'Nome Completo',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
        company: 'Empresa',
        sslUrl: 'Endereço (URL) onde o SSL irá funcionar',
        isWildcard: 'SSL Wildcard',
        agree: 'Concordo com os termos',

        yes: 'Sim',
        no: 'Não',

        user: 'Usuário',
        server: 'Servidor',
        product: 'Produto',
      },
      errors: {
        invalidEmail: 'Digite um email válido',
        emptyField: 'Preencha este campo',
      },
    },
    restorateSiteBuilderAccess: {
      title: 'Preencha os dados abaixo!',
      subject: 'Suporte Técnico - Restauração de acesso ao editor do Criador de Sites',
      terms: (
        <>
          {'Leia com atenção os '}
          <strong>termos</strong>
          {' a seguir:'}
        </>
      ),
      termsItems: (
        <>
          <ul>
            <li>
              {'Só é possível recuperar a edição do criador de Sites Rápido. Se usava o editor avançado, terá que '}
              <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/360052273314" text="recriar seu site" />
            </li>
            <li>
              <strong>{'Há uma cobrança no valor de R$100,00 '}</strong>
              para este procedimento. Ao término da restauração será gerado a cobrança na sua conta.
            </li>
            <li>Não nos responsabilizamos pelo conteúdo restaurado, pois não temos controle de versão.</li>
            <li>
              {'Caso seus emails sejam Titan, não fazemos restauração de backup. Você é o responsável por '}
              <Link href={DO_YOUR_ON_BACKUP} text="fazer o seu próprio backup." />
            </li>
          </ul>
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>Este formulário é para restauração de acesso ao PAINEL de edição do criador de sites. Ao solicitar essa restauração, nenhum Wordpress, banco de dados ou email será restaurado.</li>
            <li>A restauração de acesso ao editor somente é realizada para o mesmo tipo de plano ao qual foi ativado.</li>
          </ul>
        </>
      ),
      chargeAgreed: 'Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$100,00',
      termsAgreed: 'Confirmo que li e concordo com os termos de restauração de backup',
      bodyProduct: selectedProduct => `Produto: ${selectedProduct.name} - Usuário: ${selectedProduct.username}`,
      bodyRequest: subject => `Assunto: ${subject}`,
      bodyProductId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      bodyAgreedCharge: bool => `Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$100,00: ${bool ? 'Sim' : 'Não'}`,
      bodyAgreedTerms: bool => `Confirmo que li e concordo com os termos de restauração de backup: ${bool ? 'Sim' : 'Não'}`,
    },
    affiliateProgram: {
      title: 'Preencha os dados abaixo!',
      subject: 'Minha Conta - Dúvidas exclusivamente sobre o Programa de Afiliados',
      info: (
        <>
          <strong>
            {'Leia com atenção os '}
            <strong>termos</strong>
            {' a seguir:'}
          </strong>
          <ul>
            <li>
              {'Este formulário é de uso '}
              <strong>exclusivo</strong>
              {' sobre nosso Programa de Afiliados. Atendimento de segunda a sexta em horário comercial (exceto feriados) com tempo de resposta de até 72 horas.'}
            </li>
            <li>
              <strong>Caso queira suporte técnico ou financeiro, use o formulário apropriado.</strong>
            </li>
          </ul>
        </>
      ),
      labelAffiliate: 'Você faz parte do Programa de Afiliados?',
      affiliateOptions: {
        yes: 'Sim',
        no: 'Não, mas tenho dúvidas sobre o Programa',
      },
      labelDoubts: 'Informe suas dúvidas sobre o nosso Programa de Afiliados',
      bodyProduct: 'Produto: Dúvidas exclusivamente sobre o Programa de Afiliados',
      bodyRequest: 'Solicitação: Dúvidas exclusivamente sobre o Programa de Afiliados',
      bodyIsAffiliate: answer => `Você faz parte do programa de afiliados?: ${answer}`,
      bodyDoubts: content => `Informe sua dúvida sobre o nosso Programa de Afiliados: ${content}`,
      sendButtonLabel: 'Enviar',
    },
    nationalDomainTransfer: {
      subject: (
        <>
          Para transferir a administração do seu domínio nacional para a HostGator você deve fazer a alteração do seu Provedor de Serviços da Entidade no site da
          {' '}
          <Link href={REGISTROBR_HOMEPAGE} text="Registro.br" />
          .
        </>
      ),
      followTheSteps: 'Para realizar o procedimento, siga os passos a seguir:',
      steps: [
        <>
          Acesse o site da
          {' '}
          <Link href={REGISTROBR_HOMEPAGE} text="Registro.br" />
          {' '}
          e digite seu ID e senha (fornecidos pela
          {' '}
          <Link href={REGISTROBR_HOMEPAGE} text="Registro.br" />
          );
        </>,
        'Após o acesso, na caixa Domínios, clique sobre o domínio que deseja transferir;',
        'Em Provedor de Serviços, clique em Selecionar outro provedor;',
        'Selecione a opção ENDURANCE BRASIL (43) e clique em Prosseguir;',
        'Leia as informações e clique em Concordo;',
        <>
          Após do processo de transferência no site da
          {' '}
          <Link href={REGISTROBR_HOMEPAGE} text="Registro.br" />
          , preencha o formulário abaixo.
        </>,
      ],
      remember: 'Lembre-se:',
      rememberList: [
        'É fundamental realizar todo o processo de transferência de provedor antes de enviar o formulário;',
        'Ao realizar este procedimento, todos os seus domínios vinculados ao titular alterado passarão para o provedor selecionado;',
        'Caso tenha alguma dúvida ou dificuldade, entre em contato via chat online.',
      ],
      fillTheField: 'Por favor, preencha o campo abaixo, confirmando o(s) domínios(s) que deve(m) ser adicionado(s) em sua área do cliente.',
      domainsToTransfer: 'Domínio(s) a transferir',
      send: 'Enviar',
    },
    upgradeVps: {
      title: 'Preencha os dados abaixo!',
      subject: 'Suporte Financeiro - Upgrade de Plano',
      info: (
        <>
          <strong>
            {'Leia com atenção os '}
            <strong>termos</strong>
            {' a seguir:'}
          </strong>
          <ul>
            <li>
              <Link href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173" text="É recomendado fazer backup do conteúdo" />
            </li>
            <li>
              O IP informado de sua responsabilidade
            </li>
            <li>
              <strong>IMPORTANTE: Este formulário é para solicitação de Upgrade de plano VPS. Ao solicitar o procedimento não tem possibilidade de downgrade.</strong>
            </li>
          </ul>
        </>
      ),
      fields: {
        ipLabel: 'Qual o IP do servidor?',
        ipInfo: 'ATENÇÃO: O procedimento será realizado no servidor deste IP. Não haverá dupla confirmação',
        productLabel: 'Novo Produto',
        executionLabel: 'Podemos executar em horário comercial',
        executionInfo: '08:00 as 18:00 *Conforme horário de Brasília',
        agreedTermsLabel: 'Confirmo que li e concordo com os termos acima e com a diferença do valor entre os produtos',
        agreedResponsabilityLabel: 'Confirmo que li e estou ciente que é minha responsabilidade fazer backups do servidor antes do procedimento',
      },
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username} - IP: ${selectedProduct.dedicatedip}`,
        request: 'Solicitação: Upgrade de Plano',
        serverIp: serverIp => `Qual o IP do Servidor?: ${serverIp}`,
        newProduct: newProduct => `Novo Produto: ${newProduct}`,
        execution: execution => `Podemos executar em horário comercial: ${execution}`,
        agreedTerms: agreedTerms => `Confirmo que li e concordo com os termos acima e com a diferença do valor entre os produtos: ${agreedTerms ? 'Sim' : 'Não'}`,
        agreedResponsability: agreedResponsability => `Confirmo que li e estou ciente que é minha responsabilidade fazer backups do servidor antes do procedimento: ${agreedResponsability ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      sendButtonLabel: 'Enviar',
    },
    upgradeSiteLock: {
      info: (
        <>
          {'Caso não encontre a opção desejada na lista abaixo, inicie um '}
          <strong>atendimento por chat.</strong>
        </>
      ),
      placeholders: {
        domain: 'Domínio vinculado ao Sitelock?',
        newSitelock: 'Novo Produto',
        agreed: 'Confirmo que li e concordo com a diferença do valor entre os produtos',
      },
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Upgrade de Plano',
        domain: domain => `Dominio vinculado ao Sitelock?: ${domain}`,
        newSitelock: newSitelock => `Novo Produto: ${newSitelock}`,
        agreed: agreed => `Confirmo que li e concordo com a diferença do valor entre os produtos: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      title: 'Preencha os dados abaixo!',
      send: 'Enviar',
    },
    upgradeCodeGuard: {
      info: (
        <>
          {'Caso não encontre a opção desejada na lista abaixo, inicie um '}
          <strong>atendimento por chat.</strong>
        </>
      ),
      placeholders: {
        newProduct: 'Novo Produto',
        agreed: 'Confirmo que li e concordo com a diferença do valor entre os produtos',
        agreedSelectedPlan: 'Confirmo que selecionei o plano correto e estou ciente que não haverá dupla confirmação para realização do upgrade.',
      },
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Upgrade de Plano',
        newProduct: newProduct => `Novo Produto: ${newProduct}`,
        agreed: agreed => `Confirmo que li e concordo com a diferença do valor entre os produtos: ${agreed ? 'Sim' : 'Não'}`,
        agreedSelectedPlan: agreedSelectedPlan => `Confirmo que selecionei o plano correto e estou ciente que não haverá dupla confirmaçãop para realização do upgrade: ${agreedSelectedPlan ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      title: 'Preencha os dados abaixo!',
      send: 'Enviar',
    },
    changePaymentCycle: {
      info: (
        <>
          {'Caso não encontre a opção desejada na lista abaixo, inicie um '}
          <strong>atendimento por chat.</strong>
        </>
      ),
      placeholders: {
        newCycle: 'Selecione o novo ciclo desejado',
        agreed: 'Confirmo a alteração de ciclo de pagamento do serviço selecionado',
        generalComment: 'Comentário adicional',
      },
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Alterar Ciclo de Pagamento',
        newCycle: newCycle => `Selecione o novo ciclo desejado: ${newCycle}`,
        agreed: agreed => `Confirmo a alteração de ciclo de pagamento do serviço selecionado: ${agreed ? 'Sim' : 'Não'}`,
        generalComment: comment => `Comentário Geral: ${comment}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      title: 'Preencha os dados abaixo!',
      send: 'Enviar',
      subject: 'Suporte Financeiro - Alterar Ciclo de Pagamento',
    },
    privateSslCertificate: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>No campo abaixo, para preenchimento da URL, cuide para informar o endereço correto.</strong>
              {' Não é possível reemitir o certificado para outro domínio;'}
            </li>
            <li>
              {'Este '}
              <strong>
                {'certificado SSL Privado tem um custo de '}
                {formatCurrency(119.88)}
                {' ao ano;'}
              </strong>
            </li>
            <li>
              As faturas de renovação serão geradas automaticamente ao fim do ciclo, mas a cada pagamento deve criar um chamado para reemitir o certificado SSL renovado;
            </li>
            <li>
              A instalação deste certificado é válida apenas para servidores da HostGator.
            </li>
          </ul>

        </>
      ),
      placeholders: {
        domain: 'Endereço (URL) onde o SSL irá funcionar',
        domainInfo: 'O SSL funcionará apenas na URL informada, que pode ser um domínio ou subdomínio. Ex. nomedodominio.com.br ou loja.nomedodominio.com.br',
        agreed: 'Confirmo que li e concordo com os termos acima e com a cobrança de R$119.88 ao ano.',
      },
      subject: 'Suporte Técnico - Certificado SSL Privado',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Certificado SSL Privado',
        domain: domain => `Endereço (URL) onde o SSL irá funcionar: ${domain}`,
        agreed: agreed => `Confirmo a alteração de ciclo de pagamento do serviço selecionado: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    widlcardSslCertificate: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>No campo abaixo, para preenchimento da URL, cuide para informar o endereço correto.</strong>
              {' Não é possível reemitir o certificado para outro domínio;'}
            </li>
            <li>
              {'Este '}
              <strong>
                {'Certificado SSL Wildcard tem um custo de '}
                {formatCurrency(443.88)}
                {' ao ano;'}
              </strong>
            </li>
            <li>
              As faturas de renovação serão geradas automaticamente ao fim do ciclo, mas a cada pagamento deve criar um chamado para reemitir o certificado SSL renovado;
            </li>
            <li>
              A instalação deste certificado é válida apenas para servidores da HostGator.
            </li>
          </ul>

        </>
      ),
      placeholders: {
        domain: 'Endereço (URL) onde o SSL irá funcionar',
        domainInfo: 'O SSL funcionará apenas na URL informada, que pode ser um domínio ou subdomínio. Ex. nomedodominio.com.br ou loja.nomedodominio.com.br',
        agreed: `Confirmo que li e concordo com os termos acima e com a cobrança de ${formatCurrency(443.88)} ao ano.`,
      },
      subject: 'Suporte Técnico - Certificado SSL Wildcard',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Certificado SSL Wildcard',
        domain: domain => `Endereço (URL) onde o SSL irá funcionar: ${domain}`,
        agreed: agreed => `Confirmo a alteração de ciclo de pagamento do serviço selecionado: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    enterPrisessslCertificate: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              A instalação deste certificado somente é feita para Pessoa Jurídica (CNPJ) e todos os dados aqui na HostGator precisam corresponder aos da empresa junto a Receita Federal;
            </li>
            <li>
              A validação dos dados é feita por uma empresa terceira que pode solicitar documentos e fará chamadas telefônicas exclusivamente ao número registrado no CNPJ afim de validar a existência da sua empresa;
            </li>
            <li>
              No campo abaixo, para preenchimento da URL, cuide para informar o endereço correto. Não é possível reemitir o certificado para outro domínio;
            </li>
            <li>
              {'Este Certificado SSL Empresarial tem um custo de '}
              {formatCurrency(323.88)}
              {' ao ano;'}
            </li>
            <li>
              As faturas de renovação serão geradas automaticamente ao fim do ciclo, mas a cada pagamento deve criar um chamado para reemitir o certificado SSL renovado;
            </li>
            <li>
              A instalação deste certificado é válida apenas para servidores da HostGator.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        cnpj: 'CNPJ da empresa',
        phone: 'Número de telefone do CNPJ',
        phoneInfo: 'É obrigatório que o telefone informado conste cadastrado no CNPJ',
        email: 'Conta de email do dominío',
        emailInfo: 'É obrigatório que a conta de email informada seja do próprio dominio',
        agreed: `Confirmo que li e concordo com os termos acima e com a cobrança de ${formatCurrency(323.88)} ao ano`,
      },
      subject: 'Suporte Técnico - Certificado SSL Empresarial',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Certificado SSL Privado',
        cnpj: cnpj => `CNPJ da empresa: ${cnpj}`,
        phone: phone => `Número de telefone do CNPJ: ${phone}`,
        email: email => `Conta de email do dominío: ${email}`,
        agreed: agreed => `Confirmo que li e concordo com os termos acima e com a cobrança de ${formatCurrency(323.88)} ao ano: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    reloadRebuild: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>Nós não faremos backup de seu servidor.</strong>
            </li>
            <li>
              <strong>Caso você deseje que restauremos seu backup, após o procedimento, deverá subir todos os arquivos para um mesmo diretório em até 48 horas.</strong>
            </li>
            <li>
              <strong>Não nos responsabilizamos pelo conteúdo restaurado, pois não temos controle sobre o backup disponibilizado.</strong>
            </li>
          </ul>
          <br />
          <strong>{'Importante: '}</strong>
          <ul>
            <li>
              <strong>Este formulário é para solicitação de Reload/Rebuild. Ao solicitar o procedimento todo o conteúdo do servidor será removido sem possibilidade de restauração.</strong>
            </li>
          </ul>
          <br />
          <strong>{'Atenção: '}</strong>
          <ul>
            <li>
              <strong>O procedimento será realizado no servidor selecionado. Não haverá dupla confirmação</strong>
            </li>
            <li>
              <strong>Ao escolher uma imagem linux sem cPanel, o servidor é entregue somente com o SSH configurado. Todas configurações adicionais é responsábilidade do cliente.</strong>
            </li>
            <li>
              <strong>Se o seu servidor contratado é Windows, você somente pode escolher uma imagem Windows, assim como se seu servidor contratado for Linux, somente pode escolher uma imagem Linux. Para trocar o Sistema Operacional nesses casos, deve realizar o cancelamento e uma nova contratação.</strong>
            </li>
          </ul>
        </>
      ),
      placeholders: {
        image: 'Qual a imagem desejada?',
        canExecuteInComercialHours: 'Podemos executar em horário comercial',
        agreedBrasiliaHours: '08:00 as 18:00 *Conforme horário de Brasília',
        agreedRebuildReloadTerms: 'Confirmo que li e concordo com os Termos de Rebuild/Reload',
        agreedValue: 'Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$250,00 caso se encaixe nos cenários descritos nos termos',
        agreedResponsability: 'Confirmo que li e estou ciente que é minha responsabilidade fazer backups para posterior restauração',
        linuxWithCpanel: 'Imagens Linux com cPanel',
        linuxWithoutCpanel: 'Imagens Linux sem cPanel',
        windowsWithPleskWithoutLicense: 'Imagens Windows com Plesk (não incluso a licença que deve ser contratada diretamente com eles)',
        windowsWithPleskWithLicense: 'Imagens Windows com Plesk (incluso a licença)',
        yes: 'Sim',
        no: 'Não',
      },
      subject: 'Suporte Técnico - Solicitação de Reload/Rebuild',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Reload/Rebuild',
        image: image => `Qual a imagem desejada? ${image}`,
        canExecuteInComercialHours: bool => `Podemos executar em horário comercial? ${bool ? 'Sim' : 'Não'}`,
        agreedBrasiliaHours: bool => `08:00 as 18:00 *Conforme horário de Brasília? ${bool ? 'Sim' : 'Não'}`,
        agreedRebuildReloadTerms: bool => `Confirmo que li e concordo com os Termos de Rebuild/Reload? ${bool ? 'Sim' : 'Não'}`,
        agreedValue: bool => `Confirmo que li e concordo com os termos acima e com a cobrança do valor de R$250,00 caso se encaixe nos cenários descritos nos termos? ${bool ? 'Sim' : 'Não'}`,
        agreedResponsability: bool => `Confirmo que li e estou ciente que é minha responsabilidade fazer backups para posterior restauração? ${bool ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    moreEmails: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              {'A limitação é para a segurança de seu servidor e aumenta-la '}
              <strong>
                pode aumentar o risco de seus Ips serem bloqueados pelos maiores provedores de emails
              </strong>
            </li>
            <li>
              {'Conforme pode ser verificados nos itens '}
              <Link
                href="https://www.hostgator.com.br/termos-de-servico#servidores-dedicados"
                text="14"
                target="_blank"
              />
              {' e '}
              <Link
                href="https://www.hostgator.com.br/termos-de-servico#servidores-vps"
                text="15"
                target="_blank"
              />
              <strong>
                {' é de sua responsabilidade remover endereços de IP de listas de bloqueios, monitorar envios e filas de e-mail'}
              </strong>
            </li>
            <li>
              Não nos responsabilizamos se o IP do servidor ou seu domínio for bloqueado pelos provedores de emails devido a má utilização do mesmo como por exemplo envio de email marketing.
            </li>
          </ul>
          <br />
          <span>
            <strong>{'IMPORTANTE: '}</strong>
            O valor informado por você mais abaixo poderá ou não ser aplicado. O limite será definido com base no histórico de uso do servidor.
          </span>
        </>
      ),
      placeholders: {
        serverIp: 'Qual o IP do servidor?',
        motivation: 'Qual o motivo do pedido de aumento no limite de 500 emails por hora?',
        newLimit: 'Qual é o  limite de emails por hora que  você acha adequado para seu uso?',
        agreedService: 'Confirmo que li e concordo com os Termos de Serviço',
        agreedPolicy: 'Confirmo que li e concordo com a Política de e-mails',
        agreedResponsability: 'Confirmo que estou ciente que é minha responsabilidade remover endereços de IP de listas de bloqueios, monitorar envios e filas de e-mail',
      },
      subject: 'Suporte Técnico - Aumento no envio de e-mails',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Aumento no envio de e-mails',
        serverIp: serverIp => `Qual o IP do servidor?: ${serverIp}`,
        motivation: motivation => `Qual é o limite de emails por hora que você acha adequado para seu uso?: ${motivation}`,
        newLimit: newLimit => `Conta de email do dominío: ${newLimit}`,
        agreedService: agreed => `Confirmo que li e concordo com os Termos de Serviço: ${agreed ? 'Sim' : 'Não'}`,
        agreedPolicy: agreed => `Confirmo que li e concordo com a Política de e-mails: ${agreed ? 'Sim' : 'Não'}`,
        agreedResponsability: agreed => `Confirmo que estou ciente que é minha responsabilidade remover endereços de IP de listas de bloqueios, monitorar envios e filas de e-mail: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    installWhmcs: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>
                {'A instalação do WHMCS tem um custo de '}
                {formatCurrency(350.00)}
              </strong>
            </li>
            <li>
              Não damos suporte para a plataforma WHMCS, apenas instalamos e atualizamos as licenças
            </li>
            <li>
              <strong>
                Antes de solicitar a instalação, obtenha a licença do WHMCS
              </strong>
            </li>
            <li>
              {'para contratação veja o material de '}
              <Link
                text="Como obter a licença do WHMCS"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000533014"
                target="_blank"
              />
            </li>
          </ul>
        </>
      ),
      placeholders: {
        link: 'Informe o link desejado para instalação do WHMCS',
        linkInfo: 'Ex: seudomínio.com/whmcs',
        email: 'Email para cadastro no whmcs',
        password: 'Senha para cadastrar no whmcs',
        key: 'Licença whmcs',
        agreed: `Confirmo que li e concordo com os termos acima e com o valor de ${formatCurrency(350.00)}.`,
      },
      subject: 'Suporte Técnico - Instalação do WHMCS',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Instalação do WHMCS',
        link: link => `Informe o link desejado para instalação do WHMCS: ${link}`,
        email: email => `Email para cadastro no whmcs: ${email}`,
        password: password => `Senha para cadastrar no whmcs: ${password}`,
        key: key => `Licença whmcs: ${key}`,
        agreed: agreed => `Confirmo que li e concordo com os termos acima e com o valor de ${formatCurrency(350.00)}: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    migrateVpsDedicated: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              {'Leia os nossos '}
              <Link
                text="Termos de Migração"
                href="https://www.hostgator.com.br/termos-de-migracao"
                target="_blank"
              />
            </li>
            <li>
              Caso você venha de outra empresa de hospedagem, verifique se há limitação de backup completo
            </li>
            <li>
              {'Veja também '}
              <Link
                text="quais são as nossas compatibilidades"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000384693"
                target="_blank"
              />
              {' para ver se está adequado a sua necessidade.'}
            </li>
          </ul>
          <br />
          <span>
            <strong>IMPORTANTE:</strong>
            {' NÃO CANCELE seu plano antigo até que os arquivos tenham sido migrados, testados e o DNS alterado corretamente.(Lembre-se de aguardar o prazo de propagação, de 24 à 48h)'}
          </span>
        </>
      ),
      placeholders: {
        ipOrDomain: 'Informe o endereço IP ou nome de domínio da conta da empresa de hospedagem anterior',
        user: 'Informe o nome de usuário de acesso ao painel da empresa de hospedagem anterior',
        password: 'Informe a senha de acesso',
        selectRootAccess: 'Você tem acesso root (administrador) ao servidor da empresa de hospedagem anterior?',
        yes: 'Sim',
        no: 'Não',
        selectWhmcsKey: 'Você usa a licença gratuita do WHMCS da HostGator?',
        installedDomain: 'Para qual domínio a licença está instalada?',
        detailedList: 'Por favor, nos dê uma lista completa das contas que você quer mudar, com todos os detalhes necessários, tais como nome de usuário, senha e painel de controle que você estava usando',
        agreed: 'Confirmo que li e concordo com os Termos de Migração e itens acima.',
      },
      subject: 'Suporte Técnico - Migração de Servidor Dedicado e VPS',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Migração de Servidor Dedicado e VPS',
        ipOrDomain: ipOrDomain => `Informe o endereço IP ou nome de domínio da conta da empresa de hospedagem anterior: ${ipOrDomain}`,
        user: user => `Informe o nome de usuário de acesso ao painel da empresa de hospedagem anterior: ${user}`,
        password: password => `Informe a senha de acesso: ${password}`,
        selectRootAccess: bool => `Você tem acesso root (administrador) ao servidor da empresa de hospedagem anterior?: ${bool ? 'Sim' : 'Não'}`,
        selectWhmcsKey: bool => `Você usa a licença gratuita do WHMCS da HostGator?: ${bool ? 'Sim' : 'Não'}`,
        installedDomain: installedDomain => `Para qual domínio a licença está instalada?: ${installedDomain}`,
        detailedList: detailedList => `Por favor, nos dê uma lista completa das contas que você quer mudar, com todos os detalhes necessários, tais como nome de usuário, senha e painel de controle que você estava usando: ${detailedList}`,
        agreed: agreed => `Confirmo que li e concordo com os Termos de Migração e itens acima: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    migrateAccountVpsDedicated: {
      title: '¡Complete los datos de abajo!',
      info: (
        <>
          <strong>Lea cuidadosamente los siguientes términos:</strong>
          <ul>
            <li>
              Las versiones PHP 5.2 y 5.3 fueron descontinuadas en nuestra estructura para evitar vulnerabilidades. Pedimos que tus sitio web estén debidamente actualizados de acuerdo a la versión PHP 5.6 (o superior) para que no ocurran problemas durante la migración.
            </li>
          </ul>
          <br />
          <span>
            <strong>{'Importante: '}</strong>
          </span>
          <ul>
            <li>
              {'Antes de completar este formulario, por favor lee los '}
              <Link
                text="términos de migración"
                href={`https://www.hostgator.${COUNTRY.toLowerCase()}/terminos-de-migracion`}
                target="_blank"
              />
            </li>
            <li>NO CANCELES tu antiguo plsm hasta que los archivos se hayan migrado, los hayas probado y los DNS se hayan cambiado correctamente.</li>
          </ul>
        </>
      ),
      placeholders: {
        ipOrDomain: 'Dirección IP o nombre de dominio de la cuenta de hosting anterior',
        user: 'Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor',
        password: 'Introduce la contraseña para acceder al panel administrativo del antiguo proveedor',
        detailedList: 'Por favor propociónanos una lista completa de las cuentas que deseas migrar, con todos los detalles necesarios, tales como nombre de usuario, contraseña y panel de control que estaba utilizando',
        agreed: 'Entiendo y estoy de acuerdo con los términos de la migración',
      },
      subject: 'Soporte Técnico - Migración de Cuenta de VPS/Dedicado',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Migração de Servidor Dedicado e VPS',
        ipOrDomain: ipOrDomain => `Dirección IP o nombre de dominio de la cuenta de hosting anterior: ${ipOrDomain}`,
        user: user => `Introduce el nombre de usuario para acceder al panel administrativo del antiguo proveedor: ${user}`,
        password: password => `Introduce la contraseña para acceder al panel administrativo del antiguo proveedor: ${password}`,
        detailedList: detailedList => `Por favor propociónanos una lista completa de las cuentas que deseas migrar, con todos los detalles necesarios, tales como nombre de usuario, contraseña y panel de control que estaba utilizando: ${detailedList}`,
        agreed: agreed => `Entiendo y estoy de acuerdo con los términos de la migración: ${agreed ? 'Si' : 'No'}`,
        productId: selectedProduct => `ID del producto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    updateWhmcsLicense: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              <strong>
                Este é um formulário apenas de atualização da licença, não de contratação.
              </strong>
              {' Para contratação veje o material de '}
              <Link
                text="Como obter a licença do WHMCS"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000533014?"
                target="_blank"
              />
              .
            </li>
            <li>
              A licença gratuita é limitada a 249 contas cadastradas no WHMCS.
            </li>
            <li>
              Os planos de Revenda de hospedagem e Dedicado incluem uma única licença gratuita do WHMCS.
            </li>
            <li>
              A licença WHMCS oferecida pela HostGator só é válida se o WHMCS estiver instalado na sua conta na HostGator.
            </li>
          </ul>
          <br />
          <strong>IMPORTANTE:</strong>
          <ul>
            <li>
              {`A Hostgator não é responsável pela instalação e configuração do WHMCS. Caso queira que façamos, existe uma cobrança de ${formatCurrency(350.00)}.`}
            </li>
            <li>
              Não damos suporte para a plataforma WHMCS, apenas instalamos e atualizamos as licenças.
            </li>
            <li>
              <strong>
                Você precisará acessar o painel de controle admin no mínimo 1 (uma) vez por mês para manter sua licença ativa.
              </strong>
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'Domínio que ativou o WHMCS',
        domainInfo: 'Informe o novo dominio. Se não deve ser alterado, basta informar o dominio atual.',
        license: 'Licença WHMCS',
        directory: 'Diretório onde está instalado o WHMCS',
        directoryInfo: 'Informe o novo diretório. Se não deve ser alterado, basta informar o diretório atual',
        accessLink: 'Link de acesso ao painel admin do WHMCS',
        serverIp: 'IP do servidor',
        serverIpInfo: (
          <>
            {'Deixe em branco se não souber o IP ou pegue-o através do painel da WHMCS '}
            <Link
              href="https://www.whmcs.com/members/"
              target="_blank"
              text="https://www.whmcs.com/members/"
            />
          </>
        ),
        agreed: 'Confirmo que li e concordo com os termos acima',
      },
      subject: 'Suporte Técnico - Atualização de Licença WHMCS',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Atualização de Licença WHMCS',
        agreed: agreed => `Confirmo que li e concordo com os termos acima: ${agreed ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    whmcsAcquisition: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>
            {'Antes de prosseguir, veja se alguns materiais da nossa '}
            <Link
              text="Central de Ajuda"
              href="https://suporte.hostgator.com.br/hc/pt-br#canais-de-ajuda"
              target="_blank"
            />
            {' já podem te ajudar. Você ganhará tempo e não precisará ficar aguardando uma resposta :)'}
          </strong>
          <ul>
            <li>
              <Link
                text="O que é WHMCS?"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000386214-O-que-%C3%A9-WHMCS-"
                target="_blank"
              />
            </li>
            <li>
              <Link
                text="Como obter a licença do WHMCS?"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000533014"
                target="_blank"
              />
            </li>
            <li>
              <Link
                text="Como instalar o WHMCS?"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000533034-Como-instalar-o-WHMCS-"
                target="_blank"
              />
            </li>
            <li>
              <Link
                text="Quais os problemas mais comuns no WHMCS?"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000533074"
                target="_blank"
              />
            </li>
          </ul>
          <br />
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              A licença gratuita esta disponível para os planos de Revenda de hospedagem e Dedicado
            </li>
            <li>
              A licença gratuita é limitada a 249 contas cadastradas no WHMCS.
            </li>
            <li>
              Os planos de Revenda de hospedagem e Dedicado incluem uma única licença gratuita do WHMCS.
            </li>
            <li>
              A licença WHMCS oferecida pela HostGator só é válida se o WHMCS estiver instalado na sua conta na HostGator.
            </li>
          </ul>
        </>
      ),
      placeholders: {
        domain: 'Informe o domínio utilizado ao fazer a solicitação',
        email: 'Informe o email utilizado ao fazer a solicitação',
        problem: 'Descreva seu problema e diga qual a mensagem de erro (se existir) ',
        directory: 'Diretório onde está instalado o WHMCS',
        print: 'Possui print de algum erro ou falha? Nos envie!',
        addDocumentButton: 'Anexar documento',
        agreed: 'Confirmo que li e concordo com os termos acima',
      },
      subject: 'Suporte Técnico - Aquisição Licença WHMCS',
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Aquisição Licença WHMCS',
        agreed: ({ text, bool }) => `${text}: ${bool ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    migrateReseller: {
      title: 'Preencha os dados abaixo!',
      info: (
        <>
          <strong>Leia com atenção os termos a seguir:</strong>
          <ul>
            <li>
              {'Leia os nossos '}
              <Link
                text="Termos de Migração"
                href="https://www.hostgator.com.br/termos-de-migracao"
                target="_blank"
              />
            </li>
            <li>
              {'Veja também '}
              <Link
                text="quais são as nossas compatibilidades"
                href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000384693"
                target="_blank"
              />
              {' para ver se está adequado a sua necessidade'}
            </li>
            <li>
              {'Para a '}
              <strong>migração entre planos da HostGator</strong>
              {' é necessária a contratação de um novo plano. Se você não desejar continuar com o plano antigo, depois que a migração for finalizada, solicite o cancelamento'}
            </li>
            <li>
              <strong>
                Ao solicitar uma migração não haverá reembolso
              </strong>
              {' (devolução do seu dinheiro) caso decida cancelar o plano'}
            </li>
            <li>
              {'Migramos gratuitamente até 30 contas, para além disso, '}
              <Link
                text="entre em contato com o Vendas através do nosso site"
                href="https://www.hostgator.com.br/"
                target="_blank"
              />
            </li>
          </ul>
          <br />
          <span>
            <strong>IMPORTANTE:</strong>
            {' NÃO CANCELE seu plano antigo até que os arquivos tenham sido migrados, testados e o DNS alterado corretamente.(Lembre-se de aguardar o prazo de propagação, de 24 à 48h)'}
          </span>
        </>
      ),
      subject: 'Suporte Técnico - Migração de Revenda',
      placeholders: {
        domain: 'Domínio registrado na empresa de hospedagem anterior',
        panel: 'Qual o painel administrativo da empresa de hospedagem anterior?',
        other: 'Outro',
        user: 'Informe o nome do usuário do painel da empresa de hospedagem anterior',
        password: 'Informe a senha desse usuário do painel da empresa de hospedagem anterior',
        link: 'Link para acesso ao painel da empresa de hospedagem anterior',
        multipleDomainsLabel: 'Se você selecionou o painel WHM ou Plesk - Informe na caixa de texto abaixo no máximo 30 domínios que devem ser migrados gratuitamente, mantendo um por linha conforme o exemplo',
        multipleDomainsPlaceHolder: 'Exemplo:\ndominio1.com.br\ndominio2.com.br\ndominio3.com.br',
        mutipleDomainsCpanelOtherLabel: 'Se você selecionou o painel cPanel ou Outro - Informe na caixa de texto abaixo no máximo 30 domínios (com usuário e senha do acesso ao painel) que devem ser migrados gratuitamente, mantendo um por linha conforme o exemplo',
        mutipleDomainsCpanelOtherPlaceholder: 'Exemplo:\ndominio1.com.br / usuário painel / senha de acesso ao painel\ndominio2.com.br  / usuário painel / senha de acesso ao painel\ndominio3.com.br  / usuário painel / senha de acesso ao painel',
        agreed: 'Confirmo que li e concordo com os Termos de Migração.',
      },
      body: {
        product: selectedProduct => `Produto: ${selectedProduct.name} (${selectedProduct.domain}) - Usuário: ${selectedProduct.username}`,
        request: 'Solicitação: Migração de Revenda',
        agreed: ({ text, bool }) => `${text}: ${bool ? 'Sim' : 'Não'}`,
        productId: selectedProduct => `ID do produto: ${selectedProduct.id}`,
      },
      send: 'Enviar',
    },
    successModal: {
      title: 'Recebemos seu chamado!',
      description: (
        <>
          <strong>Fique atento ao prazo de resposta.</strong>
          <br />
          <span>
            {'Em até '}
            <strong>48h úteis</strong>
            {' (De segunda à sexta) você receberá um retorno do nosso time de suporte.'}
          </span>
        </>
      ),
      closeLabel: 'Voltar para suporte',
    },
    names: {
      internalMigration: 'Migração Interna (Entre contas HostGator)',
      restorateBackupHgRoutine: 'Restauração de Backup (Rotina HostGator)',
      userBackupRestore: 'Restauração de backup (arquivo fornecido pelo cliente)',
      generateBackup: 'Gerar Backup',
      withoutEmailAccess: 'Não tenho acesso ao e-mail cadastrado',
      changePrimaryDomain: 'Alteração de Domínio Primário',
      changeDueDate: 'Alterar Data de Vencimento',
      googleAdwordsCoupon: 'Cupom Google AdWords',
      externalMigration: 'Migração externa (Outros provedores)',
      installExternalSsl: 'Instalação de SSL de Outra Empresa',
      financialDoubts: 'Dúvidas Gerais',
      restorateCancelledPlan: 'Restauração de Plano Cancelado',
      generateCSR: 'Gerar CSR',
      restorateSiteBuilderAccess: 'Restauração de acesso ao editor do Criador de Sites',
      affiliateProgram: 'Dúvidas exclusivamente sobre o Programa de Afiliados',
      nationalDomainTransfer: 'Transferência de Domínio Nacional',
      upgradeVps: 'Upgrade de Plano',
      upgradeSiteLock: 'Upgrade de Plano - SiteLock',
      upgradeCodeGuard: 'Upgrade de Plano - CodeGuard',
      ChangePaymentcycle: 'Alterar Ciclo de Pagamento',
      privateSslCertificate: 'Certificado SSL Privado',
      widlcardSslCertificate: 'Certificado SSL Wildcard',
      enterPrisessslCertificate: 'Certificado SSL Empresarial',
      installWhmcs: 'Instalação do WHMCS',
      reloadRebuild: 'Solicitação de Reload/Rebuild',
      moreEmails: 'Aumento no envio de e-mails',
      migrateVpsDedicated: 'Migração de Servidor Dedicado e VPS',
      migrateAccountVpsDedicated: 'Migración de Cuenta de VPS/Dedicado',
      updateWhmcsLicense: 'Atualização de Licença WHMCS',
      whmcsAcquisition: 'Aquisição Licença WHMCS',
      migrateReseller: 'Migração de Revenda',
    },
  },
  history: {
    attachFile: 'Anexar arquivo',
    draggableTitle: 'Selecione um arquivo do seu dispositivo ou arraste e solte o arquivo nessa área.',
    findFile: 'Buscar arquivo',
    placeHolder: 'Envie uma mensagem',
    send: 'Enviar',
    status: {
      closed: 'Finalizado',
      solved: 'Concluído',
      new: 'Aberto',
      open: 'Em Aberto',
      opened: 'Em Aberto',
      pending: 'Aguardando Respsota',
    },
    emptyLabel: 'Não foi possível carregar as informações  do ticket',
    emptyCta: 'Ir para a lista de tickets',
    ticketDate: ({ comment }) => {
      const isToday = isSameDay(new Date(comment.created_at), new Date());
      if (isToday) {
        return format(new Date(comment.created_at), 'H:mm aa');
      }

      return `${format(new Date(comment.created_at), 'EEEE, dd/MM/yyyy', {
        locale: ptBR,
      })} às ${format(new Date(comment.created_at), 'H:mm aa', {
        locale: ptBR,
      })}`;
    },
  },
  underMaintenance: (
    <>
      Estamos em manutenção
      <br />
      Acompanhe seus tickets no seu e-mail.
    </>
  ),
};
