const styles = theme => ({

  imgLogo: {
    display: 'inline-block',
  },

  divContentLogo: {
    width: '50%',
    textAlign: 'right',
  },

  divLogos: {
    display: 'inline-block',
    marginRight: '40px',
    marginTop: '21px',
    width: '96px',
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },

  logo: {
    display: 'inline-block',
    height: '24px',
    marginLeft: '16px',
    marginTop: '11px',
    width: '24px',
  },

  logoTitle: {
    color: theme.palette.grey.light,
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '0.01px',
    marginLeft: '4px',
    opacity: 1,
    textAlign: 'left',
  },

  details: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '21px',
    minWidth: '326px',
    width: '50%',
    [theme.breakpoints.down(1285)]: {
      marginBottom: '21px',
    },

    [theme.breakpoints.down(745)]: {
      width: '100%',
    },
  },

  emailIcon: {
    fontSize: '24px',
    marginRight: '20px',
  },

  activeIcon: { fill: theme.color.oceanGreen },
  nullIcon: { fill: theme.color.coral },
  pendingIcon: { fill: theme.color.brightSun },
  provisioningIcon: { fill: theme.color.brightSun },
  suspendedIcon: { fill: theme.color.valencia },
  terminatedIcon: { fill: theme.color.geyser },
  expiredIcon: { fill: theme.color.geyser },

  proEmailDataWrapper: {
    alignSelf: 'flex-start',
    width: '100%',
  },

  forceCenter: {
    alignSelf: 'center',
  },

  info: {
    fill: theme.color.indigo,
    height: '16px',
  },

  proEmailDomain: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  proEmailStatus: {
    borderRadius: '2px',
    color: theme.color.white,
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    marginTop: '10px',
    padding: '3px 6px',
  },

  activeProEmailStatus: { backgroundColor: theme.color.oceanGreen },
  pendingProEmailStatus: { backgroundColor: theme.color.brightSun },
  provisioningProEmailStatus: { backgroundColor: theme.color.brightSun },
  suspendedProEmailStatus: { backgroundColor: theme.color.valencia },
  terminatedProEmailStatus: { backgroundColor: theme.color.geyser },
  expiredProEmailStatus: { backgroundColor: theme.color.geyser },
  nullProEmailStatus: { backgroundColor: theme.color.coral },
});

export default styles;
