import React, { useEffect, useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './GenerateErrorPixModal.styles';
import useLocale from '@/hooks/useLocale';
import QRCodeError from '@/media/billing/QRCodeError.svg';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import { invoicesActions } from '@/redux/modules';
import { INVOICES_STATUS } from '@/config/invoices/invoiceStatus';
import { useDispatch } from 'react-redux';
import Loader from '@/components/Layout/Loader';
import { IconError } from '@/icons';
import { isMobile } from '@/utils/Validators/validation';
import { OriginCalling } from '../PaymentModal/PaymentModal.types';
import { billingAnalytics } from '@/analytics';

const GenerateErrorPixModal = ({
  onCloseClick = null,
  onUpdateQRCode = null,
  invoiceId,
  onSwitchPaymentMethod = null,
  onPaymentSuccessPix = null,
  originCalling = null,
}) => {
  const { billing: billingLocale } = useLocale();
  const dispatch = useDispatch();

  const {
    manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale,
    generateErrorPixModal: generateErrorPixModalLocale,
  } = billingLocale;

  const titleModalLabel = useState(manageSubscriptionPaymentInvoiceModalLocale.titleLabel);

  const descriptionLineLabel = useState(generateErrorPixModalLocale.descriptionLineLabel);
  const changePaymentMethodButtonLabel = useState(generateErrorPixModalLocale.changePaymentMethodLabel);
  const updateQRCodeButtonLabel = useState(generateErrorPixModalLocale.updateQRCodeLabel);
  const [loading, setLoading] = useState(false);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const isCallingModalBySubscription = () => originCalling === OriginCalling.SUBSCRIPTION_MANAGER;
  const isCallingModalByInvoice = () => originCalling === OriginCalling.INVOICES_MANAGER;
  const isCallingModalBySubscriptionList = () => originCalling === OriginCalling.SUBSCRIPTION_LIST;

  useEffect(() => {
    loadReCaptcha();
  }, [setLoading]);

  const redirectToSwitchPaymentMethod = (data) => {
    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }
    onSwitchPaymentMethod && onSwitchPaymentMethod();
  };

  const redirectToUpdateQRCodeModal = (data) => {
    if (data && data.status === INVOICES_STATUS.PAID) {
      onPaymentSuccessPix && onPaymentSuccessPix();
      return true;
    }
    onUpdateQRCode && dispatch(invoicesActions.invoices.generatePix.request(invoiceId, onUpdateQRCode));

    setLoading(false);
  };

  const handleOnCloseClick = (event) => {
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('7_fechar');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('7_fechar');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('7_fechar');
    }
    onCloseClick && onCloseClick(event);
  };

  const handleOnSwitchPaymentMethod = () => {
    setLoading(true);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('7_alterar_forma');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('7_alterar_forma');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('7_alterar_forma');
    }
    dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, redirectToSwitchPaymentMethod));
  };

  const handleOnUpdateQRCode = () => {
    setLoading(true);
    if (isCallingModalBySubscription()) {
      subscriptionAnalytics.executePaymentClick('7_atualizar_qrcode');
    }
    if (isCallingModalByInvoice()) {
      subscriptionAnalytics.executePaymentInvoiceManagerClick('7_atualizar_qrcode');
    }
    if (isCallingModalBySubscriptionList()) {
      subscriptionAnalytics.executePaymentInSubscriptionsListClick('7_atualizar_qrcode');
    }
    dispatch(invoicesActions.invoices.loadDetails.request(invoiceId, redirectToUpdateQRCodeModal));
  };

  return (
    <Modal
      maxWidth={726}
      onClose={handleOnCloseClick}
      testId="generate-error-pix-modal"
      disablePadding
      fullScreen={isMobile}
    >
      <Styles.Content>
        <Styles.Header>
          <Styles.TitleModal data-testid="title-modal-generate-erro-pix">{titleModalLabel}</Styles.TitleModal>
        </Styles.Header>
        <Styles.ContentData>
          <Styles.Info>
            <Styles.InfoIcon>
              <IconError
                size="26"
                testId="icon-attention-generate-error-pix"
              />
            </Styles.InfoIcon>
            <Styles.Label data-testid="description-line-modal-data">
              {descriptionLineLabel}
            </Styles.Label>
          </Styles.Info>
          <Styles.QRCodeError
            src={QRCodeError}
            loading="lazy"
          />
        </Styles.ContentData>
        {!loading && (
        <Styles.Controls>
          <Styles.ControlButton>
            <Button
              testId="switch-payment-method-button"
              onClick={handleOnSwitchPaymentMethod}
              variant="text"
              label={changePaymentMethodButtonLabel}
            />
            <Button
              testId="update-qrcode-pix"
              onClick={handleOnUpdateQRCode}
              label={updateQRCodeButtonLabel}
            />
          </Styles.ControlButton>
        </Styles.Controls>
        )}
        {loading && (
          <Loader />
        )}
      </Styles.Content>
    </Modal>
  );
};


export default GenerateErrorPixModal;
