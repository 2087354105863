import React from 'react';
import { Button } from '@/pages/common/v1/Button';
import * as Styles from './AsaasFourthRow.styles';
import bannerSnappy from '@/media/asaas/snappy-banner.svg';
import ra100 from '@/media/asaas/ra100.svg';

const AsaasFourthRow = ({
  testId = 'asaas-fourth-row',
  title = 'title',
  description = 'description sample',
  buttonLabel = 'button label',
  moveScreenToTop,
}) => (
  <Styles.Container data-testid={testId}>
    <Styles.ContentWrapper>
      <Styles.ButtonWrapper>
        <Button
          testId={`${testId}-button`}
          label={buttonLabel}
          onClick={() => moveScreenToTop()}
          variant="secondary"
          customStyles={{
            backColor: 'transparent',
          }}
        />
      </Styles.ButtonWrapper>
      <Styles.Title data-testid={`${testId}-title`}>{title}</Styles.Title>
      <Styles.Description data-testid={`${testId}-description`}>
        {description}
      </Styles.Description>
      <Styles.RaImage src={ra100} />
    </Styles.ContentWrapper>
    <Styles.BannerSnappy src={bannerSnappy} />
  </Styles.Container>
);

export default AsaasFourthRow;
