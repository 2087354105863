import React from 'react';
import * as Style from './IconFolder.style';

const IconFolder = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-folder">
    <svg id="icon_gmd_language_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width="24" height="24" viewBox="0 0 24 24">
      <path id="Caminho_247503" data-name="Caminho 247503" d="M0,0H24V24H0Z" fill="none" />
      <g id="Grupo_1327" data-name="Grupo 1327">
        <path id="Caminho_10" data-name="Caminho 10" d="M10,4H4A2.006,2.006,0,0,0,2,6V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V8a2.006,2.006,0,0,0-2-2H12Z" />
        <path id="Caminho_11" data-name="Caminho 11" d="M0,0H24V24H0Z" fill="none" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconFolder;
