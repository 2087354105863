const styles = theme => ({
  card: {
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '5px',
    boxShadow: '0px 1px 3px #00000029',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    maxWidth: '408px',
    overflow: 'hidden',
    padding: '16px 24px',
    width: '100%',

    [theme.breakpoints.down(758)]: {
      marginRight: 'unset',
      maxWidth: 'unset',
      width: '100%',
    },
  },
});

export default styles;
