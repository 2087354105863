import React from 'react';
import * as Style from './IconThrashCan.style';

const IconThrashCan = ({
  color = '#707070',
  size = 24,
  testId = 'icon-thrashCan',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" width={size} height={size}>
      <path d="M16,9V19H8V9h8M14.5,3h-5l-1,1H5V6H19V4H15.5ZM18,7H6V19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2Z" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconThrashCan;
