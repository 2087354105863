const styles = theme => ({
  title: {
    fontSize: '24px',
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center',
    color: theme.color.emperor,
    margin: '43px 0 20px 0',
  },
});

export default styles;
