export const ProductsActionTypes = {
  REQUEST_GET_PRODUCTS: 'REQUEST_GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_SET: 'GET_PRODUCTS_SET',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  SET_HOME_PAGE: 'SET_HOME_PAGE',
  SET_PRODUCT_VISUALIZED: 'SET_PRODUCT_VISUALIZED',
  SET_PRODUCT_VISUALIZED_FAILURE: 'SET_PRODUCT_VISUALIZED_FAILURE',
  SET_CPANEL_LOADING: 'SET_CPANEL_LOADING',
  GET_CPANEL_LINK: 'GET_CPANEL_LINK',
  SET_ADD_ALERT: 'SET_ADD_ALERT',
  REQUEST_CLEAR_NEW_ALERTS: 'REQUEST_CLEAR_NEW_ALERTS',
  SET_CLEAR_NEW_ALERTS: 'SET_CLEAR_NEW_ALERTS',
  SET_ALERTS_VISIBILITY: 'SET_ALERTS_VISIBILITY',
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  SET_ALL_PRODUCTS_LIST: 'SET_ALL_PRODUCTS_LIST',
};
