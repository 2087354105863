import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './CloudFlareHandler.styles';
import { useHistory, useParams } from 'react-router';
import useLocale from '@/hooks/useLocale';
import CloudFlareHeader from './components/CloudFlareHeader';
import DomainItem from './components/DomainItem';
import { useDispatch, useSelector } from 'react-redux';
import { faqActions, sitesActions } from '@/redux/modules';
import {
  Button, Icon404File, IconLoading, IconSearchFail,
  Skeleton,
} from 'gatorcomponents';
import { cloudflareActions } from '@/redux/modules/cloudflare';
import { ChangePlanModal } from './components/ChangePlanModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CLOUDFLARE_MANAGE_CDN, CHARLIE_CLOUDFLARE, CHARLIE_USE_MOCKOON } from '@/config/GrowthBook/constants';
import { SUPPORT_DOMAINS_URL } from '@/config/urls/domainsUrls';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { ORIGIN, STATUS } from './CloudFlareHandler.types';
import { getCookie, setCookie } from '@/utils/Application/cookies';
import ActivateDeactivateInternalDomainModal from './components/ActivateDeactivateInternalDomainModal';
import UseDnsModal from '@/hooks/dns/useDnsModal/';
import { SELECT_MODAL_STEPS } from '@/hooks/dns/useDnsModal/useDnsModal.types';

let filterTimeout = null;
export const CloudFlareHandler = () => {
  const { sites, cloudflare } = useSelector(state => state);
  const { list: listItems } = cloudflare;
  const { hostingId } = useParams();
  const dispatch = useDispatch();
  const { cloudflare: cloudflareLocale, routes: routesLocale } = useLocale();
  const [inputedFilter, setInputedFilter] = useState('');
  const [currentPlan, setCurrentPlan] = useState({ });
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [changePlanModal, enableChangePlanModal] = useState(false);
  const [changePlanSelectedOption, setChangePlanSelectedOption] = useState(null);
  const faq = useSelector(state => state.newFaq);
  const [filteredItems, setFilteredItems] = useState(listItems);
  const [activateDeactivateInternalDomainModal, setActivateDeactivateInternalDomainModal] = useState({});
  const [activateExternalDomainModal, setActivateExternalDomainModal] = useState({});

  const cloudflareIsEnabled = useFeatureIsOn(CHARLIE_CLOUDFLARE);
  const useMockoonEnabled = useFeatureIsOn(CHARLIE_USE_MOCKOON);
  const manageCdnIsEnabled = useFeatureIsOn(CHARLIE_CLOUDFLARE_MANAGE_CDN);

  const history = useHistory();

  const redirectUserOut = useCallback(() => {
    !cloudflareIsEnabled && history.push(`${routesLocale.sites}`);
  }, [history, cloudflareIsEnabled, routesLocale]);

  useEffect(() => {
    redirectUserOut();
  }, [redirectUserOut]);

  const handleActivateDomain = useCallback(() => {
    dispatch(cloudflareActions.cdn.activateCdn({
      hostingId: currentPlan.hosting_id,
      domain: activateDeactivateInternalDomainModal.domain || activateExternalDomainModal.domain,
      domainOrigin: activateDeactivateInternalDomainModal.domain_origin || activateExternalDomainModal.domain_origin,
      useMockoonEnabled,
    }));
  }, [
    activateDeactivateInternalDomainModal.domain,
    activateDeactivateInternalDomainModal.domain_origin,
    currentPlan,
    activateExternalDomainModal,
    useMockoonEnabled,
    dispatch,
  ]);


  const clearCloudFlareLastActivation = useCallback(() => {
    dispatch(cloudflareActions.cdn.setActivatedDomain({}));
  }, [dispatch]);

  const {
    selectManualDnsModal,
    setSelectManualDnsStep,
    requestDomainDnsFromWhoIs,
    domainDns,
  } = UseDnsModal({
    domain: activateExternalDomainModal.domain,
    onClose: () => setActivateExternalDomainModal(false),
    handleActivateDomain,
    cloudflare,
    clearCloudFlareLastActivation,
  });

  const gotDomainDn = useCallback(() => {
    if (domainDns) {
      setSelectManualDnsStep(SELECT_MODAL_STEPS.GET_DOMAIN_ORIGINAL_REGISTRATION);
    }
  }, [domainDns, setSelectManualDnsStep]);

  useEffect(() => {
    gotDomainDn();
  }, [gotDomainDn]);

  const handleDeactivateDomain = useCallback(() => {
    dispatch(cloudflareActions.cdn.deactivateCdn({
      hostingId: currentPlan.hosting_id,
      domain: activateDeactivateInternalDomainModal.domain,
      domainOrigin: activateDeactivateInternalDomainModal.domain_origin,
      useMockoonEnabled,
    }));
  }, [
    activateDeactivateInternalDomainModal.domain,
    activateDeactivateInternalDomainModal.domain_origin,
    currentPlan,
    useMockoonEnabled,
    dispatch,
  ]);

  const requestSites = useCallback(() => {
    dispatch(sitesActions.sites.request({ withGatorSolutions: true }));
    dispatch(faqActions.faq.request.faqCloudflare());
  }, [dispatch]);

  useEffect(() => {
    requestSites();
  }, [requestSites]);

  const filterCards = useCallback(() => {
    let newFilteredItems = listItems;

    if (inputedFilter) {
      newFilteredItems = [...listItems].filter(item => item.domain.toLowerCase().includes(inputedFilter.toLowerCase()));
    }

    setFilteredItems(newFilteredItems);
  }, [
    inputedFilter,
    listItems,
  ]);

  const handleFilter = useCallback(() => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      filterCards();
    }, 1000);
  }, [filterCards]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const requestDomainsPerHostingId = useCallback(() => {
    if (currentPlan && currentPlan.hosting_id) {
      dispatch(
        cloudflareActions.domains.domainsRequest({ hostingId: currentPlan.hosting_id, useMockoonEnabled }),
      );
    }
  }, [dispatch, currentPlan, useMockoonEnabled]);

  useEffect(() => {
    requestDomainsPerHostingId();
  }, [requestDomainsPerHostingId]);

  const getUrlPlanData = useCallback(() => {
    if (sites.list.length && hostingId) {
      const site = sites.list.find(item => item.hosting_id === Number(hostingId));
      site && setCurrentPlan(site);
    }
  }, [sites, hostingId]);

  useEffect(() => {
    getUrlPlanData();
  }, [getUrlPlanData]);

  const getCloudFlareHeaderProps = useCallback(() => {
    const handleChangePlan = () => {
      enableChangePlanModal(true);
    };

    const verifyRefreshTimeLimit = () => {
      const lastRefresh = Number(getCookie('last-cloudflare-refresh'));
      const currentTimestamp = Date.now();

      const timeLimit = 60 * 60 * 1000;

      const timeDifferenceInHours = (currentTimestamp - lastRefresh) / timeLimit;

      if (!lastRefresh) {
        return true;
      }
      if (timeDifferenceInHours >= 1) {
        return true;
      }
      return false;
    };

    const setLastRefreshOnCookie = (timestamp) => {
      setCookie('last-cloudflare-refresh', timestamp);
    };

    const hasOpenStatus = listItems.find(item => (
      item.status === STATUS.PENDING_DEACTIVATION
      || item.status === STATUS.PENDING
      || item.status === STATUS.DELAY
      || (item.status === STATUS.NONEXISTENT && item.origin === ORIGIN.EXTERNAL)
    ));

    return ({
      hostingLabel: cloudflareLocale.hostingLabel,
      changeCta: cloudflareLocale.change,
      cloudflareCdnExplanation: cloudflareLocale.cloudflareCdnExplanation,
      searchPerDomainLabel: cloudflareLocale.searchPerDomain,
      refreshStatusLabel: cloudflareLocale.refreshStatus,
      noOpenStatusLabel: cloudflareLocale.noOpenStatus,
      waitRefreshTimeLimitLabel: cloudflareLocale.waitRefreshTimeLimit,
      cloudflareStatusLoading: cloudflare.loading,
      currentPlan: currentPlan.product_name || '',
      currentDomain: currentPlan.domain || '',
      inputedFilter,
      sites: sites.list.filter(item => item.cloudflare_available && item.status.toLowerCase() === 'active'),
      hasOpenStatus,
      refreshTimeLimitHasEnded: verifyRefreshTimeLimit(),
      handleRefreshStatus: requestDomainsPerHostingId,
      setLastRefreshOnCookie,
      setInputedFilter,
      handleChangePlan,
    });
  }, [
    cloudflareLocale,
    inputedFilter,
    setInputedFilter,
    requestDomainsPerHostingId,
    cloudflare.loading,
    currentPlan,
    listItems,
    sites,
  ]);

  const getChangePlanModal = useCallback(() => {
    const handleSetPlan = (plan) => {
      setCurrentPlan(plan);
      history.push(`${routesLocale.products}${routesLocale.cloudflare}/${plan.hosting_id}`);
    };

    return {
      sites: sites.list.filter(item => item.cloudflare_available && item.status.toLowerCase() === 'active'),
      onClose: () => enableChangePlanModal(false),
      handleSetPlan,
      changePlanSelectedOption,
      setChangePlanSelectedOption,
      currentPlan,
      backCta: cloudflareLocale.backCta,
      continueCta: cloudflareLocale.continueCta,
      title: cloudflareLocale.changePlanTitle,
      mainDomain: cloudflareLocale.main,
    };
  }, [
    sites.list,
    changePlanSelectedOption,
    setChangePlanSelectedOption,
    currentPlan,
    cloudflareLocale,
    history,
    routesLocale,
  ]);

  const getDomainItemProps = ({ item }) => {
    let nameServerTooltipContent = null;
    if (item.cloudflare_ns1 && item.cloudflare_ns2) {
      nameServerTooltipContent = cloudflareLocale.nameServerTooltip(item.cloudflare_ns1, item.cloudflare_ns2);
    }

    const handleVerifyDomain = () => {
      requestDomainDnsFromWhoIs(item.domain);
      setActivateExternalDomainModal(item);
    };

    const manageDomainCdn = () => {
      if (item.cloudflare_internal_id) {
        history.push(`${routesLocale.products}${routesLocale.cloudflare}${routesLocale.domaindetail}/${item.cloudflare_internal_id}`);
      }
    };

    return {
      domain: item.domain,
      status: item.status,
      origin: item.domain_origin,
      activateCdn: cloudflareLocale.activateCdn,
      manageLabel: cloudflareLocale.manage,
      verifyDomainLabel: cloudflareLocale.verifyDomain,
      messageOngoingActivation: cloudflareLocale.ongoingActivation,
      messageOngoingDeactivation: cloudflareLocale.ongoingDeactivation,
      messageExternalDomain: cloudflareLocale.externalDomain,
      nameServerTooltipContent,
      statusLabel: {
        nonexistentexternal: cloudflareLocale.status.nonexistentexternal,
        pendinginternal: cloudflareLocale.status.pendinginternal,
        pendingexternal: cloudflareLocale.status.pendingexternal,
        delayinternal: cloudflareLocale.status.delayinternal,
        delayexternal: cloudflareLocale.status.delayexternal,
        pendingdeactivationinternal: cloudflareLocale.status.pendingdeactivationinternal,
        pendingdeactivationexternal: cloudflareLocale.status.pendingdeactivationexternal,
        activationpointingerrorinternal: cloudflareLocale.status.activationpointingerrorinternal,
        activationpointingerrorexternal: cloudflareLocale.status.activationpointingerrorexternal,
        inactivationpointingerrorinternal: cloudflareLocale.status.inactivationpointingerrorinternal,
        inactivationpointingerrorexternal: cloudflareLocale.status.inactivationpointingerrorexternal,
      },
      pendingTooltip: cloudflareLocale.pendingTooltip,
      cdnAlreadyActive: cloudflareLocale.cdnAlreadyActive,
      onClickToggle: () => setActivateDeactivateInternalDomainModal(item),
      onVerifyExternalDomain: handleVerifyDomain,
      manageDomainCdn,
      manageCdnDisabledLabel: cloudflareLocale.manageCdnDisabledLabel,
      manageCdnIsEnabled,
    };
  };

  const getActivateDeactivateInternalDomainModalProps = useCallback(() => {
    const handleCloseModal = () => {
      clearCloudFlareLastActivation();
      setActivateDeactivateInternalDomainModal({});
    };

    return ({
      onClose: handleCloseModal,
      onContinueActivating: handleActivateDomain,
      onContinueDeactivating: handleDeactivateDomain,
      toActivateTitle: cloudflareLocale.activateDeactivateInternalModal.toActivateTitle,
      toDeactivateTitle: cloudflareLocale.activateDeactivateInternalModal.toDeactivateTitle,
      deactivatingTitle: cloudflareLocale.activateDeactivateInternalModal.deactivatingTitle,
      activatingTitle: cloudflareLocale.activateDeactivateInternalModal.activatingTitle,
      toActivateDescription: cloudflareLocale.activateDeactivateInternalModal.toActivateDescription,
      activatingDescription: cloudflareLocale.activateDeactivateInternalModal.activatingDescription,
      deactivatingDescription: cloudflareLocale.activateDeactivateInternalModal.deactivatingDescription,
      toDeactivateDescription: cloudflareLocale.activateDeactivateInternalModal.toDeactivateDescription,
      cancelCta: cloudflareLocale.activateDeactivateInternalModal.cancelCta,
      confirmationCta: cloudflareLocale.activateDeactivateInternalModal.confirmationCta,
      continueActivationCta: cloudflareLocale.activateDeactivateInternalModal.continueActivationCta,
      continueDeactivationCta: cloudflareLocale.activateDeactivateInternalModal.continueDeactivationCta,
      currentStatus: activateDeactivateInternalDomainModal.status,
      domain: activateDeactivateInternalDomainModal.domain,
      loadingActivation: cloudflare.loadingActivation,
      lastActivation: cloudflare.lastActivation,
    });
  }, [
    activateDeactivateInternalDomainModal,
    cloudflare.loadingActivation,
    cloudflareLocale,
    handleActivateDomain,
    cloudflare.lastActivation,
    clearCloudFlareLastActivation,
    handleDeactivateDomain,
  ]);

  if (
    sites.loadingSites
    || cloudflare.loading
    || (!!listItems.length && !filteredItems.length)
  ) {
    return (
      <Styles.Container data-testid="CloudFlareHandlerContainer">
        <Styles.SkeletonItem>
          <Styles.SkeletonHeaderColumn>
            <Skeleton variant="headline" width="268px" />
            <Skeleton variant="textLine" width="609px" />
            <Skeleton variant="textLine" width="609px" />
          </Styles.SkeletonHeaderColumn>

          <Skeleton variant="rectangle" width="320px" height="56px" />
        </Styles.SkeletonItem>

        <Styles.ListWrapper>
          {Array.from(Array(10).keys()).map(item => (
            <Styles.SkeletonItem key={`skeleton_${item}`}>
              <Skeleton variant="paragraph" width="660px" />

              <Styles.SkeletonButton>
                <Skeleton variant="button" />
                <Skeleton variant="button" />
              </Styles.SkeletonButton>
            </Styles.SkeletonItem>
          ))}
          <IconLoading />
        </Styles.ListWrapper>
      </Styles.Container>
    );
  }

  return (
    <>
      {activateExternalDomainModal && activateExternalDomainModal.domain && (
        selectManualDnsModal()
      )}

      {changePlanModal && (
        <ChangePlanModal
          {...getChangePlanModal()}
        />
      )}

      {activateDeactivateInternalDomainModal && activateDeactivateInternalDomainModal.domain && (
        <ActivateDeactivateInternalDomainModal
          {...getActivateDeactivateInternalDomainModalProps()}
        />
      )}

      <Styles.Container data-testid="CloudFlareHandlerContianer">
        <CloudFlareHeader {...getCloudFlareHeaderProps()} />

        <Styles.ListWrapper>
          {!filteredItems.length && (
            <Styles.EmptyCard>
              {cloudflare.domainsError
                ? <Icon404File size="xl" color="lowLight" />
                : <IconSearchFail size="xl" color="lowLight" />
              }

              <Styles.NoDomainText>
                {cloudflare.domainsError
                  ? cloudflareLocale.domainsError
                  : cloudflareLocale.noDomains}
              </Styles.NoDomainText>

              {cloudflare.domainsError && (
                <Styles.RefreshButtonRow>
                  <Button
                    label={cloudflareLocale.refreshList}
                    onClick={requestDomainsPerHostingId}
                    size="large"
                  />
                </Styles.RefreshButtonRow>
              )}
            </Styles.EmptyCard>
          )}

          {filteredItems.slice(0, (itemsPerPage * page)).map(item => (
            <DomainItem
              key={`${item.hostingId}_${item.domain}`}
              {...getDomainItemProps({ item })}
            />
          ))}
        </Styles.ListWrapper>

        {(filteredItems.length > (itemsPerPage * page)) && (
          <Styles.ButtonRow>
            <Button
              variant="secondary"
              label={cloudflareLocale.showMore}
              onClick={() => setPage(page + 1)}
            />
          </Styles.ButtonRow>
        )}

        <Styles.FaqRow>
          <FaqDefault
            questions={faq.cloudflare.questions}
            link={SUPPORT_DOMAINS_URL}
            loading={faq.loading}
          />
        </Styles.FaqRow>
      </Styles.Container>
    </>
  );
};

export default CloudFlareHandler;
