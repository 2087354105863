import { Paper } from '@material-ui/core';
import * as Styles from './SplitPaymentOfferModal.styles';
import React, { } from 'react';
import useLocale from '@/hooks/useLocale/useLocale';
import SplitInvoiceIcon from '@/media/icons/icone-split-fatura.svg';
import { IconClose, Link } from 'gatorcomponents';

const SplitPaymentOfferModal = ({
  onClose,
  testId = 'split-payment-offer-modal',
}) => {
  const { billing: billingLocale } = useLocale();
  const { splitPaymentOfferModal: splitPaymentOfferModalLocale } = billingLocale;

  const {
    conerTag,
    description,
    title,
    url,
    linkLabel,
  } = splitPaymentOfferModalLocale;

  const handleOnClose = () => {
    onClose && onClose();
  };
  return (
    <Styles.Wrapper data-testid={testId}>
      <Paper>
        <Styles.Content>
          <Styles.CornerTag />
          <Styles.CornerTagTextContainer>
            <Styles.CornerTagText>
              {conerTag}
            </Styles.CornerTagText>
          </Styles.CornerTagTextContainer>
          <Styles.CloseButton onClick={handleOnClose} data-testid={`${testId}-close`}>
            <IconClose color="canceledMedium" />
          </Styles.CloseButton>
          <Styles.TextContainer>
            <Styles.SplitInvoiceIcon data-testid={`${testId}-icon`} src={SplitInvoiceIcon} />
            <Styles.Title data-testid={`${testId}-title`}>
              {title}
            </Styles.Title>
            <Styles.Description data-testid={`${testId}-description`}>
              {description}
            </Styles.Description>
            <Link
              testId={`${testId}-link`}
              href={url}
              target="_blank"
              text={linkLabel}
            />
          </Styles.TextContainer>
        </Styles.Content>
      </Paper>
    </Styles.Wrapper>
  );
};

export default SplitPaymentOfferModal;
