import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    padding: 34px 0px;
  `}
`;

export const DescriptionWrapper = styled.span`
  ${({ theme }) => css`
    display:flex;
    svg{
      margin-left: 8px;

      &:hover{
        fill: ${theme.v1.color.text.featured};
      }
    }
    @media (max-width: ${theme.v1.breakpoints.sm}){
      display:inline-block;

      > div {
        display:inline-block;
      }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    span{
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.v1.font.weights.medium};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${() => css`
    max-width: 464px;
    margin: 40px auto;
  `}
`;
