import styled, { css } from 'styled-components';


export const ModalContent = styled.div`
  ${({ theme, contentNotCentered }) => css`
    align-items: ${contentNotCentered ? 'flex-start' : 'center'};
    display: flex;
    flex-direction: column;
    gap: ${!contentNotCentered && '12px'};
    padding: 8px 16px 16px 16px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      padding: 8px 4px 16px 4px;
    }
  `}
`;

export const ModalIllustration = styled.img`
  ${({ step2 }) => css`
    height: 64px;
    margin-top: ${step2 ? '32px' : '80px'};
    width: 76.22px;
  `}
`;

export const ModalButtonsWrapper = styled.div`
  ${({ theme, marginTop, marginBottom }) => css`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 24px;
    margin-top: ${marginTop || '35'}px;
    margin-bottom: ${marginBottom || '0'}px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 80px;

      div {
        margin-bottom: 0px;
      }
    }
  `}
`;

export const BackToListButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  margin-bottom: 66px;
  margin-top: 20px;
  width: 100%;
`;

export const ModalTitle = styled.h3`${({
  theme, step2, light, marginTop,
}) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${light ? theme.v2.font.sizes.md : theme.v2.font.sizes.sm};
    font-weight: ${light ? theme.v2.font.weights.regular : theme.v2.font.weights.medium};
    margin-top: ${marginTop || 10}px;
    margin-bottom: ${light ? '6px' : '3px'};
    text-align: ${light ? 'start' : 'center'};
    max-width: ${step2 && '450px'};

    span {
      color: ${theme.v2.colors.brand.primaryDark};
    }
  `}
`;

export const ModalDescription = styled.p`${({
  theme, light, maxWidth, marginBottom, marginTop,
}) => css`
    color: ${light ? theme.v2.colors.neutral.lowPure : theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin-bottom: ${`${marginBottom || 14}px`};
    margin-top: ${`${marginTop || 0}px`};
    max-width: ${maxWidth || '680'}px;
    text-align: ${light ? 'start' : 'center'};

    strong {
      color: ${light ? theme.v2.colors.brand.menuPrimaryMedium : theme.v2.colors.brand.menuPrimaryDark};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const SelectWrapper = styled.div`
  margin-top: 10px;
  max-width: 320px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 10px;
  max-width: 320px;
  width: 100%;
`;

export const RadioCard = styled.div`
  ${({ theme, first }) => css`
    align-items: flex-start;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    margin-top: ${first ? '10px' : '4px'};
    padding: 24px 19px;
    max-width: 496px;

  `}
`;

export const RadioTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const RadioTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin: 0;

    span {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const RadioDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 16.2px;
    margin: 0;
    margin-bottom: 3px;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const InputSuffix = styled.span`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v2.colors.neutral.highMedium};
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin-left: -16px;
    max-width: 230px;
    overflow: hidden;
    padding-bottom: 8px;
    padding-right: 20px;
    text-overflow: ellipsis;
    top: 50%;

    @media (max-width: ${theme.v2.breakpoints.xs}) {
      width: 70px;
    }
  `}
`;


export const Card = styled.div`
  ${({ theme, padding }) => css`
    align-items: flex-start;
    background: ${theme.v2.colors.brand.primaryUltralight};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 13px;
    padding: ${padding || '24px'};
    width: 100%;
  `}
`;

export const SubdomainText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 18.9px;
    margin-top: 0;
    word-break: break-all;
  `}
`;

export const Info = styled.p`
  ${({ theme, extraDark }) => css`
    color: ${extraDark ? theme.v2.colors.brand.menuPrimaryDark : theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 16.2px;
    margin-bottom: 0;
    margin-top: 0;
  `}
`;

export const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

export const IconCheckWrapper = styled.div`
  ${({ marginTop }) => css`
    margin-top: ${marginTop || 82}px;
    margin-bottom: -12px;
  `}
`;

export const SuccessTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 32.4px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  `}
`;

export const SuccessDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 21.6px;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 645px;
    text-align: center;

    strong {
      color: ${theme.v2.colors.neutral.lowDark};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const SuccessExternalDescription = styled.p`
  ${({ theme, smaller }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${smaller ? '18.9px' : '21.6px'};
    margin-top: 0;
    margin-bottom: 10px;
    max-width: ${smaller ? '600px' : '645px'};

    strong {
      color: ${theme.v2.colors.neutral.lowDark};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const NumberCircle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.v2.colors.brand.menuPrimaryDark};
    border-radius: 50%;
    color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    height: 24px;
    justify-content: center;
    min-width: 24px;
    width: 24px;
  `}
`;

export const Step = styled.div`
  align-items: baseline;
  display: flex;
  gap: 16px;
`;

export const SubStepList = styled.ul`
  margin-bottom:10px;
  margin-left: 23px;
  margin-top: 3px;
`;

export const SubStep = styled.li`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin-bottom: 8px;
    word-break: break-all;

    strong {
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;

export const SubStepWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 12px;
`;

export const SuccessExternalButtonWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 36px;
`;

export const ErrorAlert = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.feedback.problemUltraLight};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 13px;
    padding: 32px;
    width: 100%;
  `}
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin: 0;
  `}
`;

export const ErrorMessageBold = styled.strong`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark}; 
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 18.9px;
  `}
`;

export const AlreadyConnectedTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: 32.4px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  `}
`;

export const AlreadyConnectedDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 21.6px;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 550px;
    text-align: center;


    strong {
      color: ${theme.v2.colors.brand.primaryDark};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const DeleteAlert = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 40px;
    margin-bottom: 23px;
    width: 100%;
`;

export const DeleteAlertText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    margin: 0;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const IconWrapper = styled.div`
`;

export const ModalAlert = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 18.9px;
    max-width: 535px;

    strong{ 
      font-weight: ${theme.v2.font.weights.bold};
    }
  `}
`;
