import React from 'react';
import { Modal } from '@/pages/common';
import { Button } from '@/pages/common/v1';
import * as Styles from './ModalCreateSite.styles';
import modalDecoLine from '@/media/newSiteModal/modal-decoline.svg';
import modalDeco from '@/media/newSiteModal/modal-deco.svg';
import builderAndWordpress from '@/media/products/wordpress-sitebuildbuilder.svg';

const ModalCreateSite = ({
  title = 'title sample',
  buttonLabel = 'button label',
  backButtonLabel = 'back button label',
  descriptionFirst = 'description sample',
  descriptionSecond = 'description sample',
  onContinue,
  onClose,
}) => {
  const handleContinue = () => {
    onContinue && onContinue();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal testId="ModalCreateSite" maxWidth={630} disablePadding>
      <Styles.ContentWrapper>
        <Styles.Deco src={modalDeco} alt="Deco" />

        <Styles.Title data-testid="ModalCreateSiteTitle">
          {title}
        </Styles.Title>

        <Styles.Description>
          {descriptionFirst}
        </Styles.Description>

        <Styles.Description>
          {descriptionSecond}
        </Styles.Description>

        <Styles.Action>
          <Button
            testId="createSiteButton"
            onClick={handleContinue}
            label={buttonLabel}
          />
          <Button
            variant="text"
            testId="backButton"
            onClick={handleClose}
            label={backButtonLabel}
          />
        </Styles.Action>

        <Styles.Image src={builderAndWordpress} alt={buttonLabel} />

        <Styles.DecoLine src={modalDecoLine} alt="DecoLine" />
      </Styles.ContentWrapper>
    </Modal>
  );
};

export default ModalCreateSite;
