const styles = theme => ({
  btn: {
    '&:disabled': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: theme.color.disabled,
      cursor: 'default',
    },
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.mystic}`,
    color: theme.color.tundora,
    padding: '6px 16px',
  },
});

export default styles;
