import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    display: flex;
    flex-direction: row;
    gap: 19px;
    justify-content: flex-start;
    padding: 0px 24px 24px;

    @media (max-width:${theme.breakpoints.lg}){
      flex-direction: column;
      gap: 5px;
      padding: 0px 0px 24px;
    }
  `}
`;

export const OptionItem = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.blue.light};
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    padding: 24px;
    width: 100%;

    > span:first-child { 
      margin-right: 20px;
    }

    > a {
      margin-right: 6px;
    }

    > span:last-child {
      opacity: 0;
      transition: opacity 150ms ease-in-out;
    }

    > a:hover + span {
      opacity: 1;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    gap: 12px;
    margin-top: 8px;
    padding: 6px 6px 0px 6px;

    > svg {
      flex-shrink: 0;
      transform: scaleX(-1);
    }

    @media (max-width:${theme.breakpoints.sm}){
      padding: 6px 36px 0px 6px;
    }
  `}
`;

export const Image = styled.img`
    align-self: flex-end;
    max-width: 70px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px auto 0px 0px;

    span {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;
