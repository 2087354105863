import styled, { css, keyframes } from 'styled-components';


export const Header = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-diretion: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    padding: 24px;
  `}
`;

export const Column = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 368px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      max-width: 100%;
      min-width: unset;
      width: 100%;
    }
  `}
`;

export const RightColumn = styled(Column)`
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  max-width: 368px;
  width: 100%;
`;

export const HostingLabel = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    color: ${theme.v2.colors.neutral.lowMedium};
    margin: 0px;
  `}
`;

export const ChangePlanWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
  `}
`;

export const PlanName = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.primaryExtraDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm2};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const MainDomain = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 8px;
  `}
`;

export const CloudFlareCdnExplanation = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowMedium};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 30px 8px 0px;
  `}
`;

export const RefreshButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 16px;
`;

const loadingLoop = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const IconRefreshContainer = styled.div`
  ${({ loading }) => css`
    align-items: center;
    display: flex;
    justify-content: center;

    ${loading && css`animation: ${loadingLoop} 0.5s linear infinite`};
  `};
`;
