import shortid from 'shortid';
import { CALL_API } from '@/middleware/api';
import { VIMEO_URL_ALBUMS, API_URL_VIMEO, COURSES_VIEWS_URL } from '@/config/api';
import { COUNTRY } from '@/config/index';
import { replaceAccents, replaceSpaceForHyphen } from '@/utils/Validators/validation';
import { setDynamicBreadCrumbTitles } from '@/redux/actions/breadCrumbs';
import { isEnvironmentCountry } from '@/utils/Application/environmentInfo';
import {
  REQUEST_VIMEO_SHOWCASES, RECEIVE_VIMEO_SHOWCASES, ERROR_VIMEO_SHOWCASES, REQUEST_TRACE_COURSE_ACCESS,
  REQUEST_SHOWCASE_VIDEOS, ERROR_SHOWCASE_VIDEOS, RECEIVE_SHOWCASE_VIDEOS, RECEIVE_TRACE_COURSE_ACCESS,
  ERROR_TRACE_COURSE_ACCESS,
} from './actionsTypes';


export const traceCourseAccess = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: COURSES_VIEWS_URL,
    body: data,
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_TRACE_COURSE_ACCESS }),
      success: () => ({ type: RECEIVE_TRACE_COURSE_ACCESS }),
      error: () => ({ type: ERROR_TRACE_COURSE_ACCESS }),
    },
  },
});

const extractModuleTag = (videos) => {
  const handleModules = [];
  videos.forEach((video) => {
    if (video.tags.length > 0) {
      video.tags.forEach((tagItem) => {
        if (tagItem.tag.toLowerCase() !== COUNTRY && !handleModules.includes(tagItem.tag)) {
          handleModules.push(tagItem.tag);
        }
      });
    }
  });
  return handleModules;
};

const getTagBrand = (tags, getTag) => {
  const checkTag = obj => obj.tag === getTag;
  return tags.some(checkTag);
};

const getVideosDuration = videos => videos.reduce((acc, video) => acc + video.duration, 0);

const extractVideosByModule = (videos, courseKeyName) => {
  const extractedModules = extractModuleTag(videos);
  const modules = [];
  extractedModules.forEach((moduleName) => {
    const videosByModules = videos.filter((item) => {
      if (item.tags.length > 0 && getTagBrand(item.tags, moduleName)) {
        const handleVideoItem = item;
        handleVideoItem.id = shortid.generate();
        handleVideoItem.moduleKeyName = replaceAccents(replaceSpaceForHyphen(moduleName)).toLowerCase();
        handleVideoItem.courseKeyName = courseKeyName;
        return handleVideoItem;
      }
      return null;
    });

    const handleModuleWithVideos = {
      id: shortid.generate(),
      name: moduleName,
      keyName: replaceAccents(replaceSpaceForHyphen(moduleName)).toLowerCase(),
      total: videosByModules.length,
      duration: getVideosDuration(videosByModules),
      videos: videosByModules,
    };

    modules.push(handleModuleWithVideos);
  });
  return modules;
};

const sanitizeReceivedShowcasesVideos = (drawData) => {
  const {
    uri,
    name,
    description,
    link,
    duration,
    width,
    height,
    pictures,
    tags,
  } = drawData;

  return {
    uri,
    name,
    description,
    link,
    duration,
    width,
    height,
    pictures,
    tags,
  };
};

const receiveShowcaseVideos = (videosFromVimeo, courseId, courseKeyName) => (dispatch, getState) => {
  const sanitizedVideos = videosFromVimeo.map(video => sanitizeReceivedShowcasesVideos(video));
  const handleCourses = getState().videos.courses;
  const getVideosByModules = extractVideosByModule(sanitizedVideos, courseKeyName);

  const newCourses = handleCourses.map((item) => {
    const handleItem = item;
    if (handleItem.id === courseId) {
      handleItem.modules = getVideosByModules;
      handleItem.duration = getVideosDuration(sanitizedVideos);
      handleItem.keyName = replaceAccents(replaceSpaceForHyphen(handleItem.name)).toLowerCase();
    }
    return handleItem;
  });

  return dispatch({
    type: RECEIVE_SHOWCASE_VIDEOS,
    data: newCourses,
  });
};

export const getVideosFromShowcase = (url, courseId, courseKeyName) => ({
  [CALL_API]: {
    authorizationType: 'vimeo',
    endpoint: `${API_URL_VIMEO}${url}?direction=asc`,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SHOWCASE_VIDEOS }),
      success: response => receiveShowcaseVideos(response.data, courseId, courseKeyName),
      error: () => ({ type: ERROR_SHOWCASE_VIDEOS }),
    },
  },
});

const sanitizeReceivedVimeoShowCases = (drawData) => {
  const {
    name,
    description,
    link,
    duration,
    pictures,
  } = drawData;

  const videosUri = drawData.metadata.connections.videos.uri;
  const totalVideos = drawData.metadata.connections.videos.total;

  return {
    name,
    description,
    link,
    duration,
    pictures,
    videosUri,
    totalVideos,
  };
};

const filterVimeoShowCasesByCountry = (showCase) => {
  const handleShowCase = showCase;
  const { name: showCaseName } = handleShowCase;
  const brand = isEnvironmentCountry('br') ? '_[$BR]' : '_[$ES]';

  if (!showCaseName.includes('_')) {
    return showCase;
  }

  if (showCaseName.includes(brand)) {
    handleShowCase.name = showCaseName.replace(brand, '');
    return handleShowCase;
  }

  return false;
};

const receiveVimeoShowcases = data => (dispatch) => {
  const countryFilteredData = data.filter(filterVimeoShowCasesByCountry);
  const dynamicBreadCrumbs = [];

  const handleData = countryFilteredData.map((item) => {
    const handleItem = sanitizeReceivedVimeoShowCases(item);
    handleItem.id = shortid.generate();
    return handleItem;
  });

  dispatch({
    type: RECEIVE_VIMEO_SHOWCASES,
    data: handleData,
  });


  handleData.forEach((course) => {
    const courseKeyName = replaceAccents(replaceSpaceForHyphen(course.name)).toLowerCase();
    dynamicBreadCrumbs.push({
      urlParam: courseKeyName,
      title: course.name,
    });
    dispatch(getVideosFromShowcase(course.videosUri, course.id, courseKeyName));
  });

  dispatch(setDynamicBreadCrumbTitles(dynamicBreadCrumbs));
};

export const getVimeoShowcases = () => ({
  [CALL_API]: {
    authorizationType: 'vimeo',
    endpoint: VIMEO_URL_ALBUMS,
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_VIMEO_SHOWCASES }),
      success: response => receiveVimeoShowcases(response.data),
      error: () => ({ type: ERROR_VIMEO_SHOWCASES }),
    },
  },
});
