import React from 'react';
import * as Styles from './HelpLinks.styles';
import { IconMaterials } from '@/icons';
import { Link } from '@/pages/common';
import { Button } from '@/pages/common/v1/Button';
import { asaasAnalytics } from '@/analytics/asaas';

const HelpLinks = ({ formData, testId = 'asaas-form-help-links', accountCreated }) => {
  const handleCallAnalytics = (idx) => {
    switch (idx) {
      case 1:
        asaasAnalytics.formStepThreeMaterialOne();
        break;
      case 2:
        asaasAnalytics.formStepThreeMaterialTwo();
        break;
      case 3:
        asaasAnalytics.formStepThreeMaterialThree();
        break;
      case 4:
        asaasAnalytics.formStepThreeMaterialFour();
        break;
      case 5:
        asaasAnalytics.formStepThreeMaterialFive();
        break;
      default:
        break;
    }
  };

  const handleCallViewMore = () => {
    window.open(formData.texts[formData.activeStep].showMoreArticlesURL);
    asaasAnalytics.formStepThreeViewMore();
  };

  const thirdStepWithError = !accountCreated;
  return (
    <Styles.Materials>
      <Styles.MaterialsTitleWrapper>
        <IconMaterials />

        <Styles.MaterialTitle data-testid={`${testId}-title`}>
          {thirdStepWithError ? formData.texts[formData.activeStep].articlesTitleHelp : formData.texts[formData.activeStep].articlesTitle}
        </Styles.MaterialTitle>
      </Styles.MaterialsTitleWrapper>

      <Styles.MaterialsList>
        {formData.texts[formData.activeStep].articles.map((article, index) => (
          <Styles.Material key={article.label}>
            <Link
              testId={`${testId}-material-${index}`}
              href={article.link}
              target="_blank"
              text={article.label}
              onClick={() => handleCallAnalytics(index)}
            />
          </Styles.Material>
        ))}
      </Styles.MaterialsList>

      <Styles.ShowMoreWrapper>
        <Button
          testId={`${testId}-more-articles-button`}
          label={formData.texts[formData.activeStep].showMoreArticles}
          onClick={handleCallViewMore}
          variant="secondary"
          size="small"
        />
      </Styles.ShowMoreWrapper>
    </Styles.Materials>
  );
};

export default HelpLinks;
