import { IconInfo } from '@/icons';
import { Message } from '@/pages/common';
import React from 'react';
import * as Styles from './DnsWizardEmailConfigCard.styles';
import otherPlatform from '@/media/domains/other-card.png';

const DnsWizardEmailConfigCard = ({
  testId = 'dns-wizard-email-config-card',
  emailLocale = {
    changePlatformText: 'Alter Platform',
    propagationMessage: 'message',
  },
  changePlatformClick = () => {},
  currentPlatform,
  domain,
  propagating,
  customEmailPlatformName,
  dnsWizardAnalytics,
}) => {
  const image = currentPlatform();
  const dotBr = domain && domain.includes('.com.br');
  const displayName = image && image.platformName === 'Gmail';

  const { changePlatformText, propagationMessage } = emailLocale;
  const startChangePlatformClick = () => {
    dnsWizardAnalytics.dnsWizardAlterPlatformEmailButton();
    changePlatformClick();
  };
  return (
    <Styles.DnsWizardPlanCardWrapper data-testid={testId}>

      <Styles.ChangePlanWrapper>
        {image ? (
          <>
            <Styles.Image src={image.imageUrl} alt={image.platformName} data-testid="card-image" />
            {displayName && (
              <Styles.SelectedPlatformName>{image.platformName}</Styles.SelectedPlatformName>
            )}
          </>
        )
          : (
            <Styles.OtherCard>
              <Styles.Image src={otherPlatform} data-testid="card-image" />
              {customEmailPlatformName}
            </Styles.OtherCard>
          )
      }
        <Styles.ChangePlatform onClick={startChangePlatformClick} data-testid="change-platform-button">
          {changePlatformText}
        </Styles.ChangePlatform>
      </Styles.ChangePlanWrapper>

      {propagating && (
        <Styles.MessageWrapper>
          <Message
            Icon={<IconInfo />}
            borderColor="transpa"
            iconColor="#FFD54C"
            testId="message"
            text={propagationMessage(dotBr)}
          />
        </Styles.MessageWrapper>
      )}
    </Styles.DnsWizardPlanCardWrapper>
  );
};

export default DnsWizardEmailConfigCard;
