import { CALL_API } from '@/middleware/api';
import {
  RECEIVE_FEATURETOGGLES, REQUEST_FEATURETOGGLES, ERROR_FEATURETOGGLES,
} from '@/redux/actions/actionsTypes';
import { FEATURE_TOGGLES } from '@/config/api';


const receiveFeatureToggles = featureToggles => ({
  type: RECEIVE_FEATURETOGGLES,
  featureToggles,
});

export function loadFeatureToggles() {
  return {
    [CALL_API]: {
      endpoint: FEATURE_TOGGLES,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_FEATURETOGGLES }),
        success: response => receiveFeatureToggles(response.data),
        error: () => ({ type: ERROR_FEATURETOGGLES }),
      },
      authenticated: true,
    },
  };
}
