import React from 'react';
import * as Styles from './Card.styles';
import AspasCard from '@/media/courses/AcademyPassTrialPage/aspas-quote.svg';

const Card = ({
  textCard,
  autorCard,
  footerCard,
}) => (
  <Styles.Wrapper data-testid="card-wrapper">
    <Styles.Card>
      <Styles.IconCard data-testid="icon-card">
        <img src={AspasCard} alt="Aspas" />
      </Styles.IconCard>
      <Styles.DivCardText>
        <Styles.CardText data-testid="text-card">
          {textCard}
        </Styles.CardText>

        <Styles.CardTextAutor data-testid="autor-card">
          {autorCard}
        </Styles.CardTextAutor>

        <Styles.CardTextFooter data-testid="footer-card">
          {footerCard}
        </Styles.CardTextFooter>

      </Styles.DivCardText>
    </Styles.Card>
  </Styles.Wrapper>
);
export default Card;
