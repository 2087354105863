export const notifierSuccess = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export const notifierSuccessLong = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: 15000,
};

export const notifierError = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export const notifierErrorLong = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: 15000,
};
