import styled, { css } from 'styled-components';

export const ConfigurationsWrapper = styled.div`
  padding-bottom: 34px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    margin-bottom: 10px;
    margin-top: 34px;
    span {
      color: ${theme.color.cyanCobaltBlue};
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    font-size: ${theme.font.sizes.sm};
    letter-spacing: 0.3px;
    line-height: ${theme.font.lineHeights.lg};
    margin-top: 9px;
    margin-bottom: 30px;
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
`;

export const Triangle = styled.div`
  ${({ theme }) => css`
    border-bottom: 23px solid transparent;
    border-left: 23px solid ${theme.color.mustard};
    height: 0;
    margin-right: 18px;
    width: 0;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.cyanCobaltBlue};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.lineHeights.xl};
    margin: 0;
  `}
`;

export const Select = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: nowrap;
    max-height: 50px;
    margin-left: 16px;
    width: 350px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
      width: 100%;
    }
  `}
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  `;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding-left: 6px;

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `}
`;

export const LinkWrapper = styled.div`
${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: 32px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 16px;
      margin-top: 30px;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: -5px;
    margin-right: 22px;
    margin-top: 42px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
      margin-right: 0;
    }
  `}
`;

export const PlanChooseWrapper = styled.div`
  margin-top: 42px;
  `;

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    margin-left: 40px;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 0;
    }
  `}
`;

export const ContinueButtonWrapper = styled.div`
  margin-top: 40px;
  button{
    min-width:128px;
    min-height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span div {
      display: flex;
    }
  }
  div{
    padding: 0;
    margin: 0;
  }
`;

export const TooltipReplaceWrapper = styled.div`
  margin-top: 10px;
  margin-left: 5px;
`;

export const SinglePlanTooltipWrapper = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom;

    &:hover{
      svg {
        fill: ${theme.color.cyanCobaltBlue}
      }
    }
  `}
`;

export const TextDomain = styled.p`
  max-width:250px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
