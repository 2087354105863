import React, { useState } from 'react';
import {
  withStyles, Typography, Card, InputLabel, FormControl, Table, TableBody, TableHead, TableRow, TableCell,
} from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import MalwareItem from './MalwareItem/MalwareItem';
import styles from './styles';

const History = ({ classes, t }) => {
  const { completeScan } = useSelector(state => state.sitelock);
  const malwareScan = completeScan.find(scan => scan.code === 'malware_scan');
  const { pages } = malwareScan;

  const [detailsToggle, setDetailsToggle] = useState(false);

  return (
    <Card className={classes.card}>
      <Typography className={classes.historyTitle} data-testid="title-name">
        {t('sitelock.scanDetailed')}
      </Typography>
      <Typography className={classes.historySubtitle}>
        {t('sitelock.scanDetailedText')}
      </Typography>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="scan-period" />
      </FormControl>
      <div className={classes.table}>
        <div className={classes.tableHead} data-testid="history-table-head">
          <div className={classes.tableHeaderCell}>{t('sitelock.scanDate')}</div>
          <div className={classes.tableHeaderCell}>{t('sitelock.scanPages')}</div>
          <div className={classes.tableHeaderCell}>{t('sitelock.scanLinks')}</div>
          <div className={classes.tableHeaderCell}>{t('sitelock.scanMalwares')}</div>
          <div className={classes.tableHeaderCell}>{t('sitelock.scanStatus')}</div>
          <div className={classes.tableHeaderCell} />
        </div>
        <div className={classes.tableBody}>
          <div className={classes.tableRow}>
            <div className={classes.tableRowDetails}>
              <div className={classes.tableCell}>{ moment(malwareScan.last_scan).format('DD/MM/Y')}</div>
              <div className={classes.tableCell}>{malwareScan.total}</div>
              <div className={classes.tableCell}>{malwareScan.total_links}</div>
              <div className={classes.tableCell}>{malwareScan.issues}</div>
              <div className={classes.tableCell}>
                <span className={malwareScan.attention_flag === 'red' ? classes.vulnerable : classes.secure}>
                  { malwareScan.status ? t('sitelock.scanVulnerable') : t('sitelock.scanSecure')}
                </span>
              </div>
              <div className={classes.tableCell}>
                { malwareScan.attention_flag === 'red'
                    && (
                    <button
                      type="button"
                      data-testid="button-details"
                      className={classes.btnDetails}
                      onClick={() => {
                        setDetailsToggle(!detailsToggle);
                      }}
                    >
                      {detailsToggle ? t('sitelock.scanClose') : t('sitelock.scanDetails')}
                    </button>
                    )
                 }
              </div>
            </div>
            <div className={classnames(classes.malwareDetails, detailsToggle && classes.malwareDetailsOpen)}>
              <div className={classes.malwareDetailsContainer}>
                <div className={classes.malwareDetailsTable}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.malwareHeader}>{t('sitelock.infectedPage')}</TableCell>
                        <TableCell className={classes.malwareHeader}>{t('sitelock.malwareQuantity')}</TableCell>
                        <TableCell className={classes.malwareHeader}>{t('sitelock.severity')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pages.map(item => <MalwareItem item={item} />)}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Card>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(History));
