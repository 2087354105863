import { AlertsActionTypes } from './alerts.types';

const setDiagnosticAlerts = alerts => ({
  type: AlertsActionTypes.SET_DIAGNOSTIC_ALERTS,
  payload: alerts,
});

const addNewDiagnosticAlert = alert => ({
  type: AlertsActionTypes.ADD_NEW_DIAGNOSTIC_ALERT,
  payload: alert,
});

const setTicketSAlerts = alerts => ({
  type: AlertsActionTypes.SET_TICKETS_ALERTS,
  payload: alerts,
});

const clickOnAlertDomainToPoint = clickOnAlert => ({
  type: AlertsActionTypes.CLICK_ON_ALERT_DOMAIN_TO_POINT,
  payload: clickOnAlert,
});

const actions = {
  set: {
    diagnosticAlerts: setDiagnosticAlerts,
    ticketsAlerts: setTicketSAlerts,
    clickOnAlertDomainToPoint,
  },
  add: {
    diagnostic: addNewDiagnosticAlert,
  },
};

export default actions;
