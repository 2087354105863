import {
  ABORT_REQUEST_GENERAL_PRODUCTS,
  ALTER_VISIBLE_PRODUCTS,
  CLEAR_FILTER_GENERAL_PRODUCTS,
  ERROR_GENERAL_PRODUCTS,
  FILTER_GENERAL_PRODUCTS,
  RECEIVE_GENERAL_PRODUCTS,
  REQUEST_GENERAL_PRODUCTS,
  SELECT_STATUS_PRODUCTS,
  UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS,
} from '@/redux/actions/actionsTypes';
import { CALL_API } from '@/middleware/api';
import { GENERAL_PRODUCTS_URL } from '@/config/api';
import { config } from '@/config';
import { sortAlphabetic } from '@/utils/Application/sort';


function receiveGeneralProducts(generalProducts) {
  let activeProducts = generalProducts.filter(products => products.status === 'Active');
  let suspendedProducts = generalProducts.filter(products => products.status === 'Suspended');
  let pendingProducts = generalProducts.filter(products => products.status === 'Pending' || products.status === 'Fraud');
  let cancelledProducts = generalProducts.filter(products => products.status === 'Cancelled');
  let terminatedProducts = generalProducts.filter(products => products.status === 'Terminated');
  let buildingProducts = generalProducts.filter(products => products.status === 'Building');
  let activationPendingProducts = generalProducts.filter(products => products.status === 'ActivationPending');

  activeProducts = sortAlphabetic(activeProducts, 'productname');
  suspendedProducts = sortAlphabetic(suspendedProducts, 'productname');
  pendingProducts = sortAlphabetic(pendingProducts, 'productname');
  cancelledProducts = sortAlphabetic(cancelledProducts, 'productname');
  terminatedProducts = sortAlphabetic(terminatedProducts, 'productname');
  buildingProducts = sortAlphabetic(buildingProducts, 'productname');
  activationPendingProducts = sortAlphabetic(activationPendingProducts, 'productname');

  let initialProducts;
  const allProducts = [...activeProducts, ...suspendedProducts, ...pendingProducts, ...cancelledProducts, ...terminatedProducts, ...buildingProducts, ...activationPendingProducts];

  if (activeProducts.length === 0 && suspendedProducts.length === 0 && pendingProducts.length === 0 && buildingProducts.length === 0 && activationPendingProducts.length === 0) {
    initialProducts = allProducts;
  } else {
    initialProducts = [...activeProducts, ...suspendedProducts, ...pendingProducts, ...buildingProducts, ...activationPendingProducts];
  }

  const filterProducts = [];
  const filterStatus = [];
  const filterSelectedStatus = [];

  allProducts.forEach((element) => {
    const product = element.productname;
    const { status } = element;
    if (product && filterProducts.indexOf(product) === -1) {
      filterProducts.push(product);
    }
    if (status && filterStatus.indexOf(status) === -1) {
      filterStatus.push(status);
    }
  });

  filterStatus.forEach((status) => {
    if (status !== 'Cancelled' && status !== 'Terminated' && status !== 'Fraud') {
      filterSelectedStatus.push(status);
    }
  });

  return {
    type: RECEIVE_GENERAL_PRODUCTS,
    generalProducts: allProducts,
    initialProducts,
    filterDomain: filterProducts.sort(),
    loadedDate: Date.now(),
    filterSelectedStatus,
    filterStatus,
  };
}

export const productStatus = arrayStatus => ({
  type: SELECT_STATUS_PRODUCTS,
  arrayStatus,
});

export const productsVisible = productsNumber => ({
  type: ALTER_VISIBLE_PRODUCTS,
  productsNumber,
});

export const setUpdateDomainRenewalGeneralProducts = domainRenewalProducts => ({
  type: UPDATE_DOMAIN_RENEWAL_GENERAL_PRODUCTS,
  domainRenewalProducts,
});

export const updateDomainRenewalGeneralProducts = domainRenewal => (dispatch, getState) => {
  const generalProducts = getState().generalProducts.byId;
  const indexDomainRenewal = generalProducts.findIndex(el => el.id === domainRenewal.id);
  generalProducts[indexDomainRenewal].donotrenew = !!domainRenewal.donotrenew;
  return dispatch(setUpdateDomainRenewalGeneralProducts(generalProducts));
};

export const receiveFilteredProducts = filteredProducts => ({
  type: FILTER_GENERAL_PRODUCTS,
  filterProduct: filteredProducts,
});

export const filterGeneralProducts = productName => (dispatch, getState) => {
  const allProducts = getState().generalProducts.bkpProducts;
  const allStatus = getState().generalProducts.selectFilters.selectedStatus;

  let filteredProducts;

  if (productName !== '') {
    filteredProducts = allProducts.filter((product) => {
      if (product.productname === productName) {
        return product;
      }
      return null;
    });
  } else {
    filteredProducts = allProducts;
  }

  if (allStatus.length !== 0) {
    let adictionalStatus = null;
    switch (allStatus) {
      case 'Pending':
        adictionalStatus = 'Fraud';
        break;
      case 'Cancelled':
        adictionalStatus = 'Terminated';
        break;
      default:
        adictionalStatus = null;
        break;
    }

    filteredProducts = filteredProducts.filter((product) => {
      if (
        (
          product.status === allStatus[0]
          || (allStatus[1] && product.status === allStatus[1])
          || (allStatus[2] && product.status === allStatus[2])
          || (allStatus[3] && product.status === allStatus[3])
        )
        || product.status === adictionalStatus) {
        return product;
      }
      return null;
    });
  }

  dispatch(productsVisible(filteredProducts.length));
  return dispatch(receiveFilteredProducts(filteredProducts));
};

export const clearFilterProducts = () => ({
  type: CLEAR_FILTER_GENERAL_PRODUCTS,
});

const abortRequestGeneralProducts = () => ({
  type: ABORT_REQUEST_GENERAL_PRODUCTS,
});

export const loadGeneralProducts = () => async (dispatch, getState) => {
  const { generalProducts } = getState();
  const { loadedDate } = generalProducts;

  if (Date.now() - loadedDate < config.CACHE_TIME) {
    return new Promise((resolve) => {
      resolve(dispatch(abortRequestGeneralProducts()));
    });
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: GENERAL_PRODUCTS_URL,
      method: 'GET',
      actionTypes: {
        request: () => ({ type: REQUEST_GENERAL_PRODUCTS }),
        success: response => receiveGeneralProducts(response.data),
        error: () => ({ type: ERROR_GENERAL_PRODUCTS }),
      },
    },
  });
};
