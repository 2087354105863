import React, { useState } from 'react';
import * as Styles from './LinkNaBioList.styles';
import {
  Dropdown, Button, IconLink, IconDanger,
  Tooltip,
  IconArrowCurvedRight,
  IconButton,
} from 'gatorcomponents';
import { Modal } from '@/pages/common';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import { commonActions } from '@/redux/modules';
import { useDispatch } from 'react-redux';
import { LINK_NA_BIO_URL } from '@/config';
import { CUSTOM_DOMAIN_TO_LINK_NA_BIO } from '@/config/urls/linkNaBioUrls';
import withErrorBoundary from '@/utils/errorBoundary';

const LinkNaBioList = ({
  profiles = [],
  locale = {},
  createLinkNaBio = () => null,
  linkToCustomDomain = () => null,
  editLinkNaBio = () => null,
  deleteLinkNaBio = () => null,
  testId = 'link-na-bio-list',
  loading = false,
  goToDataAnalysis = () => null,
  canShowDataAnalysis = false,
  canShowCustomDomain = false,
}) => {
  const [profileToDelete, setProfileToDelete] = useState(undefined);
  const [showBanner, setShowBanner] = useState(true);
  const dispatch = useDispatch();

  const canShowBanner = localStorage.getItem('linkNaBioBanner') !== 'closed';

  const handleShowDeleteModal = value => setProfileToDelete(value);

  const showCopyNotification = () => {
    dispatch(commonActions.notifications.set({
      label: locale.copied,
      type: 'success',
    }));
  };

  const handleCopy = (profile) => {
    copyTextToClipboard(`${LINK_NA_BIO_URL}/${profile.username}`);
    showCopyNotification();
  };

  const handleDelete = () => {
    deleteLinkNaBio(profileToDelete.id);
  };

  const dropdownItems = (profile) => {
    const items = [
      {
        id: `${profile.username}-edit`,
        label: locale.edit,
        onClick: () => editLinkNaBio(profile),
      },
      {
        id: `${profile.username}-delete`,
        label: locale.delete,
        onClick: () => handleShowDeleteModal(profile),
      },
    ];

    if (canShowCustomDomain) {
      items.unshift({
        id: `${profile.username}-customDomain`,
        label: locale.customDomain,
        onClick: () => linkToCustomDomain(profile),
      });
    }

    return items;
  };

  const closeBanner = () => {
    localStorage.setItem('linkNaBioBanner', 'closed');
    setShowBanner(false);
  };

  const openSupportLink = () => {
    window.open(CUSTOM_DOMAIN_TO_LINK_NA_BIO, '_blank');
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      {canShowBanner && showBanner && canShowCustomDomain && (
        <Styles.Banner>
          <Styles.Label>
            <IconLink color="primaryMedium" />
            {locale.linkExample}
          </Styles.Label>
          <Styles.BannerContent>
            <Styles.BannerTitle>
              {locale.bannerTitle}
            </Styles.BannerTitle>
            <Styles.BannerDescription>
              {locale.bannerDescription}
            </Styles.BannerDescription>
          </Styles.BannerContent>
          <Styles.BannerButtonWrapper>
            <Button
              label={locale.seeHowToConfigure}
              onClick={openSupportLink}
            />
          </Styles.BannerButtonWrapper>
          <Styles.IconCloseWrapper>
            <IconButton icon="IconClose" onClick={closeBanner} />
          </Styles.IconCloseWrapper>
          <Styles.Rainbow />
        </Styles.Banner>
      )}
      <Styles.Row isTitle>
        <Styles.TitleWrapper>
          <Styles.Title>
            {locale.myLinks}
          </Styles.Title>

          <Styles.ButtonWrapper>
            {profiles.length <= 4 && (
              <Button
                label={locale.create}
                onClick={() => createLinkNaBio()}
                variant="secondary"
              />
            )}
            {profiles.length > 4 && (
              <Tooltip content={locale.tooltip} width={315}>
                <Button
                  label={locale.create}
                  onClick={() => createLinkNaBio()}
                  disabled
                />
              </Tooltip>
            )}
          </Styles.ButtonWrapper>
        </Styles.TitleWrapper>

        <Styles.Description>
          {locale.limit}
        </Styles.Description>
      </Styles.Row>

      <Styles.Profiles>
        {profiles.map((profile, index) => (
          <Styles.ListItem key={profile.id}>
            <Styles.Row>
              <Styles.ProfileLink
                href={`${LINK_NA_BIO_URL}/${profile.username}`}
                target="_blank"
              >
                <IconLink size="sm" />
                {`${locale.linkPlaceholder}${profile.username}`}
              </Styles.ProfileLink>
              <Styles.Dropdown>
                <Dropdown
                  items={dropdownItems(profile)}
                />
              </Styles.Dropdown>

              {profile.domain && (
                <Styles.DomainLinkedWrapper isMobileComponent>
                  <IconArrowCurvedRight />
                  <Styles.LinkText>
                    {locale.domainConnectedTo}
                    <Styles.DomainLink
                      href={`https://${profile.domain}`}
                      target="_blank"
                    >
                      {profile.domain}
                    </Styles.DomainLink>
                  </Styles.LinkText>
                </Styles.DomainLinkedWrapper>
              )}

              <Styles.ProfileActions>
                {canShowDataAnalysis && (
                  <Styles.ButtonWrapper>
                    <Button
                      label={locale.dataAnalysis}
                      onClick={() => goToDataAnalysis(profile)}
                      variant="secondary"
                    />
                  </Styles.ButtonWrapper>
                )}
                <Styles.ButtonWrapper>
                  <Button
                    label={locale.copy}
                    onClick={() => handleCopy(profile)}
                    testId="copy-link-button"
                  />
                </Styles.ButtonWrapper>
                <Dropdown
                  items={dropdownItems(profile)}
                />
              </Styles.ProfileActions>
              {profile.domain && (
                <Styles.DomainLinkedWrapper>
                  <IconArrowCurvedRight />
                  <Styles.LinkText>
                    {locale.domainConnectedTo}
                    <Styles.DomainLink
                      href={`https://${profile.domain}`}
                      target="_blank"
                    >
                      {profile.domain}
                    </Styles.DomainLink>
                  </Styles.LinkText>
                </Styles.DomainLinkedWrapper>
              )}
            </Styles.Row>
            <Styles.Divider lastRow={index === profiles.length - 1} />
          </Styles.ListItem>
        ))}
      </Styles.Profiles>

      {profileToDelete && (
        <Modal
          maxWidth={620}
          maxHeight={250}
          testId="link-na-bio-modal"
          onClose={handleShowDeleteModal}
        >
          <Styles.ModalContent>
            <Styles.ModalTitle>
              {locale.delete}
            </Styles.ModalTitle>

            <Styles.ModalAlert>
              <IconDanger size="xl" color="attentionPure" />
              {locale.deleteModalDescription(profileToDelete.username)}
            </Styles.ModalAlert>
            <Styles.ModalButtonsWrapper>
              <Styles.ButtonWrapper>
                <Button
                  label={locale.cancel}
                  size="large"
                  variant="tertiary"
                  onClick={() => handleShowDeleteModal()}
                />
              </Styles.ButtonWrapper>

              <Styles.ButtonWrapper>
                <Button
                  label={locale.delete}
                  size="large"
                  onClick={handleDelete}
                  loading={loading}
                  testId="modal-delete-button"
                />
              </Styles.ButtonWrapper>
            </Styles.ModalButtonsWrapper>

          </Styles.ModalContent>
        </Modal>
      )}
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(LinkNaBioList, 'LinkNaBioList');
