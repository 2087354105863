import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './SecurityHandler.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import { Tag } from 'gatorcomponents';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preChatActions } from '@/redux/modules';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { BackupModal, Cards, ProductsList } from './components';
import { makeCodeguardUrl } from '@/utils/Formatters/handleStrings';
import { productsChips, shared } from './security.types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_SITELOCK, CHARLIE_TOKEN_REFRESH } from '@/config/GrowthBook/constants';
import { isBRServer } from '@/utils/Validators/validation';

const SecurityHandler = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { security: securityLocale, routes: routesLocale } = useLocale();
  const [showBackupModal, setShowBackupModal] = useState(false);
  const { productsLoading, productsList } = useSelector(state => state.preChat);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [productFilter, setProductFilter] = useState('');
  const [productChips, setProductChips] = useState(productsChips);
  const [selectedChip, setSelectedChip] = useState(productsChips[0]);
  const [productsListFiltered, setProductsListFiltered] = useState([]);
  const waitTokenToggle = useFeatureIsOn(CHARLIE_TOKEN_REFRESH);
  const newSitelock = useFeatureIsOn(CHARLIE_SITELOCK);

  const requestProducts = useCallback(() => {
    const withCancelled = true;
    const withPending = true;
    dispatch(preChatActions.productByStatus.request({ withCancelled, withPending, waitToken: waitTokenToggle }));
  }, [dispatch, waitTokenToggle]);

  useEffect(() => {
    requestProducts();
  }, [requestProducts]);

  useEffect(() => {
    const hostingSecurityProducts = (productsList && productsList.hostings && productsList.hostings.filter(hosting => hosting.name.toLowerCase().includes('sitelock') || hosting.name.toLowerCase().includes('codeguard'))) || [];
    const addonsSecurityProducts = (productsList && productsList.addons && productsList.addons.filter(addon => addon.name.toLowerCase().includes('sitelock')).map(addon => ({ ...addon, isAddon: true }))) || [];
    const securityProducts = [...hostingSecurityProducts, ...addonsSecurityProducts];
    const sortedProducts = [];

    const activeProducts = securityProducts.filter(item => item.status === 'Active');
    sortedProducts.push(...activeProducts);
    const pendingProducts = securityProducts.filter(item => item.status === 'Pending');
    sortedProducts.push(...pendingProducts);
    const suspendedProducts = securityProducts.filter(item => item.status === 'Suspended');
    sortedProducts.push(...suspendedProducts);

    if (!productFilter && selectedChip.id === 'all') {
      setProductsListFiltered(sortedProducts);
      return;
    }

    let newProductsList = sortedProducts;

    if (productFilter) {
      newProductsList = securityProducts.filter(product => product.domain.includes(productFilter));
    }

    if (selectedChip && selectedChip.id !== 'all') {
      switch (selectedChip.id) {
        case 'pending': {
          newProductsList = newProductsList.filter(product => product.status.toLowerCase() === 'pending');
          break;
        }
        case 'security': {
          newProductsList = newProductsList.filter(product => product.name.toLowerCase().includes('sitelock'));
          break;
        }
        case 'backup': {
          newProductsList = newProductsList.filter(product => product.name.toLowerCase().includes('codeguard'));
          break;
        }
        default:
      }
    }

    setProductsListFiltered(newProductsList);
  }, [selectedChip, productFilter, productsList]);

  const handleClickAccess = (id) => {
    if (id === 'simple') {
      setShowBackupModal(true);
      return;
    }

    history.push(`${routesLocale[id]}`);
  };

  const getStatus = (status) => {
    if (status === 'Suspended') {
      return 'problem';
    }

    if (status === 'Cancelled' || status === 'Terminated') {
      return 'cancel';
    }

    return status.toLowerCase();
  };

  const getBackupModalProps = useCallback(() => {
    const defaultProps = {
      onClose: () => setShowBackupModal(false),
      securityLocale,
      productsLoading,
      selectedProduct,
      setSelectedProduct,
      setSelectedId,
    };

    const goToBackup = () => {
      history.push(`${routesLocale.products}${routesLocale.productdetail}/${selectedId}${routesLocale.backup}/wizard`);
    };

    const productsOptions = [];
    if (!productsLoading && productsList && productsList.hostings && productsList.hostings.length) {
      const onlyShared = productsList.hostings.filter(product => shared.includes(product.package_id));

      const active = onlyShared.filter(product => product.status === 'Active');
      const suspended = onlyShared.filter(product => product.status === 'Suspended');
      const cancelled = onlyShared.filter((product) => {
        const { cancel_date: cancelDate } = product;
        const dateDiff = product.status === 'Cancelled' || product.status === 'Terminated' ? differenceInCalendarDays(parseISO(cancelDate), new Date()) : 0;
        return (product.status === 'Cancelled' || product.status === 'Terminated') && dateDiff < 30;
      });

      const push = (product) => {
        productsOptions.push({
          label: (
            <Styles.SelectOptionWrapper>
              <span>{`${product.name} - ${product.domain}`}</span>
              <Tag label={securityLocale.modal.status[product.status]} variant={getStatus(product.status)} />
            </Styles.SelectOptionWrapper>
          ),
          value: product.id,
        });
      };

      active.forEach((product) => {
        push(product);
      });

      suspended.forEach((product) => {
        push(product);
      });

      cancelled.forEach((product) => {
        push(product);
      });
    }

    return {
      ...defaultProps,
      productsOptions,
      goToBackup,
    };
  }, [
    productsList,
    productsLoading,
    securityLocale,
    selectedProduct,
    setSelectedProduct,
    history,
    routesLocale,
    selectedId,
  ]);

  const getProductsListProps = () => {
    const handleChangeChip = (chip) => {
      const newChips = productsChips.map(productChip => ({ ...productChip, checked: false }));
      const chipIndex = chip && newChips.findIndex(option => option.id === chip.id);

      if (!chipIndex) {
        newChips[0].checked = true;
        setSelectedChip(newChips[0]);
      }

      if (chipIndex) {
        newChips[chipIndex].checked = true;
        setSelectedChip(newChips[chipIndex]);
      }

      setProductChips(newChips);
    };

    const handleChangeFilterBy = (text) => {
      setProductFilter(text);
    };

    const handleClearFilter = () => {
      setProductFilter('');
      handleChangeChip();
    };

    const getPageTitleProps = () => {
      const defaultProps = {
        testId: 'products-list',
        title: securityLocale.productsList.title,
        filterLabel: securityLocale.productsList.filterLabel,
        filterByPlaceholder: securityLocale.productsList.filterByPlaceholder,
        filterByValue: productFilter,
        clearFilterLabel: securityLocale.productsList.clearFilterLabel,
        filterChips: productChips,
        hideFilter: false,
        showClearFilter: !!productFilter.length || selectedChip.id !== productsChips[0].id,
        handleClearFilter,
      };

      return {
        ...defaultProps,
        handleChangeChip,
        handleChangeFilterBy,
      };
    };

    const handleManageProductClick = (product) => {
      if (product.name.toLowerCase().includes('codeguard')) {
        window.open(makeCodeguardUrl(product.id), '_blank');
      }

      if (product.name.toLowerCase().includes('sitelock')) {
        newSitelock && isBRServer
          ? history.push(`${routesLocale.security}${routesLocale.productdetail}/standalone/${product.id}`)
          : history.push(`${routesLocale.security}/${product.isAddon ? 'addon' : 'standalone'}/${product.id}`);
      }
    };

    return {
      getPageTitleProps,
      getStatus,
      handleManageProductClick,
      productsListFiltered,
      productsLoading,
    };
  };

  return (
    <>
      {showBackupModal && (
        <BackupModal {...getBackupModalProps()} />
      )}

      <Cards handleClickAccess={handleClickAccess} />

      <ProductsList {...getProductsListProps()} />
    </>
  );
};

export default SecurityHandler;
