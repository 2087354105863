import { CALL_API } from '@/middleware/api';
import { TICKET_DETAIL_URL, TICKET_DETAIL_URL_V2, TICKET_FEEDBACK_DETAIL_URL } from '@/config/api';
import {
  RECEIVE_TICKET_DETAIL, RECEIVE_COMMENT_TICKET_DETAIL, REQUEST_TICKET_DETAIL,
  ERROR_TICKET_DETAIL, SAVE_TICKET_COMMENT_START, SAVE_TICKET_COMMENT_ERROR,
  RECEIVE_UPLOAD_TICKET_DETAIL, UPLOAD_TICKET_IMAGES_START, UPLOAD_TICKET_IMAGES_ERROR,
  REQUEST_SAVE_TICKET_FEEDBACK, SUCCESS_SAVE_TICKET_FEEDBACK, ERROR_SAVE_TICKET_FEEDBACK,
  REQUEST_CLOSE_TICKET, SUCCESS_CLOSE_TICKET, ERROR_CLOSE_TICKET,
  UPDATE_TICKET_DETAIL_STATUS,
} from './actionsTypes';


function receiveTicketDetail(ticketDetail) {
  return {
    type: RECEIVE_TICKET_DETAIL,
    ticketDetail: ticketDetail.data,
  };
}

function receiveCommentTicketDetail(ticketDetail) {
  return {
    type: RECEIVE_COMMENT_TICKET_DETAIL,
    ticketDetail,
  };
}

export const loadTicketDetail = (ticketId, userEmail) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: TICKET_DETAIL_URL(ticketId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_TICKET_DETAIL }),
      success: response => receiveTicketDetail(response),
      error: () => ({ type: ERROR_TICKET_DETAIL }),
    },
  },
});

export const loadTicketDetailV2 = (ticketId, userEmail) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: TICKET_DETAIL_URL_V2(ticketId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_TICKET_DETAIL }),
      success: response => receiveTicketDetail(response),
      error: () => ({ type: ERROR_TICKET_DETAIL }),
    },
  },
});

export const saveCommentTicketDetail = (data, userEmail) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: TICKET_DETAIL_URL(data.comment.id),
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: SAVE_TICKET_COMMENT_START }),
      success: data => receiveCommentTicketDetail(data),
      error: () => ({ type: SAVE_TICKET_COMMENT_ERROR }),
    },
  },
});

export const saveCommentTicketDetailV2 = (data, userEmail) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: TICKET_DETAIL_URL_V2(data.comment.id),
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: SAVE_TICKET_COMMENT_START }),
      success: data => receiveCommentTicketDetail(data),
      error: () => ({ type: SAVE_TICKET_COMMENT_ERROR }),
    },
  },
});

// UPLOAD IMAGES
function receiveUploadTicketDetail(ticketDetail) {
  return {
    type: RECEIVE_UPLOAD_TICKET_DETAIL,
    ticketDetail,
  };
}

export const uploadImagesTicketDetail = (data, userEmail) => {
  const formData = new FormData();
  formData.append('file[]', data.comment.file[0]);
  formData.append('comment[body]', data.comment.body);

  return {
    [CALL_API]: {
      authenticated: true,
      customHeaders: {
        email: userEmail,
      },
      endpoint: TICKET_DETAIL_URL(data.comment.id),
      method: 'POST',
      body: formData,
      actionTypes: {
        request: () => ({ type: UPLOAD_TICKET_IMAGES_START }),
        success: data => receiveUploadTicketDetail(data),
        error: () => ({ type: UPLOAD_TICKET_IMAGES_ERROR }),
      },
    },
  };
};

export const onUploadImagesTicketDetailV2 = (data, userEmail) => {
  const formData = new FormData();
  formData.append('file[]', data.comment.file[0]);
  formData.append('comment[body]', data.comment.body);

  return {
    [CALL_API]: {
      authenticated: true,
      customHeaders: {
        email: userEmail,
      },
      endpoint: TICKET_DETAIL_URL_V2(data.comment.id),
      method: 'POST',
      body: formData,
      actionTypes: {
        request: () => ({ type: UPLOAD_TICKET_IMAGES_START }),
        success: data => receiveUploadTicketDetail(data),
        error: () => ({ type: UPLOAD_TICKET_IMAGES_ERROR }),
      },
    },
  };
};

export function saveFeedbackTicketDetail(data, userEmail) {
  return {
    [CALL_API]: {
      authenticated: true,
      customHeaders: {
        email: userEmail,
      },
      endpoint: TICKET_FEEDBACK_DETAIL_URL(data.id),
      method: 'POST',
      body: {
        score: data.score,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_SAVE_TICKET_FEEDBACK }),
        success: () => ({ type: SUCCESS_SAVE_TICKET_FEEDBACK }),
        error: () => ({ type: ERROR_SAVE_TICKET_FEEDBACK }),
      },
    },
  };
}

export function changeTicketStatus(ticket, userEmail) {
  return {
    [CALL_API]: {
      authenticated: true,
      customHeaders: {
        email: userEmail,
      },
      endpoint: TICKET_DETAIL_URL(ticket.id),
      method: 'PUT',
      body: {
        status: ticket.status,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_CLOSE_TICKET }),
        success: () => ({ type: SUCCESS_CLOSE_TICKET }),
        error: () => ({ type: ERROR_CLOSE_TICKET }),
      },
    },
  };
}

export const updateTicketDetailStatus = () => ({ type: UPDATE_TICKET_DETAIL_STATUS });
