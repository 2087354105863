import React from 'react';
import * as Style from './IconResale.styles';

const IconResale = ({ testId = 'icon-resale' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.999" viewBox="0 0 22 21.999">
      <g id="Grupo_40861" data-name="Grupo 40861" transform="translate(-625.498 -577)">
        <rect id="Retângulo_16282" data-name="Retângulo 16282" width="21.999" height="21.999" transform="translate(625.498 577)" fill="none" />
        <g id="Grupo_37698" data-name="Grupo 37698" transform="translate(625.499 579.892)">
          <rect id="Retângulo_14795" data-name="Retângulo 14795" width="12.616" height="12.616" transform="translate(9.383)" fill="#ff9144" />
          <g id="Grupo_37696" data-name="Grupo 37696" transform="translate(0 2.523)">
            <g id="Retângulo_14794" data-name="Retângulo 14794" transform="translate(0.551)">
              <rect id="Retângulo_14748" data-name="Retângulo 14748" width="17.242" height="7.57" transform="translate(0 0)" fill="none" />
              <g id="Grupo_37660" data-name="Grupo 37660">
                <path id="Caminho_244183" data-name="Caminho 244183" d="M589.963,541.435H572.721v-7.57h17.242Zm-15.77-1.472h14.3v-4.626h-14.3Z" transform="translate(-572.721 -533.865)" fill="#4a4a4a" />
              </g>
            </g>
            <g id="Caminho_244262" data-name="Caminho 244262" transform="translate(0 9.805)">
              <path id="Caminho_244184" data-name="Caminho 244184" d="M589.95,560.995h-1.472v-2.344h-15.6v2.344H571.41v-3.816h18.54Z" transform="translate(-571.41 -557.179)" fill="#4a4a4a" />
            </g>
            <g id="Caminho_244263" data-name="Caminho 244263" transform="translate(8.506 6.46)">
              <rect id="Retângulo_14749" data-name="Retângulo 14749" width="1.472" height="7.233" fill="#4a4a4a" />
            </g>
            <rect id="Retângulo_14248-5" data-name="Retângulo 14248-5" width="1.149" height="1.149" transform="translate(3.015 3.281)" fill="#4a4a4a" />
            <rect id="Retângulo_14248-6" data-name="Retângulo 14248-6" width="1.149" height="1.149" transform="translate(5.233 3.281)" fill="#4a4a4a" />
            <rect id="Retângulo_14248-7" data-name="Retângulo 14248-7" width="4.477" height="1.149" transform="translate(11.15 3.281)" fill="#4a4a4a" />
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconResale;
