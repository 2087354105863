import React from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles,
} from '@material-ui/core';
import styles from './styles';

const MobileRecordList = ({ classes, t }) => (
  <div className={classes.header} data-testid="zoneManagerHeaderItems">
    <div className={`${classes.headerItem} ${classes.headerItemType}`}>
      {t('dns.zoneManager.recordList.listLabel.type')}
    </div>

    <div className={`${classes.headerItem} ${classes.headerItemName}`}>
      {t('dns.zoneManager.recordList.listLabel.name')}
    </div>

    <div className={`${classes.headerItem} ${classes.headerItemClass}`}>
      {t('dns.zoneManager.recordList.listLabel.class')}
    </div>

    <div className={`${classes.headerItem} ${classes.headerItemTtl}`}>
      {t('dns.zoneManager.recordList.listLabel.ttl')}
    </div>

    <div className={`${classes.headerItem} ${classes.headerItemValue}`}>
      {t('dns.zoneManager.recordList.listLabel.address')}
    </div>
  </div>
);

export default (withI18n()((withStyles(styles)(MobileRecordList))));
