import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const SiteCard = ({
  classes, className, children, status,
}) => (
  <div
    className={classnames(classes.card, className, {
      [classes.statusActive]: status === 'active',
      [classes.statusNotConfigured]: status === 'not_configured',
    })}
  >
    {children}
  </div>
);

export default withStyles(styles)(SiteCard);
