import { CALL_API } from '@/middleware/api';
import { TWO_FACTOR_AUTH } from '@/config/api';
import {
  RESEND_TWO_FACTOR_AUTH_CODE,
  RECEIVE_TWO_FACTOR_AUTH_CODE,
  REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE,
  SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE,
  TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK,
  SEND_ENABLE_TWO_FACTOR_AUTH_CODE,
  RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE,
  SEND_DISABLE_TWO_FACTOR_AUTH_CODE,
  RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE,
} from './actionsTypes';

export const resendTwoFactorAuthCode = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${TWO_FACTOR_AUTH}/resend`,
    method: 'POST',
    actionTypes: {
      request: () => ({ type: RESEND_TWO_FACTOR_AUTH_CODE }),
      success: () => ({ type: RECEIVE_TWO_FACTOR_AUTH_CODE }),
    },
  },
});

export const sendEnableTwoFactorAuthCode = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${TWO_FACTOR_AUTH}/enable`,
    method: 'PATCH',
    actionTypes: {
      request: () => ({ type: SEND_ENABLE_TWO_FACTOR_AUTH_CODE }),
      success: () => ({ type: RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE }),
    },
  },
});

export const sendDisableTwoFactorAuthCode = () => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${TWO_FACTOR_AUTH}/disable`,
    method: 'PATCH',
    actionTypes: {
      request: () => ({ type: SEND_DISABLE_TWO_FACTOR_AUTH_CODE }),
      success: () => ({ type: RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE }),
    },
  },
});

export const setValidationFeedback = ({ hasError }) => ({
  type: TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK,
  payload: { hasError },
});

export const validateTwoFactorAuthCode = ({ code, action }) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${TWO_FACTOR_AUTH}/validate`,
    method: 'POST',
    body: { code, action },
    actionTypes: {
      request: () => ({ type: REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE }),
      success: response => ({ type: SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE, payload: response.data }),
      error: () => setValidationFeedback({ hasError: true }),
    },
  },
});
