import { ALERT_TYPES, BACKUP_STATUS } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';
import { unformatCurrency } from '@/utils/Formatters/unformatCurrency';

const mountDiagnosticAlerts = (
  diagnostic,
  enabledSubscriptionInDiagnostic,
  enablePendingOrders,
  enableSslAlert,
) => {
  const pendingOrders = diagnostic.pending_orders || [];
  const ssl = diagnostic.ssl || [];
  const duplicateOrders = diagnostic.duplicate_orders || [];
  const backupAvailable = diagnostic.backup_available || [];
  const backups = diagnostic.backups || [];
  const overdueInvoices = (diagnostic.invoices && diagnostic.invoices.overdue) || [];
  const toDueInvoices = (diagnostic.invoices && diagnostic.invoices.to_due) || [];
  const toDueSubscriptions = (diagnostic.subscriptions && diagnostic.subscriptions.to_due) || [];

  const refunds = diagnostic.refunds || [];
  const domainsPropagation = diagnostic.domains_propagation;
  const domainInPropagation = (diagnostic.domains_propagation && diagnostic.domains_propagation.filter(propagation => propagation.propagation_status === 'in_progress')) || [];
  const domainNotConfigured = (diagnostic.domains_propagation && diagnostic.domains_propagation.filter(propagation => propagation.propagation_status === 'not_configured')) || [];
  const domainNotContracted = (diagnostic.domains_propagation && diagnostic.domains_propagation.filter(propagation => propagation.propagation_status === 'not_contracted')) || [];
  const tos = diagnostic.tickets || [];
  const domainsForPointing = diagnostic.domains_for_pointing || [];

  const alertsAdapted = [];

  if (backupAvailable.length) {
    const adaptedBackupAvailable = backupAvailable.map(plan => ({
      ...plan, type: ALERT_TYPES.BACKUP, isNew: true,
    }));
    alertsAdapted.push(adaptedBackupAvailable);
  }

  if (backups.length) {
    const findInAlertsAdapted = hostingId => alertsAdapted.find(alert => alert.hosting_id === hostingId);

    backups.forEach((backup) => {
      const existingBackup = findInAlertsAdapted(backup.hosting_id);

      if (!existingBackup || backup.days_since_last < existingBackup.days_since_last) {
        const adaptedBackup = { ...backup, type: ALERT_TYPES.BACKUP, isNew: true };

        if (existingBackup) {
          alertsAdapted.splice(alertsAdapted.indexOf(existingBackup), 1);
        }

        adaptedBackup.backup_type !== BACKUP_STATUS.RESTORED && alertsAdapted.push(adaptedBackup);
      }
    });
  }

  if (tos.length) {
    const adaptedTos = tos.map(singleTos => ({ ...singleTos, type: ALERT_TYPES.TOS, isNew: true }));
    alertsAdapted.push(adaptedTos);
  }

  if (enabledSubscriptionInDiagnostic && toDueSubscriptions.length) {
    toDueSubscriptions.forEach((item) => {
      alertsAdapted.push({
        productType: item.type,
        toDueSubscriptionId: item.id,
        daysToDue: item['days-to-due'],
        type: ALERT_TYPES.SUSBCRIPTION_OVERDUE,
        isNew: true,
        discountEnable: item.discount_enable,
        emailDiscountSent: item.email_discount_sent,
        name: item.name,
        dueDate: item.due_date,
        url: item.url,
      });
    });
  }

  if ((overdueInvoices.length) || (toDueInvoices.length)) {
    const totalInvoices = overdueInvoices.length + toDueInvoices.length;
    const overdueInvoicesSum = overdueInvoices.length ? overdueInvoices.map(invoice => unformatCurrency(invoice.total)).reduce((total, invoiceValue) => total + invoiceValue) : 0;
    const toDueInvoicesSum = toDueInvoices.length ? toDueInvoices.map(invoice => unformatCurrency(invoice.total)).reduce((total, invoiceValue) => total + invoiceValue) : 0;
    const invoicesSum = overdueInvoicesSum + toDueInvoicesSum;
    const adaptedInvoices = {
      totalInvoices, invoicesSum, type: ALERT_TYPES.INVOICES, isNew: true,
    };
    alertsAdapted.push(adaptedInvoices);
  }

  if (enablePendingOrders && pendingOrders.length) {
    pendingOrders.forEach((item) => {
      const firstProduct = item.products[0];

      const pendingAnalysisOrder = {
        orderId: item.id,
        orderStatus: item.status,
        productName: firstProduct.name,
        productType: firstProduct.type,
        productId: firstProduct.id,
        type: ALERT_TYPES.PENDING_ORDER,
        isNew: true,
      };

      if (item.status !== 'pending_payment') {
        if (firstProduct.type === 'domain') {
          alertsAdapted.push({
            ...pendingAnalysisOrder,
          });
        } else {
          alertsAdapted.push({
            ...pendingAnalysisOrder,
            productDomain: firstProduct.domain,
          });
        }
      }
    });
  }

  if (duplicateOrders.length) {
    alertsAdapted.push({ type: ALERT_TYPES.DUPLICATE_ORDERS, isNew: true });
  }

  if (domainsForPointing.length) {
    domainsForPointing.forEach(domainForPointing => alertsAdapted.push({ ...domainForPointing, type: ALERT_TYPES.DOMAIN_FOR_POINTING, isNew: true }));
  }

  if (domainNotConfigured.length) {
    alertsAdapted.push({ type: ALERT_TYPES.DOMAIN_NOT_CONFIGURED, isNew: true });
  }

  if (domainNotContracted.length) {
    alertsAdapted.push({ type: ALERT_TYPES.DOMAIN_NOT_CONTRACTED, isNew: true });
  }

  if (domainInPropagation.length) {
    alertsAdapted.push({ type: ALERT_TYPES.DOMAIN_IN_PROGRESS, isNew: true });
  }

  if (refunds.length) {
    const adaptedRefunds = refunds.map(refund => ({ ...refund, type: ALERT_TYPES.REFUND, isNew: true }));
    alertsAdapted.push(adaptedRefunds);
  }

  if (enableSslAlert && ssl.length) {
    const sslItems = ssl.map(item => ({
      ...item,
      type: ALERT_TYPES.SSL,
      isNew: true,
    }));
    alertsAdapted.push(sslItems);
  }

  const inPropagationItems = [...domainsPropagation].filter((item) => {
    const existsInSslArray = ssl.find(sslItem => sslItem.domain === item.domain);
    return !existsInSslArray;
  });

  if (enableSslAlert && inPropagationItems.length) {
    const sslitems = inPropagationItems.map(domain => ({
      ...domain,
      status: 'in_progress',
      type: ALERT_TYPES.SSL,
      isNew: true,
    }));
    alertsAdapted.push(...sslitems);
  }

  return alertsAdapted.flat();
};

const getDomainsPropagations = diagnostic => diagnostic.domains_propagation.map(domain => ({
  domain: domain.domain,
  id: domain.domain_id,
  status: domain.status ? domain.status : domain.propagation_status,
  hostingId: domain.hosting_id,
  nameServer1: domain.hosting_nameserver_1,
  nameServer2: domain.hosting_nameserver_2,
  configured: domain.configured,
}));

const assembly = {
  mountDiagnosticAlerts,
  getDomainsPropagations,
};

export default assembly;
