import { videoClassesActionTypes } from './videoClasses.types';

export const initialState = {
  embedDisplayed: true,
  modalDisplayed: false,
  liveData: {},
};

const videoClasses = (state = initialState, action) => {
  switch (action.type) {
    case videoClassesActionTypes.SET_EMBED_VIEW_STATUS: {
      return {
        ...state,
        embedDisplayed: action.payload,
      };
    }
    case videoClassesActionTypes.SET_MODAL_VIEW_STATUS: {
      return {
        ...state,
        modalDisplayed: action.payload,
      };
    }
    case videoClassesActionTypes.SET_LIVE_DATA: {
      return {
        ...state,
        liveData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default videoClasses;
