const styles = theme => ({
  btnClose: {
    color: theme.color.tundora,
    padding: '6px',
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  modal: {
    borderRadius: 0,
    height: '280px',
  },
  content: {
    padding: '35px 40px',
    position: 'relative',
  },
  checkAvailabilityPlanLinkButton: {
    alignItems: 'center',
    background: theme.color.indigo,
    color: theme.color.white,
    display: 'flex',
    fontSize: '16px',
    fontWeight: 400,
    height: '32px',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '24px',
    textDecoration: 'none',
    width: '250px',
  },
  scroolPaper: {
    marginTop: '24px',
    maxHeight: 420,
    overflow: 'auto',
    padding: 0,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.color.silver,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: theme.color.indigo,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.indigo,
    },
    [theme.breakpoints.down(960)]: {
      margin: 0,
      maxHeight: 'unset',
      overflow: 'unset',
      padding: 0,
    },
  },
  titleDomain: {
    color: theme.color.indigo,
    fontSize: '24px',
    fontStyle: 'Medium',
  },
  gridDates: {
    marginBottom: '19px',
  },
  gridSubDates: {
    marginLeft: '9px',
    firstColumn: {
      marginLeft: '-9px',
    },
  },
  radioItem: {
    textAlign: 'center',
    width: 'auto',
  },
  titleItemDate: {
    color: theme.color.textPrimary,
    display: 'block',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '4px',
    textAlign: 'left',
  },
  titleItem: {
    color: theme.color.tundora,
    display: 'block',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '19px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  titleDate: {
    color: theme.color.regentGray,
    fontSize: '12px',
    marginTop: '2px',
    textAlign: 'left',
  },
  description: {
    fontSize: '16px',
    fontStyle: 'Regular',
    marginTop: '16px',
  },
  dialogTitle: {
    height: '45px',
    textAlign: 'right',
  },
  gridList: {
    marginTop: '18px',
    border: '1px solid #CDD8DF',
    borderRadius: '4px',
    width: '100%',
    marginLeft: 'auto',
    '&:hover': {
      border: `1px solid${theme.color.indigo}`,
    },
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 5px',
  },
  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },
  warning: {
    borderRadius: '2px',
    marginBottom: '30px',
    padding: '15px',
  },
  actions: {
    textAlign: 'center',
  },
  btnCancel: {
    borderColor: theme.color.geyser,
    color: theme.color.indigo,
    marginRight: '10px',
  },
});

export default styles;
