const styles = theme => ({
  textField: {
    fontSize: '14px',
    width: '100%',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 1,
      fontSize: 14,
    },
    '& input': {
      padding: 8,
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      maxWidth: '100%',
      '& fieldset': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 12,
    },
  },
  filterWrapper: {
    display: 'flex',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: 22,
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  filterLabel: {
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginRight: 12,
    minWidth: '64px',
  },
  filterList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    '& li': {
      marginLeft: 6,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 6,
      },
    },
  },
  chipElement: {
    cursor: 'pointer',
    fontSize: 12,
    height: 22,
  },
  chipElementActive: {
    backgroundColor: theme.color.indigo,
    color: theme.color.white,
    '&:focus,&:hover': {
      backgroundColor: theme.color.indigo,
      color: theme.color.white,
    },
  },
  chipElementInactive: {
    backgroundColor: theme.color.white,
    color: theme.color.tundora,
    '&:focus,&:hover': {
      backgroundColor: theme.color.white,
      color: theme.color.tundora,
    },
  },
});

export default styles;
