import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import DOMpurify from 'dompurify';
import { useSelector } from 'react-redux';
import { Modal } from '@/pages/common/Modal';
import { IconCheck } from '@/icons';
import * as Styles from './ModalToEnableTitanTrial.styles';
import ProEmailActiveTrial from '../ProEmail/ProEmailsNewList/ProEmailActiveTrial';

const ModalToEnableTitanTrial = ({
  t,
  isOpen,
  onClose,
  selectedDomain,
  essentialData,
  offerContext,
}) => {
  const allEmails = useSelector(state => state.emails.proEmailList);
  const domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');

  const createAdvantageItem = useCallback(key => (
    <li key={`modalTitanTrial.advantages.${key}`}>
      <IconCheck size={16} color={Styles.iconColor} />
      {t(`modalTitanTrial.advantages.${key}`)}
    </li>
  ), [t]);

  const advantageItemsLeft = useMemo(() => [1, 2, 3].map(createAdvantageItem), [createAdvantageItem]);
  const advantageItemsRight = useMemo(() => [4, 5, 6].map(createAdvantageItem), [createAdvantageItem]);

  const purifyTranslation = useCallback((text, interpolation = {}) => DOMpurify.sanitize(t(text, interpolation), {
    ALLOWED_TAGS: ['strong', 'a'],
    ALLOWED_ATTR: ['href', 'class', 'target', 'rel'],
  }), [t]);

  const titleSanitized = purifyTranslation('modalTitanTrial.title', {
    domain: selectedDomain.domain,
  });

  const productDescriptionSanitized = purifyTranslation('modalTitanTrial.productDescription', {
    productName: essentialData.name,
  });

  return (
    isOpen && (
      <Modal onClose={onClose} disablePadding maxWidth={720} maxHeight={680} testId="modal-to-enable-titan-trial">
        <Styles.WrapperContent>
          <Styles.Header>
            <Styles.BackButton type="button" onClick={onClose} data-testid="back-button">
              <Styles.ArrowBackIcon />
            </Styles.BackButton>

            <Styles.WrapperTextsHeader>
              <Styles.Title dangerouslySetInnerHTML={{ __html: titleSanitized }} data-testid="title-with-domain" />

              <Styles.TagPeriod>
                {t('modalTitanTrial.tagOfTrialTime')}
              </Styles.TagPeriod>
            </Styles.WrapperTextsHeader>
          </Styles.Header>
          <Styles.Content>
            <Styles.DescriptionProduct dangerouslySetInnerHTML={{ __html: productDescriptionSanitized }} />

            <Styles.AdvantagesList>
              <div data-testid="leftAdvantage">{advantageItemsLeft}</div>
              <div data-testid="rightAdvantage">{advantageItemsRight}</div>
            </Styles.AdvantagesList>

          </Styles.Content>
        </Styles.WrapperContent>

        <ProEmailActiveTrial
          renderedEmailList={domainWithoutEmail}
          selectedDomain={selectedDomain.domain.replace(/@/g, '')}
          location="modal"
          offerContext={offerContext}
        />
      </Modal>
    )
  );
};


ModalToEnableTitanTrial.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedDomain: PropTypes.shape({
    id: PropTypes.string,
    domain: PropTypes.string,
  }).isRequired,
  essentialData: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
};


ModalToEnableTitanTrial.defaultProps = {
  isOpen: false,
};

export default withI18n()(ModalToEnableTitanTrial);
