const styles = theme => ({
  modal: {
    borderRadius: 0,
    maxWidth: '475px',
  },
  content: {
    position: 'relative',
    padding: '35px 40px',
  },
  btnClose: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: theme.color.tundora,
    padding: '6px',
  },
  title: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '0 0 5px',
  },
  message: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 15px',
  },
  logItem: {
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    marginBottom: '5px',
    padding: '10px 20px',
    display: 'flex',
  },
  logSubItem: {
    marginRight: '20px',
  },
  logLabel: {
    display: 'block',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#A5A5A5',
  },
  logValue: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#555',
  },
});

export default styles;
