import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './CodeGuardUpgrade.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { HgProducts } from '../../SupportForms.types';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const CodeGuardUpgrade = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { upgradeCodeGuard: upgradeCodeGuardLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    newProduct: '',
    agreed: false,
    agreedSelectedPlan: false,
  });

  const [productOptions, setProductOptions] = useState([]);

  const checkForm = useCallback(() => !(!!formData.newProduct && !!formData.agreed && !!formData.agreedSelectedPlan), [formData]);

  const createUpgradeOptions = useCallback(() => {
    const options = [];

    const starterOption = {
      label: 'CodeGuard Iniciante',
      value: 2,
    };

    const professionalOption = {
      label: 'CodeGuard Profissional',
      value: 3,
    };

    const premiumOption = {
      label: 'CodeGuard Premium',
      value: 4,
    };

    const shouldIncludeStarterOption = [
      HgProducts.CODEGUARD_BASICO,
    ].includes(selectedProduct.pid);

    const shouldIncludeProfessional = [
      HgProducts.CODEGUARD_BASICO,
      HgProducts.CODEGUARD_INICIANTE,
      HgProducts.CODEGUARD_PREMIUM,
    ].includes(selectedProduct.pid);

    const shouldIncludePremium = [
      HgProducts.CODEGUARD_BASICO,
      HgProducts.CODEGUARD_INICIANTE,
      HgProducts.CODEGUARD_PROFISSIONAL,
    ].includes(selectedProduct.pid);

    shouldIncludeStarterOption && options.push(starterOption);
    shouldIncludeProfessional && options.push(professionalOption);
    shouldIncludePremium && options.push(premiumOption);

    setProductOptions(options);
  }, [selectedProduct]);

  useEffect(() => {
    createUpgradeOptions();
  }, [createUpgradeOptions]);


  const mountCodeGuardTag = useCallback(() => {
    const rules = {
      [HgProducts.CODEGUARD_BASICO]: 'codeguard_basico',
      [HgProducts.CODEGUARD_INICIANTE]: 'codeguard_iniciante',
      [HgProducts.CODEGUARD_PROFISSIONAL]: 'codeguard_profissional',
      [HgProducts.CODEGUARD_PREMIUM]: 'codeguard_premium',
    };

    return rules[selectedProduct.pid];
  }, [
    selectedProduct,
  ]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.upgradeSiteLock,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.UPGRADE_CODEGUARD,
      tags: [
        'whmcs_form_financeiro_upgrade_codeguard',
        mountCodeGuardTag(),
      ],
      body: `
        • ${upgradeCodeGuardLocale.body.product(selectedProduct)}
        • ${upgradeCodeGuardLocale.body.request}
        • ${upgradeCodeGuardLocale.body.newProduct(formData.newProduct)}
        • ${upgradeCodeGuardLocale.body.agreed(formData.agreed)}
        • ${upgradeCodeGuardLocale.body.agreedSelectedPlan(formData.agreedSelectedPlan)}
        • ${upgradeCodeGuardLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    upgradeCodeGuardLocale,
    mountCodeGuardTag,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {upgradeCodeGuardLocale.info}
      </Styles.Info>

      <Styles.Title>
        {upgradeCodeGuardLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Select
          name="newProduct"
          testId="newProduct"
          placeholder={upgradeCodeGuardLocale.placeholders.newProduct}
          label={formData.newProduct ? upgradeCodeGuardLocale.placeholders.newProduct : ''}
          options={productOptions}
          value={formData.newProduct}
          setValue={label => handleChange({ key: 'newProduct', value: label })}
          withScroll
        />
      </Styles.InputsWrapper>

      <Styles.InputsWrapper>
        <Styles.CheckboxLabel
          htmlFor="#readTerms"
          data-testid="checkReadTerms"
          onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
        >
          <Checkbox checked={formData.agreed} id="readTerms" />
          <Styles.Text>
            {upgradeCodeGuardLocale.placeholders.agreed}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsSelectedPlan"
          data-testid="checkReadTermsSelectedPlan"
          onClick={() => handleChange({ key: 'agreedSelectedPlan', value: !formData.agreedSelectedPlan })}
        >
          <Checkbox checked={formData.agreedSelectedPlan} id="readTermsSelectedPlan" />
          <Styles.Text>
            {upgradeCodeGuardLocale.placeholders.agreedSelectedPlan}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputsWrapper>


      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : upgradeCodeGuardLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default CodeGuardUpgrade;
