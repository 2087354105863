import React from 'react';
import * as Styles from './DnsWizardConfigCardDnsZone.styles';
import lojaIntegradaLogo from '@/media/domains/lojaintegrada-card-big.png';
import nuvemshopLogo from '@/media/domains/nuvemshop-card-big.png';
import shopifyLogo from '@/media/domains/shopify-card-big.png';
import tiendanubeLogo from '@/media/domains/tiendanube-card-big.png';
import dLojaLogo from '@/media/domains/dloja.png';
import { palette } from '@/styles/HgThemeProvider';
import { Tooltip } from '@/pages/common/v1/Tooltip';
import { IconInfo } from '@/icons';
import {
  HOSTING_PLATFORM_TYPES,
  PLATFORM_NAMES,
  CUSTOM_DNS_ZONE_PLATFORMS_URL,
} from '@/enums/domains/dns.enum';
import IconAttentionYellow from '@/icons/IconAttentionYellow/IconAttentionYellow';
import { Link } from '@/pages/common';
import { COUNTRY } from '@/config';
import { isMobile } from '@/utils/Validators/validation';
import { Button } from 'gatorcomponents';

const DnsWizardConfigCardDnsZone = ({
  platformName = '',
  hostingPlatforms,
  changePlatformText = '',
  nameServers = [],
  tooltip = '',
  accessText = '',
  serverText,
  attentionMessage,
  testId = 'dns-wizard-config-card-dns-zone',
  changePlatformClick,
  resellerMessage,
  isPointedToResale = false,
}) => {
  const { secondary } = palette;
  const { primary: lightBlueColor } = secondary;

  const currentPlatform = hostingPlatforms && hostingPlatforms.find(platform => platform.platformType === platformName);

  const customPlatformsUrl = platform => ({
    ...CUSTOM_DNS_ZONE_PLATFORMS_URL,
    shopify: CUSTOM_DNS_ZONE_PLATFORMS_URL[`shopify_${COUNTRY}`],
  })[platform];

  const platformLogoByName = (name) => {
    switch (name) {
      case HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA:
        return lojaIntegradaLogo;
      case HOSTING_PLATFORM_TYPES.NUVEMSHOP:
        return nuvemshopLogo;
      case HOSTING_PLATFORM_TYPES.SHOPIFY:
        return shopifyLogo;
      case HOSTING_PLATFORM_TYPES.TIENDANUBE:
        return tiendanubeLogo;
      case HOSTING_PLATFORM_TYPES.DLOJA:
        return dLojaLogo;
      default:
        break;
    }
  };

  const platformLogo = currentPlatform ? currentPlatform.imageUrl : platformLogoByName(platformName);
  const platformLabel = currentPlatform ? currentPlatform.platformName : PLATFORM_NAMES[platformName];

  return (
    <Styles.DnsWizardPlanCardWrapper data-testid={testId}>
      <Styles.Header>
        <Styles.LogoWrapper>
          <Styles.Logo
            src={platformLogo}
            loading="lazy"
          />
          <Tooltip content={tooltip} preferredPosition="bottom"><IconInfo color={lightBlueColor} /></Tooltip>
          <Styles.PlatformLinkWrapper>
            <Link text={`${accessText} ${platformLabel}`} href={customPlatformsUrl(platformName)} target="_blank" />
          </Styles.PlatformLinkWrapper>
        </Styles.LogoWrapper>

        {!isMobile && (
          <Styles.Actions>
            <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
          </Styles.Actions>
        )}
      </Styles.Header>
      <Styles.Body>
        {nameServers && nameServers.map((nameServer, index) => (
          <Styles.Nameservers key={nameServer}>
            {`${serverText} ${index + 1}: `}
            <span>{nameServer}</span>
          </Styles.Nameservers>
        ))}
      </Styles.Body>
      <Styles.Footer data-testid="attention-message">
        <IconAttentionYellow />
        <Styles.FooterMessage>
          {attentionMessage}
          {isPointedToResale && resellerMessage}
        </Styles.FooterMessage>
      </Styles.Footer>

      {isMobile && (
        <Styles.Actions>
          <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
        </Styles.Actions>
      )}

    </Styles.DnsWizardPlanCardWrapper>
  );
};

export default DnsWizardConfigCardDnsZone;
