import React from 'react';
import * as Styles from './DnsCard.styles';
import {
  Alert,
  Button,
  IconDomain, IconFilledInfo, Tag, Tooltip,
} from 'gatorcomponents';

const DnsCard = ({
  manageDomainHandlerLocale,
  isInPropagation,
  propagationStatus,
  detailState,
  isPointedToReserved,
  redirectToDnsWizard,
}) => {
  const handleRedirect = () => {
    redirectToDnsWizard && redirectToDnsWizard();
  };

  return (
    <>
      <Styles.CardTitle>
        <IconDomain />
        {manageDomainHandlerLocale.cardOptions.dns.title}

        <Tooltip content={manageDomainHandlerLocale.cardOptions.dns.tooltip}>
          <IconFilledInfo color="primaryBlue" />
        </Tooltip>

        {isInPropagation && (
        <Styles.TagWrapperDesktop>
          <Tag
            variant="pending"
            label={manageDomainHandlerLocale.tags[propagationStatus.status]}
            testId={`${propagationStatus.status}-tag`}
          />
        </Styles.TagWrapperDesktop>
        )}
      </Styles.CardTitle>

      {isInPropagation && (
        <Styles.TagWrapperMobile>
          <Tag
            variant="pending"
            label={manageDomainHandlerLocale.tags[propagationStatus.status]}
            testId={`${propagationStatus.status}-tag`}
          />
        </Styles.TagWrapperMobile>
      )}

      <Styles.DnsContent>
        <>
          <Styles.DnsSpan data-testid="first-dns">
            {detailState.nameservers && detailState.nameservers.length
              ? `${detailState.nameservers[0]} `
              : '- '
          }
            <Styles.SlashSpan>/</Styles.SlashSpan>
          </Styles.DnsSpan>
          <Styles.DnsSpan data-testid="second-dns">
            {detailState.nameservers && detailState.nameservers.length
              ? `${detailState.nameservers[1]}`
              : '- '
          }
          </Styles.DnsSpan>
        </>
      </Styles.DnsContent>

      {isInPropagation && (
      <Styles.NoPaddingAlertWrapper>
        <Alert
          variant="info"
          withoutBackground
          description={manageDomainHandlerLocale.cardOptions.dns.alert[propagationStatus.status]({
            dotBr: propagationStatus.domain.includes('.br'),
          })}
          testId="in_progress-alert"
        />
      </Styles.NoPaddingAlertWrapper>
      )}

      {isPointedToReserved && (
      <Alert
        variant="warning"
        description={manageDomainHandlerLocale.cardOptions.dns.alert.reserved}
        testId="reserved-alert"
      />
      )}

      <Button
        label={manageDomainHandlerLocale.cardOptions.dns.cta}
        onClick={handleRedirect}
        testId="dnsCta"
        variant="secondary"
        width="fit"
      />
    </>
  );
};

export default DnsCard;
