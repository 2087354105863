import React, { useContext, useState } from 'react';
import Loader from '@/components/Layout/Loader';
import ProEmailsNewList from '@/components/Email/ProEmail/ProEmailsNewList';
import ProEmailNewTitle from '@/components/Email/ProEmail/ProEmailNewTitle';
import { EmailsContext } from '@/contexts/Emails/context';
import FaqEmails from '@/components/FrequentlyQuestions/FaqEmails';
import styles from './styles.module.css';
import { SUPPORT_URL } from '@/config/urls/supportUrls';
import { ALPHA_ENABLED_SORT_SUBDOMAINS_LIST } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ProEmailListWrapper = () => {
  const {
    firstLoad, loadingCpanel, loadingProEmails,
  } = useContext(EmailsContext);
  const [displaySearch, setDisplaySearch] = useState(true);

  const setEnableSearch = (isEnabled) => {
    setDisplaySearch(isEnabled);
  };
  const enabledSortSubdomainsList = useFeatureIsOn(ALPHA_ENABLED_SORT_SUBDOMAINS_LIST);

  if (firstLoad || loadingCpanel || loadingProEmails) {
    return <Loader data-testid="loader" />;
  }

  return (
    <>
      <ProEmailNewTitle displaySearch={displaySearch} />
      <ProEmailsNewList setEnableSearch={setEnableSearch} enabledSortSubdomainsList={enabledSortSubdomainsList} />
      <div className={styles.bottomInfoWrapper}>
        <FaqEmails link={SUPPORT_URL} />
      </div>
    </>
  );
};

export default ProEmailListWrapper;
