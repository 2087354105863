import React, { useState } from 'react';
import { withStyles, ClickAwayListener } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { ArrowDropDown } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '@/components/Layout/Loader';
import styles from './styles';

const SelectDomainCustom = ({
  classes, t, options, selectedValue, onHandleChangeSelectValue,
}) => {
  const refDropdown = React.createRef();
  const refLabel = React.createRef();
  const refInput = React.createRef();
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    refInput.current.focus();
  };

  const handleSelectItem = (event, keySelected, optionId) => {
    onHandleChangeSelectValue(event.target.textContent, keySelected, optionId);
    setOpen(!open);
  };

  const handleBack = () => {
    setOpen(false);
    onHandleChangeSelectValue('');
  };

  const handleShowdomainList = (options, key, isCategory) => {
    if (isCategory) {
      return options[key].map(item => (
        <li
          key={item.id}
          className={classes.item}
          role="presentation"
          onClick={e => handleSelectItem(e, key, item.id)}
          data-testid={`option_${item.domain}`}
        >
          {item.domain}
        </li>
      ));
    }

    return null;
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)} data-testid="select-domain-custom">
      <div className={classes.wrapper}>
        <label
          className={classnames(classes.label, {
            [classes.focus]: selectedValue !== '',
            [classes.color]: open,
          })}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="menu"
          htmlFor="label"
          ref={refLabel}
          onClick={handleOpen}
          onKeyDown={handleOpen}
        >
          {t('dns.selectDomain')}
        </label>

        <input
          ref={refInput}
          type="text"
          className={classes.input}
          value={selectedValue}
          onClick={handleOpen}
          readOnly="readonly"
          data-testid="inputedValue"
        />
        <ArrowDropDown className={classes.svg} onClick={handleOpen} data-testid="arrowDropDown" />

        {open && (
          <div className={classes.dropdown} ref={refDropdown} data-testid="customSelectOptions">
            <ul className={classes.backlist}>
              <li className={classes.backitem} role="presentation" onClick={handleBack} data-testid="backItem">{t('dns.selectDomain')}</li>
            </ul>
              {Object.keys(options).map((key) => {
                const isCategory = key !== 'loadingInternalRequest' && key !== 'loadingExternalRequest';

                return (
                  <ul key={key} className={classes.list}>

                    {isCategory && options[key].length > 0
                      && <li className={classes.categorie}>{t(`dnsGroups.${key}`)}</li>
                    }

                    {key === 'loadingExternalRequest' && options[key]
                      && <li className={classes.categorie}>{t('dnsGroups.anotherProvider')}</li>
                    }

                    {(!isCategory && options[key])
                      ? (<Loader data-testid="loader" />)
                      : handleShowdomainList(options, key, isCategory)
                    }
                  </ul>
                );
              })
              }
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

SelectDomainCustom.propTypes = {
  options: PropTypes.object.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onHandleChangeSelectValue: PropTypes.func.isRequired,
};

export default withI18n()(withStyles(styles)(SelectDomainCustom));
