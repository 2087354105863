const styles = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px 30px 20px 30px',
    overflow: 'overlay',
  },
  endMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
