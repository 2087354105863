import { locale } from '@/utils/locale';

export const useCardActionsLocale = {
  websitebuilderAction: locale('actions.sites-actions-sitebuilder'),
  routeWebsitebuilderRedirectAccess: locale('routes.siteBuilderRedirectAccess'),
  resellerAction: locale('actions.sites-actions-reseller'),
  wordpressAction: locale('actions.sites-actions-wordpress'),
  easyPageSite: locale('actions.sites-easyPage'),
  backupSite: locale('actions.sites-backup'),
  sslSite: locale('actions.sites-ssl'),
  sitelock: locale('actions.sites-sitelock'),
  manageSite: locale('actions.sites-gerenciar'),
  cloudflareSite: locale('actions.sites-cloudflare'),
  signaturesSite: locale('actions.sites-signatures'),
  excludeSite: locale('actions.sites-exclude'),
  pageDescription: {
    empty: {
      title: locale('sites.pageDescription.empty.title'),
      subtitle: locale('sites.pageDescription.empty.subtitle'),
    },
    hasOtherThanPendingStites: {
      composedSubtitle: {
        0: locale('sites.pageDescription.hasOtherThanPendingStites.composedSubtitle.0'),
        1: locale('sites.pageDescription.hasOtherThanPendingStites.composedSubtitle.1'),
        2: locale('sites.pageDescription.hasOtherThanPendingStites.composedSubtitle.2'),
        3: locale('sites.pageDescription.hasOtherThanPendingStites.composedSubtitle.3'),
      },
      singleSubtitle: locale('sites.pageDescription.hasOtherThanPendingStites.singleSubtitle'),
      buttonLabel: locale('sites.pageDescription.hasOtherThanPendingStites.buttonLabel'),
    },
    onlyPendingSites: {
      subtitle: locale('sites.pageDescription.onlyPendingSites.subtitle'),
    },
  },
  filter: {
    options: {
      all: locale('sites.filter.options.all'),
      out: locale('sites.filter.options.out'),
      pending: locale('sites.filter.options.pending'),
      filterBy: locale('sites.filter.options.filterBy'),
    },
    clear: locale('sites.filter.clear'),
    searchBarPlaceHolder: locale('sites.filter.placeholder'),
    noResults: {
      field: locale('sites.filter.noResults.field'),
    },
  },
};
