import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import styles from './styles';


const CardWarning = ({
  classes, className, icon, status, message, iconSize,
}) => {
  const IconImage = icon || Warning;
  return (
    <div className={classnames(className, classes.cardWarning, {
      [classes.statusInfo]: status === 'Info',
      [classes.statusActive]: status === 'Active',
      [classes.statusPending]: status === 'Pending',
      [classes.statusPendingTransfer]: status === 'Pending_Transfer',
      [classes.statusExpired]: status === 'Expired',
      [classes.statusCancelled]: status === 'Cancelled',
      [classes.statusFraud]: status === 'Fraud',
      [classes.statusSuspended]: status === 'Suspended',
      [classes.statusBuilding]: status === 'Building',
    })}
    >
      <IconImage fontSize={iconSize} className={classes.cardWarningIcon} />
      <p className={classes.cardWarningText}>{message}</p>
    </div>
  );
};

export default withStyles(styles)(CardWarning);
