import React from 'react';
import { locale } from '@/utils/locale';
import { isBRServer } from '@/utils/Validators/validation';
import { Button as OldButton } from '@/pages/common';
import { Button } from 'gatorcomponents';

export const routesLocale = {
  invoices: () => locale('routes.invoices'),
  unpaid: () => locale('routes.unpaid'),
};

export const supportLocale = {
  wizard: () => locale('subscriptions.supportWizard'),
};


const displayLinkToModalPayment = (linkText, onDisplayModalPayment, subscription, newButton) => (
  newButton ? (
    <Button
      variant="text"
      onClick={() => { onDisplayModalPayment(subscription); }}
      label={linkText}
      testId="link-invoice-modal-payment"
    />
  ) : (
    <OldButton
      variant="link"
      size="small"
      align="left"
      onClick={() => { onDisplayModalPayment(subscription); }}
      label={linkText}
      testId="link-invoice-modal-payment"
    />
  )
);

export const messagesLocale = {


  pendingPayment: enabledNewMessagesInSubscription => (
    <p>
      {(enabledNewMessagesInSubscription && isBRServer) ? locale('subscriptions.message.newPendingPayment') : locale('subscriptions.message.pendingPayment')}
    </p>
  ),
  pendingAnalysis: enabledNewMessagesInSubscription => (
    <p>
      {(enabledNewMessagesInSubscription && isBRServer) ? locale('subscriptions.message.newPendingAnalysis') : locale('subscriptions.message.pendingAnalysis')}
    </p>
  ),
  toDue: ({
    paymentMethod,
    dueDate,
    invoiceUrl,
    enabledNewMessagesInSubscription,
    enabledModalPaymentInSubscriptionList,
    onDisplayModalPayment,
    subscription,
  }) => (
    <p>
      {locale('subscriptions.message.toDue.0')}
      <strong>
        {`${locale('subscriptions.message.toDue.1')} ${dueDate}`}
        {(paymentMethod && ['ticket', 'gp_pix'].includes(paymentMethod) && isBRServer) && locale('subscriptions.message.toDue.ticket.0')}
      </strong>

      {(paymentMethod && ['ticket', 'gp_pix'].includes(paymentMethod))
        ? (
          <>
            {enabledModalPaymentInSubscriptionList !== true
              ? (
                <a
                  href={invoiceUrl}
                  onClick={event => event.preventDefault()}
                >
                  {(enabledNewMessagesInSubscription === true && isBRServer) ? locale('subscriptions.message.toDue.ticket.3') : locale('subscriptions.message.toDue.ticket.1')}
                </a>
              )
              : displayLinkToModalPayment(locale((enabledNewMessagesInSubscription === true && isBRServer) ? 'subscriptions.message.toDue.ticket.3' : 'subscriptions.message.toDue.ticket.1'), onDisplayModalPayment, subscription)
          }
            {locale('subscriptions.message.toDue.ticket.2')}
          </>
        )
        : locale(`subscriptions.message.toDue.${paymentMethod}.0`)}
    </p>
  ),
  toDueWithTypeCancel: ({
    dueDate,
    invoiceUrl,
  }) => (
    <p>
      {locale('subscriptions.message.active.withCancelDate.0')}
      <strong>
        {`${locale('subscriptions.message.active.withCancelDate.1')} ${dueDate}.`}
      </strong>
      <a
        href={invoiceUrl}
        onClick={event => event.preventDefault()}
      >
        {locale('subscriptions.message.active.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.active.withCancelDate.3')}
    </p>
  ),
  overdue: ({
    dueDate,
    daysToExpiration,
    singularText,
    invoiceUrl,
  },
  enabledNewMessagesInSubscription,
  enabledModalPaymentInSubscriptionList,
  onDisplayModalPayment,
  subscription) => (
    <p>
      {locale('subscriptions.message.overdue.0')}
      <strong>
        {`${locale('subscriptions.message.overdue.1')} ${dueDate}`}
      </strong>
      {`${locale('subscriptions.message.overdue.2')}`}
      <strong>
        {`${locale('subscriptions.message.overdue.3')} ${daysToExpiration} ${locale(`subscriptions.message.overdue.4${singularText}`)}`}
      </strong>
      {enabledModalPaymentInSubscriptionList !== true ? (
        <a
          href={invoiceUrl}
          onClick={event => event.preventDefault()}
        >
          {(enabledNewMessagesInSubscription === true && isBRServer) ? locale('subscriptions.message.overdue.6') : locale('subscriptions.message.overdue.5')}
        </a>
      )
        : displayLinkToModalPayment(locale((enabledNewMessagesInSubscription === true && isBRServer) ? 'subscriptions.message.overdue.6' : 'subscriptions.message.overdue.5'), onDisplayModalPayment, subscription)
        }

    </p>
  ),
  overdueAndIsMoreThan10DaysLate: ({
    dueDate,
  }) => (
    <p>
      {locale('subscriptions.message.offAfterExpiration.0')}
      <strong>
        {`${locale('subscriptions.message.offAfterExpiration.1')} ${dueDate}`}
      </strong>
      {`${locale('subscriptions.message.offAfterExpiration.2')}`}
    </p>
  ),
  cancelled: ({
    cancelDate,
    country,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.default.0')}
      <strong>
        {locale('subscriptions.message.cancelled.default.1', {
          cancelDate,
        })}
      </strong>
      {locale('subscriptions.message.cancelled.default.2')}
      <strong>
        {locale('subscriptions.supportEmail', { country })}
      </strong>
      {locale('subscriptions.message.cancelled.default.3')}
    </p>
  ),
  cancelledWithStatusFraud: ({
    country,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.fraud.0')}
      <strong>{locale('subscriptions.message.cancelled.fraud.1')}</strong>
      {locale('subscriptions.message.cancelled.fraud.2')}
      <strong>{locale('subscriptions.supportEmail', { country })}</strong>
      {locale('subscriptions.message.cancelled.fraud.3')}
    </p>
  ),
  cancelledWithoutDateCancel: ({
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.withoutDate.0')}
      <strong>{locale('subscriptions.message.cancelled.withoutDate.1')}</strong>
      {locale('subscriptions.message.cancelled.withoutDate.2')}
      <a
        href={siteUrl}
        onClick={event => event.preventDefault()}
        title={locale('subscriptions.message.cancelled.withoutDate.3')}
      >
        {locale('subscriptions.message.cancelled.withoutDate.3')}
      </a>
    </p>
  ),
  cancelledWithoutDateCancelEcommercePlus: () => (
    <p>
      {locale('subscriptions.message.cancelled.withoutDate_ecommerce_plus.0')}
      <strong>{locale('subscriptions.message.cancelled.withoutDate_ecommerce_plus.1')}</strong>
      {locale('subscriptions.message.cancelled.withoutDate_ecommerce_plus.2')}
    </p>
  ),
  cancelledWithCancelTypeUserRequest: ({
    cancelDate,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.user_request.0')}
      <strong>
        {locale('subscriptions.message.cancelled.user_request.1', {
          cancelDate,
        })}
      </strong>
      {'. '}
      <a
        href={siteUrl}
        onClick={event => event.preventDefault()}
        title={locale('subscriptions.message.cancelled.user_request.2')}
      >
        {locale('subscriptions.message.cancelled.user_request.2')}
      </a>
    </p>
  ),
  cancelledWithCancelTypeUserRequestEcommercePlus: ({
    cancelDate,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.user_request_ecommerce_plus.0')}
      <strong>
        {locale('subscriptions.message.cancelled.user_request_ecommerce_plus.1', {
          cancelDate,
        })}
      </strong>
    </p>
  ),
  cancelledWithCancelTypeWhmcsSuspension: ({
    cancelDate,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.whmcs_suspension.0')}
      <strong>
        {locale('subscriptions.message.cancelled.whmcs_suspension.1', { cancelDate })}
      </strong>
      {` ${locale('subscriptions.message.cancelled.whmcs_suspension.2')}`}
      <a
        href={siteUrl}
        onClick={event => event.preventDefault()}
        title={locale('subscriptions.message.cancelled.whmcs_suspension.3')}
      >
        {locale('subscriptions.message.cancelled.whmcs_suspension.3')}
      </a>
    </p>
  ),
  cancelledWithCancelTypeWhmcsSuspensionEcommercePlus: ({
    cancelDate,
  }) => (
    <p>
      {locale('subscriptions.message.cancelled.whmcs_suspension_ecommerce_plus.0')}
      <strong>
        {locale('subscriptions.message.cancelled.whmcs_suspension_ecommerce_plus.1', { cancelDate })}
      </strong>
      {` ${locale('subscriptions.message.cancelled.whmcs_suspension_ecommerce_plus.2')}`}
    </p>
  ),
  activeWithCancelType: ({
    cancelDate,
  }) => (
    <p>
      {locale('subscriptions.message.active.withCancelDate.0')}
      <strong>
        {`${locale('subscriptions.message.active.withCancelDate.1')} ${cancelDate}.`}
      </strong>
      <a
        href={locale('subscriptions.message.active.withCancelDate.2')}
        onClick={event => event.preventDefault()}
      >
        {locale('subscriptions.message.active.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.active.withCancelDate.3')}
    </p>
  ),
  registeredWithCancelTypeUserRequest: ({
    cancelDate,
    activeRenew,
    activeRenewPath,
  }) => (
    <p>
      {locale('subscriptions.message.domain.registered.withCancelDate.0')}
      <strong>{`${locale('subscriptions.message.domain.registered.withCancelDate.1')} ${cancelDate}.`}</strong>
      <a
        href={activeRenewPath}
        onClick={activeRenew}
      >
        {locale('subscriptions.message.domain.registered.withCancelDate.2')}
      </a>
      {locale('subscriptions.message.domain.registered.withCancelDate.3')}
    </p>
  ),
  domainOverdue: ({
    daysToExpiration,
    dueDate,
    invoiceUrl,
    singularText,
    enabledNewMessagesInSubscription,
  }) => (
    <p>
      {locale('subscriptions.message.domain.overdue.0')}
      <strong>{`${locale('subscriptions.message.domain.overdue.1')} ${dueDate}`}</strong>
      {`${locale('subscriptions.message.domain.overdue.2')} ${daysToExpiration} ${locale(`subscriptions.message.domain.overdue.3${singularText}`)}`}
      <a
        href={invoiceUrl}
        onClick={event => event.preventDefault()}
      >
        {(enabledNewMessagesInSubscription === true && isBRServer) ? locale('subscriptions.message.domain.overdue.5') : locale('subscriptions.message.domain.overdue.4')}
      </a>
    </p>
  ),
  domainExpired: ({
    dueDate,
    invoiceUrl,
    enabledNewMessagesInSubscription,
    enabledModalPaymentInSubscriptionList,
    onDisplayModalPayment,
    subscription,
  }) => (
    <p>
      {locale('subscriptions.message.domain.expired.0')}
      <strong>{`${locale('subscriptions.message.domain.expired.1')} ${dueDate}`}</strong>
      {locale('subscriptions.message.domain.expired.2')}
      {enabledModalPaymentInSubscriptionList !== true
        ? (
          <a
            href={invoiceUrl}
            onClick={event => event.preventDefault()}
          >
            {(enabledNewMessagesInSubscription === true && isBRServer) ? locale('subscriptions.message.domain.expired.4') : locale('subscriptions.message.domain.expired.3')}
          </a>
        )
        : displayLinkToModalPayment(locale((enabledNewMessagesInSubscription === true && isBRServer) ? 'subscriptions.message.domain.expired.4' : 'subscriptions.message.domain.expired.3'), onDisplayModalPayment, subscription)
              }
    </p>
  ),
  domainCancelledForNonPayment: ({
    cancelDate,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.domain.cancelled.forNonPayment.0')}
      <strong>{`${locale('subscriptions.message.domain.cancelled.forNonPayment.1')} ${cancelDate}`}</strong>
      {locale('subscriptions.message.domain.cancelled.forNonPayment.2')}
      <a
        href={siteUrl}
        onClick={event => event.preventDefault()}
        title={locale('subscriptions.message.domain.cancelled.forNonPayment.3')}
      >
        {locale('subscriptions.message.domain.cancelled.forNonPayment.3')}
      </a>
    </p>
  ),
  domainCancelledByClient: ({
    cancelDate,
    siteUrl,
  }) => (
    <p>
      {locale('subscriptions.message.domain.cancelled.byClient.0')}
      <strong>{`${locale('subscriptions.message.domain.cancelled.byClient.1')} ${cancelDate}`}</strong>
      <a
        href={siteUrl}
        onClick={event => event.preventDefault()}
        title={locale('subscriptions.message.domain.cancelled.byClient.2')}
      >
        {locale('subscriptions.message.domain.cancelled.byClient.2')}
      </a>
    </p>
  ),
  transferredAway: () => (
    <p>
      {locale('subscriptions.message.domain.transferredAway')}
    </p>
  ),
  domainCancelledByFraud: ({
    supportUrl,
  }) => (
    <p>
      <strong>{`${locale('subscriptions.message.domain.cancelled.byFraud.0')}`}</strong>
      {locale('subscriptions.message.domain.cancelled.byFraud.1')}
      <a
        href={supportUrl}
        title={locale('subscriptions.message.domain.cancelled.byFraud.2')}
      >
        {locale('subscriptions.message.domain.cancelled.byFraud.2')}
      </a>
      {locale('subscriptions.message.domain.cancelled.byFraud.3')}
    </p>
  ),
  default: {
    suspended: {
      standard: ({
        onDisplayModalPayment,
        subscription,
      }) => (
        <>
          {locale('subscriptions.message.suspended.standard.0')}
          {displayLinkToModalPayment(locale('subscriptions.message.suspended.standard.1'), onDisplayModalPayment, subscription, true)}
          {locale('subscriptions.message.suspended.standard.2')}
          <strong>
            {locale('subscriptions.message.suspended.standard.3')}
          </strong>
        </>
      ),
      withDate: ({
        dateSuspended,
        invoiceUrl,
        category,
        enabledNewMessagesInSubscription,
        enabledModalPaymentInSubscriptionList,
        onDisplayModalPayment,
        subscription,
      }) => (
        <p>
          {locale('subscriptions.message.suspended.withDate.0')}
          <strong>
            {locale('subscriptions.message.suspended.withDate.1')}
            {dateSuspended}
          </strong>
          {(category === 'hosting') ? (
            <>
              {`${locale('subscriptions.message.suspended.withDate.2')}`}
              <strong>
                {locale('subscriptions.message.suspended.withDate.3')}
              </strong>
            </>
          ) : '.'}
          {enabledModalPaymentInSubscriptionList !== true
            ? (
              <a
                href={invoiceUrl}
                onClick={event => event.preventDefault()}
              >
                {(enabledNewMessagesInSubscription === true && isBRServer) ? locale('subscriptions.message.suspended.withDate.6') : locale('subscriptions.message.suspended.withDate.4')}
              </a>
            )
            : displayLinkToModalPayment(locale((enabledNewMessagesInSubscription === true && isBRServer) ? 'subscriptions.message.suspended.withDate.6' : 'subscriptions.message.suspended.withDate.4'), onDisplayModalPayment, subscription)
              }
          {enabledNewMessagesInSubscription !== true && (locale('subscriptions.message.suspended.withDate.5'))}
        </p>
      ),
      withoutDate: ({
        chatSupportUrl,
        category,
      }) => (
        <p>
          {locale('subscriptions.message.suspended.withoutDate.0')}
          <strong>{locale('subscriptions.message.suspended.withoutDate.1')}</strong>
          {category === 'hosting' ? locale('subscriptions.message.suspended.withoutDate.2') : ''}
          {locale('subscriptions.message.suspended.withoutDate.3')}
          <a href={chatSupportUrl}>
            {locale('subscriptions.message.suspended.withoutDate.4')}
          </a>
          {locale('subscriptions.message.suspended.withoutDate.5')}
        </p>
      ),
    },
  },
};
