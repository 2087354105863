import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '@/components/Layout/Loader';
import FaqDomainDetail from '@/components/FrequentlyQuestions/FaqDomainDetail';
import DomainDetail from '@/components/Domain/DomainDetail';
import { loadDomainDetail } from '@/redux/actions/domains';
import { loadSubdomains } from '@/redux/actions/subdomains';
import { loadDomainRenewal } from '@/redux/actions/domainRenewal';
import { BottomAdvertisementHandler } from '@/pages/advertisement/BottomAdvertisementHandler';
import styles from './styles';
import DomainPropagationAlert from '@/components/Domain/DomainPropagationAlert';

const LOCATION_DOMAINS = 'dominios';

class DomainDetailPage extends Component {
  componentDidMount() {
    const {
      loadDomainDetail,
      loadSubdomains,
      domainId,
    } = this.props;

    loadDomainDetail(domainId);
    loadSubdomains(domainId);
  }

  handleDomainRenewal = (domainId, domainStatus) => {
    const { loadDomainRenewal } = this.props;
    loadDomainRenewal(domainId, domainStatus);
  }

  render() {
    const {
      classes, domain, loading, subdomains, propagationStatus, canMapEppKeyButtons, setCanMapEppKeyButtons,
    } = this.props;

    const domainPropagation = propagationStatus.find(dnsPropagation => dnsPropagation.id === domain.id);

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.alertWrapper}>
              <DomainPropagationAlert />
            </div>
            <div className={classes.contentDetails}>
              <Grid container spacing={16}>
                <DomainDetail
                  key={domain.id}
                  domain={domain}
                  subdomains={subdomains}
                  loading={loading}
                  onHandleDomainRenewal={this.handleDomainRenewal}
                  domainPropagationStatus={domainPropagation && domainPropagation.status}
                  canMapEppKeyButtons={canMapEppKeyButtons}
                  setCanMapEppKeyButtons={setCanMapEppKeyButtons}
                />
              </Grid>
              <div className={classes.bottomInfoWrapper}>
                <FaqDomainDetail className={classes.faq} />
                <BottomAdvertisementHandler advertisementLocal={LOCATION_DOMAINS} />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.domains.detail.loading,
  eppKeyButtons: state.domains.detail.eppKeyButtons,
  domain: state.domains.detail,
  subdomains: state.subdomains.byId,
  subdomainsLoading: state.subdomains.loading,
  propagationStatus: state.newDomains.propagationStatus,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadDomainDetail,
  loadSubdomains,
  loadDomainRenewal,
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DomainDetailPage));
