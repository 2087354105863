const styles = theme => ({
  titleElement: {
    paddingBottom: 6,
    paddingTop: 0,
    padding: 0,
    margin: '0 0 0 42px',

    '& h6': {
      color: theme.color.thundora,
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: '0.01px',
      lineHeight: '29px',
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: 26,
      paddingBottom: 11,
    },
  },

  description: {
    fontSize: '14px',
    marginBottom: '24px',

    [theme.breakpoints.down(960)]: {
      margin: '0 26px 24px',
      marginBottom: '12px',
    },
  },

  banner: {
    marginBottom: '12px',
    maxWidth: '100%',
    width: '100%',

    [theme.breakpoints.down(960)]: {
      margin: '0 26px 24px',
      maxWidth: 'calc(100% - 52px)',
      width: '100%',
    },
  },

  closeButton: {
    margin: '15px 17px 0 auto',
    maxHeight: 24,
    maxWidth: 24,
  },

  containerWrapper: {
    flex: 'unset',
    margin: '0 20px 0 42px',
    overflowY: 'unset',
    padding: 0,
    paddingBottom: '15px',

    [theme.breakpoints.down(960)]: {
      margin: 0,
    },
  },
});

export default styles;
