import React from 'react';
import * as Styles from './UpgradeBanner.styles';
import { Button, IconTriangleDetail } from 'gatorcomponents';
import sitelock from '@/media/security/sitelock.svg';

const UpgradeBanner = ({ hideTopMessage = false, bannerLocale, handleSignSitelock }) => (
  <Styles.Wrapper>
    {!hideTopMessage && (
    <Styles.VerticalContainer>
      <Styles.Title>{bannerLocale.howToFixThreats}</Styles.Title>
      <Styles.Text>{bannerLocale.howToFixThreatsDescription}</Styles.Text>
    </Styles.VerticalContainer>
    )}
    <Styles.Card>
      <Styles.SignSitelock>
        <Styles.SignatureInfos>
          <Styles.SubscriptTitleContainer>
            <Styles.SitelockLogo src={sitelock} alt="sitelock-logo" />
            <Styles.SubscriptTag>{bannerLocale.tag}</Styles.SubscriptTag>
          </Styles.SubscriptTitleContainer>
          <Styles.Title>{bannerLocale.title}</Styles.Title>
          <Styles.SitelockText>{bannerLocale.description}</Styles.SitelockText>
        </Styles.SignatureInfos>
        <Styles.SignatureContainer>
          <Button size="large" label={bannerLocale.subscribeButton} onClick={handleSignSitelock} />
          <Styles.IconTriangleWrapper>
            <IconTriangleDetail color="primaryBlue" size="md" />
          </Styles.IconTriangleWrapper>
        </Styles.SignatureContainer>
      </Styles.SignSitelock>
    </Styles.Card>
  </Styles.Wrapper>
);

export default UpgradeBanner;
