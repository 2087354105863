import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFaqDns } from '@/redux/actions/faq';
import { SUPPORT_URL } from '@/config/urls/supportUrls';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';


const FaqDns = ({ className }) => {
  const faq = useSelector(state => state.faq);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFaqDns());
  }, [dispatch]);

  return (
    <FaqDefault
      questions={faq.dns.questions}
      link={SUPPORT_URL}
      className={className}
      loading={faq.loading}
    />
  );
};

export default FaqDns;
