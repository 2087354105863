import { HOSTING_PLATFORM_TYPES } from '@/enums/domains/dns.enum';
import { isBRServer } from '@/utils/Validators/validation';

const useFilterPlatforms = (platforms, enabledDloja) => {
  const hgCard = true;
  const lojaIntegradaCard = isBRServer;
  const nuvemshopCard = isBRServer;
  const tiendanubeCard = !isBRServer;
  const externalCard = true;
  const shopifyCard = true;
  const dlojaCard = true;

  const IS_STORYBOOK = process.env.STORYBOOK_IS_SB === '1';

  return platforms.filter((platform) => {
    if (platform.platformType === HOSTING_PLATFORM_TYPES.HOSTGATOR) {
      return hgCard || IS_STORYBOOK;
    }

    if (platform.platformType === HOSTING_PLATFORM_TYPES.LOJA_INTEGRADA) {
      return lojaIntegradaCard || IS_STORYBOOK;
    }

    if (platform.platformType === HOSTING_PLATFORM_TYPES.NUVEMSHOP) {
      return nuvemshopCard || IS_STORYBOOK;
    }

    if (platform.platformType === HOSTING_PLATFORM_TYPES.TIENDANUBE) {
      return tiendanubeCard || IS_STORYBOOK;
    }

    if (platform.platformType === HOSTING_PLATFORM_TYPES.SHOPIFY) {
      return shopifyCard || IS_STORYBOOK;
    }

    if (enabledDloja && platform.platformType === HOSTING_PLATFORM_TYPES.DLOJA) {
      return dlojaCard || IS_STORYBOOK;
    }

    if (platform.platformType === HOSTING_PLATFORM_TYPES.EXTERNAL) {
      return externalCard || IS_STORYBOOK;
    }

    return false;
  });
};

export default useFilterPlatforms;
