import React from 'react';
import * as Style from './IconDetailedStore.style';

const IconDetailedStore = ({
  color = '#4a4a4a',
  backgroundColor = '#ffd54c',
  size = '30',
  testId = 'icon-detailed-store',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill={color}>
      <g id="Grupo_40180" data-name="Grupo 40180" transform="translate(-617 -592.877)">
        <path id="Caminho_238842" data-name="Caminho 238842" d="M332.8,2961.2v24.652h24.652Z" transform="translate(284.199 -2362.974)" fill={backgroundColor} />
        <g id="shopping_cart_black_24dp" transform="translate(618.868 594.863)">
          <path id="Caminho_247864" data-name="Caminho 247864" d="M0,0H26.146V26.146H0Z" transform="translate(0)" fill="none" />
          <path id="Caminho_247865" data-name="Caminho 247865" d="M16.912,14.03A2.177,2.177,0,0,0,18.826,12.9l3.915-7.1a1.089,1.089,0,0,0-.951-1.619H5.6L4.576,2H1V4.187H3.187l3.937,8.3L5.648,15.156A2.191,2.191,0,0,0,7.562,18.4H20.685V16.217H7.562l1.2-2.187ZM6.643,6.374H19.93l-3.018,5.468H9.235ZM7.562,19.5a2.187,2.187,0,1,0,2.187,2.187A2.184,2.184,0,0,0,7.562,19.5Zm10.936,0a2.187,2.187,0,1,0,2.187,2.187A2.184,2.184,0,0,0,18.5,19.5Z" transform="translate(0.068 0.137)" fill={color} />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconDetailedStore;
