import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const Stepper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.v2.colors.neutral.highMedium};
  `}
`;
