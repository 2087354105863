import currency from 'currency.js';
import { COUNTRY } from '@/config';


export const currencies = {
  mx: value => currency(value, {
    separator: ',',
    decimal: '.',
    symbol: '$ ',
    formatWithSymbol: true,
  }),
  cl: value => currency(value, {
    separator: '.',
    precision: 0,
    symbol: '$ ',
    formatWithSymbol: true,
  }),
  co: value => currency(value, {
    separator: '.',
    precision: 0,
    symbol: '$ ',
    formatWithSymbol: true,
  }),
  br: value => currency(value, {
    separator: '.',
    decimal: ',',
    symbol: 'R$ ',
    formatWithSymbol: true,
  }),
};

export const currentCurrency = currencies[COUNTRY] ? currencies[COUNTRY] : currencies.br;
