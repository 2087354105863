import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import {
  withStyles, IconButton, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withI18n } from 'react-i18next';
import BoletoAddressCheck from '@/components/Invoices/BoletoAddressCheck';
import styles from './styles';


const Transition = props => <Slide direction="up" {...props} />;

const ModalCheckData = ({
  t, open, close, classes,
}) => (
  <Dialog
    open={open}
    onClose={close}
    TransitionComponent={Transition}
    disableBackdropClick
    classes={{
      paper: classes.wrapper,
      paperWidthSm: classes.overidePaperWidthSm,
    }}
  >
    <DialogContent className={classes.dialog}>
      <IconButton onClick={close} className={classes.iconButton}>
        <Close />
      </IconButton>

      <Typography className={classes.title}>{t('dialogDataCheck.title')}</Typography>
      <Typography className={classes.description}>{t('dialogDataCheck.description')}</Typography>

      <BoletoAddressCheck />
      <span className={classes.info}>{t('dialogDataCheck.info')}</span>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(withI18n()(ModalCheckData));
