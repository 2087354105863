import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const TitanUpgradeToastWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.white.light};
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family.secondary};
    gap: 16px;
    max-width: 285px;
    overflow: hidden;
  `}
`;

export const DecoLine = styled.img`
  align-self: stretch;
  margin-top:8px;
`;

export const CTA = styled(Link)`
  ${({ theme, arrow }) => css`
    color: ${theme.palette.secondary.primary};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.sizes.xl};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: url(${arrow});
      font-size: ${theme.font.sizes.xxxxxxl};
      font-weight: 1000;
      opacity: 0;
      position: absolute;
      transform: translate(-30px, -3px);
      transition: transform 0.1s, opacity 0.1s;
    }

    &:hover:after {
      opacity: 1;
      transform: translate(5px, -3px);
    }
  `}
`;

export const Description = styled.p`
  ${({ theme, isBr }) => {
    const letterSpacing = isBr ? '0px' : '-0.2px';
    return css`
      font-family: ${theme.font.family.secondary};
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.font.weights.light};
      letter-spacing: ${letterSpacing};
      line-height: ${theme.font.sizes.lg};
      margin: 0;
      padding: 0 24px;
      text-align: center;
    `;
  }
}
`;

export const YourDomain = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-family: ${theme.font.family.secondary};
    font-weight: ${theme.font.weights.bold};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    margin: 0;
    padding: 0;
    text-align: center;
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    align-self: stretch;
    color: ${theme.color.indigo};
    display: flex;
    justify-content: space-between;
    padding: 4px 4px 0 4px;
  `}
`;

export const NewLabel = styled.div`
  ${({ theme, isBr }) => {
    const letterSpacing = isBr ? '0px' : '-1px';
    const transform = isBr ? 'rotate(-45deg) translate(-5px, 3px)' : 'rotate(-45deg) translate(-6px, 3px)';
    return css`
      background: ${theme.color.indigo};
      clip-path: polygon(0 0, 0 100%, 100% 0);
      height: 48px;
      width: 48px;
      & span {
        color: ${theme.color.white};
        display:block;
        font-family: ${theme.font.family.secondary};
        font-size: ${theme.font.sizes.xxs};
        font-weight: ${theme.font.weights.bold};
        letter-spacing: ${letterSpacing};
        text-transform: uppercase;
        transform: ${transform};
    }
    `;
  }
} 
`;

export const TitanIcon = styled.img`
  align-self: flex-end;
  object-fit: contain;
  width: 48px;
`;

export const CloseButton = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 48px;
  `}
`;
