import React, { useCallback, useEffect, useState } from 'react';
import { RetentionSubscriptionHandler } from '@/components/billing';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { billingActions } from '@/redux/modules/billing';
import { withI18n } from 'react-i18next';
import * as Styles from './RetentionSubscriptionPage.styles';
import { CancelOptions } from '@/components/billing/components/manageSubscription/SubscriptionCancelModal/SubscriptionCancelModal.types';
import useLocale from '@/hooks/useLocale';
import { setBreadCrumb } from '@/redux/actions/breadCrumbs';
import { preChatActions } from '@/redux/modules';
import { ALPHA_RETENTION_BENEFIT_PAGE, ALPHA_UPGRADE_BENEFIT_PAGE } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const RetentionSubscriptionPage = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [retentionTypeParam, setRetentionTypeParam] = useState('');
  const [currentItem, setCurrentItem] = useState({});
  const { loading } = useSelector(state => state.common);
  const { items } = useSelector(state => state.billing.subscriptions);
  const breadCrumb = useSelector(state => state.breadCrumbs.crumbs[0]);
  const { triage } = useSelector(state => state.preChat);
  const { retentionType, subscriptionId, subscriptionType } = useParams();
  const { routes } = useLocale();
  const { retentionSubscription, retentionRenew } = routes;
  const isRenewCanceling = `/${retentionType}` === retentionRenew;
  const enabledBenefitPage = useFeatureIsOn(ALPHA_RETENTION_BENEFIT_PAGE);
  const enabledUpgradeBenefitPage = useFeatureIsOn(ALPHA_UPGRADE_BENEFIT_PAGE);
  const { retentionInfos } = currentItem;

  const getSubscriptions = useCallback(() => {
    if (items.length) return;

    dispatch(billingActions.subscriptions.request());
  }, [items, dispatch]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const getCurrentSubscription = useCallback(() => {
    if (!items.length) return;

    const filteredItem = items.find(({ id, type }) => String(id) === subscriptionId && type === subscriptionType);
    setCurrentItem(() => filteredItem);
  }, [items, subscriptionId, subscriptionType]);

  useEffect(() => {
    getCurrentSubscription();
  }, [getCurrentSubscription]);

  const verifyURL = useCallback(() => {
    const retentionTypeReturn = `/${retentionType}`.toLowerCase();
    const isCancelImediate = retentionTypeReturn === retentionSubscription;
    const isCancelRenew = retentionTypeReturn === retentionRenew;
    const {
      manageSubscription, subscriptions, cancelRenewal, cancelSubscription,
    } = routes;
    const pathCancel = isCancelImediate ? cancelSubscription : cancelRenewal;

    if (retentionInfos && retentionInfos.isCloseToCancelledTime) {
      return history.push(`${manageSubscription}${pathCancel}/${subscriptionId}/${subscriptionType}`);
    }

    if (isCancelImediate) {
      return setRetentionTypeParam(CancelOptions.CANCEL_IMMEDIATE);
    }

    if (isCancelRenew) {
      return setRetentionTypeParam(CancelOptions.CANCEL_RENEWAL);
    }

    history.push(subscriptions);
  }, [history, retentionInfos, subscriptionId, subscriptionType, retentionType, retentionSubscription, retentionRenew, routes]);

  useEffect(() => {
    verifyURL();
  }, [verifyURL]);

  const changeBreadcrumbTitle = useCallback(() => {
    if (isRenewCanceling) {
      breadCrumb.name = t('title.cancelRenewal');
      dispatch(setBreadCrumb([breadCrumb]));
    }
  }, [dispatch, t, breadCrumb, isRenewCanceling]);

  useEffect(() => {
    changeBreadcrumbTitle();
  }, [changeBreadcrumbTitle, retentionRenew, retentionType]);

  const openPreChatCancelFlow = useCallback(() => {
    if (triage.subscriptionToCancel) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
    }
  }, [triage, dispatch]);

  return (
    <Styles.Wrapper data-testid="retention-subscription-page">
      <RetentionSubscriptionHandler
        currentItem={currentItem}
        loading={loading}
        enableBenefitPage={enabledBenefitPage}
        enabledUpgradeBenefitPage={enabledUpgradeBenefitPage}
        isRenewCanceling={isRenewCanceling}
        retentionType={retentionTypeParam}
        retentionTypeRouteParam={retentionType}
        subscriptionId={Number(subscriptionId)}
        subscriptionType={subscriptionType}
        openPreChatCancelFlow={openPreChatCancelFlow}
      />
    </Styles.Wrapper>
  );
};

export default withI18n()(RetentionSubscriptionPage);
