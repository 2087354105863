import React from 'react';
import { IconFeaturedStar } from '@/icons';
import * as Styles from './DnsWizardBannerHosting.styles';
import arrow from '@/media/icons/icon_arrow_right.svg';
import HalfHeadSnappy from '@/media/logo/HalfHeadSnappy/HalfHeadSnappy.svg';
import SnappyDefaultLogo from '@/media/logo/SnappyDefaultLogo/SnappyDefaultLogo.svg';
import { HOSTING_URL } from '@/config/urls/hostingUrls';
import { WHMCS_URL } from '@/config';

const DnsWizardBannerHosting = ({
  hasHostGatorPlan,
  testId = 'dns-wizard-banner-hosting',
  text,
  upgradePlanOrSeePlansFromBannerClick,
  newPlanFromBannerClick,
  upgradePlanFromBannerText,
  newPlanFromBannerText,
  planId,
}) => {
  const handleUpgradePlanFromBannerClick = () => {
    upgradePlanOrSeePlansFromBannerClick();
    hasHostGatorPlan ? window.open(
      `${WHMCS_URL}/index.php?m=custom_upgrade&action=selectupgrade&id=${planId}`,
      '_blank',
    ) : window.open(HOSTING_URL, '_blank');
  };

  const handleNewPlanFromBannerClick = () => {
    newPlanFromBannerClick();
    window.open(HOSTING_URL, '_blank');
  };


  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.ContentContainer>
        <Styles.InfoContainer>
          <Styles.Title>HostGator</Styles.Title>
          <Styles.FluidText>
            {text}
          </Styles.FluidText>
          <Styles.WrapButton>
            <Styles.ButtonOutline
              hasHostGatorPlan={hasHostGatorPlan}
              onClick={() => {
                handleUpgradePlanFromBannerClick();
              }}
            >
              {upgradePlanFromBannerText}
            </Styles.ButtonOutline>
            {hasHostGatorPlan && (
            <Styles.CTA
              data-testid="cta-skip-wizard"
              arrow={arrow}
              onClick={() => {
                handleNewPlanFromBannerClick();
              }}
            >
              {newPlanFromBannerText}
            </Styles.CTA>
            )}

          </Styles.WrapButton>
        </Styles.InfoContainer>
        <Styles.WrapImgLarge>
          <img src={SnappyDefaultLogo} alt="HostGator" />
        </Styles.WrapImgLarge>
      </Styles.ContentContainer>
      <Styles.WrapImgSmall>
        <img src={HalfHeadSnappy} alt="HostGator" />
      </Styles.WrapImgSmall>
      <Styles.WrapIconFeaturedStar large>
        <IconFeaturedStar testId="featured-star-icon" />
      </Styles.WrapIconFeaturedStar>
    </Styles.Wrapper>
  );
};

export default DnsWizardBannerHosting;
