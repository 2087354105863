import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 40px 0px 35px 0px;
    width: 100%;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 20px 0px 82px 0px;
    } 
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0 0 16px 0px;

    span {
      color: ${theme.v2.colors.brand.primaryMedium};
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
        font-size: ${theme.v2.font.sizes.sm};
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    margin: 0px 0px 32px 0px;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin: 0px 0px 24px 0px;
    }
  `}
`;

export const CardsWrapper = styled.div`
  ${({ shouldRenderEcommercePlusCard, theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: ${shouldRenderEcommercePlusCard ? '56px' : '20px'};
    justify-content: center;

    > div {
      width: 313px;
    }

    > span {
      color: ${theme.v2.colors.neutral.lowPure};
      font-weight: ${theme.v2.font.weights.medium};
    }

    @media (max-width: 1349px){
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }
  `}
`;
