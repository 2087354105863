const styles = theme => ({
  listItem: {
    borderRadius: '5px',
    boxShadow: '0 3px 6px #00000029',
    display: 'flex',
    flex: '1 calc(50% - 6px)',
    flexGrow: 0,
    flexShrink: 1,
    marginBottom: '12px',
    padding: '12px',

    [theme.breakpoints.down(960)]: {
      flex: '1 100%',
    },
  },

  imageBlock: {
    marginRight: '12px',

    [theme.breakpoints.up(960)]: {
      maxHeight: '157px',
    },
  },

  infoBlock: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    [theme.breakpoints.up(960)]: {
      maxHeight: '157px',
    },
  },

  itemName: {
    colro: theme.color.tundora,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
  },

  row: {
    alignItems: 'center',
    display: 'flex',
  },

  lessonIcons: {
    fill: theme.color.regentGray,
    fontSize: '12px',
    marginRight: '2px',
  },

  lessons: {
    color: theme.color.regentGray,
    fontSize: '14px',
    lineHeight: '17px',
    marginRight: '12px',
  },

  duration: {
    color: theme.color.regentGray,
    fontSize: '14px',
    lineHeight: '17px',
  },

  description: {
    color: theme.color.tundora,
    fontSize: '12px',
    lineHeight: '14px',
    marginBottom: '6px',
    marginTop: '12px',
  },

  totalPrice: {
    alignItems: 'center',
    borderRadius: 100,
    color: theme.color.tundora,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '25px',
    justifyContent: 'center',
    lineHeight: '17px',
    marginBottom: '6px',
    padding: '0 8px',
    position: 'relative',

    '&:before': {
      backgroundColor: theme.color.valencia,
      content: "''",
      display: 'block',
      height: '1px',
      position: 'absolute',
      transform: 'rotate(7deg)',
      width: 'calc(100% - 16px)',
    },
  },

  newPrice: {
    alignItems: 'center',
    backgroundColor: theme.color.oceanGreen,
    borderRadius: 100,
    color: theme.color.white,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '25px',
    justifyContent: 'center',
    lineHeight: '17px',
    marginBottom: '6px',
    padding: '0 8px',
  },

  ctaButton: {
    alignItems: 'center',
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '100px',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: 'auto',
    padding: '8px 0',
    textDecoration: 'none',
    transition: '500ms',

    '&:hover': {
      backgroundColor: theme.color.indigo,
      color: theme.color.white,
    },
  },
});

export default styles;
