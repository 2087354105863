import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    padding: 16px;
  `}
`;

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
  `}
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    margin-top: 24px;
    p {
      margin: 0;
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
