import React, { useCallback, useState } from 'react';
import * as Styles from './WithoutEmailAccess.styles';
import {
  Button,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';
import { FieldValidators } from '@/utils/FieldValidators';
import { isBRServer } from '@/utils/Validators/validation';

export const WithoutEmailAccess = ({
  email,
  document,
  opening,
  submitForm,
  ignoreFile = false,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { withoutEmailAccess: withoutEmailAccessLocale } = ticketsLocale.forms;
  const [contactEmail, setcontactEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [file, setFile] = useState('');
  const [fileBinary, setFileBinary] = useState();
  const [description, setDescription] = useState('');

  const handleValidateEmail = useCallback(() => {
    if (!FieldValidators.email(contactEmail)) {
      setEmailError(withoutEmailAccessLocale.emailError);
    } else {
      setEmailError('');
    }
  }, [contactEmail, withoutEmailAccessLocale]);

  const checkForm = useCallback(() => {
    let isOk = false;

    if (FieldValidators.email(contactEmail) && description) {
      if (!ignoreFile && file) {
        isOk = true;
      }

      if (ignoreFile) {
        isOk = true;
      }
    }

    return !isOk;
  }, [contactEmail, description, file, ignoreFile]);

  const handleFile = (e) => {
    setFileBinary(e.target.files[0]);
    setFile(e.currentTarget.value);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    handleValidateEmail();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: null,
      product: 'Não tenho acesso ao e-mail cadastrado',
      subject: ticketsLocale.forms.withoutEmailAccess.name,
      key: V2Forms.WITHOUT_EMAIL_ACCESS,
      tags: ['form_recuperacao_acesso'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.withoutEmailAccess.name,
      },
      files: [fileBinary],
      body: `
      ${withoutEmailAccessLocale.body.registeredEmail}: ${email}
      ${withoutEmailAccessLocale.body.document}: ${document}
      ${withoutEmailAccessLocale.body.contactEmail}: ${contactEmail}
      ${withoutEmailAccessLocale.body.description}: ${description}
      `,
    };

    if (!emailError) {
      submitForm && submitForm(data);
    }
  }, [
    ticketsLocale,
    emailError,
    handleValidateEmail,
    submitForm,
    contactEmail,
    description,
    email,
    document,
    fileBinary,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {withoutEmailAccessLocale.info}
      </Styles.Info>

      <Styles.Title>
        {withoutEmailAccessLocale.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Input
          name="email"
          testId="email"
          label={withoutEmailAccessLocale.emailLabel}
          value={email}
          readOnly
        />

        <Input
          name="document"
          testId="document"
          label={withoutEmailAccessLocale.documentLabel}
          value={document}
          mask={isBRServer ? 'document' : ''}
          readOnly
        />

        <Input
          name="contactEmail"
          testId="contactEmail"
          label={withoutEmailAccessLocale.contactEmailLabel}
          placeholder={withoutEmailAccessLocale.contactEmailPlaceholder}
          value={contactEmail}
          onChange={e => setcontactEmail(e.target.value)}
          onBlur={handleValidateEmail}
          errorMessage={emailError}
        />

        <Styles.FileDescription>
          {withoutEmailAccessLocale.addDocumentLabel}
        </Styles.FileDescription>

        <Styles.FileWrapper>
          <Styles.FileButton htmlFor="fileInput" file={!!file}>
            {(fileBinary && fileBinary.name) || withoutEmailAccessLocale.addDocumentButton}
          </Styles.FileButton>
          <Styles.FileInput
            type="file"
            id="fileInput"
            data-testid="fileInput"
            accept=".jpg,.jpeg,.png,.pdf"
            value={file}
            onChange={e => handleFile(e)}
          />
        </Styles.FileWrapper>

        <Input
          type="textarea"
          testId="description"
          placeholder=""
          label={withoutEmailAccessLocale.descriptionLabel}
          value={description}
          onChange={e => setDescription(e.target.value)}
          maxLength={280}
          rows={3}
          counter
        />
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default WithoutEmailAccess;
