
const styles = theme => ({
  text: {
    color: theme.color.indigo,
  },
  wrapper: {
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  card: {
    display: 'flex',
    flex: '0 0 255px',
    flexDirection: 'column',
    maxWidt: '255px',
    minWidth: '255px',
  },
  cardImage: {
    height: '120px',
    objectFit: 'cover',
    width: '100%',
  },
  cardType: {
    color: theme.color.white,
    fontSize: '12px',
    height: '23px',
    marginTop: '-23px',
    padding: '4px 8px',
    width: 'auto',
  },
  cardTypePass: {
    backgroundColor: theme.color.coral,
  },
  cardTypeFree: {
    backgroundColor: theme.color.oceanGreen,
  },
  imageWrapper: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },

  detailsWrapper: {
    margin: '16px',
    marginBottom: '24px',
    '& a': {
      textDecoration: 'none',
    },
  },
  courseTitle: {
    color: theme.color.black,
    font: 'normal normal bold 14px/18px Roboto',
    letterSpacing: '0px',
    maxHeight: '42px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '16px',
  },
  info: {
    alignItems: 'center',
    color: theme.color.tundora,
    display: 'flex',
    gap: '6px',
  },
  textProgressBar: {
    alignItems: 'center',
    color: theme.color.tundora,
    display: 'flex',
    font: 'normal normal bold 12px/18px Roboto',
    marginTop: '5px',
  },
  progressBar: {
    backgroundColor: theme.color.whiteSmoke,
    color: theme.color.indigo,
    display: 'none',
    height: '5px',
    marginTop: '16px',
  },
  newProgressBar: {
    backgroundColor: theme.color.whiteSmoke,
    color: theme.color.indigo,
    height: '5px',
    marginTop: '16px',
  },
  ratingStars: {
    color: theme.color.mustard,
    display: 'flex',
    gap: '6px',
    justifyContent: 'center',
    marginTop: '16px',
  },
  buyButton: {
    marginTop: '16px',
    width: '100%',
  },
  watchButton: {
    border: 0,
    color: theme.color.indigo,
    marginTop: '16px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.color.white,
      color: theme.color.chambray,
    },
  },
});

export default styles;
