import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  * {
    margin: 0px;
    padding: 0p;x
  }
`;

export const Title = styled.h6`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 24px auto 42px;
    width: fit-content;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 21px;
    margin: 40px auto 24px;
    width: fit-content;
  `}
`;
