import React from 'react';
import ReactDOM from 'react-dom';
import {
  withStyles,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import styles from './styles';


const PortalAddDNS = ({ toggleAddRecordForm, children, classes }) => ReactDOM.createPortal(
  <Dialog
    fullScreen
    open={toggleAddRecordForm}
    maxWidth="md"
  >
    <DialogContent className={classes.containerWrapper}>
      {children}
    </DialogContent>
  </Dialog>,
  document.body,
);


export default withStyles(styles)(PortalAddDNS);
