import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 40px 0px 0px 0px;
`;

export const Title = styled.h3`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: 0px auto 60px auto;
    max-width: 590px;
    text-align: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      max-width: 287px;
      margin-bottom: 32px;
    }
  `}
`;

export const FirstRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    @media (max-width: ${theme.v1.breakpoints.md}) {
      padding: 0px 16px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
      margin-bottom: 32px;
    }
  `}
`;

export const MainTaxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 15px 24px 0px 24px;
    position: relative;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin-top: 0px;
      order: 1;
    }
  `}
`;

export const PartnerShipWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 38px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const PartnerShip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    gap: 8px;
    padding: 8px;
  `}
`;

export const AsaasSymbol = styled.img`
`;

export const PartnerShipContent = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0;
    line-height: 19px;
  `}
`;

export const Price = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 60px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 81px;
    margin: 0;
    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 48px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 16px;
      text-align: center;
    }
  `}
`;

export const Mensality = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0;
    max-width: 220px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const Taxs = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.v1.color.line.divisor};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0px 8px 24px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      border: none;
      margin: 16px 0px 40px 0px;
      padding: 0;
    }
  `}
`;

export const TaxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      order: 2;
    }
  `}
`;

export const TaxContent = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 0;
    span {
      color: ${theme.v1.color.action.primary};
      font-weight: ${theme.v1.font.weights.bold};
    }
    a {
      color: ${theme.v1.color.text.primary};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const SecondRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 47px;
    justify-content: center;
    margin-bottom: 40px;
    margin-left: 24px;
    @media (max-width: ${theme.v1.breakpoints.md}) {
      padding: 0px 16px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
      margin-bottom: 48px;
      margin-left: 0px;
    }
  `}
`;

export const SecondRowContent = styled.div`
${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    max-width: 568px;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      margin-top:0px;
      max-width: 440px;
    }
  `}
`;

export const RowImage = styled.img`
${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      width: 300px;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      width: 100%;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 288px;
    }
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      order: 3;
    }
  `}
`;


export const SecondRowTitle = styled.h5`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 32px;
    margin: 0px;
    max-width: 513px;
    text-align: left;
    span {
      color: ${theme.v1.color.action.primary};
      font-weight: ${theme.v1.font.weights.bold};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      text-align: center;
      margin: 0 auto;
      max-width: 288px;
    }
  `}
`;

export const SecondRowDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 22px;
    margin: 24px 0px 32px 0px;
    text-align: left;
    max-width: 435px;
    span {
      font-weight: ${theme.v1.font.weights.bold};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      font-size: 14px;
      margin-bottom: 40px;
      margin-top: 16px;
      max-width: 288px;
      text-align: center;
    }
  `}
`;

export const SecondRowButtonWrapper = styled.div`
${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      display:flex;
      justify-content: center;
    }
  `}
`;
