import { Button } from '@/pages/common/v1';
import React from 'react';
import * as Styles from './AsaasSitesAdvertisement.styles';
import asaasDeco from '@/media/asaas/asaas-deco.png';
import asaasLogo from '@/media/asaas/asaas-logo.svg';

function AsaasSitesAdvertisement({
  testId = 'asaas-bottom-banner',
  knowMore = 'Know More',
  description = 'Description',
  title = 'Title Tag',
  newTag = 'New',
  handleKnowMoreClick = () => {},
  wizardVariant = false,
}) {
  const handleClick = () => {
    handleKnowMoreClick && handleKnowMoreClick();
  };

  return (
    <Styles.AsaasSitesAdvertisementWrapper wizardVariant={wizardVariant} data-testid={`${testId}-wrapper`}>
      <Styles.HeaderWrapper wizardVariant={wizardVariant}>
        <Styles.HeaderDeco wizardVariant={wizardVariant} src={asaasDeco} />
        {!wizardVariant && (
        <Styles.Chip data-testid={`${testId}-chip`}>
          {newTag}
        </Styles.Chip>
        )}
        <Styles.HeaderContentWrapper>
          {wizardVariant && (
          <Styles.Chip data-testid={`${testId}-chip`}>
            {newTag}
          </Styles.Chip>
          )}
          <Styles.Title wizardVariant={wizardVariant} data-testid={`${testId}-title`}>{title}</Styles.Title>
        </Styles.HeaderContentWrapper>
        <Styles.Description wizardVariant={wizardVariant} data-testid={`${testId}-description`}>{description}</Styles.Description>
      </Styles.HeaderWrapper>
      <Styles.FooterWrapper wizardVariant={wizardVariant}>
        <Button variant="secondary" label={knowMore} onClick={handleClick} size="regular" testId={`${testId}-button`} />
        <Styles.DecoTriangle />
        <Styles.AsaasLogo src={asaasLogo} />
      </Styles.FooterWrapper>
    </Styles.AsaasSitesAdvertisementWrapper>
  );
}

export default AsaasSitesAdvertisement;
