import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({
    theme,
    variant,
    size,
    customStyles,
  }) => {
    const sizes = {
      regular: {
        borderWidth: customStyles && customStyles.borderWidth ? customStyles.borderWidth : '2px',
        fontSize: '16px',
        lineHeight: '22px',
        padding: '7px 13px',
        weight: theme.v1.font.weights.medium,
      },
      small: {
        borderWidth: customStyles && customStyles.borderWidth ? customStyles.borderWidth : '1px',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '6px 16px',
        weight: theme.v1.font.weights.regular,
      },
    };

    const variants = {
      text: {
        backColor: 'transparent',
        color: theme.v1.color.action.primary,
        disabledBackColor: '',
        disabledBorderColor: '',
        disabledColor: theme.v1.color.action.disabled,
        hoverBackColor: '',
        hoverBorderColor: '',
        hoverColor: '',
      },
    };

    return css`
      background-color:${(customStyles && customStyles.backColor) ? customStyles.backColor : variants[variant].backColor};
      border-style: none;
      color:${(customStyles && customStyles.color) ? customStyles.color : variants[variant].color};
      cursor: pointer;
      font-family: ${theme.v1.font.family.primary};
      font-size: ${(customStyles && customStyles.fontSize) ? customStyles.fontSize : sizes[size].fontSize};
      font-weight:${(customStyles && customStyles.weight) ? customStyles.weight : sizes[size].weight};
      letter-spacing: 0.01px;
      line-height: ${(customStyles && customStyles.lineHeight) ? customStyles.lineHeight : sizes[size].lineHeight};
      padding: ${(customStyles && customStyles.padding) ? customStyles.padding : sizes[size].padding};
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out, color 150ms ease-in-out;

      &:hover{
        background-color:${(customStyles && customStyles.hoverBackColor) ? customStyles.hoverBackColor : variants[variant].hoverBackColor};
        color:${(customStyles && customStyles.hoverColor) ? customStyles.hoverColor : variants[variant].hoverColor};
      }

      &:disabled{
        background-color:${(customStyles && customStyles.disabledBackColor) ? customStyles.disabledBackColor : variants[variant].disabledBackColor};
        color:${(customStyles && customStyles.disabledColor) ? customStyles.disabledColor : variants[variant].disabledColor};
      }
    `;
  }
}
`;


export const Label = styled.span``;
