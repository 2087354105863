import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';


const TicketButtonLoader = ({
  classes,
  fullscreen,
  size,
  classesToProps,
  ...res
}) => (
  <div {...res} className={classNames(fullscreen ? classes.fullscreen : classes.loader, classesToProps)}>
    <CircularProgress size={20} className={classNames(classes.progress, fullscreen && classes.progressFull)} />
  </div>
);

TicketButtonLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  classesToProps: PropTypes.string,
  fullscreen: PropTypes.bool,
};

export default withStyles(styles)(TicketButtonLoader);
