import React from 'react';
import * as Style from './IconDetailedWordpress.style';

const IconDetailedWordpress = ({
  color = '#4a4a4a',
  backgroundColor = '#3c97ff',
  size = '50',
  testId = 'icon-detailed-wordpress',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg id="icon-wordpress2" xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 50 48.396">
      <g id="icon_wordpress01" data-name="icon wordpress01">
        <g id="Elipse_1041" data-name="Elipse 1041" transform="translate(0 17.349)" opacity="0.83">
          <circle id="Elipse_1089" data-name="Elipse 1089" cx="15.524" cy="15.524" r="15.524" fill={backgroundColor} />
        </g>
        <g id="Grupo_31414" data-name="Grupo 31414" transform="translate(3.629)">
          <g id="wordpress-logo">
            <g id="WordPress" transform="translate(0 0.101)">
              <path id="Caminho_238831" data-name="Caminho 238831" d="M1033,197.292a23.186,23.186,0,1,0,23.185-23.079h0A23.131,23.131,0,0,0,1033,197.29Zm1.783,0a21.187,21.187,0,0,1,1.853-8.664l10.207,27.833A21.286,21.286,0,0,1,1034.783,197.292Zm21.4,21.3a21.542,21.542,0,0,1-6.046-.865l6.422-18.566,6.578,17.933a1.929,1.929,0,0,0,.151.29A21.381,21.381,0,0,1,1056.185,218.593Zm17.294-21.7a20.034,20.034,0,0,0,1.63-7.629,15.655,15.655,0,0,0-.148-2.192,21.253,21.253,0,0,1-8.016,28.63Zm-4.614-6.55a11.2,11.2,0,0,1,1.766,5.875,19.823,19.823,0,0,1-1.629,6.89l-2.135,7.1-7.734-22.9c1.291-.07,2.448-.2,2.448-.2a.881.881,0,0,0-.134-1.757s-3.467.272-5.7.272c-2.1,0-5.635-.272-5.635-.272a.881.881,0,1,0-.137,1.757s1.094.134,2.247.2l3.331,9.092-4.681,13.978-7.794-23.07c1.291-.07,2.451-.2,2.451-.2a.881.881,0,1,0-.137-1.757s-3.461.272-5.7.272c-.4,0-.872-.01-1.375-.025a21.44,21.44,0,0,1,32.327-4.006c-.091-.007-.183-.018-.275-.018a3.692,3.692,0,0,0-3.595,3.779C1066.768,187.1,1067.782,188.589,1068.865,190.343Z" transform="translate(-1033 -174.213)" />
            </g>
            <g id="Elipse_929" data-name="Elipse 929">
              <g id="Grupo_36301" data-name="Grupo 36301" transform="translate(0.371)">
                <ellipse id="Elipse_1090" data-name="Elipse 1090" cx="22.804" cy="23.198" rx="22.804" ry="23.198" fill="none" />
              </g>
              <g id="Grupo_36302" data-name="Grupo 36302" transform="translate(0 0.101)">
                <path id="Caminho_238832" data-name="Caminho 238832" d="M1056.087,220.176a22.982,22.982,0,1,1,23.087-22.981A23.06,23.06,0,0,1,1056.087,220.176Zm0-43.874a20.892,20.892,0,1,0,20.988,20.892A20.964,20.964,0,0,0,1056.087,176.3Z" transform="translate(-1033 -174.213)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedWordpress;
