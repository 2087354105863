import { GrowthBook } from '@growthbook/growthbook-react';
import { GROWTHBOOK_CLIENT_KEY, IS_PROD } from '@/config';

const Growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !IS_PROD,
});

export default Growthbook;
