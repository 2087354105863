export const SUBSCRIPTIONS_STATUS = {
  ACTIVE: 'active',
  FRAUD: 'fraud',
  EXPIRED: 'expired',
};

export const SUBSCRIPTIONS_SUBSTATUS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  OVERDUE: 'overdue',
  PENDING_ANALYSIS: 'pendingAnalysis',
  PENDING_PAYMENT: 'pendingPayment',
  PENDING: 'pending',
  REGISTERED: 'registered',
  SUSPENDED: 'suspended',
  TO_DUE: 'toDue',
  TRANSFERRED_AWAY: 'transferredAway',
};

export const PRODUCT_NAMES = {
  ECOMMERCE_PLUS: 'E-commerce Plus',
};

export const SUBSCRIPTIONS_CANCEL_TYPE = {
  USER_REQUEST: 'user_request',
  WHMCS_SUSPENSION: 'whmcs_suspension',
};

export const SUBSCRIPTIONS_CATEGORY = {
  DOMAIN: 'domain',
  COURSE: 'course',
  OTHERS: 'others',
  SECURITY: 'security',
  EMAIL: 'email',
  HOSTING: 'hosting',
};

export const CANCEL_SUBSCRIPTION_STEPS = {
  SURVEY: 1,
  REFUND: 2,
  REVIEW: 3,
  CONFIRMATION: 4,
  DONE: 5,
  NEXT_RENEW_OFFER: 99,
};

export const REFUND_PAYMENT_METHODS = {
  HG_CREDITS: 'hg_credits',
  BRASPAG_CREDIT_1X: 'braspag_credit_1x',
  DLOCAL: 'dlocal',
  PIX: 'gp_pix',
  GP_PAYPAL: 'gp_paypal',
  PAYPAL: 'paypal',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active/active',
  CANCELLED: 'cancelled/cancelled',
  EXPIRED_CANCELLED: 'expired/cancelled',
  EXPIRED: 'expired/expired',
  FRAUD_CANCELLED: 'fraud/cancelled',
  TERMINATED_CANCELLED: 'terminated/cancelled',
  OVERDUE: 'active/overdue',
  PENDING_ANALYSIS: 'pending/pendinganalysis',
  PENDING_PAYMENT: 'pending/pendingpayment',
  PENDING: 'pending/pending',
  REGISTERED: 'active/registered',
  SUSPENDED: 'suspended/suspended',
  TO_DUE: 'active/todue',
  TRANSFERRED_AWAY: 'transferredaway/transferredaway',
  TRANSFERRED_AWAY_OLD: 'transferred away/transferredaway',
  INVOICE_CANCELLED: 'active/cancelled',
};

export const CHANGE_PLAN_CYCLE_PRODUCTS = {
  BR: [
    'Plano P',
    'Plano M',
    'Plano TURBO',
    'Revenda',
  ],
  ES: [
    'Plan Personal',
    'Plan Emprendedor',
    'Plan Negocios',
    'Plan Turbo',
    'Reseller',
  ],
};

export const PAGE_RETENTION_VERSIONS = {
  OLD: 'old_version',
  NEW: 'new_version',
};
