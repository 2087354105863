import styled, { css } from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 80px);
  padding: 40px;
  position: relative;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-height: fit-content;
    padding: 40px;
    position: relative;
    width: 100%;

    > div {
      width: 100%;
    }
  `}
`;

export const CloseButtonWrapper = styled.div`
  display: block;
  height: 38px;
  height: fit-content: !important;
  position: absolute;
  right: 38px;
  width: fit-content !important;
`;

export const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 19px;
  justify-content: center;
`;
