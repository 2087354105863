import { Button } from '@/pages/common/Button';
import React from 'react';
import * as Styles from './TitanUpgradeBottomAdvertisement.styles';
import { Chip } from '@/pages/common/Chip';

function TitanUpgradeBottomAdvertisement({
  variant = 'dark',
  shouldRender = true,
  testId = 'titan-upgrade-bottom-banner',
  knowMore = 'Know More',
  description = 'Description',
  title = 'Title Tag',
  newTag = 'New',
  handleKnowMoreClick = () => {},
}) {
  return (
    shouldRender && (
    <Styles.TitanUpgradeBottomAdvertisementWrapper variant={variant} data-testid={`${testId}-${variant}`}>
      <Styles.HeaderWrapper variant={variant}>
        <Chip label={newTag.toUpperCase()} recommended bold />
        <Styles.Title>{title}</Styles.Title>
        <Styles.Description>{description}</Styles.Description>
      </Styles.HeaderWrapper>
      <Styles.FooterWrapper>
        <Button variant={variant === 'dark' ? 'darkBorderedPill' : 'lightBorderedPill'} label={knowMore} onClick={handleKnowMoreClick} size="large" testId={`${testId}-open-modal`} />
      </Styles.FooterWrapper>
    </Styles.TitanUpgradeBottomAdvertisementWrapper>
    )
  );
}

export default TitanUpgradeBottomAdvertisement;
