import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generateRecaptchaToken } from '@/utils/Application/reCaptcha';


const isReady = () => typeof window !== 'undefined'
  && typeof window.grecaptcha !== 'undefined'
  && typeof window.grecaptcha.execute !== 'undefined';


class ReCaptcha extends Component {
  constructor(props) {
    super(props);

    this.execute = this.execute.bind(this);

    this.state = {
      ready: isReady(),
      readyCheck: '',
    };

    const { ready } = this.state;
    if (!ready) {
      const readyCheck = setInterval(this.updateReadyState.bind(this), 1000);
      this.setState({
        readyCheck,
      });
    }
  }

  componentDidMount() {
    const { ready } = this.state;
    if (ready) {
      this.execute();
    }
  }

  componentDidUpdate(_, prevState) {
    const { ready } = this.state;
    if (ready && !prevState.ready) {
      this.execute();
    }
  }

  componentWillUnmount() {
    this.handleClearInterval();
  }

  handleClearInterval() {
    const { readyCheck } = this.state;
    clearInterval(readyCheck);
    this.setState({
      readyCheck: '',
    });
  }

  execute() {
    const {
      sitekey,
      verifyCallback,
      action,
    } = this.props;

    const { ready } = this.state;
    if (ready) {
      generateRecaptchaToken(sitekey, action).then((token) => {
        if (typeof verifyCallback !== 'undefined') {
          verifyCallback(token);
        }
      });
    }
  }

  updateReadyState() {
    if (isReady()) {
      this.setState(() => ({ ready: true }));

      this.handleClearInterval();
    }
  }

  render() {
    const { ready } = this.state;
    const { elementID, verifyCallbackName } = this.props;

    return ready ? (
      <div
        id={elementID}
        data-verifycallbackname={verifyCallbackName}
      />
    ) : (
      <div id={elementID} className="g-recaptcha" />
    );
  }
}


ReCaptcha.propTypes = {
  elementID: PropTypes.string,
  verifyCallbackName: PropTypes.string,
  verifyCallback: PropTypes.func,
  sitekey: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};


export default ReCaptcha;
