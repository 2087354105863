import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { asaasEnum } from '@/pages/asaas/AsaasHandler/AsaasHandler.enum';
import useLocale from '@/hooks/useLocale';
import { applyBRMoneyMask, applyCepMask, applyDocumentMask } from '@/utils/Masks/inputMasks';
import { asaasAnalytics } from '@/analytics/asaas';
import { useDispatch } from 'react-redux';
import { validateCep } from '@/utils/Validators/validation';
import { loadZipCode } from '@/redux/actions/summary';
import validators from '@/utils/FieldValidators/FieldValidators';
import { asaasActions } from '@/redux/modules';
import { sitesBuilderAnalytics } from '@/analytics';

const useAsaasRegisterForm = ({
  summary,
  location = 'lp',
  setAsaasReachedThridStep,
  accountCreated,
  getSelectedSiteTypeGaValue,
  toolSelected,
  userSawEcommercePlusOffer,
}) => {
  const { asaas: asaasLocale } = useLocale();
  const dispatch = useDispatch();

  const {
    sitesBuilderTools: wizardToolsAnalytics,
  } = sitesBuilderAnalytics.newGa;

  const callFormStepOneLogin = () => {
    asaasAnalytics.formStepOneLogin();
  };

  const stepperDefaultState = useMemo(() => [
    {
      id: 0,
      status: 'finished',
    },
    {
      id: 1,
      status: 'pending',
    },
    {
      id: 2,
      status: 'pending',
    },
  ], []);

  const defaultFormState = useMemo(() => ({
    activeStep: asaasEnum.formStepper.OPEN_ACCOUNT,
    stepItems: stepperDefaultState,
    fields: {
      name: {
        value: `${summary.firstname} ${summary.lastname}`,
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderName,
        error: false,
      },
      email: {
        value: summary.email,
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderMail,
        error: false,
      },
      documentType: {
        value: summary.documentType,
        error: false,
      },
      monthlyIncome: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderMonthlyIncome,
        error: false,
      },
      documentValue: {
        value: applyDocumentMask(summary.document),
        placeholder: summary.documentType,
        error: false,
      },
      birthday: {
        value: summary.birthDate,
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderBirthday,
        error: false,
      },
      companyType: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderCompanyType,
        error: false,
      },
      cep: {
        value: summary.postcode,
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderCep,
        error: false,
      },
      city: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderCity,
        error: false,
        readonly: true,
      },
      street: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderStreet,
        error: false,
        readonly: true,
      },
      district: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderDistrict,
        error: false,
        readonly: true,
      },
      number: {
        value: '',
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderNumber,
        error: false,
      },
      complement: {
        value: summary.address3,
        placeholder: asaasLocale[location].firstRow.form.texts.placeholderComplement,
        error: false,
      },
      checkboxConfirmation: {
        value: false,
        radioLabel: 'Declaro que li e concordo com os ',
        linkLabel: 'Termos de uso do Asaas.',
        error: false,
      },
    },
    texts: {
      [asaasEnum.formStepper.OPEN_ACCOUNT]: {
        title: asaasLocale[location].firstRow.form.texts.titleFirstStep,
        description: asaasLocale[location].firstRow.form.texts.descriptionFirstStep(),
        questionDocument: asaasLocale[location].firstRow.form.texts.questionDocument,
        document: {
          cpf: asaasLocale[location].firstRow.form.texts.labelCPF,
          cnpj: asaasLocale[location].firstRow.form.texts.labelCNPJ,
        },
        continueButtonLabel: asaasLocale[location].firstRow.form.texts.buttonLabel,
        accessAsaas: asaasLocale[location].firstRow.form.texts.acceessAsaas(callFormStepOneLogin),
      },
      [asaasEnum.formStepper.MAIN_DATA]: {
        title: asaasLocale[location].firstRow.form.texts.titleSecondStep,
        description: asaasLocale[location].firstRow.form.texts.descriptionSecondStep,
        continueButtonLabel: asaasLocale[location].firstRow.form.texts.buttonLabel,
        myCepUrl: asaasLocale[location].firstRow.form.texts.myCepUrl,
        myCepLabel: asaasLocale[location].firstRow.form.texts.myCepLabel,
        backButtonLabel: asaasLocale[location].firstRow.form.texts.backButtonLabel,
        agreementRadioLabel: asaasLocale[location].firstRow.form.texts.agreementRadioLabel,
        agreementLinkLabel: asaasLocale[location].firstRow.form.texts.agreementLinkLabel,
        agreementUrl: asaasLocale[location].firstRow.form.texts.agreementUrl,
      },
      [asaasEnum.formStepper.FORM_CONCLUSION]: {
        successTitle: asaasLocale[location].firstRow.form.texts.successTitle,
        successDescription: asaasLocale[location].firstRow.form.texts.successDescription(),
        successButtonLabel: asaasLocale[location].firstRow.form.texts.successButtonLabel,
        successButtonUrl: asaasLocale[location].firstRow.form.texts.successButtonUrl,
        failedTitle: asaasLocale[location].firstRow.form.texts.failedTitle,
        failedDescription: asaasLocale[location].firstRow.form.texts.failedDescription(),
        failedButtonBackLabel: asaasLocale[location].firstRow.form.texts.failedButtonBackLabel,
        failedButtonLoginLabel: asaasLocale[location].firstRow.form.texts.failedButtonLoginLabel,
        articlesTitle: asaasLocale[location].firstRow.form.texts.articlesTitle,
        articlesTitleHelp: asaasLocale[location].firstRow.form.texts.articlesTitleHelp,
        articles: [
          {
            label: asaasLocale[location].firstRow.form.texts.articles[0].label,
            link: asaasLocale[location].firstRow.form.texts.articles[0].link,
          },
          {
            label: asaasLocale[location].firstRow.form.texts.articles[1].label,
            link: asaasLocale[location].firstRow.form.texts.articles[1].link,
          },
          {
            label: asaasLocale[location].firstRow.form.texts.articles[2].label,
            link: asaasLocale[location].firstRow.form.texts.articles[2].link,
          },
          {
            label: asaasLocale[location].firstRow.form.texts.articles[3].label,
            link: asaasLocale[location].firstRow.form.texts.articles[3].link,
          },
          {
            label: asaasLocale[location].firstRow.form.texts.articles[4].label,
            link: asaasLocale[location].firstRow.form.texts.articles[4].link,
          },
        ],
        showMoreArticles: asaasLocale[location].firstRow.form.texts.showMoreArticles,
        showMoreArticlesURL: asaasLocale[location].firstRow.form.texts.showMoreArticlesURL,
      },
    },
  }), [summary, asaasLocale, stepperDefaultState, location]);

  const [formData, setFormData] = useState(defaultFormState);
  const [loadingCep, setLoadingCep] = useState(false);
  const [changedCepData, setChangedCepData] = useState(false);

  const resetForm = useCallback(() => {
    if (location === 'lp') {
      asaasAnalytics.formStepThreeRemake();
    }

    if (location === 'wizard') {
      wizardToolsAnalytics.remakeAsaasForm(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
    }

    const newFormData = {
      ...formData,
      activeStep: asaasEnum.formStepper.OPEN_ACCOUNT,
      stepItems: stepperDefaultState,
    };
    setFormData(newFormData);
  }, [formData, stepperDefaultState, location, wizardToolsAnalytics, getSelectedSiteTypeGaValue, toolSelected, userSawEcommercePlusOffer]);

  const getCep = useCallback(async (value) => {
    if (formData.activeStep === asaasEnum.formStepper.MAIN_DATA && changedCepData) {
      setChangedCepData(false);
      const sanitizedCep = applyCepMask(formData.fields.cep.value);
      const validCep = validateCep(sanitizedCep);
      if (validCep) {
        setLoadingCep(true);
        await dispatch(loadZipCode(value)).then((response) => {
          const { data } = response;

          if (data) {
            setFormData({
              ...formData,
              activeStep: asaasEnum.formStepper.MAIN_DATA,
              stepItems: [
                {
                  id: 0,
                  status: 'finished',
                },
                {
                  id: 1,
                  status: 'finished',
                },
                {
                  id: 2,
                  status: 'pending',
                },
              ],
              fields: {
                ...formData.fields,
                city: {
                  ...formData.fields.city,
                  value: data.city,
                  error: false,
                  readonly: data.city !== '',
                },
                street: {
                  ...formData.fields.street,
                  value: data.address1,
                  error: false,
                  readonly: data.address1 !== '',
                },
                district: {
                  ...formData.fields.district,
                  value: data.address2,
                  error: false,
                  readonly: data.address2 !== '',
                },
              },
            });
          }

          if (!data) {
            setFormData({
              ...formData,
              activeStep: asaasEnum.formStepper.MAIN_DATA,
              stepItems: [
                {
                  id: 0,
                  status: 'finished',
                },
                {
                  id: 1,
                  status: 'finished',
                },
                {
                  id: 2,
                  status: 'pending',
                },
              ],
              fields: {
                ...formData.fields,
                city: {
                  ...formData.fields.city,
                  value: '',
                  error: false,
                  readonly: true,
                },
                street: {
                  ...formData.fields.street,
                  value: '',
                  error: false,
                  readonly: true,
                },
                district: {
                  ...formData.fields.district,
                  value: '',
                  error: false,
                  readonly: true,
                },
                number: {
                  ...formData.fields.number,
                  value: '',
                  error: false,
                },
                complement: {
                  ...formData.fields.complement,
                  value: '',
                  error: false,
                },
                cep: {
                  ...formData.fields.cep,
                  error: asaasLocale[location].firstRow.form.error.cepNotFound,
                },
              },
            });
          }
          setLoadingCep(false);
        });
      }
    }
  }, [dispatch, formData, changedCepData, asaasLocale, location]);

  const mountErrorMessage = value => (value.length ? asaasLocale[location].firstRow.form.error.invalidData : asaasLocale[location].firstRow.form.error.emptyData);

  const validateField = ({ value, name }) => {
    const documentIsCPF = formData.fields.documentType.value === asaasEnum.documentType.CPF;

    const rules = {
      birthday: value.length === 10,
      cep: validateCep(value),
      city: value.length > 0,
      companyType: value.length > 0,
      complement: value.length > 0,
      district: value.length > 0,
      documentType: true,
      documentValue: documentIsCPF ? value.length === asaasEnum.documentLength.CPF : value.length === asaasEnum.documentLength.CNPJ,
      monthlyIncome: value.length > 0,
      email: validators.email(value),
      name: value.length > 0,
      number: value.length > 0,
      street: value.length > 0,
    };

    const isValid = rules[name];

    if (isValid) {
      return '';
    }

    return mountErrorMessage(value);
  };

  const validateAllFieldsFormOpenAccount = () => {
    let newFormData = { ...formData };
    let hasErrors = false;
    const {
      name,
      email,
      documentType,
      documentValue,
      birthday,
      companyType,
      monthlyIncome,
    } = newFormData.fields;
    const documentIsCPF = documentType.value === asaasEnum.documentType.CPF;

    if (!name.value.length) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          name: {
            ...newFormData.fields.name,
            error: mountErrorMessage(name.value),
          },
        },
      };
    }

    if (!validators.email(email.value)) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          email: {
            ...newFormData.fields.email,
            error: mountErrorMessage(email.value),
          },
        },
      };
    }

    if (documentIsCPF && documentValue.value.length < asaasEnum.documentLength.CPF) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          documentValue: {
            ...newFormData.fields.documentValue,
            error: mountErrorMessage(documentValue.value),
          },
        },
      };
    }

    if (!documentIsCPF && documentValue.value.length < asaasEnum.documentLength.CNPJ) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          documentValue: {
            ...newFormData.fields.documentValue,
            error: mountErrorMessage(documentValue.value),
          },
        },
      };
    }

    if (documentIsCPF && birthday.value.length < 10) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          birthday: {
            ...newFormData.fields.birthday,
            error: mountErrorMessage(birthday.value),
          },
        },
      };
    }

    if (!documentIsCPF && companyType.value.length === 0) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          companyType: {
            ...newFormData.fields.companyType,
            error: mountErrorMessage(companyType.value),
          },
        },
      };
    }

    if (!monthlyIncome.value) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          monthlyIncome: {
            ...newFormData.fields.monthlyIncome,
            error: mountErrorMessage(monthlyIncome.value),
          },
        },
      };
    }

    setFormData(newFormData);
    return hasErrors;
  };

  const validateAllFieldsFormMainData = () => {
    let newFormData = { ...formData };
    let hasErrors = false;
    const {
      cep,
      city,
      street,
      district,
      number,
    } = newFormData.fields;

    if (cep.value.length < 9) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          cep: {
            ...newFormData.fields.cep,
            error: mountErrorMessage(cep.value),
          },
        },
      };
    }

    if (!city.value.length) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          city: {
            ...newFormData.fields.city,
            error: mountErrorMessage(city.value),
          },
        },
      };
    }

    if (!street.value.length) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          street: {
            ...newFormData.fields.street,
            error: mountErrorMessage(street.value),
          },
        },
      };
    }

    if (!district.value.length) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          district: {
            ...newFormData.fields.district,
            error: mountErrorMessage(district.value),
          },
        },
      };
    }

    if (!number.value.length) {
      hasErrors = true;
      newFormData = {
        ...newFormData,
        fields: {
          ...newFormData.fields,
          number: {
            ...newFormData.fields.number,
            error: mountErrorMessage(number.value),
          },
        },
      };
    }

    setFormData(newFormData);
    return hasErrors;
  };

  const onChange = ({ value, name }) => {
    let sanitizedValue = value;

    if (name === 'documentValue') {
      sanitizedValue = applyDocumentMask(value);
    }

    if (name === 'documentType' && value === 'CPF') {
      asaasAnalytics.formStepOneCPF();
    }

    if (name === 'documentType' && value === 'CNPJ') {
      asaasAnalytics.formStepOneCNPJ();
    }

    if (name === 'companyTime' && value === asaasLocale[location].firstRow.form.texts.cnjpTypes.mei.value) {
      asaasAnalytics.formStepOneMEI();
    }

    if (name === 'companyTime' && value === asaasLocale[location].firstRow.form.texts.cnjpTypes.limited.value) {
      asaasAnalytics.formStepOneLimited();
    }

    if (name === 'companyTime' && value === asaasLocale[location].firstRow.form.texts.cnjpTypes.individual.value) {
      asaasAnalytics.formStepOneIndividual();
    }

    if (name === 'companyTime' && value === asaasLocale[location].firstRow.form.texts.cnjpTypes.association.value) {
      asaasAnalytics.formStepOneAssociation();
    }

    if (name === 'checkboxConfirmation' && value) {
      asaasAnalytics.formStepTwoUseTerm();
    }

    if (name === 'cep') {
      setChangedCepData(true);
    }

    if (name === 'monthlyIncome') {
      sanitizedValue = applyBRMoneyMask(value);
    }

    let fields = null;

    if (name === 'documentType') {
      fields = {
        ...formData.fields,
        [name]: {
          ...formData.fields[name],
          value: sanitizedValue,
          error: validateField({ value, name }),
        },
        documentValue: {
          ...formData.fields.documentValue,
          value: '',
          error: true,
        },
        companyType: {
          ...formData.fields.companyType,
          value: '',
          error: true,
        },
      };
    }

    if (name !== 'documentType') {
      fields = {
        ...formData.fields,
        [name]: {
          ...formData.fields[name],
          value: sanitizedValue,
          error: validateField({ value, name }),
        },
      };
    }

    const newData = {
      ...formData,
      fields,
    };

    setFormData(newData);

    return newData;
  };

  const onContinue = (step) => {
    let hasErrors = false;
    let newData = null;

    switch (step) {
      case asaasEnum.formStepper.OPEN_ACCOUNT:
        hasErrors = validateAllFieldsFormOpenAccount();
        if (!hasErrors) {
          if (location === 'lp') {
            asaasAnalytics.formStepOneContinue();
          }

          if (location === 'wizard') {
            wizardToolsAnalytics.asaasFormNextToStep2(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
          }

          setChangedCepData(true);

          newData = {
            ...formData,
            stepItems: [
              {
                id: 0,
                status: 'finished',
              },
              {
                id: 1,
                status: 'finished',
              },
              {
                id: 2,
                status: 'pending',
              },
            ],
            activeStep: asaasEnum.formStepper.MAIN_DATA,
          };

          setFormData(newData);
        }
        break;
      case asaasEnum.formStepper.MAIN_DATA:
        hasErrors = validateAllFieldsFormMainData();
        if (!hasErrors) {
          if (location === 'lp') {
            asaasAnalytics.formStepTwoContinue();
          }

          if (location === 'wizard') {
            wizardToolsAnalytics.asaasFormNextToStep3(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
          }

          newData = {
            ...formData,
            stepItems: [
              {
                id: 0,
                status: 'finished',
              },
              {
                id: 1,
                status: 'finished',
              },
              {
                id: 2,
                status: 'pending',
              },
            ],
            activeStep: asaasEnum.formStepper.MAIN_DATA,
          };

          setFormData(newData);
        }
        break;
      default:
    }

    return newData;
  };

  const enabledFormOpenAccountContinue = () => {
    const {
      name,
      email,
      documentType,
      documentValue,
      birthday,
      companyType,
    } = formData.fields;

    const documentIsCPF = documentType.value === asaasEnum.documentType.CPF;
    const birthdayIsValid = !birthday.error && birthday.value.length > 0;
    const companyTypeIsValid = !companyType.error && companyType.value.length > 0;

    const canContinue = !name.error
      && name.value.length > 0
      && !email.error
      && email.value && email.value.length > 0
      && !documentType.error
      && documentType.value && documentType.value.length > 0
      && !documentValue.error
      && documentValue.value && documentValue.value.length > 0;

    return documentIsCPF
      ? (canContinue && birthdayIsValid)
      : (canContinue && companyTypeIsValid);
  };

  const enabledMainDataContinue = () => {
    const {
      cep,
      city,
      street,
      district,
      number,
      checkboxConfirmation,
    } = formData.fields;

    const canContinue = !cep.error
      && cep.value.length > 0
      && cep.value !== '...'
      && !city.error
      && city.value.length > 0
      && city.value !== '...'
      && !street.error
      && street.value.length > 0
      && street.value !== '...'
      && !district.error
      && district.value.length > 0
      && district.value !== '...'
      && !number.error
      && number.value.length > 0
      && number.value !== '...'
      && checkboxConfirmation.value;

    return canContinue;
  };

  const onGoback = (step) => {
    let newData = null;

    switch (step) {
      case asaasEnum.formStepper.MAIN_DATA:
        newData = {
          ...formData,
          stepItems: [
            {
              id: 0,
              status: 'finished',
            },
            {
              id: 1,
              status: 'pending',
            },
            {
              id: 2,
              status: 'pending',
            },
          ],
          activeStep: asaasEnum.formStepper.OPEN_ACCOUNT,
          fields: {
            ...formData.fields,
            companyType: {
              ...formData.fields.companyType,
              value: '',
              error: true,
            },
          },
        };
        setFormData(newData);
        break;
      default:
    }

    return newData;
  };

  const changeToFormConclusion = (status) => {
    const newData = {
      ...formData,
      stepItems: [
        {
          id: 0,
          status: 'finished',
        },
        {
          id: 1,
          status: 'finished',
        },
        {
          id: 2,
          status,
        },
      ],
      activeStep: asaasEnum.formStepper.FORM_CONCLUSION,
    };

    setFormData(newData);

    return newData;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      email,
      birthday,
      cep,
      city,
      documentValue,
      street,
      number,
      complement,
      documentType,
      companyType,
      monthlyIncome,
    } = formData.fields;

    const postalCode = cep.value.replace(/\D+/g, '');
    let splittedDate = null;
    let birthDate = null;

    if (documentType.value === 'CPF') {
      splittedDate = birthday.value.split('/');
      birthDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
    }

    const incomeValue = monthlyIncome.value ? Math.floor(parseInt(monthlyIncome.value.replace(/\D+/g, ''), 10) / 100) : 0;

    const createSubAccountBody = {
      name: name.value,
      email: email.value,
      cpfCnpj: documentValue.value,
      birthDate,
      companyType: companyType.value,
      address: street.value,
      addressNumber: number.value,
      complement: complement.value,
      province: city.value,
      postalCode,
      connectionErrorValidationPart: asaasLocale[location].firstRow.form.error.connectionErrorValidationPart,
      conectionError: asaasLocale[location].firstRow.form.error.conectionError,
      changeToFormConclusion,
      location,
      client_id: summary.id,
      incomeValue,
    };

    if (documentType.value === 'CPF') {
      delete createSubAccountBody.companyType;
    }

    if (documentType.value === 'CNPJ') {
      delete createSubAccountBody.birthDate;
    }

    dispatch(asaasActions.createAccount.request(createSubAccountBody));

    return createSubAccountBody;
  };

  const callformStepTwoMyCEP = () => {
    asaasAnalytics.formStepTwoMyCEP();
  };

  const cnpjTypes = [
    {
      id: 0,
      label: asaasLocale[location].firstRow.form.texts.cnjpTypes.mei.label,
      value: asaasLocale[location].firstRow.form.texts.cnjpTypes.mei.value,
    },
    {
      id: 1,
      label: asaasLocale[location].firstRow.form.texts.cnjpTypes.limited.label,
      value: asaasLocale[location].firstRow.form.texts.cnjpTypes.limited.value,
    },
    {
      id: 2,
      label: asaasLocale[location].firstRow.form.texts.cnjpTypes.individual.label,
      value: asaasLocale[location].firstRow.form.texts.cnjpTypes.individual.value,
    },
    {
      id: 3,
      label: asaasLocale[location].firstRow.form.texts.cnjpTypes.association.label,
      value: asaasLocale[location].firstRow.form.texts.cnjpTypes.association.value,
    },
  ];

  const useAsaasRegisterFormData = {
    defaultFormState,
    formData,
    loadingCep,
    resetForm,
    getCep,
    onChange,
    onContinue,
    enabledFormOpenAccountContinue,
    enabledMainDataContinue,
    onGoback,
    onSubmit,
    cnpjTypes,
    callformStepTwoMyCEP,
  };

  useEffect(() => {
    if (location === 'wizard' && formData.activeStep === asaasEnum.formStepper.FORM_CONCLUSION) {
      setAsaasReachedThridStep && setAsaasReachedThridStep(true);

      if (accountCreated) {
        wizardToolsAnalytics.asaasAlreadyExists(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      } else {
        wizardToolsAnalytics.asaasAlreadyExists(toolSelected, getSelectedSiteTypeGaValue(), userSawEcommercePlusOffer);
      }
    }
  }, [formData.activeStep, location, setAsaasReachedThridStep, wizardToolsAnalytics, accountCreated, getSelectedSiteTypeGaValue, toolSelected, userSawEcommercePlusOffer]);

  return useAsaasRegisterFormData;
};

export default useAsaasRegisterForm;
