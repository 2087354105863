import cloudflareBR from './cloudflare.br';
import cloudflareCL from './cloudflare.cl';
import cloudflareCO from './cloudflare.co';
import cloudflareMX from './cloudflare.mx';

const locales = {
  BR: cloudflareBR,
  CL: cloudflareCL,
  CO: cloudflareCO,
  MX: cloudflareMX,
};

export default locales;
