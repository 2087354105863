import React from 'react';
import Link from '@/pages/common/Link/Link';
import { Button, Link as NewLink } from 'gatorcomponents';
import {
  DNS_WIZARD_EMAIL_HELP,
  DNS_WIZARD_HELP_URL, DNS_WIZARD_PROPAGATION,
  DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM,
  DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION,
  DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL,
  HOW_TO_TRANSFER_A_DOMAIN,
  HOW_TO_CREATE_OR_ALTER_DNS_REGISTER,
  REGISTER_DOMAIN_URL,
} from '@/config/urls/domainsUrls';
import { ERROR_MESSAGES, PLATFORM_NAMES, EMAIL_PLATFORM_NAMES } from '@/enums/domains/dns.enum';
import {
  CONFIGURE_DOMAIN_DNS_ARTICLE,
  DEFAULT_SERVER, DNS_PROPAGATION_ARTICLE, EXTERNAL_PLATFORM, GMAIL_CREATE_ACCOUNT, PERSONAL_DNS,
} from '@/config/urls/supportUrls';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import analytics from '@/analytics/domains/domains.analytics';

export default {
  // TODO: Review all the links below and refactor them into JSX and the new locale styles
  domainStatusTitle: 'Estado del Dominio',
  domainExpirationDate: 'Fecha de Suspensión',
  emailConfirmation: 'Confirmar Correo Electrónico',
  domainModalSubtitle: '<0>Usted tiene algunos sitios web suspendidos o a punto de ser suspendidos debido a la confirmación de correo electrónico pendiente.</0> Confírmelos a continuación para reactivar o evitar que se suspendan.',
  verificationStatusPending: 'Esperando Confirmación',
  verificationStatusSuspended: 'Suspendido Temporalmente',
  notifications: {
    activateRenewal: {
      success: {
        0: 'La renovación del dominio ',
        1: ' fue activado',
      },
      failure: {
        0: 'Cancelar la renovación del dominio ',
        1: ' no se pudo completar. Por favor, inténtelo de nuevo más tarde.',
      },
    },
    generateNewInvoice: {
      failure: {
        0: 'No fue posible generar la factura en este momento. Espere un instante y vuelva a intentarlo.',
      },
    },
  },

  // New Locales
  dnsWizard: {
    reinforcment: {
      noHostGatorPlan: {
        title: 'No se encontraron planes de hosting con espacio',
      },
      aditionalPlanOffer: {
        title: 'Todavía no tiene un plan de hosting con HostGator',
      },
      dLojaExplanation: {
        title: 'Você está iniciando a configuração de DNS para a Loja HostGator.',
        description: (
          <>
            <strong>Anteriormente conhecida como DLoja Virtual</strong>
            {`, a Loja HostGator é a plataforma de e-commerce da HostGator, contando com tudo que você precisa para criar sua loja e vender muito. 
Na Loja HostGator você irá administrar a sua loja online, cadastrar produtos e categorias, acompanhar pedidos e estoque, gerar relatórios, entre outros gerenciamentos.`}
          </>
        ),
        alert: (
          <>
            <strong>Lembrando</strong>
            {': As configurações de DNS da Loja HostGator são diferentes do seu plano de hospedagem da HostGator. Depois de confirmada a alteração, a criação de site deverá ser feita na '}
            <Link
              href="https://www.hostgator.com.br/loja-hostgator"
              text="Loja Hostgator"
              target="_blank"
            />
            . Registros na Zona de DNS, apontamento de e-mail e outras configurações continuarão sendo feitas na HostGator.
          </>
        ),
        checkbox: 'Estou ciente das diferenças entre a hospedagem HostGator e a Loja HostGator',
        cta: 'Continuar para configuração de domínio',
      },
    },
    bannerChangeOrNewPlanText: 'Su plan de hosting actual en HostGator solo admite la configuración de un dominio. Cambie su suscripción a un plan superior o compre otro plan.',
    bannerChangeOrNewPlanButtonNewPlan: 'Comprar otro plan',
    bannerChangeOrNewPlanButtonUpgradePlan: 'Cambiar plan de hosting',
    bannerNewPlanButton: 'Ver planes',
    bannerNewPlanText: 'Centralice su negocio con nosotros. Regístrese ahora para un plan de alojamiento de hostgator!',
    title: 'Configurar Dominio',
    selectedDomain: 'Dominio seleccionado:',
    chooseYourPlatformTitle: 'Elija la plataforma del sitio web:',
    chooseYourPlatformTooltip: 'La plataforma del sitio web es el local donde se alojará y/o creará el sitio web.',
    domainConfiguredTitle: 'Dominio configurado en la plataforma del sitio web:',
    dnsZoneHeaderTitle: 'Realizar configuración avanzada en la Zona de DNS',
    dnsZoneHeaderMessage: () => (
      <>
        La Zona de DNS permite acciones como direccionar sitio web, subdominio, correo electrónico, etc. Para más informaciones
        {' '}
        <NewLink
          href={HOW_TO_CREATE_OR_ALTER_DNS_REGISTER}
          target="_blank"
          rel="noreferrer"
          text="acceda a nuestra Central de Ayuda."
        />
      </>
    ),
    domainConfiguredTooltip: 'La plataforma del sitio es donde se alojará y / o se creará el sitio.',
    pageLoadingMessage: 'Cargando planes de hosting... ',
    configurationLoadingMessage: 'Cargando configuración de dominio...',
    dnsZoneOpenButtonText: 'Mostrar configuración manual de la zona DNS',
    dnsZoneCloseButtonText: 'Cerrar Zona DNS ',
    serverText: 'Servidor',
    planStatusCancelled: 'Su dominio está configurado para un plan de hosting cancelado. Configure su dominio ahora mismo para un plan o plataforma de hosting haciendo clic en "Cambiar plataforma".',
    emails: {
      emailZoneTitle: 'Configurar plataforma de correo electrónico del dominio',
      emailZoneTitleWithDefinedPlatform: 'Configurar plataforma de correo electrónico del dominio',
      propagationMessage: dotBr => (
        <>
          Después de la configuración, puede tardar hasta
          {' '}
          {dotBr ? 24 : 72}
          {' '}
          horas para que las informaciones sean
          {' '}
          <a href={DNS_WIZARD_PROPAGATION} target="_blank" rel="noreferrer">propagadas</a>
          , por esto, solo puede usar el correo electrónico después de ese período y si hay una cuenta de correo electrónico creada.
        </>
      ),
      emailZoneTitleTooltip: 'Aquí usted realiza solo la configuración de correo electrónico del dominio (apuntamiento de DNS), para que este funcione necesita tener una cuenta de correo electrónico creada en la plataforma elegida.',
      emailZoneSubtitle: 'Usted puede configurar para las plataformas de correo electrónico de Hostgator, Gmail o otra que desee.',
      changePlatformText: 'Cambiar plataforma',
      planStatusCancelled: 'Su dominio está configurado para un plan de hosting cancelado. Configure su dominio ahora mismo para un plan o plataforma de hosting haciendo clic en "Cambiar plataforma".',
      customEmailPlatformName: 'Otra plataforma de correo',
      emailZoneContentText: (platformType) => {
        if (platformType === EMAIL_PLATFORM_NAMES.HOSTGATOR) {
          return (
            <>
              Plataformas de correo
              <br />
              <b>HostGator</b>
            </>
          );
        } if (platformType === EMAIL_PLATFORM_NAMES.GMAIL) {
          return 'Gmail';
        } if (platformType === EMAIL_PLATFORM_NAMES.CUSTOM) {
          return 'Otra plataforma de correo';
        }
      },
    },
    dnsZoneTitle:
  <>
    {'¡Ahora '}
    <span>puede hacer los registros de Zona DNS</span>
    {' al final de la página!'}
  </>,
    dnsZoneAlertTitle: 'Configuración de dominio realizada con éxito',
    dnsZoneAlertDescription: 'Ahora puede seguir con las instrucciones a continuación.',
    successTitle: (domainName, domainElipsis) => (
      <>
        {'¡Su dominio '}
        <span className="full">{domainName}</span>
        <span className="ellipsis">{domainElipsis}</span>
        {' fue configurado con éxito!'}
      </>
    ),
    successMessage: propagationTime => (
      `Espere el período de propagación de hasta ${propagationTime} horas para que su sitio esté online.`
    ),
    configCardAttentionMessage: (propagationTime = 24, shouldRenderOtherPlatform, helpClickGa = () => {}) => (
      <>
        {`Puede tomar hasta ${propagationTime} horas para que el dominio esté en la nueva plataforma (período de propagación de DNS). Su sitio podrá estar online después de este período.`}
        <br />
        Si desea acompañar el status de la configuración.
        {' '}
        <Link onClick={helpClickGa} href={DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION} inlineLink target="_blank" text="Vea cómo comprobar que se ha completado la propagación de DNS." />
      </>
    ),
    resellerMessage:
  <p>
    <span>
      <strong>{'Importante: '}</strong>
      Utilice el panel de WHM para administrar el Reseller y el panel de cPanel para administrar las cuentas de los clientes
    </span>
  </p>,
    otherPlatformAttentionMessage:
  <>
    <span>
      <b>
        Importante
      </b>
      {' '}
      :
      La creación del sitio, los registros de zona DNS, los apuntamentos de correo electrónico y otras configuraciones deben realizarse en el otro proveedor. Si el dominio se ha configurado para un servidor HostGator, como el servidor VPS y Dedicado, los registros de zona DNS deben ser administrados por el panel WHM (VPS y servidor Dedicado Linux ) o el panel Plesk (Servidor Dedicado Windows).
    </span>
  </>,
    externalPlatformAttentionMessage: platformName => (
      <>
        <span>
          <b>
            Importante:
          </b>
          {` La creación del sitio, los registros de zona DNS, los apuntamentos de correo electrónico y otras configuraciones deben realizarse en ${platformName}.`}
        </span>
      </>
    ),
    otherPlatformCardTitle: 'Otra plataforma de alojamiento',
    changePlatformText: 'Cambiar plataforma',
    editAdvancedDNSZone: 'Editar Zona avanzada de DNS',
    noPlanSelectedMessage: 'Servidores de DNS (Name Servers)',
    principalDomainMessage: domainSelected => (
      <>
        {domainSelected ? `Dominio principal: ${domainSelected}` : ''}
      </>
    ),
    chooseYourPlatformText: (helpClickGa = () => {}) => (
      <>
        <p>Seleccione una de las plataformas abajo para configurar automáticamente el dominio (cambiar DNS).</p>
        <p>
          Si no es cliente de ninguna de ellas, configure manualmente la opción para otra plataforma de alojamiento o haga una configuración avanzada. Si es necesario,
          {' '}
          <Link href={DNS_WIZARD_HELP_URL} onClick={helpClickGa} target="_blank" text="vea cómo configurar." inlineLink />
        </p>
      </>
    ),
    advancedConfiguration:
  <>
    <span>Ir para configuración avanzada</span>
    {' '}
    <span>(Zona de DNS)</span>
  </>,
    otherPlatformCardText: (
      <>
        Otra plataforma de
        <br />
        alojamiento
      </>
    ),
    reservedPlatform: (
      <>
        Sin alojamiento
        <br />
        (apenas Zona de DNS)
      </>
    ),
    noPlatformSelectedMessage: () => (
      <>
        {'Cambie la zona de DNS para configurar su dominio para plataformas de sitios web, correos electrónicos, entre otros. '}
        <Link href={DEFAULT_SERVER} target="_blank" text="Vea mas." inlineLink />
      </>
    ),
    domainNotConfiguredMessage: () => (
      <>
        Su dominio está con nosotros, pero
        {' '}
        <strong>aún no está configurado</strong>
        {' '}
        para un plan de alojamiento.
        <Link href={CONFIGURE_DOMAIN_DNS_ARTICLE} target="_blank" text="Vea cómo configurar su dominio." inlineLink />
      </>
    ),
    tooltip: 'La plataforma del sitio es el lugar donde se alojará y/o se creará el sitio.',
    configCardTooltip: platformName => (`Su dominio está apuntando para ${PLATFORM_NAMES[platformName]}, por lo que la creación de su sitio debe llevarse a cabo en esa plataforma. Las configuraciones en la zona DNS se siguen realizando aquí en HostGator.`),
    access: 'Acessar',
    modals: {
      hostgator: {
        title: '¿En cuál plan o servidor desea configurar el dominio?',
        subtitle: 'Seleccione a continuación en cual plan de hosting o dominio se alojará.',
        tooltip: 'Puede seleccionar cualquier plan listado aquí. Esta configuración es necesaria para que el dominio tenga un servidor donde se almacenará sus informaciones. ',
        dnsConfigText: 'Ir para configuración avanzada (Zona de DNS)',
        checkbox: 'Estoy consciente de que las actuales configuraciones de dominio se perderán al cambiar el lugar de alojamiento del dominio.',
        button: 'Configurar',
        domain: 'dominio',
        domains: 'dominios',
        mainDomain: 'Dominio principal:',
        otherDomains: 'Otros dominios:',
        alertMessage: (propagationTime = 24, helpClickGa = () => {}) => (
          <p>
            Después de la configuración, puede tardar hasta
            {' '}
            {propagationTime}
            {' '}
            horas para que las informaciones se
            {' '}
            <Link href={DNS_WIZARD_PROPAGATION} onClick={helpClickGa} target="_blank" text="propaguen" inlineLink />
            {' '}
            en el plan de hosting elegido. Su sitio puede estar online después de este período.
          </p>
        ),
      },
      custom_dns_zone: {
        title: type => `Configuración para ${PLATFORM_NAMES[type]}`,
        subtitle: type => `No se preocupe, haremos toda la configuración por usted. Serán apuntados los registros predeterminados de la ${PLATFORM_NAMES[type]} en la Zona DNS del dominio. Si lo desea, consulte a continuación.`,
        configureButtonLabel: 'Configurar',
        showButtonLabel: 'Mostrar registros predeterminados',
        hideButtonLabel: 'Cerrar registros predeterminados',
        tableHeaders: {
          type: 'Tipo de apuntamiento',
          value: 'Destino',
          name: 'Nombre',
        },
        checkboxText: 'Estoy consciente de que las actuales configuraciones de dominio se perderán al cambiar el lugar de alojamiento del dominio.',
        alertMessage: (propagationTime = 24, platformName = '', helpClickGa = () => {}) => (
          <p>
            {`Después de la configuración, puede tardar hasta ${propagationTime} horas para que las informaciones se `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' en el plan de hosting elegido. Su sitio podrá estar online después de este período.'}
            <span>
              <b>Importante:</b>
              {` Después de confirmar esta acción, la creación del sitio debe realizarse en la ${platformName}. Los registros de zona DNS, el apuntamiento de correo electrónico y otras configuraciones se realizarán en HostGator.`}
            </span>
          </p>
        ),
      },
      custom: {
        title: 'Otra plataforma de alojamiento',
        serverWithNumber: ({ serverNumber = 0, required = false }) => (`Servidor ${serverNumber}${required ? '*' : ''}:`),
        nsWithNumber: number => (`ns${number}.seuservidor.com`),
        ipWithNumber: number => (
          <>
            {'Dirección IP* '}
            <small>{`(servidor ${number}):`}</small>
          </>
        ),
        ipPlaceholder: 'Digite la dirección IP',
        addServer: 'Añadir un servidor más',
        button: 'Configurar',
        subtitle: 'Indique a continuación los servidores de la plataforma (name server) donde desea configurar su dominio o el servidor de su alojamiento personalizado.',
        needHelp: () => (
          <p>
            {'¿necesita ayuda? '}
            <Link href={EXTERNAL_PLATFORM} inlineLink target="_blank" text="Cómo configurar el dominio en plataformas externas" />
            {' y '}
            <Link href={PERSONAL_DNS} inlineLink target="_blank" text="¿Qué es un servidor personalizado?" />
          </p>
        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => {}) => (
          <p>
            {`Después de la configuración, puede tardar hasta ${propagationTime} horas para que las informaciones se `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' en el plan de hosting elegido. Su sitio podrá estar online después de este período.'}
            <span>
              <b>Importante:</b>
              {' Si la configuración se realiza para un servidor fuera del alojamiento, la creación del sitio, los registros en la zona DNS, los apuntamientos de correo electrónico y otras configuraciones deberán realizarse en el otro proveedor.'}
            </span>
          </p>
        ),
      },
      external: {
        title: platformName => `Configuración para ${platformName}`,
        subtitle: 'No se preocupe, haremos toda la configuración por usted. Cuando confirme, los servidores (Name server/ DNS) de su dominio serán:',
        configureButtonLabel: 'Configurar',
        serverLabel: 'Servidor',
        checkboxText: 'Estoy consciente de que las actuales configuraciones de dominio se perderán al cambiar el lugar de alojamiento del dominio.',
        alertMessage: (propagationTime = 24, platformName, helpClickGa = () => {}) => (
          <p>
            {`Después de la configuración, puede tardar hasta ${propagationTime} horas para que las informaciones se `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' en el plan de hosting elegido. Su sitio podrá estar online después de este período.'}
            <span>
              <b>Importante:</b>
              {` Después de confirmar esta acción, la creación del sitio, los registros de zona DNS, el apuntamiento de correo electrónico y otras configuraciones se realizarán en ${platformName}.`}
            </span>
          </p>
        ),
      },
      alterPlatform: {
        title: 'Cambiar la plataforma del sitio',
        checkboxText: 'Estoy consciente de que las actuales configuraciones de dominio se perderán al cambiar el lugar de alojamiento del dominio.',
        subtitle:
  <p>
    Seleccione una de las  plataformas abajo para configurar automáticamente el dominio (cambiar DNS).
    <span>
      {'Si no es cliente de ninguna de esas plataformas, configure manualmente en la opción otra plataforma de alojamiento o realice una configuración avanzada. Si es necesario, '}
      <Link href={DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM} inlineLink target="_blank" text="aquí le mostramos cómo configurarlo" />
      .
    </span>
  </p>,
        button: 'Continuar',
        tooltip: 'La plataforma del sitio es el lugar donde se alojará y/o se creará el sitio.',
      },
      mail: {
        title: platformName => `Configuración para ${platformName}`,
        subtitle: (platformName, domainName, helpClickGa) => (
          <>
            {`Aquí solo realizaremos el apuntamiento de los registros estándar de ${platformName} en la zona DNS del dominio, pero para que el correo electrónico funcione, debe tener una cuenta de correo electrónico "`}
            <span className="mark">
              {`@${domainName}`}
            </span>
            {`" en ${platformName}. Si es necesario `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL} inlineLink target="_blank" text="vea cómo realizarlo." />
          </>
        ),
        gmailSubtitle: (platformName, domainName, helpClickGa) => (
          <>
            {'Vamos a realizar la configuración de correo electrónico del dominio (registros DNS estándar), pero para que funcione necesita tener una cuenta de correo '}
            &quot;
            <span className="mark">
              {`@${domainName}`}
            </span>
            {`" en ${platformName}, `}
            <Link onClick={helpClickGa} href={GMAIL_CREATE_ACCOUNT} inlineLink target="_blank" text="vea cómo hacerlo." />
          </>
        ),
        configureButtonLabel: 'Configurar',
        showButtonLabel: 'Mostrar registros predeterminados',
        hideButtonLabel: 'Cerrar registros predeterminados',
        tableHeaders: {
          type: 'Tipo de apuntamiento',
          value: 'Destino',
          name: 'Nombre',
        },
        checkboxText: () => (
          <>
            <p>
              Soy consciente de que al hacer clic en el botón a continuación, la configuración actual de la plataforma de correo electrónico se eliminará y que
              <strong> ya no será posible usarlo, </strong>
              pudiendo ver solo los correos electrónicos existentes.
            </p>
          </>
        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => {}) => (
          <p>
            {`Después de la configuración, puede tardar hasta ${propagationTime} horas para que las informaciones se `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            {' por esto, solo puede usar el correo electrónico después de ese período y si hay una cuenta de correo electrónico creada.'}
          </p>
        ),
      },
      custom_mail: {
        title: 'Cambiar para otra plataforma de correo',
        button: 'Ir para la zona de DNS',
        checkbox:
  <>
    {'Soy consciente de que al hacer clic en el botón de abajo, se eliminará la configuración actual de la plataforma de correo electrónico y que, por lo tanto, '}
    <strong>
      no será más posible usarla
    </strong>
    , pudiendo ver solo los correos electrónicos existentes.
  </>,
        moreInfo: (analyticsClick = () => {}) => <Link onClick={analyticsClick} href={DNS_WIZARD_EMAIL_HELP} target="_blank" text="Vea cómo apuntar su dominio para una plataforma de correo electrónico externa." />,
        description: domain => (
          <>
            <p>Vamos a dirigirlo para la Zona DNS, donde será necesario ingresar manualmente los registros estándar de la plataforma de correo electrónico deseada.</p>
            <p>
              Puede obtener estas informaciones poniéndose en contacto directamente con la empresa que utilizará. Recuerde crear la cuenta de correo electrónico &quot;
              <span>
                @
                {domain}
              </span>
              &quot; allí.
            </p>
          </>
        ),
        alertMessage: (propagationTime = 24, helpClickGa = () => { }) => (
          <p>
            {` Después de la configuración, puede tardar hasta ${propagationTime} horas para que las informaciones sean `}
            <Link onClick={helpClickGa} href={DNS_WIZARD_PROPAGATION} inlineLink target="_blank" text="propagadas" />
            , por esto, solo puede usar el correo electrónico después de ese período y si hay una cuenta de correo electrónico creada.
          </p>
        ),
      },
      advancedDnsZone: {
        title: 'Atención',
        description: () => (
          <>
            Esta es una Zona de configuración avanzada de DNS. Una configuración incorrecta puede dejar su sitio o servicio
            {' '}
            <strong>sin funcionar</strong>
            .
            <br />
            Tenga cuidado al realizar cambios y siempre verifique las configuraciones antes de guardar.
          </>
        ),
        checkboxDescription: 'No volver a mostrar este mensaje.',
        button: 'Ok, continuar para la Zona de DNS',
      },
      domainConfigurationReview: {
        title: 'Configurar dominio',
        subtitle: 'Esa opción permitirá administrar su dominio incluso si no tiene un plan de alojamiento.',
        subtitleWithHgPlan: plan => (
          <>
            Al seleccionar esa opción, su dominio será apuntado para el plan:
            {' '}
            <strong>
              &quot;
              {plan.name}
              &quot;
            </strong>
            {' '}
            -
            {' '}
            {plan.domain}
          </>
        ),
        description: ns => (
          <>
            Identificamos que su dominio apunta para un alojamiento. Al continuar, perderá esta configuración actual.
            <br />
            <br />
            <strong>Su configuración actual es:</strong>
            <br />
            <strong>Servidor 1:</strong>
            {' '}
            {ns && ns[0]}
            <br />
            <strong>Servidor 2:</strong>
            {' '}
            {ns && ns[1]}
            {ns && ns[2] && (
              <>
                <br />
                <strong>Servidor 2:</strong>
                {' '}
                {ns[2]}
              </>
            )}
            {ns && ns[3] && (
              <>
                <br />
                <strong>Servidor 2:</strong>
                {' '}
                {ns[3]}
              </>
            )}
            <br />
            <br />
            Si prefiere, lea más sobre
            {' '}
            <a href={HOW_TO_CREATE_OR_ALTER_DNS_REGISTER} target="_blank" rel="noreferrer">las configuraciones de la Zona de DNS</a>
            {' '}
            antes de continuar.
          </>
        ),
        keepConfigurations: 'Mantener configuración',
        configure: 'Configurar',
      },
    },
    errors: {
      default: 'No fue posible configurar el dominio en este momento. Inténtelo más tarde o póngase en contacto con nuestro soporte a través del chat',
      getMessage: (errorType) => {
        switch (errorType) {
          case ERROR_MESSAGES.DEFAULT:
            return 'No pudimos configurar su dominio en este momento. Inténtelo más tarde o comuníquese con nuestro soporte a través del chat.';
          case ERROR_MESSAGES.REQUIRED:
            return 'Rellene el campo para continuar.';
          case ERROR_MESSAGES.INVALID_SERVER:
            return '¡Servidor no válido! No puede contener caracteres especiales (excepto guión), comenzar y terminar con guión, o contener sólo números.';
          case ERROR_MESSAGES.INVALID_IP:
            return '¡Dirección IP no válida! Debe contener solo números y puntos. Los números permitidos van del 0 al 255.';
          default:
            break;
        }
      },
    },
    hostgatorMailPlatform: (
      <>
        Plataformas de correo
        <br />
        <b>HostGator</b>
      </>
    ),
  },
  dnsStatus: (status, dotBr, url) => ({
    [PROPAGATION_STATUS.IN_PROGRESS]: {
      labelText: 'Dominio en propagación',
      labelTooltip: (
        <span>
          <strong>Dominio en periodo de propagación:</strong>
          {` puede tardar hasta ${dotBr ? 24 : 72} horas para que su dominio este online.`}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          {`En periodo de propagación: puede tardar hasta ${dotBr ? 24 : 72} horas para que su dominio este online.`}
          <br />
          <Link href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" text="Entienda el plazo." />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_PROPAGATED]: {
      labelText: 'Dominio no propagado',
      labelTooltip: (
        <span>
          <strong>Dominio no propagado:</strong>
          {' su sitio web todavía no está disponible en internet porque está en proceso de actualización. Espere algunas horas para que esté disponible.'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Dominio no apuntado: su sitio esta offline por falta de la configuración de dominio (DNS).
          <br />
          <Link href={url} text="Configurar ahora" />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_CONTRACTED]: {
      labelText: 'Dominio no contratado',
      labelTooltip: (
        <span>
          <strong>Dominio no contratado:</strong>
          {' usted tiene uno o más dominios que aún no fueron adquiridos.'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Dominio no contratado: usted tiene uno o más dominios que aún no fueron adquiridos.
          <br />
          <Link
            href={url}
            text="Contratar ahora"
            onClick={() => {
              analytics.dnsWizard.bannerDomainNotContracted();
            }}
          />
        </span>
      ),
    },
    [PROPAGATION_STATUS.NOT_CONFIGURED]: {
      labelText: 'Configuración de dominio pendiente',
      labelTooltip: (
        <span>
          <strong>Configuración de dominio pendiente:</strong>
          {' usted tiene uno o más sitios web con configuración de dominio (cambio de DNS) pendiente.'}
        </span>
      ),
      labelDomainsDetails: (
        <span>
          Configuración de dominio pendiente: usted tiene uno o más sitios web con configuración de dominio (cambio de DNS) pendiente.
          <br />
          <Link
            href={url}
            text="Vea cómo configurarlo."
            onClick={() => {
              analytics.dnsWizard.buttonHowToConfigure();
            }}
          />
        </span>
      ),
    },
  })[status],
  register: {
    modal: {
      title: 'Registro de dominio',
      singleDomain: ({ domain }) => (
        <span>
          El dominio &quot;
          <strong>
            {domain}
          </strong>
          &quot; aún no está registrado, por esto está offline.
          <br />
          <br />
          ¿Es correcta la dirección del dominio? Si es así, continúe registrándose.
        </span>
      ),
      multipleDomains: 'Tiene más de un dominio no registrado. ¿Cuál le gustaría continuar registrando ahora?',
      registerDomain: 'Registrar dominio',
    },
  },
  modalEppKey: {
    defaultTitle: 'Solicitar clave EPP',
    successTitle: 'Clave EPP generada correctamente',
    failureTitle: 'Error al generar la clave EPP',
    orderEppKeyAdvice: (
      <p>
        Seleccione el dominio abajo para generar la clave.
      </p>
    ),
    selectDomainForEppLabel: '¿Para cuál dominio desea solicitar la clave EPP?',
    selectDomainForEppPlaceholder: 'Seleccione el dominio',
    generateEppKey: 'Generar clave EPP',
    confirmOrderAdvice: (
      <>
        <p>
          Para evitar pérdidas por transferencias realizadas fuera del plazo, es recomendable que su dominio haya sido registrado
          <strong>{' durante más de 60 días, no esté expirado y tenga al menos 30 días restantes antes de expirar.'}</strong>
        </p>
        <p>
          Al generar la clave, usted es consciente de que:
        </p>
      </>
    ),
    effectsOnGenerateKey: (
      <ul>
        <li>
          Las transferencias realizadas fuera del plazo informado pueden generar la
          <strong>{' pérdida del dominio '}</strong>
          durante el proceso;
        </li>
        <li>
          El dominio se desbloqueará para la transferencia;
        </li>
        <li>
          Deshabilitaremos la renovación automática en su portal y, si hay una factura pendiente, se cancelará.
        </li>
      </ul>
    ),
    cancel: 'Cancelar',
    keyGenerated: (domain, key) => (
      <>
        <p>
          La clave EPP para el dominio
          <strong>{` ${domain} `}</strong>
          es:
        </p>
        <span>{key}</span>
      </>
    ),
    successInfo: (
      <>
        <p>
          Esta clave debe enviarse a su nuevo proveedor y es válida por 7 días. Después de enviar para el nuevo proveedor, el plazo es de 5 a 15 días para completar la transferencia.
        </p>
        <p>
          Lea más en nuestro material de apoyo:
        </p>
        <NewLink text="¿Cómo transferir un dominio con la clave EPP?" href={HOW_TO_TRANSFER_A_DOMAIN} />
      </>
    ),
    understood: 'Ok, entendi',
    failureInfo: domain => (
      <>
        <p>
          Tuvimos un problema al
          <strong>{' generar la clave EPP '}</strong>
          para el dominio
          <strong>
            {` ${domain}`}
          </strong>
          .
        </p>
        <p>
          Hable con un especialista en un ticket o vuelva a la pantalla de administración de dominios.
        </p>
      </>
    ),
    openTicket: 'Abrir un ticket',
    backToDomains: 'Volver a administrar dominios',
    copyKeyTooltip: 'Copiar clave',
    copiedKeyTooltip: 'Copiado!',
    copyCodeLabel: 'Copiar código',
  },
  manageDomainHandler: {
    tags: {
      pending: 'Abierto',
      active: 'Registrado',
      cancelled: 'Cancelado',
      pendingTransfer: 'En Transferencia',
      registerError: 'Registro Fallido',
      expired: 'Caducado',
      nextDueDate: 'Por vencer',
      overDueDate: 'Vencido',
      in_progress: 'En propagación',
    },
    alert: {
      overDueDate: ({
        expiryDate,
      }) => (
        <>
          {`Su dominio venció y expirará el ${expiryDate}. Renueve ahora mismo para no correr el riesgo de perderlo.`}
        </>
      ),
      nextDueDate: () => (
        <>
          {'La suscripción vencerá pronto. Le recomendamos que utilice un método de pago con '}
          <strong>compensación inmediata,</strong>
          {' como '}
          <strong>Tarjeta de crédito.</strong>
        </>
      ),
      goToPayment: 'Ir para el pago',
    },
    domainData: {
      registerDate: 'Fecha de registro',
      nextDueDate: 'Fecha de vencimiento',
      cycle: 'Ciclo de pago',
      manageSubscription: 'Administrar suscripción',
    },
    cardOptions: {
      dns: {
        title: 'Servidores DNS',
        tooltip: 'Los servidores DNS traducen los nombres de dominio en direcciones IP. La información de DNS es importante para configurar dominios, correos electrónicos, administrar subdominios y garantizar que los sitios web y los correos electrónicos estén online y funcionen.',
        cta: 'Configurar dominio',
        alert: {
          in_progress: ({ dotBr }) => (
            <>
              {`En período de propagación: su dominio puede tardar hasta ${dotBr ? 24 : 72}h horas para estar online. .`}
              <br />
              <Link
                href={DNS_PROPAGATION_ARTICLE}
                target="_blank"
                rel="noreferrer"
                text="Comprenda el plazo."
                testId="in_progress-cta"
              />
            </>
          ),
          reserved: (
            <>
              {'Su dominio está con nosotros, '}
              <strong>pero aún no está configurado</strong>
              {' para un plan de hosting.'}
              <Link
                href={CONFIGURE_DOMAIN_DNS_ARTICLE}
                target="_blank"
                text="Vea cómo configurar su dominio."
                inlineLink
                testId="reserved-cta"
              />
            </>
          ),
        },
      },
      mail: {
        title: 'Correo Profesional',
        description: 'Administre sus correos electrónicos creados o cree una cuenta profesional con un correo personalizado @tudominio.',
        cta: 'Ir para lista de correos',
      },
      manage: {
        title: 'Opciones de administración',
        protect: {
          title: 'Protect ID',
          description: 'Oculte sus informaciones personales en sitios como WHOIS.',
          cta: 'Ver detalles',
          testId: 'protect',
        },
        contactInfo: {
          title: 'Informaciones de contacto',
          description: 'Cambie las informaciones de contacto vinculadas a su dominio.',
          cta: 'Cambiar',
          testId: 'contact',
        },
        register: {
          title: 'Bloqueo de registro',
          description: 'Bloquee su dominio para evitar que sea trasferido sin su autorización.',
          cta: 'Administrar',
          testId: 'register',
        },
        eppKey: {
          title: 'Clave EPP',
          description: 'Código vinculado al registro de dominio.',
          cta: 'Solicitar clave',
          testId: 'eppKey',
        },
      },
    },
  },
  domainsListPage: {
    emptyState: (
      <>
        {'No hemos encontrado ningún dominio contratado. '}
        <NewLink text="Haz clic aquí" target="_blank" rel="noreferrer" href={REGISTER_DOMAIN_URL} />
        {' para contratar un dominio.'}
      </>
    ),
    noFilterResult: 'No hay resultados. Inténtalo de nuevo :)',
    filter: {
      title: 'Lista de Dominios',
      filterLabel: 'Filtrar por',
      all: 'Todos',
      billingPending: 'Con pendiente financiero',
      registerFail: 'Falla de registro',
      search: 'Buscar por dominio',
      clearLabel: 'Limpiar filtros',
    },
    modalDomainAutoRenovation: {
      title: 'Habilitar la renovación automática',
      description: 'Si el método de pago es PayPal o Tarjeta de Crédito, el cobro se realizará automáticamente. Si el método es cupón de pago, la factura se emitirá 30 días antes de la fecha de vencimiento.',
      confirmLabel: 'Confirmar',
    },
    alerts: {
      disabled_auto_renew: (date, onClick) => (
        <>
          {'Su dominio vence el '}
          <strong>{date}</strong>
          {'. '}
          <Button onClick={onClick} label="Active la renovación automática" variant="text" />
          {' para no correr el riesgo de perderlo.'}
        </>
      ),
      register_fail: 'Error en el registro del dominio. Aguarda más instrucciones por correo.',
      in_transfer: 'Dominio en proceso de transferencia. Aguarda más instrucciones por correo.',
      pending_domain: onClick => (
        <>
          <strong>Dominio pendiente:</strong>
          {' Realiza el pago de la factura abierta. '}
          <Button onClick={onClick} label="Pagar factura" variant="text" />
        </>
      ),
      next_due_invoice: (date, url) => (
        <>
          {'Su factura vence el dia '}
          <strong>{date}</strong>
          {'. '}
          <Link href={url} text="¡Pague el cupón de pago ahora mismo" inlineLink rel="noreferrer" />
          {' y no corra el riesgo de perder su dominio!'}
        </>
      ),
      next_due_date_credit_card: date => (
        <>
          {'Su factura '}
          <strong>{`vence el dia ${date}`}</strong>
          {' y se le cobrará automáticamente a través de Tarjeta de Crédito.'}
        </>
      ),
      next_due_date_paypal: date => (
        <>
          {'Su factura '}
          <strong>{`vence el dia ${date}`}</strong>
          {' y se le cobrará automáticamente a través de PayPal.'}
        </>
      ),
      due_date_disabled_auto_renovation: (date, onClick, loading) => (
        <>
          {'Su dominio '}
          <strong>{`vence el ${date}`}</strong>
          <Button onClick={onClick} disabled={loading} label="Renueve ahora mismo" variant="text" />
          {' para no correr el riesgo de perderlo.'}
        </>
      ),
      due_date_invoice: (date, days, url) => (
        <>
          {'Su factura venció el día '}
          <strong>{date}</strong>
          {'y su dominio caducará en '}
          <strong>{days === 1 ? `${days} dia. ` : `${days} dias. `}</strong>
          <Link href={url} text="¡Pague la factura ahora mismo!" inlineLink rel="noreferrer" />
        </>
      ),
      overdue_disabled_auto_renew: (date, onClick, loading) => (
        <>
          {'Su dominio venció y '}
          <strong>{`expirará el ${date}. `}</strong>
          <Button onClick={onClick} disabled={loading} label="Renueve ahora mismo" variant="text" />
          {' para no correr el riesgo de perderlo.'}
        </>
      ),
      expired_invoice: (date, url) => (
        <>
          {'Su factura '}
          <strong>{`venció el día ${date}. `}</strong>
          {' y su dominio será cancelado en breve.'}
          <Link href={url} text="¡Pague la factura ahora mismo!" inlineLink rel="noreferrer" />
        </>
      ),
      expired_grace_period_overdue: date => (
        <>
          {'Su dominio '}
          <strong>{`venció el ${date}`}</strong>
          . Para más informaciones, comuníquese con soporte.
        </>
      ),
      expired_on_grace_period: onClick => (
        <>
          {'El dominio ha '}
          <strong>caducado</strong>
          {' y esta es la última oportunidad para renovarlo. '}
          <Button onClick={onClick} label="Pague la factura ahora mismo" variant="text" />
          {' para no correr el riesgo de perderlo.'}
        </>
      ),
      domain_cancelled_by_tos: (
        <>
          {'Su  '}
          <strong>dominio fue cancelado</strong>
          {'. Para más informaciónes ingrese a nuestra '}
          <Link href="https://soporte-latam.hostgator.com" text="Central de Ayuda." inlineLink rel="noreferrer" />
        </>
      ),
      cancelled_unrecoverable: (date, url) => (
        <>
          {'Su '}
          <strong>{`dominio fue cancelado el dia ${date}. `}</strong>
          <Link href={url} text="¡Ingrese a nuestro sitio web y contrate un nuevo dominio!" inlineLink rel="noreferrer" target="_blank" />
        </>
      ),
      cancelled_recoverable: (date, url) => (
        <>
          {'Su '}
          <strong>{`dominio fue cancelado el dia ${date}. `}</strong>
          {' por falta de pago. '}
          <Link href={url} text="¡Ingrese a nuestro sitio web y contrate un nuevo dominio!" inlineLink rel="noreferrer" target="_blank" />
        </>
      ),
    },
    propagationAlerts: (status, dotBr) => ({
      [PROPAGATION_STATUS.IN_PROGRESS]: {
        labelTag: 'En propagación',
        labelDomainsDetails: (
          <>
            {`En período de propagación: su dominio puede tardar hasta ${dotBr ? 24 : 72} horas para estar online. `}
            <Link href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" text="Comprenda el plazo." inlineLink />
          </>
        ),
      },
    })[status],
    statusProductDomain: {
      active: 'Registrado',
      pending: 'Abierto',
      cancelled: 'Cancelado',
      pendingTransfer: 'En transferencia',
      registerError: 'Registro fallido',
      expired: 'Caducado',
      nextDueDate: 'Por vencer',
      overDueDate: 'Vencido',
    },
    contractNewButtonLabel: 'Contratar nuevo dominio',
    configureButtonLabel: 'Configurar dominio',
    manageButtonLabel: 'Administrar',
    loadMoreButtonLabel: 'Cargar más',
  },
};
