import React, {
  useContext, useState, useEffect, useCallback,
  useMemo,
} from 'react';
import classnames from 'classnames';
import {
  Tooltip,
  Card, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import { ThemeContext } from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { EmailsContext } from '@/contexts/Emails/context';
import Loader from '@/components/Layout/Loader';
import IconStar from '@/media/icons/proemailPremiumInverted.svg';
import AccountTreeBlack from '@/media/icons/proemailAccountTreeBlack.svg';
import BlueBackGround from '@/media/icons/bg_blue.svg';
import ActionButton from '@/components/Buttons/ActionButton';
import BannerToEnableTitanTrial from '@/components/Email/BannerToEnableTitanTrial';
import ModalToEnableTitanTrial from '@/components/Email/ModalToEnableTitanTrial';
import { IconDropdownArrow } from '@/icons';
import { emailsActions } from '@/redux/modules/emails';
import { isMobile, isBRServer } from '@/utils/Validators/validation';
import {
  executeWithParams,
  executeGADisplayBannerWithDomains,
} from '@/utils/ThirdParties/tagManager';
import { queryParams } from '@/utils/Application/queryParams';
import { requestUpgradePlans } from '@/redux/actions/emails';
import { ESSENTIALS_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { COUNTRY } from '@/config/index';
import ProEmailActiveTrial from './ProEmailActiveTrial/ProEmailActiveTrial';
import ProEmailCard from './ProEmailCard';
import ModalTitanTrialColorful from '@/components/Email/ModalTitanTrialColorful';
import styles, { WrapperBannerTrial } from './styles';
import { ALPHA_ENABLE_COLORFUL_MODAL_FOR_TITAN_TRIAL_IN_EMAILS_PAGE, ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ProEmailsList = ({
  classes, t, setEnableSearch,
  enabledSortSubdomainsList = false,
}) => {
  const dispatch = useDispatch();

  const enableActivationTitanByBanner = true;

  const allEmails = useSelector(state => state.emails.proEmailList);
  const domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');

  const domainWithoutEmailFormattedToOptions = domainWithoutEmail.map(item => ({ value: item.id, label: `@${item.domain}` }));
  const reactivateAutoRenovationCycle = true;
  const shouldRenderColorfulModal = useFeatureIsOn(ALPHA_ENABLE_COLORFUL_MODAL_FOR_TITAN_TRIAL_IN_EMAILS_PAGE);
  const enableTrialTitanByUrlParam = useFeatureIsOn(ALPHA_ENABLE_TRIAL_TITAN_BY_URL_PARAM);

  const MAX_LINES_DISPLAYED = 5;
  const { renderedEmailList, searchLoader } = useContext(EmailsContext);
  const listEmailsFree = [];
  const listEmailsPaid = [];
  const [freeExpanded, setFreeExpanded] = useState(true);
  const [paidExpanded, setPaidExpanded] = useState(true);
  const [visibleFree, setVisibleFree] = useState(MAX_LINES_DISPLAYED);
  const [visiblePaid, setVisiblePaid] = useState(MAX_LINES_DISPLAYED);
  const [isLoaded, setIsLoaded] = useState(false);
  const theme = useContext(ThemeContext);
  const listKeysFree = [];
  const listKeysPaid = [];
  const [enableCreateFirstAccount, setEnableCreateFirstAccount] = useState(false);

  const location = useLocation();
  const [isTrialActivate, setTrialActivate] = useState(queryParams(location.search, 'trialSucess'));

  const sortByStatus = list => (enabledSortSubdomainsList ? list.sort((a, b) => (a.main_domain === b.domain ? 1 : -1)) : list);


  useEffect(() => {
    if (isTrialActivate) {
      setTrialActivate(false);
    }
  }, [dispatch, isTrialActivate]);


  const executeSearchLoader = () => {
    if (searchLoader) {
      if (visibleFree > MAX_LINES_DISPLAYED) {
        setVisibleFree(MAX_LINES_DISPLAYED);
      }
      if (visiblePaid > MAX_LINES_DISPLAYED) {
        setVisiblePaid(MAX_LINES_DISPLAYED);
      }
      return <Loader data-testid="Loader" />;
    }
  };

  const isEnabledCreateFirstAccount = () => {
    if (allEmails.length > 0 && !enableCreateFirstAccount) {
      const hasOnlyDomainsValids = allEmails.filter(item => item.plan !== null && item.type !== 'domain').length === 0;
      setEnableCreateFirstAccount(hasOnlyDomainsValids);
    }
    if (domainWithoutEmail.length === 0) {
      setEnableCreateFirstAccount(false);
    }
  };


  useEffect(() => {
    executeSearchLoader();
    isEnabledCreateFirstAccount();
    setEnableSearch(!enableCreateFirstAccount);
  });

  const loadMoreFree = () => {
    setVisibleFree(listEmailsFree.length);
  };

  const loadMorePaid = () => {
    setVisiblePaid(listEmailsPaid.length);
  };

  const handleChange = panel => (event, isExpanded) => {
    setPaidExpanded(isExpanded ? panel : false);
  };

  const handleChangeFree = panel => (event, isExpanded) => {
    setFreeExpanded(isExpanded ? panel : false);
  };

  const preencherGrupoDominios = (list, proEmailTmp, isFree) => {
    const domainGroup = new Map();
    if (list.length === 0) {
      domainGroup.set(proEmailTmp.hosting_id, [proEmailTmp]);
      list.push(domainGroup);
      isFree ? listKeysFree.push(proEmailTmp.hosting_id) : listKeysPaid.push(proEmailTmp.hosting_id);
    } else {
      let isAdded = false;
      list.forEach((emailFree) => {
        if (emailFree.has(proEmailTmp.hosting_id)) {
          list.map(domainGroup => (
            domainGroup.has(proEmailTmp.hosting_id)
            && !domainGroup.get(proEmailTmp.hosting_id).includes(proEmailTmp)
            && domainGroup.get(proEmailTmp.hosting_id).push(proEmailTmp)));
          isAdded = true;
        }
      });
      if (!isAdded) {
        domainGroup.set(proEmailTmp.hosting_id, [proEmailTmp]);
        list.push(domainGroup);
        isFree ? listKeysFree.push(proEmailTmp.hosting_id) : listKeysPaid.push(proEmailTmp.hosting_id);
      }
    }
  };

  const preencherLista = (list, proEmailTmp, isFree) => {
    preencherGrupoDominios(list, proEmailTmp, isFree);
  };

  renderedEmailList.map(proEmail => (
    (proEmail.type !== 'cpanel' && proEmail.plan !== 'free') && preencherLista(listEmailsPaid, proEmail, false)));

  renderedEmailList.map(proEmail => (
    (proEmail.type === 'cpanel' || proEmail.plan === 'free') && preencherLista(listEmailsFree, proEmail, true)));

  const [enableTrialState, setEnableTrialState] = useState({
    modalIsOpen: false,
    isEnabledByURLParam: false,
    hideBanner: true,
    currentDomain: {
      id: null,
      domain: null,
    },
  });

  const enableTrialByBannerGAEventsByAction = useCallback((action) => {
    executeWithParams(
      'gaEvent',
      'e-mail',
      'Banner trial de Titan - lista de e-mail com domínios',
      action,
    );
  }, []);

  const shouldShowBannerToActivateTrial = useCallback(() => enableActivationTitanByBanner
    && domainWithoutEmail.length > 0
    && enableTrialState.hideBanner,
  [enableActivationTitanByBanner, enableTrialState.hideBanner, domainWithoutEmail.length]);

  useEffect(() => {
    if (shouldShowBannerToActivateTrial()) {
      setEnableTrialState(previousEnableTrialState => ({
        ...previousEnableTrialState,
        hideBanner: false,
      }));
    }
  }, [shouldShowBannerToActivateTrial, setEnableTrialState]);

  const handlerChangeBannerSelect = useCallback(({ value, label }) => {
    enableTrialByBannerGAEventsByAction('Selecionar domínio');

    setEnableTrialState(previousEnableTrialState => ({
      ...previousEnableTrialState,
      modalIsOpen: true,
      currentDomain: { id: value, domain: label },
    }));
  }, [setEnableTrialState, enableTrialByBannerGAEventsByAction]);

  const handleCloseModal = useCallback(
    () => {
      enableTrialByBannerGAEventsByAction('Fechar modal');

      setEnableTrialState(previousEnableTrialState => ({
        ...previousEnableTrialState,
        modalIsOpen: false,
        currentDomain: { id: null, domain: null },
      }));
    }, [setEnableTrialState, enableTrialByBannerGAEventsByAction],
  );

  const handleSubmitToEnableTitanTrial = useCallback(({ emailAddress, password, offerContext }) => {
    enableTrialByBannerGAEventsByAction('Iniciar trial');

    const payload = {
      email: emailAddress,
      password,
      domain: enableTrialState.currentDomain.domain.replace('@', ''),
      isAdmin: true,
      offerContext,
    };

    dispatch(emailsActions.emails.createEmailTrial.request(payload));

    handleCloseModal();
  }, [enableTrialState.currentDomain.domain, enableTrialByBannerGAEventsByAction, dispatch, handleCloseModal]);

  const userTryLookPasswordInModalTrial = useCallback(() => {
    enableTrialByBannerGAEventsByAction('Visualização de senha');
  }, [enableTrialByBannerGAEventsByAction]);


  const urlTermsOfService = useMemo(() => {
    const mapUrlByCountry = {
      br: 'https://www.hostgator.com.br/termos-de-servico',
      mx: 'https://www.hostgator.mx/terminos',
      cl: 'https://www.hostgator.cl/terminos',
      co: 'https://www.hostgator.co/terminos',
    };

    return mapUrlByCountry[COUNTRY];
  }, []);

  const [essentialData, setEssentialData] = useState({ name: '', price: '' });

  const getDataOfEssential = useCallback(() => dispatch(
    requestUpgradePlans(),
  ).then(({ data }) => {
    const [planData] = data.filter(plan => plan.id === ESSENTIALS_PLAN);
    let dataEssential = { name: '', price: '' };
    if (planData) {
      const { name, configoptions } = planData;
      const [{ monthly: price }] = configoptions;

      dataEssential = { name, price };

      if (isBRServer) {
        dataEssential.price = price.replace('.', ',');
      }
    }

    return dataEssential;
  }), [dispatch]);
  const domainByUrl = queryParams(location.search, 'dspActtrial');

  useEffect(() => {
    if (domainWithoutEmail.length > 0) {
      getDataOfEssential().then(setEssentialData);
    }
  }, [getDataOfEssential, domainWithoutEmail.length]);


  const displayTrialTitanModalByUrl = useCallback(() => {
    if (domainByUrl !== null && !isLoaded && enableTrialTitanByUrlParam) {
      setEnableTrialState(previousEnableTrialState => ({
        ...previousEnableTrialState,
        modalIsOpen: true,
        isEnabledByURLParam: true,
        currentDomain: { id: domainWithoutEmail.filter(item => (item.domain === domainByUrl)).id, domain: domainByUrl },
      }));
    }
  }, [isLoaded, domainByUrl, domainWithoutEmail, enableTrialTitanByUrlParam]);

  useEffect(() => {
    displayTrialTitanModalByUrl();
  }, [displayTrialTitanModalByUrl]);

  const onBannerLoaded = useCallback(() => {
    if (!isLoaded) {
      executeGADisplayBannerWithDomains();
      setIsLoaded(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flowToSellTitanByBanner = useMemo(() => {
    if (!enableTrialState.hideBanner && essentialData.name && domainWithoutEmail.length > 0) {
      return (
        <WrapperBannerTrial>

          { shouldRenderColorfulModal ? (
            <ModalTitanTrialColorful
              isOpen={enableTrialState.modalIsOpen}
              onClose={handleCloseModal}
              isEnabledByURLParam={enableTrialState.isEnabledByURLParam}
              selectedDomain={enableTrialState.currentDomain}
              essentialData={essentialData}
              offerContext={['email_list_page_small_blurb']}
              location="modalEmailList"
            />
          ) : (
            <ModalToEnableTitanTrial
              isOpen={enableTrialState.modalIsOpen}
              onClose={handleCloseModal}
              handleSubmit={handleSubmitToEnableTitanTrial}
              selectedDomain={enableTrialState.currentDomain}
              userTryLookPassword={userTryLookPasswordInModalTrial}
              urlTermsOfService={urlTermsOfService}
              essentialData={essentialData}
              offerContext={['email_list_page_small_blurb']}
            />
          )}
          <BannerToEnableTitanTrial
            domainOptions={domainWithoutEmailFormattedToOptions}
            onDomainSelected={handlerChangeBannerSelect}
            productName={essentialData.name}
            onBannerLoaded={onBannerLoaded}
          />
        </WrapperBannerTrial>
      );
    }

    return null;
  }, [
    domainWithoutEmail.length,
    domainWithoutEmailFormattedToOptions,
    enableTrialState,
    essentialData,
    handleCloseModal,
    handlerChangeBannerSelect,
    handleSubmitToEnableTitanTrial,
    onBannerLoaded,
    urlTermsOfService,
    userTryLookPasswordInModalTrial,
    shouldRenderColorfulModal,
  ]);


  if (renderedEmailList.length > 0 && !enableCreateFirstAccount) {
    return (
      <>

        {flowToSellTitanByBanner}

        {listEmailsPaid.length > 0 && (
          <>
            <ExpansionPanel
              elevation={0}
              expanded={paidExpanded === true}
              onChange={handleChange(!paidExpanded)}
              className={classes.expandedPanel}
            >
              <ExpansionPanelSummary
                id="panel1bh-header"
                expandIcon={isMobile && <ExpandMore className={classes.expander} />}
                classes={{
                  root: classes.rootPanel,
                  expanded: classes.rootPanelExpanded,
                }}
              >
                <div>
                  <Typography className={isMobile ? classes.cardTitleMobile : classes.cardTitle}>
                    {!isMobile && (
                    <img src={IconStar} alt={t('sitelock.alt')} className={classes.logo} />
                    )}
                    <div className={classes.freeHostingLabel}>
                      {t('proEmail.professionalTitanMailLabel')}
                      {!isMobile && (
                      <Tooltip
                        placement="right"
                        className={classes.toolTip}
                        title={t('proEmail.infoPaidMailLabel')}
                        aria-label={t('proEmail.upgradePlan.remnantCreditExplanation')}
                      >
                        <InfoIcon className={classes.info} />
                      </Tooltip>
                      )}
                    </div>
                  </Typography>

                </div>
                {!isMobile && (
                <div className={classes.divShowMoreOrLess}>
                  <Typography className={classes.moreLessLabel}>
                    {paidExpanded ? t('proEmail.showLess') : t('proEmail.showMore')}
                    {paidExpanded ? <IconDropdownArrow color={theme.palette.primary.main} /> : <ExpandMore />}
                  </Typography>
                </div>
                )}

              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.details }}>
                <div className={classes.details}>
                  {listEmailsPaid.slice(0, visiblePaid).map((listDomains, index) => (
                    <ProEmailCard
                      reactivateAutoRenovationCycle={reactivateAutoRenovationCycle}
                      listEmailDomains={sortByStatus(listDomains.get(listKeysPaid[index]))}
                      proEmail={listDomains.get(listKeysPaid[index])[0]}
                      key={`${listDomains.get(listKeysPaid[index])[0].name}${listDomains.get(listKeysPaid[index])[0].id}`}
                    />
                  ))}

                  <ActionButton
                    disabled={listEmailsPaid.length <= visiblePaid}
                    className={listEmailsPaid.length <= visiblePaid
                      ? classes.loadingMoreButtonDisabled : classes.loadingMoreButton}
                    action={loadMorePaid}
                    data-testid="btn-loadmore-watchagain"
                    data-id="loadmore_button"
                  >
                    {t('btn.loadMore')}
                  </ActionButton>

                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <hr className={classes.line} />

          </>
        )}

        {listEmailsFree.length > 0 && (
        <>
          <ExpansionPanel
            elevation={0}
            expanded={freeExpanded === true}
            onChange={handleChangeFree(!freeExpanded)}
            className={classes.expandedPanel}
          >
            <ExpansionPanelSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              expandIcon={isMobile && <ExpandMore className={classes.expander} />}
              classes={{
                root: classes.rootPanel,
                expanded: classes.rootPanelExpanded,
              }}
            >
              <div>
                <Typography className={isMobile ? classes.cardTitleMobile : classes.cardTitle}>
                  {!isMobile && (
                  <div>
                    <img src={AccountTreeBlack} alt={t('sitelock.alt')} className={classes.logo} />
                    <img src={BlueBackGround} alt={t('sitelock.alt')} className={classes.logo} />
                  </div>
                  )}
                  <div className={classes.freeHostingLabel}>
                    {t('proEmail.freeMailLabel')}
                    {!isMobile && (
                    <Tooltip
                      className={classes.toolTip}
                      placement="right"
                      title={t('proEmail.infoFreeMailLabel')}
                      aria-label={t('proEmail.upgradePlan.remnantCreditExplanation')}
                    >
                      <InfoIcon className={classes.info} />
                    </Tooltip>
                    )}
                  </div>
                </Typography>
              </div>
              {!isMobile && (
              <div className={classes.divShowMoreOrLess}>
                <Typography className={classes.moreLessLabel}>
                  {freeExpanded ? t('proEmail.showLess') : t('proEmail.showMore')}
                  {freeExpanded ? <IconDropdownArrow color={theme.palette.primary.main} /> : <ExpandMore />}
                </Typography>
              </div>
              )}

            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.details }}>
              <div className={classes.details}>
                {listEmailsFree.slice(0, visibleFree).map((listDomains, index) => (
                  <ProEmailCard
                    reactivateAutoRenovationCycle={reactivateAutoRenovationCycle}
                    listEmailDomains={sortByStatus(listDomains.get(listKeysFree[index]))}
                    proEmail={listDomains.get(listKeysFree[index])[0]}
                    key={`${listDomains.get(listKeysFree[index])[0].name}${listDomains.get(listKeysFree[index])[0].id}`}
                  />
                ))}

                <ActionButton
                  disabled={listEmailsFree.length <= visibleFree}
                  className={listEmailsFree.length <= visibleFree ? classes.loadingMoreButtonDisabled : classes.loadingMoreButton}
                  action={loadMoreFree}
                  data-testid="btn-loadmore-watchagain"
                  data-id="loadmore_button"
                >
                  {t('btn.loadMore')}
                </ActionButton>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr className={classes.line} />

        </>
        )
  }
      </>
    );
  }

  if (enableCreateFirstAccount) {
    return (
      <ProEmailActiveTrial renderedEmailList={domainWithoutEmail} location="list" />
    );
  }

  return (
    <Card
      elevation={1}
      className={classnames(classes.container, classes.card)}
      data-testid="proEmailCard"
    >
      <Typography className={classes.emptyCardTitle}>{t('proEmail.emptyList.title')}</Typography>
      <Typography className={classes.emptyCardDescription}>{t('proEmail.emptyList.description')}</Typography>
    </Card>
  );
};

export default withI18n()(withStyles(styles)(ProEmailsList));
