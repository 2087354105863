import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import * as Styles from './TextButton.styles';

const TextButton = ({
  href,
  onClick,
  target = '_self',
  testId = 'link',
  text = 'Link description',
  title = '',
  variant = 'default',
  historyRedirect = false,
}) => {
  const history = useHistory();
  const handleOnClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ href, event }) => {
      event.preventDefault();
      event.stopPropagation();

      onClick && onClick();

      if (href && href !== '#') {
        if (target === '_blank') {
          return window.open(href, target);
        }

        if (historyRedirect) {
          history.push(href);
        } else {
          window.location.href = href;
        }
      }
    },
    [onClick, target, historyRedirect, history],
  );

  return (
    <Styles.Anchor
      data-testid={testId}
      href={href}
      onClick={event => handleOnClick({ href, event })}
      target={target}
      title={title}
      variant={variant}
    >
      {text}
    </Styles.Anchor>
  );
};

export default TextButton;
