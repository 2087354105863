const styles = theme => ({
  container: {
    paddingTop: '20px',
    minHeight: '80vh',
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
  },
});

export default styles;
