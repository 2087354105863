import styled, { css } from 'styled-components';

export const AsaasSitesAdvertisementWrapper = styled.div`
  ${({ theme, wizardVariant }) => css`
    background-color: ${theme.v1.color.white.primary};
    border: solid 1px ${theme.v1.color.background.wizard};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: ${theme.v1.font.family.primary};
    gap: 16px;
    margin-top: ${wizardVariant ? '25px' : '0px'};
    padding: 0px;
    position:relative;
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme, wizardVariant }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: ${wizardVariant ? '29px 0px 0px 20px' : '24px 24px 0px 24px'};
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: ${wizardVariant ? '42px 0px 8px 14px' : '18px 18px 0px 18px'};
    }
  `}
`;

export const HeaderContentWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 8px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      align-items: start;
      flex-direction: column;
      gap: 11px;
    }
  `}
`;

export const FooterWrapper = styled.div`
${({ theme, wizardVariant }) => css`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: ${wizardVariant ? '0px 24px 56px 20px' : '0px 24px 56px 24px'};
    @media (min-width: ${theme.v1.breakpoints.md}) {
      padding: ${wizardVariant ? '0px 24px 30px 20px' : '0px 24px 30px 24px'};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: ${wizardVariant ? '0px 24px 30px 14px' : '0px 24px 56px 18px'};
    }
  `}
`;

export const Title = styled.h5`
  ${({ theme, wizardVariant }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 19px;
    margin: 0;
    padding: 0;
    padding-top: ${wizardVariant ? '0px' : '8px'}
    text-align: left;
    text-transform: uppercase;
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      max-width: ${wizardVariant ? 'unset' : '180px'};
    }
    @media (max-width: 670px) {
      max-width: ${wizardVariant ? '188px' : '180px'};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      max-width: 207px;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme, wizardVariant }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    margin: 0;
    max-width: ${wizardVariant ? '633px' : '361px'};
    padding: 0;
    text-align: left;
    span {
      font-weight: ${theme.v1.font.weights.bold};
    }
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      max-width: ${wizardVariant ? '400px' : '200px'};
    }
    @media (max-width: 670px) {
      max-width: ${wizardVariant ? '227px' : '200px'};
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      max-width: 239px;
    }
  `}
`;

export const Chip = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.warning.pending};
    border-radius: 16px;
    color: ${theme.v1.color.white.primary};
    display: flex;
    font-size: 12px;
    height: 22px;
    letter-spacing: 0.01px;
    line-height: 14px;
    padding: 0 10px;
    font-weight: ${theme.v1.font.weights.bold};
    white-space: nowrap;

    @media (min-width: ${theme.v1.breakpoints.md}) {
      max-width: 430px;
    }
    
  `}
`;

export const HeaderDeco = styled.img`
  ${({ theme, wizardVariant }) => css`
    position: absolute;
    right: ${wizardVariant ? '56px' : '8px'};
    top: 19px;
    @media (max-width: 1366px) {
      width: 110px;
    }
    @media (max-width: ${theme.v1.breakpoints.lg}) {
      right: 8px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: ${wizardVariant ? '116px' : '131px'};
      top: ${wizardVariant ? '8px' : '19px'};
    }
  `}
`;

export const DecoTriangle = styled.div`
  ${({ theme }) => css`
    background:linear-gradient(to bottom right,transparent 50%,${theme.v1.color.background.wizard} 50%);
    bottom: 0;
    height: 110px;
    position:absolute;
    right: 0;
    width: 140px;
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      width: 143px;
    }
  `}
`;

export const AsaasLogo = styled.img`
  bottom: 24px;
  position: absolute;
  right: 16px;
`;
