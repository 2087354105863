import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';


const Loader = ({
  classes,
  fullscreen,
  positionFixed = false,
  size,
  classesToProps,
  dataId,
  ...res
}) => {
  const getFullScreenClass = () => (positionFixed ? classes.fullscreenPositionFixed : classes.fullscreen);

  return (
    <div {...res} className={classNames(fullscreen ? getFullScreenClass() : classes.loader, classesToProps)}>
      <CircularProgress size={size || 40} className={classNames(classes.progress, fullscreen && classes.progressFull)} data-id={dataId} />
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  classesToProps: PropTypes.string,
  fullscreen: PropTypes.bool,
  positionFixed: PropTypes.bool,

};

export default withStyles(styles)(Loader);
