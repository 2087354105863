import React from 'react';
import * as Style from './IconDrupal.style';

const IconDrupal = ({
  color = '#4a4a4a',
  width = '26',
  height = '30',
  testId = 'icon-drupal',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="_x31_19-drupal" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26.19 30.011" fill={color}>
      <g id="Grupo_39660" data-name="Grupo 39660" transform="translate(0)">
        <g id="Grupo_39659" data-name="Grupo 39659">
          <g id="Grupo_39658" data-name="Grupo 39658">
            <g id="Grupo_39657" data-name="Grupo 39657">
              <path id="Caminho_247728" data-name="Caminho 247728" d="M173.936,313.033a4.737,4.737,0,1,0,4.663,4.736,4.7,4.7,0,0,0-4.663-4.736Zm0,0" transform="translate(-161.618 -294.45)" />
              <path id="Caminho_247729" data-name="Caminho 247729" d="M318.085,188.733a8.241,8.241,0,0,1-1.212,12.337,13.559,13.559,0,0,0,8.459-7.7c1.974-4.924.133-8.627-2.946-11.986a6.171,6.171,0,0,1,.15,1.349,6.24,6.24,0,0,1-4.45,6Zm0,0" transform="translate(-299.96 -171.058)" />
              <path id="Caminho_247730" data-name="Caminho 247730" d="M252.1,148.879a3.57,3.57,0,1,0,3.575-3.622,3.6,3.6,0,0,0-3.575,3.622Zm0,0" transform="translate(-239.246 -137.197)" />
              <path id="Caminho_247731" data-name="Caminho 247731" d="M53.388,45.326A8.211,8.211,0,0,1,51.5,40.069a8.1,8.1,0,0,1,7.079-8.1,6.32,6.32,0,0,1-1.087-3.541,6.157,6.157,0,0,1,6.974-6.183,47.565,47.565,0,0,1-5.6-5.5c.8,8.553-7.671,5.445-10.814,13.335a12.566,12.566,0,0,0,5.337,15.24Zm0,0" transform="translate(-47.218 -16.751)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconDrupal;
