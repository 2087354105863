import React from 'react';
import {
  Alert, Button, IconArrow, IconLoading, SelectField,
} from 'gatorcomponents';
import * as Styles from './DomainOriginalDestinationStep.styles';
import PropTypes from 'prop-types';

export const DomainOriginalDestinationStep = ({
  subtitle,
  alertDescription,
  domain,
  inputLabel,
  optionsList,
  selectedManualDns,
  setSelectedManualDns,
  backLabel,
  continueLabel,
  onHowToSetupClick,
  onCancelClick,
  selectPlaceholder,
  loading,
}) => {
  const handleGoNext = () => {
    onHowToSetupClick && onHowToSetupClick();
  };

  const handleCancel = () => {
    onCancelClick && onCancelClick();
  };

  return (
    <Styles.Wrapper data="DomainOriginalDestinationStep">

      <Styles.Subtitle data-testid="subtitle">
        {subtitle}
      </Styles.Subtitle>

      <Styles.AlertWrapper>
        <Alert
          testId="alert"
          description={alertDescription(domain)}
        />
      </Styles.AlertWrapper>

      <Styles.InpuWrapper>
        <Styles.InputLabel data-testid="inputLabel">
          {inputLabel}
        </Styles.InputLabel>
        <SelectField
          size="md"
          placeholder={selectPlaceholder}
          options={optionsList}
          value={selectedManualDns}
          setValue={label => setSelectedManualDns(label)}
        />
      </Styles.InpuWrapper>

      <Styles.ActionsRow>
        <Button
          onClick={handleCancel}
          testId="back"
          size="large"
          label={backLabel}
          variant="tertiary"
          disabled={loading}
        />
        <Button
          onClick={handleGoNext}
          testId="next"
          size="large"
          label={loading ? <IconLoading /> : continueLabel}
          disabled={!selectedManualDns || loading}
          iconRight={<IconArrow direction="right" />}
        />
      </Styles.ActionsRow>
    </Styles.Wrapper>
  );
};

DomainOriginalDestinationStep.propTypes = {
  subtitle: PropTypes.string.isRequired,
  alertDescription: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedManualDns: PropTypes.string,
  setSelectedManualDns: PropTypes.func.isRequired,
  backLabel: PropTypes.string.isRequired,
  continueLabel: PropTypes.string.isRequired,
  onHowToSetupClick: PropTypes.func.isRequired,
  selectPlaceholder: PropTypes.string.isRequired,
};
export default DomainOriginalDestinationStep;
