import React from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import FeedbackNewSite from '@/media/newsite/icons/FeedbackNewSite';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';


const NewSiteFeedbackCard = ({
  classes,
  title,
  href,
  t,
}) => (
  <div className={classes.card}>
    <FeedbackNewSite className={classes.image} />
    <div className={classes.wrapperDescription}>
      <p className={classes.title}>{title}</p>
      <LinkButton to={href} className={classes.link} target="_blank">{t('newsite.feedbackLink')}</LinkButton>
    </div>
  </div>
);

export default withI18n()(withStyles(styles)(NewSiteFeedbackCard));
