import styled, { css } from 'styled-components';

export const HostGatorOtherPlatformModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 16px 8px 16px 8px;
    @media (min-width: ${theme.breakpoints.sm}) {
      margin: 16px;
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}
`;

export const HelpWrapper = styled.div`
  ${({ theme }) => css`
    display: block;
    margin-top: -12px;

    > p {
      color: ${theme.v1.color.text.primary};
      display: contents;
      font-family: ${theme.v1.font.family.primary};
      font-size: 12px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 16px;
      margin: 0;
      word-break: break-word;

      a {
        font-weight: ${theme.v1.font.weights.medium};
      }
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeights.xxxxxl};
    margin: 0;
    max-width: 90%;
  `}
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    line-height: ${theme.font.lineHeights.lg};
    font-weight: ${theme.font.weights.regular};
    margin: 0;
    > span{
      width: 100%;
      display: block;
      margin-top: 8px;
      font-size: ${theme.font.sizes.xxs};
    }
    > div {
      display: inline;
      margin-left: 3px;
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-top: 16px;
      > p {
        color: ${theme.palette.grey.dark};
        font-family: ${theme.font.family.secondary};
        font-size: ${theme.font.sizes.xxs};
        line-height: ${theme.font.lineHeights.md};
        font-weight: ${theme.font.weights.regular};
        margin: 0;
        > span{
          width: 100%;
          display: block;
          margin-top: 8px;
          font-size: ${theme.font.sizes.xxs};
        }
      }
  `}
`;

export const NameServerInputs = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ position }) => css`
    align-self: ${position === 'left' ? 'flex-start' : 'center'} ;
  `}
`;
