export const checkoutProductAdapter = (response) => {
  const isProductsArray = response && response.data && response.data.products;

  if (isProductsArray) {
    const product = response && response.data && response.data.products && response.data.products[0];
    const tld = response && response.data && response.data.tld;
    const newData = {
      product,
      tld,
    };

    return {
      data: newData,
    };
  }

  return response;
};
