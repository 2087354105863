import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.grey.ultralight};
    padding: 24px 32px;
    margin: 24px 0px;
  `}
`;

export const ContentDoubts = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxs};
    text-align: center;
  `}
`;


export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.blue.darkest};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.v1.font.weights.bold};
    margin: 0 0 8px 0;
`}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;
    padding: 0px;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;
export const Item = styled.li`
  ${() => css`
    list-style-type:none;
    padding: 0 0 16px 0;

    &:last-child{
      padding: 0;
    }

   `}
`;

export const Link = styled.a`
${({ theme }) => css`
  color: ${theme.palette.secondary.primary};
  margin-right: 5px;
  text-decoration: underline;
`}
`;
