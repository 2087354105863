const styles = theme => ({
  AlertBox: {
    marginTop: '16px',
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    fontSize: '12px',
    color: theme.color.tundora,
    border: `${theme.color.brightSun} 1px solid`,
    borderRadius: '5px',
  },
});

export default styles;
