import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 42px 0px 0px;
`;

export const ModalTitle = styled.h4`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 4px auto 0px;
    max-width: 489px;
    text-align: center;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      font-size: ${theme.v2.font.sizes.sm};
      text-align: center;
    }
  `}
`;

export const ModalDescription = styled.p`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 4px 16px 0px;
    text-align: center;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      font-size: ${theme.v2.font.sizes.xxs};
      margin: 4px 4px 0px;
      max-width: 320px;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-evenly;
    margin: 40px 30px;
    padding: 0px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      justify-content: flex-start;
      max-width: 320px;
    }
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    gap: 4px;
    list-style: none;
    max-width: 180px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      max-width: unset;
    }

    svg {
      flex: 0 0 24px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0px 0px 16px;
`;
