export default {
  title: 'Confirme su celular',
  subtitle: 'Para su seguridad, le enviaremos un código de confirmación al siguiente número:',
  codeNotRecived: '¿No ha recibido el código?',
  phoneAgreement: 'Acepto recibir comunicaciones vía SMS/WhatsApp',
  resendText: 'Reenviar en ',
  verifyCodeForm: {
    title: 'Introduzca el código ',
    subtitle: phone => `Enviamos un código vía SMS al teléfono ${phone}. Ingrese en el siguiente campo el código recibido para completar el proceso.`,
    placeholder: 'Código',
  },
  changePhoneNumber: {
    title: 'Introduzca un nuevo número',
    subtitle: 'Atención, solo se guardará después de enviar el código de seguridad y validación.',
    text: 'Tu número de celular de registro será actualizado para este nuevo celular.',
    passwordDescription: 'Introduzca la contraseña de acceso al Portal del Cliente. Esta información es necesaria para su seguridad.',
    passwordLabel: 'Contraseña',
  },
  updateNumber: 'Cambie su número de registro',
  attention: 'Atención, solo se guardará después de enviar el código de seguridad y la validación.',
  phone: 'Celular',
  phonePlaceholder: '00 0000 0000',
  password: 'Contraseña del Portal del Cliente',
  sendCode: 'Enviar código de validación',
  typePassword: 'Digite su contraseña',
  codeSended: phoneNumber => `Enviamos un código vía SMS al número de celular ${phoneNumber}. Ingrese el código recibido en el campo a continuación para completar el proceso.`,
  insertCode: 'Ingrese el código recibido',
  codePlaceholder: 'Código',
  notReceive: '¿No recibió el código?',
  requestOtherCode: 'Podrá solicitar un nuevo código en',
  changePhone: 'Cambiar celular',
  resentCode: 'Reenviar código',
  emptyPhoneNumber: {
    title: 'Valida tu número de celular',
    subtitle: 'Informa un nuevo número de celular para que enviemos el código de confirmación.',
    text: 'Este número de celular será agregado a tu cuenta.',
  },
  buttons: {
    cancel: 'Volver',
    sendCode: 'Enviar código',
    change: 'Enviar Código',
    sendLoading: 'Enviando...',
    resend: 'Reenviar código',
    resendingLoading: 'Reenviando...',
    changePhoneNumber: 'Cambiar celular',
    validate: 'Validar código',
  },
  errors: {
    invalidCode: 'Introduzca un código válido',
    requiredCode: 'Campo obligatorio',
    invalidPhoneNumber: 'Introduzca un número válido',
    passwordMinimumCharacters: 'La contraseña debe tener un mínimo de 6 caracteres.',
    countryCode: 'El país seleccionado no permite el envío de SMS. Le pedimos que informe un teléfono de otro país, o descarte la validación del teléfono en su cuenta.',
  },
  feedback: {
    resendCodeSuccess: 'Reenviamos. Por favor, verifica tu celular nuevamente.',
    resendCodeError: 'Inténtelo de nuevo más tarde o cambie el número de su celular',
    sendCodeSuccess: 'Enviamos el código por SMS al teléfono provisto.',
    sendCodeError: 'Inténtelo de nuevo más tarde o cambie el número de su celular',
    noMoreAttempts: () => 'Ha excedido la cantidad de solicitudes en 24 horas, inténtelo de nuevo mañana',
    smsSendAttempts: ({ attempts, used }) => `Ha solicitado ${used} código${used > 1 ? 's' : ''} en las últimas 24 horas, quedan ${attempts} solicitudes`,
    alreadyValidated: ({ hours, date }) => `Teléfono confirmado hace menos de 24 horas, puede cambiar este número a las ${hours} horas del día ${date}.`,
    validateCodeSuccess: 'Número de celular validado con éxito!',
    validateCodeError: 'Intenta nuevamente más tarde o cambia el número de celular',
    changePhoneNumberSuccess: 'Celular validado con éxito.',
    changePhoneNumberError: 'Inténtelo de nuevo más tarde o cambie el número de su celular',
    password: 'Contraseña invalida',
    dataInUse: 'El celular informado ya está en uso en su registro. Para continuar, informe otro número de celular.',
    invalidToken: 'Código incorrecto. Introduce el código recibido por mensaje de texto.',
    genericError: '¡Ops! Algo salió mal. Por favor, inténtalo de nuevo',
  },
};
