export default {
  billing: 'Facturas',
  changePassword: 'Cambiar Contraseña',
  accountSecurity: 'Seguridad de la Cuenta',
  courses: 'Cursos',
  creditCard: 'Gestionar Tarjeta',
  domains: 'Dominios',
  emails: 'Correos',
  help: 'Soporte',
  hireProduct: 'Contratar Producto / Servicio',
  manageUsers: 'Gestionar Usuarios',
  mktPlace: 'Ferramentas e Serviços',
  myData: 'Mis Datos',
  newBilling: 'Facturas y Suscripciones',
  manageSubscription: 'Administrar Suscripción',
  subscriptions: 'Suscripciones',
  products: 'Productos',
  quit: 'Salir',
  servicesStatus: 'Status de los Servicios',
  sites: 'Mis sitios web',
  sitesPage: 'Sitios Web',
  sitesPlans: 'Sitios Web y Planes',
  stopBeta: 'Dejar de usar la Versión Beta',
  support: 'Soporte',
  academyPass: 'Cursos',
  profile: 'Perfil',
  linkNaBio: 'Enlace en la bio',
  v2: {
    products: 'Hospedajes y Servidores',
    createSiteWithPartners: {
      title: {
        0: 'Faça seu site ',
        1: 'do jeito que você imaginou!',
      },
      description: {
        0: 'Conte com um ',
        1: 'especialista em criação de sites ',
        2: 'para o seu projeto ou faça você mesmo. :)',
      },
      buttonCreate: 'Criar com um especialista',
      buttonClose: 'Criar eu mesmo',
    },
  },
  myAlerts: 'Mis avisos',
  allNotifications: 'Todos',
  ticketNotifications: 'Tickets',
  seeMore: 'Ver más',
  alertsTooltip: 'Cuando se completa la situación de una advertencia, desaparece de este área.',
  noNotifications: 'No tienes nuevas notificaciones 😊',
};
