import backupBR from './backup.br';
import backupCL from './backup.cl';
import backupCO from './backup.co';
import backupMX from './backup.mx';

const locales = {
  BR: backupBR,
  CL: backupCL,
  CO: backupCO,
  MX: backupMX,
};

export default locales;
