
import { addDays, format } from 'date-fns';
import PropTypes from 'prop-types';

const formatDateFns = ({ productDate, additionalDays }) => format(addDays(new Date(productDate), additionalDays), 'dd/MM/yyyy');

formatDateFns.propTypes = {
  productDate: PropTypes.string.isRequired,
  additionalDays: PropTypes.number,
};

formatDateFns.defaultProps = {
  additionalDays: 0,
};

export default formatDateFns;
