import React, { useCallback, useState } from 'react';
import * as Styles from './GoogleAdwordsCoupon.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';

export const GoogleAdwordsCoupon = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { googleAdwordsCoupon: googleAdwordsCouponLocale } = ticketsLocale.forms;

  const [termsAgreed, setTermsAgreed] = useState(false);

  const checkForm = useCallback(() => {
    let isOk = false;

    if (termsAgreed) {
      isOk = true;
    }

    return !isOk;
  }, [termsAgreed]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      key: V2Forms.GOOGLE_ADWORDS_COUPON,
      tags: [
        'whmcs_form_cupom_google_adwords',
        selectedProduct.serverhostname || '',
        selectedProduct.name.toLowerCase().replace(' ', '_'),
      ],
      root: {
        name: 'techsupport',
      },
      subject: `${ticketsLocale.openNewTicketPage.textManipulation.startsWith.technical} - ${ticketsLocale.forms.names.googleAdwordsCoupon}`,
      body: `
       • ${googleAdwordsCouponLocale.bodyFields.product}: ${selectedProduct.name} (${selectedProduct.domain}) - ${googleAdwordsCouponLocale.bodyFields.user}: ${selectedProduct.username}
       • ${googleAdwordsCouponLocale.bodyFields.server}: ${selectedProduct.serverhostname || ''}
       • ${googleAdwordsCouponLocale.bodyFields.solicitation}: ${ticketsLocale.forms.names.googleAdwordsCoupon}
       • ${googleAdwordsCouponLocale.bodyFields.agree}: ${termsAgreed ? 'Sim' : 'Não'}
       • ${googleAdwordsCouponLocale.bodyFields.productId}: ${selectedProduct.id}
      `,
    };

    if (termsAgreed) {
      submitForm && submitForm(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitForm,
    selectedProduct,
    termsAgreed,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {googleAdwordsCouponLocale.info}
      </Styles.Info>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => setTermsAgreed(!termsAgreed)}
      >
        <Checkbox checked={termsAgreed} id="agreed" />
        <Styles.Text>
          {googleAdwordsCouponLocale.checkbox}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : googleAdwordsCouponLocale.sendButtonLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default GoogleAdwordsCoupon;
