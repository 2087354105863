import React from 'react';
import * as Style from './IconError.style';

const IconError = ({ color = '#4480c5', size = '14', testId = 'icon-error' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 14 14">
      <path id="error_black_24dp" d="M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Zm.7,10.5H8.3V11.1H9.7Zm0-2.8H8.3V5.5H9.7Z" transform="translate(-2 -2)" />
    </svg>
  </Style.Wrapper>
);


export default IconError;
