import React from 'react';
import * as Style from './IconDetailedPlugin.style';

const IconDetailedPlugin = ({
  color = '#4a4a4a',
  backgroundColor = '#ffcf00',
  size = '30',
  testId = 'icon-detailed-plugin',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32.089 31.598">
      <g id="Grupo_39944" data-name="Grupo 39944" transform="translate(-305.411 -1836)">
        <circle id="Elipse_1164" data-name="Elipse 1164" cx="10" cy="10" r="10" transform="translate(306 1836)" fill={backgroundColor} />
        <g id="icon-plugins" transform="translate(306.911 1866.098) rotate(-90)">
          <path id="Caminho_244404" data-name="Caminho 244404" d="M4.117,11.977a4.155,4.155,0,0,1,2.281.552V6.2h7.846a3.737,3.737,0,0,1-.618-2.247,4.121,4.121,0,0,1,8.235,0A3.735,3.735,0,0,1,21.243,6.2h7.846v7.189a4.15,4.15,0,0,0-2.338-.589,4.037,4.037,0,0,0-4.118,3.95,4.036,4.036,0,0,0,4.118,3.95,4.15,4.15,0,0,0,2.338-.589v7.856H20.4a3.737,3.737,0,0,0,.658-2.3,4.121,4.121,0,0,0-8.235,0,3.737,3.737,0,0,0,.659,2.3H6.4V19.325a4.154,4.154,0,0,1-2.281.552A4.036,4.036,0,0,1,0,15.927,4.036,4.036,0,0,1,4.117,11.977Z" transform="translate(27.964) rotate(90)" fill="none" stroke="#4a4a4a" strokeWidth="3" />
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedPlugin;
