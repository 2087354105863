import { collect } from '@/analytics';

const EVENT = {
  NAME: 'gaEvent',
  CATEGORY: {
    BACKUP_BUTTON: 'Solução - Portal do Cliente fluxo de backup - Botão Fazer Backup',
    MODAL_CPANEL_BACKUP_BUTTON: 'Solução - Portal do Cliente fluxo de backup - Exibição da modal',
    GENERAL_VISION: 'Solução - Portal do Cliente fluxo de backup - Clique na visão geral',
    ACCORDION_FAQ: 'Solução - Portal do Cliente fluxo de backup - duvidas e respostas',
    BACKUP_TAB: 'Solução - Portal do Cliente fluxo de backup - Exibição aba fazer backup',
    BACKUP_GENERATION_TAB: 'Solução - Portal do Cliente fluxo de backup - Aba fazer backup',
    RESTORE_TAB: 'Solução - Portal do Cliente fluxo de backup - Exibição aba restaurar backup',
    RESTORE: 'Solução - Portal do Cliente fluxo de backup - Fazer upload',
    LOG_TAB: 'Solução - Portal do Cliente fluxo de backup - Exibição aba últimos backups',
    CPANEL_TAB: 'Solução - Portal do Cliente fluxo de backup - Clique na aba backup pelo cpanel',
    BACKUP_BUTTON_PRODUCT_LIST: 'Solução - Portal do Cliente fluxo de backup - Botão Backup',
    INITIAL_BUTTON: 'Botão Inicial',
    RESTORATION_FLOW: 'Fluxo de Restauração',
    GENERATE_FLOW: 'Fluxo gerar um backup',
  },
  ACTION: {
    BACKUP_BUTTON_CLICK: 'Solução - Clique no botão - Clique no botão Fazer backup',
    MODAL_SHOW: 'Solução - Exibição da modal - Exibição da modal fazer backup',
    GENERAL_VISION: 'Solução - Visão geral de backup - Exibição da visão geral',
    ACCORDION_HOW_TO_DO: 'Solução - duvidas e respostas - Clique no acordeão como fazer um backup',
    ACCORDION_HOW_TO_RESTORE: 'Solução - duvidas e respostas   - Clique no acordeão como restaurar um backup',
    ACCORDION_UPDATED_BACKUP: 'Solução - duvidas e respostas   - Clique no acordeão por que é importante manter meu backup atualizado',
    ACCORDION_MAX_SIZE: 'Solução - duvidas e respostas   - Clique no acordeão qual tamanho máximo do meu armazenamento',
    ACCORDION_BACKUP_TYPES: 'Solução - duvidas e respostas   - Clique no acordeão quais são os tipos de backups',
    BACKUP_TAB: 'Solução - fazer backup  - Clique na aba fazer backup',
    BACKUP_BUTTON_ON_TAB_BACKUP: 'Solução - Aba fazer backup  - Clique no botão fazer backup',
    BACKUP_GENERATION_SUCCESS: 'Solução - Aba fazer backup  - Feedback backup gerado exibido em tela',
    BACKUP_GENERATION_ERROR: 'Solução - Aba fazer backup  - Feedback erro ao fazer backup exibido em tela',
    RESTORE_TAB: 'Solução - restaurar backup - Clique na aba restaurar backup',
    RESTORE: 'Solução - Fazer upload - Clique no cta fazer upload',
    LOG_TAB: 'Solução - últimos backups - Clique na aba últimos backups',
    CPANEL_TAB: 'Solução - backup pelo cpanel - Clique na aba backup pelo cpanel',
    BACKUP_BUTTON_PRODUCT_LIST: 'Solução - Clique no botão  - Clique no botão backup',
    BACKUP_ASSIST_FIRST_STEPS: 'Assistente de Backup, Primeiros Passos',
    FIRST_STEPS: 'Primeiros Passos',
    CTA_ASK_RESTORATION: 'CTA Solicitar restauração',
    CTA_CONTINUE: 'CTA Continuar',
    CTA_BACKUP_BY_CPANEL: 'CTA Fazer backup via cpanel',
    CTA_GENERATE_BACKUP: 'CTA Fazer backup',
  },
};

const generateBackup = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.INITIAL_BUTTON,
  gaEventAction: EVENT.ACTION.BACKUP_ASSIST_FIRST_STEPS,
  gaEventLabel: 'Gerar um Backup',
});

const restoreBackup = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.INITIAL_BUTTON,
  gaEventAction: EVENT.ACTION.BACKUP_ASSIST_FIRST_STEPS,
  gaEventLabel: 'Restaurar um Backup',
});

const haveFileClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.FIRST_STEPS,
  gaEventLabel: 'Botão: Sim, tenho o arquivo',
});

const hgFileClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.FIRST_STEPS,
  gaEventLabel: 'Botão: Não, quero usar um arquivo da Hostgator',
});

const fullHgFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_ASK_RESTORATION,
  gaEventLabel: 'Backup completo - Cliente quer usar arquivo Hostgator',
});

const rootHgFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_ASK_RESTORATION,
  gaEventLabel: 'Backup do diretório inicial - Cliente quer usar arquivo Hostgator',
});

const databaseHgFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_ASK_RESTORATION,
  gaEventLabel: 'Backup do Banco de dados - Cliente quer usar arquivo Hostgator',
});

const fullCustomerFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_ASK_RESTORATION,
  gaEventLabel: 'Backup completo - Sim tenho o arquivo',
});

const rootCustomerFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_CONTINUE,
  gaEventLabel: 'Backup do diretório inicial - Sim tenho o arquivo',
});

const databaseCustomerFileBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORATION_FLOW,
  gaEventAction: EVENT.ACTION.CTA_CONTINUE,
  gaEventLabel: 'Backup do Banco de dados - Sim tenho o arquivo',
});

const generateCompleteBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.GENERATE_FLOW,
  gaEventAction: EVENT.ACTION.CTA_BACKUP_BY_CPANEL,
  gaEventLabel: 'Gerar um backup completo',
});

const generateRootBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.GENERATE_FLOW,
  gaEventAction: EVENT.ACTION.CTA_GENERATE_BACKUP,
  gaEventLabel: 'Gerar um backup do diretório inicial',
});

const generateDatabaseBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.GENERATE_FLOW,
  gaEventAction: EVENT.ACTION.CTA_BACKUP_BY_CPANEL,
  gaEventLabel: 'Gerar um backup do banco de dados',
});

const backupButtonClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_BUTTON,
  gaEventAction: EVENT.ACTION.BACKUP_BUTTON_CLICK,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no botão Fazer backup',
});

const modalGoToCpanel = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.MODAL_CPANEL_BACKUP_BUTTON,
  gaEventAction: EVENT.ACTION.MODAL_SHOW,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no botão backup avançado cPanel',
});

const modalGoToBackup = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.MODAL_CPANEL_BACKUP_BUTTON,
  gaEventAction: EVENT.ACTION.MODAL_SHOW,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no botão backup portal do cliente',
});

const generalVision = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.GENERAL_VISION,
  gaEventAction: EVENT.ACTION.GENERAL_VISION,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique na visão geral',
});

const accordionHowToDo = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.ACCORDION_FAQ,
  gaEventAction: EVENT.ACTION.ACCORDION_HOW_TO_DO,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no acordeão como fazer um backup',
});

const accordionHowToRestore = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.ACCORDION_FAQ,
  gaEventAction: EVENT.ACTION.ACCORDION_HOW_TO_RESTORE,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no acordeão como restaurar um backup',
});

const accordionUpdatedBackup = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.ACCORDION_FAQ,
  gaEventAction: EVENT.ACTION.ACCORDION_UPDATED_BACKUP,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no acordeão por que é importante manter meu backup atualizado',
});

const accordionMaxSize = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.ACCORDION_FAQ,
  gaEventAction: EVENT.ACTION.ACCORDION_MAX_SIZE,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no acordeão qual tamanho máximo do meu armazenamento',
});

const accordionBackupTypes = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.ACCORDION_FAQ,
  gaEventAction: EVENT.ACTION.ACCORDION_BACKUP_TYPES,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no acordeão quais são os tipos de backups',
});

const clickOnDoBackupTab = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_TAB,
  gaEventAction: EVENT.ACTION.BACKUP_TAB,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique na aba fazer backup',
});

const backupButtonOnTabBackupClick = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_GENERATION_TAB,
  gaEventAction: EVENT.ACTION.BACKUP_BUTTON_ON_TAB_BACKUP,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no botão fazer backup',
});

const backupGenerationSuccess = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_GENERATION_TAB,
  gaEventAction: EVENT.ACTION.BACKUP_GENERATION_SUCCESS,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Feedback backup gerado exibido em tela',
});

const backupGenerationError = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_GENERATION_TAB,
  gaEventAction: EVENT.ACTION.BACKUP_GENERATION_ERROR,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Feedback erro ao fazer backup exibido em tela',
});

const clickRestoreTab = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORE_TAB,
  gaEventAction: EVENT.ACTION.RESTORE_TAB,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique na aba restaurar backup',
});

const clickRestore = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.RESTORE_TAB,
  gaEventAction: EVENT.ACTION.RESTORE_TAB,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no cta fazer upload',
});

const clickLogTab = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.LOG_TAB,
  gaEventAction: EVENT.ACTION.LOG_TAB,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique na aba últimos backups',
});

const clickCPanelTab = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.CPANEL_TAB,
  gaEventAction: EVENT.ACTION.CPANEL_TAB,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique na aba backup pelo cpanel',
});

const clickBackupButtonProductsList = () => collect({
  event: EVENT.NAME,
  gaEventCategory: EVENT.CATEGORY.BACKUP_BUTTON_PRODUCT_LIST,
  gaEventAction: EVENT.ACTION.BACKUP_BUTTON_PRODUCT_LIST,
  gaEventLabel: 'Solução - Portal do Cliente fluxo de backup - Clique no botão backup',
});

const analytics = {
  backupButtonClick,
  modalGoToCpanel,
  modalGoToBackup,
  generalVision,
  accordionHowToDo,
  accordionHowToRestore,
  accordionUpdatedBackup,
  accordionMaxSize,
  accordionBackupTypes,
  clickOnDoBackupTab,
  backupButtonOnTabBackupClick,
  backupGenerationSuccess,
  backupGenerationError,
  clickRestoreTab,
  clickRestore,
  clickLogTab,
  clickCPanelTab,
  clickBackupButtonProductsList,
  generateBackup,
  restoreBackup,
  haveFileClick,
  hgFileClick,
  fullHgFileBackupClick,
  rootHgFileBackupClick,
  databaseHgFileBackupClick,
  fullCustomerFileBackupClick,
  rootCustomerFileBackupClick,
  databaseCustomerFileBackupClick,
  generateCompleteBackupClick,
  generateRootBackupClick,
  generateDatabaseBackupClick,
};

export default analytics;
