import { FormsActionTypes } from './forms.types';

const sendForm = ({ data, useZendeskV2 }) => ({
  type: FormsActionTypes.POST_FORM,
  payload: { data, useZendeskV2 },
});

const setDefaultForceState = () => ({ type: FormsActionTypes.SET_DEFAULT_FORCE_STATE });
const setForceState = ({ key, params }) => ({
  type: FormsActionTypes.SET_FORCE_STATE,
  payload: {
    key,
    params,
  },
});

const getHgRoutineBackups = ({ id }) => ({ type: FormsActionTypes.REQUEST_HG_ROUTINE_BACKUPS, payload: { id } });
const setHgRoutineBackupsSuccess = () => ({ type: FormsActionTypes.SUCCESS_HG_ROUTINE_BACKUPS });
const setHgRoutineBackupsFaliure = () => ({ type: FormsActionTypes.FAILURE_HG_ROUTINE_BACKUPS });
const setHgRoutineBackupsLoadState = bool => ({ type: FormsActionTypes.SET_HG_BACKUP_HISTORY_LOAD_STATE, payload: bool });
const setHgRoutineBackups = payload => ({ type: FormsActionTypes.SET_HG_ROUTINE_BACKUPS, payload });
const skipHgRoutine = ({ id }) => ({ type: FormsActionTypes.SKIP_HG_ROUTINE_BACKUPS, payload: { id } });


const actions = {
  send: {
    request: sendForm,
  },
  forceState: {
    clear: setDefaultForceState,
    set: setForceState,
  },
  backupHgRoutine: {
    failure: setHgRoutineBackupsFaliure,
    request: getHgRoutineBackups,
    success: setHgRoutineBackupsSuccess,
    loading: setHgRoutineBackupsLoadState,
    set: setHgRoutineBackups,
    skipHgRoutine,
  },
};

export default actions;
