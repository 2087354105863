import { api } from '@/utils/api';
import { useSelector } from 'react-redux';
import { COUNTRY, config } from '@/config';

const { AWS_DNS_SERVICE_URL } = config;

const getPlanDns = (domainData) => {
  if (domainData) {
    const { ns1, ns2 } = domainData.data;
    return [ns1, ns2];
  }
};

const useCompareDns = () => {
  const { token } = useSelector(state => state.auth);
  const { jwt: jwtToken } = useSelector(state => state.auth);
  const compareDns = async (domainLabel, planId) => {
    if (!domainLabel && !planId) return false;
    let planDns = [];
    let domainDns = [];

    const requestDomain = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      url: `${AWS_DNS_SERVICE_URL}/domains/${domainLabel}/whois`,
    };

    const requestHosting = {
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${COUNTRY}/product/hosting/${planId}`,
    };

    try {
      const result = await Promise.all([
        api.aws.get(requestDomain),
        api.whmcs.get(requestHosting),
      ]);
      const [responseDomain, responseHosting] = result;
      domainDns = responseDomain.data.domain.name_servers;
      planDns = getPlanDns(responseHosting.data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dns comparison error', e);
    }

    if ((planDns && planDns.length) && (domainDns && domainDns.length)) {
      const dnsAreEquals = planDns.every((value, i) => value === domainDns[i]);

      if (dnsAreEquals) return true;
    }

    return false;
  };

  return { compareDns };
};


export default useCompareDns;
