import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    position: relative;
  `}
`;

export const MobileWrapper = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    padding-bottom: 64px;
  `}
`;

export const NotificationsCircle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.v2.colors.feedback.pendingLight};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: 14px;
    justify-content: center;
    position: absolute;
    user-select: none;
    width: 14px;
    left: 38px;
    top: 12px;
  `}
`;

export const FloatingMenu = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    box-shadow: 0px 1px 3px ${theme.v1.color.shadow.primary};
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    min-width: 435px;
    overflow: auto;
    padding-bottom: 14px;
    position: absolute;
  `}
`;

export const FloatingMenuItem = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    box-shadow: 0px 1px 3px ${theme.v1.color.shadow.primary};
    display: flex;
    flex-direction: column;
    position: absolute;    fontSize: 12,
    color: 'gray',
    marginTop: 8,
  `}
`;

export const NotificationItem = styled.div`
  ${({ theme, isNew }) => css`
    align-items: flex-start;
    background: ${isNew ? theme.v2.colors.brand.primaryUltraLight : 'transparent'};
    display: flex;
    margin: 0px 0px;
    padding: 0px;
  `}
`;

export const Header = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding: 23px 24px 15px 24px;
  `}
`;

export const HeaderTop = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
  `}
`;

export const TitleContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 4px;
  `}
`;

export const CloseButton = styled.div`
  ${() => css`
    color: blue;
    cursor: pointer;
    padding: 20px;
    position: absolute;
    right: 0;
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    font-size: 14px;
    padding-bottom: 4px;
  `}
`;

export const SeeMoreContainer = styled.div`
  ${({ hideButton }) => css`
    align-items: center;
    display: ${hideButton ? 'none' : 'flex'};
    justify-content: center;
    font-size: 14px;
  `}
`;

export const EmptyState = styled.div`
  ${({ isMobile }) => css`
    padding: ${isMobile ? '60px' : '60px 134px 94px 134px'};
    text-align: center;
  `}
`;

export const Tabs = styled.div`
   display: flex;
   height: 35px;
   gap: 23px;

   button {
    padding: 0 !important;
    margin: 0;
   }
`;
