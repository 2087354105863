import styled, { css } from 'styled-components';
import noGiveup from '@/media/subscriptions/retention/noGiveup.svg';
import linha from '@/media/subscriptions/retention/linha.svg';

export const Wrapper = styled.div`
${({ theme }) => css`
margin: 0px 0px 8px 0px;

> div {
  margin-right: auto;
}
@media (max-width: ${theme.v2.breakpoints.md}) {
  height:auto;
    > div {
      }
}
@media (max-width: ${theme.v2.breakpoints.lg}) {
  width:  100%;
}
@media (max-width: ${theme.v2.breakpoints.sm}) {
  margin: 0px 0px 8px 0px;

}
@media (max-width: ${theme.v2.breakpoints.xs}) {
  margin: 0px 0px 8px 0px;
}
`}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.brand.primaryDark};
    color: ${theme.v2.colors.white.primary};
    display: flex;
    font-size: ${theme.v2.font.sizes.xs};
    height: 87px;
    justify-content: space-between;
    left: 0;
    padding: 0px;
    z-index: unset;

    > div {
      margin-right: auto;
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      height:auto;
        > div {
          padding: 24px 16px 24px 0px;
          }
    }
    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width:  100%;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {

    }
    @media (max-width: ${theme.v2.breakpoints.xs}) {
    }
    `}
  `;

export const ContentText = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryDark};
    color: ${theme.v2.colors.white.primary};
    display: flex;
    font-size: ${theme.v2.font.sizes.xs};
    justify-content: space-between;
    left: 0;
    margin: 0px 0px 0px 0px;
    padding: 0px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      display: block;
      height:auto;
      width: 100%;
    }

    @media (max-width: ${theme.v2.breakpoints.xs}) {
      width: 100%;
    }

  `}
`;

export const NoGiveUp = styled.div`
  align-self: flex-end;
  object-fit: contain;
  background-image: url('${noGiveup}');
  height:22px
`;


export const TopLine = styled.div`
  align-self: flex-end;
  object-fit: contain;
  background-image: url('${linha}');
  height: 4px
`;


export const GiftIcon = styled.img`
    align-self: flex-end;
    height: 88px;
    object-fit: contain;
    width: 65px;

`;

export const Info = styled.div`
  ${({ theme }) => css`
    max-width: fit-content;
    margin: 0px 0px 0px 8px;
    color: ${theme.v2.colors.white.primary};
    flex: 1;
    gap: 242px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
      gap: 2px;
      margin-bottom: 8px;
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 0px 0px 0px 0px;
    }
  `}
`;

export const ContentTitle = styled.div`
  display:flex;
`;

export const Title = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.white.primary};
  font-family: ${theme.v2.font.family.secondary};
  font-size: 16px;
  font-weight: ${theme.v2.font.weights.bold};
  margin-bottom: 8px;
  margin-top:auto;
  max-width: fit-content;
  letter-spacing: 0.01px;
    @media (max-width: ${theme.v2.breakpoints.md}) {
      text-align: justify;
    }
  `}
`;

export const Discount = styled.text`
${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activePure};
    font-weight: ${theme.v2.font.weights.bold};
    margin-left: 4px;
`}
`;

export const ContentDescription = styled.div`
  width: 100%;
  @media (max-width: 1260px) {
    display:block;
  }
`;

export const Description = styled.p`
  ${({ theme, variant }) => css`
  color: ${theme.v2.colors.white.primary};
  font-family: ${theme.v2.font.family.primary};
  font-size: ${variant === 'sites' ? theme.v2.font.sizes.xxs : theme.v2.font.sizes.xs};
  font-weight: ${theme.v2.font.weights.regular};
  letter-spacing: 0.01px;
  line-height: 1.4;
  margin: 0px;
  opacity: 1;
  text-align: start;
  width: max-content;

  @media (max-width: ${theme.v2.breakpoints.sm}) {
    width: auto;
  }

  `}
`;

export const ContentButton = styled.div`
${({ theme }) => css`
   margin: 0px 0px 0px 24px;
   > button {
     background: transparent;
     border-color: ${theme.v2.colors.feedback.activePure};
     color: ${theme.v2.colors.feedback.activePure};
     font-size: ${theme.v2.font.sizes.xxs};
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 16px 0px 0px 0px;
    }
    `}
  `;

export const CloseButton = styled.button`
${({ theme }) => css`
  align-items: center;
  align-self: flex-start;
  background-color: unset;
  border: unset;
  color: ${theme.v2.colors.white.primary};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 8px 8px 8px 0px;
  object-fit: contain;
  opacity: 1;
  padding: 0;
  width: 24px;
  `}
`;
