import styled, { css } from 'styled-components';

export const DnsWizardHeaderWrapper = styled.div`
  ${() => css`
    display: flex;
    gap: 8px;
  `}
`;

export const Title = styled.h2`
  ${({ theme, variation }) => {
    const color = variation !== 'default' ? theme.v1.color.text.primary : theme.v1.color.action.hover;

    return css`
    margin: 0px;
    color: ${color};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.v1.font.weights.bold};
    letter-spacing: -0.5px;

    > div {
      display: inline;
      margin-left: 3px;
    }
  `;
  }}
`;
