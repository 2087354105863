const styles = theme => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  headerTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    letterSpacing: '0.02px',
    marginTop: '28px',
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: '17px',
    letterSpacing: '0.02px',
    marginLeft: '24px',
    marginRight: '24px',
    marginTop: '14px',
    textAlign: 'center',
  },
  video: {
    marginTop: 23,
    width: '560px',
    height: '315px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
});

export default styles;
