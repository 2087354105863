import styled, { css } from 'styled-components';
import { Button } from '@/pages/common/v1/Button/Button.styles';
import { Wrapper as Radio, Dot as RadioDot } from '@/pages/common/inputs/Radio/Radio.styles';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};;
    font-family: ${theme.v2.font.family.primary};
    padding: 32px;
  `}
`;

export const Data = styled.div`
  ${({ hasInfo }) => css`
    padding-top: ${hasInfo ? '32px' : '0px'};
  `}
`;

export const ContentInvoiceDetails = styled.div`
${() => css`
  padding-top: 32px;
`}
`;

export const ContentFingerprint = styled.iframe`
${() => css`
  width: 100px;
  height: 100px;
  border: 0;
  position: absolute;
  top: -5000px;
`}
`;

export const Header = styled.header``;

export const Title = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};;
    font-family: ${theme.v2.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
    padding: 0;
  `}
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 0;
`;

export const OptionList = styled.ul`
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
`;

export const BlockoOptionItem = styled.div`
  display: flex;

  @media (max-width: 600px) {
    margin-top: 16px;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

export const OptionItem = styled.div`
  ${({ checked }) => css`
    background-size: contain;
    border-radius: ${checked ? 0 : '2px'};
    padding-right: 24px;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const OptionItemWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
  `}
`;

export const OptionRadio = styled.div`
  ${({ theme, checked }) => css`
    ${Radio} {
      border-color: ${checked ? theme.v2.colors.neutral.lowDark : theme.v2.colors.neutral.lowLight};
    }

    ${RadioDot} {
      background-color: ${checked ? theme.v2.colors.neutral.lowDark : theme.v2.colors.neutral.lowLight};
    }
  `}
`;

export const OptionContent = styled.div`
  padding-left: 8px;
`;

export const OptionLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: -0.19px;
    transition: color 200ms ease-in-out;
  `}
`;

export const OptionDescription = styled.p`
  font-size: 14px;
`;

export const Controls = styled.div`
${({ isPix }) => css`
  display: flex;
  justify-content: center;
  margin-top: ${isPix ? '27px' : '32px'};
  `}
`;

export const ControlButton = styled.div`
  ${() => css`
    &:not(:first-child) {
      margin-left: 12px;
    }

    @media (max-width: 600px) {
      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  `}
`;

export const BlockItemResume = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
     > div {
        margin: 0px 32px 32px 0px;
      }
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 32px;
    }

    @media (max-width: 600px) {
      &:not(:first-child) {
        margin-left: 0;
      }
    }
`;

export const ItemDiscount = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 32px;
    width: max-content;

    @media (max-width: 600px) {
      &:not(:first-child) {
        margin-left: 0;
      }
    }
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};;
    display: inline-flex;
    font-size: 12px;
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 16px;
    width: max-content;
  `}
`;
export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};;
    font-size: 14px;
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
    width: max-content;

  `}
`;

export const DiscountText = styled.text`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};;
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
    width: max-content;

    > strong {
      padding: 0px 4px 0px 4px;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      display: flow;
    }

  `}
`;

export const WrapperData = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v2.font.family.primary};
    padding: 16px 24px 16px 24px;
  `}
`;

export const WrapperCreditCard = styled.div`
${({ theme }) => css`
 > div {
  > div {
    > div {
      > div {
          padding: 0px 24px 16px 16px;
          @media (max-width:  ${theme.v1.breakpoints.sm}) {
            padding: 0px 8px 16px 8px;
          }
       }
      }
    }
 }
 `}
`;

export const WrapperInfo = styled.section`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.wizard};
    font-family: ${theme.v2.font.family.primary};
    justify-content: center;
    padding: 16px 24px 16px 24px;
    text-align:center;
  `}
`;
export const WrapperInfoPix = styled.section`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v1.color.background.wizard};
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size:  ${theme.v2.font.sizes.xxs};
    justify-content: center;
    padding: 16px 24px 16px 24px;
    text-align: left;
  `}
`;
export const DescriptionInfo = styled.p`
      font-size:12px;
      margin: 8px 0px 0px 0px;
`;
export const ContentCloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;
export const ContentData = styled.div`
  ${({ theme }) => css`
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }
    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;
export const ContentPaymentSelector = styled.div`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowDark};;
  font-family: ${theme.v2.font.family.primary};
  font-weight: ${theme.v1.font.weights.medium};
  padding: 32px 0px 0px 0px;

  `}
`;

export const ContentOptionsInSelectord = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};;
    display:flex;
    font-family: ${theme.v2.font.family.primary};
    justify-content: flex-start;
    padding-top: 16px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:contents;
    }

    `}
`;

export const ContentDoubts = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: 12px;
    text-align: center;
    z-index: 99;
  `}
`;

export const Chip = styled.span`
    margin-left:8px;
    position: relative;
    top: -2px;
`;

export const ContentInfoSucess = styled.div`
  ${({ theme, hasSplitInfo }) => css`
  background-color: ${theme.v2.colors.feedback.activeUltralight};
  display: flex;
  height: auto;
  margin: ${hasSplitInfo ? '0px 0px 8px 0px' : '0px'};
  padding: 16px 16px 16px 16px;
  text-align: left;

  @media (max-width: ${theme.v2.breakpoints.sm}){

    margin-top: 0px;
    padding: 16px;
    text-align: left;
    width: 100%;
  }

`}
`;

export const DescriptionInfoSuccess = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activeMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;
    padding: 0px 0px 0px 8px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      text-align: left;
      width: 100%;
    }
  `}
`;

export const ContentInfoSplitPayment = styled.div`
  ${({ theme }) => css`
  background-color: ${theme.v2.colors.brand.primaryUltraLight};
  display: flex;
  height: auto;
  padding: 16px 16px 16px 16px;
  text-align: left;

  @media (max-width: ${theme.v2.breakpoints.sm}){

    margin-top: 0px;
    padding: 16px;
    text-align: left;
    width: 100%;
  }

`}
`;

export const DescriptionInfoSplitPayment = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;
    padding: 0px 0px 0px 8px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      text-align: left;
      width: 100%;
    }
    span {
      font-weight: ${theme.v2.font.weights.bold};
    }
    a {
      font-size: 14px;
    }
  `}
`;

export const ContentIcon = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 24px;
`;


export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 14px;
    padding: 0px 0px 24px 0px;

    @media (max-width: ${theme.v1.breakpoints.sm}) {
      margin: 32px 0px 0px 0px;
      text-align: initial;

    }
  `}
`;

export const LineThrough = styled.span`
    text-decoration: line-through;
`;

export const TagWrapper = styled.div`
${({ theme }) => css`

> div {
  background-color: ${theme.v2.colors.feedback.activePure};
  border-radius: 4px;
  font-weight: ${theme.v2.font.weights.bold};
  padding: 4px 8px;
}
`}
`;
