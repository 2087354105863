import styled, { css } from 'styled-components';
import listDecoration from '@/media/asaas/Asaas-list-decoration.svg';

export const FormContent = styled.div`
`;

export const FormTtitle = styled.h4`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.featured};
      font-family: ${theme.v1.font.family.primary};
      font-size: 24px;
      font-weight: ${theme.v1.font.weights.bold};
      line-height: 32.4px;
      margin: 32px 0px 8px 0px;
    `}
`;

export const FormDescription = styled.p`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 19px;
      margin: 0px 0px 24px 0px;
    `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    input:read-only {
      background-color: ${theme.v1.color.background.wizard} !important;
      cursor: not-allowed;
    }

    @media(max-width: ${theme.v1.breakpoints.md}){
      gap: 8px;
    }
  `}
`;

export const CepWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    @media (max-width: ${theme.v1.breakpoints.md}){
      gap: 8px;
    }
  `}
`;

export const LoaderPosition = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    min-width: 165px;
    position: relative;

    > span {
      width: 100%;
    }
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 14px;

    * {
      padding: 0;
      margin: 0;
    }
`;

export const AnchoMyCEP = styled.a`
  ${({ theme }) => css`
    align-self: flex-start;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin-top: 8px;
    text-decoration: underline;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 12px;
      line-height: 16px;
    }
  `}
`;

export const NumberComplementWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const CheckboxWrapper = styled.label`
    ${({ theme }) => css`
      align-items: center;
      color: ${theme.v1.color.text.primary};
      cursor: pointer;
      display: flex;
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 19px;
      margin: 0px;
      padding: 0px;
      width: fit-content;

      > span { 
        margin: 0px 11px 0px 0px;
      }
    `}
`;

export const AnchorAgreement = styled.a`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin-left: 4px;
    text-decoration: underline;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      font-size: 12px;
      line-height: 16px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 32px;
    margin: 24px auto;
    max-width: fit-content;
`;

export const BackButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;

    > span {
      transform: rotate(180deg);
    }
`;

export const AccessWrapper = styled.div`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 19px;
      margin: 0px auto;
      max-width: fit-content;

      a { 
        color: ${theme.v1.color.action.primary};
        font-weight: ${theme.v1.font.weights.medium};
      }
    `}
`;

export const DescriptionList = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    margin: auto 0 0 0;
    padding: 0;
    display: none;
    visibility: hidden;

    @media (max-width: ${theme.v1.breakpoints.md}){
      display: block;
      visibility: visible;
    }
  `}
`;

export const DescriptionItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;

    &:before {
      align-self: center;
      background-image: url(${listDecoration});
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 11px;
      margin-right: 10px;
      min-width: 14px;
    }
  `}
`;
