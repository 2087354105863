import React from 'react';
import { Slider } from '@/pages/common/Slider';
import TitanUpgradePlanItem from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem';

import * as Styles from './SelectHirePlan.styles';
import { useResize } from '@/hooks/useResize';

const SelectHirePlan = ({
  domainObject,
  freePlanActivated = false,
  idHosting,
  idProEmail,
  isHostingSelected,
  paymentCycle,
  planOptions,
  setPaymentCycle,
  handleOpenModalTitanTrialColorful,
  enableTitanUltra,
}) => {
  const view = useResize();

  const setVisibleSlides = () => {
    let amount = 4;

    if (view.width < 1580) {
      amount = 3;
    }

    if (view.width <= 1343) {
      amount = 2;
    }

    if (view.width <= 1034) {
      amount = 1;
    }

    return amount;
  };


  const settings = {
    initialSlide: freePlanActivated ? 1 : 0,
    slidesToShow: setVisibleSlides(),
    speed: 500,
    dots: true,
    infinite: false,
    arrows: true,
    slidesToScroll: 1,
  };

  const sliderItems = () => planOptions.length >= 1 && planOptions.map(option => (

    <TitanUpgradePlanItem
      plan={option}
      key={`plan_${option.id}`}
      domainObject={domainObject}
      cycle={paymentCycle}
      setCycle={setPaymentCycle}
      idHosting={idHosting}
      isHostingSelected={isHostingSelected}
      idProEmail={idProEmail}
      handleOpenModalTitanTrialColorful={handleOpenModalTitanTrialColorful}
      enableTitanUltra={enableTitanUltra}
    />

  ));

  return (
    <Styles.Card data-testid="selectPlanToHire">
      <Slider
        settings={settings}
        items={sliderItems()}
      />
    </Styles.Card>
  );
};

export default SelectHirePlan;
