import {
  REQUEST_ACADEMY_PASS_COURSES_LIST,
  RECEIVE_ACADEMY_PASS_COURSES_LIST,
  ABORT_ACADEMY_PASS_COURSES_LIST,
  ERROR_ACADEMY_PASS_COURSES_LIST,
  REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST,
  RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST,
  ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST,
  ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST,
  REQUEST_ACADEMY_PASS_TOKENS,
  RECEIVE_ACADEMY_PASS_TOKENS,
  ABORT_ACADEMY_PASS_TOKENS,
  ERROR_ACADEMY_PASS_TOKENS,
  REQUEST_ACADEMY_PASS_PAYMENT_METHOD,
  RECEIVE_ACADEMY_PASS_PAYMENT_METHOD,
  ABORT_ACADEMY_PASS_PAYMENT_METHOD,
  ERROR_ACADEMY_PASS_PAYMENT_METHOD,
  REQUEST_ACADEMY_PASS_PRICE,
  RECEIVE_ACADEMY_PASS_PRICE,
  ABORT_ACADEMY_PASS_PRICE,
  ERROR_ACADEMY_PASS_PRICE,
  REQUEST_ACADEMY_PASS_DETAILS,
  RECEIVE_ACADEMY_PASS_DETAILS,
  ABORT_ACADEMY_PASS_DETAILS,
  ERROR_ACADEMY_PASS_DETAILS,
  REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT,
  CLEAR_REDIRECT,
  CLEAR_MESSAGES,
} from '@/redux/actions/actionsTypes';

const initialState = {
  courses: {
    isLoading: false,
    loadedDate: null,
    aborted: false,
    error: false,
    coursesList: null,
    isPassActive: true,
  },
  tokens: {
    isLoading: false,
    loadedDate: null,
    aborted: false,
    error: false,
    jwt: null,
    userId: null,
  },
  cta: {
    isLoadingPrice: false,
    isLoadingPayment: false,
    priceLoadedDate: null,
    paymentLoadedDate: null,
    aborted: false,
    error: false,
    price: [],
    paymentMethods: [],
  },
  buyFastcheckout: {
    isLoading: false,
    loadedDate: null,
    aborted: false,
    error: false,
    invoiceId: null,
    redirectToInvoices: false,
  },
  detail: {
    isLoading: false,
    data: null,
  },
};

export default function academyPass(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ACADEMY_PASS_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: true,
          loadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_ACADEMY_PASS_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
          coursesList: action.data.productList,
          isPassActive: action.data.isPassActive,
        },
      };
    case ABORT_ACADEMY_PASS_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_ACADEMY_PASS_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_ACADEMY_PASS_FILTERED_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: true,
          loadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_ACADEMY_PASS_FILTERED_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
          coursesList: action.data,
        },
      };
    case ABORT_ACADEMY_PASS_FILTERED_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_ACADEMY_PASS_FILTERED_COURSES_LIST:
      return {
        ...state,
        courses: {
          ...state.courses,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_ACADEMY_PASS_TOKENS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          isLoading: true,
          aborted: false,
          loadedDate: Date.now(),
        },
      };
    case RECEIVE_ACADEMY_PASS_TOKENS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: null,
          jwt: action.data.jwt ? action.data.jwt : null,
          userId: action.data.userId ? action.data.userId : null,
        },
      };
    case ABORT_ACADEMY_PASS_TOKENS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
        },
      };
    case ERROR_ACADEMY_PASS_TOKENS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_ACADEMY_PASS_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrice: true,
          priceLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_ACADEMY_PASS_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrice: false,
          priceLoadedDate: null,
          aborted: false,
          error: false,
          price: action.data.data.product.prices.pricing.monthly,
        },
      };
    case ABORT_ACADEMY_PASS_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrice: false,
          priceLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_ACADEMY_PASS_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrice: false,
          priceLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_ACADEMY_PASS_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: true,
          paymentLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_ACADEMY_PASS_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: false,
          paymentMethods: Object.entries(action.data.data).filter(paymentMethod => paymentMethod[1].selectable),
        },
      };
    case ABORT_ACADEMY_PASS_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_ACADEMY_PASS_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_ACADEMY_PASS_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: true,
          loadedDate: Date.now(),
          aborted: false,
          error: false,
          redirectToInvoices: false,
        },
      };
    case RECEIVE_ACADEMY_PASS_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
          invoiceId: action.data.invoice_id,
          paymentMethod: action.data.paymentMethod,
          redirectToInvoices: true,
        },
      };
    case ABORT_ACADEMY_PASS_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: true,
          error: false,
          redirectToInvoices: false,
        },
      };
    case ERROR_ACADEMY_PASS_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
          redirectToInvoices: false,
        },
      };
    case REQUEST_ACADEMY_PASS_DETAILS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
        },
      };
    case RECEIVE_ACADEMY_PASS_DETAILS:
      return {
        ...state,
        detail: {
          isLoading: false,
          data: [...action.data],
        },
      };
    case ABORT_ACADEMY_PASS_DETAILS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
        },
      };
    case ERROR_ACADEMY_PASS_DETAILS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
        },
      };
    case CLEAR_REDIRECT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          redirectToInvoices: false,
        },
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        courses: {
          ...state.courses,
          aborted: false,
          error: false,
        },
        tokens: {
          ...state.tokens,
          aborted: false,
          error: false,
        },
        cta: {
          ...state.cta,
          aborted: false,
          error: false,
        },
        buyFastcheckout: {
          ...state.buyFastcheckout,
          aborted: false,
          error: false,
        },
      };
    default:
      return state;
  }
}
