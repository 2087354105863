import React from 'react';
import * as Style from './IconArrowLeft.style';

const IconArrowLeft = ({
  color = '#4480c5',
  testId = 'icon-arrow-left',
  size = '24',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} fill={color} stroke={color} viewBox="0 0 15.12 17.12">
      <g id="download_2_" transform="translate(15) rotate(90)">
        <path id="Caminho_247558" data-name="Caminho 247558" d="M489.314,388.722a.771.771,0,0,0-1.04-.011l-3.617,3.314v-9.181a.738.738,0,0,0-1.472,0v9.227l-3.617-3.313a.771.771,0,0,0-1.04.01.657.657,0,0,0,.011.973l4.843,4.436a.762.762,0,0,0,.515.2h.025a.74.74,0,0,0,.568-.268l4.814-4.41a.657.657,0,0,0,.01-.977Z" transform="translate(-475.36 -380.705)" strokeWidth="1" />
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconArrowLeft;
