import React from 'react';
import {
  withStyles,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';

const VerificationCode = ({
  classes,
  t,
  handleValidateCode,
  loadingValidate,
  codeNumberError,
  updateField,
  values,
}) => (
  <>
    <form onSubmit={handleValidateCode}>
      <TextField
        id="codeInput"
        autoComplete="off"
        className={classes.formInput}
        type="text"
        name="codeInput"
        placeholder={t('phoneVerify.verifyCodeForm.placeholder')}
        margin="normal"
        variant="outlined"
        fullWidth
        value={values.codeInput.value}
        onChange={updateField}
        error={!!values.codeInput.error}
        helperText={values.codeInput.error}
      />
      <div className={classes.wrapperBoxLinks}>
        <PrimaryButton
          className={classes.button}
          type="submit"
          disabled={loadingValidate || !!codeNumberError}
        >
          {loadingValidate
            ? <CircularProgress color="inherit" size={24} />
            : t('phoneVerify.buttons.validate')
          }

        </PrimaryButton>
      </div>
    </form>
  </>
);

export default withI18n()(withStyles(styles)(VerificationCode));
