import HostGatorLogo from '@/media/mktPlace/logo/hostGator.svg';
import SiteLockLogo from '@/media/mktPlace/logo/siteLock.svg';
import CodeGuardLogo from '@/media/mktPlace/logo/codeGuard.svg';
import JivoChatLogo from '@/media/mktPlace/logo/jivoChat.svg';
import CanvaLogo from '@/media/mktPlace/logo/canva.svg';
import RdStation from '@/media/mktPlace/logo/rdStation.svg';
import cobreFacil from '@/media/mktPlace/logo/cobreFacil.svg';
import trakto from '@/media/mktPlace/logo/trakto.svg';
import altCreditas from '@/media/mktPlace/logo/creditas-alt.svg';
import asaas from '@/media/mktPlace/logo/asaas.svg';

export const cardTypes = {
  ALL: 0,
  FEATURED: 1,
  EMAIL: 2,
  MARKETING: 3,
  SITEBUILDER: 4,
  CHAT: 5,
  AUTOMATION: 6,
  SECURITY: 7,
  DESIGN: 8,
  FINANCES: 9,
  COURSES: 10,
};

export const cardItems = [
  {
    featured: true,
    altLayout: false,
    coloredBorder: true,
    country: 'br',
    name: 'Asaas',
    logo: asaas,
    type: [cardTypes.FINANCES, cardTypes.FEATURED],
    description: 'Cobre por boleto, cartão, links de pagamento e Pix. Faça a gestão financeira da sua loja online em um só lugar.',
    internLink: true,
    link: '/asaas',
  },
  {
    featured: true,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'Creditas',
    logo: altCreditas,
    type: [cardTypes.FINANCES, cardTypes.FEATURED],
    description: 'Faça seu negócio crescer com soluções de empréstimo facilitado e aproveite as menores taxas do mercado.',
    internLink: false,
    link: 'https://www.creditas.com/parceiros/hostgator-veiculo?utm_source=hostgator&utm_medium=affiliates&utm_campaign=lp',
  },
  {
    featured: true,
    altLayout: true,
    coloredBorder: false,
    country: 'br',
    name: 'Cobre Fácil',
    logo: cobreFacil,
    type: [cardTypes.FINANCES, cardTypes.FEATURED],
    description: 'Cobre e receba de seus clientes de forma automatizada, profissional e sem burocracia.',
    internLink: false,
    link: 'https://www.cobrefacil.com.br/hostgator?utm_source=hostgator&utm_medium=Mktplace-portal-card',
  },
  {
    featured: true,
    altLayout: true,
    coloredBorder: false,
    country: 'br',
    name: 'Trakto',
    logo: trakto,
    type: [cardTypes.DESIGN, cardTypes.FEATURED],
    description: 'O editor feito para o empreendedor vender nas redes. Crie mini-sites, artes para redes sociais, ebooks, apresentações.',
    internLink: false,
    link: 'https://pages.trakto.io/hostgator?utm_source=hostgator-marketplace&utm_campaign=hostgator-marketplace&utm_medium=hostgator-marketplace&utm_content=hostgator-marketplace',
  },
  {
    featured: false,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'Criador de sites',
    logo: HostGatorLogo,
    type: [cardTypes.SITEBUILDER, cardTypes.FEATURED],
    description: 'Crie seu site, blog ou loja online de forma rápida e intuitiva, sem precisar escrever nenhuma linha de código.',
    internLink: false,
    link: 'https://www.hostgator.com.br/criador-de-sites?utm_source=portal&utm_medium=marketplace&utm_campaign=criador_de_sites&utm_content=seo&utm_term=builder',
  },
  {
    featured: false,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'Site Lock',
    logo: SiteLockLogo,
    type: [cardTypes.SECURITY, cardTypes.FEATURED],
    description: 'Proteja o seu site de invasões e ataques hackers de forma simples e automática.',
    internLink: false,
    link: 'https://www.hostgator.com.br/sitelock?utm_source=portal&utm_medium=marketplace&utm_campaign=sitelock&utm_content=seguranca&utm_term=sitelock',
  },
  {
    featured: false,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'Code Guard',
    logo: CodeGuardLogo,
    type: [cardTypes.SECURITY, cardTypes.FEATURED],
    description: 'Faça backup das suas alterações de maneira rápida e eficiente, garantindo mais segurança para o seu site.',
    internLink: false,
    link: 'https://www.hostgator.com.br/backup-online?utm_source=portal&utm_medium=marketplace&utm_campaign=codeguard&utm_content=seguranca&utm_term=codeguard',
  },
  {
    featured: false,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'JivoChat',
    logo: JivoChatLogo,
    type: [cardTypes.CHAT],
    description: 'Converse e monitore visitantes em tempo real por chat online, Facebook messenger, telefone e e-mail.',
    internLink: false,
    link: 'https://www.jivochat.com.br/partners/hostgator/',
  },
  {
    featured: false,
    altLayout: false,
    coloredBorder: false,
    country: 'br',
    name: 'Canva',
    logo: CanvaLogo,
    type: [cardTypes.DESIGN],
    description: 'Crie imagens para redes socais, apresentações, pôsteres, documentos e diversos outros materiais gráficos.',
    internLink: false,
    link: 'https://partner.canva.com/q1Jeq ',
  },
  {
    featured: false,
    coloredBorder: false,
    country: 'br',
    name: 'Rd Station Marketing',
    logo: RdStation,
    type: [cardTypes.MARKETING],
    description: 'Impulsione suas vendas com Automação de Marketing, WhatsApp para sites e muitos outros recursos.',
    internLink: false,
    link: 'https://materiais.resultadosdigitais.com.br/hr-parceria-rd-hostgator',
  },
];
