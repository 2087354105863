import React from 'react';
import Slick from 'react-slick';
import * as Styles from './Slider.styles';

// YOU CAN VIEW MORE AT LIB DOC: https://react-slick.neostack.com/docs/get-started

const Slider = ({
  settings = {
    initialSlide: 0,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    infinite: true,
    arrows: true,
    slidesToScroll: 1,
  },
  items = [
    <div>Sample one</div>,
    <div>Sample two</div>,
    <div>Sample three</div>,
  ],
  maxWidth = '100%',
}) => (
  <Styles.SlickWrapper maxWidth={maxWidth}>
    <Slick
      {...settings}
    >
      {items}
    </Slick>
  </Styles.SlickWrapper>
);

export default Slider;
