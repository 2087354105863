import styled, { css, keyframes } from 'styled-components';

export const LinkNaBioGuide = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
`;

export const GuideTexts = styled.div`
  max-width: 140px;
`;

export const Image = styled.img`
    max-width: 56px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`;

export const GuideTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0;
  `};
`;

export const GuideDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `};
`;


const boxShadowGrowth = ({ theme }) => keyframes`
  0% {
    top: 53%;
    left: 56%;
    width: 0vh;
    height: 0vh;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 0vw ${theme.v2.colors.brand.primaryExtraDark}99;
    
    }
    100% {
      top: 0%;
      left: 0%;
      width: 98vw;
      height: 98vh;
      border-radius: 0%;
      box-shadow: 0px 0px 0px 120vw ${theme.v2.colors.brand.primaryExtraDark}99;
  }
`;


export const GuideShadow = styled.div`
  ${({ theme }) => css`
    animation: ${boxShadowGrowth} 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: absolute;
    background-color: ${theme.v2.colors.brand.primaryExtraDark}99;
    z-index: 99;
  `};
`;
