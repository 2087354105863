import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    margin: 0px 16px 18px 16px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 22px;
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.bold};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;


export const CheckboxWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-top: 4px;
`;

export const CheckboxDescription = styled.p`
${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    margin-left: 6px;
  `}
`;
