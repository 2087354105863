import React from 'react';
import { Button } from '@/pages/common/v1/Button';
import * as Styles from './AcademyMiddleSection.styles';

const AcademyMiddleSection = ({
  title,
  paragraph,
  imagePath,
  imageRight,
  isMarginRight,
  handleClick,
  academyPassTrialBuyButtonLabel,
}) => (
  <Styles.Container data-testid="container-middle-section">
    <Styles.IsMobileTitle>{title}</Styles.IsMobileTitle>
    <Styles.ContainerRight imageRight={imageRight}>
      <Styles.TextArea>
        <Styles.Title data-testid="title-middle-section">{title}</Styles.Title>
        <Styles.Paragraph data-testid="paragraph-middle-section">{paragraph}</Styles.Paragraph>
        <Styles.Btn data-testid="button-middle-section">
          <Button
            onClick={handleClick}
            label={academyPassTrialBuyButtonLabel}
          />
        </Styles.Btn>
      </Styles.TextArea>
      <Styles.Image data-testid="image-middle-section" src={imagePath} isMarginRight={isMarginRight} />
    </Styles.ContainerRight>
  </Styles.Container>
);

export default AcademyMiddleSection;
