import React from 'react';
import * as Style from './IconFeaturedStar.styles';

const IconFeaturedStar = ({ testId = 'IconHosting' }) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="Grupo_40677" data-name="Grupo 40677" transform="translate(-185 -8)">
        <path id="Path_6" data-name="Path 6" d="M0,0,36,36V0Z" transform="translate(185 8)" fill="#ffcf00" />
        <g id="Star" transform="translate(196.613 19.782)" fill="none">
          <path d="M6.715,10.323,2.867,12.45l.735-4.5L.489,4.756l4.3-.657L6.715,0,8.638,4.1l4.3.657L9.827,7.946l.735,4.5Z" stroke="none" />
          <path d="M 10.56185245513916 12.45019626617432 L 9.827082633972168 7.945666790008545 L 12.93963241577148 4.755546569824219 L 8.638192176818848 4.098346710205078 L 6.714532375335693 -3.297424427728401e-06 L 4.790872573852539 4.098346710205078 L 0.4894323647022247 4.755546569824219 L 3.601982355117798 7.945666790008545 L 2.867212295532227 12.45019626617432 L 6.714532375335693 10.32344627380371 L 10.56185245513916 12.45019626617432 M 14.51731204986572 18.06457710266113 L 6.714532375335693 13.751296043396 L -1.088247656822205 18.06457710266113 L 0.3973620533943176 8.957015037536621 L -5.735067844390869 2.671746730804443 L 2.755334615707397 1.374531149864197 L 6.714532375335693 -7.06053352355957 L 10.67372989654541 1.374531149864197 L 19.16413307189941 2.671746730804443 L 13.03170299530029 8.957015037536621 L 14.51731204986572 18.06457710266113 Z" stroke="none" fill="#1f2044" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconFeaturedStar;
