/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withI18n } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  IconButton, Typography, CardContent, CardActions, Collapse, withStyles, DialogActions,
} from '@material-ui/core';
import { Close, Receipt, ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { loadInvoiceDetails, updateStatusInvoiceToConfirmingPayment } from '@/redux/actions/invoices';
import { verifyAgreementClient } from '@/redux/actions/paypal';
import InvoiceDetails from '@/components/Invoices/InvoiceDetails';
import InvoiceSummaryCard from '@/components/Cards/InvoiceSummaryCard';
import WarningNotice from '@/components/Notification/WarningNotice/index';
import Loader from '@/components/Layout/Loader';
import PaypalButton from '@/components/Paypal/PaypalButton';
import PaypalCard from '@/components/Paypal/PaypalCard';
import { PAYMENT_STATUS } from '@/pages/Invoices/types';
import { cancelBuyPayPalByPortalClick } from '@/utils/ThirdParties/tagManager';
import styles from './styles';
import { PaypalChangeAccount } from '../PayPalChangeAccount';
import useLocale from '@/hooks/useLocale';
import { billingAnalytics } from '@/analytics';
import { PaypalActionTypes } from './Paypal.types';


const PaypalDialog = ({
  invoiceId,
  invoiceStatusMessage,
  close,
  classes,
  t,
  onPaymentExecuted,
  showResumeData = true,
  displayActionButton = true,
  updateAgreementPayPal,
  switchPaymentMethod,
  isSubscriptionsPage = false,
  enabledChangePayPalAccount,
  labelsLocale,
  callingOrigin = '',
}) => {
  const loadingPaypalPayment = useSelector(state => state.paypal.loadingPaypalPayment);
  const [showDetails, setShowDetails] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [loadAgreement, setLoadAgreement] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [loadingPaypalScript, setLoadingPaypalScript] = useState('');
  const [isCreateNewAccount, setCreateNewAccount] = useState(false);

  const [payerEmail, setpayerEmail] = useState('...');
  const [payerUserName, setPayerUserName] = useState('...');
  const isNewLayout = !showResumeData;
  const dispatch = useDispatch();
  const { billing: billingLocale } = useLocale();

  const [labelsChangeAccountModal, setLabelsChangeAccountModal] = useState(labelsLocale);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const isCallingModalByManagerInvoices = callingOrigin => [PaypalActionTypes.CALLING_BY_MANAGER_INVOICES, PaypalActionTypes.CALLING_BY_LIST_SUBSCRIPTIONS].includes(callingOrigin);

  useEffect(() => {
    if (!labelsChangeAccountModal) {
      const { manageSubscriptionPaymentInvoiceModal } = billingLocale;
      setLabelsChangeAccountModal(manageSubscriptionPaymentInvoiceModal);
    }
  }, []);

  const handleCheckAgreement = async () => {
    try {
      const {
        success, payerEmail, payerFirstName, payerLast,
      } = await dispatch(verifyAgreementClient());
      setAgreement(success);
      updateAgreementPayPal && updateAgreementPayPal(success);
      setLoadAgreement(true);
      setpayerEmail(payerEmail);
      setPayerUserName(`${payerFirstName} ${payerLast}`);
    } catch (error) {
      setAgreement(false);
    }
  };

  const handleExpand = async () => {
    setShowDetails(!showDetails);

    if (!Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      const data = await dispatch(loadInvoiceDetails(invoiceId));
      setInvoiceDetails(data.invoice);
    }
  };


  const handleSuccess = () => {
    close && close();
    onPaymentExecuted({ status: PAYMENT_STATUS.SUCCESS }, isCallingModalByManagerInvoices(callingOrigin) === PaypalActionTypes.CALLING_BY_MANAGER_INVOICES ? t('routes.invoices') : t('routes.subscriptions'));

    dispatch(updateStatusInvoiceToConfirmingPayment(invoiceId));
  };

  const handleError = () => {
    close();
  };

  useEffect(() => {
    handleCheckAgreement();
  }, []);

  const cancelHgAcademyGAEvent = async () => {
    if (!Object.prototype.hasOwnProperty.call(invoiceDetails, 'id')) {
      const data = await dispatch(loadInvoiceDetails(invoiceId));
      const invoiceDesc = data.invoice.items[0] ? data.invoice.items[0].description : '';
      const matched = invoiceDesc.match(/Hostgator Academy Pass/);
      if (matched && matched.length > 0) {
        cancelBuyPayPalByPortalClick();
      }
    }
  };

  const updatePaymentOptionByPayPal = (data) => {
    setCreateNewAccount(data === 'add_new');
    callingOrigin === PaypalActionTypes.CALLING_BY_SWITCH_PAYMENT_METHOD_MODAL && subscriptionAnalytics.changePaymentMethodClick(data === 'add_new' ? '3_usar_paypal_novo' : '3_usar_paypal_cadastrado');

    callingOrigin === PaypalActionTypes.CALLING_BY_PAYMENT_MODAL && subscriptionAnalytics.executePaymentClick(data === 'add_new' ? '3_usar_paypal_novo' : '3_usar_paypal_cadastrado');

    callingOrigin === PaypalActionTypes.CALLING_BY_MANAGER_INVOICES && subscriptionAnalytics.executePaymentInvoiceManagerClick(data === 'add_new' ? '3_usar_paypal_novo' : '3_usar_paypal_cadastrado');
  };

  const renderPayPalButton = () => (
    <PaypalButton
      onLoadingPaypalScript={loadingPaypalScript}
      onSetLoadingPaypalScript={setLoadingPaypalScript}
      isAgreement={agreement}
      isCreateNewAccount={isCreateNewAccount}
      invoiceId={invoiceId}
      onSuccess={handleSuccess}
      onError={handleError}
      isChangeAccountToPayment={isCreateNewAccount !== undefined}
      switchPaymentMethod={switchPaymentMethod}
      isSubscriptionsPage={isSubscriptionsPage}
    />
  );

  return (
    <Fragment>
      {showResumeData && (
      <DialogTitle id="responsive-dialog-title">
        <div className={classes.title}>
          <span>{t('dialogPaypal.title')}</span>
          <IconButton aria-label="Fechar" onClick={close} className={classes.iconClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      )}
      <DialogContent>
        {showResumeData && (
        <>
          <WarningNotice message={t('dialog.message')} fill="100%" />
          <InvoiceSummaryCard status={invoiceStatusMessage}>
            <CardContent className={classes.cardSummaryContent}>
              <Grid container spacing={8} className={classes.invoiceCardBox}>
                <div className={classes.cardFlexBox}>
                  <Receipt className="receiptIcon" />
                  <Typography>{t('dialog.invoiceDetails')}</Typography>
                </div>
                <CardActions>
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: showDetails,
                    })}
                    onClick={handleExpand}
                  >
                    <ExpandMore className={classes.arrow} />
                  </IconButton>
                </CardActions>
              </Grid>

              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Collapse in={showDetails} timeout="auto">
                    {invoiceDetails.id ? (
                      <CardContent>
                        <InvoiceDetails invoice={invoiceDetails} />
                      </CardContent>
                    ) : (
                      <Loader />
                    )}
                  </Collapse>
                </Grid>
              </Grid>
            </CardContent>
          </InvoiceSummaryCard>
        </>
        )}
        {enabledChangePayPalAccount && agreement && (
        <PaypalChangeAccount
          userName={payerUserName}
          userEmail={payerEmail}
          updatePaymentOptionByPayPal={updatePaymentOptionByPayPal}
          isSubscriptionsPage={isSubscriptionsPage}
          defaultAccountLabel={labelsChangeAccountModal.selectActuralPayPalAccountLabel}
          addNewAccountLabel={labelsChangeAccountModal.addNewPayPalAccountLabel}
          defaultInfoUseDefaultAccountLabel={labelsChangeAccountModal.infoAddNewAccountLable}
          selectActionPayPalLabel={labelsChangeAccountModal.selectAccountPaypalLabel}
          infoPaymentByPicPayLabel={labelsChangeAccountModal.infoPaymentByPicPayLabel}
          infoChangePaymentByPayPalLabel={labelsChangeAccountModal.infoChangePaymentByPayPalLabel}
        />
        )}
        { loadingPaypalPayment && (<Loader />) }
        {!isNewLayout && (
        <PaypalCard />
        )}
      </DialogContent>


      {isNewLayout && (
      <PaypalCard isNewLayout={isNewLayout} displayActionButton={displayActionButton} />
      )}

      { !loadingPaypalPayment && displayActionButton && (
      <DialogActions className={classes.dialogActions}>
        {loadingPaypalScript === 'finish' && showResumeData && displayActionButton && (
          <Button
            className={classes.btnOutline}
            onClick={() => { close(); cancelHgAcademyGAEvent(); }}
            variant="outlined"
          >
            {t('cancel')}
          </Button>
        )}
        {loadAgreement && !isCreateNewAccount && renderPayPalButton()}

        {isCreateNewAccount && renderPayPalButton()}
      </DialogActions>
      )}

    </Fragment>
  );
};

PaypalDialog.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  invoiceStatusMessage: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withI18n()(withMobileDialog()(PaypalDialog)),
);
