import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, maxWidth }) => css`
    bottom: 0;
    height: 0px;
    max-width: ${maxWidth || '513px'};
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 1000;

    @media (max-width: ${theme.breakpoints.xs}) {
      max-width: 300px;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ round, theme }) => css`
    align-items: center;
    background-color: ${round ? theme.palette.white.light : '0px'};
    border-radius: ${round ? '50%' : '0px'};
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: 16px;
    max-width: 24px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.xs}) {
      width: 95px;
    }
  `}
`;

export const Notification = styled.div`
  ${({ theme, maxWidth }) => css`
      align-items:center;
      animation-delay: 0s;
      animation-duration: 500ms;
      animation-iteration-count: 1;
      border-radius:2px;
      bottom: -100vh;
      display: flex;
      margin: 10px;
      max-width: ${maxWidth || '513px'};
      position: absolute;
      right: 0px;
      transition: 200ms ease-in-out;
      width: 100%;

      > div {
        width: 100%;
      }

      p {
        margin: 0px;
      }

      @media (max-width: ${theme.breakpoints.xs}) {
        max-width: 100%;
      }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    color:${theme.palette.white.light};
    font-size: 16px;
    letter-spacing: 0.01px;
    line-height: 20px;
    margin-right: 30px;
    margin: unset;
    padding: unset;

    p{
      color:${theme.palette.white.light};
      font-size: 16px;
      letter-spacing: 0.01px;
      line-height: 20px;
      margin: unset;
      padding: unset;
    }
  `}
`;

export const CloseButton = styled.div`
  background-color: transparent;
  border: unset;
  cursor: pointer;
  display: flex;
  margin-left: auto;
`;
