const styles = theme => ({
  titleCard: {
    backgroundColor: theme.color.white,
    boxShadow: '0px 1px 3px #00000029',
    display: 'flex',
    marginBottom: '6px',
    padding: '12px 24px',
  },

  redirectButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.color.indigo,
    cursor: 'pointer',
    marginRight: '16px',
    padding: 0,
  },

  actualPlanLabel: {
    color: theme.color.indigo,
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.01px',
    lineHeight: '19px',
  },

  actualPlan: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: '300',
    letterSpacing: '0.01px',
    lineHeight: '32px',
  },

  actualDomainSpan: {
    color: theme.color.regentGray,
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.01px',
    lineHeight: '24px',
    marginLeft: '9px',
  },
});
export default styles;
