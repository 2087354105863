import React from 'react';
import { IconComment } from '@/icons/iconComment';
import * as Styles from './ClosedFloatingSurvey.styles';
import * as theme from '@/styles/HgThemeProvider';

const ClosedFloatingSurvey = ({
  survey = {},
  toggleOpen = () => {},
  testId = 'closed-floating-survey',
}) => {
  const { title } = survey;
  return (
    <Styles.ClosedWrapper type="button" onClick={toggleOpen} data-testid={testId}>
      <Styles.VerticalText>{title}</Styles.VerticalText>
      <IconComment color="#FEFEFE" backgroundColor={theme.color.oceanGreen} />
    </Styles.ClosedWrapper>
  );
};

export default ClosedFloatingSurvey;
