import React from 'react';
import { Button } from 'gatorcomponents';
import { DNS_PROPAGATION_ARTICLE } from '@/config/urls/supportUrls';
import { TOS_STATUS } from '@/enums/preChat/preChat.enum';
import { ALERT_TYPES, ANALYSIS_STATUS } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';

export default {
  invoiceStaticMessage: ({ totalInvoice, sum, handleClick }) => (
    <>
      {'Você tem '}
      {totalInvoice}
      {' fatura(s) para pagar com um total de '}
      {sum}
      .
      {' '}
      <Button variant="text" label="Pagar agora" onClick={handleClick} />
    </>
  ),
  domainCritialMessage: ({ handleClick }) => (
    <>
      {'Você tem um ou mais sites suspensos temporariamente. '}
      <Button variant="text" label="Clique aqui" onClick={handleClick} />
      {' para confirmar seu endereço de e-mail e reativar seu site.'}
    </>
  ),
  domainStaticMessage: ({ handleClick }) => (
    <>
      {'Evite a suspensão temporária do seu site! '}
      <Button variant="text" label="Clique aqui" onClick={handleClick} />
      {' para confirmar seu endereço de e-mail e evitar a suspensão.'}
    </>
  ),
  domainNotPropagated: ({ handleClick }) => (
    <>
      {'Domínio não propagado: seu site pode estar em processo de atualização, o que pode levar até 72 horas para ser concluído. Não se preocupe, isso é normal na internet. Caso deseje alterar o domínio '}
      <Button variant="text" label="configure agora." onClick={handleClick} />
    </>
  ),
  oldDomainInPropagation: ({ handleClick }) => (
    <>
      {'Parece que você tem um ou mais domínios sendo propagados, isso poderá deixar seu site fora do ar por até 72h. '}
      <a href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" onClick={handleClick}>Entenda o prazo.</a>
    </>
  ),
  domainInPropagation: ({ handleClick }) => (
    <>
      Parece que você tem um ou mais domínios sendo propagados, isso poderá deixar seu site
      {' '}
      <strong>instável ou fora do ar por até 72h.</strong>
      {' '}
      <Button variant="text" label="Ver domínios em propagação." onClick={handleClick} />
    </>
  ),
  modalDomainInPropagation: {
    title: 'Domínios em propagação',
    description: (
      <>
        Prazo de propagação é o período de tempo que o seu domínio leva para ficar online em todo o mundo, após a configuração do domínio (alteração do DNS). Ocorre geralmente em até
        {' '}
        <b>24 horas para domínios nacionais</b>
        {' '}
        (com a extensão “.br”) e até
        {' '}
        <b>72 horas para domínios internacionais</b>
        .
      </>
    ),
    knowMoreLabel: 'Quer saber mais sobre propagação?',
    helpCentralLabel: (
      <>
        <a href="https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693-O-que-%C3%A9-propaga%C3%A7%C3%A3o" target="_blank" rel="noreferrer">Clique aqui</a>
        {' '}
        e acesse a nossa Central de Ajuda.
      </>
    ),
    alertTitle: 'Domínio(s) sendo propagado(s):',
    accordionTitle: 'Dominios em propagação',
    okUnderstoodButtonLabel: 'Ok, entendi',
  },
  refundModal: {
    title: 'Status do reembolso',
    refundCompletedCardLabel: (value, paymentMethod) => (
      <>
        O valor de
        {' '}
        <strong>{value}</strong>
        {' '}
        para receber no modo
        {' '}
        <strong>{paymentMethod}</strong>
        {' '}
        já foi reembolsado!
      </>
    ),
    refundPendingCardLabel: (value, paymentMethod) => (
      <>
        Você tem um reembolso no valor de
        {' '}
        <strong>{value}</strong>
        {' '}
        para receber no modo
        {' '}
        <strong>{paymentMethod}</strong>
        .
      </>
    ),
    oneWeekCancel: 'Para cancelamentos feitos após 7 dias do pagamento, o reembolso será parcial.',
    accordionTitle: 'Produto(s) cancelado(s)',
    canceledProduct: {
      label: 'Produto cancelado:',
      value: productDescription => (
        <>
          {productDescription}
        </>
      ),
    },
    canceledDate: {
      label: 'Data do cancelamento:',
      value: date => (<>{date}</>),
    },
    canceledModality: {
      label: 'Modalidade:',
      value: modality => (<>{modality}</>),
    },
    refundValue: {
      label: 'Valor do reembolso:',
      value: value => (<>{value}</>),
    },
    modeAndTerm: {
      title: 'Modo do reembolso escolhido e prazo:',
      refundMethods: {
        wholeCreditsLabel: 'Créditos HostGator - o valor será abatido em uma próxima fatura.',
        wholeCreditCardLabel: 'Cartão de Crédito - o valor será devolvido em até 3 faturas (90 dias), de acordo com o seu banco. Entre em contato com a administradora do seu cartão para mais informações.',
        wholePaypalLabel: (
          <>
            PayPal - devolução de acordo com as
            {' '}
            <a
              href="https://www.paypal.com/br/cshelp/article/onde-est%C3%A1-o-meu-reembolso-faq1212?clickref=1101lwCTJtn8&pid=328130457&dclid=CPz00NmU4_0CFW-IlQIdzmoAlQ"
              target="_blank"
              rel="noreferrer"
            >
              regras do próprio PayPal
            </a>
            .
          </>
        ),
        wholePixLabel: 'Pix e Boleto - no momento, a opção disponível para reembolso é Créditos HostGator - Caso queira a devolução do valor entre em contato com o suporte Financeiro via chat.',
        partialCreditsLabel: 'Créditos HostGator - o valor será abatido em uma próxima fatura.',
        partialCreditCardLabel: 'Cartão de Crédito - opção disponível em até 90 dias após a contratação e devolução em até 3 faturas  (90 dias), de acordo com o seu banco. Entre em contato com a administradora do seu cartão para mais informações.',
        partialPaypalLabel: (
          <>
            PayPal - devolução de acordo com as
            {' '}
            <a
              href="https://www.paypal.com/br/cshelp/article/onde-est%C3%A1-o-meu-reembolso-faq1212?clickref=1101lwCTJtn8&pid=328130457&dclid=CPz00NmU4_0CFW-IlQIdzmoAlQ"
              target="_blank"
              rel="noreferrer"
            >
              regras do próprio PayPal
            </a>
            .
          </>
        ),
        partialPixLabel: 'Pix e Boleto - no momento, a opção disponível para reembolso é Créditos HostGator - Caso queira a devolução do valor entre em contato com o suporte Financeiro via chat.',
      },
    },
    paymentMethods: {
      hgCredits: 'Créditos HostGator',
      creditCard: 'Cartão de Crédito',
      paypal: 'PayPal',
    },
    knowMoreLabel: (
      <div>
        Entenda mais sobre os prazos e valores de reembolso que você recebeu na nossa Central de Ajuda
        {' '}
        <a href="https://suporte.hostgator.com.br/hc/pt-br/articles/360018814254" target="_blank" rel="noreferrer">clicando aqui</a>
        .
      </div>
    ),
    okUnderstoodButtonLabel: 'Ok, entendi',
  },
  selectPlaceHolder: 'selecionar domínio',
  configureDomain: 'Configurar domínio',
  whichDomain: 'Qual domínio quer configurar?',
  selectDomainToConfigure: 'Você tem mais de um domínio não configurado. Selecione qual você gostaria de configurar agora.',
  domainNotContracted: ({ handleClick }) => (
    <>
      {'Domínio não contratado: você tem um ou mais domínios que ainda não foram adquiridos. '}
      <Button variant="text" label="Contrate agora." onClick={handleClick} />
    </>
  ),
  domainNotConfigured: ({ handleClick }) => (
    <>
      {'Configuração de domínio pendente: você tem um ou mais sites com configuração do domínio pendente.  '}
      <Button variant="text" label="Veja como configurar." onClick={handleClick} />
    </>
  ),
  domainForPointing: ({ handleClick, domain }) => (
    <>
      Seu domínio
      {' '}
      <strong>
        &quot;
        {domain}
        &quot;
      </strong>
      {' '}
      precisa ser configurado para um Plano aqui da HostGator ou outra plataforma. Configurar domínio.
      <br />
      <Button variant="text" label="Veja como configurar." onClick={handleClick} />
    </>
  ),
  subscriptionToDue:
    ({ handleClick, daysToDue }) => (
      <>
        <strong>
          <>Faltam </>
          {daysToDue === 30 && (
            <> 30 </>
          )}
          {daysToDue < 30 && daysToDue > 10 && (
            <>menos que 30</>
          )}
          {daysToDue === 10 && (
            <>apenas 10</>
          )}
          {daysToDue < 10 && daysToDue > 5 && (
            <>menos que 10</>
          )}
          {daysToDue === 5 && (
            <>apenas 5</>
          )}
          {daysToDue < 5 && (
            <>menos que 5</>
          )}
          <> dias para sua assinatura ser cancelada.</>
        </strong>
        <>
          {' '}
          <>Ative a renovação e evite perder seus dados. </>
          <Button variant="text" label="Ativar assinatura" onClick={handleClick} />
        </>
      </>
    ),
  subscriptionToCancel:
    ({ handleClick, dueDate }) => (
      <>
        <strong>
          <>Em </>
          {dueDate}
          <> sua assinatura será cancelada.</>
        </strong>
        {' '}
        <br />
        <>
          Ative a renovação agora mesmo com 35% de desconto.
        </>
        <>
          <br />
          <Button variant="text" label="Ativar assinatura" onClick={handleClick} />
        </>
      </>
    ),
  refund: ({ handleClick, refundMethod, refundValue }) => (
    <>
      O valor de
      <strong>
        {' '}
        {refundValue}
        {' '}
      </strong>
      para receber no modo
      <strong>
        {' '}
        {refundMethod}
        {' '}
      </strong>
      já foi
      {' '}
      {refundMethod && refundMethod.includes('HostGator') ? 'creditado' : 'reembolsado'}
      .
      {' '}
      <Button variant="text" label="Ver detalhes." onClick={handleClick} />
    </>
  ),
  emailPassword: {
    createSuccess: 'Conta de email criada com sucesso! :)',
    changeSuccess: 'Senha do e-mail alterada com sucesso.',
    weakPassword: (
      <>
        {'Atenção: Tivemos um erro por motivo de senha fraca. Crie uma senha forte e segura com o nosso  '}
        <a href="https://www.hostgator.com.br/gerador-de-senhas" target="_blank" rel="noreferrer">gerador de senhas.</a>
      </>
    ),
  },
  tos: {
    [TOS_STATUS.DISK]: {
      title: 'Seu espaço em disco está cheio.',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.INODES]: {
      title: 'A conta chegou ao limite de arquivos e diretórios',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.BACKUP]: {
      title: 'Você só pode armazenar um backup em sua conta',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.MYSQL]: {
      title: 'Seu banco de dados MySQL atingiu o limite de uso de recursos',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.CPU]: {
      title: 'Seu uso de recursos do processador chegou ao limite',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.DMCA]: {
      title: 'Existe um problema relacionado a direitos autorais em seu site',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.PISHING]: {
      title: 'Detectamos conteúdos potencialmente perigosos em seu plano de hospedagem',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.PISHING]: {
      title: 'Detectamos conteúdos potencialmente perigosos em seu plano de hospedagem',
      description: ({ handleClick }) => (
        <>
          Entre no chamado para ser orientado pelos nossos especialistas sobre como desbloquear.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
  },
  tickets: {
    [ALERT_TYPES.TICKET_SOLVED]: {
      title: 'Chamado concluído',
      description: ({ handleClick }) => (
        <>
          Seu chamado foi concluído. Se ainda precisar de ajuda sobre esse assunto, fique a vontade para responder.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_NEW]: {
      title: 'Chamado aberto',
      description: ({ handleClick }) => (
        <>
          Recebemos seu chamado e nossa equipe já está analisando. Entraremos em contato com você em breve.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_OPEN]: {
      title: 'Chamado em aberto',
      description: ({ handleClick }) => (
        <>
          Ainda estamos trabalhando em sua solicitação! Se quiser, adicione mais informações ou perguntas.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_PENDING]: {
      title: 'Chamado aguardando sua resposta',
      description: ({ handleClick }) => (
        <>
          Precisamos de uma resposta ou ação sua para resolver o chamado. Responda o mais rápido possível.
          {' '}
          <Button variant="text" label="Ver chamado." onClick={handleClick} />
        </>
      ),
    },
  },
  backup: {
    backupCreated: {
      title: planInfo => `O backup do ${planInfo} foi concluído`,
      description: ({ handleClick }) => (
        <>
          O processo de backup completo foi finalizado.
          <Button variant="text" label="Ver detalhes." onClick={() => handleClick()} />
        </>
      ),
    },
    noBackup: {
      title: planInfo => `Seu ${planInfo} não possui um backup`,
      description: ({ handleClick }) => (
        <>
          Considere fazer um para não perder seus dados.
          <Button variant="text" label="Fazer backup." onClick={() => handleClick()} />
        </>
      ),
    },
    oldBackup: {
      title: (planInfo, daysSinceLast) => `Seu último backup do ${planInfo} foi há mais de ${daysSinceLast} dias`,
      description: ({ handleClick }) => (
        <>
          Considere atualizar para não perder seus dados.
          <Button variant="text" label="Fazer backup." onClick={() => handleClick()} />
        </>
      ),
    },
  },
  duplicateOrders: ({ handleClick }) => (
    <>
      {'Você possui um ou mais pedidos iguais para o '}
      <strong>
        mesmo produto e domínio.
      </strong>
      {' '}
      <Button
        label="Ver pedidos duplicados."
        variant="text"
        onClick={handleClick}
      />
    </>
  ),
  orderAnalysis: {
    [ANALYSIS_STATUS.PENDING_PAYMENT]: {
      description: ({ productName, handleClick, domain = null }) => (
        <>
          {'Estamos aguardando a compensação do pagamento do pedido '}
          <strong>
            {domain ? `${productName} + ${domain}` : `Domínio ${productName}`}
          </strong>
          {', que pode levar até '}
          <strong>{'72 horas úteis. '}</strong>
          <Button
            label="Ver detalhes."
            variant="text"
            onClick={handleClick}
          />
        </>
      ),
    },
    [ANALYSIS_STATUS.PENDING_ANALYSIS]: {
      description: ({ productName, handleClick, domain = null }) => (
        <>
          {'O pedido '}
          <strong>
            {domain ? `${productName} + ${domain}` : `Domínio ${productName}`}
          </strong>
          {' está em análise. O prazo de análise é de '}
          <strong>{'24 a 48 horas úteis. '}</strong>
          <Button
            label="Ver detalhes."
            variant="text"
            onClick={handleClick}
          />
        </>
      ),
    },
    [ANALYSIS_STATUS.CANCELLED]: {
      description: ({ productName, handleClick, domain = null }) => (
        <>
          {'O pedido '}
          <strong>
            {domain ? `${productName} + ${domain}` : `Domínio ${productName}`}
          </strong>
          {' foi cancelado durante a nossa análise. '}
          <Button
            label="Ver detalhes."
            variant="text"
            onClick={handleClick}
          />
        </>
      ),
    },
  },
  ssl: {
    active: ({ domain }) => (
      <>
        {'O SSL para o domínio '}
        <strong>{domain}</strong>
        {' foi ativado com sucesso!'}
      </>
    ),
    activation_failed: ({ domain }) => (
      <>
        {'Não foi possível instalar o SSL no domínio '}
        <strong>{`${domain}. `}</strong>
        <a
          href="https://suporte.hostgator.com.br/hc/pt-br/articles/115001963913-O-que-fazer-se-o-SSL-n%C3%A3o-estiver-funcionando"
          target="_blank"
          rel="noreferrer"
        >
          veja como corrigir
        </a>
      </>
    ),
    in_progress: ({ domain }) => (
      <>
        {'O SSL está em processo de ativação para seu domínio '}
        <strong>
          {`${domain}. `}
        </strong>
        Ele ficará ativo em até 24h após a propagação do domínio
      </>
    ),
  },
};
