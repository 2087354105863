import React from 'react';
import { withStyles, Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { VideoLibrary, AccessTime } from '@material-ui/icons';
import { withI18n } from 'react-i18next';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';

const CollabItem = ({ classes, item, t }) => (
  <div className={classes.listItem} data-testid={item.premium ? 'colabPremiumItem' : 'colabFreeItem'}>
    <div className={classes.imageBlock}>
      <img src={item.image} alt={`imagem do curso: ${item.name}`} />
    </div>

    <div className={classes.infoBlock}>
      <Typography className={classes.itemName} data-testid="colabItemName">{item.name}</Typography>

      <div className={classes.row}>
        <VideoLibrary className={classes.lessonIcons} />
        <Typography className={classes.lessons} data-testid="colabItemLessons">{`${item.lessons} aulas`}</Typography>

        <AccessTime className={classes.lessonIcons} />
        <Typography className={classes.duration} data-testid="colabItemDuration">{`${item.duration}`}</Typography>
      </div>

      {item.premium && (
        <>
          <Typography className={classes.description} data-testid="colabItemDescription">{`${item.description}`}</Typography>

          <div className={classes.row}>
            <Typography className={classes.totalPrice} data-testid="colabItemTotalPrice">
              {formatCurrency(item.totalPrice)}
            </Typography>


            <Typography className={classes.newPrice} data-testid="colabItemNewPrice">
              {formatCurrency(item.newPrice)}
            </Typography>
          </div>
        </>
      )}

      <LinkButton className={classes.ctaButton} to={item.link} target="_blank" data-testid="colabItemCTA">
        {item.premium ? t('mktPlace.colab.acquireButton') : t('mktPlace.colab.accessButton')}
      </LinkButton>

    </div>
  </div>
);

CollabItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(CollabItem));
