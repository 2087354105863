const styles = theme => ({
  main: {
    backgroundColor: theme.color.mystic,
    position: 'relative',
    padding: '46px 176px',
    color: theme.color.tundora,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '46px 0px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '46px 80px',
    },
  },
  card: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingTop: 40,
    paddingBottom: 80,
    minHeight: 'calc(92vh - 92px)',
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    textAlign: 'center',
    boxSizing: 'border-box',
    '&:last-child': {
      paddingBottom: 80,
    },
    '& .status-icon': {
      color: theme.color.indigo,
      marginTop: 0,
    },
    '& p': {
      margin: '1em',
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      minHeight: '100vh',
      paddingBottom: 70,
      paddingLeft: 16,
    },
  },
  contentEmail: {
    minHeight: 'auto',
    width: 395,
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    textAlign: 'start',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    '&:last-child': {
      padding: '19px 27px',
    },
  },
  title: {
    zIndex: 2,
    userSelect: 'none',
    fontSize: '24px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.color.emperor,
    [theme.breakpoints.down('xs')]: {
      padding: '0 40px',
      marginBottom: '30px',
    },
  },
  link: {
    color: theme.color.tundora,
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 15,
  },
  button: {
    marginLeft: 10,
    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      color: theme.color.tundora,
    },
  },
  emailVerifyContainer: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    wordBreak: 'break-word',
    maxWidth: '35%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },

    '& strong': {
      display: 'block',
      fontSize: '16px',
    },
  },


  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
    },
  },

  formText: {
    color: theme.color.regentGray,
  },
  formInput: {
    maxWidth: 417,
    paddingBottom: 10,
  },

  loading: {
    marginRight: 5,
  },

  countdown: {
    textTransform: 'lowercase',
  },

  countdownText: {
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: 500,
  },

  passwordWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 24px',
    maxWidth: '417px',
    width: '100%',
    '& p': {
      margin: '8px 12px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  passwordInputDescriptionD: {
    marginTop: 20,
  },


});

export default styles;
