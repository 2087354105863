import React from 'react';
import { EMAIL_PLATFORM_NAMES } from '@/enums/domains/dns.enum';
import * as Styles from './DnsWizardEmailPlatformCard.styles';

const DnsWizardEmailPlatformCard = ({
  testId = 'emailplatformcard',
  platformName = '',
  platformId,
  platformType,
  imageUrl,
  handleOnClick = () => {},
  hostgatorMailPlatformName,
}) => {
  const handleClick = () => {
    handleOnClick && handleOnClick(platformName, platformId, platformType);
  };
  const getPlatformName = name => ((name !== 'Hostgator' && name !== 'Titan') ? name : hostgatorMailPlatformName);

  const shouldRenderPlatformName = (platformName !== EMAIL_PLATFORM_NAMES.CPANEL);

  return (
    <Styles.MailCardWrapper data-testid={testId} onClick={handleClick}>
      <Styles.PlatformName>
        <img src={imageUrl} alt={platformName} data-testid="card-image" />
        {shouldRenderPlatformName && getPlatformName(platformName)}
      </Styles.PlatformName>
    </Styles.MailCardWrapper>
  );
};

export default DnsWizardEmailPlatformCard;
