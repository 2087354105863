import React from 'react';
import * as Styles from './ChangeEmail.styles';
import {
  Button, IconButton, IconLoading, Input, IconIdea,
} from 'gatorcomponents';
import { SendAgain } from '../SendAgain';
import { useResize } from '@/hooks/useResize';

const ChangeEmail = ({
  changeEmailLocale,
  email = 'sample@mail.com',
  changeEmailForm,
  setChangeEmailForm,
  validateFields,
  disabledSubmit,
  requestEmailChange,
  countDownResend,
  setCountDownResend,
  loadingChange,
  emailVerifyLocale,
  handleGoBack,
  openForm,
  changeFlow,
}) => {
  const windowRect = useResize();
  const { width } = windowRect;
  const mobile = width < 450;

  const handleChangeInput = ({ value, field }) => {
    setChangeEmailForm({
      ...changeEmailForm,
      [field]: {
        value,
        error: '',
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (!hasErrors) {
      requestEmailChange();
    }
  };

  return (
    <Styles.Wrapper data-testid="ChangeEmail">
      {!changeFlow && (
        <Styles.BackButtonWrapper>
          <IconButton
            icon="IconArrow"
            onClick={handleGoBack}
          />
        </Styles.BackButtonWrapper>
      )}

      <Styles.Title>
        {changeEmailLocale.title}
      </Styles.Title>

      <Styles.Description>
        {changeEmailLocale.description({ email })}
      </Styles.Description>

      <Styles.Form onSubmit={handleSubmit}>
        <Styles.RelativeIpunt>
          <Input
            type="text"
            placeholder={changeEmailLocale.form.mailPlaceholder}
            onChange={e => handleChangeInput({ value: e.target.value, field: 'email' })}
            value={changeEmailForm.email.value}
            label={changeEmailLocale.form.mailLabel}
            errorMessage={changeEmailForm.email.error}
            onBlur={validateFields}
            testId="email"
          />

          <Styles.Tip>
            <IconIdea />
            {changeEmailLocale.tip}
          </Styles.Tip>
        </Styles.RelativeIpunt>

        <Input
          type="text"
          placeholder={changeEmailLocale.form.checkEmailPlaceholder}
          onChange={e => handleChangeInput({ value: e.target.value, field: 'checkEmail' })}
          value={changeEmailForm.checkEmail.value}
          label={changeEmailLocale.form.checkEmailLabel}
          errorMessage={changeEmailForm.checkEmail.error}
          onBlur={validateFields}
          testId="checkEmail"
        />
        <Input
          type="password"
          onChange={e => handleChangeInput({ value: e.target.value, field: 'password' })}
          placeholder={changeEmailLocale.form.passwordPlaceholder}
          value={changeEmailForm.password.value}
          label={changeEmailLocale.form.passwordLabel}
          errorMessage={changeEmailForm.password.error}
          onBlur={validateFields}
          testId="password"
        />

        <Styles.ButtonWrapper>
          {countDownResend > 0
            ? (
              <SendAgain
                emailVerifyLocale={emailVerifyLocale}
                countDownResend={countDownResend}
                setCountDownResend={setCountDownResend}
              />
            )
            : (
              <Button
                type="submit"
                width="fit"
                size={mobile ? 'medium' : 'large'}
                label={loadingChange ? <IconLoading /> : changeEmailLocale.form.submitLabel}
                disabled={loadingChange || disabledSubmit()}
                testId="submit"
              />
            )
         }
        </Styles.ButtonWrapper>
      </Styles.Form>

      <Styles.NoAccessTitle>
        {changeEmailLocale.noAccessTitle}
      </Styles.NoAccessTitle>
      <Styles.NoAccessDescription>
        {changeEmailLocale.noAccessDescription}
      </Styles.NoAccessDescription>

      <Styles.OpenFormButtonWrapper>
        <Button
          label={changeEmailLocale.noAccessForm}
          variant="secondary"
          onClick={openForm}
        />
      </Styles.OpenFormButtonWrapper>
    </Styles.Wrapper>
  );
};

export default ChangeEmail;
