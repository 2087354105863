/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
  BACKUP_INFO, DNS_PROPAGATION_ARTICLE, WOOCOMMERCE_ARTICLE, FIND_PLAN_DNS_ARTICLE, GET_A_NEW_DOMAIN,
} from '@/config/urls/supportUrls';
import { PLATFORM_NAMES } from '@/enums/domains/dns.enum';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import {
  Link, IconCheck, IconClock, IconDanger, Button,
} from 'gatorcomponents';

export default {
  wizard: {
    isActivated: 'Ya tienes un creador de sitios activo para este dominio. Seleccione la opción a continuación para acceder a la administración de su Creador de sitios web',
    access: 'Acceda al Creador de Sitios',
    domains: 'Dominios',
    activate: {
      title: 'Creador de Sitios',
      description: '¡Active la herramienta Creador de Sitios para sus dominios de forma gratuita!',
      selectField: 'Seleccione un dominio y haga clic en Activar Creador de Sitios',
      placeholder: 'dominio.com',
      manage: 'Administrar Creador de Sitios',
      suspended: 'El plan de alojamiento tiene un estado diferente al activo. Regularice su plan para que pueda activar el Creador de sitios web para nuevos dominios.',
      ready: 'Ahora puede acceder al Creador de Sitios',
    },
    modalRemoveSite: {
      title: 'Eliminar sitio',
      message: '¿Está seguro de que desea eliminar el sitio web <1>{{productName}}</1> creado en la herramienta Creador de sitios?',
      warning: '¡Borrará todo lo que hayas creado!',
      buttonConfirmation: 'Si, quiero eliminar',
    },
    steps: {
      configurations: 'Configuraciones iniciales',
      about: 'Sobre el sitio',
      tools: 'Definir herramienta de creación',
      tips: 'Consejos para comenzar',
    },
    about: {
      title: {
        0: '¡Es hora de ',
        1: 'definir el tipo y el propósito de su sitio web!',
      },
      subtitle: 'En base a sus respuestas le indicaremos la mejor herramienta de creación :)',
      question: '¿Qué tipo de sitio web quieres empezar a crear?',
      questionTooltip: 'Elija el tipo que más se acerque a sus necesidades y no se preocupe, después puede crear otros tipos si lo desea.',
      institucional: {
        title: 'Sitio institucional',
        subtitle: '(de una empresa)',
      },
      personal: {
        title: 'Sitio personal o portafolio',
      },
      blog: {
        title: 'Blog',
      },
      ecommerce: {
        title: 'Tienda en línea',
        subtitle: '(e-commerce)',
      },
      landing: {
        title: 'Página de adquisición de clientes',
        subtitle: '(landing page)',
      },
      other: {
        title: 'Otro tipo de sitio',
      },
      objectives: {
        question: 'Seleccione el objetivo principal que desea lograr con su sitio web:',
        tooltip: 'Aunque tengas más de un objetivo, elige el que más quieras alcanzar en este momento.',
        placeholder: 'Seleccionar objetivo',
        defaultObjectives: {
          goodSite: 'Tener un buen sitio web (Bonito, rápido y seguro)',
          visibility: 'Visibilidad y difusión de mi marca',
          access: 'Alto número de visitas en el sitio',
          prospection: 'Prospección y captación de clientes',
          results: 'Resultados financieros',
          satisfaction: 'Satisfacción de mis clientes',
          other: 'Otro objetivo',
        },
      },
      othersSitesModal: {
        title: '¿Qué otro tipo de sitio web quiere crear?',
        placeholder: 'digite el tipo de site',
        back: 'Volver',
        save: 'Guardar',
      },
      othersObjectivesModal: {
        title: '¿Qué otro objetivo pretende lograr con el sitio web?',
        placeholder: 'Digite el objetivo',
        back: 'Volver',
        save: 'Guardar',
      },
    },
    tips: {
      createSites: {
        title: '¡Todo listo para comenzar el sitio ahora mismo!',
        buttonLabel: 'Ingresar al creador de sitios',
        altSubtitle: {
          0: 'Abrimos la herramienta Creador de Sitios en una nueva pestaña de su navegador. Si desea volver a ingresar nuevamente, use el acceso directo de la página de ',
          1: 'Sitios web',
          2: ' :)',
        },
        subtitleOne: '¡Vea a continuación algunos ',
        subtitleTwo: 'consejos para trabajar en la apariencia y el contenido del sitio!',
        hiddenInfo: 'Para una mejor experiencia crea tu sitio web en una computadora.',
      },
      wordpress: {
        title: '¡WordPress se instaló correctamente!',
        buttonLabel: 'Ingresar a WordPress',
        subtitle: 'También reenviamos los datos de acceso a la herramienta al correo electrónico de registro :)',
        subtitleOne: '¡Vea a continuación algunos ',
        subtitleTwo: 'consejos para trabajar en la apariencia y el contenido del sitio!',
        hiddenInfo: 'Para una mejor experiencia crea tu sitio web en una computadora.',
      },
      softaculous: {
        title: '¡Felicitaciones, usted ya puede crear su sitio web usando Softaculous!',
        subtitleOne: '¡Vea a continuación algunos ',
        subtitleTwo: 'consejos para instalar otros plugins en su sitio web!',
        hiddenInfo: 'Para una mejor experiencia crea tu sitio web en una computadora.',
      },
      dnsInProgressModal: {
        title: 'Su dominio aún se está propagando',
        description: 'Su dominio aún se encuentra en el período de propagación. Por lo general, la propagación es rápida, pero pueden tardar hasta 72 horas antes de que pueda usar la plataforma.',
        backButton: 'Volver',
        continueButton: 'Intentar ingresar de todos modos',
      },
    },
    wordPressInstall: {
      defaultWordpressValue: 'Mi sitio',
      title: {
        0: 'Antes de continuar, ',
        actionWoocommerce: '¡necesitamos instalar WordPress y Woocommerce!',
        actionWordPress: ' ¡necesitamos instalar WordPress!',
      },
      subtitle: 'Complete los campos y grupos a continuación para luego tener acceso a la herramienta y crear un sitio web :)',
      info: {
        title: 'Informaciones útiles',
        description: 'Describa el sitio y configure los datos de acceso para el panel de WordPress.',
        questions: {
          name: {
            label: 'Nombre del Sitio Web',
            placeholder: 'Nombre de la marca',
            tooltip: 'Introduzca el nombre que utilizará en su sitio web.',
            errorMessage: 'El campo debe tener mínimo 3 caracteres.',
          },
          description: {
            label: 'Descripcion del Sitio Web',
            placeholder: 'Escriba de qué trata el sitio web o el negocio.',
            tooltip: 'Haz una breve descripción de tu sitio, puede ser una explicación de lo que cubre, por ejemplo.',
          },
          email: {
            label: 'Correo',
            placeholder: 'Correo electrónico de inicio de sesión de WordPress',
            errorMessage: 'El correo electrónico digitado no es válido.',
          },
          user: {
            label: 'Usuario',
            placeholder: 'Usuario de inicio de sesión de WordPress',
            errorMessage: 'El campo debe tener mínimo 8 caracteres.',
            errorMessageWithWondersuite: 'El campo debe tener mínimo 4 caracteres.',
          },
          password: {
            label: 'Contraseña',
            placeholder: 'Crea una contraseña',
          },
          confirmPassword: {
            label: 'Confirmar contraseña',
            placeholder: 'Confirme la contraseña',
          },
        },
        passwordChecklistTexts: {
          description: 'Tu nueva contraseña debe:',
          hasLowerAndUpperCase: 'Incluir caracteres en mayúsculas y minúsculas',
          hasNumber: 'Incluir al menos 1 número',
          hasSymbol: 'Incluir al menos 1 símbolo',
          hasAtLeastEight: 'Tener al menos 8 caracteres',
          matchConfirmation: 'Ser igual en ambos campos',
        },
        userChecklistTexts: {
          description: 'El usuario debe:',
          hasAtLeastEight: 'Tener al menos 8 caracteres',
          hasAtLeastFour: 'Tener al menos 4 caracteres',
          notHaveSymbol: 'No contener ningún símbolo (ejemplo: !#$%&)',
        },
        nextStepButton: 'Ver abajo las opciones para el tema del sitio',
      },
      theme: {
        title: 'Elegir el tema del sitio web',
        description: 'Vea algunas ideas para el sitio y elija la que tenga la composición que más le guste.',
        tooltip: 'Puede cambiar las imágenes, los colores y los textos de los temas para que combinen con el objetivo de su negocio.',
        see: 'Ver tema',
        use: 'Usar tema',
        selected: 'Seleccionado',
        nextStepButton: 'Ver abajo las opciones de plugins para agregar al sitio',
      },
      plugin: {
        title: 'Elegir plugins',
        optional: ' (Opcional)',
        description: 'Hemos seleccionado algunos plugins populares para usted. Seleccione todos los que quiera usar junto con WordPress.',
        tooltip: ' Los plugins sirven para mejorar el sitio y permiten aplicarle diferentes funcionalidades. Dentro de la herramienta será posible instalar estos y varios otros.',
        use: 'Usar plugin',
        selected: 'Seleccionado',
        nextStepButton: 'Finalizar y instalar Wordpress',
        descriptions: {
          elementor: 'Con este plugin, es posible crear de manera fácil y rápida, arrastrando y soltando los componentes que desee para crear su sitio web.',
          contactForm: 'Facilite la comunicación del cliente con su marca a través de formularios de contacto personalizados.',
          jivoChat: 'Hable y supervise los visitantes en tiempo real por el chat en línea, Facebook Messenger, teléfono y correo electrónico.',
          googleKit: 'Utilice un kit con las mejores herramientas de Google para capturar informaciones y perspectivas sobre su sitio de forma centralizada. Tendrá acceso a Analytics, Adsense, entre otros.',
          yoast: 'Facilite para el cliente encontrar su sitio web en Internet. Use este plugin para mejorar su clasificación en las herramientas de búsqueda como Google.',
        },
      },
      installModal: 'Estamos instalando WordPress...',
      errorToast: 'Ocurrió un error inesperado. inténtalo más tarde.',
    },
    wondersuite: {
      tools: {
        toolsCards: {
          woocommerce: {
            title: 'WordPress + plugin Woocommerce',
            titleText: 'WordPress + Woocommerce',
            description: () => null,
            items: {
              0: () => (
                <>
                  Ideal para
                  {' '}
                  <span>crear una Tienda en línea</span>
                </>

              ),
              1: () => (
                <>
                  <span>Miles de plugins</span>
                  {' '}
                  para personalizar la tienda online
                </>
              ),
              2: () => (
                <>
                  Diversos
                  {' '}
                  <span>temas listos y receptivos</span>
                  {' '}
                  para diferentes tipos de negocios
                </>
              ),
              3: () => (
                <>
                  Solución
                  {' '}
                  <span>ideal para integrar diferentes sistemas de envío y pago</span>
                </>
              ),
              4: onWoocommerceHelpMaterialClick => (
                <>
                  <span>Registre productos y administre la tienda con </span>
                  <u>
                    <a
                      href={WOOCOMMERCE_ARTICLE}
                      onClick={() => onWoocommerceHelpMaterialClick()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Woocommerce
                    </a>

                  </u>
                  {' (plugin para venta online)'}
                </>
              ),
              5: () => (
                <>
                  Ideal para cualquier persona que quiera
                  {' '}
                  <span>crear un sitio web personalizado</span>
                </>
              ),
            },
            warning: 'Estas herramientas requieren un poco de conocimiento para un mejor uso. Tenemos contenido que puede ayudar en este proceso :)',
            action: 'Elegir WordPress',
          },
          otherSite: {
            title: 'Otras herramientas',
            titleText: 'Otras herramientas',
            description: () => (
              <>
                <span>Estas y otras herramientas las puedes encontrar en Softaculos</span>
                {' '}
                (administrador de aplicaciones de hospedaje). En el encontrarás más información sobre cada herramienta.
              </>
            ),
            items: {
              0: () => (
                <>
                  Indicado para
                  {' '}
                  <span>soluciones más complejas.</span>
                </>
              ),
              1: () => (
                <>
                  Ideal para cualquier persona que quiera
                  {' '}
                  <span>crear un sitio web personalizado.</span>
                </>
              ),
            },
            otherIconLabel: 'Otros',
            warning: 'Estas herramientas requieren un poco de conocimiento para un mejor uso.',
            action: 'Ver herramientas',
          },
        },
      },
    },
    tools: {
      title: {
        0: 'Recomendamos la herramienta ',
        ecommerce: ' para crear la tienda en línea',
        institucional: ' para crear la tienda en línea',
        personal: ' para crear la tienda en línea',
        blog: ' para crear la tienda en línea',
        landing: ' para crear la tienda en línea',
        other: ' para crear la tienda en línea',
      },
      subtitle: {
        ecommerce: 'Esta es la mejor opción para quienes quieren crear una tienda en línea, pero también puede elegir otra herramienta.',
        institucional: 'Si prefiere, elija otra opción presenta a continuación :)',
        personal: 'Si prefiere, elija otra opción presenta a continuación :)',
        blog: 'Si prefiere, elija otra opción presenta a continuación :)',
        landing: 'Si prefiere, elija otra opción presenta a continuación :)',
        other: 'Si prefiere, elija otra opción presenta a continuación :)',
      },
      woocommerce: {
        title: 'WordPress + plugin Woocommerce',
        titleText: 'WordPress + Woocommerce',
        description: () => null,
        items: {
          0: 'Ideal para <strong>crear una Tienda en línea</strong>',
          1: '<strong>Miles de plugins</strong> para personalizar la tienda online',
          2: 'Diversos <strong>temas listos y receptivos</strong> para diferentes tipos de negocios',
          3: 'Solución <strong>ideal para integrar diferentes sistemas de envío y pago</strong>',
          4: onWoocommerceHelpMaterialClick => (
            <>
              <strong>Registre productos y administre la tienda con </strong>
              <u>
                <a
                  href={WOOCOMMERCE_ARTICLE}
                  onClick={() => onWoocommerceHelpMaterialClick()}
                  target="_blank"
                  rel="noreferrer"
                >
                  Woocommerce
                </a>

              </u>
              {' (plugin para venta online)'}
            </>
          ),
          5: 'Ideal para cualquier persona que quiera <strong>crear un sitio web personalizado</strong>',
        },
        warning: 'Estas herramientas requieren un poco de conocimiento para un mejor uso. Tenemos contenido que puede ayudar en este proceso :)',
        action: 'Elegir WordPress',
      },
      wordpress: {
        title: 'WordPress',
        description: () => null,
        titleText: 'WordPress',
        items: {
          0: () => (
            <>
              Indicado para
              {' '}
              <span>soluciones más complejas</span>
            </>
          ),
          1: () => (
            <>
              <span>Tiempo de creación variable</span>
              {' '}
              según el nivel de conocimiento y el propósito del sitio
            </>
          ),
          2: () => (
            <>

              <span>Miles de temas listos para usar y receptivos</span>
              {' '}
              para diferentes tipos de negocio
            </>
          ),
          3: () => (
            <>
              Ideal para cualquier persona que quiera
              {' '}
              <span>crear un sitio web personalizado</span>
            </>
          ),
          4: () => (
            <>
              <span>Cientos de complementos</span>
              {' '}
              para personalizar el sitio
            </>
          ),
        },
        warning: 'Esta herramienta requiere un poco de conocimiento para un mejor uso.',
        action: 'Elegir WordPress',
      },
      sitebuilder: {
        title: 'Creador de sitios web',
        description: () => null,
        titleText: 'Creador de sitios web',
        items: {
          0: () => (
            <>
              Diseñado para
              {' '}
              <span>soluciones simples</span>
            </>
          ),
          1: () => (
            <>
              <span>Construya su sitio web rápidamente</span>
            </>
          ),
          2: () => (
            <>
              <span>Cientos de temas listos para usar y receptivos</span>
              {' '}
              para diferentes tipos de negocios
            </>
          ),
          3: () => (
            <>
              <span>Fácil de usar,</span>
              {' '}
              tiene una guía para crear el sitio web sin ningún código

            </>
          ),
          4: () => (
            <>
              <span>Banco de imágenes</span>
              {' '}
              lleno de fotos para hacer más atractivo el sitio web
            </>
          ),
        },
        action: 'Elegir el creador de sitios web',
      },
      otherCommerce: {
        title: 'Otras herramientas',
        titleText: 'Otras herramientas',
        description: () => (
          <>
            <span>Estas y otras herramientas las puedes encontrar en Softaculos</span>
            {' '}
            (administrador de aplicaciones de hospedaje). En el encontrarás más información sobre cada herramienta.
          </>
        ),
        items: {
          0: 'Indicado para <strong>soluciones más complejas.</strong>',
          1: 'Ideal para cualquier persona que quiera <strong>crear un sitio web personalizado.</strong>',
        },
        otherIconLabel: 'Otros',
        warning: 'Estas herramientas requieren un poco de conocimiento para un mejor uso.',
        action: 'Ver herramientas',
      },
      otherSite: {
        title: 'Otras herramientas',
        titleText: 'Otras herramientas',
        description: () => (
          <>
            <span>Estas y otras herramientas las puedes encontrar en Softaculos</span>
            {' '}
            (administrador de aplicaciones de hospedaje). En el encontrarás más información sobre cada herramienta.
          </>
        ),
        items: {
          0: () => (
            <>
              Indicado para
              {' '}
              <span>soluciones más complejas</span>
            </>
          ),
          1: () => (
            <>
              Ideal para cualquier persona que quiera
              {' '}
              <span>crear un sitio web personalizado</span>
            </>
          ),
        },
        otherIconLabel: 'Otros',
        warning: 'Estas herramientas requieren un poco de conocimiento para un mejor uso.',
        action: 'Ver herramientas',
      },
      icons: {
        other: 'Otros',
      },
    },
    actions: {
      continue: 'Continuar',
      backButton: 'Volver',
      backHome: 'Ir a la pantalla principal de Sitios web',
      showMore: 'Ver beneficios',
      showLess: 'Ver menos',
    },
    altSnappy: 'logo',
    altColorLine: 'línea de color',
    configurationStep: {
      title: '¿Listo para lograr el ',
      titleStrong: 'éxito en línea?',
      text: '¡Empiece a crear su sitio web ahora mismo!',
      selectText: '¿Cuál será la dirección del sitio web?',
      selectPlaceholder: 'seleccionar dominio o subdominio',
      wishOtherDomain: 'Quiero usar otro dominio o subdominio',
      selectedPlan: 'Vea en cuál plan de Hosting estará el sitio web:',
      choosePlan: 'Elegir plan',
      mainDomain: 'Dominio principal: ',
      continue: 'Continuar',
      tooltipReplace: 'Si ya tiene un sitio creado para ese dominio, al continuar, será reemplazado.',
      tooltipSinglePlan: 'Su sitio web se alojará en el plan a continuación porque es su único plan disponible o porque su dominio ya está agregado a él. ',
      hirePlanTitle: 'Usted no tiene un plan de hospedaje contratado y activo.',
      hirePlanSubtitle: '¡Para crear un nuevo sitio web haga clic en el botón de abajo y contrate un plan ahora mismo!',
      hirePlanCta: 'Contratar Hospedaje',
      registerDomainTitle: domain => `El dominio "${domain}" aún no está registrado.`,
      registerDomainSubtitle: 'Para crear un nuevo sitio web, el dominio debe estar registrado y activo. ¡Haga clic en el botón de abajo para registrarlo ahora mismo!',
      registerDomainCta: 'Registrar dominio',
    },
    useAnotherDomain: {
      title: '¿Cuál dirección desea utilizar para el sitio?',
      subtitle: 'Digite abajo, seleccione la extensión y vea si está lista para usar.',
      placeholder: 'Digite su dominio o subdominio (ej: midominio.com)',
      buttonLabel: 'Validar dominio',
      messageError: '¡Dominio inválido! No puede contener caracteres especiales (excepto guiones), comenzar y terminar con guiones, contener solo números o tener más de 63 caracteres.',
      tldMessageError: 'El dominio informado no se pudo agregar a su plan. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
      selectText: '¿Cuál será la dirección del sitio web?',
    },
    modalNotRegistered: {
      title: 'Validación de dominio ',
      domain: 'El dominio ',
      noRegister: ' aún no está registrado.',
      registerNow: 'Aproveche y regístrelo ahora mismo',
      otherDomain: 'o vuelva e indique otro dominio.',
      wishKeep: 'Si desea continuar con la creación del sitio sin registrar el dominio, ',
      notAvailable: 'cuando lo publique, no estará disponible para el acceso en línea.',
      backButton: 'Volver',
      continueButton: 'Continuar creando el sitio',
    },
    modalRegistered: {
      title: 'Validación de dominio ',
      domain: 'El dominio ',
      registered: ' ya está registrado con otro proveedor.',
      isCorrect: '¿La dirección es correcta? ',
      continue: ' Si es así, siga creando el nuevo sitio :)',
      incorrect: 'Si no es suyo o está incorrecto, vuelva e indique otro dominio.',
      backButton: 'Volver',
      continueButton: 'Continuar creando el sitio',
    },
    planLimit: {
      title: '¡Su plan de alojamiento actual ya alcanzó el límite de sitios!',
      text: 'Para crear un nuevo sitio web, es necesario cambiarse para un plan superior o comprar otro.',
      changePlan: 'Cambiar plan de Hosting',
      buyOtherPlan: 'Comprar otro plan',
    },
    modalUpgradePlan: {
      title: '¿Cuál plan de hosting quiere cambiar?',
      subtitle: 'Seleccione en qué plan le gustaría crear más sitios y vea las opciones disponibles.',
      buttonLabel: 'Ver planes disponibles',
      domainDirector: 'Dominio principal: ',
    },
    modalChangePlan: {
      title: '¿En cuál plan quiere que esté el sitio web?',
      subtitle: 'Seleccione a continuación en cuál plan de Hosting está alojado el sitio web.',
      tooltip: 'Todo sitio web debe estar alojado en un plan. En caso de duda, seleccione cualquiera disponible abajo. Solo enumeramos aquellos que están activos y aptos para crear nuevos sitios.',
      mainDomain: 'Dominio principal: ',
      otherDomains: 'Otros dominios: ',
      noOtherDomains: 'Aún no hay otros dominios agregados al plan.',
      domains: ' dominios',
      domain: ' dominio',
      choosePlan: 'Elegir plan',
    },
    modalSelectDnsManual: {
      title: 'Ahora falta que configure el dominio',
      descriptionOne: 'Como el dominio ',
      descriptionTwo: ' no se registró aquí en HostGator, debe ir al otro proveedor y apuntar el DNS de ese dominio para su plan de hosting con nosotros.',
      question: '¿Donde el dominio fue registrado?',
      placeholder: 'seleccionar proveedor',
      labelButtonBack: 'Volver',
      labelButtonKeep: 'Ver cómo configurar',
    },
    modalSelectDnsManualStep2: {
      title: 'Vea cómo configurar el dominio en otro proveedor',
      description: 'Utilice la siguiente información para configurar su dominio (cambiar DNS) en otro proveedor y, si es necesario, ',
      tutorialLink: 'vea cómo hacer esta configuración paso a paso:',
      replace: 'Sustituya las informaciones de DNS actuales del otro proveedor por las de Hostgator:',
      actualDns: 'DNS actual en ',
      hgDns: 'DNS de HostGator',
      server1: 'Servidor 1 (DNS):',
      server2: 'Servidor 2 (DNS):',
      goToHost: 'Ir al otro proveedor para hacer el cambio de DNS',
      waitTime: 'Después de la configuración anterior, ',
      waitTimeStrong: 'la propagación de DNS de su dominio puede demorar hasta 24 horas.',
      warning: 'Si desea, continúe creando el sitio, pero tenga en cuenta que no estará en línea hasta que esta configuración esté finalizada y propagada.',
      labelButtonBack: 'Volver',
      labelButtonContinue: 'Continuar creando el sitio',
      copied: 'Copiado',
    },
    modalVerifyingSiteExist: {
      message: 'Estamos comprobando que todo está bien para que pueda continuar...',
    },
    modalOverwrite: {
      title: '¿Ya tiene un sitio web creado?',
      warningDomain: domain => (
        <>
          Si tiene un sitio web creado para el dominio
          &quot;
          <span>{domain}</span>
          &quot;,
          al avanzar, ¡perderá todo el contenido publicado!
        </>
      ),
      warningLink: handleClickMaterials => (
        <>
          {'Usted puede hacer una '}
          <a href={BACKUP_INFO} target="_blank" rel="noreferrer" onClick={handleClickMaterials}>copia de seguridad (backup)</a>
          {'  de su hosting, o avanzar con la creación de su sitio web.'}
        </>
      ),
      checkboxLabel: 'Soy consciente y quiero continuar de todos modos',
      continueButtonLabel: 'Continuar creando el sitio web',
      backButtonLabel: 'Volver',
    },
    modalVerifiedOverwrite: {
      title: 'Ya tiene un sitio web creado para ese dominio',
      warningDomain: domain => (
        <>
          Ya tiene un sitio web creado en el dominio
          &quot;
          <span>{domain}</span>
          &quot;.
          <span> Al avanzar para la creación de un nuevo sitio web, ¡perderá todo el contenido publicado!</span>
        </>
      ),
      warningDomainWithDns: domain => (
        <>
          Ya tiene un sitio web creado en el dominio
          &quot;
          <span>{domain}</span>
          &quot;.
        </>
      ),
      warningAboutDns: () => (
        <>
          Al avanzar para la creación de un nuevo sitio web,
          {' '}
          <span>perderá todo el contenido ya publicado y aún tendremos que actualizar la configuración de ese dominio (registros DNS).</span>
        </>
      ),
      warningLink: handleClickMaterials => (
        <>
          Si lo desea, realice primero una
          <a href={BACKUP_INFO} target="_blank" rel="noreferrer" onClick={handleClickMaterials}> copia de seguridad (backup) </a>
          de su hosting.
        </>
      ),
      checkboxLabel: 'Comprendo que se eliminará el contenido y deseo continuar de todos modos.',
      continueButtonLabel: 'Continuar creando el sitio',
      backButtonLabel: 'Cancelar',
    },
  },
  configuration: {
    modal: {
      stepOne: {
        title: 'Configurar el DNS de dominio para HostGator',
        description: ({ domain }) => (
          <>
            {'Debido a que el dominio '}
            <span>
              &quot;
              {domain}
              &quot;
            </span>
            {' no se registró aquí en HostGator, debe ir al otro proveedor y apuntar el DNS de ese dominio para su plan de hosting con nosotros.'}
          </>
        ),
        question: '¿Donde el dominio fue registrado?',
        placeholder: 'seleccionar proveedor',
        labelButtonBack: 'Volver',
        labelButtonKeep: 'Ver cómo configurar',
      },
      stepTwo: {
        title: 'Vea cómo configurar el dominio en otro proveedor',
        description: 'Utilice la siguiente información para configurar su dominio (cambiar DNS) en otro proveedor y, si es necesario, ',
        tutorialLink: 'vea cómo hacer esta configuración paso a paso:',
        replace: 'Sustituya las informaciones de DNS actuales del otro proveedor por las de Hostgator:',
        actualDns: 'DNS actual en ',
        hgDns: 'DNS de HostGator',
        server1: 'Servidor 1 (DNS):',
        server2: 'Servidor 2 (DNS):',
        goToHost: ({ provider }) => (`Ir a la ${provider}`),
        waitTime: () => (
          <>
            {' Después de la confirmación anterior, la propagación de DNS de su dominio puede demorar hasta '}
            <strong>
              72
            </strong>
            {' horas. '}
            <a href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer">Leer más.</a>
          </>
        ),
        otherProvider: {
          firstLine: () => (
            <>
              <strong>
                1.
              </strong>
              {' Acceda al panel de su otro proveedor de alojamiento; '}
              <strong>
                panel de su otro proveedor
              </strong>
              {' de alojamiento;'}
            </>
          ),
          secondLine: () => (
            <>
              <strong>
                2.
              </strong>
              {' En el dominio que desea configurar (cambiar DNS) para su plan aquí con HostGator, busque ”'}
              <strong>
                Cambiar DNS
              </strong>
              {' o '}
              <strong>
                Cambiar Servidores
              </strong>
              ” - si no los encuentra, comuníquese con el soporte de ese proveedor;
            </>
          ),
          thirdLine: () => (
            <>
              <strong>
                3.
              </strong>
              {' En la parte donde le pide para cambiar los ”DNS / Servidores”, coloque los '}
              <strong>
                DNS de su plan de Hosting aquí con HostGator
              </strong>
              {' - Si es necesario, '}
              <a href={FIND_PLAN_DNS_ARTICLE} target="_blank" rel="noreferrer">vea como encontrarlo.</a>
              .
            </>
          ),
        },
        labelButtonBack: 'Volver a las instrucciones',
        copied: 'Copiado',
        changesDone: 'Ya hice los cambios',
        failureDomainDns: link => (
          <>
            {'Tuvimos un problema para encontrar las '}
            <strong>informaciones de DNS de su proveedor actual.</strong>
            <br />
            {'Copie las informaciones para garantizar el apuntamiento correcto, '}
            <a href={link} target="_blank" rel="noreferrer">haga clic aquí y vea como realizar el paso a paso.</a>
          </>
        ),
        failurePlanDns: link => (
          <>
            {'Tuvimos un problema para encontrar las '}
            <strong>informaciones de DNS de HostGator.</strong>
            <br />
            {'Copie las informaciones para garantizar el apuntamiento correcto, '}
            <a href={link} target="_blank" rel="noreferrer">haga clic aquí y vea como realizar el paso a paso.</a>
          </>
        ),
      },
      stepThree: {
        title: 'Finalizando la configuración del dominio',
        description: 'Después de realizar todos los cambios, acompañe el status de su dominio aquí.',
      },
      configuredDomainStatusTexts: {
        title: 'Cambios que ha realizado:',
        description: externalDns => ({
          [PLATFORM_NAMES.registro_br]: 'DNS actual en Registro.br para DNS de Hostgator',
          [PLATFORM_NAMES.godaddy]: 'DNS actual en GoDaddy para DNS de Hostgator',
          [PLATFORM_NAMES.locaweb]: 'DNS actual en Locaweb para DNS de Hostgator',
          [PLATFORM_NAMES.uolhost]: 'DNS actual en Uol Host para DNS de Hostgator',
          [PLATFORM_NAMES.kinghost]: 'DNS actual en Kinghost para DNS de Hostgator',
        })[externalDns] || 'DNS actual en otro proveedor para DNS de Hostgator',
        buttonLabel: 'Verificar el status del dominio',
        result: status => ({
          [PROPAGATION_STATUS.PROPAGATED]: (
            <>
              <IconCheck color="activeMedium" />
              <p>
                <strong>Propagado:</strong>
                {' La configuración del dominio se realizó correctamente.'}
              </p>
            </>
          ),
          [PROPAGATION_STATUS.IN_PROGRESS]: (
            <>
              <IconClock color="pendingPure" />
              <p>
                <strong>En propagación:</strong>
                {' La configuración de dominio todavía está sucediendo y puede tomar hasta '}
                <strong>{'72 horas. '}</strong>
                <Link text="Leer más." href={DNS_PROPAGATION_ARTICLE} />
              </p>
            </>
          ),
          [PROPAGATION_STATUS.NOT_PROPAGATED]: (
            <>
              <IconDanger color="problemMedium" />
              <div>
                <p>
                  <strong>No propagado:</strong>
                  {' La configuración del dominio aún no se ha encontrado. Pero tranquilo: puede llevar algunas horas para que la actualización en la red suceda.'}
                </p>
                <p>
                  {'Si han pasado más de 72 horas, consulte con su proveedor para ver si la configuración se realizó correctamente. '}
                  <Link text="Leer más." href={DNS_PROPAGATION_ARTICLE} />
                </p>
              </div>
            </>
          ),
        })[status]
          || (
            <>
              <IconDanger color="problemMedium" />
              <div>
                <p>
                  <strong>No propagado:</strong>
                  {' La configuración del dominio aún no se ha encontrado. Pero tranquilo: puede llevar algunas horas para que la actualización en la red suceda.'}
                </p>
                <p>
                  {'Si han pasado más de 72 horas, consulte con su proveedor para ver si la configuración se realizó correctamente. '}
                  <Link text="Leer más." href={DNS_PROPAGATION_ARTICLE} />
                </p>
              </div>
            </>
          ),
      },
    },
  },
  page: {
    editSiteCta: 'Editar Sitio',
    suspendedCpanelTooltip: 'Su plan de hosting fue suspendido, por esto, el acceso a cPanel no está disponible. Para recuperar el acceso, realice el pago de la suscripción.',
    unlimited: 'Ilimitados',
    hostCount: ({
      withGatorSolutions,
      group,
    }) => {
      const amount = withGatorSolutions
        ? (group && group[0] && group[0].cpanel_domains && group[0].cpanel_domains.length) || 0
        : group.length;
      if (amount) {
        return (
          <>
            <strong>Sites:</strong>
            {` ${amount}`}
          </>
        );
      }
    },
    empty: 'Adquiera un alojamiento y un dominio ¡y dé el primer paso para crear su sitio web!',
    title: 'Lista de Sitios',
    description: 'Vea sus sitios hospedados aquí en HostGator :)',
    createNewSiteButton: 'Crear sitio',
    seeMoreButton: 'Cargar más sitios',
    emptyState: 'No encontramos ningún sitio que coincida con los filtros aplicados.',
    alerts: {
      financial: {
        isPending: {
          label: () => (
            <p>
              <strong>Esperando la compensación del pago de las suscripciones</strong>
            </p>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isInAnalysis: {
          label: () => (
            <>
              <strong>Estamos realizando el análisis de seguridad de las suscripciones</strong>
            </>
          ),
        },
        isToDue: {
          label: () => (
            <>
              <strong>La factura de renovación del Dominio y/o Plan de Hosting ya está disponible para su pago</strong>
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isOverDue: {
          label: ({ daysForPayment }) => (
            <>
              <strong>{'Pendiente financiero: '}</strong>
              {daysForPayment === 1 ? ` ${daysForPayment} día para que el sitio web esté caído (offline)` : ` ${daysForPayment} días para que el sitio web esté caído (offline)`}
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()} />
          ),
        },
        isSuspended: {
          label: ({ daysForPayment }) => (
            <>
              <strong>{'Pendiente financiero: '}</strong>
              {daysForPayment === 1 ? ` ${daysForPayment} día para que se cancele el sitio web y se eliminen sus archivos.` : ` ${daysForPayment} días para que se cancele el sitio web y se eliminen sus archivos.`}
            </>
          ),
          button: ({ withGatorSolutions, handleClick, invoiceId }) => (
            <Button
              label={withGatorSolutions
                ? 'Realice el pago de la factura abierta'
                : 'Pagar factura'}
              variant="text"
              size="medium"
              onClick={withGatorSolutions ? () => handleClick(invoiceId) : () => handleClick()}
            />
          ),
          subLabel: 'SITIO WEB OFFLINE',
        },
      },
      propagation: {
        isExternalNotConfigured: {
          label: () => (
            <>
              <strong>{'Dominio aún no configurado (cambiar DNS): '}</strong>
              configure en la empresa donde tiene el dominio registrado y activo
            </>
          ),
          button: () => (
            <Link text="Consulte cómo hacerlo" href="https://soporte-latam.hostgator.com/hc/es-419/articles/6798006471067" />
          ),
        },
        isInternalNotConfigured: {
          label: () => (
            <>
              <strong>Dominio aún no configurado (cambiar DNS)</strong>
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Configurar dominio" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isInPropagation: {
          label: () => (
            <>
              <strong>{'Dominio en propagación: '}</strong>
              Espere hasta 72 horas para que su dominio se propague
            </>
          ),
        },
        isPropagated: {
          label: () => (
            <>
              <strong>Dominio propagado</strong>
            </>
          ),
        },
      },
      ssl: {
        NOT_ACTIVE: {
          label: () => (
            <>
              <strong>{'SSL aún no activado: '}</strong>
              Configure primero el dominio y espere hasta 24 horas después de la propagación para que el SSL se active
            </>
          ),
        },
        IN_PROGRESS: {
          label: () => (
            <>
              <strong>{'SSL en proceso de activación: '}</strong>
              Se instalará automáticamente dentro de las 24 horas posteriores a la propagación del dominio
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="¡Actívalo ahora!" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        ACTIVE: {
          label: () => (
            <>
              <strong>SSL activo</strong>
            </>
          ),
        },
        ACTIVATION_FAILED: {
          label: () => (
            <>
              <strong>{'SSL pendiente: '}</strong>
              debe realizar alguna acción para que se active el SSL
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Ver pendiente" variant="text" size="medium" onClick={handleClick} />
          ),
        },
      },
      sitelock: {
        not_active: {
          label: () => '',
          button: ({ handleClick }) => (
            <Button label="" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        active: {
          label: () => '',
        },
      },
    },
    tags: {
      is_main_domain: 'Dominio principal',
      site_offline: 'Sitio caído',
    },
    groupedPage: {
      alerts: {
        suspended: ({ history, invoicesUrl }) => (
          (
            <>
              {'Tiene uno o más dominios expirados. Renueve ahora para que sus sitios web y correos electrónicos no dejen de funcionar. '}
              <Link
                text="Ver facturas."
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="suspendedCta"
              />
            </>
          )
        ),
        overdue: ({ history, invoicesUrl }) => (
          (
            <>
              {'Tiene uno o más dominios con retrasos financieros. Sus sitios web y correos electrónicos pueden dejar de funcionar. '}
              <Link
                text="Ver facturas."
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="overdueCta"
              />
            </>
          )
        ),
        dnsProblem: ({
          history,
          domainsUrl,
        }) => (
          <>
            {'Ocurrió una falla en el registro de uno o más dominios. '}
            <Link
              text="Ver dominios."
              href={domainsUrl}
              historyRedirect
              history={history}
              target="_self"
              testId="dnsProblemCta"
            />
          </>
        ),
        to_due: ({ history, invoicesUrl }) => (
          (
            <>
              {'Tiene uno o más dominios con retrasos financieros. Sus sitios web y correos electrónicos pueden dejar de funcionar. '}
              <Link
                text="Ver facturas."
                href={invoicesUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="toDueCta"
              />
            </>
          )
        ),
        pending: ({ history, domainsUrl }) => (
          (
            <>
              {'Tiene uno o más dominios con compensación de pago pendiente. '}
              <Link
                text="Ver dominios."
                href={domainsUrl}
                historyRedirect
                history={history}
                target="_self"
                testId="pendingCta"
              />
            </>
          )
        ),
        in_analysis: 'Estamos realizando análisis de seguridad para uno o más dominios. No se preocupe, este es un procedimiento normal.',
      },
    },
    newAlerts: {
      financial: {
        isPending: {
          label: ({ groupMainDomain }) => (
            <>
              Esperando la compensación del pago de la suscripción de su plan con el dominio principales
              {' '}
              <strong>{groupMainDomain}</strong>
              .
              {' '}
              Si ya ha pagado, por favor espere. Pagar factura
              {' '}
            </>
          ),
          button: ({ handleClick }) => (
            <Button label="Pagar factura." variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isInAnalysis: {
          label: () => 'Estamos realizando el análisis de seguridad de la suscripción del plan. No se preocupe, este es un procedimiento normal.',
        },
        isToDue: {
          label: () => 'La factura de renovación del plan de Hosting ya está disponible para su pago. ',
          button: ({ handleClick }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isOverDue: {
          label: ({ daysForPayment }) => (daysForPayment === 1 ? ` ${daysForPayment} día para que su plan se suspenda y sus sitios se caigan. ` : ` ${daysForPayment} días para que su plan se suspenda y sus sitios se caigan. `),
          button: ({ handleClick }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
        isSuspended: {
          label: ({ daysForPayment, withGatorSolutions, groupMainDomain }) => {
            if (withGatorSolutions) {
              return (
                <>
                  {'Su plan con dominio principal '}
                  <strong>{groupMainDomain}</strong>
                  {` fue suspendido y los sitios vinculados a él están caídos. En ${daysForPayment} días se cancelará y sus archivos serán eliminados..`}
                </>
              );
            }

            return (daysForPayment === 1
              ? `Su plan ha sido suspendido y los sitios vinculados a él están inactivos. En ${daysForPayment} día se cancelará y se eliminarán sus archivos. `
              : `Su plan ha sido suspendido y los sitios vinculados a él están inactivos. En ${daysForPayment} días se cancelará y se eliminarán sus archivos. `);
          },

          button: ({ handleClick }) => (
            <Button label="Pagar factura" variant="text" size="medium" onClick={handleClick} />
          ),
        },
      },
      propagation: {
        not_contracted: (
          <>
            <strong>Dominio no registrado</strong>
            {': Este dominio aún no ha sido adquirido. '}
            <Link text="Contratar ahora." href={GET_A_NEW_DOMAIN} target="_blank" />
          </>
        ),
        in_progress: (
          <>
            <strong>Dominio en propagación</strong>
            {': Espere hasta 72 horas para que su dominio se propague.'}
          </>
        ),
      },
    },
    tagNew: 'Nuevo',
  },
  sslModal: {
    loading: 'Espere mientras intentamos activar el Certificado SSL. Este proceso puede tardar unos minutos...',
    verifyPendencyLoading: 'Estamos comprobando cuál es la activación pendiente del Certificado SSL...',
    backButtonLabel: 'Volver',

    domainNotRegistered: {
      domainNotRegisteredTitle: 'Dominio aún no registrado',
      domainNotRegisteredDescription: ({ domain }) => (
        <>
          {'El dominio "'}
          <strong>{domain}</strong>
          {'" aún no está registrado. '}
          <b>Haga clic en el botón de abajo para registrarlo</b>
          {', y después de que esté activo y propagado, el Certificado SSL se activará automáticamente dentro de las 24 horas.'}
        </>
      ),
      registerDomainButtonLabel: 'Registrar dominio',
    },

    externalDomainNotPointed: {
      externalDomainNotPointedTitle: 'El dominio no está configurado para HostGator',
      externalDomainNotPointedDescription: ({ domain }) => (
        <>
          {'Vimos que el dominio "'}
          <strong>{domain}</strong>
          {'" está configurado para un proveedor diferente de HostGator.'}
          <br />
          <br />
          Haz clic en el botón de abajo para ver cómo hacer esta corrección en las principales empresas de alojamiento o contacta al Soporte de ese proveedor.
        </>
      ),
      howToConfigure: 'Ver cómo configurar',
      materialUrl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/6798006471067',
    },

    internalDomainNotPointed: {
      internalDomainNotPointedTitle: 'Dominio no configurado para el alojamiento de HostGator',
      internalDomainNotPointedDescription: ({ domain }) => (
        <>
          {'Vimos que el dominio "'}
          <strong>{domain}</strong>
          {'" no está configurado (DNS apuntado) para el Plan de Alojamiento de HostGator.'}
          <br />
          <br />
          Para que puedas administrar tu sitio y tener el Certificado SSL activo en este alojamiento, será necesario configurar tu dominio (registro tipo A en la Zona de DNS) para tu plan.
          <br />
          <br />
          Si optas por mantener el apuntamiento para otro plan de alojamiento, debes acceder al panel de tu plan para administrar el certificado SSL.
        </>
      ),
      keepInOtherHostButtonLabel: 'Mantener en otro proveedor',
      keepInHostgatorButtonLabel: 'Mantener en HostGator',

      keepInHostgator: {
        keepInHostgatorTitle: 'Mantener sitio y SSL en HostGator',
        keepInHostgatorDescription: ({ domain }) => (
          <>
            {'Haremos los ajustes de los registros de Zona DNS en el dominio "'}
            <strong>{domain}</strong>
            {'" y por eso pasará por un nuevo período de '}
            <Link text="propagación." href="https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331" />
            <br />
            <br />
            {'Después de propagado, el '}
            <b>Certificado SSL se activará automáticamente</b>
            {' en hasta 24 horas.'}
          </>
        ),
        agreeButtonLabel: 'Estoy consciente y de acuerdo',
        pointingDnsLoading: 'Espere mientras realizamos los ajustes...',

        keepInHgSuccessTitle: 'Ajustes realizados en la Zona DNS',
        keepInHgSuccessDescription: ({ domain }) => (
          <>
            {'¡Los ajustes en la Zona DNS del dominio "'}
            <strong>{domain}</strong>
            {'" foram realizados! se han realizado! Espera los plazos de propagación del dominio y de activación del Certificado SSL.'}
          </>
        ),
        closeButton: 'Cerrar',

        keepInHgErrorTitle: 'No se pudo hacer los ajustes en la Zona DNS',
        keepInHgErrorDescription: ({ domain }) => (
          <>
            {'No se pudo realizar los ajustes en la Zona DNS del dominio "'}
            <strong>{domain}</strong>
            {'". Haz clic en el botón de abajo para abrir un chat con nuestro equipo de Soporte para que puedan verificar.'}
          </>
        ),
        cancelButtonLabel: 'Cancelar',
        openChatButtonLabel: 'Abrir chat',
      },
      keepInOtherHost: {
        keepInOtherHostTitle: 'Mantener sitio y SSL en otro proveedor',
        keepInOtherHostDescription: ({ domain }) => (
          <>
            {'Como has optado por mantener el sitio y SSL del dominio "'}
            <strong>{domain}</strong>
            {'" configurados para otro proveedor, la activación del Certificado SSL a través de HostGator no es posible. Sugerimos que te pongas en contacto con el Soporte de esa otra empresa.'}
          </>
        ),
        understoodButtonLabel: 'Entendido',
      },
    },

    autoSSL: {
      error: {
        title: 'Se ha producido un error al activar SSL',
        description: domain => (
          <>
            Hubo un error en la activación del SSL. No se pudo activar el Certificado SSL del dominio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            . Haz clic en el botón de abajo para intentar activarlo de nuevo
          </>
        ),
        button: 'Intentar activar de nuevo',
      },
      active: {
        title: 'Certificado SSL activado con éxito',
        description: domain => (
          <>
            ¡El Certificado SSL del dominio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            {' '}
            ha sido activado con éxito!
          </>
        ),
        button: 'Cerrar',
      },
      activation_error: {
        title: 'No se puede activar el SSL',
        description: domain => (
          <>
            ¡No fue posible completar la activación del certificado SSL para el dominio
            {' '}
            <strong>
              &quot;
              {domain}
              &quot;
            </strong>
            ! Haga clic en el botón de abajo para abrir un chat con nuestro equipo de soporte para que puedan comprobarlo.
          </>
        ),
        button: 'Abrir chat',
        cancel: 'Cancelar',
      },
    },
  },
  welcomeChecklistBanner: {
    header: {
      titleStart: userName => (`¡Hola, ${userName}! Vamos a empezar? 👋`),
      titleContinue: userName => (`¡Hola, ${userName}! ¡Sus proyectos le están esperando! 😊`),
      titleFinish: 'Vuelva a la lista de verificación de su proyecto cuando quiera 🐊✨',
      showChecklist: 'Mostrar checklist',
      hideChecklist: 'Ocultar checklist',
      dontShowThisBanner: 'No quiero ver más este panel',
      descriptions: {
        start: 'Preparamos esta guía para ayudarle en los primeros pasos de sus proyectos.',
        continue: 'Nuestros materiales fueron seleccionados para ayudarle a sacar su idea del papel.',
        finish: 'Nuestra guía siempre estará aquí para ayudarle en todas las etapas de su proyecto.',
      },
      step: 'PASO ',
    },
    blockMessage: 'Complete el paso anterior para acceder al contenido y continuar.',
    dnsCard: {
      title: 'Configurar dominio y DNS',
      description1: (
        <>
          El dominio es el
          <strong> punto de partida </strong>
          de su proyecto online, fundamental para fortalecer su marca en la web.
        </>),
      description2: 'Para garantizar que su sitio web y su correo electrónico funcionen de manera eficiente, asegúrese de que su dominio esté configurado correctamente.',
      link1: {
        label: 'Verificar la configuración de DNS',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360053080651-C%C3%B3mo-saber-si-el-DNS-est%C3%A1-apuntando-correctamente?utm_source=portal&utm_medium=passo1&utm_campaign=verificar-dns&utm_id=checklist-es',
      },
      link2: {
        label: 'Configurar dominio registrado en HostGator',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957-C%C3%B3mo-configurar-el-dominio-cambiar-DNS?utm_source=portal&utm_medium=passo1&utm_campaign=configurar-dominio&utm_id=checklist-es#portal',
      },
      link3: {
        label: 'Configurar dominio registrado externamente',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/6798006471067-C%C3%B3mo-configurar-el-dominio-cambiar-DNS-en-otro-proveedor-GoDaddy-Web-Empresa-Colombia-Hosting-Neubox-NIC-Chile?utm_source=portal&utm_medium=passo1&utm_campaign=configurar-dominio-externo&utm_id=checklist-es',
      },
    },
    emailCard: {
      title: 'Crear un Correo Electrónico Profesional',
      description1: 'Refuerce la credibilidad y el reconocimiento de su marca al comunicarse con clientes y socios. ',
      description2: (
        <>
          Cree direcciones de correo electrónico ilimitados usando su dominio, como por ejemplo
          <strong> vendas@tudominio.com.</strong>
        </>),
      button: 'Crear cuentas de correo',
      link1: {
        label: 'Cómo utilizar el correo electrónico en plataformas externas (Gmail, Outlook y otros)',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721888-C%C3%B3mo-utilizar-el-correo-electr%C3%B3nico-en-plataformas-externas-Gmail-Outlook-y-otros?utm_source=portal&utm_medium=passo2&utm_campaign=configurar-email-plataformas-externas&utm_id=checklist-es',
      },
    },
    createSiteCard: {
      title: 'Crear un Sitio web Profesional',
      description1: 'Cree un sitio web que inspire confianza y profesionalismo para sus visitantes, fortaleciendo la reputación de su marca o proyecto.',
      description2: 'Construya su presencia online y expanda su negocio.',
      button: 'Crear sitio web o Tienda virtual',
      link1: {
        label: 'Cómo crear un sitio web en WordPress',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217577968-C%C3%B3mo-crear-un-sitio-web-en-WordPress-a-trav%C3%A9s-del-Portal-del-Cliente?utm_source=portal&utm_medium=passo3&utm_campaign=criar-site-wordpress&utm_id=checklist-es',
      },
      link2: {
        label: 'Verificar el certificado de seguridad (SSL)',
        url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360008274932-Activaci%C3%B3n-del-certificado-SSL-gratuito-en-Hosting?utm_source=portal&utm_medium=passo3&utm_campaign=ativacao-ssl&utm_id=checklist-es',
      },
      link3: {
        label: 'Consejos para crear una Landing Page con éxito',
        url: 'https://www.hostgator.mx/blog/como-crear-una-landing-page/?utm_source=portal&utm_medium=passo3&utm_campaign=dicas-criar-lading-page&utm_id=checklist-es',
      },
    },

  },
};
