import { Modal, Button, Tooltip } from '@/pages/common';
import React, { useContext, useState, useEffect } from 'react';
import * as Styles from './ChoosePlanModal.styles';
import { IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { Card } from '../Card';

export default function ChoosePlanModal({
  setChosenPlan,
  handleCloseModal,
  multiDomainsPlans = [],
  chosenPlan,
  singleDomainsPlans,
  selectedDomainPlan,
  title,
  subtitle,
  tooltip,
  mainDomain,
  otherDomains,
  noOtherDomains,
  domains,
  domain,
  choosePlan,
  onChoosePlan,
  withGatorSolutionsPlans,
}) {
  const theme = useContext(ThemeContext);
  const { palette } = theme;
  const [selectedPlan, setSelectedPlan] = useState({});

  const handlePlanChoose = () => {
    setChosenPlan(selectedPlan);
    handleCloseModal();
    onChoosePlan();
  };

  useEffect(() => { if (chosenPlan) setSelectedPlan(chosenPlan); }, [chosenPlan]);

  const renderCardMainDomain = plan => (
    <Styles.MainDomain>
      {mainDomain}
      {plan.domain}
    </Styles.MainDomain>
  );

  const renderCardOthersDomains = (plan) => {
    const sitesLength = plan.sites.length;
    return (
      <Styles.OtherDomain>
        {otherDomains}

        {!!sitesLength && (
          `${plan.sites[0].site}  ${plan.sites.length === 1 ? '' : `/ ${plan.sites[1].site}`}`
        )}

        {sitesLength > 2 && (
          ` (+${(sitesLength - 2) + (sitesLength - 2 === 1 ? domain : domains)})`
        )}

        {!sitesLength && noOtherDomains}
      </Styles.OtherDomain>
    );
  };

  return (
    <Modal onClose={() => handleCloseModal(true)} maxWidth={800} testId="choose-plan-modal">
      <Styles.ModalContainer>
        <Styles.ModalTitle>{title}</Styles.ModalTitle>
        <Styles.ModalSubtitleWrapper>
          <Styles.ModalSubtitle>{subtitle}</Styles.ModalSubtitle>
          <Styles.TooltipWrapper>
            <Tooltip
              width="330"
              fixedDesktopPosition="bottomLeft"
              fixedMobilePosition="bottomLeft"
              text={tooltip}
              variant="dark"
            >
              <IconInfo color={palette.secondary.primary} size={18} />
            </Tooltip>
          </Styles.TooltipWrapper>
        </Styles.ModalSubtitleWrapper>
        <Styles.ModalBody>
          {selectedDomainPlan && singleDomainsPlans.find(plan => plan.whmcs_product_id === selectedDomainPlan.whmcs_product_id)
            && (
            <Card
              selected={withGatorSolutionsPlans ? selectedPlan.external_id === selectedDomainPlan.external_id : selectedPlan.id === selectedDomainPlan.id}
              testId="actual-plan-card"
              title={selectedDomainPlan.name}
              onClick={() => setSelectedPlan(selectedDomainPlan)}
              cursorPointer
            >
              {mainDomain}
              {selectedDomainPlan.domain}
            </Card>
            )
          }
          {multiDomainsPlans.map(plan => (
            <Card
              selected={withGatorSolutionsPlans ? selectedPlan.external_id === plan.external_id : selectedPlan.id === plan.id}
              testId="plan-card"
              title={plan.name}
              onClick={() => setSelectedPlan(plan)}
              key={plan.id}
              cursorPointer
            >
              {renderCardMainDomain(plan)}
              <Styles.Sites>
                {renderCardOthersDomains(plan)}
              </Styles.Sites>
            </Card>
          ))}
        </Styles.ModalBody>
        <Styles.ModalFooter>
          <Button
            testId="choose-plan-button"
            onClick={handlePlanChoose}
            variant="newPrimary"
            font="secondary"
            size="large"
            disabled={withGatorSolutionsPlans ? !selectedPlan.external_id : !selectedPlan.id}
            label={choosePlan}
          />
        </Styles.ModalFooter>
      </Styles.ModalContainer>
    </Modal>
  );
}
