/* eslint-disable no-underscore-dangle */
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { applyMiddleware, compose } from 'redux';
import apiGuards from '@/middleware/apiGuards';
import api from '@/middleware/api';
import { IS_PROD } from '@/config';

const getComposeEnhancersConfig = () => {
  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  return compose;
};

const composeEnhancers = !IS_PROD ? getComposeEnhancersConfig() : compose;

export const sagaMiddleware = createSagaMiddleware();

export default composeEnhancers(applyMiddleware(
  apiGuards,
  api,
  sagaMiddleware,
  thunk,
));
