import { FEATURES } from '@/pages/Products/CloudFlare/ManageCloudFlareHandler/ManageCloudFlareHandler.types';
import { Link } from 'gatorcomponents';
import React from 'react';

export default {
  hostingLabel: 'Hospedaje del sitio web',
  change: 'Cambiar',
  cloudflareCdnExplanation: 'La funcionalidad de CDN es integrada con los recursos de CloudFlare',
  searchPerDomain: 'Buscar por dominio',
  activateCdn: 'Ativar CDN',
  status: {
    nonexistentexternal: 'Pendiente',
    pendinginternal: 'En curso',
    pendingexternal: 'En curso',
    delayinternal: 'En curso',
    delayexternal: 'En curso',
    activationpointingerrorinternal: 'En curso',
    activationpointingerrorexternal: 'En curso',
    pendingdeactivationinternal: 'En curso',
    pendingdeactivationexternal: 'En curso',
    inactivationpointingerrorinternal: 'En curso',
    inactivationpointingerrorexternal: 'En curso',
  },
  manage: 'Administrar',
  externalDomain: 'Ese dominio fue registrado en otro lugar. Verifíquelo antes de activar el CDN.',
  ongoingActivation: (
    <>
      {'El  CDN se activará automáticamente después de configurar el dominio. Esto puede tardar hasta 72 horas. '}
      <Link
        text="Comprenda el plazo."
        href="https://suporte.hostgator.com.br/hc/pt-br/articles/30812694986771-O-que-%C3%A9-propaga%C3%A7%C3%A3o"
        target="_blank"
      />
    </>
  ),
  ongoingDeactivation: (
    <>
      {'El CDN se desactivará automáticamente. Esto puede tardar hasta 72 horas. '}
      <Link
        text="Comprenda la fecha límite."
        href="https://suporte.hostgator.com.br/hc/pt-br/articles/30812694986771-O-que-%C3%A9-propaga%C3%A7%C3%A3o"
        target="_blank"
      />
    </>
  ),
  noDomains: 'No se han encontrado resultados para su búsqueda',
  pendingTooltip: 'No es posible cambiar el CDN mientras la activación está en curso',
  cdnAlreadyActive: (
    <span>
      <strong>
        El CDN
      </strong>
      {' ya está activo en este sitio, dejándolo más rápido y seguro.'}
    </span>
  ),
  showMore: 'Mostrar más',
  verifyDomain: 'Verificar dominio',
  changePlanModal: {
    title: 'Seleccione un hospedaje',
    mainDomain: 'Dominio principal: ',
    back: 'Volver',
    continue: 'Continuar',
  },
  activateDeactivateInternalModal: {
    toActivateTitle: 'Activar CDN',
    toDeactivateTitle: 'Desactivar CDN',
    activatingTitle: 'Activación de CDN en curso',
    deactivatingTitle: 'Desactivación del CDN en curso',
    toActivateDescription: 'Active el CDN y deje su sitio web más rápido y seguro. Los cambios pueden tardar hasta  72 horas.',
    toDeactivateDescription: 'Desactivar el CDN puede dejar más lento y reducir la seguridad de su sitio. Los cambios pueden tardar hasta 72 horas.',
    activatingDescription: 'La activación de su CDN ha sido solicitada y está en curso. Acompañe la actualización del status en la pantalla de administración.',
    deactivatingDescription: 'La desactivación de su CDN fue solicitada y está en curso. Acompañe la actualización del status en la pantalla de administración.',
    cancelCta: 'Cancelar',
    confirmationCta: 'Ok, entendí',
    continueActivationCta: 'Activar CDN',
    continueDeactivationCta: 'Desactivar CDN',
    failedRequestTitle: 'Se ha producido un error en la solicitud',
    failedRequestDescription: 'Algo salió mal mientras procesábamos su solicitud. Por favor, inténtelo de nuevo.',
  },
  nameServerTooltip: (ns1, ns2) => (
    <>
      <strong>
        {'Servidor 1: '}
      </strong>
      {ns1}
      <br />
      <strong>
        {'Servidor 2: '}
      </strong>
      {ns2}
    </>
  ),
  manageCdnDisabledLabel: 'Esta funcionalidad estará disponible pronto para que usted gestione su CDN',
  refreshStatus: 'Actualizar status de la lista',
  noOpenStatus: 'No tiene ningún status abierto',
  waitRefreshTimeLimit: 'Espere hasta 1 hora para actualizar el status nuevamente',
  domainsError: 'Se produjo un problema al procesar su solicitud. Si persiste, intente nuevamente más tarde.',
  refreshList: 'Recarregar lista',
  manageCdnHandler: {
    hostingLabel: planName => `Alojamiento: ${planName}`,
    selectedDomainLabel: 'Dominio seleccionado',
    changeButtonLabel: 'Cambiar',
    readMoreButton: toggleOn => (toggleOn ? 'Leer menos' : 'Leer más'),
    footerLabel: 'Estas funcionalidades utilizan los recursos de Cloudflare',
    cdnCards: {
      [FEATURES.CLEAN_CACHE]: {
        cardTitle: 'Limpiar caché',
        cardDescription: 'Elimina los archivos en caché para forzar al sistema a buscar una nueva versión de estos archivos desde tu servidor web. Limpiar el caché puede hacer que tu sitio sea más lento temporalmente y sobrecargar tu servidor.',
        button: {
          buttonLabel: 'Limpiar caché',
        },
      },
      [FEATURES.DEVELOPER_MODE]: {
        cardTitle: 'Ver cambios en el sitio en tiempo real',
        cardDescription: 'Desactiva temporalmente algunas funciones como almacenamiento en caché, compresión y optimización de archivos, por tres horas (a menos que las desactives antes). Esto te permite ver inmediatamente cualquier cambio que hagas en tu sitio.',
        tooltipLabel: (
          <>
            {'Esta funcionalidad utiliza el '}
            <strong>modo desarrollador</strong>
            {' de Cloudflare'}
          </>
        ),
      },
      [FEATURES.UNDER_ATTACK_MODE]: {
        cardTitle: 'Modo bajo ataque',
        cardDescription: 'Realiza verificaciones adicionales para proteger tu sitio contra ataques de virus. Permite bloquear el tráfico sospechoso. Usa este modo cuando tu sitio esté siendo atacado. Cuando está activado, los visitantes verán una página de espera antes de acceder al sitio.',
      },
      [FEATURES.ALWAYS_ONLINE]: {
        cardTitle: 'Mantener el sitio siempre en línea',
        cardDescription: 'Mantén tu sitio accesible incluso cuando el servidor esté caído. Esta función utiliza copias de tus páginas guardadas en el Wayback Machine (un servicio en línea que archiva y permite ver versiones antiguas de sitios web).',
        tooltipLabel: (
          <>
            {'Esta funcionalidad utiliza el '}
            <strong>always online</strong>
            {' de Cloudflare'}
          </>
        ),
      },
    },
    cleanCacheModal: {
      loadingTitle: 'Limpieza de caché en progreso',
      successTitle: 'Limpieza de caché realizada',
      successDescription: 'Su sitio se actualizará con la última versión disponible en el servidor original.',
      okButtonLabel: 'Ok, entendido',
    },
  },
};
