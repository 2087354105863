import React, { } from 'react';
import * as Styles from './DisableAutoRenewHandler.styles';
import { useHistory, useParams } from 'react-router';
import useLocale from '@/hooks/useLocale';
import Star from '@/media/subscriptions/retention/star.svg';

import { Button, IconArrow, IconLoading } from 'gatorcomponents';
import { useDispatch, useSelector } from 'react-redux';
import Snappy from '@/media/billing/Snappy.svg';
import { isMobile } from '@/utils/Validators/validation';
import { billingActions } from '@/redux/modules';
import { WHMCS_URL } from '@/config';
import { RETENTION_TYPES } from '@/pages/billing/subscriptions/RetentionOfferPage/RetentionsOfferEnum';

const DisableAutoRenewHandler = ({
  loading = false,
  type = 1,
  handleDeclineRenewDiscount,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { subscriptionType, subscriptionId } = params;
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { retentionOffer } = billingLocale;
  const { offerRetentionCancel, loadingAcceptRetention } = useSelector(state => state.billing.subscriptions);

  const handleAcceptOfferButtonClick = () => {
    const actionSuccess = () => {
      if (type === RETENTION_TYPES.disable_auto_renewal) {
        history.push(`${routesLocale.subscriptions}${routesLocale.manageSubscription}/${subscriptionId}/${subscriptionType}`);
      }
      if (type === RETENTION_TYPES.change_payment_cycle) {
        window.open(`${WHMCS_URL}/index.php?m=custom_upgrade&action=selectupgrade&id=${subscriptionId}`, '_blank');
        history.push(`${routesLocale.sitesPage}`);
      }
    };

    dispatch(billingActions.subscriptions.acceptRetentionOffer.request(
      {
        subscriptionId,
        subscriptionType,
        offer: offerRetentionCancel,
        actionSuccess,
        showMessage: type === RETENTION_TYPES.disable_auto_renewal || false,
      },
    ));
  };

  const handleCancelButtonClick = () => {
    handleDeclineRenewDiscount && handleDeclineRenewDiscount();
  };

  return (
    <>
      <Styles.Wrapper data-testid="retention-offer-handler">
        {!isMobile && (
        <Styles.ContentStar>
          <Styles.StarImg src={Star} alt="Star" loading="lazy" />
        </Styles.ContentStar>
        )}
        <Styles.BenefitsWrapper>
          {loading && <IconLoading size="lg" />}
          {!loading && (
            <Styles.Content>
              <Styles.ContentWrapper>
                <Styles.BenefitsContent>
                  <div>
                    <Styles.Title data-testid="retention-title">{retentionOffer.title[type]}</Styles.Title>
                    <Styles.GreenDescription data-testid="retention-green-title">{retentionOffer.greenDescription[type]}</Styles.GreenDescription>
                    <Styles.Description data-testid="retention-offer-decription">{retentionOffer.description[type]}</Styles.Description>
                  </div>
                </Styles.BenefitsContent>
                <Styles.ControlWrapper>
                  <Button
                    label={loadingAcceptRetention ? (
                      <>
                        <IconLoading />
                        {retentionOffer.buttonLabel[type]}
                      </>
                    ) : retentionOffer.buttonLabel[type]}
                    variant="primary"
                    onClick={handleAcceptOfferButtonClick}
                    testId="retention-redirect-button"
                    disabled={loadingAcceptRetention}
                  />
                </Styles.ControlWrapper>
                <Styles.SnappyImg src={Snappy} alt="HostGator" loading="lazy" />
              </Styles.ContentWrapper>
              <Styles.Info data-testid="retention-offer-decription-info">{retentionOffer.note[type]}</Styles.Info>
            </Styles.Content>
          )}
        </Styles.BenefitsWrapper>
        <Styles.FooterContent>
          <Button
            label={retentionOffer.continueCancelButton}
            onClick={handleCancelButtonClick}
            variant="text"
            testId="retention-cancel-button"
            iconRight={<IconArrow direction="right" size="lg" color="primaryBlue" />}
          />
        </Styles.FooterContent>
      </Styles.Wrapper>
    </>
  );
};

export default DisableAutoRenewHandler;
