import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class CreatorAdvanced extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 426 377.2">
        <g id="Agrupar_1180" transform="translate(0 -28.4)">
          <path id="Caminho_2377" className={classes.st0} d="M280.1,197L426,112.4l-145.9-84l-145.2,85.3L280.1,197z" />
          <g id="Agrupar_1140" transform="translate(0 28.4)">
            <path id="Caminho_2378" className={classes.st1} d="M146.6,168.6l144.5-84L146.6,0L0,84.7L146.6,168.6z" />
            <path id="Caminho_2379" className={classes.st1} d="M357.9,292.5l-144.5,84.7L68.8,292.5V123.9l144.5-84.7l144.5,84.7V292.5z" />
          </g>
          <path id="Caminho_2380" className={classes.st0} d="M213.4,67.6l144.5,84.7v168.6l-144.5,84.7" />
          <path id="Caminho_2381" className={classes.st1} d="M280.8,274.8L426,191.5l-145.9-84.7l-145.9,84.7L280.8,274.8z" />
          <path id="Caminho_2382" className={classes.st0} d="M146.6,276.2l145.2-85.3l-145.2-84L0,191.5L146.6,276.2z" />
          <path id="Caminho_2383" className={classes.st2} d="M214,236.9l143.8-84.7L214,67.6L67.4,152.3L214,236.9z" />
          <path id="Caminho_2384" className={classes.st3} d="M214,236.9l143.8-84.7L214,67.6" />
          <path id="Caminho_2385" className={classes.st4} d="M214,236.9l31-18.6l-31-17.9l-31,17.9L214,236.9z" />
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(CreatorAdvanced);
