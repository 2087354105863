import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    margin: 8px 24px 2px 24px;
    background-color: ${theme.v2.colors.white.primary};
    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: auto;
    }
  `}
`;

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};

    height:92px;
    max-width: 100%;
    padding: 24px 24px 16px 186px;
    position: relative;
    @media (max-width: ${theme.v2.breakpoints.sm}){
      display: flex;
      flex-direction: column;
      padding: 8px 0px 24px 50px;
      width: 100%;
      height:auto;
    }
  `}
`;

export const TrianglesWrapper = styled.div`
${({ theme }) => css`
  left: 24px;
  position: absolute;
  top: 24px;

  @media (max-width: ${theme.v2.breakpoints.md}){
    left: 20px;
    position: absolute;
    top: 20px;
  }
    `}
  `;


export const SnappyImage = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    height: 42px;
    left: 88px;
    position:absolute;

    @media (max-width: ${theme.v2.breakpoints.md}){
      left: 43px;
    }
  `}
`;

export const Alert = styled.div`
  ${({ theme }) => css`
  padding: 10px 44px;
  margin: 0px 24px 0px 24px;
  height: auto;
  background-color: ${theme.v2.colors.brand.primaryUltraLight};
  text-align: center;

  @media (max-width: ${theme.v2.breakpoints.sm}){
    width: 100%;
    text-align: left;
    margin: 0px;
    padding: 8px 20px;
  }

`}
`;
export const Content = styled.div`
  ${({ theme }) => css`

    margin: 22px 0px 0px 16px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      max-width: 260px;
      text-align: right;

    }
`}
`;


export const AlertDescription = styled.span`
  ${
  ({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.regular};
    font-size: 14px;
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 229px;
      text-align:right;
      width: 100%;
    }
    span {
      font-weight: ${theme.v2.font.weights.bold};
    }
    a {
      font-size: 14px;
    }
  `}
`;

export const Description = styled.label`
  ${
  ({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.medium};
    font-size: 16px;
    letter-spacing: 0.01px;
    line-height: normal;
    margin: 0px auto 0px 0px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin: 0px 0px 0px auto;
      max-width: 229px;
      text-align:right;
      width: 100%;
    }
    span {
      color: ${theme.v2.colors.brand.primaryMedium};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;
