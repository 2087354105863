import styled, { css } from 'styled-components';

export const Bold = styled.strong`
  ${({ theme }) => css`
    font-weight: ${theme.font.weights.bold};
  `}
`;

export const Regular = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.weights.regular};
  `}
`;
