import React from 'react';
import * as Styles from './TicketsDescription.styles';
import SnappySvg from '@/media/ticket/snappy-hero.svg';
import {
  Button, IconTriangleDetail, IconDoubleChat,
} from 'gatorcomponents';

export const TicketsDescription = ({
  pendingAmount,
  ticketLocale,
  handleCreateNewTicket,
  handleOpenChat,
  hideChatButton,
}) => (
  <Styles.DescriptionWrapper>
    <IconTriangleDetail color="primaryBlue" />
    <Styles.SanppyImage src={SnappySvg} />
    <Styles.Description>
      {ticketLocale.listPage.listDescription(pendingAmount)}
    </Styles.Description>
    <Styles.ButtonsWrapper>
      {hideChatButton && (
        <Styles.ButtonWrapper chatButton>
          <Button
            variant="secondary"
            label={ticketLocale.listPage.startChat}
            onClick={handleOpenChat}
            testId="chatButton"
            iconLeft={<IconDoubleChat />}
            width="full"
          />
        </Styles.ButtonWrapper>
      )}
      <Styles.ButtonWrapper>
        <Button
          label={ticketLocale.listPage.openNewTicketLabel}
          onClick={handleCreateNewTicket}
          testId="viewButton"
        />
      </Styles.ButtonWrapper>

    </Styles.ButtonsWrapper>
  </Styles.DescriptionWrapper>
);
