import React, { useState } from 'react';


import useLocale from '@/hooks/useLocale';
import * as Styles from './TicketBarcodeCard.styles';
import { IconCopyContent } from '@/icons';

import CopyPasteButton from '../../../pages/common/v1/CopyPasteButton/CopyPasteButton';
import { Input } from '@/pages/common/v1/inputs';
import { isMobile } from '@/utils/Validators/validation';
import barCodeImg from '@/media/billing/barcode.png';
import { Button } from 'gatorcomponents';
import { CircularProgress } from '@material-ui/core';
import { Textarea } from '@/pages/common';

const TicketBarcodeCard = ({
  digitableLine = '34191.09248 77200.227195 72734.800005 7 01019888101988',
  barcode = null,
  onPrintTicket = null,
  onSaveTicketAsPDF = null,
  enabledBarcodeDisplay = false,

}) => {
  const { billing: billingLocale } = useLocale();
  const { manageSubscriptionPaymentInvoiceModal: manageSubscriptionPaymentInvoiceModalLocale } = billingLocale;
  const [loading, setLoading] = useState(false);

  const copyCodeTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.copyBarcodeLabel;
  const copiedCodeTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.copiedBarcodeLabel;
  const tipTitle = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tipTicketTitle;
  const tip1Ticket = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip1TicketLabel;
  const titleBarcode = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.infoPaymentByTicketTitle;

  const tip2Ticket0 = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip2TicketLabel[0];
  const tip2Ticket1 = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip2TicketLabel[1];
  const tip2Ticket2 = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip2TicketLabel[2];
  const tip2Ticket3 = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip2TicketLabel[3];
  const tip3Ticket = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.tip3TicketLabel;

  const saveTicketAsPDFLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.saveTicketAsPDF;
  const printTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.printTicket;

  const doubtsTicketLabel = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketDescription;
  const doubtsLink = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketLink;
  const doubtsURL = manageSubscriptionPaymentInvoiceModalLocale.ticketBlockInfo.doubtsTicketURL;

  const idContentDataTicket = 'content-data-ticket';

  const onFocus = (inputTicket) => {
    const buttonCopyTicket = document.getElementById('barcode-copy-paste-button');
    inputTicket.target.select();
    buttonCopyTicket.click();
  };

  const handleOnPrintTicket = () => {
    setLoading(true);
    onPrintTicket != null && onPrintTicket();
    setLoading(false);
  };


  const handleOnSaveTicketAsPDF = () => {
    setLoading(true);
    onSaveTicketAsPDF != null && onSaveTicketAsPDF();
    setLoading(false);
  };

  return (
    <>
      <Styles.ContentTitleTicket data-testid={idContentDataTicket} id={idContentDataTicket} displayBarcode={enabledBarcodeDisplay}>
        <Styles.TicketTitle>
          {titleBarcode()}
        </Styles.TicketTitle>
      </Styles.ContentTitleTicket>
      {!isMobile && enabledBarcodeDisplay && (
        <Styles.ContentBarcode>
          <Styles.Barcode
            variant="default"
            src={barcode != null ? `data:image/png;base64,${barcode}` : barCodeImg}
            loading="lazy"
          />
        </Styles.ContentBarcode>
      )}
      <Styles.ContentTicketCode data-testid="content-ticket-code">
        <Styles.ContentInputTicketCode
          data-testeid="content-input-ticket-code"
          onFocus={onFocus}
        >
          {!isMobile && (
          <Input
            testId="input-ticket-code"
            readOnly
            fullWidth
            type="text"
            value={digitableLine}
          />
          )}
          {isMobile && (
            <Textarea
              testId="textarea-ticket-code"
              readOnly
              type="text"
              value={digitableLine}
            />
          )}
        </Styles.ContentInputTicketCode>
        <CopyPasteButton
          id="barcode-copy-paste-button"
          testId="barcode-copy-paste-button"
          clipBoardText={digitableLine}
          copyLabel={copyCodeTicketLabel}
          copiedLabel={copiedCodeTicketLabel}
          timeOut={5}
          contentButtonId={idContentDataTicket}
          icon={<IconCopyContent size="16" />}
        />
      </Styles.ContentTicketCode>

      <Styles.Content
        variant="default"
        data-testid="content-data-info"
      >
        <Styles.TipTitle>
          {tipTitle}
        </Styles.TipTitle>
        <Styles.List>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>01</Styles.Number>
              <Styles.Label>{tip1Ticket}</Styles.Label>
            </Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>02</Styles.Number>
              <Styles.Label>
                {tip2Ticket0}
                <strong>{tip2Ticket1}</strong>
                {tip2Ticket2}
                <strong>{tip2Ticket3}</strong>
              </Styles.Label>
            </Styles.Description>
          </Styles.Item>
          <Styles.Item>
            <Styles.Description>
              <Styles.Number>03</Styles.Number>
              <Styles.Label>{tip3Ticket}</Styles.Label>
            </Styles.Description>
          </Styles.Item>
        </Styles.List>
      </Styles.Content>
      <Styles.ContentDoubts>
        {doubtsTicketLabel}
        <Styles.Link
          data-testid="generate-ticket-link"
          href={doubtsURL}
          target="_blank"
        >
          {doubtsLink}
        </Styles.Link>
      </Styles.ContentDoubts>
      <Styles.Controls>
        <Styles.ControlButton>
          <Button
            testId="save-ticket-button"
            onClick={handleOnSaveTicketAsPDF}
            variant={!isMobile ? 'secondary' : 'primary'}
            label={loading
              ? (
                <>
                  <CircularProgress color="#00000" size={16} data-id="to-ticket-loader" />
                  {'   '}
                  {saveTicketAsPDFLabel}
                </>
              )
              : saveTicketAsPDFLabel}
          />
          {!isMobile && (
          <Button
            testId="print-ticket-button"
            onClick={handleOnPrintTicket}
            label={loading
              ? (
                <>
                  <CircularProgress color="#00000" size={16} data-id="to-ticket-loader" />
                  {'   '}
                  {printTicketLabel}
                </>
              )
              : printTicketLabel}
          />
          )}
        </Styles.ControlButton>
      </Styles.Controls>
    </>
  );
};


export default TicketBarcodeCard;
