import videoClassesBR from './videoClasses.br';
import videoClassesCL from './videoClasses.cl';
import videoClassesCO from './videoClasses.co';
import videoClassesMX from './videoClasses.mx';

const videoClasses = {
  BR: videoClassesBR,
  CL: videoClassesCL,
  CO: videoClassesCO,
  MX: videoClassesMX,
};

export default videoClasses;
