const styles = theme => ({
  message: {
    fontSize: '14px',
    color: theme.color.regentGray,
    textAlign: 'center',
    marginTop: '18px',
    fontWeight: '400',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
  },
  linkMessage: {
    color: theme.color.indigo,
    textDecoration: 'none',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default styles;
