import styled, { css } from 'styled-components';

export const WrapperBannerTrial = styled.div`
  ${({ theme }) => css`

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 32px;
    }
  `}
`;


const styles = theme => ({

  info: {
    fill: theme.color.indigo,
    height: '16px',
  },

  toolTip: {
    marginLeft: '2px',
  },

  loadingMoreButton: {
    alignItems: 'center',
    backgroundColor: theme.color.white,
    border: `1px solid ${theme.color.indigo}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 0px 0px ',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: '32px',
    maxWidth: '300px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    width: '161px',
    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.color.regentGray}`,
      color: theme.color.white,
    },
  },

  loadingMoreButtonDisabled: {
    alignItems: 'center',
    backgroundColor: theme.color.mystic,
    border: `1px solid ${theme.color.regentGray}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 0px 0px ',
    color: theme.color.indigo,
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: '500',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: '32px',
    maxWidth: '300px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    width: '161px',
  },

  card: {
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    marginBottom: 6,
    padding: '24px 0px',
    textAlign: 'center',
    '& .detailParam': {
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif',
    },
    '& .cardSubTitle': {
      color: theme.color.regentGray,
    },
  },

  logo: {
    height: '28px',
    position: 'absolute',
    width: '28px',
  },

  freeHostingLabel: {
    marginLeft: '36px',
    [theme.breakpoints.down(700)]: {
      marginLeft: '24px',
    },
  },

  mobileHostingLabel: {
    [theme.breakpoints.down(700)]: {
      marginLeft: '24px',
    },
  },

  cardContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    letterSpacing: '0.01px',
    minHeight: 100,
    textAlign: 'left',
    '&:last-child': {
      padding: '19px 24px 18px 24px',
    },
  },

  cardTitle: {
    color: theme.color.tundora,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '29px',
    width: '350px',
  },

  cardTitleMobile: {
    color: theme.color.tundora,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '29px',
    width: '100%',
  },

  details: {
    backgroundColor: theme.color.mystic,
    width: '100%',
    padding: '8px 0px 0px 0px',

  },

  expander: {
    color: theme.color.indigo,
  },

  rootPanelMobile: {
    height: '80px',
    marginLeft: '24px',
    padding: '0px',
  },

  rootPanel: {
    backgroundColor: theme.color.mystic,
    borderTop: `4px ${theme.color.mystic} solid`,
    height: '80px',
    padding: '0px',
    [theme.breakpoints.down(700)]: {
      height: '20px',
    },
  },

  expandedPanel: {
    position: 'unset',
  },

  rootPanelExpaned: {
    backgroundColor: theme.color.mystic,
    borderTop: '2px red solid',
    height: '80px',
    marginTop: '-20px',
    padding: '0px',
  },

  line: {
    borderColor: theme.color.mystic,
    opacity: '0.4',
    marginTop: '8px',
    [theme.breakpoints.down(700)]: {
      marginLeft: '24px',
      marginRight: '26px',
    },
  },

  divArrow: {
    color: theme.color.indigo,
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.01px',
    marginRight: '-30px',
    marginTop: '22px',
    textAlign: 'right',
  },

  moreLessLabel: {
    color: theme.color.indigo,
    display: 'inline-flex',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.01px',
    marginRight: '-30px',
    textAlign: 'right',
    textDecoration: 'underline',
    textDecorationColor: theme.color.indigo,
  },


  divShowMoreOrLess: {
    marginRight: '2px',
    marginTop: '2px',
    textAlign: 'right',
    width: '100%',
  },

  emptyCardDescription: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
  },
});

export default styles;
