import React, { useState } from 'react';
import useLocale from '@/hooks/useLocale/useLocale';
import { Modal } from '@/pages/common';
import * as Styles from './TicketOpenModal.styles';
import { Button, Select } from 'gatorcomponents';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { preChatActions } from '@/redux/modules';

const TicketOpenModal = () => {
  const [ticketSelected, setTicketSelect] = useState('');
  const preChatTosTickets = useSelector(state => state.preChat.tos);
  const { ticket: ticketLocale, routes: routesLocale } = useLocale();
  const history = useHistory();
  const dispatch = useDispatch();
  const hasTickets = preChatTosTickets.redirect && preChatTosTickets.tickets && !!preChatTosTickets.tickets.length;
  const ticketsOptions = hasTickets && preChatTosTickets.tickets.map(ticket => ({
    value: ticket.id,
    label: `Chamado ${ticket.id} - ${ticketLocale.ticketOpenModal.ticketStatusLabel(ticket.tag)}`,
  }));
  const ticketFiltered = hasTickets && preChatTosTickets.tickets.find(ticket => String(ticket.id) === String(ticketSelected));

  const handleModalClose = () => {
    dispatch(preChatActions.tos.redirect(false));
    dispatch(preChatActions.preChat.setScreen('problemSolved'));
    dispatch(preChatActions.preChat.setOpen(true));
  };

  const handleSelectTicket = (ticket) => {
    setTicketSelect(ticket.value);
  };

  const handleRedirectButtonClick = () => {
    ticketFiltered && history.push(`${routesLocale.ticket}/${ticketFiltered.id}`);
  };

  return hasTickets && (
    <Modal onClose={handleModalClose} testId="open-ticket-modal" maxWidth={624}>
      <Styles.ModalWrapper>
        <Styles.ModalTitle>{ticketLocale.ticketOpenModal.title}</Styles.ModalTitle>
        <Styles.ModalCaution>{ticketLocale.ticketOpenModal.cautionText}</Styles.ModalCaution>
        <Styles.ModalSelectWrapper>
          <Select
            options={ticketsOptions}
            placeholder={ticketLocale.ticketOpenModal.selectPlaceholder}
            label={ticketLocale.ticketOpenModal.selectLabel}
            onClickSelect={handleSelectTicket}
            testId="open-ticket-modal-select"
          />
        </Styles.ModalSelectWrapper>
        {!!ticketFiltered && (
          <Styles.ModalTicketWarning>
            {ticketLocale.ticketOpenModal.ticketWarningText(ticketFiltered.tag)}
          </Styles.ModalTicketWarning>
        )}
        <Styles.ModalButtonWrapper>
          <Button
            label={ticketLocale.ticketOpenModal.goToTicketBtnText}
            size="large"
            onClick={handleRedirectButtonClick}
            disabled={!ticketFiltered}
            testId="open-ticket-modal-go-to-ticket-btn"
          />
        </Styles.ModalButtonWrapper>
      </Styles.ModalWrapper>
    </Modal>
  );
};

export default TicketOpenModal;
