import styled, { css } from 'styled-components';

export const DnsWizardZoneTableWrapper = styled.div`
  ${() => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
  `}
`;

export const TextWrapper = styled.div`
  ${() => css`
      display: flex;
      align-items: center;
  `}
`;

export const IconWrapper = styled.div`
  ${({ orientation }) => {
    const rotate = orientation === 'up' ? 'rotate(0)' : 'rotate(180deg)';
    return css`
      display: flex;
      align-items: center;
      transform: ${rotate};
  `;
  }}
`;

export const ButtonWrapper = styled.div``;
