import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const TicketDivider = ({ classes, className }) => (
  <span className={`${classes.divider} ${className}`} />
);

TicketDivider.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(TicketDivider);
