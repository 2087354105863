const clearNewAlerts = (rawData) => {
  const updatedAlertsList = rawData.map((alert) => {
    if (alert.isNew) {
      return { ...alert, isNew: false };
    }
    return alert;
  });

  return updatedAlertsList;
};

const assembly = {
  clearNewAlerts,
};

export default assembly;
