import React from 'react';

export default {
  manualSelectDns: {
    backLabel: 'Voltar',
    cancelLabel: 'Cancelar',
    continueLabel: 'Continar',
    finishLabel: 'Ok, entendi',
    howToSetupLabel: 'Ver como configurar',
    backToInstructions: 'Voltar para instruções',
    close: 'Fechar',
    step: {
      checkStatus: {
        title: 'Verificar Domínio',
        subtitle: 'Verifique o status de configuração do seu domínio.',
        description: 'Estamos conferindo se está tudo pronto para ativar sua CDN...',
        whereDomainIsConfiguredLabel: 'Onde o domínio foi registrado?',
        selectHostPlaceholder: 'Selecionar provedor',
      },
      domainOriginalRegistration: {
        title: 'Verificar domínio',
        subtitle: 'Verifique o status de configuração do seu domínio.',
        alertDescription: domain => (
          <>
            {'Como o domínio '}
            <span>
              {`"${domain}" `}
            </span>
            não foi registrado na HostGator, você precisa acessar o site onde ele foi registrado e mudar as configurações para apontar para a sua hospedagem na HostGator.
          </>
        ),
        inputLabel: 'Selecione onde ele foi registrado',
        selectPlaceholder: 'Selecionar',
      },
      howToSetup: {
        title: 'Como configurar domínio no outro provedor de site ',
        howToSectionTitle: 'Siga o passo a passo para substituir seu nome de servidor',
        steps: ({ from }) => [
          {
            id: 'firstStep',
            description: 'Acesse o site do provedor onde seu domínio está registrado',
          },
          {
            id: 'secondStep',
            description: `Localize os nomes de servidores (DNS) atuais no site da ${from}`,
          },
          {
            id: 'thirdStep',
            description: `Substitua o nomes de servidores (DNS) que está no site da ${from} pelo da CloudFlare. Basta copiar e colar os valores abaixo:`,
            alert: 'Ao continuar suas configurações atuais do domínio serão alteradas',
          },
        ],
        serverLabel: index => `Servidor ${index + 1} (DNS): `,
        goTo: selectedManualDns => `ir para ${selectedManualDns}`,
        alreadyMadeChanges: 'Continuar',
        backtoInstructions: 'Voltar',
      },
      finishDomainSetup: {
        title: 'Finalizando as configurações de domínio',
        subtitle: 'Após ter realizado as configurações, acompanhe aqui o status de seu domínio.',
        box: {
          title: 'Alterações que você realizou:',
          description: ({ from }) => `DNS atual na ${from} para DNS da Hostgator`,
          alert: (
            <>
              <strong>
                {'Em andamento: '}
              </strong>
              {'A configuração do domínio ainda está acontecendo e pode levar até '}
              <strong>24h</strong>
              {' para domínios nacionais e até 72h para domínios internacionais.'}
            </>
          ),
        },
      },
      domainNotRegistered: {
        title: domain => `O domínio “${domain}” ainda não está registrado`,
        description: 'Para criar um novo site o domínio precisa estar registrado e ativo. Clique no botão para registrá-lo agora mesmo!',
        redirectLabel: 'Registrar domínio',
      },
    },
  },
};
