import { COUNTRY, SITE_HG } from '@/config';

const REGISTER_DOMAIN_URL_BY_BRAND = {
  br: `${SITE_HG}/registro-de-dominio`,
  cl: `${SITE_HG}/dominios`,
  co: `${SITE_HG}/dominios`,
  mx: `${SITE_HG}/dominios`,
};

const SUPPORT_DOMAINS_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/categories/115000074153',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202652148',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202652148',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202652148',
};

const SUPPORT_SECURITY_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/sections/360007329973-SiteLock',
};

const SUPPORT_HOST_AND_SERVERS_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/categories/115000074014',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202646727',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202646727',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/categories/202646727',
};

const TRANSFER_DOMAIN_REQUIREMENTS_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001894134',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747--C%C3%B3mo-transferir-un-dominio-a-HostGator-',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747--C%C3%B3mo-transferir-un-dominio-a-HostGator-',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747--C%C3%B3mo-transferir-un-dominio-a-HostGator-',
};

const TRANSFER_DOMAIN_TO_HG_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001894134',
  cl: `${SITE_HG}/dominios`,
  co: `${SITE_HG}/dominios`,
  mx: `${SITE_HG}/dominios`,
};

const TRANSFER_ES_DOMAIN_URL_BY_BRAND = {
  cl: `${SITE_HG}/transferir-dominios`,
  co: `${SITE_HG}/transferir-dominios`,
  mx: `${SITE_HG}/transferir-dominios`,
};

const DNS_WIZARD_HELP_URL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000497973#Opcao1',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
};

const DNS_WIZARD_PROPAGATION_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331',
};

const DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693#verificar',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331#propagado',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331#propagado',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331#propagado',
};

const DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM_SITE = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000497973#Opcao1',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217236957#opcion-1',
};

const DNS_WIZARD_EMAIL_HELP_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360041621714',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001162711',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001162711',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001162711',
};

const DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360002631274',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721368',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721368',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/216721368',
};

const EPP_KEY_HOW_TO_ORDER_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389394#solicitar',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360038889171',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360038889171',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360038889171',
};

const HOW_TO_TRANSFER_A_DOMAIN_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115001894134-Como-transferir-um-dom%C3%ADnio-para-a-HostGator-',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
};

const HOW_TO_CREATE_OR_ALTER_DNS_REGISTER_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389554-Como-criar-ou-alterar-um-registro-A-MX-TXT-CNAME-e-outros-na-Zona-DNS',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/217312747-C%C3%B3mo-transferir-la-administraci%C3%B3n-de-un-dominio-a-HostGator',
};

export const REGISTER_DOMAIN_URL = REGISTER_DOMAIN_URL_BY_BRAND[COUNTRY];
export const SUPPORT_DOMAINS_URL = SUPPORT_DOMAINS_URL_BY_BRAND[COUNTRY];
export const SUPPORT_HOST_AND_SERVERS_URL = SUPPORT_HOST_AND_SERVERS_URL_BY_BRAND[COUNTRY];
export const TRANSFER_DOMAIN_REQUIREMENTS_URL = TRANSFER_DOMAIN_REQUIREMENTS_URL_BY_BRAND[COUNTRY];
export const TRANSFER_DOMAIN_TO_HG_URL = TRANSFER_DOMAIN_TO_HG_URL_BY_BRAND[COUNTRY];
export const TRANSFER_ES_DOMAIN_URL = TRANSFER_ES_DOMAIN_URL_BY_BRAND[COUNTRY];

export const SUPPORT_SECURITY_URL = SUPPORT_SECURITY_URL_BY_BRAND[COUNTRY];
export const DNS_WIZARD_HELP_URL = DNS_WIZARD_HELP_URL_BY_BRAND[COUNTRY];
export const DNS_WIZARD_PROPAGATION = DNS_WIZARD_PROPAGATION_BY_BRAND[COUNTRY];
export const DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION = DNS_WIZARD_HOW_TO_VERIFY_PROPAGATION_BY_BRAND[COUNTRY];
export const DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM = DNS_WIZARD_HOW_TO_CONFIG_ALTER_PLATFORM_SITE[COUNTRY];
export const DNS_WIZARD_EMAIL_HELP = DNS_WIZARD_EMAIL_HELP_BY_BRAND[COUNTRY];
export const DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL = DNS_WIZARD_EMAIL_HOW_CONFIGURE_MAIL_BY_BRAND[COUNTRY];
export const EPP_KEY_HOW_TO_ORDER = EPP_KEY_HOW_TO_ORDER_BY_BRAND[COUNTRY];
export const HOW_TO_TRANSFER_A_DOMAIN = HOW_TO_TRANSFER_A_DOMAIN_BY_BRAND[COUNTRY];
export const HOW_TO_CREATE_OR_ALTER_DNS_REGISTER = HOW_TO_CREATE_OR_ALTER_DNS_REGISTER_BY_BRAND[COUNTRY];
