import { COUNTRY } from '@/config';

function mountZendeskTags({
  department,
  primaryReason,
  secondaryReason,
  product,
  whmcsId,
  departmentLocales,
  priority,
  isTicket,
}) {
  const isTechnicalDepartment = department === 'technical';
  const isFinancialDepartment = department === 'financial';
  const isPriorityDepartment = department === 'priority';
  const mountedTags = [];
  let zendeskDepartment;

  const noProductSelected = product === '&noproductselected=true';
  if (noProductSelected) {
    mountedTags.push('other_product_selected');
  }

  if (!isTechnicalDepartment) {
    zendeskDepartment = departmentLocales.financial;
  }

  if (isTechnicalDepartment) {
    zendeskDepartment = departmentLocales.shared;
  }

  if (isTechnicalDepartment && primaryReason === 'technical-others') {
    zendeskDepartment = departmentLocales.shared;
  }

  if (isTechnicalDepartment && primaryReason === 'dns') {
    zendeskDepartment = departmentLocales.domain;
  }

  if (isTechnicalDepartment && primaryReason === 'email') {
    zendeskDepartment = departmentLocales.mail;
  }

  if (isTechnicalDepartment && (primaryReason === 'wordpress-tool' || primaryReason === 'site-offline')) {
    zendeskDepartment = departmentLocales.builder;
  }

  if (isTechnicalDepartment && (primaryReason === 'site-offline' && secondaryReason === 'site-tos')) {
    zendeskDepartment = departmentLocales.tos;
  }

  if (department === 'skilled') {
    zendeskDepartment = departmentLocales.skilled;
  }

  if (isPriorityDepartment) {
    zendeskDepartment = departmentLocales.priority;
  }

  if (secondaryReason) {
    mountedTags.push(secondaryReason);
  }

  if (primaryReason) {
    mountedTags.push(primaryReason);
  }

  if (priority) {
    mountedTags.push(`priority_${priority}`);
  }

  if (COUNTRY === 'br' && !isFinancialDepartment && !isPriorityDepartment) {
    mountedTags.push(isTicket ? 'hg_messaging_email' : 'hg_messaging_chat');
  }

  mountedTags.push(`whmcs_id: ${whmcsId}`);
  mountedTags.push(COUNTRY);
  mountedTags.push(zendeskDepartment);

  return mountedTags;
}

export default mountZendeskTags;
