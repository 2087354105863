import React, { } from 'react';
import useLocale from '@/hooks/useLocale';
import * as Styles from './SaversDiscountBanner.styles';

import AlarmIcon from '@/media/billing/alarm-icon.svg';
import { XRAY_ENABLED_DISCOUNT_OFFER_BANNER } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const SaversDiscountBanner = () => {
  const { billing: billingLocale } = useLocale();
  const { saversDiscountBanner: saversDiscountBannerLocale } = billingLocale;
  const enabledDiscountOfferBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_OFFER_BANNER);

  return (
    <>
      {enabledDiscountOfferBanner && (
        <Styles.Content data-testid="savers-banner-content">
          <Styles.Icon src={AlarmIcon} alt="alarm icon" />
          <Styles.ContentText>
            <Styles.Title data-testid="savers-banner-title">{saversDiscountBannerLocale.title}</Styles.Title>
          </Styles.ContentText>
        </Styles.Content>
      )
      }
    </>
  );
};


export default SaversDiscountBanner;
