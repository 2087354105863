import styled, { css } from 'styled-components';

export const FilterWrapper = styled.div`
  ${({ displayed, mobileBreakPoint }) => css`
    transition: max-height 200ms ease-in-out;
    width: 100%;

    @media (max-width: ${mobileBreakPoint}) {
      max-height:${displayed ? '700px' : '35px'};
      overflow:${displayed ? 'visible' : 'hidden'};
    }
  `}
`;

export const Row = styled.div`
  ${({ mobileBreakPoint, variant }) => {
    const variants = {
      default: {
        justifyContent: 'center',
        padding: '11px 17px 11px 24px',
      },
      secondary: {
        justifyContent: 'flex-start',
        padding: '8px 0px',
      },
    };

    return css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    @media (max-width: ${mobileBreakPoint}){
      align-items: flex-start;
      flex-direction: column;

      & >:last-child {
        margin-top: 8px;
        width: 100%;

        > button {
          justify-content: ${variants[variant].justifyContent};
          padding: ${variants[variant].padding} ;
        }
      }
    }
    `;
  }}
`;

export const FilterLabel = styled.article`
  ${({
    theme, fontFamily, variant, mobileBreakPoint,
  }) => {
    const variants = {
      default: {
        margin: '6px 10px 6px 0px',
      },
      secondary: {
        margin: '6px 16px 6px 0px',
      },
    };
    return css`
      align-items:center;
      color: ${theme.palette.grey.dark};
      font-family: ${theme.font.family[fontFamily]};
      font-size: 14px;
      letter-spacing: 0.01px;
      line-height: 19px;
      margin: ${variants[variant].margin};

      @media (max-width: ${mobileBreakPoint}){
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin: 12px 0px 6px 0px;
        width: 100%;
      }
    `;
  }}
`;

export const DropdownButton = styled.button`
  ${({ displayed, mobileBreakPoint }) => css`
    background-color:transparent;
    border: unset;
    margin-left:auto;
    transform: rotate(${displayed ? '0deg' : '180deg'});
    transition: transform 200ms ease-in-out;

    @media (min-width: ${mobileBreakPoint}){
      display:none;
      visibility: hidden
    }
  `}
`;

export const OptionsWrapper = styled.ul`
  ${({ mobileBreakPoint }) => css`
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1;
    margin: unset;
    padding: unset;

    @media (max-width:${mobileBreakPoint}){
      flex: unset;
      width: 100%;
    }
  `}
`;

export const OptionItem = styled.li`
  ${({ theme, variant }) => {
    const variants = {
      default: {
        checked: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white.light,
        },
        hover: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white.light,
        },
        margin: '6px 12px 6px 0px',
      },
      secondary: {
        checked: {
          backgroundColor: theme.palette.blue.dark,
          color: theme.palette.white.light,
        },
        hover: {
          backgroundColor: theme.color.vividBlue,
          color: theme.palette.white.light,
        },
        margin: '6px 8px 6px 0px',
      },
    };

    return css`
      min-height: 22px;
      display: flex;
      list-style: none;
      margin: ${variants[variant].margin};

      &:hover label{
        background-color: ${variants[variant].hover.backgroundColor};
        color: ${variants[variant].hover.color};
      }

      input:checked ~ label{
        background-color: ${variants[variant].checked.backgroundColor};
        color: ${variants[variant].checked.color};
      }
    `;
  }}
`;

export const OptionLabel = styled.label`
  ${({ theme, fontFamily, variant }) => {
    const variants = {
      default: {
        color: theme.palette.grey.dark,
        checked: {
          color: theme.palette.white.light,
        },
      },
      secondary: {
        color: theme.color.vividBlue,
        checked: {
          color: theme.palette.white.light,
        },
      },
    };

    return css`
      border-radius: 15px;
      color: ${variants[variant].color};
      cursor: pointer;
      font-family: ${theme.font.family[fontFamily]};
      font-size: ${theme.font.sizes.xxs};
      letter-spacing: 0.01px;
      line-height: 16px;
      padding: 3px 12px;
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    `;
  }}
`;

export const OptionInput = styled.input`
  ${() => css`
    display:none;
  `}
`;

export const MultiSelectWrapper = styled.div`
  ${({ theme }) => css`
    position:relative;
    width: 220px;
    margin-right: 20px;
    @media (max-width: ${theme.breakpoints.md}){
      margin-top: 10px;
      margin-right: 0px;
      width: 100%;
    }
  `}
`;

export const InputWrapper = styled.div`
${({
    theme, iconSide, variant, mobileBreakPoint,
  }) => {
    const sides = {
      left: {
        position: 'absolute',
        top: '4px',
        right: 'auto',
        bottom: 'auto',
        left: '8px',
      },
      right: {
        position: 'absolute',
        top: '2px',
        right: '14px',
        bottom: 'auto',
        left: 'auto',
      },
    };
    return css`
      position:relative;
      svg {
        position: ${sides[iconSide].position};
        top: ${sides[iconSide].top};
        right: ${sides[iconSide].right};
        bottom: ${sides[iconSide].bottom};
        left: ${sides[iconSide].left};
        fill: ${variant === 'secondary' ? theme.palette.secondary.primary : theme.palette.grey.darklight};
      }
      @media (max-width: ${mobileBreakPoint}){
       margin-top: 10px;
       width: 100%;
      }
    `;
  }}`;

export const InputField = styled.input`
${({
    theme, variant, fontFamily, mobileBreakPoint,
  }) => {
    const variants = {
      default: {
        borderRadius: '2px',
        borderWidth: '1px',
        borderColor: `${theme.palette.grey.light}`,
        borderStyle: 'solid',
        color: `${theme.palette.grey.dark}`,
        fontSize: `${theme.font.sizes.sm}`,
        fontWeight: `${theme.font.weights.regular}`,
        maxWidth: '100%',
        padding: '7px 17px 6px 41px',
        width: '250px',
        focusOutline: 'none',
      },
      secondary: {
        borderRadius: '2px',
        borderWidth: '0px 0px 1px 0px',
        borderColor: `${theme.palette.grey.main}`,
        borderStyle: 'solid',
        color: `${theme.palette.grey.dark}`,
        fontSize: `${theme.font.sizes.sm}`,
        fontWeight: `${theme.font.weights.regular}`,
        maxWidth: '100%',
        padding: '7px 42px 6px 16px',
        width: '300px',
        focusOutline: 'none',
      },
    };
    return css`
    border-color: ${variants[variant].borderColor};
    border-radius: ${variants[variant].borderRadius};
    border-style: ${variants[variant].borderStyle};
    border-width: ${variants[variant].borderWidth};
    color: ${variants[variant].color};
    font-family: ${theme.font.family[fontFamily]};
    font-size: ${variants[variant].fontSize};
    font-weight: ${variants[variant].fontWeight};
    max-width: ${variants[variant].maxWidth};
    padding: ${variants[variant].padding};
    width: ${variants[variant].width};
    &:focus {
       border-color: ${theme.palette.primary.main};
       outline: ${variants[variant].focusOutline}
     }
     &::placeholder{
       font-size: ${theme.font.sizes.sm};
       color: ${theme.palette.grey.main}
     }
     @media (max-width: ${theme.breakpoints.lg}){
       width: 250px;
      }
     @media (max-width: ${mobileBreakPoint}){
       width: 100%;
      }
    `;
  }}`;
