import axios from 'axios';
import { config } from '@/config';

const instance = axios.create({
  baseURL: config.TICKETS_API_URL,
  headers: {
    brand: config.COUNTRY,
    'Content-Type': 'application/json',
  },
});

const get = ({ url, headers }) => instance.get(url, { headers });
// 'delete' cannot be called on an identifier in strict mode.
const useDelete = ({ url, headers }) => instance.delete(url, { headers });
const post = ({ url, headers, data }) => instance.post(url, data, { headers });
const put = ({ url, headers, data }) => instance.put(url, data, { headers });
const patch = ({ url, headers, data }) => instance.patch(url, data, { headers });

const customerSupport = {
  instance,
  get,
  post,
  put,
  useDelete,
  patch,
};

export default customerSupport;
