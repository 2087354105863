import { IconDetailedInstitutional, IconEdit, IconInfo } from '@/icons';
import {
  Tooltip, SelectButton, Select, Button,
} from '@/pages/common/v1/';
import React, { useContext } from 'react';
import * as Styles from './WizardAbout.styles';
import { ThemeContext } from 'styled-components';
import Loader from '@/components/Layout/Loader';

const WizardAbout = ({
  title = "It's time to define the type and purpose of your website!",
  subtitle = 'Based on your answers we will indicate the best creation tool :)',
  question = {
    text: 'What type of website do you want to start creating?',
    tooltip: "Choose the type closest to your need and don't worry, later you can create other types if you wish.",
  },
  siteTypes = [
    {
      title: 'Other type of site',
      subtitle: null,
      iconLeft: <IconDetailedInstitutional />,
      id: 'other_button',
      value: 'other',
      checked: false,
    },
  ],
  checkedItem = '',
  onChange,
  objectiveOptions = [],
  selectedObjectiveIndex = null,
  handleChangeObjective,
  handleClickObjective,
  objectivesQuestion = 'Select the main object you are aiming:',
  objectivesTooltip = 'Even you have more than one objective, choose the one you wish the most at this moment.',
  continueButton = 'Continue',
  objectivesPlaceholder = 'Select objective',
  onContinue,
  setDisplayOthersModal,
  othersFieldEditMode,
  sitesLoading,
}) => {
  const handleContinue = () => {
    onContinue && onContinue(3);
  };
  const theme = useContext(ThemeContext);
  return (
    <Styles.Wrapper>
      <Styles.Title data-testid="title">
        {title}
      </Styles.Title>
      <Styles.Subtitle data-testid="subtitle">
        {subtitle}
      </Styles.Subtitle>

      <Styles.LabelWrapper>
        <Styles.Triangle data-testid="triangle" />
        <Styles.Label data-testid="select-label">
          {question.text}
        </Styles.Label>

        {question.tooltip && (
          <Tooltip
            content={question.tooltip}
            width="400px"
          >
            <Styles.IconWrapper>
              <IconInfo size={18} color={theme.v1.color.action.primary} />
            </Styles.IconWrapper>
          </Tooltip>
        )}
      </Styles.LabelWrapper>

      <Styles.TypesWrapper>
        {siteTypes.map(siteType => (
          <Styles.ButtonWrapper key={siteType.id}>
            <SelectButton
              title={siteType.title}
              subtitle={siteType.subtitle}
              iconLeft={siteType.iconLeft}
              id={siteType.id}
              value={siteType.value}
              checked={siteType.value === checkedItem}
              onChange={(event, value) => onChange(event, value)}
              truncate
              truncateMaxWidth="205px"
              truncateTitle={siteType.value === 'other' && siteType.title}
              iconRight={siteType.value === 'other' && othersFieldEditMode && <Styles.EditIconWrapper onClick={() => setDisplayOthersModal(true)}><IconEdit /></Styles.EditIconWrapper>}
              testId={siteType.id}
            />
          </Styles.ButtonWrapper>
        ))}
      </Styles.TypesWrapper>

      {checkedItem && (
        <>
          <Styles.LabelWrapper>
            <Styles.Triangle data-testid="triangle" />
            <Styles.Label data-testid="select-label">
              {objectivesQuestion}
            </Styles.Label>

            {question.tooltip && (
            <Tooltip
              content={objectivesTooltip}
              width="400px"
            >
              <Styles.IconWrapper>
                <IconInfo size={18} color={theme.v1.color.action.primary} />
              </Styles.IconWrapper>
            </Tooltip>
            )}
          </Styles.LabelWrapper>

          <Styles.SelectWrapper>
            <Select
              onChange={handleChangeObjective}
              onClick={handleClickObjective}
              defaultOption={selectedObjectiveIndex}
              options={objectiveOptions}
              placeholder={objectivesPlaceholder}
              maxWidth="390px"
              optionsPosition="top"
            />
          </Styles.SelectWrapper>

          <Styles.ContinueWrapper>
            <Button
              testId="continue-button"
              label={sitesLoading ? <Loader size={12} /> : continueButton}
              disabled={selectedObjectiveIndex === null || sitesLoading}
              onClick={handleContinue}
            />
          </Styles.ContinueWrapper>
        </>
      )}
    </Styles.Wrapper>
  );
};

export default WizardAbout;
