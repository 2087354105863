const styles = ({
  wrapper: {
    paddingTop: '15px',
  },
  newWrapper: {
    paddingTop: '12px',
  },
  header: {
    display: 'flex',
    borderBottom: 'solid 1px #E1E1E1',
    paddingBottom: '24px',
    marginBottom: '25px',
    '@media (max-width:680px)': {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '25px',
    '@media (max-width:680px)': {
      marginBottom: '15px',
    },
  },
  paypalLogo: {
    height: '24px',
    marginRight: '15px',
    width: '20px',
  },
  title: {
    color: '#333333',
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: '18px',
    margin: '0px',
  },
  cardsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    width: '36px',
    height: '23px',
    margin: '0px 2px',
    '&:first-child': {
      marginLeft: '0px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  features: {
    borderBottom: 'solid 1px #E1E1E1',
    listStyleType: 'none',
    margin: '0px 0px 24px 0px',
    padding: '0px',
  },
  newFeatures: {
    listStyleType: 'none',
    margin: '0px 0px 0px 0px',
    padding: '0px',
  },
  feature: {
    paddingLeft: '20px',
    marginBottom: '18px',
    '&:last-child': {
      marginBottom: '24px',
    },
    '@media (max-width:680px)': {
      paddingLeft: '0px',
    },
  },
  newFeature: {
    paddingLeft: '0px',
    marginBottom: '18px',
    '&:last-child': {
      marginBottom: '0px',
    },
    '@media (max-width:680px)': {
      paddingLeft: '0px',
    },
  },
  featureTitle: {
    color: '#4480C5',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    margin: '0px',
  },
  featureDescription: {
    color: '#333333',
    fontSize: '14px',
    lineHeight: '18px',
    margin: '0px',
  },
});

export default styles;
