import React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useSnackbar } from 'notistack';
import styles from './styles';


const LinkDismiss = withStyles(styles)(({ id, classes }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      color="inherit"
      className={classes.icon}
      onClick={() => closeSnackbar(id)}
    >
      <Close />
    </IconButton>
  );
});

const LinkDismissAction = key => (
  <LinkDismiss id={key} />
);

export default LinkDismissAction;
