import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Receipt from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import {
  CardContent, Grid, IconButton, Card,
} from '@material-ui/core';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';


const ModalInvoiceWrapped = ({
  classes,
  modal,
  nfse,
  open,
  onClose,
  t,
}) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={open}
    onClose={onClose}
  >
    <div className={classes.paper}>
      <IconButton
        className={classes.closeIcon}
        aria-label="Delete"
        color="default"
        onClick={() => onClose()}
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.titleNf} variant="h4" id="modal-title">
        {t('nfseTitle')}
        <Typography className={classes.invoiceNumber}>
          <Receipt className={classes.iconReceiptNf} />
          {modal}
        </Typography>
      </Typography>
      <Grid container spacing={16}>
        {nfse.map(nota => (
          <Grid item key={shortid.generate()} xs={12}>
            <Card elevation={1} className={classes.invoiceContainer}>
              <CardContent className={`default ${classes.cardContentNf}`}>
                <Grid container spacing={8} alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <Typography className="cardSubTitle" variant="caption">{t('nfseNumber')}</Typography>
                    <Typography className={classes.subTitleNf} variant="h6">{nota.code}</Typography>
                  </Grid>
                  <Grid className={`cardActions ${classes.gridLinkNf}`} item xs={12} sm={3}>
                    <LinkButton target="_blank" to={nota.url}>{t('nfseLink')}</LinkButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  </Modal>
);

export default withI18n()(withStyles(styles)(ModalInvoiceWrapped));
