import styled, { css } from 'styled-components';

export const Wrapper = styled.nav`
  ${() => css`
  `}
`;

export const NotificationsCircle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.v2.colors.feedback.pendingLight};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: 14px;
    justify-content: center;
    position: absolute;
    user-select: none;
    width: 14px;
    left: 28px;
    top: 12px;
  `}
`;
