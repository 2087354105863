import { IconWarning } from '@/icons';
import React from 'react';
import * as Styles from './SimpleConfirmation.styles';

const SimpleConfirmation = ({
  attentionLabel = 'Attention',
  text = (
    <span>
      In case of cancellation of the plan
      {' '}
      <strong>you will no longer have access to your free emails.</strong>
    </span>
  ),
  testId = 'simpleConfirmation',
}) => (
  <Styles.Content data-testid={testId}>
    <Styles.AttentionLabel>
      <IconWarning size={22} />
      <strong>{attentionLabel}</strong>
    </Styles.AttentionLabel>
    <div>
      {text}
    </div>
  </Styles.Content>
);

export default SimpleConfirmation;
