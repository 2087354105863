/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, Fragment, useEffect,
} from 'react';
import {
  CircularProgress, TextField, withStyles, Button,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import * as yup from 'yup';
import Collapse from '@material-ui/core/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInputTextField from '@/components/Fields/InputMasks/MaskedInputTextField';
import LinkButton from '@/components/Buttons/LinkButton';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { zipcodeMask } from '@/utils/Masks/inputMasks';
import { loadZipCode } from '@/redux/actions/summary';
import { CEP_URL_BY_BRAND } from '@/config/urls/cepUrls';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { boletoUpdateAddress } from '@/redux/actions/invoices';
import { SUCCESS_BOLETO_UPDATE_ADDRESS } from '@/redux/actions/actionsTypes';
import styles from './styles';


const schemaCep = yup.object().shape({
  cep: yup
    .string()
    .required('errors.required')
    .matches(/\d{5}-\d{3}/, { message: 'myData.errors.cep' }),
});

const schemaForm = yup.object().shape({
  cep: yup
    .string()
    .required('errors.required')
    .matches(/\d{5}-\d{3}/, { message: 'myData.errors.cep' }),
  address1: yup
    .string()
    .required('errors.required'),
  address3: yup
    .string()
    .required('errors.required'),
  address2: yup
    .string()
    .required('errors.required'),
  state: yup
    .string()
    .required('errors.required'),
  city: yup
    .string()
    .required('errors.required'),
});

const BoletoAddressCheck = ({ classes, t }) => {
  const dispatch = useDispatch();

  const { loadingZipCode, addressComplement } = useSelector(({ summary }) => ({
    loadingZipCode: summary.loadingZipCode,
    addressComplement: summary.address3,
  }));

  const { urlBoleto, invoiceId } = useSelector(({ invoices }) => ({
    urlBoleto: invoices.boletoDataCheck.urlBoleto,
    invoiceId: invoices.boletoDataCheck.invoiceId,
  }));

  const [showForm, setShowForm] = useState(false);
  const [lastCep, setLastCep] = useState('');
  const [formData, setFormData] = useState({
    cep: '',
    address1: '',
    address2: '',
    address3: addressComplement,
    state: '',
    city: '',
  });
  const [formError, setFormError] = useState({
    cep: {
      error: false,
      message: '',
    },
    address1: {
      error: false,
      message: '',
    },
    address2: {
      error: false,
      message: '',
    },
    address3: {
      error: false,
      message: '',
    },
    state: {
      error: false,
      message: '',
    },
    city: {
      error: false,
      message: '',
    },
  });
  const urlGetCep = CEP_URL_BY_BRAND.br;

  const {
    cep,
    address1,
    address2,
    address3,
    state,
    city,
  } = formData;

  const getCepData = async (zipCode) => {
    if (lastCep === zipCode) return;

    const { data } = await dispatch(loadZipCode(zipCode));

    if (!data) {
      dispatch(enqueueSnackbar('myData.errors.cep', notifierErrorLong));
      return;
    }

    setFormData({
      ...formData,
      ...data,
    });
    setShowForm(true);
    setLastCep(zipCode);
  };

  const checkCepValidations = () => {
    schemaCep.validate(formData, { abortEarly: true }).then(() => {
      getCepData(cep);
    }).catch((error) => {
      setFormError({
        ...formError,
        [error.path]: {
          error: true,
          message: [error.errors],
        },
      });
    });
  };

  const onHandleChangeInput = async (eventTarget) => {
    const { value, name: key } = eventTarget;

    setFormData({
      ...formData,
      [key]: value,
    });
    setFormError({
      ...formError,
      [key]: {
        error: false,
        message: '',
      },
    });
  };

  useEffect(() => {
    cep.length === 9 && checkCepValidations('cep');
  }, [cep]);

  const generateBoleto = async (e) => {
    e.preventDefault();
    schemaForm.validate(formData, { abortEarly: true }).then(async () => {
      const formDataToSend = {
        invoiceId,
        address1,
        address2,
        address3,
        state,
        city,
        postCode: cep,
      };

      const response = await dispatch(boletoUpdateAddress(formDataToSend));

      if (response.type === SUCCESS_BOLETO_UPDATE_ADDRESS) {
        window.open(urlBoleto);
      } else {
        dispatch(enqueueSnackbar('myData.snackBar.updateError', notifierErrorLong));
      }
    }).catch((error) => {
      setFormError({
        ...formError,
        [error.path]: {
          error: true,
          message: [error.errors],
        },
      });
    });
  };

  return (
    <form onSubmit={e => generateBoleto(e)} className={classes.formAddress}>
      <LinkButton to={urlGetCep} target="_blank" className={classes.cep}>{t('myData.findMyCep')}</LinkButton>
      <MaskedInputTextField
        autoFocus
        label={t('myData.cep')}
        className={`${classes.textField}`}
        classes={{ root: classes.rootInput }}
        value={cep}
        error={formError.cep.error}
        required
        name="cep"
        placeholder={t('myData.typeHere')}
        onChange={e => onHandleChangeInput(e.target)}
        margin="normal"
        variant="outlined"
        mask={zipcodeMask.br}
        onBlur={() => checkCepValidations()}
        helperText={formError.cep.error && <span>{t(formError.cep.message)}</span>}
        autoComplete="on"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: loadingZipCode && <CircularProgress className={classes.loading} size={20} />,
        }}
      />


      <Collapse in={showForm}>
        <Fragment>
          <TextField
            label={t('myData.address')}
            className={classes.textField}
            classes={{ root: classes.rootInput }}
            value={address1}
            name="address1"
            placeholder={t('myData.typeHere')}
            onChange={e => onHandleChangeInput(e.target)}
            margin="normal"
            variant="outlined"
            required
            error={formError.address1.error}
            helperText={formError.address1.error && <span>{t(formError.address1.message)}</span>}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label={t('myData.numberComplement')}
            className={`${classes.textField} ${classes.leftItem}`}
            classes={{ root: classes.rootInput }}
            value={address3}
            name="address3"
            placeholder={t('myData.typeHere')}
            onChange={e => onHandleChangeInput(e.target)}
            margin="normal"
            variant="outlined"
            required
            error={formError.address3.error}
            helperText={formError.address3.error && <span>{t(formError.address3.message)}</span>}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div className={`${classes.textFieldsGroups}`}>
            <TextField
              label={t('myData.district')}
              className={`${classes.textField} ${classes.leftItem}`}
              classes={{ root: classes.rootInput }}
              value={address2}
              name="address2"
              placeholder={t('myData.typeHere')}
              onChange={e => onHandleChangeInput(e.target)}
              margin="normal"
              variant="outlined"
              required
              disabled
              error={formError.address2.error}
              helperText={formError.address2.error && <span>{t(formError.address2.message)}</span>}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label={t('myData.state')}
              className={`${classes.textField} ${classes.rightItem} ${classes.bottomSpace}`}
              classes={{ root: classes.rootInput }}
              value={state}
              name="state"
              placeholder={t('myData.typeHere')}
              onChange={e => onHandleChangeInput(e.target)}
              margin="normal"
              variant="outlined"
              error={formError.state.error}
              helperText={formError.state.error && <span>{t(formError.state.message)}</span>}
              required
              disabled
            />
          </div>

          <TextField
            label={t('myData.city')}
            className={classes.textField}
            classes={{ root: classes.rootInput }}
            value={city}
            placeholder={t('myData.typeHere')}
            onChange={e => onHandleChangeInput('city', e.target)}
            margin="normal"
            variant="outlined"
            error={formError.city.error}
            helperText={formError.city.error && <span>{t(formError.city.message)}</span>}
            required
            disabled
          />
        </Fragment>
      </Collapse>

      <div className={classes.footer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!showForm}
          className={classes.btn}
        >
          {t('dialogDataCheck.checkData')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(withI18n()(BoletoAddressCheck));
