import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './ViewImageModal.styles';

const ViewImageModal = ({
  imageModalIsVisible,
  setImageModalVisibale,
  image,
}) => imageModalIsVisible && (
<Modal
  maxWidth={780}
  maxHeight={9999}
  onClose={() => setImageModalVisibale(false)}
  variant="regular"
>
  <Styles.ImageModal data-testid="modalImage" src={image} loading="lazy" />
</Modal>
);

export default ViewImageModal;
