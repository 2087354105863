import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.canceledLight};
    font-family: ${theme.v2.font.family.primary};
    padding: 16px 24px 16px 24px;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin: 0;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;

    &:not(:first-child) {
      margin-top: 24px;
    }
    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      &:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowLight};
    display: inline-flex;
    font-size: 12px;
    font-weight: ${theme.v2.font.weights.regular};
    line-height: 16px;
  `}
`;

export const TooltipWrapper = styled.span`
  margin-left: 8px;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-size: 16px;
    font-weight: ${theme.v2.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;

    > div {
      position: relative;
    }

    > strong {
      margin: 0px 10px 0px 5px;
    }
  `}
`;

export const LineThrough = styled.span`
  ${({ discount }) => css`
    text-decoration: ${discount ? 'line-through' : 'none'}
  `}
`;

export const TagWrapper = styled.span`
${({ theme }) => css`

> div {
  background-color: ${theme.v2.colors.feedback.activePure};
  border-radius: 4px;
  font-weight: ${theme.v2.font.weights.bold};
  padding: 4px 8px;
}
`}
`;
