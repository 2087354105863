import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { authActions } from '../auth';
import { CloudflareActionTypes } from './cloudflare.types';
import { cloudflareActions } from './index';
import logger from '@/utils/logger';
import {
  AWS_GATOR_SOLUTIONS_URL,
  COUNTRY,
} from '@/config';
import { assembledCloudflareDomains } from './cloudflare.assembly';

function* requestDomains(action) {
  const { payload } = action;
  const { hostingId, useMockoonEnabled } = payload;

  yield put(authActions.tokens.jwt.update());
  const {
    token: whmcsToken,
    jwt: jwtToken,
  } = yield select(state => state.auth);

  const url = useMockoonEnabled
    ? `${AWS_GATOR_SOLUTIONS_URL}/api/v3/cloudflare/hosting/${hostingId}/domains`
    : `/api/v3/cloudflare/zones/${hostingId}`;

  const headers = useMockoonEnabled
    ? {
      Authorization: `Bearer ${whmcsToken}`,
      'x-region': COUNTRY,
      'x-service-token': jwtToken,
    }
    : {
      Authorization: `Bearer ${whmcsToken}`,
      'x-brand': COUNTRY,
    };


  const request = {
    url,
    headers,
  };

  yield put(cloudflareActions.domains.domainsLoading(true));
  try {
    const response = yield call(api.whmcs.get, request);

    const sorted = assembledCloudflareDomains(response.data.data);
    yield put(cloudflareActions.domains.domainsSet(sorted));
    yield put(cloudflareActions.domains.domainsSuccess());
    yield put(cloudflareActions.domains.domainsError(false));
  } catch (e) {
    const error = { sagasError: e, userRequestData: request, errorMessage: e.message };
    logger.error('Error sagas requestCloudflareDomains', error);
    yield put(cloudflareActions.domains.domainsFailure());
    yield put(cloudflareActions.domains.domainsError(true));
  } finally {
    yield put(cloudflareActions.domains.domainsLoading(false));
  }
}

function* activateCdn(action) {
  const { payload } = action;
  const {
    hostingId, domain, domainOrigin, useMockoonEnabled,
  } = payload;

  yield put(authActions.tokens.jwt.update());
  const {
    token: whmcsToken,
    jwt: jwtToken,
  } = yield select(state => state.auth);

  const url = useMockoonEnabled
    ? `${AWS_GATOR_SOLUTIONS_URL}/api/v3/cloudflare/zone/create`
    : '/api/v3/cloudflare/zone/create';

  const headers = useMockoonEnabled
    ? {
      Authorization: `Bearer ${whmcsToken}`,
      'x-region': COUNTRY,
      'x-service-token': jwtToken,
    }
    : {
      Authorization: `Bearer ${whmcsToken}`,
      'x-brand': COUNTRY,
    };


  const request = {
    url,
    headers,
    data: {
      hosting_id: hostingId,
      domain,
      domain_origin: domainOrigin,
    },
  };

  yield put(cloudflareActions.cdn.setLoadingActivation(true));
  try {
    const response = yield call(api.whmcs.post, request);
    yield put(cloudflareActions.cdn.setActivatedDomain({
      domain,
      status: 'success',
      ...response.data.data,
    }));
    yield put(cloudflareActions.cdn.succeededActivation());
  } catch (e) {
    const error = { sagasError: e, userRequestData: request, errorMessage: e.message };
    logger.error('Error sagas activateCdn', error);
    yield put(cloudflareActions.cdn.setActivatedDomain({
      domain,
      status: 'failure',
    }));
    yield put(cloudflareActions.cdn.faliedActivation());
  } finally {
    yield put(cloudflareActions.cdn.setLoadingActivation(false));
  }
}

function* deactivateCdn(action) {
  const { payload } = action;
  const {
    hostingId, domain, domainOrigin, useMockoonEnabled,
  } = payload;

  yield put(authActions.tokens.jwt.update());
  const {
    token: whmcsToken,
    jwt: jwtToken,
  } = yield select(state => state.auth);

  const url = useMockoonEnabled
    ? `${AWS_GATOR_SOLUTIONS_URL}/api/v3/cloudflare/zone/inactivate`
    : '/api/v3/cloudflare/zone/inactivate';

  const headers = useMockoonEnabled
    ? {
      Authorization: `Bearer ${whmcsToken}`,
      'x-region': COUNTRY,
      'x-service-token': jwtToken,
    }

    : {
      Authorization: `Bearer ${whmcsToken}`,
      'x-brand': COUNTRY,
    };

  const request = {
    url,
    headers,
    data: {
      hosting_id: hostingId,
      domain,
      domain_origin: domainOrigin,
    },
  };

  yield put(cloudflareActions.cdn.setLoadingActivation(true));
  try {
    yield call(api.whmcs.put, request);
    yield put(cloudflareActions.cdn.setActivatedDomain({ domain, status: 'success' }));
    yield put(cloudflareActions.cdn.succeededDeactivation());
  } catch (e) {
    const error = { sagasError: e, userRequestData: request, errorMessage: e.message };
    logger.error('Error sagas activateCdn', error);
    yield put(cloudflareActions.cdn.setActivatedDomain({ domain, status: 'failure' }));
    yield put(cloudflareActions.cdn.faliedDeactivation());
  } finally {
    yield put(cloudflareActions.cdn.setLoadingActivation(false));
  }
}

function* sagas() {
  yield takeLatest(CloudflareActionTypes.DOMAINS_REQUEST, requestDomains);
  yield takeLatest(CloudflareActionTypes.ACTIVATE_CDN, activateCdn);
  yield takeLatest(CloudflareActionTypes.DEACTIVATE_CDN, deactivateCdn);
}

export default sagas;
