const styles = theme => ({
  wrapper: {
    minHeight: 'calc(100vh - 80px)',
    padding: '0 24px',
    position: 'relative',
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  title: {
    color: theme.color.tundora,
    font: 'normal normal normal 22px/29px Roboto',
    letterSpacing: '0.01px',
    marginTop: '36px',
    textAlign: 'left',
  },
  subtitle: {
    color: theme.color.tundora,
    font: 'normal normal normal 14px/16px Roboto',
    letterSpacing: '0.01px',
    marginTop: '12px',
    marginBottom: '24px',
    textAlign: 'left',
  },
  messageBox: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up(960)]: {
      marginLeft: '40px',
      marginRight: '40px',
    },
  },
  loader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px',
  },
  boldText: {
    fontWeight: 'bold',
    textDecoration: 'none',
  },
});

export default styles;
