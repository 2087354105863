import React from 'react';
import { withStyles } from '@material-ui/core';
import OutlineButton from '@/components/Buttons/OutlineButton';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';


const FilterButton = ({
  classes,
  target,
  children,
  dataId,
}) => (
  <LinkButton to={target} className={classes.button} data-id={dataId}>
    <OutlineButton className={classes.outline}>{children}</OutlineButton>
  </LinkButton>
);

export default withStyles(styles)(FilterButton);
