import { Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { find, propEq } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import TitanUpgradeTitle from '@/components/Email/TitanUpgrade/TitanUpgradeTitle';
import LinkButton from '@/components/Buttons/LinkButton';
import getUsedAccounts from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/GetUsedAccoutns';
import { getUpgradeSummary } from '@/redux/actions/emails';
import { ERROR_GET_UPGRADE_SUMMARY } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import InvoiceTable from '@/components/Email/InvoiceTable';
import PaymentCycle from '@/components/Email/TitanUpgradePlanCycle/TitanUpgradePlanCycleContainer/PaymentCycle';
import PaymentMethod from '@/components/Email/TitanUpgradePlanCycle/TitanUpgradePlanCycleContainer/PaymentMethod';
import { COUNTRY } from '@/config';
import MailBoxesBlock from './MailBoxesBlock';
import styles from './styles';
import { ESSENTIALS_PLAN, PREMIUM_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { ALPHA_DISABLED_CYCLE_PAYMENT_IN_UPGRADE_PLAN } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

let typingDelay = null;
const UpgradeEnabled = ({
  t, classes, domainObject, plans,
}) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(getUsedAccounts(domainObject.total_accounts));
  const plan = find(propEq('name', domainObject.plan), plans);
  const newNextDueDate = addDays(new Date(), domainObject.billingcycle === 'Monthly' ? 31 : 365);
  const [summaryCalcs, setSummaryCalcs] = useState('0.00');
  const loadingUpgradeSummary = useSelector(state => state.emails.loadingUpgradeSummary);
  const [queryError, setQueryError] = useState(false);
  const [typing, setTyping] = useState(false);
  const { domain } = useParams();

  const validPaymentCycle = domainObject.billingcycle.toLowerCase() === 'monthly' || domainObject.billingcycle.toLowerCase() === 'quarterly' || domainObject.billingcycle.toLowerCase() === 'semiannually' || domainObject.billingcycle.toLowerCase() === 'annually';
  const [paymentCycle, setPaymentCycle] = useState(validPaymentCycle ? domainObject.billingcycle.toLowerCase() : 'monthly');
  const [paymentMethod, setPaymentMethod] = useState(COUNTRY === 'br' ? 'braspag_credit_1x' : 'dlocal');
  const disabledCyclePaymentUpgrade = useFeatureIsOn(ALPHA_DISABLED_CYCLE_PAYMENT_IN_UPGRADE_PLAN);

  const [disabledPayment, setDisabledPayment] = useState(false);

  useEffect(() => {
    clearTimeout(typingDelay);
    if (disabledCyclePaymentUpgrade) {
      setDisabledPayment((plan.id === ESSENTIALS_PLAN || plan.id === PREMIUM_PLAN) && amount === getUsedAccounts(domainObject.total_accounts));
    }
    const dispatchUpgrade = () => {
      dispatch(getUpgradeSummary({
        inboxAmount: amount, hostingId: domainObject.hosting_id, planId: plan.id, paymentCycle,
      })).then((response) => {
        if (response.type === ERROR_GET_UPGRADE_SUMMARY) {
          dispatch(enqueueSnackbar('proEmail.upgradePlan.error', notifierError));
          setQueryError(true);
        } else {
          setQueryError(false);
          setSummaryCalcs(response.data.data);
        }
        setTyping(false);
      });
    };

    if (amount !== domainObject.total_accounts) {
      typingDelay = setTimeout(() => {
        dispatchUpgrade();
      }, 300);
    }

    if (amount === domainObject.total_accounts) {
      setTyping(false);
    }
  }, [amount, dispatch, domainObject, t, plan, paymentCycle, disabledCyclePaymentUpgrade]);

  const cycleOptions = [
    { name: t('proEmail.upgradePlan.Monthly'), value: 'monthly' },
    { name: t('proEmail.upgradePlan.Quarterly'), value: 'quarterly' },
    { name: t('proEmail.upgradePlan.Semiannually'), value: 'semiannually' },
    { name: t('proEmail.upgradePlan.Annually'), value: 'annually' },
    { name: t('proEmail.upgradePlan.Biennially'), value: 'biennially' },
  ];

  const methodOptions = [
    { name: t('proEmail.upgradePlan.creditCard'), value: COUNTRY === 'br' ? 'braspag_credit_1x' : 'dlocal', span: 'Acesso imediato ao plano' },
    { name: t('proEmail.upgradePlan.gp_boleto'), value: COUNTRY === 'br' ? 'boleto' : 'payu_boleto' },
    { name: t('proEmail.upgradePlan.gp_paypal'), value: 'paypal' },
  ];

  return (
    <>
      <TitanUpgradeTitle plan={plan} domain={domainObject.domain} />
      <div className={classes.planContainer}>
        <Typography className={classnames(classes.containerTitle, classes.enabledUpgradeTitle)}>
          {t('proEmail.upgradePlan.upgradeMailBoxTitle')}
        </Typography>

        <Typography className={classnames(classes.description, classes.enabledUpgradeDescription)}>
          {t('proEmail.upgradePlan.upgradeMailBoxDescription')}
        </Typography>

        <MailBoxesBlock
          domainObject={domainObject}
          amount={amount}
          setAmount={setAmount}
          plan={plan}
          setTyping={setTyping}
        />

        <div className={classes.row}>
          <PaymentCycle
            disabled={disabledPayment}
            paymentCycle={paymentCycle}
            setPaymentCycle={setPaymentCycle}
            cycleOptions={cycleOptions}
          />
          <PaymentMethod
            disabled={disabledPayment}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            methodOptions={methodOptions}
          />
        </div>

        <InvoiceTable
          plan={plan}
          domainObject={domainObject}
          paymentCycle={paymentCycle}
          newNextDueDate={newNextDueDate}
          paymentMethod={paymentMethod}
          amount={amount}
          summaryCalcs={summaryCalcs}
          typing={typing}
          loadingUpgradeSummary={loadingUpgradeSummary}
          queryError={queryError}
          showMonthlyCycle
          calledFrom="inboxUpgrade"
        />

        {plan.id !== 424 && plan.id !== 243 && (
          <LinkButton
            target="blank"
            to={`${t('routes.emailsList')}${t('routes.titanUpgrade')}/${domain}`}
            className={classes.changePlanLink}
          >
            {t('proEmail.upgradePlan.changeCycleOrPlanLink')}
          </LinkButton>
        )}

      </div>
    </>
  );
};

UpgradeEnabled.propTypes = {
  domainObject: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
};

export default withI18n()(withStyles(styles)(UpgradeEnabled));
