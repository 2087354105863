import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {
  VideoLibrary, Schedule, FiberManualRecord, ExpandMore,
} from '@material-ui/icons';
import classnames from 'classnames';
import PropType from 'prop-types';
import { replaceAccents, replaceSpaceForHyphen } from '@/utils/Validators/validation';
import { formatSecondsToMinutes } from '@/utils/Formatters/formatTime';
import styles from './styles';


const CourseProgress = ({
  modules,
  classes,
  handleSelectVideo,
  selectedVideo,
  isAnimationEnded,
  userProgress,
  t,
}) => {
  const [expanded, setExpanded] = useState(selectedVideo.moduleKeyName);
  const { courseKeyName: selectedCourseKeyName } = selectedVideo;

  useEffect(() => {
    // Animation callback
    if (isAnimationEnded) {
      const moduleName = replaceAccents(replaceSpaceForHyphen(Object.keys(modules)[0]).toLowerCase());
      setExpanded(moduleName);
    }
  }, [isAnimationEnded, modules]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isVideoWatched = ({ watchedLinks, videoLink }) => watchedLinks && watchedLinks.includes(videoLink);

  return (
    <div className={classes.asideWrapper} data-testid="course-progress-component">
      {modules.map((videoModule) => {
        const { keyName } = videoModule;
        const moduleHasProgressSaved = !!userProgress[selectedCourseKeyName] && !!userProgress[selectedCourseKeyName][keyName];
        const moduleHasWatched = moduleHasProgressSaved && userProgress[selectedCourseKeyName][keyName].watched;
        const videosLength = videoModule.videos.length;

        return (
          <ExpansionPanel
            key={videoModule.id}
            expanded={expanded === keyName}
            onChange={handleChange(keyName)}
            classes={{ root: classes.wrapper }}
          >
            <ExpansionPanelSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              expandIcon={<ExpandMore />}
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
                expanded: classes.summaryExpanded,
              }}
              className={classnames({ [classes.summaryDone]: moduleHasWatched })}
            >
              <div className={classes.summaryWrapper}>
                <span>{videoModule.name}</span>
                <div className={classes.infoWrapper}>
                  <div className={classes.info}>
                    <VideoLibrary />
                    <span>{`${videoModule.total} aulas`}</span>
                  </div>
                  <div className={classes.info}>
                    <Schedule />
                    <span>{`${formatSecondsToMinutes(videoModule.duration)} ${t('courses.minutes')}`}</span>
                  </div>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.details }}>
              <ul className={classes.list}>
                {videoModule.videos.map((video, index) => {
                  const nextVideoIndex = index + 1;
                  const videoNumberIndex = nextVideoIndex;
                  const lastVideo = videosLength === videoNumberIndex;
                  const { courseKeyName, moduleKeyName } = video;
                  const hasWatchedVideos = userProgress[courseKeyName] && !!userProgress[courseKeyName][moduleKeyName];
                  const watchedLinks = hasWatchedVideos && userProgress[courseKeyName][moduleKeyName].watchedLinks;
                  const videoWatched = isVideoWatched({ watchedLinks, videoLink: video.link });
                  const nextVideoWatched = !lastVideo ? isVideoWatched({ watchedLinks, videoLink: videoModule.videos[nextVideoIndex].link }) : false;

                  return (
                    <li key={video.id} className={classes.listItem}>

                      <div className={classes.progressWrapper}>
                        <FiberManualRecord
                          className={
                              classnames(classes.progressBall, {
                                [classes.progressBallDone]: videoWatched,
                              })
                            }
                        />
                        {!lastVideo && (
                          <div
                            className={
                              classnames(classes.progressLine, {
                                [classes.progressLineDone]: nextVideoWatched,
                              })
                            }
                          />
                        )}
                      </div>

                      <div
                        onClick={() => handleSelectVideo(videoModule.id, video.id)}
                        role="presentation"
                        className={classnames(classes.wrapperVideoItem, {
                          [classes.selectedVideoItemAnimation]: selectedVideo && selectedVideo.id === video.id,
                          [classes.selectedVideoItemRemoveAnimation]: selectedVideo && selectedVideo.id !== video.id,
                        })}
                      >
                        <span className={classes.listTitle}>{`${videoNumberIndex}. ${video.name}`}</span>
                        <div className={classes.info}>
                          <Schedule />
                          <span>{`${formatSecondsToMinutes(video.duration)} ${t('courses.minutes')}`}</span>
                        </div>
                      </div>

                    </li>
                  );
                })}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

CourseProgress.propTypes = {
  modules: PropType.array.isRequired,
  handleSelectVideo: PropType.func.isRequired,
  selectedVideo: PropType.shape({
    courseKeyName: PropType.string.isRequired,
    id: PropType.string.isRequired,
  }).isRequired,
  isAnimationEnded: PropType.bool.isRequired,
  userProgress: PropType.object.isRequired,
};

export default withStyles(styles)(withI18n()(CourseProgress));
