const styles = theme => ({
  invoiceContainer: {
    width: '100%',
    borderLeft: `5px solid ${theme.color.indigo}`,
    margin: '0',
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    '& .cardActions': {
      padding: '0',
    },
    '& .cardActions > a': {
      '&:hover': {
        color: theme.color.chambray,
        textDecoration: 'underline',
      },
      transition: 'color 0.2s',
      textTransform: 'uppercase',
      textDecoration: 'none',
      textAlign: 'right',
      color: theme.color.indigo,
      fontWeight: '400',
      margin: 0,
    },
    '& .cardSubTitle': {
      color: theme.color.regentGray,
    },
  },
  subTitleNf: {
    fontSize: '14px',
    fontWeight: 500,
  },
  iconReceiptNf: {
    color: theme.color.oceanGreen,
  },
  titleNf: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: '15px',
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    minHeight: 'auto',
    maxHeight: 600,
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  invoiceNumber: {
    fontSize: {
      fontSize: '16px',
      fontWeight: 500,
    },
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    '& svg': {
      verticalAlign: 'text-bottom',
      margin: '0 10px',
    },
  },
  gridLinkNf: {
    '& > a': {
      fontSize: '14px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  cardContentNf: {
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingBottom: '8px',
      },
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

export default styles;
