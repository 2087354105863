import React from 'react';
import * as Styles from './Button.styles';

const Button = ({
  disabled = false,
  label = 'Button text',
  onClick,
  testId = 'button',
  type = 'button',
  variant = 'primary',
  size = 'regular',
  display = 'inline',
  title = null,
  customStyles = {
    color: null,
    backColor: null,
    borderColor: null,
    borderStyle: null,
    hoverBackColor: null,
    hoverBorderColor: null,
    hoverColor: null,
    disabledColor: null,
    disabledBackColor: null,
    disabledBorderColor: null,
    borderRadius: null,
    padding: null,
  },
}) => {
  const handleOnClick = (event) => {
    onClick && onClick(event);
  };

  return (
    <Styles.Button
      data-testid={testId}
      disabled={disabled}
      display={display}
      onClick={handleOnClick}
      size={size}
      title={title}
      type={type}
      variant={variant}
      customStyles={customStyles}
    >
      <Styles.Label>{label}</Styles.Label>
    </Styles.Button>
  );
};

export default Button;
