
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  collabItems,
  tagList,
  cardItems,
} from './values';
import creditas from '@/media/mktPlace/logo/creditas.svg';

export const MktPlaceContext = createContext();

let handleSeachTimeOut = null;
const MktPlaceContextProvider = ({ children }) => {
  const cards = null;
  const [showModal, toggleShowModal] = useState(false);
  const [renderedCards, setRenderedCards] = useState(cardItems);
  const shouldRenderCreditas = true;

  useEffect(() => {
    const filteredCards = cardItems.filter((card) => {
      if ((cards === 'off' && card.name === 'Cobre Fácil')) {
        return false;
      }
      if ((cards === 'off' && card.name === 'Trakto')) {
        return false;
      }
      return true;
    });
    setRenderedCards(filteredCards);
  }, [cards]);

  const [filteredOptions, setFilteredOptions] = useState(tagList);
  const [filterTyped, setFilterTyped] = useState('');


  const clearFilter = () => {
    const options = [...filteredOptions];
    options.map((option) => {
      const newOption = option;
      newOption.checked = false;
      return newOption;
    });
    options[0].checked = true;

    setFilteredOptions(options);
    setFilterTyped('');
  };

  useEffect(() => {
    clearTimeout(handleSeachTimeOut);
    handleSeachTimeOut = setTimeout(() => {
      if (cardItems.length && filteredOptions.length) {
        const shouldRenderAll = filteredOptions.find(filter => filter.value === 'all').checked;
        const activeOptions = filteredOptions.filter(filterOption => filterOption.checked);
        const optionFilteredSubscriptions = cardItems.filter((card) => {
          const shouldBeRendered = activeOptions.some(option => card.type.some(type => type === option.id));
          return shouldRenderAll || shouldBeRendered;
        });

        let termFilteredSubscriptions = optionFilteredSubscriptions.filter(subscription => JSON.stringify(subscription).toLowerCase().includes(filterTyped.toLowerCase()));

        if (cards === 'off') {
          termFilteredSubscriptions = termFilteredSubscriptions.filter(item => item.name !== 'Trakto');
          termFilteredSubscriptions = termFilteredSubscriptions.filter(item => item.name !== 'Cobre Fácil');
        }

        if (cards === 'cobre_facil') {
          termFilteredSubscriptions = termFilteredSubscriptions.filter(item => item.name !== 'Trakto');
        }

        if (cards === 'trakto') {
          termFilteredSubscriptions = termFilteredSubscriptions.filter(item => item.name !== 'Cobre Fácil');
        }

        let filteredCards = termFilteredSubscriptions.filter((card) => {
          if ((cards === 'off' && card.name === 'Cobre Fácil') || (shouldRenderCreditas && card.name === 'Cobre Fácil')) {
            return false;
          }
          if ((cards === 'off' && card.name === 'Trakto') || (shouldRenderCreditas && card.name === 'Trakto')) {
            return false;
          }
          if ((cards === 'off' && card.name === 'Creditas') || (!shouldRenderCreditas && card.name === 'Creditas')) {
            return false;
          }
          if (card.name === 'Asaas') {
            return false;
          }
          return true;
        });

        filteredCards = filteredCards.map((card) => {
          if (card.name === 'Creditas') {
            return {
              ...card,
              altLayout: true,
              logo: creditas,
            };
          }
          return card;
        });
        setRenderedCards(filteredCards);
      }
    }, 100);
  }, [filteredOptions, filterTyped, cards, shouldRenderCreditas]);


  const values = {
    filteredOptions,
    setFilteredOptions,
    filterTyped,
    setFilterTyped,
    clearFilter,
    cardItems,
    renderedCards,
    setRenderedCards,
    showModal,
    toggleShowModal,
    collabItems,
  };

  return (
    <MktPlaceContext.Provider value={values} t={key => key}>
      {children}
    </MktPlaceContext.Provider>
  );
};

MktPlaceContextProvider.propTypes = {
  children: PropTypes.node,
};

MktPlaceContextProvider.defaultProps = {
  children: null,
};

export default MktPlaceContextProvider;
