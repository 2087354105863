import React from 'react';
import * as Style from './IconWatch.style';

const IconWatch = ({
  color = '#FF9442',
  testId = 'icon-watch',
  size = '30',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <path id="schedule_FILL0_wght400_GRAD0_opsz48" d="M24.512,26.238,26.2,24.55l-5.963-6V11.013h-2.25V19.45ZM19,34a14.514,14.514,0,0,1-5.812-1.181,15.113,15.113,0,0,1-8.006-8.006,14.891,14.891,0,0,1,0-11.625,15.113,15.113,0,0,1,8.006-8.006,14.891,14.891,0,0,1,11.625,0,15.113,15.113,0,0,1,8.006,8.006,14.891,14.891,0,0,1,0,11.625,15.113,15.113,0,0,1-8.006,8.006A14.514,14.514,0,0,1,19,34ZM19,19Zm0,12.75A12.274,12.274,0,0,0,28,28a12.274,12.274,0,0,0,3.75-9A12.274,12.274,0,0,0,28,10a12.274,12.274,0,0,0-9-3.75A12.274,12.274,0,0,0,10,10a12.274,12.274,0,0,0-3.75,9A12.274,12.274,0,0,0,10,28,12.274,12.274,0,0,0,19,31.75Z" transform="translate(-4 -4)" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconWatch;
