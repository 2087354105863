import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const HeaderWrapper = styled.section`
  ${({ marginbottom }) => css`
    margin-bottom: ${marginbottom ? '10px' : '0px'};

    > header {
      box-shadow: none;
      padding: 16px 24px
    }
  `}
`;
