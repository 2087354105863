import { SecurityActionTypes } from './security.types';

export const initialState = {
  scanDetails: {
    loading: false,
    malwares: null,
    vulnerabilities: null,
    blocklists: null,
    last_check: null,
    next_check: null,
    verified_pages: null,
    verified_files: null,
  },
  scanHistory: [],
  scanHistoryLoading: false,
  seal: {
    requested: false,
    data: {
      color: null,
      size: null,
      type: null,
      language: null,
    },
    loading: false,
    saving: false,
  },
  upgrade: {
    loading: false,
    invoiceId: null,
  },
  configurations: {
    updateLoading: false,
    data: {},
    getLoading: false,
  },
  loadingSSO: false,
  hasNewSitelock: false,
};

const SecurityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SecurityActionTypes.SITELOCK_SCAN_DETAILS_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        scanDetails: {
          ...state.scanDetails,
          loading: isLoading,
        },
      };
    }
    case SecurityActionTypes.SET_SITELOCK_SCAN_DETAILS: {
      return {
        ...state,
        scanDetails: {
          ...state.scanDetails,
          ...action.payload,
        },
      };
    }
    case SecurityActionTypes.SET_SEAL_LOADING_STATUS: {
      return {
        ...state,
        seal: {
          ...state.seal,
          loading: action.payload.bool,
        },
      };
    }
    case SecurityActionTypes.SET_SEAL_SAVING_STATUS: {
      return {
        ...state,
        seal: {
          ...state.seal,
          saving: action.payload.bool,
        },
      };
    }
    case SecurityActionTypes.SET_SEAL: {
      return {
        ...state,
        seal: {
          ...state.seal,
          requested: true,
          data: {
            ...action.payload,
          },
        },
      };
    }
    case SecurityActionTypes.SITELOCK_SCAN_LIST_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        scanHistoryLoading: isLoading,
      };
    }
    case SecurityActionTypes.SET_SITELOCK_SCAN_LIST: {
      return {
        ...state,
        scanHistory: action.payload,
      };
    }
    case SecurityActionTypes.SET_UPGRADE_SITELOCK_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          loading: isLoading,
        },
      };
    }
    case SecurityActionTypes.SET_UPGRADE_SITELOCK: {
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          invoiceId: action.payload.invoice_id,
        },
      };
    }
    case SecurityActionTypes.UPDATE_CONFIGURATIONS_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        configurations: {
          ...state.configurations,
          updateLoading: isLoading,
        },
      };
    }
    case SecurityActionTypes.GET_CONFIGURATIONS_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        configurations: {
          ...state.configurations,
          getLoading: isLoading,
        },
      };
    }
    case SecurityActionTypes.SET_SITELOCK_CONFIGURATIONS: {
      const { data } = action.payload;
      return {
        ...state,
        configurations: {
          ...state.configurations,
          data,
        },
      };
    }
    case SecurityActionTypes.SET_SITELOCK_SSO_LOADING: {
      return {
        ...state,
        loadingSSO: action.payload.isLoading,
      };
    }
    case SecurityActionTypes.SET_CHECK_NEW_SITELOCK: {
      return {
        ...state,
        hasNewSitelock: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SecurityReducer;
