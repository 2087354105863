import * as Styles from './InvoiceHeader.styles';
import React, { useContext } from 'react';
import { SmallTriangles } from '@/icons/SmallTriangles';
import { ThemeContext } from 'styled-components';
import SnappyFace from '@/media/pageDescription/snappy-face.svg';
import useLocale from '@/hooks/useLocale';

const InvoiceHeader = ({
  image = SnappyFace,
  pendingInvoices = 0,
}) => {
  const theme = useContext(ThemeContext);
  const hasPendingInvoices = pendingInvoices > 0;
  const { billing: billingLocale } = useLocale();
  const { invoices: invoicesLocale } = billingLocale;
  return (
    <Styles.Wrapper>
      <Styles.WrapperContent data-testid="invoice-header">
        <Styles.TrianglesWrapper>
          <SmallTriangles color={theme.v2.colors.brand.primaryLight} />
        </Styles.TrianglesWrapper>
        <Styles.SnappyImage data-testid="page-description-snappy" src={image} />
        {hasPendingInvoices && (
          <Styles.Content>
            <Styles.Description data-testid="invoice-header-description">
              {invoicesLocale.headerInvoicesPage.text.pendingInvoices(pendingInvoices)}
            </Styles.Description>
          </Styles.Content>
        )}
      </Styles.WrapperContent>
    </Styles.Wrapper>
  );
};

export default InvoiceHeader;
