import React, { useEffect, useCallback, useState } from 'react';
import * as Styles from './DnsWizardPage.styles';
import DNSContextProvider from '@/contexts/DNS/context';
import { DnsWizardContextProvider } from '@/contexts/DnsWizard';
import { useDispatch, useSelector } from 'react-redux';
import {
  DnsWizardDomainHandler,
  DnsWizardEmailHandler,
  DnsWizardZoneRecordsHandler,
} from '@/components/domain/handlers';
import { dnsWizardActions } from '@/redux/modules/dnsWizard';
import useLocale from '@/hooks/useLocale';
import { useParams } from 'react-router-dom';
import { loadDnsDetails } from '@/redux/actions/dnsForward';
import { ALPHA_DOMAINS_WIZARD_SETUP_THE_EMAIL_PLATFORM } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';


const DnsWizardPage = () => {
  const [isFetchingItems, setIsFetchingItems] = useState(true);
  const [scrollToDNSZoneRecords, setScrollToDNSZoneRecords] = useState(false);
  const [showAdvanceDNSZoneModal, setShowAdvanceDNSZoneModal] = useState(false);
  const IS_STORYBOOK = process.env.STORYBOOK_IS_SB === '1';
  const shouldRenderEmailPlatformSelection = useFeatureIsOn(ALPHA_DOMAINS_WIZARD_SETUP_THE_EMAIL_PLATFORM);

  const dispatch = useDispatch();
  const { domains: domainsLocale, routes: routesLocale } = useLocale();
  const { dnsWizard: dnsWizardLocale } = domainsLocale;

  const { domainId } = useParams();
  const {
    domain,
    hostingPlatforms,
    controls,
    selectedConfiguration,
    hostGatorAvailablePlans,
  } = useSelector(state => state.dnsWizard);
  const {
    planDetails,
  } = domain;
  const { platforms, emailPlatforms } = hostingPlatforms;
  const { hostingPlans: availablePlans } = hostGatorAvailablePlans;
  const { planStatus } = planDetails;

  const fetchItems = useCallback(() => {
    if (isFetchingItems) {
      dispatch(dnsWizardActions.platforms.request(domainId));
      dispatch(loadDnsDetails(domainId));
      dispatch(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId }));
      setIsFetchingItems(false);
    }
    if (shouldRenderEmailPlatformSelection || IS_STORYBOOK) {
      dispatch(dnsWizardActions.emailPlatforms.request(domainId));
    }
  }, [dispatch, isFetchingItems, domainId, shouldRenderEmailPlatformSelection, IS_STORYBOOK]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const goToAdvancedDNSZone = () => setScrollToDNSZoneRecords(true);

  return (
    <DNSContextProvider planStatus={planStatus}>
      <DnsWizardContextProvider>
        <Styles.OuterWrapper data-testid="dns-wizard-page">
          <Styles.Wrapper>
            <Styles.InnerWrapper>
              <DnsWizardDomainHandler
                hostingPlatforms={platforms}
                domain={domain}
                selectedConfiguration={selectedConfiguration}
                domainId={domainId}
                controls={controls}
                availablePlans={availablePlans}
                dnsWizardLocale={dnsWizardLocale}
                goToAdvancedDNSZone={goToAdvancedDNSZone}
                showAdvanceDNSZoneModal={showAdvanceDNSZoneModal}
                setShowAdvanceDNSZoneModal={setShowAdvanceDNSZoneModal}
              />
              {(shouldRenderEmailPlatformSelection || IS_STORYBOOK) && (
              <DnsWizardEmailHandler
                domain={domain}
                dnsWizardLocale={dnsWizardLocale}
                routesLocale={routesLocale}
                emailPlatforms={emailPlatforms}
                domainId={domainId}
                controls={controls}
              />
              )}
              <DnsWizardZoneRecordsHandler
                domain={domain}
                dnsWizardLocale={dnsWizardLocale}
                scrollToDNSZoneRecords={scrollToDNSZoneRecords}
                setScrollToDNSZoneRecords={setScrollToDNSZoneRecords}
                setShowAdvanceDNSZoneModal={setShowAdvanceDNSZoneModal}
              />
            </Styles.InnerWrapper>
          </Styles.Wrapper>
        </Styles.OuterWrapper>
      </DnsWizardContextProvider>
    </DNSContextProvider>
  );
};

export default DnsWizardPage;
