import React from 'react';
import * as Styles from './Preview.styles';
import { Button, IconButton, SocialMediaIcons } from 'gatorcomponents';
import { colorSchemes, templates } from '../../LinkNaBioWizardHandler.enum';
import { IconArrow } from '../IconArrow';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA } from '@/config/GrowthBook/constants';
import withErrorBoundary from '@/utils/errorBoundary';

const Preview = ({
  testId = 'preview',
  handleShowPreview = () => null,
  stepLocale = {},
  title = '',
  description = '',
  links = [],
  variant = colorSchemes.SIMPLE,
  templateType = templates.FUNNY,
  allowMobile = false,
  onMobile = false,
  socialMedia = [],
}) => {
  const showSocialMedia = useFeatureIsOn(CHARLIE_LINK_NA_BIO_SOCIAL_MEDIA);

  const renderSocialLinks = () => socialMedia.map(link => (
    <Styles.SocialLink
      key={link.value}
      href={link.value.includes('http') ? link.value : `https://${link.value}`}
      target="_blank"
    >
      <SocialMediaIcons icon={link.social_icon.icon.replace('-icon', '')} />
    </Styles.SocialLink>
  ));

  const renderLinks = () => links.map(link => (
    <Styles.Link
      key={link.id}
      variant={variant}
      templateType={templateType}
      href={link.link.includes('http') ? link.link : `https://${link.link}`}
      target="_blank"
    >
      {link.description}
      {templateType === templates.MINIMALIST && (
        <Styles.IconArrowWrapper>
          <IconArrow />
        </Styles.IconArrowWrapper>
      )}
    </Styles.Link>
  ));

  return (
    <Styles.Wrapper>
      {allowMobile && onMobile && (
        <Styles.IconCloseWrapper>
          <IconButton icon="IconClose" onClick={handleShowPreview} />
        </Styles.IconCloseWrapper>
      )}

      <Styles.Card data-testid={testId} variant={variant} withDots={templateType === templates.FUNNY}>
        <Styles.Header
          variant={variant}
          templateType={templateType}
        >
          <Styles.Title templateType={templateType}>
            {title || stepLocale.title}
          </Styles.Title>
          <Styles.Description templateType={templateType}>
            {description || stepLocale.description}
          </Styles.Description>
          {showSocialMedia && !!socialMedia.length && (
            <Styles.SocialLinksWrapper>
              {renderSocialLinks()}
            </Styles.SocialLinksWrapper>
          )}
        </Styles.Header>
        <Styles.Links withPadding={templateType !== templates.MINIMALIST}>
          {links.length ? renderLinks()
            : (
              <Styles.LinkMessage>
                {stepLocale.linksYouAdd}
              </Styles.LinkMessage>
            )
          }
        </Styles.Links>
      </Styles.Card>

      <Styles.PreviewLabel>
        {stepLocale.preview}
      </Styles.PreviewLabel>

      {allowMobile && onMobile && (
        <Styles.ButtonWrapper>
          <Button
            label={stepLocale.closePreview}
            variant="secondary"
            onClick={handleShowPreview}
          />
        </Styles.ButtonWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(Preview, 'Preview');
