const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '14px',
    '& fieldset': {
      borderRadius: '2px',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
      zIndex: 1,
      fontSize: 14,
    },
    '& input': {
      padding: 8,
      fontSize: 14,
      paddingLeft: 12,
      paddingBottom: 7,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      '& fieldset': {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
  },
  leftItem: {
    marginRight: 5,
  },
  rightItem: {
    marginLeft: 5,
    [theme.breakpoints.only('sm')]: {
      marginLeft: 0,
    },
  },
  textFieldsGroups: {
    display: 'flex',
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
  },
  cep: {
    alignSelf: 'flex-end',
    fontSize: 14,
    marginBottom: -7,
    color: theme.color.indigo,
  },
  loading: {
    flex: 'none',
  },
  bottomSpace: {
    [theme.breakpoints.only('sm')]: {
      marginBottom: 8,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px',
  },
  btn: {
    width: 243,
  },
  formAddress: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
});

export default styles;
