import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import styles from './styles';

const ZoneManager = ({
  classes, t,
}) => (
  <Typography
    variant="h6"
    className={classes.zoneTitle}
    data-id="ZoneManager-title"
    id="zoneManagerTitle"
    data-testid="zoneManagerTitle"
  >
    {t('dns.zoneManager.title')}
  </Typography>
);

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ZoneManager)));
