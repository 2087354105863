import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


const SnappyDefault = ({ classes, className }) => (
  <SvgIcon className={`${classes.icon} ${className}`} viewBox="0 0 216.965 300.001">
    <g id="Snappy-Default" opacity="0.7">
      <g id="Back_Leg" data-name="Back Leg" transform="translate(35.294 219.669)">
        <path id="Caminho_218401" data-name="Caminho 218401" d="M194.447-413.736c8.5,8.225,17,19.45,25.506,27.676-4.079,21.449,3.907,30.895-5.719,38.583-3.477,2.774-24.61,8.674-36.45,1.836-.269-11.131,5.825-14.44,12.322-18.621,6.565-14.116,4.209-35.568,4.341-49.474" transform="translate(-177.775 413.736)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218402" data-name="Caminho 218402" d="M196.178-342.532c1.517-5.939,4.705-11.043,11.976-14.1-5.752,4.318-7.859,9.072-7.464,14.129C199.188-342.445,197.675-342.447,196.178-342.532Z" transform="translate(-184.379 413.736)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218403" data-name="Caminho 218403" d="M206.388-344.811c1.542-5.837,4.743-10.845,11.917-13.869-6.229,4.676-8.192,9.867-7.32,15.394A23.681,23.681,0,0,1,206.388-344.811Z" transform="translate(-204.74 413.736)" fill="#2e578c" fillRule="evenodd" />
      </g>
      <g id="Back_Arm" data-name="Back Arm" transform="translate(0 103.581)">
        <path id="Caminho_218404" data-name="Caminho 218404" d="M252.889-524.808c4.376,6.371,6.337,19.786-28.6,39.416,0,0,14.684,25.35,19.321,29.987s7.574,17,2.937,23.186a55.359,55.359,0,0,1-8.037,8.965s-11.593,2.164-16.54-.618c0,0-9.815-9.506-3.323-24.809,0,0-29.446-30.837-27.591-40.111S238.977-545.057,252.889-524.808Z" transform="translate(-190.976 529.824)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218405" data-name="Caminho 218405" d="M212.783-448.681s11.933,9.834,14.932,11.539c0,0-5.411-1.764-6.94-3,0,0-5.293,6.469-1.411,11.939,0,0-7.117-4.058-2.353-13.468,0,0-3.784-4.9-4.372-6.372Z" transform="translate(-185.108 529.824)" fill="#2e578c" fillRule="evenodd" />
      </g>
      <g id="Body" transform="translate(39.695 33.39)">
        <g id="Tail_Spines" data-name="Tail Spines" transform="translate(94.138 131.089)">
          <path id="Caminho_218406" data-name="Caminho 218406" d="M117.666-454.388a12.7,12.7,0,0,0,2.029,6.414c2.009,3.2,3.173,2.813-1.846,5.557a37.748,37.748,0,0,1-11.936,3.7c-7.069,1.154-5.417.363-2.3-6.244a55.145,55.145,0,0,1,4.822-8.5c6.835-9.58,8.946-8.928,9.229-.929" transform="translate(-100.939 468.926)" fill="#4580c2" fillRule="evenodd" />
          <path id="Caminho_218407" data-name="Caminho 218407" d="M95.054-457.693c2.567,4.785-1.369,5.535-6.448,7.311a25.97,25.97,0,0,1-5.834,1.3c-5.713.616-4.231-.209-1.735-4.831a84.881,84.881,0,0,1,5.053-8.224c9.983-14.356,5.213-2.562,8.964,4.441" transform="translate(-53.274 468.926)" fill="#4580c2" fillRule="evenodd" />
          <path id="Caminho_218408" data-name="Caminho 218408" d="M71.553-459.84a26.8,26.8,0,0,0,.118,3.3c.379,3.982.824,3.31-4.109,3.75a28.681,28.681,0,0,1-6.577-.067c-6.291-.943-4-3.135-.722-6.791a27.8,27.8,0,0,1,3.714-3.448c12.427-9.571,7.742-2.839,7.576,3.26" transform="translate(-8.153 468.926)" fill="#4580c2" fillRule="evenodd" />
        </g>
        <path id="Caminho_218409" data-name="Caminho 218409" d="M99.436-600.015s35.6,18.35,35.288,24.153-10.038,5.018-13.958,12.86c0,0,21.8,15.056,20.388,21.957s-15.37,4.391-18.977,9.881c0,0,3.447,13.735.31,18.754s-2.663.693-7.054,7.123C115.433-505.287,117.786-556.572,99.436-600.015Z" transform="translate(-25.105 600.015)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218410" data-name="Caminho 218410" d="M113.973-583.339c-12.481,11.921-28.418,19.423-42.836,28.633-9.845,6.288-18.806,13.333-25.845,22.761-8.646,11.576-3.681,25.216-5.653,39.413-.672,4.829-1,9.717-1.2,14.587a187.214,187.214,0,0,0,.776,26.61c2.173,21.169,3.269,38.39,18.185,54.227,18.648,19.8,39.154,24.29,62.42,7.2,9.015-6.625,15-10.625,22.24-19.075,4.757-5.549,10.7-9.112,15.836-14.313,5.939-6.016,14.844-13.445,22.862-16.51,9-3.437,15.213,1.255,19.631,3.272,2.292,1.046,5.415,3.07,7.954,1.05a2.62,2.62,0,0,0,.872-3.183,23.342,23.342,0,0,0-5.865-8.337c-13.732-12.556-35.871-12.839-52.43-4-6.255,3.341-13.066,7.146-20.276,7.62-5.1.336-10.276-.624-14.234-4.046a18.738,18.738,0,0,1-5.84-10.888,33.535,33.535,0,0,1,1.218-17.49c2.691-8.081,7.526-15.418,10.646-23.386,10.648-27.182,14.1-58.152-3.014-83.55l-2.376-3.525Z" transform="translate(-32.168 600.015)" fill="#4580c2" fillRule="evenodd" />
        <g id="Grupo_34722" data-name="Grupo 34722" transform="translate(0 49.689)">
          <path id="Caminho_218411" data-name="Caminho 218411" d="M215.551-438.6c-12,6-24.735,19.435-37,34-16,19-56,47-89,3C58.468-443.042,73.1-501.126,74.909-507.739c.069-.224.138-.447.209-.671,1.047-1.346,28.764-36.712,54.153-41.916,6.77,5.881,8.086,16.233-.932,34.085-15.168,30.027-27.294,73.817-.332,100.716a36.583,36.583,0,0,0,14.361,9.06c19.171,6.325,29.32-5.3,41.308-16.517A57.938,57.938,0,0,1,215.551-438.6Z" transform="translate(-69.897 550.326)" fill="#fbce3b" fillRule="evenodd" />
          <path id="Caminho_218412" data-name="Caminho 218412" d="M167.58-406.969c7.693-.979,20.879-3.964,32.027-13.1,0,0-7.907,13.855-28.319,18.465C169.968-403.361,168.74-405.154,167.58-406.969Z" transform="translate(-151.636 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218413" data-name="Caminho 218413" d="M172.61-404.306a74.326,74.326,0,0,1-19.326,25.079,41.931,41.931,0,0,1-7.607-2.558C152.989-385.147,165.352-392.254,172.61-404.306Z" transform="translate(-102.736 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218414" data-name="Caminho 218414" d="M108.677-406.913q-.99,1.15-1.971,2.313c-.3.352-.6.708-.917,1.066.476-3.187.406-6.286-.863-8.436A44.8,44.8,0,0,1,108.677-406.913Z" transform="translate(1.948 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218415" data-name="Caminho 218415" d="M122.714-393.488q-2.073,1.693-4.324,3.306c1.025-4.8,1.461-9.807-.154-12.613A45.95,45.95,0,0,1,122.714-393.488Z" transform="translate(-25.399 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218416" data-name="Caminho 218416" d="M95.907-420.828q-1.536,1.557-3.077,3.171a27.068,27.068,0,0,0-.711-6.646A32.52,32.52,0,0,1,95.907-420.828Z" transform="translate(27.525 550.326)" fill="#edb248" fillRule="evenodd" />
          <path id="Caminho_218417" data-name="Caminho 218417" d="M214.238-443.078c-15.806-5.064-30.679-2.066-37.232-.046q-.476-2.6-.836-5.17C200.568-454.017,214.238-443.078,214.238-443.078Z" transform="translate(-174.857 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218418" data-name="Caminho 218418" d="M175.21-481.2c.175-2.092.383-4.1.612-6.006v0c25.839-.628,39.156,11.928,39.156,11.928C199.529-483.711,183.142-482.517,175.21-481.2Z" transform="translate(-174.637 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218419" data-name="Caminho 218419" d="M166.527-515.056c1.007-1.145,2.134-2.4,3.366-3.732,27.464.008,34.876,12.757,34.876,12.757A60.744,60.744,0,0,0,166.527-515.056Z" transform="translate(-155.745 550.326)" fill="#eca93e" fillRule="evenodd" />
          <path id="Caminho_218420" data-name="Caminho 218420" d="M150.728-485.153a162.461,162.461,0,0,1,3.035-18.442c.663-.4,1.322-.8,1.964-1.221,8.777-5.719,47.336-29.121,58.66-35.832a22.752,22.752,0,0,1,.121,5.172C202.5-518.965,175.89-490.853,150.728-485.153Z" transform="translate(-149.776 550.326)" fill="#eca93e" fillRule="evenodd" />
        </g>
      </g>
      <g id="Front_Leg" data-name="Front Leg" transform="translate(100.062 207.616)">
        <path id="Caminho_218421" data-name="Caminho 218421" d="M135.23-418.043c-4.032-5.956-10.993-8.287-15.918-7.641-17.66,2.306-12.077,23.2-7.837,35.5,3.327,9.651,5.023,21.848,5,27.429-.034,12.142-4.637,15.8,1.014,20.542,4.9,4.115,18.928,10.018,30.826,8.594,9.805-2.218,10.075-22.388-5.554-27.534-4.345-9.419-4.149-19.038-3.81-29.091.226-6.8.82-21.084-3.726-27.8" transform="translate(-107.158 425.788)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218422" data-name="Caminho 218422" d="M132.343-333.81c-1.392-.2-2.774-.455-4.13-.766a17.977,17.977,0,0,0-4.872-16.33C129.733-347.247,131.845-341.014,132.343-333.81Z" transform="translate(-100.5 425.788)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218423" data-name="Caminho 218423" d="M114.446-333.669c1.206-6.121.046-13.073-4.733-18.186,5.954,3.409,8.194,9.05,8.878,15.628A7.972,7.972,0,0,1,114.446-333.669Z" transform="translate(-73.12 425.788)" fill="#2e578c" fillRule="evenodd" />
      </g>
      <g id="Front_Arm" data-name="Front Arm" transform="translate(101.023 97.733)">
        <path id="Caminho_218424" data-name="Caminho 218424" d="M153.988-443.572s2.039,11.684-5.881,15.6c-6.706,3.32-14.939,3.411-19.056-1.529-5.685-6.822-14.115-20.912,8.705-34.635,14.1-8.476,14.821,20.324,14.821,20.324Z" transform="translate(-122.713 535.671)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218425" data-name="Caminho 218425" d="M146.1-499.6c-2.977,6.449-5.654,11.438-5.785,11.884,0,0-18.43-22.824-14.995-26.6C129.122-518.488,143.229-504.653,146.1-499.6Z" transform="translate(-116.778 535.671)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218426" data-name="Caminho 218426" d="M93.509-533.007C73.748-513.952,114.8-488.662,114.8-488.662c-4.47,2.823-10.116,12.468-14.351,19.526s-5.881,5.881-11.057,11.763,9.58,14.611,9.58,14.611,49.39-26.688,49.234-43.783c-.02-2.117-11.466-21.139-23.055-32.935C119.33-525.405,102.961-542.121,93.509-533.007Z" transform="translate(-82.114 535.671)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218427" data-name="Caminho 218427" d="M149.421-452.276s3.69,18.186-7.173,23.483c0,0-8.807.582-13.285-2.629,0,0-11.144-17.09-2.832-25.951S149.421-452.276,149.421-452.276Z" transform="translate(-118.853 535.671)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218428" data-name="Caminho 218428" d="M140.072-450.982s-14.288,7.659-16.77,8.358a13.378,13.378,0,0,0,7.3-1.1s3.674,6.957-.584,11.316c0,0,6.564-1.171,4.212-12.11l5.959-6.156Z" transform="translate(-109.268 535.671)" fill="#2e578c" fillRule="evenodd" />
      </g>
      <g id="Head" transform="translate(2.321 0)">
        <path id="Caminho_218429" data-name="Caminho 218429" d="M232.144-588.828s-7.427-43.811-25.619-44.565c-11.956-.495-17.977,14.748-17.977,14.748s-9.688-.646-20.668,6.135c0,0-3.337-6.028-9.042-4.09S147-601.96,149.257-581.077a18.136,18.136,0,0,0-13.025,8.934s2.476,3.66,6.244,3.983,20.022-5.6,24.435-1.722,6.351,6.781,5.813,13.886c0,0,17.869-1.722,25.942-11.949S213.521-597.978,232.144-588.828Z" transform="translate(-115.451 633.405)" fill="#4580c2" fillRule="evenodd" />
        <path id="Caminho_218430" data-name="Caminho 218430" d="M213.451-585.665s-4.47-14.7-13.057-18.82a158.729,158.729,0,0,1-2.313,21.251A128.763,128.763,0,0,1,213.451-585.665Z" transform="translate(-158.607 633.405)" fill="#f1f1f2" />
        <path id="Caminho_218431" data-name="Caminho 218431" d="M170.87-615.816s-11.057,5.293-18.35,28.23c0,0,17.409-2.823,25.055-2.235C177.575-589.821,170.517-602.877,170.87-615.816Z" transform="translate(-77.17 633.405)" fill="#f1f1f2" />
        <path id="Caminho_218432" data-name="Caminho 218432" d="M163.814-605.647c-2.695,0-4.882,3.01-4.882,6.724s2.187,6.723,4.882,6.723,4.881-3.01,4.881-6.723-2.185-6.724-4.881-6.724" transform="translate(-74.702 633.405)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218433" data-name="Caminho 218433" d="M205.053-587.773c-2.123,0-3.844-2.279-3.844-5.091s1.721-5.09,3.844-5.09a2.988,2.988,0,0,1,1.179.269,13.624,13.624,0,0,1,2.639,4.453c.007.123.028.242.028.368C208.9-590.052,207.177-587.773,205.053-587.773Z" transform="translate(-157.183 633.405)" fill="#2e578c" fillRule="evenodd" />
        <path id="Caminho_218434" data-name="Caminho 218434" d="M219.172-577.235s7.528-8.7,14.821-1.647c6.037,5.843,1.529,15.645,1.529,15.645s-.436-8.457-4.352-7.881c0,0-3.294,21.055-34.112,42.11s-39.4,19.173-46.11,17.409-14.35-5.176-11.762-10.587,18.349-18.82,18.349-18.82,7.058,5.293,20.467.588,35.288-18.938,46.816-35.052c0,0-.622-3.935-5.192-1.733" transform="translate(-122.884 633.405)" fill="#f68b51" fillRule="evenodd" />
        <path id="Caminho_218435" data-name="Caminho 218435" d="M231.814-553.278a52.315,52.315,0,0,0-48.306,13.017l3.451-1.882s-15.841-.784-18.036,1.412-14.743,9.253-16.39,27.838c-.479,5.414,12.351,8.469,40.856-5.725C211.157-527.465,231.814-553.278,231.814-553.278Z" transform="translate(-131.409 633.405)" fill="#f26a23" fillRule="evenodd" />
        <g id="Grupo_34723" data-name="Grupo 34723" transform="translate(65.069 83.728)">
          <path id="Caminho_218436" data-name="Caminho 218436" d="M187.856-549.677c-.21.108-.429.215-.662.324C187.415-549.463,187.636-549.572,187.856-549.677Z" transform="translate(-187.194 549.677)" fill="#c56428" fillRule="evenodd" />
        </g>
        <path id="Caminho_218437" data-name="Caminho 218437" d="M202.159-541.123s3.839,15.74,9.763,14c2-.588,1.177-5.058,2-8.116s3.058-8.94,3.058-8.94Z" transform="translate(-166.214 633.405)" fill="#ebeef2" fillRule="evenodd" />
        <path id="Caminho_218438" data-name="Caminho 218438" d="M231.214-547.072s1.242,16.154,7.37,15.393c2.069-.257,1.979-4.8,3.286-7.686s4.464-8.327,4.464-8.327Z" transform="translate(-224.623 633.405)" fill="#ebeef2" fillRule="evenodd" />
        <path id="Caminho_218439" data-name="Caminho 218439" d="M125.933-561.238c.794-11.362,15.794-16.362,26.794-8.362,9-2,19-8,28,1,6.366,6.367,2.726,12.5,2.726,12.5s18.7-3.019,25.172-12.547,14.821-22.271,23.995-23.172c4.82-.474,12.939-1.177,17.644,8s7.058,25.76-27.759,51.991-50.182,33.3-57.755,33.288c-9.685-.013-10.274-3.613-21.408-5.175-5.685-.8-10-10.469-3.764-18.82s20.937-18.468,20.937-18.468-15.762,13.41-18.349,18.82,5.058,8.822,11.762,10.587,15.292,3.646,46.11-17.409,34.112-42.11,34.112-42.11c3.916-.576,4.352,7.881,4.352,7.881s4.508-9.8-1.529-15.645c-7.293-7.057-14.821,1.647-14.821,1.647l.454.032c4.571-2.2,5.192,1.733,5.192,1.733-11.528,16.114-33.406,30.347-46.816,35.052s-20.467-.588-20.467-.588-14.232-.235-19.055-1.529S124.858-545.838,125.933-561.238Z" transform="translate(-125.864 633.405)" fill="#fbce3b" fillRule="evenodd" />
        <path id="Caminho_218440" data-name="Caminho 218440" d="M209.926-562.442c-3.13-1.008-5.54,3.212-1.411,5.057.58,1.555-.353,3.254-2.887,3.855,8.011,1.652,8.332-7.455,4.3-8.912" transform="translate(-165.166 633.405)" fill="#eca93e" fillRule="evenodd" />
        <path id="Caminho_218441" data-name="Caminho 218441" d="M242.082-563.738c2.583-.833,4.571,2.65,1.164,4.172-.479,1.282.291,2.686,2.384,3.181-6.612,1.362-6.878-6.151-3.548-7.353" transform="translate(-232.693 633.405)" fill="#eca93e" fillRule="evenodd" />
      </g>
    </g>
  </SvgIcon>
);

export default withStyles(styles)(SnappyDefault);
