import React from 'react';
import SecurityContextProvider from '@/contexts/Security/context';
import SitelockProductDetailsPage from './SitelockProductDetailsPage/SitelockProductDetailsPage';

const SitelockProductDetailsPageWrapper = () => (
  <div data-testid="productdetails-page">
    <SecurityContextProvider>
      <SitelockProductDetailsPage />
    </SecurityContextProvider>
  </div>
);

export default SitelockProductDetailsPageWrapper;
