import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v1.font.family.primary};
    gap: 20px;
    padding: 32px;
  `}
`;

export const ContentCloseButton = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

export const Data = styled.div`
  ${() => css`
    padding-top: 4px;
  `}
`;

export const Header = styled.header``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    margin: 0;
    padding: 0;
  `}
`;

export const IconContainer = styled.div`
  ${() => css`
    min-width: 19px;
  `}
`;

export const InfoAlert = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 16px;
  `}
`;

export const ButtonBox = styled.div`
  ${() => css`
    width: 256px;
    font-size: 16px;
  `}
`;

export const TryAgainButtonBox = styled.div`
  ${() => css`
    width: 196px;
    font-size: 16px;
  `}
`;

export const ButtonContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const ResultContainer = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: center;
  `}
`;

export const ResultAlertText = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 20px
  `}
`;

export const ResultIcon = styled.div`
  ${({ theme, variant }) => {
    const variants = {
      success: {
        backgroundColor: theme.v2.colors.feedback.activePure,
      },
      failure: {
        backgroundColor: theme.v2.colors.feedback.problemMedium,
      },
    };

    return css`
    align-items: center;
    background-color: ${variants[variant].backgroundColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;

    & svg {
      fill: ${theme.v2.colors.white.primary};
    }
  `;
  }}
`;
