import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 150px 80px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 20px;
    }
  `}
`;

export const Colum = styled.div`
`;

export const Skeleton = styled.div`
  ${({ gap }) => css`
    margin-bottom: ${gap}px;
  `}
`;

export const FaqSection = styled.section`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    margin: 16px 24px;
    width: 560px;
    
    a{
      color: ${theme.v2.colors.brand.primaryMedium};
    }

    @media (max-width: ${theme.v2.breakpoints.lg}) {
      width: 50%;
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: auto;
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 10px 0;
    }

  `};
`;
