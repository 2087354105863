import React from 'react';
import * as Styles from './LinksDescription.styles';
import SnappySvg from '@/media/ticket/snappy-hero.svg';
import { IconTriangleDetail } from 'gatorcomponents';

export const LinksDescription = ({
  ticketLocale,
}) => (
  <Styles.DescriptionWrapper>
    <IconTriangleDetail color="attentionPure" />
    <Styles.SanppyImage src={SnappySvg} />
    <Styles.Description>
      {ticketLocale.listPageFaq.listDescription}
    </Styles.Description>
  </Styles.DescriptionWrapper>
);
