import React, { useEffect, useRef } from 'react';
import * as Styles from './WizardWordPressConfig.styles';
import { WizardWordpressConfigInfo } from '../WizardWordpressConfigInfo';
import { WizardWordpressConfigThemes } from '../WizardWordpressConfigThemes';
import { WizardWordpressConfigPlugin } from '../WizardWordpressConfigPlugin';
import { WizardWordpressConfigModal } from '../WizardWordpressConfigModal';
import { useResize } from '@/hooks/useResize';
import { WizardWordpressConfigAsaas } from '../WizardWordpressConfigAsaas';

let resizeTimout = null;
const WizardWordPressConfig = ({
  title,
  subtitle,
  themeTitle,
  themeDescription,
  themeTooltip,
  themes,
  themeSelected,
  handleChangeTheme,
  handleOnOpenThemeModal,
  nextStepButtonLabel,
  wodpressSetupValues,
  handleUpdateWordpressSetupValues,
  handleBlurWordpressSetupValues,
  wordPressSetupReadyToContinue,
  wordpressInfoTitle = 'info title',
  wordpressInfoDescription = 'info subtitle',
  questions,
  passwordChecklistTexts,
  userChecklistTexts,
  onContinueSetup,
  setupSteps,
  activeSetupStep,
  pluginTitle,
  pluginDescription,
  asaasToggleTitle,
  asaasToggleDescription,
  wordPressPlugins,
  handleChangePlugin,
  pluginsSelected,
  finishLabel,
  pluginTooltip,
  modalTitle,
  handleLoadingWordpress,
  loadingWordpressInstallation,
  infoNextStepButton,
  validateWordpressInfo,
  asaasFormData,
  accountCreated,
  asaasOnChange,
  asaasOnContinue,
  loadingCep,
  asaasEnabledFormOpenAccountContinue,
  asaasEnabledMainDataContinue,
  asaasOnGoback,
  asaasOnSubmit,
  asaasResetForm,
  asaasCnpjTypes,
  asaasCallformStepTwoMyCEP,
  asaasTitle,
  asaasDescription,
  asaasReachedThridStep,
  createAsaasContinueLabel,
  dontCreateAsaasContinueLabel,
  asaasInsideWizardShouldAppear,
  descriptionImageAlt,
  imageItems,
  isWondersuiteEnabled,
}) => {
  const wordPressInfoBlock = useRef();
  const wordPressThemesBlock = useRef();
  const wordPressPluginsBlock = useRef();
  const wordPressAsaasBlock = useRef();
  const screenSize = useResize();

  useEffect(() => {
    resizeTimout = setTimeout(() => {
      let top = null;

      if (activeSetupStep === setupSteps.UTILS) {
        top = wordPressInfoBlock.current.offsetTop;
      }

      if (activeSetupStep === setupSteps.THEME) {
        top = wordPressThemesBlock.current.offsetTop;
      }

      if (activeSetupStep === setupSteps.PLUGIN) {
        top = wordPressPluginsBlock.current.offsetTop;
      }

      if (activeSetupStep === setupSteps.ASAAS) {
        top = wordPressAsaasBlock.current.offsetTop;
      }

      window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
      });
    }, 500);

    return () => {
      clearTimeout(resizeTimout);
    };
  }, [activeSetupStep, setupSteps, screenSize.height]);

  const WizardWordPressAsaas = () => {
    const defaultProps = {
      asaasToggleTitle,
      asaasToggleDescription,
      wordPressAsaasBlock,
      open: activeSetupStep === setupSteps.ASAAS,
      pluginsSelected,
      wordPressSetupReadyToContinue,
      wordPressInfoBlock,
      wordPressPluginsBlock,
      onContinueSetup,
      wodpressSetupValues,
      asaasFormData,
      accountCreated,
      asaasOnChange,
      asaasOnContinue,
      loadingCep,
      asaasEnabledFormOpenAccountContinue,
      asaasEnabledMainDataContinue,
      asaasOnGoback,
      asaasOnSubmit,
      asaasResetForm,
      asaasCnpjTypes,
      asaasCallformStepTwoMyCEP,
      asaasTitle,
      asaasDescription,
      asaasReachedThridStep,
      createAsaasContinueLabel,
      dontCreateAsaasContinueLabel,
      themeSelected,
      wordPressThemesBlock,
      activeSetupStep,
      setupSteps,
      handleLoadingWordpress,
      descriptionImageAlt,
      imageItems,
      isWondersuiteEnabled,
    };

    return defaultProps;
  };

  return (
    <Styles.Wrapper>
      <Styles.Title data-testid="title">
        {title}
      </Styles.Title>
      <Styles.Subtitle>
        {subtitle}
      </Styles.Subtitle>

      <WizardWordpressConfigInfo
        wodpressSetupValues={wodpressSetupValues}
        handleUpdateWordpressSetupValues={handleUpdateWordpressSetupValues}
        handleBlurWordpressSetupValues={handleBlurWordpressSetupValues}
        wordPressSetupReadyToContinue={wordPressSetupReadyToContinue}
        title={wordpressInfoTitle}
        infoNextStepButton={infoNextStepButton}
        description={wordpressInfoDescription}
        questions={questions}
        passwordChecklistTexts={passwordChecklistTexts}
        userChecklistTexts={userChecklistTexts}
        onContinueSetup={onContinueSetup}
        setupSteps={setupSteps}
        activeSetupStep={activeSetupStep}
        open={activeSetupStep === setupSteps.UTILS}
        wordPressInfoBlock={wordPressInfoBlock}
        isWondersuiteEnabled={isWondersuiteEnabled}
        handleLoadingWordpress={handleLoadingWordpress}
        asaasInsideWizardShouldAppear={asaasInsideWizardShouldAppear}
      />

      {!isWondersuiteEnabled && (
      <>
        <WizardWordpressConfigThemes
          themeTitle={themeTitle}
          themeDescription={themeDescription}
          themeTooltip={themeTooltip}
          themes={themes}
          themeSelected={themeSelected}
          handleChangeTheme={handleChangeTheme}
          nextStepButtonLabel={nextStepButtonLabel}
          handleOnOpenThemeModal={handleOnOpenThemeModal}
          open={activeSetupStep === setupSteps.THEME}
          onContinueSetup={onContinueSetup}
          setupSteps={setupSteps}
          activeSetupStep={activeSetupStep}
          wordPressThemesBlock={wordPressThemesBlock}
          wordPressSetupReadyToContinue={wordPressSetupReadyToContinue}
          wodpressSetupValues={wodpressSetupValues}
          wordPressInfoBlock={wordPressInfoBlock}
          validateWordpressInfo={validateWordpressInfo}
        />

        <WizardWordpressConfigPlugin
          pluginTitle={pluginTitle}
          pluginDescription={pluginDescription}
          plugins={wordPressPlugins}
          wordPressPluginsBlock={wordPressPluginsBlock}
          handleChangePlugin={handleChangePlugin}
          pluginsSelected={pluginsSelected}
          nextStepButtonLabel={finishLabel}
          pluginTooltip={pluginTooltip}
          open={activeSetupStep === setupSteps.PLUGIN}
          handleLoadingWordpress={handleLoadingWordpress}
          wodpressSetupValues={wodpressSetupValues}
          wordPressSetupReadyToContinue={wordPressSetupReadyToContinue}
          themeSelected={themeSelected}
          wordPressInfoBlock={wordPressInfoBlock}
          wordPressThemesBlock={wordPressThemesBlock}
          onContinueSetup={onContinueSetup}
          activeSetupStep={activeSetupStep}
          setupSteps={setupSteps}
          validateWordpressInfo={validateWordpressInfo}
          asaasShouldAppear={asaasInsideWizardShouldAppear}
        />
      </>
      )}

      {asaasInsideWizardShouldAppear && (
        <WizardWordpressConfigAsaas {...WizardWordPressAsaas()} />
      )}

      {loadingWordpressInstallation && <WizardWordpressConfigModal modalTitle={modalTitle} />}
    </Styles.Wrapper>
  );
};

export default WizardWordPressConfig;
