import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items:center;
    border: 1px solid ${theme.v1.color.line.wizard};
    border-radius: 4px;
    display:flex;
    flex-direction:column;
    width:240px;
  `}
`;

export const TitleWrapper = styled.div`
  align-items:center;
  align-self: flex-start;
  display:flex;
  padding: 16px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color:${theme.v1.color.text.primary};
    font-size:${theme.font.sizes.md};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.v1.font.weights.medium};
    line-height: ${theme.font.lineHeights.xl};
    margin: 0;
  `}
`;

export const PluginImage = styled.img`
  height:40px;
  margin-right: 16px;
  width: 40px;
`;

export const PluginDescription = styled.p`
  ${({ theme }) => css`
    align-self: flex-start;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxs};
    height: 113px;
    line-height: ${theme.font.lineHeights.md};
    margin: 0px 15px 13px 16px;
  `}
`;


export const ButtonsWrapper = styled.div`
  display:flex;
  justify-content:center;
  padding-bottom:16px;
`;
