import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './SiteLockUpgrade.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { HgProducts } from '../../SupportForms.types';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const SiteLockUpgrade = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { upgradeSiteLock: upgradeSiteLockLocale } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    newProduct: '',
    agreed: false,
  });

  const [productOptions, setProductOptions] = useState([]);

  const checkForm = useCallback(() => !(!!formData.domain && !!formData.newProduct && !!formData.agreed), [formData]);

  const createUpgradeOptions = useCallback(() => {
    const options = [];

    const professionalOptin = {
      label: 'Sitelock Professional',
      value: 1,
    };

    const premiumOptin = {
      label: 'Sitelock Premium',
      value: 2,
    };

    const enterpriseOptin = {
      label: 'Sitelock Enterprise',
      value: 3,
    };

    const shouldNotIncludeProfessional = [
      HgProducts.SITELOCK_PROFESSIONAL,
      HgProducts.SITELOCK_PREMIUM,
      HgProducts.SITELOCK_ENTERPRISE,
    ].includes(selectedProduct.pid);

    const shouldNotIncludePremium = [
      HgProducts.SITELOCK_PREMIUM,
      HgProducts.SITELOCK_ENTERPRISE,
    ].includes(selectedProduct.pid);

    const shouldNotIncludeEnterprise = [
      HgProducts.SITELOCK_ENTERPRISE,
    ].includes(selectedProduct.pid);

    !shouldNotIncludeProfessional && options.push(professionalOptin);
    !shouldNotIncludePremium && options.push(premiumOptin);
    !shouldNotIncludeEnterprise && options.push(enterpriseOptin);

    setProductOptions(options);
  }, [selectedProduct]);

  useEffect(() => {
    createUpgradeOptions();
  }, [createUpgradeOptions]);


  const mountSitelockTag = useCallback(() => {
    const rules = {
      [HgProducts.SITELOCK_PROFESSIONAL]: 'sitelock_professional',
      [HgProducts.SITELOCK_PREMIUM]: 'sitelock_premium',
      [HgProducts.SITELOCK_ENTERPRISE]: 'sitelock_enterprise',
    };

    return rules[selectedProduct.pid];
  }, [
    selectedProduct,
  ]);


  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.upgradeSiteLock,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.UPGRADE_SITELOCK,
      tags: [
        'whmcs_form_financeiro_upgrade_sitelock',
        mountSitelockTag(),
      ],
      body: `
        • ${upgradeSiteLockLocale.body.product(selectedProduct)}
        • ${upgradeSiteLockLocale.body.request}
        • ${upgradeSiteLockLocale.body.domain(formData.domain)}
        • ${upgradeSiteLockLocale.body.newSitelock(formData.newProduct)}
        • ${upgradeSiteLockLocale.body.agreed(formData.agreed)}
        • ${upgradeSiteLockLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    upgradeSiteLockLocale,
    mountSitelockTag,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {upgradeSiteLockLocale.info}
      </Styles.Info>

      <Styles.Title>
        {upgradeSiteLockLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="domain"
          placeholder={upgradeSiteLockLocale.placeholders.domain}
          label={formData.domain ? upgradeSiteLockLocale.placeholders.domain : ''}
          value={formData.domain}
          onChange={e => handleChange({ key: 'domain', value: e.target.value })}
          type="text"
          testId="domain"
        />

        <Select
          name="newProduct"
          testId="newProduct"
          placeholder={upgradeSiteLockLocale.placeholders.newSitelock}
          label={formData.newProduct ? upgradeSiteLockLocale.placeholders.newSitelock : ''}
          options={productOptions}
          value={formData.newProduct}
          setValue={label => handleChange({ key: 'newProduct', value: label })}
          withScroll
        />
      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {upgradeSiteLockLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : upgradeSiteLockLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default SiteLockUpgrade;
