import React from 'react';
import * as Style from './IconDetailedCheck.style';

const IconDetailedCheck = ({
  color = '#1F2044',
  backgroundColor = '#FF9442',
  width = '38',
  height = '37',
  testId = 'icon-detailed-check',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V37H38L0 0Z" fill={backgroundColor} />
      <g clipPath="url(#clip0_1172_21557)">
        <path d="M26.2098 10.8732L16.757 20.3261L11.6074 15.1909L9.58484 17.2134L16.757 24.3855L28.2324 12.9101L26.2098 10.8732ZM19.6258 2.86914C11.7078 2.86914 5.28156 9.29537 5.28156 17.2134C5.28156 25.1314 11.7078 31.5577 19.6258 31.5577C27.5439 31.5577 33.9701 25.1314 33.9701 17.2134C33.9701 9.29537 27.5439 2.86914 19.6258 2.86914ZM19.6258 28.6888C13.2857 28.6888 8.15042 23.5536 8.15042 17.2134C8.15042 10.8732 13.2857 5.73799 19.6258 5.73799C25.966 5.73799 31.1012 10.8732 31.1012 17.2134C31.1012 23.5536 25.966 28.6888 19.6258 28.6888Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1172_21557">
          <rect width="34.4262" height="34.4262" fill="white" transform="translate(2.41273)" />
        </clipPath>
      </defs>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedCheck;
