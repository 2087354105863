import { CALL_API } from '@/middleware/api';
import { TICKETS_URL, TICKETS_URL_V2, TICKET_TRANSFER_DOMAIN_URL } from '@/config/api';
import { queryString } from '@/utils/Application/queryString';
import {
  REQUEST_PENDING_TICKETS, REQUEST_CLOSED_TICKETS, REQUEST_ERROR_TICKETS,
  CLEAR_FILTER_PENDING_TICKETS, CLEAR_FILTER_CLOSED_TICKETS, RECEIVE_PENDING_TICKETS,
  RECEIVE_FILTERED_PENDING_TICKETS, RECEIVE_FILTERED_CLOSED_TICKETS, RECEIVE_CLOSED_TICKETS,
  TICKET_STATUS, FIRSTROW, SECONDROW, REQUEST_SAVE_TICKET_TRANSFER_DOMAIN,
  RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN, ERROR_SAVE_TICKET_TRANSFER_DOMAIN,
  REQUEST_PENDING_TICKETS_V2, REQUEST_CLOSED_TICKETS_V2, RECEIVE_CLOSED_TICKETS_V2,
  RECEIVE_PENDING_TICKETS_V2, REQUEST_ERROR_TICKETS_V2,
} from './actionsTypes';
import { ALERT_TYPES } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';
import { AlertsActionTypes } from '../modules/alerts/alerts.types';


export const requestPendingTickets = () => ({ type: REQUEST_PENDING_TICKETS });
export const requestPendingTicketsV2 = () => ({ type: REQUEST_PENDING_TICKETS_V2 });

export const requestClosedTickets = () => ({ type: REQUEST_CLOSED_TICKETS });
export const requestClosedTicketsV2 = () => ({ type: REQUEST_CLOSED_TICKETS_V2 });

export const requestErrorTickets = () => ({ type: REQUEST_ERROR_TICKETS });
export const requestErrorTicketsV2 = () => ({ type: REQUEST_ERROR_TICKETS_V2 });

export const clearFilterPendingTickets = () => ({ type: CLEAR_FILTER_PENDING_TICKETS });

export const clearFilterClosedTickets = () => ({ type: CLEAR_FILTER_CLOSED_TICKETS });

export const receivePendingTickets = (tickets, isToUpdateAlerts) => {
  if (isToUpdateAlerts) {
    let assembledTickets = [];
    if (tickets.length) {
      const alertStatus = {
        open: ALERT_TYPES.TICKET_OPEN,
        new: ALERT_TYPES.TICKET_NEW,
        solved: ALERT_TYPES.TICKET_SOLVED,
        pending: ALERT_TYPES.TICKET_PENDING,
      };

      assembledTickets = tickets.map(ticket => ({ ...ticket, isNew: true, type: alertStatus[ticket.status] }));
    }

    return ({
      type: AlertsActionTypes.SET_TICKETS_ALERTS,
      payload: assembledTickets,
    });
  }

  return ({
    type: RECEIVE_PENDING_TICKETS,
    tickets,
  });
};

export const receivePendingTicketsV2 = (tickets, isToUpdateAlerts) => {
  if (isToUpdateAlerts) {
    let assembledTickets = [];
    if (tickets.length) {
      const alertStatus = {
        open: ALERT_TYPES.TICKET_OPEN,
        new: ALERT_TYPES.TICKET_NEW,
        solved: ALERT_TYPES.TICKET_SOLVED,
        pending: ALERT_TYPES.TICKET_PENDING,
      };

      assembledTickets = tickets.map(ticket => ({ ...ticket, isNew: true, type: alertStatus[ticket.status] }));
    }

    return ({
      type: AlertsActionTypes.SET_TICKETS_ALERTS,
      payload: assembledTickets,
    });
  }

  return ({
    type: RECEIVE_PENDING_TICKETS_V2,
    tickets,
  });
};


export const receiveFilteredPendingTickets = tickets => ({
  type: RECEIVE_FILTERED_PENDING_TICKETS,
  tickets,
});

export const receiveFilteredClosedTickets = tickets => ({
  type: RECEIVE_FILTERED_CLOSED_TICKETS,
  tickets,
});

export const receiveClosedTickets = tickets => ({
  type: RECEIVE_CLOSED_TICKETS,
  tickets,
});

export const receiveClosedTicketsV2 = tickets => ({
  type: RECEIVE_CLOSED_TICKETS_V2,
  tickets,
});

const filterIncludesStrings = (ticketsReceived, search) => {
  const tickets = ticketsReceived.filter(
    item => item.id.toString().includes(search)
    || (item.subject && item.subject.toLowerCase().includes(search.toLowerCase())),
  );
  return tickets;
};

export const filterPendingTickets = (status, search) => (dispatch, getState) => {
  let tickets = getState().tickets.bkpTickets.pending;

  if (status !== '') {
    switch (status) {
      case TICKET_STATUS.open:
        tickets = tickets.filter(
          item => item.status === TICKET_STATUS.open
            || item.status === TICKET_STATUS.new
            || item.status === TICKET_STATUS.pending,
        );
        break;
      case TICKET_STATUS.conclude:
        tickets = tickets.filter(item => item.status === TICKET_STATUS.solved);
        break;
      case TICKET_STATUS.hold:
        tickets = tickets.filter(item => item.status === TICKET_STATUS.hold);
        break;
      default:
        break;
    }
  }

  if (search !== '') {
    tickets = filterIncludesStrings(tickets, search);
  }

  return dispatch(receiveFilteredPendingTickets(tickets));
};

export const filterClosedTickets = search => (dispatch, getState) => {
  let tickets = getState().tickets.bkpTickets.closed;

  if (search !== '') {
    tickets = filterIncludesStrings(tickets, search);
  }

  return dispatch(receiveFilteredClosedTickets(tickets));
};

export const loadTickets = (status, userEmail, isToUpdateAlerts) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: `${TICKETS_URL}?${queryString({ status })}`,
    method: 'GET',
    actionTypes: {
      request: status === FIRSTROW ? requestPendingTickets : requestClosedTickets,
      success: data => (status === FIRSTROW ? receivePendingTickets(data.data, isToUpdateAlerts) : receiveClosedTickets(data.data)),
      error: requestErrorTickets,
    },
  },
});

export const loadTicketsV2 = (status, userEmail) => ({
  [CALL_API]: {
    authenticated: true,
    customHeaders: {
      email: userEmail,
    },
    endpoint: `${TICKETS_URL_V2}?${queryString({ status })}`,
    method: 'GET',
    actionTypes: {
      request: status === FIRSTROW ? requestPendingTicketsV2 : requestClosedTicketsV2,
      success: data => (status === FIRSTROW ? receivePendingTicketsV2(data.data) : receiveClosedTicketsV2(data.data)),
      error: requestErrorTickets,
    },
  },
});

export const loadPendingTickets = isToUpdateAlerts => (dispatch, getState) => dispatch(loadTickets(FIRSTROW, getState().summary.email, isToUpdateAlerts));
export const loadPendingTicketsV2 = () => (dispatch, getState) => dispatch(loadTicketsV2(FIRSTROW, getState().summary.email));

export const loadClosedTickets = () => (dispatch, getState) => dispatch(loadTickets(SECONDROW, getState().summary.email));
export const loadClosedTicketsV2 = () => (dispatch, getState) => dispatch(loadTicketsV2(SECONDROW, getState().summary.email));

export const createTicketTransferDomain = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: TICKET_TRANSFER_DOMAIN_URL,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_SAVE_TICKET_TRANSFER_DOMAIN }),
      success: response => ({ type: RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN, ticket: response.data.ticket }),
      error: () => ({ type: ERROR_SAVE_TICKET_TRANSFER_DOMAIN }),
    },
  },
});
