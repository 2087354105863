import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class CreatorFast extends React.PureComponent {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={classes.icon} viewBox="0 0 330.7 331.2">
        <g id="Agrupar_1179" transform="translate(-0.044)">
          <path id="Caminho_2389" className={classes.st0} d="M330.7,93.9l-165.3,94.5L0,93.9L165.4,0L330.7,93.9z" />
          <path id="Caminho_2390" className={classes.st1} d="M165.4,188.4L62.8,129.7L0,165.3l165.3,94.5l165.3-94.5L268,129.7L165.4,188.4z" />
          <g id="Agrupar_1143">
            <path id="Caminho_2391" className={classes.st2} d="M165.4,259.8L62.8,201.2L0,236.8l165.3,94.5l165.3-94.5L268,201.2L165.4,259.8z" />
          </g>
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(CreatorFast);
