import axios from 'axios';
import { config } from '@/config';

const instance = axios.create({
  baseURL: 'https://api.rd.services',
  headers: {
    brand: config.COUNTRY,
    'Content-Type': 'application/json',
  },
});

const post = ({ url, headers, data }) => instance.post(url, data, { headers });

const rdStation = {
  post,
};

export default rdStation;
