import React, { useState } from 'react';
import { Radio } from '@/pages/common/inputs';
import Loader from '@/components/Layout/Loader';
import HostGator from '@/media/subscriptions/refund/HostGator.svg';
import { IconCreditCard, IconPix, IconPayPal } from '@/icons';
import * as Styles from './RefundMethodsBlock.styles';
import { REFUND_PAYMENT_METHODS } from '@/enums/billing/billing.enum';
import { billingAnalytics } from '@/analytics';

const RefundMethodsBlock = ({
  testId = 'refund-methods-block',
  title = 'Select a refund method:',
  methods = [
    'hg_credits',
    'braspag_credit_1x',
    'pix',
    'gp_paypal',
    'dlocal',
  ],
  methodOptions = {
    hg_credits: {
      show: true,
      image: <img src={HostGator} alt="hostgator" />,
      name: 'HostGator Credits',
      description: 'The refund will be deducted from your next invoice.',
    },
    braspag_credit_1x: {
      show: true,
      image: <IconCreditCard />,
      name: 'Credit Card',
      description: 'You will receive the refund directly on the credit card bill used in the contract.',
    },
    dlocal: {
      show: true,
      image: <IconCreditCard />,
      name: 'Credit Card',
      description: 'You will receive the refund directly on the credit card bill used in the contract.',
    },
    pix: {
      show: true,
      image: <IconPix />,
      name: 'Pix',
      description: '',
    },
    gp_paypal: {
      show: true,
      image: <IconPayPal />,
      name: 'PayPal',
      description: 'You will receive the refund directly to the PayPal account used in the contract.',
    },
  },
  selected = REFUND_PAYMENT_METHODS.HG_CREDITS,
  onChangeSelected,
  loading,
}) => {
  const [checkedRadio, setCheckedRadio] = useState(selected);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  const executeGAActionByMethod = (method) => {
    let gaEventLabel = '';

    switch (method) {
      case 'gp_paypal':
        gaEventLabel = '4_modo_paypal_reembolso_cancelar_imediato';
        break;
      case 'hg_credits':
        gaEventLabel = '4_modo_credito_reembolso_cancelar_imediato';
        break;
      case 'braspag_credit_1x':
        gaEventLabel = '4_modo_cartao_reembolso_cancelar_imediato';
        break;
      default:
        gaEventLabel = null;
    }

    subscriptionAnalytics.cancelSubscriptionClick(gaEventLabel);
  };

  const handleOnChange = (method) => {
    if (checkedRadio !== method) {
      onChangeSelected && onChangeSelected(method);
      setCheckedRadio(method);
      executeGAActionByMethod(method);
    }
  };

  const hasMethod = method => method in methodOptions;

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title>
        {title}
      </Styles.Title>

      {
        loading
          ? (
            <Styles.LoaderAdapter>
              <Loader />
            </Styles.LoaderAdapter>
          )
          : (
            <Styles.MethodsWrapper>
              {methods.map(method => (
                (hasMethod(method) && methodOptions[method].show) && (
                <Styles.MethodButton
                  type="button"
                  onClick={() => handleOnChange(method)}
                  active={checkedRadio === method}
                  key={`method-${method}`}
                  data-testid={`button-method-${method}`}
                >
                  <Styles.RadioWrapper>
                    <Radio
                      value={method}
                      checked={checkedRadio === method}
                    />
                  </Styles.RadioWrapper>

                  <Styles.Content>
                    <Styles.IconWrapper>
                      {methodOptions[method].image}
                    </Styles.IconWrapper>

                    <Styles.DataWrapper>
                      <Styles.MethodName>{methodOptions[method].name}</Styles.MethodName>
                      <Styles.MethodDescription>
                        {methodOptions[method].description}
                      </Styles.MethodDescription>
                    </Styles.DataWrapper>
                  </Styles.Content>

                </Styles.MethodButton>
                )
              ))}
            </Styles.MethodsWrapper>
          )
      }
    </Styles.Wrapper>
  );
};
export default RefundMethodsBlock;
