import styled, { css } from 'styled-components';

export const QuestionLabel = styled.p`
${({ theme }) => css`
  color:${theme.palette.blue.dark};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.md};
  font-weight: ${theme.font.weights.medium};
  line-height: ${theme.font.lineHeights.xl};
  padding: 0px 16px 17px 16px;
  margin: 0px;
`
}`;

export const SelectContainer = styled.div`
  margin: 0px 16px;
`;


export const QuestionButtonsContainer = styled.div`
${() => css`
  align-self: end;
  display:flex;
  padding:25px 16px 25px 0px;
  a {
    margin-right:16px;
  }
`
}`;

export const OtherInputContainer = styled.div`
  margin-top:8px;
`;
