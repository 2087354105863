import { Paper } from '@material-ui/core';
import * as Styles from './PixOfferPopup.styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { IconPix } from '@/icons';

const PixOfferPopup = ({
  closeAriaLabel = 'Close',
  cornerTagText = 'New',
  description = '',
  knowMore = 'See more about',
  onClose,
  onLinkClick,
  title = '!',
}) => {
  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnLinkClick = () => {
    onLinkClick && onLinkClick();
  };

  return (
    <Styles.Wrapper data-testid="pix-offer-popup">
      <Paper>
        <Styles.Content>
          <Styles.CornerTag>
            <Styles.CornerTagTextContainer>
              <Styles.CornerTagText>
                {cornerTagText}
              </Styles.CornerTagText>
            </Styles.CornerTagTextContainer>
          </Styles.CornerTag>
          <Styles.CreditCardInstallmentsIconAdapter>
            <IconPix color="#2E93EE" size="60" />
          </Styles.CreditCardInstallmentsIconAdapter>
          <Styles.CloseButtonAdapter>
            <CloseIcon aria-label={closeAriaLabel} data-testid="pix-offer-popup-close" onClick={handleOnClose} />
          </Styles.CloseButtonAdapter>
          <Styles.TextContainer>
            <Styles.Title>
              {title}
            </Styles.Title>
            <Styles.Description>
              {description}
            </Styles.Description>
            <Styles.Link data-testid="pix-offer-popup-link" onClick={handleOnLinkClick}>
              {knowMore}
            </Styles.Link>
          </Styles.TextContainer>
          <Styles.Footer />
        </Styles.Content>
      </Paper>
    </Styles.Wrapper>
  );
};

export default PixOfferPopup;
