import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';

const TypeAAAAValueField = ({
  classes, t, formData, onHandleChangeInput,
}) => {
  const { loading } = useContext(DNSContext);

  return (
    <TextField
      disabled={loading}
      label={t('dns.zoneManager.addRecord.form.label.typeAAAAValueField')}
      value={formData.address}
      onChange={e => onHandleChangeInput(e.target)}
      name="address"
      variant="outlined"
      required
      className={classes.textField}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
        inputProps: {
          'data-testid': 'zoneManagerIpv6Field',
        },
      }}
    />
  );
};

export default (withI18n()(withStyles(styles)(TypeAAAAValueField)));
