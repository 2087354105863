import {
  RESEND_TWO_FACTOR_AUTH_CODE,
  RECEIVE_TWO_FACTOR_AUTH_CODE,
  REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE,
  SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE,
  TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK,
  SEND_ENABLE_TWO_FACTOR_AUTH_CODE,
  RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE,
  SEND_DISABLE_TWO_FACTOR_AUTH_CODE,
  RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE,
} from '@/redux/actions/actionsTypes';

export const initialState = {
  loadingResend: false,
  loadingValidate: false,
  validationError: false,
  data: {},
};

export default function twoFactorAuth(state = initialState, action) {
  switch (action.type) {
    case SEND_ENABLE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: true,
      };
    case RECEIVE_ENABLE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: false,
      };
    case SEND_DISABLE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: true,
      };
    case RECEIVE_DISABLE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: false,
      };
    case RESEND_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: true,
      };
    case RECEIVE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingResend: false,
      };
    case REQUEST_VALIDATE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        loadingValidate: true,
      };
    case SUCCESS_VALIDATE_TWO_FACTOR_AUTH_CODE:
      return {
        ...state,
        data: action.payload,
      };
    case TWO_FACTOR_AUTH_CODE_VALIDATION_FEEDBACK:
      return {
        ...state,
        validationError: action.payload.hasError,
        loadingValidate: false,
      };
    default:
      return state;
  }
}
