import { ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT, RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT, REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT } from '@/redux/actions/actionsTypes';

const initialState = {
  loadingCreditCardAttempt: false,
  response: false,
};

export default function creditCardAttempt(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT:
      return {
        ...state,
        loadingCreditCardAttempt: true,
      };
    case ERROR_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT:
      return {
        ...state,
        loadingCreditCardAttempt: false,
      };
    case RECEIVE_GATORPAY_PAAS_GET_CREDITCARD_ATTEMPT:
      return {
        ...state,
        response: action.success.success,
        loadingCreditCardAttempt: false,
      };
    default:
      return state;
  }
}
