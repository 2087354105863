import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionList } from '@/components/billing';
import { Filter } from '@/pages/common/Filter';
import { billingAnalytics } from '@/analytics';
import * as Styles from './SubscriptionsHandler.styles';
import { subscriptionsLocale, subscriptionLocale } from './SubscriptionsHandler.locale';
import SubscriptionsFunctions from './SubscriptionsHandler.functions';
import { useSubscriptionsFilter } from '@/hooks/billing/subscriptions/useSubscriptionsFilter';
import { useSubscriptionsMessage } from '@/hooks/billing/subscriptions/useSubscriptionsMessage';
import GroupPaymentModal from '../GroupPaymentModal/GroupPaymentModal';
import { billingActions } from '@/redux/modules/billing';
import { OriginCalling } from '../PaymentModal/PaymentModal.types';
import { Modais } from '../ManageSubscriptionNewHandler/ManageSubscriptionNewHandler.types';
import { ReactivationModal } from '../ReactivationModal';
import { preChatActions } from '@/redux/modules';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_DISCOUNT_OFFER_BANNER, XRAY_ENABLED_NEW_LAYOUT_SUBSCRIPTIONS_PAGE, XRAY_LIMIT_DATE_TO_DISPLAY_SPLIT_PAYMENT_BANNER } from '@/config/GrowthBook/constants';
import { PageTitle } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { SplitPaymentOfferModal } from '@/components/Invoices/SplitPaymentOfferModal';
import { differenceInCalendarDays } from 'date-fns';
import { getCookie, setCookie } from '@/utils/Application/cookies';

const SubscriptionHandler = (
  {
    enabledNewMessagesInSubscription = false,
    enabledModalPaymentInSubscriptionList = false,
    preChatState,
  },
) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { subscriptions: subscriptionAnalytics } = billingAnalytics;
  // States
  const billingReducer = useSelector(state => state.billing);
  const { subscriptions } = billingReducer;
  const { items: subscriptionsItems, loading: loadingSubscriptions } = subscriptions;
  const { modal } = useSelector(state => state.billing.subscriptions.cancel);
  const { triage } = useSelector(state => state.preChat);
  const [activateSubscription, setActivateSubscription] = useState(null);
  const [displayModalPayment, setDisplayModalPayment] = useState(false);
  const [displaySplitPaymentOfferModal, setDisplaySplitPaymentOfferModal] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [trustReactivationInvoiceId, setTrustReactivationInvoiceId] = useState(null);
  const [modalById, setModalById] = useState(Modais.NONE);
  const canShowReactivationModal = () => modalById === Modais.ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL;
  const enabledNewLayoutSubscriptionsPage = useFeatureIsOn(XRAY_ENABLED_NEW_LAYOUT_SUBSCRIPTIONS_PAGE);
  const enabledDiscountOfferBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_OFFER_BANNER);
  const toogleWithDate = useFeatureValue(XRAY_LIMIT_DATE_TO_DISPLAY_SPLIT_PAYMENT_BANNER);
  const limitDateValue = toogleWithDate && toogleWithDate.limitDate != null ? toogleWithDate.limitDate : null;
  const enabledInvoiceSplitPaymentBanner = limitDateValue !== null && differenceInCalendarDays(new Date(`${limitDateValue} 00:00:00`), new Date()) > 0;

  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { manageSubscription: manageSubscriptionLocale } = billingLocale;

  const {
    filteredItems,
    filterOptions,
    setFilterOptions,
    filterText,
    setFilterText,
  } = useSubscriptionsFilter({
    items: subscriptionsItems,
  });

  useEffect(() => {
    if (!loadingSubscriptions) {
      dispatch(billingActions.subscriptions.request());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (preChatState && preChatState.reactivationRedirect) {
      setTrustReactivationInvoiceId(preChatState.trustReactivationInvoiceId);
      setModalById(Modais.ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL);
    }
  }, [preChatState]);

  const getReactivationModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
    };

    return {
      onCloseClick,
      invoiceId: trustReactivationInvoiceId,
    };
  };

  const onDisplayModalPayment = (currentItem) => {
    subscriptionAnalytics.executePaymentInSubscriptionsListClick('1_link_pagar_fatura_lista');
    setDisplayModalPayment(true);
    setCurrentItem(currentItem);
  };

  const decoratedItems = useSubscriptionsMessage(filteredItems,
    {
      enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment,
    });

  const messageEventHandlers = {
    onActiveRenewal: (subscription) => {
      subscriptionAnalytics.activeRenewalClick('lista');
      setActivateSubscription(subscription);
    },
    redirect: (subscription) => {
      const { url } = subscription.message;

      if (/^https?|^mailto:/.test(url)) {
        window.open(url, '_blank');
      } else {
        history.push(url);
      }
    },
  };

  const onMessageClick = (event, subscription) => {
    const { cancelType, substatus } = subscription;

    const isAnchor = () => event.target.nodeName.toLowerCase() === 'a';
    const isActiveRenewal = () => cancelType && ['active', 'registered', 'todue', 'toDue'].includes(substatus);

    if (!isAnchor()) return;

    if (isActiveRenewal()) {
      return messageEventHandlers.onActiveRenewal(subscription);
    }

    return messageEventHandlers.redirect(subscription);
  };
  const { enabledPaymentMethods } = useSelector(state => state.billing.paymentMethods);


  const fetchEnabledPaymentMethods = useCallback(() => {
    if (enabledPaymentMethods !== null) return;
    dispatch(billingActions.paymentMethod.request());
  }, [dispatch, enabledPaymentMethods]);

  useEffect(() => {
    fetchEnabledPaymentMethods();
  }, [fetchEnabledPaymentMethods]);

  // Mount filtered options
  useEffect(() => {
    SubscriptionsFunctions.mountFilteredOptions(filterOptions, subscriptionsItems, setFilterOptions);
  }, [filterOptions, setFilterOptions, subscriptionsItems]);

  useEffect(() => {
    subscriptionAnalytics.subscriptionsPageView();
  }, [subscriptionAnalytics]);

  const openPreChatCancelFlow = useCallback(() => {
    if (triage.subscriptionToCancel) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
    }
  }, [triage, dispatch]);

  const handleActivateSubscription = (subscriptionInList) => {
    const subscription = { id: subscriptionInList.id, type: subscriptionInList.type };
    SubscriptionsFunctions.handleActivateSubscription(subscription, null);
  };


  const getFilterProps = () => {
    const defaultProps = {
      testId: 'filter-subscription',
      title: subscriptionsLocale.listTitle,
      filterLabel: subscriptionsLocale.filterLabel,
      filterChips: filterOptions,
      filterByPlaceholder: subscriptionsLocale.searchPlaceholderNewLayout,
      filterByValue: filterText,
      clearFilterLabel: subscriptionsLocale.clearLabel,
      handleClearFilter: () => SubscriptionsFunctions.clearFilter(filterOptions, setFilterOptions, setFilterText),
    };

    const clearOptions = () => {
      const newOptions = [...filterOptions].map((option) => {
        const newOption = { ...option };
        newOption.checked = false;
        return newOption;
      });
      return newOptions;
    };

    const handleChangeChip = (chip) => {
      const newOptions = clearOptions();

      const chipIndex = newOptions.findIndex(option => option.id === parseInt(chip.id, 10));

      if (!chip.checked) {
        newOptions[0].checked = true;
      }

      if (chip.checked) {
        newOptions[chipIndex].checked = true;
      }
      setFilterOptions(newOptions);
    };


    const handleChangeFilterBy = (text) => {
      setFilterText(text);
    };

    return {
      ...defaultProps,
      handleChangeFilterBy,
      handleChangeChip,
    };
  };
  const cookiePopupSplitPayment = getCookie('hg-modal-split-payment') !== 'false';


  const handleOnSplitPaymentsModalClose = () => {
    setDisplaySplitPaymentOfferModal(false);
    setCookie('hg-modal-split-payment', false);
  };

  return (
    <>

      {enabledInvoiceSplitPaymentBanner && displaySplitPaymentOfferModal && cookiePopupSplitPayment && (
        <SplitPaymentOfferModal
          onClose={handleOnSplitPaymentsModalClose}
        />
      )}

      <Styles.Wrapper data-testid="subscription-handler">
        <Styles.Container>
          <Styles.HeaderRow>
            {!enabledNewLayoutSubscriptionsPage && (
              <>
                <Styles.TitleWrapper>
                  <Styles.Title>
                    {subscriptionsLocale.listTitle}
                  </Styles.Title>
                </Styles.TitleWrapper>

                <Styles.FilterWrapper>
                  <Filter
                    filterLabel={subscriptionsLocale.filterLabel}
                    filteredOptions={filterOptions}
                    setFilteredOptions={setFilterOptions}
                    filterTyped={filterText}
                    setFilterTyped={setFilterText}
                    clearFilter={() => SubscriptionsFunctions.clearFilter(filterOptions, setFilterOptions, setFilterText)}
                    searchPlaceholder={subscriptionsLocale.searchPlaceholder}
                    clearLabel={subscriptionsLocale.clearLabel}
                    onFilterClick={e => SubscriptionsFunctions.handleOnFilterClick(e)}
                  />
                </Styles.FilterWrapper>
              </>
            )}
            {enabledNewLayoutSubscriptionsPage && (
              <Styles.NewFilterWrapper>
                <PageTitle {...getFilterProps()} />
              </Styles.NewFilterWrapper>
            )}
          </Styles.HeaderRow>
          <Styles.Content>
            <SubscriptionList
              activateSubscription={activateSubscription}
              activeStatusLabel={subscriptionsLocale.activeStatusLabel}
              buttonManageSubscriptionsLabel={enabledNewLayoutSubscriptionsPage ? subscriptionsLocale.manageSubscriptionLabel : subscriptionsLocale.buttonManageSubscriptionLabel}
              cancelledStatusLabel={subscriptionsLocale.cancelledStatusLabel}
              domainLabel={subscriptionsLocale.domainLabel}
              expiredStatusLabel={subscriptionsLocale.expiredStatusLabel}
              handleActivateSubscription={handleActivateSubscription}
              handleCloseRenewModal={() => SubscriptionsFunctions.handleCloseRenewModal(setActivateSubscription)}
              loading={loadingSubscriptions}
              onMessageClick={onMessageClick}
              modal={modal}
              modalActiveRenewalButtonLabel={subscriptionLocale.modalActiveRenewalButtonLabel}
              modalActiveRenewalContent={subscriptionLocale.modalActiveRenewalContent}
              modalActiveRenewalTitle={subscriptionLocale.modalActiveRenewalTitle}
              modalContent={subscriptionsLocale.modal}
              onHandleManageSubscription={e => SubscriptionsFunctions.onHandleManageSubscription(e, history)}
              onModalClose={() => SubscriptionsFunctions.handleOnModalClose()}
              overdueStatusLabel={subscriptionsLocale.overdueStatusLabel}
              pendingAnalysisStatusLabel={subscriptionsLocale.pendingAnalysisStatusLabel}
              pendingPaymentStatusLabel={subscriptionsLocale.pendingPaymentStatusLabel}
              pendingStatusLabel={subscriptionsLocale.pendingStatusLabel}
              registeredStatusLabel={subscriptionsLocale.registeredStatusLabel}
              subscriptions={decoratedItems}
              suspendedStatusLabel={subscriptionsLocale.suspendedStatusLabel}
              toDueStatusLabel={subscriptionsLocale.toDueStatusLabel}
              transferredAwayStatusLabel={subscriptionsLocale.transferredAwayStatusLabel}
              openPreChatCancelFlow={openPreChatCancelFlow}
              enabledNewLayoutSubscriptionsPage={enabledNewLayoutSubscriptionsPage}
              enabledDiscountOfferBanner={enabledDiscountOfferBanner}
              orderStatusLocale={manageSubscriptionLocale.orderUnderReview}
              routesLocale={routesLocale}
              readMoreStepper={manageSubscriptionLocale.readMoreStepper}
            />
          </Styles.Content>
        </Styles.Container>
      </Styles.Wrapper>
      {currentItem && (
        <GroupPaymentModal
          displayModalPayment={displayModalPayment}
          setDisplayModalPayment={setDisplayModalPayment}
          subscription={currentItem}
          originCalling={OriginCalling.SUBSCRIPTION_LIST}
        />
      )}
      {canShowReactivationModal() && (
        <ReactivationModal {...getReactivationModalProps()} />
      )}
    </>
  );
};

export default SubscriptionHandler;
