import React, { } from 'react';
import { withI18n } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OFFER_RETENTION_CANCEL_TYPES, RETENTION_TYPES } from './RetentionsOfferEnum';
import { DisableAutoRenewHandler } from '@/components/billing/subscriptions/DisableAutoRenewHandler';

const RetentionOfferPage = ({ handleDeclineRenewDiscount }) => {
  const offerRetentionCancel = useSelector(state => state.billing.subscriptions.offerRetentionCancel);

  return (
    <>
      <DisableAutoRenewHandler
        loading={false}
        type={offerRetentionCancel === OFFER_RETENTION_CANCEL_TYPES.change_payment_cycle
          ? RETENTION_TYPES.change_payment_cycle
          : RETENTION_TYPES.disable_auto_renewal
        }
        handleDeclineRenewDiscount={handleDeclineRenewDiscount}
      />
    </>
  );
};

export default withI18n()(RetentionOfferPage);
