import React from 'react';
import * as Style from './IconMaterials.style';

const IconMaterials = ({
  color = '#4A4A4A',
  backgroundColor = '#FF9144',
  width = '33',
  height = '26',
  testId = 'icon-materials',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0214844 5.86133H24.9575V16.1293C24.9575 18.2041 24.1332 20.1939 22.6661 21.661C21.199 23.1281 19.2092 23.9523 17.1345 23.9523H7.84448C5.76969 23.9523 3.77984 23.1281 2.31274 21.661C0.845647 20.1939 0.0214844 18.2041 0.0214844 16.1293V5.86133Z" fill={backgroundColor} />
      <path d="M11.7568 0V25.419H21.2158V23.075H13.8568V2.349H29.9229V24.249H32.0228V0H11.7568Z" fill="#4A4A4A" />
      <path d="M27.4339 22.5859H22.5449V25.3799H27.4339V22.5859Z" fill={color} />
      <path d="M25.1323 6.61133H18.7383V9.11133H25.1323V6.61133Z" fill={color} />
      <path d="M25.1323 11.0586H18.7383V13.5586H25.1323V11.0586Z" fill={color} />
      <path d="M27.3584 15.5059H17.7334V18.0059H27.3584V15.5059Z" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconMaterials;
