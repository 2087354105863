import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  ${({ position }) => css`
    align-items: center;
    background-color: rgba(74,74,74,0.8);
    display: flex;
    height: 100vh;
    min-height: 40vh;
    justify-content: center;
    left: 0;
    position: ${position};
    top: 0;
    width: 100vw;
    z-index: 130;
  `}
`;

export const ModalCard = styled.div`${(
  {
    theme, maxWidth, maxHeight, disablePadding, disableOverFlow, variant, centerContentInMobile,
  },
) => {
  const variants = {
    default: { margin: '16px' },
    regular: { margin: '32px' },
  };
  return css`
    background-color: ${theme.palette.white.light};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: ${`${maxHeight}px`};
    max-width: ${`${maxWidth}px`};
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.v1.color.white.primary};
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.v1.color.text.disabled};
      border-left: 0;
      border-radius: 25px;
      border-right: 0;
      height: 30px;
      width: 100%;  
    }

    ${!disableOverFlow && css`
    overflow: auto;
  `}

    ${!disablePadding && css`
      padding: ${variants[variant].margin};
    `}
  
    position: relative;
    width: 100%;
  
    @media (max-width: ${theme.breakpoints.sm}) {
      height: 100%;
      max-height: 100%;
      ${centerContentInMobile && css`
        justify-content: center;
      `}
      },
  `;
}}
  `;

export const CloseButton = styled.button`
  ${({ theme, variant, whiteIcon }) => {
    const variants = {
      default: { margin: '16px' },
      regular: { margin: '32px' },
    };
    return css`
    align-items: center;
    background-color: unset;
    border: unset;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: ${variants[variant].margin};
    top: ${variants[variant].margin};
    width: 24px;
    z-index: ${theme.v1.layers.important};

    svg {
      fill: ${whiteIcon ? theme.v2.colors.white.primary : theme.palette.grey.main};
      transition: fill 200ms ease-in-out;
      &:hover{
        fill: ${theme.palette.secondary.primary};
      }
    }
  `;
  }}
  `;
