import { all } from 'redux-saga/effects';
import { authSagas } from './auth';
import { billingSagas } from './billing';
import { domainsSagas } from './domains';
import { emailsSagas } from './emails';
import { sitesSagas } from './sites';
import { productsSagas } from './products';
import { dnsWizardSagas } from './dnsWizard';
import { faqSagas } from './faq';
import { invoicesSagas } from './invoices';
import { asaasSagas } from './asaas';
import { preChatSagas } from './preChat';
import { formsSagas } from './forms';
import { backupSagas } from './backup';
import { securitySagas } from './security';
import { linkNaBioSagas } from './linkNaBio';
import { videoClassesSagas } from './videoClasses';
import { cloudflareSagas } from './cloudflare';
import { commonSagas } from './common';

const sagas = [
  authSagas(),
  billingSagas(),
  domainsSagas(),
  emailsSagas(),
  sitesSagas(),
  productsSagas(),
  faqSagas(),
  dnsWizardSagas(),
  invoicesSagas(),
  asaasSagas(),
  preChatSagas(),
  formsSagas(),
  backupSagas(),
  securitySagas(),
  linkNaBioSagas(),
  videoClassesSagas(),
  cloudflareSagas(),
  commonSagas(),
];

function* rootSaga() {
  yield all(sagas);
}

export default rootSaga;
