
const styles = theme => ({
  containerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down(1465)]: {
      width: '70%',
    },
    [theme.breakpoints.down(992)]: {
      flexDirection: 'column',
    },
  },
  ctasWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1465)]: {
      width: '100%',
      marginTop: 5,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(1072)]: {
      margin: 0,
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: 360,
    },
  },
  container: {
    position: 'relative',
    padding: '2px 0px 5px 0px',
  },
  borderContent: {
    borderRadius: 0,
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    borderLeft: `5px solid ${theme.color.oceanGreen}`,
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '10px 30px 0 30px',
    minHeight: 100,
    '&:last-child': {
      paddingBottom: 10,
    },
    [theme.breakpoints.down(1465)]: {
      justifyContent: 'flex-start',
      padding: '20px 30px 0 30px',
      '&:last-child': {
        paddingBottom: 21,
      },
    },
  },
  emailAddressWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    width: 350,
    marginRight: 40,
    [theme.breakpoints.down(1465)]: {
      width: 425,
    },
  },
  emailIcon: {
    color: theme.color.oceanGreen,
  },
  emailAddress: {
    display: 'inline-block',
    paddingLeft: 17,
    fontSize: 16,
    fontWeight: 400,
  },
  quotaWrapper: {
    minWidth: 153,
    marginRight: 15,
    [theme.breakpoints.down(1072)]: {
      marginTop: 10,
    },
    [theme.breakpoints.down(960)]: {
      width: '100%',
    },
  },
  quotaLabel: {
    margin: 0,
    fontSize: 12,
    color: theme.color.regentGray,
  },
  quotaValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    color: theme.color.tundora,
  },
  infinityIcon: {
    fontSize: 16,
    marginLeft: 6,
    marginRight: 6,
  },
  buttonsContainer: {
    width: 'auto',
    marginRight: 15,
    [theme.breakpoints.down(960)]: {
      margin: '10px 0 0 -7px',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    [theme.breakpoints.down(1465)]: {
      width: '30%',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      width: '100%',
    },
  },
  cardActions: {
    minWidth: 76,
    display: 'initial',
    padding: '0',
    '& > a': {
      '&:hover': {
        color: theme.color.chambray,
        textDecoration: 'underline',
      },
      transition: 'color 0.2s',
      textTransform: 'uppercase',
      textDecoration: 'none',
      textAlign: 'right',
      color: theme.color.indigo,
      fontWeight: '400',
      margin: 0,
    },
  },
  diskQuota: {
    marginRight: 3,
    marginLeft: 3,
  },
});

export default styles;
