import React, { useContext, useEffect, useState } from 'react';
import { RetentionBenefitHandler } from '@/components/billing';
import { useHistory, useParams } from 'react-router';
import { withI18n } from 'react-i18next';
import * as Styles from './RetentionBenefitPage.styles';
import useLocale from '@/hooks/useLocale';
import { useBenefitParams } from '@/hooks/billing/subscriptions/useBenefitParams';
import { useBenefitUrlVerification } from '@/hooks/billing/subscriptions/useBenefitUrlVerification';
import { useBenefitInvoice } from '@/hooks/billing/subscriptions/useBenefitInvoice';
import { useBenefitAnalytics } from '@/hooks/billing/subscriptions/useBenefitAnalytics';
import { ThemeContext } from 'styled-components';
import { useSubscriptionHeaderProps } from '@/hooks/billing/subscriptions/useSubscriptionHeaderProps';
import { ALPHA_NEW_BENEFIT_PRICE_VISUALIZATION } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useDispatch, useSelector } from 'react-redux';
import { AcceptedOfferSuccessModal } from '@/components/billing/subscriptions/AcceptedOfferSuccessModal';
import { billingAnalytics } from '@/analytics';
import { enqueueSnackbar } from '@/redux/actions/notifications';

const RetentionBenefitPage = ({ t }) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const colorText = theme.v2.colors.neutral.lowPure;
  const { currentItem, retentionInfos } = useBenefitParams();
  const { retentionTypeParam, isRenewCanceling } = useBenefitUrlVerification(retentionInfos, t);
  const {
    invoiceModalIsOpen,
    invoiceIsLoading,
    closeInvoiceModal,
    getInvoiceInfos,
    redirectToInvoice,
    publishInvoiceDraft,
  } = useBenefitInvoice();
  const { analyticsBenefitAccept, analyticsBenefitDecline } = useBenefitAnalytics(retentionInfos);
  const headerProps = useSubscriptionHeaderProps(currentItem, 'benefit-handler-header');
  const history = useHistory();
  const { subscriptionId, subscriptionType } = useParams();
  const enabledBenefitNewPrice = useFeatureIsOn(ALPHA_NEW_BENEFIT_PRICE_VISUALIZATION);
  const [isAcceptOffer, setAcceptOffer] = useState(false);
  const invoiceStatus = useSelector(state => state.billing.invoice);
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const [version, setVersion] = useState(0);
  const subscription = useSelector(state => state.billing.subscription);
  const reactivationData = subscription && subscription.reactivation && subscription.reactivation.length > 0 && subscription.reactivation[0];

  const {
    subscriptions: {
      acceptedOfferSuccessModalClose, acceptedOfferSuccessModalDisplay, acceptedOfferSuccessModalRenewalAfterOption, acceptedOfferSuccessModalRenewalNowOption, acceptedOfferSuccessModalPayNowOption,
    },
  } = billingAnalytics;

  const handleLoading = () => {
    if (invoiceIsLoading) {
      setVersion(invoiceStatus && invoiceStatus.draft ? 0 : 1);
      setAcceptOffer(true);
    }
  };

  useEffect(() => {
    handleLoading();
  }, [invoiceStatus]);

  const cancelButtonClick = () => {
    analyticsBenefitDecline();
    const cancelPaths = {
      CANCEL_RENEWAL: `${routesLocale.manageSubscription}${routesLocale.cancelRenewal}/${subscriptionId}/${subscriptionType}`,
      CANCEL_IMMEDIATE: `${routesLocale.manageSubscription}${routesLocale.cancelSubscription}/${subscriptionId}/${subscriptionType}`,
    };
    history.push(cancelPaths[retentionTypeParam]);
  };

  const acceptBenefitClick = () => {
    analyticsBenefitAccept();
    getInvoiceInfos(currentItem.id);
  };

  const getAcceptedOfferSuccessModalProps = () => {
    const onCloseClick = () => {
      acceptedOfferSuccessModalClose();
      history.push(`${routesLocale.subscriptions}${routesLocale.manageSubscription}/${currentItem.id}/${currentItem.type}`);
    };

    const onBackAction = () => {
      acceptedOfferSuccessModalRenewalAfterOption();
      history.push(`${routesLocale.subscriptions}${routesLocale.manageSubscription}/${currentItem.id}/${currentItem.type}`);
    };

    const onDisplay = () => {
      acceptedOfferSuccessModalDisplay(invoiceStatus.draft);
    };

    const onAcceptAction = () => {
      if (invoiceStatus.draft === false) {
        acceptedOfferSuccessModalPayNowOption();
        history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceStatus.invoiceId}?open_invoice=true`);
      } else {
        const executePayment = (enabledToPayment) => {
          enabledToPayment ? history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceStatus.invoiceId}?open_invoice=true`)
            : dispatch(enqueueSnackbar(billingLocale.genericError));
        };

        acceptedOfferSuccessModalRenewalNowOption();
        publishInvoiceDraft({ invoiceId: invoiceStatus.invoiceId, executePayment });
      }
    };

    return {
      onBackAction,
      onDisplay,
      onCloseClick,
      invoiceId: currentItem.invoiceId,
      onAcceptAction,
      version,
    };
  };

  return (
    <>
      <Styles.Wrapper>
        <RetentionBenefitHandler
          currentItem={currentItem}
          headerProps={headerProps}
          colorText={colorText}
          invoiceModalIsOpen={invoiceModalIsOpen}
          isRenewCanceling={isRenewCanceling}
          invoiceIsLoading={invoiceIsLoading}
          acceptBenefitClick={acceptBenefitClick}
          cancelButtonClick={cancelButtonClick}
          closeInvoiceModal={closeInvoiceModal}
          redirectToInvoice={redirectToInvoice}
          enabledBenefitNewPrice={enabledBenefitNewPrice}
          reactivationData={reactivationData}
        />
      </Styles.Wrapper>

      {isAcceptOffer && (
        <AcceptedOfferSuccessModal {...getAcceptedOfferSuccessModalProps()} />
      )
      }
    </>
  );
};

export default withI18n()(RetentionBenefitPage);
