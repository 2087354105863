export const TAB_TYPES = {
  THREATS_DETECTOR: 'THREATS_DETECTOR',
  LAST_SCANS: 'LAST_SCANS',
  SECURITY_SEAL: 'SECURITY_SEAL',
  CONFIGURATIONS: 'CONFIGURATIONS',
};

export const SITELOCK_COLORS = [
  'white', 'red',
];

export const SITELOCK_SIZE = [
  'small', 'medium', 'big',
];

export const SITELOCK_TYPE = [
  'mal', 'secure',
];

export const SITELOCK_LANGUAGE = [
  'br', 'es', 'en',
];

export const SITELOCK_PRODUCT_NAME = {
  SITELOCK_FREE: 'Sitelock Free',
  SITELOCK_BASIC: 'Sitelock Basic',
  SITELOCK_PROFESSIONAL: 'Sitelock Professional',
  SITELOCK_PREMIUM: 'Sitelock Premium',
  SITELOCK_ENTERPRISE: 'Sitelock Enterprise',
};
