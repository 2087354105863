import {
  REQUEST_PRODUCT_GROUPS, RECEIVE_PRODUCT_GROUPS, FILTER_PRODUCTS_GROUPS,
  UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS, ABORT_REQUEST_PRODUCTS_GROUPS,
  REMOVE_PRODUCT_GROUP,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  byId: [],
  bkpProducts: [],
  selectFilters: {
    site: [],
    status: [],
  },
  loadedDate: null,
};

export default function productGroups(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PRODUCT_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case FILTER_PRODUCTS_GROUPS:
      return {
        ...state,
        byId: action.filterProductGroups,
      };
    case RECEIVE_PRODUCT_GROUPS:
      return {
        ...state,
        loading: false,
        bkpProducts: action.productGroups,
        byId: action.productGroups,
        selectFilters: {
          ...state.selectFilters,
          status: action.filterStatus,
          site: action.filterDomain,
        },
        loadedDate: action.loadedDate,
      };
    case UPDATE_SITEBUILDER_STATUS_IN_MYSITES_PRODUCTS:
      return {
        ...state,
        byId: action.data,
        bkpProducts: action.data,
      };
    case ABORT_REQUEST_PRODUCTS_GROUPS:
      return {
        ...state,
        byId: state.bkpProducts,
      };
    case REMOVE_PRODUCT_GROUP:
      return {
        ...state,
        byId: action.newGroups,
      };
    default:
      return state;
  }
}
