import React from 'react';
import * as Styles from './EmailCard.styles';
import { Button, IconEmail, IconTriangleDetail } from 'gatorcomponents';
import Snappy from '@/media/domains/snappy.svg';

const EmailCard = ({
  manageDomainHandlerLocale,
  redirectToEmail,
}) => {
  const handleClick = () => {
    redirectToEmail && redirectToEmail();
  };

  return (
    <>
      <Styles.CardTitle>
        <IconEmail />
        {manageDomainHandlerLocale.cardOptions.mail.title}
      </Styles.CardTitle>

      <Styles.CardContent>
        <Styles.CardText>
          {manageDomainHandlerLocale.cardOptions.mail.description}
        </Styles.CardText>
      </Styles.CardContent>

      <Button
        label={manageDomainHandlerLocale.cardOptions.mail.cta}
        onClick={handleClick}
        testId="emailCta"
        variant="secondary"
        width="fit"
      />

      <Styles.FloatingIcon>
        <IconTriangleDetail size="lg" color="attentionPure" />
      </Styles.FloatingIcon>

      <Styles.FloatingImage>
        <Styles.Image src={Snappy} />
      </Styles.FloatingImage>
    </>
  );
};

export default EmailCard;
