import React from 'react';
import * as Styles from './WizardAsaasLoginBanner.styles';
import { Button } from '@/pages/common/v1';
import AsaasStamp from '@/media/asaas/asaas-stamp.svg';

const WizardAsaasLoginBanner = ({
  testId = 'wizard-asaas-login-banner',
  title = 'Asaas Wizard Title',
  description = 'Asaas Wizard Description',
  buttonLabel = 'Asaas Wizard Button Label',
  onButtonClick,
}) => {
  const handleClick = () => {
    onButtonClick && onButtonClick();
  };
  return (
    <Styles.WizardAsaasLoginBannerWrapper data-testid={`${testId}-wrapper`}>
      <Styles.Container>
        <Styles.AsaasStamp src={AsaasStamp} />

        <Styles.Title data-testid={`${testId}-title`}>{title}</Styles.Title>

        <Styles.Description data-testid={`${testId}-description`}>{description}</Styles.Description>

        <Button variant="primary" label={buttonLabel} onClick={handleClick} size="regular" testId={`${testId}-button`} />
      </Styles.Container>
    </Styles.WizardAsaasLoginBannerWrapper>
  );
};

export default WizardAsaasLoginBanner;
