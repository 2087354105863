import { CloudflareActionTypes } from './cloudflare.types';


const domainsRequest = ({ hostingId, useMockoonEnabled }) => ({
  type: CloudflareActionTypes.DOMAINS_REQUEST,
  payload: { hostingId, useMockoonEnabled },
});

const domainsFailure = payload => ({
  type: CloudflareActionTypes.DOMAINS_FAILURE,
  payload,
});

const domainsSuccess = payload => ({
  type: CloudflareActionTypes.DOMAINS_SUCCESS,
  payload,
});

const domainsSet = payload => ({
  type: CloudflareActionTypes.DOMAINS_SET,
  payload,
});

const domainsLoading = payload => ({
  type: CloudflareActionTypes.DOMAINS_LOADING,
  payload,
});

const domainsError = bool => ({
  type: CloudflareActionTypes.DOMAINS_ERROR,
  payload: { bool },
});

const activateCdn = ({
  hostingId, domain, domainOrigin, useMockoonEnabled,
}) => ({
  type: CloudflareActionTypes.ACTIVATE_CDN,
  payload: {
    hostingId,
    domain,
    domainOrigin,
    useMockoonEnabled,
  },
});

const deactivateCdn = ({
  hostingId, domain, domainOrigin, useMockoonEnabled,
}) => ({
  type: CloudflareActionTypes.DEACTIVATE_CDN,
  payload: {
    hostingId,
    domain,
    domainOrigin,
    useMockoonEnabled,
  },
});

const setLoadingActivation = bool => ({
  type: CloudflareActionTypes.LOADING_ACTIVATION,
  payload: bool,
});

const setActivatedDomain = domain => ({
  type: CloudflareActionTypes.SET_ACTIVATED_DOMAIN,
  payload: {
    domain,
  },
});

const succeededActivation = () => ({
  type: CloudflareActionTypes.LOADING_ACTIVATION_SUCCESS,
});

const faliedActivation = () => ({
  type: CloudflareActionTypes.LOADING_ACTIVATION_FAILURE,
});

const succeededDeactivation = () => ({
  type: CloudflareActionTypes.LOADING_DEACTIVATION_SUCCESS,
});

const faliedDeactivation = () => ({
  type: CloudflareActionTypes.LOADING_DEACTIVATION_FAILURE,
});

const actions = {
  domains: {
    domainsRequest,
    domainsFailure,
    domainsSuccess,
    domainsSet,
    domainsLoading,
    domainsError,
  },
  cdn: {
    activateCdn,
    deactivateCdn,
    setLoadingActivation,
    setActivatedDomain,
    succeededActivation,
    faliedActivation,
    succeededDeactivation,
    faliedDeactivation,
  },
};

export default actions;
