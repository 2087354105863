import React from 'react';
import * as Style from './IconServer.style';

const IconServer = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-server">
    <svg id="icon_gmd_dns_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width="24" height="24" viewBox="0 0 24 24">
      <path id="Caminho_247491" data-name="Caminho 247491" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_247492" data-name="Caminho 247492" d="M20,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13ZM7,19a2,2,0,1,1,2-2A2.006,2.006,0,0,1,7,19ZM20,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM7,9A2,2,0,1,1,9,7,2.006,2.006,0,0,1,7,9Z" />
    </svg>
  </Style.Wrapper>
);


export default IconServer;
