import React from 'react';
import { Card, withStyles } from '@material-ui/core';

import styles from './styles';


function ProductCard({ classes, children }) {
  return (
    <Card elevation={1} className={classes.container}>
      {children}
    </Card>
  );
}

export default withStyles(styles, { withTheme: true })(ProductCard);
