import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0;
    padding: 16px 0px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      background-color: ${theme.v2.colors.white.primary};
      margin: 0 24px;
      padding: 24px;
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px 16px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      padding: 0px 0px 24px;
    }
  `}
`;

export const DomainName = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;

    > div {
      position: relative;
      width: fit-content;
    }

    @media(min-width:${theme.v2.breakpoints.sm}){
      align-items: center;
      color: ${theme.v2.colors.neutral.lowDark};
      flex-direction: row;
      font-size: ${theme.v2.font.sizes.md};
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const SkeletonRow = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    @media(min-width:${theme.v2.breakpoints.sm}){
      align-items: center;
      flex-direction: row;
    }
  `}
`;

export const AlertContainer = styled.div`
  ${({ theme, status }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: container;
    flex-wrap: wrap;
    gap: 24px;
    margin: 0px;
    padding: 24px 16px;

    > div {
      padding: 0px;
    }

    > button {
      align-self: center;
    }

    @media(min-width:${theme.v2.breakpoints.sm}){
      flex-direction: row;
      flex-wrap: nowrap;
      max-width: ${status === 'nextDueDate' ? '788px' : '616px'};
      padding: 0px 0px 24px;
    }

  `}
`;

export const DataContainer = styled.div`
  ${({ theme, $loading }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${$loading ? '16px' : '24px 32px'};
    margin: 8px 0px 0px;
    padding: 24px 16px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      align-items: flex-end;
      margin: 0px;
      padding: 0px 0px 24px;
    }
  `}
`;

export const DataItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const DataValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: column;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const ManageMentContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px 32px;
    margin: 8px 0px 0px;
    padding: 16px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
      flex-direction: row;
      margin: 0px;
      padding: 24px 0px;
    }
  `}
`;

export const ContainerColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    gap: 16px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      flex: 1 0 606px;
    }
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    border: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    position: relative;
    width: 100%;

    > button {
      width: fit-content;
    }
  `}
`;

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    position: relative;
  `}
`;
