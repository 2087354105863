const styles = theme => ({
  select: {
    width: 197,
  },
  inputField: {
    width: '200px',
    '@media(max-width: 1060px)': {
      width: '100%',
    },
  },
  listTitle: {
    fontWeight: 300,
    fontSize: '24px',
    margin: '0 20px 0 32px',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 45,
    '@media(max-width: 1060px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
  },
  selectField: {
    width: '199px',
    marginRight: '15px',
    marginBottom: 0,
    '@media(max-width: 1060px)': {
      width: '100%',
      marginBottom: 5,
    },
  },
  card: {
    minHeight: '60px',
    position: 'relative',
    borderRadius: '0',
    boxShadow: 'none',
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width: 1060px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 2,
    },
  },
  filterTitle: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    paddingRight: '15px',
    fontWeight: 400,
    '@media(max-width: 1060px)': {
      fontSize: '24px',
      fontWeight: 300,
      paddingTop: '10px',
      marginLeft: '32px',
      paddingBottom: '10px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandMobile: {
    position: 'absolute',
    top: '7px',
    right: '12px',
  },
  collapseContainer: {
    width: '100%',

  },
  collapseWrapper: {
    width: '100%',
    background: 'transparent',
    display: 'flex',
    paddingRight: '11px',
    justifyContent: 'flex-end',
    '@media(max-width: 1060px)': {
      flexDirection: 'column',
      paddingLeft: '23px',
      paddingRight: '23px',
    },
  },
  arrow: {
    fill: theme.color.tundora,
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '@media(max-width: 1060px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
});

export default styles;
