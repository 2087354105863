import { Typography, withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { SUPPORT_ARTICLE_KNOW_MORE_DNS } from '@/config/urls/supportUrls';
import styles from './styles';

const ZoneManager = ({
  classes, t,
}) => (
  <div aria-label={t('dns.zoneManager.explanation')} className={classes.explanationWrapper} data-testid="zoneManagerExplanation">
    <Typography className={classes.explanation}>
      {t('dns.zoneManager.explanation')}
    </Typography>


    <Typography className={`${classes.explanation} ${classes.knowMore}`}>
      {t('dns.zoneManager.explanationKnowMore')}
      &nbsp;
      <a href={SUPPORT_ARTICLE_KNOW_MORE_DNS} target="_blank" rel="noopener noreferrer" className={classes.explanationLink}>
        {t('dns.zoneManager.explanationKnowMore_2')}
      </a>
    </Typography>
  </div>
);

export default withI18n()(withWidth()(withStyles(styles, { withTheme: true })(ZoneManager)));
