import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonOutline = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.md};
    font-family: ${theme.v1.font.family.primary};
    font-weight: ${theme.font.weights.medium};
    line-height: 22px;
    background-color: transparent;
    padding: 8px 0;
    margin-bottom: 16px;
    color: ${theme.palette.secondary.primary};
    border-radius: 20px;
    border: 2px solid ${theme.palette.secondary.primary};
    cursor: pointer;

    &:hover{
      background-color: ${theme.palette.blue.dark};
      border-color: ${theme.palette.blue.dark};
      color: ${theme.palette.white.light};
    }
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      margin:0 32px 0 0;
      padding: 8px 24px;
    }
    @media (max-width: ${theme.v1.breakpoints.sm}) {
      ${props => (props.hasHostGatorPlan === false ? 'max-width:35vw' : 'max-width:90vw')}
    }
  `}
`;

export const Title = styled.strong`
  ${({ theme }) => css`
    display: inline;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxl};
    line-height: 32px;
    color: ${theme.palette.primary.darkest};
  `}
`;

export const WrapButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    text-align: center;
    justify-content: start;
    flex-direction: column;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      align-items: baseline;
      flex-direction: row;
    }
  `}
`;

export const WrapImgSmall = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: -4px;
    right: 8px;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const WrapImgLarge = styled.div`
  ${({ theme }) => css`
    display: none;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      display: flex;
      justify-content: center;
    }
  `}
`;

export const WrapIconFeaturedStar = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`;

export const FluidText = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.sm};
    color: ${theme.palette.grey.dark};
    margin: 8px 0 16px 0;
    line-height: 19px;
    > div{
      display: inline-block;
      margin-left: 3px;
      span{
        height: 14px;
        height: 14px;
      }
    }
    @media (min-width: ${theme.v1.breakpoints.md}) {
      display: flex;
      align-items: center;
    }
  `}
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.blue.light} 0% 0% no-repeat padding-box;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      padding: 24px 32px;
    }
  `}
`;

export const ContainerButton = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.v1.breakpoints.sm}) {
      margin-top: 0px;
      display: flex;
    }
  `}
`;

export const CTA = styled.div`
  ${({ theme, arrow }) => css`
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    color: ${theme.palette.secondary.primary};
    line-height: ${theme.font.sizes.xl};
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    cursor: pointer;

    & > span {
      content:"\a";
      white-space: pre;
    }

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      width: min-content;
      white-space: nowrap;
      transition: transform 0.3s, opacity 0.3s;
      text-align: right;
      justify-self: end;
      & > span {
        content:"";
        white-space: nowrap;
      }
    }

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: url(${arrow});
      font-size: ${theme.font.sizes.xxxxxxl};
      font-weight: ${theme.font.weights.bold};
      opacity: 0;
      position: absolute;
      transition: transform 0.1s, opacity 0.1s;
    }

    &:hover:after {
      opacity: 1;
      transform: translate(5px, -3px);
    }
  `}
`;
