const styles = theme => ({
  linkMessage: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.color.chambray,
  },
  infoIcon: {
    color: theme.color.indigo,
    fontSize: '22px',
  },
  renewWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  renew: {
    display: 'block',
    marginTop: '-13px',
    height: '30px',
    fontSize: '14px',
    marginLeft: '-13px',
  },
  tooltipHelper: {
    marginBottom: -9,
    opacity: 1,
    transition: 'opacity 0.1s',
    '&:hover': {
      opacity: '0.8',
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '24px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px dashed ${theme.color.mystic}`,
    },
  },
  domainWrapper: {
    display: 'inline-block',
    paddingLeft: '15px',
    marginRight: 60,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  dnsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 22,
  },
  dnsContainer: {
    marginRight: 20,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  detailWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  shortcuts: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: 13,
  },
  customCard: {
    [theme.breakpoints.down('xs')]: {
      margin: '0',
    },
    '& .cardDomainDetailHeader': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '24px',
      paddingBottom: '20px',
      [theme.breakpoints.up('md')]: {
        borderBottom: `1px dashed ${theme.color.mystic}`,
      },
    },
    '& .flexGrid': {
      marginRight: 50,
      marginBottom: '15px',
      maxWidth: '480px',
      [theme.breakpoints.down('md')]: {
        width: '33%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '50%',
        marginRight: 0,
      },
    },
    '& .flexGridFullWidth': {
      marginRight: 50,
      marginBottom: '15px',
      maxWidth: '480px',
      [theme.breakpoints.down('md')]: {
        width: '33%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 0,
      },
    },
    '& .flexGrind:nth-child(2)': {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
  },
  subdomain: {
    textAlign: 'left',
    '&:after': {
      content: '" / "',
      paddingRight: '10px',
      paddingLeft: '10px',
      color: theme.color.geyser,
      [theme.breakpoints.down('xs')]: {
        content: '" "',
      },
    },
    '&:last-child:after': {
      content: '""',
    },
  },
  subdomainsIcon: {
    color: theme.color.tundora,
    verticalAlign: 'middle',
    marginRight: '7px',
  },
  divider: {
    borderBottom: `1px dashed ${theme.color.mystic}`,
    paddingLeft: '0px',
    paddingRight: '0px',
    marginTop: '18px',
    marginBottom: '16px',
  },
  link: {
    color: theme.color.tundora,
    backgroundColor: theme.color.white,
    border: `1px solid ${theme.color.oceanGreen}`,
    cursor: 'pointer',
    height: '32px',
    borderRadius: '16px',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '10px',
    padding: 0,
    '&:focus': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
    '&:hover': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
    '& > span': {
      fontSize: '12px',
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    '&.linkDisabled': {
      border: `1px solid ${theme.color.lightShade}`,
      color: theme.color.regentGray,
      '&:hover, &:focus': {
        backgroundColor: theme.color.white,
        color: theme.color.regentGray,
      },
    },
  },
  cardContentProduct: {
    paddingTop: '24px',
    paddingLeft: '30px',
    paddingRight: '30px',
    '&:last-child': {
      paddingBottom: '6px',
    },
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingBottom: '18px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        paddingBottom: '24px',
      },
    },
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.2',
  },
  cardSubTitle: {
    color: theme.color.regentGray,
  },
  cardDetail: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '12px',
    },
  },
  detailParam: {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    color: theme.color.tundora,
  },
  detailSubDomain: {
    marginLeft: '30px',
    lineHeight: 1.7,
    fontSize: '14px',
  },
  statusLabel: {
    marginLeft: 20,
  },
  topWrapper: {
    marginBottom: '16px',
  },
  dnsPropagationWrapper: {
    marginBottom: '-2px',
    marginTop: '5px',
  },
  dnsPropagation: {
    display: 'flex',
    gap: '12px',
  },
});

export default styles;
