import React from 'react';

const IconPaypalLogo = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.94 23.87"
  >
    <g>
      <path d="M18,6.17a8.51,8.51,0,0,0,.06-1A5.16,5.16,0,0,0,12.93,0H4.3A1.11,1.11,0,0,0,3.2.93L0,19.83a1.1,1.1,0,0,0,.9,1.28l.2,0H4.27a1.13,1.13,0,0,0,1.1-.93l0-.09h0L5,22.73a1,1,0,0,0,.8,1.12l.16,0H8.67a1,1,0,0,0,1-.82l.78-4.66A1.67,1.67,0,0,1,12.07,17h.72a7.14,7.14,0,0,0,7.14-7.13A4.55,4.55,0,0,0,18,6.17Z" fill="#002987" />
      <path d="M18,6.17A8.12,8.12,0,0,1,10,13.29H7.55a1.21,1.21,0,0,0-1.16.88L5,22.72a1,1,0,0,0,.81,1.13H8.68a1,1,0,0,0,1-.82l.78-4.66A1.67,1.67,0,0,1,12.08,17h.72a7.14,7.14,0,0,0,7.14-7.13h0A4.51,4.51,0,0,0,18,6.17Z" fill="#0085cc" />
      <path d="M7.55,13.29H10A8.12,8.12,0,0,0,18,6.17a4.47,4.47,0,0,0-2.62-.84H9.12A1.46,1.46,0,0,0,7.67,6.55L6.39,14.16A1.21,1.21,0,0,1,7.55,13.29Z" fill="#00186a" />
    </g>
  </svg>
);

export default IconPaypalLogo;
