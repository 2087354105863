const styles = theme => ({
  message: {
    color: theme.color.regentGray,
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '25px',
    textAlign: 'center',
    width: '100%',
  },
  contentWrapper: {
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
    [theme.breakpoints.up(960)]: {
      padding: '0px 40px',
    },
  },
});

export default styles;
