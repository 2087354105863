import { locale } from '@/utils/locale';

export const billingLocale = {
  domainLabel: locale('billing.domainLabel'),
};


export const refundFeedbackLocale = {
  titleLabel: locale('subscriptions.refundFeedback.titleLabel'),
  statusLabel: locale('subscriptions.refundFeedback.statusLabel'),
  statusText: {
    pending: locale('subscriptions.refundFeedback.statusText.pending'),
    accomplished: locale('subscriptions.refundFeedback.statusText.accomplished'),
  },
  valueLabel: locale('subscriptions.refundFeedback.valueLabel'),
  methodLabel: locale('subscriptions.refundFeedback.methodLabel'),
  methodText: method => locale(method),
};
