import React, { useEffect } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import { formatDate } from '@/utils/Formatters/formatDate';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDomain } from '@/utils/Formatters/domain';
import ProductDetailCard from '@/components/Cards/ProductDetailCard';
import { COUNTRY } from '@/config';
import { SERVER_INFO_ID_LIST } from '@/config/products/availability';
import { getSiteBuilderStatus } from '@/redux/actions/siteBuilder';
import { RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS } from '@/redux/actions/actionsTypes';
import { updateOptionsProductDetail } from '@/redux/actions/productDetail';
import styles from './styles';
import { Button } from 'gatorcomponents';
import { useHistory } from 'react-router';
import { backupAnalytics } from '@/analytics';
import { format } from 'date-fns';

const normalizeBillingCycle = billingcycle => billingcycle.toLowerCase().replace(/-/g, '');
const checkServerInfoVisibility = id => SERVER_INFO_ID_LIST[COUNTRY].includes(id);
const getAssignedIps = (ips) => {
  if (ips.length > 0) {
    return ips.join(' / ');
  }
  return '-';
};

const ProductDetail = ({
  t,
  product,
  classes,
  isDedicated,
  disabledActions,
}) => {
  const {
    amount,
    assignedips,
    billingcycle,
    dedicatedip,
    domainstatus,
    firstpaymentamount,
    ipaddress,
    nextduedate,
    ns1,
    ns2,
    paymentmethod,
    productname,
    regdate,
    serverName,
    temporaryLink,
    type,
    username,
    packageid,
    showBillingCycle,
    id,
    productType,
  } = product;
  const domain = useSelector(state => state.productDetail.product && state.productDetail.product.domain);
  const { backupLog } = useSelector(state => state.backup);
  const lastBackup = backupLog[0] || {};
  const isWebsitebuilder = type === 'websitebuilder';
  const isBorderNone = productType === 'addon';
  const isResellerOrShared = type === 'reseller' || type === 'shared';
  const isFreeAccount = billingcycle === 'Free Account';
  const isFreeDueDate = nextduedate === '0000-00-00';
  const isPending = product.domainstatus && product.domainstatus.toLowerCase() === 'pending';

  const isEnabled = true;
  const isRemoveFirstPayment = true;

  const backupEnableProductTypes = ['shared', 'reseller'];
  const showBackup = !isPending && backupEnableProductTypes.includes(product.type);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isWebsitebuilder && domain) {
      dispatch(getSiteBuilderStatus(domain, 'actions')).then((response) => {
        if (response.type === RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS) {
          const isActive = response.data.active;
          const status = isActive ? 'active' : 'inactive';

          const arrActions = [
            {
              uuid: 'active-edit-site-1',
              relid: shortid.generate(),
              linkUrl: `${t('routes.siteBuilderRedirectAccess')}/${domain}${isActive ? '' : `/${id}`}`,
              type: 'websitebuilder-active-edit',
              status,
            },
          ];

          if (status === 'active') {
            arrActions.push({
              uuid: 'removeSite-1',
              relid: shortid.generate(),
              linkUrl: '_self',
              type: 'websitebuilder',
              status,
              removeSite: true,
            });
          }

          dispatch(updateOptionsProductDetail(arrActions));
        }
      });
    }
  }, [domain, dispatch, id, isWebsitebuilder, t]);

  const getServerName = (isDedicated) => {
    if (isDedicated) {
      return formatDomain(domain) || '-';
    }
    return serverName;
  };

  const firstPayment = () => (!isRemoveFirstPayment
    ? (
      <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
        <span className={classes.cardDetailTitle}>{t('invoiceFisrtPayment')}</span>
        <span className={classes.cardDetailValue}>{formatCurrency(firstpaymentamount)}</span>
      </div>
    ) : null);

  const showBackupRedirect = () => {
    history.push(`${t('routes.products')}${t('routes.productdetail')}/${product.id}${t('routes.backup')}`);
    backupAnalytics.backupButtonClick();
  };
  return (
    domainstatus !== undefined && (
    <ProductDetailCard
      product={product}
      status={domainstatus}
      title={productname}
      subtitle={formatDomain(domain)}
      disabledActions={disabledActions}
    >

      <div className={classes.wrapper}>
        {showBackup && (
          <div className={classes.backupInfo}>
            <span className={classes.cardDetailTitle}>
              {t('productDetailsBackup.title')}
            </span>
            {lastBackup.created_at && (
            <p className={classes.backupDescription}>
              {t('productDetailsBackup.description.0')}
              <strong>{product.productname}</strong>
              {t('productDetailsBackup.description.1')}
              <strong>{format(new Date(lastBackup.created_at), 'dd/MM/yyy')}</strong>
            </p>
            )}
            <div>
              <Button
                label={t('productDetailsBackup.buttonLabel')}
                onClick={showBackupRedirect}
                variant="secondary"
              />
            </div>
          </div>
        )}

        <div className={classes.productInfo}>
          {checkServerInfoVisibility(packageid) && (
          <div className={`${classes.cardDetailItemWrapper} ${classes.cardDetailItemWrapper1}`}>
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('username')}</span>
              <span className={classes.cardDetailValue}>{username}</span>
            </div>
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('access_ssh')}</span>
              <span className={classes.cardDetailValue}>{`${t('host')}: ${dedicatedip}`}</span>
              <span className={classes.cardDetailValue}>{`${t('port')}: 22022`}</span>
            </div>
          </div>
          )}

          <div className={classnames(classes.cardDetailItemWrapper, classes.cardDetailItemWrapper2, { [classes.cardDetailNoBorder]: isBorderNone })}>
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('servername')}</span>
              <span className={classes.cardDetailValue}>{getServerName(isDedicated)}</span>
            </div>

            {assignedips[0] !== '' && (
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('server_ips')}</span>
              <span className={classes.cardDetailValue}>{getAssignedIps(assignedips)}</span>
            </div>
            )}

            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>DNS</span>
              <span className={classes.cardDetailValue}>{`${ns1} / ${ns2}`}</span>
            </div>
          </div>

          {isResellerOrShared && (
          <div className={`${classes.cardDetailItemWrapper} ${classes.cardDetailItemWrapper2}`}>
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('hostnameFtp')}</span>
              <span className={classes.cardDetailValue}>{`${ipaddress} / ${domain}`}</span>
            </div>
            <div className={classes.cardDetailItem}>
              <span className={classes.cardDetailTitle}>{t('temporaryLink')}</span>
              <span className={classes.cardDetailValue}>{temporaryLink}</span>
            </div>
          </div>
          )}

          { (!isWebsitebuilder || showBillingCycle) && (
          <div className={`${classes.cardDetailItemWrapper} ${classes.cardDetailItemWrapper3}`}>
            {!isFreeDueDate && (
            <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
              <span className={classes.cardDetailTitle}>{t('nextDueDate')}</span>
              <span className={classes.cardDetailValue}>{formatDate(nextduedate, isEnabled)}</span>
            </div>
            )}
            <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
              <span className={classes.cardDetailTitle}>{t('registerDate')}</span>
              <span className={classes.cardDetailValue}>{formatDate(regdate, isEnabled)}</span>
            </div>

            {firstPayment()}

            <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
              <span className={classes.cardDetailTitle}>{t('invoiceValueReminiscent')}</span>
              <span className={classes.cardDetailValue}>{formatCurrency(amount)}</span>
            </div>

            {!isFreeAccount && (
            <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
              <span className={classes.cardDetailTitle}>{t('domainCyclePayment')}</span>
              <span className={classes.cardDetailValue}>{t(`billingcycle.${normalizeBillingCycle(billingcycle)}`)}</span>
            </div>
            )}
            <div className={`${classes.cardDetailItem} ${classes.cardDetailItemColumn3}`}>
              <span className={classes.cardDetailTitle}>{t('invoicePaymentMethod')}</span>
              <span className={classes.cardDetailValue}>{t(paymentmethod)}</span>
            </div>
          </div>
          )}
        </div>
      </div>
    </ProductDetailCard>
    )
  );
};

export default withI18n()(withStyles(styles)(ProductDetail));
