import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './LinkCustomDomainModal.styles';
import {
  Alert,
  Button, IconCheck, IconCopy, IconDanger, IconInfo, Input, Radio, Select, Skeleton,
  Tag,
} from 'gatorcomponents';
import withErrorBoundary from '@/utils/errorBoundary';
import illustrationDomain from '../../media/illustration-domain.svg';
import { connectionTypes, steps } from './enums';

const initialDomainData = {
  selectedDomain: '',
  selectedDomainID: 0,
  customDomain: '',
  invalidDomain: false,
  connectionType: '',
  subdomain: '',
  type: '',
  nameServer: '',
  hostingId: '',
};

const LinkCustomDomainModal = ({
  locale = {},
  handleModalDisplay = () => null,
  sites = [],
  sitesLoading = false,
  profile = {},
  updateLinkNaBio = () => null,
  verifyDomain = () => null,
  resetVerify = () => null,
  domainToLinkExists = undefined,
  loadingDomainExists = false,
}) => {
  const [domainData, setDomainData] = useState(initialDomainData);
  const [activeStep, setActiveStep] = useState(steps.DOMAIN_SELECT);
  const isCustomDomain = domainData.selectedDomain === 'customDomain';

  const sitesListToSelect = sites.map(site => ({
    label: site.domain,
    value: site.domain_id,
  }));

  const handleInitialStep = useCallback(() => {
    if (profile.domain) {
      setActiveStep(steps.DOMAIN_ALREADY_CONNECTED);
    }
  }, [profile.domain]);

  useEffect(() => {
    handleInitialStep();
  }, [handleInitialStep]);

  const updateDomainData = (key, value, validate) => {
    const newDomainData = { ...domainData };
    const selectedDomainData = sites.find(site => site.domain_id === value);

    if (selectedDomainData) {
      newDomainData.selectedDomain = selectedDomainData.domain;
      newDomainData.type = selectedDomainData.type;
      newDomainData.connectionType = connectionTypes.mainDomain;
      newDomainData.selectedDomainID = value;
      newDomainData.nameServer = selectedDomainData.hosting_nameserver_1;
      newDomainData.hostingId = selectedDomainData.hosting_id;

      setDomainData(newDomainData);
      return;
    }

    if ((validate || domainData.invalidDomain) && key === 'customDomain') {
      const isDomainRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z]{2,63}\.[a-zA-Z]{2,6}(\/.*)?$/g;

      newDomainData.invalidDomain = !isDomainRegex.test(value);
      setDomainData(newDomainData);
      return;
    }

    newDomainData[key] = value;
    setDomainData(newDomainData);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleCloseModal = () => {
    handleModalDisplay();
    setDomainData(initialDomainData);
    setActiveStep(steps.DOMAIN_SELECT);
    resetVerify();
  };

  const updateProfile = (step) => {
    if (step === steps.EXTERNAL_DOMAIN_CONFIGURATION) {
      const domainToLink = isCustomDomain ? domainData.customDomain : domainData.selectedDomain;
      updateLinkNaBio({
        isExternalDomain: true,
        domain: domainToLink,
        nameServer: domainData.nameServer,
        hostingId: domainData.hostingId,
        domainId: domainData.selectedDomainID,
      });
      handleStep(steps.EXTERNAL_DOMAIN_DONE);
    }

    if (step === steps.INTERNAL_DOMAIN) {
      const domainToLink = domainData.subdomain ? `${domainData.subdomain}.${domainData.selectedDomain}` : domainData.selectedDomain;
      updateLinkNaBio({
        isExternalDomain: false,
        domain: domainToLink,
        nameServer: domainData.nameServer,
        hostingId: domainData.hostingId,
        domainId: domainData.selectedDomainID,
      });
      handleStep(steps.INTERNAL_DOMAIN_DONE);
    }

    if (step === steps.REMOVE_DOMAIN) {
      updateLinkNaBio({ domain: null, isRemovingDomain: true });
      handleCloseModal();
    }
  };

  const verifyDomainExists = () => {
    verifyDomain && verifyDomain(isCustomDomain ? domainData.customDomain : domainData.selectedDomain);
  };

  const selectDomainAgain = () => {
    resetVerify && resetVerify();
    setDomainData(initialDomainData);
    handleStep(steps.DOMAIN_SELECT);
  };

  const redirectAfterVerify = useCallback(() => {
    if (domainData.selectedDomain && typeof domainToLinkExists === 'boolean') {
      if (domainToLinkExists) {
        handleStep(domainData.type === 'internal' ? steps.INTERNAL_DOMAIN : steps.EXTERNAL_DOMAIN_CONFIGURATION);
      } else {
        handleStep(steps.DOMAIN_VERIFY_ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainToLinkExists, domainData.selectedDomain]);

  useEffect(() => {
    redirectAfterVerify();
  }, [redirectAfterVerify]);

  if (activeStep === steps.DOMAIN_SELECT) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={650}
        testId="link-custom-domain-modal-domain-select"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent>
          <Styles.ModalIllustration src={illustrationDomain} alt="Custom domain illustration" />
          <Styles.ModalTitle>
            {locale.title}
          </Styles.ModalTitle>
          <Styles.ModalDescription>
            {locale.description}
          </Styles.ModalDescription>

          <Styles.SelectWrapper>
            {sitesLoading ? (
              <Skeleton height="34px" variant="rectangle" />
            ) : (
              <Select
                placeholder={locale.domainPlaceHolder}
                options={sitesListToSelect}
                onClickSelect={({ value }) => updateDomainData('selectedDomain', value)}
                withScroll
                dropdownHeight="sm"
                fixedOption={{ label: locale.otherDomain, value: 'customDomain' }}
              />
            )}
          </Styles.SelectWrapper>

          {isCustomDomain && (
            <Styles.InputWrapper>
              <Input
                label={locale.otherDomainInputLabel}
                onChange={event => updateDomainData('customDomain', event.currentTarget.value)}
                onBlur={({ value }) => updateDomainData('customDomain', value, true)}
                value={domainData.customDomain}
                placeholder=""
                errorMessage={domainData.invalidDomain ? locale.invalidDomain : ''}
                testId="link-custom-domain-modal-external-domain-input"
              />
            </Styles.InputWrapper>
          )}

          <Styles.ModalButtonsWrapper>
            <Button
              label={locale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleCloseModal}
            />
            <Button
              label={locale.verify}
              size="large"
              onClick={verifyDomainExists}
              loading={loadingDomainExists}
              disabled={!domainData.selectedDomain || (isCustomDomain && (!domainData.customDomain || domainData.invalidDomain))}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.INTERNAL_DOMAIN) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={700}
        testId="link-custom-domain-modal-internal-domain"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent>
          <Styles.ModalIllustration src={illustrationDomain} alt="Custom domain illustration" step2 />
          <Styles.ModalTitle step2>
            {locale.howToConnectTitle(domainData && domainData.selectedDomain)}
          </Styles.ModalTitle>

          <Styles.RadioCard first htmlFor="#subdomain" onClick={() => updateDomainData('connectionType', connectionTypes.subdomain)} data-testid="subdomainRadio">
            <Radio
              checked={domainData.connectionType === connectionTypes.subdomain}
              id="subdomain"
              key="subdomain"
            />
            <Styles.RadioTexts>
              <Styles.RadioTitle>
                {locale.subdomain}
              </Styles.RadioTitle>
              <Styles.RadioDescription>
                {locale.subdomainDescription}
              </Styles.RadioDescription>
              <Tag label={locale.recommended} variant="info" />

              {domainData.connectionType === connectionTypes.subdomain && (
                <Styles.InputWrapper>
                  <Input
                    label={locale.insertSubdomain}
                    value={domainData.subdomain}
                    placeholder=""
                    onChange={event => updateDomainData(connectionTypes.subdomain, event.currentTarget.value)}
                    onBlur={({ value }) => updateDomainData(connectionTypes.subdomain, value, true)}
                    maxLength={30}
                    customMask={value => value && value.toLowerCase().replace(/[^a-z0-9]/gi, '')}
                    size="sm"
                    testId="link-custom-domain-modal-subdomain-input"
                  />
                  <Styles.InputSuffix>
                    {`.${domainData && domainData.selectedDomain}`}
                  </Styles.InputSuffix>
                </Styles.InputWrapper>
              )}
            </Styles.RadioTexts>

          </Styles.RadioCard>

          <Styles.RadioCard htmlFor="#mainDomain" onClick={() => updateDomainData('connectionType', connectionTypes.mainDomain)} data-testid="mainDomainRadio">
            <Radio
              checked={domainData.connectionType === connectionTypes.mainDomain}
              id="mainDomain"
              key="mainDomain"
            />
            <Styles.RadioTexts>
              <Styles.RadioTitle>
                {locale.mainDomain(domainData && domainData.selectedDomain)}
              </Styles.RadioTitle>
              <Styles.RadioDescription>
                {locale.mainDomainDescription}
              </Styles.RadioDescription>
            </Styles.RadioTexts>
          </Styles.RadioCard>

          <Styles.ModalButtonsWrapper>
            <Button
              label={locale.back}
              size="large"
              variant="tertiary"
              onClick={selectDomainAgain}
            />
            <Button
              label={locale.continue}
              size="large"
              onClick={() => handleStep(steps.INTERNAL_DOMAIN_VERIFY)}
              disabled={!domainData.connectionType || (domainData.connectionType === connectionTypes.subdomain && !domainData.subdomain)}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.INTERNAL_DOMAIN_VERIFY) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={450}
        testId="link-custom-domain-modal-domain-verify"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent contentNotCentered>
          <Styles.ModalTitle light>
            {locale.verifyChanges}
          </Styles.ModalTitle>
          <Styles.ModalDescription light>
            {locale.verifyChangesDescription}
          </Styles.ModalDescription>

          <Styles.Card>
            <Styles.ModalDescription light marginBottom={24}>
              {locale.afterConfirmation}
            </Styles.ModalDescription>

            <Styles.ModalDescription light marginBottom={1}>
              {locale.newDomainConnected}
            </Styles.ModalDescription>

            <Styles.SubdomainText>
              {`${domainData.subdomain ? `${domainData.subdomain}.${domainData.selectedDomain}` : domainData.selectedDomain}`}
            </Styles.SubdomainText>

            <Styles.InfoWrapper>
              <IconInfo color="primaryMedium" />
              <Styles.Info>
                {locale.afterConfirmationInfo}
              </Styles.Info>
            </Styles.InfoWrapper>
          </Styles.Card>

          <Styles.ModalButtonsWrapper>
            <Button
              label={locale.back}
              size="large"
              variant="tertiary"
              onClick={() => handleStep(steps.INTERNAL_DOMAIN)}
            />
            <Button
              label={locale.confirmChange}
              size="large"
              onClick={() => updateProfile(steps.INTERNAL_DOMAIN)}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.INTERNAL_DOMAIN_DONE) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={450}
        testId="link-custom-domain-modal-internal-done"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent>
          <Styles.IconCheckWrapper>
            <IconCheck color="activePure" size="xl" />
          </Styles.IconCheckWrapper>
          <Styles.SuccessTitle>
            {locale.domainConnectionSuccess}
          </Styles.SuccessTitle>
          <Styles.SuccessDescription>
            {locale.successDescription}
          </Styles.SuccessDescription>
          <Styles.BackToListButtonWrapper>
            <Button
              label={locale.backToList}
              size="large"
              onClick={handleCloseModal}
            />
          </Styles.BackToListButtonWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.EXTERNAL_DOMAIN_CONFIGURATION) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={700}
        testId="link-custom-domain-modal-external-configuration"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent contentNotCentered>
          <Styles.ModalTitle light>
            {locale.configureYourDomain}
          </Styles.ModalTitle>
          <Styles.ModalDescription maxWidth="690" marginBottom="25" marginTop="3" light>
            {locale.configureYourDomainDescription(isCustomDomain ? domainData.customDomain : domainData.selectedDomain)}
          </Styles.ModalDescription>

          <Styles.Card padding="32px">
            <Styles.ModalAlert>
              {locale.configureStep4Attention}
            </Styles.ModalAlert>

            <Styles.Step>
              <Styles.NumberCircle>
                1
              </Styles.NumberCircle>
              <Styles.ModalDescription light marginBottom={28}>
                {locale.configureStep1}
              </Styles.ModalDescription>
            </Styles.Step>

            <Styles.Step>
              <Styles.NumberCircle>
                2
              </Styles.NumberCircle>
              <Styles.ModalDescription light marginBottom={28}>
                {locale.configureStep2}
              </Styles.ModalDescription>
            </Styles.Step>

            <Styles.Step>
              <Styles.NumberCircle>
                3
              </Styles.NumberCircle>
              <Styles.ModalDescription light marginBottom={28}>
                {locale.configureStep3}
              </Styles.ModalDescription>
            </Styles.Step>
            <Styles.Step>
              <Styles.NumberCircle>
                4
              </Styles.NumberCircle>
              <Styles.ModalDescription light marginBottom={1}>
                {locale.configureStep4}
              </Styles.ModalDescription>
            </Styles.Step>
            <Styles.SubStepList>
              <Styles.SubStepWrapper>
                <Styles.SubStep>
                  {locale.configureStep4Name(isCustomDomain ? domainData.customDomain : domainData.selectedDomain)}
                </Styles.SubStep>
                <Styles.IconWrapper>
                  <IconCopy color="primaryMedium" size="sm" />
                </Styles.IconWrapper>
              </Styles.SubStepWrapper>

              <Styles.SubStepWrapper>
                <Styles.SubStep>
                  {locale.configureStep4TTL}
                </Styles.SubStep>
                <Styles.IconWrapper>
                  <IconCopy color="primaryMedium" size="sm" />
                </Styles.IconWrapper>
              </Styles.SubStepWrapper>

              <Styles.SubStepWrapper>
                <Styles.SubStep>
                  {locale.configureStep4Value(`${profile.username}.olink.ai`)}
                </Styles.SubStep>
                <Styles.IconWrapper>
                  <IconCopy color="primaryMedium" size="sm" />
                </Styles.IconWrapper>
              </Styles.SubStepWrapper>
            </Styles.SubStepList>

            <Styles.InfoWrapper>
              <IconInfo color="primaryMedium" size="lg" />
              <Styles.Info extraDark>
                {locale.configurationTip}
              </Styles.Info>
            </Styles.InfoWrapper>

          </Styles.Card>

          <Styles.ModalButtonsWrapper marginTop={12}>
            <Button
              label={locale.configurationDone}
              size="large"
              onClick={() => updateProfile(steps.EXTERNAL_DOMAIN_CONFIGURATION)}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.EXTERNAL_DOMAIN_DONE) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={470}
        testId="link-custom-domain-modal-external-done"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent>
          <Styles.IconCheckWrapper marginTop="50">
            <IconCheck color="activePure" size="xl" />
          </Styles.IconCheckWrapper>
          <Styles.SuccessTitle>
            {locale.domainConnectionSuccess}
          </Styles.SuccessTitle>
          <Styles.SuccessExternalDescription smaller>
            {locale.externalDomainConnected}
          </Styles.SuccessExternalDescription>
          <Alert variant="warning" description={locale.timeToPropagate} />
          <Styles.SuccessExternalButtonWrapper>
            <Button
              label={locale.backToList}
              size="large"
              onClick={handleCloseModal}
            />
          </Styles.SuccessExternalButtonWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.DOMAIN_VERIFY_ERROR) {
    return (
      <Modal
        maxWidth={800}
        maxHeight={390}
        testId="link-custom-domain-modal-verify-error"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent contentNotCentered>
          <Styles.ModalTitle light>
            {locale.verifyingDomain}
          </Styles.ModalTitle>
          <Styles.ModalDescription light>
            {locale.verifyingDomainDescription}
          </Styles.ModalDescription>
          <Styles.ErrorAlert>
            <Styles.ErrorMessage>
              {locale.verificationNotPossible}
            </Styles.ErrorMessage>
            <Styles.ErrorMessageBold>
              {locale.verifyOtherDomain}
            </Styles.ErrorMessageBold>
          </Styles.ErrorAlert>

          <Styles.ModalButtonsWrapper>
            <Button
              label={locale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleCloseModal}
            />
            <Button
              label={locale.chooseOther}
              size="large"
              onClick={selectDomainAgain}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.DOMAIN_ALREADY_CONNECTED) {
    return (
      <Modal
        maxWidth={650}
        maxHeight={440}
        testId="link-custom-domain-modal-already-connected"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent>
          <Styles.IconCheckWrapper marginTop="65">
            <IconCheck color="activePure" size="xl" />
          </Styles.IconCheckWrapper>

          <Styles.AlreadyConnectedTitle marginTop={1}>
            {locale.domainConnected}
          </Styles.AlreadyConnectedTitle>
          <Styles.Card>
            <Styles.AlreadyConnectedDescription light marginBottom={1}>
              {locale.domainConnectedDescription(domainData.selectedDomain)}
            </Styles.AlreadyConnectedDescription>
          </Styles.Card>
          <Styles.ModalButtonsWrapper marginTop={24} marginBottom={16}>
            <Button
              label={locale.removeDomainLink}
              variant="secondary"
              onClick={() => handleStep(steps.REMOVE_DOMAIN)}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
  if (activeStep === steps.REMOVE_DOMAIN) {
    return (
      <Modal
        maxWidth={650}
        maxHeight={440}
        testId="link-custom-domain-modal-already-connected"
        onClose={handleCloseModal}
      >
        <Styles.ModalContent contentNotCentered>
          <Styles.ModalTitle light>
            {locale.removeDomainTitle}
          </Styles.ModalTitle>
          <Styles.DeleteAlert>
            <Styles.IconWrapper>
              <IconDanger size="lg" color="attentionPure" />
            </Styles.IconWrapper>
            <Styles.DeleteAlertText>
              {locale.removeDomainDescription}
            </Styles.DeleteAlertText>
          </Styles.DeleteAlert>
          <Styles.ModalButtonsWrapper marginTop={24}>
            <Button
              label={locale.cancel}
              size="large"
              variant="tertiary"
              onClick={handleCloseModal}
            />
            <Button
              label={locale.removeDomainConfirm}
              size="large"
              onClick={() => updateProfile(steps.REMOVE_DOMAIN)}
            />
          </Styles.ModalButtonsWrapper>
        </Styles.ModalContent>
      </Modal>
    );
  }
};

export default withErrorBoundary(LinkCustomDomainModal, 'LinkCustomDomainModal');
