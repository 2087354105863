import { MODAL_TYPE } from './useMountNotificationModal.enum';
import { parseJSON } from 'date-fns';

export const rules = {
  [MODAL_TYPE.PLANCANCELED]: (filteredPlans) => {
    const mostRecentCanceled = filteredPlans.filteredSitesPlanCanceled.reduce((prev, current) => ((parseJSON(prev.plan_canceled_at) > parseJSON(current.plan_canceled_at)) ? prev : current));
    return {
      shouldRender: true,
      modalType: MODAL_TYPE.PLANCANCELED,
      domainsToShow: mostRecentCanceled.site,
    };
  },
  [MODAL_TYPE.DOMAINCANCELED]: filteredPlans => ({
    shouldRender: true,
    modalType: MODAL_TYPE.DOMAINCANCELED,
    domainsToShow: filteredPlans.filteredSitesDomainCanceled.map(site => site.site),
  }),
  [MODAL_TYPE.ECOMMERCEPLUS]: (filteredPlans) => {
    const mostRecentCreated = filteredPlans.filteredNewEcommercePlus.reduce((prev, current) => ((parseJSON(prev.created_at) > parseJSON(current.created_at)) ? prev : current));
    return {
      shouldRender: true,
      modalType: MODAL_TYPE.ECOMMERCEPLUS,
      domainsToShow: mostRecentCreated.site,
    };
  },
  [MODAL_TYPE.NEWSITE]: (filteredPlans) => {
    const mostRecentCreated = filteredPlans.filteredNewSites.reduce((prev, current) => ((parseJSON(prev.created_at) > parseJSON(current.created_at)) ? prev : current));
    return {
      shouldRender: true,
      modalType: MODAL_TYPE.NEWSITE,
      domainsToShow: mostRecentCreated.site,
    };
  },
  [MODAL_TYPE.SPECIALIST]: () => {
    const modalAlreadyViewd = localStorage.getItem('modalCreateSiteWithSpecialist');

    return ({
      shouldRender: !modalAlreadyViewd,
      modalType: MODAL_TYPE.SPECIALIST,
      domainsToShow: null,
    });
  },
  [null]: () => ({
    shouldRender: false,
    modalType: null,
    domainsToShow: [],
  }),
};
