import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, Card, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import FireTopLeft from '@/media/newsite/icons/FireTopLeft';
import FireTopRight from '@/media/newsite/icons/FireTopRight';
import FireBottomLeft from '@/media/newsite/icons/FireBottomLeft';
import FireBottomRight from '@/media/newsite/icons/FireBottomRight';
import NewSiteFeedbackCard from '@/components/NewSite/NewSiteFeedbackCard/index';
import NewSiteTitle from '@/components/NewSite/NewSiteTitle/index';
import NewSiteCloseLink from '@/components/NewSite/NewSiteCloseLink/index';
import LogoMarca from '@/media/logo/LogoMarca';
import { cleanFilterThemesByCategories, cleanWebsiteBuilderProduct } from '@/redux/actions/onboarding';
import { newSiteFluxDismiss } from '@/redux/actions/summary';
import { TUTORIALS_URLS } from '@/config/urls/tutorialsUrls';
import styles from './styles';


class NewSiteFeedback extends React.Component {
  handleWebsiteBuilderDismiss = () => {
    const { onNewSiteFluxDismiss, onCleanFilterThemesByCategory, onCleanWebsiteBuilderProduct } = this.props;
    onCleanWebsiteBuilderProduct();
    onNewSiteFluxDismiss();
    onCleanFilterThemesByCategory();
  }

  getMessageByCreationTool = () => {
    const { selectedTool, t } = this.props;
    let message = '';
    switch (selectedTool) {
      case 'fast':
        message = t('newsite.feedbackTitleCreator');
        break;
      case 'advanced':
        message = t('newsite.feedbackTitleCreator');
        break;
      case 'wordpress':
        message = t('newsite.feedbackTitleWordpress');
        break;
      case 'cpanel':
        message = t('newsite.feedbackTitleFile');
        break;
      default:
        message = '';
        break;
    }
    return message;
  }

  render() {
    const {
      classes,
      t,
      selectedTool,
    } = this.props;

    const link = selectedTool !== '' && TUTORIALS_URLS[selectedTool];
    const message = this.getMessageByCreationTool();

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.contentWrapper}>
            <NewSiteCloseLink action={this.handleWebsiteBuilderDismiss} />
            <LogoMarca />
            <NewSiteTitle title={t('newsite.feedbackWelcome')} className={classes.title} />

            <NewSiteFeedbackCard
              title={message}
              href={link}
            />
          </div>
          <FireTopLeft className={classes.fireTopLeft} />
          <FireTopRight className={classes.fireTopRight} />
          <FireBottomLeft className={classes.fireBottomLeft} />
          <FireBottomRight className={classes.fireBottomRight} />
        </CardContent>
      </Card>
    );
  }
}


const mapStateToProps = state => ({
  selectedTool: state.onboarding.selectedTool,
});

const mapDispatchToProps = dispatch => ({
  onNewSiteFluxDismiss: () => dispatch(newSiteFluxDismiss()),
  onCleanFilterThemesByCategory: () => dispatch(cleanFilterThemesByCategories()),
  onCleanWebsiteBuilderProduct: () => dispatch(cleanWebsiteBuilderProduct()),
});


export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(NewSiteFeedback)));
