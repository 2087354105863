import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
  align-items: center;
  background-color: ${theme.palette.blue.light};
  border-radius: 4px;
  border-radius: 5px;
  display: flex;
  font-family: ${theme.v2.font.family.primary};
  margin: 0px 0px 8px 0px;
  padding: 16px;

  a {
    color: ${theme.palette.primary.main};
    display: inline-block;
    margin-left: 8px;
    margin-right: 24px;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 700px){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 18px;

    a {
      max-width: 170px;
    }
  }`}
`;

export const SiteItemWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 32px 0;
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};

    a {
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.xs};
      color: ${theme.v2.colors.brand.primaryMedium};
      margin-left: 8px;
      display: inline-block;

      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: 700px){
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      a {
        max-width: 170px;
      }
    }
  `}
`;

export const ItemContainer = styled.div`
  ${({ theme, hasActions }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 70%;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin-bottom: ${hasActions && '16px'};
    };
  `}
`;

export const LinkContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex: 1;

  a {
    color: ${theme.palette.secondary.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    line-height: 19px;
  }

  @media (max-width: 700px){
    align-items: flex-start;
    flex: unset;
    flex-direction: column;
    margin-bottom: 4px;
    margin-top: 4px;
    width: calc(100% - 43px);
  }
  `}
`;

export const SublabelContainer = styled.div`
  ${({ theme }) => css`
  margin-right: 16px;
  span {
    font-family: ${theme.font.family.secondary};
  }`}
  @media (max-width: 700px){
      margin-left: 21px;
      margin-top:18px;
    }
`;

export const SiteContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow:hidden;

    @media (max-width: ${theme.v2.breakpoints.md}){
      overflow: visible;
      text-overflow: unset;
      flex-direction: column;
    }
  `}
`;

export const SiteTitle = styled.div``;

export const SiteTag = styled.div`
  ${({ theme, hasTag }) => css`
    width: fit-content;
    margin: 0 16px;

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin-top: ${hasTag && '12px'};
    };
  `}
`;

export const SiteAlertsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 20px 0 0 16px;

    @media (max-width: ${theme.v2.breakpoints.lg}){
      gap: 16px;
    };
  `};
`;

export const DnsPropagationTagWrapper = styled.div`
  margin-left: -16px;
  margin-right: 24px;

  @media (max-width: 700px){
    margin-bottom: 14px;
    margin-left: -10px;
    margin-top: 12px;
  }
`;

export const PayButtonContainer = styled.div`
${({ theme }) => css`
    a {
      font-family: ${theme.font.family.secondary};
      font-size: ${theme.font.sizes.xxs};
      font-weight: ${theme.font.weights.regular};
      margin: 0;
    }
    @media (max-width: 700px){
      a{
        padding: 0px 25px
      }
    }
`}`;

export const SiteActions = styled.aside`
  & > * {
    margin-left: 16px;
  }

  @media (max-width: 700px){
    order: 3;
  }
`;

export const DropdownButtonWrapper = styled.div`
  margin-left: 17px;

  > div > button {
    padding: 0px;
  }

  > div > div {
    padding: 0px;
  }

  span {
    margin: unset;
  }

  @media (max-width: 700px){
    align-self: flex-start
  }
`;
