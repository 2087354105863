export const EmailsActionTypes = {

  GET_EMAILS_FAILURE: 'GET_EMAILS_FAILURE',
  GET_EMAILS_REQUEST: 'GET_EMAILS_REQUEST',
  GET_EMAILS_SUCCESS: 'GET_EMAILS_SUCCESS',

  EMAIL_ACTIVATE_RENEWAL_FAILURE: 'EMAIL_ACTIVATE_RENEWAL_FAILURE',
  EMAIL_ACTIVATE_RENEWAL_REQUEST: 'EMAIL_ACTIVATE_RENEWAL_REQUEST',
  EMAIL_ACTIVATE_RENEWAL_SUCCESS: 'EMAIL_ACTIVATE_RENEWAL_SUCCESS',

  EMAIL_CREATE_EMAIL_TRIAL_FAILURE: 'EMAIL_CREATE_EMAIL_TRIAL_FAILURE',
  EMAIL_CREATE_EMAIL_TRIAL_REQUEST: 'EMAIL_CREATE_EMAIL_TRIAL_REQUEST',
  EMAIL_CREATE_EMAIL_TRIAL_SUCCESS: 'EMAIL_CREATE_EMAIL_TRIAL_SUCCESS',

  UPDATE_DOMAIN_SETUP_FAILURE: 'UPDATE_DOMAIN_SETUP_FAILURE',
  UPDATE_DOMAIN_SETUP_REQUEST: 'UPDATE_DOMAIN_SETUP_REQUEST',
  UPDATE_DOMAIN_SETUP_SUCCESS: 'UPDATE_DOMAIN_SETUP_SUCCESS',

  GET_PRO_EMAIL_PAYMENT_METHODS: 'GET_PRO_EMAIL_PAYMENT_METHODS',
  GET_PRO_EMAIL_PAYMENT_METHODS_SUCCESS: 'GET_PRO_EMAIL_PAYMENT_METHODS_SUCCESS',
  GET_PRO_EMAIL_PAYMENT_METHODS_ERROR: 'GET_PRO_EMAIL_PAYMENT_METHODS_ERROR',

  REQUEST_TITAN_PREMIUM_BUY_FAST_CHECKOUT: 'REQUEST_TITAN_PREMIUM_BUY_FAST_CHECKOUT',
  RECEIVE_TITAN_PREMIUM_BUY_FAST_CHECKOUT: 'RECEIVE_TITAN_PREMIUM_BUY_FAST_CHECKOUT',
  ABORT_TITAN_PREMIUM_BUY_FAST_CHECKOUT: 'ABORT_TITAN_PREMIUM_BUY_FAST_CHECKOUT',
  ERROR_TITAN_PREMIUM_BUY_FAST_CHECKOUT: 'ERROR_TITAN_PREMIUM_BUY_FAST_CHECKOUT',

  SET_LOADING: 'EMAILS_SET_LOADING',
};
