const steps = {
  VERIFYING_PENDENCIES: 'VERIFYING_PENDENCIES',
  DOMAIN_NOT_REGISTERED: 'DOMAIN_NOT_REGISTERED',
  EXTERNAL_NOT_POINTED: 'EXTERNAL_NOT_POINTED',
  INTERNAL_NOT_POINTED: 'INTERNAL_NOT_POINTED',
  INTERNAL_KEEP_IN_OTHER_HOST: 'INTERNAL_KEEP_IN_OTHER_HOST',
  INTERNAL_KEEP_IN_HG: 'INTERNAL_KEEP_IN_HG',
  INTERNAL_KEEP_IN_HG_LOADING: 'INTERNAL_KEEP_IN_HG_LOADING',
  INTERNAL_KEEP_IN_HG_SUCCESS: 'INTERNAL_KEEP_IN_HG_SUCCESS',
  INTERNAL_KEEP_IN_HG_FAIL: 'INTERNAL_KEEP_IN_HG_FAIL',
  SSL_AUTO_SSL_ACTIVATION: 'SSL_AUTO_SSL_ACTIVATION',
  SSL_AUTO_SSL_ERROR: 'SSL_AUTO_SSL_ERROR',
  SSL_ACTIVATION_SUCCESS: 'SSL_ACTIVATION_SUCCESS',
  SSL_ACTIVATION_ERROR: 'SSL_ACTIVATION_ERROR',
};

export const ModalAutoSslTypes = {
  steps,
};
