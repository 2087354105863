import {
  Button, Checkbox, IconData, IconLoading, Input,
} from 'gatorcomponents';
import * as Styles from './RestorateCancelledPlan.styles';
import React, { useState } from 'react';
import useLocale from '@/hooks/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const RestorateCancelledPlan = ({
  submitForm,
  selectedProduct,
  opening,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { restorateCancelledPlan: restorateCancelledPlanLocale } = ticketsLocale.forms;
  const [mainDomain, setMainDomain] = useState('');
  const [chargeAgreed, setChargeAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.name,
      subject: ticketsLocale.forms.restorateCancelledPlan.subject,
      key: V2Forms.RESTORATE_CANCELLED_PLAN,
      tags: ['aut-bkp-canceled'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.restorateCancelledPlan,
      },
      body: `
        ${restorateCancelledPlanLocale.bodyProduct(selectedProduct)}
        ${restorateCancelledPlanLocale.bodyServer(selectedProduct)}
        ${restorateCancelledPlanLocale.bodyRequest(restorateCancelledPlanLocale.subject)}
        ${restorateCancelledPlanLocale.bodyMainDomain(mainDomain)}
        ${restorateCancelledPlanLocale.chargeAgreed}:${chargeAgreed ? 'sim' : 'Não'}
        ${restorateCancelledPlanLocale.termsAgreed}:${termsAgreed ? 'sim' : 'Não'}
        ${restorateCancelledPlanLocale.bodyProductId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  };

  const checkForm = () => {
    const isOk = mainDomain && chargeAgreed && termsAgreed;

    return !isOk;
  };

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {restorateCancelledPlanLocale.terms}
      </Styles.Info>

      <Styles.Info>
        {restorateCancelledPlanLocale.termsItems}
      </Styles.Info>

      <Styles.Title>
        {ticketsLocale.forms.internalMigration.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Input
          name="main_domain"
          testId="main_domain"
          placeholder={restorateCancelledPlanLocale.mainDomainPlaceholder}
          label={mainDomain ? restorateCancelledPlanLocale.mainDomainPlaceholder : ''}
          value={mainDomain}
          onChange={e => setMainDomain(e.target.value)}
        />
      </Styles.InputWrapper>

      <Styles.CheckboxWrapper>
        <Styles.CheckboxLabel
          htmlFor="#chargeAgreed"
          data-testid="testChargeAgreed"
          onClick={() => setChargeAgreed(!chargeAgreed)}
        >
          <Checkbox checked={chargeAgreed} id="chargeAgreed" />
          <Styles.Text>
            {restorateCancelledPlanLocale.chargeAgreed}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#termsAgreed"
          data-testid="testTermsAgreed"
          onClick={() => setTermsAgreed(!termsAgreed)}
        >
          <Checkbox checked={termsAgreed} id="readTerms" />
          <Styles.Text>
            {restorateCancelledPlanLocale.termsAgreed}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.CheckboxWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default RestorateCancelledPlan;
