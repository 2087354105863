const parseCancellingRenewal = (
  serviceId,
  serviceType,
  {
    reason,
    optionId,
  },
) => ({
  serviceId,
  serviceType,
  reason,
  optionId,
});

const receiveCancelRenewal = (data, subscription) => {
  const {
    success,
  } = data;

  const { type, dueDate, cancelDate } = subscription;

  return {
    modal: {
      show: success,
      ...(!!subscription && {
        date: type === 'domain' ? cancelDate : dueDate,
        subscription,
      }),
    },
  };
};

const receiveSubscriptions = (rawData) => {
  const substatusAdapter = {
    pending_analysis: 'pendingAnalysis',
    pending_payment: 'pendingPayment',
    to_due: 'toDue',
    transferred_away: 'transferredAway',
  };

  const { data: subscriptions } = rawData;

  const handleAddons = addons => addons.map(addon => addon.productname);

  return subscriptions.map((subscription) => {
    const { substatus, discount, order_under_review: orderUnderReview } = subscription;

    return ({
      ...(subscription.addons && {
        addons: handleAddons(subscription.addons),
      }),
      ...(subscription.payment_installments && {
        installments: {
          quantity: subscription.payment_installments.installments,
          value: subscription.payment_installments.installmentValue,
          fees: subscription.payment_installments.fees,
          amount: subscription.payment_installments.totalValue,
        },
      }),
      ...(subscription.refund && {
        refundFeedback: { ...subscription.refund },
      }),
      addonId: subscription.addonid,
      allowCancelRenewal: subscription.allow_cancel_renewal,
      amount: subscription.amount,
      cancelDate: subscription.date_cancel,
      cancelType: subscription.type_cancel,
      category: subscription.category,
      domain: subscription.domain,
      dueDate: subscription.due_date,
      id: subscription.id,
      invoiceId: subscription.invoiceid,
      isSharedHostingAbleToRetention: subscription.isSharedHostingAbleToRetention,
      isExceedsRetriesCC: subscription.exceeds_retries_cc,
      paymentCycle: subscription.payment_cycle,
      paymentMethod: subscription.payment_method,
      paymentMethodLabel: subscription.payment_method_label,
      productName: subscription.productname,
      registerDate: subscription.reg_date,
      status: subscription.status,
      showFilesExclusionText: subscription.show_files_exclusion_text,
      substatus: substatus in substatusAdapter ? substatusAdapter[substatus] : substatus,
      suspendedDate: subscription.date_suspended,
      groupId: subscription.group_id,
      taxrate: subscription.taxrate,
      type: subscription.type,
      automaticBilling: {
        activated: subscription.has_automatic_payment,
        hasValidCreditCard: subscription.valid_credit_card,
        hasValidPaypalAgreement: subscription.valid_agreement_paypal,
      },
      ...(subscription.unpaid_invoice && {
        unpaidInvoice: {
          ccPaymentAttemptFailure: subscription.unpaid_invoice.cc_payment_attempt_failure,
          id: subscription.unpaid_invoice.id,
          totalInvoice: subscription.unpaid_invoice.totalInvoice,
        },
      }),
      trustReactivationAvailable: subscription.trust_reactivation_available,
      trustReactivationOnTheCycle: subscription.trust_reactivation_on_the_cycle,
      overrideSuspension: subscription.override_suspension,
      overrideDate: subscription.override_date,
      modal: subscription.modal,
      discount,
      orderUnderReview,
    });
  });
};

const receiveSubscriptionsQuestion = (rawData) => {
  const question = [...rawData.data][0];

  question.options = question.options.map(option => ({
    ...option,
    selected: true,
  }));

  return question;
};

const receiveRefund = (rawData) => {
  const { data } = rawData;

  return {
    total: data.value,
    methods: data.refund_methods,
    showHint: data.show_hint,
  };
};

const receiveAbleToDiscount = (rawData) => {
  const {
    billingCycle, isCloseToCancelledTime, isFullRefund, isSharedHostingAbleToDiscount,
  } = rawData.data;

  return {
    billingCycle,
    isCloseToCancelledTime,
    isFullRefund,
    isSharedHostingAbleToDiscount,
  };
};

const receiveDiscountValues = (rawData) => {
  const {
    original, recurrent, discount, final, installments,
  } = rawData.data;

  return {
    original,
    recurrent,
    discount,
    final,
    installments,
  };
};

const receiveHostBenefitBilling = (rawData) => {
  const { isGenerateInvoice, invoiceId, draft } = rawData.data;
  return {
    isGenerateInvoice,
    invoiceId,
    draft,
  };
};

const receiveRetentioData = (rawData) => {
  const { data } = rawData;
  return {
    reactivation: data,
  };
};

const assembly = {
  parseCancellingRenewal,
  receiveCancelRenewal,
  receiveRefund,
  receiveSubscriptions,
  receiveSubscriptionsQuestion,
  receiveAbleToDiscount,
  receiveDiscountValues,
  receiveHostBenefitBilling,
  receiveRetentioData,
};

export default assembly;
