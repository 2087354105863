import React from 'react';
import * as Style from './IconAcademy.style';

const IconAcademy = ({ color = '#4480c5' }) => (
  <Style.Wrapper data-testid="icon-academy">
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} width="19.782" height="23.995" viewBox="0 0 19.782 23.995">
      <g id="icon-hg-academy" transform="translate(0 -0.002)">
        <path id="Caminho_32189" data-name="Caminho 32189" d="M-551.019,314.984h0l-11.146-6.421v12.842l11.146-6.422m1.8,0a1.782,1.782,0,0,1-.9,1.559l-11.146,6.421a1.8,1.8,0,0,1-1.8,0,1.8,1.8,0,0,1-.9-1.559V308.563a1.8,1.8,0,0,1,.9-1.559,1.8,1.8,0,0,1,1.8,0l11.146,6.421a1.782,1.782,0,0,1,.9,1.559Z" transform="translate(569.001 -299.207)" />
        <path id="Caminho_32190" data-name="Caminho 32190" d="M-551.019,293.984h0l-11.146-6.421v12.842l11.146-6.422m1.8,0a1.782,1.782,0,0,1-.9,1.559l-11.146,6.422a1.8,1.8,0,0,1-1.8,0,1.8,1.8,0,0,1-.9-1.559V287.563a1.8,1.8,0,0,1,.9-1.559,1.8,1.8,0,0,1,1.8,0l11.146,6.421a1.782,1.782,0,0,1,.9,1.559Z" transform="translate(569.001 -285.761)" />
        <path id="Caminho_32191" data-name="Caminho 32191" d="M-564.121,303.429a1.8,1.8,0,0,1,.661,2.457,1.8,1.8,0,0,1-.661.66l-11.145,6.422a1.8,1.8,0,0,1-2.457-.658,1.8,1.8,0,0,1-.241-.9V298.566a1.8,1.8,0,0,1,1.8-1.8,1.8,1.8,0,0,1,.9.241Z" transform="translate(577.964 -292.808)" />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconAcademy;
