import React, { useEffect, useState } from 'react';
import { Button } from '@/pages/common/v1/Button';
import snapImage from '@/media/courses/AcademyPassTrialPage/snappy-selo.svg';
import logo from '@/media/courses/AcademyPassTrialPage/logo.svg';
import firstImage from '@/media/courses/AcademyPassTrialPage/peoples.svg';
import secondImage from '@/media/courses/AcademyPassTrialPage/certificate-trial.svg';
import thirdImage from '@/media/courses/AcademyPassTrialPage/cut-scene.svg';
import { AcademyPassTrialHandlerLocale } from './AcademyPassTrialHandler.locale';
import { Card } from '../../components/academyPassTrial/Card';
import { AcademyMiddleSection } from '../../components/academyPassTrial/AcademyMiddleSection';
import Line from '@/media/courses/AcademyPassTrialPage/linha-site.svg';
import Selo from '@/media/courses/AcademyPassTrialPage/selo.svg';
import SnapFooter from '@/media/courses/AcademyPassTrialPage/snappyFooter.svg';

import * as Styles from './AcademyPassTrialHandler.styles';
import { useDispatch, useSelector } from 'react-redux';
import { buyAcademyPass } from '@/redux/actions/academyPass';
import { ACADEMY_PASS_TRIAL_PRODUCT_ID } from '@/config/products/academypass';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';

const AcademyPassTrialHandler = () => {
  const { redirectToInvoices, invoiceId, paymentMethod } = useSelector(
    state => state.academyPass.buyFastcheckout,
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (redirectToInvoices && invoiceId) {
      history.push(`${AcademyPassTrialHandlerLocale.routes.billing}${AcademyPassTrialHandlerLocale.routes.unpaid}/${invoiceId}/${paymentMethod}`);
    }
  }, [redirectToInvoices, invoiceId, paymentMethod, history]);

  const handleClick = () => {
    setIsLoading(true);
    dispatch(
      buyAcademyPass({
        planId: ACADEMY_PASS_TRIAL_PRODUCT_ID.br,
        paymentMethod: 'boleto',
        paymentLocation: 'footer',
      }),
    );
  };
  return isLoading ? (
    <Styles.WrapperCircularProgress data-testid="academypass-trial">
      <CircularProgress size={40} />
    </Styles.WrapperCircularProgress>
  ) : (
    <Styles.Wrapper data-testid="academypass-trial">
      <Styles.TitleCard>
        <Styles.PageTitle>
          {AcademyPassTrialHandlerLocale.titleCard}
        </Styles.PageTitle>
      </Styles.TitleCard>
      <Styles.SectionMainWrapper>
        <Styles.MainContent>
          <Styles.MainContentLeft>
            <Styles.ImageLogo src={logo} />
            <Styles.MainText>
              {AcademyPassTrialHandlerLocale.title}
              <Styles.Paragraph>
                {AcademyPassTrialHandlerLocale.descriptionText}
              </Styles.Paragraph>
              <Styles.Paragraph>
                {AcademyPassTrialHandlerLocale.descriptionTextBold}
              </Styles.Paragraph>
            </Styles.MainText>
            <Styles.ButtonWrapper>
              <Button
                onClick={handleClick}
                testId="btn-trial-first"
                label={AcademyPassTrialHandlerLocale.button}
              />
            </Styles.ButtonWrapper>
            <Styles.TextInfo>
              {AcademyPassTrialHandlerLocale.footer}
            </Styles.TextInfo>
          </Styles.MainContentLeft>
          <Styles.MainContentRight>
            <Styles.Image src={snapImage} />
          </Styles.MainContentRight>
        </Styles.MainContent>
      </Styles.SectionMainWrapper>
      <Styles.SectionSlanted />
      <Styles.MiddleWrapper>
        <AcademyMiddleSection
          title={AcademyPassTrialHandlerLocale.middleSectionDust.title}
          paragraph={AcademyPassTrialHandlerLocale.middleSectionDust.paragraph}
          imagePath={firstImage}
          imageRight
          isMarginRight
          handleClick={handleClick}
          academyPassTrialBuyButtonLabel={AcademyPassTrialHandlerLocale.middleSectionDust.button}
        />
        <AcademyMiddleSection
          title={AcademyPassTrialHandlerLocale.middleSectionKnowledge.title}
          paragraph={
            AcademyPassTrialHandlerLocale.middleSectionKnowledge.paragraph
          }
          imagePath={secondImage}
          handleClick={handleClick}
          academyPassTrialBuyButtonLabel={AcademyPassTrialHandlerLocale.middleSectionKnowledge.button}
        />
        <AcademyMiddleSection
          title={AcademyPassTrialHandlerLocale.middleSectionSaveMoney.title}
          paragraph={AcademyPassTrialHandlerLocale.middleSectionSaveMoney.paragraph}
          imagePath={thirdImage}
          footer={AcademyPassTrialHandlerLocale.footer}
          handleClick={handleClick}
          imageRight
          isMarginRight
          academyPassTrialBuyButtonLabel={AcademyPassTrialHandlerLocale.middleSectionSaveMoney.button}
        />
        <Styles.Footer>
          <p>{AcademyPassTrialHandlerLocale.footer}</p>
        </Styles.Footer>
      </Styles.MiddleWrapper>
      <Styles.WrapperVideo>
        <Styles.Section>
          <Styles.PageTitlePartTwo>
            {AcademyPassTrialHandlerLocale.middleSectionVideo.title}
          </Styles.PageTitlePartTwo>
        </Styles.Section>
        <Styles.CardVideo>
          <iframe
            data-testid="has-iframe"
            width="580"
            height="330"
            src={AcademyPassTrialHandlerLocale.middleSectionVideo.linkVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Styles.CardVideo>
        <Styles.ListInfAcademyPass>
          <Styles.InfOne>
            <Styles.Icon>▶</Styles.Icon>
            {AcademyPassTrialHandlerLocale.middleSectionVideo.descInfOne}
          </Styles.InfOne>
          <Styles.InfTwo>
            <Styles.Icon>▶</Styles.Icon>
            {AcademyPassTrialHandlerLocale.middleSectionVideo.descInfTwo}
          </Styles.InfTwo>
          <Styles.InfThree>
            <Styles.Icon>▶</Styles.Icon>
            {AcademyPassTrialHandlerLocale.middleSectionVideo.descInfThree}
          </Styles.InfThree>
        </Styles.ListInfAcademyPass>
        <Styles.ButtonWrapperVideo>
          <Button
            onClick={handleClick}
            data-testid="btn-trial-second"
            label={AcademyPassTrialHandlerLocale.middleSectionVideo.button}
          />
        </Styles.ButtonWrapperVideo>
        <Styles.FooterVideo>
          <Styles.TextFooterVideo>
            {AcademyPassTrialHandlerLocale.footer}
          </Styles.TextFooterVideo>
        </Styles.FooterVideo>
      </Styles.WrapperVideo>
      <Styles.WrapperSecond>
        <Styles.Section>
          <Styles.PageTitlePartTwo>
            {AcademyPassTrialHandlerLocale.middleSectionCards.title}
          </Styles.PageTitlePartTwo>
        </Styles.Section>
        <Styles.Cards>
          <Card
            textCard={AcademyPassTrialHandlerLocale.middleSectionCards.textCardOne}
            autorCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.autorCardOne
            }
            footerCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.footerCardOne
            }
          />
          <Card
            textCard={AcademyPassTrialHandlerLocale.middleSectionCards.textCardTwo}
            autorCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.autorCardTwo
            }
            footerCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.footerCardTwo
            }
          />
          <Card
            textCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.textCardThree
            }
            autorCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.autorCardThree
            }
            footerCard={
              AcademyPassTrialHandlerLocale.middleSectionCards.footerCardThree
            }
          />
        </Styles.Cards>
      </Styles.WrapperSecond>
      <Styles.WrapperThree>
        <Styles.SectionLine>
          <img src={Line} alt="Linha" />
        </Styles.SectionLine>
        <Styles.SectionSelo>
          <img src={Selo} alt="Linha" />
        </Styles.SectionSelo>
        <Styles.SectionFooter>
          <Styles.PageTitleFooter>
            {AcademyPassTrialHandlerLocale.middleSectionFooter.title}
          </Styles.PageTitleFooter>
        </Styles.SectionFooter>
        <Styles.SectionTextFooter>
          <Styles.PageTextFooter>
            {AcademyPassTrialHandlerLocale.middleSectionFooter.textFooter}
          </Styles.PageTextFooter>
        </Styles.SectionTextFooter>
        <Styles.ButtonFooter>
          <Button
            onClick={handleClick}
            data-testid="btn-trial-third"
            label={AcademyPassTrialHandlerLocale.middleSectionFooter.btnFooter}
            variant="secondary"
          />
        </Styles.ButtonFooter>
        <Styles.DivFooter>
          <Styles.TextFooter>
            {AcademyPassTrialHandlerLocale.footer}
          </Styles.TextFooter>
        </Styles.DivFooter>
        <Styles.DivImage>
          <Styles.ImgSnapFooter src={SnapFooter} alt="Snap" />
        </Styles.DivImage>
      </Styles.WrapperThree>
      <Styles.WrapperSnapFooter />
    </Styles.Wrapper>
  );
};
export default AcademyPassTrialHandler;
