import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    font-family: ${theme.font.family.secondary};
    margin: 15px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 7px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    margin-bottom: 24px;
    margin: 0;
    text-align: left;
  `}
`;

export const IconsWrapper = styled.div`
  width: 20px;
`;

export const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;
      gap: 15px;
    }
  `}
`;

export const ConfiguredDomainStatusWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const TextDescription = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-left: 17px;
    span {
      color: ${theme.color.cyanCobaltBlue};
      font-weight: ${theme.font.weights.bold};
    }
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 0px;
      margin-top: 38px;
    }
  `}
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-top: 20px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const TextSelect = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.blue.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    margin-left: 25px;
    text-align: left;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 7px;
    }
  `}
`;

export const Select = styled.div`
  ${({ theme }) => css`
    margin-left: 315px;
    margin-top: -44px;
    position: fixed;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 8px;
      margin-top: 0;
      position: relative;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    margin-top: 39px;
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex-direction: column-reverse;
      margin-top: 40px;
    }
  `}
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: -24px;
    margin-top: 16px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: -20px;
    }
  `}
`;

export const InstructionSquare = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.background.boxes};
    border-radius: 4px;
    border: 1px solid ${theme.v2.colors.feedback.canceledMedium};
    padding: 13px 26px 22px 26px;
    width: 100%;
  `}
`;

export const VerticalInfosContainer = styled.div`
  display: grid;
  row-gap: 8px;
  margin: 8px 12px;
  padding-bottom: 28px;
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    margin-bottom: 10px;
    margin-top: 8px;

    a{
      color: ${theme.v1.color.action.primary};
      font-size: ${theme.font.sizes.sm};
    }
  `}
`;

export const SquareContentWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
        flex-direction: column;
    }
  `}
`;

export const SquareDnsColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 230px;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 30px;
    margin-top: 23px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-top: 0;
      transform: rotate(90deg);
    }
  `}
`;

export const DnsContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.brand.primaryMedium};
    display: flex;
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.v1.font.weights.medium};
    height: 34px;
    margin: 0;
    padding: 0;

    button {
      margin-left: 17px;
    }
  `}
`;

export const TextBoldSquare = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.featured};
    font-weight: ${theme.v1.font.weights.medium};
  `}
`;

export const SquareButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DnsWarning = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 25px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;

      div {
        margin-left: 15px;
      }
    }
  `}
`;

export const TextWarningDns = styled.div`
 ${({ theme }) => css`
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 20px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      div {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  `}
`;

export const UnderlinedText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    text-decoration: underline;
    margin: 0;

  `}
`;

export const ButtonsBottomWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    justify-content: center;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      align-items: center;
      flex-direction: column-reverse;
    }
  `}
`;

export const CheckStatusModalTitle = styled(Title)`
  margin: 0;
`;

export const CheckStatusModalDescription = styled(Text)`
  margin: 0;
  margin-top: 0.5rem;
`;

export const DnsFailureText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxxs};
  `}
`;

export const Actions = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 10px;
    margin-top: 24px;

    @media (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: column;
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: ${theme.font.sizes.sm};
    margin: 0;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;

export const SelectDomain = styled.div`
  ${({ theme }) => css`
    margin-left: 164px;
    margin-top: -50px;
    position: fixed;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin-left: 0;
      margin-top: -34px;
      position: relative;
    }
  `}
`;

export const SelectDomainWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 74px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
  `}
`;
