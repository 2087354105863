import React from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, OutlinedInput, withStyles,
  Checkbox, ListItemText,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from './styles';


const SelectCheckboxField = ({
  t,
  classes,
  className,
  inputLabel,
  labelWidth,
  selectValue,
  filterStatus,
  filterTrans,
  handleChangeSelectCheckbox,
}) => {
  const handleFilterTranslation = (key) => {
    if (filterTrans === 'domain') {
      return t(`statusProductDomain.${key}`);
    }

    if (filterTrans === 'proEmail') {
      return t(`proEmail.filterOptions.${key}`);
    }

    if (filterTrans === 'clean') {
      return key;
    }

    return t(`filter.${key}`);
  };

  const handleRenderValue = () => {
    const translatedSelectedValues = selectValue.map(item => handleFilterTranslation(item));
    return translatedSelectedValues.join(', ');
  };

  return (
    <FormControl className={`${classes.formControl} ${className}`}>
      <InputLabel
        className={classes.inputLabelCustom}
        id="product-input-status-label"
        classes={{ shrink: classes.shrinkReplace }}
      >
        {inputLabel}
      </InputLabel>
      <Select
        id="select-input-status"
        multiple
        value={selectValue}
        onChange={handleChangeSelectCheckbox}
        renderValue={handleRenderValue}
        input={(
          <OutlinedInput
            classes={{
              root: classes.selectCustom,
              input: classes.selectCustomInput,
              notchedOutline: classes.selectCustomOutline,
              focused: classes.selectCustomFocused,
            }}
            labelWidth={labelWidth}
            name="site"
            id="outline-input-status"
          />
        )}
      >
        {filterStatus.map(state => (
          <MenuItem key={shortid.generate()} value={state} classes={{ root: classes.menuItem }}>
            <Checkbox color="primary" checked={selectValue.indexOf(state) > -1} />
            <ListItemText primary={handleFilterTranslation(state)} classes={{ root: classes.menuItemText }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectCheckboxField.propTypes = {
  filterStatus: PropTypes.array.isRequired,
  inputLabel: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  selectValue: PropTypes.array.isRequired,
};

export default withStyles(styles)(withI18n()(SelectCheckboxField));
