import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

import styles from './styles';


const PageTitle = ({
  children,
  classes,
  ...rest
}) => (
  <Typography
    variant="caption"
    className={classes.root}
    {...rest}
  >
    {children}
  </Typography>
);

PageTitle.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default withStyles(styles)(PageTitle);
