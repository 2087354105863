import React from 'react';

export default {
  tabInvoicesLabel: 'Faturas',
  tabSubscriptionsLabel: 'Assinaturas',
  filterLabel: 'Filtrar por',
  filterSubscriptionsLabel: 'Todas as Assinaturas',
  filterStatusLabel: 'Todos os Status',
  domainLabel: 'Domínio',
  genericError: 'Não foi possível executar a ação, tente mais tarde.',
  modal: {
    title: 'Renovação cancelada.',
    firstLinecontent: 'Você ainda poderá utilizar a sua assinatura até',
    secondLinecontent: 'Após esta data, ela será cancelada.',
    activateRenew: 'ATIVAR RENOVAÇÃO',
    close: 'FECHAR',
  },
  successModal: {
    title: 'Compra realizada com sucesso',
  },
  failModal: {
    title: 'Problemas com o pagamento',
    message: 'Houve um problema ao processar seu pagamento. Volte para tentar novamente ou escolher outro método.',
  },
  splitPaymentOfferModal: {
    conerTag: 'Novo',
    title: 'Mais autonomia para você!',
    description: 'Agora você pode selecionar os itens da fatura e pagar de forma separada.',
    linkLabel: 'Veja como',
    url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/21271243902491',
  },
  splitPaymentModal: {
    title: 'Pagamento da fatura',
    alertDescription: (
      <>
        A fatura com os
        <span> itens não selecionados continuará pendente de pagamento </span>
        e poderá ser encontrada na tela de Faturas.
      </>
    ),
    description: 'Quais produtos você deseja pagar agora?',
    selectPaymentLabel: 'Escolher como pagar',
    cancelLabel: 'Cancelar',

  },
  invoices: {
    clearLabel: 'Limpar filtros',
    invoiceListTitle: 'Listagem de Faturas',
    filterOptions: {
      financialPending: 'Com pendência financeira',
      all: 'Todos',
      paid: 'Pagas',
      cancelled: 'Canceladas',
    },
    searchByProductInvoice: 'Busque por produto ou fatura',
    renewDomain: 'Renovação domínio',
    registerDomain: 'Registro domínio',
    alterPlan: 'Alteração no plano:',
    alterCycle: 'Alteração no ciclo:',
    allStatus: 'Todos os Status',
    status: 'Status',
    unpaid: 'À Vencer',
    unpaidExpired: 'Vencida',
    analysing: 'Em Análise',
    paid: 'Paga',
    cancelled: 'Cancelada',
    paymentPending: 'Confirmando Pagamento',
    awaitingPayment: 'Aguardando Pagamento',
    refunded: 'Reembolsada',
    sentBilling: 'Enviado para Cobrança',
    pay: 'Pagar Fatura',
    goToPayment: 'Ir para o pagamento',
    showProof: 'Ver comprovante',
    infotReceiptGeneration: 'A Nota Fiscal será emitida 30 dias após a confirmação do pagamento.',
    showReceiptAction: 'Ver nota fiscal',
    totalInvoice: 'Total da Fatura',
    unpaidInvoicesTitle: 'Pendentes',
    paidInvoicesTitle: 'Pagas',
    cancelledInvoicesTitle: 'Canceladas',
    download: 'Baixar comprovante de pagamento',
    knowMore: 'Saiba mais',
    knowMoreURL: 'https://suporte.hostgator.com.br/hc/pt-br/articles/21271243902491',
    headerInvoicesPage: {
      text: {
        pendingInvoices: pendingInvoices => (
          <>
            Você possui
            <span>{` ${pendingInvoices} `}</span>
            <span>{`${pendingInvoices > 1 ? 'faturas' : 'fatura'} aguardando pagamento.`}</span>
          </>
        ),
        splitAlert: (
          <>
            <span>Novidade! </span>
            Agora você também pode selecionar os itens da fatura e pagar de forma separada. Clique no botão “Ir para o pagamento”.
          </>
        ),
        withoutPendingInvoices: () => (
          <>
            Você
            <span> não tem faturas pendentes </span>
            no momento.
            <br />
            Sabia que agora
            <span> você pode comprar e renovar com Pix </span>
            {'? '}
          </>
        ),
      },
      link: 'Veja como',
    },
    holdDomainModal: {
      title: 'Reativar domínio congelado ou expirado',
      description1: 'Todo domínio registrado possui um prazo de validade, geralmente este prazo é de 1 ano, podendo ser maior caso o registro tenha sido realizado para mais anos.',
      description2: domain => (
        <>
          O seu domínio
          {' '}
          <strong>{domain}</strong>
          {' '}
          que está registrado com a gente venceu, por isso assumiu o status expirado ou congelado e o site ficou offline. Para colocá-lo no ar novamente, é preciso fazer a renovação do pagamento.
        </>
      ),
      alert: 'Para reativar o domínio mais rapidamente, recomendamos o pagamento com o Cartão de Crédito ou Pix.',
      cta: 'Pagar fatura e reativar domínio',
    },
  },
  manageSubscription: {
    activeSubscriptionRenewalButtonLabel: 'Ativar renovação de assinatura',
    cancelSubscriptionButtonLabel: 'Cancelar assinatura',
    cancelSubscriptionImmediatelyButtonLabel: 'Cancelar imediatamente',
    installmentsAmountLabel: 'Valor total',
    installmentsPaymentFormLabel: 'Forma de pagamento',
    installmentsPaymentFormTooltipText: 'Os métodos de Cartão de Crédito e PayPal tem o processamento do pagamento de forma imediata. Boleto pode levar até 72h para compensar.',
    installmentsPaymentFormTooltipWithPixText: 'Os métodos de Cartão de Crédito, Pix e PayPal tem o processamento do pagamento de forma imediata. Boleto pode levar até 72h para compensar.',
    nextRenovationDateLabel: 'Próxima renovação',
    paymentCycleLabel: 'Ciclo da assinatura',
    paymentMethodLabel: 'Método de pagamento',
    paymentMethodTooltipText: 'As opções de parcelamento são definidas são definidas de acordo com o valor do produto e seu ciclo de pagamento.',
    planButtonLabel: 'Alterar plano e/ou ciclo de pagamento',
    reactivationButtonLabel: 'Reativar minha assinatura',
    alreadyReactivatedTooltip: 'Você já realizou uma reativação desta fatura e não pode realizar novamente.',
    reactivationTooltipLabel: 'Ao realizar a reativação da assinatura, o serviço deve ficar ativo por 72 horas. Caso a gente não reconheça que houve um pagamento nesse período, a assinatura será suspensa novamente.',
    trustPeriodLabel: 'Em período de confiança',
    trustPeriodDescriptionLabel: date => (
      <>
        Assinatura reativada com sucesso, aguardando confirmação de pagamento.
        <br />
        Período de confiança ativo até
        {' '}
        {date}
        .
      </>
    ),
    productNameLabel: 'Produto',
    invoiceLabel: 'Fatura',
    registerDateLabel: 'Data de contratação',
    renewalDataTitleLabel: 'Dados de Renovação',
    renovationAmountLabel: 'Valor de renovação',
    renovationInfoLabel: 'Por ter aceito nossa oferta de retenção com desconto, sua fatura já esta disponível para pagamento.',
    renovationDraftInvoiceInfoLabel: 'Por ter aceito nossa oferta, sua próxima fatura de renovação já esta com desconto aplicado.',
    resumeTitleLabel: 'Resumo da Assinatura',
    statusLabel: 'Status',
    dueDateLabel: 'Data de vencimento',
    processBoleto: 'Neste momento, não foi possível emitir o seu boleto. Por favor, tente novamente mais tarde ou entre em contato com nosso suporte.',
    switchPaymentMethodButtonLabel: 'Alterar pagamento',
    payInvoice: 'Pagar Fatura',
    dueDatePaypalLabel: 'Não se preocupe, sua assinatura será renovada automaticamente conforme método de pagamento acima.',
    dueDateCreditCardLabel: 'Não se preocupe, sua assinatura será renovada automaticamente conforme método de pagamento acima.',
    noRenewAlertLabel: {
      0: 'Conforme solicitado',
      1: ' esta assinatura não será mais renovada e será cancelada automaticamente em ',
      2: ' Após esta data, todos os dados e arquivos serão excluídos permanentemente. ',
      3: 'Veja como fazer o backup dos seus arquivos',
    },
    nextDueAlertLabel: {
      0: 'A assinatura vencerá em breve. ',
      1: 'Pague agora mesmo',
      2: ' via boleto ou escolha um método de compensação imediata, como Cartão de Crédito ou PayPal.',
    },
    newNextDueAlertLabel: {
      0: 'A assinatura vencerá em breve. ',
      1: 'Recomendamos que você utilize um método de pagamento com',
      2: ' compensação imediata,',
      3: ' como ',
      4: 'Pix, Cartão de Crédito ou PayPal.',
    },
    dueDateAlertLabel: {
      0: 'Sua assinatura venceu e seu site sairá do ar e e-mails vinculados à ele deixarão de funcionar em ',
      1: 'Sua assinatura venceu e seu domínio e e-mails vinculados à ele deixarão de funcionar em ',
      2: 'Sua assinatura venceu e será suspensa em ',
      3: ' dias. ',
      4: ' Pague agora mesmo',
      5: ' via boleto ou escolha um método de compensação imediata, como Cartão de Crédito ou PayPal.',
    },
    newDueDateAlertLabel: {
      0: 'Sua assinatura venceu e seu site sairá do ar e e-mails vinculados à ele deixarão de funcionar em ',
      1: 'Sua assinatura venceu e seu domínio e e-mails vinculados à ele deixarão de funcionar em ',
      2: 'Sua assinatura venceu e será suspensa em ',
      3: ' dias. ',
      4: 'Recomendamos que você utilize um método de pagamento com',
      5: ' compensação imediata,',
      6: ' como ',
      7: 'Pix, Cartão de Crédito ou PayPal.',
    },
    suspendedAlertLabel: {
      0: 'Sua assinatura foi suspensa e seu site saiu do ar e e-mails vinculados à ele pararam de funcionar.',
      1: 'Sua assinatura expirou e seu domínio e e-mails vinculados à ele pararam de funcionar.',
      2: 'Sua assinatura foi suspensa.',
      3: ' Pague agora mesmo',
      4: ' via boleto ou escolha um método de compensação imediata, como Cartão de Crédito ou PayPal, antes que ela seja cancelada.',
    },
    newSuspendedAlertLabel: {
      0: 'Sua assinatura foi suspensa e seu site saiu do ar e e-mails vinculados à ele pararam de funcionar. ',
      1: 'Recomendamos que você utilize um método de pagamento com',
      2: ' compensação imediata,',
      3: ' como ',
      4: 'Pix, Cartão de Crédito ou PayPal.',
    },
    paymentFailAlertLabel: {
      0: 'Não conseguimos fazer a cobrança pois houve algum problema com o cartão cadastrado.',
      1: ' Pague agora mesmo',
      2: ' via boleto ou PayPal, ou altere o cartão de crédito. Lembrando que sua assinatura venceu e seu site sairá do ar e e-mails vinculados à ele deixarão de funcionar em ',
      3: ' dias.',
    },
    paymentFailByAttemptsLimitLabel: {
      0: 'Não conseguimos fazer a cobrança pois houve algum problema com o cartão cadastrado.',
      1: ' Pague agora mesmo',
      2: ' via PIX ou PayPal, ou altere o cartão de crédito.',
    },
    newPaymentFailAlertLabel: {
      0: 'Não conseguimos fazer a cobrança pois houve algum problema com o cartão cadastrado. ',
      1: 'Recomendamos que você utilize um método de pagamento com',
      2: ' compensação imediata,',
      3: ' como ',
      4: 'Pix, Cartão de Crédito ou PayPal.',
    },
    pendingPaymentAlertLabel: {
      0: 'Estamos aguardando a confirmação do pagamento. Boletos podem levar até 72h para compensar.',
      1: 'Se ainda não o fez, ',
      2: 'pague agora mesmo',
      3: ' via boleto ou escolha um método de compensação imediata, como Cartão de Crédito ou PayPal.',
    },
    newPendingPaymentAlertLabel: {
      0: 'Estamos aguardando a confirmação do pagamento. A assinatura vencerá em breve. ',
      1: 'Se ainda não o fez. ',
      2: 'Recomendamos que você utilize um método de pagamento com',
      3: ' compensação imediata,',
      4: ' como ',
      5: 'Pix, Cartão de Crédito ou PayPal.',
    },
    orderUnderReview: {
      buy: {
        title: 'Compra realizada',
        content: {
          creditcard: () => 'Recebemos o seu pedido corretamente.',
          other: ({ paymentMethod }) => `Recebemos a sua compra corretamente no método de pagamento ${paymentMethod}.`,
        },
        lightContent: ({ date, time }) => {
          if (time) {
            return `${date} às ${time}h`;
          }

          return `${date}`;
        },
      },
      paid: {
        ongoing: {
          title: 'Pagamento em compensação',
          content: 'Estamos aguardando a compensação do pagamento, que pode levar até 72 horas úteis.',
          tooltip: 'O prazo de compensação de um boleto é de até 72 horas.',
        },
        finished: {
          tooltip: {
            preAuth: 'Pré-autorização do pagamento no cartão de crédito realizada no valor da compra.',
          },
          title: {
            creditcard: 'Pagamento pré-autorizado',
            other: 'Pagamento compensado',
          },
          content: {
            creditcard: 'Foi realizada uma pré autorização do pagamento no cartão de crédito no valor da compra enquanto o pedido é analisado.',
            other: 'Estamos aguardando a compensação do pagamento, que pode levar até 72 horas úteis.',
          },
          lightContent: ({ date, time }) => (
            `Pagamento compensado em ${date} às ${time}`
          ),
        },
      },
      analysing: {
        tooltip: 'O prazo de análise do seu pedido é de 24 a 48 horas úteis.',
        pending: {
          lightTitle: 'Pedido em análise',
          lightContent: 'O prazo de análise do seu pedido é de 24 a 48 horas úteis.',
        },
        cancelled: {
          title: 'Pedido em análise',
          content: 'O prazo de análise do seu pedido é de 24 a 48 horas úteis.',
          lightContent: ({ date, time }) => (
            `Análise concluída em ${date} às ${time}h`
          ),
        },
      },
      finalStatus: {
        pending: {
          lightTitle: 'Análise concluída',
          lightContent: {
            creditcard: ({ email }) => `Quando o pedido for aprovado o seu produto será ativado e você receberá um email em ${email}`,
            other: ({ email }) => `Quando o pedido for aprovado, você receberá um e-mail em ${email}`,
          },
        },
        cancelled: {
          title: 'Pedido cancelado',
          content: {
            creditcard: ({ email }) => (
              <>
                {'Você receberá um e-mail no '}
                <strong>{email}</strong>
                {'  com os detalhes. A autorização de pagamento foi cancelada e o valor será  '}
                <strong>removido</strong>
                {' da fatura do cartão até a data de fechamento.'}
              </>
            ),
            other: ({ paymentMethod }) => (paymentMethod === 'Boleto Bancário' ? (
              <>
                {'Você receberá em seu e-mail de cadastro as informações. Para reembolso por '}
                <strong>boleto bancário</strong>
                {', entre em contato com o suporte enviando um e-mail para '}
                <strong>analise@hostgator.com.br.</strong>
              </>
            )
              : (
                <>
                  {'Você receberá em seu e-mail de cadastro as informações. Seu reembolso será feito '}
                  <strong>automaticamente</strong>
                  {' pela mesma forma do pagamento.'}
                </>
              )),
          },
          lightContent: ({ date, time }) => `${date} às ${time}h`,
        },
      },
    },
    readMoreStepper: 'Ler mais',
  },
  manageSubscriptionRetentionDiscountOffer: {
    paymentCycle: {
      Monthly: 'Mensal',
      Quarterly: 'Trimestral',
      SemiAnnually: 'Semestral',
      Annually: 'Anual',
      Biennially: 'Bienal',
      Triennially: 'Trienal',
      FreeAccount: 'Trial',
    },
    retentionTitles: {
      0: (
        <>
          <p>
            <span>
              Não desista ainda!
            </span>
          </p>
        </>
      ),
      1: 'Sabemos o quanto é valioso ter uma presença online e estamos aqui para te oferecer um incentivo especial:',
      2: (
        <>
          <p>
            <span>Não vamos deixar você ir tão facilmente!</span>
            {' '}
            Seu sucesso é importante demais para nós. Decida ficar e...
          </p>
        </>
      ),
    },
    discountDescription: (discount, version) => (
      <>
        {version === 0 ? 'Permaneça com a gente e' : ''}
        {' '}
        <strong>
          {version === 0 ? 'ganhe' : 'Ganhe'}
          {' '}
          {discount}
          % de desconto
          {' '}
        </strong>
        na próxima renovação!
      </>
    ),
    benefitDescription: (amount, plan) => (
      <>
        <p>
          Você irá pagar somente
          {' '}
          <strong>
            {amount}
          </strong>
          {' '}
          no seu
          {' '}
          <strong>
            {plan}
          </strong>
        </p>
      </>
    ),
    benefitAcceptOfferButton: 'Aceitar oferta',
    continueCancelButton: 'Continuar cancelando',
    benefitInfo: '*Desconto exclusivo somente para a próxima renovação.',
  },
  manageSubscriptionRetention: {
    retentionTitle: 'Tem certeza que deseja cancelar seu plano?',
    retentionTextDescription: (
      <>
        <p>
          <strong>Não perca seus e-mails.</strong>
          {' '}
          A sua hospedagem dá direito a
        </p>
        <p>
          <strong>contas gratuitas e ilimitadas</strong>
          {' '}
          nas plataformas cPanel ou Titan
        </p>
      </>
    ),
    retentionBenefits: {
      title: (
        <>
          <span>
            Conheça alguns
            {' '}
            <strong>benefícios do e-mail profissional Titan:</strong>
          </span>
        </>
      ),
      benefits: [
        (
          <span>
            Contas de e-mail
            {' '}
            <strong>ilimitadas</strong>
            {' '}
            (@seudominio)
          </span>
        ),
        (
          <span>
            <strong>1GB</strong>
            {' '}
            de armazenamento por conta
          </span>
        ),
        (
          <span>
            Suporte a Outlook, Gmail e outros aplicativos
          </span>
        ),
        (
          <span>
            Poderoso filtro anti-spam
          </span>
        ),
        (
          <span>
            Garantia de envio e recebimento de e-mails
          </span>
        ),
      ],
    },
    retentionMailButton: 'ACESSAR MEUS E-MAILS',
    retentionAttention: {
      title: 'Atenção:',
      text: (
        <span>
          Em caso de cancelamento do plano
          {' '}
          <strong>você não terá mais acesso aos seus e-mails gratuitos.</strong>
        </span>
      ),
    },
    retentionCancelButton: 'CANCELAR ASSINATURA',
    retentionCancelRenewButton: 'CANCELAR RENOVAÇÃO',
  },
  retentionOffer: {
    title: [
      'Considere isto antes de cancelar!',
      'Considere alterar o ciclo antes de cancelar!',
    ],
    greenDescription: [
      'Desative a renovação automática para não ser cobrado automaticamente ao final do ciclo.',
      'Mude para um ciclo menor que o atual para pagar menos.',
    ],
    description: [
      'Assim você mantém seus serviços sem preocupação com cobranças automáticas.',
      'Assim você continua com seu Plano e mantém seus serviços (site e e-mails) funcionando.',
    ],
    buttonLabel: [
      'Desativar renovação automática',
      'Alterar ciclo da hospedagem',
    ],
    note: [
      '*Na renovação, a fatura será gerada via Pix, mas você pode alterar o método a qualquer momento no gerenciamento da sua assinatura.',
      '*Você será redirecionado para a tela de Alteração de Ciclo.',
    ],
    continueCancelButton: 'Continuar cancelando',
    toastSucces: 'A renovação automática foi desativada com sucesso!',
  },
  saversDiscountBanner: {
    title: (
      <span>
        Oferta especial para você não desistir dos seus projetos:
        {' '}
        <strong>35% de desconto nesta fatura</strong>
      </span>),
  },
  offerDiscountBanner: {
    title: {
      0: 'Renove agora e ',
      1: 'economize 35%',
    },
    descriptionsToSitePage: {
      0: 'Você têm assinaturas ',
      1: 'prestes a expirar ',
      2: 'A assinatura do seu ',
      3: ' está prestes a expirar',
      4: ' e temos uma oferta imperdível para você:',
      5: 'Renove agora e ganhe 35% de desconto ao ativar a renovação automática. Não perca esta oportunidade!',
    },
    descriptionToSubscripionsPage: 'Oportunidade por tempo limitado! Economize 35% na renovação automática do próximo ciclo.',
    buttonLabel: 'Oba! Quero desconto!',
  },
  offerSaversBanner: {
    title: {
      0: '35% de desconto na sua fatura',
      1: ' com vencimento em ',
    },
    description: 'Esta é a sua última oportunidade antes dos seus serviços serem cancelados.',
    buttonLabel: 'Pagar fatura com desconto',
  },
  offerAcceptSuccesModal: {
    title: 'Oferta aceita com sucesso!',
    moreThanMonthDescriptionVersion: 'Sua fatura de renovação já está disponível para pagamento e o desconto já foi aplicado.',
    lessThanMonthDescriptionVersion: (
      <p>
        O desconto será aplicado na próxima renovação do seu Plano de Hospedagem.
        {' '}
        <strong>
          Você gostaria de renovar agora ou deixar para depois (ao final do ciclo atual)?
        </strong>
      </p>),
    beforeRenewal: 'Renovar depois',
    paymentNow: 'Pagar agora mesmo',
    renewalNow: 'Renovar agora mesmo',
  },
  manageSubscriptionBenefit: {
    benefitTitle: 'Considere esse benefício antes de cancelar!',
    benefitDescriptionMonth: (
      <>
        <p>
          Ao renovar sua assinatura,
          {' '}
        </p>
        <p>
          garanta um
          {' '}
          <strong>desconto exclusivo</strong>
        </p>
      </>
    ),
    benefitDescriptionYear: (
      <>
        <p>Renove agora sua assinatura e garanta um</p>
        <strong>desconto exclusivo</strong>
      </>
    ),
    benefitCurrentPriceText: (cycle) => {
      const cycleText = currentCycle => ({
        Monthly: 'mensal',
        Quarterly: 'trimestral',
        'Semi-Annually': 'semestral',
        Annually: 'anual',
        Biennially: 'bianual',
        Triennially: 'trianual',
      }[currentCycle] || '');

      return (
        <p>
          Atualmente você paga no
          {' '}
          <strong>
            ciclo
            {' '}
            {cycleText(cycle)}
            :
          </strong>
        </p>
      );
    },
    benefitCurrentPriceCycleMonth: currentPriceMonth => `${currentPriceMonth}/mês`,
    benefitCurrentPriceCycleYear: currentPriceYear => (
      <>
        <strong>
          {`${currentPriceYear} `}
        </strong>
        por ano
      </>
    ),
    benefitCurrentPriceTotal: currentPriceTotal => (
      <>
        <strong>
          {currentPriceTotal}
        </strong>
        no total
      </>
    ),
    benefitFuturePriceTextMonth: (
      <p>
        Mantenha sua assinatura por um ano
        {' '}
        <strong>renovando agora:</strong>
      </p>
    ),
    benefitFuturePriceTextYear: (
      <p>
        Renove a assinatura do próximo ciclo
        {' '}
        <strong>com desconto:</strong>
      </p>
    ),
    benefitFuturePriceCycleMonth: (quantityInstallment, futurePriceMonth) => (
      <p>
        Em até
        {' '}
        <strong>
          {quantityInstallment}
          x de
          {' '}
          {futurePriceMonth}
          /mês
        </strong>
      </p>
    ),
    benefitFuturePriceCycleYear: futurePriceOnePayment => (
      <p>
        {' '}
        <strong>
          {futurePriceOnePayment}
        </strong>
        {' à vista'}
      </p>
    ),
    benefitFutureDiscount: futureDiscount => `${futureDiscount}% DE DESCONTO`,
    benefitInstallments: quantityInstallment => `PARCELE EM ATÉ ${quantityInstallment}X`,
    benefitAcceptOfferButton: 'ACEITAR OFERTA',
    benefitFinalTextMonth: 'Após o pagamento, o ciclo do seu plano será anual. Caso não efetue o pagamento da fatura, o plano será cancelado automaticamente.',
    benefitFinalTextCycle: 'O desconto é válido apenas para o próximo ciclo e o pagamento da fatura de renovação com desconto deve ser realizado até o final do ciclo atual.',
    benefitCancelRenewButton: 'CANCELAR RENOVAÇÃO',
    benefitCancelSignatureButton: 'CANCELAR ASSINATURA',
    invoiceModal: {
      title: 'Desconto já aplicado',
      description: [
        'O desconto já foi aplicado na sua fatura. Clique no botão abaixo para acessá-la e, em seguida, poder realizar o pagamento.',
        'Caso já tenha efetuado o pagamento da fatura, pode demorar até 72 horas para compensar.',
      ],
      button: 'Ver fatura',
    },
  },
  manageSubscriptionCancelModal: {
    cancelRenewalDescriptionLabel: 'Você já pagou e pode continuar usando a assinatura até o final do ciclo (período contratado) e não pagará mais nada por isso. Ao final do ciclo, sua assinatura será cancelada automaticamente.',
    cancelRenewalLabel: 'Não quero mais renovar a assinatura',
    cancelImmediateDescriptionLabel: 'Ao optar por cancelar imediatamente, todos os dados e arquivos serão apagados e excluídos imediatamente.',
    cancelImmediateLabel: 'Quero cancelar imediatamente',
    continueButtonLabel: 'Continuar',
    descriptionLabel: 'Selecione abaixo a forma que deseja:',
    quitButtonLabel: 'Desistir da ação',
    titleLabel: 'Cancelar assinatura',
  },
  manageSubscriptionActiveRenewalModal: {
    descriptionAlertInfoLabel: 'A cobrança de pagamento será efetuada no final do ciclo (data acima), evitando que seu produto seja cancelado e seus dados e arquivos sejam excluídos permanentemente.',
    descriptionPixBoletoAlertInfoLabel: 'Faça o pagamento até a data de vencimento da fatura e evite que seu produto seja cancelado e seus dados e arquivos sejam excluídos permanentemente.',
    confirmButtonLabel: 'Confirmar ativação',
    descriptionLabel: 'Selecione abaixo a forma que deseja:',
    titleLabel: 'Ativar renovação de assinatura',
    descriptonConfirmationAlertlabel: 'Após confirmar a ativação, você poderá efetuar a alteração e pagamento da sua assinatura.',
    cancelRenewalDescriptionLabel: 'Você já pagou e pode continuar usando a assinatura até o final do ciclo (período contratado) e não pagará mais nada por isso. Ao final do ciclo, sua assinatura será cancelada automaticamente.',
    cancelRenewalLabel: 'Não quero mais renovar a assinatura',
    cancelImmediateDescriptionLabel: 'Ao optar por cancelar imediatamente, todos os dados e arquivos serão apagados e excluídos imediatamente.',
    cancelImmediateLabel: 'Quero cancelar imediatamente',
    continueButtonLabel: 'Continuar',
    quitButtonLabel: 'Desistir da ação',
    from: 'de ',
    to: 'por ',
    discount: '% de desconto',
    note: '*Desconto exclusivo para a renovação deste ciclo',
  },
  manageSubscriptionPaymentInvoiceModal: {
    titleLabel: 'Pagar fatura',
    discount: 'Desconto',
    duedate: 'Vencimento',
    successInvoiceGenerate: 'Fatura alterada com sucesso.',
    selectPaymentMethodLabel: 'Em qual método você gostaria de realizar este pagamento?',
    generateTicketLabel: 'Gerar boleto',
    infoPaymentByTicketLabel: 'Pagamentos via boleto são identificados em até 72h pelo nosso sistema',
    infoPaymentByPicPayLabel: 'Compensação rápida do pagamento',
    infoPaymentByPixLabel: 'Compensação rápida do pagamento',
    payButtonByPixLabel: 'Pagar Fatura',
    infoPaymentByCreditCardTitle: 'Compensação rápida do pagamento',
    infoPaymentByCreditCardLabel: 'As informações serão enviadas de forma segura e criptografada para reduzir o risco de fraude',
    selectCardLabel: 'Selecione qual cartão gostaria de utilizar e indique o número de parcelas:',
    infoAddNewCreditCardLabel: 'Substituirá o cartão acima em todas as assinaturas vinculadas à ele.',
    changeNextPaymentMethod: 'Usar esta forma de pagamento nas próximas renovações deste(s) produto(s).',
    paymentByCreditCardButton: 'Pagar com Cartão de Crédito',
    selectAccountPaypalLabel: 'Selecione qual conta PayPal gostaria de utilizar:',
    selectActuralPayPalAccountLabel: 'Utilizar conta PayPal cadastrada',
    addNewPayPalAccountLabel: 'Cadastrar uma nova conta PayPal',
    infoChangePaymentByPayPalLabel: 'Ao final do ciclo da assinatura faremos a cobrança automática na conta PayPal cadastrada.',
    infoAddNewAccountLable: 'Substituirá a conta Paypal em todas as faturas vinculadas à ela.',
    invoiceDetailsTitle: 'Descrição da fatura e valores',
    securityTitlePixLabel: 'Segurança em primeiro lugar',
    securityDescriptionPixLabel: 'Fique tranquilo, todos os seus dados são protegidos e toda transação é autorizada pelo Banco Central.',
    agilityTitlePixLabel: 'Mais agilidade',
    agilityDescriptionPixLabel: 'Faça o pagamento através do seu celular e tenha acesso ao produto de forma muito mais rápida.',
    taxTitlePixLabel: 'Sem taxa extras',
    taxDescriptionPixLabel: 'Com o Pix você não precisa se preocupar com taxas adicionais e burocracias.',
    doubtsPixLabel: 'Dúvidas sobre o pagamento via Pix? ',
    doubtsPixLink: 'Acesse nossa Central de Ajuda.',
    doubtsPixURL: 'https://suporte.hostgator.com.br/hc/pt-br/articles/10823239875995',
    titlePixQRCodeCardLabel: {
      0: 'Agora falta pouco, copie o código abaixo',
      1: ' ou escaneie o QR Code para efetuar o pagamento.',
    },
    timerPixLabel: 'Tempo restante para pagamento: ',
    copyCodePixLabel: 'Copiar código Pix',
    copiedCodePixLabel: 'Código Pix copiado!',
    step1PixLabel: 'Abra o aplicativo do seu banco e vá para área Pix',
    step2PixLabel: 'Selecione a opção Pagar com Pix e escolha entre escanear o QR Code ou utilizar o código Copia e Cola',
    step3PixLabel: 'Após o pagamento, aguarde confirmação em tela',
    timeFinishedTimerLabel: 'Tempo de pagamento expirado. Atualize e gere o QR Code novamente.',
    updateQRCodeLabel: 'Atualizar QR Code',
    infoSendEmailWithPix: 'Ao final do ciclo da assinatura uma cobrança Pix será gerada e enviada por e-mail.',
    ticketBlockInfo: {
      infoSelectionTicketOption: {
        0: 'Pagamentos via boleto são identificados em até 72h.',
        1: 'Tenha acesso ao produto de maneira mais rápida ',
        2: 'optando por pagamentos como ',
        3: 'Cartão de Crédito, Pix ou PayPal.',
      },
      doubtsTicketDescription: 'Dúvidas sobre o pagamento via boleto? ',
      doubtsTicketLink: 'Acesse nossa Central de Ajuda.',
      doubtsTicketURL: 'https://suporte.hostgator.com.br/hc/pt-br/articles/4402745864091-Como-pagar-uma-fatura#Boleto',
      infoPaymentByTicketTitle: () => (
        <>
          Falta pouco para finalizar seu pagamento.
          <br />
          Para pagar seu boleto, basta utilizar as informações abaixo:
        </>
      ),
      copyBarcodeLabel: 'Copiar código do boleto',
      copiedBarcodeLabel: 'Código código do copiado!',
      tipTicketTitle: 'Tem alguma dúvida de como realizar o pagamento do boleto? Veja as dicas abaixo.',
      tip1TicketLabel: 'Você pode pagar seu boleto em qualquer lotérica, caixa eletrônico ou aplicativo de banco.',
      tip2TicketLabel: {
        0: 'O seu pagamento será processado e confirmado em até ',
        1: '72 horas ',
        2: 'úteis. ',
        3: 'Portanto, após o pagamento aguarde a compensação para evitar duplicidade.',
      },
      tip3TicketLabel: 'Se o vencimento do seu boleto cair em um feriado ou final de semana, você poderá pagá-lo no próximo dia útil.',
      saveTicketAsPDF: 'Salvar boleto em PDF',
      printTicket: 'Imprimir boleto',
    },
  },
  reactivationConfirmationModal: {
    titleLabel: 'Renovação ativada',
    descriptionLabel: 'A renovação automática da assinatura foi ativada com sucesso!',
    clouseButtonLabel: 'Fechar',
  },
  invoiceGeneratorModal: {
    titleLabel: 'Por favor, aguarde',
    descriptionLabel: {
      0: 'Estamos te levando para o pagamento.',
      1: 'Não feche esta janela.',
    },
  },
  manageSubscriptionSwitchPaymentMethodInvoiceModal: {
    titleLabel: 'Alterar pagamento',
    selectPaymentMethodLabel: 'Selecione o método de pagamento que deseja para renovar as próximas faturas ou altere as informações de parcelamento:',
    infoChangePaymentByTicketLabel: 'Ao final do ciclo da assinatura um boleto será gerado para a renovação da mesma.',
    infoChangePaymentByPayPalLabel: 'Ao final do ciclo da assinatura você será cobrado automaticamente no PayPal.',
    infoChangePaymentByCreditCardLabel: 'Ao final do ciclo da assinatura faremos a cobrança automática no cartão acima.',
    confirmSwitchLabel: 'Confirmar alteração',
  },
  manageSubscriptionFinishSwitchPaymentMethodInvoiceModal: {
    titleLabel: 'Alterar método de pagamento',
    infoSwitchPaymentMethod: {
      0: 'O método de pagamento foi alterado para o ',
      1: 'Paypal',
      2: 'boleto',
      3: 'cartão de crédito',
      4: ' e ao final do ciclo da assinatura você será cobrado automaticamente através dele.',
      5: 'Pix',
    },
    backButtonLabel: 'Voltar para o Gerenciamento da Assinatura',
  },
  manageSubscriptionReactivationModal: {
    reactivateTitleLabel: 'Reativar minha assinatura',
    descriptionLabel: (
      <>
        Antes de clicar no botão abaixo para reativar, lembre-se que só é possível reativar
        {' '}
        <strong>uma vez na mesma fatura</strong>
        {' '}
        e que não é possível reativar domínios. Além disso, a data de vencimento original não será alterada.
      </>
    ),
    infoAlertLabel: (
      <>
        Ao realizar a reativação da assinatura, o serviço deve ficar
        {' '}
        <strong>ativo por 72 horas</strong>
        . Caso a gente não reconheça que houve um pagamento nesse período, a assinatura será suspensa novamente.
      </>
    ),
    reactivateButtonLabel: 'Reativar minha assinatura',

    reactivationSuccessLabel: 'Reativação realizada com sucesso',
    infoSuccessLabel: (
      <>
        Sua assinatura
        {' '}
        <strong>foi reativada com sucesso</strong>
        {' '}
        e já está pronta para uso!
      </>
    ),
    infoSuccessDescriptionLabel: (
      <>
        Não se esqueça que sua assinatura ficará ativa por 72 horas até a confirmação do pagamento e que
        {' '}
        <strong>não é possível reativar mais de uma vez na mesma fatura.</strong>
      </>
    ),
    undestoodButtonLabel: 'Ok, entendi',
    reactivationFailureLabel: 'Erro na reativação',
    infoFailureLabel: (
      <>
        Tivemos um problema na
        {' '}
        <strong>reativação do seu plano.</strong>
      </>
    ),
    infoFailureDescriptionLabel: 'Tente reativar novamente pelo botão abaixo ou volte para a tela de gerenciar sua assinatura.',
    tryAgainButtonLabel: 'Tentar novamente',
    goBackSubscriptionButtonLabel: 'Voltar para gerenciar assinatura',
    infoAlertReactivation: 'Ao reativar a assinatura, todas as hospedagens vinculadas à sua fatura serão reativadas.',
  },
  paymentInstallments: {
    withFees: '(com juros)',
    withoutFees: '(sem juros)',
  },
  paymentMethods: {
    hostgatorCredits: 'Créditos HostGator',
    creditCard: 'Cartão de Crédito',
    ticket: 'Boleto',
    paypal: 'PayPal',
    pix: 'Pix',
    new: 'Novo',
    braspag_credit: 'Cartão de crédito',
    braspag_credit_1x: 'Cartão de crédito',
    braspag_credit_2x: 'Cartão de crédito',
    braspag_credit_3x: 'Cartão de crédito',
    braspag_credit_4x: 'Cartão de crédito',
    braspag_credit_5x: 'Cartão de crédito',
    braspag_credit_6x: 'Cartão de crédito',
    braspag_credit_7x: 'Cartão de crédito',
    braspag_credit_8x: 'Cartão de crédito',
    braspag_credit_9x: 'Cartão de crédito',
    braspag_credit_10x: 'Cartão de crédito',
    braspag_credit_11x: 'Cartão de crédito',
    braspag_credit_12x: 'Cartão de crédito',
    hg_credits: 'Créditos HostGator',
    gp_boleto: 'Boleto Bancário',
    gp_paypal: 'PayPal',
    gp_pix: 'Pix',
    boleto: 'Boleto Bancário',
  },
  expiredTimePixModal: {
    titleLabel: 'Tempo de pagamento expirado',
    descriptionLine1Label: {
      0: 'Altere o método de pagamento ou atualize o ',
      1: 'QR Code',
      2: 'código copia e cola',
      3: ' para concluir o pagamento.',
    },
    descriptionLine2Label: 'Caso tenha efetuado o pagamento, em breve você receberá um e-mail de confirmação.',
    changePaymentMethodLabel: 'Alterar forma de pagamento',
    updateQRCodeLabel: 'Atualizar QR Code',
  },
  generateErrorPixModal: {
    descriptionLineLabel: 'Houve uma falha na geração do QR Code. Atualize e tente novamente.',
    changePaymentMethodLabel: 'Alterar forma de pagamento',
    updateQRCodeLabel: 'Atualizar QR Code',
  },
  paymentSuccessPixModal: {
    titleLabel: 'Pagamento realizado com sucesso!',
    descriptionLine1Label: 'Parabéns, seu pagamento foi realizado e dentro de ',
    descriptionLine2Label: 'instantes você receberá um e-mail de confirmação.',
    backToSignatures: 'Voltar para a Lista de Assinaturas',
    backToInvoices: 'Voltar para a Lista de Faturas',
  },
  ticketRedirectModal: {
    infoTextLabel: 'Seu boleto foi gerado com sucesso!',
    redirectTicketUrlButtonLabel: 'Abrir Boleto',
    downloadPDFButtonLabel: 'Fazer Download',
  },
  paymentCycle: {
    Monthly: 'Mensal',
    Quarterly: 'Trimestral',
    SemiAnnually: 'Semestral',
    Annually: 'Anual',
    Biennially: 'Bienal',
    Triennially: 'Trienal',
    FreeAccount: 'Trial',
  },
  ecommercePlus: {
    changeCycleLabel: 'Alterar ciclo do E-commerce Plus',
  },
  status: {
    activeStatusLabel: 'Ativo',
    expiredStatusLabel: 'Expirado',
    registeredStatusLabel: 'Registrado',
    transferredAwayStatusLabel: 'Transferido',
    cancelledStatusLabel: 'Cancelado',
    pendingStatusLabel: 'Pendente',
    pendingPaymentStatusLabel: 'Aguardando pagamento',
    pendingAnalysisStatusLabel: 'Em análise',
    suspendedStatusLabel: 'Suspenso',
    toDueStatusLabel: 'À Vencer',
    overdueStatusLabel: 'Vencida',
  },
  subscriptions: {
    supportEmail: 'analise@hostgator.com.br',
    supportWizard: 'mailto:analise@hostgator.com.br',
    searchPlaceholder: 'Pesquisar nome da assinatura',
    searchPlaceholderNewLayout: 'Busque por assinatura',
    clearLabel: 'Limpar filtros',
    listTitle: 'Lista de Assinaturas',
    buttonManageSubscriptionLabel: 'Gerenciar Assinatura',
    manageSubscriptionLabel: 'Gerenciar',
    activeStatusLabel: 'Ativo',
    expiredStatusLabel: 'Expirado',
    registeredStatusLabel: 'Registrado',
    readMoreStepper: 'Ler mais',
    transferredAwayStatusLabel: 'Transferido',
    cancelledStatusLabel: 'Cancelado',
    pendingStatusLabel: 'Pendente',
    pendingPaymentStatusLabel: 'Aguardando pagamento',
    pendingAnalysisStatusLabel: 'Em análise',
    suspendedStatusLabel: 'Suspenso',
    toDueStatusLabel: 'À Vencer',
    overdueStatusLabel: 'Vencida',
    sectionTitle: 'Resumo da Assinatura',
    subscriptionPrice: 'Valor da Assinatura',
    statusLabel: 'Status',
    hireDateLabel: 'Contratado em',
    renewDateLabel: 'Renova em',
    cancelledLabel: 'Cancelado em',
    paymentCicleLabel: 'Ciclo de Pagamento',
    paymentMethodLabel: 'Método de Pagamento',
    autoChargesLabel: 'Cobrança Automática',
    hirePlan: 'CONTRATAR NOVO PLANO',
    message: {
      domain: {
        registered: {
          withCancelDate: {
            0: 'Seu domínio',
            1: ' será cancelado dia',
            2: ' Ative a renovação agora mesmo',
            3: ' e não corra o risco de perdê-lo!',
          },
        },
        overdue: {
          0: 'Sua fatura ',
          1: 'venceu dia',
          2: ' e seu domínio será expirado em',
          '3-singular': 'dia. ',
          3: 'dias. ',
          4: 'Pague o boleto agora mesmo!',
          5: 'Pague com Pix agora mesmo',
        },
        expired: {
          0: 'Sua fatura',
          1: ' venceu dia',
          2: ' e seu domínio será cancelado em breve.',
          3: ' Pague o boleto agora mesmo!',
          4: 'Pague com Pix agora mesmo',
        },
        cancelled: {
          forNonPayment: {
            0: 'Seu domínio',
            1: ' foi cancelado dia',
            2: ' por falta de pagamento,',
            3: ' acesse nosso site e registre novo domínio',
          },
          byClient: {
            0: 'Conforme solicitado, seu domínio',
            1: ' foi cancelado dia',
            2: ' acesse nosso site e registre novo domínio',
          },
          byFraud: {
            0: 'Seu domínio foi cancelado.',
            1: ' Entre em contato com',
            2: ' analise@hostgator.com.br',
            3: ' para mais informações',
          },
        },
        transferredAway: 'Conforme solicitado, seu domínio foi transferido para outro provedor de hospedagem.',
      },
      addon: {
        withDateSuspended: {
          0: 'Sua assinatura ',
          1: 'foi suspensa dia',
          2: 'Pague o boleto agora mesmo',
          3: ' para que ela não seja cancelada!',
        },
        withoutDateSuspended: {
          0: 'Sua ',
          1: 'assinatura foi suspensa',
          2: '. Para mais informações ',
          3: 'entre em contato com o suporte financeiro',
          4: ' através do nosso chat.',
        },
      },
      active: {
        withCancelDate: {
          0: 'Sua assinatura',
          1: ' será cancelada dia',
          2: ' Ative a renovação agora mesmo',
          3: ' e não corra o risco de perdê-lo!',
        },
      },
      pendingAnalysis: 'Sua assinatura está passando pela nossa análise de segurança antes de confirmarmos o pagamento e ficar ativa (procedimento normal).',
      pendingPayment: 'Estamos aguardando a confirmação do pagamento. Boletos podem levar até 72h para compensar. Caso já tenha pago, por favor aguarde.',
      newPendingAnalysis: 'Sua assinatura está passando pela nossa análise de segurança depois de confirmarmos o pagamento e ficar ativa (procedimento normal).',
      newPendingPayment: 'Estamos aguardando a confirmação do pagamento da sua assinatura. Se já pagou, por favor aguarde.',

      toDue: {
        0: 'Sua assinatura ',
        1: 'vence dia',
        ticket: {
          0: '. ',
          1: 'Pague o boleto agora mesmo',
          2: ' e não corra o risco de perder seu site e arquivos!',
          3: 'Pague com Pix agora mesmo',
        },
        creditCard: {
          0: ' e você será cobrado automaticamente via Cartão de Crédito.',
        },
        paypal: {
          0: ' e você será cobrado automaticamente via Conta PayPal.',
        },
      },
      overdue: {
        0: 'Sua assinatura ',
        1: 'venceu dia',
        2: ' e ',
        3: 'seu site sairá do ar em',
        4: 'dias.',
        '4-singular': 'dia.',
        5: ' Pague o boleto agora mesmo!',
        6: ' Pague com Pix e renove seu plano agora mesmo!',
      },
      offAfterExpiration: {
        0: 'Sua assinatura ',
        1: 'venceu dia',
        2: '. Para maiores informações entre em contato com o suporte.',
      },
      com_o_cancelamento_renovação_marcado: '<p>Sua assinatura <strong>será cancelada dia {{cancelDate}}</strong> Ative a renovação agora mesmo e não corra o risco de perder seu site e arquivos!</p>',
      suspended: {
        withDate: {
          0: 'Sua assinatura ',
          1: 'foi suspensa dia ',
          2: ' e ',
          3: 'seu site saiu do ar.',
          4: ' Pague o boleto agora mesmo',
          5: ' para que ela não seja cancelada!',
          6: ' Pague com Pix e renove seu plano agora mesmo!',
        },
        withoutDate: {
          0: 'Sua ',
          1: 'assinatura foi suspensa',
          2: ' e seu site saiu do ar',
          3: '. Para mais informações ',
          4: 'entre em contato com o suporte financeiro',
          5: ' através do nosso chat.',
        },
        standard: {
          0: 'Sua assinatura foi suspensa e seu site saiu do ar.',
          1: ' Pague agora mesmo',
          2: ' para',
          3: ' reativar a assinatura.',
        },
      },
      cancelled: {
        user_request: {
          0: 'Conforme solicitado, sua assinatura ',
          1: 'foi cancelada dia {{cancelDate}}',
          2: 'Acesse nosso site e faça uma nova assinatura!',
        },
        user_request_ecommerce_plus: {
          0: 'Conforme solicitado, sua assinatura ',
          1: 'foi cancelada dia {{cancelDate}}',
        },
        whmcs_suspension: {
          0: 'Sua assinatura ',
          1: 'foi cancelada dia {{cancelDate}}',
          2: 'por falta de pagamento. ',
          3: 'Acesse nosso site e faça uma nova assinatura!',
        },
        whmcs_suspension_ecommerce_plus: {
          0: 'Sua assinatura ',
          1: 'foi cancelada dia {{cancelDate}}',
          2: 'por falta de pagamento.',
        },
        default: {
          0: 'Sua assinatura ',
          1: 'foi cancelada dia {{cancelDate}}',
          2: '. Entre com contato com ',
          3: ' para mais informações.',
        },
        fraud: {
          0: 'Sua assinatura ',
          1: 'foi cancelada',
          2: '. Entre com contato com ',
          3: ' para mais informações.',
        },
        withoutDate: {
          0: 'Sua assinatura ',
          1: 'foi cancelada',
          2: ' por falta de pagamento. ',
          3: 'Acesse nosso site e faça uma nova assinatura!',
        },
        withoutDate_ecommerce_plus: {
          0: 'Sua assinatura ',
          1: 'foi cancelada',
          2: ' por falta de pagamento.',
        },
      },
      management: {
        backupFilesLinkUrl: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173',
        firstLine: 'A renovação desta assinatura está desativada e será ',
        boldFirstLine: 'cancelada automaticamente em',
        secondLine: 'Após esta data, todos os dados e arquivos serão ',
        boldSecondLine: 'excluídos permanentemente. ',
        linkLabel: 'Veja como fazer o backup dos seus arquivos.',
      },
    },
    autoChargeTooltip: {
      paragraphOne: 'Com o status ativo, tentaremos fazer a cobrança automática no Cartão de Crédito ou PayPal cadastrado.',
      paragraphTwo: 'Se houver algum problema na cobrança ou se estiver desativada avisaremos enviando link para gerar boleto perto do prazo de renovação :)',
    },
    refundFeedback: {
      titleLabel: 'Reembolso',
      statusLabel: 'Status',
      statusText: {
        pending: 'Pendente',
        accomplished: 'Efetuado',
      },
      valueLabel: 'Valor',
      methodLabel: 'Modo',
    },
    automaticBilling: {
      feedback: {
        active: 'Ativa',
        disabled: 'Desativada',
      },
      toggle: {
        active: 'Ativar',
        disable: 'Desativar',
      },
      activeModal: {
        titleLabel: 'Ativar cobrança automática',
        infoTextLabel: {
          0: 'Para ativar a "cobrança automática" de uma assinatura, ',
          1: 'você precisa escolher entre Cartão de Crédito ou Conta PayPal ',
          2: 'e eles precisam estar aptos para cobrança ao final do contrato.',
        },
        activeCreditCardButtonLabel: 'Ativar com Cartão de Crédito',
        activePaypalButtonLabel: 'Ativar com Conta PayPal',
      },
      deactiveModal: {
        titleLabel: 'Desativar cobrança automática',
        infoTextLabel: {
          0: 'Desativar a "cobrança automática" ',
          1: 'corre o risco de não renovar sua assinatura, ',
          2: 'fazendo que você precise renová-la ',
          3: 'manualmente ao final do contrato!',
        },
        confirmeDeactiveButtonLabel: 'Confirmar desativação',
      },
    },
    cancel: {
      cancelRenewalButtonLabel: 'CANCELAR RENOVAÇÃO',
      activeRenewalButtonLabel: 'ATIVAR RENOVAÇÃO',
      confirmation: {
        controlBackButtonLabel: 'Voltar',
        controlFowardButtonLabel: 'Continuar',
        controlFowardButtonStepConfirmationCancelRenewalLabel: 'Confirmar cancelamento da renovação',
        controlFowardButtonStepConfirmationCancelSignatureLabel: 'Confirmar cancelamento da assinatura',
        tooltipCancelRenewalText: 'Selecione o motivo de cancelamento da renovação para continuar.',
        tooltipCancelSignatureText: 'Selecione o motivo do cancelamento para continuar.',
        confirmationTitleCancelRenewalLabel: 'Leia as informações abaixo antes de confirmar o cancelamento da renovação da assinatura:',
        confirmationTitleCancelSignatureLabel: 'Leia as informações abaixo antes de confirmar o cancelamento:',
        attentionLabel: 'Atenção:',
        stopCancelRenewalLabel: 'Não quero mais cancelar a renovação',
        stopCancelSignatureLabel: 'Não quero mais cancelar',
        backupFilesLinkLabel: 'Veja como fazer o backup dos seus arquivos',
        backupFilesLinkUrl: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000385173',
        attentionCancelRenewalTexts: dateString => (
          <>
            <li>
              O produto ficará disponível até o final do ciclo da assinatura, em
              {' '}
              <strong>{dateString}</strong>
            </li>
            <li>
              Após esta data, a assinatura será
              {' '}
              <strong>cancelada imediatamente</strong>
              {' '}
              e todos os seus dados e arquivos serão
              {' '}
              <strong>excluídos permanentemente.</strong>
            </li>
          </>
        ),
        domainAttentionCancelRenewalTexts: dateString => (
          <>
            <li>
              O domínio ficará disponível até
              {' '}
              <strong>{dateString}</strong>

            </li>
            <li>
              Após esta data, a assinatura será
              {' '}
              <strong>cancelada imediatamente</strong>
              .
            </li>
          </>
        ),
        addonAttentionCancelRenewalTexts: dateString => (
          <>
            <li>
              O produto ficará disponível até o final do ciclo da assinatura, em
              {' '}
              <strong>{dateString}</strong>
            </li>
            <li>
              Após esta data, a assinatura será
              {' '}
              <strong>cancelada imediatamente.</strong>
            </li>
          </>
        ),
        addonCancellation: (addon, dateString) => (
          <li>
            O produto
            {' '}
            <strong>{addon}</strong>
            {' '}
            atrelado a esta assinatura também será cancelado em
            {' '}
            <strong>{dateString}</strong>
          </li>
        ),
        attentionCancelSignatureTexts: {
          0: 'Sua assinatura será ',
          1: 'cancelada imediatamente',
          2: ' e todos os seus dados e arquivos serão ',
          3: 'excluídos permanentemente.',
        },
        addonCancellationAlert: {
          0: 'O produto',
          1: 'atrelado a esta assinatura será',
          2: 'cancelado automaticamente',
        },
      },
      feedbackStepper: {
        stepSurveyLabel: 'Responder pesquisa',
        stepRefundLabel: 'Reembolso',
        stepReviewLabel: 'Conferir informações',
        stepConfirmationLabel: 'Confirmar cancelamento',
      },
      survey: {
        surveyQuestionDescriptionLabel: 'O seu feedback é muito importante para nós.',
        surveyReasonQuestionLabel: 'Poderia nos falar um pouco mais sobre o motivo selecionado?',
      },
      withRefund: {
        title: 'Veja as possibilidades de reembolso disponíveis para sua assinatura:',
        totalLabel: 'Total para reembolso',
        refundPolitics: 'Ver política de reembolso',
        tipTitle: 'Dica:',
        tipText: 'Por essa quantia é mais vantajoso utilizar a assinatura até o final do ciclo, em {{date}}.',
        giveUp: 'Desistir do Cancelamento',
        selectMethodTitle: 'Selecione um modo de reembolso:',
      },
      noRefund: {
        title: 'Veja as possibilidades de reembolso disponíveis para sua assinatura:',
        amountTitle: 'Total para reembolso',
        withoutRefund: 'Esta assinatura não possui direito a reembolso.',
        refundPolitics: 'Ver política de reembolso',
        tipTitle: 'Dica:',
        tipText: 'É mais vantajoso utilizar a assinatura até o final do ciclo, em',
        stopCancelLabel: 'Desistir do Cancelamento',
      },
      refundModalV2: {
        title: 'Política de Reembolso',
        accordionCancelledBefore: {
          title: 'Reembolso para assinaturas mensais',
          titleHighlight: 'assinaturas mensais',
          refundableSubscriptions: {
            title: 'Cancelamentos realizados até 7 dias após a contratação',
            label: 'Assinaturas reembolsáveis:',
            text: 'Planos de Hospedagem (Start, P, M, Business, Turbo, Plus, Criador de Sites, WordPress Hosting, Revendas, VPS, Dedicados), E-mail Profissional Titan, Produtos adicionais (CodeGuard, SiteLock, SSL) e HostGator Academy Pass.',
          },
          nonRefundableSubscriptions: {
            label: 'Assinaturas NÃO reembolsáveis:',
            text: 'Registro de domínios, E-mail G-Suite e E-commerce Plus.',
          },
          refundableWays: {
            label: 'Formas de reembolso:',
            text: 'Valor reembolsado como Crédito na HostGator para abater em uma próxima fatura ou reembolso integral através do mesmo método de pagamento feito na contratação.',
          },
          exception: {
            label: 'Exceção:',
            text: 'No cancelamento de Planos de Hospedagem assinados a partir de ciclos anuais com Domínio Gratuito será reembolsado o valor do Plano descontado o valor do Domínio.',
          },
        },
        accordionCancelledAfter: {
          title: 'Reembolso para assinaturas em outros ciclos',
          titleHighlight: 'assinaturas em outros ciclos',
          refundableSubscriptions: {
            title: 'Cancelamentos realizados até 30 dias após a contratação',
            label: 'Assinaturas reembolsáveis:',
            text: 'Planos de Hospedagem (Start, P, M, Business, Turbo, Plus, Criador de Sites, WordPress Hosting, Revendas, VPS, Dedicados), E-mail Profissional Titan, Produtos adicionais (CodeGuard, SiteLock, SSL) e HostGator Academy Pass.',
          },
          nonRefundableSubscriptions: {
            label: 'Assinaturas NÃO reembolsáveis:',
            text: 'Registro de domínios, E-mail G-Suite e E-commerce Plus.',
          },
          nonRefundableCicles: {
            label: 'Ciclos não reembolsáveis:',
            text: 'Mensal.',
          },
          refundableWays: {
            label: 'Formas de reembolso:',
            text: 'Valor reembolsado como Crédito na HostGator para abater em uma próxima fatura ou reembolso integral através do mesmo método de pagamento feito na contratação.',
          },
          exception: {
            label: 'Exceção:',
            text: 'No cancelamento de Planos de Hospedagem assinados a partir de ciclos anuais com Domínio Gratuito será reembolsado o valor do Plano descontado o valor do Domínio.',
          },
          cancelAfterContract: {
            title: 'Cancelamentos realizados após 30 dias da contratação',
            label: 'Assinaturas reembolsáveis:',
            text: 'Planos de Hospedagem (Start, P, M, Business, Turbo, Plus, Criador de Sites, WordPress Hosting, Revendas, VPS, Dedicados), E-mail Profissional Titan, Produtos adicionais (Codeguard, Sitelock, SSL e E-commerce Plus).',
          },
          nonRefundContract: {
            label: 'Assinaturas NÃO reembolsáveis:',
            text: 'Registro de domínios, E-mail G-Suite e hostGator Academy Pass.',
          },
          nonRefundCycles: {
            label: 'Ciclos NÃO reembolsáveis',
            text: 'Ciclos mensais.',
          },
          refundCalculation: {
            label: 'Cálculo de reembolso:',
            text: 'Valor (R$) dos meses restantes não utilizados da assinatura - 30% de multa rescisória.',
          },
          refundWays: {
            label: 'Formas de reembolso:',
            text: 'Valor reembolsado como Crédito na HostGator para abater em uma próxima fatura ou reembolso parcial através do mesmo método de pagamento feito na contratação.',
          },
        },
        serviceTermsButtonLabel: 'Ver termos de serviço',
        serviceTermsUrl: 'https://www.hostgator.com.br/termos-de-servico',
      },
      refundModal: {
        title: 'Política de Reembolso',
        accordionCancelledBefore: {
          title: 'Cancelamentos realizados até 7 dias após a contratação',
          titleHighlight: 'até 7 dias',
          refundableSubscriptions: {
            label: 'Assinaturas reembolsáveis:',
            text: 'Planos de Hospedagem (Start, P, M, Business, Turbo, Plus, Criador de Sites, WordPress Hosting, Revendas, VPS, Dedicados), E-mail Profissional Titan, Produtos adicionais (CodeGuard, SiteLock, SSL) e HostGator Academy Pass.',
          },
          nonRefundableSubscriptions: {
            label: 'Assinaturas NÃO reembolsáveis:',
            text: 'Registro de domínios, E-mail G-Suite e E-commerce Plus.',
          },
          refundableCicles: {
            label: 'Ciclos reembolsáveis:',
            text: 'Todos (mensal, semestral, anual, bienal, trienal).',
          },
          refundableWays: {
            label: 'Formas de reembolso:',
            text: 'Valor reembolsado como Crédito na HostGator para abater em uma próxima fatura ou reembolso integral através do mesmo método de pagamento feito na contratação.',
          },
          exception: {
            label: 'Exceção:',
            text: 'No cancelamento de Planos de Hospedagem assinados a partir de ciclos anuais com Domínio Gratuito será reembolsado o valor do Plano descontado o valor do Domínio.',
          },
          footerNote: 'Política válida apenas para novas contratações.',
        },
        accordionCancelledAfter: {
          title: 'Cancelamentos realizados após 7 dias da contratação',
          titleHighlight: 'após 7 dias',
          refundableSubscriptions: {
            label: 'Assinaturas reembolsáveis:',
            text: 'Planos de Hospedagem (Start, P, M, Business, Turbo, Plus, Criador de Sites, WordPress Hosting, Revendas, VPS, Dedicados), E-mail Profissional Titan, Produtos adicionais (Codeguard, Sitelock, SSL e E-commerce Plus).',
          },
          nonRefundableSubscriptions: {
            label: 'Assinaturas NÃO reembolsáveis:',
            text: 'Registro de domínios, E-mail G-Suite e hostGator Academy Pass.',
          },
          refundableCicles: {
            label: 'Ciclos reembolsáveis:',
            text: 'Ciclos acima de mensais (semestral, anual, bienal, trienal).',
          },
          nonRefundableCicles: {
            label: 'Ciclos NÃO reembolsáveis:',
            text: 'Ciclos mensais.',
          },
          refundableCalculation: {
            label: 'Cálculo de reembolso:',
            text: 'Valor (R$) dos meses restantes não utilizados da assinatura - 30% de multa rescisória.',
          },
          exampleCalculation: {
            label: 'Exemplo de cálculo:',
            text: 'Se contratou um plano anual de 12 meses no valor de R$120,00 e utilizou 10 meses, será aplicada a multa de 30% nos R$20,00 restantes. Portanto, o valor de reembolso será de R$14,00.',
          },
          amountOwed: {
            label: 'Valor devido',
            annualContractAmount: 'R$ 120',
            annualContractLabel: 'Contrato Anual (12 meses)',
            usedAmount: 'R$ 100',
            usedLabel: 'Utilizados (10 meses)',
          },
          amountAvailableRefund: {
            label: 'Valor disponível para reembolso',
            totalAmount: 'R$ 20',
            totalLabel: 'Total',
            terminationFinePercents: '30%',
            terminationFineLabel: 'Multa rescisória',
          },
          refundableWays: {
            label: 'Formas de reembolso:',
            text: 'Valor reembolsado como Crédito na HostGator para abater em uma próxima fatura ou reembolso parcial através do mesmo método de pagamento feito na contratação.',
          },
        },
        refundPolicyButtonLabel: 'Ver mais sobre a política de reembolso',
        serviceTermsButtonLabel: 'Ver termos de serviço',
        serviceTermsUrl: 'https://www.hostgator.com.br/termos-de-servico',
      },
      review: {
        title: 'Confira as informações do seu cancelamento:',
        totalLabel: 'Total para reembolso',
        signatureTitle: 'Assinatura',
        reasonTitle: 'Motivo do Cancelamento',
        refundTitle: 'Modo de Reembolso',
      },
      refundMethodOptions: {
        hg_credits: {
          name: 'Créditos HostGator',
          description: 'O reembolso será abatido no valor da sua próxima fatura.',
          info: 'O reembolso será abatido no valor da sua próxima fatura (quando houver).',
        },
        braspag_credit_1x: {
          name: 'Cartão de Crédito',
          description: 'Você receberá o reembolso diretamente na fatura do cartão de crédito utilizado na contratação.',
          info: 'Você receberá o reembolso diretamente na fatura do cartão de crédito utilizado na contratação.',
        },
        dlocal: {
          name: 'Cartão de Crédito',
          description: 'Você receberá o reembolso diretamente na fatura do cartão de crédito utilizado na contratação.',
          info: 'Você receberá o reembolso diretamente na fatura do cartão de crédito utilizado na contratação.',
        },
        gp_boleto: {
          name: 'Transferência por PIX',
          description: 'Receba o reembolso através de uma chave PIX de CPF ou CNPJ.',
          info: 'Receba o reembolso através de uma chave PIX de CPF ou CNPJ.',
        },
        gp_paypal: {
          name: 'PayPal',
          description: 'Você receberá o reembolso diretamente na conta PayPal utilizada na contratação.',
          info: 'Você receberá o reembolso diretamente na conta PayPal.',
        },
      },
      processing: (
        <>
          <p>
            Estamos processando o seu pedido.
          </p>
          <p>
            Por favor, não feche esta janela.
          </p>
        </>
      ),
    },
    cancelSubscription: 'CANCELAR ASSINATURA',
    paymentCycle: {
      Monthly: 'Mensal',
      Quarterly: 'Trimestral',
      SemiAnnually: 'Semestral',
      Annually: 'Anual',
      Biennially: 'Bienal',
      Triennially: 'Trienal',
      FreeAccount: 'Trial',
    },
    perCycle: {
      Monthly: '/ mês',
      Quarterly: '/ 3 meses',
      SemiAnnually: '/ 6 meses',
      Annually: '/ ano',
      Biennially: '/ 2 anos',
      Triennially: '/ 3 anos',
      FreeAccount: '',
    },
    notifications: {
      cancelRenewalPartOne: 'O cancelamento da renovação da assinatura',
      cancelRenewalPartTwo: 'não pôde ser concluído. Por favor, tente novamente mais tarde.',
      activateRenewal: {
        success: {
          0: 'A renovação de sua assinatura ',
          1: ' foi ativada',
        },
        failure: {
          0: 'O cancelamento da renovação da assinatura ',
          1: ' não pôde ser concluído. Por favor, tente novamente mais tarde.',
        },
      },
      cancelRenewal: {
        retry: {
          0: 'Um novo cancelamento da renovação da assinatura poderá ser feito ',
          1: 'após 24h da última reativação ',
          2: 'da renovação da assinatura',
        },
      },
      cancelSignature: {
        success: {
          0: 'A assinatura do ',
          1: ' foi cancelada.',
        },
        failure: {
          0: 'O cancelamento da assinatura ',
          1: ' não pôde ser concluído. Por favor, tente novamente mais tarde.',
        },
      },
      switchPaymentMethod: {
        failure: 'Não foi possível salvar no momento, por favor, tente mais tarde. :)',
      },
    },
    modal: {
      activateRenew: {
        title: 'Deseja ativar a renovação da assinatura?',
        content: 'A cobrança de pagamento será efetuada no final do ciclo, evitando que seu produto seja cancelado e seus dados e arquivos sejam excluídos permanentemente.',
        buttonRenewalLabel: 'Confirmar ativação',
      },
    },
    urls: {
      domain: 'registro-de-dominio',
    },
  },
  duplicateOrdersModal: {
    modalTitle: 'Pedidos duplicados',
    modalDescription: (
      <>
        Você tem dois ou mais pedidos para o
        {' '}
        <strong>
          mesmo produto e domínio
        </strong>
        {' '}
        e isso pode gerar cobranças duplicadas. Deseja cancelar algum?
      </>
    ),
    modalDescriptionMobile: (
      <>
        Você tem dois ou mais pedidos para o
        {' '}
        <strong>mesmo produto e domínio.</strong>
        <br />
        Confira abaixo:
      </>
    ),
    invoicePendingDescription: (invoiceId, date, hour) => (
      <>
        Fatura
        {' '}
        <strong>{invoiceId}</strong>
        {' gerada dia '}
        <strong>{date}</strong>
        {' às '}
        <strong>
          {hour}
          h.
        </strong>
      </>
    ),
    invoicePendingText: 'Se o pedido foi gerado por engano, você pode cancelar.',
    invoicePayedDescription: (invoiceId, date, hour) => (
      <>
        Fatura
        {' '}
        <strong>{invoiceId}</strong>
        {' gerada dia '}
        <strong>{date}</strong>
        {' às '}
        <strong>
          {hour}
          h.
        </strong>
        <br />
      </>
    ),
    invoicePayedText: 'O pagamento desse pedido já foi efetuado e o produto está ativo.',
    showInvoice: 'Ver fatura',
    cancelOrder: 'Cancelar pedido',
    planNameAccordion: (planName, domain) => (
      <>
        {`${planName} + domínio ${domain}`}
      </>
    ),
    domainNameAccordion: domain => (
      <>
        {`Domínio ${domain}`}
      </>
    ),
    payedTag: 'PAGO',
    pendingTag: 'PENDENTE',

    successTitle: 'Pedido cancelado com sucesso',
    successAlertLabel: (domain, invoiceId, date, hour, plan = null) => (
      <>
        {'Seu pedido '}
        <strong>
          {plan ? `${plan} + domínio ${domain}, ` : `Domínio ${domain}, `}
          {`fatura ${invoiceId}`}
        </strong>
        {' gerada dia '}
        <strong>
          {date}
        </strong>
        {' às '}
        <strong>
          {hour}
          h
        </strong>
        {' foi cancelado com sucesso.'}
      </>
    ),
    okButtonLabel: 'Ok, entendi',

    failureTitle: 'Erro no cancelamento',
    failureAlertLabel: (domain, invoiceId, date, hour, plan = null) => (
      <>
        Tivemos um problema ao cancelar o
        {' seu pedido '}
        <strong>
          {plan ? `${plan} + domínio ${domain}, ` : `Domínio ${domain}, `}
          {`fatura ${invoiceId}`}
        </strong>
        {' gerada dia '}
        <strong>
          {date}
        </strong>
        {' às '}
        <strong>
          {hour}
          h.
        </strong>
        <br />
        <br />
        Aguarde alguns instantes e tente novamente.
      </>
    ),
    tryAgainButtonLabel: 'Tentar novamente',
    choiceOrderDescription: (
      <>
        {'Você tem '}
        <strong>mais de um pedido com cobranças duplicadas.</strong>
        {' Escolha o pedido que quer ver na lista abaixo:'}
      </>
    ),
    selectOrderTitle: 'Pedidos duplicados',
    selectOrderPlaceholder: 'Selecione o pedido',
    choiceOrderButtonLabel: 'Ir para o pedido duplicado',
  },
  changePaymentMethodModal: {
    defaultStep: {
      successPayment: 'Pagamento confirmado',
      successRegister: 'Cartão de Crédito cadastrado',
      signaturePaymentChanged: 'Pagamento alterado para Cartão de Crédito',
      signaturePaymentChangedSubtitle: 'Seu pagamento será debitado automaticamente na data de vencimento',
      changePaymentLabel: 'Gostaria de aproveitar para alterar a forma de pagamento das suas outras assinaturas para Cartão de Crédito?',
      changePaymentDescription: 'Com a cobrança automática via Cartão de Crédito, seu pagamento é debitado na data de vencimento da fatura, evitando esquecimentos.',
      closeModalButtonLabel: 'Não, obrigado(a)',
      changeToCcButtonLabel: 'Alterar para Cartão de Crédito',
    },
    changeMethodStep: {
      changeToCcLabel: 'Alterar para Cartão de Crédito',
      descriptionLabel: 'Quais produtos você gostaria de alterar a forma de pagamento?',
      tableLabels: {
        product: 'Produto',
        paymentMethod: 'Forma de pagamento',
        nextRenew: 'Próxima renovação',
        renewValue: 'Valor da renovação',
      },
      cancellButtonLabel: 'Cancelar',
      confirmButtonLabel: 'Alterar para Cartão de Crédito',
      errorToast: 'Ocorreu um erro inesperado, tente novamente mais tarde',
    },
    successChangeMethodStep: {
      successChangeLabel: 'Os produtos selecionados foram atualizados para Cartão de Crédito.',
      confirmButtonLabel: 'Entendi, continuar navegando',
    },
  },
  creditCard: {
    changeCreditCard: 'Alterar cartão',
    changeMethodTitle: 'Simplifique sua vida com pagamento automático!',
    changeMethodDescription: 'Use seu cartão para todas assinaturas e esqueça boletos, com a cobrança automática via cartão de crédito, seu pagamento é debitado no vencimento da fatura, evite esquecimentos.',
    useThis: 'Usar esse Cartão nas minhas assinaturas',
  },
  defaultErrorMessage: 'Ocorreu um erro inesperado, tente novamente mais tarde',
};
