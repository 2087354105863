import React from 'react';
import * as Style from './Card.styles';
import iconHospedagem from '@/media/siteBuilder/icon-hospedagem.svg';

const Card = ({
  testId = 'card',
  children,
  icon = 'default',
  hasIcon = true,
  title = '',
  description = '',
  selected = false,
  asButton = false,
  iconAction,
  onClick,
  cursorPointer,
}) => (

  <Style.Card
    data-testid={testId}
    selected={selected}
    asButton={asButton}
    onClick={onClick}
    cursorPointer={cursorPointer}
    iconAction={iconAction}
  >
    <Style.CardContainer>
      {title && (
        <Style.Title>
          {(hasIcon && icon === 'default')
            ? <Style.Icon src={iconHospedagem} alt="card-icon" />
            : icon
          }
          {title}
        </Style.Title>
      )}

      {description && (
        <Style.Description>
          {description}
        </Style.Description>
      )}

      {children && (
        <Style.Content>
          {children}
        </Style.Content>
      )}
    </Style.CardContainer>
  </Style.Card>

);

export default Card;
