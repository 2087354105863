import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  content: {
    position: 'fixed',
    zIndex: 999,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: fade(theme.palette.secondary.main, 0.5),
    textAlign: 'center',
    padding: 0,
    fontSize: 14,
    fontWeight: '300',
    color: '#333',
    backdropFilter: 'blur(1px)',
  },
  contentInfo: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    height: '30px',
    padding: 0,
  },
  item: {
    fontWeight: '500',
    marginLeft: 5,
    marginRight: 3,
  },
  commit: {
    display: 'block',
    float: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 86,
    fontSize: 14,
    marginTop: 1,
    textDecoration: 'none',
  },
});

export default styles;
