import React from 'react';
import * as Style from './IconPix.style';

const IconPix = ({
  color = '#2E578B',
  testId = 'icon-pix',
  size = 72,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 72 72" fill={color}>
      <g id="Pix" transform="translate(-320 -556.5)">
        <g id="local_icon_pix" transform="translate(340 576.5)">
          <rect id="boundary" width="32" height="32" fill="#" opacity="0.001" />
          <g id="Grupo_38612" data-name="Grupo 38612" transform="translate(-351.499 -561)">
            <g id="g992" transform="translate(357.984 579.05)">
              <path id="path994" d="M-76.928-26.774a4.671,4.671,0,0,1-3.324-1.376l-4.8-4.8a.912.912,0,0,0-1.261,0l-4.817,4.817a4.672,4.672,0,0,1-3.324,1.376H-95.4l6.079,6.079a4.862,4.862,0,0,0,6.875,0l6.1-6.1Z" transform="translate(95.4 33.202)" />
            </g>
            <g id="g996" transform="translate(357.984 561)">
              <path id="path998" d="M-3.938-31.245A4.672,4.672,0,0,1-.614-29.869L4.2-25.051a.893.893,0,0,0,1.261,0l4.8-4.8a4.672,4.672,0,0,1,3.324-1.376h.578l-6.1-6.1a4.861,4.861,0,0,0-6.875,0l-6.079,6.078Z" transform="translate(4.884 38.747)" />
            </g>
            <g id="g1000" transform="translate(351.499 569.863)">
              <path id="path1002" d="M-127.337-15.4l-3.684-3.684a.7.7,0,0,1-.262.053h-1.675a3.31,3.31,0,0,0-2.326.963l-4.8,4.8a2.3,2.3,0,0,1-1.629.674,2.3,2.3,0,0,1-1.629-.674l-4.818-4.818a3.311,3.311,0,0,0-2.326-.963h-2.06a.7.7,0,0,1-.247-.05l-3.7,3.7a4.861,4.861,0,0,0,0,6.875l3.7,3.7a.7.7,0,0,1,.248-.05h2.06a3.311,3.311,0,0,0,2.326-.963l4.817-4.817a2.359,2.359,0,0,1,3.258,0l4.8,4.8a3.31,3.31,0,0,0,2.326.964h1.675a.7.7,0,0,1,.262.053l3.684-3.684a4.861,4.861,0,0,0,0-6.875" transform="translate(157.913 19.102)" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);


export default IconPix;
