import phoneValidationBR from './phoneValidation.br';
import phoneValidationCL from './phoneValidation.cl';
import phoneValidationCO from './phoneValidation.co';
import phoneValidationMX from './phoneValidation.mx';

const phoneValidation = {
  BR: phoneValidationBR,
  CL: phoneValidationCL,
  CO: phoneValidationCO,
  MX: phoneValidationMX,
};

export default phoneValidation;
