import { STATUS_TYPE } from '@/enums/sites/status.enum';
import { rules } from './useMountCardStatus.rules';
import { ThemeContext } from 'styled-components';
import { useCallback, useContext } from 'react';

const useMountCardStatus = ({ substatusToStatus, justPlan, withGatorSolutions }) => {
  const theme = useContext(ThemeContext);
  const getAllSiteStatus = useCallback((siteItem) => {
    if (withGatorSolutions) {
      return ({
        pending: siteItem.status.toLowerCase() === 'pending',
        inAnalysis: siteItem.sub_status.toLowerCase() === 'in_analysis',
        planIsToDue: siteItem.sub_status.toLowerCase() === 'to_due',
        planIsOverdue: siteItem.sub_status.toLowerCase() === 'overdue',
        planSuspended: siteItem.status.toLowerCase() === 'suspended',
      });
    }

    if (justPlan) {
      return ({
        pending: siteItem.status_plan === 'pending',
        inAnalysis: siteItem.status_plan === 'in_analysis',
        planIsToDue: siteItem.status_plan === 'to_due',
        planIsOverdue: siteItem.status_plan === 'overdue',
        planSuspended: siteItem.status_plan === 'suspended',
      });
    }

    return ({
      pending: siteItem.status_plan === 'pending' || siteItem.status_domain === 'pending',
      inAnalysis: siteItem.status_plan === 'in_analysis' || siteItem.status_domain === 'in_analysis',
      planIsToDue: substatusToStatus ? siteItem.status_plan === 'to_due' : siteItem.substatus_plan === 'to_due',
      domainIsToDue: substatusToStatus ? siteItem.status_domain === 'to_due' : siteItem.substatus_domain === 'to_due',
      planIsOverdue: substatusToStatus ? siteItem.status_plan === 'overdue' : siteItem.substatus_plan === 'overdue',
      domainIsOverdue: substatusToStatus ? siteItem.status_domain === 'overdue' : siteItem.substatus_domain === 'overdue',
      planSuspended: substatusToStatus ? siteItem.status_plan === 'suspended' : siteItem.substatus_plan === 'suspended',
      domainSuspended: substatusToStatus ? siteItem.status_domain === 'suspended' : siteItem.substatus_domain === 'suspended',
    });
  }, [substatusToStatus, justPlan, withGatorSolutions]);

  const mountWarningStatus = useCallback((allSiteStatus) => {
    if (allSiteStatus.domainSuspended || allSiteStatus.planSuspended) return STATUS_TYPE.SUSPENDED;
    if (allSiteStatus.planIsOverdue || allSiteStatus.domainIsOverdue) return STATUS_TYPE.OVERDUE;
    if (allSiteStatus.domainIsToDue || allSiteStatus.planIsToDue) return STATUS_TYPE.TODUE;
    if (allSiteStatus.inAnalysis) return STATUS_TYPE.ANALYSIS;
    if (allSiteStatus.pending) return STATUS_TYPE.PENDING;
    return STATUS_TYPE.ACTIVE;
  }, []);

  const getSiteStatus = useCallback((siteItem) => {
    const allSiteStatus = getAllSiteStatus(siteItem);
    return mountWarningStatus(allSiteStatus);
  }, [getAllSiteStatus, mountWarningStatus]);

  const validatePlanDomainState = useCallback((siteItem) => {
    const allSiteStatus = getAllSiteStatus(siteItem);
    const warningStatus = mountWarningStatus(allSiteStatus);
    return rules[warningStatus](theme, siteItem, allSiteStatus);
  }, [getAllSiteStatus, mountWarningStatus, theme]);

  const mountCardStatusData = {
    validatePlanDomainState,
    getAllSiteStatus,
    getSiteStatus,
    mountWarningStatus,
  };
  return mountCardStatusData;
};

export default useMountCardStatus;
