import { DnsWizardActionTypes } from './dnsWizard.types';

import { RESERVED_NAME_SERVERS } from '@/enums/domains/dns.enum';

const baseDns = {
  ns: '',
  ip: '',
  valid: false,
  required: false,
};

export const initialState = {
  controls: {
    pageLoading: true,
    domainConfigurationLoading: false,
    hasAvailableHostGatorPlan: false,
    emailsPlatformsLoading: false,
    settedEmailPlataformLoading: false,
    emailsConfigurationLoading: false,
    skippedWizard: false,
    changedPlan: false,
    hasHostGatorPlan: false,
    upgradablePlanId: null,
  },
  hostingPlatforms: {
    platforms: [],
    emailPlatforms: [],
    selectedEmailPlatform: '',
    hostingId: null,
    reserved: null,
  },
  hostGatorAvailablePlans: {
    hostingPlans: [],
  },
  domain: {
    name: null,
    registrar: null,
    platformName: null,
    nameServers: [],
    planDetails: {},
    records: [],
    configurationStatus: {
      isPropagationPeriod: true,
      wasRecentlyConfigured: false,
      isConfiguredThroughWizard: false,
      isConfiguredInHostGator: false,
      isConfiguredInHostGatorWithHostingPlan: false,
      isConfiguredInHostGatorWithCustomDnsZone: false,
      propagating: false,
    },
    proEmail: {
      id: 0,
      isDomainConfigured: false,
    },
  },
  selectedConfiguration: {
    hostingId: null,
  },
  customDnsOptions: {
    master: { ...baseDns, required: true },
    slave1: { ...baseDns, required: true },
    slave2: { ...baseDns },
    slave3: { ...baseDns },
    slave4: { ...baseDns },
  },
};

const dnsWizardReducer = (state = initialState, action = DnsWizardActionTypes.DEFAULT) => {
  switch (action.type) {
    case DnsWizardActionTypes.GET_EMAIL_PLATFORMS_REQUEST: {
      return {
        ...state,
        controls: {
          ...state.controls,
          emailsPlatformsLoading: true,
        },
      };
    }
    case DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM: {
      return {
        ...state,
        controls: {
          ...state.controls,
          settedEmailPlataformLoading: true,
        },
        hostingPlatforms: {
          ...state.hostingPlatforms,
          selectedEmailPlatform: '',
        },
      };
    }
    case DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM_SUCCESS:
    case DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM_FAILURE: {
      return {
        ...state,
        controls: {
          ...state.controls,
          settedEmailPlataformLoading: false,
        },
      };
    }
    case DnsWizardActionTypes.SET_CURRENT_EMAIL_PLATAFORM: {
      return {
        ...state,
        controls: {
          ...state.controls,
          settedEmailPlataformLoading: false,
        },
        hostingPlatforms: {
          ...state.hostingPlatforms,
          selectedEmailPlatform: action.payload.platform_name,
          hostingId: action.payload.hosting_id,
          reserved: action.payload.reserved,
        },
      };
    }
    case DnsWizardActionTypes.GET_EMAIL_PLATFORMS_SUCCESS:
    case DnsWizardActionTypes.GET_EMAIL_PLATFORMS_FAILURE: {
      return {
        ...state,
        controls: {
          ...state.controls,
          emailsPlatformsLoading: false,
        },
      };
    }
    case DnsWizardActionTypes.GET_HOSTING_PLATFORMS_REQUEST: {
      return {
        ...state,
        controls: {
          ...state.controls,
          pageLoading: true,
          domainConfigurationLoading: false,
          hasHostGatorPlan: false,
          upgradablePlanId: null,
          hasAvailableHostGatorPlan: false,
          skippedWizard: false,
        },
        hostingPlatforms: {
          ...state.hostingPlatforms,
        },
        domain: {
          ...state.domain,
          name: null,
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: false,
            isConfiguredThroughWizard: false,
            isConfiguredInHostGator: false,
            isConfiguredInHostGatorWithHostingPlan: false,
            isConfiguredInHostGatorWithCustomDnsZone: false,
          },
        },
      };
    }
    case DnsWizardActionTypes.GET_HOSTING_PLATFORMS_FAILURE:
    {
      return {
        ...state,
        controls: {
          ...state.controls,
          pageLoading: false,
        },
        hostingPlatforms: {
          ...state.hostingPlatforms,
          loading: false,
        },
      };
    }
    case DnsWizardActionTypes.GET_HOSTING_PLATFORMS_SUCCESS: {
      return {
        ...state,
      };
    }
    case DnsWizardActionTypes.SET_EMAIL_PLATFORMS: {
      return {
        ...state,
        hostingPlatforms: {
          ...state.hostingPlatforms,
          emailPlatforms: action.payload.emailPlatforms.availableEmailPlatforms,
        },
        domain: {
          ...state.domain,
          configurationStatus: {
            ...state.domain.configurationStatus,
            propagating: action.payload.emailPlatforms.configurationStatus.propagating,
          },
          proEmail: action.payload.emailPlatforms.proEmail,
        },
      };
    }
    case DnsWizardActionTypes.SET_HOSTING_PLATFORMS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          pageLoading: false,
          hasAvailableHostGatorPlan: action.payload.dnsWizard.hostingPlanStatus.hasAvailableHostGatorPlan,
          hasHostGatorPlan: action.payload.dnsWizard.hostingPlanStatus.hasHostGatorPlan,
          upgradablePlanId: action.payload.dnsWizard.hostingPlanStatus.upgradablePlanId,
        },
        domain: {
          ...state.domain,
          name: action.payload.dnsWizard.domain,
          registrar: action.payload.dnsWizard.registrar,
          platformName: action.payload.dnsWizard.platformName,
          planDetails: { ...action.payload.dnsWizard.planDetails },
          nameServers: action.payload.dnsWizard.nameServers,
          configurationStatus: {
            ...state.domain.configurationStatus,
            isConfiguredThroughWizard: action.payload.dnsWizard.configurationStatus.isConfiguredThroughWizard || false,
            isPropagationPeriod: action.payload.dnsWizard.configurationStatus.isPropagationPeriod || false,
            isConfiguredInHostGator: action.payload.dnsWizard.configurationStatus.isConfiguredInHostGator || false,
            isConfiguredInHostGatorWithHostingPlan: action.payload.dnsWizard.configurationStatus.isConfiguredInHostGatorWithHostingPlan || false,
            isConfiguredInHostGatorWithCustomDnsZone: action.payload.dnsWizard.configurationStatus.isConfiguredInHostGatorWithCustomDnsZone || false,
          },
        },
        hostingPlatforms: {
          ...state.hostingPlatforms,
          platforms: action.payload.dnsWizard.availablePlatforms,
        },
        hostGatorAvailablePlans: {
          ...state.hostGatorAvailablePlans,
          hostingPlans: action.payload.dnsWizard.hostingPlanStatus.availablePlans,
        },
      };
    }
    case DnsWizardActionTypes.PUT_SKIP_WIZARD_REQUEST:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_REQUEST:
    case DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_REQUEST:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_REQUEST:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_REQUEST:
    case DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_REQUEST: {
      return {
        ...state,
        controls: {
          ...state.controls,
          domainConfigurationLoading: true,
          changedPlan: false,
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_FAILURE:
    case DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_FAILURE:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FAILURE:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_FAILURE:
    case DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_FAILURE: {
      return {
        ...state,
        controls: {
          ...state.controls,
          domainConfigurationLoading: false,
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_SUCCESS: {
      return {
        ...state,
        domain: {
          ...state.domain,
          planDetails: state.selectedConfiguration.selectedPlan,
          configurationStatus: {
            ...state.domain.configurationStatus,
          },
        },
      };
    }
    case DnsWizardActionTypes.SET_HOSTING_CONFIGURATION: {
      return {
        ...state,
        controls: {
          ...state.controls,
          domainConfigurationLoading: false,
          skippedWizard: false,
        },
        domain: {
          ...state.domain,
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: true,
            isConfiguredThroughWizard: true,
            isConfiguredInHostGatorWithHostingPlan: true,
            isConfiguredInHostGator: true,
            isConfiguredInHostGatorWithCustomDnsZone: false,
          },
        },
      };
    }
    case DnsWizardActionTypes.SET_SELECTED_CONFIGURATION: {
      return {
        ...state,
        domain: {
          ...state.domain,
        },
        selectedConfiguration: action.payload.selectedConfiguration,
      };
    }
    case DnsWizardActionTypes.PUT_SKIP_WIZARD_SUCCESS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          skippedWizard: true,
          domainConfigurationLoading: false,
        },
        domain: {
          ...state.domain,
          nameServers: [
            RESERVED_NAME_SERVERS.MASTER,
            RESERVED_NAME_SERVERS.SLAVE,
          ],
          planDetails: {},
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: true,
            isConfiguredThroughWizard: true,
            isConfiguredInHostGator: true,
            isConfiguredInHostGatorWithHostingPlan: false,
            isConfiguredInHostGatorWithCustomDnsZone: false,
          },
        },
      };
    }
    case DnsWizardActionTypes.SET_RESERVED_NS: {
      return {
        ...state,
        domain: {
          ...state.domain,
          nameServers: [
            RESERVED_NAME_SERVERS.MASTER,
            RESERVED_NAME_SERVERS.SLAVE,
          ],
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_SUCCESS:
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_SUCCESS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          skippedWizard: false,
          domainConfigurationLoading: false,
        },
        domain: {
          ...state.domain,
          planDetails: {},
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: true,
            isConfiguredThroughWizard: true,
            isConfiguredInHostGator: false,
            isConfiguredInHostGatorWithHostingPlan: false,
            isConfiguredInHostGatorWithCustomDnsZone: false,
          },
        },
      };
    }
    case DnsWizardActionTypes.SET_CUSTOM_DNS_CONFIGURATION: {
      return {
        ...state,
        controls: {
          ...state.controls,
          domainConfigurationLoading: false,
          skippedWizard: false,
        },
        domain: {
          ...state.domain,
          nameServers: action.payload.configuration.nameServers,
          platformName: null,
        },
      };
    }
    case DnsWizardActionTypes.SET_EXTERNAL_PLATFORM_CONFIGURATION: {
      return {
        ...state,
        controls: {
          ...state.controls,
          domainConfigurationLoading: false,
          skippedWizard: false,
        },
        domain: {
          ...state.domain,
          nameServers: action.payload.configuration.nameServers,
          platformName: action.payload.platformName,
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_SUCCESS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          skippedWizard: false,
          domainConfigurationLoading: false,
        },
        domain: {
          ...state.domain,
          platformName: action.platformName,
          planDetails: {},
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: true,
            isConfiguredThroughWizard: true,
            isConfiguredInHostGator: true,
            isConfiguredInHostGatorWithHostingPlan: false,
            isConfiguredInHostGatorWithCustomDnsZone: true,
          },
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_SUCCESS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          skippedWizard: false,
          domainConfigurationLoading: false,
        },
        domain: {
          ...state.domain,
          platformName: action.platformName,
          configurationStatus: {
            ...state.domain.configurationStatus,
            wasRecentlyConfigured: true,
            isConfiguredThroughWizard: true,
            isConfiguredInHostGator: true,
            isConfiguredInHostGatorWithHostingPlan: true,
            isConfiguredInHostGatorWithCustomDnsZone: true,
          },
        },
      };
    }
    case DnsWizardActionTypes.GET_DNS_ZONES_REQUEST: {
      return {
        ...state,
        domain: {
          ...state.domain,
          records: [],
        },
      };
    }
    case DnsWizardActionTypes.SET_DNS_ZONES: {
      return {
        ...state,
        domain: {
          ...state.domain,
          records: action.payload.dnsZones.records,
        },
      };
    }
    case DnsWizardActionTypes.SET_CHANGED_PLAN: {
      return {
        ...state,
        controls: {
          ...state.controls,
          changedPlan: true,
        },
      };
    }
    case DnsWizardActionTypes.RESET_CUSTOM_DNS_CONFIGURATION: {
      return {
        ...state,
        customDnsOptions: {
          ...initialState.customDnsOptions,
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_REQUEST: {
      return {
        ...state,
        controls: {
          ...state.controls,
          emailsConfigurationLoading: true,
        },
        domain: {
          ...state.domain,
          configurationStatus: {
            ...state.domain.configurationStatus,
            propagating: true,
          },
        },
      };
    }
    case DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_SUCCESS:
    case DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_FAILURE: {
      return {
        ...state,
        controls: {
          ...state.controls,
          emailsConfigurationLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default dnsWizardReducer;
