import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  withStyles, Grid, Card, CardContent, Typography, Tooltip, CardActions,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import InfinityIcon from '@material-ui/icons/AllInclusive';
import { withI18n } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import DeleteEmailAccountModal from '@/components/Email/DeleteEmailAccountModal';
import ChangeStorageEmailAccountModal from '@/components/Email/ChangeStorageEmailAccountModal';
import AlterEmailPasswordModal from '@/components/Email/AlterEmailPasswordModal';
import ChipLinkButton from '@/components/Buttons/ChipLinkButton';
import LinkButton from '@/components/Buttons/LinkButton';
import { brokenEmailAddress } from '@/utils/Formatters/handleStrings';
import { emailsTagManager } from '@/utils/ThirdParties/tagManager';
import { handleCheckEmailSso } from '@/config/components/ctas';
import styles from './styles';


const EmailItem = ({
  classes, email, t, theme,
}) => {
  const [openModal, setOpenModal] = useState({
    deleteEmailAccount: false,
    changeStorage: false,
    changePassword: false,
  });
  const loadingDeleteAccount = useSelector(state => state.emails.loadingDeleteAccount);

  let maxCharacters = 38;
  let showToolTip = email.email.length >= maxCharacters;
  const isMediaDesktopBreaking = useMediaQuery(theme.breakpoints.down(1465), {
    defaultMatches: true,
  });
  const isMediaMobileBreaking = useMediaQuery(theme.breakpoints.down(550), {
    defaultMatches: true,
  });

  if (isMediaDesktopBreaking) {
    maxCharacters = 45;
    showToolTip = email.email.length >= maxCharacters;
  }

  if (isMediaMobileBreaking) {
    maxCharacters = 30;
    showToolTip = email.email.length >= maxCharacters;
  }

  const handleOpenModal = (modal) => {
    setOpenModal({
      ...openModal,
      [modal]: true,
    });
  };

  const handleCloseModal = (modal) => {
    if (!loadingDeleteAccount) {
      setOpenModal({
        ...openModal,
        [modal]: false,
      });
    }
  };

  const diskQuota = () => (email.diskUnlimited ? (
    <>
      <InfinityIcon className={classes.infinityIcon} />
      {' '}
      MB
    </>
  ) : (
    <span className={classes.diskQuota}>
      {email.diskQuota}
      {' '}
      MB
    </span>
  ));

  return (
    <Grid item xs={12} className={classes.container} data-testid="emailItem">
      <Card elevation={1} className={classes.borderContent}>
        <CardContent className={classes.cardContainer} data-id="email-card">

          <div className={classes.containerWrapper}>
            <div className={classes.emailAddressWrapper}>
              <EmailIcon className={classes.emailIcon} />
              {showToolTip ? (
                <Tooltip
                  title={email.email}
                  interactive
                  placement="top"
                >
                  <Typography variant="h6" className={classes.emailAddress}>
                    {brokenEmailAddress(email.email, maxCharacters)}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="h6" className={classes.emailAddress} data-id="email-full-name">
                  {brokenEmailAddress(email.email, maxCharacters)}
                </Typography>
              )}
            </div>
            <div className={classes.quotaWrapper}>
              <div>
                <span className={classes.quotaLabel}>{t('emails.storage')}</span>
              </div>
              <div>
                <span className={classes.quotaValue} data-id="email-storage">
                  {email.diskUsed}
                  {' '}
                  /
                  {' '}
                  {diskQuota()}
                </span>
              </div>
            </div>
            <div className={classes.ctasWrapper}>
              <div className={classes.buttonsContainer}>
                <ChipLinkButton
                  status="active"
                  onClick={() => handleOpenModal('changeStorage')}
                  to={`${t('routes.domains')}${t('routes.dns')}/${email.id}`}
                  label={t('emails.changeStorage')}
                  data-id="email-change-storage"
                />
                <ChipLinkButton
                  status="active"
                  onClick={() => handleOpenModal('changePassword')}
                  to={`${t('routes.domains')}${t('routes.dns')}/${email.id}`}
                  label={t('emails.changePassword')}
                  data-id="email-change-password"
                />
                <ChipLinkButton
                  status="active"
                  onClick={() => handleOpenModal('deleteEmailAccount')}
                  label={t('emails.deleteAccount')}
                  data-id="email-delete"
                />
              </div>
            </div>
          </div>

          <div className={classes.actionContainer}>
            <CardActions className={classes.cardActions}>
              <LinkButton
                className={classes.link}
                target="blank"
                onClick={emailsTagManager}
                to={handleCheckEmailSso(email.hostingId, email.email, email.packageId)}
                data-id="email-management"
              >
                {t('emails.seeEmail')}
              </LinkButton>
            </CardActions>
          </div>
        </CardContent>
      </Card>

      <DeleteEmailAccountModal
        openModal={openModal.deleteEmailAccount}
        handleCloseModal={handleCloseModal}
        hostingId={email.hostingId}
        emailAddress={email.email}
      />

      <ChangeStorageEmailAccountModal
        openModal={openModal.changeStorage}
        handleCloseModal={handleCloseModal}
        email={email}
      />

      <AlterEmailPasswordModal
        openModal={openModal.changePassword}
        handleCloseModal={handleCloseModal}
        hostingId={email.hostingId}
        emailAddress={email.email}
      />
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(withI18n()(EmailItem));
