const styles = theme => ({
  '@keyframes cardTransition': {
    from: {
      opacity: 0,
      transform: 'translateY(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  container: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    animationName: 'cardTransition',
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-out',
    cursor: 'pointer',
    '&:hover $link': {
      transform: 'translateX(10px)',
      color: theme.color.chambray,
    },
    [theme.breakpoints.down(1150)]: {
      gridColumn: '1 / 3',
    },
  },
  notWatched: {
    borderLeftColor: theme.color.indigo,
  },
  watched: {
    borderLeftColor: theme.color.oceanGreen,
  },
  articleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '11px 22px 11px 11px',
  },
  containerSmall: {
    padding: '11px 12px 11px 11px',
  },
  figure: {
    margin: '0px 23px 0px 0px ',
    padding: 0,
    height: 110,
    '& img': {
      width: 195,
      height: 110,
      objectFit: 'cover',
    },
  },
  figureDecrease: {
    margin: '0px 23px 0px 0px ',
    padding: 0,
    height: 87,
    '& img': {
      width: 155,
      height: 'unset',
      objectFit: 'contain',
    },
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentWrapperSmall: {
    position: 'relative',
    alignItems: 'flex-end',
    flexDirection: 'column',
    width: '100%',
    height: 87,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentSmall: {
    width: '100%',
  },
  title: {
    display: '-webkit-box',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.2',
    maxWidth: '100%',
    maxHeight: 38,
    color: theme.color.tundora,
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: '...',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  description: {
    fontSize: 12,
    color: theme.color.tundora,
    marginBottom: 7,
    paddingRight: 40,
    maxWidth: '100%',
    maxHeight: 42,
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: '...',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 5,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 11,
    '& svg': {
      color: theme.color.regentGray,
      fontSize: 14,
    },
    '& span': {
      display: 'inline-block',
      color: theme.color.regentGray,
      fontSize: 14,
      marginLeft: 5,
    },
  },
  label: {
    width: 'fit-content',
    borderRadius: 2,
    backgroundColor: theme.color.oceanGreen,
    fontSize: 12,
    color: theme.color.white,
    padding: '2px 6px',
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    height: 'fit-content',
  },
  linkWrapperSmall: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.color.indigo,
    fontWeight: 500,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: 12,
    transition: 'transform 0.3s ease-out 0.01s, color 0.3s ease-out 0.01s',
  },
});

export default styles;
