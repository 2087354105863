const styles = theme => ({
  listWrapper: {
    borderRadius: 5,
    maxHeight: '370px',
    overflowX: 'scroll',
    overflowY: 'scroll',
    padding: '0px 6px 0px',
    width: '100%',

    '& > div:first-child': {
      borderTop: 0,
    },

    '&::-webkit-scrollbar': {
      height: 8,
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.color.silver,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: theme.color.indigo,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.indigo,
    },

  },
});

export default styles;
