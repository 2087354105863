import React from 'react';
import { CardContent, withStyles, Card } from '@material-ui/core';
import SupportFormVue from '@/components/SupportFormVue';
import { config } from '@/config';
import { ContentContainer } from '../Cards/ContentContainer';
import styles from './styles';


const VueSupportForms = ({ classes }) => (
  <ContentContainer className={classes.root}>
    <Card className={classes.cardContainer}>
      <CardContent className={classes.cardContent}>
        <div id="first-loading" className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
        <div className="bootstrap-wrapper">
          <input type="hidden" id="base_url" value={`${config.WHMCS_URL}`} />
          <input type="hidden" id="disabledCancelSolicitationOption" value />

          <SupportFormVue />
        </div>
      </CardContent>
    </Card>
  </ContentContainer>
);

export default withStyles(styles, { withTheme: true })(VueSupportForms);
