import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  min-height: 400px;
  padding: 24px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    margin: 0;
  `}
`;

export const Warning = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.error.ultralight};
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 24px;
    position: relative;
  `}
`;

export const Attention = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;

    svg {
      fill: ${theme.palette.error.primary};
    }
  `}
`;

export const AttentionLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    margin-left: 12px;
  `}
`;

export const Content = styled.div``;

export const ListItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    padding-left: 16px;
    position: relative;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:before {
      background-color: ${theme.palette.grey.dark};
      border-radius: 50%;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(70%);
      width: 8px;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    padding: 0 0 0 30px;

    li {
      color: ${theme.palette.grey.dark};
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.font.weights.regular};
      padding-left: 16px;
      position: relative;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:before {
        background-color: ${theme.palette.grey.dark};
        border-radius: 50%;
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateY(70%);
        width: 8px;
      }
    }
  `}
`;

export const Links = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-left: 30px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 32px 0 0 46px;
      flex-direction: column;
    }
  `}
`;

export const LinksItem = styled.div`
  ${({ theme }) => css`
    align-items: self-end;
    display: flex;
    padding: 0 16px;

    &:not(:first-child) {
      border-left: 1px solid ${theme.palette.grey.darklight}
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;

      &:not(:first-child) {
        border-left: 0;
        margin-top: 24px;
      }
    }
  `}
`;
