import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Search from '@/components/Tickets/Search';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import { loadReCaptcha } from '@/utils/Application/reCaptcha';
import styles from './styles';
import PageMessages from '../common/PageMessages';
import { TicketOpenModal } from '@/components/Tickets/TicketOpenModal';
import { PageTitle, Button, Link } from 'gatorcomponents';
import * as Styles from './SupportPage.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@/components/Layout/Loader';
import { useHistory } from 'react-router';
import { useTickets } from '@/hooks/tickets';
import { domainRegexWithUpperCase } from '@/utils/Validators/validation';
import { TicketsDescription } from './components/TicketsDescription/TicketsDescription';
import { TicketItems } from './components/TicketItems/TicketItems';
import { useHelpLinks } from '@/hooks/helpLinks';
import { LinksDescription } from './components/LinksDescription/LinksDescription';
import { VideoClasses } from '@/components/VideoClasses';
import {
  CHARLIE_ZENDESK_ALLOW_OLD_VERSION, HIDE_CHAT_BUTTON, NEW_SUPPORT, REMOVE_TICKETS,
} from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { preChatActions } from '@/redux/modules';

const SupportPage = ({ classes }) => {
  const history = useHistory();
  const [filteredTickets, setFilteredTickets] = useState([]);
  const shouldHideTicketsList = useFeatureIsOn(REMOVE_TICKETS);
  const allowOldZendeskTickets = useFeatureIsOn(CHARLIE_ZENDESK_ALLOW_OLD_VERSION);
  const hideChatButton = useFeatureIsOn(HIDE_CHAT_BUTTON);
  const useNewSupport = useFeatureIsOn(NEW_SUPPORT);
  const ticketsReducer = useSelector(state => state.tickets);
  const dispatch = useDispatch();

  const {
    defaultFilterChips: defaulTicketsFilterChips,
    requestTickets,
    requestTicketsV2,
    filteredOptions: filteredTicketsOptions,
    setFilteredOptions: setFilteredTicketsOptions,
    filterTyped: ticketsFilterTyped,
    setFilterTyped: ticketsSetFilterTyped,
    filterTickets,
    setTicketsArray,
    pendingAmount: ticketsPendingAmount,
    loadMore: ticketsLoadMore,
    totalTickets,
  } = useTickets({
    setFilteredTickets,
    ticketsReducer,
  });

  const {
    defaultFilterChips: linksDefaultFilterChips,
    filterOptions: linksFilterOptions,
    setFilterOptions: linksSetFilterOptions,
    filterLinks,
    filteredLinks,
    requestFaq,
    faqQuestions,
    setFaq,
  } = useHelpLinks();

  const faqLoading = !faqQuestions.mainSupportPage.loaded
    || !faqQuestions.financialSupportPage.loaded
    || !faqQuestions.dnsSupportPage.loaded
    || !faqQuestions.mailSupportPage.loaded
    || !faqQuestions.siteSupportPage.loaded;

  const shouldRenderLoader = ticketsReducer.pending.loading
    || ticketsReducer.pending.loadingV2
    || ticketsReducer.closed.loading
    || ticketsReducer.closed.loadingV2
    || faqLoading;

  const { ticket: ticketLocale, routes: routesLocale } = useLocale();

  useEffect(() => {
    setFaq();
  }, [setFaq]);

  useEffect(() => {
    requestFaq();
  }, [requestFaq]);

  useEffect(() => {
    filterTickets();
  }, [filterTickets]);

  useEffect(() => {
    filterLinks();
  }, [filterLinks]);

  useEffect(() => {
    loadReCaptcha();
    if (allowOldZendeskTickets) {
      requestTickets();
    }
    requestTicketsV2();
  }, [requestTickets, requestTicketsV2, allowOldZendeskTickets]);

  useEffect(() => {
    setTicketsArray();
  }, [setTicketsArray]);

  const handleCreateNewTicket = () => {
    history.push(`${routesLocale.help}${routesLocale.supportforms}`);
  };

  const handleOpenChat = () => {
    dispatch(preChatActions.preChat.handleChatDisplay());
  };

  const getPageFaqTitleProps = () => {
    const defaultProps = {
      testId: 'faqV2',
      title: ticketLocale.listPageFaq.title,
      filterLabel: ticketLocale.listPageFaq.filterLabel,
      filterByPlaceholder: ticketLocale.listPageFaq.filterByPlaceholder,
      filterByValue: '',
      clearFilterLabel: ticketLocale.listPageFaq.clearFilterLabel,
      filterChips: linksFilterOptions,
      hideFilter: false,
      showClearFilter: false,
    };

    const handleClearFilter = () => {
      linksSetFilterOptions(linksDefaultFilterChips);
      ticketsSetFilterTyped('');
    };

    const clearOptions = () => {
      const newOptions = [...linksFilterOptions].map((option) => {
        const newOption = { ...option };
        newOption.checked = false;
        return newOption;
      });
      return newOptions;
    };

    const handleChangeChip = (chip) => {
      const newOptions = clearOptions();
      const chipIndex = newOptions.findIndex(option => option.id === chip.id);
      if (!chip.checked) {
        newOptions[0].checked = true;
      }

      if (chip.checked) {
        newOptions[chipIndex].checked = true;
      }
      linksSetFilterOptions(newOptions);
    };

    const handleChangeFilterBy = (text) => {
      ticketsSetFilterTyped(text);
    };

    const getSearchProps = () => ({
      v2: {
        placeholder: ticketLocale.listPageFaq.filterByPlaceholder,
      },
    });

    return {
      ...defaultProps,
      handleClearFilter,
      handleChangeChip,
      handleChangeFilterBy,
      searchReplacement: <Search {...getSearchProps()} />,
    };
  };

  const getPageListTitleProps = () => {
    const defaultProps = {
      testId: 'listV2',
      title: ticketLocale.listPage.title,
      filterLabel: ticketLocale.listPage.filterLabel,
      filterByPlaceholder: ticketLocale.listPage.filterByPlaceholder,
      filterByValue: ticketsFilterTyped,
      clearFilterLabel: ticketLocale.listPage.clearFilterLabel,
      filterChips: filteredTicketsOptions,
      hideFilter: false,
    };

    const handleClearFilter = () => {
      setFilteredTicketsOptions(defaulTicketsFilterChips);
      ticketsSetFilterTyped('');
    };

    const clearOptions = () => {
      const newOptions = [...filteredTicketsOptions].map((option) => {
        const newOption = { ...option };
        newOption.checked = false;
        return newOption;
      });
      return newOptions;
    };

    const handleChangeChip = (chip) => {
      const newOptions = clearOptions();
      const chipIndex = newOptions.findIndex(option => option.id === chip.id);
      if (!chip.checked) {
        newOptions[0].checked = true;
      }

      if (chip.checked) {
        newOptions[chipIndex].checked = true;
      }
      setFilteredTicketsOptions(newOptions);
    };

    const handleChangeFilterBy = (text) => {
      ticketsSetFilterTyped(text);
    };

    return {
      ...defaultProps,
      handleChangeFilterBy,
      handleChangeChip,
      handleClearFilter,
    };
  };

  const getTicketItemsProps = () => {
    const handleOpenTicket = ({ id, v2 }) => {
      history.push(`${routesLocale.ticket}/${id}${v2 ? '/v2' : ''}`);
    };

    return ({
      filteredTickets,
      domainRegexWithUpperCase,
      ticketLocale,
      handleOpenTicket,
      empty: ticketLocale.listPage.empty,
    });
  };

  const getLoaderProps = () => ({
    'data-testid': 'loader-tickets',
  });

  const getTicketsDescription = () => ({
    pendingAmount: ticketsPendingAmount,
    ticketLocale,
    handleCreateNewTicket,
    handleOpenChat,
    hideChatButton: !useNewSupport && hideChatButton,
  });

  const getLinksDescription = () => ({
    ticketLocale,
    handleCreateNewTicket,
  });

  if (shouldRenderLoader) {
    return <Loader {...getLoaderProps()} />;
  }

  return (
    <>
      <div className={classes.container} data-testid="support-page-component">
        <PageMessages
          displayDomainAlertManager={false}
          displayDomainPropagationAlert
        />
        <ContentContainer className={classes.content}>
          <VideoClasses asBanner />
          <Styles.FilterWrapper>
            <PageTitle {...getPageFaqTitleProps()} />
          </Styles.FilterWrapper>

          <Styles.LinksContainer>
            <LinksDescription {...getLinksDescription()} />
            <Styles.LinksList>
              {filteredLinks.map(questions => (
                <Link
                  key={questions.id}
                  href={questions.link}
                  target="_blank"
                  text={questions.question}
                />
              ))}
            </Styles.LinksList>
          </Styles.LinksContainer>

          {shouldHideTicketsList
            ? (
              <Styles.UnderMaintenance>
                {ticketLocale.underMaintenance}
              </Styles.UnderMaintenance>
            )
            : (
              <>
                <Styles.FilterWrapper>
                  <PageTitle {...getPageListTitleProps()} />
                </Styles.FilterWrapper>

                <Styles.TicketsContainer>
                  <TicketsDescription {...getTicketsDescription()} />
                  <Styles.TicketsList>
                    <TicketItems {...getTicketItemsProps()} />
                  </Styles.TicketsList>
                </Styles.TicketsContainer>

                {(filteredTickets && filteredTickets.length < totalTickets) && (
                  <Styles.LoadMoreWrapper>
                    <Button
                      variant="secondary"
                      label={ticketLocale.listPage.loadMoreLabel}
                      onClick={ticketsLoadMore}
                    />
                  </Styles.LoadMoreWrapper>
                )}
              </>
            )
          }

        </ContentContainer>
      </div>
      <TicketOpenModal />
    </>
  );
};

export default (withStyles(styles, { withTheme: true })((SupportPage)));
