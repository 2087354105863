/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from 'react';
import * as Styles from './SiteAlert.styles';
import useLocale from '@/hooks/useLocale';
import { Alert } from 'gatorcomponents';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS } from '@/config/GrowthBook/constants';

const SiteAlert = ({
  type,
  alertVariant,
  customIcon,
  status,
  handleClick,
  daysForPayment,
  sslAnalytics,
  showSSLModal,
  newSitesAlert,
  groupMainDomain,
  invoiceId,
}) => {
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);

  useEffect(() => {
    if (type === 'ssl') {
      sslAnalytics && sslAnalytics.sslTags({ status });
    }
  }, [sslAnalytics, status, type]);

  const { sites: sitesLocale } = useLocale();


  const alertDescription = (
    <Styles.Description newSitesAlert={newSitesAlert}>
      <Styles.Text>
        {newSitesAlert
          ? sitesLocale.page.newAlerts[type][status].label({ withGatorSolutions, daysForPayment, groupMainDomain })
          : sitesLocale.page.alerts[type][status].label({ daysForPayment })
        }
      </Styles.Text>

      {(type === 'ssl' ? showSSLModal : true) && sitesLocale.page.alerts[type][status].button && sitesLocale.page.alerts[type][status].button({
        withGatorSolutions,
        handleClick,
        invoiceId,
      })}
    </Styles.Description>
  );

  if (newSitesAlert) {
    return (
      <Alert
        description={alertDescription}
        variant={alertVariant}
        customIcon={customIcon && customIcon}
        width="full"
      />
    );
  }

  return (
    <Styles.Wrapper>
      <Alert
        description={alertDescription}
        variant={alertVariant}
        withoutBackground="true"
        customIcon={customIcon && customIcon}
      />
    </Styles.Wrapper>
  );
};

export default SiteAlert;
