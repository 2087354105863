import React from 'react';
import { Button, Alert, Stepper } from 'gatorcomponents';
import { Tag } from '@/pages/common/Tag';
import * as Styles from './SubscriptionResume.styles';
import { SubscriptionResumeProps } from './SubscriptionResume.types';
import { IconInfo } from '@/icons';
import { Tooltip } from '@/pages/common/v1';
import { format, parseISO } from 'date-fns';
import { isBRServer } from '@/utils/Validators/validation';

const SubscriptionResume = ({
  loading = false,
  paymentCycleLabel = 'Subscription cycle',
  paymentCycleText = '',
  productNameLabel = 'Product',
  productNameText = '',
  registerDateLabel = 'Contract date',
  registerDateText = '',
  resumeTitleLabel = 'Subscription Summary',
  showPaymentCycle = false,
  statusBackgroundColor = '#a4a4a4',
  statusLabel = 'Status',
  statusText = '',
  statusTextColor = '#ffffff',
  reactivationButtonLabel = '',
  reactivationTooltipLabel = '',
  trustPeriodLabel = '',
  trustPeriodDescriptionLabel = '',
  onDisplayReactivationModal,
  trustReactivationAvailable = false,
  overrideDate,
  trustReactivationOnTheCycle,
  alreadyReactivatedTooltip,
  shouldRenderAlreadyReactivatedTooltip,
  orderUnderReview,
  summary,
  orderStatusLocale,
  substatus,
  paymentMethod,
  registerDate,
  isEcommercePlus = false,
  enabledEcommercePlusCycleChange = false,
  ecommercePlusChangeCycleLabel = '',
  onEcommercePlusCycleChangeClick = () => {},
}) => {
  const isCreditCard = paymentMethod === 'Cartão de crédito';
  const paymendMethodType = isCreditCard ? 'creditcard' : 'other';
  const buttonProps = () => ({
    onClick: onDisplayReactivationModal,
    label: reactivationButtonLabel,
    testId: 'subscription-reactivation-button',
    disabled: trustReactivationOnTheCycle,
  });
  const buttonEcommercePlusProps = () => ({
    onClick: onEcommercePlusCycleChangeClick,
    label: (loading || !ecommercePlusChangeCycleLabel) ? '...' : ecommercePlusChangeCycleLabel,
    testId: 'ecommerce-plus-change-cycle-button',
  });

  const hasDate = date => date && date !== '0000-00-00 00:00:00';

  const showEcommercePlusCycleChange = substatus === 'active' && isEcommercePlus && enabledEcommercePlusCycleChange;

  const getAnalysisStatus = () => {
    if (isCreditCard && hasDate(orderUnderReview.date_order) && orderUnderReview.status === 'pending_analysis') {
      return 'ongoing';
    }

    if (!isCreditCard && (!hasDate(orderUnderReview.date_paid) || !hasDate(orderUnderReview.date_reg_analysis))) {
      return 'pending';
    }

    if (orderUnderReview.status === 'cancelled') {
      return 'finished';
    }

    return 'ongoing';
  };

  const getStepperProps = () => {
    const defaultProps = {
      direction: 'column',
      statusColor: ((orderUnderReview && orderUnderReview.status === 'pending_analysis')
      || substatus === 'pending') ? 'pendingMedium' : 'lowLight',
    };

    const itemsWithOrder = orderUnderReview && [
      {
        id: 0,
        status: 'finished',
        text: {
          title: orderStatusLocale.buy.title,
          content: orderStatusLocale.buy.content[paymendMethodType]({ paymentMethod }),
          lightContent: orderStatusLocale.buy.lightContent({
            date: format(new Date(orderUnderReview.date_order), 'dd/MM/yyyy'),
            time: format(new Date(orderUnderReview.date_order), 'HH:mm'),
          }),
        },
      },
      {
        id: 1,
        status: (hasDate(orderUnderReview.date_paid) || isCreditCard) ? 'finished' : 'ongoing',
        text: {
          title: (hasDate(orderUnderReview.date_paid) || isCreditCard)
            ? orderStatusLocale.paid.finished.title[paymendMethodType]
            : orderStatusLocale.paid.ongoing.title,
          content: (hasDate(orderUnderReview.date_paid) || isCreditCard)
            ? orderStatusLocale.paid.finished.content[paymendMethodType]
            : orderStatusLocale.paid.ongoing.content,
          lightContent: hasDate(orderUnderReview.date_paid)
            ? orderStatusLocale.paid.finished.lightContent({
              date: format(new Date(orderUnderReview.date_paid), 'dd/MM/yyyy'),
              time: format(new Date(orderUnderReview.date_paid), 'HH:mm'),
            }) : null,
        },
      },
      {
        id: 2,
        status: getAnalysisStatus(),
        text: {
          title: orderStatusLocale.analysing[hasDate(orderUnderReview.date_update_analysis) ? 'cancelled' : 'pending'].title,
          lightTitle: orderStatusLocale.analysing[hasDate(orderUnderReview.date_update_analysis) ? 'cancelled' : 'pending'].lightTitle,
          content: orderStatusLocale.analysing[hasDate(orderUnderReview.date_update_analysis) ? 'cancelled' : 'pending'].content,
          lightContent: hasDate(orderUnderReview.date_update_analysis)
            ? orderStatusLocale.analysing.cancelled.lightContent({
              date: format(new Date(orderUnderReview.date_update_analysis), 'dd/MM/yyyy'),
              time: format(new Date(orderUnderReview.date_update_analysis), 'HH:mm'),
            })
            : orderStatusLocale.analysing.pending.lightContent,
        },
      },
      {
        id: 3,
        status: orderUnderReview.status === 'cancelled' ? 'error' : 'pending',
        text: {
          title: orderUnderReview.status === 'cancelled'
            ? orderStatusLocale.finalStatus.cancelled.title
            : orderStatusLocale.finalStatus.pending.title,
          lightTitle: orderUnderReview.status === 'cancelled' ? null : orderStatusLocale.finalStatus.pending.lightTitle,
          content: orderUnderReview.status === 'cancelled'
            ? orderStatusLocale.finalStatus.cancelled.content[paymendMethodType]({ paymentMethod, email: summary.email })
            : null,
          lightContent: orderUnderReview.status === 'cancelled'
            ? orderStatusLocale.finalStatus.cancelled.lightContent({
              date: format(new Date(orderUnderReview.date_update_analysis), 'dd/MM/yyyy'),
              time: format(new Date(orderUnderReview.date_update_analysis), 'HH:mm'),
            }) : orderStatusLocale.finalStatus.pending.lightContent[paymendMethodType]({
              email: summary.email,
            }),
        },
      },
    ];

    const itemsWithoutOrder = [
      {
        id: 0,
        status: 'finished',
        text: {
          title: orderStatusLocale.buy.title,
          content: orderStatusLocale.buy.content[paymendMethodType]({ paymentMethod }),
          lightContent: orderStatusLocale.buy.lightContent({
            date: registerDate && format(parseISO(registerDate), 'dd/MM/yyyy'),
          }),
        },
      },
      {
        id: 1,
        status: 'ongoing',
        text: {
          title: orderStatusLocale.paid.ongoing.title,
          content: orderStatusLocale.paid.ongoing.content,
        },
      },
      {
        id: 2,
        status: 'pending',
        text: {
          title: orderStatusLocale.analysing.pending.title,
          lightTitle: orderStatusLocale.analysing.pending.lightTitle,
          content: orderStatusLocale.analysing.pending.content,
          lightContent: orderStatusLocale.analysing.pending.lightContent,
        },
      },
      {
        id: 3,
        status: 'pending',
        text: {
          title: orderStatusLocale.finalStatus.pending.title,
          lightTitle: orderStatusLocale.finalStatus.pending.lightTitle,
          lightContent: orderStatusLocale.finalStatus.pending.lightContent[paymendMethodType]({
            email: summary.email,
          }),
        },
      },
    ];

    const items = orderUnderReview ? itemsWithOrder : itemsWithoutOrder;


    return {
      ...defaultProps,
      items,
    };
  };

  const shouldRenderStepper = !loading
  && (substatus === 'pending' || substatus === 'cancelled')
  && true
  && isBRServer;

  return (
    <Styles.Wrapper data-testid="subscription-resume">
      <Styles.Title data-testid="subscription-resume-title">
        {resumeTitleLabel}
      </Styles.Title>

      <Styles.Content>

        <Styles.Item>
          <Styles.Label data-testid="subscription-resume-productname-label">
            {productNameLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-resume-productname-text">
            {(loading || !productNameText) ? '...' : productNameText}
          </Styles.Text>
        </Styles.Item>

        <Styles.Item align={loading ? 'center' : 'initial'}>
          <Styles.Label data-testid="subscription-resume-status-label">
            {statusLabel}
          </Styles.Label>
          {(loading || !statusText)
            ? (
              <Styles.Text data-testid="subscription-resume-status-text">
                ...
              </Styles.Text>
            )
            : (
              <Styles.TagWrapper>
                <Tag
                  bgColor={statusBackgroundColor}
                  color={statusTextColor}
                  label={statusText}
                  testId="subscription-resume-status-text"
                />
              </Styles.TagWrapper>
            )
          }
        </Styles.Item>

        <Styles.Item>
          <Styles.Label data-testid="subscription-resume-registerdate-label">
            {registerDateLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-resume-registerdate-text">
            {(loading || !registerDateText) ? '...' : registerDateText}
          </Styles.Text>
        </Styles.Item>

        {showPaymentCycle && (
        <Styles.Item>
          <Styles.Label data-testid="subscription-resume-paymentcycle-label">
            {paymentCycleLabel}
          </Styles.Label>
          <Styles.Text data-testid="subscription-resume-paymentcycle-text">
            {(loading || !paymentCycleText) ? '...' : paymentCycleText}
          </Styles.Text>
        </Styles.Item>
        )}

        {showEcommercePlusCycleChange && (
          <Styles.Item>
            <Button {...buttonEcommercePlusProps()} />
          </Styles.Item>
        )}

        {!loading && statusText && (
        <>
          {(trustReactivationAvailable || (trustReactivationOnTheCycle && shouldRenderAlreadyReactivatedTooltip)) && (
          <Styles.Item>
            <Styles.ReactivationWrapper>
              {trustReactivationOnTheCycle
                ? (
                  <Tooltip
                    content={alreadyReactivatedTooltip}
                    preferredPosition="right"
                    testId="reactivation-tooltip"
                  >
                    <Button {...buttonProps()} />
                  </Tooltip>
                )
                : (
                  <Button {...buttonProps()} />
                )
              }
              {!trustReactivationOnTheCycle && (
                <Styles.TooltipWrapper>
                  <Tooltip
                    content={reactivationTooltipLabel}
                    preferredPosition="right"
                    testId="subscription-reactivation-tooltip"
                  >
                    <IconInfo testId="subscription-reactivation-tooltip-icon" />
                  </Tooltip>
                </Styles.TooltipWrapper>
              )}
            </Styles.ReactivationWrapper>
          </Styles.Item>
          )}
          {!trustReactivationAvailable && overrideDate && (
          <Styles.Item>
            <Alert customIcon="IconInfo" variant="success" title={trustPeriodLabel} description={trustPeriodDescriptionLabel(overrideDate)} withoutBackground />
          </Styles.Item>
          )}
        </>
        )}
      </Styles.Content>


      {shouldRenderStepper && (
        <Styles.StepperWrapper>
          <Stepper {...getStepperProps()} />
        </Styles.StepperWrapper>
      )}

    </Styles.Wrapper>
  );
};

SubscriptionResume.propTypes = SubscriptionResumeProps;

export default SubscriptionResume;
