import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import { IconError, IconArrowDownOutlined } from '@/icons';
import * as Styles from './HostGatorEmailModal.styles';
import { color, v1 } from '@/styles/HgThemeProvider';
import { Button } from '@/pages/common/v1/Button';
import { splitDomainAndTld } from '@/utils/Formatters/splitDomainAndTld';

const HostGatorEmailModal = ({
  testId = 'customdnszonemodal',
  emailModal = false,
  handleClose = () => {},
  handleConfigureEmail = () => {},
  firstTimeConfigure = false,
  modalsLocale = {
    title: 'title',
    subtitle: () => null,
    gmailSubtitle: () => null,
    alertMessageText: 'alertMessageText',
    checkboxText: 'checkboxText',
    buttonText: 'buttonText',
    alertMessage: () => {},
    tableHeaders: {
      type: '',
      value: '',
      name: '',
    },
  },
  platform = {
    dnsDefaultEntries: [],
    platformID: 'uuid',
    platformName: '',
  },
  domainName = '',
  propagationHours = '??',
  analytics = {
    dnsWizardEmailModalHideRecords: () => {},
    dnsWizardEmailModalShowRecords: () => {},
    dnsWizardEmailModalClose: () => {},
    dnsWizardEmailModalCheckbox: () => {},
    dnsWizardEmailModalHelp: () => {},
    dnsWizardEmailModalConfigure: () => {},
  },
  domainIp = '',
  reserved = false,
}) => {
  const { platformID: platformId, dnsDefaultEntries, platformName } = platform;
  const displayCheckbox = !firstTimeConfigure && (platform.platformName === 'Gmail' && reserved === true) === false;
  const {
    title,
    subtitle,
    gmailSubtitle,
    configureButtonLabel,
    showButtonLabel,
    hideButtonLabel,
    tableHeaders,
    checkboxText,
    alertMessage,
  } = modalsLocale;

  const {
    dnsWizardEmailModalHideRecords,
    dnsWizardEmailModalShowRecords,
    dnsWizardEmailModalClose,
    dnsWizardEmailModalCheckbox,
    dnsWizardEmailModalHelp,
    dnsWizardEmailModalConfigure,
  } = analytics;

  const { mustard } = color;

  const [openDnsRecordsTable, setOpenDnsRecordsTable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [hoverColor, setHoverColor] = useState(v1.color.action.primary);
  const buttonIsDisabled = !firstTimeConfigure && !checked && !reserved;
  const handleChange = () => {
    dnsWizardEmailModalCheckbox(platformName);
    setChecked(!checked);
  };


  const [domain, tld] = splitDomainAndTld(domainName);

  const {
    type: headerType, value: headerValue, name: headerName,
  } = tableHeaders;

  const handleSubmit = () => {
    handleConfigureEmail(platformId);
    dnsWizardEmailModalConfigure(platformName);
    setOpenDnsRecordsTable(false);
    handleClose();
    setChecked(false);
  };

  const handleCloseModal = () => {
    dnsWizardEmailModalClose(platformName);
    setOpenDnsRecordsTable(false);
    handleClose();
    setChecked(false);
  };

  const dnsRecordsTableSwitch = () => {
    if (openDnsRecordsTable) {
      dnsWizardEmailModalHideRecords(platformName);
    } else {
      dnsWizardEmailModalShowRecords(platformName);
    }
    setOpenDnsRecordsTable(!openDnsRecordsTable);
  };

  const formatRecordField = text => `${text}`.replace('{domain}', domain).replace('{tld}', tld).replace('{hosting-ip}', domainIp);
  const label = openDnsRecordsTable ? (
    <Styles.TextWrapper data-testid="dns-table-close-button">
      {hideButtonLabel}
      <Styles.IconWrapper orientation="down">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  ) : (
    <Styles.TextWrapper data-testid="dns-table-open-button">
      {showButtonLabel}
      <Styles.IconWrapper orientation="up">
        <IconArrowDownOutlined color={hoverColor} />
      </Styles.IconWrapper>
    </Styles.TextWrapper>
  );

  const dnsRecordsTable = (
    <>

      <Styles.DnsRecordsTable>
        <tr>
          <th>{headerType}</th>
          <th>{headerValue}</th>
          <th>{headerName}</th>
        </tr>
        {dnsDefaultEntries && dnsDefaultEntries.map(e => (
          <tr>
            <td>{formatRecordField(e.type)}</td>
            <td>
              <dl>
                {
                Object.keys(e).filter(field => (field === 'address' || field === 'cname' || field === 'exchange' || field === 'txt_data')).map(key => (
                  <div>
                    <dd title={formatRecordField(e[key])}>
                      {`${formatRecordField(e[key])}`.slice(0, 35)}
                      {`${formatRecordField(e[key])}`.length > 35 && '...'}
                    </dd>
                  </div>
                ))
                }
              </dl>
            </td>
            <td title={formatRecordField(e.name)}>
              {`${formatRecordField(e.name)}`.slice(0, 35)}
              {`${formatRecordField(e.name)}`.length > 35 && '...'}
            </td>
          </tr>
        ))}
      </Styles.DnsRecordsTable>
      <Styles.DnsRecordsTableSmall>
        {platform && platform.dnsDefaultEntries.map(e => (
          <table>
            <tr>
              <th>{headerType}</th>
              <td>{formatRecordField(e.type)}</td>
            </tr>
            <tr>
              <th>{headerValue}</th>
              {
            Object.keys(e).filter(field => (field === 'address' || field === 'cname' || field === 'exchange' || field === 'txt_data')).map(key => (
              <div>
                <dd title={formatRecordField(e[key])}>
                  {`${formatRecordField(e[key])}`.slice(0, 35)}
                  {`${formatRecordField(e[key])}`.length > 35 && '...'}
                </dd>
              </div>
            ))
            }
            </tr>
            <tr>
              <th>{headerName}</th>
              <td title={formatRecordField(e.name)}>
                {`${formatRecordField(e.name)}`.slice(0, 25)}
                {`${formatRecordField(e.name)}`.length > 25 && '...'}
              </td>
            </tr>
          </table>
        ))}
      </Styles.DnsRecordsTableSmall>
    </>
  );

  const subtitleText = platformName === 'Gmail'
    ? gmailSubtitle(platformName, domainName, () => dnsWizardEmailModalHelp(platformName))
    : subtitle(platformName, domainName, () => dnsWizardEmailModalHelp(platformName));

  return (
    <>
      {emailModal
      && (
        <Modal onClose={handleCloseModal} maxWidth={780}>
          <Styles.HostGatorOtherPlatformModalWrapper data-testid={testId}>
            <Styles.TitleWrapper data-testid="customdnszonemodal-title">
              <Styles.Title>{title(platformName)}</Styles.Title>
              <Styles.SubTitle data-testid="customdnszonemodal-subtitle">
                {subtitleText}
              </Styles.SubTitle>
            </Styles.TitleWrapper>
            <Styles.ButtonWrapper
              onMouseEnter={() => setHoverColor(v1.color.white.primary)}
              onMouseLeave={() => setHoverColor(v1.color.action.primary)}
            >
              <Button label={label} onClick={dnsRecordsTableSwitch} variant="secondary" />
            </Styles.ButtonWrapper>
            {openDnsRecordsTable && dnsRecordsTable}
            <Styles.MessageWrapper data-testid="customdnszonemodal-message">
              <IconError size="24" color={mustard} />
              {alertMessage(propagationHours, () => dnsWizardEmailModalHelp(platformName))}
            </Styles.MessageWrapper>
            {displayCheckbox && (
              <Styles.CheckboxWrapper>
                <input type="checkbox" checked={checked} onChange={handleChange} data-testid="checkbox" />
                {checkboxText()}
              </Styles.CheckboxWrapper>
            )}
            <Styles.ButtonWrapper>
              <Button disabled={buttonIsDisabled} label={configureButtonLabel} onClick={handleSubmit} />
            </Styles.ButtonWrapper>
          </Styles.HostGatorOtherPlatformModalWrapper>
        </Modal>
      )
    }
    </>
  );
};

export default HostGatorEmailModal;
