import React from 'react';
import * as Style from './IconEyePasswordDisabled.style';

const IconEyePasswordDisabled = ({
  color = '#959ea3',
  testId = 'icon-eyes-password-disabled',
  size = 20,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 20.382 20.382">
      <path id="Caminho_247782" data-name="Caminho 247782" d="M0,0H20.382V20.382H0ZM0,0H20.382V20.382H0ZM0,0H20.382V20.382H0ZM0,0H20.382V20.382H0Z" fill="none" />
      <path id="Caminho_247783" data-name="Caminho 247783" d="M10.308,6.362a4.218,4.218,0,0,1,4.231,4.2,4.055,4.055,0,0,1-.3,1.538l2.471,2.454a9.934,9.934,0,0,0,2.9-3.992A10.006,10.006,0,0,0,6.932,4.849L8.759,6.664A4.131,4.131,0,0,1,10.308,6.362ZM1.846,4.067,3.775,5.984l.389.387A9.927,9.927,0,0,0,1,10.564a10.037,10.037,0,0,0,13.014,5.6l.355.353,2.479,2.454L17.924,17.9,2.921,3ZM6.526,8.715l1.312,1.3a2.356,2.356,0,0,0-.068.546,2.527,2.527,0,0,0,2.539,2.521,2.4,2.4,0,0,0,.55-.067l1.312,1.3a4.208,4.208,0,0,1-6.093-3.757A4.143,4.143,0,0,1,6.526,8.715Zm3.647-.656,2.665,2.647.017-.134a2.527,2.527,0,0,0-2.539-2.521Z" transform="translate(-0.117 -0.352)" />
    </svg>
  </Style.Wrapper>
);


export default IconEyePasswordDisabled;
