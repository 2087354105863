/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef, useEffect, useState, useContext,
} from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, TextField,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';


const TypeCAAValueField = ({
  t, classes, formData, onHandleChangeInput, isInvalidBlock,
}) => {
  const { loading } = useContext(DNSContext);

  const valueRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isInvalidBlock) {
      if (formData.tag === 'iodef') {
        setErrorMessage(t('dns.zoneManager.addRecord.form.helperText.caaIodefValue'));
      } else {
        setErrorMessage(t('dns.zoneManager.addRecord.form.helperText.caaIssueValue'));
      }
    } else {
      setErrorMessage(null);
    }
  }, [formData, (formData && formData.tag), isInvalidBlock]);


  return (
    <div className={classes.caaBlockWrapper}>
      <FormControl component="fieldset" className={classes.radioGroup}>
        <FormLabel component="legend">Flag</FormLabel>
        <RadioGroup className={classes.radioNoWrap} row aria-label="flag" name="flag" value={formData.flag} onChange={e => onHandleChangeInput(e.target)}>
          <FormControlLabel
            value="0"
            control={(
              <Radio
                size="small"
                disabled={loading}
                inputProps={{
                  'data-testid': 'zoneManagerRadioFlag0',
                }}
              />
                )}
            label="0"
          />
          <FormControlLabel
            value="1"
            control={(
              <Radio
                size="small"
                disabled={loading}
                inputProps={{
                  'data-testid': 'zoneManagerRadioFlag1',
                }}
              />
                )}
            label="1"
          />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" className={classes.radioGroup}>
        <FormLabel component="legend">Tag</FormLabel>
        <RadioGroup className={classes.radioNoWrap} row aria-label="tag" name="tag" value={formData.tag} onChange={e => onHandleChangeInput(e.target)}>
          <FormControlLabel
            value="issue"
            control={(
              <Radio
                size="small"
                disabled={loading}
                inputProps={{
                  'data-testid': 'zoneManagerRadioTagIssue',
                }}
              />
                )}
            label="issue"
          />
          <FormControlLabel
            value="issuewild"
            control={(
              <Radio
                size="small"
                disabled={loading}
                inputProps={{
                  'data-testid': 'zoneManagerRadioTagIssueWild',
                }}
              />
                )}
            label="issuewild"
          />
          <FormControlLabel
            value="iodef"
            control={(
              <Radio
                size="small"
                disabled={loading}
                inputProps={{
                  'data-testid': 'zoneManagerRadioTagIodef',
                }}
              />
                )}
            label="iodef"
          />
        </RadioGroup>
      </FormControl>

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeCAAValueField')}
        value={formData.value}
        onChange={e => onHandleChangeInput(e.target)}
        name="value"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputProps: {
            maxLength: 255,
            ref: valueRef,
            'data-testid': 'zoneManagerValueField',
          },
        }}
        error={isInvalidBlock}
        helperText={errorMessage}
      />
    </div>
  );
};

export default (withI18n()(withStyles(styles)(TypeCAAValueField)));
