export default {
  '404Page': 'Erro 404',
  billing: 'Facturas',
  cancelled: 'Facturas',
  changePassword: 'Cambiar Contraseña',
  accountSecurity: 'Seguridad de la Cuenta',
  checkDomainAvailability: '/check-domain-availability',
  courses: 'Cursos',
  cancelRenewal: 'Cancelar Renovación',
  cancelSignature: 'Cancelar Suscripción',
  createEmail: 'Crear Cuenta de Correo',
  creditCard: 'Gestionar Tarjeta de Crédito',
  dns: 'Cambiar DNS',
  domain: 'Administrar Dominio',
  domains: 'Dominios',
  emails: 'Correos',
  emailsTitan: 'Administrar',
  help: 'Soporte',
  invoices: 'Facturas',
  mktPlace: 'Ferramentas e Serviços',
  manageSubscription: 'Administrar suscripción',
  paid: 'Facturas',
  product: 'Gestionar Producto',
  products: 'Productos',
  subscriptions: 'Suscripciones',
  sites: 'Mis sitios web',
  sitesPage: 'Sitios web',
  sitesCreate: 'Crear sitio',
  sitesPlans: 'Sitios Web y Planes',
  support: 'Soporte',
  supportforms: 'Abrir ticket',
  ticket: 'Ticket',
  titanHire: 'Contratar Plan de correo Electrónico',
  titanHireFreePlan: 'Contratar Nuevo Plan de correo Electrónico',
  titanHirePlanActiveCPanel: 'Plataforma de Correo Electrónico Gratuita',
  titanInboxUpgrade: 'Administrar Planes',
  titanUpgrade: 'Administrar Planes',
  unpaid: 'Facturas Pendientes',
  asaas: 'Asaas',
  v2: {
    sitesPage: 'Sitios web',
    sitesCreate: 'Crear Sitio',
    emails: 'Correos',
    setEmailPlatform: 'Definir plataforma de correo',
    manageEmailsTitan: 'Administrar Correo Titan',
    manageEmailsCpanel: 'Administrar Correo cPanel',
    domains: 'Dominios',
    manageDomain: 'Administrar Dominio',
    setupDomain: 'Configurar Dominio',
    hostsAndserver: 'Hospedajes y Servidores',
    manageHostOrServer: 'Administrar Alojamiento web o Servidor',
    securityAndBackup: 'Seguridad y Backup',
    manageSiteLock: 'Administrar SiteLock',
    manageCodeGuard: 'Administrar CodeGuard',
    subscribeSitelock: 'Suscribirse a SiteLock',
    subscribeCodeGuard: 'Suscribirse a CodeGuard',
    toolsAndServices: 'Herramientas y Servicios',
    courses: 'Cursos',
    subscribeAcademyPass: 'Suscribirse a Academy Pass',
    invoice: 'Facturas',
    subscription: 'Suscripciones',
    manageSubscriptions: 'Administrar Suscripción',
    cancelSubscription: 'Cancelar Suscripción',
    manageCreditCard: 'Administrar Tarjeta de Crédito',
    support: 'Soporte',
    openTicket: 'Abrir Ticket',
    seeTicket: 'Ver Ticket',
    myData: 'Mis Datos',
    changePassword: 'Cambiar Contraseña',
    asaas: 'Asaas',
    linkNaBio: 'Enlace en la bio',
    dataAnalysis: 'Análisis de datos del Enlace en la Bio',
    cloudflare: 'Desempenho de sites',
  },
};
