import { useState, useEffect } from 'react';


const useLocalStorage = (key, initialState) => {
  const getStorageValue = (key) => {
    const storageValue = localStorage.getItem(key);
    if (storageValue) {
      return JSON.parse(storageValue);
    }
    return initialState;
  };

  const [state, setState] = useState(getStorageValue(key));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default useLocalStorage;
