import React, { useEffect, useState } from 'react';
import { PaymentModal } from '../PaymentModal';
import { ExpiredTimePixModal } from '../ExpiredTimePixModal';
import { PaymentSuccessPixModal } from '../PaymentSuccessPixModal';
import { GenerateErrorPixModal } from '../GenerateErrorPixModal';
import SubscriptionsFunctions from '../SubscriptionsHandler/SubscriptionsHandler.functions';
import { OriginCalling } from '../PaymentModal/PaymentModal.types';
import { Modais } from '../ManageSubscriptionNewHandler/ManageSubscriptionNewHandler.types';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { billingActions, preChatActions } from '@/redux/modules';
import { TicketRedirectModal } from '../TicketRedirectModal';
import { format } from 'date-fns';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import { SplitPaymentModal } from '@/components/Invoices/SplitPaymentModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { XRAY_ENABLED_SPLIT_PAYMENT } from '@/config/GrowthBook/constants';

const GroupPaymentModal = ({
  subscription,
  invoice = null,
  displayModalPayment = false,
  setDisplayModalPayment,
  originCalling,
  onExecuteAction = null,
  handleUpgradeSitelock,
}) => {
  const [modalById, setModalById] = useState(Modais.NONE);
  const [hasQRCodeGenerated, setHasQRCodeGenerated] = useState(false);
  const [qrCodeGenerated, setQRCodeGenerated] = useState('');
  const [pixCodeGenerated, setPixCodeGenerated] = useState('');
  const [dueDateFormated, setDueDateFormated] = useState('');
  const [hasOriginSavePDF, setOriginSavePDF] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [hasSuccessSplitInvoice, setSuccessSplitInvoice] = useState(false);
  const [isPaymentSplited, setPaymentSplited] = useState(false);

  const hasInvoice = invoice !== null;
  const { requestDiagnostic } = useDiagnostic();

  const { billing: billingLocale } = useLocale();

  const canShowPaymentModal = () => modalById === Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION;
  const canShowSplitPaymentModal = () => modalById === Modais.ACTIVE_SPLIT_PAYMENT_MODAL;

  const canShowExpiredTimePixModal = () => modalById === Modais.ACTIVE_EXPIRED_TIME_PIX_MODAL;
  const canShowPaymentSuccessPixModal = () => modalById === Modais.ACTIVE_PAYMENT_SUCCESS_PIX_MODAL;
  const canShowGenerateErrorPixModal = () => modalById === Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL;
  const canShowTicketRedirectModal = () => modalById === Modais.ACTIVE_REDIRECT_TICKET_MODAL;
  const enabledSplitPayment = useFeatureIsOn(XRAY_ENABLED_SPLIT_PAYMENT);


  const isDomain = () => subscription && subscription.type === 'domain';
  const [urlTicket, setUrlTicket] = useState('');

  const dispatch = useDispatch();

  const preChatSetOpen = preChatActions.preChat.setOpen;
  const preChatSetScreen = preChatActions.preChat.setScreen;
  const setPreChatInvoiceId = preChatActions.invoice.set.invoiceId;
  const { setPreChatBillingGenerated, preChatBillingGenerated } = preChatActions.trustReactivation.billingGenerated;
  const setPreChatTrustReactivationInvoiceId = preChatActions.trustReactivation.setInvoiceId;
  const { preChatTrustReactivationStates } = useSelector(state => state.preChat.trustReactivation);
  const invoiceIdFromPreChat = useSelector(state => state.preChat.invoiceId);

  useEffect(() => {
    if ((subscription && subscription.dueDate !== undefined) || (invoice && invoice.duedate !== undefined)) {
      setDueDateFormated(format(new Date(`${hasInvoice ? invoice.duedate : subscription.dueDate} 00:00:00`), 'dd/MM/yyyy'));
    }
    if (displayModalPayment === true) {
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    }
    if (displayModalPayment === true && invoice && invoice.isSplittable && enabledSplitPayment) {
      setModalById(Modais.ACTIVE_SPLIT_PAYMENT_MODAL);
    }
  }, [subscription, invoice, displayModalPayment, setDisplayModalPayment]);

  const onPaymentSuccessPix = () => {
    if (handleUpgradeSitelock) {
      handleUpgradeSitelock();
    } else {
      setModalById(Modais.ACTIVE_PAYMENT_SUCCESS_PIX_MODAL);
      requestDiagnostic('groupPaymentModal');
    }
  };

  const openPreChat = () => {
    if (invoiceIdFromPreChat && invoice && invoiceIdFromPreChat.toString() === invoice.id.toString()) {
      const findTrustReactivationState = preChatTrustReactivationStates.find(state => (state.invoice_id === invoice.id && state.trust_reactivation_available));

      const trustReactivationAvailable = findTrustReactivationState && findTrustReactivationState.trust_reactivation_available;

      const trustReactivationInvoiceId = findTrustReactivationState && findTrustReactivationState.invoice_id;

      const trustReactivationFlow = preChatBillingGenerated && trustReactivationAvailable;

      if (trustReactivationFlow) {
        setPreChatTrustReactivationInvoiceId(trustReactivationInvoiceId);
        preChatSetScreen('trustReactivation');
      } else {
        preChatSetScreen('problemSolved');
      }
      setPreChatBillingGenerated(false);
      setPreChatInvoiceId(null);
      preChatSetOpen(true);
    }
  };

  const getTicketModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      openPreChat();
    };

    return {
      onCloseClick,
      urlTicket,
      hasOriginSavePDF,
    };
  };

  const getGenerateErrorPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      setDisplayModalPayment(false);
      openPreChat();
    };

    const onSwitchPaymentMethod = () => {
      setHasQRCodeGenerated(false);
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code || code !== '') && (qrCode || qrCode !== '')) {
        setPixCodeGenerated(code);
        setQRCodeGenerated(qrCode);
        setHasQRCodeGenerated(true);
        setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
      } else {
        setHasQRCodeGenerated(false);
      }
    };

    return {
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onPaymentSuccessPix,
      onCloseClick,
      invoiceId: hasInvoice ? invoice.id : subscription.invoiceId,
      originCalling,
    };
  };

  const getExpiredPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      setDisplayModalPayment(false);
      openPreChat();
    };

    const onSwitchPaymentMethod = () => {
      setHasQRCodeGenerated(false);
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
    };

    const onUpdateQRCode = (code, qrCode) => {
      if ((code || code !== '') && (qrCode || qrCode !== '')) {
        setHasQRCodeGenerated(true);
        setPixCodeGenerated(code);
        setQRCodeGenerated(qrCode);
        setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
      } else {
        setHasQRCodeGenerated(false);
        setModalById(Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL);
      }
    };

    return {
      onSwitchPaymentMethod,
      onUpdateQRCode,
      onCloseClick,
      onPaymentSuccessPix,
      invoiceId: hasInvoice ? invoice.id : subscription.invoiceId,
      originCalling,
    };
  };

  const getPaymentSuccessPixModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      dispatch(billingActions.subscriptions.request());
      setHasQRCodeGenerated(false);
      openPreChat();
    };

    return {
      onCloseClick,
      onPaymentSuccessPix,
      originCalling,
    };
  };

  const getSplitPaymentModalProps = () => {
    const onCloseClick = () => {
      setDisplayModalPayment(false);
      setModalById(Modais.NONE);
      openPreChat();
    };

    const onExecutePayment = (hasSplitedInvoice = false) => {
      setPaymentSplited(hasSplitedInvoice);
      hasSplitedInvoice && onExecuteAction && onExecuteAction();
      setModalById(Modais.ACTIVE_PAYMENT_IN_SUBSCRIPTION);
      setSuccessSplitInvoice(hasSplitedInvoice);
      openPreChat();
    };


    return {
      onCloseClick,
      onExecutePayment,
      setInvoiceDetails,
      invoice: hasInvoice ? invoice : null,
    };
  };


  const getPaymentModalProps = () => {
    const onCloseClick = () => {
      setModalById(Modais.NONE);
      setDisplayModalPayment(false);
      openPreChat();
      isPaymentSplited && window.location.reload();
    };

    const onConfirmButtonClick = () => {
      SubscriptionsFunctions.handleActivateSubscription(subscription);
      SubscriptionsFunctions.handleOnModalClose();
      setModalById(Modais.NONE);
      setDisplayModalPayment(false);
    };

    const onFinishPixTimer = () => {
      setModalById(Modais.ACTIVE_EXPIRED_TIME_PIX_MODAL);
    };

    const onGenerateErrorPix = () => {
      setModalById(Modais.ACTIVE_GENERATE_ERROR_PIX_MODAL);
    };

    const onRedirectTicket = (urlTicket, isOriginSavePDF = false) => {
      setUrlTicket(urlTicket);
      setOriginSavePDF(isOriginSavePDF);
      setModalById(Modais.ACTIVE_REDIRECT_TICKET_MODAL);
    };

    const isNotDomainType = subscription !== undefined ? subscription.subscriptionType !== 'domain' : true;

    const getProductName = () => {
      if (subscription !== undefined) {
        return isDomain() ? billingLocale.domainLabel : subscription.productName;
      }

      return '';
    };

    return {
      onCloseClick,
      invoiceId: hasInvoice ? invoice.id : subscription.invoiceId,
      totalDiscounts: hasInvoice ? invoice.totalDiscounts : '',
      onConfirmButtonClick,
      originCalling: originCalling === OriginCalling.SITELOCK_UPGRADE ? OriginCalling.SITELOCK_UPGRADE : OriginCalling.SUBSCRIPTION_LIST,
      canShowCancelImmediateButton: isNotDomainType,
      productNameText: getProductName(),
      dueDate: dueDateFormated,
      onFinishPixTimer,
      discount: hasInvoice ? invoice.discount : subscription.discount,
      onGenerateErrorPix,
      onPaymentSuccessPix,
      onRedirectTicket,
      hasSuccessSplitInvoice: isPaymentSplited,
      renovationAmountText: hasInvoice ? formatCurrency(invoiceDetails ? invoiceDetails.subtotal : invoice.total) : formatCurrency(subscription.unpaidInvoice !== undefined && subscription.unpaidInvoice.totalInvoice !== undefined ? subscription.unpaidInvoice.totalInvoice : subscription.amount),
      handleUpgradeSitelock: event => handleUpgradeSitelock(event),
      showCreditCardCheckbox: hasInvoice && invoice.showCreditCardCheckbox,
    };
  };

  return (
    <>
      {canShowPaymentModal() && (
        <PaymentModal
          {...getPaymentModalProps()}
          hasQRCodeGenerated={hasQRCodeGenerated}
          qrCodeGenerated={qrCodeGenerated}
          pixCodeGenerated={pixCodeGenerated}
          hasSuccessSplitInvoice={hasSuccessSplitInvoice}
          isExceedsRetriesCC={hasInvoice ? invoice.isExceedsRetriesCC : subscription.isExceedsRetriesCC}
        />
      )}
      {canShowSplitPaymentModal() && (
        <SplitPaymentModal {...getSplitPaymentModalProps()} />
      )}

      {canShowExpiredTimePixModal() && (
        <ExpiredTimePixModal
          {...getExpiredPixModalProps()}
        />
      )}

      {canShowPaymentSuccessPixModal() && (
        <PaymentSuccessPixModal
          {...getPaymentSuccessPixModalProps()}
        />
      )}

      {canShowGenerateErrorPixModal() && (
        <GenerateErrorPixModal {...getGenerateErrorPixModalProps()} />
      )}

      {canShowTicketRedirectModal() && (
        <TicketRedirectModal {...getTicketModalProps()} />
      )}
    </>
  );
};
export default GroupPaymentModal;
