
import PropTypes from 'prop-types';

export const ManageSubscriptionProps = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  redirectTo: PropTypes.func,
  subscriptionId: PropTypes.number,
  subscriptionType: PropTypes.string,
};

export const Modais = {
  NONE: 'NONE',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  ACTIVE_RENEWAL_SUBSCRIPTION: 'ACTIVATE_RENEWAL_SUBSCRIPTION',
  ACTIVE_PAYMENT_IN_SUBSCRIPTION: 'ACTIVE_PAYMENT_IN_SUBSCRIPTION',
  ACTIVE_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION: 'ACTIVE_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION',
  ACTIVE_FINISH_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION: 'ACTIVE_FINISH_SWITCH_PAYMENT_METHOD_IN_SUBSCRIPTION',
  ACTIVE_EXPIRED_TIME_PIX_MODAL: 'ACTIVE_EXPIRED_TIME_PIX_MODAL',
  ACTIVE_PAYMENT_SUCCESS_PIX_MODAL: 'ACTIVE_PAYMENT_SUCCESS_PIX_MODAL',
  ACTIVE_GENERATE_ERROR_PIX_MODAL: 'ACTIVE_GENERATE_ERROR_PIX_MODAL',
  ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL: 'ACTIVE_SUBSCRIPTION_REACTIVATION_MODAL',
  ACTIVE_REDIRECT_TICKET_MODAL: 'ACTIVE_REDIRECT_TICKET_MODAL',
  ACTIVE_RENOVATION_SUBSCRIPTION_SUCCESS_MODAL: 'ACTIVE_RENOVATION_SUBSCRIPTION_SUCCESS_MODAL',
  ACTIVE_INVOICE_GENERATOR_MODAL: 'ACTIVE_INVOICE_GENERATOR_MODAL',
  ACTIVE_SPLIT_PAYMENT_MODAL: 'ACTIVE_SPLIT_PAYMENT_MODAL',
  CHANGE_PAYMENT_METHOD: 'CHANGE_PAYMENT_METHOD',
};

export const EnumActionOpeningModal = {
  MODAL_DEFAULT: null,
  MODAL_INVOICE_GENERATION: 'invoice_generation',
  MODAL_REACTIVATION: 'reactivation',
};

export const EnumFeedbackStatus = {
  COMPLETED: 'accomplished',
  DECLINED: 'pending',
  ERROR: 'pending',
};
