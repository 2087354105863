import React from 'react';
import * as Style from './IconDetailedBlog.style';

const IconDetailedBlog = ({
  color = '#4a4a4a',
  backgroundColor = '#f47924',
  size = '30',
  testId = 'icon-detailed-blog',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g id="Grupo_40230" data-name="Grupo 40230" transform="translate(-817 -593)">
        <rect id="Rectangle" width="17.253" height="17.253" transform="translate(817.337 605.569)" fill={backgroundColor} />
        <path id="Caminho_247895" data-name="Caminho 247895" d="M20,10.929l1.137-1.137a2.15,2.15,0,0,0,0-3.036L19.629,5.242a2.15,2.15,0,0,0-3.036,0L15.456,6.38Zm-4.549,1.523L7.731,20.166H6.219V18.653l7.714-7.714Zm-1.523-4.56-9.86,9.87v4.549H8.622l9.86-9.86-4.549-4.56Zm6.233,10.664c0,2.35-2.725,3.755-5.364,3.755a1.073,1.073,0,0,1,0-2.146c1.652,0,3.219-.783,3.219-1.609,0-.5-.515-.933-1.32-1.287l1.588-1.588A3.263,3.263,0,0,1,20.166,18.557ZM4.7,14.1A2.861,2.861,0,0,1,3,11.583c0-1.931,2.028-2.822,3.819-3.6,1.105-.494,2.618-1.159,2.618-1.76,0-.44-.837-1.073-2.146-1.073a2.61,2.61,0,0,0-1.963.687,1.089,1.089,0,0,1-1.5.129,1.064,1.064,0,0,1-.161-1.481A4.561,4.561,0,0,1,7.291,3c2.4,0,4.291,1.416,4.291,3.219,0,2.006-2.071,2.918-3.905,3.723-1.008.44-2.532,1.105-2.532,1.641,0,.333.461.644,1.148.923Z" transform="translate(821.137 594.146)" fill={color} />
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedBlog;
