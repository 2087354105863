import styled, { css } from 'styled-components';
import listDecoration from '@/media/asaas/Asaas-list-decoration.svg';

export const FirstRowWrapper = styled.div`
  ${({ theme, isInsideWizard }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: row;
    flex-warp: wrap;
    justify-content: space-between;
    padding: ${isInsideWizard ? '0px' : '40px'};
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.md}){
      flex-direction: column;
      padding: 36px;
    }
  `}
`;

export const LeftColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 725px;
    width: 100%;
    
    @media (max-width: ${theme.v1.breakpoints.md}){
      max-width: 100%;
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme, isInsideWizard }) => css`
    margin: ${isInsideWizard ? '44px 0px 0px 0px' : '0px'};
    max-width: 141px;
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.md}){
      margin: 0px auto;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme, isInsideWizard }) => css`
    color: ${theme.v1.color.text.featured};
    font-family: ${theme.v1.font.family.primary};
    font-size: 24px;
    font-weight: ${theme.v1.font.weights.bold};
    line-height: 32px;
    margin: ${isInsideWizard ? '15px 0px 0px 0px' : '24px 0px 0px 0px'};
    max-width: ${isInsideWizard ? '364px' : '469px'};

    @media (max-width: ${theme.v1.breakpoints.md}){
      font-size: 20px;
      line-height: 27px;
      margin: 16px auto 0px auto;
      text-align: center;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme, isInsideWizard }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${isInsideWizard ? '14px' : '16px'};
    font-weight: ${theme.v1.font.weights.regular};
    line-height: ${isInsideWizard ? '19px' : '21px'};
    margin: 16px 0px 36px;
    max-width: ${isInsideWizard ? '364px' : '409px'};

    > b {
      font-weight: ${theme.v1.font.weights.bold};
    }

    > p {
      margin: 20px 0px 0px 0px;
    }

    a {
      color: ${theme.v1.color.action.primary};
      font-weight: ${theme.v1.font.weights.bold};
    }

    @media (max-width: ${theme.v1.breakpoints.md}){
      font-size: 14px;
      line-height: 19px;
      margin: 16px auto 20px auto;
      text-align: center;
    }
  `}
`;

export const AsaasDescriptionImage = styled.img`
  ${({ theme, isInsideWizard }) => css`
    max-width: ${isInsideWizard ? '364px' : '436px'};
    width: 100%;

    @media (max-width: ${theme.v1.breakpoints.md}){
      display: none;
      visibility: hidden;
    }
  `}
`;

export const RightColumn = styled.div`
  ${({ theme, isInsideWizard }) => css`
    background-color: ${theme.v1.color.white.primary};
    display: flex;
    flex-direction: column;
    max-width: 521px;
    min-height: ${isInsideWizard ? '0px' : '625px'} ;
    width: 100%;

     @media (max-width: ${theme.v1.breakpoints.md}){
      margin: 0 auto;
    }
  `}
`;

export const Form = styled.form`
  ${({ theme, isInsideWizard }) => css`
    background-color: ${theme.v1.color.white.primary};
    border-radius: 4px;
    border: 1px solid ${theme.v1.color.background.wizard};
    max-width: 520px;
    padding: ${isInsideWizard ? '30px 16px 0px' : '30px 16px'};
    width: 100%;
    @media (min-width: ${theme.v1.breakpoints.lg}){
      max-width: unset;
      width: 520px;
    }
  `}

`;

export const StepperWrapper = styled.div`
    margin: 0 auto;
    width: fit-content;
`;

export const DescriptionList = styled.ul`
  ${({ theme }) => css`
    list-style-type: none;
    margin: auto 0 0 0;
    padding: 0;
    display: none;
    visibility: hidden;

    li:last-child {
      margin-bottom: 0px;
    }

    @media (max-width: ${theme.v1.breakpoints.md}){
      display: block;
      margin: 20px auto 0 auto;
      visibility: visible;
    }
  `}
`;

export const DescriptionItem = styled.li`
  ${({ theme, isInsideWizard }) => css`
    color: ${theme.v1.color.text.primary};
    display: flex;
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 19px;
    margin-bottom: 10px;
    gap: ${isInsideWizard ? '5px' : '0px'};

    b {
      font-weight: ${theme.v1.font.weights.bold};
    }

    span {
      color: ${theme.v1.color.action.primary};
    }

    &:before {
      align-self: center;
      background-image: url(${listDecoration});
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 11px;
      margin-right: 10px;
      min-width: 14px;
    }
  `}
`;
