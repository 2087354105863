import {
  FORCE_DNS_LOADING_STATUS,

  DNS_ZONE_MANAGER_ADD_RECORD_SELECT_TYPE,
  DNS_ZONE_MANAGER_EDIT_RECORD_SELECT_TYPE,
  REQUEST_DNS_ZONE_MANAGER_ADD_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD,

  ERROR_DNS_ZONE_MANAGER_ADD_RECORD,
  ERROR_DNS_ZONE_MANAGER_EDIT_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD,
  REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD,
  REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD,
  ERROR_DNS_ZONE_MANAGER_DELETE_RECORD,
  REQUEST_DNS_ZONE_MANAGER_GET_RECORD,
  RECEIVE_DNS_ZONE_MANAGER_GET_RECORD,
  ERROR_DNS_ZONE_MANAGER_GET_RECORD,
} from '@/redux/actions/actionsTypes';

const initialState = {
  loading: false,
  recordTypes: ['A', 'AAAA', 'CAA', 'MX', 'TXT', 'CNAME', 'SRV'],
  recordedDnsItems: [],
};

const dnsZoneManager = (state = initialState, action) => {
  switch (action.type) {
    case FORCE_DNS_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.bool,
      };

    case REQUEST_DNS_ZONE_MANAGER_ADD_RECORD:
    case REQUEST_DNS_ZONE_MANAGER_EDIT_RECORD:
    case REQUEST_DNS_ZONE_MANAGER_DELETE_RECORD:
    case REQUEST_DNS_ZONE_MANAGER_GET_RECORD:
      return {
        ...state,
        loading: true,
      };

    case RECEIVE_DNS_ZONE_MANAGER_ADD_RECORD:
    case ERROR_DNS_ZONE_MANAGER_ADD_RECORD:
    case RECEIVE_DNS_ZONE_MANAGER_EDIT_RECORD:
    case ERROR_DNS_ZONE_MANAGER_EDIT_RECORD:
    case RECEIVE_DNS_ZONE_MANAGER_DELETE_RECORD:
    case ERROR_DNS_ZONE_MANAGER_DELETE_RECORD:
    case ERROR_DNS_ZONE_MANAGER_GET_RECORD:
      return {
        ...state,
        loading: false,
      };

    case RECEIVE_DNS_ZONE_MANAGER_GET_RECORD:
      return {
        ...state,
        recordedDnsItems: action.data.records,
        loading: false,
      };

    case DNS_ZONE_MANAGER_ADD_RECORD_SELECT_TYPE:
    case DNS_ZONE_MANAGER_EDIT_RECORD_SELECT_TYPE:
    default:
      return state;
  }
};

export default dnsZoneManager;
