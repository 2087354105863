import { REFUND_PAYMENT_METHODS } from '@/enums/billing/billing.enum';
import { BillingActionTypes } from './billing.types';

export const initialState = {
  subscriptions: {
    loading: false,
    questions: {},
    cancel: {
      optionId: null,
      questionId: null,
      questionValue: '',
      reason: '',
      modal: null,
    },
    offerRetentionCancel: null,
    isAcceptOfferRedutionCancel: null,
    items: [],
    itemsNotInCreditcard: [],
    loadedItemsNotInCreditcard: false,
    loadingItemsNotInCreditCard: false,
    loadingUpdateItemsNotInCreditCard: false,
    updateItemsNotInCreditCardStatus: null,
    loadingAcceptRetention: false,
  },
  paymentMethods: {
    enabledPaymentMethods: null,
  },
  subscription: {
    reactivation: null,
    switchPaymentMethod: {
      status: null,
      loading: false,
    },
  },
  invoice: {
    isGenerateInvoice: null,
    invoiceId: null,
  },
  reactivation: {
    loading: false,
    success: null,
    hostings: [],
  },
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BillingActionTypes.SET_LOADING_STATUS_ACCEPT_RETENTION_OFFER:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loadingAcceptRetention: action.payload.bool,
        },
      };
    case BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          switchPaymentMethod: {
            ...state.subscription.switchPaymentMethod,
            status: 'success',
          },
        },
      };
    case BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          switchPaymentMethod: {
            ...state.subscription.switchPaymentMethod,
            status: 'failure',
          },
        },
      };
    case BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_CLEAR_STATUS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          switchPaymentMethod: {
            ...state.subscription.switchPaymentMethod,
            status: null,
          },
        },
      };
    case BillingActionTypes.SUBSCRIPTION_SWITCH_PAYMENT_METHOD_LOADING: {
      const { isLoading } = action.payload;

      return {
        ...state,
        subscription: {
          ...state.subscription,
          switchPaymentMethod: {
            ...state.subscription.switchPaymentMethod,
            loading: isLoading,
          },
        },
      };
    }
    case BillingActionTypes.SET_LOADING_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          updateItemsNotInCreditCardStatus: null,
          loadingUpdateItemsNotInCreditCard: action.payload,
        },
      };
    case BillingActionTypes.SUCCESS_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          updateItemsNotInCreditCardStatus: 'success',
        },
      };
    case BillingActionTypes.FAILURE_UPDATE_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          updateItemsNotInCreditCardStatus: 'error',
        },
      };
    case BillingActionTypes.SUCCESS_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD:
    case BillingActionTypes.FAILURE_GET_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loadingItemsNotInCreditCard: false,
          loadedItemsNotInCreditcard: true,
        },
      };
    }
    case BillingActionTypes.SET_LOADING_SUBSCRIPTIONS_NO_IN_CREDIT_CARD: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loadingItemsNotInCreditCard: true,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTIONS_NOT_IN_CREDIT_CARD: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          itemsNotInCreditcard: action.payload.list,
        },
      };
    }
    case BillingActionTypes.SET_LOADING_SUBSCRIPTIONS_STATUS: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loading: action.payload.bool,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          items: [...action.payload.subscriptions],
          loading: false,
        },
      };
    }
    case BillingActionTypes.SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          enabledPaymentMethods: { ...action.payload.paymentMethods },
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTIONS_QUESTION: {
      const newState = { ...state };
      const { type, question } = action.payload;
      newState.subscriptions.questions[type] = [question];
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          questions: {
            ...state.subscriptions.questions,
            [type]: [question],
          },
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTIONS_CANCEL: {
      const { subscriptions } = state;

      subscriptions.cancel = {
        ...subscriptions.cancel,
        ...action.payload,
      };

      return state;
    }
    case BillingActionTypes.SET_SUBSCRIPTION_REFUND: {
      const { refund, subscriptionId, subscriptionType } = action.payload;

      const items = [...state.subscriptions.items];

      const currentItem = items.find(({ id, type }) => id === subscriptionId && type === subscriptionType);


      if (!currentItem) {
        return state;
      }


      currentItem.refund = {
        ...refund,
        methodSelected: REFUND_PAYMENT_METHODS.HG_CREDITS,
      };

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          items: [...items],
          loading: false,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTION_REFUND_METHOD_SELECTED: {
      const { methodSelected, subscriptionId, subscriptionType } = action.payload;

      const items = [...state.subscriptions.items];

      const currentItem = items.find(({ id, type }) => id === subscriptionId && type === subscriptionType);


      if (!currentItem) {
        return state;
      }


      currentItem.refund = {
        ...currentItem.refund,
        methodSelected,
      };

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          items: [...items],
          loading: false,
        },
      };
    }
    case BillingActionTypes.SET_DISCOUNT_VALUES: {
      const { values, hostingId: valueId } = action.payload;

      const subscriptionItemsValues = [...state.subscriptions.items];

      const valueItem = subscriptionItemsValues.find(({ id }) => id === valueId);

      if (!valueItem) {
        return state;
      }

      valueItem.values = values;

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          items: [...subscriptionItemsValues],
          loading: false,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTIONS_ABLE_TO_CANCEL_OFFER: {
      const { offerRetentionCancel } = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          offerRetentionCancel,
        },
      };
    }
    case BillingActionTypes.SET_ACCEPT_RETENTION_OFFER: {
      const { isAcceptOfferRedutionCancel } = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isAcceptOfferRedutionCancel,
        },
      };
    }
    case BillingActionTypes.SET_HOST_BENEFIT_BILLING: {
      const { invoice } = action.payload;

      return {
        ...state,
        invoice,
      };
    }
    case BillingActionTypes.RESET_HOST_BENEFIT_BILLING: {
      return {
        ...state,
        invoice: {
          isGenerateInvoice: null,
          invoiceId: null,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTION_REACTIVATION_LOADING: {
      return {
        ...state,
        reactivation: {
          ...state.reactivation,
          loading: action.payload.isLoading,
        },
      };
    }
    case BillingActionTypes.SUBSCRIPTION_REACTIVATION_SUCCESS: {
      return {
        ...state,
        reactivation: {
          ...state.reactivation,
          success: true,
        },
      };
    }
    case BillingActionTypes.SUBSCRIPTION_REACTIVATION_FAILURE: {
      return {
        ...state,
        reactivation: {
          ...state.reactivation,
          success: false,
        },
      };
    }
    case BillingActionTypes.SET_SUBSCRIPTION_REACTIVATION: {
      return {
        ...state,
        reactivation: {
          ...state.reactivation,
          hostings: action.payload.reactivatedHostings,
        },
      };
    }
    case BillingActionTypes.SET_CHECK_HOSTING_ABLE_DISCOUNT_DATA: {
      const { subscription } = action.payload;
      return {
        ...state,
        subscription: {
          ...state.subscription,
          reactivation: [subscription.reactivation],
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default billingReducer;
