import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFaqEmails } from '@/redux/actions/faq';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { SUPPORT_URL } from '@/config/urls/supportUrls';


const FaqEmails = ({ className, link }) => {
  const faq = useSelector(state => state.faq);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFaqEmails());
  }, [dispatch]);

  return (
    <FaqDefault
      questions={faq.emails.questions}
      link={link || SUPPORT_URL}
      className={className}
      faq={faq.loading}
    />
  );
};

export default FaqEmails;
