/* eslint-disable react-hooks/rules-of-hooks */
import {
  createElement, useCallback,
  useEffect,
  useState,
} from 'react';
import { locale } from '@/utils/locale';

import {
  IconSites as gatorIconSites, IconAsaas as gatorIconAsaas, IconEmail as gatorIconEmail, IconDomain as gatorIconDomain, IconFile as gatorIconFile, IconCourses as gatorIconCourses,
  IconServices as gatorIconServices, IconSecurity as gatorIconSecurity, IconSupport as gatorIconSupport, IconExit as gatorIconExit, IconCoin as gatorIconCoin, IconPerfil as gatorIconPerfil, IconData as gatorIconData, IconPassword as gatorIconPassword, IconStatus as gatorIconStatus,
  IconInvoice as gatorIconInvoice, IconSubscription as gatorIconSubscription, IconCreditCard as gatorIconCreditCard, IconLink,
} from 'gatorcomponents';

import { billingAnalytics } from '@/analytics';
import * as analytics from '@/utils/ThirdParties/tagManager';
import { LOGOUT_URL } from '@/config/whmcsUrls';
import { isBRServer } from '@/utils/Validators/validation';
import { getLinkServerStatus } from '@/utils/Application/links';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  ALPHA_ADMIN_TITAN_MAIL, BRAVO_ASAAS_INSIDE_WIZARD, CHARLIE_LINK_NA_BIO,
  CHARLIE_LINK_NA_BIO_GENERAL_GUIDE,
  EXPERIENCE_ACCOUNT_SECURITY,
} from '@/config/GrowthBook/constants';
import { AlertsBell as gatorAlertsBell } from '@/components/Layout/Menu/AlertsBell';
import { preChatActions, productsActions } from '@/redux/modules';
import { useDispatch } from 'react-redux';

const useMenu = ({
  moreThanOneUser, handleClick, profileIds, MANAGE_USERS_URL,
}) => {
  const shouldRenderCharlieAcademyPass = isBRServer;
  const alphaAdminTitan = useFeatureIsOn(ALPHA_ADMIN_TITAN_MAIL);
  const alphaTitan = true;
  const titanMailFeatureToggleIsOn = alphaAdminTitan || alphaTitan;
  const isBillingSignaturesEnaled = true;
  const shouldRenderAsaas = useFeatureIsOn(BRAVO_ASAAS_INSIDE_WIZARD);
  const hasNewAsaas = isBRServer && shouldRenderAsaas;
  const renderLinkNaBio = useFeatureIsOn(CHARLIE_LINK_NA_BIO);
  const canShowLinkNaBioGuide = useFeatureIsOn(CHARLIE_LINK_NA_BIO_GENERAL_GUIDE);
  const isAccountSecurityEnabled = useFeatureIsOn(EXPERIENCE_ACCOUNT_SECURITY);
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;
  const [alreadySawLinkNaBioGuide, setAlreadySawLinkNaBioGuide] = useState(true);

  const dispatch = useDispatch();
  const setAlertsVisible = (isVisible) => {
    dispatch(productsActions.myAlerts.setVisibility(isVisible));
  };

  useEffect(() => {
    const alreadySawGuide = localStorage.getItem('linkNaBioGuideIsDone');
    if (canShowLinkNaBioGuide && !alreadySawGuide) {
      setAlreadySawLinkNaBioGuide(false);
    }
  }, [canShowLinkNaBioGuide, alreadySawLinkNaBioGuide]);


  window.addEventListener('storage', () => {
    setAlreadySawLinkNaBioGuide(true);
  });

  const startSupport = () => {
    dispatch(preChatActions.preChat.setShowSidePanel());
  };

  const createItem = useCallback(({
    active = false,
    children = [],
    exitApp = false,
    showInDevice = 'mobile',
    icon = null,
    id = '',
    label = 'No Label',
    onClick = () => { },
    open = false,
    show = true,
    tag = '',
    target = '_self',
    to = '',
    tagInText,
    tagShaking = false,
  }) => ({
    active,
    children,
    exitApp,
    showInDevice,
    icon: icon ? createElement(icon) : null,
    id,
    label,
    onClick,
    open,
    show,
    tag,
    target,
    to,
    tagInText,
    tagShaking,
  }), []);

  const menuArrayV2 = ({ asaas }) => [
    createItem({
      icon: gatorIconSites,
      id: 'drawer-menu-button-sites-page',
      label: locale('menu.sitesPage'),
      to: locale('routes.sitesPage'),
      showInDevice: 'both',
      active: true,
    }),
    createItem({
      icon: gatorIconEmail,
      id: 'drawer-menu-button-emails',
      label: locale('menu.emails'),
      to: titanMailFeatureToggleIsOn
        ? locale('routes.emailsList')
        : locale('routes.emails'),
      showInDevice: 'both',
    }),
    createItem({
      icon: gatorIconDomain,
      id: 'drawer-menu-button-domains',
      label: locale('menu.domains'),
      to: locale('routes.domains'),
      showInDevice: 'both',
    }),
    createItem({
      icon: gatorIconFile,
      id: 'drawer-menu-button-products',
      label: locale('menu.v2.products'),
      showInDevice: 'both',
      to: locale('routes.products'),
    }),
    createItem({
      icon: gatorIconSecurity,
      id: 'drawer-menu-button-security',
      label: locale('menu.security'),
      to: locale('routes.security'),
      show: isBRServer,
      onClick: () => analytics.sitelockMenuButton(),
      showInDevice: 'both',
    }),
    createItem({
      icon: IconLink,
      id: 'drawer-menu-button-link-na-bio',
      label: locale('menu.linkNaBio'),
      to: locale('routes.linkNaBio'),
      tag: locale('tag.new'),
      show: renderLinkNaBio,
      showInDevice: 'both',
      tagShaking: !alreadySawLinkNaBioGuide,
    }),
    createItem({
      icon: gatorIconServices,
      id: 'drawer-menu-button-mktplace',
      label: locale('menu.mktPlace'),
      to: locale('routes.mktPlace'),
      show: isBRServer,
      onClick: () => analytics.mktPlaceAcquireButton({
        name: 'Potencialize seu negócio',
        menu: true,
      }),
      showInDevice: 'both',
    }),
    createItem({
      icon: gatorIconCourses,
      id: 'drawer-menu-button-courses',
      label: locale('menu.courses'),
      to: locale('routes.courses'),
      show: shouldRenderCharlieAcademyPass,
      showInDevice: 'both',
    }),
    createItem({
      icon: gatorIconAsaas,
      id: 'drawer-menu-button-asaas',
      label: (asaas && asaas.label) ? asaas.label : '',
      to: locale('routes.asaas'),
      show: hasNewAsaas,
      tagInText: asaas.tagInText,
      onClick: () => {
        analytics.menuAsaasClick(asaas.label);
      },
      showInDevice: 'both',
    }),
    createItem({
      label: locale('menu.newBilling'),
      id: 'drawer-menu-button-newBilling',
      icon: gatorIconCoin,
      showInDevice: 'mobile',
      show: isBillingSignaturesEnaled,
      children: [
        createItem({
          label: locale('menu.billing'),
          icon: gatorIconInvoice,
          id: 'drawer-menu-button-billing',
          to: `${locale('routes.invoices')}${locale('routes.unpaid')}`,
          show: true,
        }),
        createItem({
          label: locale('menu.subscriptions'),
          icon: gatorIconSubscription,
          id: 'drawer-menu-button-billing-subscriptions',
          to: `${locale('routes.subscriptions')}`,
          onClick: () => subscriptionAnalytics.subscriptionsMenuClick(),
          show: true,
        }),
        createItem({
          label: locale('menu.creditCard'),
          icon: gatorIconCreditCard,
          id: 'drawer-menu-button-billing-creditCard',
          to: `${locale('routes.creditcard')}`,
          onClick: () => subscriptionAnalytics.subscriptionsMenuClick(),
          show: true,
        }),

      ],
    }),
    createItem({
      exitApp: true,
      icon: gatorIconSupport,
      id: 'drawer-menu-button-help',
      label: locale('menu.support'),
      to: locale('routes.help'),
      onClick: () => startSupport(),
      showInDevice: 'mobile',
    }),
    createItem({
      exitApp: true,
      icon: gatorAlertsBell,
      id: 'drawer-menu-button-notifications',
      label: locale('menu.myAlerts'),
      onClick: () => setAlertsVisible(true),
      show: true,
      showInDevice: 'mobile',
    }),
    createItem({
      label: `${locale('menu.profile')}`,
      icon: gatorIconPerfil,
      showInDevice: 'mobile',
      show: isBillingSignaturesEnaled,
      children: [
        createItem({
          label: `${locale('menu.myData')}`,
          icon: gatorIconData,
          id: 'drawer-menu-button-billing',
          to: `${locale('routes.myData')}`,
          show: true,
        }),
        createItem({
          label: `${locale('menu.changePassword')}`,
          icon: gatorIconPassword,
          id: 'drawer-menu-button-billing-subscriptions',
          to: `${locale('routes.changePassword')}`,
          onClick: () => subscriptionAnalytics.subscriptionsMenuClick(),
          show: !isAccountSecurityEnabled,
        }),
        createItem({
          label: `${locale('menu.accountSecurity')}`,
          icon: gatorIconPassword,
          id: 'drawer-menu-button-profile-account-security',
          to: `${locale('routes.accountSecurity')}`,
          show: isAccountSecurityEnabled,
        }),
        createItem({
          label: `${locale('menu.servicesStatus')}`,
          icon: gatorIconStatus,
          id: 'drawer-menu-button-billing-subscriptions',
          to: getLinkServerStatus(),
          onClick: () => subscriptionAnalytics.subscriptionsMenuClick(),
          show: true,
        }),
        createItem({
          id: `${locale('menu.manageUsers')}`,
          icon: gatorIconPerfil,
          leftIconSize: 'md',
          label: locale('menu.manageUsers'),
          show: moreThanOneUser,
          onClick: () => handleClick({ id: profileIds.manageUsers, route: MANAGE_USERS_URL, label: `${locale('menu.manageUsers')}` }),
        }),
        createItem({
          exitApp: true,
          icon: gatorIconExit,
          id: 'drawer-menu-button-logout',
          label: locale('menu.quit'),
          to: LOGOUT_URL,
          showInDevice: 'mobile',
          show: true,
        }),
      ],
    }),
  ];

  const items = ({ asaasVariation }) => {
    let asaasLabel = '';
    let tagInText = false;
    switch (asaasVariation) {
      case 'variation_B':
        asaasLabel = locale('menu.asaas_b');
        break;
      case 'variation_C':
        asaasLabel = locale('menu.asaas_c');
        tagInText = true;
        break;
      case 'variation_A':
      default:
        asaasLabel = locale('menu.asaas_a');
    }

    return menuArrayV2({
      asaas: { label: asaasLabel, tagInText },
    });
  };

  return {
    createItem,
    items,
  };
};

export default useMenu;
