import React, { useState } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './TitanAdvertisementModal.styles';
import { locale } from '@/utils/locale';
import { titanAdvertisement } from './TitanAdvertisementModal.locale';
import { Button, SelectBottomDashed } from '@/pages/common';
import { useHistory } from 'react-router';
import {
  executeWithParams,
} from '@/utils/ThirdParties/tagManager';

const TitanAdvertisementModal = ({
  displayModal = false,
  setDisplayModal,
  domainOptions,
  testId = 'titan-advertisement-modal',
  typeAdvertisement,
}) => {
  const [selectDomain, setSelectDomain] = useState();
  const [isDisabledButton, setDisabledButton] = useState(true);
  const history = useHistory();
  const listDomains = domainOptions.map(domainObject => ({ value: domainObject.domain, label: `@${domainObject.domain}` }));
  const [defaultLabelSelect, setDefaultLabelSelect] = useState(locale(titanAdvertisement.selectDomainModal));

  const handleClick = () => {
    history.push(`${locale(titanAdvertisement.urlEmailList)}${locale(titanAdvertisement.pageHirePlanActiveCpanel)}/${selectDomain.domain}?idPro=${selectDomain.pro_email_id}`);
    setDisplayModal(false);

    executeWithParams(
      'gaEvent',
      'e-mail',
      `${typeAdvertisement} - ativação Titan em Meus Sites`,
      'Ativar plano',
    );
  };

  const handleOnClose = () => {
    setDisplayModal(false);
    setSelectDomain(null);
    setDefaultLabelSelect(locale(titanAdvertisement.selectDomainModal));
    setDisabledButton(true);

    executeWithParams(
      'gaEvent',
      'e-mail',
      `${typeAdvertisement} - ativação Titan em Meus Sites`,
      'Fechar modal',
    );
  };


  const handleDomainSelected = (domain) => {
    const domainSelected = domainOptions.filter(domainObject => `@${domainObject.domain}` === domain.label);
    setDefaultLabelSelect(domain.label);

    setSelectDomain(domainSelected[0]);
    setDisabledButton(false);

    executeWithParams(
      'gaEvent',
      'e-mail',
      `${typeAdvertisement} - ativação Titan em Meus Sites`,
      'Selecionar domínio',
    );
  };

  return (
    displayModal && (
    <Modal onClose={handleOnClose} testId={testId} disableOverFlow>
      <Styles.ContentWrapper>
        <Styles.Title>{locale(titanAdvertisement.titleModal)}</Styles.Title>
      </Styles.ContentWrapper>
      <Styles.DivSelector>
        <Styles.SelectContainer>
          <SelectBottomDashed
            label={defaultLabelSelect}
            options={listDomains}
            onChange={handleDomainSelected}
            value={selectDomain}
            width="288px"
            position="absolute"
          />
        </Styles.SelectContainer>
      </Styles.DivSelector>
      <Styles.FooterWrapper>
        <Button
          titleEnabled={false}
          disabled={isDisabledButton}
          label={locale(titanAdvertisement.buttonLabelModal)}
          testId="titan-advertisement-active"
          onClick={handleClick}
        />
      </Styles.FooterWrapper>
    </Modal>
    )
  );
};

export default TitanAdvertisementModal;
