import React, {
  createContext, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { find, propEq } from 'ramda';
import { useParams } from 'react-router';
import {
  loadEmails as requestGetCpanelEmailList,
  requestGetProEmailList,
  checkProEmailUpdate,
} from '@/redux/actions/emails';
import { ERROR_GET_PRO_EMAILS_LIST } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError } from '@/utils/Application/notifier';
import { loadGeneralProducts } from '@/redux/actions/generalProducts';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';

export const EmailsContext = createContext();

let handleSeachTimeOut = null;
let searchInterval = null;

const EmailsContextProvider = ({ t, children }) => {
  const { hosting } = useParams();

  const dispatch = useDispatch();

  const [firstLoad, setFirstLoad] = useState(true);
  const [firstProductLoad, setFirstProductLoad] = useState(true);
  const [renderedEmailList, setRenderedEmailList] = useState([]);
  const [lookingForUpdates, toggleLookingForUpdates] = useState(false);

  const proEmailList = useSelector(state => state.emails.proEmailList);
  const cpanelEmailsRedux = useSelector(state => state.emails.list);
  const loadingCpanel = useSelector(state => state.emails.loading);
  const loadingProEmails = useSelector(state => state.emails.loadingProEmails);

  const products = useSelector(state => state.generalProducts.byId);

  const [typpedTerm, setTyppedTerm] = useState('');
  const [searchLoader, setSearchLoader] = useState(false);
  const [filterEmailsBy, setFilterEmailsBy] = useState(['withoutPlan', 'proemail', 'cpanel']);
  const filterOptions = [
    t('proemail'),
    t('cpanel'),
    t('withoutPlan'),
  ];

  const [domainsFiltered, setDomainsFiltered] = useState([]);
  const [domainsFilteredWithId, setDomainsFilteredWithId] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);

  const setupDomainOptions = useCallback(() => {
    if (products && products.length > 0) {
      const domainArray = [];
      const hostArray = new Set();
      const domainArrayWithHosting = [];
      const selectedDomains = [];

      proEmailList.forEach((item) => {
        if (item.hostname) {
          hostArray.add(item.hostname);
        }
      });

      hostArray.forEach((hostname) => {
        const plan = find(propEq('hostname', hostname), products);
        if (plan) {
          domainArray.push(`${plan.productname} (${plan.domain})`);
          domainArrayWithHosting.push({ hosting: plan.hostname, name: `${plan.productname} (${plan.domain})` });
        }
      });

      domainArrayWithHosting.forEach((item) => {
        if (item.hosting === hosting) {
          selectedDomains.push(item.name);
        }
      });

      setDomainOptions(domainArray);
      setDomainsFiltered(selectedDomains);
      setDomainsFilteredWithId(domainArrayWithHosting);
    }
  }, [proEmailList, products, hosting]);

  const getEmailsRecords = useCallback(() => {
    if (firstLoad) {
      setFirstLoad(false);

      dispatch(requestGetCpanelEmailList()).then(() => {
        dispatch(requestGetProEmailList(28)).then((response) => {
          if (response.type === ERROR_GET_PRO_EMAILS_LIST) {
            dispatch(enqueueSnackbar(t('proEmail.failedToLoadList'), notifierError));
          }
        });
      });
    }

    if (proEmailList.length) {
      const hasPending = proEmailList.find(product => product.packageid === TRIAL_PLAN && (product.status.toLowerCase() === 'pending' || product.status.toLowerCase() === 'provisioning'));

      if (!firstLoad && !lookingForUpdates) {
        if (hasPending) {
          searchInterval = window.setInterval(() => {
            dispatch(checkProEmailUpdate());
          }, 10000);
          toggleLookingForUpdates(true);
        }
      }

      if (!firstLoad && lookingForUpdates && !hasPending) {
        clearInterval(searchInterval);
        toggleLookingForUpdates(false);
      }
    }
  }, [dispatch, t, firstLoad, proEmailList, toggleLookingForUpdates, lookingForUpdates]);

  const getGeneralProducts = useCallback(() => {
    const isEmptyProductList = products && products.length === 0;

    if (isEmptyProductList && firstProductLoad) {
      dispatch(loadGeneralProducts());
      setFirstProductLoad(true);
    }
  }, [dispatch, products, firstProductLoad]);

  const sortCards = useCallback((cards) => {
    const sortedCards = [];

    const ordenedStatus = ['pending', 'provisioning', 'suspended', 'active', null, 'terminated', 'expired'];

    ordenedStatus.forEach((ordenedStatus) => {
      cards.forEach((card) => {
        if (ordenedStatus === null && card.status === null) {
          sortedCards.push(card);
        } else if (card.status && ordenedStatus && (ordenedStatus.toLowerCase() === card.status.toLowerCase())) {
          sortedCards.push(card);
        }
      });
    });

    return sortedCards;
  }, []);

  const getFilteredType = useCallback((option) => {
    const selectedOption = option || filterEmailsBy;

    const filteredType = [];

    selectedOption.forEach((option) => {
      switch (option) {
        case t('withoutPlan'):
          filteredType.push(null);
          break;
        case t('proemail'):
          filteredType.push('proemail');
          break;
        case t('cpanel'):
        default:
          filteredType.push('cpanel');
          break;
      }
    });

    return filteredType;
  }, [t, filterEmailsBy]);

  const removeOlderThan31 = cards => cards.filter((card) => {
    if ((card.status !== 'pending' && card.status !== 'provisioning') || card.packageid === TRIAL_PLAN) {
      return card;
    }

    const dateDiff = differenceInDays(new Date(), new Date(card.nextduedate));
    return dateDiff <= 31;
  });

  const removeCardsWithoutPlan = cards => cards.filter(card => card.plan !== null);

  const filterCards = useCallback(({ term, option, domainOption }) => {
    let filteredCards = [];

    const filteredType = getFilteredType(option);

    if (filteredType.length > 0) {
      filteredType.forEach((filter, idx) => {
        if (filter === null) {
          const newCards = [...proEmailList].filter(card => card.plan === null);
          newCards.forEach(card => (filteredCards.push(card)));
        } else {
          const newCards = [...proEmailList].filter(card => card.type === filteredType[idx] && card.plan !== null);
          newCards.forEach(card => (filteredCards.push(card)));
        }
      });
    }
    filteredCards = filteredCards.filter(card => card.domain.toLowerCase().includes(term.toLowerCase()));

    filteredCards = removeCardsWithoutPlan(filteredCards);

    filteredCards = removeOlderThan31(filteredCards);

    if (hosting && domainOption.length > 0) {
      const filteringCards = filteredCards;
      filteredCards = [];

      domainOption.forEach((domain) => {
        const domainFilteredCards = filteringCards.filter((card) => {
          const plan = find(propEq('name', domain), domainsFilteredWithId);
          return card.hostname === plan.hosting;
        });

        domainFilteredCards.forEach((card) => {
          filteredCards.push(card);
        });
      });
    }

    setRenderedEmailList(sortCards(filteredCards));
  }, [domainsFilteredWithId, getFilteredType, hosting, proEmailList, sortCards]);

  useEffect(() => {
    if (domainsFiltered.length > 0) {
      filterCards({ term: typpedTerm, option: filterEmailsBy, domainOption: domainsFiltered });
    }
  }, [filterCards, typpedTerm, filterEmailsBy, domainsFiltered]);

  useEffect(() => {
    getGeneralProducts();
    getEmailsRecords();
  }, [getEmailsRecords, getGeneralProducts]);

  useEffect(() => {
    if (proEmailList && proEmailList.length > 0) {
      setRenderedEmailList(sortCards(removeCardsWithoutPlan(removeOlderThan31([...proEmailList]))));

      setupDomainOptions();
    }
  }, [proEmailList, cpanelEmailsRedux, sortCards, setupDomainOptions]);

  const handleTyppedTerm = (term) => {
    clearTimeout(handleSeachTimeOut);
    setTyppedTerm(term);
    setSearchLoader(true);

    handleSeachTimeOut = setTimeout(() => {
      filterCards({ term, option: filterEmailsBy });
      setSearchLoader(false);
    }, 500);
  };

  const handleSelectOption = (option) => {
    setFilterEmailsBy(option);
    filterCards({ term: typpedTerm, option });
  };

  const handleSelectDomain = (domainOption) => {
    if (hosting) {
      setDomainsFiltered(domainOption);
      filterCards({ term: typpedTerm, option: filterEmailsBy, domainOption });
    }
  };

  const values = {
    loadingCpanel,
    loadingProEmails,
    firstLoad,
    renderedEmailList,
    typpedTerm,
    handleTyppedTerm,
    handleSelectOption,
    searchLoader,
    filterEmailsBy,
    setFilterEmailsBy,
    filterOptions,
    domainOptions,
    domainsFiltered,
    handleSelectDomain,
  };

  return (
    <EmailsContext.Provider value={values}>{children}</EmailsContext.Provider>
  );
};

EmailsContextProvider.propTypes = {
  children: PropTypes.node,
};

EmailsContextProvider.defaultProps = {
  children: null,
};

export default withI18n()(EmailsContextProvider);
