import { useResize } from '@/hooks/useResize';
import React, {
  useCallback, useContext, useEffect, useRef,
} from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeContext } from 'styled-components';
import * as Styles from './TooltipCard.styles';

const TooltipCard = ({
  content = 'tooltip sample',
  toolTipWrapperCurrent,
  width = 'fit-content',
  preferredPosition = null,
  padding = 8,
  testId = 'tooltip-card',
}) => {
  const theme = useContext(ThemeContext);

  const view = useResize();
  const tooltipCard = useRef();
  const domNode = document.body;

  const setMobilePosition = useCallback(() => {
    const smWidth = theme.v1.breakpoints.sm.replace(/px/, '');

    if (view.width <= smWidth) {
      tooltipCard.current.style.left = '10px';
    }
  }, [view, theme]);

  const validateTooltipFit = useCallback(({ toolTipWrapperCurrent }) => {
    const father = toolTipWrapperCurrent.current.getBoundingClientRect();
    const card = tooltipCard.current.getBoundingClientRect();

    const fatherTopPosition = father.top;
    const fatherLeftPosition = father.left;
    const fatherWidth = father.width;
    const fatherHeight = father.height;

    const tooltipWidth = card.width;
    const tooltipHeight = card.height;

    const tooltipFitAt = {
      right: () => (fatherLeftPosition + fatherWidth + tooltipWidth) < view.width,
      left: () => (fatherLeftPosition - fatherWidth - tooltipWidth) > 0,
      bottom: () => (fatherTopPosition + tooltipHeight) < view.height,
      top: () => (fatherTopPosition - tooltipHeight) > 0,
    };

    const setTooltipAt = {
      right: () => {
        tooltipCard.current.style.top = `${fatherTopPosition - (tooltipHeight / 4)}px`;
        tooltipCard.current.style.left = `${fatherLeftPosition + fatherWidth + padding}px`;
      },
      left: () => {
        tooltipCard.current.style.top = `${fatherTopPosition - (tooltipHeight / 4)}px`;
        tooltipCard.current.style.left = `${fatherLeftPosition - tooltipWidth - padding}px`;
      },
      bottom: () => {
        tooltipCard.current.style.top = `${fatherTopPosition + fatherHeight + padding}px`;
        tooltipCard.current.style.left = `${fatherLeftPosition}px`;
        setMobilePosition(view);
      },
      top: () => {
        tooltipCard.current.style.top = `${fatherTopPosition - tooltipHeight - padding}px`;
        tooltipCard.current.style.left = `${fatherLeftPosition}px`;
        setMobilePosition(view);
      },
    };

    if (preferredPosition && tooltipFitAt[preferredPosition]()) {
      return setTooltipAt[preferredPosition]();
    }

    if (tooltipFitAt.right()) {
      return setTooltipAt.right();
    }

    if (tooltipFitAt.left()) {
      return setTooltipAt.left();
    }

    if (tooltipFitAt.bottom()) {
      return setTooltipAt.bottom();
    }

    if (tooltipFitAt.top()) {
      return setTooltipAt.top();
    }
  }, [setMobilePosition, view, preferredPosition, padding]);

  useEffect(() => {
    if (toolTipWrapperCurrent && view) {
      validateTooltipFit({ toolTipWrapperCurrent });
    }
  }, [toolTipWrapperCurrent, view, validateTooltipFit]);

  return ReactDOM.createPortal(
    <Styles.TooltipCard ref={tooltipCard} width={width} data-testid={testId}>
      {content}
    </Styles.TooltipCard>,
    domNode,
  );
};

export default TooltipCard;
