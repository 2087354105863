import React, { useContext } from 'react';
import * as Styles from './FormMainData.styles';
import { Button, TextButton } from '@/pages/common/v1';
import { Input } from '@/pages/common/v1/inputs';
import { asaasEnum } from '../../AsaasHandler.enum';
import Loader from '@/components/Layout/Loader';
import { IconArrowForward } from '@/icons';
import { ThemeContext } from 'styled-components';
import { Radio } from '@/pages/common';

const AsaasFirstRow = ({
  testId = 'asaas-form-main-data',
  formData,
  onChange,
  onContinue,
  loadingCep,
  enabledMainDataContinue,
  onGoback,
  callformStepTwoMyCEP,
}) => {
  const theme = useContext(ThemeContext);

  const handleChange = (e) => {
    onChange && onChange({ value: e.target.value, name: e.target.name });
  };

  const handleChangeRadio = (e) => {
    if (e.target.id === 'anchor-agreement') {
      return;
    }

    onChange && onChange({ value: !formData.fields.checkboxConfirmation.value, name: 'checkboxConfirmation' });
  };

  const handleContinue = () => {
    onContinue && onContinue(asaasEnum.formStepper.MAIN_DATA);
  };

  const handleGoBack = () => {
    onGoback && onGoback(asaasEnum.formStepper.MAIN_DATA);
  };

  const handleMyCep = () => {
    callformStepTwoMyCEP && callformStepTwoMyCEP();
  };

  return (
    <Styles.FormContent data-testid={testId}>
      <Styles.FormTtitle>{formData.texts[formData.activeStep].title}</Styles.FormTtitle>
      <Styles.FormDescription>{formData.texts[formData.activeStep].description}</Styles.FormDescription>

      <Styles.InputWrapper>
        <Styles.CepWrapper>
          <Styles.LoaderPosition>
            <Input
              placeholder={formData.fields.cep.placeholder}
              value={formData.fields.cep.value}
              name="cep"
              onChange={handleChange}
              variant="textWithBorder"
              type="text"
              errorMessage={formData.fields.cep.error}
              maxLength={100}
              testId={`${testId}-input-cep`}
              mask="cep"
              readOnly={loadingCep}
            />

            {loadingCep && (
              <Styles.LoaderWrapper>
                <Loader size={14} />
              </Styles.LoaderWrapper>
            )}
          </Styles.LoaderPosition>

          <Styles.AnchoMyCEP href={formData.texts[formData.activeStep].myCepUrl} target="_blank" onClick={handleMyCep}>{formData.texts[formData.activeStep].myCepLabel}</Styles.AnchoMyCEP>
        </Styles.CepWrapper>
        <Input
          placeholder={formData.fields.city.placeholder}
          value={loadingCep ? '...' : formData.fields.city.value}
          name="city"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          errorMessage={formData.fields.city.error}
          testId={`${testId}-input-city`}
          readOnly={formData.fields.city.readonly || loadingCep}
          maxLength={50}
        />
        <Input
          placeholder={formData.fields.street.placeholder}
          value={loadingCep ? '...' : formData.fields.street.value}
          name="street"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          errorMessage={formData.fields.street.error}
          testId={`${testId}-input-street`}
          readOnly={formData.fields.street.readonly || loadingCep}
          maxLength={50}
        />
        <Input
          placeholder={formData.fields.district.placeholder}
          value={loadingCep ? '...' : formData.fields.district.value}
          name="district"
          onChange={handleChange}
          variant="textWithBorder"
          type="text"
          errorMessage={formData.fields.district.error}
          testId={`${testId}-input-district`}
          readOnly={formData.fields.district.readonly || loadingCep}
          maxLength={50}
        />
        <Styles.NumberComplementWrapper>
          <Input
            placeholder={formData.fields.number.placeholder}
            value={loadingCep ? '...' : formData.fields.number.value}
            name="number"
            onChange={handleChange}
            variant="textWithBorder"
            type="text"
            errorMessage={formData.fields.number.error}
            maxLength={10}
            testId={`${testId}-input-number`}
            readOnly={loadingCep}
          />
          <Input
            placeholder={formData.fields.complement.placeholder}
            value={loadingCep ? '...' : formData.fields.complement.value}
            name="complement"
            onChange={handleChange}
            variant="textWithBorder"
            type="text"
            errorMessage={formData.fields.complement.error}
            maxLength={50}
            testId={`${testId}-input-complement`}
            readOnly={loadingCep}
          />
        </Styles.NumberComplementWrapper>

        {!loadingCep && (
          <Styles.CheckboxWrapper
            htmlFor="confirmationRadio"
            data-testid={`${testId}-checkbox-label`}
            onClick={handleChangeRadio}
          >
            <Radio value="checkboxConfirmation" checked={formData.fields.checkboxConfirmation.value} id={`${testId}-checkbox`} />
            {formData.texts[formData.activeStep].agreementRadioLabel}
            <Styles.AnchorAgreement
              href={formData.texts[formData.activeStep].agreementUrl}
              target="_blank"
              id="anchor-agreement"
            >
              {formData.texts[formData.activeStep].agreementLinkLabel}
            </Styles.AnchorAgreement>
          </Styles.CheckboxWrapper>
        )}
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Styles.BackButtonWrapper>
          <IconArrowForward
            size={18}
            fill={theme.v1.color.action.primary}
          />
          <TextButton
            text={formData.texts[formData.activeStep].backButtonLabel}
            onClick={handleGoBack}
            testId={`${testId}-back`}
          />
        </Styles.BackButtonWrapper>
        <Button
          label={formData.texts[formData.activeStep].continueButtonLabel}
          onClick={handleContinue}
          testId={`${testId}-continue`}
          disabled={!enabledMainDataContinue()}
          type="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.FormContent>
  );
};
export default AsaasFirstRow;
