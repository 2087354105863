import { useWizardTipsLocale } from './useWizardTips.locale';
import { SELECTED_TOOL } from './useWizardTips.enum';

const defaultTips = {
  [SELECTED_TOOL.OTHER]: useWizardTipsLocale.softaculous,
  [SELECTED_TOOL.SITEBUILDER]: useWizardTipsLocale.sitebuilder,
  [SELECTED_TOOL.WORDPRESS]: useWizardTipsLocale.wordpress,
  [SELECTED_TOOL.WOOCOMMERCE]: useWizardTipsLocale.wordpress,
  [SELECTED_TOOL.ECOMMERCEPLUS_TRIAL]: useWizardTipsLocale.wordpress,
  [SELECTED_TOOL.ECOMMERCEPLUS]: useWizardTipsLocale.wordpress,
};

const rules = {
  defaultTips,
};

export default rules;
