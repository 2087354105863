import React from 'react';
import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { SitesActionTypes } from './sites.types';
import sitesActions from './sites.actions';
import {
  AWS_GATOR_SOLUTIONS_URL, CACHED_AWS_GATOR_SOLUTIONS_URL, COUNTRY, config,
} from '@/config';
import { authActions } from '@/redux/modules/auth';
import sitesAssembly from './sites.assembly';
import { commonActions } from '@/redux/modules/common';
import { locale } from '@/utils/locale';
import {
  identify as identifyAmplitude, Identify as IdentifyObject,
} from '@amplitude/analytics-browser';
import { isBRServer, mockoonWarning } from '@/utils/Validators/validation';
import { AUTO_SSL_STATUS } from '@/enums/sites/status.enum';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import logger from '@/utils/logger';
import { linkNaBioActions } from '../linkNaBio';

function* getSitesRequest(action) {
  const { id: userId } = yield select(state => state.summary);
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const { withGatorSolutions } = action.payload;
  const brand = isBRServer ? 'br' : 'es';
  yield put(authActions.tokens.jwt.update());

  yield put(sitesActions.loadingSites.set(true));

  let request = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: '/sites',
  };

  if (withGatorSolutions) {
    request = {
      url: `${CACHED_AWS_GATOR_SOLUTIONS_URL}/hostings/domains`,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-region': brand,
        'x-service-token': jwtToken,
      },
    };
  }

  try {
    const response = yield call(api.aws.get, request);

    const sitesData = withGatorSolutions
      ? sitesAssembly.receiveSitesFromGatorSolutions(response)
      : sitesAssembly.receiveSites(response);

    if (sitesData) {
      const identifyObject = new IdentifyObject();
      identifyObject.set('sites_amount', sitesData.length);
      identifyAmplitude(identifyObject);
    }

    yield put(sitesActions.sites.set(sitesData));
    yield put(sitesActions.sites.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getSitesRequest', error);
    yield put(sitesActions.sites.failure());
  }
  yield put(sitesActions.loadingSites.set(false));
}

function* redirectToEdit(action) {
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const {
    hostingId,
    domain,
    history,
    siteBuilderUrl,
    sitesWizardUrl,
    webPresenceUrl,
    cpanelUrl,
  } = action.payload;
  const brand = isBRServer ? 'br' : 'es';
  yield put(authActions.tokens.jwt.update());

  const request = {
    url: `${CACHED_AWS_GATOR_SOLUTIONS_URL}/hosting/${hostingId}/builder_tools/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(sitesActions.site.loadRedirect(domain));
  try {
    const response = yield call(api.aws.get, request);

    const rules = {
      site_builder_unpublished: () => window.open(siteBuilderUrl(domain)),
      site_builder: () => window.open(siteBuilderUrl(domain)),
      empty: () => history.push(`${sitesWizardUrl}/${domain}`),
      webpresence: () => window.open(`${webPresenceUrl}${hostingId}`),
      undentified: () => cpanelUrl({ hostingId }),
    };

    const isInToolsArray = [
      'site_builder_unpublished',
      'site_builder',
      'empty',
      'webpresence',
      'undentified',
    ].includes(response.data.tool);
    const isWordpress = response.data.tool.toLowerCase().includes('wordpress');

    if (isInToolsArray) {
      rules[response.data.tool]();
    }

    if (isWordpress) {
      window.open(`https://${domain}/wp-admin`);
    }

    if (!isInToolsArray && !isWordpress && response.data.tool) {
      cpanelUrl({ hostingId, softaculous: true });
    }
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getSitesRequest', error);
  }
  yield put(sitesActions.site.loadRedirect(false));
}

function* deleteSite(action) {
  const { site, setSiteInExclusion, withGatorSolutions } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { token } = yield select(state => state.auth);
  const { id: userId, builderEmail } = yield select(state => state.summary);

  yield put(sitesActions.loadingExclusion.set(true));

  const request = {
    url: `${config.AWS_SITEBUILDER_URL}/sitebuilder/${builderEmail}/${site.site}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
  };

  try {
    yield call(api.aws.delete, request);

    yield put(commonActions.notifications.set({
      label: (<p>{locale('sites.exclusionToast.success')}</p>),
      type: 'success',
    }));
    yield put(sitesActions.sites.request({ withGatorSolutions }));
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas deleteSite', error);
    yield put(commonActions.notifications.set({
      label: (<p>{locale('sites.exclusionToast.failure')}</p>),
      type: 'error',
    }));
  }
  setSiteInExclusion(false);
  yield put(sitesActions.loadingExclusion.set(false));
}

export function* getDomainsRequest(action) {
  const { getSitesWithBuilderTools } = action.payload;
  yield put(authActions.tokens.jwt.update());
  const { jwt: jwtToken } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);

  yield put(sitesActions.loadingDomains.set(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'X-Client-ID': userId,
        'X-Brand': COUNTRY,
      },
      url: `/clients/domains${getSitesWithBuilderTools ? '?bringDomainsWithBuilderTools=true' : ''}`,
    };

    const response = yield call(api.aws.get, request);
    const domainsData = sitesAssembly.receiveDomains(response);

    yield put(sitesActions.domains.set(domainsData));
    yield put(sitesActions.domains.success());
  } catch (e) {
    yield put(sitesActions.domains.failure());
  }
  yield put(sitesActions.loadingDomains.set(false));
}

export function* getTlds() {
  yield put(sitesActions.loadingTlds.set(true));
  try {
    const request = {
      url: '/api/v3/tld',
      headers: {
        brand: COUNTRY.toUpperCase(),
      },
    };

    const response = yield call(api.whmcs.get, request);

    const tlds = sitesAssembly.receiveTlds(response);

    yield put(sitesActions.tlds.set(tlds));
    yield put(sitesActions.tlds.success());
  } catch (e) {
    yield put(sitesActions.tlds.failure());
  }
  yield put(sitesActions.loadingTlds.set(false));
}

export function* getDomainAvailability(action) {
  yield put(authActions.tokens.jwt.update());
  const { token } = yield select(state => state.auth);
  const { domain } = action.payload;

  yield put(sitesActions.loadingDomainValidation.set(true));
  try {
    const request = {
      url: `${config.API_URL}/clientarea/br/check-domain-available/${domain}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = yield call(api.whmcs.get, request);

    const domainAvailability = sitesAssembly.receiveDomainAvailability(response);

    yield put(sitesActions.domainAvailability.set({ ...domainAvailability, domainAndSubdomain: domain }));
    yield put(sitesActions.domainAvailability.success());
  } catch (e) {
    yield put(sitesActions.domainAvailability.failure());
    if (e.response && e.response.status === 401) {
      yield put(authActions.tokens.jwt.errorDuringRefresh(true));
    }
  }
  yield put(sitesActions.loadingDomainValidation.set(false));
}

export function* getPlans(action) {
  yield put(authActions.tokens.jwt.update());
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);
  const brand = isBRServer ? 'br' : 'es';
  const { withGatorSolutions } = action.payload;
  const endpoint = '/plans';

  yield put(sitesActions.loadingPlans.set(true));
  let request = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: endpoint,
  };

  if (withGatorSolutions) {
    request = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Client-ID': userId,
        'x-region': brand,
        'x-service-token': jwtToken,
      },
      url: CACHED_AWS_GATOR_SOLUTIONS_URL + endpoint,
    };
  }

  try {
    let response = null;

    response = yield call(api.aws.get, request);

    const plansData = sitesAssembly.receivePlans(response);

    yield put(sitesActions.plans.set(plansData));
    yield put(sitesActions.plans.success());
  } catch (e) {
    yield put(sitesActions.plans.failure());
  }
  yield put(sitesActions.loadingPlans.set(false));
}

export function* setWarningSeen() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { jwt: jwtToken } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);

  const request = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: '/sites/warning',
  };

  try {
    yield call(api.aws.post, request);
    yield put(sitesActions.warnings.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas setWarningSeen', error);
    yield put(sitesActions.warnings.failure());
  }
}

export function* linkDomainToPlan(action) {
  yield put(authActions.tokens.jwt.update());
  const { domain, planExternalId, withAditionDomainV2 } = action.payload;
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);
  const brand = isBRServer ? 'br' : 'es';
  const clientareaBaseUrl = `api/v2/clientarea/${brand}/user/hostings`;
  yield put(sitesActions.loading.set(true));

  const request = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-Client-ID': userId,
      'X-Brand': COUNTRY,
    },
    url: '/subscription/domain',
    data: {
      domain,
      external_id: planExternalId,
    },
  };

  if (withAditionDomainV2) {
    request.url = `${clientareaBaseUrl}/domain`;
    request.headers = {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
    };
  }

  try {
    withAditionDomainV2 ? yield call(api.whmcs.post, request) : yield call(api.aws.post, request);
    yield put(sitesActions.aditionalDomain.success());
  } catch (error) {
    if (error && error.message.includes(`A DNS entry for the domain “${domain}.cloudintegrationlatam.com” already exists`)) {
      yield put(sitesActions.aditionalDomain.success());
    } else {
      yield put(sitesActions.aditionalDomain.failure());
    }
  }
  yield put(sitesActions.loading.set(false));
}

export function* changeDomainDns(action) {
  const { domainId, planId, selectedDomain } = action.payload;

  const { token } = yield select(state => state.auth);
  const request = {
    headers: {
      Authorization: token,
    },
    url: `api/v2/clientarea/br/dns/domain/${domainId}`,
    data: { hostingId: planId },
  };

  try {
    yield call(api.whmcs.put, request);
    yield put(sitesActions.changeDomainDns.success());
  } catch (e) {
    yield put(sitesActions.changeDomainDns.failure());
    const error = { ...e, userRequestData: request, selectedDomain };
    logger.error('Error sagas changeDomainDns', error);
  }
}

export function* getDomainDns({ payload }) {
  const { AWS_DNS_SERVICE_URL } = config;
  const { domainLabel, onDomainNotRegistered } = payload;
  yield put(authActions.tokens.jwt.update());
  const { jwt: jwtToken } = yield select(state => state.auth);
  yield put(sitesActions.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      url: `${AWS_DNS_SERVICE_URL}/domains/${domainLabel}/whois`,
    };
    const response = yield call(api.aws.get, request);
    const domainDns = sitesAssembly.receiveDomainDns(response.data);
    yield put(sitesActions.domainDns.set(domainDns));
    yield put(sitesActions.domainDns.success());
    yield put(linkNaBioActions.domainVerify.success());
  } catch (e) {
    yield put(sitesActions.domainDns.failure());
    onDomainNotRegistered && onDomainNotRegistered();
    yield put(linkNaBioActions.domainVerify.failure());
  }
  yield put(sitesActions.loading.set(false));
}

export function* getPlanDns({ payload }) {
  const { planId } = payload;
  yield put(authActions.tokens.jwt.update());
  const { token } = yield select(state => state.auth);

  yield put(sitesActions.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${COUNTRY}/product/hosting/${planId}`,
    };
    const response = yield call(api.whmcs.get, request);
    const hasSiteBuilderActive = sitesAssembly.receiveHasSiteBuilderActive(response.data);
    const planDns = sitesAssembly.receivePlanDns(response.data);

    yield put(sitesActions.hasSiteBuilderActive.set(hasSiteBuilderActive));
    yield put(sitesActions.planDns.set(planDns));
    yield put(sitesActions.planDns.success());
  } catch (e) {
    yield put(sitesActions.planDns.failure());
  }
  yield put(sitesActions.loading.set(false));
}

export function* requestWordPressInstallation({ payload }) {
  yield put(sitesActions.wordpressInstallation.set(true));
  const { jwt: jwtToken, token } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);
  const {
    wordpress,
    attempt,
    setHgPluginIsInstall,
    isWondersuiteEnabled,
    themeId,
    userSelectedWondersuiteTrial,
    isFastCheckoutAddonEnabled,
    userSelectedUseEcommercePlus,
    requestAutoSsl,
  } = payload;

  const defaultAddonId = '14';
  const defaultPaymentMethod = 'braspag_credit_1x';
  const defaultPaymentCycle = 'monthly';

  const data = {
    customer_id: userId,
    softdomain: wordpress.domain,
    softsubmit: 1,
    admin_username: wordpress.admin,
    admin_pass: wordpress.password,
    admin_email: wordpress.email,
    site_name: wordpress.name,
    site_desc: wordpress.description,
    theme_id: themeId,
    sets_name: wordpress.sets,
    external_id: wordpress.external_id,
  };

  const wpType = userSelectedWondersuiteTrial || userSelectedUseEcommercePlus ? 'basic' : '';

  if (isWondersuiteEnabled) {
    data.wp_type = wpType;
  }

  const requestPayload = isFastCheckoutAddonEnabled ? {
    wp_install: data,
  } : data;

  isFastCheckoutAddonEnabled && (userSelectedWondersuiteTrial && !userSelectedUseEcommercePlus) && (requestPayload.addon = {
    products: [],
    service_id: wordpress.external_id.toString(),
    addon_id: defaultAddonId,
    payment_method: defaultPaymentMethod,
    cycle: defaultPaymentCycle,
    offer_contexts: ['criar_site_trial_woopremium_activation'],
    is_trial: true,
  });

  const headers = isWondersuiteEnabled ? { Authorization: token } : {
    Authorization: `Bearer ${jwtToken}`,
    'X-Client-ID': userId,
    'X-Brand': COUNTRY,
  };

  const url = isWondersuiteEnabled
    ? `api/v2/clientarea/${COUNTRY}/user/worpdpress/hiive/configure${attempt ? `?attempt=${attempt}` : ''}`
    : `${config.AWS_PRODUCT_LIFECYCLE_URL}/wordpress/install`;

  try {
    const request = {
      headers,
      data: requestPayload,
      url,
    };
    const response = isWondersuiteEnabled ? yield call(api.whmcs.put, request) : yield call(api.aws.post, request);
    const hgPluginInstalled = sitesAssembly.hgPluginInstalled(response);
    yield put(sitesActions.wordpressInstallation.success());
    yield put(sitesActions.wordpressInstallation.setStatus(true));

    if (hgPluginInstalled) {
      setHgPluginIsInstall(true);
    } else {
      setHgPluginIsInstall(false);
    }
    requestAutoSsl && requestAutoSsl();
  } catch (e) {
    if (attempt && attempt < 3) {
      yield call(requestWordPressInstallation, {
        payload: {
          wordpress, attempt: attempt + 1, themeId, isWondersuiteEnabled, userSelectedWondersuiteTrial, isFastCheckoutAddonEnabled, userSelectedUseEcommercePlus, setHgPluginIsInstall,
        },
      });
    } else {
      yield put(sitesActions.wordpressInstallation.failure());
      yield put(sitesActions.wordpressInstallation.setStatus(false));
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
          </p>
        ),
        type: 'error',
      }));
    }
  }

  yield put(sitesActions.wordpressInstallation.set(false));
}

export function* getSurveyQuestionsAnswered({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { SURVEY_API } = config;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const { id: userId } = yield select(state => state.summary);
  const { surveyId } = payload;
  yield put(sitesActions.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'X-Client-ID': userId,
        'X-Brand': COUNTRY,
      },
      url: `${SURVEY_API}/users/${userId}/surveys/${surveyId}`,
    };

    const response = yield call(api.aws.get, request);
    const surveyAnswersData = sitesAssembly.receiveSurveyQuestionsAnswered(response.data[0]);
    yield put(sitesActions.surveyQuestionsAnswered.set(surveyAnswersData));
    yield put(sitesActions.surveyQuestionsAnswered.success());
  } catch (e) {
    yield put(sitesActions.surveyQuestionsAnswered.failure());
  }
  yield put(sitesActions.loading.set(false));
}

export function* requestAddonCheck({ payload }) {
  const brand = isBRServer ? 'br' : 'es';
  const { token } = yield select(state => state.auth);
  const { hostingId } = payload;
  yield put(sitesActions.loading.set(true));
  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${brand}/user/hostings/${hostingId}/addon_check`,
    };

    const response = yield call(api.whmcs.get, request);
    yield put(sitesActions.addonCheck.success(response.data));
  } catch (e) {
    yield put(sitesActions.addonCheck.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
        </p>
      ),
      type: 'error',
    }));
    if (e.response && e.response.status === 401) {
      yield put(authActions.tokens.jwt.errorDuringRefresh(true));
    }
  }
  yield put(sitesActions.loading.set(false));
}

export function* requestVerifyIfSiteExist({ payload }) {
  const { token } = yield select(state => state.auth);
  const { hostingId, domain } = payload;
  const brand = isBRServer ? 'br' : 'es';
  yield put(sitesActions.verifyIfSiteExist.loading(true));
  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${brand}/user/hostings/site/${hostingId}/${domain}/check`,
    };

    const response = yield call(api.whmcs.get, request);
    const existSiteInfo = sitesAssembly.receiveSiteExistStatus(response.data.data);
    if (existSiteInfo.onlyDefaultFiles) {
      yield put(sitesActions.verifyIfSiteExist.defaultFilesExclude.request(existSiteInfo));
    } else {
      yield put(sitesActions.verifyIfSiteExist.success(existSiteInfo));
    }
  } catch (e) {
    yield put(sitesActions.verifyIfSiteExist.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
        </p>
      ),
      type: 'error',
    }));
    if (e.response && e.response.status === 401) {
      yield put(authActions.tokens.jwt.errorDuringRefresh(true));
    }
  }
  yield put(sitesActions.verifyIfSiteExist.loading(false));
}
export function* uninstallSite({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);

  const {
    hostingId, domain, domainId, domainHasPlan, needToExcludeDefaultFiles, currentlyInstallInfo,
  } = payload;
  const { siteId, siteType, sharedDocumentRoot } = currentlyInstallInfo;

  const brand = isBRServer ? 'br' : 'es';
  const isSoftaculous = siteType === 'softaculous';
  const isCpanel = siteType === 'cpanel';
  const clientareaBaseUrl = `api/v2/clientarea/${brand}/user/hostings`;
  const softaculousUrlUninstall = `${clientareaBaseUrl}/site/${hostingId}/${siteId}/${domain}/uninstall`;
  const cpanelUrlUninstall = `${clientareaBaseUrl}/cpanel/${hostingId}/${domain}/uninstall`;
  const readaptUrl = `${clientareaBaseUrl}/domain/readapt`;
  const headers = {
    Authorization: token,
  };

  const readaptRequest = {
    headers,
    url: readaptUrl,
    data: {
      hosting_id: hostingId,
      domain,
      domain_id: domainId,
    },
  };

  yield put(sitesActions.uninstallSite.loading(true));
  try {
    if (!domainHasPlan) { // if domain doesnt have a plan, add it to selected plan before uninstall
      yield put(sitesActions.aditionalDomain.request(
        domain,
        parseInt(hostingId, 10),
      ));
    }
    if (isSoftaculous) { // Currently install is softaculous: if the site is with shared document root flag true, needs to use readapt after uninstall (one AND other)
      yield call(api.whmcs.useDelete, {
        headers,
        url: softaculousUrlUninstall,
      });
      if (sharedDocumentRoot) {
        yield call(api.whmcs.post, readaptRequest);
      }
    } else if (isCpanel) { // Currently install is cpanel: if the site is with shared document root flag true, needs to use readapt, if isnt, use uninstall (one OR other)
      if (sharedDocumentRoot) {
        yield call(api.whmcs.post, readaptRequest);
      } else {
        yield call(api.whmcs.useDelete, {
          headers,
          url: cpanelUrlUninstall,
        });
      }
    }
    yield put(sitesActions.uninstallSite.success());
    if (needToExcludeDefaultFiles) { // // use the action for automatically uninstall (when only default files are found)
      yield put(sitesActions.verifyIfSiteExist.defaultFilesExclude.success());
    }
  } catch (e) {
    if (needToExcludeDefaultFiles) { // use the action for automatically uninstall (when only default files are found)
      yield put(sitesActions.verifyIfSiteExist.defaultFilesExclude.failure());
      yield put(commonActions.notifications.set({
        label: (
          <p>
            {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
          </p>
        ),
        type: 'error',
      }));
    } else { // use the action for manual uninstall
      yield put(sitesActions.uninstallSite.failure());
    }
  }
  yield put(sitesActions.uninstallSite.loading(false));
}

export function* requestDomainDnsStatusCheck({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const brand = isBRServer ? 'br' : 'es';
  const { token } = yield select(state => state.auth);
  const { domain } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      url: `api/v2/clientarea/${brand}/domains/propagation`,
    };
    const response = yield call(api.whmcs.get, request);

    yield put(sitesActions.domainDnsStatusCheck.success(sitesAssembly.receiveChoosenDomainStatus(response.data.data, domain)));
  } catch (e) {
    yield put(sitesActions.domainDnsStatusCheck.failure());
  }
}

export function* requestDomainsDnsStatusCheck() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const brand = isBRServer ? 'br' : 'es';
  const { token } = yield select(state => state.auth);
  const { jwt: jwtToken } = yield select(state => state.auth);
  yield put(sitesActions.allDomainsDnsStatusCheck.loading(true));
  try {
    const request = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      url: `api/v2/clientarea/${brand}/domains/propagation`,
    };
    const response = yield call(api.whmcs.get, request);
    yield put(sitesActions.allDomainsDnsStatusCheck.success(response.data.data));
  } catch (e) {
    yield put(sitesActions.allDomainsDnsStatusCheck.failure());
  }
  yield put(sitesActions.allDomainsDnsStatusCheck.loading(false));
}


export function* requestAutoSSLStart({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);
  const { domain, hostingId } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'requestAutoSSLStart' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${hostingId}/start-autossl/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  try {
    const response = yield call(api.aws.post, request);
    if (response.data.success) {
      yield put(sitesActions.ssl.start.success());
    } else {
      yield put(sitesActions.ssl.start.failure());
    }
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas requestAutoSSLStart', error);
    yield put(sitesActions.ssl.start.failure());
  }
}

export function* requestAutoSSLProgress({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);
  const { domain, hostingId } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const { ssl } = yield select(state => state.sites);
  const brand = isBRServer ? 'br' : 'es';
  const lastLoop = ssl.callLoop === 2;

  mockoonWarning({ name: 'requestAutoSSLProgress' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${hostingId}/check-autossl-progress`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  try {
    const response = yield call(api.aws.get, request);

    if (!lastLoop && response.data.in_progress) {
      yield put(sitesActions.ssl.progress.loop({ callLoop: ssl.callLoop += 1 }));
    }

    if (lastLoop && response.data.in_progress) {
      yield put(sitesActions.ssl.progress.failure());
    }

    if (!response.data.in_progress) {
      yield put(sitesActions.ssl.check.request({ domain, hostingId }));
    }
  } catch (e) {
    yield put(sitesActions.ssl.progress.failure());
  }
}

export function* requestAutoSSLCheck({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);
  const { hostingId, domain } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'requestAutoSSLCheck' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${hostingId}/ssl-status/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  try {
    const response = yield call(api.aws.get, request);

    if (response.data && response.data.status === 'active') {
      yield put(sitesActions.ssl.check.success());
    }

    if (response.data && (response.data.status === 'activation_failed' || response.data.status === 'not_installed')) {
      yield put(sitesActions.ssl.check.failure());
    }
  } catch (e) {
    yield put(sitesActions.ssl.check.failure());
  }
}

export function* requestSslVerification({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);
  const { domain, hostingId } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'requestSslVerification' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/hosting/${hostingId}/ssl-pending-check/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(sitesActions.ssl.verification.loading(true));
  try {
    const response = yield call(api.aws.get, request);
    if (response.data) {
      yield put(sitesActions.ssl.verification.set(response.data && response.data.status));
    }
  } catch (e) {
    yield put(sitesActions.ssl.verification.set(AUTO_SSL_STATUS.CAN_INSTALL_SSL));
  }
  yield put(sitesActions.ssl.verification.loading(false));
}

export function* postSslDomainConfiguration({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token } = yield select(state => state.auth);
  const { domain } = payload;
  const { jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'postSslDomainConfiguration' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/ssl/domain_configuration/${domain}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(sitesActions.ssl.pointDomain.loading(true));
  try {
    const response = yield call(api.aws.post, request);
    if (response.data.success) {
      yield put(sitesActions.ssl.pointDomain.success());
    } else {
      yield put(sitesActions.ssl.pointDomain.failure());
    }
  } catch (e) {
    yield put(sitesActions.ssl.pointDomain.failure());
  }
  yield put(sitesActions.ssl.pointDomain.loading(false));
}

export function* hasWelcomeChecklistInProgress({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }
  mockoonWarning({ name: 'hasWelcomeChecklistInProgress ' });

  const { token } = yield select(state => state.auth);
  const { clientId, action } = payload;
  const brand = isBRServer ? 'br' : 'es';
  try {
    const request = {
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${brand}/welcome-checklist?client_id=${clientId}`,
    };
    const response = yield call(api.whmcs.get, request);
    if (response && response.data && response.data.data) {
      yield put(sitesActions.welcomeChecklist.set(response.data.data));
      action && action(true);
      yield put(sitesActions.welcomeChecklist.success());
    }
  } catch (e) {
    yield put(sitesActions.welcomeChecklist.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
        </p>
      ),
      type: 'error',
    }));
  }
}

export function* updateStepWelcomeChecklist({ payload }) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  mockoonWarning({ name: 'hasWelcomeChecklistInProgress ' });
  const { token } = yield select(state => state.auth);

  const {
    clientId,
    hostingId,
    step,
    check,
  } = payload;

  const brand = isBRServer ? 'br' : 'es';
  try {
    const request = {
      data: {
        step,
        hostingId,
        userId: clientId,
        check,
      },
      headers: {
        Authorization: token,
      },
      url: `api/v2/clientarea/${brand}/welcome-checklist/`,
    };
    const response = yield call(api.whmcs.post, request);
    if (response && response.data && response.data.data) {
      yield put(sitesActions.welcomeChecklist.success());
    }
  } catch (e) {
    yield put(sitesActions.welcomeChecklist.failure());
    yield put(commonActions.notifications.set({
      label: (
        <p>
          {`${locale('sitesWizard.wordPressInstall.errorToast')}`}
        </p>
      ),
      type: 'error',
    }));
  }
}

export function* requestCreateRdLead({ payload }) {
  const { token } = yield select(state => state.auth);
  const {
    plan, domain,
  } = payload;

  const { name, phoneNumber, email } = yield select(state => state.summary);

  try {
    const request = {
      headers: {
        Authorization: token,
      },
      data: {
        payload: {
          name,
          email,
          personal_phone: phoneNumber,
          cf_hg_wiz_criar_site_dominio_apontamento: domain,
          cf_hg_wiz_criar_site_plano_selecionado: plan,
          conversion_identifier: 'hg-wizard-criar-site',
        },
        event_type: 'CONVERSION',
        event_family: 'CDP',
      },
      url: `/platform/conversions?api_key=${config.ECOMMERCE_RD_TOKEN}`,
    };

    yield call(api.rdStation.post, request);
  } catch (e) {
    logger.error('Error create RD lead', e);
  }
}

function* sagas() {
  yield takeLatest(SitesActionTypes.REQUEST_GET_SITES, getSitesRequest);
  yield takeLatest(SitesActionTypes.REQUEST_GET_TLDS, getTlds);
  yield takeLatest(SitesActionTypes.REQUEST_GET_DOMAIN_AVAILABILITY, getDomainAvailability);
  yield takeLatest(SitesActionTypes.REQUEST_GET_DOMAINS, getDomainsRequest);
  yield takeLatest(SitesActionTypes.DELETE_SITE, deleteSite);
  yield takeLatest(SitesActionTypes.SET_WARNING_SEEN, setWarningSeen);
  yield takeLatest(SitesActionTypes.REQUEST_WORDPRESS_INSTALLATION, requestWordPressInstallation);
  yield takeLatest(SitesActionTypes.REQUEST_GET_PLANS, getPlans);
  yield takeLatest(SitesActionTypes.REQUEST_ADITIONAL_DOMAIN_TO_PLAN, linkDomainToPlan);
  yield takeLatest(SitesActionTypes.REQUEST_CHANGE_DOMAIN_DNS, changeDomainDns);
  yield takeLatest(SitesActionTypes.REQUEST_DOMAIN_DNS, getDomainDns);
  yield takeLatest(SitesActionTypes.REQUEST_PLAN_DNS, getPlanDns);
  yield takeLatest(SitesActionTypes.REQUEST_SURVEY_QUESTIONS_ANSWERED, getSurveyQuestionsAnswered);
  yield takeLatest(SitesActionTypes.REQUEST_ADDON_CHECK, requestAddonCheck);
  yield takeLatest(SitesActionTypes.REQUEST_VERIFY_SITE_EXIST, requestVerifyIfSiteExist);
  yield takeLatest(SitesActionTypes.REQUEST_DOMAIN_DNS_STATUS_CHECK, requestDomainDnsStatusCheck);
  yield takeLatest(SitesActionTypes.AUTO_SSL_START_REQUEST, requestAutoSSLStart);
  yield takeLatest(SitesActionTypes.AUTO_SSL_PROGRESS_REQUEST, requestAutoSSLProgress);
  yield takeLatest(SitesActionTypes.AUTO_SSL_CHECK_REQUEST, requestAutoSSLCheck);
  yield takeLatest(SitesActionTypes.SSL_VERIFICATION_REQUEST, requestSslVerification);
  yield takeLatest(SitesActionTypes.REQUEST_POST_DOMAIN_TO_HG, postSslDomainConfiguration);
  yield takeLatest(SitesActionTypes.REQUEST_ALL_DOMAINS_DNS_STATUS_CHECK, requestDomainsDnsStatusCheck);
  yield takeLatest(SitesActionTypes.REQUEST_UNINSTALL_SITE, uninstallSite);
  yield takeLatest(SitesActionTypes.GET_STEP_WELCOME_CHECKLIST_REQUEST, hasWelcomeChecklistInProgress);
  yield takeLatest(SitesActionTypes.POST_STEP_WELCOME_CHECKLIST_REQUEST, updateStepWelcomeChecklist);
  yield takeLatest(SitesActionTypes.RD_CREATE_LEAD_REQUEST, requestCreateRdLead);
  yield takeLatest(SitesActionTypes.REDIRECT_TO_EDIT, redirectToEdit);
}

export default sagas;
