import React from 'react';
import * as Style from './IconDetailedWishlist.style';

const IconDetailedWishlist = ({
  size = '30',
  testId = 'icon-detailed-host',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1986_1684)">
        <path d="M16.875 9.05051C16.875 4.56981 13.3072 0.9375 8.90628 0.9375C4.50525 0.9375 0.9375 4.56981 0.9375 9.05051V9.69955C0.9375 14.1802 4.50525 17.8125 8.90628 17.8125C13.3072 17.8125 16.875 14.1802 16.875 9.69955V9.05051Z" fill="#2E93EE" />
        <path d="M19.6875 10.3125H9.375V12.1875H19.6875V10.3125Z" fill="#1F2044" />
        <path d="M19.6875 15H9.375V16.875H19.6875V15Z" fill="#1F2044" />
        <path d="M16.875 18.75H9.375V20.625H16.875V18.75Z" fill="#1F2044" />
        <path d="M4.6875 4.6875V27.1875H17.7222V25.1102H6.82655V6.76281H23.1735V16.9946H25.3125V4.6875H4.6875Z" fill="#1F2044" />
        <path d="M19.6875 19.6875L27.1875 22.6139L24.1592 23.9152L23.9395 24.0096L23.8197 24.2122L22.0609 27.1875L19.6875 19.6875Z" fill="white" />
        <path d="M21.0059 21.145L22.3999 25.5692L23.2981 24.0432L23.5324 23.6451L23.9622 23.4596L25.3662 22.8537L21.0059 21.145ZM18.75 18.75L29.0625 22.7914L24.5419 24.7424L21.9991 29.0625L18.75 18.75Z" fill="#1F2044" />
      </g>
      <defs>
        <clipPath id="clip0_1986_1684">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedWishlist;
