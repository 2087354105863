import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: {
    BUY: 'Compra Sitelock',
    LANDINGPAGE: 'Ações landingpage',
    MANAGE_FREE_SITELOCK_ACCESS: 'Acessos à Área do SiteLock',
    COPY_SECURITY_SEAL: 'Selos de Segurança',
    TABS_CHANGES: 'Acesso às Abas do Produto',
    CONTRACT_NOW_CLICK: 'Cliques no Botão "Contrate Agora"',
    SITELOCK_FREE: 'sitelock_free',
  },
  ACTION: {
    SELECT_DOMAIN: 'Selecionar um domínio contratado com a HostGator',
    TYPE_DOMAIN: 'Digitar outro domínio',
    BUY_NOW: 'Sitelock Contrate agora',
    SEE_ALL_PLANS: 'Ver todos os planos SiteLock',
    PROTECT_MY_SITE: 'Quero proteger meu site',
    WANT_MORE_PROTECTION: 'Quero mais proteção',
    GET_NOW_ANCHOR: 'Contrate agora (âncora)',
    MANAGE_FREE_SITELOCK_ACCESS: 'Acessos à tela "Gerenciar SiteLock Grátis"',
    COPY_SECURITY_SEAL: 'Cliques em "Copiar Selo" na tela de Selo de Segurança',
    TABS_CHANGES: label => `Cliques na aba "${label}"`,
    CONTRACT_NOW_CLICK: 'Cliques no botão "Contrate Agora" de um dos planos do SiteLock na tela de upgrade',
    SHOW_LEGACY_MODAL: 'Exibição_modal',
    REDIRECT_LEGACY_MODAL: 'Acessar_Sitelock',
    CLOSE_LEGACY_MODAL: 'Fechar_modal',
  },
  LABEL: {
    MANAGE_FREE_SITELOCK_ACCESS: 'Número de acessos à tela "Gerenciar SiteLock Grátis"',
    COPY_SECURITY_SEAL: 'Número de selos de segurança copiados',
    TABS_CHANGES: 'Número de vezes que a aba é acessada pelos usuários',
    CONTRACT_NOW_CLICK: 'Número de cliques no botão "Contrate Agora" para cada plano',
    LEGACY_MODAL: 'Modal_SitelockFree_Legados',
  },
};

const gaSelectDomain = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: EVENTS.ACTION.SELECT_DOMAIN,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaTypeDomain = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: EVENTS.ACTION.TYPE_DOMAIN,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaCyclePaymentClick = (cycle) => {
  let actionGa = '';
  switch (cycle) {
    case 'annually':
      actionGa = 'Sitelock Anual';
      break;
    case 'semiannually':
      actionGa = 'Sitelock Semestral';
      break;
    case 'quarterly':
      actionGa = 'Sitelock Trimestral';
      break;
    case 'monthly':
      actionGa = 'Sitelock Mensal';
      break;
    default:
      break;
  }

  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: actionGa,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaSelectPaymentMethod = (paymentMethod) => {
  let actionGa = '';
  switch (paymentMethod) {
    case 'paypal':
    case 'gp_paypal':
      actionGa = 'Codeguard Paypal';
      break;
    case 'boleto':
    case 'gp_boleto':
      actionGa = 'Codeguard Boleto';
      break;
    case 'braspag_credit_1x':
      actionGa = 'Codeguard Cartão de crédito';
      break;
    default:
      break;
  }
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: actionGa,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaBuySitelock = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: EVENTS.ACTION.BUY_NOW,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaSeeAllPlans = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
    gaEventAction: EVENTS.ACTION.SEE_ALL_PLANS,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaProtectMySite = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
    gaEventAction: EVENTS.ACTION.PROTECT_MY_SITE,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaWantMoreProtection = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
    gaEventAction: EVENTS.ACTION.WANT_MORE_PROTECTION,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaGetNowAnchor = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
    gaEventAction: EVENTS.ACTION.GET_NOW_ANCHOR,
    gaEventLabel: 'Landingpage Sitelock',
  });
};

const gaFreeSitelockAccess = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.MANAGE_FREE_SITELOCK_ACCESS,
    gaEventAction: EVENTS.ACTION.MANAGE_FREE_SITELOCK_ACCESS,
    gaEventLabel: EVENTS.LABEL.MANAGE_FREE_SITELOCK_ACCESS,
  });
};

const gaCopySecuritySeal = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.COPY_SECURITY_SEAL,
    gaEventAction: EVENTS.ACTION.COPY_SECURITY_SEAL,
    gaEventLabel: EVENTS.LABEL.COPY_SECURITY_SEAL,
  });
};

const gaTabsChange = (label = '') => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.TABS_CHANGES,
    gaEventAction: EVENTS.ACTION.TABS_CHANGES(label),
    gaEventLabel: EVENTS.LABEL.TABS_CHANGES,
  });
};

const gaHandleContractNow = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.CONTRACT_NOW_CLICK,
    gaEventAction: EVENTS.ACTION.CONTRACT_NOW_CLICK,
    gaEventLabel: EVENTS.LABEL.CONTRACT_NOW_CLICK,
  });
};

const gaShowSitelockFreeModal = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.SITELOCK_FREE,
    gaEventAction: EVENTS.ACTION.SHOW_LEGACY_MODAL,
    gaEventLabel: EVENTS.LABEL.LEGACY_MODAL,
  });
};

const gaRedirectSitelockFreeModal = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.SITELOCK_FREE,
    gaEventAction: EVENTS.ACTION.REDIRECT_LEGACY_MODAL,
    gaEventLabel: EVENTS.LABEL.LEGACY_MODAL,
  });
};

const gaCloseSitelockFreeModal = () => {
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.SITELOCK_FREE,
    gaEventAction: EVENTS.ACTION.CLOSE_LEGACY_MODAL,
    gaEventLabel: EVENTS.LABEL.LEGACY_MODAL,
  });
};

const analytics = {
  gaSelectDomain,
  gaTypeDomain,
  gaCyclePaymentClick,
  gaSelectPaymentMethod,
  gaBuySitelock,
  gaSeeAllPlans,
  gaProtectMySite,
  gaWantMoreProtection,
  gaGetNowAnchor,
  newSitelockPage: {
    gaFreeSitelockAccess,
    gaCopySecuritySeal,
    gaTabsChange,
    gaHandleContractNow,
  },
  sitelockFreeModal: {
    show: gaShowSitelockFreeModal,
    redirect: gaRedirectSitelockFreeModal,
    close: gaCloseSitelockFreeModal,
  },
};

export default analytics;
