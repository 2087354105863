import React from 'react';
import { withStyles } from '@material-ui/core';
import { getClassNamesFollowingDomainStatus } from '@/utils/Application/getCssStyleNames';
import styles from './styles';
import classnames from 'classnames';

function DomainStatusLabel({
  classes,
  children,
  domain,
  domainStatus,
  className,
  isNextDueDate,
  isDueDate,

}) {
  const domainName = domain ? domain.name : undefined;
  return (
    <span
      data-id={`domain_status_${domainName}`}
      data-testid="status_atual"
      className={classnames(classes.content,
        {
          [`${classes[getClassNamesFollowingDomainStatus(domainStatus)]} ${className}`]: !isNextDueDate,
          [`${classes[getClassNamesFollowingDomainStatus('NextDueDate')]} ${className}`]: isNextDueDate,
          [`${classes[getClassNamesFollowingDomainStatus('DueDate')]} ${className}`]: isDueDate,
        })}
    >
      {children}
    </span>
  );
}

export default withStyles(styles)(DomainStatusLabel);
