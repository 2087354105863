import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { SecurityActionTypes } from './security.types';
import securityActions from './security.actions';
import securityAssembly from './security.assembly';
import { WHMCS_URL } from '@/config';
import { makeParamsUrl } from '@/utils/Formatters/handleStrings';
import {
  SITELOCK_CLOSE_NEW_MODAL, SITELOCK_OPEN_NEW_MODAL, SITELOCK_SCAN, SITELOCK_SHIELD, SITELOCK_SMARTSCAN,
} from '@/config/api';
import { commonActions } from '../common';
import Cookies from 'js-cookie';
import { TEMPLATE_COOKIES } from '@/components/Layout/Template/Template.types';
import { authActions } from '../auth';
import logger from '@/utils/logger';

function* getSitelockScan(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;

  yield put(securityActions.scanDetails.setLoading(true));
  try {
    const request = {
      url: `${WHMCS_URL}/api/v2/sitelock/sitelock-scan-details/${payload.productType}/${payload.productId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data: {
        ...payload,
      },
    };
    const response = yield call(api.whmcs.get, request);
    const scanDetails = securityAssembly.scanDetailsResponse(response.data);

    yield put(securityActions.scanDetails.success());
    yield put(securityActions.scanDetails.set(scanDetails));
  } catch (e) {
    yield put(securityActions.scanDetails.failure());
  }

  yield put(securityActions.scanDetails.setLoading(false));
}

function* updateSeal(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const {
    formData,
    productType,
    productId,
    setDate,
    successMessage,
    failureMessage,
  } = payload;

  yield put(securityActions.seal.setSavingStatus(true));

  try {
    const request = {
      url: makeParamsUrl(SITELOCK_SHIELD, productType, productId),
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data: {
        color: formData.color.value,
        size: formData.size.value,
        type: formData.type.value,
        language: formData.language.value,
      },
    };
    yield call(api.whmcs.post, request);
    setDate(Date.now());
    yield put(securityActions.seal.setSuccess());
    yield put(commonActions.notifications.set({
      label: successMessage,
      type: 'success',
    }));
  } catch (e) {
    yield put(securityActions.seal.setSuccess());
    yield put(commonActions.notifications.set({
      label: failureMessage,
      type: 'error',
    }));
  }

  yield put(securityActions.seal.setSavingStatus(false));
}

function* requestSeal(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const {
    productType, productId,
  } = payload;

  yield put(securityActions.seal.setLoadingStatus(true));
  try {
    const request = {
      url: makeParamsUrl(SITELOCK_SHIELD, productType, productId),
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
    };
    const response = yield call(api.whmcs.get, request);
    yield put(securityActions.seal.setSeal(response.data.data));
    yield put(securityActions.seal.setSealSuccessRequest());
  } catch (e) {
    yield put(securityActions.seal.setSealFailureRequest());
  }
  yield put(securityActions.seal.setLoadingStatus(false));
}

function* getSitelockScanList(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;

  yield put(securityActions.scanList.setLoading(true));

  const dateFilter = payload.startDate && payload.endDate ? `?start=${payload.startDate}&end=${payload.endDate}` : '';

  try {
    const request = {
      url: `${WHMCS_URL}/api/v2/sitelock/smartscan/complete/${payload.productType}/${payload.productId}${dateFilter}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data: {
        ...payload,
      },
    };
    const response = yield call(api.whmcs.get, request);
    const scanList = securityAssembly.scanHistoryResponse(response.data);

    yield put(securityActions.scanList.success());
    yield put(securityActions.scanList.set(scanList));
  } catch (e) {
    yield put(securityActions.scanList.failure());
  }

  yield put(securityActions.scanList.setLoading(false));
}

function* postSitelockUpgrade(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;

  yield put(securityActions.upgrade.setLoading(true));

  try {
    const request = {
      url: `${WHMCS_URL}/api/v2/upgrades/order`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data: {
        ...payload,
      },
    };

    const response = yield call(api.whmcs.post, request);

    yield put(securityActions.upgrade.success());
    yield put(securityActions.upgrade.set(response.data.data));
  } catch (e) {
    yield put(securityActions.upgrade.failure());
  }

  yield put(securityActions.upgrade.setLoading(false));
}

function* getSitelockSso(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const { hostingId } = payload;

  yield put(securityActions.sso.setLoading(true));

  try {
    const request = {
      url: `${WHMCS_URL}/api/v2/sitelock/sso/${hostingId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
    };

    const response = yield call(api.whmcs.get, request);

    yield put(securityActions.sso.success());
    window.open(response.data.loginURL);
  } catch (e) {
    yield put(securityActions.sso.failure());
  }

  yield put(securityActions.sso.setLoading(false));
}

function* updateSmartScanConfigurations(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const {
    productType, productId, data, successMessage, failureMessage,
  } = payload;

  yield put(securityActions.configurations.update.setLoading(true));

  try {
    const request = {
      url: `${SITELOCK_SMARTSCAN}${productType}/${productId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
      data,
    };

    yield call(api.whmcs.post, request);
    yield put(securityActions.configurations.update.success());
    yield put(commonActions.notifications.set({
      label: successMessage,
      type: 'success',
    }));
  } catch (e) {
    yield put(securityActions.configurations.update.failure());
    yield put(commonActions.notifications.set({
      label: failureMessage,
      type: 'error',
    }));
  }

  yield put(securityActions.configurations.update.setLoading(false));
}

function* getSmartScanConfigurationData(action) {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;
  const { productType, productId } = payload;

  yield put(securityActions.configurations.get.setLoading(true));

  try {
    const request = {
      url: `${SITELOCK_SCAN}${productType}/${productId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Service-Token': jwtToken,
      },
    };

    const response = yield call(api.whmcs.get, request);
    const sitelockConfigurationData = securityAssembly.sitelockConfigurationResponse(response);

    yield put(securityActions.configurations.get.success());
    yield put(securityActions.configurations.get.set(sitelockConfigurationData));
  } catch (e) {
    yield put(securityActions.configurations.get.failure());
  }

  yield put(securityActions.configurations.get.setLoading(false));
}

function* checkIsNewSitelock() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: SITELOCK_OPEN_NEW_MODAL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);

    yield put(securityActions.newSiteLock.check.success());
    yield put(securityActions.newSiteLock.check.set(response.data.data));
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas checkIsNewSitelock', error);
    yield put(securityActions.newSiteLock.check.failure());
  }
}

function* sawNewSitelock() {
  const forceUpdateJwtInReducers = Cookies.get(TEMPLATE_COOKIES.FORCE_UPDATE_JWT_IN_REDUCERS);
  if (forceUpdateJwtInReducers === 'true') {
    yield put(authActions.tokens.jwt.update());
  }

  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: SITELOCK_CLOSE_NEW_MODAL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };
  try {
    yield call(api.whmcs.patch, request);

    yield put(securityActions.newSiteLock.saw.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas sawNewSitelock', error);
    yield put(securityActions.newSiteLock.saw.failure());
  }

  yield put(securityActions.newSiteLock.check.set(false));
}

function* sagas() {
  yield takeLatest(SecurityActionTypes.SITELOCK_SCAN_DETAILS_REQUEST, getSitelockScan);
  yield takeLatest(SecurityActionTypes.UPDATE_SEAL, updateSeal);
  yield takeLatest(SecurityActionTypes.REQUEST_SEAL, requestSeal);
  yield takeLatest(SecurityActionTypes.SITELOCK_SCAN_LIST_REQUEST, getSitelockScanList);
  yield takeLatest(SecurityActionTypes.REQUEST_UPGRADE_SITELOCK, postSitelockUpgrade);
  yield takeLatest(SecurityActionTypes.REQUEST_UPDATE_CONFIGURATIONS, updateSmartScanConfigurations);
  yield takeLatest(SecurityActionTypes.REQUEST_GET_CONFIGURATIONS, getSmartScanConfigurationData);
  yield takeLatest(SecurityActionTypes.REQUEST_SITELOCK_SSO, getSitelockSso);
  yield takeLatest(SecurityActionTypes.REQUEST_CHECK_NEW_SITELOCK, checkIsNewSitelock);
  yield takeLatest(SecurityActionTypes.REQUEST_SAW_NEW_SITELOCK, sawNewSitelock);
}

export default sagas;
