import React, {
  useCallback, useEffect,
  useState,
} from 'react';
import * as Styles from './LinkNaBioHandler.styles';
import useLocale from '@/hooks/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { sitesActions, linkNaBioActions } from '@/redux/modules';
import { Skeleton } from 'gatorcomponents';
import { LinkNaBioList } from './components/LinkNaBioList';
import { useHistory } from 'react-router';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { FAQ_LINK_NA_BIO } from '@/config/urls/linkNaBioUrls';
import { linkNaBioAnalytics } from '@/analytics/linkNaBio';
import withErrorBoundary from '@/utils/errorBoundary';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS, CHARLIE_LINK_NA_BIO_CUSTOM_DOMAIN, CHARLIE_LINK_NA_BIO_DATA_ANALYSIS } from '@/config/GrowthBook/constants';
import { LinkCustomDomainModal } from './components/LinkCustomDomainModal';

const LinkNaBioHandler = () => {
  const [profileToLinkCustomDomain, setProfileToLinkCustomDomain] = useState(undefined);
  const { linkNaBio: linkNaBioLocale, routes: routesLocale } = useLocale();

  const {
    loading, profiles, shouldGetProfiles, domainToLinkExists,
  } = useSelector(state => state.linkNaBio);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadingSites: sitesLoading, list: sites, loading: loadingDomainExists } = useSelector(state => state.sites);
  const { domainsPropagation } = useSelector(state => state.preChat);
  const canShowDataAnalysis = useFeatureIsOn(CHARLIE_LINK_NA_BIO_DATA_ANALYSIS);
  const canShowCustomDomain = useFeatureIsOn(CHARLIE_LINK_NA_BIO_CUSTOM_DOMAIN);
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);

  const allowedSitesToSelect = sites.filter(site => site.sub_status === 'active').flatMap(site => domainsPropagation.filter(propagation => site.domain === propagation.domain));

  const goToWizard = useCallback((profileId) => {
    profileId
      ? history.push(`${routesLocale.linkNaBio}/${routesLocale.wizard}/${profileId}`)
      : history.push(`${routesLocale.linkNaBio}/${routesLocale.wizard}`);
  }, [history, routesLocale]);

  const goToDataAnalysis = useCallback((profile) => {
    history.push(`${routesLocale.linkNaBio}/${routesLocale.dataAnalysis}/${profile.id}`);
  }, [history, routesLocale]);

  const onComponentMount = useCallback(() => {
    if (shouldGetProfiles) {
      dispatch(linkNaBioActions.profile.get.request());
    }

    if (!shouldGetProfiles && !profiles.length) {
      goToWizard();
    }
  }, [shouldGetProfiles, profiles, dispatch, goToWizard]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  const onLoadList = useCallback(() => {
    if (profiles.length) {
      linkNaBioAnalytics.listView();
    }
  }, [profiles]);

  useEffect(() => {
    onLoadList();
  }, [onLoadList]);

  const getListProps = () => {
    const linkToCustomDomain = (profile) => {
      dispatch(sitesActions.sites.request({ withGatorSolutions }));
      setProfileToLinkCustomDomain(profile);
    };

    const editLinkNaBio = (profile) => {
      goToWizard(profile.id);
      linkNaBioAnalytics.editLinkNaBio();
    };

    const deleteLinkNaBio = (profileId) => {
      dispatch(linkNaBioActions.profile.delete.request({ profileId }));
      linkNaBioAnalytics.removeLinkNaBio();
    };

    const defaultProps = {
      profiles,
      locale: linkNaBioLocale.list,
      createLinkNaBio: goToWizard,
      linkToCustomDomain,
      editLinkNaBio,
      deleteLinkNaBio,
      loading,
      goToDataAnalysis,
      canShowDataAnalysis,
      canShowCustomDomain,
    };

    return {
      ...defaultProps,
    };
  };

  const getFaqProps = useCallback(() => ({
    buttonLabel: linkNaBioLocale.faqButton,
    questions: FAQ_LINK_NA_BIO,
  }), [linkNaBioLocale]);

  const getCustomDomainModalProps = useCallback(() => {
    const updateLinkNaBio = ({
      domain, isRemovingDomain, isExternalDomain, nameServer, hostingId, domainId,
    }) => {
      const dataToSend = {
        domainToLink: domain,
        profileId: profileToLinkCustomDomain.id,
        isRemovingDomain,
        isExternalDomain,
        nameServer,
        hostingId,
        domainId,
        callback: () => dispatch(linkNaBioActions.profile.get.request()),
      };
      dispatch(linkNaBioActions.profile.update.request(dataToSend));
    };

    const closeCustomDomainModal = () => {
      setProfileToLinkCustomDomain(undefined);
    };

    const verifyDomain = (domain) => {
      dispatch(sitesActions.domainDns.request(domain));
    };

    const resetVerify = () => {
      dispatch(linkNaBioActions.domainVerify.reset());
    };

    return {
      updateLinkNaBio,
      handleModalDisplay: closeCustomDomainModal,
      locale: linkNaBioLocale.customDomainModal,
      sites: allowedSitesToSelect,
      sitesLoading,
      profile: profileToLinkCustomDomain,
      verifyDomain,
      resetVerify,
      loadingDomainExists,
      domainToLinkExists,
    };
  }, [linkNaBioLocale, dispatch, allowedSitesToSelect, sitesLoading, profileToLinkCustomDomain, loadingDomainExists, domainToLinkExists]);


  return (
    <>
      {shouldGetProfiles && (
        <Styles.SkeletonWrapper>
          <Styles.Colum>
            <Styles.Skeleton gap={16}>
              <Skeleton variant="circle" width="40px" />
            </Styles.Skeleton>

            <Styles.Skeleton gap={16}>
              <Skeleton variant="headline" width="396px" />
            </Styles.Skeleton>
            <Styles.Skeleton gap={8}>
              <Skeleton variant="textLine" width="357px" />
            </Styles.Skeleton>
            <Styles.Skeleton gap={8}>
              <Skeleton variant="textLine" width="357px" />
            </Styles.Skeleton>
            <Styles.Skeleton gap={32}>
              <Skeleton variant="textLine" width="164px" />
            </Styles.Skeleton>

            <Styles.Skeleton gap={40}>
              <Skeleton variant="textLine" width="396px" />
            </Styles.Skeleton>

            <Styles.Skeleton gap={32}>
              <Skeleton variant="input" width="236px" />
            </Styles.Skeleton>

            <Styles.Skeleton gap={80}>
              <Skeleton variant="button" width="120px" />
            </Styles.Skeleton>

            <Styles.Skeleton gap={8}>
              <Skeleton variant="textLine" width="357px" />
            </Styles.Skeleton>
            <Styles.Skeleton gap={8}>
              <Skeleton variant="textLine" width="357px" />
            </Styles.Skeleton>
            <Styles.Skeleton gap={8}>
              <Skeleton variant="textLine" width="164px" />
            </Styles.Skeleton>
          </Styles.Colum>

          <Styles.Colum>
            <Skeleton variant="rectangle" height="480px" width="420px" />
          </Styles.Colum>
        </Styles.SkeletonWrapper>
      )}

      {!shouldGetProfiles
        && <LinkNaBioList {...getListProps()} />
      }

      <Styles.FaqSection>
        <FaqDefault {...getFaqProps()} width="full" withoutButton />
      </Styles.FaqSection>

      {profileToLinkCustomDomain && (
        <LinkCustomDomainModal {...getCustomDomainModalProps()} />
      )}
    </>
  );
};

export default withErrorBoundary(LinkNaBioHandler, 'LinkNaBioHandler');
