import { Typography, withStyles, withWidth } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import styles from './styles';

const PriceBlock = ({
  classes, cycle, annuallyPrice, monthlyPrice, t,
}) => {
  const pricePart = formatCurrency(cycle === 'monthly' ? monthlyPrice : annuallyPrice / 12).split(' ');

  return (
    <Typography className={classes.cardPrice}>
      {t(`${pricePart[0]} ${pricePart[1]}`)}
    </Typography>
  );
};

PriceBlock.propTypes = {
  cycle: PropTypes.string.isRequired,
  annuallyPrice: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
};

export default withI18n()(withWidth()(withStyles(styles)(PriceBlock)));
