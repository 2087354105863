import { Modal } from '@/pages/common';
import React from 'react';
import * as Styles from './UploadFileModal.styles';
import { Button } from 'gatorcomponents';

const UploadFileModal = ({
  uploadModalIsVisible,
  setUploadModal,
  handleEnter,
  handleDrag,
  handleLeave,
  handleDrop,
  type,
  loadingSubmit,
  setFile,
  accept,
  title,
  draggableTitle,
  findFileLabel,
}) => uploadModalIsVisible && (
  <Modal
    maxWidth={544}
    onClose={() => setUploadModal(false)}
    variant="regular"
    disablePadding
  >
    <Styles.UploadModalTitle>
      {title}
    </Styles.UploadModalTitle>

    <Styles.AllowDrag
      onDragEnter={handleEnter}
      onDragOver={handleDrag}
      onMouseLeave={handleLeave}
      onDrop={handleDrop}
      htmlFor="uploadInput"
      role="button"
      as={type}
      data-testid="inputFile"
    >
      <Styles.DraggableTitle>
        {draggableTitle}
      </Styles.DraggableTitle>

      <Button
        type="button"
        onClick={() => null}
        disabled={loadingSubmit}
        label={findFileLabel}
        as="label"
        htmlFor="uploadInput"
      />

    </Styles.AllowDrag>

    <Styles.UploadInput
      id="uploadInput"
      type="file"
      onChange={(e) => {
        setFile(e.target.files[0]);
        setUploadModal(false);
      }}
      accept={accept}
    />
  </Modal>
);

export default UploadFileModal;
