import React, { useState } from 'react';
import {
  withStyles, Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withI18n } from 'react-i18next';
import InputField from '@/components/Fields/InputField';
import AlertBox from '@/components/Security/AlertBox';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { updateSMART } from '@/redux/actions/sitelock';
import { SITELOCK_RESET_TOAST } from '@/redux/actions/actionsTypes';
import styles from './styles';


const SitelockSMARTScanForm = ({
  classes, t, update, closeModal,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { smartscan, domain } = useSelector(state => state.sitelock);
  const [data, setData] = useState({ ...smartscan.data, domain });
  const [formErrors, setFormErrors] = useState({ user: false, password: false, url: false });

  const validateFormFields = () => {
    const charsRule = new RegExp('^[!-z]{2,64}$');

    setFormErrors(
      {
        ...formErrors,
        user: !charsRule.test(data.user),
        password: !charsRule.test(data.password),
      },
    );
  };

  const validateDomain = () => {
    const domainRule = new RegExp('^[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$');

    setFormErrors(
      {
        ...formErrors,
        url: !domainRule.test(data.url),
      },
    );
  };

  const handleUserChange = (e) => {
    validateFormFields();
    setData({ ...data, user: e.target.value });
  };
  const handlePasswordChange = (e) => {
    validateFormFields();
    setData({ ...data, password: e.target.value });
  };
  const handleUrlChange = (e) => {
    validateDomain();
    setData({ ...data, url: e.target.value });
  };

  const handleClose = () => {
    closeModal();
  };

  const handleSend = () => {
    validateFormFields();
    validateDomain();
    setTimeout(async () => {
      if (data.user && data.password && data.url) {
        const shouldldSendForm = !(formErrors.user || formErrors.password || formErrors.url);
        if (shouldldSendForm) {
          await dispatch(updateSMART(params.productType, params.productId, data));
          setTimeout(() => {
            dispatch({ type: SITELOCK_RESET_TOAST });
          }, 2000);
        }
      }
    }, 100);
  };


  const renderSave = () => (<PrimaryButton data-testid="smartscan-btn-confirm" role="button" onClick={handleSend} className={classnames([classes.button, classes.outlinedButton])}>{t('sitelock.smartscan.form.send')}</PrimaryButton>);
  const renderUpdate = () => (
    <div className={classes.buttonsWrapper}>
      <PrimaryButton data-testid="smartscan-btn-cancel" role="button" onClick={handleClose} className={classnames([classes.button, classes.whiteButton])}>{t('sitelock.smartscan.form.cancel')}</PrimaryButton>
      <PrimaryButton data-testid="smartscan-btn-save" role="button" onClick={handleSend} className={classnames([classes.button, classes.outlinedButton])}>{t('sitelock.smartscan.form.update')}</PrimaryButton>
    </div>
  );

  return (
    <div data-testid="smartscan-config-form" className={classes.formSpacing}>
      <Typography variant="h5" className={classes.subtitle}>
        {update ? t('sitelock.smartscan.updateForm.title') : t('sitelock.smartscan.SLtitle')}
      </Typography>
      <Typography variant="h1" className={classes.description}>{t('sitelock.smartscan.SLsubtitle')}</Typography>
      <div className={update ? classes.formContainerUpdate : classes.formContainer}>
        <InputField defaultValue={smartscan.data.user} id="name" label={t('sitelock.smartscan.form.name')} variant="outlined" fullWidth className={classes.FormField} onChange={handleUserChange} required error={formErrors.user} />
        <InputField
          id="password"
          label={t('sitelock.smartscan.form.password')}
          variant="outlined"
          password
          required
          error={formErrors.password}
          className={classes.FormField}
          onChange={handlePasswordChange}
          defaultValue={smartscan.data.password}
        />
        <InputField id="url" defaultValue={smartscan.data.url} label={t('sitelock.smartscan.form.url')} variant="outlined" fullWidth className={classes.FormField} onChange={handleUrlChange} required error={formErrors.url} ftp />
        <AlertBox message={t('sitelock.smartscan.form.alertMessage')} title={t('sitelock.smartscan.form.alertTitle')} />
        {update ? renderUpdate() : renderSave() }
      </div>
    </div>
  );
};

SitelockSMARTScanForm.propTypes = {
  update: false,
  closeModal: () => {},
};

SitelockSMARTScanForm.propTypes = {
  update: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default withI18n()(withStyles(styles, { withTheme: true })(SitelockSMARTScanForm));
