import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Styles from './DragAndDropList.styles';
import { IconButton, Skeleton, SocialMediaIcons } from 'gatorcomponents';
import { IconDrag } from '../IconDrag';
import { linkType } from '../../LinkNaBioWizardHandler.enum';
import withErrorBoundary from '@/utils/errorBoundary';

const DragAndDropList = ({
  testId = 'drag-and-drop',
  list = [],
  setList = () => null,
  editLink = () => null,
  deleteLink = () => null,
  linksLoading = false,
  model = 'LINKS',
}) => {
  const draggingPos = useRef(null);
  const [items, setItems] = useState(list);

  const updateItems = useCallback(() => {
    setItems(list);
  }, [list]);

  useEffect(() => {
    updateItems();
  }, [updateItems]);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    const dragOverPos = position;
    const newItems = [...items];
    const draggingItem = newItems[draggingPos.current];
    if (!draggingItem) return;

    newItems.splice(draggingPos.current, 1);
    newItems.splice(dragOverPos, 0, draggingItem);

    const reorderedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    draggingPos.current = position;
    setItems(reorderedItems);
  };

  const handleDragFinish = () => {
    draggingPos.current = null;
    setList(items);
  };

  const editItem = (index) => {
    editLink && editLink(index);
  };

  const deleteItem = (index) => {
    deleteLink && deleteLink(index);
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      {linksLoading && items.map(item => (
        <Skeleton variant="rectangle" height="78px" width="435px" key={item.id} />
      ))}

      {!linksLoading && items.map((item, index) => (
        <Styles.Card
          key={item.id}
          draggable="true"
          onDragStart={() => handleDragStart(index)}
          onDragEnter={() => handleDragEnter(index)}
          onDragOver={e => e.preventDefault()}
          onDragEnd={handleDragFinish}
          isDragging={draggingPos.current === index}
        >
          {draggingPos.current !== index && (
            <>
              <IconDrag />
              {model === linkType.LINK && (
                <Styles.Details className="details">
                  <Styles.Title>{item.description}</Styles.Title>
                  <Styles.Url>{item.link}</Styles.Url>
                </Styles.Details>
              )}
              {model === linkType.SOCIAL_MEDIA && (
                <Styles.SocialMedia>
                  <SocialMediaIcons icon={item.social_icon.icon.replace('-icon', '')} brandColors size="lg" />
                  <Styles.Details className="details">
                    <Styles.Title>
                      {item.social_icon.name}
                    </Styles.Title>
                    <Styles.Url>{item.value}</Styles.Url>
                  </Styles.Details>
                </Styles.SocialMedia>
              )}
              <Styles.Actions>
                <IconButton icon="IconTrash" testId="deleteLink" onClick={() => deleteItem(index)} variant="white" />
                <IconButton icon="IconPencil" testId="editLink" onClick={() => editItem(index)} variant="white" />
              </Styles.Actions>
            </>
          )}
        </Styles.Card>
      ))}
    </Styles.Wrapper>
  );
};

export default withErrorBoundary(DragAndDropList, 'DragAndDropList');
