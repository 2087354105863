import { REQUEST_PAYPAL_PAYMENT_AGREEMENT, ERROR_PAYPAL_PAYMENT_AGREEMENT, RECEIVE_PAYPAL_PAYMENT_AGREEMENT } from '@/redux/actions/actionsTypes';

const initialState = {
  loadingPaypalPayment: false,
};

export default function paypal(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PAYPAL_PAYMENT_AGREEMENT:
      return {
        ...state,
        loadingPaypalPayment: true,
      };
    case ERROR_PAYPAL_PAYMENT_AGREEMENT:
    case RECEIVE_PAYPAL_PAYMENT_AGREEMENT:
      return {
        ...state,
        loadingPaypalPayment: false,
      };
    default:
      return state;
  }
}
