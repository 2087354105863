const styles = theme => ({
  containerTitle: {
    color: theme.color.tundora,
    fontSize: '24px',
    letterSpacing: '0.01px',
    lineHeight: '29px',
    marginBottom: '24px',
  },

  description: {
    color: theme.color.tundora,
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: 33,
  },

  managementLinkButton: {
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    fontSize: '14px',
    fontWeight: '500',
    letterSpaceing: '0.01px',
    lineHeight: '17px',
    padding: '10px 20px',
    textDecoration: 'none',
    transition: '300ms',

    '&:hover': {
      backgroundColor: theme.color.chambray,
    },
  },
});
export default styles;
