/* eslint-disable no-console */
import db from './initDB';

export const indexedDBGetCourseByUserId = async (id) => {
  try {
    const query = await db.progress
      .where({
        userId: id,
      })
      .first()
      .catch(error => console.error(error));

    return query || {};
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const indexedDBPutCourseProgress = async (data) => {
  try {
    await db.progress.put(data)
      .catch(error => console.error(error));

    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
