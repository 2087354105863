export const steps = {
  DOMAIN_SELECT: 'DOMAIN_SELECT',
  INTERNAL_DOMAIN: 'INTERNAL_DOMAIN',
  INTERNAL_DOMAIN_VERIFY: 'INTERNAL_DOMAIN_VERIFY',
  INTERNAL_DOMAIN_DONE: 'INTERNAL_DOMAIN_DONE',
  EXTERNAL_DOMAIN_CONFIGURATION: 'EXTERNAL_DOMAIN_CONFIGURATION',
  EXTERNAL_DOMAIN_DONE: 'EXTERNAL_DOMAIN_DONE',
  DOMAIN_VERIFY_ERROR: 'DOMAIN_VERIFY_ERROR',
  DOMAIN_ALREADY_CONNECTED: 'DOMAIN_ALREADY_CONNECTED',
  REMOVE_DOMAIN: 'REMOVE_DOMAIN',
};

export const connectionTypes = {
  subdomain: 'subdomain',
  mainDomain: 'mainDomain',
};
