import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './GenerateBackup.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  IconData, Input, IconLoading, Checkbox, Button,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const GenerateBackup = ({
  submitForm,
  selectedProduct,
  opening,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { generateBackup: generateBackupLocale } = ticketsLocale.forms;
  const [userName, setUserName] = useState('');
  const [server, setServer] = useState('');
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (selectedProduct && selectedProduct.domain) {
      setUserName(selectedProduct.username);
      setServer((selectedProduct.serverhostname || selectedProduct.dedicatedip) || '');
    }
  }, [selectedProduct]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.generateBackup,
      key: V2Forms.RESTORATE_HG_ROUTINE,
      tags: ['backup_limite', 'fluxo_backup'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.generateBackup,
      },
      body: `
      ${generateBackupLocale.body.productId}: ${selectedProduct.id}
      ${generateBackupLocale.body.server}: ${server}
      ${generateBackupLocale.body.user}: ${userName}
      ${generateBackupLocale.body.agree}: ${agreed ? generateBackupLocale.body.yes : generateBackupLocale.body.no}
      `,
    };

    submitForm && submitForm(data);
  };

  const checkForm = useCallback(() => {
    let isOk = false;

    isOk = agreed
    && userName.length
    && server.length;

    return isOk;
  }, [
    userName,
    server,
    agreed,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {generateBackupLocale.terms}
      </Styles.Info>

      <Styles.Info>
        {generateBackupLocale.termsItems}
      </Styles.Info>

      <Styles.Title>
        {generateBackupLocale.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Input
          name="user"
          testId="user"
          placeholder={generateBackupLocale.fields.userLabel}
          label={userName ? generateBackupLocale.fields.user : ''}
          value={userName}
          onChange={e => setUserName(e.target.value)}
        />

        <Input
          name="server"
          testId="server"
          placeholder={generateBackupLocale.fields.serverLabel}
          label={server ? generateBackupLocale.fields.server : ''}
          value={server}
          onChange={e => setServer(e.target.value)}
        />
      </Styles.InputWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => setAgreed(!agreed)}
      >
        <Checkbox checked={agreed} id="readTerms" />
        <Styles.Text>
          {generateBackupLocale.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.InputWrapper>
        <Button
          size="large"
          testId="submit"
          label={opening ? <IconLoading /> : generateBackupLocale.open}
          disabled={!checkForm() || opening}
          type="submit"
        />
      </Styles.InputWrapper>
    </Styles.Form>
  );
};

export default GenerateBackup;
