
import React from 'react';
import * as Styles from './Configurations.styles';
import useLocale from '@/hooks/useLocale';
import {
  Alert, Button, Input, Skeleton,
} from 'gatorcomponents';
import { UpgradeBanner } from '../UpgradeBanner';

const Configurations = ({
  isLoading, isFree, formData, setFormData, handleSave, upgradeBannerProps, handleHelpClick, showSkeleton,
}) => {
  const { security: securityLocale } = useLocale();
  const configurationsLocale = securityLocale.detailsPage.tabs.configurations;

  const isDomainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

  const updateFormData = (dataName, dataValue) => {
    const newData = { ...formData };

    if (dataName === 'url') {
      newData[dataName] = {
        value: dataValue,
        error: !isDomainRegex.test(dataValue) ? 'Campo obrigatório' : '',
        touched: formData[dataName].touched,
      };
    } else {
      newData[dataName] = {
        value: dataValue,
        error: !dataValue ? 'Campo obrigatório' : '',
        touched: formData[dataName].touched,
      };
    }

    setFormData(newData);
  };

  const handleTouchForm = (dataName) => {
    const newData = { ...formData };

    newData[dataName] = {
      ...newData[dataName],
      touched: true,
    };

    setFormData(newData);
  };

  const handleShowError = (fieldName) => {
    if (formData[fieldName] && !formData[fieldName].touched) return '';

    if (formData[fieldName] && formData[fieldName].value) return formData[fieldName].error;

    return configurationsLocale.required;
  };


  if (showSkeleton) {
    return (
      <Styles.Wrapper>
        <Styles.SkeletonRow maxWidth="800px">
          <Skeleton variant="paragraph" width="800px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="16px">
          <Skeleton variant="rectangle" width="320px" height="56px" />
          <Skeleton variant="rectangle" width="320px" height="56px" />
          <Skeleton variant="rectangle" width="320px" height="56px" />
          <Skeleton variant="button" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="32px">
          <Skeleton variant="textLine" width="800px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="32px">
          <Skeleton variant="rectangle" height="1px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="32px">
          <Skeleton variant="textLine" width="800px" />
        </Styles.SkeletonRow>
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper>
      <Styles.Label>
        {configurationsLocale.ftpLabel}
      </Styles.Label>
      <Styles.Description>
        {configurationsLocale.ftpDescription}
      </Styles.Description>

      <Styles.Form onSubmit={handleSave}>
        <Input
          label={configurationsLocale.serverUrl}
          value={formData.url.value || ''}
          onChange={e => updateFormData('url', e.currentTarget.value)}
          placeholder={configurationsLocale.serverUrlPlaceholder}
          testId="serverUrl"
          errorMessage={handleShowError('url')}
          onBlur={() => handleTouchForm('url')}
        />
        <Input
          label={configurationsLocale.username}
          value={formData.user.value || ''}
          onChange={e => updateFormData('user', e.currentTarget.value)}
          placeholder={configurationsLocale.usernamePlaceholder}
          testId="username"
          errorMessage={handleShowError('user')}
          onBlur={() => handleTouchForm('user')}
        />
        <Input
          label={configurationsLocale.password}
          value={formData.password.value || ''}
          onChange={e => updateFormData('password', e.currentTarget.value)}
          placeholder={configurationsLocale.passwordPlaceholder}
          testId="password"
          type="password"
          errorMessage={handleShowError('password')}
          onBlur={() => handleTouchForm('password')}
        />

        <Styles.ButtonWrapper>
          <Button
            testId="submitButton"
            label={configurationsLocale.save}
            type="submit"
            loading={isLoading}
            disabled={!formData.password.value || !formData.user.value || !isDomainRegex.test(formData.url.value)}
          />
        </Styles.ButtonWrapper>
      </Styles.Form>

      <Styles.AlertWrapper>
        <Alert
          variant="info"
          description={configurationsLocale.tooltip}
          withoutBackground
          centerIcon
        />
      </Styles.AlertWrapper>

      <Styles.Line />

      <Styles.Label>
        {configurationsLocale.doubts({ handleClick: handleHelpClick })}
      </Styles.Label>

      {isFree && <UpgradeBanner {...upgradeBannerProps()} />}
    </Styles.Wrapper>
  );
};

export default Configurations;
