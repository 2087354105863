import React from 'react';

export default {
  productsDetails: {
    modals: {
      createSite: {
        title: () => (
          <>
            {'¿Vamos expandir su marca '}
            <span>creando un nuevo sitio web</span>
            ?
          </>
        ),
        description: {
          0: domain => (
            <>
              {' bilhões de pessoas no mundo estão usando a internet! Não perca tempo e crie um site personalizado para '}
              <span>{`${domain}.`}</span>
            </>
          ),
          1: () => (
            <>
              {'¡5 mil millones de personas em el mundo están usando internet! No pierda tiempo y cree un sitio web personalizado para '}
              <span>Criador de Sites, Wordpress</span>
              {' Usted puede usar el Creador de Sitios web, WordPress u otras herramientas para instalar y personalizar su sitio. :)'}
            </>
          ),
        },
        buttonLabel: 'Crear sitio',
        backButtonLabel: 'No quiero expandir mi marca',
      },
    },
  },
};
