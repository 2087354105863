const styles = theme => ({
  zoneManager: {
    width: '100%',
  },
  zoneTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '29px',
    fontWeight: 300,
    marginBottom: 10,
  },
  explanationWrapper: {
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    textTransform: 'unset',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  explanation: {
    color: theme.color.dustyGgray,
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 14,
    display: 'block',
  },
  explanationCTA: {
    color: theme.color.indigo,
  },
  explanationReadLess: {
    background: theme.color.white,
    color: theme.color.indigo,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '22px',
    padding: 0,
    textAlign: 'center',
    textDecoration: 'underline',
    textTransform: 'unset',
    width: 'unset',
  },
  cardWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: '0px 2px 4px 0px #DBDBDB',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    padding: '24px 38px 24px 36px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  tableOnlyWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    margintop: '40px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});

export default styles;
