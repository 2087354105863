import { RECEIVE_SURVEY, REQUEST_SURVEY, ERROR_SURVEY } from '@/redux/actions/actionsTypes';

const initialState = {
  loading: false,
  data: {
    save: true,
  },
};

const survey = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SURVEY:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SURVEY:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ERROR_SURVEY:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default survey;
