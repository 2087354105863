import { Button, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withI18n } from 'react-i18next';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';
import { ALPHA_TITANTRIAL_EMAILS_ENABLE_OLD_ROUTE } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const ActiveBlock = ({
  classes, t,
  proEmail,
  domain,
  isConfiguredAccounts,
  isProEmailInactive,
  almostExcluded,
  almostOverdue,
  isTrialPlan,
  shouldSetService,
  type,
  customerEmail,
}) => {
  const shouldRedirectToOldRoute = useFeatureIsOn(ALPHA_TITANTRIAL_EMAILS_ENABLE_OLD_ROUTE);

  const history = useHistory();
  const hasPlan = proEmail !== undefined && proEmail.plan !== undefined;
  const idProEmail = proEmail !== undefined && proEmail.id !== undefined ? proEmail.id : undefined;

  const displayManagerButton = !almostExcluded && hasPlan && !isProEmailInactive && isConfiguredAccounts
  && ((isTrialPlan && !almostOverdue) || (!isTrialPlan && almostOverdue) || (proEmail.status === 'active' && !almostOverdue));

  const onClick = async () => {
    const dataToFilter = type === 'proemail' ? `idPro=${idProEmail}` : `cust=${customerEmail}`;

    if (!shouldRedirectToOldRoute) {
      return history.push(`${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${domain}?${dataToFilter}&service=${type}`);
    }

    if (!isConfiguredAccounts && shouldSetService) {
      history.push(`${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${domain}?${dataToFilter}&service=${type}`);
    } else {
      history.push(`${t('routes.emailsList')}${t('routes.titanHire')}/${domain}&service=${type}`);
    }
  };
  return (
    <div className={!isConfiguredAccounts ? classes.activeBlock : classes.activeBlockLink}>
      <div
        className={classes.proEmailActions}
      >
        {!shouldSetService && displayManagerButton && (
        <LinkButton
          className={classes.manageLinkButton}
          data-testid="manageLinkButton"
          to={proEmail.type !== 'cpanel'
            ? `${t('routes.emailsList')}${t('routes.emailsTitan')}/${domain}`
            : `${t('routes.filteredEmails')}/${domain}`
        }
        >
          {t('proEmail.managerEmail')}
        </LinkButton>
        )}
        {((!almostExcluded && !isConfiguredAccounts) || shouldSetService) && (
        <Button
          className={classes.btActive}
          data-testid="getPlanLinkButton"
          onClick={onClick}
        >
          {shouldSetService || (!isConfiguredAccounts) ? t('proEmail.createEmail') : t('proEmail.getPlanLink') }
        </Button>
        )}

        {!shouldSetService && (almostExcluded || (isTrialPlan && almostOverdue)) && (

        <Typography
          className={classes.manageLinkButtonDisabled}
          data-testid="manageLinkButtonDisabled"
        >
          {t('proEmail.managerEmail')}
        </Typography>

        )}

      </div>
    </div>
  );
};

ActiveBlock.propTypes = {
  proEmail: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(ActiveBlock));
