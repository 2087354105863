import React, { useEffect, useState } from 'react';
import { IconClose } from '@/icons';
import * as Style from './DiscountOfferBanner.styles';
import discountGift from '@/media/subscriptions/retention/ilustra_88px.svg';
import smallDiscountGift from '@/media/subscriptions/retention/ilustra_48px.svg';
import { Button } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { useHistory } from 'react-router';
import { billingAnalytics } from '@/analytics';

const DiscountOfferBanner = ({
  subscription = null,
  origin = '',
  handleAction = null,
}) => {
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { offerDiscountBanner: offerDiscountBannerLocale } = billingLocale;
  const history = useHistory();
  const hasOnlyOneSubscription = subscription !== null;
  const [visible, setVisible] = useState(true);

  const {
    descriptionsToSitePage,
    descriptionToSubscripionsPage,
    title,
    buttonLabel,
  } = offerDiscountBannerLocale;
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.bannerDiscountDisplay(origin.toUpperCase());
  }, []);

  const handleClick = () => {
    if (origin === 'sites' || origin === 'subscription_list') {
      let url = routesLocale.subscriptions;

      if (subscription != null) {
        url = `${routesLocale.subscriptions}${routesLocale.manageSubscription}/${subscription.id}/${subscription.type}?modal=true`;
      }

      setVisible(!visible);
      subscriptionAnalytics.bannerDiscountClick(origin.toUpperCase());

      handleAction && handleAction();

      history.push(url);
    } else if (origin === 'subscription') {
      subscriptionAnalytics.bannerDiscountClick(origin.toUpperCase());
      handleAction && handleAction();
    }
  };

  const handleClose = () => {
    setVisible(false);
    subscriptionAnalytics.bannerDiscountClose(origin.toUpperCase());
  };

  return (
    visible
    && (
      <>
        <Style.Wrapper data-testid="discount-offer-banner" variant={origin}>
          <Style.GiftIcon variant={origin} src={origin === 'sites' ? discountGift : smallDiscountGift} alt="discountGift" />
          <Style.Content variant={origin}>
            <Style.Info>
              {origin === 'sites' && (
                <Style.ContentTitle>
                  <Style.Title data-testid="title-banner">
                    {title[0]}
                    <Style.Discount>{title[1]}</Style.Discount>
                  </Style.Title>
                </Style.ContentTitle>
              )}
              <Style.ContentDescription>
                {origin === 'sites' && (
                  <>
                    <Style.Description data-testid="description-from-sites" variant={origin}>
                      {hasOnlyOneSubscription && (
                        <>
                          {descriptionsToSitePage[2]}
                          <strong>
                            {subscription.name}
                          </strong>
                          {descriptionsToSitePage[3]}
                        </>
                      )}
                      {!hasOnlyOneSubscription && (
                        <>
                          {descriptionsToSitePage[0]}
                          <strong>
                            {descriptionsToSitePage[1]}
                          </strong>
                        </>
                      )}
                      {descriptionsToSitePage[4]}
                    </Style.Description>
                    <Style.Description variant={origin}>
                      {descriptionsToSitePage[5]}
                    </Style.Description>
                  </>
                )}

                {origin.includes('subscription') && (
                  <Style.Description data-testid="description-from-subscription" variant={origin}>
                    {descriptionToSubscripionsPage}
                  </Style.Description>
                )}

              </Style.ContentDescription>

            </Style.Info>
            <Style.ContentButton>
              <Button
                label={buttonLabel}
                testId="top-discount-banner-button-redirect"
                onClick={handleClick}
                variant="secondary"
              />
            </Style.ContentButton>
          </Style.Content>

          {origin === 'sites' && (
            <Style.CloseButton onClick={handleClose} data-testid="top-discount-banner-close">
              <IconClose color="gray" />
            </Style.CloseButton>
          )}

        </Style.Wrapper>
      </>
    )
  );
};

export default DiscountOfferBanner;
