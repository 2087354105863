import { Button } from 'gatorcomponents';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.feedback.canceledLight};
    margin: 17px 0px 8px 0px;
    padding: 24px 36px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 4px 0px 8px 0px;
      padding: 16px 11px 16px 18px;
    }
    `}
`;

export const ContentTicketCode = styled.div`
${({ theme }) => css`
  margin: 0px 88px 0px 88px;
  text-align: center;

  @media (max-width: ${theme.v2.breakpoints.sm}) {
    margin: 0px;
    padding: 28px 0px;
  }
  `}
`;

export const ContentInputTicketCode = styled.div`
  ${({ theme }) => css`
    padding: 0px 0px 10px 0px;
    div {
      border-radius: 5px;
    }

    input {
      border: solid 1px ${theme.v2.shadows.light};
      border-radius: 5px;
    }
    textarea {
      border-radius: 5px;
      border: solid 1px ${theme.v2.shadows.light};
      font-family: ${theme.v2.font.family.primary};
      font-weight: ${theme.v2.font.weights.regular};
      min-height:0px;
      text-align:center;
    }
  `}
`;

export const ContentDoubts = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: 12px;
    text-align: center;
    z-index: 99;

    a:first-child {
      display:block;
    }

  `}
`;

export const Controls = styled.div`
${({ theme }) => css`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media (max-width: ${theme.v2.breakpoints.sm}) {
    margin-bottom: 12px;

    `}
  `;

export const ControlButton = styled.div`
  ${() => css`
    &:not(:first-child) {
      margin-left: 12px;
    }

    button:first-child {
      margin-right: 16px;
    }

    @media (max-width: 600px) {
      text-align: center;

      ${Button} {
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  `}
`;

export const ContentTitleTicket = styled.div`
  ${({ theme, displayBarcode }) => css`
    font-family: ${theme.v2.font.family.primary};
    font-size: 16px;
    padding: 0px 90px 0px 90px;
    text-align: center;
    margin-bottom: ${displayBarcode ? '0px' : '24px'};
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      padding: 0;
      text-align: center;
    }
  `}
`;

export const ContentBarcode = styled.div`
  text-align: center;
`;

export const TicketTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 16px;
    margin: 0;
    padding: 24.4px 0px 0px 0px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      padding: 0px 0px 0px 0px;
      text-align: center;
    }
  `
}
`;

export const Number = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v2.font.weights.bold};
    margin: 0px 8px 0px 0px;
    display: initial;
`}
`;

export const TipTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    margin: 0px 0px 26px 0px;
    font-weight: ${theme.v2.font.weights.bold};

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      text-align: ;
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    margin: 0;
    display: flex;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      text-align: ;
    }
  `}
`;
export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      text-align: initial;
    }
  `}
`;


export const List = styled.ul`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: 14px;
    margin: 0;
    padding: 0px;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;
export const Item = styled.li`
  list-style-type:none;
  padding: 0 0 16px 0;

  &:last-child{
    padding: 0;
  }
`;

export const Link = styled.a`
${({ theme }) => css`
  color: ${theme.v2.colors.brand.primaryBlue};
  margin-right: 5px;
  text-decoration: underline;
`}
`;

export const Barcode = styled.img`
  height: 83px;
  margin: 16px 0px 14px 0px;
  width: 537px:
`;
