import React from 'react';
import { withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';


class NewSiteCloseLink extends React.PureComponent {
  render() {
    const { classes, action } = this.props;
    return (
      <div className={classes.linkWrapper}>
        <LinkButton onClick={() => action()} to="/">
          <Close />
        </LinkButton>
      </div>
    );
  }
}

export default withStyles(styles)(NewSiteCloseLink);
