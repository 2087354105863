import React from 'react';
import { withI18n } from 'react-i18next';
import { withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import WarningBlock from '../WarningBlock';
import styles from './styles';
import FormItem from './FormItem';

const OtherBlock = ({
  classes, t, arrayOtherDns, addSlave, handleChangeNs, removeSlave, handleIpChange,
}) => (
  <div className={classes.expandPanel}>

    <div className={classes.formWrapaper}>
      { arrayOtherDns.map((item, index) => (
        <div key={item.key} className={classes.inputsWrapper} data-testid="inputsWrapper">
          <FormItem i={index} arrayOtherDns={arrayOtherDns} handleChangeNs={handleChangeNs} removeSlave={removeSlave} handleIpChange={handleIpChange} />
        </div>
      ))}
    </div>

    <div className={classes.buttonWrapper}>
      {arrayOtherDns.length < 5 && (
        <Button
          className={classes.button}
          disableRipple
          onClick={() => addSlave()}
          data-testid="addSlaveButton"
        >
          {t('dnsSetup.other.button')}
        </Button>
      )}
    </div>

    <WarningBlock />
  </div>
);

OtherBlock.propTypes = {
  arrayOtherDns: PropTypes.array.isRequired,
  addSlave: PropTypes.func.isRequired,
  handleChangeNs: PropTypes.func.isRequired,
  removeSlave: PropTypes.func.isRequired,
  handleIpChange: PropTypes.func.isRequired,
};

export default withI18n()(withStyles(styles)((OtherBlock)));
