import React, { useState } from 'react';
import {
  withStyles, Grid, CardContent, CardActions, Typography, Link, Tooltip,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Dns } from '@material-ui/icons';
import classnames from 'classnames';
import { locale } from '@/utils/locale';
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns';
import DomainStatusLabel from '@/components/Domain/DomainStatusLabel';
import DomainDetailCard from '@/components/Cards/DomainDetailCard';
import ModalReactivateAutomaticSubscription from '@/components/Modal/ModalReactivateAutomaticSubscription';
import { brokeDomain } from '@/utils/Formatters/handleStrings';
import { domainsTagManager } from '@/utils/ThirdParties/tagManager';
import { formatDate } from '@/utils/Formatters/formatDate';
import { isBRServer } from '@/utils/Validators/validation';
import LinkButton from '@/components/Buttons/LinkButton';
import ChipLinkButton from '@/components/Buttons/ChipLinkButton';
import styles from './DomainItem.styles';
import { IconWarning } from '@/icons';
import { Button, Message } from '@/pages/common';
import { domainsAnalytics } from '@/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { handleDomainRenewal } from '@/redux/actions/domains';
import { domainsActions } from '@/redux/modules/domains';
import { DomainItemMessage } from './DomainItemMessage';
import { DOMAIN_STATUS, INVOICE_STATUS, INVOICE_TYPE } from '@/enums/domains/domains.enum';
import { useHistory } from 'react-router';
import {
  BOLETO, CREDIT_CARD, PAYPAL, BRASPAG_PARC_1X, BRASPAG_PARC_2X, BRASPAG_PARC_3X, BRASPAG_PARC_4X, BRASPAG_PARC_5X,
  BRASPAG_PARC_6X, BRASPAG_PARC_7X, BRASPAG_PARC_8X, BRASPAG_PARC_9X, BRASPAG_PARC_10X,
  BRASPAG_PARC_11X, BRASPAG_PARC_12X, CREDIT_CARD_ES, BOLETO_CL_CO, BOLETO_MX, GATORPAY_BOLETO, GATORPAY_PAYPAL,
} from '@/config/billing/paymentMethods';
import { useStatusColors } from '@/hooks/domains/list/useStatusColors';
import { useInvoice } from '@/hooks/domains/useInvoice';
import { PropagationStatusLabel } from '@/components/Domain/PropagationStatusLabel';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_LEGACY, ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD, ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES, ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG,
} from '@/config/GrowthBook/constants';

const DomainItem = ({
  classes,
  domain,
  t,
}) => {
  const shouldRenderDnsPropagationTag = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_PROPAGATION_TAG);
  const shouldRenderDnsLegacyButton = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_LEGACY);
  const shouldRenderDnsWizardButtonBr = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD);
  const shouldRenderDnsWizardButtonEs = useFeatureIsOn(ALPHA_DOMAINS_LIST_DNS_CONFIGURATION_WIZARD_ES);
  const shouldRenderDnsWizardButton = shouldRenderDnsWizardButtonBr || shouldRenderDnsWizardButtonEs;

  const [modalReactivation, setModalReactivation] = useState(false);

  const productIsActive = domain.status === 'active';
  const productIsExpired = domain.status.match(/expired/i);
  const showToolTip = domain.domain.indexOf('.') > 22;
  const domainName = domain ? domain.domain : undefined;
  const today = new Date();
  const dueDate = domain.isGracePeriod ? parseISO(domain.gracePeriodDueDate) : parseISO(domain.nextDueDate);
  const dateDiff = differenceInCalendarDays(dueDate, today);
  const limitNextDueDate = 30;
  const isExpired = productIsExpired && domain.isGracePeriod;
  const isCancelled = domain.status === 'cancelled' && (domain.cancelReason == null || domain.cancelReason === '');
  const isOverDue = differenceInCalendarDays(parseISO(domain.nextDueDate), today) < 0 && !domain.isGracePeriod && productIsActive;

  const hasReasonToCancel = domain.cancelReason != null && domain.cancelReason !== '';

  const daysToCancel = differenceInCalendarDays(addDays(dueDate, 31), today);
  const isNextDueDate = dateDiff <= limitNextDueDate && productIsActive && !domain.isGracePeriod;
  const valueDisabledRenovation = 1;
  const disabledAutoRenovation = domain.doNotRenew === valueDisabledRenovation && productIsActive;

  const { dnsWizard: dnsWizardAnalytics } = domainsAnalytics;

  const displayNewMessageImprovements = (disabledAutoRenovation
    || (domain.invoiceType === INVOICE_TYPE.RENEWAL && domain.invoiceStatus === INVOICE_STATUS.CANCELLED)
    || (domain.invoiceType === INVOICE_TYPE.REGISTRATION && (isNextDueDate || isOverDue || isExpired))
    || (domain.invoiceId === 0));

  const isBoleto = () => [GATORPAY_BOLETO, BOLETO, BOLETO_MX, BOLETO_CL_CO].includes(domain.paymentMethod);
  const isPayPal = () => [GATORPAY_PAYPAL, PAYPAL].includes(domain.paymentMethod);

  const creditCardPaymentForms = [
    CREDIT_CARD_ES, CREDIT_CARD, BRASPAG_PARC_1X,
    BRASPAG_PARC_2X, BRASPAG_PARC_3X,
    BRASPAG_PARC_4X, BRASPAG_PARC_5X,
    BRASPAG_PARC_6X, BRASPAG_PARC_7X,
    BRASPAG_PARC_8X, BRASPAG_PARC_9X,
    BRASPAG_PARC_10X, BRASPAG_PARC_11X,
    BRASPAG_PARC_12X];

  const isCreditCard = () => creditCardPaymentForms.includes(domain.paymentMethod);
  const [isLoadingAction, setLoadingAction] = useState(false);

  const typePayment = () => {
    if (domain.paymentMethod && domain.paymentMethod.includes('boleto')) {
      return isBRServer ? 'boleto' : 'payu_boleto';
    }
    return domain.paymentMethod;
  };

  const invoiceUrl = id => `${locale('routes.invoices')}${locale('routes.unpaid')}/${id}/${typePayment()}`;

  const history = useHistory();

  const { generate } = useInvoice();

  const dispatch = useDispatch();

  const {
    loading: isLoadingGenerateNewInvoice,
  } = useSelector(state => state.newDomains.domains.generateNewInvoice);

  const reloadListDomains = () => {
    dispatch(domainsActions.domains.request());
  };

  const callBackFunction = (idInvoice) => {
    history.push(invoiceUrl(idInvoice));
  };

  const redirectToGeneratedInvoice = () => {
    generate(domain, callBackFunction);
  };

  const colors = useStatusColors();

  const canManageDns = () => {
    if (productIsActive) {
      return !isNextDueDate && !isOverDue && !disabledAutoRenovation && !isExpired;
    }
    return productIsActive;
  };

  const getStatusLabel = (status) => {
    if (isNextDueDate && !isOverDue) {
      return 'nextDueDate';
    }
    if (isOverDue) {
      return 'overDueDate';
    }
    return status;
  };

  const handleOpen = async () => {
    setModalReactivation(true);
  };

  const getMessageAutoRenovation = () => (
    <>
      <>
        <p className={classes.paragraph}>
          {locale('cardAlerts.domain.messages.newDisableAutoRenovation.0')}
          <strong>
            {`${locale('cardAlerts.domain.messages.newDisableAutoRenovation.1')} ${formatDate(domain.nextDueDate)}. `}
          </strong>
          <Button
            size="small"
            variant="link"
            label={locale('cardAlerts.domain.messages.newDisableAutoRenovation.2')}
            onClick={handleOpen}
            data-testid="automaticReactivationButton"

          />
          {locale('cardAlerts.domain.messages.newDisableAutoRenovation.3')}

        </p>
      </>
    </>
  );


  const getMessageExpired = () => {
    if (!domain.gracePeriod) {
      return (
        <DomainItemMessage
          variant={DOMAIN_STATUS.EXPIRED_GRACE_PERIOD_OVERDUE}
          domain={domain}
        />
      );
    }
    if ((domain.doNotRenew === valueDisabledRenovation
        || (domain.gracePeriod && !domain.isGracePeriod && (domain.invoiceType !== INVOICE_TYPE.RECOVERY))
    )) {
      return (
        <DomainItemMessage
          variant={DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_DISABLED}
          domain={domain}
          handleOnClick={redirectToGeneratedInvoice}
        />
      );
    }
    return (
      <DomainItemMessage
        variant={DOMAIN_STATUS.EXPIRED_AUTO_RENEWAL_ENABLED}
        domain={domain}
      />
    );
  };

  const getMessageCancelled = () => {
    if (!hasReasonToCancel && domain.doNotRenew && !domain.isGracePeriod) {
      return (<DomainItemMessage variant={DOMAIN_STATUS.CANCELED_UNRECOVERABLE} domain={domain} />);
    }
    if (hasReasonToCancel) {
      return (<DomainItemMessage variant={DOMAIN_STATUS.CANCELED_BY_TOS_ABUSE} domain={domain} />);
    }
    return (<DomainItemMessage variant={DOMAIN_STATUS.CANCELED_RECOVERABLE} domain={domain} />);
  };

  const getMessageWarningDueDate = () => {
    if (!displayNewMessageImprovements) {
      return (
        <>
          <p className={classes.paragraph}>
            {locale('cardAlerts.domain.messages.dueDateInvoice.0')}
            <strong>
              {`${locale('cardAlerts.domain.messages.dueDateInvoice.1')} ${formatDate(domain.nextDueDate)}`}
            </strong>

            {`${locale('cardAlerts.domain.messages.dueDateInvoice.2')} ${daysToCancel}
              ${daysToCancel > 1 ? locale('cardAlerts.domain.messages.dueDateInvoice.3') : locale('cardAlerts.domain.messages.dueDateInvoice.4')}`}
            <Link className={classes.linkBillDueDate} href={invoiceUrl(domain.invoiceId)} target="_blank" rel="noreferrer" data-testid="link_invoice_almost_due_date">
              {locale('cardAlerts.domain.messages.dueDateInvoice.5')}
            </Link>

          </p>
        </>
      );
    }

    return (
      <>
        <p className={classes.paragraph}>
          {locale('cardAlerts.domain.messages.overDueDateDisabledAutoRenovation.0')}

          {`${locale('cardAlerts.domain.messages.overDueDateDisabledAutoRenovation.1')}`}

          {locale('cardAlerts.domain.messages.overDueDateDisabledAutoRenovation.2')}
          <strong>
            {formatDate(domain.expiryDate)}
          </strong>
          .
          <Button
            id={domain.id}
            variant="link"
            size="small"
            className={classes.linkNewDomain}
            disabled={isLoadingGenerateNewInvoice}
            onClick={e => redirectToGeneratedInvoice(e)}
            label={locale('cardAlerts.domain.messages.overDueDateDisabledAutoRenovation.3')}
            data-testid="link_new_improvement_message_over_due_date"
          />
          {locale('cardAlerts.domain.messages.overDueDateDisabledAutoRenovation.4')}

        </p>
      </>
    );
  };

  const getMessageWarningToNextDueDate = () => {
    let message = '';

    if (!domain.paymentMethod) {
      return (<></>);
    }

    if (isBoleto()) {
      message = 'nextDueDateInvoice';
    }
    if (isCreditCard()) {
      message = 'nextDueDateInvoiceCreditCard';
    }
    if (isPayPal()) {
      message = 'nextDueDateInvoicePayPal';
    }

    if (displayNewMessageImprovements) {
      message = 'dueDateDisabledAutoRenovation';
    }
    return (
      <>
        <p className={classes.paragraph}>
          {locale(`cardAlerts.domain.messages.${message}.0`)}
          <strong>
            {`${locale(`cardAlerts.domain.messages.${message}.1`)} ${formatDate(domain.nextDueDate)}`}
            {isBoleto() || displayNewMessageImprovements ? '. ' : ' '}
          </strong>
          {isBoleto() && !displayNewMessageImprovements && (
            <Link className={classes.linkNewDomain} href={invoiceUrl(domain.invoiceId)} target="_blank" rel="noreferrer" data-testid="link_invoice_next_almost_due_date">
              {locale(`cardAlerts.domain.messages.${message}.2`)}
            </Link>
          )}
          {displayNewMessageImprovements && (
            <Button
              variant="link"
              size="small"
              className={classes.linkNewDomain}
              onClick={() => redirectToGeneratedInvoice()}
              label={locale(`cardAlerts.domain.messages.${message}.2`)}
              data-testid="link_new_improvement_message_due_date"
            />
          )}

          {locale(`cardAlerts.domain.messages.${message}.3`)}
        </p>
      </>
    );
  };

  const domainNameLink = (
    <Link
      href={`https://www.${domain.domain}`}
      className={classes.cardTitle}
      target="_blank"
      rel="noopener"
      underline="none"
    >
      {brokeDomain(domain.domain)}
    </Link>
  );

  const handleActivateSubscription = () => {
    setLoadingAction(true);

    const dataDomain = {
      id: domain.id,
      donotrenew: false,
      name: domain.domain,
    };


    dispatch(domainsActions.domains.activateRenewal.request(dataDomain, reloadListDomains)).then((response) => {
      dispatch(handleDomainRenewal(response.payload.domainData));
      setLoadingAction(false);
    });
  };


  return (
    <>
      <ModalReactivateAutomaticSubscription
        onClose={() => setModalReactivation(false)}
        open={modalReactivation}
        handleActivateSubscription={handleActivateSubscription}
        classes={classes}
        domain="domain"
        loading={isLoadingAction}
        title={locale('modalDomainAutoRenovation.title')}
        description={locale('modalDomainAutoRenovation.description')}
        confirmLabel={locale('modalDomainAutoRenovation.confirmLabel')}
      />
      <Grid item xs={12} className={classes.container}>
        <DomainDetailCard
          domainStatus={domain.status}
          isNextDueDate={isNextDueDate}
          isDueDate={isOverDue}
        >
          <CardContent
            className={classnames(classes.cardContainer,
              {
                [`${domain.status}`]: !isNextDueDate && !isOverDue,
                [`${classes.nextDueDate}`]: isNextDueDate,
                [`${classes.dueDate}`]: isOverDue,
              })}
          >
            <div className={`${classes.wrapperContent} ${domain.nextDueDate ? classes.statusWithDue : classes.statusWithoutDue} ${shouldRenderDnsPropagationTag && classes.statusRow}`}>
              <div className={classes.descriptionContent}>
                <div className={classes.statusContainer}>
                  <Dns className="status-icon" />
                  <div className={classes.domainNameWrapper}>
                    <Typography variant="h6" className={classes.title} data-id={`domain_link_${domainName}`}>
                      {showToolTip ? (
                        <Tooltip
                          title={domain.domain}
                          interactive
                          placement="top"
                        >
                          {domainNameLink}
                        </Tooltip>
                      ) : (
                        domainNameLink
                      )}
                    </Typography>
                    <DomainStatusLabel
                      domainStatus={domain.status}
                      domain={domain}
                      isNextDueDate={isNextDueDate}
                      isDueDate={isOverDue}
                    >
                      {t(`statusProductDomain.${getStatusLabel(domain.status)}`)}
                    </DomainStatusLabel>
                    {shouldRenderDnsPropagationTag && (
                      <PropagationStatusLabel domain={domain.domain} isOnDomainList />
                    )}
                  </div>
                </div>
                {domain.nextDueDate && (
                  <div className={classes.dueContainer}>
                    <div className={classes.cardDateBox}>
                      <Typography className="cardSubTitle" variant="caption">{t('nextDueDate')}</Typography>
                      <Typography className={classes.cardTitleLetter} data-id={`domain_due_date_${domainName}`}>{formatDate(domain.nextDueDate)}</Typography>
                    </div>
                    {typeof domain.donotrenew === 'undefined' && <div />}
                  </div>
                )}
              </div>

              <div className={classes.buttonsContainer}>
                {shouldRenderDnsLegacyButton && canManageDns() && (
                  <div className={classes.addonList}>
                    <ChipLinkButton
                      component={LinkButton}
                      to={`${t('routes.domains')}${t('routes.dns')}/${domain.id}`}
                      status={domain.status}
                      label={t('actions.edit-dns.active')}
                      data-id={`domain_edit-dns_${domainName}`}
                    />
                  </div>
                )}
                {shouldRenderDnsWizardButton && canManageDns() && (
                  <div className={classes.addonList}>
                    <ChipLinkButton
                      component={LinkButton}
                      onClick={dnsWizardAnalytics.dnsWizardButtonClick}
                      to={`${locale('routes.domains')}${locale('routes.dnsWizard')}/${domain.id}`}
                      status={domain.status}
                      label={locale('actions.wizard.active')}
                      data-id={`domain_edit-dns_wizard_${domainName}`}
                    />
                  </div>
                )}
                {isNextDueDate && !isOverDue && (
                  <Message
                    icon={<IconWarning />}
                    borderColor={colors.next_due_date.border}
                    iconColor={colors.next_due_date.icon}
                    text={getMessageWarningToNextDueDate()}
                    testId="message_nextDueDate"
                    variant="default"
                  />
                )}
                {isOverDue && (
                  <Message
                    icon={<IconWarning />}
                    borderColor={colors.due_date.border}
                    iconColor={colors.due_date.icon}
                    text={getMessageWarningDueDate()}
                    testId="message_dueDate"
                    variant="default"
                  />
                )}
                {productIsActive && disabledAutoRenovation && !isOverDue && !isNextDueDate && (
                  <Message
                    icon={<IconWarning />}
                    borderColor={colors.due_date.border}
                    iconColor={colors.due_date.icon}
                    text={getMessageAutoRenovation()}
                    testId="disabled_auto_renovation"
                    variant="default"
                  />
                )}
                {productIsExpired && (
                  <Message
                    icon={<IconWarning />}
                    borderColor={colors.expired.border}
                    iconColor={colors.expired.icon}
                    text={getMessageExpired()}
                    testId="disabled_expired"
                    variant="default"
                  />
                )}
                {(isCancelled || hasReasonToCancel) && (
                  <Message
                    icon={<IconWarning />}
                    borderColor={colors.cancelled.border}
                    iconColor={colors.cancelled.icon}
                    text={getMessageCancelled()}
                    testId="message_cancelled"
                    variant="default"
                  />
                )}
              </div>
            </div>
            <div className={classnames(classes.actionContainer, { [classes.actionContainerCancelled]: domain.status === 'cancelled' })}>
              {domain.status !== 'cancelled' && (
                <CardActions className={classes.cardActions}>
                  <LinkButton
                    onClick={() => domainsTagManager()}
                    to={`${t('routes.domains')}${t('routes.domaindetail')}/${domain.id}`}
                    data-id={`domain_manage_${domainName}`}
                    data-testid="manager_button"
                  >
                    {t('details.product.domain')}
                  </LinkButton>
                </CardActions>
              )}
            </div>
          </CardContent>
        </DomainDetailCard>
      </Grid>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(withI18n()(DomainItem));
