
import gestaoTrafegoProfissionalGoogleAds from '@/media/mktPlace/collabItems/gestaoTrafegoProfissionalGoogleAds.png';
import linguagemJavascriptBasicoAvancado from '@/media/mktPlace/collabItems/linguagemJavascriptBasicoAvancado.png';
import redeDisplayYoutuber from '@/media/mktPlace/collabItems/redeDisplayYoutuber.png';
// import crieSiteProfissionalHojeMesmo from '@/media/mktPlace/collabItems/crieSiteProfissionalHojeMesmo.png'; Curso removido temporariamente
// import primeiroSiteWordpressNoAr from '@/media/mktPlace/collabItems/primeiroSiteWordpressNoAr.png'; Curso removido temporariamente
import introducaoAoWooCommerce from '@/media/mktPlace/collabItems/introducaoAoWooCommerce.png';
// import canvaDesignFacilEmpreendedores from '@/media/mktPlace/collabItems/canvaDesignFacilEmpreendedores.png'; Curso removido temporariamente
import criandoMarcasGuiaVisualConceitoDesign from '@/media/mktPlace/collabItems/criandoMarcasGuiaVisualConceitoDesign.png';
import ecommerceVendasOnline from '@/media/mktPlace/collabItems/ecommerceVendasOnline.png';
import tudoSobreCiracaoVideoParaSeuNegocio from '@/media/mktPlace/collabItems/tudoSobreCiracaoVideoParaSeuNegocio.png';
import youtubeParaIniciantesComoCriarCrescerSeuCanal from '@/media/mktPlace/collabItems/youtubeParaIniciantesComoCriarCrescerSeuCanal.png';
import afialiadoDeSucesso from '@/media/mktPlace/collabItems/afialiadoDeSucesso.png';
// import introducaoGoogleAds from '@/media/mktPlace/collabItems/introducaoGoogleAds.png'; Curso removido temporariamente
import vendaMaisWhastappBusinessGoogleMeuNegocio from '@/media/mktPlace/collabItems/vendaMaisWhastappBusinessGoogleMeuNegocio.png';
import desenvolvimentoPluginsWordpress from '@/media/mktPlace/collabItems/desenvolvimentoPluginsWordpress.png';
import introducaoLogicaPython from '@/media/mktPlace/collabItems/introducaoLogicaPython.png';
import presencaOnlineMeis from '@/media/mktPlace/collabItems/presencaOnlineMeis.png';

export const collabItems = [
  {
    id: 17, image: presencaOnlineMeis, name: 'Presença Online para MEIs', lessons: 20, duration: '01:25min', link: 'https://conteudos.hostgator.com.br/hg-academy-sebrae-presenca-online-mei?utm_source=marketplace&utm_medium=banner&utm_campaign=presenca-online-mei ',
  },
  {
    id: 0, image: gestaoTrafegoProfissionalGoogleAds, name: 'Gestão de Tráfego Profissional em Google Ads', lessons: 49, duration: '11:30min', description: 'Desconto especial para clientes HostGator:', totalPrice: 599.00, newPrice: 199.00, link: 'https://www.hostgator.com.br/academy/domine-google-ads ', premium: true,
  },
  {
    id: 1, image: linguagemJavascriptBasicoAvancado, name: 'Linguagem Javascript: do Básico ao Avançado', lessons: 50, duration: '20:27min', description: 'Desconto especial para clientes HostGator:', totalPrice: 497.00, newPrice: 397.00, link: 'https://academy.hostgator.com.br/linguagem-javascript?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-javascript', premium: true,
  },
  {
    id: 2, image: redeDisplayYoutuber, name: 'Rede de Display e YouTube', lessons: 16, duration: '05:36min', description: 'Desconto especial para clientes HostGator:', totalPrice: 99.99, newPrice: 39.99, link: 'https://academy.hostgator.com.br/rede-de-display-do-google-ads?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-display-youtube', premium: true,
  },
  // { Curso removido temporariamente
  //   id: 3, image: crieSiteProfissionalHojeMesmo, name: 'Crie um site profissional hoje mesmo', lessons: 6, duration: '26min', link: 'https://app.collabplay.online/criador-site-profissional',
  // },
  // { Curso removido temporariamente
  //   id: 4, image: primeiroSiteWordpressNoAr, name: 'Meu Primeiro Site WordPress no Ar', lessons: 17, duration: '01:35min', link: 'https://app.collabplay.online/meu-primeiro-site-wordpress',
  // },
  {
    id: 5, image: introducaoAoWooCommerce, name: 'Introdução ao WooCommerce', lessons: 6, duration: '35min', link: 'https://academy.hostgator.com.br/sua-loja-online-com-woocommerce?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-woocommerce',
  },
  // { Curso removido temporariamente
  //   id: 6, image: canvaDesignFacilEmpreendedores, name: 'Canva: Design Fácil para Empreendedores', lessons: 11, duration: '01:59min', link: 'https://app.collabplay.online/canva-design-facil-para-empreendedores',
  // },
  {
    id: 7, image: criandoMarcasGuiaVisualConceitoDesign, name: 'Criando Marcas: Guia Visual do Conceito ao Design', lessons: 11, duration: '02:06min', link: 'https://academy.hostgator.com.br/criando-marcas-guia-visual-do-conceito-ao-design?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-criando-marcas',
  },
  {
    id: 8, image: ecommerceVendasOnline, name: 'E-commerce e Vendas Online', lessons: 7, duration: '02:22min', link: 'https://academy.hostgator.com.br/ecommerce-vendas-online?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-ecommerce',
  },
  {
    id: 9, image: tudoSobreCiracaoVideoParaSeuNegocio, name: 'Tudo sobre Criação de Vídeo para o seu Negócio', lessons: 16, duration: '03:54min', link: 'https://academy.hostgator.com.br/criacao-de-video-para-seu-negocio?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-criacao-video',
  },
  {
    id: 10, image: youtubeParaIniciantesComoCriarCrescerSeuCanal, name: 'YouTube para Iniciantes: Como criar e crescer o seu canal', lessons: 8, duration: '01:24min', link: 'https://academy.hostgator.com.br/youtube-iniciantes-criar-crescer-canal?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-youtube-iniciantes',
  },
  {
    id: 11, image: afialiadoDeSucesso, name: 'Afiliado de Sucesso', lessons: 20, duration: '01:25min', link: 'https://academy.hostgator.com.br/afiliado-de-sucesso?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-afiliado-sucesso',
  },
  // { Curso removido temporariamente
  //   id: 12, image: introducaoGoogleAds, name: 'Introdução ao Google Ads', lessons: 14, duration: '02:32min', link: 'https://app.collabplay.online/introducao-google-ads',
  // },
  {
    id: 13, image: vendaMaisWhastappBusinessGoogleMeuNegocio, name: 'Venda Mais com WhatsApp Business e Google Meu Negócio', lessons: 12, duration: '01:15min', link: 'https://academy.hostgator.com.br/whatsapp-business-e-google-meu-negocio?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-whatsapp-google',
  },
  // { Curso removido temporariamente
  //   id: 14, image: null, name: 'HostGator Insights', lessons: 14, duration: '07:33min', link: 'https://app.collabplay.online/hostgator-insights',
  // },
  {
    id: 15, image: desenvolvimentoPluginsWordpress, name: 'Desenvolvimento de Plugins para WordPress', lessons: 15, duration: '02:40min', link: 'https://academy.hostgator.com.br/desenvolvimento-de-plugins-para-wordpress?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-plugins-wp',
  },
  {
    id: 16, image: introducaoLogicaPython, name: 'Introdução à Lógica de Programação em Python', lessons: 11, duration: '02:08min', link: 'https://academy.hostgator.com.br/introducao-a-logica-de-programacao-em-python?utm_source=marketplace&utm_medium=banner&utm_campaign=curso-logica-programacao',
  },
];
