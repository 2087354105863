import React, { useLayoutEffect, useState } from 'react';
import { TitanUpgradeBottomAdvertisementHandlerLocale } from './TitanUpgradeBottomAdvertisementHandler.locale';
import { TitanUpgradeBottomAdvertisement } from '../TitanUpgradeBottomAdvertisement';
import {
  executeGABottomBannerDarkTitanBannerDisplay,
  executeGABottomBannerDarkTitanBannerDisplayPlans,
  executeGABottomBannerLightTitanBannerDisplay,
  executeGABottomBannerLightTitanBannerDisplayPlans,
} from '@/utils/ThirdParties/tagManager';

function TitanUpgradeBottomAdvertisementHandler({
  variant = 'dark',
  shouldRender = true,
  setDisplayModal = () => {},
}) {
  const {
    knowMore,
    description,
    newTag,
    title,
  } = TitanUpgradeBottomAdvertisementHandlerLocale;

  const [alreadyViewed, setAlreadyViewed] = useState(false);

  useLayoutEffect(() => {
    if (!alreadyViewed) {
      switch (variant) {
        case 'dark':
          setAlreadyViewed(true);
          executeGABottomBannerDarkTitanBannerDisplay();
          break;
        case 'light':
          setAlreadyViewed(true);
          executeGABottomBannerLightTitanBannerDisplay();
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKnowMoreClick = () => {
    setDisplayModal(true);
    switch (variant) {
      case 'dark':
        executeGABottomBannerDarkTitanBannerDisplayPlans();
        break;
      case 'light':
        executeGABottomBannerLightTitanBannerDisplayPlans();
        break;
      default:
        break;
    }
  };

  return (
    <TitanUpgradeBottomAdvertisement
      variant={variant}
      shouldRender={shouldRender}
      knowMore={knowMore}
      description={description}
      newTag={newTag}
      title={title}
      handleKnowMoreClick={handleKnowMoreClick}
    />
  );
}

export default TitanUpgradeBottomAdvertisementHandler;
