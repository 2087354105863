import { locale } from '@/utils/locale';

export const useMountEmptyListLocale = {
  routes: {
    domains: locale('routes.domains'),
    emailsList: locale('routes.emailsList'),
    products: locale('routes.products'),
    security: locale('routes.security'),
    academy: locale('routes.courses'),
  },
  emptyOptions: {
    hireHost: locale('sites.emptyOptions.hireHost'),
    hirePlan: locale('sites.emptyOptions.hirePlan'),
    manageServer: locale('sites.emptyOptions.manageServer'),
    manageEmails: locale('sites.emptyOptions.manageEmails'),
    manageDomains: locale('sites.emptyOptions.manageDomains'),
    manageSecurity: locale('sites.emptyOptions.manageSecurity'),
    manageAcademy: locale('sites.emptyOptions.manageAcademy'),
  },
};
