import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles, Button, Tooltip } from '@material-ui/core';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { parseToHref, preventClick } from '@/utils/Validators/validation';
import styles from './styles';

const ShadowButton = ({
  classes,
  children,
  disabled,
  to,
  blank,
  onClick,
}) => {
  const toLocation = parseToHref(to);
  const isInternal = url => window.location.host === url.host;

  const isInternalRender = () => (
    isInternal(toLocation) ? (
      <NavLink
        to={to}
        className={classes.link}
        onClick={(e) => {
          if (onClick) { onClick(); }
          preventClick(e, disabled);
        }}
      >
        <Button
          disabled={disabled}
          classes={{ root: classes.root }}
          className={classes.button}
        >
          {children}
        </Button>
      </NavLink>
    ) : (
      <Button
        href={to}
        disabled={disabled}
        classes={{
          root: classes.root,
          disabled: classes.disabled,
        }}
        target={blank ? '_blank' : '_self'}
        className={classes.button}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  );

  return disabled ? (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
        popper: classes.customPopper,
      }}
      placement="top"
      title={(
        <Trans i18nKey="plan.tooltip.disabled">
          <>0</>
          1
          <>2</>
        </Trans>
      )}
    >
      {isInternalRender()}
    </Tooltip>
  ) : (isInternalRender());
};

ShadowButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  to: PropTypes.string.isRequired,
  blank: PropTypes.bool,
};


export default withStyles(styles)(ShadowButton);
