import React from 'react';
import * as Style from './IconBell.style';

const IconBell = ({
  color = '#000000',
  background = '#FFD54C',
  size = '40',
  testId = 'icon-bell',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="20.5" cy="25.5" rx="17" ry="8.5" fill={background} />
      <path d="M30.6475 24.1506V17.5567C30.6475 15.0233 29.5588 12.5941 27.6718 10.7893C26.0389 9.26224 23.9704 8.36004 21.7569 8.18646V6.86762C21.7569 6.38176 21.3577 6 20.8497 6C20.3419 5.99976 19.9427 6.41638 19.9427 6.90226V8.25574C15.1529 8.77621 11.4152 12.6979 11.4152 17.3831V24.1505C9.92737 24.1851 8.65744 25.2612 8.51216 26.6492C8.43952 27.4127 8.69365 28.2109 9.23785 28.8009C9.78204 29.3908 10.5804 29.7031 11.3787 29.7031H17.221C17.4025 31.5425 19.0354 33 20.9948 33C22.9541 33 24.5873 31.5424 24.7685 29.7031H30.6108C31.4091 29.7031 32.2075 29.356 32.7517 28.8009C33.2959 28.2109 33.5862 27.4474 33.4774 26.6492C33.4052 25.2612 32.135 24.1853 30.6472 24.1507L30.6475 24.1506ZM21.0313 31.2651C20.0515 31.2651 19.2532 30.6056 19.0719 29.7034H22.991C22.8095 30.6059 22.0111 31.2651 21.0313 31.2651ZM31.4458 27.6213C31.2281 27.8295 30.9378 27.9684 30.6475 27.9684H11.4152C11.0886 27.9684 10.8345 27.8643 10.6168 27.6213C10.3991 27.4131 10.3265 27.1008 10.3265 26.8231C10.3627 26.3027 10.9072 25.886 11.4878 25.886C12.4312 25.886 13.2296 25.1571 13.2296 24.2203L13.2298 17.3834C13.2298 13.3576 16.677 9.99135 20.8864 9.92194H21.0315C23.0273 9.92194 24.9505 10.6854 26.4019 12.0736C27.9622 13.5312 28.8332 15.544 28.8332 17.5916V24.2549C28.8332 25.1571 29.5954 25.9206 30.5749 25.9206C31.1918 25.9206 31.6998 26.337 31.7362 26.8577C31.7727 27.1356 31.6636 27.413 31.4459 27.6215L31.4458 27.6213Z" fill={color} />
      <path d="M25.9301 16.0991C25.4221 16.0991 25.0229 16.4809 25.0229 16.9667V21.7212C25.0229 22.2071 25.4221 22.5888 25.9301 22.5888C26.4381 22.5888 26.8373 22.2071 26.8373 21.7212V16.9667C26.8373 16.4809 26.4381 16.0991 25.9301 16.0991Z" fill={color} />
    </svg>

  </Style.Wrapper>
);


export default IconBell;
