const styles = theme => ({
  btn: {
    '&:disabled': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: theme.color.disabled,
    },
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.chambray}`,
    color: theme.color.white,
    padding: '6px 16px',
    height: '38px',
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    display: 'inline-block',
    marginTop: '2px',
    color: theme.color.white,
  },
});

export default styles;
