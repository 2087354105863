/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { cnpjCpfSize } from '@/utils/Validators/validation';
import { COUNTRY } from '@/config';


export const CpfCnpjMask = (userInput) => {
  if (cnpjCpfSize(userInput) <= 11) {
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }
  return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
};

export const zipcodeMask = {
  br: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cl: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  mx: [/\d/, /\d/, /\d/, /\d/, /\d/],
  co: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  other: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
};

export const postalCodeMask = {
  BR: value => value.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2'),
  CL: value => value.replace(/\D/g, ''),
  MX: value => value.replace(/\D/g, ''),
  CO: value => value.replace(/\D/g, ''),
  other: value => value.replace(/\D/g, ''),
};

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const cellphoneMask = {
  br: ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  cl: [/\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  co: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
  mx: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  other: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

export const cellphoneCover = {
  br: {
    regex: /^(\d{2})\d{5}(\d{4})$/,
    replace: '($1) *****-$2',
  },
  cl: {
    regex: /^(\d{1})\d{4}(\d{4})$/,
    replace: '$1 *****-$2',
  },
  co: {
    regex: /^(\d{2})\d{4}(\d{4})$/,
    replace: '$1 *****-$2',
  },
  mx: {
    regex: /^(\d{3})\d{4}(\d{4})$/,
    replace: '$1 *****-$2',
  },
  other: {
    regex: /^(\d{2})\d+(\d{4})$/,
    replace: '$1 *****-$2',
  },
};

export const cellphonePlaceholder = {
  br: '(99) 99999-9999',
  cl: '9 9999-9999',
  co: '99999-99999',
  mx: '1 99 9999 9999',
  other: '999999999999999',
};

export const phoneNumberPattern = {
  br: {
    regex: /^(\d{2})(\d{5})(\d{4})$/,
    replace: '($1) $2-$3',
  },
  cl: {
    regex: /^(\d{1})(\d{4})(\d{4})$/,
    replace: '$1 $2-$3',
  },
  co: {
    regex: /^(\d{4})(\d{5})$/,
    replace: '$1-$2',
  },
  mx: {
    regex: /^(\d{1})(\d{2})(\d{4})(\d{4})$/,
    replace: '$1 $2 $3 $4',
  },
  other: {
    regex: /^(\d+)$/,
    replace: '$1',
  },
};

export const applyCnpjMask = value => value
  .replace(/\D+/g, '')
  .replace(/(\d{2})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1/$2')
  .replace(/(\d{4})(\d)/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const applyCpfMask = value => value
  .replace(/\D+/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const applyDocumentMask = (value) => {
  if (value && COUNTRY === 'br') {
    return value.length < 15 ? applyCpfMask(value) : applyCnpjMask(value);
  }
  return value;
};

export const applyDateMask = value => value
  .replace(/\D+/g, '')
  .replace(/(\d{2})(\d)/, '$1\/$2')
  .replace(/(\d{2})(\d)/, '$1\/$2');

export const applyCepMask = value => value
  .replace(/\D+/g, '')
  .replace(/(\d{5})(\d)/, '$1-$2')
  .replace(/(-\d{3})\d+?$/, '$1');

export const applyBRMoneyMask = (value) => {
  if (value) {
    let mask = `${value}`.replace(/\D/g, '');

    if (mask.length > 2) {
      if (mask[0] == 0 && mask[1] == 0) {
        mask = mask.replace('00', '');
      }

      if (mask[0] == 0) {
        mask = mask.replace('0', '');
      }
    }

    if (mask.length == 1) {
      mask = `00${mask}`;
    }

    if (mask.length == 2) {
      mask = `0${mask}`;
    }

    if (mask == '000') {
      mask = '';
    }

    if (!mask || Number(mask) <= 0) {
      return '';
    }

    const counter = (value.length - 5) / 3;

    mask = mask.replace(/^([.\d]+)(\d{2})$/, '$1,$2');
    for (let i = 0; i < counter; i += 1) {
      mask = mask.replace(/(\d+)(\d{3})([.,\d]+)$/, '$1.$2$3');
    }

    mask = `R$ ${mask}`;
    return mask;
  }
  return value;
};
