import React, { } from 'react';
import {
  Dialog,
  IconButton,
} from '@material-ui/core'; import * as Styles from './FinishSwitchPaymentMethodModal.styles';
import { FinishSwitchPaymentMethodModalProps } from './FinishSwitchPaymentMethodModal.types';
import { IconSuccess } from '@/icons';
import { Close } from '@material-ui/icons';
import {
  BRASPAG_PARC_10X,
  BRASPAG_PARC_11X,
  BRASPAG_PARC_12X,
  BRASPAG_CREDIT,
  BRASPAG_PARC_1X,
  BRASPAG_PARC_2X,
  BRASPAG_PARC_3X,
  BRASPAG_PARC_4X,
  BRASPAG_PARC_5X,
  BRASPAG_PARC_6X,
  BRASPAG_PARC_7X,
  BRASPAG_PARC_8X,
  BRASPAG_PARC_9X,
  CREDIT_CARD_ES,
  GATORPAY_PAYPAL,
  PAYPAL,
  BOLETO,
  BOLETO_CL_CO,
  BOLETO_MX,
  GATORPAY_BOLETO,
  PIX,
} from '@/config/billing/paymentMethods';
import arrow from '@/media/icons/icon_arrow_right.svg';
import {
  isBRServer,
} from '@/utils/Validators/validation';

const FinishSwitchPaymentMethodModal = ({
  onCloseClick,
  titleLabel = 'Switch payment method',
  infoSwitchPaymentMethodLabel = 'Description',
  buttonBackLabel = 'Back to subscriptions',
  onBack,
  newPaymentMethod = 'boleto',

}) => {
  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
  };

  const handleOnBack = () => {
    onBack && onBack();
  };

  const getPaymentMethod = () => {
    switch (newPaymentMethod) {
      case BRASPAG_PARC_10X:
      case BRASPAG_PARC_11X:
      case BRASPAG_PARC_12X:
      case BRASPAG_PARC_1X:
      case BRASPAG_PARC_2X:
      case BRASPAG_PARC_3X:
      case BRASPAG_PARC_4X:
      case BRASPAG_PARC_5X:
      case BRASPAG_PARC_6X:
      case BRASPAG_PARC_7X:
      case BRASPAG_PARC_8X:
      case BRASPAG_PARC_9X:
      case CREDIT_CARD_ES:
      case BRASPAG_CREDIT:
        return 3;
      case GATORPAY_PAYPAL:
      case PAYPAL:
        return 1;
      case BOLETO:
      case GATORPAY_BOLETO:
      case BOLETO_CL_CO:
      case BOLETO_MX:
        return 2;
      case PIX:
        return 5;
      default:
        return -1;
    }
  };

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      open
      onClose={handleOnCloseClick}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          maxWidth: '726px',
        },
      }}
    >
      <Styles.Content>
        <Styles.ContentCloseButton>
          <IconButton data-testid="btn-close-modal-switch-payment" aria-label="Fechar" onClick={onCloseClick}>
            <Close />
          </IconButton>
        </Styles.ContentCloseButton>
        <Styles.Header>
          <Styles.Title data-testid="finish-switch-payment-method-title">{titleLabel}</Styles.Title>
        </Styles.Header>
        <Styles.Info>
          <Styles.InfoIcon>
            <IconSuccess
              size="26"
              testId="icon-attention-switch-payment-method"
            />
          </Styles.InfoIcon>
          <Styles.InfoText
            data-testid="info-text-attention-switch-payment-method"
          >
            <strong>{`${infoSwitchPaymentMethodLabel[0]} ${infoSwitchPaymentMethodLabel[getPaymentMethod()]}`}</strong>
            {infoSwitchPaymentMethodLabel[4]}
          </Styles.InfoText>
        </Styles.Info>
        <Styles.Controls>
          <Styles.ControlButton>

            <Styles.CTA
              data-testid="link-to-back-in-switch-payment-method"
              isBRServer={isBRServer}
              onClick={handleOnBack}
              to="#"
              arrow={arrow}
            >
              {buttonBackLabel}
            </Styles.CTA>
          </Styles.ControlButton>
        </Styles.Controls>

      </Styles.Content>
    </Dialog>
  );
};

FinishSwitchPaymentMethodModal.propTypes = FinishSwitchPaymentMethodModalProps;

export default FinishSwitchPaymentMethodModal;
