import styled, { css } from 'styled-components';

export const HostingPlatformCardWrapper = styled.div`
  ${() => css`
    min-height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    > span {
      position: absolute;
      top: 0;
      right: 0;
    }
    > img{
      max-width: 120px;
    }
  `}
`;

export const ContentText = styled.p`
  ${({ theme }) => css`
    margin: 8px 0 0 0;
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: ${theme.font.sizes.xxs};
    line-height: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    text-align: center;
  `}
`;
