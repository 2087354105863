import { useState, useLayoutEffect } from 'react';
import { debounce } from '@/utils/debounce';

const useResize = () => {
  const [rect, setRect] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResize = debounce(() => {
    setRect({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  });

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return rect;
};

export default useResize;
