import React from 'react';
import {
  withStyles, Tooltip, Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import Info from '@material-ui/icons/Info';
import { getLangByCountry } from '@/config/application/langConfig';
import { SUPPORT_SECURITY_URL } from '@/config/urls/domainsUrls';
import styles from './styles';

const Status = ({ classes, t }) => {
  const {
    lastScan, nextScan, scanning, issues, attentionFlag, scanned,
  } = useSelector(state => state.sitelock);
  const alreadyScanned = {
    green: {
      color: classes.safe,
      title: t('sitelock.scannedSecure'),
      message: t('sitelock.scannedSecureMessage'),
    },
    red: {
      color: classes.issues,
      title: (issues > 1) ? `${issues} ${t('sitelock.scannedIssues')}` : `${issues} ${t('sitelock.scannedIssuesSingular')}`,
      message: t('sitelock.scannedIssuesMessage'),
    },
    yellow: {
      color: classes.pending,
      title: t('sitelock.scannedPending'),
      message: t('sitelock.scannedPendingMessage'),
    },
  };

  const color = (scanning && !scanned) ? classes.pending : alreadyScanned[attentionFlag].color;
  const title = (scanning && !scanned) ? t('sitelock.scannedPending') : alreadyScanned[attentionFlag].title;
  const message = (scanning && !scanned) ? t('sitelock.scannedPendingMessage') : alreadyScanned[attentionFlag].message;
  return (nextScan && lastScan) && (
    <div data-testid="scan-status">
      <div className={classes.statusArea}>
        <div className={classes.statusLabel}>Status da Varredura</div>
        <div data-testid="scan-results-color" status-color={attentionFlag} className={classnames([classes.statusMessage, color])}>
          <span className={classes.bullet}>{ title }</span>
          {attentionFlag !== 'red' && (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              popper: classes.customPopper,
            }}
            title={message}
            placement="right"
            data-testid="scan-tooltip"
          >
            <Info color="primary" fontSize="small" />

          </Tooltip>
          )}

        </div>
        {attentionFlag === 'red' && (
          <div className={classes.malwareAlert}>
            <p>{t('sitelock.scannedIssuesWarningP1')}</p>
            <p>
              <Trans i18nKey="sitelock.scannedIssuesWarningP2" helpUrl={SUPPORT_SECURITY_URL}>
                <>0</>
                <strong>1</strong>
                <a target="_blank" rel="noopener noreferrer" href={SUPPORT_SECURITY_URL} className={classes.link}>2</a>
                <strong>3</strong>
              </Trans>
            </p>
          </div>
        )}
      </div>
      <Typography className={classes.statusDates}>
        <strong>{t('sitelock.nextScan')}</strong>
        {' '}
        {!Number.isNaN(nextScan.getTime()) && nextScan.toLocaleString(getLangByCountry().locale, { dateStyle: 'short' })}
      </Typography>
      <Typography className={classes.statusDates}>
        <strong>{t('sitelock.prevScan')}</strong>
        {' '}
        {!Number.isNaN(lastScan.getTime()) && lastScan.toLocaleString(getLangByCountry().locale, { dateStyle: 'short' })}
      </Typography>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(Status));
