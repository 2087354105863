import {
  REQUEST_CUSTOMER_SURVEY,
  SUCCESS_CUSTOMER_SURVEY,
  ERROR_CUSTOMER_SURVEY,
  REQUEST_VERIFY_CUSTOMER_SURVEY,
  SUCCESS_VERIFY_CUSTOMER_SURVEY,
  ERROR_VERIFY_CUSTOMER_SURVEY,
  SKIP_CUSTOMER_SURVEY,
} from '@/redux/actions/actionsTypes';

const initialState = {
  loading: false,
  loadingVerify: true,
  success: false,
  hasResponse: false,
  skipCustomerSurvey: false,
};

export default function customerProfileSurvey(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_SURVEY:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case SUCCESS_CUSTOMER_SURVEY:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ERROR_CUSTOMER_SURVEY:
      return {
        ...state,
        loading: false,
      };

    case REQUEST_VERIFY_CUSTOMER_SURVEY:
      return {
        ...state,
        loadingVerify: true,
      };
    case SUCCESS_VERIFY_CUSTOMER_SURVEY:
      return {
        ...state,
        hasResponse: true,
        loadingVerify: false,
      };
    case ERROR_VERIFY_CUSTOMER_SURVEY:
      return {
        ...state,
        hasResponse: false,
        errorVerify: true,
        loadingVerify: false,
      };

    case SKIP_CUSTOMER_SURVEY:
      return {
        ...state,
        loadingVerify: false,
        skipCustomerSurvey: action.payload,
      };
    default:
      return state;
  }
}
