import {
  RECEIVE_CODEGUARD_DOMAINS,
  REQUEST_CODEGUARD_DOMAINS,
  RECEIVE_CODEGUARD_PRICE,
  REQUEST_CODEGUARD_BASIC_PRICE,
  RECEIVE_CODEGUARD_BASIC_PRICE,
  ABORT_CODEGUARD_BASIC_PRICE,
  ERROR_CODEGUARD_BASIC_PRICE,
  ABORT_CODEGUARD_BUY_FAST_CHECKOUT,
  REQUEST_CODEGUARD_BUY_FAST_CHECKOUT,
  RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT,
  ERROR_CODEGUARD_BUY_FAST_CHECKOUT,
  ABORT_CODEGUARD_PAYMENT_METHOD,
  REQUEST_CODEGUARD_PAYMENT_METHOD,
  RECEIVE_CODEGUARD_PAYMENT_METHOD,
  ERROR_CODEGUARD_PAYMENT_METHOD,
} from '@/redux/actions/actionsTypes';


const initialState = {
  total: null,
  loading: true,
  loadingDomains: false,
  codeguard: [],
  CGbasicPrice: null,
  cta: {
    isLoadingPrices: false,
    isLoadingPayment: false,
    priceLoadedDate: null,
    paymentLoadedDate: null,
    aborted: false,
    error: false,
    prices: null,
    paymentMethods: null,
  },
  buyFastcheckout: {
    isLoading: false,
    loadedDate: null,
    aborted: false,
    error: false,
    invoiceId: null,
    redirectToInvoices: false,
  },
};
export default function codeguard(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CODEGUARD_DOMAINS:
      return {
        ...state,
        loadingDomains: true,
      };
    case RECEIVE_CODEGUARD_DOMAINS:
      return {
        ...state,
        loading: false,
        total: action.total,
        codeguard: [...action.codeguard],
      };
    case RECEIVE_CODEGUARD_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
        },
        CGbasicPrice: action.basicPrice,
      };
    case REQUEST_CODEGUARD_BASIC_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: true,
          priceLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_CODEGUARD_BASIC_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: false,
          error: false,
          prices:
            [
              {
                price: action.data.data.product.prices.renewalPricing.annually,
                cycle: 'annually',
              },
              {
                price: action.data.data.product.prices.renewalPricing.semiannually,
                cycle: 'semiannually',
              },
              {
                price: action.data.data.product.prices.renewalPricing.quarterly,
                cycle: 'quarterly',
              },
              {
                price: action.data.data.product.prices.renewalPricing.monthly,
                cycle: 'monthly',
              },
            ],
        },
      };
    case ABORT_CODEGUARD_BASIC_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_CODEGUARD_BASIC_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_CODEGUARD_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: true,
          paymentLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_CODEGUARD_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: false,
          paymentMethods: Object.entries(action.data.data).filter(paymentMethod => paymentMethod[1].selectable),
        },
      };
    case ABORT_CODEGUARD_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_CODEGUARD_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_CODEGUARD_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: true,
          loadedDate: Date.now(),
          aborted: false,
          error: false,
          redirectToInvoices: false,
        },
      };
    case RECEIVE_CODEGUARD_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
          invoiceId: action.data.invoice_id,
          paymentMethod: action.data.paymentMethod,
          redirectToInvoices: true,
        },
      };
    case ABORT_CODEGUARD_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: true,
          error: false,
          redirectToInvoices: false,
        },
      };
    case ERROR_CODEGUARD_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
          redirectToInvoices: false,
        },
      };
    default:
      return state;
  }
}
