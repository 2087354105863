import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFaqProductDetail } from '@/redux/actions/faq';
import FaqDefault from '../FaqDefault/FaqDefault';
import { SUPPORT_HOST_AND_SERVERS_URL } from '@/config/urls/domainsUrls';


const FaqProductDetail = ({ packageid }) => {
  const questions = useSelector(state => state.faq.productDetail.questions);
  const loading = useSelector(state => state.faq.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    packageid && dispatch(loadFaqProductDetail(packageid));
  }, [dispatch, packageid]);

  return (
    questions.length > 0
    && (
      <FaqDefault
        questions={questions}
        link={SUPPORT_HOST_AND_SERVERS_URL}
        loading={loading}
      />
    )
  );
};

export default FaqProductDetail;
