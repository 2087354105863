const redirects = t => ([
  {
    path: '/',
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: `${t('routes.emailVerify')}/:changeFlow?`,
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: `${t('routes.phoneVerify')}/:changeFlow?`,
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: t('routes.onboarding'),
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: t('routes.persona'),
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: t('routes.newSite'),
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: t('routes.invoices'),
    redirectPath: `${t('routes.invoices')}${t('routes.unpaid')}`,
  },
  {
    path: t('routes.helpRedirect'),
    redirectPath: t('routes.help'),
  },
  {
    path: `${t('routes.helpRedirect')}${t('routes.supportforms')}`,
    redirectPath: `${t('routes.help')}${t('routes.supportforms')}`,
  },
  {
    path: `${t('routes.homeRedirect')}${t('routes.sites')}`,
    redirectPath: t('routes.sitesPage'),
  },
  {
    path: `${t('routes.homeRedirect')}${t('routes.products')}`,
    redirectPath: t('routes.products'),
  },
  {
    path: `${t('routes.homeRedirect')}${t('routes.emails')}`,
    redirectPath: t('routes.emails'),
  },
  {
    path: `${t('routes.homeRedirect')}${t('routes.domains')}`,
    redirectPath: t('routes.domains'),
  },
  {
    path: 'non existant path -- force logic',
    redirectPath: t('routes.myData'),
  },
]);

export default redirects;
