const styles = theme => ({
  card: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cardContent: {
    minHeight: 'auto',
    width: 395,
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    textAlign: 'start',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    '&:last-child': {
      padding: '19px 27px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    margin: '8px 8px 0px !important',
    color: theme.color.valencia,
    maxWidth: '425px',
  },

  loading: {
    marginRight: 5,
  },

  button: {
    marginLeft: 10,
    minWidth: 109,
    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      color: theme.color.tundora,
    },
  },

  wrapperSendCodeBoxLinks: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 90,
    },
  },
});

export default styles;
