import templateBR from './template.br';
import templateCL from './template.cl';
import templateCO from './template.co';
import templateMX from './template.mx';

const locales = {
  BR: templateBR,
  CL: templateCL,
  CO: templateCO,
  MX: templateMX,
};

export default locales;
