import { Tooltip, Typography, withStyles } from '@material-ui/core';
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { Trans, withI18n } from 'react-i18next';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Info as InfoIcon } from '@material-ui/icons';
import { find, propEq } from 'ramda';
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { formatDate } from '@/utils/Formatters/formatDate';
import { changePlan, getUnpaidUpgradeInvoice, upgradeMailGetInvoice } from '@/redux/actions/emails';
import { ERROR_UPGRADE_MAIL_INVOICE, RECEIVE_EXISTENT_INVOICE_VALIDATION } from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import Loader from '@/components/Layout/Loader';
import { loadInvoices } from '@/redux/actions/invoices';
import ModalValidateExistentInvoice from '@/components/Modal/ModalValidateExistentInvoice/ModalValidateExistentInvoice';
import { ESSENTIALS_PLAN, TRIAL_PLAN, getTitanNameById } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import DescriptionItems from './DescriptionItems';
import styles from './styles';
import { upgradeTitanAnalytics } from '@/analytics';
import { ModalToAlertUpgradeInTrial } from './ModalToAlertUpgradeInTrial';
import { ALPHA_MODAL_TO_SHOW_COMPENSATION_OF_UPGRADE_TITAN_UPGRADE } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Invoice = ({
  classes,
  t,
  plan,
  domainObject,
  paymentCycle,
  paymentMethod,
  amount,
  summaryCalcs,
  loadingUpgradeSummary,
  typing,
  queryError,
  showMonthlyCycle,
  calledFrom,
  newAmount,
  setNewAmount,
  upgradeButtonDisable,
  setAccountNumberChanged,
  disabledPayment,
}) => {
  const dispatch = useDispatch();
  let multipliedAccounts = 0;
  const upgradeTitanPlanEvents = calledFrom === 'cycleUpgrade';
  const [displayInvoiceModal, toggleDisplayInvoiceModal] = useState(false);
  const [ongoingInvoice, setOngoingInvoice] = useState(null);
  const [loading, setLoadingRedirect] = useState(false);
  const invoices = useSelector(state => state.invoices);
  const loadingValidateUpgradeInvoice = useSelector(state => state.emails.loadingValidateUpgradeInvoice);
  const [lastResponse, setLastResponse] = useState(null);
  const cycleUpgradeChangeValidation = ((plan.isFree || domainObject.packageid === plan.id) && calledFrom === 'cycleUpgrade') && plan.id !== TRIAL_PLAN;
  const inboxUpgradeChangeValidation = (amount && (amount === domainObject.total_accounts || queryError) && calledFrom === 'inboxUpgrade') && plan.id !== TRIAL_PLAN;
  const shouldDisplayMessage = inboxUpgradeChangeValidation || cycleUpgradeChangeValidation;
  let selectMessage = null;
  const shouldRedirectWithoutPayedInvoice = true;
  const [initMultiple, setInitMultiple] = useState(null);

  const [shouldShowModalInUpgradePlan, setShouldShowModalInUpgradePlan] = useState(false);
  const shouldShowCompensationUpgradeTitan = useFeatureIsOn(ALPHA_MODAL_TO_SHOW_COMPENSATION_OF_UPGRADE_TITAN_UPGRADE);

  const handleUserEnterUpgradePage = useCallback(() => {
    upgradeTitanAnalytics.enterTitanUpgradePage();
  }, []);

  useEffect(() => {
    if (upgradeTitanPlanEvents) {
      handleUserEnterUpgradePage();
    }
  }, [upgradeTitanPlanEvents, handleUserEnterUpgradePage]);


  if (calledFrom === 'inboxUpgrade') {
    selectMessage = t('proEmail.upgradePlan.selectOther');
  } else if (plan.isFree) {
    selectMessage = t('proEmail.upgradePlan.selectOtherCycleFromFree');
  } else {
    selectMessage = t(disabledPayment ? 'proEmail.upgradePlan.newSelectOtherCycle' : 'proEmail.upgradePlan.selectOtherCycle');
  }

  if (domainObject.plan === 'free' || domainObject.packageid === TRIAL_PLAN) {
    multipliedAccounts = domainObject.used_accounts > 0 ? domainObject.used_accounts : 1;
  } else {
    multipliedAccounts = domainObject.total_accounts;
  }

  let multiple = amount || multipliedAccounts;

  useEffect(() => {
    if (initMultiple == null) {
      setInitMultiple(multiple);
    }
  }, [setInitMultiple, initMultiple, multiple]);

  if (newAmount) {
    multiple = newAmount;
  }

  const upateAccountNumber = (numberAccounts) => {
    setNewAmount(numberAccounts);
    setAccountNumberChanged(numberAccounts !== initMultiple);
  };

  const pricePart = formatCurrency(
    summaryCalcs
      ? summaryCalcs.total
      : (plan.configoptions[0][paymentCycle].toLowerCase() * multiple),
  ).split(' ');

  useEffect(() => {
    if (lastResponse && invoices && (invoices.byStatus.Paid.length > 0 || shouldRedirectWithoutPayedInvoice)) {
      const isPaid = find(propEq('id', lastResponse.data.invoice_id), invoices.byStatus.Paid);
      const paymentPart = paymentMethod ? `/${paymentMethod}` : '';

      const newLocation = (lastResponse.data.upgraded || isPaid)
        ? `${t('routes.emailsList')}${t('routes.emailsTitan')}/${domainObject.domain}`
        : `${t('routes.billing')}${t('routes.unpaid')}/${lastResponse.data.invoice_id}${paymentPart}`;

      if (isPaid) {
        dispatch(enqueueSnackbar(t('proEmail.upgradePlan.successWithCredits'), notifierSuccess));
      } else if (lastResponse.data.upgraded) {
        dispatch(enqueueSnackbar(t('proEmail.upgradePlan.success'), notifierSuccess));
      }

      setTimeout(() => {
        window.location.href = newLocation;
      }, 3000);
    }
  }, [invoices, lastResponse, t, domainObject, paymentMethod, dispatch, shouldRedirectWithoutPayedInvoice]);

  const handlePay = () => {
    const newPlan = { ...plan };

    if ((plan.id === domainObject.packageid) && (domainObject.packageid === TRIAL_PLAN)) {
      newPlan.id = ESSENTIALS_PLAN;
    }

    setLoadingRedirect(true);

    const dispatchedAction = calledFrom === 'inboxUpgrade'
      ? upgradeMailGetInvoice({
        inboxAmount: amount,
        hostingId: domainObject.hosting_id,
        planId: newPlan.id,
        paymentCycle,
        offerContext: ['titan_iframe_account_upgrade'],
      })
      : changePlan({
        planId: newPlan.id,
        domain: domainObject.domain,
        cycle: paymentCycle,
        paymentMethod,
        inboxAmount: multiple,
        hostingId: domainObject.hosting_id,
        isFree: domainObject.plan === 'free',
        offerContext: ['titan_iframe_plan_upgrade'],
      });

    dispatch(dispatchedAction).then((response) => {
      if (response.type === ERROR_UPGRADE_MAIL_INVOICE) {
        dispatch(enqueueSnackbar(t('proEmail.upgradePlan.error'), notifierError));
        setLoadingRedirect(false);
      } else {
        const noCache = true;
        calledFrom === 'inboxUpgrade' ? setLastResponse({ data: response.data.data }) : setLastResponse({ data: response.data.response.data });
        dispatch(loadInvoices('Paid', noCache));
        if (upgradeTitanPlanEvents) {
          upgradeTitanAnalytics.confirmUpgrade(getTitanNameById[newPlan.id]);
        }
      }
    });
  };

  useEffect(() => {
    if (ongoingInvoice) {
      toggleDisplayInvoiceModal(true);
    }
  }, [ongoingInvoice]);

  const ValidateExistentInvoice = (hostingId) => {
    dispatch(getUnpaidUpgradeInvoice({
      domain: domainObject.domain,
      hostingId,
      isFree: domainObject.plan === 'free',
    })).then((response) => {
      if (response.type === RECEIVE_EXISTENT_INVOICE_VALIDATION) {
        if (response.data.data.already_exists) {
          setOngoingInvoice(response.data.data.invoice);
        } else {
          handlePay();
        }
      } else {
        dispatch(enqueueSnackbar(t('proEmail.upgradePlan.error'), notifierError));
      }
    });
  };

  const getTotalDayOfMonthly = () => {
    const currentDate = new Date();
    const totalOfDaysInCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0,
    ).getDate();

    if (totalOfDaysInCurrentMonth === 31) {
      return 30;
    }

    return 31;
  };

  const newNextDueDate = useMemo(() => {
    if (summaryCalcs && summaryCalcs.item && summaryCalcs.item.nextduedate) {
      return summaryCalcs.item.nextduedate;
    }


    const paymentCycleIntervals = {
      monthly: getTotalDayOfMonthly(),
      annually: 365,
    };

    return addDays(new Date(), paymentCycleIntervals[paymentCycle]);
  }, [summaryCalcs, paymentCycle]);

  const selectOtherMessage = () => (
    <>
      <Typography className={classnames(classes.WrapperTitle, classes.disabled)}>{t('proEmail.upgradePlan.invoicesTitle')}</Typography>
      <Typography className={classnames(classes.diffAmountInfo, classes.disabled)} data-testid="selectOther">
        {selectMessage}
      </Typography>
    </>
  );

  const getTotalDaysToEndTrial = useCallback(() => {
    const currentDate = new Date();
    const registerDate = new Date(parseISO(domainObject.regdate));
    const nextDueDateByRegisterDate = addDays(registerDate, getTotalDayOfMonthly());

    return differenceInCalendarDays(nextDueDateByRegisterDate, currentDate);
  }, [domainObject.regdate]);

  const handleUpgradePayment = () => {
    ValidateExistentInvoice(domainObject.hosting_id);
  };

  const isActiveProEmail = () => (
    shouldShowCompensationUpgradeTitan
    && domainObject.status.toLowerCase() === 'active'
    && domainObject.packageid === TRIAL_PLAN
  );

  const paymentHandler = () => {
    if (isActiveProEmail()) {
      setShouldShowModalInUpgradePlan(true);
      return;
    }
    handleUpgradePayment();
  };

  const invoiceTableData = () => (
    <>
      <div className={classes.titleRow}>
        <div>
          <Typography className={classes.cardTitle} data-testid="invoiceTitle">{t('proEmail.upgradePlan.invoiceCardTitle')}</Typography>

          <Typography className={classes.subTotal}>
            <small>{pricePart[0]}</small>
            {pricePart[1]}
          </Typography>
        </div>
        <PrimaryButton
          disabled={upgradeButtonDisable}
          className={classes.paymentButton}
          color="primary"
          onLoading={loadingValidateUpgradeInvoice || loading}
          type="submit"
          variant="contained"
          onClick={paymentHandler}
          data-testid="payInvoice"
        >
          {t('proEmail.upgradePlan.changePlan')}
        </PrimaryButton>
      </div>

      <DescriptionItems
        multiple={multiple}
        paymentCycle={paymentCycle}
        plan={plan}
        newNextDueDate={newNextDueDate}
        summaryCalcs={summaryCalcs}
        showMonthlyCycle={showMonthlyCycle}
        domainObject={domainObject}
        billingcycle={domainObject.billingcycle}
        calledFrom={calledFrom}
        newAmount={newAmount}
        setNewAmount={upateAccountNumber}
      />

      <div className={classes.InvoiceTableTitleWrapper}>
        <Typography className={classes.InvoiceTableTitle}>{t('proEmail.upgradePlan.description')}</Typography>

        <Typography className={classes.InvoiceTableTitle}>
          {t('proEmail.upgradePlan.value')}
        </Typography>
      </div>

      <div className={classes.InvoiceTableWrapper}>
        <div className={classnames(classes.InvoiceDescriptionWrapper, classes.planDescription)} data-testid="planDectiption">
          <Trans
            i18nKey="proEmail.upgradePlan.changePlanTo"
            values={{
              planName: plan.name,
              cycle: t(`proEmail.upgradePlan.${paymentCycle.charAt(0).toUpperCase() + paymentCycle.slice(1)}`),
              accounts: multiple,
              from: formatDate(new Date()),
              to: formatDate(newNextDueDate),
            }}
          >
            <Typography className={classnames(classes.firstColumnName, classes.marginBottom)}>
              0
            </Typography>
            <Typography className={classnames(classes.firstColumnName)}>
              1
            </Typography>
          </Trans>

        </div>
        <div className={classes.InvoiceDataWrapper}>
          <Typography className={classes.columnValue}>
            {formatCurrency(plan.configoptions[0][paymentCycle].toLowerCase() * multiple)}
          </Typography>
        </div>

        {summaryCalcs && (
          <>
            <div className={classes.InvoiceDescriptionWrapper}>
              <Typography className={classes.columnName}>{t('proEmail.upgradePlan.apllyableCredit')}</Typography>
            </div>
            <div className={classes.InvoiceDataWrapper}>
              <Typography className={classes.columnValue}>{formatCurrency(summaryCalcs.deduction)}</Typography>
            </div>
          </>
        )}

        <div className={classnames(classes.InvoiceDescriptionWrapper, classes.balance)}>
          <Typography className={classnames(classes.columnName, classes.balance)}>{t('proEmail.upgradePlan.toPay')}</Typography>
        </div>
        <div className={classnames(classes.InvoiceDataWrapper, classes.balance)}>
          <Typography className={classnames(classes.columnValue, classes.balance)} data-testid="toPay">
            {summaryCalcs
              ? formatCurrency(summaryCalcs.total)
              : formatCurrency(plan.configoptions[0][paymentCycle].toLowerCase() * multiple)
            }
          </Typography>
        </div>

        {(summaryCalcs && summaryCalcs.remaining_credits) > 0 && (
          <>
            <div className={classes.InvoiceDescriptionWrapper}>
              <Typography className={classes.columnName}>
                {t('proEmail.upgradePlan.remnantCredit')}

                <Tooltip placement="top" title={t('proEmail.upgradePlan.remnantCreditExplanation')} aria-label={t('proEmail.upgradePlan.remnantCreditExplanation')}>
                  <InfoIcon />
                </Tooltip>
              </Typography>
            </div>
            <div className={classes.InvoiceDataWrapper}>
              <Typography className={classes.columnValue}>{formatCurrency(summaryCalcs.remaining_credits)}</Typography>
            </div>
          </>
        )}
      </div>
    </>
  );

  if (loadingUpgradeSummary || typing) {
    return (
      <div className={classes.card}>
        <Loader data-testid="loader" />
      </div>
    );
  }

  return (
    <div
      className={classnames(classes.card, {
        [classes.flexRow]: shouldDisplayMessage,
      })}
      data-testid="invoiceBlock"
    >

      <ModalToAlertUpgradeInTrial
        isOpen={shouldShowModalInUpgradePlan}
        onClose={() => setShouldShowModalInUpgradePlan(false)}
        handleConfirm={handleUpgradePayment}
        getTotalDaysToEndTrial={getTotalDaysToEndTrial}
        isLoading={loadingValidateUpgradeInvoice || loading}
      />

      {ongoingInvoice && (
        <ModalValidateExistentInvoice
          apllyableCredit={ongoingInvoice.recurring_amount - ongoingInvoice.total}
          displayInvoiceModal={displayInvoiceModal}
          handlePay={handlePay}
          loading={loading}
          ongoingInboxAmount={ongoingInvoice.number_of_mailboxes}
          ongoingInvoiceId={ongoingInvoice.id}
          ongoingMailBoxPrice={formatCurrency(ongoingInvoice.recurring_amount / ongoingInvoice.number_of_mailboxes)}
          ongoingPaymentCycle={ongoingInvoice.billing_cycle}
          ongoingPlan={ongoingInvoice.product_name}
          ongoingTotalPrice={formatCurrency(ongoingInvoice.total)}
          toggleDisplayInvoiceModal={toggleDisplayInvoiceModal}
        />
      )}

      { shouldDisplayMessage
        ? selectOtherMessage()
        : invoiceTableData()
      }
    </div>
  );
};

export default withI18n()(withStyles(styles)(Invoice));
