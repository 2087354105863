import React, {
  useEffect, useMemo, useState, useCallback, useLayoutEffect,
} from 'react';
import {
  withStyles, TextField, Select, OutlinedInput, MenuItem, CircularProgress,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import snappyMail from '@/media/icons/Snappy_Mail.svg';
import PasswordField from '@/components/Fields/PasswordField';

import { requestUpgradePlans } from '@/redux/actions/emails';
import { emailsActions } from '@/redux/modules/emails';
import {
  executeGACheckBoxInModalHirePlan,
  executeGADisplayBannerTrialInSites,
  executeGADisplayBannerWithoutDomains,
  executeGADisplayKeyPasswordActiveTrial,
  executeGADisplayKeyPasswordActiveTrialInModalHirePlan,
  executeGADisplayKeyPasswordActiveTrialInSites,
  executeGADisplayKeyPasswordActiveTrialInDomains,
  executeGADisplayKeyPasswordActiveTrialWithDomain,
  executeGADisplayModalTrialInHirePlan,
  executeGADomainSelectionActiveTrial,
  executeGADomainSelectionActiveTrialInSites,
  executeGAInitTrial,
  executeGAInitTrialInLocation,
  executeGAInitTrialWithDomain,
  executeGATermsActiveTrial,
  executeGATermsActiveTrialInModalHirePlan,
  executeGATermsActiveTrialInSites,
  executeGATermsActiveTrialWithDomain,
  executeGAAddMoreTitanBoxes,
  executeGAExcludeTitanBoxes,
  executeGAHireTitanTrial,
} from '@/utils/ThirdParties/tagManager';
import { ESSENTIALS_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { SERVICE_TERMS } from '@/config/urls/supportUrls';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import LinkButton from '@/components/Buttons/LinkButton';
import styles from './styles';
import * as Styles from './ProEmailActiveTrial.styles';
import { proEmailActiveTrialLocale } from './ProEmailActiveTrial.locale';
import { useHistory } from 'react-router';
import envelope from '@/media/icons/icon_titan.svg';
import linhaColorida from '@/media/icons/linha_colorida.svg';
import linhaColoridaMobile from '@/media/icons/linha_colorida_mobile.svg';

import validators from '@/utils/FieldValidators/FieldValidators';

import * as Inputs from '@/pages/common/inputs';
import { Button } from '@/pages/common';

import { Input } from '@/pages/common/v1/inputs';
import { useResize } from '@/hooks/useResize';
import { IconThrash } from '@/icons';

const ProEmailActiveTrial = ({
  classes,
  renderedEmailList,
  location = 'list',
  selectedDomain = undefined,
  offerContext,
  productName,
  productValue,
  submittedOut,
  setSubmittedOut,
  isEnabledByURLParam = false,
  disableConfirmationCheckbox = false,
}) => {
  const insideModal = location === 'modal';
  const insideSites = location === 'sites';
  const insideList = location === 'list';
  const insideModalHirePlan = location === 'modalHirePlan' || location === 'modalEmailList' || location === 'modalDomainList';
  const view = useResize();
  const isMobile = view.width < 580;
  const isSmaller1356 = view.width < 1356;

  const variantInNewStyle = ['sites', 'modalHirePlan', 'modalEmailList', 'modalDomainList'];

  const emptyAccount = {
    email: '',
    emailError: false,
    domain: selectedDomain || '',
    domainError: false,
    password: '',
    passwordError: false,
  };

  const gaLocations = {
    sites: 'Sites',
    modalHirePlan: 'Tela de setup',
    modalEmailList: 'E-mails',
    modalDomainList: 'Domínios',
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const initialFormData = Array(3).fill(emptyAccount);
  const [formDataAccounts, setFormDataAccounts] = useState(initialFormData);
  const [disabledDispatch, setDisabledDispatch] = useState(true);

  const [valueEssentialPlan, setValueEssentialPlan] = useState(productValue);
  const [isAcceptedTerms, setAcceptedTerms] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const loadingCreateMail = useSelector(state => state.emails.createMailLoader);

  const hostDomainList = useMemo(() => renderedEmailList.map(item => ({
    ...item,
    domain: item.domain,
    id: item.domain_id,
    hostingId: item.hostingId,
    label: `@${item.domain}`,
    value: item.domain,
  })), [renderedEmailList]);

  const hostDomainListToSites = useMemo(() => {
    const list = [...hostDomainList];
    list.shift();
    return list;
  }, [hostDomainList]);


  const firstBoxDomain = hostDomainList.find(hostDomain => hostDomain.domain === formDataAccounts[0].domain);
  const indexOfFirstBoxDomain = useMemo(() => {
    if (!firstBoxDomain) {
      return;
    }
    const position = hostDomainList.indexOf(firstBoxDomain);
    return position === -1 ? 0 : position;
  }, [hostDomainList, firstBoxDomain]);

  useMemo(() => {
    if (disableConfirmationCheckbox) {
      setAcceptedTerms(true);
    }

    if (!loadingCreateMail && isSubmitted) {
      history.push(proEmailActiveTrialLocale.routeEmailList);
    }

    if (!loadingCreateMail && submittedOut && location === 'modalHirePlan') {
      history.push(proEmailActiveTrialLocale.routeEmailList);
    }

    if (!loadingCreateMail && submittedOut && location === 'modalDomainList') {
      history.push(proEmailActiveTrialLocale.routeEmailList);
    }
  }, [loadingCreateMail, isSubmitted, history, submittedOut, location, disableConfirmationCheckbox]);


  useEffect(() => {
    if (formDataAccounts[0].domain === '') {
      setFormDataAccounts([{
        ...formDataAccounts[0],
        domain: hostDomainList[0].domain,
      }]);
    }
    if (valueEssentialPlan === null || valueEssentialPlan === undefined) {
      dispatch(requestUpgradePlans()).then((response) => {
        const filteredByEssentials = response.data.filter(plano => plano.id === ESSENTIALS_PLAN);
        if (filteredByEssentials.length > 0) {
          const valueEssential = filteredByEssentials[0].configoptions[0].monthly;
          setValueEssentialPlan(formatCurrency(valueEssential));
        }
      });
    }
  }, [valueEssentialPlan, dispatch, hostDomainList, formDataAccounts, location]);

  const getError = ({ input, value, index }) => {
    const isOnlySpaces = RegExp(/^[?!^ ]+$/);

    // eslint-disable-next-line no-useless-escape
    const validEmail = validators.email(`${value}@${formDataAccounts[index].domain.toLowerCase()}`);

    const validator = {
      email: !validEmail,
      password: value.length < 8 || isOnlySpaces.test(value),
    };

    return validator[input];
  };

  const handleAddAccount = () => {
    executeGAAddMoreTitanBoxes(gaLocations[location], formDataAccounts.length + 1);
    const newAccounts = [...formDataAccounts, { ...emptyAccount, domain: formDataAccounts[0].domain }];
    setFormDataAccounts(newAccounts);
  };

  const handleRemoveAccount = (index) => {
    executeGAExcludeTitanBoxes(gaLocations[location], index + 1);
    const newAccounts = [...formDataAccounts];
    newAccounts.splice(index, 1);
    setFormDataAccounts(newAccounts);
  };

  const checkFields = useCallback(() => {
    formDataAccounts.forEach((account) => {
      const valuesArray = Object.values(account);

      const hasEmptyFieldFields = valuesArray.includes('');
      const hasErrors = valuesArray.includes(Boolean(true));

      setDisabledDispatch(hasEmptyFieldFields || hasErrors);

      if (insideModalHirePlan) {
        setDisabledDispatch(hasEmptyFieldFields || hasErrors || !isAcceptedTerms);
      }
    });
  }, [formDataAccounts, isAcceptedTerms, insideModalHirePlan]);

  const handleChange = (event, input, index) => {
    const { value } = variantInNewStyle.includes(location) && input === 'domain' ? event : event.target;
    const newAccounts = formDataAccounts.map((account, i) => {
      if (i === index) {
        return {
          ...account,
          [input]: value,
          [`${input}Error`]: getError({ input, value, index }),
        };
      }


      if (input === 'domain') {
        return { ...account, domain: value };
      }

      return account;
    });

    setFormDataAccounts(newAccounts);

    if (input === 'domain') {
      if (location === 'sites') {
        executeGADomainSelectionActiveTrialInSites();
      } else {
        executeGADomainSelectionActiveTrial();
      }
    }
  };

  const executeGADisplayKeyPassword = () => {
    if (location === 'sites') {
      return executeGADisplayKeyPasswordActiveTrialInSites();
    }
    if (location === 'modalHirePlan') {
      return executeGADisplayKeyPasswordActiveTrialInModalHirePlan();
    }
    if (location === 'modalEmailList') {
      return executeGADisplayKeyPasswordActiveTrialWithDomain();
    }
    if (location === 'modalDomainList') {
      return executeGADisplayKeyPasswordActiveTrialInDomains();
    }
    if (selectedDomain) {
      return executeGADisplayKeyPasswordActiveTrialWithDomain;
    }
    return executeGADisplayKeyPasswordActiveTrial;
  };


  const executeGATerms = () => {
    if (location === 'modalHirePlan') {
      return executeGATermsActiveTrialInModalHirePlan;
    }
    if (location === 'sites') {
      return executeGATermsActiveTrialInSites;
    }
    if (selectedDomain) {
      return executeGATermsActiveTrialWithDomain;
    }
    return executeGATermsActiveTrial;
  };

  const handleMark = () => {
    setAcceptedTerms(!isAcceptedTerms);

    if (!isAcceptedTerms) { executeGACheckBoxInModalHirePlan(); }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loadingCreateMail) {
      if (location === 'sites') {
        executeGAInitTrialInLocation('Sites', formDataAccounts.length);
      } else if (location === 'modalHirePlan') {
        executeGAInitTrialInLocation('Tela de setup', formDataAccounts.length);
        executeGAHireTitanTrial();
      } else if (location === 'modalEmailList') {
        executeGAInitTrialInLocation('E-mails', formDataAccounts.length);
      } else if (location === 'modalDomainList') {
        executeGAInitTrialInLocation('Domínios', formDataAccounts.length);
      } else if (selectedDomain) {
        executeGAInitTrialWithDomain(formDataAccounts.length);
      } else {
        executeGAInitTrial(formDataAccounts.length);
      }

      const emailAccounts = formDataAccounts.map(({ email, password }) => ({
        email_account: email,
        email_password: password,
      }));

      const createEmailData = {
        email: formDataAccounts[0].email,
        domain: formDataAccounts[0].domain,
        password: formDataAccounts[0].password,
        enabledUpdateDomainSetup: true,
        offerContext: insideSites ? ['sites_page_banner'] : offerContext || ['email_list_page_full_blurb'],
        accounts: emailAccounts,
      };

      dispatch(emailsActions.emails.createEmailTrial.request(createEmailData));

      if (insideModalHirePlan || location === 'modalDomainList') {
        setSubmittedOut(true);
      }

      if (isEnabledByURLParam) {
        history.push(proEmailActiveTrialLocale.routeEmailList);
      }

      setSubmitted(true);
    }
  };

  useEffect(() => {
    checkFields();
  }, [checkFields]);

  useLayoutEffect(() => {
    if (!insideModal && !variantInNewStyle.includes(location)) {
      executeGADisplayBannerWithoutDomains();
    }
    if (location === 'sites') {
      executeGADisplayBannerTrialInSites();
    }
    if (location === 'modalHirePlan' && !submittedOut) {
      executeGADisplayModalTrialInHirePlan();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackground = () => {
    if (isMobile && insideModalHirePlan) { return linhaColoridaMobile; }
    if (!isMobile && insideModalHirePlan) { return linhaColorida; }

    return '';
  };
  return (
    <Styles.ContainerRow location={location} background={getBackground}>
      {!insideModal && !insideSites && !insideModalHirePlan && (
        <Styles.DescriptionContainer location={location} data-testid="proEmailCard">
          <Styles.TitleWrapper>
            <Styles.Title>{proEmailActiveTrialLocale.title}</Styles.Title>
            <Styles.TrialTag>{proEmailActiveTrialLocale.trialTag}</Styles.TrialTag>
          </Styles.TitleWrapper>

          <Styles.DescriptionWrapper>
            <div>
              <Styles.Description>{proEmailActiveTrialLocale.description}</Styles.Description>

              <Styles.ListWrapper>
                <Styles.DescriptionList>
                  <li>{proEmailActiveTrialLocale.firstList[0]}</li>
                  <li>{proEmailActiveTrialLocale.firstList[1]}</li>
                  <li>{proEmailActiveTrialLocale.firstList[2]}</li>
                </Styles.DescriptionList>

                <Styles.DescriptionList>
                  <li>{proEmailActiveTrialLocale.secondList[0]}</li>
                  <li>{proEmailActiveTrialLocale.secondList[1]}</li>
                  <li>{proEmailActiveTrialLocale.secondList[2]}</li>
                </Styles.DescriptionList>
              </Styles.ListWrapper>
            </div>
            <Styles.Snappy src={snappyMail} alt="imagem Snappy" />
          </Styles.DescriptionWrapper>
        </Styles.DescriptionContainer>
      )}
      <Styles.FormWrapper location={location} data-testid="proEmailCardCreateEmail" onSubmit={e => handleSubmit(e)}>
        <Styles.Form location={location}>
          {insideModalHirePlan && (
          <Styles.TitleHirePlanWrapper>
            <Styles.Figure>
              <img
                className="envelope"
                src={envelope}
                alt="Envelope"
                loading="lazy"
                data-testid="envelope-image"
              />
            </Styles.Figure>
            <Styles.TitleForm data-testid="title-form">{proEmailActiveTrialLocale.title}</Styles.TitleForm>
          </Styles.TitleHirePlanWrapper>
          )}
          {formDataAccounts.map((formDataAccount, index) => (
            <>
              <Styles.SetEmailRow location={location}>
                <Styles.InputWrapper location={location}>
                  <Styles.Label location={location}>{proEmailActiveTrialLocale.activeTrialEmailAddress}</Styles.Label>
                  {!variantInNewStyle.includes(location) && (
                  <TextField
                    type="text"
                    id="email-name"
                    margin="normal"
                    placeholder={proEmailActiveTrialLocale.emailSuggestions[index + 1]}
                    variant="outlined"
                    onChange={event => handleChange(event, 'email', index)}
                    value={formDataAccount.email}
                    data-testid="email-email-field"
                    error={formDataAccount.emailError}
                    helperText={formDataAccount.emailError ? proEmailActiveTrialLocale.emailError : ''}
                    InputProps={{
                      inputProps: { maxLength: 64 },
                    }}
                    data-id="email-name"
                  />
                  )}
                  <Styles.SetEmailRow location={location}>
                    {variantInNewStyle.includes(location) && (
                    <Input
                      type="text"
                      id="email-name"
                      margin="normal"
                      placeholder={proEmailActiveTrialLocale.emailSuggestions[index + 1]}
                      onChange={event => handleChange(event, 'email', index)}
                      value={formDataAccount.email}
                      data-testid="email-email-field"
                      errorMessage={formDataAccount.emailError ? proEmailActiveTrialLocale.emailError : ''}
                      maxLength={64}
                      data-id="email-name"
                    />
                    )}
                    {insideModalHirePlan && (
                    <Styles.SelectInsideHirePlan>
                      <Input
                        type="text"
                        id="email-name"
                        margin="normal"
                        disabled
                        onChange={event => handleChange(event, 'email', index)}
                        value={`@${selectedDomain}`}
                        testId={`text-domain-selected-${index}`}
                        maxLength={64}
                        textAlign="right"
                        data-id="email-name"
                      />
                    </Styles.SelectInsideHirePlan>
                    )}
                  </Styles.SetEmailRow>
                </Styles.InputWrapper>
                {!insideModalHirePlan && (
                <Styles.InputWrapper location={location}>
                  {selectedDomain
                    ? (
                      <>
                        {!variantInNewStyle.includes(location) && (
                        <Styles.InputWrapper location={location} disabled>
                          <Styles.Label location={location}>{proEmailActiveTrialLocale.activeTrialDomain}</Styles.Label>
                          <TextField
                            type="text"
                            id="domain"
                            margin="normal"
                            variant="outlined"
                            value={`@${selectedDomain}`}
                            data-testid="domain"
                            inputProps={{
                              readOnly: Boolean(true),
                            }}
                          />
                        </Styles.InputWrapper>
                        )}
                      </>
                    )
                    : (
                      <>
                        <Styles.Label location={location}>{proEmailActiveTrialLocale.activeTrialDomain}</Styles.Label>
                        {variantInNewStyle.includes(location) && (
                        <Inputs.Select
                          animatedDropdownIcon="true"
                          defaultOption={indexOfFirstBoxDomain}
                          defaultOptionLabel={hostDomainList[0].label}
                          defaultOptionValue={hostDomainList[0].value}
                          disabled={index !== 0}
                          onChange={event => handleChange(event, 'domain', index)}
                          options={hostDomainListToSites}
                          selectedOption={firstBoxDomain}
                          variant="sites"
                        />
                        )}
                        <Styles.SelectWrapper location={location}>
                          {!variantInNewStyle.includes(location) && (
                          <Select
                            value={formDataAccount.domain}
                            IconComponent={KeyboardArrowDown}
                            onChange={event => handleChange(event, 'domain', index)}
                            data-id="email-select-domain"
                            disabled={index !== 0}
                            inputProps={{
                              classes: {
                                icon: classes.icon,
                              },
                            }}
                            MenuProps={{
                              classes: {
                                paper: classes.paperProps,
                              },
                            }}
                            input={(
                              <OutlinedInput
                                classes={{
                                  root: classes.selectCustom,
                                  input: classes.selectCustomInput,
                                  notchedOutline: classes.selectCustomOutline,
                                  focused: classes.selectCustomFocused,
                                }}
                                labelWidth={0}
                                name="domain"
                                id="outlined-age-simple"
                              />
                        )}
                          >
                            {hostDomainList.map(domain => (
                              <MenuItem value={domain.domain} key={domain.id}>
                                {`@${domain.domain}`}
                              </MenuItem>
                            ))}
                          </Select>
                          )}
                        </Styles.SelectWrapper>
                      </>
                    )
                }
                </Styles.InputWrapper>
                )}
                {insideModalHirePlan && !isMobile && (
                  <Styles.PasswordWrapper location={location} hasTrashIcon={index > 0}>
                    <Styles.Label location={location}>{proEmailActiveTrialLocale.activeTrialPassword}</Styles.Label>
                    <Input
                      value={formDataAccount.password}
                      type="password"
                      autoComplete="current-password"
                      name="password"
                      maxLength={10}
                      placeholder="*******"
                      onClickIconShowPassword={executeGADisplayKeyPassword}
                      onChange={event => handleChange(event, 'password', index)}
                      errorMessage={formDataAccount.passwordError ? proEmailActiveTrialLocale.passwordError : ''}
                      dataId="email-password"
                      data-testid="email-password-field"
                    />
                  </Styles.PasswordWrapper>
                )}
                {index > 0 && (
                  <>
                    {(insideSites || insideList) && !isSmaller1356 ? <></> : (
                      <Styles.RemoveButton
                        onClick={() => handleRemoveAccount(index)}
                        data-testid={`button-remove-email-${index}`}
                        type="button"
                      >
                        <IconThrash />
                      </Styles.RemoveButton>
                    )}
                  </>
                )}
              </Styles.SetEmailRow>
              {((isMobile && insideModalHirePlan) || !insideModalHirePlan) && (
                <Styles.PasswordWrapper location={location}>
                  <Styles.Label location={location}>{proEmailActiveTrialLocale.activeTrialPassword}</Styles.Label>
                  {!variantInNewStyle.includes(location) && (
                    <PasswordField
                      value={formDataAccount.password}
                      type="password"
                      autoComplete="current-password"
                      name="password"
                      required
                      placeholder="*******"
                      variant="outlined"
                      margin="normal"
                      spellCheck="false"
                      onClickDisplayKey={executeGADisplayKeyPassword()}
                      onChange={event => handleChange(event, 'password', index)}
                      error={formDataAccount.passwordError}
                      helperText={formDataAccount.passwordError ? proEmailActiveTrialLocale.passwordError : ''}
                      dataId="email-password"
                      data-testid="email-password-field"
                    />
                  )}
                  {variantInNewStyle.includes(location) && (
                    <Input
                      value={formDataAccount.password}
                      type="password"
                      autoComplete="current-password"
                      name="password"
                      maxLength={10}
                      placeholder="*******"
                      onClickIconShowPassword={executeGADisplayKeyPassword}
                      onChange={event => handleChange(event, 'password', index)}
                      errorMessage={formDataAccount.passwordError ? proEmailActiveTrialLocale.passwordError : ''}
                      dataId="email-password"
                      data-testid="email-password-field"
                    />
                  )}
                </Styles.PasswordWrapper>
              )}
              {index > 0 && (insideSites || insideList) && !isSmaller1356 && (
              <Styles.RemoveButton
                onClick={() => handleRemoveAccount(index)}
                data-testid={`button-remove-email-${index}`}
                type="button"
              >
                <IconThrash />
              </Styles.RemoveButton>
              )}
            </>
          ))}

          {formDataAccounts.length < 5 && (
            <Button
              onClick={handleAddAccount}
              variant="pillOutlined"
              data-id="button-add-email"
              testId="button-add-email"
              onLoading={loadingCreateMail}
              label={proEmailActiveTrialLocale.buttonAddAccountLabel}
            />
          )}

          {insideModalHirePlan && (
          <Styles.PosDescription location={location}>
            <Trans i18nKey="modalTitanTrialHirePlan.termDescriptions">
              <>0</>
              <>{{ productName }}</>
              <strong>{{ amount: valueEssentialPlan }}</strong>
              <>1</>
              <>2</>
            </Trans>

            <LinkButton
              onClick={executeGATerms}
              to={SERVICE_TERMS}
              data-testid="link-terms"
              target="_blank"
            >
              {proEmailActiveTrialLocale.activeTrialTerms}
            </LinkButton>

          </Styles.PosDescription>
          )}

          <Styles.ActionWrapper location={location}>

            {insideModalHirePlan && !disableConfirmationCheckbox && (
            <Styles.CheckBoxDescription location={location}>

              <Styles.OptionInput
                type="checkbox"
                checked={isAcceptedTerms}
                onChange={() => handleMark()}
                data-testid="checkbox-buttom"
              />
              <Styles.PosDescription location={location}>
                {proEmailActiveTrialLocale.confirmText}
              </Styles.PosDescription>
            </Styles.CheckBoxDescription>

            )}

            {!variantInNewStyle.includes(location) && (
            <PrimaryButton
              data-id="button-submit-email"
              data-testid="button-submit-email"
              disabled={disabledDispatch || loadingCreateMail}
              onLoading={loadingCreateMail}
              type="submit"
            >
              {proEmailActiveTrialLocale.activeTrialButtonStart}
            </PrimaryButton>
            )}
            {variantInNewStyle.includes(location) && (

            <Button
              variant="bluePill"
              data-id="button-submit-email"
              testId="button-submit-email"
              disabled={disabledDispatch || loadingCreateMail}
              onLoading={loadingCreateMail}
              titleEnabled={false}
              type="submit"
              label={!loadingCreateMail ? proEmailActiveTrialLocale.activeTrialButtonStart : (
                <div className={classes.wrapper}>
                  <CircularProgress size={20} color="#FFFFFF" data-testid="buttonCircularProgress" />
                </div>
              )}
            />

            )}
          </Styles.ActionWrapper>
        </Styles.Form>

        {insideSites && (
        <Styles.PosDescription location={location}>
          <Trans i18nKey="proEmail.activeTrialPosDescription">
            <>0</>
            <>{{ amount: valueEssentialPlan }}</>
            <>2</>
          </Trans>

          <LinkButton
            onClick={executeGATerms}
            to={SERVICE_TERMS}
            data-testid="link-terms"
            target="_blank"
          >
            {proEmailActiveTrialLocale.activeTrialTerms}
          </LinkButton>

        </Styles.PosDescription>
        )}
        {!insideSites && !insideModalHirePlan && (
        <Styles.PosDescription location={location}>
          <Trans i18nKey="proEmail.activeTrialPosDescription">
            <>0</>
            <>{{ amount: valueEssentialPlan }}</>
            <>2</>
          </Trans>

          <LinkButton
            onClick={executeGATerms}
            to={SERVICE_TERMS}
            data-testid="link-terms"
            target="_blank"
          >
            {proEmailActiveTrialLocale.activeTrialTerms}
          </LinkButton>

        </Styles.PosDescription>
        )}
      </Styles.FormWrapper>
    </Styles.ContainerRow>
  );
};

export default withStyles(styles)(ProEmailActiveTrial);
