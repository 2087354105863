import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v1.color.white.primary};
    padding: 34px 0px;
  `}
`;

export const Title = styled.h4`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  font-family: ${theme.v1.font.family.primary};
  font-size: 24px;
  font-weight: ${theme.v1.font.weights.bold};
  margin: 0 0 8px 0px;

  span {
    color: ${theme.v1.color.text.featured};
  }
`}
`;

export const Subtitle = styled.p`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  font-family: ${theme.v1.font.family.primary};
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin: 0px 0px 40px 0px
`}
`;
