import React, { useContext } from 'react';
import DOMPurify from 'dompurify';
import withWidth from '@material-ui/core/withWidth';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { Radio } from '@/pages/common/inputs';
import { Slider } from '@/pages/common/Slider';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import * as Styles from './SelectNewPlan.styles';
import { ThemeContext } from 'styled-components';

const SelectNewPlan = ({
  freePlanActivated = false,
  planOptions,
  paymentCycle,
  handleSelectOption,
  newPlanCardTitle = 'Planos Disponíveis',
  newPlanCardDescription = 'Selecione uma das opções abaixo para solicitar a atualização do seu plano e receber mais armazenamento para suas caixas de e-mail:',
  higherPlan = 'Você já possui o plano com maior capacidade de armazenamento.',
  actualPlan = 'Plano Atual',
  freeTrialPeriod = 'em Período Gratuito',
  storage = 'de armazenamento',
  ilimitedMailBoxes = 'Contas de e-mail ilimitadas',
  monthlyPerMailBox = ' ao mês / conta de e-mail',
  notSelectedTrialPlanLabel = '30 dias grátis para testar',
  whatIsincluded = 'QUE ESTÁ INCLUIDO',
  whatIsincludedInPaid = 'TODO DE TITAN BASIC, MÁS',
}) => {
  const theme = useContext(ThemeContext);

  const getNumericalThemeBreakpoint = breakpoint => parseInt(theme.breakpoints[breakpoint].slice(0, -2), 10);

  const handleClick = (optionName) => {
    handleSelectOption && handleSelectOption(optionName);
  };

  const settings = {
    initialSlide: 0,
    slidesToShow: 4,
    speed: 500,
    dots: true,
    infinite: false,
    arrows: true,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: getNumericalThemeBreakpoint('xl'),
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: getNumericalThemeBreakpoint('lg'),
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: getNumericalThemeBreakpoint('md'),
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: getNumericalThemeBreakpoint('sm'),
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderItems = () => planOptions.length > 1 && planOptions.map((option) => {
    const price = formatCurrency(paymentCycle === 'monthly'
      ? option.price[paymentCycle].toLowerCase()
      : option.price[paymentCycle].toLowerCase() / 12).split(' ');

    const pricePart = { coin: price.shift(), value: price.pop() };

    const sanitizedBennefits = DOMPurify.sanitize(option.bennefits);
    const isTrial = option.id === TRIAL_PLAN;

    const freePlan = option.isFree;
    const selectedPlan = option.isSelected;
    const notSelectedTrialPlan = isTrial && !option.isSelected;
    const selectedTrialPlan = isTrial && option.isSelected;

    return (
      <Styles.PlanOption
        active={option.active ? 'active' : 'inactive'}
        status={option.isFree ? 'free' : 'paid'}
        type="button"
        onClick={() => handleClick(option.name)}
        key={`button_${option.name}`}
        data-testid={option.name.replace(/ /g, '')}
      >
        <Styles.TagWrapper>
          {!freePlan
            && <Styles.RadioWrapper><Radio checked={!!option.active} /></Styles.RadioWrapper>
          }

          {selectedPlan && (
            <Styles.Tag type="selected" centered={option.isFree}>{actualPlan}</Styles.Tag>
          )}

          {(notSelectedTrialPlan) && (
            <Styles.Tag type="trial" centered={notSelectedTrialPlan}>{notSelectedTrialPlanLabel}</Styles.Tag>
          )}

          {(selectedTrialPlan) && (
            <Styles.Tag type="trial">{freeTrialPeriod}</Styles.Tag>
          )}
        </Styles.TagWrapper>

        <Styles.Row>
          <Styles.PlanName>{option.name}</Styles.PlanName>
        </Styles.Row>

        <Styles.StorageValue>{option.size}</Styles.StorageValue>
        <Styles.StorageLabel>{storage}</Styles.StorageLabel>

        <Styles.PaymentPrice>
          {option.isFree
            ? ilimitedMailBoxes
            : (
              <>
                <strong>{`${pricePart.coin} ${pricePart.value}`}</strong>
                {monthlyPerMailBox}
              </>
            )
          }
        </Styles.PaymentPrice>

        <Styles.Separator />

        {freePlanActivated
          && <Styles.IncludedLabel>{option.isFree ? whatIsincluded : whatIsincludedInPaid}</Styles.IncludedLabel>
        }

        <Styles.BennefitsWrapper dangerouslySetInnerHTML={{ __html: sanitizedBennefits }} />
      </Styles.PlanOption>
    );
  });

  return (
    <Styles.Card data-testid="selectNewPlanBlock">
      {planOptions.length > 1 && (
        <Styles.CardTitle data-testid="cardTitle">{newPlanCardTitle}</Styles.CardTitle>
      )}

      <Styles.CardDescription data-testid="newPlanCardDescription">
        {planOptions.length > 1 ? newPlanCardDescription : higherPlan}
      </Styles.CardDescription>

      <Slider
        settings={settings}
        items={sliderItems()}
      />
    </Styles.Card>
  );
};

export default withWidth()((SelectNewPlan));
