// DOMAIN-AVAILABILITY
const receiveDomainAvailability = response => ({
  domainAvailability: response.data.data.available,
  domainTldAvailability: response.data.data.tld_available,
  domain: response.data.data.domain,
});

// TLDS
const receiveTlds = (rawData) => {
  const { data } = rawData.data;
  const { tlds } = data;

  const tldsMap = tlds.map(rawTld => ({
    categories: rawTld.categories,
    title: rawTld.tld,
  }));

  return tldsMap;
};

// SITES
const receiveSites = rawData => [...rawData.data.sites];
const receiveSitesFromGatorSolutions = (rawData) => {
  const newData = [...rawData.data].filter(item => !(item.sub_status === 'pending' && item.invoice && item.invoice.status === 'Cancelled')).map((item) => {
    const newItem = { ...item };

    if (newItem.cpanel_domains) {
      newItem.cpanel_domains = newItem.cpanel_domains.filter(cpanelDomain => cpanelDomain.type !== 'parked');
    }

    return newItem;
  });

  return newData;
};

// DOMAINS
const receiveDomains = rawData => [...rawData.data];

// PLANS
const receivePlans = (rawData) => {
  const activeStatus = ['active', 'to_due', 'overdue'];

  const activePlans = [...rawData.data].filter(plan => activeStatus.includes(plan.status.toLowerCase()));
  return activePlans;
};

// DOMAIN DNS
const receiveDomainDns = rawData => rawData.domain.name_servers;

// PLAN DNS
const receivePlanDns = (rawData) => {
  let planDns = [];
  if (rawData) {
    const { ns1, ns2 } = rawData.data;
    planDns = [ns1, ns2];
  }
  return planDns;
};

// HAS SITE BUILDER ACTIVE
const receiveHasSiteBuilderActive = (rawData) => {
  if (rawData) {
    const { hasSiteBuilderActive } = rawData.data;
    return hasSiteBuilderActive;
  }
};

// SURVEY QUESTIONS ANSWERED
const receiveSurveyQuestionsAnswered = (rawData) => {
  let IdQuestionsAnswered = [];
  Object.keys(rawData.answers).forEach((id) => {
    IdQuestionsAnswered = [...IdQuestionsAnswered, parseInt(id, 10)];
  });
  const questionsAnswered = {
    ids: IdQuestionsAnswered,
    answers: rawData.answers,
  };
  return questionsAnswered;
};

// CHOOSEN DOMAIN DNS STATUS

const receiveChoosenDomainStatus = (rawData, choosenDomain) => {
  const choosenDomainObject = rawData.find(domainObject => domainObject.domain === choosenDomain);
  return choosenDomainObject !== undefined ? choosenDomainObject.status : null;
};

// RECEIVE SITE EXIST STATUS

const receiveSiteExistStatus = (rawData) => {
  if (rawData.cpanel_site) {
    const onlyDefaultFiles = rawData.cpanel_site.have_only_default_files;
    return {
      showOverwriteModal: !onlyDefaultFiles && rawData.cpanel_site.files_exists,
      sharedDocumentRoot: rawData.cpanel_site.shared_document_root,
      onlyDefaultFiles,
      siteType: 'cpanel',
      siteId: null,
    };
  }
  if (rawData.softaculous_site) {
    return {
      showOverwriteModal: true,
      sharedDocumentRoot: rawData.softaculous_site.shared_document_root,
      siteType: 'softaculous',
      siteId: rawData.softaculous_site.siteId,
    };
  }
};

const hgPluginInstalled = rawdata => rawdata.data && rawdata.data.hg_plugin_installed;

const assembly = {
  receiveDomainAvailability,
  receiveTlds,
  receiveSites,
  receiveSitesFromGatorSolutions,
  receiveDomains,
  receivePlans,
  receivePlanDns,
  receiveDomainDns,
  receiveSurveyQuestionsAnswered,
  receiveHasSiteBuilderActive,
  receiveChoosenDomainStatus,
  hgPluginInstalled,
  receiveSiteExistStatus,
};

export default assembly;
