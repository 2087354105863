import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    font-family: ${theme.v1.font.family.primary};
    background-color: ${theme.v1.color.white.primary};
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin: 0;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 40px;

    @media (max-width: ${theme.v1.breakpoints.md}) {
      flex-direction: column;
      gap: 24px;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme, align }) => css`
    display: flex;
    flex-direction: column;
    justify-content: ${align === 'initial' ? 'initial' : 'center'};

    @media (max-width: ${theme.v1.breakpoints.md}) {
      &:not(:first-child) {
        margin: 0;
      }
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.label};
    font-size: 12px;
    font-weight: ${theme.v1.font.weights.regular};
    line-height: 16px;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-size: 16px;
    font-weight: ${theme.v1.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 22px;
    margin-top: 4px;
  `}
`;

export const TagWrapper = styled.span`
  margin-top: 4px;
`;

export const TooltipWrapper = styled.span`
  margin-left: 8px;
`;

export const ReactivationWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const StepperWrapper = styled.div`
  margin: 26px 0px 0px;
  max-width: 494px
`;
