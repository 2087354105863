import React from 'react';
import * as Style from './IconDetailedOtherSquare.style';

const IconDetailedOtherSquare = ({
  color = '#4a4a4a',
  backgroundColor = '#ffcf00',
  width = '64',
  height = '54',
  testId = 'icon-detailed-other-square',
}) => (
  <Style.Wrapper data-testid={testId} fill={color}>
    <svg width={width} height={height} viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5358 7.89282H0.0893555V26.1607H19.5358V7.89282Z" fill={backgroundColor} />
      <path d="M18.3572 2.14722H5.39289C5.1488 2.14722 4.95093 2.34509 4.95093 2.58918V15.5535C4.95093 15.7976 5.1488 15.9954 5.39289 15.9954H18.3572C18.6013 15.9954 18.7991 15.7976 18.7991 15.5535V2.58918C18.7991 2.34509 18.6013 2.14722 18.3572 2.14722Z" stroke="#4A4A4A" strokeWidth="2.5" />
      <path d="M18.3571 23.6562C22.5067 23.6562 25.8705 20.1605 25.8705 15.8482C25.8705 11.5359 22.5067 8.04016 18.3571 8.04016C14.2076 8.04016 10.8438 11.5359 10.8438 15.8482C10.8438 20.1605 14.2076 23.6562 18.3571 23.6562Z" stroke="#4A4A4A" strokeWidth="2.5" />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedOtherSquare;
