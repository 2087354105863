import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, disabled, variant }) => {
    const variants = {
      default: {
        borderBottom: 'none',
      },
      newStandard: {
        borderBottom: `2px solid ${theme.palette.grey.lightbluish}`,
      },
      sites: {
        borderBottom: '2px solid rgba(166, 182, 204, 0.5)',
      },
    };

    return css`
    border-bottom: ${variants[variant].borderBottom};
    display: flex;
    flex-direction: column;
    flex: 1;
    pointer-events: ${disabled ? 'none' : 'initial'};
    position: relative;
    `;
  }}
`;

export const FixedLabel = styled.label`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.light};
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.sm};
    left: 0px;
    padding: 0px 5px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    transform: translate(6px, -9px) scale(0.8);
    z-index: 1;

    &.selected {
      color: ${theme.palette.secondary.primary};
    }

    &.error {
      color: ${theme.palette.error.primary};
    }

    &.disabled {
      background: linear-gradient(0deg, transparent 40%, ${theme.palette.white.light} 40%);
    }
  `}
`;

export const Label = styled.p`
  ${({ theme, selected, variant }) => {
    const variants = {
      default: {
        fontFamily: '',
      },
      newStandard: {
        fontFamily: '',
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      sites: {
        fontFamily: theme.font.family.secondary,
        marginTop: '11px',
        maxWidth: 'calc(100% - 30px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    };

    return css`
    color: ${selected ? theme.palette.primary.darkest : theme.palette.grey.main};
    font-family: ${variants[variant].fontFamily};
    font-size: ${theme.font.sizes.md};
    margin-left: 7px;
    margin-top:${variants[variant].marginTop};
    max-width: ${variants[variant].maxWidth};
    overflow:${variants[variant].overflow};
    text-overflow: ${variants[variant].textOverflow};
    white-space: ${variants[variant].whiteSpace};
    `;
  }}
`;

export const Field = styled.div`
  ${({
    disabled, theme, variant, isOpen, animatedDropdownIcon,
  }) => {
    const variants = {
      default: {
        backgroudColor: `${disabled ? theme.palette.white.dark : theme.palette.white.light}`,
        border: `1px solid ${theme.palette.grey.primary}`,
        padding: '8px 42px 8px 9px',
        maxHeight: '35px',
        transform: `${animatedDropdownIcon && `rotate(${isOpen ? '0deg' : '180deg'})`}`,
      },
      newStandard: {
        backgroudColor: `${disabled ? theme.palette.white.dark : theme.palette.white.light}`,
        border: 'none',
        padding: '8px 42px 8px 9px',
        maxHeight: '35px',
        transform: `${animatedDropdownIcon && `rotate(${isOpen ? '0deg' : '180deg'})`}`,
      },
      sites: {
        backgroudColor: `${theme.palette.white.light}`,
        border: 'none',
        padding: '0px',
        maxHeight: '42px',
        transform: `${animatedDropdownIcon && `rotate(${isOpen ? '180deg' : '0deg'})`}`,
      },
    };

    return css`
    align-items: center;
    background-color:  ${variants[variant].backgroudColor};
    border: ${variants[variant].border};
    cursor: pointer;
    display: flex;
    flex: 1;
    max-height: ${variants[variant].maxHeight};
    padding:${variants[variant].padding};
    position: relative;

    &.error {
      border-color: ${theme.palette.error.primary};

      ${Label} {
        color: ${theme.palette.error.primary};
      }

      svg {
        fill: ${theme.palette.error.primary};
        stroke: ${theme.palette.error.primary};
      }
    }

    svg {
      transform: ${variants[variant].transform};
      transition: ${animatedDropdownIcon && 'transform 200ms ease'};
    }
    `;
  }
}
`;

export const IconAdapter = styled.div`
${({
    variant,
  }) => {
    const variants = {
      default: {
        right: '18px',
      },
      newStandard: {
        right: '18px',
      },
      sites: {
        right: '8px',
      },
    };
    return css`
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: ${variants[variant].right};
    `;
  }
}
`;
export const Options = styled.ul`
  ${({
    theme, show, fixedBottomOption, variant, maxHeight,
  }) => {
    const variants = {
      default: {
        border: '2px',
        borderRadius: '3px',
        scrollbarWidth: '10px',
      },
      newStandard: {
        border: '0',
        borderRadius: '25px',
        scrollbarWidth: '7px',
        liOverflowX: 'hidden',
        liOverflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      sites: {
        border: '0',
        borderRadius: '25px',
        ulOverflow: 'hidden',
        liOverflowX: 'hidden',
        liOverflowY: 'hidden',
        scrollbarWidth: '7px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    };
    return css`
    background-color: ${theme.palette.white.light};
    border-bottom:${fixedBottomOption ? 0 : `1px solid ${theme.palette.grey.variant}`};
    border-left: 1px solid ${theme.palette.grey.variant};
    border-right: 1px solid ${theme.palette.grey.variant};
    list-style: none;
    margin: 0;
    max-height: ${show ? '123px' : '0px'};
    opacity:  ${show ? 1 : 0};
    overflow-y: auto;
    overflow: ${variants[variant].ulOverflow};
    padding: 0;

    li {
      p {
        text-overflow: ${variants[variant].textOverflow};
        white-space: ${variants[variant].whiteSpace};
        overflow-x: ${variants[variant].liOverflowX};
        overflow-y: ${variants[variant].liOverflowY};
      }
    }

    @media(min-width: ${theme.breakpoints.lg}) {
      max-height: ${show ? maxHeight : '0px'};
    }

    &::-webkit-scrollbar {
      width: ${variants[variant].scrollbarWidth};
    }

    &::-webkit-scrollbar-track {
      background: ${theme.palette.white.light};
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.palette.grey.primary};
      border-left: ${variants[variant].border} solid ${theme.palette.white.light};
      border-radius: ${variants[variant].borderRadius};
      border-right: ${variants[variant].border} solid ${theme.palette.white.light};
      height: 30px;
      width: 100%;
    }
    `;
  }
}
`;

export const Option = styled.li`
  ${({ theme, variant }) => {
    const variants = {
      default: {
        hoverColor: theme.palette.grey.ultralight,
      },
      newStandard: {
        hoverColor: theme.palette.secondary.primary,
        color: theme.color.white,
      },
      sites: {
        hoverColor: theme.palette.secondary.primary,
        color: theme.color.white,
      },
    };
    return css`
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 8px 9px 8px 16px;

    &:hover {
      background-color: ${variants[variant].hoverColor};

      p{
        color: ${variants[variant].color}
      }
    }
    `;
  }
}
`;

export const OptionLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    line-height: ${theme.font.sizes.lg};
    margin: 2.5px 0;
    padding: 0;
  `}
`;

export const TagAdapter = styled.div`
  margin-left: 10px;
  max-height: 16px;
`;

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.error.primary};
    font-size: ${theme.font.sizes.xxs};
    margin: 8px 0px 0px 0px;
  `}
`;

export const FixedBottomOption = styled.li`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.grey.ultralight};
    color: ${theme.palette.grey.dark};
    display: flex;
    font-size: ${theme.font.sizes.md};
    height: 40px;
    line-height: ${theme.font.sizes.lg};
    list-style: none;
    padding: 8px 9px 8px 16px;
    width: 100%;

    &:hover{
      background-color: ${theme.palette.secondary.primary};
      color: ${theme.color.white}
    }
  `}
`;

export const OptionsWrapper = styled.div`
  ${({ theme, show }) => css`
    box-shadow: -1px 3px 12px ${theme.shadows.grey.light};
    opacity: ${show ? '1' : '0'};
    position: absolute;
    right: -1px;
    top: calc(100% - 1px);
    transition: opacity 200ms ease-in-out;
    visibility: ${show ? 'visible' : 'hidden'};
    width: 100%;
    z-index: ${theme.layers.awaysOnTop};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 0px;
    outline: none;
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    overflow: hidden;
    padding: 0px;
    resize: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `}
`;
