import React from 'react';
import * as Style from './IconDetailedPersonalPortfoil.style';

const IconDetailedPersonalPortfoil = ({
  color = '#4a4a4a',
  backgroundColor = '#ffd54c',
  size = '30',
  testId = 'icon-detailed-personal-portfoil',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill={color}>
      <g id="Grupo_40229" data-name="Grupo 40229" transform="translate(-767 -593)">
        <g id="Group_7" data-name="Group 7" transform="translate(778.62 604.62)">
          <circle id="Oval" cx="9" cy="9" r="9" transform="translate(0.38 0.38)" fill={backgroundColor} />
        </g>
        <g id="person_black_24dp" transform="translate(769.251 594.501)">
          <path id="Caminho_247869" data-name="Caminho 247869" d="M0,0H25.515V25.515H0Z" fill="none" />
          <path id="Caminho_247870" data-name="Caminho 247870" d="M12.464,6.116a2.116,2.116,0,1,1-2.116,2.116,2.122,2.122,0,0,1,2.116-2.116m0,10.58c2.857,0,6.137,1.365,6.348,2.116H6.116c.243-.762,3.5-2.116,6.348-2.116m0-12.7A4.232,4.232,0,1,0,16.7,8.232,4.231,4.231,0,0,0,12.464,4Zm0,10.58C9.639,14.58,4,16,4,18.812v2.116H20.928V18.812C20.928,16,15.289,14.58,12.464,14.58Z" transform="translate(0.293 0.293)" />
        </g>
      </g>
    </svg>
  </Style.Wrapper>
);


export default IconDetailedPersonalPortfoil;
