import React from 'react';
import { Card, withStyles } from '@material-ui/core';
import { getClassNamesFollowingDomainStatus } from '@/utils/Application/getCssStyleNames';
import styles from './styles';
import classNames from 'classnames';


function DomainDetailCard({
  children,
  classes,
  domainStatus,
  className,
  isNextDueDate,
  isDueDate,
}) {
  return (
    <Card
      data-testid="card_details"
      elevation={1}
      className={classNames(classes.container,
        {
          [`${classes[getClassNamesFollowingDomainStatus(domainStatus)]} ${className}`]: !isNextDueDate,
          [`${classes[getClassNamesFollowingDomainStatus('NextDueDate')]} ${className}`]: isNextDueDate,
          [`${classes[getClassNamesFollowingDomainStatus('DueDate')]} ${className}`]: isDueDate,

        })}
    >
      {children}
    </Card>
  );
}


export default withStyles(styles, { withTheme: true })(DomainDetailCard);
