import { Typography, withStyles, withWidth } from '@material-ui/core';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { withI18n, Trans } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import IconStar from '@/media/icons/proemailPremium.svg';
import { SERVICE_TERMS } from '@/config/urls/supportUrls';
import * as actions from '@/redux/actions/emails';
import LinkButton from '@/components/Buttons/LinkButton';
import {
  reativateWithHostingPlanTagManager,
  cPanelWebmailTagManager,
  generateInvoiceTitanPremium,
} from '@/utils/ThirdParties/tagManager';
import { Button as ButtonV1 } from '@/pages/common/v1/Button';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierErrorLong } from '@/utils/Application/notifier';
import { ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN } from '@/redux/actions/actionsTypes';
import ModalConfirmFreeTitan from '@/components/Modal/ModalConfirmFreeTitan';
import getUsedAccounts from './GetUsedAccoutns';
import PriceBlock from './PriceBlock';
import HireBlock from './HireBlock';
import PlanFeatures from './PlanFeatures';
import IncrementAccounts from './IncrementAccounts';
import styles from './TitanUpgradePlanItem.styles';
import {
  BASIC_PLAN, ESSENTIALS_PLAN, PREMIUM_PLAN, ULTRA_PLAN,
} from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN } from '@/config/GrowthBook/constants';
import { Select, Tag } from 'gatorcomponents';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { emailsActions } from '@/redux/modules/emails';
import titanPremiumCycles from './TitanUpgradePlanItem.enum';

const TitanUpgradePlanItem = ({
  classes,
  t,
  plan,
  domainObject,
  cycle,
  idHosting,
  isHostingSelected,
  idProEmail,
  handleOpenModalTitanTrialColorful,
}) => {
  const proEmailCycleMethods = useSelector(state => state.emails.paymentMethods);
  const history = useHistory();

  const featured = [PREMIUM_PLAN].includes(plan.id);
  const isFreemium = plan.id === BASIC_PLAN;
  const [amount, setAmount] = useState(
    getUsedAccounts(domainObject.used_accounts),
  );

  const paymentMethodsOptions = proEmailCycleMethods.length && [...proEmailCycleMethods].map((item) => {
    const paymentMethodLabel = item[1].name === 'braspag_credit_1x' ? t('braspag_credit') : item[1].description;

    const newPaymentMethod = {
      label: paymentMethodLabel,
      value: item[1].name,
      id: item[1].name,
    };

    return newPaymentMethod;
  });

  const [premiumCyclesOptions, setPremiumCyclesOptions] = useState([]);
  const [premiumSelectedCycleLabel, setPremiumSelectedCycleLabel] = useState(t('proEmail.hirePlan.titanPremium.cycles.annually'));
  const [paymentMethodSelectedLabel, setPaymentMethodSelectedLabel] = useState(paymentMethodsOptions && paymentMethodsOptions[0].label);
  const [premiumSelectedCycleValue, setPremiumSelectedCycleValue] = useState(titanPremiumCycles.ANNUALLY);
  const [paymentMethodSelectedValue, setPaymentMethodSelectedValue] = useState(paymentMethodsOptions && paymentMethodsOptions[0].value);
  const [proEmailPremiumAccounts, setProEmailPremiumAccounts] = useState(1);

  const hasCPanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );
  const [typing, setTyping] = useState(false);

  let tagVariant = '';
  let productName = '';
  switch (plan.id) {
    case BASIC_PLAN:
      tagVariant = 'cancel';
      productName = 'titanBasic';
      break;
    case ESSENTIALS_PLAN:
      tagVariant = 'active';
      productName = 'titanEssentials';
      break;
    case PREMIUM_PLAN:
      tagVariant = 'pending';
      productName = 'titanPremium';
      break;
    case ULTRA_PLAN:
      tagVariant = 'active';
      productName = 'titanUltra';
      break;
    default:
      break;
  }

  const isTitanHirePlanActiveCpanel = window.location.pathname.includes(
    '/emails-list/titan-hire-plan-active-cpanel',
  );
  const alphaFlowToSellTitanTrialByModalInHirePlan = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_MODAL_IN_HIREPLAN);
  const shouldRenderNewTitanCards = alphaFlowToSellTitanTrialByModalInHirePlan && isTitanHirePlanActiveCpanel;


  const annuallyPrice = plan.configoptions && plan.configoptions.length && plan.configoptions[0].annually;
  const monthlyPrice = plan.configoptions && plan.configoptions.length && plan.configoptions[0].monthly;
  const selectedPremiumCyclePrice = plan.configoptions && plan.configoptions.length && plan.configoptions[0][premiumSelectedCycleValue];
  const dispatch = useDispatch();
  const [
    modalConfirmFreeTitanAvailable,
    setModalConfirmFreeTitanAvailable,
  ] = useState(false);

  const handlerReactivePlanFree = () => {
    if (hasCPanel) {
      cPanelWebmailTagManager(plan.id);
      setModalConfirmFreeTitanAvailable(true);
    } else {
      dispatch(
        actions.reactivateProEmailFreePlan(
          parseInt(idHosting, 10),
          domainObject.id,
        ),
      ).then((response) => {
        if (response.type !== ERROR_PUT_REACTIVATE_PRO_EMAIL_FREE_PLAN) {
          const eventAction = isHostingSelected
            ? 'Oferta de Planos Titan Pagos/Reativação de Plano Free Escolha Hosting'
            : 'Oferta de Planos Titan Pagos/Reativação de Plano Free S/ Escolha Hosting';

          reativateWithHostingPlanTagManager(plan.id, eventAction);

          history.push(t('routes.emailsList'));
        } else {
          dispatch(
            enqueueSnackbar(
              t('snackbar.error.is_not_possible_reactive_freemium_plan'),
              notifierErrorLong,
            ),
          );
        }
      });
    }
  };

  const getPerMonthPrice = (cyclePrice, cycle) => {
    switch (cycle) {
      case titanPremiumCycles.ANNUALLY:
        return cyclePrice / 12;
      case titanPremiumCycles.SEMIANNUALLY:
        return ((cyclePrice * 2) / 12);
      case titanPremiumCycles.QUARTERLY:
        return ((cyclePrice * 4) / 12);
      case titanPremiumCycles.MONTHLY:
        return cyclePrice;
      default:
        return monthlyPrice;
    }
  };

  const getEmailTotalPrice = (cyclePrice, numberOfAccounts) => {
    const price = parseFloat(cyclePrice) * parseInt(numberOfAccounts, 10);
    return formatCurrency(price.toString());
  };

  const getPremiumCycles = useCallback(() => {
    if (plan.cycles) {
      return (
        plan.cycles.map(cycle => ({
          label: t(`proEmail.hirePlan.titanPremium.cycles.${[cycle]}`),
          value: cycle,
        }))
      );
    }
  }, [plan.cycles, t]);

  const handleSelectCycleOption = (option) => {
    setPremiumSelectedCycleValue(option.value);
  };

  const handleSelectPaymentMethodOption = (option) => {
    setPaymentMethodSelectedValue(option.value);
  };

  useEffect(() => {
    setPremiumCyclesOptions(getPremiumCycles());
  }, [getPremiumCycles]);

  const handleBuyPlan = (buyInfo) => {
    dispatch(emailsActions.emails.buyTitanPremium.request(buyInfo));
    generateInvoiceTitanPremium();
  };

  return (
    <div className={classes.root}>
      <ModalConfirmFreeTitan
        onClose={() => setModalConfirmFreeTitanAvailable(false)}
        open={modalConfirmFreeTitanAvailable}
        plan={plan}
        idHosting={idHosting}
        isHostingSelected={isHostingSelected}
        domainObject={domainObject}
        idProEmail={idProEmail}
      />

      <div
        className={classnames(
          classes.cardItem,
          {
            [classes.featuredCard]: featured,
          },
          {
            [classes.newColorfulCard]: shouldRenderNewTitanCards
            && PREMIUM_PLAN === plan.id,
          },
          {
            [classes.cardItemNew]: shouldRenderNewTitanCards,
          },
        )}
      >

        <div className={classes.insetCardItem}>
          {featured && !shouldRenderNewTitanCards && (
          <div
            className={classnames(
              classes.featuredBlock,
            )}
          >
            <img
              src={IconStar}
              alt={t('sitelock.alt')}
              className={classes.logo}
            />
          </div>
          )}

          <div className={classes.divDescriptionsPlan}>

            {shouldRenderNewTitanCards && (
            <div className={classes.chipWrapper}>
              <Tag
                label={t(`proEmail.hirePlan.${productName}.topLabel`)}
                variant={tagVariant}
              />
            </div>
            )}

            {shouldRenderNewTitanCards ? (
              <Typography className={classnames(classes.titanNewTitle,
                {
                  [classes.boldContent]: productName !== 'titanBasic',
                })}
              >
                {t(`proEmail.hirePlan.${productName}.productName`)}
              </Typography>
            ) : (
              <Typography
                className={classnames(
                  classes.itemTitle,
                  classes.itemTitleOld,
                )}
              >
                {plan.name}
              </Typography>
            ) }

            {shouldRenderNewTitanCards
              ? (
                <Typography className={classnames(classes.titanGbTitle, {
                  [classes.boldContent]: productName === 'titanPremium',
                })}
                >
                  {t(`proEmail.hirePlan.${productName}.gb`)}
                </Typography>
              ) : (
                <PriceBlock
                  cycle={cycle}
                  annuallyPrice={annuallyPrice}
                  monthlyPrice={monthlyPrice}
                />
              )}

            {!shouldRenderNewTitanCards && (
              <Typography className={classes.perEmailLabel}>
                {!isFreemium
                  ? t('proEmail.hirePlan.perMailBoxLabel')
                  : t('proEmail.hirePlan.freeMailBoxLabel')}
              </Typography>
            )}

            {shouldRenderNewTitanCards && (
              <Typography className={classes.titanNewSubtitle}>
                {t(`proEmail.hirePlan.${productName}.storage`)}
              </Typography>
            )}

            {!isFreemium && !shouldRenderNewTitanCards && (
            <div className={classes.defaultDiv}>
              <IncrementAccounts
                label={t('proEmail.hirePlan.mailBoxes')}
                min={getUsedAccounts(domainObject.used_accounts)}
                max={plan.configoptions[0].max_value}
                amount={amount}
                setAmount={setAmount}
                domainObject={domainObject}
              />
            </div>
            )}

            {shouldRenderNewTitanCards && [PREMIUM_PLAN, ULTRA_PLAN].includes(plan.id) && (

              <>
                <div className={classes.defaultDiv}>
                  <Typography className={classes.monthlyPrice}>
                    {`${formatCurrency(getPerMonthPrice(selectedPremiumCyclePrice, premiumSelectedCycleValue))}${t(`proEmail.hirePlan.${productName}.perMonth`)}`}
                  </Typography>
                  <Typography className={classes.monthlyPriceEachEmail}>
                    {t(`proEmail.hirePlan.${productName}.monthlyPriceEachEmail`)}
                  </Typography>
                  <IncrementAccounts
                    label={t('proEmail.hirePlan.mailBoxes')}
                    min={plan.configoptions[0].min_value}
                    max={plan.configoptions[0].max_value}
                    amount={proEmailPremiumAccounts}
                    setAmount={setProEmailPremiumAccounts}
                    isAUpgrade={false}
                    setTyping={setTyping}
                  />
                </div>
                <div className={classes.selectWrapper}>
                  <Select
                    label={t('proEmail.hirePlan.titanPremium.cycleTitle')}
                    options={premiumCyclesOptions}
                    value={premiumSelectedCycleLabel}
                    setValue={setPremiumSelectedCycleLabel}
                    onClickSelect={handleSelectCycleOption}
                  />
                  <Select
                    label={t('proEmail.hirePlan.titanPremium.paymentMethodTitle')}
                    options={paymentMethodsOptions}
                    value={paymentMethodSelectedLabel}
                    setValue={setPaymentMethodSelectedLabel}
                    onClickSelect={handleSelectPaymentMethodOption}
                  />
                </div>
                <div className={classes.totalPriceWrapper}>
                  <Typography className={classes.totalPrice}>
                    <Trans
                      i18nKey={proEmailPremiumAccounts === 1 ? `proEmail.hirePlan.${productName}.totalPrice` : `proEmail.hirePlan.${productName}.totalPriceMoreThanOne`}
                      values={{
                        price: getEmailTotalPrice(selectedPremiumCyclePrice, proEmailPremiumAccounts),
                        cycle: premiumSelectedCycleLabel,
                        numberAccounts: proEmailPremiumAccounts,
                      }}
                    >
                      <>0</>
                      <span>1</span>
                      <>2</>
                      <span className={classes.cycleName}>3</span>
                      <>4</>
                      <span>5</span>
                      <>6</>
                    </Trans>
                  </Typography>
                </div>
                <div className={classes.premiumHireButtonWrapper}>
                  <ButtonV1
                    data-id="acquireButton"
                    data-testid="acquireButton"
                    variant="primary"
                    onClick={() => handleBuyPlan({
                      productId: plan.id,
                      productCycle: premiumSelectedCycleValue,
                      paymentMethod: paymentMethodSelectedValue,
                      quantity: proEmailPremiumAccounts,
                      domain: domainObject.domain,
                      isUltra: [ULTRA_PLAN].includes(plan.id),
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    label={t('proEmail.hirePlan.titanPremium.ctaButton')}
                  />
                </div>
              </>
            )}

            <div className={classes.defaultDiv}>
              {!isFreemium && (![PREMIUM_PLAN, ULTRA_PLAN].includes(plan.id) || !shouldRenderNewTitanCards) && (
                <HireBlock
                  amount={amount}
                  cycle={cycle}
                  isFreemium={isFreemium}
                  annuallyPrice={annuallyPrice}
                  monthlyPrice={monthlyPrice}
                  featured={false}
                  pid={plan.id}
                  domain={domainObject.domain}
                  hostingId={domainObject.hosting_id}
                  handleOpenModalTitanTrialColorful={handleOpenModalTitanTrialColorful}
                  typing={typing}
                />
              )}

              {isFreemium && shouldRenderNewTitanCards && (
                <div className={classes.hireButtonWrapper}>
                  <ButtonV1
                    data-id="acquireButton"
                    data-testid="acquireButton"
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handlerReactivePlanFree}
                    label={t(`proEmail.hirePlan.${productName}.ctaButton`)}
                  />
                </div>
              )}

              {(shouldRenderNewTitanCards) && (
                <>
                  <Typography className={classes.descriptionPlans}>
                    <Trans
                      i18nKey={`proEmail.hirePlan.${productName}.description`}
                      values={{ price: monthlyPrice }}
                    >
                      <>0</>
                      <strong>1 </strong>
                      <>2</>
                    </Trans>
                  </Typography>
                  <LinkButton
                    to={SERVICE_TERMS}
                    data-testid="link-terms"
                    className={[ESSENTIALS_PLAN, PREMIUM_PLAN, ULTRA_PLAN].includes(plan.id) ? classes.descriptionLink : classes.hideLink}
                    target="_blank"
                  >
                    {t(`proEmail.hirePlan.${productName}.link`)}
                  </LinkButton>
                </>
              )}
              <PlanFeatures
                plan={plan}
                className={classes.gridDescription}
                description={plan.description}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TitanUpgradePlanItem.propTypes = {
  plan: PropTypes.object.isRequired,
  domainObject: PropTypes.object.isRequired,
};

export default withI18n()(
  withWidth()(withStyles(styles)(TitanUpgradePlanItem)),
);
