import React, { useCallback, useState } from 'react';
import * as Styles from './ExternalMigration.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';

export const ExternalMigration = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const {
    externalMigration: externalMigrationLocale,
  } = ticketsLocale.forms;
  const [oldHostDomain, setOldHostDomain] = useState('');
  const [oldHostLogin, setOldHostLogin] = useState('');
  const [oldHostPassword, setOldHostPassword] = useState('');
  const [oldHostLinkAdmin, setOldHostLinkAdmin] = useState('');
  const [oldHostProEmail, setOldHostProEmail] = useState('');
  const [actualProEmail, setActualProEmail] = useState('');
  const [oldHostAdminPanel, setOldHostAdminPanel] = useState('');
  const [aditionalInfo, setAditionalInfo] = useState('');
  const [terms, setTerms] = useState(false);
  const [payment, setPayment] = useState(false);

  const checkForm = useCallback(() => {
    let isOk = false;

    if (
      oldHostDomain.length
      && oldHostLogin.length
      && oldHostPassword.length
      && oldHostLinkAdmin.length
      && oldHostProEmail.length
      && actualProEmail.length
      && oldHostAdminPanel.length
      && terms
      && payment
    ) {
      isOk = true;
    }

    return !isOk;
  }, [
    oldHostDomain,
    oldHostLogin,
    oldHostPassword,
    oldHostLinkAdmin,
    oldHostProEmail,
    actualProEmail,
    oldHostAdminPanel,
    terms,
    payment,
  ]);

  const hostOptions = [
    {
      label: 'WHM',
      value: 1,
    },
    {
      label: 'cPanel',
      value: 2,
    },
    {
      label: 'Plesk',
      value: 3,
    },
    {
      label: 'Outro',
      value: 4,
    },
    {
      label: externalMigrationLocale.dontKnow,
      value: 5,
    },
  ];

  const emailOptions = [
    {
      label: 'Titan',
      value: 1,
    },
    {
      label: 'cPanel',
      value: 2,
    },
    {
      label: 'Externo',
      value: 3,
    },
    {
      label: 'Outro',
      value: 4,
    },
    {
      label: externalMigrationLocale.dontKnow,
      value: 5,
    },
  ];

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.id,
      product: selectedProduct.name,
      subject: externalMigrationLocale.subject,
      key: V2Forms.EXTERNAL_MIGRATION,
      tags: [
        'whmcs_form_tecnico_migracao_compartilhada',
        selectedProduct.name,
        `${(selectedProduct.serverhostname || selectedProduct.dedicatedip) || ''}`,
      ],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.externalMigration,
      },
      body: `
      ${externalMigrationLocale.bodyProduct(selectedProduct)}
      Servidor: ${(selectedProduct.serverhostname || selectedProduct.dedicatedip) || ''}
      ${externalMigrationLocale.solicitation}
      ${externalMigrationLocale.bodyDomain(oldHostDomain)}
      ${externalMigrationLocale.bodyLogin(oldHostLogin)}
      ${externalMigrationLocale.bodyPassword(oldHostPassword)}
      ${externalMigrationLocale.bodyLink(oldHostLinkAdmin)}
      ${externalMigrationLocale.bodyPanel(oldHostAdminPanel)}
      ${externalMigrationLocale.bodyOldEmail(oldHostProEmail)}
      ${externalMigrationLocale.bodyNewEmail(actualProEmail)}
      ${externalMigrationLocale.bodyAditionalInfo(aditionalInfo)}
      ${externalMigrationLocale.bodyTerms(terms)}
      ${externalMigrationLocale.bodyPayment(payment)}
      ${externalMigrationLocale.bodyProductId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    submitForm,
    selectedProduct,
    oldHostDomain,
    oldHostLogin,
    oldHostPassword,
    oldHostLinkAdmin,
    oldHostProEmail,
    actualProEmail,
    oldHostAdminPanel,
    aditionalInfo,
    payment,
    terms,
    externalMigrationLocale,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {externalMigrationLocale.info}
      </Styles.Info>

      <Styles.Title>
        {externalMigrationLocale.title}
      </Styles.Title>

      <Styles.InputWrapper>
        <Input
          name="oldHostDomain"
          testId="oldHostDomain"
          label={oldHostDomain ? externalMigrationLocale.oldHostDomain : ''}
          placeholder={externalMigrationLocale.oldHostDomain}
          value={oldHostDomain}
          onChange={e => setOldHostDomain(e.target.value)}
        />

        <Input
          name="oldHostLogin"
          testId="oldHostLogin"
          label={oldHostLogin ? externalMigrationLocale.oldHostLogin : ''}
          placeholder={externalMigrationLocale.oldHostLogin}
          value={oldHostLogin}
          onChange={e => setOldHostLogin(e.target.value)}
        />

        <Input
          name="oldHostPassword"
          testId="oldHostPassword"
          label={oldHostPassword ? externalMigrationLocale.oldHostPassword : ''}
          placeholder={externalMigrationLocale.oldHostPassword}
          value={oldHostPassword}
          onChange={e => setOldHostPassword(e.target.value)}
        />

        <Input
          name="oldHostLinkAdmin"
          testId="oldHostLinkAdmin"
          label={oldHostLinkAdmin ? externalMigrationLocale.oldHostLinkAdmin : ''}
          placeholder={externalMigrationLocale.oldHostLinkAdmin}
          value={oldHostLinkAdmin}
          onChange={e => setOldHostLinkAdmin(e.target.value)}
        />

        <Select
          name="oldHostAdminPanel"
          testId="oldHostAdminPanel"
          label={oldHostAdminPanel ? externalMigrationLocale.oldHostAdminPanel : ''}
          placeholder={externalMigrationLocale.oldHostAdminPanel}
          options={hostOptions}
          setValue={label => setOldHostAdminPanel(label)}
          value={oldHostAdminPanel}
          withScroll
        />

        <Select
          name="oldHostProEmail"
          testId="oldHostProEmail"
          label={oldHostProEmail ? externalMigrationLocale.oldHostProEmail : ''}
          placeholder={externalMigrationLocale.oldHostProEmail}
          options={emailOptions}
          setValue={label => setOldHostProEmail(label)}
          value={oldHostProEmail}
          withScroll
        />

        <Select
          name="actualProEmail"
          testId="actualProEmail"
          label={actualProEmail ? externalMigrationLocale.actualProEmail : ''}
          placeholder={externalMigrationLocale.actualProEmail}
          options={emailOptions}
          setValue={label => setActualProEmail(label)}
          value={actualProEmail}
          withScroll
        />

        <Input
          name="aditionalInfo"
          testId="aditionalInfo"
          label={aditionalInfo ? externalMigrationLocale.aditionalInfo : ''}
          placeholder={externalMigrationLocale.aditionalInfo}
          value={aditionalInfo}
          onChange={e => setAditionalInfo(e.target.value)}
        />

        <Styles.CheckboxLabel
          htmlFor="#terms"
          data-testid="checkReadTerms"
          onClick={() => setTerms(!terms)}
        >
          <Checkbox checked={terms} id="terms" />
          <Styles.Text>
            {externalMigrationLocale.terms}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#payment"
          data-testid="checkPayment"
          onClick={() => setPayment(!payment)}
        >
          <Checkbox checked={payment} id="payment" />
          <Styles.Text>
            {externalMigrationLocale.payment}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : ticketsLocale.forms.submitLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default ExternalMigration;
