export const copyTableToClipboard = (arr, tableColumns, t) => {
  const fieldValueByName = item => ({
    priority: `${t('dns.zoneManager.addRecord.form.label.typeSrvPriorityField')}: ${item.priority}`,
    port: `${t('dns.zoneManager.addRecord.form.label.typeSrvPortField')}: ${item.port}`,
    weight: `${t('dns.zoneManager.addRecord.form.label.typeSrvWeightField')}: ${item.weight}`,
    target: `${t('dns.zoneManager.addRecord.form.label.typeSrvDestinationField')}: ${item.target}`,
    flag: `${t('dns.zoneManager.recordList.listLabel.flag')}: ${item.flag}`,
    tag: `${t('dns.zoneManager.recordList.listLabel.tag')}: ${item.tag}`,
    value: `${t('dns.zoneManager.recordList.listLabel.value')}: ${item.value}`,
    cname: `${item.cname}`,
    txt_data: `${item.txt_data}`,
    exchange: `${t('dns.zoneManager.addRecord.form.label.typeMxDestinationField')}: ${item.exchange}`,
    preference: `${t('dns.zoneManager.addRecord.form.label.typeMxPriorityField')}: ${item.preference}`,
    address: `${item.address}`,
  });

  const valuesOfEachDnsType = {
    A: ['address'],
    CAA: ['flag', 'tag', 'value'],
    CNAME: ['cname'],
    MX: ['exchange', 'preference'],
    TXT: ['txt_data'],
    SRV: ['priority', 'port', 'weight', 'target'],
  };

  const valueByDnsType = item => valuesOfEachDnsType[item.type].reduce((currentValue, field) => `${currentValue} ${fieldValueByName(item)[field]}`, '');

  const body = arr.map(item => `${item.type} ${item.name} ${item.class} ${item.ttl}${valueByDnsType(item)}`).join('\n')
    .toString()
    .replace('"', '')
    .replace('[', '')
    .replace(']', '');
  const columns = tableColumns.map(item => item).join('\t').toString();
  const resultBody = `${columns}\n${body}`;
  // navigator clipboard api needs a secure context (https)
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(resultBody);
  }
  // local development or older browsers
  const textArea = document.createElement('textarea');
  textArea.value = resultBody;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
};
