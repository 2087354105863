import React, { useContext, useEffect, useState } from 'react';
import {
  Card, InputAdornment, TextField, Typography, withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import withWidth, { } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EmailsContext } from '@/contexts/Emails/context';
import ModalFreeTitanActiveSuccess from '@/components/Modal/ModalFreeTitanActiveSuccess';
import ModalMoveCPanelSuccess from '@/components/Modal/ModalMoveCPanelSuccess';
import { queryParams } from '@/utils/Application/queryParams';
import { IconSearch } from '@/icons/iconSearch';
import styles from './styles';

const ProEmailTitle = ({ classes, t, displaySearch }) => {
  const {
    typpedTerm, handleTyppedTerm,
  } = useContext(EmailsContext);
  const dispatch = useDispatch();

  const location = useLocation();
  const isFreemiumActivate = queryParams(location.search, 'frActivate');
  const isCPanelActivate = queryParams(location.search, 'cpActivate');
  const domain = queryParams(location.search, 'domain');
  const [isTrialActivate, setTrialActivate] = useState(queryParams(location.search, 'trialSucess'));
  const [modalFreeTitanActiveSuccess, setModalFreeTitanActiveSuccess] = useState(isFreemiumActivate);
  const [modalMoveCPanelSuccess, setModalMoveCPanelSuccess] = useState(isCPanelActivate);


  useEffect(() => {
    if (isTrialActivate) {
      setTrialActivate(false);
    }
  }, [dispatch, isTrialActivate]);

  return (

    <div className={classnames(classes.cardsWrappers, !displaySearch ? classes.cardsWrappersWithoutSearch : '')}>

      <ModalFreeTitanActiveSuccess
        onClose={() => setModalFreeTitanActiveSuccess(false)}
        open={modalFreeTitanActiveSuccess}
        domain={domain}
      />

      <ModalMoveCPanelSuccess
        onClose={() => setModalMoveCPanelSuccess(false)}
        open={modalMoveCPanelSuccess}
        domain={domain}
      />

      <Card className={classes.card}>
        <Typography className={classes.pageTitle} data-testid="proEmailTitle">
          {t('lists.emails')}
        </Typography>
      </Card>
      {displaySearch && (
      <Card className={classnames(classes.card, classes.middleCard)}>

        <TextField
          className={classes.textField}
          name="typedTerm"
          variant="outlined"
          required
          id="filterBy"
          value={typpedTerm}
          onChange={e => handleTyppedTerm(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment className={classnames(classes.iconSearch)} position="start"><IconSearch /></InputAdornment>,
          }}
          placeholder={t('proEmail.searchDomainLabel')}
        />
      </Card>
      )}

    </div>
  );
};

export default withI18n()(withWidth()(withStyles(styles)(ProEmailTitle)));
