const styles = theme => ({
  icon: {
    fontSize: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
    },
  },
  st0: { fill: '#D38C0D' },
  st1: { fill: '#EFBB67' },
  st2: { fill: '#704A0E' },
  st3: { fill: '#513307' },
  st4: { fill: '#2D1C05' },
});

export default styles;
