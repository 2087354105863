const email = (value) => {
  // eslint-disable-next-line no-useless-escape
  const emailRule = /^[a-z0-9-\-\.\_\+]{1,64}@[a-z0-9-\-]{1,63}(\.[a-z0-9-]{1,61})+$/;
  return emailRule.test(value);
};

const hasLowerAndUper = (value) => {
  const hasLowerCaseRule = /(.*[a-z].*)/;
  const hasUppercaseRule = /(.*[A-Z].*)/;
  return hasLowerCaseRule.test(value) && hasUppercaseRule.test(value);
};

const hasNumber = (value) => {
  const hasNumberRule = /(.*[0-9].*)/;
  return hasNumberRule.test(value);
};

const hasSpecial = (value) => {
  // eslint-disable-next-line no-useless-escape
  const hasSpecialRule = /.*[$-/:-?{-~!"^_`\[\]@¢¬#£].*/;
  return hasSpecialRule.test(value);
};

const userWordpressHasNotSpecial = (value) => {
  const hasNotSpecialRule = /^[a-zA-Z0-9._-]+$/;
  return hasNotSpecialRule.test(value);
};

const validators = {
  email,
  hasLowerAndUper,
  hasNumber,
  hasSpecial,
  userWordpressHasNotSpecial,
};

export default validators;
