import React, { useCallback, useState } from 'react';
import * as Styles from './MoreEmails.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const MoreEmails = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const {
    moreEmails: moreEmailsLocale,
  } = ticketsLocale.forms;

  const [formData, setFormData] = useState({
    serverIp: '',
    motivation: '',
    newLimit: '',
    agreedService: false,
    agreedPolicy: false,
    agreedResponsability: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.serverIp
      && !!formData.motivation
      && !!formData.newLimit
      && !!formData.agreedService
      && !!formData.agreedPolicy
      && !!formData.agreedResponsability
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: moreEmailsLocale.subject,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.MORE_EMAILS,
      tags: [
        'whmcs_form_tecnico_envio-emails',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
        • ${moreEmailsLocale.body.product(selectedProduct)}
        • ${moreEmailsLocale.body.request}
        • ${moreEmailsLocale.body.serverIp(formData.serverIp)}
        • ${moreEmailsLocale.body.motivation(formData.motivation)}
        • ${moreEmailsLocale.body.newLimit(formData.newLimit)}
        • ${moreEmailsLocale.body.agreedService(formData.agreedService)}
        • ${moreEmailsLocale.body.agreedPolicy(formData.agreedPolicy)}
        • ${moreEmailsLocale.body.agreedResponsability(formData.agreedResponsability)}
        • ${moreEmailsLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    moreEmailsLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {moreEmailsLocale.info}
      </Styles.Info>

      <Styles.Title>
        {moreEmailsLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="serverIp"
          placeholder={moreEmailsLocale.placeholders.serverIp}
          label={formData.serverIp ? moreEmailsLocale.placeholders.serverIp : ''}
          value={formData.serverIp}
          onChange={e => handleChange({ key: 'serverIp', value: e.target.value })}
          type="text"
          testId="serverIp"
        />

        <Styles.InputWrapper>
          <Input
            name="motivation"
            placeholder={moreEmailsLocale.placeholders.motivation}
            label={formData.motivation ? moreEmailsLocale.placeholders.motivation : ''}
            value={formData.motivation}
            onChange={e => handleChange({ key: 'motivation', value: e.target.value })}
            type="text"
            testId="motivation"
          />
        </Styles.InputWrapper>

        <Styles.InputWrapper>
          <Input
            name="newLimit"
            placeholder={moreEmailsLocale.placeholders.newLimit}
            label={formData.newLimit ? moreEmailsLocale.placeholders.newLimit : ''}
            value={formData.newLimit}
            onChange={e => handleChange({ key: 'newLimit', value: e.target.value })}
            type="text"
            testId="newLimit"
          />
        </Styles.InputWrapper>
      </Styles.InputsWrapper>

      <Styles.InputsWrapper>
        <Styles.CheckboxLabel
          htmlFor="#readTerms"
          data-testid="checkReadTerms"
          onClick={() => handleChange({ key: 'agreedService', value: !formData.agreedService })}
        >
          <Checkbox checked={formData.agreedService} id="readTerms" />
          <Styles.Text>
            {moreEmailsLocale.placeholders.agreedService}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedPolicy"
          data-testid="checkReadTermsagreedPolicy"
          onClick={() => handleChange({ key: 'agreedPolicy', value: !formData.agreedPolicy })}
        >
          <Checkbox checked={formData.agreedPolicy} id="readTermsagreedPolicy" />
          <Styles.Text>
            {moreEmailsLocale.placeholders.agreedPolicy}
          </Styles.Text>
        </Styles.CheckboxLabel>

        <Styles.CheckboxLabel
          htmlFor="#readTermsagreedResponsability"
          data-testid="checkReadTermsagreedResponsability"
          onClick={() => handleChange({ key: 'agreedResponsability', value: !formData.agreedResponsability })}
        >
          <Checkbox checked={formData.agreedResponsability} id="readTermsagreedResponsability" />
          <Styles.Text>
            {moreEmailsLocale.placeholders.agreedResponsability}
          </Styles.Text>
        </Styles.CheckboxLabel>
      </Styles.InputsWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : moreEmailsLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default MoreEmails;
