const styles = theme => ({
  faq: {
    padding: '29px 0 17px 0',
  },
  contentDetails: {
    padding: '32px',
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0 0 0',
    },
  },
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
  },
  alertWrapper: {
    marginBottom: '16px',
    marginLeft: '-6px',
  },
});

export default styles;
