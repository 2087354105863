import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { DomainsActionTypes } from './domains.types';

export const initialState = {
  list: [],
  listTotal: 0,
  loading: false,
  loaded: false,
  detail: {
    id: '',
    loading: false,
    loadingRenewal: false,
    domain: '',
    registrationDate: '',
    firstPaymentAmount: '',
    paymentMethod: '',
    recurringAmount: '',
    registrar: '',
    registrationPeriod: 1,
    billingCycle: '',
    expiryDate: '',
    nextInvoiceDate: '',
    status: '',
    doNotRenew: 0,
    nextDueDate: '',
    actions: [],
  },
  domains: {
    generateNewInvoice: {
      loading: false,
    },
  },
  propagationStatus: [],
  domainsElegible: [],
  domainConfiguredStatus: {
    id: 0,
    domain: '',
    status: '',
    loaded: false,
  },
  eppKey: {
    activeDomainsList: [],
    modalIsOpen: false,
    domainId: 0,
    domainName: '',
    keyGenerated: null,
    redirect: false,
  },
};

const domainsReducer = (state = initialState, action) => {
  let newList = [];
  switch (action.type) {
    case DomainsActionTypes.SET_DOMAINS: {
      newList = [];

      if (action.payload.page && action.payload.page > 1) {
        newList.push(...state.list);
      }

      if (action.payload.domains) {
        newList.push(...action.payload.domains);
      } else {
        newList.push(...action.payload);
      }

      return {
        ...state,
        list: newList,
        listTotal: action.payload.total,
        loading: false,
        loaded: true,
      };
    }
    case DomainsActionTypes.GET_DOMAINS_FAILURE:
    case DomainsActionTypes.GET_DOMAINS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DomainsActionTypes.GET_DOMAINS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DomainsActionTypes.SET_DOMAIN_DETAIL: {
      return {
        ...state,
        detail: {
          ...action.payload,
          loading: false,
        },
      };
    }
    case DomainsActionTypes.GET_DOMAIN_DETAIL_FAILURE:
    case DomainsActionTypes.GET_DOMAIN_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: false,
        },
      };
    }
    case DomainsActionTypes.GET_DOMAIN_DETAIL_REQUEST: {
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    }
    case DomainsActionTypes.SET_DOMAIN_PROPAGATION_STATUS: {
      const allStatus = action.payload;
      return {
        ...state,
        propagationStatus: allStatus,
      };
    }
    case DomainsActionTypes.SET_CONFIGURED_DOMAIN_STATUS: {
      const { id, domain, status } = action.payload;
      return {
        ...state,
        domainConfiguredStatus: {
          ...state.domainConfiguredStatus,
          id,
          domain,
          status,
        },
      };
    }
    case DomainsActionTypes.SET_EMPTY_DOMAIN_STATUS: {
      return {
        ...state,
        domainConfiguredStatus: {
          ...state.domainConfiguredStatus,
          status: PROPAGATION_STATUS.OUT_OF_RANGE,
          loaded: true,
        },
      };
    }
    case DomainsActionTypes.SET_RESET_DOMAIN_STATUS: {
      return {
        ...state,
        domainConfiguredStatus: {
          ...initialState.domainConfiguredStatus,
        },
      };
    }
    case DomainsActionTypes.SET_LOADED_CONFIGURED_DOMAIN_STATUS: {
      return {
        ...state,
        domainConfiguredStatus: {
          ...state.domainConfiguredStatus,
          loaded: true,
        },
      };
    }
    case DomainsActionTypes.DOMAIN_GENERATE_NEW_INVOICE_LOADING: {
      return {
        ...state,
        domains: {
          ...state.domains,
          generateNewInvoice: {
            ...state.domains.generateNewInvoice,
            loading: action.value,
          },
        },
      };
    }
    case DomainsActionTypes.SET_DOMAIN_EPP_REDIRECT: {
      return {
        ...state,
        eppKey: {
          ...state.eppKey,
          redirect: action.payload,
        },
      };
    }
    case DomainsActionTypes.SET_MODAL_EPP_KEY_IS_OPEN: {
      return {
        ...state,
        eppKey: {
          ...state.eppKey,
          modalIsOpen: true,
          domainId: action.payload.id,
          domainName: action.payload.name,
        },
      };
    }
    case DomainsActionTypes.SET_MODAL_EPP_KEY_IS_CLOSED: {
      return {
        ...state,
        eppKey: {
          ...initialState.eppKey,
        },
      };
    }
    case DomainsActionTypes.SET_MODAL_EPP_KEY_IS_CHANGED: {
      return {
        ...state,
        eppKey: {
          ...state.eppKey,
          domainId: action.payload.id,
          domainName: action.payload.name,
        },
      };
    }
    case DomainsActionTypes.SET_EPP_KEY_GENERATED: {
      return {
        ...state,
        eppKey: {
          ...state.eppKey,
          keyGenerated: action.payload,
        },
      };
    }
    case DomainsActionTypes.SET_ALL_DOMAINS_LIST: {
      return {
        ...state,
        eppKey: {
          ...state.eppKey,
          activeDomainsList: action.payload,
        },
      };
    }
    case DomainsActionTypes.SET_DOMAINS_ELEGIBLE: {
      return {
        ...state,
        domainsElegible: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default domainsReducer;
