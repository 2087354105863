import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Link, useParams, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { countBy, findIndex, propEq } from 'ramda';
import LinkButton from '@/components/Buttons/LinkButton';
import ModalRemoveSite from '@/components/Modal/ModalRemoveSite';
import Loader from '@/components/Layout/Loader';
import { toggleAccessPasswordModal } from '@/redux/actions/productDetail';
import { getSiteBuilderSso, removeSiteFromSiteBuilder } from '@/redux/actions/siteBuilder';
import {
  SUCCESS_DELETE_SITE_FROM_SITEBUILDER, SUCCESS_SITEBUILDER_SSO,
} from '@/redux/actions/actionsTypes';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { notifierSuccess, notifierErrorLong } from '@/utils/Application/notifier';
import { activateWebsiteCreationFlow } from '@/redux/actions/onboarding';
import { updateSiteBuilderStatusOnMySitesPage, removeProductGroup } from '@/redux/actions/productGroups';
import styles from './styles';
import { brSitebuilderProducts, esSitebuilderProducts } from '@/enums/products/products.enum';
import { isBRServer } from '@/utils/Validators/validation';
import { productDetailActionsLocale } from './ProductDetailActions.locale';
import { IconLoading, Tag } from 'gatorcomponents';
import { productsActions } from '@/redux/modules';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_CPANEL_SSO_PORTAL, FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE, CHARLIE_CLOUDFLARE } from '@/config/GrowthBook/constants';
import useLocale from '@/hooks/useLocale';

const ProductDetailActions = ({
  t,
  classes,
  disabledActions,
  product,
  arrayOldResellerId,
  featureOldResellerNotification,
  cloudflareAvailableProducts,
  products,
}) => {
  const { id: productId } = useParams();
  const {
    domain,
    siteBuilderMainId,
    productname,
    parentId,
    productType,
    id: hostingId,
    aditionalDomain,
  } = useSelector(state => state.productDetail.product && state.productDetail.product);
  const actions = useSelector(state => state.productDetail.product && state.productDetail.product.actions);
  const loadingSitebuilderProductDetail = useSelector(state => state.productDetail.loadingSitebuilderProductDetail);
  const { loadingSiteBuilderSso, loadingStatusSiteBuilderForActions } = useSelector(state => state.siteBuilder);
  const featureToggleSiteBuilderActivate = useSelector(state => state.featureToggles.toggles.portal
    && state.featureToggles.toggles.portal.websitebuilderTraditional);
  const loadingCPanelSSO = useSelector(state => state.products.cPanelLoading);
  const [redirectToProductDetail, setRedirectToProductDetail] = useState(false);
  const [showModalRemoveSite, setShowModalRemoveSite] = useState(false);
  const [redirectToMySitesPage, setRedirectToMySitesPage] = useState(false);
  const isStandalone = productType === 'standalone';
  const loading = loadingStatusSiteBuilderForActions || loadingSitebuilderProductDetail;
  const enabledEcommercePlusCycleChange = useFeatureIsOn(FOXTROT_ECOMMERCE_PLUS_CYCLE_CHANGE);
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);
  const enableCloudflare = useFeatureIsOn(CHARLIE_CLOUDFLARE);
  const { sites: sitesLocale } = useLocale();

  const dispatch = useDispatch();
  const handleSiteBuilder = (removeSite) => {
    if (loadingSiteBuilderSso) {
      return;
    }

    if (removeSite) {
      setShowModalRemoveSite(true);
      return;
    }

    dispatch(getSiteBuilderSso(domain)).then((response) => {
      if (response.type === SUCCESS_SITEBUILDER_SSO) {
        window.location.replace(response.data.sso);
      }
      setRedirectToProductDetail(siteBuilderMainId);
    });
  };

  const setUrlLink = link => (disabledActions ? '#' : link);

  const handleLinkUrl = (link, featureToggle) => (featureToggle ? '#' : link);

  const sortActions = () => {
    const defaultActions = [...actions];
    const webpresenceIndex = defaultActions.findIndex(item => item.type === 'webpresence');
    const productIsCloudflareAvailable = cloudflareAvailableProducts.includes(product.id);

    if (webpresenceIndex > -1) {
      defaultActions.splice(webpresenceIndex, 1);
    }

    const siteBuilderIdList = isBRServer ? brSitebuilderProducts : esSitebuilderProducts;

    if (siteBuilderIdList.includes(product.packageid)) {
      defaultActions.unshift({
        type: 'sitebuilder',
        status: 'active',
      });
    }

    const setupMail = {
      type: 'setup-mail',
      emailType: product.setupMail,
      linkUrl: '_self',
      status: 'active',
    };
    const setupMailIndex = findIndex(propEq('type', 'setup-mail'), defaultActions);

    if (setupMailIndex === -1) {
      if (product.setupMail) {
        defaultActions.push(setupMail);
      }
    }

    const ecommercePlusAction = {
      type: 'ecommerce-plus-cycle-change',
      status: 'active',
      linkUrl: '', // TODO: add path
    };
    const ecommercePlusActionIndex = findIndex(propEq('type', ecommercePlusAction.type), defaultActions);
    const hasEcommercePlus = product.ecommercePlus && product.ecommercePlus.status === 'Active';

    if (ecommercePlusActionIndex === -1 && hasEcommercePlus && enabledEcommercePlusCycleChange) {
      defaultActions.push(ecommercePlusAction);
    }

    if (enableCloudflare && productIsCloudflareAvailable) {
      defaultActions.push({
        type: 'cloudflare',
        status: 'active',
        linkUrl: `${t('routes.products')}${t('routes.cloudflare')}/${product.id}`,
      });
    }

    return defaultActions;
  };

  const getDomain = product => product.domain.domain || product.domain;

  const goToCPanel = (defaultCPanelUrl) => {
    dispatch(productsActions.cPanelSSO.requestLink({ hostingId: productId, defaultCPanelUrl, enableCpanelSSO }));
  };

  const generateLink = (action) => {
    const buttonText = t(`actions.${action.type}.active`);
    const featureToggleDisabled = action.status === 'inactive' && !featureToggleSiteBuilderActivate;
    const inactiveButton = action.status === 'inactive' || disabledActions;
    let isOldRseller = false;

    if (arrayOldResellerId) {
      isOldRseller = arrayOldResellerId.find(packageid => product.packageid === packageid);
    }

    const showOldResellerLabel = isOldRseller && featureOldResellerNotification && action.type === 'plan-switch';

    const multipleDomains = countBy(propEq('hostname', product.serverHostname), products);
    const settedDomain = domain || product.domain;
    const titanMailRedirectUrl = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${product.serverHostname}` : `${t('routes.emailsList')}${t('routes.emailsTitan')}/${settedDomain}`;
    const cpanelMailRedirectUrl = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${product.serverHostname}` : `${t('routes.filteredEmails')}/${settedDomain}`;

    let link = product.setupMail === 'proemail' ? titanMailRedirectUrl : cpanelMailRedirectUrl;

    if (!product.configuredMail) {
      link = multipleDomains.true > 1 ? `${t('routes.emailsList')}${t('routes.byHost')}/${product.serverHostname}` : `${t('routes.emailsList')}${t('routes.titanHirePlanActiveCPanel')}/${getDomain(product)}?idPro=${product.proemailId}`;
    }

    if (product.domainstatus === 'Pending' || product.domainstatus === 'Suspended' || product.domainstatus === 'Cancelled') {
      link = t('routes.emailsList');
    }

    switch (action.type) {
      case 'sitebuilder':
        return (
          <LinkButton
            dataId="action-cpanel-active"
            key="1000"
            to={`${productDetailActionsLocale.routeSitesPages}${productDetailActionsLocale.routesNewSite}`}
            className={classnames(classes.cardLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {productDetailActionsLocale.createSiteButton}
          </LinkButton>
        );
      case 'dataAccess':
        return (
          <button
            data-id="action-dataAccess-active"
            type="button"
            key={action.uuid}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
            onClick={() => dispatch(toggleAccessPasswordModal())}
            disabled={disabledActions}
          >
            {t(`actions.${action.type}.active`)}
          </button>
        );
      case 'whm':
        return (
          <Link
            dataId="action-whm-active"
            key={action.uuid}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
            to={`${t('routes.whmRedirectAccess')}/${productId}`}
            target="_blank"
          >
            {buttonText}
          </Link>
        );
      case 'websitebuilder':
        return (
          <button
            data-id={`action-websitebuilder-${action.removeSite ? 'remove' : action.status}`}
            key={action.uuid}
            type="button"
            onClick={() => handleSiteBuilder(action.removeSite)}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
          >
            {loadingSiteBuilderSso && (
              <Loader classesToProps={classes.loader} size={18} />
            )}
            {t(`actions.websitebuilder.${action.removeSite ? 'remove' : action.status}`)}
          </button>
        );
      case 'websitebuilder-on-shared-plans':
        return (
          <Link
            dataid="action-websitebuilder-on-shared-plans-active"
            key={action.uuid}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
            to={`${t('routes.siteBuilderRedirectAccess')}/${domain}`}
            target="_blank"
          >
            {buttonText}
          </Link>
        );
      case 'websitebuilder-active-edit':
        return (
          <Link
            key={action.uuid}
            data-id="action-edit-site"
            to={handleLinkUrl(action.linkUrl, featureToggleDisabled)}
            target={featureToggleDisabled ? '_self' : '_blank'}
            onClick={featureToggleDisabled ? () => dispatch(activateWebsiteCreationFlow(hostingId, domain)) : null}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
          >
            {t(`actions.websitebuilder-active-edit.${action.status}`)}
          </Link>
        );
      case 'cpanel':
        return (
          <LinkButton
            dataId="action-cpanel-active"
            key={action.uuid}
            to={productId}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
            target="_blank"
            onClick={disabledActions ? null : () => goToCPanel(action.linkUrl)}
            rel="noopener noreferrer"
          >
            {loadingCPanelSSO ? <IconLoading /> : buttonText}
          </LinkButton>
        );
      case 'setup-mail':
        return (
          <Link
            dataId="action-setup-mail"
            key={action.uuid}
            to={link}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
            rel="noopener noreferrer"
          >
            {t('proEmail.chipLinkLabel')}
          </Link>
        );
      case 'manage-site':
        return (
          <button
            type="button"
            key={action.uuid}
            onClick={() => setRedirectToProductDetail(action.relid)}
            data-id="manage-site"
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
          >
            {buttonText}
          </button>
        );
      case 'cloudflare':
        return (
          <LinkButton
            dataId={`action-${action.type}-${action.status}`}
            key={action.uuid}
            to={setUrlLink(action.linkUrl)}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
              [classes.cardWithTag]: true,
            })}
          >
            {buttonText}
            <Tag label={sitesLocale.page.tagNew} size="sm" />
          </LinkButton>
        );
      default:
        return (
          <LinkButton
            dataId={`action-${action.type}-${action.status}`}
            key={action.uuid}
            to={setUrlLink(action.linkUrl)}
            className={classnames(classes.cardLink, {
              [classes.cardLinkDisabled]: inactiveButton,
            })}
          >
            {showOldResellerLabel
              ? t(`actions.old-reseller-plan-switch.${action.status}`)
              : t(`actions.${action.type}.${action.status}`)
            }
          </LinkButton>
        );
    }
  };

  const onRemoveSiteBuilder = async () => {
    const data = {
      hosting_id: productId,
      domain,
      product: 'shop',
    };

    const response = await dispatch(removeSiteFromSiteBuilder(data));
    if (response.type === SUCCESS_DELETE_SITE_FROM_SITEBUILDER) {
      dispatch(enqueueSnackbar(t('snackbar.siteBuilder.successRemoveSite'), notifierSuccess));
      const updateProductId = isStandalone ? productId : parentId;
      dispatch(updateSiteBuilderStatusOnMySitesPage(updateProductId, false));
      if (aditionalDomain) {
        dispatch(removeProductGroup(productId));
      }
      setRedirectToMySitesPage(true);
    } else {
      dispatch(enqueueSnackbar(t('snackbar.siteBuilder.errorRemoveSite'), notifierErrorLong));
      setShowModalRemoveSite(false);
    }
  };

  if (redirectToProductDetail) {
    return <Redirect to={`${t('routes.products')}${t('routes.productdetail')}/${redirectToProductDetail}`} />;
  }

  if (redirectToMySitesPage) {
    return <Redirect to={`${t('routes.sitesPage')}`} />;
  }

  return (
    <>
      {loading || products.length === 0 ? (
        <Loader classesToProps={classes.loaderActions} size={18} />
      ) : sortActions().map(action => (
        <div className={classes.wrapperActions} key={`action-${action.type}`}>
          {generateLink(action)}
        </div>
      ))}

      {showModalRemoveSite && (
        <ModalRemoveSite
          open={showModalRemoveSite}
          onClose={() => setShowModalRemoveSite(false)}
          productName={productname}
          onRemove={onRemoveSiteBuilder}
        />
      )}
    </>
  );
};

export default withI18n()(withStyles(styles)(ProductDetailActions));
