const styles = theme => ({
  button: {
    color: theme.color.white,
    position: 'absolute',
    right: 10,
    top: 'calc(50% - 18px)',
  },
});

export default styles;
