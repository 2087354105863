import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './SitelockDetailsHandler.styles';
import {
  SITELOCK_COLORS, SITELOCK_LANGUAGE, SITELOCK_SIZE, SITELOCK_TYPE, TAB_TYPES,
} from './SitelockDetailsHandler.types';
import {
  Button, IconLoading, Skeleton, Tabs,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { ThreatsDetector } from './components/ThreatsDetector';
import { LastScans } from './components/LastScans';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { useDispatch, useSelector } from 'react-redux';
import { SUPPORT_SECURITY_URL } from '@/config/urls/domainsUrls';
import { loadFaqSecurity } from '@/redux/actions/faq';
import { useHistory, useParams } from 'react-router';
import { preChatActions, securityActions } from '@/redux/modules';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_TOKEN_REFRESH } from '@/config/GrowthBook/constants';
import { SecuritySeal } from './components/SecuritySeal';
import { FTP_ACCESS, REMOVE_MALICIOUS_CODE } from '@/config/urls/sitelock';
import { SITELOCK_PLANS } from '@/enums/products/products.enum';
import { copyTextToClipboard } from '@/utils/Application/copyTextToClipboard';
import { Configurations } from './components/Configurations';
import { sitelockAnalytics } from '@/analytics/security/sitelock';

const SitelockDetailsHandler = () => {
  const [activeTab, setActiveTab] = useState(TAB_TYPES.THREATS_DETECTOR);
  const [date, setDate] = useState(Date.now());
  const waitTokenToggle = useFeatureIsOn(CHARLIE_TOKEN_REFRESH);
  const { security: securityLocale, routes: routesLocale } = useLocale();
  const sitelockLocale = securityLocale.detailsPage;
  const threatsLocale = securityLocale.detailsPage.tabs.threats_detector;
  const dispatch = useDispatch();
  const params = useParams();
  const { productType, productId } = params;
  const history = useHistory();
  const faq = useSelector(state => state.faq);
  const { productsList } = useSelector(state => state.preChat);
  const securityState = useSelector(state => state.security);
  const { security_seal: securitySealLocale, configurations: configurationsLocale } = sitelockLocale.tabs;
  const [formData, setFormData] = useState({
    color: 'white',
    language: 'br',
    size: 'medium',
    type: 'mal',
  });
  const [configurationFormData, setConfigurationFormData] = useState({
    url: { value: '', error: '', touched: false },
    user: { value: '', error: '', touched: false },
    password: { value: '', error: '', touched: false },
  });

  const hostingsExists = !!(productsList && productsList.hostings);
  const sitelockProduct = hostingsExists && productsList.hostings.find(sitelockProduct => productId === sitelockProduct.id.toString());
  const isSitelockFree = sitelockProduct && sitelockProduct.name && sitelockProduct.name === SITELOCK_PLANS.FREE;

  const handleChangeTabs = (tabType) => {
    setActiveTab(tabType);
    sitelockAnalytics.newSitelockPage.gaTabsChange(sitelockLocale.tabs[tabType.toLowerCase()].title);
  };

  const handleSignSitelock = useCallback(() => {
    const url = `${routesLocale.security}${routesLocale.upgrade}/${productType}/${productId}`;
    history.push(url);
  }, [history, routesLocale, productType, productId]);

  const handleClickSitelockPanel = () => {
    if (!securityState.loadingSSO) {
      dispatch(securityActions.sso.request({ hostingId: productId }));
    }
  };

  const getTabsProps = useCallback(() => {
    const options = [
      {
        label: isSitelockFree ? sitelockLocale.tabs.threats_detector.title : sitelockLocale.tabs.threats_detector.paidTitle,
        id: TAB_TYPES.THREATS_DETECTOR,
        onClick: () => handleChangeTabs(TAB_TYPES.THREATS_DETECTOR),
      },
      {
        label: sitelockLocale.tabs.security_seal.title,
        id: TAB_TYPES.SECURITY_SEAL,
        onClick: () => handleChangeTabs(TAB_TYPES.SECURITY_SEAL),
      },
    ];

    if (!isSitelockFree) {
      options.splice(1, 0, {
        label: sitelockLocale.tabs.last_scans.title,
        id: TAB_TYPES.LAST_SCANS,
        onClick: () => handleChangeTabs(TAB_TYPES.LAST_SCANS),
      });

      options.push({
        label: sitelockLocale.tabs.configurations.title,
        id: TAB_TYPES.CONFIGURATIONS,
        onClick: () => handleChangeTabs(TAB_TYPES.CONFIGURATIONS),
      });
    }

    return ({
      options,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSitelockFree]);

  const getFaqProps = useCallback(() => ({
    buttonLabel: sitelockLocale.faqButton,
    questions: faq.security.questions,
    link: SUPPORT_SECURITY_URL,
    loading: faq.loading,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [faq.security]);

  const getProductsByStatus = useCallback(() => {
    dispatch(preChatActions.productByStatus.request({ withCancelled: true, withPending: true, waitToken: waitTokenToggle }));
  }, [dispatch, waitTokenToggle]);

  const upgradeBannerProps = useCallback(() => ({
    bannerLocale: threatsLocale.signSitelockCard,
    handleSignSitelock,
  }), [handleSignSitelock, threatsLocale]);

  const threatsDetectorProps = useCallback(() => ({
    isFree: isSitelockFree,
    productName: sitelockProduct && sitelockProduct.name,
    scanDetails: securityState.scanDetails,
    handleSignSitelock,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [sitelockProduct, securityState.scanDetails]);

  const lastScansProps = useCallback(() => ({
    scanHistory: securityState.scanHistory,
    handleSignSitelock,
    seeHowToFix: () => window.open(REMOVE_MALICIOUS_CODE, '_blank'),
    isLoading: securityState.scanHistoryLoading,
    isFree: isSitelockFree,
    handleGoToFTP: () => setActiveTab(TAB_TYPES.CONFIGURATIONS),
    hasFTPConfigurations: !!securityState.configurations.data.user,
  }), [securityState.scanHistory, handleSignSitelock, securityState.scanHistoryLoading, isSitelockFree, securityState.configurations.data.user]);

  const getSecuritySealProps = (() => {
    const defaultProps = {
      isFree: isSitelockFree,
      domain: sitelockProduct && sitelockProduct.domain,
      formData,
      setFormData,
      date,
      loading: securityState.seal.loading,
      saving: securityState.seal.saving,
      sealRequested: securityState.seal.requested,
      productName: sitelockProduct && sitelockProduct.name,
      upgradeBannerProps,
      copyTextToClipboard,
    };

    const colorOptions = SITELOCK_COLORS.map(item => ({
      label: securitySealLocale.colors[item],
      value: item,
    }));

    const sizeOptions = SITELOCK_SIZE.map(item => ({
      label: securitySealLocale.sizes[item],
      value: item,
    }));

    const typeOptions = SITELOCK_TYPE.map(item => ({
      label: securitySealLocale.type[item],
      value: item,
    }));

    const languageOptions = SITELOCK_LANGUAGE.map(item => ({
      label: securitySealLocale.language[item],
      value: item,
    }));

    const handleSave = (e) => {
      e.preventDefault();
      dispatch(securityActions.seal.updateSeal({
        productType,
        productId,
        formData,
        setDate,
        successMessage: securitySealLocale.successfullUpdate,
        failureMessage: securitySealLocale.failedfullUpdate,
      }));
    };

    return {
      ...defaultProps,
      colorOptions,
      sizeOptions,
      typeOptions,
      languageOptions,
      handleSave,
    };
  });

  const getConfigurationsProps = (() => {
    const handleHelpClick = () => {
      window.open(FTP_ACCESS, '_blank');
    };

    const defaultProps = {
      isFree: isSitelockFree,
      formData: configurationFormData,
      setFormData: setConfigurationFormData,
      isLoading: securityState.configurations.updateLoading,
      showSkeleton: securityState.configurations.getLoading,
      handleHelpClick,
    };

    const handleSave = (e) => {
      e.preventDefault();

      const dataToSave = {
        user: configurationFormData.user.value,
        password: configurationFormData.password.value,
        url: configurationFormData.url.value,
        domain: sitelockProduct && sitelockProduct.domain,
      };

      dispatch(securityActions.configurations.update.request({
        productType,
        productId,
        data: dataToSave,
        successMessage: configurationsLocale.successfullUpdate,
        failureMessage: configurationsLocale.failedfullUpdate,
      }));
    };

    return {
      ...defaultProps,
      handleSave,
    };
  });

  const tabs = {
    [TAB_TYPES.THREATS_DETECTOR]: <ThreatsDetector {...threatsDetectorProps()} />,
    [TAB_TYPES.LAST_SCANS]: <LastScans {...lastScansProps()} />,
    [TAB_TYPES.SECURITY_SEAL]: <SecuritySeal {...getSecuritySealProps()} />,
    [TAB_TYPES.CONFIGURATIONS]: <Configurations {...getConfigurationsProps()} />,
  };

  const initialDispatchs = useCallback(() => {
    dispatch(loadFaqSecurity());
    getProductsByStatus();
    dispatch(securityActions.scanDetails.request({ productType, productId }));
    dispatch(securityActions.configurations.get.request({ productType, productId }));
  }, [dispatch, getProductsByStatus, productType, productId]);

  useEffect(() => {
    initialDispatchs();
  }, [initialDispatchs]);

  const requestSealStatus = useCallback(() => {
    if (productType && productId) {
      dispatch(securityActions.seal.requestSeal({ productType, productId }));
    }
  }, [dispatch, productType, productId]);

  useEffect(() => {
    requestSealStatus();
  }, [requestSealStatus]);

  const setDefaultSealStatus = useCallback(() => {
    if (securityState.seal.requested) {
      setFormData({
        ...securityState.seal.data,
        color: {
          label: securitySealLocale.colors[securityState.seal.data.color],
          value: securityState.seal.data.color,
        },
        language: {
          label: securitySealLocale.language[securityState.seal.data.language],
          value: securityState.seal.data.language,
        },
        size: {
          label: securitySealLocale.sizes[securityState.seal.data.size],
          value: securityState.seal.data.size,
        },
        type: {
          label: securitySealLocale.type[securityState.seal.data.type],
          value: securityState.seal.data.type,
        },
      });
    }
  }, [securityState.seal.requested, securityState.seal.data, securitySealLocale]);

  useEffect(() => {
    setDefaultSealStatus();
  }, [setDefaultSealStatus]);

  const setConfigurationsData = useCallback(() => {
    const configurationData = securityState.configurations.data;
    setConfigurationFormData({
      url: { value: configurationData.url, error: '' },
      user: { value: configurationData.user, error: '' },
      password: { value: configurationData.password, error: '' },
    });
  }, [securityState.configurations.data]);

  useEffect(() => {
    setConfigurationsData();
  }, [setConfigurationsData]);

  const changeActiveTab = useCallback(() => {
    if (activeTab === TAB_TYPES.LAST_SCANS && !securityState.scanHistory.length) {
      dispatch(securityActions.scanList.request({ productType, productId }));
    }
    if (activeTab === TAB_TYPES.CONFIGURATIONS && !securityState.configurations.data.user) {
      dispatch(securityActions.configurations.get.request({ productType, productId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, params, dispatch]);

  const gaEventsDispatches = useCallback(() => {
    if (isSitelockFree) {
      sitelockAnalytics.newSitelockPage.gaFreeSitelockAccess();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    gaEventsDispatches();
  }, [gaEventsDispatches]);

  useEffect(() => {
    changeActiveTab();
  }, [changeActiveTab]);

  return (
    <Styles.SitelockDetailsWrapper>
      <Styles.HeadSection>
        {sitelockProduct ? (
          <>
            <Styles.ProductName>
              {`${sitelockProduct.name}`}
            </Styles.ProductName>
            <Styles.ProductDomain>
              {` - ${sitelockProduct.domain}`}
            </Styles.ProductDomain>
            {!isSitelockFree && (
            <Styles.ButtonWrapper>
              <Button
                label={
                securityState.loadingSSO
                  ? <Styles.ButtonLoadingWrapper><IconLoading /></Styles.ButtonLoadingWrapper>
                  : sitelockLocale.sitelockPanelButton
              }
                variant="secondary"
                onClick={handleClickSitelockPanel}
              />
            </Styles.ButtonWrapper>
            )}
          </>
        )
          : <Skeleton variant="headline" />
        }

      </Styles.HeadSection>

      <Styles.Section>
        <Styles.TabsWrapper>
          <Tabs {...getTabsProps()} currentActiveTab={activeTab} />
          <Styles.TabContainer>
            {tabs[activeTab]}
          </Styles.TabContainer>
        </Styles.TabsWrapper>
      </Styles.Section>

      <Styles.FaqSection>
        <FaqDefault {...getFaqProps()} width="full" />
      </Styles.FaqSection>
    </Styles.SitelockDetailsWrapper>
  );
};

export default SitelockDetailsHandler;
