const styles = theme => ({
  firstCardWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeft: `5px solid ${theme.color.indigo}`,
    boxShadow: '0px 2px 4px 0px #DBDBDB',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 12,
    padding: '24px 38px 24px 36px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerWrapper: {
    display: 'flex',
  },
  title: {
    fontSize: '24px',
    color: theme.color.tundora,
    userSelect: 'none',
    [theme.breakpoints.up(379)]: {
      whiteSpace: 'nowrap',
    },
  },
  asterisk: {
    userSelect: 'none',
    display: 'inline-block',
    color: theme.color.valencia,
    margin: '0 20px 0 8px',
  },
});
export default styles;
