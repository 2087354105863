import React from 'react';
import { Button } from 'gatorcomponents';
import { DNS_PROPAGATION_ARTICLE } from '@/config/urls/supportUrls';
import { TOS_STATUS } from '@/enums/preChat/preChat.enum';
import { ALERT_TYPES } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';

export default {
  invoiceStaticMessage: ({ totalInvoice, sum, handleClick }) => (
    <>
      {'Tienes '}
      {totalInvoice}
      {' factura(s) pendientes para pagar'}
      {' con un total del '}
      {sum}
      .
      <Button variant="text" label="Pagar ahora" onClick={handleClick} />
    </>
  ),
  domainCritialMessage: ({ handleClick }) => (
    <>
      {'Usted tiene uno o más sitios web suspendidos temporalmente. Haga '}
      <Button variant="text" label="clic aquí" onClick={handleClick} />
      {' para confirmar su dirección de correo electrónico y reactivar su sitio web.'}
    </>
  ),
  domainStaticMessage: ({ handleClick }) => (
    <>
      {'¡Evite la suspensión temporal de su sitio web! '}
      <Button variant="text" label="clic aquí" onClick={handleClick} />
      {' para confirmar su dirección de correo electrónico y evitar la suspensión.'}
    </>
  ),
  domainNotPropagated: ({ handleClick }) => (
    <>
      {'Dominio no propagado: su sitio web puede estar en proceso de actualización, lo que puede llevar hasta 72 horas para completarse. No se preocupe, esto es normal en internet. Si desea cambiar el dominio '}
      <Button variant="text" label="configure ahora." onClick={handleClick} />
    </>
  ),
  oldDomainInPropagation: ({ handleClick }) => (
    <>
      {'Parece que tiene uno o más dominios que estan siendo propagados, esto podría dejar su sitio offline por hasta 72 horas. '}
      <a href={DNS_PROPAGATION_ARTICLE} target="_blank" rel="noreferrer" onClick={handleClick}>Entienda el plazo.</a>
    </>
  ),
  domainInPropagation: ({ handleClick }) => (
    <>
      Parece que tiene uno o más dominios en propagación, esto puede dejar su sitio
      {' '}
      <strong>inestable o inactivo hasta por 72 horas.</strong>
      {' '}
      <Button variant="text" label="Ver dominios en propagación." onClick={handleClick} />
    </>
  ),
  modalDomainInPropagation: {
    title: 'Dominios en propagación',
    description: (
      <>
        El período de propagación es el tiempo que tarda su dominio en conectarse en todo el mundo después de configurar el dominio (cambio de DNS). Por lo general, ocurre
        {' '}
        <b>dentro de las 72 horas</b>
        .
      </>
    ),
    knowMoreLabel: '¿Quiere saber más sobre la propagación?',
    helpCentralLabel: (
      <>
        <a href="https://soporte-latam.hostgator.com/hc/es-419/articles/360024988331" target="_blank" rel="noreferrer">Haga clic aquí</a>
        {' '}
        para acceder a nuestra Central de Ayuda.
      </>
    ),
    alertTitle: 'Dominio(s) siendo propagado(s):',
    accordionTitle: 'Dominios en propagación',
    okUnderstoodButtonLabel: 'Ok, entendí',
  },
  refundModal: {
    title: 'Status del reembolso',
    refundCompletedCardLabel: (value, paymentMethod) => (
      <>
        ¡El valor de
        {' '}
        <strong>{value}</strong>
        {' '}
        para recibir en la modalidad
        {' '}
        <strong>{paymentMethod}</strong>
        {' '}
        ya ha sido reembolsado!
      </>
    ),
    refundPendingCardLabel: (value, paymentMethod) => (
      <>
        Tiene un reembolso por el valor de
        {' '}
        <strong>{value}</strong>
        {' '}
        para recibir en la modalidad
        {' '}
        <strong>{paymentMethod}</strong>
        .
      </>
    ),
    oneWeekCancel: 'Para cancelaciones realizadas después de 7 días del pago, el reembolso será parcial.',
    accordionTitle: 'Producto(s) cancelado(s)',
    canceledProduct: {
      label: 'Producto cancelado:',
      value: productDescription => (
        <>
          {productDescription}
        </>
      ),
    },
    canceledDate: {
      label: 'Fecha de cancelación:',
      value: date => (<>{date}</>),
    },
    canceledModality: {
      label: 'Modalidad:',
      value: modality => (<>{modality}</>),
    },
    refundValue: {
      label: 'Valor del reembolso:',
      value: value => (<>{value}</>),
    },
    modeAndTerm: {
      title: 'Modalidad del reembolso elegida y plazo:',
      refundMethods: {
        wholeCreditsLabel: 'Créditos HostGator - el valor se deducirá en una próxima factura.',
        wholeCreditCardLabel: 'Tarjeta de Crédito - el valor se devolverá dentro de 3 facturas (90 días) de acuerdo con su banco. Comuníquese con el emisor de su tarjeta para obtener más informaciones.',
        wholePaypalLabel: (
          <>
            PayPal - devolución según las
            {' '}
            <a
              href="https://www.paypal.com/es/cshelp/article/%C2%BFd%C3%B3nde-est%C3%A1-mi-reembolso-help130"
              target="_blank"
              rel="noreferrer"
            >
              propias reglas de PayPal
            </a>
            .
          </>
        ),
        wholePixLabel: 'Cupón de pago - por el momento, la opción disponible para el reembolso es Créditos HostGator - Si desea la devolución del valor, comuníquese con soporte Financiero a través del chat.',
        partialCreditsLabel: 'Créditos HostGator - el valor se deducirá en una próxima factura.',
        partialCreditCardLabel: 'Tarjeta de Crédito - opción disponible dentro de los 90 días posteriores a la contratación y reembolso dentro de 3 facturas (90 días) de acuerdo con su banco. Comuníquese con el emisor de su tarjeta para obtener más informaciones.',
        partialPaypalLabel: (
          <>
            PayPal - devolución según las
            {' '}
            <a
              href="https://www.paypal.com/es/cshelp/article/%C2%BFd%C3%B3nde-est%C3%A1-mi-reembolso-help130"
              target="_blank"
              rel="noreferrer"
            >
              propias reglas de PayPal
            </a>
            .
          </>
        ),
        partialPixLabel: 'Cupón de pago - por el momento, la opción disponible para el reembolso es Créditos HostGator - Si desea la devolución del valor, comuníquese con soporte Financiero a través del chat.',
      },
    },
    paymentMethods: {
      hgCredits: 'Créditos HostGator',
      creditCard: 'Tarjeta de Crédito',
      paypal: 'PayPal',
    },
    knowMoreLabel: (
      <div>
        Obtenga más información sobre los plazos y valores de reembolso en nuestra Central de ayuda
        {' '}
        <a href="https://soporte-latam.hostgator.com/hc/es-419/articles/222460487" target="_blank" rel="noreferrer">haciendo clic aquí</a>
        .
      </div>
    ),
    okUnderstoodButtonLabel: 'Ok, entendí',
  },
  selectPlaceHolder: 'seleccionar dominio',
  configureDomain: 'Configurar dominio',
  whichDomain: '¿Cuál dominio desea configurar?',
  selectDomainToConfigure: 'Tiene más de un dominio no configurado. Seleccione cuál desea configurar ahora.',
  domainNotContracted: ({ handleClick }) => (
    <>
      {'Dominio no contratado: usted tiene uno o más dominios que aún no fueron adquiridos. '}
      <Button variant="text" label="Contratar ahora." onClick={handleClick} />
    </>
  ),
  domainNotConfigured: ({ handleClick }) => (
    <>
      {'Configuración de dominio pendiente: usted tiene uno o más sitios web con configuración de dominio pendiente. '}
      <Button variant="text" label="Vea cómo configurarlo." onClick={handleClick} />
    </>
  ),
  domainForPointing: ({ handleClick, domain }) => (
    <>
      Su dominio
      {' '}
      <strong>
        &quot;
        {domain}
        &quot;
      </strong>
      {' '}
      debe estar configurado para un Plan aquí en HostGator u otra plataforma.
      <br />
      <Button variant="text" label="Configurar dominio" onClick={handleClick} />
    </>
  ),
  subscriptionToDue:
    ({ handleClick, daysToDue }) => (
      <>
        <strong>
          <>Faltan </>
          {daysToDue === 30 && (
            <> 30 </>
          )}
          {daysToDue < 30 && daysToDue > 10 && (
            <>menos de 30</>
          )}
          {daysToDue === 10 && (
            <>apenas 10</>
          )}
          {daysToDue < 10 && daysToDue > 5 && (
            <>menos de 10</>
          )}
          {daysToDue === 5 && (
            <>apenas 5</>
          )}
          {daysToDue < 5 && (
            <>menos de 5</>
          )}
          <> dias para que se cancele su suscripción</>
        </strong>
        <>
          {' '}
          <>Active la renovación y evite perder sus datos. </>
          <Button variant="text" label="Activar suscripción" onClick={handleClick} />
        </>
      </>
    ),
  subscriptionToCancel:
    ({ handleClick, dueDate }) => (
      <>
        <strong>
          <>En </>
          {dueDate}
          <> su suscripción será cancelada.</>
        </strong>
        {' '}
        <br />
        <>
          Active la renovación ahora mismo con un 35% de descuento.
        </>
        <>
          <br />
          <Button variant="text" label="Activar suscripción" onClick={handleClick} />
        </>
      </>
    ),
  refund: ({ handleClick, refundMethod, refundValue }) => (
    <>
      El valor de
      <strong>
        {' '}
        {refundValue}
        {' '}
      </strong>
      a recibir en el modo
      <strong>
        {' '}
        {refundMethod}
        {' '}
      </strong>
      ya fue
      {' '}
      {refundMethod && refundMethod.includes('HostGator') ? 'acreditada' : 'reembolsado'}
      .
      {' '}
      <Button variant="text" label="Ver detalles." onClick={handleClick} />
    </>
  ),
  emailPassword: {
    createSuccess: 'El correo electrónico ha sido creado exitosamente! :)',
    changeSuccess: 'La contraseña del correo ha sido modificada exitosamente.',
    weakPassword: (
      <>
        {'Atención: Se produjo un error debido a una contraseña débil. Cree una contraseña fuerte y segura con nuestro  '}
        <a href="https://www.hostgator.mx/generador-de-contrasenas" target="_blank" rel="noreferrer">generador de contraseñas.</a>
      </>
    ),
  },
  tos: {
    [TOS_STATUS.DISK]: {
      title: 'Su espacio en disco está lleno.',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.INODES]: {
      title: 'La cuenta ha alcanzado el límite de archivos y directorios',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.BACKUP]: {
      title: 'Solo puede almacenar una copia de seguridad en su cuenta',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.MYSQL]: {
      title: 'Su base de datos MySQL ha alcanzado el límite de uso de recursos',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.CPU]: {
      title: 'Su uso de los recursos del procesador ha llegado al límite',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.DMCA]: {
      title: 'Hay un problema relacionado con los derechos de autor en su sitio',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
    [TOS_STATUS.PISHING]: {
      title: 'Detectamos contenido potencialmente peligroso en su plan de hosting',
      description: ({ handleClick }) => (
        <>
          Ingrese al ticket para ser orientado por nuestros especialistas sobre cómo desbloquear.
          {' '}
          <Button variant="text" label="Ver Ticket." onClick={handleClick} />
        </>
      ),
    },
  },
  tickets: {
    [ALERT_TYPES.TICKET_SOLVED]: {
      title: 'Ticket finalizado.',
      description: ({ handleClick }) => (
        <>
          Su ticket fue concluido. Si aún necesita ayuda sobre este asunto, no dude en responder.
          {' '}
          <Button variant="text" label="Ver ticket." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_NEW]: {
      title: 'Ticket abierto',
      description: ({ handleClick }) => (
        <>
          Recibimos su ticket y nuestro equipo ya lo está analizando. Nos pondremos en contacto con usted en breve.
          {' '}
          <Button variant="text" label="Ver ticket." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_OPEN]: {
      title: 'Ticket pendiente',
      description: ({ handleClick }) => (
        <>
          ¡Todavía estamos trabajando en su solicitud! Si lo desea, agregue más información o preguntas.
          {' '}
          <Button variant="text" label="Ver ticket." onClick={handleClick} />
        </>
      ),
    },
    [ALERT_TYPES.TICKET_PENDING]: {
      title: 'Ticket esperando su respuesta.',
      description: ({ handleClick }) => (
        <>
          Necesitamos una respuesta o acción de su parte para resolver la solicitud. Responda lo más rápido posible.
          {' '}
          <Button variant="text" label="Ver ticket." onClick={handleClick} />
        </>
      ),
    },
  },
  backup: {
    backupCreated: {
      title: planInfo => `La copia de seguridad del ${planInfo} se ha completado`,
      description: ({ handleClick }) => (
        <>
          El proceso de copia de seguridad completa ha finalizado.
          <Button variant="text" label="Ver detalles." onClick={() => handleClick()} />
        </>
      ),
    },
    noBackup: {
      title: planInfo => `Tu ${planInfo} no tiene una copia de seguridad`,
      description: ({ handleClick }) => (
        <>
          Considera hacer una para no perder tus datos.
          <Button variant="text" label="Hacer copia de seguridad." onClick={() => handleClick()} />
        </>
      ),
    },
    oldBackup: {
      title: (planInfo, daysSinceLast) => `Tu última copia de seguridad del ${planInfo} fue hace más de ${daysSinceLast} días`,
      description: ({ handleClick }) => (
        <>
          Considera actualizar para no perder tus datos.
          <Button variant="text" label="Hacer copia de seguridad." onClick={() => handleClick()} />
        </>
      ),
    },
  },
  duplicateOrders: ({ handleClick }) => (
    <>
      {'Tiene uno o más pedidos iguales para el '}
      <strong>
        mismo producto y dominio.
      </strong>
      {' '}
      <Button
        label="Ver pedidos duplicados."
        variant="text"
        onClick={handleClick}
      />
    </>
  ),
  ssl: {
    active: ({ domain }) => (
      <>
        {'¡El SSL para el dominio '}
        <strong>{domain}</strong>
        {' se ha activado con éxito!'}
      </>
    ),
    activation_failed: ({ domain }) => (
      <>
        {'El SSL no se pudo instalar en el dominio '}
        <strong>{`${domain}. `}</strong>
        <a
          href="https://soporte-latam.hostgator.com/hc/es-419/articles/360008274932"
          target="_blank"
          rel="noreferrer"
        >
          Consulte cómo solucionarlo
        </a>
      </>
    ),
    in_progress: ({ domain }) => (
      <>
        {'El SSL está en proceso de activación para '}
        <strong>
          {`${domain}. `}
        </strong>
        Estará activo dentro de las 24 horas posteriores a la propagación del dominio.
      </>
    ),
  },
};
