import React from 'react';
import {
  Modal,
} from '@/pages/common';
import { Button } from '@/pages/common/v1/Button';
import * as Styles from './ThemeInfoModal.styles';
import { useResize } from '@/hooks/useResize';

const ThemeInfoModal = ({
  handleOnCloseThemeInfoModal, buttonLabel, theme, handleChangeTheme,
}) => {
  const screenSize = useResize();
  const isMobile = screenSize.width < 960;
  return (
    <Modal testId="modal-theme-info" disablePadding maxWidth={704} onClose={() => handleOnCloseThemeInfoModal(theme)}>
      <Styles.ThemeInfoModalWrapper>
        <Styles.Title data-testid="modal-theme-title">
          {theme.title}
        </Styles.Title>
        <Styles.ThemeImage data-testid="modal-theme-banner" src={theme.imageDetails} />
        <Button testId="button-select-theme" onClick={() => handleChangeTheme(theme.value)} size={isMobile ? 'regular' : 'small'} variant="secondary" label={buttonLabel} />
      </Styles.ThemeInfoModalWrapper>
    </Modal>
  );
};

export default ThemeInfoModal;
