import useLocale from '@/hooks/useLocale';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { billingActions, preChatActions } from '@/redux/modules';

const useBenefitInvoice = () => {
  const [invoiceModalIsOpen, setInvoiceModalIsOpen] = useState(false);
  const [invoiceIsLoading, setInvoiceIsLoading] = useState(false);
  const [clearedStatus, setClearedStatus] = useState(false);
  const invoiceStatus = useSelector(state => state.billing.invoice);
  const { triage } = useSelector(state => state.preChat);
  const { routes } = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();

  const openPreChatCancelFlow = useCallback(() => {
    if (triage.subscriptionToCancel) {
      dispatch(preChatActions.preChat.setOpen(true));
      dispatch(preChatActions.subscription.setSubscriptionToCancel(null));
    }
  }, [triage, dispatch]);

  const redirectToInvoice = useCallback(() => {
    const unpaidInvoicePath = `${routes.invoices}${routes.unpaid}/${invoiceStatus.invoiceId}`;
    openPreChatCancelFlow();
    history.push(unpaidInvoicePath);
  }, [history, routes, invoiceStatus, openPreChatCancelFlow]);

  useEffect(() => {
    const resetInvoiceStatus = () => {
      if (!clearedStatus) {
        dispatch(billingActions.invoice.reset());
        setClearedStatus(true);
      }
    };

    const invoiceStatusControls = () => {
      if (invoiceIsLoading && invoiceStatus.isGenerateInvoice === true) {
        redirectToInvoice();
      } else if (invoiceIsLoading && invoiceStatus.isGenerateInvoice === false) {
        setInvoiceIsLoading(false);
        setInvoiceModalIsOpen(true);
      }
    };

    resetInvoiceStatus();
    invoiceStatusControls();
  }, [clearedStatus, invoiceStatus, invoiceIsLoading, redirectToInvoice, dispatch]);

  const closeInvoiceModal = () => {
    setInvoiceModalIsOpen(false);
  };

  const getInvoiceInfos = (hostId) => {
    setInvoiceIsLoading(true);
    dispatch(billingActions.invoice.request(hostId));
  };

  const publishInvoiceDraft = ({ invoiceId, executePayment }) => {
    dispatch(billingActions.draftPublish.request({ invoiceId, executePayment }));
  };

  return {
    invoiceModalIsOpen,
    invoiceIsLoading,
    closeInvoiceModal,
    getInvoiceInfos,
    redirectToInvoice,
    publishInvoiceDraft,
  };
};

export default useBenefitInvoice;
