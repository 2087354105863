import React from 'react';
import * as Style from './IconMoodle.style';

const IconMoodle = ({
  fill = '#4a4a4a',
  stroke = '#a8abad',
  width = '42',
  height = '29',
  testId = 'icon-moodle',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42.438 28.917" fill={fill}>
      <g id="Grupo_39704" data-name="Grupo 39704" transform="translate(-1073.343 -557.038)">
        <path id="Caminho_247734" data-name="Caminho 247734" d="M41.722,49.045V35.738c0-2.8-1.167-4.2-3.443-4.2s-3.443,1.4-3.443,4.2V49.045H28.007V35.738c0-2.8-1.109-4.2-3.385-4.2s-3.443,1.4-3.443,4.2V49.045h-6.77V34.98a7.643,7.643,0,0,1,3.035-6.6A11.641,11.641,0,0,1,24.621,26.4c3.093,0,5.311.759,6.77,2.335,1.226-1.576,3.5-2.335,6.829-2.335A11.526,11.526,0,0,1,45.4,28.384a7.643,7.643,0,0,1,3.035,6.6V49.1H41.722Z" transform="translate(1067.347 536.852)" />
        <path id="Caminho_247740" data-name="Caminho 247740" d="M15.008,29.762l-.7,3.5c6.42,2.159,12.49.058,15.7-5.545-4.727-3.268-9.1.058-15,2.043" transform="translate(1067.389 536.876)" />
        <path id="Caminho_247741" data-name="Caminho 247741" d="M15.05,28.228c-.233,1.342-.409,2.626-.642,3.969,6.128,2.1,12.14.292,15.35-5.194-4.085-4.727-8.638-.817-14.708,1.226" transform="translate(1067.347 537.534)" />
        <path id="Caminho_247742" data-name="Caminho 247742" d="M22.212,29.012c-2.8-.584-5.836.875-7.938,2.393C12.932,21.6,19,21.833,26.94,24.284c-.525,2.393-1.4,5.6-2.918,7.529a3.136,3.136,0,0,0-1.809-2.8" transform="translate(1067.48 538.326)" />
        <path id="Caminho_247743" data-name="Caminho 247743" d="M.008,24.58C10.28,18.393,16.058,17.226,28.022,16,14.132,26.972,13.782,24.58.008,24.58" transform="translate(1073.343 541.182)" />
        <path id="Caminho_247744" data-name="Caminho 247744" d="M40.139,16.1l-11.031,8" transform="translate(1061.227 541.141)" stroke={fill} strokeWidth="0.5" />
        <path id="Caminho_247745" data-name="Caminho 247745" d="M25.467,23.152c.117,1.284-.175-1.226,0,0" transform="translate(1062.766 538.349)" opacity="0.23" />
        <path id="Caminho_247746" data-name="Caminho 247746" d="M.008,30.325,16.992,29.8" transform="translate(1073.343 535.437)" stroke={stroke} strokeWidth="0.5" />
        <path id="Caminho_247747" data-name="Caminho 247747" d="M14.61,23.6C11.691,24.417,2.12,26.518,2,27.744a39.463,39.463,0,0,0-.058,5.895" transform="translate(1072.573 538.018)" fill="none" stroke={fill} strokeWidth="0.5" />
        <path id="Caminho_247748" data-name="Caminho 247748" d="M2.428,49.163C1.378,46.537.094,43.619,1.611,40c.992,3.385.817,5.953.817,9.163" transform="translate(1072.965 531.19)" />
        <ellipse id="Elipse_1183" data-name="Elipse 1183" cx="0.467" cy="0.233" rx="0.467" ry="0.233" transform="matrix(0.942, -0.336, 0.336, 0.942, 1086.26, 561.377)" />
      </g>
    </svg>
  </Style.Wrapper>
);

export default IconMoodle;
