import React from 'react';
import * as Style from './IconDetailedTheme.style';

const IconDetailedTheme = ({
  color = '#4a4a4a',
  background = '#ff9758',
  width = '24',
  height = '27',
  testId = 'icon-detailed-theme',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.929 27.153">
      <g id="Grupo_37653" data-name="Grupo 37653" transform="translate(-0.427 0.034)">
        <g id="icon-velocidade" transform="translate(11.182 -0.034)">
          <rect id="Rectangle" width="14.063" height="17.226" transform="translate(0 0)" fill={background} />
        </g>
        <g id="imagesearch_roller_black_24dp" transform="translate(0.427 -0.034)">
          <g id="Grupo_39942" data-name="Grupo 39942" transform="translate(0 4.265)">
            <rect id="Retângulo_16026" data-name="Retângulo 16026" width="19.854" height="19.854" fill="none" />
          </g>
          <g id="Grupo_39943" data-name="Grupo 39943" transform="translate(1.655)">
            <path id="Caminho_247833" data-name="Caminho 247833" d="M25.274,8.465V3.293A1.3,1.3,0,0,0,23.981,2H8.465A1.3,1.3,0,0,0,7.172,3.293V4.586H4.586A2.594,2.594,0,0,0,2,7.172v5.172A2.594,2.594,0,0,0,4.586,14.93H14.93v3.879H13.637A1.3,1.3,0,0,0,12.344,20.1V27.86a1.3,1.3,0,0,0,1.293,1.293h5.172A1.3,1.3,0,0,0,20.1,27.86V20.1a1.3,1.3,0,0,0-1.293-1.293H17.516V14.93a2.594,2.594,0,0,0-2.586-2.586H4.586V7.172H7.172V8.465A1.3,1.3,0,0,0,8.465,9.758H23.981A1.3,1.3,0,0,0,25.274,8.465ZM9.758,4.586h12.93V7.172H9.758Zm7.758,21.981H14.93V21.395h2.586Z" transform="translate(-2 -2)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);


export default IconDetailedTheme;
