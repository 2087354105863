import React from 'react';
import logger from '../logger';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const { componentName, errorCallback } = this.props;
    const boundaryError = { stack: error.stack, message: error.message, componentError: errorInfo };

    logger.error(`Error on Component ${componentName}`, boundaryError);

    errorCallback && errorCallback();
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

const withErrorBoundary = (Component, componentName, errorCallback) => {
  const wrapperComponent = props => (
    <ErrorBoundary componentName={componentName} errorCallback={errorCallback}>
      <Component {...props} />
    </ErrorBoundary>
  );

  return wrapperComponent;
};

export default withErrorBoundary;
