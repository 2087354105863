import {
  REQUEST_SITELOCK_DOMAINS,
  RECEIVE_SITELOCK_DOMAINS,
  SET_SITELOCK_PRODUCT,
  RECEIVE_SITELOCK_STATUS_SCAN,
  REQUEST_SITELOCK_STATUS_SCAN,
  ERROR_SITELOCK_STATUS_SCAN,
  RECEIVE_SITELOCK_SCAN,
  REQUEST_SITELOCK_SCAN,
  RECEIVE_SITELOCK_PRICE,
  RECEIVE_SITELOCK_COMPLETE_SCAN,
  RECEIVE_SITELOCK_COMPLETE_SMARTSCAN,
  RECEIVE_SITELOCK_SCAN_STATUS,
  RECEIVE_SITELOCK_SCAN_STATUS_PENDING,
  RECEIVE_SITELOCK_SHIELD,
  SITELOCK_RESET_TOAST,
  SITELOCK_CLEAR,
  RECEIVE_SITELOCK_SHIELD_UPDATE,
  REQUEST_SITELOCK_SMARTSCAN_UPDATE,
  RECEIVE_SITELOCK_SMARTSCAN_UPDATE,
  ERROR_SITELOCK_SMARTSCAN_UPDATE,
  REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG,
  RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG,
  ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG,
  REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD,
  REQUEST_SITELOCK_PAYMENT_METHOD,
  RECEIVE_SITELOCK_PAYMENT_METHOD,
  ABORT_SITELOCK_PAYMENT_METHOD,
  ERROR_SITELOCK_PAYMENT_METHOD,
  ABORT_SITELOCK_PROFESSIONAL_PRICE,
  REQUEST_SITELOCK_PROFESSIONAL_PRICE,
  RECEIVE_SITELOCK_PROFESSIONAL_PRICE,
  ERROR_SITELOCK_PROFESSIONAL_PRICE,
  ABORT_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
  ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT,
} from '@/redux/actions/actionsTypes';

import { SITELOCK_SEAL_FORM_OPTIONS } from '@/config/products/sitelock';

import { download } from '@/utils/Application/download';

const initialState = {
  total: null,
  loading: false,
  loadingDescription: true,
  loadedDescription: false,
  loadingDomains: false,
  loadingShield: false,
  loadingError: false,
  pending: false,
  loadedDate: null,
  standalone: [],
  addon: [],
  sitelockProduct: null,
  attentionFlag: 'red',
  lastScan: null,
  nextScan: null,
  name: '',
  scanning: false,
  scanned: false,
  shieldSaved: false,
  issues: 0,
  basicPrice: null,
  scanLimitReached: false,
  domain: null,
  completeScan: null,
  status: {
    scanning: false,
    secure: false,
    vulnerable: false,
  },
  shield: {
    site_id: null,
    color: 'red',
    size: 'medium',
    type: 'mal',
    language: SITELOCK_SEAL_FORM_OPTIONS.formLanguageOptions[0].value,
  },
  smartscan: {
    active: false,
    configured: false,
    data: {},
    error: false,
    pending: false,
    success: false,
    history: null,
    file: {
      lastRequestedFilename: null,
      lastReceivedFilename: null,
      lastFailedFilename: null,
    },
    config: {
      error: false,
      pending: false,
      success: false,
      lockButton: false,
    },
  },
  cta: {
    isLoadingPrices: false,
    isLoadingPayment: false,
    priceLoadedDate: null,
    paymentLoadedDate: null,
    aborted: false,
    error: false,
    prices: [],
    paymentMethods: [],
  },
  buyFastcheckout: {
    isLoading: false,
    loadedDate: null,
    aborted: false,
    error: false,
    invoiceId: null,
    redirectToInvoices: false,
  },
};
export default function sitelock(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SITELOCK_DOMAINS:
      return {
        ...state,
        loadingDomains: true,
      };
    case RECEIVE_SITELOCK_DOMAINS:
      return {
        ...state,
        loading: false,
        loadingDomains: false,
        loadingError: false,
        loadingDescription: true,
        pending: {
          ...state.pending,
          loading: true,
        },
        total: action.total,
        standalone: [...action.standalone],
        addon: [...action.addon],
      };
    case SET_SITELOCK_PRODUCT:
      return {
        ...state,
        loadingDescription: true,
        loadingError: false,
        sitelockProduct: state[action.productType].find(e => action.productId === e.id),
      };
    case REQUEST_SITELOCK_STATUS_SCAN:
      return {
        ...state,
        loadingError: false,
      };
    case ERROR_SITELOCK_STATUS_SCAN:
      return {
        ...state,
        loadingError: true,
      };
    case RECEIVE_SITELOCK_STATUS_SCAN:
      return {
        ...state,
        loadedDescription: true,
        attentionFlag: action.attentionFlag,
        scanning: action.scanning,
        name: action.name,
        issues: action.issues,
        domain: action.domain,
        product: action.product,
        lastScan: action.lastScan,
        nextScan: action.nextScan,
        scanLimitReached: action.scanLimitReached,
        loadingDescription: false,
        smartscan: { ...state.smartscan, ...action.smartscan },
      };
    case REQUEST_SITELOCK_SCAN:
      return {
        ...state,
        scanning: true,
      };
    case RECEIVE_SITELOCK_SCAN:
      return {
        ...state,
        scanning: true,
        scanned: action.scanned,
      };
    case RECEIVE_SITELOCK_SCAN_STATUS_PENDING:
      return {
        ...state,
        status: action.status,
      };
    case RECEIVE_SITELOCK_SCAN_STATUS:
      return {
        ...state,
        status: action.status,
        scanning: action.scanning,
        attentionFlag: action.attentionFlag,
        name: action.name,
        issues: action.issues,
        lastScan: action.lastScan,
        nextScan: action.nextScan,
      };
    case RECEIVE_SITELOCK_PRICE:
      return {
        ...state,
        basicPrice: action.basicPrice,
      };

    case REQUEST_SITELOCK_PROFESSIONAL_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: true,
          priceLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_SITELOCK_PROFESSIONAL_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: false,
          error: false,
          prices:
            [
              {
                price: action.data.data.product.prices.renewalPricing.annually,
                cycle: 'annually',
              },
              {
                price: action.data.data.product.prices.renewalPricing.semiannually,
                cycle: 'semiannually',
              },
              {
                price: action.data.data.product.prices.renewalPricing.quarterly,
                cycle: 'quarterly',
              },
              {
                price: action.data.data.product.prices.renewalPricing.monthly,
                cycle: 'monthly',
              },
            ],
        },
      };
    case ABORT_SITELOCK_PROFESSIONAL_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_SITELOCK_PROFESSIONAL_PRICE:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPrices: false,
          priceLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_SITELOCK_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: true,
          paymentLoadedDate: Date.now(),
          aborted: false,
          error: false,
        },
      };
    case RECEIVE_SITELOCK_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: false,
          paymentMethods: Object.entries(action.data.data).filter(paymentMethod => paymentMethod[1].selectable),
        },
      };
    case ABORT_SITELOCK_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: true,
          error: false,
        },
      };
    case ERROR_SITELOCK_PAYMENT_METHOD:
      return {
        ...state,
        cta: {
          ...state.cta,
          isLoadingPayment: false,
          paymentLoadedDate: null,
          aborted: false,
          error: true,
        },
      };
    case REQUEST_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: true,
          loadedDate: Date.now(),
          aborted: false,
          error: false,
          redirectToInvoices: false,
        },
      };
    case RECEIVE_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: false,
          invoiceId: action.data.invoice_id,
          paymentMethod: action.data.paymentMethod,
          redirectToInvoices: true,
        },
      };
    case ABORT_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: true,
          error: false,
          redirectToInvoices: false,
        },
      };
    case ERROR_SITELOCK_PROFESSIONAL_BUY_FAST_CHECKOUT:
      return {
        ...state,
        buyFastcheckout: {
          ...state.buyFastcheckout,
          isLoading: false,
          loadedDate: null,
          aborted: false,
          error: true,
          redirectToInvoices: false,
        },
      };
    case RECEIVE_SITELOCK_COMPLETE_SCAN:
      return {
        ...state,
        completeScan: action.completeScan,
      };
    case SITELOCK_RESET_TOAST:
      return {
        ...state,
        shieldSaved: false,
        status: {
          scanning: false,
          secure: false,
          vulnerable: false,
        },
        smartscan: {
          ...state.smartscan,
          error: false,
          pending: false,
          success: false,
          config: {
            error: false,
            pending: false,
            success: false,
            lockButton: false,
          },
        },
      };
    case RECEIVE_SITELOCK_SHIELD:
      return {
        ...state,
        shield: { ...action.shield },
        loadingShield: false,
      };
    case RECEIVE_SITELOCK_SHIELD_UPDATE:
      return {
        ...state,
        shieldSaved: true,
      };
    case SITELOCK_CLEAR:
      return {
        ...initialState,
      };
    case REQUEST_SITELOCK_SMARTSCAN_UPDATE:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          error: false,
          pending: true,
          success: false,
        },
      };
    case RECEIVE_SITELOCK_SMARTSCAN_UPDATE:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          error: false,
          pending: false,
          success: true,
          configured: true,
        },
      };
    case ERROR_SITELOCK_SMARTSCAN_UPDATE:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          error: true,
          pending: false,
          success: false,
        },
      };
    case RECEIVE_SITELOCK_COMPLETE_SMARTSCAN:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          history: action.history,
        },
      };
    case REQUEST_SITELOCK_SMARTSCAN_AUTOCONFIG:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          config: {
            error: false,
            pending: true,
            success: false,
            lockButton: true,
          },
        },
      };
    case RECEIVE_SITELOCK_SMARTSCAN_AUTOCONFIG:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          configured: true,
          data: { ...action.data },
          config: {
            error: false,
            pending: false,
            success: true,
            lockButton: false,
          },
        },
      };
    case ERROR_SITELOCK_SMARTSCAN_AUTOCONFIG:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          config: {
            error: true,
            pending: false,
            success: false,
            lockButton: false,
          },
        },
      };
    case REQUEST_SITELOCK_SMARTSCAN_FILE_DOWNLOAD:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          file: {
            ...state.smartscan.file,
            lastRequestedFilename: action.file,
          },
        },
      };
    case RECEIVE_SITELOCK_SMARTSCAN_FILE_DOWNLOAD:
      download(`${action.file}.txt`, action.fileData);
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          file: {
            ...state.smartscan.file,
            lastReceivedFilename: action.file,
          },
        },
      };
    case ERROR_SITELOCK_SMARTSCAN_FILE_DOWNLOAD:
      return {
        ...state,
        smartscan: {
          ...state.smartscan,
          file: {
            ...state.smartscan.file,
            lastFailedFilename: action.file,
          },
        },
      };
    default:
      return state;
  }
}
