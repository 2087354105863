import { locale } from '@/utils/locale';
import React from 'react';

export const codeGuardCtaLocale = {
  mainTitle: locale('codeGuardLP.mainTitle'),
  getNow: {
    title: () => (
      <>
        <span>{locale('codeGuardLP.getNow.title.0')}</span>
        {locale('codeGuardLP.getNow.title.1')}
      </>
    ),
    description: locale('codeGuardLP.getNow.description'),
    listTitle: () => (
      <>
        {locale('codeGuardLP.getNow.listTitle.0')}
        <span>{locale('codeGuardLP.getNow.listTitle.1')}</span>
        {locale('codeGuardLP.getNow.listTitle.2')}
      </>
    ),
    featureList: {
      firstColumn: [
        locale('codeGuardLP.getNow.featureList.1'),
        locale('codeGuardLP.getNow.featureList.2'),
        locale('codeGuardLP.getNow.featureList.3'),
        locale('codeGuardLP.getNow.featureList.4'),
      ],
      secondColumn: [
        locale('codeGuardLP.getNow.featureList.5'),
        locale('codeGuardLP.getNow.featureList.6'),
        locale('codeGuardLP.getNow.featureList.7'),
        locale('codeGuardLP.getNow.featureList.8'),
      ],
    },
    formTitle: locale('codeGuardLP.getNow.formTitle'),
    selectePaymentLabel: locale('codeGuardLP.getNow.selectePaymentLabel'),
    cycleLabel: locale('codeGuardLP.getNow.cycleLabel'),
    cycleOptions: {
      annually: locale('codeGuardLP.getNow.cycleOptions.annually'),
      semiannually: locale('codeGuardLP.getNow.cycleOptions.semiannually'),
      quarterly: locale('codeGuardLP.getNow.cycleOptions.quarterly'),
      monthly: locale('codeGuardLP.getNow.cycleOptions.monthly'),
    },
    cyclePer: {
      annually: locale('codeGuardLP.getNow.cyclePer.annually'),
      semiannually: locale('codeGuardLP.getNow.cyclePer.semiannually'),
      quarterly: locale('codeGuardLP.getNow.cyclePer.quarterly'),
      monthly: locale('codeGuardLP.getNow.cyclePer.monthly'),
    },
    toBePaid: locale('codeGuardLP.getNow.toBePaid'),
    hgCodeguard: locale('codeGuardLP.getNow.hgCodeguard'),
    needMoreLabel: locale('codeGuardLP.getNow.needMoreLabel'),
    needMoreButtonText: locale('codeGuardLP.getNow.needMoreButtonText'),
  },
  detailsSections: {
    title: () => (
      <>
        {locale('codeGuardLP.detailsSections.title.0')}
        <span>{locale('codeGuardLP.detailsSections.title.1')}</span>
        {locale('codeGuardLP.detailsSections.title.2')}
      </>
    ),
    firstSection: {
      title: () => (
        <>
          {locale('codeGuardLP.detailsSections.firstSection.title.0')}
          <span>{locale('codeGuardLP.detailsSections.firstSection.title.1')}</span>
        </>
      ),
      description: () => (
        <>
          <span>
            {locale('codeGuardLP.detailsSections.firstSection.description.0')}
            <b>{locale('codeGuardLP.detailsSections.firstSection.description.1')}</b>
            {locale('codeGuardLP.detailsSections.firstSection.description.2')}
          </span>
          <br />
          <span>
            {locale('codeGuardLP.detailsSections.firstSection.description.3')}
          </span>
        </>
      ),
      buttonLabel: locale('codeGuardLP.detailsSections.firstSection.buttonLabel'),
    },
    secondSection: {
      title: () => (
        <>
          {locale('codeGuardLP.detailsSections.secondSection.title.0')}
          <span>{locale('codeGuardLP.detailsSections.secondSection.title.1')}</span>
          {locale('codeGuardLP.detailsSections.secondSection.title.2')}
        </>
      ),
      description: () => (
        <>
          <span>
            {locale('codeGuardLP.detailsSections.secondSection.description.0')}
          </span>
          <br />
          <span>
            {locale('codeGuardLP.detailsSections.secondSection.description.1')}
          </span>
        </>
      ),
      buttonLabel: locale('codeGuardLP.detailsSections.secondSection.buttonLabel'),
    },
  },
  discoverBenefits: {
    title: locale('codeGuardLP.discoverBenefits.title'),
    benefits: {
      0: {
        title: locale('codeGuardLP.discoverBenefits.benefits.0.title'),
        description: locale('codeGuardLP.discoverBenefits.benefits.0.description'),
      },
      1: {
        title: locale('codeGuardLP.discoverBenefits.benefits.1.title'),
        description: locale('codeGuardLP.discoverBenefits.benefits.1.description'),
      },
      2: {
        title: locale('codeGuardLP.discoverBenefits.benefits.2.title'),
        description: locale('codeGuardLP.discoverBenefits.benefits.2.description'),
      },
    },
    videoTitle: locale('codeGuardLP.discoverBenefits.videoTitle'),
    videoDescription: locale('codeGuardLP.discoverBenefits.videoDescription'),
    buttonLabel: locale('codeGuardLP.discoverBenefits.buttonLabel'),
  },
  snappyBanner: {
    title: locale('codeGuardLP.snappyBanner.title'),
    buttonLabel: locale('codeGuardLP.snappyBanner.buttonLabel'),
  },
};
