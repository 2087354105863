import {
  DNS_DOMAIN_LIST_REQUEST, DNS_DOMAIN_LIST_RECEIVE, DNS_DOMAIN_LIST_ERROR,
  DNS_DOMAIN_LIST_EXTERNAL_REQUEST, DNS_DOMAIN_LIST_EXTERNAL_RECEIVE,
  DNS_DOMAIN_LIST_EXTERNAL_ERROR, CHANGE_DNS_DOMAIN_STATE, SET_CHOSEN_DOMAIN,
} from '@/redux/actions/actionsTypes';


const initialState = {
  choosenDomain: '',
  loading: false,
  domains: {
    dnsMissing: [],
    dnsOk: [],
    anotherProvider: [],
    loadingInternalRequest: false,
    loadingExternalRequest: false,
  },
};

export default function dnsList(state = initialState, action) {
  switch (action.type) {
    case DNS_DOMAIN_LIST_EXTERNAL_REQUEST:
      return {
        ...state,
        loading: true,
        domains: {
          ...state.domains,
          loadingExternalRequest: true,
        },
      };
    case DNS_DOMAIN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        domains: {
          ...state.domains,
          loadingInternalRequest: true,
        },
      };
    case DNS_DOMAIN_LIST_EXTERNAL_RECEIVE:
      return {
        ...state,
        loading: false,
        domains: {
          ...state.domains,
          ...action.dnsList,
          loadingExternalRequest: false,
        },
      };
    case DNS_DOMAIN_LIST_RECEIVE:
      return {
        ...state,
        loading: false,
        domains: {
          ...state.domains,
          ...action.dnsList,
          loadingInternalRequest: false,
        },
      };
    case DNS_DOMAIN_LIST_EXTERNAL_ERROR:
      return {
        ...state,
        loading: false,
        domains: {
          ...state.domains,
          loadingExternalRequest: false,
        },
      };
    case DNS_DOMAIN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        domains: {
          ...state.domains,
          loadingInternalRequest: false,
        },
      };
    case CHANGE_DNS_DOMAIN_STATE:
      return {
        ...state,
        domains: action.stateDomains.domains,
      };

    case SET_CHOSEN_DOMAIN:
      return {
        ...state,
        choosenDomain: action.payload.domain,
      };

    default:
      return state;
  }
}
