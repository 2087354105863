import React from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MktPlaceContextProvider from '@/contexts/MktPlace/context';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import FilterSection from '@/components/MktPlace/FilterSection';
import ListSection from '@/components/MktPlace/ListSection';
import styles from './styles';
import { isBRServer } from '@/utils/Validators/validation';

const MktPlace = ({ classes, t }) => {
  const location = useLocation();
  const isAlphaAdminMktPlaceActive = false;
  const isAlphaMktPlaceActive = isBRServer;

  const shouldDisplay = isAlphaMktPlaceActive
  || isAlphaAdminMktPlaceActive
  || location.pathname.includes('/LED-23428');

  return (
    <ContentContainer className={classes.root}>
      {shouldDisplay
      && (
        <MktPlaceContextProvider t={t}>

          <FilterSection />

          <ListSection />
        </MktPlaceContextProvider>
      )}
    </ContentContainer>
  );
};

export default withI18n()(withStyles(styles)(MktPlace));
