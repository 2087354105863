import { createLocale, selectedLanguage } from '@/lang/i18n';

let localeInstance = createLocale(selectedLanguage);

export const setupLocale = (lang = 'pt') => {
  localeInstance = createLocale(lang);
};

const locale = (localeRoute, params) => localeInstance.t(localeRoute, params);

export default locale;
