/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { setAuthToken, setJWT, setRefreshToken } from '@/redux/actions/auth';
import { loadSummary } from '@/redux/actions/summary';
import Notifier from '@/components/Notification/Notifier/index';
import Loader from '@/components/Layout/Loader';
import { loadOnboardingDomains } from '@/redux/actions/onboarding';
import { loadFeatureToggles } from '@/redux/actions/featureToggle';
import { loadCountryCodes } from '@/redux/actions/countryPhoneCodes';
import { setCookie, getCookie } from '@/utils/Application/cookies';
import { loadScript } from '@/utils/ThirdParties/loadUniqueScripts';
import { initWootric } from '@/utils/ThirdParties/wootric';
import { initDataDog } from '@/utils/ThirdParties/datadog';
import { loadGoogleTagManagerScripts, onLoadHotjar } from '@/utils/ThirdParties/tagManager';
import { notifierError } from '@/utils/Application/notifier';
import {
  config, IS_PROD, COUNTRY, AMPLITUDE_KEY,
} from '@/config';
import { WOOTRIC_URL } from '@/config/externalScriptsUrl';
import { LOGOUT_URL } from '@/config/whmcsUrls';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { loadUnpaidInvoices } from '@/redux/actions/unpaidInvoices';
import { loadAlerts } from '@/redux/actions/alerts';
import { getLastSharedBoarding, skipSurveyLocalStorage } from '@/pages/CustomerProfileSurvey/utils';
import { skipCustomerSurvey, verifyResponseCustomerProfileSurvey } from '@/redux/actions/customerProfileSurvey';
import AppVersion from '@/components/Labels/AppVersion/AppVersion';
import useSurvey from '@/pages/SurveyMonkey/useSurvey';
import { queryParams } from '@/utils/Application/queryParams';
import ChatButton from '@/components/Buttons/ChatButton';
import { Notifications } from '@/pages/common/';
import Main from './Main';
import {
  init as initAmplitude, Identify as IdentifyObject, identify as identifyAmplitude,
} from '@amplitude/analytics-browser';
import { format } from 'date-fns';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Growthbook } from '@/config/GrowthBook';
import logger from '@/utils/logger';
import { CHARLIE_DATADOG_ALL_LOGS, CHARLIE_DATADOG_GENERIC_LOGS } from '@/config/GrowthBook/constants';
import { VideoClasses } from '@/components/VideoClasses';
import { commonActions } from '@/redux/modules';


const Pages = ({ t }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const summary = useSelector(state => state.summary);
  const websiteBuilderDomains = useSelector(state => state.onboarding);
  const featureToggles = useSelector(state => state.featureToggles.toggles);
  const notifications = useSelector(state => state.common.notifications);
  const [datadogIsRunning, setDatadogIsRunning] = useState(false);
  const sendGenericLogs = useFeatureIsOn(CHARLIE_DATADOG_GENERIC_LOGS);
  const allLogs = useFeatureIsOn(CHARLIE_DATADOG_ALL_LOGS);


  useEffect(() => {
    if (Growthbook.ready) {
      initDataDog(setDatadogIsRunning, allLogs);
    }
  }, [Growthbook.ready, allLogs]);

  const jsErrorHandler = (event) => {
    const { error } = event;
    logger.error(`Generic error: ${error.message}`, error);
  };

  const addJsErrorListner = useCallback(() => {
    if (sendGenericLogs && datadogIsRunning) {
      window.addEventListener('error', jsErrorHandler);
    }
  }, [datadogIsRunning, sendGenericLogs]);


  useEffect(() => {
    addJsErrorListner();
  }, [addJsErrorListner]);

  const {
    loadingVerify: loadingVerifyCustomerSurvey,
    hasResponse: hasResponseCustomerSurvey,
  } = useSelector(state => state.customerProfileSurvey);

  const shouldShowMain = isAuthenticated && summary.id && !loadingVerifyCustomerSurvey;
  const { successSurvey } = useSurvey(t);
  const location = useLocation();

  const handleBoletoProcessError = () => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    if (searchParams.has('process_error')) {
      const error = searchParams.get('process_error');
      if (error === 'boleto') {
        dispatch(enqueueSnackbar('snackbar.error.process_boleto', notifierError));
      }
    }
  };

  useEffect(() => {
    const { COUNTRY } = config;
    COUNTRY === 'br' && handleBoletoProcessError();
    const token = getCookie('hg-token');
    if (token) {
      localStorage.setItem('token', token);
    }
    const tokenLocal = localStorage.getItem('token');

    const redirectPath = getCookie('hg_car');
    if (redirectPath) {
      setCookie('hg_car', '', false, -1);
    }

    if (!tokenLocal) {
      setCookie('hg_car', btoa(`${location.pathname}${location.search}`), false, 1);
      window.location = LOGOUT_URL;
    }

    document.title = t('appTitle');
    loadGoogleTagManagerScripts();

    dispatch(setAuthToken(localStorage.getItem('token')));
    dispatch(setJWT(Cookies.get('hg-service-token')));
    dispatch(setRefreshToken(Cookies.get('hg-refresh-token')));
    dispatch(loadFeatureToggles());
    dispatch(loadSummary());
    dispatch(loadUnpaidInvoices());
    dispatch(loadAlerts());
    dispatch(loadCountryCodes());
    dispatch(commonActions.serverTime.request());
    successSurvey();
    websiteBuilderDomains.products.length === 0 && dispatch(loadOnboardingDomains());
    Growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    if (summary.id) {
      const amplitudeUser = `${summary.country}_${summary.id}`;
      const amplitudeConfigurationObj = {
        pageViewTracking: true,
      };

      if (IS_PROD) {
        initAmplitude(AMPLITUDE_KEY, amplitudeUser, amplitudeConfigurationObj);
        const identifyObject = new IdentifyObject();
        identifyObject.set('init_session', location.pathname);
        identifyObject.set('country_whmcs', COUNTRY);
        identifyObject.set('sign_up', summary.datecreated);
        identifyAmplitude(identifyObject);
      }

      Growthbook.setAttributes({
        id: String(summary.id),
        brand: COUNTRY,
      });
    }
  }, [(summary && summary.id)]);

  useEffect(() => {
    if (featureToggles && summary.id && summary.properties.persona !== '') {
      const { wootric } = featureToggles;
      const { properties } = summary;
      if (properties && wootric) {
        if (properties.active || properties.showWootric) {
          loadScript({ id: 'wootric', src: WOOTRIC_URL }).then(() => {
            initWootric(properties);
          });
        }
      }
    }
  }, [featureToggles, summary]);

  useEffect(() => {
    if (summary.id) {
      onLoadHotjar({
        id: `${COUNTRY}_${summary.id}`,
        signUp: summary.datecreated,
        lastLogin: format(new Date(), 'yyyy-MM-dd'),
      });
    }
  }, [summary.id]);

  useEffect(() => {
    const skip = queryParams(window.location.search, 'skip');
    const skipForAdmin = skipSurveyLocalStorage.get();
    if (skip && !skipForAdmin) { skipSurveyLocalStorage.set(); }
    if (skip === 'false' && skipForAdmin) { skipSurveyLocalStorage.remove(); }
  }, []);

  useEffect(() => {
    if (websiteBuilderDomains.products && summary.id) {
      const { hasSharedBoarding, onboardingFilter } = getLastSharedBoarding(websiteBuilderDomains.products);

      if (hasSharedBoarding && !hasResponseCustomerSurvey) {
        const { hostingId } = onboardingFilter;

        dispatch(verifyResponseCustomerProfileSurvey(summary.id, hostingId)).then(() => {
          dispatch(skipCustomerSurvey(false));
        });
      }

      if (!hasSharedBoarding) {
        dispatch(skipCustomerSurvey(true));
      }
    }
  }, [websiteBuilderDomains.products, summary.id]);

  return (
    <div className="App">
      <Notifications notifications={notifications} />
      {(shouldShowMain) ? (
        <Route path="/" component={Main} />
      ) : (
        <Loader />
      )}
      {!IS_PROD && <AppVersion />}
      <Notifier />
      <VideoClasses />
      <ChatButton />
    </div>
  );
};

export default withI18n()(Pages);
