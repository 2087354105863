import React from 'react';
import { isBRServer } from '@/utils/Validators/validation';
import * as Styles from './AddressFields.styles';
import {
  IconCheck, IconFilledInfo, Input, Link, Select, Tooltip,
} from 'gatorcomponents';

export const AddressFields = ({
  myDataLocale,
  handleFormChange,
  formData,
  countriesSelectOptions,
  validateField,
  urlGetCep,
  findMyCepClick,
  postalCodeLength,
  brand,
}) => (
  <>
    <Styles.Title>{myDataLocale.address}</Styles.Title>
    <Styles.Row>
      <Styles.InputWrapper>
        <Styles.SelectWrapper>
          <Select
            id="country"
            name="country"
            onClickSelect={fieldData => handleFormChange('country', fieldData.value)}
            label={myDataLocale.form.countryLabel}
            value={formData.country.value.name}
            placeholder={myDataLocale.form.countryPlaceholder}
            testId="country"
            options={countriesSelectOptions}
            withScroll
            onBlur={({ name, value }) => validateField(name, value)}
            errorMessage={formData.country.error}
          />
        </Styles.SelectWrapper>
      </Styles.InputWrapper>
      <Styles.InputWrapper hasError={!!formData.postalCode.error}>
        {isBRServer && urlGetCep && <Link onClick={findMyCepClick} href={urlGetCep} target="_blank" text={myDataLocale.findMyCep} />}
        <Input
          id="postalCode"
          name="postalCode"
          onChange={fieldData => handleFormChange('postalCode', fieldData.currentTarget.value)}
          label={myDataLocale.form.postalCodeLabel}
          value={formData.postalCode.value}
          placeholder={myDataLocale.form.postalCodePlaceholder}
          testId="postalCode"
          onBlur={({ name, value }) => validateField(name, value)}
          maxLength={postalCodeLength[brand]}
          errorMessage={formData.postalCode.error}
        />
      </Styles.InputWrapper>
    </Styles.Row>

    <Input
      id="address"
      name="address"
      onChange={fieldData => handleFormChange('address', fieldData.currentTarget.value)}
      label={myDataLocale.form.addressLabel}
      value={formData.address.value}
      placeholder={myDataLocale.form.addressPlaceholder}
      testId="address"
      readOnly={isBRServer}
      onBlur={({ name, value }) => validateField(name, value)}
      errorMessage={formData.address.error}
    />

    <Styles.Row>
      <Styles.InputWrapper>
        <Input
          id="numberAndComplement"
          name="numberAndComplement"
          onChange={fieldData => handleFormChange('numberAndComplement', fieldData.currentTarget.value)}
          label={myDataLocale.form.numberAndComplementLabel}
          value={formData.numberAndComplement.value}
          placeholder={myDataLocale.form.numberAndComplementPlaceholder}
          testId="numberAndComplement"
          onBlur={({ name, value }) => validateField(name, value)}
          errorMessage={formData.numberAndComplement.error}
        />
      </Styles.InputWrapper>
      <Styles.InputWrapper>
        <Input
          id="neighborhood"
          name="neighborhood"
          onChange={fieldData => handleFormChange('neighborhood', fieldData.currentTarget.value)}
          label={myDataLocale.form.neighborhoodLabel}
          value={formData.neighborhood.value}
          placeholder={myDataLocale.form.neighborhoodPlaceholder}
          testId="neighborhood"
          readOnly={isBRServer}
          onBlur={({ name, value }) => validateField(name, value)}
          errorMessage={formData.neighborhood.error}
        />
      </Styles.InputWrapper>
    </Styles.Row>

    <Styles.Row>
      <Styles.InputWrapper>
        <Input
          id="state"
          name="state"
          onChange={fieldData => handleFormChange('state', fieldData.currentTarget.value)}
          label={myDataLocale.form.stateLabel}
          value={formData.state.value.name}
          placeholder={myDataLocale.form.statePlaceholder}
          testId="state"
          readOnly={isBRServer}
          errorMessage={formData.state.error}
        />
      </Styles.InputWrapper>
      <Styles.InputWrapper>
        <Input
          id="city"
          name="city"
          onChange={fieldData => handleFormChange('city', fieldData.currentTarget.value)}
          label={myDataLocale.form.cityLabel}
          value={formData.city.value.name}
          placeholder={myDataLocale.form.cityPlaceholder}
          testId="city"
          readOnly={isBRServer}
          onBlur={({ name, value }) => validateField(name, value)}
          errorMessage={formData.city.error}
        />
      </Styles.InputWrapper>
    </Styles.Row>

    <Styles.PrivacyTitle>{myDataLocale.privacy}</Styles.PrivacyTitle>
    <Styles.Row noWrap margin="-7px 0px 0px 0px">
      <Styles.Label htmlFor="#mailSubscribe" onClick={() => handleFormChange('mailSubscribe', !formData.mailSubscribe)}>
        <Styles.CheckboxWrapper type="button">
          <Styles.CheckboxInput
            id="mailSubscribe"
            type="checkbox"
            checked={formData.mailSubscribe}
            data-testid="mailSubscribe"
            readOnly
          />
          <Styles.Checkbox checked={formData.mailSubscribe}>
            {formData.mailSubscribe && <IconCheck />}
          </Styles.Checkbox>
        </Styles.CheckboxWrapper>
        {myDataLocale.form.mailSubscribeLabel}
      </Styles.Label>
      <Tooltip content={myDataLocale.form.mailSubscribeTooltip} preferredPosition="bottomCenter">
        <IconFilledInfo color="primaryBlue" />
      </Tooltip>
    </Styles.Row>
  </>
);

export default AddressFields;
