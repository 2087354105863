import { CHARLIE_TICKETS_STATUS_V2, CHARLIE_ZENDESK_V2 } from '@/config/GrowthBook/constants';
import useLocale from '@/hooks/useLocale';
import {
  loadClosedTickets, loadPendingTickets, loadPendingTicketsV2, loadClosedTicketsV2,
} from '@/redux/actions/tickets';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const useTickets = ({
  setFilteredTickets,
  ticketsReducer,
}) => {
  const { ticket: ticketLocale } = useLocale();
  const dispatch = useDispatch();
  const newStatus = useFeatureIsOn(CHARLIE_TICKETS_STATUS_V2);
  const enableZendeskV2 = useFeatureIsOn(CHARLIE_ZENDESK_V2);

  const defaultFilterChips = !newStatus ? [
    {
      id: 'all',
      label: ticketLocale.listPage.tickets.filter.chips.all,
      value: 'all',
      checked: true,
    },
    {
      id: 'opened',
      label: ticketLocale.listPage.tickets.filter.chips.opened,
      value: 'opened',
      checked: false,
    },
    {
      id: 'closed',
      label: ticketLocale.listPage.tickets.filter.chips.closed,
      value: 'closed',
      checked: false,
    },
  ] : [
    {
      id: 'all',
      label: ticketLocale.listPage.tickets.filter.chips.all,
      value: 'all',
      checked: true,
    },
    {
      id: 'new',
      label: ticketLocale.listPage.tickets.filter.chips.new,
      value: 'new',
      checked: false,
    },
    {
      id: 'open',
      label: ticketLocale.listPage.tickets.filter.chips.open,
      value: 'open',
      checked: false,
    },
    {
      id: 'pending',
      label: ticketLocale.listPage.tickets.filter.chips.pending,
      value: 'pending',
      checked: false,
    },
    {
      id: 'solved',
      label: ticketLocale.listPage.tickets.filter.chips.solved,
      value: 'solved',
      checked: false,
    },
    {
      id: 'closed',
      label: ticketLocale.listPage.tickets.filter.chips.closed,
      value: 'closed',
      checked: false,
    },
  ];

  const [tickets, setTickets] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(defaultFilterChips);
  const [totalTickets, setTotalTickets] = useState(defaultFilterChips.length);
  const [filterTyped, setFilterTyped] = useState('');
  const [page, setPage] = useState(1);
  const ticketsPerPage = 10;
  const [pendingAmount, setPendingAmount] = useState(0);

  const requestTickets = useCallback((isToUpdateAlerts) => {
    dispatch(loadPendingTickets(isToUpdateAlerts));
    dispatch(loadClosedTickets());
  }, [dispatch]);

  const requestTicketsV2 = useCallback(() => {
    if (enableZendeskV2) {
      dispatch(loadPendingTicketsV2());
      dispatch(loadClosedTicketsV2());
    }
  }, [dispatch, enableZendeskV2]);

  const getPaginatedArray = useCallback(array => (array.length
    ? array.slice(0, (page * ticketsPerPage))
    : array), [page]);

  const sortByUpdated = useCallback(array => array.sort((a, b) => {
    if (a.updated_at > b.updated_at) { return -1; }
    if (a.updated_at < b.updated_at) { return 1; }
    return 0;
  }), []);

  const filterTickets = useCallback(() => {
    let newFilteredTickets = [...tickets];

    if (filteredOptions) {
      const activeOption = filteredOptions.find(option => option.checked);
      if (activeOption && activeOption.id !== 'all') {
        newFilteredTickets = newFilteredTickets.filter(
          ticket => ticket.status.toLowerCase() === activeOption.value,
        );
      }
    }

    if (filterTyped) {
      newFilteredTickets = newFilteredTickets.filter(
        ticket => ticket.subject && ticket.subject.toLowerCase()
          .includes(filterTyped.toLowerCase()),
      );
    }

    setTotalTickets(newFilteredTickets.length);
    let paginatedArray = getPaginatedArray(newFilteredTickets);
    paginatedArray = sortByUpdated(paginatedArray);
    setFilteredTickets(paginatedArray);
  }, [tickets, filteredOptions, filterTyped, setFilteredTickets, getPaginatedArray, sortByUpdated]);

  const setTicketsArray = useCallback(() => {
    if (!ticketsReducer.loading) {
      const ticketsArray = new Set();
      ticketsReducer.pending.ticketsV2.forEach((ticket) => {
        const newTicket = { ...ticket };
        if (!newStatus) {
          newTicket.status = 'opened';
        }
        ticketsArray.add({
          ...newTicket,
          v2: true,
        });
      });
      ticketsReducer.pending.tickets.forEach((ticket) => {
        const newTicket = { ...ticket };
        if (!newStatus) {
          newTicket.status = 'opened';
        }
        ticketsArray.add({
          ...newTicket,
          v2: false,
        });
      });

      ticketsReducer.closed.ticketsV2.forEach((ticket) => {
        const newTicket = { ...ticket };
        newTicket.status = 'closed';
        ticketsArray.add({
          ...newTicket,
          v2: true,
        });
      });
      ticketsReducer.closed.tickets.forEach((ticket) => {
        const newTicket = { ...ticket };
        newTicket.status = 'closed';
        ticketsArray.add({
          ...newTicket,
          v2: false,
        });
      });

      const newTicketsArray = Array.from(ticketsArray);
      setTickets(newTicketsArray);
      let paginatedArray = getPaginatedArray(newTicketsArray);
      paginatedArray = sortByUpdated(paginatedArray);
      setFilteredTickets(paginatedArray);
      setPendingAmount((ticketsReducer.pending.tickets.length + ticketsReducer.pending.ticketsV2.length));
    }
  }, [ticketsReducer, setFilteredTickets, getPaginatedArray, sortByUpdated, newStatus]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const useTicketsData = {
    defaultFilterChips,
    requestTickets,
    requestTicketsV2,
    filterTickets,
    filteredOptions,
    setFilteredOptions,
    filterTyped,
    setFilterTyped,
    setTicketsArray,
    page,
    pendingAmount,
    loadMore,
    totalTickets,
  };

  return useTicketsData;
};

export default useTickets;
