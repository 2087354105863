import React, { useContext } from 'react';
import { withI18n } from 'react-i18next';
import {
  withStyles, TextField,
} from '@material-ui/core';
import { DNSContext } from '@/contexts/DNS/context';
import IntegerMaskedInput from '@/components/Fields/InputMasks/IntegerMaskedInput';
import ZoneManagerSubdomainInputMask from '@/components/Fields/InputMasks/ZoneManagerSubdomainInputMask';
import styles from './styles';


const TypeCAAValueField = ({
  t,
  classes, formData, onHandleChangeInput,
  isInvalidPriority, isInvalidPort, isInvalidWeight, isInvalidDestination,
}) => {
  const { loading } = useContext(DNSContext);

  return (
    <div className={classes.caaBlockWrapper}>

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeSrvPriorityField')}
        value={formData.priority}
        onChange={e => onHandleChangeInput(e.target)}
        name="priority"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputComponent: IntegerMaskedInput,
          inputProps: {
            maxLength: 5,
            'data-testid': 'zoneManagerSrvPriorityField',
          },
        }}
        error={isInvalidPriority}
        helperText={isInvalidPriority ? t('dns.zoneManager.addRecord.form.helperText.srvIntegerHelper') : null}
      />

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeSrvPortField')}
        value={formData.port}
        onChange={e => onHandleChangeInput(e.target)}
        name="port"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputComponent: IntegerMaskedInput,
          inputProps: {
            maxLength: 5,
            'data-testid': 'zoneManagerSrvPortField',
          },
        }}
        error={isInvalidPort}
        helperText={isInvalidPort ? t('dns.zoneManager.addRecord.form.helperText.srvIntegerHelper') : null}
      />

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeSrvWeightField')}
        value={formData.weight}
        onChange={e => onHandleChangeInput(e.target)}
        name="weight"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputComponent: IntegerMaskedInput,
          inputProps: {
            maxLength: 5,
            'data-testid': 'zoneManagerSrvWeightField',
          },
        }}
        error={isInvalidWeight}
        helperText={isInvalidWeight ? t('dns.zoneManager.addRecord.form.helperText.srvIntegerHelper') : null}
      />

      <TextField
        disabled={loading}
        label={t('dns.zoneManager.addRecord.form.label.typeSrvDestinationField')}
        value={formData.target}
        onChange={e => onHandleChangeInput(e.target)}
        name="target"
        required
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputComponent: ZoneManagerSubdomainInputMask,
          inputProps: {
            maxLength: formData.target[formData.target.length - 1] === '.' ? 254 : 253,
            'data-testid': 'zoneManagerSrvDestinationField',
          },
        }}
        error={isInvalidDestination}
        helperText={isInvalidDestination ? t('dns.zoneManager.addRecord.form.helperText.srvDomainHelper') : null}
      />

    </div>
  );
};

export default (withI18n()(withStyles(styles)(TypeCAAValueField)));
