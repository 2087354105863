import { COUNTRY } from '@/config';

const PASSWORD_GENERATOR_URL_BY_BRAND = {
  br: 'https://www.hostgator.com.br/gerador-de-senhas',
  mx: 'https://www.hostgator.mx/generador-de-contrasenas',
  co: 'https://www.hostgator.co/generador-de-contrasenas',
  cl: 'https://www.hostgator.cl/generador-de-contrasenas',
};

export const PASSWORD_GENERATOR_URL = PASSWORD_GENERATOR_URL_BY_BRAND[COUNTRY];
