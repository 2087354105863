import linkNaBioBR from './linkNaBio.br';
import linkNaBioMX from './linkNaBio.mx';
import linkNaBioCL from './linkNaBio.cl';
import linkNaBioCO from './linkNaBio.co';

const linkNaBio = {
  BR: linkNaBioBR,
  MX: linkNaBioMX,
  CL: linkNaBioCL,
  CO: linkNaBioCO,
};

export default linkNaBio;
