
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, InputAdornment, IconButton, withStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import styles from './style';

const InputField = ({
  onChange, value, classes, label, className, icon, inputRef, password, required, error, ftp, defaultValue,
}) => {
  const [showPassword, setShowPassword] = useState(!password);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const setAdornment = () => (password ? (
    <InputAdornment position="end">
      <IconButton
        aria-label="Toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  ) : (<InputAdornment position="end"><div>{icon}</div></InputAdornment>));

  const setFtpAdornment = () => (ftp && (
  <InputAdornment position="start">
    <div>ftp://</div>
  </InputAdornment>
  ));

  return (
    <TextField
      label={label}
      type={showPassword ? 'text' : 'password'}
      defaultValue={defaultValue}
      id="outlined-bare"
      className={`${classes.input} ${className}`}
      margin="normal"
      variant="outlined"
      onChange={onChange}
      value={value}
      required={required}
      error={error}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        endAdornment: setAdornment(),
        startAdornment: setFtpAdornment(),
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
        inputProps: {
          ref: inputRef,
          'data-testid': 'input-field',
        },
      }}
    />
  );
};


InputField.propTypes = {
  password: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  ftp: PropTypes.bool,
  defaultValue: PropTypes.string,
};

InputField.defaultProps = {
  password: false,
  required: false,
  error: false,
  ftp: false,
  defaultValue: '',
};

export default withStyles(styles)(InputField);
