import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Collapse, Card,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { withI18n } from 'react-i18next';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import SelectField from '@/components/Fields/SelectField';
import InputField from '@/components/Fields/InputField';
import styles from './styles';


const FilterInvoices = ({
  t,
  classes,
  mobileExpanded,
  selectFilters,
  type,
  filterValues,
  onInputChange,
  total,
}) => {
  const statusOptions = type === 'Unpaid' ? selectFilters.unpaidStatus : selectFilters.cancelledStatus;
  const [expand, setExpand] = useState(false);

  const isMobile = useMediaQuery('(max-width:1060px)');

  const handleExpandMobile = () => {
    setExpand(!expand);
  };

  if (isMobile && total < 6) {
    return null;
  }

  return (
    <Card className={classes.card} elevation={1}>
      {!isMobile && (
        <Typography variant="h6" className={classes.listTitle}>{t('lists.invoices')}</Typography>
      )}
      {total > 0 && (
        <div className={classes.filterContainer}>
          <>
            <Typography variant="h6" className={classes.filterTitle}>{t('filter.title')}</Typography>
            <Collapse in={expand || !isMobile} className={classes.collapseWrapper}>
              <div className={classes.root}>
                {type !== 'Paid' && (
                <SelectField
                  classes={{ formControl: classes.select }}
                  filterOptions={statusOptions}
                  selectType="invoices"
                  selectValue={filterValues.statusValue}
                  inputLabel={t('filter.status')}
                  menuItemDefaultText={t('invoices.allStatus')}
                  labelWidth={48}
                  className={classes.selectField}
                  handleChangeSelectValue={onInputChange('statusValue')}
                />
                )}
                <InputField
                  className={classes.inputField}
                  value={filterValues.invoiceId}
                  label={t('filter.invoiceNumber')}
                  onChange={onInputChange('invoiceId')}
                />
              </div>
            </Collapse>

            {isMobile && (
            <div>
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: expand,
                  [classes.expandMobile]: isMobile,
                })}
                onClick={() => handleExpandMobile()}
              >
                {mobileExpanded ? <ExpandMore className={classes.arrow} /> : <ExpandLess className={classes.arrow} />}
              </IconButton>
            </div>
            )}
          </>
        </div>
      )}
    </Card>
  );
};

FilterInvoices.propTypes = {
  mobileExpanded: PropTypes.bool,
  filterValues: PropTypes.object.isRequired,
  type: PropTypes.string,
  selectFilters: PropTypes.object,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    cardFilter: PropTypes.string,
    cardFilterContent: PropTypes.string,
    cardFilterFlex: PropTypes.string,
    cardFilterTitle: PropTypes.string,
    top: PropTypes.string,
    expand: PropTypes.string,
    expandOpen: PropTypes.string,
    containerCollapse: PropTypes.string,
  }).isRequired,
};

FilterInvoices.defaultProps = {
  mobileExpanded: true,
};

export default withI18n()(withStyles(styles)(FilterInvoices));
