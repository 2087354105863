import styled, { css } from 'styled-components';

const contentMaxWidth = '490px';

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 24px 0px 0px;
    max-width: 769px;
  `}
`;

export const PreviewDescription = styled(Description)`
  margin: 24px 0px 16px
`;

export const SmallTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 32px 0px 8px;
  `}
`;

export const SealLink = styled.a``;

export const SelectsWrapper = styled.div`
  ${({ expanded }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: ${expanded ? '1000px' : '0px'};
    max-width: ${contentMaxWidth};
    overflow: ${expanded ? 'unset' : 'hidden'};
    width: 100%;
    transition: max-height 300ms ease-in-out;

    textarea {
      word-break: break-all;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    max-width: $483px;
    width: 100%;

    @media (max-width:${theme.v2.breakpoints.sm}){
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;

      > button {
        max-width: fit-content;
      }
    }
  `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin: 24px 0px;
  max-width: $483px;
  width: 100%;
`;

export const Form = styled.form``;

export const BannerWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    margin: 32px 0px 0px;
    padding: 32px 0px 0px;
  `}
`;

export const SkeletonRow = styled.div`
    ${({ top, row }) => css`
      display: flex;
      flex-direction: ${row ? 'row' : 'column'};
      gap: ${row ? '16px' : '8px'};
      margin: ${top} 0px 0px 0px;
    `}
`;
