export const profileIds = {
  myData: 'myData',
  changePassword: 'changePassword',
  accountSecurity: 'accountSecurity',
  serviceStatus: 'serviceStatus',
  manageUsers: 'manageUsers',
  logout: 'logout',
};

export const financialIds = {
  invoices: 'invoices',
  subscriptions: 'subscriptions',
  creditCard: 'creditCard',
};


export const supportIds = {
  support: 'support',
};
