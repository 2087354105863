import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class FeedbackNewSite extends React.PureComponent {
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={`${classes.icon} ${className}`} viewBox="0 0 512 512">
        <path
          className={classes.st0}
          d="M480.4,305.8H174.2c-13.7,0-24.8-11.1-24.8-24.8V24.8c0-13.7,11.1-24.8,24.8-24.8h306.2
          c13.7,0,24.8,11.1,24.8,24.8V281C505.2,294.7,494.1,305.8,480.4,305.8z"
        />
        <path
          className={classes.st1}
          d="M203,148.6c-2.1,0-4.1-0.8-5.6-2.4c-2.9-3.1-2.8-8,0.4-10.9l51.9-48.5c2.4-2.3,6-2.7,8.9-1.2l50.7,27.2
          L349.2,64c1.4-1.7,3.5-2.8,5.7-2.8c2.2-0.1,4.4,0.8,5.9,2.4l36.8,38.9l48-59.5c2.7-3.3,7.5-3.8,10.9-1.2c3.3,2.7,3.8,7.5,1.2,10.9
          L404.1,119c-1.4,1.7-3.5,2.8-5.7,2.9c-2.2,0.1-4.4-0.8-5.9-2.4l-36.9-38.9l-38.4,47c-2.3,2.9-6.4,3.7-9.6,1.9l-51.5-27.6l-47.8,44.7
          C206.8,148,204.9,148.6,203,148.6z"
        />
        <g>
          <path className={classes.st2} d="M269,92.4c0,7.8-6.3,14.2-14.2,14.2s-14.2-6.3-14.2-14.2s6.3-14.2,14.2-14.2S269,84.6,269,92.4z" />
          <path
            className={classes.st2}
            d="M325.4,122.7c0,7.8-6.3,14.2-14.2,14.2s-14.2-6.3-14.2-14.2c0-7.8,6.3-14.2,14.2-14.2S325.4,114.9,325.4,122.7
            z"
          />
          <path className={classes.st2} d="M369.3,68.8c0,7.8-6.3,14.2-14.2,14.2S341,76.7,341,68.8s6.3-14.2,14.2-14.2S369.3,61,369.3,68.8z" />
          <path
            className={classes.st2}
            d="M412.2,114.1c0,7.8-6.3,14.2-14.2,14.2s-14.2-6.3-14.2-14.2c0-7.8,6.3-14.2,14.2-14.2S412.2,106.3,412.2,114.1
            z"
          />
          <path
            className={classes.st2}
            d="M465.7,48c0,7.8-6.3,14.2-14.2,14.2c-7.8,0-14.2-6.3-14.2-14.2c0-7.8,6.3-14.2,14.2-14.2
            C459.4,33.9,465.7,40.2,465.7,48z"
          />
        </g>
        <path
          className={classes.st3}
          d="M249.2,131.1v3.1c0,17.4-14.1,31.5-31.5,31.5h-87.5c-5.8,0-10.4,4.7-10.4,10.4v98.5H37v31.3
          c-17.1,0-31-13.9-31-31V168.6c0-17.4,11.1-33,27.6-38.5l13.2-4.4c3-1,5.6-2.6,7.8-4.6c4.3-4,6.9-9.7,6.9-15.9v-21h36.1v21
          c0,6.1,2.6,11,6.7,14.9c7,6.7,18.3,10,27.2,10h116.7C248.8,130.1,249.2,130.5,249.2,131.1z"
        />
        <path className={classes.st4} d="M97.6,84.1v14.6l-8.2,5c-6,3.7-13.6,3.7-19.6,0l-8.2-5V84.1H97.6z" />
        <path
          className={classes.st3}
          d="M114.8,21.8v43.8c0,6.6-3.4,12.7-9.1,16.2l-16.3,10c-6,3.7-13.6,3.7-19.6,0l-16.3-10c-5.6-3.4-9.1-9.6-9.1-16.2
          V21.8C44.3,9.8,54,0,66.1,0h27C105,0,114.8,9.8,114.8,21.8z"
        />
        <path
          className={classes.st4}
          d="M93,0H76c12,0,21.8,9.8,21.8,21.8v43.8c0,6.6-3.4,12.7-9.1,16.2l-16.3,10c-0.4,0.3-0.9,0.5-1.3,0.7
          c5.8,2.9,12.7,2.7,18.3-0.7l16.3-10c5.6-3.4,9.1-9.6,9.1-16.2V21.8C114.8,9.8,105,0,93,0z"
        />
        <path className={classes.st5} d="M114.8,21.8v20.3L68,27.1L44.3,39.5V21.8C44.3,9.8,54,0,66.1,0h27C105,0,114.8,9.8,114.8,21.8z" />
        <path className={classes.st6} d="M93,0H76c12,0,21.8,9.8,21.8,21.8v14.9l17,5.5V21.8C114.8,9.8,105,0,93,0z" />
        <path
          className={classes.st7}
          d="M217.2,130.1v35.6h-86.9c-5.8,0-10.4,4.7-10.4,10.4v98.5H6V168.6c0-17.4,11.1-33,27.6-38.5l13.2-4.4
          c3-1,5.6-2.6,7.8-4.6c7.1,2.6,15.4,4.3,24.8,4.1c9.3-0.2,17.4-2.2,24.2-5l0.7-0.1c7,6.7,18.3,10,27.2,10H217.2z"
        />
        <path
          className={classes.st2}
          d="M78.7,125.2L63,207.2c-0.3,1.3,0.1,2.7,1,3.6l11.7,13c1.6,1.8,4.5,1.9,6.2,0.2l13-13.1c1-1,1.4-2.5,1.1-3.9
          L78.7,125.2z"
        />
        <path className={classes.st5} d="M119.8,274.5v206.3c0,2.3-1.9,4.2-4.2,4.2H41.2c-2.3,0-4.2-1.9-4.2-4.2V274.5H119.8z" />
        <path className={classes.st6} d="M37,274.5h82.8v17.3H37V274.5z" />
        <path className={classes.st6} d="M86.1,313.7v171.4H70.7V313.7c0-4.3,3.5-7.7,7.7-7.7C82.7,306,86.1,309.4,86.1,313.7z" />
        <path
          className={classes.st6}
          d="M76.6,512H38.9c-1,0-1.9-0.8-1.9-1.9v-4.4c0-11.4,9.3-20.7,20.7-20.7c11.4,0,20.7,9.3,20.7,20.7v4.4
          C78.4,511.2,77.6,512,76.6,512z"
        />
        <path
          className={classes.st6}
          d="M117.9,512H80.3c-1,0-1.9-0.8-1.9-1.9v-4.4c0-11.4,9.3-20.7,20.7-20.7c11.4,0,20.7,9.3,20.7,20.7v4.4
          C119.8,511.2,119,512,117.9,512z"
        />
        <path className={classes.st2} d="M44.7,179.9v94.7H29.3v-94.7c0-4.3,3.5-7.7,7.7-7.7S44.7,175.6,44.7,179.9z" />
        <path
          className={classes.st8}
          d="M456.3,212.7H192.5c-4.3,0-7.7-3.5-7.7-7.7s3.5-7.7,7.7-7.7h263.7c4.3,0,7.7,3.5,7.7,7.7
          S460.5,212.7,456.3,212.7z"
        />
        <path
          className={classes.st8}
          d="M332.2,248H192.5c-4.3,0-7.7-3.5-7.7-7.7c0-4.3,3.5-7.7,7.7-7.7h139.7c4.3,0,7.7,3.5,7.7,7.7
          C339.9,244.5,336.5,248,332.2,248z"
        />
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(FeedbackNewSite);
