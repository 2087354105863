import React from 'react';
import PropType from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';


const TicketHeaderId = ({ id, classes }) => (
  <span className={classes.id}>{id}</span>
);

TicketHeaderId.propType = {
  id: PropType.number.isRequired,
};

export default withStyles(styles)(TicketHeaderId);
