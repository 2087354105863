import billing from './billing';
import domains from './domains';
import routes from './routes';
import sites from './sites';
import sitelock from './sitelock';
import phoneValidation from './phoneValidation';
import emailValidation from './emailValidation';
import asaas from './asaas';
import menu from './menu';
import title from './title';
import linkNaBio from './linkNaBio';

export default {
  appTitle: 'Portal do Cliente',
  services: 'Serviços',
  activeSsl: 'Ativar Certificado SSL',
  more: 'Ver mais',
  morev2: 'Ver mais conteúdos na Central de Ajuda',
  showInvoices: 'Ver Faturas',
  nextDueDate: 'Vencimento',
  autoRenewal: 'Renovação Automática',
  no: 'Não',
  yes: 'Sim',
  registerDate: 'Data de Registro',
  status: 'Status',
  changeDNS: 'Alterar DNS',
  notifyFailMessage: 'Falha na operação solicitada',
  notifySuccessMessage: 'Operação realizada com sucesso',
  manageSubscriptionMessage01: 'Agora as informações da sua assinatura, como pagamento e cancelamento estarão disponíveis através do botão ',
  manageSubscriptionMessage02: 'Gerenciar Assinatura',
  manageSubscriptionMessage03: ' localizado acima.',
  dialogRegisterAndUseNewCard: 'Utilizar um Novo Cartão',
  useExistingCard: 'Utilizar Cartão Existente',
  useNewCard: 'Utilizar um Novo Cartão',
  modalbetaDescription: 'Este Portal do Cliente ainda está na versão beta, logo nem todas as páginas estão 100%. Ao acessar determinadas funcionalidades você será redirecionado para a nossa versão antiga.',
  actions: {
    ssl: {
      active: 'Certificado SSL',
      inactive: 'Certificado SSL',
    },
    webpresence: {
      active: 'Pagina Fácil',
      inactive: 'Criar Página Fácil',
    },
    webmail: {
      active: 'E-mail',
      inactive: 'E-mail',
    },
    cpanel: {
      active: 'cPanel',
      inactive: 'cPanel',
    },
    sitelock: {
      active: 'SiteLock',
      inactive: 'SiteLock',
    },
    websitebuilder: {
      active: 'Editar Site',
      inactive: 'Ativar Criador de Sites',
      access: 'Acessar Criador de Sites',
      remove: 'Excluir Site',
    },
    'websitebuilder-active-edit': {
      active: 'Editar Site',
      inactive: 'Ativar Criador de Sites',
    },
    'websitebuilder-on-shared-plans': {
      active: 'Editar Site',
    },
    'manage-site': {
      active: 'Gerenciar Criador de Sites',
    },
    'create-site': {
      active: 'Criar Site',
    },
    'cpanel-pwd-edit': {
      active: 'Alterar senha do cPanel',
    },
    'plan-switch': {
      active: 'Alterar Plano / Ciclo',
    },
    'ecommerce-plus-cycle-change': {
      active: 'Alterar ciclo do E-commerce Plus',
    },
    'old-reseller-plan-switch': {
      active: 'Escolher Novo Plano',
    },
    'product-details': {
      active: 'Detalhes de produtos',
    },
    whm: {
      active: 'Painel WHM',
    },
    'whm-reseller': {
      access: 'Painel WHM',
      active: 'Painel WHM',
    },
    'whm-plesk': {
      active: 'Painel Plesk',
    },
    'edit-dns': {
      active: 'Configurar Domínio',
    },
    wizard: {
      active: 'Configurar Domínio',
    },
    wphosting: {
      active: 'WordPress',
    },
    domainLink: {
      active: 'Acessar Site',
    },
    codeguard: {
      active: 'Acessar CodeGuard',
    },
    'cpanel-email': {
      active: 'E-mails',
    },
    'cpanel-addon': {
      active: 'Domínios Adicionais',
    },
    'cpanel-backup': {
      active: 'Backup',
    },
    cloudflare: {
      active: 'Desempenho de sites',
    },
    'cpanel-dns': {
      active: 'Zona DNS',
    },
    addEmail: {
      active: 'E-mails',
    },
    'contact-info': {
      active: 'Editar Infos Contato',
    },
    'domain-renewal': {
      active: 'Renovar Domínio',
    },
    'domain-protected-id': {
      active: 'Protect ID',
    },
    'domain-register-lock': {
      active: 'Trava de Registro',
    },
    'vps-reboot': {
      active: 'Reboot',
    },
    dataAccess: {
      active: 'Trocar Senha de Acesso',
    },
    'sites-cloudflare': 'Desempenho de sites',
    'sites-gerenciar': 'Gerenciar Hospedagem',
    'sites-backup': 'Backup',
    'sites-exclude': 'Desativar Criador de Sites',
    'sites-easyPage': 'Página Fácil',
    'sites-signatures': 'Ver assinaturas vinculadas ao site',
    'sites-ssl': 'Certificado SSL',
    'sites-sitelock': 'Detectar ameaças - SiteLock',
    'sites-actions-wordpress': 'Acessar WordPress',
    'sites-actions-sitebuilder': 'Acessar Criador de Sites',
    'sites-actions-reseller': 'Painel WHM',
  },
  hg_credits: 'Créditos HostGator',
  creditCard: '1x Cartão de crédito',
  braspag: '1x Cartão de crédito',
  braspag_credit: 'Cartão de crédito',
  braspag_credit_1x: '1x Cartão de crédito sem juros',
  braspag_credit_2x: '2x Cartão de crédito',
  braspag_credit_3x: '3x Cartão de crédito',
  braspag_credit_4x: '4x Cartão de crédito',
  braspag_credit_5x: '5x Cartão de crédito',
  braspag_credit_6x: '6x Cartão de crédito',
  braspag_credit_7x: '7x Cartão de crédito',
  braspag_credit_8x: '8x Cartão de crédito',
  braspag_credit_9x: '9x Cartão de crédito',
  braspag_credit_10x: '10x Cartão de crédito',
  braspag_credit_11x: '11x Cartão de crédito',
  braspag_credit_12x: '12x Cartão de crédito',
  cieloparc2: '2x Cartão de crédito',
  cieloparc3: '3x Cartão de crédito',
  cieloparc4: '4x Cartão de crédito',
  cieloparc5: '5x Cartão de crédito',
  cieloparc6: '6x Cartão de crédito',
  gp_boleto: 'Boleto Bancário',
  gp_paypal: 'PayPal',
  gp_pix: 'Pix',
  boleto: 'Boleto Bancário',
  paypal: 'PayPal',
  hgcielo2: 'Desativado',
  invoiceAction: {
    creditCard: 'Pagar com Cartão',
    braspag: 'Pagar com Cartão',
    braspag_credit_1x: 'Selecionar Cartão',
    braspag_credit_2x: 'Pagar com Cartão',
    braspag_credit_3x: 'Pagar com Cartão',
    braspag_credit_4x: 'Pagar com Cartão',
    braspag_credit_5x: 'Pagar com Cartão',
    braspag_credit_6x: 'Pagar com Cartão',
    braspag_credit_7x: 'Pagar com Cartão',
    braspag_credit_8x: 'Pagar com Cartão',
    braspag_credit_9x: 'Pagar com Cartão',
    braspag_credit_10x: 'Pagar com Cartão',
    braspag_credit_11x: 'Pagar com Cartão',
    braspag_credit_12x: 'Pagar com Cartão',
    cieloparc2: 'Pagar com Cartão',
    cieloparc3: 'Pagar com Cartão',
    cieloparc4: 'Pagar com Cartão',
    cieloparc5: 'Pagar com Cartão',
    cieloparc6: 'Pagar com Cartão',
    gp_boleto: 'Gerar Boleto',
    gp_paypal: 'Pagar com Paypal',
    boleto: 'Gerar Boleto',
    paypal: 'Pagar no Paypal',
    pay_invoice: 'Pagar Fatura',
  },
  month: 'Mês',
  year: 'Ano',
  save: 'Salvar',
  cancel: 'Cancelar',
  backToList: 'Voltar para a lista',
  clean: 'Limpar',
  removeCreditCard: 'Excluir Cartão de Crédito',
  creditCardExpDate: 'Vence em',
  savedCreditCard: 'Cartão Cadastrado',
  createCreditCard: 'Cadastrar Cartão de Crédito',
  saveCreditCard: 'Cadastrar',
  errorSavingCreditCard: 'Erro ao salvar cartão de crédito',
  showReceiptAction: 'Ver nota fiscal',
  payInvoiceAction: 'Pagar Fatura',
  payExpiredInvoiceAction: 'Pagar segunda via',
  unpaidInvoicesEmptyPageSubtitle: 'No momento você não tem faturas pendentes :)',
  paidInvoicesEmptyPageSubtitle: 'Sem faturas pagas até o momento :)',
  processingSelectedPaymentPleaseWait: 'Processando o método de pagamento escolhido. Por favor, não feche essa janela.',
  selectCreditCardInstallments: 'Selecione o número de parcelas',
  creditCardInstallmentsQuestion: 'Em quantas parcelas deseja pagar?',
  creditCardInstallmentsTooltipDescription: '<1>As opções de parcelamento são definidas de acordo com o valor do produto e seu ciclo de pagamento. Veja mais em:</1> <2>Regras de parcelamento</2>',
  creditCardInstallmentsTooltipDescriptionLink: 'https://suporte.hostgator.com.br/hc/pt-br/articles/4757807758235',
  creditCardInstallmentLabel: '{{installments}}x de {{installmentValue}}{{fees}}{{totalValue}}',
  creditCardInstallmentFees: '(com juros)',
  creditCardInstallmentsPopupKnowMoreURL: 'https://suporte.hostgator.com.br/hc/pt-br/articles/4757495899931',
  pixOfferPopupCloseLabel: '',
  pixOfferPopupNew: 'NOVO',
  pixOfferPopupDescription: 'Pagando com Pix, sua compra é aprovada na hora e a ativação de seu produto é imediata.',
  pixOfferPopupKnowMoreLink: 'Veja mais sobre',
  pixOfferPopupTitle: 'Agora, você pode pagar sua fatura com Pix!',
  pixOfferPopupKnowMoreURL: ' https://suporte.hostgator.com.br/hc/pt-br/articles/10823239875995',
  cancelledInvoicesEmptyPageSubtitle: 'Sem faturas canceladas até o momento :)',
  Unpaid: 'Pendente',
  Paid: 'Paga',
  Cancelled: 'Cancelada',
  invoiceDueDateLabel: 'Vencimento',
  invoiceTotalLabel: 'Valor Total',
  selectPaymentMethod: 'Selecione a forma de pagamento',
  domainCyclePayment: 'Ciclo de Pagamento',
  invoicePaymentMethod: 'Forma de Pagamento',
  invoiceDescription: 'Descrição',
  invoiceValue: 'Valor (R$)',
  invoiceCredit: 'Crédito',
  invoiceSubtotal: 'Sub Total',
  invoiceTaxes: 'Taxas',
  invoicePaidValue: 'Já foi Pago',
  invoiceUnpaidValue: 'Saldo à Pagar',
  invoiceTotalUnpaidValue: 'Valor à Pagar',
  invoiceFisrtPayment: 'Valor 1º Pagamento',
  invoiceValueReminiscent: 'Valor',
  invoiceYouHave: 'Você tem',
  invoiceToPayWithTotal: 'fatura(s) para pagar com um total de',
  invoiceStaticMessage: 'Você tem <1>{{totalInvoice}} fatura(s) para pagar</1> com um total de ',
  domainStaticMessage: 'Evite a suspensão temporária do seu site! <1>Clique aqui</1> para confirmar seu endereço de e-mail e evitar a suspensão.',
  domainCritialMessage: 'Você tem um ou mais sites suspensos temporariamente. <1>Clique aqui</1> para confirmar seu endereço de e-mail e reativar seu site.',
  myAccount: 'Minha Conta',
  myAccountAlertMessage: 'As páginas da sua conta (Meus Dados, Alterar Senha, Gerenciar Usuários) ainda estão sendo criadas nesta nova versão. Se quiser alterar algo, clique abaixo :)',
  myAccountAlertAction: 'Ver minha conta',
  domain: 'Domínio',
  Sitelock: 'Sitelock',
  hosting: 'Gerenciar',
  addon: 'Ver mais',
  subdomains: 'Subdomínios',
  pendingStatus: 'Seu site não está disponível no momento. Pague a fatura que está pendente.',
  contactUs_invoice: 'Parar gerar uma 2ª Via da sua fatura, entre em contato com nosso suporte financeiro',
  suspendedStatus: 'Seu site está suspenso no momento. Reative agora e pague a fatura vencida.',
  nfseTitle: 'Notas Fiscais',
  nfseNumber: 'Nº Nota',
  nfseLink: 'Ver Notal Fiscal',
  servername: 'Nome do Servidor',
  server_ips: 'IPs Atribuídos',
  username: 'Nome de usuário',
  access_ssh: 'Acesso via SSH',
  hostnameFtp: 'Hostname FTP',
  temporaryLink: 'Link Temporário',
  host: 'Host',
  port: 'Porta',
  hireDomain: 'Contratar Domínio',
  addSite: 'Adicionar site',
  hireProduct: 'Contratar Produto',
  ErrorSSO: 'Não foi possível acessar. Tente novamente, ou entre em contato com o suporte.',
  redirectPageMessage: {
    WHMPanel: 'Aguarde, estamos direcionando para o Painel WHM...',
    siteBuilder: 'Aguarde, estamos direcionando para o Criador de Sites...',
  },
  noResults: {
    tab: {
      sites: 'Não localizamos nenhum site. <1>Clique aqui</1> para conhecer nossos planos.',
      emails: 'Você ainda não tem nenhuma conta de e-mail criada.',
      host: 'Você não possui uma hospedagem contratada para gerenciar seus emails. Contrate um plano de hospedagem através do <1>nosso site.</1>',
      domains: 'Não localizamos nenhum domínio contratado. <1>Clique aqui</1> para contratar um domínio.',
      products: 'Não localizamos nenhum produto ou serviço contratado. <1>Clique aqui</1> para conhecer nossos serviços.',
    },
    filter: 'Nenhum resultado. Tente novamente :)',
  },
  emails: {
    message: {
      withServer: 'E-mails de planos VPS e Dedicado não são listados abaixo. O gerenciamento é possível apenas através do cPanel.',
    },
    buttonConfirmation: 'SIM, QUERO EXCLUIR',
    storage: 'Armazenamento',
    changeStorage: 'Alterar armazenamento',
    changePassword: 'Alterar Senha',
    deleteAccount: 'Excluir Conta',
    createAccount: 'Criar Conta',
    name: 'Nome do e-mail',
    password: 'Sua senha deve ter:',
    quota: 'Capacidade',
    unlimited: 'Ilimitado',
    unlimitedToolTip: 'Ao definir uma capacidade, assim que chegar ao seu limite você não receberá mais e-mails.',
    createButton: 'Criar Conta de E-mail',
    createButtonAndStay: 'Criar Conta de E-mail e permanecer nesta tela',
    confirmPassword: 'Confirmar senha',
    specialCharacters: 'O nome do e-mail permite apenas ponto, hífen e underline como caracteres especiais.',
    createEmailSuccess: 'Conta de email criada com sucesso! :)',
    createEmailError: 'Ocorreu uma falha na criação da sua conta de e-mail. Tente novamente mais tarde',
    or: 'ou',
    deleteEmailAccount: 'Excluir conta de e-mail',
    confirmationDeleteEmail: 'Você tem certeza que deseja excluir o e-mail',
    seeEmail: 'VER E-MAIL',
    warningDeleteEmail: 'Irá apagar todos os e-mails!',
    alter: 'Alterar',
    passwordPlaceholder: 'Senha',
    alterEmailPassword: 'Alterar senha do e-mail',
    toolTipQuota: 'Ao definir uma capacidade, assim que chegar ao seu limite você não receberá mais e-mails.',
    change: 'ALTERAR',
    typeQuotaStorage: 'Digite a capacidade de armazenamento que ficará disponível para o email <1>{{ emailAddress }}</1> ou defina que ele será ilimitado:',
  },
  cardAlerts: {
    suspendedPlan: 'Produto suspenso. Reative agora e pague a fatura vencida.',
    suspendedSite: 'Site suspenso. Reative agora e pague a fatura vencida.',
    pendingPlan: 'Produto indisponível. Pague a fatura para ativar seu produto.',
    pendingSitePlan: 'Site indisponível. Pague a fatura para ativar seu site.',
    pendingSite: 'Site indisponível. Pague a fatura para ativar seu site.',
    unpaidInvoice: 'Gere uma 2ª via entrando em contato com o suporte financeiro.',
    invoicePhishingCheck: 'Aguarde enquanto processamos o pagamento.',
    cancelledProduct: 'Produto cancelado. Não é possível reativa-lo. Contrate um novo produto através do nosso site.',
    domain: {
      pending: 'Domínio pendente. <1>Efetue o pagamento da fatura em aberto.</1>',
      pendingTransfer: 'Domínio em processo de transferência. Aguarde novas instruções por e-mail.',
      registerError: 'Falha no registro do domínio. Aguarde novas instruções por e-mail.',
      expired: 'Domínio expirado. <1>Efetue o pagamento da fatura em aberto.</1>',
      expiredSimpleMessage: '<0>Seu Domínio </0><1>venceu dia {{data}}</1>. <2>Para maiores informações entre em contato com o suporte.</2>',
      cancelled: 'Domínio cancelado. Não é possível reativá-lo. Contrate um novo domínio através do <1>nosso site.</1>',
      emailConfirmError: '<0>Ocorreu um erro no envio do e-mail tente novamente mais tarde,</0> caso o erro persista entre em contato com nossa atendimento.',
      emailConfirmSuccess: '<0>Um novo e-mail foi enviado para {{email}}</0>. Siga as instruções contidas na mensagem para evitar a suspensão do seu domínio. <1>Se dentro de alguns minutos você não receber o e-mail, por favor, verifique sua caixa de spam.</1> <2>Caso queira alterar o endereço de e-mail do contato</2> <3>clique aqui</3>.',
      emailSuspended: '<0>Um novo e-mail foi enviado para {{email}}</0>. Siga as instruções contidas na mensagem para evitar a suspensão do seu domínio. <1>Se dentro de alguns minutos você não receber o e-mail, por favor, verifique sua caixa de spam.</1> <2>Caso queira alterar o endereço de e-mail do contato</2> <3>clique aqui</3>. <4>1 - Aguarde a propagação (entre 4h e 24h)</4> <5>2 - Limpe o cache</5>',
      cancelledPendingInvoice: 'Domínio pendente. <1>Entre em contato com o suporte.</1>',
      cancelledExpiredInvoice: 'Domínio expirado. <1>Entre em contato com o suporte.</1>',
      messages: {
        nextDueDateInvoice: {
          0: 'Sua fatura ',
          1: 'vence dia ',
          2: 'Pague o boleto agora mesmo',
          3: ' e não corra o risco de perder seu domínio!',
        },
        nextDueDateInvoiceCreditCard: {
          0: 'Sua fatura ',
          1: 'vence dia ',
          3: 'e você será cobrado automaticamente via Cartão de Crédito.',
        },
        nextDueDateInvoicePayPal: {
          0: 'Sua fatura ',
          1: 'vence dia ',
          3: 'e você será cobrado automaticamente via PayPal.',
        },
        dueDateInvoice: {
          0: 'Sua fatura ',
          1: 'venceu dia ',
          2: ' e seu domínio será expirado em ',
          3: 'dias. ',
          4: 'dia. ',
          5: 'Pague o boleto agora mesmo!',
        },
        expiredInvoice: {
          0: 'Sua fatura ',
          1: 'venceu dia ',
          2: ' e seu domínio será cancelado em breve. ',
          3: 'Pague o boleto agora mesmo!',
        },
        expiredOnGracePeriod: {
          0: 'Seu domínio expirou e esta é a última chance de renová-lo. ',
          1: 'Pague a fatura agora mesmo',
          2: ' para não correr o risco de perdê-lo.',
        },
        expiredGracePeriodOverdue: {
          0: 'Seu Domínio ',
          1: 'venceu dia ',
          2: '. Para maiores informações entre em contato com o suporte.',
        },
        disableAutoRenovation: {
          0: 'Conforme solicitado, seu domínio ',
          1: 'será cancelado dia ',
        },
        newDisableAutoRenovation: {
          0: 'Seu domínio ',
          1: 'vence dia  ',
          2: 'Ative a renovação automática',
          3: ' para não correr o risco de perdê-lo.',
        },
        dueDateDisabledAutoRenovation: {
          0: 'Seu domínio ',
          1: 'vence dia  ',
          2: 'Renove agora mesmo',
          3: ' para não correr o risco de perdê-lo.',
        },
        overDueDateDisabledAutoRenovation: {
          0: 'Seu domínio ',
          1: 'venceu ',
          2: ' e irá expirar dia ',
          3: ' Renove agora mesmo',
          4: ' para não correr o risco de perdê-lo.',
        },
        cancelledDomain: {
          0: 'Seu domínio ',
          1: 'foi cancelado dia ',
          2: ' por falta de pagamento.  ',
          3: 'Acesse nosso site e contrate um novo domínio!',
        },
        cancelledByAbuseTOS: {
          0: 'Seu domínio ',
          1: 'foi cancelado',
          2: '. Entre em contato com ',
          3: ' para mais informações.',
        },
      },
    },
    suspendedPlanLink: 'Produto suspenso. <1>Efetue o pagamento da fatura em aberto.</1>',
    pendingPlanLink: 'Produto pendente. <1>Efetue o pagamento da fatura em aberto.</1>',
    cancelledPlanLink: 'Produto cancelado. Não é possível reativá-lo. <1>Contrate um novo plano através do nosso site.</1>',
    terminatedPlanLink: 'Produto encerrado. Não é possível reativá-lo. <1>Contrate um novo plano através do nosso site.</1>',
    buildingPlanLink: 'Seu servidor está sendo configurado. Aguarde até ele ser liberado para uso.',
  },
  billing: billing.BR,
  invoices: billing.BR.invoices,
  subscriptions: billing.BR.subscriptions,
  creditcard: {
    label: {
      number: 'Número do cartão',
      date: 'Data Expiração',
      cvvShort: 'Código',
      cvvLong: 'Código de Segurança',
      name: 'Nome impresso no cartão',
    },
    placeholder: {
      number: 'XXXX XXXX XXXX XXXX',
      date: 'MM/AA',
      cvv: 'Ex: 123',
      name: 'NOME',
    },
  },
  dataCheck: {
    cpf: 'CPF/CNPJ',
    name: 'Nome completo',
    cep: 'CEP/Endereço',
  },
  product: {
    Pending: 'Pendente',
    Active: 'Ativo',
    Completed: 'Completo',
    Suspended: 'Suspenso',
    Terminated: 'Encerrado',
    Cancelled: 'Cancelado',
    Fraud: 'Fraude',
    Building: 'Em Configuração',
    ActivationPending: 'Aguardando ativação',
  },
  statusProductDomain: {
    pending: 'Pendente',
    active: 'Registrado',
    cancelled: 'Cancelado',
    pendingTransfer: 'Em Transferência',
    registerError: 'Falha no registro',
    expired: 'Expirado',
    nextDueDate: 'À Vencer',
    overDueDate: 'Vencido',
  },
  details: {
    product: {
      domain: 'Gerenciar',
      subdomains: 'Subdomínios',
      emptySubdomains: 'Não há subdomínios',
      dedicated: 'Gerenciar',
      windows: 'Gerenciar',
      weebly: 'Ver mais',
      shared: 'Gerenciar',
      reseller: 'Gerenciar',
      wordpress: 'Gerenciar',
      websitebuilder: 'Gerenciar',
      hostingaccount: 'Gerenciar',
      upgrade: 'Ver mais',
      codeguard: 'Ver mais',
      other: 'Ver mais',
      protectedid: 'Ver mais',
      ssl: 'Ver mais',
      gapps: 'Ver mais',
      jumpstart: 'Ver mais',
      ip: 'Ver mais',
      sitelock: 'Ver mais',
      streaming: 'Ver mais',
      vps: 'Gerenciar',
      sql: 'Ver mais',
      revenda: 'Ver mais',
      setup: 'Ver mais',
      suspended: 'Reativar Plano',
    },
    service: {
      websitebuilder: 'Ver mais',
      wordpress: 'Ver mais',
      shared: 'Ver mais',
      reseller: 'Ver mais',
      revenda: 'Ver mais',
      suspended: 'Reativar Site',
      dedicated: 'Ver mais',
      weebly: 'Ver mais',
      hostingaccount: 'Ver mais',
      windows: 'Ver mais',
    },
  },
  filter: {
    product: 'Produto',
    site: 'Site',
    siteName: 'Site',
    domainName: 'Domínio',
    emails: 'E-mail',
    title: 'Filtrar por',
    status: 'Status',
    invoiceNumber: 'Número da Fatura',
    Active: 'Ativo',
    Pending: 'Pendente',
    Suspended: 'Suspenso',
    Cancelled: 'Cancelado',
    Terminated: 'Encerrado',
    Building: 'Em configuração',
    allSites: 'Todos os Sites',
    allProducts: 'Todos os Produtos',
    allStatus: 'Todos os Status',
    allDomains: 'Todos os Domínios',
    open: 'Aberto',
    hold: 'Aberto',
    conclude: 'Concluído',
    search: 'Assunto ou Número do Chamado',
    placeholder: {
      search: 'Buscar',
    },
  },
  feedback: {
    title: 'Deixe seu feedback!',
    chooseTier: 'A que você atribui essa avaliação?',
    comments: 'O que você mais gostou e/ou o que ainda podemos melhorar?',
    commentsPlaceholder: 'Deixe seu comentário',
    sendButton: 'Enviar feedback',
    hated: 'Odiei',
    regular: 'Regular',
    loved: 'Amei',
    validation: {
      tiers: 'Escolha pelo menos uma opção.',
      score: 'Escolha pelo menos uma opção.',
    },
  },
  dialog: {
    title: 'Pagar com Cartão de Crédito',
    message: 'Dados criptografados',
    subtitle: 'Com qual cartão você deseja pagar?',
    formCaption: 'Substituirá algum cartão que você já tenha cadastrado',
    invoiceDetails: 'Ver Resumo da Fatura',
  },
  dialogDataCheck: {
    title: 'Seus dados estão desatualizados ou incorretos',
    description: 'Para prosseguir com a geração do seu boleto, é preciso que você atualize seus dados cadastrais.',
    info: 'Ao clicar em "GERAR BOLETO" seus dados serão salvos automaticamente.',
    checkData: 'Gerar Boleto',
  },
  snackbar: {
    creditCard: {
      create_error: 'Não foi possível validar esse cartão junto ao banco, confira os dados do cartão e tente novamente :)',
      create: 'Cartão de Crédito cadastrado com sucesso!',
      remove_error: 'Oops... não foi possível excluir este cartão no momento. Por favor, tente mais tarde :)',
      remove: 'Cartão excluído com sucesso!',
      invoice_error: 'Oops... não foi possível pagar esta fatura no momento. Por favor, tente mais tarde :)',
      paymentFailByAttemptsLimit: 'Não foi possível fazer a cobrança no cartão cadastrado. Altere o método de pagamento e tente novamente.',
      invoice_status_not_unpaid: 'Identificamos um problema no pagamento e sua fatura foi cancelada, verifique seus dados e tente realizar uma nova compra através do site.',
      invoice: 'A sua solicitação de pagamento foi enviada! Aguarde até ela ser concluída :)',
      recaptcha_not_clicked: 'Confirme que você não é um robô clicando no reCaptcha abaixo.',
      invoicePaymentSuccess: 'O pagamento da sua Fatura foi efetuado com sucesso! :)',
      invoicePaymentPending: 'O seu pedido já está sendo processado e o status da sua fatura será atualizado automaticamente assim que esta ação for concluída.',
      exceeded_accounts: 'Você atingiu o limite de criação de contas de e-mails para este domínio. Para criar um novo e-mail, será necessário excluir uma conta já existente para este domínio.',
      paas: {
        errorSaveCreditCard: 'Preencha todos os campos disponíveis para cadastro do cartão de crédito :)',
        errorVerifyCard: 'Cartão inválido, revise os dados informados ou insira um novo cartão de crédito',
        errorCreditCardAttempt: 'Não foi possível salvar no momento, por favor, tente mais tarde.',
      },
    },
    feedback: {
      sent: 'Feedback enviado com sucesso!',
      sent_error: 'Oops... não foi possível enviar seu feedback no momento. Por favor, tente mais tarde :)',
    },
    dns: {
      changed: 'DNS alterada com sucesso!',
      changed_error: 'Oops... não foi possível alterar a DNS no momento. Por favor, tente mais tarde :)',
    },
    actions: {
      open: 'Abrir',
    },
    emails: {
      deleteEmailAccount: 'E-mail removido com sucesso',
      alterPasswordSuccess: 'Senha do e-mail alterada com sucesso.',
      alterPasswordError: 'Ocorreu uma falha na alteração da senha do seu e-mail. Tente novamente mais tarde.',
      changeStorage: 'Armazenamento do e-mail alterado com sucesso',
    },
    siteBuilder: {
      successRemoveSite: 'Site removido com sucesso!',
      errorRemoveSite: 'Não foi possível remover seu site. Tente novamente ou entre em contato com o suporte.',
      errorOnActivateSiteBuilder: 'Não foi possível ativar o Criador de sites. Tente novamente',
    },
    error: {
      address_ip_invalid: 'Este endereço de IP é inválido. Verifique se as informações estão corretas ou entre em contato com o suporte.',
      authorization_denied: 'Alteração de DNS não autorizado. Verifique se as informações estão corretas ou entre em contato com o suporte.',
      violated_policy: 'Não foi possível alterar o DNS. Verifique os seus dados cadastrais.',
      generic_error: 'Não foi possível alterar o DNS. Entre em contato com o suporte.',
      domain_not_exist: 'Não foi possível alterar o DNS. Entre em contato com o suporte.',
      invalid_nameserver: 'DNS inválido. Verifique se as informações estão corretas ou entre em contato com o suporte.',
      frozen_domain: 'Este domínio consta como congelado. Entre em contato com o suporte financeiro.',
      duplicated_information: 'Não foi possível alterar o DNS. Verifique se as informações estão corretas ou entre em contato com o suporte.',
      cpanel_authentication_fail: 'Não foi possível alterar o DNS. Entre em contato com o suporte.',
      empty_params: 'Não foi possível alterar o DNS. Entre em contato com o suporte.',
      unknown_domain_provider: 'Não foi possível alterar o DNS. Veja se o seu domínio está adicionado no plano do outro provedor.',
      unknown_domain: 'Não foi possível alterar o DNS. Entre em contato com o suporte.',
      search_refused: 'Não foi possível alterar o DNS. Entre em contato com o outro provedor.',
      change_pass: 'A senha atual está incorreta. Tente novamente.',
      minimum_requirement: 'Sua senha não tem os requisitos mínimos de segurança.',
      process_boleto: 'Neste momento, não foi possível emitir o seu boleto. Por favor, tente novamente mais tarde ou entre em contato com nosso suporte.',
      changeStorageEmail: 'Ocorreu uma falha na alteração do armazenamento do seu e-mail. Tente novamente mais tarde',
      email_already_exists: 'O e-mail já existe.',
      access_denied_cpanel: 'Ocorreu um problema na criação da sua conta de e-mail. Entre em contato com o suporte.',
      username_invalid: 'Ocorreu um problema na criação da sua conta de e-mail. Entre em contato com o suporte.',
      exceeded_accounts: 'O limite de contas de e-mail para o plano contratado chegou ao limite. Elimine uma conta de e-mail já criada para prosseguir.',
      cpanel_instability: 'Ocorreu um problema na criação da sua conta de e-mail. Entre em contato com o suporte.',
      hosting_id_not_authorized: 'Ocorreu uma falha na criação da sua conta de e-mail. Tente novamente mais tarde',
      is_not_possible_reactive_freemium_plan: 'Não foi possível reativar o seu plano freemium. Entre em contato com o suporte',
      is_not_possible_active_email_cpanel: 'Algo deu errado. Por favor, entre em contato com o suporte.',
    },
    paypal: {
      success: 'Pagamento realizado com sucesso!',
      error: {
        service_error: 'Não conseguimos realizar a cobrança junto ao Paypal. Seu pagamento não foi autorizado, confirme seus dados de pagamento na Carteira Paypal e tente novamente.',
        agreement_cancelled: 'Não foi possível realizar a cobrança em sua carteira PayPal. Você pode vincular uma carteira PayPal tentando novamente.',
      },
    },
  },
  domainStatus: {
    Pending: 'Pendente',
    Pending_Transfer: 'Faltando Transferir',
    Active: 'Ativo',
    Expired: 'Expirado',
    Transferred_Away: 'Transferido para Longe',
    Cancelled: 'Cancelado',
    Fraud: 'Rejeitado',
    Suspended: 'Suspenso',
  },
  domaindetail: {
    renewBlocked: 'A renovação Automática pode ser alterada apenas quando seu domínio estiver Ativo',
    action: {
      alterDns: 'Configurar Domínio',
      editContact: 'Editar Infos Contato',
      renewDomain: 'Renovar Domínio',
      protectId: 'Protect ID',
      registrationLock: 'Trava de registro',
      manageSubscription: 'Gerenciar Assinatura',
      domainConfigurationWizard: 'Configurar Domínio',
      eppKey: 'Gerar chave EPP',
    },
    eppKeyDisabledTooltip: 'Não é possível gerar a chave pois o domínio está expirado.',
  },
  routes: routes.BR,
  menu: menu.BR,
  title: title.BR,
  tag: {
    new: 'Novo',
  },
  lists: {
    sites: 'Lista de Sites',
    sitesPage: 'Listagem de sites',
    sitesPlans: 'Lista de Sites e Planos',
    products: 'Lista de Produtos',
    domains: 'Lista de Domínios',
    emails: 'Lista de E-mails',
    tickets: 'Lista de Chamados',
    invoices: 'Lista de Faturas',
  },
  security: {
    back: 'Voltar para meus sites',
  },
  codeguard: {
    offer: 'Faça backup das suas alterações de maneira rápida e eficiente, garantindo mais segurança para o seu site.',
    buy: 'VER PLANOS',
    year: ' / ano',
    valueAnnually: 'R$ 59,88',
    cta: {
      title: 'Assinatura CodeGuard',
      description: {
        title: 'Painel de controle fácil de usar e gerenciar',
        subtitle1: 'Deixe o backup online do seu site por conta do Codeguard, e tenha mais tempo para investir em melhorias',
        subtitle2: ' e otimizações do seu negócio online.',
        subtitle3: ' Conte com um painel completo para:',
        one: 'Gerenciar backups automáticos',
        two: 'Gerenciar backups manuais (a partir do plano Iniciante)',
        tree: 'Receber avisos sobre mudanças não autorizadas',
        four: 'Agendar opções de monitoramento',
        five: 'Gerenciar notificações e muito mais',
      },
      video: {
        title: 'A máquina do tempo do seu site',
        subtitle: 'Sempre que algum imprevisto acontece você pode restaurar seu site, ou arquivos específicos, de forma rápida e fácil. A partir do plano iniciante você ainda conta com:',
        one: 'Prioridade na execução do backup',
        two: 'Teste de restauração',
        tree: 'Rastreamento de IP',
      },
      advantages: {
        one: 'Backup de até 5 sites',
        two: '1 GB de armazenamento',
        tree: 'Backups diários automáticos',
        four: 'Monitoramento diário de alterações no site',
        five: 'Rastreio de performance do backup',
        six: 'Restaurações completa do site',
        seven: 'Banco de dados ilimitado',
        eight: 'Acesso via SFTP (SSH) e FTP',
        modal: {
          title: 'Preços promocionais na primeira fatura',
          subtitle: 'Os preços promocionais oferecidos pela HostGator se aplicam ao primeiro período de pagamento, e estão disponíveis nos ciclos indicados na página de cada produto.',
        },
      },
      header: {
        title: 'Seu site seguro com backups diários',
        subtitle: 'CodeGuard oferece proteção total e monitoramento para que seus dados estejam sempre em segurança. O backup online do Codeguard permite que seus sites e dados sejam recuperados instantaneamente, com apenas alguns cliques.',
      },
      footer: {
        title: 'Comece agora a criar backups do seu site!',
        planName: 'Plano Básico',
        valueAnual: 'R$ 59,88 /ano ',
        equivalentTo: 'equivalente a',
        valueMes: 'R$ 4,99',
        annualy: ' /ano',
      },
    },
    acessService: 'SAIBA MAIS',
  },
  sitelockLP: sitelock.BR,
  sitelock: {
    underConstruction: 'Página em construção.',
    emptyTitle: 'Você ainda não possui nenhum produto de segurança contratado, seu site pode estar vulnerável contra ameaças digitais!',
    emptySubTitle: 'Por isso, separamos esta oferta para você manter seu site sempre seguro:',
    offer: 'Proteja o seu site de invasões e ataques hackers de forma simples e automatizada.',
    buy: 'VER PLANOS',
    acessService: 'SAIBA MAIS',
    month: ' / mês',
    year: ' / ano',
    Active: 'Ativo',
    Pending: 'Em análise',
    Suspended: 'Suspenso',
    Terminated: 'Cancelado',
    manage: 'GERENCIAR',
    scannedSecure: 'Site Seguro',
    duedate: 'Vencimento',
    scannedSecureMessage: 'Não encontramos nenhuma ameaça no seu site durante a ultima varredura realizada.',
    scannedPending: 'Varredura em Andamento',
    scannedPendingMessage: 'Sua varredura está em andamento e pode demorar de acordo com o tamanho do seu site.',
    scannedIssues: 'ameaças detectadas.',
    scannedIssuesSingular: 'ameaça detectada.',
    scannedIssuesMessage: 'Site Seguro',
    scannedIssuesWarningP1: 'Seus visitantes podem estar correndo perigo, remova as ameaças que foram encontradas no seu site.',
    scannedIssuesWarningP2: '<0>Não sabe como remover as ameaças encontradas no seu site?</0> <1>Separamos um </1><2>material</2><3> para te ajudar!</3>',
    nextScan: 'Próxima Varredura:',
    prevScan: 'Última Varredura:',
    doScan: 'FAZER VARREDURA',
    checkScan: 'CHECAR STATUS DE VARREDURA',
    scanHistory: 'HISTÓRICO DE VARREDURA',
    scanComplete: 'DETALHES DA VARREDURA',
    limit1: 'A varredura diária permitida pelo seu plano já foi executada.',
    limit2: 'Uma nova varredura será permitida após 24 horas da última execução. Tente novamente mais tarde.',
    limitReached: 'LIMITE DE VARREDURAS ATINGIDO',
    scanHistoryDetails: 'Histórico de Varredura',
    scanListDetails: 'Veja os detalhes das ultimas varreduras realizadas no seu site.',
    scanDate: 'Data',
    scanPages: 'Páginas Verificadas',
    scanLinks: 'Links Conferidos',
    scanMalwares: 'Malwares Localizados',
    scanStatus: 'Status',
    scanDetails: 'DETALHES',
    scanVulnerable: 'Vulnerável',
    scanSecure: 'Seguro',
    scanDetailed: 'Detalhes da Varredura',
    scanDetailedText: 'Veja os detalhes da ultima varredura realizada no seu site.',
    scanName: 'Varredura',
    scanCompliance: 'Situação',
    scanFlag: 'Resultado',
    malware_scan: 'Varredura de Malware',
    advisories: 'Conselhos',
    risk_score: 'Escore de Risco',
    xss_scan: 'Varredura Multiplataforma',
    sqli_scan: 'Varredura do Banco de Dados',
    platform_scan: 'Varredura da Plataforma',
    verify_domain_email: 'Verificação do domínio',
    spam_scan: 'Varredura de Spam',
    na: 'Não aplicável',
    verified: 'Verificado',
    green: 'Seguro',
    red: 'Comprometido',
    panel: 'Painel SiteLock',
    SLtitle: 'Varredura de Códigos Maliciosos',
    SLsubtitle: 'Identifique códigos maliciosos no seu site através da verredura automática de malwares e garanta uma experiência de navegação mais seguro para os seus visitantes.',
    toastPending: 'Varredura em andamento, tente novamente mais tarde.',
    toastDone: 'Varredura concluída!',
    toastSecure: 'Nenhuma ameaça detectada.',
    toastVulnerable: 'ameaças detectadas.',
    infectedPage: 'Sua página',
    malwareQuantity: 'Malwares Localizados',
    severity: 'Severidade',
    critical: 'Crítico',
    information: 'Informação',
    scanClose: 'FECHAR',
    SealTitle: 'Selo de Segurança',
    SealSubtitle: 'Adicione um Selo de Segurança no seu site e mostre para seus visitantes que ele é seguro para navegação.',
    SealPreview: 'Pré visualização:',
    SealPreviewAlt: 'Pré visualização do selo Sitelock',
    SealTooltip: 'Caso uma verificação identifique problemas, o visitante não será alertado, mas o Selo de Segurança vai apresentar a data da ulltima verificação bem sucedida. Você tem alguns dias para corrigir o problema que foi sinalizado e caso ele não seja corrigido, o selo é removido. Em nenhum momento o Selo de Segurança sinalizará para o visitante que alguma verificação falhou.',
    SealWarning: 'As modificações selecionadas abaixo afetarão a pré-visualização assim que você salvar as alterações de exibição do selo de segurança.',
    SealEditBtn: 'EDITAR',
    SealCopyBtn: 'COPIAR',
    SealCopySuccess: 'Código do selo copiado com sucesso!',
    SealSaveBtn: 'SALVAR',
    SealCopyLongBtn: 'COPIAR SELO',
    SealCancelBtn: 'CANCELAR',
    sealSaved: 'Seu selo de segurança foi atualizado com sucesso!',
    SealHelp: 'Não sabe como instalar o Selo de Segurança? <0>Confira este material</0> que separamos para te ajudar!',
    formCode: 'Insira o código abaixo no seu site para instalar o Selo de Segurança:',
    shield: {
      color: 'Cor',
      white: 'Branco',
      red: 'Vermelho',
      size: 'Tamanho',
      small: 'Pequeno',
      medium: 'Médio',
      big: 'Grande',
      type: 'Estilo',
      mal: 'Sem Malware',
      secure: 'Seguro',
      language: 'Idioma',
      br: 'Português',
      es: 'Espanhol',
      en: 'Inglês',
    },
    errors: {
      noConnection: 'Não conseguimos fazer a comunicação com os servidores do SiteLock e por conta disso as funcionalidades deste produto encontram-se desabilitadas temporariamente. Por favor, tente novamente mais tarde.',
    },
    modal: {
      condition: {
        title: 'Preços promocionais na primeira fatura',
        firstText: '<0>Os preços promocionais oferecidos pela HostGator se aplicam ao </0> <1>primeiro período de pagamento</1>, <2> e estão disponíveis nos ciclos indicados na página de cada produto.</2>',
        points: {
          first: {
            text: '<0>Renovações: </0> <1>na renovação do plano, o preço regular será cobrado. A renovação será realizada de forma automática, no mesmo ciclo indicado durante a compra. Para visualizar e/ou alterar essas configurações você pode acessar o seu Portal do Cliente.</1>',
          },
          second: {
            text: '<0>Para quem o desconto é válido: </0> <1>descontos válidos para novos clientes e também para quem já é cliente (neste caso apenas para aquisição de um segundo plano de hospedagem). Não se aplica para renovações, migrações entre contas, upgrades, downgrades, nem para alterações de período de contratação.</1>',
          },
        },
        note: '* Nota: os planos são pré-pagos, ou seja, você paga o valor integral do serviço no momento da compra. Para fins de comparação, o valor equivalente mensal representa o preço cheio distribuído pela quantidade de meses contratados.',
      },
    },
    cta: {
      title: 'Assinatura SiteLock',
      DomainSelectTitle: 'Selecionar um domínio contratado com a HostGator',
      DomainInputTitle: 'Digitar outro domínio',
      selectedTitle: 'Domínio selecionado',
      modifyDomain: 'Alterar domínio',
      btnLabel: 'CONFIRMAR',
      domainPlaceholderText: 'Digite seu domínio',
      confirmDomainPlaceholderText: 'Digite novamente seu domínio',
      cashType: 'R$',
      select: {
        monthly: 'Mensal - R$',
        annually: 'Anual - R$ 179,99 /ano',
        byMonth: '/mês',
      },
      footer: {
        byYear: '/ano',
        description: 'Proteja seu site com SiteLock',
        plan: 'Plano Profissional',
        equivalentTo: '<0>{{ footerAnnuallyLabel }}</0> <2>equivalente a</2>',
        planMes: 'R$ 16,69',
        annualFraction: '/ano',
        monthlyFraction: '/mês',
        button: 'COMEÇAR AGORA',
        annualPlan: 'R$199,99/ano equivalente a',
        monthlyPlan: 'R$199,99/ano equivalente a',
      },
      description: {
        descriptionTitle: 'Descubra como é fácil proteger seu site!',
        descriptionSubTitle: 'A verificação do SiteLock identifica vulnerabilidades e protege seu site das ameaças.',
        description: 'Ao contratar o serviço você ganha também um Selo de Segurança para seu site, que passa mais confiança para seus clientes, aumentando suas vendas e conversões.',
      },
      header: {
        title: 'Proteja seu site de ameaças cibernéticas',
        subTitle: 'Soluções automatizadas para garantir segurança, credibilidade e confiança para seu negócio online',
        advantageOne: 'Proteção para até 100 páginas',
        advantageTwo: 'Varreduras frequentes no site',
        advantageThree: 'Remoção automática de vírus e Malwares',
        advantageFour: 'Verificação diária de FTP',
        advantageFive: 'Verificação de edição de arquivos',
        advantageSix: 'Selo de segurança do site',
        advantageSeven: 'Monitoramento de Blocklist',
        linkConditions: '*Confira as condições',
        optionDomain: 'Escolha seu domínio:',
        optionPayment: 'Selecione o ciclo e a forma de pagamento:',
        buttonPayment: 'Contrate agora',
        valueAnual: 'R$ 199,99 /ano equivalente a',
      },
    },
    smartscan: {
      SLtitle: 'Varredura de Códigos Maliciosos',
      SLsubtitle: 'Você precisa adicionar o seu domínio à um plano de hospedagem para realizar uma varredura. Insira os dados de acesso do FTP da sua hospedagem nos campos abaixo',
      subtitleMaterial: 'Adicione o seu domínio à um plano de hospedagem seguindo os passos <0>deste material</0> para realizar uma varredura automática.',
      subtitleCheckDomain: 'Utilize o botão "CHECAR DOMÍNIO" para confirmar a adição do seu domínio ao plano de hospedagem.',
      checkDomainBtn: 'CHECAR DOMÍNIO',
      sealUnavailable: 'Para gerar o Selo de Segurança adicione o seu domínio a um Plano de Hospedagem seguindo as instruções',
      urlTitle: 'deste material',
      historyTitle: 'Histórico de Varredura',
      historyDescription: 'Veja as ultimas correções realizadas no seu site através da varredura automática do SiteLock. Os arquivos que não puderam ser corrigidos automáticamente serão sinalizados com o status "Correção Manual Pendente" e devem ser corrigidos seguindo as instruções mencionadas <0>neste material</0>.',
      form: {
        name: 'Nome',
        password: 'Senha',
        url: 'URL do Servidor',
        alertTitle: 'Importante:',
        alertMessage: 'Para que a varredura seja feita corretamente, certifique-se de que o seu domínio já está vinculado ao plano de hospedagem selecionado através dos dados de FTP inseridos.',
        send: 'CONFIRMAR FTP',
        update: 'SALVAR',
        cancel: 'CANCELAR',
        error: 'Ocorreu um erro ao enviar os dados. Tente novamente em alguns minutos.',
        success: 'O seu domínio foi configurado com sucesso.',
        pending: 'Aguarde enquanto configuramos o seu domínio.',
      },
      config: {
        error: 'Ainda não identificamos a adição do seu domínio à um Plano de Hospedagem válido, confirme suas alterações e tente novamente mais tarde.',
        success: 'Domínio configurado com sucesso!',
        pending: 'Aguarde enquanto configuramos a varredura de códigos maliciosos.',
      },
      updateForm: {
        tooltip: 'Alterar a Hospedagem Análisada',
        title: 'Alterar Site Analisado',
        description: 'Utilize o Login FTP do seu Plano de Hospedagem para alterar o site onde será realizada a varredura de códigos maliciosos.',
        btnCancel: 'Alterar Site Análisado',
        btnSave: 'Alterar Site Análisado',
      },
      history: {
        actionBtn: 'DOWNLOAD',
        verified: 'Site Verificado',
        scanned: 'Arquivos Escaneados',
        fixed: 'Arquivos Corrigidos',
        manualFix: 'Arquivos com Correção Manual Pendente',
        verifiedFiles: 'Arquivos\nVerificados',
        date: 'Data da\nCorreção',
        time: 'Hora da\nCorreção',
        status: 'Status',
        green: 'Corrigido',
        red: 'Correção Manual Pendente',
        downloadError: 'Ocorreu um erro ao baixar o arquivo.',
        downloadTryAgainLater: 'Tente novamente mais tarde',
      },
    },
  },
  academypass: {
    underConstruction: 'Em construção...',
    paymentSlip: 'Estamos <1>aguardando a confirmação de pagamento</1> para liberar o seu acesso aos cursos do <3>Academy Pass</3>. Lembrando que pagamentos por boleto demoram até 72 horas.',
    filter: {
      filterBy: 'Filtrar por:',
      subjects: 'Assuntos',
      searchByCourseName: 'Pesquisar nome do curso',
      clearFilters: 'Limpar filtros',
      multiSelectLabel: 'Assuntos',
      subjectList: {
        design: 'Design',
        domain: 'Domínio',
        email: 'E-mail',
        ecommerce: 'Ecommerce',
        site_hosting: 'Hospedagem de Sites',
        digital_mkt: 'Marketing Digital',
        digital_business: 'Negócio Digital',
        programming: 'Programação',
        social_media: 'Redes Sociais',
        videos: 'Vídeos',
        wp: 'WordPress',
      },
    },
    list: {
      title: 'Lista de cursos',
      titleStarted: 'Comece a aprender por aqui',
      titleWatchAgain: 'Assistir novamente',
      subtitleStarted: 'Esses são os cursos mais assistidos e com mais relevância para os clientes',
      abort: 'A listagem de cursos está momentaneamente demorando um pouco mais que o esperado. Tente novamente em alguns minutos. Caso o problema persista, entre em contato com o suporte técnico.',
      error: 'Encontramos um erro ao buscar a lista de cursos. Entre em contato com o suporte técnico.',
    },
    card: {
      free: 'GRATUITO',
      pass: 'PASS',
      minutes: 'min',
      hours: 'h',
      classes: 'Aulas',
      watch: 'VER AULAS',
      started: '% Concluído',
      unstarted: 'Não Iniciado',
      unsigned: 'Sem assinatura',
      buy: 'OBTER ACADEMY PASS',
      abort: 'A busca pelas suas credenciais de acesso ao Hostgator Academy está momentaneamente demorando um pouco mais que o esperado. Algumas funcionalidades podem ficar desativadas enquanto tentamos tovamente. Entre em contato com o suporte técnico caso o problema persista.',
      error: 'Encontramos um erro ao buscar as suas credenciais de acesso ao Hostgator Academy. Entre em contato com o suporte técnico.',
    },
    fastCheckout: {
      abort: 'A geração da fatura está demorando um pouco mais que o esperado. Tente novamente em alguns minutos. Caso o problema persista, entre em contato com o suporte técnico.',
      error: 'Encontramos um erro ao gerar a sua fatura. Entre em contato com o suporte técnico.',
    },
    progress: {
      empty: 'Não Iniciado',
      unsubscribed: 'Sem Assinatura',
    },
    cta: {
      alt: 'Obter Academy Pass',
      title: 'Assinatura Academy Pass',
      video: {
        title: 'A gente está pronto para lhe ensinar, e você pode aprender do seu jeito, no seu tempo!',
        iframeTitle: 'A gente está pronto para lhe ensinar, e você pode aprender do seu jeito, no seu tempo!',
        subtitle: 'Aprenda a divulgar seu negócio com mais eficiência e diversifique seu conhecimento num espaço de aprendizagem flexível. Conte com uma metodologia prática e com benefícios exclusivos que só o HostGator Academy Pass pode oferecer.',
      },
      advantages: {
        title: 'E agora, o que te impede de avançar na sua jornada?',
        subtitle: 'Esta é a sua oportunidade para aprender a colocar seu site no ar e ter sucesso no mundo digital.',
        bullet: '▶',
        listItem1: 'Ter uma vitrine online que funciona 24h por dia para seu negócio',
        listItem2: 'Administrar facilmente os conteúdos e recursos técnicos do seu site',
        listItem3: 'Investir em anúncios no Google e posicionar seu site nos primeiros lugares',
        listItem4: 'Aparecer para quem está buscando por um serviço ou produto que você oferece',
        listItem5: 'Criar artes para redes sociais de forma profissional sem precisar entender de design',
        listItem6: 'Sempre novos conteúdos e muito mais conhecimento para enriquecer sua jornada no ambiente digital',
      },
      ctaHeader: {
        title: 'Uma assinatura para ajudar você na sua jornada digital',
        subtitle: 'O passe livre para ideias que vão guiar a jornada do seu negócio no ambiente digital. Acesse cursos exclusivos para colocar seu site no ar e criar estratégias para seu negócio na internet. Aproveite o preço especial de lançamento.',
        productName: 'Academy PASS',
        month: '/mês',
        buttonLabel: 'ASSINAR',
        listItemTitle1: 'Certificado de Conclusão',
        listItemSubtitle1: 'Adquira e compartilhe o certificado que comprova seu conhecimento no assunto.',
        listItemTitle2: 'Desconto Especial',
        listItemSubtitle2: 'Aproveite o valor promocional de lançamento. Estamos trabalhando para a sua melhor experiência.',
        listItemTitle3: 'Metodologia Prática',
        listItemSubtitle3: 'Coloque a mão na massa desde a primeira aula e faça seu negócio crescer.',
        listItemTitle4: 'Professores com Experiência',
        listItemSubtitle4: 'Estude com profissionais que vivem o dia a dia do marketing digital, empreendedorismo e tecnologia.',
      },
      ctaPaymentOptions: {
        dropTitle: 'Academy PASS',
        dropDefaut: 'Pagar com Boleto Bancário',
        drop01: 'Boleto Bancário',
        drop02: '1x Cartão de Crédito sem juros',
        drop03: 'PayPal',
        button: 'ASSINAR COM 50% DE DESCONTO',
        rigthSessionTitle01: 'Certificado de Conclusão',
        rigthSessionSubtitle01: 'Adquira e compartilhe o certificado que comprova seu conhecimento no assunto.',
        rigthSessionTitle02: 'Desconto Especial',
        rigthSessionSubtitle02: 'Aproveite o valor promocional de lançamento. Estamos trabalhando para a sua melhor experiência.',
        rigthSessionTitle03: 'Metodologia Prática',
        rigthSessionSubtitle03: 'Coloque a mão na massa desde a primeira aula e faça seu negócio crescer.',
        rigthSessionTitle04: 'Professores com Experiência',
        rigthSessionSubtitle04: 'Estude com profissionais que vivem o dia a dia do marketing digital, empreendedorismo e tecnologia.',
      },
      discloure: {
        title: 'A gente está pronto para lhe ensinar, e você pode aprender do seu jeito, no seu tempo!',
        description: 'Aprenda a divulgar seu negócio com mais eficiência e diversifique seu conhecimento num espaço de aprendizagem flexível. Conte com uma metodologia prática e com benefícios exclusivos que só o HostGator Academy Pass pode oferecer.',
      },
      testimonials: {
        title: 'Depoimentos de quem já aprendeu com a Hostgator Academy',
        testimonial01: "'Conteúdo de fácil aprendizagem, totalmente personalizável e com recursos que ainda não conhecia. Gostei da simplicidade e praticidade sem se preocupar com linguagem de programação alguma.'",
        testimonialName01: 'Anderson',
        testimonial02: "'Fiquei muito satisfeito. Apesar de ser uma introdução básica ao CANVA abrangeu praticamente tudo e foi muito bem explicado e demonstrado. Parabéns a todos os envolvidos pelo excelente trabalho.'",
        testimonialName02: 'Luiz',
        testimonial03: "'Fiquei encantado, tenho um site há quase um ano e nunca tinha conseguido configurar! Graças a Deus tive acesso a seu curso e que maravilha, consegui fazer tudo funcionar. Valeu mesmo muito obrigado.'",
        testimonialName03: 'Claudevan',
        testimonial04: "'A melhor explicação desse assunto que eu pude ter comparado aos outros online que tem por aí. Vocês estão de parabéns, muito obrigado pela disposição, pela dedicação, por compartilhar informação, por disponibilizar esse material'",
        testimonialName04: 'Amanda',
      },
      footer: {
        buyBtn: 'ASSINAR',
        productOffer: 'Academy Pass',
        month: '/mês',
      },
    },
    watchItAgain: {
      title: 'Assistir Novamente',
      loadMore: 'CARREGAR MAIS',
    },
    keepWatching: {
      title: 'Continuar Assistindo',
      loadMore: 'CARREGAR MAIS',
    },
  },
  titanAdvertisement: {
    top: {
      title: 'E-mail Profissional Titan',
      description_0: 'Potencialize ainda mais a sua marca com um e-mail personalizado ',
      description_1: '@seudomínio',
      labelLink: 'Quero saber mais',
      new: 'Novo',

    },
    toast: {
      knowMore: 'Quero saber mais',
      description: 'Potencialize ainda mais a sua marca com um e-mail personalizado ',
      yourDomain: '@seudomínio',
      title: 'E-mail Profissional Titan',
      newTag: 'Novo',
    },
    bannerDark: {
      knowMore: 'Quero saber mais',
      description: 'Potencialize ainda mais sua marca com um e-mail profissional',
      title: 'E-mail Profissional Titan',
      newTag: 'Novo',
    },
    modalGtm: {
      event: {
        one: 'gtmModalTitanOffer1',
        two: 'gtmModalTitanOffer2',
      },
    },
  },
  modalTitanAdvertisement: {
    title: 'Para qual domínio você gostaria de criar seu E-mail Profissional Titan?',
    selectDomain: 'Selecionar domínio',
    button: 'CONTINUAR PARA MAIS INFORMAÇÕES',
  },
  advertisement: {
    top: {
      trakto: {
        ctaLabel: 'Obter Parceria',
        title: 'Crie artes profissionais grátis para o seu site e suas redes sociais em minutos!',
        logo: 'static/media/src/media/partners/traktoHG.svg',
        href: 'https://pages.trakto.io/hostgator?utm_source=hostgator-banner&utm_campaign=hostgator-banner&utm_medium=hostgator-banner&utm_content=hostgator-banner',
      },
      cobrefacil: {
        ctaLabel: 'Obter Parceria',
        title: 'Cobre, receba e emita nota fiscal dos seus clientes de forma automatizada. Teste grátis agora mesmo!',
        logo: 'static/media/src/media/partners/cobrefacilHG.svg',
        href: 'https://www.cobrefacil.com.br/hostgator?utm_source=hostgator&utm_medium=Mktplace-portal-popup',
      },
      creditas: {
        ctaLabel: 'Obter Parceria',
        title: 'Consiga crédito acessível com a menor taxa do mercado!',
        logo: 'static/media/src/media/partners/creditasHG.svg',
        href: 'https://www.creditas.com/',
      },
    },
    bottom: {
      trakto: {
        btnLabel: 'Testar Grátis',
        label: 'O editor de artes que você precisa. Crie para mini-sites, redes sociais, e-books, apresentações e muito mais.',
        logo: 'static/media/src/media/partners/traktoHG.svg',
        href: 'https://pages.trakto.io/hostgator?utm_source=hostgator-banner-footer&utm_campaign=hostgator-banner-footer&utm_medium=hostgator-banner-footer&utm_content=hostgator-banner-footer',
      },
      cobrefacil: {
        btnLabel: 'Testar Grátis',
        label: 'Cobre, receba e emita nota fiscal dos seus clientes de forma automatizada.',
        logo: 'static/media/src/media/partners/cobrefacilHG.svg',
        href: ' https://www.cobrefacil.com.br/hostgator?utm_source=hostgator&utm_medium=Mktplace-portal-banner-footer',
      },
      creditas: {
        btnLabel: 'Obter Parceria',
        label: 'Consiga crédito acessível com a menor taxa do mercado!',
        logo: 'static/media/src/media/partners/creditasHG.svg',
        href: 'https://www.creditas.com/',
      },
    },
  },
  notFound: {
    error: 'Erro 404',
    title: 'OOPS, nos perdemos...',
    description: 'Parece que o caminho que você está procurando não existe ou não está mais acessível.',
    textLink: 'IR PARA A PÁGINA INICIAL',
  },
  btn: {
    loadMore: 'Carregar mais',
  },
  help: {
    frequentlyQuestions: 'Links de ajuda',
    send: 'Enviar',
    me: 'Eu',
    analyst: 'Analista',
    search: 'Qual a sua dúvida?',
    guidePlaceholder: 'Digite aqui sua dúvida',
    faqCpanel: 'Como gerenciar arquivos no Painel do cPanel',
    faqWebsiteAdvanced: 'Materiais de apoio para o Criador de Sites Avançado',
    faqSoftaculous: 'Como instalar o WordPress no Softaculous',
    faqWebsiteFast: 'Como configurar o Criador de Sites Rápido',
    seeMoreResults: 'Ver mais resultados',
    contentHelpCenter: 'Conteúdos Central de Ajuda',
    buttonRedirectWordPress: 'https://suporte.hostgator.com.br/hc/pt-br/categories/115000054674',
    buttonRedirectSitesBuilder: 'https://suporte.hostgator.com.br/hc/pt-br/categories/115000074213',
    buttonRedirectSoftaculos: 'https://suporte.hostgator.com.br/hc/pt-br/categories/203811968',
  },
  home: {
    mySites: 'Meus Sites',
    emails: 'E-mails',
    products: 'Produtos',
    domains: 'Domínios',
  },
  tickets: {
    pendingTab: 'Pendentes',
    finalizedTab: 'Finalizados',
    openTicket: 'ABRIR NOVO CHAMADO',
    tickets: 'Chamados',
    show: 'Ver Chamado',
    close: 'Finalizar Chamado',
    reply: 'Responder o Chamado',
    attachment: 'Anexar',
    endMessage: 'Confira se está tudo ok e finalize o chamado. Em 3 dias ele será finalizado automaticamente.',
    attendance: 'O que achou do atendimento?',
    sendFeedback: 'Enviar Avaliação',
    sendFeedbackSuccess: 'Avaliação enviada com sucesso.',
    sendFeedbackError: 'Não conseguimos registrar sua avaliação.',
    errorUploadFile: 'Não foi possível fazer o upload deste arquivo',
    errorUploadExtension: 'É permitido arquivos .jpg, .jpeg, .png, .pdf, .doc, .docx, .crt, .key',
    errorUploadSize: 'É permitido arquivos com até 20MB',
    anexo: 'Anexo:',
    good: 'Muito bom!',
    bad: 'Não me ajudou!',
    new: 'Aberto',
    open: 'Aberto',
    pending: 'Aguardando sua resposta',
    hold: 'Aberto',
    solved: 'Concluído',
    conclude: 'Concluído',
    closed: 'Finalizado',
    inputHere: 'Digite aqui...',
    pendingEmpty: 'Você não tem chamados pendentes até o momento :)',
    closedEmpty: 'Você não tem chamados finalizados até o momento :)',
    uploadMessage: 'Arraste o arquivo aqui, ou faça',
    sendImages: 'Anexar Arquivo',
    defaultMessage: 'Mensagem com arquivo em anexo.',
    uploadButton: 'upload.',
    back: 'atrás',
    second: 'seg',
    seconds: 'seg',
    minute: 'min',
    minutes: 'min',
    hour: 'h',
    hours: 'h',
    day: 'd',
    days: 'd',
    week: 'sem',
    weeks: 'sem',
    month: 'mês',
    months: 'meses',
    year: 'ano',
    years: 'anos',
  },
  chat: {
    open: 'Abrir Chat',
  },
  persona: {
    beginner: {
      title: 'Iniciante',
      subtitle: 'Entende pouco e vai precisar de muita ajuda',
    },
    intermediary: {
      title: 'Intermediária',
      subtitle: 'Conhece o assunto e só pede ajuda se for realmente preciso',
    },
    technician: {
      title: 'Técnica',
      subtitle: 'Entende bastante e consegue se virar muito bem sozinha',
    },
    welcome: 'Olá, seja bem-vindo(a)! :)',
    description: 'Pensando em tecnologia, hospedagem e criação de sites, você se considera uma pessoa...',
    next: 'Próximo',
    createLater: 'Não quero criar site agora!',
    errorMsg: 'Houve um erro ao validar sua escolha. Tente novamente mais tarde.',
    successMsg: 'Seu nível de conhecimento foi gravado com sucesso.',
  },
  newsite: {
    creator: {
      title: 'Criador de sites',
      subtitle: 'Um editor fácil com todos os recursos que você precisa',
    },
    wordpress: {
      title: 'Wordpress',
      subtitle: 'Ferramenta mais utilizada e completa do mundo',
    },
    manual: {
      title: 'Manual (Arquivos)',
      subtitle: 'Gerencie os arquivos do seu site via FTP',
    },
    fast: {
      title: 'Rápida',
      subtitle: 'Crie o seu site agora mesmo em até 2 passos!',
    },
    advanced: {
      title: 'Avançada',
      subtitle: 'Crie o seu site através de um editor cheio de recursos!',
    },
    siteModes: {
      title: 'Como você gostaria de criar o site?',
      subtitle: 'Escolha a forma que você gostaria de criar, se uma mais Rápida ou mais Avançada...',
      subtitle2: 'e selecione a forma de criação abaixo',
      subtitleWithoutExpress: 'Use o Criador de Sites Avançado!',
      information: 'Selecione a forma de criação abaixo:',
    },
    themes: {
      title: 'Escolha a categoria do site e o tema:',
    },
    welcome: 'Parabéns, você já pode criar o seu site :)',
    description: 'Escolha um dos sites a seguir',
    choice: 'e selecione dentre as opções disponíveis ou a que recomendamos de acordo com o seu nível de conhecimento ...',
    create: 'Criar Site',
    preview: 'preview',
    useTheme: 'Usar Tema',
    feedbackWelcome: 'Parabéns pela escolha! :)',
    feedbackLink: 'Veja nosso tutorial',
    feedbackTitleWordpress: 'Ficou com dúvida para instalar o wordpress?',
    feedbackTitleFile: 'Ficou com dúvida sobre como gerenciar arquivos?',
    feedbackTitleCreator: 'Ficou com dúvida para configurar o criador de sites?',
    chooseWebsite: 'Escolher site',
    chooseCategoryWebsite: 'Selecione a categoria do site *',
    categories: 'Categorias',
    backToSite: 'Voltar para Meus Sites',
  },
  dns: {
    label: 'DNS',
    message: 'Defina para onde seu domínio aponta :)',
    chooseDomain: 'Escolha um domínio',
    selectDomain: 'Selecione um domínio',
    selectHosting: 'Selecione um plano ativo',
    choiceOption: 'Seu domínio não foi registrado na HostGator. Para editar o apontamento de',
    choiceOption_2: 'DNS (Name Server)',
    choiceOption_3: 'siga uma das duas opções abaixo.',
    notRegistred: {
      title: 'Domínio não registrado ainda',
      text: 'Para poder alterar o DNS, é necessário fazer o registro do domínio.',
      link: 'Veja como é fácil adquirir o seu domínio pelo nosso site.',
      orText: 'OU',
    },
    anotherProvider: {
      title: 'Domínio registrado em outro provedor',
      text: 'Este domínio não está registrado conosco, por isso, você precisa apontar seu DNS para a hospedagem da HostGator. Basta seguir uma das opções abaixo:',
    },
    messages: {
      messageHosting: '<0>Para acessar o seu editor de Zona de DNS acesse o painel de administração do cPanel. Não sabe como acessar o editor de Zona de DNS através do cPanel? </0><1>Clique aqui.</1>',
      messageHosting_2: 'Caso você tenha selecionado um Plano Criador de Sites ou Plano Wordpress, solicite alterações na sua Zona de DNS abrindo um chamado com o suporte.',
      messageWhm: '<0>Para acessar o seu editor de Zona de DNS vá para o painel de administração do WHM. Não sabe como acessar o editor de Zona de DNS através do WHM? </0><1>Clique aqui.</1>',
      messageOther: 'Se o seu domínio está apontado para um servidor fora da HostGator, acesse o painel de administração do seu provedor para editar a zona de DNS.',
      messageOtherSeeMore: 'Veja como acessar a Zona de DNS do seu domínio em outro provedor:',
      messageOtherBellow: '<0>Se o seu domínio está apontado para um plano VPS ou um servidor Dedicado da Hostgator, vá para o painel de administração do WHM. Não sabe como acessar o editor de Zona de DNS através do WHM? </0><1>Clique aqui.</1>',
      messageError: 'Não foi possível conectar com o servidor da sua Zona de DNS. Por favor, tente novamente mais tarde ou entre em contato com o suporte.',
      messageError_2: 'Tente novamente mais tarde ou abra um chamado com o suporte.',
      createTicket: 'ABRIR CHAMADO',
    },
    transferDomain: {
      title: 'Transferir o domínio para a HostGator',
      text: 'Você pode transferir seus domínios para a HostGator e controlar todos os seus produtos por aqui. Para realizar este procedimento, este material pode ajudar:',
      link: 'Passo a passo para transferir o domínio para a HostGator',
    },
    changeDns: {
      title: 'Alterar DNS na empresa de registro',
      textUp: 'Você também pode alterar o DNS direto na empresa onde você registrou seu domínio. Para fazer a alteração, criamos os materiais a seguir para te ajudar.',
      textDown: 'Basta escolher o nome da empresa onde registrou seu domínio e seguir o passo a passo:',
      textOtherProvider: 'Caso não tenha feito o registro em nenhuma dessas empresas, por favor, solicite ajuda ao  suporte da empresa onde contratou o seu domínio.',
    },
    zoneManager: {
      title: 'Zona de DNS',
      explanation: 'Gerencie os registros de DNS para usar o seu domínio da maneira que precisar.',
      explanationKnowMore: 'Saiba mais sobre',
      explanationKnowMore_2: 'registros da Zona de DNS.',
      explanationTooltip_1: 'O domínio é o seu endereço na internet. Ele é representado por uma série de caracteres que identificam seu site, como  “hostgator.com.br”.',
      copyDnsTable: 'Copiar registros de DNS',
      planStatusCancelledZoneDNS: 'Seu domínio está configurado para um plano de hospedagem cancelado. Configure agora mesmo seu domínio clicando em "Alterar Plataforma"',
      addRecord: {
        button: 'Adicionar Registro',
        form: {
          title: 'Criar Registro',
          editTitle: 'Editar Registro',
          closeForm: 'Fechar formulário',
          label: {
            type: 'Tipo',
            name: 'Nome',
            class: 'Classe',
            ttl: 'TTL',
            typeAValueField: 'IPv4',
            typeAAAAValueField: 'IPv6',
            typeCAAValueField: 'Valor',
            typeMxPriorityField: 'Prioridade',
            typeMxDestinationField: 'Destino',
            typeTxtTextField: 'Texto',
            typeCnameValueField: 'Valor',
            typeSrvPriorityField: 'Prioridade',
            typeSrvPortField: 'Porta',
            typeSrvWeightField: 'Peso',
            typeSrvDestinationField: 'Destino',
          },
          placeholder: {
            type: 'Tipo',
            name: 'Use @ para root',
            class: 'Classe',
            ttl: 'TTL',
            preference: 'Número Inteiro',
          },
          helperText: {
            typeA: 'Você deve especificar um endereço IPv4 válido.',
            nameField: "Insira um domínio válido com letras de a-z, números de 0-9 e hífen. Você também pode inserir '_', como em 'sub._domainkey.exemplo.com' ou '*', como em '*.exemplo.com'. Não utilize espaços.",
            'nameFieldAs@': "O caractere '@' no nome de registro corresponderá ao endereço raiz",
            ttl: 'Insira um número inteiro positivo.',
            caaIodefValue: "Você deve inserir um endereço de email no formato 'mailto:exemplo@email.com' ou uma URL válida no formato 'https://exemplo.com' ou 'http://exemplo.com'.",
            caaIssueValue: "Você deve inserir somente ponto e vírgula ';' ou um endereço válido de autoridade certificadora no formato 'exemplo.com' ou 'www.exemplo.com'.",
            mxDestinationValue: "Você deve inserir um nome de domínio válido no formato 'exemplo.com.br' e sem espaços.",
            mxPriorityValue: 'O número deve ser menor que ou igual a 65535',
            cnameValueField: "Você deve inserir um nome de domínio válido no formato 'exemplo.com.br' e sem espaços.",
            srvIntegerHelper: 'O número deve ser menor que ou igual a 65535',
            srvDomainHelper: "Você deve especificar um nome de domínio válido no formato 'exemplo.com' ou 'www.exemplo.com'.",
          },
        },
        addButton: 'Adicionar',
        cancelButton: 'Cancelar',
        updateButton: 'Salvar',
        response: {
          success: 'Registro adicionado com sucesso.',
          error: 'Algo deu errado ao adicionar o registro. Tente novamente em alguns minutos ou solicite suporte técnico.',
        },
        successMessage: 'Registro adicionado com sucesso.',
        errorMessage: 'Algo deu errado ao adicionar o registro. Tente novamente em alguns minutos ou solicite suporte técnico.',
        errorMessageDomain: 'Você não pode ter um registro CNAME com o mesmo nome do domínio.',
      },
      excludeRecord: {
        title: 'Excluir Registro',
        confirmation: 'Você tem certeza que deseja excluir este registro?',
        explanation: 'Tome cuidado! Ao excluir um registro de DNS você pode impactar o funcionamento do seu site.',
        excludeButton: 'Excluir',
        cancelButton: 'Cancelar',
        successMessage: 'Registro excluído com sucesso.',
        errorMessage: 'Algo deu errado ao excluir o registro. Tente novamente em alguns minutos ou solicite suporte técnico.',
      },
      editRecord: {
        successMessage: 'Registro alterado com sucesso.',
        errorMessage: 'Algo deu errado ao editar o registro. Tente novamente em alguns minutos ou solicite suporte técnico.',
      },
      recordList: {
        searchField: 'Buscar',
        filterLabel: 'Filtrar por:',
        loadMore: 'Carregar Mais',
        listLabel: {
          type: 'Tipo',
          name: 'Nome',
          class: 'Classe',
          ttl: 'TTL',
          value: 'Valor',
          address: 'Valor',
          status: 'Status',
          editButton: 'Editar',
          removeButton: 'Excluir',
          flag: 'Flag',
          tag: 'Tag',
          preference: 'Prioridade',
          exchange: 'Destino',
          txt_data: 'Texto',
          cname: 'Valor',
          weight: 'Peso',
          port: 'Porta',
          target: 'Destino',
          priority: 'Prioridade',
        },
        allFiltersLabel: 'Todos',
        emptyMessage: 'Você ainda não possui registros (classe IN) na Zona de DNS do seu domínio.',
        emptyMessage_2: 'Clique no botão "ADICIONAR REGISTRO" para adicionar o seu primeiro registro.',
        emptySearch: 'Não encontramos nenhum resultado para sua busca :(',
        emptySearch_2: 'Você pode tentar procurar por outra palavra-chave e ou utilizar uma diferente combinação de filtros para encontrar o que você procura.',
      },
      errorCode: {
        internal: {
          error: 'Ocorreu um erro.',
        },
        name: {
          dns_record_name: "Insira um domínio válido com letras de a-z, números de 0-9 e hífen. Você também pode inserir '_', como em 'sub._domainkey.exemplo.com' ou '*', como em '*.exemplo.com'.",
          unique_1: 'Você não pode ter um registro',
          unique_2: 'junto com outro registro CNAME com o mesmo nome.',
          unique_3: 'Você não pode ter um registro CNAME junto com outros registros com o mesmo nome.',
        },
        address: {
          ip: 'Você deve especificar um endereço IP válido.',
        },
        value: {
          dns_record_value: "A informação do campo 'valor' é inválida.",
        },
        exchange: {
          domain: "Você deve especificar um nome de domínio válido no formato 'exemplo.com' ou 'www.exemplo.com'.",
        },
      },
    },
    dnsPropagation: 'Propagação DNS',
  },
  whichDnsToChange: {
    title: 'DNS (Name Server)',
    title2: 'Não é possível alterar o DNS',
    text: 'Por favor, ',
    text2: 'entre em contato com o suporte.',
    infoIconTooltip: 'O sistema de Nomes de Domínios (Domain Name System - DNS) é responsável pela tradução do endereço do seu site (ou domínio), por exemplo "hostgator.com.br", para a linguagem da internet.',
    paragraphOne: 'Configure os endereços de DNS (Name Server) para que os servidores da internet possam encontrar seu domínio na rede mundial de computadores.',
    wichDnsToChangeKnowMore: 'Saiba mais sobre',
    wichDnsToChangeKnowMore_2: 'apontamentos de Name Server.',
    text3Tooltip_1: '<0>O Sistema de Nomes de Domínios (</0><1>Domain Name System</1><2> - DNS) é responsável pela tradução do endereço do seu site (ou domínio), como por exemplo “hostgator.com.br”, para a linguagem da internet</2>',
    text3Tooltip_2: 'O domínio é o seu endereço na internet. Ele é representado por uma série de caracteres que identificam seu site, por exemplo: “hostgator.com.br”.',
    description: 'O domínio informado não foi registrado conosco. Para fazer a alteração do DNS será necessário transferir o domínio para a HostGator (<1>veja como</1>) ou alterar o DNS no seu provedor atual. Caso ainda não tenha contratado este domínio, adquira agora mesmo pelo <3>nosso site</3>',
  },
  dnsGroups: {
    dnsMissing: 'Falta Apontar DNS',
    dnsOk: 'DNS Apontado',
    anotherProvider: 'Não registrado na HostGator',
  },
  dnsSetup: {
    title: 'Para qual DNS você deseja alterar?',
    warning: '<1>A mudança pode levar até 24 horas</1>, período de propagação do DNS na internet',
    warningSuccess: 'Parabéns, o DNS foi alterado com sucesso :)',
    warningError: 'Não foi possível salvar no momento, por favor, tente mais tarde. :)',
    warningNative: 'Selecione uma das opções de DNS ao lado :)',
    native: {
      title: 'Não tenho um Servidor de Hospedagem',
      description: 'Utilize esse apontamento para habilitar o editor de Zona de DNS do seu domínio, caso você não tenha uma hospedagem.',
    },
    other: {
      title: 'Outro Servidor',
      description: 'Use um DNS personalizado ou aponte o seu domínio para um outro provedor de hospedagem.',
      button: 'Adicionar mais um Slave',
      ipAddress: 'Endereço IP',
    },
    hosting: {
      title: 'Servidor de Hospedagem da HostGator',
      description: 'Use um Servidor de Hospedagem da HostGator para colocar seu site no ar.',
    },
  },
  myData: {
    foreign: 'Sou estrangeiro',
    document: 'Documento',
    companyName: 'Razão Social',
    birthCountry: 'País de nascimento',
    typeHere: 'Digite aqui',
    placeHolderEmail: 'seuemail@dominio.com',
    personalDataContact: 'Dados Pessoais e de contato',
    cpfCnpj: 'CPF ou CNPJ',
    name: 'Nome completo',
    nameResponsable: 'Nome do Responsável',
    birthDate: 'Data de nascimento',
    cellPhone: 'Celular',
    email: 'E-mail',
    knowledge: 'Nível de Conhecimento',
    addressTitle: 'Endereço',
    address: 'Endereço',
    findMyCep: 'Não sei meu CEP',
    countryResidence: 'País de Residência',
    cep: 'CEP',
    postalCode: 'Código Postal',
    numberComplement: 'Número ou Complemento',
    district: 'Bairro',
    state: 'Estado',
    stateSelect: 'Estado',
    city: 'Cidade',
    citySelect: 'Cidade',
    avatar: 'Avatar',
    chooseMyAvatar: 'Escolher Avatar',
    persona: {
      beginner: 'Iniciante',
      intermediary: 'Intermediário',
      technical: 'Técnico',
    },
    errors: {
      required: 'Campo obrigatório',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      fullName: 'Nome deve possuir 2 palavras com no mínimo 2 letras e máximo 50',
      birthDate: 'Digite uma data válida',
      country: 'País inválido',
      phoneNumber: 'Digite um telefone válido',
      email: 'Digite um e-mail válido',
      cep: 'CEP inválido',
      state: 'Estado inválido',
    },
    snackBar: {
      updateSuccess: 'Seus dados foram atualizados com sucesso!',
      updateError: 'Erro ao salvar seus dados!',
      cepError: 'Digite um CEP válido',
      emailAlreadyExists: 'Seu e-mail já está cadastrado no nosso sistema.',
    },
    changePassword: {
      title: 'Altere a senha do Portal do Cliente',
      currentPassword: 'Senha atual',
      newPassword: 'Nova senha',
      confirmPassword: 'Confirme a nova senha',
      helperTextUp: 'A senha deve ter no mínimo <1>8 caracteres</1> e ter ao menos <3>uma letra maiúscula</3>, <5>uma minúscula</5>, <7>um número</7> e <9> um caracter especial (Por exemplo: !#$%&)</9>.',
      strength: 'Força da nova senha',
      generatorText: 'Caso você tenha dificuldade para gerar uma senha forte e segura, veja nosso <1>gerador de senhas</1>.',
      errorCurrentEmpty: 'Prencha sua senha atual.',
      errorEmptyField: 'Preencha este campo.',
      errorCurrentMinimumCharacters: 'A senha deve ter no mínimo 6 caracteres.',
      errorMinimumCharacters: 'A senha deve ter no mínimo 8 caracteres.',
      errorMinimumRequirements: 'Sua senha não tem os requisitos mínimos de segurança.',
      errorConfirmNew: 'A confirmação de senha não confere.',
      nofifierSuccess: 'Senha alterada com sucesso!',
      notifierError: 'Não foi possível alterar sua senha. Entre em contato com o suporte.',
      checklist: {
        title: 'Sua nova senha deve ter:',
        upperAndLowercase: 'Incluir caracteres maiúsculos e minúsculos',
        number: 'Incluir pelo menos 1 número',
        symbol: 'Incluir pelo menos 1 símbolo',
        eightCharacters: 'Ter pelo menos 8 caracteres',
        notEqualCharacters: 'Não incluir combinações de caracteres repetidos (ex.: aaa)',
        notSequencialCharacters: 'Não incluir combinações de caracteres sequenciais (ex.: 123)',
        sameInBothFields: 'Ser a mesma em ambos os campos',
      },
      error: {
        base: 'Esta faltando usar na sua senha: ',
        uppercase: '1 letra maiúscula',
        lowercase: '1 letra minúscula',
        number: '1 número',
        symbol: '1 símbolo',
      },
    },
    dataPrivacy: {
      title: 'Privacidade de Dados',
      checkboxLabel: 'Receber Novidades por E-mail',
      checkboxExplanation: 'Com essa opção ativada você nos autoriza a enviar mensagens promocionais e as últimas atualizações de conteúdo das nossas plataformas',
    },
  },
  billingcycle: {
    monthly: '1x por mês',
    quarterly: '1x a cada 3 meses',
    semiannually: '1x a cada 6 meses',
    annually: '1x a cada ano',
    biennially: '1x a cada 2 anos',
    triennially: '1x a cada 3 anos',
  },
  reboot: {
    title: 'Reboot (reiniciar máquina)',
    message: '<0>A reinicialização levará cerca de 15 minutos</0> e durante esse período todos os seus serviços ficarão indisponíveis.',
    messageWarning: 'Você reiniciou a máquina 3 vezes em menos de 1h. Se desejar, <1>abra um chamado para o suporte</1> para que eles possam ajudar a resolver o problema.',
    history: 'Ver histórico de reboots',
    restart: 'Reiniciar máquina',
    modalHistory: {
      title: 'Histórico de Reboots',
      message: 'Veja as últimas reinicializações do seu <1>{{product}}</1>, cujo domínio principal é: <3>"{{domain}}"</3>',
      date: 'Data',
      time: 'Hoários',
    },
    modalReboot: {
      title: 'Reiniciar máquina',
      message: 'Você tem certeza que deseja reiniciar o seu <1>{{product}}</1> cujo domínio principal é: <3>"{{domain}}"</3>?',
      cancel: 'Cancelar',
      confirm: 'Sim, quero reiniciar',
    },
    feedback: {
      error: 'Não foi possível reiniciar a máquina. Tente novamente, ou entre em contato com o suporte.',
      success: 'Reboot concluído',
    },
  },
  modalDomainAutoRenovation: {
    title: 'Habilitar renovação automática',
    description: 'Caso o método de pagamento seja PayPal ou Cartão de Crédito, a cobrança será feita automaticamente. Se o método for boleto, a fatura será emitida 30 dias antes do vencimento.',
    confirmLabel: 'Confirmar',
  },
  modalSetPassword: {
    setTitle: 'Definir senha de acesso',
    editingTitle: 'Trocar senha de acesso',
    message: 'Para começar a usar o seu <1>{{ product }}</1>, defina uma senha de acesso:',
    setBtn: 'Definir senha',
    changeBtn: 'Trocar senha',
    passwordLabel: 'Senha',
    confirmPasswordLabel: 'Confirmar senha',
    newPasswordLabel: 'Nova senha',
    confirmNewPasswordLabel: 'Confirmar nova senha',
    required: 'Preencha este campo',
    minimumRequirements: 'Sua senha não tem os requisitos mínimos de segurança',
    confirmPassword: 'A confirmação de senha não confere',
  },
  onboarding: {
    title: {
      whatYouCreateFirst: 'O que você deseja criar primeiro?',
      whatYouWantDoFirst: 'O que você deseja fazer primeiro?',
      createEmailAccountsAsYouLike: 'Crie quantas contas de e-mails desejar:',
      createYourEmailsRightNow: 'Crie seus e-mails agora mesmo!',
      chooseBelowCreationType: 'Escolha abaixo a forma de criação:',
      wantCreateWebsiteNow: 'Quer criar um site agora?',
      makeWebsiteRightNow: 'Faça um site agora mesmo!',
      domainNotRegistered: 'Este domínio não foi registrado conosco :/',
      getHostingsPlans: 'Adquira um dos nossos planos de hospedagem!',
      getEmailProfissional: 'Adquira um dos nossos planos de E-mail Profissional!',
      createYourEmailAccounts: 'Crie suas contas de e-mail:',
      createEmailAsYouLike: 'Crie quantos e-mails desejar:',
      chooseThemeCategory: 'Escolha a categoria do site e o tema:',
    },
    subtitle: {
      createEmailAsYouLike: 'Crie quantos e-mails desejar.',
      ifYesChooseBelowCreationType: 'Se sim, escolha abaixo a forma de criação.',
      chooseBelowCreationType: 'Escolha abaixo a forma de criação.',
      chooseOneFollowingSites: 'Escolha um dos sites a seguir',
      domainNotRegistered: 'Antes de continuar, você precisa apontar o DNS para uma hospedagem aqui na HostGator. <1>Se você já registrou este domínio em outra empresa basta seguir a opção abaixo:</1>',
      hostingsPlansToCreateSite: 'Para que você possa criar o seu site, é preciso ter um plano de hospedagem.',
      hostingsPlansToCreateEmail: 'Para que você possa criar seus e-mails é preciso ter um plano de hospedagem.',
      NewHostingsPlansToCreateEmail: 'Para você criar um e-mail personalizado com o seu domínio, você pode contratar um plano de E-mail Profissional.',
      contactUs: 'Entre agora mesmo no nosso site e escolha o que melhor se adequa as suas necessidades.',
      fastOrAdvanced: 'Escolha a forma que você gostaria de criar, se uma mais Rápida ou mais Avançada...',
    },
    option: {
      title: {
        createSite: 'Criar Site',
        createEmail: 'Criar E-mails',
        siteCreator: 'Criador de Sites',
        wordpress: 'WordPress',
        manualFiles: 'Manual (Arquivos)',
        fast: 'Rápida',
        advanced: 'Avançada',
        transferDomain: 'Transferir o domínio para a HostGator',
        changeDnsExternal: 'Alterar DNS na empresa de registro',
        changeDns: 'Alterar DNS',
      },
      description: {
        createSite: 'Coloque no ar o seu projeto agora mesmo!',
        createEmail: 'Crie quantas contas de e-mail quiser.',
        siteCreator: 'Um editor fácil com todos os recursos que você precisa.',
        wordpress: 'Ferramenta mais utilizada e completa do mundo.',
        manualFiles: 'Gerencie os arquivos do seu site via FTP.',
        changeDns: 'Aponte o domínio para um plano ou provedor de hospedagem.',
        fast: 'Crie o seu site agora mesmo em até 2 passos!',
        advanced: 'Crie o seu site através de um editor cheio de recursos!',
      },
    },
    action: {
      button: 'Criar Site',
      linkSkipCreateEmails: 'Não quero criar e-mails agora!',
      linkSkipCreateSite: 'Não quero criar site agora!',
      hostingsPlans: 'Ver Planos de Hospedagem',
      emailProfessional: 'Adquirir plano de e-mail profissional',
    },
    emails: {
      createPassword: 'Senha*',
      createEmail: 'CRIAR MAIS UM E-MAIL',
      selectAnEmail: 'Selecione um e-mail',
      limitLength: 'Seu e-mail deve conter menos do que 64 caracteres',
      emailExists: 'Este e-mail já foi inserido',
    },
    changeDns: {
      title: 'Alteração de DNS',
      subtitle: 'Para fazer a alteração, <1>veja o seu DNS abaixo e escolha o nome da empresa onde registrou seu domínio:</1>',
      note: 'Caso não tenha feito o registro em nenhuma dessas empresas, por favor, solicite ajuda ao suporte da empresa onde contratou o seu domínio.',
      link: 'Ir para o portal do cliente',
    },
    transferDomain: {
      title: 'Transferência do domínio',
      requirementsMessage: 'Tenha a certeza de que <1>todos os requisitos</1> para esta transferência estão sendo atendidos. <3>Este procedimento pode levar até 6 horas.</3>',
      eppMessage: 'Para realizarmos a transferência do seu domínio, <1>solicite a Chave EPP ao suporte da empresa onde contratou o domínio</1> e coloque a informação no campo abaixo. Este procedimento pode levar até 6 horas.',
      eppError: 'Campo obrigatório',
      label: 'Digite a Chave EPP aqui',
      link: 'Transferir domínio',
      feedback: 'Aberto o chamado {{ticket}} para transferência do seu domínio. Acompanhe este chamado pela página Suporte.',
    },
    obs: 'Caso não tenha contratado um domínio ainda, <1>registre seu domínio agora mesmo</1>!',
    wordpress: {
      plan: {
        limitedPrefix: 'Crie até',
        limitedSuffix: 'contas de e-mail.',
        unlimited: 'Crie quantos e-mails desejar.',
      },
    },
  },
  emailVerify: emailValidation.BR,
  phoneVerify: phoneValidation.BR,
  linkNaBio: linkNaBio.BR,
  courses: {
    info: 'Conteúdo gratuito com dicas e tutoriais para te ajudar a dar início na construção do seu site.',
    menuTitle: 'Conteúdo do Curso',
    classes: 'aulas',
    seeClasses: 'Ver Aulas',
    minutes: 'min',
    watched: 'Visualizado',
    vimeoServiceDown: 'Não foi possível buscar os cursos no momento. Por favor, tente novamente mais tarde.',
    certificate: {
      download: 'Baixar Certificado',
      available: 'O seu Certificado estará disponível para download após a visualização de todos os vídeos.',
      progress: 'Completado',
      title: 'Certificamos que',
      conclusion: 'Concluiu com total aproveitamento o curso',
      hours: 'com carga horária de {{hours}}h',
      date: 'Data',
      founder: 'Fundador da HostGator',
      fileName: 'certificado',
    },
  },
  sitesWizard: sites.BR.wizard,
  modalDnsAutomatic: {
    title: 'Propagação do domínio em andamento...',
    button: 'Continuar criando o site',
    descriptionOne: {
      0: 'A configuração do domínio (alteração de DNS) já foi realizada, mas as informações estão em ',
      1: 'processo de propagação',
      2: ' e isso pode levar até 24h.',
      3: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000451693',
    },
    descriptionTwo: {
      0: 'Enquanto isso, ',
      1: 'continue criando o seu site. Ele ficará online depois de finalizada essa etapa.',
    },
  },
  domains: domains.BR,
  dialogPaypal: {
    title: 'Pagar com PayPal',
    titleContainer: 'Carteira digital PayPal',
    featureTitleFirst: 'Tecnologia',
    featureDescriptionFirst: 'Cartão protegido por criptografia',
    featureTitleSecond: 'Segurança',
    featureDescriptionSecond: 'No armazenamento dos seus dados',
    featureTitleThree: 'One TouchTM',
    featureDescriptionThree: 'Pague com um clique',
  },
  dialogTicket: {
    title: 'Pagar com Boleto',
    view: 'Visualizar Boleto',
    close: 'ALTERAR MÉTODO DE PAGAMENTO',
    complete: 'O boleto foi gerado, acesse pelo botão abaixo.',
  },
  plan: {
    detail: 'Gerenciar',
    expand: 'Ver Sites',
    status: {
      active: 'Ativo',
      pending: 'Pendente',
      in_analysis: 'Em análise',
      on_configure: 'Em configuração',
      cancelled: 'Cancelado',
      suspended: 'Suspenso',
      expired: 'Expirado',
    },
    warning: {
      pending: 'Ainda não identificamos o seu pagamento. <1>Atenção: pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      in_analysis: 'Seu plano está em análise cadastral, este processo pode levar até 24 horas. Após esse período, você terá acesso a criação e gerenciamento dos seus sites.',
      on_configure: 'Seu servidor está sendo configurado, esse processo pode levar até X horas. Após esse período, você terá acesso ao gerenciamento do seu plano.',
      cancelled: 'Seu plano de hospedagem foi cancelado e todos os arquivos foram deletados. <1>Contrate um novo plano através do nosso site.</1>',
      suspended: 'Este plano está suspenso. Realize o pagamento para reativa-lo. <1>Atenção: pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      expired: 'Devido a falta de pagamento, seu plano de hospedagem expirou e todos seus sites e arquivos foram deletados. <1>Contrate um novo plano através do nosso site.</1>',
    },
    tooltip: {
      disabled: 'Esta ação está desabilitada devido ao status que seu produto está atualmente.',
      active: 'Sua hospedagem está ativa. Você possui acesso a todas as funcionalidades do seu plano de hospedagem acessando o gerenciamento ao lado.',
      pending: 'Ainda não identificamos o seu pagamento. Atenção: pagamentos por boleto podem levar até 72 horas para serem identificados.',
      suspended: 'Este plano está suspenso. Realize o pagamento para reativa-lo. Atenção: pagamentos por boleto podem levar até 72 horas para serem identificados.',
      expired: 'Devido a falta de pagamento, seu plano de hospedagem expirou e todos seus sites e arquivos foram deletados.',
    },
    filter: {
      active: 'Sites Ativos',
      pending: 'Sites Pendentes',
      in_analysis: 'Sites em análise',
      on_configure: 'Sites em configuração',
      cancelled: 'Sites Cancelados',
      suspended: 'Sites Suspensos',
      expired: 'Sites Expirados',
    },
  },
  site: {
    websitebuilder: '(Criador de Sites)',
    actions: {
      active: 'Gerenciar',
      notConfigured: 'Configurar Domínio',
    },
    status: {
      not_configured: 'Domínio Não Configurado',
    },
  },
  mktPlace: {
    title: 'Encontre produtos e serviços de diversas categorias e potencialize a sua presença online!',
    filterLabel: 'Lista de Serviços',
    filterBy: 'Filtrar por:',
    cleanFilters: 'Limpar filtros',
    allFiltersLabel: 'Todos',
    acquireA: 'OBTER',
    acquireB: 'ACESSAR',
    emptyCardTitle: 'Não encontramos nenhum resultado para sua busca :(',
    emptyCardSubTitle: 'Você pode tentar procurar por outra palavra-chave e ou utilizar uma diferente combinação de filtros para encontrar o que você procura.',
    colab: {
      modalTitle: 'HostGator Academy',
      modalDescription: 'A HostGator Academy é uma iniciativa da HostGator. Aqui você vai encontrar aulas para criar o seu site, desenvolver estratégias para o seu negócio, aprender marketing digital, empreendedorismo, programação web e muito mais! Os cursos são 100% online e vão te ajudar a alcançar o sucesso no seu negócio ou carreira.',
      premiumTab: 'CURSOS PREMIUM',
      freeTab: 'CURSOS GRATUITOS',
      accessButton: 'ACESSAR CURSO',
      acquireButton: 'OBTER CURSO',
    },
    recommended: 'Recomendado',
  },
  oldReseller: {
    ResellerSuspendedMessage: '<0>Você possui um ou mais planos afetados pela </0><1>atualização dos preços e planos de revenda.</1><2> Será necessário </2><3>escolher um novo plano</3><4> para evitar que seus dados sejam excluídos permanentemente.</4>',
    ResellerAffectedMessage: '<0>Você possui um ou mais planos afetados pela </0><1>atualização dos preços e planos de revenda.</1><2> Você precisará </2><3>escolher um novo plano</3><4> na sua próxima renovação.</4>',
    cardSuspendedMessage: '<0>Seu plano {{productname}} foi suspenso e será cancelado no dia {{invoicedate}}. </0><1>Escolha um novo plano</1><2> e evite que seus dados sejam excluídos permanentemente.</2>',
    cardAffectedMessage: '<0>Seu plano {{productname}} vence no dia {{invoicedate}}. </0><1>Devido a </1><2>atualização dos preços e planos de Revendas</2><3>, sua próxima fatura de renovação será cancelada automaticamente. Você precisará </3><4>escolher um novo plano</4><5> na sua próxima renovação.</5>',
    cardAffectedMessageTilTenDays: '<0>Seu plano {{productname}} vence no dia {{invoicedate}} e entrará em suspensão 10 dias úteis após essa data. </0><1>Devido a </1><2>atualização dos preços e planos de Revendas</2><3>, sua próxima fatura de renovação será cancelada automaticamente. Você precisará </3><4>escolher um novo plano</4><5> na sua próxima renovação.</5>',
    ModalTitle: 'Planos Pendentes',
    ModalOldResellerMessage: '<0>Você possui um ou mais planos afetados pela </0><1>atualização dos preços e planos de Revenda.</1><2> Escolha um novo plano que melhor se adapta as suas necessidades.</2>',
    productPlan: 'Plano',
    productStatus: 'Status do Plano',
    productCancelation: 'Cancela em',
    productExpiration: 'Vence em',
    ModalCta: 'ESCOLHER NOVO PLANO',
    Active: 'Ativo',
    Pending: 'Pendente',
    Pending_Transfer: 'Faltando Transferir',
    Expired: 'Expirado',
    Cancelled: 'Cancelado',
    Fraud: 'Fraude',
    Suspended: 'Suspenso',
    Building: 'Em Configuração',
  },
  proEmail: {
    searchDomainLabel: 'Pesquisar nome do domínio',
    professionalTitanMailLabel: 'E-mail profissional Titan',
    freeMailLabel: 'E-mail gratuito da hospedagem',
    showMore: 'Mostrar mais',
    showLess: 'Mostrar menos',
    infoPaidMailLabel: 'As soluções de e-mail profissional Titan Essential e Premium são perfeitas para negócios em crescimento por oferecerem mais recursos que o e-mail básico da hospedagem.',
    infoFreeMailLabel: 'O e-mail gratuito é um benefício da sua hospedagem. Você pode utilizar a solução de e-mail básico do cPanel ou o e-mail profissional Titan em sua versão grátis.',
    subscriptionsLabel: 'Assinatura',
    domainLabel: 'Domínio',
    mailBox: 'Conta de E-mail',
    titanLabel: 'Titan',
    cPanelLabel: 'cPanel',
    createFirstAccount: 'Crie sua primeira conta',
    createFirstAccountMenu: 'Criar primeira conta',
    createEmail: 'Definir Plataforma de E-mail',
    managerEmail: 'Gerenciar E-mails',
    domainFilterLabel: 'Hospedagens',
    hostingLabel: 'Hospedagem',
    dueDate: 'Data de Vencimento',
    terminatedDate: 'Data do Cancelamento',
    provisioningCardStatusNew: 'Ativando Período de Teste',
    pendingCardStatusNew: 'Aguardando Pagamento',
    toDueDateCardStatus: 'À Vencer',
    expiredCardStatusNew: 'Vencida',
    suspendedCardStatusNew: 'Suspensa',
    terminatedCardStatusNew: 'Cancelada',
    activeCardStatusNew: 'Ativo',
    renovationButton: 'Ativar renovação',
    almostCanceladCardStatus: '<0>Cancelamento próximo - </0>{{amount}}<2> dias</2>',
    trialCardStatus: '{{amount}}<2> dias disponíveis para testar</2>',
    trialCardStatusToDueDate: '{{amount}}<2> dias para o fim do teste grátis</2>',
    trialCardStatusOverDueDate: '{{amount}}<2> dias para a sua assinatura ser cancelada</2>',
    trialCardStatusTerminated: 'Período de teste grátis finalizado',
    provisioningCardStatusInfo: 'Estamos ativando seu plano de e-mail e em instantes você poderá aproveitar todos os benefícios do período de teste',
    pendingCardStatusInfo: 'Estamos aguardando a confirmação do pagamento. Boletos podem levar até 72h para compensar. Caso já tenha pago, por favor aguarde.',
    infoAutomaticRenovation: 'A renovação automática da assinatura foi cancelada. Ative a renovação e evite que seus dados sejam excluídos permanentemente após a data de vencimento.',
    uponDueDateCardStatusInfo: 'Sua assinatura vencerá em breve. Pague sua fatura e não corra o risco de perder seus e-mails.',
    uponDueDateTrialCardStatusInfo: 'O período de teste está finalizando e a renovação da assinatura vencerá em breve. Pague sua fatura e não corra o risco de perder seus e-mails.',
    expiredCardStatusInfo: 'Sua assinatura venceu e será suspensa em breve. Pague sua fatura e não corra o risco de perder seus e-mails.',
    suspendedCardStatusInfo: 'Sua assinatura foi suspensa por falta de pagamento. Pague sua fatura para que ela não seja cancelada e seus dados sejam excluídos definitivamente.',
    terminatedCardStatusInfo: 'Sua assinatura foi cancelada por falta de pagamento.',
    titleConfirmRenovation: 'Deseja ativar a renovação da assinatura?',
    descriptionConfirmRenovation: 'A cobrança de pagamento será efetuada no final do ciclo, evitando que seu produto seja cancelado e seus dados e arquivos sejam excluídos permanentemente.',
    bottonConfirmRenovation: 'Confirmar ativação',
    titanIconInfo: 'Plataforma de E-mail Titan',
    cPanelIconInfo: 'Plataforma de E-mail cPanel',
    contractNewEmailPlan: 'CONTRATAR NOVO E-MAIL',
    contractNewEmailPlanMobile: 'Contratar novo e-mail',
    chipLinkLabel: 'Configurar E-mail',
    activeTrialTitle: 'Crie sua primeira conta de e-mail profissional Titan Essential',
    activeTrialDescription: 'O E-mail Profissional é uma solução da HostGator para que empreendedores tenham um endereço de e-mail personalizado com o nome da sua empresa.',
    activeTrialInfoPersonalize: 'E-mail personalizado @seudominio',
    activeTrialInfoSpace: '10 GB de armazenamento por conta',
    activeTrialInfoApp: 'Webmail e Apps para Android e iOS',
    activeTrialInfoAntv: 'Proteção anti-spam e antivírus',
    activeTrialInfoCalendar: 'Calendário e contatos integrados',
    activeTrialInfoMore: 'E muito mais!',
    activeTrialEmailAddress: 'Endereço de e-mail',
    placeHolderEmailAddress: 'ex.: contato',
    activeTrialDomain: 'Domínio',
    activeTrialPassword: 'Senha',
    errorDomainRegistration: 'O domínio não está registrado. Escolha um domínio que esteja registrado para ativar o período de teste.',
    errorActivateTrial: 'Algo deu errado. Tente novamente mais tarde.',
    successAtivateTrial: 'Em instantes você poderá aproveitar todos os benefícios do período de teste.',
    accessEmailTrial: 'Clique aqui para acessar seu e-mail',
    activeTrialButtonStart: 'Iniciar Período de Teste',
    activeTrialPosDescription: '<0>Após o período grátis, a assinatura mensal é </0>{{amount}}<1> por conta com renovação automática. Cancele quando quiser. <1>',
    activeTrialTerms: 'Termos de Serviços',
    activeTrialFreeTime: '30 DIAS GRÁTIS PARA TESTAR',
    ilimitedMailBoxes: 'contas de e-mail ilimitadas',
    cpanelListInfo: 'Você possui uma conta de e-mail vinculada à sua hospedagem. Mantenha seu plano de hospedagem em dia para não afetar o funcionamento do seu e-mail.',
    configAccountListInfo: 'Esta conta de e-mail é vinculada à uma hospedagem. Mantenha seu plano de hospedagem em dia para não afetar o funcionamento do seu e-mail.',
    listName: 'Lista de Planos',
    activeCardStatus: 'Ativo',
    pendingCardStatus: 'Pendente',
    provisioningCardStatus: 'Pendente',
    suspendedCardStatus: 'Suspenso',
    terminatedCardStatus: 'Cancelado',
    expiredCardStatus: 'Expirado',
    nullCardStatus: 'Sem Plano de E-mail',
    almostExcludedCardStatus: '<0>Suspensão próxima - </0>{{amount}}<2> dias</2>',
    accountsUsed: 'Contas de E-mail Configuradas',
    expiringDate: 'Vencimento',
    managementLabel: 'Gerenciar',
    contractNewPlanLabel: 'CONTRATAR NOVO PLANO DE E-MAIL',
    checkAvailabilityLabel: 'VERIFICAR DISPONIBILIDADE',
    domainNoAvailabilityTitleLabel: 'O domínio selecionado não está registrado.',
    domainNoAvailabilityDescriptionLabel: 'Para comprá-lo, verifique a disponibilidade em nosso site.',
    hostingPlansTitleLabel: '<0>O domínio</0> <1>{{domain}}</1> <2>está vinculado a mais de um plano de hospedagem.</2>',
    hostingPlansDescriptionLabel: 'Para continuar, selecione abaixo qual plano de hospedagem você gostaria de vincular o novo plano de e-mail.',
    hostingPlansCreateDateLabel: 'Data de Registro',
    hostingPlansDueDateLabel: 'Vencimento',
    hostingPlansViewPlansLabel: 'VER PLANOS DE E-MAIL',
    billButton: 'Pagar Fatura',
    failedToLoadList: 'Não foi possível carregar a lista de e-mails profissionais.',
    getPlanLink: 'CONTRATAR PLANO DE E-MAIL',
    createEmailPlan: 'CRIAR PLANO DE E-MAIL',
    notifications: {
      activateRenewal: {
        success: {
          0: 'A renovação do  ',
          1: ' foi ativada',
        },
        failure: {
          0: 'A ativação da renovação do ',
          1: ' não pôde ser concluído. Por favor, tente novamente mais tarde.',
        },
      },
    },
    type: {
      titan: 'TITAN',
      cpanel: 'cPanel',
      free: 'Plano de E-mail Grátis',
    },
    emptyList: {
      title: 'Não encontramos nenhum resultado para sua busca :(',
      description: 'Você pode tentar procurar por outra palavra-chave para encontrar o que você procura.',
    },
    noPlan: 'Sem Plano de E-mail',
    proemail: {
      pendingMessage: '<0>Pagamento pendente. Realize o pagamento desse plano para ter acesso a criação e gerenciamento dos seus emails.</0> <1>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      freemiumPendingMessage: '<0>Pagamento pendente. Realize o pagamento do seu Plano de Hospedagem para ter acesso a criação e gerenciamento dos seus e-mails.</0> <1>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      suspendedMessage: '<0>Este plano está suspenso. Realize o pagamento para reativá-lo.</0> <1>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      terminatedMessage: '<0>Conforme solicitado, o seu plano foi cancelado e todos os dados e registros foram deletados.</0>',
      expiredMessage: '<0>Devido a falta de pagamento o seu plano de e-mail profissional expirou e todos os dados foram deletados.</0>',
      old_terminatedMessage: '<0>Conforme solicitado, o seu plano foi cancelado e todos os dados e registros foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
      old_expiredMessage: '<0>Devido a falta de pagamento o seu plano de e-mail profissional expirou e todos os dados foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
      almostExpiredMessageFree: '<0>O plano de hospedagem vinculado a sua conta de e-mail gratuita da TITAN expirou.</0> <1>Faça o upgrade do seu plano</1> <2>para mantê-lo ativo.</2>',
      almostExpiredMessageTitan: '<0>Este plano de e-mail venceu e entrará em suspensão em breve. Realize o pagamento da sua </0><1>fatura de renovação</1><2> para mantê-lo ativo. </2><3>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</3>',
      almostExpiredMessageCpanel: '<0>O plano de hospedagem ao qual seu e-mail está vinculado expirou. Realize o pagamento da sua </0><1>fatura de renovação</1><2> para manter o seu plano de e-mail ativo. </2><3>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</3>',
    },
    cpanel: {
      basic: 'E-mail Básico da Hospedagem',
      pendingMessage: '<0>Pagamento pendente. Realize o pagamento do seu Plano de Hospedagem para ter acesso a criação e gerenciamento dos seus emails.</0> <1>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      suspendedMessage: '<0>Este plano está suspenso. Realize o pagamento do seu Plano de Hospedagem para reativá-lo.</0> <1>Atenção: Pagamentos por boleto podem levar até 72 horas para serem identificados.</1>',
      terminatedMessage: '<0>Conforme solicitado, o seu Plano de Hospedagem foi cancelado e por consequencia todos os emails e dados foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
      expiredMessage: '<0>Devido a falta de pagamento, seu Plano de Hospedagem expirou. Todos os emails e dados foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
      old_terminatedMessage: '<0>Conforme solicitado, o seu Plano de Hospedagem foi cancelado e por consequencia todos os emails e dados foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
      old_expiredMessage: '<0>Devido a falta de pagamento, seu Plano de Hospedagem expirou. Todos os emails e dados foram deletados.</0> <1>Contrate um novo plano no nosso site.</1>',
    },
    filterOptions: {
      all: 'Todos os Domínios',
      proemail: 'Com e-mail titan',
      cpanel: 'Com e-mail básico da hospedagem',
      withoutPlan: 'Sem plano de e-mail',
    },
    upgrade: {
      underConstruction: {
        title: 'O upgrade de plano ainda não está disponível para este produto :/',
        description: 'Já estamos trabalhando para entregar uma solução em breve.',
        managementButton: 'VOLTAR PARA O GERENCIAMENTO DO PLANO',
      },
    },
    hirePlan: {
      annually: 'Anual',
      bestFormatToPayment: 'Selecione a melhor forma de pagamento para você:',
      containerDescriptionOld: 'Utilize as opções abaixo para contratar o seu novo plano de e-mail profissional.',
      containerDescription: 'Escolha o plano de e-mail ideal para seu projeto.',
      newContainerDescription: 'Escolha o plano de e-mail profissional ideal para seu negócio:',
      free: 'Titan Grátis',
      hireCTA: 'CONTRATAR PLANO',
      mailBoxes: 'Contas de E-mail',
      monthly: 'Mensal',
      paymentCycle: 'Ciclo de Pagamento',
      perMailBoxLabel: 'mês / por conta de e-mail',
      perMailBoxLabelOld: 'por conta de e-mail',
      freeMailBoxLabel: 'para você utilizar enquanto quiser',
      activeFreemium: 'ATIVAR GRATUITO',
      descriptionFreemium: 'O seu plano de hospedagem possui um plano de e-mail grátis da Titan com 1 GB de armazenamento por caixa de e-mail.',
      perMonth: '/mês',
      perYear: '/ano',
      monthPayment: 'pagamento mensal',
      yearPayment: 'pagamento anual',
      recommended: ' (Recomendado)',
      save: 'Economize',
      savedPercent: '<0>{{savedAmount}}</0><1>% de desconto</1>',
      total: 'Total de ',
      totalOld: 'Total:',
      weRecommend: 'Recomendamos',
      cpanelChangeTitle: 'Prefere usar um webmail básico do cPanel?',
      cpanelChangeDescription: '<0>Ao contrário dos planos de e-mail profissional Titan, as contas de e-mail básico do cPanel (Roundcube)</0> <1> utilizam o espaço da sua hospedagem</1> <2>para armazenar as mensagens e arquivos de e-mail.</2>',
      cpanelEnableChange: 'Ativar E-mail Básico do cPanel',
      cpanelDialogActiveTitle: 'Continuar como cPanel?',
      cpanelDialogActiveDescription: '<0>Os próximos domínios adicionados ao </0><1>{{productName}}</1><2> terão e-mails cPanel ativados automaticamente.</2>',
      cpanelDialogActiveDescriptionAdvice: 'Ao selecionar a opção de e-mail, não será possível desfazê-la, ou seja, uma vez escolhido o e-mail cPanel, não é possível mudar para Titan gratuito.',
      cpanelDialogActiveAction: 'CONTINUAR COMO CPANEL',
      continueFreePlanTitle: 'Continuar como Titan Grátis?',
      continueFreePlanDescription: '<0>Os próximos domínios adicionados ao </0><1>{{hostingName}}</1><2> terão e-mails Titan Grátis ativados automaticamente.</2>',
      continueFreePlanDescriptionAdvice: 'Ao selecionar a opção de e-mail, não será possível desfazê-la, ou seja, uma vez escolhido o e-mail Titan, não é possível mudar para cPanel.',
      continueFreePlanAction: 'CONTINUAR COMO TITAN GRÁTIS',
      successActivateFreePlanTitle: 'E-mail Titan Grátis ativo com sucesso!',
      successActivateFreePlanDescription: 'Tudo pronto para você adicionar sua primeira conta de e-mail',
      successActivateFreePlanAction: 'ADICIONAR CONTA DE E-MAILS',
      successMoveCPanelTitle: 'E-mail cPanel ativo com sucesso!',
      successMoveCPanelDescription: 'Tudo pronto para você adicionar sua primeira conta de e-mail',
      successMoveCPanelAction: 'ADICIONAR CONTA DE E-MAIL',
      hostingPlan: 'plano de hospedagem',
      titanBasic: {
        topLabel: 'GRATUITO DA HOSPEDAGEM',
        productName: 'Titan Básico',
        gb: '1Gb',
        storage: 'de armazenamento',
        ctaButton: 'Ativar Titan Básico',
        description: 'O Titan Básico é uma opção gratuita de contas ilimitadas de e-mail que não utiliza o espaço de armazenamento da sua hospedagem.',
        link: 'Saiba mais',
      },
      titanEssentials: {
        topLabel: '30 DIAS GRÁTIS PARA TESTAR',
        productName: 'Titan Essentials',
        gb: '10Gb',
        storage: 'de armazenamento',
        ctaButton: 'Experimentar Grátis',
        // eslint-disable-next-line no-template-curly-in-string
        description: '<0>Após o período de teste grátis, a assinatura mensal é </0><1>R${{price}}</1><2> por conta de e-mail com renovação automática, podendo ser cancelado quando quiser.</2>',
        link: 'Termos de Serviços',
      },
      titanPremium: {
        topLabel: 'RECOMENDADO',
        productName: 'Titan Premium',
        gb: '50Gb',
        storage: 'de armazenamento',
        ctaButton: 'Ative agora',
        description: 'Mais espaço de armazenamento e recursos poderosos de produtividade.',
        link: 'Termos de Serviços',
        monthlyPriceEachEmail: 'por conta de e-mail',
        perMonth: '/mês',
        cycleTitle: 'Ciclo',
        paymentMethodTitle: 'Forma de pagamento',
        cycles: {
          monthly: 'Mensal',
          quarterly: 'Trimestral',
          semiannually: 'Semestral',
          annually: 'Anual',
          biennially: 'Bienal',
        },
        totalPrice: '<0>Total de </0><1>{{price}} </1><2>no </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>conta de e-mail.</6>',
        totalPriceMoreThanOne: '<0>Total de </0><1>{{price}} </1><2>no </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>contas de e-mail.</6>',
      },
      titanUltra: {
        topLabel: 'MAIS COMPLETO',
        productName: 'Titan Ultra',
        gb: '100GB',
        storage: 'de armazenamento',
        ctaButton: 'Ative agora',
        description: 'Mais espaço de armazenamento e recursos poderosos de produtividade.',
        link: 'Termos de Serviços',
        monthlyPriceEachEmail: 'por conta de e-mail',
        perMonth: '/mês',
        cycleTitle: 'Ciclo',
        paymentMethodTitle: 'Forma de pagamento',
        cycles: {
          monthly: 'Mensal',
          quarterly: 'Trimestral',
          semiannually: 'Semestral',
          annually: 'Anual',
          biennially: 'Bienal',
        },
        totalPrice: '<0>Total de </0><1>{{price}} </1><2>no </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>conta de e-mail.</6>',
        totalPriceMoreThanOne: '<0>Total de </0><1>{{price}} </1><2>no </2><3>ciclo {{cycle}} </3> <4> para </4><5>{{numberAccounts}} </5><6>contas de e-mail.</6>',
      },
    },
    upgradePlan: {
      whatIsincluded: 'O QUE ESTÁ INCLUSO',
      whatIsincludedInPaid: 'Tudo do Titan Básico, mais',
      downgradeMaessage: '<0>Caso queira reduzir o número de caixas de e-mail, é necessário excluí-las através do link </0><1>Contas de E-mail<1/><2></2>',
      changeInboxLabel: 'Alterar',
      changeInboxLabelAlt: 'editar quantidade de inbox',
      changePlan: 'ALTERAR PLANO',
      modalTitle: 'Selecionar Contas de E-mail',
      modalDescription: 'Utilize os botões abaixo para selecionar a quantidade de contas de e-mail que você gostaria de ter no seu plano. <1>O valor a pagar será proporcional à quantidade de contas de e-mail contratadas.</1>',
      modalPlanTitle: 'Plano',
      modalPerMailTitle: 'Valor por conta de E-mail',
      modalRecurrentTitle: 'Valor Recorrente',
      modalCancel: 'Cancelar',
      modalSave: 'Salvar',
      upgradeMemorial: {
        initialSingleInbox: 'Caixa',
        initialMultipleInbox: 'Caixas',
        singleInbox: 'Caixa',
        multipleInbox: 'Caixas',
        multipleThis: 'estes',
        singleThis: 'este',
        singleDays: 'dia',
        multipleDays: 'dias',
        monthlyCycle: '30 dias',
        annuallyCycle: 'um ano',
        singleCapsThis: 'Este',
        multipleCapsThis: 'Estes',
        singleRemnant: 'resta',
        multipleRemnant: 'restam',
        singleEquivalent: 'equivale',
        multipleEquivalent: 'equivalem',
        singleRemaining: 'restante',
        multipleRemaining: 'restantes',
        upgradeDefault: '<0>Você já pagou {{amountUsed}} para utilizar {{cycleText}} no seu </0><1>{{planName}} - Titan com {{initialInboxAmount}} {{initialInboxText}} de E-mail </1><2>e ainda lhe {{remnantText}} {{remnantDays}} {{daysText}} para usar. {{thisTexts}} {{daysText}} {{remainingText}} {{equivalentText}} a {{refund}}, que serão devolvidos em forma de desconto na sua fatura ao realizar a atualização para o </2><3>{{newPlanName}} - Titan com {{inboxAmount}} {{inboxText}} de E-mail.</3>',
        upgradeNegativeDays: '<0>Você já pagou {{amountUsed}} para utilizar {{cycleText}} no seu </0><1>{{planName}} - Titan com {{initialInboxAmount}} {{initialInboxText}} de E-mail. </1><2>O seu plano venceu, mas permanecerá ativo por mais {{remnantDays}} {{daysText}}. Como você já utilizou o plano pelo tempo contratado, nenhum desconto será aplicado na sua fatura. Ao fazer o upgrade para um </2><3>{{newPlanName}} - Titan com {{inboxAmount}} {{inboxText}} de E-mail</3><4>, sua assinatura será renovada automaticamente por mais {{cycleText}}.</4>',
        upgradeZeroDays: '<0>Você já pagou {{amountUsed}} para utilizar {{cycleText}} no seu </0><1>{{planName}} - Titan com {{initialInboxAmount}} {{initialInboxText}} de E-mail. </1><2>O seu plano atual vence hoje e como você já o utilizou pelo tempo contratado, nenhum desconto será aplicado na sua fatura. Ao fazer o upgrade para um </2><3>{{newPlanName}} - Titan com {{inboxAmount}} {{inboxText}} de E-mail</3><4>, sua assinatura será renovada automaticamente por mais {{cycleText}}.</4>',
      },
      modalOldInvoice: {
        title: 'Gostaria de cancelar a fatura em aberto e gerar nova?',
        subtitle: 'Você possui uma fatura em aberto para esse plano',
        ongoingTitle: 'Fatura em aberto',
        viewOngoingInvoiceLabel: 'Ver fatura',
        ongoingSubtitle: 'Ao clicar em "Gerar nova fatura" essa fatura será cancelada.',
        mailBoxItem: 'Contas de E-mail',
        totalValue: 'Saldo à Pagar',
        warning: 'Se você já pagou essa fatura basta aguardar a ativação automática do seu plano. Pagamentos por Boleto Bancário podem levar até 72 hrs para serem processados.',
        close: 'FECHAR',
        execute: 'GERAR NOVA FATURA',
        pendingLabel: 'Pendente',
        aplliedCredit: 'Descontos aplicado',
      },
      actualPlan: 'Plano atual',
      newPlanCardTitle: 'Planos Disponíveis',
      newPlanCardDescription: 'Selecione uma das opções abaixo para solicitar a atualização do seu plano e receber mais armazenamento para suas caixas de e-mail:',
      storage: 'de armazenamento',
      monthlyPerMailBox: ' ao mês / conta de e-mail',
      freeTrialPeriod: 'em Período Gratuito',
      annuallyPerMailBox: '/ano por conta de e-mail',
      savedPercent: '<0>( </0>{{savedAmount}}<1>% de desconto )</1>',
      instantAccess: '( Acesso imediato ao plano )',
      paymentMethod: 'Forma de Pagamento',
      invoiceCardTitle: 'Fatura',
      buyMoreAccountsLink: 'Se desejar, compre mais contas de e-mail.',
      boughtMailBoxes: 'Contas de e-mail',
      valuePerMailBox: 'Valor por Conta de e-mail',
      recurrentValue: 'Valor Recorrente',
      nextDueDate: 'Próximo Pagamento',
      changePlanTo: '<0>Atualização para o {{planName}} {{cycle}} com {{accounts}} contas de e-mail.</0><1>Período de contratação: ({{from}} - {{to}})</1>',
      planChangedTo: '<0>Atualização para o </0>{{plan}}',
      freeBenefits: "<ul><li><strong>1 GB</strong> de armazenamento por conta</li><li>Webmail e apps para Android e iOS</li><li>Suporte a Outlook, Gmail e outros aplicativos</li><li>Poderoso filtro anti-spam</li><li>Proteção antivírus avançada</li><li>Calendário e contatos integrados <span title='Com a Titan você mantem seus contatos organizados e pode acessar outros calendários, como Google ou Outlook, para ter um resumo completo do seu dia.'>i</span></li><li>Suporte multi-contas <span title='Configure contas de e-mail essenciais como vendas@seudominio.com e contato@seudominio.com e alterne-as sem esforço com o suporte multi-contas da Titan.'>i</span></li><li>Importação de e-mails e contatos existentes com apenas um clique <span title='Uma nova conta de e-mail profissional não significa necessariamente deixar seus contatos de e-mails antigos para trás. As ferramentas de importação integradas da Titan tornam mais fácil começar uma nova conta.'>i</span></li></ul>",
      error: 'Ocorreu um erro inesperado, tente novamente mais tarde',
      success: 'Sua fatura foi paga e a alteração do plano de e-mail foi realizada com sucesso.',
      successWithCredits: 'Os créditos da sua conta foram aplicados no pagamento da fatura e a alteração do plano de e-mail foi realizada com sucesso',
      upgradeMailBoxTitle: 'Alteração do número de contas de e-mail',
      upgradeMailBoxDescription: 'Selecione abaixo o número de contas de e-mail que gostaria de ter e prossiga para realizar o pagamento.',
      upgradeMailBoxSelectMailBoxAmount: 'Selecione o número de contas de e-mail',
      upgradeMailBoxValuePerMailBoxAmount: 'Valor por Conta de E-mail',
      upgradeMailBoxMinExplanation: 'Você ja possui {{min}} contas de e-mail criadas nesta conta, logo não é possível reduzir este valor.',
      monthly: '/mês',
      Monthly: 'Mensal',
      quarterly: '/trimestral',
      Quarterly: 'Trimestral',
      semiannually: '/semestral',
      Semiannually: 'Semestral',
      annually: '/ano',
      Annually: 'Anual',
      Biennially: 'Bienal',
      biennially: '/bienal',
      creditCard: 'Cartão de crédito',
      gp_boleto: 'Boleto Bancário',
      gp_paypal: 'PayPal',
      upgradeMailBoxTotalValue: 'Valor Total',
      upgradeMailBoxTotalValueExplanation: '<0>Este é o valor total recorrente que você pagará mensalmente pelo seu</0> {{planName}} <2>- TITAN após a alteração do seu plano.</2>',
      upgradeMailBoxPaymentCycle: 'Ciclo de Pagamento',
      upgradeMailBoxNextDueDate: 'Próximo Pagamento',
      upgradeMailBoxNextDueDateExplanation: '<0>Esta é a nova data de vencimento da sua fatura para o plano</0> {{planName}} <2>- TITAN</2>',
      upgradeMailBoxAdvertisement: 'Os dias restantes no ciclo atual do seu plano serão aplicados em forma de crédito nesta fatura.',
      invoicesTitle: 'Fatura',
      description: 'Descrição',
      value: 'Valor',
      upgradeMessage: '<0>Upgrade de </0>{{from}}<2> para </2>{{to}}<4> Contas de E-mail </4>',
      downgradeMessage: '<0>Downgrade de </0>{{from}}<2> para </2>{{to}}<4> Contas de E-mail </4>',
      subTotal: 'Sub Total',
      apllyableCredit: 'Descontos aplicáveis nesta fatura',
      toPay: 'Saldo à Pagar',
      remnantCredit: 'Créditos remanescentes na conta',
      remnantCreditExplanation: 'Os cŕeditos remanescentes ficam salvos na sua conta e serão aplicados nas próximas faturas.',
      changeCycleOrPlanLink: 'Se desejar, altere seu plano de e-mail.',
      selectOther: 'Selecione um novo número de contas de e-mail e defina o ciclo de pagamento para visualizar sua fatura atualizada',
      selectOtherCycle: 'Selecione um novo plano ou altere o ciclo de pagamento para gerar e pagar sua fatura.',
      newSelectOtherCycle: 'Selecione um novo plano e defina o ciclo de pagamento para visualizar sua fatura atualizada.',
      selectOtherCycleFromFree: 'Selecione um plano diferente do atual para visualizar sua fatura atualizada.',
      monthlyRecurrentValueTooltip: '<0>Este é o valor total recorrente que você pagará mensalmente pelo seu </0> {{planName}}<1> - TITAN após a alteração.</1>',
      annuallyRecurrentValueTooltip: '<0>Este é o valor total recorrente que você pagará anualmente pelo seu </0> {{planName}}<1> - TITAN após a alteração.</1>',
      newNextduedateTooltip: '<0>Esta é a nova data de vencimento da sua fatura para o </0> {{planName}}<1> - TITAN.</1>',
      higherPlan: 'Você já possui o plano com maior capacidade de armazenamento.',
      contractedPeriod: 'Período de contratação:',
    },
  },
  customerProfileSurvey: {
    title: 'Conte-nos um pouco mais sobre o que você gostaria de fazer com o seu site <1>{{domain}}</1> para que possamos lhe ajudar a ter uma ótima experiência ao longo da sua jornada na HostGator :)',
    submitButton: 'Concluir',
    answersRequired: '* resposta obrigátoria',
    messageSuccess: 'Obrigado pelas respostas :)',
    'question-1': {
      label: 'Que tipo de site você tem ou pretende criar?',
      otherLabel: 'Outro',
      options: {
        1: 'Site pessoal',
        2: 'Site institucional (de uma empresa)',
        3: 'Blog',
        4: 'Loja Virtual (E-commerce)',
        5: 'Página para captação de clientes (Landing page)',
        6: 'Plataforma EAD',
        7: 'Outro',
      },
    },
    'question-2': {
      label: 'Qual plataforma foi ou será usada para criar o site?',
      otherLabel: 'Outra plataforma',
      options: {
        1: 'Criador de Sites da HostGator',
        2: 'WordPress',
        3: 'Ainda não sei',
        4: 'Não vou usar plataforma de criação de sites',
        5: 'Outra plataforma',
      },
    },
  },
  costumerPortalSurvey: {
    title: 'Complete seu perfil',
    welcome: {
      0: 'Queremos ',
      1: 'saber mais sobre o seu perfil para te oferecer uma melhor experiência.',
    },
    explanation: {
      0: 'São ',
      1: '4 perguntinhas rápidas de múltipla escolha. ',
      2: 'Responda quando quiser :)',
    },
    start: 'Iniciar',
    jump: 'Pular',
    send: 'Enviar',
    selectPlaceholder: 'Selecionar',
    otherInputPlaceholder: 'Qual?',
    congrats: 'Parabéns por concluir e completar o seu perfil! 👏',
    finalText: 'Essas respostas nos ajudarão a entregarmos melhores produtos e serviços. :)',
    finishLabel: 'Fechar',
    counterLabel: 'Perguntas',
    questions: {
      'question-1': {
        label: 'Pensando em hospedagem e criação de sites, você se considera uma pessoa:',
        options: {
          1: 'Iniciante - será minha primeira experiência criando site ou já tentei mas não consegui',
          2: 'Intermediária - até já criei site mas não é algo que eu domino ainda',
          3: 'Técnica - já fiz outros sites antes e entendo bem do assunto',
        },
      },
      'question-2': {
        label: 'Como você definiria seu perfil profissional?',
        options: {
          1: 'Freelancer (atendo alguns clientes)',
          2: 'Empreendedor (já tenho ou estou iniciando um negócio onde serei o dono)',
          3: 'Contratado de uma empresa',
          4: 'Estudante',
          5: 'Outro (Qual?)',
        },
      },
      'question-3': {
        label: 'Do que se trata o projeto ou negócio principal?',
        options: {
          1: 'Alimentação & Bebidas',
          2: 'Arte & Design',
          3: 'Beleza & Bem estar',
          4: 'Casa & Decoração',
          5: 'Comunicação & Marketing',
          6: 'Construção',
          7: 'Consultoria & Coaching',
          8: 'Conteúdo Adulto',
          9: 'Cultura (livros, música e cinema)',
          10: 'Educação',
          11: 'Esporte & Fitness',
          12: 'Estilo de vida',
          13: 'Finanças & Direito',
          14: 'Fotografia',
          15: 'Moda & Vestuário',
          16: 'Religião',
          17: 'Saúde',
          18: 'Serviços & Manutenção',
          19: 'Tecnologia & Desenvolvimento Web',
          20: 'Viagem',
          21: 'Outro (Qual?)',
        },
      },
      'question-4': {
        label: 'O site é para quem? Quem é o dono do projeto?',
        options: {
          1: 'Para mim mesmo',
          2: 'Para um cliente',
          3: 'Para a empresa que eu trabalho',
          4: 'Para um amigo ou conhecido',
        },
      },
    },
  },
  bannerTitanTrial: {
    title: 'Crie seu novo {{ productName }}',
    tagOfTrialTime: '30 DIAS GRÁTIS PARA TESTAR',
    selectLabel: 'Selecione um domínio disponível',
  },
  modalTitanTrial: {
    title: 'Criar e-mail profissional <strong>{{ domain }}</strong>',
    tagOfTrialTime: '30 DIAS GRÁTIS PARA TESTAR',
    productDescription: 'O <strong>{{ productName }}</strong> é uma solução da HostGator para que empreendedores tenham um endereço de e-mail personalizado com o nome da sua empresa.',
    advantages: {
      1: 'E-mail personalizado @seudominio',
      2: '10 GB de armazenamento por conta',
      3: 'Webmail e Apps para Android e iOS',
      4: 'Proteção anti-spam e antivírus',
      5: 'Calendário e contatos integrados',
      6: 'E muito mais!',
    },
    form: {
      emailAddressLabel: 'Endereço de e-mail',
      emailAddressPlaceholder: 'ex.: contato',
      domainLabel: 'Domínio',
      passwordLabel: 'Senha',
      passwordError: 'A senha deve conter no mínimo 8 caracteres',
      submitButton: 'Iniciar período de teste',
      // eslint-disable-next-line no-template-curly-in-string
      termsOfService: 'Após o período grátis, a assinatura mensal é R${{ price }} por conta com renovação automática. Cancele quando quiser. <a href="{{ termsUrl }}" target="_blank" rel="noreferrer" class="footer-text__link">Termos de Serviços</a>',
    },
  },
  modalTitanTrialHirePlan: {
    buttonAddAccount: {
      label: 'Adicionar mais uma conta',
    },
    emailSuggestions: {
      1: 'ex.: contato',
      2: 'ex.: vendas',
      3: 'ex.: atendimento',
      4: 'ex.: suporte',
      5: 'ex.: financeiro',
    },
    title: 'Titan Essentials 10GB',
    tagOfTrialTime: '30 DIAS GRÁTIS PARA TESTAR',
    confirmText: 'Estou ciente que ao iniciar o período de teste grátis do e-mail Titan Essentials não poderei realizar o downgrade para o Titan Básico (gratuito da hospedagem).',
    productDescription: {
      1: 'O ',
      2: ' é uma solução da HostGator para que empreendedores tenham um endereço de e-mail personalizado com o nome da sua empresa.',
      3: 'Aproveite agora mesmo e crie sua primeira conta de e-mail profissional com ',
      4: '30 dias grátis! ',
    },
    termDescriptions: '<0>Após o período de teste grátis, a assinatura mensal do </0><1> {{productName}}</1> é no valor de <2>{{amount}} por conta de e-mail </2><3>com renovação automática, podendo ser cancelada quando quiser.</3>',
    titleForm: 'Crie a sua primeira conta de e-mail profissional Titan Essentials',
  },
  bannerTitanTrialInSites: {
    title: 'Crie sua primeira conta de e-mail profissional Titan Essential',
    description: 'O E-mail Profissional é uma solução da HostGator para que empreendedores tenham um endereço de e-mail personalizado com o nome da sua empresa.',
    tagOfTrialTime: '30 DIAS GRÁTIS PARA TESTAR',
  },
  modalToAlertUpgradeWhenInTrialPlan: {
    title: 'Alteração de plano',
    description: '<strong>Você ainda tem {{ remainingPeriod }} dias de teste grátis</strong>, mas não se preocupe, você não vai perder isso que falta. Ao confirmar a alteração do plano <strong>vamos adicionar o que resta como dias extras</strong> ao final do ciclo contratado.',
    buttons: {
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR ALTERAÇÃO',
    },
  },
  customerProfile: {
    start: 'Vamos iniciar?',
    skip: 'Pular',
    send: 'Enviar',
    questions: 'Perguntas',
    placeholder: 'comentar',
    select: 'Selecionar',
  },
  sites: {
    tooltip: 'Esta tela é dedicada ao gerenciamento de sites de hospedagens compartilhadas.',
    newTooltip: {
      0: 'Veja os seus',
      1: ' sites hospedados aqui na HostGator :)',
    },
    pageDescription: {
      empty: {
        title: 'Esta tela é dedicada ao gerenciamento de sites de hospedagens compartilhadas.',
        subtitle: 'Veja abaixo o que deseja fazer:',
      },
      hasOtherThanPendingStites: {
        singleSubtitle: 'Veja os seus sites hospedados aqui na HostGator :)',
        composedSubtitle: {
          0: 'Escolha a plataforma de criação ',
          1: 'para os sites abaixo ou outros domínios que você tenha',
          2: ' e inicie a criação desses sites ',
          3: ':)',
        },
        buttonLabel: 'Criar site',
      },
      onlyPendingSites: {
        subtitle: 'Aguarde a ativação das assinaturas para iniciar a criação do seu site :)',
      },
    },
    emptyOptions: {
      hireHost: 'Contratar uma hospedagem',
      hirePlan: 'Contratar um domínio',
      manageServer: 'Gerenciar seu Servidor',
      manageEmails: 'Gerenciar contas de e-mail',
      manageDomains: 'Gerenciar domínios',
      manageSecurity: 'Gerenciar produtos de segurança',
      manageAcademy: 'Ver cursos do HostGator Academy',
    },
    modalExclusion: {
      title: 'Desativação do Criador de Sites',
      descriptor: {
        0: 'Está ação é irreversível! ',
        1: 'O Criador de Site do domínio ',
        2: ' será desabilitado e você não terá mais acesso a ferramenta.',
      },
      radioText: 'Desejo remover o Criador de Sites mesmo assim.',
      cancelLabel: 'Desistir da ação',
      continueLabel: 'Confirmar desativação',
    },
    exclusionToast: {
      success: 'O Criador de Sites foi desabilitado e o conteúdo do seu site removido.',
      failure: 'Não foi possível executar essa ação. Tente novamente mais tarde.',
    },
    filter: {
      options: {
        all: 'Todos',
        out: 'Fora do ar',
        pending: 'Com pendência financeira',
        filterBy: 'Filtrar por:',
      },
      placeholder: 'Busque por domínio',
      clear: 'Limpar filtros',
      noResults: {
        field: 'Não encontramos nenhum site com o nome digitado acima.',
        option: '<0>Não encontramos nenhum site </0><1>"{{status}}"</1><2>.</2>',
      },
    },
    createSiteBanner: {
      title: {
        0: 'Crie um site',
        1: ' e deixe sua marca na internet!',
      },
      subtitle: 'Escolha a plataforma de criação, instale, veja dicas de design e de como criar o conteúdo. :)',
      button: 'Criar site ou Loja virtual',
      tools: {
        wordpress: 'WordPress',
        sitebuilder: 'Criador de sites',
        other: 'Outras ferramentas',
      },
    },
    siteModals: {
      domainCanceled: {
        title: 'Site indisponível',
        contentDomainName: 'O domínio {{domain}} não está mais registrado na HostGator.',
        contentsDomainName: 'Os domínios {{domain}} não estão mais registrados na HostGator.',
        contentInfo: ' Ele estará disponível na lista de sites, pois seu conteúdo continua com a gente. Caso queira registrar o domínio novamente ',
        contentsInfo: ' Eles estarão disponíveis na lista de sites, pois seu conteúdo continua com a gente. Caso queira registrar os domínios novamente ',
        contentRedirect: 'acesse o site da HostGator.',
        button: 'Entendido',
      },
      planCanceled: {
        title: 'Site removido',
        contentDomainName: '<0>Devido o cancelamento do plano, </0><1>{{domain}} e os demais domínios que estavam nesse plano não estão mais disponíveis</1><2> na lista de site. Caso queira contratar um novo plano </2>',
        contentRedirect: 'acesse o site da HostGator.',
        button: 'Entendido',
      },
      newSite: {
        title: '<0>Crie agora mesmo um site para </0><1>{{domain}}</1>',
        contentInfo: 'Escolha a plataforma de criação, instale e veja dicas de design e como criar o conteúdo :)',
        button: 'Criar site',
      },
      createSite: {
        title: {
          0: 'Vamos expandir sua marca',
          1: ' criando um novo site?',
        },
        description: {
          0: '5 bilhões de pessoas no mundo estão usando a internet!',
          1: 'Não perca tempo e crie um site personalizado para',
          2: ' {{domain}}',
        },
        toolsText: {
          0: 'Você pode usar o',
          1: ' Criador de Sites, Wordpress',
          2: ' ou outras ferramentas para instalar e personalizar. :)',
        },
        buttonCreate: 'Criar site',
        buttonClose: 'Não quero expandir minha marca',
      },
      createSiteWithPartners: {
        title: {
          0: 'Faça seu site ',
          1: 'do jeito que você imaginou!',
        },
        description: {
          0: 'Conte com um ',
          1: 'especialista em criação de sites ',
          2: 'para o seu projeto ou faça você mesmo. :)',
        },
        toolsText: {
          0: 'Você pode usar o',
          1: ' Criador de Sites, Wordpress',
          2: ' ou outras ferramentas para instalar e personalizar. :)',
        },
        buttonCreate: 'Criar com um especialista',
        buttonClose: 'Criar eu mesmo',
      },
      ecommercePlusOffer: {
        title: 'Pronto para criar sua loja online?',
        descriptionFirst: {
          0: 'Crie sua loja online agora mesmo para o domínio',
          1: ' {{domain }} ',
          2: 'e aproveite os 14 dias de teste do plugin E-commerce Plus.',
        },
        descriptionSecond: 'Nele você irá ter acesso a várias ferramentas especializadas para tornar sua loja um sucesso!',
        continue: 'Criar loja online',
        skip: 'Vou criar mais tarde',
      },
    },
  },
  warningStatus: {
    pending: {
      label: 'Aguardando a compensação do pagamento',
      tooltip: 'A compensação de pagamento por boleto leva até 72 horas. Caso já tenha pago, por favor, aguarde.',
      buttonLabel: 'Pagar fatura',
    },
    analysis: {
      label: 'Realizando análise de segurança. Esse processo pode levar até 48 horas',
      tooltip: 'Toda nova compra passa pelo processo de análise de segurança antes de ser definitivamente aprovada. É um procedimento normal, não se preocupe.',
    },
    nearDue: {
      day: 'dia',
      days: 'dias',
      label: '{{date}} para o site ficar fora do ar',
      tooltip: 'Sua fatura de renovação já está disponível. Realize o pagamento e evite que seu site fique fora do ar.',
      buttonLabel: 'Pagar fatura',
    },
    overdue: {
      tooltip: 'Ainda não identificamos o pagamento da sua fatura de renovação e ela já está vencida. Realize o pagamento e evite que seu site fique fora do ar.',
      buttonLabel: 'Pagar fatura',
    },
    suspended: {
      subLabel: 'Site fora do ar',
      subLabelTooltip: 'Seu site está fora do ar porque ainda não identificamos o pagamento da fatura de renovação.',
      label: '{{date}} para o site ser cancelado e ter seus arquivos excluídos',
      tooltip: 'Ainda não identificamos o pagamento da sua fatura de renovação. Realize o pagamento e evite perder seu site e arquivos.',
      day: 'dia',
      days: 'dias',
    },
  },

  academyPassTrial: {
    titleCard: 'Assinatura Academy Pass',
    title: '30 dias grátis para você aprender a alavancar seus resultados na internet',
    descriptionText: 'O passo-a-passo que faltava para você aprender a aumentar seus resultados na internet através de cursos rápidos e dinâmicos, feitos para quem não tem tempo a perder.',
    descriptionTextBold: 'E o melhor: com 30 dias grátis para você testar',
    button: 'Iniciar meu teste grátis',
    footer: '*Após o término do período de testes de 30 dias, será cobrado o valor de R$19,90 por mês, na forma de pagamento escolhida pelo cliente no momento da ativação.',
    middleSectionDust: {
      0: 'Deixe a concorrência comendo poeira',
      1: 'Aprenda com experts do mercado as melhores técnicas para',
      2: ' aumentar as suas chances de sucesso na internet',
      3: ' e conquistar muito mais resultados.',
      button: 'Quero testar grátis',
    },
    middleSectionKnowledge: {
      0: 'Não importa o seu nível de conhecimento no assunto',
      1: 'Todos os cursos são 100% atualizados e organizados para você ter um',
      2: ' passo-a-passo de como aplicar em seu negócio',
      3: ' os conhecimentos aprendidos.',
      button: 'Quero testar grátis',
    },
    middleSectionSaveMoney: {
      0: 'Feitos para você economizar tempo e dinheiro',
      1: 'Chega de perder tempo e dinheiro comprando vários cursos. Com o HostGator Academy você tem',
      2: ' dezenas de cursos sobre variados assuntos em um só lugar.',
      3: ' E hoje, por 30 dias totalmente grátis.',
      button: 'Iniciar teste grátis',
    },
    middleSectionVideo: {
      title: 'Conheça um pouco mais dos cursos mais famosos do HostGator Academy',
      descInfOne: '+200 mil alunos',
      descInfTwo: '500 aulas disponíveis',
      descInfThree: '30 professores experts',
      linkVideo: 'https://www.youtube.com/embed/SDDR-0ssKxA?enablejsapi=1&origin=http://cliente.whmcs.brasil/',
      button: 'Quero iniciar meu teste grátis',
    },
    middleSectionCards: {
      title: 'Nossos cursos já transformaram o negócio de muitos empreendedores e você pode ser o próximo.',
      textCardOne: 'Conteúdo de fácil aprendizagem, totalmente personalizável e com recursos que ainda não conhecia.',
      autorCardOne: '– Anderson Silva de Souza',
      footerCardOne: 'Crie um Site Profissional Hoje Mesmo',

      textCardTwo: 'Graças a Deus tive acesso a seu curso e que maravilha, consegui fazer tudo funcionar. Valeu mesmo muito obrigado.',
      autorCardTwo: '– Claudevan Macedo Bandeira',
      footerCardTwo: 'Meu Primeiro Site Wordpress no Ar',

      textCardThree: 'A melhor explicação desse assunto que eu pude ter comparado aos outros online que tem por aí.',
      autorCardThree: '– Amanda Cristina M. Monteiro',
      footerCardThree: 'Crie um Site Profissional Hoje Mesmo',
    },
    middleSectionFooter: {
      title: 'Agora é com você!',
      textFooter: {
        0: 'Aproveite a chance de aprender ',
        1: 'técnicas que vão revolucionar o seu negócio na internet ',
        2: 'com os melhores profissionais do mercado. Tudo isso em uma metodologia fácil e 100% prática por 30 dias grátis.',
      },
      btnFooter: 'Quero testar grátis agora',
    },
  },
  codeGuardLP: {
    mainTitle: 'Assinatura CodeGuard',
    getNow: {
      title: {
        0: 'Seu site seguro ',
        1: 'com backups diários',
      },
      description: 'Proteção total e monitoramento para que seus dados estejam sempre em segurança',
      listTitle: {
        0: 'Contrate o ',
        1: 'Codeguard Básico ',
        2: 'e conte com:',
      },
      featureList: {
        1: 'Backups diários automáticos',
        2: 'Restauração completa do site',
        3: 'Monitoramento diário de alterações no site',
        4: 'Rastreio de performance do backup',
        5: 'Banco de dados ilimitado',
        6: 'Backup de até 5 sites',
        7: '1 GB de armazenamento',
        8: 'E muito mais',
      },
      formTitle: 'Plano Básico',
      cycleLabel: 'Selecione o ciclo:',
      selectePaymentLabel: 'Selecione a forma de pagamento:',
      cycleOptions: {
        annually: 'Anual',
        semiannually: 'Semestral',
        quarterly: 'Trimestral',
        monthly: 'Mensal',
      },
      cyclePer: {
        annually: '/ano',
        semiannually: '/semestre',
        quarterly: '/trimestre',
        monthly: '/mês',
      },
      toBePaid: 'Valor a pagar:',
      hgCodeguard: 'https://www.hostgator.com.br/backup-online',
      needMoreLabel: 'Precisa de um plano mais robusto? Confira opções no nosso site:',
      needMoreButtonText: 'Ver todos os planos Codeguard',
    },
    detailsSections: {
      title: {
        0: 'Tenha o ',
        1: 'backup do seu site ',
        2: 'em nuvem com o Codeguard',
      },
      firstSection: {
        title: {
          0: 'Problemas podem acontecer com qualquer site. ',
          1: 'Esteja preparado!',
        },
        description: {
          0: 'O Codeguard cria ',
          1: 'cópias de segurança ',
          2: 'do seu site na nuvem para você resgatar em caso de emergência.',
          3: 'Com o backup online você pode recuperar seus sites e dados instantaneamente com apenas alguns cliques.',
        },
        buttonLabel: 'Ativar backup para meu site',
      },
      secondSection: {
        title: {
          0: 'Como o ',
          1: 'Codeguard ',
          2: 'protege o site?',
        },
        description: {
          0: 'O CodeGuard funciona como uma máquina do tempo do seu site. Se você tiver algum problema na configuração ou sofrer um ataque hacker, pode ficar tranquilo.',
          1: 'Você pode recuperar todo seu site com apenas alguns cliques e zero complicações.',
        },
        buttonLabel: 'Quero proteger meu site',
      },
    },
    discoverBenefits: {
      title: 'Descubra por que o Codeguard é perfeito para seu site',
      benefits: {
        0: {
          title: 'Backup automático e em nuvem',
          description: 'Deixe que o CodeGuard tome conta do seu site. Todo o processo é automatizado para você não se preocupar.',
        },
        1: {
          title: 'Restauração fácil e completa',
          description: 'Caso aconteça algum imprevisto, restaure todo seu site, ou arquivos específicos, com extrema facilidade.',
        },
        2: {
          title: 'Notificação de alterações no site',
          description: 'Tenha controle total das alterações que acontecem em seu site e monitore possíveis ameaças.',
        },
      },
      videoTitle: 'A máquina do tempo do seu site',
      videoDescription: 'Tenha um serviço de backup rápido e fácil, que rastreia todas as alterações do seu site diariamente.',
      buttonLabel: 'Quero mais proteção',
    },
    snappyBanner: {
      title: 'Seu site seguro com backups diários',
      buttonLabel: 'Contrate agora',
    },
  },
  asaas: asaas.BR,
  productDetailsBackup: {
    title: 'Backup',
    description: {
      0: 'O último backup de ',
      1: ' foi feito em ',
    },
    buttonLabel: 'Fazer backup',
  },
};
