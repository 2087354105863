import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background: ${theme.palette.white.light};
    color: ${theme.palette.grey.dark};
    padding: 24px 16px;
    font-family: ${theme.font.family.secondary};
  `}
`;

export const StepCount = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.palette.grey.ultralight};
    border-radius: 50%;
    border: 2px solid ${theme.palette.primary.main};
    display: flex;
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    height: 32px;
    justify-content: center;
    width: 32px;
  `}
`;

export const StepLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    margin-left: 16px;
    flex: 1;
  `}
`;

export const Step = styled.div`
  ${({
    theme, color, bgColor, stepDoneColor, stepDoneBgColor,
  }) => css`
    display: flex;
    align-items: center;

    &.active {
      ${StepCount} {
      border-color: ${bgColor};
      background-color: ${bgColor};
      color: ${color};
      }
    }

    &.disabled {

      ${StepCount} {
        border-color: ${theme.palette.grey.ultralight};
        color: ${theme.palette.grey.main};
      }

      ${StepLabel} {
        color: ${theme.palette.grey.main};
      }
    }

    &.done {
      ${StepCount} {
        border-color: ${stepDoneBgColor || theme.palette.grey.primary};
        background-color: ${stepDoneBgColor};
        svg {
          fill: ${stepDoneColor || theme.palette.grey.primary};
        }
      }

      ${StepLabel} {
        color: ${theme.palette.grey.dark};
      }
    }
  `}
`;

export const Steps = styled.div`
${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${theme.breakpoints.md}) {
      justify-content: flex-start;
      margin-left: 24px;
    }

    & ${Step}:not(:first-child) {
      margin-left: 40px;
    }
  `}
`;

export const StepperDesktop = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const StepperMobile = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: ${theme.breakpoints.md}) {
      display: block;
    }
  `}
`;

export const StepCounter = styled.div`
  position: relative;
`;

export const StepCounterText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    position: absolute;
    z-index: 1;
    text-align: center;
    margin: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  `}
`;
