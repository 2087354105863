import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

const MalwareItem = ({ item }) => (
  <TableRow>
    <TableCell>{item.url}</TableCell>
    <TableCell>{item.links.length}</TableCell>
    <TableCell>{item.level}</TableCell>
  </TableRow>
);
export default MalwareItem;
