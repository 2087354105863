import { CALL_API } from '@/middleware/api';
import {
  INVOICES_URL, INVOICE_DETAIL_URL, PAYMENT_METHOD_TICKET_INVOICE_ES, INVOICE_BOLETO_PROCESS,
  CHECK_USER_DATA_FOR_PAYMENT_BOLETO,
  BOLETO_UPDATE_ADDRESS_URL,
} from '@/config/api';
import { queryString } from '@/utils/Application/queryString';
import { INVOICES_STATUS, INVOICES_STATUS_WITHOUT_CAPITALIZE } from '@/config/invoices/invoiceStatus';
import { getInvoicePaymentMethods } from './invoicePaymentMethods';
import {
  REQUEST_INVOICES, RECEIVE_INVOICES, ERROR_INVOICES,
  RECEIVE_INVOICE_DETAILS, REQUEST_INVOICE_DETAILS, ERROR_INVOICE_DETAILS,
  UPDATE_UNPAID_INVOICES, UPDATE_PAID_INVOICES, REQUEST_PAYMENT_METHOD_TICKET_INVOICE_ES,
  RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES, ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES,
  REQUEST_BOLETO_URL, ERROR_BOLETO_URL, RECEIVE_BOLETO_URL,
  RECEIVE_BOLETO_VALIDATE_ADDRESS, REQUEST_BOLETO_VALIDATE_ADDRESS,
  ERROR_BOLETO_VALIDATE_ADDRESS, REQUEST_BOLETO_UPDATE_ADDRESS,
  SUCCESS_BOLETO_UPDATE_ADDRESS, ERROR_BOLETO_UPDATE_ADDRESS,
} from './actionsTypes';


const requestInvoices = status => ({
  type: REQUEST_INVOICES,
  data: status,
});

const receiveInvoices = (invoices, status) => {
  const byStatus = { [status]: invoices };
  const loadedDate = { [status]: new Date() };
  if (status === 'Unpaid') {
    byStatus[status].sort((dateA, dateB) => new Date(dateA.duedate) - new Date(dateB.duedate));
  } else {
    byStatus[status].sort((dateA, dateB) => new Date(dateB.duedate) - new Date(dateA.duedate));
  }

  return {
    type: RECEIVE_INVOICES,
    loadingByStatus: status,
    invoices,
    byStatus,
    loadedDate,
  };
};

const receiveInvoiceDetails = invoice => ({
  type: RECEIVE_INVOICE_DETAILS,
  invoice,
});

const receiveDataInvoices = (dataInvoices, status, invoicesOrignal = null) => {
  const invoices = dataInvoices.data;
  const byStatus = { [status]: invoices };
  const loadedDate = { [status]: new Date() };
  const paginationData = {
    [status]: {
      last_page: dataInvoices.last_page,
      next_page_url: dataInvoices.next_page_url,
      per_page: dataInvoices.per_page,
      to: dataInvoices.to,
      total: dataInvoices.total,
    },
  };

  if (status === 'Unpaid') {
    byStatus[status].sort((dateA, dateB) => new Date(dateA.duedate) - new Date(dateB.duedate));
  } else {
    byStatus[status].sort((dateA, dateB) => new Date(dateB.duedate) - new Date(dateA.duedate));
  }


  if (invoicesOrignal) {
    let invoicesUpdatedList = invoicesOrignal;
    const listTmp = invoicesOrignal;

    byStatus[status].forEach((item) => {
      if (!listTmp.some(i => item.id === i.id)) {
        invoicesUpdatedList = invoicesUpdatedList.concat(item);
      }
    });

    byStatus.all = invoicesUpdatedList;
  }

  return {
    type: RECEIVE_INVOICES,
    loadingByStatus: status,
    invoices,
    byStatus,
    loadedDate,
    paginationData,
  };
};

export const loadInvoicesWithParams = (status = '', search = '', page = null, invoices = null, callback) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${INVOICES_URL}?page${page !== null ? `=${page}` : ''}${status === 'all' ? '&status' : `&${queryString({ status: status.toLowerCase() })}`}${search === '' ? '' : `&${queryString({ search })}`}`,
    method: 'GET',
    actionTypes: {
      request: () => requestInvoices(status),
      success: response => (dispatch) => {
        dispatch(receiveDataInvoices(response.data, status, invoices));
        dispatch(getInvoicePaymentMethods());
        callback && callback();
      },
      error: () => ({ type: ERROR_INVOICES }),
    },
  },
});

/**
 * Load invoices from an given status
 * @param {String} status
 */
export const loadInvoices = (status, callback) => dispatch => dispatch({
  [CALL_API]: {
    authenticated: true,
    endpoint: `${INVOICES_URL}?${queryString({ status: status.toLowerCase() })}`,
    method: 'GET',
    actionTypes: {
      request: () => requestInvoices(status),
      success: response => (dispatch) => {
        callback && callback();
        dispatch(receiveInvoices(response.data.data, status));
        dispatch(getInvoicePaymentMethods());
      },
      error: () => ({ type: ERROR_INVOICES }),
    },
  },
});

export const loadInvoiceDetails = invoiceId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: INVOICE_DETAIL_URL(invoiceId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_INVOICE_DETAILS }),
      success: (response) => {
        const invoice = {
          ...response.data.invoice,
          items: response.data.invoiceItems,
          nfse: response.data.nfse,
        };

        return receiveInvoiceDetails(invoice);
      },
      error: () => ({ type: ERROR_INVOICE_DETAILS }),
    },
  },
});

const receiveUpdatedUnpaidInvoices = unpaidInvoices => ({
  type: UPDATE_UNPAID_INVOICES,
  data: unpaidInvoices,
});

const receiveUpdatedPaidInvoices = paidInvoices => ({
  type: UPDATE_PAID_INVOICES,
  data: paidInvoices,
});

export const updateStatusInvoiceToConfirmingPayment = invoiceId => (dispatch, getState) => {
  const unpaidInvoices = getState().invoices.byStatus.Unpaid;
  const paidInvoices = getState().invoices.byStatus.Paid;

  const indexUnpaidInvoice = unpaidInvoices.findIndex(item => item.id === invoiceId);
  const invoicePaid = unpaidInvoices[indexUnpaidInvoice];
  unpaidInvoices.splice(indexUnpaidInvoice, 1);
  return new Promise((resolve) => {
    dispatch(receiveUpdatedUnpaidInvoices(unpaidInvoices));

    if (paidInvoices.length > 0) {
      invoicePaid.status = INVOICES_STATUS.PAID;
      invoicePaid.statusMessage = INVOICES_STATUS_WITHOUT_CAPITALIZE.PAID;
      paidInvoices.push(invoicePaid);
      paidInvoices.sort((dateA, dateB) => new Date(dateB.duedate) - new Date(dateA.duedate));

      dispatch(receiveUpdatedPaidInvoices(paidInvoices));
    }
    resolve();
  });
};

export const payTicketInvoiceForES = invoiceId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: PAYMENT_METHOD_TICKET_INVOICE_ES(invoiceId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_PAYMENT_METHOD_TICKET_INVOICE_ES }),
      success: (response) => {
        if (!!response.data && response.success && response.data.result && response.data.url !== '') {
          return ({ type: RECEIVE_PAYMENT_METHOD_TICKET_INVOICE_ES, ...response.data });
        }
        return ({ type: ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES });
      },
      error: () => ({ type: ERROR_PAYMENT_METHOD_TICKET_INVOICE_ES }),
    },
  },
});

const requestGetBoletoUrl = invoiceId => ({
  type: REQUEST_BOLETO_URL,
  invoiceId,
});

const receiveGetBoletoUrl = dataCheck => ({
  type: RECEIVE_BOLETO_URL,
  dataCheck,
});

export const getBoletoUrl = invoiceId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: INVOICE_BOLETO_PROCESS(invoiceId),
    method: 'GET',
    actionTypes: {
      request: () => requestGetBoletoUrl(invoiceId),
      success: response => receiveGetBoletoUrl(response),
      error: () => ({ type: ERROR_BOLETO_URL }),
    },
  },
});

const receiveBoletoValidateAddress = data => ({
  type: RECEIVE_BOLETO_VALIDATE_ADDRESS,
  data,
});

export const checkBoletoUserData = invoiceId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: CHECK_USER_DATA_FOR_PAYMENT_BOLETO(invoiceId),
    method: 'POST',
    actionTypes: {
      request: () => ({ type: REQUEST_BOLETO_VALIDATE_ADDRESS }),
      success: response => receiveBoletoValidateAddress(response),
      error: () => ({ type: ERROR_BOLETO_VALIDATE_ADDRESS }),
    },
  },
});

const successBoletoUpdateAddress = data => ({
  type: SUCCESS_BOLETO_UPDATE_ADDRESS,
  data,
});

export const boletoUpdateAddress = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: BOLETO_UPDATE_ADDRESS_URL,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_BOLETO_UPDATE_ADDRESS }),
      success: response => successBoletoUpdateAddress(response),
      error: () => ({ type: ERROR_BOLETO_UPDATE_ADDRESS }),
    },
  },
});
