const styles = theme => ({
  contentWrapper: {
    [theme.breakpoints.up(960)]: {
      padding: '0 40px',
    },
  },
  filterWrapper: {
    marginBottom: 12.0,
  },
  planWrapper: {
    marginBottom: 10,
  },
});

export default styles;
