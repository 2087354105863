import React from 'react';
import * as Style from './IconDetailedOther.style';

const IconDetailedOther = ({
  color = '#4a4a4a',
  backgroundColor = '#3c97ff',
  size = '30',
  testId = 'icon-detailed-other',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" fill={color}>
      <g id="Grupo_40181" data-name="Grupo 40181" transform="translate(-867 -593)">
        <g id="Grupo_40149" data-name="Grupo 40149" transform="translate(867.731 593)">
          <rect id="Rectangle" width="15" height="30" transform="translate(14.27 0)" fill={backgroundColor} />
          <g id="interests_black_24dp" transform="translate(0 1.461)">
            <rect id="Retângulo_16063" data-name="Retângulo 16063" width="27.584" height="26.985" transform="translate(0 0)" fill="none" />
            <path id="Caminho_247881" data-name="Caminho 247881" d="M7.665,14.413a4.514,4.514,0,1,0,4.514,4.514A4.512,4.512,0,0,0,7.665,14.413Zm0,6.77a2.257,2.257,0,1,1,2.257-2.257A2.263,2.263,0,0,1,7.665,21.183Zm6.748-6.77V23.44H23.44V14.413Zm6.77,6.77H16.669V16.669h4.514ZM7.642,2,2,12.156H13.284Zm0,4.649L9.448,9.9H5.837ZM21.465,2.564a3.083,3.083,0,0,0-2.539,1.32,3.1,3.1,0,0,0-5.642,1.783c0,2.257,2.731,3.859,5.642,6.488,2.911-2.629,5.642-4.232,5.642-6.488A3.108,3.108,0,0,0,21.465,2.564Zm-2.539,6.6c-1.636-1.377-3.385-2.708-3.385-3.5a.845.845,0,0,1,.846-.846,1.174,1.174,0,0,1,.824.418l1.715,1.614,1.715-1.614a1.174,1.174,0,0,1,.824-.418.845.845,0,0,1,.846.846C22.311,6.457,20.562,7.789,18.926,9.165Z" transform="translate(0.5 0.16)" />
          </g>
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);

export default IconDetailedOther;
