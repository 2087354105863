import React from 'react';
import * as Style from './IconWarning.style';

const IconWarning = ({
  color = '#4a4a4a',
  testId = 'icon-warning',
  size = 17,
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 17 14">
      <path id="Caminho_37319" data-name="Caminho 37319" d="M1,16H18L9.5,2Zm9.273-2.211H8.727V12.316h1.545Zm0-2.947H8.727V7.895h1.545Z" transform="translate(-1 -2)" />
    </svg>
  </Style.Wrapper>
);


export default IconWarning;
