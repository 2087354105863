import React from 'react';

export default {
  title: 'Dados pessoais e de contato',
  address: 'Endereço',
  privacy: 'Privacidade de dados',
  form: {
    addressLabel: 'Endereço *',
    addressPlaceholder: 'Seu endereço',
    birthCountryLabel: 'País de nascimento *',
    birthCountryPlaceholder: 'Selecione aqui',
    birthDateLabel: 'Data de nascimento *',
    birthDatePlaceholder: 'DD/MM/AAAA',
    cityLabel: 'Cidade *',
    cityPlaceholder: 'Sua cidade',
    companyNameLabel: 'Razão Social *',
    companyNameLabelPlaceholder: 'Digite Aqui',
    companyResponsible: 'Representante Legal *',
    companyResponsiblePlaceholder: 'Digite Aqui',
    complementLabel: 'Complemento *',
    complementPlaceholder: 'Apt 00',
    countryLabel: 'País de residência *',
    countryPlaceholder: 'Seu país',
    cpfCnpjLabel: 'CPF ou CNPJ *',
    documentLabel: 'Documento *',
    documentPlaceholder: '000.000.000-00',
    emailLabel: 'E-mail *',
    emailPlaceholder: 'Seu e-mail',
    foreignerLabel: 'Sou estrangeiro',
    isCompanyLabel: '',
    knowledgeLabel: 'Nível de conhecimento',
    knowledgePlaceholder: 'Selecione o nível',
    mailSubscribeLabel: 'Receber novidades por e-mail',
    mailSubscribeTooltip: 'Com essa opção ativada você nos autoriza a enviar mensagens promocionais e as últimas atualizações de conteúdo das nossas plataformas.',
    nameLabel: 'Nome completo *',
    namePlaceholder: 'Seu nome completo',
    neighborhoodLabel: 'Bairro *',
    neighborhoodPlaceholder: 'Seu bairro',
    numberAndComplementLabel: 'Número ou Complemento *',
    numberAndComplementPlaceholder: '00',
    phoneLabel: 'Celular *',
    phonePlaceholder: '(00) 0 0000-0000',
    postalCodeHelp: 'Não sei meu CEP',
    postalCodeLabel: 'CEP *',
    postalCodePlaceholder: '00000-000',
    stateLabel: 'Estado *',
    statePlaceholder: 'Seu estado',
  },
  errors: {
    birthDate: 'Digite uma data válida',
    postalCode: 'CEP inválido',
    cnpj: 'CNPJ inválido',
    cpf: 'CPF inválido',
    email: 'Digite um e-mail válido',
    numberAndComplement: 'Caracteres especiais não são permitidos',
    name: 'Por favor, forneça um nome com até 40 caracteres, incluindo um espaço',
    companyName: 'Certifique-se de que a razão social da empresa tenha até 50 caracteres, com um espaço entre eles.',
    phone: 'Digite um telefone válido',
    required: 'Campo obrigatório',
    state: '',
  },
  findMyCep: 'Não sei meu CEP',
  knowledgeLevels: {
    beginner: 'Iniciante',
    intermediary: 'Intermediário',
    technical: 'Técnico',
  },
  cancel: 'Cancelar',
  save: 'Salvar alterações',
  snackBar: {
    updateSuccess: 'Seus dados foram atualizados com sucesso!',
    updateError: 'Erro ao salvar seus dados!',
    cepError: 'Digite um CEP válido',
    emailAlreadyExists: 'Seu e-mail já está cadastrado no nosso sistema.',
  },

  changePassword: {
    title: 'Login Portal HostGator',
    modalTitle: 'Alterar Senha',
    contentDescription: () => (
      <>
        <div>Senha</div>
        <div>••••••••</div>
      </>
    ),
    SectionButtonLabel: 'Alterar Senha',
    currentPwLabel: 'Senha atual',
    currentPwPlaceholder: 'Insira sua senha atual',
    newPwLabel: 'Nova senha',
    newPwPlaceholder: 'Insira sua nova senha',
    repeatNewPwLabel: 'Repita a nova senha',
    repeatNewPwPlaceholder: 'Repita a nova senha',

    errorCurrentEmpty: 'Prencha sua senha atual.',
    errorEmptyField: 'Preencha este campo.',
    errorCurrentMinimumCharacters: 'A senha deve ter no mínimo 6 caracteres.',
    errorMinimumCharacters: 'A senha deve ter no mínimo 8 caracteres.',
    errorMinimumRequirements: 'Sua senha não tem os requisitos mínimos de segurança.',
    errorConfirmNew: 'A confirmação de senha não confere.',
    notifierSuccess: 'Senha alterada com sucesso!',
    notifierError: 'Não foi possível alterar sua senha. Entre em contato com o suporte.',

    requirementsList: {
      listTitle: 'Sua nova senha deve:',
      upperAndLowerItem: 'Incluir caracteres maiúsculos e minúsculos',
      numberLabel: 'Incluir pelo menos 1 número',
      symbolLabel: 'Incluir pelo menos 1 símbolo',
      minCharLabel: 'Ter pelo menos 8 caracteres',
      sameOnBothLabel: 'Ser a mesma em ambos os campos',
      differentPasswords: 'A nova senha não pode ser igual a senha atual.',
      matchConfirmation: 'Os campos da senha precisam ser iguais',
    },

    modalDescription: () => (
      <div>
        Apenas a
        {' '}
        <b>senha de login do Portal HostGator</b>
        {' '}
        será alterada com esta ação.
      </div>
    ),
    buttonLabel: 'Alterar senha',
    secondaryButtonLabel: 'Cancelar',

    requirementsTitle: 'Sua nova senha deve ter:',
    chips: {
      hasAtLeastEight: 'Mínimo de 8 caracteres',
      hasUpperCase: 'Letra maiúscula',
      hasLowerCase: 'Letra minúscula',
      hasSymbol: 'Caracteres especiais',
      hasNumber: 'Números',
    },
  },
  twoFactorAuth: {
    title: 'Autenticação de dois fatores',
    description: userEmail => (
      <>
        Os e-mails com o código de verificação estão sendo enviados para o endereço cadastrado:
        {' '}
        <b>{userEmail}</b>
      </>
    ),
    status: {
      title: 'Status',
      enabled: 'Habilitado',
      disabled: 'Desabilitado',
    },
    statusButtonLabel: {
      enable: 'Habilitar',
      disable: 'Desabilitar',
    },
    snackbarFeedback: type => `Autenticação de dois fatores ${type === 'enable' ? 'habilitada' : 'desabilitada'} com sucesso.`,
    validateCodeModal: {
      description: 'Para garantir a segurança da sua conta, enviamos um código temporário para:',
      codeInputPlaceholder: 'Código de 6 dígitos',
      resendCodeMessage: 'Renviar código por e-mail em: ',
      resendCodeButtonLabel: 'Reenviar código',
      confirmText: modalType => (
        <>
          Você tem certeza que deseja
          {' '}
          <b>{modalType === 'enable' ? 'habilitar' : 'desabilitar'}</b>
          {' '}
          essa função?
        </>
      ),
      codeInputError: 'Código inválido',
      enable2FA: {
        title: 'Habilitar autenticação de dois fatores',
        buttonLabel: 'Sim, habilitar',
      },
      disable2FA: {
        title: 'Desabilitar autenticação de dois fatores',
        warning: {
          title: 'Essa ação diminuirá a segurança da sua conta',
          description: 'Recomendamos manter essa camada extra de proteção para defender sua conta contra acessos não autorizados e possíveis fraudes.',
        },
        buttonLabel: 'Sim, desabilitar',
      },
    },
  },
};
