import { useSelector } from 'react-redux';

const usePropagationStatus = (domain) => {
  const { propagationStatus } = useSelector(state => state.newDomains);

  const filteredDns = propagationStatus.find(dns => dns.domain === domain);

  return !!filteredDns && filteredDns.status;
};

export default usePropagationStatus;
