import React, { useEffect } from 'react';
import { Modal } from '@/pages/common/Modal';
import * as Styles from './AcceptedOfferSuccessModal.styles';
import useLocale from '@/hooks/useLocale';
import { Button, IconCheck } from 'gatorcomponents';

const AcceptedOfferSuccessModal = ({
  onCloseClick = null,
  onBackAction = null,
  onAcceptAction = null,
  onDisplay = null,
  version = 1,
}) => {
  const { billing: billingLocale } = useLocale();
  const {
    offerAcceptSuccesModal: offerAcceptSuccesModalLocale,
  } = billingLocale;

  const titleLabel = offerAcceptSuccesModalLocale.title;
  const moreThanMonthDescriptionVersionLabel = offerAcceptSuccesModalLocale.moreThanMonthDescriptionVersion;
  const lessThanMonthDescriptionVersionLabel = offerAcceptSuccesModalLocale.lessThanMonthDescriptionVersion;
  const beforeRenewalLabel = offerAcceptSuccesModalLocale.beforeRenewal;
  const paymentNowLabel = offerAcceptSuccesModalLocale.paymentNow;
  const renewalNowLabel = offerAcceptSuccesModalLocale.renewalNow;

  useEffect(() => {
    onDisplay && onDisplay();
  }, []);

  const handleOnCloseClick = (event) => {
    onCloseClick && onCloseClick(event);
  };

  const handleOnAcceptClick = (event) => {
    onAcceptAction && onAcceptAction(event);
  };

  const handleOnBackClick = (event) => {
    onBackAction && onBackAction(event);
  };

  return (
    <Modal
      maxWidth={800}
      onClose={handleOnCloseClick}
      testId="accept-offer-success-modal"
      disablePadding
    >
      <Styles.Content>
        <Styles.ContentTitle>
          <IconCheck color="activePure" size="lg" />
          <Styles.TitleModal data-testid="title-item">{titleLabel}</Styles.TitleModal>
        </Styles.ContentTitle>
        <Styles.ContentData>
          <Styles.Label data-testid="description-text-modal">
            {version === 0 ? lessThanMonthDescriptionVersionLabel : moreThanMonthDescriptionVersionLabel}
          </Styles.Label>
        </Styles.ContentData>
        <Styles.ControlButtons>
          <>
            {version === 0 && (
              <Button
                testId="link-action-later"
                onClick={handleOnBackClick}
                label={beforeRenewalLabel}
                variant="text"
              />
            )}
            <Button
              testId="button-action-now"
              onClick={handleOnAcceptClick}
              label={version === 0 ? renewalNowLabel : paymentNowLabel}
              variant="primary"
            />
          </>
        </Styles.ControlButtons>
      </Styles.Content>
    </Modal>
  );
};


export default AcceptedOfferSuccessModal;
