import shortid from 'shortid';
import { CALL_API } from '@/middleware/api';
import {
  SITEBUILDER_SSO_URL, DELETE_SITE_FROM_SITEBUILDER_URL,
  SITE_BUILDER_DOMAINS_AVAILABLE_URL, SITE_BUILDER_STATUS_BY_DOMAIN_URL,
  ACTIVATE_SITEBUILDER_ADDON_URL, ACTIVATE_SITEBUILDER_STANDALONE_URL,
} from '@/config/api';
import {
  REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS, RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS,
  ERROR_SITE_BUILDER_AVAILABLE_DOMAINS, REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN,
  RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN, ERROR_STATUS_SITE_BUILDER_BY_DOMAIN,
  REQUEST_SITEBUILDER_SSO, ERROR_SITEBUILDER_SSO, SUCCESS_SITEBUILDER_SSO,
  SUCCESS_DELETE_SITE_FROM_SITEBUILDER, REQUEST_DELETE_SITE_FROM_SITEBUILDER,
  ERROR_DELETE_SITE_FROM_SITEBUILDER, REQUEST_ACTIVATE_SITEBUILDER_ADDON,
  RECEIVE_ACTIVATE_SITEBUILDER_ADDON, ERROR_ACTIVATE_SITEBUILDER_ADDON,
  REQUEST_ACTIVATE_SITEBUILDER_STANDALONE, RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE,
  ERROR_ACTIVATE_SITEBUILDER_STANDALONE, REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS,
  RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS, ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS,
} from '@/redux/actions/actionsTypes';
import { COUNTRY } from '@/config';


const receiveSiteBuilderAvailableDomains = (domainList) => {
  const handleDataReceived = domainList.map(item => ({ id: shortid.generate(), domain: item }));

  return ({
    type: RECEIVE_SITE_BUILDER_AVAILABLE_DOMAINS,
    data: handleDataReceived,
  });
};

export const getSiteBuilderAvailableDomains = hostingId => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: SITE_BUILDER_DOMAINS_AVAILABLE_URL(hostingId),
    method: 'GET',
    actionTypes: {
      request: () => ({ type: REQUEST_SITE_BUILDER_AVAILABLE_DOMAINS }),
      success: ({ data }) => receiveSiteBuilderAvailableDomains(data),
      error: () => ({ type: ERROR_SITE_BUILDER_AVAILABLE_DOMAINS }),
    },
  },
});

export const getSiteBuilderStatus = (domain, originAction) => (dispatch, getState) => {
  const { id: clientId, builderEmail } = getState().summary;

  let requestAction = '';
  let successAction = '';
  let errorAction = '';
  switch (originAction) {
    case 'actions':
      requestAction = REQUEST_STATUS_SITE_BUILDER_FOR_ACTIONS;
      successAction = RECEIVE_STATUS_SITE_BUILDER_FOR_ACTIONS;
      errorAction = ERROR_STATUS_SITE_BUILDER_FOR_ACTIONS;
      break;
    case 'status':
      requestAction = REQUEST_STATUS_SITE_BUILDER_BY_DOMAIN;
      successAction = RECEIVE_STATUS_SITE_BUILDER_BY_DOMAIN;
      errorAction = ERROR_STATUS_SITE_BUILDER_BY_DOMAIN;
      break;
    default:
      break;
  }

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      authorizationType: 'aws',
      endpoint: SITE_BUILDER_STATUS_BY_DOMAIN_URL(builderEmail, domain),
      method: 'GET',
      customHeaders: {
        'x-client-id': clientId,
        'x-brand': COUNTRY,
      },
      actionTypes: {
        request: () => ({ type: requestAction }),
        success: data => ({ type: successAction, data }),
        error: () => ({ type: errorAction }),
      },
    },
  });
};

export const activateSiteBuilderStandAlone = data => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ACTIVATE_SITEBUILDER_STANDALONE_URL,
    method: 'POST',
    body: data,
    actionTypes: {
      request: () => ({ type: REQUEST_ACTIVATE_SITEBUILDER_STANDALONE }),
      success: data => ({ type: RECEIVE_ACTIVATE_SITEBUILDER_STANDALONE, data }),
      error: () => ({ type: ERROR_ACTIVATE_SITEBUILDER_STANDALONE }),
    },
  },
});

export const activateSiteBuilderAddon = ({ domain, hostingId }) => ({
  [CALL_API]: {
    authenticated: true,
    endpoint: ACTIVATE_SITEBUILDER_ADDON_URL,
    method: 'POST',
    body: {
      domain,
      hosting_id: parseInt(hostingId, 10),
    },
    actionTypes: {
      request: () => ({ type: REQUEST_ACTIVATE_SITEBUILDER_ADDON }),
      success: ({ data }) => ({ type: RECEIVE_ACTIVATE_SITEBUILDER_ADDON, data }),
      error: () => ({ type: ERROR_ACTIVATE_SITEBUILDER_ADDON }),
    },
  },
});

export const getSiteBuilderSso = domain => (dispatch, getState) => {
  const { builderEmail, id: clientId } = getState().summary;

  return dispatch({
    [CALL_API]: {
      authorizationType: 'aws',
      authenticated: true,
      endpoint: SITEBUILDER_SSO_URL(builderEmail, domain),
      method: 'GET',
      customHeaders: {
        'X-Brand': COUNTRY,
        'X-Client-ID': clientId,
      },
      actionTypes: {
        request: () => ({ type: REQUEST_SITEBUILDER_SSO }),
        success: data => ({ type: SUCCESS_SITEBUILDER_SSO, data }),
        error: () => ({ type: ERROR_SITEBUILDER_SSO }),
      },
    },
  });
};

export const removeSiteFromSiteBuilder = data => (dispatch, getState) => {
  const { id: clientId, builderEmail } = getState().summary;

  return dispatch({
    [CALL_API]: {
      authenticated: true,
      endpoint: DELETE_SITE_FROM_SITEBUILDER_URL(builderEmail, data.domain),
      method: 'DELETE',
      authorizationType: 'aws',
      customHeaders: {
        'X-Brand': COUNTRY,
        'X-Client-ID': clientId,
      },
      body: data,
      actionTypes: {
        request: () => ({ type: REQUEST_DELETE_SITE_FROM_SITEBUILDER }),
        success: () => ({ type: SUCCESS_DELETE_SITE_FROM_SITEBUILDER }),
        error: () => ({ type: ERROR_DELETE_SITE_FROM_SITEBUILDER }),
      },
    },
  });
};
