import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.palette.yellow.opacity};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    max-width: 777px;
    padding: 24px;
    width: 100%;

    button {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: flex-start;
    }
  `}
`;

export const TipWrapper = styled.div`
  padding-left: 16px;
`;
export const TipTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.xl};
    margin: 0;
  `}
`;

export const TipContent = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: ${theme.font.sizes.xl};
    margin: 0px 0px 24px 0px;
  `}
`;
