import React from 'react';
import * as Styles from './ManageOptionCard.styles';
import {
  Button,
  IconKey,
  IconPerfil,
  IconSecurity,
  IconSuccessShield,
  Skeleton,
} from 'gatorcomponents';

const ManageOptionCard = ({
  loading,
  hasProtect,
  hasContact,
  hasRegister,
  renderEppKey,
  manageDomainHandlerLocale,
  dnsWizardAnalytics,
  handleRedirectAction,
}) => {
  const handleClickOption = ({
    href, eppKey, hasProtect, hasRegister,
  }) => {
    handleRedirectAction && handleRedirectAction({
      href, eppKey, hasProtect, hasRegister,
    });
  };

  const renderIcon = ({ option }) => {
    if (option.testId === 'protect') {
      return (<IconSuccessShield color="lowPure" />);
    }


    if (option.testId === 'contact') {
      return (<IconPerfil />);
    }


    if (option.testId === 'register') {
      return (<IconSecurity color="lowPure" />);
    }


    if (option.testId === 'eppKey') {
      return (<IconKey />);
    }
  };

  const LoadingElement = () => (
    <Styles.ManageItemSkeleton>
      <Skeleton variant="paragraph" />
      <Skeleton variant="button" />
    </Styles.ManageItemSkeleton>
  );

  const optionItem = ({
    option, href, eppKey, hasProtect, hasRegister,
  }) => {
    if (option.testId === 'register') {
      dnsWizardAnalytics.dnsWizardManageScreenButtonClick();
    }

    return (
      <Styles.ManageItem>
        <Styles.ManageItemTitle>
          {renderIcon({ option })}

          {option.title}

          <Styles.ButtonWrapperDesktop>
            <Button
              variant="text"
              label={option.cta}
              onClick={() => handleClickOption({
                href, eppKey, hasProtect, hasRegister,
              })}
              testId={`${option.testId}-cta`}
            />
          </Styles.ButtonWrapperDesktop>
        </Styles.ManageItemTitle>

        <Styles.ManageItemDescription>
          {option.description}
        </Styles.ManageItemDescription>

        <Styles.ButtonWrapperMobile>
          <Button
            variant="text"
            label={option.cta}
            onClick={() => handleClickOption({
              href, eppKey, hasProtect, hasRegister,
            })}
            testId={`${option.testId}-cta`}
          />
        </Styles.ButtonWrapperMobile>
      </Styles.ManageItem>
    );
  };

  if (loading) {
    return (
      <Styles.ManageSkeletonList id="LoadingManageList">
        {LoadingElement()}
        {LoadingElement()}
        {LoadingElement()}
        {LoadingElement()}
      </Styles.ManageSkeletonList>
    );
  }

  return (
    <Styles.ManageList>
      {(hasProtect) && (
        optionItem({ option: manageDomainHandlerLocale.cardOptions.manage.protect, href: hasProtect && hasProtect.link, hasProtect: true })
      )}

      <Styles.ManageDivider />
      {(hasContact) && (
        optionItem({ option: manageDomainHandlerLocale.cardOptions.manage.contactInfo, href: hasContact && hasContact.link })
      )}

      <Styles.ManageDivider />
      {(hasRegister) && (
        optionItem({ option: manageDomainHandlerLocale.cardOptions.manage.register, href: hasRegister && hasRegister.link, hasRegister: true })
      )}

      <Styles.ManageDivider />
      {(renderEppKey) && (
        optionItem({ option: manageDomainHandlerLocale.cardOptions.manage.eppKey, eppKey: true })
      )}
    </Styles.ManageList>
  );
};

export default ManageOptionCard;
