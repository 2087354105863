const styles = theme => ({
  root: {
    [theme.breakpoints.up(960)]: {
      paddingTop: 0,
    },
    '& .slick-list': {
      marginBottom: '16px',
    },
  },

  gridRadio: {
    justifyContent: 'center',
    '> span': {
      padding: '8px',
    },

  },

  labelRadio: {
    fontSize: theme.font.sizes.lg,
  },

  annuallyOption: {

  },

  reductionSquare: {
    alignItems: 'center',
    backgroundColor: theme.color.oceanGreen,
    borderRadius: '2px',
    color: theme.color.white,
    display: 'flex',
    fontSize: theme.font.sizes.sm,
    fontWeight: theme.font.weights.bold,
    height: '32px',
    lineHeight: '19px',
    marginLeft: '8px',
    padding: '0px 8px',
  },

  annuallyPriceWrapper: {
    display: 'flex',
  },

  gridList: {
    border: '1px solid #CDD8DF',
    borderRadius: '4px',
    display: 'flex',
    height: '56px',
    marginLeft: '6px',
    marginTop: '18px',
    width: '242px',
    '&:hover': {
      border: `1px solid${theme.color.indigo}`,
    },
    [theme.breakpoints.down(700)]: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },

  },

  planContainer: {
    backgroundColor: theme.color.white,
    boxShadow: '0px 1px 3px #00000029',
    padding: 24,
    textAlign: 'center',
  },
  planContainerOld: {
    textAlign: 'left',
  },
  containerSubTitle: {
    color: theme.color.tundora,
    fontSize: theme.font.sizes.md,
    fontWeight: theme.font.weights.light,
    letterSpacing: '0.01px',
    lineHeight: '29px',
    marginTop: '41px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },


  containerTitle: {
    color: theme.color.tundora,
    fontSize: theme.font.sizes.xxl,
    fontWeight: theme.font.weights.light,
    letterSpacing: '0.01px',
    lineHeight: '29px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
    },
  },

  containerNewTitle: {
    color: theme.palette.grey.ultradark,
    fontFamily: theme.font.family.secondary,
    fontSize: theme.font.sizes.xxl,
    fontWeight: theme.font.weights.light,
    lineHeight: theme.font.lineHeights.xxxxl,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
    },
  },

  containerTitleOld: {
    textAlign: 'left',
  },

  cardWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '40px',
    [theme.breakpoints.up(652)]: {
      alignItems: 'unset',
      flexDirection: 'row',
    },

  },

  gridActiveCPanelTitle: {
    color: theme.palette.grey.dark,
    fontSize: theme.font.sizes.xl,
    letterSpacing: '0.01px',
    marginLeft: '24px',
    textAlign: 'left',
  },
  gridActiveCPanelNewTitle: {
    color: theme.palette.grey.ultradark,
    fontFamily: theme.font.family.secondary,
    fontSize: theme.font.sizes.xl,
    letterSpacing: '0.01px',
    marginLeft: '24px',
    textAlign: 'left',
  },

  gridActiveCPanelDescription: {
    color: theme.palette.grey.dark,
    letterSpacing: '0.01px',
    marginLeft: '24px',
    marginRight: '24px',
    marginTop: '16px',
    textAlign: 'left',
  },
  gridActiveCPanelNewDescription: {
    color: theme.palette.grey.ultradark,
    fontFamily: theme.font.family.secondary,
    letterSpacing: '0.01px',
    marginLeft: '24px',
    marginRight: '24px',
    marginTop: '16px',
    textAlign: 'left',
  },

  gridActiveCPanel: {
    background: 'rgba(68, 128, 197, 0.04)',
    marginTop: '32px',
    paddingTop: '32px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: '33px',
      paddingBottom: '24px',
      paddingTop: '24px',
    },
  },

  mainGridActiveCPanel: {
    borderTop: `1px ${theme.color.geyser} solid`,
    marginTop: '32px',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '24px',
    },
  },

  activeCPanelButton: {
    alignItems: 'center',
    backgroundColor: theme.color.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 0px 0px ',
    color: theme.color.indigo,
    fontSize: theme.font.sizes.sm,
    fontWeight: '500',
    height: '40px',
    justifyContent: 'left',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginLeft: '24px',
    marginTop: '16px',
    marginBottom: '33px',
    maxWidth: '100%',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.color.indigo}`,
      color: theme.color.white,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  activeCPanelButtonWrapper: {
    paddingLeft: '24px',
    paddingTop: '16px',
    paddingBottom: '33px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
    },
  },
  cardWrapperOld:
  {
    justifyContent: 'left',
    marginTop: 'opx',
    [theme.breakpoints.up(652)]: {
      alignItems: 'unset',
      flexDirection: 'row',
    },
  },
});
export default styles;
