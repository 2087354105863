const styles = theme => ({
  cardLink: {
    cursor: 'pointer',
    display: 'inherit',
    backgroundColor: 'transparent',
    color: theme.color.tundora,
    border: `1px solid ${theme.color.oceanGreen}`,
    height: '32px',
    borderRadius: '16px',
    textDecoration: 'none',
    marginLeft: '10px',
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '8px 12px',
    whiteSpace: 'nowrap',
    outline: 'none',
    marginTop: 5,
    marginBottom: 5,
    '&:focus': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
    '&:hover': {
      backgroundColor: theme.color.oceanGreen,
      color: theme.color.white,
    },
  },
  cardWithTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  cardLinkDisabled: {
    color: theme.color.tundora,
    border: `1px solid ${theme.color.geyser}`,
    '&:hover': {
      backgroundColor: theme.color.geyser,
      color: theme.color.tundora,
    },
    '&:focus': {
      backgroundColor: theme.color.geyser,
      color: theme.color.tundora,
    },
  },
  loader: {
    padding: 0,
    margin: '-18px -10px 0 -18px',
    '& div': {
      color: theme.color.white,
    },
  },
  loaderActions: {
    padding: 0,
    margin: '-8px -10px 0 -18px',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  wrapperActions: {
    display: 'flex',
    animationName: 'fadeIn',
    animationDuration: '1s',
    flexWrap: 'wrap',
  },
});

export default styles;
