import styled, { css } from 'styled-components';


export const WrapperSelectDashed = styled.div`
  ${({ theme, width, position }) => css`
    background-color: ${theme.color.white};
    border-bottom: 1.7px dashed ${theme.color.halfBaked};
    padding: 0 0 .5rem;
    position: ${position || '$relative'};
    width: ${width || '100%'};
    height: 26px;
  `}
`;

export const LabelSelectDashed = styled.p`
  ${({
    theme, isOpen, isVoid, hasValue,
  }) => css`
    align-items: center;
    color: ${theme.palette.grey.light};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;

    .text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      transition: transform 150ms ease;
      ${isOpen && css`transform: rotate(180deg);`}
    }

    ${hasValue && css`
      color: ${theme.color.indigo};
      font-weight: 600;
    `}

    ${isVoid && css`
      color: ${theme.color.halfBaked};
      pointer-events: none;
      cursor: not-allowed;
    `}
  `}
`;

export const SelectListBox = styled.div`
  ${({ theme, isOpen }) => css`
    background-color: ${theme.color.white};
    bottom: -5px;
    box-shadow: 0px 2px 5px #0000001a;
    position: relative;
    z-index: 1;

    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    -webkit-animation: fadeInSelect 1s;
    animation: fadeInSelect 1s;

    ${!isOpen && 'display: none;'}

    @-webkit-keyframes fadeInSelect {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    @keyframes fadeInSelect {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `}
`;

export const WrapperSelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const SelectOptions = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.color.white};
    cursor: pointer;
    display: flex;
    height: 40px;
    padding: 6px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    &:hover {
      background-color: ${theme.color.halfBaked};
      color: ${theme.color.white};
    }
  `}
`;
