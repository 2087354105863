export default {
  title: 'Confirme seu celular',
  subtitle: 'Para sua segurança, enviaremos um código de confirmação para o número abaixo:',
  codeNotRecived: 'Não recebeu o código?',
  phoneAgreement: 'Aceito receber comunicações via SMS/WhatsApp',
  resendText: 'Enviar novamente em ',
  verifyCodeForm: {
    title: 'Insira o código',
    subtitle: phone => `Enviamos um código via SMS para o celular ${phone}. Digite no campo abaixo o código recebido para concluir o processo.`,
    placeholder: 'Código',
  },
  changePhoneNumber: {
    title: 'Digite um novo número',
    subtitle: 'Atenção, ele só será salvo após envio do código de segurança e validação.',
    text: 'Seu celular de cadastro será atualizado para este novo telefone',
    passwordDescription: 'Informe a senha de acesso ao Portal do Cliente. Essa informação é necessária para a sua segurança.',
    passwordLabel: 'Senha',
  },
  updateNumber: 'Altere seu número de cadastro',
  attention: 'Atenção, ele só será salvo após o envio do código de segurança e validação.',
  phone: 'Celular',
  phonePlaceholder: '(00) 0 0000-0000',
  password: 'Senha do Portal do Cliente',
  sendCode: 'Enviar código de validação',
  typePassword: 'Digite sua senha',
  codeSended: phoneNumber => `Enviamos um código via SMS para o celular ${phoneNumber}. Digite no campo abaixo o código recebido para concluir o processo.`,
  insertCode: 'Insira o código recebido',
  codePlaceholder: 'Código',
  notReceive: 'Não recebeu o código?',
  requestOtherCode: 'Você poderá solicitar um novo código em',
  changePhone: 'Alterar celular',
  resentCode: 'Reenviar código',
  emptyPhoneNumber: {
    title: 'Valide o seu celular',
    subtitle: 'Informe o número do seu celular para enviarmos o código de confirmação',
    text: 'Este número de celular será adicionado à sua conta.',
  },
  buttons: {
    cancel: 'Voltar',
    sendCode: 'Enviar código',
    change: 'Enviar código',
    sendLoading: 'Enviando...',
    resend: 'Reenviar código',
    resendingLoading: 'Reenviando...',
    changePhoneNumber: 'Alterar celular',
    validate: 'Validar código',
  },
  errors: {
    invalidCode: 'Digite um código válido',
    requiredCode: 'Campo obrigatório',
    invalidPhoneNumber: 'Digite um número válido',
    passwordMinimumCharacters: 'A senha deve ter no mínimo 6 caracteres.',
    countryCode: 'O país selecionado não permite o envio de SMS. Pedimos que informe um celular de outro país, ou desconsidere a validação do celular na sua conta.',
  },
  feedback: {
    resendCodeSuccess: 'Reenviamos. Por favor, verifique seu celular novamente.',
    resendCodeError: 'Tente novamente mais tarde ou altere o número do celular',
    sendCodeSuccess: 'Enviamos o código por SMS para o telefone informado.',
    sendCodeError: 'Tente novamente mais tarde ou altere o número do celular',
    noMoreAttempts: () => 'Você excedeu a quantidade de solicitações em 24 horas, tente novamente amanhã',
    smsSendAttempts: ({ attempts, used }) => `Você solicitou ${used} código${used > 1 ? 's' : ''} nas últimas 24 horas, restam ${attempts} solicitações`,
    alreadyValidated: ({ hours, date }) => `Telefone confirmado à menos de 24 horas, você poderá alterar este número às ${hours} horas do dia ${date}.`,
    validateCodeSuccess: 'Número de celular validado com sucesso!',
    validateCodeError: 'No momento, não foi possível validar o número de celular. Tente novamente mais tarde.',
    changePhoneNumberSuccess: 'Celular validado com sucesso.',
    changePhoneNumberError: 'Tente novamente mais tarde ou altere o número do celular.',
    password: 'A senha digitada não é válida. Tente novamente.',
    dataInUse: 'O celular informado já está em uso no seu cadastro. Para prosseguir, informe outro número de celular.',
    invalidToken: 'Código inválido. Insira o código recebido via SMS',
    genericError: 'Ops! Algo deu errado. Por favor, tente novamente.',
  },
};
