import React from 'react';
import * as Style from './IconOtherMiniSquares.style';

const IconOtherMiniSquares = ({
  color = '#4a4a4a',
  size = '32',
  testId = 'icon-others-mini-squares',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill={color}>
      <g id="Grupo_39832" data-name="Grupo 39832" transform="translate(-1183 -547.104)">
        <g id="apps_black_24dp" transform="translate(1183 547.104)">
          <path id="Caminho_247801" data-name="Caminho 247801" d="M0,0H32V32H0Z" fill="none" />
          <path id="Caminho_247802" data-name="Caminho 247802" d="M4,10h6V4H4Zm9,18h6V22H13ZM4,28h6V22H4Zm0-9h6V13H4Zm9,0h6V13H13ZM22,4v6h6V4Zm-9,6h6V4H13Zm9,9h6V13H22Zm0,9h6V22H22Z" />
        </g>
      </g>
    </svg>

  </Style.Wrapper>
);

export default IconOtherMiniSquares;
