import React, { useEffect, useState } from 'react';
import { Radio } from '../../inputs';
import * as Styles from './RadioToggle.styles';

const RadioToggle = ({
  testId = 'radio-toggle',
  name = 'radio-toggle',
  title = 'radio toggle title',
  maxWidht = 'unset',
  maxHeight = '1000px',
  open = false,
  children = (
    <>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
      <div>Lots of elements styled on father</div>
    </>
  ),
  onToggle,
}) => {
  const [display, toggleDisplay] = useState(open);

  const handleClickToggle = () => {
    toggleDisplay(!display);
    onToggle && onToggle({ toggleOpen: !display, name });
  };

  useEffect(() => {
    toggleDisplay(open);
  }, [open]);

  return (
    <Styles.SlideToggleWrapper data-testid={testId} maxWidht={maxWidht} maxHeight={maxHeight}>
      <Styles.ToggleAction onClick={handleClickToggle} data-testid={`${testId}-action`}>
        <Radio size="small" variant="v1" checked={display} />

        <Styles.ToggleHeader>
          <Styles.TitleWrapper>
            <Styles.Title data-testid={`${testId}-title`} active={display}>{title}</Styles.Title>
          </Styles.TitleWrapper>
        </Styles.ToggleHeader>
      </Styles.ToggleAction>

      <Styles.ToggleContent open={display} maxHeight={maxHeight} data-testid={`${testId}-content`}>
        {children}
      </Styles.ToggleContent>
    </Styles.SlideToggleWrapper>
  );
};

export default RadioToggle;
