export const wizardActions = {
  GENERATE: 'generate',
  RESTORE: 'restore',
};

export const wizardRestorationOptios = {
  FULL: 'FULL',
  ROOT: 'ROOT',
  DATABASE: 'DATABASE',
};
