import React from 'react';
import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { authActions } from '@/redux/modules/auth';
import { commonActions } from '@/redux/modules/common';
import { locale } from '@/utils/locale';
import dnsWizardAssembly from './dnsWizard.assembly';
import dnsWizardActions from './dnsWizard.actions';
import { DnsWizardActionTypes } from './dnsWizard.types';
import { mockoonWarning } from '@/utils/Validators/validation';
// import { AWS_GATOR_SOLUTIONS_URL } from '@/config';

function* getHostingPlatforms(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  mockoonWarning({ message: 'getHostingPlatforms precisa do mockoon para funcionar em ambiente local - requests - domains/:domainId/dns - respostas "pointed to dloja"' });

  const request = {
    url: `api/v2/clientarea/br/domains/${action.domainId}/dns`,
    // url: `${AWS_GATOR_SOLUTIONS_URL}/domains/${action.domainId}/dns`, // intercalar para validação local
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }
    const platformsData = dnsWizardAssembly.receivePlatforms(response.data);
    yield put(dnsWizardActions.platforms.set(platformsData));
    yield put(dnsWizardActions.platforms.success());
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.platforms.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* getEmailPlatforms(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/domains/${action.domainId}/mail`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  try {
    const response = yield call(api.whmcs.get, request);
    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }
    const emailsPlatformsData = dnsWizardAssembly.receiveEmailPlatforms(response.data);
    yield put(dnsWizardActions.emailPlatforms.set(emailsPlatformsData));
    yield put(dnsWizardActions.emailPlatforms.success());
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.emailPlatforms.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureForHostGatorHostingPlan(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/dns/domain/${action.domainId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: action.body,
  };

  const markAsPointedRequest = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.put, request);
    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }
    yield call(api.whmcs.put, markAsPointedRequest);
    yield getHostingPlatforms(action);
    const data = dnsWizardAssembly.receiveConfiguration(response.data);
    yield put(dnsWizardActions.hostGatorPlanConfiguration.set(data));
    yield put(dnsWizardActions.hostGatorPlanConfiguration.success());
    yield put(dnsWizardActions.changedPlan.request());
    yield put(dnsWizardActions.emailPlatforms.request(action.domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.hostGatorPlanConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureForCustomDns(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/dns/domain/${action.domainId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: action.body,
  };

  const markAsPointedRequest = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.put, request);

    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }

    yield call(api.whmcs.put, markAsPointedRequest);
    yield getHostingPlatforms(action);

    const data = dnsWizardAssembly.trimNameServers(action.body);

    yield put(dnsWizardActions.customConfiguration.set(data));
    yield put(dnsWizardActions.customConfiguration.success());
    yield put(dnsWizardActions.changedPlan.request());
    yield put(dnsWizardActions.emailPlatforms.request(action.domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.customConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureForExternalPlatform(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/dns/domain/${action.domainId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: action.body,
  };

  const markAsPointedRequest = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.put, request);

    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }

    yield call(api.whmcs.put, markAsPointedRequest);
    yield getHostingPlatforms(action);

    const data = dnsWizardAssembly.trimNameServers(action.body);

    yield put(dnsWizardActions.externalConfiguration.set(action.body.platformName, data));
    yield put(dnsWizardActions.externalConfiguration.success());
    yield put(dnsWizardActions.changedPlan.request());
    yield put(dnsWizardActions.emailPlatforms.request(action.domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.externalConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureForCustomDnsZone(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const { domainId, platformId, platformName } = action;

  const request = {
    url: `api/v2/clientarea/br/domain/${domainId}/platform/${platformId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  const markAsPointedRequest = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.post, request);

    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }

    yield call(api.whmcs.put, markAsPointedRequest);
    yield getHostingPlatforms(action);

    yield put(dnsWizardActions.customDnsZoneConfiguration.success(platformName));
    yield put(dnsWizardActions.changedPlan.request());
    yield put(dnsWizardActions.emailPlatforms.request(action.domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.customDnsZoneConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureForCustomDnsZoneFromPlan(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const { domainId, platformId, platformName } = action;

  const request = {
    url: `api/v2/clientarea/br/domain/${domainId}/platform/${platformId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  const markAsPointedRequest = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.post, request);

    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }

    yield call(api.whmcs.put, markAsPointedRequest);
    yield getHostingPlatforms(action);

    yield put(dnsWizardActions.customDnsZoneFromPlanConfiguration.success(platformName));
    yield put(dnsWizardActions.changedPlan.request());
    yield put(dnsWizardActions.emailPlatforms.request(domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.customDnsZoneFromPlanConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* skipWizard(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `api/v2/clientarea/br/domain/${action.domainId}/dns-pointing-wizard`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  const requestToReservedNs = {
    url: `api/v2/clientarea/br/dns/domain/${action.domainId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
    data: {
      native: true,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    yield call(api.whmcs.put, request);
    const response = yield call(api.whmcs.put, requestToReservedNs);
    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }
    yield put(dnsWizardActions.skipWizard.success());
    yield put(dnsWizardActions.changedPlan.request());
    yield getHostingPlatforms(action);
    yield put(dnsWizardActions.emailPlatforms.request(action.domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.skipWizard.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* getDnsZones(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const request = {
    url: `dns/${action.domain}/zone`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.aws.get, request);
    const dnsZonesData = dnsWizardAssembly.receiveDnsZones(response);
    yield put(dnsWizardActions.dnsZones.set(dnsZonesData));
    yield put(dnsWizardActions.dnsZones.success());
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.dnsZones.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* configureEmailPlatform(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);

  const { domainId, platformId } = action;

  const request = {
    url: `api/v2/clientarea/br/domain/${domainId}/mail/${platformId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.post, request);

    if (response.data && response.data.data && (response.data.data.result === 'error')) {
      throw locale('domains.dnsWizard.errors.default');
    }

    yield put(dnsWizardActions.emailPlatformsConfiguration.success());

    yield put(dnsWizardActions.emailPlatforms.request(domainId));
    yield put(dnsWizardActions.emailPlatforms.getSettedPlataform({ domainId: action.domainId }));
  } catch (e) {
    yield put(commonActions.notifications.set({
      label: (<p>{locale('domains.dnsWizard.errors.default')}</p>),
      type: 'error',
    }));
    yield put(dnsWizardActions.emailPlatformsConfiguration.failure());
  }

  yield put(commonActions.loading.set(false));
}

function* requestEmailPlataform(action) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const { payload } = action;

  const request = {
    url: `api/v2/clientarea/br/domain/${payload}/platform`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Service-Token': jwtToken,
    },
  };

  yield put(commonActions.loading.set(true));

  try {
    const response = yield call(api.whmcs.get, request);
    const emailPlataform = dnsWizardAssembly.receiveEmailPlataform(response);

    yield put(dnsWizardActions.emailPlatforms.getEmailPlataformSuccess());
    yield put(dnsWizardActions.emailPlatforms.setCurrentEmailPlataform(emailPlataform)); // update email plaformf
  } catch (e) {
    yield put(dnsWizardActions.emailPlatforms.getEmailPlataformFailure());
  }

  yield put(commonActions.loading.set(false));
}

function* sagas() {
  yield takeLatest(DnsWizardActionTypes.GET_HOSTING_PLATFORMS_REQUEST, getHostingPlatforms);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_REQUEST, configureForHostGatorHostingPlan);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_REQUEST, configureForCustomDns);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_REQUEST, configureForExternalPlatform);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_REQUEST, configureForCustomDnsZone);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_REQUEST, configureForCustomDnsZoneFromPlan);
  yield takeLatest(DnsWizardActionTypes.PUT_SKIP_WIZARD_REQUEST, skipWizard);
  yield takeLatest(DnsWizardActionTypes.GET_DNS_ZONES_REQUEST, getDnsZones);
  yield takeLatest(DnsWizardActionTypes.GET_EMAIL_PLATFORMS_REQUEST, getEmailPlatforms);
  yield takeLatest(DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM, requestEmailPlataform);
  yield takeLatest(DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_REQUEST, configureEmailPlatform);
}

export default sagas;
