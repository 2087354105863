import {
  call, takeLatest, put, select, delay, take,
} from 'redux-saga/effects';
import { api } from '@/utils/api';
import { authActions } from '@/redux/modules/auth';
import preChatActions from './preChat.actions';
import { PreChatActionTypes } from './preChat.types';
import preChatAssembly from './preChat.assembly';
import alertsAssembly from '../alerts/alerts.assembly';
import { config } from '@/config';
import { isBRServer, mockoonWarning } from '@/utils/Validators/validation';
import { alertsActions } from '../alerts';
import { domainsActions } from '../domains';
import { AuthActionsTypes } from '../auth/auth.types';
import logger from '@/utils/logger';
import { API_URL_V3 } from '@/config/api';

const {
  AWS_GATOR_SOLUTIONS_URL,
  WHMCS_URL,
} = config;

function* getDiagnostic({ payload }) {
  const {
    enabledSubscriptionInDiagnostic,
    enablePendingOrders,
    waitToken,
    enableSslAlert,
  } = payload;
  yield put(preChatActions.diagnostic.loading(true));
  yield put(authActions.tokens.jwt.update());
  yield waitToken && take(AuthActionsTypes.UPDATE_JWT_TOKEN_FINISH);

  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'getDiagnostic' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/diagnostic`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };


  try {
    const response = yield call(api.aws.get, request);
    const diagnostic = preChatAssembly.diagnosticResponse(response);
    yield put(preChatActions.diagnostic.set(diagnostic));
    const diagnosticAlerts = alertsAssembly.mountDiagnosticAlerts(
      diagnostic,
      enabledSubscriptionInDiagnostic,
      enablePendingOrders,
      enableSslAlert,
    );
    yield put(alertsActions.set.diagnosticAlerts(diagnosticAlerts));

    const domainsPropagation = alertsAssembly.getDomainsPropagations(diagnostic);
    yield put(domainsActions.propagationStatus.set(domainsPropagation));

    yield put(preChatActions.diagnostic.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getDiagnostic', error);
    yield put(preChatActions.diagnostic.failure());
    yield delay(2000);
  }
  yield put(preChatActions.diagnostic.loading(false));
}

function* getProductsByStatus(action) {
  const { withCancelled, withPending, waitToken } = action.payload;
  yield put(authActions.tokens.jwt.update());
  yield waitToken && take(AuthActionsTypes.UPDATE_JWT_TOKEN_FINISH);
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  const request = {
    url: `${WHMCS_URL}/api/v2/clientarea/br/products-by-status?status=Active,Suspended,Expired${withCancelled ? ',Cancelled,Terminated' : ''}${withPending ? ',Pending' : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-service-token': jwtToken,
      brand,
    },
  };

  yield put(preChatActions.productByStatus.loading(true));

  try {
    const response = yield call(api.aws.get, request);
    const productsList = response.data && response.data.data;
    yield put(preChatActions.productByStatus.set(productsList));
    yield put(preChatActions.productByStatus.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getProductsByStatus', error);
    yield put(preChatActions.productByStatus.failure());
  }

  yield put(preChatActions.productByStatus.loading(false));
}

function* getProductDetails({ payload }) {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'getProductdetails' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/diagnostic/${payload && payload.productType}/${payload && payload.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  yield put(preChatActions.productDetails.loading(true));

  try {
    const response = yield call(api.aws.get, request);
    const productDetails = preChatAssembly.productDetailsResponse(response.data);
    yield put(preChatActions.productDetails.set(productDetails));
    yield put(preChatActions.productDetails.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getProductDetails', error);
    yield put(preChatActions.productDetails.failure());
    yield delay(2000);
  }
  yield put(preChatActions.productDetails.loading(false));
}

function* getWeekDay() {
  yield put(authActions.tokens.jwt.update());
  const { token, jwt: jwtToken } = yield select(state => state.auth);
  const brand = isBRServer ? 'br' : 'es';

  mockoonWarning({ name: 'getWeekDay' });

  const request = {
    url: `${AWS_GATOR_SOLUTIONS_URL}/current-time`,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-region': brand,
      'x-service-token': jwtToken,
    },
  };

  try {
    const response = yield call(api.aws.get, request);
    const weekDay = preChatAssembly.getWeekDayResponse(response);
    yield put(preChatActions.weekDay.set(weekDay));
    yield put(preChatActions.weekDay.success());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getWeekDay', error);
    yield put(preChatActions.weekDay.failure());
  }
}

function* offerContextToZendesk({ payload }) {
  const {
    category,
    domain,
    hostingId,
    subCategory,
    serviceType,
  } = payload;
  const { token } = yield select(state => state.auth);

  const request = {
    url: `${API_URL_V3}/zendesk/bot/chat_context`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      service_type: serviceType,
      service_id: hostingId,
      domain,
      category,
      sub_category: subCategory,
    },
  };

  yield put(preChatActions.newZendesk.setLoadingOfferContext(true));
  try {
    yield call(api.aws.post, request);
    yield put(preChatActions.newZendesk.setSuccessOfferContext());
  } catch (e) {
    const error = { ...e, userRequestData: request };
    logger.error('Error sagas getWeekDay', error);
    yield put(preChatActions.newZendesk.setFailureOfferContext());
  }

  // eslint-disable-next-line no-undef
  zE('messenger', 'open');
  yield put(preChatActions.newZendesk.setLoadingOfferContext(false));
}

function* sagas() {
  yield takeLatest(PreChatActionTypes.GET_DIAGNOSTIC_REQUEST, getDiagnostic);
  yield takeLatest(PreChatActionTypes.PRODUCT_BY_STATUS_REQUEST, getProductsByStatus);
  yield takeLatest(PreChatActionTypes.GET_PRODUCT_DETAILS_REQUEST, getProductDetails);
  yield takeLatest(PreChatActionTypes.GET_WEEK_DAY_REQUEST, getWeekDay);
  yield takeLatest(PreChatActionTypes.OFFER_CONTEXT_TO_NEW_ZENDESK, offerContextToZendesk);
}

export default sagas;
