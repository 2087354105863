import React from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, OutlinedInput, withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles';


const SelectFieldCategories = ({
  classes,
  filterOptions,
  handleChangeSelectValue,
  inputLabel,
  labelWidth,
  menuItemDefaultText,
  selectValue,
  disabled,
  dataId,
}) => (
  <FormControl variant="outlined" className={classes.formControl}>
    <InputLabel className={classes.inputLabelCustom} htmlFor="outlined-age-simple">
      {inputLabel}
    </InputLabel>
    <Select
      data-id={dataId}
      disabled={disabled}
      value={selectValue}
      onChange={handleChangeSelectValue}
      className={classnames(classes.selectCustom, (selectValue === 'site' || selectValue === 'status') && classes.selectFieldTextOn)}
      input={<OutlinedInput labelWidth={labelWidth} name="site" id="outlined-age-simple" />}
    >
      <MenuItem value="">
        <em>{menuItemDefaultText}</em>
      </MenuItem>
      {filterOptions.map(item => (
        <MenuItem key={item.name} value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SelectFieldCategories.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  handleChangeSelectValue: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  menuItemDefaultText: PropTypes.string.isRequired,
  selectValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(SelectFieldCategories);
