import React from 'react';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { isBRServer } from '@/utils/Validators/validation';

import styles from './styles';

import {
  IconCardAmericanExpress,
  IconCardElo,
  IconCardHiper,
  IconCardMaster,
  IconCardVisa,
  IconPaypalLogo,
} from './Icons';

const PaypalCard = ({
  t,
  classes,
  isNewLayout = false,
}) => (
  <div className={isNewLayout ? classes.newWrapper : classes.wrapper}>
    <div className={classes.header}>
      <div className={classes.titleContainer}>
        <IconPaypalLogo className={classes.paypalLogo} />
        <p className={classes.title}>{t('dialogPaypal.titleContainer')}</p>
      </div>
      <div className={classes.cardsContainer}>
        <IconCardVisa className={classes.card} />
        <IconCardMaster className={classes.card} />
        {isBRServer && (
        <>
          <IconCardElo className={classes.card} />
          <IconCardHiper className={classes.card} />
        </>
        )}
        <IconCardAmericanExpress className={classes.card} />
      </div>
    </div>
    <ul className={isNewLayout ? classes.newFeatures : classes.features}>
      <li className={isNewLayout ? classes.newFeature : classes.feature}>
        <p className={classes.featureTitle}>{t('dialogPaypal.featureTitleFirst')}</p>
        <p className={classes.featureDescription}>{t('dialogPaypal.featureDescriptionFirst')}</p>
      </li>
      <li className={isNewLayout ? classes.newFeature : classes.feature}>
        <p className={classes.featureTitle}>{t('dialogPaypal.featureTitleSecond')}</p>
        <p className={classes.featureDescription}>{t('dialogPaypal.featureDescriptionSecond')}</p>
      </li>
      <li className={isNewLayout ? classes.newFeature : classes.feature}>
        <p className={classes.featureTitle}>{t('dialogPaypal.featureTitleThree')}</p>
        <p className={classes.featureDescription}>{t('dialogPaypal.featureDescriptionThree')}</p>
      </li>
    </ul>
  </div>
);

export default withStyles(styles, { withTheme: true })(
  withI18n()(PaypalCard),
);
