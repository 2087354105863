import React from 'react';
import {
  CardContent, Card, withStyles, CircularProgress,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import styles from './styles';
import { locale } from '@/utils/locale';
import { Timer } from '@/pages/common/v1';

const PhoneConfirmation = ({
  classes,
  t,
  loadingSend,
  setPhoneCardForm,
  handleSendPhoneCode,
  phoneNumber,
  loadingSummary,
  invalidClientPhoneNumber,
  countDownResend,
  setCountDownResend,
}) => {
  const sendCodeLabel = loadingSend ? t('phoneVerify.buttons.sendLoading') : t('phoneVerify.buttons.sendCode');
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {!loadingSummary
            ? (
              <span>{phoneNumber}</span>
            )
            : <CircularProgress color="inherit" size={24} />
        }
        </CardContent>
      </Card>

      {invalidClientPhoneNumber() && (
      <p className={classes.errorMessage}>
        {locale('phoneVerify.errors.countryCode')}
      </p>
      )}

      <div className={classes.wrapperSendCodeBoxLinks}>
        <OutlineButton
          className={classes.button}
          onClick={() => setPhoneCardForm('changePhoneNumber')}
          disabled={loadingSend}
        >
          {t('phoneVerify.buttons.changePhoneNumber')}
        </OutlineButton>
        <PrimaryButton
          className={classes.button}
          onClick={handleSendPhoneCode}
          disabled={loadingSend || invalidClientPhoneNumber() || countDownResend > 0}
        >
          {countDownResend > 0
            && <Timer startSecound={countDownResend} displayHour={false} onFinish={() => setCountDownResend(0)} />
          }

          {loadingSend && <CircularProgress className={classes.loading} size={20} />}
          {countDownResend <= 0 && sendCodeLabel}
        </PrimaryButton>
      </div>
    </>
  );
};

export default withI18n()(withStyles(styles)(PhoneConfirmation));
