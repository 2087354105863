import React from 'react';
import { withStyles } from '@material-ui/core';
import NewSiteFeedback from '@/components/NewSite/NewSiteFeedback/index';
import styles from './styles';


const FeedbackSite = ({ classes }) => (
  <div className={classes.main}>
    <NewSiteFeedback showWarning />
  </div>
);

export default withStyles(styles)(FeedbackSite);
