import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px 17px 80px 80px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 20px;
      gap: 52px
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    max-width: 420px;
    min-height: 540px;
    padding-top: 50px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      min-height: auto;
      padding-top: 14px;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.lg};
    font-weight: ${theme.v2.font.weights.medium};
    margin-bottom: 6px;
    margin-top: 6px;
    width: 100%;

    strong {
      color: ${theme.v2.colors.brand.primaryMedium};
    }

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.md};
      margin-bottom: 7px;
      margin-top: 10px;
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.xxs};
    }
  `}
`;

export const Features = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-wrap: wrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 30px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-top: 18px;
      margin-bottom: 22px;
    }
  `}
`;

export const Feature = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const AvailableMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.feedback.activePure};
    flex-wrap: wrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.medium};
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const Img = styled.img`
  max-width: 588px;
  max-height: 551px;
  width: inherit;
`;

export const Faq = styled.div`
  margin-top: 22px;
  display: flex;
  gap: 9px;
  flex-direction: column;
  margin-top: 47px;
  max-width: 588px;
`;

export const FaqTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-bottom: 9px;
  `}
`;

export const FaqLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    text-decoration: underline;
  `}
`;
