const styles = theme => ({
  contentDetails: {
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0 0 0',
    },
  },
  contentSpacing: {
    flexGrow: 1,
    marginTop: 10,
  },
  wrapperRight: {
    paddingRight: 17,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingBottom: 10,
    },
  },
  wrapperFaq: {
    paddingLeft: 17,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: 10,
    },
  },
  bottomInfoWrapper: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    '& div': {
      flex: '1 1',
    },
    [theme.breakpoints.up(960)]: {
      padding: '0px 40px',
    },
  },
});

export default styles;
