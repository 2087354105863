import styled, { css } from 'styled-components';

export const PaymentCycleWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin: 5px 0px  40px 0px;
    max-width: 50%;
    overflow: hidden;
    padding: 0px;
    width: 100%;

    > div > div {
      padding: 9px 16px 10px;
    }

    @media (max-width: 758px){
      margin: 0px 0px  32px 0px;
      max-width: unset;
      width: 100%;
    }
  `}
`;
