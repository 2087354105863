export const emailErrors = {
  DATA_IN_USE: 4,
  ALREADY_VALIDATED: 5,
  REQUEST_DISABLED: 6,
};

export const EmailSteps = {
  VALIDATE_EMAIL: 'validate_email',
  CHANGE_EMAIL: 'change_email',
  SENT_EMAIL: 'sent_email',
};
