import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  body, button, input {
    font-family: 'Roboto', sans-serif,'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  html, body, #root {
    height: 100%;
  }

  .App {
    height: 100%;
  }

  /***** Search autosuggest *****/
  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 2px;
    -webkit-appearance: none;
  }
  .react-autosuggest__input:focus {
    border: 1px solid #2E578B;
    outline: none;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input::-ms-clear {
    display: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    margin-right: 62px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 70vh;
    overflow: auto;
    max-width: 1300px;
    width: 100%;
    right: 0px;

    ::-webkit-scrollbar{
      width: 8px;
    }

    ::-webkit-scrollbar-track{
      background: #CDD8DF;
    }

    ::-webkit-scrollbar-thumb{
      border-radius: 8px;
      background: #1D5297;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #1D5297,
    }


    @media (max-width: 600px){
      max-width: calc(100vw - 40px);
      right: -10px;
    }
  }

  @media (max-width: 600px){
    .react-autosuggest__suggestions-container--open {
      margin-right: 30px;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .ais-InstantSearch__root {
    width: 100%;
  }
`;

export default GlobalStyle;
