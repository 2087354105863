import FaqMySites from '@/components/FrequentlyQuestions/FaqMySites';
import Loader from '@/components/Layout/Loader';
import { TrialActiveBannerInSites } from '@/pages/sites/components/TrialActiveBannerInSites';
import { TitanUpgradeBottomAdvertisementHandler } from '@/pages/titanAdvertisement/TitanUpgradeBottomAdvertisementHandler/';
import { TitanUpgradeToastHandler } from '@/pages/titanAdvertisement/TitanUpgradeToastHandler';
import { domainsActions, productsActions, sitesActions } from '@/redux/modules';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import SiteItem from '../components/SiteItem';
import { emptyMessage, sitesPagesLocale } from './SitesHandler.locale';
import * as Styles from './SitesHandler.styles';
import { Header } from '@/pages/common';
import { NewPageDescription } from '@/pages/common/v1/';
import { IconInfo } from '@/icons/IconInfo';
import { ThemeContext } from 'styled-components';
import { EmptyList } from '../components/EmptyList';
import { sitesHandlerGa } from '@/utils/ThirdParties/tagManager';
import { sitesAnalytics, createSitesWithSpecialistAnalytics } from '@/analytics';
import useLocale from '@/hooks/useLocale';
import { useMountNotificationModal } from '@/hooks/sites/sitesList/useMountNotificationModal';
import { useMountEmptyList } from '@/hooks/sites/sitesList/useMountEmptyList';
import { useMountCardStatus } from '@/hooks/sites/sitesList/useMountCardStatus';
import { useTechnicalStatus } from '@/hooks/sites/sitesList/useTechnicalStatus';
import { useCardActions } from '@/hooks/sites/sitesList/useCardActions';
import { useCostumerPortalSurvey } from '@/hooks/surveys/useCostumerPortalSurvey';
import { FloatingSurvey } from '@/pages/FloatingSurvey';
import { CreateSiteBanner } from '@/pages/sites/components/CreateSiteBanner';
import { AsaasSitesAdvertisement } from '../components/AsaasSitesAdvertisement';
import { isBRServer } from '@/utils/Validators/validation';
import { SitesHandlerModals } from '../components/SitesHandlerModals';
import PageMessages from '@/pages/common/PageMessages';
import {
  Accordion,
  Button, PageTitle, Skeleton,
} from 'gatorcomponents';
import {
  checklistProgressStatus, contractWithSpecialistURL, modalCloseAction, steps,
} from './SitesHandler.enum';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { DiscountSaversBanner } from '@/components/billing';
import { DiscountOfferBanner } from '@/components/billing/subscriptions/DiscountOfferBanner';
import { DiscountType } from '@/components/billing/subscriptions/PaymentModal/PaymentModal.types';
import {
  ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_BANNER_IN_SITES,
  ALPHA_FRONT_TITAN_PROMOTE_IN_SITES,
  BRAVO_CREATE_SITE_SPECIALIST_GLOBAL,
  BRAVO_NEW_CREATE_MODAL_BEHAVIOR,
  BRAVO_SITES_ITEM_CHANGE_SUBSTATUS_TO_STATUS,
  CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS,
  CHARLIE_CPANEL_SSO_PORTAL,
  CHARLIE_GROUP_SITES_CARDS,
  CHARLIE_NEW_SITES_LIST,
  CHARLIE_SHOW_CREATE_SITE_BANNER,
  CHARLIE_SITELOCK,
  EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY,
  FOXTROT_ECOMMERCE_PLUS_MODAL_TO_WIZARD,
  SITES_FILTER_V2,
  XRAY_ENABLED_DISCOUNT_OFFER_BANNER,
  XRAY_ENABLED_DISCOUNT_SAVERS,
  XRAY_ENABLED_WELCOME_CHECKLIST,
} from '@/config/GrowthBook/constants';
import { STATUS_TYPE } from '@/enums/sites/status.enum';
import { useDiagnostic } from '@/components/Layout/Menu/MyAlerts';
import { ModalAutoSSL } from '../components/ModalAutoSSL';
import { SSL_STATUS } from '@/components/Layout/Menu/MyAlerts/MyAlerts.types';
import { ModalAutoSslTypes } from '../components/ModalAutoSSL/ModalAutoSSL.types';
import { MODAL_TYPE } from '@/hooks/sites/sitesList/useMountNotificationModal/useMountNotificationModal.enum';
import { useResize } from '@/hooks/useResize';
import { GroupedCards } from '../components/GroupedCards';
import { getURLCpanel } from '@/config/urls/cpanelUrls';
import { useCardActionsLocale } from '@/hooks/sites/sitesList/useCardActions/useCardActions.locale';
import { IconDetailedOtherSquare, IconDetailedSiteBuilder, IconDetailedWordpress } from '@/icons';
import { DomainsAlerts } from '../components/DomainsAlerts';
import logger from '@/utils/logger';
import { WelcomeCheckListBanner } from '../components/WelcomeCheckListBanner';
import { WHMCS_URL } from '@/config';
import { useBlockedWindowModal } from '@/hooks/useBlockedWindowModal';

let filterTimeout = null;
const SitesHandler = ({ groupedCardsOnly }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const siteItemV2Toggle = useFeatureIsOn(CHARLIE_NEW_SITES_LIST);
  const enableSitelockStatus = useFeatureIsOn(CHARLIE_SITELOCK);
  const sites = useSelector(state => state.sites.list);
  const diagnostic = useSelector(state => state.preChat);
  const propagationStatus = useSelector(state => state.newDomains.propagationStatus);
  const [filteredSitesItems, setFilteredSitesItems] = useState(sites);
  const [sitesLength, setSitesLength] = useState(5);
  const [blockedWindowUrl, setBlockedWindowUrl] = useState('');

  const { renderBlockedWindowModal } = useBlockedWindowModal();

  const products = useSelector(state => state.products);
  const theme = useContext(ThemeContext);
  const loadedSites = useSelector(state => state.sites.loadedSites);
  const { forceReloadPropagationData, loadingAllDomainsDnsStatus, allDomainsDnsStatus } = useSelector(state => state.sites);
  const loadingExclusion = useSelector(state => state.sites.loadingExclusion);
  const loadingRedirect = useSelector(state => state.sites.loadingRedirect);
  const loadingCPanelSSO = useSelector(state => state.products.cPanelLoading);
  const { routes, sites: sitesLocale } = useLocale();
  const { requestDiagnostic } = useDiagnostic();
  const substatusToStatus = useFeatureIsOn(BRAVO_SITES_ITEM_CHANGE_SUBSTATUS_TO_STATUS);
  const enabledNewStatusDomain = useFeatureIsOn(ALPHA_FRONT_TITAN_PROMOTE_IN_SITES);
  const enabledTitanTriaBanner = useFeatureIsOn(ALPHA_FLOW_TO_SELL_TITAN_TRIAL_BY_BANNER_IN_SITES);
  const enabledFilterV2 = useFeatureIsOn(SITES_FILTER_V2);
  const enableNewCreateSiteModalBehavior = useFeatureIsOn(BRAVO_NEW_CREATE_MODAL_BEHAVIOR);
  const enabledDiscountOfferBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_OFFER_BANNER);
  const enabledDiscountSaversBanner = useFeatureIsOn(XRAY_ENABLED_DISCOUNT_SAVERS);
  const enabledEcommercePlusModalToWizard = useFeatureIsOn(FOXTROT_ECOMMERCE_PLUS_MODAL_TO_WIZARD);
  const enabledCreateSiteWithSpecialistModal = useFeatureIsOn(BRAVO_CREATE_SITE_SPECIALIST_GLOBAL);
  const enableCpanelSSO = useFeatureIsOn(CHARLIE_CPANEL_SSO_PORTAL);
  const withGatorSolutions = useFeatureIsOn(CHARLIE_CHANGE_SITES_PRODUCTSUBSCRIPTIONS_TO_GATORSOLUTIONS);
  const canShowCreateSiteBanner = useFeatureIsOn(CHARLIE_SHOW_CREATE_SITE_BANNER);
  const withGatorSolutionsProductCategory = useFeatureIsOn(EXPERIENCE_CHANGE_ENDPOINT_PRODUCT_CATEGORY);


  const allEmails = useSelector(state => state.emails.proEmailList);
  const [shouldRenderToast, setShouldRenderToast] = useState(false);
  const [shouldRenderBottomBanner, setShouldRenderBottomBanner] = useState(false);
  const [bottomBannerVariant, setBottomBannerVariant] = useState('dark');
  const [advertisementType, setAdvertisementType] = useState('');
  const [noResultMessage, setNoResultMessage] = useState('');
  const [showDnsInProgressModal, setShowDnsInProgressModal] = useState(false);
  const [dnsInProgressDomain, setDnsInProgressDomain] = useState('');
  const [shouldRenderNotificationModal, setShouldRenderNotificationModal] = useState(false);
  const [shouldRenderConfigureDomainModal, setShouldRenderConfigureDomainModal] = useState(false);
  const [modalWarningType, setModalWarningType] = useState('');
  const [modalDomains, setModalDomains] = useState([]);
  const [domainToConfigureSSL, setDomainToConfigureSSL] = useState({ domain: '', hostingId: 0 });
  const [sslModalInitialStep, setSslModalInitialStep] = useState(ModalAutoSslTypes.steps.SSL_AUTO_SSL_ACTIVATION);
  const shouldGroupCards = useFeatureIsOn(CHARLIE_GROUP_SITES_CARDS);
  const windowRect = useResize();
  const { width } = windowRect;
  const mobile = width < 600;
  const enabledWelcomeChecklist = useFeatureIsOn(XRAY_ENABLED_WELCOME_CHECKLIST) && !mobile;
  const enabledNewSiteModal = !enabledWelcomeChecklist;
  const [groupedCards, setGroupedCards] = useState([]);
  const domains = useSelector(state => state.newDomains);
  const [hostingId, setHostingId] = useState(null);
  const [domainsPage, setDomainsPage] = useState(1);
  const [justUpdatedDomains, setJustUpdatedDomains] = useState(false);

  const [hasAbleSiteToConfigure, setAbleSiteToConfigure] = useState(false);
  const summary = useSelector(state => state.summary);
  const { welcomeCheckListSteps } = useSelector(state => state.sites);
  const [welcomeChecklistCardData, setWelcomeChecklistCardData] = useState(null);
  const [listCheckedsLoaded, setListCheckedsLoaded] = useState([]);
  const [loadedWelcomeChecklistCardData, setLoadedWelcomeChecklistCardData] = useState(false);
  const {
    sitesWarningModal: sitesWarningModalAnalytics,
    sitesPaymentWarnings: sitesPaymentWarningsAnalytics,
    ssl: sslAnalytics,
    ecommercePlusModal: ecommercePlusModalAnalytics,
    welcomeChecklistBanner: welcomeChecklistBannerAnalytics,
  } = sitesAnalytics;

  const generateListChecksLoaded = useCallback(() => {
    if (enabledWelcomeChecklist && welcomeChecklistCardData !== null) {
      welcomeChecklistCardData.map((item) => {
        if (item.completion_date && item.completion_date !== null && !listCheckedsLoaded.includes(item.step)) {
          listCheckedsLoaded.push(item.step);
        }
        return null;
      });
      setListCheckedsLoaded(listCheckedsLoaded);
    }
  }, [welcomeChecklistCardData, listCheckedsLoaded, setListCheckedsLoaded, enabledWelcomeChecklist]);

  const verifyWelcomeCheckListInProgress = useCallback(() => {
    if (enabledWelcomeChecklist) {
      dispatch(sitesActions.welcomeChecklist.request({ clientId: summary.id, action: setLoadedWelcomeChecklistCardData }));
    }
  }, [dispatch, enabledWelcomeChecklist, summary.id]);

  const listHostingId = [];

  const hasWelcomeChecklistInProcess = () => {
    if (welcomeCheckListSteps.data.length === 0) {
      return false;
    }
    if (welcomeCheckListSteps.data.length === checklistProgressStatus.completed && welcomeCheckListSteps.data[3].step === steps.finishStep) {
      listHostingId.push(welcomeCheckListSteps.data[0].hosting_id);
      return false;
    }

    if (welcomeCheckListSteps.data.length > checklistProgressStatus.noStarted && welcomeCheckListSteps.data.length < checklistProgressStatus.completed) {
      setWelcomeChecklistCardData(welcomeCheckListSteps.data);
      return true;
    }

    if (welcomeCheckListSteps.data.length > checklistProgressStatus.completed) {
      welcomeCheckListSteps.data.filter(hosting => hosting.step === steps.finishStep)
        .forEach(hosting => listHostingId.push(hosting.hosting_id));
      const hostingsInProgress = welcomeCheckListSteps.data.filter(hosting => !listHostingId.includes(hosting.hosting_id));
      if (hostingsInProgress.length > 0) {
        setWelcomeChecklistCardData(hostingsInProgress);
        setHostingId(hostingsInProgress[0].hosting_id);
        return true;
      }
      return false;
    }

    return true;
  };

  const startWelcomeChecklistProcess = useCallback(() => {
    if (!hasWelcomeChecklistInProcess()) {
      const sitesEnabled = sites.filter(site => site.show_new_site_warning === true && site.status_plan === 'active' && !listHostingId.includes(site.external_id));
      if (sitesEnabled.length > 0) {
        const externalId = sitesEnabled[0].external_id;

        setHostingId(externalId);
        setAbleSiteToConfigure(true);

        const data = {
          clientId: summary.id,
          hostingId: externalId,
          step: 1,
          check: false,
        };

        dispatch(sitesActions.upateStepWelcomeChecklist.request(data));
      }
    } else {
      !hostingId && setHostingId(welcomeCheckListSteps.data[0].hosting_id);
      const hostingsInProgress = welcomeCheckListSteps.data.filter(hosting => !listHostingId.includes(hosting.hosting_id));

      if (hostingsInProgress.length > 3 && hostingsInProgress[3].step === steps.finishStep) {
        setAbleSiteToConfigure(false);
      } else {
        setAbleSiteToConfigure(true);
      }
    }
  }, [sites, dispatch, summary.id, welcomeCheckListSteps]);

  useEffect(() => {
    verifyWelcomeCheckListInProgress();
  }, [verifyWelcomeCheckListInProgress]);

  useEffect(() => {
    generateListChecksLoaded();
  }, [generateListChecksLoaded]);

  useEffect(() => {
    loadedWelcomeChecklistCardData && startWelcomeChecklistProcess();
  }, [startWelcomeChecklistProcess, loadedWelcomeChecklistCardData]);

  const {
    eligibleForTitanUpgrade,
    eligibleDomainOptions,
  } = useSelector(state => state.emails);

  const [displayModalTitanAdvertisementHandler, setDisplayModalTitanAdvertisementHandler] = useState(false);
  const [linkOptions, setLinkOptions] = useState([]);

  const domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');
  const openInNewTabClick = url => window.open(url, '_blank').focus();
  const { mountEmptyOptions } = useMountEmptyList(products.owned);
  const openWordpressLink = domain => openInNewTabClick(`https://${domain}/wp-admin`);

  const {
    survey,
    handleFinishSurvey,
    surveyQuestionsAnswered,
    setSurveyQuestionsAnswered,
    shouldRenderSurvey,
    shouldRenderOpened,
    handleSendQuestion,
    handleStartSurvey,
    handleClose,
    handleJumpQuestion,
  } = useCostumerPortalSurvey();
  const {
    validatePlanDomainState, getSiteStatus, mountWarningStatus, getAllSiteStatus,
  } = useMountCardStatus({ substatusToStatus, withGatorSolutions });
  const { getPropagationStatus, getSSLStatus } = useTechnicalStatus();

  const {
    filterCards,
    mountNoResultMessage,
    mountDescription,
    getDropdownActions,
    getMainActions,
    siteInExclusion,
    setSiteInExclusion,
    filteredOptions,
    setFilteredOptions,
    filterTyped,
    setFilterTyped,
    clearFilter,
    button,
    displayCreateSiteBanner,
    filterCanceledSites,
    groupByExternalId,
    loadingCpanelSSOProductId,
    setLoadingCpanelSSOProductId,
  } = useCardActions({
    history,
    loadingExclusion,
    loadingCPanelSSO,
    routesLocale: routes,
    allDomainsDnsStatus,
    setShowDnsInProgressModal,
    openWordpressLink,
    setDnsInProgressDomain,
    withGatorSolutions,
  });

  const groupCards = useCallback(() => {
    if (shouldGroupCards && filteredSitesItems && filteredSitesItems.length) {
      const group = groupByExternalId(filteredSitesItems);
      setGroupedCards(group);
    }
  }, [filteredSitesItems, groupByExternalId, shouldGroupCards]);

  useEffect(() => {
    groupCards();
  }, [groupCards]);

  const modalInfo = useMountNotificationModal({ sites, enabledEcommercePlusModalToWizard });

  const callFilterCards = useCallback(() => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      if (sites.length) {
        const filtered = filterCards(filteredOptions, filterTyped, sites, propagationStatus);
        setFilteredSitesItems(filtered);
      }
      setNoResultMessage(mountNoResultMessage(filteredOptions, filterTyped, emptyMessage));
    }, 150);
  }, [filteredOptions, filterTyped, sites, filterCards, mountNoResultMessage, propagationStatus]);

  useEffect(() => {
    callFilterCards();
  }, [callFilterCards]);

  const handleCloseConfigureDomainModal = useCallback(() => {
    setShouldRenderConfigureDomainModal(false);
  }, []);

  const handleOnCloseWarningModal = useCallback((action) => {
    sitesWarningModalAnalytics.modalWarningClick(modalWarningType, action, enableNewCreateSiteModalBehavior);
    setShouldRenderNotificationModal(false);
    dispatch(sitesActions.warnings.set());
    const routeWizardWithDomainSelected = `${sitesPagesLocale.routeSitesPages}${sitesPagesLocale.routesNewSite}/${modalDomains}`;

    if (action === modalCloseAction.createSite && modalWarningType === MODAL_TYPE.NEWSITE) {
      history.push(routeWizardWithDomainSelected);
    }

    if (action === modalCloseAction.createSite && modalWarningType === MODAL_TYPE.ECOMMERCEPLUS) {
      history.push(`${routeWizardWithDomainSelected}/ecommerceplus`);
    }

    if (enableNewCreateSiteModalBehavior && modalWarningType === MODAL_TYPE.SPECIALIST) {
      if (action === modalCloseAction.createWithSpecialist) {
        createSitesWithSpecialistAnalytics.createSiteModalCreate('sites');
        window.open(contractWithSpecialistURL);
      }

      if (action === modalCloseAction.close) {
        createSitesWithSpecialistAnalytics.createSiteModalClose('sites');
      }

      if (action === modalCloseAction.createSite) {
        createSitesWithSpecialistAnalytics.createSiteModalCreateMySelf('sites');
      }
    }
  }, [dispatch, setShouldRenderNotificationModal, modalWarningType, history, sitesWarningModalAnalytics, modalDomains, enableNewCreateSiteModalBehavior]);

  const setEmptyOptions = useCallback(() => {
    const options = mountEmptyOptions(products.owned);
    setLinkOptions(options);
  }, [mountEmptyOptions, products.owned]);

  useEffect(() => {
    setEmptyOptions();
  }, [setEmptyOptions]);

  const requestSitesHandlerData = useCallback(() => {
    dispatch(productsActions.products.request({ history: null, withGatorSolutions: withGatorSolutionsProductCategory }));
    dispatch(sitesActions.sites.request({ withGatorSolutions }));
    dispatch(sitesActions.force.reload(false));
    dispatch(productsActions.products.requestAll());
    dispatch(domainsActions.domains.request({ page: domainsPage }));
    !siteItemV2Toggle && dispatch(sitesActions.allDomainsDnsStatusCheck.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const shouldRequestNewDomainsPage = useCallback(() => {
    if (shouldGroupCards) {
      const hasDomains = domains.list.length > 0;
      const hasMoreDomainsPages = domains.list.length < domains.listTotal;
      const isLoadingDomains = domains.loading;

      if (hasDomains && hasMoreDomainsPages && !isLoadingDomains && !justUpdatedDomains) {
        setDomainsPage(domainsPage + 1);
        setJustUpdatedDomains(true);
      }
    }
  }, [
    domains.loading,
    domains.list,
    domains.listTotal,
    domainsPage,
    justUpdatedDomains,
    shouldGroupCards,
  ]);

  useEffect(() => {
    shouldRequestNewDomainsPage();
  }, [shouldRequestNewDomainsPage]);

  const requestDomainsPage = useCallback(() => {
    if (justUpdatedDomains) {
      dispatch(domainsActions.domains.request({ page: domainsPage }));
      setJustUpdatedDomains(false);
    }
  }, [domainsPage, dispatch, justUpdatedDomains]);

  useEffect(() => {
    requestDomainsPage();
  }, [requestDomainsPage]);

  useEffect(() => {
    requestSitesHandlerData();
  }, [requestSitesHandlerData]);

  const setModalData = useCallback(() => {
    setModalWarningType(modalInfo.modalType);
    setModalDomains(modalInfo.domainsToShow);
    setShouldRenderNotificationModal(modalInfo.shouldRender);
  }, [modalInfo]);

  useEffect(() => {
    setModalData();
  }, [setModalData]);

  useEffect(() => {
    const filteredCanceledSites = filterCanceledSites(sites);
    mountDescription({
      filteredCanceledSites,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, mountDescription]);

  const setTitanAdvertisementByTestType = (type) => {
    switch (type) {
      case 'toast':
        setShouldRenderToast(true);
        setAdvertisementType('Toast');
        break;
      case 'bottom_banner_dark':
        setShouldRenderBottomBanner(true);
        setAdvertisementType('Banner Dark');
        setBottomBannerVariant('dark');
        break;
      case 'bottom_banner_light':
        setShouldRenderBottomBanner(true);
        setAdvertisementType('Banner Light');
        setBottomBannerVariant('light');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTitanAdvertisementByTestType('top_banner');
  }, []);

  const handleClickSeeMoreButton = () => {
    setSitesLength(sitesLength + 5);
  };

  const reloadPropagationData = useCallback(() => {
    if (siteItemV2Toggle && forceReloadPropagationData) {
      requestDiagnostic('sitesHandler');

      dispatch(sitesActions.force.reload(false));
    }
  }, [
    dispatch,
    requestDiagnostic,
    forceReloadPropagationData,
    siteItemV2Toggle,
  ]);

  useEffect(() => {
    reloadPropagationData();
  }, [reloadPropagationData]);

  const handleAccessSite = () => {
    sitesHandlerGa({
      gaEventAction: 'Visitar site',
      gaEventLabel: '',
    });
  };

  const asaasBannerClick = () => {
    sitesAnalytics.asaasBanner.asaasShowMore();
    history.push(sitesPagesLocale.asaas.asaasRoute);
  };

  const sitesToShow = siteItemV2Toggle
    ? filterCanceledSites(filteredSitesItems).slice(0, sitesLength)
    : filterCanceledSites(filteredSitesItems);

  const siteItems = (sitesToShow.map((siteItem, idx) => {
    const allSiteStatus = getAllSiteStatus(siteItem);

    const siteSitelock = enableSitelockStatus && diagnostic.sitelock && diagnostic.sitelock.length && diagnostic.sitelock.find(sitelock => sitelock.domain === siteItem.site);
    const dropDownItem = siteSitelock ? { ...siteItem, sitelock: siteSitelock } : siteItem;

    // v1
    const sitePropagation = propagationStatus.find(propagation => propagation.domain === siteItem.site);
    const { id, site } = siteItem;
    const warningStatus = mountWarningStatus(allSiteStatus);
    const dropdownParams = getDropdownActions({ siteItem: dropDownItem });

    // v2
    const sitePropagationV2 = diagnostic.domainsPropagation && diagnostic.domainsPropagation.find(propagation => propagation.domain === siteItem.site);
    const sitePropagationStatus = sitePropagationV2 && getPropagationStatus(sitePropagationV2);
    const financialStatus = getSiteStatus(siteItem);
    const siteSsl = diagnostic.ssl && diagnostic.ssl.length && diagnostic.ssl.find(ssl => ssl.domain === siteItem.site);
    const siteSSLStatus = getSSLStatus(sitePropagationV2, siteSsl);

    const closerPayment = () => {
      if (financialStatus === STATUS_TYPE.SUSPENDED) {
        return siteItem.days_to_cancel_plan >= siteItem.days_to_cancel_domain ? siteItem.days_to_cancel_domain : siteItem.days_to_cancel_plan;
      }
      if (financialStatus === STATUS_TYPE.OVERDUE) {
        return siteItem.days_to_suspend_plan >= siteItem.days_to_suspend_domain ? siteItem.days_to_suspend_domain : siteItem.days_to_suspend_plan;
      }
    };

    const handlePayInvoice = () => {
      const unpaidInvoicePath = `${routes.invoices}`;
      history.push(unpaidInvoicePath);
    };

    const handleConfigureDomain = () => {
      if (sitePropagationV2 && sitePropagationV2.domain_id) {
        history.push(`${routes.domains}${routes.dnsWizard}/${sitePropagationV2.domain_id}`);
      }
    };

    const openAutoSSLModal = (siteSSLStatus) => {
      const status = siteSSLStatus.toLowerCase();
      let clicked = 'auto_ssl';

      if (status === SSL_STATUS.ACTIVATION_FAILED) {
        setSslModalInitialStep(ModalAutoSslTypes.steps.VERIFYING_PENDENCIES);
        clicked = SSL_STATUS.VIEW_PENDING;
      }
      if (status !== SSL_STATUS.ACTIVATION_FAILED) {
        setSslModalInitialStep(ModalAutoSslTypes.steps.SSL_AUTO_SSL_ACTIVATION);
      }

      sslAnalytics.sslTags({ status, clicked });
      setDomainToConfigureSSL({ domain: siteItem.site, hostingId: siteItem.external_id });
    };

    return (
      <SiteItem
        key={id}
        id={id}
        url={site}
        actions={getMainActions({ siteItem })}
        onOpenInNewTabClick={openInNewTabClick}
        financialStatus={financialStatus}
        closerPayment={closerPayment()}
        warnings={validatePlanDomainState(siteItem)}
        dropdownParams={dropdownParams}
        testId={`siteItem_${idx}`}
        handleAccessSite={() => handleAccessSite()}
        handlePayInvoice={handlePayInvoice}
        handleConfigureDomain={handleConfigureDomain}
        handlePay={() => sitesPaymentWarningsAnalytics.handlePay(warningStatus)}
        paymentUrl={sitesPagesLocale.paymentUrl}
        propagationStatus={sitePropagationStatus}
        sitePropagation={sitePropagation}
        displayV2={siteItemV2Toggle}
        sslStatus={siteSSLStatus}
        sslAnalytics={sslAnalytics}
        openAutoSSLModal={() => openAutoSSLModal(siteSSLStatus)}
        siteSitelock={siteSitelock}
        planStatus={siteItem.status_plan}
      />
    );
  }));

  const toolsList = () => {
    const iconsSize = mobile ? 20 : 26;

    return [
      {
        title: sitesPagesLocale.createSiteBanner.tools.wordpress,
        name: 'wordpress',
        icon: <IconDetailedWordpress size={iconsSize} />,
        id: 0,
      },
      {
        title: sitesPagesLocale.createSiteBanner.tools.sitebuilder,
        name: 'sitebuilder',
        icon: <IconDetailedSiteBuilder size={iconsSize} />,
        id: 1,
      },
      {
        title: sitesPagesLocale.createSiteBanner.tools.other,
        name: 'others',
        icon: <IconDetailedOtherSquare width={iconsSize} height={iconsSize} />,
        id: 2,
      },
    ];
  };

  const excludeSiteBuilder = (site) => {
    !loadingExclusion && dispatch(sitesActions.site.delete({ site, setSiteInExclusion, withGatorSolutions }));
  };

  const handleEmptyClick = (option) => {
    sitesHandlerGa({
      gaEventAction: option.label,
      gaEventLabel: '',
    });
  };

  const floatingSurveyProps = () => {
    const defaultProps = {
      onFinishSurvey: handleFinishSurvey,
      shouldRenderOpened,
      questionsAnswered: surveyQuestionsAnswered,
      setQuestionsAnswered: setSurveyQuestionsAnswered,
      dispatch: handleSendQuestion,
      handleStartSurvey,
      survey,
      handleClose,
      handleJumpQuestion,
    };
    return { ...defaultProps };
  };

  const loaderProps = () => {
    const defaultProps = {
      'data-testid': 'loader-sites-page',
    };
    return { ...defaultProps };
  };

  const titanUpgradeToastHandlerProps = () => {
    const defaultProps = {
      setDisplayModal: setDisplayModalTitanAdvertisementHandler,
    };
    return { ...defaultProps };
  };

  const headerProps = () => {
    const defaultProps = {
      title: sitesPagesLocale.sitesPageTitle,
      description: null,
      paddingBottom: false,
      tooltip: {
        text: sitesPagesLocale.tooltip,
        fixedDesktopPosition: 'right',
        fixedMobilePosition: 'bottom',
        variant: 'dark',
        width: 326,
      },
    };
    return { ...defaultProps };
  };

  const createSiteBannerProps = () => {
    const defaultProps = {
      title: sitesPagesLocale.createSiteBanner.title,
      subtitle: sitesPagesLocale.createSiteBanner.subtitle,
      buttonLabel: sitesPagesLocale.createSiteBanner.button,
      buttonAction: button && button.onClick,
      tools: sitesPagesLocale.createSiteBanner.tools,
      testId: 'create-site-banner',
    };
    return { ...defaultProps };
  };

  const newPageDescriptionProps = () => {
    const defaultProps = {
      title: sitesPagesLocale.newTooltip,
      customBreakpoint: theme.v1.breakpoints.md,
      filteredOptions,
      setFilteredOptions,
      setFilterTyped,
      clearFilter,
      filterTyped,
      clearLabel: sitesPagesLocale.filter.clear,
      searchPlaceholder: sitesPagesLocale.filter.searchBarPlaceHolder,
      filterLabel: sitesPagesLocale.filter.options.filterBy,
      sitesLength: sites.length,
    };
    return { ...defaultProps };
  };

  const [subscriptions, setSubscriptions] = useState(null);
  const [invoicesWithSaversDiscount, setInvoicesWithSaversDiscount] = useState([]);

  const verifyDiscountSaversInInvoice = (invoices) => {
    if (invoices !== undefined && invoices.length > 0) {
      const invoicesWithDiscount = invoicesWithSaversDiscount;
      invoices.forEach((invoiceTmp) => {
        if (invoiceTmp.discount !== undefined && invoiceTmp.discount.coupon === DiscountType.SAVERS35OFF) {
          invoicesWithDiscount.push(invoiceTmp);
          setInvoicesWithSaversDiscount(invoicesWithDiscount);
        }
      });
    }
  };

  const verifyDiscountSavers = (invoices) => {
    if (invoices.overdue !== undefined) {
      verifyDiscountSaversInInvoice(invoices.overdue);
    }
    if (invoices.to_due !== undefined) {
      verifyDiscountSaversInInvoice(invoices.to_due);
    }
  };
  useEffect(() => {
    if (diagnostic.invoices !== undefined && invoicesWithSaversDiscount.length === 0) {
      verifyDiscountSavers(diagnostic.invoices);
    }
  }, [diagnostic, diagnostic.invoices]);

  useEffect(() => {
    if (diagnostic.subscriptions !== undefined && diagnostic.subscriptions.to_due !== undefined) {
      setSubscriptions(diagnostic.subscriptions.to_due);
    }
  }, [diagnostic, diagnostic.subscriptions]);


  const [hasMoreThanDiscountSubscription, setHasMoreThanDiscountSubscription] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const getSubscriptionsWithDiscount = () => {
    let totalSubscriptionsWithDescount = 0;

    if (subscriptions !== null) {
      subscriptions.forEach((item) => {
        if (item.discount_enable) {
          setSubscription(item);
          totalSubscriptionsWithDescount += 1;
        }
      });
    }

    setHasMoreThanDiscountSubscription(totalSubscriptionsWithDescount > 1);

    if (totalSubscriptionsWithDescount > 1) {
      setSubscription(null);
    }
  };


  useEffect(() => {
    if (subscriptions !== null && enabledDiscountOfferBanner) {
      getSubscriptionsWithDiscount();
    }
  }, [subscriptions]);

  const getFilterProps = useCallback(() => {
    const defaultProps = {
      testId: 'filterV2',
      title: shouldGroupCards ? '' : sitesPagesLocale.sitesPageTitle,
      filterLabel: sitesPagesLocale.filter.options.filterBy,
      filterChips: filteredOptions,
      filterByPlaceholder: sitesPagesLocale.filter.searchBarPlaceHolder,
      filterByValue: filterTyped,
      clearFilterLabel: sitesPagesLocale.filter.clear,
      handleClearFilter: clearFilter,
      hideFilter: sites.length < 6,
    };

    const clearOptions = () => {
      try {
        const newOptions = [...filteredOptions].map((option) => {
          const newOption = { ...option };
          newOption.checked = false;
          return newOption;
        });
        return newOptions;
      } catch (e) {
        logger.error('Error on SitesHandler - method clearOptions', e);
      }
    };

    const handleChangeChip = (chip) => {
      try {
        const newOptions = clearOptions();
        const chipIndex = newOptions.findIndex(option => option.id === chip.id);
        if (!chip.checked) {
          newOptions[0].checked = true;
        }

        if (chip.checked) {
          newOptions[chipIndex].checked = true;
        }
        setFilteredOptions(newOptions);
      } catch (e) {
        logger.error('Error on SitesHandler - method handleChangeChip', e);
      }
    };


    const handleChangeFilterBy = (text) => {
      try {
        setFilterTyped(text);
      } catch (e) {
        logger.error('Error on SitesHandler - method handleChangeFilterBy', e);
      }
    };

    return {
      ...defaultProps,
      handleChangeFilterBy,
      handleChangeChip,
    };
  }, [filteredOptions, filterTyped, sites.length, clearFilter, setFilterTyped, setFilteredOptions, shouldGroupCards]);

  const emptyListProps = () => {
    const defaultProps = {
      handleEmptyClick,
      linkOptions,
      emptyLocale: sitesLocale.page.empty,
    };
    return { ...defaultProps };
  };

  const handleCloseAutoSSLModal = () => {
    setDomainToConfigureSSL({ domain: '', hostingId: 0 });
  };


  const trialActiveBannerInSitesProps = () => {
    const defaultProps = {
      domainWithoutEmail,
    };
    return { ...defaultProps };
  };

  const titanUpgradeBottomAdvertisementHandlerProps = () => {
    const defaultProps = {
      variant: bottomBannerVariant,
      setDisplayModal: setDisplayModalTitanAdvertisementHandler,
    };
    return { ...defaultProps };
  };

  const asaasSitesAdvertisementProps = () => {
    const defaultProps = {
      newTag: sitesPagesLocale.asaas.banner.newChip,
      title: sitesPagesLocale.asaas.banner.title,
      description: sitesPagesLocale.asaas.banner.text,
      knowMore: sitesPagesLocale.asaas.banner.buttonLabel,
      handleKnowMoreClick: () => asaasBannerClick(),
    };
    return { ...defaultProps };
  };

  const sitesHandlerModalsProps = () => {
    const defaultProps = {
      sitesPagesLocale,
      modalDomains,
      handleOnCloseWarningModal,
      handleCloseConfigureDomainModal,
      shouldRenderNotificationModal,
      shouldRenderConfigureDomainModal,
      modalWarningType,
      enabledNewSiteModal,
      siteInExclusion,
      excludeSiteBuilder,
      setSiteInExclusion,
      loadingExclusion,
      eligibleDomainOptions,
      displayModalTitanAdvertisementHandler,
      setDisplayModalTitanAdvertisementHandler,
      advertisementType,
      enableNewCreateSiteModalBehavior,
      specialistMvpButtonCreate: sitesPagesLocale.createSiteWithPartners.buttonCreate,
      specialistMvpDescription: sitesPagesLocale.createSiteWithPartners.description,
      specialistMvpTitle: sitesPagesLocale.createSiteWithPartners.title,
      enabledCreateSiteWithSpecialistModal,
      showDnsInProgressModal,
      openWordpressLink,
      dnsInProgressDomain,
      ecommercePlusModalAnalytics,
      setShowDnsInProgressModal,
    };

    return { ...defaultProps };
  };

  const mountFilter = useCallback((
    <Styles.Row marginbottom>
      <Styles.FilterWrapper>
        <Styles.FilterHeadContainer>
          {displayCreateSiteBanner && canShowCreateSiteBanner && (
            <Styles.ToolslistWrapper>
              <Styles.ToolsList>
                {toolsList().map(tool => (
                  <Styles.Tool key={tool.id}>
                    {tool.icon}
                    <Styles.ToolTitle data-testid={`tool-${tool.name}-title`}>
                      {tool.title}
                    </Styles.ToolTitle>
                  </Styles.Tool>
                ))}
              </Styles.ToolsList>
            </Styles.ToolslistWrapper>
          )}
          <Styles.FilterMessage>
            <Styles.Title>{sitesLocale.page.title}</Styles.Title>
            <Styles.Description marginBottom={displayCreateSiteBanner && canShowCreateSiteBanner}>
              {sitesLocale.page.description}
            </Styles.Description>
            {displayCreateSiteBanner && canShowCreateSiteBanner && <Button label={sitesLocale.page.createNewSiteButton} onClick={button.onClick} />}
          </Styles.FilterMessage>
        </Styles.FilterHeadContainer>
        {filterCanceledSites(sites).length > 5 && (
          <Styles.PageTitleWrapper>
            <PageTitle {...getFilterProps()} noPadding />
          </Styles.PageTitleWrapper>
        )}
      </Styles.FilterWrapper>
    </Styles.Row>
  ), [sites, displayCreateSiteBanner, getFilterProps]);

  const renderSkelleton = useCallback(() => {
    const skeletons = ['1', '2', '3'];

    return (
      <Styles.Container>
        {shouldGroupCards && mountFilter}

        <Styles.SkeletonContainer multipleSkelletons>
          <Skeleton variant="textLine" width="711px" />
          <Skeleton variant="textLine" width="711px" />
          <Skeleton variant="button" width="104px" />
        </Styles.SkeletonContainer>

        <Styles.SkeletonContainer>
          {skeletons.map(item => (
            <Styles.SkeletonCard key={item}>
              <Styles.FirstSkeletonRow>
                <Skeleton variant="headline" width={mobile ? '238px' : '363px'} />
                <Skeleton variant="button" width="72px" />
                <Skeleton variant="circle" width="32px" />
              </Styles.FirstSkeletonRow>

              <Styles.SkeletonRow marginTop marginBottom>
                <Skeleton variant="rectangle" width="100%" height="50px" />
              </Styles.SkeletonRow>

              <Styles.SkeletonRow marginTop marginBottom>
                <Skeleton variant="paragraph" width="711px" />
              </Styles.SkeletonRow>

              <Styles.LastSkeletonRow>
                <Skeleton variant="button" width="72px" />
              </Styles.LastSkeletonRow>

              <Skeleton variant="rectangle" height="1px" />
            </Styles.SkeletonCard>
          ))}
        </Styles.SkeletonContainer>
      </Styles.Container>
    );
  }, [mobile, mountFilter, shouldGroupCards]);

  const getGroupedCardsProps = useCallback(() => {
    const defaultProps = {
      editSiteCta: sitesLocale.page.editSiteCta,
      groupedCards,
      propagationStatus,
      propagationAlertLabels: sitesLocale.page.newAlerts.propagation,
      loadingCpanelSSOProductId,
      manageLabel: useCardActionsLocale.manageSite,
      cloudflareLabel: useCardActionsLocale.cloudflareSite,
      tagNewLabel: sitesLocale.page.tagNew,
      getDropdownActions,
      getMainActions,
      loadMoreLabel: sitesLocale.page.seeMoreButton,
      loading: !loadedSites,
      siteTagLabels: sitesLocale.page.tags,
      productsList: products.list,
      emptyMessage: sitesLocale.page.emptyState,
      suspendedCpanelTooltip: sitesLocale.page.suspendedCpanelTooltip,
      loadingRedirect,
    };

    const handlePayInvoice = (id) => {
      try {
        const unpaidInvoicePath = withGatorSolutions ? `${routes.invoices}${routes.unpaid}/${id}` : `${routes.invoices}`;
        history.push(unpaidInvoicePath);
      } catch (e) {
        logger.error('Error on SitesHandler - method handlePayInvoice', e);
      }
    };

    const closerPayment = (financialStatus, siteItem) => {
      try {
        if (financialStatus === STATUS_TYPE.SUSPENDED && siteItem) {
          return siteItem.days_to_cancel_plan >= siteItem.days_to_cancel_domain ? siteItem.days_to_cancel_domain : siteItem.days_to_cancel_plan;
        }
        if (financialStatus === STATUS_TYPE.OVERDUE && siteItem) {
          return siteItem.days_to_suspend_plan >= siteItem.days_to_suspend_domain ? siteItem.days_to_suspend_domain : siteItem.days_to_suspend_plan;
        }
      } catch (e) {
        logger.error('Error on SitesHandler - method closerPayment', e);
      }
    };

    const handleGoToCloudFlare = (group) => {
      const hostingId = withGatorSolutions ? group[0].hosting_id : group[0].id;

      history.push(`${routes.products}${routes.cloudflare}/${hostingId}`);
    };

    const handleClickCpanel = (group) => {
      try {
        sitesHandlerGa({
          gaEventAction: 'cPanel',
          gaEventLabel: '',
        });
        if (setBlockedWindowUrl) {
          getURLCpanel({
            externalId: withGatorSolutions ? group[0].hosting_id : group[0].id,
            params: null,
            openInNewTab: true,
            dispatch,
            enableCpanelSSO,
            setLoadingCpanelSSOProductId,
            setBlockedWindowUrl,
          });
        }
        setLoadingCpanelSSOProductId(withGatorSolutions ? group[0].hosting_id : group[0].id);
      } catch (e) {
        logger.error('Error on SitesHandler - method handleClickCpanel', e);
      }
    };

    const handleManage = (group) => {
      try {
        sitesHandlerGa({
          gaEventAction: 'Gerenciar hospedagem',
          gaEventLabel: '',
        });
        history.push(`${routes.products}${routes.productdetail}/${withGatorSolutions ? group[0].hosting_id : group[0].external_id}`);
      } catch (e) {
        logger.error('Error on SitesHandler - method handleManage', e);
      }
    };

    const hostCount = ({
      group,
    }) => sitesLocale.page.hostCount({
      withGatorSolutions,
      group,
    });

    const handleEditSite = ({
      domain,
      hostingId,
    }) => {
      if (setBlockedWindowUrl) {
        dispatch(sitesActions.site.redirectToEdit({
          domain,
          hostingId,
          history,
          siteBuilderUrl: domain => `${useCardActionsLocale.routeWebsitebuilderRedirectAccess}/${domain}`,
          sitesWizardUrl: `${routes.sitesPage}${routes.sitesCreate}`,
          webPresenceUrl: `${WHMCS_URL}/index.php?m=webpresence&action=edit&hosting_id=`,
          cpanelUrl: ({ hostingId, softaculous }) => {
            if (softaculous) {
              const url = `${getURLCpanel({ externalId: hostingId, enableCpanelSSO })}&app=Softaculous_Home`;
              const newWindow = window.open(url, '_blank');
              if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                setBlockedWindowUrl(url);
              }
            }

            if (!softaculous) {
              getURLCpanel({
                externalId: hostingId,
                params: softaculous ? '&app=Softaculous_Home' : null,
                openInNewTab: true,
                dispatch,
                enableCpanelSSO,
                setBlockedWindowUrl,
              });
            }
          },
        }));
      }
    };

    return {
      ...defaultProps,
      handleClickCpanel,
      handleManage,
      handlePayInvoice,
      closerPayment: (financialStatus, siteItem) => closerPayment(financialStatus, siteItem),
      hostCount,
      handleEditSite,
      handleGoToCloudFlare,
    };
  }, [
    loadingRedirect,
    withGatorSolutions,
    groupedCards,
    dispatch,
    setLoadingCpanelSSOProductId,
    loadingCpanelSSOProductId,
    history,
    routes,
    getDropdownActions,
    getMainActions,
    sitesLocale,
    loadedSites,
    loadingAllDomainsDnsStatus,
    products.list,
    propagationStatus,
    enableCpanelSSO,
    setBlockedWindowUrl,
  ]);

  const handleCloseBlockedWindowModal = useCallback(() => {
    setBlockedWindowUrl(false);
  }, []);

  const getDomainsAlertrsProps = useCallback(() => {
    const defaultProps = {
      diagnostic,
      domains,
    };

    return {
      ...defaultProps,
    };
  }, [
    diagnostic,
    domains,
  ]);

  if (shouldGroupCards
    ? (!loadedSites || products.listLoading || !products.listLoaded)
    : (!loadedSites || loadingAllDomainsDnsStatus)
  ) {
    if (shouldGroupCards) {
      return (
        <Styles.Container>
          {mountFilter}

          <Accordion
            width="full"
            justifyContent="flex-start"
            actions={[]}
            open
            onToggleAccordion={() => null}
            leftIcon="IconFile"
            loading
            loadingActionItems={mobile ? 0 : 2}
            loadingTitleWidth="333px"
            loadingListItems={5}
          />
        </Styles.Container>
      );
    }

    if (siteItemV2Toggle) {
      return renderSkelleton();
    }

    return (
      <Loader {...loaderProps()} />
    );
  }

  const getTypeWelcomeChecklistTypeBanner = () => {
    if (listCheckedsLoaded.length === 0) {
      return 0;
    }
    if (listCheckedsLoaded.length > 0 && listCheckedsLoaded.length < 3) {
      return 1;
    }
    return 2;
  };

  return (
    <>
      {(!!blockedWindowUrl) && (
        renderBlockedWindowModal({ url: blockedWindowUrl, closeModal: handleCloseBlockedWindowModal })
      )}

      {
        shouldRenderSurvey && (
          <Styles.FloatingSurveyContainer>
            <FloatingSurvey {...floatingSurveyProps()} />
          </Styles.FloatingSurveyContainer>
        )
      }

      <PageMessages displayDomainPropagationAlert />
      <Styles.Container>
        {(enabledWelcomeChecklist && hasAbleSiteToConfigure) && (
          <WelcomeCheckListBanner
            open={listCheckedsLoaded.length < 3}
            hostingId={hostingId}
            listCheckedsLoaded={listCheckedsLoaded}
            setListCheckedsLoaded={setListCheckedsLoaded}
            originTypeBanner={getTypeWelcomeChecklistTypeBanner()}
            onCloseBanner={(e) => { setAbleSiteToConfigure(e); }}
            welcomeChecklistBannerAnalytics={welcomeChecklistBannerAnalytics}
          />
        )}
        {enabledDiscountOfferBanner && (subscription !== null || hasMoreThanDiscountSubscription) && (
          <DiscountOfferBanner
            subscription={subscription}
            origin="sites"
            hasMoreThanDiscountSubscription={hasMoreThanDiscountSubscription}
          />
        )}

        {enabledDiscountSaversBanner && invoicesWithSaversDiscount.length > 0 && (
          <DiscountSaversBanner
            invoice={invoicesWithSaversDiscount.length > 1 ? null : invoicesWithSaversDiscount[0]}
          />
        )}

        {enabledFilterV2 && !shouldGroupCards && (
          <Styles.FilterWrapper>
            <PageTitle {...getFilterProps()} noPadding />
          </Styles.FilterWrapper>
        )}

        {shouldGroupCards && mountFilter}

        {shouldGroupCards && (
          <DomainsAlerts {...getDomainsAlertrsProps()} />
        )}

        {enabledNewStatusDomain && shouldRenderToast && eligibleForTitanUpgrade && (
          <Styles.TitanMessage>
            <TitanUpgradeToastHandler {...titanUpgradeToastHandlerProps()} />
          </Styles.TitanMessage>
        )}

        {!enabledFilterV2 && !shouldGroupCards && (
          <Styles.Row marginbottom>
            <Header {...headerProps()}>
              <Styles.InfoWrapper>
                <IconInfo color={theme.palette.primary.main} />
              </Styles.InfoWrapper>
            </Header>
          </Styles.Row>
        )}

        {displayCreateSiteBanner && !shouldGroupCards && canShowCreateSiteBanner && (
          <CreateSiteBanner {...createSiteBannerProps()} />
        )}

        {!enabledFilterV2 && !shouldGroupCards && (
          <NewPageDescription {...newPageDescriptionProps()} />
        )}

        {((!loadedSites || loadingAllDomainsDnsStatus) && siteItemV2Toggle) && (
          renderSkelleton()
        )}

        {shouldGroupCards && (
          filterCanceledSites(sites).length
            ? <GroupedCards {...getGroupedCardsProps()} />
            : <EmptyList {...emptyListProps()} withMessage />
        )}

        {(!groupedCardsOnly && !shouldGroupCards) && (
          <Styles.Row>
            <Styles.Card data-testid="sitesWrapper">
              {filterCanceledSites(sites).length
                ? (
                  <>
                    {siteItems}
                    {(siteItemV2Toggle && sitesLength < filterCanceledSites(filteredSitesItems).length) && (
                      <Styles.ButtonContainer>
                        <Button label={sitesLocale.page.seeMoreButton} variant="secondary" width="fit" onClick={handleClickSeeMoreButton} />
                      </Styles.ButtonContainer>
                    )}
                  </>
                )
                : (
                  <>
                    <EmptyList {...emptyListProps()} />
                  </>
                )
              }
              {siteItems.length === 0 && filterCanceledSites(sites).length > 0 && (
                <Styles.Row>
                  <Styles.SearchNoResultMessage data-testid="notFoundMessage">
                    {noResultMessage}
                  </Styles.SearchNoResultMessage>
                </Styles.Row>
              )}
            </Styles.Card>
          </Styles.Row>
        )}

        {enabledTitanTriaBanner && domainWithoutEmail.length > 0 && !sites.length && (
          <TrialActiveBannerInSites {...trialActiveBannerInSitesProps()} />
        )}

        {!!domainToConfigureSSL.hostingId && (
          <ModalAutoSSL
            handleCloseModal={handleCloseAutoSSLModal}
            domainToConfigureSSL={domainToConfigureSSL}
            initialStep={sslModalInitialStep}
          />
        )}

        <Styles.Row>
          <Styles.FaqWrapper>
            <FaqMySites />
            {enabledNewStatusDomain && shouldRenderBottomBanner && eligibleForTitanUpgrade && <TitanUpgradeBottomAdvertisementHandler {...titanUpgradeBottomAdvertisementHandlerProps()} />}
            {isBRServer && (
              <AsaasSitesAdvertisement {...asaasSitesAdvertisementProps()} />
            )}
          </Styles.FaqWrapper>
        </Styles.Row>

        <SitesHandlerModals {...sitesHandlerModalsProps()} />
      </Styles.Container>
    </>
  );
};

export default withI18n()(SitesHandler);
