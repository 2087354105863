import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { IconArrowDown } from '@/icons';
import * as Styles from './MultiSelect.styles';

const MultiSelect = ({
  defaultOptionLabel = 'Assuntos',
  disabled = false,
  error = '',
  label = '',
  options = [],
  testId = 'select',
  size = 'medium',
  setMultiSelected = () => {},
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [selectOptions, setSelectOptions] = useState(options);

  const onMultiSelectClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onOptionClick = (e, option) => {
    e.preventDefault();
    const newMappedSelection = selectOptions.map(e => ({ ...e, checked: (e.id !== option.id) ? e.checked : !e.checked }));
    setSelectOptions(newMappedSelection);
    const newSelected = newMappedSelection.filter(e => e.checked);
    setMultiSelected(newSelected);
  };

  const onMouseDown = useCallback(
    (event) => {
      (
        isOpen
        && wrapperRef.current
        && !wrapperRef.current.contains(event.target)
      ) && setIsOpen(false);
    },
    [isOpen],
  );

  const optionsMap = selectOptions.map((option, index) => (
    <Styles.Option data-testid={`${testId}-option-${option.id}`} key={`id-${option.id}-order-${index.toString()}`} onClick={e => onOptionClick(e, option)}>
      {option.id !== 'default' && <Styles.Checkbox id={option.value} checked={option.checked} />}
      <Styles.OptionLabel htmlFor={option.value}>{option.label}</Styles.OptionLabel>
    </Styles.Option>
  ));

  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseDown]);

  useEffect(() => {
    setIsEmpty(selectOptions.length === 1);
  }, [selectOptions.length, isEmpty, selectOptions, label]);

  return (
    <Styles.Wrapper data-testid={testId} ref={wrapperRef} disabled={isEmpty || disabled} size={size}>
      {label && (
        <Styles.FixedLabel
          data-testid={`${testId}-fixed-label`}
          className={`${error ? 'error' : ''} ${isEmpty || disabled ? 'disabled' : ''}`}
        >
          {label}
        </Styles.FixedLabel>
      )}
      <Styles.Field
        data-testid={`${testId}-field`}
        disabled={isEmpty || disabled}
        className={error ? 'error' : ''}
      >
        <Styles.Header type="button" onClick={onMultiSelectClick} size={size}>
          <Styles.Label data-testid={`${testId}-label`} size={size}>{defaultOptionLabel}</Styles.Label>
          <Styles.IconAdapter>
            <IconArrowDown />
          </Styles.IconAdapter>
        </Styles.Header>

        <Styles.Options data-testid={`${testId}-options`} show={isOpen}>
          {optionsMap}
        </Styles.Options>
      </Styles.Field>

      {error && <Styles.Error data-testid={`${testId}-error`}>{error}</Styles.Error>}
    </Styles.Wrapper>
  );
};

export default MultiSelect;
