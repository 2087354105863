import React, { useContext, useRef } from 'react';
import { Tabs, Tab, withStyles } from '@material-ui/core/';
import classnames from 'classnames';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withI18n } from 'react-i18next';
import { MktPlaceContext } from '@/contexts/MktPlace/context';
import styles from './styles';
import CollabItem from './CollabItem/CollabItem';

const CollabList = ({ classes, width, t }) => {
  const { collabItems } = useContext(MktPlaceContext);
  const [value, setValue] = React.useState(0);
  const isWidthUpSm = isWidthUp('sm', width);
  const listWrapperRef = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (isWidthUpSm && listWrapperRef.current && listWrapperRef.current.scroll) {
      listWrapperRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={classes.tabWrapper} data-testid="collabList">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secundary"
        className={classes.tabContainer}
      >
        <Tab
          label={t('mktPlace.colab.premiumTab')}
          className={classnames(classes.tabTitle, {
            [classes.activeTab]: value === 0,
          })}
        />

      </Tabs>

      <div className={classes.listItemsWrapper} ref={listWrapperRef}>
        {collabItems.filter(item => (value === 0 ? item.premium : !item.premium)).map(item => (
          <CollabItem item={item} key={`${item.id}_${item.name}`} />
        ))}
      </div>
    </div>
  );
};

export default withI18n()(withWidth()(withStyles(styles)(CollabList)));
