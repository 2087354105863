import useBlockedWindowModalBR from './useBlockedWindowModal.br';
import useBlockedWindowModalCL from './useBlockedWindowModal.cl';
import useBlockedWindowModalCO from './useBlockedWindowModal.co';
import useBlockedWindowModalMX from './useBlockedWindowModal.mx';

const locales = {
  BR: useBlockedWindowModalBR,
  CL: useBlockedWindowModalCL,
  CO: useBlockedWindowModalCO,
  MX: useBlockedWindowModalMX,
};

export default locales;
