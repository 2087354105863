const styles = theme => ({
  link: {
    display: 'inline-block',
    position: 'relative',
    color: theme.color.emperor,
    marginLeft: '-10px',
  },
  icon: {
    fontSize: '64px',
    color: 'inherit',
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.color.white,
    width: '64px',
    height: '64px',
    marginTop: '8px',
  },
});

export default styles;
