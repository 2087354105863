const styles = theme => ({
  wrapperThemesCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0 20px',
    maxWidth: 825,
    margin: '0 auto',
    marginTop: 35,
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentCard: {
    position: 'relative',
    width: '100%',
    maxWidth: 230,
    height: 130,
    border: `1px solid ${theme.color.mystic}`,
    display: 'flex',
    alignItems: 'flex-end',
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 291,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  contentTheme: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-2px',
    height: 36,
    width: '100%',
  },
  contentThemeSelected: {
    marginBottom: '-1px',
    height: 35,
  },
  buttonCustom: {
    display: 'block',
    margin: 0,
    marginBottom: '-1px',
    backgroundColor: theme.color.white,
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.mystic}`,
    color: theme.color.tundora,
    padding: '6px 16px',
    width: '51%',
    '&:hover': {
      backgroundColor: theme.color.mystic,
    },
  },
  contentThemeName: {
    backgroundColor: '#0000006b',
    position: 'absolute',
    top: 0,
    bottom: 33,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentThemeNameSelected: {
    bottom: 35,
  },
  borderButtomLeft: {
    marginLeft: '-1px',
  },
  borderButtomLeftSeleted: {
    marginLeft: 0,
    width: '50.5%',
    paddingLeft: 15,
  },
  borderButtomRight: {
    marginRight: '-1px',
  },
  borderButtomRightSelected: {
    marginRight: 0,
    width: '50.5%',
    paddingRight: 15,
  },
  titleTheme: {
    color: theme.color.white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    paddingBottom: 2,
    margin: 9,
    marginBottom: 7,
  },
  buttonLeftBorderCustom: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  buttonRightBorderCustom: {
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
});

export default styles;
