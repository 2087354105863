import React from 'react';
import { withStyles } from '@material-ui/core';
import { ContentContainer } from '@/components/Cards/ContentContainer';
import EmailsContextProvider from '@/contexts/Emails/context';
import styles from './styles';
import TitanMailPageWrapper from './TitanMailPageWrapper';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ALPHA_ADMIN_TITAN_MAIL } from '@/config/GrowthBook/constants';

const TitanMail = ({ classes }) => {
  const titanMailFeature = useFeatureIsOn(ALPHA_ADMIN_TITAN_MAIL);

  return (
    <ContentContainer className={classes.root}>
      {titanMailFeature
        && (
          <EmailsContextProvider>
            <TitanMailPageWrapper />
          </EmailsContextProvider>
        )
      }
    </ContentContainer>
  );
};

export default withStyles(styles)(TitanMail);
