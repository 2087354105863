import { COUNTRY } from '@/config';

const ES_COMPANIES_URLS = [{
  label: 'GoDaddy',
  url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115004025067',
},
{
  label: 'Neubox',
  url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037755071',
},
{
  label: 'Colombia Hosting',
  url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037389492',
},
{
  label: 'Web Empresa',
  url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/360037758891',
}];

const COMPANIES_URLS_BY_BRAND = {
  br: [
    {
      label: 'Registro.br',
      url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/115000389114',
    },
    {
      label: 'GoDaddy',
      url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037642314',
    },
    {
      label: 'Locaweb',
      url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037640614',
    },
    {
      label: 'UolHost',
      url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037642134',
    },
    {
      label: 'KingHost',
      url: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360037644234',
    },
  ],
  mx: ES_COMPANIES_URLS,
  cl: ES_COMPANIES_URLS,
  co: ES_COMPANIES_URLS,
};

const DNS_KNOW_MORE_BY_BRAND = {
  br: 'https://suporte.hostgator.com.br/hc/pt-br/articles/360019596774',
  mx: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001261012',
  cl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001261012',
  co: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115001261012',
};

export const DNS_KNOW_MORE_URL = DNS_KNOW_MORE_BY_BRAND[COUNTRY];
export const CHANGE_DNS_OTHER_PROVIDER_URL = COMPANIES_URLS_BY_BRAND[COUNTRY];
