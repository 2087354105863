export const CloudflareActionTypes = {
  DOMAINS_REQUEST: 'CLOUDFLARE_DOMAINS_REQUEST',
  DOMAINS_FAILURE: 'CLOUDFLARE_DOMAINS_FAILURE',
  DOMAINS_SUCCESS: 'CLOUDFLARE_DOMAINS_SUCCESS',
  DOMAINS_SET: 'CLOUDFLARE_DOMAINS_SET',
  DOMAINS_LOADING: 'CLOUDFLARE_DOMAINS_LOADING',
  DOMAINS_ERROR: 'CLOUDFLARE_DOMAINS_ERROR',
  ACTIVATE_CDN: 'CLOUDFLARE_ACTIVATE_CDN',
  DEACTIVATE_CDN: 'CLOUDFLARE_DEACTIVATE_CDN',
  LOADING_ACTIVATION: 'LOADING_ACTIVATION',
  LOADING_ACTIVATION_SUCCESS: 'LOADING_ACTIVATION_SUCCESS',
  LOADING_ACTIVATION_FAILURE: 'LOADING_ACTIVATION_FAILURE',
  LOADING_DEACTIVATION_SUCCESS: 'LOADING_DEACTIVATION_SUCCESS',
  LOADING_DEACTIVATION_FAILURE: 'LOADING_DEACTIVATION_FAILURE',
  SET_ACTIVATED_DOMAIN: 'SET_ACTIVATED_DOMAIN',
};
