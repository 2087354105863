import React, {
  useEffect, useRef,
} from 'react';
import * as Styles from './WizardBackup.styles';
import {
  Alert, Button, IconBackupDetail, IconButton, IconDownloadDetail, IconLoading, IconUploadDetail,
} from 'gatorcomponents';
import { Card } from './components/Card';
import { wizardActions, wizardRestorationOptios } from './WizardBackup.types';
import { SITE_HG } from '@/config';
import { backupAnalytics } from '@/analytics';


const WizardBackup = ({
  title = 'title',
  TABS,
  description = 'description',
  fristStepTitle = 'firstStepTitle',
  wizardAction,
  setWizardAction,
  generateLocale,
  restorateLocale,
  cancelledRestoreAlert = 'cancelledRestoreAlert',
  cancelledGenerateAlert = 'cancelledGenerateAlert',
  suspendedRestoreAlert = 'suspendedRestoreAlert',
  suspendedGenerateAlert = 'suspendedGenerateAlert',
  setActiveTab,
  setShowWizard,
  wizardRestorationOption,
  setWizardRestorationOption,
  haveFile,
  setHaveFile,
  currentProduct,
  hireNewLabel = 'hireNew',
  askRestorationLabel = 'askRestoration',
  askBackupLabel = 'askBackupLabel',
  breadCrumb,
  goToCPanel,
  cPanelLoading,
  openFormUserFile,
  openFormHgRoutine,
  openGenerateBackup,
  loadingInodes,
  inodesAndSizeOk,
  redirectFromPreChat,
  clearRedirectFromPrechat,
}) => {
  const productStatus = currentProduct && currentProduct.status && currentProduct.status.toLowerCase();
  const isActive = productStatus === 'active';
  const isCancelled = productStatus === 'cancelled';
  const isSuspended = productStatus === 'suspended';
  const restoreSecondOption = useRef();
  const restoreThirdOption = useRef();

  useEffect(() => {
    if (
      currentProduct
       && currentProduct.status
       && currentProduct.status.toLowerCase() === 'suspended'
    ) {
      setHaveFile(false);
    }
  }, [currentProduct, setHaveFile]);

  useEffect(() => {
    if (restoreSecondOption.current) {
      window.scrollTo({
        top: restoreSecondOption.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [restoreSecondOption.current]);

  const handleWizardAction = (action) => {
    if (action === wizardActions.RESTORE) {
      backupAnalytics.restoreBackup();
    }
    if (action === wizardActions.GENERATE) {
      backupAnalytics.generateBackup();
    }

    setHaveFile(null);
    setWizardRestorationOption(null);
    setWizardAction(action);
  };

  const gaTagDispatch = () => {
    if (wizardAction === wizardActions.RESTORE) {
      if (haveFile) {
        if (wizardRestorationOption === wizardRestorationOptios.FULL) {
          backupAnalytics.fullCustomerFileBackupClick();
        }
        if (wizardRestorationOption === wizardRestorationOptios.ROOT) {
          backupAnalytics.rootCustomerFileBackupClick();
        }
        if (wizardRestorationOption === wizardRestorationOptios.DATABASE) {
          backupAnalytics.databaseCustomerFileBackupClick();
        }
      }
      if (haveFile === false) {
        if (wizardRestorationOption === wizardRestorationOptios.FULL) {
          backupAnalytics.fullHgFileBackupClick();
        }
        if (wizardRestorationOption === wizardRestorationOptios.ROOT) {
          backupAnalytics.rootHgFileBackupClick();
        }
        if (wizardRestorationOption === wizardRestorationOptios.DATABASE) {
          backupAnalytics.databaseHgFileBackupClick();
        }
      }
    }
    if (wizardAction === wizardActions.GENERATE) {
      if (wizardRestorationOption === wizardRestorationOptios.FULL) {
        backupAnalytics.generateCompleteBackupClick();
      }
      if (wizardRestorationOption === wizardRestorationOptios.ROOT) {
        backupAnalytics.generateRootBackupClick();
      }
      if (wizardRestorationOption === wizardRestorationOptios.DATABASE) {
        backupAnalytics.generateDatabaseBackupClick();
      }
    }
  };

  const handleFile = (bool) => {
    setHaveFile(bool);
    setWizardRestorationOption(null);

    if (bool) {
      backupAnalytics.haveFileClick();
    }
    if (!bool) {
      backupAnalytics.hgFileClick();
    }

    if (restoreThirdOption.current) {
      window.scrollTo({
        top: restoreThirdOption.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleWizardRestorationOption = (action) => {
    setWizardRestorationOption(action);
  };

  const validatePreChat = () => {
    if (redirectFromPreChat) {
      clearRedirectFromPrechat();
    }
  };

  const handleClickGenerate = () => {
    gaTagDispatch();

    setActiveTab(TABS.CREATE);
    setShowWizard(false);
    validatePreChat();
  };

  const handleClickGenerateCpanel = () => {
    gaTagDispatch();

    goToCPanel && goToCPanel();
    validatePreChat();
  };

  const askBackup = () => {
    gaTagDispatch();

    if (haveFile) {
      openFormUserFile();
    }

    if (!haveFile) {
      openFormHgRoutine();
    }

    validatePreChat();
  };

  const handleClickRestore = () => {
    if (wizardRestorationOption === wizardRestorationOptios.FULL) {
      askBackup();
    }

    if (wizardRestorationOption !== wizardRestorationOptios.FULL) {
      gaTagDispatch();
      setActiveTab(TABS.RESTORE);
      setShowWizard(false);
    }

    validatePreChat();
  };

  const hireNew = () => {
    gaTagDispatch();

    window.open(SITE_HG);

    validatePreChat();
  };

  const mountGenerateLabel = () => {
    if (cPanelLoading) {
      return <IconLoading testId="icon-loading" />;
    }

    return wizardRestorationOption === wizardRestorationOptios.ROOT ? generateLocale.buttonLable : generateLocale.cpanelButtonLable;
  };

  const mountRestoreLabel = () => (wizardRestorationOption === wizardRestorationOptios.FULL
    ? restorateLocale.buttonLable
    : restorateLocale.continue);

  const mountRestoreThirdStepCardsLabels = (productStatus, backupType) => {
    if (productStatus === 'suspended') {
      return {
        title: restorateLocale.thirdStep.options.suspended[backupType].title,
        description: restorateLocale.thirdStep.options.suspended[backupType].description,
      };
    }
    if (productStatus === 'active' && !!haveFile) {
      return {
        title: restorateLocale.thirdStep.options.active.userFile[backupType].title,
        description: restorateLocale.thirdStep.options.active.userFile[backupType].description,
      };
    }
    if (productStatus === 'active' && !haveFile) {
      return {
        title: restorateLocale.thirdStep.options.active.routineFile[backupType].title,
        description: restorateLocale.thirdStep.options.active.routineFile[backupType].description,
      };
    }
  };

  const handleGenerateBackup = () => {
    openGenerateBackup();
    validatePreChat();
  };

  if (!currentProduct || loadingInodes) {
    return (
      <Styles.Container>
        <Styles.LoaderWrapper>
          <IconLoading />
        </Styles.LoaderWrapper>
      </Styles.Container>
    );
  }

  const shouldRenderRestorateSecondRow = (currentProduct.status.toLowerCase() === 'active' && wizardAction === wizardActions.RESTORE);
  const shouldRenderActionType = (!isCancelled && ((inodesAndSizeOk || wizardAction === wizardActions.RESTORE) || (haveFile !== null && wizardAction === wizardActions.GENERATE)));
  const shouldRenderRestorationSecondRow = (wizardAction === wizardActions.RESTORE && haveFile !== null && currentProduct && currentProduct.status.toLowerCase() === 'active') || (wizardAction === wizardActions.RESTORE && currentProduct && currentProduct.status.toLowerCase() !== 'active');
  const shoouldRenderActiveGenerateAction = (isActive && !inodesAndSizeOk && wizardAction !== wizardActions.RESTORE && wizardAction !== null);
  const shouldRenderInactiveGenerateDescription = !isActive && wizardAction === wizardActions.GENERATE;
  const shouldRenderActiveGenerateThirdRow = isActive && wizardAction === wizardActions.GENERATE;
  const shouldRenderActiveGenerateAction = isActive && wizardAction === wizardActions.GENERATE && wizardRestorationOption;
  const shouldRenderActiveGenerateRootDescription = wizardRestorationOption === wizardRestorationOptios.ROOT;
  const shouldRenderActiveRestorateAction = (isActive && wizardAction === wizardActions.RESTORE && haveFile !== null && wizardRestorationOption);

  return (
    <Styles.Container>
      <Styles.BreadCrumb>
        <IconButton
          testId="backButton"
          icon="IconArrow"
          onClick={() => setShowWizard(false)}
        />
        {breadCrumb}
      </Styles.BreadCrumb>

      <Styles.Title>
        {title}
      </Styles.Title>

      <Styles.Description>
        {description}
      </Styles.Description>

      <Styles.StepTitle>
        {fristStepTitle}
      </Styles.StepTitle>

      <Styles.Row>
        <Card
          icon={<IconDownloadDetail />}
          testId="actionGenerate"
          title={generateLocale.optionTitle}
          description={generateLocale.optionDescription}
          selected={wizardAction === wizardActions.GENERATE}
          onClick={() => handleWizardAction(wizardActions.GENERATE)}
        />

        <Card
          icon={<IconUploadDetail />}
          testId="actionRestore"
          title={restorateLocale.optionTitle}
          description={restorateLocale.optionDescription}
          selected={wizardAction === wizardActions.RESTORE}
          onClick={() => handleWizardAction(wizardActions.RESTORE)}
        />
      </Styles.Row>

      {shouldRenderRestorateSecondRow && (
        <>
          <Styles.StepTitle>
            {restorateLocale.firstStep.title}
          </Styles.StepTitle>

          <Styles.Row ref={restoreSecondOption}>
            <Card
              testId="myFile"
              icon={null}
              description={restorateLocale.secondStep.options.positive}
              selected={!!haveFile}
              onClick={() => handleFile(true)}
            />

            <Card
              testId="hgFile"
              icon={null}
              description={restorateLocale.secondStep.options.negative}
              selected={haveFile === false}
              onClick={() => handleFile(false)}
            />
          </Styles.Row>
        </>
      )}

      {shouldRenderActionType && (
        <>
          {shouldRenderRestorationSecondRow && (
          <>
            <Styles.Description>
              {restorateLocale.secondStep.title}
            </Styles.Description>

            {(!!haveFile) && (
            <Styles.Row>
              <Card
                icon={<IconBackupDetail backgroundColor="pendingLight" />}
                testId="userFileFullRestoration"
                title={mountRestoreThirdStepCardsLabels(productStatus, 'full').title}
                description={mountRestoreThirdStepCardsLabels(productStatus, 'full').description}
                selected={wizardRestorationOption === wizardRestorationOptios.FULL}
                onClick={() => handleWizardRestorationOption(wizardRestorationOptios.FULL)}
              />

              <Card
                icon={<IconBackupDetail />}
                testId="userFileRootRestoration"
                title={mountRestoreThirdStepCardsLabels(productStatus, 'root').title}
                description={mountRestoreThirdStepCardsLabels(productStatus, 'root').description}
                selected={wizardRestorationOption === wizardRestorationOptios.ROOT}
                onClick={() => handleWizardRestorationOption(wizardRestorationOptios.ROOT)}
              />

              <Card
                icon={<IconBackupDetail backgroundColor="attentionLight" />}
                testId="userFileDatabaseRestoration"
                title={mountRestoreThirdStepCardsLabels(productStatus, 'database').title}
                description={mountRestoreThirdStepCardsLabels(productStatus, 'database').description}
                selected={wizardRestorationOption === wizardRestorationOptios.DATABASE}
                onClick={() => handleWizardRestorationOption(wizardRestorationOptios.DATABASE)}
              />
            </Styles.Row>
            )}

            {(!haveFile) && (
            <Styles.Row>
              <div>
                <Card
                  icon={<IconBackupDetail backgroundColor="pendingLight" />}
                  testId="routineFileFullRestoration"
                  title={mountRestoreThirdStepCardsLabels(productStatus, 'full').title}
                  description={mountRestoreThirdStepCardsLabels(productStatus, 'full').description}
                  selected={wizardRestorationOption === wizardRestorationOptios.FULL}
                  onClick={() => handleWizardRestorationOption(wizardRestorationOptios.FULL)}
                />
              </div>

              <div>
                <Card
                  icon={<IconBackupDetail />}
                  testId="routineFileRootRestoration"
                  title={mountRestoreThirdStepCardsLabels(productStatus, 'root').title}
                  description={mountRestoreThirdStepCardsLabels(productStatus, 'root').description}
                  selected={wizardRestorationOption === wizardRestorationOptios.ROOT}
                  onClick={() => handleWizardRestorationOption(wizardRestorationOptios.ROOT)}
                />
              </div>

              <div>
                <Card
                  icon={<IconBackupDetail backgroundColor="attentionLight" />}
                  testId="routineFileDatabaseRestoration"
                  title={mountRestoreThirdStepCardsLabels(productStatus, 'database').title}
                  description={mountRestoreThirdStepCardsLabels(productStatus, 'database').description}
                  selected={wizardRestorationOption === wizardRestorationOptios.DATABASE}
                  onClick={() => handleWizardRestorationOption(wizardRestorationOptios.DATABASE)}
                />
              </div>
            </Styles.Row>
            )}
          </>
          )}

          {shouldRenderInactiveGenerateDescription && (
            <Styles.Description>
              {generateLocale.secondStep.generateSuspendedTitle}
            </Styles.Description>
          )}

          {shouldRenderActiveGenerateThirdRow && (
            <>
              <Styles.Description>
                {generateLocale.secondStep.description}
              </Styles.Description>

              <Styles.Row>
                <div>
                  <Card
                    icon={<IconBackupDetail backgroundColor="pendingLight" />}
                    testId="fullRestoration"
                    title={generateLocale.thirdStep.options.active.full.title}
                    description={generateLocale.thirdStep.options.active.full.description}
                    selected={wizardRestorationOption === wizardRestorationOptios.FULL}
                    onClick={() => handleWizardRestorationOption(wizardRestorationOptios.FULL)}
                  />
                </div>

                <div>
                  <Card
                    icon={<IconBackupDetail />}
                    testId="rootRestoration"
                    title={generateLocale.thirdStep.options.active.root.title}
                    description={generateLocale.thirdStep.options.active.root.description}
                    selected={wizardRestorationOption === wizardRestorationOptios.ROOT}
                    onClick={() => handleWizardRestorationOption(wizardRestorationOptios.ROOT)}
                  />
                </div>

                <div>
                  <Card
                    icon={<IconBackupDetail backgroundColor="attentionLight" />}
                    testId="databaseRestoration"
                    title={generateLocale.thirdStep.options.active.database.title}
                    description={generateLocale.thirdStep.options.active.database.description}
                    selected={wizardRestorationOption === wizardRestorationOptios.DATABASE}
                    onClick={() => handleWizardRestorationOption(wizardRestorationOptios.DATABASE)}
                  />
                </div>
              </Styles.Row>
            </>
          )}

          {(shouldRenderActiveGenerateAction) && (
            <>
              {shouldRenderActiveGenerateRootDescription ? (
                <>
                  <Styles.Description>
                    {generateLocale.generateTexts.description}
                  </Styles.Description>

                  <Styles.Description>
                    {generateLocale.generateTexts.info}
                  </Styles.Description>
                </>
              ) : (
                <>
                  <Styles.Description>
                    {generateLocale.generateByCpanel.description}
                  </Styles.Description>

                  <Styles.Description>
                    {generateLocale.generateByCpanel.info}
                  </Styles.Description>
                </>
              )}

              <Styles.Row>
                <Button
                  testId="generate"
                  disabled={cPanelLoading}
                  label={mountGenerateLabel()}
                  onClick={wizardRestorationOption === wizardRestorationOptios.ROOT ? handleClickGenerate : handleClickGenerateCpanel}
                  size="large"
                />
              </Styles.Row>
            </>
          )}

          {shouldRenderActiveRestorateAction && (
          <>
            {(haveFile === false) && (
              <Styles.AlertWrapper>
                <Alert
                  variant="warning"
                  description={restorateLocale.description}
                />
              </Styles.AlertWrapper>
            )}

            {!!haveFile && wizardRestorationOption === wizardRestorationOptios.FULL && (
              <Styles.AlertWrapper>
                <Alert
                  variant="warning"
                  description={restorateLocale.fullBkpDescription}
                />
              </Styles.AlertWrapper>
            )}

            {!!haveFile && wizardRestorationOption !== wizardRestorationOptios.FULL && (
              <Styles.InfoWrapper>
                {restorateLocale.noFullBkpDescription}
              </Styles.InfoWrapper>
            )}

            <Styles.Row>
              <Button
                testId="restore"
                label={haveFile
                  ? mountRestoreLabel()
                  : restorateLocale.buttonLable
                }
                onClick={haveFile
                  ? handleClickRestore
                  : askBackup
                }
                size="large"
              />
            </Styles.Row>
          </>
          )}
        </>
      )}

      {(!isActive) && (
        <>
          <Styles.Row>
            {wizardAction === wizardActions.RESTORE && (isSuspended ? !!wizardRestorationOption : true) && (
              <Alert
                variant="error"
                description={
                isCancelled
                  ? cancelledRestoreAlert
                  : suspendedRestoreAlert
                }
              />
            )}

            {wizardAction === wizardActions.GENERATE && (
              <Alert
                variant="error"
                description={
                isCancelled
                  ? cancelledGenerateAlert
                  : suspendedGenerateAlert
                }
              />
            )}
          </Styles.Row>

          <Styles.Row>
            {wizardAction === wizardActions.RESTORE && (isSuspended ? !!wizardRestorationOption : true) && (
              <Button
                size="large"
                testId="cancelled"
                label={isCancelled
                  ? hireNewLabel
                  : askRestorationLabel
                }
                onClick={isCancelled
                  ? () => hireNew()
                  : () => askBackup()
                }
              />
            )}
            {wizardAction === wizardActions.GENERATE && (
              <Button
                size="large"
                testId="cancelled"
                label={isCancelled
                  ? hireNewLabel
                  : askBackupLabel
                }
                onClick={isCancelled
                  ? () => hireNew()
                  : () => handleGenerateBackup()
                }
              />
            )}
          </Styles.Row>
        </>
      )}

      {shoouldRenderActiveGenerateAction && (
        <>
          <Styles.Description>
            {restorateLocale.inodeMessage}
          </Styles.Description>

          <Styles.Row>
            <Button
              size="large"
              testId="cancelled"
              label={restorateLocale.exceededInodeButton}
              onClick={handleGenerateBackup}
            />
          </Styles.Row>
        </>
      )}
    </Styles.Container>
  );
};

export default WizardBackup;
