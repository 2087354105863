import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import styled, { css } from 'styled-components';

export const LabelWrapper = styled.div`
  display: inline-block;
`;

export const StatusLabel = styled.span`
  ${({ theme, propagationStatus }) => css`
    background: ${theme.v2.colors.feedback.activePure};
    border-radius: 2px;
    color: ${theme.v2.colors.white.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    margin-left: 10px;
    opacity: 0;
    padding: 6px;
    visibility: hidden;
    white-space: nowrap;

    ${propagationStatus === PROPAGATION_STATUS.IN_PROGRESS && css`
      background: ${theme.v2.colors.feedback.pendingMedium};
      opacity: 1;
      visibility: visible;
    `}

    ${propagationStatus === PROPAGATION_STATUS.NOT_PROPAGATED && css`
      background: ${theme.v2.colors.feedback.problemMedium};
      opacity: 1;
      visibility: visible;
    `}

    ${propagationStatus === PROPAGATION_STATUS.NOT_CONTRACTED && css`
      background: ${theme.v2.colors.feedback.problemMedium};
      opacity: 1;
      visibility: visible;
    `}

    ${propagationStatus === PROPAGATION_STATUS.NOT_CONFIGURED && css`
      background: ${theme.v2.colors.feedback.problemMedium};
      opacity: 1;
      visibility: visible;
    `}
  `}
`;
