import React from 'react';
import MaskedInput from 'react-text-mask';


function ZoneManagerSubdomainInputMask(props) {
  const { inputRef, ...other } = props;

  const optionsConfig = {
    guide: false,
    mask: value => Array(value.length).fill(/[A-Za-z0-9-@\\-\\.]/),
  };

  return (
    <MaskedInput
      {...optionsConfig}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

export default ZoneManagerSubdomainInputMask;
