import React from 'react';
import { withStyles, withWidth } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line camelcase
import unstable_useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
// eslint-disable-next-line camelcase
import styles from './styles';


const Error404Page = ({ t, classes }) => {
  const isMobile = unstable_useMediaQuery('(max-width:699px)');
  return (
    <div className={classes.root}>
      {!isMobile && <p className={classes.textNote}>{t('notFound.error')}</p>}
      <h1 className={classes.title}>{t('notFound.title')}</h1>
      <p className={classes.textDescription}>{t('notFound.description')}</p>
      <PrimaryButton component={Link} to="/">
        {t('notFound.textLink')}
      </PrimaryButton>
    </div>
  );
};

export default withWidth()(withStyles(styles)(withI18n()(Error404Page)));
