import React from 'react';
import * as Styles from './Chip.style';

const Chip = ({
  testId = 'menu-item-component',
  label = 'Label Text',
  recommended = true,
  altLayout = false,
  bold = false,
}) => (
  <Styles.Chip recommended={recommended} data-testid={testId} altLayout={altLayout} bold={bold}>
    {label}
  </Styles.Chip>
);

export default Chip;
