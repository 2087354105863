const styles = theme => ({
  card: {
    position: 'relative',
    borderRadius: '0',
    boxShadow: `0px 2px 4px 0px ${theme.color.alto}`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      boxShadow: 'none',
      height: '100vh',
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    margin: '45px 0',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexgrow: 1,
    padding: '20px',
    minHeight: 'calc(92vh - 92px)',
    borderLeft: '5px solid',
    borderColor: theme.color.indigo,
    '& .status-icon': {
      color: theme.color.indigo,
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      minHeight: '92vh',
      paddingBottom: 0,
      paddingLeft: 16,
    },
  },
  warningWrapper: {
    width: '291px',
    marginBottom: '30px',
  },
  fireTopRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fireTopLeft: {
    [theme.breakpoints.down('sm')]: {
      left: '-55px',
      top: '-10px',
    },
  },
  fireBottomLeft: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      bottom: '-20px',
      left: '-55px',
    },
  },
  fireBottomRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      bottom: '-20px',
      right: '-55px',
    },
  },
});

export default styles;
