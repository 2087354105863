import React from 'react';
import * as Style from './IconBackup.style';

const IconBackup = ({
  color = '#1F2044',
  background = '#F67922',
  size = '40',
  testId = 'icon-backup',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1128 5.1087V35.7014C24.1696 35.7014 28.0603 34.0898 30.9289 31.2212C33.7975 28.3526 35.4091 24.4619 35.4091 20.4051C35.4091 16.3482 33.7975 12.4575 30.9289 9.5889C28.0603 6.72028 24.1696 5.1087 20.1128 5.1087Z" fill={background} />
      <path d="M28.779 31.7071H7.50148V29.6339H26.705V11.0069H7.50148V8.93362H28.779V31.7071Z" fill={color} />
      <path d="M27.5142 22.4224H7.5015V24.4957H27.5142V22.4224Z" fill={color} />
      <path d="M27.5142 15.4165H7.5015V17.4897H27.5142V15.4165Z" fill={color} />
      <path d="M12.2598 12.1829H9.85269V14.2561H12.2598V12.1829Z" fill={color} />
      <path d="M12.2598 25.656H9.85269V27.7292H12.2598V25.656Z" fill={color} />
      <path d="M12.2598 19.1888H9.85269V21.262H12.2598V19.1888Z" fill={color} />
    </svg>

  </Style.Wrapper>
);


export default IconBackup;
