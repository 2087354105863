import styled, { css } from 'styled-components';

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
    position: relative;
  `}
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0px;
`;

export const CardText = styled.p`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 8px;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const FloatingIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const FloatingImage = styled.div`
  ${({ theme }) => css`
    bottom: 20px;
    display: flex;
    position: absolute;
    right: 8px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      right: 18px;
      bottom: -1px;
    }
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    max-width: 56px;

    @media(min-width:${theme.v2.breakpoints.sm}){
      max-width: unset;
    }
  `}
`;
