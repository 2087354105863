import React from 'react';
import * as Style from './IconThrash.style';

const IconThrash = ({
  color = '#2E93EE',
  size = 16,
  testId = 'icon-thrash',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.3 17C2.8 17 2.375 16.825 2.025 16.475C1.675 16.125 1.5 15.7 1.5 15.2V2.5H0.5V1H5V0.125H11V1H15.5V2.5H14.5V15.2C14.5 15.7 14.325 16.125 13.975 16.475C13.625 16.825 13.2 17 12.7 17H3.3ZM13 2.5H3V15.2C3 15.2833 3.02933 15.3543 3.088 15.413C3.146 15.471 3.21667 15.5 3.3 15.5H12.7C12.7667 15.5 12.8333 15.4667 12.9 15.4C12.9667 15.3333 13 15.2667 13 15.2V2.5ZM5.4 13.5H6.9V4.5H5.4V13.5ZM9.1 13.5H10.6V4.5H9.1V13.5ZM3 2.5V15.5V15.2V2.5Z" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconThrash;
