import React, { useCallback, useState } from 'react';
import * as Styles from './EnterpriseSslCertificate.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const EnterpriseSslCertificate = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const {
    enterPrisessslCertificate: enterPrisessslCertificateLocale,
  } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    cnpj: '',
    phone: '',
    email: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(
    !!formData.cnpj
      && !!formData.phone
      && !!formData.email
      && !!formData.agreed
  ), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: enterPrisessslCertificateLocale.subject,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.ENTERPRISE_SSL,
      tags: [
        'whmcs_form_tecnico_certificado_ssl_empresarial',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
        • ${enterPrisessslCertificateLocale.body.product(selectedProduct)}
        • ${enterPrisessslCertificateLocale.body.request}
        • ${enterPrisessslCertificateLocale.body.cnpj(formData.cnpj)}
        • ${enterPrisessslCertificateLocale.body.phone(formData.phone)}
        • ${enterPrisessslCertificateLocale.body.email(formData.email)}
        • ${enterPrisessslCertificateLocale.body.agreed(formData.agreed)}
        • ${enterPrisessslCertificateLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    enterPrisessslCertificateLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {enterPrisessslCertificateLocale.info}
      </Styles.Info>

      <Styles.Title>
        {enterPrisessslCertificateLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="cnpj"
          placeholder={enterPrisessslCertificateLocale.placeholders.cnpj}
          label={formData.cnpj ? enterPrisessslCertificateLocale.placeholders.cnpj : ''}
          value={formData.cnpj}
          onChange={e => handleChange({ key: 'cnpj', value: e.target.value })}
          type="text"
          testId="cnpj"
        />

        <Styles.InputWrapper>
          <Input
            name="phone"
            placeholder={enterPrisessslCertificateLocale.placeholders.phone}
            label={formData.phone ? enterPrisessslCertificateLocale.placeholders.phone : ''}
            value={formData.phone}
            onChange={e => handleChange({ key: 'phone', value: e.target.value })}
            type="text"
            testId="phone"
          />
          <Styles.InfoText>
            {enterPrisessslCertificateLocale.placeholders.phoneInfo}
          </Styles.InfoText>
        </Styles.InputWrapper>

        <Styles.InputWrapper>
          <Input
            name="email"
            placeholder={enterPrisessslCertificateLocale.placeholders.email}
            label={formData.email ? enterPrisessslCertificateLocale.placeholders.email : ''}
            value={formData.email}
            onChange={e => handleChange({ key: 'email', value: e.target.value })}
            type="text"
            testId="email"
          />
          <Styles.InfoText>
            {enterPrisessslCertificateLocale.placeholders.emailInfo}
          </Styles.InfoText>
        </Styles.InputWrapper>

      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {enterPrisessslCertificateLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : enterPrisessslCertificateLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default EnterpriseSslCertificate;
