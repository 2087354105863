import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles, Button } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TicketDragAndDropUpload from '@/components/Tickets/TicketDragAndDropUpload';
import TicketButtonLoader from '@/components/Tickets/TicketButtonLoader';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import {
  uploadImagesTicketDetail, changeTicketStatus, updateTicketDetailStatus, onUploadImagesTicketDetailV2,
} from '@/redux/actions/ticketDetail';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { FILE_EXTENSIONS_PERMITTED } from '@/utils/Validators/fileExtensions';
import { notifierError } from '@/utils/Application/notifier';
import FileIcon from '@/media/icons/FileIcon';
import { UPLOAD_TICKET_IMAGES_ERROR } from '@/redux/actions/actionsTypes';
import styles from './styles';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class TicketUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      filePreviewUrl: '',
      filePreviewName: '',
      canSubmit: true,
    };
  }

  cleanFileInput = () => {
    document.getElementById('file').value = '';
  }

  handleChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const nameLowerCase = file.name.toLowerCase();
    const { enqueueSnackbar } = this.props;

    if (file.size > 20000000) {
      this.resetUpload();
      enqueueSnackbar('tickets.errorUploadSize', notifierError);
    }

    if (this.handleFilePreview(file.name) && file.size <= 20000000) {
      this.setState({
        file,
        filePreviewUrl: this.getFileExtension(file.name),
        filePreviewName: nameLowerCase,
      });
    } else if (this.handleImagePreview(file.name)) {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
          filePreviewName: nameLowerCase,
        });
      };
      reader.readAsDataURL(file);
    } else if (file.size <= 20000000) {
      enqueueSnackbar('tickets.errorUploadExtension', notifierError);
    }
  }

  handleDrop = (files) => {
    const reader = new FileReader();
    const file = files[0];
    const nameLowerCase = file.name.toLowerCase();
    const { enqueueSnackbar } = this.props;

    if (file.size > 20000000) {
      this.resetUpload();
      enqueueSnackbar('tickets.errorUploadSize', notifierError);
    }

    if (this.handleFilePreview(file.name) && file.size <= 20000000) {
      this.setState({
        file,
        filePreviewUrl: this.getFileExtension(file.name),
        filePreviewName: nameLowerCase,
      });
    } else if (this.handleImagePreview(file.name)) {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
          filePreviewName: nameLowerCase,
        });
      };
      reader.readAsDataURL(file);
    } else if (file.size <= 20000000) {
      enqueueSnackbar('tickets.errorUploadExtension', notifierError);
    }
  }

  handleSend = () => {
    const { filePreviewName, file } = this.state;
    const {
      t,
      comment,
      ticketId,
      ticket,
      onUploadImagesTicketDetail,
      onUploadImagesTicketDetailV2,
      userEmail,
      onChangeTicketStatus,
      onUpdateTicketDetailStatus,
      resetComment,
      handleClose,
      enqueueSnackbar,
      v2,
    } = this.props;

    this.setState({ canSubmit: false });
    const fileToUpload = {
      comment: {
        body: comment !== '' ? comment : `${t('tickets.anexo')} ${filePreviewName}`,
        id: ticketId,
        file: [file],
      },
    };
    if (this.validExtension(file)) {
      if (v2) {
        onUploadImagesTicketDetailV2(fileToUpload, userEmail).then((res) => {
          if (ticket.status === 'solved') {
            const ticket = {
              id: ticketId,
              status: 'open',
            };
            onChangeTicketStatus(ticket, userEmail).then(() => {
              onUpdateTicketDetailStatus();
            });
          }
          resetComment();
          if (res.ticketDetail && res.ticketDetail.success) {
            this.resetUpload();
            this.cleanFileInput();
            document.getElementById('input-send').value = '';
            handleClose();
          }
          if (res.type === UPLOAD_TICKET_IMAGES_ERROR) {
            enqueueSnackbar('tickets.errorUploadFile', notifierError);
          }
          this.setState({ canSubmit: true });
        });
      }

      if (!v2) {
        onUploadImagesTicketDetail(fileToUpload, userEmail).then((res) => {
          if (ticket.status === 'solved') {
            const ticket = {
              id: ticketId,
              status: 'open',
            };
            onChangeTicketStatus(ticket, userEmail).then(() => {
              onUpdateTicketDetailStatus();
            });
          }
          resetComment();
          if (res.ticketDetail && res.ticketDetail.success) {
            this.resetUpload();
            this.cleanFileInput();
            document.getElementById('input-send').value = '';
            handleClose();
          }
          if (res.type === UPLOAD_TICKET_IMAGES_ERROR) {
            enqueueSnackbar('tickets.errorUploadFile', notifierError);
          }
          this.setState({ canSubmit: true });
        });
      }
    }
  }

  validExtension = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    if (FILE_EXTENSIONS_PERMITTED.includes(extension)) {
      return true;
    }
    return false;
  };

  handleCancelUpload = () => {
    const { handleClose } = this.props;
    this.cleanFileInput();
    this.setState({
      imagePreviewUrl: '',
      file: '',
      filePreviewUrl: '',
    });
    handleClose();
  };

  handleFilePreview = (name) => {
    if (/.pdf/.test(name)
        || /.doc/.test(name)
        || /.docx/.test(name)
        || /.key/.test(name)
        || /.crt/.test(name)
    ) {
      return true;
    }
    return false;
  };

  handleImagePreview = (nameReceived) => {
    const name = nameReceived.toLowerCase();
    if (/.jpg/.test(name)
        || /.jpeg/.test(name)
        || /.gif/.test(name)
        || /.png/.test(name)
    ) {
      return true;
    }
    return false;
  };

  getFileExtension = (name) => {
    const reverseName = name.split('').reverse().join('');
    const splitedName = reverseName.split('.');
    const fileExtension = splitedName[0];
    const firstExtension = fileExtension.substr(0, 1);

    if (/f/.test(firstExtension)) {
      return 'pdf';
    }

    if (/c/.test(firstExtension)) {
      return 'doc';
    }

    if (/x/.test(firstExtension)) {
      return 'docx';
    }

    if (/y/.test(firstExtension)) {
      return 'key';
    }

    if (/t/.test(firstExtension)) {
      return 'crt';
    }
    return false;
  }

  resetUpload = () => {
    this.cleanFileInput();
    this.setState({
      file: '',
      imagePreviewUrl: '',
      filePreviewUrl: '',
      filePreviewName: '',
    });
  }


  render() {
    const {
      imagePreviewUrl,
      filePreviewUrl,
      filePreviewName,
      canSubmit,
    } = this.state;
    const {
      open,
      classes,
      t,
      loadingUpload,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={loadingUpload ? () => false : this.handleCancelUpload}
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.wrapper }}
      >
        <DialogContent className={classes.dialog}>
          <div className={classes.close}>
            <Close onClick={loadingUpload ? () => false : this.handleCancelUpload} />
          </div>
          <p className={classes.title}>{t('tickets.sendImages')}</p>
          <TicketDragAndDropUpload handleDrop={this.handleDrop}>
            <div className={`${classes.dragArea}`} id="dragarea">
              <form className={classes.textUpload}>
                <span className={classes.dragText}>{t('tickets.uploadMessage')}</span>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="file"
                  name="file"
                  type="file"
                  multiple
                  onChange={this.handleChange}
                />
                <label htmlFor="file">
                  <Button variant="contained" component="span" className={classes.btnUpload} onClick={this.resetUpload}>
                    {t('tickets.uploadButton')}
                  </Button>
                </label>
              </form>

              {imagePreviewUrl && (
                <div className={classes.imgPreview}>
                  <div className={classes.imgBox}>
                    <img className={classes.img} alt="Preview" src={imagePreviewUrl} />
                    {loadingUpload && (
                      <div className={classes.uploadingGradient}>
                        <TicketButtonLoader />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {filePreviewUrl && (
                <div className={classes.fileWrapper}>
                  <FileIcon extension={filePreviewUrl} />
                  <p>{filePreviewName}</p>
                </div>
              )}

            </div>
          </TicketDragAndDropUpload>

        </DialogContent>

        <div className={classes.dialogActions}>
          <OutlineButton
            onClick={loadingUpload ? () => false : this.handleCancelUpload}
            className={classes.fillBtn}
            disabled={loadingUpload}
          >
            {t('cancel')}
          </OutlineButton>
          <PrimaryButton
            className={`${classes.fillBtn} ${classes.fillBtnSend}`}
            onClick={canSubmit ? this.handleSend : (() => false)}
            disabled={filePreviewUrl ? false : loadingUpload || imagePreviewUrl === ''}
          >
            {loadingUpload && filePreviewUrl !== '' ? <TicketButtonLoader /> : t('tickets.attachment') }
          </PrimaryButton>
        </div>

      </Dialog>
    );
  }
}

TicketUploadModal.propTypes = {
  resetComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userEmail: state.summary.email,
  ticketId: state.ticketDetail.id,
  loadingUpload: state.ticketDetail.loadingUpload,
  ticket: state.ticketDetail,
});

const mapDispatchToProps = dispatch => ({
  onUploadImagesTicketDetail: (data, userEmail) => dispatch(uploadImagesTicketDetail(data, userEmail)),
  onUploadImagesTicketDetailV2: (data, userEmail) => dispatch(onUploadImagesTicketDetailV2(data, userEmail)),
  onChangeTicketStatus: (ticket, userEmail) => dispatch(changeTicketStatus(ticket, userEmail)),
  onUpdateTicketDetailStatus: () => dispatch(updateTicketDetailStatus()),
  enqueueSnackbar,
});

export default withI18n()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketUploadModal)));
