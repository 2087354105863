import React, { useEffect } from 'react';
import { IconWarning } from '@/icons';
import { Link, Button } from '@/pages/common';
import * as Styles from './SignatureConfirmation.styles';
import { billingAnalytics } from '@/analytics';

const SignatureConfirmation = ({
  title = 'Please read the information below before confirming your cancellation:',
  attentionLabel = 'Attention',
  backupFilesLinkLabel = 'See how to back up your files',
  backupFilesLinkUrl = '',
  stopCancelLabel = "I don't want to cancel the renewal anymore",
  onStopCancel,
  canShowBackupLink = true,
  onClickBackupLink,
  topics = [
    <>
      Your subscription will be
      <strong> canceled immediately </strong>
      and all your data and files will be
      <strong> permanently deleted.</strong>
    </>,
  ],
  testId = 'confirmation',
}) => {
  const { subscriptions: subscriptionAnalytics } = billingAnalytics;

  useEffect(() => {
    subscriptionAnalytics.cancelSubscriptionPageView('6_confirmar_cancelamento_imediato');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnStopCancelClick = () => {
    onStopCancel && onStopCancel();
  };

  const hadleOnClickBackupLink = () => {
    onClickBackupLink && onClickBackupLink();
  };

  return (
    <Styles.Wrapper data-testid={testId}>
      <Styles.Title>
        {title}
      </Styles.Title>
      <Styles.Warning>
        <Styles.Attention>
          <IconWarning size={22} />
          <Styles.AttentionLabel>
            {attentionLabel}
          </Styles.AttentionLabel>
        </Styles.Attention>
        <Styles.Content>

          <Styles.List>
            {topics.map((topic, index) => (
              <Styles.ListItem key={`${testId}-topic-${index + 1}`}>{ topic }</Styles.ListItem>
            ))}
          </Styles.List>

          <Styles.Links>
            {canShowBackupLink && (
            <Styles.LinksItem>
              <Link
                onClick={() => hadleOnClickBackupLink()}
                href={backupFilesLinkUrl}
                text={backupFilesLinkLabel}
                testId={`${testId}-backupfiles-link`}
                target="_blank"
              />
            </Styles.LinksItem>
            )}
            <Styles.LinksItem>
              <Button
                variant="link"
                size="large"
                align="left"
                onClick={handleOnStopCancelClick}
                label={stopCancelLabel}
                testId={`${testId}-stopcancel-button`}
              />
            </Styles.LinksItem>
          </Styles.Links>
        </Styles.Content>
      </Styles.Warning>
    </Styles.Wrapper>
  );
};

export default SignatureConfirmation;
