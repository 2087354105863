import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: white;
    display: flex;
    flex-direction: column;
    font-family: ${theme.v1.font.family.primary};
    gap: 16px;

    strong {
      font-weight: ${theme.v1.font.weights.medium};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin-bottom: 40px;
    }
  `}
`;

export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.white.primary};
    display: flex;
    flex-direction: column;
    padding: 32px 16px 16px;
  `}
`;

export const FaqTextContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 14px;
    font-weight: 400;
    margin: 0;

    span {
      display: flex;
      margin: 0;
      padding: 0 8px 0 12px;
      font-weight: 500;
      user-select: none;
    }

    section {
      display: flex;
      margin-top: 8px;
    }

    li {
      margin-left: 12px;
    }

    a {
      font-size: 14px;
    }
  `}
`;

export const FaqTextParagraph = styled.div`
  ${() => css`
    display: flex;
    margin-top: 8px;
  `}
`;

export const FaqTextListNumber = styled.div`
  ${() => css`
    display: flex;
    margin: 0;
    padding: 0 8px 0 12px;
    font-weight: 500;
    user-select: none;
  `}
`;


export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size:  ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 32px 0px 0px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  `}
`;

export const DescriptionText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  `}
`;

export const SecondaryText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 0;
  `}
`;

export const BackupInfoContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
    border-top: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    padding: 24px 0;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      flex-direction: column;
      gap: 24px;
    }
  `}
`;

export const InfoContainer = styled.div`
  ${() => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `}
`;

export const InfoBox = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.v2.colors.feedback.canceledPure};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 100%;
    gap: 30px;
    padding: 25px 0px 22px;


    @media (max-width: ${theme.v2.breakpoints.md}){
      gap: 24px 0px;
    }
  `}
`;

export const PlanInfoWrapper = styled.div`
  ${({ theme }) => css`
  display: flex;
  justify-content: initial;
  gap: 12px;
  max-width: 240px;
  width: 100%;

  @media (max-width: ${theme.v2.breakpoints.md}){
    max-width: 50%;
  }
  `}
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  width: 100%;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin: 0px 16px 0px 0px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

export const StatusWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 194px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}){
      align-items: flex-end;
      max-width: 50%;
    }
  `}
`;

export const FaqContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  `}
`;

export const AccordionContainer = styled.div`
${() => css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`}
`;

export const SnappyBox = styled.div`
  ${() => css`
    position: relative;
    width: 100%;
    height: 100%;
  `}
`;

export const SnappyImageContainer = styled.div`
  ${() => css`
    height: 49px;
    margin: -34px 30px 0 0;
    overflow: hidden;
    position: absolute;

    right: 0;
    width: 92px;
  `}
`;

export const SnappyFace = styled.img`
  ${({ theme }) => css`
    bottom: 0px;
    width: 100%;
    height: auto;
    user-drag: none;

    @media (max-width: ${theme.breakpoints.sm}){
      display: none;
    }
  `}
`;

export const WizardWrapper = styled.div`
    display: block;
    max-width: 800px;
    
    p {
      margin: 16px 0px;
    }
`;
