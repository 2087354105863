export default {
  title: 'Confirme seu e-mail',
  subTitleWithEmail: '<0>Para sua segurança, enviamos um link para </0> <1>{{ email }}</1><2>. Acesse seu e-mail para finalizar o procedimento.</2>',
  subtitle: 'Verifique no seu e-mail o link de confirmação que acabamos de enviar',
  emailNotRecived: 'Não recebeu o e-mail?',
  resendText: 'Enviar novamente em ',
  passwordDescription: 'Informe a senha de acesso ao Portal do Cliente. Essa informação é necessária para a sua segurança.',
  changeEmail: {
    title: 'Altere seu e-mail',
    subtitle: 'Atenção, ele só será salvo após envio do e-mail de confirmação e validação.',
    placeholder: 'Informe aqui o seu novo e-mail',
    text: 'Seu e-mail de cadastro será atualizado para este novo e-mail.',
    hint: 'Dica: ao utilizar um e-mail pessoal (por exemplo, "@gmail.com"), você garante que sempre terá acesso a sua conta na HostGator, independente dos status financeiros dos seus produtos.',
  },
  errors: {
    invalidEmail: 'Digite um email válido',
    requiredEmail: 'Campo obrigatório',
    passwordMinimumCharacters: 'A senha deve ter no mínimo 6 caracteres.',
  },
  buttons: {
    cancel: 'Voltar',
    change: 'Validar e-mail',
    send: 'Reenviar e-mail',
    sendLoading: 'Enviando...',
    changeEmail: 'Alterar e-mail',
  },
  feedback: {
    noMoreAttempts: () => 'Você esgotou as solicitações de confirmação, tente novamente amanhã',
    emailSendAttempts: ({ attempts, used }) => `Você solicitou ${used} e-mail${used > 1 ? 's' : ''} nas últimas 24 horas, restam ${attempts} solicitações`,
    alreadyValidated: ({ hours, date }) => `E-mail confirmado à menos de 24 horas, você poderá alterar este e-mail às ${hours} horas do dia ${date}.`,
    resendSuccess: 'Reenviamos. Por favor, verifique seu e-mail novamente.',
    resendError: 'Não foi possível fazer o reenvio neste momento. Por favor, mude o e-mail ou tente novamente mais tarde.',
    changeSuccess: 'Verifique seu e-mail e clique no link de confirmação.',
    changeError: 'Tente novamente mais tarde ou altere o e-mail.',
    password: 'Senha incorreta. Por favor, tente novamente.',
    dataInUse: 'O e-mail informado já está em uso no seu cadastro. Para prosseguir, informe outro e-mail.',
    emailInDataBase: 'Ops! Este e-mail já está cadastrado. Por favor, escolha um novo endereço de e-mail.',
  },
};
