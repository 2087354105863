import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 40px;

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.white.primary};
    padding: 24px 24px 50px 24px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const PrivacyTitle = styled(Title)`
    margin-top: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 48px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};

    button {
      padding-left: 3px;
    }
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  min-width: 230px;
`;

export const Row = styled.div`
 ${({ theme, noWrap, margin }) => css`
    align-items: center;
    display: flex;
    gap: 8px;
    margin: ${margin || '0px'};

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-wrap: ${noWrap ? 'nowrap' : 'wrap'};
    }
  `}
`;
export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 150px;

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
 ${({ theme }) => css`
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const SelectWrapper = styled.div`
  ${({ half }) => css`
    width: ${half ? 'calc(100% - 12px)' : '100%'};
    > div {
      margin-right: 16px;
    }
  `}
`;

export const ForeignerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  > div {
    min-width: calc(50% - 20px);
  }
`;

export const Loader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 93px;
    opacity: 0.7;
    position: fixed;
    top: -100px;
    width: 100%;
    z-index: 2;

    @media (max-width: ${theme.breakpoints.md}) {
      left: 0;
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme, hasError, phoneInput }) => css`
    flex: 1;
    min-width: auto;
    position: relative;
    padding-right: ${phoneInput ? '15px' : 0};

    a {
      bottom: ${hasError ? '30px' : '11px'};
      font-size: ${theme.v2.font.sizes.xxxs};
      position: absolute;
      right: 21px;
      z-index: 1;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      min-width: 100%;
    }
  `}
`;

export const CheckboxWrapper = styled.button`
${({ theme }) => css`
  background: transparent;
  border: 0px;

  input:checked {
    border: 2px solid ${theme.v2.colors.neutral.lowDark};
  };

  svg {
    display: inline-flex;
  };

  `}
`;

export const CheckboxInput = styled.input`
  display: none;
`;

export const Checkbox = styled.div`
  ${({ theme, checked }) => css`
    background-color: ${theme.v2.colors.white.primary};
    border: 2px solid ${checked ? theme.v2.colors.neutral.lowDark : theme.v2.colors.neutral.lowLight};
    borderRadius: 2px;
    cursor: pointer;
    display: flex;
    height: 19px;
    transition: all 300ms;
    width: 19px;

    svg {
      fill: ${theme.v2.colors.neutral.lowDark};
      stroke: ${theme.v2.colors.neutral.lowDark};
      flex: 0 0 15px;
    };

    &:hover {
      border: 2px solid ${theme.v2.colors.neutral.lowDark};
      color: ${theme.v2.colors.neutral.lowDark};
    };
  `}
`;
