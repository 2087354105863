import { COUNTRY } from '@/config';

const isBrazil = COUNTRY === 'br';

const dnsStatusInProgress = 'in_progress';

const ecommercePlusParamValue = 'ecommerceplus';

const stepsStatus = {
  CONFIGURATION: 1,
  ABOUT: 2,
  TOOLS: 3,
  TIPS: 4,
};

const setupSteps = {
  UTILS: 1,
  THEME: 2,
  PLUGIN: 3,
  ASAAS: 4,
};

const setupStepsInWondersuite = {
  UTILS: 1,
  ASAAS: 2,
};

const toolsNames = {
  OTHER: 'softaculous',
  SITEBUILDER: 'sitebuilder',
  WORDPRESS: 'wordpress',
  WOOCOMMERCE: 'wordpress-woocommerce',
  ECOMMERCEPLUS_TRIAL: 'wordpress-woocommerce-trial',
  ECOMMERCEPLUS: 'wordpress-woocommerce-plus',
};

const modals = {
  ANOTHER_DOMAIN: 'AnotherDomainModal',
  DOMAIN_NOT_REGISTERED: 'DomainNotRegistered',
  DOMAIN_REGISTERED: 'DomainRegistered',
  UPGRADE_PLAN: 'UpgradePlanModal',
  CHOOSE_PLAN: 'ChoosePlan',
  SELECT_DNS: 'SelectManualDnsModal',
  DNS_AUTOMATIC: 'ModalDnsAutomatic',
  DNS_IN_PROGRESS: 'DnsInProgressModal',
};


const brPlans = {
  planoP: 5,
  planoM: 6,
  planoStarter: 437,
  planoTurbo: 335,
  planoBusiness: 7,
  hospedagemPlus: 47,
  hospedagemColaboradoresInternos: 247,
  criadorSitesPessoal: 278,
  criadorSitesProfissional: 284,
  criadorSitesLojaOnline: 272,
  revenda1: 50,
  revenda2: 51,
  revenda3: 52,
  revenda4: 53,
  revenda5: 54,
  revendaComecando: 385,
  revendaCrescendo: 388,
  revendaExpandindo: 391,
  revendaVeterano: 394,
  revendaVeterano100: 397,
  revendaVeterano150: 409,
  revendaVeterano200: 400,
  revendaVeterano300: 403,
  revendaVeterano500: 406,
  revendaVeterano1000: 412,
  revendaVeterano1200: 415,
};

const esPlans = {
  planPersonal: 174,
  planEmpreendedor: 5,
  planNegocios: 6,
  planProfessional: 7,
  planTurbo: 213,
  creadorSitiosPersonal: 164,
  creadorSitiosProfessional: 165,
  creadorSitiosTiendEnLinea: 166,
  resellerCobre: 51,
  resellerPlata: 52,
  resellerOro: 53,
  resellerDiamante: 54,
  resellerAluminio: 50,
  resellerIniciando: 229,
  resellerCreciendo: 230,
  resellerExpandiendo: 231,
  resellerVeterano: 232,
  resellerVeterano100: 233,
  resellerVeterano150: 234,
  resellerVeterano200: 235,
  resellerVeterano300: 236,
  resellerVeterano500: 237,
  resellerVeterano1000: 240,
  resellerVeterano1200: 238,
};

const siteBuilderPlansIds = isBrazil
  ? [brPlans.criadorSitesPessoal, brPlans.criadorSitesProfissional, brPlans.criadorSitesLojaOnline]
  : [esPlans.creadorSitiosPersonal, esPlans.creadorSitiosProfessional, esPlans.creadorSitiosTiendEnLinea];

const singleDomainsPlansIds = isBrazil
  ? [brPlans.planoP, brPlans.planoStarter, brPlans.hospedagemPlus, ...siteBuilderPlansIds]
  : [esPlans.planPersonal, esPlans.planEmpreendedor, ...siteBuilderPlansIds];

const wordPressPlans = {
  WordPressBlog: 250,
  WordPressPro: 256,
  WordPressStartUp: 253,
  WordPressVip: 259,
};

const wordPressPlansIds = isBrazil
  ? [
    wordPressPlans.WordPressBlog,
    wordPressPlans.WordPressPro,
    wordPressPlans.WordPressStartUp,
    wordPressPlans.WordPressVip,
  ]
  : [];

const multiDomainsPlansIds = isBrazil
  ? [brPlans.planoM,
    brPlans.planoBusiness,
    brPlans.planoTurbo,
    brPlans.revenda1,
    brPlans.revenda2,
    brPlans.revenda3,
    brPlans.revenda4,
    brPlans.revenda5,
    brPlans.revendaComecando,
    brPlans.revendaExpandindo,
    brPlans.revendaVeterano,
    brPlans.revendaVeterano100,
    brPlans.revendaVeterano150,
    brPlans.revendaVeterano200,
    brPlans.revendaVeterano300,
    brPlans.revendaVeterano500,
    brPlans.revendaVeterano1000,
    brPlans.revendaVeterano1200,
  ]
  : [
    esPlans.planTurbo,
    esPlans.planNegocios,
    esPlans.planProfessional,
    esPlans.resellerCobre,
    esPlans.resellerPlata,
    esPlans.resellerOro,
    esPlans.resellerDiamante,
    esPlans.resellerAluminio,
    esPlans.resellerIniciando,
    esPlans.resellerCreciendo,
    esPlans.resellerExpandiendo,
    esPlans.resellerVeterano,
    esPlans.resellerVeterano100,
    esPlans.resellerVeterano150,
    esPlans.resellerVeterano200,
    esPlans.resellerVeterano300,
    esPlans.resellerVeterano500,
    esPlans.resellerVeterano1000,
    esPlans.resellerVeterano1200,
  ];

const plansToCallRdWhenVirtualStore = [brPlans.planoStarter, brPlans.planoP, brPlans.planoM, brPlans.planoTurbo];

export const wizardEnum = {
  stepsStatus,
  setupSteps,
  setupStepsInWondersuite,
  toolsNames,
  modals,
  siteBuilderPlansIds,
  singleDomainsPlansIds,
  multiDomainsPlansIds,
  wordPressPlansIds,
  dnsStatusInProgress,
  ecommercePlusParamValue,
  plansToCallRdWhenVirtualStore,
};

export default wizardEnum;
