export const INVOICES_STATUS = {
  PAYMENT_PENDING: 'Payment Pending',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  CANCELLED: 'Cancelled',
  OTHERS: 'Others',
};

export const INVOICES_STATUS_WITHOUT_CAPITALIZE = {
  PAYMENT_EXPIRED: 'unpaidExpired',
  PAYMENT_PENDING: 'paymentPending',
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELLED: 'cancelled',
  REFUNDED: 'refunded',
  OTHERS: 'others',
  FRAUD: 'fraud',
  PHISHING: 'phishing',
};

export const PHISHING_STATUS_ANALYSIS = 'order_pending_analysis';
