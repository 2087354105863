import React from 'react';
import {
  withStyles, Typography, Card,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import icon12x from '@/media/courses/AcademyPass/icon1@2x.png';
import icon1 from '@/media/courses/AcademyPass/icon1.png';
import icon22x from '@/media/courses/AcademyPass/icon2@2x.png';
import icon2 from '@/media/courses/AcademyPass/icon2.png';
import icon32x from '@/media/courses/AcademyPass/icon3@2x.png';
import icon3 from '@/media/courses/AcademyPass/icon3.png';
import icon42x from '@/media/courses/AcademyPass/icon4@2x.png';
import icon4 from '@/media/courses/AcademyPass/icon4.png';
import logo from '@/media/courses/AcademyPass/logo.png';
import { subscribeAcademyPassLpLoad } from '@/utils/ThirdParties/tagManager';
import styles from './styles';
import CtaPaymentOptions from './CtaPaymentOptions';


const CtaHeader = ({ classes, t }) => {
  const newCard = (img, img2x, title, subtitle) => (
    <div className={classes.listCard}>
      <img
        src={img}
        srcSet={`${img} 360w, ${img2x} 600w`}
        sizes="(min-width: 360px), (min-width: 600px)"
        alt={t(`academypass.cta.ctaHeader.${title}`)}
        className={classes.listIcon}
      />
      <div className={classes.itemList}>
        <Typography className={classes.title}>{t(`academypass.cta.ctaHeader.${title}`)}</Typography>
        <Typography className={classes.subtitle}>{t(`academypass.cta.ctaHeader.${subtitle}`)}</Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div>
          <img onLoad={subscribeAcademyPassLpLoad} src={logo} alt="Logo" className={classes.headerLogo} />
        </div>
        <Typography className={classes.headerTitle}>{t('academypass.cta.ctaHeader.title')}</Typography>
        <Typography className={classes.headerSubtitle}>{t('academypass.cta.ctaHeader.subtitle')}</Typography>
      </div>
      <Card className={classes.paymentOptions}>
        <CtaPaymentOptions />
        <div className={classes.perks}>
          {newCard(icon1, icon12x, 'listItemTitle1', 'listItemSubtitle1')}
          {newCard(icon2, icon22x, 'listItemTitle2', 'listItemSubtitle2')}
          {newCard(icon3, icon32x, 'listItemTitle3', 'listItemSubtitle3')}
          {newCard(icon4, icon42x, 'listItemTitle4', 'listItemSubtitle4')}
        </div>
      </Card>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(CtaHeader));
