import React, { useCallback, useState } from 'react';
import * as Styles from './UpgradeVps.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
  Select,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import useLocale from '@/hooks/useLocale/useLocale';
import { isBRServer } from '@/utils/Validators/validation';
import { HgProducts } from '../../SupportForms.types';

export const UpgradeVps = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { upgradeVps: upgradeVpsLocale } = ticketsLocale.forms;
  const [serverIp, setServerIp] = useState('');
  const [newProduct, setNewProduct] = useState(null);
  const [execution, setExecution] = useState(null);
  const [agreedTerms, setAgreedTerms] = useState(null);
  const [agreedResponsability, setAgreedResponsability] = useState(null);

  const checkForm = useCallback(() => {
    let isOk = false;

    isOk = isBRServer
      ? serverIp
      && newProduct
      && execution
      && agreedTerms
      && agreedResponsability
      : newProduct && agreedTerms;

    return !isOk;
  }, [
    serverIp,
    newProduct,
    execution,
    agreedTerms,
    agreedResponsability,
  ]);

  const getProductsOptions = useCallback(() => {
    const newProducts = [];

    const higherThanStandard = [HgProducts.VPS_STANDARD, HgProducts.VPS_OPTIMIZED, HgProducts.VPS_PLATINUM].includes(selectedProduct.pid);
    if (!higherThanStandard) {
      newProducts.push({
        label: 'VPS Standard',
        value: 'VPS Standard',
      });
    }

    const higherThanOptimized = [HgProducts.VPS_OPTIMIZED, HgProducts.VPS_PLATINUM].includes(selectedProduct.pid);
    if (!higherThanOptimized) {
      newProducts.push({
        label: 'VPS Optimized',
        value: 'VPS Optimized',
      });
    }

    const higherThanPlatinum = [HgProducts.VPS_PLATINUM].includes(selectedProduct.pid);
    if (!higherThanPlatinum) {
      newProducts.push({
        label: 'VPS Platinum',
        value: 'VPS Platinum',
      });
    }

    const higherThanPremium = [HgProducts.VPS_PREMIUM].includes(selectedProduct.pid);
    if (!higherThanPremium) {
      newProducts.push({
        label: 'VPS Premium',
        value: 'VPS Premium',
      });
    }

    return newProducts;
  }, [selectedProduct]);

  const executOptions = [{
    label: 'Sim',
    value: 'Sim',
  }, {
    label: 'Não',
    value: 'Não',
  }];

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.upgradeVps,
      key: V2Forms.UPGRADE_VPS,
      tags: ['whmcs_form_financeiro_upgrade-downgrade_plano'],
      body: isBRServer ? `
        ${upgradeVpsLocale.body.product(selectedProduct)}
        ${upgradeVpsLocale.body.request}
        ${upgradeVpsLocale.body.serverIp(serverIp)}
        ${upgradeVpsLocale.body.newProduct(newProduct)}
        ${upgradeVpsLocale.body.execution(execution)}
        ${upgradeVpsLocale.body.agreedTerms(agreedTerms)}
        ${upgradeVpsLocale.body.agreedResponsability(agreedResponsability)}
        ${upgradeVpsLocale.body.productId(selectedProduct)}
      ` : `
      ${upgradeVpsLocale.body.product(selectedProduct)}
      ${upgradeVpsLocale.body.request}
      ${upgradeVpsLocale.body.newProduct(newProduct)}
      ${upgradeVpsLocale.body.agreedTerms(agreedTerms)}
      ${upgradeVpsLocale.body.productId(selectedProduct)}
    `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    submitForm,
    selectedProduct,
    upgradeVpsLocale,
    serverIp,
    newProduct,
    execution,
    agreedResponsability,
    agreedTerms,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      {isBRServer && (
        <Styles.Info>
          <IconData />
          {upgradeVpsLocale.info}
        </Styles.Info>
      )}


      <Styles.InputsWrapper>
        {isBRServer && (
        <Styles.FieldWrapper>
          <Input
            testId="serverIp"
            label={serverIp ? upgradeVpsLocale.fields.ipLabel : ''}
            placeholder={upgradeVpsLocale.fields.ipLabel}
            value={serverIp}
            onChange={e => setServerIp(e.target.value)}
            rows={3}
            counter
          />
          <Styles.FieldInfo>
            {upgradeVpsLocale.fields.ipInfo}
          </Styles.FieldInfo>
        </Styles.FieldWrapper>
        )}

        <Select
          testId="newProduct"
          placeholder={upgradeVpsLocale.fields.productLabel}
          label={newProduct ? upgradeVpsLocale.fields.productLabel : ''}
          options={getProductsOptions()}
          value={newProduct}
          setValue={label => setNewProduct(label)}
          withScroll
        />

        {isBRServer && (
          <Styles.FieldWrapper>
            <Select
              testId="execution"
              placeholder={upgradeVpsLocale.fields.executionLabel}
              label={execution ? upgradeVpsLocale.fields.executionLabel : ''}
              options={executOptions}
              value={execution}
              setValue={label => setExecution(label)}
              withScroll
            />
            <Styles.FieldInfo>
              {upgradeVpsLocale.fields.executionInfo}
            </Styles.FieldInfo>
          </Styles.FieldWrapper>
        )}

        <Styles.CheckboxLabel
          htmlFor="#agreedTerms"
          data-testid="agreedTerms"
          onClick={() => setAgreedTerms(!agreedTerms)}
        >
          <Checkbox checked={agreedTerms} id="checkAgreedTerms" />
          <Styles.Text>
            {upgradeVpsLocale.fields.agreedTermsLabel}
          </Styles.Text>
        </Styles.CheckboxLabel>

        {isBRServer && (
          <Styles.CheckboxLabel
            htmlFor="#agreedResponsability"
            data-testid="agreedResponsability"
            onClick={() => setAgreedResponsability(!agreedResponsability)}
          >
            <Checkbox checked={agreedResponsability} id="checkAgreedResponsability" />
            <Styles.Text>
              {upgradeVpsLocale.fields.agreedResponsabilityLabel}
            </Styles.Text>
          </Styles.CheckboxLabel>
        )}
      </Styles.InputsWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : upgradeVpsLocale.sendButtonLabel}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default UpgradeVps;
