import React from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import LinkButton from '@/components/Buttons/LinkButton';
import IconMailTitan from '@/media/icons/mailTitan.svg';
import styles from './styles';


const ModalFreeTitanActiveSuccess = ({
  onClose, open, classes, t, domain,
}) => (
  <Dialog
    aria-labelledby="responsive-dialog-title"
    open={open}
  >
    <div className={classes.activeDialog}>
      <div id="dialog-title" className={classes.btnClose}>
        <IconButton aria-label="Close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent style={{ textAlign: 'center' }}>
        <img src={IconMailTitan} alt={t('sitelock.alt')} className={classes.logo} />

        <Typography className={classes.titleDialog}>{t('proEmail.hirePlan.successActivateFreePlanTitle')}</Typography>
        <Typography className={classes.descriptionDialog}>
          {t('proEmail.hirePlan.successActivateFreePlanDescription')}
        </Typography>
        <LinkButton to={`${t('routes.emailsList')}${t('routes.createFirstEmailsTitan')}/${domain}`} onClick={onClose} className={classes.btActive} data-testid="linkButton">
          {t('proEmail.hirePlan.successActivateFreePlanAction')}
        </LinkButton>

      </DialogContent>
    </div>
  </Dialog>
);

export default withI18n()(withStyles(styles)(ModalFreeTitanActiveSuccess));
