import React, { useContext } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import { DNSContext } from '@/contexts/DNS/context';
import styles from './styles';

const RadioBlock = ({ classes, t, nameServer }) => {
  const { details } = useContext(DNSContext);

  return (
    <>
      <div className={classes.header} data-testid="radioBlock">
        <div className={classes.radioWrapper}>
          <RadioGroup className={classes.group} value={details[nameServer] ? nameServer : ''}>
            <FormControlLabel
              value={nameServer}
              control={<Radio className={classes.radio} color="primary" id={`${nameServer}NameServerRadio`} data-testid="nameServerRadio" />}
            />
          </RadioGroup>
        </div>
        <h2 className={classes.title}>{t(`dnsSetup.${nameServer}.title`)}</h2>
      </div>
      <p className={classes.description}>{t(`dnsSetup.${nameServer}.description`)}</p>
    </>
  );
};

RadioBlock.propTypes = {
  nameServer: PropTypes.string.isRequired,
};

export default withI18n()(withStyles(styles)((RadioBlock)));
