import styled, { css } from 'styled-components';

export const SelectButtonWrapper = styled.div`
  cursor: pointer;
  width: 100%;
`;

export const SelectButtonLabel = styled.label`
  ${({
    align,
    justify,
    theme,
    variant,
  }) => {
    const variants = {
      default: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.v1.color.line.wizard}`,
        svgMargin: '0px 24px 0px 0px',
      },
    };

    return css`
      align-items: ${align};
      background-color: ${variants[variant].backgroundColor};
      border-radius: 4px;
      border: ${variants[variant].border};
      cursor: pointer;
      display: flex;
      justify-content: ${justify};
      padding: 16px 24px;
      transition: background-color 150ms ease-in-out, border 150ms ease-out;

      svg {
        margin: ${variants[variant].svgMargin}
      }
    `;
  }}
`;

export const SelectInput = styled.input`
  ${({
    theme,
    variant,
  }) => {
    const variants = {
      default: {
        checkedBackgroundColor: theme.v1.color.background.boxes,
        checkedBorder: `1px solid ${theme.v1.color.line.fields}`,
      },
    };
    return css`
    display: none;

    &:checked + label {
      background-color: ${variants[variant].checkedBackgroundColor};
      border: ${variants[variant].checkedBorder};
    }
    `;
  }}
`;

export const Title = styled.span`
  ${({
    theme,
    variant,
    truncate,
    truncateMaxWidth,
  }) => {
    const variants = {
      default: {
        color: theme.v1.color.text.primary,
        weight: theme.v1.font.weights.medium,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
      },
    };
    return css`
      color: ${variants[variant].color};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${variants[variant].fontSize};
      font-weight: ${variants[variant].weight};
      letter-spacing: ${variants[variant].letterSpacing};
      line-height: ${variants[variant].lineHeight};
      max-width:${truncate && truncateMaxWidth};
      overflow: ${truncate && 'hidden'};
      text-overflow: ${truncate && 'ellipsis'};
      white-space: ${truncate && 'nowrap'};
    `;
  }}
`;

export const Subtitle = styled.span`
${({
    theme,
    variant,
  }) => {
    const variants = {
      default: {
        color: theme.v1.color.text.primary,
        weight: theme.v1.font.weights.regular,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
      },
    };
    return css`
      color: ${variants[variant].color};
      font-family: ${theme.v1.font.family.primary};
      font-size: ${variants[variant].fontSize};
      font-weight: ${variants[variant].weight};
      letter-spacing: ${variants[variant].letterSpacing};
      line-height: ${variants[variant].lineHeight};
  `;
  }}
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span``;
