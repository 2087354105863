import React, { useCallback, useEffect, useState } from 'react';
import { Button, IconDanger, Select } from 'gatorcomponents';
import * as Styles from './ModalDomainDnsPropagationAlert.styles';
import { useSelector } from 'react-redux';
import useLocale from '@/hooks/useLocale';
import { withI18n } from 'react-i18next';
import { PROPAGATION_STATUS } from '@/enums/domains/domains.enum';
import { Modal } from '@/pages/common';
import { useHistory } from 'react-router';
import analytics from '@/analytics/domains/domains.analytics';

const ModalDomainDnsPropagationAlert = ({ hideModal, setMyAlertsVisible }) => {
  const { alerts: alertsLocale, routes: routesLocale } = useLocale();
  const history = useHistory();
  const domainsNotPropagated = useSelector(state => state.newDomains.propagationStatus && state.newDomains.propagationStatus.filter(propagation => propagation.status === PROPAGATION_STATUS.NOT_PROPAGATED));
  const [selectedDomainId, setSelectedDomainId] = useState('');
  const [selectedDomainLabel, setSelectedDomainLabel] = useState('');

  const selectOptions = domainsNotPropagated.map(domainNotPropagated => ({ value: domainNotPropagated.id, label: domainNotPropagated.domain }));

  const setDefaultDomain = useCallback(() => {
    if (selectOptions && selectOptions.length && selectOptions.length === 1) {
      setSelectedDomainId(selectOptions[0].value);
      setSelectedDomainLabel(selectOptions[0].label);
    }
  }, [selectOptions]);

  useEffect(() => {
    setDefaultDomain();
  }, [setDefaultDomain, selectOptions]);

  const handleHideModal = () => {
    hideModal && hideModal();
  };

  const handleSelectOption = (option) => {
    setSelectedDomainId(option.value);
  };

  const handleNavigation = () => {
    analytics.dnsWizard.dnsUnpointedDomainConfigureDomain();
    const routeToDnsWizard = selectedDomainId && `${routesLocale && routesLocale.domains}${routesLocale && routesLocale.dnsWizard}/${selectedDomainId}`;
    routeToDnsWizard && history.push(routeToDnsWizard);
    handleHideModal();
    setMyAlertsVisible && setMyAlertsVisible(false);
  };

  return (
    <Modal
      maxWidth={625}
      onClose={handleHideModal}
      testId="select-domain-to-configure-modal"
    >
      <Styles.Content>
        <Styles.Title data-testid="select-domain-to-configure-modal-title">
          {alertsLocale.whichDomain}
        </Styles.Title>
        <Styles.Info>
          <IconDanger color="problemPure" size="lg" />
          <Styles.InfoText
            data-testid="select-domain-to-configure-modal-text"
          >
            {alertsLocale.selectDomainToConfigure}
          </Styles.InfoText>
        </Styles.Info>
        <Styles.SelectWrapper>
          <Styles.Select>
            <Select
              placeholder={alertsLocale.selectPlaceHolder}
              options={selectOptions}
              size="md"
              onClickSelect={handleSelectOption}
              value={selectedDomainLabel}
              setValue={setSelectedDomainLabel}
            />
          </Styles.Select>
        </Styles.SelectWrapper>
        <Styles.Actions>
          <Styles.ButtonWrapper>
            <Button
              testId="select-domain-to-configure-modal-button"
              label={alertsLocale.configureDomain}
              onClick={handleNavigation}
              disabled={!selectedDomainId}
              size="large"
            />
          </Styles.ButtonWrapper>
        </Styles.Actions>
      </Styles.Content>
    </Modal>
  );
};

export default withI18n()((ModalDomainDnsPropagationAlert));
