import React from 'react';
import * as Styles from './DnsWizardConfigCardReserved.styles';
import hostGatorLogo from '@/media/logo/HalfSnappyLogo/HalfSnappyLogo.svg';
import { IconEdit } from '@/icons';
import { Alert, Button, Skeleton } from 'gatorcomponents';
import { isMobile } from '@/utils/Validators/validation';

const DnsWizardConfigCardReserved = ({
  changePlatformText = '',
  editAdvancedDNSZone = '',
  nameServers = [],
  serverText,
  noPlatformMessage,
  testId = 'dns-wizard-config-card',
  changePlatformClick,
  handleAdvanceDNSZoneModalShow,
  editPlanClick,
  noPlanSelectedMessage = '',
  shouldRenderChangePlanButton = false,
  shouldRenderAdvancedDNSZoneButton,
  loading,
}) => (
  <Styles.DnsWizardPlanCardWrapper data-testid={testId} isToggleOn>
    <Styles.Header isToggleOn>
      <Styles.LogoWrapper isToggleOn>
        <Styles.Logo
          src={hostGatorLogo}
          loading="lazy"
        />
      </Styles.LogoWrapper>

      {!isMobile && (
        <Styles.Actions>
          <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
          {shouldRenderAdvancedDNSZoneButton && <Button label={editAdvancedDNSZone} variant="tertiary" size="large" onClick={handleAdvanceDNSZoneModalShow} testId="advanced-dns-button" />}
        </Styles.Actions>
      )}

    </Styles.Header>
    <Styles.Body>
      <Styles.Product data-testid="no-selected-plan">
        <p>
          {noPlanSelectedMessage}
        </p>
        <span />
        {shouldRenderChangePlanButton
            && (
            <Styles.Edit onClick={editPlanClick} data-testid="change-plan-pencil">
              <IconEdit />
            </Styles.Edit>
            )
            }
      </Styles.Product>
      {nameServers && nameServers.map((nameServer, index) => (
        <Styles.NameserversWrapper>
          <Styles.Nameservers key={nameServer}>
            {`${serverText} ${index + 1}: `}
            {!loading && <span>{nameServer}</span>}
          </Styles.Nameservers>
          {loading && (
          <Styles.SkeletonWrapper>
            <Skeleton variant="rectangle" width="200px" height="20px" />
          </Styles.SkeletonWrapper>
          )}
        </Styles.NameserversWrapper>
      ))}
    </Styles.Body>
    <Styles.Footer data-testid="attention-message">
      <Alert description={noPlatformMessage} variant="warning" />
    </Styles.Footer>
    {isMobile && (
      <Styles.Actions>
        <Button label={changePlatformText} variant="tertiary" size="large" onClick={changePlatformClick} testId="platform-button" />
        {shouldRenderAdvancedDNSZoneButton && <Button label={editAdvancedDNSZone} variant="tertiary" size="large" onClick={handleAdvanceDNSZoneModalShow} testId="advanced-dns-button" />}
      </Styles.Actions>
    )}

  </Styles.DnsWizardPlanCardWrapper>
);

export default DnsWizardConfigCardReserved;
