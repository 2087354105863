import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  ${() => css`
    display: flex;
    position:relative;
    white-space: nowrap;

    button {
      align-items: center;
    }
  `}
`;

export const Button = styled.button`
  ${({
    theme,
    variant,
  }) => {
    const variants = {
      disabled: {
        backgroundColor: theme.palette.grey.primary,
        borderColor: theme.palette.grey.primary,
        color: theme.palette.grey.ultralight,
        font: theme.font.family.primary,
        fontSize: theme.font.sizes.sm,
        fontWeight: 400,
        hoverBackgroundColor: theme.palette.grey.primary,
        hoverBorderColor: theme.palette.grey.primary,
        hoverColor: theme.palette.grey.ultralight,
        justifyAlign: 'flex-start',
      },
      text: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: theme.palette.primary.main,
        font: theme.font.family.primary,
        fontSize: theme.font.sizes.sm,
        fontWeight: 400,
        hoverBackgroundColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.primary.dark,
        justifyAlign: 'center',
        lineHeight: '21px',
      },
      textHover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: theme.palette.primary.main,
        font: theme.font.family.primary,
        fontSize: theme.font.sizes.xxs,
        fontWeight: 600,
        hoverBackgroundColor: theme.palette.primary.contrastText,
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.primary.dark,
        justifyAlign: 'flex-start',
        lineHeight: '19px',
      },
      secondary: {
        backgroundColor: theme.palette.white.light,
        borderColor: 'transparent',
        color: theme.palette.secondary.primary,
        font: theme.font.family.secondary,
        fontSize: theme.font.sizes.sm,
        fontWeight: 400,
        hoverBackgroundColor: theme.palette.secondary.primary,
        hoverBorderColor: 'transparent',
        hoverColor: theme.palette.white.light,
        justifyAlign: 'flex-start',
      },
    };

    return css`
      align-items: center;
      background-color: ${variants[variant].backgroundColor};
      border-radius: 2px;
      border: 1px solid ${variants[variant].borderColor};
      color: ${variants[variant].color};
      cursor: pointer;
      display: flex;
      font-family:${variants[variant].font};
      font-size: ${variants[variant].fontSize};
      font-weight: ${variants[variant].fontWeight};
      justify-content: ${variants[variant].justifyAlign};;
      letter-spacing: 0.01px;
      line-height:${variants[variant].lineHeight};
      padding: 8px 16px;
      transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
      width: 100%;

      span{
        margin-right: 8px;
      }

      svg{
        transition: fill 200ms ease-in-out, transform 200ms ease-in-out;
      }

      &:hover {
        background-color: ${variants[variant].hoverBackgroundColor};
        border: 1px solid ${variants[variant].hoverBorderColor};
        color: ${variants[variant].hoverColor};

        svg {
          fill: ${variants[variant].hoverColor};
        }
      }

      &.disabled {
        background-color: ${variants.disabled.backgroundColor};
        border: 1px solid ${variants.disabled.borderColor};
        color: ${variants.disabled.color};

        &:hover {
          background-color: ${variants.disabled.hoverBackgroundColor};
          border: 1px solid ${variants.disabled.hoverBorderColor};
          color: ${variants.disabled.hoverColor};
        }
      }
    `;
  }}
`;

export const Ellipsis = styled.span`
  ${({ fill }) => css`
    display: flex;
    svg {
      fill: ${fill};
    }
  `}
`;

export const DropdowIconWrapper = styled.div`
  ${({ displayed }) => css`
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      margin-left: 3px;
      transform: rotate(${displayed ? '0deg' : '180deg'});
      transition: transform 200ms ease;
    }
  `}
`;

export const ItemsList = styled.div`
  ${({
    theme, displayed, isEllipsisDropdown, variant,
  }) => {
    const variants = {
      default: {
        padding: '8px 0px',
      },
      secondary: {
        padding: '0',
      },
    };

    return css`
      background-color: ${theme.palette.white.light};
      box-shadow: 3px 4px 12px ${theme.shadows.grey.light};
      display: flex;
      flex-direction: column;
      font-family:${variants[variant].font};
      opacity: ${displayed ? '1' : '0'};
      padding: ${variants[variant].padding};
      position: absolute;
      right: ${isEllipsisDropdown ? '-16px' : 'unset'};
      top: ${isEllipsisDropdown ? '30px' : '48px'};
      transition: opacity 200ms ease-in-out;
      visibility: ${displayed ? 'visible' : 'hidden'};
      width: ${isEllipsisDropdown ? 'auto' : '100%'};
      z-index: ${displayed ? '100' : '-1'};
    `;
  }}
`;
