export const TYPES = {
  visa: {
    maxLength: 16,
    minLength: 16,
    codeName: 'CVV',
    codeSize: 4,
  },
  mastercard: {
    maxLength: 16,
    minLength: 16,
    codeName: 'CVC',
    codeSize: 4,
  },
  amex: {
    maxLength: 16,
    minLength: 15,
    codeName: 'CID',
    codeSize: 4,
  },
  elo: {
    maxLength: 16,
    minLength: 16,
    codeName: 'CVE',
    codeSize: 4,
  },
};

export const ACCEPTED = {
  pt: ['visa', 'mastercard', 'elo'],
  es: ['visa', 'mastercard', 'amex'],
  cl: ['visa', 'mastercard', 'amex'],
  co: ['visa', 'mastercard', 'amex'],
  mx: ['visa', 'mastercard', 'amex'],
};

const CREDIT_CARD = {
  TYPES,
  ACCEPTED,
};

export default CREDIT_CARD;
