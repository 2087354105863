import {
  Button, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, withI18n } from 'react-i18next';
import classnames from 'classnames';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { Warning } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { formatDate } from '@/utils/Formatters/formatDate';
import IconTitan from '@/media/icons/logoTitan.svg';
import IconCpanel from '@/media/icons/logoCpanel.svg';
import LinkButton from '@/components/Buttons/LinkButton';
import ModalReactivateAutomaticSubscription from '@/components/Modal/ModalReactivateAutomaticSubscription';
import { emailsActions } from '@/redux/modules/emails';
import { isBRServer, isMobile } from '@/utils/Validators/validation';
import { TRIAL_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { HOSPEDAGEM_COLABORADORES } from '@/components/Products/HgProducts';

import styles from './styles';

const ProEmailPlan = ({
  classes, proEmail, t, almostOverdue, almostExcluded, isAutomaticRenovationDisabled, daysTrial,
}) => {
  const dispatch = useDispatch();
  const isTrialPlan = proEmail.billingcycle === 'Free Account' && proEmail.packageid === TRIAL_PLAN;
  const reactivateCPanelEnabled = true;
  const [modalReactivation, setModalReactivation] = useState(false);
  const [isAutomaticRenovation, setIsAutomaticRenovation] = useState(isAutomaticRenovationDisabled);
  const daysToDueDate = 10;
  const diferenceBetweenDays = differenceInCalendarDays(parseISO(proEmail.nextduedate), new Date());
  const isToDueDateNotTrial = proEmail.nextduedate != null ? diferenceBetweenDays <= daysToDueDate && ['active', 'suspended'].includes(proEmail.status) : false;
  const maxDaysToNextDueDateTrial = 10;
  const minDaysToNextDueDateTrial = 0;
  const minDaysToOverDueDateTrial = -10;

  const isNextDueDate = isTrialPlan ? daysTrial <= maxDaysToNextDueDateTrial && daysTrial > minDaysToNextDueDateTrial && proEmail.status !== 'terminated' : isToDueDateNotTrial;
  const toleranceDays = isBRServer ? 11 : 8;
  const suspendedToleranceDays = isBRServer ? 20 : 23;
  const planStatusWithReactiveCpanel = proEmail.plan ? t(`proEmail.${proEmail.status}CardStatusNew`) : t('proEmail.noPlan');
  const planStatusWithOutReactiveCpanel = proEmail.plan ? t(`proEmail.${proEmail.status}CardStatusNew`) : t('proEmail.noPlan');
  const planStatusMessage = reactivateCPanelEnabled ? planStatusWithReactiveCpanel : planStatusWithOutReactiveCpanel;
  const isCPanelFreePending = proEmail.type === 'cpanel' && proEmail.status === 'pending';
  const dateDiff = proEmail.nextduedate ? diferenceBetweenDays : 0;
  const daysToCalcateSuspedendDate = isBRServer ? 11 : 8;
  const displayCardInfoTrialActive = daysTrial > maxDaysToNextDueDateTrial && proEmail.status === 'active' && isTrialPlan;


  const isAlmostExcluded = almostOverdue && !almostExcluded && !isTrialPlan;
  const displayDueDate = proEmail.packageid !== HOSPEDAGEM_COLABORADORES && !isTrialPlan && proEmail.status !== 'pending' && proEmail.status !== 'suspended' && !almostOverdue && !almostExcluded;
  const displayTrialInfoStatusActive = isTrialPlan && daysTrial <= maxDaysToNextDueDateTrial && daysTrial > minDaysToNextDueDateTrial && proEmail.status !== 'terminated';
  const displayTrialInfoStatusOverDueDate = isTrialPlan && daysTrial <= minDaysToNextDueDateTrial && daysTrial >= minDaysToOverDueDateTrial && proEmail.status !== 'terminated';
  const displayBillButton = isNextDueDate || proEmail.status === 'expired' || proEmail.status === 'suspended' || almostOverdue;

  const mountPlanStatusMessage = () => {
    let message = planStatusMessage;
    const isTrial = proEmail.packageid === TRIAL_PLAN;

    if (!isTrial && proEmail.status.toLowerCase() === 'provisioning') {
      message = t('proEmail.pendingCardStatusNew');
    }

    if (isTrial && proEmail.status.toLowerCase() === 'pending') {
      message = t('proEmail.provisioningCardStatusNew');
    }

    return message;
  };


  const planStatusInfo = () => {
    let planStatusBase = '';
    const isTrial = proEmail.packageid === TRIAL_PLAN;

    if (proEmail.plan && proEmail.status !== 'active') {
      if (isTrial && (proEmail.status.toLowerCase() === 'pending' || proEmail.status.toLowerCase() === 'provisioning')) {
        planStatusBase = t('proEmail.provisioningCardStatusInfo');
      } else if (!isTrial && (proEmail.status.toLowerCase() === 'pending' || proEmail.status.toLowerCase() === 'provisioning')) {
        planStatusBase = t('proEmail.pendingCardStatusInfo');
      } else {
        planStatusBase = t(`proEmail.${proEmail.status}CardStatusInfo`);
      }
    }

    if (isNextDueDate) {
      return isTrialPlan ? t('proEmail.uponDueDateTrialCardStatusInfo') : t('proEmail.uponDueDateCardStatusInfo');
    }
    return planStatusBase;
  };

  const showPlanStatus = (plan) => {
    if ((proEmail.plan === 'free' && proEmail.hosting_plan) || proEmail.type === 'cpanel') {
      return proEmail.hosting_plan;
    }
    return plan ? proEmail.plan : ` - ${t('proEmail.type.titan')}`;
  };


  const produtctName = showPlanStatus(proEmail.plan);
  const handleOpen = async () => {
    setModalReactivation(true);
  };

  const callBackFunction = () => {
    setIsAutomaticRenovation(true);
  };

  const handleActivateSubscription = () => {
    const serviceType = 'hosting';
    const email = {
      type: serviceType,
      id: proEmail.hosting_id,
      productname: `${produtctName} - ${proEmail.domain}`,
    };
    dispatch(emailsActions.emails.activateRenewal.request(email, callBackFunction));
  };

  const toolTipBillButton = () => {
    if (isNextDueDate && !almostOverdue) {
      return t('proEmail.uponDueDateCardStatusInfo');
    }
    if (isTrialPlan && almostOverdue) {
      return t('proEmail.suspendedCardStatusInfo');
    }
    if (almostExcluded) {
      return t('proEmail.suspendedCardStatusInfo');
    }
    return t('proEmail.expiredCardStatusInfo');
  };


  return (
    <>
      <ModalReactivateAutomaticSubscription
        onClose={() => setModalReactivation(false)}
        open={modalReactivation}
        handleActivateSubscription={handleActivateSubscription}
        classes={classes}
        domain="domain"
      />
      {!isMobile && (
      <div className={classes.divFirstLine}>
        <Typography className={classes.simpleTitle} data-testid="subscriptionsLabel">
          {t('proEmail.subscriptionsLabel')}
        </Typography>
        <Typography className={classes.proEmaiPlan} data-testid="cardPlan">
          {showPlanStatus('plan')}
        </Typography>


          {!isNextDueDate && !almostOverdue && (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              popper: classes.customPopper,
            }}
            placement="top"
            title={proEmail.status === 'active' && !isNextDueDate ? '' : planStatusInfo()}
          >
            <div
              className={classnames(
                classes.proEmailStatus,
                classes[`${proEmail.plan !== null ? proEmail.status.toLowerCase() : 'null'}ProEmailStatusNew`],
              )}
              data-testid="cardStatus"
            >
              {mountPlanStatusMessage()}
            </div>
          </Tooltip>
          )}
          {isNextDueDate && !almostOverdue && (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              popper: classes.customPopper,
            }}
            placement="top"
            title={planStatusInfo()}
          >
            <div
              className={classnames(
                classes.proEmailStatus,
                classes.uponDueDateProEmailStatus,
              )}
              data-testid="cardStatus"
            >
              {t('proEmail.toDueDateCardStatus')}
            </div>
          </Tooltip>
          )}
          {(almostOverdue || almostExcluded) && (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              popper: classes.customPopper,
            }}
            placement="top"
            title={almostExcluded && !isTrialPlan ? t('proEmail.suspendedCardStatusInfo') : t('proEmail.expiredCardStatusInfo')}
          >
            <div
              data-testid="cardStatus"
              className={classnames(
                classes.proEmailStatus,
                classes.almostCanceladCardStatus,
              )}
            >
              {almostExcluded ? t('proEmail.suspendedCardStatusNew') : t('proEmail.expiredCardStatusNew')}
            </div>
          </Tooltip>

          )}
        {isAlmostExcluded && (
        <span className={classnames(classes.almostCanceladDiv)} data-testid="cardStatusInfo">
          <div className={classes.divWarningIcon}>
            <Warning className={classes.warningIcon} />
          </div>
          <Trans i18nKey="proEmail.almostExcludedCardStatus" values={{ amount: toleranceDays + dateDiff }}>
            <b>0</b>
            <>1</>
            <>2</>
          </Trans>
        </span>
        ) }

        { displayDueDate && (
          <>
            <div className={classes.divDueDate}>
              {proEmail.status !== null && proEmail.status !== 'terminated' && (
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  popper: classes.customPopper,
                }}
                placement="top"
                title={!isAutomaticRenovation && proEmail.status === 'active' && !isNextDueDate ? t('proEmail.infoAutomaticRenovation') : ''}
              >
                <div
                  className={classnames(classes.proEmailItem, classes.proEmailExpiringDate)}
                  data-testid="proEmailDueDateBlock"
                >

                  <Typography className={classes.dueDate}>
                    {!isAutomaticRenovation && proEmail.status === 'active' && !isNextDueDate && (
                    <div className={classes.divWarningIcon}>
                      <Warning className={classes.warningIcon} />
                    </div>
                    )}
                    {`${t('proEmail.dueDate')}: ${proEmail.nextduedate === '0000-00-00' ? '-' : formatDate(proEmail.nextduedate)}`}
                  </Typography>
                </div>
              </Tooltip>
              )}
              {proEmail.status === 'terminated' && (
              <div
                className={classnames(classes.proEmailItem, classes.proEmailExpiringDate)}
                data-testid="proEmailTerminatedDateBlock"
              >
                <Typography className={classes.terminationDate}>
                  {`${t('proEmail.terminatedDate')}: ${proEmail.termination_date === '0000-00-00' ? '-' : formatDate(proEmail.termination_date)}`}
                </Typography>
              </div>
              )}

            </div>
          </>
        )}
        { (proEmail.status === 'suspended' || almostExcluded) && (
        <div
          className={classnames(classes.suspendedDate)}
          data-testid="proEmailSuspendedDateBlock"
        >
          <div className={classes.divWarningIcon}>
            <Warning className={classes.warningIcon} />
          </div>
          <Trans i18nKey="proEmail.almostCanceladCardStatus">
            <b>0</b>
            <>{{ amount: suspendedToleranceDays + daysToCalcateSuspedendDate + dateDiff }}</>
            <>2</>
          </Trans>
        </div>
        )}

        { displayCardInfoTrialActive && (
          <div
            className={classnames(classes.trialDiv)}
            data-testid="proEmailTrialActive"
          >
            <Trans i18nKey="proEmail.trialCardStatus" values={{ amount: daysTrial }}>
              <>0</>
              <>1</>
            </Trans>
          </div>
        )}

        { displayTrialInfoStatusActive && (
          <div
            className={classnames(classes.trialDivToDueDate)}
            data-testid="proEmailTrialToDueDate"
          >
            <div className={classes.divWarningIcon}>
              <Warning className={classes.warningIconTrialDueDate} />
            </div>
            <Trans i18nKey="proEmail.trialCardStatusToDueDate">
              <>{{ amount: daysTrial }}</>
              <>1</>
            </Trans>
          </div>
        )}

        {displayTrialInfoStatusOverDueDate && (
          <div
            className={classnames(classes.trialDivOverDueDate)}
            data-testid="proEmailTrialOverDueDate"
          >
            <div className={classes.divWarningIcon}>
              <Warning className={classes.warningIcon} />
            </div>
            <Trans i18nKey="proEmail.trialCardStatusOverDueDate" values={{ amount: daysTrial + toleranceDays - 1 }}>
              <>0</>
              <>1</>
            </Trans>
          </div>
        )}
        { isTrialPlan && proEmail.status === 'terminated' && (
          <div
            className={classnames(classes.trialDivTerminated)}
            data-testid="proEmailTrialTerminated"
          >
            <Typography className={classes.trialTerminationDate}>
              {t('proEmail.trialCardStatusTerminated')}
            </Typography>
          </div>
        )}


        { proEmail.status !== 'pending' && (
        <>
          {displayBillButton && (
            <div className={classes.divBillButton}>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  popper: classes.customPopper,
                }}
                placement="top"
                title={toolTipBillButton()}
              >
                <LinkButton
                  to={`${t('routes.billing')}${t('routes.unpaid')}`}
                  className={classes.billButton}
                  data-testid="proEmailBillingButton"
                >
                  {t('proEmail.billButton')}
                </LinkButton>
              </Tooltip>
            </div>
          )}
        </>
        )}
        {!isAutomaticRenovation && proEmail.status === 'active' && !isNextDueDate && (
        <Button
          onClick={handleOpen}
          className={classes.automaticReactivationButton}
          data-testid="automaticReactivationButton"
        >
          {t('proEmail.renovationButton')}
        </Button>
        )}

      </div>
      )}
      {isMobile && (
      <div className={classes.divSignatureMain}>
        <div className={classes.divSignature}>
          <div className={classes.divPlan}>
            <Typography className={classes.simpleTitle} data-testid="subscriptionsLabel">
              {t('proEmail.subscriptionsLabel')}
            </Typography>
            <Typography className={classes.proEmaiPlan} data-testid="cardPlan">
              {showPlanStatus('plan')}
            </Typography>
          </div>
          <div className={classes.divLogos}>
            {proEmail.configured && !isCPanelFreePending && (
              <>
                <div className={classes.imgLogo}>
                  <img src={proEmail.type === 'cpanel' ? IconCpanel : IconTitan} alt={t('sitelock.alt')} className={classes.logo} />
                </div>
                <div className={classes.divLogoTitle}>
                  <Typography className={classes.logoTitle}>
                    {proEmail.type === 'cpanel' ? t('proEmail.cPanelLabel') : t('proEmail.titanLabel')}
                  </Typography>
                </div>
              </>
            )}
            {!proEmail.configured && (
            <>
              <div className={classes.divEmptyIcon}>
              &nbsp;
              </div>
            </>
            )}
          </div>

        </div>
        {!isNextDueDate && !almostOverdue && (
        <div
          className={classnames(
            classes.proEmailStatus,
            classes[`${proEmail.plan !== null ? proEmail.status.toLowerCase() : 'null'}ProEmailStatusNew`],
            !isTrialPlan ? classes.trialDivMobile : '',
          )}
          data-testid="cardStatus"
        >
          {planStatusMessage}
        </div>
        )}

        {(almostOverdue || almostExcluded) && (
        <div
          className={classnames(
            classes.proEmailStatus,
            classes.almostCanceladCardStatus,
          )}
          data-testid="cardStatus"
        >
          {almostExcluded ? t('proEmail.suspendedCardStatusNew') : t('proEmail.expiredCardStatusNew')}
        </div>
        )}

          {isNextDueDate && !almostOverdue && (
          <div
            className={classnames(
              classes.proEmailStatus,
              classes.uponDueDateProEmailStatus,
            )}
            data-testid="cardStatus"
          >
            {t('proEmail.toDueDateCardStatus')}
          </div>
          )}

        {isAlmostExcluded && (
        <span
          className={classnames(
            classes.almostCanceladDiv,
          )}
          data-testid="cardStatusInfo"
        >
          <div className={classes.divWarningIcon}>
            <Warning className={classes.warningIcon} />
          </div>
          <Trans i18nKey="proEmail.almostExcludedCardStatus">
            <b>0</b>
            <>1</>
            <>2</>
          </Trans>
        </span>
        )}
        { (proEmail.status === 'suspended' || almostExcluded) && (
        <div className={classnames(classes.suspendedDate)} data-testid="proEmailSuspendedDateBlock">
          <div className={classes.divWarningIcon}>
            <Warning className={classes.warningIcon} />
          </div>
          <Trans i18nKey="proEmail.almostCanceladCardStatus">
            <b>0</b>
            <>{{ amount: suspendedToleranceDays + daysToCalcateSuspedendDate + dateDiff }}</>
            <>2</>
          </Trans>
        </div>
        )}

        {displayCardInfoTrialActive && (
        <div
          className={classnames(classes.trialDiv)}
          data-testid="proEmailTrialActive"
        >
          <Trans i18nKey="proEmail.trialCardStatus">
            <>{{ amount: daysTrial }}</>
            <>1</>
          </Trans>
        </div>
        )}

        { displayTrialInfoStatusActive && (
          <div
            className={classnames(classes.trialDivToDueDate)}
            data-testid="proEmailTrialToDueDate"
          >
            <div className={classes.divWarningIcon}>
              <Warning className={classes.warningIconTrialDueDate} />
            </div>
            <Trans i18nKey="proEmail.trialCardStatusToDueDate">
              <>{{ amount: daysTrial }}</>
              <>1</>
            </Trans>
          </div>
        )}

        {displayTrialInfoStatusOverDueDate && (
          <div
            className={classnames(classes.trialDivOverDueDate)}
            data-testid="proEmailTrialOverDueDate"
          >
            <div className={classes.divWarningIcon}>
              <Warning className={classes.warningIcon} />
            </div>
            <Trans i18nKey="proEmail.trialCardStatusOverDueDate" values={{ amount: daysTrial + toleranceDays - 1 }}>
              <>0</>
              <>1</>
            </Trans>
          </div>
        )}
        { isTrialPlan && proEmail.status === 'terminated' && (
          <div
            className={classnames(classes.trialDivTerminated)}
            data-testid="proEmailTrialTerminated"
          >
            <Typography className={classes.trialTerminationDate}>
              {t('proEmail.trialCardStatusTerminated')}
            </Typography>
          </div>
        )}
        { displayDueDate && (
        <div className={classes.divDueDate}>
          {proEmail.status !== null && proEmail.status !== 'terminated' && (
          <div className={classnames(classes.proEmailItem, classes.proEmailExpiringDate)} data-testid="proEmailDueDateBlock">
            <Typography className={classes.dueDate}>
              {!isAutomaticRenovation && (
              <div className={classes.divWarningIcon}>
                <Warning className={classes.warningIcon} />
              </div>
              )}
              {`${t('proEmail.dueDate')}: ${proEmail.nextduedate === '0000-00-00' ? '-' : formatDate(proEmail.nextduedate)}`}
            </Typography>
          </div>
          )}
          {proEmail.status === 'terminated' && (
          <div className={classnames(classes.proEmailItem, classes.proEmailExpiringDate)} data-testid="proEmailTerminatedDateBlock">
            <Typography className={classes.terminationDate}>
              {`${t('proEmail.terminatedDate')}: ${proEmail.termination_date === '0000-00-00' ? '-' : formatDate(proEmail.termination_date)}`}
            </Typography>
          </div>
          )}
        </div>
        )}

        {(isNextDueDate || proEmail.status === 'expired' || proEmail.status === 'suspended' || almostOverdue) && (
        <div className={classnames(classes.divBillButtonTrial, classes.divBillButton)}>
          <LinkButton to={`${t('routes.billing')}${t('routes.unpaid')}`} className={classes.billButton} data-testid="proEmailBillingButton">
            {t('proEmail.billButton')}
          </LinkButton>
        </div>

        )}

        {!isAutomaticRenovation && proEmail.status === 'active' && !isNextDueDate && (
        <div className={classes.divBillButton}>
          <Button
            onClick={handleOpen}
            className={classes.automaticReactivationButton}
            data-testid="automaticReactivationButton"
          >
            {t('proEmail.renovationButton')}
          </Button>
        </div>
        )}

      </div>
      )}
    </>
  );
};

ProEmailPlan.propTypes = {
  proEmail: PropTypes.object.isRequired,
};

export default withI18n()(withStyles(styles)(ProEmailPlan));
