const styles = theme => ({
  cardsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '16px 24px',

    [theme.breakpoints.down(1280)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down(960)]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '1fr',
    },
  },

  emptyCard: {
    backgroundColor: theme.color.white,
    boxShadow: `0 3px 6px ${theme.color.tundoraLight}`,
    width: 'calc(100% - 22px)',
    margin: '0 11px',
    padding: 24,
    textAlign: 'center',
  },

  emptyTitle: {
    color: theme.color.tundora,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '29px',
    margin: '0 auto 6px',
  },

  emptySubTitle: {
    color: theme.color.tundora,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19px',
    margin: '0 auto',
    maxWidth: 702,
  },

  card: {
    backgroundColor: theme.color.white,
    boxShadow: '0 3px 6px #00000029',
    display: 'flex',
    flex: '0 0 calc(25% - 22px)',
    flexDirection: 'column',
    height: '262px',
    justifyContent: 'space-between',
    margin: '0 11px 11px 11px',
    padding: '24px 24px 0 24px',
    borderRadius: '2px',

    [theme.breakpoints.down(1340)]: {
      flex: '0 0 calc(33.33% - 22px)',
    },

    [theme.breakpoints.down(1069)]: {
      flex: '0 0 calc(50% - 22px)',
    },

    [theme.breakpoints.down(519)]: {
      flex: '0 0 calc(100% - 22px)',
    },
  },

  outLineButtonClass: {
    alignItems: 'center',
    border: `1px solid ${theme.color.geyser}`,
    borderRadius: '2px',
    color: theme.color.indigo,
    display: 'flex',
    fontSize: '14dp',
    height: 35,
    justifyContent: 'center',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginBottom: 24,
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: '300ms',
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.color.indigo,
      border: `1px solid ${theme.palette.primary.dark}`,
      color: theme.color.white,
      opacity: '1.0',
    },
  },


  description: {
    color: theme.color.tundora,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
  },

  tagsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },

  cardTag: {
    color: theme.color.white,
    fontSize: '12px',
    height: '22px',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    margin: '0 3px',
  },

  featuredTag: {
    backgroundColor: theme.color.coral,
    border: `1px solid ${theme.color.coral}`,
  },

  logo: {
    margin: '0 auto',
    maxHeight: 46,
    maxWidth: '100%',
  },
});

export default styles;
