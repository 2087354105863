import { Typography, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Trans, withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import { MonetizationOn as CoinIcon } from '@material-ui/icons';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import styles from './styles';

const MemorialMessage = ({
  t,
  classes,
  domainObject,
  summaryCalcs,
  dateDiff,
  multiple,
  plan,
}) => {
  const mountedMessage = useMemo(() => {
    const monthly = domainObject.billingcycle.toLowerCase() === 'monthly';
    let message = '';
    let initialMultiple = 0;

    if (domainObject.plan === 'free') {
      initialMultiple = domainObject.used_accounts > 0 ? domainObject.used_accounts : 1;
    } else {
      initialMultiple = domainObject.total_accounts;
    }

    if (dateDiff === 0) {
      message = (
        <Trans
          i18nKey="proEmail.upgradePlan.upgradeMemorial.upgradeZeroDays"
          values={{
            amountUsed: formatCurrency(summaryCalcs.item.amount_used),
            remnantDays: dateDiff + 10,
            planName: domainObject.plan,
            newPlanName: plan.name,
            initialInboxAmount: initialMultiple,
            inboxAmount: multiple,
            cycleText: t(`proEmail.upgradePlan.upgradeMemorial.${monthly ? 'monthlyCycle' : 'annuallyCycle'}`),
            daysText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleDays' : 'multipleDays'}`),
            initialInboxText: t(`proEmail.upgradePlan.upgradeMemorial.${initialMultiple === 1 ? 'initialSingleInbox' : 'initialMultipleInbox'}`),
            inboxText: t(`proEmail.upgradePlan.upgradeMemorial.${multiple === 1 ? 'singleInbox' : 'multipleInbox'}`),
          }}
        >
          <>0</>
          <strong>1</strong>
          <>2</>
          <strong>3</strong>
          <>4</>
        </Trans>
      );
      return message;
    }

    if (dateDiff < 0) {
      message = (
        <Trans
          i18nKey="proEmail.upgradePlan.upgradeMemorial.upgradeNegativeDays"
          values={{
            amountUsed: formatCurrency(summaryCalcs.item.amount_used),
            remnantDays: dateDiff + 10,
            planName: domainObject.plan,
            newPlanName: plan.name,
            initialInboxAmount: initialMultiple,
            inboxAmount: multiple,
            cycleText: t(`proEmail.upgradePlan.upgradeMemorial.${monthly ? 'monthlyCycle' : 'annuallyCycle'}`),
            daysText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff + 10 === 1 ? 'singleDays' : 'multipleDays'}`),
            initialInboxText: t(`proEmail.upgradePlan.upgradeMemorial.${initialMultiple === 1 ? 'initialSingleInbox' : 'initialMultipleInbox'}`),
            inboxText: t(`proEmail.upgradePlan.upgradeMemorial.${multiple === 1 ? 'singleInbox' : 'multipleInbox'}`),
          }}
        >
          <>0</>
          <strong>1</strong>
          <>2</>
          <strong>3</strong>
          <>4</>
        </Trans>
      );
      return message;
    }

    message = (
      <Trans
        i18nKey="proEmail.upgradePlan.upgradeMemorial.upgradeDefault"
        values={{
          amountUsed: formatCurrency(summaryCalcs.item.amount_used),
          remnantDays: dateDiff,
          planName: domainObject.plan,
          newPlanName: plan.name,
          initialInboxAmount: initialMultiple,
          inboxAmount: multiple,
          refund: formatCurrency(summaryCalcs.deduction),
          remnantText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleRemnant' : 'multipleRemnant'}`),
          cycleText: t(`proEmail.upgradePlan.upgradeMemorial.${monthly ? 'monthlyCycle' : 'annuallyCycle'}`),
          daysText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleDays' : 'multipleDays'}`),
          initialInboxText: t(`proEmail.upgradePlan.upgradeMemorial.${initialMultiple === 1 ? 'initialSingleInbox' : 'initialMultipleInbox'}`),
          inboxText: t(`proEmail.upgradePlan.upgradeMemorial.${multiple === 1 ? 'singleInbox' : 'multipleInbox'}`),
          thisTexts: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleCapsThis' : 'multipleCapsThis'}`),
          equivalentText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleEquivalent' : 'multipleEquivalent'}`),
          remainingText: t(`proEmail.upgradePlan.upgradeMemorial.${dateDiff === 1 ? 'singleRemaining' : 'multipleRemaining'}`),
        }}
      >
        <>0</>
        <strong>1</strong>
        <>2</>
        <strong>3</strong>
      </Trans>
    );

    return message;
  }, [t, domainObject, summaryCalcs, dateDiff, plan.name, multiple]);


  return (
    <div className={classes.memorial}>
      <CoinIcon className={classes.coinSvg} />
      <Typography className={classes.memorialText}>
        {mountedMessage}
      </Typography>
    </div>
  );
};

MemorialMessage.propTypes = {
  multiple: PropTypes.number.isRequired,
  plan: PropTypes.object,
};

MemorialMessage.defaultProps = {
  plan: null,
};

export default withI18n()(withStyles(styles)(MemorialMessage));
