import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: 'Pesquisa em Sites',
};

const surveyStart = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: 'Iniciar pesquisa',
  gaEventLabel: '',
});

const surveyClose = (step) => {
  let eventAction = '';
  switch (step) {
    case 'start':
      eventAction = 'Botão "X" (início)';
      break;
    case 'end':
      eventAction = 'Botão "X" (final)';
      break;
    default:
      eventAction = `Botão "X" (pergunta ${step})`;
      break;
  }

  return collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY,
    gaEventAction: eventAction,
    gaEventLabel: '',
  });
};

const surveyFinish = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: 'Botão "Fechar"',
  gaEventLabel: '',
});

const surveyQuestionSend = question => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: `Enviar (pergunta ${question})`,
  gaEventLabel: '',
});

const surveyQuestionJump = question => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY,
  gaEventAction: `Pular (pergunta ${question})`,
  gaEventLabel: '',
});

const analytics = {
  surveyStart,
  surveyClose,
  surveyQuestionSend,
  surveyQuestionJump,
  surveyFinish,
};

export default analytics;
