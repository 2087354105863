import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.color.white};
    color: ${theme.color.white};
    display: flex;
    font-size: ${theme.font.sizes.md};
    justify-content: space-between;
    left: 0;
    height:60px;
    padding: 16px;
    z-index: 9999;
    @media (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      align-items: flex-start;
      height:auto;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.color.white};
    display: flex;
    flex: 1;
    gap: 24px;
    justify-content: center;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      gap: 2px;
      margin-bottom: 8px;
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
  color: ${theme.palette.grey.dark};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.md};
  font-weight: ${theme.font.weights.bold};
  margin-bottom:auto;
  margin-left:15px;
  margin-top:auto;
  letter-spacing: 0.01px;
    @media (max-width: ${theme.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const CloseButton = styled.button`
${({ theme }) => css`
  align-items: center;
  background-color: unset;
  border: unset;
  color: ${theme.color.gray};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  width: 24px;
  `}
`;

export const Link = styled.a`
${({ theme }) => css`
  color: ${theme.palette.secondary.primary};
  display: flex;
  flex-direction: column;
  font-weight: ${theme.font.weights.medium};
  text-decoration: none;

  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    letter-spacing: 0.01px;
    opacity: 1;
    text-align: center;
  `}
`;

export const DescriptionDomain = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.blue.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    line-height: 17px;
    margin-left: 3px;
    display:content
  `}
`;

export const Footer = styled.div`
  ${() => css`
    grid-row: 3;
    display: flex;
    flex-direction: column;
  `}
`;
export const DecoLine = styled.img`
  ${() => css`
  align-self: stretch;
  height:4px;
  object-fit:none;
 `}
`;

export const CTA = styled(Link)`
  ${({ theme, arrow, isBRServer }) => css`
  color: ${theme.palette.secondary.primary};
  cursor: pointer;
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.sizes.md};
  font-weight: ${theme.font.weights.medium};
  line-height: ${theme.font.sizes.xl};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    content: url(${arrow});
    font-size: ${theme.font.sizes.xxxxxxl};
    font-weight: 1000;
    opacity: 0;
    position: absolute;
    transform: translate(122px, -3px);
    transition: transform 0.1s, opacity 0.1s;
    @media (max-width: ${isBRServer ? '1276px' : '1167px'}) {
      transform: translate(30px, 22px);
    }
    @media (max-width: 991px) {
      transform: translate(80px, 22px);
    }
    @media (max-width: ${theme.breakpoints.md}) {
      transform:none;
      content:none;
    }
  }

  &:hover:after {
    opacity: 1;
    transform: translate(142px, -3px);
    @media (max-width: ${isBRServer ? '1276px' : '1167px'}) {
      transform: translate(40px, 22px);
    }
    @media (max-width: 991px) {
      transform: translate(90px, 22px);
    }
    @media (max-width: ${theme.breakpoints.md}) {
      transform:none;
      content:none;
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    display: block;
    text-decoration: underline;
  }

  `}
`;

export const ArrowIcon = styled.img`
${({ theme, arrow }) => css`
@media (max-width: ${theme.breakpoints.md}) {
    bottom: -2px;
    content: url(${arrow});
    position: relative;
    right:-2px;
    width:16px;
   }
  `}
`;


export const TitanIcon = styled.img`
  ${() => css`
  align-self: flex-end;
  height:28px;
  object-fit: contain;
  width: 34px;
  `}
`;

export const NewLabel = styled.div`
  ${({ theme, isBr }) => {
    const letterSpacing = isBr ? '0px' : '-1px';
    const transform = isBr ? 'rotate(-45deg) translate(-6px, 3px)' : 'rotate(-45deg) translate(-7px, 3px)';
    return css`

  background: ${theme.color.indigo};
  clip-path: polygon(0 0, 0 100%, 100% 0);
  height: 48px;
  left:4px;
  position: absolute;
  top:4px;
  width: 48px;
  & span {
    color: ${theme.color.white};
    display:block;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.bold};
    text-transform: uppercase;
    transform: ${transform};
    letter-spacing: ${letterSpacing};

  }
    `;
  }}
`;


export const ContentTitle = styled.div`
  display:flex;
`;

export const ContentDescription = styled.div`
  display:flex;
  @media (max-width: 1260px) {
    display:block;
  }
`;
