import styled, { css } from 'styled-components';

import { ArrowBack } from '@material-ui/icons';
import { TextField, Typography } from '@material-ui/core';

import { color } from '@/styles/HgThemeProvider';

import PasswordField from '@/components/Fields/PasswordField';
import PrimaryButton from '@/components/Buttons/PrimaryButton';

export const iconColor = color.oceanGreen;

export const WrapperContent = styled.div`
  max-width: 720px;
  padding: 40px 24px 0;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.grey.light};
    display: grid;
    gap: 16px;
    grid-template-columns: 24px 1fr;
    padding: 0 0 24px;
    margin-bottom: 20px;
  `}
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 24px;
`;

export const ArrowBackIcon = styled(ArrowBack)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`;

export const WrapperTextsHeader = styled.div``;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.01px;
    margin: 0 0 16px;
    word-wrap: anywhere;
  `}
`;

export const TagPeriod = styled.span`
  ${({ theme }) => css`
    background-color: ${`${theme.palette.grey.primary}66`};
    color: ${theme.palette.success.primary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.bold};
    letter-spacing: 0.01px;
    padding: 6px;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoints.sm}) {
      display: inline-block;
      font-size: 15px;
      margin-left: 0;
      margin-bottom: 16px;
    }
  `}
`;

export const Content = styled.div``;

export const DescriptionProduct = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.tundora};
    font-size: 16px/26px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 24px
  `}
`;


export const AdvantagesList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    gap: 60px;
    list-style: none;
    margin: 0;
    padding: 0 0 32px;

    li {
      align-items: center;
      color: ${theme.color.tundora};
      display: flex;
      font-size: 16px;
      gap: 8px;
      letter-spacing: 0.01px;
    }

    li + li {
      margin-top: 16px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      gap: 0;
      padding: 0 0 24px;

      div + div {
        margin-top: 16px;
      }
    }
  `}
`;


export const Form = styled.form``;

export const WrapperForm = styled.div`
  ${({ theme }) => css`
    background-color: #F5F9FC;
    padding: 40px 0 60px;


    .footer-text {
      padding: 0 60px;

      &__terms  {
        color: ${theme.color.tundora};
        font-size: 14px;
        letter-spacing: 0.01px;
      }

      &__link {
        color: ${theme.palette.primary.main};
        font-weight: bold;
      }
    }

    ${Form} + .footer-text {
      margin-top: 24px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 24px;

      .footer-text {
        padding: 0;

        &__terms  {
          text-align: center;
        }

        &__link {
          display: block;
          margin-top: 16px;
        }
      }
    }
  `}
`;

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;

  & + & {
    margin-top: 16px;
  }
`;

const overrideTextField = css`
  ${({ theme }) => css`
    background-color: #fff;
    color: ${theme.palette.grey.dark};
    height: 40px;
    margin: 0;
    width: 100%;

    /* .MuiOutlinedInput-input- { */
    #override-input-style {
      margin: 0;
      padding: 10px;
      width: 100%;
    }

    & fieldset {
      border-radius: 0;
      height: 45px;
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme, hideWhenDesktop }) => css`
    color: ${theme.palette.grey.dark};
    font-size: 14px;
    margin: 0 0 8px 8px;

    @media (min-width: ${theme.breakpoints.sm}) {
      ${hideWhenDesktop && css`
        opacity: 0;
      `}
    }
  `}
`;

export const PasswordFieldStyled = styled(PasswordField)`
  ${overrideTextField}
`;


export const TextFieldStyled = styled(TextField)`
    ${overrideTextField}
`;

export const Row = styled.div`
  ${({ theme, changeViewWhenMobile }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 120px;

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 0;

      ${changeViewWhenMobile && css`
        gap: 16px;
        grid-template-columns: 1fr;
      `}
    }
  `}
`;


export const ShowDomain = styled.div`
  ${({ theme }) => css`
    background-color: #EBEFF3;
    border: 1px solid #CDD8DF;
    color: ${theme.palette.grey.dark};
    height: 40px;
    letter-spacing: 0.01px;
    overflow: hidden;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;

    @media (max-width: ${theme.breakpoints.sm}) {
      max-width: 150px;
    }
  `}
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  ${({ theme }) => css`
    height: 40px;

    @media (min-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;

export const PasswordFieldWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    .error {
      bottom: -20px;
      color: ${theme.palette.error.main};
      font-size: 12px;
      left: 4px;
      position: absolute;
      width: 100%;
    }
  `}
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    margin-left: 16px;

    @media (max-width: ${theme.breakpoints.sm}) {
      justify-content: center;
      margin: 0;
    }
  `}
`;
