import React from 'react';
import { IconLight } from '@/icons';
import { Button } from '@/pages/common';
import * as Styles from './RefundTipBlock.styles';


const RefundTipBlock = ({
  display = false,
  testId = 'refund-tip-block',
  tipTitle = 'Dica:',
  tip = 'Por essa quantia é mais vantajoso utilizar a assinatura até o final do ciclo, em 01/09/2022.',
  stopCancelLabel = 'Desistir do Cancelamento',
  handleStopCancel,
}) => (
  display && (
    <Styles.Wrapper data-testid={testId}>
      <IconLight size={24} />

      <Styles.TipWrapper>
        <Styles.TipTitle>{tipTitle}</Styles.TipTitle>
        <Styles.TipContent>{tip}</Styles.TipContent>
        <Button variant="yellow" label={stopCancelLabel} onClick={handleStopCancel} testId="refund-stop-cancelation" />
      </Styles.TipWrapper>

    </Styles.Wrapper>
  )
);

export default RefundTipBlock;
