import React from 'react';
import ZoneManager from '@/components/Dns/ZoneManager';
import * as Styles from './DnsWizardLegacyDnsTable.styles';

function DnsWizardLegacyDnsTable({
  testId = 'dnswizardlegacydnstable',
}) {
  return (
    <Styles.DnsWizardLegacyDnsTableWrapper data-testid={testId}>
      <ZoneManager dnsTableOnly />
    </Styles.DnsWizardLegacyDnsTableWrapper>
  );
}

export default DnsWizardLegacyDnsTable;
