import React, { useState, useEffect } from 'react';
import {
  withStyles, Typography, Select, MenuItem, Input,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { buyAcademyPass } from '@/redux/actions/academyPass';
import { subscribeAcademyClick, paymentMethodChangeEvent } from '@/utils/ThirdParties/tagManager';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import {
  BRASPAG_PARC_1X, GATORPAY_BOLETO, GATORPAY_PAYPAL,
} from '@/config/billing/paymentMethods';
import { ACADEMY_PASS_PRODUCT_ID } from '@/config/products/academypass';
import styles from './styles';

const CtaPaymentOptions = ({ classes, t }) => {
  const HG_ACADEMY_PAYMENT_LOCATION_HEADER = 'header';

  const dispatch = useDispatch();
  const { price, paymentMethods } = useSelector(state => state.academyPass.cta);
  const [fastcheckout, setFastCheckout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const firstPayment = paymentMethods[0][0];
      setFastCheckout(firstPayment);
    }
  }, [paymentMethods]);

  const sortedPayments = [
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === GATORPAY_BOLETO)),
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === BRASPAG_PARC_1X)),
    (paymentMethods && paymentMethods.find(paymentMethod => paymentMethod[0] === GATORPAY_PAYPAL)),
  ];

  const handleBuy = () => {
    setIsLoading(true);
    dispatch(buyAcademyPass({
      planId: ACADEMY_PASS_PRODUCT_ID.br,
      paymentMethod: fastcheckout,
      paymentLocation: HG_ACADEMY_PAYMENT_LOCATION_HEADER,
    }));
  };

  const handleChangeMethod = (payment) => {
    setFastCheckout(payment.value);
  };

  const handleClick = () => {
    handleBuy();
    subscribeAcademyClick();
    // TODO: refactor - adicionar nasmespace para identificar como analytics
    paymentMethodChangeEvent(fastcheckout);
  };

  return (
    <div className={classes.paymentOptions} data-testid="cta-payment-options">
      <Typography className={classes.productName}>{t('academypass.cta.ctaHeader.productName')}</Typography>

      {parseFloat(price) > 0 && (
        <Typography className={classes.productPrice} data-testid="header-price-tag">
          {formatCurrency(price)}
          <Typography className={classes.month}>{t('academypass.cta.ctaHeader.month')}</Typography>
        </Typography>
      )}

      <div className={classes.paymentOptions}>
        <Select
          value={fastcheckout}
          onChange={e => handleChangeMethod(e.target)}
          className={classes.select}
          input={<Input value={fastcheckout} name="paymentMethod" />}
          data-testid="header-payment-select"
          disabled={isLoading}
        >
          {sortedPayments && sortedPayments[0] !== null && sortedPayments.map(paymentMethod => (paymentMethod && paymentMethod.length > 0
            && <MenuItem key={sortedPayments[0]} value={paymentMethod[0]}>{t(paymentMethod[0])}</MenuItem>))}
        </Select>
        <PrimaryButton className={classes.button} onClick={handleClick} onLoading={isLoading} data-testid="header-buy-btn">
          {t('academypass.cta.ctaHeader.buttonLabel')}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default withI18n()(withStyles(styles, { withTheme: true })(CtaPaymentOptions));
