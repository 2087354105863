import {
  REQUEST_ERROR_TICKETS, REQUEST_PENDING_TICKETS, RECEIVE_PENDING_TICKETS,
  REQUEST_CLOSED_TICKETS, RECEIVE_CLOSED_TICKETS, RECEIVE_FILTERED_PENDING_TICKETS,
  RECEIVE_FILTERED_CLOSED_TICKETS, CLEAR_FILTER_PENDING_TICKETS, CLEAR_FILTER_CLOSED_TICKETS,
  REQUEST_SAVE_TICKET_TRANSFER_DOMAIN, RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN, ERROR_SAVE_TICKET_TRANSFER_DOMAIN,
  REQUEST_PENDING_TICKETS_V2, RECEIVE_PENDING_TICKETS_V2, REQUEST_CLOSED_TICKETS_V2, RECEIVE_CLOSED_TICKETS_V2,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  loading: false,
  loadingSave: false,
  pending: {
    loading: false,
    loadingV2: false,
    tickets: [],
    ticketsV2: [],
  },
  closed: {
    loading: false,
    loadingV2: false,
    tickets: [],
    ticketsV2: [],
  },
  bkpTickets: {
    pending: [],
    closed: [],
    pendingV2: [],
    closedV2: [],
  },
  filter: {
    select: ['open', 'hold', 'conclude'],
    input: '',
  },
};

export default function tickets(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PENDING_TICKETS:
      return {
        ...state,
        loading: true,
        pending: {
          ...state.pending,
          loading: true,
        },
      };
    case REQUEST_CLOSED_TICKETS:
      return {
        ...state,
        loading: true,
        closed: {
          ...state.closed,
          loading: true,
        },
      };
    case RECEIVE_PENDING_TICKETS:
      return {
        ...state,
        loading: false,
        pending: {
          ...state.pending,
          loading: false,
          tickets: action.tickets,
        },
        bkpTickets: {
          ...state.bkpTickets,
          pending: action.tickets,
        },
      };
    case RECEIVE_FILTERED_PENDING_TICKETS:
      return {
        ...state,
        pending: {
          ...state.pending,
          tickets: action.tickets,
        },
      };
    case RECEIVE_FILTERED_CLOSED_TICKETS:
      return {
        ...state,
        closed: {
          ...state.closed,
          tickets: action.tickets,
        },
      };
    case RECEIVE_CLOSED_TICKETS:
      return {
        ...state,
        loading: false,
        closed: {
          ...state.closed,
          loading: false,
          tickets: action.tickets,
        },
        bkpTickets: {
          ...state.bkpTickets,
          closed: action.tickets,
        },
      };
    case CLEAR_FILTER_PENDING_TICKETS:
      return {
        ...state,
        pending: {
          ...state.pending,
          tickets: state.bkpTickets.pending,
        },
      };
    case CLEAR_FILTER_CLOSED_TICKETS:
      return {
        ...state,
        closed: {
          ...state.closed,
          tickets: state.bkpTickets.closed,
        },
      };
    case REQUEST_ERROR_TICKETS:
      return {
        ...state,
        loading: false,
        pending: {
          ...state.pending,
          loading: false,
        },
        closed: {
          ...state.closed,
          loading: false,
        },
      };
    case REQUEST_SAVE_TICKET_TRANSFER_DOMAIN:
      return {
        ...state,
        loadingSave: true,
      };
    case RECEIVE_SAVE_TICKET_TRANSFER_DOMAIN:
      return {
        ...state,
        loadingSave: false,
      };
    case ERROR_SAVE_TICKET_TRANSFER_DOMAIN:
      return {
        ...state,
        loadingSave: false,
      };


    case REQUEST_PENDING_TICKETS_V2:
      return {
        ...state,
        loadingV2: true,
        pending: {
          ...state.pending,
          loadingV2: true,
        },
      };
    case RECEIVE_PENDING_TICKETS_V2:
      return {
        ...state,
        loadingV2: false,
        pending: {
          ...state.pending,
          loadingV2: false,
          ticketsV2: action.tickets,
        },
        bkpTickets: {
          ...state.bkpTickets,
          pendingV2: action.tickets,
        },
      };
    case REQUEST_CLOSED_TICKETS_V2:
      return {
        ...state,
        loadingV2: true,
        closed: {
          ...state.closed,
          loadingV2: true,
        },
      };
    case RECEIVE_CLOSED_TICKETS_V2:
      return {
        ...state,
        loadingV2: false,
        closed: {
          ...state.closed,
          loadingV2: false,
          ticketsV2: action.tickets,
        },
        bkpTickets: {
          ...state.bkpTickets,
          closedV2: action.tickets,
        },
      };
    default:
      return state;
  }
}
