const styles = theme => ({
  formControl: {
    margin: '5px 8px 5px 0',
    width: '100%',
    maxWidth: '296px',
    minWidth: '192px',
    '@media(min-width: 960px) and (max-width: 1160px)': {
      minWidth: '192px',
      maxWidth: '220px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '52%',
    },
    '@media(max-width: 420px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  inputLabelCustom: {
    transform: 'translate(14px, 10px) scale(1)',
    zIndex: 0,
  },
  selectCustom: {
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 6,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderRadius: '2px',
    },
    dialogContainer: {
      '& div': {
        '& div': {
          [theme.breakpoints.up('md')]: {
            maxWidth: 750,
          },
        },
      },
    },
  },
  selectCustomInput: {},
  selectCustomOutline: {},
  selectCustomFocused: {},
  selectWrapper: {
    minWidth: '296.17px',
    width: '100%',
  },
  dialogContainer: {
    '& div': {
      '& div': {
        maxWidth: '750px',
      },
    },
  },
  loaderDataCheck: {
    margin: 0,
    padding: '5px 50px 0px 0px',
    width: 118.52,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > div': {
      margin: 0,
    },
  },
});

export default styles;
