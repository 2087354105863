import React, { useContext } from 'react';
import {
  Link,
  Modal,
} from '@/pages/common';
import * as Styles from './ModalDnsAutomatic.styles';
import { IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { useResize } from '@/hooks/useResize';
import { Button } from '@/pages/common/v1';

const ModalDnsAutomatic = ({
  title,
  descriptionOne,
  descriptionTwo,
  link,
  button,
  onContinue,
  onAccessSupportMaterial,
  onClickButton,
}) => {
  const screenSize = useResize();
  const isMobile = screenSize.width < 600;
  const theme = useContext(ThemeContext);

  const handleContinue = () => {
    onClickButton();
    onContinue && onContinue();
  };
  return (
    <Modal maxWidth={630} disablePadding maxHeight={305} testId="modaldnsautomatic-component">
      <Styles.Title>
        {title}
      </Styles.Title>
      <Styles.ContentWrapper>
        <Styles.Icon>
          <IconInfo color={theme.palette.yellow.mustard} size={isMobile ? '70' : '30'} />
        </Styles.Icon>
        <Styles.Content>
          <Styles.Description>
            {descriptionOne[0]}
            <Link onClick={() => onAccessSupportMaterial()} target="_blank" testId="link" href={link} text={descriptionOne[1]} />
            {descriptionOne[2]}
          </Styles.Description>
          <Styles.Description>
            {descriptionTwo[0]}
            {descriptionTwo[1]}
          </Styles.Description>
        </Styles.Content>
      </Styles.ContentWrapper>
      <Styles.Action>
        <Button testId="continue-creating-site-button" onClick={handleContinue} label={button} />
      </Styles.Action>
    </Modal>
  );
};

export default ModalDnsAutomatic;
