const styles = theme => ({
  container: {
    minHeight: 'calc(100vh - 135px)',
  },
  ticket: {
    '&:last-child': {
      paddingRight: '10px',
      paddingBottom: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    padding: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  uploadBox: {
    display: 'flex',
    justifyContnet: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '36px',
    bottom: '0',
    top: '0',
  },
  buttonBox: {
    display: 'flex',
    justifyContnet: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '30px',
    top: '0',
    bottom: '0',
  },
  send: {
    width: '85.45px',
    marginTop: '-3px',
  },
  divider: {
    marginBottom: '20px',
  },
  ticketSolved: {
    backgroundColor: theme.color.white,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSolved: {
    margin: '0 5px',
  },
  buttonCustomHeight: {
    height: 'inherit',
  },
  upload: {
    marginTop: '-4px',
  },
  icon: {
    color: theme.color.tundora,
    display: 'block',
  },
});

export default styles;
