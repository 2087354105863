import {
  DNS_DETAILS_REQUEST, DNS_DETAILS_RECEIVE, DNS_DETAILS_ERROR, SAVE_CHANGE_TO_DNS_NATIVE_REQUEST,
  SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS, SAVE_CHANGE_TO_DNS_NATIVE_ERROR, CHANGE_TO_NATIVE_DNS,
  CHANGE_TO_OTHER_DNS, CHANGE_TO_HOSTING_DNS, SAVE_CHANGE_TO_DNS_OTHER_REQUEST,
  SAVE_CHANGE_TO_DNS_OTHER_SUCCESS, SAVE_CHANGE_TO_DNS_OTHER_ERROR, SAVE_CHANGE_TO_DNS_HOSTING_REQUEST,
  SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS, SAVE_CHANGE_TO_DNS_HOSTING_ERROR,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loading: false,
  loadingNative: false,
  loadingOther: false,
  loadingHosting: false,
  details: {},
  notifications: [],
};

export default function dnsDetails(state = initialState, action) {
  switch (action.type) {
    case DNS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DNS_DETAILS_RECEIVE:
      return {
        ...state,
        details: action.dnsDetails.data,
        notifications: action.dnsDetails.notifications,
        loading: false,
      };
    case DNS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_TO_NATIVE_DNS:
      return {
        ...state,
        details: {
          ...state.details,
          native: true,
          hosting: false,
          other: false,
        },
      };
    case SAVE_CHANGE_TO_DNS_NATIVE_REQUEST:
      return {
        ...state,
        loadingNative: true,
      };
    case SAVE_CHANGE_TO_DNS_NATIVE_SUCCESS:
      return {
        ...state,
        loadingNative: false,
        details: {
          ...state.details,
          nameserver: {
            ...state.details.nameserver,
            other: [],
            hosting: null,
          },
          native: true,
          hosting: false,
          other: false,
        },
      };
    case SAVE_CHANGE_TO_DNS_NATIVE_ERROR:
      return {
        ...state,
        loadingNative: false,
      };

    case CHANGE_TO_OTHER_DNS:
      return {
        ...state,
        details: {
          ...state.details,
          native: false,
          hosting: false,
          other: true,
        },
      };
    case CHANGE_TO_HOSTING_DNS:
      return {
        ...state,
        details: {
          ...state.details,
          native: false,
          hosting: true,
          other: false,
        },
      };
    case SAVE_CHANGE_TO_DNS_OTHER_REQUEST:
      return {
        ...state,
        loadingOther: true,
      };
    case SAVE_CHANGE_TO_DNS_OTHER_SUCCESS:
      return {
        ...state,
        loadingOther: false,
        details: {
          ...state.details,
          nameserver: {
            ...state.details.nameserver,
            hosting: null,
            other: [
              ...action.other,
            ],
          },
          native: false,
          hosting: false,
          other: true,
        },
      };
    case SAVE_CHANGE_TO_DNS_OTHER_ERROR:
      return {
        ...state,
        loadingOther: false,
      };
    case SAVE_CHANGE_TO_DNS_HOSTING_REQUEST:
      return {
        ...state,
        loadingHosting: true,
      };
    case SAVE_CHANGE_TO_DNS_HOSTING_SUCCESS:
      return {
        ...state,
        loadingHosting: false,
        details: {
          ...state.details,
          nameserver: {
            ...state.details.nameserver,
            other: [],
            hosting: action.hosting,
          },
          native: false,
          hosting: true,
          other: false,
        },
      };
    case SAVE_CHANGE_TO_DNS_HOSTING_ERROR:
      return {
        ...state,
        loadingHosting: false,
      };
    default:
      return state;
  }
}
