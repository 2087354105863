import React from 'react';
import { withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { withI18n } from 'react-i18next';

import styles from './styles';


function ManageEmailCpanel({ t, classes }) {
  return (
    <div className={classes.wrapper}>
      <p className={classes.container}>
        <Info className={classes.icon} />
        {' '}
        {t('emails.message.withServer')}
      </p>
    </div>
  );
}

export default withI18n()(withStyles(styles, { withTheme: true })(ManageEmailCpanel));
