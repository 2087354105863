import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './DomainsListHandler.styles';
import DomainItem from './components/DomainItem';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';
import { DOMAIN_STATUS, domainsChips } from './DomainsListHandler.types';
import { Button, PageTitle, Skeleton } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale';
import { loadFaqDomainList } from '@/redux/actions/faq';
import { SUPPORT_DOMAINS_URL } from '@/config/urls/domainsUrls';
import FaqDefault from '@/components/FrequentlyQuestions/FaqDefault';
import { useBillingStatus } from '@/hooks/domains/useBillingStatus';
import { GET_A_NEW_DOMAIN } from '@/config/urls/supportUrls';
import { domainsActions } from '@/redux/modules';
import { TitanTrialBanner } from './components/TitanTrialBanner';
import { ESSENTIALS_PLAN } from '@/components/Email/TitanUpgrade/TitanUpgradePlanItem/TitanPlans';
import { requestUpgradePlans } from '@/redux/actions/emails';
import { isBRServer } from '@/utils/Validators/validation';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_DOMAINS_LIST_HANDLER_PAGINATION } from '@/config/GrowthBook/constants';

let changeFilterTimeout = null;
const DomainsListHandler = () => {
  const { domains: domainsLocale } = useLocale();
  const domainsListLocale = domainsLocale.domainsListPage;
  const defaultFilterOptions = domainsChips.map(chip => ({ ...chip, label: domainsListLocale.filter[chip.value] }));

  const pageinateDomains = useFeatureIsOn(CHARLIE_DOMAINS_LIST_HANDLER_PAGINATION);
  const [visibleDomains, setVisibleDomains] = useState(10);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
  const [selectedChip, setSelectedChip] = useState(defaultFilterOptions[0]);
  const [domainFilter, setDomainFilter] = useState('');
  const [domainsListFiltered, setDomainsListFiltered] = useState([]);
  const [essentialData, setEssentialData] = useState({ name: '', price: '' });
  const [page, setPage] = useState(1);
  const [justUpdated, setJustUpdate] = useState(false);

  const {
    loading: loadingDomains,
    list: domains,
    listTotal,
    domainsElegible,
  } = useSelector(state => state.newDomains);
  const faq = useSelector(state => state.faq);
  const allEmails = useSelector(state => state.emails.proEmailList);

  let domainWithoutEmail = allEmails.filter(item => item.type === 'domain' && !!item.domain && item.status === 'Active');
  if (domainsElegible.length) {
    domainWithoutEmail = domainsElegible.filter(item => item.status === 'Active');
  }

  const dispatch = useDispatch();
  const { getTagStatus } = useBillingStatus();

  const emptyList = domainsListFiltered.length === 0;

  const handleShowLoadMoreButton = useCallback(() => {
    if (pageinateDomains && domains.length < listTotal) {
      setShowLoadMoreButton(true);
      return;
    }

    if (!pageinateDomains && visibleDomains < domainsListFiltered.length) {
      setShowLoadMoreButton(true);
      return;
    }

    setShowLoadMoreButton(false);
  }, [
    domainsListFiltered.length,
    listTotal,
    visibleDomains,
    pageinateDomains,
    domains,
  ]);

  const loadMore = useCallback(() => {
    if (!pageinateDomains) {
      setVisibleDomains(visibleDomains + 10);
    }

    if (pageinateDomains) {
      setPage(page + 1);
    }
  }, [page, pageinateDomains, visibleDomains]);

  const filterDomain = useCallback(() => {
    let newDomainsList = [...domains];

    if (!domainFilter && selectedChip.value === 'all') {
      setDomainsListFiltered([...domains]);
    }

    if (domainFilter) {
      newDomainsList = domains.filter(product => product.domain.includes(domainFilter));
    }

    if (selectedChip && selectedChip.value !== 'all') {
      const pendingStatus = [
        DOMAIN_STATUS.PENDING,
        DOMAIN_STATUS.EXPIRED,
        DOMAIN_STATUS.OVERDUE_DATE,
        DOMAIN_STATUS.NEXT_DUE_DATE,
      ];

      switch (selectedChip.value) {
        case 'billingPending':
          newDomainsList = newDomainsList.filter(domain => pendingStatus.includes(getTagStatus(domain)));
          break;
        case 'registerFail':
          newDomainsList = newDomainsList.filter(domain => domain.status === DOMAIN_STATUS.REGISTER_ERROR);
          break;
        default:
      }
    }
    setDomainsListFiltered(newDomainsList);
  }, [domainFilter, selectedChip, domains, getTagStatus]);

  const handleTypeFilter = (text) => {
    clearTimeout(changeFilterTimeout);
    changeFilterTimeout = setTimeout(() => {
      setDomainFilter(text);
      setJustUpdate(false);
    }, 500);
  };

  const getFilterProps = () => {
    const clearOptions = () => {
      const newOptions = [...filterOptions].map((option) => {
        const newOption = { ...option };
        newOption.checked = false;
        return newOption;
      });

      return newOptions;
    };

    const handleChangeChip = (chip) => {
      const newOptions = clearOptions();
      const chipIndex = newOptions.findIndex(option => option.id === chip.id);
      setVisibleDomains(10);

      if (!chipIndex) {
        newOptions[0].checked = true;
        setSelectedChip(newOptions[0]);
      }

      if (chipIndex) {
        newOptions[chipIndex].checked = true;
        setSelectedChip(newOptions[chipIndex]);
      }

      setFilterOptions(newOptions);
    };

    const handleClearFilter = () => {
      setDomainFilter('');
      setFilterOptions(defaultFilterOptions);
      setSelectedChip(defaultFilterOptions[0]);
    };

    const handleChangeFilterBy = (text) => {
      handleTypeFilter(text);
    };

    const headButton = {
      label: domainsListLocale.contractNewButtonLabel,
      onClick: () => window.open(GET_A_NEW_DOMAIN),
    };

    const defaultProps = {
      testId: 'domains-list',
      title: domainsListLocale.filter.title,
      filterLabel: domainsListLocale.filter.filterLabel,
      filterByPlaceholder: domainsListLocale.filter.search,
      filterByValue: domainFilter,
      clearFilterLabel: domainsListLocale.filter.clearLabel,
      filterChips: filterOptions,
      hideFilter: false,
      headButton,
      handleClearFilter,
    };

    return {
      ...defaultProps,
      handleChangeChip,
      handleChangeFilterBy,
    };
  };

  const getDataOfEssential = useCallback(() => dispatch(
    requestUpgradePlans(),
  ).then(({ data }) => {
    const [planData] = data.filter(plan => plan.id === ESSENTIALS_PLAN);
    if (planData) {
      const { name, configoptions } = planData;
      const [{ monthly: price }] = configoptions;

      const dataEssential = { name, price };

      if (isBRServer) {
        dataEssential.price = price.replace('.', ',');
      }

      setEssentialData(dataEssential);
    }
  }), [dispatch]);

  const requestDataOfEssential = useCallback(() => {
    if (domainWithoutEmail.length > 0 && essentialData.price === '') {
      getDataOfEssential();
    }
  }, [getDataOfEssential, domainWithoutEmail.length, essentialData.price]);

  const requestFaqDomainList = useCallback(() => {
    dispatch(loadFaqDomainList());
  }, [dispatch]);

  useEffect(() => {
    requestDataOfEssential();
  }, [requestDataOfEssential]);

  useEffect(() => {
    filterDomain();
  }, [filterDomain]);

  useEffect(() => {
    handleShowLoadMoreButton();
  }, [handleShowLoadMoreButton]);

  useEffect(() => {
    dispatch(domainsActions.domainsElegible.get());
  }, [dispatch]);

  useEffect(() => {
    dispatch(domainsActions.domains.request(pageinateDomains ? { page } : undefined));
  }, [dispatch, page, pageinateDomains]);

  useEffect(() => {
    requestFaqDomainList();
  }, [requestFaqDomainList]);

  const mountLoading = (
    <Styles.LoadingWrapper>
      <Skeleton variant="tableSimple" />
    </Styles.LoadingWrapper>
  );

  const loadMoreButton = () => showLoadMoreButton && (
  <Styles.LoadMoreWrapper>
    <Button label={domainsListLocale.loadMoreButtonLabel} onClick={loadMore} variant="secondary" />
  </Styles.LoadMoreWrapper>
  );

  const mountEmptyState = label => (
    <Styles.EmptyStateWrapper>
      <Styles.EmptyStateLabel>
        {label}
      </Styles.EmptyStateLabel>
    </Styles.EmptyStateWrapper>
  );

  const requestNextPageWhenEmptyState = useCallback(() => {
    if (emptyList && domainFilter && domains.length < listTotal && !loadingDomains && !justUpdated) {
      setPage(page + 1);
      setJustUpdate(true);
    }
  }, [emptyList, page, domains.length, listTotal, domainFilter, loadingDomains, justUpdated]);

  useEffect(() => {
    requestNextPageWhenEmptyState();
  }, [requestNextPageWhenEmptyState]);

  return (
    <Styles.Wrapper>
      <Styles.Section noPadding>
        <PageTitle {...getFilterProps()} />
      </Styles.Section>
      <Styles.Section noPadding>
        <TitanTrialBanner domainWithoutEmail={domainWithoutEmail} essentialData={essentialData} />
      </Styles.Section>
      <Styles.Section>
        {loadingDomains ? mountLoading : (
          <>
            {domains.length === 0 ? mountEmptyState(domainsListLocale.emptyState) : (
              <>
                {emptyList ? mountEmptyState(domainsListLocale.noFilterResult) : (
                  <Styles.DomainsList>
                    {domainsListFiltered.length > 0 && domainsListFiltered.slice(0, pageinateDomains ? domainsListFiltered.length : visibleDomains).map((domain, index) => (
                      <DomainItem firstItem={index === 0} domain={domain} statusTag={getTagStatus(domain)} key={shortid.generate()} />
                    ))}
                    {loadMoreButton()}
                  </Styles.DomainsList>
                )}
              </>
            )}
          </>
        )}
      </Styles.Section>
      <FaqDefault
        questions={faq.domainList.questions}
        link={SUPPORT_DOMAINS_URL}
        loading={faq.loading}
      />
    </Styles.Wrapper>
  );
};

export default DomainsListHandler;
