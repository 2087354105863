import React from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { withI18n } from 'react-i18next';

import styles from './styles';


const TicketTextInput = ({
  classes,
  value,
  onChange,
  onKeyDown,
  onDisabled,
  t,
}) => (
  <TextField
    id="input-send"
    className={classes.input}
    multiline
    placeholder={t('tickets.inputHere')}
    fullWidth
    variant="outlined"
    autoComplete="off"
    value={value}
    onChange={onChange}
    onKeyDown={onKeyDown}
    disabled={onDisabled}
  />
);

export default withI18n()(withStyles(styles)(TicketTextInput));
