import menuBR from './menu.br';
import menuMX from './menu.mx';
import menuCL from './menu.cl';
import menuCO from './menu.co';

const menu = {
  BR: menuBR,
  MX: menuMX,
  CL: menuCL,
  CO: menuCO,
};

export default menu;
