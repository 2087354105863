const styles = theme => ({
  btnPrimary: {
    '&:disabled': {
      border: 'none',
      backgroundColor: 'transparent',
      color: theme.color.disabled,
    },
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.chambray}`,
    color: theme.color.white,
  },
  btnOutline: {
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${theme.color.mystic}`,
    color: theme.color.tundora,
    padding: '6px 16px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  creditCard: {
    alignSelf: 'flex-start',
  },
  borderDefault: {
    border: '1px solid',
    borderColor: theme.color.regentGray,
    minHeight: '132px',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  newBorderDefault: {
    border: '1px solid',
    borderColor: theme.palette.grey.primary,
    borderRadius: '2px',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  borderActive: {
    boxSizing: 'border-box',
    border: '2px solid',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderColor: theme.color.indigo,
  },
  cardSize: {
    minHeight: '132px',
    '& .rccs': {
      transform: 'scale(0.4)',
      margin: '0',
      width: '0',
      height: '0%',
      top: '0',
    },
    '& .rccs__card': {
      height: '50px',
    },
    '& .rccs__card--front': {
      height: '160px',
    },
  },
  newCardSize: {
    minHeight: '',
  },
  cardSizeNewLayout: {
    '& .rccs': {
      transform: 'scale(0.2)',
      marginLeft: '-9px',
      width: '112px',
      height: '0%',
      top: '0',
    },
    '& .rccs__card': {
      height: '50px',
    },
    '& .rccs__card--front': {
      height: '160px',
    },
  },
  cardSizeForm: {
    '& .rccs': {
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        margin: '0 auto',
      },
      transform: 'scale(0.8)',
      margin: '0',
      width: '0',
    },

    '& .rccs__card': {
      height: '170px',
      width: '270px',
    },
  },
  cardSizeFormFull: {
    '& .rccs': {
      transform: 'scale(0.9)',
      margin: '0 auto',
      width: '100%',
    },
    '& .rccs__card': {
      height: '170px',
      width: '270px',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      '& .formBox': {
        paddingTop: 0,
      },
    },
  },
  cardFormCaption: {
    padding: '10px 0 20px 0',
    minHeight: '80px',
  },
  newCardFormCaption: {
    padding: '0px',
    marginLeft: '34px',
  },
  paddingCardFormEmpty: {
    paddingBottom: '16px',
  },
  container: {
    '& .handleInputCreditCard': {
      width: '100%',
    },
    padding: '10px 0 20px 0',
  },
  containerAddNewCard: {
    '& .handleInputCreditCard': {
      width: '100%',
    },
    padding: '10px 0 20px 33px',
    '@media (max-width: 600px)': {
      padding: '0px',
    },
  },
  containerNewLayout: {
    '& .handleInputCreditCard': {
      width: '100%',
    },
    padding: '0px',
  },
  containerLeft: {
    '& .handleInputCreditCard': {
      width: '100%',
    },
    textAlign: 'left',
    padding: '10px 0 20px 0',
  },
  newDialogContent: {
    overflowY: 'inherit',
    padding: '22px 0px 0px 0px',
  },
  dialogActions: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newDialogActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
    marginTop: 32,
  },
  cardFlexBox: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
    },
    paddingLeft: '24px',
    display: 'flex',
    alignItens: 'center',
    '& > svg': {
      marginRight: '10px',
    },
    '& > p': {
      fontWeight: '500',
      fontSize: '16px',
    },
  },
  invoiceCardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardSummaryContent: {
    paddingTop: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  receiptIcon: {
    color: theme.color.valencia,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  creditCardFormCustomRegister: {
    [theme.breakpoints.up('md')]: {
      '& > div': {
        '& > div': {
          '& > div': {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: '-28px',
            '& > div': {
              paddingLeft: 0,
              paddingRight: 0,
              '& > div': {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          },
        },
      },
    },
  },
  creditCardFormCustomReplace: {
    [theme.breakpoints.up('md')]: {
      '& > div': {
        '& > div': {
          '& > div': {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: '-12px',
            '& > div:first-child': {
              marginLeft: 'calc(50% - 108px)',
            },
            '& > div:nth-child(2)': {
              '& > div': {
                paddingLeft: 0,
                paddingRight: 0,
                '& > div': {
                  paddingRight: 0,
                },
              },
            },
          },
        },
      },
    },
  },
  arrow: {
    fill: theme.color.tundora,
  },
  creditCardInstallmentsLoadingDialog: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '60vh',
  },
  creditCardInstallmentsLoadingDialogText: {
    maxWidth: '350px',
  },
  creditCardInstallmentsQuestionAdapter: {
    alignItems: 'center',
    display: 'flex',
    margin: '20px 0 0 0',
  },
  creditCardInstallmentsQuestion: {
    fontSize: 14,
  },
  newCreditCardInstallmentsQuestion: {
    fontSize: 14,
    marginBottom: '0px',
  },
  creditCardInstallmentsIconAdapter: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '5px',
  },
  newCreditCardInstallmentsIconAdapter: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingLeft: '5px',
  },
  creditCardInstallmentsFormControl: {
    margin: '16px 8px 5px 0',
    width: '339px',
    minWidth: '192px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  creditCardInstallmentsInputLabelCustom: {
    transform: 'translate(14px, 10px) scale(1)',
    zIndex: 0,
  },
  useExistingCard: {
    color: theme.palette.secondary.primary,
    fontFamily: theme.font.family.secondary,
    fontWeight: theme.font.weights.medium,
  },
  useExistingCardSelected: {
    color: theme.palette.blue.dark,
    fontFamily: theme.font.family.secondary,
    fontWeight: theme.font.weights.medium,
  },
  creditCardInstallmentsSelectCustom: {
    '&$selectCustomFocused $selectCustomOutline': {
      borderColor: theme.color.chambray,
      borderWidth: 1,
    },
    '& div > div': {
      paddingBottom: 6,
      paddingTop: 9,
      fontSize: 14,
    },
    '& div > div:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderRadius: '2px',
    },
    dialogContainer: {
      '& div': {
        '& div': {
          [theme.breakpoints.up('md')]: {
            maxWidth: 750,
          },
        },
      },
    },
  },
  creditCardInstallmentsSelectCustomInput: {},
  creditCardInstallmentsSelectCustomOutline: {},
  creditCardInstallmentsSelectCustomFocused: {},
  creditCardInstallmentsSelectWrapper: {
    minWidth: '296.17px',
    width: '100%',
  },
  creditCardInstallmentsTooltipLink: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export default styles;
