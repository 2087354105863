import styled, { css } from 'styled-components';

export const Container = styled.section`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px 60px;
  @media (max-width: ${theme.v1.breakpoints.sm}) {
      padding: 32px 16px 28px;
    }
  `}
`;

export const Title = styled.h3`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  font-family: ${theme.v1.font.family.primary};
  font-size: 30px;
  font-weight: ${theme.v1.font.weights.bold};
  line-height: 40px;
  margin: 0px 0px 56px 0px;
  max-width: 600px;
  text-align: center;
  span {
    color: ${theme.v1.color.action.hover}
  }
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    max-width: 450px;
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    font-size: 24px;
    line-height: 32px;
    margin: 0px 0px 32px 0px;
  }
  `}
`;

export const FirstRow = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  gap: 137px;
  justify-content: center;
  margin-bottom: 55px;
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    gap:100px;
  }
  @media (max-width: 1085px) {
    gap:20px;
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
  `}
`;

export const SecondRow = styled.div`
${({ theme }) => css`
  align-items: center;
  display: flex;
  gap: 137px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    gap: 128px;
  }
  @media (max-width: 1085px) {
    gap:20px;
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    flex-direction: column;
  }
  `}
`;

export const RowContent = styled.div`
${({ theme, row }) => css`
  display: flex;
  flex-direction: column;
  margin-top: ${row === 2 ? '40px' : '0px'};
  max-width: 568px;
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    margin-top:0px;
    max-width: 440px;
  }
  `}
`;

export const RowImage = styled.img`
${({ theme }) => css`
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    width: 387px;
  }
  @media (max-width: 1085px) {
    width: 300px;
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    width: 100%;
  }
  `}
`;

export const ImageWrapper = styled.div`
`;


export const RowTitle = styled.h5`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  font-family: ${theme.v1.font.family.primary};
  font-size: 24px;
  font-weight: ${theme.v1.font.weights.bold};
  line-height: 32px;
  margin: 0px;
  text-align: left;
  span {
    color: ${theme.v1.color.action.hover}
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    text-align: center;
  }
  `}
`;

export const RowDescription = styled.p`
${({ theme }) => css`
  color: ${theme.v1.color.text.primary};
  font-family: ${theme.v1.font.family.primary};
  font-size: 16px;
  font-weight: ${theme.v1.font.weights.regular};
  line-height: 22px;
  margin: 16px 0px;
  text-align: left;
  span {
    display: block;
  }
  @media (max-width: ${theme.v1.breakpoints.lg}) {
    margin: 20px 0px;
  }
  @media (max-width: ${theme.v1.breakpoints.md}) {
    text-align: center;
  }
  `}
`;

export const RowButtonWrapper = styled.div`
${({ theme }) => css`
    @media (max-width: ${theme.v1.breakpoints.md}) {
      display:flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  `}
`;
