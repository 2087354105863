const styles = theme => ({
  main: {
    backgroundColor: theme.color.mystic,
    position: 'relative',
    padding: '46px 176px',
    color: theme.color.tundora,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '46px 0px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '46px 80px',
    },
  },

  contentWrapper: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },

  countdown: {
    textTransform: 'lowercase',
  },

  countdownText: {
    color: theme.color.tundora,
    fontSize: 14,
    fontWeight: 500,
  },

  phoneVerifyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  phoneContentWrapper: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '340px',
  },

  footer: {
    marginTop: 20,
  },

  button: {
    marginLeft: 10,
    minWidth: 109,
    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      color: theme.color.tundora,
    },
  },

  wrapperSendCodeBoxLinks: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 90,
    },
  },

  link: {
    color: theme.color.tundora,
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 15,
  },

});

export default styles;
