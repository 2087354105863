import React from 'react';
import * as Style from './IconCloud.style';

const IconCloud = ({
  color = '#000000',
  background = '#2E93EE',
  size = '40',
  testId = 'icon-cloud',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_526_7010)">
        <path d="M0.5 20C0.50082 24.5084 2.29214 28.832 5.48008 32.0199C8.66801 35.2078 12.9916 36.9992 17.5 37L17.5 3C12.9913 3 8.66731 4.79108 5.47919 7.97919C2.29108 11.1673 0.5 15.4913 0.5 20Z" fill={background} />
        <path d="M15.0203 28.1524H12.6334C8.68493 28.1524 5.47293 24.9405 5.47293 20.9919C5.47293 17.5962 7.79813 14.7475 11.0194 14.0152C11.9168 9.08709 16.1777 5.47717 21.2854 5.47717C26.3937 5.47717 30.6554 9.08769 31.5522 14.0169C33.7379 14.5211 35.5843 16.0322 36.5 18.123L34.3137 19.08C33.6161 17.4873 32.1093 16.3995 30.3821 16.2409L29.3952 16.1504L29.3033 15.1634C28.915 11.0018 25.4683 7.86371 21.2853 7.86371C17.1023 7.86371 13.6556 11.0017 13.268 15.1627L13.1761 16.1497L12.1891 16.2403C9.72105 16.4677 7.85932 18.5102 7.85932 20.9913C7.85932 23.6235 10.0007 25.7649 12.633 25.7649H15.0198L15.0203 28.1524Z" fill={color} />
        <path d="M28.1479 33.5228C26.6125 33.5228 25.1371 33.0597 23.8813 32.1832L25.2479 30.2259C26.1007 30.8217 27.1036 31.136 28.1479 31.136C30.9447 31.136 33.2199 28.8608 33.2199 26.064C33.2199 23.2672 30.9447 21.1411 28.1479 21.1411C25.3511 21.1411 23.0759 23.3788 23.0759 26.3623H20.689C20.689 22.1854 24.0349 18.7543 28.1479 18.7543C32.2609 18.7543 35.6067 22.0255 35.6067 26.1386C35.6067 30.2516 32.2609 33.5228 28.1479 33.5228Z" fill={color} />
        <path d="M22.1808 29.2436L17.7568 24.8189L19.4444 23.1313L22.1809 25.8671L24.9174 23.1313L26.6049 24.8189L22.1808 29.2436Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_526_7010">
          <rect width={size} height={size} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </Style.Wrapper>
);


export default IconCloud;
