import React, {
  useCallback, useEffect, useRef,
} from 'react';
import ReactDOM from 'react-dom';
import { IconClose } from '@/icons';
import * as Styles from './Modal.styles';

const Modal = ({
  children,
  maxWidth = 600,
  maxHeight = 600,
  onClose,
  disablePadding = false,
  disableOverFlow = false,
  centerContentInMobile = false,
  testId = 'modal',
  fromTarget = '#root',
  position = 'fixed',
  variant = 'default',
  onCloseButtonAction,
  whiteIcon,
}) => {
  const ESCAPE_KEY_CODE = 27;
  const cardRef = useRef();
  const root = document.querySelector(fromTarget) || document.body;

  const handleOnClose = useCallback((isCloseButton) => {
    onClose && onClose();

    if (isCloseButton) {
      onCloseButtonAction && onCloseButtonAction();
    }
  }, [onClose, onCloseButtonAction]);

  const handlePressKey = useCallback((event) => {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      handleOnClose();
    }
  }, [handleOnClose]);

  const handleClickOutsideCard = useCallback((event) => {
    if (!cardRef.current || !cardRef.current.contains(event.target)) {
      handleOnClose();
    }
  }, [handleOnClose]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.addEventListener('keydown', handlePressKey, false);
    document.addEventListener('mousedown', handleClickOutsideCard, false);

    return () => {
      document.removeEventListener('keydown', handlePressKey, false);
      document.removeEventListener('mousedown', handleClickOutsideCard, false);
      document.body.style.overflowY = 'unset';
    };
  }, [handlePressKey, handleClickOutsideCard]);

  const template = (
    <Styles.Overlay position={position} data-testid={testId}>
      <Styles.ModalCard
        data-testid="modal-card"
        id={`${testId}-card`}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        disablePadding={disablePadding}
        disableOverFlow={disableOverFlow}
        ref={cardRef}
        variant={variant}
        centerContentInMobile={centerContentInMobile}
      >
        {(onClose || onCloseButtonAction) && (
          <Styles.CloseButton onClick={() => handleOnClose(true)} data-testid={`${testId}-button-close`} variant={variant} whiteIcon={whiteIcon}>
            <IconClose />
          </Styles.CloseButton>
        )}
        {children}
      </Styles.ModalCard>
    </Styles.Overlay>
  );

  return fromTarget
    ? ReactDOM.createPortal(template, root)
    : template;
};

export default Modal;
