import styled, { css } from 'styled-components';

export const Anchor = styled.a`
  ${({ theme, variant }) => {
    const variants = {
      default: {
        decoration: 'underline',
        hoverDecoration: 'underline',
        color: theme.v1.color.action.primary,
        decorationColor: 'transparent',
        hoverDecorationColor: theme.v1.color.action.primary,
        hoverColor: theme.v1.color.action.primary,
        fontWeight: theme.v1.font.weights.medium,
      },
    };

    return css`
      align-items: center;
      color: ${variants[variant].color};
      cursor: pointer;
      display: flex;
      font-family: ${theme.v1.font.family.primary};
      font-weight: ${variants[variant].fontWeight};
      text-decoration-color: ${variants[variant].decorationColor};
      text-decoration-line: ${variants[variant].decoration};
      transition: color 200ms ease-in-out, text-decoration-color 200ms ease-in-out;

      &:hover {
        color: ${variants[variant].hoverColor};
        text-decoration: ${variants[variant].hoverDecoration};
        text-decoration-color: ${variants[variant].hoverDecorationColor};
      }
    `;
  }}
`;

export const IconWrapper = styled.a`
  span {
    margin: 0;
    margin-left: 5px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;
