import styled, { css } from 'styled-components';


export const NoDomainText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowLight};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm2};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0px;
  `}
`;

export const PlanWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 620px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.v2.colors.neutral.highDark};
      border-radius: 25px;
      height: 30px;
      width: 8px;  
    }
  `}
`;

export const DomainsWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    display: flex;
    flex-direction: column;
    gap: 0px;
    max-height: 620px;
    overflow-y: auto;
    padding: 0px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.v2.colors.neutral.highDark};
      border-radius: 25px;
      height: 30px;
      width: 8px;  
    }
  `}
`;


export const DomainItem = styled.button`
  ${({ theme, isSelected }) => css`
    align-items: flex-start;
    background-color: ${isSelected ? `${theme.v2.colors.brand.primaryMedium} !important` : 'transparent'};
    border-bottom: 1px solid ${theme.v2.colors.neutral.highMedium};
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
    padding: 8px 24px;

    &:last-child { 
      border-bottom: 0px;
      padding-bottom: 16px;
    }

    &:first-child {
      padding-top: 16px;
    }

    &:hover { 
      background-color: ${theme.v2.colors.white.primary};
      span{
        color: ${theme.v2.colors.brand.primaryMedium};
      }
    }
  `}
`;

export const Domain = styled.span`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? `${theme.v2.colors.white.primary} !important` : theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    min-width: 100%;
    text-align: left;
  `}
`;

export const Plan = styled.span`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? `${theme.v2.colors.white.primary} !important` : theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    min-width: 100%;
    text-align: left;
  `}
`;

export const Divider = styled.span`
  ${({ theme }) => css`
    height: 1px;
    background-color: ${theme.v2.colors.neutral.highMedium};
    width: calc(100% - 32px);
    margin: 0px auto;
  `}
`;

export const PlanItem = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 26px;
  `}
`;

export const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PlanName = styled.span`
  ${({ theme }) => css`
      color: ${theme.v2.colors.neutral.lowDark};
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.xs};
      font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const MainDomainLabel = styled.span`
  ${({ theme }) => css`
      color: ${theme.v2.colors.neutral.lowLight};
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.sm2};
      font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const MainDomainItem = styled.span`
  ${({ theme }) => css`
      color: ${theme.v2.colors.neutral.lowDark};
      font-family: ${theme.v2.font.family.primary};
      font-size: ${theme.v2.font.sizes.sm2};
      font-weight: ${theme.v2.font.weights.regular};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ changePlanWithInput }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap:24px;
    justify-content: ${changePlanWithInput ? 'flex-end' : 'center'};
    margin: ${changePlanWithInput ? '8px' : '32px 0px 0px'};
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
  `}
`;
