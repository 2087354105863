const styles = theme => ({
  table: {
    padding: '22px 0px 24px',
  },
  tableBackground: {
    backgroundColor: theme.color.gallery,
    position: 'relative',

    '&:before,&:after': {
      backgroundColor: theme.color.gallery,
      content: '""',
      display: 'block',
      height: '100%',
      position: 'absolute',
      top: 0,
      width: 30,
    },

    '&:before': {
      left: -30,
    },

    '&:after': {
      right: -30,
    },
  },
  tableRow: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.color.loblolly}`,
    display: 'flex',
    height: 37,
  },
  tableData: {
    fontSize: 14,
    letterSpacing: '0.01px',
    lineHeight: '17px',
    margin: '22px 0px',
    maxWidth: '50%',
    width: '100%',
  },
  tableHeader: {
    color: theme.color.regentGray,
    fontWeight: 400,
  },
  tableItem: {
    color: theme.color.tundora,
    fontWeight: 500,
  },
  tableButton: {
    color: theme.color.indigo,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    marginRight: 32,
    textTransform: 'unset',

    '& svg': {
      marginRight: 10,
      width: 16,
    },
  },
  buttonWrapper: {
    display: 'flex',
    alignItenx: 'center',
    justifyContent: 'center',
    marginTop: 11,
  },
  chipElement: {
    height: 21,
  },
  wrapperData: {
    '& span': {
      fontWeight: 400,
    },
  },
  trunkateData: {
    maxWidth: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default styles;
