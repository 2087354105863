import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    right: 16px;
    top: 72px;
    width: 285px;

    z-index: 99;
`;

export const Content = styled.div`
  display: flex;
  height: 250px;
  text-align: center;
`;

export const CornerTag = styled.div`
    border-color: #0070D1 transparent transparent #0070D1;
    border-style: solid;
    border-width: 20px 20px 20px 20px;
    height: 0;
    line-height: 0;
    margin: 4px 0px 0px 4px;
    width: 0;
`;

export const CornerTagTextContainer = styled.div`
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  left: 4px;
  position: absolute;
  top: 13px;
  transform: rotate(-45deg);
  width: 30px;
`;

export const CornerTagText = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.white.primary};
    font-size: 9px;
    font-family: ${theme.v2.font.family.primary};
    font-weight: ${theme.v2.font.weights.bold};
    line-height: normal;
    text-transform: uppercase;
  `}
`;

export const SplitInvoiceIcon = styled.img`
  ${({ theme }) => css`

    @media (max-width: ${theme.v2.breakpoints.md}){
    }
  `}
`;

export const CreditCardInstallmentsIconAdapter = styled.div`
    display: flex;
    justify-content: center;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 16px;
`;

export const CloseButton = styled.a`
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
`;

export const TextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 32px 32px 32px 32px;
    position:absolute;
    text-align: center;
    a {
      font-size: 14px;
      color: #0070D1;
    };
`;

export const Title = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-size: 16px;
  font-family: ${theme.v2.font.family.primary};
  font-weight: ${theme.v2.font.weights.bold};
  margin: 16px 0px 16px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: 16px;
  font-weight: ${theme.v2.font.weights.regular};
  margin: 0px 0px 16px 0px;
  witth: 216px;
  `}
`;
