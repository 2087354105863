import {
  REQUEST_SUMMARY, RECEIVE_SUMMARY, REQUEST_SAVE_PERSONA,
  SUCCESS_SAVE_PERSONA, ERROR_SAVE_PERSONA,
  ONBOARDING_DISMISS, ONBOARDING_AGREE,
  REQUEST_LOAD_STATES, RECEIVE_LOAD_STATES,
  ERROR_LOAD_STATES, REQUEST_LOAD_CITIES_BY_STATES,
  RECEIVE_LOAD_CITIES_BY_STATES, ERROR_LOAD_CITIES_BY_STATES,
  REQUEST_LOAD_COUNTRIES, RECEIVE_LOAD_COUNTRIES, ERROR_LOAD_COUNTRIES,
  REQUEST_LOAD_ZIP_CODE, RECEIVE_LOAD_ZIP_CODE, ERROR_LOAD_ZIP_CODE,
  REQUEST_UPDATE_SUMMARY, RECEIVE_UPDATE_SUMMARY, ERROR_UPDATE_SUMMARY, NEW_SITE_FLUX_DISMISS, SET_PHONENUMBER_VERIFIED,
} from '@/redux/actions/actionsTypes';


export const initialState = {
  loading: false,
  loadingStates: false,
  loadingCities: false,
  loadingCountries: false,
  loadingStatesCitiesCountries: false,
  loadingZipCode: false,
  loadingUpdateSummary: false,
  isNewUser: false,
  showModal: false,
  websiteBuilderDismiss: false,
  newSiteFlux: false,
  persona: -1,
  countryName: '',
  locationData: {
    states: [],
    cities: [],
    countries: [],
    addressesByZipCodeSearch: {},
    lastCountryLoad: '',
    lastStateLoad: '',
  },
  emailVerified: false,
  phoneNumberVerified: false,
  builderEmail: '',
};

export default function summary(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SUMMARY:
    case REQUEST_SAVE_PERSONA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SUMMARY:
      return {
        ...state,
        ...action.summary,
        loading: false,
      };
    case SUCCESS_SAVE_PERSONA:
      return {
        ...state,
        persona: action.data.idSelectedPersona,
        loading: false,
        properties: {
          ...state.properties,
          persona: action.data.nameSelectedPersona,
        },
      };
    case ERROR_SAVE_PERSONA:
      return {
        ...state,
        loading: false,
        persona: -1,
      };
    case ONBOARDING_DISMISS:
      return {
        ...state,
        websiteBuilderDismiss: true,
      };
    case NEW_SITE_FLUX_DISMISS:
      return {
        ...state,
        websiteBuilderDismiss: true,
        newSiteFlux: true,
      };
    case ONBOARDING_AGREE:
      return {
        ...state,
        websiteBuilderDismiss: false,
        newSiteFlux: true,
      };
    case REQUEST_LOAD_STATES:
      return {
        ...state,
        loadingStates: true,
      };
    case RECEIVE_LOAD_STATES:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          states: action.data,
          lastCountryLoad: action.country,
        },
        loadingStates: false,
      };
    case ERROR_LOAD_STATES:
      return {
        ...state,
        loadingStates: false,
        loadingStatesCitiesCountries: false,
      };
    case REQUEST_LOAD_CITIES_BY_STATES:
      return {
        ...state,
        loadingCities: true,
      };
    case RECEIVE_LOAD_CITIES_BY_STATES:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          cities: action.data,
          lastStateLoad: action.state,
        },
        loadingCities: false,
        loadingStatesCitiesCountries: false,
      };
    case ERROR_LOAD_CITIES_BY_STATES:
      return {
        ...state,
        loadingCities: false,
        loadingStatesCitiesCountries: false,
      };
    case REQUEST_LOAD_COUNTRIES:
      return {
        ...state,
        loadingCountries: true,
        loadingStatesCitiesCountries: true,
      };
    case RECEIVE_LOAD_COUNTRIES:
      return {
        ...state,
        countryName: action.countryName,
        locationData: {
          ...state.locationData,
          countries: action.data,
        },
        loadingCountries: false,
      };
    case ERROR_LOAD_COUNTRIES:
      return {
        ...state,
        loadingCountries: false,
        loadingStatesCitiesCountries: false,
      };
    case REQUEST_LOAD_ZIP_CODE:
      return {
        ...state,
        loadingZipCode: true,
      };
    case RECEIVE_LOAD_ZIP_CODE:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          addressesByZipCodeSearch: action.data,
        },
        loadingZipCode: false,
      };
    case ERROR_LOAD_ZIP_CODE:
      return {
        ...state,
        loadingZipCode: false,
      };
    case REQUEST_UPDATE_SUMMARY:
      return {
        ...state,
        loadingUpdateSummary: true,
      };
    case RECEIVE_UPDATE_SUMMARY:
      return {
        ...state,
        firstname: action.firstName,
        lastname: action.firstName,
        loadingUpdateSummary: false,
      };
    case ERROR_UPDATE_SUMMARY:
      return {
        ...state,
        loadingUpdateSummary: false,
      };
    case SET_PHONENUMBER_VERIFIED:
      return {
        ...state,
        phoneNumberVerified: action.payload,
      };
    default:
      return state;
  }
}
