import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.md};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const SelectManualDnsLoadingMessageWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.brand.primaryLowLight};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin: 16px 0px 0px;
    padding: 16px 0px 32px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      height: 100%;
    }
  `}
`;

export const SelectManualDnsLoadingMessage = styled.p`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.primaryUltralight};
    border-radius: 4px;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-wrap: wrap;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 16px;
    justify-content: center;
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px 0px 28px;
    text-align: center;
  `}
`;
