import { useEffect, useState } from 'react';
import { billingAnalytics } from '@/analytics';

const useBenefitAnalytics = (retentionInfos, isNewPage = false) => {
  const [pageView, setPageView] = useState(false);
  const { subscriptions: benefitAnalytics } = billingAnalytics;
  const cycle = retentionInfos && retentionInfos.billingCycle;

  const analyticsBenefitAccept = () => {
    benefitAnalytics.benefitAccept(cycle, isNewPage);
  };

  const analyticsBenefitDecline = () => {
    benefitAnalytics.benefitDecline(cycle, isNewPage);
  };

  useEffect(() => {
    const analyticsBenefitPageView = () => {
      if (!pageView && cycle) {
        benefitAnalytics.benefitAccess(cycle);
        setPageView(true);
      }
    };

    analyticsBenefitPageView();
  }, [pageView, benefitAnalytics, cycle]);

  return {
    analyticsBenefitAccept,
    analyticsBenefitDecline,
  };
};

export default useBenefitAnalytics;
