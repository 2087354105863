import React from 'react';
import * as Style from './IconDetailedInstitutional.style';

const IconDetailedInstitutional = ({
  color = '#4a4a4a',
  backgroundColor = '#f47924',
  size = '30',
  testId = 'icon-detailed-institutional',
}) => (
  <Style.Wrapper data-testid={testId}>
    <svg id="Grupo_40226" data-name="Grupo 40226" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30.518" fill={backgroundColor}>
      <g id="icon-velocidade">
        <path id="Oval" d="M295.844,544.73a12.266,12.266,0,0,1,12.263,12.264H283.58a12.264,12.264,0,0,1,12.264-12.264Z" transform="translate(-283.58 -544.73)" />
      </g>
      <path id="Caminho_247875" data-name="Caminho 247875" d="M13.689,7.7V3H2V24.162H25.378V7.7ZM11.351,21.811H4.338V19.459h7.013Zm0-4.7H4.338V14.757h7.013Zm0-4.7H4.338V10.054h7.013Zm0-4.7H4.338V5.351h7.013ZM23.04,21.811H13.689V10.054H23.04ZM20.7,12.405H16.027v2.351H20.7Zm0,4.7H16.027v2.351H20.7Z" transform="translate(2.315 6.356)" fill={color} />
    </svg>
  </Style.Wrapper>
);


export default IconDetailedInstitutional;
