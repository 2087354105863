export const FaqActionTypes = {
  REQUEST_FAQ_WORDPRESS: 'REQUEST_FAQ_WORDPRESS',
  GET_FAQ_WORDPRESS_SUCCESS: 'GET_FAQ_WORDPRESS_SUCCESS',
  GET_FAQ_WORDPRESS_FAILURE: 'GET_FAQ_WORDPRESS_FAILURE',

  REQUEST_FAQ_SOFTACULOUS: 'REQUEST_FAQ_SOFTACULOUS',
  GET_FAQ_SOFTACULOUS_SUCCESS: 'GET_FAQ_SOFTACULOUS_SUCCESS',
  GET_FAQ_SOFTACULOUS_FAILURE: 'GET_FAQ_SOFTACULOUS_FAILURE',

  REQUEST_FAQ_SITEBUILDER: 'REQUEST_FAQ_SITEBUILDER',
  GET_FAQ_SITEBUILDER_SUCCESS: 'GET_FAQ_SITEBUILDER_SUCCESS',
  GET_FAQ_SITEBUILDER_FAILURE: 'GET_FAQ_SITEBUILDER_FAILURE',

  REQUEST_FAQ_MAIN: 'REQUEST_FAQ_MAIN',
  GET_FAQ_MAIN_SUCCESS: 'GET_FAQ_MAIN_SUCCESS',
  GET_FAQ_MAIN_FAILURE: 'GET_FAQ_MAIN_FAILURE',

  REQUEST_FAQ_FINANCIAL: 'REQUEST_FAQ_FINANCIAL',
  GET_FAQ_FINANCIAL_SUCCESS: 'GET_FAQ_FINANCIAL_SUCCESS',
  GET_FAQ_FINANCIAL_FAILURE: 'GET_FAQ_FINANCIAL_FAILURE',

  REQUEST_FAQ_DNS: 'REQUEST_FAQ_DNS',
  GET_FAQ_DNS_SUCCESS: 'GET_FAQ_DNS_SUCCESS',
  GET_FAQ_DNS_FAILURE: 'GET_FAQ_DNS_FAILURE',

  REQUEST_FAQ_MAIL: 'REQUEST_FAQ_MAIL',
  GET_FAQ_MAIL_SUCCESS: 'GET_FAQ_MAIL_SUCCESS',
  GET_FAQ_MAIL_FAILURE: 'GET_FAQ_MAIL_FAILURE',

  REQUEST_FAQ_SITE: 'REQUEST_FAQ_SITE',
  GET_FAQ_SITE_SUCCESS: 'GET_FAQ_SITE_SUCCESS',
  GET_FAQ_SITE_FAILURE: 'GET_FAQ_SITE_FAILURE',

  REQUEST_FAQ_CLOUDFLARE: 'REQUEST_FAQ_CLOUDFLARE',
  GET_FAQ_CLOUDFLARE_SUCCESS: 'GET_FAQ_CLOUDFLARE_SUCCESS',
  GET_FAQ_CLOUDFLARE_FAILURE: 'GET_FAQ_CLOUDFLARE_FAILURE',

  LOADING_FAQ: 'LOADING_FAQ',
  SET_FAQ: 'SET_FAQ',
  DEFAULT: 'FAQ_DEFAULT',
};
