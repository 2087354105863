import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import * as Styles from './MyDataHandler.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  Button,
} from 'gatorcomponents';
import { myDataAnalytics } from '@/analytics/myData';
import { useDispatch, useSelector } from 'react-redux';
import { phoneNumberChangeFlow } from '@/redux/actions/phoneNumberVerify';
import { emailChangeFlow } from '@/redux/actions/emailVerify';
import {
  loadCitiesByCountryAndState, loadCountries, loadStatesByCountry, loadSummary, loadZipCode, updateSummary,
} from '@/redux/actions/summary';
import {
  hasSpecialCharacters,
  isBRServer, maxDate, minDate, required, validateCep, validateCnpj, validateCompanyName, validateCpf, validateEmail, validatePersonName, validatePhoneBR,
} from '@/utils/Validators/validation';
import { format, parse } from 'date-fns';
import { CEP_URL_BY_BRAND } from '@/config/urls/cepUrls';
import { LATAM_COUNTRIES } from '@/config/application/operatingCountriesConfig';
import { RECEIVE_UPDATE_SUMMARY } from '@/redux/actions/actionsTypes';
import { commonActions } from '@/redux/modules';
import ReCaptcha from '@/components/ReCaptcha';
import { COUNTRY, config } from '@/config';
import { generateRecaptchaToken, loadReCaptcha } from '@/utils/Application/reCaptcha';
import { postalCodeMask } from '@/utils/Masks/inputMasks';
import { useHistory } from 'react-router';
import { PersonFields } from './components/PersonFields';
import { AddressFields } from './components/AddressFields';
import MyDataSkeleton from './components/MyDataSkeleton/MyDataSkeleton';
import { CHARLIE_CHECKOUT_PHONEINPUT_FLAGS } from '@/config/GrowthBook/constants';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const defaultFormData = {
  address: { value: '', error: '' },
  numberAndComplement: { value: '', error: '' },
  birthCountry: { value: { name: '', id: 0, abbreviation: '' }, error: '' },
  birthDate: { value: '', error: '' },
  city: { value: { id: 0, name: '' }, error: '' },
  companyName: { value: '', error: '' },
  country: { value: { name: '', id: 0, abbreviation: '' }, error: '' },
  document: { value: '', error: '' },
  email: { value: '', error: '' },
  knowledge: 0,
  mailSubscribe: false,
  name: { value: '', error: '' },
  neighborhood: { value: '', error: '' },
  phone: { value: '', error: '' },
  postalCode: { value: '', error: '' },
  state: { value: { name: '', id: 0, code: '' }, error: '' },
};

const postalCodeLength = {
  BR: 9,
  MX: 5,
  CL: 7,
  CO: 6,
  other: 30,
};

loadReCaptcha();
const MyDataHandler = ({ newPhoneFlowEnabled, newEmailFlowEnabled }) => {
  const history = useHistory();
  const { myData: myDataLocale } = useLocale();
  const dispatch = useDispatch();
  const summaryState = useSelector(state => state.summary);

  const defineInitialContryOnSelect = useCallback(() => {
    switch (COUNTRY) {
      case 'co':
        return '+57';
      case 'mx':
        return '+52';
      case 'cl':
        return '+56';
      case 'br':
      default:
        return '+55';
    }
  }, []);

  const [formData, setFormData] = useState(defaultFormData);
  const [userOriginalFormData, setUserOriginalFormData] = useState(defaultFormData);
  const phoneNumberCountry = useSelector(state => state.summary.phoneNumberCountry);
  const countriesCode = useSelector(state => state.countryPhoneCodes.countryCode);
  const countryCode = phoneNumberCountry ? countriesCode && countriesCode[phoneNumberCountry] && `+${countriesCode[phoneNumberCountry].code}` : defineInitialContryOnSelect();
  const [countriesSelectOptions, setCountriesSelectOptions] = useState([]);
  const [isForeigner, setIsForeigner] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(undefined);
  const [userDataMapped, setUserDataMapped] = useState(false);

  const renderFlags = useFeatureIsOn(CHARLIE_CHECKOUT_PHONEINPUT_FLAGS);

  const brand = LATAM_COUNTRIES.includes(formData.country.value.abbreviation) ? formData.country.value.abbreviation : 'other';
  const urlGetCep = brand && CEP_URL_BY_BRAND[brand.toLowerCase()];
  const knowledgeLevels = useMemo(() => [
    {
      value: 1,
      label: myDataLocale.knowledgeLevels.beginner,
    },
    {
      value: 2,
      label: myDataLocale.knowledgeLevels.intermediary,
    },
    {
      value: 3,
      label: myDataLocale.knowledgeLevels.technical,
    },
  ], [myDataLocale]);

  const onLoadPostalCode = useCallback(
    () => dispatch(loadZipCode(formData.postalCode.value)),
    [dispatch, formData],
  );

  const onLoadCountries = country => dispatch(loadCountries(country));

  const onLoadStatesByCountry = country => dispatch(loadStatesByCountry(country));

  const onLoadCitiesByCountryAndState = (country, state) => dispatch(loadCitiesByCountryAndState(country, state));

  const findMyCepClick = useMemo(
    () => myDataAnalytics.findMyCep(),
    [],
  );

  const restoreData = () => {
    setFormData(userOriginalFormData);
    setShowButtons(false);

    const isForeignerUser = summaryState.nationality !== 'BR';

    const formatCnpjCpf = value => (value.length === 11
      ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
      : value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5'));

    const document = isBRServer ? formatCnpjCpf(summaryState.document) : summaryState.document;

    if (((!isForeignerUser && isBRServer && document.length > 14) || (!isBRServer && summaryState.companyName))) {
      setIsCompany(true);
    }

    if (isForeignerUser) {
      setIsForeigner(true);
    }
  };

  const fieldValidations = useCallback((fieldName, fieldValue) => {
    if (!required(fieldValue)) {
      return 'required';
    }

    const validateDocument = (document) => {
      if (isBRServer) {
        if (!isForeigner && document.length <= 14) {
          setIsCompany(false);
          return !validateCpf(document) ? 'cpf' : false;
        }
        !isForeigner && setIsCompany(true);
        if (!isForeigner && !validateCnpj(document)) return 'cnpj';
      }
      return false;
    };

    const validateDate = (date) => {
      const isDate = parse(date, 'dd/MM/yyyy', new Date());
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(isDate)) {
        return true;
      }

      const birthDateMin = minDate(date, '01/01/1900');
      const birthDateMax = maxDate(date, format(new Date(), 'dd/MM/yyyy'));

      return !(birthDateMin && birthDateMax);
    };

    const validatePhone = (phoneNumber) => {
      if (summaryState.phoneNumberCountry === 'BR') {
        return !validatePhoneBR(phoneNumber);
      }
      return false;
    };

    const validatePostalCode = (postalCode) => {
      if (brand === 'BR') {
        return !validateCep(postalCode);
      }
      return !required(postalCode) ? 'required' : false;
    };

    const validateComplement = complement => isBRServer && hasSpecialCharacters(complement);

    const formValidationRules = {
      birthDate: validateDate,
      cep: validateCep,
      document: validateDocument,
      email: value => !validateEmail(value),
      name: value => !validatePersonName(value),
      companyName: value => !validateCompanyName(value),
      phone: validatePhone,
      postalCode: validatePostalCode,
      numberAndComplement: validateComplement,
      required: value => (!required(value) ? 'required' : false),
    };

    return formValidationRules[fieldName] ? formValidationRules[fieldName](fieldValue) : formValidationRules.required(fieldValue);
  }, [brand, isForeigner, summaryState]);

  const verifyFormErrorBeforeSubmit = useCallback(() => {
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      switch (key) {
        case 'address':
        case 'email':
        case 'name':
        case 'neighborhood':
        case 'numberAndComplement':
        case 'phone':
        case 'postalCode':
          if (fieldValidations(key, formData[key].value)) {
            hasError = true;
          }
          break;
        case 'document':
          if (isBRServer && fieldValidations(key, formData[key].value)) {
            hasError = true;
          }
          break;
        case 'companyName':
          if (isCompany) {
            if (fieldValidations(key, formData[key].value)) {
              hasError = true;
            }
          }
          break;
        case 'birthDate':
          if (!isCompany) {
            if (fieldValidations(key, formData[key].value)) {
              hasError = true;
            }
          }
          break;
        case 'birthCountry':
          if (isForeigner && isBRServer) {
            if (fieldValidations(key, formData[key].value.name)) {
              hasError = true;
            }
          }
          break;
        case 'city':
        case 'country':
        case 'state':
          if (fieldValidations(key, formData[key].value.name)) {
            hasError = true;
          }
          break;
        default:
      }
    });

    return hasError;
  }, [fieldValidations, formData, isCompany, isForeigner]);

  const validateField = useCallback((fieldName, fieldValue) => {
    const validationError = fieldValidations(fieldName, fieldValue);
    const newFormData = formData;

    if (fieldName === 'postalCode' && !validationError && isBRServer) {
      onLoadPostalCode();
    }

    if (validationError) {
      setDisableSubmit(true);
      newFormData[fieldName] = { ...newFormData[fieldName], error: myDataLocale.errors[typeof validationError === 'boolean' ? fieldName : validationError] };
    } else {
      newFormData[fieldName] = { ...newFormData[fieldName], error: '' };

      if (disableSubmit) {
        const stillHasError = verifyFormErrorBeforeSubmit();

        if (!stillHasError) {
          setDisableSubmit(false);
        }
      }
    }

    setFormData(() => ({ ...newFormData }));
  }, [
    disableSubmit,
    fieldValidations,
    formData,
    myDataLocale,
    onLoadPostalCode,
    verifyFormErrorBeforeSubmit,
  ]);

  const handleFormChange = useCallback((fieldName, fieldValue) => {
    if (!showButtons) {
      setShowButtons(true);
    }
    const newFormData = { ...formData };

    switch (fieldName) {
      case 'birthCountry':
      case 'country':
        newFormData[fieldName] = { ...newFormData[fieldName], value: summaryState.locationData.countries.find(contry => contry.id === fieldValue) };
        break;
      case 'numberAndComplement':
      case 'birthDate':
      case 'companyName':
      case 'document':
      case 'name':
      case 'phone':
      case 'neighborhood':
      case 'address':
        newFormData[fieldName] = { ...newFormData[fieldName], value: fieldValue };
        break;
      case 'state': {
        if (formData.country.value.abbreviation !== 'BR') {
          const statesArray = (summaryState.locationData && summaryState.locationData.states) || [];
          const state = statesArray.find(singleState => singleState.code === fieldValue) || { name: fieldValue, id: 0, abbreviation: '' };
          newFormData[fieldName] = { ...newFormData[fieldName], value: { ...state } };
        }
        break;
      }
      case 'city': {
        if (formData.country.value.abbreviation !== 'BR') {
          const citiesArray = (summaryState.locationData && summaryState.locationData.cities) || [];
          const city = citiesArray.find(singleCity => singleCity.name === fieldValue) || { id: 0, name: fieldValue };
          newFormData[fieldName] = { ...newFormData[fieldName], value: { ...city } };
        }
        break;
      }
      case 'postalCode':
        newFormData[fieldName] = { ...newFormData[fieldName], value: postalCodeMask[brand](fieldValue) };
        break;
      case 'knowledge':
      case 'mailSubscribe':
        newFormData[fieldName] = fieldValue;
        break;
      case 'foreigner':
        setIsForeigner(fieldValue);
        break;
      default:
    }
    if (newFormData[fieldName] && newFormData[fieldName].error) {
      const hasError = fieldValidations(fieldName, fieldValue);
      if (!hasError) {
        newFormData[fieldName] = { ...newFormData[fieldName], error: '' };
      }
    }
    setFormData(newFormData);
  }, [
    brand,
    fieldValidations,
    formData,
    showButtons,
    summaryState,
  ]);

  const handlePhoneChange = useCallback(() => {
    const currentUrl = history.location.pathname;
    dispatch(phoneNumberChangeFlow(true, currentUrl));
    myDataAnalytics.editPhone();
  }, [dispatch, history]);

  const handleEmailChange = useCallback(() => {
    const currentUrl = history.location.pathname;
    dispatch(emailChangeFlow(true, currentUrl));
    myDataAnalytics.editEmail();
  }, [dispatch, history]);

  const verifyCallbackTokenreCaptcha = token => setRecaptchaToken(token);

  const handleSubmitNewUserData = async (event) => {
    event.preventDefault();
    myDataAnalytics.submitChange();
    const hasFormErrors = verifyFormErrorBeforeSubmit();

    if (hasFormErrors) {
      setDisableSubmit(true);
      Object.keys(formData).forEach((key) => {
        if (key !== 'knowledge' && key !== 'mailSubscribe') {
          const keyValue = formData[key].value;
          validateField(key, keyValue);
        }
        if (key === 'city' || key === 'state' || key === 'country') {
          const keyValue = formData[key].value.name;
          validateField(key, keyValue);
        }
        if (key === 'birthCountry' && isForeigner && isBRServer) {
          const keyValue = formData[key].value.name;
          validateField(key, keyValue);
        }
      });
      return;
    }

    await generateRecaptchaToken(config.RECAPTCHA_SITE_KEY, 'mydatapage').then((token) => {
      verifyCallbackTokenreCaptcha(token);
    });

    if (!summaryState.loadingUpdateSummary) {
      const phoneNumber = newPhoneFlowEnabled
        ? summaryState.phoneNumber.replace(/\D/g, '')
        : formData.phone.value.replace(/\D/g, '');

      const newUserData = {
        address1: formData.address.value,
        address2: formData.neighborhood.value,
        address3: formData.numberAndComplement.value,
        birthDate: (formData.birthDate && formData.birthDate.value),
        city: formData.city.value.name,
        companyName: formData.companyName.value,
        country: formData.country.value.abbreviation,
        document: formData.document.value,
        email: newEmailFlowEnabled ? summaryState.email : formData.email.value,
        emailMarketingAgreement: formData.mailSubscribe,
        id: summaryState.id,
        name: formData.name.value,
        nationality: isForeigner ? formData.birthCountry.value.abbreviation : summaryState.nationality,
        persona: formData.knowledge,
        phoneNumber,
        phoneNumberCountry: summaryState.phoneNumberCountry,
        postCode: formData.postalCode.value,
        recaptcha: recaptchaToken,
        state: (formData.state.value && formData.state.value.code) || (formData.state.value && formData.state.value.name),
        stateName: (formData.state.value && formData.state.value.name) || '',
      };

      dispatch(updateSummary(newUserData)).then((res) => {
        if (res.type === RECEIVE_UPDATE_SUMMARY) {
          dispatch(commonActions.notifications.set({
            label: (
              <p>{myDataLocale.snackBar.updateSuccess}</p>
            ),
            type: 'success',
          }));

          dispatch(loadSummary());
        } else {
          if (res.data && (res.data.description === 'Duplicate Email Address')) {
            dispatch(commonActions.notifications.set({
              label: (
                <p>{myDataLocale.snackBar.emailAlreadyExists}</p>
              ),
              type: 'error',
            }));


            return;
          }
          dispatch(commonActions.notifications.set({
            label: (
              <p>{myDataLocale.snackBar.updateError}</p>
            ),
            type: 'error',
          }));
        }
      });
    }
  };

  const getPersonFieldsProps = useCallback(() => ({
    myDataLocale,
    isForeigner,
    handleFormChange,
    validateField,
    formData,
    countriesSelectOptions,
    setIsCompany,
    isCompany,
    newPhoneFlowEnabled,
    handlePhoneChange,
    newEmailFlowEnabled,
    handleEmailChange,
    knowledgeLevels,
    renderFlags,
    countryCode,
  }), [
    myDataLocale,
    isForeigner,
    handleFormChange,
    validateField,
    formData,
    countriesSelectOptions,
    setIsCompany,
    isCompany,
    newPhoneFlowEnabled,
    handlePhoneChange,
    newEmailFlowEnabled,
    handleEmailChange,
    knowledgeLevels,
    renderFlags,
    countryCode,
  ]);

  const getAddressFieldsProps = useCallback(() => ({
    myDataLocale,
    handleFormChange,
    formData,
    countriesSelectOptions,
    validateField,
    urlGetCep,
    findMyCepClick,
    postalCodeLength,
    brand,
  }), [
    myDataLocale,
    handleFormChange,
    formData,
    countriesSelectOptions,
    validateField,
    urlGetCep,
    findMyCepClick,
    brand,
  ]);

  useEffect(() => {
    dispatch(loadSummary());
  }, [dispatch]);

  useEffect(() => {
    // load user address data and populate form
    onLoadCountries(summaryState.country).then((countries) => {
      onLoadStatesByCountry(summaryState.country).then((states) => {
        onLoadCitiesByCountryAndState(summaryState.country, summaryState.state).then((cities) => {
          const countriesArray = (Array.isArray(countries.data) && countries.data) || summaryState.locationData.countries;
          const statesArray = (Array.isArray(states.data) && states.data) || summaryState.locationData.states;
          const citiesArray = (Array.isArray(cities.data) && cities.data) || summaryState.locationData.cities;
          const country = countriesArray && countriesArray.find(singleCountry => singleCountry.abbreviation === summaryState.country);
          const state = (statesArray && statesArray.find(singleState => singleState.code === summaryState.state)) || { name: summaryState.state, id: 0, abbreviation: '' };

          const city = (citiesArray && citiesArray.find(singleCity => singleCity.name === summaryState.city)) || { id: 0, name: summaryState.city };
          const birthCountry = countries.data && countries.data.find(singleCountry => singleCountry.abbreviation === summaryState.nationality);
          const isForeignerUser = summaryState.nationality !== 'BR';

          if (isForeignerUser) {
            setIsForeigner(true);
          }

          const formatCnpjCpf = value => (value.length === 11
            ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
            : value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5'));

          const document = isBRServer ? formatCnpjCpf(summaryState.document) : summaryState.document;

          if (((!isForeigner && isBRServer && document.length > 14) || (!isBRServer && summaryState.companyName))) {
            setIsCompany(true);
          }

          const isLatamBrand = country && LATAM_COUNTRIES.includes(country.abbreviation);


          const originalFormData = {
            ...formData,
            address: summaryState.address1 ? { value: summaryState.address1, error: '' } : formData.address,
            birthCountry: isForeignerUser && birthCountry ? { value: birthCountry, error: '' } : formData.birthCountry,
            birthDate: summaryState.birthDate ? { value: summaryState.birthDate, error: '' } : formData.birthDate,
            city: city && city.name ? { value: city, error: '' } : formData.city,
            country: country && country.name ? { value: country, error: '' } : formData.country,
            companyName: summaryState.companyName ? { value: summaryState.companyName, error: '' } : formData.companyName,
            document: document ? { value: document, error: '' } : formData.document,
            email: summaryState.email ? { value: summaryState.email, error: '' } : formData.email,
            knowledge: summaryState.persona,
            mailSubscribe: summaryState.emailMarketingAgreement,
            name: summaryState.firstname || summaryState.lastname ? { value: `${summaryState.firstname || ''} ${summaryState.lastname || ''}`, error: '' } : formData.name,
            neighborhood: summaryState.address2 ? { value: summaryState.address2, error: '' } : formData.neighborhood,
            numberAndComplement: summaryState.address3 ? { value: summaryState.address3, error: '' } : formData.numberAndComplement,
            phone: summaryState.phoneNumber ? { value: summaryState.phoneNumber, error: '' } : formData.phone,
            postalCode: summaryState.postCode ? { value: postalCodeMask[isLatamBrand ? country.abbreviation : 'other'](summaryState.postCode), error: '' } : formData.postalCode,
            state: state && state.name ? { value: state, error: '' } : formData.state,
          };

          setFormData(originalFormData);
          setUserOriginalFormData(originalFormData);
          setUserDataMapped(true);
        });
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDataMapped) {
      setFormData({
        ...formData,
        phone: { value: summaryState.phoneNumber, error: '' },
        email: { value: summaryState.email, error: '' },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryState.phoneNumber, summaryState.email, userDataMapped]);

  useEffect(() => {
    // mount select options
    if (summaryState.locationData.countries && summaryState.locationData.countries.length) {
      const countriesOptionsArray = summaryState.locationData.countries.map(country => ({
        value: country.id,
        label: country.name,
      }));

      setCountriesSelectOptions(countriesOptionsArray);
    }
  }, [summaryState.locationData.countries]);

  useEffect(() => {
    // search address by postal code
    const { addressesByZipCodeSearch, states } = summaryState.locationData;
    if (addressesByZipCodeSearch) {
      const {
        postCode, address1, address2, state, city,
      } = addressesByZipCodeSearch;

      if (postCode && state && states) {
        onLoadCitiesByCountryAndState(formData.country.value.abbreviation, state).then((cities) => {
          const citySearched = city && cities.data ? cities.data.find(singleCity => singleCity.name === city) : defaultFormData.city;
          const stateSearched = state ? states.find(singleState => singleState.code === state) : defaultFormData.state;

          setFormData(actualFormData => ({
            ...actualFormData,
            address: { value: address1, error: '' },
            neighborhood: { value: address2, error: '' },
            city: { value: citySearched, error: '' },
            state: { value: stateSearched, error: '' },
          }));
        });
      }
    }

    if (addressesByZipCodeSearch === null) {
      setFormData(actualFormData => ({
        ...actualFormData,
        address: { ...defaultFormData.address, error: myDataLocale.errors.required },
        neighborhood: { ...defaultFormData.neighborhood, error: myDataLocale.errors.required },
        city: { ...defaultFormData.city, error: myDataLocale.errors.required },
        postalCode: { ...defaultFormData.postalCode, error: myDataLocale.errors.postalCode },
        state: { ...defaultFormData.state, error: myDataLocale.errors.required },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryState.locationData.addressesByZipCodeSearch]);

  useEffect(() => {
    // if check/uncheck isForeigner revalidate it
    if (formData.document.value) {
      validateField('document', formData.document.value);
    }

    if (isForeigner) {
      setIsCompany(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForeigner]);

  const shouldRenderLoading = (summaryState.loadingZipCode || summaryState.loadingStates || summaryState.loadingCountries || summaryState.loadingCities);

  if (shouldRenderLoading) {
    return <MyDataSkeleton isBRServer={isBRServer} personalDataLabel={myDataLocale.title} addressLabel={myDataLocale.address} />;
  }

  return (
    <Styles.Container>
      <Styles.FormWrapper>
        <Styles.Form onSubmit={e => handleSubmitNewUserData(e)}>
          <Styles.Column>
            <PersonFields {...getPersonFieldsProps()} />
          </Styles.Column>

          <Styles.Column>
            <AddressFields {...getAddressFieldsProps()} />
          </Styles.Column>

          {!!showButtons && (
          <Styles.ButtonsWrapper>
            <Button
              label={myDataLocale.cancel}
              onClick={restoreData}
              variant="tertiary"
              width="fit-content"
              size="large"
            />
            <Button
              label={myDataLocale.save}
              width="fit-content"
              type="submit"
              loading={summaryState.loadingUpdateSummary}
              disabled={disableSubmit}
              size="large"
              testId="submit"
            />
          </Styles.ButtonsWrapper>
          )}
        </Styles.Form>

        <Styles.Row>
          <ReCaptcha
            action="mydatapage"
            sitekey={config.RECAPTCHA_SITE_KEY}
            verifyCallback={verifyCallbackTokenreCaptcha}
            elementID="g-recaptcha-my-data-page"
          />
        </Styles.Row>
      </Styles.FormWrapper>
    </Styles.Container>
  );
};

export default MyDataHandler;
