import React, { } from 'react';
import * as Styles from './TrialActiveBannerInSites.styles';
import { locale } from '@/utils/locale';
import { trialActive } from './TrialActiveBannerInSites.locale';

import envelope from '@/media/icons/envelope.svg';
import ProEmailActiveTrial from '@/components/Email/ProEmail/ProEmailsNewList/ProEmailActiveTrial';

const TrialActiveSiteBanner = ({ domainWithoutEmail }) => (
  <Styles.Wrapper>
    <Styles.BannerWrapper data-testid="banner-wrapper">
      <div style={{ display: 'flex' }}>
        <Styles.Figure>
          <img
            className="envelope"
            src={envelope}
            alt="Envelope"
            loading="lazy"
            data-testid="envelope-image"
          />
        </Styles.Figure>
        <Styles.ContentSection data-testid="content">
          <Styles.BannerTitle>
            <Styles.Title>
              {locale(trialActive.title)}
            </Styles.Title>

            <Styles.TagBanner>
              {locale(trialActive.tagOfTrialTime)}
            </Styles.TagBanner>
          </Styles.BannerTitle>
        </Styles.ContentSection>
      </div>
      <Styles.BannerDescription>
        <Styles.Description data-testid="description">
          {locale(trialActive.description)}
        </Styles.Description>
      </Styles.BannerDescription>
    </Styles.BannerWrapper>

    <ProEmailActiveTrial
      location="sites"
      renderedEmailList={domainWithoutEmail}
    />
  </Styles.Wrapper>

);
export default TrialActiveSiteBanner;
