import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    padding: 10px 0 0 16px;
    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 12px 0 0 5px;
    },
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxl};
    font-weight: ${theme.font.weights.bold};
    font-family: ${theme.font.family.secondary};
    letter-spacing: 0;
    margin: 0;
    opacity: 100%;
    padding-top: 8px;
    text-align: left;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: -3px;
    },
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.regular};
    font-family: ${theme.font.family.secondary};
    letter-spacing: 0;
    margin-top: 6px;
    margin: 0;
    opacity: 100%;
    padding-top: 12px;
    text-align: left;
    @media (max-width: ${theme.breakpoints.sm}) {
      font-weight: ${theme.font.weights.light};
      padding-top: 13px;
      text-align: justify;
    },
  `}
`;

export const Cards = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
    padding-top: 42px;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      justify-content: center;
      margin-left: -7px;
    },
  `}
`;

export const Card = styled.button`
  ${({ theme, selected }) => css`
    background-color: ${selected ? theme.color.lightBlue : theme.color.white};
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${theme.color.grayVeryLight};
    border-radius: 4px;
    padding: 12px;
    padding-bottom: 17px;
    width: 352px;
    height: 77px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: auto;
      padding: 12px;
      width: 312px;
    }
    &:hover {
      background-color: ${theme.color.lightBlue};
    }
  `}
`;

export const CardTitle = styled.div`
  ${({ theme }) => css`
    text-align: left;
    margin-right: 25px;
    color: ${theme.palette.grey.dark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.medium};
    padding-left: 3px;
    @media (max-width: ${theme.breakpoints.sm}) {
      padding-left: 1px;
    }
  `}
`;

export const CardSubTitle = styled.div`
  ${({ theme }) => css`
    text-align: left;
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.regular};
    font-family: ${theme.font.family.secondary};
    margin-left: 42px;
    display: flex;
    flex-direction: row;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: 6px;
      margin-left: 40px;
    }
  `}
`;

export const DomainDirector = styled.div`
  ${() => css`
    width: 110px;
  `}
`;


export const CardIcon = styled.img`
  ${() => css`
    height: 23px;
    padding: 3px 0 0 3px;
    margin-right: 15px;
  `}
`;

export const Action = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
  `}
`;

export const Button = styled.div`
    ${({ theme }) => css`
      display: flex;
      padding: 40px 0 24px 0;
      @media (max-width: ${theme.breakpoints.sm}) {
        padding: 30px 0 0 15px;
    },
  `}
`;
