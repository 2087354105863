import React, { useState, useEffect, useRef } from 'react';
import * as Styles from './DnsWizardExternalFormField.styles';
import { v1 } from '@/styles/HgThemeProvider';
import { IconEdit, IconCheck, IconClose } from '@/icons';
import { ERROR_MESSAGES } from '@/enums/domains/dns.enum';

function DnsWizardExternalFormField({
  testId = 'dnswizardexternalformfield',
  inputValue = 'ns01.godaddy.com',
  controlName = 'id01',
  label = 'Servidor 1',
  editing = false,
  setEditing = () => {},
  setValue = p => p,
  handleEdit = () => {},
  getMessage = p => p,
  focused,
}) {
  const [error, setError] = useState(false);
  const [nameServer, setNameServer] = useState(null);
  const [editingNameServer, setEditingNameServer] = useState(inputValue);
  const inputRef = useRef();

  const validateNameServer = (ns) => {
    const regex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(ns);
  };

  const validateNameServerInput = (ns) => {
    const regex = /[^-a-z0-9.]+/i;
    return !regex.test(ns);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const isNameServerValid = validateNameServer(value) && validateNameServerInput(value);
    setEditingNameServer(value);
    setError(isNameServerValid ? null : getMessage(ERROR_MESSAGES.INVALID_SERVER));
  };

  const handleToggleEdit = () => {
    if (!editing) {
      setEditingNameServer(nameServer);
      handleEdit();
    }
    setError(false);
    setEditing(!editing);
  };

  const handleSave = () => {
    if (editingNameServer === '') {
      setError(getMessage(ERROR_MESSAGES.REQUIRED));
      return;
    }
    if (!error) {
      setEditing(!editing);
      setNameServer(editingNameServer);
      setValue(editingNameServer);
    }
  };

  useEffect(() => {
    setNameServer(inputValue);
    if (focused) {
      setEditingNameServer(nameServer);
      inputRef.current.focus();
    }
  }, [inputValue, focused, setEditingNameServer, nameServer]);

  return (
    <Styles.Wrapper data-testId={testId}>
      <Styles.Label htmlFor={controlName}>{label}</Styles.Label>
      <Styles.EditWrapper visible={editing} data-testid="input-field">
        <Styles.EditFieldsWrapper>
          <Styles.Input id={controlName} value={editingNameServer} onChange={handleChange} ref={inputRef} />
          <Styles.IconWrapper>
            <Styles.SaveAndClose onClick={handleSave} data-testid="save-check">
              <IconCheck color={v1.color.line.fields} />
            </Styles.SaveAndClose>
            <Styles.SaveAndClose onClick={handleToggleEdit} data-testid="close-x">
              <IconClose color={v1.color.line.fields} />
            </Styles.SaveAndClose>
          </Styles.IconWrapper>
        </Styles.EditFieldsWrapper>
        {error && (
        <Styles.Error>
          {error}
        </Styles.Error>
        )}
      </Styles.EditWrapper>
      <Styles.NameServerWrapper visible={!editing} data-testid="nameserver-label">
        <Styles.NameServer>
          {nameServer}
        </Styles.NameServer>
        <Styles.Edit onClick={handleToggleEdit} data-testid="edit-pencil">
          <IconEdit />
        </Styles.Edit>
      </Styles.NameServerWrapper>
    </Styles.Wrapper>
  );
}

export default DnsWizardExternalFormField;
