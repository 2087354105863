import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
  padding: 0 40px 0px 40px;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 0px;
  }
`}
`;

export const MainTitleWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.white.light};
    display: flex;
    margin: 24px 0px;
    min-height: 60px;
    padding: 7px 24px;
  `}
`;

export const MainTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.color.greyDark};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.regular};
    margin: 0px;
  `}
`;
