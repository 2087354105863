import { collect } from '@/analytics';

const EVENTS = {
  NAME: 'gaEvent',
  CATEGORY: {
    BUY: 'Compra Codeguard',
    LANDINGPAGE: 'Ações landingpage',
  },
  LABEL: 'Landingpage Codeguard',
};

const cyclePaymentClick = (cycle) => {
  let actionGa = '';
  switch (cycle) {
    case 'annually':
      actionGa = 'Codeguard Anual';
      break;
    case 'semiannually':
      actionGa = 'Codeguard Semestral';
      break;
    case 'quarterly':
      actionGa = 'Codeguard Trimestral';
      break;
    case 'monthly':
      actionGa = 'Codeguard Mensal';
      break;
    default:
      break;
  }
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: actionGa,
    gaEventLabel: EVENTS.LABEL,
  });
};

const selectPaymentMethod = (paymentMethod) => {
  let actionGa = '';
  switch (paymentMethod) {
    case 'paypal':
    case 'gp_paypal':
      actionGa = 'Codeguard Paypal';
      break;
    case 'boleto':
    case 'gp_boleto':
      actionGa = 'Codeguard Boleto';
      break;
    case 'braspag_credit_1x':
      actionGa = 'Codeguard Cartão de crédito';
      break;
    default:
      break;
  }
  collect({
    event: EVENTS.NAME,
    gaEventCategory: EVENTS.CATEGORY.BUY,
    gaEventAction: actionGa,
    gaEventLabel: EVENTS.LABEL,
  });
};

const codeGuardBuy = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.BUY,
  gaEventAction: 'Codeguard Contrate agora',
  gaEventLabel: EVENTS.LABEL,
});

const seeAllCodeGuardPlansButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
  gaEventAction: 'Ver todos os planos Codeguard',
  gaEventLabel: EVENTS.LABEL,
});

const firstSectionButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
  gaEventAction: 'Ativar backup para meu site',
  gaEventLabel: EVENTS.LABEL,
});

const secondSectionButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
  gaEventAction: 'Quero proteger meu site',
  gaEventLabel: EVENTS.LABEL,
});

const thirdRowButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
  gaEventAction: 'Quero mais proteção',
  gaEventLabel: EVENTS.LABEL,
});

const finalBannerButtonClick = () => collect({
  event: EVENTS.NAME,
  gaEventCategory: EVENTS.CATEGORY.LANDINGPAGE,
  gaEventAction: 'Contrate agora (âncora)',
  gaEventLabel: EVENTS.LABEL,
});


const analytics = {
  cyclePaymentClick,
  selectPaymentMethod,
  codeGuardBuy,
  seeAllCodeGuardPlansButtonClick,
  firstSectionButtonClick,
  secondSectionButtonClick,
  thirdRowButtonClick,
  finalBannerButtonClick,
};

export default analytics;
