import React from 'react';
import * as Styles from './DnsWizardHeader.styles';
import { Tooltip } from '@/pages/common/v1/Tooltip';
import { IconInfo } from '@/icons';
import { palette } from '@/styles/HgThemeProvider';


const DnsWizardHeader = ({
  testId = 'iconheader', title = 'Title', tooltip, icon = null, variation = 'default',
}) => {
  const { secondary } = palette;
  const { primary: lightBlueColor } = secondary;
  return (
    <Styles.DnsWizardHeaderWrapper data-testid={testId}>
      {icon}
      <Styles.Title variation={variation}>
        {title}
        {tooltip && (<Tooltip content={tooltip} preferredPosition="bottom"><IconInfo color={lightBlueColor} /></Tooltip>)}

      </Styles.Title>
    </Styles.DnsWizardHeaderWrapper>
  );
};

export default DnsWizardHeader;
