const styles = theme => ({
  container: {
    margin: '0 40px 20px',
    [theme.breakpoints.down(960)]: {
      margin: '0 0 20px',
    },
  },
  alert: {
    padding: '13px 40px',
    display: 'flex',
    alignItems: 'center',
  },
  alertCritical: {
    backgroundColor: theme.color.valencia,
    color: theme.color.white,
  },
  alertImportant: {
    backgroundColor: theme.color.brightSun,
    color: theme.color.tundora,
  },
  icon: {
    marginRight: '20px',
  },
  message: {
    flex: 1,
    color: 'inherit',
  },
});

export default styles;
