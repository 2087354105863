import styled, { css } from 'styled-components';

export const PreviewWrapper = styled.div`
  ${({ withItems }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${withItems ? '0px' : '26px'};
  `}
`;

export const CreditCardRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      flex-direction: column;
      width: 100%;
    }
  `}
`;

export const ChangePaymentInfo = styled.div`
  ${({ theme }) => css`
    align-self: flex-start;
    border-radius: 4px;
    border: 1px solid ${theme.v2.colors.neutral.highMedium};
    display: flex;
    flex-direction: row;
    max-width: 736px;
    padding: 24px;
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    max-width: 133px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      display: none;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 24px;

    @media (max-width: ${theme.v2.breakpoints.sm}){
      margin: 0px;
    }
  `}
`;

export const ChangePaymentInfoTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 0px;
  `}
`;

export const ChangePaymentInfoDescription = styled.p`
  ${({ theme }) => css`
  color: ${theme.v2.colors.neutral.lowPure};
  font-family: ${theme.v2.font.family.primary};
  font-size: ${theme.v2.font.sizes.xxxs};
  font-weight: ${theme.v2.font.weights.regular};
  line-height: ${theme.v2.font.lineHeight.text};
  margin: 8px 0px 0px;
  `}
`;

export const ButtonWrapper = styled.div`
  margin: 30px 0px 0px;
`;

export const MobileButtonWrapper = styled.div`
  margin: 0px auto;
`;

export const CreditCardColumnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 26px;

    button {
      width: fit-content;

      @media (max-width: ${theme.v2.breakpoints.sm}){
        margin: 0px auto;
      }
    }
  `}
`;
