import { differenceInCalendarDays, parseISO } from 'date-fns';
import { PRODUCT_NAMES, SUBSCRIPTIONS_CANCEL_TYPE, SUBSCRIPTION_STATUS } from '@/enums/billing/billing.enum';
import { messagesLocale, supportLocale } from './useSubscriptionsMessage.locale';
import { formatDate } from '@/utils/Formatters/formatDate';
import { extend } from '@/utils/extend';
import { locale } from '@/utils/locale';
import { getInvoiceUrl, adaptPaymentMethodFromInvoice } from './useSubscriptionsMessage.utils';
import { CHAT_SUPPORT_URL } from '@/config/urls/supportUrls';
import { SITE_HG, COUNTRY } from '@/config';
import { useHistory } from 'react-router';


const defaultMessages = {

  [SUBSCRIPTION_STATUS.PENDING]: ({ substatus }) => ({
    status: substatus,
    text: null,
    url: null,
  }),
  [SUBSCRIPTION_STATUS.PENDING_ANALYSIS]: ({ substatus }, { enabledNewMessagesInSubscription }) => ({
    status: substatus,
    url: null,
    text: messagesLocale.pendingAnalysis(enabledNewMessagesInSubscription),
  }),
  [SUBSCRIPTION_STATUS.PENDING_PAYMENT]: ({ substatus }, { enabledNewMessagesInSubscription }) => ({
    status: substatus,
    url: null,
    text: messagesLocale.pendingPayment(enabledNewMessagesInSubscription),
  }),
  [SUBSCRIPTION_STATUS.ACTIVE]: (subscription) => {
    const {
      cancelType, cancelDate, dueDate, substatus,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (cancelType && (cancelDate || dueDate)) {
      message.status = 'error';
      message.text = messagesLocale.activeWithCancelType({
        cancelDate: formatDate(cancelDate || dueDate),
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.OVERDUE]: (subscription, {
    todayDate, enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment,
  }) => {
    const {
      dueDate, substatus, invoiceId,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    const daysToCancel = 10;
    const today = todayDate || new Date();
    const daysAfterOverdue = differenceInCalendarDays(today, parseISO(dueDate));
    const daysToExpiration = daysToCancel - daysAfterOverdue;
    const willExpire = daysAfterOverdue < daysToCancel;
    if (willExpire) {
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.overdue({
        dueDate: formatDate(dueDate),
        daysToExpiration,
        singularText: daysToExpiration > 1 ? '' : '-singular',
        invoiceUrl: getInvoiceUrl(invoiceId),
      }, enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment, subscription);
    } else {
      message.text = messagesLocale.overdueAndIsMoreThan10DaysLate({
        dueDate: formatDate(dueDate),
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.TO_DUE]: (subscription, { enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment }) => {
    const {
      cancelType, cancelDate, dueDate, invoiceId, paymentMethod, substatus,
    } = subscription;
    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (cancelType) {
      message.status = 'error';
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.toDueWithTypeCancel({
        dueDate: formatDate(cancelDate || dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
      });
    } else {
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.toDue({
        paymentMethod: adaptPaymentMethodFromInvoice(paymentMethod),
        dueDate: formatDate(cancelDate || dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
        enabledNewMessagesInSubscription: enabledNewMessagesInSubscription || false,
        enabledModalPaymentInSubscriptionList: enabledModalPaymentInSubscriptionList || false,
        onDisplayModalPayment,
        subscription,

      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.CANCELLED]: (subscription, { country, enabledNewMessagesInSubscription }) => {
    const { cancelDate, substatus } = subscription;
    const message = {
      status: substatus,
      url: null,
      text: messagesLocale.cancelled({
        cancelDate: formatDate(cancelDate),
        country: country || COUNTRY,
      }, { enabledNewMessagesInSubscription }),
    };

    if (!cancelDate) {
      subscription.productName === PRODUCT_NAMES.ECOMMERCE_PLUS
        ? message.text = messagesLocale.cancelledWithoutDateCancelEcommercePlus()
        : message.text = messagesLocale.cancelledWithoutDateCancel({
          siteUrl: SITE_HG,
        });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.INVOICE_CANCELLED]: (subscription, { country, enabledNewMessagesInSubscription }) => {
    const { cancelDate, substatus } = subscription;
    const message = {
      status: substatus,
      url: null,
      text: messagesLocale.cancelled({
        cancelDate: formatDate(cancelDate),
        country: country || COUNTRY,
      }, { enabledNewMessagesInSubscription }),
    };

    if (!cancelDate) {
      subscription.productName === PRODUCT_NAMES.ECOMMERCE_PLUS
        ? message.text = messagesLocale.cancelledWithoutDateCancelEcommercePlus()
        : message.text = messagesLocale.cancelledWithoutDateCancel({
          siteUrl: SITE_HG,
        });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.TERMINATED_CANCELLED]: (subscription) => {
    const {
      cancelType, cancelDate, substatus,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (cancelType === SUBSCRIPTIONS_CANCEL_TYPE.USER_REQUEST) {
      message.url = SITE_HG;
      subscription.productName === PRODUCT_NAMES.ECOMMERCE_PLUS
        ? message.text = messagesLocale.cancelledWithCancelTypeUserRequestEcommercePlus({
          canceledDate: formatDate(cancelDate),
        })
        : message.text = messagesLocale.cancelledWithCancelTypeUserRequest({
          cancelDate: formatDate(cancelDate),
          siteUrl: SITE_HG,
        });
    }

    if (cancelType === SUBSCRIPTIONS_CANCEL_TYPE.WHMCS_SUSPENSION) {
      message.url = SITE_HG;
      subscription.productName === PRODUCT_NAMES.ECOMMERCE_PLUS
        ? message.text = messagesLocale.cancelledWithCancelTypeWhmcsSuspensionEcommercePlus({
          cancelDate: formatDate(cancelDate),
        })
        : message.text = messagesLocale.cancelledWithCancelTypeWhmcsSuspension({
          cancelDate: formatDate(cancelDate),
          siteUrl: SITE_HG,
        });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.FRAUD_CANCELLED]: ({ substatus }, { country }) => ({
    status: substatus,
    url: null,
    text: messagesLocale.cancelledWithStatusFraud({ country: country || COUNTRY }),
  }),
  [SUBSCRIPTION_STATUS.SUSPENDED]: (subscription, {
    enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment, enableTrustReactivation,
  }) => {
    const {
      suspendedDate, substatus, invoiceId, category,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (enableTrustReactivation) {
      message.text = messagesLocale.default.suspended.standard({
        onDisplayModalPayment,
        subscription,
      });
    }

    if (!enableTrustReactivation) {
      if (suspendedDate) {
        message.url = getInvoiceUrl(invoiceId);
        message.text = messagesLocale.default.suspended.withDate({
          category,
          dateSuspended: formatDate(suspendedDate),
          invoiceUrl: getInvoiceUrl(invoiceId),
          enabledNewMessagesInSubscription: enabledNewMessagesInSubscription === true || false,
          enabledModalPaymentInSubscriptionList: enabledModalPaymentInSubscriptionList === true || false,
          onDisplayModalPayment,
          subscription,
        });
      } else {
        message.url = CHAT_SUPPORT_URL;
        message.text = messagesLocale.default.suspended.withoutDate({
          category,
          chatSupportUrl: CHAT_SUPPORT_URL,
        });
      }
    }

    return message;
  },
};

const domain = extend(defaultMessages, {
  [SUBSCRIPTION_STATUS.TO_DUE]: (subscription, { enabledNewMessagesInSubscription }) => {
    const {
      cancelType, cancelDate, dueDate, invoiceId, paymentMethod, substatus,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (cancelType) {
      message.status = 'error';
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.toDueWithTypeCancel({
        dueDate: formatDate(cancelDate || dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
      });
    } else {
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.toDue({
        paymentMethod: adaptPaymentMethodFromInvoice(paymentMethod),
        dueDate: formatDate(dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
        enabledNewMessagesInSubscription: enabledNewMessagesInSubscription === true || false,
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.OVERDUE]: (subscription, {
    todayDate, enabledNewMessagesInSubscription, enabledModalPaymentInSubscriptionList, onDisplayModalPayment,
  }) => {
    const {
      substatus, dueDate, invoiceId, cancelDate,
    } = subscription;

    const today = todayDate || new Date();
    const daysToExpiration = differenceInCalendarDays(parseISO(cancelDate), today);
    const willExpire = daysToExpiration > 0;

    const message = {
      status: substatus,
      url: null,
      text: null,
    };

    if (willExpire) {
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.domainOverdue({
        daysToExpiration,
        dueDate: formatDate(dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
        singularText: daysToExpiration <= 1 ? '-singular' : '',
        enabledNewMessagesInSubscription: enabledNewMessagesInSubscription === true || false,
        enabledModalPaymentInSubscriptionList: enabledModalPaymentInSubscriptionList === true || false,
        onDisplayModalPayment,
        subscription,
      });
    } else {
      message.url = getInvoiceUrl(invoiceId);
      message.text = messagesLocale.domainExpired({
        dueDate: formatDate(dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
        enabledNewMessagesInSubscription: enabledNewMessagesInSubscription === true || false,
        enabledModalPaymentInSubscriptionList: enabledModalPaymentInSubscriptionList === true || false,
        onDisplayModalPayment,
        subscription,
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.REGISTERED]: (subscription) => {
    const {
      cancelDate, cancelType, dueDate, substatus, id, type,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    const history = useHistory();
    const activeRenewPath = `${locale('routes.subscriptions')}${locale('routes.manageSubscription')}/${id}/${type}`;

    const handleRedirect = (event) => {
      event.preventDefault();
      history.push(activeRenewPath);
    };

    if (cancelType && (cancelDate || dueDate)) {
      message.status = 'error';
      message.url = activeRenewPath;
      message.text = messagesLocale.registeredWithCancelTypeUserRequest({
        cancelDate: formatDate(cancelDate || dueDate),
        activeRenew: handleRedirect,
        activeRenewPath,
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.EXPIRED_CANCELLED]: (subscription) => {
    const {
      cancelDate, cancelType, substatus,
    } = subscription;

    const message = {
      status: substatus,
      text: null,
      url: null,
    };

    if (cancelDate && cancelType === null) {
      message.url = SITE_HG;
      message.text = messagesLocale.domainCancelledForNonPayment({
        cancelDate: formatDate(cancelDate),
        siteUrl: SITE_HG,
      });
    }

    if (cancelDate && cancelType === SUBSCRIPTIONS_CANCEL_TYPE.USER_REQUEST) {
      message.url = SITE_HG;
      message.text = messagesLocale.domainCancelledByClient({
        cancelDate: formatDate(cancelDate),
        siteUrl: SITE_HG,
      });
    }

    return message;
  },
  [SUBSCRIPTION_STATUS.EXPIRED]: (subscription) => {
    const { dueDate, invoiceId } = subscription;

    const message = {
      status: 'error',
      url: getInvoiceUrl(invoiceId),
      text: messagesLocale.domainExpired({
        dueDate: formatDate(dueDate),
        invoiceUrl: getInvoiceUrl(invoiceId),
      }),
    };

    return message;
  },
  [SUBSCRIPTION_STATUS.FRAUD_CANCELLED]: ({ substatus }) => {
    const message = {
      status: substatus,
      url: supportLocale.wizard(),
      text: messagesLocale.domainCancelledByFraud({
        supportUrl: supportLocale.wizard(),
      }),
    };

    return message;
  },
  [SUBSCRIPTION_STATUS.TRANSFERRED_AWAY]: ({ substatus }) => ({
    status: substatus,
    url: null,
    text: messagesLocale.transferredAway(),
  }),
  [SUBSCRIPTION_STATUS.TRANSFERRED_AWAY_OLD]: ({ substatus }) => ({
    status: substatus,
    url: null,
    text: messagesLocale.transferredAway(),
  }),
});

const hosting = extend(defaultMessages, {});
const addon = extend(defaultMessages, {});

const rules = {
  domain,
  hosting,
  addon,
};

export default rules;
