import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import { withI18n } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TicketButtonLoader from '@/components/Tickets/TicketButtonLoader';
import OutlineButton from '@/components/Buttons/OutlineButton';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { saveFeedbackTicketDetail, changeTicketStatus } from '@/redux/actions/ticketDetail';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import SAVE_TICKET_FEEDBACK_ERROR from '@/redux/reducers/ticketDetail';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import BadImg from '@/media/icons/bad.png';
import GoodImg from '@/media/icons/good.png';
import styles from './styles';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class TicketFeedbackModal extends React.Component {
  state = {
    feedback: '',
    canRedirect: false,
    canSendFeedback: true,
  }

  handleGood = () => { this.setState({ feedback: 'good' }); }

  handleBad = () => { this.setState({ feedback: 'bad' }); }

  handleSendFeedback = () => {
    const { canSendFeedback, feedback } = this.state;
    const {
      ticketId,
      onSaveFeedbackTicketDetail,
      userEmail,
      handleClose,
      enqueueSnackbar,
      onChangeTicketStatus,
    } = this.props;

    if (canSendFeedback) {
      this.setState({ canSendFeedback: false });
      const data = {
        id: ticketId,
        score: feedback,
      };
      onSaveFeedbackTicketDetail(data, userEmail).then((res) => {
        handleClose();

        if (res.type === SAVE_TICKET_FEEDBACK_ERROR) {
          enqueueSnackbar('tickets.sendFeedbackError', notifierError);
        } else {
          enqueueSnackbar('tickets.sendFeedbackSuccess', notifierSuccess);
        }

        const ticket = { id: ticketId, status: 'closed' };
        onChangeTicketStatus(ticket, userEmail).then(() => {
          this.setState({ canRedirect: true });
        });
      });
    }
  }

  render() {
    const { canRedirect, feedback } = this.state;
    const {
      open,
      classes,
      handleClose,
      t,
      loadingStatusChange,
    } = this.props;

    if (canRedirect) {
      return <Redirect to={t('routes.help')} />;
    }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.wrapper }}
      >
        <DialogContent className={classes.dialog}>
          <div className={classes.close}>
            <Close onClick={handleClose} />
          </div>

          <p className={classes.title}>{t('tickets.attendance')}</p>

          <div className={classes.btns}>
            <OutlineButton className={`${classes.btn} ${feedback === 'good' ? classes.btnActive : ''}`} onClick={this.handleGood}>
              <img src={GoodImg} alt={t('tickets.good')} />
              {t('tickets.good')}
            </OutlineButton>
            <OutlineButton className={`${classes.btn} ${feedback === 'bad' ? classes.btnActive : ''}`} onClick={this.handleBad}>
              <img src={BadImg} alt={t('tickets.good')} />
              {t('tickets.bad')}
            </OutlineButton>
          </div>
        </DialogContent>

        <div className={classes.dialogActions}>

          <PrimaryButton className={classes.fillBtn} onClick={this.handleSendFeedback} disabled={feedback === ''}>
            {loadingStatusChange ? <TicketButtonLoader /> : t('tickets.sendFeedback') }
          </PrimaryButton>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  userEmail: state.summary.email,
  ticketId: state.ticketDetail.id,
  loadingStatusChange: state.ticketDetail.loadingStatusChange,
});

const mapDispatchToProps = dispatch => ({
  onSaveFeedbackTicketDetail: (data, userEmail) => dispatch(saveFeedbackTicketDetail(data, userEmail)),
  onChangeTicketStatus: (data, userEmail) => dispatch(changeTicketStatus(data, userEmail)),
  enqueueSnackbar,
});

export default withI18n()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)((TicketFeedbackModal))));
