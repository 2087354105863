import React from 'react';
import { Modal } from '@/pages/common';
import * as Styles from './BackupModal.styles';
import { Button, IconInfo } from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { useDispatch, useSelector } from 'react-redux';
import { productsActions } from '@/redux/modules';
import Loader from '@/components/Layout/Loader';
import { useHistory } from 'react-router';
import { backupAnalytics } from '@/analytics';

const BackupModal = ({
  externalId,
  onClose,
}) => {
  const { cPanelLoading } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const history = useHistory();
  const { backup: backupLocale, routes: routesLocale } = useLocale();

  const handleClose = (willGoToBackup) => {
    onClose && onClose(willGoToBackup);
  };

  const goToCPanel = () => {
    dispatch(productsActions.cPanelSSO.requestLink({ hostingId: externalId, backup: true }));
    backupAnalytics.modalGoToCpanel();
  };

  const goToBackup = () => {
    backupAnalytics.modalGoToBackup();
    history.push(`${routesLocale.products}${routesLocale.productdetail}/${externalId}${routesLocale.backup}`);
    handleClose(true);
  };

  return (
    <Modal onClose={handleClose}>
      <Styles.Title>{backupLocale.modal.title}</Styles.Title>
      <Styles.Description>
        {backupLocale.modal.description()}
      </Styles.Description>

      <Styles.AlertWrapper>
        <IconInfo color="primaryBlue" />
        <Styles.AlertDescription>
          {backupLocale.modal.alertDescription()}
        </Styles.AlertDescription>
      </Styles.AlertWrapper>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          onClick={goToCPanel}
          label={cPanelLoading ? <Loader size="12" /> : backupLocale.modal.buttonLabelCpanel}
          variant="secondary"
          disabled={cPanelLoading}
        />
        <Button
          size="large"
          onClick={goToBackup}
          label={backupLocale.modal.buttonLabelPortal}
          disabled={cPanelLoading}
        />
      </Styles.ButtonWrapper>
    </Modal>
  );
};

export default BackupModal;
