/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';
import { loadScript } from '@/utils/ThirdParties/loadUniqueScripts';
import { notifierError, notifierSuccess } from '@/utils/Application/notifier';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { PAYPAL_CHECKOUT_SCRIPT_URL } from '@/config/externalScriptsUrl';
import {
  createAgreementToken,
  createAgreement,
  paymentAgreement,
  cancelAgreement,
  cancelAgreementWithBody,
} from '@/redux/actions/paypal';
import { ERROR_PAYPAL_PAYMENT_AGREEMENT } from '@/redux/actions/actionsTypes';
import Loader from '@/components/Layout/Loader';
import { config, IS_PROD } from '@/config';


const PaypalButton = ({
  isAgreement,
  invoiceId,
  onSuccess,
  onError,
  onSetLoadingPaypalScript,
  onLoadingPaypalScript,
  switchPaymentMethod,
  isCreateNewAccount,
}) => {
  const dispatch = useDispatch();
  const ERROR_GATORPAY_PAYPAL_AGREEMENT = 'gatorpay.paypal.agreement_cancelled';

  const URL_PAYPAL = IS_PROD ? 'https://www.paypal.com/cgi-bin/webscr?cmd=_logout' : 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_logout';

  const handleCancelAgreement = () => (dispatch(cancelAgreement()));

  const handleValidateAgreement = (data) => {
    if (isCreateNewAccount) {
      data.enable();
      return true;
    }
    if (isAgreement) {
      data.disable();
      return false;
    }
    data.enable();
    return true;
  };


  const handlePayment = async () => {
    const data = await dispatch(paymentAgreement(invoiceId));
    if (data.type === ERROR_PAYPAL_PAYMENT_AGREEMENT) {
      if (data.message === ERROR_GATORPAY_PAYPAL_AGREEMENT) {
        dispatch(enqueueSnackbar('snackbar.paypal.error.agreement_cancelled', notifierError));
        handleCancelAgreement();
        onError();
        return;
      }
      dispatch(enqueueSnackbar('snackbar.paypal.error.service_error', notifierError));
      return;
    }
    dispatch(enqueueSnackbar('snackbar.paypal.success', notifierSuccess));

    onSuccess();
  };

  const handleSwitchPaymentMethod = () => {
    switchPaymentMethod();
  };

  const handleOnClick = async () => {
    if (isAgreement && !isCreateNewAccount) {
      if (!switchPaymentMethod) {
        handlePayment();
      }
      switchPaymentMethod && switchPaymentMethod();
    }
  };


  const handleCreateAgreementToken = () => (dispatch(createAgreementToken()).then(value => value.tokenId));

  const handleCreateAgreement = (data) => {
    if (isCreateNewAccount) {
      return dispatch(cancelAgreementWithBody()).then(() => {
        dispatch(createAgreement(data.billingToken)).then(() => {
          if (!switchPaymentMethod) {
            handlePayment();
          }
          switchPaymentMethod && handleSwitchPaymentMethod();
        });
      });
    }
    return dispatch(createAgreement(data.billingToken)).then(() => {
      if (!switchPaymentMethod) {
        handlePayment();
      }
      switchPaymentMethod && handleSwitchPaymentMethod();
    });
  };

  const handleButton = () => {
    window.paypal.Button.render({
      locale: config.LANG.paypal,
      style: {
        color: 'blue',
        shape: 'rect',
        size: 'responsive',
        label: 'pay',
        tagline: false,
        height: 36,
      },
      env: config.PAYPAL_ENVIRONMENT,
      validate: handleValidateAgreement,
      onClick: handleOnClick,
      payment: handleCreateAgreementToken,
      onAuthorize: handleCreateAgreement,
    }, '#paypal-container-button');
  };

  const handlePaypalFlow = () => {
    if (window.paypal === undefined) {
      onSetLoadingPaypalScript('loading');
      loadScript({ id: 'paypal-objects-checkout', src: PAYPAL_CHECKOUT_SCRIPT_URL }).then(() => {
        handleButton();
        onSetLoadingPaypalScript('finish');
      });
    } else {
      handleButton();
      onSetLoadingPaypalScript('finish');
    }
  };

  useEffect(() => {
    handlePaypalFlow();
  }, []);

  return (
    <>
      {onLoadingPaypalScript === 'loading' && <Loader size={20} />}
      <div id="paypal-container-button" />
      {isCreateNewAccount && (
        <iframe src={URL_PAYPAL} title="pp" width={0} height={0} style={{ zIndex: -1 }} />
      )}
    </>
  );
};


PaypalButton.propTypes = {
  isAgreement: PropTypes.bool.isRequired,
  invoiceId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSetLoadingPaypalScript: PropTypes.func.isRequired,
  onLoadingPaypalScript: PropTypes.func.isRequired,
  switchPaymentMethod: PropTypes.func,
  isCreateNewAccount: PropTypes.bool,
};

export default withI18n()(PaypalButton);
