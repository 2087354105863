import styled, { css } from 'styled-components';

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.v2.colors.white.primary};
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 32px 24px;
  `}
`;

export const DomainName = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryExtraDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex: 1 1 100%;
      order: 1;
    }
  `}
`;

export const TagWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex: 1 1 100%;
      order: 0;
    }
  `}
`;

export const LeftColumn = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-left: auto;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      flex: 1 1 100%;
      justify-content: space-between;
      margin: 0px;
      order: 3;
    }
  `}
`;

export const ToggleWrapper = styled.span`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowMedium};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 16px;
    height: 24px;
    line-height: ${theme.v2.font.lineHeight.text};
  `}
`;

export const ToggleText = styled.span`
  margin-top: -6px;
`;

export const ItemDescription = styled.span`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowMedium};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      order: 2;
    }
  `}
`;

export const TooltipContentWrapper = styled.span`
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    flex-direction: row;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.regular};
    gap: 16px;
    line-height: ${theme.v2.font.lineHeight.text};
    max-width: 230px;

    strong {
      display: inline-flex;
    }
  `}
`;

export const DisabledManagerTooltipContentWrapper = styled(TooltipContentWrapper)`
  align-items: flex-end;
  gap: 8px;
`;

export const ActiveTooltipContentWrapper = styled(TooltipContentWrapper)`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
    max-width: 210px;

    svg {
      flex: 0 0 32px;
    }
  `}
`;

export const Image = styled.img`
  transform: scaleX(-1);
  width: 44px;
`;

export const ToggleLabel = styled.label`
`;
