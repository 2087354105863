import React, { useContext } from 'react';
import { Modal, Radio } from '@/pages/common';
import { Button } from '@/pages/common/v1';
import * as Styles from './OverwriteSiteModal.styles';
import { IconError } from '@/icons';
import { ThemeContext } from 'styled-components';

const OverwriteSiteModal = ({
  title = 'Modal title',
  checkboxLabel = 'Checkbox label',
  continueButtonLabel = 'Continue label',
  backButtonLabel = 'Back label',
  maxWidth = '624',
  continueToOverwrite = false,
  selectedDomain = null,
  onClose,
  warningDomain,
  warningLink,
  toggleContinueToOverwrite,
  cancelOverwrite,
  onContinue,
  continueToOverwriteAnalytics,
  closeOverwriteAnalytics,
  goBackOverwriteAnalytics,
  checkboxOverwriteAnalytics,
  materialOverwriteAnalytics,
}) => {
  const theme = useContext(ThemeContext);
  const { color } = theme.v1;

  const handleChangeCheckbox = () => {
    checkboxOverwriteAnalytics && checkboxOverwriteAnalytics();
    toggleContinueToOverwrite && toggleContinueToOverwrite();
  };

  const handleCancel = () => {
    goBackOverwriteAnalytics && goBackOverwriteAnalytics();
    cancelOverwrite && cancelOverwrite();
    onClose && onClose();
  };

  const handleClose = () => {
    closeOverwriteAnalytics && closeOverwriteAnalytics();
    cancelOverwrite && cancelOverwrite();
    onClose && onClose();
  };

  const handleContinue = () => {
    continueToOverwriteAnalytics && continueToOverwriteAnalytics();
    onContinue && onContinue();
  };

  const handleClickMaterials = () => {
    materialOverwriteAnalytics && materialOverwriteAnalytics();
  };

  return (
    <Modal testId="modal-others-site-types" onClose={handleClose} disablePadding maxWidth={maxWidth}>
      <Styles.Title data-testid="other-site-types-title">
        {title}
      </Styles.Title>

      <Styles.WarningWrapper>
        <IconError color={color.warning.error} size={26} />
        <Styles.Warning>{selectedDomain && warningDomain(selectedDomain.label)}</Styles.Warning>
      </Styles.WarningWrapper>

      <Styles.SecondWarningWrapper>
        <Styles.Warning>{warningLink(handleClickMaterials)}</Styles.Warning>
      </Styles.SecondWarningWrapper>

      <Styles.CheckboxWrapper htmlFor="confirmationRadio" onClick={() => handleChangeCheckbox()} data-testid="radioLabel">
        <Radio value={checkboxLabel} checked={continueToOverwrite} id="confirmationRadio" />
        {checkboxLabel}
      </Styles.CheckboxWrapper>

      <Styles.ActionWrapper>
        <Button variant="text" label={backButtonLabel} onClick={handleCancel} testId="backButton" />
        <Button label={continueButtonLabel} disabled={!continueToOverwrite} onClick={handleContinue} testId="continueButton" />
      </Styles.ActionWrapper>

    </Modal>
  );
};

export default OverwriteSiteModal;
