import {
  ERROR_COUNTRY_CODE,
  RECEIVE_COUNTRY_CODE,
  REQUEST_COUNTRY_CODE,
} from '@/redux/actions/actionsTypes';


const initialState = {
  loadingContries: false,
  countryCode: [
    {
      name: 'BRAZIL',
      code: 55,
      short_name: 'BR',
    },
    {
      name: 'MEXICO',
      code: 52,
      short_name: 'MX',
    },
    {
      name: 'CHILE',
      code: 56,
      short_name: 'CL',
    },
    {
      name: 'COLOMBIA',
      code: 57,
      short_name: 'CO',
    },
  ],
};

export default function countryPhoneCodes(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COUNTRY_CODE:
      return {
        ...state,
        loadingContries: true,
      };
    case RECEIVE_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.data,
        loadingContries: false,
      };
    case ERROR_COUNTRY_CODE:
      return {
        ...state,
        loadingContries: false,
      };
    default:
      return state;
  }
}
