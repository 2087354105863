import { WHMCS_URL, COUNTRY } from '@/config';
import { WORDPRESS_PRODUCT_ID } from '@/config/products/wordpress';


export const registrobrActions = [{
  name: 'alterDns',
  label: 'Configurar Domínio',
  link: '/produtos/alterar-dns/',
},
{
  name: 'domainConfigurationWizard',
  label: 'domainConfigurationWizard',
  link: '',
},
{
  name: 'editContact',
  label: 'Editar Infos Contato',
  link: `${WHMCS_URL}/clientarea.php?action=domaincontacts&domainid=`,
},
{
  name: 'renewDomain',
  label: 'Renovar Domínio',
  link: `${WHMCS_URL}/cart.php?gid=renewals`,
},
{
  name: 'manageSubscription',
  label: 'manageSubscription',
  link: '',
}];

export const intResellerActions = [{
  name: 'alterDns',
  label: 'Configurar Dominio',
  link: '/produtos/alterar-dns/',
},
{
  name: 'domainConfigurationWizard',
  label: 'domainConfigurationWizard',
  link: '',
},
{
  name: 'editContact',
  label: 'Editar Infos Contato',
  link: `${WHMCS_URL}/clientarea.php?action=domaincontacts&domainid=`,
},
{
  name: 'renewDomain',
  label: 'Renovar Domínio',
  link: `${WHMCS_URL}/cart.php?gid=renewals`,
},
{
  name: 'protectId',
  label: 'Protect ID',
  link: `${WHMCS_URL}/clientarea.php?action=domaindetails&id=`,
},
{
  name: 'registrationLock',
  label: 'Trava de registro',
  link: `${WHMCS_URL}/clientarea.php?action=domaindetails&id=`,
},
{
  name: 'manageSubscription',
  label: 'manageSubscription',
  link: '',
}];

export const handleCheckEmailSso = (id, email, packageId) => {
  const isWordPressHosting = WORDPRESS_PRODUCT_ID.includes(packageId);

  if (isWordPressHosting && COUNTRY === 'br') {
    return `${WHMCS_URL}/custom_includes/wordpress/endpoint.php?action=ssoWebmail&hostingid=${id}&email=${email}`;
  }
  return `${WHMCS_URL}/clientarea.php?action=productdetails&id=${id}&modop=custom&a=management&page=emails&act=emailSSO&email=${email}`;
};
