import styled, { css } from 'styled-components';

export const FormContent = styled.div`
`;

export const FormTtitle = styled.h4`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.featured};
      font-family: ${theme.v1.font.family.primary};
      font-size: 24px;
      font-weight: ${theme.v1.font.weights.bold};
      line-height: 32.4px;
      margin: 32px 0px 8px 0px;
    `}
`;

export const FormDescription = styled.p`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 19px;
      margin: 0px 0px 24px 0px;
    `}
`;

export const Question = styled.p`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.medium};
      line-height: 19px;
      margin: 36px 0px 16px 0px;
    `}
`;

export const StepperWrapper = styled.div`
    margin: 0 auto;
    width: fit-content;
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media(max-width: ${theme.v1.breakpoints.md}){
      gap: 8px;
    }
  `}
`;

export const RadioWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 0px 0px 16px 0px;

    @media(max-width: ${theme.v1.breakpoints.md}){
      flex-direction: column;
      gap: 8px;

      > div {
        flex: 0;
      }
    }
  `}
`;

export const ButtonWrapper = styled.div`
    margin: 24px auto;
    max-width: fit-content;
`;

export const AccessWrapper = styled.div`
    ${({ theme }) => css`
      color: ${theme.v1.color.text.primary};
      font-family: ${theme.v1.font.family.primary};
      font-size: 14px;
      font-weight: ${theme.v1.font.weights.regular};
      line-height: 19px;
      margin: 0px auto;
      max-width: fit-content;

      a { 
        color: ${theme.v1.color.action.primary};
        font-weight: ${theme.v1.font.weights.medium};
      }
    `}
`;

export const LabelError = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.warning.error};
    font-family: ${theme.v1.font.family.primary};
    font-size: 12px;
    margin: 8px 12px 0;
    text-align: left;
  `}
`;
