import emailVerifyBR from './emailVerify.br';
import emailVerifyCL from './emailVerify.cl';
import emailVerifyCO from './emailVerify.co';
import emailVerifyMX from './emailVerify.mx';

const locales = {
  BR: emailVerifyBR,
  CL: emailVerifyCL,
  CO: emailVerifyCO,
  MX: emailVerifyMX,
};

export default locales;
