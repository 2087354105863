const styles = theme => ({
  planContainer: {
    backgroundColor: theme.color.white,
    padding: 24,
  },

  containerTitle: {
    color: theme.color.tundora,
    fontSize: '24px',
    letterSpacing: '0.01px',
    lineHeight: '29px',
    marginBottom: '6px',
    maxWidth: '829px',
  },

  description: {
    color: theme.color.tundora,
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginBottom: 12,
    maxWidth: '829px',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down(758)]: {
      flexWrap: 'wrap',
    },
  },

  managementLinkButton: {
    backgroundColor: theme.color.indigo,
    border: `1px solid ${theme.color.chambray}`,
    borderRadius: '2px',
    color: theme.color.white,
    fontSize: '14px',
    fontWeight: '500',
    letterSpaceing: '0.01px',
    lineHeight: '17px',
    padding: '10px 20px',
    textDecoration: 'none',
    transition: '300ms',

    '&:hover': {
      backgroundColor: theme.color.chambray,
    },
  },

  changePlanLink: {
    color: theme.color.indigo,
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.01px',
    lineHeight: '17px',
    marginTop: '24px',
    textDecoration: 'underline',

    [theme.breakpoints.down(361)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '225px',
      textAlign: 'center',
    },
  },
});
export default styles;
