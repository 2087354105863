export const DOMAIN_STATUS = {
  REGISTERED_DEFAULT: 'registered_default',
  EXPIRING_AUTO_RENEWAL_ENABLED: 'expiring_auto_renewal_enabled',
  EXPIRING_AUTO_RENEWAL_DISABLED: 'expiring_auto_renewal_disabled',
  OVERDUE_AUTO_RENEWAL_DISABLED: 'overdue_auto_renewal_disabled',
  OVERDUE_AUTO_RENEWAL_ENABLED: 'overdue_auto_renewal_enabled',
  EXPIRED_AUTO_RENEWAL_DISABLED: 'expired_auto_renewal_disabled',
  EXPIRED_AUTO_RENEWAL_ENABLED: 'expired_auto_renewal_enabled',
  EXPIRED_GRACE_PERIOD_OVERDUE: 'expired_grace_period_overdue',
  CANCELED_RECOVERABLE: 'canceled_recoverable',
  CANCELED_UNRECOVERABLE: 'canceled_unrecoverable',
  CANCELED_BY_TOS_ABUSE: 'canceled_by_tos_abuse',
};

export const INITIAL_VISIBLE_DOMAINS = 5;

export const INVOICE_TYPE = {
  REGISTRATION: 'registration',
  RENEWAL: 'renewal',
  RECOVERY: 'recovery',
};

export const INVOICE_STATUS = {
  CANCELLED: 'Cancelled',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
};

export const PROPAGATION_STATUS = {
  FAIL_TO_CHECK: 'fail_to_check',
  IN_PROGRESS: 'in_progress',
  NOT_PROPAGATED: 'not_propagated',
  PROPAGATED: 'propagated',
  NOT_CONTRACTED: 'not_contracted',
  OUT_OF_RANGE: 'out_of_range',
  NOT_CONFIGURED: 'not_configured',
};

export const DOMAIN_NOT_CONFIGURED_STEPS = {
  SELECT_DOMAIN: 1,
  SELECT_PROVIDER: 2,
  CONFIGURATION: 3,
  STATUS: 4,
};
