const stylesheet = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#2D2D2D',
    display: 'inline-block',
  },
  name: {
    fontSize: 36,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#2D2D2D',
    marginTop: 0,
    marginBottom: 30,
  },
  subtitle: {
    color: '#A7A7A7',
    fontSize: 20,
  },
  course: {
    fontSize: 38,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#2D2D2D',
    marginBottom: 5,
  },
  hours: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#2D2D2D',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#A7A7A7',
    margin: '5px 0',
    opacity: '0.5',
  },
  dataValue: {
    textAlign: 'center',
    color: '#A7A7A7',
    fontSize: 18,
  },
  dataText: {
    textAlign: 'center',
    color: '#2D2D2D',
    fontSize: 18,
  },
  signatureWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  signatureText: {
    fontSize: 13,
    color: '#A7A7A7',
    textAlign: 'center',
  },
  background: {
    position: 'absolute',
    width: '864.57px',
    height: '609.45px',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: '0 auto',
  },
  container: {
    position: 'absolute',
    width: '804.57px',
    height: '549.45px',
    backgroundColor: 'white',
    top: '30px',
  },
  snappy: {
    position: 'absolute',
    width: '350px',
    height: '373px',
    bottom: 0,
    left: 0,
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0 auto',
    padding: '70px 0 30px 0',
  },
  collabplayLogo: {
    width: '210px',
    height: '34px',
  },
  footerLeft: {
    width: '240px',
    paddingRight: '90px',
  },
  footerCenter: {
    width: '240px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  footerRight: {
    width: '240px',
    paddingLeft: '90px',
  },
  hostgatorLogo: {
    width: '140px',
    height: '21x',
    marginBottom: 25,
  },
  signature: {
    width: '100px',
    height: '22px',
  },
  footer: {
    width: '100%',
    padding: '0 30px',
    marginTop: 40,
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    flexDirection: 'row',
  },
  certificate: {
    width: '100%',
  },
};

export default stylesheet;
