import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Tag } from '@/pages/common/Tag';
import { Message } from '@/pages/common/Message';
import * as Styles from './RefundFeedback.styles';

const RefundFeedback = ({
  titleLabel = 'Refund',
  statusLabel = 'Status',
  status = 'pending',
  statusText = 'Pending',
  valueLabel = 'Value',
  valueText = '$ 10,00',
  methodLabel = 'Refund Method',
  methodText = 'Credit Card',
  messageText = '',
}) => {
  const theme = useContext(ThemeContext);

  const colorStatus = {
    pending: theme.palette.yellow.main,
    accomplished: theme.palette.success.primary,
  };

  return (
    <Styles.Wrapper data-testid="refund-feedback">
      <Styles.Title data-testid="refund-feedback-title">
        {titleLabel}
      </Styles.Title>
      <Styles.Info>
        <Styles.InfoItem>
          <Styles.Label
            data-testid="refund-feedback-status-label"
          >
            { statusLabel }
          </Styles.Label>

          <Styles.Text
            data-testid="refund-feedback-status-text"
          >
            <Tag
              label={statusText}
              bgColor={colorStatus[status.toLowerCase()]}
            />
          </Styles.Text>
        </Styles.InfoItem>
        <Styles.InfoItemSecondary>
          <Styles.Label
            data-testid="refund-feedback-value-label"
          >
            { valueLabel }
          </Styles.Label>
          <Styles.Text
            data-testid="refund-feedback-value-text"
          >
            { valueText }
          </Styles.Text>
        </Styles.InfoItemSecondary>
        <Styles.InfoItemSecondary data-testid="refund-feedback-method">
          <Styles.Label
            data-testid="refund-feedback-method-label"
          >
            { methodLabel }
          </Styles.Label>
          <Styles.Text
            data-testid="refund-feedback-method-text"
          >
            { methodText }
          </Styles.Text>
        </Styles.InfoItemSecondary>
        { messageText && (
          <Styles.InfoItemCentered data-testid="refund-feedback-alert">
            <Message
              testId="refund-feedback-alert-message"
              text={messageText}
              borderColor={colorStatus[status.toLowerCase()]}
              variant="small"
            />
          </Styles.InfoItemCentered>
        )}
      </Styles.Info>
    </Styles.Wrapper>
  );
};

export default RefundFeedback;
