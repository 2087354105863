import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const VerticalContainer = styled(Container)`
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      font-size: ${theme.v2.font.sizes.sm};
    }
  `};
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.regular};
    margin: 0;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.brand.primaryExtraDark};
    border-radius: 8px;
    color: ${theme.v2.colors.white.primary};
    padding: 24px;
  `};
`;

export const SignSitelock = styled(Container)`
  ${({ theme }) => css`
    gap: 32px;
    justify-content: space-between;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      flex-direction: column;
    }
  `};
`;

export const SignatureInfos = styled(VerticalContainer)`
  gap: 8px;
  max-width: 580px;
`;

export const SubscriptTitleContainer = styled(Container)`
  align-items: center;
  gap: 16px;
`;

export const SitelockLogo = styled.img`
  filter: brightness(0) invert(1);
  height: 24px;
  user-select: none;
  -webkit-user-drag: none;
`;

export const SubscriptTag = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryDark};
    background: ${theme.v2.colors.feedback.attentionLight};
    border-radius: 20px;
    font-size: ${theme.v2.font.sizes.xxxs};
    font-weight: ${theme.v2.font.weights.bold};
    text-align: center;
    padding: 2px 10px;
    user-select: none;
    -webkit-user-drag: none;
  `};
`;

export const SitelockText = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xxs};
  `};
`;

export const SignatureContainer = styled(Container)`
  ${({ theme }) => css`
    align-items: center;
    position: relative;
    user-select: none;
    -webkit-user-drag: none;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      justify-content: space-between;
    }
  `};
`;

export const IconTriangleWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: -8px;
    margin-right: -8px;
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-top: 0;
      margin-right: 0;
      position: static;
      right: auto;
      top: auto;
    }
  `};
`;
