/* eslint-disable camelcase */
import { api } from '@/utils/api';
import { useSelector } from 'react-redux';
import { COUNTRY } from '@/config';

function useVerifyDomainNative() {
  const { jwt: jwtToken } = useSelector(state => state.auth);
  const { id: userId } = useSelector(state => state.summary);

  const domainNative = async (domain) => {
    let isNative = false;

    try {
      const request = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'X-Client-ID': userId,
          'X-Brand': COUNTRY,
        },
        url: `/verify/registration?domain=${domain}&brand=${COUNTRY}`,
      };

      await api.aws.get(request).then((response) => {
        const { is_hosted_on_hostgator } = response.data;
        isNative = is_hosted_on_hostgator;
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('domain native compare error', e);
    }
    return isNative;
  };

  return { domainNative };
}


export default useVerifyDomainNative;
