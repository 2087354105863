import React, { useState, useEffect } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, OutlinedInput, withStyles,
} from '@material-ui/core';
import { Trans, withI18n } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles';


const SelectField = ({
  classes,
  filterOptions,
  handleChangeSelectValue,
  inputLabel,
  labelWidth,
  menuItemDefaultText,
  selectType,
  selectValue,
  disabled,
  t,
  fullWidth = false,
  setWidth,
  setMaxWidth,
  className,
  required,
  error,
  helperText,
  name,
  selectTestId,
  selectInputTestId,
}) => {
  const verifyTypeItem = (value) => {
    switch (selectType) {
      case 'status':
        return t(`filter.${value}`);
      case 'planStatus':
        return t(`plan.filter.${value}`);
      case 'invoices':
        return t(`invoices.${value}`);
      case 'ticketStatus':
        return t(`tickets.${value}`);
      case 'sitelockshield':
        return t(`sitelock.shield.${value}`);
      default:
        return t(value);
    }
  };

  const generateFilterOptions = () => {
    switch (selectType) {
      case 'websiteBuilder':
        return filterOptions.map(product => (
          <MenuItem key={product.id} value={product.id}>
            {verifyTypeItem(product.domain)}
          </MenuItem>
        ));
      case 'states':
        return filterOptions.map(state => (
          <MenuItem key={state.id} value={state.code}>
            {verifyTypeItem(state.name)}
          </MenuItem>
        ));
      case 'cities':
        return filterOptions.map(city => (
          <MenuItem key={city.id} value={city.name}>
            {verifyTypeItem(city.name)}
          </MenuItem>
        ));
      case 'knowledge':
        return filterOptions.map(persona => (
          <MenuItem key={persona.name} value={persona.value}>
            {verifyTypeItem(persona.name)}
          </MenuItem>
        ));
      case 'sitelockshield':
        return filterOptions.map(shield => (
          <MenuItem key={shield.value} value={shield.value}>
            {verifyTypeItem(shield.value)}
          </MenuItem>
        ));
      case 'paymentCycle':
        return filterOptions.map((paymentCycle, idx) => (
          <MenuItem className={classes.highLightSpan} key={paymentCycle.name} value={paymentCycle.value} data-testid={`payment_cycle_${idx}`}>
            {verifyTypeItem(paymentCycle.name)}
            {paymentCycle.span && (
              <span>
                <Trans i18nKey="proEmail.upgradePlan.savedPercent">
                  <>0</>
                  {{ savedAmount: paymentCycle.span }}
                  <>1</>
                </Trans>
              </span>
            )}
          </MenuItem>
        ));
      case 'paymentMethod':
        return filterOptions.map((paymentMethod, idx) => (
          <MenuItem className={classes.highLightSpan} key={paymentMethod.name} value={paymentMethod.value} data-testid={`payment_method_${idx}`}>
            {verifyTypeItem(paymentMethod.name)}

            {paymentMethod.span && (
              <span>
                {t('proEmail.upgradePlan.instantAccess')}
              </span>
            )}
          </MenuItem>
        ));
      default:
        return filterOptions.map((value) => {
          const valueWithoutSpace = value.replace(/ /g, '');
          return (
            <MenuItem key={value} value={value} data-testid={`selectFieldType${valueWithoutSpace}`}>
              {verifyTypeItem(value)}
            </MenuItem>
          );
        });
    }
  };

  const [selectStyles, setSelectStyles] = useState({});

  useEffect(() => {
    let handleStyles = {};

    if (setWidth) {
      handleStyles = { ...selectStyles, width: setWidth };
    }

    if (setMaxWidth) {
      handleStyles = { ...selectStyles, maxWidth: setMaxWidth };
    }

    setSelectStyles(handleStyles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${fullWidth && classes.fullWidth} ${className}`}
      style={selectStyles}
    >
      <InputLabel
        className={classes.inputLabelCustom}
        htmlFor="outlined-age-simple"
        required={required}
        error={error}
      >
        {inputLabel}
      </InputLabel>
      <Select
        value={selectValue}
        disabled={disabled}
        data-testid={disabled ? 'select_disabled' : 'select_enabled'}
        onChange={handleChangeSelectValue}
        className={classnames(selectValue === 'sites' || selectValue === 'site' || selectValue === 'status') && classes.selectFieldTextOn}
        error={error}
        input={(
          <OutlinedInput
            classes={{
              root: classes.selectCustom,
              input: classes.selectCustomInput,
              notchedOutline: classes.selectCustomOutline,
              focused: classes.selectCustomFocused,
            }}
            labelWidth={labelWidth}
            name={name || 'site'}
            id="outlined-age-simple"
          />
        )}
        SelectDisplayProps={{
          'data-testid': selectTestId || null,
        }}
        inputProps={{
          'data-testid': selectInputTestId || null,
        }}
      >
        {menuItemDefaultText !== 'hidden' && (
          <MenuItem value="">
            <em data-testid="menuItemDefaultText">{menuItemDefaultText}</em>
          </MenuItem>
        )}
        {generateFilterOptions()}
      </Select>
      {helperText && (
        <span className={classes.helperText}>{helperText}</span>
      )}
    </FormControl>
  );
};

SelectField.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  handleChangeSelectValue: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  menuItemDefaultText: PropTypes.string.isRequired,
  selectType: PropTypes.string.isRequired,
  selectValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(withI18n()(SelectField));
