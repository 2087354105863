import styled, { css } from 'styled-components';

const drawerWidth = 200;

const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.canceledLight};
    margin-left: ${drawerWidth}px;
    position: sticky;
    top: 0px;
    width: calc(100% - ${drawerWidth}px);
    z-index: ${theme.v2.layers.awaysOnTop};

    @media (max-width: ${theme.v2.breakpoints.md}){
      background-color: ${theme.v2.colors.brand.primaryDark};
      margin-left: 0px;
      width: 100%;
    }
  `}
`;

const PageContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.feedback.canceledLight};
    flex-grow: 1;
    margin-left: ${drawerWidth}px;
    min-height: calc(100vh - 80px);
    transition: width 200ms ease-in-out, margin 200ms ease-in-out;
    width: calc(100% - ${drawerWidth}px);

    @media (max-width: ${theme.v2.breakpoints.md}){
      margin-left: 0px;
      width: 100%;
    }
  `}
`;

export const Styles = {
  HeaderWrapper,
  PageContent,
};


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    zIndex: 1,
  },
  drawerMargin: {
    paddingTop: '10px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerOpenMargin: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  addedMargin: {
    paddingTop: '73px',
  },
});

export default styles;
