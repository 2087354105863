import styled, { css } from 'styled-components';


export const Container = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      margin: 100px 0px 0px;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.primaryExtraDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 16px 0px 0px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.regular};
    line-height: ${theme.v2.font.lineHeight.text};
    margin: 8px 0px 0px;
    max-width: 454px;
    text-align: center;
  `}
`;

export const ActionsRow = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: center;
  margin: 32px 0px 0px;
`;
