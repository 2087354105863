import React from 'react';
import * as Style from './IconCheck.style';

const IconCheck = ({
  color = '#4a4a4a',
  testId = 'icon-check',
  size = 24,
  variant = 'regular',
}) => (
  <Style.Wrapper data-testid={testId}>
    {variant === 'regular' && (
    <svg id="gmaterial_icons_done_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 32 32">
      <path id="Caminho_247511" data-name="Caminho 247511" d="M0,0H32V32H0Z" fill="none" />
      <path id="Caminho_247512" data-name="Caminho 247512" d="M11.545,22.528,5.436,15.821,3.4,18.057,11.545,27,29,7.836,26.964,5.6Z" />
    </svg>
    )}
    {variant === 'outlined' && (
    <svg id="check_circle_black_24dp" xmlns="http://www.w3.org/2000/svg" fill={color} width={size} height={size} viewBox="0 0 20 20">
      <path id="Caminho_247739" data-name="Caminho 247739" d="M0,0H20V20H0Z" fill="none" />
      <path id="Caminho_247740" data-name="Caminho 247740" d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM8.4,14l-4-4L5.528,8.872,8.4,11.736l6.072-6.072L15.6,6.8Z" fill="#40b186" />
    </svg>
    )}
  </Style.Wrapper>
);


export default IconCheck;
