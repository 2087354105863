import React from 'react';

export default {
  tabInvoicesLabel: 'Facturas',
  tabSubscriptionsLabel: 'Suscripciones',
  filterLabel: 'Filtrar por',
  filterSubscriptionsLabel: 'Todas las suscripciones',
  filterStatusLabel: 'Todos los status',
  domainLabel: 'Dominio',
  genericError: 'No fue posible ejecutar la acción, intente nuevamente más tarde.',
  modal: {
    title: 'Renovación cancelada.',
    firstLinecontent: 'Aún puede usar su suscripción hasta el',
    secondLinecontent: 'Después de esta fecha, se cancelará.',
    activateRenew: 'ACTIVAR RENOVACIÓN',
    close: 'CERRAR',
  },
  splitPaymentOfferModal: {
    conerTag: 'Nuevo',
    title: '¡Más autonomía para usted!  ',
    description: 'Ahora puede seleccionar los artículos de la factura y pagar por separado. ',
    linkLabel: 'Vea cómo',
    url: 'https://soporte-latam.hostgator.com/hc/es-419/articles/21271378534555',
  },
  splitPaymentModal: {
    title: 'Pago de la factura',
    alertDescription: (
      <>
        La factura con los
        <span> artículos no seleccionados seguirá pendiente de pago </span>
        y se encontrará en la pantalla Facturas.
      </>
    ),
    description: '¿Cuáles productos quiere pagar ahora?',
    selectPaymentLabel: 'Elegir como pagar',
    cancelLabel: 'Cancelar',

  },
  invoices: {
    clearLabel: 'Limpiar filtros',
    invoiceListTitle: 'Lista de Facturas',
    filterOptions: {
      financialPending: 'Con pendiente financiero',
      all: 'Todos',
      paid: 'Pagadas',
      cancelled: 'Canceladas',
    },
    searchByProductInvoice: 'Busque por producto o factura',
    renewDomain: 'Renovación dominio',
    registerDomain: 'Registro dominio',
    alterPlan: 'Cambio de plan:',
    alterCycle: 'Cambio de ciclo:',
    allStatus: 'Todos los Status',
    status: 'Status',
    unpaid: 'Por vencer',
    unpaidExpired: 'Vencida',
    analysing: 'En análisis',
    paid: 'Pagada',
    cancelled: 'Anulada',
    paymentPending: 'Confirmando Pago',
    awaitingPayment: 'Esperando el pago',
    refunded: 'Reembolsada',
    sentBilling: 'Enviado para Colección',
    showProof: 'Ver comprobante',
    pay: 'Pagar Factura',
    goToPayment: 'Ir al pago',
    unpaidInvoicesTitle: 'Pendientes',
    paidInvoicesTitle: 'Pagadas',
    cancelledInvoicesTitle: 'Anuladas',
    totalInvoice: 'Total de la factura',
    download: 'Descargar recibo de pago',
    knowMore: 'Consulte más',
    knowMoreURL: 'https://soporte-latam.hostgator.com/hc/es-419/articles/21271378534555',
    headerInvoicesPage: {
      text: {
        pendingInvoices: pendingInvoices => (
          <>
            Tiene
            <span>{` ${pendingInvoices} `}</span>
            <span>{`${pendingInvoices > 1 ? 'facturas' : 'factura'} pendiente de pago.`}</span>
          </>
        ),
        splitAlert: (
          <>
            <span>¡Novedad! </span>
            Ahora también puede seleccionar los artículos de la factura y pagar por separado. Haga clic en el botón “Ir al pago“.
          </>
        ),
      },
      link: 'Consulte más',
    },
    holdDomainModal: {
      title: 'Reactivar dominio congelado o expirado',
      description1: 'Todo dominio registrado tiene un plazo de vencimiento, por lo general este plazo es de 1 año, pero este tiempo puede ser mayor si el registro se ha realizado por más años.',
      description2: domain => (
        <>
          Su dominio
          {' '}
          <strong>{domain}</strong>
          {' '}
          que está registrado con nosotros ha vencido, por lo que adquirió el status expirado o congelado y el sitio quedó offline. Para ponerlo online nuevamente, debe renovar el pago.
        </>
      ),
      alert: 'Para reactivar el dominio más rápido, le recomendamos pagar con Tarjeta de Crédito.',
      cta: 'Pagar factura y reactivar dominio',
    },
  },
  manageSubscription: {
    activeSubscriptionRenewalButtonLabel: 'Activar renovación de suscripción',
    cancelSubscriptionButtonLabel: 'Cancelar suscripción',
    cancelSubscriptionImmediatelyButtonLabel: 'Cancelar inmediatamente',
    installmentsAmountLabel: 'Valor total',
    installmentsPaymentFormLabel: 'Forma de pago',
    installmentsPaymentFormTooltipText: 'Los métodos de tarjeta de crédito y PayPal procesan el pago de inmediato. Boleto puede tardar hasta 72 horas en borrarse.',
    nextRenovationDateLabel: 'Próxima renovación',
    paymentCycleLabel: 'Ciclo de la suscripción',
    paymentMethodLabel: 'Método de pago',
    paymentMethodTooltipText: 'Las opciones de pago se definen de acuerdo al valor del producto y su ciclo de pago.',
    planButtonLabel: 'Cambiar plan y/o ciclo de pago',
    reactivationButtonLabel: 'Reactivar mi suscripción',
    alreadyReactivatedTooltip: 'Ya ha realizado una reactivación de esta factura y no puede realizarla nuevamente',
    reactivationTooltipLabel: 'Al realizar la reactivación de la suscripción el servicio estará activo por 72 horas. Si no reconocemos que hubo un pago en este período, la suscripción se suspenderá nuevamente.',
    trustPeriodLabel: 'En período de confianza',
    trustPeriodDescriptionLabel: date => (
      <>
        Suscripción reactivada con éxito, esperando la confirmación del pago.
        <br />
        Periodo de confianza activo hasta
        {' '}
        {date}
        .
      </>
    ),
    productNameLabel: 'Producto',
    invoiceLabel: 'Factura',
    registerDateLabel: 'Fecha de contratación',
    renewalDataTitleLabel: 'Datos de renovación',
    renovationAmountLabel: 'Valor de renovación',
    renovationInfoLabel: 'Debido a que ha aceptado nuestra oferta de renovación con descuento, su factura ahora está disponible para el pago.',
    renovationDraftInvoiceInfoLabel: 'Por haber aceptado nuestra oferta, su próxima factura de renovación ya está con el descuento aplicado',
    resumeTitleLabel: 'Resumen de la suscripción',
    statusLabel: 'Status',
    dueDateLabel: 'Fecha de vencimiento',
    processBoleto: 'En este momento, no es posible emitir su factura. Por favor, inténtelo de nuevo más tarde o entre en contacto con nuestro soporte.',
    switchPaymentMethodButtonLabel: 'Cambiar forma de pago',
    payInvoice: 'Pagar Factura',
    dueDatePaypalLabel: 'No se preocupe, su suscripción se renovará automáticamente conforme el método de pago anterior.',
    dueDateCreditCardLabel: 'No se preocupe, su suscripción se renovará automáticamente conforme el método de pago anterior.',
    noRenewAlertLabel: {
      0: 'Según lo solicitado,',
      1: ' esta suscripción ya no se renovará y se cancelará automáticamente el ',
      2: ' Después de esta fecha, todos los datos y archivos se eliminarán permanentemente. ',
      3: 'Vea cómo hacer una copia de seguridad de sus archivos',
    },
    nextDueAlertLabel: {
      0: 'La suscripción vencerá pronto. ',
      1: 'Pague ahora mismo',
      2: ' con cupón de pago o elija un método de compensación inmediata como Tarjeta de Crédito o PayPal.',
    },
    dueDateAlertLabel: {
      0: 'Su suscripción venció y su sitio quedará offline y los correos electrónicos vinculados a este dejarán de funcionar en ',
      1: 'Su suscripción venció y su dominio y los correos electrónicos vinculados a este dejarán de funcionar en ',
      2: 'Su suscripción venció y se suspenderá en ',
      3: ' dias. ',
      4: 'Pague ahora mismo',
      5: ' con cupón de pago o elija un método de compensación inmediata como Tarjeta de Crédito o PayPal.',
    },
    suspendedAlertLabel: {
      0: 'Su suscripción fue suspendida y su sitio quedó offline y los correos electrónicos vinculados a este dejaron de funcionar.',
      1: 'Su suscripción ha caducado y su dominio y los correos electrónicos vinculados a este han dejado de funcionar.',
      2: 'Su suscripción fue suspendida.',
      3: ' Pague ahora mismo',
      4: ' con cupón de pago o elija un método de compensación inmediata como Tarjeta de Crédito o PayPal, antes de que sea cancelado.',
    },
    paymentFailAlertLabel: {
      0: 'No pudimos hacer el cobró porque hubo algun problema con la tarjeta registrada.',
      1: ' Pague ahora mismo',
      2: ' a través de Cupón de pago, PayPal, o cambie su tarjeta de crédito. Recordando que su suscripción venció y su sitio quedará offline, además los correos electrónicos vinculados a este dejarán de funcionar en ',
      3: ' días.',
    },
    pendingPaymentAlertLabel: {
      0: 'Estamos esperando la confirmación del pago. El cupón de pago puede tardar hasta 72 horas en compensar.',
      1: 'Si aún no ha pagado, ',
      2: 'hágalo ahora mismo',
      3: ' a través de Cupón de pago o elija un método de compensación inmediata como Tarjeta de Crédito o PayPal.',
    },
    readMoreStepper: 'Ler mas',
  },
  manageSubscriptionRetentionDiscountOffer: {
    paymentCycle: {
      Monthly: 'Mensual',
      Quarterly: 'Trimestral',
      SemiAnnually: 'Semestral',
      Annually: 'Anual',
      Biennially: 'Bienal',
      Triennially: 'Trienal',
      FreeAccount: 'Trial',
    },
    retentionTitles: {
      0: (
        <>
          <p>
            <span>
              ¡No te rindas todavía!
            </span>
          </p>
        </>
      ),
      1: 'Sabemos lo valioso que es tener presencia online y estamos aquí para ofrecerte un incentivo especial:',
      2: (
        <>
          <p>
            <span>¡No te dejaremos ir tan fácilmente!</span>
            {' '}
            Tu éxito es muy importante para nosotros. Decídete a quedarte y...
          </p>
        </>
      ),
    },
    discountDescription: (discount, version) => (
      <>
        {version === 0 ? '¡Quédate con nosotros y' : ''}
        {' '}
        <strong>
          {version === 0 ? 'obtén un' : 'Obtén un'}
          {' '}
          {discount}
          % de descuento
          {' '}
        </strong>
        en tu próxima renovación!
      </>
    ),
    benefitDescription: (amount, plan) => (
      <>
        <p>
          Solo pagarás
          {' '}
          <strong>
            {amount}
          </strong>
          {' '}
          por tu
          {' '}
          <strong>
            {plan}
          </strong>
        </p>
      </>
    ),
    benefitAcceptOfferButton: 'Aceptar oferta',
    continueCancelButton: 'Continuar cancelando',
    benefitInfo: '*Descuento exclusivo solo para la próxima renovación.',
  },
  manageSubscriptionRetention: {
    retentionTitle: '¿Está seguro que desea cancelar su plan? ',
    retentionTextDescription: (
      <>
        <p>
          <strong>No pierda sus correos electrónicos.</strong>
          {' '}
          Su hosting le da derecho a
        </p>
        <p>
          <strong>cuentas gratuitas e ilimitadas</strong>
          {' '}
          en las plataformas cPanel o Titan
        </p>
      </>
    ),
    retentionBenefits: {
      title: (
        <>
          <span>
            Conozca algunos
            {' '}
            <strong>beneficios del correo profesional Titan:</strong>
          </span>
        </>
      ),
      benefits: [
        (
          <span>
            Cuentas de correo
            {' '}
            <strong>ilimitadas</strong>
            {' '}
            (@tudominio)
          </span>
        ),
        (
          <span>
            <strong>1GB</strong>
            {' '}
            de almacenamiento por cuenta
          </span>
        ),
        (
          <span>
            Soporte de Outlook, Gmail otros aplicativos
          </span>
        ),
        (
          <span>
            Poderoso filtro antiSpam
          </span>
        ),
        (
          <span>
            Garantia de envio y recibimiento de correos
          </span>
        ),
      ],
    },
    retentionMailButton: 'ACCEDER A MIS CORREOS',
    retentionAttention: {
      title: 'Atención:',
      text: (
        <span>
          En caso de cancelar el plan,
          {' '}
          <strong>no podrá ingresar a sus correos electrónicos gratuitos.</strong>
        </span>
      ),
    },
    retentionCancelButton: 'CANCELAR SUSCRIPCIÓN',
    retentionCancelRenewButton: 'CANCELAR RENOVACIÓN',
  },
  retentionOffer: {
    title: [
      '¡Considere esto antes de cancelar!',
      '¡Considere cambiarse a un ciclo antes de cancelar!',
    ],
    greenDescription: [
      'Desactive la renovación automática para que no haya cobro automático al final del ciclo.',
      'Cámbiese a un ciclo menor que el actual para pagar menos.',
    ],
    description: [
      'De esta forma, puede mantener sus servicios sin preocuparse por los cobros automáticos.',
      'De esta forma, continuará con su Plan y mantendrá sus servicios (sitio web y correos electrónicos) funcionando.',
    ],
    buttonLabel: [
      'Desactivar la renovación automática',
      'Cambiar el ciclo del hospedaje',
    ],
    note: [
      '*En la renovación, la factura se generará a través de Cupón de pago, pero usted puede cambiar la forma de pago en cualquier momento en la administración de su suscripción.',
      '*Usted será redirigido a la pantalla de Cambio de Ciclo.',
    ],
    continueCancelButton: 'Continuar cancelando',
    toastSucces: '¡La renovación automática se ha desactivado con éxito!',
  },
  offerDiscountBanner: {
    title: {
      0: 'Renueva ahora y ',
      1: 'economiza 35%',
    },
    descriptionsToSitePage: {
      0: 'Usted tiene suscripciones ',
      1: 'próximos a expirar ',
      2: 'La suscripción de su ',
      3: ' esta próximo a expirar',
      4: ' y tenemos una oferta imperdible para usted.',
      5: 'Renove agora e ganhe 35% de desconto ao ativar a renovação automática. Não perca esta oportunidade!',
    },
    descriptionToSubscripionsPage: 'Oportunidad por tiempo limitado! Economiza 35% en la renovación automática del próximo ciclo.',
    buttonLabel: 'Oba! Quiero descuento!',
  },
  offerAcceptSuccesModal: {
    title: '¡Oferta aceptada con éxito!',
    moreThanMonthDescriptionVersion: 'Su factura de renovación ya está disponible para su pago y el descuento ya fue aplicado.',
    lessThanMonthDescriptionVersion: (
      <p>
        El descuento se aplicará en la próxima renovación de su Plan de Hosting.
        {' '}
        <strong>
          ¿Le gustaría renovar ahora o dejarlo para después (al final del ciclo actual)?
        </strong>
      </p>),
    beforeRenewal: 'Renovar después',
    paymentNow: 'Pagar ahora',
    renewalNow: 'Renovar ahora',
  },
  manageSubscriptionBenefit: {
    benefitTitle: '¡Considere este beneficio antes de cancelar!',
    benefitDescriptionMonth: (
      <>
        <p>
          Cuando renueve su suscripción,
          {' '}
        </p>
        <p>
          obtenga un
          {' '}
          <strong>descuento exclusivo</strong>
        </p>
      </>
    ),
    benefitDescriptionYear: (
      <>
        <p>Renueve ahora su suscripción y garantice un</p>
        <strong>desconto exclusivo</strong>
      </>
    ),
    benefitCurrentPriceText: (cycle) => {
      const cycleText = currentCycle => ({
        Monthly: 'mensual',
        Quarterly: 'trimestral',
        'Semi-Annually': 'semi anual',
        Annually: 'anual',
        Biennially: 'bianual',
        Triennially: 'trianual',
      }[currentCycle] || '');

      return (
        <p>
          Actualmente usted paga en el
          {' '}
          <strong>
            ciclo
            {' '}
            {cycleText(cycle)}
            :
          </strong>
        </p>
      );
    },
    benefitCurrentPriceCycleMonth: currentPriceMonth => `${currentPriceMonth}/mes`,
    benefitCurrentPriceCycleYear: currentPriceYear => (
      <>
        <strong>
          {currentPriceYear}
        </strong>
        {' '}
        por año
      </>
    ),
    benefitCurrentPriceTotal: currentPriceTotal => (
      <>
        <strong>
          {currentPriceTotal}
        </strong>
        en total
      </>
    ),
    benefitFuturePriceTextMonth: (
      <p>
        Mantenga su suscripción por un año
        {' '}
        <strong>renovando ahora:</strong>
      </p>
    ),
    benefitFuturePriceTextYear: (
      <p>
        Renueve la suscripción del próximo ciclo
        {' '}
        <strong>con un descuento:</strong>
      </p>
    ),
    benefitFuturePriceCycleMonth: (quantityInstallment = 0, futurePriceMonth) => (
      <p>
        En hasta
        {' '}
        <strong>
          {quantityInstallment}
          x de
          {' '}
          {futurePriceMonth}
          /mes
        </strong>
      </p>
    ),
    benefitFuturePriceCycleYear: futurePriceOnePayment => (
      <p>
        <strong>
          {futurePriceOnePayment}
        </strong>
        {' '}
        al contado
      </p>
    ),
    benefitFutureDiscount: futureDiscount => `${futureDiscount}% DE DESCUENTO`,
    benefitInstallments: quantityInstallment => `${quantityInstallment}`,
    benefitAcceptOfferButton: 'ACEPTAR OFERTA',
    benefitFinalTextMonth: 'Después de pagar, el ciclo de su plan será anual. Si no realiza el pago de la factura, el plan se cancelará automáticamente.',
    benefitFinalTextCycle: 'El descuento es válido solo para el próximo ciclo y el pago de la factura de renovación con descuento debe realizarse hasta el final del ciclo actual.',
    benefitCancelRenewButton: 'CANCELAR RENOVACIÓN',
    benefitCancelSignatureButton: 'CANCELAR SUSCRIPCIÓN',
    invoiceModal: {
      title: 'Descuento ya aplicado',
      description: [
        'El descuento ya fue aplicado en su factura. Haga clic en el botón de abajo para acceder a esta y realizar el pago.',
        'Si ya pago la factura, puede demorar hasta 72 horas en compensar o reflejar el pago.',
      ],
      button: 'Ver factura',
    },
  },
  manageSubscriptionCancelModal: {
    cancelRenewalDescriptionLabel: 'Usted ya ha pagado y puede seguir usando la suscripción hasta el final del ciclo (período contratado) y no pagará nada más por esta. Al final del ciclo, su suscripción se cancelará automáticamente.',
    cancelRenewalLabel: 'No quiero renovar la suscripción',
    cancelImmediateDescriptionLabel: 'Al optar por cancelar inmediatamente, todos los datos y archivos se borrarán y eliminarán inmediatamente.',
    cancelImmediateLabel: 'Quiero cancelar inmediatamente',
    continueButtonLabel: 'Continuar',
    descriptionLabel: 'Seleccione la opción que desee, a continuación:',
    quitButtonLabel: 'Abandonar acción',
    titleLabel: 'Cancelar suscripción',
  },
  manageSubscriptionActiveRenewalModal: {
    descriptionAlertInfoLabel: 'El cobro del pago se realizará al final del ciclo (fecha arriba), evitando que su producto sea cancelado y sus datos y archivos eliminados permanentemente.',
    descriptionPixBoletoAlertInfoLabel: 'Realice el pago hasta la fecha de vencimiento de la factura y evite que su producto sea cancelado y sus datos y archivos se eliminen permanentemente.',
    confirmButtonLabel: 'Confirmar activación',
    titleLabel: 'Activar renovación de la suscripción',
    descriptonConfirmationAlertlabel: 'Luego de confirmar la activación, puede realizar el cambio y pago de su suscripción.',
    from: 'de ',
    to: 'para ',
    discount: '% de descuento',
    note: '*Descuento exclusivo para la renovación de este ciclo',
  },
  manageSubscriptionPaymentInvoiceModal: {
    titleLabel: 'Pagar Factura',
    discount: 'Descuento',
    duedate: 'Vencimiento',
    successInvoiceGenerate: 'Factura modificada con éxito ',
    selectPaymentMethodLabel: '¿A través de qué método le gustaría realizar este pago?',
    generateTicketLabel: 'Generar Cupón de Pago',
    infoPaymentByTicketLabel: 'Los pagos a través de cupón de pago son identificados en hasta 72h por nuestro sistema',
    infoPaymentByPicPayLabel: 'Compensación rápida del pago',
    infoPaymentByCreditCardTitle: 'Compensación rápida del pago',
    infoPaymentByCreditCardLabel: 'Las informaciones serán enviadas de forma segura y encriptada para reducir el riesgo de fraude',
    selectCardLabel: 'Seleccione cuál tarjeta le gustaría usar:',
    infoAddNewCreditCardLabel: 'Reemplace la tarjeta anterior en todas las suscripciones vinculadas a esta.',
    changeNextPaymentMethod: 'Usar esta forma de pago en las próximas renovaciones de este(os) producto(s).',
    paymentByCreditCardButton: 'Pagar con Tarjeta de Crédito',
    selectAccountPaypalLabel: 'Seleccione cuál cuenta PayPal le gustaría usar:',
    selectActuralPayPalAccountLabel: 'Usar cuenta de PayPal registrada',
    infoChangePaymentByPayPalLabel: 'Al final del ciclo de la suscripción haremos un cobro automático en la cuenta Paypal registrada',
    addNewPayPalAccountLabel: 'Registrar una nueva cuenta PayPal',
    infoAddNewAccountLable: 'Reemplazará la cuenta de PayPal en todas las facturas vinculadas a esta.',
    invoiceDetailsTitle: 'Descripción de la factura y valores',
  },
  reactivationConfirmationModalModal: {
    titleLabel: 'Renovación activa',
    descriptionLabel: '¡La renovación automática de la suscripción fue activada con éxito! ',
    clouseButtonLabel: 'Fechar',
  },
  invoiceGeneratorModal: {
    titleLabel: 'Por favor, espere',
    descriptionLabel: {
      0: 'Estamos dirigiéndote para el pago.',
      1: 'No cierres la ventana.',
    },
  },
  manageSubscriptionSwitchPaymentMethodInvoiceModal: {
    titleLabel: 'Cambiar forma de pago',
    selectPaymentMethodLabel: 'Seleccione abajo la forma de pago con que desea renovar las próximas facturas de esta suscripción:',
    infoChangePaymentByTicketLabel: 'Al finalizar el ciclo de la suscripción se generará una factura para la renovación de la misma.',
    infoChangePaymentByPayPalLabel: 'Al final del ciclo de la suscripción, se le cobrará automáticamente por PayPal.',
    infoChangePaymentByCreditCardLabel: 'Al final del ciclo de la suscripción, realizaremos el cobró automáticamente en la tarjeta de arriba.',
    confirmSwitchLabel: 'Confirmar cambio',
  },
  manageSubscriptionFinishSwitchPaymentMethodInvoiceModal: {
    titleLabel: 'Cambiar forma de pago',
    infoSwitchPaymentMethod: {
      0: 'La forma de pago se ha cambiado para ',
      1: 'Paypal',
      2: 'Cupón de pago',
      3: 'Tarjeta de crédito',
      4: ' y al final del ciclo de la suscripción se le cobrará automáticamente a través de este.',
    },
    backButtonLabel: 'Volver para Administración Suscripción',
  },
  manageSubscriptionReactivationModal: {
    reactivateTitleLabel: 'Reactivar mi suscripción',
    descriptionLabel: (
      <>
        Antes de hacer clic en el botón de abajo para reactivar, recuerde que solo puede reactivar
        {' '}
        <strong>una vez en la misma factura</strong>
        {' '}
        y que no es posible reactivar dominios. Además, la fecha de vencimiento original no se cambiará.
      </>
    ),
    infoAlertLabel: (
      <>
        Al realizar la reactivación de la suscripción el servicio estará
        {' '}
        <strong>activo por 72 horas</strong>
        . Si no reconocemos que hubo un pago en este período, la suscripción se suspenderá nuevamente.
      </>
    ),
    reactivateButtonLabel: 'Reactivar mi suscripción',

    reactivationSuccessLabel: 'Reactivación realizada con éxito',
    infoSuccessLabel: (
      <>
        ¡Su suscripción
        {' '}
        <strong>se ha reactivado con éxito</strong>
        {' '}
        y está lista para usar!
      </>
    ),
    infoSuccessDescriptionLabel: (
      <>
        No olvide que su suscripción estará activa por 72 horas hasta que se confirme el pago y que
        {' '}
        <strong>no es posible reactivarla más de una vez en la misma factura.</strong>
      </>
    ),
    undestoodButtonLabel: 'Ok, entendí',
    reactivationFailureLabel: 'Error en la reactivación',
    infoFailureLabel: (
      <>
        Tuvimos un problema para
        {' '}
        <strong>reactivar su plan. </strong>
      </>
    ),
    infoFailureDescriptionLabel: 'Intente reactivar nuevamente a través del botón de abajo o regrese a la pantalla de administrar su suscripción.',
    tryAgainButtonLabel: 'Volver a intentarlo',
    goBackSubscriptionButtonLabel: 'Regresar para administrar la suscripción',
    infoAlertReactivation: 'Cuando reactive la suscripción, todos los hostings vinculado a su factura se reactivarán.',
  },
  paymentInstallments: {
    withFees: '(con interés)',
    withoutFees: '(sin interés)',
  },
  paymentMethods: {
    hostgatorCredits: 'Créditos HostGator',
    creditCard: 'Tarjeta de crédito',
    ticket: 'Cupón de pago',
    paypal: 'PayPal',
    hg_credits: 'Créditos HostGator',
    dlocal: 'Tarjeta de Crédito',
    dlocalcash: 'Cupón de Pago',
    payu_boleto: 'Cupón de Pago',
    dlocal_boleto: 'dLocal Boleto',
    boleto: 'Efectivo',
    gp_paypal: 'PayPal',
  },
  ticketRedirectModal: {
    infoTextLabel: '¡Su Cupón de pago se ha emitido exitosamente! ',
    redirectTicketUrlButtonLabel: 'Abrir Cupón de pago',
  },
  paymentCycle: {
    Monthly: 'Mensual',
    Quarterly: 'Trimestral',
    SemiAnnually: 'Semestral',
    Annually: 'Anual',
    Biennially: 'Bienal',
    Triennially: 'Trienal',
    FreeAccount: 'Trial',
  },
  status: {
    activeStatusLabel: 'Activo',
    expiredStatusLabel: 'Caducado',
    registeredStatusLabel: 'Registrado',
    transferredAwayStatusLabel: 'Transferido',
    cancelledStatusLabel: 'Cancelada',
    pendingStatusLabel: 'Pendiente',
    pendingPaymentStatusLabel: 'Aguardando pago',
    pendingAnalysisStatusLabel: 'En análisis',
    suspendedStatusLabel: 'Suspendida',
    toDueStatusLabel: 'Por vencer',
    overdueStatusLabel: 'Vencida',
  },
  subscriptions: {
    supportEmail: 'abuse@hostgator.{{country}}',
    supportWizard: 'https://soporte-latam.hostgator.com/hc/es-419/requests/new',
    searchPlaceholder: 'Buscar nombre de factura',
    searchPlaceholderNewLayout: 'Búsqueda por suscripción',
    clearLabel: 'Limpiar filtros',
    listTitle: 'Lista de suscripciones',
    buttonManageSubscriptionLabel: 'Administrar suscripción',
    manageSubscriptionLabel: 'Administrar',
    activeStatusLabel: 'Activo',
    expiredStatusLabel: 'Caducado',
    registeredStatusLabel: 'Registrado',
    readMoreStepper: 'Ler mas',
    transferredAwayStatusLabel: 'Transferido',
    cancelledStatusLabel: 'Cancelada',
    pendingStatusLabel: 'Pendiente',
    pendingPaymentStatusLabel: 'Aguardando pago',
    pendingAnalysisStatusLabel: 'En análisis',
    suspendedStatusLabel: 'Suspendida',
    toDueStatusLabel: 'Por vencer',
    overdueStatusLabel: 'Vencida',
    message: {
      domain: {
        registered: {
          withCancelDate: {
            0: 'Tu dominio',
            1: ' será cancelado el día',
            2: ' ¡Activa la renovación ahora mismo',
            3: ' y no te arriesgues a perderla!',
          },
        },
        overdue: {
          0: 'Su factura ',
          1: 'vence el',
          2: ' y su dominio caducará en ',
          '3-singular': 'día. ',
          3: 'días. ',
          4: '¡Paga el boleto ahora mismo!',
        },
        expired: {
          0: 'Su factura',
          1: ' vence el',
          2: ' y su dominio se cancelará pronto.',
          3: ' ¡Paga el boleto ahora mismo!',
        },
        cancelled: {
          forNonPayment: {
            0: 'Tu dominio',
            1: ' fue cancelado el',
            2: ' por falta de pago,',
            3: ' ¡Acceda a nuestro sitio web y registre un nuevo dominio!',
          },
          byClient: {
            0: 'Según lo solicitado, su dominio',
            1: ' se canceló el',
            2: ' ¡Acceda a nuestro sitio web y registre un nuevo dominio!',
          },
          byFraud: {
            0: 'Su dominio fue cancelado.',
            1: ' Para más informaciónes ingrese a nuestra',
            2: ' Central de Ayuda',
            3: '.',
          },
        },
        transferredAway: 'Como solicitado, su domínio fue transferido para otra hospedaje',
      },
      addon: {
        withDateSuspended: {
          0: 'Su suscripción ',
          1: 'fue suspendida el día',
          2: '¡Pague la factura ahora mismo',
          3: ' para que no se cancele!',
        },
        withoutDateSuspended: {
          0: 'Su ',
          1: 'suscripción fue suspendida',
          2: '. Para obtener más información, ',
          3: 'entre en contacto con soporte financiero',
          4: ' a través de nuestro chat.',
        },
      },
      active: {
        withCancelDate: {
          0: 'Su suscripción',
          1: ' será cancelada el día',
          2: ' ¡Active la renovación ahora mismo',
          3: ' y no se arriesgue a perder su sitio web y sus archivos!',
        },
      },
      pendingAnalysis: 'Su suscripción está pasando por nuestro análisis de seguridad antes de que confirmemos el pago y se active (procedimiento normal).',
      pendingPayment: 'Estamos esperando la confirmación del pago. Los cupones de pago puden llevar hasta 72h para confirmarse. Si ya pagó, por favor espere.',
      toDue: {
        0: 'Su suscripción ',
        1: 'vence el día',
        ticket: {
          0: '. ',
          1: '¡Pague el cupón de pago ahora mismo',
          2: ' y no se arriesgue a perder su sitio web y sus archivos!',
        },
        creditCard: {
          0: ' y se le cobrará automáticamente a través de la Tarjeta de crédito.',
        },
        paypal: {
          0: ' y se le cobrará automáticamente a través de la cuenta de Paypal.',
        },
      },
      overdue: {
        0: 'Su suscripción ',
        1: 'vence el día',
        2: ' y ',
        3: 'su sitio dejará de funcionar en',
        4: 'días.',
        '4-singular': 'día.',
        5: ' ¡Pague la factura ahora mismo!',
      },
      offAfterExpiration: {
        0: 'Su suscripción ',
        1: 'vence el día',
        2: '. Para obtener más información, póngase en contacto con soporte.',
      },
      suspended: {
        withDate: {
          0: 'Su suscripción ',
          1: 'fue suspendida el día ',
          2: ' y ',
          3: 'su sitio dejó de funcionar.',
          4: ' ¡Pague la factura ahora mismo',
          5: ' para que no se cancele!',
        },
        withoutDate: {
          0: 'Su ',
          1: 'suscripción fue suspendida',
          2: ' y su sitio web está caído',
          3: '. Para obtener más información, ',
          4: 'entre en contacto con soporte financiero',
          5: ' a través de nuestro chat.',
        },
        standard: {
          0: 'Su suscripción fue suspendida y su sitio web esta offline.',
          1: ' Pague ahora',
          2: ' para',
          3: ' reactivar su suscripción.',
        },
      },
      cancelled: {
        user_request: {
          0: 'De acuerdo a lo solicitado, su suscripción ',
          1: 'fue cancelada el día {{cancelDate}}',
          2: '¡Visite nuestro sitio web y haga una nueva suscripción!',
        },
        whmcs_suspension: {
          0: 'Su suscripción ',
          1: 'fue cancelada el día {{cancelDate}}',
          2: 'por falta de pago. ',
          3: '¡Visite nuestro sitio web y haga una nueva suscripción!',
        },
        default: {
          0: 'Su suscripción ',
          1: 'fue cancelada el día {{cancelDate}}',
          2: '. Entre en contacto con ',
          3: ' para más informaciones.',
        },
        fraud: {
          0: 'Su suscripción ',
          1: 'fue cancelada',
          2: '. Entre en contacto con ',
          3: ' para más informaciones.',
        },
        withoutDate: {
          0: 'Su suscripción ',
          1: 'fue cancelada',
          2: ' por falta de pago. ',
          3: '¡Visite nuestro sitio web y haga una nueva suscripción!',
        },
      },
      management: {
        backupFilesLinkUrl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691',
        firstLine: 'La renovación de esta suscripción está deshabilitada y será ',
        boldFirstLine: 'cancelado automáticamente el',
        secondLine: 'Después de esta fecha, todos los datos y archivos serán ',
        boldSecondLine: 'eliminado permanentemente. ',
        linkLabel: 'Vea como hacer una copia de sus archivos',
      },
    },
    sectionTitle: 'Resumen de las suscripciones',
    subscriptionPrice: 'Valor de la suscripción',
    statusLabel: 'Status',
    hireDateLabel: 'Contratado en',
    renewDateLabel: 'Renovar en',
    cancelledLabel: 'Cancelado en',
    paymentCicleLabel: 'Ciclo de pago',
    paymentMethodLabel: 'Método de pago',
    autoChargesLabel: 'Cobro automático',
    hirePlan: 'CONTRATAR NUEVO PLAN',
    autoChargeTooltip: {
      paragraphOne: 'Con el estado activo, intentaremos cargar automáticamente la tarjeta de crédito registrada o PayPal.',
      paragraphTwo: 'Si hay algún problema con la facturación o si está desactivada, te avisaremos enviándote un enlace para generar un ticket cerca de la fecha límite de renovación. :)',
    },
    refundFeedback: {
      titleLabel: 'Reembolso',
      statusLabel: 'Status',
      statusText: {
        pending: 'Pendiente',
        accomplished: 'Realizado',
      },
      valueLabel: 'Valor',
      methodLabel: 'Modo',
    },
    automaticBilling: {
      feedback: {
        active: 'Activa',
        disabled: 'Desactivada',
      },
      toggle: {
        active: 'Activar',
        disable: 'Desactivar',
      },
      activeModal: {
        titleLabel: 'Habilitar Cobro Automático',
        infoTextLabel: {
          0: 'Para habilitar la "cobro automático" para una suscripción, ',
          1: 'debe elegir entre tarjeta de crédito o cuenta de PayPal ',
          2: 'y deben poder facturarse al final del contrato.',
        },
        activeCreditCardButtonLabel: 'Activar con Tarjeta de Crédito',
        activePaypalButtonLabel: 'Activar con cuenta PayPal',
      },
      deactiveModal: {
        titleLabel: 'Deshabilitar Cobro Automático',
        infoTextLabel: {
          0: '¡Deshabilitar "cobro automática" ',
          1: 'corres el riesgo de no renovar tu suscripción, ',
          2: 'haciendo que necesites renovarlo ',
          3: 'manualmente al final del contrato!',
        },
        confirmeDeactiveButtonLabel: 'Confirmar desactivación',
      },
    },
    cancel: {
      cancelRenewalButtonLabel: 'CANCELAR RENOVACIÓN',
      activeRenewalButtonLabel: 'ACTIVAR RENOVACIÓN',
      confirmation: {
        controlBackButtonLabel: 'Volver',
        controlFowardButtonLabel: 'Continuar',
        controlFowardButtonStepConfirmationCancelRenewalLabel: 'Confirmar la cancelación de la renovación',
        controlFowardButtonStepConfirmationCancelSignatureLabel: 'Confirmar la cancelación de la suscripción',
        tooltipCancelRenewalText: 'Seleccione el motivo de la cancelación de la renovación para continuar.',
        tooltipCancelSignatureText: 'Seleccione el motivo de la cancelación para continuar.',
        confirmationTitleCancelRenewalLabel: 'Lea la información a continuación antes de confirmar la cancelación de renovación de la suscripción:',
        confirmationTitleCancelSignatureLabel: 'Lea la información a continuación antes de confirmar la cancelación:',
        attentionLabel: 'Atención:',
        stopCancelRenewalLabel: 'No quiero cancelar la renovación',
        stopCancelSignatureLabel: 'No quiero cancelar',
        backupFilesLinkLabel: 'Vea como hacer una copia de sus archivos',
        backupFilesLinkUrl: 'https://soporte-latam.hostgator.com/hc/es-419/articles/115000322691',
        attentionCancelRenewalTexts: dateString => (
          <>
            <li>
              El producto estará disponible hasta el final del ciclo de suscripción, en
              {' '}
              <strong>{dateString}</strong>
            </li>
            <li>
              Después de esta fecha, la suscripción será
              {' '}
              <strong>cancelada inmediatamente</strong>
              {' '}
              y todos sus datos o archivos serán
              {' '}
              <strong>eliminados permanentemente.</strong>
            </li>
          </>
        ),
        domainAttentionCancelRenewalTexts: dateString => (
          <>
            <li>
              El dominio estará disponible hasta el
              {' '}
              <strong>{dateString}</strong>

            </li>
            <li>
              Después de esta fecha, la suscripción será
              {' '}
              <strong>cancelada inmediatamente</strong>
              .
              {' '}
              Su dominio y los correos electrónicos vinculados a él se
              {' '}
              <strong>eliminarán de forma permanente</strong>
              .
            </li>
          </>
        ),
        addonAttentionCancelRenewalTexts: dateString => (
          <>
            <li>
              El producto estará disponible hasta el final del ciclo de suscripción, en
              {' '}
              <strong>{dateString}</strong>
            </li>
            <li>
              Después de esta fecha, la suscripción será
              {' '}
              <strong>cancelada inmediatamente.</strong>
            </li>
          </>
        ),
        addonCancellation: (addon, dateString) => (
          <li>
            El producto
            {' '}
            <strong>{addon}</strong>
            {' '}
            vinculado a esta suscripción también será cancelado el
            {' '}
            <strong>{dateString}</strong>
          </li>
        ),
        attentionCancelSignatureTexts: {
          0: 'Su suscripción será ',
          1: 'cancelada inmediatamente',
          2: ' y todos sus datos y archivos se ',
          3: 'eliminarán permanentemente.',
        },
        addonCancellationAlert: {
          0: 'El producto',
          1: 'inculado a esta suscripción se',
          2: 'cancelará automáticamente',
        },
      },
      feedbackStepper: {
        stepSurveyLabel: 'Responder encuesta',
        stepRefundLabel: 'Reembolso',
        stepReviewLabel: 'Verificar informaciones',
        stepConfirmationLabel: 'Confirmar cancelación',
      },
      survey: {
        surveyQuestionDescriptionLabel: 'Su comentario es muy importantes para nosotros.',
        surveyReasonQuestionLabel: '¿Podría contarnos un poco más sobre el motivo seleccionado?',
      },
      refundModal: {
        title: 'Política de Reembolso',
        accordionCancelledBefore: {
          title: 'Cancelaciones realizadas dentro de los 30 días posteriores a la contratación',
          titleHighlight: 'dentro de los 30 días',
          refundableSubscriptions: {
            label: 'Suscripciones reembolsables:',
            text: 'Planes de Web Hosting (Personal, Emprendedor, Negocios, Turbo, Criador de sites, Reseller, VPS, Dedicados), Correo profesional Titan, Productos adicionales (CodeGuard, SSL)',
          },
          nonRefundableSubscriptions: {
            label: 'Suscripciones NO reembolsables:',
            text: 'Registro de Domínios y correo G-suite.',
          },
          refundableCicles: {
            label: 'Ciclos reembolsables:',
            text: 'Todos (Mensual, trimestral, semestral, anual, bienal, trienal).',
          },
          refundableWays: {
            label: 'Forma de reembolso:',
            text: 'Valor reembolsado como crédito en HostGator para descontar en una próxima factura.',
          },
          exception: {
            label: 'Excepción:',
            text: 'En la cancelación de los planes de web hosting suscritos a partir de ciclos anuales con dominio gratis, se reembolsará el valor del plan, descontando el valor del dominio.',
          },
          footerNote: 'Política valida solo para nuevas contrataciones.',
        },
        accordionCancelledAfter: {
          title: 'Cancelaciones realizadas después de los 30 días de contratación',
          titleHighlight: 'después de los 30 días',
          refundableSubscriptions: {
            label: 'Suscripciones reembolsables:',
            text: 'Planes de Web Hosting (Personal, Emprendedor, Negocios, Turbo, Criador de sites).',
          },
          nonRefundableSubscriptions: {
            label: 'Suscripciones NO reembolsables:',
            text: 'Registro de Domínios y correo G-suite.',
          },
          refundableCicles: {
            label: 'Ciclos reembolsables:',
            text: 'Ciclos mayores que mensual (semestral, anual, bienal, trienal).',
          },
          nonRefundableCicles: {
            label: 'Ciclos NO reembolsables:',
            text: 'Ciclos mensuales.',
          },
          refundableCalculation: {
            label: 'Cálculo de reembolso:',
            text: 'Valor ($) de los meses restantes no utilizados de la suscripción - 30% de multa rescisoria',
          },
          exampleCalculation: {
            label: 'Exemplo de cálculo:',
            text: 'Si contrato un plan anual (12 meses) con el valor de [CONTRACT_VALUE] y utilizó 10 meses, será aplicada la multa de 30% en los [REMAINING_VALUE] restantes. Por tanto, el valor del reembolso será de [REFUND_VALUE]',
          },
          amountOwed: {
            label: 'Valor adecuado',
            annualContractAmount: '[CONTRACT_VALUE]',
            annualContractLabel: 'Contrato anual (12 meses)',
            usedAmount: '[USED_VALUE]',
            usedLabel: 'Utilizados (10 meses)',
          },
          amountAvailableRefund: {
            label: 'Valor disponible para reembolso',
            totalAmount: '[REMAINING_VALUE]',
            totalLabel: 'Total',
            terminationFinePercents: '30%',
            terminationFineLabel: 'Multa rescisoria',
          },
          refundableWays: {
            label: 'Forma de reembolso:',
            text: 'Valor reembolsado como crédito en HostGator para descontar en una próxima factura.',
          },
        },
        refundPolicyButtonLabel: 'Ver más sobre la Política de Reembolso',
        serviceTermsButtonLabel: 'Ver Términos del Servicio',
        serviceTermsUrl: 'https://www.hostgator.mx/terminos',
      },
      withRefund: {
        title: 'Vea las posibilidades de reembolso disponibles para su suscripción:',
        totalLabel: 'Total a reembolsar',
        refundPolitics: 'Ver política de reembolso',
        tipTitle: 'Recomendación:',
        tipText: 'Por este valor, es más beneficioso utilizar la suscripción hasta el final del ciclo, en {{date}}.',
        giveUp: 'No cancelar',
        selectMethodTitle: 'Seleccione una forma de reembolso:',
      },
      noRefund: {
        title: 'Vea las posibilidades de reembolso disponibles para su suscripción:',
        amountTitle: 'Total a reembolsar',
        withoutRefund: 'Esta suscripción no es reembolsable.',
        refundPolitics: 'Ver política de reembolso',
        tipTitle: 'Recomendación:',
        tipText: 'Es más beneficioso utilizar la suscripción hasta el final del ciclo, en',
        stopCancelLabel: 'No cancela',
      },
      review: {
        title: 'Verifique su información de cancelación:',
        totalLabel: 'Total a reembolsar',
        signatureTitle: 'Suscripción',
        reasonTitle: 'Motivo de la cancelación',
        refundTitle: 'Modo de Reembolso',
      },
      refundMethodOptions: {
        hg_credits: {
          name: 'Créditos HostGator',
          description: 'El reembolso será descontado en el valor de la próxima factura.',
          info: 'El reembolso será descontado en el valor de la próxima factura (caso exista).',
        },
        braspag_credit_1x: {
          name: 'Tarjeta de Credito',
          description: 'Usted recibirá el reembolso directamente en la factura de la Tarjeta de Crédito.',
          info: 'Usted recibirá el reembolso directamente en la factura de la Tarjeta de Crédito.',
        },
        dlocal: {
          name: 'Tarjeta de Credito',
          description: 'Usted recibirá el reembolso directamente en la factura de la Tarjeta de Crédito.',
          info: 'Usted recibirá el reembolso directamente en la factura de la Tarjeta de Crédito.',
        },
        gp_boleto: {
          name: 'Pix',
          description: '',
          info: '',
        },
        gp_paypal: {
          name: 'PayPal',
          description: 'Usted recibirá el reembolso directamente en la cuenta de PayPal utilizada en la contratación.',
          info: 'Usted recibirá el reembolso directamente en la cuenta de PayPal.',
        },
        paypal: {
          name: 'PayPal',
          description: 'Usted recibirá el reembolso directamente en la cuenta de PayPal utilizada en la contratación.',
          info: 'Usted recibirá el reembolso directamente en la cuenta de PayPal.',
        },
      },
      processing: (
        <>
          <p>
            Estamos procesando su solicitud.
          </p>
          <p>
            Por favor, no cierre esta ventana.
          </p>
        </>
      ),
    },
    cancelSubscription: 'CANCELAR SUSCRIPCIÓN',
    paymentCycle: {
      Monthly: 'Mensual',
      Quarterly: 'Trimestral',
      SemiAnnually: 'Semestral',
      Annually: 'Anual',
      Biennially: 'Bienal',
      Triennially: 'Trienal',
      FreeAccount: 'Trial',
    },
    perCycle: {
      Monthly: '/ mes',
      Quarterly: '/ 3 meses',
      SemiAnnually: '/ 6 meses',
      Annually: '/ año',
      Biennially: '/ 2 años',
      Triennially: '/ 3 años',
      FreeAccount: '',
    },
    notifications: {
      cancelRenewalPartOne: 'La cancelación de la renovación de la suscripción',
      cancelRenewalPartTwo: 'no se puede completar. Por favor, inténtelo de nuevo más tarde.',
      activateRenewal: {
        success: {
          0: 'La renovación de su suscripción ',
          1: ' fue activado',
        },
        failure: {
          0: 'Cancelar la renovación de la suscripción ',
          1: ' no se pudo completar. Por favor, inténtelo de nuevo más tarde.',
        },
      },
      cancelRenewal: {
        retry: {
          0: 'Se puede realizar una nueva cancelación de renovación de suscripción ',
          1: '24 horas después de la última reactivación ',
          2: 'de la renovación de suscripción',
        },
      },
      cancelSignature: {
        success: {
          0: 'La suscripción ',
          1: ' fue cancelada.',
        },
        failure: {
          0: 'La cancelación de la suscripción ',
          1: ' no se puede completar. Por favor, inténtelo de nuevo más tarde.',
        },
      },
      switchPaymentMethod: {
        failure: 'No se pudo guardar en el momento. Por favor, inténtalo más tarde. :)',
      },
    },
    modal: {
      activateRenew: {
        title: '¿Quieres activar la renovación de la suscripción?',
        content: 'El pago se cargará al final del ciclo, evitando que su producto sea cancelado y sus datos y archivos se eliminen permanentemente.',
        buttonRenewalLabel: 'Confirmar activación',
      },
    },
    urls: {
      domain: 'dominios',
    },
  },
  duplicateOrdersModal: {
    modalTitle: 'Pedidos duplicados',
    modalDescription: (
      <>

        {'Tiene dos o más pedidos para el '}
        <strong>
          {'mismo producto y dominio, '}
        </strong>
        y esto puede generar cobros duplicados. ¿Quiere cancelar alguno?
      </>
    ),
    modalDescriptionMobile: (
      <>
        {'Tiene dos o más pedidos para el '}
        <strong>mismo producto y dominio.</strong>
        <br />
        Vea abajo:
      </>
    ),
    invoicePendingDescription: (invoiceId, date, hour) => (
      <>
        {'Factura '}
        <strong>{invoiceId}</strong>
        {' generada el día '}
        <strong>{date}</strong>
        {' a las '}
        <strong>
          {hour}
          h.
        </strong>
      </>
    ),
    invoicePendingText: ' Si el pedido fue generado por engaño, puede cancelarlo.',
    invoicePayedDescription: (invoiceId, date, hour) => (
      <>
        {'Factura '}
        <strong>{invoiceId}</strong>
        {' generada el día '}
        <strong>{date}</strong>
        {' a las '}
        <strong>
          {hour}
          h.
        </strong>
        <br />
      </>
    ),
    invoicePayedText: 'El pago de este pedido ya ha sido realizado y el producto está activo.',
    showInvoice: 'Ver factura',
    cancelOrder: 'Cancelar pedido',
    planNameAccordion: (planName, domain) => (
      <>
        {`${planName} + dominio ${domain}`}
      </>
    ),
    domainNameAccordion: domain => (
      <>
        {`Dominio ${domain}`}
      </>
    ),
    payedTag: 'PAGO',
    pendingTag: 'PENDIENTE',

    successTitle: 'Pedido cancelado con éxito',
    successAlertLabel: (domain, invoiceId, date, hour, plan = null) => (
      <>
        {'Su pedido '}
        <strong>
          {plan ? `${plan} + dominio ${domain}, ` : `Domínio ${domain}, `}
          {`factura ${invoiceId}`}
        </strong>
        {' generada el día '}
        <strong>
          {date}
        </strong>
        {' a las '}
        <strong>
          {hour}
          h
        </strong>
        {' fue cancelado con éxito.'}
      </>
    ),
    okButtonLabel: 'Ok, entendí',

    failureTitle: 'Error al cancelar',
    failureAlertLabel: (domain, invoiceId, date, hour, plan = null) => (
      <>
        {'Tuvimos un problema al cancelar su pedido '}
        <strong>
          {plan ? `${plan} + dominio ${domain}, ` : `Domínio ${domain}, `}
          {`factura ${invoiceId}`}
        </strong>
        {' generada el día '}
        <strong>
          {date}
        </strong>
        {' a las '}
        <strong>
          {hour}
          h.
        </strong>
        <br />
        <br />
        Espere algunos instantes e inténtelo nuevamente.
      </>
    ),
    tryAgainButtonLabel: 'Intentar nuevamente',
    choiceOrderDescription: (
      <>
        {'Tienes '}
        <strong>más de un pedido con cargos duplicados.</strong>
        {' Selecciona el pedido que deseas ver en la lista a continuación:'}
      </>
    ),
    selectOrderTitle: 'Pedidos duplicados',
    selectOrderPlaceholder: 'Seleccionar el pedido',
    choiceOrderButtonLabel: 'Ir al pedido duplicado',
  },
  changePaymentMethodModal: {
    defaultStep: {
      successPayment: 'Pago confirmado',
      successRegister: 'Tarjeta de Crédito registrada',
      signaturePaymentChanged: 'Pago cambiado a Tarjeta de Crédito',
      signaturePaymentChangedSubtitle: 'Su pago será debitado automáticamente en la fecha de vencimiento.',
      changePaymentLabel: '¿Le gustaría aprovechar para cambiar la forma de pago de sus otras suscripciones a Tarjeta de Crédito?',
      changePaymentDescription: 'Con el cobro automático por Tarjeta de Crédito, su pago se debita en la fecha de vencimiento de la factura, evitando así  que se olvide.',
      closeModalButtonLabel: 'No, gracias',
      changeToCcButtonLabel: 'Cambiar a Tarjeta de Crédito',
    },
    changeMethodStep: {
      changeToCcLabel: 'Cambiar a Tarjeta de Crédito',
      descriptionLabel: '¿Para cuales productos le gustaría cambiar la forma de pago?',
      tableLabels: {
        product: 'Producto',
        paymentMethod: 'Forma de pago',
        nextRenew: 'Próxima renovación',
        renewValue: 'Valor de la renovación',
      },
      cancellButtonLabel: 'Cancelar',
      confirmButtonLabel: 'Cambiar a Tarjeta de Crédito',
      errorToast: 'Ocurrió un error inesperado, inténtalo más tarde',
    },
    successChangeMethodStep: {
      successChangeLabel: 'Los productos seleccionados se han actualizado a Tarjeta de Crédito.',
      confirmButtonLabel: 'Entendi, continuar navegando',
    },
  },
  creditCard: {
    changeCreditCard: 'Cambiar tarjeta',
    changeMethodTitle: '¡Simplifique su vida con el pago automático!',
    changeMethodDescription: 'Utilice su tarjeta para todas las suscripciones y olvídese de las facturas, con el cobro automático por tarjeta de crédito, su pago se debita cuando vence la factura, evitando así  que se olvide.',
    useThis: 'Usar esta Tarjeta para mis suscripciones',
  },
  defaultErrorMessage: 'Ocurrió un error inesperado, inténtalo más tarde',
};
