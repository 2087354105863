import styled, { css } from 'styled-components';

// THIS IS A MVP SO THE COLORS ARE FROM LINK NA BIO PRODUCT, NOT FROM CLIENT AREA
const colorSchemes = {
  HOSTGATOR_DARK_BLUE: {
    primaryColor: '#1F2044',
    secondaryColor: '#FFFFFF',
    funny: {
      borderColor: '#1F2044',
      buttonColor: '#FFFFFF',
      headerColor: '#FFFFFF',
    },
  },
  NEON: {
    primaryColor: '#14FFEC',
    secondaryColor: '#212121',
    funny: {
      borderColor: '#212121',
      buttonColor: '#FFFFFF',
      headerColor: '#212121',
    },
  },
  PASTEL: {
    primaryColor: '#FFF8E0',
    secondaryColor: '#171717',
    funny: {
      borderColor: '#171717',
      buttonColor: '#FFFFFF',
      headerColor: '#171717',
    },
  },
  WARM: {
    primaryColor: '#EBDAFB',
    secondaryColor: '#FD5F37',
    funny: {
      borderColor: '#212121',
      buttonColor: '#FFFFFF',
      headerColor: '#212121',
    },
  },
  HOSTGATOR_BLUE: {
    primaryColor: '#FFFFFF',
    secondaryColor: '#0070D1',
    funny: {
      borderColor: '#0070D1',
      buttonColor: '#FFFFFF',
      headerColor: '#0070D1',
    },
  },
  SIMPLE: {
    primaryColor: '#FFFFFF',
    secondaryColor: '#1F2044',
    funny: {
      borderColor: '#1F2044',
      buttonColor: '#FFFFFF',
      headerColor: '#1F2044',
    },
  },
  GRADIENT_ROSE: {
    isGradient: true,
    primaryColor: '#F44369',
    secondaryColor: '#3E3B92',
    funny: {
      borderColor: '#212121',
      buttonColor: '#FFFFFF',
      headerColor: '#FFFFFF',
    },
  },
  GRADIENT_WARM: {
    isGradient: true,
    primaryColor: '#2E93EE',
    secondaryColor: '#F69E52',
    funny: {
      borderColor: '#212121',
      buttonColor: '#FFFFFF',
      headerColor: '#FFFFFF',
    },
  },
  GRADIENT_DARK: {
    isGradient: true,
    primaryColor: '#0E1C26',
    secondaryColor: '#294861',
    funny: {
      borderColor: '#212121',
      buttonColor: '#FFFFFF',
      headerColor: '#FFFFFF',
    },
  },
  fillColor: '#FFFFFF',
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.v2.colors.neutral.highPure};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 900px;
    padding: 52px;
    position: relative;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 24px;
      padding-bottom: 100px;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 500px;

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`;

export const BackButton = styled.div`
  position: absolute;
  bottom: 30px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.sm};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 7px 0 20px 0;
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    display: flex;
    gap: 8px;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
  `}
`;

export const Snappy = styled.img`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: ${theme.breakpoints.sm}) {
      height: 160px;
    }
  `}
`;

export const ColorForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 2px;
  margin-top: 25px;
  width: 400px;
`;

export const TemplateForm = styled.form`
  ${({ theme }) => css`
  display: flex;
  gap: 32px;
  margin-top: 4px;
  margin-bottom: 32px;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 25px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${theme.v2.colors.neutral.highMedium}; 
    border-radius: 25px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
      overflow: auto;
  }
  `}
`;

export const TemplateName = styled.form`
  ${({ theme }) => css`
    color: ${theme.v2.colors.brand.menuPrimaryDark};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xxs};
    font-weight: ${theme.v2.font.weights.medium};
    margin-top: 15px;
  `}
`;

export const Radio = styled.input``;

export const IconCheck = styled.div`
  ${({ theme, pill }) => css`
    align-items: center;
    background: ${theme.v2.colors.neutral.highPure};
    border-radius: 25px;
    display: flex;
    height: 32px;
    justify-content: center;
    left: ${pill ? '33%' : '36.5%'};
    position: absolute;
    top: ${pill ? '8%' : '45.5%'};
    width: 32px;
    z-index: 1;
  `}
`;

export const ColorPill = styled.div`
  ${({ theme, variant, checked }) => css`
      background: ${colorSchemes[variant].isGradient ? `linear-gradient(180deg, ${colorSchemes[variant].primaryColor} 0%, ${colorSchemes[variant].secondaryColor} 100%)` : `linear-gradient(90deg, ${colorSchemes[variant].primaryColor} 50%,  ${colorSchemes[variant].secondaryColor} 50%)`};
      
      border-color: ${theme.v2.colors.neutral.highMedium};
      border-radius: 25px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      height: 40px;
      opacity: ${checked ? 0.7 : 1};
      position: relative;
      width: 96px;

      &:hover {
        opacity: 0.7;
      }

      input {
        display: none;
      }
    `}
`;

export const TemplateCard = styled.div`
  ${({ theme, variant, checked }) => css`
      background: ${colorSchemes[variant].isGradient ? `linear-gradient(${colorSchemes[variant].primaryColor}, ${colorSchemes[variant].secondaryColor})` : colorSchemes[variant].primaryColor};      
      border-color: ${theme.v2.colors.neutral.highMedium};
      border-radius: 18px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      height: 160px;
      opacity: ${checked ? 0.7 : 1};
      position: relative;
      width: 120px;

      &:hover {
        opacity: 0.7;
      }

      input {
        display: none;
      }
    `}
`;

export const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TemplateHeader = styled.div`${({ template }) => {
  const templates = {
    MODERN: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    FRIENDLY: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    MINIMALIST: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    FUNNY: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
  };

  return css`
    align-items: ${templates[template].alignItems};
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 56px;
    justify-content: ${templates[template].justifyContent};
    padding: 8px;
    width: 100%;
  `;
}}`;

export const TemplateBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

export const TemplateRow = styled.div`
${({ variant, size, templateType }) => {
    const isHeader = size !== 'row';

    const templates = {
      MODERN: {
        background: colorSchemes[variant].secondaryColor,
        gradientBackground: colorSchemes.fillColor,
        gradientBorder: 'transparent',
      },
      FRIENDLY: {
        background: colorSchemes[variant].secondaryColor,
        borderRadius: '16px',
        gradientBackground: colorSchemes.fillColor,
        gradientBorder: 'transparent',
      },
      MINIMALIST: {
        background: isHeader ? colorSchemes[variant].secondaryColor : 'transparent',
        borderColor: !isHeader && colorSchemes[variant].secondaryColor,
        borderStyle: !isHeader && 'solid',
        borderWidth: !isHeader && '0px 0px 2px 0px',
        gradientBackground: isHeader ? colorSchemes.fillColor : 'transparent',
        gradientBorder: colorSchemes.fillColor,
      },
      FUNNY: {
        background: isHeader ? colorSchemes[variant].funny.headerColor : colorSchemes[variant].funny.buttonColor,
        borderColor: colorSchemes[variant].funny.borderColor,
        borderRadius: '4px',
        borderStyle: !isHeader && 'solid',
        borderWidth: !isHeader && '1px 3px 3px 1px',
        gradientBackground: colorSchemes[variant].funny.buttonColor,
        gradientBorder: colorSchemes[variant].funny.borderColor,
      },
    };

    const widths = {
      title: '47px',
      description: '62px',
      row: '104px',
    };

    return css`
      background: ${colorSchemes[variant].isGradient ? templates[templateType].gradientBackground : templates[templateType].background};
      border-color: ${colorSchemes[variant].isGradient ? templates[templateType].gradientBorder : templates[templateType].borderColor};
      border-radius: ${isHeader ? '16px' : templates[templateType].borderRadius};
      border-style: ${templates[templateType].borderStyle};
      border-width: ${templates[templateType].borderWidth};
      height: ${isHeader ? '4px' : '24px'};
      width: ${widths[size]};
    `;
  }}
`;
