import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({
    theme, disabled, variant, maxWidth, minWidth,
  }) => {
    const variants = {
      default: {
        borderBottom: `1px solid ${theme.v1.color.line.wizard}`,
      },
    };

    return css`
    border-bottom: ${variants[variant].borderBottom};
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: ${minWidth || 'unset'};
    max-width: ${maxWidth || 'unset'};
    pointer-events: ${disabled ? 'none' : 'initial'};
    position: relative;
    width: 100%;
    `;
  }}
`;

export const Field = styled.div`
  ${({
    theme, variant, isOpen,
  }) => {
    const variants = {
      default: {
        backgroudColor: `${theme.v1.color.white.primary}`,
        border: 'none',
        maxHeight: '42px',
        padding: '0px',
        transform: `rotateX(${isOpen ? '-180deg' : '0deg'})`,
      },
    };

    return css`
      align-items: center;
      background-color:  ${variants[variant].backgroudColor};
      border: ${variants[variant].border};
      cursor: pointer;
      display: flex;
      flex: 1;
      max-height: ${variants[variant].maxHeight};
      padding:${variants[variant].padding};
      position: relative;

      svg {
        transform: ${variants[variant].transform};
        transition: transform 200ms ease;
      }
    `;
  }
}
`;

export const Label = styled.p`
  ${({ theme, variant, selected }) => {
    const variants = {
      default: {
        color: selected ? theme.v1.color.text.primary : theme.v1.color.line.fields,
        fontWeight: theme.v1.font.weights.regular,
        margin: '0px',
        maxWidth: 'calc(100% - 30px)',
        padding: '10px',
      },
    };

    return css`
      color: ${variants[variant].color};
      font-family: ${theme.v1.font.family.primary};
      font-size: 16px;
      font-weight: ${variants[variant].fontWeight};
      margin: ${variants[variant].margin};
      max-width: ${variants[variant].maxWidth};
      padding: ${variants[variant].padding};
      overFlow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  }}
`;

export const IconWrapper = styled.div`
${({
    variant,
  }) => {
    const variants = {
      default: {
        right: '8px',
      },
    };

    return css`
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      right: ${variants[variant].right};
    `;
  }
}
`;

export const OptionsWrapper = styled.div`
  ${({ theme, show, optionsPosition }) => css`
    bottom: ${optionsPosition === 'top' && 'calc(100% + 1px)'};
    box-shadow: -2px 2px 10px #00000024;
    opacity: ${show ? '1' : '0'};
    position: absolute;
    right: -1px;
    top: ${optionsPosition === 'bottom' && 'calc(100% - 1px)'};
    transition: opacity 200ms ease-in-out;
    visibility: ${show ? 'visible' : 'hidden'};
    width: 100%;
    z-index: ${theme.layers.awaysOnTop};
  `}
`;

export const OptionsList = styled.ul`
  ${({
    theme, show, fixedBottomOption, variant, optionsHeight, breakLine,
  }) => {
    const variants = {
      default: {
        border: '0',
        borderRadius: '25px',
        overflow: breakLine ? 'visible' : 'hidden',
        scrollbarWidth: '7px',
        textOverflow: breakLine ? 'clip' : 'ellipsis',
        whiteSpace: breakLine ? 'normal' : 'nowrap',
      },
    };

    const props = {
      listHeight: optionsHeight || 'unset',
    };

    return css`
      background-color: ${theme.palette.white.light};
      border-bottom:${fixedBottomOption ? 0 : `1px solid ${theme.palette.grey.variant}`};
      border-left: 1px solid ${theme.palette.grey.variant};
      border-right: 1px solid ${theme.palette.grey.variant};
      list-style: none;
      margin: 0;
      max-height: ${show ? props.listHeight : '0px'};
      opacity:  ${show ? 1 : 0};
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      li {
        p {
          text-overflow: ${variants[variant].textOverflow};
          white-space: ${variants[variant].whiteSpace};
          overflow: ${variants[variant].overflow};
        }
      }

      @media(min-width: ${theme.breakpoints.lg}) {
        max-height: ${show ? props.listHeight : '0px'};
      }

      &::-webkit-scrollbar {
        width: ${variants[variant].scrollbarWidth};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.palette.white.light};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.palette.grey.primary};
        border-left: ${variants[variant].border} solid ${theme.palette.white.light};
        border-radius: ${variants[variant].borderRadius};
        border-right: ${variants[variant].border} solid ${theme.palette.white.light};
        height: 30px;
        width: 100%;
      }
    `;
  }
}
`;

export const OptionItem = styled.li`
  ${({ theme, variant }) => {
    const variants = {
      default: {
        hoverBackgroundColor: theme.v1.color.action.primary,
        hoverColor: theme.v1.color.white.primary,
      },
    };

    return css`
      align-items: center;
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: ${variants[variant].hoverBackgroundColor};

        p{
          color: ${variants[variant].hoverColor}
        }
      }
    `;
  }
}
`;

export const OptionLabel = styled.p`
  ${({ theme }) => css`
    color: ${theme.v1.color.text.primary};
    font-family: ${theme.v1.font.family.primary};
    font-size: 14px;
    font-weight: ${theme.v1.font.weights.regular};
    letter-spacing:0.25px;
    line-height: 20px;
    margin: 0px;
    padding: 10px 16px;
  `}
`;
