import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    padding: 0 40px 40px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 8px 0 0 0;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme, width }) => css`
    background: ${theme.v2.colors.white.primary};
    padding: 24px;
    margin-bottom: 16px;
    width: ${width === 'half' ? '50%' : 'full'};

    @media (max-width: ${theme.v2.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const FaqHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const PasswordFormContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 320px;
    width: 100%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      height: 100%;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.md};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0 0 24px 0;
  `}
`;

export const FormContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    padding-right: 20px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const InputContainer = styled.div`
  ${({ error }) => css`
    margin-bottom: ${error ? '0' : '20px'};
  `}
`;

export const ListContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 50%;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      margin-bottom: 20px;
      padding: 0;
    }
  `}
`;

export const ThirdFormContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
    padding-right: 20px;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      order: 2;
      padding: 0;
    }
  `}
`;

export const ThirdFormField = styled.div`
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;

    @media (max-width: ${theme.v2.breakpoints.md}) {
      justify-content: center;
    }
  `}
`;

export const FaqButtonContainer = styled(ButtonContainer)`
  justify-content: center;
  width: 100%;
`;

export const FormField = styled.input`
  margin-bottom: 10px;
  width: 100%;
`;

export const IconDot = styled.div`
  ${({ theme }) => css`
    background: ${theme.v2.colors.feedback.problemMedium};
    border-radius: 50%;
    height: 4px;
    width: 4px;
  `}
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 24px;
`;

export const RequirementsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RequirementsTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.weights.medium};
    margin: 0;
  `}
`;

export const RequirementsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  ${({ theme, success }) => css`
    color: ${success ? theme.v2.colors.feedback.activeMedium : theme.v2.colors.feedback.problemMedium};
    display: flex;
    font-size: ${theme.v2.font.sizes.xxs};
    gap: 8px;
    margin-bottom: 4px;
  `}
`;
