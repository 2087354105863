import moment from 'moment';
import { parse, isBefore, format } from 'date-fns';

const isQueryParam = (param = 'debug') => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

/**
 * Check if the given date is lower than current
 *
 * @param {String} date
 * @param {String} formatModel
 * @param {String} dateNow
 */
export const enhancedIsDateLowerThanCurrent = (date, formatModel, dateNow) => {
  const now = new Date();
  const formatConveter = formatModel === 'MM/YY' ? 'MM/yy' : formatModel;
  const dateEntered = parse(date, formatConveter, now);
  const dateToCompareParse = parse(dateNow || format(now, formatConveter), formatConveter, now);
  return isBefore(dateEntered, dateToCompareParse);
};

/**
 * Check if the given date is lower than current
 *
 * @param {String} date
 * @param {String} format
 */
export const oldIsDateLowerThanCurrent = (date, format) => {
  const currentDate = moment();
  const currentDateFormatted = moment(currentDate.format(format), format);
  return moment(date, format).isBefore(currentDateFormatted);
};

export const isDateLowerThanCurrent = (date, formatModel, dateNow, isEnabled) => {
  if (isQueryParam()) {
    const libDateActive = isEnabled ? 'date-fns' : 'moment';
    // Necessário para testar lib que é executada com feature toggle.
    // eslint-disable-next-line no-console
    console.log('@lib enabled:', libDateActive);
  }

  return (isEnabled
    ? enhancedIsDateLowerThanCurrent(date, formatModel, dateNow)
    : oldIsDateLowerThanCurrent(date, formatModel));
};
