import billing from './billing';
import useDnsModal from './useDnsModal';
import useBlockedWindowModal from './useBlockedWindowModal';
import routes from './routes';
import sites from './sites';
import domains from './domains';
import products from './products';
import asaas from './asaas';
import alerts from './alerts';
import preChat from './preChat';
import ticket from './ticket';
import backup from './backup';
import security from './security';
import myData from './myData';
import emailVerify from './emailVerify';
import template from './template';
import phoneValidation from './phoneValidation';
import videoClasses from './videoClasses';
import linkNaBio from './linkNaBio';
import cloudflare from './cloudflare';
import { config } from '@/config';

const contents = {
  billing,
  routes,
  sites,
  domains,
  products,
  asaas,
  alerts,
  preChat,
  ticket,
  backup,
  security,
  myData,
  emailVerify,
  template,
  phoneValidation,
  videoClasses,
  linkNaBio,
  useDnsModal,
  useBlockedWindowModal,
  cloudflare,
};

const getCountry = () => config.COUNTRY.toUpperCase();

const defaultCountry = getCountry();

const get = (type, brand = defaultCountry) => contents[type][brand];

const getAll = (brand = defaultCountry) => {
  const reduced = Object.keys(contents).reduce((obj, key) => ({
    ...obj,
    [key]: contents[key][brand],
  }), {});

  return reduced;
};

const locale = {
  get,
  getAll,
};

export default locale;
