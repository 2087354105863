/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, Typography, Collapse, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';
import { useParams } from 'react-router';
import { filterEmailsAddress } from '@/redux/actions/emails';
import InputField from '@/components/Fields/InputField';
import FilterButton from '@/components/Buttons/FilterButton';
import styles from './styles';


const FilterEmails = ({ classes, t }) => {
  const { domain } = useParams();

  const [expand, setExpand] = useState(false);
  const [email, setEmail] = useState('');

  const emailState = useSelector(state => state.emails);
  const { total } = emailState;
  const { hosting } = emailState.hostings.list;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1165px)');

  const handleCollapse = () => {
    isMobile ? setExpand(false) : setExpand(true);
  };

  useEffect(() => {
    handleCollapse();
  }, []);

  useEffect(() => {
    dispatch(filterEmailsAddress(email));
  }, [email]);

  const handleExpandCollapse = () => {
    setExpand(!expand);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  if (isMobile && total < 5) {
    return null;
  }

  return (
    <Card className={classes.card} data-id="page-filter-card">
      {!isMobile && (
        <div className={classes.startContentWrapper}>
          <Typography variant="h6" className={classes.listTitle} data-id="email-page-title">{t('lists.emails')}</Typography>
          {hosting > 0 && (
            <FilterButton target={`${t('routes.emails')}${t('routes.createEmail')}${domain ? `/${domain}` : ''}`} dataId="button-create-emails">
              {t('emails.createAccount')}
            </FilterButton>
          )}
        </div>
      )}

      {total > 5 && (
        <div className={classes.filterContainer}>
          <Typography variant="h6" className={classes.filterTitle}>{t('filter.title')}</Typography>

          <Collapse in={expand || !isMobile} className={classes.collapseWrapper}>
            <div className={classes.root}>
              <InputField
                className={classes.emailInput}
                value={email}
                label={t('filter.emails')}
                onChange={event => handleChange(event)}
              />
            </div>
          </Collapse>

          {isMobile && (
            <div className={classes.expandArrow}>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expand,
                })}
                onClick={() => handleExpandCollapse()}
              >
                <ExpandMore className={classes.arrow} />
              </IconButton>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default withStyles(styles, { withTheme: true })(withI18n()(FilterEmails));
