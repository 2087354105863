import styled, { css } from 'styled-components';

export const HostingPlatformsGridWrapper = styled.div`
  ${({ theme, maxGridColumns }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    > *:nth-child(2n-1):nth-last-of-type(1) {
      grid-column: span 2;
    }

    @media (min-width: ${theme.v1.breakpoints.sm}) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;

      > *:nth-child(2n-1):nth-last-of-type(1) {
        grid-column: span 1;
      }
    }

    @media (min-width: ${theme.v1.breakpoints.md}) {
      grid-template-columns: 1fr 1fr 1fr ;
    }

    @media (min-width: ${theme.v1.breakpoints.lg}) {
      grid-template-columns: 1fr 1fr 1fr 1fr ;
    }

    @media (min-width: ${theme.v1.breakpoints.xl}) {
      grid-template-columns: repeat(${maxGridColumns}, 1fr);
    }
  `}
`;
