export const InvoicesActionTypes = {

  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',
  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',

  INVOICE_DETAILS_FAILURE: 'INVOICE_DETAILS_FAILURE',
  INVOICE_DETAILS_REQUEST: 'INVOICE_DETAILS_REQUEST',
  INVOICE_DETAILS_SUCCESS: 'INVOICE_DETAILS_SUCCESS',

  GENERATE_PIX_FAILURE: 'GENERATE_PIX__FAILURE',
  GENERATE_PIX_REQUEST: 'GENERATE_PIX__REQUEST',
  GENERATE_PIX_SUCCESS: 'GENERATE_PIX__SUCCESS',

  CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',
  CANCEL_ORDER_LOADING: 'CANCEL_ORDER_LOADING',

  SPLIT_PAYMENT_FAILURE: 'SPLIT_PAYMENT_FAILURE',
  SPLIT_PAYMENT_REQUEST: 'SPLIT_PAYMENT_REQUEST',
  SPLIT_PAYMENT_SUCCESS: 'SPLIT_PAYMENT_SUCCESS',

  GET_NFSE_FAILURE: 'GET_NFSE_FAILURE',
  GET_NFSE_REQUEST: 'GET_NFSE_REQUEST',
  GET_NFSE_SUCCESS: 'GET_NFSE_SUCCESS',
  SET_NFSE: 'SET_NFSE',

};
