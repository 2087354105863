export const getCookie = (name) => {
  const cookieValue = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  if (cookieValue) {
    return cookieValue[1];
  }
  return null;
};

export const setCookie = (name, value, onlyClientArea = true, expireDays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  const hostName = window.location.hostname.split('.');

  if (onlyClientArea === false) {
    hostName.shift();
  }

  const domain = hostName.join('.');
  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain}`;
};
