const styles = theme => ({
  cardFlexBox: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
    },
    alignItens: 'center',
    display: 'flex',
    paddingLeft: '24px',
    '& > svg': {
      marginRight: '10px',
    },
    '& > p': {
      fontWeight: '500',
      fontSize: '16px',
    },
  },
  invoiceCardBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardSummaryContent: {
    paddingTop: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  arrow: {
    fill: theme.color.tundora,
  },
  dialogActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  completeMessage: {
    color: theme.color.tundora,
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  circularProgressBtn: {
    marginRight: 10,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    justifyContent: 'space-between',
  },
  iconClose: {
    marginTop: '-10px',
  },
});

export default styles;
