import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class FireBottomLeft extends React.PureComponent {
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={`${className} ${classes.icon}`} viewBox="0 0 376.1 512">
        <g id="Agrupar_1186" transform="matrix(-0.438, 0.899, -0.899, -0.438, 106.257, 19.015)">
          <path
            id="Caminho_2467"
            className={classes.st0}
            d="M113.2-72.5c-0.6,17.1,0.7,35.4,14.2,40.3c6.3,1.9,13.2,0.7,18.5-3.2
            c1,4.8,1.4,9.8,1.3,14.7l0,0c0,6.1,5,11,11,11c6.1,0,11-5,11-11c0-14.8-1.8-32.5-15.1-37.3c-6.2-1.8-12.8-0.7-18.1,2.9
            c-0.8-5.5-1.1-11.1-0.8-16.7c0.1-3.5-1.5-6.9-4.2-9c-4.8-3.7-11.7-2.9-15.4,1.9C114.1-77.1,113.3-74.9,113.2-72.5z"
          />
          <path
            id="Caminho_2468"
            className={classes.st1}
            d="M-39-37.1c1.7,5.8,7.7,9.2,13.6,7.6c5.3-1.7,10.8-2.8,16.4-3.3c-2.3,6-1.7,12.7,1.5,18.3
            C0.3-2.7,17.9-5.2,32.3-8.8l0,0c5.9-1.4,9.5-7.4,8.1-13.3s-7.4-9.5-13.3-8.1c-4.8,1.3-9.7,2.1-14.6,2.3c2.5-6.1,2-13.1-1.3-18.8
            c-8.1-11.9-26-8.7-42.5-4.1c-2.2,0.6-4.2,2-5.7,3.8C-39.3-44.1-40-40.5-39-37.1z M9.2-28.1L9.2-28.1z"
          />
          <path
            id="Caminho_2469"
            className={classes.st2}
            d="M59.3-49.3c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6
            C68.1-47.2,62.9-46.5,59.3-49.3C59.3-49.3,59.3-49.3,59.3-49.3z"
          />
          <path
            id="Caminho_2470"
            className={classes.st0}
            d="M52.1-149.1c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6s-8,4.2-11.6,1.4
            C49.9-140.3,49.3-145.5,52.1-149.1z"
          />
          <path
            id="Caminho_2471"
            className={classes.st0}
            d="M148.9,27.2c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4
            C146.8,36,146.1,30.8,148.9,27.2z"
          />
          <path
            id="Caminho_2472"
            className={classes.st1}
            d="M335.2-356.2c-0.5,17.1,0.7,35.3,14.2,40.3c6.3,1.9,13.2,0.7,18.5-3.2
            c1,4.8,1.4,9.8,1.3,14.7c0.1,5.9,4.9,10.7,10.8,10.8c6.1,0.1,11.1-4.8,11.1-10.8c0-14.8-1.8-32.5-15.1-37.3
            c-6.2-1.8-12.8-0.7-18.1,2.9c-0.8-5.5-1.1-11.1-0.8-16.7c0.1-3.5-1.5-6.9-4.2-9c-4.8-3.7-11.7-2.9-15.4,1.9
            C336.1-360.8,335.3-358.6,335.2-356.2z"
          />
          <path
            id="Caminho_2473"
            className={classes.st2}
            d="M183-320.8c1.7,5.8,7.7,9.2,13.6,7.6c5.3-1.7,10.8-2.8,16.4-3.3c-2.3,6-1.7,12.7,1.5,18.3
            c7.9,11.8,25.4,9.3,39.8,5.7l0,0c5.9-1.4,9.5-7.4,8.1-13.3c-1.4-5.9-7.4-9.5-13.3-8.1c-4.8,1.3-9.7,2.1-14.6,2.3
            c2.5-6.1,2-13.1-1.3-18.8c-8.1-11.9-26-8.7-42.5-4.1c-2.2,0.6-4.2,2-5.7,3.8C182.8-327.8,182.1-324.2,183-320.8z M231.2-311.8
            L231.2-311.8z"
          />
          <path
            id="Caminho_2474"
            className={classes.st0}
            d="M281.3-333c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6
            C290.1-330.9,284.9-330.2,281.3-333C281.3-333,281.3-333,281.3-333z"
          />
          <path
            id="Caminho_2475"
            className={classes.st3}
            d="M251.4-206.3c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4
            C249.2-197.5,248.6-202.7,251.4-206.3z"
          />
          <path
            id="Caminho_2476"
            className={classes.st0}
            d="M274.1-432.8c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6c-2.8,3.6-8,4.2-11.6,1.4
            C271.9-424,271.3-429.2,274.1-432.8z"
          />
          <path
            id="Caminho_2477"
            className={classes.st2}
            d="M372.4-244.9c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6
            C381.2-242.7,376-242.1,372.4-244.9C372.4-244.9,372.4-244.9,372.4-244.9z"
          />
          <path
            id="Caminho_2478"
            className={classes.st4}
            d="M230.7-77.2c-3.6-2.8-4.2-8-1.4-11.6c2.8-3.6,8-4.2,11.6-1.4c3.6,2.8,4.2,8,1.4,11.6
            S234.2-74.4,230.7-77.2C230.7-77.2,230.7-77.2,230.7-77.2z"
          />
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(FireBottomLeft);
