/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import * as Styles from './SecuritySeal.styles';
import {
  Button, IconLoading, Input, Select, Skeleton,
} from 'gatorcomponents';
import { useDispatch } from 'react-redux';
import { commonActions } from '@/redux/modules';
import { SITELOCK_PRODUCT_NAME } from '../../SitelockDetailsHandler.types';
import { UpgradeBanner } from '../UpgradeBanner';
import useLocale from '@/hooks/useLocale/useLocale';
import { sitelockAnalytics } from '@/analytics/security/sitelock';

const { SITELOCK_PREMIUM, SITELOCK_ENTERPRISE } = SITELOCK_PRODUCT_NAME;

const SecuritySeal = ({
  domain,
  colorOptions,
  sizeOptions,
  typeOptions,
  languageOptions,
  handleSave,
  formData,
  setFormData,
  date,
  loading,
  saving,
  sealRequested,
  productName,
  isFree,
  upgradeBannerProps,
  copyTextToClipboard,
}) => {
  const dispatch = useDispatch();
  const { security: securityLocale } = useLocale();
  const sitelockLocale = securityLocale.detailsPage;
  const { security_seal: securitySealLocale } = sitelockLocale.tabs;
  const sealText = `<a href="#" onClick="window.open('https://www.sitelock.com/verify.php?site=${domain}','SiteLock','width=600,height=600,left=160,top=170');"><img className="img-fluid" alt="SiteLock" title="SiteLock" src="https://shield.sitelock.com/shield/${domain}" /></a>`;
  const [expandedSelect, setExpandedSelect] = useState(false);

  const handleChangeFormData = ({ e, key }) => {
    setFormData({
      ...formData,
      [key]: {
        label: e.label,
        value: e.value,
      },
    });
  };

  const copyText = async () => {
    copyTextToClipboard(sealText);

    sitelockAnalytics.newSitelockPage.gaCopySecuritySeal();

    dispatch(commonActions.notifications.set({
      label: ('Código do selo copiado!!'),
      type: 'success',
    }));
  };

  const getEditLabel = () => {
    if (!sealRequested) {
      return <IconLoading />;
    }

    return expandedSelect ? securitySealLocale.cancel : securitySealLocale.edit;
  };

  if (loading) {
    return (
      <>
        <Styles.SkeletonRow>
          <Skeleton variant="paragraph" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="52px">
          <Skeleton variant="textLine" />
          <Skeleton variant="rectangle" width="200px" height="120px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="24px">
          <Skeleton variant="textLine" width="320px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="24px" row>
          <Skeleton variant="button" />
          <Skeleton variant="button" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="32px">
          <Skeleton variant="rectangle" height="1px" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="24px">
          <Skeleton variant="headline" />
          <Skeleton variant="textLine" />
        </Styles.SkeletonRow>

        <Styles.SkeletonRow top="16px">
          <Skeleton variant="rectangle" height="156px" />
        </Styles.SkeletonRow>
      </>
    );
  }

  return (
    <>
      <Styles.Form onSubmit={handleSave}>
        <Styles.Description>{securitySealLocale.description[0]}</Styles.Description>
        <Styles.Description>{securitySealLocale.description[1]}</Styles.Description>

        <Styles.SmallTitle>
          {securitySealLocale.preview}
        </Styles.SmallTitle>

        <img
          className="img-fluid"
          alt="SiteLock"
          title="SiteLock"
          src={`//shield.sitelock.com/shield/${domain}?${date}`}
        />

        <Styles.SelectsWrapper expanded={expandedSelect}>
          <Styles.PreviewDescription>
            {securitySealLocale.saveToView}
          </Styles.PreviewDescription>

          <Select
            testId="selectLanguage"
            label={securitySealLocale.labelLanguage}
            options={languageOptions}
            value={formData.language.label}
            onClickSelect={e => handleChangeFormData({ e, key: 'language' })}
          />

          <Select
            testId="selectColor"
            label={securitySealLocale.labelColor}
            options={colorOptions}
            value={formData.color.label}
            onClickSelect={e => handleChangeFormData({ e, key: 'color' })}
          />

          <Select
            testId="selectSize"
            label={securitySealLocale.labelSize}
            options={sizeOptions}
            value={formData.size.label}
            onClickSelect={e => handleChangeFormData({ e, key: 'size' })}
          />

          {(productName === SITELOCK_PREMIUM || productName === SITELOCK_ENTERPRISE) && (
          <Select
            testId="selectStyle"
            label={securitySealLocale.labelStyle}
            options={typeOptions}
            value={formData.type.label}
            onClickSelect={e => handleChangeFormData({ e, key: 'type' })}
          />
          )}

          <Input
            label={securitySealLocale.labelCopyInput}
            type="textarea"
            rows={4}
            value={sealText}
          />
        </Styles.SelectsWrapper>

        <Styles.LinkWrapper>
          {securitySealLocale.howToInsert}
        </Styles.LinkWrapper>


        <Styles.ButtonsWrapper>
          <Button
            testId="toggleEdit"
            label={getEditLabel()}
            onClick={() => setExpandedSelect(!expandedSelect)}
            type="button"
            variant="secondary"
            disabled={!sealRequested}
          />

          {expandedSelect && (
            <Button
              testId="save"
              label={saving ? <IconLoading /> : securitySealLocale.save}
              variant={expandedSelect ? 'secondary' : 'primary'}
              type="submit"
              disabled={saving}
            />
          )}

          <Button
            testId="copy"
            label={securitySealLocale.copy}
            onClick={copyText}
            type="button"
          />
        </Styles.ButtonsWrapper>
      </Styles.Form>

      {isFree && (
        <Styles.BannerWrapper>
          <UpgradeBanner {...upgradeBannerProps()} />
        </Styles.BannerWrapper>
      )}
    </>
  );
};

export default SecuritySeal;
