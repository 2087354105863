export const assembledCloudflareDomains = (list) => {
  const external = list.filter(item => item.domain_origin === 'external');
  const externalNonexistent = [...external].filter(item => item.status === 'nonexistent');
  const externalInactive = [...external].filter(item => item.status === 'inactive');
  const externalPending = [...external].filter(item => item.status === 'pending');
  const externalDelay = [...external].filter(item => item.status === 'delay');
  const externalActive = [...external].filter(item => item.status === 'active');
  const externalActivationPointingError = [...external].filter(item => item.status === 'activation_pointing_error');
  const externalInactivationPointingError = [...external].filter(item => item.status === 'inactivation_pointing_error');

  const internal = list.filter(item => item.domain_origin === 'internal');
  const internalNonexistent = [...internal].filter(item => item.status === 'nonexistent');
  const internalInactive = [...internal].filter(item => item.status === 'inactive');
  const internalPending = [...internal].filter(item => item.status === 'pending');
  const internalDelay = [...internal].filter(item => item.status === 'delay');
  const internalActive = [...internal].filter(item => item.status === 'active');
  const internalActivationPointingError = [...internal].filter(item => item.status === 'activation_pointing_error');
  const internalInactivationPointingError = [...internal].filter(item => item.status === 'inactivation_pointing_error');

  const sortedList = [];
  sortedList.push(...externalNonexistent);
  sortedList.push(...internalPending);
  sortedList.push(...internalActivationPointingError);
  sortedList.push(...externalPending);
  sortedList.push(...internalDelay);
  sortedList.push(...externalDelay);
  sortedList.push(...externalActivationPointingError);
  sortedList.push(...internalInactivationPointingError);
  sortedList.push(...externalInactivationPointingError);
  sortedList.push(...internalActive);
  sortedList.push(...externalActive);
  sortedList.push(...internalNonexistent);
  sortedList.push(...internalInactive);
  sortedList.push(...externalInactive);
  return sortedList;
};
