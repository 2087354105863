import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


class FireBottomLeft extends React.PureComponent {
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <SvgIcon {...rest} className={`${classes.icon} ${className}`} viewBox="0 0 376.1 512">
        <g id="Agrupar_1186" transform="matrix(-0.438, 0.899, -0.899, -0.438, 106.257, 19.015)">
          <path
            id="Caminho_2467"
            className={classes.st0}
            d="M292.7-165.3c-6.1,0-11,4.9-11,11c0,3.5,1.7,6.8,4.5,8.9c4.6,3.2,8.8,6.8,12.7,10.9
            c-6.1,1.9-11.1,6.5-13.5,12.4c-4.5,13.5,8.4,25.7,20,34.9c4.8,3.8,11.7,2.9,15.5-1.8c3.8-4.8,2.9-11.7-1.8-15.5l0,0
            c-4-2.9-7.6-6.3-10.8-10.1c6.4-1.8,11.5-6.5,14-12.6c4.4-13.7-9.2-25.9-23-36C297.3-164.5,295-165.3,292.7-165.3z"
          />
          <path
            id="Caminho_2468"
            className={classes.st1}
            d="M412-265.7c-2.3,0-4.6,0.7-6.5,2.1c-13.8,10.1-27.4,22.3-23,36c2.5,6.1,7.6,10.8,14,12.6
            c-3.2,3.7-6.8,7.1-10.8,10.1c-4.8,3.8-5.6,10.7-1.8,15.5c3.8,4.8,10.7,5.6,15.5,1.8l0,0c11.7-9.2,24.5-21.4,20-34.9
            c-2.4-6-7.3-10.5-13.5-12.4c3.8-4.1,8.1-7.7,12.7-10.9c4.9-3.6,6-10.5,2.4-15.4C418.9-264,415.5-265.7,412-265.7z M398.3-217.7
            L398.3-217.7z"
          />
          <path
            id="Caminho_2469"
            className={classes.st2}
            d="M350.7-191.3c0,4.6-3.7,8.2-8.3,8.2c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C347-199.5,350.7-195.8,350.7-191.3C350.7-191.3,350.7-191.3,350.7-191.3z"
          />
          <path
            id="Caminho_2470"
            className={classes.st0}
            d="M284.8-250.2c0,4.6-3.7,8.2-8.3,8.2c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C281.1-258.5,284.8-254.8,284.8-250.2z"
          />
          <path
            id="Caminho_2471"
            className={classes.st0}
            d="M364-65.2c0,4.6-3.7,8.2-8.3,8.2c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C360.3-73.5,364-69.8,364-65.2z"
          />
          <path
            id="Caminho_2472"
            className={classes.st1}
            d="M-67.6-165.3c-6.1,0-11,4.9-11,11c0,3.5,1.7,6.8,4.5,8.9c4.6,3.2,8.9,6.8,12.7,10.9
            c-6.1,1.9-11.1,6.5-13.5,12.4c-4.5,13.5,8.4,25.7,20,34.9c4.8,3.7,11.7,2.7,15.4-2.1c3.6-4.7,2.8-11.4-1.8-15.2
            c-4-3-7.6-6.3-10.8-10.1c6.4-1.8,11.5-6.5,14-12.6c4.4-13.7-9.2-25.9-23-36C-62.9-164.5-65.2-165.3-67.6-165.3z"
          />
          <path
            id="Caminho_2473"
            className={classes.st2}
            d="M51.8-265.7c-2.3,0-4.6,0.7-6.5,2.1c-13.8,10.1-27.4,22.3-23,36c2.5,6.1,7.6,10.8,14,12.6
            c-3.2,3.7-6.8,7.1-10.8,10.1c-4.8,3.8-5.6,10.7-1.8,15.5s10.7,5.6,15.5,1.8l0,0c11.7-9.2,24.5-21.4,20-34.9
            c-2.4-6-7.3-10.5-13.5-12.4c3.8-4.1,8.1-7.7,12.7-10.9c4.9-3.6,6-10.5,2.4-15.4C58.6-264,55.3-265.7,51.8-265.7z M38-217.7
            L38-217.7z"
          />
          <path
            id="Caminho_2474"
            className={classes.st0}
            d="M-9.6-191.3c0,4.6-3.7,8.2-8.3,8.2s-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C-13.2-199.5-9.6-195.8-9.6-191.3C-9.6-191.3-9.6-191.3-9.6-191.3z"
          />
          <path
            id="Caminho_2475"
            className={classes.st3}
            d="M116.9-128.5c0,4.6-3.7,8.2-8.3,8.2c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C113.3-136.8,117-133.1,116.9-128.5z"
          />
          <path
            id="Caminho_2476"
            className={classes.st0}
            d="M-75.4-250.2c0,4.6-3.7,8.2-8.3,8.2s-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C-79.1-258.5-75.4-254.8-75.4-250.2z"
          />
          <path
            id="Caminho_2477"
            className={classes.st2}
            d="M3.7-65.2C3.7-60.7,0-57-4.5-57c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C0-73.5,3.7-69.8,3.7-65.2C3.7-65.2,3.7-65.2,3.7-65.2z"
          />
          <path
            id="Caminho_2478"
            className={classes.st4}
            d="M223.2-73.5c0,4.6-3.7,8.2-8.3,8.2c-4.6,0-8.2-3.7-8.2-8.3c0-4.5,3.7-8.2,8.2-8.2
            C219.5-81.8,223.2-78.1,223.2-73.5C223.2-73.5,223.2-73.5,223.2-73.5z"
          />
        </g>
      </SvgIcon>
    );
  }
}

export default withStyles(styles)(FireBottomLeft);
