import React, { useState, useEffect } from 'react';
import * as Styles from './HeaderV2.styles';
import { useResize } from '@/hooks/useResize';

import {
  IconSites as GatorIconSites,
  IconEmail as GatorIconEmail,
  IconDomain as GatorIconDomain,
  IconFile as GatorIconFile,
  IconSecurity as GatorIconSecurity,
  IconServices as GatorIconServices,
  IconCourses as GatorIconCourses,
  IconAsaas as GatorIconAsaas,
  IconSubscription as GatorIconSubscription,
  IconInvoice as GatorIconInvoice,
  IconSupport as GatorIconSupport,
  IconData as GatorIconData,
  IconCreditCard as GatorIconCreditCard,
  IconPassword as GatorIconPassword,
  FloatingMenu,
  Button,
  IconButton,
} from 'gatorcomponents';
import { supportIds } from './HeaderV2.enum';
import MyAlerts from '../MyAlerts/MyAlerts';
import { BackupModal } from './components/BackupModal';
import { backupActions, preChatActions, securityActions } from '@/redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { SitelockProvisionedModal } from './components/SitelockProvisionedModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CHARLIE_PROVISIONATED_SITELOCK_MODAL } from '@/config/GrowthBook/constants';
import { isBRServer } from '@/utils/Validators/validation';

const HeaderV2 = ({
  onToggleMenu,
  routeSupport = '/',
  labelSupport = 'sample support',
  handleClick,
  profileItems = [{
    id: 'sample profile id',
    leftIcon: 'IconData',
    leftIconSize: 'md',
    label: 'sample label',
    onClick: () => null,
  }],
  financialItems = [{
    id: 'sample financial id',
    leftIcon: 'IconData',
    leftIconSize: 'md',
    label: 'sample label',
    onClick: () => null,
  }],
  handleChangeRoute,
  pageIcon,
  hasGoBackButton,
  pageTitle = 'sample title',
  financialLabel = 'sample financial',
  profileLabel = 'sample profile',
  myAlertsLabel = 'sample alerts',
  allNotificationsLabel,
  ticketNotificationsLabel,
  seeMoreLabel,
  alertsTooltipLabel,
  noNotificationsLabel,
}) => {
  const [alertsLabel, setAlertsLabel] = useState(myAlertsLabel);
  const [profileMenuLabel, setProfileMenuLabel] = useState(profileLabel);
  const view = useResize();
  const isMobile = view.width < 960;
  const dispatch = useDispatch();
  const { showBackupRedirectModal, productId, redirectFromPreChat } = useSelector(state => state.backup);
  const { hasNewSitelock } = useSelector(state => state.security);
  const hasProvisionatedModal = useFeatureIsOn(CHARLIE_PROVISIONATED_SITELOCK_MODAL);
  const [sitelockModalIsClosed, setSiteLockModalIsClosed] = useState(false);

  const checkHasNewSitelock = () => dispatch(securityActions.newSiteLock.check.request());

  useEffect(() => {
    function setLabelByWidth() {
      if (window.innerWidth < 1280) {
        setAlertsLabel('');
        setProfileMenuLabel('');
      } else {
        setAlertsLabel(myAlertsLabel);
        setProfileMenuLabel(profileLabel);
      }
    }
    setLabelByWidth();

    if (hasProvisionatedModal) {
      checkHasNewSitelock();
    }

    window.addEventListener('resize', setLabelByWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleMenu = () => {
    onToggleMenu && onToggleMenu();
  };

  const renderIconElement = () => {
    if (hasGoBackButton) {
      return (
        <IconButton icon="IconArrow" onClick={handleChangeRoute} />
      );
    }

    if (isMobile) {
      return (
        <IconButton icon="IconMenu" onClick={handleToggleMenu} />
      );
    }

    const iconsList = {
      gatorIconEmail: <GatorIconEmail />,
      gatorIconSites: <GatorIconSites />,
      gatorIconDomain: <GatorIconDomain />,
      gatorIconFile: <GatorIconFile />,
      gatorIconSecurity: <GatorIconSecurity />,
      gatorIconServices: <GatorIconServices />,
      gatorIconCourses: <GatorIconCourses />,
      gatorIconAsaas: <GatorIconAsaas />,
      gatorIconInvoice: <GatorIconInvoice />,
      gatorIconSubscription: <GatorIconSubscription />,
      gatorIconSupport: <GatorIconSupport />,
      gatorIconData: <GatorIconData />,
      gatorIconCreditCard: <GatorIconCreditCard />,
      gatorIconPassword: <GatorIconPassword />,
    };

    return iconsList[pageIcon];
  };

  const getBackupModalProps = () => ({
    externalId: productId,
    onClose: (willGoToBackup) => {
      dispatch(backupActions.set.showModalBackupRedirect({ showModal: false, productId: null }));

      if (redirectFromPreChat && !willGoToBackup) {
        dispatch(preChatActions.preChat.setScreen('problemSolved'));
        dispatch(backupActions.set.redirectFromPreChat(false));
        dispatch(preChatActions.preChat.setOpen(true));
      }
    },
  });

  return (
    <Styles.Container>
      {showBackupRedirectModal && (
        <BackupModal {...getBackupModalProps()} />
      )}

      {isBRServer && hasProvisionatedModal && hasNewSitelock && !sitelockModalIsClosed && (
        <SitelockProvisionedModal setSiteLockModalIsClosed={setSiteLockModalIsClosed} />
      )}

      <Styles.Row>
        <Styles.IconWrapper>
          {renderIconElement()}
        </Styles.IconWrapper>

        <Styles.PageName title={pageTitle()}>
          {pageTitle()}
        </Styles.PageName>

        <Styles.ActionsWrapper>
          <Styles.FirstFloatingWrapper>
            <FloatingMenu
              label={financialLabel}
              items={financialItems}
              testId="financial"
            />
          </Styles.FirstFloatingWrapper>
          <Button
            testId="support"
            iconLeft={<GatorIconSupport />}
            variant="headerButton"
            label={labelSupport}
            onClick={() => handleClick({ id: supportIds.support, route: routeSupport, label: labelSupport })}
            size="large"
          />
          <Styles.FirstFloatingWrapper>
            <MyAlerts
              label={alertsLabel}
              titleLabel={myAlertsLabel}
              allNotificationsLabel={allNotificationsLabel}
              ticketNotificationsLabel={ticketNotificationsLabel}
              seeMoreLabel={seeMoreLabel}
              alertsTooltipLabel={alertsTooltipLabel}
              noNotificationsLabel={noNotificationsLabel}
              isMobile={isMobile}
            />
          </Styles.FirstFloatingWrapper>
          <Styles.LastFloatingWrapper>
            <FloatingMenu
              label={profileMenuLabel}
              leftIcon="IconPerfil"
              items={profileItems}
              testId="profile"
            />
          </Styles.LastFloatingWrapper>
        </Styles.ActionsWrapper>
      </Styles.Row>
    </Styles.Container>
  );
};

export default HeaderV2;
