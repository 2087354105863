import { DnsWizardActionTypes } from './dnsWizard.types';

const getHostingPlatformsRequest = domainId => ({ type: DnsWizardActionTypes.GET_HOSTING_PLATFORMS_REQUEST, domainId });
const getHostingPlatformsSuccess = () => ({ type: DnsWizardActionTypes.GET_HOSTING_PLATFORMS_SUCCESS });
const getHostingPlatformsFailure = () => ({ type: DnsWizardActionTypes.GET_HOSTING_PLATFORMS_FAILURE });

const configureForHostGatorHostingPlanRequest = (domainId, body) => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_REQUEST, domainId, body });
const configureForHostGatorHostingPlanSuccess = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_SUCCESS });
const configureForHostGatorHostingPlanFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_HOSTGATOR_HOSTING_PLAN_FAILURE });

const configureForCustomDnsRequest = (domainId, body) => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_REQUEST, domainId, body });
const configureForCustomDnsSuccess = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_SUCCESS });
const configureForCustomDnsFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_FAILURE });

const skipWizardSetToReserved = () => ({ type: DnsWizardActionTypes.SET_RESERVED_NS });

const configureForExternalPlatformRequest = (domainId, body) => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_REQUEST, domainId, body });
const configureForExternalPlatformSuccess = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_SUCCESS });
const configureForExternalPlatformFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_EXTERNAL_PLATFORM_FAILURE });

const configureForCustomDnsZoneRequest = (domainId, platformId, platformName) => ({
  type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_REQUEST, domainId, platformId, platformName,
});
const configureForCustomDnsZoneFromPlanRequest = (domainId, platformId, platformName) => ({
  type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_REQUEST, domainId, platformId, platformName,
});

const configureForCustomDnsZoneSuccess = platformName => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_SUCCESS, platformName });
const configureForCustomDnsZoneFromPlanSuccess = platformName => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_SUCCESS, platformName });
const configureForCustomDnsZoneFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FAILURE });
const configureForCustomDnsZoneFromPlanFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_CUSTOM_DNS_ZONE_FROM_PLAN_FAILURE });

const skipWizardRequest = domainId => ({ type: DnsWizardActionTypes.PUT_SKIP_WIZARD_REQUEST, domainId });
const skipWizardSuccess = () => ({ type: DnsWizardActionTypes.PUT_SKIP_WIZARD_SUCCESS });
const skipWizardFailure = () => ({ type: DnsWizardActionTypes.PUT_SKIP_WIZARD_FAILURE });

const dnsZonesRequest = domain => ({ type: DnsWizardActionTypes.GET_DNS_ZONES_REQUEST, domain });
const dnsZonesSuccess = () => ({ type: DnsWizardActionTypes.GET_DNS_ZONES_SUCCESS });
const dnsZonesFailure = () => ({ type: DnsWizardActionTypes.GET_DNS_ZONES_FAILURE });

const getEmailPlatformsRequest = domainId => ({ type: DnsWizardActionTypes.GET_EMAIL_PLATFORMS_REQUEST, domainId });
const getEmailPlatformsSuccess = () => ({ type: DnsWizardActionTypes.GET_EMAIL_PLATFORMS_SUCCESS });
const getEmailPlatformsFailure = () => ({ type: DnsWizardActionTypes.GET_EMAIL_PLATFORMS_FAILURE });

const putEmailPlatformRequest = (domainId, platformId) => ({
  type: DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_REQUEST, domainId, platformId,
});
const putEmailPlatformSuccess = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_SUCCESS });
const putEmailPlatformFailure = () => ({ type: DnsWizardActionTypes.PUT_CONFIGURE_EMAIL_PLATFORM_FAILURE });

const resetCustomDnsConfiguration = () => ({ type: DnsWizardActionTypes.RESET_CUSTOM_DNS_CONFIGURATION });

const changedPlan = () => ({ type: DnsWizardActionTypes.SET_CHANGED_PLAN });

const setHostingPlatforms = (dnsWizard = []) => ({
  type: DnsWizardActionTypes.SET_HOSTING_PLATFORMS,
  payload: {
    dnsWizard,
  },
});

const setEmailPlatforms = (emailPlatforms = []) => ({
  type: DnsWizardActionTypes.SET_EMAIL_PLATFORMS,
  payload: {
    emailPlatforms,
  },
});

const getEmailPlataform = ({ domainId }) => ({
  type: DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM,
  payload: domainId,
});

const getEmailPlataformSuccess = () => ({
  type: DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM_SUCCESS,
});

const getEmailPlataformFailure = () => ({
  type: DnsWizardActionTypes.REQUEST_SETTED_EMAIL_PLATFORM_SUCCESS,
});

const setCurrentEmailPlataform = payload => ({
  type: DnsWizardActionTypes.SET_CURRENT_EMAIL_PLATAFORM,
  payload,
});

const setHostingConfiguration = (configuration = []) => ({
  type: DnsWizardActionTypes.SET_HOSTING_CONFIGURATION,
  payload: {
    configuration,
  },
});

const setCustomDnsConfiguration = (configuration = []) => ({
  type: DnsWizardActionTypes.SET_CUSTOM_DNS_CONFIGURATION,
  payload: {
    configuration,
  },
});

const setExternalPlatform = (platformName, configuration = []) => ({
  type: DnsWizardActionTypes.SET_EXTERNAL_PLATFORM_CONFIGURATION,
  payload: {
    configuration,
    platformName,
  },
});

const setCustomDnsZoneConfiguration = (configuration = []) => ({
  type: DnsWizardActionTypes.SET_CUSTOM_DNS_ZONE_CONFIGURATION,
  payload: {
    configuration,
  },
});

const setSelectedConfiguration = (selectedConfiguration = {}) => ({
  type: DnsWizardActionTypes.SET_SELECTED_CONFIGURATION,
  payload: {
    selectedConfiguration,
  },
});

const setDnsZones = (dnsZones = {}) => ({
  type: DnsWizardActionTypes.SET_DNS_ZONES,
  payload: {
    dnsZones,
  },
});

const setPropagatingStatus = propagating => ({
  type: DnsWizardActionTypes.SET_PROPAGATING_STATUS,
  payload: propagating,
});

const actions = {
  platforms: {
    failure: getHostingPlatformsFailure,
    request: getHostingPlatformsRequest,
    set: setHostingPlatforms,
    success: getHostingPlatformsSuccess,
  },
  hostGatorPlanConfiguration: {
    failure: configureForHostGatorHostingPlanFailure,
    request: configureForHostGatorHostingPlanRequest,
    set: setHostingConfiguration,
    success: configureForHostGatorHostingPlanSuccess,
  },
  customConfiguration: {
    failure: configureForCustomDnsFailure,
    request: configureForCustomDnsRequest,
    set: setCustomDnsConfiguration,
    success: configureForCustomDnsSuccess,
  },
  customDnsZoneConfiguration: {
    failure: configureForCustomDnsZoneFailure,
    request: configureForCustomDnsZoneRequest,
    set: setCustomDnsZoneConfiguration,
    success: configureForCustomDnsZoneSuccess,
  },
  customDnsZoneFromPlanConfiguration: {
    failure: configureForCustomDnsZoneFromPlanFailure,
    request: configureForCustomDnsZoneFromPlanRequest,
    set: setCustomDnsZoneConfiguration,
    success: configureForCustomDnsZoneFromPlanSuccess,
  },
  defineConfiguration: {
    set: setSelectedConfiguration,
  },
  skipWizard: {
    failure: skipWizardFailure,
    request: skipWizardRequest,
    success: skipWizardSuccess,
    set: skipWizardSetToReserved,
  },
  dnsZones: {
    failure: dnsZonesFailure,
    request: dnsZonesRequest,
    success: dnsZonesSuccess,
    set: setDnsZones,
  },
  changedPlan: {
    request: changedPlan,
  },
  customDnsConfiguration: {
    reset: resetCustomDnsConfiguration,
  },
  externalConfiguration: {
    failure: configureForExternalPlatformFailure,
    request: configureForExternalPlatformRequest,
    success: configureForExternalPlatformSuccess,
    set: setExternalPlatform,
  },
  emailPlatforms: {
    request: getEmailPlatformsRequest,
    failure: getEmailPlatformsFailure,
    success: getEmailPlatformsSuccess,
    set: setEmailPlatforms,
    getSettedPlataform: getEmailPlataform,
    getEmailPlataformSuccess,
    getEmailPlataformFailure,
    setCurrentEmailPlataform,
  },
  propagatingStatus: {
    set: setPropagatingStatus,
  },
  emailPlatformsConfiguration: {
    request: putEmailPlatformRequest,
    failure: putEmailPlatformFailure,
    success: putEmailPlatformSuccess,
  },
};

export default actions;
