import React from 'react';
import * as Styles from './SubscriptionsPage.styles';
import { SubscriptionsHandler } from '@/components/billing';
import { useSelector } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  XRAY_ENABLED_MODAL_PAYMENT_IN_SUBSCRIPTION_LIST,
  XRAY_ENABLED_NEW_MESSAGES_IN_SUBSCRIPTION_LIST,
} from '@/config/GrowthBook/constants';

const SubscriptionsPage = () => {
  const preChatState = useSelector(state => state.preChat);
  const enabledNewMessagesInSubscription = useFeatureIsOn(XRAY_ENABLED_NEW_MESSAGES_IN_SUBSCRIPTION_LIST);
  const enabledModalPaymentInSubscriptionList = useFeatureIsOn(XRAY_ENABLED_MODAL_PAYMENT_IN_SUBSCRIPTION_LIST);


  return (
    <Styles.Wrapper data-testid="subscriptions-page">
      <SubscriptionsHandler
        enabledNewMessagesInSubscription={enabledNewMessagesInSubscription}
        enabledModalPaymentInSubscriptionList={enabledModalPaymentInSubscriptionList}
        preChatState={preChatState}
      />
    </Styles.Wrapper>
  );
};

export default SubscriptionsPage;
