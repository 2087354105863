const { isBRServer } = require('@/utils/Validators/validation');

export const shared = isBRServer
  ? [
    5,
    6,
    437,
    7,
    335,
    50,
    51,
    52,
    53,
    54,
    385,
    388,
    391,
    394,
    397,
    400,
    403,
    406,
    409,
    412,
    415,
  ]
  : [
    5,
    6,
    7,
    213,
    50,
    51,
    52,
    53,
    54,
    385,
    388,
    391,
    394,
    397,
    400,
    403,
    406,
    409,
    412,
    415,
  ];


export const productsChips = [
  {
    id: 'all',
    checked: true,
    value: 'all',
    label: 'Todos',
  },
  {
    id: 'pending',
    checked: false,
    value: 'pending',
    label: 'Com pendência financeira',
  },
  {
    id: 'security',
    checked: false,
    value: 'security',
    label: 'Segurança',
  },
  {
    id: 'backup',
    checked: false,
    value: 'backup',
    label: 'Backup',
  },
];
