import styled, { css } from 'styled-components';

export const Form = styled.form``;

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.v2.colors.neutral.lowPure};
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;

    strong {
      font-weight: ${theme.v2.font.weights.medium};
    }
  `}
`;

export const Agreed = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.v2.colors.neutral.lowPure};
    display: flex;
    font-family: ${theme.v2.font.family.primary};
    font-size: ${theme.v2.font.sizes.xs};
    font-weight: ${theme.v2.font.family.regular};
    margin: 24px 0px 24px;
  `}
`;

export const InputWrapper = styled.div`
  max-width: 514px;
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0px 0px 4px;

  label {
    width: 19px;
    height: 19px;
  }
`;
