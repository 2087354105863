import React, { useCallback, useState } from 'react';
import * as Styles from './WildcardSslCertificate.styles';
import {
  Button,
  Checkbox,
  IconData,
  IconLoading,
  Input,
} from 'gatorcomponents';
import useLocale from '@/hooks/useLocale/useLocale';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';

export const WildcardSslCertificate = ({
  selectedProduct,
  opening,
  submitForm,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const {
    widlcardSslCertificate: widlcardSslCertificateLocale,
  } = ticketsLocale.forms;
  const [formData, setFormData] = useState({
    domain: '',
    agreed: false,
  });

  const checkForm = useCallback(() => !(!!formData.domain && !!formData.agreed), [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: widlcardSslCertificateLocale.subject,
      server_value: selectedProduct.serverhostname,
      domain_value: selectedProduct.domain,
      key: V2Forms.WILDCARD_SSL,
      tags: [
        'whmcs_form_tecnico_certificado_ssl_wildcard_2',
        selectedProduct.name.toLowerCase().replace(/ /g, '_'),
      ],
      body: `
        • ${widlcardSslCertificateLocale.body.product(selectedProduct)}
        • ${widlcardSslCertificateLocale.body.request}
        • ${widlcardSslCertificateLocale.body.domain(formData.domain)}
        • ${widlcardSslCertificateLocale.body.agreed(formData.agreed)}
        • ${widlcardSslCertificateLocale.body.productId(selectedProduct)}
      `,
    };

    submitForm && submitForm(data);
  }, [
    ticketsLocale,
    selectedProduct,
    formData,
    submitForm,
    widlcardSslCertificateLocale,
  ]);

  const handleChange = useCallback(({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  }, [
    formData,
  ]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {widlcardSslCertificateLocale.info}
      </Styles.Info>

      <Styles.Title>
        {widlcardSslCertificateLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Input
          name="domain"
          placeholder={widlcardSslCertificateLocale.placeholders.domain}
          label={formData.domain ? widlcardSslCertificateLocale.placeholders.domain : ''}
          value={formData.domain}
          onChange={e => handleChange({ key: 'domain', value: e.target.value })}
          type="text"
          testId="domain"
        />
        <Styles.InfoText>
          {widlcardSslCertificateLocale.placeholders.domainInfo}
        </Styles.InfoText>

      </Styles.InputsWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => handleChange({ key: 'agreed', value: !formData.agreed })}
      >
        <Checkbox checked={formData.agreed} id="readTerms" />
        <Styles.Text>
          {widlcardSslCertificateLocale.placeholders.agreed}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.ButtonWrapper>
        <Button
          size="large"
          label={opening ? <IconLoading /> : widlcardSslCertificateLocale.send}
          disabled={checkForm() || opening}
          type="submit"
          testId="submit"
        />
      </Styles.ButtonWrapper>
    </Styles.Form>
  );
};

export default WildcardSslCertificate;
