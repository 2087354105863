import React, { useCallback, useState } from 'react';
import * as Styles from './GenerateCSR.styles';
import useLocale from '@/hooks/useLocale/useLocale';
import {
  IconData, Input, IconLoading, Checkbox, Button, Radio,
} from 'gatorcomponents';
import { V2Forms } from '@/hooks/supportForms/useSupportForms.types';
import { FieldValidators } from '@/utils/FieldValidators';

const inputErrors = {
  email: '',
  name: '',
  city: '',
  state: '',
  country: '',
  sslUrl: '',
  isWildcard: '',
};

export const GenerateCSR = ({
  submitForm,
  selectedProduct,
  customerSummary,
  opening,
}) => {
  const { ticket: ticketsLocale } = useLocale();
  const { generateCSR: generateCSRLocale } = ticketsLocale.forms;
  const [email, setEmail] = useState(customerSummary.email || '');
  const [name, setName] = useState(customerSummary.name || '');
  const [city, setCity] = useState(customerSummary.city || '');
  const [state, setState] = useState(customerSummary.state || '');
  const [country, setCountry] = useState(customerSummary.country || '');
  const [company, setCompany] = useState(customerSummary.company || '');
  const [sslUrl, setSslUrl] = useState('');
  const [isWildcard, setIsWildcard] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState(inputErrors);

  const handleValidateEmail = useCallback(() => {
    if (!FieldValidators.email(email)) {
      setErrors({ ...errors, email: generateCSRLocale.errors.invalidEmail });
    } else {
      setErrors({ ...errors, email: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, errors]);

  const handleValidateEmptyField = useCallback((field) => {
    switch (field) {
      case 'name':
        if (!name.length) {
          setErrors({ ...errors, name: generateCSRLocale.errors.emptyField });
        } else {
          setErrors({ ...errors, name: '' });
        }
        break;
      case 'city':
        if (!city.length) {
          setErrors({ ...errors, city: generateCSRLocale.errors.emptyField });
        } else {
          setErrors({ ...errors, city: '' });
        }
        break;
      case 'state':
        if (!state.length) {
          setErrors({ ...errors, state: generateCSRLocale.errors.emptyField });
        } else {
          setErrors({ ...errors, state: '' });
        }
        break;
      case 'country':
        if (!country.length) {
          setErrors({ ...errors, country: generateCSRLocale.errors.emptyField });
        } else {
          setErrors({ ...errors, country: '' });
        }
        break;
      case 'sslUrl':
        if (!sslUrl.length) {
          setErrors({ ...errors, sslUrl: generateCSRLocale.errors.emptyField });
        } else {
          setErrors({ ...errors, sslUrl: '' });
        }
        break;
      default:
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, city, state, country, sslUrl, errors]);

  const handleSubmit = (e) => {
    Object.keys(errors).filter(key => key !== 'email').map(key => handleValidateEmptyField(key));
    e.preventDefault();

    const data = {
      locale: ticketsLocale,
      automation: false,
      pid: selectedProduct.pid,
      product: selectedProduct.id,
      subject: ticketsLocale.forms.names.generateCSR,
      key: V2Forms.GENERATE_CSR,
      tags: ['whmcs_form_tecnico_gerar_csr'],
      root: {
        name: 'techsupport',
        as: ticketsLocale.forms.names.generateCSR,
      },
      body: `
        ${generateCSRLocale.bodyFields.product}: ${selectedProduct.name} - ${selectedProduct.domain}
        ${generateCSRLocale.bodyFields.user}: ${selectedProduct.username || ''}
        ${generateCSRLocale.bodyFields.server}: ${(selectedProduct.serverhostname || selectedProduct.dedicatedip) || ''}

        ${generateCSRLocale.bodyFields.email}: ${email}
        ${generateCSRLocale.bodyFields.name}: ${name}
        ${generateCSRLocale.bodyFields.city}: ${city}
        ${generateCSRLocale.bodyFields.state}: ${state}
        ${generateCSRLocale.bodyFields.country}: ${country}
        ${generateCSRLocale.bodyFields.company}: ${company}
        ${generateCSRLocale.bodyFields.sslUrl}: ${sslUrl}
        ${generateCSRLocale.bodyFields.isWildcard}: ${isWildcard ? generateCSRLocale.bodyFields.yes : generateCSRLocale.bodyFields.no}
        ${generateCSRLocale.bodyFields.agree}: ${agreed ? generateCSRLocale.bodyFields.yes : generateCSRLocale.bodyFields.no}
      `,
    };

    submitForm && submitForm(data);
  };

  const checkForm = useCallback(() => {
    let isOk = false;

    isOk = agreed
    && email.length
    && name.length
    && city.length
    && state.length
    && country.length
    && sslUrl.length;

    return isOk;
  }, [email, name, city, state, country, sslUrl, agreed]);

  return (
    <Styles.Form onSubmit={e => handleSubmit(e)}>
      <Styles.Info>
        <IconData />
        {generateCSRLocale.info}
      </Styles.Info>

      <Styles.Title>
        {generateCSRLocale.title}
      </Styles.Title>

      <Styles.InputsWrapper>
        <Styles.InputContainer error={errors.email}>
          <Input
            name="email"
            testId="email"
            placeholder={generateCSRLocale.placeholders.email}
            label={`${generateCSRLocale.bodyFields.email} *`}
            value={email}
            onBlur={handleValidateEmail}
            onChange={e => setEmail(e.target.value)}
            errorMessage={errors.email}
          />
        </Styles.InputContainer>
        <Styles.InputContainer error={errors.name}>
          <Input
            name="name"
            testId="name"
            placeholder={generateCSRLocale.placeholders.name}
            label={`${generateCSRLocale.bodyFields.name} *`}
            value={name}
            onChange={e => setName(e.target.value)}
            onBlur={() => handleValidateEmptyField('name')}
            errorMessage={errors.name}
          />
        </Styles.InputContainer>
        <Styles.InputContainer error={errors.city}>
          <Input
            name="city"
            testId="city"
            placeholder={generateCSRLocale.placeholders.city}
            label={`${generateCSRLocale.bodyFields.city} *`}
            value={city}
            onChange={e => setCity(e.target.value)}
            onBlur={() => handleValidateEmptyField('city')}
            errorMessage={errors.city}
          />
        </Styles.InputContainer>
        <Styles.InputContainer error={errors.state}>
          <Input
            name="state"
            testId="state"
            placeholder={generateCSRLocale.placeholders.state}
            label={`${generateCSRLocale.bodyFields.state} *`}
            value={state}
            onChange={e => setState(e.target.value)}
            onBlur={() => handleValidateEmptyField('state')}
            errorMessage={errors.state}
          />
        </Styles.InputContainer>
        <Styles.InputContainer error={errors.country}>
          <Input
            name="country"
            testId="country"
            placeholder={generateCSRLocale.placeholders.country}
            label={`${generateCSRLocale.bodyFields.country} *`}
            value={country}
            onChange={e => setCountry(e.target.value)}
            onBlur={() => handleValidateEmptyField('country')}
            errorMessage={errors.country}
          />
        </Styles.InputContainer>
        <Styles.InputContainer>
          <Input
            name="company"
            testId="company"
            placeholder={generateCSRLocale.placeholders.company}
            label={generateCSRLocale.bodyFields.company}
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
        </Styles.InputContainer>
        <Styles.InputContainer error={errors.sslUrl}>
          <Input
            name="sslUrl"
            testId="sslUrl"
            placeholder={generateCSRLocale.placeholders.sslUrl}
            label={`${generateCSRLocale.bodyFields.sslUrl} *`}
            value={sslUrl}
            onChange={e => setSslUrl(e.target.value)}
            onBlur={() => handleValidateEmptyField('sslUrl')}
            errorMessage={errors.sslUrl}
          />
        </Styles.InputContainer>
      </Styles.InputsWrapper>

      <Styles.BlockTitle>
        {generateCSRLocale.isWildCard}
      </Styles.BlockTitle>

      <Styles.RadioWrapper>
        <Styles.RadioLabel htmlFor="#isWildcard" onClick={() => setIsWildcard(true)} data-testid="isWildcard">
          <Radio checked={isWildcard} id="isWildcard" />
          {generateCSRLocale.bodyFields.yes}
        </Styles.RadioLabel>

        <Styles.RadioLabel htmlFor="#isNotWildcard" onClick={() => setIsWildcard(false)} data-testid="isNotWildcard">
          <Radio checked={!isWildcard} id="isNotWildcard" />
          {generateCSRLocale.bodyFields.no}
        </Styles.RadioLabel>
      </Styles.RadioWrapper>

      <Styles.CheckboxLabel
        htmlFor="#readTerms"
        data-testid="checkReadTerms"
        onClick={() => setAgreed(!agreed)}
      >
        <Checkbox checked={agreed} id="readTerms" />
        <Styles.Text>
          {generateCSRLocale.checkbox}
        </Styles.Text>
      </Styles.CheckboxLabel>

      <Styles.InputsWrapper>
        <Button
          size="large"
          testId="submit"
          label={opening ? <IconLoading /> : generateCSRLocale.sendButtonLabel}
          disabled={!checkForm() || opening}
          type="submit"
        />
      </Styles.InputsWrapper>
    </Styles.Form>
  );
};

export default GenerateCSR;
