import styled, { css } from 'styled-components';

export const Label = styled.span``;

export const Content = styled.span`
  ${({ clickable }) => css`
    align-items: flex-start;
    cursor: ${clickable ? 'pointer' : 'unset'};
    display: inline;
    flex-direction: row;
    width: 100%;
  `}
`;
