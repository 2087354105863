import React, { useEffect, useState } from 'react';
import * as Styles from './RetentionOfferDiscountInNextRenew.styles';
import { useHistory, useParams } from 'react-router';
import useLocale from '@/hooks/useLocale';
import SnappyRetention from '@/media/subscriptions/retention/snappyGlass.svg';
import Star from '@/media/subscriptions/retention/star.svg';

import { Button, IconArrow, IconLoading } from 'gatorcomponents';
import { useBenefitParams } from '@/hooks/billing/subscriptions/useBenefitParams';
import { formatCurrency } from '@/utils/Formatters/formatCurrency';
import { useBenefitAnalytics } from '@/hooks/billing/subscriptions/useBenefitAnalytics';
import { useBenefitInvoice } from '@/hooks/billing/subscriptions/useBenefitInvoice';
import { AcceptedOfferSuccessModal } from '../AcceptedOfferSuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '@/redux/actions/notifications';
import { billingAnalytics } from '@/analytics';

const RetentionOfferDiscountInNextRenew = ({
  loading = false,
  titleVersion = 0,
  reactivationData = null,
  currentItem = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { billing: billingLocale, routes: routesLocale } = useLocale();
  const { manageSubscriptionRetentionDiscountOffer } = billingLocale;
  const { retentionInfos } = useBenefitParams();
  const invoiceStatus = useSelector(state => state.billing.invoice);
  const { analyticsBenefitAccept, analyticsBenefitDecline } = useBenefitAnalytics(retentionInfos, true);
  const { retentionType } = useParams();
  const { getInvoiceInfos, invoiceIsLoading, publishInvoiceDraft } = useBenefitInvoice();
  const [isAcceptOffer, setAcceptOffer] = useState(false);
  const [version, setVersion] = useState(0);
  const params = useParams();
  const { loadingAcceptRetention } = useSelector(state => state.billing.subscriptions);

  const handleLoading = () => {
    if (invoiceIsLoading) {
      setVersion(invoiceStatus && invoiceStatus.draft ? 0 : 1);
      setAcceptOffer(true);
    }
  };

  useEffect(() => {
    handleLoading();
  }, [invoiceStatus]);

  if (!reactivationData) {
    history.push(`${routesLocale.subscriptions}`);
    return (<></>);
  }

  const discountAmout = reactivationData && formatCurrency(reactivationData.discountAmount);
  const discountPercent = reactivationData && reactivationData.percent;


  const handleAcceptOfferButtonClick = () => {
    analyticsBenefitAccept();
    getInvoiceInfos(currentItem.id);
  };

  const handleCancelButtonClick = () => {
    analyticsBenefitDecline();
    const { subscriptionType, subscriptionId } = params;

    const cancelRenewalPath = `${routesLocale.manageSubscription}${routesLocale.cancelRenewal}/${subscriptionId}/${subscriptionType}`;
    const cancelImmediatePath = `${routesLocale.manageSubscription}${routesLocale.cancelSubscription}/${subscriptionId}/${subscriptionType}`;

    const nextStep = (retentionType.includes('renova') || params.path === 'renew')
      ? cancelRenewalPath
      : cancelImmediatePath;

    history.push(nextStep);
  };

  const getPaymentCycle = (cycle) => {
    switch (cycle) {
      case 'Monthly':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.Monthly;
      case 'Quarterly':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.Quarterly;
      case 'Annually':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.Annually;
      case 'Semi-Annually':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.SemiAnnually;
      case 'Biennially':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.Biennially;
      case 'Triennially':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.Triennially;
      case 'FreeAccount':
        return manageSubscriptionRetentionDiscountOffer.paymentCycle.FreeAccount;
      default:
        return '';
    }
  };

  const getDescriptionPlan = () => `${currentItem.productName} ${getPaymentCycle(currentItem.paymentCycle)}`;
  const {
    subscriptions: {
      acceptedOfferSuccessModalClose, acceptedOfferSuccessModalDisplay, acceptedOfferSuccessModalRenewalAfterOption, acceptedOfferSuccessModalRenewalNowOption, acceptedOfferSuccessModalPayNowOption,
    },
  } = billingAnalytics;

  const getAcceptedOfferSuccessModalProps = () => {
    const onCloseClick = () => {
      acceptedOfferSuccessModalClose();
      history.push(`${routesLocale.subscriptions}${routesLocale.manageSubscription}/${currentItem.id}/${currentItem.type}`);
    };

    const onBackAction = () => {
      acceptedOfferSuccessModalRenewalAfterOption();
      history.push(`${routesLocale.subscriptions}${routesLocale.manageSubscription}/${currentItem.id}/${currentItem.type}`);
    };

    const onDisplay = () => {
      acceptedOfferSuccessModalDisplay(invoiceStatus.draft);
    };

    const onAcceptAction = () => {
      if (invoiceStatus.draft === false) {
        acceptedOfferSuccessModalPayNowOption();
        history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceStatus.invoiceId}?open_invoice=true`);
      } else {
        const executePayment = (enabledToPayment) => {
          enabledToPayment ? history.push(`${routesLocale.invoices}${routesLocale.unpaid}/${invoiceStatus.invoiceId}?open_invoice=true`)
            : dispatch(enqueueSnackbar(billingLocale.genericError));
        };

        acceptedOfferSuccessModalRenewalNowOption();
        publishInvoiceDraft({ invoiceId: invoiceStatus.invoiceId, executePayment });
      }
    };

    return {
      onBackAction,
      onDisplay,
      onCloseClick,
      invoiceId: currentItem.invoiceId,
      onAcceptAction,
      version,
    };
  };

  return (
    <>
      <Styles.Wrapper data-testid="retention-subscription-handler">
        <Styles.ContentStar>
          <Styles.StarImg src={Star} alt="Star" loading="lazy" />
        </Styles.ContentStar>
        <Styles.BenefitsWrapper>
          {loading && <IconLoading size="lg" />}
          {!loading && (
            <Styles.Content>
              <Styles.ContentWrapper>
                <Styles.BenefitsContent version={titleVersion}>
                  <div>
                    {titleVersion === 1 && (
                      <Styles.Title version={titleVersion}>{manageSubscriptionRetentionDiscountOffer.retentionTitles[0]}</Styles.Title>
                    )}
                    <Styles.Title data-testid="retention-title">{manageSubscriptionRetentionDiscountOffer.retentionTitles[titleVersion]}</Styles.Title>
                    <Styles.BenefitDescription data-testid="retention-benefit-decription" version={titleVersion}>{manageSubscriptionRetentionDiscountOffer.discountDescription(discountPercent, titleVersion)}</Styles.BenefitDescription>
                    <Styles.OfferDescription data-testid="retention-offer-decription">{manageSubscriptionRetentionDiscountOffer.benefitDescription(discountAmout, getDescriptionPlan())}</Styles.OfferDescription>
                  </div>
                </Styles.BenefitsContent>
                <Styles.ControlWrapper>
                  <Button
                    label={loadingAcceptRetention ? (
                      <>
                        <IconLoading />
                        {manageSubscriptionRetentionDiscountOffer.benefitAcceptOfferButton}
                      </>
                    ) : manageSubscriptionRetentionDiscountOffer.benefitAcceptOfferButton}
                    variant="primary"
                    onClick={handleAcceptOfferButtonClick}
                    testId="retention-redirect-button"
                    disabled={loadingAcceptRetention}
                  />
                </Styles.ControlWrapper>
                <Styles.SnappyImg src={SnappyRetention} alt="HostGator" loading="lazy" />
              </Styles.ContentWrapper>
              <Styles.Info data-testid="retention-offer-decription-info">{manageSubscriptionRetentionDiscountOffer.benefitInfo}</Styles.Info>
            </Styles.Content>
          )}
        </Styles.BenefitsWrapper>
        <Styles.FooterContent>
          <Button
            label={manageSubscriptionRetentionDiscountOffer.continueCancelButton}
            onClick={() => handleCancelButtonClick()}
            variant="text"
            testId="retention-cancel-button"
            iconRight={<IconArrow direction="right" size="lg" color="primaryBlue" />}
          />
        </Styles.FooterContent>
      </Styles.Wrapper>
      {isAcceptOffer && (
        <AcceptedOfferSuccessModal {...getAcceptedOfferSuccessModalProps()} />
      )}
    </>
  );
};

export default RetentionOfferDiscountInNextRenew;
