import React from 'react';
import { withStyles, SvgIcon } from '@material-ui/core';

import styles from './styles';


const BlueDoodle = ({ classes, className }) => (
  <SvgIcon className={`${classes.icon} ${className}`} viewBox="0 0 436.915 453.782">
    <g id="Grupo_31594" data-name="Grupo 31594" transform="matrix(0.996, 0.087, -0.087, 0.996, -794.067, -284.135)" opacity="0.7">
      <path id="Caminho_37336" data-name="Caminho 37336" d="M119.671.018c100.192,0,183-3.707,183,96.485s10.439,252.555-89.753,252.555c-4.008,0-89.235-39.737-93.244-39.89C27.4,305.651,0,219.114,0,122.93,0,22.738,19.479.018,119.671.018Z" transform="matrix(0.819, -0.574, 0.574, 0.819, 829.012, 364.759)" fill="#d2e7f5" />
    </g>
  </SvgIcon>
);

export default withStyles(styles)(BlueDoodle);
