import React, { useContext } from 'react';
import {
  Button,
  Link,
  Modal,
} from '@/pages/common';
import * as Styles from './ModalDomainCanceled.styles';
import { IconClose, IconInfo } from '@/icons';
import { ThemeContext } from 'styled-components';
import { locale } from '@/utils/locale';
import { modalCloseAction } from '../../SitesHandler/SitesHandler.enum';

const ModalDomainCanceled = ({
  title, button, contentRedirect, contentInfo, contentDomainName, handleOnCloseWarningModal,
}) => {
  const handleOnClose = action => (
    handleOnCloseWarningModal && handleOnCloseWarningModal(action)
  );
  const theme = useContext(ThemeContext);
  return (
    <Modal maxWidth={625} testId="ModalDomainCanceled" disablePadding>
      <Styles.Title>
        {title}
      </Styles.Title>
      <Styles.CloseButton onClick={() => handleOnClose(modalCloseAction.close)}>
        <IconClose testId="closeButton" />
      </Styles.CloseButton>
      <Styles.ContentWrapper>
        <IconInfo color={theme.palette.yellow.mustard} size="26" />
        <Styles.Content>
          <Styles.DomainName>
            {contentDomainName}
          </Styles.DomainName>
          {contentInfo}
          <Link target="_blank" href={locale('routes.domainRegister')} text={contentRedirect} />
        </Styles.Content>
      </Styles.ContentWrapper>
      <Styles.Action>
        <Button testId="understandButton" onClick={() => handleOnClose(modalCloseAction.understand)} font="secondary" variant="newPrimary" label={button} size="large" />
      </Styles.Action>
    </Modal>
  );
};

export default ModalDomainCanceled;
