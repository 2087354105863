import React from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withI18n } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import LinkButton from '@/components/Buttons/LinkButton';
import IconMailCPanel from '@/media/icons/mailCPanel.svg';
import styles from './styles';


const ModalMoveCPanelSuccess = ({
  onClose, open, classes, t, domain,
}) => (
  <Dialog
    aria-labelledby="responsive-dialog-title"
    open={open}
  >
    <div className={classes.activeDialog}>
      <div id="dialog-title" className={classes.btnClose}>
        <IconButton aria-label="Close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent className={classes.dialogContent}>
        <img src={IconMailCPanel} alt={t('sitelock.alt')} className={classes.logo} />

        <Typography className={classes.titleDialog}>{t('proEmail.hirePlan.successMoveCPanelTitle')}</Typography>
        <Typography className={classes.descriptionDialog}>
          {t('proEmail.hirePlan.successMoveCPanelDescription')}
        </Typography>
        <LinkButton to={`${t('routes.emails')}${t('routes.createEmail')}/${domain}`} onClick={onClose} className={classes.btActive} data-testid="linkButton">
          {t('proEmail.hirePlan.successMoveCPanelAction')}
        </LinkButton>
      </DialogContent>
    </div>
  </Dialog>
);

export default withI18n()(withStyles(styles)(ModalMoveCPanelSuccess));
