import { datadogLogs } from '@datadog/browser-logs';
import { COUNTRY, IS_PROD } from '@/config';

const error = (message, error) => {
  const messageContext = error
    ? {
      isProd: IS_PROD,
      brand: COUNTRY,
      errorStack: error.stack || error.errorStack,
      errorResponse: error.response,
      requestData: error.userRequestData,
      errorMessage: error.message || error.errorMessage,
      componentError: error.componentError,
    }
    : undefined;

  return datadogLogs.logger.error(message, messageContext);
};

const info = (message, context) => {
  const messageContext = context
    ? {
      brand: COUNTRY,
      isProd: IS_PROD,
      ...context,
    }
    : {
      brand: COUNTRY,
      isProd: IS_PROD,
    };
  return datadogLogs.logger.info(message, messageContext);
};

const logger = {
  error,
  info,
};

export default logger;
