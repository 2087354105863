import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    margin-top: 36px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 40px;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.lg};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 24px;
    margin: 0px 0px 15px 0px;
    opacity: 1;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    align-items: flex;
    display: flex;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
    }
  `}
`;

export const InfoItem = styled.span`
  ${({ theme }) => css`
    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      &:not(:first-child) {
        margin: 26px 0 0 0;
      }

      &:first-child {
        flex: 100%;
      }
    }
  `}
`;

export const Label = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.grey.light};
    font-size: ${theme.font.sizes.xxs};
    font-weight: ${theme.font.weights.regular};
    letter-spacing: 0.01px;
    line-height: 16px;
    margin: 0 0 2px 0;
    opacity: 1;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.grey.dark};
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.medium};
    letter-spacing: 0.01px;
    line-height: 21px;
    opacity: 1;
    white-space: nowrap;
  `}
`;

export const InfoItemSecondary = styled(InfoItem)`
  ${({ theme }) => css`
    & ${Label} {
      margin-bottom: 5px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      & + ${InfoItemSecondary} {
        margin-left: 24px;
      }
    }
  `}

`;

export const InfoItemCentered = styled(InfoItem)`
  ${({ theme }) => css`
    align-self: center;

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 100%;
    }
  `}
`;
